<ion-list *ngIf="activity.uniqueSharedResources && activity.uniqueSharedResources.length > 0">
    <!-- <ion-item-divider color="light">
        {{'SHARED_RESOURCES_CAP' | translate}}
    </ion-item-divider> -->
    <ind-section-header [viewData]='{id:"meeting-details-shared-resource",title: translate.instant("SHARED_RESOURCES")}'></ind-section-header>
    <ion-item no-lines class="content-list">
        <ion-scroll scrollX="true" #scroll>
            <ion-card class="shared-resource-card" *ngFor="let resource of activity.uniqueSharedResources" tappable (click)="openSharedResourceDetails(resource)">
                <img io-thumbnail class="shared-resource-card-thumbnail" src="{{resource.thumbnailURL}}" onError="this.src='assets/imgs/image_thumbnail_1.svg'" alt="" srcset="">
                <ion-card-content class="secondary-text">{{resource.title}}</ion-card-content>
            </ion-card>
        </ion-scroll>
    </ion-item>
</ion-list>
