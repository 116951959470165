import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApprovalStatus, Quotes, QuoteStatus, QuotesToolTab } from '@omni/classes/quotes/quotes.class';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { QuoteToolDataService } from '@omni/data-services/quote-tool/quote-tool-data.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { QuotesService } from '@omni/services/quote/quote-tool.service';

import { UIService } from '@omni/services/ui/ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewQuoteComponent } from '../new-quote/new-quote.component';
import * as _ from 'lodash';
import { EventsService } from '@omni/services/events/events.service';
import { QuoteDetailsComponent } from '../quote-details/quote-details.component';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

@Component({
  selector: 'quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss'],
})
export class QuoteListComponent implements OnInit {
  indHeaderLeftModel: IndHeaderLeftDataModel;
  quoteSearchText: string;
  quotesListKeyControlAreaModel: IndKeyControlsAreaModel;
  tabsData: IndTabsDataModel[];
  selectedTab: QuotesToolTab = QuotesToolTab.QUOTES;
  isSearching: boolean = false;
  quotes = [];
  deviceOffline: boolean = false;
  ngUnSubscibe$ = new Subject<boolean>();
  allQuotesHeaderModel: IndSectionHeaderViewDataModel;
  sortBy: { text: string, value: string, asc: boolean }
  sortPopoverData: any;
  pendingSectionHeader: IndSectionHeaderViewDataModel;
  completedSectionHeader: IndSectionHeaderViewDataModel;
  isPendingSectionExpanded: boolean = true;
  isCompletedSectionExpanded: boolean = true;
  filteredApprovalTabData: Quotes[] = [];
  pendingApprovalQuotes: Quotes[] = [];
  completedApprovalQuotes: Quotes[] = [];
  filteredQuotes: Quotes[] = [];
  showVectorImage: boolean = true;
  private approvalsTabData: Quotes[] = [];
  private currentModel: QuotesToolTab = QuotesToolTab.QUOTES
  private selectedQuote: Quotes;

  constructor(
    private quotesOfflineService: QuotesService,
    public activityService: ActivityService,
    public translate: TranslateService,
    public navService: NavigationService,
    public uiService: UIService,
    public footerService: FooterService,
    private deviceService: DeviceService,
    private popover: PopoverController,
    private events: EventsService,
    private quotesToolDataService: QuoteToolDataService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) { }

  ngOnInit() {
    this.deviceService.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      // if the device offlice diisable account plan cancel button
      this.deviceOffline = isOffline;
    });
    this.initHeaderLeft();
    this._initKeyControlsAreaModel();
    this.setTabsData();
    this.updateEmptyMessage();
    this.initPendingSectionHeader();
    this.initCompletedSectionHeader();
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "createdOn",
        items: [
          // { text: this.translate.instant('NAME'), value: "name", asc: true },
          { text: this.translate.instant('ACCOUNT_CREATED_ON'), value: "createdOn", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    this.quotesOfflineService.quotesList$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((quotes) => {
      if (quotes) {
        this.quotes = quotes;
        // this.filteredQuotes = _.cloneDeep(this.quotes);
        this.onInput();
        this.initMyQuotesHeaderModel();
        this.showVectorImage = _.isEmpty(this.filteredQuotes);
      }
    });

    this.quotesOfflineService.selectedQuotes$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((selectedQuotes) => {
      if (!selectedQuotes) {
        this.selectedQuote = null;
        return;
      };
      if ((this.selectedQuote &&
        (this.currentModel === QuotesToolTab.APPROVALS ? this.selectedQuote.approvalActivityId != selectedQuotes.approvalActivityId : this.selectedQuote.ID !== selectedQuotes.ID))
        || !this.selectedQuote) {
        this.selectedQuote = selectedQuotes;
        this.openQuotes();
      }
    })

    this.events.subscribe('updatePendingItem', (quote) => {
      if (this.currentModel === QuotesToolTab.APPROVALS && quote) {
        const approvalIndex = this.approvalsTabData.findIndex(q => q.ID === quote.ID && q.approvalActivityId === quote.approvalActivityId);
        if (approvalIndex > -1) {
          this.approvalsTabData[approvalIndex].approvalStatus = quote.approvalStatus;
          this.approvalsTabData[approvalIndex].statuscode = quote.statuscode;
        }
        const index = this.filteredApprovalTabData.findIndex(q => q.ID === quote.ID && q.approvalActivityId === quote.approvalActivityId);
        if (index > -1) {
          this.filteredApprovalTabData[index].approvalStatus = quote.approvalStatus;
          this.filteredApprovalTabData[index].statuscode = quote.statuscode;
          this.filterApprovalsBasedOnStatus();
          this.initPendingSectionHeader();
          this.initCompletedSectionHeader();
        }
      }
    })
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'sort':
        this.sortQuotes();
        break;
      case 'pending-items-actions':
        this.isPendingSectionExpanded = !this.isPendingSectionExpanded
        this.initPendingSectionHeader();
        break;

      case 'completed-items-actions':
        this.isCompletedSectionExpanded = !this.isCompletedSectionExpanded
        this.initCompletedSectionHeader();
        break;
    }
  }

  sortQuotes() {
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event })
      .then((data) => {
        data.present();
      })
  }

  private _initKeyControlsAreaModel() {
    this.quotesListKeyControlAreaModel = {
      id: 'quotes-list-key-controls-area',
      eventHandler: () => this.createQuotes(),
      controls: [{
        id: 'create-btn',
        text: this.translate.instant('CREATE'),
        isDisabled: this.deviceOffline,
        leftAligned: false
      }]
    };
  }

  private initMyQuotesHeaderModel() {
    let controls: any[] = [];
    let count = this.filteredQuotes.length;

    if (!this.sortBy && this.sortPopoverData && this.sortPopoverData.length > 0) {
      this.sortBy = this.sortPopoverData[0];
    }

    controls.push({
      id: 'sort',
      text: this.sortBy?.text,
      isDisabled: false,
      icon: 'assets/imgs/sort_with_double_arrows.svg'
    });

    this.allQuotesHeaderModel = {
      id: 'quotes-all',
      title: `${this.translate.instant('MY_QUOTES')} (${count})`,
      controls: controls
    }
  }

  updateEmptyMessage() {
    const data = this.currentModel === QuotesToolTab.APPROVALS ? this.filteredApprovalTabData : this.filteredQuotes;
    this.uiService.updateNothingSelectedScreenMessageFor(data.length);
  }

  private initHeaderLeft() {
    this.indHeaderLeftModel = {
      id: 'quotes-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('QUOTES'),
      mode: true,
      controls: [{
        id: 'home',
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: 'left',
      }],
    };
  }

  initPendingSectionHeader() {
    this.pendingSectionHeader = {
      id: 'pending-items-header',
      title: `${this.translate.instant('PENDING')} (${this.pendingApprovalQuotes.length})`,
      controls: [{
        id: 'pending-items-actions',
        icon: this.isPendingSectionExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
    }
  }

  initCompletedSectionHeader() {
    this.completedSectionHeader = {
      id: 'completed-items-header',
      title: `${this.translate.instant('COMPLETED')} (${this.completedApprovalQuotes.length})`,
      controls: [{
        id: 'completed-items-actions',
        icon: this.isCompletedSectionExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
    }
  }

  setTabsData() {
    this.tabsData = [{
      displayText: this.translate.instant('QUOTES'),
      value: QuotesToolTab.QUOTES
    }, {
      displayText: this.translate.instant('APPROVALS'),
      value: QuotesToolTab.APPROVALS,
      disable: this.deviceOffline
    }];
  }

  onPageTitleControlClick(id) {
    if (id === 'home') {
      this.goBack();
    }
  }

  onInput() {
    if (this.quoteSearchText && this.quoteSearchText.trim().length > 0) {
      this.isSearching = true;
      const searchedName = this.quoteSearchText.toLowerCase();
      if (this.currentModel === QuotesToolTab.QUOTES) {
        this.filteredQuotes = _.cloneDeep(this.quotes.filter(quote => quote.name.toLowerCase().includes(searchedName)))
      } else {
        this.filteredApprovalTabData = this.filteredApprovalTabData.filter(quote => quote.name.toLowerCase().includes(searchedName));
        this.filterApprovalsBasedOnStatus();
        this.initPendingSectionHeader();
        this.initCompletedSectionHeader();
      }
    } else {
      this.isSearching = false;
      if (this.currentModel === QuotesToolTab.QUOTES) {
        this.filteredQuotes = _.cloneDeep(this.quotes)
      } else {
        this.filteredApprovalTabData = this.approvalsTabData;
        this.filterApprovalsBasedOnStatus();
        this.initPendingSectionHeader();
        this.initCompletedSectionHeader();
      }
    }
    this.initMyQuotesHeaderModel()
  }

  getStatusDisplayValue(quote) {
    switch (quote.statuscode) {
      case QuoteStatus.APPROVED:
        return this.translate.instant("APPROVED");
      case QuoteStatus.DRAFT:
        return this.translate.instant("OM_DRAFT");
      case QuoteStatus.REJECTED:
        return this.translate.instant("REJECTED");
      case QuoteStatus.DYNAMICS_CANCELED:
      case QuoteStatus.CANCELED:
        return this.translate.instant("CANCELLED");
      case QuoteStatus.PENDING_APPROVAL:
        return this.translate.instant("POP_IN_REVIEV");
      case QuoteStatus.DYNAMICS_IN_PROGRESS:
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.WON:
        return this.translate.instant("WON");
      case QuoteStatus.OPEN:
        return this.translate.instant("AL_SHIPMENT_FILTERLIST_OPEN");
      case QuoteStatus.LOST:
        return this.translate.instant("OPPORTUNITY_STATE_LOST");
    }
  }

  async checkQuoteModel(tappedTab) {
    if (this.currentModel === tappedTab) return;
    if (this.navService.getActiveChildNavViewPageName() != PageName.NothingSelectedView) this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    this.currentModel = tappedTab;
    this.isSearching = false;
    this.quoteSearchText = "";
    if (this.currentModel === QuotesToolTab.APPROVALS) {
      await this.uiService.displayLoader();
      this.approvalsTabData = await this.quotesToolDataService.getQuotesApprovalTabData().catch((er) => {
        console.error("Failed to fetch data for approvla tab ", er);
        this.uiService.dismissLoader();
        return [];
      });
      this.filteredApprovalTabData = this.approvalsTabData;
      this.filterApprovalsBasedOnStatus();
      await this.uiService.dismissLoader();
      this.initPendingSectionHeader();
      this.initCompletedSectionHeader();
    } else {
      this.filteredQuotes = _.cloneDeep(this.quotes);
      this.initMyQuotesHeaderModel();
    }
    this.updateEmptyMessage();
    this.showVectorImage = this.currentModel === QuotesToolTab.APPROVALS ? _.isEmpty(this.filteredApprovalTabData) : _.isEmpty(this.filteredQuotes);
    this.selectedQuote = null;
  }

  private filterApprovalsBasedOnStatus() {
    this.pendingApprovalQuotes = [], this.completedApprovalQuotes = [];
    _.orderBy(this.filteredApprovalTabData, (data) => {

      return new Date(data.createdon)
    }, ['desc']).forEach(quote => {
      if (quote.approvalStatus == ApprovalStatus.PENDING)
        this.pendingApprovalQuotes.push(quote);
      else
        this.completedApprovalQuotes.push(quote);
    });
  }

  private createQuotes() {
    if (this.navService.getActiveChildNavViewPageName() != PageName.NewQuoteComponent) {
      this.navService.setChildNavRightPaneView(true);
      this.quotesOfflineService.selectedQuotes$.next(null);

      this.navService.setChildNavRoot(NewQuoteComponent, PageName.NewQuoteComponent, PageName.QuotePageComponent, {});
    }
  }

  async goBack() {
    this.uiService.toolsActivityActive = false;
    this.uiService.activeView = '';
    if (this.navService.getCurrentPageName() === PageName.QuotePageComponent) {
      this.uiService.showRightPane = false;
      this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
      this.navService.popChildNavCtrlFromStack(ChildNavNames.QuotesNavigation);
      await this.navService.popToRootWithPageTracking().then(() => {
        if (this.activityService.selectedActivityAtHome) {
          this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
          if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
            this.footerService.initButtons(FooterViews.Activities);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
            this.footerService.initButtons(FooterViews.EmailDetails);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) {
            this.footerService.initButtons(FooterViews.PHONE_CALL);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
            this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.NewSampleActivityComponent) {
            this.footerService.initButtons(FooterViews.SampleDropDetails);
          } else {
            this.logicToRunAfterPopToRootWhenAccessedFromToolsDrawer();
          }
        } else {
          this.logicToRunAfterPopToRootWhenAccessedFromToolsDrawer();
        }
      });
    }
  }

  private logicToRunAfterPopToRootWhenAccessedFromToolsDrawer() {
    // Short call feature requires to bring the tab back to short call home
    if (this.faService.isShortCallLauncherEnabled) {
      this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
      this.uiService.setAgendaTab('shortCallHome');
    }
  }

  getQuotemainCardModel(quote: Quotes): MainCardViewDataModel {
    const card = {
      id: this.currentModel === QuotesToolTab.APPROVALS ? quote.approvalActivityId : quote.ID,
      fixedHeight: true,
      primaryTextRight: quote.name,
      secondaryTextRight: this.currentModel === QuotesToolTab.APPROVALS ? this.getStatusDisplayValue(quote) : quote.accountName,
      showArrow: false,
      endIconCssClass: 'completed-icon',
      isSecondSrcAttribute: true,
      showSecondEndIcon: false,
      secondEndIconType: 'assets/imgs/horizontal-menu-filled.svg',
      secondEndIconCssClass: 'showOnHover',
      arrowType: 'chevron-forward',
      isSelected: this.currentModel === QuotesToolTab.APPROVALS ? quote.ID === this.selectedQuote?.ID && this.selectedQuote?.approvalActivityId == quote.approvalActivityId : quote.ID === this.selectedQuote?.ID,
      clickHandler: async (id: string) => this.handleMarketingPlanItemClick(id)
    }
    return card;
  }

  async handleMarketingPlanItemClick(id) {
    if (this.currentModel === QuotesToolTab.APPROVALS && this.deviceOffline) return;
    let selectedQuote = null;
    if (!this.deviceOffline) {
      await this.uiService.displayLoader();
      const quoteId = this.currentModel === QuotesToolTab.APPROVALS ? this.filteredApprovalTabData.find(quote => quote.approvalActivityId === id).ID : id;
      selectedQuote = await this.quotesToolDataService.getQuotesTools(false, false, quoteId, this.currentModel === QuotesToolTab.APPROVALS);
      this.uiService.dismissLoader();
    }
    if (this.currentModel === QuotesToolTab.QUOTES) {
      selectedQuote = this.quotes.find(({ ID }) => ID === id);
    } else {
      const approvalTabQuote = this.filteredApprovalTabData.find(quote => quote.approvalActivityId === id);
      selectedQuote.approvalActivityId = approvalTabQuote.approvalActivityId;
      selectedQuote.approvalStatus = approvalTabQuote.approvalStatus;
    }
    this.quotesOfflineService.selectedQuotes$.next(selectedQuote);

  }

  openQuotes() {
    this.navService.setChildNavRightPaneView(true);
    this.navService.setChildNavRoot(QuoteDetailsComponent, PageName.QuoteDetailsComponent, PageName.QuotePageComponent, {
      selectedQuote: this.selectedQuote,
      selectedTab: this.selectedTab
    });
  }

  ngOnDestroy() {
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
  }

}
