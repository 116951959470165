<!-- Generated template for the CaseManagementDetailComponent component -->

<ion-header>
  <ind-page-title [viewData]="appSettingsRecommendationsPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content no-padding no-margin>

  <ion-list>
      <ind-section-header [viewData]='recommendationsHeader'></ind-section-header>
      <ion-item class="alignTextwithDivider">
        <ion-label class="detailsLabels">{{'SETTINGS_RECOMMENDATIONS_BEST_TIME_MEETING' | translate }}</ion-label>
        <ion-toggle [(ngModel)]="authService.user.showBestTimeRecommendation" (ionChange)="onBestTimeInMeetingsChange()" slot="end"></ion-toggle>
     </ion-item>
      <!-- <ion-item>
          <ion-label class="detailsLabel">{{'SETTINGS_SIZE_TO_SEND' | translate }}</ion-label>
          <ion-label class="detailsLabel">{{this.azureService.contentLength}}</ion-label>
      </ion-item> -->
  </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'SETTINGS_FOOTER_DETAILVIEW' | translate" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->