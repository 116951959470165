import { IONoteDTO } from '@omni/classes/io/io-note.class';
import { EventRegistrationStatus } from './../../enums/event/event.enum';
import { isValid } from "date-fns";

export class ContactEvent {
    public contactId: string;
    public availableEvents: any[];
    public upcomingEvents: EventRegistration[];

    constructor(contactId: string, availableEvents: any[], upcomingEvents: EventRegistration[]) {
        this.contactId = contactId;
        this.availableEvents = availableEvents;
        this.upcomingEvents = upcomingEvents;
    }
}

export class EventRegistration {
    public eventRegistrationId: string;
    public eventId: string;
    public eventName: string;
    public eventStartDate: any;
    public eventEndDate: Date;
    public description: string;
    public eventImage: string;
    public registeredBy: string;
    public indskr_ownerid: string;
    public indskr_ownername: string;
    public response: string;
    public registrationresponseId: string;
    public responseCapturedOn: Date;
    public registeredOn: Date;
    public scheduledStart: Date;
    public track_action: number;
    public type:string;
    public indskr_reasons: EventRegistrationStatus;
    public notes: IONoteDTO[];


    constructor(eventRegistrationId: string, eventId: string, eventName: string, eventStartDate: string,
        eventEndDate: string, description: string, eventImage: string, registeredBy: string,
        indskr_ownerid: string, indskr_ownername: string, response: string, registrationresponseId: string,
        responseCapturedOn: string, registeredOn: string, track_action: number,
        indskr_reasons: EventRegistrationStatus, notes: IONoteDTO[],
    ) {
        this.eventRegistrationId = eventRegistrationId;
        this.eventId = eventId;
        this.eventName = eventName;
        this.description = description;
        this.eventImage = eventImage;
        if (isValid(new Date(eventStartDate))) {
            this.eventStartDate = new Date(eventStartDate);
        } else {
            this.eventStartDate = new Date(parseFloat(eventStartDate));
        }
        if (isValid(new Date(eventEndDate))) {
            this.eventEndDate = new Date(eventEndDate);
        } else {
            this.eventEndDate = new Date(parseFloat(eventEndDate));
        }
        this.registeredBy = registeredBy;
        this.indskr_ownerid = indskr_ownerid;
        this.indskr_ownername = indskr_ownername;
        this.response = response;
        this.registrationresponseId = registrationresponseId;
        if (isValid(new Date(responseCapturedOn))) {
            this.responseCapturedOn = new Date(responseCapturedOn);
        } else {
            this.responseCapturedOn = new Date(parseFloat(responseCapturedOn));
        }
        if (isValid(new Date(registeredOn))) {
            this.registeredOn = new Date(registeredOn);
        } else {
            this.registeredOn = new Date(parseFloat(registeredOn));
        }
        if (this.registrationresponseId) {
            this.scheduledStart = this.responseCapturedOn;
        } else {
            this.scheduledStart = this.registeredOn;
        }
        this.track_action = track_action;
        this.indskr_reasons = indskr_reasons ?? null;
        this.notes = notes ?? [];
    }
}

export class EventRegistrationResponse {
    public offlineEventId: string;
    public eventRegistrationId: string;
    public response: string;
    public indskr_customer: string;
    public indskr_reasons: EventRegistrationStatus;
    public msevtmgt_customregistrationfield: { msevtmgt_isrequired: boolean, msevtmgt_type: number, msevtmgt_text: string };

    constructor(
      offlineEventId: string,
      eventRegistrationId: string,
      response: string,
      indskr_customer: string,
      indskr_reasons: EventRegistrationStatus,
      public eventId?: string
    ) {
        this.offlineEventId = offlineEventId;
        this.eventRegistrationId = eventRegistrationId;
        this.response = response;
        this.msevtmgt_customregistrationfield = {
            "msevtmgt_isrequired": true,
            "msevtmgt_type": 100000001,
            "msevtmgt_text": "SIGNUP"
        }
        this.indskr_customer = indskr_customer ?? null;
        this.indskr_reasons = indskr_reasons ?? null;
        this.eventId = eventId ?? null;
    }
}

