<ion-item (click)="handleCheckboxClick(item,$event)">
  <ion-icon [ngClass]="{'insights arrow-icon': true}"
  (click)="handleRightIconCLick($event, 'endArrow', item)" tappable
  [name]="item.isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'"
  (click)="item.isExpanded = !item.isExpanded;" slot="start"></ion-icon>
  <ion-spinner *ngIf="showSpinner" slot="start"></ion-spinner>
  <ion-label class="selectable-item"> {{ item.label }}</ion-label>
  <ion-checkbox *ngIf='!item.hideCheckbox'
    slot="start"
    [(ngModel)]="item.isChecked"
    [checked]="item.isChecked"
    (ionChange)="toggleItem(item, $event)"
  ></ion-checkbox>
</ion-item>
<ion-item-group class="innerChild" *ngFor="let item of childItems">
<omni-accordion-item [item]="item" [viewData]="viewData" [depth]="depth + 1" (onToggleChildItem)="emitToggleItem(item, $event)" (onToggleChildItemClick)="emitToggleItemClick(item,$event)">
</omni-accordion-item>
</ion-item-group>
<div class="separationLine"></div>