import { Component, ViewChild } from '@angular/core';
import { ScientificActivityService } from '../../../../services/scientific-activity/scientific-activity.service';
import { NavigationService, PageName, ChildNavNames } from '../../../../services/navigation/navigation.service';
import { FooterService, FooterViews } from '../../../../services/footer/footer.service';
import { IonNav } from '@ionic/angular';
import { TrackService, TrackingEventNames } from '../../../../services/logging/tracking.service';
import { UIService } from '../../../../services/ui/ui.service';
import { ScientificActivityDetail } from '../scientific-activity-detail/scientific-activity-detail';
import { ScientificActivityPlan } from '../../../../classes/scientific-activity/scientific-activity.class';
import { ContactOfflineService } from '../../../../services/contact/contact.service';
import { ActivityService } from '../../../../services/activity/activity.service';
import { DeviceService } from '../../../../services/device/device.service';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

@Component({
    selector: 'scientific-activity-page[base-page]',
    templateUrl: 'scientific-activity-page.html',
    styleUrls:['scientific-activity-page.scss']
})
export class ScientificActivityPage {

    @ViewChild('scientificactivityrightpane', {static:true})
    public navCtrl: IonNav;

    constructor(
        private navService: NavigationService,
        private footerService: FooterService,
        public scientificActivityService: ScientificActivityService,
        public trackingService: TrackService,
        public uiService: UIService,
        private contactService:ContactOfflineService,
        private activityService: ActivityService,
        private deviceService: DeviceService,
        private agendaFooterService: AgendaFooterService,
        private faService: FeatureActionsService,
    ) {

    }

    ngOnInit() {
      this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.ScientificActivityNavigation, PageName.ScientificActivityPage,false, {deviceString: this.deviceService.deviceFlags.ios ? 'ios' : 'other'});
      this.footerService.initButtons('');
    }

    ngOnDestroy() {
        this.scientificActivityService.isRightPaneNavActive = false;
    }

    public async closeScientificActivityPage(ev) {
        this.trackingService.tracking('ScientificActivityBack', TrackingEventNames.ACCOUNTPLANNING)
        this.uiService.activeView = '';
        if(this.uiService.activitiesPageTab === 'plans') {
          // this.activityService.selectedActivityAtHome = null;
          if (this.activityService.selectedActivityAtHome) {
            this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
          }
          this.uiService.showNewActivity = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          // this.uiService.activitiesPagePlanTabSelectedPlan = 'scientificPlans'
        }
        this.scientificActivityService.selectedSAP = undefined;
        //  await this.navService.popToRootChildNavPageWithPageTracking();
        this.activityService.selectedActivity = null;
        await this.navService.popChildNavCtrlFromStack(ChildNavNames.ScientificActivityNavigation);
        await this.navService.popToRootWithPageTracking().then(() => {
          if (this.activityService.selectedActivityAtHome) {
            this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
            this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
          } else {
            // Short call feature requires to bring the tab back to short call home
            if (this.faService.isShortCallLauncherEnabled) {
              this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
              this.uiService.setAgendaTab('shortCallHome');
            }
          }
        })
    }

    setSelectedSAP(event: ScientificActivityPlan){
        this.contactService.contactInformation = undefined;
        if(event){
            this.scientificActivityService.isRightPaneNavActive = true;
            this.navService.setChildNavRoot(ScientificActivityDetail, PageName.ScientificActivityDetail, PageName.ActivitiesPageComponent, { selectedSAP: event });
            this.footerService.initButtons(FooterViews.ScientificPlanDetails);
        }else{
            this.scientificActivityService.selectedSAP = undefined;
            this.scientificActivityService.isRightPaneNavActive = false;
            this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.ScientificActivityNavigation, PageName.ScientificActivityPage);
        }
    }
}
