import { Injectable } from "@angular/core";
import { DiskService } from "../../services/disk/disk.service";
import { ActivityService } from "../../services/activity/activity.service";
import { AuthenticationService } from "../../services/authentication.service";
import { OrderActivity } from "../../classes/activity/order.activity.class";
import { PriceList, CountryAndAccount } from "../../classes/order-management/price-list.class";
import { isBefore, isAfter } from "date-fns";
import { Utility } from "../../utility/util";
import { TrackAction } from "../../utility/common-enums";
/**
 * Offline data service for Order Management
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {

    public isRightPaneNavActive:boolean = false;
    public selectedOrder:OrderActivity;
    public priceLists: PriceList[]
    public teamOrder: OrderActivity[] = [];
    public ordersListMode = 'my_orders';

    public isSelectionPane = false;

    constructor(
        private disk: DiskService,
        private activityOfflineService: ActivityService,
        private authenticationService: AuthenticationService,
    ){}

    public findMatchingPriceListForOrder(data:FindPriceListModelData):Promise<any>{
        return new Promise(async (resolve, reject) => {
            if(this.priceLists.length == 0)
            reject('No price list data to search for')
            if(data){
                if(data.priceListId){
                    let pricelist = this.priceLists.find(pl => pl.ID == data.priceListId);
                    if(pricelist){
                        resolve(pricelist);
                        return;
                    }
                }
                if(data.countryId){
                    let foundPriceListByCountryAndAccount:Array<any> = [];
                    outer_loop : for(let i=0;i<this.priceLists.length;i++) {
                        let priceList = this.priceLists[i];
                        if( priceList.countryAndAccountPairs && priceList.countryAndAccountPairs.length > 0){
                          for(let j=0;j<priceList.countryAndAccountPairs.length;j++) {
                            let matchedPair:CountryAndAccount = priceList.countryAndAccountPairs[j]
                            if(matchedPair.countryID != data.countryId){
                              continue;
                            }
                            else if(matchedPair.accountID && data.accountId != matchedPair.accountID){
                              continue;
                            }
                            if(matchedPair){
                              //matched country and account, lets check the date criteria
                                if(data.accountId == matchedPair.accountID){
                                  if(priceList.startDate && priceList.endDate){
                                    if(isBefore(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.endDate),true))
                                        && isAfter(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.startDate),false))){
                                        let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                        pricelistcopy.priorityIndex = 1;
                                        foundPriceListByCountryAndAccount.push(pricelistcopy);
                                        break outer_loop;
                                    }
                                  }
                                  else if(priceList.startDate || priceList.endDate){
                                      if(priceList.endDate && !priceList.startDate && isBefore(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.endDate),true))){
                                        let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                        pricelistcopy.priorityIndex = 2;
                                        foundPriceListByCountryAndAccount.push(pricelistcopy);
                                      }
                                      else if(priceList.startDate && !priceList.endDate && isAfter(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.startDate),false))){
                                        let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                        pricelistcopy.priorityIndex = 2;
                                        foundPriceListByCountryAndAccount.push(pricelistcopy);
                                      }
                                  }
                                  else{
                                      let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                      pricelistcopy.priorityIndex = 3;
                                      foundPriceListByCountryAndAccount.push(pricelistcopy);
                                  }
                                }
                                //matched just country, lets check the date criteria
                                else{
                                  if(priceList.startDate && priceList.endDate){
                                    if(isBefore(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.endDate),true))
                                        && isAfter(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.startDate),false))){
                                          let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                          pricelistcopy.priorityIndex = 4;
                                        foundPriceListByCountryAndAccount.push(pricelistcopy);
                                    }
                                  }
                                  else if(priceList.startDate || priceList.endDate){
                                      if(priceList.endDate && !priceList.startDate && isBefore(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.endDate),true))){
                                        let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                        pricelistcopy.priorityIndex = 5;
                                        foundPriceListByCountryAndAccount.push(pricelistcopy);
                                      }
                                      else if(priceList.startDate && !priceList.endDate && isAfter(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(priceList.startDate),false))){
                                        let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                        pricelistcopy.priorityIndex = 5;
                                        foundPriceListByCountryAndAccount.push(pricelistcopy);
                                      }
                                  }
                                  else{
                                    let pricelistcopy = JSON.parse(JSON.stringify(priceList))
                                    pricelistcopy.priorityIndex = 6;
                                    foundPriceListByCountryAndAccount.push(pricelistcopy);
                                  }
                                }
                            }
                          }
                        }
                    }
                    if(foundPriceListByCountryAndAccount.length > 1){
                      foundPriceListByCountryAndAccount.sort((a,b)=>{
                        return a.priorityIndex >b.priorityIndex? 1:-1
                      })
                      resolve(foundPriceListByCountryAndAccount[0]);
                    }
                    else if(foundPriceListByCountryAndAccount.length == 1){
                      resolve(foundPriceListByCountryAndAccount[0]);
                    }
                    else{
                        reject('No price list found for the selected country');
                    }
                }else{
                    reject('Select a country to find matching price list');
                }
            }else{
                reject('No order to find matching price list');
            }
        });
    }

}

export interface FindPriceListModelData {
  countryId:string;
  accountId:string;
  priceListId:string;
}
