<ion-header>
  <ind-page-title [viewData]="allocationShipmentDetailPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content *ngIf="allocFeatureService?.selectedShipment">
  <ion-list class='details-list' *ngIf="!isAdjustment">
      <ind-section-header [viewData]='sectionTitle'></ind-section-header>
      <ind-form-field [viewData]="statusFormField"></ind-form-field>
      <ind-form-field *ngIf="isShipmentTypeReceived" [viewData]="productField"></ind-form-field>
      <ind-form-field [viewData]="transferNumberField"></ind-form-field>
      <ind-form-field [viewData]="shipmentDateField"></ind-form-field>
      <ind-form-field *ngIf="!isAllocationTransfer || isAllocationTransfer && isShipmentTypeReceived" [viewData]="lotNumberField"></ind-form-field>
      <ind-form-field *ngIf="isAllocationTransfer && isShipmentTypeSent" [viewData]="userField"></ind-form-field>
      <ind-form-field *ngIf="isAllocationTransfer && isShipmentTypeSent" [viewData]="productField "></ind-form-field>
      <ind-form-field [viewData]="qtyShippedField "></ind-form-field>
      <ind-form-field *ngIf="isShipmentTypeReceived && isShipmentStatusAcknowledged" [viewData]="qtyField"></ind-form-field>
      <ng-container>
        <ion-item *ngIf="!isAllocationTransfer && isShipmentStatusShipped" class="'form-field-container'"
            [ngClass]="isClickedQty && allocFeatureService.selectedShipment.indskr_shipmentstatus != shipmentStatus.Acknowledged && (quantityReceivedInputValue == 0 || quantityReceivedInputValue == null) ? 'form-field-invalid-input-container' : 'qty-bottom-border'">
            <ion-label class='form-field-header-label'>
                <p class='form-field-label-text'>
                    <ion-text>{{'QUANTITY_RECEIVED' | translate}}</ion-text>
                    <ion-icon class='mandatory-icon' *ngIf="allocFeatureService.selectedShipment.indskr_shipmentstatus != shipmentStatus.Acknowledged"></ion-icon>
                </p>
                <p class="form-field-input">
                    <input [value]="quantityReceivedInputValue" [(ngModel)]="quantityReceivedInputValue" type="number"
                        placeholder="0" pattern="[0-9]*" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57" text-right style="margin-right:-0.5%;" #focusInput (focus)="onInputFocus(focusInput)" (focusout)="onFocusOut($event)" (input)="checkInputReceivedValue($event)"
                        [readonly]="allocFeatureService.selectedShipment.indskr_shipmentstatus === shipmentStatus.Acknowledged">
                </p>
            </ion-label>
            <ion-icon *ngIf="allocFeatureService.selectedShipment.indskr_shipmentstatus != shipmentStatus.Acknowledged" name="chevron-back-outline" class="ion-float-right arrow-icon"></ion-icon>
        </ion-item>
        <ion-item class="error-message-container" *ngIf="isClickedQty && allocFeatureService.selectedShipment.indskr_shipmentstatus != shipmentStatus.Acknowledged && (quantityReceivedInputValue == 0 || quantityReceivedInputValue == null)">
          <ion-label class="error-message-text">{{errorMessageMandatoryField}}</ion-label>
        </ion-item>
      </ng-container>
      <ind-form-field *ngIf="isAllocationTransfer && isShipmentTypeReceived && !isShipmentStatusAcknowledged" [viewData]="qtyField"></ind-form-field>
      <ind-form-field *ngIf="!isAllocationTransfer" [viewData]="getShipmentReasonField()"></ind-form-field>
      <ind-form-field *ngIf="reasonForLossInputValue===548910002 || allocFeatureService.selectedShipment['indskr_reasonforloss'] == 548910002" [viewData]="getCommentFormField()"></ind-form-field>
      <ind-form-field *ngIf="isAllocationTransfer && isShipmentTypeReceived && isShipmentStatusAcknowledged && $any(allocFeatureService.selectedShipment).indskr_dateacknowledged" [viewData]="receiptedDateField "></ind-form-field>
      <ind-form-field *ngIf="isAllocationTransfer" [viewData]="transferReasonField "></ind-form-field>
      <ind-form-field *ngIf="allocFeatureService.selectedShipment['indskr_reasonfortransfer'] == 548910000" [viewData]="transferCommentFormField"></ind-form-field>

      <ind-form-field *ngIf="!isAllocationTransfer && isShipmentTypeReceived && isShipmentStatusAcknowledged && $any(allocFeatureService.selectedShipment).indskr_dateacknowledged" [viewData]="receiptedDateField "></ind-form-field>
  </ion-list>
  <ion-list class='details-list' *ngIf="isAdjustment">
      <ind-section-header [viewData]='sectionTitle'></ind-section-header>
      <ind-form-field [viewData]="statusFormField"></ind-form-field>
      <ind-form-field [viewData]="transferNumberField"></ind-form-field>
      <ind-form-field [viewData]="adjustmentDateField"></ind-form-field>
      <ind-form-field [viewData]="productField"></ind-form-field>
      <ind-form-field [viewData]="lotNumberField"></ind-form-field>
      <ind-form-field [viewData]="qtyAdjustedFields"></ind-form-field>
      <ind-form-field [viewData]="adjustedByFormField"></ind-form-field>
      <ind-form-field [viewData]="adjustmentReasonFormField"></ind-form-field>
      <ind-form-field *ngIf="allocFeatureService.selectedShipment['indskr_reasonforadjustment'] == 548910004" [viewData]="adjustmentCommentFormField"></ind-form-field>
      <ind-form-field *ngIf="allocFeatureService.selectedShipment?.indskr_shipmentstatus === adjustmentStatus.Approved" [viewData]="approvedByFormField"></ind-form-field>
      <ind-form-field *ngIf="allocFeatureService.selectedShipment?.indskr_shipmentstatus === adjustmentStatus.Approved" [viewData]="approvedOnFormField"></ind-form-field>
  </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'ShipmentDetails'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->