<ion-header>
    <!-- <ion-toolbar class='page-title'>
        <ion-buttons slot="start">
            <ion-button (click)="closepage(true)">
                {{ 'CANCEL' | translate}}
            </ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{ 'CHOOSE_ADDRESS' | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closepage(false)" [disabled]="!selectedaddress">
                {{ 'DONE' | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
        <ion-searchbar [(ngModel)]="query" placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}" (ionClear)="clearSearch($event)" (search)="searchAddressGlobally($event)"></ion-searchbar>
        <ion-buttons slot="primary">
            <ion-button icon-only (click)="searchAddressGlobally($event)" color="dark">
                <ion-icon name="md-locate"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar> -->
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="query" (onControlClick)="onPageTitleControlClick($event)"
        (onSearch)="searchAddressGlobally($event)" (onSearchClear)="clearSearch($event)"></ind-header-left>
</ion-header>
<ion-content>
    <ng-template [ngIf]="!noResult" [ngIfElse]="nodata">
        <ion-item-group>
            <ion-item-divider>
                {{ 'SEARCH_RESULTS' | translate}}
            </ion-item-divider>
            <ion-list>
                <ion-radio-group (ionChange)="initHeader()" [(ngModel)]="selectedaddress">
                    <ion-item *ngFor="let address of contactAddress">
                        <ion-label>{{address.compositeAdd}}</ion-label>
                        <ion-radio slot="start" [value]="address"></ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-list>
        </ion-item-group>
    </ng-template>
    <ng-template #nodata>
            <ion-item-group>
                <ion-item-divider>
                    {{ 'SEARCH_RESULTS' | translate}}
                </ion-item-divider>
                <ion-list>
                    <ion-item>
                        <ion-label>{{ 'NO_DATA' | translate}}...</ion-label>
                    </ion-item>
                </ion-list>
            </ion-item-group>
        </ng-template>

    <!-- </ng-template> -->
    <!-- <ion-item-group>
        <ion-item>
            <ion-label fixed>Street 1</ion-label>
            <ion-input type="text" name="street1" [(ngModel)]=""></ion-input>
        </ion-item>
        <ion-item>
            <ion-label fixed>Street 2</ion-label>
            <ion-input type="text" name="street2" [(ngModel)]=""></ion-input>
        </ion-item>
        <ion-item>
            <ion-label fixed>Street 3</ion-label>
            <ion-input type="text" name="street3" [(ngModel)]=""></ion-input>
        </ion-item>
    </ion-item-group> -->
</ion-content>
<io-spinner [displayText]="'please wait...'" *ngIf="loading"></io-spinner>
