import { PipeTransform, Pipe } from '@angular/core';
import { CallPlanOfflineService } from '../services/call-plan/call-plan.offline.service';
import { RepCallPlanState } from '../classes/call-plan/rep-call-plan.class';
import { AuthenticationService } from '../services/authentication.service';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'repCallPlanState',
    pure: true
})
export class RepCallPlanStatePipe implements PipeTransform {
    constructor(
        private callPlanService: CallPlanOfflineService,
        public translate:TranslateService
    ) {

    }

    /**
     *  This is the pipe for transforming the raw stateCode into the enum for repCallPlan's in repCallPlanDetails
     *
     * @param {Array<any>} items
     * @returns
     * @memberof RepCallPlanFilterPipe
     */
    transform(stateCode: number) {
        switch (stateCode) {
            case RepCallPlanState.Approved:
            let approvedText = this.translate.instant('APPROVED');
            if(this.translate.currentLang == 'es') {
              approvedText = this.translate.instant('APPROVED_CALL_PLAN');
            }
            return approvedText;

            case RepCallPlanState.ForReview:
            return this.translate.instant('FOR_REVIEW');

            case RepCallPlanState.Locked:
            return this.translate.instant('LOCKED');

            case RepCallPlanState.New:
            return this.translate.instant('NEW');

            case RepCallPlanState.Open:
            return this.translate.instant('OPEN');
        }
    }
}
