import { BehaviorSubject } from 'rxjs';
import { FollowUpActivityDataService } from './../../data-services/follow-up-activity/follow-up-activity.data.service';
import { Injectable } from '@angular/core';
import { Activity, ActivityType } from '@omni/classes/activity/activity.class';
import { ActivityService } from '../activity/activity.service';
import { format, isPast, isWithinRange } from 'date-fns';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication.service';
import { AllocationAdjustService } from '../sample/allocation-adjust.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { TimeOffService } from '../time-off/time-off.service';
import { TimeOffStatus } from '@omni/classes/activity/timeoff.class';
import { AllocationShipmentService } from '../sample/allocation-shipment.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService } from '../disk/disk.service';
import _ from 'lodash';
import { TrackingEventNames, TrackService } from '../logging/tracking.service';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';

@Injectable({
  providedIn: 'root'
})
export class AgendaPlanListService {

  allActivities: Array<Activity> = []
  allPastActivities: Array<Activity> = []
  allFutureActivities: Array<Activity> = []
  allInCompleteActivities: Array<Activity> = [];
  triggerPendingActionDataRefresh$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private  itemExpandHeight: number = 25;
  public lastOpenActivityType: ActivityType = ActivityType.AllActivity;
  public lastOpenActivityID: String = "";

  pinnedItems: PinnedDataModel[] = [];


  constructor(private activityService: ActivityService,
              private authService: AuthenticationService,
              private timeOffService: TimeOffService,
              private disk: DiskService,
              private trackingService: TrackService) {
    this.allActivities = this.activityService.activities;
    // if (!this.adjustmentService.teamAdjustments) { this.adjustmentService.loadAndMapTeamAdjustments() }
    this.fetchAllPinnedItems().then(localData => {
      this.pinnedItems = localData ? localData.raw : [];
    });
  }

  public resetLastOpenActivity() {
    this.lastOpenActivityType = ActivityType.AllActivity;
    this.lastOpenActivityID = "";
  }

  public async convertPinnedItemsIds() {

    // an activity which is created in offline have 'offline' keyword in their id which is stored for the pineed items db
    // on online and successful sync, we have to change the ids of the pinned activities which is created
    // offline and have offline ids and update the same.

    if (!_.isEmpty(this.pinnedItems)) {

      let hasChanges = false;
      this.pinnedItems.forEach(pinnedItem => {
        const pinnedItemId = pinnedItem.id;
        const activityType = pinnedItem.activityType;
        if (pinnedItemId.includes('offline')) {
          let activity = this.activityService.getActivityByOfflineIdForPinnedItems(pinnedItemId, activityType);
          if (activity) {
            pinnedItem.id = activity.ID;
            hasChanges = true;
          }
        }
      });

      if (hasChanges) {
        this.savePinnedItemsToDB(this.pinnedItems);
      }

    }
  }

  getPendingFollowups() {
    return this.activityService.getAllPendingFollowupActions();
  }

  getPendingAdjustments() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST)) {
      // if you have feature action for team adjustments and you have pending appoval items return all adjustments having status in review.
      // return this.adjustmentService.teamAdjustments.filter(adjustment => { return adjustment.statusCode === AdjustmentStatus.InReview });
    }
    return []
  }

  getPendingShipments() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)) {
      // if you have feature action for team adjustments and you have pending appoval items return all adjustments having status in review.
      // return this.shipmentService.allocationShipments.filter(shipmment => { return shipmment.statusCode === ShipmentStatus.Shipped});
    }
    return []
  }

  getPendingTimeoffs() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TEAM_REQUESTS)) {
      return this.timeOffService.teamTot.filter(timeoff => { return timeoff.statuscode === TimeOffStatus.InReview });
    }
    return []
  }

  getPendingCallsPlans(){

  }

  getPendingSalesOrder(){
    return this.activityService.getPendingSalesOrders();
  }

// Filter the Incomplete Activities

  filterIncompleteActivities() {

    this.allPastActivities = this.activityService.getAllPastActivities();

    // Activities having the grace period will be validated with the activities with today - grace peiod
    // No other activities, for  which we have grace period, should fall beyond teh grace period.
    // let meetingDateAdjustedWithGracePeriod = new Date(new Date((new Date().setDate(new Date().getDate() - this.authService.user.maxMeetingStartDays))).setHours(0, 0, 0, 0));

    let dateAdjustedWithOfflineDataDuration = new Date(new Date((new Date().setDate(new Date().getDate() - this.authService.user.offlineDataDuration))).setHours(0, 0, 0, 0));
    let todayStart = new Date(new Date().setHours(0,0,0,0));

    // 3 - Meeting completed, 4 - Meeting Canceled
    // Follow up Completed 5, 6 Canceled
    // Surgery Order - 1 : Open
    // Phone Call - 1 : Draft
    // Messages - 1: Draft, 8: Failed
    // Time Off
    // Allocation Order
    // Customer Inquiry
    // sales order : just checking draft status
    this.allInCompleteActivities = this.allPastActivities.filter(activity => { return this.isValidated(activity, dateAdjustedWithOfflineDataDuration, todayStart) } );
    return this.allInCompleteActivities;
  }

  public isValidated(activity: Activity, dateAdjustedWithOfflineDataDuration: Date, todayStart: Date): boolean {
    return (activity.type === ActivityType.Appointment && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && (activity.state === 0 || activity.state === 3)) ||
          (activity.type === ActivityType.FollowUp && (activity.status != 5 && activity.status != 6) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.SurgeryOrder && (activity.status === 1 || activity.status === 2) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.PhoneCall && (activity.state === 0) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.Email && (activity.status === 1) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.CaseIntake && ((activity as CaseActivity)._case_status_id === 0) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.Sample && (activity.status === 1) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.TimeOffRequest && (activity.status === 100000000) && isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart) && todayStart > activity.scheduledStart) ||
          (activity.type === ActivityType.Order && (activity.status === 1))
  }

  private async fetchAllPinnedItems(): Promise<{ raw: PinnedDataModel[], _rev?: string, _id?: string }> {
    const key: string = DB_KEY_PREFIXES.AGENDA_PINNED_ITEMS;
    let shouldUpsert: boolean = false;
    let localDbData: { raw: PinnedDataModel[], _rev?: string, _id?: string } = await this.disk.retrieve(key, true);

    return localDbData;
  }

  private async savePinnedItemsToDB(raw: PinnedDataModel[]) {

    let localData = await this.fetchAllPinnedItems();

    if (!localData) {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.AGENDA_PINNED_ITEMS, (doc) => ({ raw }));
      return;
    }
    localData.raw = raw;
    localData._id = localData._id;
    localData._rev = localData._rev;
    await this.disk.updateDocWithIdAndRev(localData, false, true);
  }


  // Save the Pinned items
  async savePinnedItem(id: string, activityType: string, type: PlanTabActivityType, shouldSave = true) {

    let index  = this.pinnedItems.findIndex(item => item.id === id);

    if (this.pinnedItems && index === -1) {
      this.pinnedItems.push({ id, activityType, type });
    }
    if (shouldSave)
      await this.savePinnedItemsToDB(this.pinnedItems);
  }

  async unpinItem(id: string) {

    let pinnedItemIndex = this.pinnedItems.findIndex(item => item.id === id);

    if (pinnedItemIndex > -1) {
      this.pinnedItems.splice(pinnedItemIndex, 1);
    }

    await this.savePinnedItemsToDB(this.pinnedItems)
  }


  track(name: string) {
          this.trackingService.tracking(name, TrackingEventNames.PLAN_TAB_TO_DO)
  }
}


export enum PlanTabActivityType{
  INCOMPLETE_ACTIVTIES = 'incomplete_activities',
  PENDING_ACTIONS = 'pending_actions'
}

export interface PinnedDataModel {
  id: string;
  activityType: string;
  type: PlanTabActivityType
}
