import { Component, Input } from "@angular/core";
import { Interests, Journeys, Relationships, XpCustomer, InterestInsight, OptionSet, RelationshipInsight, User, JourneyInsight, Product, JourneyPlan, CentralPlan, RelationshipContact, CustomerJourney, CustomerInterest, CustomerRelationship, InterestSources, RelationshipSources, InterestInsightsPlus, RelationshipInsightsPlus } from "../../../classes/xperiences/trending.customer.class";
import { MultiSelectPopover } from "../../multi-select-popover/multi-select-popover";
import { PopoverController, NavParams } from "@ionic/angular";
import { XperiencesService } from "../../../services/xperiences/xperiences.service";
import {default as _, cloneDeep} from 'lodash';
import { PopoverComponent } from "../../popover/popover";
import { UIService } from "../../../services/ui/ui.service";
import { DeviceService } from "../../../services/device/device.service";
import { ActivityType, Activity } from "../../../classes/activity/activity.class";
import { ActivityService } from "../../../services/activity/activity.service";
import { ActivityDataService } from "../../../data-services/activity/activity.service";
import { FooterService, FooterViews } from "../../../services/footer/footer.service";
import { SamplingDetailsViewMode, SampleService } from "../../../services/sample/sample.service";
import { EmailActivity, EmailViewType } from "../../../classes/activity/email.activity.class";
import { CaseActivity } from "../../../classes/case-intake/case-activity.class";
import { AccesingMode, CaseManagementService } from "../../../services/case-management/case-management.service";
import { EmailService } from "../../../services/email-templates/email.service";
import { CaseManagementDataService } from "../../../data-services/case-management/case-management.data.service";
import { AppointmentActivity } from "../../../classes/activity/appointment.activity.class";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { ChannelType } from "../../../classes/consent/channel.class";
import { Contact, Specialty } from "../../../classes/contact/contact.class";
import { ContactOfflineService } from "../../../services/contact/contact.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { PageName, NavigationService } from "../../../services/navigation/navigation.service";
import { MarketingEmailDetailsComponent } from "../../email/marketing-email-details/marketing-email-details";
import { TrackService } from "../../../services/logging/tracking.service";
import { TrackingEventNames } from "../../../services/logging/tracking.service";
import { CustomerJourneyComponent } from '../customer-insight/customer-journey/customer-journey';
import { CustomerInterestComponent } from "../customer-insight/customer-interest/customer-interest";
import { CustomerRelationshipComponent } from "../customer-insight/customer-relationship/customer-relationship";
import { SelectListData } from "../../../models/select-list-data-model";
import { CustomerEventsService } from '../../../services/customer-event/customer-events.service';
import { EventRegistration } from "../../../classes/customer-event/customer-event.class";
import { SampleActivity } from "../../../classes/activity/sample.activity.class";
import { CaseManagementDetailComponent } from "@omni/components/case-management/case-management-detail/case-management-detail";
import { ActivitiesDetailsPaneComponent } from "@omni/components/activity/activities-details-pane/activities-details-pane";
import { EmailActivityDetailComponent } from "@omni/components/activity/email-activity-detail/email-activity-detail";
import { NewSampleActivityComponent } from "@omni/components/activity/new-sample-activity/new-sample-activity";

@Component({
  selector: 'xperience-plan-insight',
  templateUrl: 'xperience-plan-insight.html',
  styleUrls:['xperience-plan-insight.scss']
})
export class XperiencePlanInsightComponent {

  @Input("x_type")
  public x_type: string;

  @Input("source")
  public source: any;

  weekExpand: boolean = true;
  monthExpand: boolean = false;

  planItemsCount: number = 0;
  selectedSortOption: any;

  planItemList: any[] = [
    { text: this.translate.instant('XPERIENCES_TEAM_PLANS'), value: "teamplan", icon: "assets/imgs/xp_teamplan.svg" },
    { text: this.translate.instant('XPERIENCES_CENTRAL_PLANS'), value: "centralplan", icon: "assets/imgs/xp_centralplan.svg" },
    { text: this.translate.instant('XPERIENCES_GENEE_PREDICTIONS'), value: "prediction", icon: "assets/imgs/xp_genee_active.svg" }];
  journeyInsightTypes: any[] = [
    { text: this.translate.instant('ALL_ACTIVITIES'), value: "All" },
    { text: this.translate.instant('MEETINGS'), value: "appointment" },
    { text: this.translate.instant('MESSAGES'), value: "email" },
    { text: this.translate.instant('TIME_OFF'), value: "TimeOff" },
    { text: this.translate.instant('XPERINECES_ALLOCATIONS'), value: "indskr_sampledrop" },
    { text: this.translate.instant('CUSTOMERINQUIRIES'), value: "medical_inquiry" },
  ];
  interestInsightSortOptions: any[] = [
    { text: this.translate.instant('XPERIENCES_SORT_HIGH_TO_LOW'), value: "high" },
    { text: this.translate.instant('XPERIENCES_SORT_LOW_TO_HIGH'), value: "low" },
  ];
  relationInsightSortOptions: any[] = [
    { text: this.translate.instant('XPERIENCES_SORT_DEGREE_1_TO_3'), value: "asc" },
    { text: this.translate.instant('XPERIENCES_SORT_DEGREE_3_TO_1'), value: "des" },
  ];
  productFilterOptions: any[] = [
    { text: this.translate.instant('XPERIENCES_FILTER_ALL_PRODUCTS'), value: "All" },
    { text: this.translate.instant('XPERIENCES_FILTER_COMPETITOR_PRODUCT'), value: "Competitor Product", disableLabel: false },
  ];
  plansFilterPopoverData: { text: string; value: string; displayIcon?: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  journeyInsightsFilterPopoverData: { text: string; value: string; displayIcon?: string; multiselect?: boolean; selectedValues?: string[]; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  interestInsightsFilterPopoverData: { text: string; value: string; displayIcon?: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  relationInsightsFilterPopoverData: { text: string; value: string; displayIcon?: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  journeyInsightsFilterValue = 'All';
  insightsFilterValue = 'All';
  productFilterValue = 'All';
  activitiesFilterValueList: any[] = ["All"];
  event : EventRegistration;
  private visibleCount: number = 0;
  public visibleInsights: any[] = [];

  constructor(
    private popoverCtrl: PopoverController,
    public xperiencesService: XperiencesService,
    private navParams: NavParams,
    private uiService: UIService,
    public device: DeviceService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public sampleService: SampleService,
    private emailService: EmailService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private contactService: ContactOfflineService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    private trackingService: TrackService,
    public customerEventsService: CustomerEventsService,

  ) { }

  ngOnInit() {
    if (this.source && (this.source.plans || this.source.predictions)) {
      this.source.plans = _.uniq(_.concat(this.source.plans, this.source.predictions));
      this.source.plans.forEach((plan) => {
        if(plan.activitytypecode === 'event' && plan.type === 'centralplan') {
          plan.event = this.getContactEventsDetails(this.xperiencesService.selectedXpContact.contactid, plan.id);
        }
        if (plan.activitytypecode === 'journey_insight') {
          plan.translatedTitle = this.getJourneyTouchPointTitle(plan.title);
          plan.productsText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.xperiencesService.getUserNamesText(plan.users);
        } else {
          plan.productsText = this.xperiencesService.getProductsText(plan.products);
          plan.translatedTitle = this.constructPlansTitle(plan.type, plan.activitytypecode, plan.channeltype, plan.users);
          plan.relationshipContactText = this.getRelationshipContactText(plan.contacts);
        }

      });
    }

    switch (this.x_type) {
      case "journey": {
        if (this.source.journey && this.source.journey.insights) {
          this.source.journey.insights.forEach((insight) => {
            insight.productsText = this.xperiencesService.getProductsText(insight.products);
            if (insight.activitytypecode === 'journey_insight') {
              insight.translatedTitle = this.getJourneyTouchPointTitle(insight.title);
              insight.journeyInsightsText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.xperiencesService.getUserNamesText(insight.users);
            } else {
              //Change in the future, if required: Temp fix for Bug: OMNI-16300
              insight.translatedTitle = this.constructInsightTitle(insight.activitytypecode, insight.users, insight.channeltype, insight.title);
            }
          });
        }
        this.source['xpInsightsFilterText'] = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
        break;
      }

      case "interest": {
        if (this.source.interests && this.source.interests.insights) {
          (this.source.interests.insights as InterestInsight[]).forEach((insight) => {
            insight.sources = this.getInterstInsightsSources(insight);
            insight.latestInsight = ([...insight.sources.insightsPlusByLoggedInUser, ...insight.sources.otherInsightsPlus] as InterestInsightsPlus[])
              .sort((a, b) => (b as InterestInsightsPlus).date - (a as InterestInsightsPlus).date)[0];
            insight.sourcesText = this.getInsightsPlusSourcesText(insight.sources);
          });
        }
        this.source['xpInsightsFilterText'] = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_INTEREST_INSIGHTS')
        break;
      }

      case "relationship": {
        if (this.source.relationships && this.source.relationships.insights) {
          this.source.relationships.insights.forEach((insight) => {
            insight.sources = this.getRelationshipInsightsSources(insight);
            insight.latestInsight = ([...insight.sources.insightsPlusByLoggedInUser, ...insight.sources.otherInsightsPlus] as RelationshipInsightsPlus[])
              .sort((a, b) => (b as RelationshipInsightsPlus).date - (a as RelationshipInsightsPlus).date)[0];
            insight.sourcesText = this.getInsightsPlusSourcesText(insight.sources);
          });
        }
        this.source['xpInsightsFilterText'] = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS')
        break;
      }
    }

    this.plansFilterPopoverData = [
      {
        text: "",
        value: "All",
        items: [{ text: 'Plans and Predictions', value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.xperiencesService.xpPlanFilterText = item.value;
        }
      },
      {
        text: "",
        value: "",
        items: this.planItemList.map(b => ({ text: b.text, value: b.value, displayIcon: b.icon })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.trackFilterSelection(selectedItem.value);
          this.xperiencesService.xpPlanFilterText = item.value;
          if (this.xperiencesService.xpPlanFilterText == '') {
            itemRef.parent.items[0].value = 'All';
            this.xperiencesService.xpPlanFilterText = 'All';
          }
        }
      }
    ];
    this.journeyInsightsFilterPopoverData = [
      {
        text: this.translate.instant('XPERIENCES_FILTER_JOURNEYS'),
        value: 'All',
        items: [
          { text: this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS'), value: 'All' },
          { text: this.translate.instant('XPERIENCES_FILTER_INSIGHTS_PLUS_JOURNEYS'), value: 'journey_insight' }
        ],
        handler: (selectedItem, item, itemRef) => {
          item.value = selectedItem.value;
          if(selectedItem.value === item.items[1].value &&
            (itemRef.parent.item.items[2].value ===  itemRef.parent.item.items[2].items[1].value)) {
            this.xperiencesService.xpInsightsFilterText = selectedItem.text + ' ' + this.translate.instant('WITH') + ' ' + itemRef.parent.item.items[2].items[1].text;
          } else {
              this.xperiencesService.xpInsightsFilterText = selectedItem.text;
          }
          // hiding the filter lables based on the selected value
          if (selectedItem.value === item.items[1].value) {
            itemRef.parent.items[2].items[1].disableLabel= false;
            itemRef.parent.items[1].items.forEach((item, index) => {
              if(index > 0) {
                item.disableLabel = true;
                item.checked = false;
              }
            });
            itemRef.parent.items[1].value = 'All';
          } else {
            itemRef.parent.items[2].items[1].disableLabel = true;
            itemRef.parent.items[2].items[1].checked = false;
            itemRef.parent.items[1].items.forEach((item,index) => {
              if(index > 0) {
                item.disableLabel = false;
              }
            });
            itemRef.parent.items[2].value = 'All';
          }
          this.journeyInsightsFilterValue = selectedItem.value;
          this.updateVisibleRecords();
        },
      },
      {
        text: this.translate.instant('ACTIVITIES'),
        value: '',
        multiselect: true,
        items: this.journeyInsightTypes.map(b => ({ text: b.text, value: b.value })),
        handler: (selectedItem, item, itemRef) => {
          if (selectedItem.value === item.items[0].value) {
            itemRef.selectedValues = [item.items[0].value];
          } else {
            let index = itemRef.selectedValues.indexOf(item.items[0].value);
            if (index > -1) {
              itemRef.selectedValues.splice(index,1);
            }
          }
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          if(itemRef.selectedValues.length === 0) {
            itemRef.selectedValues = [item.items[0].value];
            this.xperiencesService.xpInsightsFilterText = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
          }
          this.activitiesFilterValueList = itemRef.selectedValues;
          let labelText = '';
          if(this.activitiesFilterValueList.length > 1) {
            labelText = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
          } else {
            if (this.activitiesFilterValueList.length === 1 && this.activitiesFilterValueList[0] === 'All') {
              if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[0].value) {
                labelText = itemRef.parent.item.items[0].items[0].text;
              } else if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[1].value) {
                labelText = itemRef.parent.item.items[0].items[1].text;
              }
            } else {
              let activityType = this.getJourneyInsightsFilterLabel(this.activitiesFilterValueList[0]);
              labelText = (this.translate.instant('ALL') + ' ' + activityType + ' ' +
                                                              this.translate.instant('ACTIVITIES'));
            }
          }
          this.xperiencesService.xpInsightsFilterText = labelText;
          this.updateVisibleRecords();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: 'All',
        items: this.productFilterOptions.map(b => ({ text: b.text, value: b.value, disableLabel: b.disableLabel })),
        handler: (selectedItem, item, itemRef) => {
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          item.value = selectedItem.value;
          this.productFilterValue = item.value;
          if (selectedItem.value === item.items[1].value) {
            this.xperiencesService.xpInsightsFilterText = this.translate.instant('XPERIENCES_FILTER_INSIGHTS_PLUS_JOURNEYS') + ' ' + this.translate.instant('WITH') + ' ' + selectedItem.text;
          } else {
            if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[0].value) {
              this.xperiencesService.xpInsightsFilterText = itemRef.parent.item.items[0].items[0].text;
            } else if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[1].value) {
              this.xperiencesService.xpInsightsFilterText = itemRef.parent.item.items[0].items[1].text;
            }
          }
          this.updateVisibleRecords();
        }
      }
    ];
    this.journeyInsightsFilterPopoverData[1].selectedValues =  ['All'];

    this.interestInsightsFilterPopoverData = [
      {
        text: "",
        value: "All",
        items: [
          { text: this.translate.instant('XPERIENCES_ALL_INTERESTS'), value: 'All' },
          { text: this.translate.instant('XPERIENCES_INTEREST_PLUS_INSIGHTS'), value: 'interest_insights' },
                ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          if (selectedItem.value === 'All') {
            this.xperiencesService.xpInsightsFilterText = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_INTEREST_INSIGHTS')
          } else {
            this.xperiencesService.xpInsightsFilterText = selectedItem.text;
          }
          this.insightsFilterValue = selectedItem.value;
          this.updateVisibleRecords();
        }
      },
      {
        text: this.translate.instant('SORT_BY'),
        value: "",
        items: this.interestInsightSortOptions.map(b => ({ text: b.text, value: b.value })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.xperiencesService.xpInsightsFilterText = item.text + " " + selectedItem.text;
          this.selectedSortOption = item.value;
          if (this.xperiencesService.xpInsightsFilterText == '' || !this.selectedSortOption) {
            itemRef.parent.items[0].value = 'All';
            this.xperiencesService.xpInsightsFilterText = this.translate.instant('XPERIENCES_ALL_INTERESTS');
          }
          this.insightsFilterValue = selectedItem.value;
          this.updateVisibleRecords();
        }
      }
    ];
    this.relationInsightsFilterPopoverData = [
      {
        text: "",
        value: "All",
        items: [
          { text: this.translate.instant('XPERIENCES_ALL_RELATIONSHIPS'), value: 'All' },
          { text: this.translate.instant('XPERIENCES_INTEREST_PLUS_RELATIONSHIPS'), value: 'relationship_insights' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          if (selectedItem.value === 'All') {
            this.xperiencesService.xpInsightsFilterText = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS')
          } else {
            this.xperiencesService.xpInsightsFilterText = selectedItem.text;
          }
          this.insightsFilterValue = selectedItem.value;
          this.updateVisibleRecords();
        }
      },
      {
        text: this.translate.instant('SORT_BY'),
        value: "",
        items: this.relationInsightSortOptions.map(b => ({ text: b.text, value: b.value })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.xperiencesService.xpInsightsFilterText = item.text + " " + selectedItem.text;
          this.selectedSortOption = item.value;
          if (this.xperiencesService.xpInsightsFilterText == '' || !this.selectedSortOption) {
            itemRef.parent.items[0].value = 'All';
            this.xperiencesService.xpInsightsFilterText = this.translate.instant('XPERIENCES_ALL_RELATIONSHIPS');
          }
          this.insightsFilterValue = selectedItem.value;
          this.updateVisibleRecords();
        }
      }
    ];
    // if (!this.plans.week.length && this.plans.month.length) this.monthExpand = true;
    this.updateVisibleRecords();
  }

  getTitle(option: string) {
    let label: string;
    switch (option) {
      case "All":
        label = String(this.translate.instant('XPERIENCES_HEADER_PLANS_AND_PREDICTIONS')).toUpperCase();
        break;
      case "prediction":
        label = 'PREDICTIONS';
        break;
      case "teamplan":
        label = String(this.translate.instant('XPERIENCES_TEAM_PLANS')).toUpperCase();
        break;
      case "centralplan":
        label = String(this.translate.instant('XPERIENCES_CENTRAL_PLANS')).toUpperCase();
        break;
      default:
        label = String(this.translate.instant('XPERIENCES_HEADER_PLANS_AND_PREDICTIONS')).toUpperCase();
        break;
    }
    return label;
  }

  async openPlanInsightFilterPopup(e) {
    this.trackFilterClick();
    switch (e) {
      case 'plans':
        this.popoverCtrl.create({component: MultiSelectPopover,componentProps: { root: this.plansFilterPopoverData },cssClass: 'xpPopover', event:event }).then((data)=>data.present())
        break;
      case 'journey':
        this.toggleFilterLabelsVisibility();
        this.popoverCtrl.create({component: MultiSelectPopover,componentProps: { root: this.journeyInsightsFilterPopoverData }, event:event}).then((data)=>data.present())
        break;
      case 'interest':
        this.popoverCtrl.create({component: MultiSelectPopover,componentProps: { root: this.interestInsightsFilterPopoverData }, cssClass: 'interest-insigts-popover', event:event }).then((data)=>data.present())
        break;
      case 'relationship':
        this.popoverCtrl.create({component: MultiSelectPopover,componentProps: { root: this.relationInsightsFilterPopoverData }, cssClass: 'relationship-insigts-popover', event:event }).then((data)=>data.present())
        break;
      default:
    }
  }

  get plans(): any[] {
    let filteredObjs: any[] = [];
    if(this.source && this.source.plans) {
      if (this.xperiencesService.xpPlanFilterText == "All") {
        filteredObjs = (this.source && this.source.plans.length > 0) ? this.source.plans : [];
      } else {
        filteredObjs = (this.source && this.source.plans.length > 0) ? this.source.plans.filter(e => e.type === this.xperiencesService.xpPlanFilterText) : [];
      }
    }
    this.planItemsCount = filteredObjs.length;
    return filteredObjs;
  }

  get filteredInsights(): any[] {
    let filteredObjs: any[] = [];
    if (this.x_type === "journey") {
      if (this.journeyInsightsFilterValue == "All" && this.productFilterValue == "All" &&
          (this.activitiesFilterValueList.length === 1 && this.activitiesFilterValueList[0] === 'All')) {
          filteredObjs = this.source.journey.insights;
      } else {
        if (this.activitiesFilterValueList.length > 0) {
          if (this.activitiesFilterValueList.length === 1 && this.activitiesFilterValueList[0] === 'All') {
            filteredObjs = this.source.journey.insights;
          } else {
            filteredObjs = this.source.journey.insights.filter((e) => {
              if (this.activitiesFilterValueList.findIndex((afl) => afl === e.activitytypecode) >= 0) {
                return e;
              }
          });
          }
        }
        if (this.journeyInsightsFilterValue.length > 0 && this.journeyInsightsFilterValue !== 'All') {
          filteredObjs = filteredObjs.filter((e) => e.activitytypecode === this.journeyInsightsFilterValue);
        }
        if (this.productFilterValue.length > 0 && this.productFilterValue !== 'All') {
          filteredObjs = filteredObjs && filteredObjs.length > 0 ?
                          this.source.journey.insights.filter((i) => {
                            if (i.products && i.products.some((e) => e.productname === this.productFilterValue)) {
                              return i;
                            }
                          }): [];
        }
      }
    } else if (this.x_type === "interest") {
      if (this.insightsFilterValue === 'All') {
        filteredObjs = this.source.interests.insights.sort((a, b) => b.score - a.score);
      } else if (this.insightsFilterValue === 'interest_insights') {
        filteredObjs = this.source.interests.insights.filter((insight) => insight.insights_plus && insight.insights_plus.length > 0).sort((a, b) => b.score - a.score);
      }
        else  {
        let strengthValues = ['high', 'medium', 'low'];
        if (this.selectedSortOption === 'low') {
          strengthValues.reverse();
        }
        // sorting it based on the position of the strength value in 'strengthValues' array
        filteredObjs = this.source.interests.insights.sort((a, b) => {
          return strengthValues.indexOf(a.strength) - strengthValues.indexOf(b.strength)
        });
      }
    } else if (this.x_type === "relationship") {
      if (this.insightsFilterValue === 'All') {
        filteredObjs = this.source.relationships.insights.sort((a, b) => b.score - a.score);
      } else if (this.insightsFilterValue === 'relationship_insights') {
        filteredObjs = this.source.relationships.insights.filter((insight) => insight.insights_plus && insight.insights_plus.length > 0).sort((a, b) => b.score - a.score);
      } else {
      if (this.selectedSortOption === "asc") {
        filteredObjs = this.source.relationships.insights.sort((a, b) => a.degree - b.degree);
      } else if (this.selectedSortOption === "des") {
        filteredObjs = this.source.relationships.insights.sort((a, b) => b.degree - a.degree);
      }
      }
    }
    return filteredObjs;
  }

  public async sendFeedback(insight: InterestInsight | RelationshipInsight, upFeedback: boolean, index?: number) {
    const xpCustomer: XpCustomer = (this.navParams.data && this.navParams.data.customer);
    if (insight.feedback_Flag || this.device.isOffline) return;
    if (upFeedback) {
      if (this.x_type === 'relationship') {
        this.uiService.displayLoader();
        this.sendRelationshipInsightFeedback(xpCustomer.contactid, (<RelationshipInsight>insight).contactid, 'positive').then(() => {
          insight.feedback_Flag = 'positive';
          insight.feedbackGiven = true;
          insight.feedbackThumbsUp = true;
          this.uiService.dismissLoader();
          this.notificationService.notify(this.translate.instant('XPERIENCES_THANKS_FOR_FEEDBACK'), 'Xperience Plan');
        }).catch(() => {
          this.uiService.dismissLoader();
          this.displayErrorToastMessage();
        });
      } else {
        this.uiService.displayLoader();
        await this.xperiencesService.sendFeedback(xpCustomer.contactid, 'positive', (<InterestInsight>insight).title).then(() => {
          insight.feedback_Flag = 'positive';
          insight.feedbackGiven = true;
          insight.feedbackThumbsUp = true;
          this.uiService.dismissLoader();
        }).catch((err) => {
          console.log("Error occurred --> ", err);
          this.uiService.dismissLoader();
          this.displayErrorToastMessage();
        });
      }
    } else {
      if (this.x_type === 'relationship') {
        this.uiService.displayLoader();
        this.sendRelationshipInsightFeedback(xpCustomer.contactid, (<RelationshipInsight>insight).contactid, 'negative').then(() => {
          const index = this.source.insights.findIndex(x => x.contactid === (<RelationshipInsight>insight).contactid);
          if (index > -1) {
            this.source.insights.splice(index, 1);
            this.notificationService.notify(this.translate.instant('XPERIENCES_RELATIONSHIP_REMOVED'), 'Xperience Plan');
          }
          this.uiService.dismissLoader();
        }).catch(() => {
          this.uiService.dismissLoader();
          this.displayErrorToastMessage();
        });

      } else {
        const reasons: OptionSet[] = cloneDeep(this.xperiencesService.thumbsDownReasons);
        const popover =await  this.popoverCtrl.create({component: PopoverComponent,componentProps: { field: "thumbs-down-feedback", reasons: reasons },cssClass: "thumbs-down-reason-popover", event:event });
        popover.present();
        popover.onDidDismiss().then((data:any) => {
          if (data && data.done) {
            this.uiService.displayLoader();
            this.xperiencesService.sendFeedback(xpCustomer.contactid, 'negative', (<InterestInsight>insight).title, data.value.label).then(() => {
              this.source.insights.splice(index, 1);
              this.uiService.dismissLoader();
            }).catch((err) => {
              console.log("Error occurred --> ", err);
              this.uiService.dismissLoader();
              this.displayErrorToastMessage();
            });
          }
        });
      }
    }
  }

  public async sendPlanFeedback(plan: any, feedback: string) {
    if (plan.feedback_Flag || this.device.isOffline) return;
    if (feedback == 'negative') {
      const index = this.source.plans.findIndex(x => x.id == plan.id);
      if (index > -1) {
        this.source.plans.splice(index, 1);
        this.notificationService.notify( this.translate.instant('XPERIENCES_PREDICTION_REMOVED'), 'Xperience Plan');
        return;
      }
    }
    this.notificationService.notify( this.translate.instant('XPERIENCES_THANKS_FOR_FEEDBACK'), 'Xperience Plan');
    plan.feedback_Flag = feedback;
  }

  getOrdinalSuffix(n: number): string {
    let j = n % 10,
      k = n % 100;
    if (j == 1 && k != 11) {
      return n + "st";
    }
    if (j == 2 && k != 12) {
      return n + "nd";
    }
    if (j == 3 && k != 13) {
      return n + "rd";
    }
    return n + "th";
  }

  async displayActivityDetails(data: any) {
    if (this.device.isOffline || data.activitytypecode === 'event') return;
    if(data.activitytypecode == 'journey_insight' || data['insights_plus']) {
      this.displayInsightsPlusDetails(data);
      return;
    }
    const contact: Contact = this.contactService.getContactByID(this.xperiencesService.selectedXpContact.contactid);
    if (data.type == 'centralplan') {
      this.uiService.displayLoader();
      await this.xperiencesService.getMarketingEmails(data.id).then((response) => {
        const centralPlanData: CentralPlan = {
          type: data.title,
          customer: contact,
          products: data.products,
          subject: response['msdyncrm_subject'],
          date: data.date,
          previewContent: response['msdyncrm_emailbody'] ? response['msdyncrm_emailbody'] : ''
        }
        this.navService.pushChildNavPageWithPageTracking(
          MarketingEmailDetailsComponent,
          PageName.MarketingEmailDetailsComponent,
          PageName.MarketingEmailDetailsComponent,
          {
            viewData: { email: centralPlanData, centralPlan: data }
          });
        this.uiService.dismissLoader();
      }).catch(() => {
        this.uiService.dismissLoader();
      });
      return;
    }

    if (data.type == 'prediction' && (data.activitytypecode === 'appointment' ||
      data.activitytypecode === 'email' || data.activitytypecode === 'indskr_sampledrop')) {
      if (data.activitytypecode == 'appointment') {
        await this.xperiencesService.createMeeting([contact], data.date);
        if (this.activityService.selectedActivity) this.footerService.initButtons(FooterViews.Activities);
      } else if (data.activitytypecode === 'indskr_sampledrop') {
        const contactCustomeAllocations = this.sampleService.contactCustomerSampleAllocations.find(o => {
          return o.contactId == contact.ID
        });
        if (!contactCustomeAllocations || !contactCustomeAllocations.currentCustomerSampleAllocations ||
          contactCustomeAllocations.currentCustomerSampleAllocations.length == 0) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_ACTIVE_ALLOCATIONS'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createAllocationOrder(data.date, contact);
        }
      } else {
        const channel: ChannelType = this.xperiencesService.getChannelType(data.channeltype);
        if (channel === ChannelType.EMAIL && (!contact.emailAddressList || contact.emailAddressList.length === 0)) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_EMAIL'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if (channel === ChannelType.FACEBOOK && (contact.indskr_facebookpsid || '') === '') {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_FACEBOOK_ID'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if ((channel === ChannelType.WHATSAPP || channel === ChannelType.SMS) && ((contact && contact.mobilePhone) || '') === '') {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_PHONE_NUMBER'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createEmail(data.date, data.channeltypeid, channel, [contact]);
        }
      }
      data.type = 'teamplan';
      data.id = this.activityService.selectedActivity.ID;
      let loggedInUser = this.authService.user;
      let users: User[] = [{
        userid: loggedInUser.systemUserID,
        username: loggedInUser.displayName
      }];
      data.users = users;
      data.translatedTitle = this.constructPlansTitle(data.type, data.activitytypecode, data.channeltype, users);
    } else {
      let foundActivity = this.activityService.getActivityByID(data.id);
      this.uiService.showRightPane = true;
      this.uiService.showNewActivity = false;
      if (data.activitytypecode == "appointment") {
        if (!foundActivity) {
          await this.activityDataService.getActivityByIdOnline(data.id, "appointment" )
            .then(async response => {
              foundActivity = new AppointmentActivity(response);
            });
        }
        foundActivity.isFromXperiences = true;
        this.activityService.selected = foundActivity;
        this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
        await this.activityDataService.updateActivityDetails(foundActivity);
        if (this.activityService.selectedActivity) {
          this.footerService.initButtons(FooterViews.Activities);
        }
      } else if (data.activitytypecode == "email") {
          let activity = new AppointmentActivity({ activityid: data.id });
          activity.type = ActivityType.Email;
          activity.isFromXperiences = true;
          await this.activityDataService.getRealTimeActivityDetails(activity).then((email) => {
            if (!email) {
              return;
            }
            this.activityService.selected = email;
            this.activityService.selectedActivity = email;
            this.emailService.selectedActivity = <EmailActivity>email;
            this.emailService.selectedActivity.isFromXperiences = true;
            this.emailService.setCurrentEmail(email);
            this.emailService.viewType = EmailViewType.FROM_XPERIENCES;
            this.uiService.showNewActivity = false;
            this.uiService.activeView = 'Email';
            this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent);
            this.activityService.updateEmailAddressInEmailActivityParties(email as EmailActivity);
          }).catch(() => {
            console.log("Failed to load email activity:" + activity.ID);
          });
      } else if (data.activitytypecode == "medical_inquiry") {
        this.uiService.displayLoader();
        await this.caseDataService.findCaseById(data.id).then(
          async res => {
            if (res) {
              for (let key in res) {
                if (key.charAt(0) === "_") {
                  let a = key.substring(1, key.length);
                  res[a] = res[key];
                  delete res[key];
                }
              }
              let iCase: CaseActivity;
              iCase = new CaseActivity(res);
              iCase = await this.caseService.reInitDataDependency(iCase);
              this.activityService.selectedActivity = iCase;
              this.caseService.assignSelectedCase(iCase);
              this.caseService.accessedFrom = AccesingMode.XPERIENCE_INSIGHTS;
              this.uiService.showNewActivity = false;
              this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.CaseManagementDetailComponent);
            }
            this.uiService.dismissLoader();
          }).catch(() => {
            console.log("Failed to load customer inquiry:" + foundActivity.ID);
            this.uiService.dismissLoader();
          });
      } else {
        if (foundActivity && foundActivity.type == ActivityType.Sample) {
          this.activityService.selected = foundActivity;
        } else {
        // makes online call to fetch the allocation order details
        let foundActivity = new SampleActivity(await this.activityDataService.getActivityByIdOnline(data.id, "sampledrop"));
        this.activityService.selected = foundActivity;
        await this.activityDataService.updateActivityDetails(foundActivity);
      }
      this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'Sample';
      this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
      }
    }
  }

  displayInsightsPlusDetails(insight: JourneyInsight | InterestInsight | RelationshipInsight) {
    if((insight as JourneyInsight).activitytypecode == 'journey_insight') {
      let index = insight.users.findIndex((user) => user.userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayJourneyPlusInsights(insight as JourneyInsight);
       }
       return;
    }
    if(insight['events']) {
      let index = (insight as RelationshipInsight).insights_plus.findIndex((ip) => ip.users[0].userid === this.authService.user.systemUserID);
      if(index >= 0) {
      this.displayRelationInsightsPlusDetails(insight as RelationshipInsight);
      }
      return;
    }
    if(insight['emails']) {
      let index = (insight as InterestInsight).insights_plus.findIndex((ip) => ip.users[0].userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayInterestPlusInsights(insight as InterestInsight);
        return;
      }
     }
  }

  async displayJourneyPlusInsights(insight: JourneyInsight) {
    this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getJourneyInsigthData(insight.id);
    this.uiService.dismissLoader();
    let joureyInsightData: CustomerJourney = {
      type: insight.title,
      description: insightData['indskr_description'] && insightData['indskr_description'].length > 0 ? insightData['indskr_description']: this.translate.instant('NO_DESCRIPTION'),      date: insight.date,
      confidence:  insightData['indskr_strength'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strength']).label) : undefined,
      indskr_competitorproduct: insightData['indskr_competitorproduct'] ? insightData['indskr_competitorproduct']: undefined
    };
    if(insight.products.length > 0) {
      joureyInsightData.selectedProduct = {
                                            title: insight.productsText,
                                            id: insight.products[0].productid === 'competitor product' ? 'indskr_competitorproduct' : insight.products[0].productid
                                          }
    } else {
      if (insightData["indskr_product"]) {
        joureyInsightData.selectedProduct = {
          title: insightData["productName"],
          id: insightData["indskr_product"]
        }
      }
    }
    this.navService.pushChildNavPageWithPageTracking(CustomerJourneyComponent, PageName.CustomerJourneyComponent,
                                                      PageName.ActivitiesPageComponent, { journey: joureyInsightData, readOnly: true });
  }

  async displayRelationInsightsPlusDetails(insight: RelationshipInsight) {
    const latestInsight = ([...insight.sources.insightsPlusByLoggedInUser, ...insight.sources.otherInsightsPlus] as RelationshipInsightsPlus[])
                              .sort((a, b) => (b as RelationshipInsightsPlus).date - (a as RelationshipInsightsPlus).date)[0];
    this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getRelationshipInsigthData(latestInsight.insightid.toString());
    this.uiService.dismissLoader();
    let insightPlusSources = insight.sources.otherInsightsPlus.map((oip) => oip.displayText);
    let allSources = [];
    if(insight.sources.otherSources.length > 0) {
      allSources.push(...insight.sources.otherSources);
    }
    if (insight.sources.otherInsightsPlus) {
      allSources.push(...insightPlusSources);
    }
    const relationshipTypes: SelectListData[] = insight.insights_plus.map((ip)=> {
      const relationship = this.xperiencesService.relationships.find((relationship) => relationship.title === ip.relationship_insight_type);
      return {...relationship, isSelected: true };
    });
    const selectedSpeaciality: Specialty = this.contactService.specialties.find((sp) => sp.id === insightData["indskr_specialty"]);
    const relationshipInsightData: CustomerRelationship = {
      customerName: this.xperiencesService.selectedXpContact.fullname,
      contactid: insight.contactid,
      fullname: insight.fullname,
      contacts: insight.contacts,
      sources: allSources,
      sourcesText:  allSources.length > 1 ? allSources[0] + ' +' + (allSources.length - 1) : allSources[0],
      types: relationshipTypes.length === 0 ? undefined : relationshipTypes,
      title: relationshipTypes.length === 0 ? undefined : (relationshipTypes.length === 1 ? relationshipTypes[0].title : relationshipTypes[0].title + ' + ' + (relationshipTypes.length - 1)),
      score: insightData['indskr_strength'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strength']).label): undefined,
      selectedSpeciality: {
        title: selectedSpeaciality.name,
        id: selectedSpeaciality.id,
        isSelected: true
      }
    }
    this.navService.pushChildNavPageWithPageTracking(CustomerRelationshipComponent, PageName.CustomerJourneyComponent,
                                                      PageName.ActivitiesPageComponent, { relationship: relationshipInsightData, readOnly: true });
  }


  async displayInterestPlusInsights(insight: InterestInsight) {
    this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getInterestInsigthData(insight.latestInsight.id.toString());
    this.uiService.dismissLoader();
    let insightPlusSources = insight.sources.otherInsightsPlus.map((oip) => oip.displayText);
    let allSources = [];
    if(insight.sources.otherSources.length > 0) {
      allSources.push(...insight.sources.otherSources);
    }
    if (insight.sources.otherInsightsPlus) {
      allSources.push(...insightPlusSources);
    }
    let joureyInsightData: CustomerInterest = {
      description:  insightData['indskr_description'] && insightData['indskr_description'].length > 0 ? insightData['indskr_description']: this.translate.instant('NO_DESCRIPTION'),
      selectedCategory:  {
        title: insight.latestInsight.category
      },
      score: insightData['indskr_strengths'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strengths']).label) : undefined,
      sources: allSources,
      sourcesText:  allSources.length > 1 ? allSources[0] + ' +' + (allSources.length - 1) : allSources[0],
    };
    this.navService.pushChildNavPageWithPageTracking(CustomerInterestComponent, PageName.CustomerJourneyComponent,
                                                      PageName.ActivitiesPageComponent, { interest: joureyInsightData, readOnly: true });
  }

  displayErrorToastMessage() {
    this.notificationService.notify(this.translate.instant('XPERIENCES_ERROR'), 'Xperience Plan', 'top', ToastStyle.DANGER);
  }

  sendRelationshipInsightFeedback(contactId: string, relatedContactId: string, feedback: string): Promise<any> {
    return this.xperiencesService.sendRelationshipInsightFeedback(contactId, relatedContactId, feedback);
  }

  constructInsightTitle(activityTypeCode: string, users: User[], channeltype: string, title?:string): string {
    let activityName = '';
    let userNamesText = this.xperiencesService.getUserNamesText(users);
    switch (activityTypeCode) {
      case 'appointment':
        activityName = this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      case 'email':
        if (channeltype === 'email') activityName = this.translate.instant('XPERIENCES_EMAIL');
        else {
          let channel: string = "";
          if (channeltype === 'facebook') channel = this.translate.instant('XPERIENCES_FACEBOOK_MESSAGE');
          else if (channeltype === 'whatsapp') channel = this.translate.instant('XPERIENCES_WHATSAPP_MESSAGE');
          else if (channeltype === 'sms') channel = this.translate.instant('XPERIENCES_TEXT_MESSAGE');
          //Change in the future, if required: Temp fix for Bug: OMNI-16300
          else channel = title + " message by ";
          activityName = channel;
        }
        break;
      case 'medical_inquiry':
        return this.translate.instant('XPERIENCES_MEDICAL_INQUIRY');
      case 'indskr_sampledrop':
        activityName = this.translate.instant('XPERIENCES_ALLOCATION_ORDER');
        break;
      case 'marketing_email':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EMAIL');
        break;
      case 'event':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EVENT');
        break;
      case 'meeting':
        activityName =  this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      default:
        return '';
    }
    return activityName + ' ' + userNamesText;
  }

  constructPlansTitle(type: string, activitytypecode: string, channeltype: string, users: User[]) {
    let title = '';
    if (type === 'prediction') {
      switch (activitytypecode) {
        case "indskr_sampledrop":
          title = this.translate.instant('XPERIENCES_GENEE_ALLOCATION_LABEL');
          break;
        case "task":
          title = "Follow-up";
          break;
        case "appointment":
          title = this.translate.instant('XPERIENCES_GENEE_MEETING_LABEL');
          break;
        case "email":
          if (channeltype === 'email') title = this.translate.instant('XPERIENCES_GENEE_EMAIL_LABEL');
          else {
            if (channeltype === 'facebook') title = this.translate.instant('XPERIENCES_GENEE_FACEBOOK_LABEL');
            else if (channeltype === 'whatsapp') title = this.translate.instant('XPERIENCES_GENEE_WHATSAPP_LABEL');
            else if (channeltype === 'sms') title = this.translate.instant('XPERIENCES_GENEE_SMS_LABEL');
          }
          break;
        case "medical_inquiry":
          title = this.translate.instant('XPERIENCES_GENEE_MEDICAL_INQUIRY');
          break;
      }
      return title;
    } else if (type === 'teamplan' || type === 'centralplan') {
      if (activitytypecode === 'medical_inquiry') {
        return this.translate.instant('XPERIENCES_TEAM_MEDICAL_INQUIRY');
      }
      return this.constructInsightTitle(activitytypecode, users, channeltype);
    }
  }

  getJourneyTouchPointTitle(journeyTouchPoint: string) {
    switch (journeyTouchPoint) {
      case 'Meeting':
        return this.translate.instant('MEETING');
      case 'Email':
        return this.translate.instant('EMAIL');
      case 'Event Participant':
        return this.translate.instant('XPERIENCES_EVENT_PARTICIPANT');
      case 'Event Speaker':
        return this.translate.instant('XPERIENCES_EVENT_SPEAKER');
      case 'Event Consultant':
        return this.translate.instant('XPERIENCES_EVENT_CONSULTANT');
      case 'Website':
        return this.translate.instant('XPERIENCES_EVENT_WEBSITE');
      case 'Co Author':
        return this.translate.instant('XPERIENCES_CO_AUTHOR');
      case 'Co Investigator':
        return this.translate.instant('XPERIENCES_CO_INVESTIGATOR');
      case 'Hospital Department':
        return this.translate.instant('XPERIENCES_HOSPITAL_DEPARTMENT');
      case 'Co Presenter':
        return this.translate.instant('XPERIENCES_CO_PRESENTER');
      default:
        return this.translate.instant('XPERIENCES_JOURNEY_TOUCHPOINT');
    }
  }

  getRelationshipInsightsSources(insight: RelationshipInsight) {
    let sources: RelationshipSources= {
      insightsPlusByLoggedInUser: [],
      otherInsightsPlus: [],
      otherSources: [],
    };
    if (insight.meetings && insight.meetings.length > 0) {
      sources.otherSources.push(`${insight.meetings.length > 1 ? this.translate.instant('XPERIENCES_RELATIONSHIP_GROUP_METTINGS') : this.translate.instant('XPERIENCES_RELATIONSHIP_GROUP_METTING')}`);
    }
    if (insight.events && insight.events.length > 0) {
      sources.otherSources.push(`${insight.events.length > 1 ? this.translate.instant('XPERIENCES_RELATIONSHIP_EVENTS') : this.translate.instant('XPERIENCES_RELATIONSHIP_EVENT')}`);
    }

    if (insight.accounts && insight.accounts.length > 0) {
      sources.otherSources.push(`${insight.accounts.length > 1 ? this.translate.instant('XPERIENCES_RELATIONSHIP_AFFILIATIONS') : this.translate.instant('XPERIENCES_RELATIONSHIP_AFFILIATION', {accountname: insight.accounts[0].accountname})}`);
    }

    if (insight.insights_plus && insight.insights_plus.length > 0) {
      insight.insights_plus.forEach((ip) => {
        if(ip.users[0].userid === this.authService.user.systemUserID && sources.insightsPlusByLoggedInUser.length < 1) {
          ip.displayText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.translate.instant('ME');
          sources.insightsPlusByLoggedInUser.push(ip);
        } else if (ip.users[0].userid !== this.authService.user.systemUserID) {
          ip.displayText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + ip.users[0].username;
          sources.otherInsightsPlus.push(ip);
        }
      });
    }
    return sources;
  }

  getInterstInsightsSources(insight: InterestInsight): InterestSources {
    let sources: InterestSources = {
      insightsPlusByLoggedInUser: [],
      otherInsightsPlus: [],
      otherSources: [],
    };
    if (insight.emails && insight.emails.length > 0) {
      sources.otherSources.push(`${insight.emails.length > 1 ? this.translate.instant('XPERIENCES_EMAILS') : this.translate.instant('EMAIL')}`);
    }
    if (insight.medical_inquries && insight.medical_inquries.length > 0) {
      sources.otherSources.push(`${insight.insights_plus.length > 1 ? this.translate.instant('XPERIENCES_CUSTOMER_INQUIRIES') : this.translate.instant('XPERIENCES_CUSTOMER_INQUIRY')}`);
    }
    if (insight.insights_plus && insight.insights_plus.length > 0) {
        insight.insights_plus.forEach((ip) => {
          if(ip.users[0].userid === this.authService.user.systemUserID && sources.insightsPlusByLoggedInUser.length < 1) {
            ip.displayText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.translate.instant('ME');
            sources.insightsPlusByLoggedInUser.push(ip);
          } else if (ip.users[0].userid !== this.authService.user.systemUserID) {
            ip.displayText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + ip.users[0].username;
            sources.otherInsightsPlus.push(ip);
          }
        });
    }
    if (insight.meeting && insight.meeting.length > 0) {
      sources.otherSources.push(`${insight.meeting.length > 1 ? this.translate.instant('XPERIENCES_MEETINGS') : this.translate.instant('XPERIENCES_MEETING')}`);
    }
    return sources;
  }

  getInsightsPlusSourcesText(insightsPlusSources: InterestSources | RelationshipSources): string {
    let sourcesText = [];
    sourcesText.push(...insightsPlusSources.otherSources);
    if(insightsPlusSources.insightsPlusByLoggedInUser.length > 0) {
      sourcesText.push(this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.translate.instant('ME'));
    }
    if (insightsPlusSources.otherInsightsPlus.length > 0) {
      let insightsPlusText = (insightsPlusSources.otherInsightsPlus.length > 1) ? this.translate.instant('XPERIENCES_MULTIPLE_INSIGHTS_PLUS') :
                               this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY');
      insightsPlusText = insightsPlusText +  ' ' + insightsPlusSources.otherInsightsPlus[0].users[0].username;
      if (insightsPlusSources.otherInsightsPlus.length > 1) {
        insightsPlusText = insightsPlusText + ' +' + (insightsPlusSources.otherInsightsPlus.length -1);
      }
      sourcesText.push(insightsPlusText);
    }
    return sourcesText.join(' | ');
  }

  getRelationshipContactText(contacts: RelationshipContact[]) {
    let text = '';
    if(contacts && contacts.length) {
      let contactsCount = contacts.length;
      if(contactsCount > 1) {
        text = `${contacts[0].fullname} +${contactsCount - 1}`
      } else {
        text = `${contacts[0].fullname}`
      }
    }
    return text;
  }

  getJourneyInsightsFilterLabel(activitytype: string) {
    let activityName = '';
    switch(activitytype) {
      case "appointment":
        activityName = this.translate.instant('XPERIENCES_MEETING');
        break;
      case "email":
        activityName = this.translate.instant('MESSAGE');
        break;
      case "TimeOff":
        activityName = this.translate.instant('TIME_OFF');
        break;
      case "indskr_sampledrop":
        activityName = this.translate.instant('XPERINECES_ALLOCATIONS');
        break;
      case "medical_inquiry":
        activityName = this.translate.instant('XPERIENCES_CUSTOMER_INQUIRY');
        break;
      default:
        break;
    }
    return activityName;
  }

  toggleFilterLabelsVisibility() {
    if (this.xperiencesService.xpInsightsFilterText.indexOf(this.translate.instant('XPERIENCES_FILTER_INSIGHTS_PLUS_JOURNEYS')) >= 0) {
      this.toggleLabelsVisibility(true);
    } else {
      this.toggleLabelsVisibility(false);
    }
  }

  toggleLabelsVisibility(disableLabel: boolean) {
    this.journeyInsightsFilterPopoverData[1].items = this.journeyInsightTypes.map((type, index) => {
      if(index > 0) {
        type.disableLabel = disableLabel;
      }
      return type;
    });
    this.journeyInsightsFilterPopoverData[2].items = this.productFilterOptions.map((option, index) => {
      if(index > 0) {
        option.disableLabel = !disableLabel;
      }
      return option;
    });
  }



  thisWeekClicked() {
    this.weekExpand = !this.weekExpand;
    if (!this.weekExpand) {
      if(this.xperiencesService.customerType == 'trending') {
        this.trackTrendingCustomerWeekClick();
       } else if (this.xperiencesService.customerType == 'saved') {
         this.trackSavedCustomerWeekClick();
       } else if (this.xperiencesService.customerType == 'inactive') {
        this.trackInactiveCustomerWeekClick();
       }
    }
  }

  trackTrendingCustomerWeekClick() {
    switch (this.x_type) {
      case "journey":
        this.trackingService.tracking('XperiencesTrendingJourneyPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "interest":
        this.trackingService.tracking('XperiencesTrendingInterestPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "relationship":
        this.trackingService.tracking('XperiencesTrendingRelationshipPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
    }
  }

  trackSavedCustomerWeekClick() {
    switch (this.x_type) {
      case "journey":
        this.trackingService.tracking('XperiencesSavedJourneyPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "interest":
        this.trackingService.tracking('XperiencesSavedInterestPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "relationship":
        this.trackingService.tracking('XperiencesSavedRelationshipPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
    }
  }

  trackInactiveCustomerWeekClick() {
    switch (this.x_type) {
      case "journey":
        this.trackingService.tracking('XperiencesInactiveJourneyPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "interest":
        this.trackingService.tracking('XperiencesInactiveInterestPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "relationship":
        this.trackingService.tracking('XperiencesInactiveRelationshipPlansandPredictionThisWeekCollapse', TrackingEventNames.XPERIENCES);
        break;
    }
  }

  thisMonthClicked() {
    this.monthExpand = !this.monthExpand;
    if (!this.monthExpand) {
      if(this.xperiencesService.customerType == 'trending') {
        this.trackTrendingCustomerMonthClick();
       } else if (this.xperiencesService.customerType == 'saved') {
         this.trackSavedCustomerMonthClick();
       } else if (this.xperiencesService.customerType == 'inactive') {
        this.trackInactiveCustomerMonthClick();
       }
    }
  }

  trackTrendingCustomerMonthClick() {
    switch (this.x_type) {
      case "journey":
        this.trackingService.tracking('XperiencesTrendingJourneyPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "interest":
        this.trackingService.tracking('XperiencesTrendingInterestPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "relationship":
        this.trackingService.tracking('XperiencesTrendingRelationshipPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
    }
  }

  trackSavedCustomerMonthClick() {
    switch (this.x_type) {
      case "journey":
        this.trackingService.tracking('XperiencesSavedJourneyPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "interest":
        this.trackingService.tracking('XperiencesSavedInterestPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "relationship":
        this.trackingService.tracking('XperiencesSavedRelationshipPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
    }
  }

  trackInactiveCustomerMonthClick() {
    switch (this.x_type) {
      case "journey":
        this.trackingService.tracking('XperiencesInactiveJourneyPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "interest":
        this.trackingService.tracking('XperiencesInactiveInterestPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
      case "relationship":
        this.trackingService.tracking('XperiencesInactiveRelationshipPlansandPredictionThisMonthCollapse', TrackingEventNames.XPERIENCES);
        break;
    }
  }

  trackFilterClick() {
    if(this.xperiencesService.customerType == 'trending') {
      switch (this.x_type) {
        case "journey":
          this.trackingService.tracking('XperiencesTrendingJourneyPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
        case "interest":
          this.trackingService.tracking('XperiencesTrendingInterestPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
        case "relationship":
          this.trackingService.tracking('XperiencesTrendingRelationshipPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
      }
    } else if (this.xperiencesService.customerType == 'saved') {
      switch (this.x_type) {
        case "journey":
          this.trackingService.tracking('XperiencesSavedJourneyPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
        case "interest":
          this.trackingService.tracking('XperiencesSavedInterestPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
        case "relationship":
          this.trackingService.tracking('XperiencesSavedRelationshipPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
      }
    } else if (this.xperiencesService.customerType == 'inactive') {
      switch (this.x_type) {
        case "journey":
          this.trackingService.tracking('XperiencesInactiveJourneyPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
        case "interest":
          this.trackingService.tracking('XperiencesInactiveInterestPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
        case "relationship":
          this.trackingService.tracking('XperiencesInactiveRelationshipPlansandPredictionFilterClicked', TrackingEventNames.XPERIENCES);
          break;
      }
    }
  }

  trackFilterSelection(selectedItem: string) {
    if(this.xperiencesService.customerType == 'trending') {
      switch (this.x_type) {
        case "journey":
          this.trackTrendingCustomerJourneyEvents(selectedItem)
          break;
        case "interest":
        this.trackTrendingCustomerInterestEvents(selectedItem)
          break;
        case "relationship":
          this.trackTrendingCustomerRelationshipEvents(selectedItem)
          break;
      }
    } else if (this.xperiencesService.customerType == 'saved') {
      switch (this.x_type) {
        case "journey":
          this.trackSavedCustomerJourneyEvents(selectedItem)
          break;
        case "interest":
        this.trackSavedCustomerInterestEvents(selectedItem)
          break;
        case "relationship":
          this.trackSavedCustomerRelationshipEvents(selectedItem)
          break;
      }
    } else if (this.xperiencesService.customerType == 'inactive') {
      switch (this.x_type) {
        case "journey":
          this.trackInactiveCustomerJourneyEvents(selectedItem)
          break;
        case "interest":
        this.trackInactiveCustomerInterestEvents(selectedItem)
          break;
        case "relationship":
          this.trackInactiveCustomerRelationshipEvents(selectedItem)
          break;
      }
    }
  }

  trackTrendingCustomerJourneyEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesTrendingJourneyPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesTrendingJourneyPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesTrendingJourneyPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackTrendingCustomerInterestEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesTrendingInterestPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesTrendingInterestPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesTrendingInterestPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackTrendingCustomerRelationshipEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesTrendingRelationshipPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesTrendingRelationshipPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesTrendingRelationshipPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackInactiveCustomerJourneyEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesInactiveJourneyPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesInactiveJourneyPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesInactiveJourneyPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackInactiveCustomerInterestEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesInactiveInterestPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesInactiveInterestPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesInactiveInterestPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackInactiveCustomerRelationshipEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesInactiveRelationshipPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesInactiveRelationshipPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesInactiveRelationshipPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackSavedCustomerJourneyEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesSavedJourneyPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesSavedJourneyPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesSavedJourneyPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackSavedCustomerInterestEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesSavedInterestPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesSavedInterestPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesSavedInterestPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  trackSavedCustomerRelationshipEvents(selectedItem: string) {
    if (selectedItem === 'teamplan') {
      this.trackingService.tracking('XperiencesSavedRelationshipPlansandPredictionTeamFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'centralplan') {
      this.trackingService.tracking('XperiencesSavedRelationshipPlansandPredictionCentralFilterClicked', TrackingEventNames.XPERIENCES);
    } else if (selectedItem === 'prediction') {
      this.trackingService.tracking('XperiencesSavedRelationshipPlansandPredictionGeneeFilterClicked', TrackingEventNames.XPERIENCES);
    }
  }

  getContactEventsDetails(contactId: string, eventId: string) {
    const allEvents = this.customerEventsService.getEventsForContact(contactId);
    return [...allEvents.availableEvents, ...allEvents.upcomingEvents].find((event) => event.eventId === eventId.toLowerCase());
  }

  expandEventDetails(event, customerEvent) {
    if (this.device.isOffline) {
      return;
    }
    customerEvent.isExpanded = !customerEvent.isExpanded;
  }

  private updateVisibleRecords() {
    this.visibleCount = 20;
    if (this.filteredInsights.length > 20) {
      this.visibleInsights = this.filteredInsights.slice(0, this.visibleCount);
    } else {
      this.visibleInsights = this.filteredInsights;
    }
  }

  public doInfinite(event) {
    this.visibleCount = this.visibleInsights.length;
    this.visibleInsights.push(...this.filteredInsights.slice(this.visibleCount, this.visibleCount + 20));
    event.complete();
  }

}
