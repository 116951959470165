import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Endpoints } from '../../../config/endpoints.config';
import { LogService } from "../../services/logging/log-service";
import { AuthenticationService } from "../../services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class VirtualFileService {
    
    constructor(
        private http: HttpClient,
        private logService: LogService,
        private authenticationService: AuthenticationService,
    ) {}

    public async downloadURL(presentationID: string) {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.presentations.download;
        let req = {
            headers: Endpoints.headers.content_type.zip.headers
        }
        let headerResponse = await this.http.head(url, req)
        .toPromise();
        let response = await this.http.get(url, req)
        .toPromise();
        this.logService.logDebug(response);
    }
}