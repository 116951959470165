<div class="custom-alert-wrapper sc-ion-alert-ios">
    <div class="custom-alert-head">
        <p class="inbound-title">{{title}}</p>
        <h2 class="inbound-hcp-name">{{name}}</h2>
        <p *ngIf="inboundRequestArray[0]?.email" class="inbound-email">
            <img src="assets/imgs/envelope.png"><span>{{inboundRequestArray[0]?.email}}</span>
        </p>
        <p *ngIf="inboundRequestArray[0]?.phone" class="inbound-phone">
            <img src="assets/imgs/phone-call.png"><span>{{inboundRequestArray[0]?.phone}}</span>
        </p>
    </div>
    <div class="custom-alert-button-group sc-ion-alert-ios">
      <button class="alert-button ion-focusable ion-activatable sc-ion-alert-ios" (click)="decline()">
        <span>{{'POP_DECLINE' | translate}}</span>
      </button>
      <button class="alert-button ion-focusable ion-activatable sc-ion-alert-ios" (click)="accept()">
        <span>{{'POP_ACCEPT' | translate}}</span>
      </button>
    </div>
</div>