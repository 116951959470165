import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { UIService } from '../../../../src/app/services/ui/ui.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'custom-notification-details',
  templateUrl: 'custom-notification-details.html',
  styleUrls: ['custom-notification-details.scss']
})




export class CustomNotificationDetails {

  CustomNotificationPageTitle: IndPageTitleViewDataModel;
  public detailHeaderModel: IndSectionHeaderViewDataModel;
  NotitificationCustomerField: IndFormFieldViewDataModel;
  NotitificationChannelField: IndFormFieldViewDataModel;
  NotitificationDateField: IndFormFieldViewDataModel;
  NotitificationDescriptionField: IndFormFieldViewDataModel;
  NotitificationDepartmentField:IndFormFieldViewDataModel;
  @Input() activity
  private layoverViewPushedFrom: string;
  @Input() from: any;

  constructor(
    public readonly translate: TranslateService,
    public navService: NavigationService,
    public readonly UIService: UIService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.activity
    console.log(this.activity)
    this.initPageTitle();
    this.initializeDetailHeader();
    this.getFormField();
    if (this.from) {
      this.layoverViewPushedFrom = this.from;
    }
  }


  private initPageTitle(): void {
    const buttons = [];
    buttons.push({
      id: 'close',
      icon: 'chevron-back-outline',
      isDisabled: false,
      // isVisible: this.isBackButtonEnabled,
      align: 'left'
    }),
      this.CustomNotificationPageTitle = {
        id: 'account-plan-details',
        title: this.activity['contactFirstName'] + ' ' + this.activity['contactLastName'],
        controls: []
      };
  }

  private initializeDetailHeader() {
    this.detailHeaderModel = {
      id: 'account-plan-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
  }

  getFormField() {
    this.NotitificationCustomerField = {
      label: this.translate.instant('CUSTOMER'),
      inputText: this.activity['contactFirstName'] + ' ' + this.activity['contactLastName'],
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };
    this.NotitificationChannelField = {
      label: this.translate.instant('NEW_ACTIVITY_CHANNEL'),
      inputText: this.activity['indskr_channel_Formatted'],
      id: 'channel-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };

    this.NotitificationDateField = {
      label: this.translate.instant('DATE'),
      inputText: this.datePipe.transform(this.activity['indskr_date'], "dd-MM-yyyy"),
      id: 'date-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };
    
    this.NotitificationDepartmentField={
      label: this.translate.instant('DEPARTMENT'),
      inputText: this.activity['indskr_department'],
      id: 'department-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    }

    this.NotitificationDescriptionField = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.activity['indskr_description'],
      id: 'description-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };

  }

  public handleButtonClick(id: string): void {
    switch (id) {
      case 'close':
        this.onClosePage();
        return;
    }
  }

  public onClosePage(): void {

    // this.navService.popWithPageTracking();  
    if (this.layoverViewPushedFrom === 'AgendaView') {
      // this.events.publish("initTimePickerLimitedMinTime");
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      this.UIService.activeView = '';
      this.UIService.showRightPane = false;
    }
  }

}


