import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";

export class Inventory {
  userAllocationQtyId: string;
  userId: string;
  skuName: string;
  skuId: string;
  lot: InventoryLot;


  constructor(raw) {
    this.userAllocationQtyId = raw.indskr_userallocationquantityid;
    this.userId = raw['_indskr_user_value'];
    this.skuName = raw['aa.indskr_parentproductid_Formatted'];
    this.skuId = raw['aa.indskr_parentproductid'];
    this.lot = new InventoryLot(raw);
  }

  
}

export class InventoryLot {
  lotName: string;
  lotId: string;
  validFrom: string;
  validTo: string;
  totalQtyRemaining: number;
  totalQtyRecieved: number;
  totalQtyDropped: number;
  totalQtyTransferred: number;
  qtyAdjusted: number;
  lastdispatchdate: string;

  constructor(raw) {
    this.lotName = raw['aa.name'];
    this.lotId = raw['_indskr_lot_value'];
    this.validFrom = raw['aa.validfromdate'];
    this.validTo = raw['aa.validtodate'] ? this._getUTCDateConversion(raw['aa.validtodate']) : '';
    this.totalQtyRemaining = (raw.indskr_totalquantityremaining) ? raw.indskr_totalquantityremaining : 0;
    this.totalQtyRecieved = (raw.indskr_totalquantityrecieved) ? raw.indskr_totalquantityrecieved : 0;
    this.totalQtyDropped = (raw.indskr_totalquantitydropped) ? raw.indskr_totalquantitydropped : 0;
    this.totalQtyTransferred = (raw.indskr_totalquantitytransferred) ? raw.indskr_totalquantitytransferred : 0;
    this.qtyAdjusted = (raw.indskr_quantityadjusted) ? raw.indskr_quantityadjusted : 0;
    this.lastdispatchdate = raw['aa.indskr_lastdispatchdate'] ? this._getDateConversion(raw['aa.indskr_lastdispatchdate']) : '';
  }

  private _getDateConversion(dateString: string): string {
    const tempDate = new Date(dateString);
    let formattedDate = new Date(tempDate.getFullYear(),tempDate.getMonth(),tempDate.getDate(),23,59,59).toString() || '';
    return formattedDate;
  }

  private _getUTCDateConversion(dateString: string): string {
    const tempDate = new Date(dateString);
    let formattedDate = new Date(tempDate.getUTCFullYear(),tempDate.getUTCMonth(),tempDate.getUTCDate(),23,59,59).toString() || '';
    return formattedDate;
  }
}

export interface UserInventory {
  userId: string;
  skuName: string;
  skuId: string;
  expanded: boolean;
  lot: InventoryLot[];
  inventoryViewData: IndSectionHeaderViewDataModel;
}