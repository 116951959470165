<ion-row>
    <ion-col class="left-pane" [ngClass]="{'multiPopup': (type === 'SchedulerCity')}">
        <ion-header *ngIf="isMultiSelect" class="fixedheader">

          <ind-header-left  *ngIf="!isSmallPopoverMode; else smallPopover"
                            [viewData]="indHeaderLeftModel"
                            [hideFilterButton]="true"
                            [searchDisabled]="type == 'SchedulerCity'"
                            [(searchText)]="searchText"
                            (onControlClick)="onHeaderControlClick($event)"
                            (onSearchInput)="onInput($event.target)"
                            ></ind-header-left>

          <ng-template #smallPopover>
            <ion-toolbar class='page-title'>
              <ion-buttons slot="start">
                  <ion-button icon-only color="primary" (click)="cancelSelection()">
                    {{'CANCEL' | translate}}
                  </ion-button>
              </ion-buttons>
              <ion-title text-center>
                <span class="leftScreenHeaderTitle">{{selectionHeader}}</span>
              </ion-title>
              <ion-buttons slot="end">
                <ion-button [disabled]="!isDeviceOnline || hardDisableDoneButton" fill="clear" color="primary" (click)="emitMultiSelect()">{{'DONE' | translate}}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ng-template>
        </ion-header>

        <ion-content class="multi-select" *ngIf="isMultiSelect" [ngClass]="{ 'cityPopover': type === 'SchedulerCity',
            'vectorimageleft' : !searchText && list?.length == 0 && (myFormattedCallPlans?.past?.length == 0 && myFormattedCallPlans?.present?.length == 0 && myFormattedCallPlans?.future?.length == 0)}">

            <!-- OMNI-2358 adding UI changes for Callplan-->

            <!-- If the type isnt't call plan then proceed with the normal display -->

            <div *ngIf="type != 'SchedulerCallPlan'" [ngClass]="{'city-popover': this.type === popupType.SCi}">
                <!-- Display Selected Options -->
                <!-- <ion-item-divider *ngIf="((selectedOptions?.length>0) && !isSearching) && (type != 'SchedulerCity')">{{'SELECTED' | translate}} ({{selectedOptions?.length}})
                </ion-item-divider> -->
                <ind-section-header *ngIf="((selectedOptions?.length>0) && !isSearching) && (type != 'SchedulerCity')" [viewData]="selectedHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                <ion-list *ngIf="((selectedOptions?.length>0) && !isSearching) && (type != 'SchedulerCity')">
                    <ion-item class='selector-item' *ngFor="let sel of selectedOptions; let i = index;" (click)="openDetailsView($event, sel)">
                        <ion-icon slot="end" class='remove-icon' name="indegene-selectors-remove-icon" (click)="onItemSelection($event,sel,false)">
                        </ion-icon>
                        <span>{{sel.value}}</span>
                    </ion-item>
                </ion-list>

                <ind-section-header *ngIf="(!searchText) && (type != 'SchedulerCity')" [viewData]="allOptionHeaderModel"></ind-section-header>

                <!-- <ion-item-divider id="allOptions" *ngIf="(!searchText) && (type != 'SchedulerCity')">{{getSectionHeaderText}} ({{list?.length}})
                </ion-item-divider> -->

                <ind-section-header [hidden]="!searchText" [viewData]="resultsHeaderModel"></ind-section-header>

                <!-- <ion-item-divider [hidden]="!searchText" class="search-result">{{'RESULTS_CAP' | translate}} ({{ filterMetaData.count }}) </ion-item-divider> -->
                <ind-no-search-result *ngIf="searchText && filterMetaData.count === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                <ion-list *ngIf="list?.length>0">
                    <ion-item class="selector-item" *ngFor="let item of list | texfilter : searchText: undefined : filterMetaData" (click)="openDetailsView($event, item)">
                        <span class="item-label" text-wrap>{{item.value}}</span>
                        <ion-icon slot="end" *ngIf="(!item.isSelected) && (type != 'SchedulerCity')" class='add-icon' name="indegene-selectors-add-icon" (click)="onItemSelection($event,item,true)"></ion-icon>
                        <ion-icon slot="end" *ngIf="item.isSelected" class='checkmark-icon' name="indegene-selectors-checkmark-icon" (click)="onItemSelection($event,item,false)"></ion-icon>
                    </ion-item>
                </ion-list>
                <ion-list class="no-data-message" *ngIf="list?.length == 0 && !searchText">
                    {{ 'NO' | translate}} {{selectionHeader | lowercase}}
                </ion-list>
            </div>

            <div *ngIf="type == 'SchedulerCallPlan'">
                <!-- Display Selected Options -->

                <ion-list>
                    <ion-item-group *ngIf="list?.length>0">
                        <ion-list *ngIf="callPlanOfflineService.loadedCallPlans && (myFormattedCallPlans?.past?.length > 0 || myFormattedCallPlans?.present?.length > 0 || myFormattedCallPlans?.future?.length > 0)">
                          <ind-section-header *ngIf="selectedOptions && selectedOptions.length!=0" [viewData]="selectedCallPlanHeaderModel"></ind-section-header>

                            <!-- <ion-item-divider color="light" class="past-call-plans" *ngIf="selectedOptions && selectedOptions.length!=0">{{'SELECTED_CAP' | translate}} ({{selectedOptions.length}})
                            </ion-item-divider> -->
                            <div *ngIf="selectedOptions && selectedOptions.length!=0">
                                <ion-item class='selector-item' *ngFor="let plan of selectedOptions" (click)="openDetailsView($event, plan)" [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                                    {{plan.cycleplanname}}
                                    <span slot="end" >
                                      <img *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                                    </span>
                                    <span slot="end" class="compPercent">{{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%</span>
                                    <ion-icon class="remove-icon" slot="end" *ngIf="plan.isSelected" name="indegene-selectors-remove-icon" (click)="onItemSelection($event,plan,false)"></ion-icon>
                                </ion-item>
                            </div>

                            <!-- <ion-item-divider color="light" class="past-call-plans">{{'CP_PAST' | translate}} ({{(myFormattedCallPlans?.past | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length}})
                            </ion-item-divider> -->
                            <ind-section-header [viewData]="pastCallPlanHeaderModel"></ind-section-header>

                            <ion-item *ngIf="(myFormattedCallPlans?.past | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length === 0">
                                <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
                            </ion-item>
                            <ion-item class='selector-item' *ngFor="let plan of myFormattedCallPlans?.past | callPlanFilter:false:callPlanOfflineService.callPlanSearchText | orderBy:'cycleplanname':true" (click)="openDetailsView($event, plan)" [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                                {{plan.cycleplanname}}
                                <span slot="end" >
                                  <img *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                                </span>
                                <span slot="end" class="compPercent">{{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%</span>
                                <ion-icon class="add-icon" slot="end" *ngIf="(!plan.isSelected)" name="indegene-selectors-add-icon" (click)="onItemSelection($event,plan,true)"></ion-icon>
                                <ion-icon class="checkmark-icon" slot="end" *ngIf="plan.isSelected" name="indegene-selectors-checkmark-icon"></ion-icon>
                            </ion-item>

                            <ind-section-header [viewData]="currentCallPlanHeaderModel"></ind-section-header>

                            <!-- <ion-item-divider color="light" class="blueBackground current-call-plans" id="currentHeader">
                                {{'CP_CURRENT' | translate}} ({{(myFormattedCallPlans?.present | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length}})
                            </ion-item-divider> -->
                            <ion-item *ngIf="(myFormattedCallPlans?.present | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length === 0">
                                <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
                            </ion-item>
                            <ion-item class='selector-item' *ngFor="let plan of myFormattedCallPlans?.present | callPlanFilter:false:callPlanOfflineService.callPlanSearchText | orderBy:'cycleplanname':true" (click)="openDetailsView($event, plan)" [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                                {{plan.cycleplanname}}
                                <span slot="end">
                                  <img *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                                </span>
                                <span slot="end" class="compPercent">{{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%</span>
                                <ion-icon class="add-icon" slot="end" *ngIf="(!plan.isSelected)" name="indegene-selectors-add-icon" (click)="onItemSelection($event,plan,true)"></ion-icon>
                                <ion-icon class="checkmark-icon" slot="end" *ngIf="plan.isSelected" name="indegene-selectors-checkmark-icon"></ion-icon>
                            </ion-item>

                            <ind-section-header [viewData]="futureCallPlanHeaderModel"></ind-section-header>

                            <!-- <ion-item-divider color="light">{{'SMALL_FUTURE' | translate}} ({{(myFormattedCallPlans?.future | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length}})
                            </ion-item-divider> -->
                            <ion-item *ngIf="(myFormattedCallPlans?.future | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length === 0">
                                <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
                            </ion-item>
                            <ion-item class='selector-item' *ngFor="let plan of myFormattedCallPlans?.future | callPlanFilter:false:callPlanOfflineService.callPlanSearchText | orderBy:'cycleplanname':true" (click)="openDetailsView($event, plan)" [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                                {{plan.cycleplanname}}
                                <span slot="end">
                                  <img *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                                </span>
                                <span slot="end" class="compPercent">{{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%</span>
                                <ion-icon class="add-icon" slot="end" *ngIf="(!plan.isSelected)" name="indegene-selectors-add-icon" (click)="onItemSelection($event,plan,true)"></ion-icon>
                                <ion-icon class="checkmark-icon" slot="end" *ngIf="plan.isSelected" name="indegene-selectors-checkmark-icon"></ion-icon>
                            </ion-item>

                        </ion-list>

                        <div *ngIf="myFormattedCallPlans?.past?.length == 0 && myFormattedCallPlans?.present?.length == 0 && myFormattedCallPlans?.future?.length == 0">
                            <div class="no-data-message">
                                {{'YOU_DO_NOT_HAVE_SCHEDULED_CALL_PLANS' | translate}}
                            </div>
                        </div>

                    </ion-item-group>

                    <ion-item-group *ngIf="list?.length == 0">
                      <ind-section-header [viewData]="pastCallPlanHeaderModel"></ind-section-header>
                        <!-- <ion-item-divider color="light" class="past-call-plans">{{'CP_PAST' | translate}} ({{(myFormattedCallPlans?.past ).length}})
                        </ion-item-divider> -->
                        <ion-item *ngIf="(myFormattedCallPlans?.past).length === 0">
                            <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
                        </ion-item>
                        <ind-section-header [viewData]="currentCallPlanHeaderModel"></ind-section-header>
                        <!-- <ion-item-divider sticky='true' color="light" class="blueBackground" id="currentTeamHeader">{{'CP_CURRENT' | translate}} ({{(myFormattedCallPlans?.present).length}})</ion-item-divider> -->
                        <ion-item *ngIf="(myFormattedCallPlans?.present).length === 0">
                            <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
                        </ion-item>
                        <ind-section-header [viewData]="futureCallPlanHeaderModel"></ind-section-header>
                        <!-- <ion-item-divider sticky='true' color="light">{{'FUTURE' | translate}} ({{(myFormattedCallPlans?.future).length}})</ion-item-divider> -->
                        <ion-item *ngIf="(myFormattedCallPlans?.future).length === 0">
                            <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
                        </ion-item>
                        <!-- <ion-item>
                            <div class="no-data-message white-space">{{'NO_CALL_PLANS' | translate}}</div>
                        </ion-item> -->
                    </ion-item-group>

                </ion-list>
            </div>

        </ion-content>
        <!-- <ion-footer *ngIf="type !== 'SchedulerCity'"> -->
            <footer-toolbar *ngIf="type !== 'SchedulerCity'" [footerView]="'masterView'" [selectedView]="'callplans'"></footer-toolbar>
        <!-- </ion-footer> -->
    </ion-col>

    <ion-col *ngIf="(type != 'SchedulerCity')" class="right-pane">
        <call-plan-details [from]="uiService.page.SchedulerPopoverComponent" *ngIf="callPlanOfflineService.selectedCallPlan && isCallPlanSelected" (closeDetails)="closeDetails($event)"></call-plan-details>
        <nothing-selected-view [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'" *ngIf="!isCallPlanSelected" [textToDisplay]="getEmptyTextToDisplay()"></nothing-selected-view>
    </ion-col>

</ion-row>
