import { Lot } from "./lot.class";
import { format } from "date-fns";
import { SelectListData } from "../../components/popover/popover";
import { ShipmentStatus } from "../../enums/allocation/allocation.enum";
import { IShipmentReason } from "../../interfaces/allocation/allocation.shared.interface";

export class AllocationTransferForm {
    formattedShipmentDate: string;
    user: SelectListData;
    sku: SelectListData;
    lot: Lot;
    shipmentStatusTxt: string = '';
    isValid: boolean;
    isQtyValid: boolean;
    availableLots: Lot[];
    private _shipmentStatus: ShipmentStatus;
    private _shipmentDate: Date;
    private _quantityTransferred: number;
    private _reason: IShipmentReason;
    private _externalID: string;
    comments: string;
    
    constructor(
      externalId?: string,
      ) {
        this.shipmentStatus = ShipmentStatus.Open;
        this.shipmentDate = new Date();
        this.isValid = false;
        this.isQtyValid = false;
        this.availableLots = [];
        this.quantityTransferred = null;

        if (!externalId) {
          this._externalID = `offline_transfer_${new Date().getTime()}`;
        } else {
          this._externalID = externalId;
        }
    }

    set shipmentStatus(status: ShipmentStatus) {
        this._shipmentStatus = status;
        this._setShipmentStatusTxt(status);
    }
    get shipmentStatus(): ShipmentStatus {
        return this._shipmentStatus;
    }

    set reason(reason: IShipmentReason) {
        this._reason = reason;
        this.validate();
    }
    get reason() {
        return this._reason;
    }

    get external_id() {
      return this._externalID;
    }

    set shipmentDate(date: Date) {
        this._shipmentDate = date;
        this.formattedShipmentDate = format(date, 'MMM D, YYYY');
    }
    get shipmentDate(): Date {
        return this._shipmentDate;
    }

    set quantityTransferred(qty: any) {
        const qtyInt = parseInt(qty);
        this._quantityTransferred = !isNaN(qtyInt) ? qtyInt : null;
        this._validateQty();
        this.validate();
    }
    get quantityTransferred(): any {
        return this._quantityTransferred;
    }

    private _setShipmentStatusTxt(status: ShipmentStatus) {
        switch (status) {
            case ShipmentStatus.Open:
            case ShipmentStatus.Draft:
                this.shipmentStatusTxt = 'Open';
                break;
            case ShipmentStatus.ForReview:
              this.shipmentStatusTxt = 'ForReview';
              break;
            case ShipmentStatus.Shipped:
                this.shipmentStatusTxt = 'Shipped';
                break;
            case ShipmentStatus.NotApproved:
                this.shipmentStatusTxt = 'NotApproved';
                break;
            default:
                this.shipmentStatusTxt = '';
                break;
        }
    }

    private _validateQty() {
        this.isQtyValid = this.lot && this._quantityTransferred > 0 && this.lot.totalQuantityRemaining - this._quantityTransferred >= 0;
    }

    validate() {
        this.isValid = this.formattedShipmentDate && this.user && this.sku && this.reason && this.lot && this.isQtyValid;

        if(this.isValid && this.reason.value==548910000 && !this.comments) {
            this.isValid = false;
        }
    }
}
