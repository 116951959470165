import { Component, Input, Output, EventEmitter } from "@angular/core";
import { getDate, format } from "date-fns";
import { Contact } from "../../../../classes/contact/contact.class";
import { AuthenticationService } from "../../../../services/authentication.service";
import { ContactOfflineService } from "../../../../services/contact/contact.service";
import { ActivityDataService } from "../../../../data-services/activity/activity.service";
import { ActivityService } from "../../../../services/activity/activity.service";
import { NavController, LoadingController } from "@ionic/angular";
import { FooterService, FooterViews } from "../../../../services/footer/footer.service";
import { DeviceService } from "../../../../services/device/device.service";
import { UIService } from "../../../../services/ui/ui.service";
import { EmailService } from "../../../../services/email-templates/email.service";
import { Activity, ActivityType } from "../../../../classes/activity/activity.class";
import { NavigationService, PageName } from "../../../../services/navigation/navigation.service";
import { ScientificActivityPlan } from "../../../../classes/scientific-activity/scientific-activity.class";
import { ScientificActivityDataService } from "../../../../data-services/scientific-activity/scientific-activity.data.service";
import { TrackService, TrackingEventNames } from "../../../../services/logging/tracking.service";
import {DateTimeFormatsService} from "../../../../services/date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from "../../../../services/notification/notification.service";
import { AppointmentActivity } from "../../../../classes/activity/appointment.activity.class";
import { TranslateService } from "@ngx-translate/core";
import { EmailActivity } from "../../../../classes/activity/email.activity.class";


@Component({
    selector: 'scientific-activity-timeline',
    templateUrl: 'scientific-activity-timeline.html',
  styleUrls:['scientific-activity-timeline.scss']
})

export class ScientificActivityTimeline {

  public SPTimelineFilter: string;
  public getday = getDate;
  public isContactDetailsLoaded:boolean = false;
  public groupedActivities = [];
  private allActivities = [];
  private filteredActivities = [];
  public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  public weekDay: Array<String> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  public userName:string;


  @Input() selectedSAP: ScientificActivityPlan;
  @Input() selectedContact: Contact;
  @Output() openEventDetail:EventEmitter<any> = new EventEmitter();
  @Input() from: string;

  constructor(
    public authenticationService:AuthenticationService,
    private contactService: ContactOfflineService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public device: DeviceService,
    private uiService: UIService,
    private loadCtrl:LoadingController,
    public navService: NavigationService,
    public emailService: EmailService,
    public scientificPlanService: ScientificActivityDataService,
    private trackingService: TrackService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private translate:TranslateService
  ) {

  }

  ngOnInit(){
    this.initList();
    this.SPTimelineFilter = 'All_Activities';
  }
  async initList(){

    this.groupedActivities = [];
    if(!this.device.isOffline){
      this.contactService.isContactDetailsLoaded = false;
      let loading = await this.loadCtrl.create();
      loading.present();
      this.scientificPlanService.getTimelineDataBySPId(this.selectedSAP).subscribe((activities)=>{
        this.isContactDetailsLoaded = true;
        if(this.from === 'ContactTools'){
          activities = activities.filter((act) => {
                        if(act.type === ActivityType.Appointment){
                          if((act["_raw_contactAttendees"] && act["_raw_contactAttendees"].length>0)){
                            return act["_raw_contactAttendees"].some(contact => contact.indskr_contactid === this.selectedContact.ID);
                          }
                        }else if(act.type === ActivityType.Email){
                          if((act.emailActivityParties && act.emailActivityParties.length>0)){
                            return act.emailActivityParties.some(contact => contact.indskr_contactid === this.selectedContact.ID);
                          }
                        }

                        // if(act['contactId']){
                        //   return act['contactId'] === this.selectedContact.ID;
                        // }
                      });
        }
        this.allActivities = activities;

        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(activities);
        loading.dismiss();
      },err =>{
        loading.dismiss();
      });
      this.authenticationService.getOfflineUser().then((user)=>{
        if(user){
          this.userName = user.displayName;
          console.log("see this " +this.userName);
        }
        return;
      });
    }else{
      this.groupedActivities = [];
    }
  }

  ngDoCheck() {
    if(this.uiService.timelineRefreshRequired){
      this.uiService.timelineRefreshRequired = false;
      this.initList();
    }
      
  }

  public formatHeader(value): string {
    return value;
  }

  public onTimelineTypeChange(activityType) {

    switch (activityType){
      case 'All_Activities': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.allActivities);
        break;
      }
      case 'Appointment': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.allActivities.filter(activity=> activity.type == 'Appointment' && activity.location !='LiveMeet'));
        break;
      }
      case 'Email': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.allActivities.filter(activity=> activity.type == 'Email'));
        break;
      }
      case 'Followups': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.allActivities.filter(activity=> activity.type == 'followuptask'));
        break;
      }
      default: {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.allActivities);
      }
    }

  }

  openActivityDetails = async (selActivity) => {

    this.trackingService.tracking('ScientificPlanTimelineClick', TrackingEventNames.SCIENTIFICACTIVITYPLAN);

    const loading = await this.loadCtrl.create();
    await loading.present();
    // if(this.navCtrl.getActive().data && this.navCtrl.getActive().data.modalView && this.sampleService.inMeetingAllocationActivity) return
    if(this.device.isOffline){
      this.notificationService.notify("No Internet Connection available, please reconnect to see the timeline",'Scientific Timeline','top',ToastStyle.DANGER);
      return;
    }

    let activity = this.activityService.activities.find(item => item.ID == selActivity.ID);
    if(activity){
      selActivity.accessedFrom = PageName.ScientificActivityTimeline;
      this.openEventDetail.emit(selActivity);
    } else{
      if (selActivity instanceof AppointmentActivity) {
        let activity;
        this.activityDataService.getTimelineRawActivityDetails(selActivity).subscribe((data)=>{
          if(data){
            if(selActivity.type === ActivityType.Email){
              activity = new EmailActivity(data);
            }else if(selActivity.type === ActivityType.Appointment){
              activity = new AppointmentActivity(data);
              activity.accessedFrom = PageName.ScientificActivityTimeline;
            }
            if(activity) this.openEventDetail.emit(activity);
          }else{
            this.notificationService.notify(this.translate.instant('NO_DATA_TO_DISPLAY'),'Scientific Timeline','top',ToastStyle.DANGER);
          }
        })
      }
    }
    await loading.dismiss();
  }


  getTaskUser(taskUserList){
    if(taskUserList.length > 0){
      return taskUserList.map(tu => tu['userFullName']).join(", ");
    }
  }
  private combineEventsAndActivities(items: any) {
    if (this.contactService.contactInformation.activitesByContact) {
      items = [...this.contactService.contactInformation.activitesByContact];
    }
    return items;
  }

  async displayActivityDetails(activity:Activity){
    let foundActivity = this.activityService.getActivityByID(activity.ID);
    if(activity.type == ActivityType.Appointment){
      this.activityService.selected = (foundActivity) ? foundActivity : activity;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'activityTimelineDetails';
      this.uiService.prevView = 'scientificPlanDetails';
      await this.activityDataService.updateActivityDetails(foundActivity?foundActivity:activity);
      if (this.activityService.selectedActivity) {
        let activity = this.activityService.selectedActivity;
        this.footerService.initButtons(FooterViews.Activities);
      }
    }
  }

  isDataAvailable(){
    return (this.groupedActivities.length > 0 || this.SPTimelineFilter != 'All_Activities');
  }

  getSelectedText(value){
    const tot = this.groupedActivities.reduce((total,value)=> (total + value.list.length),0);
    switch (value){
      case 'All_Activities':
          return this.translate.instant('ALL_ACTIVITIES')+` `+`(${tot})`;
      case 'Appointment':
          return this.translate.instant('MEETINGS')+` `+`(${tot})`;
      case 'Email':
          return this.translate.instant('MESSAGES')+` `+`(${tot})`;
    }
  }

  formattedDate(val) {
    return format(val, this.dateTimeFormatsService.timeToUpper) || '';
  }
}
