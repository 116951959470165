<div class="left-float">
    <h3>{{timeOffData.totStartTime | date:'MMM dd'}}</h3>
    <p>{{calculateDuration}}</p>
</div>
<ion-thumbnail class="left-float">
  <img [src]="getTOTIcon(timeOffData)" width="20">
</ion-thumbnail>
<div text-left>
    <h2 class="dark-color ellipsis" text-nowrap>{{ getSubjectOrUser() }}</h2>
    <h4 class="light-color ellipsis" text-nowrap *ngIf="timeOffService.totMode === 'teamRequests'">
        <ion-icon color="dark" name="indegene-toReason"></ion-icon>
        {{ getReasonTeam }}
    </h4>
    <h4 class="light-color ellipsis" text-nowrap *ngIf="timeOffService.totMode !== 'teamRequests'">
        <ion-icon color="dark" name="indegene-toReason"></ion-icon>
        {{ getReason }}
    </h4>
</div>
