import { DeviceService } from './../../../services/device/device.service';
import { IndFormFieldViewDataModel } from './../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { RepServices } from './../../../data-services/rep/rep.services';
import { NavigationService } from './../../../services/navigation/navigation.service';
import { Component, Input } from '@angular/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';

/**
 * Generated class for the IndRightpaneReadonlyComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-rightpane-readonly',
  templateUrl: 'ind-rightpane-readonly.html',
  styleUrls:['ind-rightpane-readonly.scss']
})
export class IndRightpaneReadonlyComponent {
  viewData: [{
    header:IndSectionHeaderViewDataModel,
    fields: IndFormFieldViewDataModel[]
  }];
  @Input() viewHeader: string;
  @Input() webResource: {url:string, type:string, name?: string, isSupportedFileType?:boolean};
  @Input() displayData: any;
  agreementPageTitle: IndPageTitleViewDataModel;
  constructor(
    public navService: NavigationService,
    public repService: RepServices,
    public device: DeviceService
  ) {

  }

  ngOnInit() {
    if(this.displayData){
      this.viewData = this.displayData;
    }
    if(this.webResource){
      if(this.webResource.type == 'doc'){
        this.webResource.url = 'https://view.officeapps.live.com/op/embed.aspx?src='+this.webResource.url;
      }
    }
    this.initAllDataModels();
  }

  onClosePage(){
    this.navService.popChildNavPageWithPageTracking();
  }

  
  public initAllDataModels(){
    this.getPageTitle();
  }


  public getPageTitle(){
    const buttons = [];
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        isVisible: true,
        align: 'left'
      });
    this.agreementPageTitle = {
      id: 'activities-details-page-title',
      title: this.viewHeader,
      controls: buttons.filter(o => o.isVisible)
    };
  }


  public handleButtonClick(id: string): void {
    this.handleButtonEventForAgreement(id);
  }


  private handleButtonEventForAgreement(buttonId: string){
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
        default:
        return;
    }
  }

}
