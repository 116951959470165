import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ContactEvent, EventRegistration } from '@omni/classes/customer-event/customer-event.class';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { AlertOptions, OmniAlertComponent } from '@omni/components/shared/omni-alert/omni-alert.component';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { AttendeeFormat, EventModeOfCheckIns, EventRegistrationStatus } from '@omni/enums/event/event.enum';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { BarcodeStatus, BarcodescannerService } from '@omni/services/barcodescanner/barcodescanner.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { CustomerEventsService } from '@omni/services/customer-event/customer-events.service';
import { DeviceService } from '@omni/services/device/device.service';
import { DiskService } from '@omni/services/disk/disk.service';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { EventsService } from '@omni/services/events/events.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import _ from 'lodash';
import { UIService } from "../../../services/ui/ui.service";
import { EventHistory } from '@omni/classes/contact/scientific-info.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { OperationDetail } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { Contact } from '@omni/classes/marketing-management/marketing-plan.class';
import { PdfPreviewCmpInputData, PdfPreviewCmpJsPDFInput, PdfPreviewCmpSourceType, PdfPreviewComponent } from '@omni/components/pdf-preview/pdf-preview';
import { SIGNATURE_DOC_FILE_NAME_SUFFIX } from '@omni/services/pdf.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { ConsentSignatureModalComponent } from '@omni/components/contact/consent-signature-modal/consent-signature-modal';
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { AlertService } from '@omni/services/alert/alert.service';



@Component({
  selector: 'app-event-attendies-list',
  templateUrl: './event-attendies-list.component.html',
  styleUrls: ['./event-attendies-list.component.scss'],
})
export class EventAttendiesListComponent implements OnInit {
  @Input() currentEvent: EventActivity;
  public EventAttendiesPageTitle: IndPageTitleViewDataModel;
  public participantsHeader: IndPageTitleViewDataModel;
  public viewModel: Array<MainCardViewDataModel> = [];
  isEventAttendanceCapture: boolean = false;
  private isReadOnlyMode: boolean = false;
  isCompletedEvent: boolean = false;
  private ngUnsubscribe$ = new Subject<boolean>();

  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  eventsFilterPopover:any
  public filteredParticipantList:Array<MainCardViewDataModel> = [];
  searchText:string = ''
  constructor(
    private translate: TranslateService,
    public navService: NavigationService,
    public footerService: FooterService,
    public modalCtrl: ModalController,
    public notificationService: NotificationService,
    private barcodescannerService: BarcodescannerService,
    public events: EventsService, // ionic-angular
    private device: DeviceService,
    public popoverCtrl: PopoverController,
    private eventsToolDataService: EventsToolDataService,
    private authService: AuthenticationService,
    private UIService: UIService,
    private eventsToolService: EventsToolService,
    private contactService: ContactOfflineService,
    private disk: DiskService,
    private customerEventsService: CustomerEventsService,
    private alertService: AlertService
    
  ) {
    this.eventsToolService.selectedEventUpdatesObserver$
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      ).subscribe((eventActivity: EventActivity) => {
        if (eventActivity) {
          this.currentEvent = eventActivity;
          const isCovisitor = this.currentEvent.covisitors.some(c => c.id === this.authService.user.systemUserID && c.teamMemberStatecode === 0 && c.isDeleted === false)
            && this.currentEvent.ownerId !== this.authService.user.systemUserID;
          this.isReadOnlyMode = (this.currentEvent.ownerId != this.authService.user.systemUserID && !isCovisitor) || !((this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 1) || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 548910007));
          if (this.currentEvent.statuscode == 548910005 && this.currentEvent.statecode == 1) {
            this.isCompletedEvent = true;
            this.isReadOnlyMode = true;
          }
          //this._initViewModels();
        }
      });

    this.device.screenWidth.pipe(
      takeUntil(this.ngUnsubscribe$),
      debounceTime(400),
    ).subscribe((newOrientation) => {
      this._initHeaderView();
    });

  }

  filterCheckedInFlag:boolean = null
  ngOnInit() {
    this._initViewModels()
    // this._initHeaderView();
    const isCovisitor = this.currentEvent.covisitors.some(c => c.id === this.authService.user.systemUserID && c.teamMemberStatecode === 0 && c.isDeleted === false)
      && this.currentEvent.ownerId !== this.authService.user.systemUserID;
    this.isReadOnlyMode = (this.currentEvent.ownerId != this.authService.user.systemUserID && !isCovisitor) || !((this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 1) || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 548910007));
    if (this.currentEvent.statuscode == 548910005 && this.currentEvent.statecode == 1) {
      this.isCompletedEvent = true;
      this.isReadOnlyMode = true;
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_ATTENDANCE_CAPTURE)) {
      this.isEventAttendanceCapture = true;
    }


    // declaring filter option list
    this.filterPopoverData = [

      {
        text: '',
        value: 'All',
        items: [
          { text: this.translate.instant('All'), value: 'All' },
          { text: this.translate.instant('Checked In'), value: 'Checked_In' },
          { text: this.translate.instant('Not Checked In'), value: 'Not_Checked_In' }
        ],
        handler: (selectedItem, item, itemRef) => {
          // itemRef.parent.items[0].value = ''
          item.value = selectedItem.value;

          if(selectedItem.value=='All')
          {
            this.filterCheckedInFlag = null
          }else if(selectedItem.value=='Checked_In'){
            this.filterCheckedInFlag = true
          }else if(selectedItem.value=='Not_Checked_In'){
            this.filterCheckedInFlag = false
          }else{
            this.filterCheckedInFlag = null
          }
          
          this.filterParticipants(this.searchText);
        
        }
      }

    ];
  }



  ionViewWillEnter() {
    this.footerService.initButtons(FooterViews.CAPTURE_EVENTS);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  private _initViewModels(updatedEvent?): void {
    if (updatedEvent != null) {
      this.currentEvent = updatedEvent
    }
    this._initHeaderView();
    // this.getDetailsHeader();
    this.viewModel = [];
    this.currentEvent.participants.forEach(value => {
      let foundContact = this.contactService.getContactByID(value.id);
      value.name = value.firstName;
      value.initials = value.firstName?.charAt(0).toUpperCase();
      if (!_.isEmpty(value.lastName)) {
        value.initials += value.lastName?.charAt(0).toUpperCase();
        value.name += " " + value.lastName;
      }
      value.initials = value.firstName?.charAt(0).toUpperCase() + value.lastName?.charAt(0).toUpperCase();
      const isRemoved = value.isDeleted || value.indskr_reasons === EventRegistrationStatus.Cancelled
        || value.indskr_reasons === EventRegistrationStatus.Declined
        || value.indskr_reasons === EventRegistrationStatus['Cancelled late'];
      if (!isRemoved
        && ((this.currentEvent.attendeeFormat === AttendeeFormat.propose && (value.indskr_reasons === EventRegistrationStatus.Accepted || value.indskr_reasons === EventRegistrationStatus.Invited))
          || this.currentEvent.attendeeFormat !== AttendeeFormat.propose)) {
        let viewDataModel: MainCardViewDataModel = ({
          id: value.id,
          showCheckBox: true,
          checkboxChecked: (value.signature || value.checkinId || value.msevtmgt_checkintime) ? true : false,
          checkboxDisabled: true,
          primaryTextLeft: value.name,
          secondaryTextLeft: foundContact?.raw['emailaddress1'],
          expandableData: value.signature,
          showAccordionIcon: this.isReadOnlyMode && !value.signature ? false : true,
          disableCheckInBtn: value.checkinId && !value.signature,
          accordionIconCssClass: (value.indskr_reasons === EventRegistrationStatus['Checked-in'] && !value.signature) || (value.checkinId && !value.signature) ? 'accordion-icon-disabled' : '',
          accordionIconType: 'checkmark-circle',
          isExpanded: false,
          initials: value.initials,
          registrationid: value.registrationId,
          checkinId: value.checkinId,
          ERNumber: value.ERNumber,
          primarySpecialty: value.primarySpecialty,
          clickHandler: (id, eventName, viewDataModel) => this._handleActiveConsentExpandClick(id, eventName, viewDataModel),
        });
        this.viewModel.push(viewDataModel);
      }
    })

    this.filteredParticipantList = this.viewModel
    this.filterParticipants()

  }
  private _handleActiveConsentExpandClick(id, eventName, viewDataModel): void {
    if(this.isCompletedEvent){
      return
    }
    if (eventName && eventName == 'accordionIcon') {
      if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_ATTENDANCE_CAPTURE)) {
        if (!viewDataModel.checkboxChecked) {
          this.openConfirmationPopup(viewDataModel)
        } else if (viewDataModel.checkboxChecked) {
          this.openConfirmationPopup(viewDataModel)
        }
      } else {
        let targetConsent = this.viewModel.find(value => value.id == id);
        if (targetConsent) {
          targetConsent.isExpanded = !targetConsent.isExpanded;
          if (targetConsent.isExpanded) {
            viewDataModel.accordionIconType = 'checkmark-circle';
            viewDataModel.isExpanded = true;
          } else {
            viewDataModel.accordionIconType = 'checkmark-circle';
            viewDataModel.isExpanded = false;
          }
        }
      }
    }

  }

  openConfirmationPopup(item) {
     
        if (!item.checkboxChecked) {
          this.alertService.showAlert({
            message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_CHECK_IN')
          }, this.translate.instant('YES')
          ).then(res => {
            if (res.role == "ok") {
              this.onCheckIn(item)
            }
          });
        } else if (item.checkboxChecked) {
          this.alertService.showAlert({
            message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_UN_CHECK')
          }, this.translate.instant('YES')
          ).then(res => {
            if (res.role == "ok") {
              this.onCheckIn(item)
            }
          });
        }


  }



   async launchSignaturePad(participantsId: string) {
    //signature capture
    let popover = await this.popoverCtrl.create({ component: ConsentSignatureModalComponent, componentProps: {}, cssClass: 'signature-pad', backdropDismiss: false });
    popover.present();
    popover.onDidDismiss().then(async (signData: any) => {
      let data = signData.data;
      if (data && data.completedFlow) {
        const participant = this.currentEvent.participants.find(e => e.id == participantsId)
        participant.signature = data.signatureData;
        participant.msevtmgt_checkintime = new Date();
        delete participant.indskr_reasons;
        delete  participant.indskr_modeofcheckin;
        let model = this.viewModel.find(e => e.id == participantsId);
        if (model) {
          model.expandableData = data.signatureData;
          model.checkboxChecked = true;
        }
        participant.indskr_modeofcheckin = EventModeOfCheckIns["Signature"];
        const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
        await this._updateCurrentEvent(contactsDTO);
        this._initViewModels();
        participant.indskr_reasons = EventRegistrationStatus["Checked-in"];
        this.viewModel.find(e => e.id == participantsId).isExpanded = true;
        this.viewModel.find(e => e.id == participantsId).accordionIconType = 'accordion-minus';
        let contact = this.contactService.getContactByID(participantsId);
        if (!contact.scientificInfo.eventHistory.find(e => e.indskr_eventid == this.currentEvent.ID)) {
          let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
          let eventData = new EventHistory(this.currentEvent.ID, this.currentEvent.name, participant.msevtmgt_checkintime.getTime().toString(), this.currentEvent.endDate.getTime().toString(), this.currentEvent.startDate.getTime().toString());
          eventData["indskr_contactid"] = participant.id;
          offlineData.raw.scientificInformation.eventHistory.push(eventData);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save contact profile data in offline db error: ', error));
          contact.scientificInfo.eventHistory.push(eventData);
        }

      }
    })
  }

  async scanAttendies() {
    const barCodeResponse = await this.barcodescannerService.openBarCodeScanner();
    if (!barCodeResponse || barCodeResponse.status !== BarcodeStatus.SUCCESS) {
      if (barCodeResponse.status === BarcodeStatus.ERROR_OCCURED) {
        this.notificationService.notify(this.translate.instant('NOT_ABLE_TO_READ_BARCODE'), 'Event Details', 'top', ToastStyle.DANGER);
      }
      return;
    }
    this.addAttendiesByBarcode(barCodeResponse.data)
    // this.addAttendiesByBarcode("71226dce-ceb1-e911-a84d-000d3a80ecb4")
  }

  async addAttendiesByBarcode(registrationId) {
    let options;
    const participant = this.currentEvent.participants.find(e => e.ERNumber == registrationId || e.id == registrationId)
    if (!participant) {
      // ! if no participant check for contact id   
      const con = this.contactService.getContactByID(registrationId);
      if (!con) {
        options = {
          mainImgSrc: 'assets/imgs/round-red-close.svg',
          message: this.translate.instant('QR_CHECK_IN_FAILED'),
          subText: this.translate.instant('DIFFERENT_EVENT_FOUND_FOR_THE_QR'),
        }
        const response = await this.openAttendiesAlert(options);
        if (response && response.data) {
          this.scanAttendies();
        }
        return;
      }

      //contact checkin
      let cont = {
        id: con.ID,
        name: con.fullname,
        statecode: con.isActive ? 0 : 1,
        ownerId: this.authService.user.systemUserID,
        customerId: con.customerId,
        title: con.title,
        firstName: con.firstName,
        middleName: con.middleName,
        lastName: con.lastName,
        primaryAccount: con.getPrimaryAccountRelationship,
        primarySpecialty: con.primarySpecialty,
        raw: con.raw,
        eventPasses: participant ? participant['eventPasses'] : [],
        primaryEmail: con.raw.emailaddress1
      }
      let data = { selectedItems: [cont], isDone: true };
      let isCovisitorView = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_UPDATE)
        && this.currentEvent.covisitors.some(c => c.id === this.authService.user.systemUserID
          && c.teamMemberStatecode === 0 && c.isDeleted === false)
        && this.currentEvent.ownerId !== this.authService.user.systemUserID;
      let values = await this.eventsToolDataService._handleContactComponentCallback(data, isCovisitorView, this.currentEvent, true);
      let payload;
      values[0].addParticipants[0].signature = '';
      values[0].addParticipants[0].msevtmgt_checkintime = new Date();
      delete values[0].addParticipants[0].indskr_reasons;
      payload = {
        eventCheckInByContact: [
          {
            contactid: values[0].addParticipants[0].contactid,
            indskr_reasons: values[0].addParticipants[0].indskr_reasons,
            indskr_source: values[0].addParticipants[0].indskr_source,
            msevtmgt_checkintime: new Date(),
            indskr_signature: "",
            indskr_modeofcheckin :EventModeOfCheckIns["QR Code"]
          },
        ]
      }
      await this._updateCurrentEvent(payload)
      let model = this.viewModel.find(e => e.id == values[0].addParticipants[0].contactid);
      if (model) {
        model.checkboxChecked = true;
      }
      this._initViewModels();
      let checkinContact = this.currentEvent.participants.find(p => p.id ==values[0].addParticipants[0].contactid);
      this.displaySuccessfulCheckinMsg(checkinContact)
      let contact = this.contactService.getContactByID(cont.id);
      if (!contact.scientificInfo.eventHistory.find(e => e.indskr_eventid == this.currentEvent.ID)) {
        let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
        let eventData = new EventHistory(this.currentEvent.ID, this.currentEvent.name, participant.msevtmgt_checkintime.getTime().toString(), this.currentEvent.endDate.getTime().toString(), this.currentEvent.startDate.getTime().toString());
        eventData["indskr_contactid"] = participant.id;
        offlineData?.raw?.scientificInformation?.eventHistory?.push(eventData);
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
          doc = offlineData;
          return doc;
        }).catch(error => console.error('Save contact profile data in offline db error: ', error));
        contact?.scientificInfo?.eventHistory?.push(eventData);
      }
    }
    else if (participant.checkinId) {
          participant.name = participant.firstName;
          participant.initials = participant.firstName?.charAt(0).toUpperCase();
          if (!_.isEmpty(participant.lastName)) {
            participant.initials += participant.lastName?.charAt(0).toUpperCase();
            participant.name += " " + participant.lastName;
          }
          participant.initials = participant.firstName?.charAt(0).toUpperCase() + participant.lastName?.charAt(0).toUpperCase();
          let foundContact = this.contactService.getContactByID(participant.id);
          let phoneNmuber = foundContact['indskr_alternatephone1'] || foundContact['indskr_alternatephone2'] || foundContact['indskr_alternatephone3']
          let address = foundContact.addressesList.filter(address => address)
          let address1 = address[0]?.concatanatedAddress + ',' + address[0]?.street + ',' + address[0]?.country

          let options = {
            mainImgSrc: 'assets/imgs/round-grey-tick.svg',
            message: this.translate.instant('CHECKED_IN_ALREADY'),
            subText: this.translate.instant('REGISTRATION_ID') + ` : ${participant.ERNumber}`,
            list: [{
              initials: `${participant.initials}`,
              itemText: `${foundContact.raw['fullname']}`,
              subText: [`${foundContact.raw['emailaddress1']}`, `${phoneNmuber}`, `${address1}`]
            }],
          }
          const response = await this.openAttendiesAlert(options);
          if (response && response.data) {
            this.scanAttendies();
            return;
          }
    }else{

                // participant.signature = data.signatureData;
          participant.msevtmgt_checkintime = new Date();
          delete participant.indskr_reasons;
          delete  participant.indskr_modeofcheckin;
          // let model = this.viewModel.find(e => e.id == participant.id);
          // if(model){
          //   model.expandableData = data.signatureData;
          //   model.checkboxChecked = true;
          // }
          participant.indskr_modeofcheckin = EventModeOfCheckIns["QR Code"];
          const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
          await this._updateCurrentEvent(contactsDTO);
          this.displaySuccessfulCheckinMsg(participant)
          this._initViewModels();
          participant.indskr_reasons = EventRegistrationStatus["Checked-in"];
          // this.viewModel.find(e => e.id == participantsId).isExpanded = true;
          // this.viewModel.find(e => e.id == participantsId).accordionIconType ='accordion-minus';
          let contact = this.contactService.getContactByID(participant.id);
          if (!contact.scientificInfo.eventHistory.find(e => e.indskr_eventid == this.currentEvent.ID)) {
            let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
            let eventData = new EventHistory(this.currentEvent.ID, this.currentEvent.name, participant.msevtmgt_checkintime.getTime().toString(), this.currentEvent.endDate.getTime().toString(), this.currentEvent.startDate.getTime().toString());
            eventData["indskr_contactid"] = participant.id;
            offlineData?.raw?.scientificInformation?.eventHistory?.push(eventData);
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
              doc = offlineData;
              return doc;
            }).catch(error => console.error('Save contact profile data in offline db error: ', error));
            contact?.scientificInfo?.eventHistory?.push(eventData);
          }

    }




  }

  displaySuccessfulCheckinMsg(participant){
    participant.name = participant.firstName;
    participant.initials = participant.firstName?.charAt(0).toUpperCase();
    if (!_.isEmpty(participant.lastName)) {
      participant.initials += participant.lastName?.charAt(0).toUpperCase();
      participant.name += " " + participant.lastName;
    }
    participant.initials = participant.firstName?.charAt(0).toUpperCase() + participant.lastName?.charAt(0).toUpperCase();
    const foundContact = this.contactService.getContactByID(participant.id);
    let phoneNmuber = foundContact['indskr_alternatephone1'] || foundContact['indskr_alternatephone2'] || foundContact['indskr_alternatephone3']
    const address = foundContact.addressesList
    let address1

    //set address of contact
    if(address && address.length>0){
      address1 = address[0].concatanatedAddress? address[0].concatanatedAddress:''
                 +  address[0].street? ', '+address[0].street:''
                 +  address[0].country? ', '+address[0].country:'';
    
      address1 = address1.replace(/^,/, "")
      }
    
    let options = {
      mainImgSrc: 'assets/imgs/round-blue-tick.svg',
      message: this.translate.instant('CHECKED_IN_SUCCESSFUL'),
      subText:  (!this.device.isOffline) ? this.translate.instant('REGISTRATION_ID') + ` : ${participant.ERNumber || participant.eventRegistrationName }` : '',
      list: [{
        initials: `${participant.initials}`,
        itemText: `${foundContact.raw['fullname']}`,
        subText: [foundContact.raw['emailaddress1'], phoneNmuber, address1].filter(Boolean)
      }],
    }
   this.openAttendiesAlert(options).then((response)=>{
      if (response && response.data) {
        this.scanAttendies();
      }
    });

  }


  private async _updateCurrentEvent(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let action: OperationDetail = {
        onDynamics: !this.device.isOffline,
        onLocalDatabase: true,
        onLocalCopy: false,
        operationDetail: {
          code: '',
          message: '',
          payload,
        }
      };
      this.currentEvent.modifiedOn = new Date();
      this.currentEvent.pendingPushToDynamics = true;
      await this.UIService.displayLoader();
      this.eventsToolDataService.updateEventsData(action, [this.currentEvent]).then(async (value: Array<EventActivity>) => {
        this.currentEvent = value[0];
        this.eventsToolService.setSelectedEventUpdates(value[0]);
        const removeParticipantsID: any = value[0].participants.reduce((result, participant) => {
          if (participant.msevtmgt_checkintime) {
            result.push(participant.id)
          }
          return result;
        }, [])
        await this.customerEventsService.removeCustomerEventsForRemovedEventParticipants(this.currentEvent, removeParticipantsID);
        await this.UIService.dismissLoader();
        //this._initViewModels();
        resolve('');
      }).catch(err => {
        //this._initViewModels();
        this.UIService.dismissLoader();
        resolve('');
        console.log('Error updating event activity');
      })
    });
  }



  async openAttendiesAlert(options: AlertOptions) {
    const modal = await this.modalCtrl.create({
      component: OmniAlertComponent,
      cssClass: 'omni-alert-modal',
      componentProps: {
        inputOptions: {
          ...options,
          cancelBtnText: this.translate.instant('CLOSE'),
          confirmBtnText: this.translate.instant('SCAN_QRCODE')
        }
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then((data) => {
      // this.scannedProduct = null;
      return data;
    });
  }
  private _initHeaderView(): void {
    let titleButtons = []
    titleButtons = [
      {
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      },
      {
        id: "QRcode-icon",
        imgSrc: "assets/imgs/qr_code_white.svg",
        name: "Scan QR",
        isDisabled: false,
        align: "right"
      }
    ];

    this.EventAttendiesPageTitle = {
      id: 'capture_events_title',
      title: `Attendance - ${this.currentEvent.name}`,
      controls: titleButtons,
    };
  }

  public getDetailsHeader() {
    let buttons = [];
    buttons.push(
      {
        id: "refresh-icon",
        text: this.translate.instant('SYNC'),
        iconClass: 'refresh-icon',
      }
    );

    this.participantsHeader = {
      id: 'participants-details-header',
      title: this.translate.instant('PARTICIPANTS_CHECK_LIST') + '(' + this.viewModel.length + ')',
      controls: buttons,
    };
  }
  public onPageTitleControlClick(id: string): void {
    if (id && id == 'close') {
      this._closePage();
    } else if (id && id == 'QRcode-icon') {
        this.scanAttendies();
    }
  }

  footerButtonClicked(buttonId: string) {
    if (buttonId === 'pdfPreview') {
      this._handlePdfPreview();
    } else if (buttonId === 'QRcodeScan') {
      this.scanAttendies();
    }
  }

  private _handlePdfPreview() {
    let data: PdfPreviewCmpInputData = {
      type: PdfPreviewCmpSourceType.jsPDF,
      data: {
        data: this.currentEvent,
        jsPdfGenerator: async (data, pdfService) => {
          let doc = await pdfService.generateEventSignatureListPdfDoc(data);
          return doc;
        },
        allowExport: true,
        exportHandler: (jsPdfDoc, fileName, pdfService) => {
          return pdfService.exportPDF(jsPdfDoc, fileName);
        },
        exportFileName: `${this.currentEvent.name}${SIGNATURE_DOC_FILE_NAME_SUFFIX}`
      } as PdfPreviewCmpJsPDFInput
    };

    this.navService.pushWithPageTracking(PdfPreviewComponent, PageName.PdfPreviewComponent, { pdfData: data }, PageName.PdfPreviewComponent);
  }

  private _closePage(): void {
    this.navService.popChildNavPageWithPageTracking();
  }

  // check in and uncheck function implementation
  public async onCheckIn(item) {
    const participant = this.currentEvent.participants.find(e => e.id == item.id)
    participant.signature = '';
    participant.msevtmgt_checkintime = new Date();
    delete participant.indskr_reasons;
    delete participant.indskr_modeofcheckin;
    item.expandableData = null;
    if (!item.checkboxChecked) {
      item.checkboxChecked = true;
      participant.indskr_modeofcheckin = EventModeOfCheckIns["Manual"];
      const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
      await this._updateCurrentEvent(contactsDTO);
      this._initViewModels();
      participant.indskr_reasons = EventRegistrationStatus["Checked-in"];
      participant.indskr_modeofcheckin = EventModeOfCheckIns["Manual"];
    } else if (item.checkboxChecked) {
      item.checkboxChecked = false;
      // const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
      const contactsDTO = {
        "uncheckParticipants": [
            {
                "msevtmgt_eventregistrationid": item.registrationid,
                "msevtmgt_checkinid": item.checkinId,
                "indskr_signature": "",
                "indskr_reasons": EventRegistrationStatus["Proposed"] 
            }
        ]
    }
      await this._updateCurrentEvent(contactsDTO);
      participant.indskr_reasons = EventRegistrationStatus["Proposed"];
      participant.checkinId = null
      participant.msevtmgt_checkintime=null

      this._initViewModels();
    }
    let contact = this.contactService.getContactByID(item.id);
    if (!contact.scientificInfo?.eventHistory?.find(e => e.indskr_eventid == this.currentEvent.ID)) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
      let eventData = new EventHistory(this.currentEvent.ID, this.currentEvent.name, participant.msevtmgt_checkintime?participant.msevtmgt_checkintime.getTime().toString():null, this.currentEvent.endDate.getTime().toString(), this.currentEvent.startDate.getTime().toString());
      eventData["indskr_contactid"] = participant.id;
      offlineData.raw.scientificInformation.eventHistory.push(eventData);
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
        doc = offlineData;
        return doc;
      }).catch(error => console.error('Save contact profile data in offline db error: ', error));
      contact.scientificInfo?.eventHistory.push(eventData);
    }
  }


  //open filter popup
  async openFilter(event) {
    this.eventsFilterPopover = await this.popoverCtrl.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    this.eventsFilterPopover.present();
    this.eventsFilterPopover.onDidDismiss().then(() => {
      this.eventsFilterPopover = null;
    });
  }


  //search , filter participants
  filterParticipants(searchKey:string=""){
    this.searchText = searchKey 
    if(searchKey !=null && searchKey.length>0){
      this.viewModel = this.filteredParticipantList.filter(item=>
        item.primaryTextLeft?.toUpperCase().includes(searchKey.toUpperCase()) || item.secondaryTextLeft?.toUpperCase().includes(searchKey.toUpperCase())
      )

   
    }else{
      this.viewModel = this.filteredParticipantList
    }
    if(this.filterCheckedInFlag !=null){
      this.viewModel = this.viewModel.filter(item=>item.checkboxChecked ==this.filterCheckedInFlag)
    }



    this.getDetailsHeader();
  }

  //participants sync function
  syncEventDetails(event){
    this.UIService.displayLoader();
    this.eventsToolDataService.fetchEventRealTimeDetails(this.currentEvent)
    .then((result)=>{
      this._initViewModels(result)

    })
    .catch((error)=>{
      console.log("error occurred during event sync: "+error)

    })
    .finally(() => {
      this.UIService.dismissLoader();
    })
  }
}
