import { Pipe, PipeTransform } from '@angular/core';
import { format, isValid } from 'date-fns';
@Pipe({
    name: "indDateFormat"
})
export class IndDateFormatPipe implements PipeTransform{
    transform(value: any, formatting?: string): string {
      let formatedDate;
      if(formatting){
        formatedDate = isValid(new Date(value)) ? format(value, formatting) : '';
      }
      else {
        formatedDate = isValid(new Date(value)) ? format(value,"MMM DD, YYYY") : '';
      }
      return formatedDate;
    }

}
