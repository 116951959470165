import { ActivityType } from './../activity/activity.class';
import { Utility } from './../../utility/util';
import { OrderProductCategory } from '../activity/surgery-order.activity.class';
import { IONote } from '../io/io-note.class';
import { MarketingPlan } from '../marketing-management/marketing-plan.class';
export enum QuoteState {
  DRAFT = 0,
  ACTIVE = 1,
  WON = 2,
  CLOSED = 3
}
export class Opportunity{
  public ID: string;
  public offlineId: string = '';
  public opportunityName: string;
  public createdByUserID: string;
  public createdByUserName: string;
  public createdOn: string;
  public ownerID: string;
  public ownerName: string;
  public estimatedDate: string;
  public closedValue: number;
  public closedValueFormatted: string;
  public estimatedValue: number;
  public estimatedValueFormatted: string;
  public countryId:string = '';
  public countryName: string;
  public stateCode: number;
  public state: string
  public status: string;
  public statusCode: number;
  public purchaseProcess: number;
  public purchaseProcessFormatted: string;
  public elligibleDiscount:number;
  public accountID: string;
  public accountName: string;
  public accountPlanID: string;
  public accountPlanName: string;
  public products: OpportunityProduct[];
  public opportunityObjectiveID: string;
  public opportunityObjectiveName: string;
  public transactionCurrencySymbol : string;
  public transactionCurrencyID : string;
  public total : number;
  public totalFormatted: string;
  public trackAction: string;
  public lastUpdated: number;
  public priceList: string;
  public stakeholders: Array<OpportunityContact> = [];
  public pendingPushToDynamics:boolean = false;
  public isTeamOpportunity: boolean = false;
  public collaboratorType : CollaboratorType = CollaboratorType.OWNER;
  public isHardDeleted:boolean = false;
  public isrevenuesystemcalculated:boolean = true;
  public opportunityStage: string;
  public opportunityStageValue: string;
  public businessUnitId: string;
  public businessUnitFormatted: string;
  public appointmentId: string;
  public opportunityTypename: string;
  public opportunityTypeId: string;
  public opportunitySubTypename: string;
  public opportunitySubTypeId: string;
  public closeDescriptionText: string;
  public closedDate: string;
  public surgeryId : string;
  public surgeryNameString : string;
  public productCategory : Array <OrderProductCategory> = [];
  public collaborators: Array<OpportunityCollaborators> = [];
  public opportunityProgressPercent: any;
  public opportunityNotes: Array<IONote> = [];
  public activitiesByOpportunity: Array<OpportunityActivities> = [];
  public opportunityBusinessProcessInstanceId:string;
  public opportunityBusinessProcessSchemaName:string;
  public stageMoved:any;
  public surveyResponse:any;
  public surveyResponseData:any;
  public surveyResponseLookupData:any;
  public surveyResponseAdditionalData:any;
  public surveyResponseDTO:any = {};
  public competitors: Array<OpportunityCompetitor> = []
  public competitorProducts: Array<OpportunityCompetitorProduct> = [];
  public competitorBusinessValue:number;
  public competitorBusinessValueFormatted:string;
  public isestimatedrevenuebasedonprocedureandproducts: boolean;
  public indskr_source: number;
  public indskr_source_Formatted: string;
  public opportunityratingcode: number;
  public opportunityratingcode_Formatted: string;
  public indskr_affordability: number;
  public indskr_affordability_Formatted: string;
  public indskr_systemrating: number;
  public indskr_systemrating_Formatted: string;
  public indskr_gemnongem: number;
  public indskr_gemnongem_Formatted: string;
  public indskr_sourceothers: string;
  public opportunityReasonID: string;
  public opportunityReason: string;
  public indskr_progressstatus: number;
  public indskr_allowstagemovementbyuser: boolean = true;
  public indskr_allowbackwardstagemovement: boolean = true;
  public marketingBusinessPlans: MarketingPlan[] = [];
  public productManager: ProductManager;
  public parentOpportunityId: string;
  public parentOpportunityName: string;
  public primarySpecialtyId: string;
  public primarySpecialtyName: string;
  public subSpecialtyId: string;
  public subSpecialtyName: string;
  public primaryTargetName: string;
  public primaryTargetValue:OpportunityPrimaryTraget;
  public contactName: string;
  public contactID: string;
  public stakeholderValue:number = 0;
  public stakeholderValueFormatted:string = "0.00";
  public opportunityCloseReasons:Array<{
    reasonId:string,
    reasonName?:string,
  }> = [];
  public focusArea: string;
  constructor(raw:any){
    this.opportunityName = raw['name'] ||'';
    this.ID = raw['opportunityid'];
    this.createdByUserID =  raw['_createdby_value'] || raw['createdby'] || '';
    this.createdByUserName =  raw['_createdby_value@OData.Community.Display.V1.FormattedValue'];
    if(raw['createdbyusername']){
      this.createdByUserName = raw['createdbyusername'];
    }
    this.ownerID = raw['_ownerid_value'] || ''
    this.ownerName = raw['_ownerid_value@OData.Community.Display.V1.FormattedValue'] || ''
    if(raw['ownername']){
      this.ownerName = raw['ownername'];
    }
    this.createdOn = raw['createdon']? new Date(raw['createdon']).getTime().toString() : '';
    this.estimatedDate = raw['estimatedclosedate']? Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['estimatedclosedate']).getTime(), false).getTime().toString() : '';
    this.transactionCurrencySymbol = raw['transactioncurrencysymbol'] || ''
    this.transactionCurrencyID = raw['_transactioncurrencyid_value'] || ''
    this.closedValue = raw['actualvalue'] || 0;
    this.closedValueFormatted = raw['actualvalue@OData.Community.Display.V1.FormattedValue'] || this.transactionCurrencySymbol+'0';
    this.estimatedValue = parseFloat(raw['estimatedvalue'])|| 0;
    this.estimatedValueFormatted = raw['estimatedvalue@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.countryId = raw['_indskr_country_value'] || ''
    this.countryName = raw['_indskr_country_value@OData.Community.Display.V1.FormattedValue'] || '';
    this.stateCode = parseInt(raw['statecode']) || 0;
    this.state = this.stateCode === 0? 'Open': this.stateCode === 1? 'Won' : 'Lost'
    this.statusCode = parseInt(raw['statuscode']) || 1
    this.status = this.state;
    this.purchaseProcess = raw['purchaseprocess'];
    this.purchaseProcessFormatted = raw['purchaseprocess_Formatted']||'';
    this.accountID = raw['_customerid_value'] || ''
    this.accountName = raw['_customerid_value_Formatted'] || ''
    this.accountPlanID = raw['_indskr_accountplan_value'] || ''
    this.accountPlanName = raw['_indskr_accountplan_value@OData.Community.Display.V1.FormattedValue'] || ''
    this.opportunityObjectiveID = raw['_indskr_accountplanobjective_value'] || ''
    this.opportunityObjectiveName = raw['_indskr_accountplanobjective_value@OData.Community.Display.V1.FormattedValue'] || ''
    this.products = [];
    this.priceList = raw['_pricelevelid_value'] || ''
    this.total = parseFloat(raw['totalamount']) || 0;
    this.totalFormatted = raw['totalamount@OData.Community.Display.V1.FormattedValue'] || ''
    this.elligibleDiscount = raw['discountpercentage'] || 0
    this.trackAction = raw['track_action@OData.Community.Display.V1.FormattedValue']? raw['track_action@OData.Community.Display.V1.FormattedValue'] : '';
    this.offlineId = raw['indskr_externalid'] ? raw['indskr_externalid'] : '';
    this.isrevenuesystemcalculated = raw['isrevenuesystemcalculated'] || false;
    this.isestimatedrevenuebasedonprocedureandproducts = raw['indskr_isercalculatedonpp'] || false;
    this.opportunityStageValue = raw['opportunitySalesProcess.activestageid_Formatted'] || '';
    this.opportunityStage = raw['opportunitySalesProcess.activestageid'] || '';
    this.opportunityBusinessProcessInstanceId = raw['indskr_bpfinstanceid'] || '';
    this.opportunityBusinessProcessSchemaName = raw['indskr_bpfschemaname'] || '';
    this.businessUnitId = raw['businessUnitId'] || raw['_indskr_businessunit_value'];
    this.businessUnitFormatted = raw['businessUnitFormatted'] || raw['_indskr_businessunit_value_Formatted'];
    this.appointmentId = raw['appointmentId'] || '';
    this.opportunityTypeId = raw['_indskr_opportunitytype_value'] || '';
    this.opportunityTypename = raw['_indskr_opportunitytype_value_Formatted'] || '';
    this.opportunitySubTypeId = raw['_indskr_opportunitysubtype_value'] || '';
    this.opportunitySubTypename =  raw['_indskr_opportunitysubtype_value_Formatted'] || '';
    this.closeDescriptionText = raw['closeDescriptionText'] || '';
    this.closedDate = raw['closedDate']? new Date(raw['closedDate']).getTime().toString() : '';
    this.surgeryId = raw['_indskr_procedure_value'] ||'';
    this.surgeryNameString = raw['_indskr_procedure_value_Formatted'] ||'';
    this.productCategory = [];
    this.opportunityProgressPercent = raw['indskr_opportunityprogress'] ||'0';
    this.competitorBusinessValue = parseFloat(raw['indskr_competitorsbusinessvalue']) || 0;
    this.competitorBusinessValueFormatted = raw['indskr_competitorsbusinessvalue@OData.Community.Display.V1.FormattedValue'] || '';
    this.competitors = [];
    this.competitorProducts = [];
    this.surveyResponse = raw['surveyResponse'] || '';
    this.surveyResponseData = raw['surveyResponseData'] || '';
    this.surveyResponseLookupData = raw['surveyResponseLookupData'] || [];
    this.surveyResponseAdditionalData = raw['surveyResponseAdditionalData'] || {};
    this.indskr_source = raw['indskr_source'] || undefined;
    this.indskr_source_Formatted = raw['indskr_source_Formatted'] || '';
    this.opportunityratingcode = raw['opportunityratingcode'] || undefined;
    this.opportunityratingcode_Formatted = raw['opportunityratingcode_Formatted'] || '';
    this.indskr_affordability = raw['indskr_affordability'] || undefined;
    this.indskr_affordability_Formatted = raw['indskr_affordability_Formatted'] || '';
    this.indskr_systemrating = raw['indskr_systemrating'] || undefined;
    this.indskr_systemrating_Formatted = raw['indskr_systemrating_Formatted'] || '';
    this.indskr_gemnongem = raw['indskr_gemnongem'] || undefined;
    this.indskr_gemnongem_Formatted = raw['indskr_gemnongem_Formatted'] || '';
    this.indskr_sourceothers = raw['indskr_sourceothers'] || '';
    this.opportunityReasonID = raw['indskr_opportunityreasonid'] || '';
    this.opportunityReason = raw['opportunityReason'] || '';
    this.indskr_progressstatus = raw['indskr_progressstatus'];
    this.indskr_allowstagemovementbyuser = raw['indskr_allowstagemovementbyuser'] == undefined ? true : raw['indskr_allowstagemovementbyuser'];
    this.indskr_allowbackwardstagemovement = raw['indskr_allowbackwardstagemovement'] == undefined ? true : raw['indskr_allowbackwardstagemovement'];
    this.marketingBusinessPlans = [];
    this.productManager = raw['productManager'] || '';
    this.parentOpportunityId = raw['_indskr_parentopportunity_value'] || '';
    this.parentOpportunityName = raw['_indskr_parentopportunity_value_Formatted'] || '';
    this.primarySpecialtyId = raw['_indskr_primaryspecialty_value'] || '';
    this.primarySpecialtyName = raw['_indskr_primaryspecialty_value_Formatted'] || '';
    this.subSpecialtyId = raw['_indskr_subspecialty_value'] || '';
    this.subSpecialtyName = raw['_indskr_subspecialty_value_Formatted'] || '';
    this.contactID = raw['_indskr_contact_value'] || ''
    this.contactName = raw['_indskr_contact_value_Formatted'] || '';
    this.primaryTargetValue = raw['indskr_primarytarget'] || undefined;
    this.primaryTargetName = raw['indskr_primarytarget_Formatted'] || '';
    this.stakeholderValue = parseFloat(raw['indskr_stakeholdervalue'])|| 0;
    this.stakeholderValueFormatted = raw['indskr_stakeholdervalue@OData.Community.Display.V1.FormattedValue'] || ((this.transactionCurrencySymbol?this.transactionCurrencySymbol:'')+'0.00');
    this.focusArea = raw['indskr_focusarea'] || '';
  }
}
export class Quote {
  public quoteID: string;
  public opportunityID: string;
  public statecode: number;
  public statecodeFormatted: string;
  public countryName:string;
  public quoteNumber: string;
  public effectiveFrom: string;
  public effectiveTo: string;
  public total : string;
  public totalFormatted: string;
  public accountName: string;
  public accountID: string;
  public createdBy: string;
  public createdByUserName: string;
  public quoteProducts: OpportunityProduct[];
  public trackAction: string;
  public countryId: string;
  public currencyId: string;
  public currencyName: string;
  public priceListId: string;
  public priceListName: string;
  public statusCode : number;
  constructor(raw) {
    this.quoteID = raw['quotes.quoteid']
    this.quoteNumber = raw['quotes.quotenumber'] || ''
    this.statecode = raw['quotes.statecode'] || 0
    this.statecodeFormatted = raw['quotes.statecode_Formatted'] || 'Draft'
    this.opportunityID = raw['opportunityid'] || ''
    this.createdBy = raw['quotes.createdby'] || ''
    this.createdByUserName = raw['quotes.createdby@OData.Community.Display.V1.FormattedValue'] || ''
    this.countryId = raw['quotes.indskr_countryid'] || ''
    this.countryName = raw['quotes.indskr_countryid_Formatted'] || ''
    this.currencyId = raw['quotes.transactioncurrencyid'] || ''
    this.currencyName = raw['quotes.transactioncurrencyid_Formatted'] || ''
    this.priceListId = raw['quotes.pricelevelid'] || ''
    this.priceListName = raw['quotes.pricelevelid_Formatted'] || ''
    this.accountName = raw['linkedAcc.name'] || ''
    this.accountID = raw['linkedAcc.accountid'] || ''
    this.effectiveFrom = raw['quotes.effectivefrom']? new Date(raw['quotes.effectivefrom']).getTime().toString() : '';
    this.effectiveTo = raw['quotes.effectiveto']? new Date(raw['quotes.effectiveto']).getTime().toString() : '';
    this.total = raw['quotes.totalamount'] || ''
    this.totalFormatted = raw['quotes.totalamount_Formatted'] || ''
    this.quoteProducts = [];
    this.statusCode = raw['quotes.statuscode'] || 8;
    this.trackAction = raw['track_action@OData.Community.Display.V1.FormattedValue']? raw['track_action@OData.Community.Display.V1.FormattedValue'] : '';
  }
}
export class Agreement {
  public agreementID: string;
  public agreementName: string;
  public agreementURL: string;
  public opportunityID: string;
  public trackAction: string;
  public state: number;
  constructor(raw){
    this.agreementID = raw['agreements.indskr_agreementid'] || ''
    this.agreementName = raw['agreements.indskr_name'] || ''
    this.agreementURL = raw['agreements.indskr_url'] || ''
    this.opportunityID = raw['opportunityid'] || ''
    this.state = raw['agreements.statecode'] || 0;
     this.trackAction = raw['track_action@OData.Community.Display.V1.FormattedValue']? raw['track_action@OData.Community.Display.V1.FormattedValue'] : '';
  }
}
export enum purchaseProcess {
  'Individual',
  'Committee',
  'Unknown',
}
export interface OpportunityProduct{
  productID: string,
  productName: string,
  unitPrice: number,
  unitPriceFormatted: string,
  total: number,
  totalFormatted: string,
  quantity: number,
  uomid?:string,
  isDeleted?:boolean,
  parentProductID?: string,
  productCategoryID?: string,
  productCategoryName?: string,
}
export interface OpportunityContact {
  ID: string,
  firstName: string,
  lastName: string,
  fullName: string,
  isActive: boolean,
  stakeholderValue?: number,
  role?:any
}
export interface OpportunityCompetitor {
  ID: string,
  name: string,
  isDeleted?:boolean;
}
export interface ProductManager {
  id: string,
  fullName: string
}
export interface OpportunityCompetitorProduct {
  ID: string,
  name: string,
  isDeleted?:boolean;
}
export interface OpportunityCollaborators {
  ID: string,
  firstName: string,
  lastName: string,
  fullName: string,
  isActive?: boolean,
  role:any
}
export enum CollaboratorType{
  OWNER = 548910000,
  COOWNER = 548910001,
  BEHOLDER = 548910002
}
export enum OpportunityPrimaryTraget {
  ACCOUNT = 548910000,
  CONTACT = 548910001,
}
export class LookupSearchResult {
  id: string;
  name: string;
  firstname: string;
  lastname: string;
  constructor(raw) {
    this.id = raw.systemuserid || "";
    this.name = raw.fullname || "";
    this.firstname = raw.firstname || "";
    this.lastname = raw.lastname || "";
  }
}
export interface OpportunityActivities{
  ID:string,
  subject:string,
  scheduledEnd: Date,
  state: number,
  type: ActivityType,
  ownerid: string
}
export enum EstimatedRevenueCalculationBasedOn {
  COUNTRYPRICELIST = 548910000,
  PROCEDUREANDPRODUCTS = 548910001,
}
export enum OrderCreationType {
  PRICE_LIST = 548910000,
  MANUAL = 548910001
}
export enum ProgressStatus {
  IN_PROGRESS = 548910000,
  NO_PROGRESS = 548910001
}

