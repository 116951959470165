import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Injectable, Injector } from "@angular/core";
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform,  AlertController } from '@ionic/angular';
import { Events } from '@omni/events';
import { BehaviorSubject, Subscription ,  Observable, combineLatest, Subject } from "rxjs";
import { NotificationService, ToastStyle } from "../notification/notification.service";
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { distinctUntilChanged, debounceTime, map } from "rxjs/operators";
import { NavigationService } from '../navigation/navigation.service';
import { differenceInMinutes, subMinutes, differenceInMilliseconds, differenceInHours } from 'date-fns';
import { TranslateService } from "@ngx-translate/core";
import { electronApi } from "../electron-api";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { BuildInfo } from "@awesome-cordova-plugins/build-info/ngx"
import { DeviceNetworkService } from './device-network.service';
import * as UAParser from 'ua-parser-js';

export const DEVICE_ORIENTATION = {
    'not-available': 0,
    'portrait-primary': 1,
    'portrait-secondary': 2,
    'landscape-primary': 3,
    'landscape-secondary': 4,
}

export enum VIEW_MODE_CHANGE {
    'normal-to-mobile' = 1,
    'mobile-to-normal'
}

export const OFFLINE_SESSION_TIMEOUT_TIME  = {
    FOUR_HOURS: { timeInMinutes: 240, displayTxt: '4 Hours' },
    ONE_HOUR: { timeInMinutes: 60, displayTxt: '1 Hour' },
    HALF_HOUR: { timeInMinutes: 30, displayTxt: '30 Minutes' },
};

export enum HomeBarDetectState {
  'not-checked', 'checked',
}

export enum OFFLINE_SESSION_STATE {
    'not-available',
    'offline',
    'alert',
    'expired',
    'online',
};
export enum DEVICE_EVENT {
    'appForeground' = 'appForeground',
    'refreshTokenOnly' = 'refreshTokenOnly',
    'networkReconnection' = 'networkReconnection',
    'dailySync' = 'dailySync',
    'userStatusBackToOnline' = 'userStatusBackToOnline',
    'languageChange' = 'languageChange',
};
export const NETWORK_FLUCTUATION_TIMESTAMP_KEY = 'network_fluctuation_timestamp';
export interface OFFLINE_SESSION_STATUS {
    state: OFFLINE_SESSION_STATE;
    timeLeft?: string;
};

const MAX_WIDTH_FOR_MOBILE_VIEW = 500;

declare var IRoot: any;
declare var cordova:any;

const LANGUAGE_DEFAULT = 'en';
const isElectronRenderer = !!(electronApi?.versions && electronApi?.versions?.electron);
let _isIOS = false;
let _isIPhone = false;
let _isIPad = false;
let _isWindows = false;
let _isMacintosh = false;
let _isLinux = false;
let _isNative = false;
let _isWeb = false;
let _locale: string | undefined = undefined;
let _language: string = LANGUAGE_DEFAULT;
let _userAgent: string | undefined = undefined;
let _platform: string | undefined = undefined;
let _device: string | undefined = undefined;
let _os: string | undefined = 'unknown';
let _osVersion: string | undefined = 'unknown';
let _browser: string | undefined = undefined;
type DeploymentTarget = 'browser' | 'ios' | 'android' | 'windows' | 'pwa' | 'electron';

const _deploymentTarget: DeploymentTarget = (window as any).GLOBALS?.deploymentTarget || 'browser'

if (typeof navigator === 'object' && navigator.userAgent) {
  _userAgent = navigator.userAgent;
}

// OS detection
if (!isElectronRenderer) {
  _isWindows = _userAgent.indexOf('Windows') >= 0;
  _isMacintosh = _userAgent.indexOf('Macintosh') >= 0;
  _isIOS = _userAgent.indexOf('Macintosh') >= 0 && !!navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
  _isIPad = _userAgent.indexOf('iPad') >= 0 || (_userAgent.indexOf('Macintosh') >= 0 && !!navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
  _isIPhone = _isIOS && !_isIPad;
  _isLinux = _userAgent.indexOf('Linux') >= 0;
  _locale = navigator.language;
  _isNative = _deploymentTarget !== 'browser' && _deploymentTarget !== 'pwa';
  _language = _locale;
} else if (electronApi.platform) {
  _isWindows = (electronApi.platform === 'win32');
  _isMacintosh = (electronApi.platform === 'darwin');
  _isLinux = (electronApi.platform === 'linux');
  _locale = LANGUAGE_DEFAULT;
  _language = LANGUAGE_DEFAULT;
  _isNative = true;
}
_isWeb = !_isNative;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
    public _isOffline: boolean;
    public isApplicationLoaded:boolean = false;
    private translate;
    // This property is needed to distinguish the actual device offline & user set offline
    private _isDeviceRealOffline: boolean;
    private _isAppInBackground: boolean = false;
    private _isUserStateOffline: boolean = false;
    private toastStyle = ToastStyle;
    private _isOffline$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isAppInBackground$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _screenWidth$: BehaviorSubject<number> = new BehaviorSubject(0);
    private _screenHeight$: BehaviorSubject<number> = new BehaviorSubject(0);
    private _screenOrientation$: BehaviorSubject<number> = new BehaviorSubject(DEVICE_ORIENTATION['not-available']);
    private _shouldBeMobileView: boolean = false;
    private _base64Signature: string;
    private _onPause: Subject<boolean> = new Subject();

    private _homeBarDetectState: HomeBarDetectState = HomeBarDetectState['not-checked'];
    public hasHomeBar: boolean = false;

    public readonly isOfflineObservable: Observable<boolean> = this._isOffline$.asObservable();
    public readonly isAppInBackgroundObservable: Observable<boolean> = this._isAppInBackground$.asObservable();
    public readonly screenWidth: Observable<number> = (this._screenWidth$.asObservable());
    public readonly screenHeight: Observable<number> = (this._screenHeight$.asObservable());
    public readonly screenOrientation: Observable<number> = this._screenOrientation$.asObservable();
    public readonly widthAndOrOrientationChange: Observable<boolean> =
            combineLatest([this.screenOrientation, this.screenWidth])
                      .pipe(debounceTime(600),
                          map(([orientation, width]) => {
                                  const newShouldBeMobileview =
                                  ((orientation === DEVICE_ORIENTATION['portrait-primary']
                                  || orientation === DEVICE_ORIENTATION['not-available'])
                                  && width <= MAX_WIDTH_FOR_MOBILE_VIEW);

                                  if (this._shouldBeMobileView && !newShouldBeMobileview) {
                                      // Changed from Mobile view to Normal view
                                      // Below assignment needs to be there since the value needs to be updated before event publish.
                                      this._shouldBeMobileView = newShouldBeMobileview;
                                      this.events.publish('device:view-mode-change', VIEW_MODE_CHANGE['mobile-to-normal'],
                                                          this.navService.getCurrentPageName(), this.navService.getCurrentMasterPageName());
                                  } else if (!this._shouldBeMobileView && newShouldBeMobileview) {
                                      // Changed from Normal view to Mobile view
                                      // Below assignment needs to be there since the value needs to be updated before event publish.
                                      this._shouldBeMobileView = newShouldBeMobileview;
                                      this.events.publish('device:view-mode-change', VIEW_MODE_CHANGE['normal-to-mobile'],
                                                          this.navService.getCurrentPageName(), this.navService.getCurrentMasterPageName());
                                  }
                                  return null;
                              }));

    public readonly appStateAndNetworkConnectionChange: Observable<{isBg: boolean, isOffline: boolean}> = combineLatest(this.isAppInBackgroundObservable, this.isOfflineObservable)
                                                                                    .pipe(map(([isBg, isOffline]) => ({isBg, isOffline})));
    public readonly onPauseObservable: Observable<boolean> = this._onPause.asObservable();
    private _isBackgroundUploadInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isBackgroundUploadInProgress: Observable<boolean> = this._isBackgroundUploadInProgress$.asObservable();
    private backgoundUploadInProgress: boolean = false;
    private _syncInProgress: boolean = false;
    public syncInProgress$: Subject<boolean> = new Subject();

    //creating an observable for current network status
    // or just import Network??
    // private networkStatus = new BehaviorSubject<boolean>(false);
    // public isDeviceOffline = this.networkStatus.asObservable();
    // public appInBackground:boolean = false;
    public _isDeviceOfflineFromLogin: boolean = false;
    private onConnect:Subscription;
    private onDisconnect:Subscription;
    deviceNetworkService: DeviceNetworkService;
    private hadNetwork: boolean = null;
    private _isDeviceNetworkServiceReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly isDeviceNetworkServiceReady: Observable<boolean> = this._isDeviceNetworkServiceReady.asObservable();

    dbConnectionCheckStart: Subject<any> = new Subject();
    dbConnectionCheckDone: Subject<boolean> = new Subject();

    private userAgentParser;

    constructor (
        private network: Network,
        public platform: Platform,
        public notificationService: NotificationService,
        public events: Events,
        private screenOrientationService: ScreenOrientation,
        private navService: NavigationService,
        private alert: AlertController,
        private device: Device,
        private _buildInfo: BuildInfo,
        private injector: Injector,
        public statusBar: StatusBar,
    ) {
        // Translate Service initialized
        setTimeout(() => {
            this.translate = this.injector.get(TranslateService);
            this.deviceNetworkService = this.injector.get(DeviceNetworkService);
            this._isDeviceNetworkServiceReady.next(true);
        });
        // Listen device orientation change
        this.screenOrientationService.onChange().subscribe(() => {
            this._screenOrientation$.next(DEVICE_ORIENTATION[this.screenOrientationService.type]);
        });

        this.widthAndOrOrientationChange.subscribe();

        this.init();
    }

    async checkNetworkConnectivity(): Promise<boolean> {
        let hasNetwork: boolean = false;
        if (this.deviceFlags.electron) {
            hasNetwork = await this.deviceNetworkService.connectivityCheck();
        } else {
            hasNetwork = this.checkCordovaNetworkConnectivity();
        }

        this._isDeviceRealOffline = !hasNetwork;
        // If user state offline -> don't touch
        if (!this.isUserStateOffline) {
            this.isOffline = !hasNetwork;
        }

        return hasNetwork;
    }

    public detectHomeBar() {
      // Use: this.hasHomeBar to check if a device has the homebar or not.
      // No need to call this function again as we're calling it from footer-toolbar class
      // which is globally used and should have the latest value from `hasHomeBar` boolean.

      if (this.deviceFlags.ios && this.isMobileDevice) {
        // This will detect and confirm the iPhone Home Bar availability
        if (this._homeBarDetectState === HomeBarDetectState.checked) {
          return this.hasHomeBar;
        }
        const div = document.createElement('div');
        if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
            div.style.paddingBottom = 'env(safe-area-inset-bottom)';
            this.hasHomeBar = true;
        } else if (CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
            div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
            this.hasHomeBar = true;
        } else {
          this.hasHomeBar = false;
        }
        this._homeBarDetectState = HomeBarDetectState.checked;
      } else {
        this.hasHomeBar = false;
      }
  }

    private checkCordovaNetworkConnectivity() {
        if (this.isNativeApp) {
            let connType = this.network.type;
            if (connType && connType !== 'none') {
                return true;
            } else {
                return false;
            }
        } else {
            // Should be a browser and we assume it's always connected.
            return true;
        }
    }

    private async postResume() {
      if (this.navService.isInMainAppView()) {
        const hasNetwork = await this.checkNetworkConnectivity()
        if (this.hadNetwork !== hasNetwork) {
            // App came back to foreground and network connectivity changed
            this.hadNetwork = null;
            if (hasNetwork) {
                this.onNetworkConnect(DEVICE_EVENT.appForeground);
            } else {
                this.onNetworkDisconnect();
            }
        }
      }
    }

    async parseDeviceInfo() {
      // Parse userAgent info
      if (!this.userAgentParser && _userAgent) {
        this.userAgentParser = new UAParser(_userAgent);
      }
      const uaParsed = this.userAgentParser.getResult();
      const uaHints = navigator
      && typeof navigator['userAgentData'] === 'object'
      && typeof navigator['userAgentData']['getHighEntropyValues'] === 'function'
      ? await navigator['userAgentData'].getHighEntropyValues([
          'platformVersion',
          'fullVersionList'
        ])
      : undefined;

      // Detect platform & OS
      if (this.deviceFlags.android) {
        _platform = 'Android';
      } else if (this.deviceFlags.iPhone) {
        _platform = 'iPhone';
      } else if (this.deviceFlags.iPad) {
        _platform = 'iPad';
      } else if (electronApi?.platform) {
        if (this.deviceFlags.windows) {
          _platform = 'Windows';
          _os = uaParsed?.os.name || 'Windows';
        } else if (this.deviceFlags.macintosh) {
          _platform = 'Macintosh';
          _os = uaParsed?.os.name || 'macOS';
        } else if (this.deviceFlags.linux) {
          _platform = 'Linux';
          _os = uaParsed?.os.name || 'Linux';
        }
      } else {
        _platform = 'Web';
        _os = uaParsed?.os.name
      }

      // Detect OS version
      if (this.deviceFlags.nativeCordova) {
        // Native mobile app
        _osVersion = this.device.version;
        _device = `${uaParsed?.device?.vendor} (${uaParsed?.device?.type})(${uaParsed?.device?.model})`;
      } else if (this.deviceFlags.electron) {
        // Electron app
        _osVersion = electronApi.osVersion || 'unknown';
      } else if (
        uaHints !== undefined
      ) {
        // Chrome's user agent hint API if avail
        if (uaHints?.platformVersion) {
          _osVersion = uaHints.platformVersion;
        } else {
          _osVersion = uaParsed?.os?.version || 'unknown';
        }
        if (_platform === 'Web' && uaHints?.platform) {
          _os = uaHints.platform;
        }
      } else {
        // Other browsers
        // Can be inaccurate but as a last resort, guess from userAgent
        // UserAgent can report inaccurate info and be deprecated due to privacy policy
        // For example, macOS version number starting 11 will be capped at version 10_15_7
        // https://chromestatus.com/feature/5452592194781184
        _osVersion = uaParsed?.os?.version || 'unknown';
      }

      // Detect browser
      if (
        _browser === undefined
      ) {
        if (Array.isArray(uaHints?.fullVersionList) && uaHints?.fullVersionList.length > 0) {
          _browser = `${uaHints.fullVersionList[0].brand} (${uaHints.fullVersionList[0].version})`;
        } else if (uaParsed) {
          _browser = `${uaParsed?.browser?.name} (${uaParsed?.browser?.version})`;
        }
      }
    }

    private async init() {
      try {
        await this.platform.ready();
        this.parseDeviceInfo();

          if (this.deviceFlags.native && this.deviceFlags.android && cordova?.plugins?.cordovaHelpers?.getAppSignature) {
            this._base64Signature = await new Promise(cordova.plugins.cordovaHelpers.getAppSignature);
          }
                this.platform.pause.subscribe( async(e)=> {
                if (this.isNativeApp) {
                    this.appInBackground = true;
                    this.hadNetwork = await this.checkNetworkConnectivity();
                    this.unsubscribeToNetworkChanges();
                    this._onPause.next(true);
                }

            this.platform.resume.subscribe ( async(e)=> {
                if (this.isNativeApp) {
                    if (this.appInBackground) {
                        this.appInBackground = false;
                        this.subscribeToNetworkChanges();

                        // Check DB connection first
                        // Next process will follow once DB connection is confirmed
                        this.dbConnectionCheckStart.next(null);
                    }

                    if (typeof (IRoot) !== 'undefined' && IRoot && !this.device.isVirtual) {
                        IRoot.isRooted((data) => {
                          if (data && data == 1) {
                              this.alert.create({
                                header: this.translate.instant('DEVICE_CANT_USE_APP_ON_ROOTED'),
                                buttons: [
                                  {
                                    text: this.translate.instant('OK'),
                                    role: 'cancel',
                                    handler: data => {
                                      //this.platform.exitApp();
                                    }
                                  }
                                ]
                              }).then((alert)=> alert.present())

                          } else {
                            console.log("This is not rooted device");
                          }
                        }, (data) => {
                            console.log("Rooted device detection failed case", data);
                        });
                    }

                    // Update device orientation in case it changed
                    if (!this.deviceFlags.electron) {
                      if (this._screenOrientation$.getValue() !== DEVICE_ORIENTATION[this.screenOrientationService.type]) {
                        this._screenOrientation$.next(DEVICE_ORIENTATION[this.screenOrientationService.type]);
                      }
                    }
                }
            });
        });

        this.dbConnectionCheckDone.asObservable().subscribe(async () => {
          await this.postResume();
        });

        this.subscribeToNetworkChanges();
      }
      catch (ex) {
        console.error(ex);
      }
    }

    private subscribeToNetworkChanges(){
        this.platform.ready().then(() => {
            this.onDisconnect = this.network.onDisconnect().subscribe(
                async () => {
                    if (this.isNativeApp && !(await this.checkNetworkConnectivity())) {
                        this.markDeviceOfflineTimestamp();
                        this.onNetworkDisconnect();
                    }
                }
            );

            this.onConnect = this.network.onConnect().subscribe(
                async () => {
                    if (this.isNativeApp && await this.checkNetworkConnectivity()) {
                        this.onNetworkConnect(DEVICE_EVENT.networkReconnection);
                    }
                }
            );
        });
    }
    private unsubscribeToNetworkChanges() {
      this.onConnect?.unsubscribe();
      this.onDisconnect?.unsubscribe();
    }

    onNetworkConnect = (event: DEVICE_EVENT)=>{
        this._isDeviceRealOffline = false;
        // Keep it offline if user state was offline
        if (!this.isUserStateOffline) {
            this.isOffline = false;
        }
        if(!this.appInBackground){
            if(this.hadNetwork){
                return;
            }else{
                this.hadNetwork = true;
            }

            const isFluctuation = this.isNetworkFluctuation();
            this.events.publish('device:deviceIsOnline', { isFluctuation });
        }
    }
    onNetworkDisconnect = () =>{
        this.isOffline = this._isDeviceRealOffline = true;
        if(!this.appInBackground){
            if(this.hadNetwork !== null && !this.hadNetwork){
                return;
            }else{
                this.hadNetwork = false;
            }
            this.events.publish('device:deviceIsOffline');
        }
    }

    public set backgroundUploadInProgress(inProgress: boolean) {
      this.backgoundUploadInProgress = inProgress;
      this._isBackgroundUploadInProgress$.next(inProgress);
    }

    public get isBackgroundUploadInProgressObservable() {
      return this._isBackgroundUploadInProgress;
    }

    public get isBackgroundUploadInProgress() {
      return this.backgoundUploadInProgress;
    }

    public set syncInProgress(inProgress: boolean) {
      this._syncInProgress = inProgress;
      this.syncInProgress$.next(inProgress);
    }
    public get syncInProgress() {
     return this._syncInProgress
    }

    get isOffline(): boolean {
        return this._isOffline;
    }

    set isOffline(isOff: boolean) {
        this._isOffline = isOff;
        this._isOffline$.next(isOff);
    }

    get appInBackground(): boolean {
        return this._isAppInBackground;
    }

    set appInBackground(isBg: boolean) {
        this._isAppInBackground = isBg;
        this._isAppInBackground$.next(isBg);
    }

    get isUserStateOffline() {
        return this._isUserStateOffline;
    }

    set isUserStateOffline(isOfflineState: boolean) {
        this._isUserStateOffline = isOfflineState;
    }

    get isDeviceRealOffline(): boolean {
        return this._isDeviceRealOffline;
    }

    get isFakeOffline(): boolean {
        return (!this._isDeviceRealOffline && this.isOffline);
    }

    get shouldBeMobileView(): boolean {
        return this._shouldBeMobileView;
    }

    public get isMobileDevice():boolean{
        if(this.platform.isPortrait()){
            return (this.platform.width() <= 620);
        }else if(this.platform.isLandscape()){
            return this.platform.width() <= 960
        }
        return false;
    }
    public get isMobileDevicePortrait():boolean{
        if(this.platform.isPortrait()){
            return (this.platform.width() <= 500);
        }
        return false;
    }
    public get deviceOrientation() {
        if(this.platform.isPortrait()){
            return "PORTRAIT";
        }else{
            return "LANDSCAPE";
        }
    }
    /**
     * This ensures we use cordova plugins for certain thing
     *
     * @readonly
     * @memberof DeviceService
     */

    public get deploymentTarget() {
        return _deploymentTarget;
    }

    public get buildInfo() {
      let _this = this;
      return {
        get baseUrl() { return _this._buildInfo.baseUrl; },
        get basePackageName() { return _this._buildInfo.basePackageName; },
        get packageName() { return _this._buildInfo.packageName; },
        get displayName() { return _this._buildInfo.displayName; },
        get name() { return _this._buildInfo.name; },
        get version() { return _this._buildInfo.version; },
        get versionCode() { return _this._buildInfo.versionCode; },
        get debug() { return _this._buildInfo.debug; },
        get buildType() { return _this._buildInfo.buildType; },
        get flavor() { return _this._buildInfo.flavor; },
        get buildDate() { return _this._buildInfo.buildDate; },
        get installDate() { return _this._buildInfo.installDate; },
        get base64Signature() { return _this._base64Signature; }
      }
    }

    public get deviceFlags() {
        let _this = this;
        const uaParsed = _this.userAgentParser?.getResult();
        return {
          get ios() { return _this.platform.is('ios') || _isIOS; },
          get iPhone() { return uaParsed?.device?.model === 'iPhone' || _isIPhone },
          get iPad() { return uaParsed?.device?.model === 'iPad' || _isIPad },
          get android() { return _this.platform.is('android'); },
          get electron() { return isElectronRenderer; },
          get native() { return _isNative; },
          get nativeCordova() { return _isNative && !isElectronRenderer; },
          get nativeIOS() { return _deploymentTarget === 'ios'; },
          get nativeAndroid() { return _deploymentTarget === 'android'; },
          get web() { return _isWeb; },
          get desktop() { return _isWindows || _isLinux || (_isMacintosh && !_isIOS); },
          get windows() { return _isWindows; },
          get linux() { return _isLinux; },
          get macintosh() { return _isMacintosh; },
          get smallScreen() { return _this.isMobileDevice; },
          get portrait() { return _this.platform.isPortrait(); },
          toString() {
            return JSON.stringify(_this.deviceFlags);
          }
        }
    }

    get deviceInfo() {
      let _this = this;
      return {
        get os() { return _os },
        get osVersion() { return _osVersion; },
        get platform() { return _platform; },
        get info() {
          let info = 'Platform: ' + _platform;
          if (_this.deviceFlags.nativeCordova) {
            info = info + '; Device: ' + _device;
          }
          if (!_this.deviceFlags.nativeCordova) {
            info = info + '; OS: ' + _os;
          }
          info = info + '; OS Version: ' + _osVersion + '; Engine(Browser): ' + _browser;
          return info;
        }
      };
    };

    public get isNativeApp() {
      return this.deviceFlags.native;
    }

    get isNativeCordova() {
      return this.deviceFlags.nativeCordova;
    }

    getUserAgent() {
        return navigator.userAgent// this.platform.userAgent();
    }

    updateScreenWidth(width: number) {
        this._screenWidth$.next(width);
    }

    updateScreenHeight(height: number) {
        this._screenHeight$.next(height);
    }

    isOrientationPortrait() {
        if (this.screenOrientationService.type === "landscape-primary" || this.screenOrientationService.type === "landscape-secondary") {
          return false;
        } else {
          return true;
        }
    }

    getDeviceOfflineTimestamp(): number {
        let timestamp = null;
        if (localStorage) {
            const ts = Number(localStorage.getItem(NETWORK_FLUCTUATION_TIMESTAMP_KEY));
            if (ts && !isNaN(ts) && ts > 0) {
                timestamp = ts;
            }
        }
        return timestamp;
    }
    markDeviceOfflineTimestamp() {
        if (localStorage) {
            localStorage.setItem(NETWORK_FLUCTUATION_TIMESTAMP_KEY, new Date().getTime().toString());
        }
    }
    isNetworkFluctuation(minFluctuationTimeInMilliseconds = 10000): boolean {
        let isFluctuation = false;

        const lastDeviceOfflineTimestamp = this.getDeviceOfflineTimestamp();
        if (lastDeviceOfflineTimestamp !== null) {
            const now = new Date().getTime();
            isFluctuation = (lastDeviceOfflineTimestamp + minFluctuationTimeInMilliseconds) > now;
        }

        return isFluctuation;
    }

    shouldDailySyncBeTriggered(): boolean {
        const lastFullSyncTime = Number(localStorage.getItem('lastFullSyncTime'));
        if (lastFullSyncTime && !isNaN(lastFullSyncTime) && lastFullSyncTime > 0) {
            const dateObjectForTimestamp = new Date(lastFullSyncTime);
            const dateObjectForPresent = new Date();

            const hoursDiff = differenceInHours(dateObjectForPresent, dateObjectForTimestamp);
            return Math.abs(hoursDiff) >= 12;
        }
        return false;
    }

    public get runningDevice(){
        return this.device
    }

    public lockDeviceOrientationLandscape() {
      this.screenOrientationService.lock(this.screenOrientationService.ORIENTATIONS.LANDSCAPE);
    }
  
    public lockDeviceOrientationPotrait() {
      this.screenOrientationService.lock(this.screenOrientationService.ORIENTATIONS.PORTRAIT);
    }

    public unlockDeviceOrientation() {
      this.screenOrientationService.unlock();
    }

    isAndroid(): boolean {
      return this.deviceFlags.nativeAndroid || (this.deviceFlags.native && this.deviceFlags.android);
    }

    isIOS(): boolean {
      return (this.deviceFlags.native && this.deviceFlags.ios);
    }
}
