import { Component, ViewChild } from '@angular/core';
import { NavController,  IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService } from '../../../services/footer/footer.service';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { KOLStatusService } from '@omni/services/kol-status/kol-status-service';

/**
 * Generated class for the KOLStatusPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'kol-status-page[base-page]',
  templateUrl: 'kol-status-page.html',
  styleUrls:['kol-status-page.scss']
})
export class KOLStatusPageComponent {

  @ViewChild('kolstatuspagerightpane', {static:true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    private footerService: FooterService,
    private events: Events,
    public agendaFooterService: AgendaFooterService,
    private readonly kolStatusService: KOLStatusService,
  ) {
  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.KOLStatusPageNavigation, PageName.KOLStatusPageComponent, false, {deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');
  }

  ionViewDidEnter() {
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  ngOnDestroy(){
    // this.agendaFooterService.initButtons(this.agendaFooterService.footerViewAtHome);
    // this.agendaFooterService.footerViewAtHome = null;
    this.kolStatusService.myKOLStatusRecords = [];
    this.kolStatusService.diseaseAreasList = [];
    this.navService.popChildNavCtrlFromStack(ChildNavNames.KOLStatusPageNavigation);
  }

}
