import { Action } from '@ngrx/store';

export const DOWNLOAD_RESOURCE = '[file] Download Resource';
export const DOWNLOAD_RESOURCE_SUCCESS = '[file] Download Resource Success';
export const DOWNLOAD_RESOURCE_FAILURE = '[file] Download Resource Failure';

export const UPDATE_RESOURCE_RECORD = '[file] Update Resource record';

export const RESOURCE_DOWNLOAD_ENQUEUE = '[file] Download Resource enqueue';
export const RESOURCE_DOWNLOAD_DEQUEUE = '[file] Download Resource dequeue';
export const DELETE_ALL_DOWNLOADED_RESOURCES = '[file] Delete All Downloaded Resources';

export class downloadResource implements Action {
    readonly type = DOWNLOAD_RESOURCE;
    constructor(public payload: { resourceId: string }) {
    }
}

export class downloadResourceSuccess implements Action {
    readonly type = DOWNLOAD_RESOURCE_SUCCESS;
    constructor(public payload: { resourceId: string }) {
    }
}

export class downloadResourceFailed implements Action {
    readonly type = DOWNLOAD_RESOURCE_FAILURE;
    constructor(public payload: { resourceId: string }) {
    }
}

export class updateResourceRecord implements Action {
    readonly type = UPDATE_RESOURCE_RECORD;
    constructor(public payload: { resourceId: string, downloaded: boolean }) {
    }
}

export class downloadResourceEnqueue implements Action {
    readonly type = RESOURCE_DOWNLOAD_ENQUEUE;
    constructor(public payload: { resourceId: string }) {
    }
}

export class downloadResourceDequeue implements Action {
    readonly type = RESOURCE_DOWNLOAD_DEQUEUE;
}

export class deleteAlldownloadedResources implements Action {
    readonly type = DELETE_ALL_DOWNLOADED_RESOURCES;
}