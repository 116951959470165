import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { EventsService } from './../../../../services/events/events.service';
import { Utility } from './../../../../utility/util';
import { Component, Output, EventEmitter, ViewChild } from "@angular/core";
import { ScientificActivityPlan } from "../../../../classes/scientific-activity/scientific-activity.class";
import { ScientificActivityService } from "../../../../services/scientific-activity/scientific-activity.service";
import { ScientificActivityDataService } from "../../../../data-services/scientific-activity/scientific-activity.data.service";
import { DeviceService } from "../../../../services/device/device.service";
import { PopoverController } from "@ionic/angular";
import { MultiSelectPopover } from "../../../multi-select-popover/multi-select-popover";
import { OrderByPipe } from "../../../../pipes/orderby.pipe";
import { BrandOfflineService } from "../../../../services/brand/brand.service";
import { TherapeuticAreaDataService } from "../../../../data-services/therapeutic-area/therapeutic-area.data.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { FeatureActionsMap } from "../../../../classes/authentication/user.class";
import { TrackService, TrackingEventNames } from "../../../../services/logging/tracking.service";
import { LoadingController } from "@ionic/angular";
import { RepServices } from "../../../../data-services/rep/rep.services";
import { UIService } from '../../../../services/ui/ui.service';
import { ScientificActivityPlanFilter } from '../../../../pipes/scientific-activity-plan.pipe';
import { IndTabsDataModel } from '../../../../models/ind-tabs-data-model';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { NavigationService, PageName } from '../../../../services/navigation/navigation.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { FooterService } from '@omni/services/footer/footer.service';


@Component({
  selector: 'scientific-activity-list',
  templateUrl: 'scientific-activity-list.html',
  styleUrls:['scientific-activity-list.scss'],
  providers : [ScientificActivityPlanFilter]
})
export class ScientificActivityList {

  @ViewChild('Content', {static:true}) content:HTMLIonContentElement;

  @Output() closeModal = new EventEmitter();
  @Output() planTabCloseModal = new EventEmitter();
  @Output() selectSAP: EventEmitter<ScientificActivityPlan> = new EventEmitter<ScientificActivityPlan>();

  public scientificActivities: ScientificActivityPlan[] = [];
  public selectedPlan: string;
  private originalList: ScientificActivityPlan[] = [];

  // Filter
  public productPopoverData: any[];
  public sortBy: {value:any, asc: any};
  public scientificActivityFilter = { status: "", products: [], therapeuticAreaList: [] }; //, therapeuticarea: "", brands: ""
  public productFilterList: any[] = []
  public therapeuticAreaFilterList: any[] = [];

  // SAP to display
  public pastSAP: ScientificActivityPlan[] = [];
  public currentSAP: ScientificActivityPlan[] = [];
  public futureSAP: ScientificActivityPlan[] = [];
  public hasScientificTeamPlansEnabled: boolean = false;
  public scientificPlanMode: string = "mine";
  private currentUserID:string;

  public pastScientificActivities = [];
  public currentScientificActivities = [];
  public futureScientificActivities = [];

  public pastHeaderModel: IndSectionHeaderViewDataModel;
  public currentHeaderModel: IndSectionHeaderViewDataModel;
  public futureHeaderModel: IndSectionHeaderViewDataModel;
  public noSearchResultHeaderModel: IndSectionHeaderViewDataModel;
  indHeaderLeftModel: IndHeaderLeftDataModel;

  tabs: IndTabsDataModel[] = [];
  private subs: Subscription[] = [];

  filterValueSelected: boolean=false;
  listFiltered: boolean = false;
  filterPopoverData: any[];
  private _SAPFilterPopover;
  filterBadgeCount: number = 0;
  public fromPlanTab: boolean = false;
  searching: boolean = false;

  constructor(
    private sapService: ScientificActivityService,
    private sapDataService: ScientificActivityDataService,
    private therapeuticservice: TherapeuticAreaDataService,
    private deviceService: DeviceService,
    private popover: PopoverController,
    private brandService: BrandOfflineService,
    private authService: AuthenticationService,
    private trackingService:TrackService,
    private loadingController:LoadingController,
    public repService:RepServices,
    public uiService : UIService,
    private sapFilter : ScientificActivityPlanFilter,
    public device: DeviceService,
    private events: EventsService,
    public translate: TranslateService,
    private navService: NavigationService,
    public footerService: FooterService
  ) {
  }

  async ngOnInit() {
    this.uiService.toolsActivityActive = true;
    const currentDay: Date = new Date();
    this.currentUserID = this.authService.user.systemUserID;
    if (this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TEAM_TOOL_PLAN)) {
      this.hasScientificTeamPlansEnabled = true;
    }
    let loader = await this.loadingController.create({});
    loader.present();
    this.sapDataService.getScientificActivities(false, true).then(() => {

      this.scientificActivities = [...this.sapService.sapActivities];

      this.scientificActivities.sort((x,y) => {
        if(x.createdon && y.createdon) {
          return (new Date(x.createdon).getTime()) - (new Date(y.createdon).getTime());
        }
      })
      this.originalList = [...this.scientificActivities];

      this.currentSAP = this.scientificActivities.filter((sap) => {
        return Number(sap.startDate) < currentDay.getTime() && Number(sap.endDate) > currentDay.getTime();
      });
      loader.dismiss();
      this.updateScientificActivitiesList();
      setTimeout(() => {
        this.scrollTo('currentHeader');
      }, 110);
      this.updateEmptyMessage();
    });


    let orderBy = new OrderByPipe();
    this.filterPopoverData = [
      {
        text: '',
        selectedValues: ['0'],
        value: '0',
        items: [
              { text: this.translate.instant('ALL_SCIENTIFIC_PLANS'), value: '0' }
            ],
            handler: (selectedItem, item, itemRef) => {
              if (itemRef && itemRef.parent && itemRef.parent.items) {
                if(itemRef.parent.items[1]) itemRef.parent.items[1].deSelectAll();
                if(itemRef.parent.items[2]) itemRef.parent.items[2].deSelectAll();
            }
            this.filterBadgeCount = 0;
            item.value = selectedItem.value;
            this.scientificActivityFilter = {...this.scientificActivityFilter, products:[], therapeuticAreaList: []};
            this.productFilterList = [];
            this.therapeuticAreaFilterList = [];
            this.listFiltered = false;
            this.updateEmptyMessage();
            this.updateScientificActivitiesList();
            this.initSAPHeaderLeft();
          }
      },
      {
        text: this.translate.instant('PRODUCTS'),
        value: '',
        selectedValues: [],
        multiselect: true,
        items: orderBy.transform(this.brandService.brands.map(b => ({ text: b.name, value: b.ID })), "text", true),
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          if (itemRef && itemRef.parent && itemRef.parent.items && itemRef.parent.items[0] && itemRef.parent.items[0].items && itemRef.parent.items[0].items[0]) {
            itemRef.parent.items[0].value = undefined;

            itemRef.parent.items[0].items[0].checked =
            itemRef.parent.items[0].items[0].item.checked = false;

            itemRef.parent.items[0].items[0].selectedValues =
            itemRef.parent.items[0].items[0].item.selectedValues = [];
        }
          this.onProductFilterChange(selectedItem, item, itemRef, selected);
          this.updateEmptyMessage();
          this.updateScientificActivitiesList();
          this.initSAPHeaderLeft();
        }
      },
      {
        text: this.translate.instant('THERAPEUTIC_AREAS'),
        value: '',
        selectedValues: [],
        multiselect: true,
        items: orderBy.transform(this.therapeuticservice.therapeuticAreas.map(b => ({ text: b.name, value: b.therapeuticareaId })), "text", true),
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          if (itemRef && itemRef.parent && itemRef.parent.items && itemRef.parent.items[0] && itemRef.parent.items[0].items && itemRef.parent.items[0].items[0]) {
            itemRef.parent.items[0].value = undefined;

            itemRef.parent.items[0].items[0].checked =
            itemRef.parent.items[0].items[0].item.checked = false;

            itemRef.parent.items[0].items[0].selectedValues =
            itemRef.parent.items[0].items[0].item.selectedValues = [];
          }
          this.onTherapeuticAreaFilterChange(selectedItem, item, itemRef, selected);
          this.updateEmptyMessage();
          this.updateScientificActivitiesList();
          this.initSAPHeaderLeft();
        }
      },
    ];

    this.sortBy = { value: "Open" , asc:true};

    if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan =='scientificPlans') this.fromPlanTab = true;
    this.tabs = this.uiService.getAgendaTabs();
    this.subs.push(this.device.isOfflineObservable.subscribe(isOffline => {
      this.tabs = this.uiService.getAgendaTabs();
    }))
    this.events.observe("sync:completed")
      .subscribe(() => {
        this.tabs = this.uiService.getAgendaTabs();
      });

    //this.content.resize();
    this.updateEmptyMessage();
    this.initSAPHeaderLeft();
  }

  updateEmptyMessage() {
    this.uiService.updateNothingSelectedScreenMessageFor(this.sapFilter.transform(this.scientificActivities,
      this.therapeuticAreaFilterList, this.productFilterList).length);

  }

  goBack() {
    this.uiService.toolsActivityActive = false;
    if(this.uiService.activitiesPagePlanTabSelectedPlan == 'scientificPlans') {
      this.fromPlanTab = false;
      this.planTabCloseModal.emit();
    }
    else {
      this.uiService.activitiesPagePlanTabSelectedPlan = this.uiService.activitiesPagePlanTabSelectedPlanAtHome;
      this.closeModal.emit();
    }
  }

  async itemSelected(saPlan: ScientificActivityPlan) {
    this.trackingService.tracking('ScientificPlanClicked', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
    this.selectedPlan = saPlan.ID;
    let loader = await this.loadingController.create({});
    loader.present().then(() => {
      if(!this.deviceService.isOffline) {
        this.sapDataService.getScientificPlanById(saPlan.ID).then(sp => {
          loader.dismiss();
          this.selectSAP.emit(sp);
        }).catch(err=>{
          console.log(err);
          this.selectSAP.emit(saPlan);
          loader.dismiss();
        });
      } else {
        this.selectSAP.emit(saPlan);
        loader.dismiss();
      }
    });
  }

  onCancel(event) {
    this.searching = false;
    this.scientificActivities = this.originalList;
    this.updateScientificActivitiesList();
  }
  onInput(event) {
    if (event && event.target) {
      const val = event.target.value;
      if (val && val.trim() !== '') {
        this.searching = true;
        this.scientificActivities = this.originalList.filter((item) => {
        return (item.scientificActivityPlanName.toLowerCase().indexOf(val.toLowerCase()) > -1);
        });
      } else {
        this.searching = false;
      }
    } else {
     this.scientificActivities = this.originalList;
    }
    this.updateScientificActivitiesList();
    this.updateEmptyMessage();
  }

  filterSAP(type: string) {
    this.popover
      .create({
        component: MultiSelectPopover,
        componentProps: {
          root:
            type === 'products'
              ? this.productPopoverData
              : this.filterPopoverData,
          showClear: true,
        },
        event,
      })
      .then((data) => {
        data.present();
      });
  }
  private initializePastHeader() {
    this.pastHeaderModel = {
      id: 'scientific-past-header',
      title: `${this.translate.instant('PAST')} (${
        this.pastScientificActivities.length
      })`,
      controls: [],
    };
  }

  private initializeCurrentHeader() {
    this.currentHeaderModel = {
      id: 'scientific-current-header',
      isHighlighted: true,
      title: `${this.translate.instant('CURRENT')} (${
        this.currentScientificActivities.length
      })`,
      controls: [],
    };
  }

  private initializeFutureHeader() {
    this.futureHeaderModel = {
      id: 'scientific-future-header',
      title: `${this.translate.instant('FUTURE')} (${
        this.futureScientificActivities.length
      })`,
      controls: [],
    };
  }
  private initNoSearchResultHeader() {
    this.noSearchResultHeaderModel = {
      id: 'scientific-search-header',
      title: `${this.translate.instant('RESULT')} (0)`,
      controls: [],
    };
  }
  private updateScientificActivitiesList() {
    const currentDay: Date = Utility.changeUTCDateToLocalDateWith0Time(
      new Date().getTime()
    );

    this.currentScientificActivities = this.scientificActivities.filter((sap) => {
        return (
          Number(sap.startDate) <= currentDay.getTime() &&
          Number(sap.endDate) >= currentDay.getTime() &&
          (sap.ownerID === this.currentUserID ||
            sap.users.some((x) => x.ownerid === this.currentUserID)) &&
          this.applyProductFilter(sap) &&
          this.applyTAFilter(sap)
        );
      }
    );

    this.pastScientificActivities = this.scientificActivities.filter((sap) => {
      return (
        Number(sap.endDate) < currentDay.getTime() &&
        (sap.ownerID === this.currentUserID ||
          sap.users.some((x) => x.ownerid === this.currentUserID)) &&
        this.applyProductFilter(sap) &&
        this.applyTAFilter(sap)
      );
    });

    this.futureScientificActivities = this.scientificActivities.filter(
      (sap) => {
        return (
          Number(sap.startDate) > currentDay.getTime() &&
          (sap.ownerID === this.currentUserID ||
            sap.users.some((x) => x.ownerid === this.currentUserID)) &&
          this.applyProductFilter(sap) &&
          this.applyTAFilter(sap)
        );
      }
    );

    this.initializeCurrentHeader();
    this.initializeFutureHeader();
    this.initializePastHeader();
    this.initNoSearchResultHeader();
  }

  private applyProductFilter(sap: ScientificActivityPlan) {
    if (this.productFilterList.length > 0) {
      return sap.products.some((el) =>
        this.productFilterList.some((x) => x === el.ID)
      );
    } else {
      return true;
    }
  }

  private applyTAFilter(sap: ScientificActivityPlan) {
    if (this.therapeuticAreaFilterList.length > 0) {
      return sap.therapeuticAreaList.some((el) =>
        this.therapeuticAreaFilterList.some((x) => x === el.therapeuticareaId)
      );
    } else {
      return true;
    }
  }
  public productFilter(id) {
    let index = this.productFilterList.findIndex(x => x == id);
    if (index > -1) {
      this.productFilterList.splice(index, 1);
    } else {
      this.productFilterList.push(id);
    }
  }

  public therapeuticFilter(id) {
    let index = this.therapeuticAreaFilterList.findIndex(x => x == id);
    if (index > -1) {
      this.therapeuticAreaFilterList.splice(index, 1);
    } else {
      this.therapeuticAreaFilterList.push(id);
    }
  }

  filterCurrentSAP() {

    this.currentSAP = this.originalList.filter(sap => {
      return sap.products.some(el => {
        if (this.productFilterList.some(x => x == el)) return true;

        return false;
      });
    });
  }

  public getFilterButtonBadgeCount() {
    return this.filterBadgeCount;
  }

  private getFilterCount() {
    this.filterBadgeCount = 0;
    if (this.productFilterList.length > 0) this.filterBadgeCount = this.productFilterList.length;
    if (this.therapeuticAreaFilterList.length > 0) this.filterBadgeCount += this.therapeuticAreaFilterList.length;
  }

  private onProductFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if(selectedItem && selectedItem.value){
      if(selected) {
          this.scientificActivityFilter.products.push(selectedItem.value);
          this.scientificActivityFilter.products.push(selectedItem);
          if(itemRef && itemRef.item && itemRef.item.selectedValues){
              itemRef.item.selectedValues.push(selectedItem.value);
          }
      } else {
        if(itemRef && itemRef.item && itemRef.item.selectedValues){
            let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
            if(idx >= 0){
              itemRef.item.selectedValues.splice(idx,1);
            }
        }
      }
    } else {
      this.scientificActivityFilter.products = [];
      this.productFilterList = [];
    }
    this.productFilter(selectedItem.value);
    this.getFilterCount();
    this.listFiltered = this.scientificActivityFilter.products.length > 0;
    if(!this.listFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]){
        itemRef.parent._value = itemRef.parent.items[0]._value = '0';
        this.filterPopoverData[0].value = '0';
    }
  }

  private onTherapeuticAreaFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if(selectedItem && selectedItem.value){
      if(selected) {
        this.scientificActivityFilter.therapeuticAreaList.push(selectedItem.value);
        this.scientificActivityFilter.therapeuticAreaList.push(selectedItem);
        if(itemRef && itemRef.item && itemRef.item.selectedValues){
            itemRef.item.selectedValues.push(selectedItem.value);
        }
      } else {
        if(itemRef && itemRef.item && itemRef.item.selectedValues){
          let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
          if(idx >= 0){
            itemRef.item.selectedValues.splice(idx,1);
          }
        }
      }
    } else {
      this.scientificActivityFilter.therapeuticAreaList = [];
      this.therapeuticAreaFilterList = [];
    }
    this.therapeuticFilter(selectedItem.value);
    this.getFilterCount();
    this.listFiltered = this.scientificActivityFilter.therapeuticAreaList.length > 0;
    if(!this.listFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]){
        itemRef.parent._value = itemRef.parent.items[0]._value = '0';
        this.filterPopoverData[0].value = '0';
    }
  }

  trackInInsights(evt:string){
    this.itemSelected(undefined);
    if(evt == "mine"){
      this.trackingService.tracking('MyScientificPlansTab', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
    }
    if(evt == "team"){
      this.trackingService.tracking('TeamScientificPlanTab', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
    }
    // modify

  }

  private scrollTo(data){
    let ele:any = document.getElementById(data);
    if(ele){
      ele.scrollIntoView(true);
      let yOffset = ele.offsetTop;
      try {
        this.content.scrollToPoint(yOffset,0);
      } catch (error) {

      }
    }
  }

  private initSAPHeaderLeft(): void {
    let buttons = [];
    buttons.push({
        id: "close",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      });
    this.indHeaderLeftModel = {
      id: 'sap-list-header-left',
      cssClass: 'main-tool-header-title',
      // title: this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab === 'plans' ? this.translate.instant('Home') : this.translate.instant('SCIENTIFIC_PLANS'),
      title: this.getPlaceHolder(),
      mode: true,
      isOnActPage: this.fromPlanTab ? true : false,
      controls: buttons,
    };
  }

  private getPlaceHolder(): string {
    let str = '';
    if(!this.listFiltered) {
      if(this.fromPlanTab) {
        str = this.translate.instant('HOME');
      } else {
        str = this.translate.instant('SCIENTIFIC_PLANS');
      }
    } else if(this.listFiltered && this.productFilterList.length > 0) {
      if(this.productFilterList.length > 0){
        let filteredName = [];
        this.productFilterList.forEach(filteredId => {
          filteredName.push(...this.brandService.brands.filter(brand => brand.ID === filteredId));
        })
        if(filteredName.length == 1) {
          str = filteredName[0].name;
        } else{
          filteredName.forEach(product => {
          str = product.name +', ' + str;
          });
        }
        if(this.therapeuticAreaFilterList.length > 0){
          this.therapeuticAreaFilterList.forEach(filteredId => {
            filteredName.push(...this.therapeuticservice.therapeuticAreas.filter(ta => ta.therapeuticareaId === filteredId));
          })
          filteredName.forEach(ta => {
            str = ta.name +', ' + str;
            });
        }
      }
    } else if(this.listFiltered && this.therapeuticAreaFilterList.length > 0) {
        let filteredName = [];
        this.therapeuticAreaFilterList.forEach(filteredId => {
          filteredName.push(...this.therapeuticservice.therapeuticAreas.filter(ta => ta.therapeuticareaId === filteredId));
        })
        if(filteredName.length == 1) {
          str = filteredName[0].name;
        } else{
          filteredName.forEach(ta => {
            str = ta.name +', ' + str;
          });
        }
    } else {
      str = this.fromPlanTab ? this.translate.instant('Home') : this.translate.instant('SCIENTIFIC_PLANS');
    }
    return str;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
  }

  public async openSAPFilter(event){
    this.filterValueSelected = true;
    this.onCancel(event);
    this.trackingService.tracking('ScientificPlanFilter', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
    this.popover
    .create({component: MultiSelectPopover,componentProps: { root: this.filterPopoverData }, event: event})
    .then((data) => data.present());
  }

}
