import { ReportDataManagementService } from './../../services/reports/report-data-management.service';
import { DeviceService } from './../../services/device/device.service';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from './../../config/pouch-db.config';
import { DiskService } from './../../services/disk/disk.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format, subMonths } from 'date-fns';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { Endpoints, GLOBAL_AUTH } from './../../../config/endpoints.config';
import { AuthenticationService } from './../../services/authentication.service';
import { ReportService } from './../../services/reports/report.service';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import _ from 'lodash';
import { DimensionServiceDTO, FetchAllFactsResult, MultiFactsServiceDTO, MeetingFactsDTO, MessageFactsDTO, AllFactsSyncData, ProcessedAllFactsData, ConfigurationServiceDTO, MeasureConfigData } from '../../../interfaces/edge-analytics/report.interface';
import { Utility } from '../../utility/util';
import { CoachingFactsDTO } from '../../../interfaces/edge-analytics/coaching-report.interface';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { fetchQueries } from '../../config/dynamics-fetchQueries';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { MeasureType } from '../../enums/edge-analytics/edge-analytics.enum';
import { EDGE_ANALYTICS_MEETING_REPORT_REV_KEY, EDGE_ANALYTICS_MEETING_REPORT_REV } from '../../config/edge-analytics/report.config';
export interface EdgeAnalyticsSyncData {
  syncInfo: EntitySyncInfo,
  syncState: any,
  isInitialSync: boolean,
};

@Injectable({
  providedIn: 'root'
})
export class ReportDataService{
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthenticationService,
    private readonly reportService: ReportService,
    private readonly reportDataMgmService: ReportDataManagementService,
    private readonly disk: DiskService,
    private readonly device: DeviceService,
    private readonly deltaService: DeltaService,
    private readonly dynamics: DynamicsClientService,
  ){

  }
  /** ----------------------------------------------------------------------------------------
   *  Sync management
   */
  async syncFactsAndDimensions(loadFromDBOnly) {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)) return;
    this.deltaService.pushSyncEntityName(SyncFeatureCategory.profiles);
    if (loadFromDBOnly || this.device.isOffline) {
      await this.loadEdgeDataFromDB();
    } else {
      // this.reportDataMgmService.setIsSyncing(true);
      // this.reportDataMgmService.setIsSyncSuccessful(false);
      this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.meeting);
      this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.message);
      this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.coaching);

      try {
        // Prep all sync data
        const allFactsSyncData = await this.prepAllFactsSyncData();

        // Other local variables
        const newLastUpdatedDate = new Date();
        const newLastUpdatedTime = newLastUpdatedDate.getTime();

        // Service calls
        const dataStartDate = this.getDataStartDate();
        const fetchResults = await Promise.all([
          this.fetchAllFacts(allFactsSyncData),
          this.fetchDimensions(),
          //this.fetchAndProcessConfigurations(),
        ]);

        if (Array.isArray(fetchResults)) {
          let facts: FetchAllFactsResult;
          let dimensions: DimensionServiceDTO;
          if (fetchResults[0]) {
            // Facts
            facts = fetchResults[0];
          }
          if (fetchResults[1]) {
            // Dimensions
            dimensions = fetchResults[1];
            this.saveDimensionsData(dimensions, newLastUpdatedTime);
          }

          // Process and save all facts data
          const allFactsProcessResults: ProcessedAllFactsData = await this.processAllFacts(facts, allFactsSyncData, newLastUpdatedTime);

          // Update sync info & sync status (non blocking from this point.. -> no await)
          this.updateSyncInfoAndState(allFactsSyncData.meetingSyncData, newLastUpdatedTime);
          this.updateSyncInfoAndState(allFactsSyncData.messageSyncData, newLastUpdatedTime);
          this.updateSyncInfoAndState(allFactsSyncData.coachingSyncData, newLastUpdatedTime);

          if (allFactsProcessResults  && allFactsProcessResults.allMeetings
                                      && allFactsProcessResults.allMessages
                                      && allFactsProcessResults.dataForWorkingDaysCalculations
                                      && allFactsProcessResults.coachingFacts
                                      && dimensions) {

            try {
              // We're interested in current user's data only for now
              // so leave only current user's data in the dimension array
              // Reason for keeping it as an array is because there's a discussion about team view support..
              const filteredSystemUserDimensions = Array.isArray(dimensions.systemUserDimensions)
                                                  ? dimensions.systemUserDimensions.filter(s => s.internalemailaddress === this.authService.user.userPrincipalName)
                                                  : [];

              // No need to block UI for this so no await here..
              // Setup initial measures data & cube
              this.reportService.setTotalYearMonthList(dataStartDate);
              Promise.all([
                this.reportService.setupMeetingMeasureDataCube( allFactsProcessResults.allMeetings,
                                                                allFactsProcessResults.dataForWorkingDaysCalculations,
                                                                dimensions?.productDimensions ?? [],
                                                                dimensions?.callPlanDimensions ?? [],
                                                                filteredSystemUserDimensions,
                                                                dimensions?.positionContactCountDimensions ?? [],
                                                                newLastUpdatedDate,
                                                                dataStartDate),
                this.reportService.setupMessageMeasureDataCube( allFactsProcessResults.allMessages,
                                                                dimensions.emailTemplateDimensions ?? [],
                                                                dimensions.messageChannelDimensions ?? [],
                                                                filteredSystemUserDimensions,
                                                                newLastUpdatedDate,
                                                                dataStartDate),
                this.reportService.setupCoachingMeasureDataCube(allFactsProcessResults.coachingFacts, filteredSystemUserDimensions, newLastUpdatedDate, dataStartDate),
              ]).then(() => {
                this.reportService.setLastRefreshDateFormattedText(dimensions.lastRefreshDate);
                // this.reportDataMgmService.setIsSyncing(false);
                // this.reportDataMgmService.setIsSyncSuccessful(true);
                this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
                this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
                this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
              });
            } catch (error) {
              console.error('fetchFactsAndDimensions: measureDataSetup: ', error);
              // this.reportDataMgmService.setIsSyncing(false);
              // this.reportDataMgmService.setIsSyncSuccessful(false);
              this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
              this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
              this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
            }
          } else {
            console.warn('fetchFactsAndDimensions: data missing..', allFactsProcessResults, dimensions);
            // this.reportDataMgmService.setIsSyncing(false);
            // this.reportDataMgmService.setIsSyncSuccessful(false);
            this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
            this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
            this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
          }
        }
      } catch (error) {
        console.error('fetchFactsAndDimensions: ', error);
        // this.reportDataMgmService.setIsSyncing(false);
        // this.reportDataMgmService.setIsSyncSuccessful(false);
        this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
        this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
        this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
      }
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Fetch functions
   */
  private async fetchAllFacts(allFactsSyncData: AllFactsSyncData): Promise<FetchAllFactsResult> {
    const positionIds = this.getPositionIds();
    const dataStartDate = this.getDataStartDate();
    let response: FetchAllFactsResult;

    if (positionIds && dataStartDate) {
      try {
        const results = await Promise.all([
          this.fetchMultiFacts( positionIds, dataStartDate,
                                allFactsSyncData.meetingSyncData,
                                allFactsSyncData.messageSyncData,
                                allFactsSyncData.coachingSyncData),
          // Add more facts service calls here down the road..
        ]);

        if (Array.isArray(results) && results[0]) {
          response = {
            meetingFacts: Array.isArray(results[0].meetingFacts) ? results[0].meetingFacts : [],
            messageFacts: Array.isArray(results[0].messageFacts) ? results[0].messageFacts : [],
            coachingFacts: Array.isArray(results[0].coachingFacts) ? results[0].coachingFacts : [],
          };
        }
      } catch (error) {
        console.error('fetchAllFacts: ', error);
      }
    } else {
      console.warn('fetchAllFacts: position IDs or data start date missing.. ', positionIds, dataStartDate);
    }
    return response;
  }
  private async fetchMultiFacts(  positionIDs: string,
                                  dataStartDate: string,
                                  meetingSyncData: EdgeAnalyticsSyncData,
                                  messageSyncData: EdgeAnalyticsSyncData,
                                  coachingSyncData: EdgeAnalyticsSyncData,
                                ): Promise<MultiFactsServiceDTO> {

    let facts: MultiFactsServiceDTO;

    if (meetingSyncData && messageSyncData && coachingSyncData) {
      let url = this.authService.userConfig.activeInstance.EdgeAnalyticsUrl + Endpoints.edgeAnalytics.facts;
      url = url.replace('{positionIds}', positionIDs);
      url = url.replace('{startYearMonth}', dataStartDate);

      const meetingReportRevision = localStorage.getItem(EDGE_ANALYTICS_MEETING_REPORT_REV_KEY);
      if (!meetingSyncData.isInitialSync && (!meetingReportRevision || meetingReportRevision !== EDGE_ANALYTICS_MEETING_REPORT_REV)) {
        meetingSyncData.isInitialSync = true;
      }

      url = !meetingSyncData.isInitialSync ? url + '&meetingLastUpdatedTime=' + meetingSyncData.syncState.lastUpdatedTime : url;
      url = !messageSyncData.isInitialSync ? url + '&messageLastUpdatedTime=' + messageSyncData.syncState.lastUpdatedTime : url;
      url = !coachingSyncData.isInitialSync ? url + '&coachingLastUpdatedTime=' + coachingSyncData.syncState.lastUpdatedTime : url;

      try {
        facts = await this.http.get<MultiFactsServiceDTO>(url).toPromise();
      } catch (error) {
        console.error('fetchMultiFacts: ', error);
        if (meetingSyncData.syncInfo) {
          this.deltaService.addSyncErrorToEntitySyncInfo(meetingSyncData.syncInfo, url, error);
        }
        if (messageSyncData.syncInfo) {
          this.deltaService.addSyncErrorToEntitySyncInfo(messageSyncData.syncInfo, url, error);
        }
        if (coachingSyncData.syncInfo) {
          this.deltaService.addSyncErrorToEntitySyncInfo(coachingSyncData.syncInfo, url, error);
        }
      }
    } else {
      console.error('fetchMultiFacts: Invalid input: ', positionIDs, dataStartDate, meetingSyncData, messageSyncData, coachingSyncData);
    }

    return facts;
  }

  private async fetchDimensions(): Promise<DimensionServiceDTO> {
    const positionIds = this.getPositionIds();
    const dataStartDate = this.getDataStartDate();
    let url = this.authService.userConfig.activeInstance.EdgeAnalyticsUrl + Endpoints.edgeAnalytics.dimensions;
    url = url.replace('{positionIds}', positionIds);
    url = url.replace('{startYearMonth}', dataStartDate);
    let dimensions;
    try {
      dimensions = await this.http.get(url).toPromise();
    } catch (error) {
      console.error('getDimensions: ', error);
    }

    return dimensions;
  }

  public async fetchAndProcessConfigurations(loadFromDBOnly) {
    if (loadFromDBOnly || !this.authService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)) return;
    const fetchXML = fetchQueries.edgeAnalytics.fetchConfiguration;
    let response: ConfigurationServiceDTO[];

    try {
      this.reportDataMgmService.setConfigurationsLoaded(false);

      response = await this.dynamics.executeFetchQuery('indskr_edgemeasureses', fetchXML);

      if (Array.isArray(response)) {
        await this.processConfigurations(response);
      }
    } catch (error) {
      console.error('fetchAndProcessConfigurations: ', error);
    } finally {
      this.reportDataMgmService.setConfigurationsLoaded(true);
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Sync helper functions
   */
  private getPositionIds(): string {
    let positionIds = '';
    try {
      const positionIdArray = this.authService.user.positions.map(pos=>{
        return pos.ID.toUpperCase();
      });
      if (Array.isArray(positionIdArray)) {
        positionIds = positionIdArray.toString();
      }
    } catch (error) {
      console.error('getPositionIds: ', error);
    }
    return positionIds;
  }
  private getDataStartDate(): string {
    let formattedStartDate = '';
    try {
      const offlineDataDuration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration > 0)
                                ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
      const offlineDurInMonths = Math.ceil(offlineDataDuration / 30);
      const now = new Date();
      const dataStartDate: Date = subMonths(now, offlineDurInMonths);
      if (dataStartDate) {
        formattedStartDate = format(dataStartDate, 'YYYYMM');
      }
    } catch (error) {
      console.error('getDataStartDate: ', error);
    }
    return formattedStartDate;
  }
  private updateSyncInfoAndState(syncData: EdgeAnalyticsSyncData, newLastUpdatedTime: number) {
    if (syncData && syncData.syncInfo) {
      this.deltaService.addEntitySyncInfo(syncData.syncInfo);
      if (syncData.syncInfo.syncStatus) {
        syncData.syncState.lastUpdatedTime = newLastUpdatedTime;
        this.disk.updateSyncState(syncData.syncState);
      }
    }
  }

  private async prepSyncData(entityName: EntityNames, syncStateDbKey: string): Promise<EdgeAnalyticsSyncData> {
    if (entityName && syncStateDbKey) {
      const syncInfo: EntitySyncInfo = {
        entityName,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      const syncState = await this.disk.getSyncState(syncStateDbKey);
      const isInitialSync = (!syncState || !syncState.lastUpdatedTime) ? true : false;
      return { syncInfo, syncState, isInitialSync };
    } else {
      console.error('report.data.service: prepSyncData: Invalid input: ', entityName, syncStateDbKey);
    }
  }
  private async prepAllFactsSyncData(): Promise<AllFactsSyncData> {
    let syncData: AllFactsSyncData;
    try {
      const results = await Promise.all([
        this.prepSyncData(EntityNames.meetingFacts, DB_SYNC_STATE_KEYS.SYNC_EDGE_ANALYTICS_MEETING),
        this.prepSyncData(EntityNames.messageFacts, DB_SYNC_STATE_KEYS.SYNC_EDGE_ANALYTICS_MESSAGE),
        this.prepSyncData(EntityNames.messageFacts, DB_SYNC_STATE_KEYS.SYNC_EDGE_ANALYTICS_COACHING),
      ]);

      syncData = {
        meetingSyncData: results[0] || undefined,
        messageSyncData: results[1] || undefined,
        coachingSyncData: results[2] || undefined,
      };
    } catch (error) {
      console.error('prepAllFactsSyncData: ', error)
    }
    return syncData;
  }

  private async processAllFacts(allFacts: FetchAllFactsResult, allFactsSyncData: AllFactsSyncData, newLastUpdatedTime: number): Promise<ProcessedAllFactsData> {
    let response: ProcessedAllFactsData;
    if (allFacts && allFactsSyncData && newLastUpdatedTime) {
      try {
        const results = await Promise.all([
          this.processMeetingFacts(allFacts.meetingFacts, allFactsSyncData.meetingSyncData, newLastUpdatedTime),
          this.processMessageFacts(allFacts.messageFacts, allFactsSyncData.messageSyncData, newLastUpdatedTime),
          this.processCoachingFacts(allFacts.coachingFacts, allFactsSyncData.coachingSyncData, newLastUpdatedTime),
        ]);
        if (Array.isArray(results) && results.length > 0) {
          response = {
            allMeetings: results[0] && results[0].allMeetings ? results[0].allMeetings : undefined,
            dataForWorkingDaysCalculations: results[0] && results[0].dataForWorkingDaysCalculations ? results[0].dataForWorkingDaysCalculations : undefined,
            allMessages: results[1] && results[1].allMessages ? results[1].allMessages : undefined,
            coachingFacts: results[2] && results[2].coachingFacts ? results[2].coachingFacts : undefined,
          };
        }
      } catch (error) {
        console.error('processAllFacts: Invalid input: ', error);
      }
    } else {
      console.warn('processAllFacts: Invalid input: ', allFacts, allFactsSyncData, newLastUpdatedTime);
    }
    return response;
  }
  private async processMeetingFacts(meetingFacts: MeetingFactsDTO[],
                                    syncData: EdgeAnalyticsSyncData,
                                    newLastUpdatedTime: number): Promise<{  allMeetings: MeetingFactsDTO[],
                                                                            dataForWorkingDaysCalculations: MeetingFactsDTO[],
                                                                            wasThereChangeInData: boolean }> {

    let response = { allMeetings: undefined, dataForWorkingDaysCalculations: undefined, wasThereChangeInData: false };

    if (Array.isArray(meetingFacts) && syncData && newLastUpdatedTime) {
      syncData.syncInfo.totalSynced = meetingFacts.length;

      const isInitialSync = syncData.isInitialSync || (meetingFacts[0] && meetingFacts[0].is_fullrefresh === 'Y') ? true : false;
      const meetingReportRevision = localStorage.getItem(EDGE_ANALYTICS_MEETING_REPORT_REV_KEY);
      const updateMeetingReportRevInLocalStorage = !meetingReportRevision || meetingReportRevision !== EDGE_ANALYTICS_MEETING_REPORT_REV;
      
      let wasThereChangeInData = false;
      let dataForWorkingDaysCalculations: any[];
      let allMeetings: any[];

      if (!isInitialSync) {
        // Delta sync
        const savedData = await this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MEETINGFACTS, true);

        if (savedData && savedData.raw) {
          const deltaDataForWorkingDaysCalculations = [];
          const deltaAllMeetings = [];


          for (const fact of meetingFacts) {
            if (fact.source_type === 'WORK' || fact.source_type === 'HLD_WORK' || fact.source_type === 'TOFF') {
              // Working days fact
              fact.id = this.getWorkingDaysFactId(fact);
              if (fact.id !== null) {
                deltaDataForWorkingDaysCalculations.push(fact);
              }
            } else {
              // Meeting fact
              fact.id = this.getMeetingFactId(fact);
              if (fact.id !== null) {
                fact.isPhoneCall = fact.source_type === 'PH' ? 1 : 0;
                fact.inperson = (fact.isremote || fact.isPhoneCall) ? 0 : 1;
                fact.livetime = fact.isremote ? 1 : 0;
                deltaAllMeetings.push(fact);
              }
            }
          }

          if (Array.isArray(savedData.raw.dataForWorkingDaysCalculations)) {
            dataForWorkingDaysCalculations = savedData.raw.dataForWorkingDaysCalculations;
            const wasWorkingDaysDataUpdated = deltaDataForWorkingDaysCalculations.length > 0 ? true : false;
            wasThereChangeInData = wasThereChangeInData || wasWorkingDaysDataUpdated;

            for (let i = 0; i < deltaDataForWorkingDaysCalculations.length; i++) {
              const deltaFact = deltaDataForWorkingDaysCalculations[i];
              const idx = dataForWorkingDaysCalculations
                             .findIndex(f => f.id === deltaFact.id);

              if (idx >= 0) {
                if (dataForWorkingDaysCalculations[idx].updatedon < deltaFact.updatedon) {
                  // Update
                  dataForWorkingDaysCalculations[idx] = deltaFact;
                }
              } else if (deltaFact.id !== null) {
                // Append
                dataForWorkingDaysCalculations.push(deltaFact);
              }
            }
          }

          if (Array.isArray(savedData.raw.allMeetings)) {
            allMeetings = savedData.raw.allMeetings;
            const wasAllMeetingsDataUpdated = deltaAllMeetings.length > 0 ? true : false;
            wasThereChangeInData = wasThereChangeInData || wasAllMeetingsDataUpdated;

            for (let i = 0; i < deltaAllMeetings.length; i++) {
              const deltaFact = deltaAllMeetings[i];
              deltaFact.isPhoneCall = deltaFact.source_type === 'PH' ? 1 : 0;
              deltaFact.inperson = (deltaFact.isremote || deltaFact.isPhoneCall) ? 0 : 1;
              deltaFact.livetime = deltaFact.isremote ? 1 : 0;

              const idx = allMeetings.findIndex(f => f.id === deltaFact.id);

              if (idx >= 0) {
                allMeetings[idx] = deltaFact;
              } else if (deltaFact.id !== null) {
                allMeetings.push(deltaFact);
              }
            }
          }

          if (wasThereChangeInData) {
            this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MEETINGFACTS, doc => {
              savedData.lastUpdatedTime = newLastUpdatedTime;
              return savedData;
            }).catch(error => console.error('processMeetingFacts: updateOrInsert: ', error));
          }
        } else {
          console.warn('processMeetingFacts: No locally saved data but it did a delta sync?');
        }

      } else {
        // Initial sync
        dataForWorkingDaysCalculations = [];
        allMeetings = [];

        for (const fact of meetingFacts) {
          if (fact.source_type === 'WORK' || fact.source_type === 'HLD_WORK' || fact.source_type === 'TOFF') {
            // Working days fact
            fact.id = this.getWorkingDaysFactId(fact);
            if (fact.id !== null) {
              dataForWorkingDaysCalculations.push(fact);
            }
          } else {
            // Meeting fact
            fact.id = this.getMeetingFactId(fact);
            if (fact.id !== null) {
              fact.isPhoneCall = fact.source_type === 'PH' ? 1 : 0;
              fact.inperson = (fact.isremote || fact.isPhoneCall) ? 0 : 1;
              fact.livetime = fact.isremote ? 1 : 0;
              allMeetings.push(fact);
            }
          }
        }

        wasThereChangeInData = dataForWorkingDaysCalculations.length > 0 || allMeetings.length > 0;

        // Overwrite it
        this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MEETINGFACTS, doc => {
          return {
            raw: {
              allMeetings,
              dataForWorkingDaysCalculations
            },
            lastUpdatedTime: newLastUpdatedTime
          };
        });
      }

      if (updateMeetingReportRevInLocalStorage) {
        localStorage.setItem(EDGE_ANALYTICS_MEETING_REPORT_REV_KEY, EDGE_ANALYTICS_MEETING_REPORT_REV);
      }

      response = { allMeetings, dataForWorkingDaysCalculations, wasThereChangeInData };
    } else {
      console.error('processMeetingFacts: Invalid input: ', meetingFacts, newLastUpdatedTime);
    }

    return response;
  }
  private getWorkingDaysFactId(fact: MeetingFactsDTO): string {
    return fact ? fact.sk_systemuserid + '_' + fact.year_month + '_' + fact.source_type : null;
  }
  private getMeetingFactId(fact: MeetingFactsDTO): string {
    return fact ? fact.sk_systemuserid + '_' + fact.year_month + '_' + fact.sk_activityid + '_' + fact.sk_contactid + '_' + fact.sk_productid : null;
  }
  private async processMessageFacts(messageFacts: MessageFactsDTO[],
                                    syncData: EdgeAnalyticsSyncData,
                                    newLastUpdatedTime: number): Promise<{  allMessages: MessageFactsDTO[]}> {

    let response = { allMessages: undefined };

    if (Array.isArray(messageFacts) && syncData && newLastUpdatedTime) {
      syncData.syncInfo.totalSynced = messageFacts.length;

      const isInitialSync = syncData.isInitialSync || (messageFacts[0] && messageFacts[0].is_fullrefresh === 'Y') ? true : false;
      let wasThereUpdate = false;
      let allMessages: any[];

      if (!isInitialSync) {
        // Delta sync
        const savedData = await this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MESSAGE_FACTS, true);
        if (savedData && savedData.raw) {
          const deltaAllMessages = messageFacts;

          if (Array.isArray(savedData.raw.allMessages)) {
            allMessages = savedData.raw.allMessages;
            const wasDataUpdated = deltaAllMessages.length > 0 ? true : false;
            wasThereUpdate = wasThereUpdate || wasDataUpdated;

            for (let i = 0; i < deltaAllMessages.length; i++) {
              const deltaFact = deltaAllMessages[i];
              deltaFact.id = deltaFact.sk_systemuserid + '_' + deltaFact.dm_sentdate + '_' + deltaFact.sk_channelid + '_' + deltaFact.sk_contactid + '_' + deltaFact.sk_productid + '_' + deltaFact.title;

              if (deltaFact.templateid === null) {
                deltaFact.templateid = '';
              }
              const idx = allMessages.findIndex(f => f.id === deltaFact.id);

              if (idx >= 0) {
                if (allMessages[idx].updatedon < deltaFact.updatedon) {
                  // Update
                  allMessages[idx] = deltaFact;
                }
              } else {
                // Append
                allMessages.push(deltaFact);
              }
            }
          }

          if (wasThereUpdate) {
            this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MESSAGE_FACTS, doc => {
              savedData.lastUpdatedTime = newLastUpdatedTime;
              return savedData;
            });
          }
        } else {
          console.warn('processMessageFacts: No locally saved data but it did a delta sync?');
        }
      } else {
        // Initial sync
        allMessages = messageFacts;

        for (let i = 0; i < allMessages.length; i++) {
          const fact = allMessages[i];

          fact.id = fact.sk_systemuserid + '_' + fact.dm_sentdate + '_' + fact.sk_channelid + '_' + fact.sk_contactid + '_' + fact.sk_productid;
          if (fact.templateid === null) {
            fact.templateid = '';
          }
        }

        // Overwrite it
        this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MESSAGE_FACTS, doc => {
          return {
            raw: {
              allMessages
            },
            lastUpdatedTime: newLastUpdatedTime
          }
        });
      }

      response = { allMessages };
    } else {
      console.error('processMessageFacts: Invalid input: ', messageFacts, newLastUpdatedTime);
    }

    return response;
  }
  private async processCoachingFacts(facts: CoachingFactsDTO[], syncData: EdgeAnalyticsSyncData, newLastUpdatedTime: number): Promise< { coachingFacts: CoachingFactsDTO[] } > {
    let response = { coachingFacts: undefined };

    if (Array.isArray(facts) && syncData && newLastUpdatedTime) {
      syncData.syncInfo.totalSynced = facts.length;

      const isInitialSync = syncData.isInitialSync || (facts[0] && facts[0].is_fullrefresh === 'Y') ? true : false;
      let wasThereUpdate = false;
      let coachingFacts: CoachingFactsDTO[];

      if (!isInitialSync) {
        // Delta sync
        const savedData = await this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_COACHING_FACTS, true);
        if (savedData && savedData.raw) {
          const deltaCoachingFacts = facts;

          if (Array.isArray(savedData.raw.facts)) {
            coachingFacts = savedData.raw.facts;
            const wasDataUpdated = deltaCoachingFacts.length > 0 ? true : false;
            wasThereUpdate = wasThereUpdate || wasDataUpdated;

            for (let i = 0; i < deltaCoachingFacts.length; i++) {
              const deltaFact = deltaCoachingFacts[i];
              deltaFact.id = deltaFact.sk_systemuserid + '_' + deltaFact.activitydate + '_' + deltaFact.activityid + '_' + deltaFact.sk_contactid + '_' + deltaFact.sk_productid;

              if (deltaFact.coaching_score === null) {
                deltaFact.coaching_score = 0;
              }
              const idx = coachingFacts.findIndex(f => f.id === deltaFact.id);

              if (idx >= 0) {
                if (coachingFacts[idx].updatedon < deltaFact.updatedon) {
                  // Update
                  coachingFacts[idx] = deltaFact;
                }
              } else {
                // Append
                coachingFacts.push(deltaFact);
              }
            }
          }

          if (wasThereUpdate) {
            this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_COACHING_FACTS, doc => {
              savedData.lastUpdatedTime = newLastUpdatedTime;
              return savedData;
            });
          }
        } else {
          console.warn('processCoachingFacts: No locally saved data but it did a delta sync?');
        }
      } else {
        // Initial sync
        coachingFacts = facts;

        for (let i = 0; i < coachingFacts.length; i++) {
          const fact = coachingFacts[i];

          fact.id = fact.sk_systemuserid + '_' + fact.activitydate + '_' + fact.activityid + '_' + fact.sk_contactid + '_' + fact.sk_productid;
          if (fact.coaching_score === null) {
            fact.coaching_score = 0;
          }
        }

        // Overwrite it
        this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_COACHING_FACTS, doc => {
          return {
            raw: { facts: coachingFacts },
            lastUpdatedTime: newLastUpdatedTime
          };
        });
      }

      response = { coachingFacts };
    } else {
      console.error('processCoachingFacts: Invalid input: ', facts, newLastUpdatedTime);
    }

    return response;
  }

  private saveDimensionsData(data: DimensionServiceDTO, newLastUpdatedTime: number) {
    // Just overwrite it since it's a full sync every time
    this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MEETING_DIMENSIONS, doc => {
      return {
        raw: data,
        lastUpdatedTime: newLastUpdatedTime
      }
    });
  }

  private async processConfigurations(data: ConfigurationServiceDTO[]) {
    let configurations: MeasureConfigData;
    let configList: ConfigurationServiceDTO[];
    try {
      data = _.sortBy(data, 'createdon');
      let groupedList = _.groupBy(data, 'indskr_edgemeasuresid');
      configList = groupedList && Array.isArray(data) && data.length > 0 && groupedList[data[data.length - 1].indskr_edgemeasuresid] ? groupedList[data[data.length - 1].indskr_edgemeasuresid] : null;

      if (Array.isArray(configList)) {
        configurations = (_.chain(configList)
                          .map(o =>  {
                            // Measure Board KPI Option set field has following format
                            // {{ Measure }} - {{ KPI }}
                            const measureBoardKpi = o['mk.indskr_name'];
                            if (measureBoardKpi) {
                              const split = measureBoardKpi.split(' - ');
                              if (Array(split) && split.length === 2) {
                                return { measure: split[0], kpi: split[1] };
                              }
                            }
                          })
                          .groupBy('measure')
                          .value()) as MeasureConfigData;
      }

      // Set configurations
      this.reportDataMgmService.setConfigurations(configurations);
      this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_CONFIGURATIONS, doc => ({ configurations }))
                .catch(e => console.error('processConfigurations: updateOrInsert: ', e));
    } catch (error) {
      console.error('processConfigurations: ', error);
    }
  }

  /** ----------------------------------------------------------------------------------------
   *  Offline data load function
   */
  async loadEdgeDataFromDB() {
    // Configuration loading state
    this.reportDataMgmService.setConfigurationsLoaded(false);

    // this.reportDataMgmService.setIsSyncing(true);
    // this.reportDataMgmService.setIsSyncSuccessful(false);
    this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.meeting);
    this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.message);
    this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.coaching);

    const [
      dimensionsDoc,
      configurationsDoc,
      meetingFactsDoc,
      messageFactsDoc,
      coachingFactsDoc,
    ] = await Promise.all([
        this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MEETING_DIMENSIONS, true),
        this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_CONFIGURATIONS, true),
        this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MEETINGFACTS, true),
        this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MESSAGE_FACTS, true),
        this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_COACHING_FACTS, true),
    ]);

    if (dimensionsDoc && dimensionsDoc.raw && meetingFactsDoc && meetingFactsDoc.raw && messageFactsDoc && messageFactsDoc.raw && coachingFactsDoc && coachingFactsDoc.raw) {
      try {
        // Set configurations
        if (configurationsDoc && configurationsDoc.configurations) {
          this.reportDataMgmService.setConfigurations(configurationsDoc.configurations);
        }
        this.reportDataMgmService.setConfigurationsLoaded(true);

        // We're interested in current user's data only for now
        // so leave only current user's data in the dimension array
        // Reason for keeping it as an array is because there's a discussion about team view support..
        const filteredSystemUserDimensions = Array.isArray(dimensionsDoc.raw?.systemUserDimensions)
                                            ? dimensionsDoc.raw?.systemUserDimensions?.filter(s => s.internalemailaddress === this.authService.user.userPrincipalName)
                                            : [];

        // No need to block UI for this so no await here..
        // Setup initial measures data & cube
        const dataStartDate = this.getDataStartDate();
        this.reportService.setTotalYearMonthList(dataStartDate);
        Promise.all([
          this.reportService.setupMeetingMeasureDataCube( meetingFactsDoc.raw?.allMeetings ?? [],
                                                          meetingFactsDoc.raw?.dataForWorkingDaysCalculations ?? [],
                                                          dimensionsDoc.raw?.productDimensions ?? [],
                                                          dimensionsDoc.raw?.callPlanDimensions ?? [],
                                                          filteredSystemUserDimensions,
                                                          dimensionsDoc.raw?.positionContactCountDimensions ?? [],
                                                          new Date(meetingFactsDoc.lastUpdatedTime),
                                                          dataStartDate),
          this.reportService.setupMessageMeasureDataCube( messageFactsDoc.raw?.allMessages ?? [],
                                                          dimensionsDoc.raw?.emailTemplateDimensions ?? [],
                                                          dimensionsDoc.raw?.messageChannelDimensions ?? [],
                                                          filteredSystemUserDimensions,
                                                          new Date(messageFactsDoc.lastUpdatedTime),
                                                          dataStartDate),
          this.reportService.setupCoachingMeasureDataCube(coachingFactsDoc.raw?.facts ?? [], filteredSystemUserDimensions, new Date(coachingFactsDoc.lastUpdatedTime), dataStartDate),
        ]).then(() => {
          this.reportService.setLastRefreshDateFormattedText(dimensionsDoc.raw.lastRefreshDate);
          // this.reportDataMgmService.setIsSyncing(false);
          // this.reportDataMgmService.setIsSyncSuccessful(true);
          this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
          this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
          this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
        });
      } catch (error) {
        console.error('loadEdgeDataFromDB: ', error);
        // this.reportDataMgmService.setIsSyncing(false);
        // this.reportDataMgmService.setIsSyncSuccessful(false);
        this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
        this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
        this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
      }
    } else {
      console.warn('loadEdgeDataFromDB: Invalid data: ', dimensionsDoc, configurationsDoc, meetingFactsDoc, messageFactsDoc, coachingFactsDoc);
      // this.reportDataMgmService.setIsSyncing(false);
      // this.reportDataMgmService.setIsSyncSuccessful(false);
      this.reportDataMgmService.deRegisterSyncTask(MeasureType.meeting);
      this.reportDataMgmService.deRegisterSyncTask(MeasureType.message);
      this.reportDataMgmService.deRegisterSyncTask(MeasureType.coaching);
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Data purge functions
   */
  async purgeData(maxEndDateUnixTimestamp: number) {
    try {
      const maxEndDate: Date = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp, true);
      const maxEndDateYearMonthNumber = parseInt(format(maxEndDate, 'YYYYMM'));

      if (maxEndDateYearMonthNumber > 200000) {
        await Promise.all([
          this.purgeMeetingFacts(maxEndDateYearMonthNumber),
          this.purgeMessageFacts(maxEndDateYearMonthNumber),
          this.purgeCoachingFacts(maxEndDateYearMonthNumber),
        ]);
      } else {
        console.error('report.data.service: purgeData: Invalid date', maxEndDateUnixTimestamp, maxEndDate, maxEndDateYearMonthNumber);
      }
    } catch (error) {
      console.error('report.data.service: purgeData: ', error);
    }
  }
  private async purgeMeetingFacts(maxEndDateYearMonthNumber: number) {
    const meetingFactsDoc = await this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MEETINGFACTS, true);
    if (meetingFactsDoc && meetingFactsDoc.raw) {
      let wasThereChangeInData = false;

      if (Array.isArray(meetingFactsDoc.raw.allMeetings)) {
        const filteredAllMeetings = meetingFactsDoc.raw.allMeetings.filter((m: MeetingFactsDTO) => m.year_month >= maxEndDateYearMonthNumber);
        if (Array.isArray(filteredAllMeetings) && meetingFactsDoc.raw.allMeetings.length !== filteredAllMeetings.length) {
          wasThereChangeInData = true;
          meetingFactsDoc.raw.allMeetings = filteredAllMeetings;
        }
      }
      if (Array.isArray(meetingFactsDoc.raw.dataForWorkingDaysCalculations)) {
        const filteredData = meetingFactsDoc.raw.dataForWorkingDaysCalculations.filter((m: MeetingFactsDTO) => m.year_month >= maxEndDateYearMonthNumber);
        if (Array.isArray(filteredData) && meetingFactsDoc.raw.dataForWorkingDaysCalculations.length !== filteredData.length) {
          wasThereChangeInData = true;
          meetingFactsDoc.raw.dataForWorkingDaysCalculations = filteredData;
        }
      }

      if (wasThereChangeInData) {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MEETINGFACTS, doc => meetingFactsDoc);
      }
    }
  }
  private async purgeMessageFacts(maxEndDateYearMonthNumber: number) {
    const messageFactsDoc = await this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_MESSAGE_FACTS, true);
    if (messageFactsDoc && messageFactsDoc.raw && Array.isArray(messageFactsDoc.raw.allMessages)) {
      let wasThereChangeInData = false;

      const filteredData = messageFactsDoc.raw.allMessages.filter((m: MessageFactsDTO) => m.dm_sentdate >= maxEndDateYearMonthNumber);
      if (Array.isArray(filteredData) && messageFactsDoc.raw.allMessages.length !== filteredData.length) {
        wasThereChangeInData = true;
        messageFactsDoc.raw.allMessages = filteredData;
      }

      if (wasThereChangeInData) {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_MESSAGE_FACTS, doc => messageFactsDoc);
      }
    }
  }
  private async purgeCoachingFacts(maxEndDateYearMonthNumber: number) {
    const coachingFactsDoc = await this.disk.retrieve(DB_KEY_PREFIXES.EDGEANALYTICS_COACHING_FACTS, true);
    if (coachingFactsDoc && coachingFactsDoc.raw && Array.isArray(coachingFactsDoc.raw.facts)) {
      let wasThereChangeInData = false;

      const filteredData = coachingFactsDoc.raw.facts.filter((m: CoachingFactsDTO) => m.yearandmonth >= maxEndDateYearMonthNumber);
      if (Array.isArray(filteredData) && coachingFactsDoc.raw.facts.length !== filteredData.length) {
        wasThereChangeInData = true;
        coachingFactsDoc.raw.facts = filteredData;
      }

      if (wasThereChangeInData) {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.EDGEANALYTICS_COACHING_FACTS, doc => coachingFactsDoc);
      }
    }
  }
}
