import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OmniAccordionViewDataModel } from '@omni/models/omniAccordionViewDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { UIService } from '@omni/services/ui/ui.service';
import { SuggestionPillType } from '@omni/models/search-config-data-model';
import { SearchConfigDataService } from '@omni/data-services/search-config/search-config-data-service';
import { SearchConfigService } from '@omni/services/search/search-config.service';

@Component({
  selector: 'omni-accordion-item',
  templateUrl: 'omni-accordion-item.html',
  styleUrls:['omni-accordion-item.scss']
})
export class OmniAccordionItemComponent {

  @Input() viewData:OmniAccordionViewDataModel;
  @Input() item:any;
  @Output() onToggleChildItem = new EventEmitter();
  @Output() onToggleChildItemClick = new EventEmitter();
  @Output() onLoadMoreOptions = new EventEmitter();
  @Output() onToggleSingleItem = new EventEmitter();
  @Input('depth') depth: number = 0;

  public childItemSelected: boolean;
  public searchText: string = '';
  tempViewData: OmniAccordionViewDataModel;
  public searching: boolean = false;
  public ngDestroy$ = new Subject<boolean>();

  public showSpinner = false;
  public childItems: Array<{
    id: string,
    label: string,
    value: string | Array<any>,
    isChecked?: boolean,
    type?: number | string,
    category?: string,
    showArrow?: boolean;
    arrowType?: string;
    isSrcAttribute?: boolean;
    iconName?: string;
    isSingleSelect?: boolean;
    hideCheckbox?:boolean;
}> = [];

  constructor(
    public device: DeviceService,
    private iab: InAppBrowser,
    public contactService: ContactOfflineService,
    private contactDataService: ContactDataService,
    public uiService: UIService,
    public searchConfigDataService: SearchConfigDataService,
    public searchConfigService: SearchConfigService,
  ){
  }
  ngOnInit() {
    if(this.item.isExpanded) {
      const foundItem = this.searchConfigDataService.findById(this.searchConfigDataService.childUsersWithPositions, this.item.label);
      if (foundItem) {
        if (!_.isEmpty(foundItem.childUsers)) {
          foundItem.childUsers.forEach(childUser=>{
            this.childItems.push({
              id: 'filter_menu_Teamusers'+'_'+childUser['childpositionid'],
              label: childUser['userfullname'],
              value: childUser['childpositionid'],
              isChecked: false,
              type: SuggestionPillType.BOOLEAN_FIELD,
              showArrow:true,
              arrowType: 'chevron-down-outline',
              category:'Teamusers'
            });
          });
        }
      }
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  public async handleRightIconCLick(event, specificClickTarget?:string, selected?){
    this.childItems = [];

    this.searchConfigDataService.updateAttributeById(this.searchConfigDataService.childUsersWithPositions, selected.label, this.depth-1,'isExpanded', true)

    if(!selected.isExpanded && selected.value && this.searchConfigDataService.checkIfValidUUID(selected.value)) {
      await this.showLoader();
      const foundItem = this.searchConfigDataService.findById(this.searchConfigDataService.childUsersWithPositions, selected.label);
      if (foundItem) {
        if (!_.isEmpty(foundItem.childUsers)) {
          foundItem.childUsers.forEach(childUser=>{
            this.childItems.push({
              id: 'filter_menu_Teamusers'+'_'+childUser['childpositionid'],
              label: childUser['userfullname'],
              value: childUser['childpositionid'],
              isChecked: false,
              type: SuggestionPillType.BOOLEAN_FIELD,
              showArrow:true,
              arrowType: 'chevron-down-outline',
              category:'Teamusers'
            });
          });
        } else if(!foundItem.wasCalled)  {
          let contactsBasedOnPositionId = await this.contactDataService.getAllChildUsersBasedOnPositionId(selected.value);

          if (!_.isEmpty(contactsBasedOnPositionId)) {
            const childUsers = [];

            contactsBasedOnPositionId.forEach(childUser=>{
              this.childItems.push({
                id: 'filter_menu_Teamusers'+'_'+childUser['childpositionid'],
                label: childUser['userfullname'],
                value: childUser['childpositionid'],
                isChecked: false,
                type: SuggestionPillType.BOOLEAN_FIELD,
                showArrow:true,
                arrowType: 'chevron-down-outline',
                category:'Teamusers'
              });
              childUsers.push({
                childUsers: [],
                childpositionid :childUser['childpositionid'],
                depth : this.depth,
                isExpanded : false,
                isChecked: false,
                wasCalled: false,
                userfullname: childUser['userfullname'],
              });
            });
            this.searchConfigDataService.updateElementById(this.searchConfigDataService.childUsersWithPositions, selected.label, this.depth-1, childUsers);
          }
          else {
            selected.isExpanded = false;
            this.searchConfigDataService.updateAttributeById(this.searchConfigDataService.childUsersWithPositions, selected.label, this.depth-1,'wasCalled',true);
          }
        } else {
          selected.isExpanded = false;
        }
      }
      await this.hideLoader();
    }
  }

  public async showLoader(){
    this.showSpinner = true;
  }

  public async hideLoader(){
    this.showSpinner = false;
  }

  emitToggleItemClick(item,event){
    // this.searchConfigService.onToggleChildItemBehaviorSubject.next(item);
  }
  emitToggleItem(item,event){
    this.searchConfigService.onToggleChildItemBehaviorSubject.next(item);
  }

  public handleLableCLick(event,specificClickTarget?:string){
    if(specificClickTarget && specificClickTarget == 'loadmoreoptions'){
      this.onLoadMoreOptions.emit(true);
    }
  }

  public toggleItem(item, event) {
    if (event && event.detail) {
      this.searchConfigDataService.unCheckAllChildUsersWithPositions();

      if(event.detail.checked)  {
        if (this.viewData && this.viewData.childItems)
        this.viewData.childItems.forEach((child) => {
          if(child.id != item.id) child.isChecked = false;
        })
        if(this.viewData && this.viewData.childItems) {
          const foundIndex = this.viewData.childItems.findIndex((i)=> i.id == item.id);
          if (foundIndex > -1) {
            this.viewData.childItems[foundIndex].isChecked = true;
          }
        }
        item.isChecked = true;

        this.searchConfigDataService.updateAttributeById(this.searchConfigDataService.childUsersWithPositions, item.label, this.depth-1,'isChecked', true);
      } else {
        if(this.viewData && this.viewData.childItems) {
          const foundIndex = this.viewData.childItems.findIndex((i)=> i.id == item.id);
          if (foundIndex > -1) {
            this.viewData.childItems[foundIndex].isChecked = true;
          }
        }
        item.isChecked = false;

        this.searchConfigDataService.updateAttributeById(this.searchConfigDataService.childUsersWithPositions, item.label, this.depth-1,'isChecked', true);
      }
      this.searchConfigService.childUsersWithPositionsViewData = this.viewData;
      this.searchConfigService.isTargetContactsPillRemoved = false;

      if(item.isSingleSelect) this.onToggleSingleItem.emit(item);
      else this.onToggleChildItem.emit(item);
    }
  }

  public handleCheckboxClick(item,event){
    if (event && event.detail) {
      this.onToggleChildItemClick.emit(item);
    }
  }

}
