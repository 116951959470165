<ion-content>
  <ng-container>
    <div *ngIf="isGeneralSectionEnabled">
      <ind-section-header [viewData]="slideModalReplacementHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <ind-form-field [viewData]="getSlideNameFormField()"></ind-form-field>
      <div class="container">
        <div class="box">
          <div class="replacementContainer" [innerHTML]="selectedSlideHtml"></div>
        </div>
      </div>
      <ion-buttons class="slide-buttons" justify-content-center *ngIf="slides && slides.length>0">
        <ion-icon name="chevron-back-outline" class="back-btn" (click)="goBackward()"></ion-icon>
        <div *ngFor="let slide of slides">
          <ion-icon class="ellipse" tappable src="{{ slide.is_default ? 'assets/imgs/ellipse_outline.svg' : 'assets/imgs/ellipse_fill.svg' }}"
            (click)="selectSlide($event, slide)">
          </ion-icon>
        </div>
        <ion-icon name="chevron-forward-outline" class="back-btn" size="small" (click)="goForward()"></ion-icon>
      </ion-buttons>
    </div>    
    
    <div class="previewSection">
      <ind-section-header [viewData]="slideModalPreviewHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <div class="replacementContainer previewContainer" [innerHTML]="selectedSlideHtml"></div>
    </div>
  </ng-container>

</ion-content>