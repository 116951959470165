<!-- Generated template for the CaseManagementDetailComponent component -->

<ion-header>
    <ind-page-title [viewData]="appSettingsDetailsPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content no-padding no-margin>

    <ion-list>
        <ind-section-header [viewData]='diagnosticHeader'></ind-section-header>
        <ion-item>
            <ion-label class="detailsLabel">{{'SETTINGS_SIZE_TO_SEND' | translate }}</ion-label>
            <ion-label class="detailsLabel">{{this.azureService.contentLength}}</ion-label>
        </ion-item>
        <ion-item>
            <appsettings-progress-bar style="width: 100%;" [progress]="this.azureService.progress"></appsettings-progress-bar>
        </ion-item>
        <ion-item>
            <p class="light-color privacy">{{'SETTINGS_DIAGNOSTIC_DATA_SEND_PRIVACY_POLICY' | translate}}
                <a (click)="openPrivacyPopover($event)">{{'PRIVACY_POLICY' | translate}}</a>. {{'SETTINGS_DIAGNOSTIC_DATA_SEND_QUESTION' | translate}}.</p>
            <!-- <ion-textarea rows="10" cols="20" disabled readonly value="You are sending the diagnostic data to Omnipresence inorder for you us to betterp assist you with your issue or an issue which you have been identified.If you would like more information on what data we may collect then please see the privacy policy by <a> clicking or tapping here</a>.If you have any questions, please contact your employer or organization and do not send the diagnostic data."></ion-textarea> -->
        </ion-item>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'SETTINGS_FOOTER_DETAILVIEW' | translate" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->