<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
    <ion-row>
      <ion-col class="opportunity-mgmt-left-pane">
        <account-list [accountListMode]="accountListMode" [accountListAccessedFrom]="'OpportunityManagementPage'"
          (closeModal)="closeAccountModal($event)" (createNewOpportunity)="createNewOpportunity($event)" (selectAccount)="openAccountDetail($event)"></account-list>
      </ion-col>
      <ion-col class="opportunity-mgmt-right-pane">
        <ion-nav #opportunitymanagementrightpane></ion-nav>
      </ion-col>
    </ion-row>
</ion-grid>
  