<ion-header>
    <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<!--PLANS AND PREDICTIONS-->
<!-- <ion-item-divider class="page-header">
  {{ 'XPERIENCES_HEADER_PLANS_AND_PREDICTIONS' | translate}} ({{activities.length}})
</ion-item-divider> -->
<ind-section-header [viewData]='{id:"plants-and-predictions",title: translate.instant("XPERIENCES_HEADER_PLANS_AND_PREDICTIONS") + " (" + activities.length + ")"}'>
</ind-section-header>
<ion-content>
    <ion-list>
        <ng-container *ngFor="let activity of activities | orderBy:'date':false">
            <ion-item class="activity-item">
                <div>
                    <div text-left class="padTop">
                        <img src="assets/imgs/xp_{{activity.type}}.svg" width="20" height="20px" alt="activity-image" />
                        <h3 text-nowrap class="header">{{ activity.description }}</h3>
                    </div>
                    <div text-left class="account-secondary-info">
                        <span text-nowrap class='secondaryTextColorAndfont'>
          <ion-row>
              <span class="date-product"> {{(activity.date | date: 'MMM dd') + ' - ' + (activity.products[0].productname)}}
              </span>
                        </ion-row>
                        <ion-row class="margin">
                            <span class="contacts"> {{activity.contacts.length > 3 ?
                (activity.contacts[0].fullname + ' +' + (activity.contacts.length - 1)) : activity.contacts}}
              </span>
                            <ng-container *ngIf="activity.type !== 'teamplan'">
                                <ion-thumbnail class="right-float strength description">
                                    <img src="assets/imgs/xp_{{activity.strength}}_strength_bar.svg" width="20" />
                                </ion-thumbnail>
                            </ng-container>
                        </ion-row>
                        <ion-row *ngIf="feed.feed_type === 'Topics'" class="marginY">
                            <span>{{feed.product_topic}}</span>
                        </ion-row>
                        </span>
                    </div>
                </div>

            </ion-item>
        </ng-container>
        <!-- <ion-item *ngIf="activities.length > 5">
    <div class="expProd" (click)="openMoreFeeds()">{{ this.translate.instant('AND')+' '+ (activities.length - xperiencesService.limitItems) +' '+this.translate.instant('MORE') }}</div>
  </ion-item> -->
    </ion-list>
    <!--PLANS AND PREDICTIONS-->
    <!--INSIGHTS-->
    <!-- <ion-item-divider class="page-header">
  ALL JOURNEY INSIGHTS ({{insights.length}})
</ion-item-divider> -->
    <ind-section-header [viewData]='{id:"all-journet-insights",title: "ALL JOURNEY INSIGHTS (" + insights.length + ")"}'>
    </ind-section-header>
    <ion-list>
        <ng-container *ngFor="let insight of insights | orderBy:'date':false">
            <ion-item class="insights-ion-item">
                <div>
                    <div text-left class="header-div">
                        <h3 text-nowrap class="header">{{ insight.description }}</h3>
                    </div>
                    <div text-left class="account-secondary-info" [ngClass]="{'native-content': device.isNativeApp}">
                        <span text-nowrap class='secondaryTextColorAndfont'>
          <ion-row>
              <span class="date-product">{{(insight.date | date: 'MMM dd') + ' - ' + (insight.products[0].productname)}}</span>
                        </ion-row>
                        <ion-row>
                            <span class="contacts"> {{insight.contacts.split(',').length > 3 ?
                (insight.contacts.split(',')[0] + ' +'+ (insight.contacts.split(',').length - 1)) : insight.contacts}}
            </span>
                        </ion-row>
                        </span>
                    </div>
                </div>
            </ion-item>
        </ng-container>
        <!-- <ion-item *ngIf="insights.length > 2">
    <div class="expProd" (click)="openMorePlansInsights()">{{ this.translate.instant('AND')+' '+ (insights.length - xperiencesService.limitItems) +' '+this.translate.instant('MORE') }}</div>
  </ion-item> -->
    </ion-list>
    <!--INSIGHTS-->
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar>
<!-- </ion-footer> -->