import { Component, OnInit } from '@angular/core';
import { XperiencesService } from '../../services/xperiences/xperiences.service';
import { DeviceService } from '../../services/device/device.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UIService } from '../../services/ui/ui.service';
import { TrendingAccount } from '../../classes/xperiences/trending.customer.class';
import { FooterViews } from '../../services/footer/footer.service';
import { FooterService } from '../../services/footer/footer.service';
import { IndPageTitleViewDataModel } from '../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

/**
 * Generated class for the TrendingAccountsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'trending-accounts',
  templateUrl: 'trending-accounts.html',
  styleUrls:['trending-accounts.scss']
})
export class TrendingAccountsComponent implements OnInit{

  text: string;
  account: TrendingAccount;
  activities: any;
  pageTitle: IndPageTitleViewDataModel;
  constructor(private xperienceService: XperiencesService,
              private device: DeviceService,
              private navService: NavigationService,
              private uiService: UIService,
              public footerService: FooterService,
              public translate: TranslateService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
        this.account = this.xperienceService.selectedTrendingAccount;
        this.activities = [...this.account.plans, ...this.account.predictions];
        this.footerService.initButtons(FooterViews.Contacts);
        this.pageTitle = {
          id: 'xp-accounts-page-title',
          title: this.account.accountName,
          controls: []
        };
  }

  async onPageTitleControlClick(id: string) {
    if(id === 'close') {
      await this.navService.popChildNavPageWithPageTracking();
      this.uiService.showRightPane = false;
    }
  }

  getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, 'MMM dd', undefined, this.translate.currentLang);
  }

  getLocalisedPlanDate(dateString: string){
    return this.getFormattedAndLocalisedDate(new Date(parseInt(dateString)));
  }

}
