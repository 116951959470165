export enum AttendeeFormat {
  'propose' = 548910000,
  'open' = 548910001
}

export enum EventRegistrationStatus {
  'Proposed' = 451680000,
  'Invited' = 451680001,
  'Accepted' = 451680002,
  'Cancelled' = 451680003,
  'Cancelled late' = 451680004,
  'No show' = 451680005,
  'Checked-in' = 548910002,
  'Declined' = 548910001,
  'Draft' = 548910003,
  'Rejected' = 548910000
}

export enum EventParticipantStatusCode {
  'DRAFT' = 1,
  'REJECTED' = 2,
  'FOR_REVIEW' = 548910000,
  'APPROVED' = 548910001
}

export enum EventParticipantApprovalStatusCode {
  'DRAFT' = 548910000,
  'CANCELLED' = 548910003,
  'FOR_REVIEW' = 548910001,
  'APPROVED' = 548910002
}

export enum EventModeOfCheckIns {
  'Manual' = 548910000,
  'Signature' = 548910001,
  'QR Code' = 548910002,
}

export enum EventStatus {
  'DRAFT' = 1,
  'FOR_REVIEW' = 548910004,
  'APPROVED' = 548910007,
  'REJECTED' = 548910003,
  'COMPLETED' = 548910005,
  'CANCELED' = 548910006,
}

export enum EventStateCode {
  'Active' = 0,
  'Inactive' = 1,
}

export enum EventApprovalStatus {
  'Canceled' = 548910003,
  'Draft' = 548910000,
  'For Review' = 548910001,
  'Approved' = 548910002,
}
