<ion-header>
    <ind-page-title [viewData]="titleBar" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ind-section-header [viewData]='activityDetailsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ind-form-field *ngFor="let field of activityFormFields" [viewData]='field'></ind-form-field>
    <ind-section-header [viewData]='previewHeader'></ind-section-header>
    <ion-item #preview>
    </ion-item>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" (dislikeActivity)="dislikeActivity()" (likeActivity)="likeActivity()"></footer-toolbar>
<!-- </ion-footer> -->