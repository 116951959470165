import { IndPageTitleViewDataModel } from './../../models/indPageTitleDataModel';
import { Component, ViewChild, AfterViewInit, ElementRef, OnDestroy, Input } from '@angular/core';
import {TrendingCustomer, XpCustomer} from '../../classes/xperiences/trending.customer.class';
import { RepServices } from '../../data-services/rep/rep.services';
import { DeviceService } from '../../services/device/device.service';
import { UIService } from '../../services/ui/ui.service';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../services/logging/tracking.service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { Contact } from '../../classes/contact/contact.class';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { XperiencesService } from '../../services/xperiences/xperiences.service';
import { CustomerInsightComponent } from '../contact/customer-insight/customer-insight';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { IndTabsDataModel } from '../../models/ind-tabs-data-model';
import { InsightsPlusComponent } from '../inisghts-plus/insights-plus';
import { IndTabsComponent } from '../shared/ind-tabs/ind-tabs';
import { FooterToolbarComponent } from '../footer-toolbar/footer-toolbar';
import { XperiencePlanInsightComponent } from '../contact/xperience-plan-insight/xperience-plan-insight';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Generated class for the CustomerXperiencesComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'customer-xperiences',
  templateUrl: 'customer-xperiences.html',
  styleUrls:['customer-xperiences.scss']
})
export class CustomerXperiencesComponent implements AfterViewInit, OnDestroy {
  // public customer: TrendingCustomer;
  public xpCustomer: XpCustomer;
  @ViewChild('Content', {static:true}) content: HTMLIonContentElement;
  @ViewChild('Header', {read: ElementRef, static: true}) tabsComponent: ElementRef;
  @ViewChild(FooterToolbarComponent, {read: ElementRef, static: true} ) footerToolbarComponent: ElementRef;
  @ViewChild(XperiencePlanInsightComponent,  {read: ElementRef, static: true} ) xperiencePlanInsightComponent: ElementRef;

  tabsData: IndTabsDataModel[] = [
    {
      displayText: 'XPERIENCES_JOURNEY',
      value: 'journeys',
    },
    {
      displayText: 'XPERIENCES_INTERESTS',
      value: 'interests',
    },
    {
      displayText: 'XPERIENCES_RELATIONSHIPS',
      value: 'relationships',
    }
  ];
  screenOrientaion$;
  headerSection: HTMLCollectionOf<Element>;
  @Input() customer:any
  xperienceDetailPageHeaderModel: IndPageTitleViewDataModel;
  private ngDestroy$ = new Subject<boolean>();

  constructor(
    public repService: RepServices,
    public device: DeviceService,
    private uiService: UIService,
    public footerService: FooterService,
    private contactService: ContactOfflineService,
    private trackingService: TrackService,
    private navService: NavigationService,
    public translate: TranslateService,
    public xperiencesService: XperiencesService) {
  }

  ngOnInit() {
    // this.customer = (this.navParams.data && this.navParams.data.customer);
    this.xpCustomer = this.customer;
    const contact: Contact = this.contactService.getContactByID(this.xpCustomer.contactid);
    if (contact) this.contactService.contactInformation = contact;
    this.xperiencesService.contactDetailsSegment = "journeys";
    // this.footerservice.initButtons(FooterViews.Contacts);
    this.initPageTitle();
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.xperienceDetailPageHeaderModel.controls[0].isDisabled = data;
  });
  }

  ngAfterViewInit() {
    this.screenOrientaion$ = this.device.screenOrientation.subscribe((orientation) => {
      if(orientation) {
        this.addMinimumHeightToInsigthsSection();
      }
    });
    this.addMinimumHeightToInsigthsSection();
  }

  private initPageTitle(): void {
    this.xperienceDetailPageHeaderModel = {
      id: 'xperiences-customer',
      title: this.xpCustomer.fullname,
      controls: [{
        id: 'insight',
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('XPERIENCES_INSIGHT'),
        isDisabled: this.repService.isOfflineState,
        align: 'right',
      }]
    };
  }
  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closepage();
        break;
      case 'insight':
        this.createInsight();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  closepage() {
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    this.xperiencesService.selectedXpContact = null;
    this.xperiencesService.resetFilters();
    this.uiService.showRightPane = false;
  }

  public createInsight() {
    this.trackingService.tracking('XperiencesInsights+', TrackingEventNames.INSIGHTSPLUS);
    this.xperiencesService.enableInsight = false;
    this.uiService.showRightPane = true;
    let newInsight: TrendingCustomer = new TrendingCustomer();
    newInsight.contactid = this.xpCustomer.contactid;
    newInsight.fullname = this.xpCustomer.fullname;
    newInsight.journeys = [];
    newInsight.interests = [];
    newInsight.relationships = [];
    this.xperiencesService.newInsight = newInsight;
    this.xperiencesService.isFromCustomerXperience = true;
    this.navService.pushChildNavPageWithPageTracking(CustomerInsightComponent, PageName.CustomerInsightComponent, PageName.ActivitiesPageComponent);
  }

    // adding minimum height to make the filter position consistent if the insights data changes
    addMinimumHeightToInsigthsSection() {
      setTimeout(() => {
        this.headerSection = ((this.xperiencePlanInsightComponent.nativeElement as HTMLElement).getElementsByClassName('insights-section') as HTMLCollectionOf<Element>);
        const minHeight = ((this.footerToolbarComponent.nativeElement as HTMLElement).getBoundingClientRect().top -
        (this.tabsComponent.nativeElement as HTMLElement).getBoundingClientRect().bottom).toString() + 'px !important';
        // this.headerSection[0].setAttribute("style",`min-height: ${minHeight}`)
      })
    }

    ngOnDestroy() {
      this.screenOrientaion$.unsubscribe();
      this.ngDestroy$.next(true);
      this.ngDestroy$.complete();
    }
}
