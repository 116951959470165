import { IAllocationAdjustment } from './../interfaces/allocation/allocation-adjustment.interface';
import { IAllocationTransfer } from './../interfaces/allocation/allocation-transfer.interface';
import { IAllocationShipment, IAllocationShipmentAndTransferBase } from './../interfaces/allocation/allocation-shipment.interface';
import { Pipe, PipeTransform } from '@angular/core';
import { AllocationShipmentService } from '../services/sample/allocation-shipment.service';
import { AllocationAdjustService } from '@omni/services/sample/allocation-adjust.service';
import { filter } from 'lodash';
import { AdjustmentStatus, ShipmentStatus, TransferType } from '../enums/allocation/allocation.enum';

@Pipe({
  name: 'allocationShipmentFilterPipe',
  pure: true
})
export class AllocationShipmentFilterPipe implements PipeTransform {

  constructor(
      private shipmentService: AllocationShipmentService,
  ) {}

  /**
   *  This is the pipe for filtering Allocation Shipments
   *
   * @returns
   * @memberof AllocationShipmentFilter
   */
  transform(items: Array<IAllocationShipment | IAllocationTransfer | IAllocationAdjustment>) {
    if (this.shipmentService.showAllShipmentsFilter$.value) {
      return items;
    }
    if (this.shipmentService.shipmentsSKUFilter) {
      items = items.filter(shipment =>
        shipment.indskr_skuid === this.shipmentService.shipmentsSKUFilter.skuId
        || shipment.at_indskr_skuid === this.shipmentService.shipmentsSKUFilter.skuId
      );
    }
    if (this.shipmentService.shipmentsStatusFilter) {
      if (this.shipmentService.shipmentsStatusFilter == AdjustmentStatus.Open) {
        items = items.filter(shipment =>
          shipment.indskr_shipmentstatus === ShipmentStatus.Draft
          || shipment.indskr_adjustedstatus === this.shipmentService.shipmentsStatusFilter
        );
      } else if (this.shipmentService.shipmentsStatusFilter == AdjustmentStatus.InReview) {
        items = items.filter(shipment =>
          shipment.indskr_shipmentstatus === ShipmentStatus.ForReview
          || shipment.indskr_adjustedstatus === this.shipmentService.shipmentsStatusFilter
        );
      } else if (this.shipmentService.shipmentsStatusFilter == ShipmentStatus.NotApproved) {
        items = items.filter(shipment =>
          shipment.indskr_shipmentstatus === ShipmentStatus.NotApproved
        );
      } else {
        items = items.filter(shipment =>
          shipment.indskr_shipmentstatus === this.shipmentService.shipmentsStatusFilter
          || shipment.indskr_adjustedstatus === this.shipmentService.shipmentsStatusFilter
        );
      }
    }
    if (this.shipmentService.transferTypeFilter) {
      items = items.filter(shipment =>
        (!shipment.indskr_transfertype && this.shipmentService.transferTypeFilter === TransferType.AllocationShipment)
        || shipment.indskr_transfertype === this.shipmentService.transferTypeFilter
      );
    }
    return items;
  }

  search(items: Array<IAllocationShipment | IAllocationTransfer | IAllocationAdjustment>, searchTxt: string) {
    return filter(items, (item) => {
      const re = new RegExp('(' + searchTxt + ')+', 'gi');
      return re.test(item.indskr_name);
    });
  }

  orderByShipmentOrAdjustmentDate(items: Array<IAllocationShipment | IAllocationTransfer | IAllocationAdjustment>, asc: boolean = false) {
    items = items.sort((a, b) => {
      const propA = (a as IAllocationShipmentAndTransferBase).indskr_shipmentdate || (a as IAllocationAdjustment).indskr_adjustmentdate;
      const propB = (b as IAllocationShipmentAndTransferBase).indskr_shipmentdate || (b as IAllocationAdjustment).indskr_adjustmentdate;

      if (!asc) {
        return propB < propA ? -1 : 1;
      } else {
        return propA < propB ? -1 : 1;
      }
    });
    return items;
  }
}

@Pipe({
  name: 'allocationAdjustmentFilter',
  pure: true
})
export class AllocationAdjustmentFilter implements PipeTransform {

    constructor(
        private shipmentService: AllocationShipmentService,
        private adjustmentService: AllocationAdjustService
    ) {}


    /**
     *  This is the pipe for filtering Allocation Shipments
     *
     * @returns
     * @memberof AllocationAdjustjmentFilter
     */
    transform(items: Array<IAllocationAdjustment>){

        if(this.adjustmentService.showAllAdjustmentsFilter$.value){
            return items;
        }
        if(this.adjustmentService.adjustmentSKUFilter){
            items = items.filter(shipment => {
              const skuId = shipment.at_indskr_skuid || shipment.indskr_skuid;
              return skuId === this.adjustmentService.adjustmentSKUFilter.skuId
            });
        }
        if(this.adjustmentService.adjustmentStatusFilter){
            items = items.filter(shipment => shipment.indskr_adjustedstatus === this.adjustmentService.adjustmentStatusFilter);
        }

        return items;
    }

    search(items: Array<IAllocationAdjustment>, searchTxt: string) {
        return filter(items, (item) => {
            const re = new RegExp('(' + searchTxt + ')+', 'gi');
            return re.test(item.indskr_name);
        })
    }
}
