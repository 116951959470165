import { AlertService } from './../../../services/alert/alert.service';
import { EventsService } from '@omni/services/events/events.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { LoadingController } from '@ionic/angular';
import { AuthenticationService } from '@omni/services/authentication.service';
import { isBefore } from 'date-fns';
import { UIService } from '@omni/services/ui/ui.service';
import { NotificationService } from '@omni/services/notification/notification.service';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { Subscription } from 'rxjs';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DatePipe } from '@angular/common';
import { AssetTransfer, AssetTrasferStatus } from './../../../classes/field-materials/asset-transfer.class';
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerAsset } from "@omni/classes/field-materials/customer-asset.class";
import { IONote } from "@omni/classes/io/io-note.class";
import { IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { DeviceService } from "@omni/services/device/device.service";
import { FieldMaterialManagementService } from "@omni/services/field-materials/field-material-management.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import _ from 'lodash';

@Component({
  selector: 'asset-transfer-details',
  templateUrl: 'asset-transfer-details.html',
  styleUrls: ['asset-transfer-details.scss']
})
export class AssetTransferDetailsComponent {

  public titleBar: IndPageTitleViewDataModel;
  @Input() public transfer: AssetTransfer;

  public transferIdFormField: IndFormFieldViewDataModel;
  public transferStatusFormField: IndFormFieldViewDataModel;
  public assetStatusFormField: IndFormFieldViewDataModel;
  public assetOwnerFormField: IndFormFieldViewDataModel;
  public assetCategoryFormField: IndFormFieldViewDataModel;
  public currentAccountFormField: IndFormFieldViewDataModel;
  public createdOnFormField: IndFormFieldViewDataModel;

  public transferDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public newAccountFormField: IndFormFieldViewDataModel;
  public newOwnerFormField: IndFormFieldViewDataModel;
  public startDateFormField: IndFormFieldViewDataModel;
  public endDateFormField: IndFormFieldViewDataModel;

  public notesSectionHeaderViewData: IndSectionHeaderViewDataModel;
  @ViewChild('attachInput') attachInput: ElementRef;
  public notesPlaceholder: string = '';
  public attachmentTitle: string = '';
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;
  public transferNotes: Array<IONote> = [];
  public tempNoteText: string = '';
  public formEditable: boolean = false;
  public proofConfirmation: boolean = false;

  private dateFormatSubscription: Subscription;

  constructor(
    public device: DeviceService,
    public fieldMaterialManagementService: FieldMaterialManagementService,
    public footerService: FooterService,
    private navService: NavigationService,
    private datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
    public translate: TranslateService,
    private notificationService: NotificationService,
    private uiService: UIService,
    private authService: AuthenticationService,
    private loadingCtrl: LoadingController,
    private events: EventsService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit() {
    this.transfer.indskr_customerconfirmation = 0;
    if (this.transfer.statuscode === AssetTrasferStatus.Completed) this.proofConfirmation = true;
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe(df => {
      if (df) {
        this._initDateTimeFormFields();
      }
    });
    this._initPageTitle();
    this._initTransferNotes();
    this._initFormFields();
  }

  private _initTransferNotes() {
    this.transferNotes = [];
    if (!_.isEmpty(this.transfer.notes)) {
      this.transferNotes = this.transfer.notes.map(a => (new IONote(a)));
    }
    this._sortCustomerNotes();
  }

  private _initPageTitle() {
    this.formEditable = false;
    if (this.transfer.statuscode === 548910000 && !this.device.isOffline) {
      if (this.transfer.isLocationChange) {
        this.formEditable = true;
      } else {
        if (this.authService.user.systemUserID == this.transfer.new_asset_owner) this.formEditable = true;
      }
    }
    let controls = [];
    if (this.transfer.isLocationChange) {
      controls.push({
        id: 'transfer-cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.device.isOffline || (this.transfer.statuscode !== AssetTrasferStatus.Initiated && this.transfer.statuscode !== AssetTrasferStatus.Approved),
        align: 'right'
      });
      controls.push({
        id: 'transfer-complete',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        isDisabled: !this.formEditable || this.transfer.indskr_customerconfirmation === 0 || _.isEmpty(this.transfer.notes) || !this.transfer.notes.some(n => n.isdocument),
        align: 'right'
      });
    } else {
      if (this.authService.user.systemUserID == this.transfer.current_asset_owner) {
        const disabled = this.transfer.isLocationChange ? (this.transfer.statuscode != AssetTrasferStatus.Initiated && this.transfer.statuscode != AssetTrasferStatus.Approved) : this.transfer.statuscode != AssetTrasferStatus.Initiated;
        controls.push({
          id: 'transfer-cancel',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: this.device.isOffline || disabled,
          align: 'right'
        });
      } else {
        controls.push({
          id: 'transfer-reject',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('REJECT'),
          isDisabled: !this.formEditable,
          align: 'right'
        });
        controls.push({
          id: 'transfer-acknowledge',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('ACKNOWLEDGE'),
          isDisabled: !this.formEditable,
          align: 'right'
        });
      }
    }
    this.titleBar = {
      id: 'asset-details',
      title: this.transfer.indskr_assetname,
      controls: controls
    };
    this.footerService.initButtons(FooterViews.ASSET_TRANSFER_DETAILS);
  }

  private _initDateTimeFormFields() {
    this._getCreatedOnFormField();
    if (this.transfer.isLocationChange) {
      this._getStartDateFormField();
      this._getEndDateFormField();
    }
  };

  private _initFormFields() {
    this._getTransferIdFormField();
    this._getTransferStatusFormField();
    this._getAssetStatusFormField();
    this._getCurrentAccountFormField();
    this._getAssetCategoryFormField();
    this._getAssetOwnerFormField();
    this._initDateTimeFormFields();
    this.transferDetailsHeaderModel = { id: 'transfer-details-header', title: this.translate.instant('TRANSFER_DETAILS'), controls: [] };
    this.transfer.isLocationChange ? this._getNewAccountFormField() : this._getNewOwnerFormField();
    this._initCustomerNotesViewData();
  }

  private _getNewAccountFormField() {
    this.newAccountFormField = {
      label: this.translate.instant('NEW_ASSET_LOCATION'),
      inputText: this.transfer.new_asset_account_name,
      id: 'new-asset-location-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getNewOwnerFormField() {
    this.newOwnerFormField = {
      label: this.translate.instant('NEW_ASSET_OWNER'),
      inputText: this.transfer.new_asset_owner_name,
      id: 'new-asset-owner-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getStartDateFormField() {
    this.startDateFormField = {
      label: this.translate.instant('NEW_ASSET_START_DATE'),
      inputText: this._getFormattedDateTime(this.transfer.new_location_startdate),
      id: 'start-date-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getEndDateFormField() {
    this.endDateFormField = {
      label: this.translate.instant('NEW_ASSET_END_DATE'),
      inputText: this._getFormattedDateTime(this.transfer.new_location_enddate),
      id: 'end-date-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getAssetCategoryFormField() {
    this.assetCategoryFormField = {
      label: this.translate.instant('CATEGORY'),
      inputText: this.transfer.asset_category_Formatted,
      id: 'asset-category-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  public _getCreatedOnFormField() {
    this.createdOnFormField = {
      label: this.translate.instant('CREATED_DATE'),
      inputText: this._getFormattedDateTime(this.transfer.createdon),
      id: 'created-date-field',
      isReadOnly: true,
      isDisabled: true,
    };;
  }

  private _getAssetOwnerFormField() {
    this.assetOwnerFormField = {
      label: this.translate.instant('ASSET_OWNER'),
      inputText: this.transfer.current_asset_owner_name,
      id: 'asset-owner-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getCurrentAccountFormField() {
    this.currentAccountFormField = {
      label: this.translate.instant('ACCOUNT'),
      inputText: _.isEmpty(this.transfer.current_asset_account_name) ? this.translate.instant('NO_ACCOUNT') : this.transfer.current_asset_account_name,
      id: 'asset-account-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getAssetStatusFormField() {
    this.assetStatusFormField = {
      label: this.translate.instant('STATUS'),
      inputText: this.transfer.asset_status_Formatted,
      id: 'asset-status-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getTransferIdFormField() {
    this.transferIdFormField = {
      label: this.translate.instant('TRANSFER_ID'),
      inputText: this.transfer.indskr_name,
      id: 'request-id-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getTransferStatusFormField() {
    this.transferStatusFormField = {
      label: this.translate.instant('TRANSFER_STATUS'),
      inputText: this.transfer.statuscode_Formatted,
      id: 'request-id-field',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private _getFormattedDateTime(value, timeRequired?: boolean): string {
    if (_.isEmpty(value)) return '';
    const day = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    if (!timeRequired) return day;
    const time = (new Date(_.toNumber(value))).toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return day + " " + time;
  }

  private _initCustomerNotesViewData() {
    const isCompletedOrApprovedLoanerAsset =  this.transfer.asset_category === 548910002 && (this.transfer.statuscode === AssetTrasferStatus.Approved || this.transfer.statuscode === AssetTrasferStatus.Completed);
    this.notesSectionHeaderViewData = {
      id: 'asset_remarks_section_header',
      title: this.translate.instant('CUSTOMER_CONFIRMATION_NOTES'),
      controls: [{
        id: 'attach_note',
        text: this.translate.instant('ATTACH'),
        isDisabled: this.transfer.isLocationChange && isCompletedOrApprovedLoanerAsset ? false : !this.formEditable  
      },
      {
        id: 'save_note',
        text: this.translate.instant('SAVE'),
        isDisabled: (_.isEmpty(this.tempNoteText) && !this.isAttachmentAdded) || (this.transfer.isLocationChange && isCompletedOrApprovedLoanerAsset  ? false :  !this.formEditable),
      }]
    }
    this.notesPlaceholder = this.translate.instant('ENTER_NOTES');
  }

  public async handleButtonClick(id: string) {
    switch (id) {
      case 'save_note':
        this.handleSaveNote();
        break;
      case 'close':
        await this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.FieldMaterialManagementPageComponent);
        this.navService.setChildNavRightPaneView(false);
        this.fieldMaterialManagementService.selectedAssetTransfer = null;
        break;
      case 'transfer-acknowledge':
        this.updateStatus({ transferStatusCode: 548910002, dateAcknowledged: 1647612685786 });
        break;
      case 'transfer-reject':
        this.updateStatus({ transferStatusCode: 548910001 });
        break;
      case 'transfer-complete':
        this.updateStatus({ transferStatusCode: 548910002 });
        if (this.transfer.isLocationChange) {
          this.events.publish('assetTransferCompleted', {assetid: this.transfer.asset_id, accountName: this.transfer.new_asset_account_name, accountId: this.transfer.new_asset_account});
        }
        break;
      case 'transfer-cancel':
        this.cancelAssetTransfer();
        break;
      case 'attach_note':
        this._handleNoteAttachment();
        break;
      default:
        console.log('Unhandled switch case statement', id);
        break;
    }
  }

  private async updateStatus(payload) {
    this.uiService.displayLoader();
    await this.fieldMaterialManagementService.updateAssetTrasferStatus(payload, this.transfer.indskr_assettransferid).then(res => {
      if (res) {
        this.transfer = res;
        this._initFormFields();
        this._initPageTitle();
        this._initCustomerNotesViewData();
      }
      this.uiService.dismissLoader();
    }).catch(err => {
      console.log("error occurred ", err)
      this.uiService.dismissLoader();
    });
  }

  private async cancelAssetTransfer() {
    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_ASSET_TRANSFER_TITLE'),
      message: this.translate.instant('SCRAP_ASSET_TRANSFER_MSG', {assetName: this.transfer.indskr_assetname})
    }, this.translate.instant('SCRAP')
    ).then (async res => {
      if(res.role === 'ok') {
        this.uiService.displayLoader();
        await this.fieldMaterialManagementService.cancelAssetTransfer(this.transfer.indskr_assettransferid).then(res => {
          if (res) {
            this.events.publish('assetTransferCancelled', res);
          }
          this.uiService.dismissLoader();
        }).catch(err => {
          console.log("error occurred ", err)
          this.uiService.dismissLoader();
        });
      }
    });
    
  }

  private _handleNoteAttachment() {
    try {
      this.attachInput.nativeElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  public removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    this._initCustomerNotesViewData();
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        const file = event.target.files[0];
        if ((file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this._initCustomerNotesViewData();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Account Info', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Account Info', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  public notesChanged(ev): void {
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
    } else {
      this.tempNoteText = '';
    }
    this._initCustomerNotesViewData();
  }

  public async handleSaveNote() {
    if (!_.isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      const loader = await this.loadingCtrl.create();
      loader.present();
      const now = new Date().getTime().toString();
      const offlinePayload = {
        annotationid: 'offlineassettransfernote' + now,
        createdon: now,
        activityid: this.transfer.indskr_assettransferid,
        notetext: this.tempNoteText,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
        deleted: false,
        pendingPushOnDynamics: true,
      };

      if (this.base64str) {
        offlinePayload['isdocument'] = true;
        offlinePayload['documentbody'] = this.base64str;
        offlinePayload['filename'] = this.attachmentFile.name;
        offlinePayload['filesize'] = this.attachmentFile.size;
        offlinePayload['mimetype'] = this.attachmentFile.type;
      }

      let note = new IONote(offlinePayload);
      if (!this.device.isOffline) {
        const payload = [{
          assetTransferId: note.activityId,
          createdon: note.createdTime.getTime(),
          notetext: note.noteText,
          ownerid: note.ownerId,
          filename: note.documentName,
          filesize: note.documentSize,
          documentbody: note.documentDataURL,
          mimetype: note.documentMimeType
        }];
        await this.fieldMaterialManagementService.uploadTransferNotesOnline(payload).then(res => {
          if (res) this.transfer.notes = res;
          this._initTransferNotes();
          this._initPageTitle();
          this._initCustomerNotesViewData();
          loader.dismiss();
          this.tempNoteText = '';
          this.removeAttachment(null);
        });
      }
    }
  }

  private _sortCustomerNotes() {
    if (!_.isEmpty(this.transferNotes)) {
      this.transferNotes = this.transferNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      console.log(ev.action);
      const loader = await this.loadingCtrl.create();
      loader.present();
      let idx = this.transfer.notes.findIndex(note => note.annotationid == ev.noteId);
      let payload;
      let deleted = false;
      if (ev.action == 'DELETE' && idx >= 0) {
        deleted = true;
        payload = { "deleted": true, "noteid": ev.noteId };
      } else if (ev.action == 'SAVE' && idx >= 0) {
        let note = { noteid: ev.noteId, notetext: ev.updatedText };
        if (ev.attachmentFileUpdated) {
          note['isdocument'] = true;
          note['filename'] = ev.documentName;
          note['filesize'] = ev.documentSize;
          note['mimetype'] = ev.documentMimeType;
          note['documentbody'] = ev.attachmentFileDataUrl;
        }
        payload = note;
      }
      await this.fieldMaterialManagementService.editAssetTransferNotesOnline(payload, deleted, this.transfer.indskr_assettransferid).then(res => {
        if (res) this.transfer.notes = res;
        this._initTransferNotes();
        this._initPageTitle();
        this._initCustomerNotesViewData();
        loader.dismiss();
        this.tempNoteText = '';
        this.removeAttachment(null);
      }).catch(err => {
        loader.dismiss();
        console.log(err);
      });
    }
  }

  updateProofConfirmation(ev) {
    if (this.formEditable) {
      this.proofConfirmation = !this.proofConfirmation;
      this.transfer.indskr_customerconfirmation = this.proofConfirmation ? 1 : 0;
    }
    this._initPageTitle();
  }

  public isNoteEditable(note: IONote): boolean {
    const isCompletedOrApprovedLoanerAsset =  this.transfer.asset_category === 548910002 && (this.transfer.statuscode === AssetTrasferStatus.Approved || this.transfer.statuscode === AssetTrasferStatus.Completed);
    return ((this.transfer.isLocationChange && isCompletedOrApprovedLoanerAsset) || this.formEditable) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }

  ngOnDestroy() {
    this.dateFormatSubscription.unsubscribe();
  }

}
