<!--PLANS AND PREDICTIONS-->
<ion-item-divider class="list-header" [ngClass]="{'list-header-ios' : device.deviceFlags.ios}" color="light">
    <ion-button class="xpHeadingText padTop5" icon-only fill="clear" (click)='openPlanInsightFilterPopup("plans")'>
        <span class="xpFilterStyleHeading"> {{ getTitle(xperiencesService.xpPlanFilterText)  | capitalizeFirstLetter}}</span>
        <span class="filter-count">({{planItemsCount}})</span>
        <ion-icon class="filterIcon" name="caret-down-outline"></ion-icon>
    </ion-button>
</ion-item-divider>
<div [ngClass]="{ 'plans-section' : planItemsCount === 0 }">
    <ion-item-sliding #item *ngFor="let plan of plans | slice:0:xperiencesService.limitItems">
        <ion-item (click)="displayActivityDetails(plan)" [ngClass]="{'feedback-item': plan.type === 'prediction', 'event-item': plan.activitytypecode === 'event' && plan.event?.isExpanded }">
            <!-- <ion-thumbnail class="left-float"><img src="assets/imgs/xp_{{plan.type}}.svg" width="20"/></ion-thumbnail>
      <ion-icon  [name]="plan.event?.isExpanded ? 'arrow-up': 'arrow-down'" *ngIf="plan.activitytypecode === 'event'" slot="end" (click)="expandEventDetails($event,plan.event)"></ion-icon> -->
            <div>
                <div text-left class="padTop">
                    <img src="assets/imgs/xp_{{plan.type}}.svg" width="20" height="20px" />
                    <ion-icon [name]="plan.event?.isExpanded ? 'arrow-up': 'arrow-down'" *ngIf="plan.activitytypecode === 'event'" slot="end" (click)="expandEventDetails($event,plan.event)"></ion-icon>
                    <h3 text-nowrap class="header">{{ plan.translatedTitle }}</h3>
                </div>
                <div text-left class="marginTop5">
                    <span text-nowrap class='secondaryTextColorAndfont'>
                <ion-row class="marginX">
                    <span class="accountNameText header">{{getLocalisedPlanDate(plan.date) +  (plan.productsText ? (' - ' + plan.productsText) : '')}}</span>
                    <ng-container *ngIf="plan.strength">
                        <ion-thumbnail *ngIf="plan.type !== 'teamplan'" class="left-float strength">
                            <img src="assets/imgs/xp_{{plan.strength}}_strength_bar.svg" width="20" />
                        </ion-thumbnail>
                    </ng-container>
                    </ion-row>
                    <ion-row class="caption-text" *ngIf="plan.captiontext && plan.captiontext.length > 0">
                        <span class="marginY" *ngIf="plan.captiontext && plan.captiontext.length > 0">{{plan.captiontext}}</span>
                        <span class="marginY" *ngIf="plan.relationshipContactText && plan.captiontext">
                     {{ (plan.captiontext.length > 0 ? ' | ' : '') + plan.relationshipContactText}}
                  </span>
                    </ion-row>
                    <ion-row class="caption-text" *ngIf="plan.type === 'teamplan' && plan.topic && plan.topic.length > 0">
                        <span class="marginY">{{plan.topic}}</span>
                    </ion-row>
                    <ion-row class="caption-text" *ngIf="plan.contacts && plan.contacts.length > 0">
                        <span class="marginY">{{plan.contactLabel}}</span>
                    </ion-row>
                    <ion-row class="caption-text" *ngIf="feedTopic">
                        <span class="marginY">{{feedTopic}}</span>
                    </ion-row>
                    </span>
                </div>
            </div>
        </ion-item>
        <ion-item class="event-details" item-content *ngIf="plan.activitytypecode === 'event' && plan.event?.isExpanded">
            <ng-container *ngTemplateOutlet="eventDetails;context: {eventData: plan.event}"></ng-container>
        </ion-item>
        <ion-item-options *ngIf="((plan.type === 'prediction') || (plan.type === 'centralplan'))" side="end">
            <ion-button (click)="sendPlanFeedback(plan, 'positive')" [ngClass]="{'feedback-done' : plan.feedback_Flag || device.isOffline}">
                <ion-icon src="assets/imgs/feedback_thumbs_up{{(plan.feedback_Flag && (plan.feedback_Flag === 'positive')) ? '_active' : ''}}.svg"></ion-icon>
            </ion-button>
            <ion-button (click)="sendPlanFeedback(plan, 'negative')" [ngClass]="{'feedback-done' : plan.feedback_Flag || device.isOffline}">
                <ion-icon src="assets/imgs/feedback_thumbs_down{{(plan.feedback_Flag && (plan.feedback_Flag === 'negative')) ? '_active' : ''}}.svg"></ion-icon>
            </ion-button>
        </ion-item-options>
    </ion-item-sliding>
    <ion-item *ngIf="plans.length > 5">
        <div class="expProd" (click)="openMorePlansInsights()">{{ this.translate.instant('AND')+' '+ (plans.length - xperiencesService.limitItems) +' '+this.translate.instant('MORE') }}</div>
    </ion-item>
</div>

<ng-template #eventDetails let-event="eventData">
    <ion-row class="row event-name">
        <h3>{{event.eventName}}</h3>
    </ion-row>
    <ion-row class="row">
        <h4 class="event-info">
            <span class="ellipsis event-start-date">
        {{'ON' | translate | titlecase }} {{event.eventStartDate | customDate:'DATE_TIME'}}
      </span>
            <span class="ellipsis">
        {{ 'CONTACT_REGISTERED_BY_OWNER' | translate : {text : event.registeredBy} }}
      </span>
        </h4>
    </ion-row>
    <expandable [viewType]="'eventDetails'" [customerEvent]="{ event:event }" [expandHeight]="'auto'" [expanded]="true">
    </expandable>
</ng-template>