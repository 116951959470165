<ion-header>
  <ion-toolbar><label>{{'FILTER' | translate}}</label></ion-toolbar>
</ion-header>
<ion-content>
  <div class="fields-contatiner">
    <div class="sc-field" *ngFor="let parentTag of tags">
      <div class="field-label" (click)="selectParentTag($event, parentTag)">
        <label>{{parentTag.label}}</label>
      </div>
      <div class="field-value"  *ngFor="let childTag of parentTag.tags" [ngClass]="{'selected': childTag.selected}" (click)="selectChildTag($event, parentTag, childTag)">
        <label>{{childTag.label}}</label>
      </div>
    </div>
  </div>
  <div class="action-bar">
    <ion-button slot="end" (click)="closeModal('confirm')" [disabled]="confirmDisabled">
      <ion-icon class="submit-icon" src="assets/imgs/filter-white-horz.svg" alt="Confirm"></ion-icon> 
      {{'CONFIRM' | translate}}
    </ion-button>
    <ion-button class="cncl-btn" (click)="closeModal('reset')" fill="clear" slot="end" [disabled]="resetDisabled">
      <ion-icon class="cncl-icon" src="assets/imgs/short_call_cancel.svg" alt="Close"></ion-icon> 
      {{'RESET' | translate}}
    </ion-button>
  </div>
</ion-content>