import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { FooterService } from '../../../services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { DeviceService } from '../../../services/device/device.service';

/**
 * Generated class for the MarketScanPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'market-scan-page[base-page]',
  templateUrl: 'market-scan-page.html',
  styleUrls:['market-scan-page.scss']
})
export class MarketScanPageComponent {

  @ViewChild('marketscanrightpane', { static: true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    private footerService: FooterService,
    private deviceService: DeviceService,
  ) { }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.MarketScanNavigation, PageName.MarketScanPageComponent, false, {
      'deviceString': this.deviceService.deviceFlags.ios ? 'ios' : 'other'
    });
    this.footerService.initButtons('');
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.MarketScanNavigation);
  }

}
