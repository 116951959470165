import { ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
import { tap, mergeMap, share } from 'rxjs/operators';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { TimeOffStatus } from '@omni/classes/activity/timeoff.class';
import { ChannelType, ChannelActivityType, Channel } from './../../classes/consent/channel.class';
import { Injectable, NgZone } from "@angular/core";
import { ChangeEvent } from "../../components/angular-virtual-list";
import { format, isFuture, isToday, isTomorrow, isValid, isYesterday, differenceInCalendarDays, differenceInDays, isPast, addMinutes } from "date-fns";
import { AlertController, PopoverController, } from "@ionic/angular";
import { Events } from '@omni/events';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { AccompainedUser, IrawAccompainedUser } from "../../classes/activity/accompained-user.class";
import { Activity, ActivityCancellationReson, ActivityType, ActivityTypeCode, ActivityTypeToFilterNameMap, ConfigFieldOptionValue, FormatType, MeetingActivityState, MeetingActivityType, MeetingActivityTypeCode, MeetingSubActivityType } from '../../classes/activity/activity.class';
import { AppointmentActivity, MeetingTypeDTO } from '../../classes/activity/appointment.activity.class';
import { EmailActivity, EmailActivityParty, EmailStatusCodes } from "../../classes/activity/email.activity.class";
import { FollowUpActivity, FollowupActionType, FOLLOW_UP_TYPE } from '../../classes/activity/follow-up-action.activity.class';
import { PhoneActivity } from "../../classes/activity/phone.activity.class";
import { SampleActivity, CreateSampleDropRequestBody } from "../../classes/activity/sample.activity.class";
import { TimeOffActivity } from "../../classes/activity/timeoff.class";
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { Contact, ContactMeetingState } from "../../classes/contact/contact.class";
import { Resource } from "../../classes/resource/resource.class";
import { SampleAllocationMetas } from '../../classes/sample/sample-allocation.class';
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { Appointment } from "../../models/appointment-data-model";
import { SampleActivityDTO } from "../../models/sample-model";
import { TrackAction } from "../../utility/common-enums";
import { AuthenticationService } from "../authentication.service";
import { DeviceService } from "../device/device.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import { LogService } from "../logging/log-service";
import {MyAssistantService, NOTIFICATION} from "../my-assistant/my-assistant.service";
import { NavigationService } from "../navigation/navigation.service";
import { UIService } from "../ui/ui.service";
import { OrderActivity } from "../../classes/activity/order.activity.class";
import { CaseActivity } from "../../classes/case-intake/case-activity.class";
import {GlobalUtilityService} from "../global-utility.service";
import { TranslateService } from '@ngx-translate/core';
import { ActivityPresentation } from '../../classes/presentation/activity-presentation.class';
import {DateTimeFormatsService} from "../date-time-formats/date-time-formats.service";
import { ToastStyle, NotificationService } from '../notification/notification.service';
import _ from 'lodash';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { DynamicsBoolean } from '../../enums/shared-enums';
import { DatePipe } from '@angular/common';
import { Utility } from '../../utility/util';
import { Util } from 'applicationinsights-js';
import { EmailTemplateType } from '@omni/classes/email-templates/email-template.class';
import { ActivityColorCode } from './../../classes/activity/activity.class';
import { IActivityEvent, IActivityFilterEvent } from '../../interfaces/activity/activity-event.interface';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { isDateRangeOverlapOrInFuture } from '../../utility/activity.utility';
import { SimpleProcessState } from '../../types/state/state.type';
import { SimpleProcessAction } from '../../types/action/action.type';
import { binarySearchInsertionIdx, getTodayTimestamp } from '../../utility/common.utility';
import { DateTimeFieldType } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import moment from 'moment';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { electronApi } from '@omni/services/electron-api';
import { IoConfiguration } from '@omni/interfaces/shared/shared.interface';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { StoreCheckActivity } from '@omni/classes/activity/store-check.activity.class';
import { SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input';
import { LocalizationService } from '../localization/localization.service';


export enum ActivitySource {
  MEETING_DETAIL = "MeetingDetail",
  MEETING_PRESENTATION = "MeetingPresentation",
  AGENDA = "Agenda",
  CONTACT_INFO = "ContactInfo",
  PHONE_CALL_ACTIVITY_DETAIL = "phoneCallActivityDetail",
  ONE_KEY_CONTACT_EDIT = "OneKeyContactEdit",
  ONE_KEY_ACCOUNT_EDIT = "OneKeyAccountEdit",
  EVENT_DETAIL = "EventDetail",
  SET_BOOKING_ACTIVITY_DETAIL = "SetBookingActivityDetail"
}

export enum ActivityScrapStatus {
  CANBESCRAPPED = "100000",
  HASCOMPLETEDINMEETINGALLOCATIONS = "100001",
  HASOPENINMEETINGALLOCATIONS = "100002",
  HASCOMPLETEDINMEETINGSURGERYORDERS = "100003",
  HASOPENINMEETINGSURGERYORDERS = "100004",
  HASCOMPLETEDINMEETINGFOLLOWUPS = "100005",
  HASOPENINMEETINGFOLLOWUPS = "100006",
  HAS_COMPLETED_ACCOUNT_VISIT_NESTED_MEETING = "100007",
  HAS_COMPLETED_IN_MEETING_ACTIVITY_IN_ACCOUNT_VISIT_NESTED_MEETING = "100008",
}


/**
 * Offline service for holding activities
 * Methods include sorting internal array and returning different result sets
 */
@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  isCustomerAllocationToolVisited: boolean;
  [x: string]: any;
  /**
   * All the activities
   */
  public activities: Array<Activity>;
  public activityFilter: string = ActivityType.AllActivity;
  public multiOptionActivityFilter:any;
  //public activityFilterValue: any = ActivityTypeToFilterNameMap["All Activities"]
  public activityFilterValue: any = 'HOME';
  //no acivity checking
  public noActivityConfirmation:boolean;
  /**
   * All the activity details
   *
   * @type {Array<Object>}
   * @memberof ActivityService
   */
  public activityDetails: Array<Object>;

  /**
   * Displayed activites
   */
  public displayActivities: Array<Activity>;

  public displayActivities$ = new BehaviorSubject<Activity[]>(null);

  public agendaTeamViewActivities:Array<Activity> = [];

  /**
   * Email activities
   */
  //public emailActivites: Array<EmailActivity>;
  //public phonecallActivites: Array<PhoneActivity>;
  //public appointmentActivites: Array<AppointmentActivity>;
  //public timeOffActivities: Array<TimeOffActivity>;

  //Keeping sampleActivities array intact as we don't show inMeeting Sample activities on agenda and all the checks associated with it are tightly coupled with this sampleActivities array
  public sampleActivities: Array<SampleActivity>;
  public sampleActivityMetasIndexedBySKU: Array<SampleAllocationMetas>;

  /**
   * Selected activity
   */
  public selectedActivity: Activity;
  public selectedActivityOnAgenda: Activity; // CWD-2422 To track the selected activity on Agenda view when user selects another activity on week view
  public prevSelectedActivity: Activity;
  public selectedActivityOnGenee: Activity;
  public prevSelectedActivityOnTimeline: Activity;

  /**
   * Selected shared resource from appointment screen
   */
  public selectedSharedResource: Resource;

  // Hack to allow scroll to today only once. Open to better solution
  public scrolledOnce: boolean = false;

  // Flag for the activity pane to scroll to the selected activity after DOM rendered.
  // It had to be done this way to get around the problem of an event gets pub/sub
  // even before the DOM gets rendered.
  public isNewActivityCreated: boolean = false;
  public isNewActivityFlow: boolean = false;
  public isNewMeetingFlow: boolean = false;
  public isComingFromOtherPage: boolean = false;
  public isLayOverPushedMeetingFromOtherActivities: boolean = false;
  public didOpenDetailPageFromAgendaPage: boolean = false;
  public shouldScrollToPrevSelectedActivity: boolean = false;
  private _activityEvent$: Subject<IActivityEvent> = new Subject();
  readonly activityEvent$: Observable<IActivityEvent> = this._activityEvent$.asObservable();
  private _activityDataFilterEvent$: Subject<IActivityFilterEvent> = new Subject();
  readonly activityDataFilterEvent$: Observable<IActivityFilterEvent> = this._activityDataFilterEvent$.asObservable();
  public inboundMeetingFlagForActivitiesPane: boolean = false;
  public inboundMeetingFlagForActivitiesDetailsPane: boolean = false;
  public repUserList: AccompainedUser[] = [];

  // Hashmap to keep track offline meeting data in local db
  public offlineMeetingIds = new Map();
  public offlinePhoneCallActivityIds = new Map();
  public offlineEmailActivityIds = new Map();
  public offlineSamplingIds = new Map();
  private covisitorNotificationModel: IndNotificationDataModel;

  public samplingToInMeetingActivityMapping = new Map();
  public teamViewActive: boolean = false;
  public activitiesSearchText:string = '';
  public invokeCallCenterActivity$ = new BehaviorSubject<boolean>(false);
  public contactCenterActivityInputs$ = new BehaviorSubject<any>(null);
  public contactCenterContactInputs$ = new BehaviorSubject<any>(null);
  public meetingTypes: MeetingTypeDTO[] = [];
  public configFieldOptionsValues: ConfigFieldOptionValue[] = [];
  /**
   * Activity and SubActivity Type
   */
  public meetingActivityTypes: MeetingActivityType[] = [];
  public meetingSubActivityTypes: MeetingSubActivityType[] = [];
  public meetingSubjects: string[] = [];
  public isProductMandatory: boolean = true;
  public appConfigFields:ConfiguredFields[]= [];
  public unsolicitedMeetingsData:Array<{
    contactid:string,
    appointments:Array<{
      activityid:string,
      scheduledend:Date,
    }>,
  }> = [];
  private _conflictCheckStartIdx: number = 0;
  get conflictCheckStartIdx() {
    return this._conflictCheckStartIdx;
  }
  private _conflictCheckState$: BehaviorSubject<SimpleProcessState> = new BehaviorSubject('Initial');
  public readonly conflictCheckState$: Observable<SimpleProcessState> = this._conflictCheckState$.asObservable();
  public signalConflictCheck$: Subject<{ componentUuid: number, signal: boolean, start: Date, end: Date }> = new Subject();
  public signalActivityConflictCheck$: Subject<Activity> = new Subject();
  public refreshAgendaAction$: Subject<{ action: SimpleProcessAction, nonce: number }> = new Subject();
  public readonly refreshAgendaStateObservable: Observable<SimpleProcessState> = this.refreshAgendaAction$
    .asObservable()
    .pipe(
      mergeMap(({ action, nonce }) => {
        let agendaRefreshState: SimpleProcessState = 'Idle';
        if (action === 'Run') {
          agendaRefreshState = 'Running';
        } else if (action === 'Stop') {
          if (nonce < this.refreshAgendaNonce) {
            // possibly a new one is running
          } else if (nonce === this.refreshAgendaNonce) {
            // finished
            agendaRefreshState = 'Done';
          }
        }
        return of(agendaRefreshState);
      }),
      share(),
    );
  public refreshAgendaNonce: number = null;
  public signalRefreshConflictAlertMsg$: Subject<any> = new Subject();

  /**
   * set/Reset actiview property and selected activity property
   *
   * @memberof ActivityService
   */
  set selected(activity: Activity) {
    if(activity instanceof AppointmentActivity) {
      this.uiService.activeView = ActivityType.Appointment;
    }else if (activity instanceof EmailActivity) {
      this.uiService.activeView = ActivityType.Email;
    } else if (activity instanceof PhoneActivity) {
      this.uiService.activeView = ActivityType.PhoneCall;
    } else if (activity instanceof PhoneActivity) {
      this.uiService.activeView = ActivityType.PhoneCall;
    } else if (activity instanceof TimeOffActivity) {
      this.uiService.activeView = 'TimeOffDetail';
    } else if (activity instanceof SampleActivity) {
      this.uiService.activeView = ActivityType.Sample;
    }
    console.log("set Selected activity")
    this.selectedActivity = activity;
  }

  customerInquiries: CaseActivity[] = [];

  public selectedActivityAtHome: Activity;

  public activitySource: ActivitySource;
  snapshot: any[];  // Snapshot of agenda list view port items
  calendarInviteTemplateSelection$: Subject<{ body: string, isEditable: boolean }> = new Subject<{ body: string, isEditable: boolean }>();
  teamsMeetingStatus$: BehaviorSubject<{ status: string, appointmentId: string }> = new BehaviorSubject<{ status: string, appointmentId: string }>(null);

  //Date Time picker
  public dateTimePickerType: DateTimeFieldType;

  private activityNotificationModel: IndNotificationDataModel;
  activitiesPaneNewActivityComponentUUID: number;
  private allNotifications: IndNotificationDataModel[] = [];
  //Update offline Activity for timeline
  private _offlineActivityForTimeline$: Subject<Activity> = new Subject();
  readonly offlineActivityForTimeline$: Observable<Activity> = this._offlineActivityForTimeline$.asObservable();

  meetingContentReasons:any=[];

  accountVisitAllowedFormatIds: string[];

  public activityCancellationResons = [];

  constructor(
    public authenticationService: AuthenticationService,
    private disk: DiskService,
    private uiService: UIService,
    private logService: LogService,
    private events: Events,
    private _ngZone: NgZone,
    private device: DeviceService,
    private alertCtrl: AlertController,
    private navService: NavigationService,
    private myAssistantService: MyAssistantService,
    public utilityService:GlobalUtilityService,
    public translate:TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private iab: InAppBrowser,
    private readonly authService: AuthenticationService,
    private readonly popoverCtrl: PopoverController,
    public languageService:LocalizationService,
  ) {
    this.activities = new Array<Activity>();
    this.activityDetails = new Array<object>();
    //this.calendarActivities = new Array<Appointment>();
    this.displayActivities = new Array<Activity>();
    //this.emailActivites = new Array<EmailActivity>();
    //this.phonecallActivites = new Array<PhoneActivity>();
    //this.appointmentActivites = new Array<AppointmentActivity>();
    //this.timeOffActivities = new Array<TimeOffActivity>();
    this.sampleActivities = new Array<SampleActivity>();
    this.sampleActivityMetasIndexedBySKU = [];
    this.setScrollDate(new Date());
    this.refreshAgendaStateObservable
    .pipe(
      filter(state => state === 'Done'),
    )
    .subscribe(() => {
      // Signal conflict alert msg refresh
      this.signalRefreshConflictAlertMsg$.next(null);
    });
    this.IallNotificationsSubscription = this.myAssistantService.notificationsObs$.subscribe((data: IndNotificationDataModel[]) => {
      this.allNotifications = data;
    });
  }

  // public get appointmentActivities(): AppointmentActivity[] {
  //   return this.appointmentActivites;
  // }

  public get fetchMeetingActivityTypes(): MeetingActivityType[] {
     return this.meetingActivityTypes;
  }

  public configuredActivityTypes(activityType: MeetingActivityTypeCode, formatType?: FormatType): MeetingActivityType[] {
    if (this.meetingActivityTypes.length === 0) {
      return [];
    }
    const filteredTypes = this.meetingActivityTypes.filter(at => at.indskr_omnipresenceactivity === activityType);
    const compareNames = (a, b) => a.indskr_name.toLowerCase().localeCompare(b.indskr_name.toLowerCase());
    if (!formatType) {
      return filteredTypes.sort(compareNames);
    } else {
      return filteredTypes.filter(at => _.isEmpty(at.indskr_type) || at.indskr_type.includes(formatType)).sort(compareNames);
    }
  }

  public configuredActivitySubTypes(activityType: MeetingActivityTypeCode): MeetingSubActivityType[] {
    return _.isEmpty(this.meetingSubActivityTypes) ? [] : this.meetingSubActivityTypes.filter(at => at.indskr_omnipresenceactivity === activityType).sort((a, b) => a.indskr_name.toLowerCase() < b.indskr_name.toLowerCase() ? -1 : 1);
}

  public get fetchMeetingSubActivityTypes(): MeetingSubActivityType[] {
    return this.meetingSubActivityTypes;
 }

  // public get emailAppointmentActivities(): EmailActivity[] {
  //   return this.emailActivites;
  // }

  // public get phoneAppointmentActivities(): PhoneActivity[] {
  //   return this.phonecallActivites;
  // }

  getFollowUpActivities(offlineDataOnly = false): FollowUpActivity[] {
    return this.activities.filter(a => offlineDataOnly ? a.type === ActivityType.FollowUp && (a as FollowUpActivity).pendingPushToDynamics : a.type === ActivityType.FollowUp) as FollowUpActivity[];
  }
  trackOfflineFollowUpDataCount() {
    // Track offline data count
    const offlineDataCount = this.getFollowUpActivities(true).length;
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, offlineDataCount);
  }

  getOrderActivities(offlineDataOnly = false): OrderActivity[] {
    return this.activities.filter(a => offlineDataOnly ? a.type === ActivityType.Order && (a as OrderActivity).pendingPushToDynamics : a.type === ActivityType.Order) as OrderActivity[];
  }

  // public getAppointmentActivityByID(id: string) {
  //   return this.appointmentActivites.find(a => a.ID === id);
  // }

  public isHomeActivitiyEqualsToSelected(): boolean {
    if (this.selectedActivityAtHome) {
      if (this.selectedActivity) {
        if (this.selectedActivity.ID === this.selectedActivityAtHome.ID) {
          return true;
        }
      }
    }
    return false;
  }

  public getActivityByID(id: string) {
    if(this.authenticationService.impersonatedUser && this.agendaTeamViewActivities){
      return this.agendaTeamViewActivities.find(a => a.ID === id);
    }else{
      let activity = this.activities.find(a => a.ID === id);
      return activity;
    }
  }

  public getActivityByOfflineIdForPinnedItems(id: string, activityType: string) {
    switch (activityType) {
      case ActivityType.FollowUp:
        return (this.activities.filter(a => a.type === activityType) as [FollowUpActivity]).find(f => f.offlineId === id);
      case ActivityType.Sample:
        return (this.activities.filter(a => a.type === activityType) as [SampleActivity]).find(f => f.offlineActivityId === id);
      case ActivityType.Appointment:
        return (this.activities.filter(a => a.type === activityType) as [AppointmentActivity]).find(f => f.offlineMeetingId === id);
    }
  }

  public getActivityByOfflineID(id: string) {
    //@ts-ignore
    return this.activities.find(a => a.offlineActivityId === id || a.offlineMeetingId === id);
  }

  public getDisplayctivityByID(id: string) {
    return this.displayActivities.find(a => a.ID === id);
  }

  public async updateAsRemoteMeeting(activity: AppointmentActivity) {
    activity.isRemoteDetailing = true;
    const dispIdx = this.displayActivities.findIndex(e => e.ID === activity.ID);
    if (dispIdx >= 0)
        this.displayActivities[dispIdx] = activity;

    //Update Appointment activity list
    // let appointmentIdx = this.appointmentActivites.findIndex(e => e.ID === activity.ID);
    // this.appointmentActivites[appointmentIdx] = activity;

    //Update Appointment activity list
    const accIdx = this.activities.findIndex(e => e.ID === activity.ID);
    if (accIdx >= 0)
      this.activities[accIdx] = activity;
    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
    this.filterActivities(this.activityFilter);
    await this.upsertMeetingsOfflineData(activity);
}

public fetchAppConfigData(filedName) {

}

  async mapMeetingTypes(meetingTypes: MeetingTypeDTO[]) {
    this.meetingTypes = [];
    if (Array.isArray(meetingTypes)) {
      for (let i = 0; i < meetingTypes.length; i++) {
        this.meetingTypes.push(meetingTypes[i]);
      }
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_TYPES, (doc) => {
        return { meetingTypes };
      });
    }
  }

  async loadMeetingTypesFromDB() {
    this.meetingTypes = [];
    const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_TYPES, true);
    if (dbData && Array.isArray(dbData.meetingTypes)) {
      for (let i = 0; i < dbData.meetingTypes.length; i++) {
        this.meetingTypes.push(dbData.meetingTypes[i]);
      }
    }
  }

  async mapConfigFieldOptions(options: ConfigFieldOptionValue[], configType: string, activityName: string) {
    let tableName = configType + '_customFieldType' + '_' + activityName;
    if (!this.configFieldOptionsValues.length) {
      this.configFieldOptionsValues = [];
    }
    if (Array.isArray(options)) {
      for (let i = 0; i < options.length; i++) {
        this.configFieldOptionsValues.push(options[i]);
      }
      await this.disk.updateOrInsert(tableName, (doc) => {
        return { options };
      });
    }
  }

  async loadConfigFieldOptionsFromDB(configType: string, activityName: string) {
    let tableName = configType + '_customFieldType' + '_' + activityName;
    this.configFieldOptionsValues = [];
    const dbData = await this.disk.retrieve(tableName, true);
    if (dbData && Array.isArray(dbData.options)) {
      for (let index = 0; index < dbData.options.length; index++) {
        this.configFieldOptionsValues.push(dbData.options[index]);
      }
    }
  }

  public getConfigFieldsValues(activityType: ActivityType, fieldName: string): ConfigFieldOptionValue[] {
    let activity: string = '';
    switch (activityType) {
      case ActivityType.Appointment:
        activity = 'appointment';
        break;
      case ActivityType.PhoneCall:
        activity = 'phonecall';
        break;
      default:
        console.error('Case is missing inside getConfigFieldsValues.');
        break;
    }
    return this.configFieldOptionsValues.filter(value => { return (value.activity === activity && value.fieldName === fieldName) } );
  }

  public getConfigFieldsValuesByActivityName(activityName: string, fieldName: string): ConfigFieldOptionValue[] {
    return this.configFieldOptionsValues.filter(value => { return (value.activity === activityName && value.fieldName === fieldName) });
  }

  async mapMeetingActivityTypes(formats: MeetingActivityType[]) {
    let meetingActivityTypes = this.meetingActivityTypes = [];
    if (Array.isArray(formats)) {
      meetingActivityTypes = this.meetingActivityTypes = _.chain(formats) .groupBy('indskr_activitytypeid').map((activities, activityId) => _.find(activities, { hcpmandatory: true }) || _.first(activities)).value();
    }
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_ACTIVITY_TYPES, (doc) => {
      return { meetingActivityTypes };
    });
  }

  async mapMeetingSubjects(meetingSubjects: string[]) {
    this.meetingSubjects = [];
    if (Array.isArray(meetingSubjects)) {
      this.meetingSubjects.push(...(meetingSubjects || []));
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_ACTIVITY_SUBJECTS, (doc) => {
        return { meetingSubjects };
      });
    }
  }

  async loadMeetingActivityTypesFromDB() {
    this.meetingActivityTypes = [];
    const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_ACTIVITY_TYPES, true);
    if (dbData && Array.isArray(dbData.meetingActivityTypes)) {
      for (let i = 0; i < dbData.meetingActivityTypes.length; i++) {
        this.meetingActivityTypes.push(dbData.meetingActivityTypes[i]);
      }
    }
  }

  async loadMeetingSubjectsFromDB() {
    this.meetingSubjects = [];
    const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_ACTIVITY_SUBJECTS, true);
    if (dbData && Array.isArray(dbData.meetingSubjects)) {
      this.meetingSubjects.push(...(dbData.meetingSubjects || []));
    }
  }

  async mapMeetingSubActivityTypes(meetingSubActivityTypes: MeetingSubActivityType[]) {
    this.meetingSubActivityTypes = [];
    if (Array.isArray(meetingSubActivityTypes)) {
      for (let i = 0; i < meetingSubActivityTypes.length; i++) {
        this.meetingSubActivityTypes.push(meetingSubActivityTypes[i]);
      }
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_SUB_ACTIVITY_TYPES, (doc) => {
        return { meetingSubActivityTypes };
      });
    }
  }

  async loadMeetingSubActivityTypesFromDB() {
    this.meetingSubActivityTypes = [];
    const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_SUB_ACTIVITY_TYPES, true);
    if (dbData && Array.isArray(dbData.meetingSubActivityTypes)) {
      for (let i = 0; i < dbData.meetingSubActivityTypes.length; i++) {
        this.meetingSubActivityTypes.push(dbData.meetingSubActivityTypes[i]);
      }
    }
  }

  getMeetingType(value: number): string {
    const meetingType: MeetingTypeDTO = this.meetingTypes.find(r => r.value == value);
    return meetingType && meetingType.type ? meetingType.type : '';
  }

  public get selectedActivityJointMeetingStatus(): boolean {
    if (this.selectedActivity != undefined && this.selectedActivity != null) {
      //If the meeting has been marked as joint meeting
      //Handle case where joint meeting flag is set as undefined.
      if (this.selectedActivity['isJointmeeting'] || (this.selectedActivity['accompaniedUserList'] && this.selectedActivity['accompaniedUserList'].length > 0)) {
        let meetingOwnerId = this.selectedActivity['meetingOwnerId'];
        let userId = this.authenticationService.user.systemUserID;
        //owner
        if (meetingOwnerId === userId) {
          return false;
        }
        else
          return true;
      }
      //owner is the meeting creator
      else {
        return false;
      }

    }
    else
      return false;
  }

  public async getOfflineCreatedMeetingDetailsByID(id: string) {
    //No longer in memory, load from disk
    let offlineMeetingsDoc = await this.disk.retrieve('offlineMeetings', true);

    if (!offlineMeetingsDoc || !offlineMeetingsDoc['meetings'] || !Array.isArray(offlineMeetingsDoc['meetings'])) {
      return null;
    }

    return offlineMeetingsDoc['meetings'].find(a => a.offlineMeetingId === id);
  }

  public async getOfflineCreatedPhonecallMeetingDetailsByID(id: string) {
    //No longer in memory, load from disk
    let offlineMeetingsDoc = await this.disk.retrieve('offlinePhoneCalls', true);

    if (!offlineMeetingsDoc || !offlineMeetingsDoc['meetings'] || !Array.isArray(offlineMeetingsDoc['meetings'])) {
      return null;
    }
    return offlineMeetingsDoc['meetings'].find(a => a.offlineId === id);
  }

  public fetchMoreActivities(event: ChangeEvent) {
    return;
  }

  getActivitysubject(activity: Activity) {

      let subject = ''
      switch (activity.type) {
        case ActivityType.Appointment:
          subject = ((activity && activity.subject) ? (activity.subject == 'Meeting' ? this.translate.instant('MEETING') : this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && activity.subject == 'Visit' ? this.translate.instant('VISIT') :activity.subject) + '' : '')
          break;
        case ActivityType.StoreCheck:
          subject = activity?.subject == 'Store Check' ? this.translate.instant('STORE_CHECK') : activity?.subject;
          break;
        case ActivityType.Sample:
          if (activity instanceof SampleActivity && activity.subject) {
            subject =  activity.subject.includes('Order') ? activity.subject.replace('Order', this.translate.instant('ALLOWCATION_ORDER')): activity.subject
          }
          break;
        case ActivityType.TimeOffRequest:
          //OMNI-6073
          let timeoffRequestActivity = <TimeOffActivity>activity;

          subject = (timeoffRequestActivity && timeoffRequestActivity.name) ? (timeoffRequestActivity.name == 'Time Off' ? this.translate.instant('TIME_OFF') : timeoffRequestActivity.name) : this.translate.instant('TIME_OFF');

          break;
        case ActivityType.TimeOff:
          let timeoffActivity = <TimeOffActivity>activity;
          subject = (timeoffActivity && timeoffActivity.name) ? (timeoffActivity.name == 'Time Off' ? this.translate.instant('TIME_OFF') : timeoffActivity.name) : this.translate.instant('TIME_OFF');
          break;
        case ActivityType.Email:
          subject = this.setPrimaryTextRightForMessages(activity as EmailActivity);
          break;
        case ActivityType.FollowUp:
          subject = (activity && activity.subject) ? (activity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : activity.subject) : this.translate.instant('FOLLOW_UP_ACTION')
          break;
        case ActivityType.Order:
          subject =(activity && activity.subject) ? (activity.subject.includes(this.translate.instant('NEW_ACTIVITY_SALES_ORDER')) ?  activity.subject : this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ' ' + activity.subject) : this.translate.instant('NEW_ACTIVITY_SALES_ORDER');
          break;
        case ActivityType.CaseIntake:
          if(activity instanceof CaseActivity) {
             subject = activity.caseName;
            let contact = activity._case_contact;
            let contactName = contact ? contact.fullName : (activity._case_trans_customer_name && activity._case_trans_customer_name != Utility.globalCustomerText) ? activity._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
            let status = activity.state === 1 ? 'Resolve' : activity.state === 2 ? 'Cancelled' : activity._case_stage_value ? activity._case_stage_value : 'Open';
          }
          break;
        case ActivityType.SurgeryOrder:
          subject = activity.subject
          break;
        case ActivityType.PhoneCall:
          let phonecallSubject = activity.subject;
          subject = (activity && activity.subject) && (activity.subject == 'Phone Call' || activity.subject.includes(' - Phone Call') ? phonecallSubject.replace('Phone Call', this.translate.instant('NEW_ACTIVITY_PHONECALL')) : phonecallSubject);
          break;
        default:
          subject = '';
          break;
      }
      return subject;
  }

  private getContactName(emailParty: EmailActivityParty) {
    return `${emailParty.contact_firstname} ${_.isEmpty(emailParty.contact_lastname) ? '' : emailParty.contact_lastname}`;
  }

  public setPrimaryTextRightForMessages(activity: EmailActivity): string {
    if (_.isEmpty(activity?.emailActivityParties)) {
      return activity.channelName + '';
    } else {
      let fullName;
      if (activity.emailActivityParties && activity.emailActivityParties.length == 1) {
        if (activity.emailActivityParties[0]?.contact_firstname) {
          fullName = this.getContactName(activity.emailActivityParties[0]);
          return `${fullName} - ${activity.channelName}`;
        } else return activity.channelName + '';
      } else if (activity.emailActivityParties && activity.emailActivityParties.length >= 2) {
        let cntName = 0;
        let cntNameStr = '';
        if (activity.emailActivityParties[0]?.contact_firstname) {
          fullName = this.getContactName(activity.emailActivityParties[0]);;
        }
        for (let emailActivityParty in activity.emailActivityParties) {
          if (activity.emailActivityParties[emailActivityParty]?.contact_firstname) {
            cntName = cntName + 1;
          }
        }
        cntNameStr = cntName == 0 ? '' : String(cntName = cntName - 1);
        return `${fullName} + ${cntNameStr} - ${activity.channelName}`;
      } else return activity.channelName + '';
    }
  }

  public getCaseState(activity: Activity):string{

    let caseState = '';

    if (activity['state'] === 1) {
      caseState = this.translate.instant('POP_RESOLVE');
    } else if (activity['state'] === 2) {
      caseState = this.translate.instant('CANCELLED');
    } else {

      switch ((<CaseActivity>activity)._case_stage_value) {
        case 'Open':
          caseState = this.translate.instant('OPEN');
          break;
        case 'Approved':
          caseState = this.translate.instant('APPROVE');
          break;
        case 'For Review':
          caseState = this.translate.instant('FOR_REVIEW');
          break;
        case 'In Progress':
          caseState = this.translate.instant('IN_PROGRESS');
          break;
        case 'Intake':
          caseState = this.translate.instant('INTAKE');
          break;
        case 'Pending Sync':
          caseState = this.translate.instant('PENDING_SYNC');
          break;
        default:
          caseState = caseState;
          break;
      }
    }
        return caseState;
}


  getSampleActivitiesByIDs(listOfSampleActivityIds) {
    return this._ngZone.runOutsideAngular(async () => {
      let arr = this.sampleActivities.filter(s => listOfSampleActivityIds.includes(s.ID));
      arr = arr.sort((a, b) => {
        const aT = a.scheduledStart.getTime();
        const bT = b.scheduledStart.getTime();
        if (aT > bT) {
          return 1;
        } else if (aT < bT) {
          return -1;
        } else {
          return 0;
        }
      });
      return arr;
    });
  }

  public getSecondaryInfoProcedureLog(activity: Activity): string {
    if ((activity as SurgeryOrderActivity).indskr_noprocedureday) {
      return this.translate.instant('NO_PROCEDURE_DAY');
    } else {
      return (!(activity as SurgeryOrderActivity).accountId) ? this.translate.instant('NO_ACCOUNT') : (activity as SurgeryOrderActivity).accountNameString;
    }
  }

  public getSecondaryInfoProcedureTracker(activity: Activity): string {
    return (!(activity as ProcedureTrackerActivity).accountId) ? this.translate.instant('NO_ACCOUNT') : (activity as ProcedureTrackerActivity).accountNameString;
  }

  indexWholeSampleActivityMetasBySKU() {
    if (!this.isCustomerAllocationToolVisited) {
      this.sampleActivityMetasIndexedBySKU = [];

      this.sampleActivities.map(sampleActivity => {
        this.addNewActivityToSampleActivityMetasIndexedBySKU(sampleActivity);
      });
      this.isCustomerAllocationToolVisited = true;
    }
  }

  addNewActivityToSampleActivityMetasIndexedBySKU(newSampleActivity: SampleActivity) {
    if (newSampleActivity.state === 1 && newSampleActivity.samples && Array.isArray(newSampleActivity.samples)
      && newSampleActivity.ownerId == this.authenticationService.user.systemUserID) {
      newSampleActivity.samples.map(sampleDrop => {
        const idx = this.sampleActivityMetasIndexedBySKU.findIndex(a => a.skuId === sampleDrop.indskr_skuid);
        if (idx >= 0) {
          if (this.sampleActivityMetasIndexedBySKU[idx].sampleOrderActivityMetas.findIndex(a => a.id === newSampleActivity.ID) < 0) {
            this.sampleActivityMetasIndexedBySKU[idx].sampleOrderActivityMetas.push({
              id: newSampleActivity.ID,
              subject: newSampleActivity.subject,
              contactName: newSampleActivity.contactName,
              contactID: newSampleActivity.contactID,
              startDate: newSampleActivity.startDate
            });
          }
        } else {
          this.sampleActivityMetasIndexedBySKU.push({
            skuId: sampleDrop.indskr_skuid,
            skuName: sampleDrop.indskr_skuname,
            sampleOrderActivityMetas: [{
              id: newSampleActivity.ID,
              subject: newSampleActivity.subject,
              contactName: newSampleActivity.contactName,
              contactID: newSampleActivity.contactID,
              startDate: newSampleActivity.startDate
            }]
          });
        }
      });
    }
  }

  /**
   * Copies any run time generated variables from old record to the replacing record
   */
  copyRuntimeActivityVariables(oldMeeting: Activity, replacingMeeting: Activity) {
    if (
      oldMeeting.ID === replacingMeeting.ID
      && oldMeeting instanceof AppointmentActivity
      && replacingMeeting instanceof AppointmentActivity
    ) {
      replacingMeeting.isFromXperiences = !!oldMeeting.isFromXperiences;
      replacingMeeting.conflictingActivityIds = oldMeeting.conflictingActivityIds;
      replacingMeeting.closeByActivityIds = oldMeeting.closeByActivityIds;
      replacingMeeting.closeByCompletedActivityIds = oldMeeting.closeByCompletedActivityIds;
    }
  }

  /**
   * Adds an activity to the data store, accepts the base class or any derivative
   * @param activity
   */
  public async addActivity(
    activity: Activity | EmailActivity | PhoneActivity | AppointmentActivity | SampleActivity | FollowUpActivity | CaseActivity | OrderActivity | SurgeryOrderActivity | ProcedureTrackerActivity | StoreCheckActivity | SetBookingActivity,
    replaceIfExists = false,
    updateDB = false,
    rawDoc = null,
    callFilterActivities = true,
  ) {
    if (isNaN(activity.scheduledStart?.getTime())) {
        console.error('addActivity: has invalid scheduledStart: ', activity);
        return;
    }
    let index = this.activities.findIndex(a => a.ID == activity.ID);
    let offlineIdx = -1;
    if (rawDoc && rawDoc.hasOwnProperty('offlineMeetingId')) {
      offlineIdx = this.activities.findIndex(a => a.ID === rawDoc['offlineMeetingId']);
    }

    if (index < 0 && offlineIdx < 0) {
      // if (activity.type === ActivityType.Email) {
      //   this.emailActivites.push(activity as EmailActivity);
      // }
      // if (activity.type === ActivityType.Appointment) {
      //   this.appointmentActivites.push(activity as AppointmentActivity);
      // }
      // else if (activity.type === ActivityType.PhoneCall) {
      //   this.phonecallActivites.push(activity as PhoneActivity);
      // }
      //else
      if (activity.type === ActivityType.Sample) {
        this.sampleActivities.push(activity as SampleActivity);
        // Add to sample activity index
        if (this.isCustomerAllocationToolVisited) {
          this.addNewActivityToSampleActivityMetasIndexedBySKU(activity as SampleActivity);
        }
      }
      else if (activity.type === ActivityType.FollowUp) {
        // Add to follow up activity array
      } else if (activity.type === ActivityType.Order) {
        // Add to order activity array
      }else if (activity.type === ActivityType.SurgeryOrder) {
        // Add to order activity array
      }
      else if (activity.type === ActivityType.CaseIntake) {
        this.customerInquiries.push(activity as CaseActivity);
      }
      // let idx = _.sortedIndexBy(this.activities, activity, 'scheduledStart');
      // if (idx < 0) {
      //   idx = 0;
      // }
      // this.activities.splice(idx, 0, activity);
      this.activities.push(activity);
      if(callFilterActivities){
        this.filterActivities(this.activityFilter);
      }
    }

    else if (index >= 0 && replaceIfExists) {
      // Updating. Replace the whole object
      if (activity.type === ActivityType.Appointment) {
        // Need to copy runtime variables
        this.copyRuntimeActivityVariables(this.activities[index], activity);
        this.activities[index] = activity;
      } else if (activity.type === ActivityType.Sample) {
        this.sampleActivities.map(sampleDrop => sampleDrop.ID === activity.ID ? activity : sampleDrop);
        let idx = this.sampleActivities.findIndex(sampleDrop => sampleDrop.ID === activity.ID);
        if(idx >= 0){
          this.sampleActivities[idx] = activity as SampleActivity;
        }
        if (this.isCustomerAllocationToolVisited) {
          this.addNewActivityToSampleActivityMetasIndexedBySKU(activity as SampleActivity);
        }
      } else if (activity.type === ActivityType.Email || activity.type === ActivityType.PhoneCall || activity.type === ActivityType.JointPhoneCall
        || activity.type === ActivityType.FollowUp || activity.type === ActivityType.Order || activity.type === ActivityType.SurgeryOrder
        || activity.type === ActivityType.StoreCheck) {
        // Update in follow up and Order activity array
        this.activities[index] = activity;
      }
      else if (activity.type === ActivityType.CaseIntake) {
        this.customerInquiries.map(ci => ci.ID === activity.ID ? activity : ci);
      }

      if (updateDB && rawDoc) {
        // We fall here only when we try to update existing record.
        // Set _rev to the raw doc so that it can be updated in DB
        if (this.activities[index]._rev) {
          rawDoc._rev = this.activities[index]._rev;
        } else {
          try {
            const doc: any = await this.disk.retrieve(rawDoc._id);
            rawDoc._rev = doc._rev;
          } catch (error) {
            // TODO: error handle
            console.error(`addActivity: Couldn't find ${rawDoc.ID} from local db`);
          }
        }
      }
      this.activities[index] = activity;
    }

  }

  public async removeActivity(
    activity: Activity | EmailActivity | PhoneActivity | AppointmentActivity | SampleActivity | FollowUpActivity | OrderActivity | CaseActivity | SurgeryOrderActivity | ProcedureTrackerActivity | StoreCheckActivity,
    updateDB = false,
    rawDoc = null,
    callFilterActivities = true,
  ) {
    // if (activity.type === ActivityType.Email) {
    //   let emailActivityIndex = this.emailActivites.findIndex(a => a.ID == activity.ID);
    //   if (emailActivityIndex >= 0) {
    //     this.emailActivites.splice(emailActivityIndex, 1);
    //   }
    // }
    // else
    // if (activity.type === ActivityType.Appointment || activity.type === ActivityType.JointMeeting) {
    //   let appointmentActivityIndex = this.appointmentActivites.findIndex(a => a.ID == activity.ID);
    //   if (appointmentActivityIndex >= 0) {
    //     //console.log("was " +this.appointmentActivites.length);
    //     this.appointmentActivites.splice(appointmentActivityIndex, 1);
    //     if(callFilterActivities){
    //       this.filterActivities(this.activityFilter);
    //     }
    //     //console.log("now is " +this.appointmentActivites.length);
    //   }
    // }
    // else if (activity.type === ActivityType.PhoneCall) {
    //   let phoneActivityIndex = this.phonecallActivites.findIndex(a => a.ID == activity.ID);
    //   if (phoneActivityIndex >= 0) {
    //     this.phonecallActivites.splice(phoneActivityIndex, 1);
    //   }
    // }
    //else
    if (activity instanceof SampleActivity) {
      let sampleActivityIndex = this.sampleActivities.findIndex(a => a.ID === activity.ID);
      if (sampleActivityIndex >= 0) {
        this.sampleActivities.splice(sampleActivityIndex, 1);
      }
      // Remove from sample activity index
      if (activity.samples && Array.isArray(activity.samples)) {
        activity.samples.map(sampleDrop => {
          const idx = this.sampleActivityMetasIndexedBySKU.findIndex(a => a.skuId === sampleDrop.indskr_skuid);
          if (idx >= 0) {
            const sampleActivityIdIdx = this.sampleActivityMetasIndexedBySKU[idx].sampleOrderActivityMetas
              .findIndex(a => a.id === activity.ID);
            if (sampleActivityIdIdx >= 0) {
              this.sampleActivityMetasIndexedBySKU[idx].sampleOrderActivityMetas.splice(sampleActivityIdIdx, 1);
            }
          }
        });
      }
    }
    // else if (activity.type === ActivityType.TimeOffRequest) {
    //   let idx = this.timeOffActivities.findIndex(e => e.ID === activity.ID);
    //   if (idx > -1) {
    //     this.timeOffActivities.splice(idx, 1);
    //     if(callFilterActivities){
    //       this.filterActivities(this.activityFilter);
    //     }
    //     if (!this.uiService.toolsActivityActive){
    //       this.events.publish('refreshAgenda');
    //     } else this.uiService.agendaRefreshRequired = true;
    //   }
    // }
    else if (activity.type === ActivityType.CaseIntake) {
      let idx = this.customerInquiries.findIndex(e => e.ID === activity.ID);
      this.customerInquiries.splice(idx, 1);
    }
    const activityIndex = this.activities.findIndex(a => a.ID == activity.ID);
    if (activityIndex >= 0) {
      if (updateDB && rawDoc) {
        // Set _rev & _deleted to the raw doc so that it can be deleted in DB
        if (this.activities[activityIndex]._rev) {
          rawDoc._rev = this.activities[activityIndex]._rev;
        } else {
          try {
            const doc: any = await this.disk.retrieve(rawDoc._id);
            if (doc) {
              rawDoc._rev = doc._rev;
            }
          } catch (error) {
            console.error('removeActivity: ', error);
            // TODO: error handle
            console.error(`removeActivity: Couldn't find ${rawDoc.ID} from local db`);
          }
        }

        if (rawDoc._rev) {
          rawDoc._deleted = true;
        }
      }

      this.activities.splice(activityIndex, 1);
      if(callFilterActivities){
        this.filterActivities(this.activityFilter);
      }
    }
    else {
      if (updateDB && rawDoc && rawDoc._id) {
        // Clean up dead record
        if (!rawDoc._rev) {
          try {
            const doc: any = await this.disk.retrieve(rawDoc._id);
            if (doc) {
              rawDoc._rev = doc._rev;
            }
          } catch (error) {
            console.error('removeActivity: ', error);
            // TODO: error handle
            console.error(`removeActivity: Couldn't find ${rawDoc._id} from local db during clean up`, rawDoc);
          }
        }
        if (rawDoc._rev) {
          rawDoc._deleted = true;
        }
      }
    }
  };

  /**
   * Returns a sorted list of activities that havent been completed
   * @param limit Number of results to return
   */
  public nonCompleteActivities(limit?: number): Activity[] {
    let resultArray = this.activities.filter(activity => {
      return activity.completed.getTime() === 0;
    });

    return resultArray.slice(0, limit ? limit : 25);
  }

  /**
   * Returns the latest activites based on creation date
   */
  public get todaysActivities(): Activity[] {
    // const today: string = new Date().toDateString().substr(0, 10);
    // let filterFn = (activity) => {
    //   activity.scheduledStart.toDateString().substr(0, 10) === today
    // }

    return this.activities.filter(activity => isToday(new Date(activity.scheduledStart)));
  }

  /**
   * Returns the activities for the selected date
   */
  public selectedDayActivities(day: Date): Activity[] {
    const selectedDay = day.getDate();
    return this.displayActivities.filter(activity => activity.scheduledStart.getDate() <= selectedDay && (!isValid(activity.scheduledEnd) || activity.scheduledEnd.getDate() >= selectedDay));
  }

  async loadActivitiesFromDBAndMap(dataRange: { from: string, to: string }, forceReload = false) {
    // Unless forceReload flag is up, don't run it if activities are already mapped.
    if (this.activities.some(activity => activity.type == ActivityType.Appointment) && !forceReload) {
      return;
    }
    await this.loadMeetingsFromDB(dataRange);
    await this.loadStoreCheckFromDB(dataRange);

    // Load offline created meetings that are not uploaded yet
    const offlineMeetingsDoc = await this.disk.loadOfflineMeetings();
    if (offlineMeetingsDoc && offlineMeetingsDoc.hasOwnProperty('meetings') && Array.isArray(offlineMeetingsDoc['meetings'])) {
      for (let i = 0; i < offlineMeetingsDoc['meetings'].length; i++) {
        let rawOfflineMeeting = offlineMeetingsDoc['meetings'][i];
        const isOfflineCreatedMeeting = !(rawOfflineMeeting.activityid || rawOfflineMeeting.activityId) || (rawOfflineMeeting.hasOwnProperty('activityid') && rawOfflineMeeting.activityid.includes('offline'));
        let activityId: string;

        if (isOfflineCreatedMeeting) {
          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawOfflineMeeting) as AppointmentActivity;
          if (activity && activity.state !== 4 && activity.state !== 2) {
            // Don't add canceled activity to the list.
            this.addActivity(activity,false,false,null,false);
            activityId = activity.ID;
          } else if(this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar'] && activity.status === 4 && activity.state === 2){
            this.addActivity(activity,false,false,null,false);
          }
        } else {
          activityId = rawOfflineMeeting.activityId;
        }

        // Add to hashmap regardless of offline created / edited meeting.
        if (activityId && !this.hasOfflineMeetingData(activityId)) {
          this.addToOfflineMeetingIds(activityId);
        }
      }
    }

    this.events.publish('activitiesrefreshed');
  }

  private async loadMeetingsFromDB(dataRange: { from: string; to: string; }) {
    // Find option
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.MEETING_ACTIVITY,
          $lte: DB_KEY_PREFIXES.MEETING_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };
    if (dataRange && dataRange.from) {
      option.selector['scheduledend'] = {
        $gte: dataRange.from
      };
    }
    if (dataRange && dataRange.to) {
      option.selector['scheduledend'] = Object.assign({},
        option.selector['scheduledend'],
        { $lte: dataRange.to }
      );
    }
    try {
      // Fetch from DB and do mapping
      const rawMeetings: any[] = await this.disk.find(option);

      if (rawMeetings && Array.isArray(rawMeetings)) {

        for (let i = 0; i < rawMeetings.length; i++) {
          if (rawMeetings[i].hasOwnProperty('track_action') && rawMeetings[i].track_action === TrackAction.Deleted) {
            continue;
          }

          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeetings[i]);

          if (activity && activity.state !== 4 && activity.state !== 2) {
            // Don't add canceled activity to the list.
            //CWD-3212 fix
            if (activity.indskr_type != 100000001) {
              this.addActivity(activity, false, false, null, false);
            }
          }
          else if(this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar'] && activity.status === 4 && activity.state === 2){
            this.addActivity(activity,false,false,null,false);
          }
        };
      }
    } catch (error) {
      console.error('loadActivitiesFromDBAndMap: ', error);
    }
  }

  private async loadStoreCheckFromDB(dataRange: { from: string; to: string; }) {
    // Find option
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY,
          $lte: DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };
    if (dataRange && dataRange.from) {
      option.selector['scheduledend'] = {
        $gte: dataRange.from
      };
    }
    if (dataRange && dataRange.to) {
      option.selector['scheduledend'] = Object.assign({},
        option.selector['scheduledend'],
        { $lte: dataRange.to }
      );
    }
    try {
      // Fetch from DB and do mapping
      const rawMeetings: any[] = await this.disk.find(option);

      if (rawMeetings && Array.isArray(rawMeetings)) {

        for (let i = 0; i < rawMeetings.length; i++) {
          if (rawMeetings[i].hasOwnProperty('track_action') && rawMeetings[i].track_action === TrackAction.Deleted) {
            continue;
          }

          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeetings[i]);

          if (activity && activity.state !== 4 && activity.state !== 2) {
            // Don't add canceled activity to the list.
            //CWD-3212 fix
            if (activity.indskr_type != 100000001) {
              this.addActivity(activity, false, false, null, false);
            }
          }
        };
      }
    } catch (error) {
      console.error('loadStoreCheckFromDB: ', error);
    }
  }

  private async loadOfflineEmailActivities() {
    const offlineEmailsDoc = await this.disk.loadOfflineEmails();
    if (offlineEmailsDoc && offlineEmailsDoc['emails'] && Array.isArray(offlineEmailsDoc['emails']) && offlineEmailsDoc['emails'].length >= 0) {
      const rawOfflineCreatedEmails = offlineEmailsDoc['emails'].map(a => {
        if (!a.activityid) {
          a.activityid = a.offlineActivityId;
        }
        this.addToOfflineEmailActivityIds(a.activityid);
        return a;
      }).filter(b => b.activityid.includes('offline'));

      for (let i = 0; i < rawOfflineCreatedEmails.length; i++) {
        let rawOfflineEmailActivity = rawOfflineCreatedEmails[i];
        const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawOfflineEmailActivity) as EmailActivity;
        if (activity) {
          this.addActivity(activity,false,false,null,false);
        }
      };
    }
  }

  async loadSampleOrderActivitiesFromDBAndMap(dataRange: { from: string, to: string }) {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.SAMPLE_ACTIVITY,
          $lte: DB_KEY_PREFIXES.SAMPLE_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };
    if (dataRange && dataRange.from) {
      option.selector['scheduledend'] = {
        $gte: dataRange.from
      };
    }
    if (dataRange && dataRange.to) {
      option.selector['scheduledend'] = Object.assign({},
        option.selector['scheduledend'],
        { $lte: dataRange.to }
      );
    }

    try {
      const rawSampleOrderActivities: SampleActivityDTO[] = await this.disk.find(option);

      if (rawSampleOrderActivities && Array.isArray(rawSampleOrderActivities)) {
        for (let i = 0; i < rawSampleOrderActivities.length; i++) {
          const rawSampleOrderActivity = rawSampleOrderActivities[i];

          if (rawSampleOrderActivity.hasOwnProperty('track_action') && rawSampleOrderActivity.track_action === TrackAction.Deleted) {
            continue;
          }

          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawSampleOrderActivity);

          if (activity && activity.state !== 4 && activity.state !== 2) {
            // Don't add canceled activity to the list.
            this.addActivity(activity,false,false,null,false);
          }
          if (activity instanceof SampleActivity && activity.appointmentID && activity.statusString == 'Open') {
            if (!this.samplingToInMeetingActivityMapping.has(activity.appointmentID)) {
              this.samplingToInMeetingActivityMapping.set(
                activity.appointmentID, activity.ID
              )
            }
          }
        }

        //this.displayActivities = this.activities;
        const offlineSamplesDoc = await this.disk.loadOfflineSampleOrders();
        if (offlineSamplesDoc && offlineSamplesDoc['orders'] && Array.isArray(offlineSamplesDoc['orders']) && offlineSamplesDoc['orders'].length >= 0) {
          const rawOfflineCreatedSampleActivities = offlineSamplesDoc['orders'].map(a => {
            if (!a.activityid) {
              a.activityid = a.offlineActivityId;
            }
            if (!a.indskr_appointmentid && a.offlineMeetingId) {
              a.indskr_appointmentid = a.offlineMeetingId;
            }
            this.addToOfflineSampleOrderIds(a.offlineActivityId);
            return a;
          }).filter(b => b.activityid.includes('offline'));

          for (let i = 0; i < rawOfflineCreatedSampleActivities.length; i++) {
            let rawOfflineCreatedSampleActivity = rawOfflineCreatedSampleActivities[i];
            const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawOfflineCreatedSampleActivity) as SampleActivity;

            if (activity && activity.state !== 4 && activity.state !== 2) {
              //Don't add canceled activity to the list.
              this.addActivity(activity,false,false,null,false);

              //Add to hashmap
              if (activity instanceof SampleActivity && activity.appointmentID && activity.statusString == 'Open') {
                if (!this.samplingToInMeetingActivityMapping.has(activity.appointmentID)) {
                  this.samplingToInMeetingActivityMapping.set(
                    activity.appointmentID, activity.ID
                  )
                }
              }
            }
          };
        }

      }
    } catch (error) {
      console.error('loadSampleOrderActivitiesFromDBAndMap: ', error);
    }
  }

  async loadPhoneCallActivitiesFromDBAndMap(dataRange: { from: string, to: string }) {
    let option = {
        selector: {
            '_id': {
                $gte: DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY,
                $lte: DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
            },
        }
    };
    if (dataRange && dataRange.from) {
      option.selector['scheduledend'] = {
        $gte: dataRange.from
      };
    }
    if (dataRange && dataRange.to) {
      option.selector['scheduledend'] = Object.assign({},
        option.selector['scheduledend'],
        { $lte: dataRange.to }
      );
    }
    try {
      // Fetch from DB and do mapping
      const rawMeetings: any[] = await this.disk.find(option);

      if (rawMeetings && Array.isArray(rawMeetings)) {
        for (let i = 0; i < rawMeetings.length; i++) {
          if (rawMeetings[i].hasOwnProperty('track_action') && rawMeetings[i].track_action === TrackAction.Deleted) {
            continue;
          }

          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeetings[i]);

          if (activity && activity.state !== 4 && activity.state !== 2) {
            // Don't add canceled activity to the list.

            if (activity.indskr_type != 100000001) {
              this.addActivity(activity,false,false,null,false);
            }
          }
        };
        //this.displayActivities = this.activities;
      }
    } catch (error) {
      console.error('loadActivitiesFromDBAndMap: ', error);
    }

    const offlineMeetingsDoc = await this.disk.loadOfflinePhoneCallMeetings();
    if (offlineMeetingsDoc && offlineMeetingsDoc.hasOwnProperty('meetings') && Array.isArray(offlineMeetingsDoc['meetings'])) {
      for (let i = 0; i < offlineMeetingsDoc['meetings'].length; i++) {
        let rawOfflineMeeting = offlineMeetingsDoc['meetings'][i];
        const isOfflineCreatedMeeting = !(rawOfflineMeeting.activityid || rawOfflineMeeting.activityId) || (rawOfflineMeeting.hasOwnProperty('activityid') && rawOfflineMeeting.activityid.includes('offline'));
        let activityId: string;

        if (isOfflineCreatedMeeting) {
          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawOfflineMeeting) as PhoneActivity;
          if (activity && activity.state !== 4 && activity.state !== 2) {
            // Don't add canceled activity to the list.
            this.addActivity(activity,false,false,null,false);
            activityId = activity.ID;
          }
        } else {
          activityId = rawOfflineMeeting.activityId;
        }

        // Add to hashmap regardless of offline created / edited meeting.
        if (activityId && !this.hasOfflinePhoneCallData(activityId)) {
          this.addToOfflinePhoneCallActivityIds(activityId);
        }
      }
    }
   }

  async loadEmailActivitiesFromDBAndMap(dataRange: { from: string, to: string }) {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.EMAIL_ACTIVITY,
          $lte: DB_KEY_PREFIXES.EMAIL_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };
    if (dataRange && dataRange.from) {
      option.selector['scheduledend'] = {
        $gte: dataRange.from
      };
    }
    if (dataRange && dataRange.to) {
      option.selector['scheduledend'] = Object.assign({},
        option.selector['scheduledend'],
        { $lte: dataRange.to }
      );
    }

    try {
      const rawEmailActivities: EmailActivity[] = await this.disk.find(option);
      if (rawEmailActivities && Array.isArray(rawEmailActivities)) {
        for (let i = 0; i < rawEmailActivities.length; i++) {
          const emailActivity = rawEmailActivities[i];
          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(emailActivity);
          if (activity && activity.state !== 4 && activity.state !== 2) {
            this.addActivity(activity,false,false,null,false);
          }
        }
        this.displayActivities = this.activities;
      }

      await this.loadOfflineEmailActivities();
    } catch (error) {
      console.error('loadEmailActivitiesFromDBAndMap: ', error);
    }
  }

  async mapFullSyncedActivities(rawMeetings, newLastUpdatedTime: number, forceFullSync = false) {
    await this._ngZone.runOutsideAngular(async () => {
      if (rawMeetings && Array.isArray(rawMeetings)) {
        for (let i = 0; i < rawMeetings.length; i++) {

          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeetings[i], newLastUpdatedTime, true);

          if ((activity && activity.state !== 4 && activity.state !== 2 && activity.indskr_type != 100000001)) {
            this.addActivity(activity,false,false,null,false);
          }else if(this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar'] && activity.status === 4 && activity.state === 2){
            this.addActivity(activity,false,false,null,false);
          }
        };

        try {
          // Bulk save docs to DB
          // TODO: Maybe depending on result above, take some action here?
          await this.disk.bulk(rawMeetings);
          this.displayActivities = this.activities;
        } catch (error) {
          console.error('mapFullSyncedActivities: ', error);
          // TODO: handle error..
        }
      } else {
        console.error('mapFullSyncedActivities: Invalid raw data provided');
        // TODO: handle error..
      }
    });
  }

  async mapFullSyncedPhoneCallActivities(rawMeetings, newLastUpdatedTime: number, forceFullSync = false) {
    await this._ngZone.runOutsideAngular(async () => {
      if (rawMeetings && Array.isArray(rawMeetings)) {
        if (forceFullSync) {
          // In case of force full sync, clear all of the activities objects
          // this.clear();

          try {
            // Delete all of the activity docs (not clearing offline created meetings that's not synced yet)
            // This is needed for force fullsync.
            await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_PHONECALL_ACTIVITIES);
          } catch (error) {
            console.error('mapFullSyncedActivities: ', error);
            // TODO: handle error..
          }
        }

        for (let i = 0; i < rawMeetings.length; i++) {
          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeetings[i], newLastUpdatedTime, true);

          if (activity && activity.state !== 2) {
            // Don't add canceled activity to the list.
            this.addActivity(activity,false,false,null,false);
          }
        };

        try {
          // Bulk save docs to DB
          // TODO: Maybe depending on result above, take some action here?
          await this.disk.bulk(rawMeetings);
          this.displayActivities = this.activities;
        } catch (error) {
          console.error('mapFullSyncedActivities: ', error);
          // TODO: handle error..
        }
      } else {
        console.error('mapFullSyncedActivities: Invalid raw data provided');
        // TODO: handle error..
      }
    });
  }

  async mapFullSyncedSampleOrderActivities(rawSampleOrderActivities, newLastUpdatedTime: number) {
    if (rawSampleOrderActivities && Array.isArray(rawSampleOrderActivities)) {
      for (let i = 0; i < rawSampleOrderActivities.length; i++) {
        const rawSampleOrderActivity = rawSampleOrderActivities[i];
        if (rawSampleOrderActivity['scheduledend']) {
          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawSampleOrderActivity, newLastUpdatedTime);

          if (activity && activity.state !== 2 && activity.state !== 4) {
            this.addActivity(activity,false,false,null,false);
          }
          if (activity instanceof SampleActivity && activity.appointmentID) {
            this.samplingToInMeetingActivityMapping.set(
              activity.appointmentID, activity.ID
            )
          }
        }
      }

      try {
        // Bulk save docs to DB
        // TODO: Maybe depending on result above, take some action here?
        await this.disk.bulk(rawSampleOrderActivities);
        //this.displayActivities = this.activities;
      } catch (error) {
        console.error('mapFullSyncedSampleOrderActivities: ', error);
        // TODO: handle error..
      }
    } else {
      console.error('mapFullSyncedSampleOrderActivities: Invalid raw data provided');
      // TODO: handle error..
    }
  }

  async mapFullSyncedEmailActivities(rawEmailData, newLastUpdatedTime: number) {
    if (rawEmailData && Array.isArray(rawEmailData)) {
      for (let i = 0; i < rawEmailData.length; i++) {
        const rawEmailActivity = rawEmailData[i];
        if(rawEmailActivity['offlineActivityId'] && rawEmailActivity['offlineActivityId'].indexOf('remoteurl')>-1) continue;
        if (rawEmailActivity['scheduledend']) {
          const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawEmailActivity, newLastUpdatedTime);
          if (activity && activity.state !== 2 && activity.state !== 4) {
            await this.addActivity(activity,false,false,null,false);
          }
        }
      }
      try {
        await this.disk.bulk(rawEmailData);
        this.displayActivities = this.activities;
      } catch (error) {
        console.error('mapFullSyncedEmailActivities: ', error);
        // TODO: handle error..
      }
    } else {
      console.error('mapFullSyncedEmailActivities: Invalid raw data provided');
      // TODO: handle error..
    }
  }

  async mapDeltaSyncedActivities(rawMeetings, newLastUpdatedTime: number) {
    if (rawMeetings && Array.isArray(rawMeetings)) {
      const offlineMeetingsDoc = await this.disk.loadOfflineMeetings();
      const rawMeetingIdxesNotToBeSaved = [];
      let offlineDocUpdated = false;
      let selectedActivityUpdated: boolean = false;
      const accountVisitMap: Map<string, AppointmentActivity> = new Map();
      const accountVisitChildrenMap: AppointmentActivity[] = [];

      for (let i = 0; i < rawMeetings.length; i++) {
        const rawMeeting = rawMeetings[i];
        const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeeting, newLastUpdatedTime);
        let keyInOfflineMeetingIdsMap: string;
        let hasOfflineData = this.hasOfflineMeetingData(rawMeeting.activityid);
        keyInOfflineMeetingIdsMap = hasOfflineData ? rawMeeting.activityid : null;
        if (!hasOfflineData && rawMeeting.offlineMeetingId) {
          hasOfflineData = this.hasOfflineMeetingData(rawMeeting.offlineMeetingId);
          keyInOfflineMeetingIdsMap = hasOfflineData ? rawMeeting.offlineMeetingId : null;
        }

        if (activity) {
          if (this.selectedActivity && (this.selectedActivity.ID == rawMeeting['activityid'] || this.selectedActivity.ID == rawMeeting['offlineMeetingId'])) {
            selectedActivityUpdated = true;
          }

          if (activity['accompaniedUserList'] && activity['accompaniedUserList'].length > 0 && activity.ownerId != this.authenticationService.user.systemUserID && activity['accompaniedUserList'].find(ac => ac.id === this.authenticationService.user.systemUserID)
            && this.getNotification(activity) == false) {
            this.covisitorNotificationModel = {
              type: NOTIFICATION.NOTIFICATION_TO_COVISITOR,
              name: this.translate.instant('NOTIFICATION_TO_COVISITOR'),
              DateTime: Date.now(),
              id: NOTIFICATION.NOTIFICATION_TO_COVISITOR + activity.ID,
              data: activity,
              icon: 'assets/imgs/appointment.svg',
              isRed: false,
              params: {}
            };
            this.myAssistantService.saveNotificationToDisk(this.covisitorNotificationModel);
          }
          if (hasOfflineData && keyInOfflineMeetingIdsMap) {
            if (!offlineMeetingsDoc || !offlineMeetingsDoc['meetings'] || !Array.isArray(offlineMeetingsDoc['meetings']) || offlineMeetingsDoc['meetings'].length === 0) {
              console.warn(`mapDeltaSyncedActivities: Offline meeting doc not found...  keyInOfflineMeetingIdsMap = ${keyInOfflineMeetingIdsMap}`);
            } else {
              // Possible that upload succeeded but didn't get the ok response. Remove it from offline data and update object
              if (rawMeeting.hasOwnProperty('offlineMeetingId')) {
                // Data with "offlineMeetingId" value
                const index = offlineMeetingsDoc['meetings'].findIndex(meeting => meeting.offlineMeetingId === rawMeeting.offlineMeetingId);

                if (index < 0) {
                  console.warn('mapDeltaSyncedActivities: Offline meeting record not found for ' + rawMeeting.offlineMeetingId);
                } else {
                  if (offlineMeetingsDoc['meetings'][index].activityId && offlineMeetingsDoc['meetings'][index].activityId.indexOf('offline_meeting') > -1) {
                    // Offline created data
                    // Replace object with proper ID
                    const objIdx = this.activities.findIndex(a => a.ID === rawMeeting.offlineMeetingId);
                    if (objIdx >= 0 && rawMeeting.activityid) {
                      //console.log('--- before replacing offline created object!! ', this.activities[objIdx]);
                      this.activities[objIdx] = activity;
                      //console.log('--- replaced offline created object!! ', activity);
                    } else {
                      console.warn(`mapDeltaSyncedActivities: Couldn't find offline created meeting object... keyInOfflineMeetingIdsMap: ${keyInOfflineMeetingIdsMap}, idx: ${objIdx}, rawMeeting: ${rawMeeting}`);
                    }
                  } else {
                    // Existing data
                    // Nothing needs to be done
                    //console.log(`mapDeltaSyncedActivities: Existing data which is already updated in our local db.. keyInOfflineMeetingIdsMap: ${keyInOfflineMeetingIdsMap}, rawMeeting: ${rawMeeting}`);
                  }
                  offlineMeetingsDoc['meetings'].splice(index, 1);
                  offlineDocUpdated = true;
                }
              } else {
                // Probably legacy data without "offlineMeetingId" value
                const index = offlineMeetingsDoc['meetings'].findIndex(meeting => meeting.activityId === rawMeeting.activityid);

                if (index < 0) {
                  console.warn('mapDeltaSyncedActivities: Offline meeting record not found for ' + rawMeeting.activityid);
                } else {
                  //console.log(`mapDeltaSyncedActivities: Existing old data which is already updated in our local db.. keyInOfflineMeetingIdsMap: ${keyInOfflineMeetingIdsMap}, rawMeeting: ${rawMeeting}`);
                  offlineMeetingsDoc['meetings'].splice(index, 1);
                  offlineDocUpdated = true;
                }
              }
              this.deleteFromOfflineMeetingIds(keyInOfflineMeetingIdsMap);
              //console.log('----- t4) deleted ' + keyInOfflineMeetingIdsMap + ' from hashmap', this.offlineMeetingIds);
            }
          } else {
            if (activity.state === 4 || activity.state === 2 || (rawMeeting.hasOwnProperty('track_action') && rawMeeting.track_action === TrackAction.Deleted)) {
              // Activity Canceled. Remove from list & db. Update raw doc for bulk update
             if((this.authenticationService.user.buSettings && !this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar']) && activity.status !== 4){
              const isRedundantHardDeleteRecord = rawMeeting.hasOwnProperty('track_action') && rawMeeting.track_action === TrackAction.Deleted && !rawMeeting._rev;
              if (!isRedundantHardDeleteRecord) {
                await this.removeActivity(activity, true, rawMeeting,false);
                if (this.selectedActivity && (this.selectedActivity.ID == rawMeeting['activityid'] || this.selectedActivity.ID == rawMeeting['offlineMeetingId'])) {
                  this.selectedActivity = null;
                }
              } else {
                // Since it's a record that notifies a hard deletion, no point of saving it to the disk.
                rawMeetingIdxesNotToBeSaved.push(i);
              }
             }else{
              await this.addActivity(activity, true, true, rawMeeting,false);
             }
              // If it's a hard deleted record hitting us the first time, we should have the record in our local db with '_rev' value.
              // Therefore, a record with hard delete track_action value that we cannot find '_rev' value in our local db will be skipped and ignored.

            } else {
              //CWD-3212 fix
              if (activity.indskr_type !== ActivityTypeCode.TimeOff) {
                // Add or Update both list & db. Update raw doc for bulk update
                await this.addActivity(activity, true, true, rawMeeting,false);
              }
            }
          }
        }
      };

      await this.myAssistantService.loadAndMapNotificationsFromDB();

      // Remove data that don't need to be saved
      for (let i = rawMeetingIdxesNotToBeSaved.length - 1; i >= 0; i--) {
        const rawMeetingIdx = rawMeetingIdxesNotToBeSaved[i];
        //console.log('--- deleting rawMeetingIdxesNotToBeSaved... ', rawMeetings[rawMeetingIdx]);
        rawMeetings.splice(rawMeetingIdx, 1);
      }

      if (offlineDocUpdated && offlineMeetingsDoc) {
        try {
          await this.disk.updateDocWithIdAndRev(offlineMeetingsDoc);
        } catch (error) {
          console.error('mapDeltaSyncedActivities:', error);
        }
      }

      try {
        // Bulk save/update docs to DB
        // TODO: Maybe depending on result above, take some action here?
        await this.disk.bulk(rawMeetings);
        this.displayActivities = this.activities;
        if(selectedActivityUpdated) this.events.publish('updateSelectedActivityDetails');
      } catch (error) {
        // TODO: handle error..
      }
    } else {
      console.error('mapDeltaSyncedActivities: Invalid raw data provided');
    }
  }
  getNotification(activity) {
    let displayNotification = false
    let notCovis = this.allNotifications.filter(n => n.type == NOTIFICATION.NOTIFICATION_TO_COVISITOR)
    if (notCovis.length) {
      displayNotification = notCovis.some(e => e.id.includes(activity.ID))
    }
    return displayNotification;
  }

  async mapDeltaSyncedPhoneActivities(rawMeetings, newLastUpdatedTime: number) {
    if (rawMeetings && Array.isArray(rawMeetings)) {
      const offlineMeetingsDoc = await this.disk.loadOfflinePhoneCallMeetings();
      const rawMeetingIdxesNotToBeSaved = [];
      let offlineDocUpdated = false;
      let selectedActivityUpdated: boolean = false;
      for (let i = 0; i < rawMeetings.length; i++) {
        const rawMeeting = rawMeetings[i];
        rawMeeting['activitytypecode'] = 'phonecall';
        const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeeting, newLastUpdatedTime);
        let keyInOfflineMeetingIdsMap: string;
        let hasOfflineData = this.hasOfflinePhoneCallData(rawMeeting.activityid);
        keyInOfflineMeetingIdsMap = hasOfflineData ? rawMeeting.activityid : null;
        if (!hasOfflineData && rawMeeting.indskr_externalid) {
          hasOfflineData = this.hasOfflinePhoneCallData(rawMeeting.indskr_externalid);
          keyInOfflineMeetingIdsMap = hasOfflineData ? rawMeeting.indskr_externalid : null;
        }

        if (activity) {
          if (hasOfflineData && keyInOfflineMeetingIdsMap) {
            if (!offlineMeetingsDoc || !offlineMeetingsDoc['meetings'] || !Array.isArray(offlineMeetingsDoc['meetings']) || offlineMeetingsDoc['meetings'].length === 0) {
              console.warn(`mapDeltaSyncedActivities: Offline meeting doc not found...  keyInOfflineMeetingIdsMap = ${keyInOfflineMeetingIdsMap}`);
            } else {
              // Possible that upload succeeded but didn't get the ok response. Remove it from offline data and update object
              if (rawMeeting.hasOwnProperty('offlineId')) {
                // Data with "offlineMeetingId" value
                const index = offlineMeetingsDoc['meetings'].findIndex(meeting => meeting.offlineMeetingId === rawMeeting.indskr_externalid);

                if (index < 0) {
                  console.warn('mapDeltaSyncedActivities: Offline meeting record not found for ' + rawMeeting.indskr_externalid);
                } else {
                  if (!offlineMeetingsDoc['meetings'][index].activityId) {
                    // Offline created data
                    // Replace object with proper ID
                    const objIdx = this.activities.findIndex(a => a.ID === rawMeeting.indskr_externalid);
                    if (objIdx >= 0 && rawMeeting.activityid) {
                      //console.log('--- before replacing offline created object!! ', this.activities[objIdx]);
                      this.activities[objIdx] = activity;
                      //console.log('--- replaced offline created object!! ', activity);
                    } else {
                      console.warn(`mapDeltaSyncedActivities: Couldn't find offline created meeting object... keyInOfflineMeetingIdsMap: ${keyInOfflineMeetingIdsMap}, idx: ${objIdx}, rawMeeting: ${rawMeeting}`);
                    }
                  } else {
                    // Existing data
                    // Nothing needs to be done
                    //console.log(`mapDeltaSyncedActivities: Existing data which is already updated in our local db.. keyInOfflineMeetingIdsMap: ${keyInOfflineMeetingIdsMap}, rawMeeting: ${rawMeeting}`);
                  }
                  offlineMeetingsDoc['meetings'].splice(index, 1);
                  offlineDocUpdated = true;
                }
              } else {
                // Probably legacy data without "offlineMeetingId" value
                const index = offlineMeetingsDoc['meetings'].findIndex(meeting => meeting.activityId === rawMeeting.activityid);

                if (index < 0) {
                  console.warn('mapDeltaSyncedActivities: Offline meeting record not found for ' + rawMeeting.activityid);
                } else {
                  //console.log(`mapDeltaSyncedActivities: Existing old data which is already updated in our local db.. keyInOfflineMeetingIdsMap: ${keyInOfflineMeetingIdsMap}, rawMeeting: ${rawMeeting}`);
                  offlineMeetingsDoc['meetings'].splice(index, 1);
                  offlineDocUpdated = true;
                }
              }
              this.deleteFromOfflinePhoneCallIds(keyInOfflineMeetingIdsMap);
              //console.log('----- t4) deleted ' + keyInOfflineMeetingIdsMap + ' from hashmap', this.offlineMeetingIds);
            }
          } else {
            if (activity.state === 4 || activity.state === 2 || (rawMeeting.hasOwnProperty('track_action') && rawMeeting.track_action === TrackAction.Deleted)) {
              // Activity Canceled. Remove from list & db. Update raw doc for bulk update

              // If it's a hard deleted record hitting us the first time, we should have the record in our local db with '_rev' value.
              // Therefore, a record with hard delete track_action value that we cannot find '_rev' value in our local db will be skipped and ignored.
              const isRedundantHardDeleteRecord = rawMeeting.hasOwnProperty('track_action') && rawMeeting.track_action === TrackAction.Deleted && !rawMeeting._rev;
              if (!isRedundantHardDeleteRecord) {
                await this.removeActivity(activity, true, rawMeeting,false);
              } else {
                // Since it's a record that notifies a hard deletion, no point of saving it to the disk.
                rawMeetingIdxesNotToBeSaved.push(i);
              }
            } else {
              //CWD-3212 fix
              if (activity.indskr_type !== ActivityTypeCode.TimeOff) {
                // Add or Update both list & db. Update raw doc for bulk update
                await this.addActivity(activity, true, true, rawMeeting,false);
                // await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as PhoneActivity, true);
              }/* else {
                //remove the timeoff-activity if exists with the given activity ID
                let index = this.activities.findIndex(a => a.ID == activity.ID);
                if (index >= 0) {
                  this.activities.splice(index,1);
                }
              }*/
            }
          }
        }
        if (this.selectedActivity && (this.selectedActivity.ID == rawMeeting['activityid'] || this.selectedActivity.ID == rawMeeting['indskr_externalid'])) {
          //On delta if covisitor is removed from accompanied user list, remove selected activity
          if (rawMeeting['indskr_ownerid'] != this.authenticationService.user.systemUserID &&
            (_.isEmpty(rawMeeting['activityAccompaniedUsers']) || !rawMeeting['activityAccompaniedUsers'].find(ac => ac.indskr_user === this.authenticationService.user.systemUserID))) {
            this.selectedActivity = null;
          } else {
            // this.selectedActivity = activity;
            selectedActivityUpdated = true;
          }
        }
      };

      // Remove data that don't need to be saved
      for (let i = rawMeetingIdxesNotToBeSaved.length - 1; i >= 0; i--) {
        const rawMeetingIdx = rawMeetingIdxesNotToBeSaved[i];
        //console.log('--- deleting rawMeetingIdxesNotToBeSaved... ', rawMeetings[rawMeetingIdx]);
        rawMeetings.splice(rawMeetingIdx, 1);
      }

      if (offlineDocUpdated && offlineMeetingsDoc) {
        try {
          await this.disk.updateDocWithIdAndRev(offlineMeetingsDoc);
        } catch (error) {
          console.error('mapDeltaSyncedActivities:', error);
        }
      }

      try {
        // Bulk save/update docs to DB
        // TODO: Maybe depending on result above, take some action here?
        await this.disk.bulk(rawMeetings);
        this.displayActivities = this.activities;
        if (selectedActivityUpdated){
          this.events.publish('UpdatePhonecallActivity');
        }
      } catch (error) {
        // TODO: handle error..
        console.error('mapDeltaSyncedActivities: error', error);
      }
    } else {
      console.error('mapDeltaSyncedActivities: Invalid raw data provided');
    }
  }

  async mapDeltaSyncedSampleOrderActivities(rawSampleOrderActivities: SampleActivityDTO[], newLastUpdatedTime: number) {
    if (rawSampleOrderActivities && Array.isArray(rawSampleOrderActivities)) {
      const offlineSampleOrdersDoc = await this.disk.loadOfflineSampleOrders();
      const rawSampleOrderIdxesNotToBeSaved = [];
      let offlineDocUpdated = false;

      for (let i = 0; i < rawSampleOrderActivities.length; i++) {
        const rawSampleOrderActivity = rawSampleOrderActivities[i];
        const activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawSampleOrderActivity, newLastUpdatedTime);
        if (rawSampleOrderActivity['scheduledend']) {
          if (activity) {
            if (activity.state === 4 || activity.state === 2) {
              // Activity Canceled. Remove from list & db. Update raw doc for bulk update
              await this.removeActivity(activity, true, rawSampleOrderActivity,false);
            } else {
              // Add or Update both list & db. Update raw doc for bulk update

              // Check if we have this activity in offline database
              let offlineCreatedNotSyncedFlag: boolean = false;
              let offlineCreatedNotSyncedIndex: number = -1;
              if (offlineSampleOrdersDoc && offlineSampleOrdersDoc['orders'] && Array.isArray(offlineSampleOrdersDoc['orders']) && offlineSampleOrdersDoc['orders'].length > 0 && (activity as SampleActivity).offlineActivityId) {
                offlineSampleOrdersDoc['orders'].map((order, index) => {
                  if (order['offlineActivityId'] && (activity as SampleActivity).offlineActivityId == order['offlineActivityId']) {
                    console.log('Found a gem');
                    offlineCreatedNotSyncedFlag = true;
                    offlineCreatedNotSyncedIndex = index;
                  }
                });
              }
              // If Activity is present in offline datatbase then update the ID and remove from database
              if (offlineCreatedNotSyncedFlag) {
                for (let retries = 0; retries < 6; retries++) {
                  const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSampleOrdersDoc['orders'][offlineCreatedNotSyncedIndex]));
                  let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && !newSampleOrderDoc['activityid'] && newSampleOrderDoc['offlineActivityId']) ? true : false;
                  newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = (activity as SampleActivity).ID;
                  newSampleOrderDoc['lastUpdatedTime'] = 0;
                  try {
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + (activity as SampleActivity).ID, (doc) => {
                      return newSampleOrderDoc;
                    });
                    // Update appointment activity id only if it was created offline
                    if (isSampleOrderDocCreatedOffline) {
                      let foundActivity = this.getActivityByID((activity as SampleActivity).offlineActivityId);
                      if (foundActivity) {
                        foundActivity.ID = (activity as SampleActivity).ID;
                      }
                    }
                    offlineSampleOrdersDoc['orders'].splice(offlineCreatedNotSyncedIndex, 1);
                    offlineSampleOrdersDoc['count']--
                    this.deleteFromOfflineSampleOrderIds((activity as SampleActivity).offlineActivityId)
                    break;
                  } catch (error) {
                    if (retries < 5) {
                      continue;
                    } else {
                      console.error('uploadOfflineMeetings: error saving a new record..', error);
                    }
                  }
                }
                await this.disk.updateDocWithIdAndRev(offlineSampleOrdersDoc);
              }
              await this.addActivity(activity, true, true, rawSampleOrderActivity,false);
            }
            if (activity instanceof SampleActivity && activity.appointmentID && activity.statusString == 'Open') {
              if (!this.samplingToInMeetingActivityMapping.has(activity.appointmentID)) {
                this.samplingToInMeetingActivityMapping.set(
                  activity.appointmentID, activity.ID
                )
              }
            }
          }
        }
        else {
          if ((rawSampleOrderActivity.hasOwnProperty('track_action') && rawSampleOrderActivity.track_action === TrackAction.Deleted)) {
            const isRedundantHardDeleteRecord = rawSampleOrderActivity.hasOwnProperty('track_action') && rawSampleOrderActivity.track_action === TrackAction.Deleted && !rawSampleOrderActivity._rev;
            if (!isRedundantHardDeleteRecord) {
              await this.removeActivity(activity, true, rawSampleOrderActivity,false);
            } else {
              // Since it's a record that notifies a hard deletion, no point of saving it to the disk.
              rawSampleOrderIdxesNotToBeSaved.push(i);
            }
          } else {
            await this.removeActivity(activity, true, rawSampleOrderActivity,false);
          }
        }
      }

      // Remove data that don't need to be saved
      for (let i = rawSampleOrderIdxesNotToBeSaved.length - 1; i >= 0; i--) {
        const rawSampleOrderIdx = rawSampleOrderIdxesNotToBeSaved[i];
        rawSampleOrderActivities.splice(rawSampleOrderIdx, 1);
      }

      try {
        // Bulk save/update docs to DB
        // TODO: Maybe depending on result above, take some action here?
        await this.disk.bulk(rawSampleOrderActivities);
        this.displayActivities = this.activities;
      } catch (error) {
        // TODO: handle error..
      }
    }
  }

  async mapDeltaSyncedEmailActivities(rawEmailActivities, newLastUpdatedTime: number) {
    if (rawEmailActivities && Array.isArray(rawEmailActivities)) {
      const offlineEmailDoc = await this.disk.loadOfflineEmails();
      const rawEmailIdxesNotToBeSaved = [];
      const sentEmailContactNames: string[] = [];
      const failedEmailContactNames: string[] = [];
      let notifications: string[] = [];

      for (let i = 0; i < rawEmailActivities.length; i++) {
        let rawEmailActivity = rawEmailActivities[i];
        if(rawEmailActivity['offlineActivityId'] && rawEmailActivity['offlineActivityId'].indexOf('remoteurl')>-1) continue;
        rawEmailActivity['activitytypecode'] = 'email';
        let activity = await this._initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawEmailActivity, newLastUpdatedTime);
        if ((rawEmailActivity.hasOwnProperty('track_action') && rawEmailActivity.track_action === TrackAction.Deleted)) {
          const isRedundantHardDeleteRecord = rawEmailActivity.hasOwnProperty('track_action') && rawEmailActivity.track_action === TrackAction.Deleted && !rawEmailActivity._rev;
          if (!isRedundantHardDeleteRecord) {
            await this.removeActivity(activity, true, rawEmailActivity,false);
          } else {
            // Since it's a record that notifies a hard deletion, no point of saving it to the disk.
            rawEmailIdxesNotToBeSaved.push(i);
          }
          if (this.selectedActivity && activity && (this.selectedActivity.ID === activity.ID)) {
            this.selectedActivity = null;
          }
        }
        else if (rawEmailActivity['scheduledend']) {
          if (activity) {
            if (activity.state === 4 || activity.state === 2) {
              // Activity Canceled. Remove from list & db. Update raw doc for bulk update
              await this.removeActivity(activity, true, rawEmailActivity,false);
            } else {
              // Add or Update both list & db. Update raw doc for bulk update

              // Check if we have this activity in offline database
              let offlineCreatedNotSyncedFlag: boolean = false;
              let offlineCreatedNotSyncedIndex: number = -1;
              if (offlineEmailDoc && offlineEmailDoc['emails'] && Array.isArray(offlineEmailDoc['emails']) && offlineEmailDoc['emails'].length > 0 && (activity as EmailActivity).offlineActivityId) {
                offlineEmailDoc['emails'].map((email, index) => {
                  if (email['offlineActivityId'] && (activity as EmailActivity).offlineActivityId == email['offlineActivityId']) {
                    offlineCreatedNotSyncedFlag = true;
                    offlineCreatedNotSyncedIndex = index;
                  }
                });
              }

              // If Activity is present in offline datatbase then update the ID and remove from database
              if (offlineCreatedNotSyncedFlag) {
                for (let retries = 0; retries < 6; retries++) {
                  let newEmailDoc = JSON.parse(JSON.stringify(offlineEmailDoc['emails'][offlineCreatedNotSyncedIndex]));
                  let isEmailDocCreatedOffline: boolean = (newEmailDoc && !newEmailDoc['activityid'] && newEmailDoc['offlineActivityId']) ? true : false;
                  newEmailDoc['activityid'] = (activity as EmailActivity).ID;
                  newEmailDoc['lastUpdatedTime'] = 0;
                  try {
                    if(newEmailDoc['statecode'] == 0 && newEmailDoc['statuscode'] == 6){
                      // if message is sent then store latest copy from dynamics
                      newEmailDoc = rawEmailActivity;
                    }
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.EMAIL_ACTIVITY + (activity as EmailActivity).ID, (doc) => {
                      return newEmailDoc;
                    });
                    // Update appointment activity id only if it was created offline
                    if (isEmailDocCreatedOffline) {
                      let foundActivity = this.getActivityByID((activity as EmailActivity).offlineActivityId);
                      if (foundActivity) {
                        foundActivity.ID = (activity as EmailActivity).ID;
                      }
                    }
                    offlineEmailDoc['emails'].splice(offlineCreatedNotSyncedIndex, 1);
                    offlineEmailDoc['count']--
                    this.deleteFromOfflineEmailIds((activity as EmailActivity).offlineActivityId)
                    break;
                  } catch (error) {
                    if (retries < 5) {
                      continue;
                    } else {
                      console.error('uploadOfflineMeetings: error saving a new record..', error);
                    }
                  }
                }
                await this.disk.updateDocWithIdAndRev(offlineEmailDoc);
              }
              //Prepare data for email activities notification if email is sent/ read/ link attached is open
              ({ rawEmailActivity, activity } = await this.prepareEmailActivitiesNotification(rawEmailActivity, activity, sentEmailContactNames, notifications, failedEmailContactNames));

              await this.addActivity(activity, true, true, rawEmailActivity,false);
            }
          }
        }

      }

      // Remove data that don't need to be saved
      for (let i = rawEmailIdxesNotToBeSaved.length - 1; i >= 0; i--) {
        const rawEmailIdx = rawEmailIdxesNotToBeSaved[i];
        rawEmailActivities.splice(rawEmailIdx, 1);
      }
      //Prepare data for SENT emails with Contact Names list
      // if (sentEmailContactNames.length > 0)
      //   this.myAssistantService.addNewNotification(this.translate.instant('YOUR_MAIL_TO_TEXT_HAS_SENT',{text:sentEmailContactNames.join(", ")}));
      // if (failedEmailContactNames.length > 0)
      //   this.myAssistantService.addNewNotification(this.translate.instant('YOUR_MAIL_TO_TEXT_WAS_NOT_DELIVERED',{text:failedEmailContactNames.join(", ")}));
      //Add email activities notification if present
      // if (notifications.length) {
      //   notifications.forEach(n => this.myAssistantService.addNewNotification(n));
      // }
      try {
        // Bulk save/update docs to DB
        // TODO: Maybe depending on result above, take some action here?
        await this.disk.bulk(rawEmailActivities);
        this.displayActivities = this.activities;
      } catch (error) {
        // TODO: handle error..
      }
    }
  }

  getFormattedTime = (timeToFormat: Date): string => {
    let scheduleDay = isToday(timeToFormat) ? "Today" : isTomorrow(timeToFormat) ? "Tomorrow"
      : isYesterday(timeToFormat) ? "Yesterday" : format(timeToFormat, this.dateTimeFormatsService.dateToUpper);
    let startDate: Date = new Date(timeToFormat);
    let scheduleTime = startDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return scheduleDay + " " + scheduleTime;
  }

  //Prepare Email activities notification and update email activity with latest count
  private async prepareEmailActivitiesNotification(rawEmailActivity: any, activity: Activity, sentEmailContactNames: string[], notifications: string[], failedEmailContactNames: string[]) {
    //notification is required for sent & failed emails
    if (rawEmailActivity['statuscode'] === 3 || rawEmailActivity['statuscode'] == 8) {
      //fetch from DB, to compare values
      let activityInDB = null;
      let index;
      if (rawEmailActivity['parentemailid'] != null) {
        index = this.activities.findIndex(e => e.ID == rawEmailActivity['parentemailid']);
      } else {
        index = this.activities.findIndex(e => e.ID == rawEmailActivity['activityid']);
      }
      if (index >= 0) {
        activityInDB = this.activities[index];
      } else {
        let key = "";
        if (rawEmailActivity['parentemailid'] != null) {
          key = DB_KEY_PREFIXES.EMAIL_ACTIVITY + rawEmailActivity['parentemailid'];
        }
        else {
          key = DB_KEY_PREFIXES.EMAIL_ACTIVITY + rawEmailActivity['activityid'];
        }
        const email = await this.disk.retrieve(key);
        if (email) {
          activityInDB = new EmailActivity(email);
        }
      }
      if (activityInDB) {
        let emailActivityInDB: EmailActivity = <EmailActivity>activityInDB;
        if (rawEmailActivity['emailAttachments'] && emailActivityInDB.emailAttachments.length != rawEmailActivity['emailAttachments'].length) {
          emailActivityInDB.emailAttachments = rawEmailActivity['emailAttachments'];
        }
        if (rawEmailActivity['parentemailid'] == null) {
          emailActivityInDB.description = rawEmailActivity['description'];
          emailActivityInDB.template_id = rawEmailActivity['template_id'];
        }

        let activityParties;
        if(rawEmailActivity['emailActivityParties'] && rawEmailActivity['emailActivityParties'].length != 0){
          activityParties = rawEmailActivity['emailActivityParties'];
        }else if(rawEmailActivity['smsActivityParties'] && rawEmailActivity['smsActivityParties'].length != 0){
          activityParties = rawEmailActivity['smsActivityParties'];
        }
        emailActivityInDB.emailActivityParties.forEach(emailParty => {
          let partyIndex = activityParties.findIndex(rEmailParty => rEmailParty.indskr_contactid == emailParty.indskr_contactid);
          if (partyIndex >= 0) {
            // if email was scheduled in local and on delta sync if it was sent, add a SENT notification
            if ((emailActivityInDB.emailStatus == 6 || emailActivityInDB.emailStatus == 9) && rawEmailActivity['statuscode'] == 3) {
              sentEmailContactNames.push(activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname);
              this.activityNotificationModel = {
                type: NOTIFICATION.MESSAGE_SENT,
                name: this.translate.instant("MESSAGE_SENT", {emailSubject: rawEmailActivity['subject']}),
                DateTime: Date.now(),
                id: NOTIFICATION.MESSAGE_SENT + Date.now(),
                data: rawEmailActivity,
                icon: 'assets/imgs/email.svg',
                isRed: false,
                params: {emailSubject: rawEmailActivity['subject']}
              };
              this.myAssistantService.saveNotificationToDisk(this.activityNotificationModel);
            }
            // if email was scheduled in local and on delta sync if it was failed, add a FAILED notification
            if ((emailActivityInDB.emailStatus == 6 || emailActivityInDB.emailStatus == 9) && rawEmailActivity['statuscode'] == 8) {
              emailActivityInDB.emailStatus = rawEmailActivity['statuscode'];
              failedEmailContactNames.push(activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname);
              this.activityNotificationModel = {
                type: NOTIFICATION.MESSAGE_NOT_DELEVERED,
                name: this.translate.instant("MESSAGE_NOT_DELEVERED", {customerName: failedEmailContactNames.join(",")}),
                DateTime: Date.now(),
                id: NOTIFICATION.MESSAGE_NOT_DELEVERED + Date.now(),
                data: rawEmailActivity,
                icon: 'assets/imgs/email.svg',
                isRed: false,
                params: {customerName: failedEmailContactNames.join(",")}
              };
              this.myAssistantService.saveNotificationToDisk(this.activityNotificationModel);
            }
            emailParty?.emailAddresses?.forEach(emailAddress => {
              let emailIndex = activityParties[partyIndex].emailAddresses?.findIndex(rEmailAddress => rEmailAddress.emailAddress == emailAddress.emailAddress);
              if (emailIndex >= 0) {
                let rEmailAddress = activityParties[partyIndex].emailAddresses[emailIndex];
                //Update with latest Email ReadOn time
                emailAddress.readOn = rEmailAddress['readOn'];
                emailAddress.email_senton = rEmailAddress['email_senton'];
                emailAddress.email_statuscode = rEmailAddress['email_statuscode'];
                //If latest email open count is different, add a READ notification
                if ((!emailAddress.email_opencount && rEmailAddress.email_opencount > 0) || (emailAddress.email_opencount < rEmailAddress.email_opencount)) {
                  emailAddress.email_opencount = rEmailAddress.email_opencount;
                  this.activityNotificationModel = {
                    type: NOTIFICATION.MESSAGE_OPENED,
                    name: this.translate.instant("MESSAGE_OPENED", {customerName: activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname}),
                    DateTime: Date.now(),
                    id: NOTIFICATION.MESSAGE_OPENED + Date.now(),
                    data: rawEmailActivity,
                    icon: 'assets/imgs/email.svg',
                    isRed: false,
                    params: {customerName: activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname}
                  };
                  this.myAssistantService.saveNotificationToDisk(this.activityNotificationModel);
                  notifications.push(activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname + " has read your email from " + this.getFormattedTime(emailActivityInDB['scheduledEnd']) + ": " + activity['emailSubject']);
                }
                //If latest email links click count is different, add a LINK CLICKED notification
                if ((!emailAddress.email_linksclickedcount && rEmailAddress.email_linksclickedcount > 0) || (emailAddress.email_linksclickedcount < rEmailAddress.email_linksclickedcount)) {
                  emailAddress.email_linksclickedcount = rEmailAddress.email_linksclickedcount;
                  this.activityNotificationModel = {
                    type: NOTIFICATION.MESSAGE_ATTACHMENT_CLICKED,
                    name: this.translate.instant("MESSAGE_ATTACHMENT_CLICKED", {customerName: activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname}),
                    DateTime: Date.now(),
                    id: NOTIFICATION.MESSAGE_ATTACHMENT_CLICKED + Date.now(),
                    data: rawEmailActivity,
                    icon: 'assets/imgs/email.svg',
                    isRed: false,
                    params: {customerName: activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname}
                  };
                  this.myAssistantService.saveNotificationToDisk(this.activityNotificationModel);
                  notifications.push(activityParties[partyIndex].contact_firstname + " " + activityParties[partyIndex].contact_lastname + " opened a link from your email: " + activity['emailSubject']);
                }
              }
            });
          }
        });
        emailActivityInDB.statuscode = rawEmailActivity['statuscode'];
        emailActivityInDB.emailStatus = emailActivityInDB.statuscode;
        emailActivityInDB.status = emailActivityInDB.statuscode;
        //Update email address status code
        emailActivityInDB = this.updateEmailActivityPartyStatus(emailActivityInDB);
        //Update counts in local DB
        rawEmailActivity = emailActivityInDB;
        activity = emailActivityInDB;
      }
    }
    return { rawEmailActivity, activity };
  }

  private updateEmailActivityPartyStatus(emailActivity: EmailActivity) {
    const emailActivityParties = emailActivity.emailActivityParties;
    if (emailActivityParties.length > 0) {
      emailActivityParties.forEach(emailActivityParty => {
        if (emailActivity.emailStatus === EmailStatusCodes.Draft) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Draft;
        } else if (emailActivityParty.emailAddresses.some(emailAddress => (emailAddress.email_statuscode == EmailStatusCodes.Sent) && emailAddress.readOn == null)) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Sent;
        } else if (emailActivityParty.emailAddresses.some(emailAddress => emailAddress.email_statuscode == EmailStatusCodes.Shared)) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Shared;
        } else if (emailActivityParty.emailAddresses.some(emailAddress => emailAddress.email_statuscode == EmailStatusCodes.PendingSend)) {
          emailActivityParty.email_statuscode = EmailStatusCodes.PendingSend;
        } else if (emailActivityParty.emailAddresses.filter(emailAddress => emailAddress.email_statuscode == EmailStatusCodes.Failed).length === emailActivityParty.emailAddresses.length) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Failed;
        }
        if (emailActivityParty.emailAddresses.some(emailAddress => emailAddress.readOn != null)) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Read;
        }
      })
      if (emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == EmailStatusCodes.Read).length === emailActivityParties.length) {
        emailActivity.status = EmailStatusCodes.Read;
      }
    }
    return emailActivity;
  }

  async purgeData(maxEndDateUnixTimestamp: number) {
    await Promise.all([
      this.purgeMeetings(maxEndDateUnixTimestamp),
      this.purgeAllocationOrders(maxEndDateUnixTimestamp),
    ]);
  }

  private async purgeMeetings(maxEndDateUnixTimestamp: number) {
    // Find option
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.MEETING_ACTIVITY,
          $lte: DB_KEY_PREFIXES.MEETING_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
        'scheduledend': { $lt: maxEndDateUnixTimestamp.toString() }
      }
    };

    let deletedMeetings;
    try {
      // Fetch from DB and delete
      const rawMeetings: any[] = await this.disk.find(option);

      if (rawMeetings && Array.isArray(rawMeetings)) {
        deletedMeetings = rawMeetings.map(raw => {
          // Remove from activity array if exists.
          // Not using constructor to avoid unnecessary overhead
          const tempMeeting = { ID: raw['activityid'] || raw['activityId'], type: ActivityType.Appointment };
          this.removeActivity(tempMeeting as AppointmentActivity);
          return { _id: raw._id, _rev: raw._rev, _deleted: true };
        });
      }
    } catch (error) {
      console.error('purgeMeetings: ', error);
    }

    try {
      // Bulk save/update docs to DB
      await this.disk.bulk(deletedMeetings);
    } catch (error) {
      // TODO: handle error..
      console.error('purgeMeetings: ', error);
    }
  }

  private async purgeAllocationOrders(maxEndDateUnixTimestamp: number) {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.SAMPLE_ACTIVITY,
          $lte: DB_KEY_PREFIXES.SAMPLE_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
        'actualstart': { $lt: maxEndDateUnixTimestamp }
      }
    };

    let deletedAllocationOrders;
    try {
      const rawSampleOrderActivities: SampleActivityDTO[] = await this.disk.find(option);

      if (rawSampleOrderActivities && Array.isArray(rawSampleOrderActivities)) {
        deletedAllocationOrders = rawSampleOrderActivities.map(raw => {
          // Remove from activity array if exists.
          // Not using constructor to avoid unnecessary overhead
          const tempAllocationOrder = { ID: raw['activityid'] || raw['activityId'], type: ActivityType.Sample };
          this.removeActivity(tempAllocationOrder as SampleActivity);
          return { _id: raw._id, _rev: raw._rev, _deleted: true };
        });
      }
    } catch (error) {
      console.error('purgeAllocations: ', error);
    }

    try {
      // Bulk save/update docs to DB
      await this.disk.bulk(deletedAllocationOrders);
    } catch (error) {
      // TODO: handle error..
      console.error('purgeAllocations: ', error);
    }
  }

  private async _initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc(rawMeeting, newLastUpdatedTime: number = null, isNewData: boolean = false) {
    let activity = new Activity(rawMeeting);
    switch (activity.type) {
      case ActivityType.Appointment:
        activity = new AppointmentActivity(rawMeeting);
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.MEETING_ACTIVITY + rawMeeting.activityid;
        //OMNI-3172: meeting details to reload if meeting is selected on agenda before manual sync
        break;

      case ActivityType.StoreCheck:
        activity = new StoreCheckActivity(rawMeeting);
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY + rawMeeting.activityid;
        break;

      case ActivityType.PhoneCall:
        activity = new PhoneActivity(rawMeeting);
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + rawMeeting.activityid;
        break;

      case ActivityType.Email:
        activity = new EmailActivity(rawMeeting);
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.EMAIL_ACTIVITY + rawMeeting.activityid;
        break;

      case ActivityType.TimeOff:
        activity = new TimeOffActivity(rawMeeting, ActivityType.TimeOff);
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.TIMEOFF_ACTIVITY + rawMeeting.activityid;
        break;

      case ActivityType.Sample:
        activity = new SampleActivity(rawMeeting);
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.SAMPLE_ACTIVITY + rawMeeting.activityid;
        break;

      default:
        activity = null;
        rawMeeting._id = activity._id = DB_KEY_PREFIXES.UNKNOWN_ACTIVITY + rawMeeting.activityid;
        console.error("_initActivityObjAndSetDocIDAndLastUpdatedTimeToRawDoc: No case for activity.type", activity.type);
    }

    // Prevent db hit if we know that the data is new
    if (!isNewData && !rawMeeting['_rev']) {
      const doc = await this.disk.retrieve(rawMeeting._id, true);
      if (doc) {
        // This field is required for bulk update to work..
        rawMeeting['_rev'] = activity._rev = doc['_rev'];
      }
    }

    if (!isNaN(newLastUpdatedTime)) {
      rawMeeting.lastUpdatedTime = activity.lastUpdatedTime = newLastUpdatedTime;
    }
    return activity;
  }

    /**
   * Handles meeting offline data
   * @param activity
   */
  public async upsertPhoneCallOfflineData(activity:PhoneActivity, isMeetingUpdatedOffline: boolean = false) {
    this.publishActivityEvent({action: "Update", activity: activity});
    const isOfflineCreatedMeeting = activity.ID.includes('offline');
    let isOfflineUpdatedMeeting = false;
    let updateLocalRecord = !isOfflineCreatedMeeting;
    if (!isOfflineCreatedMeeting) {
      isOfflineUpdatedMeeting = this.hasOfflinePhoneCallData(activity.ID);
    }

    if (isOfflineCreatedMeeting || isOfflineUpdatedMeeting || this.device.isOffline || isMeetingUpdatedOffline) {
      const meetingOfflineData: any = activity.DTO;
      if (!meetingOfflineData['indskr_positionid']) {
        meetingOfflineData['indskr_positionid'] = this.authenticationService.user.xPositionID;
      }

      await this.disk.updateOrInsert('offlinePhoneCalls', (dbDoc) => {
        if (!dbDoc || !dbDoc.meetings) {
          dbDoc = {
            meetings: []
          };
        }

        const meetingElementIndex = dbDoc.meetings.findIndex(a => a.activityid === activity.ID);
        if (meetingElementIndex >= 0) {
          // We have offline data for the meeting
          // Overwrite it
          if (isOfflineCreatedMeeting) {
            meetingOfflineData.hasOfflineChange = activity.hasOfflineChange = true;
          }
          dbDoc.meetings[meetingElementIndex] = meetingOfflineData;
        } else {
          // We don't have offline data for the meeting
          // Insert only when we're in offline mode
          if (this.device.isOffline || isOfflineCreatedMeeting || isOfflineUpdatedMeeting) {
            dbDoc.meetings.push(meetingOfflineData);
            this.addToOfflinePhoneCallActivityIds(activity.ID);
          } else {
            // Not supposed to be here..
            updateLocalRecord = false;
            console.error(`upsertMeetingsOfflineData: Coulnd't update. Couldn't find ${activity.ID} from offline doc.`);
          }
        }

        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PHONECALL_MEETING, dbDoc.meetings.length);
        this.events.publish('phoneCallActivityIsUpdated', activity);
        return dbDoc;
      });
    }

    if (updateLocalRecord) {
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
    }
    //Update completed phone-call in contact timeline offline mode
    if(this.device.isOffline && activity.state == 1) {
      await this.publishOfflineActivityForTimeline(activity);
    }
  }

  /**
   * Handles meeting offline data
   * @param activity
   */
  public async upsertMeetingsOfflineData(activity: AppointmentActivity | EmailActivity, isMeetingUpdatedOffline: boolean = false, forceUpsertOfflineData: boolean = false): Promise<boolean> {
    const isOfflineCreatedMeeting = activity.ID.includes('offline');
    let isSuccess = true;
    let isOfflineUpdatedMeeting = false;
    let updateLocalRecord = !isOfflineCreatedMeeting;
    if (!isOfflineCreatedMeeting) {
      isOfflineUpdatedMeeting = this.hasOfflineMeetingData(activity.ID);
    }
    // Prevent account visit creating offline record since it doesn't support offline yet
    const accountVisitRecordCheckResponse = activity instanceof AppointmentActivity ? this.accountVisitRecordCheck(activity) : undefined;
    if (
      accountVisitRecordCheckResponse?.isAccountVisitRecord
      || accountVisitRecordCheckResponse?.isAccountVisitNestedMeeting
    ) {
      return false;
    }

    if (isOfflineCreatedMeeting || isOfflineUpdatedMeeting || this.device.isOffline || isMeetingUpdatedOffline || forceUpsertOfflineData) {
      const meetingOfflineData: any = activity.DTO;
      if (!meetingOfflineData['indskr_positionid']) {
        meetingOfflineData['indskr_positionid'] = this.authenticationService.user.xPositionID;
      }
      const index = this.activities?.findIndex(act => act.ID === activity.ID);
      if (index >= 0) this.activities[index] = activity;
      try {
        await this.disk.updateOrInsert('offlineMeetings', (dbDoc) => {
          if (!dbDoc || !dbDoc.meetings) {
            dbDoc = {
              meetings: []
            };
          }

          const meetingElementIndex = dbDoc.meetings.findIndex(a => a.activityid === activity.ID);
          if (meetingElementIndex >= 0) {
            // We have offline data for the meeting
            // Overwrite it
            if (isOfflineCreatedMeeting) {
              meetingOfflineData.hasOfflineChange = activity.hasOfflineChange = true;
            }
            dbDoc.meetings[meetingElementIndex] = meetingOfflineData;
          } else {
            // We don't have offline data for the meeting
            // Insert only when we're in offline mode
            if (this.device.isOffline || isOfflineCreatedMeeting || isOfflineUpdatedMeeting || forceUpsertOfflineData) {
              dbDoc.meetings.push(meetingOfflineData);
              this.addToOfflineMeetingIds(activity.ID);
            } else {
              // Not supposed to be here..
              updateLocalRecord = false;
              const errorMsg = `upsertMeetingsOfflineData: Coulnd't update. Couldn't find ${activity.ID} from offline doc.`;
              let message;
              try {
                message = meetingOfflineData ? JSON.stringify(meetingOfflineData) : undefined;
              } catch (error) {
                message = 'DTO creation failed';
              }
              this.errorLog.writeToErrorLogFile(
                new Error(errorMsg),
                'upsertMeetingsOfflineData',
                message,
              );
              console.error(errorMsg);
              isSuccess = false;
            }
          }

          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, dbDoc.meetings.length);
          return dbDoc;
        });

        this.publishActivityEvent({action: "Update", activity: activity});
        // this.events.publish('contentmatching:refresh');
      } catch (error) {
        const dto = activity?.DTO;
        let message;
        try {
          message = dto ? JSON.stringify(dto) : undefined;
        } catch (error) {
          message = 'DTO creation failed';
        }
        await this.errorLog.writeToErrorLogFile(
          error,
          'upsertMeetingsOfflineData: updateOrInsert',
          message,
        );
        console.error('upsertMeetingsOfflineData: updateOrInsert: ', error);
        isSuccess = false;
      }
    }

    if (updateLocalRecord) {
      try {
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
      } catch (error) {
        const dto = activity?.DTO;
        let message;
        try {
          message = dto ? JSON.stringify(dto) : undefined;
        } catch (error) {
          message = 'DTO creation failed';
        }
        await this.errorLog.writeToErrorLogFile(
          error,
          'upsertMeetingsOfflineData: updateOrInsertActivityToActivityDetailRawDocument',
          message,
        );
        console.error('upsertMeetingsOfflineData: updateOrInsertActivityToActivityDetailRawDocument: ', error);
        isSuccess = false;
      }
    }
    //Update meeting in contact timeline offline mode
    if(this.device.isOffline) {
      await this.publishOfflineActivityForTimeline(activity);
    }
    return isSuccess;
  }

  public scheduleDataCalendar(): Appointment[] {
    let calendarActivities: Appointment[] = [];
    this._ngZone.runOutsideAngular(() => {

      this.displayActivities.forEach((data: AppointmentActivity) => {
        if (!isValid(data.scheduledStart)) return;
        let mappedObject: Appointment = {
          id: data.ID,
          text: this.getActivitysubject(data),
          type: data.type,
          location: data.location,
          priorityId: this.assignEmbeddedPriorityBasedOnScheduleDates(data),
          startDate: data.scheduledStart,
          endDate: data.scheduledEnd,
          timeOffReason: data.reason, //timeOff activity reason
          allDay: data.allDayEvent && isFuture(new Date(data.scheduledEnd))
        };
        if (data.type === ActivityType.TimeOffRequest) {
          if (data['totIsAlldayEvent']) {
            mappedObject.allDay = data['totIsAlldayEvent'];
          }
        }else if (data.type === ActivityType.FollowUp) {
          //Untill we have a date only field for due date, update start and end time manually
          mappedObject.allDay = true;
          mappedObject.startDate = new Date(new Date(data.scheduledStart).setHours(0,0,0,0));
          mappedObject.endDate = new Date(new Date(data.scheduledEnd).setHours(23,59,59,0));
        }
        if (this.activityFilter == ActivityType.AllActivity) {
          calendarActivities.push(mappedObject);
        }
        else if (this.activityFilter == ActivityType.TimeOff && (data.type === ActivityType.TimeOff || data.type === ActivityType.TimeOffRequest)) {
          calendarActivities.push(mappedObject);
        }
        //For Not selecting inbound and other unwanted meeting types
        else if (this.activityFilter == ActivityType.Appointment && (data.indskr_type === 100000000 && data.indskr_subtype === 100000001 || data.indskr_subtype === 100000005) && !data.isJointmeeting) {
          calendarActivities.push(mappedObject);
        }
        else if (this.activityFilter == ActivityType.JointMeeting && data.isJointmeeting) {
          calendarActivities.push(mappedObject);
        }
        else if (this.activityFilter == ActivityType.CaseIntake) {
          let subject = data['caseName'];
          let contact = data['_case_contact'];
          let contactName = contact ? contact.fullName : (data['_case_trans_customer_name'] && data['_case_trans_customer_name'] != 'Select '+this.utilityService.globalCustomerText) ? data['_case_trans_customer_name'] : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
          let status = this.getCaseState(data);
          mappedObject.text = subject;
          mappedObject.location = status +"   " +contactName;
          mappedObject['info'] = status +"   " +contactName;
          calendarActivities.push(mappedObject);
        }
      });

      // this.appointmentActivites.forEach((data: AppointmentActivity) => {

      //   let mappedObject: Appointment = {
      //     id: data.ID,
      //     text: data.subject,
      //     type: data.type,
      //     location: data.location,
      //     priorityId: this.assignEmbeddedPriorityBasedOnScheduleDates(data),
      //     startDate: data.scheduledStart,
      //     endDate: data.scheduledEnd,
      //     timeOffReason: data.reason, //timeOff activity reason
      //     allDay: data.allDayEvent && isFuture(new Date(data.scheduledEnd))
      //   };
      //   if (this.activityFilter == ActivityType.Appointment && (data.indskr_type === 100000000 && data.indskr_subtype === 100000001 || data.indskr_subtype === 100000005)) {
      //     calendarActivities.push(mappedObject);
      //   }
      // });

      this.logService.logDebug(calendarActivities);
    });
    return calendarActivities;
  }

  public getNoLocationDefaultMessage(data: Activity) {
    if (data.location && data.location == 'No Location' || !data.location)
    return this.translate.instant('NO_LOCATION');
  }

  public mapAppointmentPriorities(data: any): any {
    /*
    * More can be done eg color coding with the priority based mapping , will discuss with product  to discuss this
    */
    let resources: any;
    if (data.priorityId === 1) {
      // compares the start date with the currrent date and determines if its the past
      resources = {};
      (resources.subject = data.text),
        (resources.text = "Completed Activity"),
        (resources.id = data.priorityId),
        (resources.color = ActivityColorCode.ActivityCompleted),
        (resources.type = data.type);
        (resources.state = data.state);
      resources.location = data.location;

      //check the activity type and assign the right image
      switch (data.type) {
        case "Appointment": {
          resources.image = "assets/imgs/appointment.png";
          break;
        }
        case "Phone": {
          resources.image = "assets/imgs/phone-call-activity.svg";
          break;
        }
        case "Email": {
          resources.image = "assets/imgs/envelope.png";
          break;
        }
        case ActivityType.FollowUp: {
          resources.image = "assets/imgs/follow-up_32x32.svg";
          break;
        }
        case ActivityType.CaseIntake: {
          resources.image = "assets/imgs/case-intake.png";
          break;
        }
        default: {
          //console.log("Assign some random image");
          resources.image = "";
          break;
        }
      }
    }
    else if (data.priorityId === 2) {
      resources = {};
      (resources.subject = data.text),
        (resources.text = "Upcoming Activity"),
        (resources.id = data.priorityId),
        (resources.color = "#C2E6FF"),
        (resources.type = data.type),
        (resources.location = data.location),
        (resources.state = data.state);
      //check the activity type and assign the right image
      switch (data.type) {
        case "Appointment": {
          resources.image = "assets/imgs/appointment.png";
          break;
        }
        case "Phone": {
          resources.image = "assets/imgs/phone-call-activity.svg";
          break;
        }
        case "Email": {
          resources.image = "assets/imgs/envelope.png";
          break;
        }
        case ActivityType.FollowUp: {
          resources.image = "assets/imgs/follow-up_32x32.svg";
          break;
        }
        case ActivityType.CaseIntake: {
          resources.image = "assets/imgs/case-intake.png";
          break;
        }
        default: {
          //console.log("Assign some random image");
          resources.image = "";
          break;
        }
      }
    } else if (data.priorityId === 3) {
      resources = {};
      (resources.subject = data.text),
        (resources.text = "Time Off Request/Activity"),
        (resources.id = data.priorityId),
        (resources.color = ActivityColorCode.TimeOffActivity),
        (resources.type = data.type),
        (resources.location = data.location);
    }else if (data.priorityId === 4) {
      resources = {};
      (resources.subject = data.text),
        (resources.text = "Follow-up action"),
        (resources.id = data.priorityId),
        (resources.color = ActivityColorCode.FollowUpTask),
        (resources.type = data.type);
    } else if (data.priorityId === 5) {
      resources = {};
      (resources.subject = data.text),
        (resources.text = "Allocation Order"),
        (resources.id = data.priorityId),
        (resources.color = ActivityColorCode.Sample),
        (resources.type = data.type);
    }
    if(resources)
      resources.ID = data.id;
    return resources;
  }

  public getActivityColorForPriority(priorityId: number) {
    let color = ActivityColorCode.ActivityOpen;

    switch (priorityId) {
      case 1:
        color = ActivityColorCode.ActivityCompleted;
        break;
      case 3:
        color = ActivityColorCode.TimeOffActivity;
        break;
      case 4:
        color = ActivityColorCode.FollowUpTask;
        break;
      case 5:
        color = ActivityColorCode.Sample;
        break;
      case 6:
        color = ActivityColorCode.ProcedureLog;
      // case 6:
      //   color = ActivityColorCode.MeetingProximityWarning;
        break;
      default:
        break;
    }

    return color;
  }
  public getActivityTypeForCalendarFilter(activity: Activity) {
    let type: ActivityType;
    switch (activity.type) {
      case ActivityType.Appointment: {
        let activityHandle: AppointmentActivity = activity as AppointmentActivity;
        if (!activityHandle.isJointmeeting && !activityHandle.isRemoteDetailing && activityHandle.location !== 'LiveMeet') {
          type = ActivityType.Appointment;
        } else if (activityHandle.location === 'LiveMeet') {
          type = ActivityType.LiveMeet;
        } else if (activityHandle.isJointmeeting) {
          type = ActivityType.JointMeeting;
        } else if (activityHandle.isRemoteDetailing && activityHandle.location !== 'LiveMeet') {
          type = ActivityType.RemoteMeeting;
        }
        break;
      }
      case ActivityType.PhoneCall: {
        let activityHandle: PhoneActivity = activity as PhoneActivity;
        type = activityHandle.jointphonecall ? ActivityType.JointPhoneCall : ActivityType.PhoneCall
        break;
      }
      case ActivityType.TimeOffRequest:
        type = ActivityType.TimeOff;
        break;
      default:
        type = activity.type;
        break;
    }

    return type;
  }

  public assignEmbeddedPriorityBasedOnScheduleDates(data: any): number {
    /*
        * Appointment Entity
        * STATECODE             STATUSCODE
        * 0: Open               1:Free
        *                       2: Tentative
        * 1: Completed          3:Completed
        * 2:Cancelled           4: Cancelled
        * 3:Scheduled           5:Busy
        *                       6:Out of Office
        */
    if (data.type === ActivityType.Appointment || data.type === ActivityType.CaseIntake) {
      // if (data?.closeByActivityIds instanceof Map && data?.closeByActivityIds?.size > 0) {
      //   return 6;
      // }
      if (data.state === 1) {
        return 1;
      } else {
        // anything else should should be marked according to product specs
        return 2;
      }
    } else if (data.type === ActivityType.Email) {
      if (data.emailStatus == EmailStatusCodes.Sent || data.emailStatus == EmailStatusCodes.Failed || data.emailStatus == EmailStatusCodes.Shared) {
        return 1;
      } else {
        return 2;
      }
    } else if (data.type === ActivityType.PhoneCall) {
      if (data.state === 1) {
        return 1;
      } else {
        return 2;
      }
    } else if (data.type === ActivityType.TimeOff || data.type === ActivityType.TimeOffRequest) {
      return 3;
    } else if (data.type === ActivityType.FollowUp) {
      if (data.state === 1) {
        return 1
      } else {
        return 4;
      }
    } else if (data.type === ActivityType.Order) {
      return 2;
    } else if (data.type === ActivityType.SurgeryOrder) {
      return 6;
    } else if (data.type === ActivityType.Sample) {
      return 5;
    } else if (data.type === ActivityType.SetBooking) {
      return 6;
    }
  }

  public setScrollDate(date: Date): void {
    sessionStorage.setItem("date", date.toUTCString());
  }

  public get getScrollDate(): string {
    return sessionStorage.getItem("date");
  }

  public get getActivitiesByStartDate(): Activity[] {
    let startOfDay = new Date();
    startOfDay.setDate(startOfDay.getDate() - 180);
    startOfDay.setHours(0);

    let endOfDay = new Date();
    endOfDay.setDate(endOfDay.getDate() + 30);
    endOfDay.setHours(23);


    return this.displayActivities.filter(activity => {
      if (this.activityFilter != ActivityType.AllActivity) {
        return activity.scheduledStart.getTime() > startOfDay.getTime() && activity.scheduledStart.getTime() < endOfDay.getTime() && activity.type == this.activityFilter;
      } else {
        return activity.scheduledStart.getTime() > startOfDay.getTime() && activity.scheduledStart.getTime() < endOfDay.getTime();
      }
    });
  }


  /**
   *
   * Returns Icons based on activity type and state
   * @memberof ActivityService
   */
  public getActivityIcon = (activity: Activity): string => {
    let path = "";
    switch (activity.type) {
      case ActivityType.Appointment:
        //Change Icon when activity state is complete=1
        //Check for joint meeting
        path =  "assets/imgs/appointment.svg";
        if (activity instanceof AppointmentActivity && activity.indskr_parentcallid) {
          path = activity.state != 1
            ? 'assets/imgs/account_visit.svg'
            : 'assets/imgs/appointment_complete.svg';
        } else if (activity instanceof AppointmentActivity && activity.isLiveMeet) {
          path = "assets/imgs/live_meet.svg";
        } else if (activity['isJointmeeting']) {
          if(activity['indskr_suggest'] && activity['ownerId'] === this.authService.user.systemUserID) {
            path = activity.state != 1
            ? "assets/imgs/open_joint_recommended_meeting.svg"
            : "assets/imgs/completed_joint_recommended_meeting.svg";
          }
          else if (activity['isRemoteDetailing']) {
            path = activity.state != 1
              ? "assets/imgs/joint_remote_meeting_open.svg"
              : "assets/imgs/joint_remote_meeting_completed.svg";
          } else {
            path =
              activity.state != 1
                ? "assets/imgs/joint_appointment.svg"
                : "assets/imgs/Joint_appointment_complete.svg";
          }
        }
        else {
          if(activity['indskr_suggest']) {
            path = activity.state != 1
            ? "assets/imgs/open-recommendation-meeting.svg"
            : "assets/imgs/completed-recommendation-meeting.svg";
          }
          else if (activity['isRemoteDetailing']) {
            path = activity.state != 1
              ? "assets/imgs/remote_meeting_open.svg"
              : "assets/imgs/remote_meeting_completed.svg";
          } else {
            path =
              activity.state != 1
                ? "assets/imgs/appointment.svg"
                : "assets/imgs/appointment_complete.svg";
          }
        }
        if(activity.status === 4 ){
          path =  "assets/imgs/meeting_cancel.svg";
        }

        break;
      case ActivityType.PhoneCall:
        if (activity instanceof PhoneActivity) {
          if (activity.state === 0) {
            path = activity.jointphonecall ? "assets/imgs/open-joint-phonecall.svg" : "assets/imgs/phone-call-activity.svg";
          }
          else if (activity.state === 1) {
            path = activity.jointphonecall ? "assets/imgs/completed-joint-phonecall.svg" : "assets/imgs/phone-call-completed.svg";
          }
        }
        break;
      case ActivityType.Email:
        path = "assets/imgs/email.svg";
        if (activity.status === 1) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email.svg" : "assets/imgs/email.svg";
        }
        else if (activity.status === 3) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email-black.svg" : "assets/imgs/email-black.svg";
        }
        else if (activity.status === 6 || activity.status === 9) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email-yellow.svg" : "assets/imgs/envelope-pendingsyn.svg";
        }
        else if (activity.status === 8) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email-red.svg" : "assets/imgs/email-red.svg";
          // if (activity instanceof EmailActivity && activity.channelType !== ChannelType.EMAIL && activity.status == 8 && activity.emailActivityParties.length > 1) {
          //     let cntFailedStatus = activity.emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == 8).length;
          //     path = (activity.emailActivityParties.length == cntFailedStatus) ? "assets/imgs/email-red.svg" : "assets/imgs/email.svg";
          if (activity instanceof EmailActivity && activity.emailActivityParties.length > 1) {
            let cntSentStatus = activity.emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == 3).length;
            if (cntSentStatus >= 1) {
              path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email-black.svg" : "assets/imgs/email-black.svg";
            }
          }
        }
        else if (activity.status === 0 || (activity as EmailActivity).channelActivityType == ChannelActivityType.SMS && activity.status === 4) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email-green.svg" : "assets/imgs/email-green.svg";
        }
        else if (activity.status === 548910000) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/recommendation-email-green.svg" : "assets/imgs/email-green.svg";
        }
        break;
      case ActivityType.TimeOff:
        path = "assets/imgs/tot_approved_status.svg";
        break;
      case ActivityType.StoreCheck:
        path = "assets/imgs/storecheck.svg";
        break;
      case ActivityType.TimeOffRequest:
        path = "assets/imgs/tot_unapproved_status.svg";
        break;
      case ActivityType.Sample:
        path =
          activity.state != 1
            ? "assets/imgs/sample_activity_agendaIcon.svg"
            : "assets/imgs/sample_activity_agenda_complete.svg";
        break;
      case ActivityType.FollowUp:
        path =
          activity.state != 1
            ? "assets/imgs/follow-up_32x32.svg"
            : "assets/imgs/follow-up-complete.svg";
        break;
      case ActivityType.Order:
        switch (activity.status) {
          case 0: // Draft
            path = "assets/imgs/order_open.svg";
            break;
          case 100001: // Fulfilled or Completed
            path = "assets/imgs/orders_complete.svg";
            break;
          case 548910001: // Approved
            path = "assets/imgs/orders_complete.svg";
            break;
          case 1: // Draft
            path = "assets/imgs/order_open.svg";
            break;
          case 4: // Cancelled
            path = "assets/imgs/order_open.svg";
            break;
          case 548910000: // For Review
            path = "assets/imgs/orders_pending.svg";
          default:
            break;
        }
        break;
      case ActivityType.SurgeryOrder:
        switch (activity.status) {
          case 0: // Draft
            path = "assets/imgs/order_open.svg";
            break;
          case 100001: // Fulfilled or Completed
            path = "assets/imgs/orders_complete.svg";
            break;
          case 548910001: // Approved
            path = "assets/imgs/orders_complete.svg";
            break;
          case 1: // Draft
            path = "assets/imgs/order_open.svg";
            break;
          case 4: // Cancelled
            path = "assets/imgs/order_open.svg";
            break
          case 548910005:
            path = "assets/imgs/order_cancel.svg";
            break;
          default:
            break;
        }
        break;
      case ActivityType.ProcedureTracker:
        path = "assets/imgs/order_open.svg";
        if(activity.status === 548910001 || activity.status == 100001) path = "assets/imgs/order_complete.svg";
        break;
      case ActivityType.CaseIntake: {
        path = (<CaseActivity>activity)._case_status_value === 'Open' ? "assets/imgs/case-intake.svg" : "assets/imgs/case-intake-completed.svg"
        break;
      }
      case ActivityType.SetBooking:
        if((activity as SetBookingActivity).indskr_status == SetBookingStatus.CANCELLED)
          path = "assets/imgs/kit-booking-red.svg";
        else
          path = "assets/imgs/kit-booking.svg";
        break;
      default:
        break;
    }
    return path;
  };


  // For c360 new Icons
  getActivityIconForTimeLine(activity): string{
    let path = "";
    switch (activity.type) {
      case ActivityType.Appointment:
        //Change Icon when activity state is complete=1
        //Check for joint meeting
        path = "assets/imgs/omni_appointment.svg";
        if (activity instanceof AppointmentActivity && activity.indskr_parentcallid) {
          path = activity.state != 1
            ? 'assets/imgs/omni_account_visit.svg'
            : 'assets/imgs/omni_appoinment_complete.svg';
        } else if (activity instanceof AppointmentActivity && activity.isLiveMeet) {
          path = "assets/imgs/omni_live_meet.svg";
        } else if (activity['isJointmeeting']) {
          if(activity['indskr_suggest'] && activity['ownerId'] === this.authService.user.systemUserID) {
            path = activity.state != 1
            ? "assets/imgs/omni_open_joint_recommended_meeting.svg"
            : "assets/imgs/omni_completed_joint_recommended_meeting.svg";
          }
          else if (activity['isRemoteDetailing']) {
            path = activity.state != 1
              ? "assets/imgs/omni_joint_remote_meeting_open.svg"
              : "assets/imgs/omni_joint_remote_meeting_completed.svg";
          } else {
            path =
              activity.state != 1
                ? "assets/imgs/omni_joint_appointment.svg"
                : "assets/imgs/omni_Joint_appointment_complete.svg";
          }
        }
        else {
          if(activity['indskr_suggest']) {
            path = activity.state != 1
            ? "assets/imgs/omni_open-recommendation-meeting.svg"
            : "assets/imgs/omni_completed-recommendation-meeting.svg";
          }
          else if (activity['isRemoteDetailing']) {
            path = activity.state != 1
              ? "assets/imgs/omni_remote_meeting_open.svg"
              : "assets/imgs/omni_remote_meeting_completed.svg";
          } else {
            path =
              activity.state != 1
                ? "assets/imgs/omni_appointment.svg"
                : "assets/imgs/omni_appoinment_complete.svg";
          }
        }
        break;
      case ActivityType.PhoneCall:
        if (activity instanceof PhoneActivity) {
          if (activity.state === 0) {
            path = activity.jointphonecall ? "assets/imgs/omni_open-joint-phonecall.svg" : "assets/imgs/omni_phone-call-activity.svg";
          }
          else if (activity.state === 1) {
            path = activity.jointphonecall ? "assets/imgs/omni_completed-joint-phonecall.svg" : "assets/imgs/omni_phone-call-completed.svg";
          }
        }
        break;
      case ActivityType.Email:
        path = "assets/imgs/omni_email.svg";
        if (activity.status === 1) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation-email.svg" : "assets/imgs/omni_email.svg";
        }
        else if (activity.status === 3) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation_email_black.svg" : "assets/imgs/omni_email_black.svg";
        }
        else if (activity.status === 6 || activity.status === 9) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation_email_yellow.svg" : "assets/imgs/omni_envelope_pendingsyn.svg";
        }
        else if (activity.status === 8) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation_email_red.svg" : "assets/imgs/omni_email_red.svg";
          // if (activity instanceof EmailActivity && activity.channelType !== ChannelType.EMAIL && activity.status == 8 && activity.emailActivityParties.length > 1) {
          //     let cntFailedStatus = activity.emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == 8).length;
          //     path = (activity.emailActivityParties.length == cntFailedStatus) ? "assets/imgs/email-red.svg" : "assets/imgs/email.svg";
          if (activity instanceof EmailActivity && activity.emailActivityParties.length > 1) {
            let cntSentStatus = activity.emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == 3).length;
            if (cntSentStatus >= 1) {
              path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation_email_black.svg" : "assets/imgs/omni_email_black.svg";
            }
          }
        }
        else if (activity.status === 0 || (activity as EmailActivity).channelActivityType == ChannelActivityType.SMS && activity.status === 4) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation_email_green.svg" : "assets/imgs/omni_email_green.svg";
        }
        else if (activity.status === 548910000) {
          path = activity['indskr_recommendedmessage'] ? "assets/imgs/omni_recommendation_email_green.svg" : "assets/imgs/omni_email_green.svg";
        }
        break;
      case ActivityType.TimeOff:
        path = "assets/imgs/omni_tot_approved_status.svg";
        break;
      case ActivityType.TimeOffRequest:
        path = "assets/imgs/omni_tot_unapproved_status.svg";
        break;
      case ActivityType.Sample:
        path =
          activity.state != 1
            ? "assets/imgs/omni_sample_activity_agendaIcon.svg"
            : "assets/imgs/omni_sample_activity_agenda_complete.svg";
        break;
      case ActivityType.FollowUp:
        path =
          activity.state != 1
            ? "assets/imgs/omni_follow_up.svg"
            : "assets/imgs/omni_follow_up_complete.svg";
        break;
      case ActivityType.Order:
        switch (activity.status) {
          case 0: // Draft
            path = "assets/imgs/omni_order_open.svg";
            break;
          case 100001: // Fulfilled or Completed
            path = "assets/imgs/omni_orders_complete.svg";
            break;
          case 548910001: // Approved
            path = "assets/imgs/omni_orders_complete.svg";
            break;
          case 1: // Draft
            path = "assets/imgs/omni_order_open.svg";
            break;
          case 4: // Cancelled
            path = "assets/imgs/omni_order_open.svg";
            break;
          case 548910000: // For Review
            path = "assets/imgs/omni_orders_pending.svg";
          default:
            break;
        }
        break;
      case ActivityType.SurgeryOrder:
        switch (activity.status) {
          case 0: // Draft
            path = "assets/imgs/omni_order_open.svg";
            break;
          case 100001: // Fulfilled or Completed
            path = "assets/imgs/omni_orders_complete.svg";
            break;
          case 548910001: // Approved
            path = "assets/imgs/omni_orders_complete.svg";
            break;
          case 1: // Draft
            path = "assets/imgs/omni_order_open.svg";
            break;
          case 4: // Cancelled
            path = "assets/imgs/omni_order_open.svg";
            break;
          default:
            break;
        }
        break;
      case ActivityType.ProcedureTracker:
        path = "assets/imgs/omni_order_open.svg";
        if(activity.status === 548910001 || activity.status == 100001) path = "assets/imgs/omni_orders_complete.svg";
        break;
      case ActivityType.CaseIntake:
        path = (<CaseActivity>activity)._case_status_value === 'Open' ? "assets/imgs/omni_case_intake.svg" : "assets/imgs/omni_case_intake_completed.svg"
        break;
      case ActivityType.Event:
        path = "assets/imgs/omni_event_open.svg";
        break;
      default:
        break;
    }
    return path;
  }

  /**
   *
   * Generates array from enum
   * @returns
   * @memberof ActivityService
   */
  activityTypes() {
    let types = [];
    for (let t in ActivityType) {
      types.push(ActivityType[t]);
    }
    return types;
  }

  public get activityState() {
    return this.selectedActivity.state
      ? MeetingActivityState[this.selectedActivity.state]
      : "";
  }

  /**
   *
   * Filters activity by type and reset displayActivities array
   * @memberof ActivityService
   */
  filterActivities = (activityType: string) => {
    this.filterDisplayActivity();
    // let teamViewId: string;
    // this.teamViewActive = this.authenticationService.impersonatedUser ? true : false
    // teamViewId = this.authenticationService.impersonatedUser ? this.authenticationService.impersonatedUser.userId : ''
    // // Filter Out In Meeting Activities that should not be shown on agenda
    // let allActivities:Activity[] = this.activities.filter(ac=>{
    //   return !(ac.type == ActivityType.Sample && (ac as SampleActivity).appointmentID !== '')
    // });
    // switch (activityType) {
    //   case ActivityType.AllActivity:
    //     this.displayActivities = this.teamViewActive ? allActivities.filter((e) => {
    //       if (e instanceof AppointmentActivity && e.isJointmeeting) {
    //         return e.ownerId == teamViewId || e.accompaniedUserList.some(u => u.id == teamViewId)
    //       }
    //       else if (e instanceof TimeOffActivity) {
    //         return e.totOwnerId == teamViewId
    //       } else if (e instanceof FollowUpActivity) {
    //         return e.ownerId == teamViewId || (e as FollowUpActivity).assignedTo.some(u => u.userId == teamViewId)
    //       } else if (e instanceof SampleActivity) {
    //         return e.ownerId == teamViewId || (e as SampleActivity).ownerId == teamViewId;
    //       } else if (e instanceof CaseActivity) {
    //         return (e._case_created_by == teamViewId || e._case_owner_id == teamViewId) || ((e as CaseActivity)._case_created_by == teamViewId || (e as CaseActivity)._case_owner_id == teamViewId);
    //       } else {
    //         return e.ownerId == teamViewId
    //       }
    //     }) : allActivities.filter((e) => {
    //       if (e instanceof SampleActivity) {
    //         return e.ownerId == teamViewId || (e as SampleActivity).ownerId == this.authenticationService.user.systemUserID;
    //       } else {
    //         return true;
    //       }
    //     });
    //     break;
    //   case ActivityType.Appointment:
    //     //return all appointment that have joint meeting flagged as false and remote detailing flag is false
    //     let result: AppointmentActivity[] = this.appointmentActivites.filter((e: AppointmentActivity) => {
    //       return (this.teamViewActive ? e.ownerId == this.authenticationService.impersonatedUser.userId && !e.isJointmeeting && !e.isRemoteDetailing : !e.isJointmeeting && !e.isRemoteDetailing)
    //         && (e.location !== 'LiveMeet');
    //     });
    //     this.displayActivities = Object.assign([], result);
    //     break;
    //   case ActivityType.RemoteMeeting:
    //       //return all appointment that have remote detailing flag is true
    //       const res: AppointmentActivity[] = this.appointmentActivites.filter((e: AppointmentActivity) => {
    //         return (this.teamViewActive ? (e.ownerId == this.authenticationService.impersonatedUser.userId || (e.isJointmeeting && e.accompaniedUserList.some(u => u.id == teamViewId))) && e.isRemoteDetailing : e.isRemoteDetailing)
    //           && (e.location !== 'LiveMeet');
    //       });
    //       this.displayActivities = Object.assign([], res);
    //       break;
    //   case ActivityType.Email:
    //     this.displayActivities = this.teamViewActive ? this.emailActivites.filter((e) => e.ownerId == teamViewId) : this.emailActivites;
    //     break;
    //   case ActivityType.PhoneCall:
    //     this.displayActivities = this.teamViewActive ? this.phonecallActivites.filter((e) => e.ownerId == teamViewId && !e.jointphonecall) : this.phonecallActivites.filter(ph => !ph.jointphonecall);
    //     break;
    //   case ActivityType.TimeOff:
    //     this.displayActivities = this.teamViewActive ? this.filteredTimeOffs.filter((e) => (e as TimeOffActivity).totOwnerId == teamViewId) : this.filteredTimeOffs;
    //     break;
    //   case ActivityType.JointMeeting:
    //     let resultJm: AppointmentActivity[] = this.teamViewActive ?
    //       this.appointmentActivites.filter((e) => {
    //         return e.isJointmeeting && (e.ownerId == teamViewId || e.accompaniedUserList.some(u => u.id == teamViewId))
    //       }) : this.appointmentActivites.filter((e: AppointmentActivity) => e.isJointmeeting);
    //     this.displayActivities = Object.assign([], resultJm);
    //     break;
    //   case ActivityType.JointPhoneCall:
    //     let resultJp: PhoneActivity[] = this.teamViewActive ?
    //       this.phonecallActivites.filter((e) => {
    //         return e.jointphonecall && (e.ownerId == teamViewId || e.accompaniedUserList.some(u => u.id == teamViewId))
    //       }) : this.phonecallActivites.filter((e: PhoneActivity) => e.jointphonecall);
    //     this.displayActivities = Object.assign([], resultJp);
    //     break;
    //   case ActivityType.Sample:
    //     this.displayActivities = this.teamViewActive ? allActivities.filter((e) => e.type == ActivityType.Sample && e.ownerId == teamViewId) : allActivities.filter((e) => e.type == ActivityType.Sample && e.ownerId == this.authenticationService.user.systemUserID);
    //     break;
    //   case ActivityType.FollowUp:
    //     if (this.teamViewActive) {
    //       this.displayActivities = allActivities.filter(activity => {
    //         if (activity.type == ActivityType.FollowUp) {
    //           let isfollowupOwner: boolean = activity.ownerId == teamViewId;
    //           let isfollowupAssigned: boolean = (activity as FollowUpActivity).assignedTo.some(u => u.userId == teamViewId);
    //           return (isfollowupOwner || isfollowupAssigned)
    //         }
    //       });
    //     } else {
    //       this.displayActivities = allActivities.filter(activity => {
    //         if (activity.type == ActivityType.FollowUp) {
    //           let isfollowupOwner: boolean = activity.ownerId == this.authenticationService.user.systemUserID;
    //           let isfollowupAssigned: boolean = (activity as FollowUpActivity).assignedTo.some(u => u.userId == this.authenticationService.user.systemUserID);
    //           return (isfollowupOwner || isfollowupAssigned)
    //         }
    //       });
    //     }
    //     break;
    //   case ActivityType.Order:
    //     if(this.teamViewActive){
    //       this.displayActivities = allActivities.filter(activity => activity.type == ActivityType.Order && activity.ownerId == teamViewId)
    //     }else{
    //       this.displayActivities = allActivities.filter(activity => activity.type == ActivityType.Order && activity.ownerId == this.authenticationService.user.systemUserID)
    //     }
    //     break;
    //   case ActivityType.CaseIntake:
    //       let systemUserId = this.authenticationService.user.systemUserID;
    //       this.displayActivities = this.teamViewActive ? this.customerInquiries.filter((e) => (e._case_created_by == teamViewId || e._case_owner_id == teamViewId)) : this.customerInquiries.filter((e) => (e._case_created_by == systemUserId || e._case_owner_id == systemUserId));
    //     break;
    //   case ActivityType.LiveMeet:
    //       let resultLiveMeet: AppointmentActivity[] = this.appointmentActivites.filter((event: AppointmentActivity) => {
    //         return (event.location === 'LiveMeet');
    //       });
    //       this.displayActivities = resultLiveMeet;
    //       break;
    //   case ActivityType.SurgeryOrder:
    //       if(this.teamViewActive){
    //         this.displayActivities = allActivities.filter(activity => activity.type == ActivityType.SurgeryOrder && activity.ownerId == teamViewId)
    //       }else{
    //         this.displayActivities = allActivities.filter(activity => activity.type == ActivityType.SurgeryOrder && activity.ownerId == this.authenticationService.user.systemUserID)
    //       }
    //       break;
    //   default:
    //     this.displayActivities = [];
    //     break;
    // }
  };

  getSurgeryOrderActivitiesForReport(includeTeamOrders = false): SurgeryOrderActivity[] {
    const currentYear = new Date().getFullYear();
    return (this.activities.filter(activity => {
      let match = false;
      if (activity.type === ActivityType.SurgeryOrder) {
        if (includeTeamOrders) {
          match = activity.status === 548910001 && (activity as SurgeryOrderActivity).year && (activity as SurgeryOrderActivity).year === currentYear;
        } else {
          match = activity.ownerId === this.authenticationService.user.systemUserID && activity.status === 548910001  && (activity as SurgeryOrderActivity).year && (activity as SurgeryOrderActivity).year === currentYear;
        }
      }
      return match;
    })) as SurgeryOrderActivity[];
  }

  public get filteredAppointments(): AppointmentActivity[] {
    return this.activities.filter((activity) => activity instanceof AppointmentActivity && activity.indskr_type === 100000000).map(a=> a as AppointmentActivity);
  }

  public get filteredTimeOffs(): Activity[] {
    return this.activities.filter((activity) => (activity.type === ActivityType.TimeOff || activity.type === ActivityType.TimeOffRequest));
  }

  public get filteredJointMeetings(): Activity[] {
    return this.activities.filter((activity) => {
      if (activity.hasOwnProperty('isJointmeeting')) {
        return activity['isJointmeeting'];
      }
    });
  }
  public get samplingActivities(): Activity[] {
    return this.activities.filter((activity) => (activity.type === ActivityType.Sample && (activity as SampleActivity).appointmentID == ''));
  }

  public clear() {
    this.activities = new Array<Activity>();
    this.displayActivities = new Array<Activity>();
    //this.emailActivites = new Array<EmailActivity>();
    //this.phonecallActivites = new Array<PhoneActivity>();
    //this.appointmentActivites = new Array<AppointmentActivity>();
    this.sampleActivities = new Array<SampleActivity>();
    this.sampleActivityMetasIndexedBySKU = [];

    this.selectedActivity = undefined;
  }

  public filterDisplayActivity() {
    this.teamViewActive = this.authenticationService.impersonatedUser ? true : false;
    if(this.multiOptionActivityFilter && this.multiOptionActivityFilter.some(a=> a.categoryPath == "Users" && a.value != this.authService.user.systemUserID)){
      this.teamViewActive = true;
    }
    const allActivities: Array<Activity> = (this.teamViewActive) ? this.agendaTeamViewActivities : this.activities;
    if(this.isMyUserSelectedInTeamView){
      this.activities.forEach(a=> {
        if(!allActivities.some(b=> b.ID == a.ID)){
          allActivities.push(a);
        }
      })
    }
    if(this.multiOptionActivityFilter && this.multiOptionActivityFilter.length > 0){
      this.displayActivities = [];
      let selectedActivityTypes = this.multiOptionActivityFilter.filter(a=> a.categoryPath == "Activity_Type").map(b=> b.value);
      if (selectedActivityTypes.some(a=> a == ActivityType.AllActivity) || selectedActivityTypes.length == 0) {
        this.displayActivities = allActivities.filter(o => {
          if (o.type == ActivityType.Sample) {
            return ((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null)
          } else if (o.type == ActivityType.Email) {
            return o instanceof EmailActivity && o.emailType !== EmailTemplateType.RemoteURL
          }else {
            return true;
          }
        });;
      } else{
          allActivities.forEach(o => {
            let flag = false;
            if (selectedActivityTypes.some(a=>a == ActivityType.Appointment)) {
              if(o instanceof AppointmentActivity && !o.isJointmeeting && !o.isRemoteDetailing && o.location !== 'LiveMeet'){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.LiveMeet)) {
              if(o instanceof AppointmentActivity && o.location == 'LiveMeet'){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.PhoneCall)) {
              if(o instanceof PhoneActivity && o.type == ActivityType.PhoneCall && !o.jointphonecall){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.JointMeeting)) {
              if(o instanceof AppointmentActivity && o.isJointmeeting){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.JointPhoneCall)) {
              if(o instanceof PhoneActivity && o.jointphonecall){
                flag = true;
              }
            }
            if (selectedActivityTypes.some(a => a == ActivityType.StoreCheck)) {
              if (o instanceof StoreCheckActivity && o.type === ActivityType.StoreCheck) {
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.Sample)) {
              if(o.type == ActivityType.Sample && ((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null)){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.Email)) {
              if(o instanceof EmailActivity && o.emailType !== EmailTemplateType.RemoteURL){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.RemoteMeeting)) {
              if(o instanceof AppointmentActivity && o.isRemoteDetailing && o.location !== 'LiveMeet'){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.TimeOff)) {
              if(o instanceof TimeOffActivity && (o.type == ActivityType.TimeOff || o.type == ActivityType.TimeOffRequest)){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.SurgeryOrder)) {
              if(o.type == ActivityType.SurgeryOrder || o.type == ActivityType.ProcedureTracker){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.FollowUp)) {
              if(o.type == ActivityType.FollowUp){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.Order)) {
              if(o.type == ActivityType.Order){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.CaseIntake)) {
              if(o.type == ActivityType.CaseIntake){
                flag = true;
              }
            }
            if (!flag && selectedActivityTypes.some(a=>a == ActivityType.SetBooking)) {
              if(o.type == ActivityType.SetBooking){
                flag = true;
              }
            }
            // Add remaining types check
            if(flag && !this.displayActivities.some(a=> a.ID == o.ID)){
              this.displayActivities.push(o);
            }
          });
      }
    }else{
      if (!this.activityFilter || this.activityFilter == ActivityType.AllActivity) {
        this.displayActivities = allActivities.filter(o => {
          if (o.type == ActivityType.Sample) {
            return ((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null)
          } else if (o.type == ActivityType.Email) {
            return o instanceof EmailActivity && o.emailType !== EmailTemplateType.RemoteURL
          }else {
            return true;
          }
        });;
      } else if (this.activityFilter == ActivityType.Appointment) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof AppointmentActivity && !o.isJointmeeting && !o.isRemoteDetailing && o.location !== 'LiveMeet';
        });
      } else if (this.activityFilter == ActivityType.LiveMeet) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof AppointmentActivity && o.location == 'LiveMeet';
        });
      } else if (this.activityFilter == ActivityType.StoreCheck) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof StoreCheckActivity && o.type == ActivityType.StoreCheck;
        });
      } else if (this.activityFilter == ActivityType.PhoneCall) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof PhoneActivity && o.type == ActivityType.PhoneCall && !o.jointphonecall;
        });
      } else if (this.activityFilter == ActivityType.JointMeeting) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof AppointmentActivity && o.isJointmeeting
        });
      } else if (this.activityFilter == ActivityType.JointPhoneCall) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof PhoneActivity && o.jointphonecall
        });
      } else if (this.activityFilter == ActivityType.Sample) {
        this.displayActivities = allActivities.filter(o => {
          return (o.type == ActivityType.Sample && ((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null))
        });
      }else if (this.activityFilter == ActivityType.Email) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof EmailActivity && o.emailType !== EmailTemplateType.RemoteURL
        });
      } else if (this.activityFilter == ActivityType.RemoteMeeting) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof AppointmentActivity && o.isRemoteDetailing && o.location !== 'LiveMeet';
        });
      } else if (this.activityFilter == ActivityType.TimeOff) {
        this.displayActivities = allActivities.filter(o => {
          return o instanceof TimeOffActivity && (o.type == ActivityType.TimeOff || o.type == ActivityType.TimeOffRequest);
        });
      } else if (this.activityFilter == ActivityType.SurgeryOrder) {
        this.displayActivities = allActivities.filter(o => {
          return (o.type == ActivityType.SurgeryOrder || o.type == ActivityType.ProcedureTracker);
        });
      }else if (this.activityFilter == ActivityType.SetBooking) {
        this.displayActivities = allActivities.filter(o => {
          return (o.type == ActivityType.SetBooking);
        });
      } else {
        this.displayActivities = allActivities.filter(o => {
          return o.type == this.activityFilter
        });
      }
    }
    // if(!this.authenticationService.user.isManager){
    //   this.displayActivities = this.displayActivities.filter((o)=> {
    //     if(o.type == ActivityType.Sample){
    //       return ((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null)
    //     }else {
    //       return true;
    //     }
    //   });
    // }
  }

  public getProcedureActivties(startDate, endDate) : SurgeryOrderActivity[] {
    const allActivities: Array<Activity> = (this.teamViewActive) ? this.agendaTeamViewActivities : this.activities;

    const procedureLogs:any = allActivities.filter(o => {
      if(o.type == ActivityType.SurgeryOrder){
        let startTime = o.scheduledStart.getTime();
        let endTime = o.scheduledEnd.getTime();
        if((startTime >= startDate && startTime <= endDate) ||
        (endTime >= startDate && endTime <= endDate) ||
        (startTime >= startDate && endTime <= endDate) ){
          return o
        }
      }
    });

    return procedureLogs;
  }

  // public _checkIfActivityIncludesSearchText(activity:Activity, searchtext: string):boolean{
  // public tempfilterDisplayActivity() {
  //   if (!this.teamViewActive) {
  //     this.displayActivities = this.activities.filter((o) => {
  //       if (o instanceof AppointmentActivity && o.isJointmeeting
  //         && ((this.activityFilter == ActivityType.JointMeeting)
  //           || !this.activityFilter
  //           || this.activityFilter == ActivityType.AllActivity)) {
  //         return o.ownerId == this.authenticationService.user.systemUserID
  //           || o.accompaniedUserList.some(u => u.id == this.authenticationService.user.systemUserID)
  //       } else if (o instanceof PhoneActivity && o.jointphonecall
  //         && ((this.activityFilter == ActivityType.JointPhoneCall)
  //           || !this.activityFilter
  //           || this.activityFilter == ActivityType.AllActivity)) {
  //         return o.ownerId == this.authenticationService.user.systemUserID
  //           || o.accompaniedUserList.some(u => u.id == this.authenticationService.user.systemUserID)
  //       } else if ((o.type == this.activityFilter || !this.activityFilter
  //         || this.activityFilter == ActivityType.AllActivity)) {

  //         if (o instanceof AppointmentActivity && this.activityFilter == ActivityType.Appointment) {
  //           return o.ownerId == this.authenticationService.user.systemUserID && !o.isJointmeeting && !o.isRemoteDetailing && o.location !== 'LiveMeet';
  //         }
  //         if (o instanceof PhoneActivity && this.activityFilter == ActivityType.PhoneCall) {
  //           return o.ownerId == this.authenticationService.user.systemUserID && !o.jointphonecall;
  //         }
  //         else if (o instanceof FollowUpActivity && (!this.activityFilter || this.activityFilter == ActivityType.AllActivity || this.activityFilter == ActivityType.FollowUp)) {
  //           return (o.ownerId == this.authenticationService.user.systemUserID || (o as FollowUpActivity).assignedTo.some(u => u.userId == this.authenticationService.user.systemUserID))
  //         }else if(o.type == ActivityType.Order || o.type == ActivityType.SurgeryOrder){
  //           return (o.ownerId == this.authenticationService.user.systemUserID);
  //         }else if(o.type == ActivityType.Sample){
  //           return (((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null) && (o as SampleActivity).ownerId == this.authenticationService.user.systemUserID)
  //         }
  //         if ((o.type == ActivityType.CaseIntake && o instanceof CaseActivity)) {
  //           //TC-1134 Fix
  //           return (o._case_created_by == this.authenticationService.user.systemUserID || o._case_owner_id == this.authenticationService.user.systemUserID)
  //         }
  //         if (o.ownerId) {
  //           return o.ownerId == this.authenticationService.user.systemUserID
  //         }
  //         else if (o instanceof TimeOffActivity) {
  //           return o.totOwnerId == this.authenticationService.user.systemUserID
  //         }
  //       }
  //       else if ((o.type == ActivityType.TimeOffRequest && o instanceof TimeOffActivity &&
  //         this.activityFilter == ActivityType.TimeOff)) {
  //         return o.totOwnerId == this.authenticationService.user.systemUserID
  //       }
  //       else if (o.type === ActivityType.Appointment && o instanceof AppointmentActivity && o.isRemoteDetailing && this.activityFilter === ActivityType.RemoteMeeting) {
  //         return (o.ownerId == this.authenticationService.user.systemUserID || (o.isJointmeeting && o.accompaniedUserList.some(u => u.id == this.authenticationService.user.systemUserID))) && o.location !== 'LiveMeet';
  //       }else if (o.type === ActivityType.Appointment && o instanceof AppointmentActivity && this.activityFilter === ActivityType.LiveMeet){
  //         return o.location == 'LiveMeet';
  //       }
  //     });
  //   }
  //   if(!this.authenticationService.user.isManager){
  //     this.displayActivities = this.displayActivities.filter((o)=> {
  //       if(o.type == ActivityType.Sample){
  //         return ((o as SampleActivity).appointmentID == '' || (o as SampleActivity).appointmentID == undefined || (o as SampleActivity).appointmentID == null)
  //       }else {
  //         return true;
  //       }
  //     });
  //   }
  // }

  public _checkIfActivityIncludesSearchText(activity:Activity, searchtext: string):boolean{
    try{
      let searchText:string = searchtext.trim().toLowerCase();
      let str:string = (activity.subject) ? activity.subject.trim().toLowerCase(): '';
      let secondaryString:string = "";
      if(activity.type == ActivityType.Appointment){
        secondaryString = (!activity.location || activity.location == "nan") ? "No Location" : activity.location;
      }
      else if(activity.type == ActivityType.Sample){
        secondaryString = ((activity as SampleActivity).contactName) ? (activity as SampleActivity).contactName : 'No Contact';
      }
      else if(activity.type == ActivityType.Email){
        // secondaryString = (activity as EmailActivity).info;
        if (activity.subject.includes('Message - ')) {
          secondaryString = activity.subject=='Message'?this.translate.instant('MESSAGE'):activity.subject;
        } else {
          secondaryString = activity.subject=='Message'?this.translate.instant('MESSAGE'):this.translate.instant('MESSAGE') + ' - ' + activity.subject;
        }
      }
      else if(activity.type == ActivityType.CaseIntake){
        secondaryString = (activity as CaseActivity).caseName;
      }
      else if(activity.type == ActivityType.TimeOff || activity.type == ActivityType.TimeOffRequest){
        secondaryString = (activity as TimeOffActivity).reason;
      }
      else if(activity.type == ActivityType.FollowUp){
        if((activity as FollowUpActivity).assignedTo && (activity as FollowUpActivity).assignedTo.length > 1){
          (activity as FollowUpActivity).assignedTo.forEach(user => {
            if(user.userFullName){
              secondaryString = secondaryString + user.userFullName.trim().toLowerCase();
            }
          });
        }
      }
      else if(activity.type == ActivityType.Order){
        secondaryString = ((activity as OrderActivity).accountId)? (activity as OrderActivity).accountNameString : 'No Account';
      }
      else if(activity.type == ActivityType.SurgeryOrder){
        secondaryString = ((activity as SurgeryOrderActivity).accountId)? (activity as SurgeryOrderActivity).accountNameString : 'No Account';
        if((activity as SurgeryOrderActivity).customersNameString){
          secondaryString += (activity as SurgeryOrderActivity).customersNameString;
        }
      } else if(activity.type == ActivityType.ProcedureTracker){
        secondaryString = ((activity as ProcedureTrackerActivity).accountId)? (activity as ProcedureTrackerActivity).accountNameString : 'No Account';
      } else if(activity.type == ActivityType.SetBooking){
        secondaryString = (activity as SetBookingActivity).statusString;
      }
      if(secondaryString && secondaryString.length >= 1){
        secondaryString = secondaryString.trim().toLowerCase();
        str = str + secondaryString;
      }
      return str.includes(searchText);
    }catch(error){
      console.error('Error occured while searching text in activity:'+error);
    }
  }

  public getGroupedActivities(): Array<Object> {
    const events: any[] = [];
    const groupedElements: any = {};
    let groupByKey = 'scheduledStart';
    let value: Activity[] = this.displayActivities.filter(activity => {
      if (this.activityFilter != ActivityType.AllActivity) {
        return activity.type == this.activityFilter;
      } else {
        return true;
      }
    });
    if (value.length > 0) {
      value.sort((a, b) => (a.scheduledStart.getTime()) - (b.scheduledStart.getTime()));
      value.forEach((obj: any) => {
        let item = format(obj[groupByKey], 'DD-MMM-YYYY');
        if (!(item in groupedElements)) {
          groupedElements[item] = [];
        }
        groupedElements[item].push(obj);
      });

      for (let prop in groupedElements) {
        if (groupedElements.hasOwnProperty(prop)) {
          //let meetingDay = isToday(prop) ? "Today" : isTomorrow(prop) ? "Tomorrow" : format(prop,'dddd MMM D');
          events.push({
            key: prop,
            key_id: 'd' + prop.substr(0, 2),
            list: groupedElements[prop]
          });
        }
      }
    }
    return events;
  }

  public get phonecallActivityCanComplete():boolean{
    if (this.selectedActivity) {
      if (this.selectedActivity['contacts'] && this.selectedActivity['contacts'].some(c => (!c.isActive && !c.isguest))) {
        this.notificationService.notify(this.translate.instant('YOU_NO_LONGER_HAVE_ACCESS',{globalCustomerText:this.utilityService.globalCustomerText}),'Activity Service','top',ToastStyle.DANGER);
        return false;
      }
      if (!this.selectedActivity['contacts'].length) {
        let message = this.translate.instant('PLEASE_SELECT') + " "+this.translate.instant('CUSTOMER').toLowerCase() +" " + this.translate.instant('COVERED_IN_THIS_PHONECALL');
        // message=this.translate.instant('NOTIFY_MESSAGE_UNCOMPLETED');
        this.notificationService.notify(message,'Activity Service', 'top', ToastStyle.DANGER);
        return false;
      }
      if (this.selectedActivity.startDate.getTime() > new Date().getTime()) {
        this.notificationService.notify(this.translate.instant('ACTIVITY_PHONECALL_SCHEDULED_IN_FUTURE'),'Activity Service','top',ToastStyle.DANGER);
        return false;
      }
      if ((this.selectedActivity['products'] || this.selectedActivity['activityTherapeuticAreas']) && this.selectedActivity['contacts']) {
        let message = this.translate.instant('PLEASE_SELECT') + " " ;
        const missingData = this.getMissingPhoneCallData();
        const numberOfMissingData = missingData.length;
        if (numberOfMissingData > 0) {
          let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && ((_.isEmpty(this.selectedActivity['activityTherapeuticAreas'])) ? true : !(this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])));
          if (!this.isProductMandatory) {
            isTARequired = false;
          }
          if (numberOfMissingData == 1) {
            const isProdAdded = (this.selectedActivity['products']) ? this.selectedActivity['products'].some(prod => prod['isSelected']) : false;
            const isTAAdded = (this.selectedActivity['products']) ? (this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])): false;
            if ((isProdAdded && missingData[0]===(this.translate.instant('THERAPEUTIC_AREAS').toLowerCase())) ||
              (isTAAdded && missingData[0]===(this.translate.instant('PRODUCT').toLowerCase()))) return true;
            message += missingData[0] + " " + this.translate.instant('COVERED_IN_THIS_PHONECALL');
          } else if(!isTARequired){
            message += missingData.slice(0, numberOfMissingData - 1).join(", ") +
              " "+ this.translate.instant('AND_SMALL') + " " +
              missingData[numberOfMissingData - 1] + " " +
              this.translate.instant('COVERED_IN_THIS_PHONECALL');
          } else {
            message += missingData.slice(0, numberOfMissingData - 1).join(", ") +
              " "+ this.translate.instant('AND_OR') + " " +
              missingData[numberOfMissingData - 1] + " " +
              this.translate.instant('COVERED_IN_THIS_PHONECALL');
          }
          // message=this.translate.instant('NOTIFY_MESSAGE_UNCOMPLETED');
          this.notificationService.notify(message, 'Activity Service', 'top', ToastStyle.DANGER);
          console.log(missingData);
          return false;
        }
      } else {
        // this.notificationService.notify(this.translate.instant('YOU_MUST_HAVE_ONE_PRODUCT_AND_MEETING',{globalCustomerText:this.utilityService.globalCustomerText}),'Activity Service','top',ToastStyle.DANGER);
        return false;
      }
      return true;
    }
    return false;
  }
  public get activityCanComplete(): boolean {
    if (this.selectedActivity) {
      if (this.selectedActivity['contacts'] && this.selectedActivity['contacts'].some(c => (!c.isActive && !c.isguest))) {
        this.notificationService.notify(this.translate.instant('YOU_NO_LONGER_HAVE_ACCESS',{globalCustomerText:this.utilityService.globalCustomerText}),'Activity Service','top',ToastStyle.DANGER);
        return false;
      }

      if (this.selectedActivity.startDate.getTime() > new Date().getTime()) {
        this.notificationService.notify(this.translate.instant('ACTIVITY_MEETING_SCHEDULED_IN_FUTURE'),'Activity Service','top',ToastStyle.DANGER);
        return false;
      }
      // const isMeetingObjectivesRequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVE_SELECTION) ? _.isEmpty(this.selectedActivity['activityObjectives']) : false;
      if ((this.selectedActivity['products'] || this.selectedActivity['activityTherapeuticAreas']) && this.selectedActivity['contacts']) {
        let message = this.translate.instant('PLEASE_SELECT') + " " ;
        const missingData = this.getMissingData(this.selectedActivity);
        const numberOfMissingData = missingData.length;
        if (numberOfMissingData > 0) {
          let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && ((_.isEmpty(this.selectedActivity['activityTherapeuticAreas'])) ? true : !(this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])));
          if (!this.isProductMandatory) {
                 isTARequired = false;
            }
          if (numberOfMissingData == 1) {
            const isProdAdded = (this.selectedActivity['products']) ? this.selectedActivity['products'].some(prod => prod['isSelected']) : false;
            const isTAAdded = (this.selectedActivity['products']) ? (this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])) : false;
            if ((isProdAdded && missingData[0] === (this.translate.instant('THERAPEUTIC_AREAS').toLowerCase())) ||
              (isTAAdded && missingData[0] === (this.translate.instant('PRODUCT').toLowerCase()))) return true;
            message += missingData[0] + " " + this.translate.instant('COVERED_IN_THIS_MEETING');
          } else if (!isTARequired) {
            message += missingData.slice(0, numberOfMissingData - 1).join(", ") +
              " " + this.translate.instant('AND_SMALL') + " " +
              missingData[numberOfMissingData - 1] + " " +
              this.translate.instant('COVERED_IN_THIS_MEETING');
          } else {
            message += missingData.slice(0, numberOfMissingData - 1).join(", ") +
              " " + this.translate.instant('AND_OR') + " " +
              missingData[numberOfMissingData - 1] + " " +
              this.translate.instant('COVERED_IN_THIS_MEETING');
          }
          // message=this.translate.instant('NOTIFY_MESSAGE_UNCOMPLETED');
          this.notificationService.notify(message, 'Activity Service', 'top', ToastStyle.DANGER);
          console.log(missingData);
          return false;
        }
      } else {
        this.notificationService.notify(this.translate.instant('YOU_MUST_HAVE_ONE_PRODUCT_AND_MEETING',{globalCustomerText:this.utilityService.globalCustomerText}),'Activity Service','top',ToastStyle.DANGER);
        return false;
      }
      return true;
    }
    return false;
    // return (this.selectedActivity.state == MeetingActivityState.Completed) || this.selectedActivity['contacts'].length==0 || this.selectedActivity['products'].length==0);
  }

  public appointmentsCanComplete(activities: AppointmentActivity[]): boolean {
    let manadatoryfilled = true;

    if (activities) {
      // activities.forEach(activity => {
      for (let activity of activities) {
        const activityTypes: MeetingActivityType[] = this.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.ACCOUNT_VISIT);
        const isActivityTypeAdded = !_.isEmpty(activity.indskr_activitytype);
        let isContactMandatory = true;
        if (activityTypes.length !== 0) {
          if (isActivityTypeAdded) {
            const selectedActivityType = activityTypes.find(at => at.indskr_activitytypeid === activity.indskr_activitytype);
            if (selectedActivityType) {
              isContactMandatory = selectedActivityType.hcpmandatory == true;
            }
          } else {
            isContactMandatory = activityTypes.some(at => at.hcpmandatory);
          }
        }
        if(!this.checkMandatoryFields(activity)) {
          manadatoryfilled = false;
          break;
        }
        if (isContactMandatory) {
          if (activity['contacts'] && ((activity['contacts'].length === 0) || activity['contacts'].some(c => (!c.isActive && !c.isguest)))) {
            manadatoryfilled = false;
            break;
          }
        }
        if (activity.startDate.getTime() > new Date().getTime()) {
          manadatoryfilled = false;
          break;
        }
        if ((activity['products'] || activity['activityTherapeuticAreas']) && activity['contacts']) {
          // let message = this.translate.instant('PLEASE_SELECT') + " ";
          const missingData = this.getMissingData(activity);
          const numberOfMissingData = missingData.length;
          if (numberOfMissingData > 0) {
            let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && ((_.isEmpty(activity['activityTherapeuticAreas'])) ? true : !(activity['activityTherapeuticAreas'].some(ta => ta['isSelected'])));
            if (!this.isProductMandatory) {
              isTARequired = false;
            }
            if (numberOfMissingData == 1) {
              const isProdAdded = (activity['products']) ? activity['products'].some(prod => prod['isSelected']) : false;
              const isTAAdded = (activity['products']) ? (activity['activityTherapeuticAreas'].some(ta => ta['isSelected'])) : false;
              if ((isProdAdded && missingData[0] === (this.translate.instant('THERAPEUTIC_AREAS').toLowerCase())) ||
                (isTAAdded && missingData[0] === (this.translate.instant('PRODUCT').toLowerCase()))) { manadatoryfilled = true; break; }
              // message += missingData[0] + " " + this.translate.instant('COVERED_IN_THIS_MEETING');
            } else if (!isTARequired) {
              // message += missingData.slice(0, numberOfMissingData - 1).join(", ") +
              //   " " + this.translate.instant('AND_SMALL') + " " +
              //   missingData[numberOfMissingData - 1] + " " +
              //   this.translate.instant('COVERED_IN_THIS_MEETING');
            } else {
              // message += missingData.slice(0, numberOfMissingData - 1).join(", ") +
              //   " " + this.translate.instant('AND_OR') + " " +
              //   missingData[numberOfMissingData - 1] + " " +
              //   this.translate.instant('COVERED_IN_THIS_MEETING');
            }
            this.notificationService.notify(this.translate.instant('ACCOUNT_VISIT_NESTED_MEETINGS_MANADATORY'), 'Activity Service', 'top', ToastStyle.DANGER);
            console.log(missingData);
            manadatoryfilled = false;
            break;
          }
        } else {
          manadatoryfilled = false;
          break;
        }
        manadatoryfilled = true;
        // break;
      }
    }
    if(!manadatoryfilled) {
      this.notificationService.notify(this.translate.instant('ACCOUNT_VISIT_NESTED_MEETINGS_MANADATORY'), 'Activity Service', 'top', ToastStyle.DANGER);
    }
    return manadatoryfilled;
  }

  private checkMandatoryFields(activity) {
    let manadatoryFilled = true;
    if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY)) {
      if (!this.teamViewActive && _.isEmpty(activity['accounts'])) {
        return false;
      }
    }
    if (!_.isEmpty(this.meetingActivityTypes) && _.isEmpty(activity.activityTypeName)) {
      return false;
    }
    if (
      this.shouldShowCovisitorComplianceForm(activity)
      && this.authService.user.isCovisitorComplianceMandatory
      && _.isEmpty(activity.covisitorComplianceDescription)
    ) {
      const formField = this.secondLevelFormFields.find(field => field.id === 'covisitor-compliance');
      if (formField) {
        return false;
      }
    }
    let appointmentConfigFields = [];
    appointmentConfigFields = this.authService.user.getSupportedAppointmentConfiguredFields();
    // to prevent infinite call to the same function.
    // causing when creating a new phone call activity.
    appointmentConfigFields = appointmentConfigFields.filter(field => activity.location !== "LiveMeet");
    // appointmentConfigFields.forEach(field => {
    for (let field of appointmentConfigFields) {
      if (field.mandatory == 'true') {
        if (!field.value) {
          field.isEmptyRequiredField = true;
          break;
        }
        if (field.fieldName == 'indskr_hcpinteraction' && (!activity['contacts'] || activity['contacts'].some(c => (!c.isActive && !c.isguest)))) {
          manadatoryFilled = true;
          break;
        }
      }
    }
    return manadatoryFilled;
  }

  public shouldShowCovisitorComplianceForm(activity): boolean {
    let shouldShow = false;
    if (
      activity instanceof AppointmentActivity
      && (
        (
          activity.state !== MeetingActivityState.Completed
          && this.authService.user.covisitorComplianceCheck
          && Array.isArray(activity.accompaniedUserList)
          && activity.accompaniedUserList.length > 0
        )
        || (
          activity.state === MeetingActivityState.Completed
          && activity.covisitorComplianceAcked === true
        )
      )
    ) {
      shouldShow = true;
    }
    return shouldShow;
  }

  public getRequiredPhoneCallData() {
    const activityTypes = this.configuredActivityTypes(MeetingActivityTypeCode.PHONE_CALL);
    const activitySubTypes = this.getActivitySubTypesByActivityType(MeetingActivityTypeCode.PHONE_CALL, this.selectedActivity.indskr_activitytype);
    const isActivityTypeAdded = !_.isEmpty(this.selectedActivity.indskr_activitytype);
    const isActivitySubTypeAdded = !_.isEmpty(this.selectedActivity.indskr_activitysubtype);
    let isProdAdded = (this.selectedActivity['products']) ? this.selectedActivity['products'].some(prod => prod['isSelected']) : false
    let isTAAdded = (this.selectedActivity['activityTherapeuticAreas']) ? (this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])): false;
    this.isProductMandatory = isActivitySubTypeAdded ? !_.isEmpty(activitySubTypes) && activitySubTypes.find(ast => _.isEqual(ast.indskr_activitysubtypeid, this.selectedActivity.indskr_activitysubtype)).indskr_productmandatory : true;
    let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && _.isEmpty(this.selectedActivity['activityTherapeuticAreas']) ? true : !isProdAdded;
    if (!this.isProductMandatory)
      isTARequired = false;
    let requiredData = {
      'contact-field': { isRequired: true, isAdded: this.selectedActivity['contacts'].length > 0 ? true : false},
      'phone-call-product-key-messages' : {isRequired: this.isProductMandatory && (!isTAAdded || (isTAAdded&&isProdAdded)), isAdded: isProdAdded},
      'phone-call-therapeutic-area' : {isRequired: isTARequired && !isProdAdded, isAdded: isTAAdded},
      'phonecall-activityType-field' : {isRequired: activityTypes.some(at => at.indskr_mandatory), isAdded: !_.isEmpty(this.selectedActivity.indskr_activitytype)},
      'phonecall-activitySubType-field' : {isRequired: isActivityTypeAdded && !_.isEmpty(activitySubTypes) && activitySubTypes.some(ast => ast.indskr_mandatory), isAdded: !_.isEmpty(this.selectedActivity.indskr_activitysubtype)}
    }
    return requiredData;
  }

  /**
   * getMissingPhoneCallData().
   * @description
   * It returns the list of the missing required data
   *when we try to mark complete the phone call without
   *choosing the products,TA
  */
  private getMissingPhoneCallData(): string[]{
    const isContAdded = (this.selectedActivity['contacts'].length > 0) ? true : false;
    let isProdAdded = (this.selectedActivity['products']) ? this.selectedActivity['products'].some(prod => prod['isSelected']) : false;
    let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && ((_.isEmpty(this.selectedActivity['activityTherapeuticAreas'])) ? true : !(this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])));
    const activityTypes = this.configuredActivityTypes(MeetingActivityTypeCode.PHONE_CALL);
    const activitySubTypes = this.getActivitySubTypesByActivityType(MeetingActivityTypeCode.PHONE_CALL, this.selectedActivity.indskr_activitytype);
    const isActivityTypeMandatory: boolean = activityTypes.some(at => at.indskr_mandatory);
    const isActivityTypeAdded = !_.isEmpty(this.selectedActivity.indskr_activitytype);
    const isActivitySubTypeMandatory: boolean = isActivityTypeAdded && !_.isEmpty(activitySubTypes) && activitySubTypes.some(ast => ast.indskr_mandatory);
    const isActivitySubTypeAdded = !_.isEmpty(this.selectedActivity.indskr_activitysubtype);
    this.isProductMandatory = isActivitySubTypeAdded ? !_.isEmpty(activitySubTypes) && activitySubTypes.find(ast => _.isEqual(ast.indskr_activitysubtypeid, this.selectedActivity.indskr_activitysubtype)).indskr_productmandatory : true;
    if (!this.isProductMandatory) {
      isProdAdded = true;
      isTARequired = false;
    }
    return  [ isContAdded ? "" : this.utilityService.globalCustomerText.toLowerCase(),
      isProdAdded ? "" : this.translate.instant('PRODUCT').toLowerCase(),
      isTARequired ? this.translate.instant('THERAPEUTIC_AREAS').toLowerCase() : "",
      isActivityTypeMandatory && !isActivityTypeAdded ? this.translate.instant('FORMAT').toLowerCase() : "",
      isActivitySubTypeMandatory && !isActivitySubTypeAdded? this.translate.instant('FORMAT_DETAILS').toLowerCase() : ""
    ].filter(Boolean);
  }

  getActivitySubTypesByActivityType(type: MeetingActivityTypeCode, activityType: string): MeetingSubActivityType[] {
    return this.configuredActivitySubTypes(type).filter(ast => _.isEqual(ast.indskr_activitytype, activityType));
  }

  public getRequiredData(activity: AppointmentActivity) {
    const record = this.accountVisitRecordCheck(activity);
    const formatType = record.isAccountVisitRecord || record.isAccountVisitNestedMeeting ? FormatType.ACCOUNT_VISIT : FormatType.HCP_MEETING;
    const activityTypes: MeetingActivityType[] = this.configuredActivityTypes(MeetingActivityTypeCode.MEETING, formatType);
    const activitySubTypes = this.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, this.selectedActivity.indskr_activitytype);
    const isActivityTypeAdded = !_.isEmpty(this.selectedActivity.indskr_activitytype);
    let isContactMandatory = true;
    if (activityTypes.length !== 0) {
      if (isActivityTypeAdded) {
        const selectedActivityType = activityTypes.find(at => at.indskr_activitytypeid === this.selectedActivity.indskr_activitytype);
        if (selectedActivityType) {
          isContactMandatory = selectedActivityType.hcpmandatory == true;
        }
      } else {
        isContactMandatory = activityTypes.some(at => at.hcpmandatory);
      }
    }
    const isActivitySubTypeAdded = !_.isEmpty(this.selectedActivity.indskr_activitysubtype);
    let isProdAdded = (this.selectedActivity['products']) ? this.selectedActivity['products'].some(prod => prod['isSelected']) : false
    let isTAAdded = (this.selectedActivity['activityTherapeuticAreas']) ? (this.selectedActivity['activityTherapeuticAreas'].some(ta => ta['isSelected'])): false;
    if(isActivitySubTypeAdded) {
      if(!_.isEmpty(activitySubTypes)) {
        const foundActSubType = activitySubTypes.find(ast => _.isEqual(ast.indskr_activitysubtypeid, this.selectedActivity.indskr_activitysubtype));
        if(foundActSubType) this.isProductMandatory = foundActSubType.indskr_productmandatory;
      }
    }else {
      this.isProductMandatory = true;
    }
    this.isProductMandatory = (this.authenticationService.hasFeatureAction(FeatureActionsMap.SET_PRODUCTS_TO_BE_OPTIONAL_SECTION) || this.authenticationService.user.buConfigs?.indskr_disableproductsection == true) ? false : this.isProductMandatory;
    let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.SET_THERAPEUTIC_AREAS_TO_BE_OPTIONAL_SECTION) ? false : this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && _.isEmpty(this.selectedActivity['activityTherapeuticAreas']) ? true : !isProdAdded;
    let meetingTypeRequired = (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_TYPE) && !this.selectedActivity['indskr_meetingtype'])
    if (!this.isProductMandatory) {
      isTARequired = false;
      meetingTypeRequired = false;
    } else if(isActivitySubTypeAdded || (!_.isEmpty(activityTypes) && !_.isEmpty(activitySubTypes))) {
      meetingTypeRequired = false;
    }
    let requiredData:any = {
      'account-field': { isRequired: true, isAdded: Array.isArray(this.selectedActivity['accounts']) && this.selectedActivity['accounts'].length > 0 ? true : false },
      'contact-field': { isRequired: isContactMandatory, isAdded: this.selectedActivity['contacts'].length > 0 ? true : false},
      'meeting-type-field' : { isRequired: meetingTypeRequired, isAdded: this.selectedActivity['indskr_meetingtype']},
      'product-key-messages' : {isRequired: this.isProductMandatory && (!isTAAdded || (isTAAdded && isProdAdded)), isAdded: isProdAdded},
      'therapeutic-area' : {isRequired: isTARequired && !isProdAdded, isAdded: isTAAdded},
      'meeting-activityType-field' : {isRequired: activityTypes.some(at => at.indskr_mandatory), isAdded: !_.isEmpty(this.selectedActivity.indskr_activitytype)},
      'meeting-activitySubType-field' : {isRequired: isActivityTypeAdded && !_.isEmpty(activitySubTypes) && activitySubTypes.some(ast => ast.indskr_mandatory), isAdded: !_.isEmpty(this.selectedActivity.indskr_activitysubtype)},
      'covisitor-compliance' : {
        isRequired: activity.state !== MeetingActivityState.Completed
                    ? this.authenticationService.user.isCovisitorComplianceMandatory
                    : false,
        isAdded: !!activity.covisitorComplianceAcked,
        isEvaluated: false,
      }
    }

      if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY)){
        requiredData = {...requiredData, 'account-field': { isRequired: true, isAdded: this.selectedActivity['accounts'].length > 0 ? true : false }}
      }
    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN)){
      requiredData = { ...requiredData, 'account-field' :  { isRequired: true, isAdded: this.selectedActivity['accounts'].length > 0 ? true : false}}
    }

    return requiredData;
  }

  private getMissingData(activity) : string[]{
    let meetingTypeRequired = (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_TYPE) && !activity['indskr_meetingtype'])

    let isAccountsAdded =  true;
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY)) {
      isAccountsAdded = (activity['accounts'].length > 0) ? true : false;
    }

    const isContAdded = (activity['contacts'].length > 0) ? true : false;
    let isProdAdded = (activity['products']) ? activity['products'].some(prod => prod['isSelected']) : false;
    const record = this.accountVisitRecordCheck(activity as AppointmentActivity);
    const formatType = record.isAccountVisitRecord || record.isAccountVisitNestedMeeting ? FormatType.ACCOUNT_VISIT : FormatType.HCP_MEETING;
    const activityTypes = this.configuredActivityTypes(MeetingActivityTypeCode.MEETING, formatType);
    const activitySubTypes = this.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, activity.indskr_activitytype);
    const isActivityTypeMandatory: boolean = activityTypes.some(at => at.indskr_mandatory);
    const isActivityTypeAdded = !_.isEmpty(activity.indskr_activitytype);
    let isContactMandatory = true;
    if (activityTypes.length !== 0) {
      if (isActivityTypeAdded) {
        const selectedActivityType = activityTypes.find(at => at.indskr_activitytypeid === activity.indskr_activitytype);
        if (selectedActivityType) {
          isContactMandatory = selectedActivityType.hcpmandatory == true;
        }
      } else {
        isContactMandatory = activityTypes.some(at => at.hcpmandatory);
      }
    }
    const isActivitySubTypeMandatory: boolean = isActivityTypeAdded && !_.isEmpty(activitySubTypes) && activitySubTypes.some(ast => ast.indskr_mandatory);
    const isActivitySubTypeAdded = !_.isEmpty(activity.indskr_activitysubtype);
    this.isProductMandatory = (this.authenticationService.hasFeatureAction(FeatureActionsMap.SET_PRODUCTS_TO_BE_OPTIONAL_SECTION) || this.authenticationService.user.buConfigs?.indskr_disableproductsection == true) ? false : isActivitySubTypeAdded ? !_.isEmpty(activitySubTypes) && activitySubTypes.find(ast => _.isEqual(ast.indskr_activitysubtypeid, activity.indskr_activitysubtype)).indskr_productmandatory : true;
    let isTARequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.SET_THERAPEUTIC_AREAS_TO_BE_OPTIONAL_SECTION) ? false : this.authenticationService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) && ((_.isEmpty(activity['activityTherapeuticAreas'])) ? true : !(activity['activityTherapeuticAreas'].some(ta => ta['isSelected'])));
    // const isMeetingObjectivesRequired = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVE_SELECTION) ? _.isEmpty(this.selectedActivity['activityObjectives']) : false;
    if (!this.isProductMandatory) {
      isProdAdded = true;
      isTARequired = false;
      meetingTypeRequired = false;
    } else if(isActivitySubTypeAdded || (!_.isEmpty(activityTypes) && !_.isEmpty(activitySubTypes))) {
      meetingTypeRequired = false;
    }

    let isAccountAdded = true;
    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN)){
      isAccountAdded =  activity['accounts'].length > 0 ;
    }

    return [
      meetingTypeRequired ? this.translate.instant('MEETING_TYPE').toLowerCase() : "",
      isContactMandatory && !isContAdded ? this.utilityService.globalCustomerText.toLowerCase() : "",
      isAccountsAdded ? "" : this.translate.instant('ACCOUNT').toLowerCase(),
      isProdAdded ? "" : this.translate.instant('PRODUCT').toLowerCase(),
      isTARequired ? this.translate.instant('THERAPEUTIC_AREAS').toLowerCase() : "",
      isActivityTypeMandatory && !isActivityTypeAdded ? this.translate.instant('FORMAT').toLowerCase() : "",
      isActivitySubTypeMandatory && !isActivitySubTypeAdded? this.translate.instant('FORMAT_DETAILS').toLowerCase() : "",
      // isMeetingObjectivesRequired ? this.translate.instant("OBJECTIVES").toLowerCase() : "",
      isAccountAdded ? "" : this.translate.instant('ACCOUNT').toLowerCase()
    ].filter(Boolean);
  }

  public updateTimeOffRecords(data: TimeOffActivity) {
    let activityIndex = this.activities.findIndex(e => e['timeOffRequestId'] == data.timeOffRequestId);
    if (activityIndex < 0) {
      activityIndex = this.activities.findIndex(e => e['offlineTimeOffRequestId'] === data.offlineTimeOffRequestId);
    }

    if (activityIndex > -1) {
      this.activities[activityIndex] = data;
    } else if (activityIndex === -1) {
      // As activities need to be kept sorted
      // need to insert at the right place when adding.
      let idx = _.sortedIndexBy(this.activities, data, 'scheduledStart');
      if (idx < 0) {
        idx = 0;
      }
      this.activities.splice(idx, 0, data);
    }
    this.filterActivities(this.activityFilter);
    if (!this.uiService.toolsActivityActive)
    {
      this.events.publish("refreshAgenda");
    }
    else {
      this.uiService.agendaRefreshRequired = true;
      this.signalActivityConflictCheck$.next(data);
    }
}

  public get accompaniedString(): string {

    if (this.selectedActivity != undefined
      && this.selectedActivity != null
      && this.selectedActivity["accompaniedUserList"].length > 0
    ) {
      let names: string[] = this.selectedActivity["accompaniedUserList"].map(e => e.name);
      console.log(names);
      return names.join(",");
    }
    else
      return "Select Covisitors";
  }

  public async mapAccompaniedUserList(data: IrawAccompainedUser[], isOnline?: boolean) {
    this.repUserList = [];
    if (isOnline && Array.isArray(data)) {
      console.log("mapping user list for current rep");
      data.forEach(e => {
        //since the service is returning duplicate data informed vipin
        //check if the id exist, if not then push
        let idx = this.repUserList.findIndex(el => el.id === e.userid);
        if (idx < 0) {
          this.repUserList.push(new AccompainedUser(e));
        }
        else {
          console.log("duplicate data from server detected, report MSE");
        }
      });
      //save bulk data into the disk for offline purpose
      this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOMPANIED_USERS, doc => ({ raw: JSON.stringify(data) }))
        .catch(error => {
          console.error('mapAccompaniedUserList: ', error);
        });
    }
    //offline
    else {
      console.log("mapping offline user list for current rep");
      data.forEach(e => {
        //since the service is returning duplicate data informed vipin
        //check if the id exist, if not then push
        let idx = this.repUserList.findIndex(el => el.id === e.userid);
        if (idx < 0) {
          this.repUserList.push(new AccompainedUser(e));
        }
        else {
          console.log("duplicate data from server detected, report MSE");
        }
      });
    }


    if (this.repUserList.length > 0) {
      this.sortJointUserList();
    }
  }

  public sortJointUserList() {
    this.repUserList.sort(function (a, b) {
      let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });
  }

  public async setAccompaniedUser(value: AccompainedUser[]) {
    // We don't want to overwrite the joinstatus values everytime we update accompanied user
    let clonedUserList = JSON.parse(JSON.stringify(value));
    if (Array.isArray(clonedUserList) && Array.isArray(this.selectedActivity["accompaniedUserList"])) {
      for (let i = 0; i < clonedUserList.length; i++) {
        const user: AccompainedUser = clonedUserList[i];
        const oUser: AccompainedUser = this.selectedActivity["accompaniedUserList"].find(u => u.id === user.id);
        if (oUser) {
          user.remoteMeetingJoinStatus = oUser.remoteMeetingJoinStatus;
        }
      }
    } else {
      clonedUserList = [];
    }

    this.selectedActivity["accompaniedUserList"] = clonedUserList;

    if (clonedUserList.length === 0) {
      this.selectedActivity["accompaniedUserString"] = "";
      this.selectedActivity["isJointmeeting"] = false;
    }
    else {
      //TC-231
      let distinctValues = Array.from(new Set(clonedUserList.map(s => s.id))).map(
        id => {
          return {
            id: id,
            name: clonedUserList.find(s => s.id === id).name
          }
        });

      let names = distinctValues.map(e => e.name);

      this.selectedActivity["isJointmeeting"] = true;

      if (names.length <= 1) {
        this.selectedActivity["accompaniedUserString"] = names.join(",");
      }
      else {
        this.selectedActivity["accompaniedUserString"] = names[0] + " +" + (names.length - 1);
      }
    }

    //Added support for TC-294 and TC-296
    let currentActivity: any = this.selectedActivity;
    //update display activity list
    let dispIdx = this.displayActivities.findIndex(e => e.ID === this.selectedActivity.ID);
    this.displayActivities[dispIdx] = this.selectedActivity;

    //Update Appointment activity list
    // let appointmentIdx = this.appointmentActivites.findIndex(e => e.ID === this.selectedActivity.ID);
    // this.appointmentActivites[appointmentIdx] = currentActivity;

    //Update Appointment activity list
    let accIdx = this.activities.findIndex(e => e.ID === this.selectedActivity.ID);
    this.activities[accIdx] = currentActivity;

    this.filterActivities(this.activityFilter);
    if (!this.uiService.toolsActivityActive){
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }

  hasOfflinePhoneCallData(id: string) {
    return this.offlinePhoneCallActivityIds.has(id);
  }

  hasOfflineMeetingData(id: string) {
    return this.offlineMeetingIds.has(id);
  }

  hasOfflineEmailData(id: string) {
    return this.offlineEmailActivityIds.has(id);
  }

  addToOfflineMeetingIds(id: string) {
    this.offlineMeetingIds.set(id, true);
  }

  addToOfflinePhoneCallActivityIds(id: string) {
    this.offlinePhoneCallActivityIds.set(id, true);
  }

  addToOfflineEmailActivityIds(id: string) {
    this.offlineEmailActivityIds.set(id, true);
  }

  deleteFromOfflineMeetingIds(id: string) {
    this.offlineMeetingIds.delete(id);
  }

  deleteFromOfflinePhoneCallIds(id: string) {
    this.offlinePhoneCallActivityIds.delete(id);
  }

  addToOfflineSampleOrderIds(key: string) {
    this.offlineSamplingIds.set(key, true)
  }

  initOfflineSampleOrderIds(ids: Array<string>) {
    ids.forEach(value => {
      this.offlineSamplingIds.set(value, true);
    });
  }

  deleteFromOfflineSampleOrderIds(key: string) {
    this.offlineSamplingIds.delete(key);
  }

  deleteFromOfflineEmailIds(key: string) {
    this.offlineEmailActivityIds.delete(key);
  }

  hasOfflineSampleOrderData(activityId: string) {
    return this.offlineSamplingIds.has(activityId);
  }

  async cancelRemoteMeeting() {
      let alert = await this.alertCtrl.create({
        header: this.translate.instant('ACTIVITY_SWITCH_MEETING'),
        message: this.translate.instant('ACTIVITY_SWITCHING_TO_NEW_MEETING_DISCONNECT_YOUR_CURRENT_PARTICIPANT'),
        buttons: [
          {
            text: this.translate.instant('CANCEL'),
            role: 'cancel',
          },
          {
            text: this.translate.instant('YES'),
            role:'ok'
          }]
      });

      if (!this.device.isOffline &&
          this.selectedActivity && this.selectedActivity instanceof AppointmentActivity &&
          this.isThereConnectedContactsForCurrentMeeting(this.selectedActivity, true)
      ) {
        await alert.present();
        return (await alert.onDidDismiss()).role=='ok'
      } else {
        return true;
      }

  }

  public validateMeetingDate(currentDate):Boolean{
    let diffDays = differenceInCalendarDays(new Date(), currentDate);
      if (diffDays > this.authenticationService.user.maxMeetingStartDays) {
        this.notificationService.notify(this.translate.instant('YOU_CANT_SCHEDULE_MEETING_MORE_THAN_TEXT_DAYS_AGO',{text:this.authenticationService.user.maxMeetingStartDays}),'Activity Service','top',ToastStyle.DANGER);
        return false;
      } else {
        return true;
      }
  }

  public validateTimeOffDate(currentDate):Boolean{
    let diffDays = differenceInCalendarDays(new Date(), currentDate);
      if (diffDays > this.authenticationService.user.pastTimeOffGracePeriod) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_TIMEOFF',{maxTimeOffStartDays:this.authenticationService.user.pastTimeOffGracePeriod}),'Activity Service','top',ToastStyle.DANGER);
        return false;
      } else {
        return true;
      }
  }

  updateInquiryActivity(data: CaseActivity) {

  }

  public validateProcedureDate(currentDate, message: string): Boolean {
    let diffDays = differenceInCalendarDays(new Date(), currentDate);
    if (diffDays >= this.authenticationService.user.maxMeetingStartDays) {
      this.notificationService.notify(this.translate.instant(message, { Eventstartdays: this.authenticationService.user.maxMeetingStartDays }), 'Activity Service', 'top', ToastStyle.DANGER);
      return false;
    } else {
      return true;
    }
  }

  getPresentationsDTO(presentations: ActivityPresentation[]) {
    let presMainobj = {
      activityPresentations: [],
    };

    if (Array.isArray(presentations) && presentations.length > 0) {
      presMainobj['activityPresentations'] = [];
      presentations.forEach(pres => {
        let presObj = {
          activityPresentationSlides: []
        };
        presObj.activityPresentationSlides = []

        if (pres.activityPresentationSlides.length > 0) {
          pres.activityPresentationSlides.forEach(slide => {
            let slideObj = {
              activityPresentationSlideContacts: []
            };
            slideObj['activityPresentationSlideContacts'] = [];

            if (slide['activityPresentationSlideContacts'].length > 0) {
              slide['activityPresentationSlideContacts'].forEach(slideContact => {
                let obj = {
                  "indskr_contactid": slideContact['contactid'],
                  "indskr_pagesentiment": slideContact['pagesentiment']
                }
                slideObj['activityPresentationSlideContacts'].push(obj);
              });
            }
            slideObj['indskr_slideid'] = slide['ckmpageid'];
            slideObj['indskr_name'] = slide['name'];
            slideObj['indskr_endtime'] = slide['endtime'];
            slideObj['indskr_starttime'] = slide['starttime'];
            slideObj['indskr_slidepath'] = slide['ckmpageurl'];
            presObj.activityPresentationSlides.push(slideObj);
          });
        }
        presObj['indskr_iopresentationid'] = pres['presentationId'];
        presObj['indskr_iopresentationname'] = pres['name'];
        presObj['indskr_name'] = pres['name'];
        presMainobj.activityPresentations.push(presObj);
      });
    }
    return presMainobj.activityPresentations;
  };

  hasAnyCompletedAccountVisitNestedMeeting(accountVisit: AppointmentActivity): boolean {
    if (
      !accountVisit
    ) {
      return false;
    }

    return this.activities.some(a => (a as AppointmentActivity).indskr_parentcallid === accountVisit.ID && a.state === 1);
  }

  private _getActivityScrapStatus(activity:Activity): ActivityScrapStatus {
    let scrapStatus:ActivityScrapStatus = ActivityScrapStatus.CANBESCRAPPED;
    if(activity.type == ActivityType.Appointment && activity.ID){
      const isAccountVisitNestedMeeting = !!((activity as AppointmentActivity).indskr_parentcallid);
      if (isAccountVisitNestedMeeting) {
        // Has completed nested meeting under the account visit
        if (activity.state === 1) {
          return ActivityScrapStatus.HAS_COMPLETED_ACCOUNT_VISIT_NESTED_MEETING;
        }
      }

      // Check the status of In Meeting Allocations
      for(let idx=0;idx<this.sampleActivities.length;idx++){
        let sampleOrder:SampleActivity = this.sampleActivities[idx];
        if(sampleOrder.appointmentID && sampleOrder.appointmentID === activity.ID){
          if(sampleOrder.statusString == 'Completed'){
            scrapStatus = !isAccountVisitNestedMeeting
              ? ActivityScrapStatus.HASCOMPLETEDINMEETINGALLOCATIONS
              : ActivityScrapStatus.HAS_COMPLETED_IN_MEETING_ACTIVITY_IN_ACCOUNT_VISIT_NESTED_MEETING;
            break;
          }else{
            scrapStatus = ActivityScrapStatus.HASOPENINMEETINGALLOCATIONS;
          }
        }
      };
      this.activities.filter(a=> a.type == ActivityType.SurgeryOrder).forEach((surgeryOrder:SurgeryOrderActivity)=> {
        if((surgeryOrder.appointmentId && surgeryOrder.appointmentId == activity.ID) || (surgeryOrder.offlineMeetingId && surgeryOrder.offlineMeetingId == activity.ID)){
          if(surgeryOrder.surgeryOrderStatusString === 'Completed'){
            scrapStatus = !isAccountVisitNestedMeeting
              ? ActivityScrapStatus.HASCOMPLETEDINMEETINGSURGERYORDERS
              : ActivityScrapStatus.HAS_COMPLETED_IN_MEETING_ACTIVITY_IN_ACCOUNT_VISIT_NESTED_MEETING;
          }else{
            scrapStatus = ActivityScrapStatus.HASOPENINMEETINGSURGERYORDERS;
          }
        }
      });
      this.activities.filter(a=> a.type == ActivityType.FollowUp).forEach((followup:FollowUpActivity)=> {
        if((followup.appointmentId && followup.appointmentId == activity.ID) || (followup.offlineMeetingId && followup.offlineMeetingId == activity.ID)){
          if(followup.status === 5){
            scrapStatus = !isAccountVisitNestedMeeting
              ? ActivityScrapStatus.HASCOMPLETEDINMEETINGFOLLOWUPS
              : ActivityScrapStatus.HAS_COMPLETED_IN_MEETING_ACTIVITY_IN_ACCOUNT_VISIT_NESTED_MEETING;
          }else{
            scrapStatus = ActivityScrapStatus.HASOPENINMEETINGFOLLOWUPS;
          }
        }
      });
    }
    return scrapStatus;
  }

  checkAndNotifyScrapProcessHalt(scrapStatus: ActivityScrapStatus, doNotNotify = false): boolean {
    let shouldHaltScrap = false;
    let notifyString: string;

    switch (scrapStatus) {
      case ActivityScrapStatus.HASCOMPLETEDINMEETINGALLOCATIONS:
        notifyString = this.translate.instant('DRAFT_MEETING_HAS_COMPLETED_ALLOCATIONS_SCRAP_MESSAGE');
        shouldHaltScrap = true;
        break;
      case ActivityScrapStatus.HASCOMPLETEDINMEETINGSURGERYORDERS:
        notifyString = this.translate.instant('DRAFT_MEETING_HAS_COMPLETED_PROCEDURE_LOG_SCRAP_MESSAGE');
        shouldHaltScrap = true;
        break;
      case ActivityScrapStatus.HASCOMPLETEDINMEETINGFOLLOWUPS:
        notifyString = this.translate.instant('DRAFT_MEETING_HAS_COMPLETED_FOLLOWUP_SCRAP_MESSAGE');
        shouldHaltScrap = true;
        break;
      case ActivityScrapStatus.HAS_COMPLETED_ACCOUNT_VISIT_NESTED_MEETING:
        notifyString = this.translate.instant('ACCOUNT_VISIT_HAS_COMPLETED_NESTED_MEETING_SCRAP_MESSAGE');
        shouldHaltScrap = true;
        break;
      case ActivityScrapStatus.HAS_COMPLETED_IN_MEETING_ACTIVITY_IN_ACCOUNT_VISIT_NESTED_MEETING:
        notifyString = this.translate.instant('ACCOUNT_VISIT_HAS_COMPLETED_IN_MEETING_ACTIVITY_IN_NESTED_MEETING_SCRAP_MESSAGE');
        shouldHaltScrap = true;
        break;

      default:
        break;
    }

    if (!doNotNotify && notifyString) {
      this.notificationService.notify(notifyString, 'ActivityScrap');
    }

    return shouldHaltScrap;
  }

  getAccountVisitNestedMeetingsScrapStatus(
    accountVisitNestedMeetings: AppointmentActivity[],
    notifyScrapHalt = false,
  ): { shouldHalt: boolean, scrapStatusResponse: { [key: string]: ActivityScrapStatus }} {
    let response: { shouldHalt: boolean, scrapStatusResponse: { [key: string]: ActivityScrapStatus }} = {
      shouldHalt: false,
      scrapStatusResponse: {},
    };

    for (let i = 0; i < accountVisitNestedMeetings.length; i++) {
      const nestedMeeting = accountVisitNestedMeetings[i];
      response.scrapStatusResponse[nestedMeeting.ID] = this._getActivityScrapStatus(nestedMeeting);
      if (this.checkAndNotifyScrapProcessHalt(response.scrapStatusResponse[nestedMeeting.ID], !notifyScrapHalt)) {
        response.shouldHalt = true;
        break;
      }
    }

    return response;
  }
  getAccountVisitScrapStatus(activity: AppointmentActivity): { [key: string]: ActivityScrapStatus } {
    let response: { [key: string]: ActivityScrapStatus } = {};
    response[activity.ID] = ActivityScrapStatus.CANBESCRAPPED;

    // First check parent level
    response[activity.ID] = this._getActivityScrapStatus(activity);

    let shouldStop = this.checkAndNotifyScrapProcessHalt(response[activity.ID], true);
    // Check nested meetings if parent is okay
    if (!shouldStop) {
      const nestedMeetings: AppointmentActivity[] = this.getAccountVisitNestedMeetings(activity.ID);
      const nestedMeetingScrapStatusResponse = this.getAccountVisitNestedMeetingsScrapStatus(nestedMeetings);
      if (nestedMeetingScrapStatusResponse.scrapStatusResponse) {
        response = {
          ...response,
          ...nestedMeetingScrapStatusResponse.scrapStatusResponse,
        };
      }
    }

    return response;
  }

  getActivityScrapStatus(activity: Activity): { [key: string]: ActivityScrapStatus } {
    let response: { [key: string]: ActivityScrapStatus } = {};
    response[activity.ID] = ActivityScrapStatus.CANBESCRAPPED;
    // Check account visit with nested meetings separately
    if (
      activity instanceof AppointmentActivity
      && activity.indskr_isparentcall
    ) {
      response = this.getAccountVisitScrapStatus(activity);
    } else {
      // Rest of the activities
      response[activity.ID] = this._getActivityScrapStatus(activity);
    }
    return response;
  }

  // updates email address object in local db and activities array in activity service
  public async updateEmailAddressInEmailActivityParties(emailActivity: EmailActivity) {
    const emailActivityId = (emailActivity.parentemailid ? emailActivity.parentemailid: emailActivity.activityid);
    let emailActivityIndex = this.activities.findIndex((a) => a.ID  === emailActivity.activityid);
    if(emailActivityIndex >= 0) {
      const updatedEmail = this.replaceEmailAddressObject(this.activities[emailActivityIndex]);
      this.addActivity(updatedEmail, true);
    }
    await this.updateEmailAddressInEmailActivityPartiesInDB(emailActivityId);
  }

  // update local db
  private async updateEmailAddressInEmailActivityPartiesInDB(emailActivityId: string) {
    const key = DB_KEY_PREFIXES.EMAIL_ACTIVITY + emailActivityId;
    const email = await this.disk.retrieve(key);
    if(email) {
      await this.disk.updateOrInsert(key, (email) => {
        return this.replaceEmailAddressObject(email);
      });
    }
  }

  replaceEmailAddressObject(email) {
    let emailActivity = new EmailActivity(email);
    const index = emailActivity.emailActivityParties.findIndex((eap) => eap.emailActivityId ===  emailActivity.activityid);
    if (index >=  0) {
      emailActivity.emailActivityParties[index].emailAddresses = emailActivity.emailActivityParties[0].emailAddresses;
    }
    return emailActivity;
  }

  isThereConnectedContactsForCurrentMeeting(activity: AppointmentActivity, checkIncludingCoVisitor = false): boolean {
    let isAnyoneConnected = false;

    if (Array.isArray(activity?.contacts)) {
        isAnyoneConnected = activity.contacts.some(c => (c.isremote || c.isguest) && c.connectionState === ContactMeetingState.JOINED);
    }

    if (checkIncludingCoVisitor && Array.isArray(activity?.accompaniedUserList) && !isAnyoneConnected) {
        isAnyoneConnected = activity.accompaniedUserList.some(u => u.remoteMeetingJoinStatus === ContactMeetingState.JOINED);
    }

    return isAnyoneConnected;
  }


  /**
   * Activity list view port snapshot helper functions
   */
  storeSnapshot(snapshotItems: any[]) {
    this.disk.updateOrInsert(DB_KEY_PREFIXES.ACTIVITY_SNAPSHOT, doc => ({ snapshotItems }));
  }
  async restoreSnapshot() {
    const snapshot = await this.disk.retrieve(DB_KEY_PREFIXES.ACTIVITY_SNAPSHOT, true);
    this.snapshot = snapshot && snapshot.snapshotItems ? snapshot.snapshotItems : undefined;
  }

  /**
   * Return formated date string based on activity time
   */
   getFormattedMeetingTimeText(): string {
    if (!this.selectedActivity) return '';
    let meetingDay;
    meetingDay = this.datePipe.transform(this.selectedActivity.scheduledStart, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    // meetingDay = format(this.selectedActivity.scheduledStart, this.dateTimeFormatsService.dateToUpper, {locale : this.translate.currentLang});
    let startDate: Date = new Date(this.selectedActivity.scheduledStart);

    let endDate: Date = new Date(this.selectedActivity.scheduledEnd);

    let meetingStartTime = startDate.toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });

    let meetingEndTime = endDate.toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });

    if (differenceInDays(endDate, startDate) > 0) {
      //new format with days only
      return meetingDay + " " + meetingStartTime + "-" + format(endDate, this.dateTimeFormatsService.dateToUpper) + " " + meetingEndTime;
    }

    return meetingDay + " " + meetingStartTime + "-" + meetingEndTime;
  }

  /**
   * Notify calendar invite update when following appointment attributes are updated
   * Subject, Time, Attendees, Location
   */
  notifyCalendarInviteUpdate(activity: AppointmentActivity) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_CALENDAR_INVITE)
        && activity
        && activity.isInviteSent === DynamicsBoolean.true) {
      this.notificationService
      .notify(
        this.translate.instant('CALENDAR_INVITE_UPDATE_NOTIFICATION'),
        'Meeting Details',
        'top',
        ToastStyle.INFO
      );
    }
  }

  getActivityDateRange(activity) {
    if (activity)
      return this.datePipe.transform(activity.scheduledStart, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) +
        ' - ' + this.datePipe.transform(activity.scheduledEnd, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  getFormattedDateTimeText(): any {
    if (!this.selectedActivity) return '';
    const mStart = moment(this.selectedActivity.scheduledStart);
    const mEnd = moment(this.selectedActivity.scheduledEnd);
    if (!mStart.isValid() || !mEnd.isValid()) return '';

    let startDateTimeValue: Date = new Date(this.selectedActivity.scheduledStart);
    let endDateTimeValue: Date = new Date(this.selectedActivity.scheduledEnd);
    let meetingStartDay = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let meetingEndDay = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let formattedDuration = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

    let dateTimeValue: any = {
      startDateTime: '',
      startDate: '',
      startTime: '',
      endDateTime: '',
      endDate: '',
      endTime: '',
      duration: '',
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: meetingStartDay,
      startTime: meetingStartTime,
      endDateTime: endDateTimeValue,
      endDate: meetingEndDay,
      endTime: meetingEndTime,
      duration: formattedDuration,
    };
    return dateTimeValue;
  }

  public  getDefaultMeetingActivityType() {
    const activityTypes = this.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.HCP_MEETING);
    let data = null;
    if (!_.isEmpty(activityTypes)) {
      this.logService.logDebug("Creating meeting with activity type");
      const defaultActivityType = activityTypes.length == 1 && activityTypes[0].indskr_mandatory ? activityTypes[0] : activityTypes.find(at => at.indskr_default);
      if (!_.isEmpty(defaultActivityType)) {
        data = { indskr_activitytype: defaultActivityType.indskr_activitytypeid,  activityTypeName: defaultActivityType.indskr_name};
        const activitySubTypes = this.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, defaultActivityType.indskr_activitytypeid);
        if (!_.isEmpty(activitySubTypes)) {
          const defaultActivitySubType = activitySubTypes.length == 1 ? (activitySubTypes[0].indskr_mandatory ? activitySubTypes[0] : null) : activitySubTypes.find(ast => ast.indskr_default);
          if (!_.isEmpty(defaultActivitySubType)) {
            data["indskr_activitysubtype"] = defaultActivitySubType.indskr_activitysubtypeid;
            data["activitySubTypeName"] = defaultActivitySubType.indskr_name;
          }
        }
      }
    }
    return data;
  }

  // Filter the Incomplete Activities
  getAllPastActivities() {
    let systemUserID = this.authenticationService.user.systemUserID;
    const isAccountVisitEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT);
    return this.activities.filter(activity => {
      let otherConditions = false
      if (activity.type === ActivityType.Appointment) {
        otherConditions = activity.ownerId === systemUserID
          && !(isAccountVisitEnabled && (activity as AppointmentActivity).indskr_parentcallid);
      } else if (activity.type === ActivityType.FollowUp) {
        let followupActivity = activity as FollowUpActivity;
        otherConditions = followupActivity.ownerId === systemUserID || followupActivity.assignedTo.some(u => u.userId === systemUserID)
      } else if (activity.type === ActivityType.SurgeryOrder || activity.type === ActivityType.Order) {
        otherConditions = activity.ownerId === systemUserID;
      } else if (activity.type === ActivityType.PhoneCall) {
        otherConditions = activity.ownerId === systemUserID;
      } else if (activity.type === ActivityType.Email) {
        otherConditions = activity.ownerId === systemUserID;
      } else if (activity.type === ActivityType.CaseIntake) {
        otherConditions = (activity as CaseActivity)._case_owner_id === systemUserID;
      } else if (activity.type === ActivityType.TimeOffRequest) {
        otherConditions = (activity as TimeOffActivity).totOwnerId === systemUserID;
      } else if (activity.type === ActivityType.Sample) {
        otherConditions = activity.ownerId === systemUserID;
      }
      return isPast(activity.scheduledStart) && otherConditions;
    });
  }

  getAllPendingFollowupActions() {

    let todayStart = new Date(new Date().setHours(0,0,0,0));

    let allPendingFollowupTasks = this.activities.filter((activity) => {
      return (activity.scheduledStart >= todayStart && activity.type === ActivityType.FollowUp && (activity.status != 5 && activity.status != 6))
      }) as Array<FollowUpActivity>;

    let followupTasks: Array<FollowUpActivity> = [];

    followupTasks = followupTasks.concat(allPendingFollowupTasks.filter(followupTask => {
      return ((followupTask.planType === FOLLOW_UP_TYPE.MEETINGTASK && (followupTask.ownerId === this.authenticationService.user.systemUserID || followupTask.assignedTo.some(u=> u.userId === this.authenticationService.user.systemUserID))))
    }));

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)) {
      followupTasks = followupTasks.concat(allPendingFollowupTasks.filter(followupTask => {
        return (((followupTask.planType === FOLLOW_UP_TYPE.NONE) || (followupTask.planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN) && (followupTask.ownerId === this.authenticationService.user.systemUserID || followupTask.assignedTo.some(u=> u.userId === this.authenticationService.user.systemUserID))))
      }));
    }

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)) {
      followupTasks = followupTasks.concat(allPendingFollowupTasks.filter(followupTask => {
        return followupTask.planType === FOLLOW_UP_TYPE.SCIENTIFIC_PLAN && (followupTask.ownerId === this.authenticationService.user.systemUserID || followupTask.assignedTo.some(u=> u.userId === this.authenticationService.user.systemUserID));
        }));
    }

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK)) {
      followupTasks = followupTasks.concat(allPendingFollowupTasks.filter(followupTask => {
        return followupTask.planType === FOLLOW_UP_TYPE.OPPORTUNITY_TASK && (followupTask.ownerId === this.authenticationService.user.systemUserID || followupTask.assignedTo.some(u=> u.userId === this.authenticationService.user.systemUserID));
       }));
    }

    return followupTasks;
  }

  getPendingSalesOrders(){
    // let todayStart = new Date(new Date().setHours(0,0,0,0));
    let orderActivity: Array<OrderActivity> = [];

    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)){
      orderActivity = this.activities.filter((activity) => {
        return (activity.type === ActivityType.Order && activity.status == 548910001 && activity.ownerId === this.authenticationService.user.systemUserID)
        }) as Array<OrderActivity>;
    }
    return orderActivity
  }

  updateActivities(activity: Activity) {

    let index = this.activities.findIndex((anActivity) => {return anActivity.ID === activity.ID})

    if (index > -1) {
      this.activities[index] = activity;
    }
  }

  publishActivityEvent(event: IActivityEvent) {
    if (event) {
      this._activityEvent$.next(event);
    }
  }
  publishActivityDataFilterEvent(event: IActivityFilterEvent) {
    this._activityDataFilterEvent$.next(event);
  }

  getErrorMessageRequiredField(): string {
    return this.translate.instant('THIS_IS_A_REQUIRED_FIELD');
  }

  sortAllActivitiesByStartDateTime(resetConflictActivityIds = false) {
    if (Array.isArray(this.activities) && this.activities.length > 0) {
      try {
        if (this.activities.length == 1) this.activities[0].conflictingActivityIds = this.activities[0].closeByActivityIds = this.activities[0].closeByCompletedActivityIds = undefined;
        else {
          this.activities.sort((activity1, activity2) => {
            if (resetConflictActivityIds) {
              // Trying to reset conflictingActivityIds during sort
              activity1.conflictingActivityIds = undefined;
              activity2.conflictingActivityIds = undefined;
              activity1.closeByActivityIds = undefined;
              activity2.closeByActivityIds = undefined;
              activity1.closeByCompletedActivityIds = undefined;
              activity2.closeByCompletedActivityIds = undefined;
            }

            if (activity1.scheduledStart > activity2.scheduledStart) return 1;
            if (activity1.scheduledStart < activity2.scheduledStart) return -1;
            return 0;
          });
        }
      } catch (error) {
        console.error('sortAllActivitiesByStartDateTime: ', error);
      }
    }
  }
  findAndSetConflictCheckStartIdx(): number {
    const todayTimestamp: number = getTodayTimestamp();
    const insertionIdx = binarySearchInsertionIdx(
      this.activities,
      todayTimestamp,
      (a, b) => a - b,
      (o: Activity) => o.scheduledStart?.getTime()
    );

    // (-m - 1) where m is the insertion point.
    let startIdx = insertionIdx < 0 ? -(insertionIdx + 1) : insertionIdx;
    if (startIdx === this.activities.length) {
      startIdx--;
    }
    if (startIdx < 0
      || startIdx >= this.activities.length
    ) {
      startIdx = 0;
    }

    this.setConflictCheckStartIdx(startIdx);
    return startIdx;
  }
  setConflictCheckStartIdx(idx: number) {
    if (isNaN(idx) || idx < 0) {
      idx = 0;
    }
    this._conflictCheckStartIdx = idx;
  }
  checkStartPointForScheduleConflictCheck(activity: Activity, todayTimestamp: number, idx: number) {
    let isStartingPoint: boolean = isDateRangeOverlapOrInFuture(activity?.scheduledStart, activity?.scheduledEnd, todayTimestamp);
    if (isStartingPoint === true && !isNaN(idx)) {
      this.setConflictCheckStartIdx(idx);
    }
    return isStartingPoint;
  }
  setConflictCheckState(state: SimpleProcessState) {
    this._conflictCheckState$.next(state);
  }

  public updateMeetingNoteInOfflineDB(payload):Promise<void> {
    return new Promise(async (resolve,reject)=> {
      let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_MEETING_NOTES);
      let data:Array<any> = [];
      if(offlineNotes && offlineNotes.raw){
          data = offlineNotes.raw;
          if (payload) {
              let idx = data.findIndex(a => a && a.hasOwnProperty('annotationid') && a.annotationid == payload.annotationid);
              if (idx >= 0) {
                  data[idx] = payload;
                  if(data[idx].annotationid.includes('offlinmeetingnote') && data[idx].deleted == true){
                      data.splice(idx,1);
                  }
              } else {
                  data.push(payload);
              }
          }
          offlineNotes.raw = data;
          offlineNotes.count = data.length;
      }else{
          data.push(payload);
          offlineNotes = {
              raw: data,
              count: data.length,
          }
      }
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_NOTES, offlineNotes.count);
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_MEETING_NOTES, doc => {
          doc = offlineNotes;
          return doc;
      }).catch(error => console.error('Save Meeting Notes to DB error: ', error));
      resolve();
    });
  }

  public async updateMeetingNoteInOfflineDBForOfflineMeeting(offlineMeetings) {
      let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_MEETING_NOTES);
      if(offlineNotes && offlineNotes.raw && Array.isArray(offlineNotes.raw) && offlineNotes.raw.length != 0) {
        for (const key in offlineMeetings) {
          if (offlineMeetings.hasOwnProperty(key)) {
            const element = offlineMeetings[key];
            offlineNotes.raw.forEach(note => {
              if(note.activityid.includes('offline_meeting_') && note.activityid == element['offlineMeetingId']) {
                note.activityid = element['activityId'];
              }
            })

          }
        }      }
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_NOTES, offlineNotes.count);
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_MEETING_NOTES, doc => {
          doc = offlineNotes;
          return doc;
      }).catch(error => console.error('Save Meeting Notes to DB For Offline Meeting error: ', error));
  }
  public replacePropertyInTag(contentBody: any): any {
    let formattedBody = contentBody;
    formattedBody = formattedBody.replaceAll('href','style="color:#007AFF; text-decoration:underline" id');
    return formattedBody;
  }
  public openExternalLink(eventId:string, contentBody:any) {
    let checkedText: string[] = [];
    const anchorTagRegexp = new RegExp(/<a href[^>]*>/,'gi');
    const urlRegexp = new RegExp(/(((https?\:\/\/)|(www\.))(\S+))/,'gi');
    let anchorTagData = contentBody.match(anchorTagRegexp);
    let extractUrlData = !_.isEmpty(anchorTagData) ? anchorTagData.toString().match(urlRegexp) : '';
    if(extractUrlData && extractUrlData.length > 0) {
      extractUrlData.forEach(urlData=>{
        if(!urlData.includes('https://') && !urlData.includes('http://')) {
          urlData = 'https://' + urlData;
        }
        urlData = urlData.replaceAll('"','');
        urlData = urlData.replaceAll('<','');
        urlData = urlData.replaceAll('>','');
        checkedText.push(urlData);
      });
    }
    if(checkedText && checkedText.length > 0) {
      let targetId = eventId;
      targetId = targetId.replace('https://', '');
      targetId = targetId.replace('http://', '');
      targetId = targetId.replace('wwww.', '');
      const idx = checkedText.findIndex(t=> targetId && t.includes(targetId));
      if(idx>-1) {
        if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
          if(this.device.deviceFlags.ios) {
            this.iab.create(checkedText[idx], '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
          }else {
            this.iab.create(checkedText[idx], '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });
          }
        } else {
          if(this.device.deviceFlags.electron) {
            electronApi.openExternal(checkedText[idx]);
          }else {
            window.open(checkedText[idx], "_system");
          }
        }
      }
    }
  }
  public publishOfflineActivityForTimeline(activiy: Activity) {
    if (!_.isEmpty(activiy)) {
      this._offlineActivityForTimeline$.next(activiy);
    }
  }

  isRemoteChannelEnabled(id){
    if(_.isEmpty(this.meetingActivityTypes)) return false;
    if(!id) return true;
    return (this.meetingActivityTypes.findIndex((meetingActivityType) => meetingActivityType.indskr_activitytypeid === id  &&  meetingActivityType.indskr_remotechannel)) >= 0;
  }

  isContentRequiredForRemoteChannelEnabled(id){
    if(_.isEmpty(this.meetingActivityTypes)) return false;
    if(!id) return false;
    return (this.meetingActivityTypes.findIndex((meetingActivityType) => meetingActivityType.indskr_activitytypeid === id  &&  meetingActivityType.indskr_remotechannel && meetingActivityType.indskr_contentrequired)) >= 0;
  }

  getAccountVisitNestedMeetings(parentId: string): AppointmentActivity[] {
    let nestedMeetings: AppointmentActivity[];
    nestedMeetings = this.activities.filter(a => (a as AppointmentActivity).indskr_parentcallid === parentId) as AppointmentActivity[];
    return nestedMeetings;
  }

  nestedMeetingExistWithContact(contactId: string, parentId: string): boolean {
    const nestedMeetings = this.getAccountVisitNestedMeetings(parentId);
    const nestedMeetingExistWithContact = _.find(nestedMeetings, { contacts : [{ID : contactId}]});
    return nestedMeetingExistWithContact != null;
  }

  // Account visit meeting does not support offline yet
  accountVisitOfflineCheck(
    accountVisitChecks: {
      isAccountVisitEnabled: boolean,
      isAccountVisitRecord: boolean,
      isAccountVisitNestedMeeting: boolean,
    },
    showNotification = false
  ): boolean {
    let cannotProceed = false;

    cannotProceed = this.device.isOffline
      && (
        accountVisitChecks.isAccountVisitRecord
        || accountVisitChecks.isAccountVisitNestedMeeting
    );

    if (cannotProceed && showNotification) {
      this.notificationService.notify(
        this.translate.instant('ACCOUNT_VISIT_OFFLINE_WARNING'),
        'Meeting Details',
        'top',
        ToastStyle.DANGER
      );
    }

    return cannotProceed;
  }

  accountVisitRecordCheck(activity: AppointmentActivity): {
    isAccountVisitEnabled: boolean,
    isAccountVisitRecord: boolean,
    isAccountVisitNestedMeeting: boolean,
  } {
    const response = {
      isAccountVisitEnabled: false,
      isAccountVisitRecord: false,
      isAccountVisitNestedMeeting: false,
    };

    if (activity instanceof AppointmentActivity) {
      const isAccountVisitEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT);
      response.isAccountVisitEnabled = isAccountVisitEnabled;
      response.isAccountVisitRecord = isAccountVisitEnabled && !!activity.indskr_isparentcall;
      response.isAccountVisitNestedMeeting = isAccountVisitEnabled && !!activity.indskr_parentcallid;
    }

    return response;
  }

  async saveAccountVisitAllowedFormatIDsConfiguration(response: IoConfiguration[]) {
    try {
      this.accountVisitAllowedFormatIds = undefined;
      const config = Array.isArray(response) && response[0] ? response[0] : undefined;
      if (
        !response
        || !config
      ) {
        return;
      }

      const parsed: string[] = JSON.parse(config?.indskr_configvalue);
      if (Array.isArray(parsed)) {
        this.accountVisitAllowedFormatIds = parsed.length > 0
          ? parsed
          : undefined;

        await this.disk.updateOrInsert(
          DB_KEY_PREFIXES.ACCOUNT_VISIT_ALLOWED_FORMAT_IDS,
          doc => ({
            raw: this.accountVisitAllowedFormatIds,
          })
        );
      }
    } catch (error) {
      console.error('saveAccountVisitAllowedFormatFieldConfiguration: ', error);
    }
  }
  async loadAccountVisitAllowedFormatIDsConfiguration() {
    try {
      const doc: { raw: string[] } = await this.disk.retrieve(
        DB_KEY_PREFIXES.ACCOUNT_VISIT_ALLOWED_FORMAT_IDS,
        true,
      );
      if (Array.isArray(doc?.raw)) {
        this.accountVisitAllowedFormatIds = doc.raw;
      }
    } catch (error) {
      console.error('loadAccountVisitAllowedFormatFieldConfiguration: ', error);
    }
  }

  public get actualDuration() {
    let duration = 0;
    if (this.selectedActivity && this.selectedActivity instanceof AppointmentActivity) {
      const histories = this.selectedActivity.activityattendeejoiningdetails;
      if (!_.isEmpty(histories)) {
        histories.forEach((h: any) => {
          if (h['indskr_joineddate'] && h['indskr_leftdate']) {
            const joined_date = new Date(h.indskr_joineddate).getTime();
            const left_date = new Date(h.indskr_leftdate).getTime();
            if (left_date > joined_date) {
              const diff = left_date - new Date(joined_date).getTime();
              duration += moment.duration(diff).asSeconds();
            }
          }
        });
      }
    }
    return duration;
  }

  public get formattedDuration() {
    const duration = this.actualDuration;
    if (duration == 0) return "0";
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    return `${hours > 0 ? hours + ' ' + this.translate.instant("HOURS") : ''} ${minutes} ${this.translate.instant("MINUTES")} ${seconds} ${this.translate.instant("SECONDS")}`;
  }

  public get isMyUserSelectedInTeamView():boolean {
    return this.multiOptionActivityFilter && this.multiOptionActivityFilter.some(a=> a.value == this.authService.user.systemUserID);
  }

  public async showCancelMeetingPopover(event) {
    let altCancelText: string = this.languageService.selectedLanguage.code == "ja"  ? this.translate.instant('CANCEL_BTN_ALERT') : this.translate.instant('CANCEL');

    let popoverOptions = {
      header: this.translate.instant('CANCEL_MEETING'),
      message: this.translate.instant('CANCEL_SURGEY_ORDER_SUB_TEXT'),
      inputs: this.fetchCancellationReasonsScrap(),
      cancelText: altCancelText,
      confirmText: this.languageService.selectedLanguage.code == "ja" ? this.translate.instant('CONFIRM_BTN_ALERT') : this.translate.instant('CONFIRM'),
      Placeholder: this.translate.instant('ENTER_DESCRIPTION'),
      enableMultiSelect: false,
      isMandatory: true
    }

    let cancellationPopover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: popoverOptions,
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? ((event && event.target && event.target.getBoundingClientRect) ? event : undefined) : undefined,
    });

    cancellationPopover.present();

    const { data } = await cancellationPopover.onDidDismiss();
    return data
  }

  private fetchCancellationReasonsScrap() {
    if (_.isEmpty(this.activityCancellationResons)) return [];
    return this.activityCancellationResons.filter(type =>type.indskr_activitytype == 548910001).map((reason: ActivityCancellationReson) => {
      return {
        type: 'radio',
        name: reason.indskr_reason,
        label: reason.indskr_reason,
        value: reason.reasonId,
        isSelected: false,
      }
    })
  }

  public async addIoConfigDefaultValuesToActivity(activity:Activity) {
    const supportedDataTypes= ['Picklist','Boolean']
      const defaultConfigFields = this.authService.user.configuredFields.filter(a=>{
        let flag:boolean = a.indskr_optionsetdefaultvalue && supportedDataTypes.includes(a.fieldType);
        if(flag){
          if(activity instanceof AppointmentActivity){
            flag = a.entityName == "appointment";
          }else if(activity instanceof PhoneActivity){
            flag = a.entityName == "phonecall";
          }
        }
        return flag;
        });
      if(defaultConfigFields && defaultConfigFields.length > 0){
        const configFieldsPayload = [];
        defaultConfigFields.forEach(a=> {
          let item:any = {
            fieldName: a.fieldName,
            fieldname: a.fieldName,
            datatype: a.fieldType,
            value: a.indskr_optionsetdefaultvalue,
            stringValue: a.indskr_optionsetdefaultext,
            fieldType: a.fieldType,
            fieldLabel: a.fieldLabel,
            order: a.order,
            mandatory: a.mandatory,
            readOnly: a.readOnly,
            isEmptyRequiredField: false,
            indskr_optionsetdefaultext: a.indskr_optionsetdefaultext,
            indskr_optionsetdefaultvalue: a.indskr_optionsetdefaultvalue,
            entityName: a.entityName,
          };
          if(a.datatype == 'Lookup' || a.fieldType == 'Lookup'){
            item['indskr_lookupentitysetname'] = a.indskr_lookupentitysetname;
            item['indskr_referencingentitynavigationpropertyname'] = a.indskr_referencingentitynavigationpropertyname;
            item['indskr_lookupentityprimaryidattribute'] = a.indskr_lookupentityprimaryidattribute;
            item['indskr_lookupentityprimarynameattribute'] = a.indskr_lookupentityprimarynameattribute;
            item['indskr_lookupquery'] = a.indskr_lookupquery;
            item['indskr_lookupentityname'] = a.indskr_lookupentityname;
          }else if(a.datatype == 'Boolean' || a.fieldType == 'Boolean'){
            item.value = (a.indskr_optionsetdefaultvalue == 'true')  ? true : false;
          }
          item['multipleLabels'] = [];
          item['multipleValues'] = [];
          item['hasMultipleValues'] = false;
          if(a.datatype == 'Picklist' || a.fieldType == 'Picklist'){
            item['selectedValues'] = a.indskr_optionsetdefaultext;
          }
          configFieldsPayload.push(item);
        });

        //Save Config Fields
        if(activity instanceof AppointmentActivity || activity instanceof PhoneActivity){
          configFieldsPayload.forEach(payload=> {
            if (activity.appConfigFields !== undefined) {
              let removeIndex = activity.appConfigFields.map(function (item) { return item.fieldName; }).indexOf(payload.fieldName);
              if (removeIndex > -1) {
                activity.appConfigFields.splice(removeIndex, 1);
              }
              activity.appConfigFields.push(payload);
            }else{
              activity.appConfigFields = [];
              activity.appConfigFields.push(payload);
            }
          })
          
          // TO DO COACHING AND EVENTS
          
        }
        
      }
  }
}
