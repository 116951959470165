import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KOLStatus, KOLStatusCode } from "@omni/classes/contact/kol-status.class";
import { IndTabsDataModel } from "@omni/models/ind-tabs-data-model";
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { AlertService } from "@omni/services/alert/alert.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { DeviceService } from "@omni/services/device/device.service";
import { EventName, EventsService } from "@omni/services/events/events.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { KOLStatusService } from "@omni/services/kol-status/kol-status-service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { KOLStatusDetailsComponent } from "../kol-status-details/kol-status-details";

/**
 * Generated class for the KOLStatusListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
 @Component({
  selector: 'kol-status-list',
  templateUrl: 'kol-status-list.html',
  styleUrls:['kol-status-list.scss']
})
export class KOLStatusListComponent implements OnInit {

  private myKOLStatusItemsList: Array<KOLStatus> = [];
  public filteredItemsList:Array<KOLStatus> = [];
  public filteredViewItemsList: Array<MainCardViewDataModel> = [];
  public isTeamKOLViewEnabled = true;
  public kolStatusListMode:string = 'my_kol_status';

  public searchInput ='';
  public isTeamTabActive = false;
  public noItemsMessage = 'NO_SUBMISSIONS';
  public listCount = 0;
  public tabsData: IndTabsDataModel[];
  public indHeaderLeftModel: IndHeaderLeftDataModel;
  public listHeader: IndSectionHeaderViewDataModel;

  constructor(
    public navService: NavigationService,
    private readonly authService: AuthenticationService,
    private readonly uiService: UIService,
    public readonly device: DeviceService,
    private readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    public kolStatusService: KOLStatusService,
    public footerService: FooterService,
    private readonly alertService: AlertService,
    private readonly events: EventsService,
    public contactService: ContactOfflineService,
  ){

    this._initHeaderLeft();
    this.myKOLStatusItemsList = this.kolStatusService.myKOLStatusRecords;
  }

  ngOnInit() {
    this._setTabsData();
    this.updateItemsToDisplay();

    this.events.subscribe(EventName.NEW_KOL_STATUS_SUBMITTED, (params) => {
      this.updateItemsToDisplay();
    });
  }

  ngOnDestroy() {
    this.kolStatusService.teamKOL = [];
    this.kolStatusService.selectedKOLStatus = null;
    this.kolStatusService.isKOLCreateInProgress = false;
  }

  private _initHeaderLeft(): void {  
    this.indHeaderLeftModel = {
      id: 'kol-status-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('KOL_STATUS'),
      mode: true,
      controls: [
        {
          id: "back",
          imgSrc: 'assets/imgs/header_cancel.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: false,
          align: "left",
        },
        {
          id: "plusNew",
          imgSrc: 'assets/imgs/ios_add_3x.svg',
          isDisabled: false,
          align: "right",
        }
      ],
    };
  }

  private _initListHeader() {
    let title:string = 'ALL_SUBMISSIONS';
    if(this.searchInput.length){
      title = 'RESULTS_CAP'
    }else if(this.isTeamTabActive){
      title = 'ALL_APPROVALS'
    }
    this.listHeader = {
      id: 'selected-header',
      title: this.translate.instant(title) + ' (' + this.listCount + ')',
      controls: [],
    };
  }

  private _setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('MY_SUBMISSIONS'),
         value: "my_kol_status"
      },
      {
       displayText: this.translate.instant('APPROVALS'),
       value: "team_kol_status",
       disable: !this.isTeamKOLViewEnabled,
      },
    ];
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'back':
        this._onClosePage();
        break;
      case 'plusNew':
        this._handleKOLStatusCreate();
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public segmentChanged(selectedTab: string): void {
    if (selectedTab === 'my_kol_status') {
      this.isTeamTabActive = false;
      this.updateItemsToDisplay();
      this.noItemsMessage = 'NO_SUBMISSIONS';
    } else if (selectedTab === 'team_kol_status') {
      this.isTeamTabActive = true;
      this.noItemsMessage = 'NO_APPROVALS';
      this.getTeamKOLData();
    }
    this.searchInput = '';
  }

  public updateItemsToDisplay() {
    if(this.isTeamTabActive){
      this.filteredItemsList = this.kolStatusService.teamKOL.filter((a) => {
        return (a.contactName.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
      });
      this.filteredViewItemsList = this.filteredItemsList.sort((a, b) => (b.createdOn.getTime()) - (a.createdOn.getTime())).map(item=>{
        return this.getMainCardDataModel(item);
      });
    }else{
      this.filteredItemsList = this.myKOLStatusItemsList.filter((a) => {
        return (a.contactName.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
      });
      this.filteredViewItemsList = this.filteredItemsList.sort((a, b) => (b.createdOn.getTime()) - (a.createdOn.getTime())).map(item=>{
        return this.getMainCardDataModel(item);
      });
    }
    this.updateEmptyMessage();
    this._initListHeader();
  }

  public getMainCardDataModel(item: KOLStatus) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: item.ID,
      fixedHeight: true,
      //primaryTextLeft: item.contactName,
      primaryTextRight: item.contactName,
      secondaryTextRight: this.getFormattedDate(item.createdOn),
      secondaryTextRightTwo: item.isApprovalRecord?item.approvalStatus:item.statusString,
      secondaryTextRightThree: item.isApprovalRecord?item.ownerName:'',
      isSelected: item.ID === this.kolStatusService.selectedKOLStatus?.ID,
      showArrow: false,
      clickHandler: (id: string, event, specificTarget,refData) => {
        this.handleCardItemClick(id, event, specificTarget, refData)
      }
    };
    return viewData;
  }

  private handleCardItemClick(id: string, event, specificTarget,refData){
    if(specificTarget === 'mainItem'){
      if(this.kolStatusService.isKOLCreateInProgress){
        this._showDiscardConfirmationPopover().then(res=> {
          this.openKOLDetails(id);
        }).catch(err=> {
          return;
        })
      }else{
        this.openKOLDetails(id);
      }
    }
  }

   private openKOLDetails(id) {
     let foundKOLStatus: KOLStatus;
     if (this.isTeamTabActive) {
       foundKOLStatus = this.kolStatusService.teamKOL.find(a => a.ID == id);
     } else {
       foundKOLStatus = this.myKOLStatusItemsList.find(a => a.ID == id);
     }
     if (foundKOLStatus) {
       this.kolStatusService.selectedKOLStatus = foundKOLStatus;
       this.filteredViewItemsList.forEach(order => {
         order.isSelected = false;
         if (order.id === foundKOLStatus.ID) order.isSelected = true;
       });
       this.navService.setChildNavRightPaneView(true);
       this.navService.setChildNavRoot(KOLStatusDetailsComponent, PageName.KOLDetailsComponent, PageName.KOLStatusPageComponent, { from: 'KOLStatusTool', kolStatus: foundKOLStatus });
     }
   }

  public getFormattedDate(value): string {
    return this.datePipe.transform(value, 'MMM dd yyyy', undefined, this.translate.currentLang);
  }

  public handleSearch() {
    this.updateItemsToDisplay();
  }

  updateEmptyMessage() {
    this.listCount = this.filteredItemsList.length;
    this.uiService.updateNothingSelectedScreenMessageFor(this.filteredItemsList.length);
  }

  private _onClosePage(): void {
    if(this.kolStatusService.isKOLCreateInProgress){
      this._showDiscardConfirmationPopover().then(res=> {
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.navService.popWithPageTracking().then(() => {
            this.footerService.initButtons(this.footerService.previousFooterView);
        });
      }).catch(err=> {
        return;
      })
    }else{
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      this.navService.popWithPageTracking().then(() => {
        this.footerService.initButtons(this.footerService.previousFooterView);
      });;
    }
    
    
  }

  private _handleKOLStatusCreate(){
    if(this.kolStatusService.isKOLCreateInProgress){
      this._showDiscardConfirmationPopover().then(res=> {
        this._createKOL();
      }).catch(err=> {
        return;
      })
    }else{
      this._createKOL();
    }
  }

  private _createKOL(){
    let kolStatus = new KOLStatus({
      indskr_kolstatusid : 'kol_status_offline_'+new Date().getTime(),
      indskr_name : 'KOL Status',
      _ownerid_value : this.authService.user.xSystemUserID,
      _ownerid_value_Formatted : this.authService.user.displayName,
      createdon : new Date(),
      statuscode_Formatted : 'Draft',
      statuscode : KOLStatusCode.Draft,
      statecode : 0,
      isPendingPushToDynamics : true,
      isApprovalRecord : false,
    });
    this.filteredViewItemsList.forEach(order=>{
      order.isSelected = false;
    });
    this.navService.setChildNavRightPaneView(true);
    this.navService.setChildNavRoot(KOLStatusDetailsComponent, PageName.KOLDetailsComponent, PageName.KOLStatusPageComponent, { from: 'KOLStatusTool', kolStatus: kolStatus });
  }

  private async getTeamKOLData() {
    if (this.kolStatusService.teamKOL.length == 0) {
      this.uiService.displayLoader();
      this.kolStatusService.getTeamKOLDataOnline().then(succ => {
        this.updateItemsToDisplay();
        this.uiService.dismissLoader();
      }).catch(err => {
        this.uiService.dismissLoader();
      });
    }else{
      this.updateItemsToDisplay();
    }
    
  }

  private _showDiscardConfirmationPopover():Promise<any>{
    let cancelText = this.translate.instant("CANCEL");
    if(this.translate.currentLang == 'it') {
      cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
    }
    return new Promise(async (resolve,reject)=> {   
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
      ).then (res => {
        if(res.role == "ok") {
          this.kolStatusService.isKOLCreateInProgress = false;
          resolve(true);
        }else{
          reject(null);
        }
      });
    });
  }

  onSearchCancel() {}
  
}