import { DatePipe } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DB_SYNC_STATE_KEYS } from '@omni/config/pouch-db.config';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';
import { AuthenticationService } from "@omni/services/authentication.service";
import { DiskService } from '@omni/services/disk/disk.service';
import { MyAssistantService, NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import moment from 'moment';
import * as _ from 'lodash';
import { throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import { Endpoints } from 'src/config/endpoints.config';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { GPSApprovalActivity } from "@omni/classes/approvals/gps-approval-activity.class";
import { GpsDetails } from "@omni/classes/activity/appointment.activity.class";
import { GPSActivityPhoto } from "@omni/classes/store-check/photo";
import { AppealService } from '@omni/services/appeal/appeal.service';
import { ApprovalReasonConfig } from '@omni/classes/approvals/approval-reasons-config.class';
import { addDays, addMonths, isAfter, isBefore, lastDayOfMonth } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AppealDataService {

  public approvalReasonsConfigForGPSCheckinDetails:Array<ApprovalReasonConfig> = [];

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    private diskService: DiskService,
    public deltaService: DeltaService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private translate: TranslateService,
    private myAssistantService: MyAssistantService,
    public dynamics: DynamicsClientService) {
  }

  private appealStatusNotificationModel: IndNotificationDataModel;

  public async updateGPSActivityPhotos(activityId, payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.GPS_ACTIVITY_PHOTOS.replace('{activity_id}', activityId);
    await this.http.put(url, payload).toPromise();
  }

  public async updateAppealStatus(meetingId, payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.GPS_CHECK_IN.replace("{activity_id}", meetingId);
    await this.http.put(url, payload).toPromise();
  }

  async updateApprovalActivity(payload, approvalActivityId: string) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.quotes.UPDATE_APPROVAL_ACTIVITY.replace('{approvalActivityId}', approvalActivityId);
    return await this.http.patch<any>(url, payload).toPromise();
  }

  public async fetchPendingApprovals() {
    const fetchXML = fetchQueries.appeal.pendingApprovals;
    return await this.dynamics.executeFetchQuery('indskr_approvalactivities', fetchXML).then(async (responses: any[]) => {
      if (responses) {
        //for approver, its approver primary position:
        responses.map(res => res['positionName'] = this.authService.user.positionName);
        return this.getGPSApprovalActivities(responses);
      }
      return [];

    }).catch((err) => {
      console.error("Failed to fetch pending approvals: ", err);
      return [];
    });
  }

  public async fetchApprovalActivitystatus(approvalActivityId: string) {
    let approvalActivity = await this.dynamics.retrieveAll('indskr_approvalactivities',
      ['statuscode', 'indskr_approvalactivityid'],
      `indskr_approvalactivityid eq '${approvalActivityId}'`
    ).catch(() => console.log('failed to fetch approval status'));
    if (!_.isEmpty(approvalActivity) && !_.isEmpty(approvalActivity['value']) && approvalActivity['value'][0].statuscode) return approvalActivity['value'][0].statuscode;
  }

  public async fetchMyApprovalReq() {
    const fetchXML = fetchQueries.appeal.myApprovalReq;
    return await this.dynamics.executeFetchQuery('indskr_approvalactivities', fetchXML).then(async (responses: any[]) => {
      if (responses) {
        return this.getGPSApprovalActivities(responses);
      }
      return [];
    }).catch((err) => {
      console.error("Failed to fetch my approvals: ", err);
      return [];
    });
  }

  public async fetchAppealDetails(approvalActivityId: string, mode: number=0): Promise<GPSApprovalActivity> {
    const fetchXML = fetchQueries.appeal.fetchAppealDetails.replace('{approvalActivityId}', approvalActivityId);
    return await this.dynamics.executeFetchQuery('indskr_approvalactivities', fetchXML).then(async (responses: any[]) => {
      if (responses) {
        if (mode == 0) {
          //for approver, its approver primary position, approver name:
          responses.map((res) => {
            res['positionName'] = this.authService.user.positionName;
          });
        } else {
          //Requestor
          responses.map((res) => {
            res['ownerId@OData.Community.Display.V1.FormattedValue'] = res['approverName@OData.Community.Display.V1.FormattedValue'];
          });
        }
        return this.getGPSApprovalActivities(responses)[0];
      }
      return null;
    }).catch((err) => {
      console.error("Failed to fetchAppealDetails: ", err);
      return null;
    });
  }

  private getGPSApprovalActivities(responses: any[]): GPSApprovalActivity[] {
    let approvals: GPSApprovalActivity[] = [];
    for (let response of responses) {
      const index = approvals.findIndex(approval => approval.indskr_approvalactivityid === response['indskr_approvalactivityid']);
      if (index >= 0) {
        approvals[index].gpsDetails = this.getGPSDetails(response);
        const gpsActivityPhotos = this.getGPSActivityPhoto(response);
        const gpsActivityPhotoIdx = approvals[index].gpsActivityPhotos.findIndex(gpsActivityPhoto => gpsActivityPhoto.indskr_type === gpsActivityPhotos.indskr_type);
        if (gpsActivityPhotoIdx >= 0) {
          approvals[index].gpsActivityPhotos[gpsActivityPhotoIdx].photoAttachments.push(...gpsActivityPhotos.photoAttachments);
        } else {
          approvals[index].gpsActivityPhotos.push(gpsActivityPhotos);
        }
      } else {
        const gpsApprovalActivity = new GPSApprovalActivity(response);
        gpsApprovalActivity.gpsDetails = this.getGPSDetails(response);
        gpsApprovalActivity.gpsActivityPhotos = <GPSActivityPhoto[]>[this.getGPSActivityPhoto(response)];
        approvals.push(gpsApprovalActivity);
      }
    }

    approvals = _.uniqBy(approvals, 'activityId');

    return approvals;
  }

  private getGPSDetails(response: any): GpsDetails {
    return <GpsDetails>{
      indskr_checkinstatus: response['checkinstatus'],
      indskr_checkindatetime: response['checkindatetime'],
      indskr_checkoutdatetime: response['checkoutdatetime'],
      indskr_checkoutstatus: response['checkoutstatus'],
      indskr_gpscheckindetailsid: response['indskr_gpscheckindetailid'],
      statuscode: response['statuscode'],
      indskr_checkinlatitude: response['checkinlatitude'],
      indskr_checkinlongitude: response['checkinlongitude'],
      indskr_checkoutlatitude: response['checkoutlatitude'],
      indskr_checkoutlongitude: response['checkoutlongitude']
    };
  }

  private getGPSActivityPhoto(response: any): GPSActivityPhoto {
    return {
      indskr_type: response['type'],
      indskr_gpscheckindetailsid: response['indskr_gpscheckindetailid'],
      photoAttachments: [{
        name: response['name'],
        indskr_photoattachmentid: response['photoAttachmentId'],
        indskr_photoorigin: 548910004,
        indskr_photourl: response['photoUrl']
      }]
    };
  }

  fetchAppealStatusNotifications(loadFromDBOnly = false){
    if (loadFromDBOnly) {
      return;
    }
    if (!this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN)) return;
    this.fetchForPendingForSubmissionAppealStatus();
    this.fetchForReviewAppealStatus();
    this.fetchApprovedAppealStatus();
    this.fetchRejectedAppealStatus();
  }

  async fetchForPendingForSubmissionAppealStatus() {
    console.warn(`fetchForPendingForSubmissionAppealStatus`);
    let parentEntityFetchXml = fetchQueries.appeal.fetchForPendingForSubmission;
    const appealStatusSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.appealStatus,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PENDING_FOR_SUBMISSION_APPEAL_STATUS);
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    if (isInitialSync) {
      parentEntityFetchXml = parentEntityFetchXml.replace('{DeltaCondition}', '');
    }
    else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(+syncState.lastUpdatedTime).toISOString() + `" />`
      parentEntityFetchXml = parentEntityFetchXml.replace('{DeltaCondition}', deltaSyncFilter);
    }
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    return await this.dynamics.executeFetchQuery('indskr_gpscheckindetailses', parentEntityFetchXml).then(async (response) => {
      console.warn(`fetchForPendingForSubmissionAppealStatus: executeFetchQuery`);
      console.log(response);
      let formattedRes: any[] = [];

      //Fetch and assign approvalSubmissionPeriod  
      let approvalSubmissionPeriod  = +this.authService.user.buConfigs['indskr_approvalsubmissionperiod'];
      approvalSubmissionPeriod = approvalSubmissionPeriod - 1;  

      response.forEach((resp: any) => {
        //When the Approval submission period is not set to No limit option
        if(approvalSubmissionPeriod !== -1) {
          const dateCompleted = new Date(resp['meeting.omnip_datecompleted']);
          console.log(`dateCompleted: ${dateCompleted.toString()}`);

          const currentdate = new Date();
          console.log(`currentdate: ${currentdate.toString()}`);

          let calcApprovalSubmissionPeriod = addMonths(dateCompleted, approvalSubmissionPeriod);
          calcApprovalSubmissionPeriod = lastDayOfMonth(calcApprovalSubmissionPeriod);

          //Notification of 3 days before month end
          calcApprovalSubmissionPeriod = addDays(calcApprovalSubmissionPeriod, -3);

          console.warn(`calcApprovalSubmissionPeriod: ${calcApprovalSubmissionPeriod.toString()}`);

          if(isAfter(currentdate, calcApprovalSubmissionPeriod)) {
            let message = this.translate.instant('APPEAL_STATUS_REQUEST_PENDING_FOR_SUBMISSION', { subject: resp['meeting.subject'], position: resp['positionName'], userName: resp['userfullname'] });
            let formattedDate = this.datePipe.transform(new Date(resp['createdon']), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            formattedRes.push({ message: message, formattedDate: formattedDate, id: resp['meeting.activityid'] });
          }
        }// end of if(approvalSubmissionPeriod !== -1)
      });

      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.appealStatusNotificationModel = {
          type: NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION,
          name: this.translate.instant("NEW_APPEAL_PENDING_FOR_SUBMISSION_NOTIFICATION", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION + Date.now(),
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/appointment.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.appealStatusNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }
    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(appealStatusSyncInfo, 'appealStatus', err);
    })

  }

  async fetchForReviewAppealStatus() {
    console.warn(`fetchForReviewAppealStatus`);
    let parentEntityFetchXml = fetchQueries.appeal.fetchForReview;
    const appealStatusSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.appealStatus,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_REVIEW_APPEAL_STATUS);
    if (!syncState || !syncState.lastUpdatedTime) {
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.diskService.updateSyncState(syncState);
      return;
    }
    let lastSync = moment(parseInt(syncState.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    parentEntityFetchXml = parentEntityFetchXml.replace('{lastUpdatedTime}', lastSync);
    return await this.dynamics.executeFetchQuery('indskr_gpscheckindetailses', parentEntityFetchXml).then(async (response) => {
      console.warn(`fetchForReviewAppealStatus: executeFetchQuery`);
      console.log(response);
      let formattedRes: any[] = [];
      response.forEach((resp: any) => {
        let message = this.translate.instant('APPEAL_STATUS_REQUEST_SUBMITTED', { subject: resp['meeting.subject'], position: resp['positionName'], userName: resp['userfullname'] });
        let formattedDate = this.datePipe.transform(new Date(resp['createdon']), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        formattedRes.push({ message: message, formattedDate: formattedDate, id: resp['approvalActivityId'] });
      })
      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.appealStatusNotificationModel = {
          type: NOTIFICATION.APPEAL_STATUS_SUBMITTED,
          name: this.translate.instant("NEW_APPEAL_FOR_REVIEW_NOTIFICATION", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.APPEAL_STATUS_SUBMITTED + Date.now(),
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/appointment.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.appealStatusNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }
    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(appealStatusSyncInfo, 'appealStatus', err);
    })

  }

  async fetchApprovedAppealStatus() {
    console.warn(`fetchApprovedAppealStatus`);
    let parentEntityFetchXml = fetchQueries.appeal.fetchForApproved;
    const appealStatusSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.appealStatus,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_APPROVED_APPEAL_STATUS);
    if (!syncState || !syncState.lastUpdatedTime) {
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.diskService.updateSyncState(syncState);
      return;
    }
    let lastSync = moment(parseInt(syncState.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    parentEntityFetchXml = parentEntityFetchXml.replace('{lastUpdatedTime}', lastSync);
    return await this.dynamics.executeFetchQuery('indskr_gpscheckindetailses', parentEntityFetchXml).then(async (response) => {
      console.warn(`fetchApprovedAppealStatus: executeFetchQuery`);
      console.log(response);
      let formattedRes: any[] = [];
      response.forEach((resp: any) => {
        let message = this.translate.instant('APPEAL_STATUS_REQUEST_APPROVED', { subject: resp['meeting.subject'], userName: resp['userfullname'] });
        let formattedDate = this.datePipe.transform(new Date(resp['ac.modifiedon']), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        formattedRes.push({ message: message, formattedDate: formattedDate, id: resp['approvalActivityId'] });
      })
      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.appealStatusNotificationModel = {
          type: NOTIFICATION.APPEAL_STATUS_APPROVED,
          name: this.translate.instant("APPEAL_STATUS_APPROVED", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.APPEAL_STATUS_APPROVED +  Date.now(),
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/appointment.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.appealStatusNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }
    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(appealStatusSyncInfo, 'appealStatus', err);
    })

  }

  async fetchRejectedAppealStatus() {
    console.warn(`fetchRejectedAppealStatus`);
    let parentEntityFetchXml = fetchQueries.appeal.fetchForRejected;
    const appealStatusSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.appealStatus,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_REJECTED_APPEAL_STATUS);
    if (!syncState || !syncState.lastUpdatedTime) {
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.diskService.updateSyncState(syncState);
      return;
    }
    let lastSync = moment(parseInt(syncState.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    parentEntityFetchXml = parentEntityFetchXml.replace('{lastUpdatedTime}', lastSync);
    return await this.dynamics.executeFetchQuery('indskr_gpscheckindetailses', parentEntityFetchXml).then(async (response) => {
      console.warn(`fetchRejectedAppealStatus: executeFetchQuery`);
      console.log(response);
      let formattedRes: any[] = [];
      response.forEach((resp: any) => {
        let message = this.translate.instant('APPEAL_STATUS_REQUEST_REJECTED', { subject: resp['meeting.subject'], userName: resp['userfullname'], reason: resp['ac.indskr_reason'] });
        let formattedDate = this.datePipe.transform(new Date(resp['ac.modifiedon']), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        formattedRes.push({ message: message, formattedDate: formattedDate, id: resp['approvalActivityId'] });
      })
      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.appealStatusNotificationModel = {
          type: NOTIFICATION.APPEAL_STATUS_REJECTED,
          name: this.translate.instant("APPEAL_STATUS_REJECTED", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.APPEAL_STATUS_REJECTED +  Date.now(),
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/appointment.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.appealStatusNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }

    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(appealStatusSyncInfo, 'appealStatus', err);
    })

  }

  fetchApprovalReasonsConfiguration(loadFromDBOnly = false){
    if (loadFromDBOnly) {
      return;
    }
    if (!this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN)) return;
    this.fetchForReviewAppealStatus();
    this.fetchApprovedAppealStatus();
    this.fetchRejectedAppealStatus();
  }


  public async getfetchApprovalReasonsConfigurationByBusinessUnitId(loadFromDBOnly) {
    if (loadFromDBOnly) {
      // This fetch request was breaking offline launch scenario
      // Applying temporary fix to unblock
      // This will need to be handled properly by the feature owner
      return;
    }

    const isManualGPSCheckinFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN); 
    
    if (!isManualGPSCheckinFAEnabled) return;

    /* To check foll.
      1.Feature Action is enabled
    */
    if (isManualGPSCheckinFAEnabled) {
      console.warn(`Check for Approval Reasons Configuration for GPS Checkin Details`);

      let fetchXML = fetchQueries.approvalreasonconfig.gpscheckindetails;
      fetchXML = fetchXML.replace('{businessUnitId}', `${this.authService.user.xBusinessUnitId}`);

      try {
        let response = await this.dynamics.executeFetchQuery('indskr_approvalreasonconfigurations', fetchXML);
        console.warn(`getfetchApprovalReasonsConfigurationByBusinessUnitId: executeFetchQuery`);
        console.log(response);

        // reset this array
        this.approvalReasonsConfigForGPSCheckinDetails = [];

        if (!_.isEmpty(response)) {
          this.approvalReasonsConfigForGPSCheckinDetails = this.getApprovalReasonsConfig(response);
        } 
      } catch (error) {
        console.error('getfetchApprovalReasonsConfigurationByBusinessUnitId: error:', error);
      }
      return;
    } //end of if (this.isManualGPSCheckinFAEnabled)
  }

  private getApprovalReasonsConfig(responses: any[]): Array<ApprovalReasonConfig> {
    const approvalReasonsConfig: Array<ApprovalReasonConfig> = [];
    for (let response of responses) {
        const gpsApprovalActivity = new ApprovalReasonConfig(response);
        approvalReasonsConfig.push(gpsApprovalActivity);
    }
    return approvalReasonsConfig;
  }
}