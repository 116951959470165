<ion-header>
  <ind-page-title [viewData]='titleBar' (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>

<ion-content *ngIf="transfer">
  <ion-list>
    <ind-form-field [viewData]="transferIdFormField"></ind-form-field>
    <ind-form-field [viewData]="transferStatusFormField"></ind-form-field>
    <ind-form-field [viewData]="assetCategoryFormField"></ind-form-field>
    <ind-form-field [viewData]="assetStatusFormField"></ind-form-field>
    <ind-form-field [viewData]="assetOwnerFormField"></ind-form-field>
    <ind-form-field [viewData]="currentAccountFormField"></ind-form-field>
    <ind-form-field [viewData]="createdOnFormField"></ind-form-field>
    <ind-section-header [viewData]="transferDetailsHeaderModel"></ind-section-header>
    <ind-form-field *ngIf="!transfer.isLocationChange" [viewData]="newOwnerFormField"></ind-form-field>
    <ng-container *ngIf="transfer.isLocationChange">
      <ind-form-field [viewData]="newAccountFormField"></ind-form-field>
      <ind-form-field [viewData]="startDateFormField"></ind-form-field>
      <ind-form-field [viewData]="endDateFormField"></ind-form-field>
    </ng-container>
    <ng-container *ngIf="notesSectionHeaderViewData">
      <ind-section-header [viewData]="notesSectionHeaderViewData" (onControlClick)='handleButtonClick($event)'></ind-section-header>
      <ind-text-area [disabled]=" (transfer.isLocationChange && transfer.asset_category === 548910002 && (transfer.statuscode === 548910002 || transfer.statuscode === 548910000)) ? false : !formEditable " id="notesInput" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000" (indChange)="notesChanged($event)" [value]="tempNoteText"
                     (removeAttachment)="removeAttachment($event)" [rows]="4" [cols]="10"> </ind-text-area>
      <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
      <io-note *ngFor="let note of transferNotes" [inputNote]="note" [editable]="isNoteEditable(note)" [isNoteAttachmentEnabled]="true" 
      (saveNoteOut)="updateNote($event)" [deletable]="isNoteEditable(note)" [disableDownloadInOffline]="true"></io-note>
    </ng-container>
    <ion-item class="conf-proof" *ngIf="transfer.isLocationChange && transfer.asset_category !== 548910002">
      <div #roundInput [id]="proofConfirmation" [ngClass]="{'round': formEditable, 'selected': proofConfirmation, 'round-disabled': !formEditable}" (click)="updateProofConfirmation($event)">
        <input [id]="proofConfirmation" type="checkbox" [ngModel]="proofConfirmation" />
        <label [for]="proofConfirmation" [ngClass]="{'selected':proofConfirmation}"></label>
        <span style="margin-bottom:35px; margin-left: 15px;" size="25" class="proof-label">{{'UPLOAD_CONFIRMATION_PROOF' | translate}}</span>
      </div>
    </ion-item>
  </ion-list>
</ion-content>

<footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="handleButtonClick($event)" [footerView]="'detailView'" [selectedView]="'AssetDetails'"></footer-toolbar>