<ion-header>
  <ind-page-title [viewData]="newChangeRequestPageTitle" (onControlClick)='onSectionHeaderControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item *ngFor="let item of options" (click)="selectType(item)" [disabled]="item['isDisabled']">
      {{item?.formattedLabel}}
      <ion-icon *ngIf="item['isChecked']" name="checkmark" color="primary" slot="end"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
