<!--RELATIONSHIP INSIGHTS-->
<ion-item-divider color="light" class="insights-list-header" [ngClass]="{'insights-list-header-ios' : device.deviceFlags.ios}">
  <ion-button class="xpHeadingText padTop5" icon-only fill="clear" (click)='openPlanInsightFilterPopup()'>
    <span class="xpFilterStyleHeading">
      {{ xperiencesService.xpRelationshipInsightsFilterText | capitalizeFirstLetter}}
    </span>
    <span class="filter-count">
      ({{filteredInsights.length}})
    </span>
    <ion-icon class="filterIcon" name="caret-down-outline"></ion-icon>
  </ion-button>
</ion-item-divider>
<div class="insights-section" #parentScrolling [ngClass]="{ 'min-section-ht' : filteredInsights.length === 0 }">
  <!-- TODO: Add the pipe for sorting the data by date -->
  <!--  -->
  <ion-item-sliding #item *ngFor="let insight of filteredInsights | slice:0:xperiencesService.limitItems; let i=index">
    <ion-item class="interest-item" (click)="displayActivityDetails(insight)">
      <div  class="relationship-div">
        <div text-left class="padTop relationship-header">
          <h3 text-nowrap class="header">{{ insight.fullname }}</h3>
          <ion-badge class="badge-ind">{{getOrdinalSuffix(insight.degree)}}</ion-badge>
        </div>
        <div text-left class="marginTop5">
          <span text-nowrap class='secondaryTextColorAndfont'>
            <ion-row class="marginX" *ngIf="insight.latestInsight">
              <span class="accountNameText header">{{insight.latestInsight.relationship_insight_type}}</span>
            </ion-row>
            <ion-row class="marginX">
              <span class="accountNameText header">{{insight.sourcesText}}</span>
            </ion-row>
          </span>
        </div>
      </div>
    </ion-item>
    <ion-item-options side="end" *ngIf="xperiencesService.segmentType !== segmentType.FEED">
      <ion-button (click)="sendFeedback(insight, true)"
      [ngClass]="{'feedback-done' : insight.feedback_Flag || device.isOffline}">
        <ion-icon src="assets/imgs/feedback_thumbs_up{{(insight.feedback_Flag && (insight.feedback_Flag === 'positive')) ? '_active' : ''}}.svg"></ion-icon>
      </ion-button>
      <ion-button (click)="sendFeedback(insight, false, i)"
      [ngClass]="{'feedback-done' : insight.feedback_Flag || device.isOffline}">
        <ion-icon src="assets/imgs/feedback_thumbs_down{{(insight.feedback_Flag && (insight.feedback_Flag === 'negative')) ? '_active' : ''}}.svg"></ion-icon>
      </ion-button>
    </ion-item-options>
  </ion-item-sliding>
  <ion-item *ngIf="filteredInsights.length > 5">
    <div class="expProd" (click)="openMorePlansInsights()">{{ this.translate.instant('AND')+' '+ (filteredInsights.length - xperiencesService.limitItems) +' '+this.translate.instant('MORE') }}</div>
  </ion-item>
</div>
