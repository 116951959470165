import { CustomerCallPlanPopover } from './../customer-call-plan-popover/customer-call-plan-popover';
import { DB_KEY_PREFIXES } from './../../../config/pouch-db.config';
import {
  FooterService,
  FooterViews
} from "./../../../services/footer/footer.service";
import { ContactOfflineService } from "./../../../services/contact/contact.service";
import { Component, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { NavParams, NavController, PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { RepCallPlanState } from "../../../classes/call-plan/rep-call-plan.class";
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { AuthenticationService } from '../../../services/authentication.service';
// import { NavController } from "@ionic/angular/navigation/nav-controller";
import { DeviceService } from "../../../services/device/device.service";
import { CallPlanDataService } from '../../../data-services/call-plan/call-plan.data.service';
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { DiskService } from "../../../services/disk/disk.service";
import { RepServices } from "../../../data-services/rep/rep.services";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { ComponentViewMode, UIService } from "../../../services/ui/ui.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { AlertService } from "../../../services/alert/alert.service";
import { IndFormFieldViewDataModel, FormFieldType } from "../../../models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { RepCallPlanStatePipe } from '../../../pipes/rep-call-plan-state.pipe';
import { ExtendedDatePipe } from '../../../pipes/extended-date.pipe';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { Contact } from "../../../classes/contact/contact.class";
import { ContactDetailsComponent } from "../../contact/contact-details/contact-details";
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivityService } from '@omni/services/activity/activity.service';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input';
import { IndAlertMsgComponent } from '@omni/components/shared/ind-alert-msg/ind-alert-msg';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { AccountDetailsComponent } from '@omni/components/account/account-details/account-details';

/**
 * Generated class for the CallPlanCustDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "customer-call-plan-suggest",
  templateUrl: "customer-call-plan-suggest.html",
  styleUrls: ['customer-call-plan-suggest.scss'],
  providers: [RepCallPlanStatePipe, ExtendedDatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class CustomerCallPlanSuggest {
  public contactFirstName: string;
  public contactLastName: string;
  public suggestedEmailsGoal: string;
  public suggestedMeetingsGoal: string;
  public detailsHeaderModel: IndSectionHeaderViewDataModel;
  public overviewHeaderModel: IndSectionHeaderViewDataModel;
  public meetingsHeaderModel: IndSectionHeaderViewDataModel;
  public messagingHeaderModel: IndSectionHeaderViewDataModel;
  public coverageHeaderModel: IndSectionHeaderViewDataModel;
  public hasTeamPlansEnabled: boolean = false;
  public F2FMeetingHeaderModel: IndSectionHeaderViewDataModel;
  public remoteMeetingHeaderModel: IndSectionHeaderViewDataModel;
  public phoneCallHeaderModel: IndSectionHeaderViewDataModel;

  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case 'sendForApproval':
        this.sendForApproval(true);
        break;
      case 'clear':
        this.clear(true);
        break;
      case 'reject':
        this.reject();
        break;
      case 'approve':
        this.approve();
        break;

      default:
    }
  };

  public pageTitle: IndPageTitleViewDataModel;
  otherRepCallPlans: any = [];
  ngUnSubscribe$ = new Subject<boolean>();
  private isClickedMeetingSuggested: boolean = false;
  private isClickedMessageSuggested: boolean = false;
  private isClickedF2FMeetingSuggested: boolean = false;
  private isClickedRemoteMeetingSuggested: boolean = false;
  private isClickedPhoneCallSuggested: boolean = false;
  public hasCallPlanProgress: boolean = false;
  public displaygoalsbeyond100: boolean = false;
  public displayAllActProgress: boolean = false;

  /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
  public activitiesHeaderModel: IndSectionHeaderViewDataModel;
  public isCallPlanActivitiesEnabled: boolean = false;
  /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

  constructor(
    // private datePipe:ExtendedDatePipe,
    private datePipe: DatePipe,
    private statePipe: RepCallPlanStatePipe,
    private navParams: NavParams,
    public callPlanService: CallPlanOfflineService,
    public callPlanDataService: CallPlanDataService,
    public contact: ContactOfflineService,
    public footerService: FooterService,
    public authenticationService: AuthenticationService,
    public navCtrl: NavController,
    public device: DeviceService,
    public repService: RepServices,
    private alertService: AlertService,
    public notifications: NotificationService,
    private navService: NavigationService,
    private events: Events,
    private _cd: ChangeDetectorRef,
    private disk: DiskService,
    private authService: AuthenticationService,
    private uiService: UIService,
    private contactService: ContactOfflineService,
    public trackingService: TrackService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private activityService: ActivityService,
    private popoverCtrl: PopoverController,
    public accountService: AccountOfflineService,
  ) {
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  ionViewWillEnter() {
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  ngOnInit() {
    this._checkBUConfig();
    this.events.subscribe('customer-call-plan-details:footerEvents', this.footerHandler);

    if (this.authService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)) {
      this.hasTeamPlansEnabled = true;
    }
    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans'
      && this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans')
      this.uiService.activeViewOnPlanTab = 'customerCallPlanDetails';
    this.initPageTitle();
    this.initializeSectionHeader();
    if (this.callPlanService.selectedCallPlan && !this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      this.disk.retrieve(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS).then((res) => {
        if (res && res.raw?.length) {
          this.otherRepCallPlans = res.raw.filter(o => o.customerPlanId == this.callPlanService.selectedRepCallPlan.customerPlanId);
        }
      })
    }
    else {
      this.otherRepCallPlans = this.callPlanService.teamPositionsOtherRepPlans.filter(o => o.customerPlanId == this.callPlanService.selectedRepCallPlan.customerPlanId);
    }
    this.callPlanService.otherRepCallPlansList$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.hasUpdatedData) {
        this.disk.retrieve(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS).then((res) => {
          if (res && res.raw?.length) {
            this.otherRepCallPlans = res.raw.filter(o => o.customerPlanId == this.callPlanService.selectedRepCallPlan.customerPlanId);
          }
        })
      }
    })
    /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLAN_ACTIVITIES)) {
      this.isCallPlanActivitiesEnabled = true;
    }
    //Account Call Plan Activities are not implemented yet.
    if(this.callPlanService.selectedCallPlan?.accountId) {
      this.isCallPlanActivitiesEnabled = false;
    }
    /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
   }

  initializeSectionHeader() {
    this.detailsHeaderModel = {
      id: 'details',
      title: `${this.translate.instant('DETAILS_CAP')}`,
      controls: []
    }
    this.overviewHeaderModel = {
      id: 'overview',
      title: `${this.translate.instant('OVERVIEW')}`,
      controls: []
    }
    let meetingTitleText = `${this.translate.instant('ENGAGEMENTS')}`;
    if(this.translate.currentLang == 'es' || this.translate.currentLang == 'it') {
      meetingTitleText = `${this.translate.instant('ENGAGEMENTS_CALL_PLAN')}`;
    }
    this.meetingsHeaderModel = {
      id: 'meetings',
      title: meetingTitleText,
      controls: []
    }
    let messageTitleText = `${this.translate.instant('MESSAGES')}`;
    if(this.translate.currentLang == 'es') {
      messageTitleText = `${this.translate.instant('MESSAGES_CALL_PLAN')}`;
    }
    this.messagingHeaderModel = {
      id: 'messages',
      title: messageTitleText,
      controls: []
    }
    this.F2FMeetingHeaderModel = {
      id: 'F2F_meetings',
      title: `${this.translate.instant('F2F_MEETINGS')}`,
      doNotModifyTitleCase:true,
      controls: []
    }
    this.remoteMeetingHeaderModel = {
      id: 'remote_meetings',
      title: `${this.translate.instant('REMOTE_MEETINGS')}`,
      controls: []
    }
    this.phoneCallHeaderModel = {
      id: 'phone_calls',
      title: `${this.translate.instant('PHONE_CALLS')}`,
      controls: []
    }
    this.coverageHeaderModel = {
      id: 'coverage',
      title: `${this.translate.instant('COVERAGE_TEAM_CAP')}`,
      controls: []
    }
    /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
    this.activitiesHeaderModel = {
      id: 'activities',
      title: `${this.translate.instant('ACTIVITIES')}`,
      controls: []
    }
    /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
  }

  private initPageTitle(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    })
    if (this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      buttons.push({
        id: "not-approve",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('NOT_APPROVED'),
        isDisabled: this.callPlanService.selectedRepCallPlan.statuscode != 100000003,
        align: "right"
      },
        {
          id: "approve",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('APPROVE'),
          isDisabled: this.callPlanService.selectedRepCallPlan.statuscode != 100000003,
          align: "right"
        });
    } else {
      buttons.push({
        id: "clear",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CLEAR'),
        isDisabled: this.callPlanService.selectedRepCallPlan.statuscode != 1,
        align: "right"
      },
        {
          id: "send",
          imgSrc: 'assets/imgs/header_send.svg',
          name: this.translate.instant('SEND'),
          isDisabled: this.callPlanService.selectedRepCallPlan.statuscode != 1 || this.callPlanService.isSendDisabled,
          align: "right"
        });
    }
    this.pageTitle = {
      id: 'call-plan-cust-detail',
      title: this.callPlanService.selectedRepCallPlan && this.callPlanService.selectedRepCallPlan?.indskr_name,
      controls: buttons,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.backToCallplanDetails()
        break;
      case 'not-approve':
        this.reject();
        break;
      case 'approve':
        this.approve();
        break;
      case 'clear':
        this.clear();
        break;
      case 'send':
        this.sendForApproval();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  ngOnDestroy() {
    if (this.uiService.activeViewOnPlanTab == 'customerCallPlanDetails') this.uiService.activeViewOnPlanTab = undefined;
    this.events.unsubscribe('customer-call-plan-details:footerEvents', this.footerHandler);
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }

  backToCallplanDetails() {
    this.trackingService.tracking('MyCallPlanRepCallPlanDetailsBack', TrackingEventNames.CALLPLAN)
    if (this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab === 'plans') this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    else this.uiService.activeView = 'CallPlanDetails';
    this.navService.popChildNavPageWithPageTracking().then(() => {
      this.footerService.initButtons(FooterViews.CallPlanDetails)
      this.events.publish('refresh-callplan-activity');
      this.callPlanService.selectedRepCallPlan.duration = `${this.translate.instant('CALL_PLAN_DURATION', { startDate: this.callPlanService.selectedRepCallPlan.startDate, endDate: this.callPlanService.selectedRepCallPlan.endDate })} | ${this.statePipe.transform(this.callPlanService.selectedRepCallPlan.statuscode)}`;;
    });
  }

  clear(isClickedFromFooter?: boolean) {
    if (isClickedFromFooter) this.trackingService.tracking('MyCallPlanClearSuggestionsActionBar', TrackingEventNames.CALLPLAN);
    else this.trackingService.tracking('MyCallPlanClearSuggestions', TrackingEventNames.CALLPLAN);
    this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls = 0;
    this.callPlanService.selectedRepCallPlan.indskr_suggestedemails = 0;
    this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings = 0;
    this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings = 0;
    this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings = 0;
    //-----------------//Allow Reps to submit Call plans adjustments//-----------------//
    this.callPlanService.selectedRepCallPlan.indskr_commentsforcalls = '';
    this.callPlanService.selectedRepCallPlan.indskr_commentsforemails = '';
    this.callPlanService.selectedRepCallPlan.indskr_commentsforf2fmeetings = '';
    this.callPlanService.selectedRepCallPlan.indskr_commentsforremotemeetings = '';
    this.callPlanService.selectedRepCallPlan.indskr_commentsforphonecallmeetings = '';
    //-----------------//Allow Reps to submit Call plans adjustments//-----------------//
  }

  public async setSuggestedMeeting(value, eventName) {
    if (eventName == 'input_value_confirm') {
      this.isClickedMeetingSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
    this.initPageTitle();
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  public async setSuggestedEmail(value, eventName) {
    if (eventName == 'input_value_confirm') {
      this.isClickedMessageSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedemails = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
    this.initPageTitle();
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  public async setSuggestedF2FMeeting(value, eventName) {
    if (eventName == 'input_value_confirm') {
      this.isClickedF2FMeetingSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
    this.initPageTitle();
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  public async setSuggestedRemoteMeeting(value, eventName) {
    if (eventName == 'input_value_confirm') {
      this.isClickedRemoteMeetingSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
    this.initPageTitle();
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  public async setSuggestedPhoneCall(value, eventName) {
    if (eventName == 'input_value_confirm') {
      this.isClickedPhoneCallSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
    this.initPageTitle();
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  public async approve() {
    if (this.device.isOffline) {
      this.notifications.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'callPlanCustDetails', 'top', undefined, 2000, true);
      return;
    }
    if (this.callPlanService.isSendDisabled) {
      this.notifications.notify(this.translate.instant('PLEASE_ENTER_THE_SUGGESTED_VALUE'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    this.alertService.showAlert({
      title: this.translate.instant('APPROVE'),
      message: this.translate.instant('APPROVE_THIS_PLANS_SUGGESTIONS')
    }, this.translate.instant('APPROVE')
    ).then(res => {
      if (res.role == "ok") {
        this.callPlanDataService.updateCallPlan(this.callPlanService.selectedRepCallPlan, 1, RepCallPlanState.Approved,
          this.callPlanService.selectedRepCallPlan.indskr_suggestedemails, this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls,this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings,
          this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings, this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings)
          .then(() => {
            this.footerService.initButtons(FooterViews.CustomerCallPlan);
            this._cd.markForCheck();
            this.initPageTitle();
          });
      }
    });
  }

  public async reject() {
    if (this.device.isOffline) {
      this.notifications.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'callPlanCustDetails', 'top', undefined, 2000, true);
      return;
    }
    if (this.callPlanService.isSendDisabled) {
      this.notifications.notify(this.translate.instant('PLEASE_ENTER_THE_SUGGESTED_VALUE'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    this.alertService.showAlert({
      title: this.translate.instant('NOT_APPROVED'),
      message: this.translate.instant('NOT_APPROVE_THIS_PLANS_SUGGESTIONS')
    }
    ).then(res => {
      if (res.role == "ok") {
        this.uiService.displayLoader();
        this.callPlanDataService.updateCallPlan(this.callPlanService.selectedRepCallPlan, 0, RepCallPlanState.NotApproved,
          this.callPlanService.selectedRepCallPlan.indskr_suggestedemails, this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls, this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings,
          this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings, this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings,)
          .then(() => {
            this.uiService.dismissLoader();
            this.footerService.initButtons(FooterViews.CustomerCallPlan);
            this._cd.markForCheck();
            this.initPageTitle();
          })
          .catch(err => {
            this.uiService.dismissLoader();
          });
      }
    });
  }

  public async sendForApproval(isClickedFromFooter?: boolean) {
    if (isClickedFromFooter) this.trackingService.tracking('MyCallPlanSendforApprovalActionBar', TrackingEventNames.CALLPLAN);
    else this.trackingService.tracking('MyCallPlanSendforApproval', TrackingEventNames.CALLPLAN);
    if (this.device.isOffline) {
      this.notifications.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    if (this.callPlanService.selectedRepCallPlan.totalGoalCalls > 0 && !this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls && this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls <= 0) {
      this.isClickedMeetingSuggested = true;
    }
    else if (this.callPlanService.selectedRepCallPlan.totalGoalEmails > 0 && !this.callPlanService.selectedRepCallPlan.indskr_suggestedemails && this.callPlanService.selectedRepCallPlan.indskr_suggestedemails <= 0) {
      this.isClickedMessageSuggested = true;
    }
    else if (this.callPlanService.selectedRepCallPlan.totalGoalF2FMeetings > 0 && !this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings && this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings <= 0) {
      this.isClickedF2FMeetingSuggested = true;
    }
    else if (this.callPlanService.selectedRepCallPlan.totalGoalRemoteMeetings > 0 && !this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings && this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings <= 0) {
      this.isClickedRemoteMeetingSuggested = true;
    }
    else if (this.callPlanService.selectedRepCallPlan.totalGoalPhoneCalls > 0 && !this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings && this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings <= 0) {
      this.isClickedPhoneCallSuggested = true;
    }
    this.alertService.showAlert({
      title: this.translate.instant('SEND_FOR_APPROVAL')
    }, this.translate.instant('SEND')
    ).then(res => {
      this.uiService.displayLoader();
      if (res.role == "ok") {
        this.callPlanDataService.updateCallPlan(this.callPlanService.selectedRepCallPlan, 0, RepCallPlanState.ForReview,
          this.callPlanService.selectedRepCallPlan.indskr_suggestedemails, this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls, this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings,
          this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings, this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings ,true)
          .then(() => {
            this.uiService.dismissLoader();
            this.footerService.initButtons(FooterViews.CustomerCallPlan);
            this._cd.markForCheck();
            this.initPageTitle();
          })
          .catch(err => {
            this.uiService.dismissLoader();
          });
      }
    });
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.callPlanService.selectedRepCallPlan.productid_Formatted ? this.callPlanService.selectedRepCallPlan.productid_Formatted : this.translate.instant('NO_PRODUCT'),
      id: 'order-number-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !(this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.productName)
    };
    return viewData;
  }

  public getStatusFormField(): IndFormFieldViewDataModel {

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('STATUS'),
      inputText: this.callPlanService.selectedRepCallPlan.statuscode ? this.statePipe.transform(this.callPlanService.selectedRepCallPlan.statuscode) : '',
      id: 'callplan-custdetails-status-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getCustNameFormField(): IndFormFieldViewDataModel {
    if(this.callPlanService.selectedRepCallPlan?.contactId) {
      let viewData: IndFormFieldViewDataModel = {
        label: this.utilityService.globalCustomerText,
        inputText: this.callPlanService.selectedRepCallPlan?.customerName || "",
        id: 'callplan-custdetails-name-field-contact',
        isReadOnly: true,
        isDisabled: !this.hasContact(),
        showArrow: this.hasContact(),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      return viewData;
    }else {
      let viewData: IndFormFieldViewDataModel = {
        label: this.utilityService.globalCustomerText,
        inputText: this.callPlanService.selectedRepCallPlan?.customerName || "",
        id: 'callplan-custdetails-name-field-account',
        isReadOnly: true,
        isDisabled: !this.hasAccount(),
        showArrow: this.hasAccount(),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      return viewData;
    }
  }

  private hasContact(): boolean {
    let contact = this.contactService.getContactByID(this.callPlanService.selectedRepCallPlan['contactId']);
    if (contact) return true;
    else return false;
  }

  private hasAccount(): boolean {
    const foundAccount = this.accountService.getAccountById(this.callPlanService.selectedRepCallPlan['accountId']);
    return !_.isEmpty(foundAccount);
  }

  public getStartDateFormField(): IndFormFieldViewDataModel {


    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CP_PERIOD_START_DATE'),
      inputText: this.callPlanService.selectedRepCallPlan && this.callPlanService.selectedRepCallPlan?.indskr_startdate
        ? this.datePipe.transform(this.callPlanService.selectedRepCallPlan.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
        : this.translate.instant('N_A'),
      id: 'callplan-custdetails-startdate-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getEndDateFormField(): IndFormFieldViewDataModel {

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CP_PERIOD_END_DATE'),
      inputText: this.callPlanService.selectedRepCallPlan && this.callPlanService.selectedRepCallPlan?.indskr_enddate
        ? this.datePipe.transform(this.callPlanService.selectedRepCallPlan.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
        : this.translate.instant('N_A'),
      id: 'callplan-custdetails-enddate-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getSegmentFormField(): IndFormFieldViewDataModel {

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SEGMENT'),
      inputText: this.callPlanService.selectedRepCallPlan?.segmentid_Formatted ? this.callPlanService.selectedRepCallPlan?.segmentid_Formatted : '',
      id: 'callplan-custdetails-segment-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getPositionFormField(): IndFormFieldViewDataModel {

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('POSITION'),
      inputText: this.callPlanService.selectedRepCallPlan?.positionName ? this.callPlanService.selectedRepCallPlan?.positionName : '',
      id: 'callplan-custdetails-position-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  //-----------------//Allow Reps to submit Call plans adjustments//-----------------//
  public isFieldDisabled(commentsForFieldName: string) {
    let isDisabled = false;
    const indskr_comments = this.callPlanService.selectedRepCallPlan[commentsForFieldName] ? this.callPlanService.selectedRepCallPlan[commentsForFieldName] : '';

    if (this.callPlanService?.selectedRepCallPlan?.isTeamCallPlan) {
      isDisabled = !indskr_comments;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1 && !indskr_comments;
    }
    return isDisabled;
  }
  public hasComment(commentsForFieldName: string) {
    return !!(this.callPlanService.selectedRepCallPlan[commentsForFieldName]);
  }

  public isFieldMandatory(){
    let isFieldMandatory = false;
    if (!this.callPlanService.selectedRepCallPlan?.isTeamCallPlan) {
      isFieldMandatory = this.callPlanService.selectedRepCallPlan.statuscode === 1;
    }
    return isFieldMandatory;
  }
  //-----------------//Allow Reps to submit Call plans adjustments//-----------------//

  public getMeetingGoalValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_hocalls != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_hocalls) : '';
    return value;
  }

  public getMeetingSuggestedValue() {
    let value = (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls : 0;
    return value;
  }

  public getMeetingSuggestedFormField(): IndFormFieldViewDataModel {
    let isDisabled = false;
    if (this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }
    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly:false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedMeetingSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls <= 0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls),
      eventHandler: (id: string, event, eventName) => this.setSuggestedMeeting(event.target.value, eventName),
    };
    return viewData;
  }

  public getMeetingCompletedValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_actualcalls != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_actualcalls) : 0;
    return value;
  }

  public getMeetingCompletionValue() {
    let value = this.getCompletionPercentage('indskr_meetingcompletionpercent') + "%";
    return value;
  }

  public getMessageGoalValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_hoemails != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_hoemails) : '';
    return value;
  }

  public getMessageSuggestedValue() {
    let value = (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedemails : 0;
    return value;
  }

  public getMessageSuggestedFormField(): IndFormFieldViewDataModel {
    let isDisabled = false;
    if (this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedemails) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedemails : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly: false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedMessageSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails <= 0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedemails),
      eventHandler: (id: string, event, eventName) => this.setSuggestedEmail(event.target.value, eventName),
    };
    return viewData;
  }

  public getMessageCompletedValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_actualemails != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_actualemails) : 0;
    return value;
  }

  public getMessageCompletionValue() {
    let value = this.getCompletionPercentage('indskr_emailcompletionpercent') + "%";
    return value;
  }

  public getF2FMeetingGoalValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_f2fgoals != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_f2fgoals) : '';
    return value;
  }

  public getF2FMeetingSuggestedValue() {
    let value = (this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings : 0;
    return value;
  }

  public getF2FMeetingSuggestedFormField(): IndFormFieldViewDataModel {
    let isDisabled = false;
    if (this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly: false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedF2FMeetingSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings <= 0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedf2fmeetings),
      eventHandler: (id: string, event, eventName) => this.setSuggestedF2FMeeting(event.target.value, eventName),
    };
    return viewData;
  }

  public getF2FMeetingCompletedValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.actualF2FMeetings != undefined) ? String(this.callPlanService.selectedRepCallPlan?.actualF2FMeetings) : 0;
    return value;
  }

  public getF2FMeetingCompletionValue() {
    let value = this.getCompletionPercentage('indskr_f2fmeetingcompletion') + "%";
    return value;
  }

  public getRemoteMeetingGoalValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_remotemeetinggoals != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_remotemeetinggoals) : '';
    return value;
  }

  public getRemoteMeetingSuggestedValue() {
    let value = (this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings : 0;
    return value;
  }

  public getRemoteMeetingSuggestedFormField(): IndFormFieldViewDataModel {
    let isDisabled = false;
    if (this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly: false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedRemoteMeetingSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings <= 0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedremotemeetings),
      eventHandler: (id: string, event, eventName) => this.setSuggestedRemoteMeeting(event.target.value, eventName),
    };
    return viewData;
  }

  public getRemoteMeetingCompletedValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.actualRemoteMeetings != undefined) ? String(this.callPlanService.selectedRepCallPlan?.actualRemoteMeetings) : 0;
    return value;
  }

  public getRemoteMeetingCompletionValue() {
    let value = this.getCompletionPercentage('indskr_remotemeetingcompletion') + "%";
    return value;
  }

  public getPhoneCallGoalValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.indskr_phonecallgoals != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_phonecallgoals) : '';
    return value;
  }

  public getPhoneCallSuggestedValue() {
    let value = (this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings : 0;
    return value;
  }

  public getPhoneCallSuggestedFormField(): IndFormFieldViewDataModel {
    let isDisabled = false;
    if (this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly: false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedPhoneCallSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings <= 0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedphonecallmeetings),
      eventHandler: (id: string, event, eventName) => this.setSuggestedPhoneCall(event.target.value, eventName),
    };
    return viewData;
  }

  public getPhoneCallCompletedValue() {
    let value = (this.callPlanService.selectedRepCallPlan?.actualPhoneCalls != undefined) ? String(this.callPlanService.selectedRepCallPlan?.actualPhoneCalls) : 0;
    return value;
  }

  public getPhoneCallCompletionValue() {
    let value = this.getCompletionPercentage('indskr_phonecallscompletion') + "%";
    return value;
  }

  public getCoverateTeamDataModel(repCallPlan): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: repCallPlan.ID,
      primaryTextRight: repCallPlan.contactFirstName + ' ' + repCallPlan.contactLastName,
      primaryTextLeft: (repCallPlan.indskr_meetingcompletionpercent || 0) + '%',
      isPrimaryTextLeftPillType: true,
      primaryTextLeftTwo: this.translate.instant('STATUS') + ' - ' + this.statePipe.transform(repCallPlan.statuscode),
      secondaryTextRight: repCallPlan.positionName,
      clickHandler: (id: string, event) => {
      },
    }
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'callplan-custdetails-name-field-contact':
          let contact = this.contactService.getContactByID(this.callPlanService.selectedRepCallPlan['contactId']);
          if (contact) this.openContactDetails(contact);
          break;
        case 'callplan-custdetails-name-field-account':
          const foundAccount = this.accountService.getAccountById(this.callPlanService.selectedRepCallPlan['accountId']);
          if (foundAccount) this.openAccountDetails(foundAccount);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private openContactDetails(contact) {
    this.contactService.contactInformation = contact;
    if (this.device.isOffline) {
      this.contactService.contactInformation = this.contactService.contacts.find(x => x.ID == contact.ID);
    }
    //this.uiService.contactDetailsSegment = 'info';
    this.contactService.contactPageMode = ComponentViewMode.READONLY;
    if (this.uiService.activeView == 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans') {
      this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.ActivitiesDetailsPaneComponent, { contactListMode: ComponentViewMode.READONLY });
    } else {
      this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.CallPlanComponent, { contactListMode: ComponentViewMode.READONLY });
    }
  }

  private openAccountDetails(account) {
    this.accountService.selected = account;
    if (this.device.isOffline) {
      this.accountService.selected = this.accountService.accounts.find(x => x.id == account.id);
    }
    this.accountService.accountPageMode === ComponentViewMode.READONLY
    if (this.uiService.activeView == 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans') {
      this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.ActivitiesDetailsPaneComponent, { accountListMode: ComponentViewMode.READONLY });
    } else {
      this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.CallPlanComponent, { accountListMode: ComponentViewMode.READONLY });
    }
  }

  private getCompletionPercentage(field: string) { 
    let value = this.callPlanService.selectedRepCallPlan[field];
    if (value == undefined) value = 0;
    else if (value > 100) value = 100;
    return value;
  }

  private _checkBUConfig() {
    if(this.authService.user.buConfigs['indskr_callplanprogress']) this.hasCallPlanProgress = true;
    if(this.authService.user.buConfigs['indskr_displaygoalsbeyond100']) this.displaygoalsbeyond100 = true;
    if(this.authService.user.buConfigs['indskr_displayallactivitiesprogress']) this.displayAllActProgress = true;
  }

  /*=============================Account Call Plan=============================*/
  public async showAddSuggestedPopover(commentsForFieldName: string, suggestedValueForFieldName: string){
    //To check if the field/button for popup is disabled in the UI then return 
    if(this.isFieldDisabled(commentsForFieldName))
     return;

    const indskr_comments = this.callPlanService.selectedRepCallPlan[commentsForFieldName] ? this.callPlanService.selectedRepCallPlan[commentsForFieldName] : '';
    const indskr_suggestedValue = this.callPlanService.selectedRepCallPlan[suggestedValueForFieldName] ? this.callPlanService.selectedRepCallPlan[suggestedValueForFieldName] : 0;

    if (
      (this.callPlanService.selectedRepCallPlan.statuscode !== 1 && indskr_comments)
      || this.callPlanService.selectedRepCallPlan.isTeamCallPlan
    ) {
      const popover = await this.popoverCtrl.create({
        component: CustomerCallPlanPopover,
        componentProps: {
          header: this.translate.instant("SUGGESTED") + '*',
          inputTextValue: indskr_suggestedValue,
          textAreaValue: indskr_comments,
          isReadOnly: true
      },
        cssClass: 'alert-with-input-list-view-for-callplan-adjustment-reason',
        backdropDismiss: true,
      });
      popover.present();
    } else {
      const popover = await this.popoverCtrl.create({
        component: CustomerCallPlanPopover,
        componentProps: {
          header: this.translate.instant("ADD_SUGGESTED") + '*',
          inputTextValue: indskr_suggestedValue,
          textAreaPlaceholder: this.translate.instant('ADD_COMMENT') + '...',
          textAreaValue: indskr_comments,
          confirmText: this.translate.instant('SAVE'),
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isReadOnly: false
        },
        cssClass: 'alert-with-input-list-view-for-callplan-adjustment-reason',
        backdropDismiss: true,
        event: this.device.isNativeApp ? event : null
      });
      popover.present();
      popover.onDidDismiss().then(async (res) => {
        if (res.data && res.data.role && res.data.role == 'ok' && res.data.suggestedValue) {
          this.callPlanService.selectedRepCallPlan[commentsForFieldName] = res.data.comments;
          this.callPlanService.selectedRepCallPlan[suggestedValueForFieldName] = res.data.suggestedValue;

          await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
          this.initPageTitle();
          this.footerService.initButtons(FooterViews.CustomerCallPlan);
        }
      });
    }
  }
  /*=============================Account Call Plan=============================*/
}
