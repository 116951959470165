<div class='container'>
  <div class="videobox">
    <div #videoContainer class="videocontainer">
    </div>
  </div>
  <div class="transbox">
    <p>
      {{initials}}
    </p>
  </div>
  <div class="text-element">
    <p>
      {{name}}
    </p>
    <ion-button fill="clear" icon-only (click)="handleUnmuteClicked()">
      <ion-icon class="mute-icon" slot="icon-only" [src]="hasAudio ? 'assets/imgs/unmute-subscriber.svg' : 'assets/imgs/mute-subscriber.svg'">
      </ion-icon>
    </ion-button>
  </div>
</div>
