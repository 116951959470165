import { Component } from '@angular/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@omni/services/device/device.service';
import { NavParams } from '@ionic/angular';
import { UIService } from '@omni/services/ui/ui.service';
import { TrackService, TrackingEventNames } from '@omni/services/logging/tracking.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { XperiencesService, IMPACT_SEGMENT } from '@omni/services/xperiences/xperiences.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { TrendingCustomer, ImpactDetails } from '@omni/classes/xperiences/trending.customer.class';
import { CustomerInsightComponent } from '@omni/components/contact/customer-insight/customer-insight';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import * as _ from 'lodash';

@Component({
  selector: "impact-details-pane",
  templateUrl: "impact-details-pane.html",
  styleUrls: ['impact-details-pane.scss']
})
export class ImpactDetailsComponent {

  public headerModel: IndPageTitleViewDataModel;
  public impactDetails: ImpactDetails;
  public plansAndPredictions = [];

  constructor(
    private device: DeviceService,
    private uiService: UIService,
    private trackingService: TrackService,
    private navService: NavigationService,
    private translate: TranslateService,
    private xperiencesService: XperiencesService,
    private navParams: NavParams,
    public footerService: FooterService
  ) {

  }

  ngOnInit() {
    this.footerService.initButtons(FooterViews.None);
    this.impactDetails = this.navParams.get("imapactDetails");
    this.plansAndPredictions = _.uniq(_.concat(this.impactDetails.plans ? this.impactDetails.plans : [], this.impactDetails.predictions ? this.impactDetails.predictions : []));
    this.initHeader();
  }

  private initHeader() {
    this.headerModel = {
      id: 'xperiences-customer',
      title: this.impactDetails.title,
      controls: []
    };
    if (this.impactDetails.segment === IMPACT_SEGMENT.CONTACT) {
      this.headerModel.controls.push({
        id: 'insight',
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('XPERIENCES_INSIGHT'),
        isDisabled: this.device.isOffline,
        align: 'right',
      });
    }

  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closepage();
        break;
      case 'insight':
        if (this.device.isOffline) {
          this.initHeader();
          return;
        }
        this.createInsight();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public closepage() {
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    this.xperiencesService.selectedXpContact = null;
    this.xperiencesService.resetFilters();
    this.uiService.showRightPane = false;
  }

  public createInsight() {
    this.trackingService.tracking('XperiencesInsights+', TrackingEventNames.INSIGHTSPLUS);
    this.xperiencesService.enableInsight = false;
    this.uiService.showRightPane = true;
    let newInsight: TrendingCustomer = new TrendingCustomer();
    newInsight.contactid = this.impactDetails.data.contactId;
    newInsight.fullname = this.impactDetails.title;
    this.xperiencesService.newInsight = newInsight;
    this.xperiencesService.isFromCustomerXperience = true;
    this.navService.pushChildNavPageWithPageTracking(CustomerInsightComponent, PageName.CustomerInsightComponent, PageName.ActivitiesPageComponent);
  }

}
