import { Component, Input, HostListener, ViewChild, ElementRef } from "@angular/core";
import { Contact, AccountRelation, ContactAccountRole } from "../../../../classes/contact/contact.class";
import { format } from "date-fns";
import { AccountOfflineService, AccountSelectedFor } from "../../../../services/account/account.offline.service";
import { Account } from "../../../../classes/account/account.class";
import { ContactOfflineService } from "../../../../services/contact/contact.service";

import { Events } from '@omni/events';
import { NavigationService, ChildNavNames, PageName } from "../../../../services/navigation/navigation.service";
import { AccountPageComponent } from "../../../account/account-page/account-page";
import { ComponentViewMode } from "../../../../services/ui/ui.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../../../../services/alert/alert.service";


@Component({
    selector: 'contact-account-aff',
    templateUrl: 'contact-acount-affiliation.html',
  styleUrls:['contact-acount-affiliation.scss']
})
export class ContactAcountAffiliationComponent {

    public selectedContact: Contact;
    public contactAccountAff:AccountRelation;
    public selectedAccount:Account;
    public contactRole = {
        key: 1,
        value: null,
    }
    public isDirectAffiliation: boolean = false;
    public startTime: string;
    public endTime: string;
    public comments: string = '';
    public accountList: Account[] = [];
    public contactFullName:string = '';
    public contactRoles = [];

    public max: string;

    // @ViewChild('accInput') accInput: ElementRef;
    // @ViewChild('accList') accList:ElementRef;

    constructor(
        private accountService: AccountOfflineService,
        private contactService: ContactOfflineService,
        private alertService: AlertService,
        private navService: NavigationService,
        private events: Events,
        private translate: TranslateService
    ) {
        let today = new Date();
        this.startTime = format(today);
        this.endTime = format(today.setDate(today.getDate()) + 1);
        this.max = format(today.setFullYear(today.getFullYear() + 10))

        this.selectedContact = this.contactService.tempContactInformation;
        this.contactFullName = `${this.selectedContact['firstName']}  ${this.selectedContact['lastName']}`;

        this.events.subscribe('contact:account', (selectedAccount) => {
            this.selectedAccount = selectedAccount;
        });
    }

    // @HostListener('document:click', ['$event'])
    // @HostListener('document:touchstart', ['$event'])
    // handleOutsideClick(event) {
    //     if (this.accInput['_elementRef'].nativeElement
    //             && !this.accInput['_elementRef'].nativeElement.contains(event.target)
    //             && !this.accList.nativeElement.contains(event.target) ){
    //         this.accountList = [];
    //     }
    // }

    ngOnInit(){
        this.contactRoles = this.contactService.contactRolesValues;
        if(this.contactRoles) {
            this.contactRole = this.contactRoles[0];
        }
    }

    ngOnDestroy() {
        this.events.unsubscribe('contact:account');
    }


    getItems(ev: any) {

        const val = ev.target.value;
        this.selectedAccount = undefined;
        if (val && val.trim() != '') {
            this.accountList = this.accountService.accounts.filter((item) => {
                const isExists = this.contactService.tempContactInformation.accountRelationships.some(acc => acc.accountId === item.id);
                return (isExists) ? false : (item.accountName.toLowerCase().indexOf(val.toLowerCase()) > -1);
            });
        }else{
            this.accountList= [];
        }
    }

    setAccount(acc){
        if(this.selectedAccount){
            this.accountList = [];
        }
    }

    handleEndDateTime(eve){
        if (new Date(this.endTime).getTime() < new Date(this.startTime).getTime()) {
            this.endTime = this.startTime;
        }
    }

    handleStartDateTime(eve){

    }

    closepage(isCancel:boolean) {
        if(!isCancel){
            let addObj: AccountRelation = {
                accountId: this.selectedAccount.id,
                // accountContactAffiliationId: '',
                accountName: this.selectedAccount.accountName,
                name: this.selectedAccount.accountName,
                // startDate: this.startTime,
                // endDate: this.endTime,
                createdDate: format(new Date()),
                // comments: this.comments,
                contactRoleId: '' + this.contactRole.key,
                contactRole: this.contactRole.value,
                // directRelation: this.isDirectAffiliation ? 'Yes' : 'No',
                status: 'Active',
                statecode: '' + 1,
                // expandIcon: 'arrow-dropup1',
                // showDetails: false,
                isNew: true
            };

            if(!this.selectedAccount) return;
            this.contactService.tempContactInformation.accountRelationships.push(addObj);
            this.contactService.selectedAccountAffiliation(addObj);
            this.navService.popChildNavPageWithPageTracking();
            // this.viewCtrl.dismiss();
        }else{
            if(this.selectedAccount){
                let cancelText = this.translate.instant("CANCEL");
                if(this.translate.currentLang == 'it') {
                  cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
                }
                this.alertService.showAlert({
                    title: this.translate.instant('DISCARD_CHANGES'),
                    message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
                  ).then (res => {
                    if(res.role == "ok") {
                        this.navService.popChildNavPageWithPageTracking();
                    }
                });

            } else {
                this.navService.popChildNavPageWithPageTracking();
                // this.viewCtrl.dismiss();
            }
        }
    }

    public openAccountSelection() {
        this.accountService.selectedFor = AccountSelectedFor.CONTACT_ACCOUNT_SELECTION;
        this.accountService.accessedAccountListFrom = PageName.ContactAcountAffiliationComponent;
        this.navService.pushWithPageTracking( AccountPageComponent, PageName.AccountPageComponent,
                                            { 'listMode': ComponentViewMode.ADDNEW, type: 'PushedAccountSelectionView', paramAccount: this.selectedAccount ? (this.selectedAccount.id || '') : ''},
                                            PageName.ContactAcountAffiliationComponent, { animate: false }, null);

    }

}


// @Component({
//     template: `
//     <ion-list class="accountList" *ngIf="accountList.length>0" radio-group [(ngModel)]="selectedAccount">
//         <ion-item *ngFor="let account of accountList">
//             <ion-label>{{account.accountName}}</ion-label>
//             <ion-radio [value]="account" (ionSelect)="setAccount(account)"></ion-radio>
//         </ion-item>
//     </ion-list>
//     `
//   })
//   export class AccountSearchPopoverPage {

//     public selectedAccount:Account;
//     public accountList:Account[] = [];

//     constructor(public viewCtrl: ViewController, public navParams:NavParams) {
//         this.accountList = (this.navParams.data && this.navParams.data.accountList);
//     }

//     close() {
//       this.viewCtrl.dismiss();
//     }
//   }
