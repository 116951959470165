import { SelectListData } from './../../models/select-list-data-model';
import { SelectListComponent } from './../../components/shared/select-list/select-list';
import { SelectListDetail } from './../../models/select-list-detail-model';
import { Injectable } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
    currentAlert: HTMLIonAlertElement;
    constructor(
        private alertCtrl: AlertController,
        private translate: TranslateService,
        private modalCtrl: ModalController,
    ) {
    }

    async alert(title, message, buttons: any[]) {
        let options: any = {
            title: title,
            message: message,
            buttons: buttons
        }
        if (this.currentAlert) {
            this.currentAlert.dismiss();
            this.currentAlert = null;
        }
        this.currentAlert = await this.alertCtrl.create(options);
        this.currentAlert.present();
        this.currentAlert.onDidDismiss().then(() => {
            this.currentAlert = null;
        });
        return this.currentAlert;
    }

    /**
     *
     * @param options AlertOptions. Don't have to include buttons property
     * @param altConfirmText Defaults to "OK". Can be changed. Pass a string.
     * @param altCancelText Defaults to "Cancel". Should not be changed
     */
    async showAlert(options: any, altConfirmText?: string, altCancelText?: string) {
        if(options.title){
          options.header = options.title
          delete options.title
        }
        if(options.subTitle){
          options.subHeader = options.subTitle
          delete options.subTitle
        }
        let buttons = [{ text: altCancelText ? altCancelText : this.translate.instant('CANCEL'), role: 'cancel'},
                      { text: altConfirmText ? altConfirmText : this.translate.instant('CONFIRM'), role: 'ok' }];
        if(!options["buttons"]) options["buttons"] = buttons;

        if (this.currentAlert) {
          this.currentAlert.dismiss();
          this.currentAlert = null;
        }
        return this.alertCtrl.create(options)
                .then((alert)=>{
                  this.currentAlert = alert;
                  this.currentAlert.present();
                  return this.currentAlert.onDidDismiss();
                })
                .then((res)=> res)
    }

    async showModal(component: any, data?: any, opts?) {
      return this.modalCtrl.create({component: component,componentProps: data, ...opts})
      .then((modal)=>{
        modal.present();
        return modal.onDidDismiss()
      }).then((res)=> res)
  }

  async showSelectListModal(selectListDetail: SelectListDetail, opts = { enableBackdropDismiss: false }) {
    let { data: { selectedItems, isDone } } = await this.showModal(SelectListComponent, { viewData: selectListDetail }, opts);
    return { selectedItems, isDone } as { selectedItems: Array<SelectListData>, isDone: boolean };
}

// this.alertService.showAlert({
    //     title: this.translate.instant('TITLE'),
    //     message: this.translate.instant('MESSAGE')}
    // ).then (res => {
    //     if(res.role == "ok") {

    //     }
    // });

}
