<ion-grid class="content-page">
<!-- <ion-grid>     -->
    <ion-row no-padding >
        <ion-col class="presentation-left-pane" [ngClass]="!isPortrait ? 'presentation-left-pane' :  {'mobile-in-focus': !presentationService.presShowRightPane, 'mobile-hidden': presentationService.presShowRightPane}" no-padding>
            <presentation-list id="pres-list-row" [viewMode]="viewMode" [showPreviewId]="presViewId" [selectedContentIds]="selectedContentIds"
            [callbackEvent]="callbackEvent"></presentation-list>
        </ion-col>
        <ion-col class="presentation-right-pane" [ngClass]="!isPortrait ? 'presentation-right-pane' :  {'mobile-in-focus': presentationService.presShowRightPane, 'mobile-hidden': !presentationService.presShowRightPane}" no-padding>
            <ion-nav #presentationtoolsrightpane></ion-nav>
        </ion-col>
    </ion-row>
</ion-grid>
<!-- <ion-grid class="content-page" *ngIf="viewMode == presView.FULLSCREEN">
    <presentation-preview [viewMode]="viewMode"></presentation-preview>
</ion-grid> -->
<!-- <footer-toolbar *ngIf="!device.isMobileDevice" [footerView]="'both'" [selectedView]="'presentations'"></footer-toolbar>
<footer-toolbar *ngIf="device.isMobileDevice" [footerView]="'masterView'" [selectedView]="'presentations'"></footer-toolbar> -->