<ion-grid no-padding [ngClass]="(isRightPaneNavActive)?'preview-active':'preview-inactive'">
  <ion-row no-padding>
    <ion-col class="select-list-left-pane" [ngClass]="!readOnly?'searchActive':'searchInactive'" no-padding>
      <ion-header>
        <ind-header-left  [viewData]="filterMenuHeaderLeftModel"
                          [hideFilterButton]="true"
                          [searchDisabled]="readOnly"
                          [(searchText)]="searchInput"
                          (onControlClick)="onPageTitleControlClick($event)"
                          (onSearchInput)="searchText($event.detail)"
                          (onSearchClear)="onCancel($event)"
                          >
        </ind-header-left>
      </ion-header>
      <ion-content [ngClass]="{'vectorimageleft' : categories.length === 0}">
          <ion-list *ngIf="viewData" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
              <ng-container *ngIf="!searching">
                  <ind-section-header [viewData]="filterMenuSectionHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                  <ion-item-group>
                      <p *ngIf="categories.length == 0" justify-content-center class="no-data-message">
                          {{ 'NO' | translate}} {{((viewData)?viewData.title:'' )| lowercase}}
                      </p>
                  </ion-item-group>
                  <ion-item-group>
                    <omni-accordion id="saved-filter-toggle" [viewData]="savedFilters" (onToggleSingleItem)="selectSavedCategories(savedFilters, $event)"></omni-accordion>
                  </ion-item-group>
                  <ion-item-group *ngIf="isDisplayDateField">
                    <ion-row>
                      <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
                      <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
                    </ion-row>
                  </ion-item-group>
                  <ng-container *ngFor="let item of categories">
                    <omni-accordion id="category-filter-toggle" [viewData]="item" (onToggleItem)="toggleCategoryValue(item, $event)" (onToggleItemClick)="toggleOptionClick(item,$event)" (onLoadMoreOptions)="loadMoreOptions(item,$event)"></omni-accordion>
                    <ion-row *ngIf="item.id == 'myActivitiesByContact' && item.isExpanded">
                      <ion-col size="6"><ind-datetime-form *ngIf="startDateField && isSelectedMyActivity" [viewData]="startDateField"></ind-datetime-form></ion-col>
                      <ion-col size="6"><ind-datetime-form *ngIf="endDateField && isSelectedMyActivity" [viewData]="endDateField"></ind-datetime-form></ion-col>
                    </ion-row>
                    <div *ngIf="item.id == 'myActivitiesByContact' && item.isExpanded" class="separationLine"></div>
                  </ng-container>
              </ng-container>
              <ng-container *ngIf="searching">
                <ind-section-header [viewData]="searchedFilterMenuSectionHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ion-item-group *ngIf="isDisplayDateField">
                  <ion-row>
                    <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
                    <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
                  </ion-row>
                </ion-item-group>
                <ng-container *ngFor="let item of searchedCategories">
                  <omni-accordion [viewData]="item" (onToggleItem)="toggleCategoryValue(item, $event)" (onToggleItemClick)="toggleOptionClick(item,$event)" (onLoadMoreOptions)="loadMoreOptions(item,$event)"></omni-accordion>
                  <ion-row *ngIf="item.id == 'myActivitiesByContact' && item.isExpanded">
                    <ion-col size="6"><ind-datetime-form *ngIf="startDateField && isSelectedMyActivity" [viewData]="startDateField"></ind-datetime-form></ion-col>
                    <ion-col size="6"><ind-datetime-form *ngIf="endDateField && isSelectedMyActivity" [viewData]="endDateField"></ind-datetime-form></ion-col>
                  </ion-row>
                  <div *ngIf="item.id == 'myActivitiesByContact' && item.isExpanded" class="separationLine"></div>
                </ng-container>
            </ng-container>
          </ion-list>
          <ind-no-search-result *ngIf="searching && searchInput && categories.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
      </ion-content>
      <footer-toolbar *ngIf="isSaveFilterEnabled" [footerView]="'masterView'" [selectedView]="'indFilterMenu'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
      <footer-toolbar *ngIf="!isSaveFilterEnabled" [footerView]="'masterView'" [selectedView]="'select-list'"></footer-toolbar>
    </ion-col>
    <ion-col class="select-list-right-pane" no-padding>
      <ng-container *ngIf="isRightPaneNavActive && optionsList">
        <ion-header>
          <ind-page-title [viewData]='{id:"filter-options-list",title: selectedCategory.primaryText}'
            (onControlClick)='onCloseRightPane()'></ind-page-title>
        </ion-header>
        <ion-content>
          <ion-searchbar (ionInput)="searchOptions($event)" showCancelButton="false" placeholder="{{'SEARCH' | translate}}"></ion-searchbar>
          <virtual-list [source$]="displayOptionList$" (update)="viewPortItems = $event" [height]="'off'" [visibleChildren]="12" [bufferAmount]="3" [childHeight]="96">
            <ion-item *ngFor="let item of viewPortItems" (click)="selectItem(item)">
              <ng-container *ngIf="item.category !== 'Teamusers'">
                <ion-checkbox [checked]="item.isChecked"></ion-checkbox>
                <ion-label> {{item.label}} </ion-label>
              </ng-container>
              <ng-container *ngIf="item.category === 'Teamusers' && !device.isOffline">
                <omni-accordion-item [item]="item" [viewData]="viewData" [depth]="0 + 1">
                </omni-accordion-item>
              </ng-container>
            </ion-item>
          </virtual-list >
          <!-- <ion-list class="dropdown-options-list">
            <ion-item *ngFor="let item of displayOptionsList" (click)="selectItem(item)">
              <ng-container *ngIf="item.category !== 'Teamusers'">
                <ion-checkbox [checked]="item.isChecked"></ion-checkbox>
                <ion-label> {{item.label}} </ion-label>
              </ng-container>
              <ng-container *ngIf="item.category === 'Teamusers' && !device.isOffline">
                <omni-accordion-item [item]="item" [viewData]="viewData" [depth]="0 + 1">
                </omni-accordion-item>
              </ng-container>
            </ion-item>
          </ion-list>
          <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll> -->
          <ind-no-search-result *ngIf="isOptionsSearching && filteredOptionsList.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        </ion-content>
        <!-- <ion-footer> -->
        <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'opportunityList'">
        </footer-toolbar>
      </ng-container>
      <nothing-selected-view *ngIf="!isRightPaneNavActive" [textToDisplay]="''" [notShowText]="true" style="background-color: aqua;" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
    </ion-col>
  </ion-row>
</ion-grid>