<div class="left-float">
    <h3>{{report.createdon | date:'MMM dd': undefined: translate.currentLang}}</h3>
    <p>{{calculateDuration}}</p>
</div>

<div class="coaching-details">
    <h3 *ngIf="type === 'myCoaching'" class="dark-color ellipsis label-font">
        {{'BY' | translate }} {{report.created_by}}
    </h3>
    <h3 *ngIf="type === 'teamCoaching' || type === 'detailView'" class="dark-color ellipsis label-font">
        {{'COACHING_FOR' | translate}} {{(report.indskr_coachingfor_value ? report.created_for : 'COACHING_UNKNOWN_USER') | translate}}
    </h3>
    <div *ngIf="report.indskr_coachingtemplateid && showCoachingScore()">
      <ion-badge style="background-color:#007AFF" *ngIf="hasGreater && report?.indskr_coachingscore">
        <img src="assets/imgs/coaching-report-high.svg" width="10" />
        <span class="margin-left-rating">{{report.indskr_coachingscore + '%'}}</span>
      </ion-badge>
      <ion-badge style="background-color:#FF006E" *ngIf="!hasGreater && report?.indskr_coachingscore">
        <img src="assets/imgs/coaching-report-low.svg" width="10" />
        <span class="margin-left-rating">{{report.indskr_coachingscore + '%'}}</span>
      </ion-badge>
    </div>
    <div *ngIf="!report.indskr_coachingtemplateid">
      <ion-badge style="background-color:#007AFF" *ngIf="report.max_rating?.exist">
        <img src="assets/imgs/coaching-report-high.svg" width="10" />
        <span class="margin-left-rating">{{report.max_rating.value}}</span>
      </ion-badge>
      <ion-badge style="background-color:#FF006E" *ngIf="report.min_rating?.exist">
        <img src="assets/imgs/coaching-report-low.svg" width="10" />
        <span class="margin-left-rating">{{report.min_rating.value}}</span>
      </ion-badge>
    </div>
    <span class="report-satus-label ellipsis" [ngClass]="{'no-label-margin':!report.min_rating?.exist && !report.max_rating?.exist}">
        {{statusName(report.statuscode)}}
    </span>
    <div class="tertiary overflowHiddenAndEllipsis secondaryTextColorAndfont" *ngIf="showTertiary"> {{getTertiaryInfo(report)}} </div>
</div>
