import { Injectable } from '@angular/core';
import { InboundMeetingRequestWebsocketPayload, InboundMeetingRequestDataForPopup } from '../../models/inbound-meeing-request-data-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { InboundMeetingDataService } from '../../data-services/meeting/inbound-meeting.data.service';
import { HttpErrorResponse } from '@angular/common/http';

export const enum REQUEST_STREAM_ACTIONS {
    'QUEUED',
    'ACCEPTED',
    'TIMED_OUT',
    'DECLINED',
    'CANCELED'
}

@Injectable({
  providedIn: 'root'
})
export class InboundMeetingService {
    private _inboundMeetingRequestArray: InboundMeetingRequestDataForPopup[] = [];
    private _inboundMeetingRequestArray$: BehaviorSubject<InboundMeetingRequestDataForPopup[]> = new BehaviorSubject(this._inboundMeetingRequestArray);
    private _inboundMeetingRequestChange$: BehaviorSubject<{ request: InboundMeetingRequestDataForPopup, action: REQUEST_STREAM_ACTIONS }> = new BehaviorSubject(null);
    private _inboundMeetingRequestTimeoutIdList: { inboundRequestId: string, timeoutId: any }[] = [];
    private _inboundMeetingRequestTimeoutIdList$: BehaviorSubject<{ inboundRequestId: string, timeoutId: any }[]> = new BehaviorSubject(this._inboundMeetingRequestTimeoutIdList);
    private _inboundMeetingRequestTimeout$: BehaviorSubject<{ inboundRequestId: string, timeoutId: any }> = new BehaviorSubject(null);

    readonly inboundMeetingRequestArrayObservable: Observable<InboundMeetingRequestDataForPopup[]> = this._inboundMeetingRequestArray$.asObservable();
    readonly inboundMeetingRequestChangeObservable: Observable<{ request: InboundMeetingRequestDataForPopup, action: REQUEST_STREAM_ACTIONS }> = this._inboundMeetingRequestChange$.asObservable();
    readonly inboundMeetingRequestTimeoutObservable: Observable<{ inboundRequestId: string, timeoutId: any }> = this._inboundMeetingRequestTimeout$.asObservable();

    constructor(private _inboundMeetingDataService: InboundMeetingDataService) {}

    queueInboundMeetingRequest(data: InboundMeetingRequestWebsocketPayload) {
        const newData: InboundMeetingRequestDataForPopup = { ...data, timeoutTimestamp: new Date().getTime() + data.timeoutDuration * 1000 };
        this._inboundMeetingRequestArray.push(newData);
        this._inboundMeetingRequestArray$.next(this._inboundMeetingRequestArray);
        this._inboundMeetingRequestChange$.next({ request: newData, action: REQUEST_STREAM_ACTIONS.QUEUED });
    }

    dequeueInboundMeetingRequest(inboundRequestId: string, action: REQUEST_STREAM_ACTIONS) {
        if (inboundRequestId) {
            const idx = this._inboundMeetingRequestArray.findIndex(d => d.inboundRequestId === inboundRequestId);
            if (idx >= 0) {
                const data = this._inboundMeetingRequestArray[idx];
                this._inboundMeetingRequestArray.splice(idx, 1);
                this._inboundMeetingRequestArray$.next(this._inboundMeetingRequestArray);
                this._inboundMeetingRequestChange$.next({ request: data, action: action });
            } else {
                console.warn('dequeueInboundMeetingRequest: request id not found: ', inboundRequestId);
            }
        }
    }

    initiateTimeout(data: InboundMeetingRequestDataForPopup) {
        if (this.checkIfTimeoutDoesNotExist) {
            const timeoutId = setTimeout(() => {
                this._inboundMeetingRequestTimeout$.next({ inboundRequestId: data.inboundRequestId, timeoutId });
                const idx = this._inboundMeetingRequestTimeoutIdList.findIndex(o => o.inboundRequestId === data.inboundRequestId);
                if (idx >= 0) {
                    this._inboundMeetingRequestTimeoutIdList.splice(idx, 1);
                    this._inboundMeetingRequestTimeoutIdList$.next(this._inboundMeetingRequestTimeoutIdList);
                }
            }, data.timeoutDuration * 1000);

            this._inboundMeetingRequestTimeoutIdList.push({ inboundRequestId: data.inboundRequestId, timeoutId });
            this._inboundMeetingRequestTimeoutIdList$.next(this._inboundMeetingRequestTimeoutIdList);
        }
    }

    private checkIfTimeoutDoesNotExist(inboundRequestId: string) {
        return !this._inboundMeetingRequestTimeoutIdList.some(o => o.inboundRequestId === inboundRequestId);
    }

    cancelTimeout(inboundRequestId: string) {
        const idx = this._inboundMeetingRequestTimeoutIdList.findIndex(o => o.inboundRequestId === inboundRequestId);
        if (idx >= 0) {
            clearTimeout(this._inboundMeetingRequestTimeoutIdList[idx].timeoutId);
            this._inboundMeetingRequestTimeoutIdList.splice(idx, 1);
            this._inboundMeetingRequestTimeoutIdList$.next(this._inboundMeetingRequestTimeoutIdList);
        } else {
            console.warn(`cancelTimeout: inboundRequestId '${inboundRequestId}' not found.`);
        }
    }

    cancelAllTimeoutAndDeclineOtherRequests() {
        this._inboundMeetingRequestTimeoutIdList.forEach((obj: { inboundRequestId: string, timeoutId: number }, idx: number) => {
            clearTimeout(obj.timeoutId);
            if (idx > 0) {
                // Decline and dequeue requests in queue
                this._inboundMeetingDataService.declineInboundRequest(obj.inboundRequestId)
                .catch((err: HttpErrorResponse) => {
                    console.error('cancelAllTimeoutAndDeclineOtherRequests: Inbound Decline Error: ', err);
                });
                this.dequeueInboundMeetingRequest(obj.inboundRequestId, REQUEST_STREAM_ACTIONS.DECLINED);
            }
        });

        // Dequeue accepted request at the end
        this.dequeueInboundMeetingRequest(this._inboundMeetingRequestTimeoutIdList[0].inboundRequestId, REQUEST_STREAM_ACTIONS.ACCEPTED);

        this._inboundMeetingRequestTimeoutIdList = [];
        this._inboundMeetingRequestTimeoutIdList$.next(this._inboundMeetingRequestTimeoutIdList);
    }

    getFormattedCallerName(data: InboundMeetingRequestDataForPopup): string {
        if (data) {
            let name = data.firstName ? data.firstName : '';
            name += data.lastName ? data.firstName ? ` ${data.lastName}` : data.lastName : '';
            return name;
        } else {
            return '';
        }
    }
}
