<ion-header>
    <ind-page-title [viewData]="markScanDetailsPageTitle" (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-page-title>
    <ind-section-header [viewData]='headerData'>
    </ind-section-header>
</ion-header>
<ion-content #content [scrollX]="true" id="marketScanTable">
    <div id="cs-details" [style.width.px]="scrollWidth">
        <div class="stick-left" [style.width.px]="contentWidth">
            <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>
            <ind-form-field [viewData]="getSubjectFormField()"></ind-form-field>
            <ind-datetime-form *ngIf="!isWeeklyType" [viewData]="getDateField()"></ind-datetime-form>
            <ind-datetime-form *ngIf="!isWeeklyType && errorMessageField?.isEmptyRequiredField" [errorMessageData]="errorMessageField"></ind-datetime-form>
            <ng-container *ngIf="isWeeklyType">
              <ion-row>
                <ion-col size="6"><ind-datetime-form [viewData]="getDateField()"></ind-datetime-form></ion-col>
                <ion-col size="6"><ind-datetime-form [viewData]="getEndDateField()"></ind-datetime-form></ion-col>
              </ion-row>
              <ind-datetime-form *ngIf="errorMessageField?.isEmptyRequiredField" [errorMessageData]="errorMessageField"></ind-datetime-form>
            </ng-container>

            <ind-form-field [viewData]="getCategoriesFormField()"></ind-form-field>

            <!-- <ind-form-field [viewData]="getTherapeuticAreaFormField()"></ind-form-field> -->

            <ind-form-field *ngIf="selectedMarketScan.category && selectedMarketScan.category?.indskr_capturedatafor !== 548910003 && !showJustAccounts" [viewData]="getProductFormField()"></ind-form-field>

            <ng-container *ngIf="selectedMarketScan.category?.indskr_capturedatafor === 548910002 && !showJustAccounts">
              <ind-form-field [viewData]="getViewFormField()"></ind-form-field>
            </ng-container>

            <ng-container *ngIf="(selectedMarketScan.category?.indskr_capturedatafor === 548910000) || (selectedMarketScan.category?.indskr_capturedatafor === 548910002 && selectedMarketScan.selectView == 'CUSTOMER_VIEW') && !showJustAccounts">
              <ind-form-field [viewData]="getSegmentFormField()"></ind-form-field>

              <ind-form-field [viewData]="getCustomerFormField()"></ind-form-field>
            </ng-container>

            <ng-container *ngIf="selectedMarketScan.category?.indskr_capturedatafor === 548910003 && !showJustAccounts">
              <ind-form-field [viewData]="getCompetitorField()"></ind-form-field>

              <ind-form-field [viewData]="getProcedureFormField()"></ind-form-field>
            </ng-container>
            
            <ng-container *ngIf="selectedMarketScan.category?.indskr_capturedatafor === 548910001 || selectedMarketScan.category?.indskr_capturedatafor === 548910003 || (selectedMarketScan.category?.indskr_capturedatafor === 548910002 && selectedMarketScan.selectView == 'ACCOUNT_VIEW')">
              <ind-form-field [viewData]="getAccountFormField()"></ind-form-field>
            </ng-container>

            <ind-section-header *ngIf="selectedMarketScan.category && !showJustAccounts" [viewData]="tableHeaderData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

            <ng-container *ngIf="isMarketScanMatrixViewAvailable">
              <ion-grid>
                <ion-row class="table-row" id="marketScanDetailsTableRow">
                  <ion-col *ngFor="let itemCol of matrixScanData.matrixColumns;let i = index"
                    [ngClass]="i == 0 ? 'first-column' : 'products-column' ">
                    <div class="header-item">
                      {{itemCol.columnLabel}}
                    </div>
                    <div *ngFor="let item of itemCol['columnItems'];let j = index" class="main-div">
                      <div class="main-label-text">
                        {{item.columnItemLabel}}
                        <div class="main-expand-icon">
                          <ion-icon src="assets/imgs/expand-icon.svg" *ngIf="item.showExpandIcon" (click)="openScanItemDetails(item)"></ion-icon>
                        </div>
                      </div>

                      <div class="item-text" *ngFor="let childItem of item['childItems'];let k = index"
                        [ngClass]="childItem.isMoreHeight ? 'more-height-item-text' : 'normal-height' ">
                        <div class="primary-label-text">
                          {{childItem.childItemLabel}}
                        </div>
                        <div class="secondary-label-text" *ngIf="childItem.childItemSecondaryLabel">
                          {{childItem.childItemSecondaryLabel}}
                        </div>
                        <div *ngFor="let subItem of childItem['subchildItems']; let k = index">
                          <div class="subchild-label-text">
                            {{subItem.subchildItemLabel}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ng-container>
        </div>
    </div>

    <!-- <market-scan-table *ngIf="selectedMarketScan.category" [rows]="rows" [columns]="columns" [subrows]="subrows"
      (dataChanged)="updatetableData($event)" [readonly]="isTableReadOnly"
      [captureDataFor]="selectedMarketScan.category?.indskr_capturedatafor">
    </market-scan-table> -->
</ion-content>
<!-- <ion-footer class="p-paginator-footer"> -->
<p-paginator slot="fixed" class="stick-left p-paginator-footer" [ngStyle]="{'display': (showPaginator && visibleRowsLength > 10 ? 'block' : 'none')}" #paginator *ngIf="visibleRowsLength > 0" [rows]="10" [showFirstLastIcon]="false"
    showCurrentPageReport="true" [totalRecords]="visibleRowsLength" pageLinkSize="5" (onPageChange)="paginate($event)"></p-paginator>
<!-- </ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'marketScanDetails'"></footer-toolbar>
