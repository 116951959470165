import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {  ModalController, PopoverController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { DeviceService } from '@omni/services/device/device.service';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';
import { Specialty } from '../../../../classes/contact/contact.class';
import { CustomerRelationship } from '../../../../classes/xperiences/trending.customer.class';
import { RepServices } from '../../../../data-services/rep/rep.services';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../../models/indSectionHeaderDataModel';
import { AlertService } from '../../../../services/alert/alert.service';
import { ContactOfflineService } from '../../../../services/contact/contact.service';
import { FooterService, FooterViews } from '../../../../services/footer/footer.service';
import { GlobalUtilityService } from '../../../../services/global-utility.service';
import { TrackingEventNames, TrackService } from '../../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../../services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '../../../../services/ui/ui.service';
import { XperiencesService } from '../../../../services/xperiences/xperiences.service';
import { CustomerSelectionMode, OptionSelectionMode } from '../../../../utility/util';
import { PopoverComponent, SelectListData } from '../../../popover/popover';
import { SelectListComponent } from '../../../shared/select-list/select-list';
import { ContactPageComponent } from '../../contact-page/contact-page';
import { IndPageTitleViewDataModel } from './../../../../models/indPageTitleDataModel';
import { ActivityService } from './../../../../services/activity/activity.service';


/**
 * Generated class for the CustomerRelationshipComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'customer-relationship',
  templateUrl: 'customer-relationship.html',
  styleUrls:['customer-relationship.scss']
})
export class CustomerRelationshipComponent implements OnInit, OnDestroy {

  @Input() relationship: CustomerRelationship;
  public tempRelationship: CustomerRelationship;
  private createMode = false;
  private specialities: Specialty[];
  public mode = CustomerSelectionMode;
  @Input() readOnly = false;
  public insightsRelationshipHeaderModel: IndSectionHeaderViewDataModel;
  public relationshipDetailPageHeaderModel: IndPageTitleViewDataModel;
  disableContactSelection = false;
  private isClickedRelatedCustomer: boolean = false;

  constructor(
    public repService: RepServices,
    private readonly trackingService: TrackService,
    public xpService: XperiencesService,
    private readonly navService: NavigationService,
    public readonly footerService: FooterService,
    private readonly uiService: UIService,
    private readonly contactService: ContactOfflineService,
    private readonly events: Events,
    public utilityService: GlobalUtilityService,
    private readonly modalCtrl: ModalController,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly popoverCtrl: PopoverController,
    private readonly navParams: NavParams,
    private readonly activityService: ActivityService,
    ) {
  }

  ngOnInit() {
    this.relationship = (this.navParams.data && this.navParams.data.relationship);
    this.readOnly = (this.navParams.data && this.navParams.data.readOnly);
    this.specialities = cloneDeep(this.contactService.specialties);
    if (!this.relationship) {
      this.relationship = new CustomerRelationship();
      this.createMode = true;
    }
    this.footerService.initButtons(FooterViews.Contacts);

    this.initializeAllSubscription();

    this.tempRelationship = cloneDeep(this.relationship);
    this.intializeRelationshipDetailsHeader();
    this.initInterestDetailsPageTitle();
    if (this.xpService.newInsight && this.xpService.newInsight.contactid) this.disableContactSelection = true;
  }

  private initializeAllSubscription() {
    this.events.subscribe(OptionSelectionMode.SELECT_SPECIALITY.toString(), (res) => {
      this.relationship.selectedSpeciality = res.selected;
    });

    this.events.subscribe('insights_relationship_contact_assignment', () => {
      this.initInterestDetailsPageTitle();
    });
  }

  private initInterestDetailsPageTitle(): void {
    let buttons = [];
    if (this.readOnly) {
      buttons.push({
        id: 'cancel',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    } else {
      buttons.push({
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'right'
      }, {
        id: 'done',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: !this.relationship.contactid,
        align: 'right'
      })
    }
    this.relationshipDetailPageHeaderModel = {
      id: 'customer-relationship',
      title: `${this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS')} +`,
      controls: buttons
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.closepage(false);
        break;
      case 'done':
        this.closepage(true);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

 private intializeRelationshipDetailsHeader() {

    this.insightsRelationshipHeaderModel = {
      id: 'relationship-details-header',
      title: this.translate.instant('XPERIENCES_RELATIONSHIP_DETAILS'),
      controls: [],
    };
  }

  public get customerFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomersText),
      inputText: this.readOnly? this.relationship.customerName : this.xpService.newInsight.fullname,
      customPlaceholderLabel: this.translate.instant('SELECT_WITH_GLOBALCUSTOMER'),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

   public get relatedCustomerFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CONTACT_RELATED_CUSTOMER', { globalCustomerText: this.utilityService.globalCustomerText}),
      inputText: this.relationship.fullname,
      placeholderLabel: this.translate.instant('CONTACT_RELATED_CUSTOMER', { globalCustomerText: this.utilityService.globalCustomerText}),
      id: 'relationship-related-customer-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      isRequired: !this.readOnly,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedRelatedCustomer && _.isEmpty(this.relationship.fullname),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private get categoryInputText() {

    let inputText = '';

    if (this.readOnly) {
        inputText = !this.relationship.selectedSpeciality ? this.translate.instant('XPERIENCES_RELATIONSHIP_NO_CATEGORY') : this.relationship.selectedSpeciality.title;
    } else {
      inputText = !this.relationship.selectedSpeciality ? this.translate.instant('SELECT_CATEGORY') : this.relationship.selectedSpeciality.title;
    }

    return inputText;
  }

  public get relationshipCategoryFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CATEGORY'),
      placeholderLabel: this.translate.instant('CATEGORY'),
      inputText: this.relationship.selectedSpeciality ? this.relationship.selectedSpeciality.title : "",
      id: 'relationship-category-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private get typeInputText() {

    let inputText = '';
    if (this.readOnly) {
        inputText =  this.relationship && _.isEmpty(this.relationship.title) || !this.relationship.title ? this.translate.instant('XPERIENCES_RELATIONSHIP_NO_RELATIONSHIP_TYPE') : this.relationship.title;
    } else {
      inputText = this.relationship && _.isEmpty(this.relationship.title) || !this.relationship.title ? this.translate.instant('SELECT_RELATIONSHIP_TYPES') : this.relationship.title;
    }
    return inputText;
  }

  public get relationshipTypeFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('RELATIONSHIP_TYPE'),
      customPlaceholderLabel: this.readOnly && _.isEmpty(this.relationship.title) ? this.translate.instant('XPERIENCES_RELATIONSHIP_NO_RELATIONSHIP_TYPE') : this.translate.instant('SELECT_RELATIONSHIP_TYPES'),
      inputText: this.relationship && !_.isEmpty(this.relationship.title) ? this.relationship.title : '',
      id: 'relationship-type-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get otherSourcesFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('XPERIENCES_OTHER_SOURCES'),
      inputText: this.relationship.sourcesText,
      id: 'other-sources-field',
      isReadOnly: false,
      isDisabled: false,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isDateTimeField: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }


    private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'customer-field':
          this.gotoContactPage(this.mode.FOR_INSIGHT);
          break;
        case 'relationship-related-customer-field':
          this.gotoContactPage(this.mode.FOR_INSIGHT_RELATIONSHIP);
          break;
        case 'relationship-category-field':
          this.selectSpeciality();
          break;
        case 'relationship-type-field':
          this.selectRelationship();
          break;
        case 'other-sources-field':
          this.openSourcesPopover(event);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  public gotoContactPage(mode: CustomerSelectionMode) {
    if(mode === this.mode.FOR_INSIGHT &&this.disableContactSelection) return;
    this.isClickedRelatedCustomer = true;
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.CustomerInsightComponent;
    this.uiService.activeView = 'add-customer-relationship';
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
      { type: mode, contactId: this.relationship.contactid, relationship: this.relationship },
      PageName.ContactPageComponent);
  }

  public async selectRelationship() {
    if (this.relationship.types.length === 0) {
      this.relationship.types = cloneDeep(this.xpService.relationships);
    }
    this.footerService.forceHideMasterFooter = true;
    const relationshipSelectionModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps:{
        viewData: {
          data: cloneDeep(this.relationship.types),
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('RELATIONSHIP_TYPE'),
          dividerTitle: 'All relationship types',
          isSearchEnabled: true,
          isReadOnly: this.readOnly
        }
      },
      backdropDismiss: false
    });
    relationshipSelectionModal.present();
    relationshipSelectionModal.onDidDismiss().then(async (data: any) => {
      this.footerService.forceHideMasterFooter = false;
      data = data.data;
      if (data && data.isDone) {
        this.relationship.title = null;
        if (data.selectedItems && data.selectedItems.length) {
          const count = data.selectedItems.length;
          data.selectedItems.sort((a,b)=> (a.title.toLowerCase() > b.title.toLowerCase()) ? 1: -1);
          this.relationship.title = count === 1 ? data.selectedItems[0].title : data.selectedItems[0].title + ' + ' + (count - 1);
          this.relationship.types.map(rt => rt.isSelected = data.selectedItems.some(st => st.id === rt.id));
        } else {
          this.relationship.types.map(rt => rt.isSelected = false);
        }
      }
    });

  }

  public async selectSpeciality() {
    const specialities: Specialty[] = cloneDeep(this.contactService.specialties);
    const selected = this.relationship.selectedSpeciality;
    const options: SelectListData[] = specialities.map(sp => {
      return { id: sp.id, title: sp.name, isSelected: selected && selected.id === sp.id };
    });
    this.footerService.forceHideMasterFooter = true;
    const categoryModal =await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps:{
        viewData: {
          data: options,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('CATEGORY'),
          dividerTitle: this.translate.instant('XPERINECES_ALL_CATEGORY'),
          isSearchEnabled: true,
          isReadOnly : this.readOnly
        }
      },
      backdropDismiss: false
    });
    categoryModal.present();
    categoryModal.onDidDismiss().then(async (data: any) => {
      this.footerService.forceHideMasterFooter = false;
      data = data.data;
      if (data && data.isDone) {
        if (data.selectedItems && data.selectedItems.length) {
          this.relationship.selectedSpeciality = data.selectedItems[0];
        } else {
          this.relationship.selectedSpeciality = null;
        }
      }
    });
  }

  updateRelationshipConfidence(event) {
    this.relationship.score = event;
  }

  public closepage(add: boolean) {
    if (add) {
      if (this.relationship.contactid) {
        if (this.createMode) {
          this.trackingService.tracking('XperiencesInsgihts+RelationshipDone', TrackingEventNames.INSIGHTSPLUS);
          this.xpService.newInsight.relationships.push(this.relationship);
          this.events.publish('insights-relationship-created');
        }
      } else {
        return;
      }
      this.navService.popChildNavPageWithPageTracking();
    } else {
      if (!_.isEqual(this.relationship, this.tempRelationship) && !
      this.readOnly) {
        this.discardChangesPopup();
      }
      else {
        if (this.tempRelationship) this.relationship = this.tempRelationship;
        this.navService.popChildNavPageWithPageTracking();
      }
    }
  }

  discardChangesPopup() {
    this.alertService.showAlert({
      title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
      message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')}, this.translate.instant('DISCARD')
    ).then (res => {
      if(res.role === 'ok') {
        if (this.tempRelationship) this.relationship = this.tempRelationship;
        this.navService.popChildNavPageWithPageTracking();
      }
    });
  }

  async openSourcesPopover(event) {
    if(this.relationship.sources.length > 1) {
      const popover = await this.popoverCtrl.create({
        component: PopoverComponent,componentProps: { field: 'XperienceInsightsPlusSources', insightsPlusSources: this.relationship.sources },cssClass: 'relationship-plus-sources-popover', event });
      popover.present();
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe(OptionSelectionMode.SELECT_SPECIALITY.toString());
  }
}
