<ion-header>
    <ind-page-title [viewData]='{id:"call-plan-detail", title: callPlanOfflineService.selectedCallPlan?.cycleplanname}' (onControlClick)='closepage()'></ind-page-title>
</ion-header>
<ion-content *ngIf="callPlanOfflineService.selectedCallPlan">
    <ion-item-group class='plan-fields'>
        <ind-section-header [viewData]="activityFilterHeaderModel" (onControlClick)="openCllPlanActivityFilterPopup($event)"></ind-section-header>
        <ind-form-field [viewData]=getProductFormField()></ind-form-field>
        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="getStartPeriodFormField()"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="getEndPeriodFormField()"></ind-form-field></ion-col>
        </ion-row>
        <ind-section-header [viewData]="goalCompletionHeaderModel"></ind-section-header>
        <ion-item class='item-container'>
            <call-plan-progress-bar
            [progress]="((callPlanOfflineService?.meetingOrEmailFilter == 'Engagements')
                        ? ((callPlanOfflineService.selectedCallPlan.actualCalls)
                          ?((callPlanOfflineService.selectedCallPlan.actualCalls / callPlanOfflineService.selectedCallPlan.totalGoalCalls) * 100)
                          :0)
                        : ((callPlanOfflineService.selectedCallPlan.actualEmails)
                          ?((callPlanOfflineService.selectedCallPlan.actualEmails / callPlanOfflineService.selectedCallPlan.totalGoalEmails) * 100)
                          : 0))"
            [label]="(callPlanOfflineService?.meetingOrEmailFilter == 'Engagements'
                      ? '(' + callPlanOfflineService.selectedCallPlan.actualCalls + '/' + callPlanOfflineService.selectedCallPlan.totalGoalCalls + ')'
                      : '(' + callPlanOfflineService.selectedCallPlan.actualEmails + '/' + callPlanOfflineService.selectedCallPlan.totalGoalEmails + ')' )">
            </call-plan-progress-bar>
        </ion-item>
    </ion-item-group>
    <ion-item-group [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <ind-section-header [viewData]="allCustomerPlansHeaderModel" (onControlClick)="openCllPlanFilterPopup($event)"></ind-section-header>
        <ion-list *ngIf="callPlans.length>0" class="rep-plans-list">
            <div *ngFor="let repCallPlan of callPlans">
                <main-card [viewData]='getAllCustomerPlans(repCallPlan)'></main-card>
            </div>
        </ion-list>
        <ion-infinite-scroll *ngIf="callPlans.length != callPlanOfflineService.selectedCallPlan.repPlans.length" (ionInfinite)="doInfinite($event.detail,$event)" threshold="20%">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-item-group>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'callplandetails'"></footer-toolbar>
<io-spinner [displayText]="'NEW_ACTIVITY_SPINNER' | translate" *ngIf="!callPlanOfflineService.callplanDetailsLoaded"></io-spinner>
