import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { UIService } from '@omni/services/ui/ui.service';

@Component({
  selector: 'app-app-settings-recommendations',
  templateUrl: './app-settings-recommendations.component.html',
  styleUrls: ['./app-settings-recommendations.component.scss'],
})
export class AppSettingsRecommendationsComponent implements OnInit {
  appSettingsRecommendationsPageTitle: IndPageTitleViewDataModel;
  recommendationsHeader: IndSectionHeaderViewDataModel;

  constructor(public footerService: FooterService,
    private translate : TranslateService,
    public navService: NavigationService,
    public uiService: UIService,
    public authService: AuthenticationService,
    public activityDataService: ActivityDataService
    ) {
      this.recommendationsHeader = {
        id:'recommendationsHeader-header',
        title: this.translate.instant('SETTINGS_RECOMMENDATIONS_HEADER'),
        controls: [],
      };
     }

  ngOnInit() {
    this.initAppSettingsRecommendationsPageTitle();
  }

  private initAppSettingsRecommendationsPageTitle(): void {
    this.appSettingsRecommendationsPageTitle = {
      id: 'app-settings-recommendations-title',
      title: this.translate.instant('SETTINGS_RECOMMENDATIONS'),
      controls: [],
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public onClosePage() {
    this.uiService.showRightPane = false;
    this.navService.setChildNavRightPaneView(false);
  }

  // public async toggleBestTimeInMeetings(){
  //   this.authService.user.showBestTimeRecommendation = !this.authService.user.showBestTimeRecommendation;

  //   if(this.authService.user.showBestTimeRecommendation === false) {
  //     this.authService.user.hideBestTimeRecommendationCount = 0;
  //   }

  //   await this.activityDataService.updateUserTimeRecommendation();
  // }

  public async onBestTimeInMeetingsChange(){
    if(this.authService.user.showBestTimeRecommendation === false) {
      this.authService.user.hideBestTimeRecommendationCount = 0;
    }

    await this.activityDataService.updateUserTimeRecommendation();
  }
}
