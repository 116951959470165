import { SharedDataService } from './../shared-data/shared.data.service';
import { PriceList, CountryAndAccount } from './../../classes/order-management/price-list.class';
import { DynamicsClientService } from './../dynamics-client/dynamics-client.service';
import { Injectable } from "@angular/core";
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../../services/disk/disk.service";
import { ActivityService } from "../../services/activity/activity.service";
import { Endpoints } from "../../../config/endpoints.config";
import { AuthenticationService } from "../../services/authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventsService } from "../../services/events/events.service";
import { OrderActivity } from "../../classes/activity/order.activity.class";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { OrderManagementService } from "../../services/order-management/order-management.service";
import { fetchQueries } from "../../config/dynamics-fetchQueries";
import { differenceInHours, isBefore, isAfter } from "date-fns";
import { ActivityType } from '../../classes/activity/activity.class';
import { UIService } from '../../services/ui/ui.service';
import { TrackAction } from '../../utility/common-enums';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from '../../utility/util';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { DeltaService } from '../delta/delta.service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { DeviceService } from '@omni/services/device/device.service';
import { MyAssistantService, NOTIFICATION} from "../../services/my-assistant/my-assistant.service";
import { format } from "date-fns";
import { ReportDataManagementService } from '@omni/services/reports/report-data-management.service';
import { MeasureType } from './../../enums/edge-analytics/edge-analytics.enum';
/**
 * Offline/Online data operations service for Order Activity
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class OrderActivityDataService {

    public isSelectedOrderActivityUpdated:boolean = false;

    constructor(
        private disk: DiskService,
        private activityOfflineService: ActivityService,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private events: EventsService,
        public authService: AuthenticationService,
        public orderMgmtOfflineService: OrderManagementService,
        public dynamics: DynamicsClientService,
        private uiService: UIService,
        private translate: TranslateService,
        private sharedDataService: SharedDataService,
        private globalUtility: GlobalUtilityService,
        private accountService: AccountOfflineService,
        private deltaService: DeltaService,
        private readonly deviceService: DeviceService,
        private myAssistantService: MyAssistantService,
        private reportDataService : ReportDataManagementService
    ) { }

    private async createOrderActivityOnline(payload: object): Promise<any> {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl+Endpoints.orderManagement.CREATE_UPDATE_ORDER;
        // Create new order activity on dynamics and return promise with success or failure
        return this.http.post(url, payload).toPromise();
    }

    private UpdateOrderActivityOnline(payload: object,approvalActivityId:string = ''): Promise<any> {

        let url: string;
        if(approvalActivityId){
          url = this.authenticationService.userConfig.activeInstance.entryPointUrl+Endpoints.orderManagement.APPROVE_NOT_APPROVE_ORDER;
          url = url.replace('{{approvalactivityid}}',approvalActivityId);
        }else {
          url = this.authenticationService.userConfig.activeInstance.entryPointUrl+Endpoints.orderManagement.CREATE_UPDATE_ORDER;
        }
        return this.http.patch(url, payload).toPromise();
    }

    private scrapOrderActivityOnline(order:OrderActivity):Promise<any>{
        let url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.DELETE_ORDER_ACTIVITY;
        url = url.replace('{{salesorderid}}',order.ID)
        return this.http.delete(url).toPromise();
    }

    public loadOfflineOrderActivities(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let option = {
                selector: {
                    '_id': {
                        $gte: DB_KEY_PREFIXES.ORDER_ACTIVITY,
                        $lte: DB_KEY_PREFIXES.ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
                    },
                }
            };
            option.selector['pendingPushToDynamics'] = {
                $eq: true
            };
            try {
                // Fetch from DB
                const offlineOrders: any[] = await this.disk.find(option);
                if (offlineOrders && Array.isArray(offlineOrders) && offlineOrders.length > 0) {
                    let raworderspayload = [];
                    offlineOrders.forEach(raworder => {
                        let  newOfflineOrder = new OrderActivity(raworder);
                        raworderspayload.push(newOfflineOrder.serviceDTO);
                    });
                    // Track Offline data count
                    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, offlineOrders.length);
                    resolve(raworderspayload);
                } else {
                    resolve('');
                }
            } catch (error) {
                reject('Error occured while fetching order activities data from offline db');
            }
        });
    }

    public handleOfflinePushResponse(rawResponse: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let updatedOfflineOrders = [];
            for (var i = 0; i < rawResponse.length; i++) {
                let orderActivity = rawResponse[i];
                if (orderActivity.hasOwnProperty('salesorderid') && orderActivity.hasOwnProperty('indskr_externalid') && !(orderActivity['errorCode'] || orderActivity['errorId'])) {
                    let idtobeused = (orderActivity.indskr_externalid) ? DB_KEY_PREFIXES.ORDER_ACTIVITY + orderActivity.indskr_externalid : DB_KEY_PREFIXES.ORDER_ACTIVITY + orderActivity.salesorderid
                    let savedOfflineObject = await this.disk.retrieve(idtobeused);
                    if (savedOfflineObject) {
                        savedOfflineObject.salesorderid = orderActivity.salesorderid;
                        savedOfflineObject.ordernumber = ( (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : ((savedOfflineObject['ordernumber'])?savedOfflineObject['ordernumber']:'New Order') );
                        savedOfflineObject.subject = savedOfflineObject.ordernumber;
                        savedOfflineObject.name = ( (orderActivity['name']) ? orderActivity['name'] : ((savedOfflineObject['ordernumber'])?savedOfflineObject['ordernumber']:'New Order') );
                        savedOfflineObject.pendingPushToDynamics = false;
                        if(savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 4 && savedOfflineObject.isHardDeleted){
                            updatedOfflineOrders.push(getDeletedPayloadObejct(savedOfflineObject));
                        }else{
                            updatedOfflineOrders.push(savedOfflineObject);
                        }
                    }
                    const order = this.activityOfflineService.getActivityByID(orderActivity.indskr_externalid ? orderActivity.indskr_externalid : orderActivity.salesorderid);
                    if (order) {
                        (<OrderActivity>order).ID = orderActivity.salesorderid;
                        (<OrderActivity>order).orderNumber = ( (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : (((<OrderActivity>order).orderNumber)?(<OrderActivity>order).orderNumber:'New Order') );
                        (<OrderActivity>order).subject = ( (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : (((<OrderActivity>order).orderNumber)?(<OrderActivity>order).orderNumber:'New Order') );
                        (<OrderActivity>order).name = ( (orderActivity['name']) ? orderActivity['name'] : (((<OrderActivity>order).name)?(<OrderActivity>order).name:'New Order') );
                        (<OrderActivity>order).pendingPushToDynamics = false;
                        if(this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Order && this.activityOfflineService.selectedActivity.ID == order.ID){
                          this.isSelectedOrderActivityUpdated = true;
                        }
                    } else {
                        const order2 = this.activityOfflineService.getActivityByID(orderActivity.salesorderid);
                        if (order2) {
                            (<OrderActivity>order2).ID = orderActivity.salesorderid;
                            (<OrderActivity>order2).orderNumber = ( (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : (((<OrderActivity>order2).orderNumber)?(<OrderActivity>order2).orderNumber:'New Order') );
                            (<OrderActivity>order2).subject = ( (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : (((<OrderActivity>order2).orderNumber)?(<OrderActivity>order2).orderNumber:'New Order') );
                            (<OrderActivity>order2).name = ( (orderActivity['name']) ? orderActivity['name'] : (((<OrderActivity>order2).name)?(<OrderActivity>order2).name:'New Order') );
                            (<OrderActivity>order2).pendingPushToDynamics = false;
                        }
                        if(this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Order && this.activityOfflineService.selectedActivity.ID == order2.ID){
                          this.isSelectedOrderActivityUpdated = true;
                        }
                    }

                }
            }
            if (updatedOfflineOrders.length > 0) {
                try {
                    // Bulk save docs to DB
                    await this.disk.bulk(updatedOfflineOrders);

                    // Track Offline data count
                    const newCount = this.disk.getOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER) - updatedOfflineOrders.length;
                    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, newCount >= 0 ? newCount : 0);
                    resolve('Successfully updated in offline DB');
                } catch (error) {
                    reject('Error Occured while updating offline data' + error);
                }
            } else {
                resolve('No order found to be updated');
            }
        });
    }

    public async loadOrderActivitiesFromDb(dataRange: { from: string, to: string }): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let option = {
                selector: {
                    '_id': {
                        $gte: DB_KEY_PREFIXES.ORDER_ACTIVITY,
                        $lte: DB_KEY_PREFIXES.ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
                    },
                }
            };
            // Don't consider the offline data duration field for orders as of now
            // if (dataRange && dataRange.from) {
            //     option.selector['scheduledstart'] = {
            //         $gte: dataRange.from
            //     };
            // }
            // if (dataRange && dataRange.to) {
            //     option.selector['scheduledstart'] = Object.assign({},
            //         option.selector['scheduledstart'],
            //         { $lte: dataRange.to }
            //     );
            // }
            try {
                // Fetch from DB and do mapping
                const rawOrders: any[] = await this.disk.find(option);
                if (rawOrders && Array.isArray(rawOrders) && rawOrders.length > 0) {
                    let orders = [];
                    rawOrders.forEach(rawOrder => {
                        let newOrder = new OrderActivity(rawOrder);
                        //if(!(newOrder.state == 2 && newOrder.status == 4)){// Don't add scrapped order activities
                            if(newOrder.ownerId != this.authenticationService.user.systemUserID){
                                newOrder.isTeamOrder = true;
                            }
                            orders.push(newOrder);
                        //}
                    });
                    if(orders.length > 0){
                        let action: OperationDetail = {
                            onDynamics: false,
                            onLocalDatabase: false,
                            onLocalCopy: true,
                            operationDetail: {
                              code: 'OAOFDBMAP101',
                              message: 'Order Activity Offline DB data mapping'                            }
                        };
                        // Track Offline data count
                        let offlineDataCount = orders.filter(o => o.pendingPushToDynamics === true).length;
                        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, offlineDataCount);
                        await this.createOrderActivity(action, orders, null, false).then(success => {
                            resolve('');
                            return;
                        });
                    }else{
                        resolve('');
                    }
                } else {
                    resolve('');
                }
            } catch (error) {
                reject('Error occured while fetching order activities data from offline db');
            }
        });
    }

    private async fetchRealTimeOrderActivityStatus(id): Promise<any>{
        return new Promise(async (resolve, reject) => {
            let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_DETAILS;
            url = url.replace("{activity_id}", id);
            url = url.replace("{activity_type}", 'order');
            url = url.replace('&configFields={{configFields}}', '');

            let headers = Endpoints.headers.content_type.json;
            headers.headers = headers.headers.set(
                'No-Retry', 'true'
            );
            headers.headers = headers.headers.set(
            'X-Feature-Id', 'Fetch_Activity_Appointment'
            );
            let rawActivity: any = await this.http.get(url, headers).toPromise();
            if(rawActivity){
                resolve({
                    statecode: rawActivity['statecode'],
                    statuscode: rawActivity['statuscode'],
                });
            }else{
                reject();
            }
        });
    }

    public async updateOrderActivityStatus(action: OperationDetail, order: OrderActivity, force:boolean = false): Promise<any>{
        /*
        Statecode values:
        Value: 0, Label: Active
        Value: 1, Label: Submitted
        Value: 2, Label: Canceled
        Value: 3, Label: Fulfilled
        Value: 4, Label: Invoiced


        Status code values:
        Value: 1, Label: New
        Value: 2, Label: Pending
        Value: 3, Label: In Progress
        Value: 548910000, Label: For Review
        Value: 548910001, Label: Approved
        Value: 4, Label: No Money
        Value: 100001, Label: Complete
        Value: 100002, Label: Partial
        Value: 100003, Label: Invoiced
        */
        if (order && action && action.operationDetail && action.operationDetail.code) {
            let realtimeStatus;
            if(action.operationDetail.code == 'cancelorderactivityreview' || action.operationDetail.code == 'autoapprovalflow' || action.operationDetail.code == 'approveorderactivity' || action.operationDetail.code == 'autofulfillflow'){
                await this.fetchRealTimeOrderActivityStatus(order.ID).then(res=>{
                    realtimeStatus = res;
                }).catch(err => {
                    console.log(err);
                })
            }
            switch(action.operationDetail.code){
                case 'scraporderactivity':{
                    // Check for deletion conditions
                    // if(order.ownerId != this.authenticationService.user.systemUserID && !force){
                    //     return Promise.reject(this.translate.instant('NOT_AUTHORIZED_TO_SCRAP_ACTIVITY'));
                    // }else
                     if((order.status == 548910001 || order.status == 100001 || order.status == 548910000) && !force){
                        return Promise.reject('Cannot scrap completed activity');
                    }else if(order.state == 2 && !force){
                        return Promise.reject('Cannot scrap already cancelled activity');
                    }else if(order.state == 0 || force){
                        order.state = 2;
                        order.status = 4;
                        order.isHardDeleted = true;
                        if(order.ID.includes('offline')){
                            order.pendingPushToDynamics = false;
                        }
                        return this.updateOrderActivity(action,[order],new Date().getTime());
                    }else{
                        return Promise.reject('Error Occured while scraping order activity');
                    }
                }
                case 'sendorderactivityforreview':{
                    order.state = 1;
                    order.status = 548910000;
                    order.pendingPushToDynamics = true;
                    return this.updateOrderActivity(action,[order],new Date().getTime());
                }
                case 'autoapproveorderactivity':
                case 'autoapprovalflow':
                case 'autofulfillflow':
                case 'approveorderactivity':{
                    if(action.operationDetail.code =='approveorderactivity' && !(realtimeStatus && realtimeStatus.statecode == 1 && realtimeStatus.statuscode == 548910000)){
                      return Promise.reject({
                          errorCode: 'ONLINEONLYUPDATEFAILED',
                          operationCode: action.operationDetail.code,
                          errorMessage: 'Failed to perform the operation on dynamics',
                          errorDetails: '',
                      });
                    }else{
                      order.state = 1;
                      order.status = 548910001;
                      order.pendingPushToDynamics = true;
                      if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT_AUTO_FULFILL)) {
                        await this.updateOrderActivity(action, [order], new Date().getTime());
                      } else {
                        return this.updateOrderActivity(action, [order], new Date().getTime());
                      }
                    }
                }
                case 'fulfillorderactivity':{
                    order.state = 3;
                    order.status = 100001;
                    order.pendingPushToDynamics = true;
                    return this.updateOrderActivity(action,[order],new Date().getTime());
                }
                case 'cancelorderactivityreview':
                    if(!(realtimeStatus && realtimeStatus.statecode == 1 && realtimeStatus.statuscode == 548910000)){
                        return Promise.reject({
                            errorCode: 'ONLINEONLYUPDATEFAILED',
                            operationCode: action.operationDetail.code,
                            errorMessage: 'Failed to perform the operation on dynamics',
                            errorDetails: '',
                        });
                    }
                case 'notapproveorderactivity':
                case 'reopenorderactivity':
                    order.state = 0;
                    order.status = 1;
                    order.pendingPushToDynamics = true;
                    return this.updateOrderActivity(action,[order],new Date().getTime());
                default: {
                    return Promise.reject('Not a valid option for updating status');
                }
            }
        } else {
            return Promise.reject('No activity passed for status updation');
        }
    }

    // public scrapOrderActivity(action: OperationDetail, order: OrderActivity, force: boolean = false): Promise<any> {

    // }

    // public submitOrderActivityForReview(action: OperationDetail, order: OrderActivity): Promise<any> {
    //     if (order) {
    //         if (order.ownerId == this.authenticationService.user.systemUserID) {
    //                 order.state = 1;
    //                 order.status = 4;
    //                 order.statusString = 'For Review';
    //                 order.pendingPushToDynamics = true;
    //                 return this.updateOrderActivity(action, [order], new Date().getTime()).then(succ => {
    //                 });
    //         } else {
    //             return Promise.reject(this.translate.instant('NOT_AUTHORIZED_TO_SCRAP_ACTIVITY'));
    //         }
    //     } else {
    //         return Promise.reject('No activity passed for review');
    //     }
    // }

    // public approveOrderActivityReview(action: OperationDetail, order: OrderActivity): Promise<any> {
    //     if (order) {
    //         order.state = 1;
    //         order.status = 5;
    //         order.statusString = 'Approved';
    //         order.pendingPushToDynamics = true;
    //         return this.updateOrderActivity(action, [order], new Date().getTime()).then(succ => {
    //         });
    //     } else {
    //         return Promise.reject('No activity passed for approval');
    //     }
    // }

    // public reOpenOrderActivity(action: OperationDetail, order: OrderActivity): Promise<any> {
    //     if (order) {
    //         if (order.ownerId == this.authenticationService.user.systemUserID) {
    //                 order.state = 1;
    //                 order.status = 0;
    //                 order.statusString = 'Draft';
    //                 order.pendingPushToDynamics = true;
    //                 return this.updateOrderActivity(action, [order], new Date().getTime()).then(succ => {
    //                 });
    //         } else {
    //             return Promise.reject('Not authorized to reopen this activity');
    //         }
    //     } else {
    //         return Promise.reject('this.translate.instant('NO_ACTIVITY_PASSED_FOR_COMPLETION')');
    //     }
    // }

    // public fulfillApproveOrderActivity(action: OperationDetail, order: OrderActivity): Promise<any> {
    //     if (order) {
    //         if (order.ownerId == this.authenticationService.user.systemUserID) {
    //                 order.state = 1;
    //                 order.status = 8;
    //                 order.statusString = 'Fulfilled';
    //                 order.pendingPushToDynamics = true;
    //                 return this.updateOrderActivity(action, [order], new Date().getTime()).then(succ => {
    //                 });
    //         } else {
    //             return Promise.reject('Not authorized to reopen this activity');
    //         }
    //     } else {
    //         return Promise.reject('this.translate.instant('NO_ACTIVITY_PASSED_FOR_COMPLETION')');
    //     }
    // }

    public async createOrderActivity(action: OperationDetail, data: Array<OrderActivity>, newLastUpdatedTime: number, isInitialSync: boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
            let checkNextAction: boolean = true;
            if (action.onDynamics) {
                if (data && data.length == 1) { //Realtime order creation on dynamics
                    let serviceDTO = data[0].serviceDTO;
                    await this.createOrderActivityOnline(serviceDTO).then(info => {
                        // Succesfully created on dynamics
                        if (info && info['salesorderid']) {
                            data[0].ID = info['salesorderid'];
                            data[0].pendingPushToDynamics = false;
                            data[0].orderNumber = info['ordernumber'] || 'New Order';
                            data[0].name = info['name'] || 'New Order';
                            data[0].subject = data[0].orderNumber;
                        }
                        if (!action.onLocalDatabase && !action.onLocalCopy) {
                            resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
                            checkNextAction = false;
                        }
                    }).catch(error => {
                        // Handle any error scenario
                        checkNextAction = true; // As per current expected behaviour still create order in offline db
                    });
                } else {
                    console.log('Got offline data as:' + data);
                    resolve('');
                    // can be used forbulk data upload on dynamics
                }
            }
            if (action.onLocalDatabase && checkNextAction) {
                if (isInitialSync) {
                    try {
                        await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ORDER_ACTIVITIES);
                        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 0);
                    } catch (error) {
                        reject(this.translate.instant('ERROR_WHILE_CLEARING_OFFLINE_DB') + error);
                        return;
                    }
                }
                //Initialise document id and get offline DTO for each activity
                if (data) {
                    let offlineData = [];
                    for (let i = 0; i < data.length; i++) {
                        let order = data[i];
                        let offlineDTO = order.offlineDataDTO;
                        offlineDTO._id = order.offlineDBId;
                        offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                        offlineData.push(offlineDTO);
                    };

                    if (offlineData && Array.isArray(offlineData)) {
                        try {
                            // Bulk save docs to DB
                            await this.disk.bulk(offlineData);

                            // Track offline data count
                            const count: number = offlineData.filter(o => o.pendingPushToDynamics).length;
                            if (count > 0) {
                                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, count);
                            }
                        } catch (error) {
                            reject(this.translate.instant('ERROR_WHILE_SAVING_OFFLINE_DATA') + error);
                            checkNextAction = false;
                        }
                        if (!action.onLocalCopy) {
                            resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
                            checkNextAction = false;
                        }
                    }
                }
            }
            if (action.onLocalCopy && checkNextAction) {
                // push into local array of order-activities
                if (data) {
                  let callForFilterActivities = true;
                  if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'OAOFDBMAP101' || action.operationDetail.code == 'OIAINI101')){
                    callForFilterActivities = false;
                  }
                    data.forEach(order => {
                        this.activityOfflineService.addActivity(order, false, false, null,callForFilterActivities).then(success => {
                            resolve('Successfully created order activity');
                        }).catch(err => {
                            reject('Error Occured while saving order activity into local array' + err);
                        })
                    })
                }
            }
        });
    }

    public async updateOrderActivity(action: OperationDetail, data: Array<OrderActivity>, newLastUpdatedTime): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
            let checkNextAction: boolean = true;
            if (action.onDynamics) {
                if (data && data.length == 1) { //Realtime order-activity creation on dynamics
                    if (!data[0].ID.includes('offline')) {
                        let serviceDTO = data[0].serviceDTO;
                        if(data[0].state == 2 && data[0].status == 4){
                            await this.scrapOrderActivityOnline(data[0]).then(info => {
                                data[0].pendingPushToDynamics = false;
                                // Succesfully updated on dynamics
                                if (info && info['salesorderid']) {
                                    data[0].pendingPushToDynamics = false;
                                }
                                if (!action.onLocalDatabase && !action.onLocalCopy) {
                                    resolve('Successfully deleted on server');
                                    checkNextAction = false;
                                }
                            }).catch( err => {
                                //reject({message:'Error occured while deleting follow up task on server',error:err});
                                // Handle any error scenario
                                if(err && err['error']){
                                    let errorDetail = err['error'];
                                    if(errorDetail['errorCode']){
                                        // Handle error codes
                                    }
                                }
                                checkNextAction = true;
                            });
                        }else{
                            let approvalActivityId:string;
                            if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'fulfillorderactivity' || action.operationDetail.code == 'reopenorderactivity' || action.operationDetail.code == 'cancelorderactivityreview' || action.operationDetail.code == 'autoapprovalflow' || action.operationDetail.code == 'autofulfillflow')){
                              serviceDTO = data[0].statusUpdateDTO;
                            }else if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'notapproveorderactivity' || action.operationDetail.code == 'approveorderactivity')){
                              serviceDTO = {
                                statuscode: data[0].status + '',
                              };
                              if(action.operationDetail.code == 'notapproveorderactivity' && data[0].rejectionReason){
                                serviceDTO['indskr_reason'] = data[0].rejectionReason;
                                serviceDTO['statuscode'] = 548910002 + '';
                              }
                              approvalActivityId = data[0].approvalActivityId
                            }

                            await this.UpdateOrderActivityOnline(serviceDTO,approvalActivityId).then(info => {
                                // Succesfully updated on dynamics
                                if (info && info['salesorderid']) {
                                    data[0].pendingPushToDynamics = false;
                                }
                                if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'notapproveorderactivity' || action.operationDetail.code == 'approveorderactivity')){
                                    data[0].pendingPushToDynamics = false;
                                }
                                if (!action.onLocalDatabase && !action.onLocalCopy) {
                                    resolve('Successfully updated on server');
                                    checkNextAction = false;
                                    this.isSelectedOrderActivityUpdated = true;
                                }
                            }).catch(err => {
                                // Handle any error scenario
                                //Check for online only actions
                                if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'sendorderactivityforreview' || action.operationDetail.code == 'autoapproveorderactivity' || action.operationDetail.code == 'notapproveorderactivity' || action.operationDetail.code == 'approveorderactivity' || action.operationDetail.code == 'fulfillorderactivity' || action.operationDetail.code == 'reopenorderactivity' || action.operationDetail.code == 'cancelorderactivityreview' || action.operationDetail.code == 'autoapprovalflow' || action.operationDetail.code == 'autofulfillflow')){
                                    checkNextAction = false;
                                    //data[0].pendingPushToDynamics = true;
                                    reject({
                                        errorCode: 'ONLINEONLYUPDATEFAILED',
                                        operationCode: action.operationDetail.code,
                                        errorMessage: 'Failed to perform the operation on dynamics',
                                        errorDetails: err,
                                    })
                                }else{
                                    checkNextAction = true; //As per current expected behaviour still push the updates to offline db
                                }
                            });
                        }
                    } else {
                        // Check whether to push activity online or not
                        if(data[0].state == 2 && data[0].status == 4 && action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scraporderactivity'){
                            // Deleting an order activity that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
                            data[0].pendingPushToDynamics = false;
                        }
                    }
                } else {
                    // can be used forbulk data upload on dynamics
                }
            }
            if (action.onLocalDatabase && checkNextAction) {
                let offlineData = [];
                for (let i = 0; i < data.length; i++) {
                    let order = data[i];
                    let offlineDTO = order.offlineDataDTO;
                    offlineDTO._id = order.offlineDBId;
                    let savedOfflineObject = await this.disk.retrieve(order.offlineDBId);
                    if(action.operationDetail && action.operationDetail.code == 'OIADEL101' && savedOfflineObject && savedOfflineObject.pendingPushToDynamics){
                        // In delta Sync order activity update, do not update activities that have pending changes to be pushed
                        //checkNextAction = false;
                        data[i].pendingPushToDynamics = true;
                        resolve('');
                    }
                    else if(savedOfflineObject){
                        // Handle Deleted activities and track action
                        if (action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scraporderactivity' && order.pendingPushToDynamics == false && order.state == 2 && order.status == 4 && order.isHardDeleted) { // Will reach here only if the updates have been pushed to dynamics and we can remove the object from offline db
                            offlineData.push(getDeletedPayloadObejct(savedOfflineObject));
                        }else{
                            offlineDTO._id = savedOfflineObject['_id'];
                            offlineDTO._rev = savedOfflineObject['_rev'];
                            offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                            offlineData.push(offlineDTO);

                            // If a new update in offline, add the offline data count
                            if (!savedOfflineObject.pendingPushToDynamics && offlineDTO.pendingPushToDynamics) {
                                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 1);
                            }
                        }
                    }else if(action.operationDetail && action.operationDetail.code == 'OIADEL101'){
                        offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                        offlineData.push(offlineDTO);

                        // If a new update in offline, add the offline data count
                        if (offlineDTO.pendingPushToDynamics) {
                            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 1);
                        }
                    }
                }
                if (offlineData.length > 0) {
                    try {
                        // Bulk save docs to DB
                        await this.disk.bulk(offlineData);
                    } catch (error) {
                        reject('Error Occured while updating offline data' + error);
                        checkNextAction = false;
                    }
                    if (!action.onLocalCopy) {
                        resolve('Successfully updated in offline DB');
                        checkNextAction = false;
                    }
                }
            }
            if (action.onLocalCopy && checkNextAction) {
                if (data) {
                  let callForFilterActivities = true;
                  if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'OIADEL101')){
                    callForFilterActivities = false;
                  }
                    data.forEach(order => {
                        // CHeck for deletion case
                        if(action.operationDetail && action.operationDetail.code == 'OIADEL101' && order.pendingPushToDynamics){
                            // In delta Sync order activity update, do not update activities that have pending changes to be pushed
                            //checkNextAction = false;
                            resolve('');
                        }else{
                            if (order.state == 2 && order.status == 4 && order.isHardDeleted) {// If order activity is deleted or track action is deletion one
                                this.activityOfflineService.publishActivityEvent({action: "Delete", activity: order});
                                if(this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Order && this.activityOfflineService.selectedActivity.ID == order.ID){
                                    this.activityOfflineService.selectedActivity = null;
                                    this.uiService.activeView = '';
                                }
                                this.activityOfflineService.removeActivity(order, false, null,callForFilterActivities).then(success => {
                                    resolve('Successfully updated order activity');
                                }).catch(err => {
                                    reject('Error Occured while updating order activity into local array' + err);
                                })
                            } else {
                                this.activityOfflineService.addActivity(order, true, false, null,callForFilterActivities).then(success => {
                                    resolve('Successfully updated order activity');
                                }).catch(err => {
                                    reject('Error Occured while updating order activity into local array' + err);
                                })
                            }// interaction update for account completed order
                            if(order.status === 100001){
                                this.globalUtility.updateInteractionAccount(this.accountService.getAccountById(order.accountId), 'Order');
                            }
                            // Need to refresh UI on the week view tab
                            if(action.operationDetail?.code=='ORDER_ACCOUNT_UPDATED') {
                              this.activityOfflineService.publishActivityEvent({action: "Update", activity: order});
                              // this.events.publish('weekview:RefreshUI');
                            }

                            if(this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Order && this.activityOfflineService.selectedActivity.ID == order.ID){
                                this.isSelectedOrderActivityUpdated = true;
                            }
                        }
                    })
                }
            }
        });
    }

    public async handleDeletedTrackActionForOrderActivity(raw) {
        if (raw && raw['salesorderid']) {
            let order = (this.activityOfflineService.getActivityByID(raw['salesorderid']) as OrderActivity);
            if (!order) {// Search for the activity in offline db
                let option = {
                    selector: {
                        '_id': {
                            $gte: DB_KEY_PREFIXES.ORDER_ACTIVITY,
                            $lte: DB_KEY_PREFIXES.ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
                        },
                        'salesorderid': {
                            $eq: raw['salesorderid']
                        },
                    }
                };
                const rawOrders: any[] = await this.disk.find(option);
                if (rawOrders && Array.isArray(rawOrders) && rawOrders.length > 0) {
                    order = new OrderActivity(rawOrders[0]);
                    order.isHardDeleted = true;
                }
            }
            if (order) {
                this.updateOrderActivityStatus({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true ,operationDetail: {code: 'scraporderactivity',message:'Scrap Order'} }, order, true);
            }
        }
    }

    public async loadOfflinePriceListsDataForOrders(){
      if(this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)){
        await this.disk.retrieve(DB_KEY_PREFIXES.PRICE_LISTS, true).then((doc)=>{
          if(doc && doc.raw){
            this.orderMgmtOfflineService.priceLists = doc.raw
            this.orderMgmtOfflineService.priceLists.map((o)=>{
              o.countryAndAccountPairs.forEach((CAP)=>{
                if(!this.sharedDataService.countriesWithPriceList.some((c)=>c.countryId == CAP.countryID)){
                  this.sharedDataService.countriesWithPriceList.push({
                    countryId: CAP.countryID,
                    countryName: CAP.countryName
                  })
                }
              })
            })
            // Sort Country List
            this._sortCountriesWithPriceList();
          }
          else {
            this.orderMgmtOfflineService.priceLists = [];
            this.sharedDataService.countriesWithPriceList = [];
          }
        }).catch(()=>{
          this.orderMgmtOfflineService.priceLists = [];
        })
      }
    }

    private _sortCountriesWithPriceList(){
        if(this.sharedDataService.countriesWithPriceList && this.sharedDataService.countriesWithPriceList.length !== 0){
            this.sharedDataService.countriesWithPriceList = this.sharedDataService.countriesWithPriceList.sort((a,b) => a.countryName < b.countryName ? -1 : 1);
        }
    }

    public async fetchPriceLists(fullSync?:boolean, loadFromDbOnly = false){
      if(this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)){
        this.deltaService.pushSyncEntityName(SyncFeatureCategory.orders);
          if (loadFromDbOnly) {
            await this.loadOfflinePriceListsDataForOrders();
          } else {
        try{
          let offlineDataStored;
          let lastModifiedForDeltaSync, hourDifference;
          await this.disk.retrieve(DB_KEY_PREFIXES.PRICE_LISTS, true).then((doc)=>{
              offlineDataStored = doc
              if(doc && doc.raw){
                this.orderMgmtOfflineService.priceLists = doc.raw
              }
              else {
                this.orderMgmtOfflineService.priceLists = [];
                fullSync = true;
              }
            })
          let positionIds = this.authService.user.positions.map(o=>{
            return o.ID
          });
          let positionString = '';
          positionIds.forEach(p=>{
            positionString += '<value>'+p+'</value>'
          })
          let fetchXML = fetchQueries.orderManagement.fetchPriceListsWithAccounts.split('{positionIDs}').join(positionString);
          fetchXML = fetchXML.replace('{busUnitID}', this.authService.user.xBusinessUnitId)
          let now = new Date();
          if(fullSync){
            fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
          }
          else{
            offlineDataStored?lastModifiedForDeltaSync = offlineDataStored.lastModified : '';
            let deltaSyncFilter
            if(lastModifiedForDeltaSync){
              hourDifference = differenceInHours(
                now,
                new Date(lastModifiedForDeltaSync)
              )
              //add one to make sure we take care of fractional difference in hours
              hourDifference += 1
              const entityname = 'pricelevel'
              deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
              deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference)
              deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
            }
            else deltaSyncFilter = ''
            fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter)
          }
          await this.dynamics.executeFetchQuery('pricelevels',fetchXML)
          .then(async (res)=>{
            if(!fullSync){
              //let deletedPlans = await this.fetchHardDeletedAccountPlans(hourDifference);
              //if(deletedPlans) res.push(...deletedPlans);
            }
            console.log('price lists with accounts', res);
            let priceListsWithoutAccount = await this.fetchPriceListsWithoutAccounts(fullSync, lastModifiedForDeltaSync);
            if(priceListsWithoutAccount) res.push(...priceListsWithoutAccount)
            await this.aggregatepriceLists(res, fullSync);
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRICE_LISTS, (doc)=>{
                doc = {
                    raw: []
                };
                doc.raw = this.orderMgmtOfflineService.priceLists;
                doc.raw.map(r=>{
                  r.lastUpdated = now.getTime();
                })
                doc.lastModified = now.getTime();
                return doc;
              })
          },
          (err)=>{

          })
        }catch (error){
          console.log(error)
        }
        }
      }
    }
    async fetchPriceListsWithoutAccounts(fullSync?: boolean, lastModifiedForDeltaSync?){
      try {
          let fetchXML = fetchQueries.orderManagement.fetchPriceListsWithoutAccounts;
          fetchXML = fetchXML.replace('{busUnitID}', this.authService.user.xBusinessUnitId)
          let now = new Date();
          let hourDifference, deltaSyncFilter;
          if(fullSync){
            fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
          }
          else{
            if(lastModifiedForDeltaSync){
              hourDifference = differenceInHours(now,new Date(lastModifiedForDeltaSync))
              //add one to make sure we take care of fractional difference in hours
              hourDifference += 1
              const entityname = 'pricelevel'
              deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
              deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference);
              deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
            }
            else deltaSyncFilter = ''
            fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter)
          }
          let response = await this.dynamics.executeFetchQuery('pricelevels',fetchXML)
          console.log('price lists without account', response)
          return response
      } catch (error) {

      }
    }

    async aggregatepriceLists(priceListData, fullSync?: boolean){
      let priceLists:PriceList[] = [];
      priceListData.map(a=>{
      if(a.pricelevelid){
        let priceList: PriceList
        priceList = priceLists.find(o=>o.ID == a.pricelevelid)
        if(!priceList) {
          priceList = new PriceList(a);
          priceList.countryAndAccountPairs = []
          priceList.products = []
          priceLists.push(priceList)
        }
        if(a.hasOwnProperty('CAP.indskr_accountpricelistid')){
          let countryAndAccount = priceList.countryAndAccountPairs.find(o => o.countryAccountPricelistID == a['CAP.indskr_accountpricelistid']);
          if(!countryAndAccount) {
            let countryAndAccount:CountryAndAccount = {
              countryAccountPricelistID: a['CAP.indskr_accountpricelistid'] || '',
              countryID: a['CAP.indskr_country']||'',
              countryName: a['CAP.indskr_country_Formatted']||'',
              accountID: a['CAP.indskr_account']||'',
              accountName: a['CAP.indskr_account_Formatted']||'',
            };
            priceList.countryAndAccountPairs.push(countryAndAccount);
          }
          // if(a['PO.CSF.indskr_criticalsuccessfactorsid']){
          //   let CSF = objective.CSFs.find(c=>c.CSFId == a['PO.CSF.indskr_criticalsuccessfactorsid']);
          //   if(!CSF){
          //      CSF = {
          //       CSFId: a['PO.CSF.indskr_criticalsuccessfactorsid'],
          //       CSFName: a['PO.CSF.indskr_name']
          //     }
          //     objective.CSFs.push(CSF)
          //   }
          // }
        }
        if(a.hasOwnProperty('products.productid')){
          let product = priceList.products.find(p => p.productID == a['products.productid'])
            if(!product) {
              product = {
                          productID:a['products.productid'],
                          amount: a['products.amount'],
                          productName:a['productname.name'],
                          productApplicabilityOnSalesOrder: a['productname.indskr_productapplicability']? (a['productname.indskr_productapplicability'].split(',').indexOf('100000002')>-1 || a['productname.indskr_productapplicability'].split(',').indexOf('100000003')>-1)? true: false: true,
                          formattedAmount:a['products.amount_Formatted'],
                          uomid: a['products.uomid'],
                          parentProductID: a['productname.indskr_parentproductid'] || ''
                        }
              priceList.products.push(product)
            }
         }
        if(a.hasOwnProperty('track_action')){
          priceList.trackAction = a['track_action_Formatted'];
        }
      }
      // else if(a.hasOwnProperty('planID')){
      //   a['indskr_accountplanid'] = a['planID'];
      //   let aAccountPlan = new AccountPlan(a);
      //   //if we have an entry in the accountPlans array already that means its not hard deleted, we can skip this entry
      //   if(accountPlans.some(ap=> ap.ID == aAccountPlan.ID)){

      //   }
      //   else accountPlans.push(aAccountPlan);
      // }
    })
    if(fullSync){
      this.orderMgmtOfflineService.priceLists = priceLists
    }
    else{
      priceLists.map(pl=>{
        if(!pl.trackAction || (pl.trackAction &&  pl.trackAction == 'Download')){
          let index = this.orderMgmtOfflineService.priceLists.findIndex(o=> o.ID == pl.ID)
          if(index>-1){
            this.orderMgmtOfflineService.priceLists[index] = pl
          }
          else{
            this.orderMgmtOfflineService.priceLists.push(pl);
          }
        }
        if(pl.trackAction &&  pl.trackAction == 'Remove'){
          let index = this.orderMgmtOfflineService.priceLists.findIndex(o=> o.ID == pl.ID)
          if(index>-1){
            this.orderMgmtOfflineService.priceLists[index].state = 1
            this.orderMgmtOfflineService.priceLists[index].statusCode = 2
          }
          else{

          }
        }
      })
    }
    this.orderMgmtOfflineService.priceLists.map((o)=>{
      let toBeSkipped = false;
      if(o.startDate && o.endDate){
        if(isBefore(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(o.endDate),true))
            && isAfter(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(o.startDate),false))){
        }
        else {
          o.state = 1;
          o.statusCode = 2;
          toBeSkipped = true;
        }
      }
      else if(o.startDate || o.endDate){
          if(o.endDate && !o.startDate && isBefore(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(o.endDate),true))){
          }
          else if(o.startDate && !o.endDate && isAfter(new Date(),Utility.changeLocalDateToUTCDateWith0Time(parseInt(o.startDate),false))){
          }
          else{
            o.state = 1;
            o.statusCode = 2;
            toBeSkipped = true;
          }
      }
      if(!toBeSkipped){
        o.countryAndAccountPairs.forEach((CAP)=>{
          if(!this.sharedDataService.countriesWithPriceList.some((c)=>c.countryId == CAP.countryID)){
            this.sharedDataService.countriesWithPriceList.push({
              countryId: CAP.countryID,
              countryName: CAP.countryName
            })
          }
        })
        // Sort Country List
        this._sortCountriesWithPriceList();
      }
    })
    this.orderMgmtOfflineService.priceLists = this.orderMgmtOfflineService.priceLists.filter(p=>{
      return p.state == 0 || !(p.state == 1 && p.statusCode == 2)
    })
  }
  public async getTeamOrderDataOnline() :Promise<any>{
      let headers = new HttpHeaders();
      headers = headers.set('Sync-Service', 'true');
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_TEAM_ACTIVITIES_FOR_TOOLS_BY_ACTIVITY_TYPES;
      const dataRange = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
      url = url.replace('{startDate}', dataRange.from);
      url = url.replace('{endDate}', dataRange.to);
      //url = url.replace('{activityTypes}', 'case');
      url = url.replace('{teamActivity}', 'teamOrder');
      url = url.replace('{positionIDs}', positions.toString());
      try {
        const response =  await this.http.get(url, { headers }).toPromise();
        if(response && response['teamOrders'] && Array.isArray(response['teamOrders'])){
          this.orderMgmtOfflineService.teamOrder = [];
          response['teamOrders'].forEach(rawTeamOrder=> {
            const teamOrder = new OrderActivity(rawTeamOrder);
            teamOrder.isTeamOrder = true;
            teamOrder.type = ActivityType.Order;
            this.orderMgmtOfflineService.teamOrder.push(teamOrder);
          })
        }
      } catch (error) {
        console.log('Fetch Team Order for Tool Error: '+error)
      }
      return;
    }

    private _getAttributeFetchXMLFromElements(elements){
      let attributes = '';
      elements.forEach((element) => {
        if (element.metadata && element.metadata.logicalName) {
          attributes += `<attribute name="${element.metadata.logicalName}"/>`;
        }
        if(element.type == 'panel' && element.elements && element.elements.length > 0){
          attributes += this._getAttributeFetchXMLFromElements(element.elements);
        }
      });
      return attributes;
    }

  public async fetchSurveyJSAtrributesForSalesOrder(salesOrder: OrderActivity, template: any) {
    if (template.surveyConfiguration) {
      let fetchXML = fetchQueries.surgeryOrders.fetchConfiguredAttributesForSurveyJSInfo;
      fetchXML = fetchXML.replace('{salesOrderId}', salesOrder.ID);
      let attributes: string = '';
      template.surveyConfiguration.pages[0].elements.forEach((element) => {
        if (element.metadata && element.metadata.logicalName) {
          attributes += `<attribute name="${element.metadata.logicalName}"/>`;
        }
        if(element.type == 'panel' && element.elements && element.elements.length > 0){
          attributes += this._getAttributeFetchXMLFromElements(element.elements);
        }
      });
      fetchXML = fetchXML.replace('{ATTRIBUTES}', attributes);
      const response = await this.dynamics.executeFetchQuery(
        'salesorders',
        fetchXML
      );
      if (response && Array.isArray(response)) {
        salesOrder.surveyResponse = [];
        salesOrder.surveyResponseData = {};
        salesOrder.surveyResponseAdditionalData = {};
        salesOrder.surveyResponseLookupData = [];
        template.surveyConfiguration.pages[0].elements.forEach((element) => {
          if(element.metadata){
            this._updateSurveyResponseForElement(element,salesOrder,response);
          }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
            this._updateSurveyResponseForElements(element.elements,salesOrder,response);
          }
        });
      }
    }
  }

  private _updateSurveyResponseForElements(elements,salesOrder,response){
    elements.forEach((element) => {
      if(element.metadata){
        this._updateSurveyResponseForElement(element,salesOrder,response);
      }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
        this._updateSurveyResponseForElements(element.elements,salesOrder,response);
      }
    });
  }

  private _updateSurveyResponseForElement(element,salesOrder,response){
    if (element.metadata) {
      let answer: string = '';
      if (element.type == "lookup" && response[0].hasOwnProperty(`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`)) {
        answer = response[0][`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`];
        salesOrder.surveyResponseAdditionalData[`${element.metadata.schemaName}@odata.bind`] = `/${element.metadata.target.setName}(${response[0][`_${element.metadata.logicalName}_value`]})`;
        salesOrder.surveyResponseLookupData.push({
          id: response[0][`_${element.metadata.logicalName}_value`],
          name: response[0][`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`],
          targetEntity: element.metadata.target.name,
          questionName: element.name,
        });
        salesOrder.surveyResponseData[element.name] = response[0][`_${element.metadata.logicalName}_value`];
      } else if (response[0].hasOwnProperty(element.metadata.logicalName)) {
        answer = response[0][element.metadata.logicalName];
        if(element.inputType == "date"){
          answer = format(new Date(answer), 'YYYY-MM-DD');
        }
        salesOrder.surveyResponseData[element.name] = answer;
      }
      salesOrder.surveyResponse.push({
        indskr_question: element.title,
        indskr_answer: answer,
      })
    }
  }

  public async getTeamOrdersNotifications(loadFromDBOnly = false){
    if (!this.deviceService.isOffline && !loadFromDBOnly) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_TEAM_ORDER_NOTIFICATIONS);
      if(syncState && syncState['lastUpdatedTime']){
        const lastUpdatedTime = syncState['lastUpdatedTime'];
        const now = new Date().getTime();
        let fetchXML = fetchQueries.salesOrders.fetchTeamOrdersNotification;
        fetchXML = fetchXML.replace("{userId}",this.authService.user.xSystemUserID);
        let hourDifference = differenceInHours(now,new Date(lastUpdatedTime));
        hourDifference += 1
        fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
        await this.dynamics.executeFetchQuery('salesorders',fetchXML)
          .then(async (res)=> {
            if(res){
              if(Array.isArray(res)){
                res.forEach(record => {
                  let salesOrderNotificationModel = {
                    type: NOTIFICATION.NEW_TEAM_ORDER_NOTIFICATION,
                    name: record['ordernumber'],
                    DateTime: Date.now(),
                    id: NOTIFICATION.NEW_TEAM_ORDER_NOTIFICATION + record['salesorderid'],
                    data: {data: record['ordernumber'],id:record['salesorderid']},
                    icon: 'assets/imgs/orders_pending.svg',
                    isRed: false,
                    params: {teamorder: record['ordernumber']}
                  };
                  const allNotifications = this.myAssistantService.notificationsSubject.getValue();
                  //let idx = allNotifications.findIndex(a=> a.type == NOTIFICATION.NEW_TEAM_ORDER_NOTIFICATION && a.id == NOTIFICATION.NEW_TEAM_ORDER_NOTIFICATION + record['salesorderid']);

                  this.myAssistantService.saveNotificationToDisk(salesOrderNotificationModel);
                })
              }
              syncState.lastUpdatedTime = now;
              await this.disk.updateSyncState(syncState);
            }
          })
          .catch(async (err)=> {
            console.log('Error while fetching new team sales order notifications'+err);
          });
      }else{
        syncState.lastUpdatedTime = new Date().getTime();
        await this.disk.updateSyncState(syncState);
      }
    }
  }


  public async getSalesOrderMeasures(loadFromDbOnly) {
    try {
      if (this.authService.hasFeatureAction(FeatureActionsMap.SALES_DATA_REPORT)) {
        let response;
        let restructuredData;

        if (loadFromDbOnly) {
          const doc = await this.loadOfflineSalesData();
          if (doc?.raw) response = doc.raw;
        } else {
          response = await this.getSalesDataByOwner();
          if (response?.length) this.saveSalesDataInDB(response[0]);
        }

        if (response?.length) {
          restructuredData = { ...response[0], measureType: MeasureType.salesOrder, sortOrder: 0 }
          this.reportDataService.addMeasure(MeasureType.salesOrder, restructuredData);
        } else {
          restructuredData = { measureType: MeasureType.salesOrder, sortOrder: 0 }
          this.reportDataService.addMeasure(MeasureType.salesOrder, restructuredData);
        }
      } else {
        this.reportDataService.removeMeasure(MeasureType.salesOrder);
      }
    } catch (err) {
      console.log(err);
    }
  }

  private async getSalesDataByOwner() {
    try {
      let fetchXML = fetchQueries.edgeAnalytics.fetchSalesOrderMeasuresByOwner;
      return await this.dynamics.executeFetchQuery('indskr_saleskpis', fetchXML);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async saveSalesDataInDB(salesData) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.SALES_DATA_MEASURE, (doc) => {
        doc = {
          raw: [],
        };
        doc.raw = salesData;
        doc.lastModified = doc.lastModified || new Date().getTime();
        return doc;
      });
    } catch (error) {
      console.log('Error in saving sales data')
    }
  }

  async loadOfflineSalesData() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.SALES_DATA_MEASURE, true).then((doc) => {
        offlineDataStored = doc;
      });
    }
    catch (er) {
      console.error("Failed to fetch quotes from db!: ", er)
      return [];
    }
    return offlineDataStored;
  }
}

function getDeletedPayloadObejct (raw){
    let responsePayload = {
        _id: raw._id,
        _rev: raw._rev,
        offlineId: raw.offlineId,
        statecode: raw.statecode,
        statuscode: raw.statuscode,
        track_action: TrackAction.Deleted,
        _deleted: true,
        pendingPushToDynamics:false,
    };
    if(raw['salesorderid']){
        responsePayload['salesorderid'] = raw['salesorderid'];
    }
    return responsePayload;
}

export interface OperationDetail {
    onDynamics: boolean;
    onLocalDatabase: boolean;
    onLocalCopy: boolean;
    appendActivityDetails?: boolean;
    operationDetail?: {
        code: string;
        message: string;
    }
}


