
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Endpoints } from '../../../config/endpoints.config';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../../services/authentication.service';
import { retry, timeout, flatMap, take, concat } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class InboundMeetingDataService {
    constructor(
        private httpClient: HttpClient,
        private authenticationOfflineService: AuthenticationService,
    ) {}

    acknowledgeRequestReceipt(inboundRequestId: string): Observable<any> {
        if (!inboundRequestId) {
            return observableThrowError({ error: 'acknowledgeRequestReceipt: inboundRequestId is required' });
            //return Promise.reject('acknowledgeRequestReceipt: inboundRequestId is required');
        }

        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
            Endpoints.meeting.INBOUND_MEETING_REQUEST.replace('{inbound_request_id}', inboundRequestId);
        let headers = Endpoints.meeting.INBOUND_MEETING_REQUEST_HEADERS;
        headers.headers = headers.headers.set(
            'X-SystemUserId',
            this.authenticationOfflineService.user.systemUserID
        );

        return this.httpClient.put(
            url,
            { statusCode: 'RECEIVED' },
            headers
        ).pipe(
            timeout(10000),
            retry(3),
            /*retryWhen(attempts => 
                attempts.pipe(
                    flatMap((error: HttpErrorResponse) => {
                        if (error.status === 403) {
                            return of(error.status).delay(100);
                        }
                        return Observable.throw(error);
                    }),
                    take(3),
                    concat(Observable.throw({ error: 'Sorry, there was an error (after 3 retries)'})),
                )
            )*/
        );
    }

    async declineInboundRequest(inboundRequestId: string) {
        if (!inboundRequestId) {
            return Promise.reject('declineInboundRequest: inboundRequestId is required');
        }

        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
            Endpoints.meeting.INBOUND_MEETING_REQUEST.replace('{inbound_request_id}', inboundRequestId);
        let headers = Endpoints.meeting.INBOUND_MEETING_REQUEST_HEADERS;
        headers.headers = headers.headers.set(
            'X-SystemUserId',
            this.authenticationOfflineService.user.systemUserID
        );

        return await this.httpClient.put(
            url,
            { statusCode: 'DENY' },
            headers
        ).pipe(
            timeout(10000),
            retry(2),
        ).toPromise();
    }

    async acceptInboundRequest(inboundRequestId: string) {
        if (!inboundRequestId) {
            return Promise.reject('acceptInboundRequest: inboundRequestId is required');
        }

        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
            Endpoints.meeting.INBOUND_MEETING_REQUEST.replace('{inbound_request_id}', inboundRequestId);
        let headers = Endpoints.meeting.INBOUND_MEETING_REQUEST_HEADERS;
        headers.headers = headers.headers.set(
            'X-SystemUserId',
            this.authenticationOfflineService.user.systemUserID
        );

        return await this.httpClient.put(
            url,
            {
                statusCode: 'ACCEPTED',
                repFirstName: this.authenticationOfflineService.user.givenName,
                repLastName: this.authenticationOfflineService.user.lastName,
                repEmail: this.authenticationOfflineService.user.mail,
                repPhone: this.authenticationOfflineService.user.mobilePhone
            },
            headers
        ).pipe(
            timeout(10000),
            retry(2),
        )
        .toPromise();
    }
}