<ion-header>
    <!-- <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button (click)="goBackToObjectiveDetails()" class="back-btn">
                <ion-icon name="chevron-back" color="dark"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title text-center>{{reportData.createdBy}} - {{formattedDate(reportData.createdOn)}}</ion-title>
    </ion-toolbar> -->
    <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ion-list class='details-list'>
        <ion-item-group>
            <!-- <ion-item-divider color="light" class="list-title">
                {{'DETAILS_CAP' | translate}}
                <div class="ion-float-right" slot="end">
                    <span class="actionBtn ion-margin-start" (click)="scrapReport()" [ngClass]="reportData.status != 'Open' ? 'closed-status' : 'open-status'" *ngIf="viewType=='creatorView'">{{'SCRAP' | translate}}</span>
                    <span class="actionBtn ion-margin-start" (click)="sendForApproval()" *ngIf="(reportData.status != 'For Review' && viewType=='creatorView')" [ngClass]="(!isApprovalEnabled || device.isOffline || reportData.status != 'Open')?'closed-status':'open-status'">{{'ACCOUNT_PLAN_SEND_FOR_APPROVAL' | translate}}</span>
                    <span class="actionBtn ion-margin-start" [ngClass]="(device.isOffline)?'closed-status':'open-status'" (click)="cancelreview($event)" *ngIf="(reportData.status == 'For Review' && viewType=='creatorView')">{{'CANCEL_REVIEW' | translate}}</span>
                    <span class="actionBtn  ion-margin-start" [ngClass]="(reportData.status != 'For Review' || device.isOffline)?'closed-status':'open-status'" (click)="disApproveReport($event)" *ngIf="viewType=='managerView'">{{'NOT_APPROVE' | translate}}</span>
                    <span class="actionBtn ion-margin-start" [ngClass]="(reportData.status != 'For Review' || device.isOffline)?'closed-status':'open-status'" (click)="approveReport($event)" *ngIf="viewType=='managerView'">{{'APPROVE' | translate}}</span>
                </div>
            </ion-item-divider> -->

            <ind-section-header [viewData]="reportDetailHeaderModel"></ind-section-header>

            <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>

            <ind-form-field [viewData]="getCreatedByFormField()"></ind-form-field>

            <ind-form-field *ngIf="selectedObjective.type == 'QUANTITATIVE'" [viewData]="getCompletedPercentQuantFormField()"></ind-form-field>

            <ind-form-field *ngIf="selectedObjective.type == 'QUANTITATIVE'" [viewData]="getCompletedValueFormField()"></ind-form-field>

            <ind-form-field *ngIf="selectedObjective.type == 'QUALITATIVE'" [viewData]="getCompletedPercentFormField()"></ind-form-field>

            <ind-form-field [viewData]="getDateFormField()"></ind-form-field>
            <!-- <ion-item-divider color="light" class="list-title">
                {{'ACCOUNT_PLAN_PROGRESS_NOTE' | translate}}
                <div class="ion-float-right" slot="end">
                    <span class="actionBtn ion-margin-start" [ngClass]="!unsavedNotes?'closed-status':'open-status'" (click)="saveNotes()" *ngIf="reportData.status == 'Open'">{{'SAVE' | translate}}</span>

                </div>
            </ion-item-divider> -->
            <ind-form-field [viewData]="getCSFFormField()"></ind-form-field>

            <!-- Progress Report Notes -->
            <ind-section-header [viewData]="reportNotesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
            <ind-text-area  id="notesInput" class="text-area-text" [wrap]="'hard'" [placeHolder]="reportData.status == 'Open' ? textPlaceholder : '' " [readonly]="backgroundUploadInProgress"
                [maxLength]="1000" [value]="tempNoteText" (indChange)="progresNotesChanged($event)" (removeAttachment)="removeAttachment($event)" [attachmentTitle]="attachmentTitle" [showAttachment]="isAttachmentAdded"
                [rows]="3" [cols]="10">
            </ind-text-area>
            <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)" id="followup-file-input">
            <io-note *ngFor="let note of nonDeletedNotes" [isNoteAttachmentEnabled]="!backgroundUploadInProgress && isNotesAttachmentEnabled" [inputNote]="note" [editable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)" [readOnlyNote]="backgroundUploadInProgress || !isNoteControlsEnabled(note)" [readOnlyAttachment]="backgroundUploadInProgress || !isNotesAttachmentEnabled "
                [deletable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)" (saveNoteOut)="updateNote($event)" [disableDownloadInOffline]="true" [from]="UIService.page.PlanProgressReportComponent"></io-note>

        </ion-item-group>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'accountObjectiveReport'" (cancelReportReview)="cancelreview()" (scrapReport)="scrapReport()" (sendReportForApproval)="sendForApproval()" (notApproveReport)="disApproveReport()" (approveReport)="approveReport()"></footer-toolbar>
<!-- </ion-footer> -->
