
<ion-content>
    <ion-list class="overrideIOSList">
<ion-radio-group>
        <ion-list-header (click)="expanded = !expanded">
          <ion-label>{{'ACTIVITIES' | translate}}</ion-label>
          <ion-button>
            <ion-icon *ngIf="!expanded" class="light-color" tappable name="chevron-down" slot="end" ></ion-icon>
            <ion-icon *ngIf="expanded" class="light-color" tappable name="chevron-up" slot="end" ></ion-icon>
          </ion-button>
        </ion-list-header>

        <div *ngIf="expanded">
          <ion-item class="filter-item" *ngFor="let activityType of activityTypes" (click)="onActivityTypeSelection(activityType.activityTypeValue)">
            <ion-label class="smallerPositionText" [ngClass]="{'selected':activityService.activityFilter==activityType.activityTypeValue}">{{activityType.activityTypeName}}</ion-label>
            <ion-icon class="selected" tappable *ngIf="activityService.activityFilter==activityType.activityTypeValue" name="checkmark" slot="end" ></ion-icon>
          </ion-item>
        </div>

        <ion-list-header (click)="goToUserSelection()" [ngClass]="{'disabled':device.isOffline}"
          *ngIf="teamViewEnabled">
          <ion-label>{{'TEAM_VIEW' | translate}}</ion-label>
          <ion-button>
            <ion-icon class="light-color" tappable name="chevron-forward-outline" slot="end" ></ion-icon>
          </ion-button>
        </ion-list-header>
    </ion-radio-group>
</ion-list>
</ion-content>
