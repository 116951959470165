import { Component, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Action, CaptureData, CaptureDataFor, MarketScan, MarketScanAccount, MarketScanCompetitor, MarketScanContact, MarketScanCustomerData, MarketScanMatricChildItemModel, MarketScanMatricItemModel, MarketScanMatrixModel, MarketScanStatusCode } from "@omni/classes/market-scan/market-scan.class";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { ContactPageComponent } from "@omni/components/contact/contact-page/contact-page";
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { AccountOfflineService, AccountSelectedFor } from "@omni/services/account/account.offline.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { DeviceService } from "@omni/services/device/device.service";
import { MarketScanService } from "@omni/services/market-scan/market-scan.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import _, { cloneDeep } from 'lodash';
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from 'rxjs';
import { Contact } from "@omni/classes/contact/contact.class";
import { Paginator } from 'primeng/paginator';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { OpportunityManagementService } from "@omni/services/opportunity-management/opportunity-management.service";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";

@Component({
  selector: 'market-scan-edit-page[base-page]',
  templateUrl: 'market-scan-edit-page.html',
  styleUrls:['market-scan-edit-page.scss']
})
export class MarketScanEditPageComponent {
  public isPreviewActive:boolean = false;
  public indHeaderLeftModel:IndHeaderLeftDataModel;
  public indHeaderRightModel:IndPageTitleViewDataModel;
  public sectionHeader:IndSectionHeaderViewDataModel;
  public rightpanesectionHeader:IndSectionHeaderViewDataModel;
  public itemsSearchStr:string = '';
  public allItems:MainCardViewDataModel[] = [];
  public filteredItems:MainCardViewDataModel[] = [];
  public selectedItem:MainCardViewDataModel;
  private IcurrentMarketScanSubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  public scanData:MarketScanMatrixModel;
  private masterScanData:MarketScanMatrixModel;
  public backgroundUploadInProgress: boolean = false;
  @Input() from: any;
  @Input() currentMarketScan: MarketScan;
  @Input() selectedItemId:string;

  @ViewChild('paginator', { static: false }) paginator: Paginator;

  public contentWidth: number;
  public showPaginator: boolean = false;
  public visibleRowsLength:number = 0;


  constructor(
    public readonly device: DeviceService,
    private readonly translate: TranslateService,
    private readonly navService: NavigationService,
    private readonly uiService: UIService,
    private readonly accountService: AccountOfflineService,
    private readonly marketScanService: MarketScanService,
    private readonly contactService: ContactOfflineService,
    // private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private readonly opportunityService: OpportunityManagementService,
  ){

  }

  ngOnInit(){
    if(this.currentMarketScan){
      this._setViewData();
    }
    
    if(this.selectedItemId){
      let ids = this.selectedItemId.split('_');
      if(ids.length > 0){
        this.selectedItemId = ids[0];
        this._openItemDetail(this.selectedItemId);
      }
    }
    this.IcurrentMarketScanSubscription = this.marketScanService.currentMarketScanObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      if (value) {
        this.currentMarketScan = value;
        this._setViewData();
        if(this.selectedItem){
          this._openItemDetail(this.selectedItem.id);
        }
      }
    });

    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentMarketScan) {
        this.backgroundUploadInProgress = inProgress && this.currentMarketScan.pendingPushToDynamics;
      }
    });

    this.device.screenOrientation.pipe(takeUntil(this.ngDestroy$))
      .subscribe((orientation: number) => {
        this.setWidthofContent();
    });
  }

  ngOnDestroy() {
    if (this.IcurrentMarketScanSubscription){
      this.IcurrentMarketScanSubscription.unsubscribe();
    }
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  setWidthofContent() {
      setTimeout(() => {
        const table = document.getElementById('marketScanTable');
        if (table && table.scrollWidth) {
          //this.scrollWidth = table.scrollWidth;
          this.contentWidth = table.clientWidth;
        }
      }, 400);
  }


  private _setViewData(){
    if(this.currentMarketScan && this.currentMarketScan.category){
      let leftModalTitle:string = '';
      let sectionHeaderModalTitle:string = '';
      if((this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && this.currentMarketScan.selectView == 'ACCOUNT_VIEW') || this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS){
        leftModalTitle = this.translate.instant('ACCOUNTS');
        sectionHeaderModalTitle = this.translate.instant('ACCOUNT_ALL_ACCOUNTS');
        this.allItems = [];
        if(this.currentMarketScan.accounts.length > 0){
          this.allItems = this.currentMarketScan.accounts.filter(a=> a.action != Action.DELETE).map(acc => {
            let viewData:MainCardViewDataModel;
            viewData = {
              id: acc.accountId,
              fixedHeight: true,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showIcon: false,
              iconName: '',
              primaryTextRight: acc.accountName,
              secondaryTextRight: 'Pharmacy',
              secondaryTextRightTwo: '',
              isSecondaryTextRightTwoPillType: false,
              showArrow: false,
              arrowType: '',
              clickHandler: (id: string, event) => {
                this._openItemDetail(id);
              }
            };
            return viewData;
          })
        }
      }else if(this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS || (this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && this.currentMarketScan.selectView == 'CUSTOMER_VIEW') ){
        leftModalTitle = this.translate.instant('CUSTOMERS');
        sectionHeaderModalTitle = this.translate.instant('ALL_CUSTOMERS');
        this.allItems = [];
        if(this.currentMarketScan.contacts.length > 0){
          this.allItems = this.currentMarketScan.contacts.filter(a=> a.action != Action.DELETE).map(con => {
            let viewData:MainCardViewDataModel;
            viewData = {
              id: con.contactId,
              fixedHeight: true,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showIcon: false,
              iconName: '',
              primaryTextRight: con.contact_fullName,
              secondaryTextRight: '',
              secondaryTextRightTwo: '',
              isSecondaryTextRightTwoPillType: false,
              showArrow: false,
              arrowType: '',
              clickHandler: (id: string, event) => {
                this._openItemDetail(id);
              }
            };
            return viewData;
          })
        }
      } else if(this.currentMarketScan.category.indskr_capturedatafor === CaptureDataFor.COMPETITOR){
        leftModalTitle = this.translate.instant('COMPETITORS');
        sectionHeaderModalTitle = this.translate.instant('ALL_COMPETITORS');
        this.allItems = [];
        if(this.currentMarketScan.competitors.length > 0){
          this.allItems = this.currentMarketScan.competitors.filter(a=> a.action != Action.DELETE).map(con => {
            let viewData:MainCardViewDataModel;
            viewData = {
              id: con.competitorId,
              fixedHeight: true,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showIcon: false,
              iconName: '',
              primaryTextRight: con.competitorName,
              secondaryTextRight: '',
              secondaryTextRightTwo: '',
              isSecondaryTextRightTwoPillType: false,
              showArrow: false,
              arrowType: '',
              clickHandler: (id: string, event) => {
                this._openItemDetail(id);
              }
            };
            return viewData;
          })
        }
      }
      this.filteredItems = this.allItems;
      this.indHeaderLeftModel = {
        id: 'market-scan-edit-header-left',
        cssClass: 'main-tool-header-title',
        title: leftModalTitle,
        mode: false,
        customHeaderProps:{hasCancel:true},
        controls: [
          {
            id: "closepage",
            imgSrc: 'assets/imgs/header_cancel.svg',
            cssClass: 'seventyPercentWidth',
            isDisabled: false,
            align: "left",
         }
        ],
      };
  
      this.indHeaderRightModel = {
        id: 'market-scan-edit-header-right',
        title: this.selectedItem?this.selectedItem.title:'',
        controls: [],
      };
  
      this.sectionHeader = {
        id: 'all-items-header',
        title: sectionHeaderModalTitle + ' (' + this.filteredItems.length + ')',
        controls: [
          {
            id: 'add-item',
            text: `${this.translate.instant('ADD')}`,
            //icon: 'assets/imgs/ios_add_3x.svg',
            isDisabled: !this.marketScanService.isOwner() || this.currentMarketScan.statuscode !== MarketScanStatusCode.Active || this.backgroundUploadInProgress,
          }
        ]
      };

      this.rightpanesectionHeader = {
        id: 'scan-matirx-header',
        title: this.translate.instant('SCAN'),
        controls: [
          {
            id: 'left-arrow',
            text: '',
            icon: 'assets/imgs/arrow-left-grey.svg',
            isDisabled: false,
          },
          {
            id: 'right-arrow',
            text: ``,
            icon: 'assets/imgs/arrow-right-grey.svg',
            isDisabled: false,
          }
        ],
      };
    }
  }


  public searchInput (data) {
    if(this.itemsSearchStr.length>0){
      this.filteredItems = this.allItems.filter((a)=>{
        return a.primaryTextRight.toLowerCase().indexOf(this.itemsSearchStr.trim().toLowerCase())>-1;
      });
    }
    else{
      this.filteredItems = this.allItems;
    }
    this.uiService.updateNothingSelectedScreenMessageFor(this.filteredItems.length);
  }

  public onPageTitleControlClick(id){
    if(id==='closepage'){
      this.closePage();
    }
    if(id==='close'){
      this.closeRightPane();
    }
  }

  public onSectionHeaderControlClick (event){
    if(event && event == 'add-item'){
      this._handleAddButton();
    }
    const scrollJump = 50;
    if(event && event == 'left-arrow'){
      let target = document.getElementById('marketScanEditTableRow');
      if(target){
        target.scrollLeft = target.scrollLeft - scrollJump;
      }
    }
    if(event && event == 'right-arrow'){
      let target = document.getElementById('marketScanEditTableRow');
      if(target){
        target.scrollLeft = target.scrollLeft + scrollJump;
      }
    }
  }

  public closePage(): void {
    this.navService.popWithPageTracking();
  }

  public closeRightPane(): void {
    this.isPreviewActive = false;
    this.masterScanData = null;
    this.scanData = null;
    this.selectedItem = null;
  }

  private _openItemDetail(id){
    if(this.selectedItem && this.selectedItem.id !== id){
      this.currentMarketScan.retainPage = false;
    }
    this.isPreviewActive = true;
    this.allItems.forEach(a=> {
      a.isSelected = false;
      if(a.id == id){
        a.isSelected = true;
        this.selectedItem = a;
      }
    });
    let idx = this.marketScanService.currentMarketScanMatrixData.findIndex(a => a.matrixId == id);
    if(idx >= 0){
      this.masterScanData = this.marketScanService.currentMarketScanMatrixData[idx];
    }
    this.setUpPaginator();
  }

  private async _handleAddButton(){
    if(this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS || (this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && this.currentMarketScan.selectView == 'ACCOUNT_VIEW')){
      const contactIds = [];
      this.uiService.showNewActivity = false;
      this.accountService.selectedFor = AccountSelectedFor.MARKET_SCAN;
      this.accountService.accountPageMode = ComponentViewMode.ADDNEW;
      this.accountService.accessedAccountListFrom = PageName.MarketScanDetailsComponent;
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': this.accountService.accountPageMode, selectedContactIds:contactIds, callbackEvent: (data: any) => this._handleAccountComponentCallback(data) }, PageName.AccountPageComponent);
    }else if(this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS || (this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && this.currentMarketScan.selectView == 'CUSTOMER_VIEW')){
      this.uiService.prevView = 'market-scan-details';
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'contactDetails';
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      if (_.isEmpty(this.currentMarketScan.segmentedContacts)) {
        this.marketScanService.mapFiltersForCustomerSegmentation(this.currentMarketScan, false);
      }
      this.contactService.accessedContactListFrom = PageName.MarketScanDetailsComponent;
      await this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent)
    }else if(this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.COMPETITOR){
      this.openCompetitorsSelect();
    }
    
  }

  private async _handleAccountComponentCallback(data) {
    if (this.backgroundUploadInProgress) return;
    if (data) {
      await this.uiService.displayLoader();
      const marketScanSelectedAccounts: MarketScanAccount[] = [];
      if (!_.isEmpty(data.selectedItems)) {
        //data.selectedItems.map(async item => {
        for (let l=0;l<data.selectedItems.length;++l){
          let item = data.selectedItems[l];
          if(item){
            let marketScanSelectedContacts: MarketScanContact[] = [];
            if (this.currentMarketScan.category && this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && this.currentMarketScan.selectView == 'ACCOUNT_VIEW') {
              let affiliatedContacts:Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById(item.id)]);
              if (affiliatedContacts && affiliatedContacts.length > 0) {
                //affiliatedContacts.map(contact => {
                for (let m=0;m<affiliatedContacts.length;++m){
                  let contact = affiliatedContacts[m];
                  if(contact && !marketScanSelectedContacts.some(c=> c.contactId == contact.ID)){
                    marketScanSelectedContacts.push(new MarketScanContact(contact.fullName, contact.ID, Action.PATCH, contact['sequence']))
                  }
                }
              }
            }
            marketScanSelectedAccounts.push(new MarketScanAccount(item.name, item.id, Action.PATCH, item.sequence,true,marketScanSelectedContacts))
          }
        }
      }
      if (this.currentMarketScan.indskr_marketscanid || this.currentMarketScan.pendingPushToDynamics) {
        this.currentMarketScan.isModified = true;
      }
      this.marketScanService.mapAccountsToMarketScan(this.currentMarketScan, marketScanSelectedAccounts);
      this.uiService.dismissLoader();
    }
  }

  public async handleMetricValue(item:MarketScanMatricItemModel,event){
    if( !event || !event.target) return;
    let num=parseInt(event.target.value) >=0 ? parseInt(event.target.value) : 0
    if(item && item.columnItemId){
      let idx = this.currentMarketScan.marketScanCustomerDatas.findIndex(c=> c.indskr_externalid == item.columnItemId);
      if(idx >= 0){
        let metricName:string;
        let metricObject:string;
        if(this.currentMarketScan.category.indskr_capturedata == CaptureData.PRODUCT || this.currentMarketScan.category.indskr_capturedata === CaptureData.PROCEDURE){
          metricName = 'productId';
          metricObject = 'products';
        } else{
          return;
        }
        this.currentMarketScan.marketScanCustomerDatas[idx].rX =num;
        this.currentMarketScan.marketScanCustomerDatas[idx].action = Action.PATCH;
        this.currentMarketScan.retainPage = true;
        //this.currentMarketScan.pendingPushToDynamics = true;
        if (this.currentMarketScan.indskr_marketscanid || this.currentMarketScan.pendingPushToDynamics) {
          this.currentMarketScan.isModified = true;
        }
        const matrixIdx = this.marketScanService.currentMarketScanMatrixData.findIndex(a=> a.matrixId == this.selectedItem.id);
        if(matrixIdx >= 0){
          let columnIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable.findIndex(c => c.columdId == this.currentMarketScan.marketScanCustomerDatas[idx][metricName]);
          if(columnIdx >= 0){
            let itemIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems.findIndex(i => i.columnItemId == item.columnItemId);
            if(itemIdx >= 0){
              this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems[itemIdx].columnItemLabel = num.toString();
              this.masterScanData.matrixTable[columnIdx].columnItems[itemIdx].columnItemLabel = num.toString();
              if(this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
                let rows:MarketScanCustomerData[];
                if(this.currentMarketScan.selectView == "ACCOUNT_VIEW"){
                  rows = this.currentMarketScan.marketScanCustomerDatas.filter(r => r.action != Action.DELETE && r['indskr_assessmentcategoryid'] == this.currentMarketScan.category.indskr_assessmentcategoryid && r[metricName] == this.currentMarketScan.marketScanCustomerDatas[idx][metricName] && r['measureId'] == this.currentMarketScan.marketScanCustomerDatas[idx].measureId && r['accountId'] == this.currentMarketScan.marketScanCustomerDatas[idx].accountId);
                }else if(this.currentMarketScan.selectView == "CUSTOMER_VIEW"){
                  rows = this.currentMarketScan.marketScanCustomerDatas.filter(r => r.action != Action.DELETE && r['indskr_assessmentcategoryid'] == this.currentMarketScan.category.indskr_assessmentcategoryid && r[metricName] == this.currentMarketScan.marketScanCustomerDatas[idx][metricName] && r['measureId'] == this.currentMarketScan.marketScanCustomerDatas[idx].measureId && r['contactId'] == this.currentMarketScan.marketScanCustomerDatas[idx].contactId);
                }
                if(rows && rows.length){
                  let total = 0;
                  rows.forEach(q=> {
                    if(q.rX){
                      total += parseInt(q.rX);
                    }
                  })
                  for (let w = itemIdx; w < this.masterScanData.matrixTable[columnIdx].columnItems.length; w++){
                    if(this.masterScanData.matrixTable[columnIdx].columnItems[w].isTotal){
                      this.masterScanData.matrixTable[columnIdx].columnItems[w].columnItemLabel = total.toString();
                      this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems[w].columnItemLabel = total.toString();
                      break;
                    }
                  }
                }
              }
            }
          }
        }

        if(this.marketScanService.currentMarketScanDetailsViewMatrix){
          let productIdx = this.currentMarketScan[metricObject].filter(a => a.action != Action.DELETE).findIndex(p=> p[metricName] == this.currentMarketScan.marketScanCustomerDatas[idx][metricName]);
          if(productIdx >= 0){
            let colItemIdx = this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx+1].columnItems.findIndex(a=> a.columnItemId == this.selectedItem.id+'_'+(productIdx+1));
            if(colItemIdx >= 0){
              let childItemIdx = this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx+1].columnItems[colItemIdx].childItems.findIndex(a=> a.childItemId == item.columnItemId);
              if(childItemIdx >= 0){
                this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx+1].columnItems[colItemIdx].childItems[childItemIdx].childItemLabel = num.toString();
              }
            }
          }
        }

        await this.marketScanService.updateMarketScanInPouchDB(this.currentMarketScan);
        this.marketScanService.upsertMarketScans(this.currentMarketScan);
        this.marketScanService.setCurrentMarketScan(this.currentMarketScan);
      }
    }
  }

  async handleMetricItemValue(item: MarketScanMatricChildItemModel, event) {
    if( !event || !event.target) return;
    let num=parseInt(event.target.value) >=0 ? parseInt(event.target.value) : 0
    if (item && item.childItemID) {
      let idx = this.currentMarketScan.marketScanCustomerDatas.findIndex(c => c.indskr_externalid == item.childItemID);
      if (idx >= 0) {
        let metricName: string;
        let metricObject: string;
        if (this.currentMarketScan.category.indskr_capturedata == CaptureData.PRODUCT || this.currentMarketScan.category.indskr_capturedata === CaptureData.PROCEDURE) {
          metricName = 'productId';
          metricObject = 'products';
        } else {
          return;
        }
        this.currentMarketScan.marketScanCustomerDatas[idx].rX = num;
        this.currentMarketScan.marketScanCustomerDatas[idx].action = Action.PATCH;
        this.currentMarketScan.retainPage = true;
        //this.currentMarketScan.pendingPushToDynamics = true;
        if (this.currentMarketScan.indskr_marketscanid || this.currentMarketScan.pendingPushToDynamics) {
          this.currentMarketScan.isModified = true;
        }
        const matrixIdx = this.marketScanService.currentMarketScanMatrixData.findIndex(a => a.matrixId == this.selectedItem.id);
        if (matrixIdx >= 0) {
          let columnIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable.findIndex(c => c.columdId == this.currentMarketScan.marketScanCustomerDatas[idx][metricName]);
          if (columnIdx >= 0) {
            let itemIdx;
            if (this.currentMarketScan.selectView == "ACCOUNT_VIEW") {
              itemIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems.findIndex(i => i.columnItemId == this.currentMarketScan.marketScanCustomerDatas[idx].contactId + this.currentMarketScan.marketScanCustomerDatas[idx].productId)
            } else if (this.currentMarketScan.selectView == "CUSTOMER_VIEW") {
              itemIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems.findIndex(i => i.columnItemId == this.currentMarketScan.marketScanCustomerDatas[idx].accountId + this.currentMarketScan.marketScanCustomerDatas[idx].productId)
            }
            if (itemIdx >= 0) {
              let childIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems[itemIdx].childItems.findIndex(i => i.childItemID == item.childItemID);
              if (childIdx >= 0) {
                this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems[itemIdx].childItems[childIdx].childItemLabel = num.toString();
                this.masterScanData.matrixTable[columnIdx].columnItems[itemIdx].childItems[childIdx].childItemLabel = num.toString();
                if (this.currentMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS) {
                  let rows: MarketScanCustomerData[];
                  if (this.currentMarketScan.selectView == "ACCOUNT_VIEW") {
                    rows = this.currentMarketScan.marketScanCustomerDatas.filter(r => r.action != Action.DELETE && r['indskr_assessmentcategoryid'] == this.currentMarketScan.category.indskr_assessmentcategoryid && r[metricName] == this.currentMarketScan.marketScanCustomerDatas[idx][metricName] && r['measureId'] == this.currentMarketScan.marketScanCustomerDatas[idx].measureId && r['accountId'] == this.currentMarketScan.marketScanCustomerDatas[idx].accountId);
                  } else if (this.currentMarketScan.selectView == "CUSTOMER_VIEW") {
                    rows = this.currentMarketScan.marketScanCustomerDatas.filter(r => r.action != Action.DELETE && r['indskr_assessmentcategoryid'] == this.currentMarketScan.category.indskr_assessmentcategoryid && r[metricName] == this.currentMarketScan.marketScanCustomerDatas[idx][metricName] && r['measureId'] == this.currentMarketScan.marketScanCustomerDatas[idx].measureId && r['contactId'] == this.currentMarketScan.marketScanCustomerDatas[idx].contactId);
                  }
                  if (rows && rows.length) {
                    let total = 0;
                    rows.forEach(q => {
                      if (q.rX) {
                        total += parseInt(q.rX);
                      }
                    })
                    let childIdx = this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems[itemIdx].childItems.findIndex(i => i.childItemID == item.childItemID);
                    for (let w = itemIdx; w < this.masterScanData.matrixTable[columnIdx].columnItems.length; w++) {
                      if (this.masterScanData.matrixTable[columnIdx].columnItems[w].isTotal) {
                        this.masterScanData.matrixTable[columnIdx].columnItems[w].childItems[childIdx].childItemLabel = total.toString();
                        this.marketScanService.currentMarketScanMatrixData[matrixIdx].matrixTable[columnIdx].columnItems[w].childItems[childIdx].childItemLabel = total.toString();
                        break;
                      }
                    }
                  }
                }
              }

            }
          }
        }
        if (this.marketScanService.currentMarketScanDetailsViewMatrix) {
          let productIdx = this.currentMarketScan[metricObject].filter(a => a.action != Action.DELETE).findIndex(p => p[metricName] == this.currentMarketScan.marketScanCustomerDatas[idx][metricName]);
          if (productIdx >= 0) {
            let colItemIdx = this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx + 1].columnItems.findIndex(a => a.columnItemId == this.selectedItem.id + '_' + (productIdx + 1));
            if (colItemIdx >= 0) {
              let childItemIdx;
              if (this.currentMarketScan.selectView == "ACCOUNT_VIEW") {
                childItemIdx = this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx + 1].columnItems[colItemIdx].childItems.findIndex(a => a.childItemId == this.currentMarketScan.marketScanCustomerDatas[idx].contactId + this.currentMarketScan.marketScanCustomerDatas[idx].productId);
              } else if (this.currentMarketScan.selectView == "CUSTOMER_VIEW") {
                childItemIdx = this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx + 1].columnItems[colItemIdx].childItems.findIndex(a => a.childItemId == this.currentMarketScan.marketScanCustomerDatas[idx].accountId + this.currentMarketScan.marketScanCustomerDatas[idx].productId);
              }
              if (childItemIdx >= 0) {
                let subChilItemIdx = this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx + 1].columnItems[colItemIdx].childItems[childItemIdx].subchildItems.findIndex(a => a.subchildItemID == this.currentMarketScan.marketScanCustomerDatas[idx].indskr_externalid);
                if (subChilItemIdx >= 0) {
                  this.marketScanService.currentMarketScanDetailsViewMatrix.matrixColumns[productIdx + 1].columnItems[colItemIdx].childItems[childItemIdx].subchildItems[subChilItemIdx].subchildItemLabel = num.toString();
                }
              }
            }
          }
        }
        await this.marketScanService.updateMarketScanInPouchDB(this.currentMarketScan);
        this.marketScanService.upsertMarketScans(this.currentMarketScan);
        this.marketScanService.setCurrentMarketScan(this.currentMarketScan);
      }
    }
  }


  private setUpPaginator() {
    this.setWidthofContent()
    let pages = this.getPage();
    const tableElement = document.getElementById("marketScanTable");
    if (!tableElement || this.visibleRowsLength <= 10) {
      this.scanData = cloneDeep(this.masterScanData);
      this.showPaginator = false;
    }else{
      this.scanData = cloneDeep(this.masterScanData);
      this.scanData.matrixTable.forEach(col => {
        let firstColItem = col.columnItems[0];
        col.columnItems = col.columnItems.slice(pages.from, pages.to);
        if(pages.from != 0){
          col.columnItems.unshift(firstColItem);
        }
      })
      this.showPaginator = true;
    }
  }

  private getPage(): { from: number, to: number } {
    let from: number = 0;
    let to: number = 10;
    this.visibleRowsLength = this.masterScanData.matrixTable[0].columnItems.length;
    if (this.visibleRowsLength > 10 && this.paginator) {
      if (this.currentMarketScan.retainPage) {
        from = (this.paginator.getPage() * this.paginator.rows);
        to = (this.paginator.getPage() + 1) * this.paginator.rows;
        if (this.paginator.getPage() == this.paginator.getPage() + 1 && to > this.visibleRowsLength) {
          to = this.visibleRowsLength;
        }
      } else {
        this.paginator.changePage(0);
      }
    }
    return { from: from, to: to };
  }

  async paginate(event) {
    // event.first = Index of the first record
    // event.rows = Number of rows to display in new page
    // event.page = Index of the new page
    // event.pageCount = Total number of pages
    if (event.pageCount == 1) return;
    const from: number = (event.page * event.rows);
    let to: number = (event.page + 1) * event.rows;
    if (event.pageCount == event.page + 1 && to > this.visibleRowsLength) {
      to = this.masterScanData.matrixTable[0].columnItems.length;
    }
    this.scanData = cloneDeep(this.masterScanData);
    this.scanData.matrixTable.forEach(col => {
      let firstColItem = col.columnItems[0];
      col.columnItems = col.columnItems.slice(from, to);
      if (from != 0) {
        col.columnItems.unshift(firstColItem);
      }
    })
  }

  private openCompetitorsSelect(): void {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('COMPETITORS'),
      dividerTitle: this.translate.instant('ALL_COMPETITORS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: (this.opportunityService.opportunityCompetitors),
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('COMPETITORS'),
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleCompetitorComponentEvent(data, eventTarget, refData, 'competitors'),
      searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'competitor'),
      data: this.opportunityService.opportunityCompetitors.map(c => {
        let isSelected = false;
        if (this.currentMarketScan && this.currentMarketScan.competitors && this.currentMarketScan.competitors.some(oco => (oco.competitorId === c.id && oco.action !== Action.DELETE))) {
          isSelected = true;
        }
        return {
          id: c.id,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: c.name,
          showArrow: false,
          arrowType: '',
        };
      }),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
  }

  private async _handleCompetitorComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (data && data.isDone) {
        console.log(data, eventTarget, refData, fieldName)
        const marketScanSelectedCompetitors: MarketScanCompetitor[] = [];
        if (data.selectedItems && data.selectedItems.length) {
          data.selectedItems.forEach(competitor => {
            marketScanSelectedCompetitors.push(new MarketScanCompetitor(competitor.primaryTextRight, competitor.id, Action.PATCH))
          });
        }
        await this.uiService.displayLoader();
        if (this.currentMarketScan.indskr_marketscanid || this.currentMarketScan.pendingPushToDynamics) {
          this.currentMarketScan.isModified = true;
        }

        this.marketScanService.unMapReMapCompetitors(marketScanSelectedCompetitors, this.currentMarketScan);
        this.marketScanService.mapCompetitorToMarketScan(this.currentMarketScan, marketScanSelectedCompetitors);
        this.uiService.dismissLoader();
      }
    }
  }

  private _handleProductsComponentSearch(text: string, fieldName: string): string[] {
    let ids;
    switch (fieldName) {
      case 'competitor':
        if (text.length >= 1) {
          ids = this.opportunityService.opportunityCompetitors && this.opportunityService.opportunityCompetitors.filter(com => {
            return com.name.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(com => com.id);
        } else {
          ids = this.opportunityService.opportunityCompetitors && this.opportunityService.opportunityCompetitors.map(com => com.id);
        }
        break;
      // case 'procedure':
      //   if (text.length >= 1) {
      //     ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.filter(pro => {
      //       return pro.surgeryName.trim().toLowerCase().includes(text.trim().toLowerCase());
      //     }).map(pro => pro.surgeryId);
      //   } else {
      //     ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.map(pro => pro.surgeryId);
      //   }
      //   break;
      default:
        ids = [];
        break;
    }
    return ids;
  }

}