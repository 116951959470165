import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {RepServices} from "@omni/data-services/rep/rep.services";
import {MdmService} from "@omni/services/mdm/mdm.service";
import {UIService} from "@omni/services/ui/ui.service";
import {IndPageTitleViewDataModel} from "@omni/models/indPageTitleDataModel";
import {TranslateService} from "@ngx-translate/core";
import {IndSectionHeaderViewDataModel} from "@omni/models/indSectionHeaderDataModel";
import {AuthenticationService} from "@omni/services/authentication.service";
import {ChangeRequestType, MDMType} from "@omni/classes/mdm/source-type-optionset.class";
import {FeatureActionsMap} from "@omni/classes/authentication/user.class";
import {NavigationService} from "@omni/services/navigation/navigation.service";
import {EventsService} from "@omni/services/events/events.service";
import {DeviceService} from "@omni/services/device/device.service";
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { NewActivityDataModel } from '@omni/models/indNewActivityDataModel';
import { ActivitySource } from '@omni/services/activity/activity.service';
import { DynamicFormsService } from "@omni/services/dynamic-forms/dynamic-forms-service";


@Component({
  selector: 'new-change-request',
  templateUrl: 'new-change-request.html',
  styleUrls:['new-change-request.scss']
})
export class NewChangeRequestComponent {

  /** Use this.crRequestedFrom instead of this.from to compare the source origin of the new change request as this.from is also used to show the translated source origin value. */
  @Input() from: string;
  @Input() newActivityDataModel: NewActivityDataModel;
  public selectedType:any = null;
  newChangeRequestPageTitle: IndPageTitleViewDataModel;
  changeRequestMainHeaderData: IndSectionHeaderViewDataModel;
  options: any[];
  private crRequestedFrom: string;

  constructor(
    private authenticationService: AuthenticationService,
    private _cd: ChangeDetectorRef,
    public repService: RepServices,
    public mdmService: MdmService,
    private navService: NavigationService,
    private uiService: UIService,
    public translate: TranslateService,
    private events: EventsService,
    private device: DeviceService,
    private accountService: AccountOfflineService,
    private contactService: ContactOfflineService,
    private dynamicFormsService: DynamicFormsService,
  ) {}

  ngOnInit() {
    if (this.newActivityDataModel) {
      this.options = this.newActivityDataModel.displayOptions;
      this.from = this.newActivityDataModel.source;
      this.setAndUpdateFromValues();
      this.selectedType = this.options[0];
      this.initNewChangeRequestPageTitle(this.newActivityDataModel.title);
    } else {
      this.setAndUpdateFromValues();
      this.initNewChangeRequestMainHeader();
      this.formatSourceTypeOptions();
      this.initNewChangeRequestPageTitle();
    }
  }

  ngOnDestroy() {
    // this.events.unsubscribe("contacts:customForm");
    // this.events.unsubscribe("accounts:customForm");
  }

  formatSourceTypeOptions() {
    this.options = [];
    const sourceTypes = JSON.parse(JSON.stringify(this.mdmService.sourceTypeOptionSet));
    sourceTypes[0]['formattedLabel'] = sourceTypes[0].label + " " + this.from;
    if(this.translate.currentLang == 'pt') {
      sourceTypes[0]['formattedLabel'] = `${this.from} ${sourceTypes[0]['label']}`;
    }else if(this.translate.currentLang == 'es') {
      sourceTypes[0]['formattedLabel'] = `${sourceTypes[0]['label']} ${this.from}`;
    }
    sourceTypes[0]['isChecked'] = true;
    
    // To disable Business Contacts checkbox in Create Contacts section in Offline
    if(sourceTypes[0]['value'] === ChangeRequestType.BUSINESS && this.device.isOffline) {
      sourceTypes[0]['isDisabled'] = true;
      sourceTypes[0]['isChecked'] = false;
    }

    this.selectedType = sourceTypes[0];
    this.options.push(sourceTypes[0]);
    if (sourceTypes[1] && sourceTypes[1].mdmOptionSet.length) {
      sourceTypes[1].mdmOptionSet.forEach(mdm => {
        mdm['formattedLabel'] = mdm.label + " " + this.from;
        if(this.translate.currentLang == 'pt') {
          mdm['formattedLabel'] = this.from + " " + mdm.label;
        }else if(this.translate.currentLang == 'es') {
          mdm['formattedLabel'] = `Profesional Sanitario (${mdm.label})`;
        }
        mdm['isMDMType'] = true;
        if (this.crRequestedFrom === 'Contact' || this.crRequestedFrom === 'Contacts') {
          if (mdm.value == MDMType.ONEKEY && this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_ONEKEY_SELECTION)) {
            this.options.push(mdm);
          }
        }
        if (this.crRequestedFrom === 'Account') {
          if (mdm.value == MDMType.ONEKEY && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_ONEKEY_SELECTION)) {
            this.options.push(mdm);
          }
        }
      })
    }

    // To check Second checkbox in Create Contacts section in Offline
    if(this.options.length === 2 && sourceTypes[0]['value'] === ChangeRequestType.BUSINESS && this.device.isOffline) {
      this.options[1]['isChecked'] = true;
      this.selectedType = this.options[1];
    }
  }

  handleNewRequest() {
    this.dynamicFormsService.okLookUpMapper = [];
    if (this.newActivityDataModel) {
      this.newActivityDataModel.eventHandler(this.selectedType);
    } else {
      if (this.crRequestedFrom === 'Contact' || this.crRequestedFrom === 'Contacts') {
        this.events.publish("contacts:customForm", this.selectedType);
      }
      else {
        this.events.publish("accounts:customForm", this.selectedType);
      }
      if (this.selectedType.value == MDMType.ONEKEY && this.selectedType.id && this.selectedType.id.includes('one_key')) {
        this.contactService.isOneKeyChangeRequest = true;
        this.accountService.isOneKeyChangeRequest = true;
      }
    }
    this.navService.popChildNavPageWithPageTracking();
  }

  cancel() {
    this.uiService.activeView = '';
    this.events.unsubscribe("contacts:customForm");
    this.events.unsubscribe("accounts:customForm");
    this.navService.setChildNavRightPaneView(false);
    this.navService.popChildNavPageWithPageTracking().then(() => {
      if (this.crRequestedFrom === ActivitySource.ONE_KEY_CONTACT_EDIT) {
        this.uiService.activeView = this.uiService.prevView;
        this.navService.setChildNavRightPaneView(true);
      } else if(this.crRequestedFrom === 'Account' && this.accountService.tempSelected) {
        this.accountService.selected = this.accountService.tempSelected;
        //clean-up this.from later
      } else if((this.crRequestedFrom === 'Contact' || this.crRequestedFrom === 'Contacts') && this.contactService.tempContactInformation) {
        // this.uiService.activeView = 'contactDetails';
        this.navService.setChildNavRightPaneView(true);
        this.contactService.contactInformation = this.contactService.tempContactInformation;
      }
    });
  }

  public selectType(item) {
    this.options.forEach(o => {
      o['isChecked'] = false;
    });
    item['isChecked'] = true;
    this.selectedType = item;
    this.newChangeRequestPageTitle.controls[1].isDisabled = false;
  }

  private setAndUpdateFromValues() {
    this.crRequestedFrom = this.from;
    if(this.crRequestedFrom === "Account"){
      this.from = this.translate.instant('ACCOUNT',this.from);
    } else {
      this.from = this.translate.instant('CONTACT',this.from);
    }
  }

  private initNewChangeRequestPageTitle(title?: string): void {
    console.log("this.from",this.from)
    this.newChangeRequestPageTitle = {
      id: 'new-change-req-page-title',
      title: title? title : this.translate.instant('CREATE_CHANGE_REQUEST', { changeRequest: this.from }),
      controls: [
        {
          id: 'cancel',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: false,
          align: 'right'
        },
        {
          id: 'confirm',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('CONFIRM'),
          isDisabled: !this.selectedType,
          align: 'right'
        }
      ],
    };
  }

  private initNewChangeRequestMainHeader(): void {
    this.changeRequestMainHeaderData = {
      id: 'change-req-select-header',
      title: this.translate.instant('CREATE_CHANGE_REQUEST', { changeRequest: this.from }),
      isMultiFilter: false,
      controls: [
      ],
    };
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.cancel();
        break;
      case 'confirm':
        this.handleNewRequest();
        break;
      default:
        break;
    }
  }

}
