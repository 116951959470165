import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { UIService } from "../../services/ui/ui.service";
import { EventRegistration, ContactEvent } from "../../classes/customer-event/customer-event.class";
import { ActivityService } from "../../services/activity/activity.service";
import { CustomerEventsService } from "../../services/customer-event/customer-events.service";
import { Contact } from "../../classes/contact/contact.class";
import { TranslateService } from "@ngx-translate/core";
import { IndPageTitleViewDataModel } from "../../models/indPageTitleDataModel";
import { EventRegistrationStatus } from '@omni/enums/event/event.enum';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';

@Component({
  selector: 'event-registrations',
  templateUrl: 'event-registrations.html',
  styleUrls:['event-registrations.scss']
})
export class EventRegistrationComponent implements OnInit {
  public contactEvents: Array<{
    contactId: string;
    contactName: string;
    events: EventRegistration[]
  }> = [];
  @ViewChild('expWrapper', {static:true}) expandWrapper: ElementRef;
  eventRegistrationPageTitle: IndPageTitleViewDataModel;

  constructor(private uiService: UIService,
    private activityService: ActivityService,
    private customerEventService: CustomerEventsService,
    private translate : TranslateService,
    public modalCtrl: ModalController,
    private eventsToolService: EventsToolService,
    private eventsToolDataService: EventsToolDataService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService
    ) {
  }

  ngOnInit() {
    //Initialize meeting contacts events
    const meetingContacts: Contact[] = this.activityService.selectedActivity['contacts'];
    meetingContacts.forEach(contact => {
      let contactEvent: ContactEvent = this.customerEventService.getEventsForContact(contact.ID);
      let events: any = contactEvent['availableEvents'];
      if (events.length > 0) {
        let contactEvent = {
          'contactId': contact.ID,
          'contactName': contact.firstName + ' ' + contact.lastName,
          'events': events
        };
        this.contactEvents.push(contactEvent);
      }
    });
    this.initEventRegistrationsPageTitle();
  }

  isCheckedInEvents(events) {
    return events.some((event) => event.indskr_reasons != 548910002 && event.indskr_reasons != 451680002);
  }
  expandEventDetails(event, customerEvent) {
    customerEvent.isExpanded = !customerEvent.isExpanded;
    customerEvent.eventExpandIcon = (customerEvent.isExpanded) ? 'accordion-minus':'accordion-add';
  }

  goBack() {
    this.uiService.activeView = this.uiService.prevView;
    this.modalCtrl.dismiss();
  }

  eventRegResponseChange(eventRegResponse) {
    this.contactEvents.forEach((cId) => {
      cId.events.forEach(ev => {
        if (ev.eventRegistrationId == eventRegResponse.eventRegistrationId) {
          ev.response = eventRegResponse.response;

          // Update the participant registration status in the event records
          const event = this.eventsToolService.getEventDetailsbyId(ev.eventId);
          if (event) {
            const participant = event.participants.find(p => p.id == cId.contactId);
            if (participant) {
              participant.indskr_reasons = ev.response ? EventRegistrationStatus.Accepted : EventRegistrationStatus.Declined;
            }
            this.eventsToolDataService.updateEventsData(
              {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
              },
              [event],
            );
          }
        }
      });
    });
  }

  private initEventRegistrationsPageTitle(): void {
    this.eventRegistrationPageTitle = {
      id: 'event-registration-page-title',
      title: this.translate.instant('EVENTS'),
      controls: [
        {
          id: "close",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
        }
      ],
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.goBack();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  getFormattedDate(date) {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }
}
