import { createSelector } from '@ngrx/store';
import { ResourceState } from '../../application.state';

import { ResourceManagerState } from './resource.state';

export const selectResourceManager = (state: ResourceState) => state.resourceManager;
export const selectIsDownloading = createSelector(selectResourceManager, (state: ResourceManagerState) => state.isDownloading);
export const selectResourceManagerError = createSelector(selectResourceManager, (state: ResourceManagerState) => state.errorInfo);
export const selectDownloadQueue = createSelector(selectResourceManager, (state: ResourceManagerState) => state.downloadQueue);
export const selectDownloadedQueue = createSelector(selectResourceManager, (state: ResourceManagerState) => state.downloadedQueue);
export const selectResourceId = createSelector(selectResourceManager, (state: ResourceManagerState) => state.resourceId);
