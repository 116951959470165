import { CaseManagementService } from './../case-management/case-management.service';
import { Injectable } from "@angular/core";
import { LookupSearchResult } from "@omni/classes/popover/search-lookup-popover.class";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { DynamicsClientService } from "@omni/data-services/dynamics-client/dynamics-client.service";
import { AuthenticationService } from "../authentication.service";
import { DeviceService } from "../device/device.service";
import { DiskService } from "../disk/disk.service";

@Injectable({
  providedIn: 'root'
})

export class SearchLookupPopoverService {

  public lookupSearchInProgress: boolean = false;
  public lookupSearchData:Array<LookupSearchResult>= [];

  constructor (
    public dynamicsClient : DynamicsClientService,
    public authService: AuthenticationService,
    private readonly device: DeviceService,
    public disk: DiskService,
    public caseManagementService: CaseManagementService
  ) {}
  
  public async getLookupResultsForCaseSurvey(searchParams, targetEntity) {
    let searchResult: LookupSearchResult[] = [];
    let entityAttribute = targetEntity.attributes;

    this.lookupSearchInProgress = true;

    await this.disk.retrieve(DB_KEY_PREFIXES.SURVEY_LOOKUP_ + targetEntity.name, true).then((doc)=>{
      if(doc && doc.raw){
        for(let r of doc.raw) {
          let data = new LookupSearchResult(r, entityAttribute.id, entityAttribute.name);
          if(data.name.includes(searchParams)) searchResult.push(data);
        }
        this.lookupSearchData = searchResult;
      }
      this.lookupSearchInProgress = false;
      return searchResult;
    },
    (err) => {
      console.log('Lookup search dynamics error', err);
      this.lookupSearchInProgress = false;
    })
  }

  public async getLookupExistingDataForCaseSurvey(searchParams, targetEntity) {
    let searchResult: LookupSearchResult[] = [];
    let entityAttribute = targetEntity.attributes;
    
    this.lookupSearchInProgress = true;
    let response;
    if(targetEntity.name == 'indskr_expertcategorytype') {
      response = this.caseManagementService.getCurrentCaseCategoryTypes();
    } else if (targetEntity.name == 'indskr_expertinquiryresponse') {
      response = this.caseManagementService.responsePreferenceList;
    }

    for (let r of response) {
      let data = new LookupSearchResult(r, 'id', 'name');
      if(data.id.includes(searchParams)) searchResult.push(data);
      this.lookupSearchData = searchResult;
      this.lookupSearchInProgress = false;
    }
    return searchResult;

  }
}

