import {DB_KEY_PREFIXES} from './../../config/pouch-db.config';
import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject} from 'rxjs';
import {XperiencesDataService} from "../../data-services/xperiences/xperiences.data.service";
import {AuthenticationService} from "../authentication.service";
import {FeatureActionsMap} from "../../classes/authentication/user.class";
import { takeUntil, map, first } from 'rxjs/operators';
import {
  GeneralInsight,
  OptionSet,
  Product,
  TrendingCustomer,
  User,
  XpCustomer,
  TrendingAccount,
  TrendingFeed
} from "../../classes/xperiences/trending.customer.class";
import {SelectListData} from "../../models/select-list-data-model";
import {TranslateService} from "@ngx-translate/core";
import {AppointmentActivity, OFFLINE_ID_PREFIX} from "../../classes/activity/appointment.activity.class";
import {Activity} from "../../classes/activity/activity.class";
import {Channel, ChannelActivityType, ChannelType, EmailActivityChannelDetails} from "../../classes/consent/channel.class";
import {addMinutes, format, isToday, isTomorrow, isYesterday} from "date-fns";
import {InitiateMeetingPayload, MeetingDataService} from "../../data-services/meeting/meeting.data.service";
import {UIService} from "../ui/ui.service";
import {EmailService} from "../email-templates/email.service";
import {ActivityDataService} from "../../data-services/activity/activity.service";
import {ActivityService} from "../activity/activity.service";
import {
  EmailActivity,
  EmailActivityParty,
  EmailAddress,
  EmailViewType
} from "../../classes/activity/email.activity.class";
import {Contact} from "../../classes/contact/contact.class";
import {NotificationService, ToastStyle} from "../notification/notification.service";
import {CreateSampleDropRequestBody, SampleActivity} from "../../classes/activity/sample.activity.class";
import {SampleService} from "../sample/sample.service";
import {SampleDataService} from "../../data-services/sample/sample.data.service";
import {TrackingEventNames, TrackService} from "../logging/tracking.service";
import {DiskService} from "../disk/disk.service";
import {DateTimeFormatsService} from '../date-time-formats/date-time-formats.service';
import { AlertService } from '../alert/alert.service';
import { NavigationService, PageName } from '../navigation/navigation.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { ConsentService } from '../consent/consent.service';
import { DirectMessagingService } from '../direct-messaging/direct-messaging.service';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { NewSampleActivityComponent } from '@omni/components/activity/new-sample-activity/new-sample-activity';

export enum INSIGHT_TYPE {
  CUSTOMER_INSIGHT = 1,
  GENERAL_INSIGHT = 2
}

export enum IMPACT_SEGMENT {
  CONTACT = 1,
  ACCOUNT = 2,
  FEED = 3,
  LOCATION = 4,
  SAVED_CONTACT = 5

}
@Injectable({
  providedIn: 'root'
})
export class XperiencesService {

  private xpCustomersSource = new BehaviorSubject<XpCustomer[]>([]);
  public xpCustomersObserver$ = this.xpCustomersSource.asObservable();
  private xpTrendingAccountsSource = new BehaviorSubject<TrendingAccount[]>([]);
  public xpTrendingAccountsObserver$ = this.xpTrendingAccountsSource.asObservable();
  private xpTrendingFeedsSource = new BehaviorSubject<TrendingFeed[]>([]);
  public xpTrendingFeedsObserver$ = this.xpTrendingFeedsSource.asObservable();

  public enableInsight: boolean = true;
  public newInsight: TrendingCustomer;
  public activityTypes: OptionSet[];
  private relationshipTypes: SelectListData[];
  private strengthList: OptionSet[];
  private _contactDetailsSegment: string;

  private interestCategoryOptions: SelectListData[];
  public selectedXpContact: XpCustomer;
  private thumbsDownReasonsOptionSet: OptionSet[] = [
    new OptionSet("NOT_RELEVANT_TOPIC_TO_CUSTOMER", this.translate.instant('XPERIENCES_TOPIC_IS_NOT_RELEVANT')),
    new OptionSet("NOT_RELEVANT_MEDICAL_TOPIC", this.translate.instant('XPERIENCES_CUSTOMER_NOT_INTERESTED_IN_TOPIC')),
    new OptionSet("OTHER", this.translate.instant('OTHER'))];

  public xpPlanFilterText: string = 'All';
  public xpInsightsFilterText: string = '';
  public xpInsightsFilterValue = 'All';
  public xpRelshipInsightsFilterValue = 'All';
  public trackActivityCreation: { activity: Activity, topicId: string };
  public marketingEmailFeedback: string;
  public marketingEmailFeedbackGiven = false;
  public customerType: string;
  private _isSyncing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly isSyncing: Observable<boolean> = this._isSyncing$.asObservable();
  public isFromCustomerXperience: boolean = false;
  public selectedTrendingAccount: TrendingAccount;
  public selectedTrendingFeed: TrendingFeed;
  public limitItems: number = 5;
  plansFilterPopoverData: { text: string; value: string; displayIcon?: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];

  journeyInsightsFilterValue = 'All';
  productFilterValue = 'All';
  activitiesFilterValueList: any[] = ["All"];

  selectedSortOption: string;
  relshipSelectedSortOption: string;

  public filterSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterObs$ = this.filterSubject.asObservable();

  public xpJourneyInsightsFilterText: string = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
  public xpInterestInsightsFilterText: string = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_INTEREST_INSIGHTS');
  public xpRelationshipInsightsFilterText: string = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS');
  private impacttabDataSource = new BehaviorSubject<string>('');
  public impactTabObserver = this.impacttabDataSource.asObservable();
  public generalInsight: GeneralInsight;
  public tempInsight: GeneralInsight;

  private impactDataSyncFailed: boolean = false;
  public segmentType: IMPACT_SEGMENT;

  constructor(
    private xperiencesDataService: XperiencesDataService,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private uiService: UIService,
    private meetingService: MeetingDataService,
    private emailService: EmailService,
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    private notificationService: NotificationService,
    public samplingService: SampleDataService,
    public samplingOfflineService: SampleService,
    private trackingService: TrackService,
    private diskService: DiskService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private alertService: AlertService,
    private navService: NavigationService,
    private consetService: ConsentService,
    private directMessagingService: DirectMessagingService
  ) {
    this.plansFilterPopoverData = this.filterOptions;
  }

   get filterOptions() {
    return [
      {
        text: "",
        value: "All",
        items: [{ text: this.translate.instant('XPERIENCES_HEADER_PLANS_AND_PREDICTIONS'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.xpPlanFilterText = item.value;
        }
      },
      {
        text: "",
        value: "",
        items: this.getFilterItems().map(b => ({ text: b.text, value: b.value, displayIcon: b.icon })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          // this.trackFilterSelection(selectedItem.value);
          this.xpPlanFilterText = item.value;
          if (this.xpPlanFilterText == '') {
            itemRef.parent.items[0].value = 'All';
            this.xpPlanFilterText = 'All';
          }
        }
      }
    ];
  }

  getFilterItems(): Array<{text: string, value: string, icon: string}> {
    return [
    { text: this.translate.instant('XPERIENCES_TEAM_PLANS'), value: "teamplan", icon: "assets/imgs/xp_teamplan.svg" },
    { text: this.translate.instant('XPERIENCES_CENTRAL_PLANS'), value: "centralplan", icon: "assets/imgs/xp_centralplan.svg" },
    { text: this.translate.instant('XPERIENCES_GENEE_PREDICTIONS'), value: "prediction", icon: "assets/imgs/xp_genee_active.svg" }];
  }

  setPlansFilterState() {
    if (this.xpPlanFilterText === 'All') {
      this.plansFilterPopoverData[1].value = '';
      this.plansFilterPopoverData[0].value = 'All';
    } else {
      this.plansFilterPopoverData[0].value = '';
      this.plansFilterPopoverData[1].value = this.xpPlanFilterText;
    }
  }

  public get contactDetailsSegment() : string {
    return this._contactDetailsSegment;
  }

  public set contactDetailsSegment(value: string) {
    this._contactDetailsSegment = value;
    if (value === 'journeys') {
      this.trackJourneyTabClick()
    }
    if (value === 'interests') {
      this.trackInterestTabClick();
    }
    if (value === 'relationships') {
      this.trackRelationshipTabClick();
    }
  }

  public async fetchXperiencesData(loadFromDbOnly = false) {
    this._isSyncing$.next(true);
    this.impactDataSyncFailed = false;
    this.activityTypes = [];
    this.strengthList = [];
    this.relationshipTypes = [];
    this.interestCategoryOptions = [];
    if (this.authService.hasFeatureAction(FeatureActionsMap.XPERIENCES)) {
      await this.fetchTrendingCustomers(loadFromDbOnly);
      await this.fetchOptionSets(loadFromDbOnly);
      await this.fetchInterestCategories(loadFromDbOnly);
    }
    await this.getTrendingAccountsData(loadFromDbOnly);
    await this.getTrendingFeedsData(loadFromDbOnly);
    if (this.impactDataSyncFailed) this.clearImpactData();
    this._isSyncing$.next(false);
  }

  public saveCustomer(customer: XpCustomer, save: string): Promise<any> {
    return this.xperiencesDataService.saveCustomer(customer, save);
  }

  public sendFeedback(contactid: string, feedback: string, topic: string, reason?: string): Promise<any> {
    return this.xperiencesDataService.sendFeedback(contactid, feedback, topic, reason);
  }

  private async fetchOptionSets(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      const dbData = await this.diskService.retrieve(DB_KEY_PREFIXES.XPERIENCE_OPTION_SETS, true);
      if (dbData && dbData.raw) {
        this.mapXpOptionSets(dbData.raw);
      }
    } else {
    await this.xperiencesDataService.fetchOptionSets().then(res => {
      if (res) {
        this.mapXpOptionSets(res);
        this.diskService.updateOrInsert(DB_KEY_PREFIXES.XPERIENCE_OPTION_SETS, doc => ({ raw: res }));
      }
    });
    }
  }

  private mapXpOptionSets(raw: any) {
    this.activityTypes = raw.activityTypes.map(at => {
      return new OptionSet(at.value.toString(), at.response);
    });
    this.relationshipTypes = raw.relationshipTypes.map(rt => {
      return { id: rt.value.toString(), title: rt.response, isSelected: false };
    });
    this.strengthList = raw.strengths.map(strength => {
      return new OptionSet(strength.value, strength.response.length.toString());
    });
  }

  private async fetchInterestCategories(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      const dbData = await this.diskService.retrieve(DB_KEY_PREFIXES.XPERIENCE_INTEREST, true);
      if (dbData && Array.isArray(dbData.raw)) {
        this.interestCategoryOptions = dbData.raw.map(cat => {
          return { id: cat.indskr_interestcategoryid, title: cat.indskr_name, isSelected: false };
        });
      }
    } else {
    await this.xperiencesDataService.fetchInterestCategories().then(res => {
      if (res) {
        this.interestCategoryOptions = res.map(cat => {
          return { id: cat.indskr_interestcategoryid, title: cat.indskr_name, isSelected: false };
        });
        this.diskService.updateOrInsert(DB_KEY_PREFIXES.XPERIENCE_INTEREST, doc => ({ raw: res }));
      }
    });
    }
  }

  private async fetchTrendingCustomers(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      const dbData = await this.diskService.retrieve(DB_KEY_PREFIXES.XPERIENCE_CUSTOMERS, true);
      let nextData = [];
      if (dbData && dbData.processed) {
        nextData = dbData.processed;
      }
      this.xpCustomersSource.next(nextData);
    } else {
    let eligbleActions: string[] = [];
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)
      && this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ORDER_ACTIVITY)) {
      eligbleActions.push("indskr_sampledrop");
    }
    /*
    if (this.authService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
      eligbleActions.push("medical_inquiry");
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)
      || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_FOLLOW_UP_TASK)) {
      eligbleActions.push("task");
    } */
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
      eligbleActions.push("email");
    }

    await this.xperiencesDataService.fetchXpCustomers(eligbleActions).then((data: XpCustomer[]) => {
      if (data && Array.isArray(data)) {
        data.forEach((customer) => {
          if (!customer.plans || !customer.plans.length) {
            customer.plans = [];
          }
          if (!customer.predictions || !customer.predictions.length) {
            customer.predictions = [];
          }
          if(customer.interests  && customer.interests.insights && customer.interests.insights.length === 0) {
            customer.interests = null;
          }
          if(customer.relationships && customer.relationships.insights && customer.relationships.insights.length === 0) {
            customer.relationships = null;
          }
          if(customer.journey && customer.journey.insights && customer.journey.insights.length === 0) {
            customer.journey = null;
          }
        });
        this.xpCustomersSource.next(data);
        this.diskService.updateOrInsert(DB_KEY_PREFIXES.XPERIENCE_CUSTOMERS, doc => ({ processed: data }));
      }
    }, err => {
      this.xpCustomersSource.next([]);
      this.impactDataSyncFailed = true;
      console.log("Error occurred while fetching trending customers", err);
    });
    }
  }

  public get activities() {
    return this.activityTypes;
  }

  public get strengths() {
    return this.strengthList;
  }

  public get relationships() {
    return this.relationshipTypes;
  }

  public get interestCategories() {
    return this.interestCategoryOptions;
  }

  public async createCustomerInsight(customer: TrendingCustomer) {
    let request: any = {
      "contactid": customer.contactid
    }
    if (customer.journeys && customer.journeys.length) {
      const journeys: any[] = customer.journeys.map(j => {
        let journey: any = {
          "indskr_description": j.description,
          "indskr_activitydate": j.date,
        }
        if(j.type) {

          if (this.activityTypes.length > 0) {
            const filteredTypes = this.activityTypes.filter(activityType => {
              return activityType.label.toLowerCase() === j.type.toLowerCase()
            });
            if (filteredTypes.length > 0) {
            journey["indskr_activitytype"] = filteredTypes[0].value;
            }
          }
        }
        if(j.confidence) {
          journey["indskr_strength"] = this.strengths.find(st => st.label === j.confidence.toString()).value;
        }
        if(j.selectedProduct) {
          if (j.selectedProduct.id == "indskr_competitorproduct") {
            journey.indskr_competitorproduct = j.indskr_competitorproduct;
          } else {
            journey.indskr_product = j.selectedProduct.id;
          }
        }
        return journey;
      });
      request.journeys = [...journeys];
    }
    if (customer.relationships && customer.relationships.length) {
      const relationships: any[] = customer.relationships.map(r => {
        let relationship: any = {
          "indskr_relatedcontact": r.contactid,
        }
        if(r.score) {
          relationship["indskr_strength"] = this.strengths.find(st => st.label === r.score.toString()).value;
        }
        const selectedRelationshipTypes = r.types.filter(rt => rt.isSelected);
        if(selectedRelationshipTypes.length > 0) {
          relationship["indskr_relationshiptype"]= selectedRelationshipTypes.map(rt => rt.id).join(",");
        }

        if (r.selectedSpeciality) relationship.indskr_specialty = r.selectedSpeciality.id;
        return relationship;
      });
      request.relationships = relationships;
    }
    if (customer.interests && customer.interests.length) {
      const interests: any[] = customer.interests.map(i => {
        let interest: any = {
          "indskr_description" : i.description
        };
        if(i.selectedCategory) {
          interest["indskr_interestcategory"] =  i.selectedCategory.id;
        }
        if(i.score) {
          interest["indskr_strengths"] = this.strengths.find(st => st.label === i.score.toString()).value;
        }
        return interest;
      });
      request.interests = interests;
    }
    return await this.xperiencesDataService.createCustomerInsight(request);
  }

  public get thumbsDownReasons() {
    return this.thumbsDownReasonsOptionSet;
  }

  public get unSavedChanges() {
    return this.newInsight && (this.newInsight.contactid || this.newInsight.journeys.length
      || this.newInsight.interests.length || this.newInsight.relationships.length);
  }

  public shouldSkipDiscardAlert(): boolean {
    if (this.isFromCustomerXperience) {
      if (this.newInsight.journeys.length === 0 &&
          this.newInsight.interests.length === 0 &&
          this.newInsight.relationships.length === 0) {
          return true;
        } else {
          return false;
        }
    } else {
        return false;
    }
  }

  public resetFilters() {
    this.xpPlanFilterText = "All";
    this.xpJourneyInsightsFilterText = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
    this.xpInterestInsightsFilterText = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_INTEREST_INSIGHTS');
    this.xpRelationshipInsightsFilterText = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS');
    this.journeyInsightsFilterValue = 'All';
    this.productFilterValue = 'All';
    this.activitiesFilterValueList = ["All"];
    this.xpInsightsFilterValue = 'All';
    this.xpRelshipInsightsFilterValue = 'All';
  }

  sendRelationshipInsightFeedback(contactId: string, relatedContactId: string, feedback: string): Promise<any> {
    return this.xperiencesDataService.sendRelationshipInsightFeedback(contactId, relatedContactId, feedback);
  }

  public async createMeeting(contacts: Contact[], date: any) {
    this.uiService.displayLoader();
    let startTime, endTime;
    startTime = new Date(date);
    endTime = new Date();
    endTime = addMinutes(startTime, 30);
    if (contacts && contacts.length > 0) {
      let subject: string = contacts[0].fullName ? `${contacts[0].fullName} - ` + this.translate.instant('MEETING') : this.translate.instant('MEETING');
      if (this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
        subject = contacts[0].fullName ? this.translate.instant('VISIT') + ` - ${contacts[0].fullName}` : this.translate.instant('VISIT');
      }
      let payload: InitiateMeetingPayload = new InitiateMeetingPayload(subject, "", startTime.getTime(), endTime.getTime(), "", OFFLINE_ID_PREFIX + new Date().getTime());
      let response = await this.meetingService.createNewMeeting(payload, true);
      this.activityService.selectedActivity = response;
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.activityService.selectedActivity.contacts = contacts;
        const requestSuccess = await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity);
        if (!requestSuccess) {
          await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity, false, true);
        }
      }
    } else {
      this.notificationService.notify(this.translate.instant('ERROR_FINDING_CONTACT_TO_ADD_TO_MEETING'), 'Xperiencer Service');
      return;
    }
    const currentChildNav = this.navService.getActiveChildNavViewPageName();
    if (!(currentChildNav === PageName.NothingSelectedView || currentChildNav === PageName.ImpactDetailsComponent || currentChildNav === PageName.MorePlansInsightsComponent)) {
      await this.navService.popChildNavPageWithPageTracking();
    }
    this.uiService.dismissLoader();
    this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
    this.uiService.activeView = "ActivitiesPageRightPaneNav";
    this.uiService.showCancelDoneOnActivityDetails = true;
    this.activityService.selectedActivity.isFromXperiences = true;
    this.activityDataService.activityDetailsLoaded = true;
    this.uiService.showRightPane = true;
    await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);

  }

  public async createEmail(date: any, channelId: string, channelType: ChannelType, contacts: Contact[]) {
    let scheduleTime = new Date(date).getTime().toString();
    const channels: EmailActivityChannelDetails[] = await this.directMessagingService.channelList.pipe(map(channels => channels.map(channel => (channel))), first()).toPromise();
    const channel = channels.find(ch => ch.id === channelId && ch.channelType === channelType);
    const request: any = {
      scheduledstart: scheduleTime,
      scheduledend: scheduleTime,
      subject: 'Message',
      statuscode: 1,
      channelType: channel.channelType,
      channelActivityType: channel.activityType,
      channelId: channel.id,
      emailAddresses: [],
      emailActivityParties: []
    };
    let emailParties: EmailActivityParty[] = [];
    let emailAddress: EmailAddress[] = [];
    let emailAddresses: string[] = [];
    contacts.forEach(contact => {
      contact.emailAddressList = contact.emailAddressList || [];
      for (let ea of contact.emailAddressList) {
        if (ea.isPrimary) {
          ea.isSelected = true;
          emailAddresses.push(ea.emailAddressId);
          emailAddress.push(new EmailAddress(ea.emailAddressId, ea.emailAddress));
        } else {
          ea.isSelected = false;
        }
      }
      if (emailAddress.length === 0 && contact.emailAddressList.length > 0) {
        let ea = contact.emailAddressList[0];
        ea.isSelected = true;
        emailAddresses.push(ea.emailAddressId);
        emailAddress.push(new EmailAddress(ea.emailAddressId, ea.emailAddress));
      }
      let emailParty: EmailActivityParty = this.getEmailPartyFromContact(contact);
      emailParty.emailAddresses = emailAddress;
      emailParties.push(emailParty);
      if (emailAddresses.length > 0) {
        request.emailAddresses = [...request.emailAddresses, ...emailAddresses];
      }
      if ( channel.channelType != ChannelType.EMAIL) {
        request.emailActivityParties = [...request.emailActivityParties, ...emailParties];
      }
    });
    let activity: EmailActivity = await this.emailService.createNewEmailActivity(request);
    if (activity.isSocialChannel) {
      await this.emailService.updateEmailActivityContactParties(emailParties,channel.channelType, activity.ID, channel.activityType);
    }
    this.emailService.updateEmailActivtyDetailsOnContactSelection(activity, emailParties);
    this.activityService.addActivity(activity);
    this.activityService.selectedActivity = activity;
    this.uiService.activeView = 'Email';
    activity.isFromXperiences = true;
    this.emailService.selectedActivity = activity;
    const currentChildNav = this.navService.getActiveChildNavViewPageName();
    if (!(currentChildNav === PageName.NothingSelectedView || currentChildNav === PageName.ImpactDetailsComponent || currentChildNav === PageName.MorePlansInsightsComponent)) {
      await this.navService.popChildNavPageWithPageTracking();
    }
    this.emailService.setCurrentEmail(this.emailService.selectedActivity);
    this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent);
    this.emailService.viewType = EmailViewType.FROM_XPERIENCES;
    this.uiService.showNewActivity = false;
    this.activityService.activityDetailsLoaded = true;
    this.emailService.setCurrentEmail(activity);
    this.uiService.showRightPane = true;
  }

  getEmailPartyFromContact(contact: Contact): EmailActivityParty {
    let emailParty: EmailActivityParty = new EmailActivityParty();
    emailParty.allowToRemove = true;
    emailParty.contact_firstname = contact.firstName;
    emailParty.contact_lastname = contact.lastName;
    emailParty.contact_mobilephone = contact.mobilePhone;
    emailParty.contact_indskr_facebookpsid = contact.indskr_facebookpsid;
    emailParty.indskr_contactid = contact.ID;
    return emailParty;
  }

  async createAllocationOrder(date: any, contact: Contact) {
    this.uiService.displayLoader();
    let start, end;
    start = format(new Date(date));
    end = format(addMinutes(start, 30));
    if (start) {
      let orderStartTime: any = new Date(start);
      let orderEndTime: any = new Date(end);
      let contactID = '', addressID = '', subject: string = 'Allocation Order';
      contactID = contact.ID;
      subject = 'Allocation Order with ' + contact.fullName;
      addressID = contact.addressesList && contact.addressesList.length == 1 ? contact.addressesList[0].customerAddressID : '';
      let sampleDropPayload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody({
        scheduledStart: orderStartTime,
        scheduledEnd: orderEndTime,
        offlineActivityId: 'offlineSampleOrder_' + new Date().getTime(),
        subject: subject,
        contactID: contactID,
        addressID: addressID
      });
      sampleDropPayload.indskr_ownerid = this.authService.user.systemUserID;
      try {
        let response = await this.samplingService.initiateSampleOrder(sampleDropPayload);
        if (response) {
          let data;
          if (response['activityId']) {
            data = { id: response.activityId, ...sampleDropPayload }
          } else {
            // Offline created meeting
            data = {
              id: response.offlineActivityId,
              subject: response.subject,
              location: response.location,
              scheduledStart: response.scheduledStart,
              scheduledEnd: response.scheduledEnd,
            };
          }
          if (data) {
            this.uiService.setUIServiceData({
              view: "new-activity", data,
            });
          } else {
            console.log('wrong activity creation response.. ', response);
          }
        }
        let activity: SampleActivity = response;
        activity.contactName = contact.fullName;
        this.activityService.selectedActivity = activity;
        activity.isFromXperiences = true;
        await this.activityService.addActivity(activity);
        this.activityService.activityDetailsLoaded = true;
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'Sample';
        const currentChildNav = this.navService.getActiveChildNavViewPageName();
        if (!(currentChildNav === PageName.NothingSelectedView || currentChildNav === PageName.ImpactDetailsComponent || currentChildNav === PageName.MorePlansInsightsComponent)) {
          await this.navService.popChildNavPageWithPageTracking();
        }
        this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
        this.uiService.dismissLoader();
        this.uiService.showRightPane = true;
      } catch (error) {
        this.uiService.dismissLoader();
        this.notificationService.notify(this.translate.instant("NEW_ACTIVITY_TOAST_ERROR_CREATING_NEW_ALLOWCATION_ORDER"), 'New Activity', 'top', ToastStyle.INFO);
      }
      // this.activityService.isActivityUpdate = true;
      this.activityService.publishActivityEvent({action: "Update", activity: this.activityService.selectedActivity});
    }
  }

  public getChannelType(channeltype: string): ChannelType {
    let channel: ChannelType;
    switch (channeltype) {
      case 'facebook':
        channel = ChannelType.FACEBOOK
        break;
      case 'whatsapp':
        channel = ChannelType.WHATSAPP
        break;
      case 'sms':
        channel = ChannelType.SMS
        break;
      case 'email':
        channel = ChannelType.EMAIL
        break;
      default:
        console.log(`${channeltype} is not implemented`);
        break;
    }
    return channel;
  }

  public async getMarketingEmails(id: string) {
    return await this.xperiencesDataService.getMarketingEmails(id);
  }

  public sendMarketingEmailFeedback(feedback: string, emailId?: string) {
    if (this.marketingEmailFeedbackGiven) {
      return;
    }
    this.marketingEmailFeedback = feedback;
    this.marketingEmailFeedbackGiven = true;
  }

  async createGeneralInsight(insight: GeneralInsight) {
    const request = {
      "indskr_name": insight.description,
      "indskr_date": insight.date
     };
     if(insight.selectedSpeciality && insight.selectedSpeciality.id) {
      request["indskr_specialty"] = insight.selectedSpeciality.id;
     }
     if(insight.selectedProduct && insight.selectedProduct.id) {
      if (insight.selectedProduct.id == "indskr_competitorproduct" && insight.indskr_competitorproduct) {
        request["indskr_otherproduct"] = insight.indskr_competitorproduct;
      } else {
        request["indskr_product"] =insight.selectedProduct.id;
      }
     }
     if(insight.confidence) {
      request["indskr_confidence"] = this.strengths.find(st => st.label === insight.confidence.toString()).value;
     }
     return await this.xperiencesDataService.createGeneralInsight(request);
  }

  public getUserNamesText(users: User[]): string {
    let userNamesText = '';

    let andText = this.translate.instant('AND_SMALL');
    if (users && users.length) {
      let  userCount = users.length;
      let firstUserName = '';
      let index = users.findIndex((user) => user.userid === this.authService.user.systemUserID);
      if (index >= 0) {
        firstUserName = this.translate.instant('ME');
      } else {
        firstUserName = users[0].username;
      }
      if(userCount === 2) {
        if(index >= 0) {
          userNamesText = index === 0 ? `${firstUserName} ${andText} ${users[1].username}` : `${firstUserName} ${andText} ${users[0].username}`;
        } else {
          userNamesText = `${firstUserName} ${andText} ${users[1].username}`;
        }
      } else {
        userNamesText = `${firstUserName} ${userCount > 2 ? andText + ' ' + (userCount - 1) + ' ' + this.translate.instant('OTHERS') : ''}`;
      }
    }
    return userNamesText;
  }

  public getProductsText(products: Product[]) {
    let productsText = '';
    if (products && products.length) {
      const productsCount = products.length;
      let firstProductName = products[0].productname;
      productsText = `${firstProductName}` + (products.length >= 2 ? (' +' + (productsCount - 1)) : '');
    }
    return productsText;
  }

  trackJourneyTabClick() {
    if(this.customerType == 'trending') {
      this.trackingService.tracking("XperiencesTrendingJourneyClicked", TrackingEventNames.XPERIENCES);
    } else if (this.customerType == 'saved') {
      this.trackingService.tracking("XperiencesSavedJourneyClicked", TrackingEventNames.XPERIENCES);
    } else if (this.customerType == 'inactive') {
      this.trackingService.tracking("XperiencesInactiveJourneyClicked", TrackingEventNames.XPERIENCES);
    }
  }

  trackInterestTabClick() {
    if(this.customerType == 'trending') {
      this.trackingService.tracking("XperiencesTrendingInterestClicked", TrackingEventNames.XPERIENCES);
    } else if (this.customerType == 'saved') {
      this.trackingService.tracking("XperiencesSavedInterestClicked", TrackingEventNames.XPERIENCES);
    } else if (this.customerType == 'inactive') {
      this.trackingService.tracking("XperiencesInactiveInterestClicked", TrackingEventNames.XPERIENCES);
    }
  }

  trackRelationshipTabClick() {
    if(this.customerType == 'trending') {
      this.trackingService.tracking("XperiencesTrendingRelationshipClicked", TrackingEventNames.XPERIENCES);
    } else if (this.customerType == 'saved') {
      this.trackingService.tracking("XperiencesSavedRelationshipClicked", TrackingEventNames.XPERIENCES);
    } else if (this.customerType == 'inactive') {
      this.trackingService.tracking("XperiencesInactiveRelationshipClicked", TrackingEventNames.XPERIENCES);

    }
  }

  formattedDate(date: number, dateOnly: boolean): string {
    const scheduleDay = isToday(date) ? this.translate.instant('TODAY') : isTomorrow(date) ? this.translate.instant('TOMORROW') : isYesterday(date) ? this.translate.instant('YESTERDAY') : format(date, this.dateTimeFormatsService.dateToUpper);
    const startDate: Date = new Date(date);
    if (dateOnly) return scheduleDay;
    const scheduleTime = startDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return scheduleDay + " " + scheduleTime;
  }

  resetViewToDefault(showRightPane: boolean = false) {
    this.activityService.selectedActivity = null;
    this.uiService.showRightPane = showRightPane;
    this.activityService.selectedActivityAtHome = null;
    if(this.navService.getActiveChildNavViewPageName() !== PageName.ImpactDetailsComponent) {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  public async getJourneyInsigthData(id: string) {
    return  this.xperiencesDataService.fetchJourneyInsigthData(id);
  }

  public async getInterestInsigthData(id: string) {
    return await this.xperiencesDataService.fetchInterestInsigthData(id);
  }

  public async getRelationshipInsigthData(id: string) {
    return await  this.xperiencesDataService.fetchRelationshipInsigthData(id);
  }

  public async getTrendingAccountsData(loadFromDbOnly = false) {
    if(this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_TRENDING_PILL)) {
      if (loadFromDbOnly) {
        const dbData = await this.diskService.retrieve(DB_KEY_PREFIXES.XPERIENCES_TRENDING_ACCOUNTS, true);
        let nextData = [];
        if (dbData && dbData.processed) {
          nextData = dbData.processed;
        }
        this.xpTrendingAccountsSource.next(nextData);
      } else {
        await this.xperiencesDataService.fetchTrendigAccountsData().then(async (accounts) => {
          this.xpTrendingAccountsSource.next(accounts);
          await this.diskService.updateOrInsert(DB_KEY_PREFIXES.XPERIENCES_TRENDING_ACCOUNTS, doc => ({ processed: accounts }));
        }).catch((error) => {
          this.xpTrendingAccountsSource.next([]);
          this.impactDataSyncFailed = true;
        });
      }
    } else {
      this.xpTrendingAccountsSource.next([]);
      await this.diskService.remove(DB_KEY_PREFIXES.XPERIENCES_TRENDING_ACCOUNTS);
    }
  }

  public async getTrendingFeedsData(loadFromDbOnly = false) {
    if(this.authService.hasFeatureAction(FeatureActionsMap.XPERIENCES_FEED)) {
      if (loadFromDbOnly) {
        const dbData = await this.diskService.retrieve(DB_KEY_PREFIXES.XPERIENCES_TRENDING_FEEDS, true);
        let nextData = [];
        if (dbData && dbData.processed) {
          nextData = dbData.processed;
        }
        this.xpTrendingFeedsSource.next(nextData);
      } else {
        await this.xperiencesDataService.fetchTrendingFeedsData().then(async (feeds) => {
          this.xpTrendingFeedsSource.next(feeds);
          await this.diskService.updateOrInsert(DB_KEY_PREFIXES.XPERIENCES_TRENDING_FEEDS, doc => ({ processed: feeds }));
        }).catch((error) => {
          this.xpTrendingFeedsSource.next([]);
          this.impactDataSyncFailed = true;
        });
      }
    }
    else {
      this.xpTrendingFeedsSource.next([]);
      await this.diskService.remove(DB_KEY_PREFIXES.XPERIENCES_TRENDING_FEEDS);
    }
  }

  sortObjs(data:any, sortBy:any): any[] {
    return data.sort((a, b) => a[sortBy] - b[sortBy]);
  }

  showMandatoryCompetitorProductNamePopover() {
    this.notificationService.notify(this.translate.instant('XPERIENCES_ENTER_COMPETITOR_PRODUCT_NAME'), 'Xperience_Insights_Plus', 'top', ToastStyle.DANGER);
  }

  impactTabChanged(selectedTab: string) {
    this.impacttabDataSource.next(selectedTab);
  }


  async discardChangesPopup() {
    return await this.alertService.showAlert({
      title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
      message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')}, this.translate.instant('DISCARD')
    );
  }

  async dicardChangesForXperiences() {
    // setting values to zero to avoid discard popup on customer click in xperience for the first time
    this.newInsight.contactid = '';
    this.newInsight.journeys.length = 0;
    this.newInsight.interests.length = 0;
    this.newInsight.relationships.length = 0;
    this.enableInsight = true;
    this.selectedXpContact = null;
    await this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent)
  }

  clearImpactData() {
    this.xpCustomersSource.next([]);
    this.xpTrendingAccountsSource.next([]);
    this.xpTrendingFeedsSource.next([]);
  }

}
