import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {IonTextarea} from "@ionic/angular";

/**
 * Generated class for the IndTextAreaComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-text-area',
  templateUrl: 'ind-text-area.html',
  styleUrls:['ind-text-area.scss']
})
export class IndTextAreaComponent {
  @Input() autoCapitalize = 'none';
  @Input() autoFocus = false;
  @Input() debounce = 0;
  @Input() disabled = false;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() inputMode?: string;
  @Input() placeHolder?: string;
  @Input() readonly = false;
  @Input() required = false;
  @Input() spellCheck = false;
  @Input() cols?: number;
  @Input() rows?: number;
  @Input() wrap?: string;
  @Input() autoGrow = false;
  @Input() value?: string;
  @Input() showAttachment?:boolean;
  @Input() hasDownload?:boolean = false;
  @Input() hasRemove?:boolean = true;
  @Input() attachmentTitle?:string;
  @Output() valueChange = new EventEmitter<string>();
  @Output() indFocus = new EventEmitter();
  @Output() indBlur = new EventEmitter();
  @Output() indChange = new EventEmitter();
  @Input() ws: number;
  @Output() removeAttachment = new EventEmitter();
  @ViewChild(IonTextarea, {static:false}) ionTextArea: IonTextarea;
  @Output() downloadNotesAttachment = new EventEmitter();

  constructor() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.ionTextArea) {
        this.ionTextArea.getInputElement().then((element) => {
          if (element.style.height == '0px' || !element.style.height) {
            element.style.height = 'auto';
            element.parentNode['style'].height = 'auto'
          }
        });
      }
    }, 50);
  }

  ontextChange(value) {
    this.value = value;
    this.valueChange.emit(value);
  }

  onFocus() {
    this.indFocus.emit();
  }

  onBlur() {
    this.indBlur.emit()
  }

  onInput(value) {
    this.indChange.emit(value);
  }

  deleteAttachment(event){
    this.removeAttachment.emit(event);
  }
  downloadAttachment(event) {
    this.downloadNotesAttachment.emit(event)
  }
}
