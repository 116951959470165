import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentCard, ShortCallContent } from '@omni/classes/short-call/short-call.class';

@Component({
  selector: 'io-content-cards',
  templateUrl: './io-content-cards.component.html',
  styleUrls: ['./io-content-cards.component.scss']
})
export class IoContentCardsComponent {

  @Input() contentData: ShortCallContent;
  @Output() selectedContent = new EventEmitter<ContentCard>();

  constructor() {

  }

  public onContentSelect(card: ContentCard) {
    this.selectedContent.emit(card);
  }


}
