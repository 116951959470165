import { IonNav, NavParams } from '@ionic/angular';
import { Component, ViewChild } from '@angular/core';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { FooterService } from '../../../services/footer/footer.service';
import { Events } from '@omni/events';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';

/**
 * Generated class for the EventsToolPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'events-tool-page[base-page]',
  templateUrl: 'events-tool-page.html',
  styleUrls:['events-tool-page.scss']
})
export class EventsToolPageComponent {

  @ViewChild("eventtoolsrightpane", {static:true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    private navParams: NavParams,
    private footerService: FooterService,
    private events: Events,
    private eventsToolService: EventsToolService,
  ) {
  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl,ChildNavNames.EventsToolNavigation,PageName.EventsToolPageComponent);
    this.footerService.initButtons('');
    this.eventsToolService.setEventTypeNeededForParticipantsMandatory();
    if(this.navParams.data) {
      if (this.navParams.data.isOpenEventFromGenee) {
        const isOpenEventFromGenee = this.navParams.data.isOpenEventFromGenee;
        if (isOpenEventFromGenee) {
          const eventId = this.navParams.data.eventId;
          const requestParams = {'eventId': eventId};
          setTimeout(() => {
            this.events.publish('open-event-from-genee', requestParams);
          }, 30);
        }
      }
    }
  }

  ngOnDestroy(){
    this.navService.popChildNavCtrlFromStack(ChildNavNames.EventsToolNavigation);
  }

}
