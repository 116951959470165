import { SignaturePad } from '../../shared/signaturepad/angular2-signaturepad.component';
import { PopoverController } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { NavParams, LoadingController } from '@ionic/angular';
import { AlertService } from '../../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { DeviceService } from '../../../services/device/device.service';
import { CaseManagementService } from '../../../services/case-management/case-management.service';
import { EventsService } from '../../../services/events/events.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { CaseManagementDataService } from '../../../data-services/case-management/case-management.data.service';

/**
 * Generated class for the InquirySignatureModalComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'inquiry-signature-modal',
  templateUrl: 'inquiry-signature-modal.html',
  styleUrls:['inquiry-signature-modal.scss']
})
export class InquirySignatureModalComponent {

  @ViewChild('signaturePad', {static:true}) signaturePad: SignaturePad;
  disableClear = true;
  public submitEnable = true;
  public signaturePadOptions = {
    minWidth: 0.5,
    canvasWidth: 300,
    canvasHeight: 150
  };
  selectedCase: CaseActivity;
  public offlineFAEnabled = false;
  globalCustomerText: any;
  capturedSignature: string;


  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly alertService: AlertService,
    public readonly translate: TranslateService,
    public params: NavParams,
    private readonly authenticationService: AuthenticationService,
    public device: DeviceService,
    public caseManagementService: CaseManagementService,
    private readonly events: EventsService,
    public notificationService: NotificationService,
    public utilityService: GlobalUtilityService,
    private readonly loadingController: LoadingController,
    public caseManagementDataService: CaseManagementDataService,
    public popoverCtrl: PopoverController
    ) {
      if(!this.device.isMobileDevicePortrait) {
        this.signaturePadOptions.canvasWidth = this._calculateWidth();
      }

      this.disableClear = true;
      this.selectedCase = params.get('selectedCase');
      switch (this.utilityService.globalCustomerText) {
        case 'Customer':
          this.globalCustomerText = this.translate.instant('CUSTOMER');
          break;
        case 'Stakeholder':
          this.globalCustomerText = this.translate.instant('STAKEHOLDER');
          break;
        default:
          this.globalCustomerText = this.utilityService.globalCustomerText;
          break;
        }
  }

  private _calculateWidth(): number {
    let targetWidth: number = window.innerWidth * 0.8;
    return targetWidth;
  }

  ionViewDidEnter(){

  }

  clearDraw(){
    this.disableClear = true;
    this.signaturePad.clear();
    this.submitEnable = true;
    this._cd.markForCheck();
    this._cd.detectChanges();
  }

  drawStart(){
    this.disableClear = false;
  }

  closePopover(){
    this.popoverCtrl.dismiss({submit: false});
  }

  drawComplete(){
    this.submitEnable = false;
    this._cd.markForCheck();
    this._cd.detectChanges();
  }

  openConfirmationPopup() {
    this.alertService.showAlert({
      title: this.translate.instant('SUBMIT_INQUIRY'),
      message: this.translate.instant('SUBMIT_INQUIRY_CONFIRMATION')}, this.translate.instant('SUBMIT')
    ).then (res => {
      if(res.role === 'ok') {
        this.submitDraw();
      }
    });
  }

  async submitDraw(){
    this.capturedSignature = this.signaturePad.toDataURL();
    this.selectedCase.signature = this.capturedSignature;
    if (
      this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_INQUIRY_OFFLINE)
    ) {
      this.offlineFAEnabled = true;
    }
    if (this.device.isOffline) {
      if (this.offlineFAEnabled) {
        //Generate case in offline mode

        const res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
        this.caseManagementService.assignSelectedCase(res);
        this.selectedCase = res;
        this.popoverCtrl.dismiss({submit: true});
        this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
        if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID ||
          this.selectedCase.indskr_phonecallid)
          this.events.publish('updateEmbeddedActivity', this.selectedCase);
      } else {
        this.popoverCtrl.dismiss({submit: true});
        this.notificationService.notify(
          this.translate.instant('CASE_CU_INQ_SUB_ALLOW_ONLY_ONLINE_WITH_TEXT', { text: this.globalCustomerText }),
          'Customer Inquiry',
          'top',
          ToastStyle.DANGER
        );
      }
    }
    else {
      const loader = await this.loadingController.create();
      loader.present();
      await this.caseManagementDataService.createCaseOnline(this.selectedCase).then(
        async value => {
          if (!value.hasOwnProperty('error')) {
            await this.caseManagementService.addNewCase(value, this.selectedCase).then(
              res => {
                this.caseManagementService.assignSelectedCase(res);
                this.selectedCase = res;
                this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
                if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID ||
                  this.selectedCase.indskr_phonecallid)
                  this.events.publish('updateEmbeddedActivity', this.selectedCase);
                loader.dismiss();
                this.popoverCtrl.dismiss({submit: true});
              },
              async err => {
                loader.dismiss();
                this.popoverCtrl.dismiss({submit: true});
              }
            );
          }
          else {
            loader.dismiss();
            this.popoverCtrl.dismiss({submit: true});
            const res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
            this.caseManagementService.assignSelectedCase(res);
            this.selectedCase = res;
            this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
            if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
              this.events.publish('updateEmbeddedActivity', this.selectedCase);
          }
        }
      ).catch(
        async err => {
          loader.dismiss();
          this.popoverCtrl.dismiss({submit: true});
          const res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
          this.caseManagementService.assignSelectedCase(res);
          this.selectedCase = res;
          this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
          if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
            this.events.publish('updateEmbeddedActivity', this.selectedCase);
        });
    }
  }


}
