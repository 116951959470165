
import { ResourceManagerState, INITIAL_RESOURCE_MANAGER_STATE } from '../states/resource.state';
import { Action } from '@ngrx/store';
import {
    DOWNLOAD_RESOURCE,
    DOWNLOAD_RESOURCE_SUCCESS,
    DOWNLOAD_RESOURCE_FAILURE,
    UPDATE_RESOURCE_RECORD,
    downloadResource,
    downloadResourceSuccess,
    downloadResourceFailed,
    updateResourceRecord,
    downloadResourceDequeue,
    downloadResourceEnqueue,
    deleteAlldownloadedResources,
    RESOURCE_DOWNLOAD_ENQUEUE,
    RESOURCE_DOWNLOAD_DEQUEUE,
    DELETE_ALL_DOWNLOADED_RESOURCES

} from '../actions/resource.actions';


export function resourceManagerState(state: ResourceManagerState = INITIAL_RESOURCE_MANAGER_STATE, action: Action): ResourceManagerState {

    switch (action.type) {

        case DOWNLOAD_RESOURCE:
            return handleDownloadResourceAction(state, <downloadResource>action);

        case DOWNLOAD_RESOURCE_SUCCESS:
            return handleDownloadResourceSuccessAction(state, <downloadResourceSuccess>action);

        case DOWNLOAD_RESOURCE_FAILURE:
            return handleDownloadResourceFailedAction(state, <downloadResourceFailed>action);

       case UPDATE_RESOURCE_RECORD:
            return handleUpdateResourceRecordAction(state, <updateResourceRecord>action);

        case RESOURCE_DOWNLOAD_ENQUEUE:
            return handleDownloadResourceEnqueueAction(state, <downloadResourceEnqueue>action);

        case RESOURCE_DOWNLOAD_DEQUEUE:
            return handleDownloadResourceDequeueAction(state, <downloadResourceDequeue>action);

        case DELETE_ALL_DOWNLOADED_RESOURCES:
            return state;

        default:
            return state;
    }
}

function handleDownloadResourceEnqueueAction(state: ResourceManagerState, action: downloadResourceEnqueue) {
    const newState = {
      ...state,
      downloadQueue: state.downloadQueue.slice() || []
    }
    if (newState.downloadQueue.indexOf(action.payload.resourceId) === -1) {
        newState.downloadQueue.push(action.payload.resourceId);
    }
    return newState;
}

function handleDownloadResourceDequeueAction(state: ResourceManagerState, action: downloadResourceDequeue) {
  const newState = {
    ...state,
    downloadQueue: state.downloadQueue.slice() || []
  }
  newState.downloadQueue.shift();
    newState.isDownloading = false;
    return newState;
}

function handleDownloadResourceAction(state: ResourceManagerState, action: downloadResource) {
    const newState = Object.assign({}, state);
    newState.resourceId = action.payload.resourceId;
    newState.errorInfo = undefined;
    newState.isDownloading = true;
    return newState;
}

function handleDownloadResourceSuccessAction(state: ResourceManagerState, action: downloadResourceSuccess) {
    const newState = Object.assign({}, state);
    newState.resourceId = action.payload.resourceId;
    newState.errorInfo = undefined;
    newState.isDownloading = false;
    return newState;
}

function handleDownloadResourceFailedAction(state: ResourceManagerState, action: downloadResourceFailed) {
    const newState = Object.assign({}, state);
    newState.errorInfo = action.payload.resourceId;
    newState.isDownloading = false;
    newState.resourceId = undefined;
    return newState;
}

function handleUpdateResourceRecordAction(state: ResourceManagerState, action: updateResourceRecord) {
    const newState = Object.assign({}, state);
    newState.resourceId = action.payload.resourceId;
    newState.errorInfo = undefined;
    newState.isDownloading = false;
    return newState;
}
