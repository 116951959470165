<ion-header>
    <ind-page-title [viewData]='pageTitle' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ion-list class='details-list' *ngIf="currentFollowUpActivity">
        <ion-item-group>
            <ind-section-header [viewData]="taskDetailsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
            <!-- <ion-item-divider color="light" class="list-title">
                {{'DETAILS_CAP' | translate}} -->
            <!-- <span slot="end" class="ion-float-right ion-margin-start" class='divider-text' [ngClass]="(!isScrapEnabled)?'closed-status':'open-status'" (click)="scrapFollowup()">{{'SCRAP' | translate}}</span>

                <span slot="end" class="ion-float-right ion-margin-start" class='divider-text' [ngClass]="(!isMarkCompleteEnabled)?'closed-status':'open-status'" (click)="markFollowUpComplete()">{{'MARK_COMPLETE' | translate}}</span> -->
            <!-- </ion-item-divider> -->
            <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>
            <ind-form-field [viewData]="getSubStatusFormField()"></ind-form-field>
            <!-- <ind-form-field [viewData]="getReasonForCancellationFormField()"></ind-form-field> -->
            <ind-form-field [viewData]="getSubjectFormField()"></ind-form-field>
            <ind-datetime-form [viewData]="getDueDateField()"></ind-datetime-form>
            <ind-form-field [viewData]="getCreatedByFormField()"></ind-form-field>
            <ind-form-field [viewData]="getActionTypeFormField()"></ind-form-field>
            <ind-form-field [viewData]="getPriorityFormField()"></ind-form-field>
            <ind-form-field [viewData]="getMeetingFormField()"></ind-form-field>
            <ind-form-field [viewData]="getEventFormField()"></ind-form-field>
            <ind-form-field [viewData]="getAssignedToFormField()"></ind-form-field>
            <ind-form-field [viewData]="getProductFormField()"></ind-form-field>
            <ind-form-field [viewData]="getCustomerFormField()"></ind-form-field>
            <ind-form-field [viewData]="getAccountFormField()"></ind-form-field>
            <ind-form-field [viewData]="getAccountPlanFormField()"></ind-form-field>
            <ind-form-field [viewData]="getMarketingPlanFormField()"></ind-form-field>
            <ind-form-field [viewData]="getOpportunityFormField()"></ind-form-field>
            <ind-form-field [viewData]="getReasonForCancellationFormField()"></ind-form-field>

            <ng-container *ngIf="isFollowUpObjectiveSelectionFAEnabled">
              <ind-section-header  [viewData]="objectiveSectionHeader"
                (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
              <div [ngClass]="{'events-container':!currentFollowUpActivity['objectives']?.length}" lines="none">
                <ion-item *ngFor=" let objective of currentFollowUpActivity['objectives']">
                  <ion-label text-wrap>{{ objective.followUpObjectiveName}}</ion-label>
                </ion-item>
              </div>
            </ng-container>

    <ng-container *ngIf="isFollowUpNotesFAEnabled">
      <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>
      <!-- <ind-text-area id="notesInput" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000" [(value)]="tempNoteText" (indChange)="notesChanged()" (indFocus)="onNotesFocus()" (indBlur)="onNotesBlur()"
              [rows]="4" [cols]="10" (removeAttachment)="removeAttachment($event)" [readonly]="!canAddNotes"></ind-text-area> -->
    
    
    
      <ind-text-area id="notesInput" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded"
        [attachmentTitle]="attachmentTitle" [maxLength]="1000" (indChange)="notesChanged($event)" [value]="tempNoteText"
        (removeAttachment)="removeAttachment($event)" [rows]="4" [cols]="10"
        [readonly]="backgroundUploadInProgress || !canAddNotes"> </ind-text-area>
    
      <!-- <input class="hide-input" type="file" (change)="loadImageFromDevice($event)" id="followup-file-input" accept=".doc,.docx,image/*,.pdf,.txt"> -->
    
      <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)" id="followup-file-input">
    
      <io-note *ngFor="let note of nonDeletedNotes"
        [isNoteAttachmentEnabled]="!backgroundUploadInProgress && isNotesAttachmentEnabled" [inputNote]="note"
        [editable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)"
        [readOnlyNote]="backgroundUploadInProgress || !isNoteControlsEnabled(note)"
        [readOnlyAttachment]="backgroundUploadInProgress || !isNotesAttachmentEnabled "
        [deletable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)" (saveNoteOut)="updateNote($event)"
        [disableDownloadInOffline]="true" [from]="UIService.page.FollowUpActionDetailComponent"></io-note>
    </ng-container>
        

            <ind-section-header [viewData]="timeLineHeaderModel"></ind-section-header>
            <div class="timelineWrapper">
                <ng-container *ngIf="timelineData.length === 0">
                    <div class="no-data-message">
                        {{'NO_TIMELINE' | translate}}
                    </div>
                </ng-container>
                <div class="containerForData" *ngIf="timelineData.length > 0">
                    <ion-item-group class="timeline-group" *ngFor="let group of timelineData">
                        <ion-item-divider sticky='true' [id]="group.key">
                            <div class="dividerBorder"></div>
                            <div class="monthGroupName">{{group.key}}</div>
                        </ion-item-divider>
                        <ion-item *ngFor="let history of group.list">
                            <ion-grid>
                                <ion-row>
                                    <ion-col size="3">
                                        <div class="ion-float-center" size="3">
                                            <h3 class="ion-text-center">{{history.day}}</h3>
                                            <h4 class="ion-text-center time-label">{{history.formmattedDate}}</h4>
                                        </div>
                                    </ion-col>
                                    <ion-col size="8">
                                        <div class="col-9 ion-float-left ion-margin-right" size="8">
                                            <h3>
                                                <div>{{history.text}}</div>
                                            </h3>
                                            <h4>
                                                <div>{{history.secondaryText}}</div>
                                            </h4>
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-item>
                    </ion-item-group>
                </div>
            </div>
        </ion-item-group>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('followuptask')" [footerView]="'detailView'" [selectedView]="'follow-up'"></footer-toolbar>
<!-- </ion-footer> -->