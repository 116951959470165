import { Injectable } from "@angular/core";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { HttpClient } from "@angular/common/http";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { OffTakeCheck, OffTakeCheckCollection } from "@omni/classes/offtake-check/offtake-check.class";
import { Endpoints } from "src/config/endpoints.config";
import { AuthenticationService } from "@omni/services/authentication.service";
import { format } from "date-fns";
import _ from "lodash";
import { Guid } from "typescript-guid";

@Injectable({
  providedIn: 'root'
})
export class OffTakeCheckDataService {

  constructor(
    private http: HttpClient,
    private dynamics: DynamicsClientService,
    private authService: AuthenticationService
  ) {
  }

  public async fetchOfftakeCheckProducts(): Promise<OffTakeCheckCollection[]> {
    let offTakeCheckCollections: OffTakeCheckCollection[] = [];
    let fetchXML = fetchQueries.offtakeCheck.offtakeProducts;
    await this.dynamics.executeFetchQuery('indskr_businessunitproducts', fetchXML).then((response: any) => {
      if (response) {
        offTakeCheckCollections.push(...response.map(prod => {
          return new OffTakeCheckCollection(null,
            prod['productId'],
            prod['productName'],
            prod['skuId'],
            prod['skuName'],
            prod['skuNum'],
            null,
            null,
            null
          )
        }));
        offTakeCheckCollections = _.sortBy(offTakeCheckCollections, ['productName', 'skuName']);
      }
    }).catch((err) => {
      console.error('Failed to fetchOfftakeCheckProducts', err);
    });
    return offTakeCheckCollections;
  }

  public async saveOffTakeCollections(offTakeCheck: OffTakeCheck) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.offtakeCheck.SAVE_OFFTAKE;
    return await this.http.put(url, offTakeCheck.requestPayload).toPromise();
  }

  public async fetchOffTakeChecks(startDate: Date, endDate: Date, accountId: string, onlyTopRecord: boolean = false): Promise<OffTakeCheck[]> {
    const offTakeCheckResp: OffTakeCheck[] = [];
    let fetchXML = fetchQueries.offtakeCheck.offTakeCheckCollections
      .replace('{startDate}', format(startDate, 'YYYY-MM-DD'))
      .replace('{endDate}', format(endDate, 'YYYY-MM-DD'))
      .replace('{accountId}', accountId)
      .replace('{topPlaceholder}', onlyTopRecord ? 'top="1"' : '');

    await this.dynamics.executeFetchQuery('indskr_offtakechecks', fetchXML).then((response: any) => {
      if (response) {
        _.values(_.groupBy(response, 'indskr_offtakedate'))
          .forEach(resp => {
            resp = _.sortBy(resp, 'modifiedon')
            const offtake = new OffTakeCheck(resp[0]['indskr_offtakedate'],
              resp[0]['_modifiedby_value_Formatted'],
              resp[0]['modifiedon'],
              resp[0]['accountId'],
              resp[0]['indskr_offtakechecktype'],
              []
            );
            offtake.collections = resp.map(collection => {
              return new OffTakeCheckCollection(collection['indskr_offtakecheckid'],
                collection['productId'],
                collection['productId@OData.Community.Display.V1.FormattedValue'],
                collection['skuId'],
                collection['skuId@OData.Community.Display.V1.FormattedValue'],
                collection['indskr_skucode'],
                collection['indskr_distributedornot'],
                collection['indskr_inventoryofftake'],
                collection['indskr_storeinventory']);
            });
            offtake.collections = _.sortBy(offtake.collections, ['productName', 'skuName']);
            offTakeCheckResp.push(offtake);
          })
      }
    }).catch((err) => {
      console.error('Failed to fetchOffTakeChecks', err);
    });
    return offTakeCheckResp;
  }
}