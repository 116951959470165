import { AssetSelectionViewDataModel } from './../../../models/meeting-assets-selection-model';
import { IonNav, NavParams } from '@ionic/angular';
import { Component, ViewChild, Input } from '@angular/core';
import { Events } from "@omni/events";
import {
  ChildNavNames,
  NavigationService,
  PageName,
} from '@omni/services/navigation/navigation.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { UIService, ComponentViewMode } from "@omni/services/ui/ui.service";
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 * Generated class for the FieldMaterialManagementPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'field-material-management-page[base-page]',
  templateUrl: 'field-material-management-page.html',
  styleUrls: ['field-material-management-page.scss'],
})
export class FieldMaterialManagementPageComponent {
  @ViewChild('fieldmaterialrightpane', { static: true }) public navCtrl: IonNav;

  @Input() viewData: AssetSelectionViewDataModel = {viewMode: ComponentViewMode.LISTVIEW};

  constructor(
    public navService: NavigationService,
    private readonly events: Events,
    private readonly footerService: FooterService,
    private uiService: UIService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
    private navParams: NavParams,
    private tCtrl : IonNav
  ) {}

  ngOnInit() {
    this.navService.initRightPaneChildNav(
      this.navCtrl,
      ChildNavNames.FieldMaterialManagementNavigation,
      PageName.FieldMaterialManagementPageComponent
    );
    this.footerService.initButtons('');
  }

  ngOnDestroy(){
    this.navService.popChildNavCtrlFromStack(
      ChildNavNames.FieldMaterialManagementNavigation
    );
  }

  onCloseModal = (event) => {
    this.uiService.showNewActivity = false;
    // this.uiService.activeView = this.uiService.prevView;

    if (this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingProcedurePushedAccountSelectionView') {
     
      this.tCtrl.pop({ progressAnimation: false }).then(() => {
        // this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        // this.uiService.activeView = this.uiService.prevView;
        // this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        // if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
          // this.accountService.accessedAccountListFrom = PageName.ToolsDrawer
        // }
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
      });
    }
    else if(this.navService.getPreviousPageName() == PageName.CustomerCallPlanPage){
      this.navService.popWithPageTracking().then(a=>{
        if(this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent){
          this.footerService.initButtons(FooterViews.Activities)
        }
      });
    }else{
      this.navService.popWithPageTracking().then(() => {
        if (
          this.navService.getActiveChildNavViewPageName() ===
            PageName.NothingSelectedView &&
          this.uiService.showRightPane
        ) {
          this.uiService.showRightPane = false;
        }

        // Short call feature requires to bring the tab back to short call home
        if (this.faService.isShortCallLauncherEnabled) {
          this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
          this.uiService.setAgendaTab('shortCallHome');
        }
        if(this.navService.getPreviousPageName() == PageName.FieldMaterialManagementPageComponent){
          this.events.publish("tools-back-clicked", PageName.FieldMaterialManagementPageComponent);
        }
      });
    }
    
  };
}
