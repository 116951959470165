<ion-header>
  <ind-page-title [viewData]="newManageListTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item-group class="toolActivtiesList">
      <ion-item (click)="selectPeriod('currentListPeriod')" [ngClass]="{'greyedOut': territoryService.isCurrentCustomerListPeriodExists()}">
          {{'CURRENT_PERIOD' | translate}}
          <ion-icon name="checkmark" color="primary" *ngIf="selectedListPeriod === 'currentListPeriod'" slot="end">
          </ion-icon>
      </ion-item>
      <ion-item (click)="selectPeriod('nextListPeriod')"  [ngClass]="{'greyedOut': territoryService.isNextCustomerListPeriodExists()}">
        {{'NEXT_PERIOD' | translate}}
        <ion-icon name="checkmark" color="primary" *ngIf="selectedListPeriod === 'nextListPeriod'" slot="end">
        </ion-icon>
    </ion-item>
      </ion-item-group>
  </ion-list>
</ion-content>