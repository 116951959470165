import { Pipe, PipeTransform } from '@angular/core';
import { SampleService } from '../services/sample/sample.service';
import { format } from 'date-fns';
import { SampleOrderActivityMetas, SampleAllocationMetas } from '../classes/sample/sample-allocation.class';
import { SampleActivity } from '../classes/activity/sample.activity.class';

const MIN_SEARCH_LENGTH = 3;

@Pipe({
  name: 'allocationOrderFilter',
  pure: true
})
export class AllocationOrderFilter implements PipeTransform {

    constructor(
        private sampleService: SampleService,
    ) {}


    /**
     *  This is the pipe for filtering Allocation Orders history using search string
     *
     * @returns
     * @memberof AllocationOrderFilter
     */
    transform(items: Array<SampleActivity>
    ){
        let sortedItems:Array<SampleActivity> = items.sort((a, b) => (b.startDate.getTime()) - (a.startDate.getTime()));
        if(this.sampleService.allocationOrdersSearchStr.length >= MIN_SEARCH_LENGTH){
            sortedItems = sortedItems.filter(sampleDrop=>{
                let str:string = sampleDrop.subject.slice() + sampleDrop.contactName.slice() + format(new Date(sampleDrop.scheduledStart),'MMM DD');
                if(sampleDrop.samples && sampleDrop.samples.length > 0){
                    sampleDrop.samples.forEach(sampleProd => {
                        str += sampleProd.indskr_skuname.slice();
                    });
                }
                str = str.toUpperCase();
                let flag = str.includes(this.sampleService.allocationOrdersSearchStr.toUpperCase());
                return flag;
            });
        }

        if(this.sampleService.multiProductSelectionFilter && this.sampleService.multiProductSelectionFilter.length > 0){
            sortedItems = sortedItems.filter(sampleDrop=>{
                let flag:boolean = false;
                if(sampleDrop.samples && sampleDrop.samples.length > 0){
                    this.sampleService.multiProductSelectionFilter.forEach(selProduct => {
                        let idx = sampleDrop.samples.findIndex(sampleProd => sampleProd.indskr_skuid == selProduct);
                        if(idx >= 0){
                            if(!flag){
                                flag = true;
                            }
                        }
                    });
                }
                return flag;
            });
        }
        return sortedItems;
    }
 
}