import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { Injectable } from "@angular/core";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { AuthenticationService } from "../../services/authentication.service";
import { DiskService } from "../../services/disk/disk.service";
import { fetchQueries } from "../../config/dynamics-fetchQueries";

@Injectable({
  providedIn:'root'
})
export class ExternallinksDataService{
    public rawHyperlinkList;
    constructor(
        public dynamics: DynamicsClientService,
        public authService: AuthenticationService,
        public disk: DiskService,
    ) {

    }

    public async syncHyperlinkData(forceFullSync:boolean = false, loadFromDBOnly:boolean = false){
        try{
            await this.getHyperlinkList(forceFullSync, loadFromDBOnly);
        } catch (error){
            console.log(error);
        }
    }

    private async getHyperlinkList(fullSync?: boolean, loadFromDBOnly = false){
        if(loadFromDBOnly){
            await this.loadOfflineHyperlinkList();
        } else {
            try {
              let fetchXML = fetchQueries.hyperlinkFetchXMLs.fetchConfiguration;
              fetchXML = fetchXML.replace('{0}', this.authService.user.systemUserID);
              fetchXML = fetchXML.replace('{BUID}', this.authService.user.xBusinessUnitId);
                await this.dynamics.executeFetchQuery('indskr_hyperlinks',fetchXML).then(async (res)=>{
                 console.log('Hyperlink list: ', res)
                 this.rawHyperlinkList = res;
                 await this.disk.updateOrInsert(DB_KEY_PREFIXES.HYPERLINK_LIST, (doc) => {
                    doc = {
                        raw: []
                    };
                    doc.raw = res;
                    return doc;
                    })
                }), async (error)=>{
                    this.loadOfflineHyperlinkList();
                }
            } catch (error){
                console.log('Error occured while fetching Hyperlink list: ' + error);
            }
        }
    }

    private async loadOfflineHyperlinkList() {
        await this.disk.retrieve(DB_KEY_PREFIXES.HYPERLINK_LIST, true).then((doc) => {
            if(doc && doc.raw){
              this.rawHyperlinkList = doc.raw;
            } else {
              this.rawHyperlinkList = [];
            }
        });
    }

}
