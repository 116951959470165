import { ActivitySource } from '@omni/services/activity/activity.service';

export interface NewActivityDataModel {
  title: string;
  displayOptions: {id: string, formattedLabel: string, isChecked: boolean, isDisabled?: boolean}[];
  eventHandler?:(data)=>void;
  source: ActivitySource;
}

export enum FormFieldType {
    POPOVER_SELECT = 'popover-select',
    NEW_PAGE_SELECT = 'new-page-select',
    INLINE_INPUT = 'inline-input',
    DATA_SUBMITTED = 'data-submitted',
    OPTIONS_NOT_AVAILABLE = 'options-not-available'
}
