import { MarketScanCompetitor, MarketScanDetailsScreenMatrixModel, MarketScanMatrixModel, MeasureCaptureFor } from './../../classes/market-scan/market-scan.class';
import { Injectable } from "@angular/core";
import _, { cloneDeep, isInteger, map, values } from "lodash";
import { BehaviorSubject } from "rxjs";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { Brand } from "../../classes/brand/brand.class";
import {
  Action,
  CustomerSegmentation,
  FilterType,
  MarketScan,
  MarketScanContact,
  MarketScanCustomerSegment,
  MarketScanProduct,
  MarketScanStateCode,
  MarketScanStatusCode,
  MarketScanTA,
  MarketScanCustomerData,
  Category,
  Measure,
  CreateFrequency,
  SubRowData,
  MeasureType,
  CaptureData,
  CustomerJourney,
  Event,
  AffiliatedAccount, CaptureDataFor, MarketScanAccount
} from "../../classes/market-scan/market-scan.class";
import { Endpoints } from "../../../config/endpoints.config";
import {
    DB_ALLDOCS_QUERY_OPTIONS,
    DB_KEY_PREFIXES,
    DB_SYNC_STATE_KEYS,
    PREFIX_SEARCH_ENDKEY_UNICODE
} from "../../config/pouch-db.config";
import { DeltaService, EntityNames, EntitySyncInfo } from "../../data-services/delta/delta.service";
import { MarketScanDataService } from "../../data-services/market-scan/market-scan.data.service";
import { AuthenticationService } from "../authentication.service";
import { ContactOfflineService } from "../contact/contact.service";
import { DeviceService } from "../device/device.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME, OFFLINE_DB_LINKED_ENTITY_NAME } from "../disk/disk.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from '../../services/alert/alert.service';
import { NotificationService, ToastStyle } from "../notification/notification.service";
import * as moment from 'moment';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { EventsService } from "../events/events.service";
import { AccountOfflineService } from "../account/account.offline.service";
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { OpportunityManagementService } from '../opportunity-management/opportunity-management.service';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';

@Injectable({
    providedIn: 'root'
})
export class MarketScanService {
    private marketScans = new BehaviorSubject<MarketScan[]>([]);
    public marketScansObs$ = this.marketScans.asObservable();
    public customerSegmentations: CustomerSegmentation[] = [];
    public productToEventsMap = {};
    public productToCampaignsMap = {};
    public contactToAccounts: any[] = [];
    public categories: Category[] = [];
    public brands: Brand[] = [];
    public therapeuticAreasMappedData = [];
    private currentMarketScanSource = new BehaviorSubject<MarketScan>(undefined);
    currentMarketScanObserver = this.currentMarketScanSource.asObservable();
    marketScanCopy: MarketScan;
    private rowData: any[] = [];
    private hasEvents: boolean = false;
    private hasCampaigns: boolean = false;
    private hasAccounts: boolean = false;
    private fetchEventsAttribute: boolean = false;
    private fetchCampaignsAttribute: boolean = false;
    public currentMarketScanMatrixData:Array<MarketScanMatrixModel> = [];
    public currentMarketScanDetailsViewMatrix:MarketScanDetailsScreenMatrixModel;

    constructor(
      private authService: AuthenticationService,
        private diskService: DiskService,
        private marketScanDataService: MarketScanDataService,
        private deltaService: DeltaService,
        private deviceService: DeviceService,
        public translate: TranslateService,
        private contactService: ContactOfflineService,
        private alertService: AlertService,
        private events: EventsService,
        private notificationService: NotificationService,
        private surgeryOrderDataService: SurgeryOrderActivityDataService,
        private readonly opportunityService: OpportunityManagementService,
        private readonly opportunityDataService: OpportunityManagementDataService,
        private accountService: AccountOfflineService) {
        this.events.subscribe("marketScan:newAccountContactAff", data => {
          if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_TOOL) && this.hasAccounts && !_.isEmpty(data)) {
            data.forEach(d => {
              if (!d.indskr_accounttype) {
                const account = this.accountService.getAccountById(d.accountId);
                if (account && (account.raw?.indskr_accounttype == '100000001' || account.raw?.indskr_accounttype == '100000004')) {
                  d.indskr_accounttype = account.raw.indskr_accounttype;
                  d.accountName = account.accountName;
                }
              }
              //Add only if account type=Pharmacy/Other
              if (d.indskr_accounttype == '100000001' || d.indskr_accounttype == '100000004')
                this.contactToAccounts.push(d);
            })
            this.diskService.updateOrInsert(DB_KEY_PREFIXES.MARKET_SCAN_ACCOUNTS, doc => ({
              raw: this.contactToAccounts
            })).catch((err) =>
              console.error("marketScan:newAccountContactAff: Error saving accounts data to offline db!", err)
            );
          }
        })
    }

    public setCurrentMarketScan(input) {
        this.currentMarketScanSource.next(input);
    }

    public getAllMarketScans(): MarketScan[] {
        return this.marketScans.getValue();
    }

    public getSelectedMarketScan(): MarketScan {
        return this.currentMarketScanSource.getValue();
    }

    public async initNewMarketScan() {
        this.revertStatus();
        const payload = {
          indskr_externalid: "offline_marketScan_" + new Date().getTime(),
          indskr_name: this.translate.instant('CUSTOMER_SCAN'),
          indskr_date: new Date().getTime().toString(),
          indskr_enddate: new Date().getTime().toString(),
          ownerId: this.authService.user.systemUserID,
          statecode: MarketScanStateCode.Active,
          statuscode: MarketScanStatusCode.Active,
          indskr_positionid: this.authService.user.xPositionID,
        };
        const marketScan = new MarketScan(payload);
        // Removing code to prefill 1 category as it doesn't allow user to create records for past date when a record already exists for current date/month
        // if (this.categories && this.categories.length === 1) {
        //     //Auto map category
        //     marketScan.categoryId = this.categories[0].indskr_assessmentcategoryid;
        //     marketScan.category = this.categories[0];
        //     this.updateSubjectBasedOnFrequency(marketScan,true);
        //     if (this.checkIfMarketScanExists(marketScan.categoryId,marketScan)) {
        //       const date = marketScan.indskr_date ? moment(parseInt(marketScan.indskr_date)).format() : undefined;
        //       this.notificationService.notify(this.getErrorMessageAsPerConf(new Date(date), this.categories[0].indskr_name), 'Market Scan', 'top', ToastStyle.DANGER);
        //       return;
        //     }
        // }

        this.setCurrentMarketScan(marketScan);
        await this.updateMarketScanInPouchDB(marketScan);
        this.upsertMarketScans(marketScan);
        return marketScan;
    }

    public async updateMarketScanInPouchDB(marketScan: MarketScan) {
        if (marketScan.isModified) {
            marketScan.modifiedon = new Date().getTime().toString();
        }
        await this.diskService.upsertMarketScan(marketScan);
    }

    upsertMarketScans(data: MarketScan) {
        //Keep only meta data in list
        const marketScan: MarketScan = this.getOnlyMetaData(data);
        let marketScans: MarketScan[] = this.marketScans.getValue();
        let offIdx: number = marketScans.findIndex((e: MarketScan) => {
            if (e.indskr_externalid) {
                return data.indskr_externalid === e.indskr_externalid
            }
            else
                return false;
        });
        if (offIdx > -1) {
            marketScans[offIdx] = marketScan;
        }
        else {
            marketScans.push(marketScan);
        }
        this.marketScans.next(marketScans);
    }

    private getOnlyMetaData(data: MarketScan) {
        const marketScan: MarketScan = new MarketScan(data);
        marketScan._id = data._id;
        marketScan.lastUpdatedTime = data.lastUpdatedTime;
        marketScan.pendingPushToDynamics = data.pendingPushToDynamics;
        marketScan.isModified = data.isModified;
        marketScan.retainPage = data.retainPage;
        // if(_.isEmpty(data.category)) {
        //   this.mapCategory(data);
        // }
        marketScan.category = data.category;
        return marketScan;
    }

    private async loadAndMapMarketScanMasterDataFromDB() {
        if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_TOOL)) {
            await Promise.all([
                this.loadMarketScansFromDB(),
                this.loadProductsFromDB(),
            ]);
            this.prepareAllFilterToTherapeuticArea();
            this.customerSegmentations = _.uniqBy(this.customerSegmentations, "indskr_customersegmentationid");
        }
    }

    private prepareAllFilterToTherapeuticArea() {
        this.therapeuticAreasMappedData = [];
        this.brands.forEach(brand => {
            if (!brand['customerSegmentations']) brand['customerSegmentations'] = [];
            //Map customer segmentation to brands
            this.mapCustomerSegToProducts(brand);
            if (brand.therapeuticAreas) {
                //Map brands to therapeutic areas
                this.mapBrandsToTherapeuticAreas(brand);
            }
        });

        this.therapeuticAreasMappedData = this.sortListByFielName(this.therapeuticAreasMappedData, 'therapeuticarea_name');
    }

    private mapCustomerSegToProducts(brand: Brand) {
        this.customerSegmentations.filter(seg => seg.productId === brand.ID).forEach(segm => {
            let contact = {
                contactId: segm.contactId,
                contact_fullName: this.contactService.getContactByID(segm.contactId)?.fullName,
                action: Action.PATCH
            };
            const index = brand['customerSegmentations'].findIndex(brandSegment => brandSegment.customerSegmentationId === segm.indskr_customersegmentationid);
            if (index >= 0) {
                //Customer segmentation is present, add contact
                brand['customerSegmentations'][index]['contacts'].push(contact);
            }
            else {
                //Add Customer segmentation with contact
                let segmentation = {
                    customerSegmentationId: segm.indskr_customersegmentationid,
                    segmentName: segm.indskr_name,
                    action: Action.PATCH,
                    contacts: [
                        contact
                    ]
                };
                brand['customerSegmentations'].push(segmentation);
            }
        });
    }

    private mapBrandsToTherapeuticAreas(brand: Brand) {
        brand.therapeuticAreas.forEach(ta => {
            if (!ta['brands'])
                ta['brands'] = [];
            const index = this.therapeuticAreasMappedData.findIndex(therapeuticArea => therapeuticArea.therapeuticareaid == ta.therapeuticareaid);
            let product = {
                productId: brand.ID,
                productName: brand.name,
                action: Action.PATCH,
                customerSegmentations: brand['customerSegmentations']
            };
            if (index >= 0) {
                this.therapeuticAreasMappedData[index]['brands'].push(product);

            }
            else {
                ta['brands'].push(product);
                this.therapeuticAreasMappedData.push(ta);
            }
        });
    }

    private async loadProductsFromDB() {
        await this.diskService.retrieve(DB_KEY_PREFIXES.CASE_PRODUCTS, true).then((data: any[]) => {
            if (data && data['raw']) {
                this.brands = data['raw'].map(e => new Brand(e));
                this.brands = this.sortListByFielName(this.brands, 'name');
            }
            console.log(`Brands from disk : ${this.brands ? this.brands.length : 0}`);
        });

    }

    public async syncMarketScanMasterData(dataRange: { from: string, to: string }, loadFromDbOnly = false, fullSync) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_TOOL)) {
            this.deltaService.pushSyncEntityName(SyncFeatureCategory.profiles);
            await Promise.all([
              this.syncCustomerSegmentations(loadFromDbOnly),
              this.syncCreateFrequency(),
              this.opportunityDataService.getCompetitorsData(fullSync,loadFromDbOnly,!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_COMPETITOR_DATA)),
              this.syncCustomerDataAttributes(loadFromDbOnly).then(async () => {
                await this.syncMarketScans(dataRange, loadFromDbOnly);
              }),
              this.syncMarketScanCategories(loadFromDbOnly).then(async () => {
                let tasks = []
                if (this.hasEvents)
                    tasks.push(this.syncEvents(loadFromDbOnly))
                if (this.hasCampaigns)
                    tasks.push(this.syncCampaigns(loadFromDbOnly))
                if (this.hasAccounts)
                    tasks.push(this.syncAccounts(loadFromDbOnly))
                await Promise.all(tasks);
              })
            ])
            this.loadAndMapMarketScanMasterDataFromDB();
        }
    }

    public async syncEvents(loadFromDbOnly = false) {
        let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
        if (offlineFallback) {
            await this.loadEventsFromDB();
        } else {
            const startDate: string = new Date(moment().startOf('year').format()).getTime().toString();
            const endDate: string = this.getEndDateForQuater();
            await this.marketScanDataService.getEvents(startDate, endDate).then(async (data) => {
                if (data) {
                    this.productToEventsMap = data;
                    await this.diskService.updateOrInsert(DB_KEY_PREFIXES.MARKET_SCAN_EVENTS, doc => ({ raw: data })).catch((err) => console.error("syncEvents: Error saving events data to offline db!", err));
                }
            }).catch((err) => {
                console.error("syncEvents: Error fetching events!", err);
            });
        }
    }

    getEndDateForQuater(): string {
        const currentMonth: number = new Date().getMonth();
        let month = 12;
        if (currentMonth <= 1) {
            //When user in Jan/ Feb, fetch data only till March
            month = 2;
        } else if (currentMonth > 1 && currentMonth <= 4) {
            //When user in March, fetch next quater records to support offline
            month = 5;
        } else if (currentMonth > 4 && currentMonth <= 7) {
            //When user in June, fetch next quater records to support offline
            month = 8;
        } else {
            //When user in Sept, fetch next quater records to support offline
            month = 12;
        }
        return new Date(moment(new Date(new Date().setMonth(month))).endOf('month').format()).getTime().toString();
    }

    public async syncCampaigns(loadFromDbOnly = false) {
        let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
        if (offlineFallback) {
            await this.loadCampaignsFromDB();
        } else {
            const startDate: string = new Date(moment().startOf('year').format()).getTime().toString();
            const endDate: string = this.getEndDateForQuater();
            await this.marketScanDataService.getCampaigns(startDate, endDate).then(async (data) => {
                if (data) {
                    this.productToCampaignsMap = data;
                    await this.diskService.updateOrInsert(DB_KEY_PREFIXES.MARKET_SCAN_CAMPAIGNS, doc => ({ raw: data })).catch((err) => console.error("syncCampaigns: Error saving campaigns data to offline db!", err));
                }
            }).catch((err) => {
                console.error("syncCampaigns: Error fetching campaigns!", err);
            });
        }
    }

    public async syncAccounts(loadFromDbOnly = false) {
      let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
      if (offlineFallback) {
        await this.loadAccountsFromDB();
      } else {
        await this.marketScanDataService.getAffiliatedAccounts().then(async (data) => {
          if (data) {
            this.contactToAccounts = data;
            await this.diskService.updateOrInsert(DB_KEY_PREFIXES.MARKET_SCAN_ACCOUNTS, doc => ({ raw: data })).catch((err) => console.error("syncAccounts: Error saving accounts data to offline db!", err));
          }
        }).catch((err) => {
          console.error("syncAccounts: Error fetching accounts!", err);
        });
      }
    }

    private async loadEventsFromDB() {
        await this.diskService.retrieve(DB_KEY_PREFIXES.MARKET_SCAN_EVENTS).then((data) => {
            if (data && data['raw']) {
                this.productToEventsMap = data['raw'];
                console.log(`Events from disk : ${data['raw'].length}`);
            }
        }).catch((err) => console.error("loadEventsFromDB: Error saving events data to offline db!", err));
    }

    private async loadCampaignsFromDB() {
        await this.diskService.retrieve(DB_KEY_PREFIXES.MARKET_SCAN_CAMPAIGNS).then((data) => {
            if (data && data['raw']) {
                this.productToCampaignsMap = data['raw'];
                console.log(`Campaigns from disk : ${data['raw'].length}`);
            }
        }).catch((err) => console.error("loadCampaignsFromDB: Error saving campaigns data to offline db!", err));
    }

    private async loadAccountsFromDB() {
      await this.diskService.retrieve(DB_KEY_PREFIXES.MARKET_SCAN_ACCOUNTS).then((data) => {
        if (data && data['raw']) {
          this.contactToAccounts = data['raw'];
          console.log(`Campaigns from disk : ${data['raw'].length}`);
        }
      }).catch((err) => console.error("loadAccountsFromDB: Error saving accounts data to offline db!", err));
    }

    private async syncCustomerDataAttributes(loadFromDbOnly = false) {
        let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
        if (!offlineFallback) {
            try {
                const attributes = await this.marketScanDataService.getCustomerDataAttributes();
                if (attributes) {
                    this.fetchEventsAttribute = attributes.hasEvents;
                    this.fetchCampaignsAttribute = attributes.hasCampaigns
                } else {
                    this.fetchEventsAttribute = false;
                    this.fetchCampaignsAttribute = false;
                }
            } catch (error) {
                console.log("Error retreiving marketScanCreateFrequency ", error);
            }
        }
    }

    private async syncCreateFrequency() {
        if (!this.deviceService.isOffline) {
            try {
                const frequency = await this.marketScanDataService.getCreateFrequency();
                if (frequency) {
                    this.authService.user.marketScanCreateFrequency = frequency
                } else {
                    this.authService.user.marketScanCreateFrequency = CreateFrequency.MONTHLY;
                }
                const newDoc = {
                    user: this.authService.user,
                    lastModified: new Date().getTime()
                };
                await this.diskService.updateOrInsert(DB_KEY_PREFIXES.USER, doc => newDoc);

            } catch (error) {
                console.log("Error retreiving marketScanCreateFrequency ", error);
            }
        }
    }

    private async syncCustomerSegmentations(loadFromDbOnly = false) {
        let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
        if (offlineFallback) {
            await this.loadCustomerSegmentationFromDB();
        } else {
            try {
                await this.marketScanDataService.getCustomerSegmentations().then(async (data: CustomerSegmentation[]) => {
                    this.customerSegmentations = data;
                    await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_SEGMENTATION, doc => ({ raw: data }))
                        .catch((err) =>
                            console.error("syncCustomerSegmentations: Error saving customer segmentations data to offline db!", err)
                        );
                });
            } catch (error) {
                console.log("Error retreiving customer segmentations data ", error);
            }
        }
    }

    private async loadCustomerSegmentationFromDB() {
        await this.diskService.retrieve(DB_KEY_PREFIXES.CUSTOMER_SEGMENTATION).then((data) => {
            if (data && data['raw']) {
                this.customerSegmentations = data['raw'];
                this.customerSegmentations = this.sortListByFielName(this.customerSegmentations, 'indskr_name');
            }
            console.log(`Customer segments from disk : ${data && data['raw'] ? data['raw'].length : 0}`);
        });
    }

    public async syncMarketScanCategories(loadFromDbOnly = false) {
        let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
        if (offlineFallback) {
            await this.loadCategoriesFromDB();
        } else {
            let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.GET_CATEGORIES;
            await this.marketScanDataService.getMarketScanCategories(url).then(async (data: Category[]) => {
                if (data) {
                    data.forEach((d: Category) => {
                      //backward compatabilty
                      if (!d.indskr_capturedatafor) {
                        d.indskr_capturedatafor = CaptureDataFor.CUSTOMERS;
                      }
                      d.measures.forEach(m=>{
                        if (!m.captureFor) {
                          m.captureFor = MeasureCaptureFor.CUSTOMERS_ACCOUNTS;
                        }
                      })
                      d.measures = this.sortListByFielName(d.measures, "measureName");
                      this.mapCatureData(d);
                    })
                    this.categories = data;
                    await this.diskService.updateOrInsert(DB_KEY_PREFIXES.MARKET_SCAN_CATEGORIES, doc => ({ raw: data, fetchEventsAttribute: this.fetchEventsAttribute, fetchCampaignsAttribute: this.fetchCampaignsAttribute })).catch((err) => console.error("syncMarketScanCategories: Error saving categories data to offline db!", err));
                }
            }).catch((err) => {
                console.error("syncMarketScanCategories: Error fetching categories!", err);
            });
        }
    }

    private mapCatureData(d: Category) {
        if (d.indskr_capturedata) {
            if (d.indskr_capturedata === CaptureData.EVENTS && !this.hasEvents) {
                this.hasEvents = true;
            }
            else if (d.indskr_capturedata === CaptureData.CAMPAIGN && !this.hasCampaigns) {
                this.hasCampaigns = true;
            }
            else if (d.indskr_capturedata === CaptureData.ACCOUNTS && !this.hasAccounts) {
              this.hasAccounts = true;
            }
        } else {
            d.indskr_capturedata = CaptureData.PRODUCT;
        }
    }

    private async loadCategoriesFromDB() {
        await this.diskService.retrieve(DB_KEY_PREFIXES.MARKET_SCAN_CATEGORIES).then((data) => {
            if (data) {
                if (data['raw']) {
                    this.categories = data['raw'];
                    this.categories.forEach(category => this.mapCatureData(category));
                    console.log(`Categories from disk : ${data['raw'].length}`);
                }
                this.fetchEventsAttribute = data['fetchEventsAttribute'];
                this.fetchCampaignsAttribute = data['fetchCampaignsAttribute'];
            }
        }).catch((err) => console.error("syncMarketScanCategories: Error saving categories data to offline db!", err));
    }

    private async syncMarketScans(dataRange: { from: string, to: string }, loadFromDbOnly = false) {
        let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
        if (offlineFallback) {
            await this.loadMarketScansFromDB();
        } else {
            const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MARKET_SCAN);
            const isInitialSync = !syncState || !syncState.lastUpdatedTime;
            const marketScanSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.marketScan,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };
            if (isInitialSync) {
                await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MARKET_SCAN);
            }
            let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.GET_MARKET_SCANS
                .replace('{startDate}', dataRange.from)
                .replace('{endDate}', dataRange.to)
              .replace('{positionId}', this.authService.user.xPositionID);
            url = isInitialSync ? url : url + '&lastUpdatedTime=' + syncState.lastUpdatedTime;
            if (this.fetchCampaignsAttribute || this.fetchEventsAttribute) {
                url = url + '&captureData=events,campaigns';
            }
            await this.marketScanDataService.getMarketScans(url).then(
                async (data: MarketScan[]) => {
                    const newLastUpdatedTime = new Date().getTime().toString();
                    if (isInitialSync) {
                        await this.mapFullSyncedMarketScans(data, newLastUpdatedTime);
                    }
                    else {
                        await this.mapDeltaSyncedMarketScans(data, newLastUpdatedTime);
                    }
                    syncState.lastUpdatedTime = newLastUpdatedTime;
                    await this.diskService.updateSyncState(syncState);
                    if (Array.isArray(data)) {
                        marketScanSyncInfo.totalSynced = data.length;
                    }
                    this.deltaService.addEntitySyncInfo(marketScanSyncInfo);
                    console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
                }).catch(err => {
                    console.error("Error occurred while fetching market scans..." + err)
                    this.deltaService.addSyncErrorToEntitySyncInfo(marketScanSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.GET_MARKET_SCANS, err);
                    this.deltaService.addEntitySyncInfo(marketScanSyncInfo);
                })
        }
    }

    private async loadMarketScansFromDB() {
        await this.diskService.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MARKET_SCAN).then((data: MarketScan[]) => {
            const marketScans: MarketScan[] = [];
            //Filter cancelled market scans
            data.filter(data => data.statuscode != MarketScanStatusCode.Cancelled).forEach(d => {
                // if (d.pendingPushToDynamics && d.statuscode === MarketScanStatusCode.Active) {
                //     d.statuscode = MarketScanStatusCode.PendingSync;
                // }
                //this.mapCategory(d);
                marketScans.push(this.getOnlyMetaData(d));
            });
            this.marketScans.next(marketScans);
            console.log(`Market scans from disk : ${marketScans ? marketScans.length : 0}`);
        });
        // For offline data tracking...
        await this.checkOfflineDataExists();
    }

    public async checkOfflineDataExists(): Promise<boolean> {
        const offlineMarketScans = this.marketScans.getValue().filter(marketScan => marketScan.pendingPushToDynamics);
        const offlineDataCount = offlineMarketScans ? offlineMarketScans.length : 0;
        this.diskService.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, offlineDataCount);
        return offlineDataCount ? true : false;
    }

    private async mapDeltaSyncedMarketScans(rawData: MarketScan[], newLastUpdatedTime: string) {
        if (rawData && rawData.length > 0) {
            for (let data of rawData) {
                const key = DB_KEY_PREFIXES.MARKET_SCANS + data.indskr_externalid;
                if (!(data.statuscode === MarketScanStatusCode.Completed || data.statuscode === MarketScanStatusCode.Expired || data.statuscode === MarketScanStatusCode.PendingSync)) {
                    await this.diskService.remove(key);
                } else {
                    data._id = key;
                    data.lastUpdatedTime = newLastUpdatedTime;
                    if (this.getSelectedMarketScan() && this.getSelectedMarketScan().indskr_externalid === data.indskr_externalid) {
                        //Refresh details screen on delta sync
                        this.setCurrentMarketScan(data);
                        this.marketScanCopy = cloneDeep(data);
                    }
                    if (this.marketScans.getValue()) {
                        this.mapCategory(data);
                        this.upsertMarketScans(data);
                    }
                    await this.diskService.updateOrInsert(key, (doc) => { return data; });
                }
            }
        }
    }

    private async mapFullSyncedMarketScans(rawData: MarketScan[], newLastUpdatedTime: string) {
        rawData.forEach((data: MarketScan) => {
            data._id = DB_KEY_PREFIXES.MARKET_SCANS + data.indskr_externalid;
            data.lastUpdatedTime = newLastUpdatedTime;
        });
        await this.diskService.bulk(rawData);
    }

    public async mapFilteredDataToSelectedMarketScan(marketScan: MarketScan, type: FilterType) {
        const startTime = new Date().getTime();
        switch (type) {
            // case FilterType.THERAPEUTIC_AREA:
            //     this.mapFiltersForTAs(marketScan);
            //     break;
            case FilterType.CUSTOMER_SEGMENTATION:
                this.mapFiltersForCustomerSegmentation(marketScan,false);
                break;
            case FilterType.PRODUCT:
              this.mapFiltersForProduct(marketScan);
              break;
            default:
                console.log("Unhandled case");

        }
        this.createUpdateMarketScanCustomerDataPayload(marketScan);
        await this.updateMarketScanInPouchDB(marketScan);
        this.setCurrentMarketScan(marketScan)
        const endTime = new Date().getTime();
        console.log("mapFilteredDataToSelectedMarketScan: " + (endTime - startTime) + "type: " + type);
    }

    private mapFiltersForProduct(marketScan: MarketScan) {
        if (marketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS || (marketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS_ACCOUNTS && marketScan.selectView == 'CUSTOMER_VIEW')) {
        let selectedCustomerSegments: Map<string, MarketScanCustomerSegment> = new Map<string, MarketScanCustomerSegment>();
        let selectedContacts: Map<string, MarketScanContact> = new Map<string, MarketScanContact>();
        let filteredProducts = marketScan.products.filter(pr => pr.action && pr.action != Action.DELETE);
        if (filteredProducts && filteredProducts.length) {
          filteredProducts.forEach(mProduct => {
            const index = this.brands.findIndex(p => p.ID === mProduct.productId && p['customerSegmentations']);
            if (index >= 0) {
              this.brands[index]['customerSegmentations'].forEach(cs => {
                if (!selectedCustomerSegments.has(cs.customerSegmentationId)) {
                  selectedCustomerSegments.set(cs.customerSegmentationId, new MarketScanCustomerSegment(cs.segmentName, cs.customerSegmentationId, cs.action));
                }
                cs['contacts'].forEach(c => {
                  if (!selectedContacts.has(c.contactId)) {
                    selectedContacts.set(c.contactId, new MarketScanContact(c.contact_fullName, c.contactId, c.action));
                  }
                });
              });
            }
          });
        }
        let selectedCustomerSegmentsList = Array.from(selectedCustomerSegments.values());
        let selectedContactsList = Array.from(selectedContacts.values())
        if (marketScan.indskr_marketscanid) {
          this.unMapReMapSegments(selectedCustomerSegmentsList, marketScan);
          //this.unMapReMapContacts(selectedContactsList, marketScan);
        } else if (!_.isEmpty(marketScan.contacts)) {
          //For unsaved records clear selected contacts if not matched with filters
          //this.resetContacts(selectedContacts, marketScan);
        }
        marketScan.customerSegmentations = selectedCustomerSegmentsList;
        marketScan.segmentedContacts = this.sortListByFielName(selectedContactsList, "contact_fullName");
      }
      //this.mapCaptureDataToProducts(marketScan);
    }

    // public mapCaptureDataToProducts(marketScan: MarketScan) {
    //     const latestDataToAdd: ColumnData[] = [];
    //     if (!marketScan.category) {
    //         marketScan.selectedColumnData = [];
    //         return;
    //     }
    //     if (marketScan.category.indskr_capturedata === CaptureData.PRODUCT) {
    //         if (marketScan.indskr_marketscanid) {
    //             marketScan.selectedColumnData.forEach(selectedColumn => {
    //                 //if product is already present in selectedColumn but with different action. then set in selectedColumn to constuct request payload
    //                 const product: MarketScanProduct = marketScan.products.find(p => p.productId === selectedColumn.id);
    //                 if (product) {
    //                     latestDataToAdd.push(new ColumnData(product.productId, product.productName, product.action));
    //                 } else {
    //                     selectedColumn.action = Action.DELETE;
    //                     latestDataToAdd.push(selectedColumn);
    //                 }
    //             });
    //             // in case of category change, it should be added newly
    //             latestDataToAdd.push(...marketScan.products.filter(product =>
    //                 marketScan.selectedColumnData.findIndex(sc => sc.id === product.productId) === -1).map(product =>
    //                     new ColumnData(product.productId, product.productName, product.action)));
    //             // marketScan.selectedColumnData = latestDataToAdd;
    //         } else {
    //             marketScan.selectedColumnData = marketScan.products.map(p => new ColumnData(p.productId, p.productName, p.action));
    //         }
    //     } else if (marketScan.category.indskr_capturedata === CaptureData.EVENTS) {
    //         let events: Event[] = [];
    //         marketScan.products.forEach(product => {
    //             const productEvents: Event[] = this.filterEventForCurrentQuater(this.productToEventsMap[product.productId], marketScan.indskr_date);
    //             productEvents.forEach(c => { c.action = product.action });
    //             events.push(...productEvents);
    //         });
    //         events = _.uniqBy(events, obj => obj.msevtmgt_eventid);
    //         if (marketScan.indskr_marketscanid) {
    //             //if event is already present in selectedColumn but with different action. then set in selectedColumn to constuct request payload
    //             marketScan.selectedColumnData.forEach(selectedColumn => {
    //                 const event: Event = events.find(e => e.msevtmgt_eventid === selectedColumn.id);
    //                 if (event) {
    //                     latestDataToAdd.push(new ColumnData(event.msevtmgt_eventid, event.msevtmgt_name, event.action));
    //                 } else {
    //                     selectedColumn.action = Action.DELETE;
    //                     latestDataToAdd.push(selectedColumn);
    //                 }
    //             });
    //             // in case of category change, it should be added newly
    //             latestDataToAdd.push(...events.filter(event =>
    //                 marketScan.selectedColumnData.findIndex(sc => sc.id === event.msevtmgt_eventid) === -1).map(event =>
    //                     new ColumnData(event.msevtmgt_eventid, event.msevtmgt_name, event.action)));
    //             // marketScan.selectedColumnData = latestDataToAdd;
    //         } else {
    //             marketScan.selectedColumnData = events.map(e => new ColumnData(e.msevtmgt_eventid, e.msevtmgt_name, e.action));
    //         }
    //     } else if (marketScan.category.indskr_capturedata === CaptureData.CAMPAIGN) {
    //         let campaigns: CustomerJourney[] = [];
    //         marketScan.products.forEach(product => {
    //             const productCampaigns: CustomerJourney[] = this.filterCampaignsForCurrentQuater(this.productToCampaignsMap[product.productId], marketScan.indskr_date);
    //             productCampaigns.forEach(c => c.action = product.action);
    //             campaigns.push(...productCampaigns);
    //         });
    //         campaigns = _.uniqBy(campaigns, obj => obj.msdyncrm_customerjourneyid);
    //         if (marketScan.indskr_marketscanid) {
    //             marketScan.selectedColumnData.forEach(selectedColumn => {
    //                 //if campaign is already present in selectedColumn but with different action. then set in selectedColumn to constuct request payload
    //                 const campaign: CustomerJourney = campaigns.find(c => c.msdyncrm_customerjourneyid === selectedColumn.id);
    //                 if (campaign) {
    //                     latestDataToAdd.push(new ColumnData(campaign.msdyncrm_customerjourneyid, campaign.msdyncrm_name, campaign.action));
    //                 } else {
    //                     selectedColumn.action = Action.DELETE;
    //                     latestDataToAdd.push(selectedColumn);
    //                 }
    //             });
    //             // in case of category change, it should be added newly
    //             latestDataToAdd.push(...campaigns.filter(campaign =>
    //                 marketScan.selectedColumnData.findIndex(sc => sc.id === campaign.msdyncrm_customerjourneyid) === -1).map(campaign =>
    //                     new ColumnData(campaign.msdyncrm_customerjourneyid, campaign.msdyncrm_name, campaign.action)
    //                 ));
    //             // marketScan.selectedColumnData = latestDataToAdd;
    //         } else {
    //             marketScan.selectedColumnData = campaigns.map(c => new ColumnData(c.msdyncrm_customerjourneyid, c.msdyncrm_name, c.action));
    //         }
    //     } else if (marketScan.category.indskr_capturedata === CaptureData.ACCOUNTS) {
    //       let accounts: AffiliatedAccount[] = [];
    //       marketScan.contacts.forEach(contact => {
    //         const affiliatedAccounts: AffiliatedAccount[] = this.contactToAccounts.filter(cA => cA['contactId'] == contact.contactId);
    //         affiliatedAccounts.forEach(c => {
    //           c.action = contact.action;
    //           const accountIndex = accounts.findIndex(ac => ac.accountId === c.accountId);
    //           if (accountIndex >= 0) {
    //             //Just incase same account is mapped to different contact and that account has different action
    //             if (accounts[accountIndex].action !== c.action) {
    //               accounts[accountIndex].action = Action.PATCH;
    //             }
    //           } else {
    //             accounts.push(c);
    //           }
    //         });
    //       });
    //       // accounts = _.uniqBy(accounts, obj => obj.accountId);
    //       if (marketScan.indskr_marketscanid) {
    //         marketScan.selectedColumnData.forEach(selectedColumn => {
    //           //if account is already present in selectedColumn but with different action. then set in selectedColumn to constuct request payload
    //           const account: AffiliatedAccount = accounts.find(c => c.accountId === selectedColumn.id);
    //           if (account) {
    //             latestDataToAdd.push(new ColumnData(account.accountId, account.accountName, account.action));
    //           } else {
    //             selectedColumn.action = Action.DELETE;
    //             latestDataToAdd.push(selectedColumn);
    //           }
    //         });
    //         // in case of category change, it should be added newly
    //         latestDataToAdd.push(...accounts.filter(account =>
    //           marketScan.selectedColumnData.findIndex(sc => sc.id === account.accountId) === -1).map(account =>
    //           new ColumnData(account.accountId, account.accountName, account.action)
    //         ));
    //         // marketScan.selectedColumnData = latestDataToAdd;
    //       } else {
    //         marketScan.selectedColumnData = accounts.map(c => new ColumnData(c.accountId, c.accountName, c.action));
    //       }
    //     }
    //     if (marketScan.indskr_marketscanid) {
    //         marketScan.selectedColumnData = [];
    //         latestDataToAdd.forEach(selectedColumn => {
    //             //remove new column which has delete action
    //             const index = this.marketScanCopy.selectedColumnData.findIndex(sc => sc.id === selectedColumn.id);
    //             if (!(index === -1 && selectedColumn.action === Action.DELETE)) {
    //                 marketScan.selectedColumnData.push(selectedColumn);
    //             }
    //         });
    //         marketScan.selectedColumnData.filter(selecteColumn => selecteColumn.action === Action.DELETE).forEach(selectedColumn => {
    //             marketScan.marketScanCustomerDatas.forEach(cData => {
    //                 cData.data.filter(data => (data.indskr_campaignid === selectedColumn.id) || (data.indskr_eventid === selectedColumn.id) || (data.productId === selectedColumn.id) || (data.accountId === selectedColumn.id)).forEach(data => {
    //                     data.action = Action.DELETE;
    //                 });
    //             })
    //         })
    //         //Remove customer data if not part of selected column
    //         const selectedColumnIds: string[] = marketScan.selectedColumnData.map(sc => sc.id);
    //         marketScan.marketScanCustomerDatas?.forEach(cData => {
    //             cData.data = cData.data.filter(d => (selectedColumnIds.includes(d.indskr_eventid) || selectedColumnIds.includes(d.indskr_campaignid) || selectedColumnIds.includes(d.productId) || selectedColumnIds.includes(d.accountId)));
    //         })
    //     } else if (_.isEmpty(marketScan.selectedColumnData)) {
    //         //For open records, if selectedcolumns are empty
    //         marketScan.marketScanCustomerDatas = [];
    //     } else if (!_.isEmpty(marketScan.marketScanCustomerDatas)) {
    //         // For open records, if selected columns are updated
    //         const selectedColumnIds: string[] = marketScan.selectedColumnData.map(sc => sc.id);
    //         marketScan.marketScanCustomerDatas.forEach(cData => {
    //             cData.data = cData.data.filter(d => (selectedColumnIds.includes(d.indskr_eventid) || selectedColumnIds.includes(d.indskr_campaignid) || selectedColumnIds.includes(d.productId) || selectedColumnIds.includes(d.accountId)));
    //         })
    //     }
    //     //market scan selected colum data first colum shoub be account
    //     // marketScan.selectedColumnData
    // }

    // filterEventForCurrentQuater(events: Event[], date: string): Event[] {
    //     if (!events) return [];
    //     const selectedMonth: number = new Date(parseInt(date)).getMonth();
    //     const selectedYear: number = new Date(parseInt(date)).getFullYear();
    //     let { startMonth, endMonth }: { startMonth: number; endMonth: number; } = this.getStartEndMonth(selectedMonth);
    //     return events.filter((event: Event) => new Date(parseInt(event.startDate)).getMonth() >= startMonth &&
    //         new Date(parseInt(event.startDate)).getMonth() <= endMonth &&
    //         new Date(parseInt(event.startDate)).getFullYear() === selectedYear)
    // }

    // filterCampaignsForCurrentQuater(campaigns: CustomerJourney[], date: string) {
    //     if (!campaigns) return [];
    //     const selectedMonth: number = new Date(parseInt(date)).getMonth();
    //     const selectedYear: number = new Date(parseInt(date)).getFullYear();
    //     let { startMonth, endMonth }: { startMonth: number; endMonth: number; } = this.getStartEndMonth(selectedMonth);
    //     return campaigns.filter((campaign: CustomerJourney) => new Date(parseInt(campaign.startDate)).getMonth() >= startMonth &&
    //         new Date(parseInt(campaign.startDate)).getMonth() <= endMonth &&
    //         new Date(parseInt(campaign.startDate)).getFullYear() === selectedYear)
    // }

    // private getStartEndMonth(selectedMonth: number) {
    //     let startMonth: number = null;
    //     let endMonth: number = null;
    //     if (selectedMonth <= 2) {
    //         startMonth = 0;
    //         endMonth = 2;
    //     } else if (selectedMonth >= 3 && selectedMonth <= 5) {
    //         startMonth = 3;
    //         endMonth = 5;
    //     } else if (selectedMonth >= 6 && selectedMonth <= 8) {
    //         startMonth = 6;
    //         endMonth = 8;
    //     } else {
    //         startMonth = 9;
    //         endMonth = 11;
    //     }
    //     return { startMonth, endMonth };
    // }

    // private resetContacts(selectedContacts: Map<string, MarketScanContact>, marketScan: MarketScan) {
    //     if (!_.isEmpty(selectedContacts)) {
    //         marketScan.contacts = marketScan.contacts.filter(c => selectedContacts.has(c.contactId));
    //         if (marketScan.marketScanCustomerDatas)
    //             marketScan.marketScanCustomerDatas = marketScan.marketScanCustomerDatas.filter(cD => selectedContacts.has(cD.contactId));
    //     } else {
    //         marketScan.contacts = [];
    //         marketScan.marketScanCustomerDatas = [];
    //     }
    //     if (marketScan.category.indskr_capturedata === CaptureData.ACCOUNTS) {
    //       //this.mapCaptureDataToProducts(marketScan)
    //     }
    // }

    public mapFiltersForCustomerSegmentation(marketScan: MarketScan, resetSelectedContacts: boolean = true) {
        let selectedContacts: Map<string, MarketScanContact> = new Map<string, MarketScanContact>();
        let customerSegId = [];
        let filteredSegments = marketScan.customerSegmentations.filter(seg => seg.action && seg.action != Action.DELETE);
        if (filteredSegments && filteredSegments.length) {
            //marketScan.customerSegmentations = filteredSegments;
            filteredSegments.map(cs => customerSegId.push(cs.customerSegmentationId));
            if (filteredSegments.length > 0)
                marketScan.products.forEach(mProduct => {
                    const index = this.brands.findIndex(p => p.ID === mProduct.productId && p['customerSegmentations']);
                    if (index >= 0) {
                        this.brands[index]['customerSegmentations'].filter(cs => customerSegId.includes(cs.customerSegmentationId)).map(cs => {
                            cs['contacts'].forEach(c => {
                                if (!selectedContacts.has(c.contactId)) {
                                    selectedContacts.set(c.contactId, new MarketScanContact(c.contact_fullName, c.contactId, c.action));
                                }
                            });
                        });
                    }
                });
        }
        let selectedContactsList = Array.from(selectedContacts.values());
        // if (resetSelectedContacts) {
        //     if (marketScan.indskr_marketscanid) {
        //         this.unMapReMapContacts(selectedContactsList, marketScan);
        //     } else if (!_.isEmpty(marketScan.contacts)) {
        //         //For unsaved records clear selected contacts if not matched with filters
        //         this.resetContacts(selectedContacts, marketScan);
        //     }
        // }
        marketScan.segmentedContacts = this.sortListByFielName(selectedContactsList, "contact_fullName");
    }

    // private mapFiltersForTAs(marketScan: MarketScan) {
    //     let selectedProducts: Map<string, MarketScanProduct> = new Map<string, MarketScanProduct>();
    //     let selectedCustomerSegments: Map<string, MarketScanCustomerSegment> = new Map<string, MarketScanCustomerSegment>();
    //     let selectedContacts: Map<string, MarketScanContact> = new Map<string, MarketScanContact>();
    //     let filteredTAs = marketScan.therapeuticAreas.filter(ta => ta.action && ta.action != Action.DELETE);
    //     if (filteredTAs && filteredTAs.length) {
    //         filteredTAs.forEach(ta => {
    //             const index = this.therapeuticAreasMappedData.findIndex(t => t.therapeuticareaid === ta.therapeuticAreaId);
    //             if (index >= 0) {
    //                 this.therapeuticAreasMappedData[index]['brands'].forEach(element => {
    //                     if (!selectedProducts.has(element.productId)) {
    //                         selectedProducts.set(element.productId, new MarketScanProduct(element.productId, element.productName,element.isCompetitorProduct, element.action));
    //                     }
    //                     if (marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS) {
    //                       element['customerSegmentations'].forEach(cs => {
    //                         if (!selectedCustomerSegments.has(cs.customerSegmentationId)) {
    //                           selectedCustomerSegments.set(cs.customerSegmentationId, new MarketScanCustomerSegment(cs.segmentName, cs.customerSegmentationId, cs.action));
    //                         }
    //                         cs['contacts'].forEach(c => {
    //                           if (!selectedContacts.has(c.contactId)) {
    //                             selectedContacts.set(c.contactId, new MarketScanContact(c.contact_fullName, c.contactId, c.action));
    //                           }
    //                         });
    //                       });
    //                     }
    //                 });
    //             }
    //         });
    //     }
    //     let selectedProductsList = Array.from(selectedProducts.values());
    //     let selectedCustomerSegmentsList = Array.from(selectedCustomerSegments.values());
    //     let selectedContactsList = Array.from(selectedContacts.values())
    //     if (marketScan.indskr_marketscanid) {
    //       this.unMapReMapProducts(selectedProductsList, marketScan);
    //       if (marketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS) {
    //         this.unMapReMapSegments(selectedCustomerSegmentsList, marketScan);
    //         this.unMapReMapContacts(selectedContactsList, marketScan);
    //       }
    //     } else if (!_.isEmpty(marketScan.contacts)) {
    //       //For unsaved records clear selected contacts if not matched with filters
    //       if (marketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS) {
    //         this.resetContacts(selectedContacts, marketScan);
    //       }
    //     }
    //     marketScan.products = _.uniqBy(selectedProductsList.map(p => new MarketScanProduct(p.productId, p.productName,p.isCompetitorProduct, p.action)), obj => obj.productId);
    //     if (marketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS) {
    //       marketScan.customerSegmentations = _.uniqBy(selectedCustomerSegmentsList.map(c => new MarketScanCustomerSegment(c.segmentName, c.customerSegmentationId, c.action)), obj => obj.customerSegmentationId);
    //       marketScan.segmentedContacts = this.sortListByFielName(_.uniqBy(selectedContactsList.map(c => new MarketScanContact(c.contact_fullName, c.contactId, c.action)), obj => obj.contactId), "contact_fullName");
    //     }
    //     //this.mapCaptureDataToProducts(marketScan);
    // }

    unMapReMapProducts(selectedProducts: MarketScanProduct[], marketScan: MarketScan) {
        if (_.isEmpty(marketScan.indskr_marketscanid)) return;
        if (!selectedProducts.length) {
            selectedProducts.push(...marketScan.products);
            selectedProducts.forEach(p => p.action = Action.DELETE);
            return;
        }
        let alreadySelectedProducts = _.map(marketScan.products.filter(ta => ta.action && ta.action != Action.DELETE), 'productId');
        let currentSelectedProducts = _.map(selectedProducts, 'productId');
        if (!alreadySelectedProducts.length && _.every(marketScan.products, ['action', Action.DELETE])) {
            const ids = selectedProducts.map((dc) => dc.productId);
            marketScan.products.forEach(product => {
                //If removed and added back same product, dont add it to selected product
                if (product.action === Action.DELETE && ids.includes(product.productId)) {
                    product.action = Action.PATCH;
                } else {
                    selectedProducts.push(product);
                }
            });
            // selectedProducts.push(...marketScan.products);
        }
        let diffProducts = _.differenceWith(alreadySelectedProducts, currentSelectedProducts, _.isEqual);
        if (diffProducts && diffProducts.length) {
            diffProducts.forEach((diff) => {
                let deletableProd = marketScan.products.find((ta) => ta.productId == diff);
                if (deletableProd) {
                    deletableProd.action = Action.DELETE;
                    selectedProducts.push(deletableProd);
                }
            });
        } else {
            selectedProducts.forEach(t => {
                t.action = t.action ? t.action : Action.PATCH;
            })
        }
        // add unique deletable objects which gets missed in the above logic
        // scenario: when "marketScan.products" has one object with action patch and the other as delete
        const deletableProducts = marketScan.products.filter(mp => mp.action && mp.action == Action.DELETE);
        if (deletableProducts.length > 0) {
            const ids = selectedProducts.map((p) => p.productId);
            deletableProducts.forEach((p) => {
                if (!ids.includes(p.productId)) {
                    selectedProducts.push(p);
                }
            });
        }
    }

    unMapReMapSegments(selectedCustomerSegments: MarketScanCustomerSegment[], marketScan: MarketScan) {
        if (_.isEmpty(marketScan.indskr_marketscanid)) return;
        if (!selectedCustomerSegments.length) {
            selectedCustomerSegments.push(...marketScan.customerSegmentations);
            selectedCustomerSegments.forEach(seg => seg.action = Action.DELETE);
            return;
        }
        let alreadySelectedSegments = _.map(marketScan.customerSegmentations.filter(ta => ta.action && ta.action != Action.DELETE), 'customerSegmentationId');
        let currentSelectedSegments = _.map(selectedCustomerSegments, 'customerSegmentationId');
        if (!alreadySelectedSegments.length && _.every(marketScan.customerSegmentations, ['action', Action.DELETE])) {
            const ids = selectedCustomerSegments.map((dc) => dc.customerSegmentationId);
            marketScan.customerSegmentations.forEach(seg => {
                //If removed and added back same seg, dont add it to selected seg
                if (seg.action === Action.DELETE && ids.includes(seg.customerSegmentationId)) {
                    seg.action = Action.PATCH;
                } else {
                    selectedCustomerSegments.push(seg);
                }
            });
            // selectedCustomerSegments.push(...marketScan.customerSegmentations);
        }
        let diffSegments = _.differenceWith(alreadySelectedSegments, currentSelectedSegments, _.isEqual);
        if (diffSegments && diffSegments.length) {
            diffSegments.forEach((diff) => {
                let deletableSegment = marketScan.customerSegmentations.find((ta) => ta.customerSegmentationId == diff);
                if (deletableSegment) {
                    deletableSegment.action = Action.DELETE;
                    selectedCustomerSegments.push(deletableSegment);
                }
            });
        } else {
            selectedCustomerSegments.forEach(t => {
                t.action = t.action ? t.action : Action.PATCH;
            })
        }
        // add unique deletable objects which gets missed in the above logic
        // scenario: when "marketScan.customerSegmentations" has one object with action patch and the other as delete
        const deletableCustomerSegments = marketScan.customerSegmentations.filter(mc => mc.action && mc.action == Action.DELETE);
        if (deletableCustomerSegments.length > 0) {
            const ids = selectedCustomerSegments.map((c) => c.customerSegmentationId);
            deletableCustomerSegments.forEach((c) => {
                if (!ids.includes(c.customerSegmentationId)) {
                    selectedCustomerSegments.push(c);
                }
            });
        }
    }

    unMapReMapContacts(selectedContacts: MarketScanContact[], marketScan: MarketScan) {
        if (_.isEmpty(marketScan.indskr_marketscanid)) return;
        if (!selectedContacts.length) {
            selectedContacts.push(...marketScan.contacts);
            selectedContacts.forEach(con => con.action = Action.DELETE);
            //this.mapCaptureDataToProducts(marketScan);
            return;
        }
        let alreadySelectedContacts = _.map(marketScan.contacts.filter(ta => ta.action && ta.action != Action.DELETE), 'contactId');
        let currentSelectedContacts = _.map(selectedContacts, 'contactId');
        if (!alreadySelectedContacts.length && _.every(marketScan.contacts, ['action', Action.DELETE])) {
            const ids = selectedContacts.map((dc) => dc.contactId);
            marketScan.contacts.forEach(contact => {
                //If removed and added back same contacts, dont add it to selectedContacts
                if (contact.action === Action.DELETE && ids.includes(contact.contactId)) {
                    contact.action = Action.PATCH;
                    contact.sequence = selectedContacts.find(c => c.contactId === contact.contactId).sequence;
                } else {
                    selectedContacts.push(contact);
                }
            });
            // selectedContacts.push(...marketScan.contacts);
        }
        let diffContacts = _.differenceWith(alreadySelectedContacts, currentSelectedContacts, _.isEqual);
        if (diffContacts && diffContacts.length) {
            diffContacts.forEach((diff) => {
                let deletableContact = marketScan.contacts.find((ta) => ta.contactId == diff);
                if (deletableContact) {
                    deletableContact.action = Action.DELETE;
                    selectedContacts.push(deletableContact);
                }
            });
        } else {
            selectedContacts.forEach(t => {
                t.action = t.action ? t.action : Action.PATCH;
            })
        }
        // add unique deletable objects which gets missed in the above logic
        // scenario: when "marketScan.contacts" has one object with action patch and the other as delete
        const deletableContacts = marketScan.contacts.filter(ms => ms.action && ms.action == Action.DELETE);
        if (deletableContacts.length > 0) {
            const ids = selectedContacts.map((dc) => dc.contactId);
            deletableContacts.forEach((c) => {
                let marketScanCustomerDatas = marketScan.marketScanCustomerDatas.filter(cd => cd.contactId === c.contactId);
                if(marketScanCustomerDatas.length > 0){
                  marketScanCustomerDatas.forEach(item=> {
                    item.action = Action.DELETE;
                  });
                }
                // const index = marketScan.marketScanCustomerDatas.findIndex(cd => cd.contactId === c.contactId);
                // if (index >= 0)
                //     marketScan.marketScanCustomerDatas[index].data.forEach(customerData => customerData.action = Action.DELETE);
                if (!ids.includes(c.contactId)) {
                    selectedContacts.push(c);
                }
            });
        }
        //this.mapCaptureDataToProducts(marketScan);
    }

    unMapReMapCompetitors(selectedCompetitors: MarketScanCompetitor[], marketScan: MarketScan) {
      if (_.isEmpty(marketScan.indskr_marketscanid)) return;
      if (!selectedCompetitors.length) {
        selectedCompetitors.push(...marketScan.competitors);
        selectedCompetitors.forEach(con => con.action = Action.DELETE);
        return;
      }
      let alreadySelectedCompetitors = _.map(marketScan.competitors.filter(ta => ta.action && ta.action != Action.DELETE), 'competitorId');
      let currentSelectedCompetitors = _.map(selectedCompetitors, 'competitorId');
      if (!alreadySelectedCompetitors.length && _.every(marketScan.competitors, ['action', Action.DELETE])) {
        const ids = selectedCompetitors.map((dc) => dc.competitorId);
        marketScan.competitors.forEach(competitor => {
          if (competitor.action === Action.DELETE && ids.includes(competitor.competitorId)) {
            competitor.action = Action.PATCH;
            competitor.sequence = selectedCompetitors.find(c => c.competitorId === competitor.competitorId).sequence;
          } else {
            selectedCompetitors.push(competitor);
          }
        });
      }
      let diffCompetitors = _.differenceWith(alreadySelectedCompetitors, currentSelectedCompetitors, _.isEqual);
      if (diffCompetitors && diffCompetitors.length) {
        diffCompetitors.forEach((diff) => {
          let deletableCompetitor = marketScan.competitors.find((ta) => ta.competitorId == diff);
          if (deletableCompetitor) {
            deletableCompetitor.action = Action.DELETE;
            selectedCompetitors.push(deletableCompetitor);
          }
        });
      } else {
        selectedCompetitors.forEach(t => {
          t.action = t.action ? t.action : Action.PATCH;
        })
      }
      // // add unique deletable objects which gets missed in the above logic
      // // scenario: when "marketScan.contacts" has one object with action patch and the other as delete
      const deletableCompetitor = marketScan.competitors.filter(ms => ms.action && ms.action == Action.DELETE);
      if (deletableCompetitor.length > 0) {
        const ids = selectedCompetitors.map((dc) => dc.competitorId);
        deletableCompetitor.forEach((c) => {
          let marketScanCustomerDatas = marketScan.marketScanCustomerDatas.filter(cd => cd.competitorId === c.competitorId);
          if (marketScanCustomerDatas.length > 0) {
            marketScanCustomerDatas.forEach(item => {
              item.action = Action.DELETE;
            });
          }
          if (!ids.includes(c.competitorId)) {
            selectedCompetitors.push(c);
          }
        });
      }
    }

    unMapReMapAccounts(selectedAccounts: MarketScanAccount[], marketScan: MarketScan) {
      if (_.isEmpty(marketScan.indskr_marketscanid)) return;
      if (!selectedAccounts.length) {
        selectedAccounts.push(...marketScan.accounts);
        selectedAccounts.forEach(ac => ac.action = Action.DELETE);
        //this.mapCaptureDataToProducts(marketScan);
        return;
      }
      let alreadySelectedAccounts = _.map(marketScan.accounts.filter(ta => ta.action && ta.action != Action.DELETE), 'accountId');
      let currentSelectedAccounts = _.map(selectedAccounts, 'accountId');
      if (!alreadySelectedAccounts.length && _.every(marketScan.accounts, ['action', Action.DELETE])) {
        const ids = selectedAccounts.map((dc) => dc.accountId);
        marketScan.accounts.forEach(account => {
          //If removed and added back same accounts, dont add it to selectedAccounts
          if (account.action === Action.DELETE && ids.includes(account.accountId)) {
            account.action = Action.PATCH;
            account.sequence = selectedAccounts.find(ac => ac.accountId === account.accountId).sequence;
          } else {
            selectedAccounts.push(account);
          }
        });
      }
      let diffAccounts = _.differenceWith(alreadySelectedAccounts, currentSelectedAccounts, _.isEqual);
      if (diffAccounts && diffAccounts.length) {
        diffAccounts.forEach((diff) => {
          let deletableAccount = marketScan.accounts.find((ta) => ta.accountId == diff);
          if (deletableAccount) {
            deletableAccount.action = Action.DELETE;
            selectedAccounts.push(deletableAccount);
          }
        });
      } else {
        selectedAccounts.forEach(t => {
          t.action = t.action ? t.action : Action.PATCH;
        })
      }
      // add unique deletable objects which gets missed in the above logic
      // scenario: when "marketScan.accounts" has one object with action patch and the other as delete
      const deletableContacts = marketScan.accounts.filter(ms => ms.action && ms.action == Action.DELETE);
      if (deletableContacts.length > 0) {
        const ids = selectedAccounts.map((dc) => dc.accountId);
        deletableContacts.forEach((c) => {
          let marketScanCustomerDatas = marketScan.marketScanCustomerDatas.filter(cd => cd.accountId === c.accountId);
          if (marketScanCustomerDatas.length > 0) {
            marketScanCustomerDatas.forEach(item => {
              item.action = Action.DELETE;
            });
          }
          // const index = marketScan.marketScanCustomerDatas.findIndex(cd => cd.accountId === c.accountId);
          // if (index >= 0)
          //   marketScan.marketScanCustomerDatas[index].data.forEach(customerData => customerData.action = Action.DELETE);
          if (!ids.includes(c.accountId)) {
            selectedAccounts.push(c);
          }
        });
      }
      //this.mapCaptureDataToProducts(marketScan);
    }

    public async mapAccountsToMarketScan(marketScan: MarketScan, accounts: MarketScanAccount[]) {
      this.unMapReMapAccounts(accounts, marketScan);
      accounts = this.sortListByFielName(accounts, 'accountName');
      marketScan.accounts = accounts;//_.uniqBy(accounts.map(ac => new MarketScanAccount(ac.accountName, ac.accountId, ac.action, ac.sequence, true, ac.affiliatedContacts)), obj => obj.accountId);
      // if (_.isEmpty(marketScan.accounts) && !marketScan.indskr_marketscanid) {
      //   marketScan.marketScanCustomerDatas = [];
      // }
      //this.mapCaptureDataToProducts(marketScan);
      this.createUpdateMarketScanCustomerDataPayload(marketScan);
      await this.updateMarketScanInPouchDB(marketScan);
      this.upsertMarketScans(marketScan);
      this.setCurrentMarketScan(marketScan);
    }

    public async mapContactsToMarketScan(marketScan: MarketScan, contacts: MarketScanContact[]) {
        //const contactsWithSeq: MarketScanContact[] = contacts.filter(v => v.sequence != null);
        //const contactWithoutSeq: MarketScanContact[] = contacts.filter(v => v.sequence == null);
        //const _contacts = _.orderBy(contactsWithSeq, ob => ob.sequence, ['desc']);
        contacts = this.sortListByFielName(contacts, 'contact_fullName');
        marketScan.contacts = contacts;
        // if (_.isEmpty(marketScan.contacts) && !marketScan.indskr_marketscanid) {
        //     marketScan.marketScanCustomerDatas = [];
        // }
        //this.mapCaptureDataToProducts(marketScan);
        if (marketScan.category.indskr_capturedata === CaptureData.ACCOUNTS && (_.isEmpty(marketScan.marketScanCustomerDatas) || marketScan.marketScanCustomerDatas.filter(columnData => columnData.action === Action.PATCH).length === 0)
        && (!_.isEmpty(marketScan.contacts) && marketScan.contacts.filter(contact => contact.action === Action.PATCH).length > 0)) {
          this.notificationService.notify(this.translate.instant("NO_DATA_TO_CAPTURE_CUSTOMER_SCAN", { captureData: this.translate.instant("ACCOUNTS").toLowerCase() }), 'Market Scan', 'top', ToastStyle.DANGER);
        }
        this.createUpdateMarketScanCustomerDataPayload(marketScan);
        await this.updateMarketScanInPouchDB(marketScan);
        this.setCurrentMarketScan(marketScan);
    }

    public async mapCompetitorToMarketScan(marketScan: MarketScan, competitors: MarketScanCompetitor[]) {
      competitors = this.sortListByFielName(competitors, 'competitorName');
      marketScan.competitors = competitors;
      this.createUpdateMarketScanCustomerDataPayload(marketScan);
      await this.updateMarketScanInPouchDB(marketScan);
      this.setCurrentMarketScan(marketScan);
    }

    public displayText(any, fieldName: string): string {
        if (_.isEmpty(any)) {
            return "";
        }
        else {
            const filteredItems = any.filter((v) => v.action !== Action.DELETE);
            if (filteredItems.length) {
                any = filteredItems;
            } else {
                return "";
            }

            any = this.sortListByFielName(any, fieldName);;
            if (fieldName === 'contact_fullName' || fieldName === 'accountName') {
                return any.length > 1 ? (any[0][fieldName].concat(this.getInavtiveText(any[0])).concat(' +').concat(any.length - 1)) : (any[0][fieldName] + this.getInavtiveText(any[0]));
            }
            return any.length > 1 ? (any[0][fieldName].concat(' +').concat(any.length - 1)) : any[0][fieldName];
        }
    }

    getInavtiveText(obj: any) {
        return obj['isActive'] === false ? ` (${this.translate.instant('INACTIVE')})` : '';
    }

    public async createMarketScan(marketScan: MarketScan) {
        //marketScan = await this.addMissingCustomerData(marketScan);
        if (this.deviceService.isOffline) {
            //Keep offline count
            if (!marketScan.pendingPushToDynamics)
                this.diskService.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
            marketScan.pendingPushToDynamics = true;
            this.marketScanCopy = cloneDeep(marketScan);
        } else {
            marketScan.statuscode = MarketScanStatusCode.Completed;
            this.upsertMarketScans(marketScan);
            const payload = this._getMarketScanServicePayload(marketScan);
            await this.marketScanDataService.createMarketScan(payload).then(async (res) => {
                if (res && res['indskr_marketscanid']) {
                    marketScan.pendingPushToDynamics = false;
                    marketScan.lastUpdatedTime = new Date().getTime().toString();
                    marketScan.indskr_marketscanid = res['indskr_marketscanid'];
                    this.marketScanCopy = cloneDeep(marketScan);
                }
            }).catch(err => {
                console.error("Failed to save marketscan due to: ", err);
                marketScan.statuscode = MarketScanStatusCode.PendingSync;
                marketScan.pendingPushToDynamics = true;
                this.diskService.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
            })
        }
        this.createUpdateMarketScanCustomerDataPayload(marketScan);
        this.upsertMarketScans(marketScan);
        await this.updateMarketScanInPouchDB(marketScan);
        marketScan.isModified = false;
        this.setCurrentMarketScan(marketScan);
    }

    // private async addMissingCustomerData(marketScan: MarketScan) {
    //   const subrows = this.getSubrows(marketScan);
    //   const columns = this.getColumns(marketScan);
    //   const data = await this.constructTableData(this.rowData, columns, subrows, 0, marketScan.contacts.length);
    //   marketScan = data.marketScan;
    //   marketScan.marketScanCustomerDatas = this.prepareCustomerDataReqPayload(marketScan, this.rowData, 0, marketScan.contacts.length);
    //   return marketScan;
    // }

    async scrapMarketScan(marketScan: MarketScan) {
        const selectedMarketScanId = marketScan.indskr_marketscanid;
        if (this.deviceService.isOffline) {
            if (selectedMarketScanId) {
                marketScan.statuscode = MarketScanStatusCode.Cancelled;
                if (!marketScan.pendingPushToDynamics) {
                    await this.diskService.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
                    marketScan.pendingPushToDynamics = true;
                }
                //Service expects only id and status for scrapping record
                const scrapRecord = {
                    indskr_externalid: marketScan.indskr_externalid,
                    indskr_marketscanid: marketScan.indskr_marketscanid,
                    statecode: MarketScanStateCode.InActive,
                    statuscode: MarketScanStatusCode.Cancelled,
                    pendingPushToDynamics: true
                };
                return this.updateMarketScanInPouchDB(<MarketScan>scrapRecord).then(() => {
                    this.removeMarketScanFromUI(marketScan.indskr_externalid);
                }).catch(() => {
                    marketScan.statuscode = MarketScanStatusCode.Completed;
                });
            } else {
                //If offline created, offline scrap
                if (marketScan.pendingPushToDynamics) {
                    await this.diskService.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
                }
                return this.scrapMarketScanInPouchDB(marketScan.indskr_externalid);
            }
        } else {
            if (selectedMarketScanId) {
                await this.marketScanDataService.scrapMarketScan(selectedMarketScanId);
            }
            return this.scrapMarketScanInPouchDB(marketScan.indskr_externalid);
        }
    }

    public async updateMarketScan(marketScan: MarketScan) {
      //marketScan = await this.addMissingCustomerData(marketScan);
      //this.spliceDeletableObjects(this.marketScanCopy, marketScan);
      if (this.deviceService.isOffline) {
        if (!marketScan.pendingPushToDynamics)
          this.diskService.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
        marketScan.pendingPushToDynamics = true;
        this.marketScanCopy = cloneDeep(marketScan);
      } else {
        marketScan.statuscode = MarketScanStatusCode.Completed;
        this.upsertMarketScans(marketScan);
        const onlyChangedData = this._getMarketScanServicePayload(marketScan)//this.prepareRequestBodyWithOnlyChangedDataForUpdate(marketScan);
        await this.marketScanDataService.updateMarketScan(onlyChangedData).then(async (res) => {
          if (res && res['indskr_marketscanid']) {
            marketScan.pendingPushToDynamics = false;
            marketScan.lastUpdatedTime = new Date().getTime().toString();
            marketScan.indskr_marketscanid = res['indskr_marketscanid'];
            this.removeDELETEActionObjs(marketScan);
            this.marketScanCopy = cloneDeep(marketScan);
          }
        }).catch(err => {
          console.error("Failed to update marketscan due to: ", err);
          marketScan.statuscode = MarketScanStatusCode.PendingSync;
          marketScan.pendingPushToDynamics = true;
          this.diskService.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
        })
      }
      this.createUpdateMarketScanCustomerDataPayload(marketScan);
      this.upsertMarketScans(marketScan);
      await this.updateMarketScanInPouchDB(marketScan);
      marketScan.isModified = false;
      this.setCurrentMarketScan(marketScan);
    }

    private prepareRequestBodyWithOnlyChangedDataForUpdate(marketScan: MarketScan): MarketScan {
        if (!this.marketScanCopy || this.marketScanCopy.indskr_externalid != marketScan.indskr_externalid) {
            //Just in case, copy doesn't have same market scan
            return marketScan;
        } else if (this.marketScanCopy.statuscode === MarketScanStatusCode.Active && marketScan.statuscode === MarketScanStatusCode.Completed) {
            return marketScan; //To handle app kill scenario, db will have latest data
        } else if (marketScan.pendingPushToDynamics) {
            return marketScan;
        }
        //update request body should have only changed related entities data along with meta data, as it can make performance hit
        let onlyChangedData: MarketScan = new MarketScan(marketScan);
        onlyChangedData.therapeuticAreas.push(..._.differenceWith(marketScan.therapeuticAreas, this.marketScanCopy.therapeuticAreas, _.isEqual));
        onlyChangedData.products.push(..._.differenceWith(marketScan.products, this.marketScanCopy.products, _.isEqual));
        onlyChangedData.customerSegmentations.push(..._.differenceWith(marketScan.customerSegmentations, this.marketScanCopy.customerSegmentations, _.isEqual));
        onlyChangedData.contacts.push(..._.differenceWith(marketScan.contacts, this.marketScanCopy.contacts, _.isEqual));
        onlyChangedData.accounts.push(..._.differenceWith(marketScan.accounts, this.marketScanCopy.accounts, _.isEqual));
        const customerData: MarketScanCustomerData[] = _.differenceWith(marketScan.marketScanCustomerDatas, this.marketScanCopy.marketScanCustomerDatas, _.isEqual);
        let onlyChangedCustomerData: MarketScanCustomerData[] = [];
        // customerData.forEach((cd: MarketScanCustomerDatas) => {
        //   const existingData = this.marketScanCopy.marketScanCustomerDatas?.find(c => (c.contactId && c.contactId == cd.contactId) || (c.accountId && c.accountId === cd.accountId));
        //   if(this.marketScanCopy.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS_ACCOUNTS){
        //     const existingData = this.marketScanCopy.marketScanCustomerDatas?.find(cust => cust.contactId === cd.contactId && cust.data[0].accountId === cd.accountId);
        //   }
        //   if (existingData) {
        //     if (cd.sequence != existingData.sequence) {
        //       onlyChangedCustomerData.push(new MarketScanCustomerDatas(cd.accountId, cd.contactId, cd.sequence, cd.data));
        //     } else {
        //       existingData.data.filter(d => !d.action).map(d => d.action = Action.PATCH);
        //       cd.data.filter(d => !d.action).map(d => d.action = Action.PATCH);
        //       const changedCustomerData: Data[] = _.differenceWith(cd.data, existingData.data, _.isEqual);
        //       if (changedCustomerData.length) {
        //         const customerData = new MarketScanCustomerDatas(cd.accountId, cd.contactId, cd.sequence, changedCustomerData);
        //         onlyChangedCustomerData.push(customerData);
        //       }
        //     }
        //   } else {
        //     onlyChangedCustomerData.push(cd);
        //   }
        // });
        onlyChangedData.marketScanCustomerDatas.push(...onlyChangedCustomerData);
        delete onlyChangedData.therapeuticAreas;
        delete onlyChangedData.products;
        delete onlyChangedData.contacts;
        delete onlyChangedData.accounts;
        return onlyChangedData;
    }

    removeDELETEActionObjs(marketScan: MarketScan) {
      if (marketScan.category) {
        _.remove(marketScan.category.measures, m => m.action === Action.DELETE);
      }
      //if (!_.isEmpty(marketScan.selectedColumnData))
        //_.remove(marketScan.selectedColumnData, sc => sc.action === Action.DELETE);
      _.remove(marketScan.therapeuticAreas, ta => ta.action === Action.DELETE);
      _.remove(marketScan.products, p => p.action === Action.DELETE);
      _.remove(marketScan.customerSegmentations, cs => cs.action === Action.DELETE);
      _.remove(marketScan.contacts, c => c.action === Action.DELETE);
      _.remove(marketScan.accounts, c => c.action === Action.DELETE);
      if (marketScan.marketScanCustomerDatas) {
        _.remove(marketScan.marketScanCustomerDatas, c => c.action === Action.DELETE);
        marketScan.marketScanCustomerDatas.forEach(c => {
          c.action = null;
        });
        // marketScan.marketScanCustomerDatas.forEach((cData, index) => {
        //   _.remove(cData.data, cp => cp.action === Action.DELETE);
        // });
        // marketScan.marketScanCustomerDatas = marketScan.marketScanCustomerDatas.filter(cData => !_.isEmpty(cData.data));
      }
    }

    // spliceDeletableObjects(existing: MarketScan, current: MarketScan) {
    //   let contactAccountIdToRemove: string[] = [];
    //   existing.marketScanCustomerDatas?.forEach((ex) => {
    //     current.marketScanCustomerDatas.forEach((cur) => {
    //       //If existing contact/account has all DELETE actions, splice only newly added objects
    //       if (((cur.contactId && cur.contactId == ex.contactId) || (cur.accountId && cur.accountId == ex.accountId))
    //         && _.some(cur.data, ['action', Action.DELETE])) {
    //         const currentData = JSON.parse(JSON.stringify(cur.data));
    //         cur.data.forEach((data) => {
    //           let o = ex.data.find(d => d.indskr_externalid === data.indskr_externalid);
    //           //category is not updated and extra data is present remove
    //           if (!o && existing.category.indskr_assessmentcategoryid === current.category.indskr_assessmentcategoryid && data.action === Action.DELETE) {
    //             let idx = _.findIndex(currentData, ['indskr_externalid', data.indskr_externalid]);
    //             if (idx > -1) currentData.splice(idx, 1);
    //           }
    //         });
    //         cur.data = currentData;
    //       }
    //       //For Newly added contacts/accounts has some DELETE actions, change action to PATCH
    //       if (existing.marketScanCustomerDatas.find(ex => ex.contactId !== cur.contactId && ex.accountId !== cur.accountId)
    //         && _.some(cur.data, ['action', Action.DELETE])) {
    //         for (let i = cur.data.length - 1; i >= 0; i--) {
    //           if (cur.data[i].action == Action.DELETE) {
    //             cur.data.splice(i, 1);
    //           }
    //         }
    //       }
    //       if (_.isEmpty(cur.data)) {
    //         contactAccountIdToRemove.push(cur.contactId ? cur.contactId : cur.accountId);
    //       }
    //     });
    //   });
    //   if (!_.isEmpty(contactAccountIdToRemove))
    //     current.marketScanCustomerDatas = current.marketScanCustomerDatas.filter(cData => !contactAccountIdToRemove.includes(cData.contactId ? cData.contactId : cData.accountId));
    // }

    async scrapMarketScanInPouchDB(id: string) {
        await this.diskService.remove(DB_KEY_PREFIXES.MARKET_SCANS + id).then(() => {
            this.removeMarketScanFromUI(id);
        });
    }

    removeMarketScanFromUI(id: string) {
        let marketScans: MarketScan[] = this.marketScans.getValue();
        const index = marketScans.findIndex((ms) => ms.indskr_externalid === id);
        if (index >= 0) {
            marketScans.splice(index, 1);
            this.marketScans.next(marketScans);
        }
    }

    guidUtility() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public getStatusText(statuscode: number) {
        let statusText = '';
        switch (statuscode) {
            case MarketScanStatusCode.Active:
                statusText = this.translate.instant('OPEN');
                break;
            case MarketScanStatusCode.Completed:
                statusText = this.translate.instant('COMPLETED');
                break;
            case MarketScanStatusCode.PendingSync:
                statusText = this.translate.instant('PENDING_SYNC');
                break;
            case MarketScanStatusCode.Cancelled:
                statusText = this.translate.instant('CANCELLED');
                break;
            case MarketScanStatusCode.Expired:
                statusText = this.translate.instant('COMPLETED');
                break;
            default:
                statusText = this.translate.instant('OPEN');
        }
        return statusText;
    }

    public async getMarketScanDeltaById(marketScanId: string) {
        const startTime = new Date().getTime();
        await this.revertStatus();
        const index = this.marketScans.getValue().findIndex(mS => mS.indskr_externalid === marketScanId);
        let marketScan: MarketScan = null;
        let fetchFromDB: boolean = true;
        if (index >= 0) {
            marketScan = _.cloneDeep(this.marketScans.getValue()[index]);
            if (!this.deviceService.isOffline && marketScan.indskr_marketscanid && !marketScan.pendingPushToDynamics && !marketScan.isModified) {
                let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.GET_DELTA_MARKET_SCAN_BY_ID;
                url = url.replace('{marketScanId}', marketScan.indskr_marketscanid).replace('{lastUpdatedTime}', marketScan.lastUpdatedTime?marketScan.lastUpdatedTime:'0');
                if (this.fetchCampaignsAttribute || this.fetchEventsAttribute) {
                    url = url + '&captureData=events,campaigns';
                }
                const serviceStartTime = new Date().getTime();
                await this.marketScanDataService.getMarketScanDeltaById(url).then(async (data: MarketScan) => {
                    if (data) {
                        data = new MarketScan(data);
                        const endTime = new Date().getTime();
                        console.log("Time taken to service getMarketScanDeltaById: " + (endTime - serviceStartTime));
                        fetchFromDB = false;
                        data.lastUpdatedTime = new Date().getTime().toString();
                        //this.initializeLinkedEntities(data);
                        await this.diskService.upsertMarketScan(data);
                        this.upsertMarketScans(data);
                        marketScan = data;
                    }
                }).catch(er => {
                    console.error("Error occurred while fetching delta market scan by id", er);
                });
            }
            if (fetchFromDB) {
                const key = DB_KEY_PREFIXES.MARKET_SCANS + marketScanId;
                marketScan = await this.diskService.retrieve(key);
                marketScan = new MarketScan(marketScan);
                if(!marketScan){
                  const newkey = DB_KEY_PREFIXES.MARKET_SCANS + marketScan.indskr_marketscanid;
                  marketScan = await this.diskService.retrieve(newkey);
                }
                if (marketScan?.statuscode === MarketScanStatusCode.Active) {
                    if (marketScan.pendingPushToDynamics || (marketScan.indskr_marketscanid && marketScan.isModified)) {
                        //For appkill, Incase value has edited but not saved
                        marketScan.statuscode = MarketScanStatusCode.PendingSync;
                        if ((marketScan.indskr_marketscanid && marketScan.isModified) && !marketScan.pendingPushToDynamics) {
                            marketScan.pendingPushToDynamics = true;
                            this.diskService.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, 1);
                            await this.diskService.upsertMarketScan(marketScan); // to triggere sync
                        }
                    } else if (marketScan.indskr_marketscanid && !marketScan.isModified) {
                        //For appkill, Incase only status is changed but not modified
                        marketScan.statuscode = MarketScanStatusCode.Completed;
                    }
                }

            }
            this.mapCategory(marketScan);
            this.initializeLinkedEntities(marketScan);
            this.createUpdateMarketScanCustomerDataPayload(marketScan);
            this.upsertMarketScans(marketScan);
            //this.mapSelectedColumnData(marketScan);
            if (marketScan.statuscode === MarketScanStatusCode.Active) {
                await this.checkForInActiveEntitites(marketScan);
            }
        }
        if (this.isUnderAllowedDurationForModification(marketScan.indskr_date)) {
            this.setInactiveFlagForContacts(marketScan);
            this.setInactiveFlagForAccounts(marketScan);
        }
        const endTime = new Date().getTime();
        console.log("Time taken to getMarketScanDeltaById: " + (endTime - startTime));
        return marketScan;
    }

    private async revertStatus() {
        const marketScan = this.getSelectedMarketScan();
        if (marketScan && this.marketScanCopy && marketScan.indskr_externalid === this.marketScanCopy.indskr_externalid) {
            //For already synced records
            if (marketScan.indskr_marketscanid) {
                if (marketScan.pendingPushToDynamics && marketScan.statuscode !== MarketScanStatusCode.PendingSync) {
                    if (!marketScan.isModified) {
                        marketScan.statuscode = MarketScanStatusCode.PendingSync;
                        this.upsertMarketScans(marketScan);
                        await this.updateMarketScanInPouchDB(marketScan);
                    } else if (this.marketScanCopy.statuscode === MarketScanStatusCode.PendingSync) {
                        this.marketScanCopy.pendingPushToDynamics = marketScan.pendingPushToDynamics;
                        this.upsertMarketScans(this.marketScanCopy);
                        await this.updateMarketScanInPouchDB(this.marketScanCopy);
                    }
                } else if (marketScan.statuscode === MarketScanStatusCode.Active && this.marketScanCopy.statuscode === MarketScanStatusCode.Completed) {
                    this.upsertMarketScans(this.marketScanCopy);
                    await this.updateMarketScanInPouchDB(this.marketScanCopy);
                }
            } else if (marketScan.statuscode === MarketScanStatusCode.Active && this.marketScanCopy.statuscode === MarketScanStatusCode.PendingSync) {
                //For offline, not synced records
                this.upsertMarketScans(this.marketScanCopy);
                await this.updateMarketScanInPouchDB(this.marketScanCopy);
            }
        }
    }

    // private mapSelectedColumnData(marketScan: MarketScan) {
    //     //For completed records set columnData, for other status DB will be holding data
    //     if ((marketScan.statuscode === MarketScanStatusCode.Completed) || (marketScan.statuscode === MarketScanStatusCode.Expired)) {
    //         if (marketScan.category && marketScan.category.indskr_capturedata == CaptureData.PRODUCT) {
    //             marketScan.selectedColumnData = marketScan.products.map(p => new ColumnData(p.productId, p.productName, p.action));
    //             return;
    //         }
    //         const selectedColumns: ColumnData[] = [];
    //         marketScan.marketScanCustomerDatas && marketScan.marketScanCustomerDatas[0].data.forEach(d => {
    //             if (!_.isEmpty(d.productId)) {
    //                 selectedColumns.push(new ColumnData(d.productId, marketScan.products.find(p => p.productId === d.productId).productName, d.action ? d.action : Action.PATCH));
    //             }
    //             else if (!_.isEmpty(d.indskr_campaignid)) {
    //                 selectedColumns.push(new ColumnData(d.indskr_campaignid, d.campaignName, d.action ? d.action : Action.PATCH));
    //             }
    //             else if (!_.isEmpty(d.indskr_eventid)) {
    //                 selectedColumns.push(new ColumnData(d.indskr_eventid, d.eventName, d.action ? d.action : Action.PATCH));
    //             }
    //             else if (!_.isEmpty(d.accountId)) {
    //               selectedColumns.push(new ColumnData(d.accountId, d.accountName, d.action ? d.action : Action.PATCH));
    //             }
    //         });
    //         marketScan.selectedColumnData = _.uniqBy(selectedColumns, obj => obj.id);
    //     }
    // }

    private mapCategory(marketScan: MarketScan) {
        if (marketScan.category) {
            const index = this.categories.findIndex(c => c.indskr_assessmentcategoryid === marketScan.category.indskr_assessmentcategoryid);
            if (index === -1) {
              if (!marketScan.category.indskr_name.includes(" (" + this.translate.instant('INACTIVE') + ")"))
                marketScan.category.indskr_name += " (" + this.translate.instant('INACTIVE') + ")";
            } else {
              marketScan.category.indskr_name = this.categories[index].indskr_name;
            }
        }else if (marketScan.categoryId) {
          const index = this.categories.findIndex(c => c.indskr_assessmentcategoryid === marketScan.categoryId);
          if (index === -1) {
            if (!marketScan.category.indskr_name.includes(" (" + this.translate.instant('INACTIVE') + ")"))
              marketScan.category.indskr_name += " (" + this.translate.instant('INACTIVE') + ")";
          } else {
            marketScan.category = this.categories[index];
          }
        }  else {
            const validCategoryDataIndex = this.getValidCategory(marketScan);
            if (validCategoryDataIndex && validCategoryDataIndex.cDataIndex >= 0) {
                const validCustomerData: MarketScanCustomerData = marketScan.marketScanCustomerDatas[validCategoryDataIndex.cDataIndex];
                // const categoryId = validCustomerData.data[validCategoryDataIndex.dataIndex].indskr_assessmentcategoryid;
                // let categoryName = validCustomerData.data[validCategoryDataIndex.dataIndex].categoryName;
                const categoryId = validCustomerData.indskr_assessmentcategoryid;
                let categoryName = validCustomerData.categoryName;
                //Default capture data for = CUSTOMER
                //const captureDataFor = validCustomerData.data[validCategoryDataIndex.dataIndex].indskr_capturedatafor ? validCustomerData.data[validCategoryDataIndex.dataIndex].indskr_capturedatafor : CaptureDataFor.CUSTOMERS;
                const captureDataFor = validCustomerData.indskr_capturedatafor ? validCustomerData.indskr_capturedatafor : CaptureDataFor.CUSTOMERS;
                if (categoryId) {
                    // const index = this.categories.findIndex(c => c.indskr_assessmentcategoryid === validCustomerData.data[validCategoryDataIndex.dataIndex].indskr_assessmentcategoryid);
                    const index = this.categories.findIndex(c => c.indskr_assessmentcategoryid === validCustomerData.indskr_assessmentcategoryid);
                    const measures: Measure[] = [];
                    if (index === -1) {
                      if (!categoryName.includes(" (" + this.translate.instant('INACTIVE') + ")"))
                        categoryName += " (" + this.translate.instant('INACTIVE') + ")";
                    } else {
                      categoryName = this.categories[index].indskr_name;
                    }
                    _.unionBy(marketScan.marketScanCustomerDatas, d => d.measureId).forEach(d => {
                        const foundMeasure = this.categories[index].measures.find(m=> m.measureId == d.measureId);
                        const measureCaptureFor = foundMeasure.captureFor;
                        const measureType = foundMeasure.measureType
                        const measure = new Measure(d.measureName, d.measureId, measureType, d.action ? d.action : Action.PATCH, measureCaptureFor);
                        measures.push(measure);
                    });
                    marketScan.category = new Category(categoryId, categoryName, validCustomerData.indskr_capturedata, captureDataFor, measures);
                }
            }
        }
    }

    private getValidCategory(marketScan: MarketScan) {
        //get first category with action PATCH, in case of offline it can customer data can contain previously selected category data with action deleted
        let cDataIndex: number = -1;
        //let dataIndex: number = -1;
        if (!_.isEmpty(marketScan.marketScanCustomerDatas)) {
          cDataIndex = marketScan.marketScanCustomerDatas.findIndex(d => d.action != Action.DELETE);
            // for (let i = 0; i < marketScan.marketScanCustomerDatas.length; i++) {
            //     const index = marketScan.marketScanCustomerDatas[i].data.findIndex(d => d.action != Action.DELETE);
            //     if (index >= 0) {
            //         cDataIndex = i;
            //         dataIndex = index;
            //         break;
            //     }
            // }
        }
        return { cDataIndex: cDataIndex };
    }

    private initializeLinkedEntities(data: MarketScan) {
        //data.therapeuticAreas = !_.isEmpty(data.therapeuticAreas) ? data.therapeuticAreas.map((ta) => new MarketScanTA(ta.therapeuticAreaId, ta.therapeuticAreaName, ta.action ? ta.action : Action.PATCH)) : [];
        // data.products = !_.isEmpty(data.products) ? data.products.map((p) => new MarketScanProduct(p.productId, p.productName,p.isCompetitorProduct, p.action ? p.action : Action.PATCH)) : [];
        // data.customerSegmentations = !_.isEmpty(data.customerSegmentations) ? data.customerSegmentations.map((cs) => new MarketScanCustomerSegment(cs.segmentName, cs.customerSegmentationId, cs.action ? cs.action : Action.PATCH)) : [];
        // if (!_.isEmpty(data.contacts)) {
        //     const contactsWithSeq: MarketScanContact[] = data.contacts.filter(v => v.sequence != null);
        //     const contactWithoutSeq: MarketScanContact[] = data.contacts.filter(v => v.sequence == null);
        //     const _contacts = _.orderBy(contactsWithSeq, ob => ob.sequence, ['desc']);
        //     _contacts.push(...this.sortListByFielName(contactWithoutSeq, 'contact_fullName'));
        //     data.contacts = _contacts.map((c) => new MarketScanContact(c.contact_fullName, c.contactId, c.action ? c.action : Action.PATCH, c.sequence));
        // } else {
        //     data.contacts = [];
        // }
        // data.accounts = !_.isEmpty(data.accounts) ? _.orderBy(data.accounts.map(ac => new MarketScanAccount(ac.accountName, ac.accountId, ac.action ? ac.action : Action.PATCH, ac.sequence, true, ac.affiliatedContactIDs)), obj => obj.sequence) : [];
        if(!data) return;
        if(!_.isEmpty(data.marketScanCustomerDatas) && _.isEmpty(data.products)){
          let products:Array<MarketScanProduct> = [];
          let accounts:Array<MarketScanAccount> = [];
          let contacts:Array<MarketScanContact> = [];
          let competitors:Array<MarketScanCompetitor> = [];

          const accountcontactidpair :Array <{
            contactid:string,
            accountid:string,
          }> = [];
          data.marketScanCustomerDatas.forEach(item => {
            if(item.action != Action.DELETE){
              if(item.productId && !products.some(p => p.productId == item.productId)){
                let foundBrand;
                if(data.category && data.category.indskr_capturedatafor == CaptureDataFor.COMPETITOR){
                  foundBrand = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.find(({surgeryId}) => surgeryId === item.productId);
                  if(foundBrand) products.push(new MarketScanProduct(foundBrand.surgeryId, foundBrand.surgeryName,false, Action.PATCH));
                } else {
                  foundBrand = this.brands.find(b=> b.ID == item.productId);
                  if(foundBrand){
                    products.push(new MarketScanProduct(foundBrand.ID, foundBrand.name,foundBrand.isCompetitorProduct == 'true', Action.PATCH));
                  }
                }
              }
              if(data.category && data.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
                let idx = accountcontactidpair.findIndex(m=> m.accountid == item.accountId && m.contactid == item.contactId);
                if(idx < 0){
                  accountcontactidpair.push({accountid:item.accountId,contactid:item.contactId});
                }
              }else{
                if(item.accountId && !accounts.some(a => a.accountId == item.accountId)){
                  let foundAccount = this.accountService.getAccountById(item.accountId);
                  if(foundAccount){
                    accounts.push(new MarketScanAccount(foundAccount.accountName, foundAccount.id, Action.PATCH));
                  }
                }
                if(item.contactId && !contacts.some(c => c.contactId == item.contactId)){
                  let foundContact = this.contactService.getContactByID(item.contactId);
                  if(foundContact){
                    contacts.push(new MarketScanContact(foundContact.fullName,foundContact.ID, Action.PATCH));
                  }
                }
                if(item.competitorId && !competitors.some(c => c.competitorId == item.competitorId)){
                  let foundCompetitor = this.opportunityService.opportunityCompetitors.find(({id}) => item.competitorId == id);
                  // let foundContact = this.contactService.getContactByID(item.contactId);
                  if(foundCompetitor){
                    competitors.push(new MarketScanCompetitor(foundCompetitor.name, foundCompetitor.id,Action.PATCH));
                  }
                }
              }
            }
          })
          if(data.category && data.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
            if(data.selectView && data.selectView == 'CUSTOMER_VIEW'){
              accountcontactidpair.forEach(pair => {
                if(pair.contactid){
                  let idx = contacts.findIndex(c => c.contactId == pair.contactid)
                  if(idx >= 0){
                    if(pair.accountid){
                      let newIdx  = contacts[idx].affiliatedAccounts.findIndex(aa=> aa.accountId == pair.accountid)
                      if(newIdx < 0){
                        let foundAccount = this.accountService.getAccountById(pair.accountid);
                        if(foundAccount){
                          contacts[idx].affiliatedAccounts.push(new MarketScanAccount(foundAccount.accountName, foundAccount.id, Action.PATCH));
                        }
                      }
                    }
                  }else{
                    let foundContact = this.contactService.getContactByID(pair.contactid);
                    let msCon = new MarketScanContact(foundContact.fullName,foundContact.ID, Action.PATCH);
                    if(pair.accountid){
                      let foundAccount = this.accountService.getAccountById(pair.accountid);
                      if(foundAccount){
                        msCon.affiliatedAccounts.push(new MarketScanAccount(foundAccount.accountName, foundAccount.id, Action.PATCH));
                      }
                    }
                    contacts.push(msCon);
                  }
                }
              })
            }else{
              data.selectView = 'ACCOUNT_VIEW';
              accountcontactidpair.forEach(pair => {
                if(pair.accountid){
                  let idx = accounts.findIndex(c => c.accountId == pair.accountid)
                  if(idx >= 0){
                    if(pair.contactid){
                      let newIdx  = accounts[idx].affiliatedContacts.findIndex(aa=> aa.contactId == pair.contactid)
                      if(newIdx < 0){
                        let foundContact = this.contactService.getContactByID(pair.contactid);
                        if(foundContact){
                          accounts[idx].affiliatedContacts.push(new MarketScanContact(foundContact.fullName, foundContact.ID, Action.PATCH));
                        }
                      }
                    }
                  }else{
                    let foundAccount = this.accountService.getAccountById(pair.accountid);
                    let msAcc = new MarketScanAccount(foundAccount.accountName,foundAccount.id, Action.PATCH);
                    if(pair.contactid){
                      let foundContact = this.contactService.getContactByID(pair.contactid);
                      if(foundContact){
                        msAcc.affiliatedContacts.push(new MarketScanContact(foundContact.fullName, foundContact.ID, Action.PATCH));
                      }
                    }
                    accounts.push(msAcc);
                  }
                }
              })
            }
          }
          data.products = products;
          data.accounts = accounts;
          data.contacts = contacts;
          data.competitors = competitors;
        }
    }

    public async checkForInActiveEntitites(marketScan: MarketScan) {
        let foundInActive = false;
        if (this.isUnderAllowedDurationForModification(marketScan.indskr_date) && this.authService.user.systemUserID === marketScan.ownerId) {
            foundInActive = await this.removeInActiveEntities(marketScan);
            if (foundInActive) {
                this.notificationService.notify(this.translate.instant("REMOVED_DEACTIVATED").replace("{{activityType}}", String(this.translate.instant("CUSTOMER_SCAN")).toLowerCase()), 'Market Scan Detail', 'top', ToastStyle.INFO);
                if (marketScan.indskr_marketscanid) {
                    //For removing deactivated data, to enable update btn
                    marketScan.isModified = true;
                }
                await this.updateMarketScanInPouchDB(marketScan);
            }
        }
        return foundInActive;
    }

    private async removeInActiveEntities(marketScan: MarketScan) {
        let foundInActiveDetails: boolean = false;
        foundInActiveDetails = this.removeInActiveCategory(marketScan, foundInActiveDetails);
        // if (!foundInActiveDetails)
        //     foundInActiveDetails = await this.removeInActiveTA(marketScan, foundInActiveDetails);
        if (!foundInActiveDetails)
            foundInActiveDetails = marketScan.category.indskr_capturedatafor == CaptureDataFor.COMPETITOR ? await this.removeInActiveProcedures(marketScan, foundInActiveDetails) :  await this.removeInActiveProducts(marketScan, foundInActiveDetails);
        if (!foundInActiveDetails)
            foundInActiveDetails = await this.removeInActiveCustomerSegmenations(marketScan, foundInActiveDetails);
        if (foundInActiveDetails) {
            this.removeInActiveMarketCustomerData(marketScan);
        }
        return foundInActiveDetails;
    }

    removeInActiveCategory(marketScan: MarketScan, foundInActiveDetails: boolean) {
        if (!_.isEmpty(marketScan.category)) {
            const categoryId = marketScan.category.indskr_assessmentcategoryid;
            let categoryName = marketScan.category.indskr_name;
            if (categoryId) {
                const index = this.categories.findIndex(c => c.indskr_assessmentcategoryid === marketScan.category.indskr_assessmentcategoryid);
                if (index === -1 && !categoryName.includes(" (" + this.translate.instant('INACTIVE') + ")"))
                    categoryName += " (" + this.translate.instant('INACTIVE') + ")";
                let measures: Measure[] = [];
                if (!_.isEmpty(marketScan.marketScanCustomerDatas)) {
                    const validCategoryDataIndex = this.getValidCategory(marketScan);
                    if (validCategoryDataIndex && validCategoryDataIndex.cDataIndex >= 0) {
                        const validCustomerData: MarketScanCustomerData = marketScan.marketScanCustomerDatas[validCategoryDataIndex.cDataIndex];
                        _.unionBy(marketScan.marketScanCustomerDatas, d => d.measureId).forEach(d => {
                            const foundMeasure = this.categories[index].measures.find(m=> m.measureId == d.measureId);
                            const measureCaptureFor = foundMeasure.captureFor;
                            const measureType = foundMeasure.measureType;
                            measures.push(new Measure(d.measureName, d.measureId, measureType, d.action ? d.action : Action.PATCH, measureCaptureFor));
                        });
                    }
                } else {
                    measures = marketScan.category.measures;
                }
                if (index >= 0) {
                    categoryName = this.categories[index].indskr_name;
                }
                if (index === -1) {
                    marketScan.category = new Category(categoryId, categoryName, marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, measures);
                } else {
                    //Remove inactive measures
                    foundInActiveDetails = this.removeInActiveMeasures(index, marketScan, foundInActiveDetails, measures);
                    marketScan.category = new Category(categoryId, categoryName, marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, []);
                    if (marketScan.indskr_marketscanid) {
                        marketScan.category.measures = measures;
                    } else {
                        marketScan.category.measures = measures.filter(m => m.action !== Action.DELETE);
                    }
                }
            }
        }
        return foundInActiveDetails;
    }

    private removeInActiveMeasures(index: number, marketScan: MarketScan, foundInActiveDetails: boolean, measures: Measure[]) {
        if (index > -1) {
            if (_.isEmpty(marketScan.marketScanCustomerDatas))
                return foundInActiveDetails;
            const measureIds = this.categories[index].measures.map(measure => measure.measureId);

            marketScan.marketScanCustomerDatas.forEach(cData => {
                //const validData: Data[] = [];
                if (!measureIds.includes(cData.measureId)) {
                  foundInActiveDetails = true;
                  if (marketScan.indskr_marketscanid) {
                    cData.action = Action.DELETE;
                  }
                  const mIndex = measures.findIndex(m => m.measureId === cData.measureId);
                  if (mIndex > -1)
                  measures[mIndex].action = Action.DELETE;
                }
                // cData.data.filter(d => d.action != Action.DELETE).forEach(d => {
                //     //If measure is not present in current
                //     if (!measureIds.includes(d.measureId)) {
                //         foundInActiveDetails = true;
                //         if (marketScan.indskr_marketscanid) {
                //             d.action = Action.DELETE;
                //         }
                //         const mIndex = measures.findIndex(m => m.measureId === d.measureId);
                //         if (mIndex > -1)
                //             measures[mIndex].action = Action.DELETE;
                //     }
                //     else if (!marketScan.indskr_marketscanid && measureIds.includes(d.measureId)) {
                //         validData.push(d);
                //     }
                // });
                // if (!marketScan.indskr_marketscanid) {
                //     cData.data = validData;
                // }
            })
            return foundInActiveDetails;
        }
    }

    private async removeInActiveProducts(marketScan: MarketScan, foundInActiveDetails: boolean) {
        const availableProducts: string[] = this.brands.map(p => p.ID);
        if (!marketScan.indskr_marketscanid) {
            //Not saved in dynamics, remove TAs from list
            const inActiveProducts: MarketScanProduct[] = marketScan.products.filter(p => !availableProducts.includes(p.productId));
            if (inActiveProducts.length) {
                foundInActiveDetails = true;
                marketScan.products = _.difference(marketScan.products, inActiveProducts);
            }
        }
        else {
            //Saved TA in dynamics, add action as DELETE
            marketScan.products.filter(p => !availableProducts.includes(p.productId)).forEach(p => {
                foundInActiveDetails = true;
                p.action = Action.DELETE;
            });
        }
        if (foundInActiveDetails) {
            this.mapFiltersForProduct(marketScan);
        }
        return foundInActiveDetails;
    }

    private async removeInActiveProcedures(marketScan: MarketScan, foundInActiveDetails: boolean) {
      const availableProducts: string[] = this.surgeryOrderDataService.productHierarchies.map(p => p.surgeryId);
      if (!marketScan.indskr_marketscanid) {
          //Not saved in dynamics, remove TAs from list
          const inActiveProducts: MarketScanProduct[] = marketScan.products.filter(p => !availableProducts.includes(p.productId));
          if (inActiveProducts.length) {
              foundInActiveDetails = true;
              marketScan.products = _.difference(marketScan.products, inActiveProducts);
          }
      }
      else {
          //Saved TA in dynamics, add action as DELETE
          marketScan.products.filter(p => !availableProducts.includes(p.productId)).forEach(p => {
              foundInActiveDetails = true;
              p.action = Action.DELETE;
          });
      }
      if (foundInActiveDetails) {
          this.mapFiltersForProduct(marketScan);
      }
      return foundInActiveDetails;
  }

    private removeInActiveMarketCustomerData(marketScan: MarketScan) {
        const inActiveProductIds: string[] = marketScan.products.filter(p => p.action === Action.DELETE).map(p => p.productId);
        const activeEventIds: string[] = [];
        const activeCampaignIds: string[] = [];
        let activeAccountIds: string[] = [];
        if (marketScan.category.indskr_capturedata != CaptureData.PRODUCT && !_.isEmpty(inActiveProductIds)) {
            switch (marketScan.category.indskr_capturedata) {
                case CaptureData.EVENTS:
                    for (let productId in this.productToEventsMap) {
                        activeEventIds.push(...this.productToEventsMap[productId].map(ei => ei.msevtmgt_eventid));
                    }
                    break;
                case CaptureData.CAMPAIGN:
                    for (let productId in this.productToCampaignsMap) {
                        activeCampaignIds.push(...this.productToCampaignsMap[productId].map(ei => ei.msdyncrm_customerjourneyid));
                    }
                    break;
                default:
                    console.log("Unhandled case");
            }
        }
        const inActiveContactIds: string[] = marketScan.contacts.filter(c => c.action === Action.DELETE).map(c => c.contactId);
        if (marketScan.indskr_marketscanid && (inActiveContactIds.length || inActiveProductIds.length)) {
            if (marketScan.category.indskr_capturedata == CaptureData.ACCOUNTS && !_.isEmpty(inActiveContactIds)) {
              activeAccountIds.push(...this.contactToAccounts.map(a => a.accountId));
              activeAccountIds = _.uniqWith(activeAccountIds, _.isEqual);
            }
            marketScan.marketScanCustomerDatas.forEach(record => {
                if (inActiveContactIds.includes(record.contactId)) {
                    //If contact is removed, all rows of that contact should be deleted
                    record.action = Action.DELETE;
                }
                //else {
                  //  contact.data.forEach(d => {
                        switch (marketScan.category.indskr_capturedata) {
                            case CaptureData.EVENTS:
                                //If product is removed, all columns of that product should be deleted
                                if (!activeEventIds.includes(record.indskr_eventid)) {
                                    record.action = Action.DELETE;
                                }
                                break;
                            case CaptureData.CAMPAIGN:
                                //If product is removed, all columns of that product should be deleted
                                if (!activeCampaignIds.includes(record.indskr_campaignid)) {
                                    record.action = Action.DELETE;
                                }
                                break;
                          case CaptureData.ACCOUNTS:
                              //If product is removed, all columns of that product should be deleted
                              if (!activeAccountIds.includes(record.accountId)) {
                                record.action = Action.DELETE;
                              }
                              break;
                            default:
                                //If product is removed, all columns of that product should be deleted
                                if (inActiveProductIds.includes(record.productId)) {
                                    record.action = Action.DELETE;
                                }
                        }
                  //  })
                //}
            });
        }
    }

    private async removeInActiveCustomerSegmenations(marketScan: MarketScan, foundInActiveDetails: boolean) {
        const availableCSs: string[] = this.customerSegmentations.map(cs => cs.indskr_customersegmentationid);
        if (!marketScan.indskr_marketscanid) {
            const inActiveCSs: MarketScanCustomerSegment[] = marketScan.customerSegmentations.filter(cs => !availableCSs.includes(cs.customerSegmentationId));
            if (inActiveCSs.length) {
                foundInActiveDetails = true;
                marketScan.customerSegmentations = _.difference(marketScan.customerSegmentations, inActiveCSs);
            }
        }
        else {
            marketScan.customerSegmentations.filter(cs => !availableCSs.includes(cs.customerSegmentationId)).forEach(cs => {
                foundInActiveDetails = true;
                cs.action = Action.DELETE;
            });
        }
        if (foundInActiveDetails) {
            this.mapFiltersForCustomerSegmentation(marketScan,false);
        }
        return foundInActiveDetails;
    }

    // private async removeInActiveTA(marketScan: MarketScan, foundInActiveDetails: boolean) {
    //     const availableTAs: string[] = this.therapeuticAreasMappedData.map(ta => ta.therapeuticareaid);
    //     if (!marketScan.indskr_marketscanid) {
    //         //Not saved in dynamics, remove TAs from list
    //         const inActiveTAs: MarketScanTA[] = marketScan.therapeuticAreas.filter(ta => !availableTAs.includes(ta.therapeuticAreaId));
    //         if (inActiveTAs.length) {
    //             foundInActiveDetails = true;
    //             marketScan.therapeuticAreas = _.difference(marketScan.therapeuticAreas, inActiveTAs);
    //         }
    //     }
    //     else {
    //         //Saved TA in dynamics, add action as DELETE
    //         marketScan.therapeuticAreas.filter(ta => !availableTAs.includes(ta.therapeuticAreaId)).forEach(ta => {
    //             foundInActiveDetails = true;
    //             ta.action = Action.DELETE;
    //         });
    //     }
    //     if (foundInActiveDetails) {
    //         this.mapFiltersForTAs(marketScan);
    //     }
    //     return foundInActiveDetails;
    // }

    public async purgeData(maxEndDateUnixTimestamp: number) {
        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.MARKET_SCANS,
                    $lte: DB_KEY_PREFIXES.MARKET_SCANS + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                'indskr_date': { $lte: maxEndDateUnixTimestamp.toString() }
            }
        };

        let deletedMarketScans = [];
        try {
            const rawMarketScans = await this.diskService.find(option);

            if (rawMarketScans && Array.isArray(rawMarketScans)) {
                deletedMarketScans = rawMarketScans.filter(raw => !_.isEmpty(raw.indskr_date)).map(raw => {
                    // Not using constructor to avoid unnecessary overhead
                    return { _id: raw._id, _deleted: true, _rev: raw._rev };
                });
            }
        } catch (error) {
            console.error('error occurred : purge market scans: ', error);
        }
        try {
            // Bulk save/update docs to DB
            if (deletedMarketScans.length)
                await this.diskService.bulk(deletedMarketScans);
        } catch (error) {
            console.error('error occurred :saving deleted market scans: ', error);
        }
    }

    public async uploadOfflineMarketScans(isPartialUpload = false, maxRecordCountForPartialUpload = 10): Promise<any> {
        if (!isPartialUpload && this.deviceService.isOffline) return;
        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.MARKET_SCANS,
                    $lte: DB_KEY_PREFIXES.MARKET_SCANS + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                'pendingPushToDynamics': { $eq: true }
            }
        };
        const marketScanSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.marketScan,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        let rawMarketScans: MarketScan[] = [];
        try {
            //Get all market scans, which has pendingPushToDynamics=true
            rawMarketScans = await this.diskService.find(option);
            if (!_.isEmpty(rawMarketScans)) {
                if (isPartialUpload) {
                    if (rawMarketScans.length > maxRecordCountForPartialUpload) {
                        rawMarketScans = rawMarketScans.splice(0, maxRecordCountForPartialUpload);
                    }
                }
                //While pushing to db, update Pending sync to Completed status code
                rawMarketScans.filter(marketScan =>
                    (marketScan.statuscode === MarketScanStatusCode.Active || marketScan.statuscode === MarketScanStatusCode.PendingSync)).forEach(marketScan => {
                        if (this.isExpired(marketScan.indskr_date) && marketScan.indskr_marketscanid) {
                            //Dynamics might have marked record as expired
                            marketScan.statecode = MarketScanStateCode.InActive;
                            marketScan.statuscode = MarketScanStatusCode.Expired;
                        } else {
                            marketScan.statuscode = MarketScanStatusCode.Completed
                        }
                    });
                const uploadTime = new Date().getTime().toString();
                //let rawMarketScanCopy = cloneDeep(rawMarketScans);
                const payload = rawMarketScans.map(item => {
                  return this._getMarketScanServicePayload(item);
                })
                await this.marketScanDataService.uploadOfflineMarketScan(payload).then((response) => {
                    if (response) {
                        for (let i = 0; i < response.length; i++) {
                            const data = response[i];
                            const indskr_externalid = data['indskr_externalid'];
                            const index = rawMarketScans.findIndex(mS => mS.indskr_externalid === indskr_externalid);
                            if (index >= 0) {
                                //errorId will bre returned if it fails to save in dynamics
                                if (data['errorId']) {
                                    marketScanSyncInfo.totalFailed++;
                                    rawMarketScans[index].statuscode = MarketScanStatusCode.PendingSync;
                                    //If invalid marketscan customer data action is DELETE
                                    if (data['errorCode'] === 'ERR_MARKET_SCAN_DELETE_01') {
                                        //Remove all deleted customer data
                                        rawMarketScans[index].marketScanCustomerDatas = rawMarketScans[index].marketScanCustomerDatas.filter(customerData => customerData.action === Action.PATCH);
                                        // rawMarketScans[index].marketScanCustomerDatas.forEach(customerData => {
                                        //     let onlyPatchData = customerData.data.filter(data => data.action === Action.PATCH);
                                        //     customerData.data.push(...onlyPatchData);
                                        // });
                                    }
                                }
                                else if (data['indskr_marketscanid'] && !data['errorId']) {
                                    this.removeDELETEActionObjs(rawMarketScans[index]);
                                    rawMarketScans[index].indskr_marketscanid = data['indskr_marketscanid'];
                                    rawMarketScans[index].pendingPushToDynamics = false;
                                    marketScanSyncInfo.totalSynced++;
                                }
                            }
                        }
                      //If flag is set false, it is saved successfully to dynamics
                      const savedMarketScans = rawMarketScans.filter(marketScan => !marketScan.pendingPushToDynamics);
                      this.mapDeltaSyncedMarketScans(savedMarketScans, uploadTime);
                      // Track offline data count
                      const newCount = rawMarketScans.filter(marketScan => marketScan.pendingPushToDynamics);
                      this.diskService.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MARKET_SCANS, newCount ? newCount.length : 0);
                      this.deltaService.addEntitySyncInfo(marketScanSyncInfo, true);
                    }
                })
            }
        } catch (err) {
            marketScanSyncInfo.totalFailed = rawMarketScans?.length;
            console.error("Failed to upload offline marketscan: ", err);
            this.deltaService.addSyncErrorToEntitySyncInfo(marketScanSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.OFFLINE_UPLOAD_MARKET_SCAN, err);
            this.deltaService.addEntitySyncInfo(marketScanSyncInfo, true);
        }
    }

    private isExpired(date: string): boolean {
        const allowedUpdateDuration: string = moment(new Date(parseInt(date))).endOf('month').add(this.authService.user.marketScanUpdateDuration, 'days').format();
        return new Date(allowedUpdateDuration) < new Date();
    }

    public getEndDateAsPerConf(startDate: Date): Date {
        let endDate = null;
        switch (this.authService.user.marketScanCreateFrequency) {
            case CreateFrequency.WEEKLY:
                endDate = moment(startDate).add(6, 'days').format();
                break;
            case CreateFrequency.DAILY:
                endDate = startDate;
                break;
            case CreateFrequency.MONTHLY:
                endDate = moment(startDate).endOf('month').format();
                break;
            default:
                endDate = moment(startDate).endOf('month').format();
        }
        return new Date(endDate);
    }

    public getErrorMessageAsPerConf(startDate: Date, categoryName: string, isRecordCapturedAt? : string): string {
      let errorMessage: string = null;
      switch (this.authService.user.marketScanCreateFrequency) {
        case CreateFrequency.WEEKLY:
          errorMessage = isRecordCapturedAt ? "CUSTOMER_SCAN_FOR_WEEK_ALREADY_EXISTS_WITH_CATEGORY_WITH_ACCOUNTS" : "CUSTOMER_SCAN_FOR_WEEK_ALREADY_EXISTS_WITH_CATEGORY";
          errorMessage = this.translate.instant(errorMessage, { category: categoryName });
          break;
        case CreateFrequency.DAILY:
          errorMessage = isRecordCapturedAt ? "CUSTOMER_SCAN_FOR_DATE_ALREADY_EXISTS_WITH_CATEGORY_WITH_ACCOUNTS" : "CUSTOMER_SCAN_FOR_DATE_ALREADY_EXISTS_WITH_CATEGORY";
          errorMessage = this.translate.instant(errorMessage, { category: categoryName, date: this.getDaySuffix(moment(new Date(startDate)).format('D')), month: this.translate.instant(moment(new Date(startDate)).format('MMMM').toUpperCase()) });
          break;
        case CreateFrequency.MONTHLY:
          errorMessage = isRecordCapturedAt ? "CUSTOMER_SCAN_ALREADY_EXISTS_WITH_CATEGORY_WITH_ACCOUNTS" :  "CUSTOMER_SCAN_ALREADY_EXISTS_WITH_CATEGORY";
          errorMessage = this.translate.instant(errorMessage, { category: categoryName, month: this.translate.instant(moment(new Date(startDate)).format('MMMM').toUpperCase()) });
          break;
        default:
          errorMessage = isRecordCapturedAt ? "CUSTOMER_SCAN_ALREADY_EXISTS_WITH_CATEGORY_WITH_ACCOUNTS" :  "CUSTOMER_SCAN_ALREADY_EXISTS_WITH_CATEGORY";
          errorMessage = this.translate.instant("CUSTOMER_SCAN_ALREADY_EXISTS_WITH_CATEGORY", { category: categoryName });
      }
      return errorMessage;
    }

    private getDaySuffix(day: string) {
        const i = parseInt(day)
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    public showDiscardChangesPopup() {
        return this.alertService.showAlert({
            title: this.translate.instant('DISCARD_CHANGES'),
            message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
        }, this.translate.instant('DISCARD')
        );
    }

    private setInactiveFlagForContacts(marketScan: MarketScan) {
      if(marketScan.contacts && marketScan.contacts.length > 0){
        marketScan.contacts.forEach((c, index) => {
          const contact = this.contactService.getContactByID(c.contactId);
          if (!contact || !contact.isActive) {
              c.isActive = false;
          }
        });
      }
    }

    private setInactiveFlagForAccounts(marketScan: MarketScan) {
      if(marketScan.accounts && marketScan.accounts.length > 0){
        marketScan.accounts.forEach(ac => {
          const account = this.accountService.getAccountById(ac.accountId);
          if (!account || (account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)) {
            ac.isActive = false;
          }
        });
      }
    }

    private isUnderAllowedDurationForModification(date: string) {
        if (_.isEmpty(date)) return true;
        const allowedDuration = moment(new Date(parseInt(date))).endOf('month').add(this.authService.user.marketScanUpdateDuration, 'days').format();
        return new Date(allowedDuration) >= new Date();
    }

    isOwner() {
        return this.getSelectedMarketScan()
            && (this.authService.user.systemUserID === this.getSelectedMarketScan().ownerId);
    }

    sortListByFielName(options, fieldName: string) {
        if (_.isEmpty(options)) return [];
        return options.sort((a, b) => {
            let nameA: string = a[fieldName], nameB: string = b[fieldName];
            if (!nameA || !nameB) return 1;
            nameA = nameA.toLowerCase();
            nameB = nameB.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA >= nameB)
                return 1;
        });
    }

    // public async constructTableData(existingRows, columns, subrows: SubRowData[], contactStartIndex: number, contactEndIndex: number) {
    //   const selectedMarketScan = this.getSelectedMarketScan();
    //   const startTime = new Date().getTime();
    //   let slicedRows = [];
    //   let rows = [];
    //   if (selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
    //     selectedMarketScan.accounts = _.orderBy(selectedMarketScan.accounts, ob => ob.sequence, ['desc']);
    //     slicedRows = selectedMarketScan.accounts.filter(c => c.action == Action.PATCH).slice(contactStartIndex, contactEndIndex);
    //   }
    //   else{
    //     const contactsWithSeq: MarketScanContact[] = selectedMarketScan.contacts.filter(v => v.sequence != null);
    //     const contactWithoutSeq: MarketScanContact[] = selectedMarketScan.contacts.filter(v => v.sequence == null);
    //     selectedMarketScan.contacts = _.orderBy(contactsWithSeq, ob => ob.sequence, ['desc']);
    //     selectedMarketScan.contacts.push(...this.sortListByFielName(contactWithoutSeq, "contact_fullName"));
    //     slicedRows = selectedMarketScan.contacts.filter(c => c.action == Action.PATCH).slice(contactStartIndex, contactEndIndex);
    //   }
    //   if (_.isEmpty(slicedRows)) return { marketScan: selectedMarketScan, rows: [] };
    //   if(selectedMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
    //     let reqdAffiliations;
    //     let rawData = await this.diskService.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_CONTACT_AFFILIATION);
    //     if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
    //         reqdAffiliations = rawData.raw.filter(o=>{
    //           return o.hasOwnProperty('contactid')
    //                 && selectedMarketScan.contacts.some(cont=>cont.contactId == o['contactid'])
    //                 && o.hasOwnProperty('indskr_accountcontactaffiliation.indskr_accountid')
    //                 && selectedMarketScan.accounts.some(acc=>acc.accountId == o['indskr_accountcontactaffiliation.indskr_accountid'])
    //         })
    //     }
    //     let groupedAndSlicedRows: MarketScanContact[] = [];
    //     slicedRows.forEach(conRow=>{
    //       reqdAffiliations.forEach(aff => {
    //         if(aff['contactid'] == conRow.contactId){
    //           groupedAndSlicedRows.push({
    //             ...conRow,
    //             accountId: aff['indskr_accountcontactaffiliation.indskr_accountid'],
    //             accountName: aff['indskr_accountcontactaffiliation.indskr_accountid_Formatted']
    //           })
    //         }
    //       });
    //     })
    //     slicedRows = groupedAndSlicedRows;
    //   }
    //   if (selectedMarketScan.marketScanCustomerDatas && selectedMarketScan.marketScanCustomerDatas.length > 0) {
    //     // generates row data if the marketscandata array has the data already
    //     rows = this.updateRowData(selectedMarketScan, columns, subrows, existingRows, slicedRows);
    //   } else {
    //     selectedMarketScan.marketScanCustomerDatas = [];
    //     // push empty subrow data for the table for newly created market scan
    //     if (slicedRows) {
    //       rows = slicedRows.map((c) => {
    //         const rowData = this.getEmptySubRowData(columns, subrows);
    //         if (selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
    //           return { 'Customer': { id: c.accountId, name: c.accountName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': [...rowData] };
    //         } else if (selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS){
    //           return { 'Customer': { id: c.contactId, name: c.contact_fullName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': [...rowData] };
    //         } else{
    //           return { 'Customer': { id: c.accountId, name: c.accountName, groupId:c.contactId, groupName: c.contact_fullName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': [...rowData] };
    //         }
    //       });
    //     }
    //   }
    //   //Remove undefined items from list
    //   rows = _.compact(rows);
    //   if (existingRows.length > 0) {
    //     rows = existingRows.concat(rows);
    //   }
    //   // if (selectedMarketScan.statuscode !== MarketScanStatusCode.Completed && selectedMarketScan.statuscode !== MarketScanStatusCode.Expired && selectedMarketScan.statuscode !== MarketScanStatusCode.PendingSync) {
    //   //   selectedMarketScan.marketScanCustomerDatas = this.prepareCustomerDataReqPayload(selectedMarketScan, rows, contactStartIndex, contactEndIndex);
    //   //   await this.updateMarketScanInPouchDB(selectedMarketScan);
    //   // }
    //   this.rowData = rows;
    //   const endTime = new Date().getTime();
    //   console.log("Time taken to construct table: " + (endTime - startTime));
    //   return { marketScan: selectedMarketScan, rows: rows };
    // }

    // prepareCustomerDataReqPayload(marketScan: MarketScan, tableData, startIndex: number, endIndex: number) {
    //   const startTime = new Date().getTime();
    //   let selectedRows: MarketScanContact[] | MarketScanAccount[] = [];
    //   let existingCustData: MarketScanCustomerDatas[] = marketScan.marketScanCustomerDatas;
    //   if (marketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS) {
    //     selectedRows = marketScan.accounts.filter(ac => ac.action != Action.DELETE).slice(startIndex, endIndex);
    //   } else {
    //     selectedRows = marketScan.contacts.filter(c => c.action != Action.DELETE).slice(startIndex, endIndex);
    //   }
    //   if (_.isEmpty(selectedRows) && !_.isEmpty(existingCustData)) return existingCustData;
    //   let finalCustData: MarketScanCustomerDatas[] = [];
    //   const selectedColumns: ColumnData[] = marketScan.selectedColumnData.filter(sc => sc.action !== Action.DELETE);
    //   if(marketScan.category.indskr_capturedatafor !== CaptureDataFor.CUSTOMERS_ACCOUNTS){
    //     selectedRows.forEach(row => {
    //       const rowId = row.contactId ? row.contactId : row.accountId;
    //       const tableProducts = [];
    //       let index = tableData.findIndex(c => c['Customer']['id'] == rowId);
    //       if (index >= 0) {
    //         tableProducts.push(...tableData[index]['Products']);
    //       }
    //       let data: Data[] = [];
    //       selectedColumns.forEach(selectedColumn => {
    //         this.prepareCustomerData(tableProducts, marketScan, data, selectedColumn.id);
    //       });
    //       if (!_.isEmpty(data)) {
    //         let existingCData: MarketScanCustomerDatas = existingCustData.find(cust => cust.contactId === rowId || cust.accountId === rowId);
    //         if (existingCData) {
    //           data.forEach(d => {
    //             const index = existingCData.data.findIndex(ed => ed.indskr_externalid === d.indskr_externalid);
    //             if (index >= 0) {
    //               existingCData.data[index] = d;
    //             } else {
    //               existingCData.data.push(d);
    //             }
    //           });
    //           existingCData.sequence = row.sequence;
    //         } else {
    //           if (marketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
    //             finalCustData.push(new MarketScanCustomerDatas(rowId, null, row.sequence, data));
    //           } else {
    //             finalCustData.push(new MarketScanCustomerDatas(null, rowId, row.sequence, data));
    //           }
    //         }
    //       }
    //     });
    //   }
    //   else{
    //     let selectedContacts = selectedRows;
    //     let selectedAccounts = marketScan.accounts.filter(ac => ac.action != Action.DELETE).slice(startIndex, endIndex);
    //     selectedContacts.forEach(cont=>{
    //       selectedAccounts.forEach(account => {
    //         let index = tableData.findIndex(c => c['Customer']['groupId'] == cont.contactId && c['Customer']['id'] == account.accountId);
    //         const rowId =  account.accountId;
    //         const rowName =  account.accountName;
    //         const rowGroupId = cont.contactId;
    //         const tableProducts = [];
    //         if (index >= 0) {
    //           tableProducts.push(...tableData[index]['Products']);
    //         }
    //         let data: Data[] = [];
    //         selectedColumns.forEach(selectedColumn => {
    //           this.prepareCustomerData(tableProducts, marketScan, data, selectedColumn.id, rowId, rowName);
    //         });
    //         if (!_.isEmpty(data)) {
    //           let existingCData: MarketScanCustomerDatas = existingCustData.find(cust => cust.contactId === rowGroupId && cust.data[0].accountId === rowId);
    //           if (existingCData) {
    //             data.forEach(d => {
    //               const index = existingCData.data.findIndex(ed => ed.indskr_externalid === d.indskr_externalid);
    //               if (index >= 0) {
    //                 existingCData.data[index] = d;
    //               } else {
    //                 existingCData.data.push(d);
    //               }
    //             });
    //             existingCData.sequence = cont.sequence;
    //           } else {
    //             finalCustData.push(new MarketScanCustomerDatas(null, rowGroupId, cont.sequence, data));
    //           }
    //         }
    //       });
    //     })
    //     // tableData.forEach(row => {
    //     //   const rowId =  row['Customer']['id'];
    //     //   const rowName =  row['Customer']['name'];
    //     //   const rowGroupId = row['Customer']['groupId'];
    //     //   const tableProducts = [];
    //     //   tableProducts.push(...row['Products']);
    //     //   let data: Data[] = [];
    //     //   selectedColumns.forEach(selectedColumn => {
    //     //     this.prepareCustomerData(tableProducts, marketScan, data, selectedColumn.id, rowId, rowName);
    //     //   });
    //     //   if (!_.isEmpty(data)) {
    //     //     let existingCData: MarketScanCustomerDatas = existingCustData.find(cust => cust.contactId === rowGroupId && cust.data[0].accountId === rowId);
    //     //     if (existingCData) {
    //     //       data.forEach(d => {
    //     //         const index = existingCData.data.findIndex(ed => ed.indskr_externalid === d.indskr_externalid);
    //     //         if (index >= 0) {
    //     //           existingCData.data[index] = d;
    //     //         } else {
    //     //           existingCData.data.push(d);
    //     //         }
    //     //       });
    //     //       existingCData.sequence = row.sequence;
    //     //     } else {
    //     //       finalCustData.push(new MarketScanCustomerDatas(null, rowGroupId, row.sequence, data));
    //     //     }
    //     //   }
    //     // });
    //   }
    //   const selectedContactIds = marketScan.contacts.map(c => c.contactId);
    //   const selectedAccountIds = marketScan.accounts.map(c => c.accountId);
    //   let customerDataRemoved: MarketScanCustomerDatas[] = [];
    //   if (marketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS) {
    //     if (marketScan.indskr_marketscanid) {
    //       customerDataRemoved = existingCustData.filter(cd => selectedContactIds.includes(cd.contactId));
    //     }
    //     //remove incase account is removed
    //     existingCustData = existingCustData.filter(cD => selectedAccountIds.includes(cD.accountId));
    //   } else {
    //     if (marketScan.indskr_marketscanid) {
    //       customerDataRemoved = existingCustData.filter(cd => selectedAccountIds.includes(cd.accountId));
    //     }
    //     //remove incase contact is removed
    //     existingCustData = existingCustData.filter(cD => selectedContactIds.includes(cD.contactId));
    //   }
    //   existingCustData.push(...customerDataRemoved);
    //   existingCustData.push(...finalCustData);
    //   const endTime = new Date().getTime();
    //   console.log("Time taken to prepareCustomerDataReqPayload: " + (endTime - startTime));
    //   return existingCustData;
    // }

    // private prepareCustomerData(tableProducts: any[], marketScan: MarketScan, data: Data[], id: string, rowId?: string, rowName?: string) {
    //     const tableProductIndex = tableProducts.findIndex(p => p['id'] == id);
    //     if (tableProductIndex > -1 && marketScan.category) {
    //         marketScan.category.measures.forEach((measure, index) => {
    //             const pData = this.getProductData(marketScan.category.measures, tableProducts, tableProductIndex, measure);
    //             if (pData && pData.hasOwnProperty(measure.measureId)) {
    //                 const cData = <Data>{
    //                     rX: pData[measure.measureId],
    //                     action: pData['action'] ? pData['action'] : Action.PATCH,
    //                     indskr_externalid: pData['indskr_externalid'] ? pData['indskr_externalid'] : "offline_marketScanCustomerData_" + this.guidUtility(),
    //                     indskr_assessmentcategoryid: marketScan.category.indskr_assessmentcategoryid,
    //                     measureId: measure.measureId,
    //                     categoryName: marketScan.category.indskr_name,
    //                     measureName: measure.measureName,
    //                     measureType: measure.measureType,
    //                     indskr_capturedata: marketScan.category.indskr_capturedata,
    //                     indskr_capturedatafor: marketScan.category.indskr_capturedatafor,
    //                 };
    //                 switch (marketScan.category.indskr_capturedata) {
    //                     case CaptureData.EVENTS:
    //                         cData.indskr_eventid = tableProducts[tableProductIndex]['id'];
    //                         cData.eventName = tableProducts[tableProductIndex]['name'];
    //                         break;
    //                     case CaptureData.CAMPAIGN:
    //                         cData.indskr_campaignid = tableProducts[tableProductIndex]['id'];
    //                         cData.campaignName = tableProducts[tableProductIndex]['name'];
    //                         break;
    //                     case CaptureData.ACCOUNTS:
    //                         cData.accountId = tableProducts[tableProductIndex]['id'];
    //                         cData.accountName = tableProducts[tableProductIndex]['name'];
    //                         break;
    //                     default:
    //                         cData.productId = tableProducts[tableProductIndex]['id'];
    //                 }
    //                 if(marketScan.category.indskr_capturedatafor===CaptureDataFor.CUSTOMERS_ACCOUNTS){
    //                   cData['accountId'] = rowId;
    //                   cData['accountName'] = rowName;
    //                 }
    //                 data.push(cData);
    //             }
    //         });
    //     }
    // }

    // private getProductData(measures, tableProducts: any[], tableProductIndex: number, measure: Measure) {
    //     for (let measureIdx in measures) {
    //         if (tableProducts[tableProductIndex][measureIdx] && tableProducts[tableProductIndex][measureIdx].hasOwnProperty(measure.measureId))
    //             return tableProducts[tableProductIndex][measureIdx];
    //     }
    //     return null;
    // }

    // updateRowData(selectedMarketScan: MarketScan, columns: [], subrows: SubRowData[], existingRows, slicedRows: MarketScanContact[] | MarketScanAccount[]) {
    //   if (selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
    //     return this.updateAccountRowData(slicedRows as MarketScanAccount[], selectedMarketScan, subrows, columns, existingRows);
    //   } else if (selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS){
    //     return this.updateContactRowData(slicedRows as MarketScanContact[], selectedMarketScan, subrows, columns, existingRows);
    //   } else{
    //     return this.updateAccountAndContactRowData(slicedRows as MarketScanContact[], selectedMarketScan, subrows, columns, existingRows);
    //   }
    // }

  // private updateAccountRowData(slicedRows: MarketScanAccount[], selectedMarketScan: MarketScan, subrows: SubRowData[], columns, existingRows: any) {
  //   return slicedRows.map((c) => {
  //     let columnData = [];
  //     let row = null;
  //     const cData: MarketScanCustomerDatas = selectedMarketScan.marketScanCustomerDatas.find((msd) => msd.accountId === c.accountId);
  //     // Get the data for the selected accounts from the marketscan table
  //     if (cData) {
  //       let columns = [];
  //       selectedMarketScan.selectedColumnData.filter(selectedColumn => selectedColumn.action != Action.DELETE).forEach(selectedColumn => {
  //         const data: Data[] = cData.data.filter((d) => ((selectedColumn.id === d.indskr_eventid) || (selectedColumn.id === d.indskr_campaignid) || (selectedColumn.id === d.productId) || (selectedColumn.id === d.accountId)) && d.action !== Action.DELETE);
  //         // Get the data for the selected accounts from the marketscan table
  //         this.getDataForSelectContactFromCustomerData(data, selectedMarketScan, c, selectedColumn, subrows, columns);
  //       });
  //       columnData = this.sortListByFielName(columns, "name");
  //       //padding for first column
  //       columnData.unshift({ id: "", name: this.translate.instant("ACCOUNT"), rX: "", action: Action.PATCH });
  //       row = { 'Customer': { id: c.accountId, name: c.accountName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': columnData };
  //     } else {
  //       // insert empty data if the selected account is not present in the marketscan table
  //       const rowData = this.getEmptySubRowData(columns, subrows);
  //       row = { 'Customer': { id: c.accountId, name: c.accountName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': [...rowData] };
  //     }
  //     const existingDataIndex: number = existingRows.findIndex(er => er['Customer']['id'] === c.accountId);
  //     if (existingDataIndex >= 0) {
  //       existingRows[existingDataIndex] = row;
  //     } else {
  //       return row;
  //     }
  //   });
  // }

  // private updateContactRowData(slicedRows: MarketScanContact[], selectedMarketScan: MarketScan, subrows: SubRowData[], columns, existingRows: any) {
  //   return slicedRows.map((c) => {
  //     let columnData = [];
  //     let row = null;
  //     const cData: MarketScanCustomerDatas = selectedMarketScan.marketScanCustomerDatas.find((msd) => msd.contactId === c.contactId);
  //     // Get the data for the selected contacts from the marketscan table
  //     if (cData) {
  //       let columns = [];
  //       selectedMarketScan.selectedColumnData.filter(selectedColumn => selectedColumn.action != Action.DELETE).forEach(selectedColumn => {
  //         const data: Data[] = cData.data.filter((d) => ((selectedColumn.id === d.indskr_eventid) || (selectedColumn.id === d.indskr_campaignid) || (selectedColumn.id === d.productId) || (selectedColumn.id === d.accountId)) && d.action !== Action.DELETE);
  //         // Get the data for the selected contacts from the marketscan table
  //         this.getDataForSelectContactFromCustomerData(data, selectedMarketScan, c, selectedColumn, subrows, columns);
  //       });
  //       columnData = this.sortListByFielName(columns, "name");
  //       //padding for first column
  //       columnData.unshift({ id: "", name: this.translate.instant("CUSTOMER"), rX: "", action: Action.PATCH });
  //       row = { 'Customer': { id: c.contactId, name: c.contact_fullName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': columnData };
  //     } else {
  //       // insert empty data if the selected contact is not present in the marketscan table
  //       const rowData = this.getEmptySubRowData(columns, subrows);
  //       row = { 'Customer': { id: c.contactId, name: c.contact_fullName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': [...rowData] };
  //     }
  //     const existingDataIndex: number = existingRows.findIndex(er => er['Customer']['id'] === c.contactId);
  //     if (existingDataIndex >= 0) {
  //       existingRows[existingDataIndex] = row;
  //     } else {
  //       return row;
  //     }
  //   });
  // }

  // private updateAccountAndContactRowData(slicedRows: MarketScanContact[], selectedMarketScan: MarketScan, subrows: SubRowData[], columns, existingRows: any) {
  //   return slicedRows.map((c) => {
  //     let columnData = [];
  //     let row = null;
  //     const cData: MarketScanCustomerDatas = selectedMarketScan.marketScanCustomerDatas.find((msd) => msd.contactId === c.contactId && msd.data[0].accountId === c.accountId);
  //     // Get the data for the selected contacts from the marketscan table
  //     if (cData) {
  //       let columns = [];
  //       selectedMarketScan.selectedColumnData.filter(selectedColumn => selectedColumn.action != Action.DELETE).forEach(selectedColumn => {
  //         const data: Data[] = cData.data.filter((d) => ((selectedColumn.id === d.indskr_eventid) || (selectedColumn.id === d.indskr_campaignid) || (selectedColumn.id === d.productId) || (selectedColumn.id === d.accountId)) && d.action !== Action.DELETE);
  //         // Get the data for the selected contacts from the marketscan table
  //         this.getDataForSelectContactFromCustomerData(data, selectedMarketScan, c, selectedColumn, subrows, columns);
  //       });
  //       columnData = this.sortListByFielName(columns, "name");
  //       //padding for first column
  //       columnData.unshift({ id: "", name: this.translate.instant("ACCOUNT"), rX: "", action: Action.PATCH });
  //       columnData.unshift({ id: "", name: this.translate.instant("CUSTOMER"), rX: "", action: Action.PATCH });
  //       row = { 'Customer': { id: c.accountId, name: c.accountName, groupId:c.contactId, groupName: c.contact_fullName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': columnData };
  //     } else {
  //       // insert empty data if the selected contact is not present in the marketscan table
  //       const rowData = this.getEmptySubRowData(columns, subrows);
  //       row = { 'Customer': { id: c.accountId, name: c.accountName, groupId:c.contactId, groupName: c.contact_fullName, action: c.action ? c.action : Action.PATCH, seq: c.sequence }, 'Products': [...rowData] };
  //     }
  //     const existingDataIndex: number = existingRows.findIndex(er => er['Customer']['id'] === c.contactId && er['Customer']['groupId'] === c.accountId);
  //     if (existingDataIndex >= 0) {
  //       existingRows[existingDataIndex] = row;
  //     } else {
  //       return row;
  //     }
  //   });
  // }

    // getProductDataForRow(selectedMarketScan: MarketScan, cData: MarketScanCustomerDatas, selectedContact: MarketScanContact | MarketScanAccount, subrows: SubRowData[]) {
    //     let productData = [];
    //     selectedMarketScan.products.forEach((p) => {
    //         const pData: Data[] = cData.data.filter((d) => d.productId === p.productId);
    //         // Get the product data for the selected contacts/accounts from the marketscan table
    //         this.getDataForSelectContactFromCustomerData(pData, selectedMarketScan, selectedContact, { id: p.productId, name: p.productName, action: p.action }, subrows, productData);
    //     });
    //     return this.sortListByFielName(productData, "name");
    // }

    // getEventsDataForRow(selectedMarketScan: MarketScan, cData: MarketScanCustomerDatas, selectedContact: MarketScanContact | MarketScanAccount, subrows: SubRowData[]) {
    //     let columnData = [];
    //     let events = [];
    //     if (selectedMarketScan.statuscode === MarketScanStatusCode.Active) {
    //         events = selectedMarketScan.selectedColumnData;
    //     } else {
    //         cData.data.forEach(d => events.push(new ColumnData(d.indskr_eventid, d.eventName, d.action)));
    //     }
    //     events = _.uniqBy(events, obj => obj.id);
    //     events.forEach(event => {
    //         const data: Data[] = cData.data.filter((d) => (event.id === d.indskr_eventid) || (event.id === d.indskr_campaignid) || (event.id === d.productId) || (event.id === d.accountId));
    //         // Get the data for the selected contacts/accounts from the marketscan table
    //         this.getDataForSelectContactFromCustomerData(data, selectedMarketScan, selectedContact, event, subrows, columnData);
    //     });
    //     return this.sortListByFielName(columnData, "name");
    // }

    // getCampaignsDataForRow(selectedMarketScan: MarketScan, cData: MarketScanCustomerDatas, selectedContact: MarketScanContact | MarketScanAccount, subrows: SubRowData[]) {
    //     let columnData = [];
    //     let campaigns = [];
    //     if (selectedMarketScan.statuscode === MarketScanStatusCode.Active) {
    //         campaigns = selectedMarketScan.selectedColumnData;
    //     } else {
    //         cData.data.forEach(d => campaigns.push(new ColumnData(d.indskr_campaignid, d.campaignName, d.action)));
    //     }
    //     campaigns = _.uniqBy(campaigns, obj => obj.id);
    //     campaigns.forEach(campaign => {
    //         const data: Data[] = cData.data.filter((d) => (campaign.id === d.indskr_eventid) || (campaign.id === d.indskr_campaignid) || (campaign.id === d.productId) || (campaign.id === d.accountId));
    //         // Get the data for the selected contacts/accounts from the marketscan table
    //         this.getDataForSelectContactFromCustomerData(data, selectedMarketScan, selectedContact, campaign, subrows, columnData);
    //     });
    //     return this.sortListByFielName(columnData, "name");
    // }

    // private getDataForSelectContactFromCustomerData(data: Data[], selectedMarketScan: MarketScan, selectedContact: MarketScanContact | MarketScanAccount, p: ColumnData, subrows: SubRowData[], columnData: any[]) {
    //     if (!_.isEmpty(data)) {
    //         let subRowsData = [];
    //         let action;
    //         data.forEach(d => {
    //             const measure: Measure = selectedMarketScan.category.measures.find(m => m.measureId === d.measureId);
    //             let obj = {};
    //             obj[d.measureId] = d.rX;
    //             obj['name'] = d.measureName;
    //             obj['indskr_externalid'] = d.indskr_externalid;
    //             action = (selectedContact.action && selectedContact.action === Action.DELETE) ? selectedContact.action : (p.action ? p.action : Action.PATCH);
    //             obj['action'] = (measure && measure.action === Action.DELETE) ? measure.action : action;
    //             subRowsData.push(obj);
    //         });
    //         //Incase category is changed/ updated, new measures should be added
    //         const pMeasures = data.map(p => p.measureId);
    //         subrows.forEach((subrow, index) => {
    //             if (!pMeasures.includes(subrow.value)) {
    //                 let obj = {};
    //                 if (index) {
    //                     obj['name'] = subrow.name;
    //                     obj[subrow.value] = null;
    //                     obj['action'] = (selectedContact.action && selectedContact.action === Action.DELETE) ? selectedContact.action : (p.action ? p.action : Action.PATCH);
    //                     subRowsData.push(obj);
    //                 }
    //             }
    //         });
    //         //Keep subrows in sort sync with subrowData
    //         const sortedSubRow = subRowsData.length > 0 ? this.sortListByFielName(subRowsData, "name") : [];
    //         columnData.push({ id: p.id, name: p.name, ...sortedSubRow, action: action });
    //     } else {
    //         // insert empty data if the selected product is not present in the marketscan table
    //         let subRowsData = [];
    //         let action;
    //         subrows.forEach((sr, index) => {
    //             let obj = {};
    //             if (index) {
    //                 obj[sr.value] = null;
    //                 obj['name'] = sr.name;
    //                 action = (selectedContact.action && selectedContact.action === Action.DELETE) ? selectedContact.action : (p.action ? p.action : Action.PATCH);
    //                 obj['action'] = (sr.action === Action.DELETE) ? sr.action : action;
    //                 subRowsData.push(obj);
    //             }
    //         });
    //         columnData.push({ id: p.id, name: p.name, ...subRowsData, action: action });
    //     }
    // }

    // generates empty data for each product
    // getEmptySubRowData(columns, subrows: SubRowData[]) {
    //     return columns.map((c, index) => {
    //         let subRowsData = [];
    //         subrows.forEach((sr, index) => {
    //             //iterate through account
    //             let obj = {};
    //             if (index) {
    //                 obj[sr.value] = null;
    //                 obj['name'] = sr.name;
    //                 obj['action'] = c.action === Action.PATCH ? sr.action : c.action;
    //                 subRowsData.push(obj);
    //             }
    //         });
    //         return { ...c, ...subRowsData, action: c.action ? c.action : Action.PATCH };
    //     });
    // }

    // public getColumns(value: MarketScan) {
    //   let columns = [];
    //   if (value.category) {
    //     columns = _.isEmpty(value.selectedColumnData) ? [] : value.selectedColumnData.filter(sc => sc.action != Action.DELETE);
    //   }
    //   if(value.category?.indskr_capturedata == CaptureData.PRODUCT){
    //     let compColumns = [];
    //     let nonCompColumns = [];
    //     columns.forEach(o=>{
    //       if(this.brands.find(br=>br.ID == o.id).isCompetitorProduct === "true") compColumns.push(o)
    //       else nonCompColumns.push(o)
    //     })
    //     compColumns = _.unionBy(this.sortListByFielName(compColumns, "name"), obj => obj.id);
    //     nonCompColumns = _.unionBy(this.sortListByFielName(nonCompColumns, "name"), obj => obj.id);
    //     columns = nonCompColumns.concat(compColumns);
    //   }
    //   else columns = _.unionBy(this.sortListByFielName(columns, "name"), obj => obj.id);
    //   // adding this in the first position to display customer text in the very first column
    //   if (value.category?.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
    //     columns.unshift({ id: '', name: this.translate.instant('ACCOUNT'), action: Action.PATCH });
    //   } else if (value.category?.indskr_capturedatafor === CaptureDataFor.CUSTOMERS_ACCOUNTS) {
    //     columns.unshift({ id: '', name: this.translate.instant('ACCOUNT'), action: Action.PATCH });
    //     columns.unshift({ id: '', name: this.translate.instant('CUSTOMER'), action: Action.PATCH });
    //   } else {
    //     columns.unshift({ id: '', name: this.translate.instant('CUSTOMER'), action: Action.PATCH });
    //   }
    //   return columns;
    // }

    // public getSubrows(value: MarketScan) {
    //   let subrows = [{ name: '', value: '', action: Action.PATCH, type: MeasureType.NUMERIC ? 'number' : 'text', measureCaptureFor: MeasureCaptureFor.CUSTOMERS_ACCOUNTS }];
    //   value.category && value.category.measures.forEach(measure => subrows.push(
    //     {
    //       name: measure.measureName,
    //       value: measure.measureId,
    //       action: measure.action ? measure.action : Action.PATCH,
    //       type: measure.measureType === MeasureType.NUMERIC ? 'number' : 'text' ,
    //       measureCaptureFor : measure.captureFor
    //     }
    //   ))
    //   subrows = this.sortListByFielName(subrows, "name");
    //   return subrows;
    // }

    public async discardChanges() {
        if (!this.marketScanCopy) return;
        this.revertStatus();
        this.getSelectedMarketScan().isModified = false;
        this.marketScanCopy.isModified = false;
        this.upsertMarketScans(this.marketScanCopy);
        await this.updateMarketScanInPouchDB(this.marketScanCopy);
        this.setCurrentMarketScan(null); // so that revert status is not called again
    }

    public createUpdateMarketScanCustomerDataPayload(marketScan:MarketScan){
      let flag:boolean = this.checkIfMarketScanCustomerDataCanBePopulated(marketScan);
      if(flag){
        let matrixData:Array<MarketScanMatrixModel> = [];
        let detailsScreenMatrixData:MarketScanDetailsScreenMatrixModel;
        let msMetricName:string;
        let msMetricIdProperty:string;
        let msMetricLabelName:string;
        const isReadOnlyView = this.authService.user.systemUserID !== marketScan.ownerId || marketScan.statuscode !== MarketScanStatusCode.Active
        if(marketScan.category.indskr_capturedata == CaptureData.PRODUCT || marketScan.category.indskr_capturedata === CaptureData.PROCEDURE){
          msMetricName = 'products';
          msMetricLabelName = 'productName';
          msMetricIdProperty = 'productId';
        } else{
          return;
        }

        this._sortMarketScanMetrics(marketScan);
        if (_.isEmpty(marketScan.indskr_marketscanid)){
          marketScan.marketScanCustomerDatas.forEach(record => {
            record.action = Action.DELETE;
          })
        }
        if(marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
          if(marketScan.selectView == 'ACCOUNT_VIEW'){
            detailsScreenMatrixData = {
              matrixId: marketScan.indskr_externalid,
              matrixColumns: [
                {
                  columnId: marketScan.selectView,
                  columnLabel: this.translate.instant('ACCOUNTS'),
                  columnItems: [],
                }
              ],
            };
            let msAccIdx = 0
            marketScan.accounts.forEach((msAccount)=> {
              if(msAccount.action != Action.DELETE){
                matrixData.push({
                  matrixId: msAccount.accountId,
                  matrixName: msAccount.accountName,
                  matrixTable: [{
                    columdId: 'first_column',
                    columnItems: [{
                      columnItemId: 'first_column_item',
                      isHeader: true,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel: this.translate.instant('CUSTOMERS'),
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    }]
                  }]
                });
                marketScan[msMetricName].forEach((item)=>{
                  if(item.action != Action.DELETE){
                    matrixData[msAccIdx].matrixTable.push({
                      columdId: item[msMetricIdProperty],
                      columnItems: [{
                        columnItemId: 'first_column_item'+item[msMetricIdProperty],
                        isHeader: true,
                        isTotal: false,
                        isInputField: false,
                        columnItemLabel: item[msMetricLabelName],
                        columnItemSecondaryLabel: '',
                        isMoreHeight: false,
                      }]
                    })
                    if(msAccIdx == 0){
                      detailsScreenMatrixData.matrixColumns.push({
                        columnId: item[msMetricIdProperty],
                        columnLabel: item[msMetricLabelName],
                        columnItems: [],
                      })
                    }
                  }
                })
                msAccIdx = msAccIdx + 1;
              }
            })

            msAccIdx = 0;
            marketScan.accounts.forEach((msAccount)=> {
              if(msAccount.action != Action.DELETE){
                detailsScreenMatrixData.matrixColumns.forEach((column,columnIdx)=>{
                  column.columnItems.push(
                    {
                      columnItemId: msAccount.accountId+'_'+columnIdx,
                      columnItemLabel: columnIdx == 0 ? msAccount.accountName : '',
                      showExpandIcon: columnIdx == detailsScreenMatrixData.matrixColumns.length -1 ? true : false,
                      childItems: [],
                    }
                  );
                });
              }
              msAccount.affiliatedContacts.forEach((msContact,msIdx)=> {
                matrixData[msAccIdx].matrixTable[0].columnItems.push({
                  columnItemId:msContact.contactId,
                  isHeader: false,
                  isTotal: false,
                  isInputField: false,
                  columnItemLabel: msContact.contact_fullName,
                  columnItemSecondaryLabel: '',
                  isMoreHeight: false,
                  childItems:[]
                });

                detailsScreenMatrixData.matrixColumns[0].columnItems[msAccIdx].childItems.push(
                  {
                    childItemId: msContact.contactId,
                    childItemLabel: msContact.contact_fullName,
                    childItemSecondaryLabel:'',
                    isMoreHeight: true,
                    subchildItems:[]
                  }
                );
                let msProductIdx=0;
                marketScan.products.forEach((msProduct)=> {
                  if(msProduct.action != Action.DELETE){
                    matrixData[msAccIdx].matrixTable[msProductIdx + 1].columnItems.push({
                      columnItemId:msContact.contactId + msProduct.productId,
                      isHeader: false,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel:'',
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                      childItems:[]
                    });

                    detailsScreenMatrixData.matrixColumns[msProductIdx + 1].columnItems[msAccIdx].childItems.push(
                      {
                         childItemId: msContact.contactId + msProduct.productId,
                         childItemLabel: '',
                         childItemSecondaryLabel:'',
                         isMoreHeight: true,
                         subchildItems:[]
                      }
                    );
                    msProductIdx=msProductIdx +1;
                  }

                })
               marketScan.category.measures.forEach((msMeasure,msConIdx)=> {
                  if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS && msAccount.action != Action.DELETE && msMeasure.action != Action.DELETE && msContact.action != Action.DELETE){
                    matrixData[msAccIdx].matrixTable[0].columnItems[msIdx +1].childItems.push({
                      childItemID: msMeasure.measureId,
                      isHeader: false,
                      isTotal: false,
                      isInputField: false,
                      childItemLabel: msMeasure.measureName,
                      isMoreHeight: false,
                    });
                    detailsScreenMatrixData.matrixColumns[0].columnItems[msAccIdx].childItems[msIdx].subchildItems.push(
                      {
                        subchildItemID: msMeasure.measureId,
                        subchildItemLabel: msMeasure.measureName,
                        isMoreHeight: true,
                      }
                    );
                  }
                  let msProIdx = 0;
                  marketScan.products.forEach((msProduct)=> {
                    if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
                      let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.accountId == msAccount.accountId && c.contactId == msContact.contactId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                      let obj: MarketScanCustomerData;
                      if (idx >= 0) {
                        obj = marketScan.marketScanCustomerDatas[idx];
                        if (msMeasure.action == Action.DELETE || msAccount.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE) {
                          marketScan.marketScanCustomerDatas[idx].action = Action.DELETE;
                        } else {
                          if (_.isEmpty(marketScan.indskr_marketscanid)){
                            obj.action = Action.PATCH;
                          }else{
                            if(obj.action == Action.DELETE){
                              obj.action = Action.PATCH;
                            }
                          }
                        }
                      } else {
                        if (!(msMeasure.action == Action.DELETE || msAccount.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE)) {
                          obj = new MarketScanCustomerData(this.generateRandomGuid(), marketScan.category.indskr_assessmentcategoryid, msMeasure.measureId, marketScan.category.indskr_name, msMeasure.measureName, msMeasure.measureType.toString(), marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, msProduct.productId, msAccount.accountId, msContact.contactId, 1, Action.PATCH, '', '', null, null);
                          marketScan.marketScanCustomerDatas.push(obj);
                        }
                      }
                      if (msAccount.action != Action.DELETE && msMeasure.action != Action.DELETE && msContact.action != Action.DELETE && msProduct.action != Action.DELETE) {
                        matrixData[msAccIdx].matrixTable[msProIdx + 1].columnItems[msIdx +1].childItems.push({
                          childItemID: obj.indskr_externalid,
                          isHeader: false,
                          isTotal: false,
                          isInputField: !isReadOnlyView,
                          childItemLabel:obj.rX ? obj.rX : (!isReadOnlyView ? null : '---'),
                          isMoreHeight: true,
                        });
                        detailsScreenMatrixData.matrixColumns[msProIdx + 1].columnItems[msAccIdx].childItems[msIdx].subchildItems.push(
                          {
                            subchildItemID: obj.indskr_externalid,
                            subchildItemLabel: obj.rX ? obj.rX : '---',
                            isMoreHeight: true,
                          }
                        );
                        msProIdx = msProIdx + 1;
                      }
                    }
                  });
                });
              });
              if(msAccount.affiliatedContacts.length > 0 && marketScan.category.measures.length > 0){
              matrixData[msAccIdx].matrixTable[0].columnItems.push({
                columnItemId:msAccount.accountId,
                isHeader: false,
                isTotal: true,
                isInputField: false,
                columnItemLabel: this.translate.instant('TOTAL'),
                columnItemSecondaryLabel: '',
                isMoreHeight: false,
                childItems:[]
              });
              let msProductIdx=0;
              marketScan.products.forEach((msProduct)=> {
                if(msProduct.action != Action.DELETE){
                  matrixData[msAccIdx].matrixTable[msProductIdx + 1].columnItems.push({
                    columnItemId: msProduct.productId,
                    isHeader: false,
                    isTotal: true,
                    isInputField: false,
                    columnItemLabel:'',
                    columnItemSecondaryLabel: '',
                    isMoreHeight: false,
                    childItems:[]
                  });
                  msProductIdx=msProductIdx +1;
                }

              })
                }
              marketScan.category.measures.forEach((msMeasure,msIdx)=> {
                if(msAccount.action != Action.DELETE&& msAccount.affiliatedContacts.length > 0  && msMeasure.action != Action.DELETE  && msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
                  matrixData[msAccIdx].matrixTable[0].columnItems[msAccount.affiliatedContacts.length+1].childItems.push({
                    childItemID: msMeasure.measureId,
                    isHeader: false,
                    isTotal: true,
                    isInputField: false,
                    childItemLabel: msMeasure.measureName,
                    isMoreHeight: false,
                  });

                  let msProIdx = 0;
                  marketScan.products.forEach((msProduct)=> {
                    if(msProduct.action != Action.DELETE){
                      let totalValue = '0';
                      let totalNumberValue:number = 0;
                      msAccount.affiliatedContacts.forEach(tempContact=>{
                        if(msProduct.action != Action.DELETE && tempContact.action != Action.DELETE){
                          let totalidx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.accountId == msAccount.accountId && c.contactId == tempContact.contactId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                          if(totalidx >= 0){
                            if(marketScan.marketScanCustomerDatas[totalidx].rX){
                              totalNumberValue += parseInt(marketScan.marketScanCustomerDatas[totalidx].rX);
                            }
                          }
                        }
                      })
                      matrixData[msAccIdx].matrixTable[msProIdx + 1].columnItems[msAccount.affiliatedContacts.length+1].childItems.push({
                        childItemID: 'product_item_total'+msProduct.productId,
                        isHeader: false,
                        isTotal: true,
                        isInputField: false,
                        childItemLabel:totalNumberValue == 0 ? totalValue : totalNumberValue.toString(),
                        isMoreHeight: true,
                      });
                      msProIdx = msProIdx + 1;
                    }

                  });
                }
              })

              if(msAccount.action != Action.DELETE){
                msAccIdx = msAccIdx + 1;
              }
            });
          }else if(marketScan.selectView == 'CUSTOMER_VIEW'){
            detailsScreenMatrixData = {
              matrixId: marketScan.indskr_externalid,
              matrixColumns: [
                {
                  columnId: marketScan.selectView,
                  columnLabel: this.translate.instant('CUSTOMERS'),
                  columnItems: [],
                }
              ],
            };
            let msConIdx = 0;
            marketScan.contacts.forEach((msContact)=> {
              if(msContact.action != Action.DELETE){
                matrixData.push({
                  matrixId: msContact.contactId,
                  matrixName: msContact.contact_fullName,
                  matrixTable: [{
                    columdId: 'first_column',
                    columnItems: [{
                      columnItemId: 'first_column_item',
                      isHeader: true,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel: this.translate.instant('ACCOUNTS'),
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    }]
                  }]
                });
                marketScan[msMetricName].forEach((item)=>{
                  if(item.action != Action.DELETE){
                    matrixData[msConIdx].matrixTable.push({
                      columdId: item[msMetricIdProperty],
                      columnItems: [{
                        columnItemId: 'first_column_item'+item[msMetricIdProperty],
                        isHeader: true,
                        isTotal: false,
                        isInputField: false,
                        columnItemLabel: item[msMetricLabelName],
                        columnItemSecondaryLabel: '',
                        isMoreHeight: false,
                      }]
                    })
                    if(msConIdx == 0){
                      detailsScreenMatrixData.matrixColumns.push({
                        columnId: item[msMetricIdProperty],
                        columnLabel: item[msMetricLabelName],
                        columnItems: [],
                      })
                    }
                  }
                })
                msConIdx = msConIdx + 1;
              }
            })

            msConIdx = 0;
            marketScan.contacts.forEach((msContact)=> {
              if(msContact.action != Action.DELETE){
                detailsScreenMatrixData.matrixColumns.forEach((column,columnIdx)=>{
                  column.columnItems.push(
                    {
                      columnItemId: msContact.contactId+'_'+columnIdx,
                      columnItemLabel: columnIdx == 0 ? msContact.contact_fullName : '',
                      showExpandIcon: columnIdx == detailsScreenMatrixData.matrixColumns.length -1 ? true : false,
                      childItems: [],
                    }
                  );
                });
              }
              msContact.affiliatedAccounts.forEach((msAccount,msIdx)=> {
                  matrixData[msConIdx].matrixTable[0].columnItems.push({
                    columnItemId:msAccount.accountId,
                    isHeader: false,
                    isTotal: false,
                    isInputField: false,
                    columnItemLabel: msAccount.accountName,
                    columnItemSecondaryLabel: '',
                    isMoreHeight: false,
                    childItems:[]
                  });

                  detailsScreenMatrixData.matrixColumns[0].columnItems[msConIdx].childItems.push(
                    {
                      childItemId: msAccount.accountId,
                      childItemLabel: msAccount.accountName,
                      childItemSecondaryLabel:'',
                      isMoreHeight: true,
                      subchildItems:[]
                    }
                  );
                  let msProductIdx=0;
                  marketScan.products.forEach((msProduct)=> {
                    if(msProduct.action != Action.DELETE){
                      matrixData[msConIdx].matrixTable[msProductIdx + 1].columnItems.push({
                        columnItemId:msAccount.accountId + msProduct.productId,
                        isHeader: false,
                        isTotal: false,
                        isInputField: false,
                        columnItemLabel:'',
                        columnItemSecondaryLabel: '',
                        isMoreHeight: false,
                        childItems:[]
                      });

                      detailsScreenMatrixData.matrixColumns[msProductIdx + 1].columnItems[msConIdx].childItems.push(
                        {
                           childItemId: msAccount.accountId + msProduct.productId,
                           childItemLabel: '',
                           childItemSecondaryLabel:'',
                           isMoreHeight: true,
                           subchildItems:[]
                        }
                      );
                      msProductIdx=msProductIdx +1;
                    }

                  })
                  marketScan.category.measures.forEach((msMeasure,msAccIdx)=> {
                    if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS && msContact.action != Action.DELETE && msMeasure.action != Action.DELETE && msAccount.action != Action.DELETE){
                      matrixData[msConIdx].matrixTable[0].columnItems[msIdx +1].childItems.push({
                        childItemID:msMeasure.measureId,
                        isHeader: false,
                        isTotal: false,
                        isInputField: false,
                        childItemLabel: msMeasure.measureName,
                        // childItemSecondaryLabel: msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS ? msMeasure.measureName : '',
                        isMoreHeight: false,
                      });

                      detailsScreenMatrixData.matrixColumns[0].columnItems[msConIdx].childItems[msIdx].subchildItems.push(
                        {
                          subchildItemID: msMeasure.measureId,
                          subchildItemLabel:msMeasure.measureName,
                          // childItemSecondaryLabel: msMeasure.measureName,
                          isMoreHeight: true,
                        }
                      );
                    }
                    let msProIdx = 0;
                    marketScan.products.forEach((msProduct)=> {
                      if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
                        let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.accountId == msAccount.accountId && c.contactId == msContact.contactId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                        let obj: MarketScanCustomerData;
                        if (idx >= 0) {
                          obj = marketScan.marketScanCustomerDatas[idx];
                          if (msMeasure.action == Action.DELETE || msAccount.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE) {
                            marketScan.marketScanCustomerDatas[idx].action = Action.DELETE;
                          } else {
                            if (_.isEmpty(marketScan.indskr_marketscanid)){
                              obj.action = Action.PATCH;
                            }else{
                              if(obj.action == Action.DELETE){
                                obj.action = Action.PATCH;
                              }
                            }
                          }
                        } else {
                          if (!(msMeasure.action == Action.DELETE || msAccount.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE)) {
                            obj = new MarketScanCustomerData(this.generateRandomGuid(), marketScan.category.indskr_assessmentcategoryid, msMeasure.measureId, marketScan.category.indskr_name, msMeasure.measureName, msMeasure.measureType.toString(), marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, msProduct.productId, msAccount.accountId, msContact.contactId, 1, Action.PATCH, '', '', null, null);
                            marketScan.marketScanCustomerDatas.push(obj);
                          }
                        }
                        if (msAccount.action != Action.DELETE && msMeasure.action != Action.DELETE && msContact.action != Action.DELETE && msProduct.action != Action.DELETE) {
                          matrixData[msConIdx].matrixTable[msProIdx + 1].columnItems[msIdx +1].childItems.push({
                            childItemID: obj.indskr_externalid,
                            isHeader: false,
                            isTotal: false,
                            isInputField: !isReadOnlyView,
                            childItemLabel: obj.rX ? obj.rX : (!isReadOnlyView ? null : '---'),
                            // columnItemSecondaryLabel: '',
                            isMoreHeight: msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS ? true : false,
                          });

                          detailsScreenMatrixData.matrixColumns[msProIdx + 1].columnItems[msConIdx].childItems[msIdx].subchildItems.push(
                            {
                              subchildItemID: obj.indskr_externalid,
                              subchildItemLabel: obj.rX ? obj.rX : '---',
                              // childItemSecondaryLabel: '',
                              isMoreHeight: true,
                            }
                          );
                          msProIdx = msProIdx + 1;
                        }
                      }

                    });
                  });
              });
              if(msContact.affiliatedAccounts.length > 0 && marketScan.category.measures.length > 0){
                matrixData[msConIdx].matrixTable[0].columnItems.push({
                  columnItemId:msContact.contactId,
                  isHeader: false,
                  isTotal: true,
                  isInputField: false,
                  columnItemLabel: this.translate.instant('TOTAL'),
                  columnItemSecondaryLabel: '',
                  isMoreHeight: false,
                  childItems:[]
                });
                let msProductIdx=0;
                marketScan.products.forEach((msProduct)=> {
                  if(msProduct.action != Action.DELETE){
                    matrixData[msConIdx].matrixTable[msProductIdx + 1].columnItems.push({
                      columnItemId: msProduct.productId,
                      isHeader: false,
                      isTotal: true,
                      isInputField: false,
                      columnItemLabel:'',
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                      childItems:[]
                    });
                    msProductIdx=msProductIdx +1;
                  }

                })
              }
           marketScan.category.measures.forEach((msMeasure,msAccIdx)=> {
             if(msContact.action != Action.DELETE  && msContact.affiliatedAccounts.length > 0  && msMeasure.action != Action.DELETE && msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
              matrixData[msConIdx].matrixTable[0].columnItems[msContact.affiliatedAccounts.length+1].childItems.push({
                childItemID: msMeasure.measureId,
                isHeader: false,
                isTotal: true,
                isInputField: false,
                childItemLabel: msMeasure.measureName,
                isMoreHeight: false,
              });

                    let msProIdx = 0;
                    marketScan.products.forEach((msProduct)=> {
                      if(msProduct.action != Action.DELETE && msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
                        let totalValue = '0';
                        let totalNumberValue:number = 0;
                        msContact.affiliatedAccounts.forEach(tempAccount=>{
                          if(msProduct.action != Action.DELETE && tempAccount.action != Action.DELETE){
                            let totalidx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.contactId == msContact.contactId && c.accountId == tempAccount.accountId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                            if(totalidx >= 0){
                              if(marketScan.marketScanCustomerDatas[totalidx].rX){
                                totalNumberValue += parseInt(marketScan.marketScanCustomerDatas[totalidx].rX);
                              }
                            }
                          }
                        })
                        matrixData[msConIdx].matrixTable[msProIdx + 1].columnItems[msContact.affiliatedAccounts.length+1].childItems.push({
                          childItemID: 'product_item_total'+msProduct.productId,
                          isHeader: false,
                          isTotal: true,
                          isInputField: false,
                          childItemLabel:totalNumberValue == 0 ? totalValue : totalNumberValue.toString(),
                          isMoreHeight: true,
                        });
                        msProIdx = msProIdx + 1;
                      }
                    });
                  }
              });
               marketScan.category.measures.forEach((msMeasure,msAccIdx)=> {
                 if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS){
                  if(msContact.action != Action.DELETE && msMeasure.action != Action.DELETE){
                    matrixData[msConIdx].matrixTable[0].columnItems.push({
                      columnItemId: 'measure_item_total'+msMeasure.measureId,
                      isHeader: false,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel: msMeasure.measureName,
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    });

                    detailsScreenMatrixData.matrixColumns[0].columnItems[msConIdx].childItems.push(
                      {
                        childItemId: msMeasure.measureId,
                        childItemLabel: msMeasure.measureName,
                        childItemSecondaryLabel: '',
                        isMoreHeight: false,
                      }
                    );
                  }

                  let msProIdx = 0
                  marketScan.products.forEach((msProduct)=> {
                    let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && !c.accountId && c.contactId == msContact.contactId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                    let obj: MarketScanCustomerData;
                    if (idx >= 0) {
                      obj = marketScan.marketScanCustomerDatas[idx];
                      if (msMeasure.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE) {
                        marketScan.marketScanCustomerDatas[idx].action = Action.DELETE;
                      } else {
                        if (_.isEmpty(marketScan.indskr_marketscanid)){
                          obj.action = Action.PATCH;
                        }else {
                          if(obj.action == Action.DELETE){
                            obj.action = Action.PATCH;
                          }
                        }
                      }
                    } else {
                      if (!(msMeasure.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE)) {
                        obj = new MarketScanCustomerData(this.generateRandomGuid(), marketScan.category.indskr_assessmentcategoryid, msMeasure.measureId, marketScan.category.indskr_name, msMeasure.measureName, msMeasure.measureType.toString(), marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, msProduct.productId, '', msContact.contactId, 1, Action.PATCH, '', '', null,null);
                        marketScan.marketScanCustomerDatas.push(obj);
                      }
                    }
                    if (msMeasure.action != Action.DELETE && msContact.action != Action.DELETE && msProduct.action != Action.DELETE) {
                      matrixData[msConIdx].matrixTable[msProIdx + 1].columnItems.push({
                        columnItemId: obj.indskr_externalid,
                        isHeader: false,
                        isTotal: false,
                        isInputField: !isReadOnlyView,
                        columnItemLabel: obj.rX ? obj.rX : (!isReadOnlyView ? null : '---'),
                        columnItemSecondaryLabel: '',
                        isMoreHeight: false,
                      });
                      detailsScreenMatrixData.matrixColumns[msProIdx + 1].columnItems[msConIdx].childItems.push(
                        {
                          childItemId: obj.indskr_externalid,
                          childItemLabel: obj.rX ? obj.rX : '---',
                          childItemSecondaryLabel: '',
                          isMoreHeight: false,
                        }
                      );
                      msProIdx = msProIdx + 1;
                    }
                  });
                }
              });
              if(msContact.action != Action.DELETE){
                msConIdx = msConIdx + 1;
              }
            });
          }
        }else if(marketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS){
          detailsScreenMatrixData = {
            matrixId: marketScan.indskr_externalid,
            matrixColumns: [
              {
                columnId: marketScan.selectView,
                columnLabel: this.translate.instant('ACCOUNTS'),
                columnItems: [],
              }
            ],
          };
          let msAccIdx = 0;
          marketScan.accounts.forEach((msAccount)=> {  // row setting up
            if(msAccount.action != Action.DELETE){
              matrixData.push({
                matrixId: msAccount.accountId,
                matrixName: msAccount.accountName,
                matrixTable: [{
                  columdId: 'first_column',
                  columnItems: [{
                    columnItemId: 'first_column_item',
                    isHeader: true,
                    isTotal: false,
                    isInputField: false,
                    columnItemLabel: this.translate.instant('MEASURES'),
                    columnItemSecondaryLabel: '',
                    isMoreHeight: false,
                  }]
                }]
              });
              marketScan[msMetricName].forEach((item)=>{ // columns heading
                if(item.action != Action.DELETE){
                  matrixData[msAccIdx].matrixTable.push({
                    columdId: item[msMetricIdProperty],
                    columnItems: [{
                      columnItemId: 'first_column_item'+item[msMetricIdProperty],
                      isHeader: true,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel: item[msMetricLabelName],
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    }]
                  })
                  if(msAccIdx == 0){ // set columns-headings with first row
                    detailsScreenMatrixData.matrixColumns.push({
                      columnId: item[msMetricIdProperty],
                      columnLabel: item[msMetricLabelName],
                      columnItems: [],
                    })
                  }
                }
              })
              msAccIdx = msAccIdx + 1;
            }
          })

          msAccIdx = 0;
          marketScan.accounts.forEach((msAccount)=> {
            if(msAccount.action != Action.DELETE){
              detailsScreenMatrixData.matrixColumns.forEach((column,columnIdx)=>{
                column.columnItems.push(
                  {
                    columnItemId: msAccount.accountId+'_'+columnIdx,
                    columnItemLabel: columnIdx == 0 ? msAccount.accountName : '',
                    showExpandIcon: columnIdx == detailsScreenMatrixData.matrixColumns.length -1 ? true : false,
                    childItems: [],
                  }
                );
              });
            }
            marketScan.category.measures.forEach((msMeasure,msIdx)=> {

              if(msAccount.action != Action.DELETE && msMeasure.action != Action.DELETE){
                matrixData[msAccIdx].matrixTable[0].columnItems.push({
                  columnItemId: 'first_column_item'+msMeasure.measureId,
                  isHeader: false,
                  isTotal: false,
                  isInputField: false,
                  columnItemLabel: msMeasure.measureName,
                  columnItemSecondaryLabel: '',
                  isMoreHeight: false,
                });

                detailsScreenMatrixData.matrixColumns[0].columnItems[msAccIdx].childItems.push(
                  {
                    childItemId: msMeasure.measureId,
                    childItemLabel: msMeasure.measureName,
                    childItemSecondaryLabel: '',
                    isMoreHeight: false,
                  }
                );
              }
              let msProIdx = 0;
              marketScan.products.forEach((msProduct)=> {
                //if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
                  let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.accountId == msAccount.accountId && !c.contactId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                  let obj: MarketScanCustomerData;
                  if (idx >= 0) {
                    obj = marketScan.marketScanCustomerDatas[idx];
                    if (msMeasure.action == Action.DELETE || msAccount.action == Action.DELETE || msProduct.action == Action.DELETE) {
                      marketScan.marketScanCustomerDatas[idx].action = Action.DELETE;
                    } else {
                      if (_.isEmpty(marketScan.indskr_marketscanid)){
                        obj.action = Action.PATCH;
                      }else{
                        if(obj.action == Action.DELETE){
                          obj.action = Action.PATCH
                        }
                      }
                    }
                  } else {
                    if (!(msMeasure.action == Action.DELETE || msAccount.action == Action.DELETE || msProduct.action == Action.DELETE)) {
                      obj = new MarketScanCustomerData(this.generateRandomGuid(), marketScan.category.indskr_assessmentcategoryid, msMeasure.measureId, marketScan.category.indskr_name, msMeasure.measureName, msMeasure.measureType.toString(), marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, msProduct.productId, msAccount.accountId, '', 1, Action.PATCH, '', '', null,null);
                      marketScan.marketScanCustomerDatas.push(obj);
                    }
                  }
                  if (msAccount.action != Action.DELETE && msMeasure.action != Action.DELETE && msProduct.action != Action.DELETE) {
                    matrixData[msAccIdx].matrixTable[msProIdx + 1].columnItems.push({
                      columnItemId: obj.indskr_externalid,
                      isHeader: false,
                      isTotal: false,
                      isInputField: !isReadOnlyView,
                      columnItemLabel: obj && obj.rX ? obj.rX : (!isReadOnlyView ? null : '---'),
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    });
                    detailsScreenMatrixData.matrixColumns[msProIdx + 1].columnItems[msAccIdx].childItems.push(
                      {
                        childItemId: obj.indskr_externalid,
                        childItemLabel: obj.rX ? obj.rX : '---',
                        childItemSecondaryLabel: '',
                        isMoreHeight: false,
                      }
                    );
                    msProIdx = msProIdx + 1;
                  }
                //}
              });
            });
            if(msAccount.action != Action.DELETE){
              msAccIdx =  msAccIdx + 1;
            }
          });
        }else if(marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS){
          detailsScreenMatrixData = {
            matrixId: marketScan.indskr_externalid,
            matrixColumns: [
              {
                columnId: marketScan.selectView,
                columnLabel: this.translate.instant('CUSTOMERS'),
                columnItems: [],
              }
            ],
          };
          let msConIdx = 0;
          marketScan.contacts.forEach((msContact)=> {
            if(msContact.action != Action.DELETE){
              matrixData.push({
                matrixId: msContact.contactId,
                matrixName: msContact.contact_fullName,
                matrixTable: [{
                  columdId: 'first_column',
                  columnItems: [{
                    columnItemId: 'first_column_item',
                    isHeader: true,
                    isTotal: false,
                    isInputField: false,
                    columnItemLabel: this.translate.instant('MEASURES'),
                    columnItemSecondaryLabel: '',
                    isMoreHeight: false,
                  }]
                }]
              });
              marketScan[msMetricName].forEach((item,idx)=>{
                if(item.action != Action.DELETE){
                  matrixData[msConIdx].matrixTable.push({
                    columdId: item[msMetricIdProperty],
                    columnItems: [{
                      columnItemId: 'first_column_item'+item[msMetricIdProperty],
                      isHeader: true,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel: item[msMetricLabelName],
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    }]
                  })
                  if(msConIdx == 0){
                    detailsScreenMatrixData.matrixColumns.push({
                      columnId: item[msMetricIdProperty],
                      columnLabel: item[msMetricLabelName],
                      columnItems: [],
                    })
                  }
                }
              })
              msConIdx = msConIdx + 1;
            }
          })
          msConIdx = 0;
          marketScan.contacts.forEach((msContact)=> {
            if(msContact.action != Action.DELETE){
              detailsScreenMatrixData.matrixColumns.forEach((column,columnIdx)=>{
                column.columnItems.push(
                  {
                    columnItemId: msContact.contactId+'_'+columnIdx,
                    columnItemLabel: columnIdx == 0 ? msContact.contact_fullName : '',
                    showExpandIcon: columnIdx == detailsScreenMatrixData.matrixColumns.length -1 ? true : false,
                    childItems: [],
                  }
                );
              });
            }
            marketScan.category.measures.forEach((msMeasure,msIdx)=> {

              if(msContact.action != Action.DELETE && msMeasure.action != Action.DELETE){
                matrixData[msConIdx].matrixTable[0].columnItems.push({
                  columnItemId: 'first_column_item'+msMeasure.measureId,
                  isHeader: false,
                  isTotal: false,
                  isInputField: false,
                  columnItemLabel: msMeasure.measureName,
                  columnItemSecondaryLabel: '',
                  isMoreHeight: false,
                });
                detailsScreenMatrixData.matrixColumns[0].columnItems[msConIdx].childItems.push(
                  {
                    childItemId: msMeasure.measureId,
                    childItemLabel: msMeasure.measureName,
                    childItemSecondaryLabel: '',
                    isMoreHeight: false,
                  }
                );
              }
              let msProIdx = 0;
              marketScan.products.forEach((msProduct)=> {
                //if(msMeasure.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS){
                  let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && !c.accountId && c.contactId == msContact.contactId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                  let obj: MarketScanCustomerData;
                  if (idx >= 0) {
                    obj = marketScan.marketScanCustomerDatas[idx];
                    if (msMeasure.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE) {
                      marketScan.marketScanCustomerDatas[idx].action = Action.DELETE;
                    } else {
                      if (_.isEmpty(marketScan.indskr_marketscanid)){
                        obj.action = Action.PATCH;
                      }else{
                        if(obj.action == Action.DELETE){
                          obj.action = Action.PATCH;
                        }
                      }
                    }
                  } else {
                    if (!(msMeasure.action == Action.DELETE || msContact.action == Action.DELETE || msProduct.action == Action.DELETE)) {
                      obj = new MarketScanCustomerData(this.generateRandomGuid(), marketScan.category.indskr_assessmentcategoryid, msMeasure.measureId, marketScan.category.indskr_name, msMeasure.measureName, msMeasure.measureType.toString(), marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, msProduct.productId, '', msContact.contactId, 1, Action.PATCH, '', '', null,null);
                      marketScan.marketScanCustomerDatas.push(obj);
                    }
                  }
                  if (msContact.action != Action.DELETE && msMeasure.action != Action.DELETE && msProduct.action != Action.DELETE) {
                    matrixData[msConIdx].matrixTable[msProIdx + 1].columnItems.push({
                      columnItemId: obj.indskr_externalid,
                      isHeader: false,
                      isTotal: false,
                      isInputField: !isReadOnlyView,
                      columnItemLabel: obj.rX ? obj.rX : (!isReadOnlyView ? null : '---'),
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    });
                    detailsScreenMatrixData.matrixColumns[msProIdx + 1].columnItems[msConIdx].childItems.push(
                      {
                        childItemId: obj.indskr_externalid,
                        childItemLabel: obj && obj.rX ? obj.rX : '---',
                        childItemSecondaryLabel: '',
                        isMoreHeight: false,
                      }
                    );
                    msProIdx = msProIdx + 1;
                  }
                //}
              });
            });
            if(msContact.action != Action.DELETE){
              msConIdx = msConIdx + 1;
            }
          });
        } else if (marketScan.category.indskr_capturedatafor == CaptureDataFor.COMPETITOR) {
          detailsScreenMatrixData = {
            matrixId: marketScan.indskr_externalid,
            matrixColumns: [
              {
                columnId: marketScan.selectView,
                columnLabel: this.translate.instant('COMPETITORS'),
                columnItems: [],
              }
            ],
          };
          let msConIdx = 0;
          marketScan.competitors.forEach((msCompetitor) => {
            if (msCompetitor.action != Action.DELETE) {
              matrixData.push({
                matrixId: msCompetitor.competitorId,
                matrixName: msCompetitor.competitorName,
                matrixTable: [{
                  columdId: 'first_column',
                  columnItems: [{
                    columnItemId: 'first_column_item',
                    isHeader: true,
                    isTotal: false,
                    isInputField: false,
                    columnItemLabel: this.translate.instant('MEASURES'),
                    columnItemSecondaryLabel: '',
                    isMoreHeight: false,
                  }]
                }]
              });
              marketScan[msMetricName].forEach((item, idx) => {
                if (item.action != Action.DELETE) {
                  matrixData[msConIdx].matrixTable.push({
                    columdId: item[msMetricIdProperty],
                    columnItems: [{
                      columnItemId: 'first_column_item' + item[msMetricIdProperty],
                      isHeader: true,
                      isTotal: false,
                      isInputField: false,
                      columnItemLabel: item[msMetricLabelName],
                      columnItemSecondaryLabel: '',
                      isMoreHeight: false,
                    }]
                  })
                  if (msConIdx == 0) {
                    detailsScreenMatrixData.matrixColumns.push({
                      columnId: item[msMetricIdProperty],
                      columnLabel: item[msMetricLabelName],
                      columnItems: [],
                    })
                  }
                }
              })
              msConIdx = msConIdx + 1;
            }
          })
          msConIdx = 0;
          marketScan.competitors.forEach((msCompetitor) => {
            if (msCompetitor.action != Action.DELETE) {
              detailsScreenMatrixData.matrixColumns.forEach((column, columnIdx) => {
                column.columnItems.push(
                  {
                    columnItemId: msCompetitor.competitorId + '_' + columnIdx,
                    columnItemLabel: columnIdx == 0 ? msCompetitor.competitorName : '',
                    showExpandIcon: columnIdx == detailsScreenMatrixData.matrixColumns.length - 1 ? true : false,
                    childItems: [],
                  }
                );
              });
            }
            marketScan.category.measures.forEach((msMeasure, msIdx) => {
              if (msCompetitor.action != Action.DELETE && msMeasure.action != Action.DELETE) {
                matrixData[msConIdx].matrixTable[0].columnItems.push({
                  columnItemId: 'first_column_item' + msMeasure.measureId,
                  isHeader: false,
                  isTotal: false,
                  isInputField: false,
                  columnItemLabel: msMeasure.measureName,
                  columnItemSecondaryLabel: '',
                  isMoreHeight: false,
                });
                detailsScreenMatrixData.matrixColumns[0].columnItems[msConIdx].childItems.push(
                  {
                    childItemId: msMeasure.measureId,
                    childItemLabel: msMeasure.measureName,
                    childItemSecondaryLabel: '',
                    isMoreHeight: false,
                  }
                );
              }
              let msProIdx = 0;
              marketScan.products.forEach((msProduct) => {
                // ! add account id check in the below idx line from the commect c.accountID
                // let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.accountId && c.competitorId == msCompetitor.competitorId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);
                let idx = marketScan.marketScanCustomerDatas.findIndex(c => c.indskr_assessmentcategoryid == marketScan.category.indskr_assessmentcategoryid && c.competitorId == msCompetitor.competitorId && c.measureId == msMeasure.measureId && c.productId == msProduct.productId);

                let obj: MarketScanCustomerData;
                if (idx >= 0) {
                  obj = marketScan.marketScanCustomerDatas[idx];
                  if (msMeasure.action == Action.DELETE || msCompetitor.action == Action.DELETE || msProduct.action == Action.DELETE) {
                    marketScan.marketScanCustomerDatas[idx].action = Action.DELETE;
                  } else {
                    if (_.isEmpty(marketScan.indskr_marketscanid)) {
                      obj.action = Action.PATCH;
                    } else if(obj.accountId !== marketScan.accounts.find((account) => account.action !== Action.DELETE).accountId){
                      obj.accountId = marketScan.accounts.find((account) => account.action !== Action.DELETE).accountId;
                      obj.action = Action.PATCH;
                    }else {
                      if (obj.action == Action.DELETE) {
                        obj.action = Action.PATCH;
                      }
                    }
                  }
                } else {
                  if (!(msMeasure.action == Action.DELETE || msCompetitor.action == Action.DELETE || msProduct.action == Action.DELETE)) {
                    let accountId = marketScan.accounts.length ? marketScan.accounts.find((account) => account.action !== Action.DELETE).accountId : null
                    obj = new MarketScanCustomerData(this.generateRandomGuid(), marketScan.category.indskr_assessmentcategoryid, msMeasure.measureId, marketScan.category.indskr_name, msMeasure.measureName, msMeasure.measureType.toString(), marketScan.category.indskr_capturedata, marketScan.category.indskr_capturedatafor, msProduct.productId, accountId, '', 1, Action.PATCH, '', '', null, msCompetitor.competitorId);
                    marketScan.marketScanCustomerDatas.push(obj);
                  }
                }
                if (msCompetitor.action != Action.DELETE && msMeasure.action != Action.DELETE && msProduct.action != Action.DELETE) {
                  matrixData[msConIdx].matrixTable[msProIdx + 1].columnItems.push({
                    columnItemId: obj.indskr_externalid,
                    isHeader: false,
                    isTotal: false,
                    isInputField: !isReadOnlyView,
                    columnItemLabel: obj.rX ? obj.rX : (!isReadOnlyView ? null : '---'),
                    columnItemSecondaryLabel: '',
                    isMoreHeight: false,
                  });
                  detailsScreenMatrixData.matrixColumns[msProIdx + 1].columnItems[msConIdx].childItems.push(
                    {
                      childItemId: obj.indskr_externalid,
                      childItemLabel: obj && obj.rX ? obj.rX : '---',
                      childItemSecondaryLabel: '',
                      isMoreHeight: false,
                    }
                  );
                  msProIdx = msProIdx + 1;
                }
              });
            });
            if (msCompetitor.action != Action.DELETE) {
              msConIdx = msConIdx + 1;
            }
          });
        }
        if (_.isEmpty(marketScan.indskr_marketscanid)){
          marketScan.marketScanCustomerDatas = marketScan.marketScanCustomerDatas.filter(record => record.action != Action.DELETE);
        }
        this.currentMarketScanMatrixData = matrixData;
        this.currentMarketScanDetailsViewMatrix = detailsScreenMatrixData;
      }else{
        this.currentMarketScanMatrixData = [];
        this.currentMarketScanDetailsViewMatrix = null;
      }
    }

    public checkIfMarketScanCustomerDataCanBePopulated(marketScan:MarketScan):boolean {
      let flag:boolean = true;
      if(!marketScan.category){
        return false;
      }else if(marketScan.category.indskr_capturedatafor == CaptureDataFor.COMPETITOR){
        if(marketScan.competitors.filter(p => p.action != Action.DELETE).length == 0){
          return false;
        }
        if(marketScan.products.filter(p => p.action != Action.DELETE).length == 0){
          return false;
        }
        if(marketScan.accounts.filter(p => p.action != Action.DELETE).length == 0){
          return false;
        }
      }else if(marketScan.products.filter(p => p.action != Action.DELETE).length == 0){
        return false;
      }else if(marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
        if(marketScan.selectView){
          if(marketScan.selectView == 'ACCOUNT_VIEW'){
            if(marketScan.accounts.filter(p => p.action != Action.DELETE).length == 0){
              return false;
            }
          }else if(marketScan.selectView == 'CUSTOMER_VIEW'){
            if(marketScan.contacts.filter(p => p.action != Action.DELETE).length == 0){
              return false;
            }
          }
        }else{
          return false;
        }
      }else if(marketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS){
        if(marketScan.accounts.filter(p => p.action != Action.DELETE).length == 0){
          return false;
        }
      }else if(marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS){
        if(marketScan.contacts.filter(p => p.action != Action.DELETE).length == 0){
          return false;
        }
      }
      return flag;
    }

    private _sortMarketScanMetrics(marketScan:MarketScan){
      let compeProducts: MarketScanProduct[] = marketScan.products.filter(v => v.isCompetitorProduct);
      let otherProducts: MarketScanProduct[] = marketScan.products.filter(v => !v.isCompetitorProduct);
      otherProducts = this.sortListByFielName(otherProducts,'productName')
      otherProducts.push(...this.sortListByFielName(compeProducts,'productName'))
      marketScan.products = otherProducts;
      marketScan.accounts = this.sortListByFielName(marketScan.accounts,'accountName');
      marketScan.contacts = this.sortListByFielName(marketScan.contacts,'contact_fullName');
      if(marketScan.category && marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS){
        if(marketScan.selectView == 'ACCOUNT_VIEW'){
          marketScan.accounts.forEach(account => {
            account.affiliatedContacts = this.sortListByFielName(account.affiliatedContacts,'contact_fullName');
          });
        }else if(marketScan.selectView == 'CUSTOMER_VIEW'){
          marketScan.contacts.forEach(contact => {
            contact.affiliatedAccounts = this.sortListByFielName(contact.affiliatedAccounts,'accountName');
          });
        }
      }
      let cusAccMeasures: Measure[] = marketScan.category.measures.filter(v => v.captureFor == MeasureCaptureFor.CUSTOMERS_ACCOUNTS);
      let cusMeasures: Measure[] = marketScan.category.measures.filter(v => v.captureFor == MeasureCaptureFor.CUSTOMERS);
      cusAccMeasures = this.sortListByFielName(cusAccMeasures,'measureName');
      cusAccMeasures.push(...this.sortListByFielName(cusMeasures,'measureName'));
      marketScan.category.measures = cusAccMeasures;
    }

    public updateSubjectBasedOnFrequency(marketScan:MarketScan,catergoryUpdated: boolean = false) {
      if (!marketScan) return '';
      if (marketScan.statuscode === MarketScanStatusCode.Active) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_AUTO_SUBJECT)) {
          if (this.authService.user.marketScanCreateFrequency === CreateFrequency.MONTHLY && marketScan.indskr_date) {
            const month = moment(new Date(parseInt(marketScan.indskr_date))).format('MMMM').toUpperCase();
            const year = moment(new Date(parseInt(marketScan.indskr_date))).format('YYYY').toUpperCase();
            let categoryName:string = '';
            if (marketScan.category) {
              categoryName = marketScan.category.indskr_name.replace(" (" + this.translate.instant('INACTIVE') + ")", "");
            }
            //marketScan.indskr_name = this.translate.instant("CUSTOMER_SCAN") + " " + this.translate.instant("FOR") + " " + this.translate.instant(month);
            marketScan.indskr_name = this.translate.instant("CUSTOMER_SCAN") + " - " + categoryName + " - " + this.translate.instant(month) + " " + year;
          } else {
            marketScan.indskr_name = this.translate.instant("CUSTOMER_SCAN");
          }
          // if (marketScan.category) {
          //   const categoryName = marketScan.category.indskr_name.replace(" (" + this.translate.instant('INACTIVE') + ")", "");
          //   marketScan.indskr_name += " - " + categoryName;
          // }
        } else if (catergoryUpdated) {
          marketScan.indskr_name = this.translate.instant("CUSTOMER_SCAN") + " - " + marketScan.category.indskr_name;
        }
        //this.markScanDetailsPageTitle.title = marketScan.indskr_name;
      }
    }

    private generateRandomGuid() {
      return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
          var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
          return v.toString(16);
      });
    }

    private _getMarketScanServicePayload(marketScan:MarketScan){
      if(marketScan){
        let obj = {
          indskr_externalid: marketScan.indskr_externalid,
          statecode: marketScan.statecode,
          statuscode: marketScan.statuscode,
        };
        if(marketScan.indskr_marketscanid){
          obj['indskr_marketscanid'] = marketScan.indskr_marketscanid;
        }
        if(marketScan.indskr_name){
          obj['indskr_name'] = marketScan.indskr_name;
        }
        if(marketScan.indskr_date){
          obj['indskr_date'] = marketScan.indskr_date;
        }
        if(marketScan.indskr_enddate){
          obj['indskr_enddate'] = marketScan.indskr_enddate;
        }
        if(marketScan.ownerId){
          obj['ownerId'] = marketScan.ownerId;
        }
        if(marketScan.indskr_positionid){
          obj['indskr_positionid'] = marketScan.indskr_positionid;
        }
        if(marketScan.categoryId){
          obj['categoryId'] = marketScan.categoryId;
        }
        if(marketScan.customerSegmentations && marketScan.customerSegmentations.length >= 0){
          obj['customerSegmentations'] = marketScan.customerSegmentations.filter(a=> a.action);
        }
        if(marketScan.marketScanCustomerDatas && marketScan.marketScanCustomerDatas.length >= 0){
          obj['marketScanCustomerData'] = marketScan.marketScanCustomerDatas.filter(a=> a.action).map(a=> {
            let scanObj = {
              indskr_externalid: a.indskr_externalid,
            };
            if(a.indskr_assessmentcategoryid){
              scanObj['indskr_assessmentcategoryid'] = a.indskr_assessmentcategoryid;
            }
            if(a.measureId){
              scanObj['measureId'] = a.measureId;
            }
            if(a.categoryName){
              scanObj['categoryName'] = a.categoryName;
            }
            if(a.measureName){
              scanObj['measureName'] = a.measureName;
            }
            if(a.measureType){
              scanObj['measureType'] = a.measureType;
            }
            if(a.indskr_capturedata){
              scanObj['indskr_capturedata'] = a.indskr_capturedata;
            }
            if(a.indskr_capturedatafor){
              scanObj['indskr_capturedatafor'] = a.indskr_capturedatafor;
            }
            if(a.productId){
              scanObj['productId'] = a.productId;
            }
            if(a.accountId){
              scanObj['accountId'] = a.accountId;
            }
            if(a.contactId){
              scanObj['contactId'] = a.contactId;
            }
            // if(a.sequence){
            //   scanObj['sequence'] = a.sequence;
            // }
            if(a.action){
              scanObj['action'] = a.action;
            }
            if(a.indskr_eventid){
              scanObj['indskr_eventid'] = a.indskr_eventid;
            }
            if(a.indskr_campaignid){
              scanObj['indskr_campaignid'] = a.indskr_campaignid;
            }
            if(a.rX){
              scanObj['rX'] = isInteger(a.rX)?a.rX.toString():a.rX;
            }
            if(a.competitorId){
              scanObj['competitorId'] = a.competitorId;
              scanObj['categoryName'] = a.categoryName
            }
            return scanObj;
          });
        }
        return obj;
      }
    }

  // checks if market scan already exists for the selected period and selected catergory
  checkIfMarketScanExists(selectedCategoryId: string, marketScan:MarketScan): boolean {
    const marketScanDate = marketScan.indskr_date ? moment(parseInt(marketScan.indskr_date)).format() : undefined;
    if (_.isEmpty(marketScanDate) || _.isEmpty(selectedCategoryId)) return false;
    const selectedEndDate: Date = this.getEndDateAsPerConf(new Date(marketScanDate));
    const selectedCategory = this.categories.find((category) => category.indskr_assessmentcategoryid === selectedCategoryId);
    const marketScans: MarketScan[] = this.getAllMarketScans();
    const index = marketScans.findIndex((ms) => {
      if (ms.indskr_externalid !== marketScan.indskr_externalid && ms.indskr_date) {
        //For old data, end date will be empty
        if (!ms.indskr_enddate)
          ms.indskr_enddate = new Date(moment(new Date(parseInt(ms.indskr_date))).endOf('month').format()).getTime().toString();

        let needToCheckAccountId = selectedCategory.indskr_recordcapturedat != null;

        if (this.authService.user.marketScanCreateFrequency === CreateFrequency.MONTHLY) {
          return ((new Date(parseInt(ms.indskr_date)).getFullYear() === new Date(marketScanDate).getFullYear()) &&
            (new Date(parseInt(ms.indskr_date)).getMonth() === new Date(marketScanDate).getMonth())) && ms.categoryId === selectedCategoryId && (!needToCheckAccountId || this.checkAccountExists(marketScan, ms));
        } else
          return this.dateRangeOverlaps(new Date(parseInt(ms.indskr_date)), new Date(parseInt(ms.indskr_enddate)), new Date(marketScanDate), selectedEndDate) && ms.categoryId === selectedCategoryId && (!needToCheckAccountId || this.checkAccountExists(marketScan, ms)) ;
      }
    });
    return index >= 0;
  }

  checkAccountExists(currentMarketScan: MarketScan, ms: MarketScan): boolean {
    const selectedMarketScanAccountIds = currentMarketScan.accounts.map((account) => account.accountId);
    const accountIds = ms.marketScanCustomerDatas.map((account) => account.accountId);
    let x = accountIds.some((accountId) => selectedMarketScanAccountIds.some(acc => acc === accountId))
    console.log('account exists',x)

    return x;
  }

  dateRangeOverlaps(start1: Date, end1: Date, start2: Date, end2: Date) {
    //compare without time
    const a_start: number = start1.setHours(0, 0, 0, 0);
    const a_end: number = end1.setHours(0, 0, 0, 0);
    const b_start: number = start2.setHours(0, 0, 0, 0);
    const b_end: number = end2.setHours(0, 0, 0, 0);
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b
    return false;
  }


}
