import _ from "lodash";

export class OffTakeCheck {
  constructor(public indskr_offtakedate: Date,
    public modifiedby: string,
    public modifiedOn: Date,
    public accountId: string,
    public indskr_offtakechecktype: number,
    public collections: OffTakeCheckCollection[]) {

  }

  get requestPayload() {
    const payload = [];
    this.collections.forEach(prod => {
      if (!_.isEmpty(prod.indskr_offtakecheckid)) {
        payload.push({
          indskr_account: this.accountId,
          indskr_offtakedate: this.indskr_offtakedate.getTime(),
          indskr_sku: prod.skuId,
          indskr_offtakechecktype: this.indskr_offtakechecktype,
          indskr_skucode: prod.skuCode,
          indskr_brand: prod.productId,
          indskr_offtakecheckid: prod.indskr_offtakecheckid,
          indskr_distributedornot: prod.indskr_distributedornot,
          indskr_inventoryofftake: prod.indskr_inventoryofftake,
          indskr_storeinventory: prod.indskr_storeinventory
        })
      }
    })
    return payload;
  }
}



export class OffTakeCheckCollection {
  constructor(
    public indskr_offtakecheckid: string,
    public productId: string,
    public productName: string,
    public skuId: string,
    public skuName: string,
    public skuCode: string,
    public indskr_distributedornot: boolean,
    public indskr_inventoryofftake: number,
    public indskr_storeinventory: number
  ) {

  }
}

