<div [ngClass]="{'container-ios' : device.deviceFlags.ios}">
<!--ALL PLANS/PREDICTIONS AND INSIGHTS-->
  <ion-list>
    <ion-item-group>
      <!--PLANS AND PREDICTIONS-->
      <plans-predictions [source]="source"></plans-predictions>
      <!--INSIGHTS-->
      <journey-insights [source]="source"></journey-insights>
      <interest-insights [source]="source"></interest-insights>
      <relationship-insights [source]="source"></relationship-insights>
    </ion-item-group>
  </ion-list>
</div>
