import {ConsentTerm} from "./consent-term.class";

export class ContactConsent {
  constructor(
    public _id: String,
    public _rev: String,
    public indskr_contactid: string,
    public activeConsents: ConsentTerm[]
  ) {}
}
