<ion-header>
  <ind-page-title [viewData]="procedureTrackerDetailsPanePageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ng-container>
    <ion-list class='details-list' *ngIf="currentProcedureTrackerActivity">
      <ind-section-header [viewData]='detailsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <ind-form-field [viewData]="orderNumberFormField"></ind-form-field>
      <ion-row>
        <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
        <ion-col size="6"><ind-datetime-form *ngIf="startTimeField" [viewData]="startTimeField"></ind-datetime-form></ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
        <ion-col size="6"><ind-datetime-form *ngIf="endTimeField" [viewData]="endTimeField"></ind-datetime-form></ion-col>
      </ion-row>
      <ind-form-field [viewData]="createdByFormField"></ind-form-field>
      <ind-form-field [viewData]="modifiedDateFormField"></ind-form-field>
      <ind-form-field [viewData]="modifiedByFormField"></ind-form-field>
      <ind-form-field [viewData]="divisionFormField"></ind-form-field>
      <ind-form-field [viewData]="accountFormField"></ind-form-field>
      <ind-form-field *ngIf="isHideOpportunity" [viewData]="OpportunityFormField"></ind-form-field>
      <ind-form-field [viewData]="coOwnersFormField"></ind-form-field>
      <ind-section-header [viewData]="procedureTrackerHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <div class="tracker-container">
        <table *ngIf="currentProcedureTrackerActivity.procedureTrackers?.length > 0">
          <thead>
            <tr>
              <th>{{'PROCEDURE' | translate}}</th>
              <th>{{'NO_OF_PROCEDURES' | translate}}</th>
              <th>{{'XPERIENCES_TRENDING_CUSTOMERS' | translate : { globalCustomerText : globalCustomerText } }}</th>
              <th>{{'PROCEDURES_SUPPORTED' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pt of currentProcedureTrackerActivity.procedureTrackers"  (click)="editProcedureTracker(pt)">
              <td>{{pt.procedureName}}</td>
              <td>{{pt.indskr_quantity}}</td>
              <td>{{pt.currentCustomerString}}</td>
              <td>{{pt.indskr_casessupported}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <ion-item class="placeholderEmptyDiv" *ngIf="(!currentProcedureTrackerActivity.procedureNotes || currentProcedureTrackerActivity.procedureNotes.length==0) && isReadOnlyMode"></ion-item>
      <ind-text-area *ngIf="!isReadOnlyMode" id="notesInput" [placeHolder]="notesPlaceholder" [maxLength]="1000" (indChange)="notesChanged($event)"
      [value]="tempNoteText" [rows]="4" [cols]="10" [disabled]="backgroundUploadInProgress"> </ind-text-area>
      <io-note *ngFor="let note of currentProcedureTrackerActivity.procedureNotes|filterBy:{isDeleted:false}" [inputNote]="note" [editable]="isNoteControlsEnabled(note)"
      [deletable]="isNoteControlsEnabled(note)" (saveNoteOut)="updateNote($event)">
      </io-note>
    </ion-list>
  </ng-container>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('ProcedureTracker')" (buttonClicked)="handleButtonClick($event)" [footerView]="'detailView'" [selectedView]="''"></footer-toolbar>
