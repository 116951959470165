<ion-header>
  <ind-header-left
    [viewData]="indHeaderLeftModel"
    (onControlClick)="onPageTitleControlClick($event)"
    [(searchText)]="searchText"
    [searchDisabled]="mainList.length === 0 && !searchText"
    [searchDebounce]="300"
    (onSearchChange)="onSearch()"
    (onSearchClear)="onSearchCancel($event)"
    hideFilterButton="true"
  >
  </ind-header-left>
  <ind-key-controls-area
    [viewData]="keyControlAreaModel"
  >
  </ind-key-controls-area>
</ion-header>

<ion-content>
  <ind-section-header [viewData]="sectionHeaderModel"></ind-section-header>
  <div [ngSwitch]="viewState">
    <div *ngSwitchCase="'NoData'">
      <div class="no-data-message">
        {{ 'NO_REPORT' | translate}}
      </div>
    </div>
    <div *ngSwitchCase="'EmptySearchResult'">
      <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </div>
    <div *ngSwitchCase="'List'">
      <ion-list class="list">
        <main-card [viewData]="getMainCardModel(record)" *ngFor="let record of displayList"></main-card>
      </ion-list>
    </div>
  </div>
</ion-content>
