import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { DeviceService } from '@omni/services/device/device.service';
import { AssessmentAttributeType, AssessmentTemplate, AttributeSection, ChoiceOption, Control, RatingScaleType } from '@omni/classes/customer-assessment/assessment-template.class';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { format } from 'date-fns';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { IndDropdownListComponent } from '../../shared/ind-dropdown-list/ind-dropdown-list';
import { UIService } from '@omni/services/ui/ui.service';
import { AssessmentGrid, AssessTeamviewRefData, ColumnType, CustomerAssessment, InputFieldType } from '@omni/classes/customer-assessment/customer-assessment.class';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { DatePipe } from '@angular/common';
import { TherapeuticAreaDataService } from '@omni/data-services/therapeutic-area/therapeutic-area.data.service';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { Contact, Specialty } from '@omni/classes/contact/contact.class';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { EventsService } from '@omni/services/events/events.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import moment from 'moment';
import { CurViewPageType, DateTimeFieldType } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import _ from 'lodash';
import { SelectListData } from '@omni/components/popover/popover';

@Component({
  selector: 'customer-assess-teamview',
  templateUrl: 'customer-assess-teamview.html',
  styleUrls: ['customer-assess-teamview.scss']
})
export class CustomerAssessTeamviewComponent {
  public currentFormView: Array<{
    id: string,
    type: string,
    isSharedSection: boolean,
    view: any,
    data?: string,
    control?: Control,
  }> = [];
  @Input() isLoading: boolean;
  @Input() templates: AssessmentTemplate[];
  @Input() templatesTeamview: AssessmentTemplate[];
  @Output() isFormDirty = new EventEmitter<boolean>();
  @Output() savedData = new EventEmitter<CustomerAssessment>();
  // @Input() rawData: CustomerAssessment;
  @Input() specialty: Specialty;
  
  private masterData: { positions: AssessTeamviewRefData[], ta: AssessTeamviewRefData[], products: AssessTeamviewRefData[], procedures: any[], positionGroups: AssessTeamviewRefData[] };
  private _dropdownPopover: HTMLIonPopoverElement;
  public allFilterFormView: IndFilterMenuModalDataModel[] = [];
  private templateFilterFormView: IndFilterMenuModalDataModel;
  private selectedTemplate: AssessmentTemplate;
  public rawData: CustomerAssessment;
  public groupedAssessRawData: CustomerAssessment[] = [];
  public teamAssessmentSectionHeader: IndSectionHeaderViewDataModel;
  public getSelectedTemplate:  IndFormFieldViewDataModel;
  public getTemplateSelection: IndFormFieldViewDataModel;
  public selectedTemplateName: IndFormFieldViewDataModel;
  private _currentFormValue: { [x: string]: any } = {};
  public seletedCustomers: Contact[];
  public assessGrid: AssessmentGrid[] = [];
  public sharedSectionFormView: Array<{
    id: string,
    type: string,
    isSharedSection: boolean,
    view: any,
    data?: string,
    control?: Control,
  }> = [];
  public sectionsFormView: Array<{
    sectionId: string,
    isExpanded: boolean,
    sectionHeaderViewData: IndSectionHeaderViewDataModel,
    assessGrid: AssessmentGrid[]
  }> = [];
  //View-Card
  public cardFormViewList: Array<{
    sectionId: string,
    viewData: Array<{}>;
  }> = [];
  public positionCardFormView: Array<{
    sectionId: string,
    isExpanded: boolean,
    sectionHeaderViewData: IndSectionHeaderViewDataModel,
    viewCard: any[]
  }> = [];
  private _positionCardFormView: Array<{
    positionId: string,
    rawData: CustomerAssessment,
    currentFormView: Array<{
      id: string,
      type: string,
      isSharedSection: boolean,
      view: any,
      data?: string,
      control?: Control,
    }>
  }> = [];
  public _formViewForViewCard: Array<{
    id: string,
    type: string,
    isSharedSection: boolean,
    view: any,
    data?: string,
    control?: Control,
  }> = [];

  public multipleFormView: Array<{
    positionId: string,
    rawData: CustomerAssessment,
    currentFormView: Array<{
      id: string,
      type: string,
      isSharedSection: boolean,
      view: any,
      data?: string,
      control?: Control,
    }>
  }> = [];
  private _multipleFormValue: Array<{
    positionId: string,
    sectionId: string,
    id: string,
    currentValue: { [x: string]: any }
  }> = [];
  private selectedProcedures: Array<{
    positionId: string,
    sectionId: string,
    section_general: string,
    specialty: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>
  }> = [];
  private selectedProducts: Array<{
    positionId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>
  }> = [];
  private selectedPositions: Array<{
    positionId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>  
  }> = [];
  private selectedPositionGroups: Array<{
    positionId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>  
  }> = [];
  private selectedTAs: Array<{
    positionId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>
  }> = [];

  private isGeneratedCardFormView: boolean = false;

  constructor(
    public customerAssessService: CustomerAssessService,
    public readonly device: DeviceService,
    public translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly popoverCtrl: PopoverController,
    public navService: NavigationService,
    private uiService: UIService,
    private readonly therapeuticService: TherapeuticAreaDataService,
    private readonly localizationService: LocalizationService,
    public navParams: NavParams,
    private contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    private readonly events: EventsService,
    public _cd: ChangeDetectorRef,
    public modalCtrl: ModalController,
    private datePipe: DatePipe,
  ) { 

  }

  async ngOnInit() {
    this._initSectionHeaderModel();
    this._initSelectedTemplate();
    if(this.selectedTemplate.indskr_entity == 'contact') {
      this.events.publish('refreshContactDetailPageTitleOnly');
    }else {
      this.events.publish('refreshAccountDetailPageTitle');
    }
    await this._initCustomerAssessDataTeamview().then(()=>{
      this.masterData = { 
        positions: this.customerAssessService.positionsTeamview,
        ta: this.therapeuticService.therapeuticAreasTeamview,
        products: this.customerAssessService.productsTeamview,
        procedures: [],
        positionGroups: this.customerAssessService.positionGroupsTeamview,
      };
      if (this.specialty) {
        this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
      }
      this._initMultipleAssessData();
      this._initViewData();
    });
  }

  ngOnDestroy() {
    this.events.unsubscribe('refreshContactDetailPageTitleOnly');
    this.events.unsubscribe('refreshAccountDetailPageTitle');
  }

  /*------------------init data------------------*/
  private _initSelectedTemplate() {
    this.allFilterFormView = this.customerAssessService.assessmentTeamViewFielterMenuData;
    let idx = this.allFilterFormView.findIndex(v=>v.id == 'teamview-template-field');
    if(idx > -1) {
      this.templateFilterFormView = this.allFilterFormView[idx];
    }
    const filteredTemplateId = this.templateFilterFormView.options.find(o=>o.isSelected).id;
    const templateIdx = this.templatesTeamview.findIndex(t=>t.indskr_assessmenttemplateid == filteredTemplateId);
    if(templateIdx > -1) {
      this.selectedTemplate = this.templatesTeamview[templateIdx];
    }
  }

  private _initSectionHeaderModel() {
    this.teamAssessmentSectionHeader = {
      id: 'team_assessment_section_header',
      title: this.translate.instant("TEAM_ASSESSMENT"),
      controls: [{
        id: 'assessment-teamView-filter',
        isDisabled: this.device.isOffline,
        icon: 'assets/imgs/filter-blue.svg'
      }],
    };
  }

  private _initViewData() {
    if (this.selectedTemplate && !_.isEmpty(this.selectedTemplate.indskr_metadata)) {
      this.currentFormView = [];
      this.isGeneratedCardFormView = false;

      this.selectedTemplate.indskr_metadata.Sections.forEach(section => {
        if (section && !_.isEmpty(section.Controls)) {
          if (section.PositionGroup || section.TherapetuicArea || section.Product || section.Position || section.Procedure) {
            //set View-Card for only one section - position
            const isPositionOnly: boolean = section.Position && !section.TherapetuicArea && !section.Product && !section.Procedure && !section.PositionGroup;
            if(isPositionOnly && !this.isGeneratedCardFormView) {
              this._initCardFormView(section);
            }
          } else {
            //Shared section - there is no combination between position, TA, Product, Procedure, and Position Group
            this._getCurrentFormViewForControl(section, section.Controls);
          }
        }
      });

    const selectedTemplateName = this.templateFilterFormView.options.find(o=>o.isSelected).displayName;    
    this.getSelectedTemplate = {
      label: this.translate.instant("TEMPLATE"),
      inputText: selectedTemplateName,
      placeholderLabel: this.translate.instant("TEMPLATE"),
      id: 'selected-template-name',
      isReadOnly: true,
    }
    this.getTemplateSelection = {
      label: this.translate.instant("TEMPLATE"),
      inputText: this.templateFilterFormView?.options.find(o=>o.isSelected).displayName || '',
      placeholderLabel: this.translate.instant("TEMPLATE"),
      id: 'teamview-template-field',
      isReadOnly: true,
      isDisabled: this.device.isOffline,
      showArrow: true,
      imgSrc: 'assets/imgs/dropdown-open.svg',
      cssIcon: 'dropdown-open-big',
      eventHandler: (id: string, event) => this._handleFormFieldEvent(id, event),
    }
    //update shared section form view data
    this.sharedSectionFormView = this.currentFormView.filter(v=>v.isSharedSection);
    }
  }

  private _initCardFormView(section: AttributeSection) {
    this._clearCardFormViewValues();
    const targetSectionId: string = section.SectionProperties.GUID;
    let _targetCardFormViewData = this._positionCardFormView;
    let targetCardFormView = this.positionCardFormView;
    if(!_.isEmpty(this.groupedAssessRawData)) {
      this.groupedAssessRawData.forEach(rData=>{
        const positionId = rData['positionId'];
        _targetCardFormViewData.push({
          positionId: positionId,
          rawData: rData,
          currentFormView: []
        });
        let foundFormView = _targetCardFormViewData.find(view=>view.positionId == positionId).currentFormView;
        this.rawData = rData;
        const subSectionValues = this._getValuesForSubSectionView(section, null, positionId);
        if(!_.isEmpty(subSectionValues)) {
          Object.keys(subSectionValues).forEach(subSectionId => {
            let subSection = this._getSubSectionHeaderViewForTab(subSectionId);
            if (subSection) {
              const index: number = foundFormView.findIndex(view => view.id == subSectionId);
              if (index > - 1) {
                foundFormView[index] = subSection;
              } else {
                //Add subsection just below Section
                const index: number = foundFormView.findIndex(view => view.id == targetSectionId);
                foundFormView.splice(index + 1, 0, subSection);
              }
              //Generate controls views for each subSection
              for (let i = 0; i < Object.keys(subSectionValues[subSectionId]).length; i++) {
                this._initSubSecControlsCardFormView(section, subSectionValues[subSectionId], subSectionId, positionId, _targetCardFormViewData);
              }
            }
          });
          //set view card data
          const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);
          let sectionHeaderViewdata: IndSectionHeaderViewDataModel = {
            id: targetSectionId,
            title: headerLabel,
            controls: [{
              id: 'sections-card-view-expand',
              isDisabled: false,
              icon: 'assets/imgs/dropdown-open.svg',
            }],
          }
          let subSectionInfoArr = [];
          let fieldDataArr = [];
          foundFormView.forEach(v=> {
            if(v.type == 'sub-section-header') {
              let subSectionName = {
                id: v.view.id,
                name: v.view.title,
              }
              subSectionInfoArr.push(subSectionName);
            }else if(v.type == 'form-field') {
              let viewData = {
                id: v.view.id,
                label: v.view.label,
                inputText: v.view.inputText,
                imgSrc: v.view.imgSrc,
                eventHandler: (id: string, event, eventName, item) => this._handleGridFieldEvent(v.control.Attribute.GUID, event, eventName, section, positionId, v.view.id, true),
              }
              fieldDataArr.push(viewData);
            }
          });
          let subData = {
            sectionId: targetSectionId,
            isExpanded: false,
            sectionHeaderViewData: sectionHeaderViewdata,
            viewCard: [],
          }
          //Update card for view based on sub-sections
          subSectionInfoArr.forEach(p=>{
            let filteredFieldData = fieldDataArr.filter(d=>d.id.includes(p.id));
            let cardData = {
              boxLetterText: this._getDataAttr(p.name),
              positionName: p.name,
              fieldData: filteredFieldData  
            }
            if(_.isEmpty(targetCardFormView)) {
              let viewCardData = [];
              viewCardData.push(cardData);
              subData = {
                sectionId: targetSectionId,
                isExpanded: false,
                sectionHeaderViewData: sectionHeaderViewdata,
                viewCard: viewCardData,
              }
              targetCardFormView.push(subData);
            }else {
              let foundIdx = targetCardFormView.findIndex(v=>v.sectionId == subData.sectionId);
              if(foundIdx > -1) {
                targetCardFormView[foundIdx].viewCard.push(cardData);
              }else {
                let viewCardData = [];
                viewCardData.push(cardData);
                subData = {
                  sectionId: targetSectionId,
                  isExpanded: false,
                  sectionHeaderViewData: sectionHeaderViewdata,
                  viewCard: viewCardData,
                }
                targetCardFormView.push(subData);
              }
            }
          });
        }else {
          /*case: no responses from position section only but responses from the other sections. Display position section card form view with no responses  
            requirement change - do not show position info and attributes*/
          const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);
          const sectionHeaderViewdata: IndSectionHeaderViewDataModel = {
            id: targetSectionId,
            title: headerLabel,
            controls: [{
              id: 'sections-card-view-expand',
              isDisabled: false,
              icon: 'assets/imgs/dropdown-open.svg',
            }],
          }
    
          // let foundFormView = [];
          // section.Controls.forEach(control => {
          //   const isValidDateControl: boolean = this._isValidDateControl(control);
          //   if(isValidDateControl) {
          //     let view = this._getViewForControl(control, section, null);
          //     if (view) {
          //       const index: number = foundFormView.findIndex(view => view.id == control.Attribute.GUID);
          //       if (index > -1) {
          //         foundFormView[index] = view;
          //       } else {
          //         foundFormView.push(view);
          //       }
          //     }
          //   }
          // });

          // let fieldDataArr = [];
          // foundFormView.forEach(v=> {
          //  if(v.type == 'form-field') {
          //     let viewData = {
          //       id: v.view.id,
          //       label: v.view.label,
          //       inputText: v.view.inputText,
          //       imgSrc: v.view.imgSrc,
          //     }
          //     fieldDataArr.push(viewData);
          //   }
          // });
          let subData = {
            sectionId: targetSectionId,
            isExpanded: false,
            sectionHeaderViewData: sectionHeaderViewdata,
            viewCard: [],
          }
          //Update position information
          // let cardData = {
          //   boxLetterText: this._getDataAttr(rData.position),
          //   positionName: rData.position,
          //   fieldData: fieldDataArr  
          // }
          //Update targetCardFormView
          if(_.isEmpty(targetCardFormView)) {
            // let viewCardData = [];
            // viewCardData.push(cardData);
            // subData = {
            //   sectionId: targetSectionId,
            //   isExpanded: false,
            //   sectionHeaderViewData: sectionHeaderViewdata,
            //   viewCard: viewCardData,
            // }
            targetCardFormView.push(subData);
          }else {
            let foundIdx = targetCardFormView.findIndex(v=>v.sectionId == subData.sectionId);
            if(foundIdx > -1) {
              // targetCardFormView[foundIdx].viewCard.push(cardData);
            }else {
              // let viewCardData = [];
              // viewCardData.push(cardData);
              // subData = {
              //   sectionId: targetSectionId,
              //   isExpanded: false,
              //   sectionHeaderViewData: sectionHeaderViewdata,
              //   viewCard: viewCardData,
              // }
              targetCardFormView.push(subData);
            }
          }
        }
      });
    }else {
      /*case: no responses from all sections
        It will display attribute label without position info*/
      const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);
      const sectionHeaderViewdata: IndSectionHeaderViewDataModel = {
        id: targetSectionId,
        title: headerLabel,
        controls: [{
          id: 'sections-card-view-expand',
          isDisabled: false,
          icon: 'assets/imgs/dropdown-open.svg',
        }],
      }

      let foundFormView = [];
      section.Controls.forEach(control => {
        const isValidDateControl: boolean = this._isValidDateControl(control);
        if(isValidDateControl) {
          let view = this._getViewForControl(control, section, null);
          if (view) {
            const index: number = foundFormView.findIndex(view => view.id == control.Attribute.GUID);
            if (index > -1) {
              foundFormView[index] = view;
            } else {
              foundFormView.push(view);
            }
          } 
        }
      });

      let fieldDataArr = [];
      foundFormView.forEach(v=> {
       if(v.type == 'form-field') {
          let viewData = {
            id: v.view.id,
            label: v.view.label,
            inputText: v.view.inputText,
            imgSrc: v.view.imgSrc,
          }
          fieldDataArr.push(viewData);
        }
      });
      let subData = {
        sectionId: targetSectionId,
        isExpanded: false,
        sectionHeaderViewData: sectionHeaderViewdata,
        viewCard: [],
      }
      //no position information
      let cardData = {
        boxLetterText: '',
        positionName: '',
        fieldData: fieldDataArr  
      }
      //Update targetCardFormView
      if(_.isEmpty(targetCardFormView)) {
        let viewCardData = [];
        viewCardData.push(cardData);
        subData = {
          sectionId: targetSectionId,
          isExpanded: false,
          sectionHeaderViewData: sectionHeaderViewdata,
          viewCard: viewCardData,
        }
        targetCardFormView.push(subData);
      }else {
        let foundIdx = targetCardFormView.findIndex(v=>v.sectionId == subData.sectionId);
        if(foundIdx > -1) {
          targetCardFormView[foundIdx].viewCard.push(cardData);
        }else {
          let viewCardData = [];
          viewCardData.push(cardData);
          subData = {
            sectionId: targetSectionId,
            isExpanded: false,
            sectionHeaderViewData: sectionHeaderViewdata,
            viewCard: viewCardData,
          }
          targetCardFormView.push(subData);
        }
      }
    }
    //Update card form view list to display
    let listData = {
      sectionId: targetSectionId,
      viewData: targetCardFormView
    }
    this.cardFormViewList.push(listData);
    this.isGeneratedCardFormView = true;
  }

  private _initSubSecControlsGridFormView(section: AttributeSection, values, subSectionId: string, _targetGridFormViewData: any) {
    const controlsWithOrders = _.orderBy(section.Controls.filter(control => control.Order != 0), ['Order', 'Attribute.DisplayName'], ["desc", "desc"]);
    const controlsWithoutOrders = _.orderBy(section.Controls.filter(control => control.Order == 0), ['Attribute.DisplayName'], "desc");
    const controls: Control[] = [...controlsWithoutOrders, ...controlsWithOrders]
    controls.forEach(control => {
      const isValidDateControl: boolean = this._isValidDateControl(control);
      if (values.hasOwnProperty(control.Attribute.GUID) && isValidDateControl) {
        //Add view only if value is present
        let view = this._getViewForControl(control, section, subSectionId);
        if (view) {
          let index: number = _targetGridFormViewData.findIndex(formView => formView.id == view.id);
          if (index > -1) {
            _targetGridFormViewData.splice(index, 1);
          }
          //Add controls below its subsection
          index = _targetGridFormViewData.findIndex(view => view.id == subSectionId);
          _targetGridFormViewData.splice(index + 1, 0, view);
        }
      }
    });
  }

  private _initSubSecControlsCardFormView(section: AttributeSection, values, subSectionId: string, positionId: string, _targetCardFormViewData: any) {
    const controlsWithOrders = _.orderBy(section.Controls.filter(control => control.Order != 0), ['Order', 'Attribute.DisplayName'], ["desc", "desc"]);
    const controlsWithoutOrders = _.orderBy(section.Controls.filter(control => control.Order == 0), ['Attribute.DisplayName'], "desc");
    const controls: Control[] = [...controlsWithoutOrders, ...controlsWithOrders]
    controls.forEach(control => {
      const isValidDateControl: boolean = this._isValidDateControl(control);
      if (values.hasOwnProperty(control.Attribute.GUID) && isValidDateControl) {
        //Add view only if value is present
        let view = this._getViewForControl(control, section, subSectionId);
        if (view) {
          let foundFormView = _targetCardFormViewData.find(view=>view.positionId == positionId).currentFormView;
          let index: number = foundFormView.findIndex(formView => formView.id == view.id);
          if (index > -1) {
            foundFormView.splice(index, 1);
          }
          //Add controls below its subsection
          index = foundFormView.findIndex(view => view.id == subSectionId);
          foundFormView.splice(index + 1, 0, view);
        }
      }
    });
  }

  private async _initCustomerAssessDataTeamview() {
    const entity = this.selectedTemplate.indskr_entity;
    const entityId = this.selectedTemplate.indskr_entity == 'contact' ? this.contactService.contactInformation.ID : this.accountService.selected.id;
    const selectedTemplateId = this.templateFilterFormView.options.find(o=>o.isSelected).id;
    await this._fetchAssessRawDataTeamview(entity, entityId, selectedTemplateId);
  }

  private async _initMultipleAssessData() {
    if(this.selectedTemplate.indskr_entity == 'contact') {
      const selectedContact = this.contactService.contactInformation;
      this._initGridForMultipleAssess(this.groupedAssessRawData, selectedContact);  
    }else {
      this._initGridForMultipleAssess(this.groupedAssessRawData);
    }
  }

  private _initGridForMultipleAssess(groupedAssessRawData: CustomerAssessment[], selectedContact?: Contact) {
    this.sectionsFormView = [];
    this.assessGrid = [];
    this.multipleFormView = [];
    /*-----First Column-----*/
    this.assessGrid = [{
      columnId: 'first_column',
      columnSubId: 'first_column',
      columnType: ColumnType.CONTACT_LIST,
      columnItems: [
        {
          columnItemId: 'first_column_item_first',
          isHeaderFirst: true,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: '',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },
        {
          columnItemId: 'first_column_item_second',
          isHeaderFirst: false,
          isHeaderSecond: true,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: this.translate.instant('POSITION'),
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },
      ]
    }]
    //set position name
    groupedAssessRawData.forEach(rData => {
      const positionId = rData['positionId'];
      this.assessGrid.find(a => a['columnId']=='first_column').columnItems.push(
        {
          columnItemId: positionId,
          isHeaderFirst: false,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: rData.position,
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        }
      );
      //set multipleFormView to find proper rawData in setting Second & after columns
      let _targetGridFormViewData: Array<{
        id: string,
        type: string,
        isSharedSection: boolean,
        view: any,
        data?: string,
        control?: Control,
      }> = [];
      this.selectedTemplate.indskr_metadata.Sections.forEach(section => {
        // let foundFormView = _targetGridFormViewData.find(view=>view.positionId == positionId).currentFormView;
        this.rawData = rData;
        if (section && !_.isEmpty(section.Controls)) {
          let secHeader = this._getSectionHeaderViewForTab(section);
          if (secHeader) {
            const index: number = _targetGridFormViewData.findIndex(view => view.id == section.SectionProperties.GUID);
            if (index > - 1) {
              _targetGridFormViewData[index] = secHeader;
            } else {
              _targetGridFormViewData.push(secHeader);
            }
          }
          if (section.PositionGroup || section.TherapetuicArea || section.Product || section.Position || section.Procedure) {
            const subSectionValues = this._getValuesForSubSectionView(section, null);
            if (_.isEmpty(Object.keys(subSectionValues))) {
              //If no controls/sub-sections available in section specific
              _targetGridFormViewData.push({
                id: section.SectionProperties.GUID.concat("_empty-continer"),
                type: 'empty-container',
                isSharedSection: false,
                view: null
              })
            } else {
              //once some controls/subsections are available remove empty container
              const index = _targetGridFormViewData.findIndex(view => view.id == section.SectionProperties.GUID.concat("_empty-continer"));
              if (index > -1) _targetGridFormViewData.splice(index, 1);
              //Iterate subSectionValues to generate subSection
              Object.keys(subSectionValues).forEach(subSectionId => {
                let subSection = this._getSubSectionHeaderViewForTab(subSectionId);
                if (subSection) {
                  const index: number = _targetGridFormViewData.findIndex(view => view.id == subSectionId);
                  if (index > - 1) {
                    _targetGridFormViewData[index] = subSection;
                  } else {
                    //Add subsection just below Section
                    const index: number = _targetGridFormViewData.findIndex(view => view.id == section.SectionProperties.GUID);
                    _targetGridFormViewData.splice(index + 1, 0, subSection);
                  }
                  //Generate controls views for each subSection
                  for (let i = 0; i < Object.keys(subSectionValues[subSectionId]).length; i++) {
                    this._initSubSecControlsGridFormView(section, subSectionValues[subSectionId], subSectionId, _targetGridFormViewData);
                  }
                }
              });
            }
          }
        }
      });
      this.multipleFormView.push({
        positionId: positionId,
        rawData: rData,
        currentFormView: _targetGridFormViewData
      });
    });
    /*-----Second Column and after-----*/
    let gridColumnIdx = 1;
    this.selectedTemplate.indskr_metadata.Sections.forEach((section) => {
      if(section.TherapetuicArea || section.Product || section.Position || section.PositionGroup || section.Procedure) {
        const isPositionOnly: boolean = section.Position && !section.TherapetuicArea && !section.Product && !section.Procedure && !section.PositionGroup;
        let headerSecondForSection = [];
        let buttons = [];

        /* 1.set selectedPositions, selectedTAs, selectedProducts, selectedProcedures, selectedPositionGroups 
           2.set headerSecondForSection id - which will be parsed to subSectionDetails
           3.get response with subSectionDetails from rawData */
        this.multipleFormView.forEach((view) => {
          this.rawData = view.rawData;
          if(selectedContact) this.specialty = this.contactService.getContactByID(selectedContact.ID)?.primarySpecialty;
          if(section.TherapetuicArea) this._updateSectionGeneralValueFromResponse(section, 'TherapetuicArea', view.positionId);
          if(section.Position) this._updateSectionGeneralValueFromResponse(section, 'Position', view.positionId);
          if(section.PositionGroup) this._updateSectionGeneralValueFromResponse(section, 'PositionGroup', view.positionId);
          if(section.Product) this._updateSectionGeneralValueFromResponse(section, 'Product', view.positionId);
          this.masterData.procedures = [];
          if (this.specialty) this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
          
          if(!isPositionOnly) {
            let headerSecond = this._getHeaderSecondForSectionControl(section, view.positionId);
            let idx = -1;
            if(!_.isEmpty(headerSecond)) {
              headerSecond.forEach((header) => {
                if(!_.isEmpty(headerSecondForSection)) {
                  idx = headerSecondForSection.findIndex((hs) => hs.id == header.id);
                  if(idx>-1) headerSecondForSection[idx] = header;
                  else headerSecondForSection.push(header);
                } else headerSecondForSection.push(header);
              });
            }
          }
        });
        if(_.isEmpty(headerSecondForSection)) {
          this._pushEmptyColumnToGrid(gridColumnIdx, section.SectionProperties.GUID, 'Empty', section, groupedAssessRawData, buttons);
          gridColumnIdx++;
        }
        
        let numberToIterate = 1;
        numberToIterate = headerSecondForSection.length;
        //loop for the subsection controls
        for(let i=0; i<=(numberToIterate-1); i++) {
          let headerSecondLabel, headerSecondSecondaryLabel, headerSecondId;
          if(!_.isEmpty(headerSecondForSection)) {
            headerSecondLabel = headerSecondForSection[i].view.title;
            headerSecondSecondaryLabel =headerSecondForSection[i].view.secondaryText;  
            headerSecondId = headerSecondForSection[i].view.id;
          }
          //set section attribute name(_getLabeltext) and columnItemLabel(itemLabel)
          section.Controls.forEach((control) => {
            const isValidDateControl: boolean = this._isValidDateControl(control);
            if(isValidDateControl) {
              this.assessGrid[gridColumnIdx] = {
                columnId: section.SectionProperties.GUID,
                columnSubId: control.Attribute.GUID,
                columnType: ColumnType.COLUMN_SECTION,
                sectionIdx: i,
                headerSecondId: headerSecondId,
                headerSecondLabel: { primary: headerSecondLabel, secondary: headerSecondSecondaryLabel },
                columnItems: [
                  {
                    columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_header_first',
                    isHeaderFirst: true,
                    isHeaderSecond: false,
                    isHeaderThird: false,
                    isInputField: false,
                    inputFieldType: InputFieldType.NONE,
                    endSlotItemType: 'none',
                    columnItemLabel: '',
                    columnItemSecondaryLabel: '',
                    isRequired: false,
                    isMoreHeight: false,
                    controls: this.assessGrid[gridColumnIdx-1].columnId != section.SectionProperties.GUID ? buttons : undefined
                  },
                  {
                    columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_header_second',
                    isHeaderFirst: false,
                    isHeaderSecond: true,
                    isHeaderThird: false,
                    isInputField: false,
                    inputFieldType: InputFieldType.NONE,
                    endSlotItemType: 'none',
                    columnItemLabel: this._getLabeltext(control), 
                    columnItemSecondaryLabel: '',
                    isRequired: false,
                    isMoreHeight: false,
                  },
                ]
              }
              //set columnItemLabel- field value from each position responses
              groupedAssessRawData.forEach((rData) => {
                this.rawData = this.multipleFormView.find((view) => view.positionId == rData.positionId).rawData;
                this.currentFormView = this.multipleFormView.find((view) => view.positionId == rData.positionId).currentFormView;
                const inputTextObj = this._getInputTextForFormField(control, section, headerSecondForSection[i]?.id, true, rData.positionId, headerSecondId);
                let isDropdown: boolean = false;
                let itemLabel = inputTextObj.value;
                if(!_.isEmpty(inputTextObj) && inputTextObj.controlType == AssessmentAttributeType.CHOICE && inputTextObj.selectedValues && inputTextObj.selectedValues.length > 1) {
                  isDropdown = true;
                }
                this.assessGrid[gridColumnIdx].columnItems.push({
                  columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_' + rData.positionId,
                  isHeaderFirst: false,
                  isHeaderSecond: false,
                  isHeaderThird: false,
                  isInputField: true,
                  inputFieldType: this._getFieldType(control),
                  endSlotItemType: 'arrow',
                  columnItemLabel: itemLabel,
                  columnItemSecondaryLabel: '',
                  placeholder: '',
                  isMoreHeight: false,
                  isDisabled: false,
                  isRequired: false,
                  imgSrc: !isDropdown ? '' : 'assets/imgs/menu_arrow_right.svg',
                  eventHandler:(id:string, event:any, eventName:string, item)=>this._handleGridFieldEvent(control.Attribute.GUID, event, eventName, section, rData.positionId, headerSecondId, true),
                })
              })
              headerSecondLabel = '';
              headerSecondSecondaryLabel = '';
              gridColumnIdx++;
            }
          });
        }

        if(!isPositionOnly) {
          const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);
          let sectionHeaderViewdata: IndSectionHeaderViewDataModel = {
            id: section.SectionProperties.GUID,
            title: headerLabel,
            controls: [{
              id: 'sections-form-view-expand',
              isDisabled: false,
              icon: 'assets/imgs/dropdown-open.svg',
            }],
          }
          let groupedSubSections: any = [];
          let foundFirstColumn = this.assessGrid.filter(g=>g.columnId == 'first_column');
          let filteredSections = this.assessGrid.filter(g=>g.columnId == section.SectionProperties.GUID && g.columnType != ColumnType.COLUMN_GENERAL);
          groupedSubSections = _.groupBy(filteredSections, 'headerSecondId');
          groupedSubSections = _.values(groupedSubSections);
          
          groupedSubSections.forEach((subSection: AssessmentGrid, idx)=>{
            let firstColumn = _.cloneDeep(foundFirstColumn);
            let updateFirstColumn = firstColumn[0].columnItems?.find(i=>i['columnItemId'] == 'first_column_item_first');
            if(subSection[0]?.headerSecondLabel?.primary || subSection[0]?.headerSecondLabel?.secondary) {
              updateFirstColumn.columnItemLabel = subSection[0]?.headerSecondLabel?.primary || '';
              updateFirstColumn.columnItemSecondaryLabel = subSection[0]?.headerSecondLabel?.secondary || '';
            }
            if(_.isEmpty(updateFirstColumn?.columnItemLabel) && !_.isEmpty(firstColumn[0]['columnItems']) && !_.isEmpty(subSection)) {
              firstColumn[0]['columnItems'][0].isForcedHide = true;
              for (let [, value] of Object.entries(subSection)) {
                if (value && value['columnItems']) {
                  value['columnItems'][0].isForcedHide = true;
                }
              }
            }
            let filteredGrid = firstColumn.concat(subSection);

            /* force hide row if there is no response */
            let noResponseIndexList = [];
            let responseIndexList = [];
            filteredGrid.forEach((col,i) => {
              //start from 2nd column-responses, 1st column-sub section and position
              if(i > 0) {
                col.columnItems.forEach((item,idx)=>{
                  //start from 3rd row-responses, 1st row-blank, 2nd row-section attribute label
                  if(idx > 1){
                    if(_.isEmpty(item.columnItemLabel)) {
                      let foundIdx = responseIndexList.findIndex(v=>v == idx);
                      if(foundIdx == -1) {
                        let foundIdx = noResponseIndexList.findIndex(v=>v == idx);
                        if(foundIdx == -1) {
                          noResponseIndexList.push(idx);
                        }
                      }
                    }else {
                      let foundRespIdx = responseIndexList.findIndex(v=>v == idx);
                      if(foundRespIdx == -1) {
                        responseIndexList.push(idx);
                      }
                      let foundNoRespIdx = noResponseIndexList.findIndex(v=>v == idx);
                      if(foundNoRespIdx > -1) {
                        noResponseIndexList.splice(0,foundNoRespIdx);
                      }
                    }
                  }
                });
              }
            });
            //set to force hide
            if(!_.isEmpty(noResponseIndexList)) {
              filteredGrid.forEach(col=>{
                noResponseIndexList.forEach(v=>{
                  col.columnItems[v].isForcedHide = true;
                  console.log("Forced hide row ",col.columnItems[v], v, sectionHeaderViewdata.title);
                });
              });
            }

            const subData = {
              isExpanded: false,
              sectionId: section.SectionProperties.GUID,
              sectionHeaderViewData: sectionHeaderViewdata,
              assessGrid: filteredGrid,
            }
            if(idx > 0) subData.sectionHeaderViewData = undefined;
            this.sectionsFormView.push(subData);
          });
        }else {
          //only one section is covered by Card Form View
        }
      }
    });
  }

  /*------------------get labels------------------*/
  private _getLabeltext(control: Control) {
    let labelText = control.DisplayName;
    if (control.Attribute.hasOwnProperty('DisplayNames')) {
      const displayNames = control.Attribute['DisplayNames'];
      if (!_.isEmpty(displayNames)) {
        const displayName = displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        if (displayName) {
          labelText = displayName.Description;
        } else {
          let foundTranslatedStr = this.localizationService.getTranslatedString(labelText);
          if(!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
        }
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(labelText);
        if(!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
      }
    }
    return labelText;
  }

  /*------------------get sections------------------*/
  private _getSectionId(formValue) {
    return [formValue['indskr_position'], formValue['indskr_therapeuticarea'], formValue['indskr_product'], formValue['indskr_procedures'], formValue['indskr_positiongroup']].join("_")
  }

  private _getSectionHeaderViewForTab(section: AttributeSection) {
    try {
      const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);
      let sectionHeader = {
        id: section.SectionProperties.GUID,
        type: 'section-header',
        isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
        view: {
          id: headerLabel,
          title: headerLabel,
          controls: [],
          doNotModifyTitleCase: true
        },
      };
      return sectionHeader;
    } catch (error) {
      console.log('Error parsing section header view from tab' + error);
    }
  }

  private _getSubSectionHeaderViewForTab(subSectionId: string) {
    try {
      const subSectionDetails = subSectionId ? this.customerAssessService.parseSectionId(subSectionId) : null;
      const headerLabel = this.customerAssessService.getSelectionTemplateTeamview(subSectionDetails, this.masterData, null, this.specialty);
      let sectionHeader = {
        id: subSectionId,
        type: 'sub-section-header',
        isSharedSection: false,
        view: {
          id: subSectionId,
          title: headerLabel.name,
          secondaryText: headerLabel.secondaryText,
          controls: [],
        },
      };
      return sectionHeader;
    } catch (error) {
      console.log('Error parsing sub-section header view from tab : ' + error);
    }
  }

  /*------------------get form view------------------*/
  private _getCurrentFormViewForControl(section: AttributeSection, controls: Control[]) {
    let secHeader = this._getSectionHeaderViewForTab(section);
    if (secHeader) {
      const index: number = this.currentFormView.findIndex(view => view.id == section.SectionProperties.GUID);
      if (index > - 1) {
        this.currentFormView[index] = secHeader;
      } else {
        this.currentFormView.push(secHeader);
      }
    }
    controls.forEach(control => {
      const isValidDateControl: boolean = this._isValidDateControl(control);
      if(isValidDateControl) {
        let view = this._getViewForControl(control, section, null);
        if (view) {
          const index: number = this.currentFormView.findIndex(view => view.id == control.Attribute.GUID);
          if (index > -1) {
            this.currentFormView[index] = view;
          } else {
            this.currentFormView.push(view);
          }
        }
      }
    });
  }

  private _getViewForControl(control: Control, section: AttributeSection, subSectionId: string) {
    if (control.Type) {
      try {
        //Account Contact Criteria match
        if (this.selectedTemplate.indskr_entity == 'contact') {
          if (control.Attribute.ContactType || control.Attribute.Designation || control.Attribute.Specialty) {
            if (control.Attribute.ContactType && !control.Attribute.Designation && !control.Attribute.Specialty) {
              if (!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) {
                return;
              }
            } else if (control.Attribute.ContactType && control.Attribute.Designation && !control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) || (!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id)) {
                return;
              }
            } else if (control.Attribute.ContactType && control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) || (!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id) || !this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (!control.Attribute.ContactType && control.Attribute.Designation && !control.Attribute.Specialty) {
              if (!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id) {
                return;
              }
            } else if (!control.Attribute.ContactType && control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id) || !this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (!control.Attribute.ContactType && !control.Attribute.Designation && control.Attribute.Specialty) {
              if (!this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (control.Attribute.ContactType && !control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) || !this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            }
          }
        } else {
          const account = this.accountService.selected;
          if (control.Attribute.AccountType) {
            if (!account.extendedDetails.accountTypeOption || control.Attribute.AccountType['SelectedValue'] != account.extendedDetails.accountTypeOption) {
              return;
            }
          }
        }
        const labelText: string = this._getLabeltext(control);
        const fieldType = this.customerAssessService.getFormFieldType(control);
        const inputTextObj = this._getInputTextForFormField(control, section, subSectionId, false);
        let isDropdown: boolean = false;
        let inputText = inputTextObj.value;
        if(!_.isEmpty(inputTextObj) && inputTextObj.controlType == AssessmentAttributeType.CHOICE && inputTextObj.selectedValues && inputTextObj.selectedValues.length > 1) {
          isDropdown = true;
        }
        const controlId = (section.PositionGroup || section.Position || section.Product || section.TherapetuicArea || section.Procedure) && subSectionId ? subSectionId.concat("_").concat(control.Attribute.GUID) : control.Attribute.GUID;
        let positionId: string = '';
        if(!_.isEmpty(this.rawData) && this.rawData.positionId) {
          positionId = this.rawData.positionId;
        }
        
        if (control.Type != AssessmentAttributeType.TIMESTAMP) {
          let fieldView: IndFormFieldViewDataModel = {
            label: labelText,
            inputText: inputText,
            inputValue: inputText,
            placeholderLabel: ' ',
            id: controlId,
            isReadOnly: true,
            formFieldType: fieldType,
            isInvalid: false,
            isDisabled: false,
            isTextNextIcon: true,
            imgSrc: !isDropdown ? '' : 'assets/imgs/menu_arrow_right.svg',
            eventHandler: (id: string, event, eventName, dataRef) => this._handleFormFieldEvent(control.Attribute.GUID, event, eventName, positionId),
          };
          return {
            id: fieldView.id,
            type: 'form-field',
            isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
            view: fieldView,
            control: control
          };
        }else if (control.Type == AssessmentAttributeType.TIMESTAMP) {
          return this._getViewForTimeStampFormField(control, section, inputText, labelText, true, controlId);
        }
      } catch (error) {
        console.log('Error occured while parsing controls view' + error);
      }
    }
  }

  private _getViewForTimeStampFormField(control: Control, section: AttributeSection, inputText: string, labelText: string, isNotEditable: boolean, controlId: string) {
    let dateFieldView: IndDateTimeFormViewDataModel;
    let timeFieldView: IndDateTimeFormViewDataModel;
    let dateFieldValue: string = '';
    let timeFieldValue: string = '';
    if (!_.isEmpty(inputText)) {
      const formattedDateTimeValue = moment(inputText).format();
      if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid Date') {
        let currentDateTimeValue = new Date(formattedDateTimeValue);
        dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        timeFieldValue = currentDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        if (!this.dateTimeFormatsService.is12HourFormat && timeFieldValue.includes("24:")) {
          timeFieldValue = timeFieldValue.replace("24:", "00:");
        }
      }
    }
    const dateTextTrans = this.translate.instant('DATE');
    let labelDateText: string = labelText;
    if (labelText.includes('Date') || labelText.includes('date') || labelText.includes(dateTextTrans) || labelText.includes(dateTextTrans.toLowerCase())) {
      labelDateText = labelText;
    } else {
      labelDateText = labelDateText + ' ' + dateTextTrans;
    }
    dateFieldView = {
      isDateField: true,
      label: labelDateText,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      inputText: dateFieldValue,
      inputValue: dateFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartDateField,
      controlId: controlId,
      isReadOnly: true,
      isDisabled: isNotEditable,
      showArrow: false,
      isInvalid: false,
    };
    const timeTextTrans = this.translate.instant('TIME');
    let labelTimeText: string = labelText;
    if (labelText.includes('Date')) {
      labelTimeText = labelText.replace('Date', 'Time');
    } else if (labelText.includes('date')) {
      labelTimeText = labelText.replace('date', 'time');
    } else if (labelText.includes(dateTextTrans)) {
      labelTimeText = labelText.replace(dateTextTrans, timeTextTrans);
    } else if (labelText.includes(dateTextTrans.toLowerCase())) {
      labelTimeText = labelText.replace(dateTextTrans.toLowerCase(), timeTextTrans.toLowerCase());
    } else {
      labelTimeText = labelTimeText + ' ' + timeTextTrans;
    }

    timeFieldView = {
      isTimeField: true,
      label: labelTimeText,
      customPlaceholderLabel: this.translate.instant('SELECT_TIME'),
      inputText: timeFieldValue,
      inputValue: timeFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartTimeField,
      controlId: controlId,
      isReadOnly: true,
      isDisabled: isNotEditable,
      showArrow: false,
      isRequired: control.Mandatory,
      isInvalid: false,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false) : true,
    };

    return {
      id: dateFieldView.controlId,
      type: 'form-field',
      isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
      view: dateFieldView,
      timeView: timeFieldView,
      control: control,
    };
  }

  private _getFormValue(control: Control): any {
    let foundResponse = null;
     for(let i=0; i < this.groupedAssessRawData.length; i++) {
      foundResponse = this.groupedAssessRawData[i].responses.find(resp => resp.indskr_attribute === control.Attribute.GUID);
      if(!_.isEmpty(foundResponse)) {
        break;
      }
     }
    return foundResponse;
  }
  
  private _getInputTextForFormField(control: Control, section: AttributeSection, subSectionId: string, isGridLabel: boolean = false, positionId?: string, sectionGeneralValue?: string): { value: string, controlType: AssessmentAttributeType, selectedValues: ChoiceOption[] } {
    let value = '';
    if (control && control.Type) {
      const controlType = control.Type;
      let selectedOptions: ChoiceOption[] = [];
      let formValue;
      if (section.TherapetuicArea || section.Product || section.PositionGroup || section.Position || section.Procedure) {
        formValue = this._getFormValueForSubSectionControl(control, section, subSectionId, positionId, sectionGeneralValue, isGridLabel);
      } else {
        formValue = this._getFormValue(control);
      }
      if (formValue || controlType == AssessmentAttributeType.FLAG) {
        switch (controlType) {
          case AssessmentAttributeType.TEXT:
            value = formValue['indskr_text'];
            break;
          case AssessmentAttributeType.FLAG:
            if (formValue && formValue.hasOwnProperty('indskr_flag')) {
              value = formValue['indskr_flag'] ? this.translate.instant("YES") : this.translate.instant("NO");
            }
            break;
          case AssessmentAttributeType.MEMO:
            value = formValue['indskr_memo'];
            break;
          case AssessmentAttributeType.NUMERIC:
          case AssessmentAttributeType.RANGE:
            value = '' + formValue['indskr_numeric'];
            break;
          case AssessmentAttributeType.TIMESTAMP:
            value = formValue['indskr_timestamp'] ? format(formValue['indskr_timestamp'], this.dateTimeFormatsService.dateTimeToUpper) : '';
            break;
          case AssessmentAttributeType.CHOICE:
            const choiceValues: string[] = formValue['indskr_choicevalues'] ? formValue['indskr_choicevalues'].split(",") : [];
            selectedOptions = control.Choice.Options.filter(option => choiceValues.includes(option.RatingProperties[0].GUID));
            if (selectedOptions.length)
              value = selectedOptions.length == 1 ? this._getChoiceLabel(selectedOptions[0]) : this._getChoiceLabel(selectedOptions[0]) + ' (+' + (selectedOptions.length - 1) + ')';
            break;
        }
      }
      return { value: value, controlType: controlType, selectedValues: selectedOptions }
    }
  }

  private _getHeaderSecondForSectionControl(section, positionId) {
    if (section.TherapetuicArea || section.Product || section.Position || section.PositionGroup || section.Procedure) {
      let headers = [];
      let headerSecFromFormatted = this._getFormattedHeaderSecond(section, positionId);
      if(!_.isEmpty(headerSecFromFormatted)) {
        headerSecFromFormatted.forEach((headerSec) => {
          let idx = headers?.findIndex((hd) => headerSec.id == hd.id);
          if(idx && idx<0) headers.push(headerSec);
        });
      }
      return headers;
    }
  }

  private _getTargetSectionCardFormView(sectionId: string): any {
    let selectedCardFormView = this.cardFormViewList.find(v=>v['sectionId'] == sectionId).viewData;
    return selectedCardFormView;
  }

  private _getFormattedHeaderSecond(section:AttributeSection, positionId) {
    let sectionHeaderSecond = [];
    const sectionId = section.SectionProperties.GUID;
    //parseSectionId details: position[0], TA[1], product[2], procedure[3], positionGroup[4]
    let cntCombination = 0;
    if(section.Position) cntCombination++;
    if(section.TherapetuicArea) cntCombination++;
    if(section.Product) cntCombination++;
    if(section.Procedure) cntCombination++;
    if(section.PositionGroup) cntCombination++;
    
    const _positionText = this._getFormattedValuesForHeaderSecond(this.selectedPositions, this.masterData.positions, positionId, sectionId, 'Position') || [];
    const _taText = this._getFormattedValuesForHeaderSecond(this.selectedTAs, this.masterData.ta, positionId, sectionId, 'TherapetuicArea') || [];
    const _productText = this._getFormattedValuesForHeaderSecond(this.selectedProducts, this.masterData.products, positionId, sectionId, 'Product') || [];
    const _procedureText = this._getFormattedValuesForHeaderSecond(this.selectedProcedures, this.masterData.procedures, positionId, sectionId, 'Procedure') || [];
    const _pgText = this._getFormattedValuesForHeaderSecond(this.selectedPositionGroups, this.masterData.positionGroups, positionId, sectionId, 'PositionGroup') || [];

    if(cntCombination == 0) {
      return sectionHeaderSecond;
    }
    else if(cntCombination == 1) {
      //only one section
      //isPositionOnly is covered by card form view instead of grid form view
      // const isPositionOnly: boolean = section.Position && !section.TherapetuicArea && !section.Product && !section.Procedure && !section.PositionGroup;
      const isTaOnly: boolean = !section.Position && section.TherapetuicArea && !section.Product && !section.Procedure && !section.PositionGroup;
      const isProductOnly: boolean = !section.Position && !section.TherapetuicArea && section.Product && !section.Procedure && !section.PositionGroup;
      const isProcedureOnly: boolean = !section.Position && !section.TherapetuicArea && !section.Product && section.Procedure && !section.PositionGroup;
      const isPgOnly: boolean = !section.Position && !section.TherapetuicArea && !section.Product && !section.Procedure && section.PositionGroup;

      if(isTaOnly) {
        let mainItem = _taText;
        if(!_.isEmpty(mainItem)) {
          mainItem.forEach((item) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + item?.id + '___',
              type: 'header-second',
              view: {
                id: '_' + item?.id + '___',
                title: item?.name,
                secondaryText: '',  
              }
            });
          });
        } 
      }
      else if(isProductOnly) {
        let mainItem = _productText;
        if(!_.isEmpty(mainItem)) {
          mainItem.forEach((item) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '__' + item?.id + '__',
              type: 'header-second',
              view: {
                id: '__' + item?.id + '__',
                title: item?.name,
                secondaryText: '',  
              }
            });
          });
        } 
      }
      else if(isProcedureOnly) {
        let mainItem = _procedureText;
        if(!_.isEmpty(mainItem)) {
          mainItem.forEach((item) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '___' + item?.id + '_',
              type: 'header-second',
              view: {
                id: '___' + item?.id + '_',
                title: item?.name,
                secondaryText: '',  
              }
            });
          });
        }         
      }
      else if(isPgOnly) {
        let mainItem = _pgText;
        if(!_.isEmpty(mainItem)) {
          mainItem.forEach((item) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '____' + item?.id,
              type: 'header-second',
              view: {
                id: '____' + item?.id,
                title: item?.name,
                secondaryText: '',  
              }
            });
          });
        }    
      }
    }
    else if(cntCombination == 2) {
      //two sections combination
      const isPositionTa: boolean = section.Position && section.TherapetuicArea && !section.Product && !section.Procedure && !section.PositionGroup;
      const isPositionProduct: boolean = section.Position && !section.TherapetuicArea && section.Product && !section.Procedure && !section.PositionGroup;
      const isPositionProcedure: boolean = section.Position && !section.TherapetuicArea && !section.Product && section.Procedure && !section.PositionGroup;
      const isPositionPg: boolean = section.Position && !section.TherapetuicArea && !section.Product && !section.Procedure && section.PositionGroup;
      const isTaProduct: boolean = !section.Position && section.TherapetuicArea && section.Product && !section.Procedure && !section.PositionGroup;
      const isTaProcedure: boolean = !section.Position && section.TherapetuicArea && !section.Product && section.Procedure && !section.PositionGroup;
      const isTaPg: boolean = !section.Position && section.TherapetuicArea && !section.Product && !section.Procedure && section.PositionGroup;
      const isProductProcedure: boolean = !section.Position && !section.TherapetuicArea && section.Product && section.Procedure && !section.PositionGroup;
      const isProductPg: boolean = !section.Position && !section.TherapetuicArea && section.Product && !section.Procedure && section.PositionGroup;
      const isProcedurePg: boolean = !section.Position && !section.TherapetuicArea && !section.Product && section.Procedure && section.PositionGroup;
      
      if(isPositionTa) {
        const mainText = _positionText;
        const subText = _taText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '_' + subText[idx]['id'] + '___',
              type: 'header-second',
              view: {
                id: main.id + '_' + subText[idx]['id'] + '___',
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isPositionProduct) {
        const mainText = _productText;
        const subText = _positionText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subText[idx]['id'] + '__' + main.id + '__',
              type: 'header-second',
              view: {
                id: subText[idx]['id'] + '__' + main.id + '__',
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isPositionProcedure) {
        const mainText = _positionText;
        const subText = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '___' + subText[idx]['id'] + '_',
              type: 'header-second',
              view: {
                id: main.id + '___' + subText[idx]['id'] + '_',
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isPositionPg) {
        const mainText = _positionText;
        const subText = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '____' + subText[idx]['id'],
              type: 'header-second',
              view: {
                id: main.id + '____' + subText[idx]['id'],
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isTaProduct) {
        const mainText = _productText;
        const subText = _taText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + subText[idx]['id'] + '_' + main.id + '__',
              type: 'header-second',
              view: {
                id: '_' + subText[idx]['id'] + '_' + main.id + '__',
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isTaProcedure) {
        const mainText = _taText;
        const subText = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + main.id + '__' + subText[idx]['id'] + '_',
              type: 'header-second',
              view: {
                id: '_' + main.id + '__' + subText[idx]['id'] + '_',
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isTaPg) {
        const mainText = _taText;
        const subText = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + main.id + '___' + subText[idx]['id'],
              type: 'header-second',
              view: {
                id: '_' + main.id + '___' + subText[idx]['id'],
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isProductProcedure) {
        const mainText = _productText;
        const subText = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '__' + main.id + '_' + subText[idx]['id'] + '_',
              type: 'header-second',
              view: {
                id: '__' + main.id + '_' + subText[idx]['id'] + '_',
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isProductPg) {
        const mainText = _productText;
        const subText = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '__' + main.id + '__' + subText[idx]['id'],
              type: 'header-second',
              view: {
                id: '__' + main.id + '__' + subText[idx]['id'],
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
      else if(isProcedurePg) {
        //need to confirm which one is main
        const mainText = _procedureText;
        const subText = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subText)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '___' + main.id + subText[idx]['id'],
              type: 'header-second',
              view: {
                id: '___' + main.id + subText[idx]['id'],
                title: main.name,
                secondaryText: subText[idx]['name'],
              }
            });
          });
        }
      }
    }
    else if(cntCombination == 3) {
      //three sections combination
      const isPositionTaProduct: boolean = section.Position && section.TherapetuicArea && section.Product && !section.Procedure && !section.PositionGroup;
      const isPositionTaProcedure: boolean = section.Position && section.TherapetuicArea && !section.Product && section.Procedure && !section.PositionGroup;
      const isPositionTaPg: boolean = section.Position && section.TherapetuicArea && !section.Product && !section.Procedure && section.PositionGroup;
      const isPositionProductProcedure: boolean = section.Position && !section.TherapetuicArea && section.Product && section.Procedure && !section.PositionGroup;
      const isPositionProductPg: boolean = section.Position && !section.TherapetuicArea && section.Product && !section.Procedure && section.PositionGroup;
      const isPositionProcedurePg: boolean = section.Position && !section.TherapetuicArea && !section.Product && section.Procedure && section.PositionGroup;
      const isTaProductProcedure: boolean = !section.Position && section.TherapetuicArea && section.Product && section.Procedure && !section.PositionGroup;
      const isTaProductPg: boolean = !section.Position && section.TherapetuicArea && section.Product && !section.Procedure && section.PositionGroup;
      const isTaProcedurePg: boolean = !section.Position && section.TherapetuicArea && !section.Product && section.Procedure && section.PositionGroup;
      const isProductProcedurePg: boolean = !section.Position && !section.TherapetuicArea && section.Product && section.Procedure && section.PositionGroup;

      if(isPositionTaProduct) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _taText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '__',
              type: 'header-second',
              view: {
                id: subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '__',
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isPositionTaProcedure) {
        const mainText = _positionText;
        const subTextOne = _taText;
        const subTextTwo = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id + '_',
              type: 'header-second',
              view: {
                id: main.id + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id + '_',
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isPositionTaPg) {
        const mainText = _positionText;
        const subTextOne = _taText;
        const subTextTwo = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '_' + subTextOne[idx].id + '__' + subTextTwo[idx].id,
              type: 'header-second',
              view: {
                id: main.id + '_' + subTextOne[idx].id + '__' + subTextTwo[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isPositionProductProcedure) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subTextOne[idx].id +'__' + main.id + '_' + subTextTwo[idx].id + '_',
              type: 'header-second',
              view: {
                id: subTextOne[idx].id +'__' + main.id + '_' + subTextTwo[idx].id + '_',
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isPositionProductPg) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subTextOne[idx].id +'__' + main.id + '__' + subTextTwo[idx].id,
              type: 'header-second',
              view: {
                id: subTextOne[idx].id +'__' + main.id + '__' + subTextTwo[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isPositionProcedurePg) {
        const mainText = _positionText;
        const subTextOne = _procedureText;
        const subTextTwo = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '___' + subTextOne[idx].id + '_' + subTextTwo[idx].id,
              type: 'header-second',
              view: {
                id: main.id + '___' + subTextOne[idx].id + '_' + subTextTwo[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isTaProductProcedure) {
        const mainText = _productText;
        const subTextOne = _taText;
        const subTextTwo = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + subTextOne[idx].id + '_' + main.id + '_' + subTextTwo[idx].id + '_',
              type: 'header-second',
              view: {
                id: '_' + subTextOne[idx].id + '_' + main.id + '_' + subTextTwo[idx].id + '_',
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isTaProductPg) {
        const mainText = _productText;
        const subTextOne = _taText;
        const subTextTwo = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + subTextOne[idx].id + '_' + main.id + '__' + subTextTwo[idx].id,
              type: 'header-second',
              view: {
                id: '_' + subTextOne[idx].id + '_' + main.id + '__' + subTextTwo[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isTaProcedurePg) {
        const mainText = _taText;
        const subTextOne = _procedureText;
        const subTextTwo = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + main.id + '__' + subTextOne[idx].id + '_' + subTextTwo[idx].id,
              type: 'header-second',
              view: {
                id: '_' + main.id + '__' + subTextOne[idx].id + '_' + subTextTwo[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
      else if(isProductProcedurePg) {
        const mainText = _productText;
        const subTextOne = _procedureText;
        const subTextTwo = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '__' + main.id + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id,
              type: 'header-second',
              view: {
                id: '__' + main.id + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name
              }
            });
          });
        }
      }
    }
    else if(cntCombination == 4) {
      //four sections combination
      const isPositionTaProductProcedure: boolean = section.Position && section.TherapetuicArea && section.Product && section.Procedure && !section.PositionGroup;
      const isPositionTaProductPg: boolean = section.Position && section.TherapetuicArea && section.Product && !section.Procedure && section.PositionGroup;
      const isPositionTaProcedurePg: boolean = section.Position && section.TherapetuicArea && !section.Product && section.Procedure && section.PositionGroup;
      const isPositionProductProcedurePg: boolean = section.Position && !section.TherapetuicArea && section.Product && section.Procedure && section.PositionGroup;
      const isTaProductProcedurePg: boolean = !section.Position && section.TherapetuicArea && section.Product && section.Procedure && section.PositionGroup;

      if(isPositionTaProductProcedure) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _taText;
        const subTextThree = _procedureText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo) && !_.isEmpty(subTextThree)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '_' + subTextThree[idx].id + '_',
              type: 'header-second',
              view: {
                id: subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '_' + subTextThree[idx].id + '_',
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name + ', ' + subTextThree[idx].name
              }
            });
          });
        }
      }
      else if(isPositionTaProductPg) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _taText;
        const subTextThree = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo) && !_.isEmpty(subTextThree)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '__' + subTextThree[idx].id,
              type: 'header-second',
              view: {
                id: subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '__' + subTextThree[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name + ', ' + subTextThree[idx].name
              }
            });
          });
        }
      }
      else if(isPositionTaProcedurePg) {
        const mainText = _positionText;
        const subTextOne = _taText;
        const subTextTwo = _procedureText;
        const subTextThree = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo) && !_.isEmpty(subTextThree)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '_' + subTextOne[idx].id + '__' + subTextTwo[idx].id + '_' + subTextThree[idx].id,
              type: 'header-second',
              view: {
                id: main.id + '_' + subTextOne[idx].id + '__' + subTextTwo[idx].id + '_' + subTextThree[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name + ', ' + subTextThree[idx].name
              }
            });
          });
        }
      }
      else if(isPositionProductProcedurePg) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _procedureText;
        const subTextThree = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo) && !_.isEmpty(subTextThree)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + subTextOne[idx].id + '__' + main.id + '_' + subTextTwo[idx].id + '_' + subTextThree[idx].id,
              type: 'header-second',
              view: {
                id: subTextOne[idx].id + '__' + main.id + '_' + subTextTwo[idx].id + '_' + subTextThree[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name + ', ' + subTextThree[idx].name
              }
            });
          });
        }
      }
      else if(isTaProductProcedurePg) {
        const mainText = _productText;
        const subTextOne = _taText;
        const subTextTwo = _procedureText;
        const subTextThree = _pgText;

        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo) && !_.isEmpty(subTextThree)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + '_' + subTextOne[idx].id + '_' + main.id + '_' + subTextTwo[idx].id + '_' + subTextThree[idx].id,
              type: 'header-second',
              view: {
                id: '_' + subTextOne[idx].id + '_' + main.id + '_' + subTextTwo[idx].id + '_' + subTextThree[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name + ', ' + subTextThree[idx].name
              }
            });
          });
        }
      }
    }
    else if(cntCombination == 5) {
      //All sections
      const isAll: boolean = section.Position && section.TherapetuicArea && section.Product && section.Procedure && section.PositionGroup;
      if(isAll) {
        const mainText = _productText;
        const subTextOne = _positionText;
        const subTextTwo = _taText;
        const subTextThree = _procedureText;
        const subTextFour = _pgText;
        if(!_.isEmpty(mainText) && !_.isEmpty(subTextOne) && !_.isEmpty(subTextTwo) && !_.isEmpty(subTextThree) && !_.isEmpty(subTextFour)) {
          mainText?.forEach((main, idx)=>{
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + main.id + '_' + subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '_' + subTextThree[idx].id + '_' + subTextFour[idx].id,
              type: 'header-second',
              view: {
                id: subTextOne[idx].id + '_' + subTextTwo[idx].id + '_' + main.id + '_' + subTextThree[idx].id + '_' + subTextFour[idx].id,
                title: main.name,
                secondaryText: subTextOne[idx].name + ', ' + subTextTwo[idx].name + ', ' + subTextThree[idx].name + ', ' + subTextFour[idx].name,
              }
            });
          });
        }
      }
    }
    return sectionHeaderSecond;
  }

  private _getFormattedValuesForHeaderSecond(selectedItems: any[], masterData: any[], positionId: string, sectionId: string, sectionGeneral: string) {
    let _foundItem;
    let _formattedText = [];
    
    if(!_.isEmpty(selectedItems)) {
      _foundItem = selectedItems.find((item) => item.positionId == positionId && item.sectionId == sectionId && item.section_general == sectionGeneral)?.selected;
      if(!_.isEmpty(_foundItem)) {
        _foundItem.forEach((item) => {
          _formattedText.push({
            id: masterData.find(m => m.ID == item.id)?.ID,
            name: masterData.find(m => m.ID == item.id)?.name,
          });
        });
      }
    }
    return _formattedText;
  }

  private _getFieldType(control: Control): InputFieldType {
    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
      case AssessmentAttributeType.MEMO:
        return InputFieldType.TEXT;
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        return InputFieldType.NUMERIC;
      case AssessmentAttributeType.TIMESTAMP:
        return InputFieldType.DATE_TIME;
      case AssessmentAttributeType.CHOICE:
      case AssessmentAttributeType.FLAG:
        return InputFieldType.DROP_DOWN;
      default:
        console.error("unhanled type");
        return InputFieldType.NONE;
    }
  }

  private _getDataAttr(data): string {
    let str: string = "";
    if(!_.isEmpty(data)) {
      str = data.charAt(0).toUpperCase();
    }
    return str;
  }

  private _getChoiceLabel(choiceOption: ChoiceOption) {
    let label = choiceOption.Label;
    if (choiceOption.displayNames && !_.isEmpty(choiceOption.displayNames)) {
      const multilingualLabel = choiceOption.displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
      if (multilingualLabel) {
        label = multilingualLabel.Description;
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(label);
        if(!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
      }
    } else {
      let foundTranslatedStr = this.localizationService.getTranslatedString(label);
      if(!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
    }
    return label;
  }

  private _getTargetControl(id: string): Control {
    let targetControl: Control;
    for (let tabIdx = 0; tabIdx < this.selectedTemplate.indskr_metadata.Sections.length; tabIdx++) {
      const tab = this.selectedTemplate.indskr_metadata.Sections[tabIdx];
      if (tab && tab.Controls && tab.Controls.length > 0) {
        let foundControl = tab.Controls.find(c => id.includes(c.Attribute.GUID));
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    }
    return targetControl;
  }

  /*--------------get response values----------------*/
  private _getValuesForSubSectionView(section: AttributeSection, sectionId: string, positionId?: string) {
    let responses = {};
    const sectionDetails = sectionId ? this.customerAssessService.parseSectionId(sectionId) : null;
    if (sectionDetails) {
      //Refreshing particular subsection only
      return this._getValueForGivenSubSectionView(sectionDetails, responses, sectionId);
    }
    //Get values for controls which are available in template
    let controlIds = [];
    section.Controls.forEach(control => {
      const isValidDateControl: boolean = this._isValidDateControl(control);
      if(isValidDateControl) {
        controlIds.push(control.Attribute.GUID);
      }
    });
    if (!_.isEmpty(this._multipleFormValue)) {
      let forms = this._multipleFormValue.filter((value) => value.positionId == positionId && value.sectionId == section.SectionProperties.GUID);
      if(!_.isEmpty(forms)) {
        forms?.forEach((form) => {
          for (let [, value] of Object.entries(form.currentValue)) {
            let response;
            if (value && controlIds.includes(value['indskr_attribute'])) {
              response = this._getResponseForSectionFromValue(section, value);
              if (response) {
                const sectionId = this._getSectionId(response);
                if (!responses.hasOwnProperty(sectionId)) {
                  responses[sectionId] = {};
                }
                responses[sectionId][value['indskr_attribute']] = value;
              }
            }
          }
        });
      }
    } 
    
    let sectionResponses = [];
    if (this.rawData) {
      sectionResponses = this._getResponsesForGivenSection(section, controlIds);
      sectionResponses.forEach(response => {
        const sectionId = this._getSectionId(response);
        if (!responses.hasOwnProperty(sectionId)) {
          responses[sectionId] = {};
        }
        if (!responses[sectionId].hasOwnProperty(response['indskr_attribute']))
          responses[sectionId][response['indskr_attribute']] = response;
      });
    }
    return responses;
  }

  private _getFormValueForSubSectionControl(control: Control, section: AttributeSection, subSectionId?: string, positionId?: string, sectionGeneralValue?: string, isGridLabel?) {
    let response;
    let subSectionDetails;
    if(isGridLabel) subSectionDetails = sectionGeneralValue ? this.customerAssessService.parseSectionId(sectionGeneralValue) : null;
    else subSectionDetails = subSectionId ? this.customerAssessService.parseSectionId(subSectionId) : null;
    let _currentValue = this._multipleFormValue.find((value) => value.positionId == positionId && value.sectionId == section.SectionProperties.GUID && value.id == sectionGeneralValue);
    if(_currentValue && _currentValue.currentValue && _currentValue.currentValue[control.Attribute.GUID]) {
      return _currentValue.currentValue[control.Attribute.GUID];
    }else if(this.rawData) {
      response = this.rawData.responses.find(resp => resp.indskr_procedures == subSectionDetails?.procedureId && resp.indskr_position == subSectionDetails?.positionId && resp.indskr_positiongroup == subSectionDetails?.positionGroupId && resp.indskr_therapeuticarea == subSectionDetails?.taId && resp.indskr_product == subSectionDetails?.productId && resp.indskr_attribute === control.Attribute.GUID);
      return response;
    }
    return response;
  }

  private _getValueForGivenSubSectionView(sectionDetails: { positionId: string; taId: string; productId: string; procedureId: string; positionGroupId: string; }, responses: {}, sectionId: string) {
    if (this._currentFormValue && !_.isEmpty(Object.keys(this._currentFormValue))) {
      for (let [, value] of Object.entries(this._currentFormValue)) {
        for (let [, value1] of Object.entries(value)) {
          if (value1 && value1['indskr_procedures'] == sectionDetails.procedureId && value1['indskr_position'] == sectionDetails.positionId && value1['indskr_positiongroup'] == sectionDetails.positionGroupId && value1['indskr_therapeuticarea'] == sectionDetails.taId && value1['indskr_product'] == sectionDetails.productId) {
            if (!responses.hasOwnProperty(sectionId)) {
              responses[sectionId] = {};
            }
            responses[sectionId][value1['indskr_attribute']] = value1;
          }
        }
      }
    }
    if (this.rawData) {
      this.rawData.responses.filter(response => response['indskr_procedures'] == sectionDetails.procedureId && response['indskr_position'] == sectionDetails.positionId && response['indskr_positiongroup'] == sectionDetails.positionGroupId && response['indskr_therapeuticarea'] == sectionDetails.taId && response['indskr_product'] == sectionDetails.productId).forEach(response => {
        if (!responses.hasOwnProperty(sectionId)) {
          responses[sectionId] = {};
        }
        if (!responses[sectionId].hasOwnProperty(response['indskr_attribute']))
          responses[sectionId][response['indskr_attribute']] = response;
      });
    }
    return responses;
  }

  private _getResponseForSectionFromValue(section: AttributeSection, value: any) {
    let response;
    if (!section.Position && !section.PositionGroup && !section.TherapetuicArea && !section.Product && section.Procedure) {
      //Procedure only
      if (!value['indskr_position'] && !value['indskr_positiongroup'] && !value['indskr_therapeuticarea'] && !value['indskr_product'] && value['indskr_procedures']) {
        response = value;
      }
    } else if (section.PositionGroup && !section.Position && !section.TherapetuicArea && !section.Product) {
      //PositionGroup only
      if (value['indskr_positiongroup'] && !value['indskr_position'] && !value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && section.Position && !section.TherapetuicArea && !section.Product) {
      //Position only
      if (!value['indskr_positiongroup'] && value['indskr_position'] && !value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && !section.Position && section.TherapetuicArea && !section.Product) {
      //TA only
      if (!value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.TherapetuicArea && section.Product) {
      //Product and Position, Positon only
      // if (!value['indskr_position'] && !value['indskr_therapeuticarea'] && value['indskr_product']) {
      if (!value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && section.Position && section.TherapetuicArea && !section.Product) {
      //Position and TA
      if (!value['indskr_positiongroup'] && value['indskr_position'] && value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    }  else if (section.PositionGroup && !section.Position && section.TherapetuicArea && !section.Product) {
      //PositionGroup and TA
      if (value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && !section.Position && section.TherapetuicArea && section.Product) {
      //Product and TA
      if (!value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && section.TherapetuicArea && section.Product) {
      //Position and TA  and Position, Product and TA
      if (!value['indskr_positiongroup'] && value['indskr_position'] && value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    } else if (section.PositionGroup && section.TherapetuicArea && section.Product) {
      //PositionGroup and TA , Product and TA
      if (value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    }
    return response;
  }

  private _getResponsesForGivenSection(section: AttributeSection, controlIds: string[]) {
    let sectionResponses = [];
    if (!section.PositionGroup && !section.Position && !section.TherapetuicArea && !section.Product && section.Procedure) {
      //Procedure only
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && !resp.indskr_product && resp.indskr_procedures && controlIds.includes(resp.indskr_attribute));
    } else if (section.PositionGroup && !section.Position && !section.TherapetuicArea && !section.Product) {
      //PositionGroup only
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_positiongroup && !resp.indskr_position && !resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (section.Position && !section.PositionGroup && !section.TherapetuicArea && !section.Product) {
      //Position only
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.Position && !section.PositionGroup && section.TherapetuicArea && !section.Product) {
      //TA only
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && !resp.indskr_positiongroup && resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.TherapetuicArea && section.Product) {
      //Position and Product/PositionGroup and Product, Product only
      if (section.Position) {
        sectionResponses = this.rawData.responses.filter(resp => resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
      } else if (section.PositionGroup) {
        sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && resp.indskr_positiongroup && !resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
      } else {
        //Product Only
        sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
      }
    }
     else if (section.Position && section.TherapetuicArea && !section.Product && !section.PositionGroup) {
      //Position and TA
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_positiongroup && resp.indskr_position && resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.Position && section.TherapetuicArea && !section.Product && section.PositionGroup) {
      //PositionGroup and TA
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_positiongroup && !resp.indskr_position && resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.PositionGroup && !section.Position && section.TherapetuicArea && section.Product) {
      //Product and TA
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_positiongroup && !resp.indskr_position && resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.PositionGroup && section.TherapetuicArea && section.Product) {
      //Position and TA and Product, Product and TA
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_positiongroup && resp.indskr_position && resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (section.PositionGroup && section.TherapetuicArea && section.Product) {
      //PositionGroup and TA and Product, Product and TA
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_positiongroup && !resp.indskr_position && resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    }
    return sectionResponses;
  }
  
  private _updateSectionGeneralValueFromResponse(section, columnSubID, positionId) {
    let selectedValue;
    switch(columnSubID) {
      case 'Procedure':
        selectedValue = this.selectedProcedures;
        break;
      case 'Position':
        selectedValue = this.selectedPositions;
        break;
      case 'PositionGroup':
        selectedValue = this.selectedPositionGroups;
        break;
      case 'Product' :
        selectedValue = this.selectedProducts;
        break;
      case 'TherapetuicArea':
        selectedValue = this.selectedTAs;
        break;
    }
    const subSectionValues = this._getValuesForSubSectionView(section, null, positionId);
    if (!_.isEmpty(Object.keys(subSectionValues))) {
      Object.keys(subSectionValues).forEach(subSectionId => {
        const subSectionDetails = subSectionId ? this.customerAssessService.parseSectionId(subSectionId) : null;
        let labelText = this.customerAssessService.getLabelsForSectionTeamview(subSectionDetails, this.masterData, null, this.specialty, columnSubID);
        this._updateSelectedSectionValue(selectedValue, section.SectionProperties.GUID, positionId, columnSubID, labelText);
      });
    }
  }

  private _updateSelectedSectionValue(existingData, sectionID, positionId, columnSubID, labelText) {
    if(!_.isEmpty(existingData)) {
      let idx = existingData.findIndex((data) => data.positionId == positionId && data.sectionId == sectionID && data.section_general == columnSubID);
      if(idx>-1) {
        existingData[idx].selected.push({
          id: labelText?.id,
          title: labelText?.label,
          isSelected: true
        });
      } else {
        existingData.push({
          positionId: positionId,
          sectionId: sectionID,
          section_general: columnSubID,
          selected: [{
            id: labelText?.id,
            title: labelText?.label,
            isSelected: true
          }]
        });
      }
    } else {
      existingData.push({
          positionId: positionId,
          sectionId: sectionID,
          section_general: columnSubID,
          selected: [{
            id: labelText?.id,
            title: labelText?.label,
            isSelected: true
          }]
      });
    }
  }

  private _clearCardFormViewValues() {
    this.cardFormViewList = [];
    this.positionCardFormView = [];
    this._positionCardFormView = [];
    this._formViewForViewCard = [];
  }

  private _isValidDateControl(control: Control): boolean {
    let isValidDate: boolean = true;
    if (!_.isEmpty(control.FromDate)) {
      isValidDate = new Date(control.FromDate) <= new Date();
      if (!_.isEmpty(control.ToDate)) {
        isValidDate = isValidDate && new Date(control.ToDate) >= new Date();
      }
    }
    return isValidDate;
  }

  /*------------------event handler------------------*/
  public onSectionHeaderControlClick(id: string, targetSectionId?: string) {
    switch(id){
      case 'assessment-teamView-filter':
        this._openTeamViewFilter();
        break;
      case 'sections-form-view-expand':
        let targetFormViewData = this.sectionsFormView;
        targetFormViewData.forEach(viewData => {
          if(viewData.sectionId == targetSectionId) {
            viewData.isExpanded = !viewData.isExpanded;
            if(!_.isEmpty(viewData.sectionHeaderViewData) && !_.isEmpty(viewData.sectionHeaderViewData.controls)) {
              viewData.sectionHeaderViewData.controls[0].icon = viewData.isExpanded ? 'assets/imgs/dropdown-close.svg' : 'assets/imgs/dropdown-open.svg';
            }
          }
        });
        break;
      case 'sections-card-view-expand':
        let targetCardViewData = this._getTargetSectionCardFormView(targetSectionId);
        targetCardViewData.forEach(viewData => {
          if(viewData.sectionId == targetSectionId) {
            viewData.isExpanded = !viewData.isExpanded;
            if(!_.isEmpty(viewData.sectionHeaderViewData) && !_.isEmpty(viewData.sectionHeaderViewData.controls)) {
              viewData.sectionHeaderViewData.controls[0].icon = viewData.isExpanded ? 'assets/imgs/dropdown-close.svg' : 'assets/imgs/dropdown-open.svg';
            }
          }
        });
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }  
  }

  public onInputTextClick(event, item) {
    if(item.isDisabled) return;
    if(item.eventHandler) {
      item.eventHandler(item.columnItemId, event, 'input_click_event', item);
    } else return;
  }

  public handleInputValue(event, item) {
    if(item.isDisabled) return;
    if(item.eventHandler) {
      item.eventHandler(item.columnItemId, event, 'input_value_confirm');
    } else return;
  }
  
  private _handleFormFieldEvent(id: string, event, eventName?: string, positionId?: string) {
    if(id == 'teamview-template-field') {
      let categoryId: string = id;
      this._openDropDownList(categoryId, event);
    }else {
      const targetControl: Control = this._getTargetControl(id);
      this.rawData = this.multipleFormView.find(view => view.positionId == positionId).rawData;
      if (targetControl) {
        switch (targetControl.Type) {
          case AssessmentAttributeType.CHOICE:
            if(targetControl.Choice.Type == RatingScaleType.MULTI_SELECT) {
              this._handleChoiceFormField(id, event, targetControl, true, positionId);
            }
            break;
          default:
            console.error("unhanled type");
        }
      }
    }
  }

  private _handleGridFieldEvent(id:string, event, eventName, section, positionId: string, sectionGeneralValue?, isGrid?) {
    const targetControl: Control = this._getTargetControl(id);
    this.currentFormView = this.multipleFormView.find(view => view.positionId == positionId).currentFormView;
    this.rawData = this.multipleFormView.find(view => view.positionId == positionId).rawData;
    if (targetControl) {
      switch (targetControl.Type) {
        case AssessmentAttributeType.CHOICE:
          if(targetControl.Choice.Type == RatingScaleType.MULTI_SELECT) {
            this._handleChoiceFormField(id, event, targetControl, true, positionId, section, sectionGeneralValue, isGrid);
          }
          break;
        default:
          console.error("unhanled type");
          break;
      }
    }
  }
  
  private async _handleChoiceFormField(id, event, targetControl: Control, isReadOnly: boolean = false, positionId: string, section?, sectionGeneralValue?, isGrid?: boolean) {
    if (this._dropdownPopover) return;
    let selectedValue: string[] = [];
    if(isGrid) {
      const formValue = this._getFormValueForSubSectionControl(targetControl, section, null, positionId, sectionGeneralValue, isGrid);
      selectedValue = formValue ? formValue['indskr_choicevalues'].split(",") : [];
    }else {
      if (this.rawData) {
        const resp = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID);
        selectedValue = resp ? resp['indskr_choicevalues'].split(",") : []
      }
    }
        
    if(_.isEmpty(selectedValue) || !_.isEmpty(selectedValue) && selectedValue.length == 1) return;
    //sort choice options by order value
    const targetChoiceOptions: ChoiceOption[] = targetControl.Choice.Options || [];
    const sortedOptionsWithOrders = targetChoiceOptions.filter(op => op.Order !='').sort((a,b) => {
      const rawOrderA: string = a.Order;
      const rawOrderB: string = b.Order;
      const numOrderA: number = parseFloat(rawOrderA);
      const numOrderB: number = parseFloat(rawOrderB);

      if(!isNaN(numOrderA) && !isNaN(numOrderB)) {
        if(numOrderA !== numOrderB) {
          return numOrderA - numOrderB;
        }
        // If 'Order' values are the same, compare 'Value'
        const rawValueA: string = a.Value;
        const rawValueB: string = b.Value;
        const numValueA: number = parseFloat(rawValueA);
        const numValueB: number = parseFloat(rawValueB);
  
        if(!isNaN(numValueA) && !isNaN(numValueB)) {
          if(numValueA !== numValueB) {
            return numValueA - numValueB;
          }
        }
      }
      // If 'Order' and 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });

    const sortedOptionsWithoutOrders = targetChoiceOptions.filter(op => op.Order =='').sort((a,b) => {
      const rawValueA: string = a.Value;
      const rawValueB: string = b.Value;
      const numValueA: number = parseFloat(rawValueA);
      const numValueB: number = parseFloat(rawValueB);

      if(!isNaN(numValueA) && !isNaN(numValueB)) {
        if(numValueA !== numValueB) {
          return numValueA - numValueB;
        }
      }
      // If 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });
    
    let sortedOptions = sortedOptionsWithOrders.concat(sortedOptionsWithoutOrders);

    let data: SelectListData[] = []; 
    sortedOptions.forEach(op => {
      if(selectedValue.includes(op.RatingProperties[0].GUID)) {
        data.push({
          id: op.RatingProperties[0].GUID,
          title: this._getChoiceLabel(op),
          isReadOnly: true,
        });
      }
    });
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-choice-field-select',
      data: data,
      isMultipleSelectionEnabled: false,
      showCancelandDoneBtn: false,
      isReadOnly: isReadOnly
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        
      }
      this._dropdownPopover = undefined;
    });
  }

  private async _openTeamViewFilter(){
    if(this.device.isOffline) return;
    //check current selected template
    const selectedId = this.templateFilterFormView.options.find(o=>o.isSelected).id;
    const idx = this.allFilterFormView.findIndex(v=>v.id == 'teamview-template-field');
    if(idx > -1) {
      this.allFilterFormView[idx].options.map(o=>{
        o.isSelected = o.id == selectedId ? true : false;
      });
    }
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.CustomerAssessTeamviewComponent,
          data: this.allFilterFormView,
          filterTitle: this.translate.instant('FILTER'),
        },
      }
    });
    await modal.present().then(() => {});
    modal.onDidDismiss().then(async(res)=>{
      //Show Result
      if(res && res.data && res.data.isDone) {
        if(!_.isEmpty(res.data.selectedItems)) {
          this.customerAssessService.assessmentTeamViewFielterMenuData = res.data.selectedItems;
          this._initSelectedTemplate();
          await this._initCustomerAssessDataTeamview().then(()=>{
            this.sharedSectionFormView = [];
            this._clearCardFormViewValues();
            this._initMultipleAssessData();
            this._initViewData();
          });
        }
        this.uiService.contactDetailsSegment = "customer-assess-teamview";
      }
    })
  }

  private async _openDropDownList(categoryId: string, event) {
    if(this.device.isOffline) return;
    let options = [];
    const targetIdx = this.allFilterFormView.findIndex(v=>v.id == categoryId);
    if(targetIdx > -1) {
      this.allFilterFormView[targetIdx].options.forEach((o) => {
        options.push({ id: o.id, title: o.displayName, isSelected: o.isSelected });
      });  
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dropDown-select',
      data: options,
      showCancelandDoneBtn: false,
      isMultipleSelectionEnabled: false,
      isReadOnly: false,
      showClearAllBtn: false,
    };
    const dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'teamview-template-dropdown-list-view',
      event: event
    });

    dropdownPopover.onDidDismiss().then(async(popoverData: any) => {
      const data = popoverData.data;
      if (data && data?.selectedItems && data.isDone) {
        const targetIdx = this.allFilterFormView.findIndex(v=>v.id == categoryId);
        if(targetIdx > -1) {
          const curId = this.allFilterFormView[targetIdx].options.find(o=>o.isSelected).id;
          const selId = data.selectedItems.find(s=>s.isSelected).id;
          let isDiff: boolean = curId != selId;
          if(isDiff) {
            this.allFilterFormView[targetIdx].options.forEach(o=>{
              o.isSelected = false;
              data.selectedItems.forEach(item => {
                if(item.isSelected && item.id == o.id) {
                  o.isSelected = true;
                }
              });  
            });
            this._initSelectedTemplate();
            await this._initCustomerAssessDataTeamview().then(()=>{
              this.sharedSectionFormView = [];
              this._clearCardFormViewValues();
              this._initMultipleAssessData();
              this._initViewData();
            });
          }
        }
      }
    });
    dropdownPopover.present();
  }

  private async _fetchAssessRawDataTeamview(entity: string, entityId: string, selectedTemplateId: string) {
    try {
      this.uiService.displayLoader();
      if(entity == 'contact') {
        const contactInfo = this.contactService.contactInformation;
        const templateIdx = this.templatesTeamview.findIndex(v=>v.indskr_assessmenttemplateid == selectedTemplateId);
        const isProcedureSectionEnabled: boolean = this.templatesTeamview[templateIdx].indskr_metadata.Sections.some(section => section.Procedure) && contactInfo.primarySpecialty?.id != null;
        const procedures = isProcedureSectionEnabled ? this.customerAssessService.specialtyProceduresMap.get(contactInfo.primarySpecialty.id) : [];
        await this.customerAssessService.fetchCustomerAssessmentMasterDataTeamview();
        await this.customerAssessService.fetchCustomerAssessmentTeamview(entity, entityId, selectedTemplateId).then((data: CustomerAssessment[]) => {
          this.rawData = undefined;
          this.currentFormView = [];
          this.multipleFormView = [];
          this.groupedAssessRawData = [];
          if(!_.isEmpty(data)) {
            data.forEach(assessRawData =>{
              if(isProcedureSectionEnabled && assessRawData?.responses) {
                if (_.isEmpty(procedures)) {
                  assessRawData.responses = assessRawData.responses.filter(resp => resp['indskr_procedures'] == null);
                } else {
                  const procedureIds = procedures.map(procedure => procedure.ID);
                  assessRawData.responses = assessRawData.responses.filter(resp => resp['indskr_procedures'] == null || procedureIds.includes(resp['indskr_procedures']))
                }    
              }
            });
            this.groupedAssessRawData = _.cloneDeep(_.sortBy(data, [function (d) { return d.position}]));
          }
        }).catch((error)=>{
          console.log("Error fetch CustomerAssessmentTeamview ", error);
          this.uiService.dismissLoader();
        });
      }else if(entity == 'account') {
        await this.customerAssessService.fetchCustomerAssessmentMasterDataTeamview();
        await this.customerAssessService.fetchCustomerAssessmentTeamview(entity, entityId, selectedTemplateId).then((data: CustomerAssessment[]) => {
          this.rawData = undefined;
          this.currentFormView = [];
          this.multipleFormView = [];
          this.groupedAssessRawData = [];
          if(!_.isEmpty(data)) {
            this.groupedAssessRawData = _.cloneDeep(_.sortBy(data, [function (d) { return d.position}]));
          }
        }).catch((error)=>{
          console.log("Error fetch CustomerAssessmentTeamview ", error);
          this.uiService.dismissLoader();
        });
      }
      this.uiService.dismissLoader();
    }catch(error) {
      console.log("Error fetch Assessment RawData for Teamview ", error);
      this.uiService.dismissLoader();
    }
  }

  private _pushEmptyColumnToGrid(gridColumnIdx, columnID, columnSubID, section, groupedAssessRawData, controls) {
    this.assessGrid[gridColumnIdx] = {
      columnId: columnID,
      columnSubId: columnSubID,
      columnType: ColumnType.COLUMN_SECTION_EMPTY,
      columnItems: [
        {
          columnItemId: columnID + '_' + columnSubID + '_header_first',
          isHeaderFirst: true,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: ' ',
          columnItemSecondaryLabel: '',
          isMoreHeight: false,
          isRequired: false,
          controls: controls
        },
        {
          columnItemId: columnID + '_' + columnSubID + '_header_second',
          isHeaderFirst: false,
          isHeaderSecond: true,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: '',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },
      ]
    }
    groupedAssessRawData.forEach((rData) => {
      this.assessGrid[gridColumnIdx].columnItems.push({
        columnItemId: section.SectionProperties.GUID + '_' + columnSubID + '_' + rData.ID,
        isHeaderFirst: false,
        isHeaderSecond: false,
        isHeaderThird: false,
        isInputField: true,
        inputFieldType: InputFieldType.NONE,
        endSlotItemType: 'none',
        columnItemLabel: '',
        columnItemSecondaryLabel: '',
        placeholder: '',
        arrowType: '',
        isRequired: false, 
        isMoreHeight: false,
        isDisabled: true,
      });
    });
  }

}
