<div class="container">
  <div class="card-header">
    <div class="card-header-title">
      {{ cardData?.title | translate }}
    </div>
    <div class="card-segment" *ngIf="cardData?.showSegment">
      <ion-segment *ngIf="cardData?.segmentData"
                    value="{{ cardData?.segmentData?.initialValue }}"
                    (ionChange)="onSegmentChanged($event)">

        <ion-segment-button *ngFor="let button of cardData.segmentData.buttons"
                            value="{{ button.value }}"
                            [disabled]="button?.disabled">
          <ion-label>{{ button.label }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
  </div>

  <div class="card-body">
    <ng-container [ngSwitch]="cardData.cardBodyCompType">
      <ind-report-card-table *ngSwitchCase="'card-table'" [tableData]="cardData.cardBodyCompData"></ind-report-card-table>
    </ng-container>
  </div>
</div>
