import { Injectable } from '@angular/core';
import { OpportunityManagementDataService } from './../opportunity-management/opportunity-management.data.service';
import { DiskService } from '../../services/disk/disk.service';
import { OpportunityManagementService } from '../../services/opportunity-management/opportunity-management.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';

@Injectable({
  providedIn: 'root'
})
export class QuoteDataService {
  constructor(
    private dynamicsClient: DynamicsClientService,
    private opportunitiesService: OpportunityManagementService,
    private oppService: OpportunityManagementDataService,
    private disk: DiskService,
  ) {

  }

  async generateQuoteFromOpportunity(opportunityId: string) {
    let response = await this.dynamicsClient.executeUnboundAction(
      'GenerateQuoteFromOpportunity', {
      OpportunityId: opportunityId,
      ColumnSet: {
        AllColumns: false,
        Columns: [
          "quoteid",
          "customerid"
        ]
      }
    }
    );
    await this.oppService.getOpportunitiesData(false, response._customerid_value);
    return this.opportunitiesService.qoutes.find(quote => quote.quoteID === response.quoteid);
  }

  async updateQuote(quoteId: string, updateQuote: {
    countryId?: string,
    priceListId?: string,
    currencyId?: string,
    effectiveFrom?: Date,
    effectiveTo?: Date,
    products?: {
      productId?, uomid?, quantity?, unitPrice?, total?
    }[]
  }) {
    updateQuote = JSON.parse(JSON.stringify(updateQuote));
    if (updateQuote.hasOwnProperty('products') && updateQuote.products) {
      // let existingProducts = (await this.dynamicsClient.retrieveAll('quotedetails', ['quotedetailid'], `_quoteid_value eq ${quoteId}`)).value.map(q => q.quotedetailid);
      this.dynamicsClient.startBatch();
      try {
        // for (let quotedetailid of existingProducts) {
        //   this.dynamicsClient.delete(quotedetailid, 'quotedetails');
        // }
        for (let product of updateQuote.products) {
          this.dynamicsClient.create({
            priceperunit: product.unitPrice,
            quantity: product.quantity,
            pricingerrorcode: 0,
            producttypecode: 1,
            propertyconfigurationstatus: 2,
            ispriceoverridden: false,
            isproductoverridden: false,
            willcall: false,
            'uomid@odata.bind': `uoms(${product.uomid})`,
            'productid@odata.bind': `products(${product.productId})`,
            ...updateQuote.hasOwnProperty('currencyId') ? { 'transactioncurrencyid@odata.bind': updateQuote.currencyId ? `transactioncurrencies(${updateQuote.currencyId})` : null } : {},
            'quoteid@odata.bind': `quotes(${quoteId})`,
          }, 'quotedetails')
        }
      }
      finally {
        //{"priceperunit":1000,"uomid@odata.bind":"/uoms(827fd1a1-f7c0-e811-a971-000d3af49211)","quantity":1,"productid@odata.bind":"/products(00386b64-35c6-e811-a971-000d3af49c44)","pricingerrorcode":0,"producttypecode":1,"propertyconfigurationstatus":2,"ispriceoverridden":false,"isproductoverridden":false,"transactioncurrencyid@odata.bind":"/transactioncurrencies(67dfed39-b5b8-e811-a974-000d3af4aec4)","willcall":false,"quoteid@odata.bind":"/quotes(6abefcbe-117c-ea11-a812-000d3a1ab78a)"}
        await this.dynamicsClient.executeBatch();
      }

    } else {
      await this.dynamicsClient.update(quoteId, 'quotes', {
        ...updateQuote.hasOwnProperty('countryId') ? { 'indskr_countryid@odata.bind': updateQuote.countryId ? `indskr_lu_countries(${updateQuote.countryId})` : null } : {},
        ...updateQuote.hasOwnProperty('priceListId') ? { 'pricelevelid@odata.bind': updateQuote.priceListId ? `pricelevels(${updateQuote.priceListId})` : null } : {},
        ...updateQuote.hasOwnProperty('currencyId') ? { 'transactioncurrencyid@odata.bind': updateQuote.currencyId ? `transactioncurrencies(${updateQuote.currencyId})` : null } : {},
        ...updateQuote.hasOwnProperty('effectiveFrom') ? { 'effectivefrom': updateQuote.effectiveFrom || null } : {},
        ...updateQuote.hasOwnProperty('effectiveTo') ? { 'effectiveto': updateQuote.effectiveTo || null } : {},
      });
    }
    await this.oppService.getOpportunitiesData(false);
    return this.opportunitiesService.qoutes.find(quote => quote.quoteID === quoteId);
  }

  async getPriceList() {
    return ((await this.dynamicsClient.retrieveAll('pricelevels', ['name', 'pricelevelid'], 'statecode eq 0')).value) as { name: string, pricelevelid: string }[]
  }

  async getCurrencyList() {
    return ((await this.dynamicsClient.retrieveAll('transactioncurrencies', ['currencyname', 'transactioncurrencyid'], 'statecode eq 0')).value) as { currencyname: string, transactioncurrencyid: string }[]
  }

  async getQuoteTimeline(quoteid) {
    return ((await this.dynamicsClient.retrieveAll('ind_omnipresenceprocesshistories', ['_createdby_value','createdon','_ind_activestage_value','_ind_quote_value'], `_ind_quote_value eq ${quoteid}`)).value);
  }

  async writeToDisk() {
    this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_QUOTES, (doc) => {
      doc = {
        raw: []
      };
      doc.raw = this.opportunitiesService.qoutes;
      return doc;
    })
  }
}
