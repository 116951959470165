import { PipeTransform, Pipe } from '@angular/core';
import { TimeOffActivity, TimeOffStatus } from '../classes/activity/timeoff.class';
import { TimeOffService, filterModel } from '../services/time-off/time-off.service';

@Pipe({
    name: 'timeOffFilter',
    pure: false
})
export class TimeOffFilterPipe implements PipeTransform {

    constructor() { }

    transform(timeOffList: Array<TimeOffActivity>, team: boolean, currentFilter: filterModel, forceReload?: boolean) {

        if(timeOffList != undefined) {
            let filteredTImeOff = this.getFilteredTimeOff(timeOffList, team, currentFilter);
            //console.log(filteredTImeOff);
            if(filteredTImeOff != undefined){
                return filteredTImeOff.sort(function (a, b) {
                    let t1 = new Date(a.totStartTime).getTime();
                    let t2 = new Date(b.totStartTime).getTime();
                    return t2 - t1;
                });
            }
            return [];
        }
        else{
            return [];
        }

    }

    getFilteredTimeOff(timeOffList: TimeOffActivity[], team: boolean, currentFilter: filterModel): TimeOffActivity[] {
        let filteredItems = [];
        //myrequest
        if (!team) {
            filteredItems = currentFilter.status != "" ? this.stateSwitchFilter(timeOffList, currentFilter.status.toString()) : timeOffList;
        } else { // team requests
            filteredItems = timeOffList;
            filteredItems = currentFilter.status != "" ? this.stateSwitchFilter(filteredItems, currentFilter.status.toString()) : filteredItems;
            filteredItems = currentFilter.user != "" ? this.userFilter(filteredItems, currentFilter.user.toString()) : filteredItems;
            filteredItems = currentFilter.position != "" ? this.positionFilter(filteredItems, currentFilter.position.toString()) : filteredItems;
        }
        return filteredItems;
    }

    private stateSwitchFilter(tots: TimeOffActivity[], status: string): TimeOffActivity[] {
        //Status
        switch (status) {

            case "Open":
                tots = tots.filter(tot => tot.statuscode === TimeOffStatus.Open);
                break;

            case "For Review":
                tots = tots.filter(tot => tot.statuscode === TimeOffStatus.InReview);
                break;

            case "Approved":
                tots = tots.filter(tot => tot.statuscode === TimeOffStatus.Approved);
                break;

            default: console.error('unhandled switch case for time off status filter pipe', status);
        }
        return tots;
    }

    private userFilter(tots: TimeOffActivity[], user: string): TimeOffActivity[] {

        return tots.filter(tot => tot.totOwnerValue === user);

    }

    private positionFilter(tots: TimeOffActivity[], position: string): TimeOffActivity[] {

        return tots.filter(tot => tot.positionValue === position);

    }

}
