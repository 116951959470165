<ion-grid no-padding>
    <ion-row>
        <ion-col class="left-pane">
            <ion-header>
                <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
                    <ion-buttons slot="start">
                        <ion-button large fill="clear" color="primary" (click)="closeModal(false)">
                            <div *ngIf="selectedCoaching.statuscode === 1"> {{'CANCEL' | translate}}</div>
                        </ion-button>
                    </ion-buttons>
                    <ion-title>{{'COACHING_TEAM_MEMBERS' | translate}}</ion-title>
                    <ion-buttons slot="end">
                        <ion-button [disabled]="isDoneDisabled" color="primary" *ngIf="selectedCoaching.statuscode === 1" (click)="closeModal(true)">
                            {{'DONE' | translate}}
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
                <ion-searchbar *ngIf="selectedCoaching.statuscode === 1" [(ngModel)]="searchTerm" (ionInput)="getItems()" placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}"></ion-searchbar>
            </ion-header>

            <ion-content no-padding>
                <ng-container *ngIf="selectedCoaching.statuscode === 1">
                    <ion-item-divider color="light">
                        <span [ngClass]="{ 'results-text' : searchTerm.length > 0}">
                <span *ngIf="searchTerm.length === 0">{{'ALL_TEAM_MEMBERS_CAP' | translate}}</span><span *ngIf="searchTerm.length > 0">{{'RESULTS'| translate}}</span> ({{filteredMembers.length}})
                        </span>
                    </ion-item-divider>
                    <div class="no-data-message" *ngIf="teamMembers.length == 0">{{'COACHING_NO_TEAM_MEMBERS_ASSOCIATED' | translate}}</div>
                    <ion-list *ngIf="filteredMembers.length > 0">
                        <ion-item class='selector-item' *ngFor="let user of filteredMembers | orderBy:'fullName':true; let last=last;" [ngClass]="{'search-last-result': last}" (click)="addSelected(user)">
                            <span text-wrap>{{user.fullName}}</span>
                            <ion-icon class="checkmark-icon" *ngIf="selectedCoachingFor && selectedCoachingFor.id === user.id" slot="end" name="indegene-selectors-checkmark-icon"></ion-icon>
                        </ion-item>
                    </ion-list>
                </ng-container>
            </ion-content>
            <!-- <ion-footer> -->
                <footer-toolbar [footerView]="'masterView'" [selectedView]="'CoachingForSelect'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>
        <ion-col class="right-pane"></ion-col>
    </ion-row>
</ion-grid>
