export class TherapeuticArea{
    name:string;
    therapeuticareaId:string;
    isAutoSelected:boolean = false;
    isSelected:boolean = false;
    isGeneeSelected:boolean = false;
    sequence:number;

    constructor(raw){
        this.name = raw['indskr_name'] || raw['activitytherapeuticareaname'];
        this.therapeuticareaId = raw['indskr_therapeuticareaid'] || raw['indskr_therapeuticareaId'] || raw['indskr_therapeuticarea'];
        this.sequence = raw['indskr_sequence'] || 0;
        this.isAutoSelected = raw['indskr_isautomaticallyselected'] || false;
        this.isGeneeSelected = raw['indskr_geneeselected'] || false;

    }

    get TherapeuticAreaDTO():any{
        return {
            "indskr_name": this.name,
            "indskr_therapeuticareaid": this.therapeuticareaId,
            "indskr_sequence": this.sequence,
            "indskr_isautomaticallyselected": this.isAutoSelected,
            "indskr_geneeselected": this.isGeneeSelected
        }
    }

    get TherapeuticAreaMeetingDTO():any{
        return{
            "indskr_therapeuticarea": this.therapeuticareaId,
            "indskr_sequence": this.sequence,
            "indskr_isautomaticallyselected": this.isAutoSelected,
            "indskr_geneeselected": this.isGeneeSelected,
            "activitytherapeuticareaname": this.name
        }
    }
}