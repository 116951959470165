<ion-grid [ngClass]="(!!orderManagementService.isRightPaneNavActive)?'preview-active':'preview-inactive'">
    <ion-row>
      <ion-col class="order-mgmt-left-pane omni-left-pane">
        <orders-list></orders-list>
      </ion-col>
      <ion-col class="order-mgmt-right-pane omni-right-pane">
        <ion-nav #ordermanagementrightpane></ion-nav>
      </ion-col>
    </ion-row>
</ion-grid>
