import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the TimeOffSearchPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'timeOffSearch',
})
export class TimeOffSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, searchParam?: string) {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    if (searchParam === 'created_by') {
      return items.filter(item => {
        if(item.totOwnerValue && item.reason){
          return ((item.totOwnerValue.toLowerCase().includes(searchText)) || (item.reason.toLowerCase().includes(searchText)))
        }
        else if (item.totOwnerValue) {
          return (item.totOwnerValue.toLowerCase().includes(searchText))
        } else if(item.reason) {
          return (item.reason.toLowerCase().includes(searchText))
        }
      });
    } else if (searchParam === 'reason'){
      return items.filter(item => {
        if (item.reason) {
          return (item.reason.toLowerCase().includes(searchText))
        }
      });
    } 
  }
}
