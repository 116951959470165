export class NextCallObjective {
  
  objectiveID: string;
  externalid: string;
  objective: string;
  stateCode: number = 0;
  statusCode: number = 1;
  isComplete : boolean;
  contactId: string;
  userId: string;
  capturedMeetingId: string;
  completedMeetingId: string;
  createdOn: number;
  syncPending : boolean = true;
  deletestate : boolean = false;

  constructor(raw: JSON) {
    this.objectiveID = raw['indskr_callobjectiveid'];
    this.externalid = raw['indskr_externalid'];
    this.objective = raw['indskr_callobjectives'];
    this.isComplete = raw['statuscode'] == 2 ? true : false;
    this.contactId = raw['_indskr_customer_value'];
    this.userId = raw['_indskr_user_value'];
    this.capturedMeetingId = raw['_indskr_objectivecaptured_value'];
    this.completedMeetingId = raw['_indskr_objectivecompleted_value'];
    this.createdOn = raw['createdon'];
    this.stateCode = raw['statecode'] || NextCallObjectiveStateCode.active;
    this.statusCode = raw['statuscode'] || NextCallObjectiveStatusCode.pending;
    this.syncPending = false;
  }

}

export enum NextCallObjectiveStatusCode {
  pending = 1,
  completed = 2
}


export enum NextCallObjectiveStateCode {
  active = 0,
  inActive = 1,
}

export enum NextCallObjectiveStatus {
  pending = 'Pending',
  completed = 'Completed',
  deleted = 'Deleted'
}


export interface NextCallObjectiveServiceDTO {
  indskr_callobjectiveid: string;
  indskr_callobjectives : string,
  statuscode : number,
  statecode : number,
  indskr_externalid : string,
  indskr_MeetingCaptured : string,
  indskr_MeetingCompleted : string,
  indskr_User: string,
  indskr_Customer_contact: string,
  deletestate : number
  createdon: number,
}