export class zipcode{
    public value:string
    public isSelected:boolean;
    public id:string;
    public stateId: string;
    public stateName: string;
    public stateCode: number;
    public cityId: string;
    public cityName: string;
    constructor(payload: zipPayload){
        this.value = payload.indskr_postalcode;
        this.id = payload.indskr_lu_postalcodeid;
        this.stateId = payload.state;
        this.cityId = payload._indskr_city_value;
        this.cityName= payload['_indskr_city_value@OData.Community.Display.V1.FormattedValue'];
        this.stateName= payload['state@OData.Community.Display.V1.FormattedValue'];
        this.stateCode = payload.statecode;
    }

}
export interface zipPayload {
    '@odata.etag': string;
    'statecode@OData.Community.Display.V1.FormattedValue': string;
    statecode: number;
    indskr_postalcode: string;
    '_indskr_city_value@OData.Community.Display.V1.FormattedValue': string;
    _indskr_city_value: string;
    indskr_lu_postalcodeid: string;
    'state@OData.Community.Display.V1.FormattedValue': string;
    state: string;
  }
