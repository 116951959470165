<ion-item lines="none" class="list-item"
  [ngClass]="{ 'sub-item' : isSubItem, 'list-item-division' :  headerSection , 'parent-item' : isParent }">

  <ion-button fill="clear"  slot="start" class="down-arrow-btn" *ngIf="isParent" (click)="onTap(value)">
    <!-- <ion-icon name="indegene-down-arrow"></ion-icon> -->
    <img src="assets/imgs/down-arrow.svg" alt="child-icon">
  </ion-button>

  <ion-button fill="clear"  slot="start" class="down-arrow-btn" *ngIf="isSubItem">
    <!-- <ion-icon name="indegene-child"></ion-icon> -->
    <img src="assets/imgs/child_item.svg" alt="child-icon">
  </ion-button>

  <ion-avatar class="contact-avatar-text" slot="start" *ngIf="!value.imageUrl" >
    <span class="ion-text-uppercase">{{ getFirstLetter(value.name) }}</span>
  </ion-avatar>
  <ion-avatar class="contact-avatar" slot="start" *ngIf="value.imageUrl">
    <img [src]="value.imageUrl" />
  </ion-avatar>
  <ion-label>{{ value.name }}</ion-label>


  <ng-container *ngIf="!isReadOnly">
    <ion-button slot="end" fill="clear" class="close-icon" *ngIf="value.isSelected && !isSelectionList"
      (click)="clikedButton('remove')">
      <!-- <ion-icon name="indegene-selectors-remove-icon"></ion-icon> -->
      <img src="assets/imgs/selectors_remove.svg" alt="remove-icon">
    </ion-button>

    <ion-button slot="end" fill="clear" class="close-icon" *ngIf="!value.isSelected" (click)="clikedButton('add')">
      <!-- <ion-icon name="indegene-selectors-add-icon"></ion-icon> -->
      <img src="assets/imgs/selectors_add.svg" alt="add-icon">
    </ion-button>

    <ion-button slot="end" fill="clear" class="close-icon" *ngIf="value.isSelected && isSelectionList">
      <img src="assets/imgs/selectors_checkmark.svg" alt="check-icon">
    </ion-button>
  </ng-container>

</ion-item>