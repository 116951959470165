<ng-container *ngIf="isLoading; else displayData">
  <io-spinner></io-spinner>
</ng-container>
<ng-template #displayData>
  <!--Template Selection Field  -->
  <ind-section-header [viewData]='teamAssessmentSectionHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-row>
    <ion-col size="6"><ind-form-field [viewData]='getTemplateSelection'></ind-form-field></ion-col>
  </ion-row>
  <div class="separationLine-main"></div>
  <!--Shared Sections Form View  -->
  <ng-container *ngFor="let viewData of sharedSectionFormView">
      <ind-section-header *ngIf="viewData.type == 'section-header'" [viewData]="viewData.view"></ind-section-header>
      <ng-container *ngIf="viewData.type == 'form-field'">
        <ind-form-field *ngIf="viewData.control?.Type != '451680002'" [viewData]='viewData.view'
          [ngClass]="viewData.id">
        </ind-form-field>
        <ng-container *ngIf="viewData.control?.Type == '451680002'">
          <ion-row>
            <ion-col size="6">
              <ind-datetime-form *ngIf="viewData.view" [viewData]='viewData.view'></ind-datetime-form>
            </ion-col>
            <ion-col size="6">
              <ind-datetime-form *ngIf="viewData.timeView" [viewData]='viewData.timeView'></ind-datetime-form>
            </ion-col>
          </ion-row>
        </ng-container>
      </ng-container>
  </ng-container>
  <!--Section Card Form View-->
  <ng-container *ngFor="let cardFormView of cardFormViewList">
      <ng-container *ngFor="let subFormView of cardFormView.viewData">
        <div *ngIf="subFormView.sectionHeaderViewData" class="separationLine"></div>
        <ind-section-header *ngIf="subFormView.sectionHeaderViewData" [viewData]="subFormView.sectionHeaderViewData" 
          (onControlClick)='onSectionHeaderControlClick($event, subFormView.sectionId)'></ind-section-header>
        <ng-container *ngIf="subFormView?.isExpanded">
          <div *ngIf="subFormView.viewCard" class="position-card">
            <div class="view-data" *ngFor="let viewData of subFormView.viewCard">
              <ion-item *ngIf="viewData.boxLetterText && viewData.positionName" class="box-letter-position-card">
                <p [attr.data-letters]="viewData.boxLetterText"><ion-text class='primary-text'>{{ viewData.positionName}}</ion-text></p>
              </ion-item>
              <ng-container *ngFor="let field of viewData.fieldData ">
                <div class="view-data-secondary">
                  <label class="title">{{field.label}}</label>
                </div>
                <div class="view-data-primary">
                  <label class="title" (click)="onInputTextClick($event, field)">{{field.inputText}}<ion-icon class="menu-arrow" *ngIf="field.imgSrc" [src]="field.imgSrc"></ion-icon></label>
                </div>  
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
  </ng-container>
  <!--Section Grid Form View-->
  <ng-container *ngFor="let subFormView of sectionsFormView">
      <div *ngIf="subFormView.sectionHeaderViewData" class="separationLine"></div>
      <ind-section-header *ngIf="subFormView.sectionHeaderViewData" [viewData]="subFormView.sectionHeaderViewData" 
        (onControlClick)='onSectionHeaderControlClick($event, subFormView.sectionId)'></ind-section-header>
      <ng-container *ngIf="subFormView?.isExpanded">
        <ion-grid class="grid-wrapper">
            <ion-row class="table-row">
              <ion-col *ngFor="let itemCol of subFormView.assessGrid; let i = index"
                [ngClass]="[i == 0 ? 'first-column' : 'assess-column', itemCol.sectionIdx%2 == 0 ? 'even-section' : 'odd-section']" class="{{itemCol.columnType}}">
                <div *ngFor="let item of itemCol['columnItems']; let j = index"
                  [ngClass]=" !item.isForcedHide ? (item.isHeaderFirst ? 'header-first' : (item.isHeaderSecond ? 'header-second' : 'input-item')):'hide-column-item'">
                  <ion-item class="item-text" *ngIf="!item.isInputField && !item.isForcedHide" [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' ">
                    <ion-label class="header-label">
                      <p class="primary-label-text">{{item.columnItemLabel}}</p>
                      <p class="secondary-label-text" *ngIf="item.columnItemSecondaryLabel">{{item.columnItemSecondaryLabel}}</p>
                    </ion-label>
                  </ion-item>
                  <ion-item class="input-field-wrap" *ngIf="item.isInputField || item.control?.Type == '451680002'">
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Numeric Field'" id="cellId-{{i}}-{{j}}"
                      [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                      <ion-text type="text" placeholder="{{item.placeHolder}}">{{item.columnItemLabel}}</ion-text>
                    </ion-label>
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Text Field'" id="cellId-{{i}}-{{j}}"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                      <ion-text type="text" placeholder="{{item.placeHolder}}">{{item.columnItemLabel}}</ion-text>
                    </ion-label>
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Drop Down Field'" id="cellId-{{i}}-{{j}}"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                      <ion-text type="text" placeholder="{{item.placeHolder}}">{{item.columnItemLabel}}<ion-icon class="menu-arrow" *ngIf="item.imgSrc" [src]="item.imgSrc"></ion-icon></ion-text>
                    </ion-label>
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Date Time Field'" id="cellId-{{i}}-{{j}}"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                      <ion-text type="text" placeholder="{{item.placeHolder}}">{{item.columnItemLabel}}</ion-text>
                    </ion-label>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
        </ion-grid>
      </ng-container>
  </ng-container>
</ng-template>