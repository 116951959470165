
<ion-grid no-padding class="content-page"
  [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row no-padding>
      <ion-col class="left-pane" no-padding >
        <coaching-list (onNewCoaching)="onNewCoaching($event)" (onCoachingReportSelection)="onCoachingReportSelection($event)" (closeModal)="onCloseModal($event)"></coaching-list>
      </ion-col>
      <ion-col class="right-pane" no-padding>
        <ion-nav #detailsPage></ion-nav> 
      </ion-col>
  </ion-row>
</ion-grid>
