<!-- Generated template for the TimeOffComponent component -->
<ion-grid no-padding class="time-off">
    <ion-row no-padding>
        <ion-col class="timeoff-left-pane" [ngClass]="!isPortrait ? 'timeoff-left-pane' :  {'mobile-in-focus': !timeOffService.totShowRightPane, 'mobile-hidden': timeOffService.totShowRightPane}" no-padding>
            <time-off-list [forceReload]="forceReload" (onNewTimeOff)="onNewAppointment($event)" (timeOffSelected)="onTimeOffSelection($event)" (closeModal)="onCloseModal($event)"></time-off-list>
        </ion-col>
        <ion-col class="timeoff-right-pane" [ngClass]="!isPortrait ? 'timeoff-right-pane' :  {'mobile-in-focus': timeOffService.totShowRightPane, 'mobile-hidden': !timeOffService.totShowRightPane}" no-padding>
            <nothing-selected-view *ngIf="uiService.activeView == 'TimeOff' || uiService.showNewActivity"></nothing-selected-view>
            <time-off-details [selectedTot]="selectedTot" *ngIf="!uiService.showNewActivity && uiService.activeView === 'TimeOffDetail'">
            </time-off-details>
            <new-time-off *ngIf="!uiService.showNewActivity && uiService.activeView === 'NewTimeOff'">
            </new-time-off>
        </ion-col>
    </ion-row>
</ion-grid>
<!-- <footer-toolbar [selectedView]="'Timeoff'"></footer-toolbar> -->