import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { StoreCheckActivity } from "@omni/classes/activity/store-check.activity.class";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from "@omni/components/shared/ind-datetime-form/ind-datetime-form";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { IndDateTimeFormViewDataModel } from "@omni/models/indDateTimeFormDataModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { ActivityService } from "@omni/services/activity/activity.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DeviceService } from "@omni/services/device/device.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { Subject } from "rxjs";
import { StoreCheckService } from '@omni/services/store-check/store-check.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { PopoverController } from '@ionic/angular';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import _ from 'lodash';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { IndPhotoCaptureViewerDataModel } from '@omni/models/indPhotoCaptureViewerDataModel';
import { IndPhotoCaptureViewerComponent } from './../../shared/ind-photo-capture-viewer/ind-photo-capture-viewer';
import { IndPhotoViewerComponent } from './../../shared/ind-photo-viewer/ind-photo-viewer';
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { AccountOfflineService, AccountSelectedFor } from "@omni/services/account/account.offline.service";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { EventsService } from "@omni/services/events/events.service";
import { AlertService } from "@omni/services/alert/alert.service";
import { Photo, PhotoOrigin, PhotoResponse } from '@omni/classes/store-check/photo';
import { UploadedPhoto } from '@omni/classes/store-check/uploaded-photo';
import { takeUntil } from "rxjs/operators";
import { PhotoAttachment, PhotoAttachmentDto } from '@omni/classes/store-check/photo-attachment';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { Product } from '@omni/classes/store-check/product';
import { IndPhotoViewerDataModel } from '@omni/models/indPhotoViewerDataModel';

@Component({
  selector: "store-check-details",
  templateUrl: "store-check-details.html",
  styleUrls: ['store-check-details.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StoreCheckDetailsComponent {
  private ngDestroy$: any = new Subject<boolean>();
  //DateFields
  startDateTime: string;
  startDate: string;
  startTime: string;
  endDateTime: string;
  endDate: string;
  endTime: string;

  //form-fields
  subjectFormField: IndFormFieldViewDataModel;
  startDateField: IndDateTimeFormViewDataModel;
  endDateField: IndDateTimeFormViewDataModel;
  accountFormField: IndFormFieldViewDataModel;
  categoryFormField: IndFormFieldViewDataModel;

  //sections
  public pageTitle: IndPageTitleViewDataModel;
  promoCheckSectionHeader: IndSectionHeaderViewDataModel;
  public isPromoCheckFAEnable: boolean = false;

  private selectedProducts: Product[];
  private removedProductIds: any[];
  public photoAttachments: PhotoAttachmentDto[] = [];

  constructor(public translate: TranslateService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public device: DeviceService,
    public authService: AuthenticationService,
    private navService: NavigationService,
    private storeCheckService: StoreCheckService,
    public popoverCtrl: PopoverController,
    private uiService: UIService,
    private readonly accountService: AccountOfflineService,
    private readonly notificationService: NotificationService,
    private events: EventsService,
    private alertService: AlertService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly datePipe: DatePipe,
  ) {

  }

  ngOnInit() {
    this.isPromoCheckFAEnable = this.authService.hasFeatureAction(FeatureActionsMap.PROMO_CHECK);
    this.init();
    this.storeCheckService.selectedStoreCheckActivity.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      if (value) {
        this.init();
      }
    })
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(isOffline => {
      this.init();
    })
  }

  private init() {
    this.initPageTitle();
    this.initPromoCheckSectionHeader();
    this.initFormFields();
    this.footerService.initButtons(FooterViews.STORE_CHECK);
    this.updateFooterButtonStatus();
  }

  private initPromoCheckSectionHeader() {
    const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
    this.promoCheckSectionHeader = {
      id: 'promo-check',
      title: this.translate.instant('PROMO_CHECK'),
      controls: [{
        id: 'add-product',
        text: this.translate.instant('ADD'),
        isDisabled: this.device.isOffline
          || this.activityService.selectedActivity.isCompleted
          || this.activityService.selectedActivity.ownerId != this.authService.user.xSystemUserID
          || _.isEmpty(storeCheckActivity.categoryId) || _.isEmpty(storeCheckActivity.accounts),
      }],
      isRequired: true,
    };
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  initFormFields() {
    let dateTimeValue = this.activityService.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.startDateTime = dateTimeValue.startDateTime;
      this.startDate = dateTimeValue.startDate;
      this.startTime = dateTimeValue.startTime;
      this.endDateTime = dateTimeValue.endDateTime;
      this.endDate = dateTimeValue.endDate;
      this.endTime = dateTimeValue.endTime;
    }
    this.initStartDateField();
    this.initEndDateField();
    this.initSubjectField();
    this.initAccountField();
    this.initCategoryField();
    this.initPromoCheckSectionHeader();
    this.initPhotoAttachments();
  }

  private initPhotoAttachments() {
    const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
    const photoAttachments = storeCheckActivity.photoAttachments ?? [];
    this.photoAttachments = photoAttachments.map(at => {
      const createdAt = this._getFormattedDate(at.overriddenCreatedOn);
      const productString = at.products.map(pr => pr.productName).join(', ');
      return { formattedDate: createdAt, photos: at.photos, productString: productString, products: at.products, overriddenCreatedOn: at.overriddenCreatedOn }
    });
  }

  private updateFooterButtonStatus() {
    const buttonsToDisabled = [];
    if (this.isScrapDisabled) {
      buttonsToDisabled.push('scrapStoreCheck');
    }
    if (this.isCompleteDisabled) {
      buttonsToDisabled.push('completeStoreCheck');
    }

    // if (!_.isEmpty(buttonsToDisabled)) {
    this.footerService.disableButton(buttonsToDisabled);
    // }
  }

  private initStartDateField() {
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDate,
      startDateTimeValue: this.startDateTime,
      endDateTimeValue: this.endDateTime,
      fromViewPage: CurViewPageType.StoreCheck,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }

  private initEndDateField() {
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDate,
      startDateTimeValue: this.startDateTime,
      endDateTimeValue: this.endDateTime,
      fromViewPage: CurViewPageType.Appointment,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isRequired: true,
      isDisabled: false,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }

  private initSubjectField() {
    this.subjectFormField = {
      label: this.translate.instant('SUBJECT'),
      inputText: this.activityService.selectedActivity.subject,
      inputValue: this.activityService.selectedActivity.subject,
      id: 'subject-field',
      isReadOnly: true,
      isDisabled: true,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      dropdown: undefined,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }

  private initAccountField() {
    const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
    this.accountFormField = {
      label: this.translate.instant('ACCOUNT'),
      inputText: _.isEmpty(storeCheckActivity.accounts) ? '' : storeCheckActivity.accountString,
      customPlaceholderLabel: this.translate.instant('SELECT_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isRequired: true,
      isDisabled: false,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }
  private initCategoryField() {
    const activity: StoreCheckActivity = <StoreCheckActivity>this.activityService.selectedActivity;
    this.categoryFormField = {
      label: this.translate.instant('STORE_CHECK_CATEGORY'),
      inputText: activity.categoryName,
      customPlaceholderLabel: this.translate.instant('SELECT_CATEGORY'),
      id: 'category-field',
      isReadOnly: true,
      isDisabled: this.activityService.selectedActivity.isCompleted || this.activityService.teamViewActive
        || this.activityService.selectedActivity.ownerId != this.authService.user.xSystemUserID
        || _.isEmpty(this.storeCheckService.productCategories),
      showArrow: true,
      isRequired: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }

  handleFormFieldEvent(id, event, eventName) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    switch (id) {
      case 'category-field':
        this.selectCategory(event);
        break;
      case 'account-field':
        this.openAccountSelection();
        break;
      case 'start-date-field':
        this.openStartDatePicker(event);
        break;
      case 'end-date-field':
        this.openEndDatePicker(event);
        break;
      default:
        break;
    }

  }

  async openStartDatePicker(myEvent) {
    if (this.isScrapDisabled) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.StoreCheck,
          startDateTimeValue: this.activityService.selectedActivity.scheduledStart,
          endDateTimeValue: this.activityService.selectedActivity.scheduledEnd
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime != '') {
        await this.updateStoreCheckDate(data.data);
      }
    });
    popover.present();
  }

  async openEndDatePicker(myEvent) {
    if (this.isScrapDisabled) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.StoreCheck,
          startDateTimeValue: this.activityService.selectedActivity.scheduledStart,
          endDateTimeValue: this.activityService.selectedActivity.scheduledEnd
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
        await this.updateStoreCheckDate(data.data);
      }
    });
    popover.present();
  }

  private async updateStoreCheckDate(dateData) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    const startTime = dateData.startTime;
    const endTime = dateData.endTime;
    const prevStartTime = dateData.prevStartTime;
    const prevEndTime = dateData.prevEndTime;
    let payload = {
      scheduledstart: new Date(startTime).getTime(),
      scheduledend: new Date(endTime).getTime()
    }
    await this.uiService.displayLoader();
    const activity = await this.storeCheckService.updateStoreCheck(payload);
    if (activity) {
      this.activityService.selectedActivity = activity;
    } else {
      this.activityService.selectedActivity.scheduledStart = new Date(prevStartTime);
      this.activityService.selectedActivity.scheduledEnd = new Date(prevEndTime);
    }
    if (!this.uiService.toolsActivityActive) {
      this.events.publish("refreshAgenda");
    } else this.uiService.agendaRefreshRequired = true;
    this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    this.activityService.selectedActivity.resetScheduledStartLocale();
    let dateTimeValue = this.activityService.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.startDate = dateTimeValue.startDate;
      this.endDate = dateTimeValue.endDate;
    }
    await this.uiService.dismissLoader();
    this.initStartDateField();
    this.initEndDateField();
  }

  private openAccountSelection() {
    if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) return;
    let mode = ComponentViewMode.ADDNEW;
    const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
    this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION;
    if (this.activityService.selectedActivity.isCompleted || this.activityService.teamViewActive
      || this.device.isOffline || this.activityService.selectedActivity.ownerId != this.authService.user.xSystemUserID) {
      mode = ComponentViewMode.READONLY;
    }
    let accountId = null;
    if (!_.isEmpty(storeCheckActivity.accounts)) {
      accountId = storeCheckActivity.accounts[0].indskr_accountid;
    } else {
      if (mode == ComponentViewMode.READONLY) {
        //No account selected, no need to navigate in readonly mode
        return;
      }
    }
    this.accountService.accessedAccountListFrom = PageName.StoreCheckDetailsComponent;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent,
      {
        listMode: mode, type: 'PushedAccountSelectionView',
        selectedAccountId: accountId,
        callbackEvent: (data: any) => this.handleAccountComponentCallback(data)
      },
      PageName.StoreCheckDetailsComponent);

  }

  private async handleAccountComponentCallback(data) {
    this.footerService.initButtons(FooterViews.STORE_CHECK);
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    if (data) {
      const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
      let payload;
      if (data.clearValue) {
        if (storeCheckActivity.photoAttachments?.length > 0) {
          const res = await this.alertService.showAlert({
            title: this.translate.instant('CHANGE_ACCOUNT_TITLE'),
            message: this.translate.instant('ACCOUNT_CHANGE_CONFIRMATION_MSG'),
          },
            this.translate.instant('CHANGE'),
          );
          if (res.role !== 'ok') return;
        }
        payload = {
          subject: [this.isPromoCheckFAEnable ? this.translate.instant("STORE_PROMO_CHECK") : this.translate.instant('STORE_CHECK'),
          this.isPromoCheckFAEnable ? _.isEmpty(storeCheckActivity.categoryId) ? '' : storeCheckActivity.categoryName : ''
          ].filter(Boolean).join(' - '),
          accounts: []
        }
      }
      else if (data.isDone && !this.isScrapDisabled) {
        const selectedItem = data.selectedItem;
        let accountId = null;
        if (!_.isEmpty(storeCheckActivity.accounts)) {
          accountId = storeCheckActivity.accounts[0].indskr_accountid;
        }
        if (selectedItem && accountId && accountId != selectedItem['id']) {
          if (storeCheckActivity.photoAttachments?.length > 0) {
            const res = await this.alertService.showAlert({
              title: this.translate.instant('CHANGE_ACCOUNT_TITLE'),
              message: this.translate.instant('ACCOUNT_CHANGE_CONFIRMATION_MSG'),
            },
              this.translate.instant('CHANGE'),
            );
            if (res.role !== 'ok') return;
          }
          //Account changed
          payload = {
            accounts: [
              {
                accountId: selectedItem['id'],
                indskr_accountid: selectedItem['id'],
                indskr_name: selectedItem['name']
              }
            ],
            promoChecks: []
          }
        } else if (selectedItem && !accountId) {
          //Account added
          payload = {
            accounts: [
              {
                accountId: selectedItem['id'],
                indskr_accountid: selectedItem['id'],
                indskr_name: selectedItem['name']
              }
            ]
          }
        }
        payload['subject'] = [this.isPromoCheckFAEnable ? this.translate.instant("STORE_PROMO_CHECK") : this.translate.instant('STORE_CHECK'),
        selectedItem['name'],
        this.isPromoCheckFAEnable ? _.isEmpty(storeCheckActivity.categoryId) ? '' : storeCheckActivity.categoryName : ''
        ].filter(Boolean).join(' - ');
      }
      if (payload) {
        await this.uiService.displayLoader();
        await this.storeCheckService.updateStoreCheck(payload);
        this.initFormFields();
        this.initPageTitle();
        await this.uiService.dismissLoader();
      }
    }
    this.updateFooterButtonStatus();
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'add-product':
        this.handleProductSelection();
        break;

      default:
        break;
    }
  }

  private handleProductSelection(photoAttachment?: PhotoAttachmentDto) {
    if (!this.device.isNativeApp && !photoAttachment) {
      this.notificationService.notify(this.translate.instant('PROMO_CHECK_BROWSER_WARNING'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    const isReadOnlyMode = this.isScrapDisabled;
    const selectedProducts:Product[] = photoAttachment ? _.cloneDeep(photoAttachment.products) : [];
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('XPERIENCES_FILTER_ALL_PRODUCTS'),
      isSearchEnabled: !isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: isReadOnlyMode,
      isListSelectionEnabled: !isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, photoAttachment),
      data: this.getProductsSelectionData(isReadOnlyMode, selectedProducts),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getProductsSelectionData(isReadOnlyMode, products: Product[]) {
    const activity: StoreCheckActivity = <StoreCheckActivity>this.activityService.selectedActivity;
    const productCategory = _.cloneDeep(this.storeCheckService.productCategories).find(cat => cat.indskr_productfamilycategoryid === activity.categoryId);
    const masterList = _.sortBy(productCategory.products, "productName");
    const selectedProducts = products.map(product => product.productId);
    const data: Array<MainCardViewDataModel> = masterList.map(item => {
      const isSelected = selectedProducts.includes(item.productId);
      return {
        id: item.productId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.productName,
        showArrow: false,
        eventOwnerId: this.activityService.selectedActivity.ownerId
      };
    });
    return data;
  }

  private async _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, photoAttachment: PhotoAttachmentDto) {
    console.log("data", data);
    if (eventTarget) {
      if (eventTarget === 'RightHeaderButtonClick') {
        await this.navService.popWithPageTracking();
        if (this.device.isOffline) {
          this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
          return;
        }
        this.selectedProducts = data.selectedItems.map(item => {
          return { productId: item.id, productName: item.primaryTextRight }
        });
        this.removedProductIds = data.removedItems.map(item => item.id);
        if (!photoAttachment && !_.isEmpty(data.selectedItems)) {
          this.openPhotoCaptureViewer();
        } else {
          if (photoAttachment) {
            if (data.removedItems.length || data.selectedItems.length) {
              const allProductsRemoved = data.removedItems.length > 0 && data.selectedItems.length == 0 && data.removedItems.length === photoAttachment.products.length;
              const payload = photoAttachment.photos.map(photo => {
                const photoAttachmentPayload = {
                  photoAttachmentId: photo.indskr_photoattachmentid,
                  deleted: allProductsRemoved,
                  products: []
                };
                data.removedItems.forEach(item => {
                  photoAttachmentPayload.products.push({ productId: item.id, deleted: true });
                });
                data.selectedItems.forEach(item => {
                  const productExists = photoAttachment.products.some(product => product.productId === item.id);
                  if (!productExists) {
                    photoAttachmentPayload.products.push({ productId: item.id, productName: item.primaryTextRight });
                  }
                });
                return photoAttachmentPayload;
              });
              console.log(payload);
              await this.uiService.displayLoader();
              await this.storeCheckService.updatePhotoAttachmentProducts(payload, this.selectedProducts, photoAttachment);
              this.initPageTitle();
              this.initPromoCheckSectionHeader();
              this.initPhotoAttachments();
              this.updateFooterButtonStatus();
              await this.uiService.dismissLoader();
            }
          }
        }
      } else if (eventTarget === 'LeftHeaderButtonClick') {
        await this.navService.popWithPageTracking();
      }
    }
  }

  private openPhotoCaptureViewer() {
    const activity: StoreCheckActivity = <StoreCheckActivity>this.activityService.selectedActivity;
    let etmsCode = '';
    let accountName = '';
    if (!_.isEmpty(activity.accounts)) {
      accountName = activity.accountString;
      const account = this.accountService.getAccountById(activity.accounts[0].indskr_accountid);
      if (account?.extendedDetails['externalId']) {
        etmsCode = account.extendedDetails['externalId']
      }
    }
    const photoModel: IndPhotoCaptureViewerDataModel = {
      pageTitle: this.translate.instant("ADD") + ' - ' + activity.subject,
      photosLimit: 10,
      photoNameFormat: `${this.authService.user.displayName} ${accountName} ${etmsCode} {currentTimestamp} ${this.translate.instant('PROMO_CHECK')}.PNG`,
      photoOrigin: PhotoOrigin.PROMO_CHECK,
      accountId: _.isEmpty(activity.accounts) ? '' : activity.accounts[0].indskr_accountid,
      errorMessage: this.translate.instant('MAX_PHOTO_NOTIFICATION', { limit: 10 }),
      callbackEvent: (data: []) => this._handlePhotoCaptureComponentCallback(data)
    };
    this.navService.pushWithPageTracking(IndPhotoCaptureViewerComponent, PageName.IndPhotoCaptureViewerComponent, { photoModel: photoModel }, PageName.IndPhotoCaptureViewerComponent);
  }

  private async _handlePhotoCaptureComponentCallback(data: Photo[]) {
    if(!_.isEmpty(data)) {
      const activity: StoreCheckActivity = <StoreCheckActivity>this.activityService.selectedActivity;
      let promoCheckPayload = [];

      if (!_.isEmpty(this.removedProductIds) && !_.isEmpty(activity.photoAttachments)) {
        //Delete products from all saved photos
        activity.photoAttachments.forEach((photoAtt: any) => {
          if (photoAtt['indskr_photoattachmentid']) {
            const payload = {
              photoAttachmentId: photoAtt['indskr_photoattachmentid']
            }
            const products = photoAtt.activityPhotos.filter(activityPhoto => this.removedProductIds.includes(activityPhoto['productId']));
            if (!_.isEmpty(products)) {
              payload['products'] = products.map(prod => {
                return {
                  deleted: true,
                  productId: prod.productId
                }
              });
            }
            if (!_.isEmpty(payload['products'])) {
              promoCheckPayload.push(payload);
            }
          }
        })
      }
      if (!_.isEmpty(this.selectedProducts)) {
        data.forEach(photo => {
          promoCheckPayload.push({
            name: photo.name,
            deleted: false,
            products: this.selectedProducts.map(p => {
              return { productId: p.productId, productName: p.productName, deleted: false }
            })
          })
        })

      }
      await this.uiService.displayLoader({ duration: 30000 });
      await this.storeCheckService.checkProductCategoryMismatch();
      await this.storeCheckService.uploadPhotosToBlobStorgae(data, promoCheckPayload);
      this.selectedProducts = [];
      this.removedProductIds = [];
      this.initPhotoAttachments();
      this.initPageTitle();
      this.updateFooterButtonStatus();
      await this.uiService.dismissLoader();
    }
  }

  async removeSelectedPhotos(photoAttachment: PhotoAttachmentDto) {
    const res = await this.alertService.showAlert({
        title: this.translate.instant('DELETE_PHOTOS_TITLE'),
        message: this.translate.instant('DELETE_PHOTOS_WARNING'),
      },
      this.translate.instant('DELETE'),
    );
    if (res.role !== 'ok') return;
    if (photoAttachment) {
      await this.uiService.displayLoader();
      await this.storeCheckService.deletePhotoAttachment(photoAttachment);
      this.initPageTitle();
      this.initPromoCheckSectionHeader();
      this.initPhotoAttachments();
      this.updateFooterButtonStatus();
      await this.uiService.dismissLoader();
    }
  }

  editPhotoAttachments(photoAttachment) {
    this.handleProductSelection(photoAttachment)
  }

  public openPhotoViewer(photoAttachment: PhotoAttachmentDto) {
    const activity: StoreCheckActivity = <StoreCheckActivity>this.activityService.selectedActivity;
    const photoModel: IndPhotoViewerDataModel = {
      pageTitle: `${this.translate.instant('PROMO_CHECK')} - ${photoAttachment.productString}`,
      masterPhotos: photoAttachment.photos,
      readOnly: this.isScrapDisabled,
      callbackEvent: (data: []) => this._handlePhotoViewerCallback(data, photoAttachment)
    };
    this.navService.pushWithPageTracking(IndPhotoViewerComponent, PageName.IndPhotoViewerComponent, { photoModel: photoModel }, PageName.IndPhotoViewerComponent);
  }

  private async _handlePhotoViewerCallback(removedItems: PhotoResponse[], photoAttachment: PhotoAttachmentDto) {
    console.log("PhotoResponse[] ", removedItems);
    if (removedItems.length) {
      const removedAttachmentIds = removedItems.map((item) => item.indskr_photoattachmentid);
      const photos:PhotoResponse[] = _.filter(photoAttachment.photos, (photo) =>
        _.includes(removedAttachmentIds, photo.indskr_photoattachmentid)
      );
      // const updatedProducts: Product[] = photoAttachment.products.map(product =>({...product, deleted: true}));
      const payload = photos.map(photo => ({ photoAttachmentId: photo.indskr_photoattachmentid, deleted: true, products: photoAttachment.products }));
      console.log(payload);
      await this.uiService.displayLoader();
      await this.storeCheckService.removePhotoAttachments(payload, removedItems, photoAttachment);
      this.initPageTitle();
      this.initPromoCheckSectionHeader();
      this.initPhotoAttachments();
      this.updateFooterButtonStatus();
      await this.uiService.dismissLoader();
    }
  }

  private async selectCategory(event) {
    const activity: StoreCheckActivity = <StoreCheckActivity>this.activityService.selectedActivity;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'store-check-category',
      data: _.cloneDeep(this.storeCheckService.productCategories).map(category => ({
        id: category.indskr_productfamilycategoryid,
        title: category.indskr_name,
        isSelected: category.indskr_productfamilycategoryid === activity.categoryId
      }))
    };
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    })
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      console.log("data ", data);
      if (this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
        return;
      }
      if (data && data.data && data.data.selectedItems && data.data.selectedItems.length == 1 && data.data.selectedItems[0].id != activity.categoryId) {
        console.log("selectedItems ", data.data.selectedItems[0]);
        if (activity.photoAttachments?.length > 0) {
          const res = await this.alertService.showAlert({
            title: this.translate.instant('CHANGE_CATEGORY_TITLE'),
            message: this.translate.instant('CATEGORY_CHANGE_CONFIRMATION_MSG'),
          },
            this.translate.instant('CHANGE'),
          );
          if (res.role !== 'ok') return;
        }
        const payload = {
          categoryId: data.data.selectedItems[0].id,
          categoryName: data.data.selectedItems[0].title,
          subject: [this.isPromoCheckFAEnable ? this.translate.instant("STORE_PROMO_CHECK") : this.translate.instant('STORE_CHECK'),
          _.isEmpty(activity.accounts) ? '' : activity.accountString,
          this.isPromoCheckFAEnable ? data.data.selectedItems[0].title : ''
          ].filter(Boolean).join(' - ')
        }
        if (activity.categoryId != null && activity.categoryId !== data.data.selectedItems[0].id) {
          payload['promoChecks'] = [];
        }
        if (payload) {
          await this.uiService.displayLoader();
          await this.storeCheckService.updateStoreCheck(payload);
          this.initFormFields();
          this.initPageTitle();
          this.updateFooterButtonStatus();
          this.activityService.publishActivityEvent({ action: "Update", activity: this.activityService.selectedActivity });
          await this.uiService.dismissLoader();
        }
        this.initCategoryField();
      }
    });
  }

  private initPageTitle(): void {
    const controls = [];
    controls.push({
      id: "scrap",
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('SCRAP'),
      isDisabled: this.isScrapDisabled,
      align: "right"
    },
      {
        id: "markComplete",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        isDisabled: this.isCompleteDisabled,
        align: "right"
      });
    if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
      controls.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      })
    }
    this.pageTitle = {
      id: 'store-check-page-title',
      title: this.activityService.selectedActivity.subject,
      controls: controls
    };
  }

  private get isCompleteDisabled(): boolean {
    const storeCheckActivity = this.activityService.selectedActivity ? this.activityService.selectedActivity as StoreCheckActivity : null;
    const isDisabled = storeCheckActivity && (this.device.isOffline || this.activityService.selectedActivity.isCompleted
      || _.isEmpty(storeCheckActivity.categoryId)
      || _.isEmpty(storeCheckActivity.accounts)
      || this.activityService.selectedActivity.ownerId != this.authService.user.xSystemUserID);
    return !isDisabled ? this.isPromoCheckFAEnable && _.isEmpty(storeCheckActivity.photoAttachments) ? true : isDisabled : isDisabled;
  }

  private get isScrapDisabled(): boolean {
    return this.device.isOffline
      || this.activityService.selectedActivity.isCompleted
      || this.activityService.teamViewActive
      || this.activityService.selectedActivity.ownerId != this.authService.user.xSystemUserID;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      case 'scrapStoreCheck':
      case 'scrap':
        this.handleScrap();
        break;
      case 'completeStoreCheck':
      case 'markComplete':
        this.handleComplete();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }
  private async handleScrap() {
    if (this.isScrapDisabled) return;
    this.alertService.showAlert({
      header: this.translate.instant('SCRAP_STORE_CHECK'),
      message: this.translate.instant('SCRAP_STORE_CHECK_CONFIRMATION_MSG')
    }, this.translate.instant('SCRAP')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.device.isOffline) {
          this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
          return;
        }
        await this.uiService.displayLoader();
        await this.storeCheckService.scrapStoreCheck(this.activityService.selectedActivity);
        this.activityService.publishActivityEvent({ action: "Delete", activity: this.activityService.selectedActivity });
        this.closePage();
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        } else this.uiService.agendaRefreshRequired = true;
        await this.uiService.dismissLoader();
      }
    })
  }

  private async handleComplete() {
    if (this.isCompleteDisabled) return;
    this.alertService.showAlert({
      header: this.translate.instant('MARK_COMPLETE'),
      message: this.translate.instant('COMPLETE_STORE_CHECK_CONFIRMATION_MSG').replace("{{subject}}", this.activityService.selectedActivity.subject)
    }, this.translate.instant('SUBMIT')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.device.isOffline) {
          this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
          return;
        }
        await this.uiService.displayLoader();
        await this.storeCheckService.completeStoreCheck();
        this.init();
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        } else this.uiService.agendaRefreshRequired = true;
        this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
        await this.uiService.dismissLoader();
      }
    });
  }


  private async closePage() {
    this.storeCheckService.setCurrentStoreCheckActivity(null);
    const currentPage: PageName = this.navService.getCurrentPageName();
    this.uiService.showRightPane = false;
    this.activityService.selectedActivity = undefined;
    //going back in activities page right pane
    if (currentPage === PageName.ActivitiesPageComponent) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    } else if (currentPage === PageName.AccountPageComponent) {
      this.uiService.showNewActivity = false;
      await this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Accounts);
    }
  }

  private _getFormattedDate(value: any) {
    try {
      if (value) {
        const noteDay = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        const noteTime = (new Date(value)).toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        return noteDay + " " + noteTime;
      }
    } catch (err) {
      console.error("Error while formatting date ", err);
    }
    return '';
  }

}