export const DEFAULT_CONTACT_CREATE_FORM = {
  value: [
    {
      createdon: "",
      importsequencenumber: null,
      indskr_entityid: null,
      indskr_entityname: "contact",
      indskr_formid: "",
      indskr_formtype: 548910000,
      indskr_mobileappformid: "",
      indskr_name: "Contact - Default Create",
      indskr_validate: false,
      modifiedon: "",
      overriddencreatedon: null,
      statecode: 0,
      statuscode: 1,
      timezoneruleversionnumber: null,
      utcconversiontimezonecode: null,
      versionnumber: null,
      _createdby_value: "",
      _createdonbehalfby_value: null,
      _modifiedby_value: "b40cee12-9518-e911-a984-000d3af49b9e",
      _modifiedonbehalfby_value: null,
      _ownerid_value: "",
      _owningbusinessunit_value: "",
      _owningteam_value: null,
      _owninguser_value: "",
      indskr_metadata: {
        EntityLogicalName: "contact",
        EntitySetName: "contacts",
        SchemaName: "Contact",
        Tabs: [
          {
            Controls: [
              {
                AttributeName: "indskr_title",
                DataType: "PicklistType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "SELECT_TITLE",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "TITLE",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_title",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "firstname",
                DataType: "StringType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "ENTER_FIRST_NAME",
                    LanguageCode: "0000"
                  },
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_FIRST_NAME",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: true,
                SchemaName: "FirstName",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "middlename",
                DataType: "StringType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "ENTER_MIDDLE_NAME",
                    LanguageCode: "0000"
                  },
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_MIDDLE_NAME",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "MiddleName",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "lastname",
                DataType: "StringType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "ENTER_LAST_NAME",
                    LanguageCode: "0000"
                  },
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_LAST_NAME",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: true,
                SchemaName: "LastName",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "telephone1",
                DataType: "StringType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "ENTER_BUSINESS_PHONE",
                    LanguageCode: "0000"
                  },
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_BUSINESS_PHONE",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "Telephone1",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "mobilephone",
                DataType: "StringType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "ENTER_MOBILE_PHONE",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_MOBILE_PHONE",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "MobilePhone",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "indskr_lu_professional_designationid",
                DataType: "LookupType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "SELECT_PROFESSIONAL_DESIGNATION",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_PROFESSIONAL_DESIGNATION",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: "indskr_lu_professional_designationid",
                LookupEntityPrimaryNameAttribute: "indskr_professionaldesignation",
                LookupEntitySetName: "indskr_lu_professional_designations",
                LookupQueries: [
                  {
                    EntityName: "indskr_lu_professional_designation",
                    FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_professional_designation"><attribute name="indskr_professionaldesignation" /><attribute name="createdon" /><order attribute="indskr_professionaldesignation" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or"><condition attribute="indskr_professionaldesignation" operator="like" value="{0}" /></filter><attribute name="indskr_lu_professional_designationid" /></entity></fetch>`,
                    ObjectTypeCode: 10098
                  }
                ],
                LookupTargets: [
                  "indskr_lu_professional_designation"
                ],
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_lu_professional_designationid",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "indskr_lu_languageid",
                DataType: "LookupType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "SELECT_PREFERRED_LANGUAGE",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_PREFERRED_LANGUAGE",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: "indskr_lu_languageid",
                LookupEntityPrimaryNameAttribute: "indskr_languagename",
                LookupEntitySetName: "indskr_lu_languages",
                LookupQueries: [
                  {
                    EntityName: "indskr_lu_language",
                    FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_language"><attribute name="indskr_languagename" /><attribute name="createdon" /><order attribute="indskr_languagename" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or"><condition attribute="indskr_languagename" operator="like" value="{0}" /><condition attribute="indskr_languagecodealpha3" operator="like" value="{0}" /><condition attribute="indskr_languagecodealpha2" operator="like" value="{0}" /></filter><attribute name="indskr_lu_languageid" /></entity></fetch>`,
                    ObjectTypeCode: 10096
                  }
                ],
                LookupTargets: [
                  "indskr_lu_language"
                ],
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_lu_languageid",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "indskr_lu_specialtyid",
                DataType: "LookupType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "SELECT_PRIMARY_SPECIALTY",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_PRIMARY_SPECIALTY",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: "indskr_lu_specialtyid",
                LookupEntityPrimaryNameAttribute: "indskr_specialty",
                LookupEntitySetName: "indskr_lu_specialties",
                LookupQueries: [
                  {
                    EntityName: "indskr_lu_specialty",
                    FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_specialty"><attribute name="indskr_specialty" /><attribute name="createdon" /><order attribute="indskr_specialty" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or"><condition attribute="indskr_specialty" operator="like" value="{0}" /></filter><attribute name="indskr_lu_specialtyid" /></entity></fetch>`,
                    ObjectTypeCode: 10100
                  }
                ],
                LookupTargets: [
                  "indskr_lu_specialty"
                ],
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_lu_specialtyid",
                Subgrid: null,
                Visible: true,
              },
              {
                AttributeName: "indskr_contactsourcetype",
                DataType: "PicklistType",
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "CONTACT_SOURCE_TYPE",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_SOURCE_TYPE",
                    LanguageCode: "0000"
                  }
                ],
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: true,
                Required: false,
                SchemaName: "indskr_ContactSourceType",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "EMAIL",
                DataType: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "EMAIL",
                    LanguageCode: "0000"
                  }
                ],
                IsList: true,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_customer",
                  ReferencingEntity: "indskr_email_address",
                  ReferencingEntityNavigationPropertyName: "indskr_customer_contact",
                  RelationshipName: "indskr_contact_indskr_email_address_Customer",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_emailaddress",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "Email Address",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_isprimary",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "ACCOUNT_PRIMARY_CONTACT_NAME",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_type",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "TYPE",
                            LanguageCode: "0000"
                          }
                        ]
                      }
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" mapping="logical">
                    <entity name="indskr_email_address">
                      <order attribute="indskr_emailaddress" descending="false" />
                      <attribute name="indskr_isprimary" />
                      <attribute name="indskr_emailaddress" />
                      <attribute name="indskr_type" />
                      <attribute name="indskr_customer" />
                      <attribute name="indskr_email_addressid" />
                      <filter>
                        <condition attribute="indskr_customer" operator="eq" value="{0}" />
                      </filter>
                    </entity>
                  </fetch>`,
                  TargetEntityType: "indskr_email_address",
                  ViewId: "{705E9018-59FC-4C27-90F9-EDB560A241F3}"
                },
                Visible: true,
              },
              {
                AttributeName: "ioCustomeraddress",
                DataType: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ADDRESSES",
                    LanguageCode: "0000"
                  }
                ],
                IsList: true,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_customer",
                  ReferencingEntity: "indskr_indskr_customeraddress_v2",
                  ReferencingEntityNavigationPropertyName: "indskr_Customer_contact",
                  RelationshipName: "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_line1",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "STREET_1_ADDRESS",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_country_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "COUNTRY",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_state_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "STATE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_city_lu",
                        Descriptions: [
                          {
                            Description: "",
                            LanguageCode: "0000"
                          }
                        ],
                        DisplayNames: [
                          {
                            Description: "CITY",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_postalcode_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "POSTAL_CODE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch mapping="logical" version="1.0">
                      <entity name="indskr_indskr_customeraddress_v2">
                        <attribute name="indskr_name" />
                        <attribute name="createdon" />
                        <order descending="false" attribute="indskr_name" />
                        <filter type="and">
                          <condition value="0" attribute="statecode" operator="eq" />
                        </filter>
                        <attribute name="indskr_samplingeligible" />
                        <attribute name="indskr_isprimary" />
                        <attribute name="indskr_indskr_customeraddress_v2id" />
                        <filter>
                          <condition attribute="indskr_customer" operator="eq" value="{0}" />
                        </filter>
                      </entity>
                    </fetch>`,
                  TargetEntityType: "indskr_indskr_customeraddress_v2",
                  ViewId: "{13A94E3C-BF2E-4528-8914-D17085120A6E}"
                },
                Visible: true,
              },
              {
                AttributeName: "ACCOUNTS",
                DataType: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ACCOUNT_AFFILIATION_CAP",
                    LanguageCode: "0000"
                  }
                ],
                IsList: true,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_contactid",
                  ReferencingEntity: "indskr_accountcontactaffiliation",
                  ReferencingEntityNavigationPropertyName: "indskr_contactid",
                  RelationshipName: "indskr_contact_accountcontactaffiliation_contact",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_accountid",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "Account",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "omnione_role",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_ROLE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" mapping="logical">
                    <entity name="indskr_accountcontactaffiliation">
                      <attribute name="indskr_name" />
                      <attribute name="createdon" />
                      <order attribute="indskr_accountid" descending="false" />
                      <order attribute="indskr_contactid" descending="false" />
                      <attribute name="omnione_role" />
                      <attribute name="indskr_contactid" />
                      <attribute name="indskr_accountid" />
                      <attribute name="indskr_accountcontactaffiliationid" />
                      <filter>
                        <condition attribute="indskr_contactid" operator="eq" value="{0}" />
                      </filter>
                    </entity>
                  </fetch>`,
                  TargetEntityType: "indskr_accountcontactaffiliation",
                  ViewId: "{8871F68F-9AB5-4ED7-9EDB-C36CABCF7F28}"
                },
                Visible: true,
              },
            ],
            DisplayNames: [
              {
                Description: "OVERVIEW",
                LanguageCode: "0000"
              }
            ]
          },
        ]
      }
    }
  ]
};

export const DEFAULT_CONTACT_DISPLAY_FORM = {
  value: [
    {
      createdon: "",
      importsequencenumber: null,
      indskr_entityid: null,
      indskr_entityname: "contact",
      indskr_formid: "",
      indskr_formtype: 548910000,
      indskr_mobileappformid: "",
      indskr_name: "Contact - Default Display",
      indskr_validate: false,
      modifiedon: "",
      overriddencreatedon: null,
      statecode: 0,
      statuscode: 1,
      timezoneruleversionnumber: null,
      utcconversiontimezonecode: null,
      versionnumber: null,
      _createdby_value: "",
      _createdonbehalfby_value: null,
      _modifiedby_value: "b40cee12-9518-e911-a984-000d3af49b9e",
      _modifiedonbehalfby_value: null,
      _ownerid_value: "",
      _owningbusinessunit_value: "",
      _owningteam_value: null,
      _owninguser_value: "",
      indskr_metadata: {
        EntityLogicalName: "contact",
        EntitySetName: "contacts",
        SchemaName: "Contact",
        Tabs: [
          {
            Controls: [
              {
                AttributeName: "indskr_title",
                DataType: "PicklistType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "TITLE",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_title",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "firstname",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_FIRST_NAME",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: false,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: true,
                SchemaName: "FirstName",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "middlename",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_MIDDLE_NAME",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: false,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "MiddleName",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "lastname",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_LAST_NAME",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: false,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: true,
                SchemaName: "LastName",
                Subgrid: null,
                Visible: true
              },
              // {
              //   AttributeName: "emailaddress1",
              //   DataType: "StringType",
              //   DateBehavior: null,
              //   DateFormat: null,
              //   Descriptions: [
              //     {
              //       Description: "Type the primary email address for the contact.",
              //       LanguageCode: "1033"
              //     }
              //   ],
              //   DisplayNames: [
              //     {
              //       Description: "PRIMARY_EMAIL",
              //       LanguageCode: "1033"
              //     }
              //   ],
              //   Format: "Email",
              //   IsCustomAttribute: false,
              //   IsList: false,
              //   LookupEntityPrimaryIdAttribute: null,
              //   LookupEntityPrimaryNameAttribute: null,
              //   LookupEntitySetName: null,
              //   LookupQueries: [],
              //   LookupTargets: null,
              //   MaxLength: 100,
              //   MaxValue: null,
              //   MinValue: null,
              //   OptionSet: {
              //     Options: []
              //   },
              //   Precision: 0,
              //   ReadOnly: true,
              //   Required: false,
              //   SchemaName: "EMailAddress1",
              //   Subgrid: null,
              //   Visible: true
              // },
              {
                AttributeName: "telephone1",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_BUSINESS_PHONE",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: false,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "Telephone1",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "mobilephone",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_MOBILE_PHONE",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: false,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 50,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "MobilePhone",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "indskr_alternatephone1",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ALTERNATE_PHONE_1",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 100,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_alternatephone1",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "indskr_alternatephone2",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ALTERNATE_PHONE_2",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 100,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_alternatephone2",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "indskr_alternatephone3",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ALTERNATE_PHONE_3",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 100,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_alternatephone3",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "indskr_lu_professional_designationid",
                DataType: "LookupType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_PROFESSIONAL_DESIGNATION",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: "indskr_lu_professional_designationid",
                LookupEntityPrimaryNameAttribute: "indskr_professionaldesignation",
                LookupEntitySetName: "indskr_lu_professional_designations",
                LookupQueries: [
                  {
                    EntityName: "indskr_lu_professional_designation",
                    FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_professional_designation"><attribute name="indskr_professionaldesignation" /><attribute name="createdon" /><order attribute="indskr_professionaldesignation" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or" ><condition attribute="indskr_professionaldesignation" operator="like" value="{0}" /></filter><attribute name="indskr_lu_professional_designationid" /></entity></fetch>`,
                    ObjectTypeCode: 10098
                  }
                ],
                LookupTargets: [
                  "indskr_lu_professional_designation"
                ],
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_lu_professional_designationid",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "indskr_lu_languageid",
                DataType: "LookupType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_PREFERRED_LANGUAGE",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: "indskr_lu_languageid",
                LookupEntityPrimaryNameAttribute: "indskr_languagename",
                LookupEntitySetName: "indskr_lu_languages",
                LookupQueries: [
                  {
                    EntityName: "indskr_lu_language",
                    FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_language"><attribute name="indskr_languagename" /><attribute name="createdon" /><order attribute="indskr_languagename" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or" ><condition attribute="indskr_languagename" operator="like" value="{0}" /><condition attribute="indskr_languagecodealpha3" operator="like" value="{0}" /><condition attribute="indskr_languagecodealpha2" operator="like" value="{0}" /></filter><attribute name="indskr_lu_languageid" /></entity></fetch>`,
                    ObjectTypeCode: 10096
                  }
                ],
                LookupTargets: [
                  "indskr_lu_language"
                ],
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_lu_languageid",
                Subgrid: null,
                Visible: true
              },
              // ADD Primaary Speciality
              {
                AttributeName: "indskr_lu_specialtyid",
                DataType: "LookupType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "CONTACT_PRIMARY_SPECIALTY",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: "indskr_lu_specialtyid",
                LookupEntityPrimaryNameAttribute: "indskr_specialty",
                LookupEntitySetName: "indskr_lu_specialties",
                LookupQueries: [
                  {
                    EntityName: "indskr_lu_specialty",
                    FetchXml: '<fetch returntotalrecordcount="true" count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_specialty"><attribute name="indskr_specialty" /><attribute name="createdon" /><order attribute="indskr_specialty" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or" ><condition attribute="indskr_specialty" operator="like" value="{0}" /></filter><attribute name="indskr_lu_specialtyid" /></entity></fetch>',
                    ObjectTypeCode: 10100
                  }
                ],
                LookupTargets: [
                  "indskr_lu_specialty"
                ],
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_lu_specialtyid",
                Subgrid: null,
                Visible: true
              },
              // {
              //   AttributeName: "indskr_speaker",
              //   DataType: "BooleanType",
              //   DateBehavior: null,
              //   DateFormat: null,
              //   Descriptions: [
              //     {
              //       Description: "Indicates if the contact does speaker engagements",
              //       LanguageCode: "1033"
              //     }
              //   ],
              //   DisplayNames: [
              //     {
              //       Description: "SPEAKER",
              //       LanguageCode: "0000"
              //     }
              //   ],
              //   Format: null,
              //   IsCustomAttribute: true,
              //   IsList: false,
              //   LookupEntityPrimaryIdAttribute: null,
              //   LookupEntityPrimaryNameAttribute: null,
              //   LookupEntitySetName: null,
              //   LookupQueries: [],
              //   LookupTargets: null,
              //   MaxLength: 0,
              //   MaxValue: null,
              //   MinValue: null,
              //   OptionSet: {
              //     Options: []
              //   },
              //   Precision: 0,
              //   ReadOnly: false,
              //   Required: false,
              //   SchemaName: "indskr_speaker",
              //   Subgrid: null,
              //   Visible: true
              // },
              // {
              //   AttributeName: "indskr_kol",
              //   DataType: "BooleanType",
              //   DateBehavior: null,
              //   DateFormat: null,
              //   Descriptions: [
              //     {
              //       Description: "Key Opinion Leader",
              //       LanguageCode: "1033"
              //     }
              //   ],
              //   DisplayNames: [
              //     {
              //       Description: "KOL",
              //       LanguageCode: "0000"
              //     }
              //   ],
              //   Format: null,
              //   IsCustomAttribute: true,
              //   IsList: false,
              //   LookupEntityPrimaryIdAttribute: null,
              //   LookupEntityPrimaryNameAttribute: null,
              //   LookupEntitySetName: null,
              //   LookupQueries: [],
              //   LookupTargets: null,
              //   MaxLength: 0,
              //   MaxValue: null,
              //   MinValue: null,
              //   OptionSet: {
              //     Options: []
              //   },
              //   Precision: 0,
              //   ReadOnly: false,
              //   Required: false,
              //   SchemaName: "indskr_kol",
              //   Subgrid: null,
              //   Visible: true
              // },
              // {
              //   AttributeName: "statecode",
              //   DataType: "StateType",
              //   DateBehavior: null,
              //   DateFormat: null,
              //   Descriptions: [
              //     {
              //       Description: "Shows whether the contact is active or inactive. Inactive contacts are read-only and can't be edited unless they are reactivated.",
              //       LanguageCode: "1033"
              //     }
              //   ],
              //   DisplayNames: [
              //     {
              //       Description: "Status",
              //       LanguageCode: "1033"
              //     }
              //   ],
              //   Format: null,
              //   IsCustomAttribute: false,
              //   IsList: false,
              //   LookupEntityPrimaryIdAttribute: null,
              //   LookupEntityPrimaryNameAttribute: null,
              //   LookupEntitySetName: null,
              //   LookupQueries: [],
              //   LookupTargets: null,
              //   MaxLength: 0,
              //   MaxValue: null,
              //   MinValue: null,
              //   OptionSet: {
              //     Options: [
              //       {
              //         Descriptions: [],
              //         DisplayNames: [
              //           {
              //             Description: "Active",
              //             LanguageCode: "1033"
              //           }
              //         ],
              //         Value: 0
              //       },
              //       {
              //         Descriptions: [],
              //         DisplayNames: [
              //           {
              //             Description: "Inactive",
              //             LanguageCode: "1033"
              //           }
              //         ],
              //         Value: 1
              //       }
              //     ]
              //   },
              //   Precision: 0,
              //   ReadOnly: false,
              //   Required: false,
              //   SchemaName: "StateCode",
              //   Subgrid: null,
              //   Visible: false
              // },
              {
                AttributeName: "indskr_contactsourcetype",
                DataType: "PicklistType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [
                  {
                    Description: "CONTACT_SOURCE_TYPE",
                    LanguageCode: "0000"
                  }
                ],
                DisplayNames: [
                  {
                    Description: "CONTACT_SOURCE_TYPE",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: true,
                Required: false,
                SchemaName: "indskr_ContactSourceType",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "indskr_externalid",
                DataType: "StringType",
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "MDM ID",
                    LanguageCode: "0000"
                  }
                ],
                Format: "Text",
                IsCustomAttribute: true,
                IsList: false,
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 100,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: "indskr_externalid",
                Subgrid: null,
                Visible: true
              },
              {
                AttributeName: "EmailAddressDisplay",
                DataType: null,
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "EMAIL",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: false,
                IsList: true,
                isSearchable:true,
                searchAttributeName:'indskr_email_address.indskr_emailaddress',
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: 'indskr_email_addresses',
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_customer",
                  ReferencingEntity: "indskr_email_address",
                  ReferencingEntityNavigationPropertyName: "indskr_customer_contact",
                  RelationshipName: "indskr_contact_indskr_email_address_Customer",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_emailaddress",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "Email Address",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_isprimary",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "ACCOUNT_PRIMARY_CONTACT_NAME",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" mapping="logical">
                                <entity name="indskr_email_address">
                                  <order attribute="indskr_emailaddress" descending="false" />
                                  <attribute name="indskr_isprimary" />
                                  <attribute name="indskr_emailaddress" />
                                  <attribute name="indskr_type" />
                                  <attribute name="indskr_email_addressid" />
                                  <filter>
                                    <condition attribute="indskr_customer" operator="eq" value="{0}" />
                                  </filter>
                                </entity>
                              </fetch>`,
                  TargetEntityType: "indskr_email_address",
                  ViewId: "{705E9018-59FC-4C27-90F9-EDB560A241F3}"
                },
                Visible: true
              },
              {
                AttributeName: "CustomerAddresses",
                DataType: null,
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ADDRESSES",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: false,
                IsList: true,
                isSearchable:true,
                searchAttributeName:'indskr_indskr_customeraddress_v2.indskr_address',
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: null,
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_customer",
                  ReferencingEntity: "indskr_indskr_customeraddress_v2",
                  ReferencingEntityNavigationPropertyName: "indskr_Customer_contact",
                  RelationshipName: "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_address.indskr_composite",
                        Descriptions: [],
                        DisplayNames: []
                      },
                      {
                        Attribute: "indskr_address.indskr_line1",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "STREET_1_ADDRESS",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_address.indskr_country_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "COUNTRY",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_address.indskr_state_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "STATE_OR_PROVINCE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_address.indskr_city_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "CITY",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_address.indskr_postalcode_lu",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "POSTAL_CODE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                      <entity name="indskr_indskr_customeraddress_v2">
                        <attribute name="indskr_name" />
                        <order attribute="indskr_name" descending="false" />
                        <attribute name="indskr_address" />
                        <link-entity alias="indskr_address" name="indskr_address" from="indskr_addressid" to="indskr_address" link-type="outer" visible="false">
                          <attribute name="indskr_line1" />
                          <attribute name="indskr_state_lu" />
                          <attribute name="indskr_postalcode_lu" />
                          <attribute name="indskr_city_lu" />
                        </link-entity>
                        <attribute name="indskr_indskr_customeraddress_v2id" />
                        <filter>
                          <condition attribute="indskr_customer" operator="eq" value="{0}" />
                        </filter>
                      </entity>
                    </fetch>`,
                  TargetEntityType: "indskr_indskr_customeraddress_v2",
                  ViewId: "{25AD3833-4C8F-EA11-A811-000D3A1AB78A}"
                },
                Visible: true
              },
              {
                AttributeName: "AccountAffiliations",
                DataType: null,
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "ACCOUNT_AFFILIATIONS",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: false,
                IsList: true,
                isSearchable:true,
                searchAttributeName:'indskr_accountcontactaffiliation.indskr_accountid',
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: 'indskr_accountcontactaffiliations',
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_contactid",
                  ReferencingEntity: "indskr_accountcontactaffiliation",
                  ReferencingEntityNavigationPropertyName: "indskr_contactid",
                  RelationshipName: "indskr_contact_accountcontactaffiliation_contact",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_accountid",
                        Descriptions: [],
                        DisplayNames: []
                      },
                      {
                        Attribute: "omnione_role",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_ROLE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_affiliationsourcetype",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_SOURCE_TYPE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_mdm",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_MDM",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                      <entity name="indskr_accountcontactaffiliation">
                        <order attribute="indskr_contactid" descending="false" />
                        <attribute name="statecode" />
                        <attribute name="omnione_role" />
                        <attribute name="indskr_accountid" />
                        <attribute name="indskr_accountcontactaffiliationid" />
                        <attribute name="indskr_affiliationsourcetype" />
                        <attribute name="indskr_mdm" />
                        <attribute name="createdon" />
                        <attribute name="indskr_startdate" />
                        <attribute name="indskr_enddate" />
                        <filter>
                          <condition attribute="indskr_contactid" operator="eq" value="{0}" />
                        </filter>
                      </entity>
                    </fetch>`,
                  TargetEntityType: "indskr_accountcontactaffiliation",
                  ViewId: "{67A358E8-BC8E-E911-A980-000D3AF49C44}"
                },
                Visible: true
              },
              {
                AttributeName: "Contact_From_Relationship",
                DataType: null,
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "RELATIONSHIPS",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: false,
                IsList: true,
                isSearchable:true,
                searchAttributeName:'indskr_contactrelationship.indskr_relatedcontactid',
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: 'indskr_contactrelationships',
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_contactid",
                  ReferencingEntity: "indskr_contactrelationship",
                  ReferencingEntityNavigationPropertyName: "indskr_contactid",
                  RelationshipName: "indskr_contact_contactrelationship_contact",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_relatedcontactid",
                        Descriptions: [],
                        DisplayNames: []
                      },
                      {
                        Attribute: "omnione_relationship",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "RELATIONSHIP",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_relationshipsourcetype",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_SOURCE_TYPE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_mdm",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_MDM",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                                    <entity name="indskr_contactrelationship">
                                      <order attribute="indskr_relatedcontactid" descending="false" />
                                      <attribute name="indskr_relationship" />
                                      <attribute name="omnione_relationship" />
                                      <attribute name="indskr_relatedcontactid" />
                                      <attribute name="indskr_contactid" />
                                      <attribute name="indskr_mdm" />
                                      <attribute name="indskr_relationshipsourcetype" />
                                      <attribute name="createdon" />
                                      <attribute name="createdby" />
                                      <attribute name="indskr_comments" />
                                      <attribute name="indskr_contactrelationshipid" />
                                      <attribute name="statecode" />
                                      <filter>
                                        <condition attribute="indskr_contactid" operator="eq" value="{0}" />
                                      </filter>
                                    </entity>
                                  </fetch>`,
                  TargetEntityType: "indskr_contactrelationship",
                  ViewId: "{95ADE36D-1D00-E711-810A-480FCFEAF991}"
                },
                Visible: true
              },
              {
                AttributeName: "Contact_To_Relationship",
                DataType: null,
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "RELATIONSHIPS_TO",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: false,
                IsList: true,
                isSearchable:true,
                searchAttributeName:'indskr_contactrelationship.indskr_contactid',
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: 'indskr_contactrelationships',
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_relatedcontactid",
                  ReferencingEntity: "indskr_contactrelationship",
                  ReferencingEntityNavigationPropertyName: "indskr_relatedcontactid",
                  RelationshipName: "indskr_contact_contactrelationship_relatedcontactid",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_contactid",
                        Descriptions: [],
                        DisplayNames: []
                      },
                      {
                        Attribute: "omnione_relationship",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "RELATIONSHIP",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_relationshipsourcetype",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_SOURCE_TYPE",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                      {
                        Attribute: "indskr_mdm",
                        Descriptions: [],
                        DisplayNames: [
                          {
                            Description: "AFFILIATION_MDM",
                            LanguageCode: "0000"
                          }
                        ]
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                                    <entity name="indskr_contactrelationship">
                                      <order attribute="indskr_contactid" descending="false" />
                                      <attribute name="indskr_relationship" />
                                      <attribute name="omnione_relationship" />
                                      <attribute name="indskr_contactid" />
                                      <attribute name="indskr_relatedcontactid" />
                                      <attribute name="indskr_mdm" />
                                      <attribute name="indskr_relationshipsourcetype" />
                                      <attribute name="createdon" />
                                      <attribute name="createdby" />
                                      <attribute name="indskr_comments" />
                                      <attribute name="indskr_contactrelationshipid" />
                                      <attribute name="statecode" />
                                      <filter>
                                        <condition attribute="indskr_relatedcontactid" operator="eq" value="{0}" />
                                      </filter>
                                    </entity>
                                  </fetch>`,
                  TargetEntityType: "indskr_contactrelationship",
                  ViewId: "{95ADE36D-1D00-E711-810A-480FCFEAF991}"
                },
                Visible: true
              },
              {
                AttributeName: "CustomerPosition",
                DataType: null,
                DateBehavior: null,
                DateFormat: null,
                Descriptions: [],
                DisplayNames: [
                  {
                    Description: "COVERAGE_TEAM",
                    LanguageCode: "0000"
                  }
                ],
                Format: null,
                IsCustomAttribute: false,
                IsList: true,
                isSearchable:true,
                searchAttributeName:'indskr_customerposition.indskr_positionid',
                LookupEntityPrimaryIdAttribute: null,
                LookupEntityPrimaryNameAttribute: null,
                LookupEntitySetName: 'indskr_customerpositions',
                LookupQueries: [],
                LookupTargets: null,
                MaxLength: 0,
                MaxValue: null,
                MinValue: null,
                OptionSet: {
                  Options: []
                },
                Precision: 0,
                ReadOnly: false,
                Required: false,
                SchemaName: null,
                Subgrid: {
                  ParentAttribute: "contactid",
                  ParentEntity: "contact",
                  ReferencingAttribute: "indskr_customerid",
                  ReferencingEntity: "indskr_customerposition",
                  ReferencingEntityNavigationPropertyName: "indskr_customerid_contact",
                  RelationshipName: "indskr_contact_customerposition_Customer",
                  RelationshipType: 0,
                  SubgridLayout: {
                    Attributes: [
                      {
                        Attribute: "indskr_positionid",
                        Descriptions: [],
                        DisplayNames: []
                      },
                    ]
                  },
                  SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                    <entity name="indskr_customerposition">
                      <attribute name="indskr_name" />
                      <order attribute="indskr_name" descending="false" />
                      <attribute name="indskr_positionid" />
                      <attribute name="indskr_customerpositionid" />
                      <filter>
                        <condition attribute="indskr_customerid" operator="eq" value="{0}" />
                      </filter>
                    </entity>
                  </fetch>`,
                  TargetEntityType: "indskr_customerposition",
                  ViewId: "{D30CC7F1-36C0-EA11-A812-000D3A1ABBDD}"
                },
                Visible: true
              }
            ],
            DisplayNames: [
              {
                Description: "OVERVIEW",
                LanguageCode: "0000"
              }
            ]
          }
        ]
      }
    }
  ]
}

export const RequiredContactAttributes = [
  "indskr_isguest",
  "indskr_externalid",
  "firstname",
  "middlename",
  "lastname",
  "fullname",
  "indskr_lu_specialtyid",
  "indskr_primaryaccount",
  "statuscode",
  "statecode",
  "indskr_alternatephone1",
  "indskr_alternatephone2",
  "indskr_alternatephone3",
  "mobilephone",
  "modifiedon",
  "indskr_iseventparticipant",
  "indskr_mdm",
  "indskr_contactsourcetype",
  "indskr_mdmid",
  "indskr_wechatuserid"
]

export const RequiredContactCRAttributes = [
  "indskr_firstname",
  "indskr_lastname",
  "indskr_requestcomments",
  "indskr_responsecomments",
  "statuscode",
  "statecode",
  "createdon",
  "modifiedon",
  "indskr_mdm",
  "indskr_mdmrequesttype",
  "indskr_contactsourcetype",
  "indskr_mdmid",
  "indskr_contactdcr"
]

export const ConfigExcludedAttributes = [
  "firstname",
  "middlename",
  "lastname",
  "fullname",
]

export const TAG_LABEL_CONTROL = {
    AttributeName: "tagLabels",
    DataType: "StringType",
    DateBehavior: null,
    DateFormat: null,
    Descriptions: [],
    DisplayNames: [
      {
        Description: "TAGS",
        LanguageCode: "0000"
      }
    ],
    Format: "Text",
    IsCustomAttribute: false,
    IsList: false,
    LookupEntityPrimaryIdAttribute: null,
    LookupEntityPrimaryNameAttribute: null,
    LookupEntitySetName: null,
    LookupQueries: [],
    LookupTargets: null,
    MaxLength: 50,
    MaxValue: null,
    MinValue: null,
    OptionSet: {
      Options: []
    },
    Precision: 0,
    ReadOnly: false,
    Required: true,
    SchemaName: "tagLabels",
    Subgrid: null,
    Visible: true
}

export enum multilingualLanguageToAttributeMapping {
  "dynamics_language_code_1030" = "omnione_da_dk_long_label",
  "dynamics_language_code_1043" = "omnione_nl_nl_long_label",
  "dynamics_language_code_2067" = "omnione_nl_be_long_label",
  "dynamics_language_code_2057" = "omnione_en_gb_long_label",
  "dynamics_language_code_1033" = "omnione_en_long_label",
  "dynamics_language_code_1035" = "omnione_fi_fi_long_label",
  "dynamics_language_code_2060" = "omnione_fr_be_long_label",
  "dynamics_language_code_4108" = "omnione_fr_ch_long_label",
  "dynamics_language_code_1036" = "omnione_fr_fr_long_label",
  "dynamics_language_code_3079" = "omnione_de_at_long_label",
  "dynamics_language_code_2055" = "omnione_de_ch_long_label",
  "dynamics_language_code_1031" = "omnione_de_de_long_label",
  "dynamics_language_code_1040" = "omnione_it_it_long_label",
  "dynamics_language_code_20" = "omnione_no_no_long_label",
  "dynamics_language_code_2068" = "omnione_no_no_long_label",
  "dynamics_language_code_2070" = "omnione_pt_pt_long_label",
  "dynamics_language_code_3082" = "omnione_es_es_long_label",
  "dynamics_language_code_1053" = "omnione_sv_se_long_label",
  "dynamics_language_code_1055" = "omnione_tr_tr_long_label"
}

export const oneKeyCodeAttribute = [
  "omnione_da_dk_long_label",
  "omnione_nl_nl_long_label",
  "omnione_nl_be_long_label",
  "omnione_en_gb_long_label",
  "omnione_en_long_label",
  "omnione_fi_fi_long_label",
  "omnione_fr_be_long_label",
  "omnione_fr_ch_long_label",
  "omnione_fr_fr_long_label",
  "omnione_de_at_long_label",
  "omnione_de_ch_long_label",
  "omnione_de_de_long_label",
  "omnione_it_it_long_label",
  "omnione_no_no_long_label",
  "omnione_no_no_long_label",
  "omnione_pt_pt_long_label",
  "omnione_es_es_long_label",
  "omnione_sv_se_long_label",
  "omnione_tr_tr_long_label"
]
