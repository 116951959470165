import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { PositionListTarget } from '@omni/classes/territory-management-list/position-list-target.class';
import { PositionListTargetContact, CustomerPositionList, StatusCode, Account, CustomerPositionListType } from '@omni/classes/territory-management-list/customer-position-list.class';
import { CustomerListPeriod } from '@omni/classes/territory-management-list/customer-list-period.class';
import { ApprovalStatus } from '@omni/classes/quotes/quotes.class';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from '../global-utility.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService } from '../disk/disk.service';
import { AuthenticationService } from '../authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';

@Injectable({
  providedIn: 'root'
})
export class TerritoryManagementService {
  availableContactsSubject = new BehaviorSubject([]);

  customerPositionList = new BehaviorSubject([]);

  rawCustomerPositionList: CustomerPositionList;
  public selectedCustomerPostionListSubject: any = new BehaviorSubject({});
  public currentListPeriod: CustomerListPeriod;
  public contactsForNextPeriod: Contact[] = [];
  currentQAccountIds: string[] = [];
  basePositionListTargets: PositionListTarget[] = [];

  // ? when selected/created customerposition list is for currentperiod
  currentListPeriodCustomerList = false;

  constructor(private readonly translate: TranslateService,
    public utilityService: GlobalUtilityService,
    private diskService: DiskService,
    private authService: AuthenticationService
  ) { }


  addCustomerPositionList(newCustomerPositionList) {
    const customerPositionList = this.customerPositionList.getValue();
    customerPositionList.push(newCustomerPositionList);
    this.customerPositionList.next(customerPositionList);
  }

  setAvailableContacts(contacts) {
    this.availableContactsSubject.next(contacts);
  }

  public getListPeriodTags(): string[] {
    if(!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) return [];
    const tags: string[] = [];
    if (this.currentListPeriod) {
      tags.push(this.currentListPeriod.tagLabel);
      if (this.currentListPeriod.indskr_nextlistperiod) {
        tags.push(this.currentListPeriod.indskr_nextlistperiod.tagLabel);
      }
      if (this.currentListPeriod.indskr_previouslistperiod) {
        tags.push(this.currentListPeriod.indskr_previouslistperiod.tagLabel);
      }
    }
    return _.filter(tags).sort();
  }

  setSelectionForAvailableContacts() {
    const selectCustomerPositionList = this.selectedCustomerPostionListSubject.getValue();
    if (selectCustomerPositionList.positionListTargets.length) {
      const myContactIds = selectCustomerPositionList.positionListTargets.map((contact) => contact.contact.id);
      let availableContacts = this.availableContactsSubject.getValue();
      availableContacts = availableContacts.map((positionListTarget) => {
        if (myContactIds.includes(positionListTarget.contact.id)) positionListTarget.contact.isSelected = true;
        return positionListTarget;
      });
      this.availableContactsSubject.next(availableContacts);
    }
  }

  getApprovalActiviyStatusCodeFormatted(statuscode) {
    switch (statuscode) {
      case ApprovalStatus.DRAFT: return this.translate.instant('OM_DRAFT');
      case ApprovalStatus.PENDING: return this.translate.instant('PENDING');
      case ApprovalStatus.APPROVED: return this.translate.instant('APPROVED');
      case ApprovalStatus.NOT_APPROVED: return this.translate.instant('NOT_APPROVED');
      case ApprovalStatus.CANCELED: return this.translate.instant('CANCELLED');
      case ApprovalStatus.AUTO_APPROVED: return this.translate.instant('AUTO_APPROVED');
      case ApprovalStatus.IN_ACTIVE: return this.translate.instant('IN_ACTIVE');
      case ApprovalStatus.ESCALATED: return this.translate.instant('ESCALATED');
    }
  }

  getStatusCodeFormatted(statusCode) {
    switch (statusCode) {
      case StatusCode.FOR_REVIEW: return this.translate.instant('FOR_REVIEW');
      case StatusCode.OPEN: return this.translate.instant('OPEN');
      case StatusCode.APPROVED: return this.translate.instant('APPROVED');
      case StatusCode.EXPIRED: return this.translate.instant('EXPIRED_LOT');
      case StatusCode.CANCELED: return this.translate.instant('CANCELLED');
    }
  }

  removeSelectionForAvailableContacts(remoevdContact) {
    let availableContacts = this.availableContactsSubject.getValue();
    availableContacts = availableContacts.map((aContact) => {
      if (remoevdContact.id == aContact.contact.id) {
        aContact.contact.isSelected = false;
        aContact.contact.accounts.forEach(ac => ac.isSelected = false);
      }
      return aContact;
    });
    this.availableContactsSubject.next(availableContacts);
  }

  setMyContacts(customerPositionList) {
    let selectCustomerPositionList = this.selectedCustomerPostionListSubject.getValue();
    selectCustomerPositionList = customerPositionList;
    this.rawCustomerPositionList = _.cloneDeep(selectCustomerPositionList);
    this.selectedCustomerPostionListSubject.next(selectCustomerPositionList);
    this.setSelectionForAvailableContacts();
  }

  addContactToMyContact(contact) {
    const selectCustomerPositionList = this.selectedCustomerPostionListSubject.getValue();
    const selectedContact = new PositionListTargetContact(contact['id'], contact['name'], contact['accounts']);
    selectedContact['isSelected'] = true;
    selectCustomerPositionList.positionListTargets.push(new PositionListTarget(null, selectedContact));
    this.updateOnContactAddition(contact, selectCustomerPositionList);
    this.selectedCustomerPostionListSubject.next(selectCustomerPositionList);
  }

  removeContactFromMycontact(contact, selectedAccounts) {
    const selectCustomerPositionList = this.selectedCustomerPostionListSubject.getValue();
    const indexToRemove = selectCustomerPositionList.positionListTargets.findIndex((existingContact) => existingContact.contact.id === contact.id);
    selectCustomerPositionList.positionListTargets.splice(indexToRemove, 1);
    this.removeSelectionForAvailableContacts(contact);
    this.updateOnContactRemoval(contact, selectCustomerPositionList, selectedAccounts);
    this.selectedCustomerPostionListSubject.next(selectCustomerPositionList);
  }

  updateAccount(selectedAccount) {
    const selectCustomerPositionList = this.selectedCustomerPostionListSubject.getValue();
    const contactToUpdate = selectCustomerPositionList.positionListTargets.find((contact) => contact.contact.id === selectedAccount.parentId);
    if (!contactToUpdate) return
    let accountIndex = contactToUpdate.contact.accounts.findIndex((account) => account.id == selectedAccount.id);
    if (accountIndex < 0) return;
    contactToUpdate.contact.accounts[accountIndex] = selectedAccount;
    if (selectedAccount.isSelected) {
      this.updateOnAccountAddition(selectedAccount.id, selectedAccount.parentId, selectCustomerPositionList);
    } else {
      this.updateOnAccountRemoval(selectedAccount.id, selectedAccount.parentId, selectCustomerPositionList);
    }
    this.selectedCustomerPostionListSubject.next(selectCustomerPositionList);
  }

  private updateOnAccountRemoval(accountId, contactId, selectedCustomerPositionList: CustomerPositionList) {
    if (_.isEmpty(this.basePositionListTargets)) return;
    //If removed account is present in BASE
    if (this.currentQAccountIds.includes(accountId)) {
      //Check if contact is present 
      const baseContact = this.basePositionListTargets.find(plt => plt.contact.id === contactId);
      if (baseContact) {
        //check if removed account is present in base
        const baseAcc = baseContact.contact.accounts.find(acc => acc.id === accountId);
        if (baseAcc) {
          selectedCustomerPositionList.indskr_numberremoved++;
        } else {
          selectedCustomerPositionList.indskr_numberadded--;
        }
      } else if (selectedCustomerPositionList.indskr_numberadded > 0) selectedCustomerPositionList.indskr_numberadded--;
      selectedCustomerPositionList.indskr_netchange = selectedCustomerPositionList.indskr_numberremoved + selectedCustomerPositionList.indskr_numberadded;
      selectedCustomerPositionList.indskr_percentagechange = Math.round((selectedCustomerPositionList.indskr_netchange / this.basePositionListTargets.length) * 100);
    }
  }

  private updateOnAccountAddition(accountId, contactId, selectedCustomerPositionList: CustomerPositionList) {
    if (_.isEmpty(this.basePositionListTargets)) return;
    //If selected account is present in BASE
    if (this.currentQAccountIds.includes(accountId)) {
      //Check if contact is present 
      const baseContact = this.basePositionListTargets.find(plt => plt.contact.id === contactId);
      if (baseContact) {
        //check if selected account is present in base
        const baseAcc = baseContact.contact.accounts.find(acc => acc.id === accountId);
        if (baseAcc) {
          selectedCustomerPositionList.indskr_numberremoved--;
        } else {
          selectedCustomerPositionList.indskr_numberadded++;
        }
      } else {
        selectedCustomerPositionList.indskr_numberadded++;
      }
      selectedCustomerPositionList.indskr_netchange = selectedCustomerPositionList.indskr_numberremoved + selectedCustomerPositionList.indskr_numberadded;
      selectedCustomerPositionList.indskr_percentagechange = Math.round((selectedCustomerPositionList.indskr_netchange / this.basePositionListTargets.length) * 100);
    }
  }

  private updateOnContactRemoval(contact, selectedCustomerPositionList: CustomerPositionList, selectedAccounts) {
    if (_.isEmpty(this.basePositionListTargets)) return;
    const selectedAccountIds = selectedAccounts.map(acc => acc.id);
    //If removed account is present in BASE
    if (_.intersection(this.currentQAccountIds, selectedAccountIds).length > 0) {
      const baseContact = this.basePositionListTargets.find(plt => plt.contact.id === contact.id);
      //If removed contact is present in BASE
      if (baseContact) {
        const diff = _.xor(baseContact.contact.accounts.map(acc => acc.id), selectedAccountIds);
        //If removed contact is present in BASE with same Account
        if (baseContact.contact.accounts.length == selectedAccountIds.length && diff.length == 0) {
          selectedCustomerPositionList.indskr_numberremoved += baseContact.contact.accounts.length;
        } else if (diff.length > 0) {
          //if additional account selected for same contact in BASE
          if (selectedCustomerPositionList.indskr_numberadded > 0) {
            selectedCustomerPositionList.indskr_numberadded -= diff.length;
          }
          selectedCustomerPositionList.indskr_numberremoved += baseContact.contact.accounts.length;
        }
      } else {
        if (selectedCustomerPositionList.indskr_numberadded > 0) {
          selectedCustomerPositionList.indskr_numberadded -= _.intersection(this.currentQAccountIds, selectedAccountIds).length;
        }
      }
    }
    selectedCustomerPositionList.indskr_netchange = selectedCustomerPositionList.indskr_numberremoved + selectedCustomerPositionList.indskr_numberadded;
    selectedCustomerPositionList.indskr_percentagechange = Math.round((selectedCustomerPositionList.indskr_netchange / this.basePositionListTargets.length) * 100);
  }

  private updateOnContactAddition(contact, selectedCustomerPositionList: CustomerPositionList) {
    if (_.isEmpty(this.basePositionListTargets)) return;
    const selectedAccountIds = contact['accounts'].filter(acc => acc['isSelected']).map(acc => acc.id);
    //If selected account is present in BASE
    if (this.currentQAccountIds.includes(selectedAccountIds[0])) {
      const baseContact = this.basePositionListTargets.find(plt => plt.contact.id === contact.id);
      //If selected contact is present in BASE
      if (baseContact) {
        //If selected contact is present in BASE with same Account
        if (baseContact.contact.accounts.length == selectedAccountIds.length && _.xor(baseContact.contact.accounts.map(acc => acc.id), selectedAccountIds).length == 0) {
          if (selectedCustomerPositionList.indskr_numberremoved > 0) {
            selectedCustomerPositionList.indskr_numberremoved -= baseContact.contact.accounts.length;
          }
        } else {
          selectedCustomerPositionList.indskr_numberadded += selectedAccountIds.length;
        }
      } else {
        selectedCustomerPositionList.indskr_numberadded++;
      }
    }
    selectedCustomerPositionList.indskr_netchange = selectedCustomerPositionList.indskr_numberremoved + selectedCustomerPositionList.indskr_numberadded;
    selectedCustomerPositionList.indskr_percentagechange = Math.round((selectedCustomerPositionList.indskr_netchange / this.basePositionListTargets.length) * 100);
  }

  isNextCustomerListPeriodExists() {
    if (!this.currentListPeriod || !this.currentListPeriod.indskr_nextlistperiod) return true;
    if (!this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_NO_WINDOW)) {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const windowOpen = currentDate > new Date(this.currentListPeriod.indskr_nextlistmanagementwindowstartdate).setHours(0, 0, 0, 0) && currentDate < new Date(this.currentListPeriod.indskr_nextlistmanagementwindowenddate).setHours(0, 0, 0, 0);
      if (!windowOpen) return true;
    }
    return this.customerPositionList.getValue().some((customerPositionList) => customerPositionList.listPeriod.indskr_customerlistperiodid === this.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid);
  }

  isCurrentCustomerListPeriodExists() {
    if (!this.currentListPeriod) return true;
    return this.customerPositionList.getValue().some((customerPositionList) => (customerPositionList.statuscode === StatusCode.OPEN || customerPositionList.statuscode === StatusCode.FOR_REVIEW) && customerPositionList.listPeriod.indskr_customerlistperiodid === this.currentListPeriod.indskr_customerlistperiodid);
  }

  createCustomerPositionList() {
    let currentListPeriod = this.currentListPeriod;
    const customerPositionList = new CustomerPositionList({
      createdon: new Date(),
      indskr_type: CustomerPositionListType.CONTACT,
      position: this.authService.user.xPositionID,
      'position@OData.Community.Display.V1.FormattedValue': this.authService.user.positionName,
      listPeriod: this.currentListPeriodCustomerList ? currentListPeriod : currentListPeriod.indskr_nextlistperiod,
    });
    this.selectedCustomerPostionListSubject.next(customerPositionList);
    return customerPositionList;
  }

  clear() {
    this.availableContactsSubject.next([]);
    this.rawCustomerPositionList = null;
    this.basePositionListTargets = [];
    this.currentQAccountIds = [];
    this.currentListPeriodCustomerList = false;
  }

  setCustomerText() {
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        return this.translate.instant('CUSTOMER');
      case 'Stakeholder':
        return this.translate.instant('STAKEHOLDER');
      default:
        return this.utilityService.globalCustomerText;
    }
  }

}
