import { NewActivityComponent } from "./../../activity/new-activity/new-activity";
import {
  FooterService,
  FooterViews
} from "./../../../services/footer/footer.service";
import {
  Component,
  ApplicationRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  KeyValueDiffer,
  KeyValueDiffers,
  ViewChild,
  EventEmitter,
  Output,
  Input
} from "@angular/core";
import {
  PopoverController,
} from "@ionic/angular";
import { Events } from '@omni/events';
import { PopoverComponent } from "../../popover/popover";
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { CallPlanCustDetailsComponent } from "../call-plan-cust-details/call-plan-cust-details";
import { DeviceService } from "../../../services/device/device.service";
import { UIService } from "../../../services/ui/ui.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import {
  NavigationService,
  PageName
} from "../../../services/navigation/navigation.service";
import { RepCallPlanFilterPipe } from "../../../pipes/rep-call-plan-filter.pipe";
import { AuthenticationService } from "../../../services/authentication.service";
import { AccountOfflineService } from "../../../services/account/account.offline.service";
import { LoadingController } from "@ionic/angular";
import {
  InitiateMeetingPayload,
  MeetingDataService
} from "../../../data-services/meeting/meeting.data.service";
import { format, addMinutes } from "date-fns";
import { ActivityService } from "../../../services/activity/activity.service";
import { ActivityDataService } from "../../../data-services/activity/activity.service";
import { ActivitiesDetailsPaneComponent } from "../../activity/activities-details-pane/activities-details-pane";
import { ContactOfflineService } from "../../../services/contact/contact.service";
import { AppointmentActivity, OFFLINE_ID_PREFIX } from "../../../classes/activity/appointment.activity.class";
import { RepServices } from "../../../data-services/rep/rep.services";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { ContentMatchingService } from "../../../services/content-matching/content-matching.service";
import { MultiSelectPopover } from "../../multi-select-popover/multi-select-popover";
import { TranslateService } from "@ngx-translate/core";
import {GlobalUtilityService} from "../../../services/global-utility.service";
import { NotificationService } from "../../../services/notification/notification.service";
import { IndFormFieldViewDataModel } from "../../../models/indFormFieldDataModel";
import { ExtendedDatePipe } from '../../../pipes/extended-date.pipe';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { RepCallPlanStatePipe } from '../../../pipes/rep-call-plan-state.pipe';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DatePipe } from "@angular/common";
import { Utility } from "@omni/utility/util";
import { CustomerCallPlanSuggest } from "@omni/components/customer-call-plans/customer-call-plan-suggest/customer-call-plan-suggest";

@Component({
  selector: "call-plan-details",
  templateUrl: "call-plan-details.html",
  styleUrls:['call-plan-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RepCallPlanStatePipe, ExtendedDatePipe]
})
export class CallPlanDetails {
  public callPlans: any[] = [];
  private recordCount: number = 30;
  private repCallPlanFilterPipe: RepCallPlanFilterPipe;
  private offlineDataDiffers: KeyValueDiffer<string, any>;
  private _callPlanFilterPopover;
  private _callPlanActivityFilterPopover;
  private _orientationChange$;
  private isAutoSubjectEnabled: boolean = false;
  public customerCallplansFilterData: any;
  public listFiltered: boolean = false;
  public customerCallplanStatusFilter: number = 0;
  public customerCallplanFilterString: string = this.translate.instant('ALL_PLANS_WITH_TEXT', {text : this.utilityService.globalCustomerText.toUpperCase()});
  public meetingOrEmailFilter: string;
  public goalCompletionHeaderModel : IndSectionHeaderViewDataModel
  public activityFilterHeaderModel : IndSectionHeaderViewDataModel
  public allCustomerPlansHeaderModel : IndSectionHeaderViewDataModel;

  //@ViewChild(Content, {static:true}) content: Content;
  @Output() closeDetails = new EventEmitter<boolean>(false);

  @Input() from: PageName

  constructor(
    // private datePipe:ExtendedDatePipe,
    private datePipe: DatePipe,
    public repService: RepServices,
    private popoverCtrl: PopoverController,
    public callPlanOfflineService: CallPlanOfflineService,
    private _appRef: ApplicationRef,
    private _cd: ChangeDetectorRef,
    private device: DeviceService,
    private uiService: UIService,
    private callPlanService: CallPlanOfflineService,
    public footerService: FooterService,
    private trackingService: TrackService,
    private navService: NavigationService,
    private authenticationService: AuthenticationService,
    private differs: KeyValueDiffers,
    private loadingCtrl: LoadingController,
    private meetingService: MeetingDataService,
    private activityService: ActivityService,
    private activityDataService: ActivityDataService,
    private events: Events,
    private contactService: ContactOfflineService,
    private notificationService: NotificationService,
    public translate:TranslateService,
    private contentMatchService: ContentMatchingService,
    public utilityService: GlobalUtilityService,
    public accountService: AccountOfflineService,
    private statePipe: RepCallPlanStatePipe,
    private dateTimeFormatsService: DateTimeFormatsService
  ) {
    this.footerService.initButtons(FooterViews.CallPlanDetails);
    this.offlineDataDiffers = this.differs
      .find(this.callPlanOfflineService)
      .create();
    this.repCallPlanFilterPipe = new RepCallPlanFilterPipe(
      callPlanService,
      authenticationService,
      accountService
    );
    this.callPlans = this.repCallPlanFilterPipe
      .transform(callPlanOfflineService.selectedCallPlan.repPlans)
      .reduce((filtered, repCallPlan) => {
        const status = this.getContactStatus(repCallPlan.contactId);

        if (status === 1) {
          filtered.push(repCallPlan);
        } else if (status === 2) {
          repCallPlan['contactInActive'] = true;
          filtered.push(repCallPlan);
        }

        return filtered;
      }, [])
      .slice(0, this.recordCount);
    this.events.unsubscribe('call-plan-details:confirmedNewActivity');
    this.events.subscribe("call-plan-details:confirmedNewActivity", () => {
      this.callPlanService.callPlanPageView = "CallPlanDetail";
      this.uiService.activeView = 'CallPlanDetails'
      this.activityService.selectedActivity = undefined;
      this.uiService.showCancelDoneOnActivityDetails = false;
      this.uiService.showNewActivity = false;
    });
  }

  ngOnInit() {
    //this.content.resize();
    this.events.unsubscribe('activity-scrapped-created-from-call-plan');
    this.events.subscribe('activity-scrapped-created-from-call-plan', () => {
      setTimeout(() => {
        this._cd.detectChanges();
      }, 100);
    });

    this.events.unsubscribe('refresh-callplan-activity');
    this.events.subscribe('refresh-callplan-activity', () => {
      setTimeout(() => {
        this._cd.detectChanges();
      }, 100);
    });

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      this.isAutoSubjectEnabled = true;
    }

    let statusFilterItems = [
      { text: this.translate.instant('ALL'), value: 0 },
      { text: this.translate.instant('OPEN'), value: 1 },
      { text: this.translate.instant('FOR_REVIEW'), value: 100000003 },
      { text: this.translate.instant('APPROVED'), value: 2 },
    ];

    this.customerCallplansFilterData = [
      {
        text: this.translate.instant('STATUS'),
        expanded: true,
        value: "",
        items: statusFilterItems,
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.onfilterChange(item.value)
          this.listFiltered = true;
        }
      }
    ];

    this.customerCallplansFilterData[0].value = 0;
    this.onfilterChange(0);
    this.initializeSectionHeader();
  }

  initializeSectionHeader() {
    this.goalCompletionHeaderModel = {
      id: 'goalCompletion',
      title : `${this.translate.instant('GOAL_COMPLETION')}`,
      controls: []
    }
    this.activityFilterHeaderModel = {
      id: 'activityFilter',
      title : `${this.translate.instant('DETAILS_CAP')}`,
      controls: [
        {
          id: 'customer-scan-sort',
          text: this.meetingOrEmailFilter,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ]
    }
    let allPlanTitleText = `${this.translate.instant("ALL")} ${this.utilityService.globalCustomerText} ${this.translate.instant('PLANS')} (${this.callPlans.length})`;
    if(this.translate.currentLang == 'es') {
      allPlanTitleText = `Clientes (${this.callPlans.length})`;
    }else if(this.translate.currentLang == 'it') {
      allPlanTitleText = `${this.translate.instant("CALL_PLANS")} (${this.callPlans.length})`;
    }
    this.allCustomerPlansHeaderModel = {
      id: 'allCustomerPlans',
      title : allPlanTitleText,
      controls: [
        {
          id: 'customer-scan-sort',
          text: this.customerCallplanFilterString,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ]
    }
  }

  ngDoCheck(): void {
    if (this.callPlanOfflineService.selectedCallPlan) {
    const changes = this.offlineDataDiffers.diff(this.callPlanOfflineService);
    if (changes) {
      this.callPlans = this.repCallPlanFilterPipe
        .transform(
          this.callPlanOfflineService.selectedCallPlan.repPlans
        )
        .reduce((filtered, repCallPlan) => {
          const status = this.getContactStatus(repCallPlan.contactId);

          if (status === 1) {
            filtered.push(repCallPlan);
          } else if (status === 2) {
            repCallPlan['contactInActive'] = true;
            filtered.push(repCallPlan);
          }

          return filtered;
        }, [])
        .slice(0, this.recordCount);
      //this.callPlans = this.callPlanOfflineService.selectedCallPlan.repPlans;
      if(this.callPlanOfflineService.meetingOrEmailFilter) {
        if(this.callPlanOfflineService.meetingOrEmailFilter == 'Engagements') {
          this.meetingOrEmailFilter = this.translate.instant('ENGAGEMENTS')
          if(this.translate.currentLang == 'es' || this.translate.currentLang == 'it') {
            this.meetingOrEmailFilter = this.translate.instant('ENGAGEMENTS_CALL_PLAN');
          }
        }
        else if(this.callPlanOfflineService.meetingOrEmailFilter == 'Messages') {
          this.meetingOrEmailFilter = this.translate.instant('MESSAGES')
          if(this.translate.currentLang == 'es') {
            this.meetingOrEmailFilter = this.translate.instant('MESSAGES_CALL_PLAN');
          }
        }
        this.initializeSectionHeader();
      }
      this._cd.detectChanges();
    }
  }
  }

  ionViewWillEnter() {
    if (!this._orientationChange$) {
      this._orientationChange$ = this.device.screenOrientation.subscribe((chg) => {
        if (this._callPlanFilterPopover) {
          this._callPlanFilterPopover.dismiss();
        }
        if (this._callPlanActivityFilterPopover) {
          this._callPlanActivityFilterPopover.dismiss();
        }
      });
    }
  }

  ionViewWillLeave() {
    if (this._orientationChange$) {
      this._orientationChange$.unsubscribe();
    }
  }

  ionViewDidEnter(){
    if (this.callPlanService.callPlanPageView) {
      if (this.callPlanService.callPlanPageView !== 'CallPlanNewMeetingDetails') {
        this.footerService.initButtons(FooterViews.CallPlanDetails);
        this.customerCallplansFilterData[0].value = 0;
        this.onfilterChange(0);
      }
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('activity-scrapped-created-from-call-plan');
    this.events.unsubscribe('refresh-callplan-activity');
  }

  closepage() {

    if(this.navService.getActiveChildNavViewPageName() == PageName.SchedulerDetailComponent) {
      //used for closing details view via scheduler details selection flow
      try {
        this.closeDetails.emit(true);
        if (this.device.isMobileDevice && this.device.deviceOrientation === 'PORTRAIT') {
          this.navService.popWithPageTracking().then(() => {
            this.callPlanOfflineService.selectedCallPlan = undefined;
          });
        }
        else
        this.callPlanOfflineService.selectedCallPlan = undefined;
      }
      catch (error) {
        console.log(error);
      }
    } else if (this.uiService.activeView ==='ActivitiesPageRightPaneNav' && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
        this.navService.popToRootWithPageTracking();
        this.uiService.showRightPane = false;
        this.callPlanOfflineService.selectedCallPlan = undefined;
    } else {
    this.callPlanOfflineService.selectedCallPlan = undefined;
    }
  }

  openCallPlanCustomerDetails(repCallPlan: any) {
    if (this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) {
      let plan = repCallPlan;
      plan.actualCalls = this.goalOrCompletedValue(plan.indskr_hocalls, plan.indskr_actualcalls);
    plan.totalGoalCalls = plan.indskr_hocalls ? plan.indskr_hocalls : 0;
    plan.actualEmails = this.goalOrCompletedValue(plan.indskr_hoemails, plan.indskr_actualemails);
    plan.totalGoalEmails = plan.indskr_hoemails ? plan.indskr_hoemails : 0;
    plan.actualF2FMeetings = this.goalOrCompletedValue(plan.indskr_f2fgoals, plan.totalf2fmeetingscompleted);
    plan.totalGoalF2FMeetings = plan.indskr_f2fgoals ? plan.indskr_f2fgoals : 0;
    plan.actualRemoteMeetings = this.goalOrCompletedValue(plan.indskr_remotemeetinggoals, plan.totalremotemeetingscompleted);
    plan.totalGoalRemoteMeetings = plan.indskr_remotemeetinggoals ? plan.indskr_remotemeetinggoals : 0;
    plan.actualPhoneCalls = this.goalOrCompletedValue(plan.indskr_phonecallgoals, plan.totalphonecallscompleted);
    plan.totalGoalPhoneCalls = plan.indskr_phonecallgoals ? plan.indskr_phonecallgoals : 0;
    this.callPlanOfflineService.selectedRepCallPlan = plan;
    this.navService.pushChildNavPageWithPageTracking(CustomerCallPlanSuggest, PageName.CustomerCallPlanSuggest, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails });
    } else {
      this.callPlanOfflineService.selectedRepCallPlan = repCallPlan;
      this.navService.pushChildNavPageWithPageTracking(CallPlanCustDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails });
    }



    // this.callPlanOfflineService.selectedRepCallPlan = repCallPlan;
    // this.callPlanOfflineService.callPlanPageView = "CallPlanCustomerDetail";
    // this.navService.pushChildNavPageWithPageTracking(CallPlanCustDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails });
    // if (
    //   this.device.isMobileDevice &&
    //   this.device.deviceOrientation === "PORTRAIT"
    // ) {
    //   this.navService.pushWithPageTracking(
    //     CallPlanCustDetailsComponent,
    //     PageName.CallPlanCustDetailsComponent
    //   );
    // }
    let insightsEventName =
      this.callPlanOfflineService.getCallPlanMode == "mine"
        ? "MyCallPlanRepCallPlanDetails"
        : "TeamCallPlanRepCallPlanDetails";
    this.trackingService.tracking(insightsEventName, TrackingEventNames.CALLPLAN);
  }

  private goalOrCompletedValue(goal, completed) {
    let value = 0;
    if (completed) {
      value = completed > goal ? goal : completed
    }
    return value;
  }

  openCllPlanFilterPopup(myEvent) {
    // this._callPlanFilterPopover = this.popoverCtrl.create(
    //   PopoverComponent,
    //   { field: "callPlanFilter" },
    //   { cssClass: "callplan-filter-popover" }
    // );
    // this._callPlanFilterPopover.present({
    //   ev: myEvent
    // });
    // this._callPlanFilterPopover.onDidDismiss(() => {
    //   this._callPlanFilterPopover = null;
    // });
    this.popoverCtrl
      .create({component: MultiSelectPopover,
              componentProps: { root: this.customerCallplansFilterData },
              event:event})
      .then((data)=>{
        data.present();
      })
  }

  openCllPlanActivityFilterPopup(myEvent) {
    // this._callPlanActivityFilterPopover = this.popoverCtrl.create({
    //   component: PopoverComponent,
    //   componentProps:{ field: "callPlansActivityFilter" },
    //   cssClass: "callplan-activity-filter-popover",
    //   event: myEvent }
    // );
    // this._callPlanActivityFilterPopover.present();

    // this._callPlanActivityFilterPopover.onDidDismiss().then(() => {
    //   this._appRef.tick();
    //   this._cd.markForCheck();
    //   this._callPlanActivityFilterPopover = null;
    // });

    this.popoverCtrl
    .create({component: PopoverComponent,
            componentProps: { field: "callPlansActivityFilter"  },
            event:event})
    .then((data)=>{
      data.present();
    })

  }

  /**
   * This creates a new meeting, opens the meetings details with a custom parameter to indicate to show the cancel or done btns
   *
   * @private
   * @param {*} repCallPlan
   * @memberof CallPlanDetails
   */
  public async createNewActivityForRepCallPlan(repCallPlan, event) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) || this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      this.openNewActivityComponent(repCallPlan, event)
    } else {
      event.stopPropagation();
      // event.stopImmediatePropagation();
      this.trackingService.tracking(' MyCallPlan+Clicked', TrackingEventNames.CALLPLAN)
      this.callPlanOfflineService.selectedRepCallPlan = repCallPlan;

      //We don't show the new activity screen, we just insta create it.

      //Start a loader while we wait for new meeting
      let loader = await this.loadingCtrl.create();
      loader.present();

      //Going to need a payload for initiation
      let startTime, endTime;
      startTime = new Date();
      endTime = new Date();
      endTime = addMinutes(startTime, 30);

      //Add contact to new appointment activity
      let contact = this.contactService.getContactByID(
        this.callPlanOfflineService.selectedRepCallPlan["contactId"]
      );

      let subject: string = (contact && contact.fullName) ? `${contact.fullName} - ` + this.translate.instant('MEETING') : this.translate.instant('MEETING_CREATED_FROM_CALL_PLAN');
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
        subject = (contact && contact.fullName) ? this.translate.instant('VISIT') + ` - ${contact.fullName}` : this.translate.instant('VISIT')
      }
      let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
        subject,
        "",
        startTime.getTime(),
        endTime.getTime(),
        "",
        OFFLINE_ID_PREFIX + new Date().getTime()
      );

      let response = await this.meetingService.createNewMeeting(payload, true);
      this.activityService.selectedActivity = response;

      //Add contact to new appointment activity
      // let contact = this.contactService.getContactByID(
      //   this.callPlanOfflineService.selectedRepCallPlan["customerID"]
      // );
      if (contact) {
        if (
          this.activityService.selectedActivity instanceof AppointmentActivity
        ) {
          this.activityService.selectedActivity.contacts.push(contact);

          const requestSuccess = await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity);
          this.contentMatchService.isNewContactAddedToMeeting = true;
          // TODO: Only hybrid app does offline stuff in future
          if (!requestSuccess/* && this.device.isHybridApp*/) {
            await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
          }
        }
      } else {
        this.notificationService.notify(this.translate.instant('ERROR_FINDING_CONTACT_TO_ADD_TO_MEETING'), 'Call Plan Detail');
      }
      loader.dismiss();

      //We've made an activity, we just need to display the right hand details
      this.uiService.activeView = "Appointment";

      //This hides our right hand view

      // if (
      //   this.device.isMobileDevice &&
      //   this.device.deviceOrientation === "PORTRAIT"
      // ) {
      //   this.uiService.prevView = 'callPlanDetails';
      //   this.navService.pushWithPageTracking(
      //     ActivitiesDetailsPaneComponent,
      //     PageName.ActivitiesDetailsPaneComponent
      //   ).then(async () => {
      //     await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);
      //     this.activityDataService.activityDetailsLoaded = true;
      //     this.callPlanService.callPlanPageView = "CallPlanNewMeetingDetails";
      //   });
      // } else {
      await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);
      this.uiService.prevView = 'callPlanDetails';
      this.uiService.activeView = 'activityTimelineDetails';
      this.callPlanService.callPlanPageView = "CallPlanNewMeetingDetails";
      this.footerService.initButtons(FooterViews.Activities);
      this.activityDataService.activityDetailsLoaded = true;
      //}
      this.uiService.showCancelDoneOnActivityDetails = true;
    }
  }

  openNewActivityComponent(repCallPlan, event) {
    this.callPlanOfflineService.selectedRepCallPlan = repCallPlan;
    let contact = this.contactService.getContactByID(this.callPlanOfflineService.selectedRepCallPlan['contactId']);
    this.contactService.contactInformation = contact;
    this.uiService.showNewActivity = true;
    // this.callPlanService.callPlanPageView = "NEW_ACTIVITY_SELECTION";
    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent, { from: PageName.CallPlanDetails });
    } else {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails });
    }
    event.stopPropagation();
  }

  isSplitView() {
    return (window.innerWidth > 500);
    // return (
    //   //this.navService.getCurrentPageName() === PageName.CallPlanDetails &&
    //   this.device.isMobileDevice &&
    //   this.device.deviceOrientation === "PORTRAIT"
    // );
  }

  doInfinite(eventDetail,event) {
    if (this.callPlanOfflineService.selectedCallPlan) {
      if (this.callPlanOfflineService.selectedCallPlan.repPlans.length == this.callPlans.length) return;
    }
    let callPlans = this.repCallPlanFilterPipe.transform(
      this.callPlanOfflineService.selectedCallPlan.repPlans
    )
      .reduce((filtered, repCallPlan) => {
        const status = this.getContactStatus(repCallPlan.contactId);

        if (status === 1) {
          filtered.push(repCallPlan);
        } else if (status === 2) {
          repCallPlan['contactInActive'] = true;
          filtered.push(repCallPlan);
        }

        return filtered;
      }, []);

    if (this.callPlans.length < callPlans.length + 30) {
      this.callPlans = callPlans.slice(0, this.recordCount + 30);
    } else {
      this.callPlans = callPlans.slice(0);
    }
    this.recordCount = this.callPlans.length;
    event.target.complete();
  }

  getContactStatus(contactId) {
    const idx = this.contactService.contacts.findIndex(c => c.ID === contactId);

    if (idx >= 0) {
      return this.contactService.contacts[idx].isActive ? 1 : 2;
    } else {
      return 0;
    }
  }

  public onfilterChange(status_code: number): void {
    this.customerCallplanStatusFilter = status_code;
    this.callPlanService.resetAllRepCallPlanFilters();
    switch (status_code) {
      case 1:
        this.customerCallplanFilterString = this.translate.instant('OPEN');
        this.callPlanService.repCallPlanDetailStatusFilterOpen = true;
        break;
      case 2:
        this.customerCallplanFilterString = this.translate.instant('APPROVED');
        this.callPlanService.repCallPlanDetailStatusFilterApproved = true;
        break;
      case 100000003:
        this.customerCallplanFilterString = this.translate.instant('FOR_REVIEW');
        this.callPlanService.repCallPlanDetailStatusFilterForReview = true;
        break;
      case 0:
        this.customerCallplanFilterString = this.translate.instant('STATUS');
        break;
    }
  }

  public getProductFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT'),
      inputText: (this.callPlanOfflineService.selectedCallPlan && this.callPlanOfflineService.selectedCallPlan.productName)?this.callPlanOfflineService.selectedCallPlan.productName:this.translate.instant('NO_PRODUCT'),
      id:'order-number-field',
      isReadOnly:true,
      isDisabled:true,
      isHidden: !(this.callPlanOfflineService.selectedCallPlan && this.callPlanOfflineService.selectedCallPlan.productName)
    };
    return viewData;
  }

  private get _startPeriod() {
    return this._getFormattedAndLocalisedDate(Utility.changeUTCDateToLocalDateWith0Time(new Date(this.callPlanOfflineService.selectedCallPlan.indskr_startdate).getTime()));
  }

  private get _endPeriod() {
    return this._getFormattedAndLocalisedDate(Utility.changeUTCDateToLocalDateWith0Time(new Date(this.callPlanOfflineService.selectedCallPlan.indskr_enddate).getTime(),true));
  }

  private _getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  public getStartPeriodFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('START_PERIOD'),
      inputText: (this.callPlanOfflineService.selectedCallPlan && this.callPlanOfflineService.selectedCallPlan.indskr_startdate)?this._startPeriod:'',
      id:'callplan-start-period-field',
      placeholderLabel: '-',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }
  public getEndPeriodFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('END_PERIOD'),
      inputText: (this.callPlanOfflineService.selectedCallPlan && this.callPlanOfflineService.selectedCallPlan.indskr_enddate)?this._endPeriod:'',
      id:'callplan-end-period-field',
      placeholderLabel: '-',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getAllCustomerPlans(repCallPlan): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    viewData={
      id: repCallPlan['indskr_customercallplanid'],
      primaryTextRight: repCallPlan.contactFirstName + ' ' + repCallPlan.contactLastName,
      primaryTextLeft: this.callPlanOfflineService?.meetingOrEmailFilter == 'Engagements' ? (repCallPlan.indskr_meetingcompletionpercent_Formatted || 0)+'%' : (repCallPlan.indskr_emailcompletionpercent_Formatted || 0)+'%',
      isPrimaryTextLeftPillType: true,
      primaryTextLeftTwo: this.statePipe.transform(repCallPlan.statuscode),
      showEndIcon: !this.callPlanOfflineService.selectedCallPlan.isTeamCallPlan && this.navService.getActiveChildNavViewPageName() !== PageName.SchedulerDetailComponent && !repCallPlan['contactInActive'] ? true : false,
      endIconCssClass: 'pluse-icon',
      tooltip: 'New Activity',
      clickHandler: (id: string, event, specificTarget) => {
        if (specificTarget && specificTarget == 'endIcon') {
          this.createNewActivityForRepCallPlan(repCallPlan, event)
        } else if (this.navService.getActiveChildNavViewPageName() !== PageName.SchedulerDetailComponent && (this.callPlanOfflineService.selectedCallPlan.suggestionApprovalneeded == undefined || this.callPlanOfflineService.selectedCallPlan.suggestionApprovalneeded == null) || this.callPlanOfflineService.selectedCallPlan.suggestionApprovalneeded) {

          this.openCallPlanCustomerDetails(repCallPlan);
        }
      },
    }
    return viewData;
  }

}
