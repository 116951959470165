import { Activity } from './activity.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { addMinutes } from 'date-fns';
import { TrackAction } from '../../utility/common-enums';
import { Utility } from '../../utility/util';

export class OrderActivity extends Activity {

    public offlineId : string;
    public name : string;
    public ownerNameString : string;
    public orderNumber : string;
    public countryId : string;
    public countryNameString : string;
    public accountId : string;
    public accountNameString : string;
    public quoteNumberString : string;
    public quoteNumberId : string;
    public elligibleDiscount : number;
    public formattedDiscount : string;
    public total : number;
    public formattedTotalAmount: string;
    public createdDate : Date;
    public pendingPushToDynamics : boolean;
    public products : Array<OrderProductClass> = [];
    public isTeamOrder:boolean = false;
    public priceListId:string = '';
    public currencySymbol:string = '';
    public currencyId: string;
    public isHardDeleted:boolean = false;
    public opportunityId: string;
    public opportunityNameString: string;
    public offlineOpportunityId: string;
    public accountPlanId: string;
    public accountPlanNameString: string;
    public orderType: number;
    public OrderTypeFormatted: OrderTypes;
    public manualOrder: boolean = false;
    public surveyResponse:any;
    public surveyResponseData:any;
    public surveyResponseLookupData:any;
    public surveyResponseAdditionalData:any;
    public surveyResponseDTO:any = {};
    public approvalActivityId:string;

    constructor(raw: any) {
        super(raw);
        if(!this.ID){
            this.ID = raw['salesorderid'];
            if(!this.ID){
                this.ID = raw['indskr_externalid'];
            }
        }
        this.offlineId = (raw['indskr_externalid']) ? raw['indskr_externalid'] : '';
        this.ownerNameString = (raw['ownerName']) ? raw['ownerName'] : '';
        if(raw['statuscode']){
            this.status = parseInt(raw['statuscode']);
        }
        this.name = (raw['name']) ? raw['name'] : '';
        this.orderNumber = (raw['ordernumber']) ? raw['ordernumber'] : '';
        this.subject = (raw['ordernumber']) ? raw['ordernumber'] : '';
        this.countryId = (raw['indskr_country']) ? raw['indskr_country'] : '';
        this.countryNameString = (raw['countryName']) ? raw['countryName'] : '';
        this.accountId = (raw['customerid']) ? raw['customerid'] : '';
        this.accountNameString = (raw['customerName']) ? raw['customerName'] : '';
        this.quoteNumberString = (raw['quoteName']) ? raw['quoteName'] : '';
        this.quoteNumberId = (raw['quoteid']) ? raw['quoteid'] : '';
        this.elligibleDiscount = (raw['discountpercentage']) ? parseInt(raw['discountpercentage']) : 0;
        this.formattedDiscount = (raw['discountpercentage']) ? parseInt(raw['discountpercentage'])+"%" : "0%";
        this.total = (raw['totalamount']) ? parseFloat(raw['totalamount']) : 0;
        this.formattedTotalAmount = (raw['formattedTotalAmount']) ? raw['formattedTotalAmount'] : '0';
        this.createdDate = (raw['createdon']) ? new Date(parseInt(raw['createdon'])) : null;
        if(raw['createdon'] && !raw['scheduledstart']){
            // Manually create the schedule start and scheduled end from createdon field untill MSE introduces these fields in st.
            let roundedDownTimeForAgenda = Utility.getRoundedDownTime(new Date(parseInt(raw['createdon'])));
            this.scheduledStart = roundedDownTimeForAgenda;
            this.scheduledEnd = addMinutes(roundedDownTimeForAgenda,30);
            this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});
        }
        this.priceListId = (raw['pricelevelid']) ? raw['pricelevelid'] : '';
        this.currencySymbol = raw['currencysymbol'] ? raw['currencysymbol'] : '';
        this.currencyId = raw['transactioncurrencyid'] || ''
        this.isHardDeleted = (raw['track_action'] && raw['track_action'] == TrackAction.Deleted) ? true : (raw['isHardDeleted'] ? raw['isHardDeleted'] : false) ;
        this.pendingPushToDynamics = (raw['pendingPushToDynamics']) ? raw['pendingPushToDynamics'] : null;
        if(raw['products'] && raw['products'].length > 0){
            this.products = [];
            raw['products'].forEach(product => {
                this.products.push(new OrderProductClass({
                  productId: product['productid'],
                  productName: (product['productName']) ? product['productName'] : '',
                  total: (product.extendedamount) ? product.extendedamount : 0,
                  formattedTotalAmount: (product['formattedExtendedamount'])? product['formattedExtendedamount'] : '',
                  unitPrice: (product.priceperunit) ? product.priceperunit : 0,
                  quantity: (product.quantity) ? product.quantity : 0,
                  uomid: (product['uomid']) ? product['uomid'] : '',
                  isDeleted: (product['isDeleted']) ? product['isDeleted'] : false,
                }));
            });
        };
        this.opportunityId = raw['opportunityid'] || '';
        this.opportunityNameString = raw['opportunityName'] || '';
        this.offlineOpportunityId = this.opportunityId.indexOf('offline')>-1?this.opportunityId:'';
        this.accountPlanId = raw['accountplanid']|| raw['accountPlanId'] || '';
        this.accountPlanNameString = raw['accountplanName'] || raw['accountPlanName'] || '';
        this.orderType = 548910000;
        this.OrderTypeFormatted = OrderTypes.Sales;
        this.surveyResponse = raw['surveyResponse'] || '';
        this.surveyResponseData = raw['surveyResponseData'] || '';
        this.surveyResponseLookupData = raw['surveyResponseLookupData'] || [];
        this.surveyResponseAdditionalData = raw['surveyResponseAdditionalData'] || {};
        this.approvalActivityId = raw['approvalActivityId'];
    }

    public get offlineDBId():string {
        if(this.offlineId){
            return DB_KEY_PREFIXES.ORDER_ACTIVITY + this.offlineId;
        }else{
            return DB_KEY_PREFIXES.ORDER_ACTIVITY + this.ID;
        }
    }

    public get orderStatusString():string {
        let str:string = '';
        switch(this.status){
            case 4:
                str = 'Canceled';
                break;
            case 548910001:
                str = 'Approved';
                break;
            case 548910000:
                str = 'For Review';
                break;
            case 100001:
                str = 'Fulfilled';
                break;
            case 548910003:
                str = 'Rejected';
                break;
            default:
                str = 'Draft';
        }
        return str;
    }

    public get serviceDTO():any{
        let payloadProducts = [];
        this.products.map(product => {
            if(!product.isDeleted){
                payloadProducts.push({
                    productid: product.productId,
                    quantity: product.quantity,
                    uomid: product.uomid,
                });
            }
        });
        let payload = {
            indskr_externalid: this.offlineId  ? this.offlineId : '',
            customerid_account: (this.accountId) ? this.accountId : '',
            ownerid: this.ownerId,
            discountpercentage: this.elligibleDiscount,
            indskr_Country: this.countryId ? this.countryId : '',
            createdon: this.createdDate.getTime()+'',
            //quoteid: this.quoteNumberId,
            totalamount: this.total,
            pricelevelid: this.priceListId  ? this.priceListId : '',
            products: payloadProducts,
            opportunityid: this.opportunityId.indexOf('offline')>-1?"indskr_externalid='"+this.opportunityId+"',indskr_externalsource='"+this.opportunityId+"'":this.opportunityId,
            accountplanid : this.accountPlanId,
            indskr_ordertype: this.orderType

        };
        if (this.manualOrder) payload['freightamount'] = this.total;
        if(!this.ID.includes('offline')){
            payload['salesorderid'] = this.ID;
        }
        if((this.state == 2 && this.status == 4) || (this.state == 1 && this.status == 548910000)){
            payload['statecode'] = this.state+'';
            payload['statuscode'] = this.status+'';
        }
        if(this.surveyResponseDTO){
          payload['surveyResponses'] = this.surveyResponseDTO;
        }
        return payload;
    }

    public get statusUpdateDTO():any {
        if(!this.ID) return {};
        return {
            salesorderid: this.ID,
            statecode: this.state+'',
            statuscode:this.status+'',
        };
    }

    public get offlineDataDTO():any{
        let payload = {
            indskr_externalid : this.offlineId,
            salesorderid: this.ID,
            ownerName : this.ownerNameString,
            ordernumber : this.orderNumber,
            indskr_country : this.countryId,
            countryName : this.countryNameString,
            customerid : this.accountId,
            customerName : this.accountNameString,
            quoteName : this.quoteNumberString,
            name: this.name,
            quoteid : this.quoteNumberId,
            discountpercentage : this.elligibleDiscount,
            totalamount : this.total,
            formattedTotalAmount: this.formattedTotalAmount,
            pendingPushToDynamics : this.pendingPushToDynamics,
            isHardDeleted: this.isHardDeleted,
            statuscode : this.status,
            statecode : this.state ,
            ownerid : this.ownerId,
            activitytypecode : this.type,
            createdon : this.createdDate ? this.createdDate.getTime()+'' : '',
            subject: this.subject,
            scheduledstart: this.scheduledStart.getTime()+'',
            scheduledend: this.scheduledEnd.getTime()+'',
            pricelevelid:this.priceListId,
            currencysymbol: this.currencySymbol,
            transactioncurrencyid: this.currencyId,
            opportunityid: this.opportunityId,
            opportunityName : this.opportunityNameString,
            offlineOpportunityId: this.offlineOpportunityId,
            accountplanid: this.accountPlanId,
            accountplanName: this.accountPlanNameString,
            products: this.products.map(product => {
                return {
                    productid: product.productId,
                    productName: product.productName,
                    extendedamount: product.total,
                    formattedExtendedamount: product.formattedTotal,
                    priceperunit: product.unitPrice,
                    quantity: product.quantity,
                    isDeleted: product.isDeleted,
                    uomid: product.uomid,
                }
            })
        }
      if (this.manualOrder) payload['freightamount'] = this.total;
      return payload;
    }

}

export class OrderProductClass {

    public productId : string;
    public productName : string;
    public total?: number;
    public formattedTotal?: string;
    public unitPrice?: number;
    public formattedUnitPrice?: string;
    public quantity?:number;
    public uomid?: string;
    public isDeleted?: boolean = false;
    public parentProductID?: string;
    public productCategoryID?: string;
    public productCategoryName?: string;
    public isEmpty?: boolean = false;
    constructor(raw:any){
        this.productId = (raw['productId']) ? raw['productId'] : '';
        this.productName = (raw['productName']) ? raw['productName'] : '';
        this.total = (raw['total']) ? raw['total'] : '';
        this.formattedTotal = (raw['formattedTotalAmount']) ? raw['formattedTotalAmount'] : "";
        this.unitPrice = (raw['unitPrice']) ? raw['unitPrice'] : '';
        this.formattedUnitPrice = (raw['formattedUnitPrice']) ? raw['formattedUnitPrice'] : "";
        this.quantity = (raw['quantity']) ? parseInt(raw['quantity']) : 0;
        this.isDeleted = raw['isDeleted']? raw['isDeleted'] : false;
        this.uomid = raw['uomid'] || '';
        this.parentProductID = raw['parentProductID'] || '';
        this.productCategoryID = raw['productCategoryId'] || '';
        this.productCategoryName = raw['productCategoryName'] || '';
    }
}

export enum OrderTypes{
  Sales = 'sales',
  Surgery = 'surgery'
}

