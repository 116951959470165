import { Component, ViewChild } from "@angular/core";
import { IonNav } from "@ionic/angular";
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { DeviceService } from '../../../services/device/device.service';
import { PresentationService } from "@omni/services/presentation/presentation.service";
import { MeetingStructureService } from "@omni/services/meeting-structure/meeting-structure.service";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";

@Component({
  selector: 'survey-page[base-page]',
  templateUrl: 'survey-page.html',
  styleUrls:['survey-page.scss']
})
export class SurveyPageComponent {

  @ViewChild('surveyrightpane', { static: true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    private footerService: FooterService,
    private deviceService: DeviceService,
    private presentationService: PresentationService,
    private meetingStructureService: MeetingStructureService,
    private customerSurveyService: CustomerSurveyService,
  ) { }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.SurveyNavigation, PageName.SurveyComponent, false, {
      'deviceString': this.deviceService.deviceFlags.ios ? 'ios' : 'other'
    });
    this.footerService.initButtons('');
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.SurveyNavigation);
    if (this.navService.getActiveChildNavViewPageName() === PageName.NewActivityComponent) {
      this.navService.popChildNavPageWithPageTracking();
    }
    if (this.navService.getCurrentMasterPageName() === PageName.PresentationMeetingComponent) {
      this.presentationService.showNewActivity = false;
      this.meetingStructureService.setCurrentEmbeddedActivityOnPresentation(this.customerSurveyService.inMeetingSelectedSurveyId);
      this.customerSurveyService.inMeetingCustomerSurvey = true;
      this.footerService.initButtons(FooterViews.PreviewMeeting);
    }
  }


}