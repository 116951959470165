import { BehaviorSubject, Observable } from 'rxjs';
import { fetchQueries } from './../../config/dynamics-fetchQueries';
import { Injectable } from "@angular/core";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME, OFFLINE_DB_LINKED_ENTITY_NAME } from "../disk/disk.service";
import { differenceInHours } from "date-fns";
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { AuthenticationService } from '../authentication.service';
import { Endpoints } from 'src/config/endpoints.config';
import { HttpClient } from '@angular/common/http';
import { Building } from '../../interfaces/shared/shared.interface';


@Injectable({
  providedIn: 'root'
})

export class AddressService {
  private buildings$: BehaviorSubject<Building[]> = new BehaviorSubject([]);
  public preferredAdress = [];
  public readonly buildingsObservable: Observable<Building[]> = this.buildings$.asObservable();

  constructor(
    private diskService: DiskService,
    private dynamics: DynamicsClientService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
  ) {}

  public async fetchPreferredAddress(isFullSync, loadFromDBOnly = false) {

    let offlineDocs;
    let lastModifiedForDeltaSync, hourDifference;
    let now = new Date();

    // offline db add
    await this.diskService.retrieve(DB_KEY_PREFIXES.PREFERRED_ADDRESS, true).then((doc)=>{
      offlineDocs = doc
        if(doc && doc.raw){
          this.preferredAdress = doc.raw
        }
        else {
          this.preferredAdress = [];
          isFullSync = true;
        }
      })

    if(!loadFromDBOnly) {
      let fetchXML = fetchQueries.address.preferredAddress;
      fetchXML = fetchXML.replace("{PositionFilter}", this._getPositionFilter());
      if(isFullSync) {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      else {
        let deltaSyncFilter = `<condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />`
        lastModifiedForDeltaSync = offlineDocs ? offlineDocs.lastDeltaSync : null;
        if(lastModifiedForDeltaSync){
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
          deltaSyncFilter = deltaSyncFilter.replace('{lastXHours}', hourDifference);
        }
        else deltaSyncFilter = ''
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', deltaSyncFilter);
      }

      try {
        let response = await this.dynamics.executeFetchQuery('indskr_positionpreferredaddresses', fetchXML);
        if(response){
          await this.mapPreferredAddress(response);          
          this.diskService.updateOrInsert(DB_KEY_PREFIXES.PREFERRED_ADDRESS, (doc)=>{
            doc = {
              raw: this.preferredAdress,
              lastDeltaSync: new Date().getTime()
            };
            return doc;
          })
        }
      } catch(error) {
        console.log("Error fetching form metadata: ", error)
      }
    }
  }

  private mapPreferredAddress(response) {
    response.map((rawData) => {
      let address;
      let addressId = rawData['indskr_positionpreferredaddressid'];
      if(addressId) {
        let existingAddress = this.preferredAdress.find(a => a['preferredAddressId'] == addressId);
        address= {
          preferredAddressId : rawData['indskr_positionpreferredaddressid'],
          customerAddressId : rawData['_indskr_customeraddress_value'],
          positionId: rawData['_indskr_position_value'],
          isDeleted: false,
          pendingPushOnDynamics: false
        }
        if(!existingAddress) {
          this.preferredAdress.push(address);
        } else {
          let index = this.preferredAdress.findIndex(add => add.preferredAddressId === addressId);
          this.preferredAdress[index] = address;
        }
      }
    })
  }

  private _getPositionFilter() {
    let positionIds = this.authenticationService.user.positions.map(o => {
      return o.ID
    });
    let childPositionIds = this.authenticationService.user.childPositions.map(o => {
      return o.childPositionId
    });
    if(!positionIds) return "";
    let positionString = '';
    positionIds.forEach(p => positionString += '<value>' + p + '</value>');
    if(childPositionIds) childPositionIds.forEach(cp => positionString += '<value>' + cp + '</value>');
    return positionString;
  }

  public updatePreferredAddressInOfflineDB(payload):Promise<void> {
    return new Promise(async (resolve,reject)=> {
      let offlinePreferredAdd = await this.diskService.retrieve(DB_KEY_PREFIXES.PREFERRED_ADDRESS);
      let data:Array<any> = [];
      if(offlinePreferredAdd && offlinePreferredAdd.raw){
          data = offlinePreferredAdd.raw;
          if (payload && payload.length>0) {
            payload.forEach(pl => {
              let idx = data.findIndex(a => 
                a && a.hasOwnProperty('customerAddressId') && a.customerAddressId == pl.customerAddressId
                && a.hasOwnProperty('positionId') && a.positionId == pl.positionId);
              if (idx >= 0) {
                  data[idx].isDeleted = pl.isDeleted;
                  data[idx].pendingPushOnDynamics = pl.pendingPushOnDynamics;
              } else {
                  if(pl.isDeleted) {
                    pl.pendingPushOnDynamics = false;
                  } else {
                    data.push(pl);
                  }
              }
            })
          }
          offlinePreferredAdd.raw = data;
          offlinePreferredAdd.count = data.length;
      }else{
          data.push(payload);
          offlinePreferredAdd = {
              raw: data,
              count: data.length,
          }
      }
      this.diskService.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PREFERRED_ADDRESS, offlinePreferredAdd.count);
      await this.diskService.updateOrInsert(DB_KEY_PREFIXES.PREFERRED_ADDRESS, doc => {
          doc = offlinePreferredAdd;
          console.log("Preferred Address is updated in local data");
          return doc;
      }).catch(error => console.error('Save Customer Notes to DB error: ', error));
      this.preferredAdress = offlinePreferredAdd.raw;
      resolve();
    });
  }

  public uploadPreferredAddressOnline():Promise<void> {
    return new Promise(async (resolve,reject)=> {
      let offlinePreferredAdd = await this.diskService.retrieve(DB_KEY_PREFIXES.PREFERRED_ADDRESS);
      if(offlinePreferredAdd && offlinePreferredAdd.raw && Array.isArray(offlinePreferredAdd.raw) && offlinePreferredAdd.raw.length != 0){
          const payload = offlinePreferredAdd.raw.filter(item => item.pendingPushOnDynamics).map(item=>{
            if(item.isDeleted) return {
              preferredAddressId: item.preferredAddressId,
              customerAddressId: item.customerAddressId,
              positionId: item.positionId,
              isDeleted: item.isDeleted
            };
            else return {
              customerAddressId: item.customerAddressId,
              positionId: item.positionId,
              isDeleted: item.isDeleted
            }
          });
          if(payload && payload.length>0) {
            let headers = Endpoints.headers.content_type.json;
            let url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.UPLOAD_PREFERRED_ADDRESS;
            let response = await this.http.put(url, payload,headers).toPromise();
            if(response && Array.isArray(response) && response.length != 0){
              response.forEach((add,idx) => {
                if(add.hasOwnProperty('customerAddressId') && add.hasOwnProperty('positionId')) {
                  let dbIdx = offlinePreferredAdd.raw.findIndex(item => item['customerAddressId'] == add['customerAddressId'] && item['positionId'] == add['positionId'])
                  if(dbIdx>0) {
                    if(add.isDeleted) {
                      offlinePreferredAdd.raw.splice(dbIdx, 1);
                    }
                    else {
                      offlinePreferredAdd.raw[dbIdx].pendingPushOnDynamics = false;
                      if(add['preferredAddressId']) offlinePreferredAdd.raw[dbIdx]['preferredAddressId'] = add['preferredAddressId'];  
                    }
                  }
                }
              })
              let offlineCount = offlinePreferredAdd.raw.filter(item => item.pendingPushOnDynamics).length;
              this.diskService.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PREFERRED_ADDRESS, offlineCount);
              await this.diskService.updateOrInsert(DB_KEY_PREFIXES.PREFERRED_ADDRESS, doc => {
                doc = offlinePreferredAdd;
                console.log("Preferred Address is updated in local data based on response from service");
                return doc;
              }).catch(error => console.error('Save Preferred Address to DB error: ', error));
            }
            this.preferredAdress = offlinePreferredAdd.raw;
          }  
      }  
      resolve();    
    });
  }

    public async markFavouriteAddress(addressList) {
      await this.diskService.retrieve(DB_KEY_PREFIXES.PREFERRED_ADDRESS).then((doc) => {
        doc.raw.forEach(p => {
          addressList.forEach((data) => {
            if(data['customerAddressID'] && data['customerAddressID'] == p['customerAddressId']) data['isFavourite'] = true;
          });
        });
        return addressList;
      });
    }

    private setBuildings(buildings: Building[]) {
      this.buildings$.next(buildings);
    }
    getBuildings(): Building[] {
      const buildings = this.buildings$?.getValue();
      return buildings ?? [];
    }
    getBuildingById(id: string): Building {
      const buildings = this.buildings$?.getValue();
      let building: Building;
      try {
        building = buildings.find(b => b.msevtmgt_buildingid === id);
      } catch (error) {
        console.error('getBuildingById: ', error, id);
      }
      return building;
    }

    async loadOfflineBuildings() {
      try {
        const dbDoc = await this.diskService.retrieve(DB_KEY_PREFIXES.ADDRESS_BUILDINGS, true);

        if (Array.isArray(dbDoc?.raw)) {
          this.setBuildings(dbDoc.raw);
        }
      } catch (error) {
        console.error('loadOfflineBuildings: ', error);
      }
    }

    async processBuildingsFetchResponse(buildings: Building[]) {
      try {
        if (Array.isArray(buildings)) {
          this.setBuildings(buildings);

          await this.diskService.updateOrInsert(DB_KEY_PREFIXES.ADDRESS_BUILDINGS, (doc) => {
            doc = {
              raw: buildings
            };
            return doc;
          });
        }
      } catch (error) {
        console.error('processBuildingsFetchResponse: ', error);
      }
    }
  }