import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { IO_CONFIGURATION_ENTITY_NAME } from '@omni/config/fetch-xml/shared-fetchXML-entity-names';
import { Pharmacovigilance, PharmacovigilanceBtnConfigNameAndDataTypeMapping, PharmacovigilanceCreateDTO, PharmacovigilanceCreateResponseDTO, PharmacovigilanceInfoModalDataType } from '@omni/interfaces/pharmacovigilance-reporting/pharmacovigilance.interface';
import { IoConfiguration } from '@omni/interfaces/shared/shared.interface';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DeviceService } from '@omni/services/device/device.service';
import { PharmacovigilanceService } from '@omni/services/pharmacovigilance-reporting/pharmacovigilance.service';
import { Endpoints } from 'src/config/endpoints.config';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';

@Injectable({
  providedIn: 'root',
})
export class PharmacovigilanceDataService {
  constructor(
    private http: HttpClient,
    private deltaService: DeltaService,
    private authService: AuthenticationService,
    private device: DeviceService,
    private pharmacovigilanceService: PharmacovigilanceService,
    private dynamics: DynamicsClientService,
  ) {}

  async fetchPharmacovigilanceReportings(loadFromDbOnly = false) {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.PHARMACOVIGILANCE_TOOL)) {
      return;
    }
    this.pharmacovigilanceService.updateRecordDataSyncState('Null');
    // Full sync all the time for now.
    if (!(this.device.isOffline || loadFromDbOnly)) {
      this.pharmacovigilanceService.updateRecordDataSyncState('Sync');
      const url = this.authService.userConfig.activeInstance.entryPointUrl
        + Endpoints.pharmacovigilance.GET_PHARMACOVIGILANCE_REPORTS;
      const syncInfo: EntitySyncInfo = {
        entityName: EntityNames.pharmacovigilance,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true,
      };

      let response: Pharmacovigilance[];
      try {
        response = await this.http.get<Pharmacovigilance[]>(
          url,
          Endpoints.GLOBAL_SYNC_HEADER,
        ).toPromise();
      } catch (error) {
        console.error('fetchPharmacovigilanceReportings: ', error);
        this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
      }

      try {
        await this.pharmacovigilanceService.saveFullSyncedRecords(response, syncInfo);
      } catch (error) {
        console.error('fetchPharmacovigilanceReportings: save: ', error);
        this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
      }

      this.deltaService.addEntitySyncInfo(syncInfo);
      this.pharmacovigilanceService.updateRecordDataSyncState(syncInfo.syncStatus ? 'Success' : 'Fail');
    }
  }

  async createPharmacovigilanceReport(
    dto: PharmacovigilanceCreateDTO,
  ): Promise<PharmacovigilanceCreateResponseDTO> {
    let returnValue: PharmacovigilanceCreateResponseDTO;

    if (this.device.isOffline) {
      return;
    }

    const url = this.authService.userConfig.activeInstance.entryPointUrl
      + Endpoints.pharmacovigilance.POST_PHARMACOVIGILANCE_REPORT;

    try {
      const response = await this.http.post<PharmacovigilanceCreateResponseDTO>(
        url,
        dto,
      ).toPromise();
      if (response?.indskr_pharmacovigilancereportingid) {
        returnValue = response;
      }
    } catch (error) {
      console.error('createPharmacovigilanceReport: ', error);
      if (
        error?.error?.errorMessage?.includes('Error identified in Payload provided by the user')
      ) {
        console.error('createPharmacovigilanceReport: Invalid payload: ', dto);
      }
      throw new Error();
    }

    return returnValue;
  }

  async fetchInfoButtonsData(loadFromDBOnly) {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.PHARMACOVIGILANCE_INFORMATION_BUTTONS)) {
      return;
    }
    if (!this.device.isOffline && !loadFromDBOnly) {
      let response: IoConfiguration[];
      const buName: string = this.authService.user.businessUnitName;
      try {
        const mapping = this.pharmacovigilanceService.generateInfoButtonConfigMapping(buName);
        const fetchXML = this.generateInfoButtonFetchXML(mapping);

        response = await this.dynamics.executeFetchQuery(
          IO_CONFIGURATION_ENTITY_NAME,
          fetchXML
        );

        if (Array.isArray(response)) {
          this.pharmacovigilanceService.saveInfoButtonsData(response);
        }
      } catch (error) {
        console.error('fetchInfoButtonsData: ', error);
      }
    }
  }

  private generateInfoButtonFetchXML(mapping: PharmacovigilanceBtnConfigNameAndDataTypeMapping): string {
    let fetchXML = fetchQueries.fetchIoConfigurations;
    let filter = '<filter type="or">';
    for (const key in mapping) {
      if (Object.prototype.hasOwnProperty.call(mapping, key)) {
        const data = mapping[key];
        filter = filter + '<condition attribute="indskr_configname" operator="eq" value="' + data.configName + '" />';
      }
    }
    filter = filter + '</filter>';

    fetchXML = fetchXML.replace(
      '{filter}',
      filter,
    );
    return fetchXML;
  }
}
