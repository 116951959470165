import { UIService } from '@omni/services/ui/ui.service';
import { SearchConfigService } from './../../services/search/search-config.service';
import { Injectable } from "@angular/core";
import { ScientificActivityPlan, ScientificPlansUsers } from "../../classes/scientific-activity/scientific-activity.class";
import { fetchQueries } from "../../config/dynamics-fetchQueries";
import { DynamicsClientService } from "../../data-services/dynamics-client/dynamics-client.service";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../services/authentication.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DiskService } from "../../services/disk/disk.service";
import { ScientificActivityService } from "../../services/scientific-activity/scientific-activity.service";
import { DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { differenceInHours, isValid } from "date-fns";
import { BrandOfflineService } from "../../services/brand/brand.service";
import { TherapeuticAreaDataService } from "../therapeutic-area/therapeutic-area.data.service";
import { TherapeuticArea } from "../../classes/therapeutic-area/therapeutic-area.class";
import { ContactOfflineService } from "../../services/contact/contact.service";
import { Contact } from "../../classes/contact/contact.class";
import { HttpClient } from "@angular/common/http";
import { Endpoints } from "../../../config/endpoints.config";
import { AppointmentActivity } from "../../classes/activity/appointment.activity.class";
import { DeviceService } from "../../services/device/device.service";
import { Utility } from "../../utility/util";
import { FollowUpActivity } from "../../classes/activity/follow-up-action.activity.class";
import { ActivityService } from "../../services/activity/activity.service";

import { Events } from '@omni/events';
import { Resource } from "../../classes/resource/resource.class";
import { ActivityTypeCodeRaw } from "../../classes/activity/activity.class";
import { DeltaService } from '../delta/delta.service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class ScientificActivityDataService{

    private SAP_FETCH_XML = fetchQueries.ScientificActivity.fetchScientificActivities;
    private SAP_FETCH_XML_LINKED_ENTITY = fetchQueries.ScientificActivity.fetchScientifcPlanLinkedEntity;

    private publishedCondition =
                `<condition attribute="statuscode" operator="in">
                  <value>2</value>
                  <value>548910003</value>
                </condition>`

    constructor(
        private dynamics: DynamicsClientService,
        private authService: AuthenticationService,
        private sapService: ScientificActivityService,
        private activityService: ActivityService,
        private disk: DiskService,
        private brandService: BrandOfflineService,
        private therapeuticService: TherapeuticAreaDataService,
        private contactService: ContactOfflineService,
        private http: HttpClient,
        private device: DeviceService,
        private events: Events,
        private searchConfigService: SearchConfigService,
        private readonly uiService: UIService,
        private deltaService: DeltaService,
    ){}

    async getScientificActivities(fullSync:boolean = false, loadFromDbOnly = false, scientificPlanID?: string):Promise<any>{
      // if (loadFromDbOnly) {
        // This function gets called at the time of opening scientific activity page.
        // Hence no need to run twice for offline login flow.
        // return;
      // }

        if(this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN)){
          this.deltaService.pushSyncEntityName(SyncFeatureCategory.plans);
            try{
              let offlineSAP;
              let lastModifiedForDeltaSync, hourDifference;
              await this.disk.retrieve(DB_KEY_PREFIXES.SCIENTIFIC_PLANS, true).then((doc)=>{
                  offlineSAP = doc
                  if(doc && doc.raw){
                    this.sapService.sapActivities = doc.raw;
                  }
                  else {
                    this.sapService.sapActivities = [];
                    fullSync = true;
                  }
                });

              if(this.device.isOffline || loadFromDbOnly) {
                console.log("SAP is offline do nothing")
                return;
              }

              // let userIds = this.authService.user.childUsers.map(o=>{
              //   return o.userId
              // });
              // userIds.push(this.authService.user.systemUserID);
              // // console.log("User ids:" + userIds);
              // let usersString = '';
              // userIds.forEach(p=>{
              //   usersString += '<value>'+p+'</value>'
              // })
              let fetchXML = this.SAP_FETCH_XML;
              let fetchXmlLinkedEntity = this.SAP_FETCH_XML_LINKED_ENTITY;



              fetchXML = fetchXML.replace('{currentUserId}', this.authService.user.systemUserID);
              fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{currentUserId}', this.authService.user.systemUserID);
              let now = new Date();
              if(fullSync){
                fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
                fetchXML = fetchXML.replace('{publishedCondition}', this.publishedCondition);
                fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{deltaSyncFilter}', '');
                fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{publishedCondition}', this.publishedCondition);
              }
              else {

                fetchXML = fetchXML.replace('{publishedCondition}', '');
                fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{publishedCondition}', '');

                if(scientificPlanID) {
                  let plan = offlineSAP.raw.find(x => x.ID == scientificPlanID)
                  if(plan.lastUpdated) lastModifiedForDeltaSync = plan.lastUpdated;
                }
                else offlineSAP?lastModifiedForDeltaSync = offlineSAP.lastModified : '';
                let deltaSyncFilter;
                if(lastModifiedForDeltaSync){
                  hourDifference = differenceInHours(
                    now,
                    new Date(lastModifiedForDeltaSync)
                  )
                  //add one to make sure we take care of fractional difference in hours
                  hourDifference += 1
                  const entityname = 'indskr_scientificplan'
                  deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname);
                  deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference);
                  deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id');
                }
                else {
                  deltaSyncFilter = '';
                }
                fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
                fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{deltaSyncFilter}', deltaSyncFilter);
              }
              if(scientificPlanID){
                let planIDFilter = `<filter><condition attribute="ind_scientificactivityplanid" operator="eq" value="`+scientificPlanID+`"/></filter>`;
                fetchXML = fetchXML.replace('{planID}',planIDFilter );
                fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{planID}',planIDFilter );
              }
              else{
                fetchXML = fetchXML.replace('{planID}','')
                fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{planID}','')
              }
              await this.dynamics.executeFetchQuery('indskr_scientificplans',fetchXML).then(
                async (res)=> {
                  let linkedEntities = await this.dynamics.executeFetchQuery('indskr_scientificplans',fetchXmlLinkedEntity);
                  if(linkedEntities) res.push(...linkedEntities);
                  if(!fullSync){
                    let deletedPlans = await this.fetchHardDeletedScientificPlans(hourDifference);
                    if(deletedPlans) res.push(...deletedPlans);
                  }

                  await this.mapScientificActivities(res, fullSync);

                  if(scientificPlanID) {
                    let index = this.sapService.sapActivities.findIndex(x => x.ID == scientificPlanID);
                    if(index >- 1){
                      this.sapService.sapActivities[index].lastUpdated = now.getTime()
                    }
                    this.disk.updateOrInsert(DB_KEY_PREFIXES.SCIENTIFIC_PLANS, (doc)=>{
                      doc = {
                          raw: [],
                          lastModified: doc.lastModified
                      };
                      doc.raw = this.sapService.sapActivities;
                      return doc;
                    })
                  }
                  else {
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SCIENTIFIC_PLANS, (doc)=>{
                      doc = {
                          raw: []
                      };
                      doc.raw = this.sapService.sapActivities;
                      doc.raw.map(x => {
                        x.lastUpdated = now.getTime();
                      });
                      doc.lastModified = now.getTime();
                      return doc;
                    });
                  }
                  if(!fullSync) {
                    //Why do we need to do deltasync everytime we open the list??
                    try {
                      const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
                      if (duration > 0) {
                        let today = new Date();
                        const maxEndDateUnixTimestamp = Date.UTC(
                          today.getFullYear(),
                          today.getMonth(),
                          today.getDate() - duration
                        );
                        await this.purgeData(maxEndDateUnixTimestamp);
                      }
                    } catch (error) {
                      console.error('SAP Delta Purge Error: ', error);
                    }
                  }
                },
              (err) => {
                console.log("Error fetching SAP", err);
              })
            } catch (error){
              console.log("GET SAP", error);
            }
          }

    }


    async fetchHardDeletedScientificPlans(hourDifference?: any){
        try{
          let responseForDeletedPlans = [];
          let fetchXML;
            if(hourDifference){
              fetchXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
              <entity name="indskr_trackchange">
                <order attribute='createdon' descending='false'/>
                <attribute name="createdon" alias="track_action_CreatedOn"/>
                <attribute name="indskr_action" alias="track_action"/>
                <attribute name="indskr_entityid" alias="indskr_scientificplanid"/>
                <filter type="and">
                  <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference+`" />
                  <condition value="indskr_scientificplan" attribute="indskr_entityname" operator="eq" />
                  <condition value="548910001" attribute="indskr_action" operator="eq" />
                </filter>
                </entity>
                </fetch>`
            }
          responseForDeletedPlans = await this.dynamics.executeFetchQuery('indskr_trackchanges',fetchXML);
          // console.log("RESPONSE FOR DELETED PLANS");
          // console.log(responseForDeletedPlans);
          return responseForDeletedPlans;
        }catch (error){
          console.log(error)
        }
    }
    async mapScientificActivities(response, fullSync?: boolean){
        let scientificActivities: ScientificActivityPlan[];

        try{
            if(response && Array.isArray(response)){
              scientificActivities = this.groupSAPList(response);
              if(fullSync){
                this.sapService.sapActivities = scientificActivities;
              } else {
                scientificActivities.map(sap => {
                    if(!sap.trackAction || (sap.trackAction &&  sap.trackAction == 'Download')){
                      let index = this.sapService.sapActivities.findIndex(o=> o.ID == sap.ID)
                      if(index>-1){
                        this.sapService.sapActivities[index] = sap
                      }
                      else{
                        this.sapService.sapActivities.push(sap);
                      }
                    }
                    if((sap.trackAction &&  sap.trackAction == 'Remove') || (sap.statusCode && sap.statusCode != 2 && sap.statusCode !=  548910003)){
                      let index = this.sapService.sapActivities.findIndex(o=> o.ID == sap.ID)
                      if(index>-1){
                        this.sapService.sapActivities[index].state = 1
                        this.sapService.sapActivities[index].statusCode = 2
                      }
                      else{

                      }
                    }
                  })
                  this.sapService.sapActivities = this.sapService.sapActivities.filter(p=>{
                    return p.state == 0 || !(p.state == 1 && p.statusCode == 2)
                  })
              }
              this.searchConfigService.isSAPActivitiesMappedToContacts = false;
              await this.getUSersBySP(scientificActivities.map(x => x.ID));


            }
        }catch(err){
            console.error("mapScientificActivities: " + err);
        }
    }


    groupSAPList(response): ScientificActivityPlan[] {
        let scientificActivities: ScientificActivityPlan[] = [];
        let newSAP:boolean = false;
        response.forEach(row => {
            let item:ScientificActivityPlan = scientificActivities.find(sapItem => sapItem.ID === row['indskr_scientificplanid']);
            if(!item){
              item = new ScientificActivityPlan(row);
              newSAP = true;
            }else{
              if(row['track_action@OData.Community.Display.V1.FormattedValue']) {
                if(row['track_action@OData.Community.Display.V1.FormattedValue'] != item.trackAction) {
                  if(new Date(row['track_action_CreatedOn']).getTime() > new Date(item.trackActionCreatedOn).getTime()) {
                    item.trackAction = row['track_action@OData.Community.Display.V1.FormattedValue'];
                  }
                }
              }
              newSAP = false;
            }

            if(!item.products.some(prod => prod.ID === row['product.productid'])){
              let prod = this.brandService.brands.find(brand=> brand.ID === row['product.productid']);
              if(prod) item.products.push(prod);
            }
            if(!item.therapeuticAreaList.some(ta => ta.therapeuticareaId === row['therapeutic.indskr_therapeuticareaid'])){
              // let thAreaItem:TherapeuticArea = this.therapeuticService.therapeuticAreas.find(ta => ta.therapeuticareaId === row['therapeutic.indskr_therapeuticareaid']);
              let tempTa = new TherapeuticArea({'indskr_therapeuticareaid':row['therapeutic.indskr_therapeuticareaid'], 'indskr_name' : row['therapeutic.indskr_name']});
              item.therapeuticAreaList.push(tempTa);
            }

            if(!item.contacts.some(cust => cust.ID === row['contact.contactid'])){
              let contact :Contact = this.contactService.contacts.find(cust => cust.ID === row['contact.contactid'] && cust.statusCode !== 2);
              if(contact) item.contacts.push(contact);
            }

            if(!item.sapObjectives.some(obj => obj.objectiveId === row['PO.indskr_accountplanobjectiveid'])){
              if(row['PO.indskr_accountplanobjectiveid'])
              {
              let tempObj = {
                "objectiveId" : row['PO.indskr_accountplanobjectiveid'],
                "name" : row['PO.indskr_name']
              };
              item.sapObjectives.push(tempObj);
            }
          }

            if(row['sap_document.indskr_iodocumentid'] && !item.documents.some(doc => doc.ioDocumentId === row['sap_document.indskr_iodocumentid'])){
              let document: Resource = this.mapResource(row);
              if(document) item.documents.push(document);
            }

            // if(row['indskr_scientificplanid']== 'e78bd9ff-deda-e911-a812-000d3af46b49')
            // console.log(row['sap_systemuser.systemuserid']);
            // if(item.users.indexOf(row['sap_systemuser.systemuserid']) === -1){
            //   item.users.push(row['sap_systemuser.systemuserid']);
            // }

            if(newSAP) scientificActivities.push(item);
        });
        return scientificActivities;
    }

    async getScientificPlanById(spId):Promise<any>{
      return new Promise(async (resolve,reject)=>{
        try{
            // let userIds = this.authService.user.childUsers.map(o=>{
            //   return o.userId
            // });
            // userIds.push(this.authService.user.systemUserID);
            // // console.log("User ids:" + userIds);
            // let usersString = '';
            // userIds.forEach(p=>{
            //   usersString += '<value>'+p+'</value>'
            // })
            let fetchXML = this.SAP_FETCH_XML;
            let fetchXmlLinkedEntity = this.SAP_FETCH_XML_LINKED_ENTITY;

            fetchXML = fetchXML.replace('{currentUserId}', this.authService.user.systemUserID);
            fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{currentUserId}', this.authService.user.systemUserID);
            if(spId){
              let planIDFilter = `<filter><condition attribute="indskr_scientificplanid" operator="eq" value="`+spId+`"/></filter>`;
              fetchXML = fetchXML.replace('{planID}',planIDFilter );
              fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{planID}',planIDFilter );
            }
            else {
              fetchXML = fetchXML.replace('{planID}','');
              fetchXmlLinkedEntity = fetchXmlLinkedEntity.replace('{planID}','');
            }
            let spSelected:ScientificActivityPlan;
            await this.dynamics.executeFetchQuery('indskr_scientificplans',fetchXML)
            .then(async (res)=>{
              // console.log('scientific Activity plans', res);

              let linkedEntities = await this.dynamics.executeFetchQuery('indskr_scientificplans',fetchXmlLinkedEntity);
              if(linkedEntities) res.push(...linkedEntities);

              if(res && Array.isArray(res) && res.length>0){
                spSelected = this.groupSAPList(res)[0];
                resolve(spSelected);

                let planIndex = this.sapService.sapActivities.findIndex(o=> o.ID == spId);
                if(planIndex>-1){
                  this.sapService.sapActivities[planIndex] = spSelected;
                  this.sapService.sapActivities[planIndex].lastUpdated = new Date().getTime();
                  await this.getUSersBySP([spId]);
                }
                this.disk.updateOrInsert(DB_KEY_PREFIXES.SCIENTIFIC_PLANS, (doc)=>{
                  doc = {
                      raw: [],
                      lastModified: doc.lastModified
                  };
                  doc.raw = this.sapService.sapActivities;
                  return doc;
                })
              }else{
                reject("No Records found");
              }
            },
            (err)=>{
              reject(err);
            })
        }catch (error){
          console.log(error)
          reject(error);
        }
      });
    }

    getScientificPlansByContactId(contactId): Promise<ScientificActivityPlan[]> {
      let fetchXML = fetchQueries.ScientificActivity.fetchScientificActivitiesByContact;
      const currentDay: Date = new Date();
      let compareToDate;

      const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
      if (duration > 0) {
        const maxEndDateUnixTimestamp = Date.UTC(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate() - duration
        );

        compareToDate = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp,true);
      } else {
        compareToDate = currentDay.getTime();
      }

      fetchXML = fetchXML.replace('{contactId}', contactId);
      let plans:ScientificActivityPlan[] = [];
      return new Promise(async (response,reject) => {

        plans = this.sapService.sapActivities.filter(sp =>{
          return sp.contacts.some(con => con.ID === contactId) && (Number(sp.endDate) >= compareToDate.getTime().toString());
        });

        response(plans);
        // await this.dynamics.executeFetchQuery('indskr_scientificplans',fetchXML)
        // .then(async (res) => {

        //   // console.log('scientific Activity plans', res);
        //   if(res && Array.isArray(res)) {
        //     res.forEach(sp => {
        //       plans.push(new ScientificActivityPlan(sp));
        //     });

        //     plans = plans.filter((sp) => {
        //       return (Number(sp.endDate) >= currentDay.getTime());
        //     });

        //     response(plans);
        //   }else{
        //     reject("No Plans Found");
        //   }

        // },
        // (err)=>{
        //   reject("No Plans Found : " + err);
        // });
      });
    }

    // getUSersBySP(sapList?: any):Promise<any>{
    //   return new Promise(async (resolve,reject)=>{

    //     try{
    //       if(!this.device.isOffline){

    //         let fetchXML = fetchQueries.ScientificActivity.fetchUserByScientificPlans;
    //         if(sapList) {
    //           let filter = `<filter> <condition attribute="positionid" operator="not-in" entityname="position">`+ sapList + `</condition> </filter>`
    //           fetchXML.replace('{getUsersForTask}', filter);
    //         } else {
    //           fetchXML.replace('{getUsersForTask}', '');
    //         }
    //         let response = await this.dynamics.executeFetchQuery('systemusers',fetchXML);

    //         if(response && Array.isArray(response)){
    //           console.log(response);
    //           response.forEach(res =>{
    //             let sp = this.sapService.sapActivities.find(sp => sp.ID === res['indskr_scientificplan_systemuser1.indskr_scientificplanid']);
    //             if(sp){
    //               let userId = res['indskr_scientificplan_systemuser1.systemuserid'];
    //               if(sp.users.indexOf(userId)===-1){
    //                 sp.users.push(userId);
    //               }
    //             }
    //           });
    //           await this.disk.updateOrInsert(DB_KEY_PREFIXES.SCIENTIFIC_PLANS, (doc)=>{
    //             doc = {
    //                 raw: []
    //             };
    //             doc.raw = this.sapService.sapActivities;
    //             return doc;
    //           })
    //           resolve(true);
    //         }
    //       }
    //     }catch(err){
    //       reject(false);
    //     }
    //   })

    // }

    async getUSersBySP(sapList?: any):Promise<any>{

        try{
          if(!this.device.isOffline){

            let fetchXML = fetchQueries.ScientificActivity.fetchUserByScientificPlans;

            if(sapList && sapList.length > 0) {
              let sapListString = '';
              sapList.forEach(p => {
                sapListString += '<value>' + p + '</value>';
              })
              let filter = `<filter> <condition attribute="indskr_scientificplanid" operator="in" entityname="sap_user">`+ sapListString + `</condition> </filter>`;
              fetchXML = fetchXML.replace('{getUsersForTask}', filter);
            } else {
              return;
            }
            let response = await this.dynamics.executeFetchQuery('systemusers',fetchXML);
            if(response && Array.isArray(response)){
              response.forEach(res =>{
                let sp = this.sapService.sapActivities.find(sp => sp.ID === res['sap_user.indskr_scientificplanid']);
                if(sp){
                  let sapUser: ScientificPlansUsers = {
                    ownerid: res['systemuserid'],
                    fullname: res['fullname']
                  };
                  if(!sp.users.some(x => x.ownerid == sapUser.ownerid)){
                    sp.users.push(sapUser);
                  }
                }
              });
            }
          }
        }catch(err){
        }

    }

    getTimelineDataBySPId(scientificPlan:ScientificActivityPlan):Observable<any[]> {
      let activities = [];

      return new Observable((observer) => {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.scientificActivities.GET_SP_TIMELINE;

        url = url.replace('{{scientificplanID}}',scientificPlan.ID);
        const prodIds = scientificPlan.products.map(prod => prod.ID).join();
        if(prodIds.length>0){
          url = url.replace('{{prodIDs}}',"?productIds=" + prodIds);
        }else{
          url = url.replace('{{prodIDs}}',"");
        }


        const taIds = scientificPlan.therapeuticAreaList.map(item => item.therapeuticareaId).join();
        if(taIds.length>0){
          url = url.replace('{{therapeuticIds}}',"&therapeuticAreaIds=" + taIds);
        }else{
          url = url.replace('{{therapeuticIds}}',"");
        }

        url = url.replace('{{planStart}}',scientificPlan.startDate);
        url = url.replace('{{planEnd}}',scientificPlan.endDate);

        const contactIds = scientificPlan.contacts.map(contact => contact.ID).join();
        if(contactIds.length>0){
          url = url.replace('{{contactId}}',"&contactIds=" + contactIds);
        }else{
          url = url.replace('{{contactId}}',"");
        }

        const positions = this.authService.user.positions.map(pos => pos.ID).join();
        url = url.replace('{{PositionId}}',positions);

        // url = 'https://entry.dev.labs.global.omnipresence.io/api/cag/contacts/97c1b048-a6be-e911-a811-000d3af46489/activities?features=liveMeet';

        this.http.get(url).subscribe(data => {
          if(data && Array.isArray(data['activities'])) {
            data['activities'].forEach(activity =>{
              if(activity['indskr_ownerid'] === this.authService.user.systemUserID){
                switch (activity['activitytypecode']) {
                  case ActivityTypeCodeRaw.Appointment:
                      let appointment = this.activityService.activities.find(act => act.ID === activity['activityid']);
                      if(appointment) {
                        activities.push(appointment);
                      } else {
                        activities.push(new AppointmentActivity(activity));
                      }

                      break;
                  case ActivityTypeCodeRaw.Email:
                      if(activity['scheduledstart']) {
                        let email = this.activityService.activities.find(act => act.ID === activity['activityid']);
                        if(email) {
                          if(activity['indskr_ownerfullname']) email['meetingOwnerName'] = activity['indskr_ownerfullname'];
                          if(activity['senton']) email['senton'] = activity['senton'];
                          if(activity['readOn']) email['senton'] = activity['readOn'];
                          activities.push(email);
                        } else {
                          activities.push(new AppointmentActivity(activity));
                        }
                      }
                      break;
                  case ActivityTypeCodeRaw.FollowUpTask:
                      let spTask = this.activityService.activities.find(act => act.ID === activity['activityid']);
                      if(spTask) {
                        activities.push(spTask);
                      } else {
                        activities.push(new FollowUpActivity(activity));
                      }
                      break;
                }
              }
            });
            observer.next(activities);
            observer.complete();
          }
        }, err=>{
          observer.error(err);
        });
      });
    }

    public async purgeData(maxEndDateUnixTimestamp:number) {
      let document;
      let dbScientificPlan;
      let sapToPurge = [];
      let afterPurgedSap = [];
      let deletedSapFollowups = [];
      let compareToDate = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp,true);
      await this.disk.retrieve(DB_KEY_PREFIXES.SCIENTIFIC_PLANS, true)
      .then((doc) => {
        if (doc && doc.raw) {
          document = doc;
          dbScientificPlan = doc.raw
        }
      }).catch(error => {
        console.log('Error occured while fetching Scientfic Activity Plans'+error);
      });

      if(dbScientificPlan) {
        dbScientificPlan.forEach(raw => {
          if (raw['endDate'] && raw['endDate'] < compareToDate.getTime().toString()) {
            // remove from existing arrays
            sapToPurge.push(raw);
            if(this.sapService.sapActivities){
              const idx = this.sapService.sapActivities.findIndex(x => x.ID === raw['ID']);
              if (idx >= 0) {
                this.sapService.sapActivities.splice(idx, 1);
              }
            }
          } else {
            afterPurgedSap.push(raw);
          }
        });
      }

      // Only write to local db when something got purged
      if (dbScientificPlan && afterPurgedSap.length < dbScientificPlan.length) {
        try {
            document.raw = afterPurgedSap;
            await this.disk.updateDocWithIdAndRev(document);
        } catch (error) {
            console.error('purgeSap: ', error);
        }
        // Purge Associated followup tasks
        let option = {
          selector: {
            '_id': {
              $gte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY,
              $lte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
            }
          }
        };
        try {
          // Fetch from DB
          const offlineFollowups: any[] = await this.disk.find(option);
          if (offlineFollowups && Array.isArray(offlineFollowups) && offlineFollowups.length > 0) {
            offlineFollowups.forEach(async rawfollowup => {
              let followup = new FollowUpActivity(rawfollowup);
              if(sapToPurge.some(plan => plan['ID'] == followup.scientificPlanId)){
                deletedSapFollowups.push({ _id: rawfollowup._id, _rev: rawfollowup._rev, _deleted: true });
                this.activityService.removeActivity(followup,false,null);
              }
            });
          }
        } catch (error) {
          console.log('Error occured while fetching SAP follow up activities data from offline db');
        }
        try {
          // Bulk save/update docs to DB
          if(deletedSapFollowups.length > 0){
            await this.disk.bulk(deletedSapFollowups);
            if (!this.uiService.toolsActivityActive){
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
          }
        } catch (error) {
          // TODO: handle error..
          console.error('purgeSapTask: ', error);
        }
      }
    }

    public mapResource(row: any) {
      return new Resource({
        indskr_iodocumentid: row['sap_document.indskr_iodocumentid'],
        indskr_ioresourceid: "",
        indskr_title: row['sap_document.indskr_title'],
        createdon: new Date(row['sap_document.createdon']).getTime().toString(),
        modifiedon: new Date(row['sap_document.modifiedon']).getTime().toString(),
        indskr_ckmassettype: row['sap_document.indskr_ckmdocumenttype'],
        indskr_ckmthumbnailurl: row['sap_document.indskr_ckmthumbnailurl'],
        indskr_ckmasseturl: row['sap_document.indskr_ckmdocumenturl'],
        indskr_ckmassetid: row['sap_document.indskr_ckmdocumentid'],
        indskr_description:row['sap_document.indskr_description'],
        statecode: row['sap_document.statecode'],
        statuscode: row['sap_document.statuscode'],
        indskr_availablefrom: new Date(row['sap_document.indskr_availablefrom']).getTime().toString(),
        indskr_availableuntil: new Date(row['sap_document.indskr_availableuntil']).getTime().toString(),

        brands: null,
        indskr_ckmcreateddate: null,
        indskr_ckmtitle: null,
        _indskr_ckmcreatedbyid_value: null,
        indskr_ckmdescription: null,
        _indskr_ckmpublishedbyid_value: null,
        indskr_ckmpublisheddate: null,
        _indskr_ckmversionbyid_value: null,
        indskr_ckmversiondate: null,
        indskr_ckmversionid: null,
        _indskr_languageid_value: null,
        isNew: row['isNew']
      });
    }
  public mapMyScientificPlansToSearchIndex(newScientifcPlans) {
    newScientifcPlans.forEach(newSP => {
      if (!_.isEmpty(newSP.products))
        newSP.products.forEach(product => {
          if (product.name && !this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.some(o => o == product.name))
            this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.push(product.name);
        });
    })
  }
}
