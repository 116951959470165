export class city{
    public value:string
    public isSelected:boolean;
    public id:string;
    public zipCodeId: string;
    public zipCodeList: string[];
    public countryId: string;
    public countryName: string;
    public stateCode: any;
    constructor(payload: cityPayload){
        this.value = payload.indskr_name;
        this.id = payload.indskr_lu_cityid;
        this.zipCodeId = payload.postalCodeId;
        this.zipCodeList = payload.postalCodes;
        this.countryId = payload.country;
        this.stateCode = payload.statecode ? payload.statecode : 0;
        this.countryName = payload['country@OData.Community.Display.V1.FormattedValue'];
    }

}
export interface cityPayload {
    '@odata.etag': string;
    indskr_lu_cityid: string;
    indskr_name: string;
    'createdon@OData.Community.Display.V1.FormattedValue': string;
    createdon: string;
    '_createdby_value@OData.Community.Display.V1.FormattedValue': string;
    _createdby_value: string;
    '_indskr_state_value@OData.Community.Display.V1.FormattedValue': string;
    _indskr_state_value: string;
    'modifiedon@OData.Community.Display.V1.FormattedValue': string;
    modifiedon: string;
    'country@OData.Community.Display.V1.FormattedValue': string;
    country: string;
    postalCodeId: string;
    postalCodes: string[];
    statecode?:any;
  }

  interface RootObject {
  '@odata.etag': string;
  indskr_lu_cityid: string;
  indskr_name: string;
  'createdon@OData.Community.Display.V1.FormattedValue': string;
  createdon: string;
  '_createdby_value@OData.Community.Display.V1.FormattedValue': string;
  _createdby_value: string;
  '_indskr_state_value@OData.Community.Display.V1.FormattedValue': string;
  _indskr_state_value: string;
  'modifiedon@OData.Community.Display.V1.FormattedValue': string;
  modifiedon: string;
  'country@OData.Community.Display.V1.FormattedValue': string;
  country: string;
  postalCodeId: string;
  postalCodes: string[];
}
