<ion-header [ngClass]="{'show-progress-bar-pad': deviceService.isBackgroundUploadInProgress }">
  <ind-section-header [viewData]="sectionHeader" (onFilterClick)="openFilter($event)"></ind-section-header>
</ion-header>
<div>
  <ion-list *ngIf="approvals.length > 0">
    <div *ngFor="let item of approvals" (click)="openApprovalDetails(item)">
      <app-approval-list-item
        [selectedMode]="selectedMode"
        [ngClass]="{'itemSelected': item.indskr_approvalactivityid === selectedApproval?.indskr_approvalactivityid}"
        [approval]="item"></app-approval-list-item>
      <div class="dividerBorder"></div>
    </div>
  </ion-list>
  <div *ngIf="approvals.length == 0" class="no-data-message">
    {{'NO_APPROVALS' | translate}}
  </div>
</div>