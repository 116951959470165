<!-- <ion-header>
    <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button (click)="closepage(false)" class="back-btn">
                <ion-icon name="chevron-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">{{ 'CONTACT_JOURNEY_INSIGHT' | translate}} +</ion-title>
        <ion-buttons *ngIf="!readOnly" slot="end">
            <ion-button icon-only (click)="closepage(true)" [disabled]="!journey.description">
                {{ 'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header> -->

<ion-header>
    <ind-page-title [viewData]="journeyDetailPageHeaderModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content class="ion-no-padding" toolbar-md-height>
    <ng-container>
        <ion-list>
            <!-- <ion-item-divider>
                <ion-label> {{'XPERIENCES_JOURNEY_DETAILS' | translate}} </ion-label>
            </ion-item-divider> -->
            <ind-section-header [viewData]="journeyHeaderModel"></ind-section-header>
            <ion-item-group>
                <!-- <ion-item>
                    <ion-label>{{ 'XPERIENCES_JOURNEY_TOUCHPOINT' | translate}}</ion-label>
                    <div slot="end">
                        <span *ngIf="readOnly && journey.type" [ngClass]="{'disabledLabel': readOnly}"> {{journey.type}}</span>
                        <span *ngIf="readOnly && !journey.type" class="right-label disabledLabel">{{'NO' | translate}} {{'XPERIENCES_JOURNEY_TOUCHPOINT' | translate}}</span>
                        <ion-select *ngIf="!readOnly" [(ngModel)]="journey.type" placeholder="{{'XPERIENCES_SELECT_JOURNEY_TOUCH_POINT' | translate}}" [interfaceOptions]="interfaceOptions" interface="popover">
                            <ion-select-option *ngFor="let activity of xpService.activityTypes" [value]="activity.value"> {{activity.label}}</ion-select-option>
                        </ion-select>
                    </div>
                </ion-item> -->
                <ind-form-field [viewData]="joiurneyTouchPointFormField()"></ind-form-field>
                <ind-form-field [viewData]="joiurneyDescriptionFormField()"></ind-form-field>
                <!-- <ion-item>
                    <ion-label position="fixed">{{ 'DESCRIPTION' | translate}}</ion-label>
                    <ion-label *ngIf="readOnly" [ngClass]="{'disabledLabel no-description-text': readOnly}"> {{ journey.description }}</ion-label>
                    <ion-input *ngIf="!readOnly" placeholder="{{'ENTER_DESCRIPTION' | translate}}" [(ngModel)]="journey.description"></ion-input>
                    <ion-icon *ngIf="!readOnly" slot="end" name="chevron-back" color="indgray" class="light-color"></ion-icon>
                </ion-item> -->

                <!-- <ind-form-field [viewData]="getTimeFormField()"></ind-form-field> -->
                <ind-form-field [viewData]="journeyDateFormField()"></ind-form-field>
                <!-- <ion-item no-lines hidden="true">
                    <ion-datetime #date [displayFormat]="dateTimeFormatsService.dateToUpper" [pickerFormat]="dateTimeFormatsService.dateToUpper" (ionChange)="updateJourneyDate()" [(ngModel)]="journeyDate" placeholder="{{'CUSTOMER_INSIGHT_JDATE' | translate}}" [max]="todayDate"
                        [monthShortNames]="shortMonth | monthTranslate" [doneText]="translate.instant('DONE')" [cancelText]="translate.instant('CANCEL')"></ion-datetime>
                </ion-item> -->

                <!-- <ion-item>
                    <ion-label>{{ 'DATE' | translate}}</ion-label>
                    <div item-content text-wrap>
                        <ion-row align-items-center>
                            <ion-label class="date-label" [ngClass]="{'disabledLabel': readOnly}">{{formattedJourneyTime}}</ion-label>

                            <ion-icon *ngIf="!readOnly" class="ion-float-right" style="color:#c8c7cc;margin-left:5px;" name="chevron-down" slot="end"></ion-icon>
                        </ion-row>
                    </div>
                </ion-item> -->

                <ind-form-field [viewData]="journeyProductsFormField()"></ind-form-field>
                <!-- <ion-item>
                    <ion-label>{{ 'PRODUCT' | translate}}</ion-label>
                    <div slot="end" [ngClass]="{'disabledLabel': readOnly}" (click)="selectJourneyProduct()">
                        <span *ngIf="journey.selectedProduct" [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ journey.selectedProduct.title }}</span>
                        <span *ngIf="!journey.selectedProduct && !readOnly" class="right-label placeholder">{{ 'SELECT_PRODUCT' | translate }}</span>
                        <span *ngIf="!journey.selectedProduct && readOnly" class="right-label disabledLabel">{{ 'NO_PRODUCT' | translate | titlecase }}</span>
                        <ion-icon *ngIf="!readOnly" name="chevron-forward" color="indgray" class="light-color"></ion-icon>
                    </div>
                </ion-item> -->
                <ind-form-field *ngIf="journey.selectedProduct && journey.selectedProduct.id == 'indskr_competitorproduct'" [viewData]="journeyCompetitorProductsFormField()"></ind-form-field>

                <!-- <ion-item *ngIf="journey.selectedProduct && journey.selectedProduct.id == 'indskr_competitorproduct'">
                    <ion-label>{{ 'CONTACT_COMPETITOR_PRODUCT' | translate}}</ion-label>
                    <ion-label *ngIf="readOnly" class="disabledLabel right-label" s> {{ journey.indskr_competitorproduct }}</ion-label>
                    <ion-input *ngIf="!readOnly" [(ngModel)]="journey.indskr_competitorproduct"></ion-input>
                    <ion-icon *ngIf="!readOnly" slot="end" name="chevron-back" color="indgray" class="light-color"></ion-icon>
                </ion-item> -->


                <ion-item class="confidence">
                    <div>
                        <ion-label class="form-field-header-label">{{ 'XPERIENCES_INSIGHTS_CONFIDENCE' | translate}}</ion-label>
                        <!-- <app-star-rating [scale]="[1,2,3]"></app-star-rating> -->
                        <app-star-rating [rating]="journey.confidence" (ratingChanged)="updateJourneyConfidence($event)" activeIcon="star-sharp" defaultIcon="star-sharp" activeColor="#FFA736" defaultColor="#c3c3c3" fontSize="32px" maxRating=3 readonly="{{readOnly}}"></app-star-rating>
                    </div>
                </ion-item>
            </ion-item-group>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar>
<!-- </ion-footer> -->
