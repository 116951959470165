import { TrackAction } from "../../utility/common-enums";
import { StatusCode, TimeInMilliseconds } from "../../enums/shared-enums";
import { isWithinRange } from "date-fns";

export enum CUSTOMER_LICENSE_TYPE {
    'Country' = 548910000,
    'State',
    'DEA',
    'FDA',
    'TDDD',
    'Other'
}

export interface CustomerLicenseDTO {
    indskr_customerlicenseid: string;
    indskr_licensenumber: string;
    customerId: string;
    customerName: string;
    countryId: string;
    countryName: string;
    indskr_type: CUSTOMER_LICENSE_TYPE;
    indskr_validfrom: string;
    indskr_validuntil: string;
    statuscode: StatusCode;
    stateId?: string;
    state?: string;
    customerAddressId?: string;
    customerAddress?: string;
    indskr_cutoffperiod?: number;
    track_cation?: TrackAction;

    // --- Locally assigned fields
    lastSyncedTimestamp?: number;
}

export class CustomerLicense {
    public id: string;
    public licenseNumber: string;
    public contactId: string;
    public contactName: string;
    public countryId: string;
    public country: string;
    public licenseType: CUSTOMER_LICENSE_TYPE;
    public licenseTypeLabel: string;
    public validFrom: number;
    public validUntil: number;
    public statusCode: number;
    public stateId: string;
    public state: string;
    public addressId: string;
    public address: string;
    public cutOffPeriodInDays: number;
    public lastSyncedTimestamp: number;

    constructor(raw: CustomerLicenseDTO) {
        this.id = raw.indskr_customerlicenseid;
        this.licenseNumber = raw.indskr_licensenumber ? raw.indskr_licensenumber : '';
        this.contactId = raw.customerId;
        this.contactName = raw.customerName;
        this.countryId = raw.countryId ? raw.countryId : null;
        this.country = raw.countryName ? raw.countryName : null;
        this.licenseType = raw.indskr_type ? raw.indskr_type : null;
        this.licenseTypeLabel = raw.indskr_type ? this.getLicenseTypeLabel(raw.indskr_type) : null;
        this.validFrom = raw.indskr_validfrom ? parseInt(raw.indskr_validfrom) : null;
        this.validUntil = raw.indskr_validuntil ? parseInt(raw.indskr_validuntil) : null;
        this.statusCode = raw.statuscode;
        this.stateId = raw.stateId ? raw.stateId : null;
        this.state = raw.state ? raw.state : null;
        this.addressId = raw.customerAddressId ? raw.customerAddressId : null;
        this.address = raw.customerAddress ? raw.customerAddress : null;
        this.cutOffPeriodInDays = raw.indskr_cutoffperiod ? raw.indskr_cutoffperiod : null;
        this.lastSyncedTimestamp = raw.lastSyncedTimestamp ? raw.lastSyncedTimestamp : null;
    }

    static getValidUntilTimestampWithCutoffPeriod(validUntil: number, cutOffPeriodInDays: number) {
        if (!validUntil) {
            return null;
        }

        return cutOffPeriodInDays ? validUntil - (cutOffPeriodInDays * TimeInMilliseconds.Day) : validUntil;
    }
    static isWithinExpiry(validFrom: number, validUntil: number, cutOffPeriodInDays: number, calledFrom?: string): boolean {
        const nowTimeStamp: number = new Date().getTime();
        // Adding a day here so that it can be still considered to be valid on the expiry day 
        const validUntilTimeStamp: number = CustomerLicense.getValidUntilTimestampWithCutoffPeriod(validUntil, cutOffPeriodInDays) + TimeInMilliseconds.Day;

        let isWithinRangeBool: boolean = false;
        try {
            isWithinRangeBool = isWithinRange(nowTimeStamp, validFrom, validUntilTimeStamp);
        } catch (error) {
            console.warn(`${calledFrom ? calledFrom : 'isWithinExpiry'}: ` , error);
        }
        return isWithinRangeBool;
    }

    private getLicenseTypeLabel(typeValue: CUSTOMER_LICENSE_TYPE) {
        const label = CUSTOMER_LICENSE_TYPE[typeValue];
        return label ? label : '';
    }
}
