import { BehaviorSubject, Observable } from 'rxjs';
export class Counter {
  private _name: string = '';
  private _counter: number = 0;
  private _counter$: BehaviorSubject<number> = new BehaviorSubject(this._counter);
  readonly counterObservable: Observable<number> = this._counter$.asObservable();

  constructor(name: string) {
    this._name = name;
  }

  get name(): string {
    return this._name;
  }

  incCounter(byCount?: number) {
    if (!isNaN(byCount) && byCount > 0) {
      this._counter = this._counter + byCount;
    } else {
      this._counter++;
    }

    this._counter$.next(this._counter);
  }
  decCounter(byCount? : number) {
    if (!isNaN(byCount) && byCount > 0) {
      this._counter = this._counter - byCount;
    } else {
      this._counter--;
    }

    if (this._counter < 0) {
      this._counter = 0;
    }
    this._counter$.next(this._counter);
  }
  resetCounter() {
    this._counter = 0;
    this._counter$.next(this._counter);
  }
}
