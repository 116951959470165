import { Component, Input } from "@angular/core";
import { Activity } from "../../../classes/activity/activity.class";
import { CoachingReportService } from "../../../services/coaching/coaching.report.service";
import { CoachingActivity } from "../../../classes/coaching/activity.class";
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'coaching-activity-item',
  templateUrl: 'coaching-activity-item.html',
  styleUrls:['coaching-activity-item.scss']
})
export class CoachingActivityItemComponent {

  @Input() activity: CoachingActivity;

  constructor(private coachingReportService: CoachingReportService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private translate: TranslateService) { }

  getActivityLength(start_date: any, end_date: any) {
    let difference = new Date(parseFloat(end_date)).getTime() - new Date(parseFloat(start_date)).getTime();

    if (difference === 0) return 'N/A';

    var days, hours, mins, secs;
    secs = Math.floor(difference / 1000);
    mins = Math.floor(secs / 60);
    secs = secs % 60;
    hours = Math.floor(mins / 60);
    mins = mins % 60;
    days = Math.floor(hours / 24);
    hours = hours % 24;

    if (days > 0) {
      if (hours > 0 && mins > 0) {
        return `${days}d ${hours}h ${mins}m`
      } else if (hours > 0 && mins <= 0) {
        return `${days}d ${hours}h`
      } else if (hours <= 0 && mins > 0) {
        return `${days}d ${mins}m`
      }
    } else {
      if (hours > 0 && mins > 0) {
        return `${hours}h ${mins}m`
      } else if (hours > 0 && mins <= 0) {
        return `${hours}h`
      } else if (hours <= 0 && mins > 0) {
        return `${mins}m`
      }
    }

    return 'N/A';
  }

  public getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

}
