import { AlertService } from './../../../services/alert/alert.service';
import { AssetTrasferStatus } from './../../../classes/field-materials/asset-transfer.class';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { LoadingController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  AssetCategory,
  CustomerAsset,
} from '@omni/classes/field-materials/customer-asset.class';
import { IONote } from '@omni/classes/io/io-note.class';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import {
  CurViewPageType,
  DateTimeFieldType,
  IndDatetimeFormComponent,
} from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import {
  FormFieldType,
  IndFormFieldViewDataModel,
} from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import {
  AccountOfflineService,
  AccountSelectedFor,
} from '@omni/services/account/account.offline.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsService } from '@omni/services/events/events.service';
import { FieldMaterialManagementService } from '@omni/services/field-materials/field-material-management.service';
import {
  FooterService,
  FooterViews,
} from '@omni/services/footer/footer.service';
import { TrackingEventNames } from '@omni/services/logging/tracking.service';
import {
  NavigationService,
  PageName,
} from '@omni/services/navigation/navigation.service';
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import {
  checkTimeConflictWithOtherActivities,
  removeConflictIdFromOtherActivities,
} from '@omni/utility/activity.utility';
import {
  MAXIMUM_NOTE_ATTACHMENT_SIZE,
  NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX,
  toBase64,
} from '@omni/utility/util';
import { addMinutes, format, isBefore, isSameDay } from 'date-fns';
import _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'asset-details',
  templateUrl: 'asset-details.html',
  styleUrls: ['asset-details.scss'],
})
export class AssetDetailsComponent {
  public titleBar: IndPageTitleViewDataModel;
  @Input() selectedAsset: CustomerAsset;
  public categoryViewData: IndFormFieldViewDataModel;
  public productViewData: IndFormFieldViewDataModel;
  public productCodeViewData: IndFormFieldViewDataModel;
  public serialNoViewData: IndFormFieldViewDataModel;
  public parentAssetViewData: IndFormFieldViewDataModel;
  public accountViewData: IndFormFieldViewDataModel;
  public contactViewData: IndFormFieldViewDataModel;
  public statusFieldViewData: IndFormFieldViewDataModel;
  public newAssetOwnerFieldViewData: IndFormFieldViewDataModel;
  public newAssetLocationFieldViewData: IndFormFieldViewDataModel;
  public newAssetLocationStartDateFieldViewData: IndDateTimeFormViewDataModel;
  public newAssetLocationEndDateFieldViewData: IndDateTimeFormViewDataModel;
  public newAssetLocationContractFieldViewData: IndFormFieldViewDataModel;
  private readonly notificationService: NotificationService;
  public notesSectionHeaderViewData: IndSectionHeaderViewDataModel;
  @ViewChild('attachInput') attachInput: ElementRef;
  public notesPlaceholder = '';
  public tempNoteText = '';
  public isAttachmentAdded = false;
  public attachmentTitle = '';
  private attachmentFile: any;
  private base64str;
  public assetNotes: Array<IONote> = [];
  public transferDetailsHeaderModel: IndSectionHeaderViewDataModel;
  private confirmEnabled: boolean = false;

  constructor(
    public device: DeviceService,
    private accountService: AccountOfflineService,
    private readonly _cd: ChangeDetectorRef,
    public fieldMaterialManagementService: FieldMaterialManagementService,
    public footerService: FooterService,
    private readonly navService: NavigationService,
    private readonly popoverCtrl: PopoverController,
    public translate: TranslateService,
    private readonly loadingCtrl: LoadingController,
    private readonly authService: AuthenticationService,
    private readonly datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
    public uiService: UIService,
    private readonly events: EventsService,
    public activityService: ActivityService,
    private readonly alertService: AlertService
  ) {}

  ngOnInit() {
    this._initAssetStaticViewData();
    this._initAssetRemarksViewData();
    this._initPageTitle();
    this._initAssetNotesData();
    this.transferDetailsHeaderModel = {
      id: 'transfer-details-header',
      title: this.translate.instant('TRANSFER_DETAILS'),
      controls: [],
    };
    if (this.selectedAsset.ownerid == this.authService.user.systemUserID) {
      this.footerService.initButtons(FooterViews.ASSET_DETAILS);
    } else {
      this.footerService.initButtons(FooterViews.None);
    }
  }

  private _initPageTitle() {
    let controls = [];
    if (this.selectedAsset.ownerid == this.authService.user.systemUserID) { 
      controls = [
        {
          id: 'cancelassetdeployment',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('ASSET_CANCEL_DEPLOYMENT'),
          isDisabled: !this.isAssetDelpoymentBtnEnabled,
          align: 'right',
        },
        {
          id: 'transferasset',
          imgSrc: 'assets/imgs/header_transfer.svg',
          name: this.translate.instant('TRANSFER'),
          isDisabled: this.transferDisabled,
          align: 'right',
        },
        {
          id: 'confirmtransfer',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('CONFIRM'),
          isDisabled: this.confirmDisabled,
          align: 'right',
        },
      ]
    }
    this.titleBar = {
      id: 'asset-details',
      title: this.selectedAsset.msdyn_name,
      controls: controls,
    };
  }

  get transferDisabled(): boolean {
    return (
      this.device.isOffline ||
      this.selectedAsset.indskr_approvalrequired ||
      this.selectedAsset.indskr_acknowledgerequired ||
      this.selectedAsset.indskr_customerconfirmation
    );
  }

  get confirmDisabled(): boolean {
    return this.device.isOffline || !this.confirmEnabled;
  }

  private _initAssetStaticViewData() {
    this.categoryViewData = {
      label: this.translate.instant('CATEGORY'),
      inputText: this.selectedAsset.categoryName,
      id: 'category-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.selectedAsset.categoryName,
    };
    this.productViewData = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.selectedAsset.productName,
      id: 'product-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.selectedAsset.productName,
    };
    this.productCodeViewData = {
      label: this.translate.instant('PRODUCT_CODE'),
      inputText: this.selectedAsset.indskr_productcode,
      id: 'product-code-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.selectedAsset.indskr_productcode,
    };
    this.serialNoViewData = {
      label: this.translate.instant('SERIAL_NUMBER'),
      inputText: this.selectedAsset.indskr_serialnumber,
      id: 'serial-number-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.selectedAsset.indskr_serialnumber,
    };
    this.parentAssetViewData = {
      label: this.translate.instant('PARENT_ASSET'),
      inputText: this.selectedAsset.parentAssentName,
      id: 'parent-asset-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.selectedAsset.parentAssentName,
    };
    this.accountViewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: _.isEmpty(this.selectedAsset.accountName) ? this.translate.instant('NO_ACCOUNT') : this.selectedAsset.accountName,
      id: 'account-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.contactViewData = {
      label: this.translate.instant('CONTACT'),
      inputText: this.selectedAsset.contactName,
      id: 'contact-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.selectedAsset.contactName,
    };
    this.statusFieldViewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.selectedAsset.indskr_assetstatus,
      inputValue: this.selectedAsset.indskr_assetstatus,
      id: 'status-field',
      isReadOnly: true,
      isDisabled: this.device.isOffline,
      formFieldType: FormFieldType.POPOVER_SELECT,
      showArrow: !this.device.isOffline,
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    };
    this._initAccountManagerFormField();
    this._locationRequestFormFields();
  }

  private _initAccountManagerFormField() {
    this.newAssetOwnerFieldViewData = {
      label: this.translate.instant('NEW_ASSET_OWNER'),
      inputText: this.selectedAsset.indskr_tobeassetowner_name
        ? this.selectedAsset.indskr_tobeassetowner_name
        : '',
      id: 'new_asset_owner',
      isReadOnly: true,
      isDisabled:
        !this.transferDisabled &&
        !_.isEmpty(this.selectedAsset.indskr_newassetlocation),
      customPlaceholderLabel: _.isEmpty(
        this.selectedAsset.indskr_tobeassetowner
      )
        ? this.translate.instant('SELECT_NEW_ASSET_OWNER')
        : '',
      showArrow: !this.transferDisabled,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      errorMessage: '',
      isEmptyRequiredField: _.isEmpty(this.selectedAsset.indskr_tobeassetowner),
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private _locationRequestFormFields() {
    this.newAssetLocationFieldViewData = {
      label: this.translate.instant('NEW_ASSET_LOCATION'),
      inputText: this.selectedAsset.indskr_newassetlocation_value
        ? this.selectedAsset.indskr_newassetlocation_value
        : '',
      id: 'new_asset_location',
      isReadOnly: true,
      isDisabled:
        !this.transferDisabled &&
        !_.isEmpty(this.selectedAsset.indskr_tobeassetowner),
      customPlaceholderLabel: _.isEmpty(
        this.selectedAsset.indskr_newassetlocation
      )
        ? this.translate.instant('SELECT_NEW_ASSET_LOCATION')
        : '',
      showArrow: !this.transferDisabled,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: false,
      errorMessage: '',
      isEmptyRequiredField: _.isEmpty(
        this.selectedAsset.indskr_newassetlocation
      ),
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    };
    this.newAssetLocationStartDateFieldViewData = {
      isDateField: true,
      label: this.translate.instant('NEW_ASSET_LOCATION_START_DATE'),
      customPlaceholderLabel: this.translate.instant(
        'SELECT_NEW_ASSET_LOCATION_START_DATE'
      ),
      inputText: this.selectedAsset.indskr_newlocationstartdate
        ? this.datePipe.transform(
            this.selectedAsset.indskr_newlocationstartdate,
            this.dateTimeFormatsService.date,
            undefined,
            this.translate.currentLang
          )
        : '',
      fromViewPage: CurViewPageType.Asset,
      id: DateTimeFieldType.StartDateField,
      startDateTimeValue: this.selectedAsset.indskr_newlocationstartdate
        ? moment(this.selectedAsset.indskr_newlocationstartdate).format()
        : '',
      endDateTimeValue: this.selectedAsset.indskr_newlocationenddate
        ? moment(this.selectedAsset.indskr_newlocationenddate).format()
        : '',
      isReadOnly: true,
      isDisabled: _.isEmpty(this.selectedAsset.indskr_newassetlocation),
      showArrow: !this.transferDisabled,
      isRequired: !_.isEmpty(this.selectedAsset.indskr_newassetlocation),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    };

    this.newAssetLocationEndDateFieldViewData = {
      isDateField: true,
      label: this.translate.instant('NEW_ASSET_LOCATION_END_DATE'),
      customPlaceholderLabel: this.translate.instant(
        'SELECT_NEW_ASSET_LOCATION_END_DATE'
      ),
      inputText: this.selectedAsset.indskr_newlocationenddate
        ? this.datePipe.transform(
            this.selectedAsset.indskr_newlocationenddate,
            this.dateTimeFormatsService.date,
            undefined,
            this.translate.currentLang
          )
        : '',
      fromViewPage: CurViewPageType.Asset,
      id: DateTimeFieldType.EndDateField,
      startDateTimeValue: this.selectedAsset.indskr_newlocationstartdate
        ? moment(this.selectedAsset.indskr_newlocationstartdate).format()
        : '',
      endDateTimeValue: this.selectedAsset.indskr_newlocationenddate
        ? moment(this.selectedAsset.indskr_newlocationenddate).format()
        : '',
      isReadOnly: true,
      isDisabled: _.isEmpty(this.selectedAsset.indskr_newassetlocation),
      showArrow: !this.transferDisabled,
      isRequired: !_.isEmpty(this.selectedAsset.indskr_newassetlocation) && (this.selectedAsset.indskr_assetscategory === AssetCategory.LOANER || !this.authService.user.enddateautocalculation.includes(this.selectedAsset.indskr_assetscategory.toString())) ,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    };

    this.newAssetLocationContractFieldViewData = {
      label: this.translate.instant('NEW_ASSET_LOCATION_CONTRACT'),
      inputText: this.selectedAsset.contractName
        ? this.selectedAsset.contractName
        : '',
      id: 'new_asset_location_contract',
      isReadOnly: true,
      isDisabled: _.isEmpty(this.selectedAsset.indskr_newassetlocation),
      customPlaceholderLabel: _.isEmpty(
        this.selectedAsset.indskr_tobeassetowner
      )
        ? this.translate.instant('SELECT_NEW_ASSET_LOCATION_CONTRACT')
        : '',
      showArrow: !this.transferDisabled,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: !_.isEmpty(this.selectedAsset.indskr_newassetlocation),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: _.isEmpty(this.selectedAsset.contractName),
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private async handleLocationSelection() {
    this.uiService.showNewActivity = false;
    this.accountService.selectedFor =
      AccountSelectedFor.ASSET_LOCATION_SELECTION;
    this.accountService.accessedAccountListFrom =
      PageName.AssetDetailsComponent;
    this.navService.pushWithPageTracking(
      AccountPageComponent,
      PageName.AccountPageComponent,
      {
        listMode: ComponentViewMode.ADDNEW,
        type: 'PushedAccountSelectionView',
        callbackEvent: (data: any) =>
          this._handleAccountComponentCallback(data),
      },
      PageName.AssetDetailsComponent,
      { animate: false },
      null
    );
  }

  private async _handleAccountComponentCallback(data) {
    if (data) {
      if (data.selectedItems) {
        this.selectedAsset = data.selectedItems as CustomerAsset;
        if (!this.selectedAsset.indskr_newassetlocation) {
          this.selectedAsset.indskr_newlocationstartdate = null;
          this.selectedAsset.indskr_newlocationenddate = null;
          this.selectedAsset.contractId = null;
          this.selectedAsset.contactName = null;
        }
        await this.canMapContracts();
        this._locationRequestFormFields();
        this.checkAllLocationFieldsHasData();
        this._initPageTitle();
        this.footerService.initButtons(FooterViews.ASSET_DETAILS);
      }
      this._initAccountManagerFormField();
    }
  }

  private checkAllLocationFieldsHasData() {
    const asset: CustomerAsset = this.selectedAsset;
    if (
      asset.indskr_newassetlocation &&
      asset.indskr_newlocationstartdate &&
      (asset.indskr_newlocationenddate || this.authService.user.enddateautocalculation.includes(this.selectedAsset.indskr_assetscategory.toString()))
    ) {
      this.confirmEnabled = true;
      if (asset.indskr_assetscategory == AssetCategory.LOANER) {
        this.confirmEnabled = !!asset.contractId;
      } else {
        this.confirmEnabled = true;
      }
    }
    if (this.confirmEnabled) {
      this.footerService.enableButtons(['confirmtransfer']);
      this._initPageTitle();
    }
  }

  private handleFormFieldEvent(id, event, eventName) {
    switch (id) {
      case 'status-field':
        if (event) {
          this.selectStatusPopover(event);
        }
        break;
      case 'new_asset_owner':
        this.selectNewAssetOwnerPopover(event);
        break;
      case 'new_asset_location':
        this.handleLocationSelection();
        break;
      case 'start-date-field':
        this.openStartDatePicker(event);
        break;
      case 'end-date-field':
        this.openEndDatePicker(event);
        break;
      case 'new_asset_location_contract':
        this.selectNewLocationContractsPopover(event);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public async selectNewAssetOwnerPopover(event) {
    if (this.transferDisabled) return;
    if (!this.fieldMaterialManagementService.amUsers.length) return;
    let options = [];
    this.fieldMaterialManagementService.amUsers.forEach((user) => {
      const isSelected: boolean =
        this.selectedAsset.indskr_tobeassetowner === user.systemuserid;
      options.push({
        id: user.systemuserid,
        title: user.fullname,
        isSelected,
        value: user.fullname,
      });
    });
    options = this.fieldMaterialManagementService.sortListByFieldName(
      options,
      'title'
    );
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'am-user-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.transferDisabled,
    };
    const dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail,
      },
      cssClass: 'am-user-list-view',
      event,
    });
    dropdownPopover.onDidDismiss().then(async (selData) => {
      if (selData && selData.data?.selectedItems) {
        if (
          this.selectedAsset.indskr_tobeassetowner !==
          selData.data.selectedItems[0].id
        ) {
          this.selectedAsset.indskr_tobeassetowner =
            selData.data.selectedItems[0].id;
          this.selectedAsset.indskr_tobeassetowner_name =
            selData.data.selectedItems[0].value;
          this.confirmEnabled = true;
          this._initAccountManagerFormField();
          this._locationRequestFormFields();
          this._initPageTitle();
          this.footerService.enableButtons(['confirmtransfer'], true);
        }
      }
    });
    dropdownPopover.present();
  }

  public async selectNewLocationContractsPopover(event) {
    if (this.transferDisabled) return;
    if (!this.fieldMaterialManagementService.locationContracts.length) return;
    let options = [];
    this.fieldMaterialManagementService.locationContracts.forEach(
      (contract) => {
        const isSelected: boolean =
          this.selectedAsset.contractId === contract.indskr_assetcontractid;
        options.push({
          id: contract.indskr_assetcontractid,
          title: contract.indskr_name,
          isSelected,
          value: contract.indskr_name,
        });
      }
    );
    options = this.fieldMaterialManagementService.sortListByFieldName(
      options,
      'title'
    );
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'location-contract-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.transferDisabled,
    };
    const dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail,
      },
      cssClass: 'location-contract-list-view',
      event,
    });
    dropdownPopover.onDidDismiss().then(async (selData) => {
      if (selData && selData.data?.selectedItems) {
        if (
          this.selectedAsset.contractId !== selData.data.selectedItems[0].id
        ) {
          this.selectedAsset.contractId = selData.data.selectedItems[0].id;
          this.selectedAsset.contractName = selData.data.selectedItems[0].value;
          this._locationRequestFormFields();
          this.checkAllLocationFieldsHasData();
          this._initPageTitle();
        }
      }
    });
    dropdownPopover.present();
  }

  private async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    const startDate: string = this.selectedAsset.indskr_newlocationstartdate
      ? moment(this.selectedAsset.indskr_newlocationstartdate).format()
      : null;
    const endDate: string = this.selectedAsset.indskr_newlocationenddate
      ? moment(this.selectedAsset.indskr_newlocationenddate).format()
      : null;
    const popover = await this.popoverCtrl.create({
      component: IndDatetimeFormComponent,
      componentProps: {
        currentViewPage: CurViewPageType.Asset,
        startDateTimeValue: startDate,
        endDateTimeValue: endDate,
      },
      cssClass: 'datetime-popover',
    });
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime != '') {
        const selectedStartDate = new Date(data.data.startTime);
        const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(
          selectedStartDate
        );
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getStartDate = this.datePipe.transform(
          selectedStartDate,
          'MMM dd, yyyy',
          undefined,
          'en-US'
        );
        const setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
        this.selectedAsset.indskr_newlocationstartdate = new Date(
          setCombinedStartDateTime
        ).toString();
        await this.canMapContracts();
        this._locationRequestFormFields();
        this.checkAllLocationFieldsHasData();
        this._initPageTitle();
      }
    });
    popover.present();
  }

  private async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    const startDate: string = this.selectedAsset.indskr_newlocationstartdate
      ? moment(this.selectedAsset.indskr_newlocationstartdate).format()
      : null;
    const endDate: string = this.selectedAsset.indskr_newlocationenddate
      ? moment(this.selectedAsset.indskr_newlocationenddate).format()
      : null;
    const popover = await this.popoverCtrl.create({
      component: IndDatetimeFormComponent,
      componentProps: {
        currentViewPage: CurViewPageType.Asset,
        startDateTimeValue: startDate,
        endDateTimeValue: endDate,
      },
      cssClass: 'datetime-popover',
    });
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
        const selectedEndDate = new Date(data.data.endTime);
        const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(
          selectedEndDate
        );
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getEndDate = this.datePipe.transform(
          selectedEndDate,
          'MMM dd, yyyy',
          undefined,
          'en-US'
        );
        const setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
        this.selectedAsset.indskr_newlocationenddate = new Date(
          setCombinedEndDateTime
        ).toString();
        await this.canMapContracts();
        this._locationRequestFormFields();
        this.checkAllLocationFieldsHasData();
        this._initPageTitle();
      }
    });
    popover.present();
  }

  private async canMapContracts() {
    const asset: CustomerAsset = this.selectedAsset;
    if (
      asset.indskr_assetscategory == AssetCategory.LOANER &&
      asset.indskr_newassetlocation &&
      asset.indskr_newlocationstartdate &&
      asset.indskr_newlocationenddate
    ) {
      let startDate = moment(this.selectedAsset.indskr_newlocationstartdate).format('YYYY-MM-DD');
      let endDate = moment(this.selectedAsset.indskr_newlocationenddate).format(
        'YYYY-MM-DD'
      );
      await this.uiService.displayLoader();
      await this.fieldMaterialManagementService
        .getLocationContracts(
          this.selectedAsset.indskr_newassetlocation,
          startDate,
          endDate
        )
        .then(() => {
          if (
            !_.isEmpty(this.fieldMaterialManagementService.locationContracts)
          ) {
            if (
              this.fieldMaterialManagementService.locationContracts.length == 1
            ) {
              asset.contractId = this.fieldMaterialManagementService.locationContracts[0].indskr_assetcontractid;
              asset.contractName = this.fieldMaterialManagementService.locationContracts[0].indskr_name;
            }
          } else {
            asset.contractId = null;
            asset.contractName = null;
            this.notificationService.notify(this.translate.instant("NO_CONTRACTS_FOUND_ERR_MESSAGE"), 'AssetDetails', 'top', ToastStyle.DANGER);
          }
        })
        .catch((e) => {
          console.log('error fetching contracts');
        });
      await this.uiService.dismissLoader();
    }
  }

  private async _createTransferRequest() {
    await this.uiService.displayLoader();
    let isLocationFlow = true;
    if (this.selectedAsset.indskr_tobeassetowner) {
      isLocationFlow = false;
    }
    await this.fieldMaterialManagementService
      .createTransferRequest(this.selectedAsset, isLocationFlow)
      .then(() => {
        this.selectedAsset.indskr_approvalrequired = true;
        this.selectedAsset.transferEnabled = false;
        this.confirmEnabled = false;
        this._initAccountManagerFormField();
        this._initPageTitle();
        this.footerService.disableButton(['transferasset', 'confirmtransfer', 'cancelassetdeployment']);
      })
      .catch((err) => {
        console.error('failed creating transfer request');
      });
    await this.uiService.dismissLoader();
  }

  get isAssetDelpoymentBtnEnabled(): boolean {
    return !this.device.isOffline && !_.isEmpty(this.selectedAsset.msdyn_account) && this.selectedAsset.latestTransfer && this.selectedAsset.latestTransfer.isLocationChange && this.selectedAsset.latestTransfer.statuscode == AssetTrasferStatus.Completed
  }

  private async _cancelAssetDeployment() {
    if (!this.isAssetDelpoymentBtnEnabled) return;
    const endDate = this.selectedAsset.latestTransfer.new_location_enddate ? this.datePipe.transform(this.selectedAsset.latestTransfer.new_location_enddate, this.dateTimeFormatsService.date, null, this.translate.currentLang) : '';
    this.alertService.showAlert({
      title: this.translate.instant('ASSET_CANCEL_DEPLOYMENT'),
      message: this.translate.instant('CANCEL_DEPLOYMENT_MSG', { assetName: this.selectedAsset.msdyn_name, currentLocation: this.selectedAsset.accountName, endDate: endDate })
    }, this.translate.instant('OK')
    ).then(async res => {
      if (res.role === 'ok') {
        await this.uiService.displayLoader();
        await this.fieldMaterialManagementService
          .cancelAssetDeployment(this.selectedAsset)
          .then(() => {
            this.selectedAsset.msdyn_account = this.selectedAsset.accountName = this.selectedAsset.latestTransfer = null;
            this._initPageTitle();
            this._initAssetStaticViewData();
            this.footerService.initButtons(FooterViews.ASSET_DETAILS);
            this.events.publish('assetDeploymentCancelled', this.selectedAsset);
          })
          .catch((err) => {
            console.error('failed creating transfer request');
          });
        await this.uiService.dismissLoader();
      }
    });
  }

  private async selectStatusPopover(event) {
    const selectListData = [];
    this.fieldMaterialManagementService.getAssetStatus().forEach((as) => {
      const selectedData = {
        title: as.assetStatusValue,
        id: as.assetStatusValue,
        isSelected:
          as.assetStatusValue === this.selectedAsset.indskr_assetstatus,
        value: as.assetStatusKey,
      };
      selectListData.push(selectedData);
    });

    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'asset-status-field',
      data: selectListData,
    };

    const popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event,
    });
    await popover.present();
    await popover.onDidDismiss().then(async (selData: any) => {
      if (
        selData &&
        selData.data?.selectedItems &&
        this.selectedAsset.indskr_assetstatus !==
          selData.data.selectedItems[0].id
      ) {
        const payload = {
          assetStatus: selData.data.selectedItems[0].value,
        };
        await this.uiService.displayLoader();
        this.fieldMaterialManagementService
          .updateCustomerAsserDetail(
            payload,
            this.selectedAsset.msdyn_customerassetid,
            selData.data.selectedItems[0].id
          )
          .then((data) => {
            this.selectedAsset.indskr_assetstatus =
              selData.data.selectedItems[0].id;
            this.selectedAsset.indskr_assetstatus_value =
              selData.data.selectedItems[0].value;
            this._initAssetStaticViewData();
          })
          .catch((err) => {
            console.error('updating asset detail failed');
          });
        await this.uiService.dismissLoader();
      }
    });
  }

  public handleButtonClick(id: string) {
    switch (id) {
      case 'close':
      case 'cancel':
        this.closePage();
        break;
      case 'transferasset':
        this.transferAsset();
        break;
      case 'confirmtransfer':
        this._createTransferRequest();
        break;
      case 'cancelassetdeployment':
        this._cancelAssetDeployment();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private transferAsset() {
    if (this.device.isOffline) return;
    this.selectedAsset.transferEnabled = true;
  }

  private _initAssetNotesData() {
    this.assetNotes = [];
    if (this.selectedAsset?.notes) {
      this.selectedAsset.notes.forEach((cn) => {
        if (this.selectedAsset.msdyn_customerassetid == cn.customerassetid) {
          const payload = {
            annotationid: cn.annotationid,
            activityid: cn.customerassetid,
            createdon: cn.createdon ? cn.createdon.toString() : null,
            notetext: cn.notetext,
            ownerName:
              cn.ownerid != cn.modifiedby ? cn.modifiedbyname : cn.ownerName,
            ownerid: cn.ownerid,
            filename: cn.filename,
            filesize: cn.filesize,
            isdocument: cn.isdocument,
            mimetype: cn.mimetype,
          };
          const currentNote = new IONote(payload);
          this.assetNotes.push(currentNote);
        }
      });
    }
    this._sortCustomerNotes();
  }

  private _sortCustomerNotes() {
    if (!_.isEmpty(this.assetNotes)) {
      this.assetNotes = this.assetNotes.sort((a, b) => {
        return isBefore(a.createdTime, b.createdTime) ? 1 : -1;
      });
    }
  }

  private closePage() {
    this.navService.popChildNavPageWithPageTracking();
    this.navService.setChildNavRightPaneView(false);
    this.fieldMaterialManagementService.selectedAsset = null;
  }

  private _initAssetRemarksViewData() {
    const controls: any[] = [
      {
        id: 'attach_note',
        text: this.translate.instant('ATTACH'),
        isDisabled: this.device.isOffline,
      },
      {
        id: 'save_note',
        text: this.translate.instant('SAVE'),
        isDisabled:
          _.isEmpty(this.tempNoteText) &&
          !this.isAttachmentAdded &&
          !this.device.isOffline,
      },
    ];
    this.notesSectionHeaderViewData = {
      id: 'asset_remarks_section_header',
      title: this.translate.instant('REMARKS'),
      controls,
    };
    this.notesPlaceholder = this.translate.instant('ENTER_NOTES');
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'save_note':
        this.handleSaveNote();
        break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public async handleSaveNote() {
    if (!_.isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      const loader = await this.loadingCtrl.create();
      loader.present();
      const now = new Date().getTime().toString();
      const offlinePayload = {
        annotationid: `offlineassetnote${now}`,
        createdon: now,
        activityid: this.selectedAsset.msdyn_customerassetid,
        notetext: this.tempNoteText,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
        deleted: false,
        pendingPushOnDynamics: true,
      };

      if (this.base64str) {
        offlinePayload['isdocument'] = true;
        offlinePayload['documentbody'] = this.base64str;
        offlinePayload['filename'] = this.attachmentFile.name;
        offlinePayload['filesize'] = this.attachmentFile.size;
        offlinePayload['mimetype'] = this.attachmentFile.type;
      }

      const note = new IONote(offlinePayload);
      if (!this.device.isOffline) {
        const payload = {
          customerAssetId: note.activityId,
          createdon: note.createdTime.getTime(),
          notetext: note.noteText,
          ownerid: note.ownerId,
          filename: note.documentName,
          filesize: note.documentSize,
          documentbody: note.documentDataURL,
          mimetype: note.documentMimeType,
        };
        await this.fieldMaterialManagementService
          .uploadAssetNotesOnline(payload)
          .then((res) => {
            if (res) {
              // this.selectedAsset.notes.push(res);
              // this.assetNotes.push(res);
            }
          })
          .catch((err) => console.error(err));
      }
      this._initAssetNotesData();
      this.tempNoteText = '';
      this.removeAttachment(null);
      this._initAssetRemarksViewData();
      loader.dismiss();
    }
  }

  public isNoteEditable(note: IONote): boolean {
    return (
      !this.device.isOffline &&
      _.isEqual(note.ownerId, this.authService.user.systemUserID)
    );
  }

  private handleNoteAttachment() {
    try {
      this.attachInput.nativeElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  public notesChanged(ev): void {
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
    } else {
      this.tempNoteText = '';
    }
    this._initAssetRemarksViewData();
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      await this.uiService.displayLoader();
      const idx = this.assetNotes.findIndex((note) => note.noteId == ev.noteId);
      if (idx < 0) {
        console.error("Error : coundn't find note id --> ", ev.noteId);
        return;
      }
      const existingNote = this.assetNotes[idx];
      if (ev.action == 'DELETE' && idx >= 0 && existingNote) {
        await this.fieldMaterialManagementService
          .removeNotes(existingNote.noteId)
          .then(() => {
            this.assetNotes.splice(idx, 1);
            this._initAssetNotesData();
          })
          .catch((er) => console.error(er));
      } else if (ev.action === 'SAVE' && idx >= 0 && existingNote) {
        const payload: any = {
          noteid: existingNote.noteId,
        };
        existingNote.noteText = payload.notetext = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          existingNote.hasDocument = payload.isdocument = true;
          existingNote.documentDataURL = payload.documentbody =
            ev.attachmentFileDataUrl;
          existingNote.documentName = payload.filename = ev.documentName;
          existingNote.documentSize = payload.filesize = ev.documentSize;
          existingNote.documentMimeType = payload.mimetype =
            ev.documentMimeType;
        }
        if (ev.isAttachmentRemoved) {
          existingNote.hasDocument = payload.isdocument = false;
          existingNote.documentName = '';
          existingNote.documentDataURL = '';
          existingNote.documentMimeType = '';
          existingNote.documentSize = 0;
          payload.documentbody = payload.filename = payload.mimetype = '';
          payload.filesize = 0;
        }
        await this.fieldMaterialManagementService
          .updateAssetNoteOnline(payload, existingNote)
          .then((nt) => {
            this._initAssetNotesData();
          })
          .catch((err) => console.log(err));
      }
      await this.uiService.dismissLoader();
    } else {
      console.log('empty action for updating notes in asset');
    }
  }

  public removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    this._initAssetRemarksViewData();
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        const file = event.target.files[0];
        if (file.size / 1000 < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this._initAssetRemarksViewData();
          } else {
            this.notificationService.notify(
              this.translate.instant(
                'NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'
              ),
              'Asset Detail',
              'top',
              'info'
            );
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(
            this.translate.instant(
              'MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',
              { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }
            ),
            'Asset Detail',
            'top',
            'info'
          );
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  ngOnDestroy() {
  }
}
