import { AccountPlan, AccountPlanObjectiveReport, CreateProgressReportRequestBody, AccountPlanObjective, AccountPlanMode } from './../../classes/account-management/account-plan.class';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from 'rxjs';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import * as _ from 'lodash';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../events/events.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { PopoverController } from '@ionic/angular';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { format } from 'date-fns';
import { IONote } from '@omni/classes/io/io-note.class';

/**
 * Offline data service for Account Management
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class AccountManagementOfflineService {

    //private _accountManagementNav:NavController;
    public isRightPaneNavActive:boolean = false;
    public accountPlans$:Subject<AccountPlan[]> = new Subject();
    public selectedAccountPlan$:BehaviorSubject<AccountPlan> = new BehaviorSubject(null);
    public accountPlans: AccountPlan[] = [];
    public allProgressReports:Array<AccountPlanObjectiveReport> = [];
    public allProgressReports$: Subject<AccountPlanObjectiveReport[]> = new Subject();
    public currencySymbol: string;
    public currentAccountPlan:AccountPlan;
    public selectedMode:AccountPlanMode;
    public tempReqPayload:any;
    public selectedForGoalTab:AccountPlan;
    
    constructor(
      public disk: DiskService,
      public alertService : AlertService,
      public translate : TranslateService,
      public events : EventsService,
      public popoverCtrl : PopoverController,
      // public accountManagementDataService:  AccountManagementDataService
    ){}

    public upsertLocalObjectProgressReport(report:AccountPlanObjectiveReport):void {
      if(report.reportId){
        let index = this.allProgressReports.findIndex(rep => rep.reportId == report.reportId);
        if(index >=0 ){
          if(report.trackAction == 'Remove'){
            this.allProgressReports[index].state = 1;
            this.allProgressReports[index].statusCode = 2;
          }
          else{
            this.allProgressReports[index] = report;
          }
        }else if(report.trackAction !='Remove'){
            this.allProgressReports.push(report);
        }
      }
      else if(report.offlineId){
        let index = this.allProgressReports.findIndex(rep => rep.offlineId == report.offlineId);
        if(index >=0 ){
            this.allProgressReports[index] = report;
        }else{
            this.allProgressReports.push(report);
        }
      }
    }

    public loadOfflineReports():Promise<any> {
      return new Promise(async (resolve,reject)=> {
          try {
              // Fetch from DB
              const offlineReports = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT);
              if(offlineReports && Array.isArray(offlineReports.raw) && offlineReports.raw.length > 0){
                  let rawreportspayload = offlineReports.raw.filter(o=>o.syncPending)
                  rawreportspayload = rawreportspayload.map(rawreport => {
                    let rept = new CreateProgressReportRequestBody(rawreport);
                    if(rawreport.reportId){
                      rept['indskr_accountplanprogressreportid'] = rawreport.reportId
                    }
                    return rept
                  });
                  // Track offline data count
                  this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROGRESS_REPORT, rawreportspayload.length);
                  resolve(rawreportspayload);
              }else{
                // Track offline data count
                  this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROGRESS_REPORT, 0);
                  resolve('');
              }
          }catch(error){
              reject('Error occured while fetching reports data from offline db');
          }
      });
    }

    public handleOfflinePushResponse(rawResponse:any):Promise<any>{
      return new Promise(async (resolve,reject)=> {
        try {
          let updatedOfflineFollowups = [];
          let savedOfflineReports  = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT);
          for (var i = 0; i < rawResponse.length; i++) {
              let reportData = rawResponse[i];
              let idtobeused = (reportData.indskr_externalid)
              let currentReport
              if(savedOfflineReports && savedOfflineReports.raw && savedOfflineReports.raw.length){
                currentReport = <AccountPlanObjectiveReport>savedOfflineReports.raw.find((o)=>{
                  return o.offlineId == idtobeused
                })
              }
              let sessionCopyForReport = this.allProgressReports.find((o)=>{
                return o.offlineId = idtobeused
              });
              if (reportData.hasOwnProperty('indskr_accountplanprogressreportid')) {

                  // Update offline notes id
                  let notesArrayFromResponse = reportData['notes'];
                  let notes = [];
                  if ( notesArrayFromResponse && _.isArray(notesArrayFromResponse)) {
                    notesArrayFromResponse.map(noteFromResponse => {
                      if (noteFromResponse.hasOwnProperty('deleted') && !noteFromResponse['deleted']) {
                        let foundOwnerName = '';
                        let foundNote;
                        if(currentReport){
                          foundNote = currentReport.notes.find(a=> a.ownerId == noteFromResponse['ownerid']);
                        }
                        if(!foundNote && sessionCopyForReport){
                          foundNote = sessionCopyForReport.notes.find(a=> a.ownerId == noteFromResponse['ownerid']);
                        }
                        if(foundNote && foundNote.ownerName){
                          foundOwnerName = foundNote.ownerName
                        }
                        notes.push(new IONote({
                          annotationid: noteFromResponse['noteid'],
                          isdocument: (noteFromResponse.hasOwnProperty('filesize') && noteFromResponse['filesize'] > 0),
                          notetext: noteFromResponse['notetext'],
                          createdon: noteFromResponse['createdon'],
                          ownerid: noteFromResponse['ownerid'],
                          ownerName: foundOwnerName,
                          filename: noteFromResponse['filename'],
                          filesize: noteFromResponse['filesize'],
                          mimetype: noteFromResponse['mimetype'],
                          documentbody: noteFromResponse['documentbody'],
                          activityid: noteFromResponse['progressReportId'],
                          accountid: '',
                          contactid: '',
                          pendingPushForDynamics: false,
                          deleted: false,
                        }));
                      }
                    });
                  } 

                  if(currentReport){
                      currentReport.reportId = reportData.indskr_accountplanprogressreportid;
                      currentReport.syncPending = false;
                      currentReport.notes = notes;
                      // if( currentReport.statecode == 2 && savedOfflineObject.statuscode == 6){
                      //     savedOfflineObject._deleted = true;
                      // }
                      //updatedOfflineFollowups.push(savedOfflineObject);
                  }
                  if (sessionCopyForReport) {
                      sessionCopyForReport.reportId = reportData.indskr_accountplanprogressreportid;
                      sessionCopyForReport.syncPending = false;
                      sessionCopyForReport.notes = notes;
                  }

              }
              //handle errors reeived in response,if any
              //if(reportData.errorId)
          }
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT,(doc)=>{
            if (!doc || !doc.raw) {
              doc = {
                  raw: []
              };
            }
            doc = savedOfflineReports;
            return doc;
          });

          // Track offline data count
          const offlineDataCount: number = savedOfflineReports.raw.filter(r => r.syncPending === true).length;
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROGRESS_REPORT, offlineDataCount);
          resolve('')
        } catch (error) {
          reject(error)
        }
          // if(updatedOfflineFollowups.length > 0){
          //     try {
          //         // Bulk save docs to DB
          //         await this.disk.bulk(updatedOfflineFollowups);
          //         resolve('Successfully updated in offline DB');
          //     } catch (error) {
          //         reject('Error Occured while updating offline data'+error);
          //     }
          // }else{
          //     resolve('No follow up found to be updated');
          // }
      });
    }

  public getAccountPlanObjectiveByPlanId(id): Array<AccountPlanObjective> {
    let results: Array<AccountPlanObjective> = [];
    if (id) {
      let foundAccountPlan = this.accountPlans.find(ap => ap.ID == id);
      if (foundAccountPlan && foundAccountPlan.accountPlanObjectives) {
        results = foundAccountPlan.accountPlanObjectives;
      }
    }
    return results;
  }

  public getAccountPlanByPlanId(id: string): AccountPlan {
    let accountPlan: AccountPlan = undefined;
    accountPlan = this.accountPlans.find(ap => ap.ID == id);
    return accountPlan;
  }

  public async showConfirmAlert(): Promise<boolean> {
    return this.alertService.showAlert({
      header: this.translate.instant('ACCOUNTPLAN'),
      message: this.translate.instant('CANCEL_ACCOUNT_PLAN_EDIT')
    }, this.translate.instant('CONFIRM'), this.translate.instant('CANCEL')
    ).then(res => {
      if (res.role == "ok") {
        const accountPlan = _.cloneDeep(this.accountPlans.find(plan => plan.ID === this.currentAccountPlan.ID));
        this.selectedMode = AccountPlanMode.VIEW;
        this.selectedAccountPlan$.next(accountPlan);
        this.tempReqPayload = undefined;
        this.events.publish('account-field-change');
        return true
      }
      return false;
    });
  }

  async showCloneDropDown(id, event) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'activities-pane-more-options-select',
      showClearAllBtn: false,
      data: [{
        title: this.translate.instant('CLONE'),
        id: 'Clone',
        isSelected: false,
      }],
    };

    let target = {
      top: event.target.getBoundingClientRect().top,
      bottom: event.target.getBoundingClientRect().bottom,
      height: event.target.getBoundingClientRect().height,
      left: event.target.getBoundingClientRect().left,
      right: event.target.getBoundingClientRect().right,
      width: event.target.getBoundingClientRect().width,
      x: event.target.getBoundingClientRect().x,
      y: event.target.getBoundingClientRect().y,
    };

    let ev: any = {
      target: {
        getBoundingClientRect: () => {
          return target;
        }
      }
    };

    let moreOptionsPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: ev,
    });

    return moreOptionsPopover;
  }

  async cloneAccountPlan(id){

    const accountPlan = this.accountPlans.find(({ ID }) => ID === id);
    let reqPayload = {
      indskr_name: accountPlan.accountPlanName + '_cloned',
      accounts: [],
      contactAccountAffs: [],
      indskr_startdate: null,
      products: [],
      indskr_enddate: null,
      indskr_plannedbudget: accountPlan.indskr_plannedbudget,
      accountId: accountPlan.accountId
    };

    // accountPlan.accounts.forEach(element => {
    //   reqPayload.accounts.push({ accountId: element.id });
    // });

    accountPlan.contactAccountAffs.forEach(element => {
      reqPayload.contactAccountAffs.push({ indskr_accountcontactaffiliationid: element.indskr_accountcontactaffiliationid })
    });

    accountPlan.products.forEach(element => {
      reqPayload.products.push({ productId: element.productID })
    });

    if (accountPlan.startDate) {
      reqPayload.indskr_startdate = format(new Date(parseInt(accountPlan.startDate)), 'YYYY-MM-DD');
    }

    if (accountPlan.endDate){
      let endDate = new Date(parseInt(accountPlan.endDate));
      if(endDate.getTime() > new Date().getTime()) {
        reqPayload.indskr_enddate = format(endDate, 'YYYY-MM-DD');
      }
    } 

    return reqPayload;
  }


}

