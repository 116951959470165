<ion-header>
    <ind-page-title [viewData]="pageTitleHeader" (onControlClick)="onPageTitleControlClick($event)"></ind-page-title>
</ion-header>

<ion-content no-padding>
    <ion-list class="edge-analytics-details-list" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <ng-container *ngIf="selectedMeasure?.tileGroups && selectedMeasure?.tileGroups?.length > 0; else noConfig">
            <ind-section-header [viewData]="sectionHeader" [loader]="selectedMeasure?.isTileDataLoading$?.asObservable()" (onFilterClick)="openDateFilter($event)"></ind-section-header>

            <!-- Report Tiles -->
            <ind-report-group-tile *ngFor="let tile of selectedMeasure?.tileGroups; let last = last" [viewData]="tile" [ngClass]="{ 'last-group': last }"></ind-report-group-tile>

            <!-- Report Cards -->
            <ind-section-header *ngIf="cardSectionHeader?.visible else spacer" [viewData]="cardSectionHeader" (onFilterClick)="openDateFilter($event)"></ind-section-header>
            <ng-template #spacer>
              <div class="spacer"></div>
            </ng-template>

            <ion-grid *ngIf="selectedMeasure?.reportCards">
              <ion-row>
                <ion-col *ngFor="let cardData of selectedMeasure?.reportCards"
                          [size]="cardData.mobileCardSize"
                          [sizeSm]="cardData.cardSize">
                  <ind-report-card [cardData]="cardData"></ind-report-card>
                </ion-col>
              </ion-row>
            </ion-grid>

            <!-- Report Charts -->
            <ng-container *ngFor="let chartData of selectedMeasure?.charts">
                <div class="container-c chart-container" *ngIf="chartData?.chart">
                    <div class="chart-wrapper">
                        <div id="highchart" [chart]="chartData?.chart" style="display: block;" type="chart"> </div>
                        <div class="chart-filter-icon" *ngIf="chartData?.chartFilterTemplate" (click)="openChartFilter(chartData)">
                            <!-- <fa-icon [icon]="faEllipsisH"></fa-icon> -->
                            <i class="pi pi-ellipsis-h"></i>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #noConfig>
            <div class="no-configuration">{{'No Configuration'}}</div>
        </ng-template>
    </ion-list>

</ion-content>

<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'edgeAnalyticsDetail'" [fromWhichView]="'edge-analytics-detail'"></footer-toolbar>
<!-- </ion-footer> -->

<io-spinner *ngIf="(reportDataMgmService.loadingCounterObservable | async) > 0"></io-spinner>
