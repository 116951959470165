import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { TranslateService } from "@ngx-translate/core";
import { ActivityService } from "@omni/services/activity/activity.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DiskService } from "@omni/services/disk/disk.service";
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";
import { NotificationService } from "@omni/services/notification/notification.service";
import { OpportunityManagementService } from "@omni/services/opportunity-management/opportunity-management.service";
import { DeltaService, EntityNames, EntitySyncInfo } from "../delta/delta.service";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { FollowUpActivityDataService } from "../follow-up-activity/follow-up-activity.data.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from "@omni/config/pouch-db.config";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import moment from "moment";
import { MarketingPlansManagementOfflineService } from "@omni/services/marketing-management/marketing-management.service";
import { Currency, MarketingPlan } from "@omni/classes/marketing-management/marketing-plan.class";
import _ from "lodash";
import { IONote } from "@omni/classes/io/io-note.class";
import { Guid } from "typescript-guid";
import { Endpoints } from "src/config/endpoints.config";
import { Opportunity } from "@omni/classes/opportunity-management/opportunity.class";
import { EventActivity } from "@omni/classes/events-tool/event.class";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";
import { PhoneActivity } from "@omni/classes/activity/phone.activity.class";
import { ActivityType } from "@omni/classes/activity/activity.class";
import { FollowUpActivity, FOLLOW_UP_TYPE } from "@omni/classes/activity/follow-up-action.activity.class";
import { differenceInHours, isBefore, isEqual, isValid } from "date-fns";
import { Events } from '@omni/events';
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { Utility } from "@omni/utility/util";
import { SharedDataService } from "../shared-data/shared.data.service";


@Injectable({
  providedIn: 'root'
})

export class MarketingPlanManagementDataService {

  constructor(
    public dynamics: DynamicsClientService,
    public authService: AuthenticationService,
    // public accountmanagementOfflineService: AccountManagementOfflineService,
    public marketingmanagementOfflineService: MarketingPlansManagementOfflineService,
    public opportunityService: OpportunityManagementService,
    private activityService: ActivityService,
    private followupDataService: FollowUpActivityDataService,
    public disk: DiskService,
    public http: HttpClient,
    public notifyService: NotificationService,
    public translate: TranslateService,
    private deltaService: DeltaService,
    private readonly eventsToolService: EventsToolService,
    public events : Events,
    public dateService: DateTimeFormatsService,
    private  sharedDataService: SharedDataService,
  ) {

  }

  public async getMarketingBusinessPlans(fullSync?: boolean, brandPlanID?: string, loadFromDbOnly = false) {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)) return;
    if (loadFromDbOnly) {
      await this.loadOfflineDataForMarketingPlans();
    } else {
      const offlineDataStored = await this.loadOfflineDataForMarketingPlans();
      let positionString = '';
      this.authService.user.positions.map(o => {
        return o.ID
      }).forEach(p => {
        positionString += '<value>' + p + '</value>'
      });
      const now = new Date();
      let res = [];
      if (brandPlanID) {
        res = await this.fetchMarketingPlans(fullSync, brandPlanID, offlineDataStored?.lastModified, "", positionString);
      } else {
        // const responses = await Promise.all([
        //   this.fetchMarketingPlans(fullSync, brandPlanID, offlineDataStored?.lastModified, fetchQueries.marketingPlanManagement.accountPositionFilterCondition, positionString),
        //   this.fetchMarketingPlans(fullSync, brandPlanID, offlineDataStored?.lastModified, fetchQueries.marketingPlanManagement.productPositionFilterCondition, positionString),
        //   this.fetchMarketingPlans(fullSync, brandPlanID, offlineDataStored?.lastModified, fetchQueries.marketingPlanManagement.noAccountNoProductFilterCondition, positionString)
        // ]);
        // let fetchXML = fetchQueries.marketingPlanManagement.fetchMarketingBusinessPlansbasedBU;
        res = await this.fetchMarketingPlans(fullSync, brandPlanID,  offlineDataStored?.lastModified, "", positionString);
        // if (responses) {
        //   try {
        //     responses.forEach(resp => res.push(...resp));
        //   } catch (error) {
        //     console.log('errorr',error);
            
        //   }
          
        // }
      }
      await this.aggregateMarketingBusinessPlans(res, fullSync, brandPlanID);
      if (brandPlanID) {
        let planIndex = this.marketingmanagementOfflineService.marketingPlans.findIndex(o => o.ID == brandPlanID);
        if (planIndex > -1) {
          this.marketingmanagementOfflineService.marketingPlans[planIndex].lastUpdated = now.getTime()
        }
        this.saveMarketingBusinessPlanInDB();
        this.marketingmanagementOfflineService.selectedMarketingPlan$.next(this.marketingmanagementOfflineService.marketingPlans[planIndex]);
        this.events.publish('market-plan-update',this.marketingmanagementOfflineService.marketingPlans[planIndex]);
      } else {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.MARKETING_PLANS, (doc) => {
          doc = {
            raw: []
          };
          doc.raw = this.marketingmanagementOfflineService.marketingPlans;
          doc.raw.map(r => {
            r.lastUpdated = now.getTime();
          })
          doc.lastModified = now.getTime();
          return doc;
        })
        this.marketingmanagementOfflineService.marketingPlans$.next(this.marketingmanagementOfflineService.marketingPlans);
      }
    }
  }

  private async fetchMarketingPlans(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string): Promise<any> {
    // let fetchXML = fetchQueries.marketingPlanManagement.fetchMarketingBusinessPlans.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    
    filterCondition = `<link-entity name="indskr_associationbutomarketingbusinessplan" from="indskr_marketingbusinessplan" to="indskr_brandplanid" link-type="inner" alias="ad">
    <filter type="and">
      <condition attribute="indskr_businessunit" operator="eq-businessid" />
    </filter>
  </link-entity>`


    let fetchXML = fetchQueries.marketingPlanManagement.fetchMarketingBusinessPlansbasedBU.replace("{filterCondition}", filterCondition);
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);
  //   filterCondition = ` <link-entity name="businessunit" from="indskr_marketingbusinessplan" to="indskr_brandplanid" link-type="inner" alias="ax">
  //   <filter type="and">
  //     <condition attribute="businessunitid" operator="eq-businessid" />
  //   </filter>
  // </link-entity>`


    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        /* Pouch db doesnt allow to store keyword starting with _ */
        this.removeUnderscoreFromDynamicsResp(res);
        const responses = await Promise.all([
          this.getContacts(fullSync, brandPlanID, lastModifiedTime, filterCondition, positionString),
          this.getEvents(fullSync, brandPlanID, lastModifiedTime, filterCondition, positionString),
          this.getAccounts(fullSync, brandPlanID, lastModifiedTime, filterCondition, positionString),
          this.getOpportunities(fullSync, brandPlanID, lastModifiedTime, filterCondition, positionString),
          this.getProducts(fullSync, brandPlanID, lastModifiedTime, filterCondition, positionString),
          this.getNotes(fullSync, brandPlanID, lastModifiedTime, filterCondition, positionString)
        ])
        if (responses) {
          responses.forEach(resp => res.push(...resp));
        }
        return res;
      }
    }).catch(er => {
      console.error("Failed to fetch marketing business plans: ", er);
    })
  }

  private removeUnderscoreFromDynamicsResp(res: any) {
    for (let i = 0; i < res.length; i++) {
      const rawCase = res[i];
      for (let key in res[i]) {
        if (key.charAt(0) === "_") {
          var a = key.substring(1, key.length);
          res[i][a] = rawCase[a] = res[i][key];
          delete res[i][key];
          delete rawCase[key];
        }
      }
    }
  }

  private async getNotes(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.marketingPlanManagement.fetchNotes.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);

    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        this.removeUnderscoreFromDynamicsResp(res);
        return res;
      }
      return [];
    }).catch(err => { console.error("Failed to fetch notes: ", err); return []; });
  }

  private async getContacts(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.marketingPlanManagement.fetchContacts.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);

    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        this.removeUnderscoreFromDynamicsResp(res);
        return res;
      }
      return [];
    }).catch(err => { console.error("Failed to fetch contacts: ", err); return []; });
  }

  private async getAccounts(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.marketingPlanManagement.fetchAccounts.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);

    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        this.removeUnderscoreFromDynamicsResp(res);
        return res;
      }
      return [];
    }).catch(err => { console.error("Failed to fetch accounts: ", err); return []; });
  }

  private async getProducts(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.marketingPlanManagement.fetchProducts.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);

    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        this.removeUnderscoreFromDynamicsResp(res);
        return res;
      }
      return [];
    }).catch(err => { console.error("Failed to fetch products: ", err); return []; });
  }

  private async getEvents(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.marketingPlanManagement.fetchEvents.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);

    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        this.removeUnderscoreFromDynamicsResp(res);
        return res;
      }
      return [];
    }).catch(err => { console.error("Failed to fetch events: ", err); return []; });
  }

  private async getOpportunities(fullSync: boolean, brandPlanID: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.marketingPlanManagement.fetchOpportunities.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatFetchXml(fullSync, fetchXML, brandPlanID, lastModifiedTime);

    return await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then(async (res) => {
      if (res) {
        this.removeUnderscoreFromDynamicsResp(res);
        return res;
      }
      return [];
    }).catch(err => { console.error("Failed to fetch opportunities: ", err); return []; });
  }

  private formatFetchXml(fullSync: boolean, fetchXML: string, brandPlanID: string, lastModifiedTime: any) {
    const statusCondition = `<condition attribute="statecode" value="1" operator="ne"/><condition attribute="statuscode" value="2" operator="ne"/>`;
    if (fullSync) {
      fetchXML = fetchXML.replace('{deltaSyncCondition}', '').replace('{statusCondition}', statusCondition).replace('{brandPlanIdCondition}', '');
    } else if (brandPlanID) {
      const brandPlanIdCondition = `<condition attribute="indskr_brandplanid" operator="eq" value="` + brandPlanID + `"/>`;
      fetchXML = fetchXML.replace('{brandPlanIdCondition}', brandPlanIdCondition).replace('{deltaSyncCondition}', '').replace('{statusCondition}', '');
    } else {
      const lastModifiedForDeltaSync = lastModifiedTime ? lastModifiedTime : '';
      let deltaSyncCondition;
      if (lastModifiedForDeltaSync) {
        const modifiedon = moment(lastModifiedForDeltaSync).format("YYYY-MM-DD");
        deltaSyncCondition = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
        fetchXML = fetchXML.replace('{statusCondition}', '');
      }
      else {
        deltaSyncCondition = '';
        fetchXML = fetchXML.replace('{statusCondition}', statusCondition);
      }
      fetchXML = fetchXML.replace('{deltaSyncCondition}', deltaSyncCondition).replace('{brandPlanIdCondition}', '');
    }
    // //fill up offline duration filter
    fetchXML = fetchXML.replace('{compareToDate}', this.getDateWithDuration());
    return fetchXML;
  }

  async getCurrencies(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      await this.loadOfflineCurrencies();
    } else {
      let fetchXML = fetchQueries.marketingPlanManagement.fetchCurrencies;
      await this.dynamics.executeFetchQuery('transactioncurrencies', fetchXML).then((res) => {
        if (res) {
          let currencies: Currency[] = [];
          for (let currency of res) {
            currencies.push(new Currency(currency));
          }
          this.saveCurrenciesInDB(currencies);
          this.marketingmanagementOfflineService.currencies = currencies;
        }
      })
    }
  }

  private async saveCurrenciesInDB(currencies: Currency[]) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CURRENCIES, (doc) => {
        doc = {
          raw: []
        };
        doc.raw = currencies;
        return doc;
      });
    } catch (error) {
      console.error('error from save currenct db',error);
    }
    
  }

  private async loadOfflineCurrencies() {
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.CURRENCIES, true).then((doc) => {
        this.marketingmanagementOfflineService.currencies = (doc && doc.raw) ? doc.raw : [];
      })
    } catch (err) {
      this.marketingmanagementOfflineService.currencies = [];
    }
  }

  async loadOfflineDataForMarketingPlans() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.MARKETING_PLANS, true).then((doc) => {
        if (doc?.raw) {
          this.marketingmanagementOfflineService.marketingPlans = doc.raw;
        } else {
          this.marketingmanagementOfflineService.marketingPlans = [];
        }
        offlineDataStored = doc;
      });
    }
    catch (er) {
      console.error("Failed to fetch marketing business plans from db!: ", er)
      this.marketingmanagementOfflineService.marketingPlans = [];
    }
    return offlineDataStored;
  }

  private getDateWithDuration() {
    const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
    let today = new Date();
    const maxEndDateUnixTimestamp = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - duration
    );
    let dateWithDuration = moment(maxEndDateUnixTimestamp).format('YYYY-MM-DD');
    return dateWithDuration;
  }

  public async saveMarketPlan(marketPlan, marketPlanId?: string): Promise<MarketingPlan> {
    if (!marketPlanId) {
      marketPlanId = Guid.create().toString();
    }
    if (marketPlan.indskr_validfrom) {
      marketPlan.indskr_validfrom = moment(marketPlan.indskr_validfrom).format("YYYY-MM-DD").toString();
    }
    if (marketPlan.indskr_validto) {
      marketPlan.indskr_validto = moment(marketPlan.indskr_validto).format("YYYY-MM-DD").toString();
    }
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketingBusineesPlan.SAVE_MARKET_BUSINESS_PLAN.replace('{marketPlanId}', marketPlanId);
    await this.http.put<any>(url, marketPlan, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    marketPlan.indskr_brandplanid = marketPlanId;
    return new MarketingPlan(marketPlan);
  }

  public async saveAttachments(payload, marketPlanId): Promise<void> {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketingBusineesPlan.SAVE_MARKET_BUSINESS_PLAN_ATTACHMETS.replace('{marketPlanId}', marketPlanId);
    const response = await this.http.put<any>(url, payload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    return response[0];
  }

  public async saveMarketingBusinessPlanInDB() {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.MARKETING_PLANS, (doc) => {
      doc = {
        raw: [],
        lastModified: doc && doc.lastModified ? doc.lastModified : 0,
      };
      doc.raw = this.marketingmanagementOfflineService.marketingPlans;
      return doc;
    });
  }

  public async saveMarketingPlanInLocal(marketingPlan: MarketingPlan) {
    const index = this.marketingmanagementOfflineService.marketingPlans.findIndex(plan => plan.ID == marketingPlan.ID);
    if (index > -1) {
      this.marketingmanagementOfflineService.marketingPlans[index] = marketingPlan;
      await this.saveMarketingBusinessPlanInDB();
      return;
    }
    return;
  }

  private async aggregateMarketingBusinessPlans(brandPlansData, fullSync?: boolean, brandPlanID?: string) {
    let marketingPlans: MarketingPlan[] = [];
    brandPlansData.map(a => {
      if (a.indskr_brandplanid) {
        let brandPlan: MarketingPlan;
        brandPlan = marketingPlans.find(o => o.ID == a.indskr_brandplanid)
        if (!brandPlan) {
          brandPlan = new MarketingPlan(a);
          brandPlan.contacts = []
          brandPlan.products = []
          brandPlan.events = []
          brandPlan.opportunities = []
          brandPlan.MarketingPlansNotes = []
          brandPlan.associationBusinessUnits = []
          marketingPlans.push(brandPlan)
        }
        if (a.hasOwnProperty('pr.productid')) {
          let product = brandPlan.products.find(p => p.productID == a['pr.productid'])
          if (!product) {
            product = { productID: a['pr.productid'], productName: a['pr.name'] }
            brandPlan.products.push(product);
            brandPlan.products = _.orderBy(brandPlan.products, 'productName');
          }
        }

        if (a.hasOwnProperty('accountId')) {
          let account = brandPlan.accounts.find(ac => ac.id == a['accountId'])
          if (!account && a['accountStatecode'] == 0) {
            //Only active account
            account = { id: a['accountId'], name: a['accountName'], statecode: a['accountStatecode'] }
            brandPlan.accounts.push(account);
            brandPlan.accounts = _.orderBy(brandPlan.accounts, 'name');
          }
        }

        if (a.hasOwnProperty('eventid')) {
          let event = brandPlan.events.find(event => event.ID === a.eventid);
          if (!event) {
            let eventExists = a.eventStatusCode != 548910003 && a.eventStatusCode != 548910006; // event not Rejected and not Cancelled
            if (eventExists) {
              event = new EventActivity(
                {
                  msevtmgt_eventid: a.eventid,
                  msevtmgt_name: a.event_name,
                  statuscode: a.eventStatusCode,
                  "statuscode@OData.Community.Display.V1.FormattedValue": a["eventStatusCode@OData.Community.Display.V1.FormattedValue"],
                  "msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue": a["startDate@OData.Community.Display.V1.FormattedValue"]
                });
              delete event.created;
              delete event.scheduledStart;
              delete event.scheduledEnd;
              brandPlan.events.push(event);
              let sortedEvents = _.orderBy(brandPlan.events, [event => event.name.toLowerCase()]);
              brandPlan.events = sortedEvents;
            }

          }
        }

        if (a.hasOwnProperty('ab.opportunityid')) {
          let opportunities = brandPlan.opportunities.find(opp => opp.ID === a['ab.opportunityid']);
          if (!opportunities) {
            opportunities = new Opportunity({ opportunityid: a['ab.opportunityid'], name: a['ab.name'] });
            brandPlan.opportunities.push(opportunities);
          }
        }

        if (a.hasOwnProperty('ae.annotationid')) {
          const id = a['ae.annotationid']
          let note = brandPlan.MarketingPlansNotes.find(o => o.noteId == id)
          if (!note) {
            brandPlan.MarketingPlansNotes.push(new IONote({
              annotationid: id,
              activityid: '',
              contactid: '',
              accountid: '',
              createdon: new Date(a['ae.createdon']).getTime().toString(),
              notetext: a['ae.notetext'],
              ownerName: a['ae.ownerid@OData.Community.Display.V1.FormattedValue'],
              ownerid: a['ae.ownerid'],
              isdocument: a['ae.isdocument'],
              documentbody: '',
              filename: a['ae.filename'],
              filesize: a['ae.filesize'],
              mimetype: a['ae.mimetype'],
              isDeleted: false,
              pendingPushForDynamics: false,
              updated: false
            }));
            brandPlan.MarketingPlansNotes.sort((a, b) => {
              return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
            })
          }
        }

        if (a.hasOwnProperty('ownerid_value')) {
          brandPlan.ownerID = a['ownerid_value']
        }

        if (a.hasOwnProperty('ab.contactid')) {
          let contact = brandPlan.contacts.find(c => c.contactId == a['ab.contactid'])
          if (!contact) {
            contact = { contactId: a['ab.contactid'], contactFullName: a['ab.fullname'] }
            brandPlan.contacts.push(contact);
          }
        }

        if(a.hasOwnProperty('indskr_parentmarketingbusinessplan_value')){
          brandPlan.parentMarketingBusinessPlanId = a['indskr_parentmarketingbusinessplan_value'];
          brandPlan.parentMarketingBusinessPlanName = a['indskr_parentmarketingbusinessplan_value_Formatted'];
        }

        //created date
        if(a.hasOwnProperty('createdon')) {
          brandPlan.createdOnString = Utility.changeUTCDateToLocalDateWith0Time(new Date(a['createdon']).getTime(), true).getFullYear().toString();
        }
        //focus area
        if(a.hasOwnProperty('indskr_focusarea')) {
          brandPlan.focusArea = a['indskr_focusarea'];
        }
        //association business unit
        if(a.hasOwnProperty('bu.indskr_businessunit')) {
          let associationBu = brandPlan.associationBusinessUnits.find(bu => bu.associationBuID == a['bu.indskr_businessunit'])
          if (!associationBu) {
            associationBu = { associationBuMarketingPlanID: a['bu.indskr_associationbutomarketingbusinessplanid'], associationBuID: a['bu.indskr_businessunit'], associationBuName: a['bu.indskr_businessunit_Formatted'] }
            brandPlan.associationBusinessUnits.push(associationBu);
            brandPlan.associationBusinessUnits = _.orderBy(brandPlan.associationBusinessUnits, 'associationBuName');
          }
        }
      }
    })

    if (fullSync) {
      this.marketingmanagementOfflineService.marketingPlans = marketingPlans;
    } else {
      marketingPlans.map(acpl => {
        let index = this.marketingmanagementOfflineService.marketingPlans.findIndex(o => o.ID == acpl.ID);
        if (index > -1) {
          this.marketingmanagementOfflineService.marketingPlans[index] = acpl;
        }
        else {
          this.marketingmanagementOfflineService.marketingPlans.push(acpl);
        }
      })
      this.marketingmanagementOfflineService.marketingPlans = this.marketingmanagementOfflineService.marketingPlans.filter(p => {
        return (p.state == 0 || !(p.state == 1 && p.statusCode == 2))
      })
    }
  }

  public async removeScrappedEvents(event: EventActivity) {
    this.marketingmanagementOfflineService.marketingPlans.forEach(mbp => {
      const index  = mbp.events.findIndex(event => event.ID === event.ID);
      mbp.events.splice(index, 1);
    })
  }

  public async fetchSurgeryOrders(marketingPlan: MarketingPlan): Promise<SurgeryOrderActivity[]>{
    let startDate = this.dateService.formatDateForFetchXML(new Date(parseInt(marketingPlan.startDate)));
    let endDate = this.dateService.formatDateForFetchXML(new Date(parseInt(marketingPlan.endDate)));
    const fetchXml = fetchQueries.marketingPlanManagement.fetchSurgeryOrders.replace('{planID}', marketingPlan.ID).replace('{startDate}', startDate).replace('{endDate}',endDate);
    let response: any[] = await this.dynamics.executeFetchQuery('salesorders', fetchXml).then((resp: []) => {
      return resp.map((data:any) => {
        data.indskr_scheduledenddate =  new Date(data.indskr_scheduledenddate).getTime();
        data.indskr_scheduleddate = new Date(data.indskr_scheduleddate).getTime();
        const surgeryOrder = new SurgeryOrderActivity(data);
        surgeryOrder.type = ActivityType.SurgeryOrder;
        surgeryOrder.ownerId = this.authService.user.xSystemUserID;
        return surgeryOrder;
      });
    });

    let newStartDate = moment(new Date(parseInt(marketingPlan.startDate))).startOf('day').toDate().getTime();
    let newEndDate = moment(new Date(parseInt(marketingPlan.endDate))).endOf('day').toDate().getTime();

    let localData = await this.getLocalSurgeryOrders(newStartDate, newEndDate, marketingPlan.ID)
    response = [...response, ...localData];
    return response;
  }

  public async fetchAppointments(marketingPlan: MarketingPlan): Promise<AppointmentActivity[]> {
    let startDate = this.dateService.formatDateForFetchXML(new Date(parseInt(marketingPlan.startDate)));
    let endDate = this.dateService.formatDateForFetchXML(new Date(parseInt(marketingPlan.endDate)));
    const fetchXml = fetchQueries.marketingPlanManagement.fetchAppointments.replace('{planID}', marketingPlan.ID).replace('{startDate}', startDate).replace('{endDate}',endDate);
    let response: any[] = await this.dynamics.executeFetchQuery('appointments', fetchXml).then((resp: []) => {
      return resp.map(data => {
        const appointment = new AppointmentActivity(data);
        appointment.ownerId = this.authService.user.xSystemUserID;
        return appointment;
      });
    })

    let newStartDate = moment(new Date(parseInt(marketingPlan.startDate))).startOf('day').toDate().getTime();
    let newEndDate = moment(new Date(parseInt(marketingPlan.endDate))).endOf('day').toDate().getTime();

    let localData = await this.getLocalMeeting(newStartDate,newEndDate , marketingPlan.ID)
    response = [...response, ...localData];
    return response;
  }

  public async fetchPhoneCalls(marketingPlan: MarketingPlan): Promise<PhoneActivity[]> {
    if (_.isEmpty(marketingPlan.accounts)) return [];
    const accountIds = marketingPlan.accounts.map(acc => '<value>' + acc.id + '</value>').join("");
    const fetchXml = fetchQueries.accountManagement.fetchPhoneCallsForAccounts.replace('{accountIds}', accountIds);
    const response: PhoneActivity[] = await this.dynamics.executeFetchQuery('phonecalls', fetchXml).then((resp: []) => {
      return resp.map(data => {
        const phoneCallActivity = new PhoneActivity(data);
        phoneCallActivity.ownerId = this.authService.user.systemUserID;
        return phoneCallActivity;
      });
    })
    return response;
  }

  public getFollowupActivity(marketingPlan: MarketingPlan) {
    const followUp = this.activityService.activities.filter((a) => {
      return (a.type === ActivityType.FollowUp && (a as FollowUpActivity).marketingPlanId === marketingPlan.ID
        && (a.ownerId === this.authService.user.systemUserID
          || (a as FollowUpActivity).assignedTo.some(o => o.userId === this.authService.user.systemUserID)
          || marketingPlan.ownerID === this.authService.user.systemUserID)
        && ((a as FollowUpActivity).planType === FOLLOW_UP_TYPE.MARKETING_BUSINESS_PLAN)
        && (a.state === 0));
    }).sort((a, b) => {
      return (a.subject > b.subject) ? 1 : -1;
    }).sort((a, b) => {
      if (isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        if (isEqual(a.scheduledEnd, b.scheduledEnd)) return 1;
        else return (isBefore(a.scheduledEnd, b.scheduledEnd) ? 1 : -1);
      } else if (isValid(a.scheduledEnd) && !isValid(b.scheduledEnd)) {
        return 1;
      } else if (!isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        return -1;
      } else {
        return 1;
      }
    });
    return followUp;
  }

  public async saveMeetingsForMarketingPlan(marketingPlanId: string, requestPayload) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketingBusineesPlan.SAVE_MEETINGS_FOR_MARKETING_PLAN.replace('{marketingPlanId}', marketingPlanId);
    return await this.http.put<any>(url, requestPayload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async savePhoneCallsForMarketingPlan(marketingPlanId: string, requestPayload) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketingBusineesPlan.SAVE_PHONE_CALLS_FOR_MARKETING_PLAN.replace('{marketingPlanId}', marketingPlanId);
    return await this.http.put<any>(url, requestPayload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async saveFollowupForAccountPlan(requestPayload: FollowUpActivity[]) {
    await this.followupDataService.updateFollowUpActivities(requestPayload);
  }

  public async saveSurgeryOrder(marketingPlanId: string,requestPayload: []){
    try {
      const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketingBusineesPlan.SAVE_SURGERY_ORDER_FOR_MARKETING_PLAN.replace('{marketingPlanId}', marketingPlanId);
      return await this.http.put<any>(url, requestPayload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    } catch (error) {
      console.log(error);
      return false;
    } 
  }

  public async fetchMeetingsTimeline(marketingPlanId: string): Promise<any> {
    const fetchXML = fetchQueries.marketingPlanManagement.fetchMeetingForMarketingPlan.replace('{planID}', marketingPlanId);
    const response = await this.dynamics.executeFetchQuery('appointments', fetchXML).then((resp: []) => {
      return resp.map(data => {
        let meeting = {
          location: data["meeting.location"],
          subject: data["subject"],
          scheduledstart: data["scheduledstart"],
          scheduledend: data["scheduledend"],
          indskr_ownerfullname: data["_ownerid_value_Formatted"],
          indskr_ownerid: data["_ownerid_value"],
          activityid: data["activityid"],
          indskr_jointmeeting: data["indskr_jointmeeting"],
          isRemoteDetailing: data["isRemoteDetailing"],
          statecode: data["statecode"],
        }
        const appointment = new AppointmentActivity(meeting);
        appointment['activityMeetingId'] = data['activityMeetingId'];
        return appointment;
      });
    });
    return response;
  }

  public async fetchSurgeryOrdersTimeline(marketingPlanId: string): Promise<any> {
    if(!this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG)) return [];
    
    const fetchXML = fetchQueries.marketingPlanManagement.fetchSurgeryOrderForMarketingPlan.replace('{planID}', marketingPlanId);
    const response = await this.dynamics.executeFetchQuery('salesorders', fetchXML).then((resp: []) => {
    return resp.map((data:any) => {
      data.indskr_scheduledenddate =  new Date(data.indskr_scheduledenddate).getTime();
      data.indskr_scheduleddate = new Date(data.indskr_scheduleddate).getTime();
      let meeting = {
        indskr_scheduleddate: data["indskr_scheduleddate"],
        indskr_ownerfullname: data["_ownerid_value_Formatted"],
        ownerid: data["_ownerid_value"],
        indskr_scheduledenddate : data['indskr_scheduledenddate'],
        statecode: data["statecode"],
        statuscode : data['statuscode'],
        name: data['name'],
        salesorderid : data['salesorderid']
      }
      const surgeryOrder = new SurgeryOrderActivity(meeting);
      surgeryOrder.type = ActivityType.SurgeryOrder;
      return surgeryOrder;
    });
     return resp;
    }).catch((error) => {
      console.log(error); 
    });
    return response;
  }

  public async fetchPhoneCallsTimeline(marketingPlanId: string): Promise<any> {
    let response = [];
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      const fetchXML = fetchQueries.marketingPlanManagement.fetchPhoneCallsForMarketingPlan.replace('{planID}', marketingPlanId);
      response = await this.dynamics.executeFetchQuery('indskr_brandplans', fetchXML).then((resp: []) => {
        return resp.map(data => {
          let phoneCall = {
            subject: data["phonecall.subject"],
            scheduledstart: data["phonecall.scheduledstart"],
            scheduledend: data["phonecall.scheduledend"],
            indskr_ownerfullname: data["phonecall.ownerid_Formatted"],
            indskr_ownerid: data["phonecall.ownerid"],
            phonenumber: data["phonecall.phonenumber"],
            activityid: data["phonecall.activityid"],
            statecode: data["phonecall.statecode"],
            activitytypecode: ActivityType.PhoneCall,
          }
          const phonecallActivity = new PhoneActivity(phoneCall);
          phonecallActivity['activityPhoneCallId'] = data['activityPhoneCallId'];
          return phonecallActivity;
        });
      })
    }
    return response;
  }

  getLocalSurgeryOrders(startdate, endDate , planId){
    if(!this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG)) return;

    const unFilteredSurgeryOrder = this.activityService.activities.filter((activty) => activty.type === ActivityType.SurgeryOrder);
    const filteredForMarketingBusinessPlan =  unFilteredSurgeryOrder.filter((surgeryOrder : SurgeryOrderActivity) => {
      let scheduleEnd = new Date(surgeryOrder.scheduledEnd).getTime();
      let scheculeStart = new Date(surgeryOrder.scheduledStart).getTime();

      if((!surgeryOrder.marketingBusinessPlanId
        && surgeryOrder.ownerId === this.authService.user.systemUserID
        && (scheculeStart >= startdate &&  scheduleEnd <= endDate)
        && surgeryOrder.status === 1) || surgeryOrder.marketingBusinessPlanId === planId
        ){
        return true;
      }
      return false;
    });
    return filteredForMarketingBusinessPlan;
  }

  getLocalMeeting(startdate, endDate , planId){
    const unFilteredSurgeryOrder = this.activityService.activities.filter((activty) => activty.type === ActivityType.Appointment);
    const filteredForMarketingBusinessPlan =  unFilteredSurgeryOrder.filter((meeting : AppointmentActivity) => {
      let scheduleEnd = new Date(meeting.scheduledEnd).getTime();
      let scheculeStart = new Date(meeting.scheduledStart).getTime();

      if((!meeting.marketingBusinessPlanId
        && meeting.ownerId === this.authService.user.systemUserID
        && (scheculeStart >= startdate &&  scheduleEnd <= endDate)
        && meeting.status === 1) || (meeting.marketingBusinessPlanId === planId)
        ){
        return true;
      }
      return false;
    });
    return filteredForMarketingBusinessPlan;
  }

  public async getBusinesUnitsMasterList(fullSync : boolean, loadFromDbOnly = false){
      if(loadFromDbOnly){
        await this.disk.retrieve(DB_KEY_PREFIXES.BUSINESS_UNTIS, true).then((doc)=>{
          if(doc && doc.raw){
            this.sharedDataService.businessUnits = doc.raw
          }
          else {
            this.sharedDataService.businessUnits = [];
          }
        })
        return;
      }
      let fetchXml = fetchQueries.marketingPlanManagement.fetchBusinessUnits;
      await this.dynamics.executeFetchQuery('businessunits',fetchXml).then((res)=>{
        this.sharedDataService.businessUnits = res;
        this.disk.updateOrInsert(DB_KEY_PREFIXES.BUSINESS_UNTIS, (doc)=>{
          doc = {
              raw: []
          };
          doc.raw = this.sharedDataService.businessUnits;
          return doc;
        })
      })
    }

  public async syncMarketingBusinessPlanTypeRecords(fullSync?: boolean, loadFromDBOnly = false) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.MARKETING_BUSINESS_PLAN_TYPES);
      let lastUpdatedTime;
      if (offlineData && offlineData.raw && !fullSync) {
        if (offlineData.lastUpdatedTime) {
          lastUpdatedTime = offlineData.lastUpdatedTime;
        }
      }
      if (!loadFromDBOnly) {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MARKETING_BUSINESS_PLAN_TYPES);
        const bulkMarketingBusinessPlanTypesSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.marketing_business_plan_types,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        const now = new Date().getTime();
        let fetchXML = fetchQueries.marketingPlanManagement.fetchMarketingBusinessPlanTypes;
        //fetchXML = fetchXML.replace("{userId}", this.authenticationService.user.xSystemUserID);

        if (lastUpdatedTime && !fullSync) {
          let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
        } else {
          fetchXML = fetchXML.replace('<condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />', '');
        }

        let response = await this.dynamics.executeFetchQuery('indskr_marketingbusinessplantypeses', fetchXML);

        lastUpdatedTime = new Date().getTime();
        if (response && Array.isArray(response) && response.length != 0 && response[0]) {
          bulkMarketingBusinessPlanTypesSyncInfo.totalSynced += response.length;
          if (offlineData && offlineData.raw && !fullSync) {
            response.forEach(item => {
              let idx = offlineData.raw.findIndex(a => a.indskr_marketingbusinessplantypesid == item.indskr_marketingbusinessplantypesid);
              if (idx >= 0) {
                if (item.statecode == 0) {
                  offlineData.raw[idx] = item;
                } else {
                  offlineData.raw.splice(idx, 1);
                }
              } else {
                if (item.statecode == 0) {
                  offlineData.raw.push(item);
                }
              }
            });
            offlineData.lastUpdatedTime = lastUpdatedTime;
          } else {
            offlineData = {
              raw: response,
              lastUpdatedTime: lastUpdatedTime,
            }
          }
          syncState.lastUpdatedTime = lastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          await this.deltaService.addEntitySyncInfo(bulkMarketingBusinessPlanTypesSyncInfo);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.MARKETING_BUSINESS_PLAN_TYPES, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save Marketing Business Plan Types data in offline db error: ', error));
        }
      }
    }
  }



 public deleteMarketingBusinessPlan(id:string){
  let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketingBusineesPlan.DELETE_MARKETING_PLAN;
  url = url.replace('{marketingPlanId}', id);
  return  this.http.delete(url).toPromise();
}

}
