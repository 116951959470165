import { DirectMessagingConfig } from './../../../config/endpoints.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConsentService } from './../consent/consent.service';
import { AuthenticationService } from './../authentication.service';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, share } from 'rxjs/operators';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { ChannelType, EmailActivityChannelDetails, ChannelActivityType } from '../../classes/consent/channel.class';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DirectMessagingService {
    public channels: Observable<ChannelType[]>;
    public channelList:  Observable<EmailActivityChannelDetails[]>;
    constructor(
        authService: AuthenticationService,
        consentService: ConsentService,
        private http: HttpClient,
        private translate: TranslateService,
    ) {

       // this.channels = consentService.allConsentChannelSubject
          this.channelList = consentService.allConsentChannelSubject
            .pipe(map(consentChannels => {
                return consentChannels.map(consentChannel => {
                    let channelWecom = this.translate.instant('CHANNEL_WECOM');
                    let validate = (channelType?: ChannelType,channelActivityType?:ChannelActivityType, featureAction?: FeatureActionsMap, checkLDOnly?: boolean):EmailActivityChannelDetails => {
                      if(consentChannel.indskr_externalchannel){
                        if(authService.hasFeatureAction(FeatureActionsMap.MESSAGE_NON_NATIVE)){
                          if(authService.hasFeatureAction(featureAction, checkLDOnly) && consentChannel.activityType === channelActivityType ? channelActivityType : undefined){
                            return {
                              id: consentChannel.indskr_consenttypeid+'',
                              // title: consentChannel.indskr_consentType + '  ' + '(Non Native)',
                              title: consentChannel.indskr_consentType.startsWith('WeCom')?channelWecom: consentChannel.indskr_consentType + '  ' + '(Non Native)',
                              activityType: channelActivityType,
                              channelType: channelType,
                              indskr_externalchannel:consentChannel.indskr_externalchannel,
                              enablepreview: consentChannel.indskr_enablepreview
                            }
                          }
                        }
                      }else{
                        if(authService.hasFeatureAction(featureAction, checkLDOnly) && consentChannel.activityType === channelActivityType ? channelActivityType : undefined){
                          return {
                            id: consentChannel.indskr_consenttypeid+'',
                            title: consentChannel.indskr_consentType+'',
                            activityType: channelActivityType,
                            channelType: channelType,
                          }
                        }
                      }
                    };
                  return validate(ChannelType.EMAIL, ChannelActivityType.EMAIL, FeatureActionsMap.EMAIL_ACTIVITY)
                    || validate(ChannelType.SMS, ChannelActivityType.SMS, FeatureActionsMap.SMS_ACTIVITY)
                    || validate(ChannelType.FACEBOOK, ChannelActivityType.FACEBOOK, FeatureActionsMap.FACEBOOK_ACTIVITY)
                    || validate(ChannelType.WHATSAPP, ChannelActivityType.WHATSAPP, FeatureActionsMap.WHATSAPP_ACTIVITY)
                    || validate(ChannelType.WECOM, ChannelActivityType.WECOM, FeatureActionsMap.WECOM_ACTIVITY, true)
                }).filter(Boolean)
            }),
            );
    }

    async sendTwilioMessage({ body, recipient, from }: { body: string, recipient: string, from: string }) {
        return await this.http.post(`https://api.twilio.com/2010-04-01/Accounts/${DirectMessagingConfig.TWILIO.accountSid}/Messages.json`,
            new HttpParams({
                fromObject: {
                    To: recipient,
                    From: from,
                    Body: body,
                },
                encoder: {
                    encodeKey(key) { return encodeURIComponent(key)},
                    encodeValue(value) { return encodeURIComponent(value)},
                    decodeKey(key) { return decodeURIComponent(key)},
                    decodeValue(value) { return decodeURIComponent(value)},
                },
            }), {
                headers: {
                    'X-Skip-Interceptor': '',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${btoa(`${DirectMessagingConfig.TWILIO.accountSid}:${DirectMessagingConfig.TWILIO.authToken}`)}`
                }
            }).toPromise();
    }

    async sendTextMessage({ body, recipient }: { body: string, recipient: string }) {
        return this.sendTwilioMessage({ body, recipient, from: '+16475607865' });
    }

    async sendWhatsAppMessage({ body, recipient }: { body: string, recipient: string }) {
        return this.sendTwilioMessage({ body, recipient: `whatsapp:${recipient}`, from: 'whatsapp:+14155238886' });
    }

    async sendFacebookMessage({ body, recipient }: { body: string, recipient: string }) {
        //return Promise.all(recipients.map(r => this.sendTwilioMessage({ body, recipient: `messenger:${r}`, from: 'messenger:351977312144814' })));
        let parameters = {
            "recipient": {id: recipient},
            "message": {
                "text": body
            }
        };
        let requestURL = "https://graph.facebook.com/v4.0/me/messages"
        return await this.http.post(requestURL,
            parameters, {
                params: {'access_token': DirectMessagingConfig.Facebook.access_token}
            }).toPromise();
    }

}
