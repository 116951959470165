import { Component } from "@angular/core";
import { FooterService } from '@omni/services/footer/footer.service';
const NumberOfFieldsToDisplay = 6;

@Component({
  selector: 'activity-skeleton-pane',
  templateUrl: 'activity-skeleton-pane.html',
  styleUrls: ['activity-skeleton-pane.scss'],
})
export class ActivitySkeletonPaneComponent {
  public sectionHeaderConfig: { headerSkeletonTextWidth: string, headerSkeletonTextLineHeight: string }
    = { headerSkeletonTextWidth: '15%', headerSkeletonTextLineHeight: '24px' };
  public formFieldConfigs: { labelWidth: string, valueWidth: string }[] = [];
  constructor(public readonly footerService: FooterService) {
    for (let i = 0; i < NumberOfFieldsToDisplay; i++) {
      if (i % 2 === 0) {
        this.formFieldConfigs.push(undefined);
      } else {
        this.formFieldConfigs.push({ labelWidth: '15%', valueWidth: '60%' });
      }
    }
  }
}
