import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import { FooterViews } from '../../services/footer/footer.service';
import { FooterService } from '../../services/footer/footer.service';
import { IndPageTitleViewDataModel } from '../../models/indPageTitleDataModel';
import { IonicModule } from '@ionic/angular';
import { NavigationService } from '../../services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { TrendingFeed } from './../../classes/xperiences/trending.customer.class';
import { UIService } from '../../services/ui/ui.service';
import { XperiencesService } from '../../services/xperiences/xperiences.service';


/**
 * Generated class for the TrendingFeedsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'trending-feeds',
  templateUrl: 'trending-feeds.html',
  styleUrls: ['trending-feeds.scss']
})
export class TrendingFeedsComponent implements OnInit{

  text: string;
  feed: TrendingFeed;
  activities: any;
  insights: any;
  pageTitle: IndPageTitleViewDataModel;
  constructor(private xperiencesService: XperiencesService,
              public device: DeviceService,
              private navService: NavigationService,
              private uiService: UIService,
              public footerService: FooterService,
              public translate: TranslateService) {
  }

  ngOnInit() {
        this.feed = this.xperiencesService.selectedTrendingFeed;
        this.activities = [...this.feed.plans, ...this.feed.predictions];
        this.insights = this.feed.insights;
        this.footerService.initButtons(FooterViews.Contacts);
        this.pageTitle = {
          id: 'xp-feeds-page-title',
          // title: this.feed.description,
          title: this.xperiencesService.selectedTrendingFeed.description,
          controls: []
        };
  }

  async onPageTitleControlClick(id: string) {
    if(id === 'close') {
      await this.navService.popChildNavPageWithPageTracking();
      this.uiService.showRightPane = false;
    }
  }

}
