import { Injectable } from "@angular/core";
import { Contact } from "@omni/classes/contact/contact.class";
import { AssessmentTemplate, SurveyToolTab, TemplateType } from "@omni/classes/customer-assessment/assessment-template.class";
import _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { AuthenticationService } from "../authentication.service";
import { CustomerAssessService } from "../customer-assess/customer-assess.service";
import { Account } from "@omni/classes/account/account.class";


@Injectable({
  providedIn: 'root'
})
export class CustomerSurveyService {
  public selectedSurvey: any;
  private currentSurveySource = new BehaviorSubject<any>(undefined);
  currentSurveyObserver = this.currentSurveySource.asObservable();
  private isSurveyPreview = new BehaviorSubject<any[]>([true, []]);
  surveyPreviewObserver = this.isSurveyPreview.asObservable();
  public selectedContactForSurvey: Contact = null;
  public selectedContactFromContactTool: Contact = null;
  public selectedAccountForSurvey: Account = null;
  public selectedAccountFromAccountTool: Account = null;

  public surveyTemplates: AssessmentTemplate[];
  public inMeetingCustomerSurvey: boolean = false;
  public inMeetingSelectedSurveyId: string = '';

  constructor(

    private customerAssessService: CustomerAssessService,
    private authService: AuthenticationService

  ) {
    this.surveyTemplates = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
  }

  public getSelectedSurvey() {
    return this.currentSurveySource.getValue();
  }

  public setCurrentSurvey(input) {
    this.currentSurveySource.next(input);
  }

  public getSurveyPreviewMode() {
    return this.isSurveyPreview.getValue();
  }

  public setSurveyPreviewMode(isPreview: boolean, survey: any = null, from: string = 'preview', disableSave: boolean = false) {
    if (isPreview && _.isEmpty(survey)) {
      this.selectedContactForSurvey = null;
      this.selectedAccountForSurvey = null;
    }
    this.isSurveyPreview.next([isPreview, survey, from, disableSave]);
  }

  public fetchInternalSurvey() {

  }
}