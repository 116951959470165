<ion-header>
    <ind-page-title [viewData]='{id:"participant-list",title: title, controls: backButton}' (onControlClick)='goBack()'></ind-page-title>
</ion-header>

<ion-content no-padding>
    <ng-container *ngIf="participants && participants.length > 0">
        <ion-list>
            <ind-section-header [viewData]='participantHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
            <ion-item class="participant-item" *ngFor="let item of participants | orderBy:'canChangeSelection':false:'isSelected':false" [attr.detail-push]="readOnly ? '' : undefined" (click)="selectParticipant(item)" tappable>
                <ion-checkbox *ngIf="!readOnly" [(ngModel)]="item.isSelected" [disabled]="!item.canChangeSelection"></ion-checkbox>
                <ion-label item-content class="primary-text">
                    <h3>{{item.participant.firstName}} {{item.participant.lastName}}
                        <span *ngIf="item.participant.isguest" class="contact-state-guest">{{ 'CONTACT_GUEST' | translate}}</span>
                    </h3>
                    <h4 class="secondary-text" *ngIf="!!item.resourceStatus">{{ ResourceParticipantStatusDisplay[item.resourceStatus] | translate }} {{'AT' | translate}} {{ getStatusDate(item.date) }}</h4>
                </ion-label>
                <img *ngIf="!readOnly" slot="end" [src]="ContactStatusDisplay[item.contactStatus]" width="14" class="contact-state-icon" />
                <!-- <button slot="end" ion-button (click)="onCheckIconClicked(participant)" icon-only>
          <ion-icon [name]="selectedParticipants.indexOf(participant) >= 0 ? 'checkmark' : 'add'"></ion-icon>
        </button> -->
            </ion-item>
        </ion-list>
    </ng-container>
</ion-content>
<ion-footer>
    <ion-list>
        <ind-section-header [viewData]='resourceHeader'></ind-section-header>
        <ion-item lines="none" class="resource-container">
            <resource-list-item [allowPushResource]="false" [resource]="resource" [sortedBy]="'Title'"></resource-list-item>
        </ion-item>
    </ion-list>
    <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'participantList'"></footer-toolbar>
</ion-footer>