<ion-header>
    <ind-page-title [viewData]='{id:"account-plan-opportunity-list",title: selectedAccountPlan.accountPlanName, controls: pageControls}' (onControlClick)='onPageControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ind-section-header *ngIf="applicableOpportunities.length != 0" [viewData]='opportunitiesHeader'></ind-section-header>
    <!-- <div class="accordion-header">
    <main-card class="accordian-expanded" *ngIf="openOpportunitiesViewDataModels.length>0" [viewData]='openOpportunitiesHeader'></main-card>
  </div>
  <div class="expandableDiv" *ngIf="expandedSection=='open'">
    <main-card *ngFor="let viewData of openOpportunitiesViewDataModels" [viewData]="viewData">
      <div class='main-card-secondary-right'>
        <ng-container *ngIf="viewData.customSecondaryTextRight">
          <span>
            <span class="ion-text-center displayPill">
              {{viewData.customSecondaryTextRight}}
            </span>
          </span>
        </ng-container>
        <ng-container *ngIf="viewData.customSecondaryTextRightTwo">
          <span>
            <span class="ion-text-center secondary-text-right-ellipsis">
              {{viewData.customSecondaryTextRightTwo}}
            </span>&nbsp;
            <span>Estimated</span>
          </span>
        </ng-container>
      </div>
    </main-card>
  </div>
  <div class="accordion-header">
    <main-card class="accordian-expanded" *ngIf="wonOpportunitiesViewDataModels.length>0" [viewData]='wonOpportunitiesHeader'></main-card>
  </div>
  <div class="expandableDiv" *ngIf="expandedSection=='won'">
    <main-card *ngFor="let viewData of wonOpportunitiesViewDataModels" [viewData]="viewData">
      <div class='main-card-secondary-right'>
        <ng-container *ngIf="viewData.customSecondaryTextRight">
          <span>
            <span class="ion-text-center displayPill">
              {{viewData.customSecondaryTextRight}}
            </span>
          </span>
        </ng-container>
        <ng-container *ngIf="viewData.customSecondaryTextRightTwo">
          <span>
            <span class="ion-text-center secondary-text-right-ellipsis">
              {{viewData.customSecondaryTextRightTwo}}
            </span>&nbsp;
            <span>Estimated</span>
          </span>
        </ng-container>
      </div>
    </main-card>
  </div>
  <div class="accordion-header">
    <main-card class="accordian-expanded" *ngIf="lostOpportunitiesViewDataModels.length>0" [viewData]='lostOpportunitiesHeader'></main-card>
  </div>
  <div class="expandableDiv" *ngIf="expandedSection=='lost'">
    <main-card *ngFor="let viewData of lostOpportunitiesViewDataModels" [viewData]="viewData">
      <div class='main-card-secondary-right'>
        <ng-container *ngIf="viewData.customSecondaryTextRight">
          <span>
            <span class="ion-text-center displayPill">
              {{viewData.customSecondaryTextRight}}
            </span>
          </span>
        </ng-container>
        <ng-container *ngIf="viewData.customSecondaryTextRightTwo">
          <span>
            <span class="ion-text-center secondary-text-right-ellipsis">
              {{viewData.customSecondaryTextRightTwo}}
            </span>&nbsp;
            <span>Estimated</span>
          </span>
        </ng-container>
      </div>
    </main-card>
  </div> -->

    <ion-list *ngIf="this.formattedOpportunities.Won.length>0">
        <div class="accordion-header" [ngClass]="expandedSection == 'won'?'expanded':'collapsed'">
            <main-card [viewData]="getOpportunityDataModel('won')"></main-card>
        </div>
        <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Won" [expandHeight]="0" [expanded]="expandedSection == 'won'" (openOpportunity)="openOpportunityDetail($event)">
        </expandable>
    </ion-list>

    <ion-list *ngIf="this.formattedOpportunities.Open.length>0">
        <div class="accordion-header" [ngClass]="expandedSection == 'open'?'expanded':'collapsed'">
            <main-card [viewData]="getOpportunityDataModel('open')"></main-card>
        </div>
        <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Open" [expandHeight]="0" [expanded]="expandedSection == 'open'" (openOpportunity)="openOpportunityDetail($event)">
        </expandable>
    </ion-list>

    <ion-list *ngIf="this.formattedOpportunities.Lost.length>0">
        <div class="accordion-header" [ngClass]="expandedSection == 'lost'?'expanded':'collapsed'">
            <main-card [viewData]="getOpportunityDataModel('lost')"></main-card>
        </div>
        <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Lost" [expandHeight]="0" [expanded]="expandedSection == 'lost'" (openOpportunity)="openOpportunityDetail($event)">
        </expandable>
    </ion-list>
    <div *ngIf="applicableOpportunities.length == 0" class="no-data-message">{{'NO_OPPORTUNITIES' | translate}}</div>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'accountPlanOpportunitiesList'"></footer-toolbar>
<!-- </ion-footer> -->
