import { Component, ChangeDetectorRef, OnDestroy, OnInit, Input } from '@angular/core';

import { ActivityService } from '../../../services/activity/activity.service';
import { Brand } from '../../../classes/brand/brand.class';
import { DeviceService } from '../../../services/device/device.service';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { GlobalErrorHandler } from '../../../services/error-handler/error-handler-service';
import { PhoneActivity } from '../../../classes/activity/phone.activity.class';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { PhoneCallDataService } from '../../../data-services/phone-call/phonecall.data.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '../../../services/ui/ui.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import _ from 'lodash';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { PageName, NavigationService } from '@omni/services/navigation/navigation.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from '@omni/services/events/events.service';
import { Subject } from 'rxjs';

/**
 * Generated class for the PhoneCallProductKeyMessageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'phone-call-product-key-message',
  templateUrl: 'phone-call-product-key-message.html',
  styleUrls:['phone-call-product-key-message.scss']
})
export class PhoneCallProductKeyMessageComponent implements OnInit,OnDestroy {
  tempProducts: Array<Brand> = [];
  updateProductsFlag: boolean = false;
  showReorderIcon: boolean;
  private productKeyMessagesSaved: Array<Brand> = [];
  showKeyMessageArrowButtons: boolean;
  phoneCallStartTime: number;
  phoneCallEndTime: number;
  products:Array<Brand> = [];
  public productKeyMessageHeaderModel : IndSectionHeaderViewDataModel
  contentBrand: any;
  brandProductsAfterSort: Brand[];
  @Input() isReadOnlyJointPhoneCall:boolean = false;
  @Input() backgroundUploadInProgress: boolean;
  private ngDestroy$ = new Subject<boolean>();
  @Input() isProductRequired: boolean;

  constructor(public activityService: ActivityService,
    public device: DeviceService,
    public trackingService: TrackService,
    private brandService: BrandOfflineService,
    private _cd: ChangeDetectorRef,
    public globalErrorHandler: GlobalErrorHandler,
    public phonecallDataService: PhoneCallDataService,
    public notificationService: NotificationService,
    private translate: TranslateService,
    public events:EventsService,
    public uiService: UIService,
    public navService: NavigationService,
  ) {
    if(this.activityService.selectedActivity){
      this.phoneCallStartTime = new Date(this.activityService.selectedActivity.scheduledStart).getTime();
      this.phoneCallEndTime = new Date(this.activityService.selectedActivity.scheduledEnd).getTime();
    }
  }
  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  ngOnInit() {
    this.subscribeToEvents();
    this.initDetails();
    this.initializeSectionHeader();
  }

  ngOnChanges() {
    this.initDetails();
    this.initializeSectionHeader();
  }

  initializeSectionHeader() {
    this.productKeyMessageHeaderModel = {
      id: 'productKeyMessage',
      title : `${this.translate.instant('PRODUCT_KEY_MESSGES')}`,
      isRequired: this.isProductRequired,
      controls: [
        {
          id: 'add-product',
          text: this.translate.instant('ADD'),
          isDisabled: this.backgroundUploadInProgress || this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointPhoneCall,
        },
        {
          id: 'productMessage-key-save',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress || !this.updateProductsFlag || this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointPhoneCall,
        }
      ]
    }
  }

  private initDetails = () => {
    //when an activity is selected let's reset our data set but keep the general structure
    this._activitySelectedEventHandler();
    this.showKeyMessageArrowButtons = !(this.device.isNativeApp);
    this.showReorderIcon = true;
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.products /*&& !this.device.isOffline*/) {
      this.products = _.cloneDeep(this.activityService.selectedActivity.products);
      let products = _.sortBy(this.products, [function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }]);
      const savedProducts = this.products.filter(o =>{return o.isSelected || o.isAutoSelected || o.isGenieSelected});
      if (savedProducts.length) {
        products = _.sortBy(products, [function(o) { return o.name; }]);
        this.tempProducts = _.uniqBy(savedProducts.concat(products.splice(0,5)), 'ID');;
      }
      else {
        products = _.sortBy(products, [function (o) { return o.name; }]);
        this.tempProducts = JSON.parse(JSON.stringify(products.splice(0, 5)));
      }
    } else {
      //we are offline or the selected activity products is not in sync with MSE , and we need to retrieve from disk
      if (this.productKeyMessagesSaved.length !== 0) {
        this.products = this.productKeyMessagesSaved;
        this.tempProducts = JSON.parse(JSON.stringify(this.products));
      } else {
        this.brandService.deSelectAll();
        let brands: Array<Brand> = JSON.parse(JSON.stringify(this.brandService.brands));
        //no details, make basic array
        let brand = brands.filter(value => {
          return value.isSelected === undefined || !value.isSelected;
        });
        brand.forEach((el, index) => el.priority = brands.length + index + 1);
        //sort the products and return it to UI
        //now sort the products based on the sequence(product priority) , if the sequence is not available default
        brands.sort((item1, item2) => {
          //sort criteria
          if (item1.priority && item2.priority) {
            return item1.priority - item2.priority;
          }
        });
        this.products = brands;
        if(this.activityService.selectedActivity instanceof PhoneActivity) {
          this.activityService.selectedActivity.products = brands;
        }
        this.products = _.sortBy(this.products, [function(o) { return o.name; }]);
        this.tempProducts = JSON.parse(JSON.stringify(this.products.slice(0,5)));
      }
    }
    /** Filter activity products for the completed meeting before loading */
    this.filterCompletedActivityProducts();
    /** */
    this._cd.detectChanges();
  }

  private _activityProductsCreatedEventHandler = (brands:Array<Brand>) => {
    /**
     * this is a reference to master brands with isSelected and isAutoSelected set accordingly
     * based on the meeting activityProduct from dynamics
     */

      this.products = _.sortBy(brands, [function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }]);
      const savedProducts = _.takeWhile(brands, function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; });
      let defaultProducts = (_.sortBy(brands, [function(o) { return o.name; }])).splice(0,5)
      if(savedProducts.length){
       this.tempProducts = _.uniqBy(savedProducts.concat(defaultProducts), 'ID');
      } else{
       this.tempProducts = defaultProducts;
      }

    /** Filter activity products for the completed meeting before loading */
    this.filterCompletedActivityProducts();
    /* */


  };

  filterCompletedActivityProducts() {
    /** Filter only the completed activity products */
    if ((this.tempProducts.length > 0 && this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted) || this.activityService.teamViewActive) {
      this.tempProducts = this.tempProducts.filter(pdt => pdt.isSelected);
    }
  }

  subscribeToEvents() {
    this.events.observe('activities-pane:activitySelected').pipe(takeUntil(this.ngDestroy$)).subscribe(this._activitySelectedEventHandler);
    this.events.observe('initActivityKeyMessages').pipe(takeUntil(this.ngDestroy$)).subscribe(this.initDetails);
     this.events.observe('user:activity_products_created').pipe(takeUntil(this.ngDestroy$)).subscribe(this._activityProductsCreatedEventHandler);
     this.events.observe('phone-call-activity-updated').pipe(takeUntil(this.ngDestroy$)).subscribe((res : { eventType:string }) => {
       // when mark complete/reopen the phone call activity
       // this will update the Add Product button
        this.initializeSectionHeader();

        if (res.eventType === 'markcomplete') {
          this.filterCompletedActivityProducts();
        } else if (res.eventType === 'reopen') {
          // keep the selected ids.
          const previouslySelecteIds = this.tempProducts.filter(p => p.isSelected).map(p => p.ID);
          // keep the selected products
          const copiedTempProducts = [...this.tempProducts];

          // initialise the details again.
          this.initDetails();

          // re-selected the product.
          const notAddedProducts = copiedTempProducts.filter(a => !this.tempProducts.some(b=>  a.ID == b.ID));

          // Select the Not Added Products
          notAddedProducts.map(p => {
            if (previouslySelecteIds.includes(p.ID)) {
              p.isSelected = true;
            }
          });

          // Select the Added Products
          this.tempProducts.map(p => {
            if (previouslySelecteIds.includes(p.ID)) {
              p.isSelected = true;
            }
          });

          // Added missig products to the array
          this.tempProducts.push(...notAddedProducts);

          // Sort
          this.tempProducts.sort((item1, item2) => {
            if (item1.priority && item2.priority) {
              return item1.priority - item2.priority;
            }
          });

          // it will update the activity, so validation (product selection) will pass.
          if (this.activityService.selectedActivity instanceof PhoneActivity) {
            this.activityService.selectedActivity.products = this.tempProducts;
          }
        }
      });
  }

  private _activitySelectedEventHandler = () => {
    this.tempProducts.map(product => {
      //Remove isSelected & isAutoSelected from each product to reset the ui
      product.isSelected = undefined;
      product.isAutoSelected = undefined;
      product.isGenieSelected = undefined;

      product.keyMessages.map(keyMessage => {
        keyMessage.isAutoSelected = undefined;
        keyMessage.isSelected = undefined;
        keyMessage.isGenieSelected = undefined;
      })
    })
    this.updateProductsFlag = false;
    this.initializeSectionHeader();
  };

  /**
   * To save the productKeyMessage
   */
  async saveProductKeyMessages() {
    if(this.isReadOnlyJointPhoneCall) return;
    if (this.updateProductsFlag) { // this ensures we are not doing the expensive network call to update the meeting DTO object all the time , do it only when needed
      if (this.activityService.selectedActivity instanceof PhoneActivity) {
        this.activityService.selectedActivity.products = this.tempProducts;
        this.tempProducts = JSON.parse(JSON.stringify(this.activityService.selectedActivity.products));
      }

      if (this.activityService.selectedActivity instanceof PhoneActivity) {
        this.uiService.displayLoader();
        if (this.device.isOffline || this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
          try { // I/O operations needs to be wrap in a try and catch block
            await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
            this.uiService.dismissLoader();
            this.activityService.selectedActivity.products = this.activityService.selectedActivity.products.sort((item1: any, item2: any) => {
              if (item1.priority && item2.priority) {
                return item1.priority - item2.priority;
              }
            })
            this.initDetails();
            this.events.publish('updateMandatoryField');
          } catch (e) {
            this.globalErrorHandler.handleError(new Error(e)); // will implement a Global Error handler
          }

        } else {
          try {
            let phoneCallPayLoad = this.phonecallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
            if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
            await  this.phonecallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
            this.uiService.dismissLoader();
            //  this.activityService.isActivityUpdate = true;
            }
            else {
              this.uiService.dismissLoader();
              if (this.device.isOffline) {
              }
            }
            this.activityService.selectedActivity.products = this.activityService.selectedActivity.products.sort((item1: any, item2: any) => {
            if (item1.priority && item2.priority) {
              return item1.priority - item2.priority;
            }
          })
          this.initDetails();
          this.events.publish('updateMandatoryField');
        }catch (e) {
          //handled in the patch services!!!
          this.globalErrorHandler.handleError(new Error(e));
        }
      }
    }
  }
    this.updateProductsFlag = false;
this._cd.detectChanges();
  }

/**
 * To reOrder products
 * @param indexes
 */
reorderData(indexes: any){
  let element = this.tempProducts[indexes.from];
  this.tempProducts.splice(indexes.from, 1);
  this.tempProducts.splice(indexes.to, 0, element);
  this.tempProducts.map((obj: any, index: any) => {
    let tempObj: any = obj;
    if (tempObj.isSelected) {
      tempObj['priority'] = index + 1;
    }
    return tempObj
  });
  indexes.complete();
  if (element.isSelected){
    this.updateProductsFlag = true;
    this.initializeSectionHeader();
  }
  this.updateProductsFlag = true;
}

// /**
//  * To handle products
//  */
// updateProduct(event: UIEvent, product: any){
//   if (product.isAutoSelected || this.activityService.selectedActivity.isCompleted) return;
//   if (product.isSelected) {
//     product.isSelected = false;
//     product.isAutoSelected = false;
//     // Unselect all keymessages if the user unselects the brand
//     product.keyMessages.forEach(key => key.isSelected = false);
//   } else {
//     this.trackingService.tracking('SelectProduct', TrackingEventNames.PHONECALL);
//     product.isSelected = true;
//     product.isAutoSelected = false;

//   }
//   this.updateProductsFlag = true;
// }

updateProduct=(event:UIEvent , product:any )=>{
  //update the model here too for unchecking and triggering a change detetctor ref
  //If the user is accompanied and viewing a readonly meeting then ignore clicks
  if(product.isAutoSelected || this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted  || this.activityService.teamViewActive || this.isReadOnlyJointPhoneCall || this.backgroundUploadInProgress) return;
  if(product.isSelected){
    product.isSelected = false;
    product.isAutoSelected = false;

     // Unselect all keymessages if the user unselects the brand
    product.keyMessages.forEach(key => key.isSelected = false);
  } else {
    this.trackingService.tracking('SelectProduct', TrackingEventNames.PHONECALL);
    product.isSelected = true;
    product.isAutoSelected = false;
  }
  this.updateProductsFlag = true;
  this.events.publish('detectChangesOnActivityDetails');
  this.initializeSectionHeader();
}

/**
 * To handle keyMessage
 */
updatedHighlightedKeyMessage(ev: MouseEvent, product: any, keyMessage: any){
  if (keyMessage.isAutoSelected) return;
  if (!keyMessage.isSelected) {
    keyMessage.isSelected = true;
    keyMessage.isAutoSelected = false;
    this.trackingService.tracking('SelectKeymessages', TrackingEventNames.PHONECALL);
  } else {
    keyMessage.isSelected = false;
  }
  this.updateProductsFlag = true;
  this.toggleChecked(ev, product, keyMessage.name)
  this.initializeSectionHeader();
}

toggleChecked = (ev: MouseEvent, product: any, keyMessage: string) => {
  //tag the div round by id
  if (!product.isAutoSelected) {
    if (product.isSelected) {
    } else {
      product.isSelected = true;
    }
  }
}

onSectionHeaderControlClick(id) {
  if (id === 'productMessage-key-save') {
    this.saveProductKeyMessages();
  } else if (id === 'add-product'){
    this.addProduct();
  }
}

addProduct(){
  this.brandProductsAfterSort = _.sortBy(this.brandService?.brands,[function(o) { return o.name; }]);
  if(this.brandProductsAfterSort){
    this.brandProductsAfterSort = this.brandProductsAfterSort.filter((product, index) => { return index > 4}); _.sortBy(this.products, [function(o) { return o.name; }]);
  const listDetail: MainToolTemplateDetail = {
    title: this.translate.instant('PRODUCTS'),
    dividerTitle:this.translate.instant('ALL_PRODUCTS_CAP'),
    isSearchEnabled: true,
    showLeftHeaderButton: true,
    leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
    leftHeaderBtnText: this.translate.instant('CANCEL'),
    showRightHeaderButton: true,
    rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
    rightHeaderBtnText: this.translate.instant('DONE'),
    orderByPropertyName: 'primaryTextRight',
    searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
    hideAllItemsList: false,
    isListSelectionEnabled: true,
    listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
    navOptions: { animate: false },
    eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData),
    searchHandler:(text: string) => this._handleProductsComponentSearch(text),
    data: this.brandProductsAfterSort && this.brandProductsAfterSort.map(pro => {
      let isSelected = false;
      let showEndIcon = true;
      if(this.tempProducts  && this.tempProducts.some(evpro => (evpro.ID === pro.ID))){
        isSelected = true;
      }
      // if(this.tempProducts  && this.tempProducts.some(evpro => ((evpro.ID === pro.ID) && (evpro.isAutoSelected || evpro.isSelected || evpro.isChecked || evpro.isGenieSelected)))){
      //   showEndIcon = false;
      // }
      return {
        id: pro.ID,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: showEndIcon,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
        endIconCssClass: isSelected?'checkmark-icon':'add-icon',
        primaryTextRight: pro.name,
        showArrow: false,
        arrowType: '',
        eventOwnerId: this.activityService.selectedActivity.ownerId
      };
    }),
  };
    this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail, isGroupedView:false},PageName.MainToolTemplateComponent);
  }
}


private _handleProductsComponentSearch(text: string): string[] {
  let ids: Array<string> = [];
  if (text.length >= 1) {
      ids = this.brandService.brands && this.brandService.brands.filter(pro => {
          return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.ID);
  } else {
      ids = this.brandService.brands && this.brandService.brands.map(pro => pro.ID);
  }
  return ids;
}
private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
  if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (this.activityService.selectedActivity && !this.activityService.selectedActivity.isCompleted && data && data.isDone) {
          if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length >= 0) {
            const foundProduct:Array<Brand> = [];
            let activityProduct:Array<Brand>  = [];
            if((this.activityService.selectedActivity as PhoneActivity).activityProducts.length){
               activityProduct = (this.activityService.selectedActivity as PhoneActivity).activityProducts;
            } else{
               activityProduct = this.brandService.brands;
            }
            data.selectedItems.forEach(item => {
              activityProduct.forEach(product =>{
                 if(product.ID === item.id){
                    product.isSelected = true;
                    foundProduct.push(product)
                 }
              });
              if (foundProduct) {
                this.tempProducts =  _.uniqBy(this.tempProducts.concat(foundProduct), 'ID');
              }
          });
          let index;
          data.removedItems.forEach(removedItem => {
            index = this.tempProducts.findIndex(activePro => {
              return (activePro.ID === removedItem.id);
            });
            if (index > -1) {
              this.tempProducts.splice(index, 1);
            }
          });
          }
          this._updateProduct();
      }
  } 
  // else if(eventTarget && eventTarget === 'itemRemoved'){
  //   if (!this.activityService.selectedActivity.isCompleted && data) {
  //     data.forEach((data, index)=>{
  //       this.tempProducts.forEach((pro, index) =>{
  //             if(data.id  == pro.ID){
  //              this.tempProducts.splice(index,1);
  //             }
  //          });
  //     });
  //   }
  // }
}
_updateProduct(){
  this.updateProductsFlag = true;
  this.saveProductKeyMessages();
}
}
