<ion-header>
    <ind-page-title [viewData]="eventRegistrationPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ng-container>
        <ion-list>
            <div *ngFor="let contactEvent of contactEvents">
                <ion-item-divider color="light" *ngIf="isCheckedInEvents(contactEvent.events)">{{contactEvent.contactName}}</ion-item-divider>
                <div *ngFor="let event of contactEvent.events">
                  <ion-item (click)="expandEventDetails($event,event);" *ngIf="event.indskr_reasons != 548910002 && event.indskr_reasons != 451680002 ">
                        <ion-label>
                            <h3>{{event.eventName}}</h3>
                            <h4 class="row">
                                <span class="overflowEllipsis" class="ellipsis" class="noellipsis">
                                    {{'ON' | translate}} {{getFormattedDate(event.eventStartDate)}}
                                </span>
                                <span class="overflowEllipsis" class="ellipsis">
                                    {{ 'CONTACT_REGISTERED_BY_OWNER' | translate : {text : event.registeredBy} }}
                                </span>
                            </h4>
                        </ion-label>
                        <ion-icon item-content class="status-indicator" class="indegene-event-signup-{{event.response == true? 'accepted' : event.response == false ? 'declined' : ''}}" class="light-color"></ion-icon>
                        <ion-icon [name]="event.eventExpandIcon" class="light-color" slot="end"></ion-icon>
                    </ion-item>
                    <expandable #expWrapper [viewType]="'eventDetails'" [customerEvent]="{event:event, register:true, contact: contactEvent.contactName, contactId: contactEvent.contactId}" [expandHeight]="'auto'" [expanded]="event.isExpanded" (eventRegStatus)='eventRegResponseChange($event)'>
                    </expandable>
                    <div *ngIf="event.isExpanded" class="separationLine"></div>
                </div>
            </div>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'event-registrations'" [selectedView]="'event-registrations'"></footer-toolbar>
<!-- </ion-footer> -->
