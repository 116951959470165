import { Component, AfterViewInit, ViewChild, ElementRef, TemplateRef, OnInit } from '@angular/core';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { NavParams } from '@ionic/angular';
import { CentralPlan } from '../../../classes/xperiences/trending.customer.class';
import { isToday, isTomorrow, isYesterday, format } from 'date-fns';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { XperiencesService } from '../../../services/xperiences/xperiences.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the MarketingEmailDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'marketing-email-details',
  templateUrl: 'marketing-email-details.html',
  styleUrls:['marketing-email-details.scss']
})
export class MarketingEmailDetailsComponent implements AfterViewInit, OnInit {

  public titleBar: IndPageTitleViewDataModel;
  public activityDetailsHeader: IndSectionHeaderViewDataModel;
  public previewHeader: IndSectionHeaderViewDataModel;
  public activityFormFields: Array<IndFormFieldViewDataModel> = [];
  @ViewChild('preview', {static:true}) previewContent;
  public activity: CentralPlan;

  constructor(
    private readonly navService: NavigationService,
    private readonly navParams: NavParams,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly xperiencesSerivce: XperiencesService,
    public readonly footerService: FooterService,
    private readonly notificationService: NotificationService,
    public translate: TranslateService) {
  }

  ngOnInit() {
    if ((this.navParams.data && this.navParams.data.viewData)) {
      this.activity = (this.navParams.data && this.navParams.data.viewData).email;
    }
    this.titleBar = {
      id: 'activities-details-page-title',
      title: this.translate.instant('XPERIENCES_MARKETING_EMAIL'),
      controls: [{
        id: 'back',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      },
      {
        id: 'negative',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('XPERIENCES_MARKETING_EMAIL_DISLIKE'),
        isDisabled: false,
        align: 'right'
      },
      {
        id: 'positive',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('XPERIENCES_MARKETING_EMAIL_LIKE'),
        isDisabled: false,
        align: 'right'
      }],
    };
    this.activityDetailsHeader = {
      id: 'activity-details-header',
      title: 'Activity details',
      controls: [
      ],
    };
    if ((this.navParams.data && this.navParams.data.viewData).centralPlan.feedback_Flag) {
      (this.navParams.data && this.navParams.data.viewData).centralPlan.feedback_Flag === 'positive' ? this.applyLikeControls() : this.applyDislikeControls();
    } else {
      this.footerService.updateButtons(['marketingEmailLike', 'marketingEmailDislike'], true);
      this.footerService.enableButtons(['marketingEmailLike', 'marketingEmailDislike']);
    }
    this.previewHeader = {
      id: 'activity-preview-header',
      title: 'preview',
      controls: [],
    };
    this.activityFormFields = [
      {
        label: 'Date',
        inputText: this.formattedDate(this.activity.date, false),
        id: 'date',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
      }, {
        label: 'To',
        inputText: this.activity.customer.fullName,
        id: 'to-contact',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
      }, {
        label: 'Subject',
        inputText: this.activity.subject,
        id: 'activity-subject',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
      }, {
        label: 'Product',
        inputText: this.xperiencesSerivce.getProductsText(this.activity.products),
        id: 'activity-product',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
      }
    ];
  }
  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'back':
        this.footerService.hideAllButtons();
        this.navService.popChildNavPageWithPageTracking();
        break;
      case 'positive':
        this.likeActivity();
        break;
      case 'negative':
        this.dislikeActivity();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  onSectionHeaderControlClick(id: string) {
    id === 'positive' ? this.likeActivity() : this.dislikeActivity();
  }

  ngAfterViewInit() {
    this.previewContent.getElementRef().nativeElement.innerHTML = this.activity.previewContent;
  }

  formattedDate(date: number, dateOnly: boolean): string {
    const scheduleDay = isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : isYesterday(date) ? 'Yesterday' : format(date, this.dateTimeFormatsService.dateToUpper);
    const startDate: Date = new Date(date);
    if (dateOnly) return scheduleDay;
    const scheduleTime = startDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return scheduleDay + ' ' + scheduleTime;
  }

  likeActivity() {
    (this.navParams.data && this.navParams.data.viewData).centralPlan.feedback_Flag = 'positive';
    this.applyLikeControls();
    this.notificationService.notify(this.translate.instant('XPERIENCES_THANKS_FOR_FEEDBACK'), 'Central Plan');
  }

  applyLikeControls() {
    this.footerService.updateButtons(['marketingEmailLikeActive', 'marketingEmailDislike'], true);
    this.footerService.disableButton(['marketingEmailLikeActive', 'marketingEmailDislike']);
    let control = this.titleBar.controls.find(c => c.id === 'positive');
    control.isDisabled = true;
    this.titleBar.controls = [control];
  }

  dislikeActivity() {
    (this.navParams.data && this.navParams.data.viewData).centralPlan.feedback_Flag = 'negative';
    this.applyDislikeControls();
    this.notificationService.notify(this.translate.instant('XPERIENCES_THANKS_FOR_FEEDBACK'), 'Central Plan');
  }

  applyDislikeControls() {
    this.footerService.updateButtons(['marketingEmailLike', 'marketingEmailDisllikeActive'], true);
    this.footerService.disableButton(['marketingEmailLike', 'marketingEmailDisllikeActive']);
    const control = this.titleBar.controls.find(c => c.id === 'negative');
    control.isDisabled = true;
    this.titleBar.controls = [control];
  }
}
