import { DB_KEY_PREFIXES } from './../../config/pouch-db.config';
import { DiskService } from './../disk/disk.service';
import { AuthenticationService } from './../authentication.service';
import { DynamicsClientService } from './../../data-services/dynamics-client/dynamics-client.service';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { multilingualLanguageToAttributeMapping, oneKeyCodeAttribute } from '@omni/config/dynamic-forms/default-contact/default-contact-create';
import { SearchConfigService } from '../search/search-config.service';
import { UIService } from '../ui/ui.service';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import es from '@angular/common/locales/es';
import en from '@angular/common/locales/en';
import ja from '@angular/common/locales/ja';
import de from '@angular/common/locales/de';
import nl from '@angular/common/locales/nl';
import tr from '@angular/common/locales/tr';
import pt from '@angular/common/locales/pt';
import it from '@angular/common/locales/it';
import zh_CN from '@angular/common/locales/zh';
import { FeatureActionsMap, OneKeyTokenRplacementLanguage } from '@omni/classes/authentication/user.class';
import { CustomerReplaceText, Utility } from '@omni/utility/util';
import _ from 'lodash';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { differenceInHours } from 'date-fns';
import { Country } from '@omni/classes/shared/country-list.class';

export class Language {
    public code: string;
    public name: string;
    public subName: string;
    public localeID: string;
}
@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
    public language = new Subject<Language>();

    /**
     * https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oe376/6c085406-a698-4e12-9d4d-c3b0ee3dbc4a
     * List of dynamics localedID
     * Sorted in alphabetical order
     */
    public languages: Language[] = [
        {
            "code": "nl",
            "name": "Dutch",
            "subName":"Nederlands",
            "localeID": "1043"
        },
        {
            "code": "en",
            "name": "English",
            "subName": "English",
            "localeID": "1033"
        },
        {
            "code": "fr",
            "name": "French",
            "subName": "Française",
            "localeID": "1036",
        },
        {
            "code": "de",
            "name": "German",
            "subName":"Deutsche",
            "localeID": "1031"
        },
        {
            "code": "ja",
            "name": "Japanese",
            "subName":"日本語",
            "localeID": "1041"
        },
        {
            "code": "es",
            "name": "Spanish",
            "subName":"Española",
            "localeID": "3082"
        },
        {
            "code": "it",
            "name": "Italy",
            "subName":"Italia",
            "localeID": "1040"
        },
        {
            "code": "pt",
            "name": "Portuguese (Portugal)",
            "subName": "pôrCHəgəl",
            "localeID": "2070"
        },
        {
          "code": "tr",
          "name": "Turkish",
          "subName": "Türkçe",
          "localeID": "1055"
        },
        {
          "code": "zh_CN",
          "name": "Simplified Chinese",
          "subName":"简体中文",
          "localeID": "2052"
        }
        // {
        //     "code": "pt-bz",
        //     "name": "Portuges (Brazil)",
        //     "subName":"brəˈzil"
        // }

    ]
    ;
    public languageSelected: Language
    public defaultLanguage: Language = this.languages.find(l => l.localeID == "1033");
    multiLingualAttributes = ['omnione_lu_affiliationrole','omnione_lu_professionaldesignation'];
    public fullSyncRequiredForOnekeyCodes: boolean = false;

    public multiLingualFieldsData:Array<any> = [];
    private _multiLingualFieldsDataIntializedCheck:boolean = false;
    private _multilingualDataMappingServiceWorker;
    public multilingualLabels:Array<any> = [];
    public countryList: Array<Country> = [];
    public languageList: any[] = [];

    constructor(
        private http: HttpClient,
        private translate : TranslateService,
        private readonly dynamicsClient: DynamicsClientService,
        private readonly authService: AuthenticationService,
        private readonly disk: DiskService,
        private searchConfigService: SearchConfigService,
        private ngZone: NgZone,
        private uiService: UIService,
    ) {
        this.languageSelected = this.defaultLanguage;
        this._initMultilingualDataMappingServiceWorker();
    }

    /**
     * Language getter method
     *
     * @returns {Observable<any>}
     * @memberof LocalizationService
     */
    public getLanguages() {
        return this.languages;
        // return this.http.get(this.url);
    }
    /**
     * Returns selected language
     *
     * @readonly
     * @type {Language}
     * @memberof LocalizationService
     */
    public get selectedLanguage(): Language {
        return this.languageSelected;
    }
    /**
     * Language setter method
     *
     * @param {Language} lang
     * @memberof LocalizationService
     */
    public setLanguage(lang: Language) {
        this.languageSelected = lang;
    }

    public async getOneKeyCodesAndLabelsData(fullSync, loadFromDB){
      if(loadFromDB || !this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin')) return;
      // let attributes:string[] = [`${multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]}`,'omnione_onekeycodeslabelsid','statecode','omnione_schemafieldname','omnione_cod_id_onekey'];
      let attributes:string[] = ['omnione_onekeycodeslabelsid','statecode','omnione_schemafieldname','omnione_cod_id_onekey'];
      if(this.authService.user.buConfigs['indskr_onekeytokenreplacementlanguage'] == OneKeyTokenRplacementLanguage.Customer) {
        attributes = [...oneKeyCodeAttribute, ...attributes];
        this.fullSyncRequiredForOnekeyCodes = true;
      } else {
        attributes.push(this.getOneKeyTableAttributes());
      }
      if(this.authService.user.localeId != '1033') attributes.push('omnione_en_long_label');
      let filterString: string = ``;
      // let filterString: string = `(omnione_bu eq '${this.authService.user.businessUnitName}' or omnione_bu eq null) `;

      let lastUpdateTime;
      let now = new Date();
      let offlineData = [];
      await this.disk.retrieve(DB_KEY_PREFIXES.ONEKEY_CODES_LABELS, true).then((doc)=>{
        if(!doc || !doc.raw){
          fullSync = true;
        }
        else{
          offlineData = doc.raw
          lastUpdateTime = doc.lastUpdated
        }
      })
      fullSync = fullSync || this.fullSyncRequiredForOnekeyCodes
      if(!fullSync && lastUpdateTime){
        filterString+= `modifiedon ge ${new Date(lastUpdateTime).toISOString()} `
      }
      let counter = 0;
      for(let attr in this.multiLingualAttributes){
        counter++;
        if(counter == 1) filterString +=`${filterString.length ? ' and':''} (` 
        else if(counter >1) filterString +=`${filterString.length ? ' or':''} `
        filterString += `omnione_schemafieldname eq '${this.multiLingualAttributes[attr]}'`
        if(counter == this.multiLingualAttributes.length) filterString +=')'
      }
      let res = await this.dynamicsClient.retrieveAll('omnione_onekeycodeslabelses',
                                                    attributes,
                                                    filterString
                                                  ).then(res => res.value).catch(()=> []);

      if(!fullSync && offlineData?.length){
        res.forEach(r=>{
          let existingIndex = offlineData.findIndex(o=>o['omnione_onekeycodeslabelsid']==r['omnione_onekeycodeslabelsid']);
          if(existingIndex){
            offlineData[existingIndex] = r
          }
          else{
            offlineData.push(r)
          }
        })
      }
      else offlineData = res;
      const activeOfflineData = offlineData.filter(o=>o['statecode']==0);
      // this._postMessageOnMultilingualDataMappingWorker({rawList: activeOfflineData,multilingualAttributeName:multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]});
      this._postMessageOnMultilingualDataMappingWorker({rawList: activeOfflineData,multilingualAttributeName:this.getOneKeyTableAttributes()});
      this.disk.updateOrInsert(DB_KEY_PREFIXES.ONEKEY_CODES_LABELS,(doc)=>{
        doc = {
          raw: []
        };
        doc.raw = offlineData;
        this.multiLingualFieldsData = offlineData;
        doc.lastUpdated = now.getTime();
        return doc;
      })
    }

    public async getMultilingualFieldsData(){
      try {
        let offlineRecords = await this.disk.retrieve(DB_KEY_PREFIXES.ONEKEY_CODES_LABELS, true);
        if(offlineRecords && offlineRecords.raw && Array.isArray(offlineRecords.raw)){
          return offlineRecords.raw;
        }else{
          return [];
        }
      } catch (error) {
        console.log(error);
      }
    }

    public async initMultilingualFieldsData() {
      if (this.multiLingualFieldsData?.length) return;
      if(this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin') && !this._multiLingualFieldsDataIntializedCheck){
        try {
          let offlineRecords = await this.disk.retrieve(DB_KEY_PREFIXES.ONEKEY_CODES_LABELS, true);
          if(offlineRecords && offlineRecords.raw && Array.isArray(offlineRecords.raw)){
            this.multiLingualFieldsData = offlineRecords.raw;
            this._multiLingualFieldsDataIntializedCheck = true;
          }else{
            this.multiLingualFieldsData = [];
            this._multiLingualFieldsDataIntializedCheck = true;
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    public resetMultilingualFieldsData() {
      this.multiLingualFieldsData = [];
      this._multiLingualFieldsDataIntializedCheck = false;
    }

    private _postMessageOnMultilingualDataMappingWorker(data){
      this._multilingualDataMappingServiceWorker.postMessage(data);
    }

    private _initMultilingualDataMappingServiceWorker() {
      this._multilingualDataMappingServiceWorker = new Worker('./assets/workers/multilingual-data-mapping-worker.js');
      this._multilingualDataMappingServiceWorker.onmessage = (event) => {
          this.ngZone.run(() => {
              if(event && event.data){
                  if(this.searchConfigService.accountConfiguredSearchIndexConfig && this.searchConfigService.accountConfiguredSearchIndexConfig.length > 0){
                    this.searchConfigService.accountConfiguredSearchIndexConfig.forEach(config=> {
                      if(config.isMultilingualLookup){
                        if(config.controlDataType == "LinkedEntity"){
                          let attribName = config.categoryRelativePath;
                          attribName = attribName.replace(config.entity+'.','');
                          if(event.data[attribName]){
                            config.values = event.data[attribName].values;
                            config.mappingValues = event.data[attribName].mappingValues;
                          }
                        }else if(event.data[config.categoryRelativePath]){
                          config.values = event.data[config.categoryRelativePath].values;
                          config.mappingValues = event.data[config.categoryRelativePath].mappingValues;
                        }
                      }
                    })
                  }
                  if(this.searchConfigService.contactConfiguredSearchIndexConfig && this.searchConfigService.contactConfiguredSearchIndexConfig.length > 0){
                    this.searchConfigService.contactConfiguredSearchIndexConfig.forEach(config=> {
                      if(config.isMultilingualLookup){
                        if(config.controlDataType == "LinkedEntity"){
                          let attribName = config.categoryRelativePath;
                          attribName = attribName.replace(config.entity+'.','');
                          if(event.data[attribName]){
                            config.values = event.data[attribName].values;
                            config.mappingValues = event.data[attribName].mappingValues;
                          }
                        }else if(event.data[config.categoryRelativePath]){
                          config.values = event.data[config.categoryRelativePath].values;
                          config.mappingValues = event.data[config.categoryRelativePath].mappingValues;
                        }
                      }
                    })
                  }
              }
          })
      };
  }

  getOneKeyCodesLabelFormattedValue(id: string): string {
    // if id exists and find in this.multiLingualFieldsData where omnione_onekeycodeslabelsid == id and return dynamics_language_code_"+this.authService.user.localeId property or return undefined
    if(id && this.multiLingualFieldsData && this.multiLingualFieldsData.length){
      let record = this.multiLingualFieldsData.find(r=>r['omnione_onekeycodeslabelsid']==id);
      if(record){
        // const fieldName = multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId];
        // return record[fieldName];
        let key = this.getOneKeyTableAttributes();
        let translatedLang = record[key] ? record[key] || '' : record['omnione_en_long_label'] || '';
        return translatedLang;
      }
    }
    return undefined;
  }

  getOneKeyCodesLabelFormattedValueByPreferredLang(id: string, countryCode:string, langCode:string): string {
    // if id exists and find in this.multiLingualFieldsData where omnione_onekeycodeslabelsid == id and return dynamics_language_code_"+this.authService.user.localeId property or return undefined
    if(id && this.multiLingualFieldsData && this.multiLingualFieldsData.length){
      let record = this.multiLingualFieldsData.find(r=>r['omnione_onekeycodeslabelsid']==id);
      if(record){
        // const fieldName = multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId];
        // return record[fieldName];
        
        let key = this.authService.user.buConfigs['indskr_onekeytokenreplacementlanguage'] == OneKeyTokenRplacementLanguage.Customer ? this.getOneKeyTableAttributesByPreferredLang(countryCode, langCode) : this.getOneKeyTableAttributes();
        let translatedLang = record[key] ? record[key] || '' : record['omnione_en_long_label'] || '';
        return translatedLang;
      }
    }
    return undefined;
  }

  public async updateDyanmicsLanguage(language) {
    return await this.dynamicsClient.update(this.authService.user.systemUserID , "usersettingscollection", {"uilanguageid" : language.localeID, "helplanguageid": language.localeID});
  }

  public async getDynamicsLanguage() {
    return await this.dynamicsClient.executeUnboundFunction('RetrieveAvailableLanguages');
  }

  updateLanguage(localeID) {
    let lang = this.languages.find(l => l.localeID == localeID);
    localStorage.setItem("selectedLanguage", lang.code);    
    this.applyLangaugeSelected(lang);
  }

  public async applyLangaugeSelected(lang) {
    this.setLanguage(lang);
    await this.uiService.displayLoader();
    switch (lang.code) {
      case 'fr':
        registerLocaleData(fr);
        break;
      case 'es':
        registerLocaleData(es);
        break;
      case 'ja':
        registerLocaleData(ja);
        break;
      case 'de':
        registerLocaleData(de);
        break;
      case 'nl':
        registerLocaleData(nl);
        break;
      case 'tr':
        registerLocaleData(tr);
        break;
      case 'pt':
        registerLocaleData(pt);
        break;
      case 'it':
        registerLocaleData(it);
        break;
        case 'zh_CN':
          registerLocaleData(zh_CN);
          break;
      default:
        registerLocaleData(en);
        break;
    }
    this.setGlobalCustomerTextAfterTranlation()
    this.uiService.agendaRefreshRequired = true;
    await this.translate.use(lang.code);
    this.searchConfigService.configUpdateRequired = true;
    this.searchConfigService.isConfigInitiated = false;
  }

  public setGlobalCustomerTextAfterTranlation() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.CONTACT_UPDATE)) {
      Utility.globalCustomerText = CustomerReplaceText.GLOBAL_STAKEHOLDER_REPLACE_TEXT;
    } else {
      Utility.globalCustomerText = CustomerReplaceText.GLOBAL_CUSTOMER_REPLACE_TEXT
    }
  }

  getLanguageDetails() {
    let selectedLang;
    let languageCode = localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : (typeof navigator !== 'undefined' ? navigator.language : 'en');
    let languagesList = this.getLanguages();
    let index = _.findIndex(languagesList, {code : languageCode});
    if(index != -1) {
      selectedLang = languagesList[index];
    } else {
      selectedLang = this.defaultLanguage;
    }
    this.setLanguage(selectedLang);
  }

  public async fetchMultilingualLabels(fullSync?: boolean, loadFromDbOnly = false) {      
    try {
      let offlineDataStored;
      let lastModifiedForDeltaSync, hourDifference;
      ({ offlineDataStored, fullSync } = await this.retrieveMultilinguallabels(offlineDataStored, fullSync));
      if(offlineDataStored && offlineDataStored.raw) this.multilingualLabels = offlineDataStored.raw;

      if (loadFromDbOnly) {
        this.mapAndSaveMultilingualLookupLabels(undefined, false, offlineDataStored, true);
        return;
      }

      let fetchXML = fetchQueries.multilingual.multilingual_lookup_labels;
      fetchXML = fetchXML.replace('{languageCode}', this.languageSelected.localeID);
      const now = new Date();

      if (fullSync) {
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', '');
      } else {
        let deltaSyncFilter;
        lastModifiedForDeltaSync = offlineDataStored.lastModified;
        if (lastModifiedForDeltaSync) {
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          hourDifference += 1
          deltaSyncFilter = `<condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />`
          fetchXML = fetchXML.replace('{DeltaSyncFilter}', deltaSyncFilter);
        } else {
          fetchXML = fetchXML.replace('{DeltaSyncFilter}', '')
        }
      }

      await this.dynamicsClient.executeFetchQuery('indskr_multilinguallookuplabelses', fetchXML)
        .then(async (res) => {
          if(!_.isEmpty(res)) {
            this.mapAndSaveMultilingualLookupLabels(res, fullSync, offlineDataStored);
          }
        },(err) => {
            console.log(err)
        })
    } catch (error) {
      console.log(error)
    }
  }

  private async retrieveMultilinguallabels(offlineDataStored: any, fullSync: boolean) {
    await this.disk.retrieve(DB_KEY_PREFIXES.MULTILINGUAL_LOOKUP_LABELS, true).then((doc) => {
      if(doc) {
        offlineDataStored = doc;
      } else {
        offlineDataStored = [];
        fullSync = true;
      }
    });
    return { offlineDataStored, fullSync };
  }
  
  private async mapAndSaveMultilingualLookupLabels(data, isInitialSync, offlineDocs, doNotSave = false) {
    let labels = [];
    if (!isInitialSync && offlineDocs && offlineDocs.raw) labels = offlineDocs.raw;
    if (!_.isEmpty(data)) {
      if(isInitialSync) {
        data.forEach(lbs => {
          let idx = labels.findIndex(lb => lb.indskr_multilinguallookuplabelsid === lbs['indskr_multilinguallookuplabelsid']);
          if (idx > -1) {
            if(lbs.statecode == 1) labels.splice(idx, 1);
            else labels[idx] = lbs;
          } else {
            if(lbs.statecode == 0) labels.push(lbs)
          }
        })
        const sorted = labels?.sort((a,b) => a['indskr_name'].toLowerCase() > b['indskr_name'].toLowerCase() ? 1 : -1);
        const grouped = sorted.reduce((groups, lbl) => {
          const letter = lbl['indskr_name'].charAt(0).toLowerCase();   
          groups[letter] = groups[letter] || [];
          groups[letter].push(lbl);
          return groups;
        }, {});   
        labels = grouped;
      } else {
        data.forEach(lbs => {
          let firstLetter = lbs['indskr_name'].charAt(0).toLowerCase(); 
          let idx = labels[firstLetter].findIndex(lb => lb.indskr_multilinguallookuplabelsid === lbs['indskr_multilinguallookuplabelsid']);
          if (idx > -1) {
            if(lbs.statecode == 1) labels[firstLetter].splice(idx, 1);
            else labels[firstLetter][idx] = lbs;
          } else {
            if(lbs.statecode == 0) labels[firstLetter].push(lbs)
          }
        })
      }
    }
    if (!doNotSave) {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.MULTILINGUAL_LOOKUP_LABELS, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          }
        }
        doc.raw = labels;
        doc.lastModified = new Date().getTime();
        return doc;
      }).catch(err => console.log("Failed to save multiligual Lookup labels InDB ", err));
    }

    this.multilingualLabels = labels;
    return labels;
  }

  public async fetchMultilingualEntities(fullSync?: boolean, loadFromDbOnly = false) { 
    if (loadFromDbOnly) {
      // It seems this data is not being used anywhere as of March 2023.
      // Will just prevent this to run during the session restore
      return;
    }

    try {
      let offlineDataStored;
      let lastModifiedForDeltaSync, hourDifference;
      await this.disk.retrieve(DB_KEY_PREFIXES.MULTILINGUAL_LOOKUP_ENTITIES, true).then((doc) => {
        if(doc) {
          offlineDataStored = doc;
        } else {
          offlineDataStored = [];
          fullSync = true;
        }
      })
      let fetchXML = fetchQueries.multilingual.multilingual_lookup_entities;
      const now = new Date();
    
      if (fullSync) {
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', '');
      } else {
        let deltaSyncFilter;
        lastModifiedForDeltaSync = offlineDataStored.lastModified;
        if (lastModifiedForDeltaSync) {
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          hourDifference += 1
          deltaSyncFilter = `<condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />`
          fetchXML = fetchXML.replace('{DeltaSyncFilter}', deltaSyncFilter) 
        } else {
          fetchXML = fetchXML.replace('{DeltaSyncFilter}', '')
        }
      }
    
      await this.dynamicsClient.executeFetchQuery('indskr_multilinguallookups', fetchXML).then(
        async (res) => {
          if(!_.isEmpty(res)) {
            this.saveMultilingualLookupEntities(res, fullSync, offlineDataStored);
          }
        },(err) => {
          console.log(err)
        })
    } catch (error) {
      console.log(error)
    }
  }

  private async saveMultilingualLookupEntities(data, isInitialSync, offlineDocs) {
    let entities = [];
    if (!isInitialSync && offlineDocs && offlineDocs.raw) entities = offlineDocs.raw;
    if (!_.isEmpty(data)) {
      data.forEach(ent => {
        let idx = entities.findIndex(en => en.indskr_multilinguallookupid === ent['indskr_multilinguallookupid']);
        if (idx > -1) {
          entities[idx] = ent;
        } else {
          entities.push(ent)
        }
      })
    }
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.MULTILINGUAL_LOOKUP_ENTITIES, (doc) => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        }
      }
      doc.raw = entities;
      doc.lastModified = new Date().getTime();
      return doc;
    }).catch(err => console.log("Failed to save multiligual Lookup entities InDB ", err))
    return entities;
  }

  public getTranslatedString(sourceStr: string, isSetToEnglish: boolean = false): string {
    if(_.isEmpty(sourceStr)) return '';
    sourceStr = sourceStr.trim()

    let translatedStr: string = sourceStr;
    let firstLetter = sourceStr.charAt(0).toLowerCase();
    if(firstLetter && !_.isEmpty(this.multilingualLabels)) {
      let foundLbl;
      if(!isSetToEnglish) {
        foundLbl = this.multilingualLabels[firstLetter]?.find(label => label['indskr_name'].toLowerCase() == sourceStr.toLowerCase() && label['indskr_languagecode'] == this.languageSelected.localeID);
      }else {
        foundLbl = this.multilingualLabels[firstLetter]?.find(label => label['indskr_name'].toLowerCase() == sourceStr.toLowerCase() && label['indskr_languagecode'] == '1033');
      }
      if(!_.isEmpty(foundLbl)) translatedStr = foundLbl['indskr_translation'];
    }  
    return translatedStr;
  }

  public _getLocaleCodefromId(localeId){
    switch (localeId) {
      case 1043:
        return 'nl';
      case 1033:
        return 'en' ;
      case 1036 :
        return 'fr';
      case 1031 :
        return 'de';
      case 1041 :
        return 'ja';
      case 1055:
        return 'tr' ;
      case 2070:
        return 'pt';
      case 1040 :
        return 'it';
      case 3082 :
        return 'es';
      case 2052 :
        return 'zh';
      default :
        return 'en';
    }
  }

  public getOneKeyTableAttributes(): string {
    let attr = `omnione_en_long_label`;
    if(this.authService.user.localeId != '1033') {
      attr = `omnione_${this._getLocaleCodefromId(this.authService.user.localeId)}_${(this.authService.user.countryId)?.toLowerCase()}_long_label`;
      let isKeyValid = oneKeyCodeAttribute.some(att => att == attr);
      if(!isKeyValid) attr = multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId];
    }
    return attr;
  }

  public getOneKeyTableAttributesByPreferredLang(countryCode: string, langCode: string): string {
    let attr = `omnione_en_long_label`;
    if(countryCode && langCode) {
      attr = `omnione_${langCode.toLowerCase()}_${countryCode.toLowerCase()}_long_label`;
      let isKeyValid = oneKeyCodeAttribute.some(att => att == attr);
      if(!isKeyValid) attr = multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId];
    } else attr = this.getOneKeyTableAttributes();
    return attr;
  }

  public getCountryCodeByName(name: string): string {
    let countryName = name.toLocaleLowerCase();
    let countryCode = this.countryList.find(country => country.countryName.toLocaleLowerCase().includes(countryName))?.countryCode ?? '';
    return countryCode;
  }

  public getLangCodeById(id: string): string {
    let langCode = this.languageList.find(lang => lang.id == id)?.code ?? '';
    return langCode;
  }
}
