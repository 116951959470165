import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerSampleAllocation } from "@omni/classes/sample/customer-sample-allocation.class";

@Component({
  selector: 'allocation-eligibility',
  templateUrl: 'allocation-eligibility.html',
  styleUrls: ['allocation-eligibility.scss']
})
export class AllocationEligibilityComponent {
  @Input() sampleEligibilities: Array<CustomerSampleAllocation>;

  constructor(public translate: TranslateService) { }
}