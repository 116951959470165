import { TencentService } from './../../services/video-streaming/tencent.service';
import { electronApi } from '@omni/services/electron-api';
import { ProcedureTrackerActivityDataService } from './../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { ProcedureTrackerDetailsComponent } from './../../components/surgery-order/procedure-tracker-details/procedure-tracker-details';
import { ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
/* eslint-disable @typescript-eslint/member-ordering */

import {catchError, takeUntil, debounceTime} from 'rxjs/operators';
import { AuthenticationService } from './../../services/authentication.service';
import { Component, ViewChild, HostBinding, HostListener, Injector, ChangeDetectorRef, ElementRef, AfterViewInit, OnInit, Input } from '@angular/core';
import { Presentation, Page, KeyMessage, ContentMode, SwipeStatus } from '../../classes/presentation/presentation.class';
import { PresentationService } from '../../services/presentation/presentation.service';
import { FooterViews, FooterService } from '../../services/footer/footer.service';
import { NavParams, LoadingController, DomController, IonNav, ModalController } from '@ionic/angular';
import { PresentationView, UIService } from '../../services/ui/ui.service';
import { ActivityService, ActivitySource } from '../../services/activity/activity.service';
import { WebsocketDataService } from '../../data-services/websocket/websocket.data.service';
import { SENTIMENT } from '../../data-services/websocket/payloads/sentiment.payload';
import { DeviceService } from '../../services/device/device.service';
import { MeetingDataService, UpdateKeyMessagesFor } from '../../data-services/meeting/meeting.data.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { Brand } from '../../classes/brand/brand.class';
import { Subscription, Subject, Observable, EMPTY } from 'rxjs';
import { NavigationService, ChildNavNames, PageName } from '../../services/navigation/navigation.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { REP_STATUS } from '../../models/rep-status-model';
import { EventsService } from '../../services/events/events.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { Contact, ContactMeetingState } from '../../classes/contact/contact.class';
import { IoEventListener } from '../../services/io-event-listener.decorator';
import { TherapeuticAreaDataService } from '../../data-services/therapeutic-area/therapeutic-area.data.service';
import { TherapeuticArea } from '../../classes/therapeutic-area/therapeutic-area.class';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { MeetingStructureService } from '../../services/meeting-structure/meeting-structure.service';
import { EmbeddedInteraction, EmbeddedInteractionType, Activity, ActivityType } from '../../classes/activity/activity.class';
import { EmailService } from '../../services/email-templates/email.service';
import { CaseManagementService, AccesingMode } from "../../services/case-management/case-management.service";
import { EmailViewType, EmailActivity } from '../../classes/activity/email.activity.class';
import { EmailActivityDetailComponent } from '../../components/activity/email-activity-detail/email-activity-detail';
import { CaseActivity } from '../../classes/case-intake/case-activity.class';
import { CaseManagementDetailComponent } from '../../components/case-management/case-management-detail/case-management-detail';
import { SampleService, SamplingDetailsViewMode } from '../../services/sample/sample.service';
import { SampleActivity } from '../../classes/activity/sample.activity.class';
import { NewSampleActivityComponent } from '../../components/activity/new-sample-activity/new-sample-activity';
import { ActivityDataService } from '../../data-services/activity/activity.service';
import { NewActivityComponent } from '../../components/activity/new-activity/new-activity';
import { Resource } from '../../classes/resource/resource.class';
import { ResourceService } from './../../services/resource/resource.service';
import _ from 'lodash';
import { Endpoints } from 'src/config/endpoints.config';
import { HttpClient } from '@angular/common/http';
import { OpentokService, OTState } from '@omni/services/video-streaming/opentok.service';
import { SpeechSDKService } from '@omni/services/speechskd.service';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { PollService } from '@omni/services/poll/poll.service';
import { SurveyService } from './../../services/survey/survey.service';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { FollowUpActivityDataService } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { FollowUpActivity } from '@omni/classes/activity/follow-up-action.activity.class';
import { FollowUpActionDetailComponent } from '@omni/components/activity/follow-up-action-detail/follow-up-action-detail';
import { PresentationDataService } from '../../data-services/presentation/presentation.data.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AssessmentTemplate, SurveyCategory, SurveyStatus, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { MeetingStatus } from '@omni/classes/meeting/meeting.class';
import { IoShortCallDetailsComponent } from '@omni/components/io-component/io-short-call-details/io-short-call-details.component';

@Component({
    selector: 'presentation-meeting[base-page]',
    templateUrl: 'presentation-meeting.html',
  styleUrls:['presentation-meeting.scss'],
})
export class PresentationMeetingComponent implements OnInit, AfterViewInit {

    @ViewChild('carousel', {static: true}) carousel: any;
    @HostBinding('class.carousel-hidden') get hideCarousel() {
        return this.presentationService.hideCarousel && !this.presentationService.isReferenceVisible;
    }
    @HostBinding('class.short-call') get isShortCall() {
      const appointment = this.activityService.selectedActivity;
      return appointment && appointment instanceof AppointmentActivity && appointment.indskr_shortcall;
    }

    public viewMode: PresentationView;

    public presList: (Presentation | Resource)[];
    public presData: Presentation | Resource;
    public selectedPageUrl: string;
    public selectedPage: Page;

    public presMode = PresentationView;
    public shouldShowPresentationCarousel = true;

    private _brands: Brand[];
    @ViewChild("subscriberVids") subscriberVids: ElementRef<HTMLDivElement>;
    videoElement: HTMLElement;
    // subscription
    private curSelPresSubscription: Subscription;
    private curSelPageSubscription: Subscription;
    private carouselSubscription: Subscription;
    private subscriptions: { unsubscribe: Function }[] = []
    private ngDestroy$: Subject<boolean> = new Subject<boolean>();
    public isScreenSharingAllowed: boolean;
    private remoteFeatureEnabled: boolean;
    private isVOIPAllowed: boolean;
    private isVideoAllowed: boolean;
    public canCaptureSentiments: boolean = true;
    public tencentEnabled: boolean = false;
    //@ViewChild(Content, {static: true}) content: Content;
    public showContent: boolean = true;
    @ViewChild('meetingpresentationpane', {static: true})
    private navCtrl: IonNav;
    public embeddedInteractions: EmbeddedInteraction[] = [];
    public interactionType = EmbeddedInteractionType;
    private IcurrentMeetingStructureSubscription: Subscription;
    private IcurrentMeetingActivitySubscription: Subscription;
    public selectedMeetingActivity: EmbeddedInteraction;
    private newOrientation: number = null;
    public disablePlayIconsFlag: boolean = false;
    public pageName = PageName;
    public videoElements:HTMLElement[];


    private screenTimeForContacts: { indskr_contactid: string, startTime: number, endTime?: number}[] = [];
    isAndroid: boolean = false;
    private currentPdfPage: number = 1;
    private timeout;
    public backgroundUploadInProgress: boolean = false;
    private isProductIndicationsEnabled: boolean = false;
    @Input() onShortCallCompletion: (data: any) => void;
    @Input() from: PageName;
    private _deviceConnectionChangeHandler;

    constructor(
        public presentationService: PresentationService,
        public footerService: FooterService,
        public activityService: ActivityService,
        public websocket: WebsocketDataService,
        public deviceService: DeviceService,
        private meetingDataService: MeetingDataService,
        private brandsOfflineService: BrandOfflineService,
        private _dom: DomController,
        public loadingController: LoadingController,
        private events: EventsService,
        private navService: NavigationService,
        private repService: RepServices,
        private authService: AuthenticationService,
        private speechService: SpeechSDKService,
        public uiService: UIService,
        private therapeuticService: TherapeuticAreaDataService,
        private loadingCtrl: LoadingController,
        public translate: TranslateService,
        private notificationService: NotificationService,
        private meetingStructureService: MeetingStructureService,
        private emailService: EmailService,
        public caseService: CaseManagementService,
        private sampleService: SampleService,
        private activityDataService: ActivityDataService,
        private navParams: NavParams,
        private resourceService: ResourceService,
        public injector: Injector,
        private http: HttpClient,
        public opentokService: OpentokService,
        public _cd: ChangeDetectorRef,
        private surgeryOrderDataService: SurgeryOrderActivityDataService,
        private procedureTrackerDataService: ProcedureTrackerActivityDataService,
        private followupActivityDataService: FollowUpActivityDataService,
        private keyboard: Keyboard,
        private pollService: PollService,
        private surveyService: SurveyService,
        private ioFileDownload: IoFileService,
        public presDataService: PresentationDataService,
        private iab: InAppBrowser,
        private tencentService: TencentService,
        private customerSurveyService: CustomerSurveyService,
        private customerAssessService: CustomerAssessService,
        private contactService: ContactOfflineService,
        private accountService: AccountOfflineService,
        private modalCtrl: ModalController
    ) {
        this.viewMode = this.presentationService.viewMode;
        this.isScreenSharingAllowed = this.deviceService.deviceFlags.desktop && this.deviceService.deviceFlags.web && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_SCREENSHARE)
        this.remoteFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE);
        this.isVideoAllowed = this.authService.hasFeatureAction(FeatureActionsMap.VIDEO_BUTTON);
        this.isVOIPAllowed = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_VOIP);
        this.isProductIndicationsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_INDICATIONS);
        this.disablePlayIconsFlag = this.isScreenSharingAllowed;
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
        let restoreCarousel: boolean = undefined
        this.tencentEnabled = this.remoteFeatureEnabled && this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING);
        this.subscriptions.push(this.keyboard.onKeyboardHide().pipe(debounceTime(100)).subscribe(() => {
          if (restoreCarousel && this.selectedMeetingActivity.type === EmbeddedInteractionType.Content) {
            this.presentationService.hideCarousel = false;
            this.footerService.showButton('carouselactive');
            this.footerService.hideButton('carousel');
          }
          this.events.publish("footer-toolbar:carousel-toggle");
        }));
        this.subscriptions.push(this.keyboard.onKeyboardShow().pipe(debounceTime(100)).subscribe(() => {
          restoreCarousel = undefined
          if (this.selectedMeetingActivity.type === EmbeddedInteractionType.Content && !this.presentationService.hideCarousel) {
            restoreCarousel = true;
            this.presentationService.hideCarousel = true;
            this.footerService.showButton('carousel');
            this.footerService.hideButton('carouselactive');
          }
          this.events.publish("footer-toolbar:carousel-toggle");
        }));
    }

    ngOnInit() {
        document.getElementById("chat").style.display = "none";
        this.isAndroid = this.deviceService.isAndroid();
        this.events.publish("session-stack:stop");
        this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.MeetingPresentationNavigation, PageName.PresentationMeetingComponent);
        this.IcurrentMeetingStructureSubscription = this.meetingStructureService.currentMeetingStructureObserver.subscribe((value: EmbeddedInteraction[]) => {
            if (value && value.length > 0) {
                this.embeddedInteractions = value;
                this.presentationService.meetingPresentationList = <(Presentation | Resource)[]>value.filter(ei => ei.type === EmbeddedInteractionType.Content).map(ei => { return ei.interaction });
            } else {
                this.presentationService.meetingPresentationList = [];
                this.embeddedInteractions = [];
            }
        });

        // Update status to in meeting if not.
        if (!this.deviceService.isOffline && this.repService.getCurrentUserState() !== REP_STATUS.IN_MEETING.userState) {
            let newStateData = { userState: REP_STATUS.IN_MEETING.userState };
            if (!this.deviceService.isNativeApp) {
                this.repService.setCurrentState(newStateData);
            }
        }

        // this._brands = this.brandsOfflineService.brands;
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        if (this.isShortCall) {
              this.isScreenSharingAllowed = this.remoteFeatureEnabled = this.isVideoAllowed = this.isVOIPAllowed = this.tencentEnabled = false;
              this.isProductIndicationsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_INDICATIONS);
              this.presentationService.swipeStatus = SwipeStatus.DISABLED_BY_USER;
              this.presentationService.hideCarousel = true;
              this._deviceConnectionChangeHandler = () => {
                if (this.deviceService.isOffline) {
                  this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'presentation-meeting', 'top', ToastStyle.DANGER);
                }
                this.footerService.initButtons(FooterViews.PreviewMeeting)
              }
              this.events.subscribe('device:deviceIsOffline', this._deviceConnectionChangeHandler);
              this.events.subscribe('device:deviceIsOnline', this._deviceConnectionChangeHandler);
            }
            //Added playlist presentation
            this.presentationService.activtyPlaylist = this.activityService.selectedActivity['presentations'];

            if (!this.activityService.selectedActivity.products) {
                this.brandsOfflineService.deSelectAll();
                this.activityService.selectedActivity.products = this.brandsOfflineService.brands.slice();
            }
            //this._brands = this.activityService.selectedActivity.products;
            // console.log("what is this!!!!");
            // console.log(this._brands);
        }

        this.carouselSubscription = this.presentationService.carouselBriefcase.subscribe(pres => {
            if (this.navService.getCurrentPageName() != PageName.PresentationMeetingComponent) return;
            this.presList = pres;
        });

        this.curSelPresSubscription = this.presentationService.currentSelectedPres.subscribe((pres: Presentation | Resource) => {
            if (this.navService.getCurrentPageName() != PageName.PresentationMeetingComponent) return;
            if (pres && (this.presentationService.viewMode === this.presMode.MEETING || this.presentationService.viewMode === this.presMode.ADDTOMEETING)) {
                this.presData = pres;
                const id = pres['ioPresentationId'] ? pres['ioPresentationId'] : (pres['ioDocumentId'] ? pres['ioDocumentId'] : pres['ioResourceId']);
                const embeddedInteraction: EmbeddedInteraction = this.embeddedInteractions.filter(activity => activity.type === EmbeddedInteractionType.Content).find(content => content.id === id);
                if (embeddedInteraction) {
                    this.showContent = true;
                    this.initFooterForContent(pres);
                    this.meetingStructureService.setCurrentMeetingActivity(embeddedInteraction);
                    this.scrollToSelectedActivity();
                }
                if (this.presData) {
                    this.carousel.updateCarousel();
                }
            }
        });

        this.curSelPageSubscription = this.presentationService.currentSelectedPresPage.subscribe(async (page: Page) => {
            if (this.navService.getCurrentPageName() != PageName.PresentationMeetingComponent) return;
            if (this.presentationService.viewMode === this.presMode.MEETING || this.presentationService.viewMode === this.presMode.ADDTOMEETING) {
                const isDifferentPage = page && !_.isEqual(page, this.selectedPage);
                if (isDifferentPage) {
                  // Fetch reference data for the page
                  this.presDataService.getPageReferences(
                    (this.presentationService.activePresentation as Presentation).ioPresentationId,
                    Number(page.pageSequence),
                    page.id,
                  );
                  if (this.websocket.meetingInProgress) {
                    await this.sharePage(page, <Presentation>this.presentationService.activePresentation, false);
                  }
                }
                this.selectedPage = page;
                this.selectedPageUrl = page ? page.pageUrl : "";
                this.canCaptureSentiments = true;
            }
        });

        this.IcurrentMeetingActivitySubscription = this.meetingStructureService.selectedMeetingActivityObserver.subscribe(activity => {
            if (activity) {
                if (activity !== this.selectedMeetingActivity) {
                    if (activity.type == EmbeddedInteractionType.Content && this.deviceService.isNativeApp && this.deviceService.isOffline
                        && !(<Presentation | Resource>activity.interaction).downloaded) {
                            this.clearScreen();
                    }
                    else this.openInteraction(activity, false);
                }
            } else {
                this.clearScreen();
            }
        });

        this.events.subscribe('remote_meeting_participant_Joined_left',(data)=> {

          if (data.joinedStatus === 'joined') {

            if (data.contactID) {
              // if Contact is there and a contact be it guest or nor mal contact, We have to create a new record with start time and if contact leaves we have to update the end time for those contact which does not have end time, it will be done in else part.
              if (this.opentokService.screenshareActive) {// If screen is already being shared.
                this.screenTimeForContacts.push({indskr_contactid: data.contactID, startTime: (new Date()).getTime()});
              }
            }
          } else if (data.joinedStatus === 'left') {

            if (data.contactID) {

              if (this.opentokService.screenshareActive) {
                // We have to update the contact Which Does not have end time
                // For a contact there might be multiple Entries as result of paus/ resume/ Stop So we have to look for only those records, which Does not really have end tiem value.// if Screen is already shared and contact leaves in between
                let screenShareRecord = this.screenTimeForContacts.find(screenShareRecord => {
                  return screenShareRecord.indskr_contactid === data.contactID && !screenShareRecord.endTime
                  });

                const index = this.screenTimeForContacts.indexOf(screenShareRecord);
                screenShareRecord.endTime = (new Date()).getTime();
                if (index > 0) {
                  this.screenTimeForContacts[index] = screenShareRecord;
                }
              }
            }
          }
          if (this.uiService.activeView == 'ActivitiesPageRightPaneNav' || this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
            this.footerService.initButtons(FooterViews.Activities)
          }
          else if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
            this.showContent ? this.footerService.initButtons(FooterViews.PreviewMeeting) : this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
          }
        })


        this.subscriptions && this.subscriptions.push(...this.events.subscribe('ContactsSelected', async (data) => {
            this.meetingDataService.addContactsToMeeting(this.activityService.selectedActivity, true);
            if (this.showContent) {
                await this.meetingStructureService.addFilteredContentToMeeting(true);
            }
            if (this.presentationService.contentMode === ContentMode.PRESENTATION) {
                this.updateSelecedSlide();
                this.pollService.contactsRefreshed(data);
            } else {
                this.updateSelectedResources();
                // this.websocket.addResourceContacts(this.activityService.selectedActivity);
            }
            // else {
            //     this.websocket.updateResourceContcats(this.activityService.selectedActivity, <Resource>this.presentationService.activePresentation);
            // }
        }));
        this.deviceService.screenOrientation.pipe(takeUntil(this.ngDestroy$)).subscribe((newOrientation) => {
            if (this.deviceService.isMobileDevice && newOrientation) {
                this.newOrientation = newOrientation;
            }
        });
        this.events.subscribe('disableVoipIconsFlag', (disablePlayIconsFlag) => {
            if (this.isScreenSharingAllowed) {
                this.disablePlayIconsFlag = disablePlayIconsFlag;
            }
        })
        this.events.observe("sync:completed").pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
          this.showContent ? this.footerService.initButtons(FooterViews.PreviewMeeting) : this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
        });
        this.deviceService.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
          if (this.activityService.selectedActivity) {
            this.backgroundUploadInProgress = inProgress && this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID);
            this._cd.detectChanges();
          }
        });

        // Things to run when network is connected
        this.events.observe('device:deviceIsOnline').pipe(
          takeUntil(this.ngDestroy$),
        ).subscribe(() => {
          // Just in case, fetch reference data for page
          if (this.selectedPage) {
            this.presDataService.getPageReferences(
              (this.presentationService.activePresentation as Presentation).ioPresentationId,
              Number(this.selectedPage.pageSequence),
              this.selectedPage.id,
            );
          }
        });

        if(this.navParams.data && this.navParams.data.accountVisiteCreation){
          this.onContentPlayButtonClicked();
        }
  }

  async ngAfterViewInit() {
    await this.initVOIP();
  }

  private async initVOIP() {
    if (this.tencentEnabled && !this.deviceService.isOffline && !this.activityService.selectedActivity.ID.includes('offline')) {
      const userID = this.authService.user.displayName;
      const roomID = this.activityService.selectedActivity.ID;
      await this.tencentService.initTencentConfig(userID, roomID, this.isVideoAllowed, this.isVOIPAllowed);
    } else {
      const isRemote = this.activityService.isThereConnectedContactsForCurrentMeeting(<AppointmentActivity>this.activityService.selectedActivity, true);
      if (this.isVOIPAllowed) {
        if (isRemote) this.notificationService.notify(this.translate.instant('CONNECTING_TO_AUDIO_DEVICE'), "activity-details", "top", ToastStyle.INFO);
        if (!this.deviceService.isOffline) this.uiService.displayLoader();
        this.initOpenTok();
          // this.footerService.enableButtons(['video', 'stop_video_active', 'audioactive', 'stop_audio', 'play', 'pause'], false);
          if(isRemote) this.opentokService.audioActive = true;
          if (this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES) //isRemote &&
            && !this.deviceService.isOffline
            && !this.speechService.isRecording) {
              await this.speechService.initRecognizer();
          }
          setTimeout(() => {
            this.uiService.dismissLoader();
          }, 0);
      }
      else {
        await this.initOpenTok();
      }
    }
  }

  @IoEventListener('tencent-start-video')
  startTencentVideo() {
    if (!this.isVideoAllowed) return;
    this.tencentService.unmuteVideo();
    this.footerService.showButton('stop_video_active');
    this.footerService.hideButton('video');
  }

  @IoEventListener('tencent-start-audio')
  startTencentAudio() {
    if (!this.isVOIPAllowed) return;
    this.tencentService.unmuteAudio();
    this.footerService.showButton('audioactive');
    this.footerService.hideButton('stop_audio');
  }

  @IoEventListener('tencent-stop-video')
  stopTencentVideo() {
    if (!this.isVOIPAllowed) return;
    this.tencentService.muteVideo(true);
    this.footerService.showButton('video');
    this.footerService.hideButton('stop_video_active');
  }

  @IoEventListener('tencent-stop-audio')
  stopTencentAudio() {
    if (!this.isVOIPAllowed) return;
    this.tencentService.muteAudio(true);
    this.footerService.showButton('stop_audio');
    this.footerService.hideButton('audioactive');
  }

  @IoEventListener('opentok-start-audio')
  startAudio() {
    if (!this.isVOIPAllowed) return;
    this.opentokService.audioActive = true;
  }

  @IoEventListener('opentok-start-screenshare')
  startScreenshare() {
    if (!this.isScreenSharingAllowed) return;
    this.opentokService.screenshareActive = true;
  }

  @IoEventListener('opentok-stop-screenshare')
  stopScreenshare() {
    this.opentokService.screenshareActive = false;
  }

  @IoEventListener('opentok-start-video')
  startVideo() {
    if (!this.isVideoAllowed) return;
    this.opentokService.videoActive = true;
  }

  @IoEventListener('opentok-stop-audio')
  stopAudio() {
    this.opentokService.audioActive = false;
  }

  @IoEventListener('opentok-stop-video')
  stopVideo() {
    this.opentokService.videoActive = false;
  }

    @IoEventListener('opentok-state-changed')
    public opentok_stateChanged(state: OTState, oldState: OTState) {
      this.uiService.dismissLoader();
      if (state?.audio === 'waiting'
      || state?.video === 'waiting'
      || state?.screenshare === 'waiting') return;
      if (this.isVOIPAllowed) {
        if (state?.audio) {
          this.footerService.showButton('audioactive');
          this.footerService.hideButton('stop_audio');
        } else {
          this.footerService.showButton('stop_audio');
          this.footerService.hideButton('audioactive');
        }
      }
      if (this.isVideoAllowed) {
        if (state?.video) {
          this.footerService.showButton('stop_video_active');
          this.footerService.hideButton('video');
        } else {
          this.footerService.showButton('video');
          this.footerService.hideButton('stop_video_active');
        }
      }
      if (this.isScreenSharingAllowed) {
        if (state?.screenshare === 'playing') {
          this.footerService.showButton('pause');
          this.footerService.hideButton('play');
          if (state?.screenshare !== oldState?.screenshare) {
            this.opentok_screenshareStarted();
          }
        } else if (!state?.screenshare) {
          this.opentokService.sendSignal({
            data: `refreshThumbnail`,
          })
          this.footerService.showButton('play');
          this.footerService.hideButton('pause');
          this.footerService.hideButton('homepage_disabled');
          this.footerService.showButton('homepage');
          this.footerService.enableButtons(['homepage']);

          if (oldState?.screenshare) {
            this.opentok_screenshareStopped();
          }
        }
      }      this.showContent ? this.footerService.initButtons(FooterViews.PreviewMeeting) : this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
      if (window.innerWidth > 500) {
        this.footerService.displayFullScreenButtons();
      }
    }

    @IoEventListener('presentation-meeting::play-clicked')
    public async presentationMeeting_playClicked() {
      // If play, pause, and play on the same page, the tracking should have 2 entries for that page.
      // It only happens if there's any selected page already.
      let hasContacts = false;
      // if (this.isScreenSharingAllowed && this.activityService.selectedActivity instanceof AppointmentActivity) {
      //   let joinedContacts = this.activityService.selectedActivity.contacts.filter(c => c.connectionState == ContactMeetingState.JOINED)
      //   hasContacts = joinedContacts.length > 0
      // }

// TO-DO: Discussion with product is needed, This suffices for f2f and remote when hit on play button ut there might be scenario where two remote parties might be talking about product even without hitting play button.

      // if (this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES) //isRemote &&
      //   && !this.deviceService.isOffline
      //   && !this.speechService.isRecording) {
      //   await this.speechService.initRecognizer();
      // }

      if (this.isScreenSharingAllowed) {
       this.events.publish('opentok-start-screenshare');
      }
      else{
        // here added else block  to resolve OMNI-16611
        const presentation = this.presentationService.activePresentation;
        this.selectedPage && await this.sharePage(this.selectedPage, presentation, true);

        if (this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_REFERENCES)) {
          // Broadcast references to the participants if already has cached references
          setTimeout(() => {
            if (this.selectedPage && presentation instanceof Presentation) {
              const cachedReferenceData = this.presentationService.getReferencesFromCache(
                presentation.ioPresentationId,
                Number(this.selectedPage.pageSequence),
              );
              if (cachedReferenceData?.references) {
                this.websocket.broadcastCurrentPageReferences(
                  (this.activityService.selectedActivity as AppointmentActivity).ID,
                  presentation.ioPresentationId,
                  Number(this.selectedPage.pageSequence),
                  cachedReferenceData.pageId,
                  cachedReferenceData.references,
                );
              }
            }
          }, 500);
        }
      }

      //Auto select Therapeutic Area
      if (this.presentationService.activePresentation instanceof Presentation || this.presentationService.activePresentation instanceof Resource) {
        this.updateTherapeuticArea(this.presentationService.activePresentation);
      }
    }

    async opentok_screenshareStarted() {
        // capture Screen Sharing here
        if (!(this.activityService.selectedActivity instanceof AppointmentActivity)) return;
        this.selectedPage && await this.sharePage(this.selectedPage, this.presentationService.activePresentation, true);
        let joinedContacts = this.activityService.selectedActivity.contacts.filter(c => c.connectionState == ContactMeetingState.JOINED)
        if (joinedContacts && _.isArray(joinedContacts) && !_.isEmpty(joinedContacts)) {
          joinedContacts.forEach(contact => {
            this.screenTimeForContacts.push({ indskr_contactid: contact.ID, startTime: (new Date()).getTime() });
          });
          // await this.trackScreenSharingTime();
      }
    }

    async opentok_screenshareStopped() {
      this.captureEndTime();
      if (this.timeout) await clearTimeout(this.timeout);
      this.events.publish('presentation-meeting::pause-clicked');
    }

    @IoEventListener('presentation-meeting::flip-clicked')
    presentationMeetingFlipClicked() {
      this.events.publish('opentok-stop-screenshare');
    }

    private async fetchTokens(activityId: string) {
      const response = await this.http
      .get<{ apiKey: string, meetingToken: { token: string, startTime: number, expiryTime: number, tokenType: string }[] }>(this.authService.userConfig.activeInstance.entryPointUrl +
        Endpoints.meeting.VIDEO_TOKEN.replace("{activity_id}", activityId)
      ).pipe(catchError(() => EMPTY)).toPromise();

      if (response) {
        const tokens = _.keyBy(response.meetingToken, 'tokenType');
        return {
          apiKey: response.apiKey,
          tokens: {
            subscriber: tokens.OPENTOK_SESSION.token,
            publisher: tokens.OPENTOK_PUBLISHER.token,
            session: tokens.OPENTOK_SESSION.token,
          }
        };
      }
    }

  private captureEndTime() {
    if (this.screenTimeForContacts.length > 0) {
      this.screenTimeForContacts.forEach(record => {
        if (!record.endTime) {
        record.endTime = new Date().getTime();
        }
      });
    }
    this.trackScreenSharingTime();
  }

    @HostListener("window:resize", ["$event"])
    onresize(event) {
      if (this.newOrientation && !this.isShortCall) {
            this.showContent ? this.footerService.initButtons(FooterViews.PreviewMeeting) : this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
        }
    }

    private async trackScreenSharingTime() {
      if (!this.screenTimeForContacts?.length) return;
      this.activityDataService.trackScreensharetime([...this.screenTimeForContacts], this.activityService.selectedActivity.ID);
      this.screenTimeForContacts = [];
    }

    private async initOpenTok() {
      if (this.deviceService.isOffline
        || this.activityService.selectedActivity.ID.includes('offline')
        || !this.remoteFeatureEnabled) return;
      let features = await this.opentokService.checkSupportedFeatures();
      if (!features.screenshare) {
        this.isScreenSharingAllowed = false;
      }
      if (!features.webrtc) {
        this.isVOIPAllowed = false;
        this.isVideoAllowed = false;
      }
      if (!this.isScreenSharingAllowed && !this.isVOIPAllowed && !this.isVideoAllowed) return;

      const tokens = await this.fetchTokens(this.activityService.selectedActivity.ID);

      // let tokens  = undefined;
      // if (this.opentokService.tokens && this.opentokService.activityId==this.activityService.selectedActivity.ID) {
      //   tokens = {"tokens":this.opentokService.tokens};
      // }
      // else {
      //    tokens = await this.fetchTokens(this.activityService.selectedActivity.ID);
      //    this.opentokService.activityId = this.activityService.selectedActivity.ID;
      // }
      await this.opentokService.init({
        userId: this.authService.user.systemUserID,
        name: this.authService.user?.displayName,
        ...tokens,
        features: {
          video: this.isVideoAllowed,
          screenshare: this.isScreenSharingAllowed,
          audio: this.isVOIPAllowed,
        },
      });
    }

    private clearScreen() {
        this.presentationService.clearCurrentPresentation();
        this.navService.popToRootChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        this.showContent = true;
    }

    updateSelecedSlide() {
        let selPres = this.activityService.selectedActivity['activityPresentations'].find((pres) => {
            // Added null check as active presentation can be null if meeting presentaion view is opened without any content
            if (this.presentationService.activePresentation) {
                return pres.presentationId === this.presentationService.activePresentation['ioPresentationId'];
            }
        });
        if (selPres && selPres.activityPresentationSlides && selPres.activityPresentationSlides.length > 0) {
            let slides = selPres.activityPresentationSlides.find(slide => slide.id === this.selectedPage.id && slide.starttime === slide.endtime);
            if (slides && this.activityService.selectedActivity['contacts'] && Array.isArray(this.activityService.selectedActivity['contacts'])) {
                this.activityService.selectedActivity['contacts'].forEach(contact => {
                    let findCont = slides.activityPresentationSlideContacts.some(tempCont => tempCont.contactid === contact.ID);
                    if (!findCont) {
                        slides.activityPresentationSlideContacts.push(
                            {
                                contactid: contact.ID,
                                pagesentiment: "NEUTRAL"
                            }
                        )
                    }
                })
            }
        }
    }

    updateSelectedResources() {
      let selResource = this.activityService.selectedActivity['activityResources'].find((pres) => {
        // Added null check as active resource can be null if meeting presentaion view is opened without any content
        if (this.presentationService.activePresentation) {
          return pres['indskr_ioresourceid'] ? pres['indskr_ioresourceid'] === this.presentationService.activePresentation['ioResourceId'] : pres['indskr_iodocumentid'] === this.presentationService.activePresentation['ioDocumentId'];
        }
      });
      if (selResource && selResource.activityResourceSlides && selResource.activityResourceSlides.length > 0) {
        let slides = selResource.activityResourceSlides.find(slide => slide['indskr_pagenumber'] === this.currentPdfPage && !slide.indskr_endtime);
        if (slides && this.activityService.selectedActivity['contacts'] && Array.isArray(this.activityService.selectedActivity['contacts'])) {
          this.activityService.selectedActivity['contacts'].forEach(contact => {
            let findCont = slides.activityResourceSlideContacts.some(tempCont => tempCont['indskr_contactid'] === contact.ID);
            if (!findCont) {
              slides.activityResourceSlideContacts.push(
                {
                  contactid: contact.ID,
                  pagesentiment: "NEUTRAL"
                }
              )
            }
          })
        }
      }
    }

    ngOnDestroy() {
        document.getElementById("chat").style.display = "block";
        this.events.publish("session-stack:start");
        this.opentokService.disconnect();
        this.tencentService.handleLeave();
        this.ngDestroy$.next(true);
        this.ngDestroy$.complete();
        this.subscriptions.forEach(sub => sub.unsubscribe());

        this.presentationService.setCarouselBriefcase(null);
        this.presentationService.setCurrentSelectedPres(null);
        this.presentationService.setCurrentSelectedPresPage(null);
        this.resourceService.reset();
        this.presentationService.videoResourceCurrentTime = null;

        this.presentationService.hideCarousel = false;
        this.presentationService.isReferenceVisible = false;
        this.presentationService.showNewActivity = false;
        this.presentationService.isPrevFullScreenMode = false;
        this.presentationService.isFullScreenMode = false;

        // Close any opened references
        this.presentationService.closeAllReferenceModals();
        // Clear reference data
        this.presentationService.clearReferenceBeingShared();
        this.presentationService.clearPageReferences();

        //Unsubscribe
        this.curSelPresSubscription.unsubscribe();
        this.curSelPageSubscription.unsubscribe();
        this.carouselSubscription.unsubscribe();
        this.events.unsubscribe('changePresentation');
        if (this._deviceConnectionChangeHandler) {
          this.events.unsubscribe('device:deviceIsOffline', this._deviceConnectionChangeHandler);
          this.events.unsubscribe('device:deviceIsOnline', this._deviceConnectionChangeHandler);
        }
        this.IcurrentMeetingStructureSubscription.unsubscribe();
        this.IcurrentMeetingActivitySubscription.unsubscribe();
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
        this.navService.popChildNavCtrlFromStack(ChildNavNames.MeetingPresentationNavigation);
        this.activityService.activitySource = null;
        this.emailService.selectedActivity = null;
        this.emailService.viewType = null;
        this.caseService.assignSelectedCase(undefined);
        this.surveyService.assignLookupResult(undefined);
        this.customerSurveyService.inMeetingSelectedSurveyId = null;
        this.sampleService.inMeetingAllocationActivity = null;
        if (this.from === PageName.ActivitiesPageComponent || (this.from === PageName.ShortCallHomeComponent && !this.isShortCall)) {
          if (this.from === PageName.ShortCallHomeComponent) this.onShortCallCompletion({ data: { data: {type: 'submit'} } });
            this.uiService.showRightPane = true;
        }
        this.websocket.shortCallRemoteMeeting = false;
        this.footerService.initButtons(FooterViews.Activities);
    }

    ionViewWillLeave() {
      this.events.publish('opentok-pause-screenshare');
      this.opentokService.updateViews();
    }

    ionViewDidEnter() {
        if (this.presentationService.viewMode !== this.presMode.MEETING) {
            let temp: Presentation | Resource;
            if (this.presData) {
                if (this.activityService.selectedActivity instanceof AppointmentActivity) {
                    const meetingPresentations = this.activityService.selectedActivity.presentations;
                    let id = this.presData['ioPresentationId'] ? this.presData['ioPresentationId'] : (this.presData['ioDocumentId'] ? this.presData['ioDocumentId'] : this.presData['ioResourceId']);
                    temp = meetingPresentations.find(res => res['ioPresentationId'] === id ||
                        res['ioDocumentId'] === id || res['ioResourceId'] === id);
                    // if presentation was deleted load the first presentation available
                    if (!temp) {
                        if (this.activityService.selectedActivity.presentations.length > 0) {
                            this.presentationService.setCurrentSelectedPres(meetingPresentations[0]);
                            const page: Page = this.presentationService.presPages && this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0] : null;
                            this.presentationService.setCurrentSelectedPresPage(page);
                            this.selectedPageUrl = page.pageUrl;
                            this.selectedPage = page;
                        } else {
                            this.presentationService.setCurrentSelectedPres(undefined);
                            this.presentationService.setCurrentSelectedPresPage(undefined);
                        }
                    }
                    // Do this if presentation still exist in the meeting
                    if (temp) {
                        this.presentationService.setCurrentSelectedPres(this.presData);
                        this.presentationService.setCurrentSelectedPresPage(this.selectedPage);
                    }
                }
            } else {
                this.presentationService.setCurrentSelectedPres(undefined);
                this.presentationService.setCurrentSelectedPresPage(undefined);
            }
        }
        this.presentationService.viewMode = this.presMode.MEETING;
        if (this.showContent) {
          this.footerService.initButtons(FooterViews.PreviewMeeting);
        } else {
          this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
        }
        this.uiService.isFlipButtonClicked = false;
        setTimeout(() => {
          this.events.publish('opentok-resume-screenshare');
          this.opentokService.updateViews();
          if (this.isShortCall) {
            this.websocket.meetingInProgress = true
            this.presentationMeeting_playClicked();
          }
        }, 500)
        this.carouselToggled();
    }

    get footerView() {
        if (window.innerWidth > 500) {
            return "fullScreenView";
        } else {
            return "detailView";
        }
    }

    public async goBack(dontupdateslide?: boolean) {
      this.uiService.toolsActivityActive = false;
      if (!dontupdateslide) {
        await this.websocket.updateLastSlideEndtime(this.activityService.selectedActivity);
      }
      if (this.from !== PageName.ShortCallHomeComponent) {
        this.footerService.initButtons(FooterViews.Activities);
        this.events.publish("detectChangesOnActivityDetails");
      }
      await this.tencentService.handleLeave();
      this.navService.popWithPageTracking();
    }

    async onPdfPageChange(page) {
      this.currentPdfPage = page;
      this.canCaptureSentiments = false;
      this._cd.detectChanges();
      if (this.timeout) await clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.websocket.shareResourcePage(this.activityService.selectedActivity as AppointmentActivity, page, this.presentationService.activePresentation as Resource, false);
        this.canCaptureSentiments = true;
        this._cd.detectChanges();
      }, 3000);
    }

    public async sharePage(page: Page, pres: Presentation | Resource, playClicked) {
        if (this.presentationService.viewMode !== this.presMode.MEETING || !pres) return;
        console.log("websocket me");
        // Websocket share in here
        this.canCaptureSentiments = true;
        this._brands = this.activityService.selectedActivity.products;
        if (this.timeout) await clearTimeout(this.timeout);
        if (pres instanceof Presentation) {
            this.websocket.shareSlide(this.activityService.selectedActivity, page, pres, playClicked);
            console.log(page.keyMessage);
            this.updateProductAndKeyMessage(pres, page.keyMessage);
        } else {
          this.websocket.shareResourcePage(this.activityService.selectedActivity as AppointmentActivity, this.currentPdfPage, pres, playClicked);
            this.updateProductAndKeyMessage(pres);
        }
    }

    /**
     * Receive page-init message from slide and do any necessary initialization
     */
    @IoEventListener("window:message", message => message.type === 'io-spa' && message.action === 'page-init', message => [message.data])
    onSlidePageInit(initData) {
        let { isInteractivePage, href } = initData;
        this.presentationService.swipeStatus = isInteractivePage ? SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION :
                                                this.presentationService.swipeStatus === SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION ? SwipeStatus.ENABLED_DEFAULT : this.presentationService.swipeStatus;
        if (!this.isShortCall && this.navService.getCurrentPageName() === this.pageName.PresentationMeetingComponent) {
            this.footerService.updateSwipeButtonState(this.pageName.PresentationMeetingComponent);
            this.events.publish('footerButtonsUpdated', this.pageName.PresentationMeetingComponent);
        }

        if(href) {
          if (this.presentationService.activePresentation instanceof Presentation) {
            const navPage = this.presentationService.activePresentation.presentationPages.find(page => this.getFileNameFromUrl(page.pageUrl) === this.getFileNameFromUrl(href));
            if(navPage) {
              let pageUrlParts = href.split('/');
              let slideSrcName = pageUrlParts[pageUrlParts.length - 1];
              if(navPage.pageUrl == href || (this.deviceService.isOffline && navPage.name == slideSrcName)) this.presentationService.setCurrentSelectedPresPageSlide(navPage);
              else this.presentationService.setCurrentSelectedPresPage(navPage);
            }
          }
        }
    }

    @IoEventListener("window:message", message => message.type === 'io-spa' && message.action === 'tracking-event', message => [message.data])
    public async onTrackingEvent(eventData) {
        let { indeTrack, indeGoal, elementId, eventType } = eventData;
        let _class = eventData['class'];
        let events: { type: string, value: string }[] = [
            ...(elementId ? [{ type: 'ID', value: elementId }] : []),
            ...(_class ? [{ type: 'CLASS', value: _class }] : []),
            ...(indeTrack ? [{ type: 'TRACK', value: indeTrack }] : []),
            ...(indeGoal ? [{ type: 'GOAL', value: indeGoal }] : []),
        ];
        let caseInsensitiveEquals = (a: string, b: string) => (a || '').localeCompare(b || '', undefined, { sensitivity: 'accent' }) === 0;
        if (this.presentationService.activePresentation instanceof Presentation) {
            let page = this.presentationService.activePresentation.virtualPages.find(page =>
                events.findIndex(ev =>
                    caseInsensitiveEquals(ev.type, page.indskr_virtualpagetype) && caseInsensitiveEquals(ev.value, page.indskr_virtualpagevalue)) >= 0);
            page && await this.sharePage(page, this.presentationService.activePresentation, false);
        }

        // Sync virtual events with HCP
        // Supports only a click event for now
        switch (eventType) {
            case 'MouseEvent':
            case 'TouchEvent':
            case 'VideoEvent':
                if (!this.isScreenSharingAllowed) {
                    const msg = {
                        type: 'io-spa',
                        action: 'virtual-event',
                        eventDetail: eventData
                    };
                    this.websocket.broadcastVirtualEvent(msg);
                }
                break;

            case 'Redirect':
                const shouldBroadcast = this.presentationService.handleVirtualPageRedirect(this.presentationService.activePresentation as Presentation, eventData);
                if (shouldBroadcast) {
                    this.websocket.broadcastVirtualEvent({
                        type: 'io-spa',
                        action: 'redirect',
                        eventDetail: eventData
                    });
                }
                break;

            default:
                break;
        }
    }

    updateTherapeuticArea(pres: Presentation | Resource) {
      const therapeuticAreas: [] = (pres['theraputicAreas'] && Array.isArray(pres['theraputicAreas'])) ? pres['theraputicAreas'] : pres['therapeuticAreas'];
        if (therapeuticAreas && therapeuticAreas.length > 0) {
            this.therapeuticService.therapeuticAreas.forEach(ta => {
                if (therapeuticAreas.some(item => item['therapeuticAreaId'] === ta.therapeuticareaId || item === ta.therapeuticareaId)) {
                    let findTa = this.activityService.selectedActivity['activityTherapeuticAreas'].find(item => item.therapeuticareaId === ta.therapeuticareaId);
                    if (findTa) {
                        findTa.isAutoSelected = true;
                        findTa.isSelected = true;
                    } else {
                        let newTa = new TherapeuticArea(ta.TherapeuticAreaDTO);
                        newTa.isAutoSelected = true;
                        newTa.isSelected = true;
                        this.activityService.selectedActivity['activityTherapeuticAreas'].push(newTa);
                    }
                }
            });

            /**
             * Update key messages offline or online
             */
            if (this.activityService.selectedActivity instanceof AppointmentActivity) {

                if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                    try { // I/O operations needs to be wrap in a try and catch block
                        this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity); // offline saving
                    } catch (e) {
                        throw new Error(e); // will implement a Global Error handler
                    }

                } else {
                    try {
                        if (this.activityService.selectedActivity && this.activityService.selectedActivity['activityTherapeuticAreas'].length > 0) {
                            this.therapeuticService.updateMeetingTherapeuticArea(this.activityService.selectedActivity).then(response => {
                                console.log(response);
                            }).catch(err => {
                                console.log(err);
                                // this.notificationService.notify("Updating therapeutic area failed",'Presentation meeting','top',ToastStyle.DANGER);
                            });
                        }
                    } catch (e) {
                    }
                }
            }
        }
    }

    /**
     *
     *
     * @private
     * @param {*} key
     * @param {Presentation} pres
     * @returns
     * @memberof PresentationMeetingComponent
     */
  private async updateProductAndKeyMessage(content: Presentation | Resource, key?: Array<KeyMessage>) {
        // if (this.authService.user.buConfigs?.indskr_disableproductsection == true) return;
        const clonedProducts = _.cloneDeep(this.activityService.selectedActivity["productsDTO"]);
        const prevProdState = _.cloneDeep(this.activityService.selectedActivity.products);
        //If meeting is not running, ignore
        if (!this.websocket.meetingInProgress) return;
        let selectedProducts = _.filter(this._brands, (brand => brand.isSelected || brand.isGenieSelected));
        let _priority = 1;
        selectedProducts.forEach(selPro => {
            _priority = selPro.priority >= _priority ? selPro.priority + 1 : _priority;
        })
        let action:UpdateKeyMessagesFor = {
          products: true,
          indications:false,
          diseaseAreas:false,
        }
        this._brands.forEach(b => {
            if (content.brands && content.brands.length !== 0)
            if (_.isEmpty(content.brands) || (content instanceof Presentation && content.brands.findIndex(x => x.ID === b.ID) === -1) ||
                (content instanceof Resource && content.brands.findIndex(x => _.lowerCase(x) === _.lowerCase(b.name) || _.lowerCase(x.name) === _.lowerCase(b.name)) === -1)) {
                return;
            } else {
                //specifically for teva release , product will necessary not have a key message so we tag the product alone , CWD-1468
                b.priority = b.isSelected ? b.priority : _priority;
                b.isAutoSelected = true;
                b.isSelected = true;
                if (!_.isEmpty(key)) {
                    key.forEach(k => {
                        let temp = b.keyMessages.find(x => x.ID == k.keyMessageId)
                        // console.log("searching keymessage");
                        // console.log(temp);
                        if (temp) {
                            b.isSelected = true; //redundant but it covers some use cases where product key messages is a must
                            b.isAutoSelected = true; //redundant but it covers some use cases where product key messages is a must
                            temp.isSelected = true;
                            temp.isAutoSelected = true;
                        }
                        if(this.isProductIndicationsEnabled){
                          action.indications = this.autoSelectProductIndications(b,k);
                        }
                    });
                }
            }
            _priority++;
        });

        this.activityService.selectedActivity.products = this.activityService.selectedActivity.products.sort((item1: any, item2: any) => {
            if (item1.priority && item2.priority) {
                return item1.priority - item2.priority;
            }
        });
        /**
         * Update key messages offline or online
         */
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          if (_.isEqual(this.activityService.selectedActivity.productsDTO, clonedProducts) && !action.indications) {
            console.log ("These Products are already mapped !!!");
            return;
          }
          console.log('Activity data to push: ', this.activityService.selectedActivity.DTO);
          if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
              try { // I/O operations needs to be wrap in a try and catch block
                  this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity); // offline saving
              } catch (e) {
                  throw new Error(e); // will implement a Global Error handler
              }

          } else {
              try {
                  await this.meetingDataService.updateMeetingProductKeyMessages(this.activityService.selectedActivity,action);
              } catch (e) {
                this.activityService.selectedActivity.products = prevProdState;
              }
          }
          this.events.publish('user:activity_products_created', this.activityService.selectedActivity.products);
        }
    }

    @IoEventListener("footer-toolbar:carousel-toggle")
    private carouselToggled() {
        //this.content.resize();
        if (!this.isShortCall && this.navService.getCurrentPageName() === this.pageName.PresentationMeetingComponent) {
          this.updateFooterCarouselButtonState();
          this._dom.write(() => {
              this.events.publish('carousel:iframe-resize');
          });
        }
    }

    public sharePresentation(pres: Presentation) {
        if (this.viewMode !== this.presMode.MEETING || !pres) return;

        this.websocket.sharePresentation(this.activityService.selectedActivity, pres);
    }

    public toggleCarousel()
    {
      this.shouldShowPresentationCarousel = !this.shouldShowPresentationCarousel;
      this.opentokService.updateViews();
    }

    public async setSentiment(sentimentNumber: number) {

        if (!this.websocket.meetingInProgress) return;

        let page: Page;
        // await this.presentationService.currentSelectedPresPage.subscribe(
        //     resultingPage => page = resultingPage
        // );

        page = this.presentationService.activePresPage;
        let sentiment: SENTIMENT;

        switch (sentimentNumber) {
            case 0:
                sentiment = SENTIMENT.POSITIVE;
                break;

            case 1:
                sentiment = SENTIMENT.NEUTRAL;
                break;

            case 2:
                sentiment = SENTIMENT.NEGATIVE;
                break;
        }

        if (this.presentationService.activePresentation instanceof Presentation)
          this.websocket.setSentiment(<Presentation>this.presentationService.activePresentation, page, this.activityService.selectedActivity, sentiment);
        else
          this.websocket.setSentimentForResourceSlide(<Resource>this.presentationService.activePresentation, this.currentPdfPage, this.activityService.selectedActivity, sentiment);
    }

    public onContentPlayButtonClicked() {
        this.events.publish('presentation-play');
    }
    public async openNewActivity() {
        this.presentationService.showNewActivity = true;
        this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
        this.activityService.activitySource = ActivitySource.MEETING_PRESENTATION;
        this.selectedMeetingActivity = undefined;
        if (this.timeout) await clearTimeout(this.timeout);
        this.events.publish('presentation-meeting::pause-clicked');
        await this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.PresentationMeetingComponent);
    }

    async openInteraction(embeddedInteraction: EmbeddedInteraction, triggerObeservable?: boolean) {
        this.presentationService.showNewActivity = false;
        this.presentationService.isPrevFullScreenMode = false;
        this.presentationService.isFullScreenMode = false;
        if (this.selectedMeetingActivity == embeddedInteraction) return;
        this.currentPdfPage = 1;
        this.presentationService.videoResourceCurrentTime = null;
        if (embeddedInteraction.type === this.interactionType.Content) {
          if (await this.presentationService.canChangeSlideOrPresentation()) {
            const content = <Presentation | Resource>embeddedInteraction.interaction;
            if (this.deviceService.isNativeApp && (this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !content.downloaded) {
                this.notificationService.notify(this.translate.instant('PLEASE_DOWNLOAD_CONTENT_TO_VIEW__WHILE_OFFLINE'), "activity-details", "top", ToastStyle.DANGER, 3000, true);
                return;
            }
            await this.navService.popToRootChildNavPageWithPageTracking();
            this.showContent = true;
            this.initFooterForContent(content);
            // this.websocket.lastResourceSelected = undefined;
            if (triggerObeservable) this.meetingStructureService.setCurrentMeetingActivity(embeddedInteraction);
            this.selectedMeetingActivity = embeddedInteraction;
            if (this.presData != content) {
                this.events.publish("changePresentation", content);
            }
            this.events.publish("footer-toolbar:carousel-toggle");
            this.presData = content;
            if (this.emailService.selectedActivity) {
                this.emailService.selectedActivity = null;
                this.emailService.viewType = null;
            }
          }
        } else if (embeddedInteraction.type === this.interactionType.Survey)  {
          if(this.deviceService.isOffline) {
            this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-in-meeting", "top", ToastStyle.DANGER, 3000, true);
            return;
          }
          const survey = <CustomerAssessment>embeddedInteraction.interaction;
          if(!_.isEmpty(embeddedInteraction.interaction)) {
            this.presData = null;
            this.presentationService.setCurrentSelectedPres(null);
            if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) {
              await this.navService.popToRootChildNavPageWithPageTracking();
              this.customerSurveyService.inMeetingCustomerSurvey = true;
            }
            if (this.timeout) await clearTimeout(this.timeout);
            this.events.publish('presentation-meeting::pause-clicked');
            this.selectedMeetingActivity = embeddedInteraction;
            if (triggerObeservable) this.meetingStructureService.setCurrentMeetingActivity(embeddedInteraction);
            const surveyTemplate = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);

            // let foundTemplate: AssessmentTemplate = surveyTemplate.find(template=> template.indskr_assessmenttemplateid == survey.indskr_template);
            const targetTemplateId = survey['_indskr_template_value'] || survey['indskr_template'];
            const foundTemplate: AssessmentTemplate = surveyTemplate.find(template=> template.indskr_assessmenttemplateid == targetTemplateId);
            const surveyFor:string = foundTemplate.indskr_entity == SurveyCategory.CONTACT ? SurveyCategory.CONTACT: SurveyCategory.ACCOUNT
            if(!_.isEmpty(foundTemplate)) {
              this.customerSurveyService.setCurrentSurvey(foundTemplate);
            }else {
              console.log("Survey tempalte is not avaialble");
              return;
            }
            if(surveyFor == SurveyCategory.CONTACT) {
              this.customerSurveyService.selectedContactForSurvey = this.contactService.getContactByID(survey.indskr_entityid);
              this.customerSurveyService.selectedAccountForSurvey = null;
            } else {
              this.customerSurveyService.selectedContactForSurvey = null;
              this.customerSurveyService.selectedAccountForSurvey = this.accountService.getAccountById(survey.indskr_entityid);
            }
            this.customerSurveyService.inMeetingSelectedSurveyId = survey.indskr_customerassessmentid;

            const targetAppointmentId = survey['_indskr_appointment_value'] || survey['indskr_appointmentid'];
            await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.PresentationMeetingComponent, { appointmentId: targetAppointmentId, from: ActivitySource.MEETING_PRESENTATION });

            // if(survey?.surveyStatus == SurveyStatus.SUBMITTED) {
              const responseData = await this.customerAssessService.fetchContactSurveyForDetails(survey, surveyFor, true);
              if(!_.isEmpty(responseData)) {
                survey.responses = responseData;
              }
            // }
            let isSubmitted = survey?.surveyStatus == SurveyStatus.SUBMITTED;
            this.customerSurveyService.setSurveyPreviewMode(isSubmitted, survey);
            this.showContent = false;
            if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) {
              this.customerSurveyService.inMeetingCustomerSurvey = true;
              this.footerService.initButtons(FooterViews.PreviewMeeting);
            }
          }
        } else {
            this.presData = null;
            this.presentationService.setCurrentSelectedPres(null);
            await this.navService.popToRootChildNavPageWithPageTracking();
            const activity: Activity = <Activity>embeddedInteraction.interaction;
            if (this.timeout) await clearTimeout(this.timeout);
            this.events.publish('presentation-meeting::pause-clicked');
            this.selectedMeetingActivity = embeddedInteraction;
            if (triggerObeservable) this.meetingStructureService.setCurrentMeetingActivity(embeddedInteraction);
            switch (activity.type) {
                case ActivityType.Email:
                    this.emailService.selectedActivity = <EmailActivity>activity;
                    this.emailService.setCurrentEmail(this.emailService.selectedActivity);
                    this.emailService.viewType = EmailViewType.EMAIL_FROM_MEETING_PRESENTATION;
                    this.activityDataService.updateActivityDetails(activity, true, false);
                    await this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.PresentationMeetingComponent).then(() => {
                        this.emailService.viewType = EmailViewType.EMAIL_FROM_MEETING_PRESENTATION;
                    });
                    break;
                case ActivityType.CaseIntake:
                    const iCase: CaseActivity = <CaseActivity>activity;
                    this.caseService.accessedFrom = AccesingMode.PRESENTATION;
                    this.caseService.assignSelectedCase(iCase);
                    if(!this.authService.impersonatedUser){
                      this.activityDataService.fetchCaseDetails(activity);
                    }
                    await this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.PresentationMeetingComponent);
                    break;
                case ActivityType.Sample:
                    this.sampleService.inMeetingAllocationActivity = <SampleActivity>activity;
                    this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION;
                    this.activityDataService.updateActivityDetails(activity, true, false);
                    await this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.PresentationMeetingComponent);
                    break;
                case ActivityType.SurgeryOrder:
                    this.activityDataService.updateActivityDetails(activity, true, false);
                    this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = activity as SurgeryOrderActivity;
                    await this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.PresentationMeetingComponent,{from:'PresentationMeetingComponent', activity:activity});
                    break;
                case ActivityType.ProcedureTracker:
                    this.activityDataService.updateActivityDetails(activity, true, false);
                    this.procedureTrackerDataService.inMeetingProcedureTrackerActivity = activity as ProcedureTrackerActivity;
                    await this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent, PageName.PresentationMeetingComponent,{from:'PresentationMeetingComponent', activity:activity});
                    break;
                case ActivityType.FollowUp:
                    this.activityDataService.updateActivityDetails(activity, true, false);
                    this.followupActivityDataService.inMeetingFollowupActionActivity = activity as FollowUpActivity;
                    await this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.PresentationMeetingComponent,{from:'PresentationMeetingComponent', activity:activity});
                    break;
                default:
                    break;
            }
            this.showContent = false;
        }
        this.scrollToSelectedActivity();
    }

    private initFooterForContent(content: Presentation | Resource) {
        if (this.presentationService.swipeStatus !== SwipeStatus.DISABLED_BY_USER && content instanceof Resource) {
            if (content['fileType'] && content['fileType'] === 'video' || content['fileType'] === 'pdf') {
                this.presentationService.swipeStatus = SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION;
            } else {
                this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
            }
        } else if (this.presentationService.swipeStatus !== SwipeStatus.DISABLED_BY_USER && content instanceof Presentation) {
            this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
        }
        this.footerService.initButtons(FooterViews.PreviewMeeting);
    }

    private updateFooterCarouselButtonState() {
      if (this.presentationService.hideCarousel) {
        this.footerService.showButton('carousel');
        this.footerService.hideButton('carouselactive');
      } else {
        this.footerService.showButton('carouselactive');
        this.footerService.hideButton('carousel');
      }
      if (this.presentationService.isReferenceVisible) {
        this.footerService.showButton('referencesactive');
        this.footerService.hideButton('references');
      } else {
        this.footerService.showButton('references');
        this.footerService.hideButton('referencesactive');
      }
      if (!this.deviceService.shouldBeMobileView) {
        this.footerService.displayFullScreenButtons();
      }
    }
    public handleCarouselClick() {
      this.presentationService.hideCarousel = !this.presentationService.hideCarousel;
      // hide reference carousel if it is visible
      if (this.presentationService.isReferenceVisible) {
        this.presentationService.isReferenceVisible = false;
      }
        this.updateFooterCarouselButtonState();
        this.events.publish('footer-toolbar:carousel-toggle');
    }

    public handleFullScreenClick() {
      if(!this.presentationService.hideCarousel) {
        this.presentationService.hideCarousel = true;
      }else {
       if(this.presentationService.isFullScreenMode) {
        this.presentationService.hideCarousel = false;
       }
      }
      this.presentationService.isPrevFullScreenMode = this.presentationService.isFullScreenMode;
      this.presentationService.isFullScreenMode = !this.presentationService.isFullScreenMode;
      // hide reference carousel if it is visible
      if (this.presentationService.isReferenceVisible) {
        this.presentationService.isReferenceVisible = false;
      }
      this.updateFooterCarouselButtonState();
      this.events.publish('footer-toolbar:carousel-toggle');
    }

    private scrollToSelectedActivity() {
        setTimeout(() => {
            const activeChip = document.getElementsByClassName('io-chip-active');
            if (activeChip.length > 0) {
                activeChip[0].scrollIntoView(true);
            }
        }, 600);
    }

    private getFileNameFromUrl(url:string): string {
      return url.substr(url.lastIndexOf('/')+1);
    }

    public thumbURL(activity: EmbeddedInteraction) {
      if (!_.isEmpty(activity.thumbnailUrl)) {
        if (this.deviceService.isOffline) {
          if (activity.interaction instanceof Presentation) {
            return this.ioFileDownload.getLocalURL("pres_thumbnail/" + activity.thumbnailUrl.substr(activity.thumbnailUrl.lastIndexOf('/') + 1), activity.id);
          } else if (activity instanceof Resource || activity instanceof Document) {
            const thumbLocation = "thumbnail/" + activity.thumbnailUrl.substr(activity.thumbnailUrl.lastIndexOf('/') + 1);
            return this.ioFileDownload.getLocalURLForResource(activity.id, thumbLocation);
          }
        }
      }
      return activity.thumbnailUrl;
    }

    public async onReferenceCardClick(reference: any) {
      let refEventType: 'open' | 'show' | 'hide' | 'close' = 'open';

      if (!reference?.['_indskr_document_value_Formatted']) {
        // URL reference open
        if (this.deviceService.deviceFlags.electron) {
          // Electron app
          electronApi.openExternal(reference.indskr_url);
        } else if (this.deviceService.isNativeApp) {
          // Native app
          const tab = this.iab.create(reference.indskr_url, '_blank', { location: 'yes' });
          if (this.websocket.meetingInProgress) {
            tab.on('exit').subscribe(async (event) => {
              this.presentationService.removeFromReferenceBeingShared(reference);
            });
          }
        } else {
          // Browser
          window.open(reference.indskr_url, '_blank');
        }

        if (this.websocket.meetingInProgress) {
          // Only send event if slide is being presented
          this.presentationService.addToReferenceBeingShared(reference);
        }
      } else if (!this.presentationService.getReferenceModalById(reference.indskr_referencesid)) {
        // Document reference open
        this.presentationService.createReferenceModal(
          reference,
          this.activityService.selectedActivity.ID,
          (this.presData as Presentation).ioPresentationId,
          Number(this.selectedPage.pageSequence),
          this.selectedPage.id,
          true,
        );
        return;
      } else {
        // Show hidden document reference
        this.presentationService.showReferenceModal(reference.indskr_referencesid);
        this.presentationService.showReferenceBeingShared(reference.indskr_referencesid);
        refEventType = 'show';
      }

      this.websocket.broadcastReferenceEvent(
        this.activityService.selectedActivity.ID,
        (this.presData as Presentation).ioPresentationId,
        Number(this.selectedPage.pageSequence),
        this.selectedPage.id,
        reference.indskr_referencesid,
        refEventType,
        this.presentationService.getReferenceType(reference),
        reference.indskr_url,
      );
    }

    private autoSelectProductIndications(brand,keyMsg):boolean{
      let flag:boolean = false;
      if(brand && keyMsg && this.brandsOfflineService.productIndications){
        let prodIdx = this.brandsOfflineService.productIndications.findIndex(p=> p.productid == brand.ID);
        if(prodIdx >= 0 && this.brandsOfflineService.productIndications[prodIdx].productIndications){
          this.brandsOfflineService.productIndications[prodIdx].productIndications.forEach(prodIndi => {
            if(prodIndi.productIndicationsKeyMessages){
              let foundPIKM = prodIndi.productIndicationsKeyMessages.find(km=> km.indskr_keymessageid == keyMsg.keyMessageId)
              if(foundPIKM){
                let obj:any = {
                  "productIndicationId": prodIndi.indskr_productindicationid,
                  "indskr_sequence": 1,
                  "indskr_automaticallyselected": true,
                  "indskr_geneeselected": false,
                  "indskr_name": prodIndi.indskr_name,
                  "isSelected" : true,
                  activityProductIndicationKeyMessages: [{
                    "indskr_keymessageid": foundPIKM.indskr_keymessageid,
                    "indskr_automaticallyselected": true,
                    "indskr_geneeselected": false,
                    "indskr_name": foundPIKM.indskr_name,
                    "isSelected": true,
                  }],
                };
                if((this.activityService.selectedActivity as AppointmentActivity).activityProductIndications){
                  let apiidx = (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.findIndex(a=> a.productIndicationId == obj.productIndicationId);
                  if(apiidx >= 0){
                    let apikmidx = (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications[apiidx].activityProductIndicationKeyMessages.findIndex(b=> b.indskr_keymessageid == obj.activityProductIndicationKeyMessages[0].indskr_keymessageid);
                    if(apikmidx >= 0){
                      obj = null;
                    }else{
                      let tempKeyMsg = obj.activityProductIndicationKeyMessages;
                      obj = (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications[apiidx];
                      obj.indskr_automaticallyselected = true;
                      obj.activityProductIndicationKeyMessages.push(tempKeyMsg);
                    }
                  }
                }
                if(obj){
                  (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.push(obj);
                  flag = true;
                }
              }
            }
          });
        }
      }
      return flag;
    }

    get turnOffSentimentCaptureEnabled() {
      let isEnabled =  this.authService.hasFeatureAction(FeatureActionsMap.TURN_OFF_SENTIMENT_CAPTURE)
      return isEnabled;
    }

  public async onFooterButtonClicked(buttonId: string) {
    console.log('Footer botton clicked : ', buttonId);
    if (this.deviceService.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'presentation-meeting', 'top', ToastStyle.DANGER);
      return;
    }
    switch (buttonId) {
      case 'completeShortCall':
        const pageModal = await this.modalCtrl.create({
          component: IoShortCallDetailsComponent, componentProps: {
            view: 'short-call-details'
          }, backdropDismiss: true, cssClass: 'short-call-details'
        })
        await pageModal.present();
        await pageModal.onDidDismiss().then(async (data) => {
          if (data?.data?.type == 'submit') {
            await this.uiService.displayLoader();
            await this.websocket.updateLastSlideEndtime(this.activityService.selectedActivity);
            await this.meetingDataService.updateMeetingStatus(<AppointmentActivity>this.activityService.selectedActivity, MeetingStatus.COMPLETED, new Date().getTime());
            await this.uiService.dismissLoader();
            this.notificationService.notify(this.translate.instant('SHORT_MEETING_COMPLETED'), 'presentation-meeting', 'top', ToastStyle.INFO);
            if (this.from == PageName.ShortCallHomeComponent) {
              this.onShortCallCompletion(data.data);
            }
            this.goBack(true);
          }
        });
        break;
      case 'shareContent':
        if (this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.presentations.length > 0) {
          const pres = this.activityService.selectedActivity.presentations[0] as Presentation;
          const url = pres.indskr_externalcontentlink;
          const shareData = { url: url, title: pres.name, text: this.translate.instant("SHORT_CALL_CONTENT_SHARE") };
          this.shareLink(shareData);
        }

        break;
      case 'remoteMeeting':
        const meetingURL = this.activityService.selectedActivity['meetingURL'];
        const shareRemoteMeetingLink = { url: meetingURL, title: this.activityService.selectedActivity.subject, text: this.translate.instant("INVITE_YOU_TO_CONNECT_REMOTELY") };
        this.shareLink(shareRemoteMeetingLink, true);
        break;
      default:
        break;
    }
  }

  private shareLink(shareData, remote?: boolean) {
      try {
        if (this.nativeShareAvailable && (!(navigator as any).canShare || (navigator as any).canShare(shareData))) {
          navigator.share(shareData)
            .then(() => {
              console.log('Share successful');
              if (remote && !this.websocket.shortCallRemoteMeeting) {
                this.previewTempRemoteMeeting();
              }
            })
            .catch((error) => {
              console.error('Error sharing:', error);
            });
        }
      } catch (err) {
        console.log('Oops, unable to copy', err);
      }
  }

  private async previewTempRemoteMeeting() {
    this.websocket.shortCallRemoteMeeting = true;
    this.isScreenSharingAllowed = this.deviceService.deviceFlags.desktop && this.deviceService.deviceFlags.web && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_SCREENSHARE)
    this.remoteFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE);
    this.isVideoAllowed = this.authService.hasFeatureAction(FeatureActionsMap.VIDEO_BUTTON);
    this.isVOIPAllowed = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_VOIP);
    this.presentationService.swipeStatus = SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION;
    this.tencentEnabled = this.remoteFeatureEnabled && this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING);
    this.presentationService.hideCarousel = false;
    this.shouldShowPresentationCarousel = false;
    this.footerService.initButtons(FooterViews.PreviewMeeting);
    this._dom.write(() => {
      this.events.publish('carousel:iframe-resize');
    });
    await this.initVOIP();
  }

  private get nativeShareAvailable() {
    if (this.deviceService.isNativeApp && this.deviceService.deviceFlags.electron) {
      return false;
    }
    if (!this.deviceService.isNativeApp && (window as any).plugins?.socialsharing?.shareW3C === navigator.share) {
      return false;
    }
    if (this.deviceService.isNativeApp && this.deviceService.deviceFlags.ios) return !!(navigator.share);
    return !!(navigator.share && (!(navigator as any).canShare || (navigator as any).canShare({ url: location.href })));
  }
}
