export const SURVEY_TEMPLATE_FETCHXML =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">  
  <entity name="indskr_expertinquirytemplate">
    <attribute name="indskr_expertinquirytemplateid"/>
    <attribute name="indskr_name"/>
    <attribute name="indskr_surveyconfiguration"/>
    <attribute name="indskr_templatetype"/>
    <attribute name="modifiedon"/>
    <attribute name="statecode"/>
    {deltaSyncFilter}
    <link-entity name="indskr_associatebuandmobileapptemplates" from="indskr_mobileapptemplates" to="indskr_expertinquirytemplateid" link-type="inner" alias="ab">
      <attribute name="statecode"/>
      <filter type="and">
        <condition attribute="indskr_businessunit" operator="eq" value="{userBUId}" />
      </filter>
    </link-entity>
  </entity>
</fetch>`;

export const SURVEY_LOOKUP_POPOVER = 
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  {entityName}
    {attributeId}
    {attributeName}
    <filter type="and">
      <condition attribute="statecode" value="0" operator="eq"/>
      {deltaSyncFilter}
    </filter>
  </entity>
</fetch>`;

export const SURVEY_LOOKUP_POPOVER_OTHERS = 
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  {entityName}
    {attributeId}
    {attributeName}
    <filter type="and">
      <condition attribute="statecode" value="1" operator="eq"/>
      {deltaSyncFilter}
    </filter>
  </entity>
</fetch>`;