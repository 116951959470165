import { RetrieveMultipleResponse } from 'dynamics-web-api';
import { ToastStyle, NotificationService } from '@omni/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from './../../services/device/device.service';
import { AttendeeFormat, EventRegistrationStatus, EventStateCode, EventStatus, EventApprovalStatus, EventModeOfCheckIns } from './../../enums/event/event.enum';
import { IONote } from '@omni/classes/io/io-note.class';
import { ConfigFieldOptionResponse, ConfigFieldOptionValue } from './../../classes/activity/activity.class';
import { ConfiguredFields } from './../../classes/authentication/configured.field.class';
import { EventsToolService } from './../../services/events-tool/events-tool.service';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from './../../config/pouch-db.config';
import { Injectable } from "@angular/core";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { AuthenticationService } from "../../services/authentication.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../../services/disk/disk.service";
import { fetchQueries } from "../../config/dynamics-fetchQueries";
import { differenceInHours, addMinutes, format, isValid } from 'date-fns';
import { Endpoints } from '../../../config/endpoints.config';
import { EntitySyncInfo, EntityNames, DeltaService } from '../delta/delta.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventActivity, EventPass } from '../../classes/events-tool/event.class';
import { OperationDetail } from '../follow-up-activity/follow-up-activity.data.service';
import { Utility } from '../../utility/util';
import { getConfigFieldPickListRequestURL, parseConfigFieldPicklistResponse } from '../../utility/common.utility';
import _ from 'lodash';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { Account } from '@omni/classes/account/account.class';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { PageName } from '@omni/services/navigation/navigation.service';

type EventOptionSetFetchedValues = { [key: string]: ConfigFieldOptionValue[] };

@Injectable({
  providedIn:'root'
})
export class EventsToolDataService{
    constructor(
        public dynamics: DynamicsClientService,
        public authService: AuthenticationService,
        private http: HttpClient,
        public disk: DiskService,
        private deltaService: DeltaService,
        private eventsToolService: EventsToolService,
        private deviceService: DeviceService,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private readonly marketingMgmService: MarketingPlansManagementOfflineService,
        public accountService : AccountOfflineService,
        public contactService : ContactOfflineService
    ) {

    }

    public async syncEventToolData(forceFullSync:boolean = false, loadFromDBOnly:boolean = false){
        if(!this.authService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) return;

        try{
            await Promise.all([
              this.getEventGoals(loadFromDBOnly),
              this.getEventTypes(forceFullSync, loadFromDBOnly),
              this.getSpeakers(forceFullSync, loadFromDBOnly),
              this.syncEventConfigFieldOptionSetValues(loadFromDBOnly),
            ]);
        } catch (error){
            console.log(error);
        }

    }

    private shouldFullSyncForConfigFields(): boolean {
      let shouldFullSync: boolean = false;

      if (Array.isArray(this.authService.individualFullSyncList)) {
        const idx = this.authService.individualFullSyncList.findIndex(i => i === EntityNames.eventsTool);
        if (idx >= 0) {
          shouldFullSync = true;
          this.authService.individualFullSyncList.splice(idx, 1);
        }
      }

      return shouldFullSync;
    }

    public async syncEventsToolActivities(dataRange: { from: string, to: string },forceFullSync:boolean = false, loadFromDBOnly:boolean = false):Promise<any>{
        if(!this.authService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL) && !this.authService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_MEETINGS)) return;

        let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK);

        let offlineDataCount: number = 0;

        let lastUpdatedTime;
        if(offlineData && offlineData.raw && !forceFullSync) {
            if(offlineData.lastUpdatedTime){
                lastUpdatedTime = offlineData.lastUpdatedTime;
            }
            offlineDataCount = offlineData.raw.filter(o => o && o.hasOwnProperty('pendingPushToDynamics') && o.pendingPushToDynamics === true).length;
        }

        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, offlineDataCount);

        if (!loadFromDBOnly) {
            const positions = this.authService.user.positions.map((o) => {
                return o.ID
            });
            let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.SYNC_EVENTS_ACTIVITY_DATA;
            url = url.replace('{{positionIDs}}',positions.toString());
            url = url.replace('{{startDate}}', dataRange.from)
            url = url.replace('{{endDate}}', dataRange.to);
            url = url.replace('{{lastupdatedTime}}',
                              (lastUpdatedTime && !this.shouldFullSyncForConfigFields())
                              ? '&lastUpdatedTime=' + lastUpdatedTime
                              : '');

            const configFields = this.getConfigFieldsString('&');
            if (configFields) {
              url = url.concat(configFields);
            }

            let syncStateEventsTool = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_EVENTS_TOOL);

            const bulkEventsToolSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.eventsTool,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let response;
                response = await this.http.get(url).toPromise();
                this.mapLookupConfigFieldsToResponse(response);
                if (response && Array.isArray(response) && response.length != 0 && response[0]) {
                    bulkEventsToolSyncInfo.totalSynced += response.length;
                    let allEventsToolData: Array<any> = [];
                    if (lastUpdatedTime) {
                        allEventsToolData = offlineData.raw;
                        response.forEach(item => {
                          let idx = allEventsToolData.findIndex(a => a.msevtmgt_eventid == item.msevtmgt_eventid);
                          this.removeTimeZoneForEventDates(item);
                            if (idx >= 0) {
                                if(!(allEventsToolData[idx].pendingPushToDynamics == true)){
                                    if ((
                                      (item['statecode'] == 0 && item['statuscode'] == EventStatus.DRAFT)
                                      || (item['statecode'] == 0 && item['statuscode'] == EventStatus.REJECTED)
                                      || (item['statecode'] === 1 && item['statuscode'] === EventStatus.FOR_REVIEW)
                                      || (item['statecode'] == 0 && item['statuscode'] == EventStatus.APPROVED)
                                      || (item['statecode'] == 1 && item['statuscode'] == EventStatus.CANCELED)
                                      || (item['statecode'] == 1 && item['statuscode'] == EventStatus.COMPLETED)
                                      ) && //Draft, Approved and Completed Filter
                                        (   this.authService.user.positions.some(pos=> pos.ID == item['_indskr_positionid_value']) || //Event Mapped to user position
                                            this.authService.user.childPositions.some(childpos=> childpos.childPositionId == item['_indskr_positionid_value']) || // Event Mapped to child user position
                                            ( item['covisitors'] && item['covisitors'].length != 0 && item['covisitors'].some(cov => cov['teammember_statecode'] == 0 && cov['user'] == this.authService.user.systemUserID)) // Event Mapped as Covisitor
                                        )
                                    ){
                                        allEventsToolData[idx] = item;
                                    }else {
                                        allEventsToolData.splice(idx, 1);
                                    }
                                }
                            } else {
                                if (
                                  (item['statecode'] == 0 && item['statuscode'] == EventStatus.DRAFT)
                                  || (item['statecode'] == 0 && item['statuscode'] == EventStatus.REJECTED)
                                  || (item['statecode'] === 1 && item['statuscode'] === EventStatus.FOR_REVIEW)
                                  || (item['statecode'] == 0 && item['statuscode'] == EventStatus.APPROVED)
                                  || (item['statecode'] == 1 && item['statuscode'] == EventStatus.COMPLETED)
                                ){
                                    allEventsToolData.push(item);
                                }
                            }
                        });
                        offlineData = {
                            raw: allEventsToolData,
                            lastUpdatedTime: new Date().getTime(),
                        }
                    } else {
                      response = response.filter(item => {
                        return (((item['statecode'] == 0 && item['statuscode'] == EventStatus.DRAFT)
                          || (item['statecode'] == 0 && item['statuscode'] == EventStatus.REJECTED)
                          || (item['statecode'] === 1 && item['statuscode'] === EventStatus.FOR_REVIEW)
                          || (item['statecode'] == 0 && item['statuscode'] == EventStatus.APPROVED)
                          || (item['statecode'] == 1 && item['statuscode'] == EventStatus.COMPLETED)) && //Draft, Approved and Completed Filter
                          (this.authService.user.positions.some(pos => pos.ID == item['_indskr_positionid_value']) || //Event Mapped to user position
                            this.authService.user.childPositions.some(childpos => childpos.childPositionId == item['_indskr_positionid_value']) || // Event Mapped to child user position
                            (item['covisitors'] && item['covisitors'].length != 0 && item['covisitors'].some(cov => cov['teammember_statecode'] == 0 && cov['user'] == this.authService.user.systemUserID)) // Event Mapped as Covisitor
                          )
                        )
                      }).map(item => {
                        this.removeTimeZoneForEventDates(item);
                        return item;
                      });
                        lastUpdatedTime = new Date().getTime()
                        offlineData = {
                            raw: response,
                            lastUpdatedTime: lastUpdatedTime,
                        };
                    }

                    syncStateEventsTool.lastUpdatedTime = lastUpdatedTime;
                    this.eventsToolService.initData(offlineData.raw);
                    await this.disk.updateSyncState(syncStateEventsTool);
                    await this.deltaService.addEntitySyncInfo(bulkEventsToolSyncInfo);
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, doc => {
                        doc = offlineData;
                        return doc;
                    }).catch(error => console.error('Save events tool bulk data in offline db error: ', error));
                } else {
                  this.eventsToolService.initData(offlineData?.raw);
                }
            } catch (error) {
                this.deltaService.addSyncErrorToEntitySyncInfo(bulkEventsToolSyncInfo, url, error);
            }
        } else {
          this.eventsToolService.initData(offlineData?.raw);
        }

    }

   // removing the timezone for online data as the event already getting stored in user timezone
   removeTimeZoneForEventDates(item) {
    if (item['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue']) {
      const date = new Date(item['msevtmgt_eventstartdate']);
      const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
      item['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue'] = new Date(date.getTime() - userTimezoneOffset);
    }
    if (item['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue']) {
      const date = new Date(item['msevtmgt_eventenddate']);
      const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
      item['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue'] = new Date(date.getTime() - userTimezoneOffset);
    }
  }

    public createNewEvent(action: OperationDetail): Promise<EventActivity> {
        return new Promise(async (resolve, reject) => {
            let now = new Date().getTime();
            let startTime = Utility.getRoundedDownTime(new Date(now));
            startTime = addMinutes(startTime, 30);
            let endTime = addMinutes(startTime, 30);
            const rawPayload = {
                "statecode@OData.Community.Display.V1.FormattedValue": "Active",
                "statecode": 0,
                "msevtmgt_name": "Event",
                "statuscode@OData.Community.Display.V1.FormattedValue": "Draft",
                "statuscode": EventStatus.DRAFT,
                'indskr_approvalstatus': EventApprovalStatus.Draft,
                "indskr_externalid": 'offline_event_activity_' + now,
                "pendingPushToDynamics": true,
                "createdon": now.toString(),
                // "modifiedon": now.toString(),
                "_ownerid_value": this.authService.user.systemUserID,
                "_ownerid_value@OData.Community.Display.V1.FormattedValue": this.authService.user.displayName,
                "msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue": format(startTime),
                "msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue": format(endTime),
                "_indskr_positionid_value": this.authService.user.xPositionID,
            };
            if(this.authService.user.defaultCurrencyID){
              rawPayload["_transactioncurrencyid_value"] = this.authService.user.defaultCurrencyID
              const currency = this.marketingMgmService.currencies.find(o=>o.id==this.authService.user.defaultCurrencyID)
              if(currency){
                rawPayload["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] = currency.name
              }
            }
            let newEvent = new EventActivity(rawPayload);
            this.addIoConfigDefaultValuesToEventActivity(newEvent);
            let checkNextAction: boolean = true;
            if (action.onDynamics) {
                let serviceDTO = this.getServiceDTOForEvent(newEvent);
                await this.createNewEventOnline(serviceDTO).then(info => {
                    // Succesfully created on dynamics
                    if (info && info['msevtmgt_eventid']) {
                        newEvent.ID = info['msevtmgt_eventid'];
                        newEvent.pendingPushToDynamics = false;
                    }
                    if (!action.onLocalDatabase && !action.onLocalCopy) {
                        resolve(newEvent);
                        checkNextAction = false;
                    }
                }).catch(error => {
                    // Handle any error scenario
                    //Still Create it as an offline event activity
                    checkNextAction = true;
                    return;
                });
            }
            if (action.onLocalDatabase && checkNextAction) {
                let now = new Date().getTime();
                let offlineDataDoc;
                let offlineData;
                await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, true).then((doc) => {
                    offlineDataDoc = doc;
                    if (doc && doc.raw) {
                        offlineData = doc.raw;
                    }
                    else {
                        offlineDataDoc = {
                            raw: [],
                            lastUpdatedTime: null,
                        }
                        offlineData = [];
                    }
                }).catch(err=>{
                    offlineDataDoc = {
                        raw: [],
                        lastUpdatedTime: null,
                    }
                    offlineData = [];
                });
                let offlineDTO = this.getOfflineDTOForEvent(newEvent);
                offlineDTO['lastUpdatedTime'] = now;
                offlineData.push(offlineDTO);
                offlineDataDoc.raw = offlineData;
                let pendingPushCount:number = 0;
                if(newEvent.pendingPushToDynamics){
                    pendingPushCount = 1;
                }
                if (offlineData && Array.isArray(offlineData)) {
                    try {
                        await this.disk.updateOrInsert(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, (doc) => {
                            doc = offlineDataDoc;
                            return doc;
                        })
                        // Track offline data count
                        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, pendingPushCount);
                    } catch (error) {
                        reject('ERROR_WHILE_SAVING_OFFLINE_DATA' + error);
                        checkNextAction = false;
                    }
                    if (!action.onLocalCopy) {
                        resolve(newEvent);
                        checkNextAction = false;
                    }
                }
            }
        });
    }

    private processUpdateResponse(dto: any, event: EventActivity, response: any) {
      if (response?.msevtmgt_eventid) {
        event.pendingPushToDynamics = false;
        if(response['addParticipants'] && Array.isArray(response['addParticipants']) && response['addParticipants'].length != 0){
          response['addParticipants'].forEach(addedParticipant=>{
              let idx = event.participants.findIndex(p=> p.id == addedParticipant['contactid'] || (p.registrationId && p.registrationId == addedParticipant['msevtmgt_eventregistrationid']));
              if(idx >= 0){
                  if(addedParticipant['msevtmgt_eventregistrationid']){
                      event.participants[idx].registrationId = addedParticipant['msevtmgt_eventregistrationid'];
                      event.participants[idx].ERNumber = addedParticipant['eventregistration_name'];
                      event.participants[idx].indskr_modeofcheckin = addedParticipant['indskr_modeofcheckin'];
                      if (addedParticipant.isAddedBack) {
                        event.participants[idx].isAddedBack = false;
                        delete event.participants[idx].isAddedBack;
                      }
                  }
                  if(addedParticipant['msevtmgt_checkinid']){
                      event.participants[idx].checkinId = addedParticipant['msevtmgt_checkinid'];
                  }
              }
          });
        }
        if(response['addCovisitors'] && Array.isArray(response['addCovisitors']) && response['addCovisitors'].length != 0){
          response['addCovisitors'].forEach(addedCovisitor=>{
              let idx = event.covisitors.findIndex(p=> p.id == addedCovisitor['userid']);
              if(idx >= 0){
                  if(addedCovisitor['msevtmgt_eventteammemberid']){
                      event.covisitors[idx].teamMemberId = addedCovisitor['msevtmgt_eventteammemberid'];
                  }
              }
          });
        }
        if(response['addSpeakers'] && Array.isArray(response['addSpeakers']) && response['addSpeakers'].length != 0){
          response['addSpeakers'].forEach(addedSpeaker=>{
              let idx = event.speakers.findIndex(p=> p.id == addedSpeaker['msevtmgt_speakerid']);
              if(idx >= 0){
                  if(addedSpeaker['msevtmgt_speakerengagementid']){
                      event.speakers[idx].engagementId = addedSpeaker['msevtmgt_speakerengagementid'];
                  }
              }
          });
        }
        if (response['eventCheckInByContact'] && Array.isArray(response['eventCheckInByContact']) && response['eventCheckInByContact'].length != 0) {
          response['eventCheckInByContact'].forEach(eventCheckInByContact => {
            let idx = event.participants.findIndex(p => p.id == eventCheckInByContact['contactid'] || (p.registrationId && p.registrationId == eventCheckInByContact['msevtmgt_eventregistrationid']));
            if (idx >= 0) {
              if (eventCheckInByContact['msevtmgt_eventregistrationid']) {
                event.participants[idx].registrationId = eventCheckInByContact['msevtmgt_eventregistrationid'];
                event.participants[idx].eventRegistrationName = eventCheckInByContact['eventregistration_name'];
              }
              if (eventCheckInByContact['msevtmgt_checkinid']) {
                event.participants[idx].checkinId = eventCheckInByContact['msevtmgt_checkinid'];
              }
            }
          });
        }
        if (dto.removeCovisitors) {
          event.covisitors = event.covisitors.filter(a=> !a.isDeleted);
        }
        if (dto.removeSpeakers) {
          event.speakers = event.speakers.filter(a=> !a.isDeleted);
        }
        if (dto.removeAccounts) {
          event.accounts = event.accounts.filter(a=> !a.isDeleted);
        }
        if (dto.removeProducts) {
          event.products = event.products.filter(a=> !a.isDeleted);
        }
        if (dto.removePresentations) {
          event.presentations = event.presentations.filter(a => !a.isDeleted);
        }
        if (dto.removeParticipants) {
          if (event.participants.some(a => a.isDeleted)) {
            event.participants.filter(a => a.isDeleted).forEach(a => {
              a.isDeleted = false;
              a.indskr_reasons = 451680003;
            });
          }
        }
      }
    }
    private processUploadResponse(data: EventActivity[], response: any) {
      if (response && Array.isArray(response)) {
        response.forEach(eventResponse => {
          if (!eventResponse['error'] && !eventResponse['errorMessage'] && eventResponse['msevtmgt_eventid']) {
            let idx = data.findIndex(a => {
              if (a.ID) {
                return a.ID == eventResponse['msevtmgt_eventid']
              } else if (a.offlineId && eventResponse['indskr_externalid']) {
                return a.offlineId === eventResponse['indskr_externalid']
              }
            });
            if (idx >= 0) {
              data[idx].ID = eventResponse['msevtmgt_eventid'];
              data[idx].pendingPushToDynamics = false;
              if (data[idx].statecode == 1 && data[idx].statuscode == EventStatus.CANCELED) {// Check for deleted status
                data[idx].isHardDeleted = true;
              }
              if (eventResponse['addParticipants'] && Array.isArray(eventResponse['addParticipants']) && eventResponse['addParticipants'].length != 0){
                eventResponse['addParticipants'].forEach(addedParticipant=>{
                  let idxp = data[idx].participants.findIndex(p=> p.id == addedParticipant['contactid'] || (p.registrationId && p.registrationId == addedParticipant['msevtmgt_eventregistrationid']));
                  if (idxp >= 0) {
                    if (addedParticipant['msevtmgt_eventregistrationid']){
                      data[idx].participants[idxp].registrationId = addedParticipant['msevtmgt_eventregistrationid'];
                      data[idx].participants[idxp].ERNumber = addedParticipant['eventregistration_name'];
                      data[idx].participants[idxp].indskr_modeofcheckin = addedParticipant['indskr_modeofcheckin'];
                      if (addedParticipant.isAddedBack) {
                        data[idx].participants[idxp].isAddedBack = false;
                        delete data[idx].participants[idxp].isAddedBack;
                      }
                    }
                    if(addedParticipant['msevtmgt_checkinid']){
                        data[idx].participants[idxp].checkinId = addedParticipant['msevtmgt_checkinid'];
                    }
                  }
                });
              }
              if(eventResponse['addCovisitors'] && Array.isArray(eventResponse['addCovisitors']) && eventResponse['addCovisitors'].length != 0){
                eventResponse['addCovisitors'].forEach(addedCovisitor=>{
                  let idxc = data[idx].covisitors.findIndex(p=> p.id == addedCovisitor['userid']);
                  if (idxc >= 0) {
                    if (addedCovisitor['msevtmgt_eventteammemberid']) {
                      data[idx].covisitors[idxc].teamMemberId = addedCovisitor['msevtmgt_eventteammemberid'];
                    }
                  }
                });
              }
              if(eventResponse['addSpeakers'] && Array.isArray(eventResponse['addSpeakers']) && eventResponse['addSpeakers'].length != 0){
                eventResponse['addSpeakers'].forEach(addedSpeaker=>{
                  let idxs = data[idx].speakers.findIndex(p=> p.id == addedSpeaker['msevtmgt_speakerid']);
                  if (idxs >= 0) {
                    if(addedSpeaker['msevtmgt_speakerengagementid']){
                        data[idx].speakers[idxs].engagementId = addedSpeaker['msevtmgt_speakerengagementid'];
                    }
                  }
                });
              }
              if (eventResponse['eventCheckInByContact'] && Array.isArray(eventResponse['eventCheckInByContact']) && eventResponse['eventCheckInByContact'].length != 0) {
                eventResponse['eventCheckInByContact'].forEach(eventCheckInByContact => {
                  let idxe = data[idx].participants.findIndex(p => p.id == eventCheckInByContact['contactid'] || (p.registrationId && p.registrationId == eventCheckInByContact['msevtmgt_eventregistrationid']));
                  if (idx >= 0) {
                    if (eventCheckInByContact['msevtmgt_eventregistrationid']) {
                      data[idx].participants[idx].registrationId = eventCheckInByContact['msevtmgt_eventregistrationid'];
                      data[idx].participants[idx].eventRegistrationName = eventCheckInByContact['eventregistration_name'];
                    }
                    if (eventCheckInByContact['msevtmgt_checkinid']) {
                      data[idx].participants[idxe].checkinId = eventCheckInByContact['msevtmgt_checkinid'];
                    }
                  }
                });
              }
              if (
                data[idx].offlineApprovalSubmit
              ) {
                data[idx].offlineApprovalSubmit = false;
                delete data[idx].offlineApprovalSubmit;

                if (
                  !data[idx].hasOwnProperty('indskr_approvalstatus')
                ) {
                  // Event has been created but something went wrong with approval submission...
                  console.error('processUploadResponse: Something went wrong with approval submission: ', data[idx]);
                } else if (eventResponse.approvalSubmitErrorCode) {
                  // Submit failed
                  data[idx].pendingPushToDynamics = true;
                  let errorMessage = this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE');
                  if (eventResponse.approvalSubmitErrorCode.includes('ERR_EV_APPROVE')) {
                    errorMessage = this.translate.instant(eventResponse.approvalSubmitErrorCode);
                  }
                  this.notificationService.notify(errorMessage, 'Event Details', 'top', ToastStyle.DANGER);
                } else {
                  // Submit success
                  data[idx].statecode = EventStateCode.Inactive;
                  data[idx].statuscode = EventStatus.FOR_REVIEW;
                }
              }
              data[idx].covisitors = data[idx].covisitors.filter(a=> !a.isDeleted);
              data[idx].speakers = data[idx].speakers.filter(a=> !a.isDeleted);
              data[idx].accounts = data[idx].accounts.filter(a=> !a.isDeleted);
              data[idx].products = data[idx].products.filter(a=> !a.isDeleted);
              data[idx].presentations = data[idx].presentations.filter(a => !a.isDeleted);
              if (data[idx].participants.some(a => a.isDeleted)) {
                data[idx].participants.filter(a => a.isDeleted).forEach(a => {
                  a.isDeleted = false;
                  a.indskr_reasons = 451680003;
                });
              }
              if(this.eventsToolService.selectedEventOnEventsTool && (this.eventsToolService.selectedEventOnEventsTool.ID == data[idx].ID || ( this.eventsToolService.selectedEventOnEventsTool.offlineId && this.eventsToolService.selectedEventOnEventsTool.offlineId == data[idx].offlineId))){
                this.eventsToolService.setSelectedEventUpdates(data[idx]);
              }
            }
          } else if (
            (eventResponse['error'] || eventResponse['errorMessage'])
            && (eventResponse['msevtmgt_eventid']
            || eventResponse['indskr_externalid'])
          ) {
            // Approval flow edge case
            // if the total budget field update fails (it is required field)
            if (
              eventResponse.totalBudgetUpdateErrorMsg
            ) {
              let idx = data.findIndex(a => {
                if (a.ID) {
                  return a.ID == eventResponse['msevtmgt_eventid']
                } else if (a.offlineId && eventResponse['indskr_externalid']) {
                  return a.offlineId === eventResponse['indskr_externalid']
                }
              });

              if (idx >= 0) {
                if (eventResponse['msevtmgt_eventid']) {
                  data[idx].ID = eventResponse['msevtmgt_eventid'];
                }
                data[idx].budgetAllocated = null;

                // Cancel approval submission because
                // required field is reverted back to null
                if (data[idx].offlineApprovalSubmit) {
                  data[idx].offlineApprovalSubmit = false;
                  delete data[idx].offlineApprovalSubmit;
                }

                this.notificationService.notify(
                  eventResponse.totalBudgetUpdateErrorMsg,
                  'Event Details', 'top', ToastStyle.DANGER
                );
              }
            }
          }
        });
      }
    }
    private updateLocalDBDoc(event: EventActivity, offlineData: any[]) {
      let idx = offlineData.findIndex(value => {
        let flag: boolean = false;
        if (value.hasOwnProperty('msevtmgt_eventid') && value.msevtmgt_eventid) {
          flag = value.msevtmgt_eventid == event.ID;
        } else if(value.hasOwnProperty('indskr_externalid')){
          flag = value.indskr_externalid == event.offlineId;
        }
        return flag
      });
      if (idx >= 0) {
        if (event.isHardDeleted) {
          if (offlineData[idx].pendingPushToDynamics) {
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, 1);
          }
          offlineData.splice(idx, 1);
        } else {
          if (!offlineData[idx].pendingPushToDynamics && event.pendingPushToDynamics) {
            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, 1);
          }
          offlineData[idx] = this.getOfflineDTOForEvent(event);
        }
      } else if (!event.isHardDeleted) {
        offlineData.push(this.getOfflineDTOForEvent(event));
        if (event.pendingPushToDynamics) {
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, 1);
        }
      }
    }
    public updateEventsData(action:OperationDetail,data:Array<EventActivity>): Promise<Array<EventActivity>> {
        return new Promise(async (resolve,reject) => {
            if(data.length == 0){
                reject('No Data Passed For Operation');
            }
            let checkNextAction:boolean = true;
            if(action.onDynamics){
                if(data && data.length == 1 && (!action.operationDetail || action.operationDetail.code !== 'OFFLINEUPLOAD')){
                    if (data[0].ID && !data[0].ID.includes('offline')) {
                      try {
                        let response;
                        let dto;

                        if (action.operationDetail?.payload) {
                          // New flow with payload optimization
                          dto = action.operationDetail.payload;
                          data[0].pendingPushToDynamics = false;
                          response = await this.updateEvent(data[0], dto);
                        } else {
                          // Leaving the old flow (full event dto payload) just in case
                          dto = this.getServiceDTOForEvent(data[0], action.operationDetail?.code);
                          response = await this.updateEventOnline(data[0].ID, dto);
                        }
                        this.processUpdateResponse(dto, data[0], response);

                        if (!action.onLocalDatabase && !action.onLocalCopy) {
                          this.eventsToolService.setEventsUpdate(data);
                          checkNextAction = false;
                          resolve(data);
                        }
                      } catch (error) {
                        console.error('updateEventsData: ', error);
                      }
                    }else {
                        // Check whether to push activity online or not
                        if (action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapeventactivity') {
                            // Deleting an event that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
                            data[0].pendingPushToDynamics = false;
                            data[0].isHardDeleted = true;
                        }
                    }
                }else{ // Bulk update on dynamics
                    if(action.onDynamics){
                        let payload = data.map(event => {
                            let serviceDTO = this.getServiceDTOForEvent(event, action.operationDetail?.code);
                            return serviceDTO;
                        });
                        await this.uploadEventsOnline(payload).then(response => {
                            this.processUploadResponse(data, response);

                            if (!action.onLocalDatabase && !action.onLocalCopy) {
                                this.eventsToolService.setEventsUpdate(data);
                                resolve(data);
                                checkNextAction = false;
                            }
                        }).catch(err => {
                            if (action.operationDetail && action.operationDetail.code == 'OFFLINEUPLOAD') {
                                checkNextAction = false;
                                reject('Error Occoured while uploading events' + '\ndata: ' + data + '\nError: ' + err);
                            }
                        })
                    }
                }
            }
            if(action.onLocalDatabase && checkNextAction && this.eventsToolService.eventsToolData && this.eventsToolService.eventsToolData.length){
                if(data){
                    let now = new Date().getTime();
                    let offlineDataDoc;
                    let offlineData;
                    await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, true).then((doc) => {
                        offlineDataDoc = doc;
                        if (doc && doc.raw) {
                            offlineData = doc.raw;
                        }
                        else {
                            offlineDataDoc = {
                                raw: [],
                                lastUpdatedTime: null,
                            }
                            offlineData = [];
                        }
                    }).catch(err=>{
                        offlineDataDoc = {
                            raw: [],
                            lastUpdatedTime: null,
                        }
                        offlineData = [];
                    });

                    data.forEach(d => this.updateLocalDBDoc(d, offlineData));

                    if (offlineData && Array.isArray(offlineData)) {
                        offlineDataDoc.raw = offlineData;
                        try {
                            await this.disk.updateOrInsert(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, (doc) => {
                                doc = offlineDataDoc;
                                return doc;
                            })
                        } catch (error) {
                            reject('ERROR_WHILE_SAVING_OFFLINE_DATA'+error);
                            checkNextAction = false;
                        }
                    }
                    if ((action.operationDetail && action.operationDetail.code == 'OFFLINEUPLOAD')){
                      this.eventsToolService.initData(offlineDataDoc.raw);
                    }
                    if (!action.onLocalCopy) {
                        this.eventsToolService.setEventsUpdate(data);
                        resolve(data);
                        checkNextAction = false;
                    }
                }
            }
            if(action.onLocalCopy && checkNextAction){
              try {
                if (data) {
                  data.forEach(event => {
                    let idx = this.eventsToolService.eventsToolData.findIndex(a=> a.ID == event.ID || a.offlineId == event.offlineId);
                    if(idx >= 0){
                      if(action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapeventactivity'){
                        this.eventsToolService.eventsToolData.splice(idx,1);
                      }else{
                        this.eventsToolService.eventsToolData[idx] = event;
                      }
                    }
                  });
                  resolve(data);
                }
              } catch (error) {
                console.log(error);
              }
            }
        });
    }

    // Make sure to check that modifiedon attribute of event record is
    // updated after calling this.
    // This is important because delta sync depends on it.
    public async updateEvent(event: EventActivity, dto: {}): Promise<boolean> {
      const id = event.ID ? event.ID : event.offlineId;
      if (!id) {
        console.error('updateEvent: event ID not provided: ', id, dto);
        return;
      } else if (!dto) {
        console.error('updateEvent: DTO not provided: ', id, dto);
        return;
      }
      let operationCode: string;

      if (
        !(
          id.includes('offline')
          || event.pendingPushToDynamics
        )
        && !this.deviceService.isOffline
      ) {
        // Online record update
        try {
          event.pendingPushToDynamics = true;

          const response: any = await this.postEventOnline(id, dto);

          if (response) {
            // Process and update local event record
            this.processUpdateResponse(dto, event, response);
          }
        } catch (error) {
          console.error('updateEvent: ', error);
          if (error?.error?.errorMessage?.includes('budget entered for the Event exceeds')) {
            // This is an error from dynamics that refuses to update
            // the budget value based on the configuration.
            // Hence, we cannot just save it as offline record and try again later.
            // Need to throw an error to revert the value and let the user know about it.
            throw { errorCode: 'ERR_EV_BUDGET_EXCEEDS', errorMessage: error.error.errorMessage };
          } else if (!this.deviceService.isNativeApp) {
            // Throw error for the browser platform
            throw error;
          }
        }
      } else if (
        this.deviceService.isNativeApp
        && (
          id.includes('offline')
          || event.pendingPushToDynamics
        )
        && !this.deviceService.isOffline
      ) {
        // Offline record but device's online.
        // Try to upload
        try {
          operationCode = 'OFFLINEUPLOAD';
          const serviceDTO = this.getServiceDTOForEvent(event, operationCode);
          const response = await this.uploadEventsOnline([serviceDTO]);
          if (response) {
            // Process and update local event record
            this.processUploadResponse([event], response);
          }
        } catch (error) {
          console.error('updateEvent: offline upload', error);
        }
      } else if (this.deviceService.isNativeApp) {
        // Offline record update
        event.pendingPushToDynamics = true;
      } else {
        // Browser platform should not rely on offline data
        return false;
      }

      // Update DB
      try {
        const doc = await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, true);
        const offlineData = Array.isArray(doc?.raw) ? doc?.raw : [];

        this.updateLocalDBDoc(event, offlineData);

        if (operationCode === 'OFFLINEUPLOAD') {
          this.eventsToolService.initData(offlineData);
        }

        if (doc) {
          // Update existing record
          doc.raw = offlineData;
          await this.disk.updateDocWithIdAndRev(doc);
        } else {
          // New event DB record creation
          await this.disk.createDocument(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, {
            raw: offlineData,
          });
        }
      } catch (error) {
        console.error('updateEvent: update DB', error);
      }

      return true;
    }

    public async patchEventAttributes(action: OperationDetail, event: EventActivity, dto: {}, isConfigField: boolean = false) {
      if (!event) {
        console.error('patchEventAttributes: event not provided: ', action, event, dto);
      } else if (!dto) {
        console.error('patchEventAttributes: DTO not provided: ', action, event, dto);
      } else {
        let checkNextAction: boolean = true;
        let pendingPushToDynamics: boolean = false;

        if (action.onDynamics) {
          if (!event.ID?.includes('offline')) {
            // Online record
            try {
              const response = await this.patchEventOnline(event.ID, dto);
              if (!action.onLocalDatabase && !action.onLocalCopy) {
                checkNextAction = false;
                if (!isConfigField) {
                  this.eventsToolService.setEventsUpdate([event]);
                }
              }
            } catch (error) {
              console.error('patchEventAttributes: ', error);
              // Let's not update if it failed online because
              // there's no clear way of knowing whether
              // the update failed due to server or misconfiguration
              // Unfortunately, can't really control offline scenarios
              throw error;
            }
          }
        }

        if (action.onLocalDatabase && checkNextAction) {
          const offlineDoc = await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, true);

          if (!offlineDoc || !Array.isArray(offlineDoc.raw)) {
            console.error('patchEventAttributes: Offline Doc does not exist?');
            // Safer not to overwrite because it could have been some other issue..
            // TODO: let's find what to do with this scenario
          } else {
            let isDataUpdated: boolean = false;
            const idx = offlineDoc.raw.findIndex(value => {
              let flag: boolean = false;
              if (value.hasOwnProperty('msevtmgt_eventid') && value.msevtmgt_eventid) {
                  flag = value.msevtmgt_eventid == event.ID;
              } else if(value.hasOwnProperty('indskr_externalid')){
                  flag = value.indskr_externalid == event.offlineId;
              }
              return flag;
            });

            if (idx >= 0) {
              if (!event.isHardDeleted) {
                if (
                  !offlineDoc.raw[idx].pendingPushToDynamics
                  && !event.pendingPushToDynamics
                  && pendingPushToDynamics
                ) {
                  this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, 1);
                  event.pendingPushToDynamics = true;
                  // event.modifiedOn = new Date();
                }
                offlineDoc.raw[idx] = this.getOfflineDTOForEvent(event);
                isDataUpdated = true;
              }
            } else if (!event.isHardDeleted) {
              offlineDoc.raw.push(this.getOfflineDTOForEvent(event));
              isDataUpdated = true;
              if (!event.pendingPushToDynamics && pendingPushToDynamics) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, 1);
                event.pendingPushToDynamics = true;
                // event.modifiedOn = new Date();
              }
            }

            if (isDataUpdated) {
              try {
                await this.disk.updateOrInsert(
                  DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK,
                  doc => offlineDoc
                );

              } catch (error) {
                console.error('patchEventAttributes: ', error);
              }
            }

            if (!action.onLocalCopy && !isConfigField) {
              this.eventsToolService.setEventsUpdate([event]);
            }
          }
        }
      }
    }

    public async fetchEventRealTimeDetails(event:EventActivity):Promise<any>{
        return new Promise(async (resolve,reject) => {
            if(event.ID && !event.ID.includes('offline')){
                if(event.pendingPushToDynamics == true){
                    reject('Event has pending changes to be pushed before syncing updated details');
                }else{
                    try {
                        let response = await this.fetchEventDetailsOnline(event.ID);
                        this.mapLookupConfigFieldsToResponse([response]);
                      if (response && response['msevtmgt_eventid']) {
                        this.removeTimeZoneForEventDates(response);
                            let updatedEvent = new EventActivity(response);
                            if(!isValid(updatedEvent.scheduledStart)){
                              updatedEvent.scheduledStart = updatedEvent.startDate;
                            }
                            if(!isValid(updatedEvent.scheduledEnd)){
                              updatedEvent.scheduledEnd = updatedEvent.endDate;
                            }
                            const action:OperationDetail = {
                                onDynamics: false,
                                onLocalDatabase: true,
                                onLocalCopy: true,
                                operationDetail: {
                                    code: 'REALTIMEDETAILSUPDATE',
                                    message: 'Updating Event Details in offline DB after realtime details fetch',
                                }
                            };
                            if(!(
                              (updatedEvent.statecode == 0 && updatedEvent.statuscode == EventStatus.DRAFT)
                              || (updatedEvent.statecode == 0 && updatedEvent.statuscode == EventStatus.REJECTED)
                              || (updatedEvent.statecode === 1 && updatedEvent.statuscode === EventStatus.FOR_REVIEW)
                              || (updatedEvent.statecode == 0 && updatedEvent.statuscode == EventStatus.APPROVED)
                              || (updatedEvent.statecode == 1 && updatedEvent.statuscode == EventStatus.COMPLETED))
                            ){
                                updatedEvent.isHardDeleted = true;
                            }
                            if (
                                !this.authService.user.positions.some(pos=> pos.ID == updatedEvent.positionId) && //Event Mapped to user position
                                !this.authService.user.childPositions.some(childpos=> childpos.childPositionId == updatedEvent.positionId) && // Event Mapped to child user position
                                !( updatedEvent.covisitors && updatedEvent.covisitors.length != 0 && updatedEvent.covisitors.some(cov => cov.teamMemberStatecode == 0 && cov.id == this.authService.user.systemUserID)) // Event Mapped as Covisitor
                            ){
                                updatedEvent.isHardDeleted = true;
                            }
                            await this.updateEventsData(action,[updatedEvent]);
                            resolve(updatedEvent);
                        }
                    } catch (error) {
                        reject(error);
                    }
                }
            }else{
                reject('Please pass event guid');
            }
        });
    }

    private async createNewEventOnline(payload):Promise<any> {
        let url:string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.CREATE_UPDATE_NEW_EVENT;
        // Create new event on dynamics and return promise with success or failure
        return this.http.post(url, payload).toPromise();
    }

    private async updateEventOnline(id,payload) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.CREATE_UPDATE_NEW_EVENT;
        url = url.replace('{{eventid}}', id)
        // Update event on dynamics and return promise with success or failure
        return this.http.post(url, payload).toPromise();
    }

    private async patchEventOnline(id: string, body: {}) {
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.PATCH_REALTIME_DETAILS;
      url = url.replace('{{eventid}}', id);

      return await this.http.patch(url, body).toPromise();
    }

    private async postEventOnline(id: string, body: {}) {
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.PATCH_REALTIME_DETAILS;
      url = url.replace('{{eventid}}', id);

      return await this.http.post(url, body).toPromise();
    }

    private async uploadEventsOnline(payload: any[]) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.UPLOAD_EVENTS_DATA;
        // Upload events on dynamics and return promise with success or failure
        return this.http.post(url, payload).toPromise();
    }

    private async fetchEventDetailsOnline(id: string) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.FETCH_REALTIME_DETAILS;
        url = url.replace('{{eventid}}', id);
        const configFields = this.getConfigFieldsString('?');
        if (configFields) {
          url = url.concat(configFields);
        }
        // Fetch realtime event details from dynamics and return promise with response
        return this.http.get(url).toPromise();
    }

    public async getPastEventAttachments(contactIds: string[]) {
      let contactsFormatted = "";
      contactIds.forEach(id => {
        contactsFormatted += '<value>'+id+'</value>'
      });
      const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
      let fetchXML = fetchQueries.fetchPastEventAttachments.replace('{0}', contactsFormatted).replace('{1}', String(duration));
      await this.dynamics.executeFetchQuery('msevtmgt_eventregistrations', fetchXML).then(async (res)=>{
        await this.eventsToolService.aggregateEventAttachments(res)
      }).catch(err => {
        console.log('Error occured while fetching Event Attachments: ' + err);
      })
    }

  public async getAnnotationById(annotationId: string) {
    let fetchXML = fetchQueries.fetchAnnotation.replace('{0}', annotationId);
    await this.dynamics.executeFetchQuery('annotations', fetchXML).then(async (res)=>{
      this.eventsToolService.annotation = res[0];
    }).catch(err => {
      console.log('Error occured while fetching Event Attachments: ' + err);
    })
  }

  private async loadOfflineEventGoals() {
    try {
      const doc = await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_GOALS, true);
      this.eventsToolService.eventGoals = Array.isArray(doc?.raw) ? doc.raw : [];
    } catch (error) {
      console.error('loadOfflineEventGoals: ', error);
    }
  }
  private async getEventGoals(loadFromDBOnly = false) {
    if (loadFromDBOnly) {
      await this.loadOfflineEventGoals();
    } else {
      try {
        let response: RetrieveMultipleResponse<any>;
        const collection = 'indskr_eventgoals';
        const select = [
          'indskr_name',
          'statecode',
        ];
        const filter = `statecode eq 0`;
        const expand = [
          {
            property: 'indskr_indskr_eventgoal_indskr_event_type',
            select: [
              'indskr_event_typeid'
            ],
          },
        ];

        response = await this.dynamics.retrieveMultipleRequest({
          collection,
          select,
          filter,
          expand,
        });

        if (Array.isArray(response?.value)) {
          this.eventsToolService.eventGoals = response.value;
          this.disk.updateOrInsert(DB_KEY_PREFIXES.EVENT_GOALS, doc => {
            doc = {
                raw: []
            };
            doc.raw = response.value;
            return doc;
          });
        }
      } catch (error) {
        console.error('getEventGoals: ', error);
        this.loadOfflineEventGoals();
      }
    }
  }
    private async getEventTypes(fullSync?: boolean, loadFromDBOnly = false){
        if(loadFromDBOnly){
            await this.loadOfflineEventTypes();
        } else {
            try {
                let fetchXML = fetchQueries.eventCheckInFetchXMLs.fetchEventType;
                await this.dynamics.executeFetchQuery('indskr_event_types',fetchXML).then(async (res)=>{
                 console.log('Event Types', res)
                 await this.disk.updateOrInsert(DB_KEY_PREFIXES.EVENT_TYPES, (doc) => {
                    doc = {
                        raw: []
                    };
                    doc.raw = res;
                    return doc;
                    }).then(succ => {
                    this.eventsToolService.aggregateEventTypes(res);
                    })
                }), async (error)=>{
                    this.loadOfflineEventTypes();
                }
            } catch (error){
                console.log('Error occured while fetching Event Types: ' + error);
            }
    }
    }

    private async fetchConfigFieldOptionSetValues(configuredField: ConfiguredFields) {
      const syncInfo: EntitySyncInfo = {
        entityName: EntityNames.eventPicklistAttributes + configuredField.fieldName,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      let url: string;
      try {
        url = this.authService.userConfig.activeInstance.url + getConfigFieldPickListRequestURL(
          configuredField.fieldType,
          configuredField.entityName,
          configuredField.fieldName
        );
        const headers = new HttpHeaders().set('X-SystemUserId', this.authService.user.xSystemUserID);

        const response: ConfigFieldOptionResponse = await this.http.get<ConfigFieldOptionResponse>(url, { headers }).toPromise();

        if (response) {
          const result: ConfigFieldOptionValue[] = parseConfigFieldPicklistResponse(
            response,
            configuredField.entityName,
            configuredField.fieldName,
            configuredField.fieldType,
            configuredField.fieldLabel,
          );

          if (Array.isArray(result)) {
            const options = { [configuredField.fieldName]: result };

            // Keep in the memory
            this.eventsToolService.configFieldOptionsValues = { ...this.eventsToolService.configFieldOptionsValues, ...options };

            // Upsert DB
            const dbId: string = DB_KEY_PREFIXES.EVENT_TYPES_PICKLIST_OPTIONSETS + configuredField.fieldName;
            try {
              await this.disk.updateOrInsert(dbId, doc => ({ options }));
            } catch (error) {
              console.error('fetchConfigFieldOptionSetValues: DB upsert: ', error);
              this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
            }

            syncInfo.totalSynced = result.length;
          }
        }
      } catch (error) {
        console.error('fetchConfigFieldOptionSetValues: ', error);
        this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
        const localData = await this.loadOfflineConfigFieldOptionSetValues(configuredField.fieldName);
        if (localData) {
          this.eventsToolService.configFieldOptionsValues = { ...this.eventsToolService.configFieldOptionsValues, ...localData };
        }
      }
      this.deltaService.addEntitySyncInfo(syncInfo);
    }

    private async loadOfflineConfigFieldOptionSetValues(fieldName?: string): Promise<EventOptionSetFetchedValues> {
      let dbId = DB_KEY_PREFIXES.EVENT_TYPES_PICKLIST_OPTIONSETS;
      let options: EventOptionSetFetchedValues;

      if (fieldName) {
        // Retrieve one field's record
        try {
          dbId = dbId + fieldName;
          const doc = await this.disk.retrieve(dbId, true);
          if (doc?.hasOwnProperty('options')) {
            options = doc.options;
          }
        } catch (error) {
          console.error(`loadOfflineConfigFieldOptionSetValues: retrieve ${fieldName}: `, error);
        }
      } else {
        // Retrieve all fields records
        let option = {
          selector: {
            '_id': {
              $gte: DB_KEY_PREFIXES.EVENT_TYPES_PICKLIST_OPTIONSETS,
              $lte: DB_KEY_PREFIXES.EVENT_TYPES_PICKLIST_OPTIONSETS + PREFIX_SEARCH_ENDKEY_UNICODE
            },
          }
        };

        try {
          const localRecords: { options: EventOptionSetFetchedValues }[] = await this.disk.find(option);
          if (Array.isArray(localRecords)) {
            options = localRecords.reduce((acc, cur) => {
              if (cur.hasOwnProperty('options')) {
                acc = {
                  ...acc,
                  ...cur.options,
                };
              }
              return acc;
            }, {});
          }
        } catch (error) {
          console.error('loadOfflineConfigFieldOptionSetValues: retrieve all: ', error);
        }
      }

      return options;
    }

    private async syncEventConfigFieldOptionSetValues(loadFromDbOnly = false) {
      try {
        if (loadFromDbOnly) {
          const localRecords: EventOptionSetFetchedValues = await this.loadOfflineConfigFieldOptionSetValues();
          if (localRecords) {
            this.eventsToolService.configFieldOptionsValues = localRecords;
          }
        } else {
          const optionSetFields = this.authService.user.eventConfiguredFields.filter(field => field.fieldType === 'Picklist' || field.fieldType === 'Virtual');
          this.eventsToolService.resetConfigFieldOptionsValues();
          const results = await Promise.all([
            ...optionSetFields.map(field => this.fetchConfigFieldOptionSetValues(field))
          ]);
        }
      } catch (error) {
        console.error('getEventConfigFieldOptionSetValues: ', error);
      }
    }

    private async loadOfflineEventTypes() {
        let rawEventTypes;
        await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES, true).then((doc) => {
            if(doc && doc.raw){
                rawEventTypes = doc.raw;
            } else {
                rawEventTypes = [];
            }
        });
        if(Array.isArray(rawEventTypes) && rawEventTypes.length != 0){
            this.eventsToolService.aggregateEventTypes(rawEventTypes);
        }
    }

    private async getSpeakers(fullSync?: boolean, loadFromDBOnly:boolean = false){
      if(!this.authService.hasFeatureAction(FeatureActionsMap.EVENT_CREATION_ON_APP)) return;
        let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.SPEAKERS);
        let lastUpdatedTime;
        if(offlineData && offlineData.raw && !fullSync) {
            if(offlineData.lastUpdatedTime){
                lastUpdatedTime = offlineData.lastUpdatedTime;
            }
        }
        if(loadFromDBOnly){
            await this.loadOfflineSpeakers();
        } else {
            try{
                let fetchXML = fetchQueries.eventCheckInFetchXMLs.fetchSpeaker;
                let now = new Date();
                let hourDifference, deltaSyncFilter;
                if(fullSync){
                    fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
                } else{
                    if(lastUpdatedTime){
                        hourDifference = differenceInHours(now,new Date(lastUpdatedTime))
                        //add one to make sure we take care of fractional difference in hours
                        hourDifference += 1
                        const entityname = 'msevtmgt_speaker'
                        deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
                        deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference);
                        deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
                    } else {
                        deltaSyncFilter = ''
                    }
                    fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter)
                }
                await this.dynamics.executeFetchQuery('msevtmgt_speakers',fetchXML).then(async (res)=>{
                    console.log('Fetch Event Speakers', res);
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SPEAKERS, (doc) => {
                        doc = {
                            raw: []
                        };
                        doc.raw = res;
                        return doc;
                    }).then(succ => {
                        this.eventsToolService.mapSpeakers(res);
                    })
                }), async (error) => {
                    this.loadOfflineSpeakers();
                }
            } catch (error){
                console.log('Error occured while fetching Event Speakers: ' +error);
            }
        }
    }

    private async loadOfflineSpeakers() {
        let rawSpeakers;
        await this.disk.retrieve(DB_KEY_PREFIXES.SPEAKERS, true).then((doc) => {
            if(doc && doc.raw){
                rawSpeakers = doc.raw;
            } else {
                rawSpeakers = [];
            }
        });
        if(Array.isArray(rawSpeakers) && rawSpeakers.length != 0){
            this.eventsToolService.mapSpeakers(rawSpeakers);
        }
    }

    public async uploadOfflineEventsToolData(isPartialUpload,maxRecordCountForPartialUpload):Promise<any> {
        let eventsData:Array<EventActivity> = [];
        let offlineData;
        await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK,true).then((doc) => {
            if(doc && doc.raw){
                offlineData = doc.raw;
            }else{
                offlineData = [];
            }
        });
        if(offlineData && offlineData.length != 0){
            let toBePushedData = offlineData.filter(event => event.hasOwnProperty('pendingPushToDynamics') && event.pendingPushToDynamics == true);
            if(toBePushedData && toBePushedData.length != 0){
                if(isPartialUpload){
                    toBePushedData = toBePushedData.splice(0,maxRecordCountForPartialUpload);
                }
                let events:Array<EventActivity> = toBePushedData.map(ev => {
                    if(ev){
                        return new EventActivity(ev);
                    }
                });
                return this.updateEventsData({onDynamics:true,onLocalDatabase:true,onLocalCopy:false,operationDetail:{code:'OFFLINEUPLOAD',message:'Offline events upload'}},events).catch(err=>{
                    console.log("Error occored while uploading events tool data\nData:"+offlineData+"\nError:"+err);
                });
            }
        }
    }

    async createUpdateDeleteEventNote(payload: any[]): Promise<any[]> {
      let response: any[];
      const headers = Endpoints.headers.content_type.json;
      const url:string = this.authService.userConfig.activeInstance.entryPointUrl
        + Endpoints.contacts.UPLOAD_CONTACT_NOTES;
      response = await this.http.post<any[]>(url, payload, headers).toPromise();
      return response;
    }

    public async purgeData(maxEndDateUnixTimestamp):Promise<any> {
        let offlineData;
        let offlineDataDoc;
        let afterPurgeData;
        let compareToDate = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp,true);
        const eraseAllData:boolean = !this.authService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL);
        await this.disk.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, true).then((doc) => {
            offlineDataDoc = doc;
            if (doc && doc.raw) {
                offlineData = doc.raw;
            }
            else {
                offlineDataDoc = {
                    raw: [],
                    lastUpdatedTime: null,
                }
                offlineData = [];
            }
        }).catch(err=>{
            offlineDataDoc = {
                raw: [],
                lastUpdatedTime: null,
            }
            offlineData = [];
        });
        if(offlineData && offlineData.length != 0 && !eraseAllData){
            afterPurgeData = offlineData.filter(raw=> !(raw['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue'] && new Date(raw['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue']).getTime().toString() < compareToDate.getTime().toString()));
        }
        if (afterPurgeData && Array.isArray(afterPurgeData)) {
            if(eraseAllData) {
                offlineDataDoc.raw = [];
                offlineDataDoc['_deleted'] = true;
            }else{
                offlineDataDoc['_deleted'] = false;
                offlineDataDoc.raw = afterPurgeData;
            }
            try {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, (doc) => {
                    doc = offlineDataDoc;
                    return doc;
                })
            } catch (error) {
                console.log('ERROR_WHILE_SAVING_EVENTS_OFFLINE_DATA'+error);
            }
        }
    }

    public getConfigFieldDTO(event: EventActivity, fieldNames: string[]): {} {
      const dto = {};

      if (Array.isArray(fieldNames)) {
        for (const fieldName of fieldNames) {
          if (event?.configuredFields?.hasOwnProperty(fieldName)) {
            dto[fieldName] = event.configuredFields[fieldName];
          }
        }
      }

      return dto;
    }

    getAccountsDTO(event: EventActivity): {
      addAccounts: any[],
      removeAccounts: any[],
    } {
      let dto;
      if(event.accounts && event.accounts.length != 0){
        dto = {};
        let addaccountspayload = [];
        let removeaccountspayload = [];
        event.accounts.forEach(p=>{
            if(p.id){
                if(p.isDeleted){
                    removeaccountspayload.push({
                        "accountid": p.id,
                    });
                }else{
                    let payload = {
                        "accountid": p.id,
                    };
                    addaccountspayload.push(payload);
                }
            }
        });
        dto['addAccounts'] = addaccountspayload;
        dto['removeAccounts'] = removeaccountspayload;
      }
      return dto;
    }
    getContactsDTO(event: EventActivity): {
      addParticipants: any[],
      removeParticipants: any[],
    } {
      let dto;
      if(event.participants && event.participants.length != 0) {
        dto = {};
        let addparticipantspayload = [];
        let removeparticipantspayload = [];
        event.participants.forEach(p => {
            if(p.id){
                if(p.isDeleted){
                    if(p.registrationId){
                        const payload = {
                          'msevtmgt_eventregistrationid': p.registrationId,
                        };
                        if (p.shouldClearResponse) {
                          payload['shouldClearResponse'] = p.shouldClearResponse;
                        }
                        removeparticipantspayload.push(payload);
                    }
                }else{
                    let payload = {};
                    if(p.registrationId){
                        payload['msevtmgt_eventregistrationid'] = p.registrationId;
                    }else{
                        payload['contactid'] = p.id;
                    }
                    if(p.checkinId){
                        payload['msevtmgt_checkinid'] = p.checkinId;
                    }
                    if (p.msevtmgt_checkintime) {
                      payload['msevtmgt_checkintime'] = format(p.msevtmgt_checkintime);
                      payload['indskr_signature'] = p.signature;
                      payload['indskr_modeofcheckin'] = p.indskr_modeofcheckin;                  
                    }
                    if (p.isAddedBack) {
                      payload['isAddedBack'] = p.isAddedBack;
                    }
                    if (p.indskr_reasons) {
                      payload['indskr_reasons'] = p.indskr_reasons;
                    }

                    // if(operationCode === 'OFFLINEUPLOAD' && (p.msevtmgt_checkintime && !p.checkinId)){
                    //   payload['indskr_reasons'] = 548910002;
                    // }

                    payload['indskr_source'] = 548910001;


                    if(p.isContactCheckIn){
                      payload['isContactCheckIn']=true;

                    }
                    if (!p.registrationId || (p.msevtmgt_checkintime && !p.checkinId) || p.isAddedBack) {
                      
                        addparticipantspayload.push(payload);
                    }
                }
            }
        });
        dto['addParticipants'] = addparticipantspayload;
        dto['removeParticipants'] = removeparticipantspayload;
      }
      return dto;
    }
    getCovisitorsDTO(event: EventActivity): {
      addCovisitors: any[],
      removeCovisitors: any[],
    } {
      let dto;
      if (event.covisitors && event.covisitors.length != 0) {
        dto = {};
        let addcovisitorspayload = [];
        let removecovisitorspayload = [];
        event.covisitors.forEach(p => {
            if(p.id){
                if(p.isDeleted){
                    if(p.teamMemberId && p.teamMemberStatecode == 0){
                        removecovisitorspayload.push({
                            "msevtmgt_eventteammemberid": p.teamMemberId,
                        });
                    }
                }else{
                    let payload = {};
                    if(p.teamMemberId){
                        payload['msevtmgt_eventteammemberid'] = p.teamMemberId;
                        payload['userid'] = p.id;
                    }else{
                        payload['userid'] = p.id;
                    }
                    addcovisitorspayload.push(payload);
                }
            }
        });
        dto['addCovisitors'] = addcovisitorspayload;
        dto['removeCovisitors'] = removecovisitorspayload;
      }
      return dto;
    }
    getSpeakersDTO(event: EventActivity): {
      addSpeakers: any[],
      removeSpeakers: any[],
    } {
      let dto;
      if (event.speakers && event.speakers.length != 0) {
        dto = {};
        let addspeakersspayload = [];
        let removespeakerspayload = [];
        event.speakers.forEach(s=>{
            if(s.id){
                if(s.isDeleted){
                    if(s.engagementId){
                        removespeakerspayload.push({
                            "msevtmgt_speakerengagementid": s.engagementId,
                        });
                    }
                }else{
                    let payload = {};
                    if(s.engagementId){
                        payload['msevtmgt_speakerengagementid'] = s.engagementId;
                        payload['msevtmgt_speakerid'] = s.id;
                    }else{
                        payload['msevtmgt_speakerid'] = s.id;
                    }
                    if(!s.engagementId){
                        addspeakersspayload.push(payload);
                    }
                }
            }
        });
        dto['addSpeakers'] = addspeakersspayload;
        dto['removeSpeakers'] = removespeakerspayload;
      }
      return dto;
    }
    getProductsDTO(event: EventActivity): {
      addProducts: any[],
      removeProducts: any[],
    } {
        let dto;
        if (event.products && event.products.length != 0) {
            dto = {};
            let addproductspayload = [];
            let removeproductspayload = [];
            event.products.forEach(p=>{
                if(p.id){
                    if(p.isDeleted){
                        removeproductspayload.push({
                            "productid": p.id,
                        });
                    }else{
                        let payload = {
                            "productid": p.id,
                        };
                        addproductspayload.push(payload);
                    }
                }
            });
            dto['addProducts'] = addproductspayload;
            dto['removeProducts'] = removeproductspayload;
        }
        return dto;
    }
    getPresentationsDTO(event: EventActivity): {
      addPresentations: any[],
      removePresentations: any[],
    } {
      let dto;
      if (event.presentations && event.presentations.length != 0) {
        dto = {};
        let addpresentationspayload = [];
        let removepresentationspayload = [];
        event.presentations.forEach(s=>{
            if(s.id){
                if(s.isDeleted){
                    removepresentationspayload.push({
                        "indskr_iopresentationid": s.id,
                    });
                } else{
                    let payload = {
                        "indskr_iopresentationid": s.id,
                    };
                    addpresentationspayload.push(payload);
                }
            }
        });
        dto['addPresentations'] = addpresentationspayload;
        dto['removePresentations'] = removepresentationspayload;
      }
      return dto;
    }

    public getServiceDTOForEvent(givenEvent:EventActivity, operationCode?:string){
        let serviceDTO = {
            "msevtmgt_name": givenEvent.name,
            //"msevtmgt_eventstartdate": givenEvent.startDate.toISOString(),
            "msevtmgt_eventstartdate": format(givenEvent.startDate,"YYYY-MM-DDTHH:mm:SS"),
            //"msevtmgt_eventenddate": givenEvent.endDate.toISOString(),
            "msevtmgt_eventenddate": format(givenEvent.endDate,"YYYY-MM-DDTHH:mm:SS"),
            "msevtmgt_description": givenEvent.notes,
            "indskr_positionid": givenEvent.positionId,
            "indskr_externalid": givenEvent.offlineId,
            "indskr_partners" : givenEvent.partners,
            "msevtmgt_maximumeventcapacity": givenEvent.msevtmgt_maximumeventcapacity !=null? givenEvent.msevtmgt_maximumeventcapacity : null,
        };
        if(!givenEvent.ID){
            serviceDTO['msevtmgt_eventtimezone'] = this.authService.user.timezonecode;
        }
        if(givenEvent.ID && !givenEvent.ID.includes('offline')){
            serviceDTO['msevtmgt_eventid'] = givenEvent.ID;
            if(!(givenEvent.statecode == 0 && givenEvent.statuscode == EventStatus.DRAFT)){
                serviceDTO['statecode'] = givenEvent.statecode;
                serviceDTO['statuscode'] = givenEvent.statuscode;
            }
        }else{
            serviceDTO['statecode'] = givenEvent.statecode;
            serviceDTO['statuscode'] = givenEvent.statuscode;
        }
        if (givenEvent.eventGoal) {
          serviceDTO['indskr_eventgoal'] = givenEvent.eventGoal;
        }
        if(givenEvent.eventType){
            serviceDTO['indskr_event_type'] = givenEvent.eventType;
            serviceDTO['indskr_eventsubtype'] = givenEvent.eventSubType;
        }
        if(givenEvent.locationFormatted){
            serviceDTO["indskr_location"] = givenEvent.locationFormatted;
        }
        const productsDTO = this.getProductsDTO(givenEvent);
        if (productsDTO) {
          serviceDTO = { ...serviceDTO, ...productsDTO };
        }

        const accountsDTO = this.getAccountsDTO(givenEvent);
        if (accountsDTO) {
          serviceDTO = { ...serviceDTO, ...accountsDTO };
        }
      let contactsDTO;
        contactsDTO = this.getContactsDTO(givenEvent);
        if (contactsDTO) {
          serviceDTO = { ...serviceDTO, ...contactsDTO };
        }   
        if(contactsDTO){
          // if(contactsDTO.addParticipants.isContactCheckin)
          let eventContactCheckInlist=[]
          contactsDTO.addParticipants.forEach(data=>{
            if(data.isContactCheckIn){
              eventContactCheckInlist.push(
                {
                  contactid: data.contactid,
                  indskr_reasons: data.indskr_reasons,
                  indskr_source: data.indskr_source,
                  msevtmgt_checkintime: new Date(),
                  indskr_signature: "",
                  indskr_modeofcheckin :EventModeOfCheckIns["QR Code"]
                }
              )
            }

          })
          
          let eventCheckInByContact = {
            eventCheckInByContact: eventContactCheckInlist
          }
          if(eventCheckInByContact){
            contactsDTO.addParticipants.forEach((x) => {
              let index = eventCheckInByContact.eventCheckInByContact.findIndex((y) => y.contactid === x.contactid )     
              if(index >= 0){
                 contactsDTO.addParticipants.splice(index,1)
              } 
              })
              serviceDTO = {...serviceDTO , ...eventCheckInByContact} 
           }
        }
     
        const speakersDTO = this.getSpeakersDTO(givenEvent);
        if (speakersDTO) {
          serviceDTO = { ...serviceDTO, ...speakersDTO };
        }

        const covisitorsDTO = this.getCovisitorsDTO(givenEvent);
        if (covisitorsDTO) {
          serviceDTO = { ...serviceDTO, ...covisitorsDTO };
        }

        const presentationsDTO = this.getPresentationsDTO(givenEvent);
        if (presentationsDTO) {
          serviceDTO = { ...serviceDTO, ...presentationsDTO };
        }

        if (givenEvent.attendeeFormat !== undefined) {
          serviceDTO['indskr_attendeeformat'] = givenEvent.attendeeFormat;
        }
        if (givenEvent.cutOffDate !== undefined) {
          serviceDTO['indskr_cutoffdate'] = givenEvent.cutOffDate;
        }
        if (givenEvent.selectionCriteria !== undefined) {
          serviceDTO['indskr_selectioncriteria'] = givenEvent.selectionCriteria;
        }
        // Config fields
        if (
          Array.isArray(this.authService.user.eventConfiguredFields)
          && this.authService.user.eventConfiguredFields.length > 0
        ) {
          for (let i = 0; i < this.authService.user.eventConfiguredFields.length; i++) {
            const configuredField = this.authService.user.eventConfiguredFields[i];
            if (givenEvent?.configuredFields?.hasOwnProperty(configuredField.fieldName)) {
              serviceDTO[configuredField.fieldName] = givenEvent.configuredFields[configuredField.fieldName];
            }
          }
        }
        if(givenEvent.lookupConfiguredFields && givenEvent.lookupConfiguredFields.length > 0){
          serviceDTO['lookupfields'] = givenEvent.lookupConfiguredFields.map(field=> {
            return  {
              name: field.name,
              entity: field.entity,
              indskr_referencingentitynavigationpropertyname: field.indskr_referencingentitynavigationpropertyname,   
              id: field.id
            };
          })
        }
        if (givenEvent.offlineApprovalSubmit) {
          serviceDTO['offlineApprovalSubmit'] = givenEvent.offlineApprovalSubmit;
        }
        if (!givenEvent['msevtmgt_eventid'] &&  givenEvent.budgetAllocated) {
          // Total budget field has it's own dynamics validation logic
          // including this value sometimes causes other field update failure
          // To avoid it, we include this attribute only for the offline created record
          // and don't include to the payload for other field updates.
          // Actual total budget field update is done through 'updateEvent' method.
          serviceDTO['msevtmgt_budgetallocated'] = givenEvent.budgetAllocated;
        }
        if (givenEvent.currencyId) {
          serviceDTO['transactioncurrencyid'] = givenEvent.currencyId;
        }
        if (givenEvent.building?.msevtmgt_buildingid) {
          serviceDTO['msevtmgt_buildingid'] = givenEvent.building.msevtmgt_buildingid;
        }

        return serviceDTO;
    }

    getEventNoteDTO(note: IONote) {
      const dto: Record<string, any> = {
        annotationid: note.noteId,
        ownerId: note.ownerId,
        ownerName: note.ownerName,
        isdocument: note.hasDocument,
      };
      if (note.createdTime instanceof Date) {
        dto.createdon = note.createdTime.getTime().toString();
      }
      if (note.documentName) {
        dto.filename = note.documentName;
      }
      if (note.documentMimeType) {
        dto.mimetype = note.documentMimeType;
      }
      if (note.noteText) {
        dto.notetext = note.noteText;
      }

      return dto;
    }

    public getOfflineDTOForEvent(givenEvent:EventActivity){
        let productsDTO = [];
        let covisitorsDTO = [];
        let speakersDTO = [];
        let accountsDTO = [];
        let participantsDTO = [];
        let checkInsDTO = [];
        let presentationsDTO = [];
        let annotations = [];
        let lookupFieldsDTO = [];
        if(givenEvent.products.length != 0){
            productsDTO = givenEvent.products.map(p=>{
                return {
                    "product_id": p.id,
                    "product_name": p.name,
                    "product_statecode": p.statecode,
                    "isDeleted": p.isDeleted,
                };
            })
        }
        if(givenEvent.covisitors.length != 0){
            covisitorsDTO = givenEvent.covisitors.map(p=>{
                return {
                    "user": p.id,
                    "user@OData.Community.Display.V1.FormattedValue": p.name,
                    "eventteammemberid": p.teamMemberId,
                    "isDeleted": p.isDeleted,
                    "teammember_statecode": p.teamMemberStatecode,
                };
            })
        }
        if(givenEvent.speakers.length != 0){
            speakersDTO = givenEvent.speakers.map(s=>{
                return {
                    "speaker": s.id,
                    "speakerengagementid": s.engagementId,
                    "speaker@OData.Community.Display.V1.FormattedValue": s.name,
                    "speaker_statecode": s.statecode,
                    "isDeleted": s.isDeleted
                };
            })
        }
        if(givenEvent.participants.length != 0){
            givenEvent.participants.forEach(p=>{
                const pDTO: Record<string, any> = {
                  "contactid": p.id,
                  "contactid@OData.Community.Display.V1.FormattedValue": p.name,
                  "contact_statecode": p.statecode,
                  "eventregistrationid": p.registrationId,
                  "owner_id": p.ownerId,
                  "isDeleted": p.isDeleted,
                  "contact_externalid": p.customerId,
                  "contact_title@OData.Community.Display.V1.FormattedValue": p.title,
                  "contact_firstname": p.firstName,
                  "contact_middlename": p.middleName,
                  "contact_lastname": p.lastName,
                  "contact_primaryaccount@OData.Community.Display.V1.FormattedValue": p.primaryAccount,
                  "contact_specialtyid@OData.Community.Display.V1.FormattedValue": p.primarySpecialty,
                  'msevtmgt_checkintime': p.msevtmgt_checkintime,
                  'indskr_reasons': p.indskr_reasons,
                  'indskr_reasons@OData.Community.Display.V1.FormattedValue': p.indskr_reasons_formatted,
                  'eventPasses': p.eventPasses || [],
                  'checkinId': p.checkinId? p.checkinId:null,
                  'indskr_modeofcheckin' :p.indskr_modeofcheckin,
                  'eventregistration_name': p.ERNumber,

                };
                if (Array.isArray(p.notes)) {
                  pDTO['annotations'] = p.notes.map(n => this.getEventNoteDTO(n));
                }
                if (p.isAddedBack) {
                  pDTO['isAddedBack'] = p.isAddedBack;
                }
                if (p.shouldClearResponse) {
                  pDTO['shouldClearResponse'] = p.shouldClearResponse;
                }
                if (p['isContactCheckIn'] === true) {
                  pDTO.isContactCheckIn = true;
                }
                participantsDTO.push(pDTO);
                if(p.signature){
                    checkInsDTO.push({
                        "signature": p.signature,
                        "contactid": p.id,
                        "checkinid": p.checkinId,
                    });
                }
            })
        }
        if (givenEvent.annotations.length != 0) {
          annotations = givenEvent.annotations.map(n => {
            return {
              annotationid: n.noteId,
              ownerId: n.ownerId,
              ownerName: n.ownerName,
              isdocument: n.hasDocument,
              createdon: n.createdTime instanceof Date ? n.createdTime.getTime().toString() : n.createdTime,
              filename: n.documentName ? n.documentName : null,
              mimetype: n.documentMimeType ? n.documentMimeType : null,
              notetext: n.noteText ? n.noteText : null
            }
          });
        }
        if(givenEvent.accounts.length != 0){
            accountsDTO = givenEvent.accounts.map(p=>{
                return {
                    "account_id": p.id,
                    "account_name": p.name,
                    "account_statecode": p.statecode,
                    "isDeleted": p.isDeleted,
                };
            })
        }
        if(givenEvent.presentations.length != 0){
            presentationsDTO = givenEvent.presentations.map(p=>{
                return {
                    "presentationid": p.id,
                    "presentation_title": p.name,
                    "presentation_statecode": p.statecode,
                    "isDeleted": p.isDeleted,
                    "ckmthumbnailurl": p.thumbnailUrl,
                    "downloaded": p.downloaded
                };
            })
        }
        // Config fields
        const configuredFields = {};
        if (
          Array.isArray(this.authService.user.eventConfiguredFields)
          && this.authService.user.eventConfiguredFields.length > 0
        ) {
          for (let i = 0; i < this.authService.user.eventConfiguredFields.length; i++) {
            const configuredField = this.authService.user.eventConfiguredFields[i];
            if (givenEvent?.configuredFields?.hasOwnProperty(configuredField.fieldName)) {
              configuredFields[configuredField.fieldName] = givenEvent.configuredFields[configuredField.fieldName];
            }
          }
        }

        if(givenEvent.lookupConfiguredFields && givenEvent.lookupConfiguredFields.length > 0){
          lookupFieldsDTO = givenEvent.lookupConfiguredFields;
        }

        const dto: Record<string, any> = {
            "indskr_externalid": givenEvent.offlineId,
            "statecode": givenEvent.statecode,
            '_indskr_eventgoalid_value@OData.Community.Display.V1.FormattedValue': givenEvent.eventGoalFormatted || '',
            '_indskr_eventgoalid_value': givenEvent.eventGoal || '',
            'eventgoal_statecode': givenEvent.eventGoalStateCode,
            "_indskr_event_type_value@OData.Community.Display.V1.FormattedValue": givenEvent.eventTypeFormatted || '',
            "_indskr_event_type_value": givenEvent.eventType || '',
            "eventtype_statecode": givenEvent.eventTypeStateCode,
            "msevtmgt_name": givenEvent.name || 'Event',
            "msevtmgt_eventid": givenEvent.ID || '',
            "indskr_location": givenEvent.locationFormatted,
            "msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue": givenEvent.startDate ? givenEvent.startDate.toISOString() : null,
            "_indskr_eventsubtype_value@OData.Community.Display.V1.FormattedValue": givenEvent.eventSubTypeFormatted || '',
            "_indskr_eventsubtype_value": givenEvent.eventSubType,
            "eventsubtype_statecode": givenEvent.eventSubTypeStateCode,
            "statuscode@OData.Community.Display.V1.FormattedValue": givenEvent.formattedStatus,
            "statuscode": givenEvent.statuscode,
            "msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue": givenEvent.endDate ? givenEvent.endDate.toISOString() : null,
            // "modifiedon": givenEvent.modifiedOn ? givenEvent.modifiedOn.getTime().toString() : "0",
            "pendingPushToDynamics": givenEvent.pendingPushToDynamics,
            "_ownerid_value": givenEvent.ownerId,
            "_ownerid_value@OData.Community.Display.V1.FormattedValue": givenEvent.meetingOwnerName ? givenEvent.meetingOwnerName : '',
            "_indskr_positionid_value": givenEvent.positionId,
            "msevtmgt_description": givenEvent.notes,
            "indskr_partners": givenEvent.partners,
            "products": productsDTO,
            "covisitors": covisitorsDTO,
            "speakers": speakersDTO,
            "accounts": accountsDTO,
            "participants": participantsDTO,
            "checkIns" : checkInsDTO,
            "presentations" : presentationsDTO,
            "lookupfields" : lookupFieldsDTO,
            "msevtmgt_maximumeventcapacity": givenEvent.msevtmgt_maximumeventcapacity !=null ? givenEvent.msevtmgt_maximumeventcapacity : null,
            "passes": givenEvent.passes || [],
            configuredFields,
            "annotations": annotations
        };

        if (givenEvent.attendeeFormat !== undefined) {
          dto['indskr_attendeeformat'] = givenEvent.attendeeFormat;
        }
        if (givenEvent.cutOffDate) {
          dto['indskr_cutoffdate'] = givenEvent.cutOffDate;
        }
        if (givenEvent.selectionCriteria !== undefined) {
          dto['indskr_selectioncriteria'] = givenEvent.selectionCriteria;
        }
        if (givenEvent.offlineApprovalSubmit) {
          dto['offlineApprovalSubmit'] = givenEvent.offlineApprovalSubmit;
        }
        if (givenEvent.budgetAllocated) {
          dto['msevtmgt_budgetallocated'] = givenEvent.budgetAllocated;
        }
        if (givenEvent.currencyId) {
          dto['_transactioncurrencyid_value'] = givenEvent.currencyId;
          if (givenEvent.currencyName) {
            dto['_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue'] = givenEvent.currencyName;
          }
        }
        if (givenEvent.building?.msevtmgt_buildingid) {
          dto['_msevtmgt_building_value'] = givenEvent.building.msevtmgt_buildingid;
          if (givenEvent.building?.msevtmgt_name) {
            dto['_msevtmgt_building_value@OData.Community.Display.V1.FormattedValue'] = givenEvent.building.msevtmgt_name;
          }
          if (givenEvent.building?.cityId) {
            dto['msevtmgt_building_city'] = givenEvent.building.cityId;
          }
          if (givenEvent.building?.cityName) {
            dto['msevtmgt_building_city@OData.Community.Display.V1.FormattedValue'] = givenEvent.building.cityName;
          }
        }

        return dto;
    }

    private getConfigFieldsString(queryStringSymbol: '&' | '?'): string {
      let configFieldsString = '';

      if (!queryStringSymbol) {
        console.error('events-tool.data.service: getConfigFieldsString: query string symbol is missing.');
      }
      else if (
        Array.isArray(this.authService.user.eventConfiguredFields)
        && this.authService.user.eventConfiguredFields.length > 0
      ) {
        const joinedStr = this.authService.user.eventConfiguredFields
                  .map(configuredField => configuredField.fieldName)
                  .join();

        configFieldsString = `${queryStringSymbol}configFields=${joinedStr}`;
      }

      return configFieldsString;
    }

  public async saveEventAttendeePasses(eventPassespayload: any[], event: EventActivity) {
    eventPassespayload = _.orderBy(eventPassespayload, obj => obj.deleted);
    const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.eventsTool.SAVE_EVENT_ATTENDEE_PASSES.replace('{eventId}', event.ID);
    let response;
    try {
      response = await this.http.put(url, eventPassespayload).toPromise();
      event.passes = response; // save eventPassess to avoid conflicts with dynamics count
    } catch (error) {
      eventPassespayload.forEach(payload => payload['deleted'] = true); // to remove participant passes in case of error
      if (error.hasOwnProperty('errorDetails')) {
        event.passes = error['errorDetails'];
      }
    }

   this.eventsToolService.updateEventPassesLeft(eventPassespayload, event);
    const action: OperationDetail = {
      onDynamics: false,
      onLocalDatabase: true,
      onLocalCopy: false,
      operationDetail: {
        code: '',
        message: '',
      }
    };
    // event.modifiedOn = new Date();
    event.pendingPushToDynamics = false;
    event['eventPassesToSave'] = [] // clear once saved
    await this.updateEventsData(action, [event]);
  }

  async sendForApproval(event: EventActivity): Promise<boolean> {
    if (!event) {
      console.error('sendForApproval: invalid event: ', event);
      return;
    }
    const isOfflineCreatedEvent = event.ID?.includes('offline');

    if (
      !this.deviceService.isOffline
      && !isOfflineCreatedEvent
      && !event.pendingPushToDynamics
    ) {
      // Online submit
      const url: string = this.authService.userConfig.activeInstance.entryPointUrl
        + Endpoints.eventsTool.SEND_FOR_APPROVAL;

      try {
        const body = { msevtmgt_eventid: event.ID };
        await this.http.post(url, body).toPromise();

        event.statecode = EventStateCode.Inactive;
        event.statuscode = EventStatus.FOR_REVIEW;
        event.approvalStatus = EventApprovalStatus["For Review"];
        event.formattedStatus = "For Review";
      } catch (error: any) {
        console.error('eventsTool: sendForApproval: ', error);
        throw error;
      }
    } else {
      // Offline submit
      event.offlineApprovalSubmit = true;
      event.pendingPushToDynamics = true;
    }

    const action: OperationDetail = {
      onDynamics: false,
      onLocalDatabase: true,
      onLocalCopy: false,
      operationDetail: {
        code: '',
        message: '',
      }
    };
    await this.updateEventsData(action, [event]);

    return true;
  }

  async sendForApprovalParticipant(event: EventActivity, eventRegId: string): Promise<boolean> {
    if (!event) {
      console.error('sendForApprovalParticipant: invalid event registration: ', event);
      return;
    }
    const isOfflineCreatedEvent = event.ID?.includes('offline');

    if (
      !this.deviceService.isOffline
      && !isOfflineCreatedEvent
      && !event.pendingPushToDynamics
    ) {
      // Online submit
      const url: string = this.authService.userConfig.activeInstance.entryPointUrl
        + Endpoints.eventsTool.SEND_FOR_APPROVAL_PARTICIPANT;

      try {
        const body = { msevtmgt_eventregistrationid: eventRegId };
        await this.http.post(url, body).toPromise();
      } catch (error: any) {
        console.error('eventsPartiipantTool: sendForApprovalParticipant: ', error);
        throw error;
      }
    } else {
      // Offline submit
      event.pendingPushToDynamics = true;
    }
    // const action: OperationDetail = {
    //   onDynamics: false,
    //   onLocalDatabase: true,
    //   onLocalCopy: false,
    //   operationDetail: {
    //     code: '',
    //     message: '',
    //   }
    // };
    // await this.updateEventsData(action, [event]);

    return true;
  }

  async _handleContactComponentCallback(data, isCovisitorView, currentEvent, addedContactByScanQR, isFrom?) {
    let updateAccountsForEventFlag = false;
    if (!isCovisitorView) {
      // To Add/Remove affiliated accounts for event
      let accountsToUpdate: Account[] = [];
      const alreadySelectedContacts: Contact[] = [];
      currentEvent.participants.forEach(ac => {
        const foundCon = this.contactService.getContactByID(ac.id);
        if (foundCon) {
          alreadySelectedContacts.push(foundCon);
        }
      });
      //Check for Remove
      if (
        currentEvent.accounts.filter(a => !a.isDeleted).length === 1
        && currentEvent.participants.filter(a => !(
          a.isDeleted
          || a.indskr_reasons === EventRegistrationStatus.Cancelled
          || a.indskr_reasons === EventRegistrationStatus.Declined
          || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
        )).length === 1
      ) {
        const affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(alreadySelectedContacts);
        if (affiliatedAccounts.length === 1 && affiliatedAccounts[0].id === currentEvent.accounts.filter(a => !a.isDeleted)[0].id) {
          // Remove the only added affiliated account
          updateAccountsForEventFlag = true;
          accountsToUpdate = [];
        }
      }
      //Check for add
      if (
        currentEvent.participants
          .filter(a => !(
            a.isDeleted
            || a.indskr_reasons === EventRegistrationStatus.Cancelled
            || a.indskr_reasons === EventRegistrationStatus.Declined
            || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
          )).length === 0
        && data.selectedItems
        && data.selectedItems.length === 1
        && currentEvent.accounts.filter(a => !a.isDeleted).length === 0
      ) {
        const nowSelectedContacts: Contact[] = [];
        data.selectedItems.forEach(ac => {
          const foundCon = this.contactService.getContactByID(ac.id);
          if (foundCon) {
            nowSelectedContacts.push(foundCon);
          }
        });
        const affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(nowSelectedContacts);
        if (affiliatedAccounts.length === 1) {
          // Add the only affiliated contact
          updateAccountsForEventFlag = true;
          accountsToUpdate = affiliatedAccounts;
        }
      }
      if (updateAccountsForEventFlag) {
        currentEvent.accounts.forEach(a => {
          a.isDeleted = true;
          return a;
        });
        accountsToUpdate.forEach(item => {
          const idx = currentEvent.accounts.findIndex(a => a.id === item.id);
          if (idx >= 0) {
            currentEvent.accounts[idx].isDeleted = false;
            currentEvent.accounts[idx].name = item.accountName;
          } else {
            currentEvent.accounts.push({
              id: item.id,
              name: item.accountName,
              statecode: item.state || 0,
              isDeleted: false,
            });
          }
        });
      }
    }
    const newParticipantList = [];
    const addedParticipantIds = [];
    const removedParticipantIds = [];
    for (let i = 0; i < currentEvent.participants.length; i++) {
      const participant = currentEvent.participants[i];
      if (data.selectedItems.length > 0) {
        if (addedContactByScanQR == true) {
          participant.isDeleted = false
          const pendingDetails = this.eventsToolService.getPendingParticipantDetails(participant.id);
          let indskr_reasons = pendingDetails?.indskr_reasons || (currentEvent.attendeeFormat === AttendeeFormat.propose ? EventRegistrationStatus.Draft : EventRegistrationStatus.Proposed);
          participant.indskr_reasons = indskr_reasons;
          // participant.msevtmgt_checkintime = new Date();
          // participant.isContactCheckIn = true
          addedParticipantIds.push(participant.id);
        } else {
          const selectedContact = data.selectedItems.find(c => c.id === participant.id);
          if (selectedContact) {
            participant.isDeleted = false;
            if (
              participant.indskr_reasons === EventRegistrationStatus.Cancelled
              || participant.indskr_reasons === EventRegistrationStatus.Declined
            ) {
              participant.isAddedBack = true;
              addedParticipantIds.push(participant.id);
            }
            const pendingDetails = this.eventsToolService.getPendingParticipantDetails(participant.id);
            let indskr_reasons = pendingDetails?.indskr_reasons || (currentEvent.attendeeFormat === AttendeeFormat.propose ? EventRegistrationStatus.Draft : EventRegistrationStatus.Proposed);
            participant.indskr_reasons = indskr_reasons;
          } else {
            if (
              participant.indskr_reasons !== EventRegistrationStatus.Cancelled
              && participant.indskr_reasons !== EventRegistrationStatus.Declined
              && participant.indskr_reasons !== EventRegistrationStatus['Cancelled late']
              && isFrom != PageName.EventParticipantComponent
            ) {
              participant.isDeleted = true;
              participant.shouldClearResponse = participant.indskr_reasons !== EventRegistrationStatus.Proposed;
              participant.indskr_reasons = EventRegistrationStatus.Cancelled;
              participant.indskr_reasons_formatted = "Canceled";
              participant.eventPasses = [];
              removedParticipantIds.push(participant.id);
            }
          }
        }
        newParticipantList.push(participant);
      } else {
        if (
          participant.indskr_reasons !== EventRegistrationStatus.Cancelled
          && participant.indskr_reasons !== EventRegistrationStatus.Declined
          && participant.indskr_reasons !== EventRegistrationStatus['Cancelled late']
        ) {
          participant.isDeleted = true;
          participant.shouldClearResponse = participant.indskr_reasons !== EventRegistrationStatus.Proposed;
          participant.indskr_reasons = EventRegistrationStatus.Cancelled;
          participant.indskr_reasons_formatted = "Canceled";
          participant.eventPasses = [];
          removedParticipantIds.push(participant.id);
        }
        newParticipantList.push(participant);
      }
    }
    for (let i = 0; i < data.selectedItems.length; i++) {
      const newParticipant = data.selectedItems[i];
      const pendingDetails = this.eventsToolService.getPendingParticipantDetails(newParticipant.id);
      let indskr_reasons = pendingDetails?.indskr_reasons || (currentEvent.attendeeFormat === AttendeeFormat.propose ? EventRegistrationStatus.Draft : EventRegistrationStatus.Proposed);
      if (currentEvent.participants.length > 0) {
        const participant = currentEvent.participants.find(c => c.id === newParticipant.id);
        if (!participant) {
          let newContact: any = {
            id: newParticipant.id,
            name: newParticipant.name,
            statecode: newParticipant.statecode,
            signature: '',
            checkinId: '',
            ownerId: newParticipant.ownerId,
            isDeleted: false,
            isAddedBack: false,
            registrationId: null,
            customerId: newParticipant.customerId,
            title: newParticipant.title,
            firstName: newParticipant.firstName,
            middleName: newParticipant.middleName,
            lastName: newParticipant.lastName,
            primaryAccount: newParticipant.primaryAccount,
            primarySpecialty: newParticipant.primarySpecialty,
            msevtmgt_checkintime: addedContactByScanQR ? new Date() : null,
            indskr_reasons,
            indskr_reasons_formatted: this.eventsToolService.getFormattedRegistrationStatus(indskr_reasons),
            primaryEmail: newParticipant.primaryEmail,
            ERNumber: newParticipant.ERNumber,
            indskr_modeofcheckin :newParticipant.indskr_modeofcheckin,
          }
          if (addedContactByScanQR == true) {
            newContact = {
              ...newContact,
              isContactCheckIn: true,
              eventRegistrationName: newParticipant.eventRegistrationName,
              indskr_modeofcheckin: EventModeOfCheckIns['QR Code']
            }
          }
          newParticipantList.push(newContact);
          addedParticipantIds.push(newParticipant.id);
        }
      } else {
        newParticipantList.push({
          id: newParticipant.id,
          name: newParticipant.name,
          statecode: newParticipant.statecode,
          signature: '',
          checkinId: '',
          ownerId: newParticipant.ownerId,
          isDeleted: false,
          isAddedBack: false,
          registrationId: null,
          customerId: newParticipant.customerId,
          title: newParticipant.title,
          firstName: newParticipant.firstName,
          middleName: newParticipant.middleName,
          lastName: newParticipant.lastName,
          primaryAccount: newParticipant.primaryAccount,
          primarySpecialty: newParticipant.primarySpecialty,
          msevtmgt_checkintime: null,
          indskr_reasons,
          primaryEmail: newParticipant.primaryEmail,
          ERNumber: newParticipant.ERNumber,
          indskr_modeofcheckin :newParticipant.indskr_modeofcheckin
        });
        addedParticipantIds.push(newParticipant.id);
      }
    }
    currentEvent.participants = newParticipantList;
    let payload;
    const contactsDTO = this.getContactsDTO(currentEvent);
    if (contactsDTO) {
      payload = { ...contactsDTO };
    }
    if (updateAccountsForEventFlag) {
      const accountsDTO = this.getAccountsDTO(currentEvent);
      if (accountsDTO) {
        payload = { ...payload, ...accountsDTO };
      }
    }
    return [payload, addedParticipantIds, removedParticipantIds];
  }

  public async saveJustification(registrationId, justification: string, event: EventActivity) {
    await this.dynamics.update(registrationId, 'msevtmgt_eventregistrations', { 'indskr_justification': justification });
    this.updateEventsData(
      {
        onDynamics: false,
        onLocalDatabase: true,
        onLocalCopy: true,
      },
      [event],
    );
  }

  public async addIoConfigDefaultValuesToEventActivity(activity:EventActivity) {
    const supportedDataTypes= ['Picklist','Boolean']
      const defaultConfigFields = this.authService.user.eventConfiguredFields.filter(a=> a.indskr_optionsetdefaultvalue && supportedDataTypes.includes(a.fieldType));
      if(defaultConfigFields && defaultConfigFields.length > 0){
        activity.lookupConfiguredFields = [];
        activity.configuredFields = {};
        defaultConfigFields.forEach(a=> {
          if(a.datatype == 'Lookup' || a.fieldType == 'Lookup'){
            activity.lookupConfiguredFields.push({
              id: a.indskr_optionsetdefaultvalue,
              name: a.fieldName,
              entity: a.indskr_lookupentitysetname,
              stringValue: a.indskr_optionsetdefaultext,
              indskr_referencingentitynavigationpropertyname: a.indskr_referencingentitynavigationpropertyname
            });
          }else if(a.datatype == 'Boolean' || a.fieldType == 'Boolean'){
            activity.configuredFields[a.fieldName] = a.indskr_optionsetdefaultvalue == 'true' ? true : false;
          }else if(a.datatype == 'Picklist' || a.fieldType == 'Picklist' || a.datatype == 'Virtual' || a.fieldType == 'Virtual'){
            activity.configuredFields[a.fieldName] = a.indskr_optionsetdefaultvalue ? parseInt(a.indskr_optionsetdefaultvalue) : a.indskr_optionsetdefaultvalue;
          }else {
            activity.configuredFields[a.fieldName] = a.indskr_optionsetdefaultvalue;
          }
        });        
      }
  }

  private mapLookupConfigFieldsToResponse(res) {
    if(res && res.length > 0 && this.authService.user.eventConfiguredFields && this.authService.user.eventConfiguredFields.length > 0){
      const lookupConfiguredFields = this.authService.user.eventConfiguredFields.filter(a=> a.fieldType == 'Lookup' || a.datatype == 'Lookup');
      if(lookupConfiguredFields && lookupConfiguredFields.length){
        res.forEach(item => {
          let lookupfields:Array<{
            name: string,
            indskr_referencingentitynavigationpropertyname?: string,
            entity: string,  
            id: string,
            stringValue: string,
          }> = [];
          
          lookupConfiguredFields.forEach(field=> {
            const key = '_'+field.fieldName+'_value';
            if(item[key]){
              lookupfields.push({
                name: field.fieldName,
                indskr_referencingentitynavigationpropertyname: field.indskr_referencingentitynavigationpropertyname,
                entity: field.indskr_lookupentitysetname,  
                id: item[key],
                stringValue: item[key+'@OData.Community.Display.V1.FormattedValue'],
              })
            }
          })
          item['lookupfields'] = lookupfields;
        })
      }
    }
  }

}

export enum EventOperationsCode{
  'updateSubject',
  'updateStartDate',
  'updateStartTime',
  'updateEndDate',
  'updateEndTime'
}
