import { AddressService } from '@omni/services/address/address.service';

import {debounceTime} from 'rxjs/operators';
import { Component, ViewChild, ElementRef, NgZone, AfterViewInit, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { UntypedFormControl } from '@angular/forms';
// import { MapsAPILoader} from '@agm/core'; // to do the predictive analysis
import { DeviceService } from '../../services/device/device.service';
import { Platform, PopoverController } from '@ionic/angular';
import { UIService } from '../../services/ui/ui.service';
import { ActivityService } from '../../services/activity/activity.service';
import { LocationService } from '../../data-services/location/location.data.service';
import { LogService } from '../../services/logging/log-service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { BehaviorSubject } from 'rxjs';
import { ChangeEvent , VirtualListComponent } from '../angular-virtual-list';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { UpdateMeetingPayload, MeetingDataService } from '../../data-services/meeting/meeting.data.service';
import { DiskService, OFFLINE_DB_LINKED_ENTITY_NAME } from '../../services/disk/disk.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { ContactDataService } from '../../data-services/contact/contact.data.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { ContactAddress } from '../../classes/contact/contact.class';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { AccountAddress } from '@omni/classes/account/account.class';
import * as _ from 'lodash'
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';

import { AgendaFooterService } from '../../services/footer/agenda-footer.service';
import { EventsService } from '../../services/events/events.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
/**
 * Generated class for the LocationComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */


@Component({
  selector: 'location',
  templateUrl: 'location.html',
  styleUrls:['location.scss']
})

export class LocationComponent implements OnInit, AfterViewInit {
  public activityLocation:any;
  public mapReady:boolean = false;
  public firstLoad: boolean = true
  public latitude: number;
  public longitude: number;
  public zoom: number;
  public onDevice:boolean;
  public mapCanvasElement: HTMLElement;
  public tempLocationList:Array<any> = [];
  public locationSelected: any;
  public searchActivated:boolean = true;
  public selectedAddressFromList:any;
  public isSearchBoxDisabled = false;

  @ViewChild('search',{read:ElementRef, static: true}) searchInputElRef: ElementRef;
  public searchTerm:string ='';
  private tempEl:HTMLInputElement;
  public searchControl: UntypedFormControl;
  markers:any[]=[];
  webMarkers:any[]=[];
  public locationData = []; // will be coming from service locations
  public locationAddress:Array<string> = new Array<string>();
  tempLocationData = [];
  indices: ChangeEvent = { start: 0, end: 0 };
  public locationData$ = new BehaviorSubject<any[]>(undefined);  // make the data into an observable stream
  scrollItems: any[];
  filteredList: any[] = [];
  participantAdded:boolean = false;
  @ViewChild(VirtualListComponent,{static:true})
  private virtualList: VirtualListComponent;
  private isLayoverPushView:boolean = false;
  private layoverViewPushedFrom:string;
  public pageTitle: IndPageTitleViewDataModel;
  @Input() from:any

  public selectionFor:string = '';
  @Input() for: any;
  @Input() contactIds: any;
  @Input() accountIds: any;
  @Input() selectedAddress: any;
  @Input() callbackEvent: any;
  @Input() isAccountVisit: boolean;

  constructor(private platform: Platform,
    public deviceService: DeviceService,
    private ngZone: NgZone,
    // private mapsApiLoader: MapsAPILoader,
    private geoLocation:Geolocation ,
    private nativeGeocoder: NativeGeocoder,
    public uiService: UIService,
    public activityService: ActivityService,
    private locationService:LocationService,
    private logServices: LogService,
    public contactOfflineService:ContactOfflineService,
    public contactService: ContactDataService,
    public meetingDataService: MeetingDataService,
    public disk: DiskService,
    private navService: NavigationService,
    public trackingService: TrackService,
    private accountService: AccountOfflineService,
    private callplanService: CallPlanOfflineService,
    public translate: TranslateService,
    public _CD: ChangeDetectorRef,
    private agendaFooterService: AgendaFooterService,
    private events: EventsService,
    private authService: AuthenticationService,
    private addressService: AddressService,
    private readonly popoverCtrl: PopoverController
    ) {
    this.onDevice = this.deviceService.isNativeApp
    this.searchControl = new UntypedFormControl();
  }

  async ngOnInit(){
      if (this.from) {
        this.isLayoverPushView = true;
        if (this.from == 'AccountManagementTool') {
          this.layoverViewPushedFrom = 'AccountManagementTool';
        } else if (this.from == 'ScientificActivityPage') {
          this.layoverViewPushedFrom = 'ScientificActivityPage';
        } else if (this.from == 'AgendaView') {
          this.layoverViewPushedFrom = 'AgendaView';
        } else if (this.from == 'EventsToolPage') {
          this.layoverViewPushedFrom = 'EventsToolPage';
        } else if (this.from == 'OpportunityManagementTool') {
          this.layoverViewPushedFrom = 'OpportunityManagementTool';
        }
      }
      if(this.for){
        this.selectionFor = this.for;
      }else{
        this.activityLocation =  this.activityService.selectedActivity.location;
      }
    //if the participants are added to the meeting do not default to the contacts location
    if(this.selectionFor && this.selectionFor == 'GENERAL_LOCATION_SELECTION'){
      let contactIds = [];
      if(this.contactIds){
        contactIds = this.contactIds;
      }
      if(contactIds.length != 0){
        let rawData = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
        if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
          rawData.raw.forEach(item => {
            if(item.hasOwnProperty('contactid') && contactIds.some(contact=> contact == item['contactid'])){
              let foundAddress:ContactAddress = {
                city: item['indskr_address.indskr_city_lu@OData.Community.Display.V1.FormattedValue']||'',
                state: item['indskr_address.indskr_state_lu@OData.Community.Display.V1.FormattedValue']||'',
                postal: item['indskr_address.indskr_postalcode_lu@OData.Community.Display.V1.FormattedValue']||'',
                country: item['indskr_address.indskr_country_lu@OData.Community.Display.V1.FormattedValue']||'',
                countryCode: item['indskr_address.indskr_countrycode_lu@OData.Community.Display.V1.FormattedValue']||'',
                street: item['indskr_address.indskr_line1']||'',
                street2: item['indskr_address.indskr_line2']||'',
                street3: item['indskr_address.indskr_line3']||'',
                region: item['indskr_address.indskr_luregion@OData.Community.Display.V1.FormattedValue']||'',
                postOfficeBox: item['indskr_address.indskr_postofficebox@OData.Community.Display.V1.FormattedValue']||'',
                isPrimary: item['indskr_indskr_customeraddress_v2.indskr_isprimary']||'',
                addressId: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
                compositeAdd: item['indskr_address.indskr_composite']||'',
                primaryContact: item['indskr_indskr_customeraddress_v2.indskr_primarycontact@OData.Community.Display.V1.FormattedValue']||'',
                fax: item['indskr_indskr_customeraddress_v2.indskr_fax@OData.Community.Display.V1.FormattedValue']||'',
                fax2: item['indskr_indskr_customeraddress_v2.indskr_fax2@OData.Community.Display.V1.FormattedValue']||'',
                telephone1: item['indskr_indskr_customeraddress_v2.indskr_telephone1@OData.Community.Display.V1.FormattedValue']||'',
                telephone2: item['indskr_indskr_customeraddress_v2.indskr_telephone2@OData.Community.Display.V1.FormattedValue']||'',
                isSampleEligible: item['indskr_indskr_customeraddress_v2.indskr_samplingeligible'],
                customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                concatanatedAddress: item['indskr_indskr_customeraddress_v2.indskr_address@OData.Community.Display.V1.FormattedValue']||'',
                latitude: item['indskr_address.indskr_latitude']||'',
                longitude: item['indskr_address.indskr_longitude:']||'',
              };
              if(!_.isEmpty(foundAddress.addressId)){
              const idx = this.locationData.findIndex(a => a.addressId === foundAddress.addressId);
              if (idx < 0) {
                this.locationData.push(foundAddress);
              }
             }
            }
          })
        }
      }
      if(this.accountIds){
        let accountIds = this.accountIds;
        if(accountIds.lenght != 0){
          let rawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
          if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
            rawData.raw.forEach(item => {
              if(item.hasOwnProperty('accountid') && accountIds.some(account=> account == item['accountid'])){
                let foundAddress = {
                  compositeAdd: item['indskr_address.indskr_composite']||'',
                  addressId: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                };
                if(!_.isEmpty(foundAddress.addressId)){
                const idx = this.locationData.findIndex(a => a.addressId === foundAddress.addressId);
                if (idx < 0) {
                  this.locationData.push(foundAddress);
                }
               }
              }
            })
          }
          // accountIds.forEach(value => {
          //   const account = this.accountService.getAccountById(value);
          //   if (account && account.accountAddressList && account.accountAddressList.length>0) {
          //     let mappedAddresses = account.accountAddressList.map(address => {
          //       return {
          //         compositeAdd: address.compositeAdd,
          //         addressId: address.customerAddressId,
          //       };
          //     })
          //     for (let address of mappedAddresses) {
          //       const idx = this.locationData.findIndex(a => a.addressId === address.addressId);
          //       if (idx < 0) {
          //         this.locationData.push(address);
          //       }
          //     }
          //   }
          // });
        }
      }
      this.setToFullList();
      this.tempLocationData = this.locationData;
      this.participantAdded = true;
      if(this.selectedAddress){
        this.locationSelected = this.selectedAddress.name;
        this.searchTerm = this.selectedAddress.name;
      }
    }else if(this.selectionFor && this.selectionFor == 'ACCOUNT_ADDRESSES' ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])){
      let dbKeys = ['account_linked_entityindskr_indskr_customeraddress_v2'];
      //             account_linked_entityindskr_indskr_customeraddress_v2
      dbKeys.forEach(async (dbKey) => {
        let rawData = await this.disk.retrieve(dbKey);
        if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
          rawData.raw.forEach(item => {
            if(item.hasOwnProperty('accountid') && this.accountIds.some(account=> account == item['accountid'])){
              let foundAddress = {
                addressId: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
                compositeAdd: item['indskr_address.indskr_composite']||'',
                customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                concatanatedAddress: item['indskr_indskr_customeraddress_v2.indskr_address_Formatted']||'',
                latitude: item['indskr_address.indskr_latitude']||'',
                longitude: item['indskr_address.indskr_longitude']||'',
              };

              if(!_.isEmpty(foundAddress.addressId)){
                const idx = this.locationData.findIndex(a => a.addressId === foundAddress.addressId);
                if (idx < 0) {
                  this.locationData.push(foundAddress);
                }
              }
            }
          })
          this.locationSelected = (this.activityService.selectedActivity as AppointmentActivity).location;
          this.searchTerm = (this.activityService.selectedActivity as AppointmentActivity).location;
        }
      });

      this.locationData = _.uniqBy(this.locationData, 'compositeAdd')
      this.tempLocationData = this.locationData;

    }else if(this.activityService.selectedActivity instanceof AppointmentActivity){
      //let tempLocation:Array<any> = [];
      let addedParticipantsData  = this.activityService.selectedActivity.contacts;
      let addedAccounts = this.activityService.selectedActivity.accounts;
      //console.log("Added participant data=> " , addedParticipantsData);
      if (addedParticipantsData.length !== 0 || addedAccounts.length != 0) {

        let rawData = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
        if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
          rawData.raw.forEach(item => {
            if(item.hasOwnProperty('contactid') && addedParticipantsData.some(contact=> contact.ID == item['contactid'])){
              let foundAddress:ContactAddress = {
                city: item['indskr_address.indskr_city_lu@OData.Community.Display.V1.FormattedValue']||'',
                state: item['indskr_address.indskr_state_lu@OData.Community.Display.V1.FormattedValue']||'',
                postal: item['indskr_address.indskr_postalcode_lu@OData.Community.Display.V1.FormattedValue']||'',
                country: item['indskr_address.indskr_country_lu@OData.Community.Display.V1.FormattedValue']||'',
                countryCode: item['indskr_address.indskr_countrycode_lu@OData.Community.Display.V1.FormattedValue']||'',
                street: item['indskr_address.indskr_line1']||'',
                street2: item['indskr_address.indskr_line2']||'',
                street3: item['indskr_address.indskr_line3']||'',
                region: item['indskr_address.indskr_luregion@OData.Community.Display.V1.FormattedValue']||'',
                postOfficeBox: item['indskr_address.indskr_postofficebox@OData.Community.Display.V1.FormattedValue']||'',
                isPrimary: item['indskr_indskr_customeraddress_v2.indskr_isprimary']||'',
                addressId: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
                compositeAdd: item['indskr_address.indskr_composite']||'',
                primaryContact: item['indskr_indskr_customeraddress_v2.indskr_primarycontact@OData.Community.Display.V1.FormattedValue']||'',
                fax: item['indskr_indskr_customeraddress_v2.indskr_fax@OData.Community.Display.V1.FormattedValue']||'',
                fax2: item['indskr_indskr_customeraddress_v2.indskr_fax2@OData.Community.Display.V1.FormattedValue']||'',
                telephone1: item['indskr_indskr_customeraddress_v2.indskr_telephone1@OData.Community.Display.V1.FormattedValue']||'',
                telephone2: item['indskr_indskr_customeraddress_v2.indskr_telephone2@OData.Community.Display.V1.FormattedValue']||'',
                isSampleEligible: item['indskr_indskr_customeraddress_v2.indskr_samplingeligible'],
                customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                concatanatedAddress: item['indskr_indskr_customeraddress_v2.indskr_address@OData.Community.Display.V1.FormattedValue']||'',
                latitude: item['indskr_address.indskr_latitude']||'',
                longitude: item['indskr_address.indskr_longitude:']||'',
              };
              if(!_.isEmpty(foundAddress.addressId)){
                const idx = this.locationData.findIndex(a => a.addressId === foundAddress.addressId);
                if (idx < 0) {
                  this.locationData.push(foundAddress);
                }
              }
            }
          })
        }
        // addedParticipantsData.forEach(data => {
        //   const contact = this.contactOfflineService.contacts.find(a => a.ID === data.ID);
        //   if (contact && contact.addressesList) {
        //     for (let address of contact.addressesList) {
        //       const idx = this.locationData.findIndex(a => a.addressId === address.addressId);
        //       if (idx < 0) {
        //         this.locationData.push(address);
        //       }
        //     }
        //   }
        // });
        let rawAccountsData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
          if(rawAccountsData && rawAccountsData.raw && Array.isArray(rawAccountsData.raw) && rawAccountsData.raw.length != 0){
            rawAccountsData.raw.forEach(item => {
              if(item.hasOwnProperty('accountid') && addedAccounts.some(account=> account.id == item['accountid'])){
                let foundAddress = {
                  compositeAdd: item['indskr_address.indskr_composite']||'',
                  addressId: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
                  customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                };
                if(!_.isEmpty(foundAddress.addressId)){
                const idx = this.locationData.findIndex(a => a.addressId === foundAddress.addressId);
                if (idx < 0) {
                  this.locationData.push(foundAddress);
                }
               }
              }
            })
          }
        // addedAccounts.forEach(value => {
        //   const account = this.accountService.getAccountById(value.id);
        //   if (account && account.accountAddressList && account.accountAddressList.length>0) {
        //     let mappedAddresses = account.accountAddressList.map(address => {
        //       return {
        //         compositeAdd: address.compositeAdd,
        //         addressId: address.customerAddressId,
        //       };
        //     })
        //     for (let address of mappedAddresses) {
        //       const idx = this.locationData.findIndex(a => a.addressId === address.addressId);
        //       if (idx < 0) {
        //         this.locationData.push(address);
        //       }
        //     }
        //   }
        // });
        this.setToFullList();
        this.locationData = _.uniqBy(this.locationData, 'compositeAdd')
        this.tempLocationData = this.locationData;

        console.log("Valid address for customers ", this.locationData);
        //set the template flag
        this.participantAdded = true;
      }else{
        this.locationData = this.locationService.locationData();
        this.setToFullList();
        this.logServices.logInfo( this.translate.instant('LOCATION_ENDPOINT') , this.locationData);
        this.tempLocationData = this.locationData;
      }
      if((this.activityService.selectedActivity && this.activityService.selectedActivity.location && this.activityService.selectedActivity.location != 'No Location' && this.activityService.selectedActivity.location != this.translate.instant('NO_LOCATION'))){
        this.locationSelected = (this.activityService.selectedActivity as AppointmentActivity).location;
        this.searchTerm = (this.activityService.selectedActivity as AppointmentActivity).location;
      }
    }
    setTimeout(() => {
      //used the native DOM to get the input warraper for ionic, when using ionic accessing the native element via the view child causes problems when we the element is  to be fed as args to the google maps API does not work.(Known issue in ionic)
    // The work around to the access this HTNLInputElement via the DOM default routines when the page load and all the DOM elements has properly been initialized , very critical!!!
    this.tempEl =  document.getElementById("search-dom-input-wrapper").getElementsByTagName('input')[0];
      //
      //this.mapCanvasElement =  document.getElementById("ionic-map-canvas");
      // if(!this.searchTerm || !this.searchTerm.length){
        this.searchForLocation();
      // }
      this.platform.ready()
      .then(()=>{
        //this.loadMapNativeMap(); //future sprint when we actually want a physical map to be shown
        //use observables to stream search output data
        this.searchControl.valueChanges.pipe(debounceTime(10000))
        .subscribe(search=>{ // delay for 10 seconds before triggering google maps API
          this.searchForLocation();
        });
      });
      this.initPageTitle();
    }, 100);
    this.initPageTitle();
    this.isSearchBoxDisabled = this.isAccountVisit;
  }

  private initPageTitle(): void {
    this.pageTitle = {
      id: 'location-title',
      title: this.translate.instant('LOCATION_CHOOSE_LOCATION'),
      controls: [{
        id: "close",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: "right"
      },
      {
        id: "done",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: this.isDoneButtonDisabled,
        align: "right"
      }],
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onCancelModal();
        break;
      case 'done':
        this.onCloseModal();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public get isDoneButtonDisabled():boolean {
    let flag:boolean = false;
    if(this.selectionFor && this.selectionFor == 'GENERAL_LOCATION_SELECTION'){
      if((this.tempEl  && this.selectedAddress?.name == this.tempEl.value)
          ||(!this.tempEl || !this.tempEl.value || this.tempEl.value.trim() == "")
            ){
              flag = true;
      }
    }else if((this.selectionFor ==='ACCOUNT_ADDRESSES') ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])){
      flag = !this.locationData.find((item) => item.compositeAdd === this.locationSelected);
    }else if((this.tempEl  && this.activityService.selectedActivity && this.activityService.selectedActivity.location == this.tempEl.value) || ((this.activityService.selectedActivity.location == undefined  || this.activityService.selectedActivity.location == 'No Location' || this.activityService.selectedActivity.location == this.translate.instant('NO_LOCATION')) && (!this.tempEl || !this.tempEl.value || this.tempEl.value == ""))){
      flag = true;
    }
    return flag;
  }

  private initSuggestedLocationsBySelectedAccountsContacts(){

  }

  /*public mapAddressDetails(contactDetail: any) {
    if (contactDetail && contactDetail['customerAddresses']) {
        for (let add of contactDetail['customerAddresses']) {
          if (add["line1"] || add["address_line1"]) {
                let addObj: any = {
                    city: (add["city"]) ? add["city"] : '',
                    state: (add["stateorprovince"]) ? add["stateorprovince"] : '',
                    postal: (add["postalcode"]) ? add["postalcode"] : '',
                    country: (add["country"]) ? add["country"] : '',
                    street: (add["line1"]) ? add["line1"] : (add["address_line1"])?add["address_line1"]:'',
                    street2: (add["line2"]) ? add["line2"] : add["address_line2"]?add["address_line2"]:'',
                    street3: (add["line3"]) ? add["line3"] : add["address_line3"]?add["address_line3"]:'',
                    postOfficeBox: (add["postalbox"]) ? add["postalbox"] : '',
                    isprimary: (add['indskr_isprimary']) ? add['indskr_isprimary'] : '',
                    addressId: (add['customeraddressid']) ? add['customeraddressid'] : '',
                    compositeAdd: '',
                    primaryContact: add['primarycontact'] || '',
                    fax: add['fax'] || '',
                    fax2: add['secondaryfax'] || '',
                    telephone1: add['telephone1'] || '',
                    telephone2: add['telephone2'] || ''
                };
                addObj.compositeAdd = addObj.street + ' ' + addObj.street2 + ' ' + addObj.street3 + ' ' + addObj.city + ' ' + addObj.state + ' ' + addObj.country + ' ' + addObj.postOfficeBox;
                let exists: boolean = false;
                this.locationData.forEach(address => {
                  console.log("addObj : ", addObj);
                  if (address.addressId === addObj.addressId) {
                      exists = true;
                  }
                });
                if (!exists) this.locationData.push(addObj);
            }
        }
        this.setToFullList();
    }

}*/
  ngAfterViewInit(){
    //used the native DOM to get the input warraper for ionic, when using ionic accessing the native element via the view child causes problems when we the element is  to be fed as args to the google maps API does not work.(Known issue in ionic)
    // The work around to the access this HTNLInputElement via the DOM default routines when the page load and all the DOM elements has properly been initialized , very critical!!!
    // this.tempEl =  document.getElementById("search-dom-input-wrapper")
    // .getElementsByTagName('input')[0];

  }
  ionViewDidLoad(){

  }

  ionViewWillLeave() {
    //this.map.setDiv(undefined);
    this.locationData = this.locationService.locationData(); //cache mechanism
  }

  ionViewDidEnter() {
    if (!this.firstLoad) {
    //this.map.setDiv('ionic-map-canvas');
      this.firstLoad = true;
    } else {
      this.firstLoad = false;
    }
  }
/**
 * Search For location by first traversing through the local cached data , if the location is not there we trigger the Google Maps API for places
 *
 * @memberof LocationComponent
 */
public  searchForLocation=async()=>{
  if(this.selectionFor === 'ACCOUNT_ADDRESSES' || (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])){
    this.searchAddressList();
    return;
  }

    if(this.searchTerm && this.searchTerm.length > 200) {
      this.searchTerm = this.searchTerm.substr(0,200);
      this.logServices.logInfo(this.translate.instant('LOCATION_SEARCH_TERM') , this.searchTerm.length);
      return;
    }else if(this.searchTerm.length == 0){
      this.searchActivated = false;
      this.tempLocationList = [];
      this.initPageTitle();
    }else{
      this.searchActivated = true;
      this.locationData = this.tempLocationData; //reset the data back to its original form
      this.locationData = this.filterCachedLocation(this.searchTerm);
      this.tempLocationList =  this.locationData; // make a new list for the search items
      this.initPageTitle();
      //this.virtualList.scrollInto(this.locationData ? this.locationData[0]: undefined);
      //if the cached location is an empty hit use google maps api place search
     if(this.locationData.length == 0){
        this.platform.ready()
        .then(() => {
          // this.mapsApiLoader.load()
          // .then(()=>{
            this.tempEl =  document.getElementById("search-dom-input-wrapper")
              .getElementsByTagName('input')[0]; // reason why we have this in multiple places is the way the dom loads, very unpredictanle
            //console.log("available in maps loader: ", this.tempEl);
            let autocomplete =  new google.maps.places.Autocomplete(this.tempEl ,{
              types:["address"]
            });
            autocomplete.addListener("place_changed" , ()=>{
              this.initPageTitle();
              this.ngZone.run(()=>{
                let place: google.maps.places.PlaceResult =  autocomplete.getPlace();
                //console.log("Please be an array of suggestions , will go in my modal as output: " ,place.address_components);
                if(this.markers.length > 0){
                  //reset it
                  this.markers = [];
                  this.markers.push(place.address_components);
                }else{
                  this.markers.push(place.address_components);
                }
                if(place.geometry === undefined || place.geometry === null){
                  return; // some error mesaage
                }else{
                  //for web only
                  this.latitude = place.geometry.location.lat();
                  this.longitude = place.geometry.location.lng();
                  //push the coordinates to the webMarker
                  this.webMarkers.push({
                    lat:this.latitude,
                    lng: this.longitude
                  });
                }
              });
            }); // google places implementation
          //})
          // .catch((e:any)=>{
          //   this.logServices.logError(e);
          // });
        });
      }
    }

    //this.tempLocationList = [];//reset
  }

  searchAddressList(){
      if(this.searchControl.value === '' || this.searchControl.value === 'No Location'){
        this.locationData = this.tempLocationData;
        this.locationSelected = '';
        this.searchActivated = true;
        return;
      }

      this.locationData = this.tempLocationData; //reset the data back to its original form
      this.locationData = this.filterCachedLocation(this.searchControl.value);
      this.searchActivated = true;
      this.initPageTitle();
  }

  selectAddressFromList(addressObj){
    this.tempEl =  document.getElementById("search-dom-input-wrapper").getElementsByTagName('input')[0];
    // this.tempEl.value = addressObj.compositeAdd;
    this.searchTerm = addressObj.compositeAdd;
    this.selectedAddressFromList = addressObj;
    this.locationSelected =  addressObj.compositeAdd;
    this.searchActivated = false;
    this.initPageTitle();
  }


 /**
  * The actual filtering implemation using the location data cached array
  *
  * @private
  * @param {string} searchValue
  * @returns {Array<string>}
  * @memberof LocationComponent
  */
 private filterCachedLocation(searchValue:string):Array<string>{
    return this.locationData.filter((item:any)=>{
      //dynamic searching based on the rendered template
        // return item.name ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : item.compositeAdd.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        return !searchValue ? true : item.name ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : (item.compositeAdd) ? item.compositeAdd.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : false

      });
  }
  /**
   * This callback function makes sure the promise resolves (more like sync) before i feed the position value to the MAP API
   * In this case i'm sure i have the position field coords available at all times.
   *
   * @private
   * @param {Function} callback
   * @param {*} position
   * @memberof LocationComponent
   */
  private onSuccess(callback: Function, position: any) {
    // console.log(position);
    if ('geolocation' in navigator && !this.onDevice) {
      // this.mapsApiLoader.load()
      // .then(() => {
        let webGeocoder = new google.maps.Geocoder();
        let latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
        //console.log(latlng);
        webGeocoder.geocode({ 'location': latlng }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => { //reverse geocoding
        //  console.log(results, status);
          if (status == google.maps.GeocoderStatus.OK) {
            let geometryLocation = results[0].geometry.location;
            this.latitude = geometryLocation.lat();
            this.longitude = geometryLocation.lng();
            this.webMarkers.push({
              lat: this.latitude,
              lng: this.longitude
            });
            this.tempEl =  document.getElementById("search-dom-input-wrapper")
              .getElementsByTagName('input')[0];
            this.tempEl.value = results[0].formatted_address.toString()
            .trim();
            this.searchTerm = this.locationSelected = this.tempEl.value;
            this.initPageTitle();
            this._CD.detectChanges();
          } else {
            this.logServices.logInfo(this.translate.instant('LOCATION_UNSUCCESFUL_ATTEMPT'), status);
          }
        });
      // })
      // .catch((e: any) => {
      //   this.logServices.logError(this.translate.instant('LOCATION_GOOGLE_MAP_API_NOT_READY'), e)
      // });
    } else {
      //for native only , i need more error checking , so i can avoid scratching me head all the time
      this.nativeGeocoder.reverseGeocode(position.coords.latitude, position.coords.longitude)
        .then((res) => {
        this.tempEl =  document.getElementById("search-dom-input-wrapper")
            .getElementsByTagName('input')[0];
        this.tempEl.value = res[0].subThoroughfare + " " + res[0].thoroughfare + ", " + res[0].locality + ", " + res[0].countryName; //viola
        this.searchTerm = this.locationSelected = this.tempEl.value;
        this.initPageTitle();
        this._CD.detectChanges();
      })
      .catch((e: any) => {
        //some really bad happened
      });
    }
    callback();
  }
  /**
   * native and web implementation to detect and map a user current location , might be needed for future development
   *
   * @private
   * @memberof LocationComponent
   */
  private async setCurrentPostion(callback:Function){
    if ("geolocation" in navigator && !this.onDevice) { // the problem is navigator is available somehow on devices
      // console.log("Available navigator geolocation" , navigator.geolocation);
      /*
      * maximum age
      *Is a positive long value indicating the maximum age in milliseconds of a possible cached position that is acceptable to return.
      *If set to 0, it means that the device cannot use a cached position and must attempt to retrieve the real current position.
      *If set to Infinity the device must return a cached position regardless of its age. Default: 0.
      */
      let postionOptions:PositionOptions = {enableHighAccuracy:true , maximumAge:0};
      navigator.geolocation.getCurrentPosition(this.onSuccess.bind(this, callback), undefined , postionOptions)

    }else{
      //check if we are on device
      // console.log("On device.............");
      this.geoLocation.getCurrentPosition({enableHighAccuracy:true})
        .then((this.onSuccess.bind(this, callback)));
    }
  }

/**
 * Get the current location by pressing a button , i use a lot of callbacks in this routine , downsides of javascript/typescript
 *
 * @memberof LocationComponent
 */
getCurrentLocation=()=>{
     this.setCurrentPostion(()=>{
     }); //CALLBACK HELL!!!!!!! => stuck with JS callback option ,  see routine implmentation for details
  }
/**
 * routines to convert back to actual adressses if no location is given , sent that data back to the activity detail page
 *
 * @memberof LocationComponent
 */
onCloseModal=()=>{
  if(this.selectionFor == 'GENERAL_LOCATION_SELECTION'){
    this._doGeneralSelection(true);
    return;
  }
    // if(this.tempEl.value === ""){
    //   //do nothing
    // }else{

    if(this.selectionFor === 'ACCOUNT_ADDRESSES' ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])){
      this._doGeneralSelection(true);
      return;
    }


      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.trackingService.tracking('MeetingLocationUpdate', TrackingEventNames.ACTIVITY)
        let currentAppointmentActivity: AppointmentActivity = this.activityService.selectedActivity;

        let payload = new UpdateMeetingPayload(
          currentAppointmentActivity.subject,
          currentAppointmentActivity.location,
          currentAppointmentActivity.scheduledStart,
          currentAppointmentActivity.scheduledEnd,
          currentAppointmentActivity.notes
        );

        payload.location = this.tempEl.value;
        this.activityService.selectedActivity.location = this.tempEl.value;
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).location = this.tempEl.value;

        if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(currentAppointmentActivity.ID)) {
          this.activityService.upsertMeetingsOfflineData(currentAppointmentActivity);
        } else {
          this.meetingDataService.updateMeeting(this.activityService.selectedActivity, payload, false, this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent)
          .catch(error => {
            console.error('onCloseModal: ', error);
            this.activityService.upsertMeetingsOfflineData(currentAppointmentActivity, false, true);
          });
        }
        this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity)

        // this.activityService.isActivityUpdate = true;

        // if (this.uiService.activitiesPageTab === 'day') {
        //   this.events.publish('activityUpdated', this.activityService.selectedActivity);
        // }

      }
    // }
    this.uiService.showNewActivity = false;
    // if(this.uiService.previousActiveView  == 'activityTimelineDetails' || this.uiService.prevView == 'contactDetails') {
    //   this.uiService.activeView = 'activityTimelineDetails';
    // } else {
    //   this.uiService.activeView = 'Appointment';
    // }
    if(this.isLayoverPushView){
      this.navService.popChildNavPageWithPageTracking();
    }else if(this.deviceService.isMobileDevice && this.navService.getCurrentPageName() == PageName.LocationComponent){
      this.navService.popWithPageTracking();
    }else{
      if(this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent){
        this.uiService.activeView = 'Appointment';
      }
      if(this.navService.getCurrentPageName() == PageName.ContactPageComponent){
        this.uiService.activeView = 'activityTimelineDetails';
      }
      if(this.navService.getCurrentPageName() == PageName.CallPlanComponent){
        this.callplanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.navService.popChildNavPageWithPageTracking();
      }
    }
  }
  onCancelModal=()=>{
    if(this.selectionFor == 'GENERAL_LOCATION_SELECTION'){
      this._doGeneralSelection(false);
      return;
    }
    this.uiService.showNewActivity = false;
    // if(this.uiService.previousActiveView  == 'activityTimelineDetails') {
    //   this.uiService.activeView = 'activityTimelineDetails';
    // } else {
    //   this.uiService.activeView = 'Appointment';
    // }
    if(this.isLayoverPushView){
      this.navService.popChildNavPageWithPageTracking();
    }else if(this.deviceService.isMobileDevice && this.navService.getCurrentPageName() == PageName.LocationComponent){
      this.navService.popWithPageTracking();
    }else{
      if(this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent){
        this.uiService.activeView = 'Appointment';
      }
      if(this.navService.getCurrentPageName() == PageName.ContactPageComponent){
        this.uiService.activeView = 'activityTimelineDetails';
      }
      if(this.navService.getCurrentPageName() == PageName.CallPlanComponent){
        this.callplanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.navService.popChildNavPageWithPageTracking();
      }
    }
  }

private _doGeneralSelection(isDone){
  this.navService.popChildNavPageWithPageTracking().then(()=>{
    if(isDone && this.callbackEvent && this.tempEl.value != ""){
      let selectedLocation = this.selectionFor === 'ACCOUNT_ADDRESSES' ? this.selectedAddressFromList : this.tempEl.value;
      this.callbackEvent(selectedLocation);
    }else if(this.selectionFor === 'ACCOUNT_ADDRESSES' ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])){
      this.callbackEvent(null)
    }
  });
}
  /**
   * Sets the location value in the search bar
   *
   * @param {*} eventData
   * @memberof LocationComponent
   */
  onLocationClick(eventData:any):void{
    this.locationSelected = (eventData && eventData['compositeAdd'])?eventData['compositeAdd']:eventData;
    this.searchTerm = this.tempEl.value = eventData.name ? eventData.name :eventData.compositeAdd; //dynamic as to what template is rendered
    this.initPageTitle();
  }
/**
 *
 *
 * @private
 * @memberof LocationComponent
 */
private setToFullList():void {
  this.locationData = _.uniqBy(this.locationData, 'compositeAdd');
  this.addressService.markFavouriteAddress(this.locationData);
    this.filteredList = this.locationData
      .slice()
      .map((item, idx) => {
        item.order = idx;
        return item;
      });

    this.locationData$.next(this.filteredList);
     //console.log(this.locationData$);
  }
// tslint:disable-next-line:max-file-line-count

  refreshPlanToDO() {
    if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
      this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
  }

  shouldShowSection() {
    if (this.selectionFor !== 'GENERAL_LOCATION_SELECTION') {
      return true;
    } else {
      return this.participantAdded && (!this.searchActivated && this.locationData$.getValue().length != 0 || this.searchActivated && this.tempLocationList.length != 0)
    }
  }

  shouldShowNoLocationData() {
    if (this.selectionFor !== 'GENERAL_LOCATION_SELECTION') {
      return false;
    } else {
      return !this.participantAdded || (!this.searchActivated && this.locationData$.getValue().length == 0 || this.searchActivated && this.tempLocationList.length == 0)
    }
  }

  hasMeetingURL() {
    return (this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE ) || this.authService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING)) && this.from != 'EventsToolPage' && (!_.isEmpty(this.activityService.selectedActivity.meetingURL) && this.selectionFor !== 'ACCOUNT_ADDRESSES'  || !_.isEmpty(this.activityService.selectedActivity['indskr_teamslink']));
  }

  meetingURL() {
    if (!_.isEmpty(this.activityService.selectedActivity['indskr_teamslink'])) {
      return this.activityService.selectedActivity['indskr_teamslink'];
    } else if (!_.isEmpty(this.activityService.selectedActivity.meetingURL))
      return this.activityService.selectedActivity.meetingURL
  }

  public favourite() {

  }

  showCurrentLocationButton(){
    return (this.authService.user.buConfigs && !this.authService.user.buConfigs['indskr_populateaccountaddress'] && this.selectionFor !== 'ACCOUNT_ADDRESSES')
  }

}
