<ion-header #Header>
    <ind-page-title [viewData]="xperienceDetailPageHeaderModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content #Content no-padding>
    <div class="switchConditionContainer">
        <xperience-plan-insight [x_type]="'plans'" [source]="xpCustomer"></xperience-plan-insight>
    </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'" [fromWhichView]="'Xperience'"></footer-toolbar>
<!-- </ion-footer> -->