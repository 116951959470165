<ion-header>
  <ind-page-title [viewData]="CustomNotificationPageTitle" (onControlClick)='handleButtonClick($event)'>
  </ind-page-title>
</ion-header>

<ion-content>
  <ng-container>
    <ion-list>
      <ind-section-header [viewData]="detailHeaderModel"></ind-section-header>
      <ion-row>
        <ion-col size="6">
          <ind-form-field [viewData]="NotitificationCustomerField"></ind-form-field>
        </ion-col>
        <ion-col size="6">
          <ind-form-field [viewData]="NotitificationChannelField"></ind-form-field>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ind-form-field [viewData]="NotitificationDateField"></ind-form-field>
        </ion-col>
        <ion-col size="6">
          <ind-form-field [viewData]="NotitificationDepartmentField"></ind-form-field>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ind-form-field [viewData]="NotitificationDescriptionField"></ind-form-field>
        </ion-col>
      </ion-row>
    </ion-list>
  </ng-container>
</ion-content>