<div *ngIf="plan">
  <ion-item-group class='plan-fields'>
    <ind-section-header [viewData]="overviewHeaderModel" (onFilterClick)="onFilterControlClick($event)" (onControlClick)="onSectionHeaderControlClick($event)">
    </ind-section-header>
    <div *ngIf="!hasCallPlanProgress || (hasCallPlanProgress && !displaygoalsbeyond100 && !displayAllActProgress)" class="overview">
      <div class="goal" *ngFor="let plan of goals">
        <div class="goal-primary">
          <label class="title"> {{ plan.title | translate}}</label>
          <span class="percentage info"> {{ plan.actual ? ((plan.actual / plan.goal) * 100).toFixed(0) : 0 }}%</span>
        </div>
        <div class="goal-secondary">
          <label class="title">{{'GOAL' | translate}}</label>
          <span class="info">{{ plan.actual }}/{{ plan.goal }}</span>
        </div>
        <call-plan-progress-bar [progressHeight]="3" [backgroundHeight]="1" [displayPercentage]="false"
          [progress]="plan.actual ? (plan.actual / plan.goal) * 100 : 0" [label]=""> </call-plan-progress-bar>
      </div>
    </div>
    <div *ngIf="hasCallPlanProgress && (displaygoalsbeyond100 || displayAllActProgress)" class="overview">
      <div class="goal" *ngFor="let plan of goals">
        <div class="goal-primary">
          <label class="title"> {{ plan.title | translate}}</label>
          <!-- <span *ngIf="plan.goal == 'NA'" class="percentage info"> NA </span>
          <span *ngIf="plan.goal != 'NA'" class="percentage info"> {{ plan.actual ? ((plan.actual / plan.goal) * 100).toFixed(0) : 0 }}%</span> -->
        </div>
        <div class="goal-secondary">
          <label class="title">{{'GOAL' | translate}}</label>
          <span class="info">{{ plan.actual }}/{{ plan.goal }}</span>
        </div>
        <call-plan-progress-bar [progressHeight]="3" [backgroundHeight]="1" [displayPercentage]="false"
          [progress]="plan.goal == 'NA' ? 0 : plan.actual ? (plan.actual / plan.goal) * 100 : 0" [label]=""> </call-plan-progress-bar>
      </div>
    </div>
  </ion-item-group>
  <ion-item-group [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
    <ind-section-header [viewData]="allCustomerPlansHeaderModel" (onControlClick)="onFilterControlClick($event)">
    </ind-section-header>
    <div class="call-plan" *ngFor="let rp of plan.repPlans | orderBy:'cycleplanname':true ">
      <div class="primary-content">
        <div class="plan-info">
          <ion-text class="primary-text">{{ rp.title}}
          <ion-icon class="arrow-icon"  class="customer-arrow-icon" name="chevron-forward-outline" (click)="openCustomerCallPlanSuggest(rp)"></ion-icon>
          </ion-text>
          <ion-text class="small-text">{{ rp.duration }}</ion-text>
        </div>
        <div *ngIf="!displaygoalsbeyond100" class="total">
          <div class="title small-text">{{'TOTAL' | translate}}</div>
          <div class="value">
            <div class="completed"> {{rp.completedGoals}}</div>
            <div class="defined">/{{rp.definedGoals}}</div>
          </div>
        </div>
        <div *ngIf="!displaygoalsbeyond100" class="percent">
          {{rp.percentage}}%
        </div>
      </div>
      <div class="secondary-content">
        <div class="goal-item" *ngFor="let g of rp.goals">
          <div class="title small-text">{{g.title | translate}}</div>
          <div class="value">
            <div class="completed"> {{g.actual}}</div>
            <div class="defined">/{{g.goal}}</div>
          </div>
        </div>
      </div>
      <div class="separationLine"></div>
    </div>
  </ion-item-group>
</div>
