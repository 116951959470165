import { Component, Input, HostListener, ViewChild, ElementRef } from "@angular/core";
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';

@Component({
    selector: 'call-plan-progress-bar',
    templateUrl: 'call-plan-progress.html',
  styleUrls:['call-plan-progress.scss']
})
export class CallPlanProgressBar {
    @Input('progress') progress: number;
    @Input('label') label: string;
    @Input('progressHeight') progressHeight: number= 26;
    @Input('backgroundHeight') backgroundHeight: number= 26;
    @Input('displayPercentage') displayPercentage: boolean= true;
    //@ViewChild('progressBar', {static:true}) progressBar:ElementRef;

    public realProgressRounded: number;
    private progresslabelShift: number;

    constructor(
        private navService: NavigationService,
    ) {

    }

  ngOnInit() {
        this.realProgressRounded = Math.round(this.progress);
    }

    ngOnChanges() {
        this.realProgressRounded = Math.round(this.progress);
    }

    ionViewWillEnter() {
        this.realProgressRounded = Math.round(this.progress);
    }

    ngDoCheck(){
        this.calculateLabelShift();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.calculateLabelShift();
    }

    calculateLabelShift(){
        let windowWidth = window.innerWidth > 450 ? window.innerWidth - (window.innerWidth * 0.1) : window.innerWidth - (window.innerWidth * 0.05);
        //let width = this.progressBar.nativeElement['offsetWidth'];
        if(this.navService.getCurrentPageName() == PageName.CallPlanDetails){
            this.progresslabelShift = (windowWidth * 0.45) - 10;
        }else{
            this.progresslabelShift = (windowWidth * 0.30) - 15;
        }
    }
}
