<ion-header>
    <!-- <ion-toolbar class="page-title">
    <ion-buttons slot="start">
      <ion-button color="primary" class="web-back" (click)="goBack()">
        {{ 'CANCEL' | translate}}
      </ion-button>
      <ion-button class="mobile-back" (click)="goToPreview()" icon-only>
        <ion-icon class="back-btn" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="leftScreenHeaderTitle ion-text-center" *ngIf="contentToken">{{contentToken.indskr_name}}</ion-title>
  </ion-toolbar> -->
    <ind-header-left [searchHidden]="true" [viewData]="replacementTokenPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-header-left>
</ion-header>

<ion-content class="ion-no-padding">
    <ion-item-group *ngIf="!contentToken?.indskr_iseditable; else empty">
        <!-- <ion-list>

    </ion-list> -->
        <ng-container *ngFor="let rToken of contentToken?.replacementTokens | orderBy: 'indskr_name' :true">
            <ion-item lines="none" class='selector-item' (click)="selectOption(rToken)">
                <ion-label>{{rToken.replacement_value}}</ion-label>
                <ion-icon *ngIf="rToken.is_default" slot="end" class='checkmark-icon' name="indegene-selectors-checkmark-icon"></ion-icon>
            </ion-item>
            <div class="separationLine"></div>
        </ng-container>
    </ion-item-group>
    <ng-template #empty>
        <div class="no-data-message">
            {{ 'EMAIL_NO_REPLACEMENT_OPTIONS' | translate}}
        </div>
    </ng-template>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'email-replacement-list'"></footer-toolbar>
<!-- </ion-footer> -->
