import { Component, Input } from '@angular/core';
import { IndDropdownListDetailModel } from './../../../models/indDropdownListModel';
import { SelectListData } from '../../../models/select-list-data-model';
import { NavParams, PopoverController } from '@ionic/angular';
const MIN_SEARCH_LENGTH = 1;
import * as _ from 'lodash';
import { IndKeyControlsAreaModel } from '../../../models/indKeyControlsAreaModel';
import { EventsService, EventName } from '../../../services/events/events.service';

@Component({
  selector: 'ind-dropdown-list',
  templateUrl: 'ind-dropdown-list.html',
  styleUrls:['ind-drowdown-list.scss']
})
export class IndDropdownListComponent {

  @Input() viewData: IndDropdownListDetailModel;
  public searchInput: string;
  public listData: Array<SelectListData>;
  public selectedItems: Array<SelectListData> = [];
  public searchActive: boolean = false;
  private tempSelectedItems: Array<SelectListData>;
  public dropdownListKeyControlAreaModel: IndKeyControlsAreaModel;
  private _isViewActive:boolean = true;

  constructor(
    private params: NavParams,
    private popoverCtrl: PopoverController,
    private events: EventsService,
  ) {

  }

  ngOnInit() {
      this.listData = this.viewData.data.filter(d => !d.isForcehide);
      this.listData.forEach((item: SelectListData) => {
        if (item.isSelected) {
          this.selectedItems.push(item);
        }
      });
      this._initKeyControlsAreaModel();
    this.tempSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
  }

  ngOnDestroy(){
    if(this._isViewActive && !this.viewData.showCancelandDoneBtn){
      let data = { selectedItems: this.selectedItems, isDone: true };
      this.events.publish(EventName.DROPDOWNLISTDISMISSDATA,data)
    }
  }

  private _initKeyControlsAreaModel() {
    this.dropdownListKeyControlAreaModel = {
      id:'dropdown-list-key-controls-area',
      isHidden: !this.viewData.showClearAllBtn,
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
      controls:[
        {
          id:'clear-all-btn',
          text: this.viewData.clearAllBtnText,
          isHidden: !this.viewData.showClearAllBtn,
          leftAligned:false
        }
      ]
    };
  }

  private handleControlsAreaEvent(id, event, eventName){
    if(id == 'clear-all-btn'){
      this.listData.map(item =>{
        item.isSelected = false;
        return item;
      });
      this.selectedItems = [];
      if(!this.viewData.isMultipleSelectionEnabled){
        this.dismissList(true);
      }
    }
  }

  public get noChanges(): boolean {
    return _.isEqual(this.selectedItems, this.tempSelectedItems)
  }

  public searchText(ev): void {
    let val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= MIN_SEARCH_LENGTH) {
      this.listData = this.viewData.data.filter(data => {
        return !data.isForcehide && data.title.trim().toLowerCase().includes(val.trim().toLowerCase());
      })
      this.searchActive = true;
    } else {
      this.listData = this.viewData.data.filter(d => !d.isForcehide);
      this.searchActive = false;
    }
  }

  private _deselectItem(item: SelectListData): void {
    //if (!item.isSelected) return;
    item.isSelected = false;
    let index = this.selectedItems.findIndex(data => data.id == item.id);
    if (index >= 0) {
      let listItem: SelectListData = this.listData.find(data => data.id == this.selectedItems[index].id);
      if (listItem) listItem.isSelected = false
      this.selectedItems.splice(index, 1);
    }
  }

  public selectItem(item: SelectListData): void {
    if(this.viewData.isReadOnly)return;
    if (item.isSelected && this.viewData.isMultipleSelectionEnabled) {
      this._deselectItem(item);
      return;
    }
    if (!this.viewData.isMultipleSelectionEnabled && this.selectedItems.length > 0) {
      this.selectedItems = [];
      this.viewData.data.forEach(data => {
        data.isSelected = false;
      })
    }
    item.isSelected = true;
    this.selectedItems.push(item);
    if(!this.viewData.isMultipleSelectionEnabled){
      this.dismissList(true);
    }
  }

  public async dismissList(flag: boolean) {
    if(this.viewData.isMultipleSelectionEnabled){
      let data = { selectedItems: this.selectedItems, isDone: flag };
      this._isViewActive = false;
      this.popoverCtrl.dismiss(data);
    }else if(this.viewData.showCancelandDoneBtn){
      let data = { selectedItems: this.selectedItems, isDone: flag };
      this._isViewActive = false;
      this.popoverCtrl.dismiss(data);
    }else{
      let data = { selectedItems: this.selectedItems, isDone: true };
      this._isViewActive = false;
      this.popoverCtrl.dismiss(data);
    }
  }

}
