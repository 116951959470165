import { Observable } from 'rxjs';
import { IndReportCardTableData } from './../../../../interfaces/edge-analytics/ind-report-card-table.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ind-report-card-table',
  templateUrl: 'ind-report-card-table.html',
  styleUrls: ['ind-report-card-table.scss']
})
export class IndReportCardTableComponent {
  @Input() tableData: Observable<IndReportCardTableData>;
  constructor() {}
}
