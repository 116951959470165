<ion-header>
  <ind-page-title [viewData]='viewData.pageTitle' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list>
    <ind-section-header *ngIf="viewData.pageSectionHeader" [viewData]="viewData.pageSectionHeader" (onControlClick)='onPageTitleControlClick($event)'> </ind-section-header>
    <ion-item class="info-item">
      <ion-label> {{viewData.infoText}}</ion-label>
    </ion-item>
    <ion-item class="selection-item" *ngFor="let item of viewData.data" (click)="onCheckboxClick(item)">
      <ion-label>
        <ion-checkbox [checked]="item.isChecked"> </ion-checkbox>
        {{item.title}}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>