import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { AssessmentTemplate } from '@omni/classes/customer-assessment/assessment-template.class';
import { Endpoints } from 'src/config/endpoints.config';
import { AuthenticationService } from '@omni/services/authentication.service';
import { CustomerAssessment, InternalSurvey } from '@omni/classes/customer-assessment/customer-assessment.class';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomerAssessDataService {
  constructor(
    private http: HttpClient,
    private dynamics: DynamicsClientService,
    private authService: AuthenticationService
  ) {
  }

  public async getAssessmentTemplates(fetchXml: string): Promise<AssessmentTemplate[]> {
    return await this.dynamics.executeFetchQuery('indskr_assessmenttemplates', fetchXml);
  }

  public async getAssessedContacts(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('contacts', fetchXml);
  }

  public async getAssessedAccounts(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('accounts', fetchXml);
  }

  public async getCustomerAssessment(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_customerassessmentresponses', fetchXml);
  }

  public async getAssessmentTeamViewRoles(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('roles', fetchXml);
  }

  public async getAssessmentTeamViewPositionGroups(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_positiongroupses', fetchXml);
  }

  public async getAssessmentTeamViewProducts(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('products', fetchXml);
  }

  public async getAssessmentTeamViewTherapeuticareas(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_therapeuticareas', fetchXml);
  }

  public async getCustomerSurvey(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_customerassessmentresponses', fetchXml);
  }

  public async getInternalSurvey(fetchXml): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_usersurveyresponses', fetchXml);
  }
  public async getCustomerSurveyAppt(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_customerassessments', fetchXml);
  }

  public async getCustomerSurveyCustomerAssessments(fetchXml: string): Promise<[]> {
    return await this.dynamics.executeFetchQuery('indskr_customerassessments', fetchXml);
  }

  public async saveContactAssessment(contactAssessment: CustomerAssessment) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'X-BusinessUnitId',
      this.authService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authService.user.xPositionID
    );
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CONTACT_ASSESSMENT.replace('{contactId}', contactAssessment.indskr_entityid);
    return await this.http.put(url, contactAssessment, { headers }).toPromise();
  }

  public async saveAccountAssessment(accountAssessment: CustomerAssessment) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'X-BusinessUnitId',
      this.authService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authService.user.xPositionID
    );
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.ACCOUNT_ASSESSMENT.replace('{accountId}', accountAssessment.indskr_entityid);
    return await this.http.put(url, accountAssessment, { headers }).toPromise();
  }


  // Submission of a Survey
  public async saveCustomerSurvey(captureFor: string, customerSurvey: CustomerAssessment) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'X-BusinessUnitId',
      this.authService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authService.user.xPositionID
    );
    const endpoint = captureFor == 'contact' ? Endpoints.contacts.CONTACT_ASSESSMENT.replace('{contactId}', customerSurvey.indskr_entityid) : Endpoints.accounts.ACCOUNT_ASSESSMENT.replace('{accountId}', customerSurvey.indskr_entityid);
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + endpoint;

    if (customerSurvey.indskr_appointmentid == '') {
      customerSurvey.indskr_appointmentid = undefined;
    }
    
    return await this.http.put(url, customerSurvey, { headers }).toPromise();
  }

  public async getProducts(fetchXml: string) {
    return await this.dynamics.executeFetchQuery('products', fetchXml);
  }

  public async getPositionGroupProducts(fetchXml: string) {
    return await this.dynamics.executeFetchQuery('systemusers', fetchXml);
  }

  public async uploadOfflineAssessment(customerAssessments) {
    if(_.isEmpty(customerAssessments)) return '';
    let headers = new HttpHeaders();
    headers = headers.set(
      'X-BusinessUnitId',
      this.authService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authService.user.xPositionID
    );
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.UPLOAD_OFFLINE_ASSESSMENTS;
    return await this.http.put<any>(url, customerAssessments, { headers }).toPromise();
  }

  public async uploadOfflineSurvey(customerSurveys) {
    if(_.isEmpty(customerSurveys)) return '';
    let headers = new HttpHeaders();
    headers = headers.set(
      'X-BusinessUnitId',
      this.authService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authService.user.xPositionID
    );
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.UPLOAD_OFFLINE_ASSESSMENTS;
    return await this.http.put<any>(url, customerSurveys, { headers }).toPromise();
  }


  public async saveInternalSurvey(internalSurvey: InternalSurvey) {

    let headers = new HttpHeaders();
    headers = headers.set(
      'X-BusinessUnitId',
      this.authService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authService.user.xPositionID
    ).set("X-SystemUserId",this.authService.user.systemUserID);
    const endpoint =  Endpoints.survey.INTERNAL//? Endpoints.contacts.CONTACT_ASSESSMENT.replace('{contactId}', customerSurvey.indskr_entityid) : Endpoints.accounts.ACCOUNT_ASSESSMENT.replace('{accountId}', customerSurvey.indskr_entityid);
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + endpoint;

    if (internalSurvey.hasOwnProperty('indskr_appointmentid')) {
      delete internalSurvey['indskr_appointmentid'];
    }
    return await this.http.put(url, internalSurvey, { headers }).toPromise();
  }

  public async scrapExternalSurvey(id: string) {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.SCRAP_ASSESSMENT;
    url = url.replace('{assessmentId}', id);
    return this.http.delete<any>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async scrapInternalSurvey(id: string) {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.survey.SCRAP_SURVEY;
    url = url.replace('{userSurveyid}', id);
    return this.http.delete<any>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

}
