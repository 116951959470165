import { Input, ElementRef, Renderer2, Directive } from "@angular/core";

@Directive({ selector: '[embed]' })
export class EmbedChildDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  @Input() set embed(child: HTMLElement) {
    Array.from(this.el.nativeElement.children).forEach(child => {
      this.renderer.removeChild(this.el.nativeElement, child);
    });
    if (child) {
      this.renderer.appendChild(this.el.nativeElement, child);
    }
  }
}