import { ChartFilterOption } from '../../../../interfaces/edge-analytics/report.interface';
import { CoachingDimensions, CoachingKPI } from '../../../enums/edge-analytics/coaching/coaching-report.enum';
import { CommonDimensions } from '../../../enums/edge-analytics/edge-analytics.enum';
import { IndReportTileDataModel } from '../../../models/IndReportTileDataModel';
import { SelectListDetail } from '../../../models/select-list-detail-model';

export const CoachingReportTilePerRow = 3;
export const CoachingReportYearMonthKeyProp = 'yearandmonth';
export const CoachingDataCubeDimensionHierarchies = {
  dimensionHierarchies: [
    {
      dimensionTable: { dimension: CommonDimensions.yearMonth, keyProps: [CoachingReportYearMonthKeyProp] },
    },
    {
      dimensionTable: { dimension: CoachingDimensions.statusCode, keyProps: ['statuscode'] },
    },
    {
      dimensionTable: { dimension: CoachingDimensions.sourceType, keyProps: ['source_type'] },
    },
    {
      dimensionTable: { dimension: CoachingDimensions.activityId, keyProps: ['activityid'] },
    },
    {
      dimensionTable: { dimension: CoachingDimensions.covisitor, keyProps: ['covisitor'] },
    },
    {
      dimensionTable: { dimension: CommonDimensions.users, keyProps: ['sk_systemuserid'] },
    },
  ]
}

export const CoachingReportTiles: IndReportTileDataModel[] = [
  {
    id: CoachingKPI.coVisitorMeetings,
    firstHeading: "WITH_COVISITOR",
    dataValue: '',
    dataFieldName: "MEETINGS_PHONE_CALLS",
    order: 0
  },
  {
    id: CoachingKPI.coachingsAcknowledged,
    firstHeading: "COACHINGS_ACKNOWLEDGED",
    dataValue: '',
    dataFieldName: "COACHINGS",
    order: 1
  },
  {
    id: CoachingKPI.avgCoachingScore,
    firstHeading: "AVERAGE_COACHING_SCORE",
    dataValue: '',
    dataFieldName: '',
    order: 2
  },
  {
    id: CoachingKPI.uniqCustMetWithCovisitor,
    firstHeading: 'UNIQ_CUST_MET_WITH_COVISITOR',
    dataValue: '',
    dataFieldName: '',
    order: 3
  },
  {
    id: CoachingKPI.attendedAsCovisitor,
    firstHeading: 'ATTENDED_AS_COVISITOR',
    dataValue: '',
    dataFieldName: '',
    order: 4,
  }
]

export const CoachingTrendChartFilterOptions: ChartFilterOption[] = [
  {
    value: CoachingKPI.coVisitorMeetings,
    isSelected: true,
    displayText: 'With Covisitor',
    displayTextKey: "WITH_COVISITOR",
    data: undefined
  },
  {
    value: CoachingKPI.coachingsAcknowledged,
    isSelected: false,
    displayText: 'Coachings Acknowledged Volume',
    displayTextKey: "COACHINGS_ACKNOWLEDGED_VOLUME",
    data: undefined
  },
  {
    value: CoachingKPI.avgCoachingScore,
    isSelected: false,
    displayText: 'Average Coaching Score',
    displayTextKey: "AVERAGE_COACHING_SCORE",
    data: undefined
  },
  {
    value: CoachingKPI.uniqCustMetWithCovisitor,
    isSelected: false,
    displayText: 'Unique Customers Met with Covisitor',
    displayTextKey: "UNIQ_CUST_MET_WITH_COVISITOR",
    data: undefined
  },
  {
    value: CoachingKPI.attendedAsCovisitor,
    isSelected: false,
    displayText: 'Attended as Covisitor',
    displayTextKey: 'ATTENDED_AS_COVISITOR',
    data: undefined,
  },
]

export const CoachingTrendChartFilterTemplate: SelectListDetail = {
  title: 'Measure',
  titleKey: 'MEASURE',
  dividerTitle: 'All Measures',
  dividerTitleKey: 'ALL_MEASURES',
  isMultipleSelectionEnabled: true,
  data: []
}

export const CoachingChartTitles = {
  trend: 'TREND',
}
