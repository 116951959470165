<ion-grid [ngClass]="(!!scientificActivityService.isRightPaneNavActive)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="scientific-activity-left-pane">
      <scientific-activity-list (closeModal)="closeScientificActivityPage($event)" (selectSAP)="setSelectedSAP($event)"></scientific-activity-list>
    </ion-col>
    <ion-col class="scientific-activity-right-pane">
      <ion-nav #scientificactivityrightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
  