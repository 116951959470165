<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content>
  <ind-section-header [viewData]="detailHeaderModel"></ind-section-header>

  <ind-form-field [viewData]="getNameFormField()"></ind-form-field>

   <ind-form-field [viewData]="getCurrencyFormField()"></ind-form-field>

 <ind-form-field [viewData]="getAccountsFormField()"></ind-form-field>

  <ind-form-field [viewData]="getPriceListFormField()"></ind-form-field> 
</ion-content>