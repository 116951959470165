<ng-container *ngIf="viewData">
  <ion-toolbar class="header-toolbar">
      <div class="header-toolbar-inner-wrapper-first"
          [ngClass]="(viewData.isExpanded || !viewData.enableExpand)?'expanded-view':'collapsed-view'">
          <span class="avatar-box">
              <div class="avatar" *ngIf="viewData.avatarURL">
                  <img class="avatar-img" src="{{viewData.avatarURL}}">
              </div>
              <div class="avatar userInitialsBox"  *ngIf="!viewData.avatarURL">
                  {{userInitials}}
              </div>
          </span>
          <span class="text-box">
              <div class="first-line">
                  <ion-text class="primary-glance-label">
                      <ion-text class="primary-glance-label-text"> {{viewData.name}} </ion-text>
                  </ion-text>
                  <ion-buttons class="end-slot" *ngIf="viewData.enableExpand || viewData?.buttons.length > 0">
                      <ng-container
                          *ngIf="viewData?.buttons.length > 0 && (!viewData.enableExpand || viewData.isExpanded)">
                          <ion-button *ngFor="let button of viewData.buttons" (click)="onButtonClick(button,$event)">
                              <ion-icon [name]="button.icon" [src]="button.imgSrc"></ion-icon>
                          </ion-button>
                      </ng-container>
                      <ng-container *ngIf="viewData.enableExpand">
                          <ion-button (click)="onExpandClick()" *ngIf="!viewData.isExpanded">
                              <ion-icon name="chevron-down-outline"></ion-icon>
                          </ion-button>
                          <ion-button (click)="onExpandClick()" *ngIf="viewData.isExpanded">
                              <ion-icon name="chevron-up-outline"></ion-icon>
                          </ion-button>
                      </ng-container>
                  </ion-buttons>
              </div>
              <span class="second-line">
                  <!-- <ion-icon *ngIf="viewData.isExpanded || !viewData.enableExpand" class="location-icon blue-text"
                      src="assets/imgs/glance-card-location.svg"></ion-icon> -->
                  <ion-text *ngIf="viewData.isExpanded || !viewData.enableExpand" class="glance-secondary-text">
                    <ion-text *ngIf="viewData.locationText; else nolocationtext">
                      {{viewData.locationText}}
                    </ion-text>
                    <ng-template #nolocationtext>
                      <ion-text  class="placeholder-text">
                        {{'NO_LOCATION' | translate}}
                      </ion-text>
                    </ng-template>
                  </ion-text>
                  <ion-buttons class="start-slot"
                      *ngIf="viewData?.buttons.length > 0 && viewData.enableExpand && !viewData.isExpanded">
                      <ion-button *ngFor="let button of viewData.buttons" (click)="onButtonClick(button,$event)">
                          <ion-icon [name]="button.icon" [src]="button.imgSrc"></ion-icon>
                      </ion-button>
                  </ion-buttons>
              </span>
          </span>
      </div>
      <div class="header-toolbar-inner-wrapper-second" *ngIf="viewData.isExpanded || !viewData.enableExpand" [ngClass]="(viewData.isExpanded || !viewData.enableExpand)?'expanded-view':'collapsed-view'">
        <span class="third-line" *ngIf="viewData.thirdText || viewData.fourthText">
          <div class="values-div">
            <ion-text class="label-text" *ngIf="viewData.thirdTextLabel">{{viewData.thirdTextLabel}}</ion-text>
            <ion-text class="blue-text" *ngIf="viewData.thirdText">{{viewData.thirdText}}</ion-text>
          </div>
          <div class="values-div">
            <ion-text class="label-text right-side-text" *ngIf="viewData.fourthTextLabel">{{viewData.fourthTextLabel}}</ion-text>
            <ion-text class="right-side-text" *ngIf="viewData.fourthText">{{viewData.fourthText}}</ion-text>
          </div>
        </span>
        <span class="fourth-line" *ngIf="viewData.fifthText || viewData.sixthText">
          <div class="values-div">
            <ion-text class="label-text" *ngIf="viewData.fifthTextLabel">{{viewData.fifthTextLabel}}</ion-text>
            <ion-text *ngIf="viewData.fifthText">{{viewData.fifthText}}</ion-text>
          </div>
          <div class="values-div">
            <ion-text class="label-text right-side-text" *ngIf="viewData.sixthTextLabel">{{viewData.sixthTextLabel}}</ion-text>
            <ion-text class="right-side-text" *ngIf="viewData.sixthText">{{viewData.sixthText}}</ion-text>
          </div>
        </span>
      </div>
  </ion-toolbar>
</ng-container>