<div class="content-cards">
  <div class="content-title">{{ contentData.title | translate }}</div>
  <div *ngIf="contentData.showSkeleton; else actualContent">

    <div class="card-grid">
      <div *ngFor="let _ of [1, 2, 3]" class="card">
        <ion-skeleton-text class="img-skl skeleton-text-animated" [animated]="true"></ion-skeleton-text>
        <div class="card-content">
          <ion-skeleton-text class="skl-name skeleton-text-animated" [animated]="true"></ion-skeleton-text>
          <ion-skeleton-text class="skl-tag skeleton-text-animated" [animated]="true"></ion-skeleton-text>
        </div>
      </div>
    </div>
  </div>

  <ng-template #actualContent>
    <div class="card-grid" *ngIf="contentData.cards && contentData.cards.length > 0; else noContent">
      <div *ngFor="let card of contentData.cards" class="card" [ngClass]="{selected: card.selected}" (click)="onContentSelect(card)">
        <img [src]="card.imageUrl" alt="{{card.name}}" io-thumbnail>
        <div class="card-content">
          <div class="card-name">{{ card.name }}</div>
          <div class="tags">
            <div *ngFor="let tag of card.tags" class="tag"><label>{{ tag.indskr_name }}</label></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #noContent>
    <div class="no-content">No Content</div>
  </ng-template>
</div>