import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DiskService} from "@omni/services/disk/disk.service";
import {AuthenticationService} from "@omni/services/authentication.service";
import {Endpoints} from "../../../config/endpoints.config";


@Injectable({
  providedIn: 'root'
})
export class OnekeyApiDataService {

  requestOptions: any = {};

  constructor(
    private http: HttpClient,
    private disk: DiskService,
    private authService: AuthenticationService
  ) {}

  authenticate() {
    let baseUri = this.authService.okIntegrationSettings.okConfigDTO.apiEndPoint;
    return this.http.get(baseUri, { ...this.requestOptions, responseType: 'text' }).toPromise();
  }

  searchOneKey(payload) {
    let baseUri = this.authService.okIntegrationSettings.okConfigDTO.apiEndPoint;
    baseUri = baseUri + Endpoints.OneKey.SEARCH_OK.replace('{connectorId}', this.authService.okIntegrationSettings.okConfigDTO.connectorId.toString());
    return this.http.post<any>(baseUri, payload).toPromise();
  }

  //testing purpose
  refreshOneKey(payload) {
    let baseUri = this.authService.okIntegrationSettings.okConfigDTO.apiEndPoint;
    baseUri = baseUri + '/pop/refreshMyOnekey/2379';
    return this.http.post<any>(baseUri, payload).toPromise();
  }

  submitVR(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    let baseUri = this.authService.okIntegrationSettings.okConfigDTO.apiEndPoint;
    baseUri = baseUri + Endpoints.OneKey.SUBMIT_VR.replace('{connectorId}', this.authService.okIntegrationSettings.okConfigDTO.connectorId.toString());
    return this.http.post<any>(baseUri, payload, { headers }).toPromise();
  }

  traceVR(payload) {
    let baseUri = this.authService.okIntegrationSettings.okConfigDTO.apiEndPoint;
    baseUri = baseUri + Endpoints.OneKey.TRACE_VR.replace('{connectorId}', this.authService.okIntegrationSettings.okConfigDTO.connectorId.toString());
    return this.http.post<any>(baseUri, payload).toPromise();
  }

  submitPop(payload) {
    let baseUri = this.authService.okIntegrationSettings.okConfigDTO.apiEndPoint;
    baseUri = baseUri + Endpoints.OneKey.SUBMIT_POP.replace('{connectorId}', this.authService.okIntegrationSettings.okConfigDTO.connectorId.toString());
    return this.http.post<any>(baseUri, payload).toPromise();
  }

}
