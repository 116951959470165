export enum SENTIMENT {
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL',
    POSITIVE = 'POSITIVE'
}


/*
{
	"indskr_iopresentationid": "123",
	"activityPresentationSlides": [
		{
				"indskr_slideid": "1",
				"indskr_assettype": 100000000,
				"activityPresentationSlideContacts": [
					{
						"indskr_contactid": "92738493-593a-e711-811a-480fcfeab991",
						"sentiment": "POSITIVE"
					},
					{
						"indskr_contactid": "87738493-593a-e711-811a-480fcfeab991",
						"sentiment": "POSITIVE"
					}
				]
		}
	]
} 
*/