import { ReportService } from './../../../services/reports/report.service';
import { UIService } from './../../../services/ui/ui.service';
import { NavParams, ModalController } from '@ionic/angular';
import { RepServices } from './../../../data-services/rep/rep.services';
import { Component, Input } from '@angular/core';
import _ from 'lodash';

/**
 * Generated class for the ReportsFiltersComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'reports-filters',
  templateUrl: 'reports-filters.html',
  styleUrls:['reports-filters.scss']
})
export class ReportsFiltersComponent {
  @Input() filterData: any;
  updatedFilters: any[] = [];

  constructor(
    public repService: RepServices,
    public navParams: NavParams,
    public uiService: UIService,
    public modalCtrl: ModalController,
    public reportService: ReportService
  ) {
  }
  ngOnInit() {
    this.filterData.forEach(fd=>{
      fd.modelVariable = fd.data.map(option=>{
        if(option.isSelected) return option.value
      })
    })
  }
  filterChanged(filter:any){
    let selectedFilter = this.filterData.find(f=>f.id == filter.id);
    if(selectedFilter){
      selectedFilter.data.forEach(dataPoint=>{
        if(selectedFilter.modelVariable.indexOf(dataPoint.value)>-1){
          dataPoint.isSelected = true;
        }
        else{
          dataPoint.isSelected = false;
        }
      })
      _.remove(this.updatedFilters,(filter)=>{
        return filter.id == selectedFilter.id
      })
      this.updatedFilters.push(selectedFilter);
    }
  }

  onClosePage(isDone: boolean){
    console.log('embedded: filter selected time', new Date(), new Date().getTime())
    this.uiService.displayLoader();
    if(isDone && this.updatedFilters.length>0){
      this.modalCtrl.dismiss({updatedFilterData: this.updatedFilters});
    }
    else this.modalCtrl.dismiss();
  }

}
