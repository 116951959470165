import { Component, Input } from '@angular/core';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { CaseManagementService } from '../../../services/case-management/case-management.service';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from "rxjs";
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { Utility } from '../../../utility/util';

/**
 * Generated class for the CaseListItemComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'case-list-item',
  templateUrl: 'case-list-item.html',
  styleUrls:['case-list-item.scss']
})
export class CaseListItemComponent {

  isSelected: boolean = false;
  public IcurrentCaseSubscription: Subscription;

  @Input() case: CaseActivity;
  constructor(
    private caseManagementService: CaseManagementService,
    public utilityService:GlobalUtilityService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService
  ) {
  }

  ngOnInit() {
    this.IcurrentCaseSubscription = this.caseManagementService.currentSelectedCase.subscribe(e => {
      this.isSelected = e && this.case && (e.ID === this.case.ID);
    });
  }

  ngOnDestroy() {
    this.IcurrentCaseSubscription.unsubscribe();
  }

  public get caseName():string {
    // let caseNum = this.case._case_notes ? this.case._case_notes : '';
    // let caseType = this.case._case_type ? this.case._case_type.name : '';
    return this.case._case_notes ? this.case._case_notes : (this.case._case_type && this.case._case_type.name) ? this.case._case_type.name : '';
  }

  public getMainCardDataModel():MainCardViewDataModel{
    if(!this.case) return;
    let viewData:MainCardViewDataModel;
    viewData = {
      id: this.case.ID,
      isExpandable: false,
      fixedHeight: true,
      isSelected: this.isSelected,
      primaryTextLeft: (this.case.scheduledStart)?`${this.datePipe.transform(this.case.scheduledStart, 'MMM', undefined, this.translate.currentLang)}  ${this.case.scheduledStart.getDate()}`:'',
      primaryTextRight: this.case.caseName,
      secondaryTextRight: '',
      secondaryTextRightTwo: '',
      showArrow: false,
      // arrowType: 'chevron-forward-outline',
    };
    if(this.case._case_stage_value){
      viewData.secondaryTextRight = this.getState(this.case._case_stage_value);
      viewData.secondaryTextRightTwo = (this.case._case_contact)?this.case._case_contact.fullName:( this.case._case_trans_customer_name && this.case._case_trans_customer_name != Utility.globalCustomerText ) ? this.case._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
    }else{
      viewData.secondaryTextRight = (this.case._case_contact)?this.case._case_contact.fullName:( this.case._case_trans_customer_name && this.case._case_trans_customer_name != Utility.globalCustomerText ) ? this.case._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
    }
    return viewData;
  }
  public getState(caseState):string{

    switch (caseState) {
        case 'Open':
          caseState = this.translate.instant('OPEN');
            break;
        case 'Approved':
          caseState = this.translate.instant('APPROVE');
            break;
        case 'For Review':
            caseState = this.translate.instant('FOR_REVIEW');
            break;
        case 'In Progress':
            caseState = this.translate.instant('IN_PROGRESS');
            break;
            case 'Intake':
              caseState = this.translate.instant('INTAKE');
              break;
        default:
            caseState = caseState;
            break;
        }
        return caseState;
}

}
