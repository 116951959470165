export const timezones = [
    {
      "code": 0,
      "offset": '-12:00'
    },
    {
      "code": 1,
      "offset": '-11:00'
    },
    {
      "code": 2,
      "offset": '-10:00'
    },
    {
      "code": 3,
      "offset": '-09:00'
    },
    {
      "code": 4,
      "offset": '-08:00'
    },
    {
      "code": 10,
      "offset": '-07:00'
    },
    {
      "code": 13,
      "offset": '-07:00'
    },
    {
      "code": 15,
      "offset": '-07:00'
    },
    {
      "code": 20,
      "offset": '-06:00'
    },
    {
      "code": 25,
      "offset": '-06:00'
    },
    {
      "code": 30,
      "offset": '-06:00'
    },
    {
      "code": 33,
      "offset": '-06:00'
    },
    {
      "code": 35,
      "offset": '-05:00'
    },
    {
      "code": 40,
      "offset": '-05:00'
    },
    {
      "code": 45,
      "offset": '-05:00'
    },
    {
      "code": 50,
      "offset": '-04:00'
    },
    {
      "code": 55,
      "offset": '-04:00'
    },
    {
      "code": 56,
      "offset": '-04:00'
    },
    {
      "code": 60,
      "offset": '-03:30'
    },
    {
      "code": 65,
      "offset": '-03:00'
    },
    {
      "code": 70,
      "offset": '-03:00'
    },
    {
      "code": 73,
      "offset": '-03:00'
    },
    {
      "code": 75,
      "offset": '-02:00'
    },
    {
      "code": 80,
      "offset": '-01:00'
    },
    {
      "code": 83,
      "offset": '-01:00'
    },
    {
      "code": 85,
      "offset": '+00:00'
    },
    {
      "code": 90,
      "offset": '+00:00'
    },
    {
      "code": 95,
      "offset": '+01:00'
    },
    {
      "code": 100,
      "offset": '+01:00'
    },
    {
      "code": 105,
      "offset": '+01:00'
    },
    {
      "code": 110,
      "offset": '+01:00'
    },
    {
      "code": 113,
      "offset": '+01:00'
    },
    {
      "code": 115,
      "offset": '+02:00'
    },
    {
      "code": 120,
      "offset": '+02:00'
    },
    {
      "code": 125,
      "offset": '+02:00'
    },
    {
      "code": 130,
      "offset": '+02:00'
    },
    {
      "code": 135,
      "offset": '+02:00'
    },
    {
      "code": 140,
      "offset": '+02:00'
    },
    {
      "code": 145,
      "offset": '+03:00'
    },
    {
      "code": 150,
      "offset": '+03:00'
    },
    {
      "code": 155,
      "offset": '+03:00'
    },
    {
      "code": 158,
      "offset": '+03:00'
    },
    {
      "code": 160,
      "offset": '+03:30'
    },
    {
      "code": 165,
      "offset": '+04:00'
    },
    {
      "code": 170,
      "offset": '+04:00'
    },
    {
      "code": 175,
      "offset": '+04:30'
    },
    {
      "code": 180,
      "offset": '+05:00'
    },
    {
      "code": 185,
      "offset": '+05:00'
    },
    {
      "code": 190,
      "offset": '+05:30'
    },
    {
      "code": 193,
      "offset": '+05:75'
    },
    {
      "code": 195,
      "offset": '+06:00'
    },
    {
      "code": 200,
      "offset": '+06:00'
    },
    {
      "code": 201,
      "offset": '+06:00'
    },
    {
      "code": 203,
      "offset": '+06:30'
    },
    {
      "code": 205,
      "offset": '+07:00'
    },
    {
      "code": 207,
      "offset": '+07:00'
    },
    {
      "code": 210,
      "offset": '+08:00'
    },
    {
      "code": 215,
      "offset": '+08:00'
    },
    {
      "code": 220,
      "offset": '+08:00'
    },
    {
      "code": 225,
      "offset": '+08:00'
    },
    {
      "code": 227,
      "offset": '+08:00'
    },
    {
      "code": 230,
      "offset": '+09:00'
    },
    {
      "code": 235,
      "offset": '+09:00'
    },
    {
      "code": 240,
      "offset": '+09:00'
    },
    {
      "code": 245,
      "offset": '+09:50'
    },
    {
      "code": 250,
      "offset": '+09:50'
    },
    {
      "code": 255,
      "offset": '+10:00'
    },
    {
      "code": 260,
      "offset": '+10:00'
    },
    {
      "code": 265,
      "offset": '+10:00'
    },
    {
      "code": 270,
      "offset": '+10:00'
    },
    {
      "code": 275,
      "offset": '+10:00'
    },
    {
      "code": 280,
      "offset": '+11:00'
    },
    {
      "code": 285,
      "offset": '+12:00'
    },
    {
      "code": 290,
      "offset": '+12:00'
    },
    {
      "code": 300,
      "offset": '+13:00'
    },
    {
      "code": 360,
      "offset": '+00:00'
    }
  ];