<ion-header>
    <ind-page-title [viewData]="generalInsightsPageHeaderModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ind-section-header [viewData]='headerData'></ind-section-header>
    <ion-list>
        <ion-item-group>
            <!-- <ion-item>
                <ion-label position="fixed">{{ 'DESCRIPTION' | translate}}</ion-label>
                <ion-label *ngIf="readOnly" [ngClass]="{'disabledLabel no-description-text': readOnly}"> {{ insight.description }}</ion-label>
                <ion-input *ngIf="!readOnly" placeholder="{{'ENTER_DESCRIPTION' | translate}}" [(ngModel)]="insight.description" (input)="onDescriptionChange($event.target.value)"></ion-input>
                <ion-icon *ngIf="!readOnly" slot="end" name="arrow-back" color="indgray" class="light-color"></ion-icon>
            </ion-item> -->
            <ind-form-field [viewData]="generalInsightsDescription"></ind-form-field>
            <!-- <ion-item>
                <ion-label>{{ 'DATE' | translate}}</ion-label>
                <div item-content text-wrap>
                    <ion-row align-items-center>
                        <ion-label class="date-label">{{formattedInsightTime}}</ion-label>
                        <ion-datetime *ngIf="!readOnly" [displayFormat]="dateTimeFormatsService.dateToUpper" [pickerFormat]="dateTimeFormatsService.dateToUpper" (ionChange)="updateInsightDate()" [(ngModel)]="insightDate" placeholder="{{'CUSTOMER_INSIGHT_JDATE' | translate}}"
                            [max]="maxDate" [monthShortNames]="shortMonth | monthTranslate" [doneText]="translate.instant('DONE')" [cancelText]="translate.instant('CANCEL')"></ion-datetime>
                        <ion-icon *ngIf="!readOnly" float-right style="color:#c8c7cc;margin-left:5px;" name="arrow-down" slot="end"></ion-icon>
                    </ion-row>
                </div>
            </ion-item> -->
            <ind-form-field [viewData]="generalDateFormField"></ind-form-field>
            <!-- <ion-datetime #generalInsights hidden="true" [displayFormat]="dateTimeFormatsService.dateToUpper" [pickerFormat]="dateTimeFormatsService.dateToUpper" (ionChange)="updateInsightDate()" [(ngModel)]="insightDate" placeholder="{{'CUSTOMER_INSIGHT_JDATE' | translate}}"
                [max]="maxDate" [monthShortNames]="shortMonth | monthTranslate" [doneText]="translate.instant('DONE')" [cancelText]="translate.instant('CANCEL')"></ion-datetime> -->
            <!-- <ion-item>
                <ion-label>{{ 'PRODUCT' | translate}}</ion-label>
                <div slot="end" [ngClass]="{'disabledLabel': readOnly}" (click)="selectInsightProduct()">
                    <span *ngIf="insight.selectedProduct" [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ insight.selectedProduct.title }}</span>
                    <span *ngIf="!insight.selectedProduct" class="right-label placeholder">{{ 'SELECT_PRODUCT' | translate }}</span>
                    <ion-icon *ngIf="!readOnly" name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                </div>
            </ion-item> -->
            <ind-form-field [viewData]="generalProductFormField"></ind-form-field>


            <!-- <ion-item *ngIf="insight.selectedProduct && insight.selectedProduct.id == 'indskr_competitorproduct'">
                <ion-label>{{ 'CONTACT_COMPETITOR_PRODUCT' | translate}}</ion-label>
                <ion-input *ngIf="!readOnly" [(ngModel)]="insight.indskr_competitorproduct"></ion-input>
                <ion-icon *ngIf="!readOnly" slot="end" name="arrow-back" color="indgray" class="light-color"></ion-icon>
            </ion-item> -->
            <ind-form-field *ngIf="xpService.generalInsight && xpService.generalInsight.selectedProduct && xpService.generalInsight.selectedProduct.id == 'indskr_competitorproduct'" [viewData]="generalInsightCompetitorFormField"></ind-form-field>

            <!-- <ion-item>
                <ion-label>{{ 'CATEGORY' | translate}}</ion-label>
                <div slot="end" (click)="selectInsightSpeciality()">
                    <span *ngIf="!insight.selectedSpeciality" class="right-label placeholder">{{ 'SELECT_CATEGORY' | translate }}</span>
                    <span *ngIf="insight.selectedSpeciality" class="right-label">{{ insight.selectedSpeciality.title }}</span>
                    <ion-icon name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                </div>
            </ion-item> -->
            <ind-form-field [viewData]="generalCategoryFormField"></ind-form-field>
            <!-- <ion-item>
                <ion-label>{{ 'XPERIENCES_INSIGHTS_CONFIDENCE' | translate}}</ion-label>
                <div slot="end">
                    <ionic5-star-rating [(ngModel)]="insight.confidence" #rating activeIcon="ios-star" defaultIcon="ios-star" activeColor="#FFA736" defaultColor="#c3c3c3" fontSize="32px" maxRating=3 readonly="{{readOnly}}"></ionic5-star-rating>
                </div>
            </ion-item> -->
            <ion-item class="confidence">
                <div>
                    <ion-label class="form-field-header-label">{{ 'XPERIENCES_INSIGHTS_CONFIDENCE' | translate}}</ion-label>
                    <app-star-rating [rating]="xpService.generalInsight && xpService.generalInsight.confidence" (ratingChanged)="updateJourneyConfidence($event)" activeIcon="star-sharp" defaultIcon="star-sharp" activeColor="#FFA736" defaultColor="#c3c3c3" fontSize="32px"
                        maxRating=3 readonly="{{readOnly}}"></app-star-rating>
                </div>
            </ion-item>
        </ion-item-group>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'" [fromWhichView]="'Xperience'"></footer-toolbar>
<!-- </ion-footer> -->
