import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { takeUntil } from "rxjs/operators";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { Subject } from "rxjs";
import _ from "lodash";
import { IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";


@Component({
  selector: 'ind-slide-modal',
  templateUrl: 'ind-slide-modal.html',
  styleUrls:['ind-slide-modal.scss']
})

export class IndSlideModalComponent {
  @Input() viewData: any;
  ngDestroy$: Subject<boolean> = new Subject<boolean>();
  public slideModalReplacementHeader: IndSectionHeaderViewDataModel;
  public slideModalPreviewHeader: IndSectionHeaderViewDataModel;
  public slides: any[] = [];
  public selectedSlideHtml: SafeHtml;
  public selectedSlide: any;
  public isGeneralSectionEnabled: boolean = false;
  public isTemplatePreview: boolean = false;

  constructor(
    public translate: TranslateService,
    private screenOrientation: ScreenOrientation,
    private readonly modalCtrl: ModalController,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.screenOrientation
    .onChange()
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.modalCtrl.dismiss();
    });
  }
  
  ngOnInit() {
    if(!_.isEmpty(this.viewData) && !_.isEmpty(this.viewData.data)) {
      this.slides = this.viewData.data.replacementTokens;
      this.selectedSlide = this.slides.find(s => s.is_default);
      this.selectedSlideHtml = this.sanitizer.bypassSecurityTrustHtml(this.slides.find(s => s.is_default).replacement_value);
    } else if(!_.isEmpty(this.viewData) && !_.isEmpty(this.viewData.htmlString)) {
      this.isTemplatePreview = true;
      this.selectedSlideHtml = this.viewData.htmlString;
    }
    this._initSectionHeader();
  }

  ngOnDestroy() {
    this.isTemplatePreview = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.unsubscribe();
  }

  private _initSectionHeader() {
    this.slideModalReplacementHeader = {
      id: 'slide-modal-replacement-header',
      title: this.translate.instant('CONTENTS'),
      controls: []
    }
    let buttons = [];
    buttons.push({
      id: 'slide-go-backward',
      isDisabled: false,
      iconName:'chevron-back-outline'
    },{
      id: 'slide-go-forward',
      isDisabled: false,
      iconName: 'chevron-forward-outline'
    },{
      id: 'slide-close',
      isDisabled: false,
      icon: 'assets/imgs/preview_close.svg'
    },{
      id: 'slide-select',
      isDisabled: false,
      icon: 'assets/imgs/preview_select.svg'
    })
    this.slideModalPreviewHeader = {
      id: 'slide-modal-preview-header',
      title: this.isTemplatePreview ? this.translate.instant('PREVIEW') : this.translate.instant('EDIT'),
      controls: this.isTemplatePreview ? [] : buttons
    }
  }

  public onSectionHeaderControlClick(id: string) {
    switch(id) {
      case 'slide-go-backward':
        this.goBackward();
        break;
      case 'slide-go-forward':
        this.goForward();
        break;
      case 'slide-close':
        this.modalCtrl.dismiss( );
        break;
      case 'slide-select':
        const data = { selectedItem: this.selectedSlide.replacement_id, isDone: true }
        this.modalCtrl.dismiss(data);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public getSlideNameFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NAME'),
      inputText: this.viewData.data.indskr_name,
      id: 'name-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public goForward() {
    let numOfSlides = this.slides.length;
    let currentIdx = this.slides.findIndex(sl => sl.replacement_id == this.selectedSlide.replacement_id);
    let nextIdx = currentIdx+1 < numOfSlides ? currentIdx+1 : 0;
    this.slides[currentIdx].is_default = false;
    this.slides[nextIdx].is_default = true;
    this.selectedSlide = this.slides[nextIdx];
    this.selectedSlideHtml = this.sanitizer.bypassSecurityTrustHtml(this.selectedSlide.replacement_value);
    console.log("go forward to the next slide");
  }

  public goBackward() {
    let numOfSlides = this.slides.length;
    let currentIdx = this.slides.findIndex(sl => sl.replacement_id == this.selectedSlide.replacement_id);
    let prevIdx = currentIdx-1 < 0 ? numOfSlides-1 : currentIdx-1;
    this.slides[currentIdx].is_default = false;
    this.slides[prevIdx].is_default = true;
    this.selectedSlide = this.slides[prevIdx];
    this.selectedSlideHtml = this.sanitizer.bypassSecurityTrustHtml(this.selectedSlide.replacement_value);
    console.log("go backward to the previous slide");
  }

  public selectSlide(event, slide) {
    console.log("show this selected slide");

  }

}