<ion-item-group>
    <ind-section-header  [viewData]='{id:"details-header",title: translate.instant("DETAILS_CAP")}'></ind-section-header>

    <!-- <ind-form-field [viewData]="getPeriodFormField()"></ind-form-field>
    <ind-form-field [viewData]="getProductsFormField()"></ind-form-field>
    <ind-form-field [viewData]="getTherapeuticAreaFormField()"></ind-form-field>
    <ind-form-field [viewData]="getMeetingGoalsFormField()"></ind-form-field>
    <ind-form-field [viewData]="getMeetingCompletedFormField()"></ind-form-field>
    <ind-form-field [viewData]="getMessageGoalFormField()"></ind-form-field>
    <ind-form-field [viewData]="getDirectMsgCompletedFormField()"></ind-form-field> -->
    <!-- <ind-form-field *ngFor="let field of allFormFieldValues" [viewData]='field'></ind-form-field> -->
    <ion-row>
      <ion-col size="6"><ind-form-field [viewData]="getStartPeriodFormField()"></ind-form-field></ion-col>
      <ion-col size="6"><ind-form-field [viewData]="getEndPeriodFormField()"></ind-form-field></ion-col>
    </ion-row>
    <ind-form-field *ngFor="let field of allFormFieldValues" [viewData]='field'></ind-form-field>

    <ind-section-header  [viewData]='strategyDocumentHeader' (onControlClick)="openResources(selectedSAP.documents)"></ind-section-header>
    <!-- <ion-item [ngClass]="{'empty-list':!selectedSAP.strategy}"  no-lines>
        <div text-wrap class="strategy-text">{{selectedSAP.strategy}}</div>
    </ion-item> -->
    <ion-item class="itemAlign" [ngClass]="{'empty-list':!selectedSAP.strategy}" >
      <div class="strategy-text" [ngClass]="showMore?'show-more':''">
          {{ showMore? selectedSAP.strategy : selectedSAP.strategy | slice:0:strategy_Text_default_length}}
          <a class="showEllipsis" *ngIf="selectedSAP.strategy.length > strategy_Text_default_length && !showMore">... </a>
          <a *ngIf="selectedSAP.strategy.length > strategy_Text_default_length" (click)="setShowMore($event)" class="show-more">{{ !showMore ? ('SHOW_MORE' | translate) : ('SHOW_LESS' | translate)}}</a>
      </div>
    </ion-item>
    
    <ind-section-header  [viewData]='{id:"scientific-object",title: translate.instant("SCIENTIFIC_INFO_SCIENTIFIC_OBJECTIVES")}'></ind-section-header>
    <ion-item-group [attr.no-lines]="(i == selectedSAP.sapObjectives.length - 1) ? true : null" *ngFor="let obj of selectedSAP.sapObjectives; index as i">
      <ion-item>
          <div text-wrap>{{obj.name}}</div>
      </ion-item>
      <!-- <div class="separationLine"></div> -->
    </ion-item-group>
    <ion-item class='empty-list' *ngIf="selectedSAP.sapObjectives.length==0"></ion-item>
    
    <ind-section-header  [viewData]='{id:"followup-action",title: translate.instant("FOLLOW_UP_ACTIONS_CAPS")}'></ind-section-header>
    <ion-item class="placeholderEmptyDiv" *ngIf="!followUpTasksForPlan || followUpTasksForPlan.length==0"></ion-item>
    <main-card *ngFor="let task of allModelTasks" [viewData]="task"></main-card>
</ion-item-group>
