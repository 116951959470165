<ion-grid  [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="left-pane">
      <pharmacovigilance-list></pharmacovigilance-list>
    </ion-col>
    <ion-col class="right-pane">
      <ion-nav #rightPaneNav></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
