import { ModalController } from '@ionic/angular';
import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { RepServices } from '../../../data-services/rep/rep.services';
import { CaseManagementService, AccesingMode } from '../../../services/case-management/case-management.service';
import { DeviceService } from '../../../services/device/device.service';
import { CaseType } from '../../../classes/case-intake/case-utility.class';
import { NavParams } from '@ionic/angular';
import {CaseActivity} from "../../../classes/case-intake/case-activity.class";
import {ActivityService} from "../../../services/activity/activity.service";
import {UIService} from "../../../services/ui/ui.service";
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { SurveyService } from './../../../services/survey/survey.service';

/**
 * Generated class for the NewCaseManagementComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'new-case-management',
  templateUrl: 'new-case-management.html',
  styleUrls:['new-case-management.scss']
})
export class NewCaseManagementComponent {

  type: CaseType;
  isModalView: boolean = false;
  public selectedType:string = null;
  @Input() viewMode: any
  public newCaseHeaderModel: IndSectionHeaderViewDataModel;
  public pageTitle: IndPageTitleViewDataModel;
  public pageTitleControls = [];

  constructor(
    public repService: RepServices,
    public caseManagementService: CaseManagementService,
    private deviceService: DeviceService,
    private activityService: ActivityService,
    private uIService: UIService,
    public navParams: NavParams,
    private _cd: ChangeDetectorRef,
    public modalCtrl: ModalController,
    public translate: TranslateService,
    private surveyService: SurveyService

  ) {
    this.type = caseManagementService.caseTypeList[0];

  }
  ngOnInit() {
    if (
      (this.viewMode === 'modalView') ||
      (this.caseManagementService.accessedFrom === AccesingMode.INMEETING || this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION)
    ) {
      this.isModalView = true;
    }

    this.newCaseHeaderModel = {
      id:'details-header',
      title: this.translate.instant('NEW_ACTIVITY_CATEGORY'),
      controls: [ ]
    }

    this.pageTitleControls = [
      {
        id: 'new-case-cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'right'
      },
      {
        id: 'new-case-confirm',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('CONFIRM'),
        isDisabled: false,
        align: 'right'
      }
    ];
    this.pageTitle = {
      id: 'followup-details',
      title: this.translate.instant('NEW_ENQUIRY'),
      controls: this.pageTitleControls 
    };
  }

  onPageTitleControlClick(id: string) {
    if (id === 'new-case-cancel') {
       this.cancelNewCase();
    } else if (id === 'new-case-confirm'){
       this.handleNewCase();
    } else{
      this.cancelNewCase();
    }
  }

  async handleNewCase() {
    if (this.isModalView) {
      let preSelectedData: Object = {};
      preSelectedData['ID'] = this.activityService.selectedActivity.ID;
      /*
        Creating case from meeting with some data
      */
      if(this.activityService.selectedActivity['contacts'] && this.activityService.selectedActivity['contacts'].length === 1){
        preSelectedData['contacts'] = this.activityService.selectedActivity['contacts'];
      }
      if(this.activityService.selectedActivity['accounts'] && this.activityService.selectedActivity['accounts'].length === 1){
        preSelectedData['accounts'] = this.activityService.selectedActivity['accounts'];
      }


      let iCase: CaseActivity = await this.caseManagementService.initNewCaseActivity(this.type, true, preSelectedData);
      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
        this.navParams.data.modalView.dismiss(iCase);
      }
      // else if(this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
      //   this.viewCtrl.dismiss(iCase);
      // }
      else {
        try {
          this.activityService.selectedActivity = iCase;
          this.uIService.activeView = 'customer_inquiry';
          this.modalCtrl.dismiss(iCase);
        } catch (error) {

        }
      }
    }
    else {
      this.caseManagementService.initNewCaseActivity(this.type);
    }
  }

  cancelNewCase() {
    if (this.isModalView) {
      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
        this.navParams.data.modalView.dismiss();
      }
      // else if(this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
      //   this.viewCtrl.dismiss();
      // }
      else {
        try {
          this.modalCtrl.dismiss();
        } catch (error) {

        }
      }
    }
    else {
      this.caseManagementService.assignSelectedCase(undefined);
      this.surveyService.assignLookupResult(undefined);
      this.caseManagementService.showNewCase = false;
      if (this.deviceService.shouldBeMobileView) {
        this.caseManagementService.showRightPane = false;
      }
      else {
        this.caseManagementService.showRightPane = false;
      }
    }
  }

  public toggle(selected, event) {
    if (event.checked)  {
      this.selectedType = selected.id;
      this.type = selected;
    }
    else {
      if (selected.id == this.selectedType) event.checked = true;
    }
  }

  public selectType(item) {
    this._cd.detectChanges();
    this.type = item;
    this._cd.detectChanges();
    this._cd.markForCheck();
  }

}
