<!-- Generated template for the TimeOffDetailsComponent component -->
<ion-header>
    <ind-page-title [viewData]='{id:"timeOff-details", title: pageTitle, controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content *ngIf="selectedTot" [ngClass]="{'mobileContent': device.isMobileDevice}">
    <ion-list>
        <ind-section-header [viewData]='{id:"timeOff-details", title: translate.instant("DETAILS_CAP")}'>
        </ind-section-header>
        <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>
        <ind-form-field [viewData]="getCreatedByFormField()"></ind-form-field>
        <ind-form-field *ngIf="!hideSubjectAndPosition" [viewData]="getSubjectFormField()"></ind-form-field>
        <ind-form-field [viewData]="getDayTypeFormField()"></ind-form-field>
        <!-- <ind-form-field [viewData]="getDateFormField()"></ind-form-field> -->
        <ion-row *ngIf="selectedDurationType == 'All Day'">
          <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
          <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
        </ion-row>
        <ion-row *ngIf="selectedDurationType == 'Half Day'">
          <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
          <ion-col size="6"><ind-datetime-form *ngIf="timePeriodField" [viewData]="timePeriodField"></ind-datetime-form></ion-col>
        </ion-row>
        <ion-row *ngIf="selectedDurationType == 'Hourly'">
          <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
          <ion-col size="6"><ind-datetime-form *ngIf="startTimeField" [viewData]="startTimeField"></ind-datetime-form></ion-col>
        </ion-row>
        <ion-row *ngIf="selectedDurationType == 'Hourly'">
          <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
          <ion-col size="6"><ind-datetime-form *ngIf="endTimeField" [viewData]="endTimeField"></ind-datetime-form></ion-col>
        </ion-row>
        <activity-conflict-alert-msg *ngIf="displayConflictMsg"></activity-conflict-alert-msg>
        <ind-form-field [viewData]="getReasonFormField()"></ind-form-field>
        <ind-form-field *ngIf="selectedTot.timeOffReason==548910003 && commentsForAdminLeave" [viewData]="getCommentFormField()"></ind-form-field>
        <ind-form-field *ngIf="!hideSubjectAndPosition" [viewData]="getPositionFormField()"></ind-form-field>
        <ind-form-field [viewData]="getApprovedByFormField()"></ind-form-field>
        <ind-form-field [viewData]="getApprovedOnFormField()"></ind-form-field>
        <ind-form-field [viewData]="getApproverCommentsFormField()"></ind-form-field>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'TimeOffDetails'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->
