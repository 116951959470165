
import {BehaviorSubject, throwError as observableThrowError} from 'rxjs';
import { Injectable } from "@angular/core";
import { RepStatus } from "../../classes/rep/rep.class";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { LogService } from "../../services/logging/log-service";
import { Observable } from "rxjs";
import { Endpoints } from "../../../config/endpoints.config";
import { map, catchError, timeout } from "rxjs/operators";

import { Events } from '@omni/events';
import { REP_STATUS, LAST_REP_STATUS_KEY } from '../../models/rep-status-model';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from "../../services/authentication.service";
import { DeviceService } from "../../services/device/device.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { TranslateService } from "@ngx-translate/core";
// import { AppInsightsService } from '@markpieszak/ng-application-insights';

@Injectable({
  providedIn: 'root'
})
export class RepServices {

    private data: Array<any> = new Array();
    private repData: RepStatus;
    private userState: string;
    private prevState: string;
    private _isOfflineState: boolean = false;
    private _isOfflineState$: BehaviorSubject<boolean> = new BehaviorSubject(this._isOfflineState);
    readonly isOfflineState$: Observable<boolean> = this._isOfflineState$.asObservable();
    private backgroundNoti: string;
    private appInBackground: boolean;

    /**
     * Creates an instance of RepServices.
     * @param {HttpClient} http
     * @param {AuthenticationService} authenticationService
     * @memberof RepServices
     */
    constructor(private http: HttpClient,
                private logService:LogService,
                private events: Events,
                private notificationService: NotificationService,
                private authenticationOfflineService: AuthenticationService,
                private platform: Platform,
                private device: DeviceService,
                // public appInsightsService: AppInsightsService,
                public translate:TranslateService
            ) {
        this.events.subscribe('device:deviceIsOffline', (status) => {
          this.updateWhenInternetStatusChangesToOffline(true);
            if ((<any>window).SessionStack) {
                (<any>window).SessionStack.stop();
            }
            // if (this.appInsightsService.config){
            //     appInsightsService.config.disableTelemetry = true;
            // }

            // If rep status is already offline, no notification banner required
            if (this.userState !== REP_STATUS.OFFLINE.userState && this.device.isNativeApp && !this.device.appInBackground) {
              this.translate.get('init').subscribe(() => {
                this.notificationService.notify(this.translate.instant('DEVICE_SEEMS_TO_BE_OFFLINE'), "Device Service", "top", ToastStyle.DANGER);
                this.device.backgroundUploadInProgress = false;
              });
            }
        });

        this.events.subscribe('device:deviceIsOnline', (status) => {
            if (
                this.authenticationOfflineService.user &&
                this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ERROR_REPORTING_REPLAY)
            ) {
                if ((<any>window).SessionStack) {
                    (<any>window).SessionStack.start();
                }
            }
            // if (this.appInsightsService.config){
            //     appInsightsService.config.disableTelemetry = false;
            // }
            if (this.userState === REP_STATUS.OFFLINE.userState) {
                //Tell device service we want to stay offline instead
                this.device.isOffline = true;

            } else {
                if(this.device.isNativeApp && !this.device.appInBackground){
                    this.updateWhenInternetStatusChangesToOffline(false);
                    this.notificationService.notify(this.translate.instant('CONNECTION_RESTORED'), "Device Service", "top", ToastStyle.INFO);
                }
            }

        });

        // this.platform.pause.subscribe( ev=> {
        //     this.appInBackground = true;
        // });

        // this.platform.resume.subscribe ( ev=> {
        //     this.appInBackground = false;
        //     if(this.backgroundNoti){
        //         if(this.backgroundNoti === "Connection Restored"){
        //             this.notificationService.notify(this.backgroundNoti, "Device Service", "top", ToastStyle.INFO)
        //         }else{
        //             this.notificationService.notify(this.backgroundNoti, "Device Service", "top", ToastStyle.DANGER)
        //         }
        //         this.backgroundNoti = undefined;
        //     }
        // });
    }

    private updateWhenInternetStatusChangesToOffline(isOffline: boolean) {
      if (this.device.isNativeApp) {
        this.isOfflineState = isOffline;
      }
    }

    /**
     * Get the rep status in real time
     *
     * @returns {Observable<RepStatus>}
     * @memberof RepServices
     */
    public async getCurrentState(isInitialCall = false): Promise<RepStatus> {
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.authentication.REP_STATUS;
        let headers = new HttpHeaders();
        headers = headers.set('X-SystemUserId', this.authenticationOfflineService.user.systemUserID);

        return await this.http.get(url, { headers })
        .pipe(
            map(response => this._mapRepStatus(response, isInitialCall)
            ), // mapped the new Rep info in real time
            catchError((e: any , caught:any) =>
                observableThrowError(this.errorHandler(e)))
        ).toPromise();//end of pipe
    }
    /**
     *  Change the current rep status via a POST request
     *
     * @param {Object} body
     * @returns {Observable<RepStatus>}
     * @memberof RepServices
     */
    public async setCurrentState(body: { userState: string }): Promise<RepStatus> {
        let url = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.authentication.UPDATE_STATUS;
        let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
        headers.headers = headers.headers.set('X-SystemUserId', this.authenticationOfflineService.user.systemUserID);

        /**
         * Set device to offline regardless if the http requestion fails or not
         */
        const tempPrevState = this.userState;
        if(body.userState === REP_STATUS.OFFLINE.userState) {
            this.userState =  body.userState;
            this.prevState = tempPrevState;
            this.isOfflineState = true;
        }
        return await this.http.post(url, body, headers)
        .pipe(
            timeout(30000),     // 30s timeout so it doesn't block the app..
            map(() => {
                if (this.repData) {
                    this.repData.currentStatus = this.userState = body.userState;
                    this.repData.previousStatus = this.prevState = tempPrevState;
                    this.isOfflineState = this.userState === REP_STATUS.OFFLINE.userState;
                    return this.repData;
                } else {
                    this.prevState = tempPrevState;
                    this.userState = body.userState;
                    this.isOfflineState = this.userState === REP_STATUS.OFFLINE.userState;
                    return null;
                }
            }), // mapped the new Rep info in real time
            catchError((e: any , caught:any) => {
                if(body.userState !== REP_STATUS.OFFLINE.userState) {
                    this.notificationService.notify( this.translate.instant('UNABLE_TO_UPDATE_USER_STATUS'), "menu-toggle", "top", ToastStyle.DANGER, 3000, true);
                }
                return observableThrowError(this.errorHandler(e))
            })
        ).toPromise();
    }

    /**
     *  ADDED BECAUSE OF MERGE FROM 1.3
     *  MOST LIKELY DEPRECATED PLEASE DELETE
     *  10/02/18
     */
    get isOfflineState(): boolean {
        return !!this._isOfflineState;
    }
    set isOfflineState(isOffline: boolean) {
        this._isOfflineState = isOffline;
        this._isOfflineState$.next(isOffline);
    }

    // End of added code

    /**
     * Returns the target state of the current. This is an array of target state objects
     *
     * @readonly
     * @type {Array<any>}
     * @memberof RepServices
     * @returns {Array<any>}
     *
    public get getTargetState(): Array<any> {
        return this.data;
    }*/
    /**
    * Return the current status of the rep
    * @readonly
    * @type {string}
    * @memberof RepServices
    * @returns {string}
    */
    public getCurrentUserState(): string {
        return this.userState;
    }

    public setCurrentUserState(state: string) {
        this.userState = state;
        this.repData ? this.repData.currentStatus = state : undefined;
    }

    public setPreviousUserState(state: string) {
        this.prevState = state;
        this.repData ? this.repData.previousStatus = state : undefined;
    }

    getLastUserState(): string | null {
        const state = window.localStorage.getItem(LAST_REP_STATUS_KEY);
        return state ? state : null;
    }
    saveLastUserState(state: string) {
        window.localStorage.setItem(LAST_REP_STATUS_KEY, state);
    }
    wasLastUserStateOffline(): boolean {
        return this.getLastUserState() === REP_STATUS.OFFLINE.userState;
    }

    /**
    * Return the previous status of the rep
    * @readonly
    * @type {string}
    * @memberof RepServices
    * @returns {string}
    */
    public getPreviousUserState(): string {
        return this.prevState ? this.prevState : this.repData && this.repData.previousStatus ? this.repData.previousStatus : REP_STATUS.ONLINE.userState;
    }
    /**
     * maps the raw data from the endpoint into a user data structure
     * @param {object} raw
     * @returns {RepStatus}
     * @memberof RepServices
     */
    _mapRepStatus(raw: object, isInitialCall = false): RepStatus {
        if (raw && !Array.isArray(raw)) {
            this.repData = new RepStatus(raw);
            this.userState = this.repData.currentStatus;
            this.prevState = this.repData.previousStatus;
            this.isOfflineState = !isInitialCall && this.userState === REP_STATUS.OFFLINE.userState;
            return this.repData; // make a data-service class
        } else if (!raw) {
            // Should be a new user
            console.warn('_mapRepStatus: Is it a new user?');
        } else {
            console.error('_mapRepStatus: Wrong response returned. ', raw);
        }
    }
    /**
     *
     *
     * @private
     * @param {*} err
     * @memberof RepServices
     */
    private errorHandler(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logService.logError('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(
              `Backend returned code ${error.status}`) //, body was: ${error}, ` +  ` message was: ${error.error.errorMessage}`);
          }
          // return an ErrorObservable with a user-facing error message , leads to runtime error , so we handle this to prevent the app from crashing?
          return observableThrowError(() => (error.message + this.translate.instant('SOMETHING_REALLY_BAD_HAPPENED')));
    };
}
