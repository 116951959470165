import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LogService } from '../logging/log-service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    constructor(private injector: Injector) { }
    /**
     * 
     * 
     * @param {(Error | HttpErrorResponse)} error 
     * @memberof GlobalErrorHandler
     */
    handleError(error: Error | HttpErrorResponse | any) {
        // Do whatever you like with the error (send it to the server?)
        // And log it to the console
        // for stack trace purpose since it takes the cast version of the particular error
        error = (error instanceof  Error || error instanceof HttpErrorResponse) ? error : new Error(error); 
        const message = error.message ? error.message : error.toString();
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const loggingService = this.injector.get(LogService);
        //const notificationService = this.injector.get(NotificationService); notification service shd be an email service we subscribe to
        if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) { //server connection error happened
                //handle offline error
            } else {
                if (new RegExp(/^5[0-9][0-9]$/gm).test(error.status.toString())) { // 500 error series
                    console.log("500 error" , error);
                }
                if (new RegExp(/^4[0-9][0-9]$/gm).test(error.status.toString())) { //400 error series
                    console.log("400 error" , error);
                }
                if(new RegExp(/^3[0-9][0-9]$/gm).test(error.status.toString())){
                    console.log("redirection error" , error);
                }
            }
            loggingService.logError(error);
        } else {
            //handle angular , client , reference error etc
            // get the stack trace, lets grab the last 10 stacks only
            StackTrace.fromError(error).then(stackframes => {
                const stackString = stackframes
                    .splice(0, 50)
                    .map((sf)=> {
                        return sf.toString();
                    });
                console.log("printing the stack trace error information ........")
                loggingService.logInfo({ message, url, stackString });
            });
        }
    }
}