import { Utility } from "../../utility/util";
import { AccountPlanObjective } from "../account-management/account-plan.class";
import { Contact } from "../contact/contact.class";
import { Brand } from "../brand/brand.class";
import { TherapeuticArea } from "../therapeutic-area/therapeutic-area.class";
import { Resource } from "../resource/resource.class";


export class ScientificActivityPlan {
    public ID: string;
    public scientificActivityPlanName: string;
    public createdon:string;
    public startDate: string;
    public endDate: string;
    public state:number;
    public status: string;
    public statusCode: any;
    public strategy: string;
    public productId:string;
    public products:Brand[]=[];
    public contactId:string;
    public contacts:Contact[]=[];
    public sapObjectives: any[]=[];
    public lastUpdated?: number;
    public ownerID: string;
    public totalEmailGoals:number;
    public totalMeetingGoals:number;
    public totalCompletedEmailGoals:number;
    public totalCompletedMeetingGoals:number;
    public therapeuticArea: string;
    public therapeuticAreaList: TherapeuticArea[] = [];
    public users:ScientificPlansUsers[] = [];
    public documents: Resource[] = [];
    public trackAction: string;
    public trackActionCreatedOn : string;

    constructor(rawObj){
        this.ID = rawObj['indskr_scientificplanid'];
        this.scientificActivityPlanName = rawObj['indskr_name'];
        // this.startDate = rawObj['indskr_startdate'] ? Utility.changeUTCDateToLocalDateWith0Time(new Date(rawObj['indskr_startdate@OData.Community.Display.V1.FormattedValue']).getTime()).getTime().toString() : '';
        // this.endDate = rawObj['indskr_enddate'] ? Utility.changeUTCDateToLocalDateWith0Time(new Date(rawObj['indskr_enddate@OData.Community.Display.V1.FormattedValue']).getTime(),true).getTime().toString() : '';
        this.startDate = rawObj['indskr_startdate'] ? new Date(rawObj['indskr_startdate@OData.Community.Display.V1.FormattedValue']).getTime().toString() : '';
        this.endDate = rawObj['indskr_enddate'] ? new Date(rawObj['indskr_enddate@OData.Community.Display.V1.FormattedValue']).getTime().toString() : '';
        this.state = rawObj['statecode'] || 0;
        this.statusCode = rawObj['statuscode'] || 1
        this.status = rawObj['statuscode'] && rawObj['statuscode'] == 548910000 ? 'Completed' : rawObj['statuscode'] && rawObj['statuscode'] == 2 ? 'Inactive': 'Open';
        this.strategy = rawObj['indskr_strategy'] || '';
        this.productId = rawObj['product.productid'];
        this.totalEmailGoals = rawObj['indskr_scientificplanmessagegoals'] || 0;
        this.totalMeetingGoals = rawObj['indskr_scientificplanmeetinggoals'] || 0;
        this.totalCompletedEmailGoals = rawObj['indskr_scientificplantotalmessages'] || 0;
        this.totalCompletedMeetingGoals = rawObj['indskr_scientificplantotalmeetings'] || 0;
        this.ownerID = rawObj['_ownerid_value'];
        this.createdon = rawObj['createdon'];
        this.lastUpdated = rawObj['modifiedon'];
        this.therapeuticArea = rawObj['therapeutic.indskr_therapeuticareaid'] || '';
        this.contactId = rawObj['contact.contactid'] || '';
        this.trackAction = rawObj['track_action@OData.Community.Display.V1.FormattedValue']? rawObj['track_action@OData.Community.Display.V1.FormattedValue'] : '';
        this.trackActionCreatedOn = rawObj['track_action_CreatedOn'] || '';
    }
}

// export class ScientificPlansUsers{
//     public userId:string;
//     public userName:string;
//     public scientificPlanId:string;

//     constructor(rawObj){
//         this.userId = rawObj['systemuserid'],
//         this.scientificPlanId = rawObj['indskr_scientificplanid'],
//         this.userName = rawObj['fullname'];
//     }

//     get ScientificPlanUserDTO(){
//         return{
//             'systemuserid' : this.userId,
//             'indskr_scientificplanid' : this.scientificPlanId,
//             'fullname' : this.userName
//         }
//     }
// }

export interface ScientificPlansUsers {
    ownerid: string;
    fullname: string;
    scientificPlanId?: string;
}
