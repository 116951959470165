<ion-header id="ph-capture-header">
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
</ion-header>

<ion-content #ionContent>
  <div class="iframe-container">
    <img [src]="selectedPhoto" />
  </div>
  <div class="carousel-container">
    <ind-section-header [viewData]="allPhotosSectionHeader"></ind-section-header>
    <div #carousel class="slides-container">
      <ion-slides #Slides [options]="{slidesPerView: slidesPerView}" class="swiper-wrapper">
        <ion-slide class="carousel-slide" (click)="selectImage(photo.indskr_photourl)" *ngFor="let photo of photos; let i = index">
          <img [ngClass]="{'active': selectedPhoto === photo.indskr_photourl, 'thumb-without-background': photo.isLoaded, 'thumb': !photo.isLoaded}"
          id="photo-thumbnail{{i}}" io-thumbnail [src]="photo.indskr_photourl" (load)="imgLoadCheck($event, photo, i)">
          <img *ngIf="!photoModel.readOnly && selectedPhoto === photo.indskr_photourl && photos.length > 1" src="assets/imgs/photo-delete.svg" class="delete-icon" (click)="deletePhoto(photo)" />
        </ion-slide>
      </ion-slides>
    </div>
  </div>
</ion-content>