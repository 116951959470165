export class ConfirmationAlertData {
    
    public message:string;
    public title:string;
    public cancelButtonText:string;
    public confirmButtonText:string;

    constructor(raw?:any){
        if(raw){
            this.message = raw['message'] ? raw['message'] : '';
            this.title = raw['title'] ? raw['title'] : '';
            this.cancelButtonText = raw['cancelButtonText'] ? raw['cancelButtonText'] : '';
            this.confirmButtonText = raw['confirmButtonText'] ? raw['confirmButtonText'] : '';
        }
    }
}