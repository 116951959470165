<!-- Generated template for the ProductKeyMessagesComponent component-->
<!-- <ion-item-divider>
    {{'PRODUCT_KEY_MESSGES' | translate}}
    <ion-button fill="clear" slot="end" (click)="saveProductKeyMessages($event)" [disabled]="!updateProductsFlag || activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting || enableReadonlyViewForMeetings">{{'SAVE' | translate}}</ion-button>
    <ion-button fill="clear" slot="end" *ngIf="(showCallNotesButton && !activityService.selectedActivity.isCompleted) && !enableReadonlyViewForMeetings" (click)="goToNotesAssistant()" [disabled]="isReadOnlyJointMeeting || device.isOffline">
        {{'VIEW' | translate}}
        <img width="15" class="geneeGreen" src="assets/imgs/ogeenee.svg">
    </ion-button>
</ion-item-divider> -->
<ind-section-header [viewData]="productsKeyMessagesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
<ion-reorder-group [ngClass]="{'empty-list':!tempProducts.length}" [disabled]="!(!activityService.selectedActivity.isCompleted && !isReadOnlyJointMeeting && !enableReadonlyViewForMeetings && !backgroundUploadInProgress && !activityService.selectedActivity?.isDiffPosition)" (ionItemReorder)="reorderData($event.detail)">
    <div *ngFor="let product of tempProducts">
        <ion-item *ngIf="tempProducts.length > 0">
            <!--THIS WILL BE A FIELD TO CHECK IF THE PRODUCT SHOULD BE SHOWN -->
            <div class="product-key-msg-wrapper">
                <div class="product-wrapper">
                    <!-- <h2> -->
                    <!-- [ngClass]="{'round':tester , 'round-checked': !tester}"The functionality in this component is going to be updated once we have a new set of data after content has been played-->
                    <div #roundInput [id]="product.ID" [ngClass]="{'round': (!activityService.selectedActivity.isCompleted  && !isReadOnlyJointMeeting && !enableReadonlyViewForMeetings && !backgroundUploadInProgress && !activityService.selectedActivity?.isDiffPosition),
          'round-disabled':(activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting || enableReadonlyViewForMeetings || backgroundUploadInProgress || activityService.selectedActivity?.isDiffPosition)}" (click)="updateProduct($event , product)">
                        <input [id]="product.ID" type="checkbox" [ngModel]="product.isSelected || product.isGenieSelected" />
                        <label [for]="product.ID" [ngClass]="{
                      'selected':product.isSelected && !product.isAutoSelected && !product.isGenieSelected,
                      'auto-selected': product.isAutoSelected,
                      'genie-selected' :hasCallNotesEnabled && product.isGenieSelected && !product.isAutoSelected}">
            </label>
                        <span style="margin-bottom:35px; margin-left: 15px;" size="25" class="key-message-text">{{product.name}}</span>
                        <img *ngIf="hasCallNotesEnabled && product.isGenieSelected && !product.isAutoSelected" width="15" class="geneeGreen" src="assets/imgs/ogeenee.svg">
                    </div>
                    <!-- </h2> -->
                </div>
                <div class="key-messages-wrapper" *ngIf="product.keyMessages && product.keyMessages.length != 0">
                    <!-- <div [id]="product.name" *ngIf="showKeyMessageArrowButtons && product.keyMessages.length != 0" (click)="slideDown($event , product.name)" class="swiper-button-prev"></div> -->
                    <div class="keyMessage-container">
                        <span *ngFor="let keyMessage of product.keyMessages" (click)="updatedHighlightedKeyMessage($event ,  product , keyMessage)" [attr.disabled]="activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting || enableReadonlyViewForMeetings || backgroundUploadInProgress || activityService.selectedActivity?.isDiffPosition" id="{{product.ID + '-' + keyMessage.ID}}"
                            class="customized-button" [ngClass]="{
                      'selected':keyMessage.isSelected && !keyMessage.isAutoSelected && !keyMessage.isGenieSelected,
                      'auto-selected': keyMessage.isAutoSelected,
                      'genie-selected': hasCallNotesEnabled && keyMessage.isGenieSelected && !product.isAutoSelected}">
                      {{keyMessage.name}}
                      <img width="10" class="geneeWhiteFont" *ngIf="hasCallNotesEnabled && keyMessage.isGenieSelected" src="assets/imgs/genee_white.svg">
                    </span>
                    </div>
                    <!-- <div [id]="product.name" *ngIf="showKeyMessageArrowButtons && product.keyMessages.length != 0" (click)="slideUp($event , product.name)" class="swiper-button-next"></div> -->
                </div>
            </div>

            <!-- <div class="key-messages-wrapper">
        <h2>
          <div #roundInput [id]="product.ID" [ngClass]="{'round': !activityService.selectedActivity.isCompleted , 'round-disabled':activityService.selectedActivity.isCompleted}" (click)="updateProduct($event , product)">
            <input [id]="product.ID" type="checkbox" [ngModel]="product.isSelected"/>
            <label [for]="product.ID" [ngClass]="{'auto-selected': product.isAutoSelected}" ></label>
            <span style="margin-bottom:35px; margin-left: 10px;" size="25" class="key-message-text">{{product.name}}</span>
          </div>
        </h2>
        <div *ngIf="!device.shouldBeMobileView">
        <ion-slides [id]="product.name" #slider [spaceBetween]="spaceBetween" [slidesPerView]="slidePerView" [speed]="0" (ionSlideReachEnd)="slideEnd($event.detail,$event)" (ionSlideDrag)="setCurrentSlider($event.detail,$event)">
          <div *ngFor="let keyMessage of product.keyMessages">
              <ion-slide *ngIf="keyMessage.name.length > 0" [ngClass]="{'override-slider-device':this.device.isOnAndroidDevice , 'override-slider-web': !this.device.isOnAndroidDevice}">
              <ion-row nowrap baselines>
                <ion-col col-1>
                <div (click)="updatedHighlightedKeyMessage($event ,  product , keyMessage)">
                  <button [disabled]= "activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting" [id]="assignUniqueIdToKeyMessages(product.ID ,keyMessage.ID)"
                          class="customized-button"
                          [ngClass]="{
                            'selected':keyMessage.isSelected && !keyMessage.isAutoSelected,
                            'auto-selected': keyMessage.isAutoSelected }">
                            {{keyMessage.name}}
                  </button>
                </div>
                </ion-col>
              </ion-row>
            </ion-slide>
          </div>
        </ion-slides>
        </div>
        <div *ngIf="device.shouldBeMobileView">
          <ion-slides  #slider [id]="product.name" [spaceBetween]="spaceBetween" [slidesPerView]="slidePerView" (ionSlideDidChange)="slideChanged()" (ionSlideDrag)="setCurrentSlider($event.detail,$event)">
            <div *ngFor="let keyMessage of product.keyMessages">
                <ion-slide *ngIf="keyMessage.name.length > 0" [ngClass]="{'override-slider-device':this.device.isOnAndroidDevice , 'override-slider-web': !this.device.isOnAndroidDevice}">
                <ion-row nowrap baselines>
                  <ion-col col-1>
                  <div (click)="updatedHighlightedKeyMessage($event ,  product , keyMessage)">
                    <button [disabled]= "activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting" [id]="assignUniqueIdToKeyMessages(product.ID ,keyMessage.ID)"
                            class="customized-button"
                            [ngClass]="{
                              'selected':keyMessage.isSelected && !keyMessage.isAutoSelected,
                              'auto-selected': keyMessage.isAutoSelected }">
                              {{keyMessage.name}}
                    </button>
                  </div>
                  </ion-col>
                </ion-row>
              </ion-slide>
            </div>
          </ion-slides>
        </div>
      </div> -->
            <ion-reorder slot="end"></ion-reorder>
        </ion-item>
    </div>
</ion-reorder-group>
