<ion-header>
    <ind-page-title [viewData]="phoneCallDetailsPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
    <business-process *ngIf="phonecallProcessView && phonecallProcessView.length >= 4" [viewData]="phonecallProcessView" [scroller]="scroller"></business-process>
</ion-header>
<!-- ion date for appConfigField(End Time) -->
<!-- <ion-item no-lines hidden="true">
    <ion-datetime #appConfigFieldDatePicker [displayFormat]="dateTimeFormatsService.dateTimeToUpper" (ionCancel)="updateConfigFieldDate(false)" [(ngModel)]="appConfigFieldDate" [pickerFormat]="dateTimeFormatsService.dateTimeToUpper" (ionChange)="updateConfigFieldDate(true)"
        [max]="maxDate" [monthShortNames]="shortMonth | monthTranslate" [doneText]="this.translate.instant('DONE')" [cancelText]="this.translate.instant('CANCEL')">
    </ion-datetime>
</ion-item> -->
<ion-content color="light" #scroller>
    <ng-container>
        <ion-list id="{{detailsSectionId}}">

            <ind-section-header [viewData]="callDetailsHeaderModel"></ind-section-header>

            <ind-form-field [viewData]="getScheduledByField()"></ind-form-field>

            <!-- status field -->
            <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>

            <!-- subject field -->
            <ind-form-field [viewData]="getSubjectFormField()"></ind-form-field>

            <!-- time field -->
            <ion-row>
              <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField" (onUpdate)="onTimeUpdated()"></ind-datetime-form></ion-col>
              <ion-col size="6"><ind-datetime-form *ngIf="startTimeField" [viewData]="startTimeField" (onUpdate)="onTimeUpdated()"></ind-datetime-form></ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField" (onUpdate)="onTimeUpdated()"></ind-datetime-form></ion-col>
              <ion-col size="6"><ind-datetime-form *ngIf="endTimeField" [viewData]="endTimeField" (onUpdate)="onTimeUpdated()"></ind-datetime-form></ion-col>
            </ion-row>
            <activity-conflict-alert-msg *ngIf="displayConflictMsg"></activity-conflict-alert-msg>

            <ind-form-field *ngIf='activityTypeFormField' [viewData]="activityTypeFormField"></ind-form-field>
            <ind-form-field *ngIf='activityTypeFormField' [viewData]="activitySubTypeFormField"></ind-form-field>

            <!-- accounts field -->
            <ind-form-field [viewData]="getAccountsField()"></ind-form-field>

            <!-- customers field -->
            <ind-form-field [viewData]="getCustomersField()"></ind-form-field>

            <!-- contact number field -->
            <ind-form-field [viewData]="getContactNumberField()"></ind-form-field>

            <!-- duration field -->
            <ind-form-field [viewData]="durationField"></ind-form-field>

            <!-- covisitor field -->
            <ind-form-field [viewData]="getCovisitorField()"></ind-form-field>

            <!-- appConfigFields -->
            <div *ngFor="let phoneConfigField of phoneCallConfigFields">
                <ind-form-field [viewData]="getPhoneCallFormField(phoneConfigField)"></ind-form-field>
            </div>
        </ion-list>
    </ng-container>
    <ng-container *ngIf="!durationField.isHidden">
      <ind-section-header id="{{callNotesSectionId}}" [viewData]="{id:'call-records', title: 'Recordings'}"></ind-section-header>

      <ion-list>
        <ion-item class="recording" *ngFor="let cr of callRecordings">
          <ion-label>{{cr.indskr_voicecallid}}</ion-label>
          <audio controls controlsList="nodownload noplaybackrate" [src]="cr.link"></audio>
        </ion-item>
      </ion-list>
    </ng-container>
    <phone-call-meeting-structure id="{{callStSectionId}}" (openNewActivityPage)="openNewActivityPage()" [backgroundUploadInProgress]="backgroundUploadInProgress"></phone-call-meeting-structure>
    <phone-call-product-key-message id="{{keyMessagesSectionId}}" [isReadOnlyJointPhoneCall]="isReadOnlyJointPhoneCall" [backgroundUploadInProgress]="backgroundUploadInProgress" [isProductRequired]="requiredFields['phone-call-product-key-messages']['isRequired'] && isNotReadOnly"></phone-call-product-key-message>
    <phone-call-therapeutic-area id="{{therapeuticSectionId}}" [isReadOnlyJointPhoneCall]="isReadOnlyJointPhoneCall" [ngClass]="{'notesemptysection':!isTherapeuticAreaEnabled}" *ngIf="isTherapeuticAreaEnabled" (saveTA)="onSaveTA($event)" [therapeuticAreas]="therapeuticAreas" 
    [backgroundUploadInProgress]="backgroundUploadInProgress" [isTARequired]="requiredFields['phone-call-therapeutic-area']['isRequired'] && isNotReadOnly">
    </phone-call-therapeutic-area>
    <ion-list *ngIf='isNotesEnabled'>

        <ind-section-header id="{{callNotesSectionId}}" [viewData]="{id:'notes', title:translate.instant('ACTIVITY_DETAILS_NOTES')}"></ind-section-header>

        <!-- <ion-item-divider> {{'ACTIVITY_DETAILS_NOTES' | translate}}
      <ion-button fill="clear" slot="end" (click)="saveNotes()" [disabled]="!isNotesDirty || activityService.selectedActivity &&  activityService.selectedActivity?.isCompleted" [ngClass]="(!isThisPhoneCallEditable())?'closed-status':'open-status'">{{'SAVE' | translate}}</ion-button>
      <ion-button fill="clear" slot="end" (click)="cancelNotes()"  [disabled]="!isNotesDirty || activityService.selectedActivity && activityService.selectedActivity?.isCompleted" [ngClass]="(!isThisPhoneCallEditable())?'closed-status':'open-status'">{{'CANCEL' | translate}}</ion-button>
    </ion-item-divider> -->
        <ind-text-area [placeHolder]="'ENTER_NOTES' | translate" [disabled]="isReadOnlyJointPhoneCall" [maxLength]="1000" (indFocus)="onNotesFocus()" (indBlur)="saveNotes()" [(value)]="activityService.selectedActivity && activityService.selectedActivity.notes"
            [rows]="4" [cols]="10" [disabled]='!isThisPhoneCallEditable()'>
        </ind-text-area>
    </ion-list>
    <ion-list id="phone-call-detail-12" *ngIf='isCoachingEnabled && !activityService.teamViewActive'>
        <ind-section-header id="{{coachingSectionId}}" [viewData]="{id:'phonecall-detail-coaching', title:translate.instant('COACHING')}"></ind-section-header>
        <ion-list *ngIf="coachingReportService.coachingReports.length == 0">
            <ion-item style="height: 88px;">
            </ion-item>
        </ion-list>
        <ion-list *ngIf="coachingReportService.coachingReports">
            <ion-item *ngFor="let report of coachingReportService.coachingReports | orderBy:'createdon':false" (click)="openCoachingReportDetails(report)">
                <coaching-item [report]="report" [type]="'detailView'"></coaching-item>
                <ion-icon class="arrow-icon" [ngClass]="{'disabled':enableCoachingView}" style="height:16px; width:16px; color:#007AFF" name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-item>
        </ion-list>
    </ion-list>
    <ion-list *ngIf='isCallObjectivesEnabled'>
        <ion-item-divider id="{{callObjectiveSectionId}}">
            {{'ACTIVITY_DETAILS_CALLOBJECTIVES' | translate}}
            <ion-button fill="clear" slot="end" (click)="saveCallObjectives()" [ngClass]="(!isThisPhoneCallEditable())?'closed-status':'open-status'">{{'SAVE' | translate}}</ion-button>
            <ion-button fill="clear" slot="end" (click)="cancelCallObjectives()" [ngClass]="(!isThisPhoneCallEditable())?'closed-status':'open-status'">{{'CANCEL' | translate}}</ion-button>
        </ion-item-divider>
        <ind-text-area [placeHolder]="'ENTER_CALLOBJECTIVES' | translate" [maxLength]="1000" (indFocus)="onCallObjectivesFocus()" (indChange)="CallObjectivesChanged()" [rows]="4" [cols]="10" [disabled]='!isThisPhoneCallEditable()'>
        </ind-text-area>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'phonecall'" (buttonClicked)="onFooterButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->