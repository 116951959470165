import { Injectable } from '@angular/core';
import { DiskService } from '../disk/disk.service';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from '../../config/pouch-db.config';
import { AuthenticationService } from '../authentication.service';
import { Endpoints } from 'src/config/endpoints.config';
import { CONTACT_FETCH_QUERIES } from '@omni/config/fetch-xml/contact-fetchXMLs';
import { differenceInHours } from 'date-fns';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { DeviceService } from '../device/device.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DeltaService, EntityNames, EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { HttpClient } from '@angular/common/http';
import { ContactMedicalInsight } from '@omni/classes/contact/contact.class';
import { BehaviorSubject } from 'rxjs';
import { SearchConfigService } from '../search/search-config.service';
import _, {isEmpty} from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class MedicalInsightService {

  public selectedMedicalInsight: ContactMedicalInsight;
  public myMedicalInsights: Array<ContactMedicalInsight> = [];
  public isInsightCreateInProgress: boolean = false;

  private newMedicalInsight$ = new BehaviorSubject<ContactMedicalInsight>(null);
  public newMedicalInsightObservable = this.newMedicalInsight$.asObservable();

  constructor(
    private disk: DiskService,
    private readonly authenticationService: AuthenticationService,
    private readonly dynamics: DynamicsClientService,
    private readonly deviceService: DeviceService,
    private readonly deltaService: DeltaService,
    private readonly http: HttpClient,
    private searchConfigService: SearchConfigService,
  ) {
  }

  public set newMedicalInsight(value: ContactMedicalInsight) {
    this.newMedicalInsight$.next(value);
  }

  public async syncMyMedicalInsights(fullSync?: boolean, loadFromDBOnly = false) {
    if (!this.deviceService.isOffline && this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MEDICAL_INSIGHT)) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CUSTOMER_MEDICAL_INSIGHTS);
      //let offlineDataCount: number = 0;

      let lastUpdatedTime;
      if (offlineData && offlineData.raw && !fullSync) {
        if (offlineData.lastUpdatedTime) {
          lastUpdatedTime = offlineData.lastUpdatedTime;
        }
        //offlineDataCount = offlineData.raw.filter(o => o && o.hasOwnProperty('pendingPushToDynamics') && o.pendingPushToDynamics === true).length;
      }

      //this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, offlineDataCount);

      if (!loadFromDBOnly) {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MEDICAL_INSIGHTS);
        const bulkMedicalInsightsSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.medical_insights,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        const now = new Date().getTime();
        let fetchXML = CONTACT_FETCH_QUERIES.myMedicalInsights;
        fetchXML = fetchXML.replace("{userId}", this.authenticationService.user.xSystemUserID);

        let positionIds = this.authenticationService.user.positions.map(o => o.ID);
        let positionString = '';
        positionIds.forEach(p => {
          positionString += '<value>' + p + '</value>'
        })

        let childFetchXML = CONTACT_FETCH_QUERIES.childMedicalInsights.split('{parentPosition}').join(positionString);


  
        if (lastUpdatedTime && !fullSync) {
          let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
          fetchXML = fetchXML.replace('<condition attribute="statecode" operator="eq" value="0" />','');
          childFetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
          childFetchXML = childFetchXML.replace('<condition attribute="statecode" operator="eq" value="0" />','');
        } else {
          fetchXML = fetchXML.replace('<condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />', '');
          childFetchXML = childFetchXML.replace('<condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />', '');
        }

        let response:any= await Promise.all([
          this.dynamics.executeFetchQuery('indskr_customerinteractioninsightses', fetchXML),
          this.dynamics.executeFetchQuery('indskr_customerinteractioninsightses', childFetchXML),
        ]);

        response = _.flatten(response);

        lastUpdatedTime = new Date().getTime();
        if (response && Array.isArray(response) && response.length > 0) {
          bulkMedicalInsightsSyncInfo.totalSynced += response.length;
          if (offlineData && offlineData.raw && !fullSync) {
            response.forEach(item => {
              let idx = offlineData.raw.findIndex(a => a.indskr_customerinteractioninsightsid == item.indskr_customerinteractioninsightsid);
              if (idx >= 0) {
                if(item.statecode == 0){
                  offlineData.raw[idx] = item;
                }else{
                  offlineData.raw.splice(idx,1);
                }
              } else {
                if(item.statecode == 0){
                  offlineData.raw.push(item);
                }
              }
            });
            offlineData.lastUpdatedTime = lastUpdatedTime;
          } else {
            offlineData = {
              raw: response,
              lastUpdatedTime: lastUpdatedTime,
            }
          }
          syncState.lastUpdatedTime = lastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          await this.deltaService.addEntitySyncInfo(bulkMedicalInsightsSyncInfo);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_MEDICAL_INSIGHTS, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save medical insights data in offline db error: ', error));
        }
      }
    }
  }

  public async getMyMedicalInsightDataOffline() {
    let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CUSTOMER_MEDICAL_INSIGHTS);
    if(offlineData && offlineData.raw && Array.isArray(offlineData.raw)){
      this.myMedicalInsights = [];
      offlineData.raw.forEach(item => {
        try {
          // if(item['_ownerid_value'] == this.authenticationService.user.xSystemUserID){
            let medicalInsight = new ContactMedicalInsight(item);
            this.myMedicalInsights.push(medicalInsight);
          // }
        } catch (error) {
          console.error("Invalid Medical Insight Data Error: "+error);
        }
      });
      this.myMedicalInsights.sort((a,b)=> a.dateCreated.getTime() - b.dateCreated.getTime());
    }
  }

  public async saveNewMedicalInsight(medicalInsight:ContactMedicalInsight):Promise<any> {
    let res = await this.saveContactMedicalInsightsOnline(medicalInsight);
    if (res && res['indskr_customerinteractioninsightsid']) {
      medicalInsight.ID = res['indskr_customerinteractioninsightsid'];
      // Save in db
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CUSTOMER_MEDICAL_INSIGHTS);
      if (offlineData && offlineData.raw && Array.isArray(offlineData.raw) && offlineData.raw.length != 0) {
        let idx = offlineData.raw.findIndex(insight => insight['indskr_customerinteractioninsightsid'] == medicalInsight.ID);
        if (idx >= 0) {
          offlineData.raw[idx] = medicalInsight.getOfflineDataDTO();
        } else {
          offlineData.raw.push(medicalInsight.getOfflineDataDTO());
        }
      } else {
        offlineData = {
          raw: [medicalInsight.getOfflineDataDTO()],
        };
      }
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_MEDICAL_INSIGHTS, doc => {
        doc = {
          raw: offlineData.raw,
        };
        return doc;
      }).catch(error => console.error('Save Default LE to DB error: ', error));

      //Add to local
      let idx = this.myMedicalInsights.findIndex(a => a.ID == medicalInsight.ID);
      if (idx >= 0) {
        this.myMedicalInsights[idx] = medicalInsight;
      } else {
        this.myMedicalInsights.push(medicalInsight);
      }
    }
  }

  private saveContactMedicalInsightsOnline(medicalInsight: ContactMedicalInsight): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let headers = Endpoints.headers.content_type.json;
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.SAVE_CONTACT_MEDICAL_INSIGHT;
      const payload = medicalInsight.getServiceDTO();
      let response = await this.http.put(url, payload, headers).toPromise().catch(err=>{
        reject(err);
      });
      resolve(response);
    });
  }

  public mapMedicalInsightsToSearchIndex(medicalInsights : ContactMedicalInsight[]) {
    medicalInsights.forEach(async medicalInsight => {
      if (!this.searchConfigService.isConfigInitiated) {
        this.searchConfigService.initSearchConfigs();
        this.searchConfigService.isConfigInitiated = true;
      }
      // medicalInsight.forEach(p => {
        if (medicalInsight.ownerId && !this.searchConfigService.medicalInsightsSearchIndexsConfig.find(config => config.categoryName == 'Owners').values.some(o => o == medicalInsight.ownerNameString))
          this.searchConfigService.medicalInsightsSearchIndexsConfig.find(config => config.categoryName == 'Owners').values.push(medicalInsight.ownerNameString);
    //   })

    //   if (newCP.customerName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values.some(o => o == newCP.customerName)) {
    //     this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values.push(newCP.customerName);
    //   }
    //   let accounts = this.contactService.getContactByID(newCP.contactId)?.accountRelationships || []
    //   accounts.forEach(account => {
    //     if (account.accountName.length > 0 && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values.some(o => o == account.accountName))
    //       this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values.push(account.accountName);
    //   })
    //   newCP.repPlans.forEach(sp => {
    //     if (sp.specialtyid_Formatted && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values.some(o => o == sp.specialtyid_Formatted))
    //       this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values.push(sp.specialtyid_Formatted);
      // })
    })
  }

}