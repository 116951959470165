/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { DeviceService } from '../device/device.service';
import { AuthenticationService } from '../authentication.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService } from '../disk/disk.service';
import { VeevaConfiguration } from '@omni/classes/authentication/veeva.configuration';
import { VeevaWidgetConfig } from '@omni/models/veeva.widget.config';
import { ModalController } from '@ionic/angular';
import { ContactVeevaWidgetComponent } from '@omni/components/contact/contact-veeva-widget/contact-veeva-widget';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from '../global-utility.service';

let OT;


@Injectable({
  providedIn: 'root',
})
export class VeevaWidgetService {
  config: VeevaConfiguration;
  get widgetConfig(): VeevaWidgetConfig {
    return {
      scriptsLoaded: this.scriptsLoaded,
      type: this.widgetType,
      title: this.widgetTitle,
      data: this.widgetData,
    }
  }
  private scriptsLoaded = false;
  widgetTitle: string;
  widgetType: 'VEEVA_ACCOUNT_SEARCH' | 'VEEVA_CONTACT_SEARCH' | 'VEEVA_PROFILE' | 'VEEVA_MY_REQUESTS';
  widgetData: any;
  private searchEnabled = false;
  private myRequestsEnabled = false;
  private selectionEnabled = false;
  private manifestScriptTag: HTMLScriptElement;
  private myRequestsScriptTag: HTMLScriptElement;
  private profileScriptTag: HTMLScriptElement;
  private searchScriptTag: HTMLScriptElement;
  constructor(
    private authService: AuthenticationService,
    private dynamics: DynamicsClientService,
    private disk: DiskService,
    private device: DeviceService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private utilityService: GlobalUtilityService,
  ) {
    this.searchEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_GLOBAL_SEARCH) || this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_GLOBAL_SEARCH);
    this.myRequestsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_MY_REQUESTS) || this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_MY_REQUESTS);
    this.selectionEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_SELECTION) || this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_SELECTION);
  }

  async fetchConfig(loadFromDbOnly = false) {
    try {
      let fallbackConfig = this.authService.userConfig.activeInstance.veevaConfig;
      if (this.searchEnabled || this.myRequestsEnabled || this.selectionEnabled) {
        if (loadFromDbOnly) {
          const dbData = await this.disk.retrieve(
            DB_KEY_PREFIXES.VEEVA_WIDGET_CONFIG,
            true
          );
          if (dbData && dbData.raw) {
            this.config = dbData.raw;
          }
        } else {
          const widgetProps = ['omniveev_widgetname', 'omniveev_widgetid', 'omniveev_authdomain', 'omniveev_widgetscripturl'];
          const configFromBU = await this.dynamics.dwa.retrieveRequest({
            collection: 'businessunits',
            key: this.authService.user.xBusinessUnitId,
            expand: [
              {
                property: 'omniveev_veevaprofilewidget',
                select: widgetProps
              },
              {
                property: 'omniveev_veevamyrequestswidget',
                select: widgetProps
              },
              {
                property: 'omniveev_veevasearchwidget',
                select: widgetProps
              },
              {
                property: 'omniveev_veevahcosearchwidget',
                select: widgetProps
              },
              {
                property: 'omniveev_veevahcpsearchwidget',
                select: widgetProps
              },
            ],
            select: ['omniveev_veevamanifestscripturl', 'omniveev_syneosveevanetworkfingerprint']
          });
          this.config = {
            ...fallbackConfig,
            veevaNetworkFingerprint: configFromBU?.omniveev_syneosveevanetworkfingerprint || fallbackConfig.veevaNetworkFingerprint,
            manifestScriptUrl: configFromBU?.omniveev_veevamanifestscripturl || fallbackConfig.manifestScriptUrl,
            myRequestsWidgetId: configFromBU?.omniveev_veevamyrequestswidget?.omniveev_widgetid || fallbackConfig.myRequestsWidgetId,
            myRequestsWidgetName: configFromBU?.omniveev_veevamyrequestswidget?.omniveev_widgetname || fallbackConfig.myRequestsWidgetName,
            myRequestsWidgetScriptUrl: configFromBU?.omniveev_veevamyrequestswidget?.omniveev_widgetscripturl || fallbackConfig.myRequestsWidgetScriptUrl,
            myRequestsWidgetAuthDomain: configFromBU?.omniveev_veevamyrequestswidget?.omniveev_authdomain || fallbackConfig.authDomain,
            searchWidgetId: configFromBU?.omniveev_veevasearchwidget?.omniveev_widgetid || fallbackConfig.searchWidgetId,
            searchWidgetName: configFromBU?.omniveev_veevasearchwidget?.omniveev_widgetname || fallbackConfig.searchWidgetName,
            searchWidgetScriptUrl: configFromBU?.omniveev_veevasearchwidget?.omniveev_widgetscripturl || fallbackConfig.searchWidgetScriptUrl,
            searchWidgetAuthDomain: configFromBU?.omniveev_veevasearchwidget?.omniveev_authdomain || fallbackConfig.authDomain,
            profileWidgetId: configFromBU?.omniveev_veevaprofilewidget?.omniveev_widgetid || fallbackConfig.profileWidgetId,
            profileWidgetName: configFromBU?.omniveev_veevaprofilewidget?.omniveev_widgetname || fallbackConfig.profileWidgetName,
            profileWidgetScriptUrl: configFromBU?.omniveev_veevaprofilewidget?.omniveev_widgetscripturl || fallbackConfig.profileWidgetScriptUrl,
            profileWidgetAuthDomain: configFromBU?.omniveev_veevaprofilewidget?.omniveev_authdomain || fallbackConfig.authDomain,
          };
          this.config = {
            ...this.config,
            hcoSearchWidgetId: configFromBU?.omniveev_veevahcosearchwidget?.omniveev_widgetid || this.config.searchWidgetId,
            hcoSearchWidgetName: configFromBU?.omniveev_veevahcosearchwidget?.omniveev_widgetname || this.config.searchWidgetName,
            hcoSearchWidgetScriptUrl: configFromBU?.omniveev_veevahcosearchwidget?.omniveev_widgetscripturl || this.config.searchWidgetScriptUrl,
            hcoSearchWidgetAuthDomain: configFromBU?.omniveev_veevahcosearchwidget?.omniveev_authdomain || this.config.authDomain,
            hcpSearchWidgetId: configFromBU?.omniveev_veevahcpsearchwidget?.omniveev_widgetid || this.config.searchWidgetId,
            hcpSearchWidgetName: configFromBU?.omniveev_veevahcpsearchwidget?.omniveev_widgetname || this.config.searchWidgetName,
            hcpSearchWidgetScriptUrl: configFromBU?.omniveev_veevahcpsearchwidget?.omniveev_widgetscripturl || this.config.searchWidgetScriptUrl,
            hcpSearchWidgetAuthDomain: configFromBU?.omniveev_veevahcpsearchwidget?.omniveev_authdomain || this.config.authDomain,
          }
          this.disk.updateOrInsert(
            DB_KEY_PREFIXES.VEEVA_WIDGET_CONFIG,
            (doc) => ({ raw: this.config })
          );
        }
      }
      if (this.searchEnabled || this.myRequestsEnabled || this.selectionEnabled) {
        if (!this.manifestScriptTag) {
          this.manifestScriptTag = this.createScriptTag(this.config.manifestScriptUrl);
        }
        if (!this.profileScriptTag) {
          this.profileScriptTag = this.createScriptTag(this.config.profileWidgetScriptUrl);
        }
        if (!this.myRequestsScriptTag) {
          this.myRequestsScriptTag = this.createScriptTag(this.config.myRequestsWidgetScriptUrl);
        }
      }
      if (this.searchEnabled) {
        if (!this.searchScriptTag) {
          this.searchScriptTag = this.createScriptTag(this.config.hcoSearchWidgetScriptUrl || this.config.hcpSearchWidgetScriptUrl || this.config.searchWidgetScriptUrl);
        }
      }
      // if (this.searchEnabled) {
      //   if (!this.myRequestsScriptTag) {
      //     this.myRequestsScriptTag = this.createScriptTag(this.config.myRequestsWidgetScriptUrl);
      //   }
      // }
      this.scriptsLoaded = true;
    } catch (error) {
      console.log('error in Veeva API config service', error);
    }
  }
  private createScriptTag(url: string) {
    if (!url) return;
    const tag = document.createElement('script');
    tag.src = url;
    document.head.appendChild(tag);
    return tag;
  }

  private openExternal(type: string, params: string[][]) {
    let activeInstance = this.authService.userConfig.activeInstance;
    window.open(`${activeInstance.OmnipresenceWfeUrl}/veeva/${type}?${params.map(p => (`${p[0]}=${p[1]}`)).join('&')}`, '_system');
  }

  async openProfileWidget(identifier: string, title: string) {
    if (this.device.isOffline) return;
    if (this.device.deviceFlags.ios) {
      const veevaConfig = this.config;
      const params = [
        ['auth-domain', veevaConfig.authDomain],
        ['manifest-url', veevaConfig.manifestScriptUrl],
        ['widget-name', veevaConfig.profileWidgetName],
        ['widget-url', veevaConfig.profileWidgetScriptUrl],
        ['widget-id', veevaConfig.profileWidgetId],
        ['identifier', identifier],
      ]
      this.openExternal('profile', params);
      return;
    }

    this.widgetType = "VEEVA_PROFILE";
    this.widgetTitle = title;
    this.widgetData = identifier;
    //device is offline return

    const modal = await this.modalCtrl.create({ component: ContactVeevaWidgetComponent },);
    await modal.present();
    if (this.device.isMobileDevice) this.device.lockDeviceOrientationLandscape();
  }

  async openSearchHCOWidget() {
    if (this.device.isOffline) return;
    if (this.device.deviceFlags.ios) {
      const veevaConfig = this.config;
      const params = [
        ['auth-domain', veevaConfig.authDomain],
        ['manifest-url', veevaConfig.manifestScriptUrl],
        ['widget-name', veevaConfig.hcoSearchWidgetName],
        ['widget-url', veevaConfig.hcoSearchWidgetScriptUrl],
        ['widget-id', veevaConfig.hcoSearchWidgetId],
      ]
      this.openExternal('hco-search', params);
      return;
    }

    this.widgetTitle = this.translate.instant('ACCOUNTS');
    this.widgetType = "VEEVA_ACCOUNT_SEARCH";
    const modal = await this.modalCtrl.create({ component: ContactVeevaWidgetComponent });
    await modal.present();
    if (this.device.isMobileDevice) this.device.lockDeviceOrientationLandscape();
  }

  async openSearchHCPWidget() {
    if (this.device.isOffline) return;
    if (this.device.deviceFlags.ios) {
      const veevaConfig = this.config;
      const params = [
        ['auth-domain', veevaConfig.authDomain],
        ['manifest-url', veevaConfig.manifestScriptUrl],
        ['widget-name', veevaConfig.hcpSearchWidgetName],
        ['widget-url', veevaConfig.hcpSearchWidgetScriptUrl],
        ['widget-id', veevaConfig.hcpSearchWidgetId],
      ]
      this.openExternal('hcp-search', params);
      return;
    }

    this.widgetTitle = this.translate.instant('XPERIENCES_TRENDING_CUSTOMERS', { globalCustomerText: this.utilityService.globalCustomerText });
    this.widgetType = "VEEVA_CONTACT_SEARCH";

   if (this.device.deviceFlags.ios) {
     // return this.loadIOSWidget();
   }
   const modal = await this.modalCtrl.create({component: ContactVeevaWidgetComponent});
   await modal.present()
    if (this.device.isMobileDevice) this.device.lockDeviceOrientationLandscape();
  }

  async openMyRequestsWidget() {
    if (this.device.isOffline) return;
    if (this.device.deviceFlags.ios) {
      const veevaConfig = this.config;
      const params = [
        ['auth-domain', veevaConfig.authDomain],
        ['manifest-url', veevaConfig.manifestScriptUrl],
        ['widget-name', veevaConfig.myRequestsWidgetName],
        ['widget-url', veevaConfig.myRequestsWidgetScriptUrl],
        ['widget-id', veevaConfig.myRequestsWidgetId]
      ]
      this.openExternal('my-requests', params);
      return;
    }

    this.widgetTitle = this.translate.instant('MY_VEEVA_CHANGE_REQUESTS');
    this.widgetType = "VEEVA_MY_REQUESTS";

    // setTimeout(() => {
    //     document.getElementById('searchWidget').addEventListener('veeva-network:OmnipresenceSearchWidget:select', (event) => this.createVeevaEntity(event));
    // }, 2000);
    const modal = await this.modalCtrl.create({ component: ContactVeevaWidgetComponent });
    await modal.present();
    if (this.device.isMobileDevice) this.device.lockDeviceOrientationLandscape();
  }
}
