import { Component, OnInit, ViewChild, HostBinding, Injector } from '@angular/core';
import { Presentation, Page, SwipeStatus } from '../../classes/presentation/presentation.class';
import { PresentationService } from '../../services/presentation/presentation.service';
import { FooterViews, FooterService } from '../../services/footer/footer.service';
import { NavParams } from '@ionic/angular';
import { Events } from '@omni/events'
import { PresentationView } from '../../services/ui/ui.service'
import { Subscription } from 'rxjs';
import { IOIframe } from '../../components/io-component/io-iframe/io-iframe';
import { IoEventListener } from '../../services/io-event-listener.decorator';
import { DeviceService } from '../../services/device/device.service';
import { Resource } from '../../classes/resource/resource.class';
import { PageName } from '../../services/navigation/navigation.service';
import { EventsService } from '@omni/services/events/events.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Component({
  selector: 'presentation-fullscreen[base-page]',
  templateUrl: 'presentation-fullscreen.html',
  styleUrls:['presentation-fullscreen.scss']
})
export class PresentationFullscreenComponent implements OnInit {

    //@ViewChild(Content, {static: true}) content: Content;
    @ViewChild('carousel', {static: true}) carousel: any;
    @HostBinding('class.carousel-hidden') get hideCarousel() {
        return this.presentationService.hideCarousel && !this.presentationService.isReferenceVisible;
    }

    @ViewChild(IOIframe, {static: true}) iframe: IOIframe;

    public viewMode: PresentationView;

    public presList: (Presentation | Resource)[];
    public presData: Presentation | Resource;
    public selectedPageUrl: string;
    public selectedPage: Page;

    public presMode = PresentationView;

    private curSelPresSubscription: Subscription;
    private curSelPageSubscription: Subscription;
    private carouselSubscription: Subscription;
    public pageName = PageName;
    private subscriptions: { unsubscribe: Function }[] = []

    constructor (
        public injector: Injector,
        public presentationService:PresentationService,
        public footerService:FooterService,
        public deviceService: DeviceService,
        private events: EventsService,
        private keyboard: Keyboard,
    ) {
        this.viewMode = this.presentationService.viewMode;
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
        this.subscriptions.push(this.keyboard.onKeyboardDidShow().pipe(debounceTime(100)).subscribe(() => {
          this.presentationService.hideCarousel = true;
          this.footerService.showButton('presentationcarousel');
          this.footerService.hideButton('presentationcarouselactive');
          this.events.publish("footer-toolbar:carousel-toggle");
        }));
    }

    ngOnInit() {

        this.carouselSubscription = this.presentationService.carouselBriefcase.subscribe(pres => {
            this.presList = pres;
        });

        this.curSelPresSubscription = this.presentationService.currentSelectedPres.subscribe(pres => {
            this.presData = pres;
            if(this.presData) {
                this.selectedPageUrl = this.presentationService.activePresPage ? this.presentationService.activePresPage.pageUrl : this.presData['presentationPages'][0].pageUrl;
                this.carousel.updateCarousel();
            }
        });

        this.curSelPageSubscription = this.presentationService.currentSelectedPresPage.subscribe(page => {

            this.selectedPage = page;
            this.selectedPageUrl = page ? page.pageUrl : this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0].pageUrl : "";

        });
        this.events.subscribe('footer-toolbar:carousel-toggle', () => {
            this.iframe.resizeIframe();
        });
    }
    @IoEventListener("window:message", message => message.type === 'io-spa' && message.action === 'page-init', message => [message.data])
    onSlidePageInit(initData) {
        this.footerService.updateSwipeButtonState(this.pageName.PresentationFullscreenComponent);
        this.events.publish('footerButtonsUpdated', this.pageName.PresentationFullscreenComponent);
    }

    ngOnDestroy() {
        this.presentationService.hideCarousel = false;
        this.curSelPresSubscription.unsubscribe();
        this.curSelPageSubscription.unsubscribe();
        this.carouselSubscription.unsubscribe();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ionViewDidEnter() {
        this.footerService.initButtons(FooterViews.PresentationFullscreen);
        this.iframe.resizeIframe();
    }

}
