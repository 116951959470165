<ion-item lines="{{inputData.showLines ? '' : 'none' }}" class="display-input">
  <ion-label class="ion-text-wrap">
    <ion-text class="small-label">
      <h3>{{ inputData.label }}
        <ion-icon *ngIf="inputData.labelIcon" [src]="inputData.labelIcon" class="vertical-middle"
          [color]="inputData.labelIconColor" tooltip="{{'SEEN' | translate}}"></ion-icon>
      </h3>
    </ion-text>
    <ion-text class="value-text">
      <p>
        <ion-text *ngIf="inputData.value; else skeletonA" class="value-text-span">
          {{ inputData.value }}
        </ion-text>
        <ng-template #skeletonA>
          <ion-text *ngIf="inputData.placeholderText; else skeletonB" class="value-text-span" class="placeholder-text">
            {{ inputData.placeholderText }}
          </ion-text>
        </ng-template>
        <ng-template #skeletonB>
          <ion-text class="value-text-span" class="placeholder-text">
            {{ "NO" | translate }} {{ inputData.label }}
          </ion-text>
        </ng-template>
        <span *ngIf="inputData.openDetailIcon" >
          <ion-icon class="drop-icon" [src]="inputData.openDetailIcon" (click)="openInputDetails(inputData)"></ion-icon>
        </span>
        <span *ngIf="showPopover">({{ inputData.valuePopupText }}
          <ion-icon class="drop-icon" name="chevron-down-outline" (click)="openPopup($event)"></ion-icon>)
        </span>
      </p>
    </ion-text>
  </ion-label>
</ion-item>