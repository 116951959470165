import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { ChangeDetectorRef, Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MyAssistantService, NOTIFICATION } from "@omni/services/my-assistant/my-assistant.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { IndNotificationDataModel } from "@omni/models/indNotificationDataModel";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { Observable, Subject, Subscription } from "rxjs";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import _ from "lodash";
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { Account } from '@omni/classes/account/account.class';
import { DatePipe } from '@angular/common';
import { SafeUrl } from '@angular/platform-browser';
import { SafePipe } from '@omni/pipes/safe.pipe';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { MdmService } from '@omni/services/mdm/mdm.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { Model } from 'survey-angular';
import { Notification } from 'electron';
import { DynamicForm, FormType, BusinessProcessType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { CrDetailsComponent } from '@omni/components/mdm/contact/cr-details/cr-details';
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { ChangeRequestStatusCode } from '@omni/classes/mdm/source-type-optionset.class';
import { ContactDetailsComponent } from '@omni/components/contact/contact-details/contact-details';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AccountCrDetailsComponent } from '@omni/components/mdm/account/account-cr-details/account-cr-details';
import { AccountDetailsComponent } from '@omni/components/account/account-details/account-details';
import { TerritoryManagementDataService } from '@omni/data-services/territory-management/territory-management.data.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { CustomerPositionList, StatusCode } from '@omni/classes/territory-management-list/customer-position-list.class';
import { TerritoryManagementListComponent, TerritoryManagementTabs } from '@omni/components/territory-management/territory-management-list/territory-management-list';
import { TerritoryManagementDetailsComponent } from '@omni/components/territory-management/territory-management-details/territory-management-details';
import { takeUntil } from 'rxjs/operators';
import { TerritoryManangementListDetailsComponent } from '@omni/components/territory-management/territory-manangement-list-details/territory-manangement-list-details';
import { AppealDetailsComponent } from '@omni/components/appeal-details/appeal-details.component';
import { AppealDataService } from '@omni/data-services/appeal/appeal.data.service';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ResourceService } from '@omni/services/resource/resource.service';
import { fileState } from '@omni/store/application.state';
import { TrackService, TrackingEventNames } from '@omni/services/logging/tracking.service';
import { Store } from '@ngrx/store';
import { DeviceService } from '@omni/services/device/device.service';
import * as ResourceAction from '../../../store/io-file-service/actions/resource.actions';
import { PresentationDataService } from '@omni/data-services/presentation/presentation.data.service';
import * as FileAction from '../../../store/io-file-service/actions/file.actions';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { selectIsDownloading, selectIsUnZipping } from '../../../store/io-file-service/states/file.selectors';

@Component({
  selector: 'notification-details',
  templateUrl: 'notification-details.html',
  styleUrls: ['notification-details.scss'],
  providers: [SafePipe]
})
export class NotificationDetailsComponent {

  selectedNotification: IndNotificationDataModel = null;
  notification: any;
  newData: any[] = [];
  private IcurrentNotifSubscription: Subscription;
  notificationDetailsPageTitle: IndPageTitleViewDataModel;
  headerData: IndSectionHeaderViewDataModel = {
    id: 'notification-scan-header',
    title: this.translate.instant('DETAILS_CAP'),
    controls: [
    ],
  };

  IcontactCRsSubscription: any;
  contactCRs: any[];
  filteredContactCRs: any[];
  accountContactDCR: Contact | Account;
  private ngDestroy$: any = new Subject<boolean>();
  public selectedCustomerPositionList: CustomerPositionList;

  public isDownloading$: Observable<boolean>;
  public isUnzipping$: Observable<boolean>;

  public progressPer: number = 0;
  private dlProgressSubscription: Subscription;
  private unzipProgressSubscription: Subscription;

  constructor(
    public uiService: UIService,
    public translate: TranslateService,
    public myAssistantService: MyAssistantService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public navService: NavigationService,
    private footerService: FooterService,
    private contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    private mdmService: MdmService,
    private secondaryInfoService: SecInfoConfigDataService,
    private datePipe: DatePipe,
    private safePipe: SafePipe,
    private readonly notificationService: NotificationService,
    private _cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    private dynamicFormsService: DynamicFormsService,
    private territoryDataService: TerritoryManagementDataService,
    private territoryService: TerritoryManagementService,
    private appealDataService: AppealDataService,
    private activityDataService: ActivityDataService,
    private activityService: ActivityService,
    public resourceService: ResourceService,
    public store: Store<fileState>,
    public trackingService: TrackService,
    public deviceService: DeviceService,
    private presDataService: PresentationDataService,
    private cdRef: ChangeDetectorRef,
    private ioFileDownload: IoFileService,
  ) { }

  ngOnInit() {
    this.IcurrentNotifSubscription = this.myAssistantService.selectedNotificationObs$.subscribe((model: IndNotificationDataModel) => {
      if (model) {
        this.selectedNotification = model;
        this.notification = model.data.data;
        this.initData(model);
        this.initHeaderData(model);
        this.initNotificationDetailsPageTitle();
      }
    });
    this.footerService.initButtons(FooterViews.None);

    this.dlProgressSubscription = this.ioFileDownload.dlProgress$.subscribe(per => {
      this.progressPer = per;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
    this.unzipProgressSubscription = this.ioFileDownload.unzipProgress$.subscribe(per => {
      this.progressPer = per;
    });

    this.isDownloading$ = this.store.select(selectIsDownloading);
    this.isUnzipping$ = this.store.select(selectIsUnZipping);
  }

  initHeaderData(model) {
    switch (model.type) {
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
        this.headerData.title = `${this.translate.instant('NEW')} ${this.translate.instant('CONTACT_CUSTOMER_DETAILS')}`;
        break;
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
        this.headerData.title = this.translate.instant('ACCOUNT_DETAILS');
        break;
      case NOTIFICATION.DCR_FOR_APPROVAL:
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
        this.headerData.title = this.translate.instant('CHANGE_REQUEST_HEADER_DETAIL');
        break;
      case NOTIFICATION.SYNC_FAILED:
        this.headerData.title = `${this.translate.instant('SYNC')} ${this.translate.instant('DETAILS')}`;
        break;
      case NOTIFICATION.MESSAGE_OPENED:
      case NOTIFICATION.MESSAGE_ATTACHMENT_CLICKED:
      case NOTIFICATION.MESSAGE_SENT:
      case NOTIFICATION.MESSAGE_NOT_DELEVERED:
        this.headerData.title = `${this.translate.instant('MESSAGE')} ${this.translate.instant('DETAILS')}`;
        break;
      case NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED:
        this.headerData.title = `${this.translate.instant('MANAGE_LIST')}`;
        break;
      case NOTIFICATION.APPEAL_STATUS_SUBMITTED:
      case NOTIFICATION.APPEAL_STATUS_APPROVED:
      case NOTIFICATION.APPEAL_STATUS_REJECTED:
      case NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION:
        this.headerData.title = `${this.translate.instant('APPEAL_STATUS')}`;
        break;
      default:
        this.headerData.title = this.translate.instant('DETAILS');
        break;
    }
  }

  get accountContactHeader() {
    return {
      id: 'notification-scan-header',
      title: this.accountContactDCR instanceof Account ? this.translate.instant('ACCOUNT_DETAILS') : `${this.translate.instant('NEW')} ${this.translate.instant('CONTACT_CUSTOMER_DETAILS')}`,
      controls: [
      ],
    }
  }

  initData(model: IndNotificationDataModel) {
    switch (model.type) {
      case NOTIFICATION.SYNC_FAILED:
        this.notification = this.translate.instant('SYNC_FAILED_DETAILS') + " " + this.datePipe.transform(model.DateTime, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        break;
      case NOTIFICATION.SERVICE_ERR:
        const serviceErr = this.translate.instant('SERVICE_ERR_DETAILS', this.selectedNotification.params)
        this.notification = serviceErr.replace('{dateTime}', this.datePipe.transform(model.DateTime, this.dateTimeFormatsService.date, undefined, this.translate.currentLang));
        break;
      case NOTIFICATION.NEW_SEARCH_ADV_SEARCH:
        this.notification = this.translate.instant("NEW_SEARCH_ADV_SEARCH_DETAILS", this.selectedNotification.params);
        break;
      case NOTIFICATION.DATA_UPLOAD_ERR_FOLLOWUP:
        this.notification = this.translate.instant("DATA_UPLOAD_ERR_FOLLOWUP", this.selectedNotification.params);
        break;
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
        this.newData = _.filter(this.contactService.contacts, (con) => {
          return _.findIndex(this.notification, (e) => { return con.ID == e }) >= 0;
        });
        break;
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
        this.newData = _.filter(this.accountService.accounts, (acc) => {
          return _.findIndex(this.notification, (e) => { return acc.id == e }) >= 0;
        });
        break;
      case NOTIFICATION.NEW_PRESENTATIONS_NOTIFICATION:
      case NOTIFICATION.NEW_RESOURCES_NOTIFICATION:
        this.newData = this.notification;
        break;
      case NOTIFICATION.MISSED_CALL:
        const missedDate = this.datePipe.transform(model.data.timeoutTimestamp, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
        this.notification = this.translate.instant("MISSED_CALL_NOTIFICATION", { customer: model.data.firstName, missed_date: missedDate });
        break;
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
        if (this.notification) {
          this.newData = [{ ...this.mdmService.contactCRs.value.find((contactCR) => { return contactCR.indskr_contactcrid == this.notification }) }];
        } else {
          if (!model.data.data) {
            if (model.data.indskr_contactcrid) {
              this.newData = [{ ...this.mdmService.contactCRs.value.find((contactCR) => { return contactCR.indskr_contactcrid == model.data.indskr_contactcrid }) }];
            }
            //The CR created offline do not have a CR id, it can be obtained after sync.
            else if (model.data.offlineData) {
              this.newData = [{
                ...this.mdmService.contactCRs.value.find((contactCR) => {
                  if (contactCR.addedNewOneKeyCRId == model.data.offlineData && contactCR.indskr_contactcrid) {
                    model.data['indskr_contactcrid'] = contactCR.indskr_contactcrid;
                    return true;
                  }
                })
              }];
            }
          }
        }
        break;
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
        this.newData = [{ ...this.mdmService.accountCRs.value.find((accountCR) => { return accountCR.indskr_accountcrid == (this.notification || model.data.indskr_accountcrid) }) }];
        break;
      case NOTIFICATION.NEW_FOLLOW_UP_NOTIFICATION:
        this.newData = model.data?.data;
        break;
      case NOTIFICATION.ASSET_REMOVED:
        this.notification = model.data?.data;
        break;
      case NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED:
        this.notification = model.data?.data;
        this.newData = model.data?.data;
        break;
      case NOTIFICATION.APPEAL_STATUS_SUBMITTED:
      case NOTIFICATION.APPEAL_STATUS_APPROVED:
      case NOTIFICATION.APPEAL_STATUS_REJECTED:
      case NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION:
      this.notification = model.data?.data;
      this.newData = model.data?.data;
        break;
      case NOTIFICATION.DCR_FOR_APPROVAL:
        this.notification = model.data?.data;
        this.newData = [model.data?.data];
        break;
      default:
        this.notification = model.data.data;
        break;
    }
    if (!this.isTextBasedView && !this.newData.length)
      this.notificationService.notify(this.translate.instant('DATA_NO_LONGER_EXIST'), 'Notification Details', 'top', ToastStyle.DANGER);
  }

  ngOnDestroy() {
    if (this.IcurrentNotifSubscription) this.IcurrentNotifSubscription.unsubscribe();
  }

  private initNotificationDetailsPageTitle(): void {
    this.notificationDetailsPageTitle = {
      id: 'notification-details-page-title',
      title: this.translate.instant(this.selectedNotification.type, this.selectedNotification.params),
      controls: []
    };
  }

  get isTextBasedView(): boolean {
    return this.selectedNotification.type === NOTIFICATION.SYNC_FAILED || this.selectedNotification.type === NOTIFICATION.SERVICE_ERR || this.selectedNotification.type === NOTIFICATION.NEW_SEARCH_ADV_SEARCH
      || this.selectedNotification.type === NOTIFICATION.DATA_UPLOAD_ERR_FOLLOWUP || this.selectedNotification.type === NOTIFICATION.MISSED_CALL || this.selectedNotification.type === NOTIFICATION.ASSET_REMOVED;
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      case 'goToManagedList':
        this.goToManagedList();
      default:
        break;
    }
  }

  onClosePage() {
    // this.navService.popToRootWithPageTracking();
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    this.uiService.showRightPane = false;
    this.myAssistantService.selectedNotification.next(null);
  }

  goToManagedList() {
    this.navService.setChildNavRightPaneView(true);
    this.navService.pushChildNavPageWithPageTracking(TerritoryManagementListComponent, PageName.TerritoryManagementPageComponent, PageName.TerritoryManagementPageComponent, {
      from: PageName.NotificationDetailsComponent
    });
  }

  getDataAttr(data): string {
    let str: string = "";
    if (this.selectedNotification.type == NOTIFICATION.NEW_CONTACTS_NOTIFICATION) {
      str = data.firstName.charAt(0) + data.lastName.charAt(0);
    } else if (this.selectedNotification.type == NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION) {
      str = data.accountName.charAt(0);
    } else if (this.selectedNotification.type == NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS || this.selectedNotification.type == NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED || this.selectedNotification.type == NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED || this.selectedNotification.type == NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED || this.selectedNotification.type == NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN) {
      if (data instanceof Contact) {
        str = data.indskr_firstname.charAt(0) + data.indskr_lastname.charAt(0);
      } else {
        if (data.indskr_contactcrid && data.statuscode != 548910007) {
          str = data.indskr_firstname.charAt(0) + data.indskr_lastname.charAt(0);
        }
      }
    } else {
      switch (this.selectedNotification.type) {
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
        case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
        case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
        case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
        case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
        case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
        case NOTIFICATION.ONECRM_DCR_APPROVED:
        case NOTIFICATION.ONECRM_DCR_REJECTED:
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
        case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
          if (data instanceof Contact) {
            str = data.indskr_firstname ? data.indskr_firstname.charAt(0) : '' + data.indskr_lastname ? data.indskr_lastname.charAt(0) : '';
          } else {
            if (data.indskr_contactcrid && data.statuscode != 548910007) {
              str = data.indskr_firstname ? data.indskr_firstname.charAt(0) : '' + data.indskr_lastname ? data.indskr_lastname.charAt(0) : '';
            }
          }
          break;
        case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
        case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
        case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
        case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
          if (data instanceof Account) {
            str = data.accountName.charAt(0);
          } else {
            if (data.indskr_accountcrid && data.statuscode != 548910007) {
              str = data.indskr_name.charAt(0);
            }
          }
          break;
        default:
          break;
      }
    }
    return str;
  }

  getPrimaryInfo(data): string {
    let primaryText: string = "";
    switch (this.selectedNotification.type) {
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
        primaryText = data.fullname;
        break;
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
        primaryText = data.accountName;
        break;
      case NOTIFICATION.NEW_PRESENTATIONS_NOTIFICATION:
        primaryText = data.name;
        break;
      case NOTIFICATION.NEW_RESOURCES_NOTIFICATION:
        primaryText = data.title;
        break;
      case NOTIFICATION.DCR_FOR_APPROVAL:
        primaryText = data.name;
        break;
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
        if (data instanceof Contact) {
          primaryText = data.fullName;
        } else {
          if (data.statuscode != 548910007) {
            if (data.indskr_contactcrid) {
              primaryText = `${data.indskr_firstname ? data.indskr_firstname : ''} ${data.indskr_lastname ? data.indskr_lastname : ''}`;
            } else {
              primaryText = this.translate.instant("BUSINESS_PROCESS_CONTACT_NO_LONGER_AVAILABLE");
            }
          } else {
            primaryText = this.translate.instant("BUSINESS_PROCESS_CONTACT_NO_LONGER_AVAILABLE");
          }
        }
        break;
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
        if (data instanceof Account) {
          primaryText = data.accountName;
        } else {
          if (data.statuscode != 548910007) {
            if (data.indskr_accountcrid) {
              primaryText = `${data.indskr_name}`;
            } else {
              primaryText = this.translate.instant("BUSINESS_PROCESS_CONTACT_NO_LONGER_AVAILABLE");
            }
          } else {
            primaryText = this.translate.instant("BUSINESS_PROCESS_CONTACT_NO_LONGER_AVAILABLE");
          }
        }
        break;
      case NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED:
        primaryText = data.listPeriod.indskr_name;
        break;
      case NOTIFICATION.APPEAL_STATUS_SUBMITTED:
      case NOTIFICATION.APPEAL_STATUS_APPROVED:
      case NOTIFICATION.APPEAL_STATUS_REJECTED:
      case NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION:
        primaryText = data.message;
        break;
      default:
        console.log("primary text new data");
        break;
    }
    return primaryText;
  }

  getSecInfo(data) {
    let secInfo: string = "";
    switch (this.selectedNotification.type) {
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
        secInfo = this.secondaryInfoService.getSecondaryInfoFormatedText(data as Contact, SecondaryInfoEntityName.Contact);
        break;
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
        secInfo = this.secondaryInfoService.getSecondaryInfoFormatedText(data as Account, SecondaryInfoEntityName.Account);
        break;
      case NOTIFICATION.NEW_PRESENTATIONS_NOTIFICATION:
        secInfo = this.datePipe.transform(data.createdOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        break;
      case NOTIFICATION.NEW_RESOURCES_NOTIFICATION:
        secInfo = data.assetType;
        break;
      case NOTIFICATION.DCR_FOR_APPROVAL:
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
        if (data instanceof Contact) {
          secInfo = this.secondaryInfoService.getSecondaryInfoFormatedText(data, SecondaryInfoEntityName.Contact);
        } else {
          secInfo = `${this.mdmService.getStatusText(data.statuscode)}`;
        }
        break;
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
        if (data instanceof Contact) {
          secInfo = this.secondaryInfoService.getSecondaryInfoFormatedText(data, SecondaryInfoEntityName.Account);
        } else {
          secInfo = `${this.mdmService.getStatusText(data.statuscode)}`;
        }
        break;
      case NOTIFICATION.APPEAL_STATUS_SUBMITTED:
      case NOTIFICATION.APPEAL_STATUS_APPROVED:
      case NOTIFICATION.APPEAL_STATUS_REJECTED:
      case NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION:
        secInfo = data.formattedDate;
        break;
      default:
        console.log("secondary text new data");
        break;
    }
    return secInfo;
  }

  public thumbURL(data): SafeUrl {
    let url = data['thumbnailUrl'] || data['thumbnailURL'];
    return this.safePipe.transform(url, 'url');
  }

  isCustomerAvailibilityflagTrue(): boolean {
    return this.authService.user.customerAvailability
  }

  getCustomerWithCRID(data) {
    if (data._indskr_contactdcr_value) {
      this.accountContactDCR = this.contactService.getContactByID(data._indskr_contactdcr_value);
    } else if (data._indskr_accountdcr_value) {
      this.accountContactDCR = this.accountService.getAccountById(data._indskr_accountdcr_value);
    }
    return this.accountContactDCR;
  }

  async onItemClick(data) {

    switch (this.selectedNotification.type) {
      case NOTIFICATION.DCR_FOR_APPROVAL:
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
        if (data instanceof Contact) {
          this.contactService.contactInformation = data;
          this.uiService.showNewActivity = false;
          //this.uiService.contactDetailsSegment = 'info';
          this.contactService.isNotInMyContactList = false;
          this.contactService.contactPageMode = ComponentViewMode.PREVIEW;
          this.footerService.initButtons(FooterViews.Contacts);
          let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM);
          await this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, {
            displayFormDetails: contactForm,
            dynamicFormType: DynamicFormType.CONFIGUREDFORM,
            accessedFrom: 'NotificationDetails'
          });
        } else {
          if (data['indskr_contactcrid'] && data['statuscode'] != 548910007) { // open the Contact CR detail
            let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM);
            let selectedCR: any = data;
            if (!selectedCR.hasOwnProperty('@odata.etag')) {
              //The CR created offline needs to fetch the data to update.
              try {
                this.uiService.displayLoader();
                await this.mdmService.fetchContactCRConfiguredDisplay(false, false, data).then((res) => {
                  if (res && Array.isArray(res)) {
                    selectedCR = res[0];
                  } else {
                    selectedCR = res;
                  }
                  this.uiService.dismissLoader();
                });
              } catch (e) {
                console.log("error occurred fetching contactCR online: ", e);
                await this.uiService.dismissLoader();
              }
            }
            this.mdmService.currentSelected.next(selectedCR);
            this.uiService.showNewActivity = false;
            this.uiService.showRightPane = true;
            await this.navService.pushChildNavPageWithPageTracking(CrDetailsComponent, PageName.CrDetailsComponent, PageName.ContactPageComponent, {
              from: 'Contact',
              displayFormDetails: contactForm,
              approvalRequest: this.selectedNotification.type === NOTIFICATION.DCR_FOR_APPROVAL ? data : null,
              contactCrId: selectedCR.indskr_contactcrid,
              dynamicFormType: DynamicFormType.CONFIGUREDFORM,
              accessedFrom: 'NotificationDetails'
            });
          }
        }
        break;
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
        if (data instanceof Account) {
          this.accountService.selected = data;
          this.accountService.isAccountTimelineLoaded = true;
          this.accountService.isNotInMyAccountList = false;
          this.accountService.accountPageMode = ComponentViewMode.PREVIEW;
          this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, { from: 'NotificationDetails', 'listMode': ComponentViewMode.LISTVIEW });
          this.footerService.initButtons(FooterViews.Accounts);
          this.navService.setChildNavRightPaneView(true);
        } else {
          if (data['indskr_accountcrid'] && data['statuscode'] != 548910007) {
            let currentData;
            try {
              await this.uiService.displayLoader();
              await this.mdmService.fetchAccountCRConfiguredDisplay(false, false, data).then((res) => {
                if (res && Array.isArray(res)) {
                  currentData = res[0];
                }
                else {
                  currentData = res;
                }
                console.log(currentData);
                this.uiService.dismissLoader();
              });

            } catch (e) {
              console.log("error occurred fetching accountCR online: ", e);
              await this.uiService.dismissLoader();
            }
            this.mdmService.currentSelected.next(currentData);
            this.navService.setChildNavRightPaneView(true);
            this.uiService.showNewActivity = false;
            await this.navService.pushChildNavPageWithPageTracking(AccountCrDetailsComponent, PageName.AccountCrDetailsComponent, PageName.AccountPageComponent, {
              from: 'Account', accessedFrom: 'NotificationDetails', accountCrId: data.indskr_accountcrid,
            });
          }
        }
        break;
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
        if (data instanceof Contact) {
          this.contactService.contactInformation = data;
          this.uiService.showNewActivity = false;
          //this.uiService.contactDetailsSegment = 'info';
          this.contactService.isNotInMyContactList = false;
          this.contactService.contactPageMode = ComponentViewMode.PREVIEW;
          let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM);
          await this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, {
            displayFormDetails: contactForm,
            dynamicFormType: DynamicFormType.CONFIGUREDFORM,
            accessedFrom: 'NotificationDetails'
          });
          this.footerService.initButtons(FooterViews.Contacts);
        }
        break;
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
        if (data instanceof Account) {
          this.accountService.selected = data;
          //this.uiService.accountDataSegment = 'info';
          this.accountService.isAccountTimelineLoaded = true;
          this.accountService.isNotInMyAccountList = false;
          this.accountService.accountPageMode = ComponentViewMode.PREVIEW;
          this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, {
            from: 'NotificationDetails',
            'listMode': ComponentViewMode.LISTVIEW
          });
          this.footerService.initButtons(FooterViews.Accounts);
          this.navService.setChildNavRightPaneView(true);
        }
        break;

      case NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED:
        await this.uiService.displayLoader();
        let customerPositionList = data;
        const resp = await this.territoryDataService.fetchMyContactsByCustomerPositionList(customerPositionList, true);
        this.territoryService.setAvailableContacts(resp);
        customerPositionList.positionListTargets = resp;
        this.territoryService.setMyContacts(customerPositionList)
        await this.uiService.dismissLoader();
        this.territoryService.selectedCustomerPostionListSubject.next(customerPositionList);
        // this.selectedCustomerPositionList = customerPositionList;
        this.navService.setChildNavRightPaneView(true);
        let selectedTab = this.selectedNotification.type == NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED ? TerritoryManagementTabs.APPROVALS : TerritoryManagementTabs.MANAGE_LIST;
        this.navService.pushChildNavPageWithPageTracking(TerritoryManagementDetailsComponent, PageName.TerritoryManagementDetailsComponent, PageName.TerritoryManagementPageComponent, {
          selectedTab: selectedTab,
          from: PageName.NotificationDetailsComponent
        });
        break;

      case NOTIFICATION.APPEAL_STATUS_SUBMITTED:
        await this.uiService.displayLoader();
        const appealDetails = await this.appealDataService.fetchAppealDetails(data['id']);
        this.navService.pushChildNavPageWithPageTracking(AppealDetailsComponent, PageName.AppealDetailsComponent, PageName.ActivitiesPageComponent,
          {
            selectedActivity: appealDetails,
            selectedMode: 0,
            from: PageName.NotificationDetailsComponent
          });
        await this.uiService.dismissLoader();

        break;
      case NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION:
            await this.uiService.displayLoader();
            const activity = new AppointmentActivity({ activityId: data['id'], activitytypecode: 'appointment' });
            this.activityService.selectedActivity = await this.activityDataService.getRealTimeActivityDetails(activity);
            await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent,
              PageName.AppealDetailsComponent,
              {
                selectedMode: 0,
                from: PageName.NotificationDetailsComponent,
              });
            await this.uiService.dismissLoader();
          break;
      case NOTIFICATION.APPEAL_STATUS_APPROVED:
      case NOTIFICATION.APPEAL_STATUS_REJECTED:
        await this.uiService.displayLoader();
        const appeal = await this.appealDataService.fetchAppealDetails(data['id'], 1);
        this.navService.pushChildNavPageWithPageTracking(AppealDetailsComponent, PageName.AppealDetailsComponent, PageName.ActivitiesPageComponent,
          {
            selectedActivity: appeal,
            selectedMode: 1,
            from: PageName.NotificationDetailsComponent
          });
          await this.uiService.dismissLoader();
        break;

      default:
        break;
    }
  }

  get integratedNotificationType(): string {
    let integratedType: string = '';
    switch (this.selectedNotification.type) {
      case NOTIFICATION.DCR_FOR_APPROVAL:
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
        integratedType = "changeRequest";
        break;
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
        integratedType = "newContactNewAccount"
        break;
      case NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED:
        integratedType = "manageList"
        break;
      default:
        break;
    }
    return integratedType;
  }

  public downloadPresentation(pres: any) {
    this.trackingService.tracking('PresentationsDownlaod',TrackingEventNames.PRESENTATIONS)
    let indexToUpdate = this.newData.findIndex(item => item.ioPresentationId === pres.ioPresentationId);

    if(this.newData[indexToUpdate]) {
      if((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !this.newData[indexToUpdate].isDownloaded) {
        this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "Presentation List", "top", ToastStyle.DANGER, 2000, true);
        return;
      }

      if(!pres.downloaded && !this.newData[indexToUpdate].isDownloaded) {
          this.notificationService.notify((this.translate.instant('DOWNLOADING') +' '+ pres.name),'Presentation Download');

          if(pres.zipUrl && pres.thumbnailZipUrl) {
              this.newData[indexToUpdate].isDownloaded = true;
              this.store.dispatch(new FileAction.downloadFileEnqueue({ presentationId: pres.ioPresentationId }));
          } else {
              this.notificationService.notify( this.translate.instant('FAILED_TO_DOWNLOAD_PRESENTATION') + pres.name, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
          }
      } 
   }
}

  public downloadResource(resource: any) {
    event.stopPropagation();
    this.trackingService.tracking('ResourceDownlaod', TrackingEventNames.RESOURCES);
    const resourceId: string = resource.ioResourceId ? resource.ioResourceId : resource.ioDocumentId;
    let indexToUpdate = this.newData.findIndex(item => item.ioResourceId === resourceId);

    if(this.newData[indexToUpdate]) {
      if ((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !this.newData[indexToUpdate].isDownloaded) {
        this.notificationService.notify(this.translate.instant('CANNOT_DOWNLOAD_RESOURCE_WHILE_OFFLINE'), "Resource List", "top", ToastStyle.DANGER, 2000, true);
        return;
      }
  
      if (!resource.downloaded && !this.newData[indexToUpdate].isDownloaded) {
        this.notificationService.notify((this.translate.instant('DOWNLOADING') + ' ' + resource.title), 'Resource Download');

        if (resource.assetURL) {
          this.newData[indexToUpdate].isDownloaded = true;
          this.store.dispatch(new ResourceAction.downloadResourceEnqueue({ resourceId: resourceId }));
        } else {
          this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + resource.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
        }
      }
    }
  }

  ngDestroy() {
    this.dlProgressSubscription.unsubscribe();
    this.unzipProgressSubscription.unsubscribe();
  }
}
