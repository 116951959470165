
import { fileManagerState, INITIAL_FILE_MANAGER_STATE } from '../states/file.state';
import { Action } from '@ngrx/store';
import {
    DOWNLOAD_FILE,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_FAILURE,
    UNZIP_FILE,
    UNZIP_FILE_SUCCESS,
    UNZIP_FILE_FAILURE,
    UPDATE_FILE_RECORD,
    downloadFile,
    downloadFileSuccess,
    downloadFileFailed,
    unZipFile,
    unZipFileSuccess,
    unZipFileFailed,
    updateFileRecord,
    downloadFileDequeue,
    downloadFileEnqueue,
    FILE_DOWNLOAD_ENQUEUE,
    FILE_DOWNLOAD_DEQUEUE

} from '../actions/file.actions';


export function fileManagerState(state: fileManagerState = INITIAL_FILE_MANAGER_STATE, action: Action): fileManagerState {

    switch (action.type) {

        case DOWNLOAD_FILE:
            return handleDownloadFileAction(state, <downloadFile>action);

        case DOWNLOAD_FILE_SUCCESS:
            return handleDownloadFileSuccessAction(state, <downloadFileSuccess>action);

        case DOWNLOAD_FILE_FAILURE:
            return handleDownloadFileFailedAction(state, <downloadFileFailed>action);

        case UNZIP_FILE:
            return handleUnZipFileAction(state, <unZipFile>action);

        case UNZIP_FILE_SUCCESS:
            return handleUnZipFileSuccessAction(state, <unZipFileSuccess>action);

        case UNZIP_FILE_FAILURE:
            return handleUnZipFileFailedAction(state, <unZipFileFailed>action);

        case UPDATE_FILE_RECORD:
            return handleUpdateFileRecordAction(state, <updateFileRecord>action);

        case FILE_DOWNLOAD_ENQUEUE:
            return handleDownloadFileEnqueueAction(state, <downloadFileEnqueue>action);

        case FILE_DOWNLOAD_DEQUEUE:
            return handleDownloadFileDequeueAction(state, <downloadFileEnqueue>action);


        default:
            return state;
    }
}

function handleDownloadFileEnqueueAction(state: fileManagerState, action: downloadFileEnqueue) {
  const newState = {
    ...state,
    downloadQueue: state.downloadQueue.slice() || []
  }
  if (newState.downloadQueue.indexOf(action.payload.presentationId) === -1) {
        newState.downloadQueue.push(action.payload.presentationId);
    }
    return newState;
}

function handleDownloadFileDequeueAction(state: fileManagerState, action: downloadFileEnqueue) {
  const newState = {
    ...state,
    downloadQueue: state.downloadQueue.slice() || []
  }
  newState.downloadQueue.shift();
    return newState;
}

function handleDownloadFileAction(state: fileManagerState, action: downloadFile) {

    const newState = Object.assign({}, state);
    newState.presentationId = action.payload.presentationId;
    newState.errorInfo = undefined;
    newState.isDownloading = true;
    newState.isUnZipping = false;

    return newState;
}


function handleDownloadFileSuccessAction(state: fileManagerState, action: downloadFileSuccess) {

    const newState = Object.assign({}, state);
    newState.presentationId = action.payload.presentationId;
    newState.errorInfo = undefined;
    newState.isDownloading = false;
    newState.isUnZipping = true;
    return newState;
}

function handleDownloadFileFailedAction(state: fileManagerState, action: downloadFileFailed) {

    const newState = Object.assign({}, state);
    newState.errorInfo = action.payload.presentationId;
    newState.isDownloading = false;
    newState.isUnZipping = false;
    newState.presentationId = undefined;
    // newState.downloadQueue.shift()
    // newState.downloadedQueue.push(newState.downloadQueue.shift());
    return newState;
}

function handleUnZipFileAction(state: fileManagerState, action: unZipFile) {

    const newState = Object.assign({}, state);
    newState.errorInfo = undefined;
    newState.isDownloading = false;
    newState.isUnZipping = true;

    return newState;
}


function handleUnZipFileSuccessAction(state: fileManagerState, action: unZipFileSuccess) {

    const newState = Object.assign({}, state);
    newState.presentationId = action.payload.presentationId;
    newState.errorInfo = undefined;
    newState.isDownloading = false;
    newState.isUnZipping = false;
    return newState;
}

function handleUnZipFileFailedAction(state: fileManagerState, action: unZipFileFailed) {

    const newState = Object.assign({}, state);
    newState.errorInfo = action.payload.error;
    newState.isDownloading = false;
    newState.isUnZipping = false;
    newState.presentationId = undefined;
    return newState;
}


function handleUpdateFileRecordAction(state: fileManagerState, action: updateFileRecord) {

    const newState = Object.assign({}, state);
    newState.presentationId = action.payload.presentationId;
    newState.errorInfo = undefined;
    newState.isDownloading = false;
    newState.isUnZipping = false;

    return newState;
}
