
import { takeUntil } from 'rxjs/operators';
import { PopoverController } from '@ionic/angular';
import { FooterService } from './../../services/footer/footer.service';
import { UIService } from './../../services/ui/ui.service';
import { Component, Input } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import * as _ from 'lodash';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { ActivityService } from '../../services/activity/activity.service';
import { Subject } from 'rxjs';
import { AgendaFooterService } from '../../services/footer/agenda-footer.service';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from '@omni/services/email-templates/email.service';
import { SampleService, SamplingDetailsViewMode } from '@omni/services/sample/sample.service';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { SampleActivity } from '@omni/classes/activity/sample.activity.class';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { SampleDetailInSampleActivity } from '@omni/models/sample-model';

/**
 * Generated class for the MoreOptionsPopoverComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'more-options-popover',
  templateUrl: 'more-options-popover.html',
  styleUrls: ['more-options-popover.scss']
})

export class MoreOptionsPopoverComponent {

  text: string;
  buttonGroups: any[];
  ngUnsubscribe$ = new Subject<boolean>();
  constructedNow: boolean;
  @Input() moreButtons: any

  constructor(
    public device: DeviceService,
    public uiService: UIService,
    public footerService: FooterService,
    private activityService: ActivityService,
    private agendaFooterService: AgendaFooterService,
    public popoverCtrl: PopoverController,
    private translate: TranslateService,
    private emailService: EmailService,
    private sampleService: SampleService,
  ) {

    this.device.screenOrientation.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((newOrientation) => {
      if (this.constructedNow) {
        this.constructedNow = false;
      }
      else {
        this.popoverCtrl.dismiss();
      }
    });
  }

  ngOnInit() {
    let filteredButtons = null;
    if (this.moreButtons) {
      filteredButtons = this.moreButtons;
    } else {
      if (this.footerService.footerView === 'masterView' && this.agendaFooterService.actionButtons.filter(btn => btn.isvisible && btn.shouldbeinmore).length > 0) {

        filteredButtons = this.agendaFooterService.actionButtons.filter(btn => btn.isvisible && btn.shouldbeinmore);

      } else if (this.footerService.footerView === 'detailView' && this.footerService.actionButtons.filter(btn => !btn.availableOnFullScreen && btn.isvisible).length > 0) {

        filteredButtons = this.footerService.actionButtons.filter(btn => btn.isvisible && !btn.availableOnFullScreen);

      }
      else {
        filteredButtons = this.footerService.actionButtons.filter((btn) => {
          // return btn.isvisible == true && btn.align == 'left' && btn.variableVisibility == true && !btn.availableOnFullScreen;
          return btn.isvisible && !btn.availableOnFullScreen;
        });
      }
    }
    filteredButtons = _.reverse(filteredButtons);
    this.buttonGroups = _.chunk(filteredButtons, 4);
    this.constructedNow = true;
  }

  buttonEventHandler(button) {
    this.popoverCtrl.dismiss(button);
  }

  ngAfterViewInit() {
    this.footerService.hideButton('openmoreoptions');
    this.footerService.showButton('openmoreoptionsactive');

    if (this.footerService.footerSelectedView === 'masterView' && this.device.isMobileDevice && this.device.isMobileDevicePortrait) {
      // if it's master view and device is not mobile portrait. add right: 70% ; to position to the popover  in teh left pane

    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  shouldShowBadge(button: any): boolean {
    if (button && button.isBadge) {
      if (this.getBadgeData(button) > 0) {
        return true;
      }
    }
    return false;
  }

  public footerIconStyle(button: any) {
    const avoidButtons = ['message'];
    if (!avoidButtons.includes(button.id)) {
      return 'fabActionItemIcon';
    }
    return '';
  }

  getBadgeData(buttonId): number {
    switch (buttonId.id) {
      case 'contactsMessage':
      case 'contactsphonecall':
      case 'contactspresentationpreview':
      case 'contacts':
        if (this.emailService.viewType != EmailViewType.EMAIL_FROM_MEETING_PRESENTATION && this.emailService.selectedActivity && this.emailService.selectedActivity.emailActivityParties) {
          return this.emailService.selectedActivity.emailActivityParties.length;
        }
        return (this.activityService.selectedActivity && this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'].length : 0;
      case 'presentationsrightalligned':
      case 'presentationsleftalligned':
      case 'presentations':
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          return (this.activityService.selectedActivity as AppointmentActivity).presentations ? (this.activityService.selectedActivity as AppointmentActivity).presentations.length : 0;
        }
      case 'contactsAllocation':
        if (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING) {
          return (this.sampleService.inMeetingAllocationActivity
            && this.sampleService.inMeetingAllocationActivity.contactID) ? 1 : 0;
        }
        else {
          return (this.activityService.selectedActivity
            && this.activityService.selectedActivity.type == ActivityType.Sample
            && (this.activityService.selectedActivity as SampleActivity).contactID) ? 1 : 0;
        }
      case 'samplingeligibilities':
        if (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING) {
          return (this.sampleService.inMeetingAllocationActivity
            && this.sampleService.inMeetingAllocationActivity.samples) ? this.calculateValidSampleDropsNumber(this.sampleService.inMeetingAllocationActivity.samples) : 0;
        }
        else {
          return (this.activityService.selectedActivity
            && this.activityService.selectedActivity.type == ActivityType.Sample
            && (this.activityService.selectedActivity as SampleActivity).samples) ? this.calculateValidSampleDropsNumber((this.activityService.selectedActivity as SampleActivity).samples) : 0;
        }
      case 'attachment': {
        if (this.emailService.selectedActivity && this.emailService.selectedActivity.emailAttachments) {
          return this.emailService.selectedActivity.emailAttachments.length;
        }
        else {
          return;
        }
      }

      default:
        break;
    }
    // switch (buttonId.id) {
    //   case 'contactspresentationpreview':
    //     return (this.activityService.selectedActivity && this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'].length : 0;
    //   case 'presentations':
    //     if (this.activityService.selectedActivity instanceof AppointmentActivity) {
    //       return (this.activityService.selectedActivity.presentations) ? this.activityService.selectedActivity.presentations.length : 0;
    //     }
    //   default:
    //     break;
    // }
  }

  private calculateValidSampleDropsNumber(samples: Array<SampleDetailInSampleActivity>): number {
    let num = 0;
    samples.forEach(sample => {
      if (!sample['deleted']) {
        num++;
      }
    })
    return num;
  }

  getButtonValue(button: any): string {
    if (button) {
      if (button['external']) {
        return button['external'];
      } else {
        return this.translate.instant(button.name);
      }
    } else {
      return '';
    }
  }

}
