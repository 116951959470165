import { Component, Input } from '@angular/core';

@Component({
  selector: 'appsettings-progress-bar',
  templateUrl: 'appsettings-progress-bar.html',
  styleUrls:['appsettings-progress-bar.scss']
})
export class AppSettingsProgressBarComponent {

  @Input('progress') progress;

  constructor() {

  }

}
