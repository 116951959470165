<ion-header [ngClass]="{'show-progress-bar-pad': deviceService.isBackgroundUploadInProgress }">
  <ind-section-header [viewData]="routesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

     <!-- <ion-item-divider color="light" class="list-title">
        <span>
            {{'SAVED_ROUTES_PLANS' | translate}}
        </span>
        <span slot="end" float-right margin-left class="divider-text" (click)="newScheduler()"
            [ngClass]="!isDeviceOnline || deviceService.isOffline?'closed-status':'open-status'">
            {{ 'SCHEDULE_NEW_PLAN' | translate}}
        </span>
     </ion-item-divider> -->
</ion-header>

<div [ngClass]="{'vectorimageleft-scheduler': schedulerList.length === 0 && schedulerSearchText.length === 0}">
     <ion-list *ngIf="schedulerList.length > 0">
        <scheduler-item *ngFor="let schedule of slicedSchedulerList"
            [schedule]="schedule" [isDeviceOnline]='isDeviceOnline' (onSelect)="onSelect($event)"
            [isSelected]="schedule?.ID === selectedSchedule?.ID"></scheduler-item>
        </ion-list>
        <ion-list *ngIf="schedulerList.length === 0 && schedulerSearchText === ''">
            <div class="no-data-message">
                {{'SCHEDULING_NO_ROUTES' | translate}}
            </div>
    </ion-list>
    <ion-infinite-scroll *ngIf="schedulerList.length > 0" (ionInfinite)="doInfinite($event.detail,$event)" threshold="20%">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ind-no-search-result *ngIf="schedulerList.length === 0 && schedulerSearchText" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </div>
