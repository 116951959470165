import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { WebsocketDataService } from '@omni/data-services/websocket/websocket.data.service';

@Component({
  selector: 'tencent-participant',
  templateUrl: './tencent-participant.component.html',
  styleUrls: ['./tencent-participant.component.scss'],
})
export class TencentParticipantComponent implements OnInit {

  @Input() tencentConfig;

  public initials: string;
  public name: string;
  public id: string;

  @ViewChild('videobox') videobox: ElementRef;

  @HostBinding('class.has-video') get hasVideo(): boolean {
    return !!this.tencentConfig?.hasVideo && !this.tencentConfig.mutedVideo;
  }

  @HostBinding('style.order') get displayOrder() {
    return this.tencentConfig.type == 'local' ? 1 : 2;
  }

  @HostBinding('class.has-audio') get hasAudio(): boolean {
    return !!this.tencentConfig?.hasAudio;
  }

  constructor(
    private webSocket: WebsocketDataService,
  ) { }

  ngOnInit() {
    console.log("Am i");
    this.updateName();
  }

  ngAfterViewInit() {
    const fit = { objectFit: 'contain' };
    if (this.videobox) this.tencentConfig?.stream.play(this.videobox.nativeElement, fit);
  }

  private updateName() {
    const nameArr = (this.tencentConfig?.userID as string)?.split('|') || [];
    this.name = nameArr[0];
    this.id = nameArr[nameArr.length - 1];
    const names = this.name.split(' ');
    this.initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      this.initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
  }

  handleUnmuteClicked() {
    this.webSocket.sendRemoteAction({ type: 'voip:actions', contactId: this.id, muteAudio: !this.tencentConfig.mutedAudio })
  }

}
