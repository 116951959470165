export async function decrypt(
  encryptedString: string,
  ivString: string,
  hash: string
) {
  const pwUtf8 = new TextEncoder().encode(hash);
  const pwHash = await crypto.subtle.digest("SHA-256", pwUtf8);

  const ivAB = str2ab(ivString);
  console.log(ivAB);

  const alg = { name: "AES-GCM", iv: ivAB };
  const key = await crypto.subtle.importKey("raw", pwHash, alg.name, false, [
    "decrypt"
  ]);

  const uint8Array = str2ab(encryptedString);
  const ptBuffer = await crypto.subtle.decrypt(alg, key, uint8Array);

  const plaintext = new TextDecoder().decode(ptBuffer);

  return plaintext;
}

export async function encrypt(
  data: string,
  hash: string
) {
  const ptUtf8 = new TextEncoder().encode(data);
  const pwUtf8 = new TextEncoder().encode(hash);
  const pwHash = await crypto.subtle.digest("SHA-256", pwUtf8);

  const iv = crypto.getRandomValues(new Uint8Array(12));
  const alg = { name: "AES-GCM", iv: iv };
  const key = await crypto.subtle.importKey("raw", pwHash, alg.name, false, [
    "encrypt"
  ]);
  const encBuffer = await crypto.subtle.encrypt(alg, key, ptUtf8);

  let ivString = ab2str(iv);
  let encryptedString = ab2str(encBuffer);
  return { ivString, encryptedString };
}

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

function str2ab(str) {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}
