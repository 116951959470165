import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '../ind-dropdown-list/ind-dropdown-list';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'omni-year-month-picker',
  templateUrl: './omni-year-month-picker.component.html',
  styleUrls: ['./omni-year-month-picker.component.scss']
})
export class OmniYearMonthPickerComponent implements OnInit {
  @Input() header: string;
  @Input() params;
  @Input() isMultiSelect = false;
  @Input() buttonLabel;

  selectedMonths = [];
  selectedYear;

  monthArray = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];
  disaplayMonthArray = [];
  monthArrayWithDate = [];
  monthField: IndFormFieldViewDataModel;

  yearArray = [];
  yearArrayWithDate = [];
  yearField: IndFormFieldViewDataModel;
  min: Date;
  max: Date;

  constructor(
    public modalController: ModalController,
    private popoverCtrl: PopoverController,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this.params) {
      this.selectedYear = this.params.selectedYear ? this.params.selectedYear : new Date().getFullYear();
      this.yearArray = this.params.inputYears.length > 0 ? this.params.inputYears : [];
      this.min = this.params.min ? this.params.min : null;
      this.max = this.params.max ? this.params.max : null;
    }

    this.getMOnthFormField();
    this.getYearField();
    this.setmonthArrayWithDate();
    this.setYearArrayWithDate();
  }

  setmonthArrayWithDate() {
    this.monthArrayWithDate = [];
    this.monthArray.forEach((month, index) => {
      let startDateofMonth = this.getStartOfMonth(index + 1, this.selectedYear);
      if ((this.min && this.min.getTime() <= startDateofMonth) && (this.max && this.max.getTime() >= startDateofMonth)) {
        this.monthArrayWithDate.push({
          title: month,
          id: startDateofMonth,
          isSelected: this.selectedMonths.includes(startDateofMonth),
        })
      }
    });
  }

  setMonthListData() {
    this.disaplayMonthArray = this.monthArrayWithDate.filter((x) => !this.shouldShowMonthInTheList(x.id));
  }

  getStartOfMonth(month, year) {
    let startDateofMonth;
    if (this.min.getMonth() === month - 1) {
      startDateofMonth = new Date(`${year}- ${month}-${this.min.getDate()}`).getTime();
    } else {
      startDateofMonth = new Date(`${year}- ${month}-01`).getTime();
    }
    return startDateofMonth
  }

  shouldShowMonthInTheList = (startMonth) => {
    return this.params.selectedMonths.includes(startMonth);
  }

  private async handleMonthField(myEvent) {
    this.setmonthArrayWithDate();
    this.setMonthListData()
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'procedure-types',
      isMultipleSelectionEnabled: false,
      data: this.disaplayMonthArray
    };

    const procedureTypePopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent
    });

    await procedureTypePopover.present();

    await procedureTypePopover.onDidDismiss().then(async ({ data }) => {
      if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0) return;

      if (this.isMultiSelect) {
        if (!this.selectedMonths.includes(data.selectedItems[0].id)) {
          this.selectedMonths.push(data.selectedItems[0].id);
        }
      } else {
        if (!this.selectedMonths.includes(data.selectedItems[0].id)) {
          this.selectedMonths[0] = data.selectedItems[0].id;
        }
      }
      this.getMOnthFormField();
    });
  }

  public getMOnthFormField() {
    let title = this.monthArrayWithDate.find((x) => x.id === this.selectedMonths[0]) ? this.monthArrayWithDate.find((x) => x.id === this.selectedMonths[0]).title : '';
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MONTH'),
      inputText: title,
      customPlaceholderLabel: this.translate.instant('SELECT_MONTH'),
      id: 'month',
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      isRequired: false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.monthField = viewData;
  }

  handleFormFieldEvent(id, event, eventName) {
    if (id === 'month') {
      this.handleMonthField(event)
    } else if (id === 'year') {
      this.handleYearsField(event);
    }
  }

  public getYearField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('YEAR'),
      inputText: this.selectedYear?.toString(),
      customPlaceholderLabel: this.translate.instant('SELECT_YEAR'),
      id: 'year',
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      isRequired: false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.yearField = viewData;
  }

  setYearArrayWithDate() {
    this.yearArray.forEach((year) => {
      this.yearArrayWithDate.push({
        title: year,
        id: year,
        isSelected: this.selectedYear === year,
      })
    });
  }

  private async handleYearsField(myEvent) {
    this.setMonthListData()
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'procedure-types',
      isMultipleSelectionEnabled: false,
      data: this.yearArrayWithDate
    };

    const procedureTypePopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent
    });

    await procedureTypePopover.present();

    await procedureTypePopover.onDidDismiss().then(async ({ data }) => {
      if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0) return;

      if (!this.selectedYear !== data.selectedItems[0].id) {
        this.selectedYear = data.selectedItems[0].id;
        let currentMonth = new Date(this.selectedMonths[0]).getMonth();


        let currentStartOfMonth = this.getStartOfMonth(currentMonth + 1, data.selectedItems[0].id)
        let alreadySelected = this.params.selectedMonths.includes(currentStartOfMonth);
        if (alreadySelected) {
          let v = this.selectedMonths.findIndex((y) => y === currentStartOfMonth);
          if (v >= 0) {
            this.selectedMonths.splice(v, 1);
          }
        } else {
          this.selectedMonths[0] = currentStartOfMonth;
        }
      }


      this.getYearField();
      this.setmonthArrayWithDate();
      this.getMOnthFormField();
    });

  }

  onConfirm() {
    this.modalController.dismiss(this.selectedMonths);
  }

  public shouldDisable() {
    return this.selectedMonths.length == 0 || !this.selectedYear
  }

}
