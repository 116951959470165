export class KOLStatus {
  public ID:string;
  public name:string;
  public kolTypeString:string;
  public kolTypeValue:KOLType;
  public therapeuticAreaId:string;
  public therapeuticAreaName:string;
  public specialtyId:string;
  public specialtyName:string;
  public diseaseAreaId:string;
  public diseaseAreaName:string;
  public comments:string;
  public ownerId:string;
  public ownerName:string;
  public contactId:string;
  public contactName:string;
  public createdOn:Date;
  public statuscode:KOLStatusCode;
  public statusString:string;
  public statecode:number;
  public approvalActivityId:string;
  public approvalStatus:string;
  public isPendingPushToDynamics:boolean;
  public isApprovalRecord:boolean;

  constructor(raw:any){
    this.ID = raw['indskr_kolstatusid'] ? raw['indskr_kolstatusid'] : '';
    this.name = raw['indskr_name'] ? raw['indskr_name'] : '';
    this.kolTypeString = raw['indskr_koltype_Formatted'] ? raw['indskr_koltype_Formatted'] : '';
    this.kolTypeValue = raw['indskr_koltype'] ? raw['indskr_koltype'] : null;
    this.therapeuticAreaId = raw['_indskr_therapeuticarea_value'] ? raw['_indskr_therapeuticarea_value'] : '';
    this.therapeuticAreaName = raw['_indskr_therapeuticarea_value_Formatted'] ? raw['_indskr_therapeuticarea_value_Formatted'] : '';
    this.specialtyId = raw['_indskr_specialty_value'] ? raw['_indskr_specialty_value'] : '';
    this.specialtyName = raw['_indskr_specialty_value_Formatted'] ? raw['_indskr_specialty_value_Formatted'] : '';
    this.diseaseAreaId = raw['_indskr_diseasearea_value'] ? raw['_indskr_diseasearea_value'] : '';
    this.diseaseAreaName = raw['_indskr_diseasearea_value_Formatted'] ? raw['_indskr_diseasearea_value_Formatted'] : '';
    this.comments = raw['indskr_comments'] ? raw['indskr_comments'] : '';
    this.ownerId = raw['_ownerid_value'] ? raw['_ownerid_value'] : '';
    this.contactName = raw['_indskr_customer_value_Formatted'] ? raw['_indskr_customer_value_Formatted'] : '';
    this.contactId = raw['_indskr_customer_value'] ? raw['_indskr_customer_value'] : '';
    this.ownerName = raw['_ownerid_value_Formatted'] ? raw['_ownerid_value_Formatted'] : '';
    this.createdOn = raw['createdon'] ? new Date(raw['createdon']) : null;
    this.statuscode = raw['statuscode'] ? raw['statuscode'] : null;
    this.statusString = raw['statuscode_Formatted'] ? raw['statuscode_Formatted'] : null;
    this.statecode = raw['statecode'] ? raw['statecode'] : null;
    this.approvalActivityId = raw['aa.indskr_approvalactivityid'] ? raw['aa.indskr_approvalactivityid'] : '';
    this.approvalStatus = raw['aa.statuscode_Formatted'] ? raw['aa.statuscode_Formatted'] : '';
    this.isPendingPushToDynamics = raw['isPendingPushToDynamics'] ? raw['isPendingPushToDynamics'] : false;
    this.isApprovalRecord = raw['isApprovalRecord'] ? raw['isApprovalRecord'] : false;
  }

  public get getOfflineDataDTO():any{
    return {
      "indskr_kolstatusid": this.ID,
      "indskr_name": this.name,
      "indskr_koltype_Formatted": this.kolTypeString,
      "indskr_koltype": this.kolTypeValue,
      "_indskr_therapeuticarea_value": this.therapeuticAreaId,
      "_indskr_therapeuticarea_value_Formatted": this.therapeuticAreaName,
      "_indskr_specialty_value": this.specialtyId,
      "_indskr_specialty_value_Formatted": this.specialtyName,
      "_indskr_diseasearea_value": this.diseaseAreaId,
      "_indskr_diseasearea_value_Formatted": this.diseaseAreaName,
      "indskr_comments": this.comments,
      "_ownerid_value": this.ownerId,
      "_indskr_customer_value_Formatted": this.contactName,
      "_indskr_customer_value": this.contactId,
      "_ownerid_value_Formatted": this.ownerName,
      "createdon": this.createdOn,
      "statuscode": this.statuscode,
      "statuscode_Formatted": this.statusString,
      "statecode": this.statecode,
      "aa.indskr_approvalactivityid": this.approvalActivityId,
      "aa.statuscode_Formatted": this.approvalStatus,
      "isPendingPushToDynamics": this.isPendingPushToDynamics,
      "isApprovalRecord": this.isApprovalRecord,
    }
  }

  public get getServiceDTO():any {
    let payload = {
      "contactId": this.contactId,
      "diseaseArea": this.diseaseAreaId,
      "kolType": this.kolTypeValue,
      "kolName": this.name,
      "comments": this.comments,
      "statuscode": this.statuscode,
    };
    if(this.therapeuticAreaId){
      payload['therapeuticArea'] = this.therapeuticAreaId;
    }
    if(this.specialtyId){
      payload['specialty'] = this.specialtyId;
    }
    if(!this.ID.includes('offline')){
      //payload['indskr_kolstatusid'] = this.ID;
    }
    return payload;
  }

}

export enum KOLStatusCode {
  Draft = 1,
  Submitted = 600000000,
  Approved = 600000001,
  Rejected = 600000002,
}

export enum KOLType {
  Global = 600000000,
  Local = 600000003,
  National = 600000002,
  Regional = 600000001,
}