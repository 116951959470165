  <div class="container-v">
    <div class="table-container">
    <p class="container-c label top" [innerHTML]="viewData.firstHeading | translate">
    </p>
    </div>
    <p class="container-c value-text" *ngIf="viewData.observableData; else staticData">
      {{viewData.observableData | async}}
    </p>
    <ng-template #staticData>
      <p class="container-c value-text">
        {{viewData.dataValue}}
      </p>
    </ng-template>
    <div class="table-container">
    <p  class="container-c label bottom">
      {{viewData.dataFieldName | translate}}
    </p >
    </div>
  </div>
