import { Injectable } from "@angular/core";
import { NextCallObjective } from '../../classes/Next-Call-Objective/next.call.objective';
import { AuthenticationService } from '../authentication.service';
import { DiskService } from '../disk/disk.service';
import { ActivityService } from '../activity/activity.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';

@Injectable({
  providedIn: 'root'
})
export class NextCallObjectiveService {

  nextCallObjectives: Array<NextCallObjective> = [];
  constructor(
    private authService: AuthenticationService,
    private disk: DiskService,
    private activityService: ActivityService
  ) { }

  /**
   * @description This method gets all the nco the list of contacts and remove duplicate nco if any in case of group meeting
   * @param {string[]=} contactIdList to get all the all nco in the current meeting with the customer id list
  */
  public getPendingObjectivesForContact(contactIdList: string[]): Array<NextCallObjective> {

    let teamViewEnable = this.activityService.teamViewActive;
    let currentUserId = this.authService.user.systemUserID;

    // Selected activity ID
    const activity = this.activityService.selectedActivity as AppointmentActivity;
    const activityID = activity.ID

    // NCO should also be available in TeamsView or Covisitors meetings created with the same customer.
    // Check for Covistors (OMNI-13364 #1)
    let isCurrentUserAddedAsCovisitor = false;
    let comparisionIds: string[] = [];
    if (!teamViewEnable) {
      const activityOwnerId = activity.ownerId ?? activity.meetingOwnerId;
      if (activityOwnerId) {
        if (activityOwnerId === currentUserId) {
          // Current user is owner of the Activity.
          comparisionIds.push(currentUserId);
        } else {
          // Check for the co-visitors.
          const accompaniedUsers = activity.accompaniedUserList;
          if (accompaniedUsers && accompaniedUsers.length) {
          const accompaniedUsersIds = accompaniedUsers.map(e => e.id);
            if (accompaniedUsersIds.includes(currentUserId)) {
              // Current user is added as the co-visitor.
              isCurrentUserAddedAsCovisitor = true;
              // Since current user is added as the co-visitor, we've to check Activity ownerId
              comparisionIds.push(activityOwnerId);
            }
          } else {
            // No covisitors, adding current user for the comparision.
            comparisionIds.push(currentUserId);
          }
        }
      }
    } else {
      // TeamView is activated, we have to use the id for which TeamView is active.
      let teamViewId = this.authService.impersonatedUser ? this.authService.impersonatedUser.userId : '';
      comparisionIds.push(teamViewId);
    }

    // Compare if same set of Contacts are added

    let ncosCreatedInMeeting = this.getNextCallObjectivescapturedInActivity(activityID); // Fetching the Ncos created in This meeting.

    // get All pending objectives
    let pendingCallObjectives : Array<NextCallObjective> = this.nextCallObjectives.filter(nco => {
      return !nco.isComplete && !nco.deletestate && comparisionIds.includes(nco.userId); // this is to get the pending and self owned Objectives
    });

    let allCallObjectivesBasedOnContacts: Array<NextCallObjective> = [];
    // Filter Contacts Based on Contacts, for All Pending Objectives, match if ContactIDString contacts Objective's
    // ContactsID.a2
    if (contactIdList.length <= (this.activityService.selectedActivity as AppointmentActivity).contacts.length) {
      let contactIDString = contactIdList.toString();
      allCallObjectivesBasedOnContacts = [...pendingCallObjectives.filter(objective => {
        return contactIDString.indexOf(objective.contactId) !== -1;
      })];
    }


    // Filter Again based on length of contact IDs and number of objectives having same captured in meeting and same combination of contacts
    //
      let filteredObjectives: NextCallObjective[] = [];
      let objectivesSharingSameCapturedIDandContacts: NextCallObjective[] = [];

     filteredObjectives = ncosCreatedInMeeting;

    allCallObjectivesBasedOnContacts.forEach(objective => {
      //For one Objectives Get all objectives having same meeting captured in ID and same number of contacts.

      let externalIDArray = objective.externalid?objective.externalid.split('--'):[];
      let externalIDFirstHalf = externalIDArray.length>0 ? externalIDArray[0] : objective.externalid;
      let objectivesCreatedOnceInOneShot = allCallObjectivesBasedOnContacts.filter(anObjective => {
        return anObjective.externalid.indexOf(externalIDFirstHalf) !== -1;
      });

      if (objectivesCreatedOnceInOneShot.length === contactIdList.length && !objectivesSharingSameCapturedIDandContacts.find(item => {
            return objective.externalid === item.externalid
          })) {
            objectivesSharingSameCapturedIDandContacts.push(objective);
          }
    });

    if (ncosCreatedInMeeting.length > objectivesSharingSameCapturedIDandContacts.length) {

      filteredObjectives = [...ncosCreatedInMeeting];

      objectivesSharingSameCapturedIDandContacts.forEach( objective => {

        let foundObjective  = ncosCreatedInMeeting.find(anObjective => {
          return anObjective.externalid === objective.externalid;
        });
        if (!foundObjective) { // if objective Does not exist in
          filteredObjectives.push(foundObjective);
        }
      });
    } else {
       filteredObjectives = [...objectivesSharingSameCapturedIDandContacts];
      ncosCreatedInMeeting.forEach(objective => {
        let foundObjective = objectivesSharingSameCapturedIDandContacts.find(anObjective => {
          return anObjective.externalid === objective.externalid;
        });
        if (!foundObjective) { // if objective Does not exist in
          filteredObjectives.push(foundObjective);
        }
      });
    }
    return filteredObjectives;
  }

  /**
   * @description This method gets the nco list which are created in the meeting with the meeting id
   * @param {string=} meetingID to get captured nco with the meeting id
  */
  public getNextCallObjectivescapturedInActivity(activityID: string): Array<NextCallObjective> {

    let ncosCapturedInMeeting: Array<NextCallObjective> = [];

     ncosCapturedInMeeting  = this.nextCallObjectives.filter(nco => { // Filtering out Next call Objectives Which are created in teh meeting.
      return nco.capturedMeetingId === activityID
    });
    return ncosCapturedInMeeting
  }

  /**
   * @description This method gets all the pending nco with the current meeting  id
   * @param {string=} meetingID to get all the pending nco in the current meeting with the meeting id
  */
  public getPendingObjectiveForMeeting(meetingID: string): Array<NextCallObjective> {
    let results: Array<NextCallObjective> = [];
    if (meetingID) {
      results = this.nextCallObjectives.filter(nco => {
        return nco.capturedMeetingId === meetingID && !nco.deletestate;
      });
    }
    return results;
  }

  /**
   * @description This method gets the completed nco list with the meeting id
   * @param {string=} meetingID to get completed nco with the meeting id
  */
  public getCompletedObjectiveForMeeting(meetingID: string): Array<NextCallObjective> {
    let results: Array<NextCallObjective> = [];
    if (meetingID) {
      results = this.nextCallObjectives.filter(nco => {
        return nco.isComplete && nco.completedMeetingId === meetingID && !nco.deletestate;
      });
    }
    return results;
  }

  /**
   * @description This method gets the nco list with the external id of the nco used in the case of multiple customers in the meeting
   * @param {string=} externalID from updateObjective method the nco external id is passed to update
  */
  public getObjectivesWithExternalID(externalID: string) {

    const externalIDArray = externalID.split('--');

    const externalFirstHalf = externalIDArray.length > 0 ? externalIDArray[0] : externalID;

    let foundObjectives =  this.nextCallObjectives.filter(objective => {
      if (objective.externalid){
      return objective.externalid.indexOf(externalFirstHalf) !== -1;
      }
      return false;
    });
    return foundObjectives;
  }
}
