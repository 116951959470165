import { Component } from '@angular/core';
import { LocalizationService, Language } from '../../../services/localization/localization.service';
import { NavController } from '@ionic/angular';
import { UIService, AppView } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert/alert.service';

/**
 * Generated class for the LanguageListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'language-list',
  templateUrl: 'language-list.html',
  styleUrls:['language-list.scss']
})
export class LanguageListComponent {

  public languages:Language[];
  public selectedLanguageCode:string;

  constructor(public languageService:LocalizationService,
    public uiService:UIService,
    public navCtrl: NavController,
    public device: DeviceService,
    private alertService: AlertService,
    public translate:TranslateService
    ) {

  }

  ngOnInit(){

      this.languages = this.languageService.getLanguages();
      this.selectedLanguageCode = this.languageService.selectedLanguage.code;
  }
  /**
   * Set language selection to language service
   *
   * @memberof LanguageListComponent
   */
  changeLanguage(value){
    if(value && value != this.languageService.selectedLanguage.code){
      console.log(value);
      this.showConfirm(value);
    }
    //TODO: Add code to set language selection.
  }

  showConfirm(setLang) {

    let langName = this.languages.filter(function(lang){ return lang.code === setLang});

    this.alertService.showAlert({
      title: this.translate.instant('LANGUAGE_LIST_ALERT_TITLE'),
      message: this.translate.instant('LANGUAGE_LIST_ALERT_MESSGE', {langName : langName[0].name})}, this.translate.instant('CHANGE')
    ).then (res => {
      if(res.role == "ok") {
        this.languageService.selectedLanguage.code = setLang;
        this.translate.use(setLang)
      } else if(res.role == "cancel") {
        this.selectedLanguageCode = this.languageService.selectedLanguage.code;
      }
    });
  }
  goBack(){
    this.uiService.showNewActivity = false;
    this.uiService.activeView = AppView.SETTINGS;
    this.uiService.showRightPane = false;
  }
}
