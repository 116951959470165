import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { EventsService } from '../events/events.service';
import { DeviceService } from '../device/device.service';
import { Subject, Observable } from 'rxjs';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { XperiencesService } from '../xperiences/xperiences.service';
import { UIService } from '../ui/ui.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { CallPlanOfflineService } from '../call-plan/call-plan.offline.service';
import { ActivityService } from '../../services/activity/activity.service';
import { Events } from '@omni/events';
import { AllocationFeatureService } from '../sample/allocation.feature.service';
import { FeatureActionsService } from '../feature-actions/feature-actions.service';

@Injectable({
  providedIn: 'root'
})
export class AgendaFooterService {

  public actSegment: string = "agenda";
  public planTabSegment: string = "planTabTodo";
  public callPlanToolSegment: string = "call-plan-current";
  xperiencesFooterIcons = ['impact-contacts', 'impact-saved-contact', 'impact-location'];
  callPlansFooterIcons = ['call-plan-current', 'call-plan-past', 'call-plan-future'];
  plansTabFooterIcons = [];

  public actionButtons: FooterIcon[] = [
    {
      id: 'short-call',
      iconurl: 'assets/imgs/footer-home-inactive.svg',
      isactive: false,
      isvisible: false,
      name: 'HOME',
      align: 'right',
      group: "Act",
      shouldbeinmore: false,
      order: 1,

    },
    {
      id: 'short-call-active',
      iconurl: 'assets/imgs/footer-home-active.svg',
      isactive: false,
      isvisible: false,
      name: 'HOME',
      align: 'right',
      group: "Act",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'approvals',
      iconurl: 'assets/imgs/act-approvals.svg',
      isactive: false,
      isvisible: false,
      name: 'APPROVALS',
      align: 'right',
      group: 'Act',
      shouldbeinmore: false,
      order: 4
    },
    {
      id: 'approvals-active',
      iconurl: 'assets/imgs/act-approvals-active.svg',
      isactive: false,
      isvisible: false,
      name: 'APPROVALS',
      align: 'right',
      group: 'Act',
      shouldbeinmore: false,
      order: 4
    },
    {
      id: 'scheduler',
      iconurl: 'assets/imgs/act-scheduler.svg',
      isactive: false,
      isvisible: false,
      name: 'SCHEDULER',
      align: 'right',
      group: 'Act',
      shouldbeinmore: false,
      order: 3
    },
    {
      id: 'scheduler-active',
      iconurl: 'assets/imgs/act-scheduler-active.svg',
      isactive: false,
      isvisible: false,
      name: 'SCHEDULER',
      align: 'right',
      group: 'Act',
      shouldbeinmore: false,
      order: 3
    },
    {
      id: 'act',
      iconurl: 'assets/imgs/act-list.svg',
      isactive: false,
      isvisible: false,
      name: 'LIST',
      align: 'right',
      group: "Act",
      shouldbeinmore: false,
      order: 1,

    },
    {
      id: 'act-active',
      iconurl: 'assets/imgs/act-list-active.svg',
      isactive: false,
      isvisible: false,
      name: 'LIST',
      align: 'right',
      group: "Act",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'impact-feed',
      iconurl: 'assets/imgs/impact-feed.svg',
      isactive: false,
      isvisible: false,
      name: 'FEED',
      align: 'left',
      group: "Impact",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'impact-feed-active',
      iconurl: 'assets/imgs/impact-feed-active.svg',
      isactive: false,
      isvisible: false,
      name: 'FEED',
      align: 'left',
      group: "Impact",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'impact-contacts',
      iconurl: 'assets/imgs/impact-contact.svg',
      isactive: false,
      isvisible: false,
      name: 'CUSTOMERS',
      align: 'left',
      group: "Impact",
      shouldbeinmore: false,
      order: 2
    },
    {
      id: 'impact-contacts-active',
      iconurl: 'assets/imgs/impact-contact-active.svg',
      isactive: false,
      isvisible: false,
      name: 'CUSTOMERS',
      align: 'left',
      group: "Impact",
      shouldbeinmore: false,
      order: 2
    },
    {
      id: 'impact-accounts',
      iconurl: 'assets/imgs/impact-account.svg',
      isactive: false,
      isvisible: false,
      name: 'ACCOUNTS',
      align: 'left',
      group: "Impact",
      shouldbeinmore: false,
      order: 3
    },
    {
      id: 'impact-accounts-active',
      iconurl: 'assets/imgs/impact-account-active.svg',
      isactive: false,
      isvisible: false,
      name: 'ACCOUNTS',
      align: 'left',
      group: "Impact",
      shouldbeinmore: false,
      order: 3
    },
    {
      id: 'impact-saved-contact',
      iconurl: 'assets/imgs/impact-contact-saved.svg',
      isactive: false,
      isvisible: false,
      name: 'SAVED',
      align: 'right',
      group: "Impact",
      shouldbeinmore: false,
      order: 4
    },
    {
      id: 'impact-saved-contact-active',
      iconurl: 'assets/imgs/impact-contact-saved-active.svg',
      isactive: false,
      isvisible: false,
      name: 'SAVED',
      align: 'right',
      group: "Impact",
      shouldbeinmore: false,
      order: 4
    },
    {
      id: 'impact-location',
      iconurl: 'assets/imgs/impact-location.svg',
      isactive: false,
      isvisible: false,
      name: 'ACTIVITY_DETAILS_LOCATION',
      align: 'right',
      group: "Impact",
      shouldbeinmore: false,
      order: 5
    },
    {
      id: 'impact-location-active',
      iconurl: 'assets/imgs/impact-location-active.svg',
      isactive: false,
      isvisible: false,
      name: 'ACTIVITY_DETAILS_LOCATION',
      align: 'right',
      group: "Impact",
      shouldbeinmore: false,
      order: 5
    },
    {
      id: 'plantab-todo',
      iconurl: 'assets/imgs/to-do.svg',
      isactive: false,
      isvisible: false,
      name: 'TODO',
      align: 'right',
      group: "plantab",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'plantab-todo-active',
      iconurl: 'assets/imgs/to-do-active.svg',
      isactive: false,
      isvisible: false,
      name: 'TODO',
      align: 'right',
      group: "plantab",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'plantab-goals-plan',
      iconurl: 'assets/imgs/goals-plan.svg',
      isactive: false,
      isvisible: false,
      name: 'GOALS',
      align: 'right',
      group: "plantab",
      shouldbeinmore: false,
      order: 1

    },
    {
      id: 'plantab-goals-plan-active',
      iconurl: 'assets/imgs/goals-plan.svg',
      isactive: false,
      isvisible: false,
      name: 'GOALS',
      align: 'right',
      group: "plantab",
      shouldbeinmore: false,
      order: 1

    }, 
    {
      id: 'plantab-goals',
      iconurl: 'assets/imgs/plantab-call-plan.png',
      isactive: false,
      isvisible: false,
      name: 'GOALS',
      align: 'right',
      group: "plantab",
      shouldbeinmore: false,
      order: 2

    },
     {
      id: 'plantab-goals-active',
      iconurl: 'assets/imgs/plantab-scientific-plan-active.png',
      isactive: false,
      isvisible: false,
      name: 'GOALS',
      align: 'right',
      group: "plantab",
      shouldbeinmore: false,
      order: 2
    },
    {
      id: 'week',
      iconurl: 'assets/imgs/act-week.svg',
      isactive: false,
      isvisible: false,
      name: 'CALENDAR',
      align: 'right',
      group: "Act",
      shouldbeinmore: false,
      order: 2
    },
    {
      id: 'week-active',
      iconurl: 'assets/imgs/act-week-active.svg',
      isactive: false,
      isvisible: false,
      name: 'CALENDAR',
      align: 'right',
      group: "Act",
      shouldbeinmore: false,
      order: 2,
    },
    // {
    //   id: 'plantab-notification',
    //   iconurl: 'assets/imgs/notifications-inactive.svg',
    //   isactive: false,
    //   isvisible: false,
    //   name: 'NOTIFICATIONS',
    //   align: 'right',
    //   group: "plantab",
    //   shouldbeinmore: false,
    //   order: 2
    // },
    // {
    //   id: 'plantab-notification-active',
    //   iconurl: 'assets/imgs/notifications-active.svg',
    //   isactive: false,
    //   isvisible: false,
    //   name: 'NOTIFICATIONS',
    //   align: 'right',
    //   group: "plantab",
    //   shouldbeinmore: false,
    //   order: 2
    // },
    {
      id: "openmoreoptions",
      iconurl: "assets/imgs/action_button_more.svg",
      isactive: true,
      isvisible: false,
      name: "MORE",
      align: "right",
      group: "general",
      shouldbeinmore: false,
      order: 100

    },
    {
      id: "openmoreoptions-active",
      iconurl: "assets/imgs/action_button_more.svg",
      isactive: true,
      isvisible: false,
      name: "MORE",
      align: "right",
      group: "general",
      shouldbeinmore: false,
      order: 100
    },
    {
      id: 'call-plan-current',
      iconurl: 'assets/imgs/call-plan-current.svg',
      isactive: false,
      isvisible: false,
      name: 'CALL_PLAN_CURRENT',
      align: 'right',
      group: "callplan",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'call-plan-current-active',
      iconurl: 'assets/imgs/call-plan-current-active.svg',
      isactive: false,
      isvisible: false,
      name: 'CALL_PLAN_CURRENT',
      align: 'right',
      group: "callplan",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'call-plan-past',
      iconurl: 'assets/imgs/call-plan-past.svg',
      isactive: false,
      isvisible: false,
      name: 'CALL_PLAN_PAST',
      align: 'right',
      group: "callplan",
      shouldbeinmore: false,
      order: 2
    },
    {
      id: 'call-plan-past-active',
      iconurl: 'assets/imgs/call-plan-past-active.svg',
      isactive: false,
      isvisible: false,
      name: 'CALL_PLAN_PAST',
      align: 'right',
      group: "callplan",
      shouldbeinmore: false,
      order: 2
    },
    {
      id: 'call-plan-future',
      iconurl: 'assets/imgs/call-plan-future.svg',
      isactive: false,
      isvisible: false,
      name: 'CALL_PLAN_FUTURE',
      align: 'right',
      group: "callplan",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'call-plan-future-active',
      iconurl: 'assets/imgs/call-plan-future-active.svg',
      isactive: false,
      isvisible: false,
      name: 'CALL_PLAN_FUTURE',
      align: 'right',
      group: "callplan",
      shouldbeinmore: false,
      order: 1
    },
    // {
    //   id: 'kol-status-management',
    //   iconurl: 'assets/imgs/call-plan-future-active.svg',
    //   isactive: false,
    //   isvisible: false,
    //   name: 'KOL_MANAGEMENT',
    //   align: 'left',
    //   group: "contacts",
    //   shouldbeinmore: false,
    //   order: 1
    // }
    {
      id: 'saveFilter',
      iconurl: 'assets/imgs/save_filter.svg',
      isactive: false,
      isvisible: false,
      name: 'SAVE_FILTER',
      align: 'right',
      group: "filter",
      shouldbeinmore: false,
      order: 1
    },
    {
      id: 'notifications',
      iconurl: 'assets/imgs/footer-notifications-inactive.svg',
      isactive: false,
      isvisible: false,
      name: 'NOTIFICATIONS',
      align: 'right',
      group: "filter",
      shouldbeinmore: false,
      order: 200
    },
    {
      id: 'notifications-active',
      iconurl: 'assets/imgs/footer-notifications-active.svg',
      isactive: false,
      isvisible: false,
      name: 'NOTIFICATIONS',
      align: 'right',
      group: "filter",
      shouldbeinmore: false,
      order: 200
    },
  ];

  private _agendaFooterBtnDblClick$: Subject<string> = new Subject();
  readonly agendaFooterBtnDblClick$: Observable<string> = this._agendaFooterBtnDblClick$.asObservable();
  public lastFooterView:AgendaFooterView;
  public footerViewAtHome:AgendaFooterView;

  constructor(private authService: AuthenticationService, private eventService: EventsService,
    private device: DeviceService, private alertService: AlertService, private translate: TranslateService,
    private xpService: XperiencesService, public uiService: UIService,
    private navService: NavigationService, public callPlanOfflineService: CallPlanOfflineService,
    private events: Events,
    private allocFeatureService: AllocationFeatureService,
    private activityService: ActivityService,
    private faService: FeatureActionsService,
  ) {
    this.device.isOfflineObservable.subscribe((data) => {
      if (data) {
        this.disableButton(['impact-location']);
      } else {
        this.enableButtons(['impact-location']);
      }
    });

    this.eventService.observe("sync:completed")
      .subscribe(() => {
        const index = this.xperiencesFooterIcons.findIndex(btn => btn == 'impact-accounts');
        if (!this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_TRENDING_PILL)) {
          if (index >= 0) {
            this.xperiencesFooterIcons.splice(index, 1);
          }
        } else {
          if (index < 0) {
            this.xperiencesFooterIcons.splice(1, 0, 'impact-accounts');
          }
        }

        // add or remove scheduler footer icon based on feature action
        let schedulerButtons = this.actionButtons.filter(btn => btn.id == 'scheduler' || btn.id == 'scheduler-active');
        if (!this.faService.isShortCallLauncherEnabled && this.authService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && this.uiService.activitiesPageTab == 'agenda') {
          if (!schedulerButtons.some(btn => btn.isvisible)) {
            let schedulerButton = schedulerButtons.find(btn => btn.id == 'scheduler');
            schedulerButton.isvisible = true;
            schedulerButton.isactive = !this.activityService.teamViewActive;
          }
        } else if (!this.faService.isShortCallLauncherEnabled && schedulerButtons.some(btn => btn.isvisible)) {
            schedulerButtons.filter(btn => btn.isvisible).forEach(btn => {
              if (btn.id == 'scheduler-active') {
                this.actSegment = 'agenda';
                this.initButtons(AgendaFooterView.Act);
              }
              btn.isvisible = false;
            });
        }

        // add or remove approvals footer icon based on feature action
        let approvalsButtons = this.actionButtons.filter(btn => btn.id == 'approvals' || btn.id == 'approvals-active');
        const isApprovalsEnabled = this.authService.user.buConfigs['indskr_approvalsongoapp']; 
        if (!this.faService.isShortCallLauncherEnabled && isApprovalsEnabled && this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN) && this.uiService.activitiesPageTab == 'agenda') {
          if (!approvalsButtons.some(btn => btn.isvisible)) {
            let approvalsButton = approvalsButtons.find(btn => btn.id == 'approvals');
            approvalsButton.isvisible = true;
            approvalsButton.isactive = true;
          }
        } else if (!this.faService.isShortCallLauncherEnabled && approvalsButtons.some(btn => btn.isvisible)) {
            approvalsButtons.filter(btn => btn.isvisible).forEach(btn => {
              if (btn.id == 'approvals-active') {
                this.actSegment = 'agenda';
                this.initButtons(AgendaFooterView.Act);
              }
              btn.isvisible = false;
            });
        }

        // To check if XPERIENCES_FEED Dynamics Feature action is present and set All pill as default after SYNC completed
        this.enableDisableFeedButton();

        if (this.uiService.activitiesPageTab === 'plans') {
          this.enableDisablePlansTabButton();
        }
      });


    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_TRENDING_PILL)) {
      this.xperiencesFooterIcons.splice(1, 0, 'impact-accounts');
    }
    // To check if XPERIENCES_FEED Dynamics Feature action is present and set All pill as default
    if (this.authService.hasFeatureAction(FeatureActionsMap.XPERIENCES_FEED)) {
      this.xperiencesFooterIcons.splice(0, 0, 'impact-feed');
    }
    if (this.uiService.activitiesPageTab === 'plans') {
      this.enableDisablePlansTabButton();
    }
  }

  enableDisableFeedButton() {
    const feedIndex = this.xperiencesFooterIcons.findIndex(btn => btn == 'impact-feed');
    if (!this.authService.hasFeatureAction(FeatureActionsMap.XPERIENCES_FEED)) {
      // To check if All pill is present then Remove the all pill and set Customer pill as default
      if (feedIndex >= 0) {
        this.xperiencesFooterIcons.splice(feedIndex, 1);
      }
    } else {
      // To check if All pill is not present then Add it and set it as default
      if (feedIndex < 0) {
        this.xperiencesFooterIcons.splice(0, 0, 'impact-feed');
      }
    }
  }

  enableDisablePlansTabButton() {
    this.plansTabFooterIcons = [];

        this.plansTabFooterIcons = ['plantab-todo', 'plantab-notification'];

    // if (this.authService.hasFeatureAction(FeatureActionsMap.CALL_PLANS) ||
    //     this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT) ||
    //     this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN)) {
    //   this.plansTabFooterIcons.splice(1, 0, 'plantab-goals');
    // }
    if ((this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT))||(this.authService.hasFeatureAction(FeatureActionsMap.CALL_PLANS))||(this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN))) {
      this.plansTabFooterIcons.splice(2, 0, 'plantab-goals-plan');
    }
    if (this.faService.isShortCallLauncherEnabled) {
      this.plansTabFooterIcons = ['short-call', 'act-active', 'week', 'notifications'];
    }
    if (this.plansTabFooterIcons.length == 1) {
      this.plansTabFooterIcons = [];
      // In case of added plan feature on the other tabs
      // if(this.uiService.activitiesPageTab != 'plans') {
      //   this.events.publish("refreshTab");
      // }
    }
  }

  initAgendaFooterButtons() {
    if(this.uiService.activitiesPageTab == 'agenda') {
      if(this.actSegment == 'agenda') {
        this.initButtons(AgendaFooterView.Act);
      } else if(this.actSegment == 'week') {
        this.initButtons(AgendaFooterView.Week);
      } else if(this.actSegment == 'scheduler') {
        this.initButtons(AgendaFooterView.Scheduler);
      } else if(this.actSegment == 'approvals') {
        this.initButtons(AgendaFooterView.Act);
      }
    } else if(this.uiService.activitiesPageTab == 'Impact') {
      this.initButtons(AgendaFooterView.ImpactFeed);
    } else if(this.uiService.activitiesPageTab == 'edgeAnalytics' || this.uiService.activitiesPageTab == 'notifications') {

    }

  }

  updateButtons(keys: Array<string>, hidden) {

    this.actionButtons.forEach(value => {
      if (keys.indexOf(value.id) > -1) {
        value.isvisible = hidden;
      } else {
        value.isvisible = !hidden;
      }
      value.shouldbeinmore = false;
    });

    const numberOfMoreButtons = this.getMoreButtonNumbers(); // take out the buttons to be put in more popover.

    if (numberOfMoreButtons > 0) {
      const visibleButtons = this.actionButtons.filter(btn => btn.isvisible);
      const intoolbarbutton = visibleButtons.reverse().splice(0, visibleButtons.length - (numberOfMoreButtons + 1));

      this.actionButtons.filter(btn => btn.isvisible).forEach(button => {
        button['shouldbeinmore'] = intoolbarbutton.indexOf(button) != -1 ? false : true;
      });

      this.actionButtons.filter(btn => btn.id === 'openmoreoptions').forEach(btn => {
        btn.isvisible = true;
        btn.isactive = true;
      });
    }
    this.actionButtons = this.actionButtons.sort((btn1, btn2) => 0 - (btn1.order > btn2.order ? 1 : -1));
    const btns = this.actionButtons.filter(btn => btn.isvisible);
  }


  enableButtons(keys?: Array<string>, enable = true) {
    this.actionButtons.forEach(value => {
      if (keys && keys.indexOf(value.id) > -1) {
        if (this.device.isOffline && value.id === 'impact-location') {
          value.isactive = false;
        } else {
          value.isactive = enable;
        }
      }
    })
  }

  disableButton(keys?: Array<string>) {
    this.actionButtons.forEach(value => {
      if (keys && keys.indexOf(value.id) > -1) {
        value.isactive = false;
      } else {
        value.isactive = true;
      }
    });
  }


  public initButtons(view: AgendaFooterView, afterSync:boolean = false) {
    this.lastFooterView=view;
    let keys: Array<string>;
    switch (view) {
      case AgendaFooterView.Act: {
        let actTab = ["act-active", "week"];
        this.actSegment = "agenda";
        if (this.faService.isShortCallLauncherEnabled) {
          actTab.unshift('short-call');
          actTab.push('notifications');
        } else {
          const isSchedulerEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SCHEDULER);
          if (isSchedulerEnabled) {
            actTab.push("scheduler");
          }
          const isApprovalsEnabled = this.authService.user.buConfigs['indskr_approvalsongoapp']; 
          const isManualGPSCheckinFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN); 
          if (isApprovalsEnabled && isManualGPSCheckinFAEnabled) {
            actTab.push("approvals");
          }
        }
        this.updateButtons(actTab, true);
        this.enableButtons(actTab);
        if (!this.faService.isShortCallLauncherEnabled && this.activityService.teamViewActive) {
          this.enableButtons(['scheduler'], false);
        }
        keys = actTab;
        this.eventService.publish('refreshAgendHeader');
        // this.activityService.setScrollDate(new Date());
        // this.eventService.publish('scrollToDate');
        break;
      }
      case AgendaFooterView.Week: {
        this.actSegment = "week";
        let weekTab = ["act", "week-active"];
        const isSchedulerEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SCHEDULER);
        if (this.faService.isShortCallLauncherEnabled) {
          weekTab.unshift('short-call');
          weekTab.push('notifications');
        } else {
          if (isSchedulerEnabled) {
            weekTab.push("scheduler");
          }
          const isApprovalsEnabled = this.authService.user.buConfigs['indskr_approvalsongoapp']; 
          const isManualGPSCheckinFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN);
          if (isApprovalsEnabled && isManualGPSCheckinFAEnabled) {
            weekTab.push("approvals");
          }
        }

        this.updateButtons(weekTab, true);
        this.enableButtons(weekTab);
        if (!this.faService.isShortCallLauncherEnabled && isSchedulerEnabled && this.activityService.teamViewActive) {
          this.enableButtons(['scheduler'], false);
        }
        keys = weekTab;
        this.uiService.toolsActivityActive = true;
        this.eventService.publish('refreshAgendHeader');
        const weekCol = document.getElementById('week');
        weekCol?.classList?.add('col-selected')
        break;
      }
      case AgendaFooterView.Scheduler:
        this.actSegment = "scheduler";
        let schTab = ["act", "week", "scheduler-active"];
        const isApprovalsEnabled = this.authService.user.buConfigs['indskr_approvalsongoapp']; 
        const isManualGPSCheckinFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN); 
        if (isApprovalsEnabled && isManualGPSCheckinFAEnabled) {
          schTab.push("approvals");
        }
        this.updateButtons(schTab, true);
        this.enableButtons(schTab);
        keys = schTab;
        this.eventService.publish('refreshAgendHeader');
        break;
      case AgendaFooterView.Approvals:
        this.actSegment = "approvals";
        let appTab = ["act", "week", "approvals-active"];
        const isSchedulerEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SCHEDULER);
        if (isSchedulerEnabled) {
          appTab.push("scheduler");
        }
        this.updateButtons(appTab, true);
        this.enableButtons(appTab);
        keys = appTab;
        this.eventService.publish('refreshAgendHeader');
        break;
      case AgendaFooterView.ImpactFeed:
        const buttons = this.getIcons('impact-feed');
        this.updateButtons(buttons, true);
        this.enableButtons(buttons);
        keys = buttons;

        break;
      case AgendaFooterView.ImpactContacts:
        let contactButtons = this.getIcons('impact-contacts');
        this.updateButtons(contactButtons, true);
        this.enableButtons(contactButtons);
        keys = contactButtons;

        break;
      case AgendaFooterView.ImpactAccounts:
        let accountButtons = this.getIcons('impact-accounts');
        keys = accountButtons;
        this.updateButtons(accountButtons, true);
        this.enableButtons(accountButtons);
        keys = accountButtons;

        break;
      case AgendaFooterView.ImpactSavedContacts:
        let savedContactButtons = this.getIcons('impact-saved-contact');
        keys = savedContactButtons;
        this.updateButtons(savedContactButtons, true);
        this.enableButtons(savedContactButtons);
        keys = savedContactButtons
        break;
      case AgendaFooterView.ImpactLocation:
        let locationButtons = this.getIcons('impact-location');
        keys = locationButtons;
        this.updateButtons(locationButtons, true);
        this.enableButtons(locationButtons);
        keys = locationButtons

        break;
      case AgendaFooterView.PlanGoalsPlan:
        let accPlanTab = this.getIconsForPlansTab('plantab-goals-plan');
        this.planTabSegment = "goalsPlansTab";
        this.uiService.activitiesPagePlanTabSelectedPlan = "goalsPlansTab";
        this.updateButtons(accPlanTab, true);
        this.enableButtons(accPlanTab);
        if(!afterSync && this.navService.getCurrentPageName() !== PageName.PresentationMeetingComponent) this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        break;
      case AgendaFooterView.PlanNotifications:
        let notificationTab = this.getIconsForPlansTab('plantab-notification');
        this.planTabSegment = "notifications";
        this.uiService.activitiesPagePlanTabSelectedPlan = 'notifications';
        this.updateButtons(notificationTab, true);
        this.enableButtons(notificationTab);
        if(!afterSync && this.navService.getCurrentPageName() !== PageName.PresentationMeetingComponent) this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        break;
      case AgendaFooterView.PlanTODO:
        let planToDo = this.getIconsForPlansTab('plantab-todo');
        this.planTabSegment = "planTabTodo";
        this.uiService.activitiesPagePlanTabSelectedPlan = 'planTabTodo';
        this.updateButtons(planToDo, true);
        this.enableButtons(planToDo);
        this.uiService.toolsActivityActive = false;
        if(!afterSync && this.navService.getCurrentPageName() !== PageName.PresentationMeetingComponent) {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
          this.uiService.showRightPane = false;
        }
        break;
      case AgendaFooterView.PlanGoals:
        let planTabGoals = this.getIconsForPlansTab('plantab-goals');
        this.planTabSegment = "planTabGoals";
        this.uiService.activitiesPagePlanTabSelectedPlan = 'planTabGoals';
        this.updateButtons(planTabGoals, true);
        this.enableButtons(planTabGoals);
        if(!afterSync && this.navService.getCurrentPageName() !== PageName.PresentationMeetingComponent) this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        break;
      case AgendaFooterView.CallPlanCurrent:
        let currentBtns = this.getIconsForCallPlansTab('call-plan-current');
        this.updateButtons(currentBtns, true);
        this.enableButtons(currentBtns);
        break;
      case AgendaFooterView.CallPlanPast:
        let pastBtns = this.getIconsForCallPlansTab('call-plan-past');
        this.updateButtons(pastBtns, true);
        this.enableButtons(pastBtns);
        break;
      case AgendaFooterView.CallPlanFuture:
        let futureBtns = this.getIconsForCallPlansTab('call-plan-future');
        this.updateButtons(futureBtns, true);
        this.enableButtons(futureBtns);
        break;
      // case AgendaFooterView.ContactKOL:
      //   this.updateButtons(['kol-status-management'], true);
      //   this.enableButtons(['kol-status-management']);    
      //   break;
      case AgendaFooterView.FilterMenu:
        let FilterBtns = ['saveFilter'];
        this.updateButtons(FilterBtns, true);
        break;
      case AgendaFooterView.ShortCallLauncher: {
        const shortCallTab = ['short-call-active', 'act', 'week', 'notifications'];
        this.updateButtons(shortCallTab, true);
        this.enableButtons(shortCallTab);
        break;
      }
      case AgendaFooterView.Notifications: {
        const notificationsTab = ['short-call', 'act', 'week', 'notifications-active'];
        this.updateButtons(notificationsTab, true);
        this.enableButtons(notificationsTab);
        break;
      }
      default:
        break;
    }
    // this.updateSelection(keys);
  }

  // private updateSelection(keys: Array<string>) {
  //   keys.forEach(key => {
  //       if (key.indexOf('active') <= -1) {

  //         const keyParts = key.split('-')
  //         let colElement = document.getElementById(keyParts[0]);
  //         if (colElement)
  //           colElement.classList.remove('col-selecetd');
  //       } else {
  //         let colElement = document.getElementById(keyParts[0]);
  //         colElement.classList.add('col-selected');
  //       }
  //     }
  //   );
  // }

  // appends active to the button name
  getIcons(iconName) {
    return this.xperiencesFooterIcons.map((xfi) => {
      if (xfi === iconName) {
        return xfi + '-active';
      }
      return xfi;
    });
  }

  getIconsForPlansTab(iconName) {
    return this.plansTabFooterIcons.map((plan) => {
      if (plan === iconName) {
        return plan + '-active';
      }
      return plan;
    });
  }

  getIconsForCallPlansTab(iconName) {
    return this.callPlansFooterIcons.map((plan) => {
      if (plan === iconName) {
        return plan + '-active';
      }
      return plan;
    });
  }

  showDiscardPopup(): Promise<any> {
    return this.alertService.showAlert({
      title: this.translate.instant('DISCARD_CHANGES'),
      message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
    }, this.translate.instant('DISCARD')
    );
  }

  public getMoreButtonNumbers() {
    let visibleButtons = this.actionButtons.filter(btn => btn.isvisible);
    const visibleButons: number = visibleButtons.length;
    let totalButtonsToFit = 6
    if (this.device.isMobileDevice && this.device.isMobileDevicePortrait === false) {
      totalButtonsToFit = 4
    }
    // if (this.device.deviceFlags.ios || this.device.deviceFlags.ios || this.device.deviceFlags.electron) {
    //   totalButtonsToFit = 6
    // }
    return visibleButons - totalButtonsToFit;
  }

  publishBtnDblClickEvent(buttonId: string) {
    this._agendaFooterBtnDblClick$.next(buttonId);
  }
}

interface FooterIcon {
  "id": string,
  "iconurl": string,
  "isactive": boolean,
  "isvisible": boolean,
  "name": string,
  "align": string,
  "group": string,
  "shouldbeinmore": boolean,
  "order": number
}

export enum AgendaFooterView {
  Act = "Act",
  Week = "Week",
  Scheduler = "Scheduler",
  Approvals = "Approvals",
  ImpactFeed = "ImpactFeed",
  ImpactContacts = "ImpactContacts",
  ImpactAccounts = "ImpactAccounts",
  ImpactSavedContacts = "ImpactSavedContacts",
  ImpactLocation = "ImpactLocation",
  PlanScientificPlan = "PlanScientificPlan",
  PlanNotifications = "PlanNotifications",
  PlanCallPlan = "PlanCallPlan",
  PlanAccountPlan = "PlanAccountPlan",
  PlanTODO = "PlanToDo",
  PlanGoals = "PlanGoals",
  PlanGoalsPlan="goalsPlansTab",
  CallPlanCurrent="CallPlanCurrent",
  CallPlanPast="CallPlanPast",
  CallPlanFuture="CallPlanFuture",
  FilterMenu="FilterMenu",
  ShortCallLauncher = 'ShortCallLauncher',
  Notifications = 'Notifications',
  //ContactKOL="ContactKolManagement"
}
