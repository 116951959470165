export class AzureStorageDetails {
    accessKey: string;
    containerName: string;
    serviceURL: string;
    sasToken: string;
    storageAccount: string

    constructor (raw: object) {
        this.storageAccount = raw['storageAccount'];
        this.accessKey = raw['accessKey'];
        this.containerName = raw['containerName'];
        this.serviceURL = raw['serviceURL'];
        this.sasToken = raw['sasToken'];
    }
}