import { Injectable, Injector } from "@angular/core";
import { ToastController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";

//CSS STYLE FOR TOAST
export enum ToastStyle {
    //SUCCESS = 'toast-success', //GREEN
    DANGER = 'toast-danger', //RED
    INFO = 'toast-info', //BLACK
}

/**
 * A service for handling all notifications presented to the user and a wrapper for ionic toasts
 *
 * @export
 * @class NotificationService
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    private currentToast: HTMLIonToastElement;
    private translate;

    constructor(
        private toastController: ToastController,

        private injector: Injector
    ) {
        setTimeout(() => { this.translate = this.injector.get(TranslateService)});
    }

    /**
     * Creates a toast notification as well as saves for history
     *
     * @param {string} message: Message to display
     * @param {string} from: Where you are calling the notification from
     * @memberof NotificationService
     */
    public notify (
        message: string,
        from: string,
        position: 'top' | 'bottom' | 'middle' = 'top',
        cssClass: ToastStyle | string = ToastStyle.INFO,
        dur: number = 3000,
        close: boolean = true,
        buttonText: string = this.translate.instant('CLOSE'),
        onDismissCallback?: () => void,
    ) {
        try {
            if(this.currentToast){
                this.currentToast.dismiss();
                this.currentToast = undefined;
            }
            let options = [];
            if (close)
            {
                options = [{
                side : "end",
                text: buttonText ? buttonText : this.translate.instant('CLOSE'),
                role:'close',
                handler: null
                }];

                if (from === "Change request") {
                  options = [{
                    side : "end",
                    text:buttonText?buttonText:this.translate.instant('CLOSE'),
                    role:'close',
                    handler: () => {
                      if (typeof onDismissCallback === 'function') {
                        onDismissCallback();
                      }
                    }
                  }];
                }
            }

            this.toastController.create({
                message: message,
                duration: dur,
                buttons: options,
                //showCloseButton: close,
                //closeButtonText: buttonText?buttonText:this.translate.instant('CLOSE'),
                position: position,
                cssClass: cssClass,
                color : 'dark',
            }).then((toast)=>{
              this.currentToast = toast;
              this.currentToast.present();
              this.currentToast.onWillDismiss().then(()=> {
                this.currentToast = undefined;
                if (typeof onDismissCallback === 'function') {
                  if (from !== "Change request") {
                    onDismissCallback();
                  }
                }
              });
            });

        } catch (error) {
            console.error('Error occurred while delivering toast notification:', error);
        }
    }
}
