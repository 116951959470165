<ion-header>
    <ind-page-title [viewData]='{id:"about-details",title: pageTitle, controls: backButton}' (onControlClick)='onClosePage()'></ind-page-title>
</ion-header>
<ion-content>
  <iframe *ngIf="!deviceService.isNativeApp && isReadyToDisplay" class="about-content" [src]="urlLink"></iframe>
  <ng2-pdfjs-viewer *ngIf="deviceService.isNativeApp && isReadyToDisplay" class="about-content" [pdfSrc]="urlString" openFile="false" viewBookmark="false"
  download="false" find="false" viewBookmark="false" ></ng2-pdfjs-viewer>

  <ind-block-level-loader *ngIf="!isReadyToDisplay"></ind-block-level-loader>
</ion-content>
