<ion-header>
    <ind-page-title [viewData]="orderDetailsPanePageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ion-list class='details-list' *ngIf="currentOrderActivity && allDataInitialized" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}" id="ordermaindetailslist">
        <ind-section-header [viewData]='detailsHeader'></ind-section-header>
        <ind-form-field [viewData]="statusFormField"></ind-form-field>
        <ind-form-field [viewData]="createdByFormField"></ind-form-field>
        <ind-form-field [viewData]="orderNumberFormField"></ind-form-field>
        <ind-form-field [viewData]="countryFormField"></ind-form-field>
        <ind-form-field [viewData]="accountFormField"></ind-form-field>
        <ind-form-field [viewData]="accountPlanFormField"></ind-form-field>
        <ind-form-field [viewData]="opportunityFormField"></ind-form-field>
        <ind-form-field [viewData]="discountFormField"></ind-form-field>
        <ind-form-field [viewData]="totalFormField"></ind-form-field>
        <ind-form-field [viewData]="productsFormField"></ind-form-field>
        <ion-item-group *ngIf="!currentOrderActivity.manualOrder">
            <ind-section-header [viewData]='productsHeader' (onControlClick)='openProductSelect()'></ind-section-header>
            <main-card *ngFor="let product of allProductsMainCardModel" [viewData]="product"></main-card>
            <!-- <ion-item class='selector-item' *ngFor="let product of currentOrderActivity.products|filterBy:{isDeleted:false}">
                <ion-label (click)="openProductSelect(product)">
                  {{product.productName}}
                  <p class="secondaryTextColorAndfont">
                    {{product.formattedTotal}} {{ 'FOR' |translate}} {{product.quantity}} {{ 'OM_UNITS' |translate}}
                  </p>
                </ion-label>
                <ion-icon slot="end" *ngIf="isAddProductEnabled" class='arrow-icon remove-icon' name="indegene-selectors-remove-icon" (click)="removeSelectedProduct(product)" slot="end" ></ion-icon>
            </ion-item> -->
            <ion-item class='order-field-empty-label' *ngIf="(currentOrderActivity.products|filterBy:{isDeleted:false}).length == 0">
                <ion-label></ion-label>
            </ion-item>
        </ion-item-group>

        <!-- Survey grid -->
        <ng-container *ngIf="isSurveyConfigured && surveyService.surveyActiveFor == 'SalesOrder'"> 
          <ind-section-header *ngIf="salesOrderSurveyHeaderModel" [viewData]="salesOrderSurveyHeaderModel">
          </ind-section-header>
          <ind-survey *ngIf="!device.isOffline" [surveyTemplate]="surveyTemplate" [salesOrder]="currentOrderActivity" [tempLookupResult]="surveyLookupResults" (completeSurvey)="updateSurveyResult($event)" (surveyData)="updateSurveyData($event)"></ind-survey>
          <div class="no-data-message" *ngIf="device.isOffline"> {{ 'AVAILABLE_IN_ONLINE_ONLY' | translate}}</div>
        </ng-container>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->
