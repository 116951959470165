<ion-header>
    <ind-page-title [viewData]='{id:"resource-history",title: participant.participant.fullName , controls: backButton}' (onControlClick)='viewdismiss()'></ind-page-title>
</ion-header>
<ion-content no-padding>
    <ng-container *ngIf="participant.statuses && participant.statuses.length > 0">
        <ion-list>
            <ind-section-header [viewData]='{id:"meeting-details",title: translate.instant("RESOURCE_SHARE_HISTORY")}'></ind-section-header>
            <ng-container *ngFor="let item of participant.statuses | orderBy:'date':false">
                <ion-item lines="none">
                    <ion-label class="primary-text">
                        <h3>{{ ResourceParticipantStatusDisplay[item.status] | translate }} - {{ getStatusDate(item.date) }}</h3>
                    </ion-label>
                </ion-item>
                <div class="separationLine"></div>
            </ng-container>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'resourceShareHistory'"></footer-toolbar>
<!-- </ion-footer> -->