import { DeviceService } from './../../../services/device/device.service';
import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
import { ActivityService, ActivitySource } from '../../../services/activity/activity.service';
import { UIService } from "../../../services/ui/ui.service";
import {EmbeddedInteraction, ActivityType, EmbeddedInteractionType, MeetingActivityState} from "../../../classes/activity/activity.class";
import { PhoneCallMeetingStructureService } from '../../../services/phone-call-meeting-structure/phone-call-meeting-structure.service';
import {Activity} from '../../../classes/activity/activity.class'
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { EmailActivity, EmailViewType } from '../../../classes/activity/email.activity.class';
import {EmailService} from '../../../services/email-templates/email.service';
import { CaseManagementService } from "../../../services/case-management/case-management.service";
  import { from ,  Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { PhoneActivity } from '../../../classes/activity/phone.activity.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { CdkDropListGroup, CdkDropList, CdkDrag, moveItemInArray, CdkDragMove } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
/**
 * Generated class for the PhoneCallMeetingStructureComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'phone-call-meeting-structure',
  templateUrl: 'phone-call-meeting-structure.html',
  styleUrls:['phone-call-meeting-structure.scss']
})

export class PhoneCallMeetingStructureComponent {

  public embeddedInteractions: EmbeddedInteraction[];
  private IcurrentPhoneCallMeetingStructureSubscription: Subscription;
  public PHONE_CALL_MEETING_STRUCTURE: string = "PHONE_CALL_MEETING_STRUCTURE";
  @Output() openNewActivityPage = new EventEmitter();
  private activityDisabled : boolean
  public inPhoneCallActivitiesEnabled: boolean = false;
  public contentDisabled;
  public meetingStructureHeader : IndSectionHeaderViewDataModel;
  @Input() backgroundUploadInProgress: boolean;

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  constructor(private translate: TranslateService,
    public trackingService: TrackService,
    private navService: NavigationService,
    public activityService: ActivityService,
    private uiService: UIService,
    private phoneCallMeetingStructureService : PhoneCallMeetingStructureService,
    private emailService : EmailService,
    private caseManagementService : CaseManagementService,
    private alertService : AlertService,
    public authenticationService : AuthenticationService,
    public deviceService: DeviceService,
    private callPlanService: CallPlanOfflineService,
    private viewportRuler: ViewportRuler
  ) {

    // this.dragulaService.destroy(this.PHONE_CALL_MEETING_STRUCTURE);
    //     this.dragulaService.createGroup(this.PHONE_CALL_MEETING_STRUCTURE, {
    //         moves: function (el, container, cHandle) {
    //             if (!cHandle.className) return false;
    //             const dragedEl: string = cHandle.className.toLowerCase();
    //             return (dragedEl.indexOf('on-ios-reorder') > -1) && dragedEl.indexOf('disabled') == -1;
    //         },
    //         revertOnSpill: true
    //     });
    //     this.dragulaService.dropModel(this.PHONE_CALL_MEETING_STRUCTURE).subscribe(args => {
    //         if (args && args.targetModel) {
    //             this.phoneCallMeetingStructureService.updateSequenceOnEmbeddedInteractionDrag(args.targetModel);
    //         }
    //     });
    this.target = null;
    this.source = null;

  }


   get phoneCallStructureHeader(){
    return {
      id: 'meeting-structure-header',
      title: this.translate.instant('CALL_ACTIVITIES').toUpperCase(),
      controls: [{
        id: 'add-activity',
        iconClass: 'pluse-icon',
        isDisabled: (this.backgroundUploadInProgress || this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity.isReopened ||
          this.activityService.selectedActivity.ownerId !== this.authenticationService.user.systemUserID),
        tooltip: 'New Activity'
      }]
    };

  }


  ngOnInit() {
    this.setViewData();
  }

  private setViewData() {
    this.inPhoneCallActivitiesEnabled = this.isInPhoneCallActivityEnabled;
    this.activityDisabled = this.backgroundUploadInProgress || this.authenticationService.user.systemUserID != this.activityService.selectedActivity.ownerId;
    this.IcurrentPhoneCallMeetingStructureSubscription = this.phoneCallMeetingStructureService.currentPhoneCallMeetingStructureObserver.subscribe((value: EmbeddedInteraction[]) => {
      this.inPhoneCallActivitiesEnabled = this.isInPhoneCallActivityEnabled;
      if (value && value.length > 0) {
        value.forEach((ei: EmbeddedInteraction) => ei.completed = !this.isScrapEnabled(ei));
      }
      this.embeddedInteractions = value;
      this.meetingStructureHeader = this.phoneCallStructureHeader;
    });
  }

  ngAfterViewInit() {
        let phElement = this.placeholder.element.nativeElement;

            phElement.style.display = 'none';
           phElement.parentElement.removeChild(phElement);

          }

  ngOnChanges() {
    this.setViewData();
  }

private get isInPhoneCallActivityEnabled() {
  return (this.activityService.selectedActivity instanceof PhoneActivity &&
      (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)
          || this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)));
}

public isScrapEnabled(embeddedInteraction: EmbeddedInteraction): boolean {
  if (this.activityDisabled) return false;
  if (embeddedInteraction.type === EmbeddedInteractionType.Activity) {
      const activity: Activity = <Activity>embeddedInteraction.interaction;
      if (activity instanceof CaseActivity && String(activity._case_status_value).toLocaleLowerCase() != 'open') {
          if (String(activity._case_status_value).toLocaleLowerCase() == 'pending sync') {
              return true;
          } else {
              return false;
          }
      }
      else if (activity instanceof EmailActivity && activity.emailStatus != 1) {
          return false;
      }
  }
  return true;
}

  onSectionHeaderControlClick(event) {
    switch (event) {
      case "add-activity":
        //open new Activity page
        this.plusButtonClicked(null)
        break
    }
  }

  openEmbeddedInteraction(embeddedInteraction: EmbeddedInteraction) {

    let activity = <Activity>embeddedInteraction.interaction
    switch (activity.type) {
      case ActivityType.CaseIntake:
          this.caseManagementService.assignSelectedCase(<CaseActivity>activity);
          this.phoneCallMeetingStructureService.openInPhoneCallMeetingCaseModal(activity);
          break;
      case ActivityType.Email:
          this.emailService.selectedActivity = <EmailActivity>activity;
          this.emailService.setCurrentEmail(activity);
          this.emailService.viewType = EmailViewType.CREATE_FROM_PHONE_CALL;
          this.phoneCallMeetingStructureService.openInPhoneCallMeetingActivitiesModal(activity);
          break;
      default:
          console.log("Unhandled activity case");
          break
  }
}

  handleInteractionRemove(embeddedInteraction: EmbeddedInteraction) {
    let popupTitle: string;
    let activity = <Activity> embeddedInteraction.interaction
  switch (activity.type) {
      case ActivityType.Email:
          popupTitle = this.translate.instant('EMAIL_ACTIVITY_ALERT_TITLE_SCRAP_MESSGE');
          break;
      case ActivityType.CaseIntake:
          popupTitle = this.translate.instant('POP_SCRAP_INQUIRY');
          break;
  }
  this.alertService.showAlert({
      title: popupTitle,
      message: this.getAlertMessage(activity)
  }, this.translate.instant('SCRAP')
  ).then(async res => {
      if (res.role == "ok") {
          if (this.backgroundUploadInProgress) return;
          switch (activity.type) {
              case ActivityType.Email:
                 await this.emailService.scrapEmailActivity(<EmailActivity>activity, true);
                  break;
              case ActivityType.CaseIntake:
                 await this.caseManagementService.scrapCustomerInquiry(<CaseActivity>activity, null, null, true);
                  break;
          }
          this.phoneCallMeetingStructureService.createEmbeddedInteractions(this.activityService.selectedActivity as PhoneActivity)
      }
  });
}


private getAlertMessage(activity: any): string {
  let message: string;
  switch (activity.type) {
      case ActivityType.Sample:
          message = this.translate.instant('POP_R_U_SURE_SCRAP_ALLOC_O');
          break;
      case ActivityType.Email:
          message = this.translate.instant('R_U_SURE_SCRAP_MESG');
          break;
      case ActivityType.CaseIntake:
          message = this.translate.instant('POP_R_U_SURE_SCRAP_SELECTED_INQUIRY');
          break;
  }
  return message;
}

ngOnDestroy() {
  this.IcurrentPhoneCallMeetingStructureSubscription.unsubscribe();
  // this.dragulaService.destroy(this.PHONE_CALL_MEETING_STRUCTURE);
}
dragMoved(e: CdkDragMove) {
  let point = this.getPointerPositionOnPage(e.event);

  this.listGroup._items.forEach(dropList => {
    if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
      this.activeContainer = dropList;
      return;
    }
  });
}
dropListDropped(event: any) {
  if (!this.target)
    return;

  let phElement = this.placeholder.element.nativeElement;
  let parent = phElement.parentElement;

  phElement.style.display = 'none';

  parent.removeChild(phElement);
  parent.appendChild(phElement);
  parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

  this.target = null;
  this.source = null;

  if (this.sourceIndex != this.targetIndex)
    moveItemInArray(this.embeddedInteractions, this.sourceIndex, this.targetIndex);
    this.phoneCallMeetingStructureService.updateSequenceOnEmbeddedInteractionDrag(this.embeddedInteractions);
}
dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
  if (drop == this.placeholder)
    return true;

  if (drop != this.activeContainer)
    return false;

  let phElement = this.placeholder.element.nativeElement;
  let sourceElement = drag.dropContainer.element.nativeElement;
  let dropElement = drop.element.nativeElement;

  let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
  let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

  if (!this.source) {
    this.sourceIndex = dragIndex;
    this.source = drag.dropContainer;

    phElement.style.width = sourceElement.clientWidth + 'px';
    phElement.style.height = sourceElement.clientHeight + 'px';

    sourceElement.parentElement.removeChild(sourceElement);
  }

  this.targetIndex = dropIndex;
  this.target = drop;

  phElement.style.display = '';
  dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
    ? dropElement.nextSibling : dropElement));

  this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
  return false;
}
getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
  // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
  const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
      const scrollPosition = this.viewportRuler.getViewportScrollPosition();

      return {
          x: point.pageX - scrollPosition.left,
          y: point.pageY - scrollPosition.top
      };
  }

  async plusButtonClicked(embeddedInteraction : EmbeddedInteraction) {
    if(embeddedInteraction){
      this.trackingService.tracking('Select+buttononactivitytile', TrackingEventNames.PHONECALL);
    }
    else{
      this.trackingService.tracking('Select+button', TrackingEventNames.PHONECALL);
    }

    const curMasterPage = this.navService.getCurrentMasterPageName();
    this.phoneCallMeetingStructureService.setCurrentPhoneCallMeetingActivity(embeddedInteraction)
    this.activityService.activitySource = ActivitySource.PHONE_CALL_ACTIVITY_DETAIL;
    if (curMasterPage === PageName.ActivitiesPageComponent) {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showRightPane = true;
      this.uiService.showNewActivity = false;
    }
    // if(curMasterPage === PageName.ContactPageComponent){
    //   this.uiService.showNewActivity = true;
    //   this.openNewActivityPage.emit();
    //   return
    // }
    if(curMasterPage === PageName.CallPlanComponent || curMasterPage === PageName.CallPlanDetails){
      this.uiService.showNewActivity = true;
      this.callPlanService.callPlanPageView = 'NEW_ACTIVITY_SELECTION';
      this.openNewActivityPage.emit();
      return
    }

    await this.navService.pushChildNavPageWithPageTracking(NewActivityComponent,
      PageName.NewActivityComponent, PageName.PhoneCallDetailsComponent,
      { from: 'ActivitiesDetailsPane'});
}


}
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}
function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};
