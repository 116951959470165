<ind-section-header [viewData]="productKeyMessageHeaderModel"
(onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header> 

<!-- <ion-item-divider>
  {{'PRODUCT_KEY_MESSGES' | translate}}
  <ion-button fill="clear" slot="end" (click)="saveProductKeyMessages()" [disabled]="!updateProductsFlag || activityService.selectedActivity.isCompleted">{{'SAVE' | translate}}</ion-button>
</ion-item-divider> -->
<ion-reorder-group [ngClass]="{'empty-list':!tempProducts.length}" [disabled]="!(!activityService.selectedActivity?.isCompleted && !isReadOnlyJointPhoneCall && !activityService.teamViewActive && !backgroundUploadInProgress)" 
(ionItemReorder)="reorderData($event.detail)">
  <div *ngFor="let product of tempProducts">
    <ion-item *ngIf="tempProducts.length > 0" >
      <div class="product-key-msg-wrapper">
        <div class="product-wrapper">
          <div #roundInput [id]="product.ID"
          [ngClass]="{'round': (!activityService.selectedActivity?.isCompleted && !isReadOnlyJointPhoneCall && !backgroundUploadInProgress),
           'round-disabled': (backgroundUploadInProgress || activityService.selectedActivity?.isCompleted || isReadOnlyJointPhoneCall)}"
           (click)="updateProduct($event , product)">
            <input [id]="product.ID" type="checkbox" [ngModel]="product.isSelected"/>
            <label [for]="product.ID" [ngClass]="{
              'selected':!product.isAutoSelected,
              'auto-selected': product.isAutoSelected}">
            </label>
            <span style="margin-bottom:35px; margin-left: 15px;" size="25" class="key-message-text">{{product.name}}</span>
          </div>
      </div>
      <div class="key-messages-wrapper" *ngIf="product.keyMessages && product.keyMessages.length != 0" >
            <div class="keyMessage-container">
            <button class="customized-button" *ngFor="let keyMessage of product.keyMessages" (click)="updatedHighlightedKeyMessage($event ,  product , keyMessage)"
                    [disabled]="backgroundUploadInProgress || activityService.selectedActivity?.isCompleted || isReadOnlyJointPhoneCall || activityService.teamViewActive"
                    [ngClass]="{
                      'selected':keyMessage.isSelected && !keyMessage.isAutoSelected && !keyMessage.isGenieSelected,
                      'auto-selected': keyMessage.isAutoSelected}"
                    id="{{product.ID + '-' + keyMessage.ID}}">
                      {{keyMessage.name}}
            </button>
          </div>
      </div>
      </div>
      <ion-reorder slot="end"></ion-reorder>
    </ion-item>
  </div>
</ion-reorder-group>
