import { ActivityService } from '../../../services/activity/activity.service';
import { NavigationService, PageName, ChildNavNames } from '../../../services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { Component, Output, EventEmitter, ViewChild, OnDestroy, OnInit } from "@angular/core";
import { ScientificActivityPlan } from "../../../classes/scientific-activity/scientific-activity.class";
import { ScientificActivityService } from "../../../services/scientific-activity/scientific-activity.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { ScientificActivityDetail } from '../../activity/scientific-activity/scientific-activity-detail/scientific-activity-detail'
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { CallPlanDetails } from '../../../components/call-plans/call-plan-details/call-plan-details';
import { AgendaFooterService, AgendaFooterView } from '../../../services/footer/agenda-footer.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { AuthenticationService } from '../../../services/authentication.service';
import { EventsService } from '../../../services/events/events.service';
import _ from "lodash";
import {MyAssistantService} from "@omni/services/my-assistant/my-assistant.service";
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import {Subscription} from "rxjs";
import { DeviceService } from '@omni/services/device/device.service';
import { CustomerCallPlanDetails } from '@omni/components/customer-call-plans/customer-call-plan-details/customer-call-plan-details';

@Component({
  selector: 'plans-list',
  templateUrl: 'plans-list.html',
  styleUrls: ['plans-list.scss'],
})
export class PlansListComponent implements OnInit {

  // @Output() closeModal = new EventEmitter();
  @Output() selectScientificPlan: EventEmitter<ScientificActivityPlan> = new EventEmitter<ScientificActivityPlan>();
  public accountListMode: ComponentViewMode;
  private IallNotificationsSubscription: Subscription;

  constructor(
    public uiService: UIService,
    public activityService: ActivityService,
    private navService: NavigationService,
    private sapService: ScientificActivityService,
    private trackingService: TrackService,
    private contactService: ContactOfflineService,
    private footerService: FooterService,
    public agendaFooterService: AgendaFooterService,
    private authenticationOfflineService: AuthenticationService,
    public eventService: EventsService,
    public myAssistantService: MyAssistantService,
    private device: DeviceService
  ) {}

  async ngOnInit() {
    this.uiService.activitiesPageTab = 'plans';
    this.IallNotificationsSubscription = this.myAssistantService.notificationsObs$.subscribe((data: IndNotificationDataModel[]) => {
      if (data) {
        this.myAssistantService.hasAnyUnRedNotifications = _.some(data, ['isRed', false]);
      }
    });
    this.agendaFooterService.enableDisablePlansTabButton();
    this.enableDisablePlanView();
    const currentActivePageName = this.navService.getActiveChildNavViewPageName();
    const skipFunctionOnThisActivePageList = [PageName.GeneralSettingsComponent];
    if(!skipFunctionOnThisActivePageList.includes(currentActivePageName)) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    }
    this.uiService.showRightPane = false;
    this.resetView();

    this.eventService.observe("sync:completed")
      .subscribe(() => {
        if (this.uiService.activitiesPageTab === 'plans' && this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
          this.enableDisablePlanView(true);
        }
      });

    this.eventService.subscribe("tools-back-clicked", this.handleToolsBack);
  }

  ionViewWillEnter(){
   console.log('view entered')
  }

  ngOnDestroy() {
    this.uiService.activitiesPagePlanTabSelectedPlan = undefined;
    this.uiService.activeViewOnPlanTab = undefined;
    this.eventService.unsubscribe("tools-back-clicked");
    if (this.IallNotificationsSubscription) this.IallNotificationsSubscription.unsubscribe();
  }

  private resetView(showRightPane: boolean = false) {
    if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
      return;
    }
    if (this.agendaFooterService.planTabSegment !== 'planTabTodo') {
      // Added change to prevent this OMNI-23826
      // create an activity from account plan from the plan tab on agenda
      // open tools menu > go to account plan > select any and create an activity
      // click on the home button, notice the previously created actvitiy page is blank.
      if (this.activityService.isHomeActivitiyEqualsToSelected() === false) {
        this.activityService.selectedActivity = undefined;
        this.activityService.selectedActivityAtHome = null;
      }
      // this.uiService.showRightPane = showRightPane;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = '';
    } else {
      if (this.activityService.selectedActivityAtHome)
      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
    }
    this.agendaFooterService.enableDisablePlansTabButton();
    this.enableDisablePlanView();
  }

  private enableDisablePlanView(afterSync:boolean = false) {
    let selectedPlan = this.uiService.activitiesPagePlanTabSelectedPlan;
    if (selectedPlan) {
      if(selectedPlan == 'goalsPlansTab') {
        if((this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT))|| (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CALL_PLANS)) ||(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN))) {
          this.uiService.activitiesPagePlanTabSelectedPlan = 'goalsPlansTab';
          this.agendaFooterService.planTabSegment = 'goalsPlansTab';
          this.agendaFooterService.initButtons(AgendaFooterView.PlanGoalsPlan, afterSync);
        } else this.checkUserFeatureAction();
      }
      else if (selectedPlan == 'planTabTodo') {
        this.uiService.activitiesPagePlanTabSelectedPlan = 'planTabTodo';
        this.agendaFooterService.planTabSegment = 'planTabTodo';
        this.agendaFooterService.initButtons(AgendaFooterView.PlanTODO, afterSync);
      } else if (selectedPlan == 'planTabGoals') {
        this.uiService.activitiesPagePlanTabSelectedPlan = 'planTabGoals';
        this.agendaFooterService.planTabSegment = 'planTabGoals';
        this.agendaFooterService.initButtons(AgendaFooterView.PlanGoals, afterSync);
      } else if (selectedPlan == 'notifications') {
        this.uiService.activitiesPagePlanTabSelectedPlan = 'notifications';
        this.agendaFooterService.planTabSegment = 'notifications';
        this.agendaFooterService.initButtons(AgendaFooterView.PlanNotifications, afterSync);
      }
    } else this.checkUserFeatureAction();
  }

  private checkUserFeatureAction() {
    this.uiService.activitiesPagePlanTabSelectedPlan = 'planTabTodo';
    this.agendaFooterService.planTabSegment = 'planTabTodo';
    this.agendaFooterService.initButtons(AgendaFooterView.PlanTODO);
    // if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) {
    //   this.uiService.activitiesPagePlanTabSelectedPlan = 'accountPlansTab';
    //   this.agendaFooterService.planTabSegment = 'accountPlansTab';
    //   this.agendaFooterService.initButtons(AgendaFooterView.PlanAccountPlan);
    // } else if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CALL_PLANS)) {
    //   this.uiService.activitiesPagePlanTabSelectedPlan = 'callPlans';
    //   this.agendaFooterService.planTabSegment = 'callPlans';
    //   this.agendaFooterService.initButtons(AgendaFooterView.PlanCallPlan);
    // } else if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN)){
    //   this.uiService.activitiesPagePlanTabSelectedPlan = 'scientificPlans';
    //   this.agendaFooterService.planTabSegment = 'scientificPlans';
    //   this.agendaFooterService.initButtons(AgendaFooterView.PlanScientificPlan);
    // }
  }

  public async closeScientificActivityPage(ev) {
    this.trackingService.tracking('ScientificActivityBack', TrackingEventNames.ACCOUNTPLANNING)
    this.uiService.activeView = '';
    this.sapService.selectedSAP = undefined;
    this.activityService.selectedActivity = null;
    await this.navService.popChildNavCtrlFromStack(ChildNavNames.ActivitiesPageNavigation);
    await this.navService.popToRootWithPageTracking();
  }

  setSelectedSAP(event: ScientificActivityPlan){
    this.contactService.contactInformation = undefined;
    if(event){
        this.uiService.showRightPane = true;
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.trackingService.tracking('ActivitiesPageRightPaneNav', TrackingEventNames.PLANS_LIST, true);
        this.navService.setChildNavRoot(ScientificActivityDetail, PageName.ScientificActivityDetail, PageName.ActivitiesPageComponent, { selectedSAP: event });
        this.footerService.initButtons(FooterViews.ScientificPlanDetails);
    }
  }

  // For Call-Plans
  onCallPlanSelected(callPlan) {
    if (callPlan) {
      this.uiService.showRightPane = true;
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.trackingService.tracking('ActivitiesPageRightPaneNav', TrackingEventNames.PLANS_LIST, true);
      this.navService.setChildNavRoot(CustomerCallPlanDetails,  PageName.CustomerCallPlanDetails, PageName.ActivitiesPageComponent, { selectedCallPlan: callPlan });
    } else {
      this.navService.popToRootChildNavPageWithPageTracking();
    }
  }

  private updateRightSideView() {
    if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
      return;
    }
    // This is specially to handle the footer fab which is not getting refreshed when comes back from different tools.
    if (this.device.isMobileDevice === false) {
      switch (this.agendaFooterService.planTabSegment) {
        case 'accountPlansTab':
          if (this.navService.getActiveChildNavViewPageName() == PageName.AccountPlanDetailsComponent) {
            this.footerService.initButtons(FooterViews.AccountPlanObjectives);
          } else {
            this.initFooterBasedOnActivityDetail();
          }
          break;
        case 'callPlans':
          if (this.navService.getActiveChildNavViewPageName() == PageName.CallPlanDetails) {
            this.footerService.initButtons(FooterViews.CallPlanDetails);
          } else {
            this.initFooterBasedOnActivityDetail();
          }
          break;
        case 'scientificPlans':
          if (this.navService.getActiveChildNavViewPageName() == PageName.ScientificActivityDetail) {
            this.footerService.initButtons(FooterViews.ScientificPlanDetails);
          } else {
            this.initFooterBasedOnActivityDetail();
          }
          break;

          case 'planTabTodo':
          this.initFooterBasedOnActivityDetail();
          break;

      }
    }
  }

  private initFooterBasedOnActivityDetail() {
    if (this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
      this.footerService.initButtons(FooterViews.Activities);
    } else if (this.navService.getActiveChildNavViewPageName() == PageName.PhoneCallDetailsComponent) {
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
      this.eventService.publish('update-email-footer');
      // this.footerService.initButtons(FooterViews.EmailDetails);
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.NewSampleActivityComponent) {
      this.footerService.initButtons(FooterViews.SampleDropDetails);
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.FollowUpActionDetailComponent) {
      this.footerService.initButtons(FooterViews.FollowUpDetails);
    } else if (this.navService.getActiveChildNavViewPageName() == PageName.NothingSelectedView) {
      this.footerService.initButtons(FooterViews.None);
    } else if (this.navService.getActiveChildNavViewPageName() == PageName.SurgeryOrderDetailsComponent) {
      this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
    } else if (this.navService.getActiveChildNavViewPageName() == PageName.ProcedureTrackerDetailsComponent) {
      this.footerService.initButtons(FooterViews.PROCEDURE_TRACKER_ACTIVITY);
    }
  }

  handleToolsBack = (component) => {
    const currentActivePageName = this.navService.getActiveChildNavViewPageName();
    const skipFunctionOnThisActivePageList = [PageName.GeneralSettingsComponent];
    if(skipFunctionOnThisActivePageList.includes(currentActivePageName)) return;
    
    if (this.activityService.selectedActivity) {
        setTimeout(() => {
          this.resetView();
        }, 200);
    } else if (this.activityService.selectedActivity) {
        setTimeout(() => {
          this.resetView();
        }, 200);
    }

     else {
      this.resetView();
    }

    setTimeout(() => {
      this.updateRightSideView()
    }, 200);
  }

}
