import { Injectable } from "@angular/core";
import { DurationType, TimeOffActivity, TimeOffStatus } from "../../classes/activity/timeoff.class";
import { NavigationService, PageName } from "../navigation/navigation.service";
import { AlertController, LoadingController, } from "@ionic/angular";
import { Events } from '@omni/events';
// import { TimeOffComponent } from "../../components/time-off/time-off-home/time-off-home";
import { TimeOffDataService } from "../../data-services/time-off/time-off.data.service";
import { TimeOffService } from "./time-off.service";
import { DeviceService } from '../device/device.service';
import { ActivityService } from "../activity/activity.service";
import { UIService } from "../ui/ui.service";
import { ActivityType } from "../../classes/activity/activity.class";
import { AuthenticationService } from "../authentication.service";
import { NotificationService, ToastStyle } from "../notification/notification.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../alert/alert.service";
import { AgendaFooterService } from "../footer/agenda-footer.service";
import moment from "moment";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { LocalizationService } from "../localization/localization.service";

@Injectable({
  providedIn: 'root'
})
export class TimeOffUtilityService {

    public currentDurationType: string = DurationType.AllDay;

    constructor(
        private navService: NavigationService,
        private alertService: AlertService,
        private loadingCtrl: LoadingController,
        private timeOffDataService: TimeOffDataService,
        private timeOffService: TimeOffService,
        private deviceService: DeviceService,
        private activityService: ActivityService,
        private uiService: UIService,
        public authenticationService: AuthenticationService,
        private events: Events,
        private notifyService: NotificationService,
        public translate:TranslateService,
        private agendaFooterService: AgendaFooterService,
        public languageService:LocalizationService,
    ) { }


    public scrapTimeOff(timeOffActivity: TimeOffActivity, view?: string) {
        console.log("Dleteing time off : " + timeOffActivity.timeOffRequestId);
        if (timeOffActivity.statuscode != TimeOffStatus.Open) return;

        let altCancelText: string = this.languageService.selectedLanguage.code == "ja"  ? this.translate.instant('CANCEL_BTN_ALERT') : this.translate.instant('CANCEL');

        this.alertService.showAlert({
            title: this.translate.instant('SCRAP_TIME_OFF'),
            message: this.translate.instant('POP_R_U_SURE_SCRAP_TIME_OFF')}, this.translate.instant('SCRAP'), altCancelText
        ).then (async res => {
            if(res.role == "ok") {
              if (this.deviceService.isBackgroundUploadInProgress && timeOffActivity.pendingPushToDynamics) return;
                const loader = await this.loadingCtrl.create();
                loader.present();
                //network detected
                if (this.deviceService.isOffline || timeOffActivity.timeOffRequestId.includes("offline_")) {
                    if (view != undefined) {
                        this.performDeletion(false, timeOffActivity, loader, view);
                    }
                    else {
                        this.performDeletion(false, timeOffActivity, loader);
                    }
                } else {
                    this.timeOffDataService.deleteTimeOff(timeOffActivity.timeOffRequestId).then(response => {
                        if (view != undefined) {
                            this.performDeletion(true, timeOffActivity, loader, view);
                        } else {
                            this.performDeletion(true, timeOffActivity, loader);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.performDeletion(false, timeOffActivity, loader);
                    });
                }
            }
        });
    }

    public async performDeletion(isOnline: boolean, timeOffActivity: TimeOffActivity, loader: HTMLIonLoadingElement, view?: string) {
        if (isOnline && !timeOffActivity.timeOffRequestId.includes("offline_")) {
            try {
                await this.timeOffService.delete(true, timeOffActivity);
                this.timeOffService.setSelectedTot(null);
                this.activityService.selectedActivity = null;
                this.uiService.activeView = 'TimeOff';
                this.activityService.publishActivityEvent({action: "Delete", activity: timeOffActivity});
                this.events.publish("timeOffActivityDeleted", timeOffActivity);
                if (this.uiService.activitiesPageTab === 'day') {
                    this.events.publish('activityDeleted', timeOffActivity);
                }
                if (view != undefined) {
                    if (this.deviceService.shouldBeMobileView) {
                        if (view === "agenda") {
                            /**
                             * new css approach
                             */
                            loader.dismiss();
                            this.timeOffService.unsetMobileTracker();
                            this.uiService.showRightPane = false;
                        }
                        else if (view === "calendar") {
                            loader.dismiss();
                            this.timeOffService.unsetMobileTracker();
                            this.navService.popToRootWithPageTracking();
                        }
                        else if (view === "timeOff") {
                            loader.dismiss();
                            this.timeOffService.unsetMobileTracker();
                            // this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffDetailsComponent, null, PageName.TimeOffDetailsComponent);
                            this.timeOffService.totShowRightPane = false;
                        } else {
                            loader.dismiss();
                            this.timeOffService.unsetMobileTracker();
                            this.timeOffService.totShowRightPane = false;
                            this.uiService.showRightPane = false;
                        }
                    } else{
                        loader.dismiss();
                    }
                }
                else {
                    loader.dismiss();
                    this.timeOffService.unsetMobileTracker();
                    // alert("time off view undefined");
                    // this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffDetailsComponent, null, PageName.TimeOffDetailsComponent);
                }
            } catch (error) {
                loader.dismiss();
            }
        }
        else {
            try {
                await this.timeOffService.delete(false, timeOffActivity);
                this.timeOffService.setSelectedTot(null);
                this.uiService.activeView = 'TimeOff';
                this.activityService.publishActivityEvent({action: "Delete", activity: timeOffActivity});
                this.events.publish("timeOffActivityDeleted", timeOffActivity);
                this.activityService.selectedActivity = null;
                if (this.uiService.activitiesPageTab === 'day') {
                    this.events.publish('activityDeleted', timeOffActivity);
                }
                loader.dismiss();
                if (view != undefined) {
                    if (this.deviceService.shouldBeMobileView) {
                        if (view === "agenda") {
                            /**
                             * new css approach
                             */
                            this.timeOffService.unsetMobileTracker();
                            this.uiService.showRightPane = false;
                        }
                        else if (view === "calendar") {
                            this.timeOffService.unsetMobileTracker();
                            this.navService.popToRootWithPageTracking();
                        }
                        else if (view === "timeOff") {
                            this.timeOffService.unsetMobileTracker();
                            this.timeOffService.totShowRightPane = false;
                            // this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffDetailsComponent, null, PageName.TimeOffDetailsComponent);
                        }
                    }
                }
                else {
                    // alert("time off view undefined");
                    // this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffDetailsComponent, null, PageName.TimeOffDetailsComponent);
                }
            } catch (error) {
                loader.dismiss();
            }
        }
        // loader.dismiss();
        this.uiService.showNewActivity = false; //Fix for issue that shows new activity page after deleting timeoff
        // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
        //Agenda
        if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent)
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }

    public async updateTimeOff(tot: TimeOffActivity, isForReview: boolean, isAutoApproval: boolean, isSubmitted: boolean = false) {
        //just in case we receieve the date in format of text from date picker component
        tot.totStartTime = new Date(tot.totStartTime);
        tot.totEndTime = new Date(tot.totEndTime);
        //If device is online then update CRM and indexDB
        const loader = await this.loadingCtrl.create();
        loader.present();
        if (!this.deviceService.isOffline && !tot.pendingPushToDynamics) {
            if (!tot.timeOffRequestId.includes("offline_")) {
                this.timeOffDataService.updateTimeOff(tot, isForReview, isAutoApproval).subscribe(
                    res => {
                        console.log(res);
                        TimeOffUtilityService.updateStatus(tot, res);
                        tot.approverName = this.authenticationService.user.displayName;
                        this.timeOffService.onTimeOffUpdated(tot, true, res);
                        this.updateBulkActivities(tot, isAutoApproval);
                        this.activityService.publishActivityEvent({action: "Update", activity: tot});
                        // if (this.uiService.activitiesPageTab === 'day') {
                        //     this.events.publish('activityUpdated', tot);
                        // }
                        this.events.publish("timeOffActivityIsUpdated", tot, isSubmitted);
                        this.timeOffService.setSelectedTot(tot);
                        loader.dismiss();
                    },
                    err => {
                        loader.dismiss();
                        //message indicate that TO is already updated in Dynamics and need to update on UI now
                        if (err && err.error && err.error.errorCode && err.error.errorCode=='ERR_IO_AATO') {

                            let dummyRes: any = {
                                "indskr_starttime": tot.totStartTime,
                                "indskr_endtime": tot.totEndTime,
                                "indskr_reason": tot.timeOffReason,
                                "indskr_name": tot.name,
                                "indskr_isalldayevent": tot.totIsAlldayEvent,
                                "statecode": 1,
                                "statuscode": 100000004,
                                "indskr_isautoapproved": tot.isAutoApproved,
                                "indskr_approvaldate": tot.approvalDate,
                                "indskr_positionid": tot.positionId,
                                "indskr_timeoffrequestid": tot.timeOffRequestId
                            };

                            TimeOffUtilityService.updateStatus(tot, dummyRes);
                            tot.approverName = this.authenticationService.user.displayName;
                            this.timeOffService.onTimeOffUpdated(tot, true, dummyRes);
                            this.timeOffService.setSelectedTot(tot);
                            this.events.publish("timeOffActivityIsUpdated", tot, isSubmitted);
                            this.updateBulkActivities(tot, isAutoApproval);
                        } else if(err && err.error && err.error.errorCode && err.error.errorCode=='ERR_IO_TO_OVERLAP'){
                            this.notifyService.notify(this.translate.instant('TIME_OFF_UTILITY_SELECTED_CONFLICTING'),'','top', ToastStyle.DANGER);
                        } else if(err && err.error && err.error.errorCode && err.error.errorCode=='ERR_IO_TO_OVERLAP_FOR_REVIEW'){
                            this.notifyService.notify(this.translate.instant('TIME_OFF_UTILITY_CONFLICTING_FOR_REVIEW'),'','top', ToastStyle.DANGER);
                        } else if(err && err.error && err.error.errorCode && err.error.errorCode=='ERR_IO_TO_OVERLAP_APPROVE'){
                            this.notifyService.notify(this.translate.instant('TIME_OFF_SELECTED_HAVE_CONFLICTING_IS_APPROVED'),'','top', ToastStyle.DANGER);
                        }
                    }
                );
            }
            else {
                // Offline data
                if (!isForReview) {
                    // If not for review, update can be done local
                    this.timeOffService.onTimeOffUpdated(tot, false);
                    this.timeOffService.setSelectedTot(tot);
                    this.activityService.publishActivityEvent({action: "Update", activity: tot});
                }
                loader.dismiss();
            }
        }
        //If device is offline then update indexDB and update payload for offline sync
        else {
            this.timeOffService.onTimeOffUpdated(tot, false);
            this.timeOffService.setSelectedTot(tot);
            this.activityService.publishActivityEvent({action: "Update", activity: tot});
            this.events.publish("timeOffActivityIsUpdated", tot, isSubmitted);
            loader.dismiss();
        }
        // if (this.uiService.activitiesPageTab === 'day') {
        //     this.events.publish('activityUpdated', tot);
        // }
        this.timeOffService.setIsTotModified(false);
        this.timeOffService.mapMyTimeOffFieldsToSearchIndex(tot);
    }

    public async reOpenTimeOff(tot: TimeOffActivity, teamViewActive: boolean) {
        const loader = await this.loadingCtrl.create();
        loader.present();
        if (!this.deviceService.isOffline && !tot.pendingPushToDynamics) {
            if (!tot.timeOffRequestId.includes("offline_")) {
                this.timeOffDataService.reOpenTimeOff(tot).subscribe(
                    async res => {
                        console.log(res);
                        TimeOffUtilityService.updateStatus(tot, res);
                        await this.timeOffService.onTimeOffReopened(tot, true, teamViewActive);
                        tot.statecode = 0;
                        tot.statuscode= 100000000;
                        // if (this.uiService.activitiesPageTab === 'day') {
                        //     this.events.publish('activityUpdated', tot);
                        // }
                        if (this.timeOffService.totMode === 'teamRequests') {
                            this.activityService.selectedActivity = null;
                            this.timeOffService.setSelectedTot(null);
                            this.timeOffService.totShowRightPane = false;
                            this.uiService.activeView = 'TimeOff';
                        }
                        if (!this.uiService.toolsActivityActive) {
                            this.events.publish("refreshAgenda");
                        }
                        else {
                            this.uiService.agendaRefreshRequired = true;
                        }
                        this.events.publish('timeOffActivityReopen', tot);
                        this.timeOffService.setSelectedTot(tot);
                        loader.dismiss();
                    },
                    err => {
                        loader.dismiss();
                    });
            }
        }
    }

    public async cancelReviewTimeOff(tot: TimeOffActivity) {
        //just in case we receieve the date in format of text from date picker component
        tot.totStartTime = new Date(tot.totStartTime);
        tot.totEndTime = new Date(tot.totEndTime);
        //If device is online then update CRM and indexDB
        const loader = await this.loadingCtrl.create();
        loader.present();
        if (!this.deviceService.isOffline && !tot.pendingPushToDynamics) {
            if (!tot.timeOffRequestId.includes("offline_")) {
                this.timeOffDataService.cancelReviewTimeOff(tot).subscribe(
                    res => {
                        console.log(res);
                        TimeOffUtilityService.updateStatus(tot, res);
                        tot.approverName = this.authenticationService.user.displayName;
                        this.timeOffService.onTimeOffUpdated(tot, true, res);
                        this.timeOffService.setSelectedTot(tot);
                        this.events.publish('timeOffActivityReopen', tot);
                        this.updateBulkActivities(tot, this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTO_APPROVAL));
                        // if (this.uiService.activitiesPageTab === 'day') {
                        //     this.events.publish('activityUpdated', tot);
                        // }
                        loader.dismiss();
                    },
                    err => {
                        loader.dismiss();
                        //message indicate that TO is already updated in Dynamics and need to update on UI now

                    }
                );
            }

        }
        //If device is offline then update indexDB and update payload for offline sync
        else {
            this.timeOffService.onTimeOffUpdated(tot, false);
            this.timeOffService.setSelectedTot(tot);
            loader.dismiss();
        }
        // if (this.uiService.activitiesPageTab === 'day') {
        //     this.events.publish('activityUpdated', tot);
        // }
        this.timeOffService.setIsTotModified(false);
    }

    public getDateTimeForTimeOffEvent(type: string, tot?: TimeOffActivity): Object {
        let dateUpdate: Object = {
            totStartTime: new Date(),
            totEndTime: new Date()
        };
        if (type === 'Hourly') {
            dateUpdate = {
                totStartTime: new Date(this.timeOffDataService.getTimeStamp("otherStart", tot)),
                totEndTime: new Date(this.timeOffDataService.getTimeStamp("otherEnd", tot))
            };
            return dateUpdate;
        }
        else if (type === 'All Day') {
            dateUpdate = {
                totStartTime: new Date(this.timeOffDataService.getTimeStamp("start", tot)),
                totEndTime: new Date(this.timeOffDataService.getTimeStamp("end", tot))
            };
            return dateUpdate;
        }
        else if (type === 'Half Day') {
            dateUpdate = {
                totStartTime: new Date(this.timeOffDataService.getTimeStamp("halfStart", tot)),
                totEndTime: new Date(this.timeOffDataService.getTimeStamp("halfEnd", tot))
            };
            return dateUpdate;
        }
    }

    public getTimePeriodForTimeOffEvent(tot: TimeOffActivity): string {
      let timePeriod: string;
      let meridian = moment(new Date(tot.totStartTime)).format('A');
      timePeriod = (meridian === 'AM') ? "AM" : "PM";
      return timePeriod;
    }

    public async approveTimeOff(tot: TimeOffActivity, approve: boolean) {
        //If device is online then update CRM and indexDB
        const APPROVE_ALERT = this.translate.instant('TIME_OFF_LIKE_TO_APPROVE');
        const NOT_APPROVED_ALERT = this.translate.instant('TIME_OFF_NOTAPPROVE');
        this.timeOffService.isProcessing = true;

        const altConfirmText = approve ? this.translate.instant('APPROVE') : this.translate.instant('NOT_APPROVE');

        this.alertService.showAlert(
            {title: approve ? this.translate.instant('TIME_OFF_APPROVE') : this.translate.instant('TIME_OFF_NOT_APPROVE'),
            message: approve ? APPROVE_ALERT : NOT_APPROVED_ALERT,
            inputs:[{type:'text', name:"comments", attributes:{'required':approve?'false':'true'}, placeholder:this.translate.instant('APPROVER_COMMENTS')}]},
            altConfirmText
        ).then (res => {
            if (this.deviceService.backgroundUploadInProgress && tot.pendingPushToDynamics) {
              this.timeOffService.isProcessing = false;
              return;
            }
            if(res.role == "ok") {
              if(approve || res.data?.values?.comments){
                tot.approverComments = res.data.values.comments
                this.timeOffApproveHandler(tot, approve);
              }
              else this.approveTimeOff(tot,approve);
            } else {
                this.timeOffService.isProcessing = false;
            }
        });
    }

    private async timeOffApproveHandler(tot: TimeOffActivity, approve: boolean) {
        let canApprove = true;
        const loader = await this.loadingCtrl.create();
        loader.present();

        if (tot.timeOffRequestId.includes('offline')) {
            try {
                await this.timeOffDataService.createNewTimeOffFromOfflineData(tot);
            } catch (error) {
                this.timeOffService.isProcessing = false;
                canApprove = false;
            }
        }

        if (!canApprove) {
            console.warn('timeOffApproveHandler: Failed to upload offline data before requesting for approval');
            loader.dismiss();
            return;
        }

        let response: any;
        try {
            response = await this.timeOffDataService.approveTimeOff(tot, approve).toPromise();
        } catch (error) {
            if(error && error.error && error.error.errorCode && error.error.errorCode=='ERR_IO_AATO'){
                this.notifyService.notify(this.translate.instant('TIME_OFF_IS_ALREADY_APPROVED'),'','top','')
            } else if(error && error.error && error.error.errorCode && error.error.errorCode=='ERR_IO_TO_OPEN_TO_APPROVE'){
                this.notifyService.notify(this.translate.instant('TIME_OFF_CANT_APPROVE_TIMEOFF'),'','top','')
            }
            console.error('timeOffApproveHandler: ', error);
            this.timeOffService.isProcessing = false;
            loader.dismiss();
            return;
        }

        if (response) {
            if (!approve) {
                // this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffDetailsComponent, null, PageName.TimeOffDetailsComponent);
                this.timeOffService.isProcessing = false;
                this.timeOffService.totShowRightPane = false;
                this.activityService.selectedActivity = null;
                this.timeOffService.setSelectedTot(null);
                this.uiService.activeView = 'TimeOff';
            }
            TimeOffUtilityService.updateStatus(tot, response);
            tot.approverName = this.authenticationService.user.displayName;
            await this.timeOffService.onTimeOffApproval(tot, true, approve, response).then(() => {
                if(approve)
                    this.timeOffService.setSelectedTot(tot);
            });
        }
        this.timeOffService.isProcessing = false;
        loader.dismiss();
    }

    private static updateStatus(tot: TimeOffActivity, response: any): TimeOffActivity {
        tot.statecode = response['statecode'];
        tot.statuscode = response['statuscode'];
        tot.status = tot.statuscode;
        tot.approvalDate = new Date(response['indskr_approvaldate']);
        if (response['statuscode'] === TimeOffStatus.Open) {
            tot.statusValue = "Open";
            tot.statusString = "Open";
        } else if (response['statuscode'] === TimeOffStatus.InReview) {
            tot.statusValue = "For Review";
            tot.statusString = "For Review";
        } else if (response['statuscode'] === TimeOffStatus.Approved) {
            tot.statusValue = "Approved";
            tot.statusString = "Approved";
        }
        return tot;
    }

    private updateBulkActivities(tot: TimeOffActivity, isAutoApproval: boolean) {
        if (isAutoApproval) {
            // this.activityService.removeActivity(tot);
            tot.type = ActivityType.TimeOff;
        }
    }

    public decodeEventType(timeoff: TimeOffActivity): string {
        let eventType = this.translate.instant('ALL_DAY');
        if (timeoff.totIsAlldayEvent === true) {
            eventType = this.translate.instant('ALL_DAY');
            this.currentDurationType = DurationType.AllDay;
        }
        //either halfday or other
        else {
            //duration in minutes format
            let duration: number = this.timeOffService.durationCalculator(timeoff);
            //dont wanna take chances with seconds
            if (duration === 719 || duration === 720) {
                eventType = "Half Day";
                this.currentDurationType = DurationType.HalfDay;
            }
            else {
                eventType = "Hourly";
                this.currentDurationType = DurationType.Hourly;
            }
        }
        return eventType;
    }

    public checkCurrentDurationType(selectedDurationType): string {
      let checkedValue;
      switch(selectedDurationType){
        case DurationType.AllDay:
          checkedValue = 'All Day';
          break;
        case DurationType.HalfDay:
          checkedValue = 'Half Day';
          break;
        case DurationType.Hourly:
          checkedValue = 'Hourly';
          break;
        default:
          checkedValue = 'All Day';
          break;
      }
      return checkedValue;
    }

    public recodeEventType(data): boolean {
        if (data === "Hourly") {
            return false;
        }
        else if (data === "All Day") {
            return true;
        }
        else if (data === "Half Day") {
            return false;
        }
    }
}
