import { AssetTransfer } from './asset-transfer.class';
import { CustomerAssetNote } from "./asset-notes.class";
import _ from 'lodash';

export class CustomerAsset {

  public msdyn_customerassetid: string;
  public msdyn_name: string;
  public createdon: string;
  public msdyn_product: string;
  public productName: string;
  public msdyn_parentasset: string;
  public parentAssentName: string;
  public msdyn_account: string;
  public accountName: string;
  public statuscode: number;
  public statecode: number;
  public indskr_serialnumber: string;
  public indskr_remarks: string;
  public indskr_productcode: string;
  public indskr_customerconfirmation: boolean;
  public indskr_contact: string;
  public contactName: string;
  public indskr_assetscategory: number;
  public categoryName: string;
  public indskr_assetstatus_value: number;
  public indskr_assetstatus: string;
  public indskr_acknowledgerequired: boolean;
  public indskr_approvalrequired: boolean;
  public indskr_tobeassetowner: string;
  public indskr_tobeassetowner_name: string;
  public indskr_newlocationstartdate: string;
  public indskr_newlocationenddate: string;
  public indskr_newassetlocation: string;
  public indskr_newassetlocation_value: string;
  public ownerid: string;
  public contractId: string;
  public contractName: string;
  public transferEnabled: boolean = false;
  public _id: string;
  public _rev: string;
  public notes: CustomerAssetNote[] = [];
  public latestTransfer: AssetTransfer;

  constructor(raw) {
    this.msdyn_customerassetid = raw['msdyn_customerassetid'];
    this.msdyn_name = raw['msdyn_name'];
    this.createdon = raw['createdon'];
    this.msdyn_product = raw['msdyn_product_value'];
    this.productName = raw['msdyn_product_value@OData.Community.Display.V1.FormattedValue'];
    this.msdyn_parentasset = raw['msdyn_parentasset_value'];
    this.parentAssentName = raw['msdyn_parentasset_value@OData.Community.Display.V1.FormattedValue'];
    this.msdyn_account = raw['msdyn_account_value'];
    this.accountName = raw['msdyn_account_value@OData.Community.Display.V1.FormattedValue'];
    this.statuscode = raw['statuscode'];
    this.statecode = raw['statecode'];
    this.indskr_serialnumber = raw['indskr_serialnumber'];
    this.indskr_remarks = raw['indskr_remarks'];
    this.indskr_productcode = raw['indskr_productcode'];
    this.indskr_contact = raw['indskr_contact_value'];
    this.contactName = raw['indskr_contact_value@OData.Community.Display.V1.FormattedValue'];
    this.indskr_assetscategory = raw['indskr_assetscategory'];
    this.categoryName = raw['indskr_assetscategory@OData.Community.Display.V1.FormattedValue'];
    this.indskr_assetstatus = this.getAssetStatus(raw['indskr_assetstatus']);
    this.indskr_assetstatus_value = raw['indskr_assetstatus'];
    this.indskr_acknowledgerequired = raw['indskr_acknowledgerequired'];
    this.indskr_approvalrequired = raw['indskr_approvalrequired'];
    this.indskr_customerconfirmation = raw['indskr_customerconfirmation'];
    this.indskr_newassetlocation = raw['indskr_newassetlocation'];
    this.indskr_newlocationstartdate = raw['indskr_newlocationstartdate'];
    this.indskr_newlocationenddate = raw['indskr_newlocationenddate'];
    this.indskr_tobeassetowner = raw['indskr_tobeassetowner_value'];
    this.indskr_tobeassetowner_name = raw['indskr_tobeassetowner_value@OData.Community.Display.V1.FormattedValue'];
    this.ownerid = raw['ownerid_value'] || raw['ownerid'];
    this._id = raw['_id'];
    this._rev = raw['_rev'];
    this.notes = _.isEmpty(raw['notes']) ? [] : raw['notes'];
    if (raw['latestTransfer']) this.latestTransfer = new AssetTransfer(raw['latestTransfer']);
  }

  public getAssetStatus(value:number):string{
    switch(value){
      case 548910000 :
      return "Inactive";
      case 548910001 :
      return "Obsolete";
      case 548910002 :
      return "Active";
      case 548910003 :
      return "Damaged";
      case 548910004 :
      return "Lost by Sales Rep";
      case 548910005 :
      return "Lost by Hospital";
      case 548910006 :
      return "In Repair";
    }

  }

}

export class LocationContract {
  public indskr_assetcontractid: string;
  public indskr_name: string;
  constructor(raw) {
    this.indskr_assetcontractid = raw['indskr_assetcontractid'];
    this.indskr_name = raw['indskr_name'];
  }
}

export class AMConfig {
  public indskr_configname: string;
  public indskr_configvalue: string;
  public indskr_ioconfigurationid: string;
  constructor(raw) {
    this.indskr_configname = raw['indskr_configname'];
    this.indskr_configvalue = raw['indskr_configvalue'];
    this.indskr_ioconfigurationid = raw['indskr_ioconfigurationid'];
  }
}

export class AccountManagerUser {
  public systemuserid: string;
  public fullname: string;
  public _id: string;
  public _rev: string;
  constructor(raw) {
    this.systemuserid = raw['systemuserid'];
    this.fullname = raw['fullname'];
    this._id = raw['_id'];
    this._rev = raw['_rev'];
  }
}

export interface AssetStatusPickList{
   assetStatusKey: number;
   assetStatusValue: string
}

export enum AssetCategory {
  DEMO = 548910000,
  STAND_BY = 548910001,
  LOANER = 548910002
}

export enum AssetStatus {
  INACTIVE = 548910000,
  OBSOLETE = 548910001,
  ACTIVE = 548910002,
  DAMAGED = 548910003,
  LOST_BY_SALES_REP = 548910004,
  LOST_BY_HOSPITAL = 548910005,
  IN_REPAIR = 548910006

}
