<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [searchDebounce]="500" (onSearchInput)="onInput($event.detail)" (onFilterClick)="openActivitiesFilter($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()" (onSearchClear)="onCancel($event)"></ind-header-left>
    <!-- plan tab in activities page -->
    <ion-toolbar class="container-c">
      <ion-button *ngIf="!faService.isShortCallLauncherEnabled" fill="clear" size="small" (click)="onSelectedTabChange('notifications')" class="notification-btn">
        <ion-icon slot="icon-only" src="assets/imgs/notification_bluedot.svg" class="tab-notification-dot" *ngIf="assistantService.hasAnyUnRedNotifications"></ion-icon>
        <ion-icon slot="icon-only" src="assets/imgs/notifications-inactive.svg"></ion-icon>
      </ion-button>
        <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab" (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>
<ion-content>

    <ng-container *ngIf="deltaService.isInitialSyncFinished; else snapshot">
        <ng-container *ngIf="noPlanList ">
            <ind-no-search-result [text]="('NO_PENDING_TASK_TODAY' | translate)" [src]="'assets/imgs/no-pending-task-today.svg'"></ind-no-search-result>
        </ng-container>
        <ng-container *ngIf="!noPlanList">
            <ion-list class="incomplete-activities-list" *ngIf="!shouldHidePinnedSection()">
                <ind-section-header [viewData]="pinnedItemsSectionheader" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
                <p *ngIf="filteredPinnedItems.length === 0 && isPinnedItemsSectionExpanded" class="no-pending-message">
                    {{getNoPinnedItemsMessage()}}
                </p>
                <ng-container *ngIf="filteredPinnedItems.length > 0 && isPinnedItemsSectionExpanded">
                    <div *ngFor="let pinnedItem of filteredPinnedItems">
                        <ion-item-sliding #slidingItem>
                            <main-card [viewData]="pinnedItem"></main-card>
                            <ion-item-options side="end">
                                <div class="sliding-button" (click)="unpinItem(pinnedItem.id); slidingItem.close();">
                                    <img class="sliding-icon" src="assets/imgs/unpin.svg" width="40" />
                                    <!-- <p class="sliding-btn-text">{{''|translate}}</p> -->
                                </div>
                            </ion-item-options>
                        </ion-item-sliding>
                    </div>
                </ng-container>
            </ion-list>
            <ion-item class="seperator" *ngIf="!isPinnedItemsSectionExpanded"></ion-item>
            <ion-list class="incomplete-activities-list">
                <ind-section-header [viewData]="pendingActionsSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
                <p *ngIf="filteredPendingActioncards.length === 0 && isPendingActionsExpanded" class="no-pending-message">
                    {{getNoPendingActionmessages()}}
                </p>
                <ng-container *ngIf="filteredPendingActioncards.length > 0 && isPendingActionsExpanded">
                    <div *ngFor="let pendingActionItem of filteredPendingActioncards">
                        <ion-item-sliding #slidingItem>
                            <main-card [viewData]="pendingActionItem"></main-card>
                            <ion-item-options side="end">
                                <div class="sliding-button" (click)="pinIncompleteActivity(pendingActionItem.id, pendingActionItem.type, type.PENDING_ACTIONS); slidingItem.close();">
                                    <img class="sliding-icon" src="assets/imgs/pin.svg" width="40" />
                                    <!-- <p class="sliding-btn-text">{{''|translate}}</p> -->
                                </div>
                            </ion-item-options>
                        </ion-item-sliding>
                    </div>
                </ng-container>
            </ion-list>
            <!-- <ion-infinite-scroll *ngIf="allPendingActionCards.length > 0" (ionInfinite)="doInfinite($event)" threshold="50px">
                <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll> -->
            <ion-item class="seperator" *ngIf="!isPendingActionsExpanded"></ion-item>
            <ion-list class="incomplete-activities-list">
                <ind-section-header [viewData]="incompleteActivitiesSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
                <div *ngIf="visibleIncompleteActivities.length === 0 && getisIncompleteActivitiesExpanded()" class="no-data-message">
                    {{getNoIncompleteActivitiesmessage()}}
                </div>
                <ng-container *ngIf="visibleIncompleteActivities.length > 0 && getisIncompleteActivitiesExpanded()">
                    <div *ngFor="let activity of visibleIncompleteActivities">
                        <ion-item-sliding #slidingItem *ngIf="!isPinned(activity.ID, type.INCOMPLETE_ACTIVTIES)">
                            <main-card [viewData]="getActivityMainCard(activity)"></main-card>
                            <ion-item-options side="end">
                                <div class="sliding-button" (click)="pinIncompleteActivity(activity.ID, activity.type, type.INCOMPLETE_ACTIVTIES); slidingItem.close();">
                                    <img class="sliding-icon" src="assets/imgs/pin.svg" width="40" />
                                    <!-- <p class="sliding-btn-text">{{''|translate}}</p> -->
                                </div>
                            </ion-item-options>
                        </ion-item-sliding>
                    </div>
                </ng-container>
            </ion-list>
            <ion-infinite-scroll *ngIf="filteredIncompleteActivities.length > 0" (ionInfinite)="doInfinite($event)" threshold="15%">
                <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ng-container>
    </ng-container>

    <ng-template #snapshot>
        <ng-container *ngIf="!deltaService.isInitialSyncFinished && activityService.snapshot; else initializing;">
            <div *ngFor="let viewPortItem of activityService.snapshot">
                <ind-section-header *ngIf="viewPortItem.isDivider" [viewData]="viewPortItem.view"></ind-section-header>
                <main-card *ngIf="!viewPortItem.isDivider" [viewData]="viewPortItem"></main-card>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #initializing>
        <ion-list>
            <ind-section-header [isSkeleton]="true"></ind-section-header>
            <ng-container *ngFor="let i of [].constructor(numberOfSkeletonCardsToDisplay)">
                <main-card [isSkeleton]="true"></main-card>
            </ng-container>
        </ion-list>
    </ng-template>
</ion-content>
<!-- <footer-toolbar [footerView]="'masterView'" [selectedView]="'accountPlansList'"></footer-toolbar> -->
