import { Injectable } from '@angular/core';
import { Brand } from '../../classes/brand/brand.class';
import PouchDB from 'pouchdb';
import { DiskService } from '../disk/disk.service';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BrandOfflineService {
    public brands: Array<Brand> = [];
    public productIndications = [];
    public keyMessagesByPositionGroups = [];

    constructor(
      private disk: DiskService,
      private readonly authService: AuthenticationService,
      ) {
        this.brands = new Array<Brand>();
    }

    public getBrandByID(id: string): Brand {
        return this.brands.find(brand => brand.ID === id);
    }

    //bug CWD-3628
    public sortBrands(){
        if(!this.brands || this.brands.length == 0) return;
        this.brands = this.brands.sort((a,b) =>{
            if(a.name.toLowerCase() > b.name.toLowerCase()){
                return 1;
            }
            if(b.name.toLowerCase() > a.name.toLowerCase()){
                return -1;
            }
        }); 
    }

    /**
     * Maps a raw json response into classes and adds them to our array, brands
     * 
     * @param {object} raw the json response object
     * @returns {Array<Brand>} our internal array
     * @memberof BrandOfflineService
     */
    public mapBrands(raw: object, doNotSave?: boolean): Array<Brand> {
        if (Array.isArray(raw)) {
            this.brands = [];
            raw.map((rawJSON , index) => {
                let brand = new Brand(rawJSON , index);
                if(this.authService.user.indskr_mapkeymessagestopositiongroup){
                  brand.keyMessages = brand.keyMessages.filter(k => this.keyMessagesByPositionGroups.some(km=> km.indskr_keymessageid == k.ID));
                }
                this.brands.push(brand); //indexed because the data from the backend does not assign priorities / sequence!!!
            });
        } else if (raw) {
          let brand = new Brand(raw);
          if (this.authService.user.indskr_mapkeymessagestopositiongroup) {
            brand.keyMessages = brand.keyMessages.filter(k => this.keyMessagesByPositionGroups.some(km => km.indskr_keymessageid == k.ID));
          }
          this.brands.push(brand); 
        }

        if (!doNotSave) {
            //Save to our DB or update
            this.disk.updateOrInsert(DB_KEY_PREFIXES.PRODUCT, doc => ({ raw }))
                .catch(error => console.error('mapBrands: ', error));
        }

        return this.brands;
    }

    deSelectAll() {
        this.brands.map(brand => {
            //Remove isSelected & isAutoSelected from each product to reset the ui
            brand.isSelected = undefined;
            brand.isAutoSelected = undefined;
            brand.isGenieSelected = undefined;

            brand.keyMessages.map(keyMessage => {
                keyMessage.isAutoSelected = undefined;
                keyMessage.isSelected = undefined;
                keyMessage.isGenieSelected = undefined;
            });
        });
    }

    public clear() {
        this.brands = [];
    }
}