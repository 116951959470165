import { Component, Output, EventEmitter } from "@angular/core";
import { PresentationService } from "../../../services/presentation/presentation.service"
import { LogService } from "../../../services/logging/log-service";
import { PopoverController } from '@ionic/angular';

@Component({
    selector:"presentation-view-popover",
    template: `
      <ion-list>
        <ion-item *ngFor="let ic of iconList" (click)="changeView(ic)">
            <ion-icon [name]="ic.code" item-start> </ion-icon>
            <ion-label> {{ic.name}} </ion-label>
        </ion-item>
      </ion-list>
    `
  })
  export class PresentationViewPopover {
    constructor(
        public presServices: PresentationService,
        private logService:LogService,
        public popoverCtrl: PopoverController
    ) {}

    public iconList = [
        {
            name: "list",
            code: "ios-list"
        },
        // {
        //     name: "grid",
        //     code: "ios-grid"
        // }
    ]

    type = {
        name: "list",
        code: "ios-list"
    }

    @Output()
    changePresView = new EventEmitter();

    changeView(type: any) {
        this.logService.logDebug(this.iconList)
        this.presServices.setCurrentSelectedView(type);
        this.popoverCtrl.dismiss();
    }
  }
