<ind-popup-modal-header
  #modalWrapper
  [title]="data?.title"
  (onCloseClick)="onCloseClick($event)"
>
</ind-popup-modal-header>
<ion-content #content>
  <div
    #contentWrapper
    class="wrapper"
  >
    <div
      *ngIf="data?.dataType == 'table' else textBlock"
      class="table-data-wrapper"
    >
      <ion-grid
        *ngIf="data?.data?.tableHeader && data?.data?.tableRows"
      >
        <ion-row
          class="table-header"
        >
          <ion-col
            class="table-header-col"
            *ngFor="let headerCol of data.data.tableHeader; let i = index"
            [size]="i == 0 ? 3 : 9"
            [sizeSm]="i == 0 ? 4 : 8"
          >
            <div class="table-header-cell">
              {{headerCol}}
            </div>
          </ion-col>
        </ion-row>
        <ion-row
          class="table-body"
          *ngFor="let row of data.data.tableRows"
        >
          <ion-col
            class="table-body-col"
            *ngFor="let col of row; let i = index"
            [size]="i == 0 ? 3 : 9"
            [sizeSm]="i == 0 ? 4 : 8"
          >
            <div class="table-body-cell">
              {{col}}
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ng-template #textBlock>
      <div class="text-data-wrapper">
        <ion-textarea
          autoGrow
          readonly
          [value]="data?.data?.text"
        ></ion-textarea>
      </div>
    </ng-template>
  </div>
</ion-content>