<ion-header>
    <ind-page-title [viewData]="allocationAdjustPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content #Content padding="true" overflow-scroll="false" id="alloc-adjust-content" [ngClass]="{'ion-content ': device.deviceFlags.ios}">
    <ion-list class='details-list'>

        <ind-section-header [viewData]='detailsHeader'></ind-section-header>

        <ind-form-field [viewData]="statusFormField"></ind-form-field>

        <ind-form-field [viewData]="adjustNumberField"></ind-form-field>

        <ind-form-field [viewData]="adjustDateField"></ind-form-field>

        <ind-form-field [viewData]="selectedProductField"></ind-form-field>

        <ind-section-header [viewData]='availableLotsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

        <ion-item class="pill-scroll-x-item" margin-top *ngIf="allocationAdjustmentForm?.sku && allocationAdjustmentForm?.availableLots">
            <!-- <ion-row> -->
            <ion-col [ngClass]="allocationAdjustmentForm?.availableLots.length > 1?'activeArrow':''" class="arrow">
                <ion-icon name="chevron-back-outline" style="color:#c8c7cc;" (click)="arrowClicked('left')"></ion-icon>
            </ion-col>
            <ion-col class="scroll-container" id="lotsContainer">
                <div *ngIf="allocationAdjustmentForm?.availableLots.length > 0">
                    <span *ngFor="let Lot of allocationAdjustmentForm?.availableLots" [ngClass]="{'selected': isLotSelected(Lot)}" class="lotNameBubble" (click)="selectLot(Lot)">{{Lot.name}} {{'(' + Lot.totalQuantityRemaining + ')'}}
                            </span>
                </div>
            </ion-col>
            <ion-col [ngClass]="allocationAdjustmentForm?.availableLots.length > 1?'activeArrow':''" class="arrow arrow-right">
                <ion-icon name="chevron-forward-outline" style="color:#c8c7cc;" (click)="arrowClicked('right')"></ion-icon>
            </ion-col>
        </ion-item>

        <ind-section-header *ngIf="allocationAdjustmentForm?.sku && allocationAdjustmentForm?.lot" [viewData]='selectedLotsHeader'></ind-section-header>

        <ion-item *ngIf=" !device.deviceFlags.ios && allocationAdjustmentForm?.sku && allocationAdjustmentForm?.lot" class="qtyLot">
            <ion-label>
                <p class="primary-text">
                    <ion-text>{{allocationAdjustmentForm?.lot?.name}}</ion-text>
                </p>
                <p class="child-label-text">
                    <ion-text>{{formattedDateInfoLabelForSampleLot}}</ion-text>
                </p>
                <p class="form-field-label-text">
                  <ion-text>{{'QUANTITY' | translate}}</ion-text>
                </p>
                <p class="qtyLotInput" *ngIf="!device.deviceFlags.ios">
                    <ion-input *ngIf="!device.deviceFlags.ios" [ngClass]="{'exceededlimits': allocationAdjustmentForm.quantityAdjusted && !allocationAdjustmentForm.isQtyValid}" [(ngModel)]='allocationAdjustmentForm.quantityAdjusted' pattern="-?[0-9]*" step="1" placeholder="0"
                        value="{{allocationAdjustmentForm.quantityAdjusted?allocationAdjustmentForm.quantityAdjusted:null}}" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 45" class="qtyLot-labelText" #focusInput (focus)="onInputFocus(focusInput)"
                        [disabled]="allocationAdjustmentForm.lot.totalQuantityRemaining >= 0 ? false : true">
                    </ion-input>
                </p>
            </ion-label>
        </ion-item>

        <ind-form-field *ngIf="device.deviceFlags.ios && allocationAdjustmentForm?.sku && allocationAdjustmentForm?.lot" [viewData]="getadjustmentQuantityField()" [biggerFontLabel]="true" [ngClass]="{'exceededlimits': allocationAdjustmentForm.quantityAdjusted && !allocationAdjustmentForm.isQtyValid}"></ind-form-field>
        <ind-form-field *ngIf="allocationAdjustmentForm.lot" [viewData]="reasonField"></ind-form-field>
        <ind-form-field *ngIf="allocationAdjustmentForm.reason && allocationAdjustmentForm.reason.value==548910004" [viewData]="getCommentFormField()"></ind-form-field>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'AdjustDtails'"></footer-toolbar>
<!-- </ion-footer> -->
