<!-- Generated template for the LanguageSettingsComponent component -->

<ion-grid no-padding class="content-page" [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-inactive':'preview-active'">
    <ion-row no-padding>
        <ion-col class="left-pane" no-padding>
            <app-language-list></app-language-list>
        </ion-col>
        <ion-col class="right-pane" no-padding>
            <nothing-selected-view notShowText="true" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
        </ion-col>
    </ion-row>
</ion-grid> 