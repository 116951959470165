
import {map} from 'rxjs/operators';
import { Component, Input, EventEmitter, Output, ChangeDetectorRef, HostListener } from "@angular/core";
import { Presentation } from '../../../classes/presentation/presentation.class';
import { Observable ,  Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { fileState } from "../../../store/application.state";
import { IoFileService } from "../../../services/io-file-service/io-file-service";
import { selectIsDownloading, selectPresentationId, selectIsUnZipping } from '../../../store/io-file-service/states/file.selectors';

import { PresentationView } from '../../../services/ui/ui.service'
import { PresentationService } from "../../../services/presentation/presentation.service";
import { DeviceService } from "../../../services/device/device.service";
import { Resource } from "../../../classes/resource/resource.class";
import { ResourceAssetType } from "../../../classes/meeting/meeting.class";
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { TranslateService } from '@ngx-translate/core';
import { SafePipe } from '../../../pipes/safe.pipe';
import _ from 'lodash';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'presentation-item',
  templateUrl: 'presentation-item.html',
  styleUrls:['presentation-item.scss'],
  providers: [SafePipe]
})

export class PresentationItem {

    @Input() pres: Presentation | Resource;
    @Input() isMeetingPres: any;
    @Input() isSelected: any;
    @Input() viewMode: PresentationView;
    @Input() sortedBy: string;
    @Input() displayFormat: string;
    @Input() listType: string;
    @Input() presItemTransform;
    @Input() showcancelIcon: boolean = true;

    @Output() addToMeeting = new EventEmitter();
    @Output() downloadPres = new EventEmitter();
    @Output() favouritePres = new EventEmitter();
    @Output() showPreview = new EventEmitter();
    @Output() togglePresSelection = new EventEmitter();


    // Download related
    public progressPer: number = 0;
    public isDownloading$: Observable<boolean>;
    public isUnzipping$: Observable<boolean>;
    public isDownloadPending$: Observable<boolean>;
    public isActiveDownload$: Observable<boolean>;

    public isActivePres: boolean = false;
    public isOffline: boolean = false;
    public isMobile: boolean = false;

    public presView = PresentationView;

    //resource
    public isResource: boolean = false;
    public isResourceAttachable: boolean = false;

    private _createdOn: Date;
    public createdDate: string;
    private _updatedOn: Date;
    public updatedDate: string;

    public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];


    public mainTransform: number;

    public mainWrapperHeight: number;
    public mainWrapperWidth: number;
    public mainWrapperMarginTop: number;
    public mainWrapperMarginLeft: number;

    public iframeScaleWidth: number;
    public iframeScaleHeight: number;

    private windowWidth: number = 0;
    //This is used to check last selected presentation
    private lastSelectedPres:any;


    private curPresSubscription: Subscription;
    private dlProgressSubscription: Subscription;
    private unzipProgressSubscription: Subscription;
    public thumbUrl: SafeUrl;

    constructor(
        public store: Store<fileState>,
        private ioFileDownload: IoFileService,
        public presService: PresentationService,
        private deviceService: DeviceService,
        private ref: ChangeDetectorRef,
        private datePipe: DatePipe,
        public dateTimeFormatsService: DateTimeFormatsService,
        public translate: TranslateService,
        private safePipe: SafePipe
    ) {

        this.isOffline = this.deviceService.isOffline;
        this.isMobile = this.deviceService.isNativeApp;

        this.curPresSubscription = this.presService.currentSelectedPres.subscribe(aPres => {
            this.lastSelectedPres=aPres;
            if (!aPres || !this.pres) {
                this.isActivePres = false;
                return;
            }
            if (aPres === this.pres) {
                this.isActivePres = true;
            } else {
                this.isActivePres = false;
            }
        })

        this.dlProgressSubscription = this.ioFileDownload.dlProgress$.subscribe(per => {
            // console.log(this.progressPer)
            this.progressPer = per;
        });

        this.unzipProgressSubscription = this.ioFileDownload.unzipProgress$.subscribe(per => {
            this.progressPer = per;
        });

        this.isDownloading$ = this.store.select(selectIsDownloading);
        this.isUnzipping$ = this.store.select(selectIsUnZipping);

        this.isActiveDownload$ = this.store.select(selectPresentationId).pipe(
            map(rs => {
                if (!this.pres) {
                    return false;
                }
                if (rs === this.pres['ioPresentationId']) {
                    return true;
                } else {
                    return false;
                }
            }));
    }

    ngOnInit() {
      this.thumbUrl = this.thumbURL();
    }

    ngAfterViewInit() {
        if (this.pres instanceof Presentation) {
            this._createdOn = new Date(Number.parseInt(this.pres.createdOn));
            this._updatedOn = new Date(Number.parseInt(this.pres.modifiedOn));
            //here we check condition  any selected presentation is there or not
           if(this.lastSelectedPres==this.pres){
               this.isActivePres=true;
           }
        } else if (this.pres instanceof Resource) {
            this.isResource = true;
            this._createdOn = this.pres.createdOn;
            this._updatedOn = this.pres.modifiedOn;
            if( this.pres.assetType){
                this.isResourceAttachable = (this.pres.assetType === ResourceAssetType.DOCUMENT && this.pres.assetURL.endsWith(".pdf")) ||
                this.pres.assetType === ResourceAssetType.IMAGE || this.pres.assetType === ResourceAssetType.VIDEO;
            }
        }
        if(this._createdOn && this._updatedOn){
            this.createdDate = this.months[this._createdOn.getMonth()] + " " + this._createdOn.getDate() + ", " + this._createdOn.getFullYear();
            this.updatedDate = this.months[this._updatedOn.getMonth()] + " " + this._updatedOn.getDate() + ", " + this._updatedOn.getFullYear();
        }
    }

    ngDestroy() {

        this.curPresSubscription.unsubscribe();
        this.dlProgressSubscription.unsubscribe();
        this.unzipProgressSubscription.unsubscribe();

    }

    public ngAfterViewChecked(): void {
        // if(this.initView) {
        //     this.resizePresThumb();
        //     this.initView = false;
        // }
    }

    public addPresToMeeting() {
        event.stopPropagation();
        if (!this.isOffline || this.pres.downloaded) {
            this.addToMeeting.emit();
        } else {
            return;
        }
    }

    public removePresFromMeeting() {
        event.stopPropagation();
        this.addToMeeting.emit();
    }

    public download() {
        event.stopPropagation();
        this.downloadPres.emit();
    }

    public favourite() {
        event.stopPropagation();
        this.favouritePres.emit();
    }

    public showDetails() {
        this.showPreview.emit();
    }

    public toggleIsSelected() {
        this.pres.isSelected = !this.pres.isSelected;
        this.toggleIsPresSelected(null);
    }

    public toggleIsPresSelected(ev: any) {
        this.togglePresSelection.emit();
        if (this.presService.isSelectMode && !(this.deviceService.isMobileDevice && this.deviceService.deviceOrientation === 'PORTRAIT')) {
            this.showDetails();
        }
    }

    public unixToDate(unixTS: any) {
        let d = new Date(unixTS + 1000);
        // console.log(this.unixToDate)
        let hr = d.getHours();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // this.resizePresThumb();
    }

    resizePresThumb() {

        if (this.listType != 'grid') return;


        let imgHeight = 75;
        let imgWidth = 100;

        let width = document.getElementById('grid-item-wrapper').offsetWidth - 10;
        let height = 136 - 5;

        if (this.windowWidth == width) return;
        this.windowWidth = width;

        const scalerH = height / imgHeight;
        const scalerW = width / imgWidth;

        const scaleRatio = imgWidth / imgHeight;
        var ratio = Math.min(scalerW, scalerH);

        this.mainTransform = ratio;

        if (ratio > scaleRatio) {

            this.mainWrapperMarginLeft = (width - (imgWidth * scalerH)) / 2;
            this.mainWrapperMarginTop = (height - (imgHeight * scalerH)) / 2;
        } else {

            this.mainWrapperMarginLeft = 0;
            this.mainWrapperMarginTop = (height - (imgHeight * scalerW)) / 2;
        }

        this.iframeScaleWidth = imgWidth * this.mainTransform;
        this.iframeScaleHeight = imgHeight * this.mainTransform;

    }

  getFormattedDate() {
    return this.getFormattedAndLocalisedDate(this._updatedOn ? this._updatedOn : this._createdOn)
  }

  getFormattedAndLocalisedDate(date: Date): string {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  public thumbURL():SafeUrl {
    let url = null;
    if (this.pres) {
      url = this.pres['thumbnailUrl'] || this.pres['thumbnailURL'];
      if (!_.isEmpty(url) && this.pres.downloaded && this.deviceService.isOffline) {
        if (this.pres instanceof Presentation) {
          url = this.ioFileDownload.getLocalURL("pres_thumbnail/" + this.pres.thumbnailUrl.substr(this.pres.thumbnailUrl.lastIndexOf('/') + 1), this.pres.ioPresentationId);
        } else {
          const thumbLocation = "thumbnail/" + this.pres.thumbnailURL.substr(this.pres.thumbnailURL.lastIndexOf('/') + 1);
          url = this.ioFileDownload.getLocalURLForResource(this.pres.ioResourceId ? this.pres.ioResourceId : this.pres.ioDocumentId, thumbLocation);
        }
      }
    } 
    return this.safePipe.transform(url, 'url');
  }
}
