import { EventsService } from '@omni/services/events/events.service';
import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';

const DEFAULT_SLIDES_PER_VIEW = 6;

@Component({
  selector: 'io-reference-carousel',
  templateUrl: 'io-reference-carousel.component.html',
  styleUrls: ['io-carousel.scss', 'io-reference-carousel.component.scss'],
})
export class IOReferenceCarousel implements AfterViewInit {
  private _isDataLoading: boolean = true;
  public references: any[];

  @Input()
  set pageReferences(references: any[]) {
    this.references = references;
  }
  @Input()
  set isDataLoading(isLoading: boolean) {
    this._isDataLoading = isLoading;
  }
  get isDataLoading() {
    return this._isDataLoading;
  }
  @Output() onLinkClick = new EventEmitter<any>();

  @ViewChild('Slides', {static:true}) slides: HTMLIonSlidesElement;

  public slidesPerView: number = DEFAULT_SLIDES_PER_VIEW;

  constructor(
    private events: EventsService,
  ) {}

  public ngAfterViewInit() {
    this.events.publish('footer-toolbar:carousel-toggle');
  }

  public removeSwiperLock(){
    //CWD-1459
    this.slides.lockSwipes(false); // on drag event unlock swiper
  }

  public referenceClicked(reference: any) {
    this.onLinkClick.emit(reference);
    this.slides.lockSwipes(false); // release the lock on the swiper
  }
}
