import { SurveyFrequency, SurveyType, TemplateType, SurveyStatus } from "./assessment-template.class";

export class CustomerAssessment {
  public _id: string;
  public _rev: string;
  public pendingPushToDynamics?: boolean = false;
  public isInitialAssessInOffline?: boolean = false;
  public contactId?: string;
  public accountId?: string;
  public positionId?: string;
  public position: string;
  // public surveyStatus?: SurveyStatus;
  
  constructor(
    public indskr_customerassessmentid: string,
    public indskr_template: string,
    public indskr_entity: string,
    public indskr_name: string,
    public indskr_entityid: string,
    public indskr_assessmentdate: string,
    public modifiedon: string,
    public responses: any[],
    public indskr_type?: TemplateType,
    public indskr_surveytype?: SurveyType,
    public indskr_surveyfrequency?: SurveyFrequency,
    public indskr_appointmentid?: string,
    public surveyStatus?: SurveyStatus,
    public isValidToSubmit?: boolean,
    public indskr_affiliatedcontactid?: string
    ) {
  }
}

export interface AssessmentGrid {
  columnId: string,
  columnSubId: string,
  columnType: ColumnType,
  sectionIdx?: number,
  headerSecondId?: string,
  headerSecondLabel?:{primary: string , secondary: string},
  columnItems: Array<AssessMatricItemModel>,
}

export type EndSlotItemType = 'none' | 'button' | 'arrow';
export interface AssessMatricItemModel {
  columnItemId: string,
  isHeaderFirst: boolean,
  isHeaderSecond: boolean,
  isHeaderThird: boolean,
  isInputField: boolean,
  inputFieldType: InputFieldType,
  endSlotItemType: EndSlotItemType,
  columnItemLabel: string,
  columnItemSecondaryLabel: string,
  isRequired: boolean,
  placeholder?: string,
  arrowType?: string,
  isDisabled?: boolean,
  isMoreHeight:boolean,
  isForcedHide?: boolean,
  controls?: Array<any>,
  imgSrc?: string,
  sectionId?: string,
  eventHandler?:(id?:string, event?:any, eventName?:string, item?)=>void;
}

export enum ColumnType {
  CONTACT_LIST = 'Contact-List',
  COLUMN_SECTION_GENERAL = 'Column-Section-General',
  COLUMN_GENERAL = 'Column-General',
  COLUMN_SECTION_EMPTY = 'Column-Section-Empty',
  COLUMN_SECTION = 'Column-Section'
}

export enum InputFieldType {
  NONE = 'None',
  TEXT = 'Text Field',
  DROP_DOWN = 'Drop Down Field',
  DATE_TIME = 'Date Time Field',
  NUMERIC = 'Numeric Field'
}
export interface AssessTeamviewRefData {
  ID: string;
  name: string;
}


export class InternalSurvey {
  public _id: string;
  public _rev: string;
  public pendingPushToDynamics?: boolean = false;
  public isInitialAssessInOffline?: boolean = false;
  public positionId?: string;
  public position: string;
  
  constructor(
    public indskr_usersurveyid: string,
    public indskr_template: string,
    public indskr_name: string,
    public indskr_surveydate: string,
    public modifiedon: string,
    public responses: any[],
    public indskr_userid: string,
    public indskr_type?: TemplateType,
    public indskr_surveytype?: SurveyType,
    public indskr_surveyfrequency?: SurveyFrequency,
    public indskr_appointmentid?: string,
    public surveyStatus?: SurveyStatus,
    public isValidToSubmit?: boolean,
    
    ) {
  }
}