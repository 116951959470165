import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ScientificActivityPlan } from '../../../../classes/scientific-activity/scientific-activity.class';
import { ContactOfflineService } from '../../../../services/contact/contact.service';
import { Contact } from '../../../../classes/contact/contact.class';
import { isThisISOWeek } from 'date-fns';
import { TrackService, TrackingEventNames } from '../../../../services/logging/tracking.service';
import {GlobalUtilityService} from "../../../../services/global-utility.service";
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../../services/device/device.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';

@Component({
    selector: 'scientific-contact-list',
    templateUrl:'scinetific-contact-list.html',
  styleUrls:['scinetific-contact-list.scss']
})

export class ScinetificContactList{

    @Input() selectedSAP: ScientificActivityPlan;
    @Output() selectContact = new EventEmitter();
    public contactsList:Contact[] = [];
    public isSearching:boolean = false;
    public customerHeader:IndSectionHeaderViewDataModel;
    sapContacts:Contact[] = [];
    globalCustomerDisplayText: string;
    globalCustomerText: string;
    searchText: string;
    constructor(
      private contactService:ContactOfflineService,
      private trackingService: TrackService,
      public utilityService:GlobalUtilityService,
      public translate:TranslateService,
      public device: DeviceService
    ){
      switch (this.utilityService.globalCustomerText) {
        case 'Customer':
          this.globalCustomerDisplayText = this.translate.instant('CUSTOMERS').toUpperCase();
          this.globalCustomerText = this.translate.instant('CUSTOMER');
          break;
        case 'Stakeholder':
          this.globalCustomerDisplayText = this.translate.instant('STAKEHOLDERS').toUpperCase();
          this.globalCustomerText = this.translate.instant('STAKEHOLDER');
          break;
        default:
          this.globalCustomerDisplayText = this.utilityService.globalCustomersText.toUpperCase();
          this.globalCustomerText=this.utilityService.globalCustomerText;
          break;
      }
    }
    ngOnInit(){
        this.contactsList = [...this.selectedSAP.contacts];
        this.contactsList.sort((x,y) => {
          return ((x.fullname.toLowerCase() == y.fullname.toLowerCase()) ? 0 : ((x.fullname.toLowerCase() > y.fullname.toLowerCase()) ? 1 : -1 ));
      });
      this.setCustomerHeader();
    }

    setCustomerHeader() {
      this.customerHeader = {
        id: 'customer-header',
        title: this.isSearching? this.translate.instant('RESULTS_SMALL') + " (" + this.contactsList.length +")" :
               this.translate.instant('ALL_CAP') + " " + this.globalCustomerDisplayText+ " (" + this.contactsList.length +")",
        controls: [
        ],
    };
    }

    openContact(contact:Contact){
      this.trackingService.tracking('ScientificPlanCustomerClick', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
      this.selectContact.emit(contact);
    }


  onInput(event) {
    if (event && event.target) {
      const val = event.target.value;
      if (val && val.trim() != '') {
        this.isSearching = true;
        this.contactsList = this.selectedSAP.contacts.filter((item) => {
          return (item.fullname.toLowerCase().indexOf(val.toLowerCase()) > -1);
        });
      }
    } else {
      this.contactsList = [...this.selectedSAP.contacts];
      this.isSearching = false;
    }
    this.setCustomerHeader();
  }
}
