<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
  <ind-section-header [viewData]="headerData">
  </ind-section-header>
</ion-header>
<ion-content>
  <ind-form-field [viewData]="descriptionFormField"></ind-form-field>
  <ind-form-field [viewData]="amountFormField"></ind-form-field>
  <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-section-header>
  <ind-text-area [placeHolder]="'ENTER_NOTES' | translate" [maxLength]="1000" [showAttachment]="isAttachmentAdded"
    [hasDownload]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" (removeAttachment)="removeAttachment($event)"
    [value]="notes" (indChange)="notesChanged($event)" [rows]="4" [cols]="10">
  </ind-text-area>
  <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
  <io-note *ngFor="let note of nonDeletedNotes" [isNoteAttachmentEnabled]="true" [inputNote]="note" [editable]="true"
    [readOnlyNote]="false" [readOnlyAttachment]="false " [deletable]="true" (saveNoteOut)="updateNote($event)"
    [disableDownloadInOffline]="true" [from]="uiService.page.ExpensesDetails"></io-note>
</ion-content>