import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { CustomerInsightComponent } from '../contact/customer-insight/customer-insight';
import { TrackingEventNames, TrackService } from '../../services/logging/tracking.service';
import { XperiencesService } from '../../services/xperiences/xperiences.service';
import { UIService } from '../../services/ui/ui.service';
import { TrendingCustomer, InsightType } from '../../classes/xperiences/trending.customer.class';
import { GeneralInsightsPlusComponent } from '../general-insights-plus/general-insights-plus';
import { DeviceService } from '../../services/device/device.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Utility } from '@omni/utility/util';
import { FooterService } from '@omni/services/footer/footer.service';

/**
 * Generated class for the InisghtsPlusComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'insights-plus',
  templateUrl: 'insights-plus.html',
  styleUrls: ['insights-plus.scss']
})
export class InsightsPlusComponent implements OnInit, OnDestroy {
  isConfirmEnabled = !this.device.isOffline;
  headerData = {
    id: 'new-insights-header',
    title: this.translate.instant('XPERIENCES_NEW_INSIGHTS'),
    controls: [],
  };
  title = {
    id: "new-insights-plus-header",
    title: this.translate.instant('XPERIENCES_INSIGHTS_PLUS'),
    controls: [
      {
        id: 'cancel_button',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: "right"
      },
      {
        id: 'confirm_button',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('CONFIRM'),
        isDisabled: this.device.isOffline ? true : false,
        align: "right"
      }
    ]
  };
  insightType = InsightType;
  selectedInsightType: InsightType = this.insightType.InisghtsPlus;
  private ngDestroy$ = new Subject<boolean>();
  public translatedCutomerText: string;

  constructor(private translate: TranslateService,
    private navService: NavigationService,
    private xperiencesService: XperiencesService,
    private uiService: UIService,
    private trackingService: TrackService,
    public footerService: FooterService,
    private device: DeviceService) {
      this.translatedCutomerText = Utility.globalCustomerText == 'Stakeholder' ? this.translate.instant('STAKEHOLDER') : this.translate.instant('CUSTOMER');
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case this.title.controls[1].id:
        this.createNewInsightsPlus();
        break;
      case this.title.controls[0].id:
        this.cancelNewInsights();
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.title.controls[1].isDisabled = data;
    });
  }

  selectInsightsType(event) {
    this.isConfirmEnabled = true;
    this.selectedInsightType = event;
  }

  cancelNewInsights() {
    this.xperiencesService.enableInsight = true;
    this.navService.popChildNavPageWithPageTracking();
    this.uiService.showRightPane = false;
  }

  createNewInsightsPlus() {
    if (this.selectedInsightType === InsightType.InisghtsPlus) {
      this.openInsightPlusPage();
    } else if (this.selectedInsightType === InsightType.GeneralInsightsPlus) {
      this.openGeneralInsightPlusPage();
    }
  }

  openGeneralInsightPlusPage() {
    this.xperiencesService.generalInsight = null;
    this.navService.pushChildNavPageWithPageTracking(GeneralInsightsPlusComponent,
      PageName.GeneralInsightsPlusComponent);
  }

  async openInsightPlusPage() {
    this.trackingService.tracking('XperiencesInsights+', TrackingEventNames.INSIGHTSPLUS);
    this.xperiencesService.enableInsight = false;
    let newInsight: TrendingCustomer = new TrendingCustomer();
    newInsight.journeys = [];
    newInsight.interests = [];
    newInsight.relationships = [];
    this.xperiencesService.newInsight = newInsight;
    await this.navService.pushChildNavPageWithPageTracking(CustomerInsightComponent, PageName.CustomerInsightComponent, PageName.ActivitiesPageComponent, { from: PageName.GeneralInsightsPlusComponent });
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }


}
