<ion-header>
  <ind-page-title [viewData]="notificationDetailsPageTitle" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-page-title>
  <ind-section-header [viewData]='headerData'>
  </ind-section-header>
</ion-header>

<ion-content>
  <div *ngIf="isTextBasedView">
    <ind-text-area [readonly]="true" [maxLength]="1000" [value]="notification"
                   [ws]="10" [rows]="4" [cols]="10" [autoGrow]="true">
    </ind-text-area>
  </div>

  <ng-container *ngIf="!isTextBasedView">
    <ion-list scrollY="true" *ngIf="newData.length > 0">
      <ion-item-group class="item-group" *ngFor="let item of newData">
        <ion-item *ngIf="integratedNotificationType != 'manageList'" (click)="onItemClick(item)">
          <p *ngIf="integratedNotificationType == 'changeRequest' || integratedNotificationType == 'newContactNewAccount'" [attr.data-letters]="getDataAttr(item)"></p>
          <progress *ngIf="selectedNotification.type == 'NEW_PRESENTATIONS_NOTIFICATION' && 
          (isDownloading$ | async) && item.isDownloaded" class="progress-bar" [value]="progressPer" max="100"></progress>
            <progress *ngIf="selectedNotification.type == 'NEW_PRESENTATIONS_NOTIFICATION' && 
          (isUnzipping$ | async) && item.isDownloaded" class="progress-bar" [value]="progressPer" max="100"></progress>
          <img *ngIf="selectedNotification.type == 'NEW_PRESENTATIONS_NOTIFICATION' || selectedNotification.type == 'NEW_RESOURCES_NOTIFICATION'" class="thumbnail" io-thumbnail [src]="thumbURL(item)" />
          <ion-label>
            <ion-text class="primary-text">
              <h3>{{getPrimaryInfo(item)}}</h3>
            </ion-text>
            <p class="overflowHiddenAndEllipsis secondary-text">
              <ion-text>{{getSecInfo(item)}}</ion-text>
            </p>
          </ion-label>
          <img *ngIf="selectedNotification.type == 'NEW_PRESENTATIONS_NOTIFICATION' && this.deviceService.isMobileDevice" slot="end" [src]="item.isDownloaded ? 'assets/imgs/contact_conn.svg':'assets/imgs/pres_download_fill.svg'" [ngClass]="item.isDownloaded ? 'green-dot' : 'img-download'" (click)="downloadPresentation(item)" />
          <img *ngIf="selectedNotification.type == 'NEW_RESOURCES_NOTIFICATION' && this.deviceService.isMobileDevice" slot="end" [src]="item.isDownloaded ? 'assets/imgs/contact_conn.svg':'assets/imgs/pres_download_fill.svg'" [ngClass]="item.isDownloaded ? 'green-dot' : 'img-download'" (click)="downloadResource(item)" />
          <ion-icon  *ngIf="integratedNotificationType == 'changeRequest' && (item.statuscode &&item.statuscode != 548910007) || integratedNotificationType == 'newContactNewAccount'" class="insights arrow-icon"  name="chevron-forward-outline" slot="end" ></ion-icon>
        </ion-item>
        <div *ngIf="integratedNotificationType == 'changeRequest' && getCustomerWithCRID(item)">
        <ind-section-header  [viewData]='accountContactHeader'></ind-section-header>
        <ion-item (click)="onItemClick(getCustomerWithCRID(item))">
          <p *ngIf="integratedNotificationType == 'changeRequest' && getDataAttr(item) != ''" [attr.data-letters]="getDataAttr(item)"></p>
          <ion-label>
            <ion-text class="primary-text">
              <h3>{{getPrimaryInfo(accountContactDCR)}}</h3>
            </ion-text>
            <p class="overflowHiddenAndEllipsis secondary-text">
              <ion-text>{{getSecInfo(accountContactDCR)}}</ion-text>
            </p>
          </ion-label>
          <ion-icon  class="insights arrow-icon"  name="chevron-forward-outline" slot="end" ></ion-icon>
        </ion-item>
        </div>

        <div *ngIf="integratedNotificationType == 'manageList'" (click)="onItemClick(item)">
        <ion-item class="manage-list-item" lines="none">
          <ion-icon name="indegene-manage-list" class="list-icon" slot="start"></ion-icon>
          <ion-label class="manage-list-header"> {{item.listPeriod.indskr_name}}</ion-label>
          <ion-button slot="end" fill="clear">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </ion-button>
        </ion-item>
      </div>
      </ion-item-group>
    </ion-list>
  </ng-container>
</ion-content>

<footer-toolbar [footerView]="'detailView'" [selectedView]="'none'"></footer-toolbar>
