import { ChangeDetectorRef, Component, Input} from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { SampleDataService } from '../../data-services/sample/sample.data.service';
import { ActivityService } from '../../services/activity/activity.service';
import { SampleActivity, CreateSampleDropRequestBody } from '../../classes/activity/sample.activity.class';
import { DeviceService } from '../../services/device/device.service';
import { SampleService, SamplingDetailsViewMode } from '../../services/sample/sample.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { ActivityColorCode } from '../../classes/activity/activity.class';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification/notification.service';
import { AlertService } from '../../services/alert/alert.service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SignaturePad } from '../shared/signaturepad/angular2-signaturepad.component';

/**
 * Generated class for the CaptureSignatureModalComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'capture-signature-popover',
  templateUrl: 'capture-signature-popover.html',
  styleUrls:['capture-signature-popover.scss']
})
export class CaptureSignaturePopoverComponent {
  @ViewChild('sigPad', {static:true}) signaturePad: SignaturePad;
  @Input() confirmMessage;
  public submitEnable = false;
  public signaturePadOptions = {
    minWidth: 0.5,
    canvasWidth: 300,
    canvasHeight: 150
  };
  constructor(
    public loadingCtrl: LoadingController,
    public sampleDataService: SampleDataService,
    public activityService: ActivityService,
    public notificationService: NotificationService,
    public readonly repService: RepServices,
    public sampleService: SampleService,
    private readonly alertService: AlertService,
    private readonly authService: AuthenticationService,
    public trackingService: TrackService,
    public readonly translate: TranslateService,
    private readonly _cd: ChangeDetectorRef,
    private readonly contactService: ContactOfflineService,
    public popoverCtrl: PopoverController,
    public device: DeviceService,
  ) {
    if(!this.device.isMobileDevicePortrait) {
      this.signaturePadOptions.canvasWidth = this._calculateWidth();
    }
  }

  private _calculateWidth(): number {
    let targetWidth: number = window.innerWidth * 0.8;
    return targetWidth;
  }

  drawStart(){
    console.log('detected signature pad draw start');
  }
  drawComplete(){
    console.log('draw end', this.signaturePad, this.signaturePad.toDataURL());
    this.submitEnable = true;
    this._cd.markForCheck();
    this._cd.detectChanges();
  }
  clearDraw(){
    this.signaturePad.clear();
    this.submitEnable = false;
    this._cd.markForCheck();
    this._cd.detectChanges();
  }
  closePopover(){
    this.popoverCtrl.dismiss({completedFlow: false});
  }

  openConfirmationPopup() {
    const confirmationMessage = this.confirmMessage ? this.confirmMessage : this.translate.instant('ONCE_ORDER_SUB_NO_MODIFIED');
    this.alertService.showAlert({
      title: this.translate.instant('SUBMIT_THE_ORDER'),
      message: confirmationMessage}, this.translate.instant('SUBMIT')
    ).then (res => {
      if(res.role === 'ok') {
        this.submitDraw();
      }
    });
  }

  async submitDraw () {
    await this.trackingService.tracking('AllocationOrderSignatureSubmit',TrackingEventNames.ALLOCATIONS, null, true);
    const signatureData = this.signaturePad.toDataURL();
    await this.popoverCtrl.dismiss({completedFlow: true, signatureData:signatureData});
  }
}
