<ion-header class="sticky-header">
    <ion-toolbar *ngIf="popoverView === 'Subject'" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar *ngIf="popoverView === 'LiveTimeURL'">
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
    </ion-toolbar>
    <ion-toolbar *ngIf="popoverView === 'TeamsMeetingUrl'">
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'EmailSubject'" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onEmailDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onEmailDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'thumbsDownFeedback'">
        <ion-title text-center>{{'SELECT_REASON' | translate}}</ion-title>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'SubjectTOT'">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'CustomerInsight'">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'SubjectScheduler'">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>


    <ion-toolbar *ngIf="popoverView === 'CoachingFor'">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'CalendarMeeting'">
        <ion-buttons slot="start">
            <ion-button [disabled]="!isMeetingOwner" *ngIf="(activityForCalendarPopover && !activityForCalendarPopover.isCompleted && checkCustomScrapCondition)" fill="clear" color="danger" (click)="scrapMeeting()">{{'SCRAP' | translate}}</ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center" class="popoverTitle">
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="goToActivityDetails()">{{'OPEN' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'CalendarEmail'">
        <ion-buttons slot="start">
            <ion-button [disabled]="!isMeetingOwner  || !isEmailAddressEditingEnable" *ngIf="(activityForCalendarPopover && !activityForCalendarPopover.isCompleted)" fill="clear" color="danger" (click)="scrapMeeting()">{{'SCRAP' | translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center class="popoverTitle">
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="goToActivityDetails()">{{'OPEN' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'CalendarCase'">
        <ion-buttons slot="start">
            <ion-button [disabled]="isDisabled" fill="clear" color="danger" (click)="scrapCustomerInquiry()">
                {{'SCRAP' | translate}}
            </ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{'CUSTOMER_INQUIRY' | translate : {globalCustomerText : utilityService.globalCustomerText} }}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="goToActivityDetails()">{{'OPEN' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'CalendarTimeOff'">
        <ion-buttons slot="start">
            <ion-button *ngIf="(activityForCalendarPopover && activityForCalendarPopover.type != 'TimeOff' && $any(activityForCalendarPopover).statuscode === 100000000)" fill="clear" color="danger" (click)="scrapTimeOff()">{{'SCRAP' | translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="goToActivityDetails()">{{'OPEN' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'phoneActivity'">
        <ion-buttons slot="start">
            <ion-button [disabled]="!isMeetingOwner" *ngIf="(activityForCalendarPopover && !activityForCalendarPopover.isCompleted && checkCustomScrapCondition)" fill="clear" color="danger" (click)="scrapMeeting()">{{'SCRAP' | translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="goToActivityDetails()">{{'OPEN' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
    <!-- <ion-toolbar *ngIf="popoverView === 'SchedulerDuration'">
  </ion-toolbar> -->
    <ion-toolbar *ngIf="popoverView === 'editContentToken'">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="primary" (click)="onDismiss(false)">{{'CANCEL' |translate}}</ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{textInputType}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" color="primary" (click)="onDismiss(true)">{{'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="popoverView === 'more-options' ">
      <ion-title class="contact_tags" text-center>
        {{textInputType}}
      </ion-title>
    </ion-toolbar>

</ion-header>

<!-- Added ion-scroll and scrollableListView flag to avoid the issue where scrolling doesn't respond on first touch beacuse of ion-content refer to CWD-3733 -->
<!-- <ion-scroll scrollY="true" *ngIf="scrollableListView"> -->
<ion-list class="scrollableListView" *ngIf="scrollableListView && activityService.selectedActivity && popoverView === 'ReasonListing'">
    <ion-radio-group [(ngModel)]="timeOffService.reasonSelectedEvent">
        <ion-item *ngFor="let reason of timeOffService.timeOffReasonList | orderBy:'reason':true | filterReasons:timeoffReasonsConfig:$any(activityService.selectedActivity).durationType">
            <ion-label class="smallerStatusTextTimeOff">{{this.localisationService.getTranslatedString(reason.reason)}}</ion-label>
            <ion-radio [value]="reason.reason" (click)="reasonSelectedEvent(reason)"></ion-radio>
        </ion-item>
    </ion-radio-group>
</ion-list>
<!-- </ion-scroll> -->

<!-- Filter Popover for Active Consents  -->
<div class="consent-filter">
    <ion-list class="overrideIOSList all-item " *ngIf="popoverView === 'consentFilter'" (click)="onSettingAllConsentsFilter()">
        <ion-radio-group [(ngModel)]="consentService.showAllConsentsFilter">
            <ion-item>
                <ion-label color="dark">{{'ALL_ACTIVE_CONSENTS' | translate}}</ion-label>
                <ion-radio slot="end" (click)="onSettingAllConsentsFilter();" value="All"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
    <ion-list class="overrideIOSList" *ngIf="popoverView === 'consentFilter'">
        <ion-radio-group>
            <ng-container *ngIf="authenticationService.user.isProductConsent">
                <ion-list-header tappable (click)="expandList('consent:product');">
                    <ion-label> {{'PRODUCT' | translate}} </ion-label>
                    <ion-button>
                        <ion-icon [name]="consentService.isProductFilterExpanded ? 'chevron-up' : 'chevron-down'" slot="end"></ion-icon>
                    </ion-button>
                </ion-list-header>
                <div *ngIf="consentService.isProductFilterExpanded">
                    <ion-item *ngFor="let product of productService.brands | orderBy:'name':true" (click)="onSettingConsentProductFilter(product)">
                        <ion-label class="smallerStatusText">{{product.name}}</ion-label>
                        <ion-icon class="checkmarkIcon" src="assets/imgs/multi_select_filter_tick.svg" name="checkmark" slot="end" *ngIf="consentService.consentProductFilter$?.ID === product.ID"></ion-icon>
                    </ion-item>
                </div>
            </ng-container>
        </ion-radio-group>
    </ion-list>

    <ion-list class="overrideIOSList" *ngIf="popoverView === 'consentFilter'">
        <ion-radio-group>
            <ion-list-header tappable (click)="expandList('consent:channel');">
                <ion-label> {{'NEW_ACTIVITY_CHANNEL' | translate}} </ion-label>
                <ion-button>
                    <ion-icon [name]="consentService.isChannelFilterExpanded ? 'chevron-up' : 'chevron-down'" slot="end"></ion-icon>
                </ion-button>
            </ion-list-header>
            <div *ngIf="consentService.isChannelFilterExpanded && consentService.allConsentChannelSubject.value.length > 0">
                <ion-item *ngFor="let channel of consentService.allConsentChannelSubject.value | orderBy:'indskr_consentType':true" (click)="onSettingConsentChannelFilter(channel)">
                    <ion-label class="smallerStatusText">{{consentService.displayChannelLabels(channel.indskr_consentType)}}</ion-label>
                    <ion-icon class="checkmarkIcon" src="assets/imgs/multi_select_filter_tick.svg" name="checkmark" slot="end" *ngIf="consentService.consentChannelFilter$?.indskr_consenttypeid === channel.indskr_consenttypeid"></ion-icon>
                </ion-item>
            </div>
        </ion-radio-group>
    </ion-list>
</div>

<ion-content [ngClass]="{'callPlanFilterScroll': (popoverView === 'CallPlanFilter')}" *ngIf="!scrollableListView">
    <div class="loader-container" *ngIf="showLoader">
        <ion-spinner name="bubbles"></ion-spinner>
    </div>
    <ion-list *ngIf="popoverView === 'Subject'">
        <ion-item no-padding>
            <textarea spellcheck="false" [(ngModel)]="textInput" maxlength="200"></textarea>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="popoverView === 'LiveTimeURL'">
        <ion-item no-padding>
            <div>{{textInput}}</div>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="popoverView === 'TeamsMeetingUrl'">
        <ion-item no-padding>
            <div>{{textInput}}</div>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="popoverView === 'EmailSubject'">
        <ion-item no-padding>
            <textarea spellcheck="false" [(ngModel)]="textInput" maxlength="200"></textarea>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="popoverView === 'thumbsDownFeedback'">
        <ion-item no-padding no-lines class="thumbsDownFeedback" *ngFor="let option of thumbsDownReasons" (click)="selectThumbsDownReason(option)">
            <!-- <ion-icon color="primary" [name]="selectedthumbsDownReason.value == option.value ? 'checkmark-circle' : 'radio-button-off'"></ion-icon> -->
            <ion-checkbox [checked]="selectedthumbsDownReason.value == option.value" slot="start"></ion-checkbox>
            <ion-label>{{option.label}}</ion-label>
        </ion-item>
        <ion-item no-lines no-padding *ngIf="selectedthumbsDownReason.value == 'OTHER'" class="other-reason">
            <ion-input placeholder="Enter Reason" spellcheck="false" [(ngModel)]="textInput" maxlength="200"></ion-input>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="popoverView === 'CustomerInsight'">
        <ion-item no-padding>
            <textarea spellcheck="false" [(ngModel)]="textInput" maxlength="50"></textarea>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="popoverView === 'SubjectTOT'">
        <ion-item no-padding>
            <textarea spellcheck="false" [(ngModel)]="textInput" maxlength="200"></textarea>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="popoverView === 'SubjectScheduler'">
        <ion-item no-padding>
            <textarea spellcheck="false" [(ngModel)]="textInput" maxlength="200"></textarea>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="popoverView === 'CoachingFor'">
        <ion-item no-padding>
            <!--<ion-select [(ngModel)]="coachingReportService.selectedCoachingFor" interface="popover">
        <ion-select-option [value]="user" *ngFor="let user of timeOffService.users">
          {{user.fullName}}
        </ion-select-option>
      </ion-select>-->
        </ion-item>
    </ion-list>

    <ion-list *ngIf="popoverView === 'Sentiment'" class="sentimentsPopover" mode='md'>
        <ion-item text-center (click)="selectSentiment('positive')">
            <img src="assets/imgs/sentiment-up.png" width="20" />
        </ion-item>
        <ion-item text-center (click)="selectSentiment('neutral')">
            <img src="assets/imgs/sentiment-nutral.png" width="20" />
        </ion-item>
        <ion-item text-center (click)="selectSentiment('negative')">
            <img src="assets/imgs/sentiment-down.png" width="20" />
        </ion-item>
    </ion-list>

    <!-- Contact-Profile-CallPlan-filter -->
    <ion-list *ngIf="popoverView === 'CallPlansActivityFilter'">
        <ion-radio-group [(ngModel)]="callPlanService.meetingOrEmailFilter">
            <ion-item>
                <ion-label>{{filterLabelEngagements}}</ion-label>
                <ion-radio slot="end" (click)="callPlanService.selectEmailOrMeetingFilter('Engagements');closePopup();" value="Engagements"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>{{'F2F_MEETINGS' | translate}}</ion-label>
              <ion-radio slot="end" (click)="callPlanService.selectEmailOrMeetingFilter('F2fMeetings');closePopup();" value="F2fMeetings"></ion-radio>
            </ion-item>
            <ion-item>
                <ion-label>{{filterLabelMessages}}</ion-label>
                <ion-radio slot="end" (click)="callPlanService.selectEmailOrMeetingFilter('Messages');closePopup();" value="Messages"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>{{'PHONE_CALLS' | translate}}</ion-label>
              <ion-radio slot="end" (click)="callPlanService.selectEmailOrMeetingFilter('PhoneCalls');closePopup();" value="PhoneCalls"></ion-radio>
            </ion-item>  
            <ion-item>
              <ion-label>{{'REMOTE_MEETINGS' | translate}}</ion-label>
              <ion-radio slot="end" (click)="callPlanService.selectEmailOrMeetingFilter('RemoteMeetings');closePopup();" value="RemoteMeetings"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <ion-list class="overrideIOSList all-item" *ngIf="popoverView === 'CallPlanFilter'" (click)="callPlanService.resetAllRepCallPlanFilters()">
        <ion-radio-group [(ngModel)]="callPlanService.repCallPlanDetailFilterValue">
            <ion-item>
                <ion-label color="dark">{{ 'ALL' | translate}}</ion-label>
                <ion-radio slot="start" (click)="callPlanService.resetAllRepCallPlanFilters();closePopup();" value="All"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <!-- <ion-list class="overrideIOSList" radio-group *ngIf="popoverView === 'CallPlanFilter'" [(ngModel)]="callPlanService.repCallPlanDetailCompletionFilter">
    <ion-list-header>
      Completion
      <ion-icon *ngIf="!callPlanFlags.isCompletion" class="light-color" tappable name="md-arrow-dropdown" slot="end" (click)="expandList('callPlan:completion');"></ion-icon>
      <ion-icon *ngIf="callPlanFlags.isCompletion" class="light-color" tappable name="md-arrow-dropup" slot="end" (click)="expandList('callPlan:completion');"></ion-icon>
    </ion-list-header>
      <ion-item *ngIf="callPlanFlags.isCompletion">
        <ion-label class="smallerStatusText">Complete</ion-label>
        <ion-checkbox [(ngModel)]="callPlanService.repCallPlanDetailCompletionFilter" (click)="callPlanService.repCallPlanDetailFilterValue = '';" ></ion-checkbox>
      </ion-item>
      <ion-item *ngIf="callPlanFlags.isCompletion">
        <ion-label class="smallerStatusText">Not Complete</ion-label>
        <ion-checkbox [(ngModel)]="callPlanService.repCallPlanDetailNonCompletionFilter" (click)="callPlanService.repCallPlanDetailFilterValue = '';" ></ion-checkbox>
      </ion-item>
  </ion-list> -->

    <!-- Status filter -->
    <ion-list class="overrideIOSList" *ngIf="popoverView === 'CallPlanFilter'">
        <ion-radio-group>
            <!-- <ion-list-header>
      Status
      <ion-icon *ngIf="!callPlanFlags.isStatus" class="light-color" tappable name="md-arrow-dropdown" slot="end" (click)="expandList('callPlan:status');"></ion-icon>
      <ion-icon *ngIf="callPlanFlags.isStatus" class="light-color" tappable name="md-arrow-dropup" slot="end" (click)="expandList('callPlan:status');"></ion-icon>
    </ion-list-header> -->
            <ion-item>
                <ion-label class="smallerStatusText">{{'APPROVED' | translate}}</ion-label>
                <ion-checkbox [(ngModel)]="callPlanService.repCallPlanDetailStatusFilterApproved" (click)="callPlanService.repCallPlanDetailFilterValue = '';" [checked]="callPlanService.repCallPlanDetailStatusFilterApproved"></ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-label class="smallerStatusText">{{'POP_IN_REVIEV' | translate}}</ion-label>
                <ion-checkbox [(ngModel)]="callPlanService.repCallPlanDetailStatusFilterForReview" (click)="callPlanService.repCallPlanDetailFilterValue = '';" [checked]="callPlanService.repCallPlanDetailStatusFilterForReview"></ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-label class="smallerStatusText">{{'OPEN' | translate}}</ion-label>
                <ion-checkbox [(ngModel)]="callPlanService.repCallPlanDetailStatusFilterOpen" (click)="callPlanService.repCallPlanDetailFilterValue = '';" [checked]="callPlanService.repCallPlanDetailStatusFilterOpen"></ion-checkbox>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <!-- Position filter for non managers -->
    <ion-list class="overrideIOSList" *ngIf="popoverView === 'CallPlanFilter' && !authenticationService._user.isManager && callPlanService.callPlanMode == 'team'">
        <ion-radio-group [(ngModel)]="callPlanService.repCallPlanDetailPositionFilter">
            <ion-list-header>
                {{'POSITION' | translate}}
                <ion-icon *ngIf="!callPlanFlags.isPosition" class="light-color" tappable name="md-arrow-dropdown" slot="end"></ion-icon>
                <ion-icon *ngIf="callPlanFlags.isPosition" class="light-color" tappable name="md-arrow-dropup" slot="end"></ion-icon>
            </ion-list-header>

            <ion-item *ngIf="callPlanFlags.isPosition">
                <ion-label class="smallerPositionText">{{authenticationService?._user.positionName}}</ion-label>
                <ion-radio slot="start"></ion-radio>
            </ion-item>

        </ion-radio-group>
    </ion-list>

    <!-- Position filter for managers -->
    <ion-list class="overrideIOSList" *ngIf="popoverView === 'CallPlanFilter' && authenticationService._user.isManager && callPlanService.callPlanMode == 'team'" [(ngModel)]="callPlanService.repCallPlanDetailPositionFilter">
        <ion-radio-group>
            <ion-list-header (click)="expandList('callPlan:position');">
                {{'POSITIONS' | translate}}
                <ion-icon *ngIf="!callPlanFlags.isPosition" class="light-color" tappable name="md-arrow-dropdown" slot="end"></ion-icon>
                <ion-icon *ngIf="callPlanFlags.isPosition" class="light-color" tappable name="md-arrow-dropup" slot="end"></ion-icon>
            </ion-list-header>

            <div *ngIf="callPlanFlags.isPosition">
                <ion-item *ngFor="let position of authenticationService.childPositions">
                    <ion-label class="smallerPositionText">{{position.childPosition}}</ion-label>
                    <ion-checkbox (click)="callPlanService.repCallPlanDetailFilterValue = '';" [(ngModel)]="position.selectedRepCallPlanFilter" (ionChange)="callPlanService.setRepCallPlanDetailPositionFilter()"></ion-checkbox>
                </ion-item>
            </div>


        </ion-radio-group>
    </ion-list>

    <!-- Address for new sample order -->
    <ion-list class="overrideIOSList" *ngIf="popoverView === 'SampleOrderAddresses'">
        <ion-radio-group>
            <ion-item class='primary-text'>
                <ion-label>{{'ELIGIBLE_ADDRESS' | translate}}</ion-label>
            </ion-item>
            <div>
                <ion-item *ngFor="let address of samplingAddressList | orderBy:'compositeAdd':true">
                    <ion-label (click)='setAddressForSampleOrder(address)' class="smallerPositionText">{{address.compositeAdd}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedAddress(address)" class="arrow-icon" name="checkmark-outline"></ion-icon>
                </ion-item>
            </div>
        </ion-radio-group>
    </ion-list>

    <ion-list no-margin no-padding *ngIf="popoverView === 'TimeSettings'">
        <ion-item *ngFor="let tF of timeFormats | orderBy:'format':true" (click)="setTimeFormat(tF)">
            {{'TIME_FORMATS_VALUE' | translate : {formatValue : tF.format | translate} }}
            <ion-icon slot="end" *ngIf="selectedTimeFormat.format === tF.format" class="arrow-icon" name="checkmark-outline">
            </ion-icon>
        </ion-item>
    </ion-list>

    <ion-list no-margin no-padding *ngIf="popoverView === 'SymbolPositions'">
        <ion-item *ngFor="let sPos of symbolPos | orderBy:'value':true" (click)="setSymbolPos(sPos)">
            {{sPos.value}}
            <ion-icon slot="end" *ngIf="selectedSymbolPos.value === sPos.value" class="arrow-icon" name="checkmark-outline">
            </ion-icon>
        </ion-item>
    </ion-list>

    <ion-list no-margin no-padding *ngIf="popoverView === 'DecimalPositions'">
        <ion-item *ngFor="let dPos of decimalPos | orderBy:'value':true" (click)="setDecimalPos(dPos)">
            {{dPos.value}}
            <ion-icon slot="end" *ngIf="selectedDecimalPos.value === dPos.value" class="arrow-icon" name="checkmark-outline">
            </ion-icon>
        </ion-item>
    </ion-list>

    <ion-list class="overrideIOSList" *ngIf="popoverView === 'SelectListPopup'">
        <ion-radio-group>
            <div class="select-list-popup-list-wrapper">
                <ion-item class="select-list-popup-list" *ngFor="let item of selectListData | orderBy:selectDataOrderPipeOption1:selectDataOrderPipeOption2">
                    <ion-label (click)='setSelectedItemForSelectList($event, item)' class="selectListPopupText">{{item.title}}</ion-label>
                    <ion-icon (click)='setSelectedItemForSelectList($event, item)' *ngIf='!!item.isSelected' name="checkmark" color="primary" slot="end"></ion-icon>
                </ion-item>
                <ion-item *ngIf="selectListData.length == 0">
                    <ion-label class="smallerPositionText">{{'NO_DATA_TO_DISPLAY' | translate}}</ion-label>
                </ion-item>
            </div>
        </ion-radio-group>
    </ion-list>

    <ion-list class="overrideIOSList" *ngIf="popoverView === 'CalendarInviteEmailTooltip'">
        <ion-item>
            <ion-label class="smallerPositionText">
                <div class="email-address-contact">
                    <span class="contact-name">
              {{tooltipData.name}}
            </span>
                    <div class="email-address">{{tooltipData.emailAddress}}</div>
                </div>
            </ion-label>
        </ion-item>
    </ion-list>
    <ion-list class="CustomerAssessTooltipRespones" *ngIf="popoverView === 'CustomerAssessTooltip'">
      <ion-item>
        <ion-label class="smallerPositionText">
          <div class="email-address-contact">
            <span class="modified_by">
              {{tooltipData.modifiedOn}}
            </span>
            <div class="modified_by">{{tooltipData.modifiedBy}}</div>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list class="overrideIOSList" *ngIf="popoverView === 'EmailAddresses'">
        <div [ngSwitch]="channelActivityType">
            <ng-container *ngSwitchCase="ChannelActivityType.FACEBOOK">
                <ion-item>
                    <ion-label class="smallerPositionText">
                        <div class="email-address-contact">
                            <span class="contact-name">
                  {{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}
                </span>
                            <div>{{'FACEBOOK' | translate}}</div>
                        </div>
                    </ion-label>
                </ion-item>
            </ng-container>
            <ng-container *ngSwitchCase="ChannelActivityType.SMS">
                <ion-item>
                    <ion-label class="smallerPositionText">
                        <div class="email-address-contact">
                            <span class="contact-name">
                  {{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}
                </span>
                            <div>{{emailActivityParty.contact_mobilephone}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode === 8 && emailActivityParty.email_failedOn">{{'FAILED' | translate}} - {{emailService.getFormattedTime(emailActivityParty.email_failedOn)}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode != 8 && emailActivityParty.email_senton && !emailActivityParty.readOn">{{'SENT' | translate}} - {{emailService.getFormattedTime(emailActivityParty.email_senton)}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode != 8 && emailActivityParty.readOn">{{'DELIVERED' | translate}} - {{emailService.getFormattedTime(emailActivityParty.readOn)}}</div>
                            <div class="errortext" *ngIf="!!emailActivityParty.email_channelprovidererror">{{emailActivityParty.email_channelprovidererror}}</div>
                        </div>
                    </ion-label>
                </ion-item>
            </ng-container>
            <ng-container *ngSwitchCase="ChannelActivityType.WHATSAPP">
                <ion-item>
                    <ion-label class="smallerPositionText">
                        <div class="email-address-contact">
                            <span class="contact-name">
                  {{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}
                </span>
                            <div>{{emailActivityParty.contact_mobilephone}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode === 8 && emailActivityParty.email_failedOn">{{'FAILED' | translate}} - {{emailService.getFormattedTime(emailActivityParty.email_failedOn)}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode != 8 && emailActivityParty.email_senton && !emailActivityParty.readOn">{{'SENT' | translate}} - {{emailService.getFormattedTime(emailActivityParty.email_senton)}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode != 8 && emailActivityParty.readOn">{{'DELIVERED' | translate}} - {{emailService.getFormattedTime(emailActivityParty.readOn)}}</div>
                            <div class="errortext" *ngIf="!!emailActivityParty.email_channelprovidererror">{{emailActivityParty.email_channelprovidererror}}</div>
                        </div>
                    </ion-label>
                </ion-item>
            </ng-container>
            <ng-container *ngSwitchCase="ChannelActivityType.WECOM">
              <ion-item *ngFor="let emailAddress of emailActivityParty?.emailAddresses">
                <ion-label class="smallerPositionText">
                  <div class="email-address-contact">
                    <span class="contact-name">
                      {{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}
                    </span>
                    <div *ngIf="emailActivityParty.email_statuscode === 8 && emailActivityParty.email_failedOn">{{'FAILED' | translate}} -
                      {{emailService.getFormattedTime(emailAddress.email_failedOn)}}</div>
                    <div *ngIf="emailActivityParty.email_statuscode != 8 && emailAddress.email_senton && !emailService.selectedActivity.lastopenedtime">
                      {{'DELIVERED'
                      | translate}} - {{emailService.getFormattedTime(emailAddress.email_senton)}}</div>
                    <div *ngIf="emailActivityParty.email_statuscode != 8 && emailService.selectedActivity.lastopenedtime">{{'READ' | translate}} -
                      {{emailService.getFormattedTime(emailService.selectedActivity.lastopenedtime)}}</div>
                  </div>
                </ion-label>
              </ion-item>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ion-item *ngFor="let emailAddress of emailActivityParty?.emailAddresses; let i = index">
                    <ion-label class="smallerPositionText">
                        <div class="email-address-contact">
                            <span class="contact-name">
                {{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}
              </span>
                            <div class="email-address">{{emailAddress.emailAddress}}</div>
                            <div *ngIf="emailAddress.email_statuscode === 8 && emailAddress.email_failedOn">{{'FAILED' | translate}} - {{emailService.getFormattedTime(emailAddress.email_failedOn)}}</div>
                            <div *ngIf="emailAddress.email_statuscode != 8 && emailAddress.email_senton && !emailAddress.readOn">{{'DELIVERED' | translate}} - {{emailService.getFormattedTime(emailAddress.email_senton)}}</div>
                            <div *ngIf="emailAddress.email_statuscode != 8 && emailAddress.readOn">{{'READ' | translate}} - {{emailService.getFormattedTime(emailAddress.readOn)}}</div>
                        </div>
                    </ion-label>
                    <ion-icon class="closeEmailAddress" [ngClass]="{'disabled':isEmailAddressEditingEnable}" slot="end" *ngIf="emailActivityParty.emailAddresses.length > 1 && !isCompletedActivity" ios="ios-close-circle" (click)="removeEmailAddress(i)">
                    </ion-icon>
                </ion-item>
            </ng-container>
        </div>
    </ion-list>
    <!-- Non native channel: SMS and WhatsApp information -->
    <ion-list class="overrideIOSList" *ngIf="popoverView === 'nonNativeChannel'">
        <div [ngSwitch]="emailActivity.channelActivityType">
            <ng-container *ngSwitchCase="ChannelActivityType.SMS">
                <ion-item>
                    <ion-label class="smallerPositionText">
                        <div class="email-address-contact">
                            <span class="contact-name">{{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}</span>
                            <div>{{emailActivityParty.contact_mobilephone}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode === 548910000 && emailActivityParty.email_senton">{{'SHARED' | translate}} - {{emailService.getFormattedTime(emailActivityParty.email_senton)}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode === 548910000 && emailActivityParty.email_senton == null">{{'SHARED' | translate}} - {{emailService.getFormattedTime(emailActivity.scheduledStart.toString())}}</div>
                        </div>
                    </ion-label>
                </ion-item>
            </ng-container>
            <ng-container *ngSwitchCase="ChannelActivityType.WHATSAPP">
                <ion-item>
                    <ion-label class="smallerPositionText">
                        <div class="email-address-contact">
                            <span class="contact-name">{{emailActivityParty.contact_firstname}} {{emailActivityParty.contact_lastname}}</span>
                            <div>{{emailActivityParty.contact_mobilephone}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode === 548910000 && emailActivityParty.email_senton">{{'SHARED' | translate}} - {{emailService.getFormattedTime(emailActivityParty.email_senton)}}</div>
                            <div *ngIf="emailActivityParty.email_statuscode === 548910000 && emailActivityParty.email_senton == null">{{'SHARED' | translate}} - {{emailService.getFormattedTime(emailActivity.scheduledStart.toString())}}</div>
                        </div>
                    </ion-label>
                </ion-item>
            </ng-container>
        </div>
    </ion-list>

    <ion-list *ngIf="activityService.selectedActivity && (popoverView === 'CalendarMeeting' || popoverView === 'CalendarTimeOff' || popoverView === 'CalendarEmail' || popoverView === 'CalendarCase' || popoverView === 'phoneActivity')">
        <ion-item>
            <div class="left-float">
                <h5 class="dark-color" [ngClass]="{'popover-message':popoverView === 'CalendarEmail'}">{{getLocalisedAndFormattedTime(activityService.selectedActivity.scheduledStart)}}</h5>
                <p *ngIf="activityService.selectedActivity.type === 'Appointment'" class="light-color">{{activityService.selectedActivity.lengthOfActivity}}</p>
                <p *ngIf="activityService.selectedActivity.type === 'TimeOff' || activityService.selectedActivity.type === 'TimeOffRequest' || activityService.selectedActivity.type === 'PhoneCall'" class="light-color">{{timeOffService.getFormattedTimeInterval(activityService.selectedActivity)}}</p>
            </div>
            <ion-thumbnail class="left-float">
                <img [src]="this.activityService.getActivityIcon(activityService.selectedActivity)" width="20">
            </ion-thumbnail>
            <div text-left>
                <h5 class="dark-color"  [ngClass]="{'popover-phoneCall':popoverView === 'phoneActivity'}" text-nowrap> {{activityService.getActivitysubject(activityService.selectedActivity)}}</h5>

                <h6 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'Appointment'">
                    <ion-icon name="location-outline"></ion-icon>
                    {{ activityService.selectedActivity.location && activityService.selectedActivity.location == 'No Location' || !activityService.selectedActivity.location ? ('NO_LOCATION'| translate) : activityService.selectedActivity.location }}
                </h6>
                <h5 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'Sample'">
                    <ion-icon name="person"></ion-icon>
                    {{ ($any(activityService.selectedActivity).contactName ? $any(activityService.selectedActivity).contactName : 'NO_CONTACT') | translate}}
                </h5>
                <h5 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'TimeOff'">
                    <ion-icon color="dark" name="indegene-toReason"></ion-icon>
                    {{activityService.selectedActivity.reason}}
                </h5>
                <h5 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'Email'">
                    {{activityService.selectedActivity.info}}
                </h5>
                <h5 class="light-color accountName" *ngIf="activityService.selectedActivity.type == 'SurgeryOrder'">
                    {{getActivitySecondaryTextStringForSurgeryOrder($any(activityService.selectedActivity))}}
                </h5>
                <h5 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'TimeOffRequest'">
                    <ion-icon color="dark" name="indegene-toReason"></ion-icon>
                    {{activityService.selectedActivity.reason}}
                </h5>
                <h5 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'followuptask'">
                    <!-- <ion-icon color="dark" name="indegene-toReason"></ion-icon> -->
                    {{getActivitySecondaryTextStringForFollowup($any(activityService.selectedActivity))}}
                </h5>
                <h5 class="light-color" text-nowrap *ngIf="activityService.selectedActivity.type == 'CaseIntake'">

                    <span>{{ getCaseState()}}</span> &nbsp; &nbsp;
                    <span>{{$any(activityService.selectedActivity)._case_contact ? $any(activityService.selectedActivity)._case_contact.fullName : ($any(activityService.selectedActivity)._case_trans_customer_name && $any(activityService.selectedActivity)._case_trans_customer_name != 'Select '+utilityService.globalCustomerText) ? $any(activityService.selectedActivity)._case_trans_customer_name : "No "+utilityService.globalCustomerText }}</span>
                </h5>
            </div>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="popoverView === 'timeOffEvent'">
        <ion-radio-group [(ngModel)]="timeOffService.popupSelectedEvent">
            <ion-item>
                <ion-label class="smallerStatusTextTimeOff">{{'ALL_DAY' | translate}}</ion-label>
                <ion-radio value="All Day" (click)="onEventSelection('All Day')"></ion-radio>
            </ion-item>
            <ion-item>
                <ion-label class="smallerStatusTextTimeOff">{{'HALF_DAY' | translate}}</ion-label>
                <ion-radio value="Half Day" (click)="onEventSelection('Half Day')"></ion-radio>
            </ion-item>
            <ion-item>
                <ion-label class="smallerStatusTextTimeOff">{{'HOURLY' | translate}}</ion-label>
                <ion-radio value="Hourly" (click)="onEventSelection('Hourly')">
                </ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <ion-list *ngIf="popoverView === 'timePeriod'">
      <ion-radio-group [(ngModel)]="timeOffService.popupSelectedTimePeriodEvent">
          <ion-item>
              <ion-label class="smallerStatusTextTimeOff">{{'AM' | translate}}</ion-label>
              <ion-radio value="AM" (click)="onEventPeriodSelection('AM')"></ion-radio>
          </ion-item>
          <ion-item>
              <ion-label class="smallerStatusTextTimeOff">{{'PM' | translate}}</ion-label>
              <ion-radio value="PM" (click)="onEventPeriodSelection('PM')"></ion-radio>
          </ion-item>
      </ion-radio-group>
  </ion-list>

    <ion-list *ngIf="popoverView === 'SchedulerDuration'">
        <ion-radio-group [(ngModel)]="scheduledDuration" (ngModelChange)="onSchedulerDuration($event)">
            <ion-item *ngFor="let item of schedulerList">
                <ion-label class="schedulerListText">{{item.value}}</ion-label>
                <ion-radio slot="start" [value]="item.id"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <ion-list *ngIf="popoverView === 'SchedulerPrioritize'">
        <ion-radio-group [(ngModel)]="priorityID" (ngModelChange)="onSchedulerPrioritize($event)">
            <ion-item *ngFor="let item of schedulerList">
                <ion-label class="schedulerListText">{{item.value}}</ion-label>
                <ion-radio slot="end" [value]="item.id"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <ion-list *ngIf="popoverView === 'InsightsPlusSources'">
        <ion-radio-group>
            <ion-item *ngFor="let source of insightsPlusSources">
                <ion-label>{{source}}</ion-label>
            </ion-item>
        </ion-radio-group>
    </ion-list>



    <div class="tooltip-popover" *ngIf="popoverView === 'tooltip'">
        <ion-list>
            <ion-item *ngFor="let item of tooltipDataSource">
                <!-- <ion-input readonly [value] = "item" ></ion-input> -->
                <ion-label>{{item}}</ion-label>
            </ion-item>
        </ion-list>
    </div>

    <ion-list *ngIf="popoverView === 'editContentToken'">
        <ion-item no-padding>
            <textarea spellcheck="false" [(ngModel)]="textInput" maxlength="200"></textarea>
        </ion-item>
    </ion-list>


    <ion-list class="contact_tags-listing" *ngIf=" popoverView === 'more-options'">
      <ion-item class="contact_tags-listing" *ngFor="let moreOption of moreOptions">
        <ion-button slot="start" class="button-font-size" icon-only fill="clear" (click)="moreOptionSelection(moreOption)"
          color="primary">
          {{moreOption.text}}
        </ion-button>
      </ion-item>
    </ion-list>

  <ion-list  *ngIf="popoverView === 'contact_tags'">
    <ion-item  *ngFor="let contactTag of contactTags" >
      <ion-label (click)="contactTagSelection(contactTag)" > {{contactTag.indskr_name}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list  *ngIf="popoverView === 'user_tags'">
    <ion-item  *ngFor="let userTag of userTags" >
      <ion-label (click)="userTagSelection(userTag)" > {{userTag.indskr_name}}</ion-label>
    </ion-item>
  </ion-list>
  <ion-list  *ngIf="popoverView === 'presentation_tags'">
    <ion-item  *ngFor="let userTag of userTags" >
      <ion-label (click)="presTagSelection(userTag)" > {{userTag.indskr_name}}</ion-label>
    </ion-item>
  </ion-list>
  <ion-list  *ngIf="popoverView === 'resource_tag'">
    <ion-item  *ngFor="let userTag of userTags" >
      <ion-label (click)="resourceTagSelection(userTag)" > {{userTag.indskr_name}}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer *ngIf="popoverView === 'thumbsDownFeedback'">
    <ion-toolbar>
        <ion-buttons>
            <ion-button float-left fill="clear" (click)="onDismiss(false)" color="primary">{{'CANCEL' | translate}}</ion-button>
            <ion-button [disabled]="selectedthumbsDownReason.value == 'OTHER' && !textInput" fill="clear" float-right color="primary" (click)="onDismiss(true)">{{'SEND' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>

<ion-list *ngIf="popoverView === 'touchPoints'">
    <ion-radio-group [(ngModel)]="journeyTouchPoints">
        <ion-item *ngFor="let touchPoint of journeyTouchPoints | orderBy:'touchPoint.label':true">
            <ion-label class="smallerStatusTextTimeOff">{{touchPoint.label}}</ion-label>
            <ion-radio [value]="touchPoint.label" (click)="reasonSelectedEvent(touchPoint)"></ion-radio>
        </ion-item>
    </ion-radio-group>
</ion-list>
