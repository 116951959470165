<ion-grid class="content-page">
    <ion-row>
      <ion-col class="callplan-left-pane">
        <call-plan-list (onCallPlanSelect)="onCallPlanSelected($event)"></call-plan-list>
      </ion-col>
      <ion-col class="callplan-right-pane">
        <ion-nav #rightPaneNav></ion-nav>
      </ion-col>
    </ion-row>
  </ion-grid>
