<ion-header>
    <ind-page-title [viewData]='{id:"resource-interaction-details",title: pageTitle, controls: backButton}' (onControlClick)='goBack()'></ind-page-title>
</ion-header>
<ion-content>
    <div *ngIf="uiService.activeView === 'ResourceContactList'">
        <ind-section-header [viewData]='allCustomersHeaderModel'>
        </ind-section-header>
        <ion-item *ngFor="let emailParty of resourceInteractionDetails.interactionDetails">
            <div style="width: 90%;" (click)="openInteractionDetails(emailParty.indskr_contactid)">
                {{emailParty.contact_firstname}} {{emailParty.contact_lastname}}
            </div>
            <ion-icon class="rightArrow" name="chevron-forward-outline"></ion-icon>
        </ion-item>
        <ind-section-header [viewData]='attachmentsHeaderModel'></ind-section-header>
        <ion-thumbnail class="thumbnail">
            <img io-thumbnail src="{{thumbnailURL}}">
        </ion-thumbnail>
    </div>
    <div *ngIf="uiService.activeView === 'ResourceInteractionDetails'">
      <!-- for WeCom channel -->
      <ng-container *ngIf="emailService.selectedActivity.channelActivityType == 600000000">
        <div *ngFor="let attachment of interactedAttachments">
          <div *ngIf="attachment.messageInteractions?.length > 0; else displayMessage">
            <ng-container *ngFor="let interaction of attachment.messageInteractions">
              <ion-item class="resourceShareHistoryResult" lines="none">
                {{emailService.getFormattedTime(interaction.indskr_readtime)}}, {{interaction.indskr_readduration}} {{'MINUTES' | translate}}
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
          </div>
          <ng-template #displayMessage>
            <ion-item class="resourceShareHistoryNotAvailable" lines="none">
              {{'RESOURCE_SHARE_HISTORY_NOT_AVAILABLE' | translate}}
            </ion-item>
            <div class="separationLine"></div>
          </ng-template>
        </div>
      </ng-container>
      <!-- for non-WeCom Channel -->
      <ng-container *ngIf="emailService.selectedActivity.channelActivityType != 600000000">
        <div *ngFor="let emailAddressDetails of interactionTimeDetails">
            <ind-section-header [viewData]='{id:"resource-interaction-details-title", title: emailAddressDetails.emailAddress}'></ind-section-header>
            <!-- <ion-item-divider color="light">{{emailAddressDetails.emailAddress}}</ion-item-divider> -->
            <div *ngIf="emailAddressDetails.attachmentDetails?.length > 0; else displayMessage">
                <ng-container *ngFor="let attachmentDetail of emailAddressDetails.attachmentDetails">
                    <ion-item class="resourceShareHistoryResult" lines="none">
                        {{emailService.getFormattedTime(attachmentDetail.interactionTime)}}
                    </ion-item>
                    <div class="separationLine"></div>
                </ng-container>
            </div>
            <ng-template #displayMessage>
                <ion-item class="resourceShareHistoryNotAvailable" lines="none">
                    {{'RESOURCE_SHARE_HISTORY_NOT_AVAILABLE' | translate}}
                </ion-item>
                <div class="separationLine"></div>
            </ng-template>
        </div>
      </ng-container>
    </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->