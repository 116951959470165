<!-- <ion-item-divider color="light">
    {{ 'TIME_CAP' | translate}}
</ion-item-divider> -->
<ind-section-header [viewData]='{id:"new-category",title: translate.instant("TIME_CAP")}'></ind-section-header>

<!-- All/Half Day -->
<ion-item>
    <ion-label>
      <p class="form-field-label-text">
        <ion-text>{{ 'TIMEOFF_ALL_OR_HALF_DAY' | translate}}</ion-text>
      </p>
      <div class="form-field-value" item-content text-wrap (click)="openEventPopover($event)">
        {{timeOffService.popupSelectedEvent}}
      </div>
    </ion-label>
    <ion-icon slot="end" class="ion-float-right arrow-icon chevron-down-outline" name="chevron-down-outline" (click)="openEventPopover($event)"></ion-icon>
</ion-item>

<!-- All Day -->
<ng-container *ngIf="selectedDurationType == 'All Day'">
  <ion-row>
    <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
    <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
  </ion-row>
</ng-container>
<!-- Half Day -->
<ng-container *ngIf="selectedDurationType == 'Half Day'">
  <ion-row>
    <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
    <ion-col size="6"><ind-datetime-form *ngIf="timePeriodField" [viewData]="timePeriodField"></ind-datetime-form></ion-col>
  </ion-row>
</ng-container>
<!-- Hourly -->
<ng-container *ngIf="selectedDurationType == 'Hourly'">
  <ion-row>
    <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
    <ion-col size="6"><ind-datetime-form *ngIf="startTimeField" [viewData]="startTimeField"></ind-datetime-form></ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
    <ion-col size="6"><ind-datetime-form *ngIf="endTimeField" [viewData]="endTimeField"></ind-datetime-form></ion-col>
</ion-row>
</ng-container>
<activity-conflict-alert-msg *ngIf="displayConflictAlert"></activity-conflict-alert-msg>
<!-- Duration -->
<ind-datetime-form *ngIf="durationField" [viewData]="durationField"></ind-datetime-form>
