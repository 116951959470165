import { ChartFilterOption } from '../../../../interfaces/edge-analytics/report.interface';
import { CommonDimensions } from '../../../enums/edge-analytics/edge-analytics.enum';
import { MessageDimensions, MessageKPI } from '../../../enums/edge-analytics/message/message-report.enum';
import { IndReportTileDataModel } from '../../../models/IndReportTileDataModel';
import { SelectListDetail } from '../../../models/select-list-detail-model';

export const MessageReportTilePerRow = 3;
export const MessageReportYearMonthKeyProp = 'dm_sentdate';

export const MessageDataCubeDimensionHierarchies = {
  dimensionHierarchies: [
    {
      dimensionTable: { dimension: CommonDimensions.yearMonth, keyProps: [MessageReportYearMonthKeyProp] },
    },
    {
      dimensionTable: { dimension: MessageDimensions.contacts, keyProps: ['sk_contactid'] },
    },
    {
      dimensionTable: { dimension: MessageDimensions.channel, keyProps: ['sk_channelid'] },
    },
    {
      dimensionTable: { dimension: MessageDimensions.templateId, keyProps: ['templateid'] },
    },
    {
      dimensionTable: { dimension: CommonDimensions.users, keyProps: ['sk_systemuserid'] },
    }
  ]
}

export const MessageReportTiles: IndReportTileDataModel[] = [
  {
    id: MessageKPI.sentVolume,
    firstHeading: "TOTAL_SENT",
    dataValue: '',
    dataFieldName: "MESSAGES",
    order: 0
  },
  {
    id: MessageKPI.customerReach,
    firstHeading: "CUSTOMER_REACH",
    dataValue: '',
    dataFieldName: "CUSTOMERS",
    order: 1
  },
  {
    id: MessageKPI.openRate,
    firstHeading: "OPEN_RATE",
    dataValue: '',
    dataFieldName: "MESSAGES",
    order: 2
  },
  {
    id: MessageKPI.clickThroughRate,
    firstHeading: "CLICK_THROUGH_RATE",
    dataValue: '',
    dataFieldName: "MESSAGES",
    order: 3
  },
  {
    id: MessageKPI.templateUtilizationVolume,
    firstHeading: "TEMPLATES_UTILIZATION_VOLUME",
    dataValue: '',
    dataFieldName: "TEMPLATES",
    order: 4
  },
  {
    id: MessageKPI.templateUtilizationRate,
    firstHeading: "TEMPLATES_UTILIZATION_RATE",
    dataValue: '',
    dataFieldName: "TEMPLATES",
    order: 5
  }
]

export const MessageByChannelChartFilterOptions: ChartFilterOption[] = [
  {
    value: MessageKPI.sentVolume,
    isSelected: true,
    displayText: 'Total Sent',
    displayTextKey: "TOTAL_SENT",
    data: undefined
  },
  {
    value: MessageKPI.customerReach,
    isSelected: false,
    displayText: 'Customer Reach',
    displayTextKey: "CUSTOMER_REACH",
    data: undefined
  },
  {
    value: MessageKPI.openRate,
    isSelected: false,
    displayText: 'Open Rate %',
    displayTextKey: "OPEN_RATE_PCT",
    data: undefined
  },
  {
    value: MessageKPI.clickThroughRate,
    isSelected: false,
    displayText: 'Click-through Rate %',
    displayTextKey: "CLICK_THROUGH_RATE_PCT",
    data: undefined
  },
  {
    value: MessageKPI.templateUtilizationVolume,
    isSelected: false,
    displayText: 'Templates Utilization Volume',
    displayTextKey: "TEMPLATES_UTILIZATION_VOLUME",
    data: undefined
  },
  {
    value: MessageKPI.templateUtilizationRate,
    isSelected: false,
    displayText: 'Templates Utilization Rate %',
    displayTextKey: "TEMPLATES_UTILIZATION_RATE_PCT",
    data: undefined
  }
]
export const MessageByChannelChartFilterTemplate: SelectListDetail = {
  title: 'Measure',
  titleKey: 'MEASURE',
  dividerTitle: 'All Measures',
  dividerTitleKey: 'ALL_MEASURES',
  isMultipleSelectionEnabled: true,
  data: []
}

export const MessageTrendChartFilterOptions: ChartFilterOption[] = [

]
export const MessageTrendChartFilterTemplate: SelectListDetail = {
  title: 'Measure',
  titleKey: 'MEASURE',
  dividerTitle: 'All Measures',
  dividerTitleKey: 'ALL_MEASURES',
  isMultipleSelectionEnabled: true,
  data: []
}

export const MessageChartTitles = {
  byChannel: 'BY_CHANNEL',
  trend: 'TREND',
}
