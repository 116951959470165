<ion-header>
    <ind-page-title [viewData]="scientificInfoDetailsPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <!-- <ion-toolbar class="activity-detail-menu-bar" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
    <ion-buttons slot="start">
      <ion-button class="back-btn" (click)="closePage()">
        <ion-icon name="chevron-back-outline" large></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title text-center>{{uiService.scientificInfoView}}</ion-title>
  </ion-toolbar> -->
</ion-header>
<ion-content>

    <!--PUBLICATIONS GRID -->
    <div *ngIf="uiService.scientificInfoView == 'Publications'">
        <ind-section-header [viewData]='{id:"contact-publication-header", title: translate.instant("CONTACT_PUBLICATIONS_DETAILS_CAP")}'></ind-section-header>
        <!-- <ion-item-divider color="light">
        {{ 'CONTACT_PUBLICATIONS_DETAILS_CAP' | translate}}
    </ion-item-divider> -->
        <div *ngFor="let pub of scientificInfo.publications | orderBy: 'indskr_name': true;">
            <!-- <ion-item *ngFor="let pub of scientificInfo.publications | orderBy: 'indskr_name': true;"> -->
            <div class="accordion-header" [ngClass]="pub['isExpanded'] ? 'expanded':'collapsed'">
                <main-card [viewData]="getPublicationsMainCardDataModel(pub)"></main-card>
            </div>
            <!-- <ion-item>
        <div ion-row>
          <span col-11 text-wrap>{{pub.indskr_name}}</span>
          <ion-icon class="arrow-icons" text-right col-1 [name]="pub['isExpanded'] ? 'chevron-up-outline': 'chevron-down-outline'"
                    (click)="expandOrCollapse(pub)"></ion-icon>
        </div>
      </ion-item> -->
            <expandable #expWrapper [publications]="pub" [viewType]="'medPublication'" [expanded]="pub['isExpanded']"></expandable>
        </div>
    </div>
    <!--RESEARCH GRID -->
    <div *ngIf="uiService.scientificInfoView == 'Research'">
        <ind-section-header [viewData]='{id:"contact-research-header", title: translate.instant("CONTACT_RESEARCH_DETAILS_CAP")}'></ind-section-header>
        <!-- <ion-item-divider color="light">
        {{ 'CONTACT_RESEARCH_DETAILS_CAP' | translate}}
    </ion-item-divider> -->
        <div *ngFor="let res of scientificInfo.researches | orderBy: 'indskr_name': true;">
            <div class="accordion-header" [ngClass]="res['isExpanded'] ? 'expanded':'collapsed'">
                <main-card [viewData]="getResearchMainCardDataModel(res)"></main-card>
            </div>
            <expandable #expWrapper [researches]="res" [viewType]="'medResearch'" [expanded]="res['isExpanded']"></expandable>
        </div>
        <!-- <ion-item *ngFor="let res of scientificInfo.researches | orderBy: 'indskr_name': true;">
      <div ion-row>
        <span col-11 text-wrap>{{res.indskr_name}}</span>
        <ion-icon class="arrow-icons" text-right col-1 [name]="res['isExpanded'] ? 'chevron-up-outline': 'chevron-down-outline'"
                  (click)="expandOrCollapse(res)"></ion-icon>
      </div>
      <expandable #expWrapper [researches]="res" [viewType]="'medResearch'" [expanded]="res['isExpanded']"></expandable>
    </ion-item> -->
    </div>
    <!--SPEAKER ENGAGEMENT GRID -->
    <div *ngIf="uiService.scientificInfoView == 'Speaker Engagements'">
        <ind-section-header [viewData]='{id:"contact-speaker-header", title: translate.instant("CONTACT_SPEAKER_ENGAGEMENT_ DETAILS_CAP")}'></ind-section-header>
        <!-- <ion-item-divider color="light">
        {{ 'CONTACT_SPEAKER_ENGAGEMENT_ DETAILS_CAP' | translate}}
    </ion-item-divider> -->
        <div *ngFor="let sE of scientificInfo.speakerEngagements | orderBy: 'indskr_speakerengagementname': true;">
            <!--<span col-4 text-wrap>Name</span>-->
            <ion-item class="accordion-header" [ngClass]="sE['isExpanded'] ? 'expanded':'collapsed'">
                <ion-text [ngClass]="sE['isExpanded'] ? 'accordion-primary-text' : 'primary-text'">
                    <h3>{{sE.indskr_eventname}}</h3>
                </ion-text>
                <ion-icon (click)="expandOrCollapse(sE)" [name]="sE['isExpanded'] ? 'accordion-minus' : 'accordion-add'" slot="end" class='accordion-icon'></ion-icon>
            </ion-item>
            <expandable #expWrapper [engagements]="sE" [viewType]="'mktEngagement'" [expanded]="sE['isExpanded']"></expandable>
        </div>
    </div>

    <!--EVENT HISTORY GRID -->
    <div *ngIf="uiService.scientificInfoView == 'Event Attendance'">
        <ind-section-header [viewData]='{id:"event-history-header", title: translate.instant("CONTACT_EVENT_ATTENDANCE_DETAIL_CAP")}'></ind-section-header>
        <!-- <ion-item-divider color="light">
      {{ 'CONTACT_EVENT_ATTENDANCE_DETAIL_CAP' | translate}}
    </ion-item-divider> -->
        <ion-list class="eventAttendanceDetail">
            <ion-item-group *ngFor="let eH of scientificInfo.eventHistory | orderBy: 'indskr_eventStartDate': false;">
                <ion-item>
                    <ion-label>
                        <h3 class="wrap-long-text primary-text">{{eH.indskr_eventname}}</h3>
                        <div ion-row>
                            <h4 class="row">
                                <ion-text class="overflowEllipsis ellipsis noellipsis">
                                    {{'ON' | translate}} {{eH.indskr_eventStartDate | customDate:'DATE_TIME'}}
                                </ion-text>
                                <!-- <ion-thumbnail>
                  <img src="assets/imgs/tot_unapproved_status.svg"/>
                </ion-thumbnail> -->
                                <ion-icon class="iconTimeOutline" name="time-outline"></ion-icon>
                                <ion-text class="overflowEllipsis ellipsis noellipsis">
                                    {{'CHECK_IN' | translate}} {{eH.indskr_checkInTime | customDate: 'TIME' }}
                                </ion-text>
                            </h4>
                        </div>
                    </ion-label>
                </ion-item>

            </ion-item-group>
        </ion-list>
    </div>

    <!--SESSION HISTORY GRID -->
    <!--<div *ngIf="uiService.scientificInfoView == 'Session History'">
    <ion-item-divider color="light">
      SESSION HISTORY DETAILS
    </ion-item-divider>
    <ion-item *ngFor="let sH of scientificInfo.sessionHistory | orderBy: 'indskr_sessionname': true;">
      <div ion-row>
        &lt;!&ndash;<span col-4 text-wrap>Name</span>&ndash;&gt;
        <span col-11 text-wrap>{{sH.indskr_sessionname}}</span>
        <ion-icon text-right col-1 [name]="sH['isExpanded'] ? 'chevron-up-outline': 'chevron-down-outline'" (click)="expandOrCollapse(sH)"></ion-icon>
      </div>
      <expandable #expWrapper [sessions]="sH" [viewType]="'mktSessions'" [expanded]="sH['isExpanded']"></expandable>
    </ion-item>
  </div>-->
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"></footer-toolbar>
<!-- </ion-footer> -->
