
import {takeUntil, distinctUntilChanged, debounceTime, skip} from 'rxjs/operators';
import { Component, OnChanges, OnDestroy, Input, Output, EventEmitter, NgZone, ElementRef } from '@angular/core';
import { SchedulerService, SchedulingPop } from '../../../services/scheduler/scheduler.service';
import { Subscription ,  Subject } from 'rxjs';
import { Scheduler } from '../../../classes/scheduler/scheduler.class';
import { CustomerSegment } from '../../../classes/customer-segment/customer-segment.class';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { CallPlanDetails } from "../../call-plans/call-plan-details/call-plan-details";
import { DeviceService } from "../../../services/device/device.service";
import { PopoverController } from '@ionic/angular';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { EventsService } from '../../../services/events/events.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { REP_STATUS } from '../../../models/rep-status-model';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { IndHeaderLeftDataModel } from '../../../models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { CallPlanFilterPipe } from '@omni/pipes/call-plan-filter.pipe';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { UIService } from '@omni/services/ui/ui.service';

@Component({
    selector: 'scheduler-popover',
    templateUrl: 'scheduler-popover.html',
  styleUrls:['scheduler-popover.scss'],
  providers: [CallPlanFilterPipe]
})
export class SchedulerPopoverComponent implements OnDestroy {

    //To compare the popup type
    public popupType = SchedulingPop;

    //Describe which type of popup has to be opened
    type: string = '';
    //Display value for HTML section
    selectionHeader: string = ""
    //Stores the list of all options user can select basis of the type
    list: any[] = [];

    //Store the result for single select mode
    selectedResult: any = [];

    //Toggle between full page selection or small popup
    isMultiSelect: boolean = false;

    //Store the result for multi select mode
    selectedOptions: any[] = [];

    //Current scheduler pattern selected by user for initiating the pre isSelected key
    currentSchedule: Scheduler;

    //Data subscriptions for initializing, needs to be unsubscribed on destroy
    ISelectedScheduleSubcription: Subscription;
    ItypeSubscription: Subscription;
    IschedulerListSubscription: Subscription;

    isCallPlanSelected: boolean = false;

    public searchText: string = "";
    searchTextChanged: Subject<string> = new Subject<string>();
    isSearching: boolean = false;

    myFormattedCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };

    //Not in use anymore just added for component communications
    @Output() userSelection = new EventEmitter<any>();
    @Output() closeComponent = new EventEmitter<boolean>();

    isDeviceOnline: boolean = true;
    ngDestroy$: any = new Subject<boolean>();
    globalCustomerText:string;
    filterMetaData = {
        count: undefined
    };
    indHeaderLeftModel: IndHeaderLeftDataModel;
    doneButtonRef: any;
    isSmallPopoverMode: boolean = false;
    selectedHeaderModel: IndSectionHeaderViewDataModel;
    allOptionHeaderModel: IndSectionHeaderViewDataModel;
    resultsHeaderModel: IndSectionHeaderViewDataModel;
    selectedCallPlanHeaderModel: IndSectionHeaderViewDataModel;
    pastCallPlanHeaderModel: IndSectionHeaderViewDataModel;
    currentCallPlanHeaderModel: IndSectionHeaderViewDataModel;
    futureCallPlanHeaderModel: IndSectionHeaderViewDataModel;

    constructor(
        private schedulerService: SchedulerService,
        private navService: NavigationService,
        public _ngZone: NgZone,
        public device: DeviceService,
        private listElement: ElementRef,
        public trackingService: TrackService,
        public callPlanOfflineService: CallPlanOfflineService,
        private accountService: AccountOfflineService,
        public utilityService: GlobalUtilityService,
        private events: EventsService,
        public repService: RepServices,
        private translate:TranslateService,
        private footerService: FooterService,
        public popoverCtrl: PopoverController,
        public callPlanFilterPipe:CallPlanFilterPipe,
        public uiService: UIService
    ) {
        //wait for 50ms if the user stops typing then start search
        this.searchTextChanged.pipe(
            debounceTime(50),
            distinctUntilChanged(),)
            .subscribe(text => {
                this.searchText = text;

                if (this.type === SchedulingPop.CP) {
                    this.callPlanOfflineService.callPlanSearchText = text;
                }


                if (this.searchText != '') {
                    this.isSearching = true;
                }
                else {
                    this.isSearching = false;
                }
                if (this.type === SchedulingPop.CP)
                  this.initSectionHeader();
            });

            switch (this.utilityService.globalCustomerText) {
                case 'Stakeholder':
                  this.globalCustomerText = this.translate.instant('STAKEHOLDER');
                  break;
                case 'Contact':
                  this.globalCustomerText = this.translate.instant('CONTACT');
                  break;
                case 'Customer':
                  this.globalCustomerText = this.translate.instant('CUSTOMER');
                  break;
                default:
                  this.globalCustomerText = this.utilityService.globalCustomerText;
                  break;
              }
              this.initSectionHeader();

    }

    ngOnInit() {

        //Fetch the current selected scheduler route
        this.ISelectedScheduleSubcription = this.schedulerService.schedulerObs.subscribe((value: Scheduler) => {
            this.currentSchedule = Object.assign({}, value);
        });

        //Fetch the type of popup to be opened
        this.ItypeSubscription = this.schedulerService.selectionTypeObserver.subscribe(value => {
            if (value) {
                this.type = value;
                this.isSmallPopoverMode = this.type === SchedulingPop.SCi ? true : false;
                this.selectionHeader = this.setHeader(value)
            }
        });

        //Fetch the list of options to be displayed to user and preselect the option on the basis of selected schedule
        this.IschedulerListSubscription = this.schedulerService.selectionListObserver.subscribe(value => {
            if (value && Array.isArray(value)) {
                this.initiateType(value);
            }
        });


        this.events.observe('device:deviceIsOffline').pipe(
            takeUntil(this.ngDestroy$))
            .subscribe((status) => {
                this.isDeviceOnline = false;
                this.updateDoneButtonStatus();
            });

        this.events.observe('device:deviceIsOnline').pipe(
            takeUntil(this.ngDestroy$))
            .subscribe((status) => {
                if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
                    this.isDeviceOnline = false;
                }
                else {
                    this.isDeviceOnline = true;
                }
                this.updateDoneButtonStatus();
            });

        this.initHeaderLeft();
        this.device.isOfflineObservable.pipe(skip(1), takeUntil(this.ngDestroy$)).subscribe(isOffline => this.updateDoneButtonStatus());

        this.initSectionHeader();
    }

    ngOnDestroy() {
        //remove subscription
        if (this.ISelectedScheduleSubcription) { this.ISelectedScheduleSubcription.unsubscribe(); };
        if (this.IschedulerListSubscription) { this.IschedulerListSubscription.unsubscribe(); };
        if (this.ItypeSubscription) { this.ItypeSubscription.unsubscribe(); };


        if (this.type === SchedulingPop.CP) {
            this.callPlanOfflineService.callPlanSearchText = "";
        }

        this.ngDestroy$.next(true);
        this.ngDestroy$.complete();

    }

    public getResultsHeader(searchText: string) {
      let count = this.list ? this.list.length : 0;
      if(searchText && searchText.length > 0) {
        count = this.list.filter(item => {
          if (item.hasOwnProperty('value')) {
              return item.value.toLowerCase().includes(searchText.toLowerCase());
          }
        }).length;
      }
      let name = this.translate.instant('RESULTS_CAP') + " (" + count + ")";

      this.resultsHeaderModel = {
        id: 'results-section-header',
        title: name,
        controls: [],
      };
    }

    public getAllOptionHeader() {
      let name = this.getSectionHeaderText + " (" + String(this.list?.length) + ")";

      this.allOptionHeaderModel = {
        id: 'allOption-section-header',
        title: name,
        controls: [],
      };
    }

    public getSelectedHeader() {

      let name = this.translate.instant('SELECTED') + " (" + String(this.selectedOptions?.length) + ")";

      this.selectedHeaderModel = {
        id: 'selected-section-header',
        title: name,
        controls: [],
      };
    }


    public getSelectedCallPlanHeader() {

      let name = `${this.translate.instant('SELECTED_CAP')} (${this.selectedOptions.length})`;

      this.selectedCallPlanHeaderModel = {
        id: 'selected-call-plan-section-header',
        title: name,
        controls: [],
      };
    }

    public getPastCallPlanHeader() {

      let count = this.callPlanFilterPipe.transform(this.myFormattedCallPlans?.past, false, this.callPlanOfflineService.callPlanSearchText).length
      let name = `${this.translate.instant('CP_PAST')} (${count})`;

      this.pastCallPlanHeaderModel = {
        id: 'past-call-plan-section-header',
        title: name,
        controls: [],
      };
    }

    public getCurrentCallPlanHeader() {

      let count = this.callPlanFilterPipe.transform(this.myFormattedCallPlans?.present, false, this.callPlanOfflineService.callPlanSearchText).length
      let name = `${this.translate.instant('CP_CURRENT')} (${count})`;
      //(myFormattedCallPlans?.present | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length
      this.currentCallPlanHeaderModel = {
        id: 'current-call-plan-section-header',
        title: name,
        controls: [],
      };
    }

    public getFutureCallPlanHeader() {

      let count = this.callPlanFilterPipe.transform(this.myFormattedCallPlans?.future, false, this.callPlanOfflineService.callPlanSearchText).length
      let name = `${this.translate.instant('SMALL_FUTURE')} (${count})`;
      //(myFormattedCallPlans?.present | callPlanFilter:false:callPlanOfflineService.callPlanSearchText).length
      this.futureCallPlanHeaderModel = {
        id: 'future-call-plan-section-header',
        title: name,
        controls: [],
      };
    }


    onSectionHeaderControlClick(id) {


    }

    public get getSectionHeaderText():string {
        let str:string;
        switch (this.type) {
            case SchedulingPop.CP: {
                str = this.translate.instant('ALL_CALL_PLANS');
                break;
            }

            case SchedulingPop.CS: {
                str = this.translate.instant('ALL_TEXT_SEGMENTS_CAP',{configuredText:this.globalCustomerText.toUpperCase()});
                break;
            }

            case SchedulingPop.SA: {
                str = this.translate.instant('ACCOUNT_ALL_ACCOUNTS');
                break;
            }

            case SchedulingPop.SB: {
                str = this.translate.instant('ALL_PRODUCTS_CAP');
                break;
            }

            case SchedulingPop.SCi: {
                str = this.translate.instant('ALL_CITIES');
                break;
            }

            case SchedulingPop.SZ: {
                str = this.translate.instant('ALL_ZIP_POSTAL_CODES_CAP');
                break;
            }

            default:
                str = this.translate.instant('ALL_CAP');
                break;
        }
        return str;
    }

    public get hardDisableDoneButton():boolean {
        let flag:boolean = false;
        try {
            //cities selected and has something has been selected by user already
            if (this.type == SchedulingPop.SCi && this.currentSchedule) {
                flag = _.xor(this.currentSchedule.cityList,this.selectedOptions).length === 0;
            }

            // postal codes logic
            if (this.type === SchedulingPop.SZ && this.currentSchedule) {
                flag = _.xor(this.currentSchedule.zipcodeList,this.selectedOptions).length === 0;
            }

            //Customer Segement
            if (this.type == SchedulingPop.CS && this.currentSchedule) {
                flag = _.xor(this.currentSchedule.customersegmentsList,this.selectedOptions).length === 0;
            }

            // Accounts
            if (this.type == SchedulingPop.SA && this.currentSchedule) {
                flag = _.xor(this.currentSchedule.accountList,this.selectedOptions).length === 0;
            }

            // Callplan
            if (this.type == SchedulingPop.CP && this.currentSchedule) {
                flag = _.xor(this.currentSchedule.cycleplansList,this.selectedOptions).length === 0;
            }

            // Products
            if (this.type == SchedulingPop.SB && this.currentSchedule) {
                flag = _.xor(this.currentSchedule.productsList,this.selectedOptions).length === 0;
            }
        } catch (error) {
            console.log('Error occured while performing done button check'+error);
            flag = false;
        }
        return flag;
    }

    initiateType(v: any[]) {
        //Allow user to select call plan and other multiple options
        if (this.type != SchedulingPop.SD && this.type != SchedulingPop.SP) {
            this.isMultiSelect = true;
            this.list = [];
            this.selectedOptions = [];
            Object.assign(this.list, v);

            //adding an extra key for handling the icon for selection
            this.list.map(e => {
                e.isSelected = this.getSelectedStatus(e);
                if (e.isSelected) { this.selectedOptions.push(e); }
            });


            if (this.type === SchedulingPop.CP) {
                this.myFormattedCallPlans = this.formattedCallPlans;
            }

            /*
                Adding a logic to add the
                options that are not in the list
                but were selected by user for that instance.

                Kinda transactional data..
            */
            this.addUnMappedData();
        }
    }

    private initHeaderLeft() {
      this.indHeaderLeftModel = {
        id: 'scheduler-popover-header-left',
        title: this.selectionHeader,
        mode: true,
        controls: [
          {
            id: 'cancel',
            imgSrc: 'assets/imgs/header_cancel.svg',
            cssClass: 'seventyPercentWidth',
            isDisabled: false,
            align: 'left'
          },
          {
            id: 'done',
            imgSrc: 'assets/imgs/header_complete.svg',
            cssClass: 'seventyPercentWidth',
            isDisabled: this.isDoneButtonDisabled(),
            align: 'right'
          }
        ]
      };
      this.doneButtonRef = this.indHeaderLeftModel.controls[1];
    }
    private isDoneButtonDisabled(): boolean {
      return !this.isDeviceOnline || this.hardDisableDoneButton;
    }
    private updateDoneButtonStatus() {
      this.doneButtonRef.isDisabled = this.isDoneButtonDisabled();
    }
    onHeaderControlClick(id: string) {
      if (id === 'cancel') {
        this.cancelSelection();
      } else if (id === 'done') {
        this.emitMultiSelect();
      }
      this.initSectionHeader();
    }

    initSectionHeader(searchText?: string) {
      this.getSelectedHeader();
      this.getAllOptionHeader();
      this.getResultsHeader(searchText);
      this.getSelectedHeader();
      this.getSelectedCallPlanHeader();
      this.getPastCallPlanHeader();
      this.getCurrentCallPlanHeader();
      this.getFutureCallPlanHeader();
    }

    addUnMappedData() {
        if (this.type === SchedulingPop.SZ) {
            this.currentSchedule.zipcodeList.map(e => {
                let i = this.selectedOptions.findIndex(s => s.id === e.id);
                /*
                    Option is selected on the user object but is not available on the list
                    so adding the data explicitly
                */
                if (i === -1) {
                    e.isSelected = true;
                    this.selectedOptions.push(e);
                }
            });
        }
    }

    getSelectedStatus(e): boolean {
        // return await this._ngZone.runOutsideAngular(async () => {
        let res: boolean = false;
        //cities selected and has something has been selected by user already
        if (this.type == SchedulingPop.SCi && this.currentSchedule.cityList.length) {
            this.currentSchedule.cityList.forEach(city => {
                if (city.id === e.id) {
                    res = true;
                }
            });
        }

        // postal codes logic
        if (this.type == SchedulingPop.SZ && this.currentSchedule.zipcodeList.length) {
            this.currentSchedule.zipcodeList.forEach(val => {
                if (val.id === e.id) {
                    res = true;
                }
            });
        }

        //Customer Segement
        if (this.type == SchedulingPop.CS && this.currentSchedule.customersegmentsList.length) {
            this.currentSchedule.customersegmentsList.forEach(val => {
                if (val.id === e.id) {
                    res = true;
                }
            });
        }

        // Accounts
        if (this.type == SchedulingPop.SA && this.currentSchedule.accountList.length) {
            this.currentSchedule.accountList.forEach(val => {
                if (val.id === e.id) {
                    res = true;
                }
            });
        }

        // Callplan
        if (this.type == SchedulingPop.CP && this.currentSchedule.cycleplansList.length) {
            this.currentSchedule.cycleplansList.forEach(val => {
                if (val.cycleplanid === e.cycleplanid) {
                    res = true;
                }
            });
        }

        // Products
        if (this.type == SchedulingPop.SB && this.currentSchedule.productsList.length) {
            this.currentSchedule.productsList.forEach(val => {
                if (val.ID === e.ID) {
                    res = true;
                }
            });
        }

        // Products
        if (this.type == SchedulingPop.SB && this.currentSchedule.productsList.length) {
            this.currentSchedule.productsList.forEach(val => {
                if (val.ID === e.ID) {
                    res = true;
                }
            });
        }

        return res;
        // });
    }

    setHeader(value: string): string {
        switch (value) {
            case SchedulingPop.CP: {
                return this.translate.instant('CALL_PLANS');
            }

            case SchedulingPop.CS: {
                return this.globalCustomerText +' '+ this.translate.instant('SEGMENTS');
            }

            case SchedulingPop.SA: {
                return this.translate.instant('ACCOUNTS');
            }

            case SchedulingPop.SB: {
                return this.translate.instant('PRODUCTS');
            }

            case SchedulingPop.SCi: {
                return this.translate.instant('CITIES');
            }

            case SchedulingPop.SZ: {
                return this.translate.instant('ZIP_POSTAL_CODES');
            }

            default:
                return "";
        }
    }

    //Perform Action for single selection
    onSelection() {
        let item = this.list.filter(e => e.id === this.selectedResult);
        this.schedulerService.setSelectionResult(item);
    }

    cancelSelection() {
        //Go back to list
        if (this.type === SchedulingPop.SCi) {
            this.popoverCtrl.dismiss(undefined);
        }
        else {
            this.selectedOptions = []
            this.navService.popWithPageTracking().then(() => {
                setTimeout(() => {
                  this.footerService.initButtons(FooterViews.SchedulingDetails);
                }, 10);
            });
        }
        //this.closeComponent.emit(true);
    }

    onItemSelection(event: Event, e: any, flag: boolean) {
        e.isSelected = flag;
        if (flag) {
            this.selectedOptions.push(e);
        }
        else {
            console.log(this.selectedOptions);
            let index: number;
            if (this.type != SchedulingPop.CP && this.type != SchedulingPop.SB) {
                index = this.selectedOptions.findIndex(v => v.id === e.id);
            }
            else if (this.type === SchedulingPop.CP) {
                index = this.selectedOptions.findIndex(v => v.cycleplanid === e.cycleplanid);
            }
            else if (this.type === SchedulingPop.SB) {
                index = this.selectedOptions.findIndex(v => v.ID === e.ID);
            }
            this.selectedOptions.splice(index, 1);
        }
        this.updateDoneButtonStatus();
        event.stopPropagation();

        this.initSectionHeader();
    }

    openDetailsView(event: Event, e: any) {
        if (this.type === SchedulingPop.CP) {
            this.isCallPlanSelected = true;
            this.schedulerService.selectCallPlan(e);

            if (this.device.isMobileDevice && this.device.deviceOrientation === 'PORTRAIT') {
                this.navService.pushWithPageTracking(CallPlanDetails, PageName.CallPlanDetails);
            }
        }
        if (this.type === SchedulingPop.SCi) {
            this.onItemSelection(event, e, !e.isSelected);
        }
        this.updateDoneButtonStatus();
        event.preventDefault();

        this.initSectionHeader();
    }

    //Perform Action for multi selection
    emitMultiSelect() {
        if (this.type === SchedulingPop.SCi) {
            this.schedulerService.setSelectionResult(this.selectedOptions);
            this.popoverCtrl.dismiss(undefined);
        }
        else {
            this.schedulerService.setSelectionResult(this.selectedOptions);
            this.navService.popWithPageTracking().then(() => {
              setTimeout(() => {
                this.footerService.initButtons(FooterViews.SchedulingDetails);
              }, 10);
          });
        }
    }

    handleSelection(event) {
        console.log(event);
    }

    onInput(ev) {
        try {
            this.searchTextChanged.next(ev.value);
            this.initSectionHeader(ev.value);
          } catch (error) {

        }
    }

    ionSearchFocus(ev) {
        // commenting out as this is causing blue strip in the bottom
        // try {
        //     if (this.type != SchedulingPop.SCi) {
        //         let el = this.listElement.nativeElement.ownerDocument.getElementById('allOptions');
        //         if (el) {
        //             el.scrollIntoView({ behavior: 'smooth' });
        //         }
        //     }
        // } catch (error) {
        //     console.log("scroll error");
        // }
    }

    get formattedCallPlans(): { past: any[], present: any[], future: any[] } {
        const currentDay: Date = new Date();

        let formattedCallPlanObj = {
            past: [],
            present: [],
            future: []
        };

        this.list.map(plan => {
            if (new Date(plan.indskr_enddate).getTime()< currentDay.getTime()) {
                formattedCallPlanObj.past.push(plan);
                return;
            }

            if ((new Date(plan.indskr_startdate).getTime() < currentDay.getTime())
                && (new Date(plan.indskr_enddate).getTime() > currentDay.getTime())) {
                formattedCallPlanObj.present.push(plan);
                return;
            }

            formattedCallPlanObj.future.push(plan);
            return;
        });

        return formattedCallPlanObj;
    }

    closeDetails(e) {
        this.isCallPlanSelected = false;
        this.callPlanOfflineService.callPlanAccessedFromScheduler = false;
    }

    getEmptyTextToDisplay(){
       return this.type === 'SchedulerCallPlan' && this.list.length >= 1 ?
        this.translate.instant('NOTHING_SELECTED'): ''
    }

}
