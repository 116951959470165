<ion-header>
    <ind-page-title [viewData]='{id:"account-obj-details",title: objectiveData.name, controls: backButton}' (onControlClick)='backToAccountPlanDetails()'></ind-page-title>
</ion-header>
<ion-content *ngIf="objectiveData">
    <ion-list class='details-list' [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <!-- <ion-item-divider color="light" class="list-title">
            {{'DETAILS_CAP' | translate}}
        </ion-item-divider> -->

        <ind-section-header [viewData]="objectiveDetailHeaderModel"></ind-section-header>
        <!-- <ion-item>
            <ion-label>{{'STATUS' | translate}}</ion-label>
            <div item-content>
                {{objectiveDataStatus}}
            </div>
        </ion-item> -->
        <ind-form-field [viewData]="statusFormField"></ind-form-field>
        <!-- <ion-item>
            <ion-label>{{'ACCOUNTS_OBJECTIVE' | translate}}</ion-label>
            <div item-content class="seventyPercentWidth">
                {{objectiveData.name}}
            </div>
        </ion-item> -->

        <ind-form-field [viewData]="objectiveFormField"></ind-form-field>
        <!-- <ion-item>
            <ion-label>{{'ACCOUNTS_OBJECTIVE_TARGET' | translate}}
            </ion-label>
            <div item-content *ngIf="objectiveData.type == 'QUANTITATIVE'">
                {{(objectiveData.formattedTarget) ? dateTimeFormatsService.formattedCurrency(objectiveData.formattedTarget) : 0}}
            </div>
            <div item-content *ngIf="objectiveData.type == 'QUALITATIVE'">
                100%
            </div>
        </ion-item> -->
        <ind-form-field [viewData]="productFormField"></ind-form-field>

        <ind-form-field [viewData]="targetFormField" *ngIf="objectiveData.type == 'QUANTITATIVE'"></ind-form-field>

        <ind-form-field [viewData]="achievementActualOneFormField"></ind-form-field>

        <ind-form-field [viewData]="achievementProjectedOneFormField"></ind-form-field>

        <ind-form-field [viewData]="achievementTotalOneFormField"></ind-form-field>

        <ind-form-field [viewData]="achievementTwoFormField"></ind-form-field>

        <ind-form-field [viewData]="completedValueFormField"></ind-form-field>
        <!-- <ion-item *ngIf="objectiveData.type == 'QUANTITATIVE'">
            <ion-label>{{'COMPLETE_VALUE' | translate}}
            </ion-label>
            <div item-content>
                {{(objectiveData.formattedCompletedValue) ? dateTimeFormatsService.formattedCurrency(objectiveData.formattedCompletedValue) : 0}}
            </div>
        </ion-item> -->
        <ind-form-field [viewData]="completedPercent"></ind-form-field>
        <!-- <ion-item>
            <ion-label>{{'COMPLETED_PERCENT' | translate}}</ion-label>
            <div item-content>
                {{(objectiveData.completedPercent) ? objectiveData.completedPercent : 0}}%
            </div>
        </ion-item> -->
        <!-- <ion-item-divider color="light" class="list-title">
            {{'ACCOUNTS_OBJECTIVE_CRITICAL_SUCCESS_FACTORS' | translate}}
        </ion-item-divider> -->
        <ind-section-header [viewData]="objectiveCSFHeaderModel"></ind-section-header>
        <ion-item class="placeholderEmptyDiv" *ngIf="!objectiveData.CSFs || objectiveData.CSFs.length==0">

        </ion-item>
        <ng-container *ngFor="let CSF of objectiveData.CSFs">
            <ion-item class="csfTextItem">
                <ion-label><span class="csfText">{{CSF.CSFName}}</span></ion-label>
            </ion-item>
            <div class="separationLine"></div>
        </ng-container>
        <!-- <ion-item-divider color="light" class="list-title">
            {{'ACCOUNTS_OBJECTIVE_PROGRESS REPORTS' | translate}}
            <span class='new-report-text ion-float-right ion-margin-left' [ngClass]="{'disabledButton':selectedPlan.status != 'Open'}" (click)="newReport()" slot="end">{{'CREATE' | translate}}</span>
        </ion-item-divider> -->
        <ind-section-header [viewData]="objectiveReportsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

        <ion-item class="placeholderEmptyDiv" *ngIf="!reportsData || reportsData.length==0">

        </ion-item>
        <main-card *ngFor="let report of allModelReports" [viewData]="report"></main-card>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'accountObjectiveDetails'"></footer-toolbar>
<!-- </ion-footer> -->
<div *ngIf="!objectiveData" class="no-data-message">{{'ACCOUNTS_OBJECTIVE_NO_DATA_TO_DISPLAY' | translate}}</div>
