import { Pipe, PipeTransform } from "@angular/core";

/**
 * Generated class for the OrderBy pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
  transform<T>(items: Array<T>, ...args) {
    if(!items) return items;
    let pairs: Array<{ orderBy: string; asc: boolean }> = [];
    if (args.length > 0) {
      // Added a flag check to ignore the pipe. If starts with '$', pipe ignores it.
      if (args[0] && args[0].includes('$')) {
        return items;
      }
      for (let index = 0; index < args.length; index++) {
        pairs.push({
          orderBy: <string>args[index],
          asc: <boolean>args[++index]
        });
      }
    }
    if (!items || pairs.length == 0) {
      return items.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
    }

    let asc = (a, b, prop) => this.compare(a[prop], b[prop]);
    let desc = (a, b, prop) => this.compare(b[prop], a[prop]);

    return Array.from(items).sort((a, b) => {
      for (let index = 0; index < pairs.length; index++) {
        let pair = pairs[index];
        var result = (pair.asc ? asc : desc)(a, b, pair.orderBy);
        if (result != 0) return result;
      }
      return 0;
    });
  }

  private compare(a: any, b: any): number {
    let isString = val =>
      typeof val === "string" ||
      (!!val &&
        typeof val === "object" &&
        Object.prototype.toString.call(val) === "[object String]");
    if (a === undefined && b === null) return -1;
    if (a === null && b === undefined) return 1;
    if (a == undefined && !!b) return -1;
    if (!!a && b == undefined) return 1;
    if (isString(a) && isString(b)) {
      if (a.toLowerCase().trim() < b.toLowerCase().trim()) return -1;
      if (b.toLowerCase().trim() < a.toLowerCase().trim()) return 1;
    } else if (
      isNaN(parseFloat(a)) ||
      !isFinite(a) ||
      (isNaN(parseFloat(b)) || !isFinite(b))
    ) {
      if (a < b) return -1;
      if (a > b) return 1;
    } else {
      //parse values to numbers for proper comparison
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }
    return 0; //if equal
  }
}
