<ion-header>
  <div>
    <ion-toolbar class="modal-title">
      <div class="customerAvailabilityHeader">
        {{('EDIT' | translate) + " " + dayRecord.day}}
        <ion-button (click)="closemodal(false)" fill="clear" icon-only>
          <ion-icon src="assets/imgs/action_button_cancel.svg"></ion-icon>
        </ion-button>
      </div>
      <div class="controlsContainer">
        <ion-item lines="none">
          <ion-label>
            <p class="form-field-label-text">
              <ion-text>{{fromDataSlotDetails.label}}</ion-text>
            </p>
            <div *ngIf="fromDataSlotDetails.inputText" class="form-field-value" (click)="onInputTextClick($event, fromDataSlotDetails)" tappable>
              <div>{{fromDataSlotDetails.inputText}}</div>
            </div>
            <p *ngIf="!fromDataSlotDetails.inputText && fromDataSlotDetails.customPlaceholderLabel" class="form-field-placeholder-text" (click)="onInputTextClick($event, fromDataSlotDetails)" tappable>
              <ion-text>{{fromDataSlotDetails.customPlaceholderLabel}}</ion-text>
            </p>
          </ion-label>
          <ion-icon slot="end" class="ion-float-right arrow-icon chevron-down-outline" name="chevron-down-outline" (click)="onInputTextClick($event, fromDataSlotDetails)"></ion-icon>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p class="form-field-label-text">
              <ion-text>{{toDataSlotDetails.label}}</ion-text>
            </p>
            <div *ngIf="toDataSlotDetails.inputText" class="form-field-value" (click)="onInputTextClick($event, toDataSlotDetails)" tappable>
              <div>{{toDataSlotDetails.inputText}}</div>
            </div>
            <p *ngIf="!toDataSlotDetails.inputText && toDataSlotDetails.customPlaceholderLabel" class="form-field-placeholder-text" (click)="onInputTextClick($event, toDataSlotDetails)" tappable>
              <ion-text>{{toDataSlotDetails.customPlaceholderLabel}}</ion-text>
            </p>
          </ion-label>
          <ion-icon slot="end" class="ion-float-right arrow-icon chevron-down-outline" name="chevron-down-outline" (click)="onInputTextClick($event, toDataSlotDetails)"></ion-icon>
        </ion-item>
        <ion-button [disabled]="!fromDataSlotDetails.inputText || !toDataSlotDetails.inputText" color="primary" (click)="addData()">{{'ADD' | translate}}</ion-button>
      </div>
    </ion-toolbar>
  </div>
</ion-header>
<ion-content>
  <div class="timeWrapper">
    <p class="timeContainer" *ngFor="let timeRecord of dayRecord.data">
      {{timeRecord.indskr_from_Formatted +" - "+ timeRecord.indskr_to_Formatted}}
      <!-- <ion-icon size="small" class="blueFont" (click)="deleteRecord(timeRecord, dayRecord)" name="close-outline"></ion-icon> -->
    </p>
    <p class="timeContainer" *ngFor="let unsavedTimeRecord of unSavedChanges">
      {{unsavedTimeRecord.startTimeLabel +" - "+ unsavedTimeRecord.endTimeLabel}}
      <ion-icon size="small" class="blueFont" (click)="deleteRecord(unsavedTimeRecord, unSavedChanges)" name="close-outline"></ion-icon>
    </p>
  </div>
</ion-content>
<ion-footer>
  <div class="footerContainer">
    <ion-button fill="clear" color="primary" icon-only (click)="closemodal(false)">{{'CANCEL' | translate}}</ion-button>
    <ion-button fill="clear" [disabled]="!unSavedChanges.length" color="primary" icon-only (click)="closemodal(true)">{{'SAVE' | translate}}</ion-button>
  </div>
</ion-footer>
