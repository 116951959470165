<div class="no-data-message" *ngIf="!device.isOffline && !isDataAvailable()"> {{'NO_TIMELINE' | translate}}</div>
<div class="no-data-message" *ngIf="device.isOffline">{{'CONTACT_TIMELINE_ONLY_AVAILABLE_IN_ONLINE_MODE' | translate}} </div>
<img *ngIf="device.isOffline || !device.isOffline && !isDataAvailable()" class="os_background-list " [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios, 'os_background-list-android': device.deviceFlags.android}" />

<div *ngIf="isDataAvailable()" class="timelineWrapper">
    <div class="SPTimelineFilter">
        <ion-select #SPTimelineSelect (ionChange)="onTimelineTypeChange(SPTimelineSelect.value)" [(ngModel)]="SPTimelineFilter" interface="popover" class="timelineFilter" [selectedText]="getSelectedText(SPTimelineFilter)">
            <ion-select-option value="All_Activities">{{'ALL_ACTIVITIES' | translate}}</ion-select-option>
            <ion-select-option value="Appointment">{{'MEETINGS' | translate}}</ion-select-option>
            <ion-select-option value="Email">{{'MESSAGES' | translate}}</ion-select-option>
            <!-- <ion-select-option value="Followups">Follow-up Actions</ion-select-option> -->
        </ion-select>
    </div>
    <ion-scroll class="containerForData">
        <ion-item-group class="timeline-group" *ngFor="let group of groupedActivities">
            <ion-item-divider [id]="group.key">
                <div class="dividerBorder">

                </div>
                <div class="monthGroupName">
                    {{formatHeader(group.key)}}
                </div>
            </ion-item-divider>
            <ion-item tappable *ngFor="let activity of group.list" (click)="openActivityDetails(activity)">
                <!-- <div col-2 float-left class="time-wrapper">
                    <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                    <h4 class="ion-text-center" text-wrap>{{formattedDate(activity.scheduledStart)}}</h4>
                </div>
                <div col-10 float-right class="text-wrapper">

                    <h4 *ngIf="activity.type == 'Appointment'">
                        <span class="ion-margin-start" *ngIf="activity.meetingOwnerName == userName">
              {{(activity.type=='Appointment' || activity.type=='Sample'? 'MET':'') | translate }} {{'BY_ME' | translate}}
            </span>
                        <span class="ion-margin-start" *ngIf="activity.meetingOwnerName !== userName">
              {{(activity.type=='Appointment' || activity.type=='Sample' ? 'MET' :'') | translate }} {{'BY_OWNER' | translate : { meetingOwnerName : activity.meetingOwnerName} }}
            </span>
                        <br>
                        <span class="ion-margin-start">{{activity.location}}</span>
                    </h4>
                    <h4 *ngIf="activity.type == 'Email'">
                        <span class="ion-margin-start">
              {{'SENT_BY' | translate}} {{(activity.meetingOwnerName == userName ? 'ME': activity.meetingOwnerName) | translate}}
            </span>
                        <br *ngIf="device.shouldBeMobileView">
                        <span class="ion-margin-start" *ngIf="activity.senton && !activity.readOn">
              {{'DELIVERED' | translate}} - {{emailService.getFormattedTime(activity.senton)}}
            </span>
                        <span class="ion-margin-start" *ngIf="activity.readOn">
              {{'READ' | translate}} - {{emailService.getFormattedTime(activity.readOn)}}
            </span>
                    </h4>
                </div> -->

                <ion-grid>
                    <ion-row>
                        <ion-col size="3">
                            <div class="ion-float-center" size="3">
                                <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center">{{formattedDate(activity.scheduledStart)}}</h4>
                            </div>
                        </ion-col>
                        <ion-col size="8">
                            <div class="col-9 ion-float-left ion-margin-right" size="8">
                                <h3 class="wrap-text">

                                    <div *ngIf="activity.type == 'Appointment'"><span class="boldText" class="ion-margin-right">{{'MEETING' | translate}} - </span>{{activity.subject}}</div>

                                    <div *ngIf="activity.type == 'Email'"> <span class="ion-margin-right">{{'MESSAGES' | translate}} - </span>{{activity.subject}}</div>

                                    <div *ngIf="activity.type == 'followuptask'"><span class="ion-margin-right">{{'FOLLOW_UP' | translate}} - </span>{{activity.subject}}</div>
                                </h3>
                                <h4 *ngIf="activity.type == 'Appointment'">

                                    <span *ngIf="activity.meetingOwnerName == userName">
              {{(activity.type=='Appointment' || activity.type=='Sample'? 'MET':'') | translate }} {{'BY_ME' | translate}}
            </span>
                                    <span *ngIf="activity.meetingOwnerName !== userName">
              {{(activity.type=='Appointment' || activity.type=='Sample' ? 'MET' :'') | translate }} {{'BY_OWNER' | translate : { meetingOwnerName : activity.meetingOwnerName} }}
            </span>
                                    <div>
                                        <span>{{activity.location}}</span>
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email'">
                                    <span>
              {{'SENT_BY' | translate}} {{(activity.meetingOwnerName == userName ? 'ME': activity.meetingOwnerName) | translate}}
            </span>
                                    <!-- <br *ngIf="device.shouldBeMobileView"> -->
                                    <div>
                                        <span *ngIf="activity.senton && !activity.readOn">
              {{'DELIVERED' | translate}} - {{emailService.getFormattedTime(activity.senton)}}
            </span>
                                        <span *ngIf="activity.readOn">
              {{'READ' | translate}} - {{emailService.getFormattedTime(activity.readOn)}}
            </span>
                                    </div>
                                </h4>

                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-item-group>
    </ion-scroll>
</div>