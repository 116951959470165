<ion-grid no-padding>
  <ion-row>
    <ion-col class="left-pane">
      <div class="geneeNotifcationListHeader">
        <ion-row>
          <ion-col class="ion-align-self-start">
            <ion-buttons class="backButton" slot="secondary">
              <ion-button (click)="actionGoBack()">
                <ion-icon  name="ios-arrow-back-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-col>
          <ion-col class="ion-align-self-center">
            <ion-buttons class="geneeInfoButton" center>
              <ion-button>
                {{'GENEE_INFO' | translate}}&nbsp;
                <img src="assets/imgs/genee-info-active.svg" width="25" height="25" />
              </ion-button>
            </ion-buttons>
          </ion-col>
          <ion-col class="ion-align-self-end">
            <ion-buttons class="backButton" slot="primary">
              <ion-button (click)="actionDismissGeneeAssistant()">
                <img src="assets/imgs/closeGeneeScreen.png" width="25" height="25" />
              </ion-button>
            </ion-buttons>
          </ion-col>
        </ion-row>
        <div class="searchBoxContainer">
          <ion-searchbar (ionClear)="actionSearchClear($event)" (ionCancel)="actionSearchCancel($event)"
            (ionInput)="actionSearchInfo($event.detail)" type="text" [(ngModel)]="searchInput"
            placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}" class="searchBar" inputmode="search"
            [disabled]="!realGeneeInfo.length">
          </ion-searchbar>
        </div>
      </div>
      <!-- KPI List -->
      <ion-scroll class="skillContent">
        <ion-card *ngFor="let skill of geneeInfo; let buttonIndex = index" class="skillCard">
          <ion-card-content class="skillCardContent">
            <ion-row class="skillCardContentRow">
              <ion-col class="ion-align-self-start no-padding skillCardContentTitleColumn">
                <ion-card-title class="skillTitle">{{skill.skill}}</ion-card-title>
              </ion-col>
              <ion-col class="ion-align-self-end no-padding skillCardContentButtonColumn">
                <ion-buttons class="expandButton" slot="primary">
                  <ion-button id="{{buttonIndex}}" (click)="expandAction(buttonIndex)" small fill="clear">
                    <ion-icon class="arrowIcon" size="small" [name]="getIconName(buttonIndex)"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-col>
            </ion-row>
            <hr class="item-divider">
            <ion-card-title class="skillInfo" text-wrap>
              {{skill.info}}
            </ion-card-title>
            <!-- Suggestions List when Expand Start  -->

            <ng-container *ngIf="(buttonIndex === tappedButtonIndex)">
              <ng-container
                *ngIf="(skill.suggestions.length >= showMoreCardsAfter && showMore === false); else allSuggestions">
                <ng-container *ngFor="let suggestion of skill.suggestions; let si = index">
                  <ng-container [ngTemplateOutlet]="(si<=suggestionsCardsAtMin) ? suggestions : showMoreOption"
                    [ngTemplateOutletContext]="{suggestion: suggestion, index: si}">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #suggestions let-suggestion='suggestion' let-index='index'>
              <hr class="item-divider">
              <ion-card-title class="suggestionTitle" text-wrap>{{suggestion.name}}</ion-card-title>
              <ion-card-title class="suggestionInfo" text-wrap>{{suggestion.value}}</ion-card-title>
            </ng-template>

            <ng-template #showMoreOption let-suggestion='suggestion' let-index='index'>
              <ng-container *ngIf="(index + 1) >= skill.suggestions.length">
                <hr class="item-divider">
                <ion-card-title (click)="actionShowMore(buttonIndex)" class="showMoreOption">{{"Show More"}}
                </ion-card-title>
              </ng-container>
            </ng-template>

            <ng-template #allSuggestions>
              <ng-container *ngFor="let suggestion of skill.suggestions">
                <hr class="item-divider">
                <ion-card-title class="suggestionTitle" text-wrap>{{suggestion.name}}</ion-card-title>
                <ion-card-title class="suggestionInfo" text-wrap>{{suggestion.value}}</ion-card-title>
              </ng-container>
            </ng-template>

            <!-- Suggestions List when Expand End  -->
          </ion-card-content>
        </ion-card>
      </ion-scroll>
      <!--  -->
    </ion-col>
  </ion-row>
</ion-grid>
