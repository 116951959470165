<ion-header>
    <ind-page-title [viewData]="generalSettingsPageTitle" (onControlClick)="onPageTitleControlClick($event)"></ind-page-title>
</ion-header>
<ion-content no-padding toolbar-md-height>
    <ng-container>
        <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
            <ind-section-header [viewData]='regionalHeader'></ind-section-header>
            <ion-item (click)="openLanguageList()" class="alignTextwithDivider">
                <ion-label class="detailsLabels">{{'SETTINGS_DISPLAY_LANGUAGE' | translate }}</ion-label>
                <ion-label class="light-color ellipsis text-end" tappable>{{language.subName}}</ion-label>
                <ion-icon *ngIf="!device.isOffline" class="arrow-icon" tappable name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-item>
            <ind-section-header [viewData]='dateTimeicHeader'></ind-section-header>
            <ion-item (click)="openDateFormatList()" class="alignTextwithDivider">
                <ion-label class="detailsLabels">{{'SETTINGS_DATE_FORMAT' | translate }}</ion-label>
                <ion-label class="light-color ellipsis text-end">{{'DATE_FORMATS_VALUE' | translate : {formatValue : dateFormat} }}
                </ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-item>
            <ion-item (click)="openTimeFormatList($event)" class="alignTextwithDivider">
                <ion-label class="detailsLabels">{{'SETTINGS_TIME_FORMAT' | translate }}</ion-label>
                <ion-label class="light-color ellipsis text-end">{{'TIME_FORMATS_VALUE' | translate : {formatValue : timeFormat | translate} }}
                </ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-down-outline" slot="end"></ion-icon>
            </ion-item>
            <ind-section-header [viewData]='currencyHeader'></ind-section-header>
            <ion-item (click)="openSymbolPositionList($event)" class="alignTextwithDivider">
                <ion-label class="detailsLabels">{{'SETTINGS_SYMBOL_POSITION' | translate }}</ion-label>
                <ion-label class="light-color ellipsis text-end">{{symbolPos}}</ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-down-outline" slot="end"></ion-icon>
            </ion-item>
            <ion-item (click)="openDecimalPositionList($event)" class="alignTextwithDivider">
                <ion-label class="detailsLabels">{{'SETTINGS_DECIMAL_POSITION' | translate }}</ion-label>
                <ion-label class="light-color ellipsis text-end">{{decimalPos}}</ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-down-outline" slot="end"></ion-icon>
            </ion-item>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'aboutsettings'"></footer-toolbar>
<!-- </ion-footer> -->
