<div class="no-data-message" *ngIf="!isDataAvailable() && contactService.isContactProfileLoaded && !isMedProfileEnabled">{{ 'CONTACT_PROFILE_DATA_NOT_AVAILABLE' | translate}}</div>
<img *ngIf="!isDataAvailable() && contactService.isContactProfileLoaded && !isMedProfileEnabled" class="os_background-list" [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios}" />

<ion-item-group>

  <div #scrollTop></div>

    <!-- Active Meeting -->
    <div *ngIf="isActiveMeetingViewEnabled">
      <ind-section-header *ngIf="isActiveMeetingViewEnabled" [viewData]='{id:"active-meeting-header", title: translate.instant("MEETING_SENTIMENT")}'></ind-section-header>
      <div class="accordion-header" [ngClass]="contactService.contactSentimentUpdateActivity?.expanded ? 'expanded':'collapsed'">
        <main-card [viewData]="activeMeetingMainCardDataModel(contactService.contactSentimentUpdateActivity)"></main-card>
      </div>
     <expandable #expWrapper [viewType]="'contactActivePres'" [activity]="contactService.contactSentimentUpdateActivity"
        [expandHeight]="getHeight(contactService.contactSentimentUpdateActivity)" [expanded]="contactService.contactSentimentUpdateActivity?.expanded"
        [meetingContentDuration]="isMeetingContentDurationEnabled" [expandableAccessedFrom]="'contactProfile'"></expandable>
    </div>

    <!-- Events -->
    <ng-container *ngIf="isEventActivitiesEnabled">
      <ind-section-header *ngIf="eventsSectionHeader" [viewData]="eventsSectionHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <ion-item class='empty-item' *ngIf="eventItems.length == 0"></ion-item>
      <ion-list class="events-items-list" *ngIf="eventItems.length > 0">
        <timeline-item *ngFor="let item of eventItems" [inputData]="item" (clickedOnHeader)="openEventDetails($event)"></timeline-item>
      </ion-list>
      
    </ng-container>

  <!--  Captured Consents will be present in Contact Profile Tab-->
  <contact-consent *ngIf='this.isConsentToolEnabled && !this.contactService.isOneKeySearching' [readOnly]="readOnly"></contact-consent>
  <ng-container *ngIf="showConsents">
    <ind-section-header  [viewData]='{id:"consents-header", title: translate.instant("CONSENTS")}'></ind-section-header>
    <div *ngIf="consents.length == 0" class="all-consents no-data-message">{{ 'CONTACT_NO_CONSENTS' | translate}}</div>
    <ion-list *ngFor="let consent of consents">
      <main-card [viewData]="getConsentMainCardDataModel(consent)"></main-card>
    </ion-list>
  </ng-container>



  <div *ngIf="isSalesProfileEnabled">

    <!--CUSTOMER SEGMENT-->
    <!-- <ind-section-header *ngIf="isDataAvailable()" [viewData]='{id:"customer-segment-header", title: translate.instant("CONTACT_CUSTOMER_SEGMENT_CAP")}'></ind-section-header>
    <ion-item-group class="customerSegment enableCopyPaste" *ngFor="let segment of contactService.contactInformation?.productSegmentations">
      <ion-item> -->
        <!-- <ion-label>{{segment.productName}}</ion-label> -->
        <!-- <ion-label>
          <ion-text class="primary-text">
            <h3>{{segment.productName}}</h3>
          </ion-text> -->
          <!-- <p class="secondary-text">
            <ion-text>{{segment.segmentation}}</ion-text>
          </p> -->
        <!-- </ion-label>
        <div class="segmentation-text" slot="end">{{segment.segmentation}}</div>
      </ion-item>
      <div class="separationLine"></div>
    </ion-item-group>
    <ion-item class='empty-item' *ngIf="isDataAvailable() && contactService.isContactProfileLoaded && !(contactService.contactInformation?.productSegmentations?.length > 0)"></ion-item> -->
    
    <!--CALL PLANS-->
    <ng-container *ngIf="isCallPlanToolEnbaled">
      <ind-section-header *ngIf="isDataAvailable() && contactService.isContactProfileLoaded" [viewData]='contactCallPlanHeader' (onControlClickWithEventBubbleUp)="openCllPlanActivityFilterPopup($event)"></ind-section-header>
      <!-- Current Call Plans -->
      <ion-item class='empty-item' *ngIf="isDataAvailable() && contactService.isContactProfileLoaded && (!formattedCallPlans || (formattedCallPlans && formattedCallPlans.present.length == 0 && formattedCallPlans.past.length == 0 && formattedCallPlans.future.length == 0))"></ion-item>
      <ion-list *ngIf="formattedCallPlans && formattedCallPlans?.present && formattedCallPlans?.present?.length>0">
        <div class="accordion-header" [ngClass]="expandedCallPlanSection == 'present'?'expanded':'collapsed'">
          <main-card [viewData]="getCallPlanDataModel('present')"></main-card>
        </div>
        <expandable #expWrapper [viewType]="'contactCallplans'" [contactCallPlans]="formattedCallPlans?.present"
            [expandHeight]="60.34*formattedCallPlans?.present?.length" [contactCallPlanViewMode]="callPlanOfflineService.meetingOrEmailFilter"
            [expanded]="expandedCallPlanSection == 'present'" [expandableAccessedFrom]="'contactProfile'">
        </expandable>
      </ion-list>
      <!-- Future Call Plans -->
      <ion-list *ngIf="formattedCallPlans && formattedCallPlans?.future && formattedCallPlans?.future?.length>0">
        <div class="accordion-header" [ngClass]="expandedCallPlanSection == 'future'?'expanded':'collapsed'">
          <main-card [viewData]="getCallPlanDataModel('future')"></main-card>
        </div>
        <expandable #expWrapper [viewType]="'contactCallplans'" [contactCallPlans]="formattedCallPlans?.future"
          [expandHeight]="60.34*formattedCallPlans?.future?.length" [contactCallPlanViewMode]="callPlanOfflineService.meetingOrEmailFilter"
          [expanded]="expandedCallPlanSection == 'future'" [expandableAccessedFrom]="'contactProfile'">
        </expandable>
      </ion-list>
      <!-- Past Call Plans -->
      <ion-list *ngIf="formattedCallPlans && formattedCallPlans?.past && formattedCallPlans?.past?.length>0">
        <div class="accordion-header" [ngClass]="expandedCallPlanSection == 'past'?'expanded':'collapsed'">
          <main-card [viewData]="getCallPlanDataModel('past')"></main-card>
        </div>
        <expandable #expWrapper [viewType]="'contactCallplans'" [contactCallPlans]="formattedCallPlans?.past"
          [expandHeight]="60.34*formattedCallPlans?.past?.length" [contactCallPlanViewMode]="callPlanOfflineService.meetingOrEmailFilter"
          [expanded]="expandedCallPlanSection == 'past'" [expandableAccessedFrom]="'contactProfile'">
        </expandable>
      </ion-list>
    </ng-container>

    <!--ALLOCATION ELIGIBILITIES-->
    <allocation-eligibility *ngIf="isSampleRequestEnabled && contactService.isContactProfileLoaded && isDataAvailable()"
      [sampleEligibilities]="sampleEligibilities"></allocation-eligibility>

  </div>

  <!--CONTENTS HISTORY-->
  <ng-container *ngIf="meetingHistory?.length>0">
    <ind-section-header *ngIf="meetingHistory?.length>0" [viewData]='{id:"contact-content-history-header", title: translate.instant("SENTIMENT_HISTORY")}'></ind-section-header>
    <ion-list *ngFor="let meeting of meetingHistoryToDisplay">
      <div class="accordion-header" [ngClass]="meeting.expanded?'expanded':'collapsed'">
        <main-card [viewData]="getContentHistoryMainCardDataModel(meeting)"></main-card>
      </div>
        <expandable #expWrapper [viewType]="'contactPresHistory'" [activity]="meeting" [expandHeight]="getHeight(meeting)"
                  [expanded]="meeting.expanded" [meetingContentDuration]="isMeetingContentDurationEnabled" [expandableAccessedFrom]="'contactProfile'">
        </expandable>
    </ion-list>
    <ion-infinite-scroll *ngIf="meetingHistory.length > 0" (ionInfinite)="doContentInfinite($event)" threshold="30%">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ng-container>
  <!--CONTENTS HISTORY CUSTOMER 360 UI-->
  <ng-container *ngIf="sentimentHistorySectionHeader && meetingHistoryItems.length>0">
    <ind-section-header *ngIf="meetingHistoryItems?.length>0" [viewData]='sentimentHistorySectionHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
    <ion-list class="events-items-list">
      <timeline-item *ngFor="let item of meetingHistoryItems" [inputData]="item" (clickedOnHeader)="openItemDetails($event)"></timeline-item>
    </ion-list>
  </ng-container>

  <!--SCIENTIFIC INFORMATION-->
  <ind-section-header *ngIf="isMedProfileEnabled" [viewData]='{id:"scientific-info-header", title: translate.instant("CONTACT_SCIENTIFIC_INFORMATION_CAP")}'></ind-section-header>
  <div class="medical">
    <ion-item-group *ngIf="isMedProfileEnabled && selectedContact.scientificInfo && selectedContact.scientificInfo.publications.length > 0" tappable (click)="openSciInfoDetails('Publications')">
      <ion-item>
        <ion-label>
          <ion-text class="primary-text">
            <h3>{{'PUBLICATIONS' | translate}}</h3>
          </ion-text>
        </ion-label>
          <ion-icon slot="end" class="arrow-icon" name="chevron-forward-outline"></ion-icon>
      </ion-item>
      <div class="separationLine"></div>
    </ion-item-group>
    <ion-item-group *ngIf="isMedProfileEnabled && selectedContact.scientificInfo && selectedContact.scientificInfo.researches.length > 0" tappable (click)="openSciInfoDetails('Research')">
      <ion-item>
        <ion-label>{{ 'RESEARCH' | translate}}</ion-label>
          <ion-icon slot="end" class="arrow-icon" name="chevron-forward-outline"></ion-icon>
      </ion-item>
      <div class="separationLine"></div>
    </ion-item-group>
    <ion-item class='empty-item' *ngIf="isMedProfileEnabled && (!selectedContact.scientificInfo || (selectedContact.scientificInfo.publications.length == 0 && selectedContact.scientificInfo.researches.length == 0))"> </ion-item>
  </div>

  <!--SCIENTIFIC ACTIVITY PLANS-->
  <ng-container *ngIf="isScientificActivityEnabled && (currentScientificPlans.length > 0 || futureScientificPlans.length > 0 || pastScientificPlans.length > 0)">
    <ind-section-header [viewData]='{id:"scientific-activity-header", title: translate.instant("SCIENTIFIC_PLANS_CAP")}'></ind-section-header>
    <ion-list *ngIf="isScientificActivityEnabled  && (currentScientificPlans.length > 0)">
      <div class="accordion-header" [ngClass]="expandedSPSection == 'current'?'expanded':'collapsed'">
        <main-card [viewData]="getScientificActivityDataModel('current')"></main-card>
      </div>
      <expandable #expWrapper [viewType]="'scientificplans'" [scientificPlans]="currentScientificPlans"
        [expandHeight]="0" [expandableAccessedFrom]="'contactProfile'"
        [expanded]="expandedSPSection == 'current'" (openSPContactProfile)="onOpenSPContactProfile($event)">
      </expandable>
    </ion-list>

    <ion-list *ngIf="isScientificActivityEnabled  && (futureScientificPlans.length > 0)">
      <div class="accordion-header" [ngClass]="expandedSPSection == 'future'?'expanded':'collapsed'">
        <main-card [viewData]="getScientificActivityDataModel('future')"></main-card>
      </div>
      <expandable #expWrapper [viewType]="'scientificplans'" [scientificPlans]="futureScientificPlans"
      [expandHeight]="0" [expandableAccessedFrom]="'contactProfile'"
      [expanded]="expandedSPSection == 'future'" (openSPContactProfile)="onOpenSPContactProfile($event)">
      </expandable>
    </ion-list>

    <ion-list *ngIf="isScientificActivityEnabled  && (pastScientificPlans.length > 0)">
      <div class="accordion-header" [ngClass]="expandedSPSection == 'past'?'expanded':'collapsed'">
        <main-card [viewData]="getScientificActivityDataModel('past')"></main-card>
      </div>
      <expandable #expWrapper [viewType]="'scientificplans'" [scientificPlans]="pastScientificPlans"
        [expandHeight]="0" [expandableAccessedFrom]="'contactProfile'"
        [expanded]="expandedSPSection == 'past'" (openSPContactProfile)="onOpenSPContactProfile($event)">
      </expandable>
    </ion-list>
  </ng-container>

  <!--EVENT INFORMATION-->
  <!-- This section is no longer required, as we Events section show all active events  -->
  <!--<ind-section-header *ngIf="isMedProfileEnabled" [viewData]='{id:"contact-event-info-header", title: translate.instant("CONTACT_EVENT_INFORMATION_CAP")}'></ind-section-header>
  <ion-item class='empty-item' *ngIf="isMedProfileEnabled &&
                                      (!selectedContact.scientificInfo
                                        || (selectedContact.scientificInfo.speakerEngagements.length == 0
                                          && selectedContact.scientificInfo.eventHistory.length == 0))">
  </ion-item>
  <div class="medical eventInformation">
    <main-card *ngIf="isMedMarketingEnabled && selectedContact.scientificInfo && selectedContact.scientificInfo.speakerEngagements.length > 0" [viewData]="eventSpeakerMainCardDataModel()"></main-card>
    <main-card *ngIf="isMedMarketingEnabled && selectedContact.scientificInfo && selectedContact.scientificInfo.eventHistory.length > 0" [viewData]="eventAttendanceMainCardDataModel()"></main-card>
  </div>-->

   <!-- All Events which contains Present & Future events -->
  <ng-container *ngIf="isEventsEnabled">
    <ind-section-header *ngIf="isEventsEnabled && contactService.isContactProfileLoaded && customerEvents && customerEvents.availableEvents && customerEvents.availableEvents.length > 0" [viewData]='{id:"customer-available-event-header", title: translate.instant("EVENTS")}'></ind-section-header>
    <ion-list class="margin-left-15" *ngIf="isEventsEnabled && contactService.isContactProfileLoaded && customerEvents && customerEvents.availableEvents && customerEvents.availableEvents.length > 0">
      <div class="accordion-header" *ngFor="let event of customerEvents.availableEvents">
        <main-card [viewData]="availableEventMainCardDataModel(event)"></main-card>
       <expandable #expWrapper [viewType]="'eventDetails'" [customerEvent]="{event:event, register:false, contact:''}" [expandHeight]="'auto'"
        [expanded]="event.isExpanded" [expandableAccessedFrom]="'contactProfile'">
        </expandable>
      </div>
    </ion-list>

    <!-- Customer Upcoming events -->
    <!--<ind-section-header *ngIf="isEventsEnabled && contactService.isContactProfileLoaded && customerEvents && customerEvents.upcomingEvents && customerEvents.upcomingEvents.length > 0" [viewData]='{id:"customer-upcoming-event-header", title: translate.instant("CONTACT_UPCOMING_EVENTS")}'></ind-section-header>
    <ion-list *ngIf="isEventsEnabled && contactService.isContactProfileLoaded && customerEvents && customerEvents.upcomingEvents && customerEvents.upcomingEvents.length > 0">
      <div class="accordion-header" *ngFor="let event of customerEvents.upcomingEvents">
        <main-card [viewData]="availableEventMainCardDataModel(event)"></main-card>
        <expandable #expWrapper [viewType]="'eventDetails'" [customerEvent]="{event:event, register:false, contact: ''}"
        [expandHeight]="'auto'" [expanded]="event.isExpanded" [expandableAccessedFrom]="'contactProfile'">
        </expandable>
      </div>
    </ion-list>-->
  </ng-container>

   <!-- Available Survey -->
   <ng-container *ngIf="isSurveyEnabled && contactService.isContactProfileLoaded && isDataAvailable()">
    <ind-section-header [viewData]="surveyListSectionHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
    <ion-list *ngIf="filteredSurveyListToDisplay" class='survey-list-grid'>
      <div class="survey-card" *ngFor="let survey of filteredSurveyListToDisplay">
        <sub-card [viewData]="survey" [id]="survey.id"  (onControlClick)="onButtonControlClick($event)"></sub-card>
      </div>
    </ion-list>
  </ng-container>
</ion-item-group>
