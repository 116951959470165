<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
  <ind-section-header [viewData]="headerData">
  </ind-section-header>
</ion-header>
<ion-content>
  <ind-form-field [viewData]="getNameFormField()"></ind-form-field>
  <ind-form-field [viewData]="getAccountFormField()"></ind-form-field>
  <ind-datetime-form [viewData]="startDateField" [errorMessageData]="startDateField?.errorMessageField"></ind-datetime-form>
  <ind-datetime-form [viewData]="endDateField" [errorMessageData]="endDateField?.errorMessageField"></ind-datetime-form>
</ion-content>