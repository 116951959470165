<ion-header mode="md">
  <ion-toolbar class="header">
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="icon-only" (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>


<ion-list lines="none">
  <ion-item *ngFor="let option of options;let i = index;">
    <ion-label>{{option.label}}</ion-label>
    <ion-checkbox  slot="start" [(ngModel)]="option.isChecked" (click)="onCheck(option)"
      ></ion-checkbox>
  </ion-item>
</ion-list>
<p class="note-p"><span>Note : </span> {{ note }}</p>

<div>
  <ion-button fill="clear" class="ion-float-right" (click)="onConfirm()" [disabled]="shouldDisable()">{{ buttonLabel
    }}</ion-button>
</div>