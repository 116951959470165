import { Component, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SettingsComponent } from '../settings/settings';

/**
 * Generated class for the VideoSettingsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'video-settings',
  templateUrl: 'video-settings.html',
  styleUrls:['video-settings.scss']
})
export class VideoSettingsComponent {

  @Output()
  onBackButtonClick = new EventEmitter();

  public camResolution = 'high';
  public camFramrate = '30fps';
  public audioOnOff = true;
  public videoOnOff = true;

  constructor(public navCtrl:NavController) {

  }
/**
 * Returns to video settings main screen
 *
 * @memberof VideoSettingsComponent
 */
goBack(){
    this.onBackButtonClick.emit();
  }
}
