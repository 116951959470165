import { FooterService } from "./../../../services/footer/footer.service";
import { Component } from "@angular/core";
import { ResourceParticipantStatus } from "../../../classes/resource/resource.class";
import { NavParams, IonNav } from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "resource-share-history",
  templateUrl: "resource-share-history.html",
  styleUrls:['resource-share-history.scss']
})
export class ResourceShareHistoryComponent {
  public participant;
  public ResourceParticipantStatus = ResourceParticipantStatus;
  ResourceParticipantStatusDisplay = [
    "",
    "SENT",
    "ACCEPTED",
    "DECLINED", // It has to be converted to caps once we have translations.
    "ERROR"
  ];

  public backButton = [{
    id: "close",
    icon: "chevron-back-outline",
    isDisabled: false,
    align: "left"
  }];

  constructor(
    private navParams: NavParams,
    public footerService: FooterService,
    public navCtrl: IonNav,
    public translate:TranslateService,
    private  dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe
  ) {
    this.participant = (this.navParams.data && this.navParams.data.participant);
  }

  ngOnInit() {
    this.footerService.initButtons("ResourceShareHistory");
  }
  viewdismiss(){
    this.navCtrl.pop()
  }

  getStatusDate(date){
    return this.datePipe.transform(date, this.dateTimeFormatsService.dateTime, undefined, this.translate.currentLang)
  }
}
