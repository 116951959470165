import { ModalController } from '@ionic/angular';

import {takeUntil} from 'rxjs/operators';
import { Component } from '@angular/core';
import { SpeechSDKService } from '../../services/speechskd.service';
import { UIService } from '../../services/ui/ui.service';
import { DeviceService } from '../../services/device/device.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { ProductKeyMessagesEntity, CallNotesAssistantData, AssistantDataAddition, UserLikedStatus } from '../../classes/notes-assisstant/products-keymessages.class';
import { LoadingController, PopoverController } from '@ionic/angular';
import { ActivityService } from '../../services/activity/activity.service';
import { MeetingStatus } from '../../classes/meeting/meeting.class';
import { Brand } from '../../classes/brand/brand.class';
import { keyframes } from '@angular/animations';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { EventsService } from '../../services/events/events.service';
import { Subject } from 'rxjs';
import { Endpoints } from '../../../config/endpoints.config';
import { HttpClient } from '@angular/common/http';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { MeetingDataService } from '../../data-services/meeting/meeting.data.service';
import { GlobalErrorHandler } from '../../services/error-handler/error-handler-service';
import { GeneeNotificationPopoverComponent } from '../genee-notification-popover/genee-notification-popover';
import { MyAssistantComponent } from '../my-assistant/my-assistant';
import { MyAssistantService } from '../../services/my-assistant/my-assistant.service';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert/alert.service';
import { TrackService, TrackingEventNames, TrackingGeneeEventProperties } from '../../services/logging/tracking.service';

/**
 * Generated class for the CallNotesAssistantComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'call-notes-assistant',
  templateUrl: 'call-notes-assistant.html',
  styleUrls:['call-notes-assistant.scss']
})
export class CallNotesAssistantComponent {
  isEditing: boolean = false;
  messageStream: string = '';
  callnotesAssistantData: CallNotesAssistantData;
  meetingState: MeetingStatus
  forCompletedMeeting: boolean;
  ngDestroy$: Subject<any> = new Subject<boolean>();
  popover: any;
  userLikedSuggestions: any;
  userInitials: string;
  userResponded: boolean;
  constructor(
    public speechService: SpeechSDKService,
    public uiService: UIService,
    public deviceService: DeviceService,
    public navService: NavigationService,
    public repService: RepServices,
    private alertService: AlertService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public events: EventsService,
    public loadingCtrl: LoadingController,
    public http: HttpClient,
    public meetingService: MeetingDataService,
    public globalErrorHandler: GlobalErrorHandler,
    public device: DeviceService,
    public popoverCtrl: PopoverController,
    public assistantService: MyAssistantService,
    public authService: AuthenticationService,
    public translate: TranslateService,
    private trackingService: TrackService,
    public modalCtrl: ModalController
  ) {
    //this.messageStream = this.speechService.speechToTextInputStream;
  }
  ngOnInit() {
    this.callnotesAssistantData =
      this.speechService.CallNotesAssistantDataMappedToMeeting.find(o => o.activityID == this.activityService.selectedActivity.ID)
    let userName = this.authService.user.displayName.split(' ')
    this.userResponded = this.callnotesAssistantData.userLikedStatus == UserLikedStatus.NO_SELECTION ? false : true
    this.userInitials = (userName.length > 1 ? userName[0].charAt(0) + userName[userName.length - 1].charAt(0) : '').toUpperCase();
    if (this.activityService.selectedActivity.statusString == 'Completed') {
      this.forCompletedMeeting = true;
    }
    else this.forCompletedMeeting = false;

    if (this.callnotesAssistantData.sentForApproval) {
      this.footerService.disableButton(['editText', 'confirmChanges'])
    }
    //this.showGeneePopover();

    this.events.observe('editText:clicked').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.editRecognition();
      })

    this.events.observe('confirm:sendForApproval').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.sendEditsForApproval();
      })
  }
  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
  async editRecognition() {
    if (this.isEditing) {
      this.footerService.enableButtons(['confirmChanges']);
      if (!this.deviceService.isOffline) {
        let loader = await this.loadingCtrl.create();
        loader.present();
        this.callnotesAssistantData.textStreamWithRecognitionEffects = this.callnotesAssistantData.textStream = '';
        this.callnotesAssistantData.productsMatched = this.callnotesAssistantData.productsMatched.filter(o => {
          return o.isUserAdded
        });
        this.callnotesAssistantData.keysmatched = this.callnotesAssistantData.keysmatched.filter(o => {
          return o.isUserAdded
        });
        this.speechService.speechToTextInputStream = document.getElementById('inputStreamEditArea').innerText;
        this.speechService.getEntityAndIntentMatches(this.activityService.selectedActivity.ID, 'callNotesModal').then(() => {
          loader.dismiss();
          this.userResponded = false;
        },
          (error) => {
            console.log('error in LUIS detection', error)
            loader.dismiss();
            this.userResponded = false;
          });
      }
      else {
        this.callnotesAssistantData.textStreamWithRecognitionEffects = this.callnotesAssistantData.textStream =
          document.getElementById('inputStreamEditArea').innerText;

        setTimeout(() => {
          this.callnotesAssistantData.productsMatched.map((e) => {
            e.isSelected = false;
            this.callnotesAssistantData.textStreamWithRecognitionEffects =
              this.callnotesAssistantData.textStreamWithRecognitionEffects.split(e.entityName).join('<mark>' + e.entityName + '</mark>');
          })
          this.callnotesAssistantData.keysmatched.map((e) => {
            e.isSelected = false;
            this.callnotesAssistantData.textStreamWithRecognitionEffects =
              this.callnotesAssistantData.textStreamWithRecognitionEffects.split(e.entityName).join('<mark>' + e.entityName + '</mark>');
          })
        }, 0);
      }
    }
    this.isEditing = !this.isEditing;
    // if(!this.isEditing){
    //   document.getElementById('inputStreamDisplayArea').innerHTML = this.speechService.speechToTextStreamWithRecognition;
    // }
  }
  cancelEditing() {
    this.isEditing = !this.isEditing;
  }
  sendEditsForApproval() {
    this.footerService.disableButton(['editText', 'confirmChanges']);
    this.callnotesAssistantData.sentForApproval = true;
    let url = Endpoints.notes_assisstant.sendToModerator;
    let payload = {
      machine_transcript: this.callnotesAssistantData.textStream,
      transcripts: [],
      is_transcript_approve: false,
      entities: this.callnotesAssistantData.assistantDataAdditionsOrEdits
    }
    let headers = Endpoints.headers
    this.http.post(url, payload).subscribe((res) => {
      console.log('moderator response', res);
      document.getElementById('geneeFirstComment').innerHTML = this.translate.instant('CORRECTIONS_TO_MODERATOR');
    })
    document.getElementById('geneeFirstComment').innerHTML = this.translate.instant('CORRECTIONS_TO_MODERATOR')
  }
  captureInputStreamEdits(event) {
    //this.speechService.speechToTextInputStream = event.target.textContent;
  }
  addToSelected(productOrKeyMessage: ProductKeyMessagesEntity) {
    if (this.isEditing) {
      return;
    }
    productOrKeyMessage.isSelected = !productOrKeyMessage.isSelected;
    //if(productOrKeyMessage.type == 'keymessages'){
    if (this.callnotesAssistantData.textStreamWithRecognitionEffects.includes('<mark>' + productOrKeyMessage.entityName)) {
      this.callnotesAssistantData.textStreamWithRecognitionEffects =
        this.callnotesAssistantData.textStreamWithRecognitionEffects.split(
          '<mark>' + productOrKeyMessage.entityName).join('<mark class="selected">' + productOrKeyMessage.entityName);
    }
    else if (this.callnotesAssistantData.textStreamWithRecognitionEffects.includes('<mark class="selected">' + productOrKeyMessage.entityName)) {
      this.callnotesAssistantData.textStreamWithRecognitionEffects =
        this.callnotesAssistantData.textStreamWithRecognitionEffects.split(
          '<mark class="selected">' + productOrKeyMessage.entityName).join('<mark>' + productOrKeyMessage.entityName);
    }
    document.getElementById('inputStreamDisplayArea').innerHTML = this.callnotesAssistantData.textStreamWithRecognitionEffects;
    //}
  }

  removeSelected(productOrKeyMessage: ProductKeyMessagesEntity) {
    if (this.isEditing) {
      return;
    }
    productOrKeyMessage.isSelected = false;
    if (this.callnotesAssistantData.textStreamWithRecognitionEffects.includes('<mark>' + productOrKeyMessage.entityName.toLowerCase())) {
      this.callnotesAssistantData.textStreamWithRecognitionEffects =
        this.callnotesAssistantData.textStreamWithRecognitionEffects.split(
          '<mark>' + productOrKeyMessage.entityName.toLowerCase() + '</mark>').join(productOrKeyMessage.entityName.toLowerCase());
    }
    else if (this.callnotesAssistantData.textStreamWithRecognitionEffects.includes('<mark class="selected">' + productOrKeyMessage.entityName.toLowerCase())) {
      this.callnotesAssistantData.textStreamWithRecognitionEffects =
        this.callnotesAssistantData.textStreamWithRecognitionEffects.split(
          '<mark class="selected">' + productOrKeyMessage.entityName.toLowerCase() + '</mark>').join(productOrKeyMessage.entityName.toLowerCase());
    }
    document.getElementById('inputStreamDisplayArea').innerHTML = this.callnotesAssistantData.textStreamWithRecognitionEffects;

    if (productOrKeyMessage.type == 'keymessages') {
      this.callnotesAssistantData.keysmatched =
        this.callnotesAssistantData.keysmatched.filter(k => k.entityName !== productOrKeyMessage.entityName);

      // this.activityService.selectedActivity.products.map((p)=>{
      //   if(p.isGenieSelected){
      //     p.keyMessages.map((k)=>{
      //       if(k.name == productOrKeyMessage.entityName){
      //         k.isGenieSelected = false;
      //       }
      //     })
      //   }
      // })
      this.callnotesAssistantData.assistantDataAdditionsOrEdits.keymessages.filter(o => o.after != productOrKeyMessage.entityName)
    }
    else if (productOrKeyMessage.type == 'brand') {
      this.callnotesAssistantData.productsMatched =
        this.callnotesAssistantData.productsMatched.filter(p => p.entityName !== productOrKeyMessage.entityName);

      let indexOfGenieSelected =
        this.activityService.selectedActivity.products.findIndex(o => o.isGenieSelected
          && o.name.toLowerCase() == productOrKeyMessage.entityName.toLowerCase())

      if (indexOfGenieSelected >= 0) {
        let selectedProd = this.activityService.selectedActivity.products[indexOfGenieSelected] as Brand;
        //selectedProd.isSelected = selectedProd.isAutoSelected? true: false;
        selectedProd.isGenieSelected = false;
        selectedProd.keyMessages.forEach((o) => {
          o.isGenieSelected = false;
          //o.isSelected = o.isAutoSelected?true:false;
        })
      }
      this.callnotesAssistantData.assistantDataAdditionsOrEdits.products.filter(o => o.after != productOrKeyMessage.entityName)
    }
    this.events.publish('initActivityKeyMessages');
  }

  addNewProductOrkey(type: string) {

    this.alertService.showAlert({
      title: this.translate.instant('ADD') + " " + `${type == 'keymessages' ? 'Key Message' : type == 'brand' ? 'Product' : ''}?`,
      message: this.translate.instant('CN_PLEASE_ENTER') + " " + `${type == 'keymessages' ? 'Key Message' : type == 'brand' ? 'Product' : ''}` + " " + this.translate.instant('CN_NAME_BELOW'),
      inputs: [
        {
          name: 'entity',
          type: 'text',
          id: 'nameOfEntitiyAdded',

        }
      ]
    }, this.translate.instant('ADD')
    ).then(res => {
      if (res.role == "ok") {
        let e = res.data.values;
        if (!e.entity) return
        if (this.callnotesAssistantData.textStream.includes(e.entity)) {
          e['startIndex'] = this.callnotesAssistantData.textStream.indexOf(e.entity);
          e['endIndex'] = e['startIndex'] + e.entity.length;
          this.callnotesAssistantData.textStreamWithRecognitionEffects =
            this.callnotesAssistantData.textStreamWithRecognitionEffects.split(e.entity.toLowerCase()).join('<mark>' + e.entity.toLowerCase() + '</mark>');
        }
        else {
          e['startIndex'] = 0
          e['endIndex'] = 0
        }
        e['type'] = type;
        e['useradded'] = true;
        let newProdOrKey = new ProductKeyMessagesEntity(e);
        let needToUpdateActivityInDyanmics: boolean = false;
        if (type == 'keymessages') {
          this.callnotesAssistantData.keysmatched.push(newProdOrKey);
          this.activityService.selectedActivity.products.map((p) => {
            if (p.isGenieSelected) {
              (p as Brand).keyMessages.map((k) => {
                if (k.name.toLowerCase() == newProdOrKey.entityName.toLowerCase()) {
                  //k.isSelected = true;
                  k.isGenieSelected = true;
                  needToUpdateActivityInDyanmics = true;
                }
              })
            }
          })
          this.callnotesAssistantData.assistantDataAdditionsOrEdits.keymessages.push({
            before: null,
            is_approve: false,
            status: this.translate.instant('CN_PENDING_FOR_MODERATOR_APPROVAL'),
            after: newProdOrKey.entityName
          })
        }
        else if (type == 'brand') {
          let productIndexInSelectedActivity =
            this.activityService.selectedActivity.products.findIndex(q => q.name.toLowerCase() == newProdOrKey.entityName.toLowerCase());
          if (productIndexInSelectedActivity >= 0) {
            let foundProduct = this.activityService.selectedActivity.products[productIndexInSelectedActivity] as Brand;
            if (!foundProduct.isAutoSelected) {
              //foundProduct.isSelected = true;
              foundProduct.isGenieSelected = true;
              needToUpdateActivityInDyanmics = true;
              foundProduct.keyMessages.map((k) => {
                if (!k.isAutoSelected && this.callnotesAssistantData.keysmatched.some(l => l.entityName.toLowerCase() == k.name.toLowerCase())) {
                  //k.isSelected = true;
                  k.isGenieSelected = true;
                }
              })
            }
          }
          this.callnotesAssistantData.productsMatched.push(newProdOrKey);
          this.callnotesAssistantData.assistantDataAdditionsOrEdits.products.push({
            before: null,
            is_approve: false,
            status: this.translate.instant('CN_PENDING_FOR_MODERATOR_APPROVAL'),
            after: newProdOrKey.entityName
          })
        }
      }
    });
  }

  async showGeneePopover() {
    let data;
    //if(source && source == "callNotesModal"){
    data = {
      heading: this.translate.instant('CN_SUGGESTED_NOTES_ARE_AVAILABLE'),
      notificationText: this.translate.instant('CN_NEW_PRODUCTS_AND_KEY_MSG'),
      mainButtontext: this.translate.instant('CN_TAKE_SUGGESTIONS')
    }
    if (!this.popover) {
      this.popover = await this.popoverCtrl.create({
        component: GeneeNotificationPopoverComponent,componentProps: data,
        cssClass: 'geneeNotesSuggestions', showBackdrop: false})
    }
    else {
      this.popover = undefined;
      this.popover = this.popoverCtrl.create({component: GeneeNotificationPopoverComponent,componentProps: data, cssClass: 'geneeNotesSuggestions', showBackdrop: false })
    }
    // this.popover.onDidDismiss((data)=>{

    // })
    this.popover.present();
    //return;
    //}
  }

  getCountUnreadNotifications() {
    return this.assistantService.countUnreadNotifications
  }
  async handleSuggestionclick(liked: boolean) {
    this.userResponded = true;
    if (liked) {
      this.callnotesAssistantData.userLikedStatus = UserLikedStatus.LIKED;
      if (this.callnotesAssistantData.productsMatched && this.callnotesAssistantData.productsMatched.length >= 0) {
        //code here to add new products to selected activity and select its keymessages
        this.callnotesAssistantData.productsMatched.map((p) => {
          let productIndexInSelectedActivity = this.activityService.selectedActivity.products.findIndex(q => q.name.toLowerCase() == p.entityName.toLowerCase())
          if (productIndexInSelectedActivity >= 0) {
            let foundProduct = this.activityService.selectedActivity.products[productIndexInSelectedActivity] as Brand;
            if (!foundProduct.isAutoSelected) {
              foundProduct.isSelected = true;
              foundProduct.isGenieSelected = true;
              foundProduct.keyMessages.map((k) => {
                if (!k.isAutoSelected && this.callnotesAssistantData.keysmatched.some(l => l.entityName.toLowerCase() == k.name.toLowerCase())) {
                  k.isSelected = true;
                  k.isGenieSelected = true;
                }
              })
            }
          }
        })
        if (!this.device.isOffline) {
          let loader = await this.loadingCtrl.create();
          loader.present();
          try {
            await this.meetingService.updateMeetingProductKeyMessages(this.activityService.selectedActivity as AppointmentActivity);
            this.activityService.selectedActivity.products = this.activityService.selectedActivity.products.sort((item1: any, item2: any) => {
              if (item1.priority && item2.priority) {
                return item1.priority - item2.priority;
              }
            })
            //this._activityProductsCreatedEventHandler(this.activityService.selectedActivity.products);
            this.events.publish('initActivityKeyMessages');
            loader.dismiss();
            this.closepage();
          } catch (e) {
            this.globalErrorHandler.handleError(new Error(e));
            loader.dismiss();
            this.closepage();
          }
        }
        else {
          try { // I/O operations needs to be wrap in a try and catch block
            this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
            this.events.publish('initActivityKeyMessages');
            this.closepage();
          } catch (e) {
            this.globalErrorHandler.handleError(new Error(e));
            this.closepage();
          }
        }
      }
      else {
        this.events.publish('initActivityKeyMessages');
        this.closepage();
      }
    }
    else {
      this.callnotesAssistantData.userLikedStatus = UserLikedStatus.DISLIKED;
      this.closepage();
    }
  }

  closepage() {
    this.isEditing = false;
    this.callnotesAssistantData.productsMatched.forEach(o => {
      if (o.isSelected) {
        this.addToSelected(o)
      }
    });
    this.callnotesAssistantData.keysmatched.forEach(o => {
      if (o.isSelected) {
        this.addToSelected(o)
      }
    });
    this.modalCtrl.dismiss();
    // this.uiService.activeView= 'Appointment';
    // this.footerService.initButtons(FooterViews.Activities)
    // if(this.deviceService.isMobileDevice){
    //   this.navService.popWithPageTracking().then(()=>{
    //     this.footerService.initButtons(FooterViews.Activities)
    //   });
    // }
  }

}
