import { Component, Input } from "@angular/core";
import { LoadingController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { InventoryLot, UserInventory } from "@omni/classes/sample/allocation-inventory.class";
import { MultiSelectPopover } from "@omni/components/multi-select-popover/multi-select-popover";
import { AllocationInventoryDataService } from "@omni/data-services/sample/allocation-inventory.data.service";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { DeviceService } from "@omni/services/device/device.service";
import { EventsService } from "@omni/services/events/events.service";
import { AllocationAdjustService } from "@omni/services/sample/allocation-adjust.service";
import { AllocationInventoryService } from "@omni/services/sample/allocation-inventory.service";
import { AllocationTransferService } from "@omni/services/sample/allocation-transfer.service";
import { AllocationFeatureService } from "@omni/services/sample/allocation.feature.service";
import { SampleService } from "@omni/services/sample/sample.service";
import { UIService } from "@omni/services/ui/ui.service";
import { Utility } from "@omni/utility/util";
import _ from "lodash";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from "@omni/services/authentication.service";
import { isAfter, isFuture, isValid, subDays } from "date-fns";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";

const MIN_SEARCH_LENGTH = 3;

@Component({
  selector: 'user-inventory-list',
  templateUrl: 'user-inventory-list.html',
styleUrls:['user-inventory-list.scss']
})
export class UserInventoryListComponent {

  @Input() searchEvent: Observable<string>;
  private searchEventSubscription: Subscription;
  public isLoading: boolean = true;
  public userInventory: UserInventory[] = [];
  public filteredUserInventory: UserInventory[] = [];
  public inventoryHeaderModel: IndSectionHeaderViewDataModel;
  public multiSelectFilterPopoverData: { text: string; value: string;  items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  filterBy: { text: string; value: string; asc: boolean; };
  private readonly ngDestroy$: any = new Subject<boolean>();
  private filterBadgeCount: number = 1; //Applied filter defaults to 'Active'
  private sampleLastDispatchLimitNum: number = 0;
  private selectedFilterValue: string = 'active';
  private isFullSyncData: boolean = false;


  constructor(
    private allocationInventoryService: AllocationInventoryService,
    private allocationInventoryDataService: AllocationInventoryDataService,
    private popover: PopoverController,
    public translate: TranslateService,
    public uiService: UIService,
    private allocAdjustService: AllocationAdjustService,
    public allocFeatureService: AllocationFeatureService,
    public allocTransferService: AllocationTransferService,
    private events: EventsService,
    public device: DeviceService,
    public sampleService: SampleService,
    public authService: AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private loadingController: LoadingController,
  ) {

  }
  
  async ngOnInit() {
    this.sampleLastDispatchLimitNum = this.authService.user.sampleLastDispatchLimit || 0;
    this.userInventory = [];
    await this.fetchInventory(true);
    this._initFilterPopover();
    if (this.searchEvent) {
      this.searchEventSubscription = this.searchEvent.subscribe(searchTxt => this.searchInput(searchTxt));
    }
    this.events.subscribe('syncAllocationInventory', async () => {
      this.isLoading = true;
      await this.fetchInventory();
    })
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(async (offline) => {
      if (!this.isFullSyncData) {
        this.isLoading = true;
        await this.fetchInventory();
      }
    });

  }

  ngOnDestroy() {
    this.events.unsubscribe('syncAllocationInventory');
    this.isLoading = false;
  }

  async fetchInventory(forceFullSync: boolean = false) {
    if(!this.device._isOffline) {
      const loader = await this.loadingController.create();
      loader.present();
      await this.allocationInventoryDataService.fetchInventory(forceFullSync, false).then(() => {
        if (forceFullSync) this.isFullSyncData = true;
        this.userInventory = _.cloneDeep(this.allocationInventoryService.userInventory);
        this.isLoading = false;
        loader.dismiss();
      }).catch((err) => {
        console.log("fetching allocation Inventory failed: " + err);
        this.userInventory = _.cloneDeep(this.allocationInventoryService.userInventory);
        this.isFullSyncData = false;
        this.isLoading = false;
        loader.dismiss();
      });
    } else {
      this.userInventory = _.cloneDeep(this.allocationInventoryService.userInventory);
      this.isFullSyncData = false;
      this.isLoading = false;
    }
    //Default inventory data is 'Active' (in stock and active)
    this.selectedFilterValue = "active";
    this.initFilterData(this.selectedFilterValue);
  }

  public getInvSectionHeaderDataModel(inventory: UserInventory): IndSectionHeaderViewDataModel {
    let viewData: IndSectionHeaderViewDataModel;
    viewData = {
      id: 'inventory_' + inventory.skuId,
      title: inventory.skuName,
      controls: [{
        id: 'inventory_expand_'+inventory.skuId,
        isDisabled: false,
        icon: inventory.expanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
      }]
    }
    return viewData;
  }

  public getLotMaincardDataModel(lot: InventoryLot): MainCardViewDataModel {
    let lastDispatchDateText: string = '';

    if (lot.lastdispatchdate) {
      lastDispatchDateText = this.datePipe.transform(lot.lastdispatchdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    } else {
      const sampleLastDispatchLimitNum: number = this.authService.user.sampleLastDispatchLimit || 0;
      if (sampleLastDispatchLimitNum > 0 && lot.validTo) {
        const calculatedDateWithLastDispatch: Date = subDays(new Date(lot.validTo), sampleLastDispatchLimitNum);
        if (calculatedDateWithLastDispatch && isValid(calculatedDateWithLastDispatch)) {
          lastDispatchDateText = this.datePipe.transform(calculatedDateWithLastDispatch, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) || '';
        }
      }
    }
    return  {
      id: 'inventory_' + lot.lotId,
      fixedHeight: true,
      primaryTextRight: lot.lotName,
      isSecondaryGrid: true,
      secondaryTextLeft: this.translate.instant('QUANTITY_REMAINING'),
      secondaryTextLeftTwo: lot.totalQtyRemaining.toString(),
      secondaryTextRight: this.translate.instant('EXPIRY_DATE'),
      secondaryTextRightTwo:  lot.validTo ? this.datePipe.transform(lot.validTo, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
      secondaryTextRightThreeLabel: lastDispatchDateText ? this.translate.instant('AL_LAST_DISPATCH_DATE') : '',
      secondaryTextRightThree: lastDispatchDateText ? lastDispatchDateText : '',
      noSeparationLine: false,
      clickHandler: (id: string, ev) => {
        this.uiService.activeView = 'UserInventory';
        this.allocationInventoryService.selectedLot$.next(lot);
        this.allocFeatureService.selectedShipment = undefined;
        if (this.allocAdjustService.adjustForm) {
          this.allocAdjustService.destroyForm();
        }
        if (this.allocTransferService.transferForm) {
          this.allocTransferService.destroyForm();
        }
      }
    };
  }

  public filterData(event) {
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.multiSelectFilterPopoverData }, event: event })
      .then((data) => {
        data.present();
      });
  }

  updateEmptyMessage() {
    this.events.publish('updateAllocationsRHSEmptyMessage', this.userInventory.length > 0 ? true : false)
  }

  searchInput(val: string) {
    this.initFilterData(this.selectedFilterValue);

    if (val.length >= MIN_SEARCH_LENGTH) {
      if (val.trim().length == 0) return;
      let isFoundSku: boolean = false;
      let isFoundLot: boolean = false;
      let tempFilteredUserInventory: UserInventory[] = [];
      this.filteredUserInventory.forEach((inventory) => {
        //search in sku
        if(inventory.skuName.toUpperCase().includes(val.toUpperCase())) {
          isFoundSku = true;
          if(!_.isEmpty(tempFilteredUserInventory)) {
            const idx = tempFilteredUserInventory.findIndex(i=>i.skuId == inventory.skuId);
            if(idx == -1) {
              tempFilteredUserInventory.push(inventory);  
            } 
          }else {
            tempFilteredUserInventory.push(inventory);
          }
        }
        //search in lot 
        else {
          let tempInv = _.cloneDeep(inventory);
          let filteredLot = [];
          filteredLot = inventory.lot.filter(lo => lo.lotName.toUpperCase().includes(val.toUpperCase()));
          if(!_.isEmpty(filteredLot)) {
            isFoundLot = true;
            tempInv.lot = filteredLot;
            if(!_.isEmpty(tempFilteredUserInventory)) {
              const idx = tempFilteredUserInventory.findIndex(i=>i.skuId == inventory.skuId);
              if(idx > -1) {
                tempFilteredUserInventory[idx] = tempInv;
              }else {
                tempFilteredUserInventory.push(tempInv);  
              }
            }else {
              tempFilteredUserInventory.push(tempInv);
            }
          }
        }
      });
      if(!isFoundSku && !isFoundLot) {
        this.filteredUserInventory = [];
      } else {
        this.filteredUserInventory = tempFilteredUserInventory;
      }
    }
    this.updateEmptyMessage();
  }

  onSectionHeaderControlClick(event, skuId) {
    if (event.includes('inventory_expand')) {
      const index = this.userInventory.findIndex(inventory => inventory.skuId == skuId);
      if (index > -1) {
        this.userInventory[index].expanded = !this.userInventory[index].expanded;
        this.userInventory[index].inventoryViewData.controls[0].icon = this.userInventory[index].expanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg';
      }
      const indexForFilterData = this.filteredUserInventory.findIndex(inventory => inventory.skuId == skuId);
      if (indexForFilterData > -1) {
        this.filteredUserInventory[indexForFilterData].expanded = !this.filteredUserInventory[indexForFilterData].expanded;
        this.filteredUserInventory[indexForFilterData].inventoryViewData.controls[0].icon = this.filteredUserInventory[indexForFilterData].expanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg';
      }
    }
  }

  getInventoryExpanded(inventory:UserInventory) {
    return inventory.expanded;
  }

  private _initFilterPopover() {
    this.multiSelectFilterPopoverData = [
      {
        text: '',
        value: 'active',
        items: [
          { text: this.translate.instant('ALL'), value: 'all' },
          { text: this.translate.instant('EXPIRED_LOT'), value: 'expired' },
          { text: this.translate.instant('ACTIVE'), value: 'active' },
          { text: this.translate.instant('OUT_OF_STOCK'), value: '0' }
        ],
        multiselect: false,
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value && item.text.lowercased() === selectedItem.text.lowercased() ? '' : selectedItem.value;
          const today = Utility.changeUTCDateToLocalDateWith0Time(
            new Date().getTime(),
            true
          );
          if (item.value == 'all') {
            this.selectedFilterValue = 'all';
          } else if (item.value == 'expired') {
            this.selectedFilterValue = 'expired';
          } else if (item.value == 'active') {
            this.selectedFilterValue = 'active';
          } else if (item.value == '0') {
            this.selectedFilterValue = '0';
          }
          this.initFilterData(this.selectedFilterValue);
          this.updateEmptyMessage();
        }
      }
    ]
  }

  getAppliedFilterCount() {
    return this.filterBadgeCount;
  }

  private initFilterData(selectedFilterValue: string) {
    this.filterBadgeCount = 1;
    this.userInventory = _.cloneDeep(this.allocationInventoryService.userInventory);
    this.filteredUserInventory = _.cloneDeep(this.userInventory);
    const today = new Date();
    
    if (selectedFilterValue == 'all') {
      this.filterBadgeCount = 0; // 'All' means no applied filter
      this.filteredUserInventory.forEach((inventory, index) => {
        let filteredLot = inventory.lot;
        if(!_.isEmpty(filteredLot)) {
          inventory.lot = filteredLot;
        } else {
          inventory.lot = [];
        }          
      });
      
    } else if (selectedFilterValue == 'expired') {
      this.filteredUserInventory.forEach((inventory, index) => {
        let filteredLot = inventory.lot.filter(lot => {
          if (lot.totalQtyRemaining != 0) {
            let isValidDate: boolean = false;
            if (lot.lastdispatchdate) {
              isValidDate = isAfter(new Date(lot.lastdispatchdate),today);
            } else if (lot.validTo) {
              if (this.sampleLastDispatchLimitNum > 0) {
                const calculatedDateWithLastDispatch: Date = subDays(new Date(lot.validTo), this.sampleLastDispatchLimitNum);
                if (calculatedDateWithLastDispatch && isValid(calculatedDateWithLastDispatch)) {
                  isValidDate = isAfter(new Date(calculatedDateWithLastDispatch),today);
                }
              } else {
                isValidDate = isAfter(new Date(lot.validTo),today);
              }
            }
            return !isValidDate;
          }
        });
        
        if(!_.isEmpty(filteredLot)) {
          inventory.lot = filteredLot;
        } else {
          inventory.lot = [];
        }
      });
    } else if (selectedFilterValue == 'active') {
      this.filteredUserInventory.forEach((inventory, index) => {
        let filteredLot = inventory.lot.filter(lot => {
          if (lot.totalQtyRemaining != 0) {
            let isValidDate: boolean = false;
            if (lot.lastdispatchdate) {
              isValidDate = isAfter(new Date(lot.lastdispatchdate),today);
            } else if (lot.validTo) {
              if (this.sampleLastDispatchLimitNum > 0) {
                const calculatedDateWithLastDispatch: Date = subDays(new Date(lot.validTo), this.sampleLastDispatchLimitNum);
                if (calculatedDateWithLastDispatch && isValid(calculatedDateWithLastDispatch)) {
                  isValidDate = isAfter(new Date(calculatedDateWithLastDispatch),today);
                }
              } else {
                isValidDate = isAfter(new Date(lot.validTo),today);
              }
            }
            return isValidDate;
          }
        });

        if(!_.isEmpty(filteredLot)) {
          inventory.lot = filteredLot;
        } else {
          inventory.lot = [];
        }          
      });
    } else if (selectedFilterValue == '0') {
      this.filteredUserInventory.forEach((inventory, index) => {
        let filteredLot = inventory.lot.filter(lot => lot.totalQtyRemaining == 0);
        if(!_.isEmpty(filteredLot)) {
          inventory.lot = filteredLot;
        } else {
          inventory.lot = [];
        } 
      });
    }
    this.filteredUserInventory = this.filteredUserInventory.filter((inventory) => inventory.lot.length>0);
  }

}