import { CoOwner, ProcedureTracker } from './../../classes/activity/procedure-tracker.activity.class';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { SurgeryProductHierarchy } from '@omni/classes/activity/surgery-order.activity.class';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { differenceInHours, format, isAfter, isBefore } from 'date-fns';
import _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Endpoints } from "../../../config/endpoints.config";
import { ActivityType } from '../../classes/activity/activity.class';
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { ActivityService } from "../../services/activity/activity.service";
import { AuthenticationService } from "../../services/authentication.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../../services/disk/disk.service";
import { EventsService } from "../../services/events/events.service";
import { ReportDataManagementService } from '../../services/reports/report-data-management.service';
import { UIService } from '../../services/ui/ui.service';
import { TrackAction } from '../../utility/common-enums';
import { DeltaService } from '../delta/delta.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { OperationDetail } from '../follow-up-activity/follow-up-activity.data.service';
import { ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
/**
 * Offline/Online data operations service for Order Activity
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ProcedureTrackerActivityDataService {

  private _isInitialMappingDone: boolean = false;
  public isSelectedProcedureTrackerActivityUpdated: boolean = false;
  public inMeetingProcedureTrackerActivity: ProcedureTrackerActivity;
  private _surgeryOrderSyncCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _procedureLogDataReadyToBeLoadedSub: Subscription;
  public isInMeetingProcedureTrackerFlow: boolean = false;
  public users: CoOwner[] = [];
  constructor(
    private disk: DiskService,
    private activityOfflineService: ActivityService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private events: EventsService,
    public dynamics: DynamicsClientService,
    private uiService: UIService,
    private translate: TranslateService,
    private deltaService: DeltaService,
    private reportDataMgmService: ReportDataManagementService,
  ) {
    // combineLatest([this._surgeryOrderSyncCompleted$.asObservable(), this.reportDataMgmService.configurationsLoaded$])
    //   .pipe(
    //     debounceTime(0),
    //     filter(([syncCompleted, configsLoaded]) => syncCompleted && configsLoaded),
    //   ).subscribe(async ([syncCompleted, configsLoaded]) => {
    //     const isEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG);
    //     if (isEnabled) {
    //       this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(true);
    //       this.reportDataMgmService.deRegisterSyncTask(MeasureType.procedure);

    //       const isMeasureInitDone = this.reportDataMgmService.hasMeasure(MeasureType.procedure);
    //       if (isMeasureInitDone) {
    //         // Data needs to be refreshed
    //         this.reportDataMgmService.requestLocalDataRefresh(MeasureType.procedure);
    //       }
    //     }
    //   });
    // this.reportDataMgmService.reportDataRefreshRequest$.subscribe(async (measureType: MeasureType) => {
    //   const isEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG);
    //   if (!isEnabled) {
    //     if (this.reportDataMgmService.hasMeasure(MeasureType.procedure)) {
    //       this.reportDataMgmService.removeMeasure(MeasureType.procedure);
    //     }
    //   }

    //   if (measureType === MeasureType.procedure && isEnabled) {
    //     const isMeasureInitDone = this.reportDataMgmService.hasMeasure(MeasureType.procedure);
    //     const isDataReadyToBeLoaded = this.reportDataMgmService.getProcedureLogDataReadyToBeLoaded() || !isMeasureInitDone;
    //     if (isDataReadyToBeLoaded) {
    //       await this.setupProcedureTrackerMeasureData();
    //     } else {
    //       if (!this._procedureLogDataReadyToBeLoadedSub) {
    //         this._procedureLogDataReadyToBeLoadedSub = this.reportDataMgmService.procedureLogDataReadyToBeLoaded$
    //           .subscribe(async isReady => {
    //             if (isReady) {
    //               await this.setupProcedureTrackerMeasureData();
    //               this._procedureLogDataReadyToBeLoadedSub.unsubscribe();
    //               this._procedureLogDataReadyToBeLoadedSub = undefined;
    //             }
    //           });
    //       }
    //     }
    //   }
    // });
  }

  public async fetchAllUsers(forceFullSync: boolean, loadFromDBOnly: boolean) {
    if (loadFromDBOnly) {
      const data = await this.disk.retrieve(DB_KEY_PREFIXES.ALL_USERS);
      if (data?.raw) {
        this.users = data.raw;
      }
    } else {
      let fetchXml = fetchQueries.fetchAllUsers;
      const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_USERS);
      const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
      if (isInitialSync) {
        this.users = [];
        fetchXml = fetchXml.replace("{deltaSyncCondition}", '');
      } else {
        const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`
        fetchXml = fetchXml.replace("{deltaSyncCondition}", deltaSyncFilter);
        const data = await this.disk.retrieve(DB_KEY_PREFIXES.ALL_USERS);
        if (data?.raw) {
          this.users = data.raw;
        }
      }
      const users = await this.dynamics.executeFetchQuery('systemusers', fetchXml);
      if (users) {
        users.forEach(user => {
          const index = this.users.findIndex(user => user.userId === user['systemuserid']);
          if (index >= 0) {
            if (user['isdisabled']) {
              this.users.splice(index, 0);
            } else {
              this.users[index] = new CoOwner(user['systemuserid'], null, user['fullname'], user['indskr_usertype'] ? user['indskr_usertype'].split(',') : [], user['_positionid_value']);
            }
          } else {
            if (!user['isdisabled']) {
              this.users.push(new CoOwner(user['systemuserid'], null, user['fullname'], user['indskr_usertype'] ? user['indskr_usertype'].split(',') : [], user['_positionid_value']));
            }
          }
        })
        this.users = _.orderBy(this.users, 'userFullName');
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.ALL_USERS, doc => ({ raw: this.users }))
          .catch((err) =>
            console.error("fetchAllUsers: Error saving all users to offline db! ", err)
          );
        syncState.lastUpdatedTime = new Date().getTime();
        await this.disk.updateSyncState(syncState);
      }
    }
  }

  public setProcedureTrackerSyncCompletedSubjectValue(value: boolean) {
    this._surgeryOrderSyncCompleted$.next(value);
  }

  private async createOrderActivityOnline(payload: object): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.CREATE_UPDATE_ORDER;
    // Create new order activity on dynamics and return promise with success or failure
    return this.http.post(url, payload).toPromise();
  }

  private UpdateOrderActivityOnline(payload: object): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.CREATE_UPDATE_ORDER;
    return this.http.patch(url, payload).toPromise();
  }

  private UpdateOrderActivityTrackerOnline(payload: object, procedureid: string): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.CREATE_UPDATE_PROCEDURE_PERIOD_TRACKER;
    url = url.replace('{{procedureid}}', procedureid)
    return this.http.post(url, payload).toPromise();
  }

  private scrapOrderActivityOnline(order: ProcedureTrackerActivity): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.DELETE_ORDER_ACTIVITY;
    url = url.replace('{{salesorderid}}', order.ID)
    return this.http.delete(url).toPromise();
  }

  public scrapProcedureTracker(trackerId: string): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.DELETE_PROCEDURE_TRACKER;
    url = url.replace('{{procedureTrackerId}}', trackerId)
    return this.http.delete(url).toPromise();
  }

  public updateProcedureTrackerOnline(data: object): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.UPDATE_PROCEDURE_TRACKER;
    return this.http.patch(url, data).toPromise();
  }

  public loadOfflineProcedureTrackerActivities(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY,
            $lte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };
      option.selector['pendingPushToDynamics'] = {
        $eq: true
      };
      try {
        // Fetch from DB
        const offlineProcedureTrackers: any[] = await this.disk.find(option);
        if (offlineProcedureTrackers && Array.isArray(offlineProcedureTrackers) && offlineProcedureTrackers.length > 0) {
          let raworderspayload = [];
          offlineProcedureTrackers.forEach(raworder => {
            let newOfflineOrder = new ProcedureTrackerActivity(raworder);
            raworderspayload.push(newOfflineOrder.serviceDTO);
          });
          // Track Offline data count
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER, offlineProcedureTrackers.length);
          resolve(raworderspayload);
        } else {
          resolve([]);
        }
      } catch (error) {
        reject('Error occured while fetching order activities data from offline db');
      }
    });
  }

  public getProcedureLogDataStartDate(userOfflineDataStartFrom: string): string {
    let procedureLogDataStartDate = userOfflineDataStartFrom;
    try {
      const userOfflineDataDurationStartDate: Date = new Date(parseInt(userOfflineDataStartFrom));
      const now: Date = new Date();
      const currentYear = now.getFullYear();
      const startDateOfThisYear = new Date(currentYear, 0, 1);

      if (!isBefore(userOfflineDataDurationStartDate, startDateOfThisYear)) {
        procedureLogDataStartDate = '' + startDateOfThisYear.getTime();
      }
    } catch (error) {
      console.log('getProcedureLogDataStartDate: ', error);
    }

    return procedureLogDataStartDate;
  }

  public async handleDeletedTrackActionForProcedureTrackerActivity(raw) {
    if (raw && raw['salesorderid']) {
      let order = (this.activityOfflineService.getActivityByID(raw['salesorderid']) as ProcedureTrackerActivity);
      if (!order) {// Search for the activity in offline db
        let option = {
          selector: {
            '_id': {
              $gte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY,
              $lte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
            },
            'salesorderid': {
              $eq: raw['salesorderid']
            },
          }
        };
        const rawOrders: any[] = await this.disk.find(option);
        if (rawOrders && Array.isArray(rawOrders) && rawOrders.length > 0) {
          order = new ProcedureTrackerActivity(rawOrders[0]);
          order.isHardDeleted = true;
        }
      }
      if (order) {
        this.updateOrderActivityStatus({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'scrapproceduretrackeractivity', message: 'Scrap Bulk Procedure Log' } }, order, true);
      }
    }
  }


  public async loadProcedureTrackerActivitiesFromDb(dataRange: { from: string, to: string }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const userOfflineDataDurationStartDate: Date = new Date(parseInt(dataRange.from));
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY,
            $lte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };
      try {
        // Fetch from DB and do mapping
        const rawOrders: any[] = await this.disk.find(option);
        if (rawOrders && Array.isArray(rawOrders) && rawOrders.length > 0) {
          let orders = [];
          rawOrders.forEach(rawOrder => {
            let newOrder = new ProcedureTrackerActivity(rawOrder);
            //if(!(newOrder.state == 2 && newOrder.status == 4)){// Don't add scrapped order activities
            if (newOrder.ownerId != this.authenticationService.user.systemUserID) {
              newOrder.isTeamOrder = true;
            }
            if (isAfter(newOrder.createdDate, userOfflineDataDurationStartDate)) {
              orders.push(newOrder);
            }
            //}
          });
          if (orders.length > 0) {
            let action: OperationDetail = {
              onDynamics: false,
              onLocalDatabase: false,
              onLocalCopy: true,
              operationDetail: {
                code: 'SOODBDM101',
                message: 'Proceduere Trackers Offline Db data mapping'
              }
            };
            // Track Offline data count
            let offlineDataCount = orders.filter(o => o.pendingPushToDynamics === true).length;
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_TRACKER, offlineDataCount);
            await this.createOrderActivity(action, orders, null, false).then(success => {
              resolve();
              return;
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      } catch (error) {
        reject('Error occured while fetching surgery order activities data from offline db');
      }
    });
  }

  async loadProcedureLogsFromDBForEdgeAnalytics(includeTeamOrders: boolean = false): Promise<ProcedureTrackerActivity[]> {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY,
          $lte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };
    let procedureLogs: ProcedureTrackerActivity[] = [];

    try {
      const rawProcedureLogs: any[] = await this.disk.find(option);
      if (Array.isArray(rawProcedureLogs)) {
        const currentYear = new Date().getFullYear();

        for (let i = 0; i < rawProcedureLogs.length; i++) {
          const rawProcedureLog = rawProcedureLogs[i];
          if (rawProcedureLog.statuscode !== 548910001
            || rawProcedureLog.indskr_noprocedureday === true
            || (!includeTeamOrders && rawProcedureLog.ownerid !== this.authenticationService.user.systemUserID)) {
            continue;
          }
          const procedureLog = new ProcedureTrackerActivity(rawProcedureLog);
          if (procedureLog.year === currentYear) {
            procedureLogs.push(procedureLog);
          }
        }
      }
    } catch (error) {
      console.error('loadProcedureLogsFromDBForEdgeAnalytics: ', error);
    }

    return procedureLogs;
  }

  public async updateOrderActivityStatus(action: OperationDetail, order: ProcedureTrackerActivity, force: boolean = false): Promise<any> {
    if (order && action && action.operationDetail && action.operationDetail.code) {
      switch (action.operationDetail.code) {
        case 'scrapproceduretrackeractivity': {
          // Check for deletion conditions
          // if (order.ownerId != this.authenticationService.user.systemUserID && !force) {
          //   return Promise.reject(this.translate.instant('NOT_AUTHORIZED_TO_SCRAP_ACTIVITY'));
          // } else
          if ((order.status == 548910001 || order.status == 100001 || order.status == 548910000) && !force) {
            return Promise.reject('Cannot scrap completed activity');
          } else if (order.state == 2 && !force) {
            return Promise.reject('Cannot scrap already cancelled activity');
          } else if (order.state == 0 || force) {
            order.state = 2;
            order.status = 4;
            order.isHardDeleted = true;
            order.pendingPushToDynamics = true;
            if (order.ID.includes('offline')) {
              order.pendingPushToDynamics = false;
            }
            return this.updateOrderActivity(action, [order], new Date().getTime());
          } else {
            return Promise.reject('Error Occured while scraping order activity');
          }
        }
        case 'markcomplete': {
          //order.state = 3;
          //order.status = 100001;
          order.state = 1;
          order.status = 548910001;
          order.pendingPushToDynamics = true;
          order.indskr_datecompleted = new Date().getTime().toString();
          return this.updateOrderActivity(action, [order], new Date().getTime());
        }
        case 'reopenproceduretrackeractivity': {
          order.state = 0;
          order.status = 1;
          order.pendingPushToDynamics = true;
          return this.updateOrderActivity(action, [order], new Date().getTime());
        }
        default: {
          return Promise.reject('Not a valid option for updating status');
        }
      }
    } else {
      return Promise.reject('No activity passed for status updation');
    }
  }


  public async createOrderActivity(action: OperationDetail, data: Array<ProcedureTrackerActivity>, newLastUpdatedTime: number, isInitialSync: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) { //Realtime order creation on dynamics
          let serviceDTO = data[0].serviceDTO;
          await this.createOrderActivityOnline(serviceDTO).then(info => {
            // Succesfully created on dynamics
            if (info && info['salesorderid']) {
              data[0].ID = info['salesorderid'];
              data[0].pendingPushToDynamics = false;
              data[0].subject = data[0].subject;
              data[0].ordernumber = info['ordernumber'];
            }

            if(info && info['procedureTrackers']){
              data[0].procedureTrackers = info['procedureTrackers'];
            }

            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
              checkNextAction = false;
            }
          }).catch(error => {
            // Handle any error scenario
            checkNextAction = true; // As per current expected behaviour still create order in offline db
          });
        } else {
          console.log('Got offline data as:' + data);
          resolve('');
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        if (isInitialSync) {
          try {
            await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_PROCEUDRE_TRACKER_ACTIVITIES);
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_TRACKER, 0);
          } catch (error) {
            reject(this.translate.instant('ERROR_WHILE_CLEARING_OFFLINE_DB') + error);
            return;
          }
        }
        //Initialise document id and get offline DTO for each activity
        if (data) {
          let offlineData = [];
          for (let i = 0; i < data.length; i++) {
            let order = data[i];
            let offlineDTO = order.offlineDataDTO;
            offlineDTO._id = order.offlineDBId;
            if (newLastUpdatedTime) {
              offlineDTO.lastUpdatedTime = newLastUpdatedTime;
            }
            offlineData.push(offlineDTO);
          };

          if (offlineData && Array.isArray(offlineData)) {
            try {
              // Bulk save docs to DB
              await this.disk.bulk(offlineData);

              // Track offline data count
              const count: number = offlineData.filter(o => o.pendingPushToDynamics).length;
              if (count > 0) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_TRACKER, count);
              }
            } catch (error) {
              reject(this.translate.instant('ERROR_WHILE_SAVING_OFFLINE_DATA') + error);
              checkNextAction = false;
            }
            if (!action.onLocalCopy) {
              resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
              checkNextAction = false;
            }
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        // push into local array of order-activities
        if (data) {
          let callFilterActivities: boolean = true;
          if (action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'SOODBDM101' || action.operationDetail.code == 'SOAISDM101')) {
            callFilterActivities = false;
          }
          data.forEach(order => {
            this.activityOfflineService.addActivity(order, false, false, null, callFilterActivities).then(success => {
              resolve('Successfully created order activity');
            }).catch(err => {
              reject('Error Occured while saving order activity into local array' + err);
            })
          })
          // Need to refresh UI on the week view tab
          // this.events.publish('weekview:RefreshUI');
        }
      }
    });
  }

  public async updateOrderActivity(action: OperationDetail, data: Array<ProcedureTrackerActivity>, newLastUpdatedTime, hasOfflineChanges: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) { //Realtime order-activity creation on dynamics
          if (!data[0].ID.includes('offline')) {
            let serviceDTO = data[0].serviceDTO;
            delete serviceDTO.procedureTrackers;
            if (data[0].state == 2 && data[0].status == 4) {
              await this.scrapOrderActivityOnline(data[0]).then(info => {
                data[0].pendingPushToDynamics = false;
                // Succesfully updated on dynamics
                if (info && info['salesorderid']) {
                  data[0].pendingPushToDynamics = false;
                }
                if (!action.onLocalDatabase && !action.onLocalCopy) {
                  resolve('Successfully deleted on server');
                  checkNextAction = false;
                }
              }).catch(err => {
                // Handle any error scenario
                if (err && err['error']) {
                  let errorDetail = err['error'];
                  if (errorDetail['errorCode']) {
                    // Handle error codes
                  }
                }
                checkNextAction = true;
              });
            } else {
              if (action.operationDetail && action.operationDetail.code) {
                if ((action.operationDetail.code == 'markcomplete' || action.operationDetail.code == 'reopenproceduretrackeractivity') && !hasOfflineChanges) {
                  serviceDTO = data[0].statusUpdateDTO;
                } else if(action.operationDetail.code == 'coOwnerUpdate') {
                  serviceDTO = data[0].coOwnersUpdateDTO;
                }
              }
              await this.UpdateOrderActivityOnline(serviceDTO).then(info => {
                // Succesfully updated on dynamics
                if (info && info['salesorderid']) {
                  data[0].pendingPushToDynamics = false;
                  data[0].modifiedByName = this.authenticationService.user.displayName;
                  data[0].modifiedby = this.authenticationService.user.systemUserID;
                  data[0].modifiedon = new Date().getTime() + '';
                  if (action.operationDetail.code == 'updatenotes') {
                    if (info['notes']) {
                      for (let i = 0; i < data[0].procedureNotes.length; i++) {
                        data[0].procedureNotes[i].updated = false;
                        if (!(data[0].procedureNotes[i].noteId && !data[0].procedureNotes[i].noteId.includes('offline'))) {
                          data[0].procedureNotes[i].noteId = info['notes'][i]['annotationid'];
                          data[0].procedureNotes[i].ownerName = this.authenticationService.user.displayName;
                          data[0].procedureNotes[i].createdTime = new Date();
                        }
                      }
                      data[0].procedureNotes = data[0].procedureNotes.filter(a => !a.isDeleted);
                    }
                  } else if (action.operationDetail.code == 'coOwnerUpdate') {
                    data[0].coOwners = data[0].coOwners.filter(coOwner => !coOwner['deleted']);
                  }
                }
                if (!action.onLocalDatabase && !action.onLocalCopy) {
                  resolve('Successfully updated on server');
                  checkNextAction = false;
                }
              }).catch(err => {
                // Handle any error scenario
                //Check for online only actions
                if (action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'markcomplete')) {
                  checkNextAction = false;
                  //data[0].pendingPushToDynamics = true;
                  reject({
                    errorCode: 'ONLINEONLYUPDATEFAILED',
                    operationCode: action.operationDetail.code,
                    errorMessage: 'Failed to perform the operation on dynamics',
                    errorDetails: err,
                  })
                } else {
                  checkNextAction = true; // As per current expected behaviour still push the updates to offline db
                }
              });
            }
          } else {
            // Check whether to push activity online or not
            if (data[0].state == 2 && data[0].status == 4 && action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapproceduretrackeractivity') {
              // Deleting an order activity that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
              data[0].pendingPushToDynamics = false;
            }
          }
        } else {
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        let offlineData = [];
        for (let i = 0; i < data.length; i++) {
          let order = data[i];
          let offlineDTO = order.offlineDataDTO;
          offlineDTO._id = order.offlineDBId;
          let savedOfflineObject = await this.disk.retrieve(order.offlineDBId);
          if (action.operationDetail && action.operationDetail.code == 'SOIADEL101' && savedOfflineObject && savedOfflineObject.pendingPushToDynamics) {
            // In delta Sync order activity update, do not update activities that have pending changes to be pushed
            //checkNextAction = false;
            data[i].pendingPushToDynamics = true;
            resolve([]);
          }
          else if (savedOfflineObject) {
            // offline create and delete
            if (data[0].state == 2 && data[0].status == 4 && action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapproceduretrackeractivity' && data[0].ID.includes('offline')) {
              // Deleting an order activity that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
              offlineData.push(getDeletedPayloadObejct(savedOfflineObject));
            }
            // Handle Deleted activities and track action
            if (action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapproceduretrackeractivity' && order.pendingPushToDynamics == false && order.state == 2 && order.status == 4 && order.isHardDeleted) { // Will reach here only if the updates have been pushed to dynamics and we can remove the object from offline db
              offlineData.push(getDeletedPayloadObejct(savedOfflineObject));
            } else {
              offlineDTO._id = savedOfflineObject['_id'];
              offlineDTO._rev = savedOfflineObject['_rev'];
              offlineDTO.lastUpdatedTime = newLastUpdatedTime;
              offlineData.push(offlineDTO);

              // If a new update in offline, add the offline data count
              if (!savedOfflineObject.pendingPushToDynamics && offlineDTO.pendingPushToDynamics) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 1);
              }
            }
          } else if (action.operationDetail && action.operationDetail.code == 'SOIADEL101') {
            offlineDTO.lastUpdatedTime = newLastUpdatedTime;
            offlineData.push(offlineDTO);

            // If a new update in offline, add the offline data count
            if (offlineDTO.pendingPushToDynamics) {
              this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 1);
            }
          }
        }
        if (offlineData.length > 0) {
          try {
            // Bulk save docs to DB
            await this.disk.bulk(offlineData);
          } catch (error) {
            reject('Error Occured while updating offline data' + error);
            checkNextAction = false;
          }
          if (!action.onLocalCopy) {
            resolve('Successfully updated in offline DB');
            checkNextAction = false;
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        if (data) {
          let callFilterActivities: boolean = true;
          if (action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'SODSDM101')) {
            callFilterActivities = false;
          }
          data.forEach(order => {
            // CHeck for deletion case
            if (action.operationDetail && action.operationDetail.code == 'SOIADEL101' && order.pendingPushToDynamics) {
              // In delta Sync order activity update, do not update activities that have pending changes to be pushed
              //checkNextAction = false;
              resolve('');
            } else {
              if (order.state == 2 && order.status == 4 && order.isHardDeleted) {// If surgery order activity is deleted or track action is deletion one
                if (action.operationDetail?.code != 'scrapproceduretrackeractivity' && this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.ProcedureTracker && this.activityOfflineService.selectedActivity.ID == order.ID) {
                  this.activityOfflineService.selectedActivity = null;
                  this.uiService.activeView = '';
                  this.isSelectedProcedureTrackerActivityUpdated = true;
                }
                this.activityOfflineService.removeActivity(order, false, null, callFilterActivities).then(success => {
                  resolve('Successfully updated order activity');
                }).catch(err => {
                  reject('Error Occured while updating order activity into local array' + err);
                })
              } else {
                this.activityOfflineService.addActivity(order, true, false, null, callFilterActivities).then(success => {
                  resolve('Successfully updated order activity');
                }).catch(err => {
                  reject('Error Occured while updating order activity into local array' + err);
                })
              }// interaction update for account completed order
              // if (order.status === 100001) {
              //   this.globalUtility.updateInteractionAccount(this.accountService.getAccountById(order.accountId), 'Order');
              // }
              // Need to refresh UI on the week view tab
              if (action.operationDetail?.code == 'updateaccounts'
                || action.operationDetail?.code == 'updatecustomers'
                || action.operationDetail?.code == 'updatetime') {
                // this.events.publish('weekview:RefreshUI');
                this.activityOfflineService.publishActivityEvent({ action: "Update", activity: order });
              }

              if (action.operationDetail?.code != 'scrapproceduretrackeractivity' && this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.ProcedureTracker && this.activityOfflineService.selectedActivity.ID == order.ID) {
                this.activityOfflineService.selectedActivity = order;
                this.isSelectedProcedureTrackerActivityUpdated = true;
              }
            }
          })
        }
      }
    });
  }

  public async updateProcedureTracker(tracker: ProcedureTracker, id: string) {
    return await this.UpdateOrderActivityTrackerOnline(tracker.dto, id);
  }

  private updateTrackAction(arrDeletedMappings: any, prodHierarchies: any) {
    if (!_.isEmpty(arrDeletedMappings)) {
      arrDeletedMappings.forEach(deletedMapping => {
        if (!_.isEmpty(prodHierarchies)) {
          prodHierarchies.forEach(prodHR => {
            let index = prodHR.psoitiongroupProductIDs.findIndex(id => id === deletedMapping['positiongroupproductID']);
            if (index > -1) {
              prodHR.psoitiongroupProductIDs.splice(index, 1);
            }
            if (_.isEmpty(prodHR.psoitiongroupProductIDs)) {
              prodHR.trackAction = deletedMapping['track_action'];
            }
          });
        }
      });
    }
  }

  async purgeData(maxEndDateUnixTimestamp: number) {
    await Promise.all([
      this.purgeProcedureTrackers(maxEndDateUnixTimestamp),
    ]);
  }

  private async purgeProcedureTrackers(maxEndDateUnixTimestamp: number) {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY,
          $lte: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };

    try {
      const rawProcedureLogs = await this.disk.find(option);

      if (Array.isArray(rawProcedureLogs)) {
        const { from } = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
        const localDataStartDate: number = Number(this.getProcedureLogDataStartDate(from));
        if (!isNaN(localDataStartDate)) {
          const deletedRawProcedureLogs = [];
          for (let i = 0; i < rawProcedureLogs.length; i++) {
            const rawProcedureLog = rawProcedureLogs[i];
            const scheduedStart: number = Number(rawProcedureLog.indskr_scheduleddate);
            if (isNaN(scheduedStart) || (!isNaN(scheduedStart) && scheduedStart < localDataStartDate) && rawProcedureLog._id && rawProcedureLog._rev) {
              const tempProcedureLog = { ID: rawProcedureLog.salesorderid, type: ActivityType.ProcedureTracker };
              this.activityOfflineService.removeActivity(tempProcedureLog as ProcedureTrackerActivity, false, null);
              deletedRawProcedureLogs.push({
                _id: rawProcedureLog._id,
                _rev: rawProcedureLog._rev,
                _deleted: true
              });
            }
          }

          if (deletedRawProcedureLogs.length > 0) {
            await this.disk.bulk(deletedRawProcedureLogs);
          }
        }
      }
    } catch (error) {
      console.error('purgeProcedureTracker: ', error);
    }
  }

  public async isDuplicateProceureTrackerExists(accountId: string, { scheduledStart , scheduledEnd }): Promise<boolean> {
    let fetchQuery = fetchQueries.duplicateProceureCheck;
    const formattedStartDate = format(scheduledStart,'YYYY-MM-DD');
    const formattedEndDate = format(scheduledEnd,'YYYY-MM-DD');
    fetchQuery = fetchQuery.replace('{startDate}', `${formattedStartDate}`);
    fetchQuery = fetchQuery.replace('{endDate}', `${formattedEndDate}`);
    fetchQuery = fetchQuery.replace('{accountId}', `${accountId}`);
    fetchQuery = fetchQuery.replace('{ownerId}', `${this.authenticationService.user.xSystemUserID}`);
    const response = await this.dynamics.executeFetchQuery('salesorders', fetchQuery);
    return response.length > 0;
  }
}

function getDeletedPayloadObejct(raw) {
  let responsePayload = {
    _id: raw._id,
    _rev: raw._rev,
    offlineId: raw.offlineId,
    statecode: raw.statecode,
    statuscode: raw.statuscode,
    track_action: TrackAction.Deleted,
    _deleted: true,
    pendingPushToDynamics: false,
  };
  if (raw['salesorderid']) {
    responsePayload['salesorderid'] = raw['salesorderid'];
  }
  return responsePayload;
}
