import { Pipe, PipeTransform } from '@angular/core';
import { Scheduler } from '../classes/scheduler/scheduler.class';
import { ActivityService } from '../services/activity/activity.service';

/**
 * Generated class for the SchedulerPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'schedulerFilter',
  pure: true
})
export class SchedulerPipe implements PipeTransform {

  constructor(
    private activityService: ActivityService,
  ) {

  }

  transform(schedulerList: Array<Scheduler>) {

    if (schedulerList != undefined) {

      if (schedulerList.length > 1) {
        return schedulerList.sort(function (a, b) {
          let t1 = new Date(a.startdate).getTime();
          let t2 = new Date(b.startdate).getTime();
          return t2 - t1;
        });
      }
      else
        return schedulerList;
    }
    else
      return [];
  }
}
