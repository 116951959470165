import { Injectable } from '@angular/core';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '@omni/services/disk/disk.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from '@omni/config/pouch-db.config';
import { Endpoints } from 'src/config/endpoints.config';
import { DeviceService } from '@omni/services/device/device.service';
import { UIService } from '@omni/services/ui/ui.service';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Guid } from 'typescript-guid';
// import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
// import { AlertService } from '@omni/services/alert/alert.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { Utility } from '@omni/utility/util';
import { ContractType, PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES, ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { differenceInDays, differenceInHours, endOfDay, format } from 'date-fns';
// import { ActivityService } from '@omni/services/activity/activity.service';
// import { EventsService } from '@omni/services/events/events.service';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { TrackAction } from '@omni/utility/common-enums';
import { TranslateService } from '@ngx-translate/core';
import { getConfigFieldPickListRequestURL, parseConfigFieldPicklistResponse } from '@omni/utility/common.utility';
import { ConfigFieldOptionResponse, ConfigFieldOptionValue } from '@omni/classes/activity/activity.class';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';


@Injectable({
  providedIn: 'root'
})
export class ProcedureContractService {
  public procedureContracts: ProcedureContract[] = [];
  public procedureContractsToList: ProcedureContract[] = [];
  public isRightPaneNavActive = false;
  public procedureSignatureTypes = [];
  refreshProcedureContract: BehaviorSubject<ProcedureContract[]> = new BehaviorSubject([]);
  public isProcedureContractCreationActive: boolean = false; // For Discard alert from account tool 
  childContracts: BehaviorSubject<ProcedureContract[]> = new BehaviorSubject([]);
  contractTempid = '';
  public contractTypes: ContractType[] = [];
  public configFieldOptionsValues = [];
  constructor(
    private disk: DiskService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    public dynamics: DynamicsClientService,
    private uiService: UIService,
    private dateService: DateTimeFormatsService,
    private deviceService: DeviceService,
    private fileservice: IoFileService,
    public navService: NavigationService,
    // private activityService: ActivityService,
    // private readonly events: EventsService,
    private readonly deltaService: DeltaService,
    public translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) { }

  public async fetchProcedureContracts(fullSync, loadFromDBOnly, contractId?) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;
    const offlineData = await this.loadProcedureContractsFromLocalDB();
    let fetchXML = fetchQueries.salesOrders.fetchProcedureContracts;

    try {
      let deltaFilter = '';

      if (loadFromDBOnly) {
        this.procedureContracts = offlineData.raw ? offlineData.raw : [];
        return;
      }

      if (offlineData && offlineData.lastModified) {
        const modifiedon = this.dateService.formatDateForFetchXML(offlineData.lastModified);
        deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
      }

      fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? deltaFilter : '');

      if (this.authenticationService.user.procedureContractConfiguredFields.length) {
        let configFields = '';
        this.authenticationService.user.procedureContractConfiguredFields.forEach((configField) => {
          configFields = configFields.concat(`<attribute name="${configField.fieldName}" />`);
        });
        fetchXML = fetchXML.replace('{configFields}', configFields);
      }

      if (contractId) {
        // realTimeFilter
        fetchXML = fetchXML.replace('{realTimeFilter}', this.getRealTimeSynFilter(contractId));
      }

      await this.dynamics.executeFetchQuery('indskr_procedurecontracts', fetchXML).then(async (response) => {
        if (fullSync && !response.length) this.procedureContracts = [];

        if (response && response.length) {
          let procedureContracts = [];
          response.forEach((contract) => {
            let tempContract = new ProcedureContract(contract);
            tempContract.configuredFields = this.mapConfigFieldValuesToContract(contract);
            procedureContracts.push(tempContract);
          });

          procedureContracts = _.unionBy(procedureContracts, (procedureContract: ProcedureContract) => procedureContract.indskr_procedurecontractid);
          if (fullSync || !this.procedureContracts.length) this.procedureContracts = procedureContracts;

          if (!fullSync && this.procedureContracts.length > 0) {
            procedureContracts.forEach(procedureContract => {
              const index = this.procedureContracts.findIndex(({ indskr_procedurecontractid }) => indskr_procedurecontractid === procedureContract.indskr_procedurecontractid);
              if (index < 0) {
                this.procedureContracts.push(procedureContract);
              } else {
                this.procedureContracts[index] = procedureContract;
              }
            });
          }
        }
        let updatedProc = this.expireContracts(this.procedureContracts);
        this.procedureContracts = updatedProc;
        this.refreshProcedureContract.next(this.procedureContracts);
        this.saveProcedureContractsInLocalDB(this.procedureContracts);
      });
      return true;
    } catch (error) {
      console.log(error);
      this.procedureContracts = offlineData.raw ? offlineData.raw : [];
      return false;
    }
  }

  private getRealTimeSynFilter(contractId) {
    return (`
    <filter type="or">
      <condition attribute="indskr_procedurecontractid" operator="eq" value="${contractId}" />
      <condition attribute="indskr_parentprocedurecontractid" operator="eq" value="${contractId}"/>
    </filter>`)
  }

  public async saveProcedureContractsInLocalDB(procedureContracts: ProcedureContract[]) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_CONTRACT, (doc) => {
        doc = {
          raw: [],
          lastModified: new Date().getTime()
        };
        doc.raw = procedureContracts;
        return doc;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async loadProcedureContractsFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc : [];
      });
      if (offlineDataStored) {
        const pendingPushForDynamics = offlineDataStored.raw.some((pr) => pr.pendingPushToDynamics == true);
        if (pendingPushForDynamics) this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1); // app kill scenario
      }
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public async fetchProcedureContractPositionGroupProducts(fullSync, loadFromDBOnly) {
    if (this.deviceService.isOffline || !this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;
    let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS);
    let lastUpdatedTime;
    if (offlineData && offlineData.raw && !fullSync) {
      if (offlineData.lastUpdatedTime) {
        lastUpdatedTime = offlineData.lastUpdatedTime;
      }
    } else {
      offlineData = {
        raw: [],
      }
    }
    if (!loadFromDBOnly) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS);
      const bulkPositionGroupProductsSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.procedure_contract_position_group_products,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      const now = new Date().getTime();
      let fetchXML = fetchQueries.salesOrders.fetchProcedureContractPositionGroupProducts;
      let deletedPostionGroupProductfetchXML;
      if (lastUpdatedTime && !fullSync) {
        let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
        hourDifference += 1
        fetchXML = fetchXML.replace('{deltaSyncFilter}', `'<condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}"/>`);
        deletedPostionGroupProductfetchXML = fetchQueries.surgeryOrders.fetchDeletedPositiongroupProductMapping;
        deletedPostionGroupProductfetchXML = deletedPostionGroupProductfetchXML.replace('{hourDifference}', `${hourDifference}`);
      } else {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
      let response = await this.dynamics.executeFetchQuery('indskr_positiongroupses', fetchXML);
      let deletedPostionGroupProducts = [];
      if (deletedPostionGroupProductfetchXML) {
        await this.dynamics.executeFetchQuery('indskr_trackchanges', deletedPostionGroupProductfetchXML).then(res => {
          if (res && res.length > 0) {
            deletedPostionGroupProducts = res;
            // deletedPostionGroupProducts.sort((a, b) => {
            //   if (a['track_action_CreatedOn'] > b['track_action_CreatedOn']) return 1;
            //   else return -1;
            // });
          }
        }).catch(err => {
          console.log("Error Occured while fetching deleted position group products mappings" + err)
        });
      }
      lastUpdatedTime = new Date().getTime();
      if (response && Array.isArray(response) && response.length != 0 && response[0]) {
        if (deletedPostionGroupProducts && deletedPostionGroupProducts.length > 0) {
          deletedPostionGroupProducts.forEach(deletedMapping => {
            let residx = response.findIndex(a => a['positiongroupproduct.indskr_positiongroupproductid'] == deletedMapping['positiongroupproductID']);
            if (residx >= 0) {
              response[residx]['track_action'] = deletedMapping['track_action']
            } else {
              if (deletedMapping['track_action'] == TrackAction.Deleted) {
                let idx = offlineData.raw.findIndex(a => a['positiongroupproduct.indskr_positiongroupproductid'] == deletedMapping['positiongroupproductID']);
                if (idx >= 0) {
                  offlineData.raw.splice(idx, 1);
                }
              }
            }
          })
        }
        bulkPositionGroupProductsSyncInfo.totalSynced += response.length;
        if (offlineData && offlineData.raw) {
          response.forEach(item => {
            let idx = offlineData.raw.findIndex(a => a['positiongroupproduct.indskr_positiongroupproductid'] == item['positiongroupproduct.indskr_positiongroupproductid']);
            if (idx >= 0) {
              if (item['positiongroupproduct.statecode'] == 0 && item['product.statecode'] == 0 && item['track_action'] != TrackAction.Deleted) {
                offlineData.raw[idx] = item;
              } else {
                offlineData.raw.splice(idx, 1);
              }
            } else {
              if (item['positiongroupproduct.statecode'] == 0 && item['product.statecode'] == 0 && item['track_action'] != TrackAction.Deleted) {
                offlineData.raw.push(item);
              }
            }
          });
          offlineData.lastUpdatedTime = lastUpdatedTime;
        } else {
          offlineData = {
            raw: response.filter(item => item['positiongroupproduct.statecode'] == 0 && item['product.statecode'] == 0),
            lastUpdatedTime: lastUpdatedTime,
          }
        }
        syncState.lastUpdatedTime = lastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        await this.deltaService.addEntitySyncInfo(bulkPositionGroupProductsSyncInfo);
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS, doc => {
          doc = offlineData;
          return doc;
        }).catch(error => console.error('Save procedure contract position group product data in offline db error: ', error));
      }
    }
  }

  //? To enable procedure contract field in procedure log(surgery-order-info)
  public fieldsForProcedureContractFilled(surgeryOrder: SurgeryOrderActivity): boolean {
    if (!surgeryOrder.accountId ||
      !surgeryOrder.indskr_proceduretype ||
      !this.procedureContracts.length) return false;
    return true;
  }

  //? list procedure contract that satisfies the criteria
  public async setProcedureContractsToList(surgeryOrder: SurgeryOrderActivity, contractType: any) {
    try {
      this.procedureContractsToList = this.procedureContracts.filter((procedureContract) => {
        return this.isContractMeetCriteria(procedureContract, surgeryOrder, contractType);
      });
    } catch (error) {
      console.log(error);
      this.procedureContractsToList = [];
    }
  }

  public isContractMeetCriteria(procedureContract: ProcedureContract, surgeryOrder: SurgeryOrderActivity, contractType: ContractType): boolean {
    const startDate = this.dateService.formatDateForWithStartTime(procedureContract.indskr_startdate).getTime();
    const endDate = this.dateService.formatDateForWithEndTime(procedureContract.indskr_enddate).getTime();
    const isFreeProcedureType = contractType.indskr_usagetype === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT;
    let isCriteriaMet = false;

    if (procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) return false;
    if (contractType.procedureLogApplicability.length == 0 && procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.ACTIVE) return false;
    if (contractType.procedureLogApplicability.length > 0 && !contractType.procedureLogApplicability.includes(procedureContract.statuscode.toString())) return false;
    if (isFreeProcedureType && procedureContract.indskr_noofassistanceavailed === procedureContract.indskr_maximumnoofassistance) return false;

    if (
      procedureContract.indskr_account_value === surgeryOrder.accountId &&
      procedureContract.indskr_contracttypes === contractType.indskr_contracttypeid &&
      surgeryOrder.scheduledStart.getTime() >= startDate &&
      surgeryOrder.scheduledStart.getTime() <= endDate
    ) {
      isCriteriaMet = true;
    }

    if (contractType.indskr_usagetype === PROCEDURE_CONTRACT_TYPES.PAID_MULTIPLE_DAY_CONTRACT) {
      const month = surgeryOrder.scheduledStart.getMonth();
      const year = surgeryOrder.scheduledStart.getFullYear();

      const childExists = this.procedureContracts.find((pc) => {
        if (pc.indskr_parentprocedurecontractid &&
          pc.indskr_parentprocedurecontractid === procedureContract.indskr_procedurecontractid &&
          new Date(pc.indskr_startdate).getMonth() === month && new Date(pc.indskr_startdate).getFullYear() === year
        ) {
          return pc
        }
      });
      if (!childExists) isCriteriaMet = false;
    }

    return isCriteriaMet;
  }

  public generateContract(accountId, accountName, parentContract = null) {
    let newContract: any = {
      indskr_account_value: accountId,
      accountName: accountName,
      indskr_maximumnoofassistance: 0,
      indskr_noofassistanceavailed: 0,
      indskr_procedurecontractid: Guid.create().toString(),
      indskr_name: accountName,
      business_unit: this.authenticationService.user.xBusinessUnitId,
      statecode: 0
    }

    if (parentContract) {
      newContract.indskr_parentprocedurecontractid = parentContract.indskr_procedurecontractid;
      newContract.parentProcedureContractName = parentContract.indskr_name;
      newContract = {
        ...newContract,
        indskr_product_value: parentContract.indskr_product_value,
        indskr_proceduretype: parentContract.indskr_proceduretype,
        indskr_procedure_value: parentContract.indskr_procedure_value,
        accountName: parentContract.accountName,
        productName: parentContract.productName,
        procedureName: parentContract.procedureName,
        procedureTypeString: parentContract.procedureTypeString,
        parentProcedureContractName: parentContract.indskr_name,
        indskr_maximumnoofassistance: parentContract.indskr_maximumnoofassistance,
        specialityId: parentContract.specialityId,
        specialityName: parentContract.specialityName,
        customerAddress: parentContract.customerAddress,
        customerAddressName: parentContract.customerAddressName
      }
    }

    return newContract;
  }

  public CreateProcedureContractInLocal(contract) {
    this.procedureContracts.push(contract);
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
    if (contract.indskr_parentprocedurecontractid) {
      this.setChildContracts(contract.indskr_parentprocedurecontractid);
    }
  }

  public updateProcedureContract(contractId, updatedValue) {
    const index = this.procedureContracts.findIndex(({ indskr_procedurecontractid }) => indskr_procedurecontractid === contractId);
    if (index >= 0) {
      this.procedureContracts[index] = {
        ...this.procedureContracts[index],
        ...updatedValue
      }
    }
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
  }

  public findProcedureContractById(contractId: any) {
    if (!this.procedureContracts.length) return;
    return this.procedureContracts.find((procedureContract) => procedureContract.indskr_procedurecontractid === contractId);
  }

  public async getSurgeryInfoAssociatedWithContract(contractId: string, startDate?, endDate?) {
    let condition = '';
    let fetchXML = fetchQueries.salesOrders.fetchAssociatedSurgeryOrder;

    if (contractId) {
      condition += `<condition attribute="indskr_procedurecontract" operator="eq" value="${contractId}" />`
    }

    if (startDate && endDate) {
      startDate = this.dateService.formatDateForFetchXML(startDate);
      endDate = this.dateService.formatDateForFetchXML(endDate);

      condition += `
       <condition attribute="indskr_scheduleddate" operator="on-or-after" value="${startDate}" />
      <condition attribute="indskr_scheduledenddate" operator="on-or-before" value="${endDate}" />`
    }

    fetchXML = fetchXML.replace('{condition}', condition);
    return await this.dynamics.executeFetchQuery('salesorders', fetchXML).catch((error) => {
      console.log('Error while fetching proceudure log associated with procedure contract', error)
    });
  }

  public async UploadOfflineProcedureContracts(): Promise<void> {
    let offlineProcedureContracts = await this.loadProcedureContractsFromLocalDB();
    let payload;
    if (offlineProcedureContracts && offlineProcedureContracts.raw && Array.isArray(offlineProcedureContracts.raw) && offlineProcedureContracts.raw.length != 0) {
      payload = offlineProcedureContracts.raw.filter(item => item.pendingPushToDynamics == true).map(proceduerContract => {
        return this.createNewContractOfflinePayload(proceduerContract);
      });
      if (!payload || payload.length === 0) return;
      try {
        const url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.UPLOAD_OFFLINE_PROCEDURE_CONTRACT;
        let response: any = [];
        response = await this.http.patch(url, payload).toPromise();
        if (response) {
          response.forEach(res => {
            offlineProcedureContracts.raw.forEach(element => {
              element.pendingPushToDynamics = false;
            });
          })
        }
        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 0);
        this.procedureContracts = offlineProcedureContracts.raw;
        this.saveProcedureContractsInLocalDB(offlineProcedureContracts.raw);
      } catch (error) {
        console.log('contract error', error);
        // return false;
      }
    }
  }

  public generateContractName(procedureContract: ProcedureContract) {
    let name: string = procedureContract.accountName;
    if (procedureContract.contractTypeString) name = name + ' - ' + procedureContract.contractTypeString
    return name;
  }

  public async createProcedureContractOnline(payload: object) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.CREATE_NEW_PROCEDURE_CONTRACT;
    const response = await firstValueFrom(this.http.post(url, payload));
    return response;
  }

  public createNewContractOfflinePayload(proceduerContract: ProcedureContract) {
    let payload: any = {
      indskr_procedurecontractid: proceduerContract.indskr_procedurecontractid,
      indskr_account_value: proceduerContract.indskr_account_value,
      business_unit: this.authenticationService.user.xBusinessUnitId
    };

    const dateFields = [
      'indskr_enddate',
      'indskr_startdate',
      'indskr_postsurgerysignaturecapturedate',
      'indskr_presurgerysignaturecapturedate'
    ];

    //if payload keys are different from contract class fields
    const differentKeyFields = {
      'indskr_proceduresubtype': 'indskr_proceduresubtypes',
    };

    const excludeFields = [
      'accountName',
      'productName',
      'procedureName',
      'procedureTypeString',
      'proceudreSubTypeString',
      'pendingPushToDynamics',
      'parentProcedureContractName',
      'specialityName',
      'customerAddressName',
      'contractTypeString',
      'isNew',
      'contactName',
      'statusString',
      'configuredFields'
    ]

    try {
      Object.keys(proceduerContract).forEach((key) => {
        if (!excludeFields.includes(key) &&
          ((typeof proceduerContract[key] === 'number' && proceduerContract[key] >= 0) || proceduerContract[key])) {
          let payloadKey = differentKeyFields[key] || key;
          payload[payloadKey] = dateFields.includes(key) ? new Date(proceduerContract[key]).getTime() : proceduerContract[key];
        }
      });

      if (proceduerContract.configuredFields) {
        payload['appconfigfields'] = [];
        Object.keys(proceduerContract.configuredFields).forEach((key) => {
          let configuredField = this.authenticationService.user.procedureContractConfiguredFields.find((configField) => {
            return configField.fieldName === key
          });
          if (configuredField && proceduerContract.configuredFields[key] != null) {
            let generatedPayload = this.generatePayloadForConfiguredFields(proceduerContract.configuredFields[key], configuredField.fieldName, configuredField.fieldType);
            payload['appconfigfields'].push(generatedPayload);
          }
        });
      }

      if (proceduerContract.isNew) {
        payload = { ...payload, offline_procedurecontractid: "offline_" + new Date().getTime().toString() }
      }

      return payload;
    } catch (error) {
      console.log('Error while creating payload for procedure contract');
      return null;
    }

  }

  public async downloadContract(url: string) {
    try {
      if (this.deviceService.deviceFlags.electron) {
        await this.fileservice.downloadInElectron(url);
      } else {
        const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();

        const httpOptions = {
          headers: new HttpHeaders({ 'X-Zone-Id': timeZone }),
          responseType: 'text' as 'text',
          observe: 'response' as 'response'
        }

        const resp = await this.http.get(url, httpOptions).toPromise();
        if (!resp) return;
        const base64String = resp.body;
        const contentDisposition = resp.headers.get('Content-Disposition');
        const encodedFileName = contentDisposition.split("''")[1];
        const fileName = decodeURIComponent(encodedFileName);

        const blob = await this.fileservice.convertBase64ToBlob(base64String, 'application/pdf');
        if (this.deviceService.isNativeApp) {
          if (this.deviceService.deviceFlags.ios) {
            const arrayBuffer = await this.fileservice.convertBlobToArrayBuffer(blob);
            if (arrayBuffer) this.fileservice.writeFileBufferToSharedDirectory(fileName, arrayBuffer, { replace: false })
          } else if (this.deviceService.deviceFlags.android) {
            await this.fileservice.writeToExternalStoageInAndroid(base64String, fileName);
          }
        } else {
          this.fileservice.downloadBlobFileInBrowser(blob, fileName);
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  // download pre and post contract doc
  public async downloadContractDocument(contract: ProcedureContract, fileName: string) {
    try {
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.DOWNLOAD_CONTRACT_DOC;
      url = url.replace('{{contractId}}', contract.indskr_procedurecontractid);
      url = url.replace('{{fileName}}', encodeURIComponent(fileName));
      await this.downloadContract(url);
      return true;
    } catch (error) {
      console.log('error in download', error);
      return null;
    }
  }

  public async downloadGeneratedContract(contract: ProcedureContract) {
    try {
      const kCodeEnabled = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_procedurecontracturl'];

      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.DOWNLOAD_GENERATED_CONTRACT_DOC;
      url = url.replace('{{contractId}}', contract.indskr_procedurecontractid);
      const contractType = this.findContractTypeById(contract.indskr_contracttypes);
      url = url.replace('{{usageType}}', contractType.indskr_usagetype.toString());
      const buid = this.authenticationService.user.xBusinessUnitId;
      url = url.replace('{{buId}}', buid.toString());

      if (kCodeEnabled) {
        url = url.concat(`&featureFlags=fetchKCode`)
      }

      await this.downloadContract(url);
      return true;
    } catch (error) {
      console.log('error in download', error);
      return null;
    }
  }

  public async uploadContractDocument(file, payload, contractId,) {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify(payload));
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.UPLOAD_CONTRACT_DOC;
      url = url.replace('{{contractId}}', contractId);
      const response = await this.http.post(url, formData).toPromise();
      return response;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public async UpdateProcedureContractOnline(payload: object, procedureContractid: string) {
    try {
      await this.uiService.displayLoader();
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.UPDATE_PROCEDURE_CONTRACT
      url = url.replace('{{procedureContractId}}', procedureContractid)
      const response = await lastValueFrom(this.http.patch(url, payload));
      await this.uiService.dismissLoader();
      return response;
    } catch (error) {
      console.log(error);
      await this.uiService.dismissLoader();
      return false;
    }
  }

  public async fetchProcedureSignatureType(loadFromDBOnly) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;

    if (loadFromDBOnly) {
      this.procedureSignatureTypes = await this.loadProcedureSignatureTypeFromLocalDB();
      return;
    }

    const response = await this.dynamics.retrieveGlobalOptionSet("Name='indskr_signaturecapturemode'");

    if (response && response.Options && response.Options.length > 0) {
      let ptypes = response.Options?.map((r) => {
        return ({
          label: r.Label.UserLocalizedLabel.Label,
          value: r.Value
        })
      })
      this.saveProcedureSignatureTypeInLocalDB(ptypes);
      this.procedureSignatureTypes = ptypes;
    }
  }

  private async saveProcedureSignatureTypeInLocalDB(procedureSignatureTypes: any) {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_SIGNATURE_TYPE, (doc) => {
      doc = {
        raw: [],
        lastModified: doc.lastModified
      };
      doc.raw = procedureSignatureTypes;
      return doc;
    });
  }

  public async loadProcedureSignatureTypeFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_SIGNATURE_TYPE, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc.raw : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure signature types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public findProcedureSignatureTypeById(procedureSignatureId) {
    if (!this.procedureSignatureTypes.length) return;
    return this.procedureSignatureTypes.find((signatureType) => signatureType.value === procedureSignatureId)
  }

  public setChildContracts(parentContractId) {
    let childContracts = this.procedureContracts.filter(({ indskr_parentprocedurecontractid }) => indskr_parentprocedurecontractid && indskr_parentprocedurecontractid === parentContractId)
    this.childContracts.next(childContracts);
  }

  // to update procedure allocated and proceure used after updating crteria fields(date, account)
  public updatePaidMultipleDayContract(parentId, response) {
    if (response.indskr_maximumnoofassistance_parent) {
      this.updateProcedureContract(parentId, {
        indskr_maximumnoofassistance: response.indskr_maximumnoofassistance_parent,
        indskr_noofassistanceavailed: response.indskr_noofassistanceavailed_parent
      });
      let month = new Date(response.indskr_scheduleddate).getMonth();
      let year = new Date(response.indskr_scheduleddate).getFullYear();

      const childContract = this.getChildContractByMonthAndYear(month, year, parentId);

      this.updateProcedureContract(childContract.indskr_procedurecontractid, {
        indskr_maximumnoofassistance: response.indskr_maximumnoofassistance_parent,
        indskr_noofassistanceavailed: response.indskr_noofassistanceavailed_child
      });
    }
  }

  public decrementProcedureUsedCount(contractId) {
    const contract = this.findProcedureContractById(contractId);
    if (contract) {
      this.updateProcedureContract(contractId, {
        indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed - 1
      });
    }
  }

  public incrementProcedureUsedCount(contractId) {
    const contract = this.findProcedureContractById(contractId);
    if (contract) {
      this.updateProcedureContract(contractId, {
        indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed + 1
      });
    }
  }

  public getChildContractByMonthAndYear(month, year, parentId) {
    const childContract = this.procedureContracts.find((procedureContract) => {
      let cmonth = new Date(procedureContract.indskr_startdate).getMonth();
      let cyear = new Date(procedureContract.indskr_startdate).getFullYear();
      if (cmonth === month && cyear === year && procedureContract.indskr_parentprocedurecontractid === parentId) return procedureContract;
    });

    return childContract;
  }

  public generatePayload(params: any) {
    const excludeList = ['accountName'];
    const payload = Object.entries(params).reduce((acc, [key, value]) => {
      if ((value || value === '' || typeof value === 'number') &&
        !excludeList.includes(key)) {
        acc[key] = value
      }
      return acc
    }, {});
    return payload;
  }

  public async fetchContractTypes(fullSync, loadFromDBOnly) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;
    const offlineData = await this.loadContractTypesFromLocalDB();

    try {
      let deltaFilter = '';

      if (loadFromDBOnly) {
        this.contractTypes = offlineData.raw ? offlineData.raw : [];
        return;
      }

      if (offlineData && offlineData.lastModified) {
        const modifiedon = this.dateService.formatDateForFetchXML(offlineData.lastModified);
        deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
      }

      let fetchXML = fetchQueries.salesOrders.fetchContractTypes;
      fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? deltaFilter : '');

      if (fullSync) {
        fetchXML = fetchXML.replace('{fullSyncFilter}', '<condition attribute="statecode" operator="eq" value="0" />');
      }

      await this.dynamics.executeFetchQuery('indskr_contracttypes', fetchXML).then((response) => {
        if (fullSync && !response.length) this.contractTypes = [];

        if (response && response.length) {
          // configuredFields
          let contractTypes: any = [];
          response.forEach((rcontractType) => {
            // TODO : try removing configuration from dynamics and check
            const index = contractTypes.findIndex((contractType) => contractType.indskr_contracttypeid === rcontractType.indskr_contracttypeid)
            if (index >= 0) {
              contractTypes[index].configuredFields.push(rcontractType['aa.indskr_appfieldid']);
            } else {
              contractTypes.push(new ContractType(rcontractType));
            }
          });

          contractTypes = _.unionBy(contractTypes, (contractType: ContractType) => contractType.indskr_contracttypeid);
          if (fullSync || !this.contractTypes.length) this.contractTypes = contractTypes;

          if (!fullSync && this.contractTypes.length > 0) {
            contractTypes.forEach(contractType => {
              const index = this.contractTypes.findIndex(({ indskr_contracttypeid }) => indskr_contracttypeid === contractType.indskr_contracttypeid);
              if (index < 0 && contractType.statecode !== 1) {
                this.contractTypes.push(contractType);
              } else if (contractType.statecode === 1) { // if contract deactivated remove from local
                this.contractTypes.splice(index, 1);
              } else {
                this.contractTypes[index] = contractType;
              }
            });
          }
        } else if (!fullSync && offlineData.raw) {
          this.contractTypes = offlineData.raw ? offlineData.raw : [];
          return;
        }
        this.saveContractTypesInLocalDB(this.contractTypes);
      });
      return true;
    } catch (error) {
      console.log(error);
      this.contractTypes = offlineData?.raw ? offlineData.raw : [];
      return false;
    }
  }

  public async saveContractTypesInLocalDB(contractTypes: ContractType[]) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTRACT_TYPES, (doc) => {
        doc = {
          raw: [],
          lastModified: new Date().getTime()
        };
        doc.raw = contractTypes;
        return doc;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async loadContractTypesFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.CONTRACT_TYPES, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public findContractTypeById(contractTypeId) {
    return this.contractTypes.find((type) => type.indskr_contracttypeid === contractTypeId);
  }

  public getContractType(contractTypeId) {
    const contractType = this.findContractTypeById(contractTypeId);
    if (!contractType) return false;
    return contractType.indskr_usagetype;
  }

  public isContractCompleted(contractId) {
    const contract = this.findProcedureContractById(contractId);
    if (!contract) return false;
    // return contract.statecode === PROCEDURE_CONTRACT_STATUS.COMPLETED;
    return false;
  }

  public async purgeData(maxEndDateUnixTimestamp): Promise<any> {
    let offlineData = [];
    let offlineDataDoc = {
      raw: [],
      lastUpdatedTime: null,
    };
    let afterPurgeData;
    let compareToDate = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp, true);
    await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT, true).then((doc) => {
      offlineDataDoc = doc;
      if (doc && doc.raw) {
        offlineData = doc.raw;
      }
    }).catch(err => {
      console.log(err);
    });
    try {
      if (offlineData && offlineData.length != 0) {
        afterPurgeData = offlineData.filter(raw => (raw['indskr_enddate'] &&
          new Date(raw['indskr_enddate']).getTime() < compareToDate.getTime() &&
          (
            raw['statuscode'] === PROCEDURE_CONTRACT_STATUS.CANCELLED || raw['statuscode'] === PROCEDURE_CONTRACT_STATUS.EXPIRED) ||
          (!raw['indskr_enddate'] && new Date(raw['createdon']).getTime() < compareToDate.getTime() &&
            raw['statuscode'] === PROCEDURE_CONTRACT_STATUS.EXPIRED)
        ));
      }
      if (afterPurgeData && Array.isArray(afterPurgeData)) {
        offlineDataDoc.raw = afterPurgeData;
        this.procedureContracts = offlineData;
        this.refreshProcedureContract.next(this.procedureContracts);
        this.saveProcedureContractsInLocalDB(this.procedureContracts);
      }

    } catch (error) {
      console.log('Error while purging procedure contract ' + error);
    }
  }

  private expireContracts(offlineData) {
    let updatedData = offlineData.filter((contract) => {
      return this.isActiveContract(contract);
    });
    return updatedData;
  }

  isActiveContract(contract: ProcedureContract) {
    const today = new Date().getTime();
    const diff = contract.indskr_enddate ? 0 : differenceInDays(new Date(contract.createdon), new Date()); // if enddate is not there we consider created date for difference
    if ((contract.indskr_enddate && endOfDay(new Date(contract.indskr_enddate)).getTime() > today) || (!contract.indskr_enddate && diff < 30)) {
      return true;
    }
    return false;
  }

  public removeProcedureContract(contractId) {
    const index = this.procedureContracts.findIndex(({ indskr_procedurecontractid }) => indskr_procedurecontractid === contractId);
    if (index < 0) return;
    this.procedureContracts.splice(index, 1);
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
  }

  public getStatusString(statusCode: Number) {
    switch (statusCode) {
      case PROCEDURE_CONTRACT_STATUS.DRAFT:
        return this.translate.instant('CONTRACT_DRAFT');
      case PROCEDURE_CONTRACT_STATUS.PRE_SIGNED:
        return this.translate.instant('PRE_SIGNED');
      // case PROCEDURE_CONTRACT_STATUS.COMPLETED:
      //   statusString = 'COMPLETED'
      //   break;
      case PROCEDURE_CONTRACT_STATUS.CANCELLED:
        return this.translate.instant('CANCELLED');
      case PROCEDURE_CONTRACT_STATUS.EXPIRED:
        return this.translate.instant('EXPIRED_LOT');
      case PROCEDURE_CONTRACT_STATUS.POST_SIGNED:
        return this.translate.instant('POST_SIGNED');
      case PROCEDURE_CONTRACT_STATUS.ACTIVE:
        return this.translate.instant('ACTIVE');
      default:
        return null;
    }
  }

  public shouldContractFieldsReadonly(contractType, contractStatus) {
    if (contractType.procedureLogApplicability.length == 0 && contractStatus !== PROCEDURE_CONTRACT_STATUS.ACTIVE) return false;
    if (contractType.procedureLogApplicability.length > 0 && contractType.procedureLogApplicability.includes(contractStatus.toString())) return false;
    return true;
  }

  //------- config fields related methods --------//

  private mapConfigFieldValuesToContract(response) {
    let configFieldValues = {};

    let selectedContractType = this.findContractTypeById(response._indskr_contracttypes_value);
    if (!selectedContractType) return configFieldValues;
    let applicableConfiguredFields = this.authenticationService.user.procedureContractConfiguredFields.filter((configuredField) => {
      return selectedContractType.configuredFields.includes(configuredField.appFieldId);
    });

    applicableConfiguredFields.forEach((configuredField: ConfiguredFields) => {
      switch (configuredField.fieldType) {
        case 'String':
        case 'Memo':
        case 'Currency':
        case 'Money':
        case 'Integer':
        case 'BigInt':
        case 'Decimal':
          configFieldValues = { ...configFieldValues, [configuredField.fieldAlias]: response[configuredField.fieldAlias] }
          break;
        // case 'Boolean':
        //   break;
        case 'DateTime':
          configFieldValues = { ...configFieldValues, [configuredField.fieldAlias]: response[configuredField.fieldAlias] ? new Date(response[configuredField.fieldAlias]) : null }
          break;
        case 'Picklist':
        case 'Virtual':
          configFieldValues = { ...configFieldValues, [configuredField.fieldAlias]: response[configuredField.fieldAlias] }
          break;
        case 'Uniqueidentifier':
        case 'Lookup':
          const valueKey = `_${configuredField.fieldAlias}_value`;
          const formattedKey = `${valueKey}_Formatted`;
          if (response.hasOwnProperty(valueKey)) {
            configFieldValues = {
              ...configFieldValues, [configuredField.fieldAlias]: {
                id: response[valueKey],
                name: configuredField.fieldName,
                entity: configuredField.indskr_lookupentitysetname,
                stringValue: response[formattedKey],
                indskr_referencingentitynavigationpropertyname: configuredField.indskr_referencingentitynavigationpropertyname,
                fieldname: configuredField.fieldName,
                datatype: configuredField.fieldType,
                value: response[valueKey],
                indskr_lookupentitysetname: configuredField.indskr_lookupentitysetname,
              }
            }
          }
          break;
        case 'Owner':
        case 'EntityName':
          break;
        default:
          console.error('getConfigFieldInputTextAndValue: Unhandled switch case statement: ');
          break;
      }
    });

    return configFieldValues;
  }

  public async syncProcedureContractConfigFieldOptionSetValues(loadFromDbOnly = false) {
    try {
      if (loadFromDbOnly) {
        const localRecords: any = await this.loadOfflineConfigFieldOptionSetValues();
        if (localRecords) {
          this.configFieldOptionsValues = localRecords;
        }
      } else {
        const optionSetFields = this.authenticationService.user.procedureContractConfiguredFields.filter(field => field.fieldType === 'Picklist' || field.fieldType === 'Virtual');
        // this.eventsToolService.resetConfigFieldOptionsValues();
        const results = await Promise.all([
          ...optionSetFields.map(field => this.fetchConfigFieldOptionSetValues(field))
        ]);
      }
    } catch (error) {
      console.error('getEventConfigFieldOptionSetValues: ', error);
    }
  }

  private async fetchConfigFieldOptionSetValues(configuredField: ConfiguredFields) {
    const syncInfo: EntitySyncInfo = {
      entityName: EntityNames.eventPicklistAttributes + configuredField.fieldName,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    let url: string;
    try {
      url = this.authenticationService.userConfig.activeInstance.url + getConfigFieldPickListRequestURL(
        configuredField.fieldType,
        configuredField.entityName,
        configuredField.fieldName
      );
      const headers = new HttpHeaders().set('X-SystemUserId', this.authenticationService.user.xSystemUserID);

      const response: ConfigFieldOptionResponse = await this.http.get<ConfigFieldOptionResponse>(url, { headers }).toPromise();

      if (response) {
        const result: ConfigFieldOptionValue[] = parseConfigFieldPicklistResponse(
          response,
          configuredField.entityName,
          configuredField.fieldName,
          configuredField.fieldType,
          configuredField.fieldLabel,
        );

        if (Array.isArray(result)) {
          const options = { [configuredField.fieldName]: result };

          console.log(options);


          // Keep in the memory
          // this.eventsToolService.configFieldOptionsValues = { ...this.eventsToolService.configFieldOptionsValues, ...options };

          this.configFieldOptionsValues = { ...this.configFieldOptionsValues, ...options };

          // Upsert DB
          const dbId: string = DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS + configuredField.fieldName;
          try {
            await this.disk.updateOrInsert(dbId, doc => ({ options }));
          } catch (error) {
            console.error('fetchConfigFieldOptionSetValues: DB upsert: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
          }

          syncInfo.totalSynced = result.length;
        }
      }
    } catch (error) {
      console.error('fetchConfigFieldOptionSetValues: ', error);
      this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
      const localData = await this.loadOfflineConfigFieldOptionSetValues(configuredField.fieldName);
      if (localData) {
        this.configFieldOptionsValues = { ...this.configFieldOptionsValues, ...localData };
      }
    }
    this.deltaService.addEntitySyncInfo(syncInfo);
  }

  private async loadOfflineConfigFieldOptionSetValues(fieldName?: string): Promise<any> {
    let dbId = DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS;
    let options: any;

    if (fieldName) {
      //   // Retrieve one field's record
      try {
        dbId = dbId + fieldName;
        const doc = await this.disk.retrieve(dbId, true);
        if (doc?.hasOwnProperty('options')) {
          options = doc.options;
        }
      } catch (error) {
        console.error(`loadOfflineConfigFieldOptionSetValues: retrieve ${fieldName}: `, error);
      }
    } else {
      //   // Retrieve all fields records
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS,
            $lte: DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };

      try {
        const localRecords: { options: any }[] = await this.disk.find(option);
        if (Array.isArray(localRecords)) {
          options = localRecords.reduce((acc, cur) => {
            if (cur.hasOwnProperty('options')) {
              acc = {
                ...acc,
                ...cur.options,
              };
            }
            return acc;
          }, {});
        }
      } catch (error) {
        console.error('loadOfflineConfigFieldOptionSetValues: retrieve all: ', error);
      }
    }

    return options;
  }

  generatePayloadForConfiguredFields(inputValue, fieldName, fieldType) {
    let payload: any = {
      "fieldname": fieldName,
      "datatype": fieldType,
      "value": inputValue
    }

    if (fieldType === 'DateTime') {
      const offsetDate = new Date(inputValue);
      const tzIndependentDateObj = new Date(
        offsetDate.getUTCFullYear(),
        offsetDate.getUTCMonth(),
        offsetDate.getUTCDate(),
        offsetDate.getUTCHours(),
        offsetDate.getUTCMinutes(),
        0,
        0
      );
      payload.value = inputValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
      // payload.appconfigfields[0].value = inputValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
    } else if (fieldType === 'Lookup') {
      // payload.appconfigfields[0] = { ...payload.appconfigfields[0] , ...payload.appconfigfields[0].value };
      payload = { ...inputValue }
    }

    return payload;
  }

  //------- config fields related methods --------//

}
