import { Pipe, Injectable, PipeTransform } from "@angular/core";
import { Contact } from "../classes/contact/contact.class";

@Pipe(
    {
        name:'contactSearch'
    }
)
@Injectable({
  providedIn: 'root'
})
export class ContactSearchPipe implements PipeTransform{
    transform(contacts:Contact[],searchText:string,filterMetadata): Contact[]{

        let filteredContacts: Contact[];

        if(searchText && contacts){
            searchText = searchText.toUpperCase();
            filteredContacts = contacts.filter(contact => {
                let contactString = this.getOjectValues(contact); // JSON.stringify(contact).toUpperCase();

                return contactString.includes(searchText);
                /*let name = ((contact.firstName) ? contact.firstName + ' ' : '') + ((contact.lastName) ? contact.lastName : '');
                return ((name.toUpperCase().includes(searchText) || 
                        contact.speciality.toUpperCase().includes(searchText) || 
                        contact.productSegmentations.some((segment) => {
                            return segment.segmentation && segment.segmentation.toUpperCase().includes(searchText)
                        }) || 
                        contact.addressesList.some((addObj) => {
                            let compositeAdd = addObj.city + ' ' + addObj.postal;
                            return compositeAdd && compositeAdd.toUpperCase().includes(searchText)
                        }) ||
                        contact.customerPositions.some(pos=> pos.position && pos.position.toUpperCase().includes(searchText))) && !contact.isguest);*/
            });
        }else{
            return contacts;
        }
        filterMetadata.count = filteredContacts.length;
        
        return filteredContacts;
    }

    getOjectValues(conObject):string{
        let objString:string[]=[];
        let propNotInclude = ['ID','activitesByContact','configuredFields','connectionState','repCallPlansByContact','title','titleFormattedValue','trackAction','trackActionFormattedValue'];
        for(let contact in conObject){
            if(propNotInclude.indexOf(contact) == -1 && conObject[contact]){
                if(Array.isArray(conObject[contact])){
                    for(let i=0; i<conObject[contact].length;i++){
                        objString.push(this.getOjectValues(conObject[contact][i]));
                    }
                }else{
                    objString.push(conObject[contact]);
                }
            }
        }

        return objString.toString().toUpperCase();

    }
}