import { ResourceService } from './../../services/resource/resource.service';
import { Injectable } from '@angular/core';
import { Endpoints } from '../../../config/endpoints.config';
import { DiskService } from '../../services/disk/disk.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../services/logging/log-service';
import { IResource, Resource, OfflineResource } from '../../classes/resource/resource.class';
import { AuthenticationService } from '../../services/authentication.service';
import { EntitySyncInfo, EntityNames, DeltaService } from '../delta/delta.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS } from '../../config/pouch-db.config';
import { TrackAction } from '../../utility/common-enums';
import { Store } from '@ngrx/store';
import { ResourceState } from '../../store/application.state';
import * as ResourceAction from '../../store/io-file-service/actions/resource.actions';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import {MyAssistantService, NOTIFICATION} from '../../services/my-assistant/my-assistant.service';
import { TranslateService } from '@ngx-translate/core';
import { IoFileService } from '../../services/io-file-service/io-file-service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { EntityTag, TagEntityType, TagStateCode, UserTag, UserTagService } from '@omni/services/user-tag/user-tag.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { CONTACT_FETCH_QUERIES } from '@omni/config/fetch-xml/contact-fetchXMLs';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { isEmpty, uniqBy } from 'lodash';
import { DeviceService } from '@omni/services/device/device.service';
import moment from 'moment';



/**
 * Data service for Presentation
 *
 * @export
 * @class PresentationDataService
 */
@Injectable({
  providedIn: 'root'
})
export class ResourceDataService {

    private _isInitialMappingDone: boolean = false;
    private resourcesNotificationModel: IndNotificationDataModel;
    constructor(
        private disk: DiskService,
        private http: HttpClient,
        private resourceService: ResourceService,
        private logService: LogService,
        private authenticationService: AuthenticationService,
        private deltaService: DeltaService,
        private store: Store<ResourceState>,
        private assistantService: MyAssistantService,
        private notificationService: NotificationService,
        public translate: TranslateService,
        private readonly userTagService: UserTagService,
        private ioFileService: IoFileService,
        private dynamicsService: DynamicsClientService,
        private deviceService: DeviceService,
        private authService: AuthenticationService,
    ) {

    }

    /**
     * Returns an array of presentation from either our disk or network depending on cache expiry time
     *
     * @memberof PresentationDataService
     */
    async getResources(loadFromDbOnly = false) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL)) {
            this.resourceService.clearAllResources();
            return;
        }
        this.deltaService.pushSyncEntityName(SyncFeatureCategory.files);
        if (loadFromDbOnly) {
            await this.loadOfflineResources();
        } else {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_RESOURCE);
        const isDeltaSync = syncState && syncState.lastUpdatedTime && !this.authenticationService.shouldContentFullSync;

        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.resources.GET_RESOURCES;
        // let url ='http://localhost:8080/api/bms/resources';
        let params = {
            positionIds: this.authenticationService.user.positions.map((o) => {
                return o.ID
            })
        }
        url = url + "?positionIds=" + params.positionIds;
        url = isDeltaSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

        const resourceSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.resource,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };

        let response: any;
        try {
            response = await this.http.get<IResource[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
        } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(resourceSyncInfo, url, error);
        }

        if (response) {
            if (!this._isInitialMappingDone) {
                try {
                    const offlineresource = await this.disk.retrieve('offlineresource', true);
                    if (offlineresource) {
                        this.mapOfflineResources(offlineresource.offlineresource);
                    } else {
                        this.resourceService.offlineResources = [];
                    }
                    const raw = await this.disk.retrieve(DB_KEY_PREFIXES.RESOURCE);
                    if (raw) {
                        this.mapResources(raw.raw, true);
                        this._isInitialMappingDone = true;
                    }
                } catch (error) {
                    this.logService.logError('Error occured while fetching resources from offline db');
                }
            }
            const newLastUpdatedTime = new Date().getTime();
            if (isDeltaSync) {
                await this.mapDeltaSyncedResources(response);
            } else {
                this.mapResources(response, false);
            }
            // Done sync. Update sync state.
            if (resourceSyncInfo.syncStatus) {
                syncState.lastUpdatedTime = newLastUpdatedTime;
                resourceSyncInfo.totalSynced = response.length;
                await this.disk.updateSyncState(syncState);
            }
            this.deltaService.addEntitySyncInfo(resourceSyncInfo);

            this.disk.retrieve(DB_KEY_PREFIXES.RESOURCE_RECENT_SEARCHES, true).then((doc)=>{
                if(doc && doc.raw){
                  this.resourceService.recentSearches = doc.raw
                }
                else {
                  this.resourceService.recentSearches = [];
                }
              })
        }
        }
    }

    public mapOfflineResources(raw: any) {
        if (Array.isArray(raw)) {
            this.resourceService.refreshOfflineResourceList(raw);
        } else {
            console.error('Unsupported data format for presentations!');
        }
    }

    public async mapDeltaSyncedResources(raw: IResource[]) {
        let dbResources = await this.getResourcesFromDB();
        let notifResources: Resource[] = [];
        if (raw && Array.isArray(raw)) {
            if (raw.length == 0) {
                //display offline saved new resources which were not visited bfr
                if (dbResources && Array.isArray(dbResources)) {
                    let newResources = dbResources.filter((savedRes) => {
                        return savedRes.isNew;
                    });
                    if (newResources && newResources.length > 0) {
                        console.log("Not visited new resources", newResources);
                        newResources.forEach(nr => {
                            this.deltaService.deltaRecordsDTO.resources.push(nr['indskr_ioresourceid']);
                        });
                    }
                }
                return;
            } else {
                for (let i = 0; i < raw.length; i++) {
                    let dtoidtobeused: string;
                    let idtobeused: string;
                    if (raw[i] && raw[i].indskr_ioresourceid) {
                        dtoidtobeused = 'indskr_ioresourceid';
                        idtobeused = 'ioResourceId';
                    } else if (raw[i] && raw[i].indskr_iodocumentid) {
                        dtoidtobeused = 'indskr_iodocumentid';
                        idtobeused = 'ioDocumentId';
                    } else {
                        console.log('Unhandled resource id for' + raw[i]);
                    }
                    const rawResource = raw[i];
                    if (rawResource.track_action && rawResource.track_action == TrackAction.Deleted) {
                        const idx = this.resourceService.allResources.findIndex(a => a[idtobeused] == rawResource[dtoidtobeused]);
                        const dbIdx = dbResources.length > 0 ? dbResources.findIndex(a => a[dtoidtobeused] == rawResource[dtoidtobeused]) : -1;
                        const offdbidx = this.resourceService.offlineResources.findIndex(a => a.ioResourceId === rawResource[dtoidtobeused]);
                        if (idx >= 0) {
                            this.resourceService.allResources.splice(idx, 1);
                        }
                        if (dbIdx >= 0) {
                            dbResources.splice(dbIdx, 1);
                        }
                        if (offdbidx >= 0) {
                            await this.deleteDownloadedResource(rawResource[dtoidtobeused]);
                        }
                    }
                    else if (!isNaN(parseInt(rawResource.statecode))) {
                        const idx = this.resourceService.allResources.findIndex(a => a[idtobeused] == rawResource[dtoidtobeused]);
                        const dbIdx = dbResources.length > 0 ? dbResources.findIndex(a => a[dtoidtobeused] == rawResource[dtoidtobeused]) : -1;
                        const offdbidx = this.resourceService.offlineResources.findIndex(a => a.ioResourceId === rawResource[dtoidtobeused]);
                        if (idx >= 0) {
                            // Update existing Resource or Documents if statuscode == 1(Published) update existing data else remove
                            if (parseInt(rawResource.statuscode) == 1) {
                                // Active
                                const resource = new Resource(rawResource);
                                if (rawResource['isNew']) {
                                    this.deltaService.deltaRecordsDTO.resources.push(rawResource[dtoidtobeused]);
                                }
                                if (offdbidx >= 0) {
                                    resource.downloaded = true;
                                }
                                if (dbIdx >= 0) {
                                    dbResources[dbIdx] = rawResource;
                                } else {
                                    // Shouldn't fall here..
                                    console.warn('mapDeltaSyncedResources: Could not find a record from db.. Cannot Replace ', rawResource);
                                }
                                this.resourceService.allResources[idx] = resource;
                                this.resourceService.mapResourceFieldsToSearchIndex(resource);
                            } else if (parseInt(rawResource.statuscode) !== 1 ) {
                                // Removed / Inactive
                                if (dbIdx >= 0) {
                                    dbResources.splice(dbIdx, 1);
                                } else {
                                    // Shouldn't fall here..
                                    console.warn('mapDeltaSyncedResources: Could not find a record from db.. Cannot Remove ', rawResource);
                                }
                                if (offdbidx >= 0) {
                                    await this.deleteDownloadedResource(rawResource[dtoidtobeused]);
                                }
                                this.resourceService.allResources.splice(idx, 1);
                            } else {
                                console.warn('mapDeltaSyncedResources: Invalid statuscode ' + parseInt(rawResource.statuscode), rawResource.indskr_ioresourceid);
                            }
                        } else {
                            // New Resource or Documents if statuscode == 1(Published) add to local data
                            if (parseInt(rawResource.statuscode) == 1) {
                                const contact = new Resource(rawResource);
                                dbResources.push(rawResource);
                                this.resourceService.allResources.push(contact);
                                rawResource['isNew'] = false;
                                this.deltaService.deltaRecordsDTO.resources.push(rawResource[dtoidtobeused]);
                                notifResources.push(contact);
                            }

                            this.resourceService.mapResourceFieldsToSearchIndex(new Resource(rawResource));
                        }
                    }
                }
                //Save to DB or update
                await this.saveOrUpdateResourcesToDB(dbResources);
            }
            if (notifResources.length) {
              let displayName = notifResources.length == 1 ? this.translate.instant("RESOURCE_LOWER") : this.translate.instant("RESOURCES");
              let showCount = notifResources.length == 1 ? '' : notifResources.length;
              this.resourcesNotificationModel = {
                type: NOTIFICATION.NEW_RESOURCES_NOTIFICATION,
                name: this.translate.instant("NEW_RESOURCES_NOTIFICATION", {count: showCount, resource: displayName}),
                DateTime: Date.now(),
                id: NOTIFICATION.NEW_RESOURCES_NOTIFICATION + Date.now(),
                data: {
                  data: notifResources
                },
                icon: 'assets/imgs/tools_resources.svg',
                isRed: false,
                params: {count: showCount, resource: displayName}
              }
              this.assistantService.saveNotificationToDisk(this.resourcesNotificationModel);
            }
        }
    }

    public async saveOrUpdateResourcesToDB(dbResources: any[]) {
        if (dbResources.length > 0) {
            try {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.RESOURCE, (doc) => {
                    if (!doc || !doc.raw) {
                        doc = {
                            raw: []
                        };
                    }
                    doc.raw = dbResources;
                    return doc;
                });
            }
            catch (error) {
                console.error('mapFullSyncedResources: ', error);
            }
        }
    }

    public async getResourcesFromDB() {
        let dbResources = [];
        try {
            let rawResourcesDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.RESOURCE);
            if (rawResourcesDoc && rawResourcesDoc.raw && Array.isArray(rawResourcesDoc.raw)) {
                dbResources = rawResourcesDoc.raw;
            }
        }
        catch (error) {
            console.warn('mapDeltaSyncedResources: No data to load');
        }
        return dbResources;
    }

    public async mapResources(raw: IResource[], doNotSave?: boolean) {
        if (Array.isArray(raw)) {
            this.resourceService.refreshResourceList(raw);
        } else {
            console.error('Unsupported data format for resources!');
            return;
        }

        if (!doNotSave) {
            //Save to DB or update
            try {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.RESOURCE, doc => ({ raw }));
                this.logService.logInfo("Successfully saved resources");
            } catch (error) {
                this.logService.logError(`Failed saving resource. Error: ${error}`);
            }
        }
    }

    public async loadOfflineResources() {
        //if (this.disk.check('resources')) {
        console.log("Offline resources");
        const offlineresource = await this.disk.retrieve('offlineresource');
        if (offlineresource) {
            this.mapOfflineResources(offlineresource.offlineresource);
        } else {
            this.resourceService.offlineResources = [];
        }
        const resourcesDoc = await this.disk.retrieve(DB_KEY_PREFIXES.RESOURCE, true);
        if (resourcesDoc && resourcesDoc.raw) {
            this.mapResources(resourcesDoc.raw, true);
        }
        this._isInitialMappingDone = true;
        //}
    }

    public saveResourceForOffline(resourceId: string, downloaded: boolean) {
        let resourceOffline: OfflineResource = null;
        let resource: Resource = this.resourceService.getResourceById(resourceId, resourceId);
        if (resource) {
            if (!downloaded) {
                console.log("Deleting ", resource.title);
                resource.downloaded = false;
                this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + resource.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
                setTimeout(() => { this.store.dispatch(new ResourceAction.downloadResourceDequeue()) }, 500);
                return;
            }
            resourceOffline = {
                ioResourceId: resource.ioResourceId ? resource.ioResourceId : resource.ioDocumentId,
                name: resource.title,
                downloaded: downloaded,
                downloadDate: new Date()
            }
        }
        this.resourceService.offlineResources.push(resourceOffline);
        this.disk.retrieve('offlineresource').then(async (resources) => {
            let resourceArray = [];
            if (resources) {
                resources.offlineresource.push(resourceOffline);
                resourceArray = resources.offlineresource;
            } else {
                resourceArray = [resourceOffline];
            }
            this.disk.updateOrInsert('offlineresource', doc => ({ offlineresource: resourceArray }));
            this.store.dispatch(new ResourceAction.downloadResourceDequeue());
            resource.downloaded = true;

        }).catch(diskError => {
            this.store.dispatch(new ResourceAction.downloadResourceDequeue());
            console.error('Caught disk error trying to load resources from disk', diskError);
        });
        // this.assistantService.addNewNotification(resource.title + this.translate.instant('PRESENTATION_DOWNLODING_FINISHED'));
        this.notificationService.notify(resource.title + this.translate.instant('PRESENTATION_DOWNLODING_FINISHED'), "Resource Service")
        console.log('KB: resource download end', new Date());
    }

    public deleteDownloadedResource(resourceId: string) {

        let resource: Resource = this.resourceService.getResourceById(resourceId, resourceId);
        if (resource) resource.downloaded = false;
        const temp = this.resourceService.getOfflineResourceById(resourceId);
        if (temp) {
            const index = this.resourceService.offlineResources.indexOf(temp);
            this.resourceService.offlineResources.splice(index, 1);
        }
        try {
            this.ioFileService.deleteDownloadedResource(resourceId);
            this.disk.updateOrInsert('offlineresource', doc => ({ offlineresource: this.resourceService.offlineResources }))
        } catch (error) {
            console.log("Error while deleteing resource " + resource.title);
        }

    }
    async syncResourceTag(loadFromDbOnly = false) {
      let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
      if (offlineFallback) {
        await this.loadResourceTagFromDB();
      } else {
        const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_RESOURCE_TAG);
        const isInitialSync = !syncState || !syncState.lastUpdatedTime;
        let lastUpdatedTime = syncState?.lastUpdatedTime;
        const resourceTagsSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.resource,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        if (isInitialSync) {
          // await this.resourceTagInitialSync();
        } else {
          // await this.resourceTagDeltaSync(lastUpdatedTime)
        }
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(this.userTagService.resourceTag)) {
          resourceTagsSyncInfo.totalSynced = this.userTagService.resourceTag.length;
        }
        this.deltaService.addEntitySyncInfo(resourceTagsSyncInfo);
      }
    }
  
    // async resourceTagDeltaSync(lastUpdatedTime: any) {
    //   await this.loadResourceTagFromDB();
    //   const positionIds = this.authService.user.positions.map(o => {
    //     return o.ID
    //   });
    //   const buId = this.authService.user.buConfigs.indskr_bulevelconfigurationid;
    //   let positionString = '';
    //   positionIds.forEach(p => {
    //     positionString += '<value>' + p + '</value>';
    //   })
    //   let PrivateTagfetchXml = fetchQueries.resource.updatedResourcePrivateTags;
    //   let publicTagfetchXml = fetchQueries.resource.updatedResourcePublicTags;
    //   let assignTagfetchXml = fetchQueries.resource.updatedAssignedResourceTag;
    //   publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
    //   assignTagfetchXml = assignTagfetchXml.replace(/{positionIDs}/g, `${positionString}`);
    //   lastUpdatedTime = moment(parseInt(lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    //   PrivateTagfetchXml = PrivateTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    //   publicTagfetchXml = publicTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    //   assignTagfetchXml = assignTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    //   let deletedUserTagsFetchXml = CONTACT_FETCH_QUERIES.deletedUserTagsFetchXml;
    //   deletedUserTagsFetchXml = deletedUserTagsFetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    //   await Promise.all([
    //     this.dynamicsService.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
    //     this.dynamicsService.executeFetchQuery('indskr_usertags', publicTagfetchXml),
    //     this.dynamicsService.executeFetchQuery('indskr_usertags', assignTagfetchXml),
    //     this.dynamicsService.executeFetchQuery('indskr_trackchanges', deletedUserTagsFetchXml)
    //   ]).then(response => {
    //     let privateTag = response[0];
    //     let publicTag = response[1];
    //     let assign = response[2];
    //     let updateResourceTags = privateTag.concat(publicTag,assign);
    //     updateResourceTags.forEach(record => {
    //       if (!record['indskr_externalid']) {
    //         record['indskr_externalid'] = record['indskr_usertagid'];
    //       }
    //     });
    //     let deletedResourceTags = response[3];
    //     if (!isEmpty(deletedResourceTags)) {
    //       deletedResourceTags.forEach(async trackChangedTag => {
    //         // let index = this.userTagService.resourceTag
    //         //   .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
    //         // if (index >= 0) {
    //         //   await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.resourceTag[index].indskr_externalid);
    //         //   this.userTagService.resourceTag.splice(index, 1);
    //         // }
    //         let index = this.userTagService.accountTags
    //         .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
    //         if (index >= 0) {
    //           await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.accountTags[index].indskr_externalid);
    //           this.userTagService.accountTags.splice(index, 1);
    //         }
    //         });
    //     }
    //     if (!isEmpty(updateResourceTags)) {
    //       let resourceIdList = this.getresourceIdsForTagIds(updateResourceTags)
    //       let resourceTagDetails: UserTag[] = updateResourceTags.map((tag) => {
    //         return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, resourceIdList[tag.indskr_usertagid], false, tag.statecode, TagEntityType.RESOURCE, tag.indskr_visibility, tag.indskr_filter)
    //       });
    //       resourceTagDetails = uniqBy(resourceTagDetails, 'indskr_usertagid');
    //       resourceTagDetails.forEach(async (tagDetails) => {
  
    //         const checkTagIndex = this.userTagService.resourceTag.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
    //         if (checkTagIndex > -1) {
    //           this.userTagService.resourceTag.splice(checkTagIndex, 1);
    //         }
    //         // if Tag data is updated with resource
    //         if (tagDetails.stateCode === TagStateCode.Active) {
    //           this.userTagService.resourceTag.push(tagDetails);
    //           await this.disk.upsertUserTag(tagDetails, TagEntityType.RESOURCE);
    //         } else {
    //           await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
    //         }
    //       });
    //       this.userTagService.resourceTag = _.uniqBy(this.userTagService.resourceTag,"indskr_usertagid");
    //       this.userTagService.resourceTag = this.userTagService.sortUserTags(this.userTagService.resourceTag);
    //     }
    //   })
    //     .catch((e) => { console.error('uploadOfflineData: ', e); return false; });
    // }
  
    // async resourceTagInitialSync() {
    //   try {
    //     const positionIds = this.authService.user.positions.map(o => {
    //       return o.ID
    //     });
    //     const buId = this.authService.user.buConfigs.indskr_bulevelconfigurationid;
    //     let positionString = '';
    //     positionIds.forEach(p => {
    //       positionString += '<value>' + p + '</value>';
    //     })
    //     await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG);
    //     let PrivateTagfetchXml = fetchQueries.resource.fetchResourcePrivateTags;
    //     let publicTagfetchXml = fetchQueries.resource.fetchResourcePublicTags;
    //     let assignTagfetchXml = fetchQueries.resource.fetchResourcePositionTags;
    //     publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
    //     assignTagfetchXml = assignTagfetchXml.replace(/{positionIDs}/g, `${positionString}`);
    //     await Promise.all([
    //       this.dynamicsService.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
    //       this.dynamicsService.executeFetchQuery('indskr_usertags', publicTagfetchXml),
    //       this.dynamicsService.executeFetchQuery('indskr_usertags', assignTagfetchXml),
    //     ]).then(async response => {
    //       let privateTag = response[0];
    //       let publicTag = response[1];
    //       let assignTag = response[2];
    //       const resourceTagDetailsResponse = privateTag.concat(publicTag,assignTag)
    //       resourceTagDetailsResponse.forEach(record => {
    //         if (!record['indskr_externalid']) {
    //           record['indskr_externalid'] = record['indskr_usertagid'];
    //         }
    //       });
    //       let resourceIdList = this.getresourceIdsForTagIds(resourceTagDetailsResponse)
    //       let resourceTagDetails = resourceTagDetailsResponse.map((tag) => {
    //         return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
    //           resourceIdList[tag.indskr_usertagid], false, TagStateCode.Active, TagEntityType.RESOURCE, tag.indskr_visibility,tag.indskr_filter)
    //       });
    //       resourceTagDetails = uniqBy(resourceTagDetails, 'indskr_usertagid');
    //       this.userTagService.resourceTag = resourceTagDetails;
    //       this.userTagService.resourceTag = this.userTagService.sortUserTags(this.userTagService.resourceTag);
    //       await this.disk.upsertUserTagListToDisk(resourceTagDetails)
    //     })
    //   }
    //   catch (e) {
    //     console.log(e);
    //   };
    // }
    
    private async loadResourceTagFromDB() {
      await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG).then((data: UserTag[]) => {
        //check for delete true
        this.userTagService.resourceTag = data.filter(a => a.entity == TagEntityType.RESOURCE);
      });
    }
    private getresourceIdsForTagIds(updateResourceTags: any[]) {
      return updateResourceTags.reduce((data, val) => {
        data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
        let resourceId = new EntityTag(val["resourceId"]);
        let documentId = new EntityTag(val["documentId"]);
        if (resourceId || documentId) {
          if (resourceId.id) {
            data[val.indskr_usertagid].push(resourceId)
          }
          if (documentId.id) {
            data[val.indskr_usertagid].push(documentId)
          }
        } else if (!data[val.indskr_usertagid].length) {
          data[val.indskr_usertagid] = []
        }
        return data;
      }, {});
    }
  
}
