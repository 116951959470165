import { BehaviorSubject, Observable } from 'rxjs';

export class State<I, T> {
  private _id: I;
  private _state: T;
  private _state$: BehaviorSubject<T>;
  readonly stateObservable: Observable<T>;

  constructor(id: I, initState: T) {
    this._id = id;
    this._state = initState;
    this._state$ = new BehaviorSubject(this._state);
    this.stateObservable = this._state$.asObservable();
  }

  get id(): I {
    return this._id;
  }

  get state(): T {
    return this._state;
  }
  setState(newFetchState: T): boolean {
    if (newFetchState) {
      this._state = newFetchState;
      this._state$.next(this._state);
      // console.log(`### State <${this._id}> set to: ${this._state}`);
      return true;
    } else {
      return false;
    }
  }
}
