<ion-header>
    <ind-page-title [viewData]="templateDetailsPageTitle" (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
</ion-header>
<ion-content class="ion-no-padding" *ngIf="template">
    <ind-section-header [viewData]='{id:"email-template-details", title: translate.instant("TEMPLATE_CAP")}'>
    </ind-section-header>
    <ng-container>
        <div [innerHTML]="preview" class="emailTempContainer" (click)="openUrl($event,contentBody)" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}"></div>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'email-templates'"></footer-toolbar>
<!-- </ion-footer> -->
