<ion-header class="alert-option">
  <ion-toolbar class="title-toolbar">
    <ion-title class="ion-text-center"> {{header}} 
      <ion-icon class='mandatory-icon' *ngIf="isMandatory && !message"></ion-icon>
    </ion-title>
  </ion-toolbar>
  <ion-title size="small" class="message-text" *ngIf="message">
    <ion-label>{{message}}</ion-label>
    <ion-icon class='mandatory-icon' *ngIf="isMandatory"></ion-icon>
  </ion-title>
</ion-header>


<ion-content>
  <ion-list mode="ios" class="input" *ngIf="inputs.length > 0">
    <ion-radio-group [ngModel]="selectedItem" *ngIf="!enableMultiSelect">
      <div *ngFor="let input of inputs">
        <ion-item lines="none">
          <ion-label>{{input.label}}</ion-label>
          <ion-radio slot="end" [value]="input.value" (click)="onSelectOption(input)"></ion-radio>
        </ion-item>
        <div class="separationLine"></div>
        <ion-item
          *ngIf="selectedItem && selectedItem.name === 'Others' && input.name === 'Others' || inputs.length == 0"
          lines="none">
          <ion-input type="text" [placeholder]="Placeholder" [(ngModel)]="selectedOtherItem" class="input-text">
          </ion-input>
        </ion-item>
      </div>
    </ion-radio-group>
    <ng-container *ngIf="enableMultiSelect">
      <div *ngFor="let input of inputs">
        <ion-item lines="none" (click)="onMultiSelectOption(input)">
          <ion-label>{{input.label}}</ion-label>
          <ion-icon slot="end" color="primary" name="checkmark" *ngIf="input.isSelected"></ion-icon>
        </ion-item>
        <div class="separationLine"></div>
        <ion-item
          *ngIf="enableOtherItemTextBox && (input.name === 'Others' || inputs.length == 0)"
          lines="none">
          <ion-input type="text" [placeholder]="Placeholder" [(ngModel)]="selectedOtherItem" class="input-text">
          </ion-input>
        </ion-item>
      </div>
    </ng-container>
  </ion-list>

  <ion-item *ngIf="inputs.length == 0" lines="none">
    <ion-input type="text" [placeholder]="Placeholder" [(ngModel)]="selectedOtherItem" class="input-text"></ion-input>
  </ion-item>

  <ind-form-field *ngIf="customFormField" [viewData]='customFormField'></ind-form-field>

  <ion-row class="button-row">
    <ion-col class="first-col" size="6">
      <ion-button expand="block" fill="clear" size="small" (click)="onDismiss(false)" class="cancel-button">
        {{cancelText}}</ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button expand="block" fill="clear" [disabled]="inputs.length === 0 ? isMandatory && !selectedOtherItem : isMandatory && !selectedItem" size="small" (click)="onDismiss(true)" class="confirm-button">
        {{confirmText}}</ion-button>
    </ion-col>
  </ion-row>
</ion-content>