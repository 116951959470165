export const MOBILE_FORMS_FETCHXML = `<?xml version="1.0" encoding="UTF-8"?>
<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
	<entity name="indskr_mobileappform">
        <all-attributes />
        <filter type="and">
            <condition attribute="statecode" value="1" operator="eq" />
            {IdCondition}
		</filter>

		<link-entity name="indskr_searchentity" alias="indskr_searchentity" to="indskr_mobileappformid" from="indskr_mobileappformid" link-type="outer">
			<attribute name="indskr_searchentityid" />
			<attribute name="indskr_mobileformid" />
			<attribute name="indskr_mobileappformid" />
			<attribute name="indskr_attribute" />
			<attribute name="indskr_relatedentity" />
			<filter type="and">
				<condition attribute="statecode" value="0" operator="eq" />
			</filter>
 		</link-entity>

    <link-entity name="indskr_linkedentityformconfigurations" alias="indskr_linkedentityformconfigurations" to="indskr_mobileappformid" from="indskr_mobileappformid" link-type="outer">
			<attribute name="indskr_entityname" />
			<attribute name="indskr_linkedentityformconfigurationsid" />
			<attribute name="indskr_maximumnumberofrecordsallowed" />
			<attribute name="indskr_minimumnumberofrecordsallowed" />
			<attribute name="indskr_requirementtype" />
			<filter type="and">
				<condition attribute="statecode" value="0" operator="eq" />
			</filter>
 		</link-entity>

    <link-entity name="indskr_mobileappformfield" alias="indskr_mobileappformfield" to="indskr_mobileappformid" from="indskr_mobileappformid" link-type="outer">
      <attribute name="indskr_mobileappformid" />
      <attribute name="indskr_mobileappformfieldid" />
      <attribute name="indskr_mobileappformfieldname" />
      <attribute name="indskr_isrequired" />
      <filter type="and">
        <condition attribute="statecode" value="0" operator="eq" />
      </filter>
    </link-entity>

		<link-entity name="indskr_mobileapprole" alias="as" to="indskr_mobileappformid" from="indskr_mobileappform">
			<filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
        <condition attribute="indskr_businessunit" operator="eq" value="{BUID}" />
			</filter>
			<link-entity name="role" alias="at" to="indskr_securityrole" from="roleid">
				<link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">
					<link-entity name="systemuser" alias="au" to="systemuserid" from="systemuserid">
						<filter type="and">
							<condition attribute="systemuserid" operator="eq" value="{0}" />
						</filter>
					</link-entity>
				</link-entity>
			</link-entity>
		</link-entity>
	</entity>
</fetch>`

export const MOBILE_FORMS_DELTA = `<?xml version="1.0" encoding="UTF-8"?>
<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
	<entity name="indskr_mobileappform">
        <attribute name="indskr_mobileappformid" />
        <attribute name="indskr_name" />
        <attribute name="statecode" />
        <attribute name="statuscode" />
        {DeltaSyncFilter}
	</entity>
</fetch>`

export const MOBILE_FORMS_ID_FILTER = `
        <condition attribute="indskr_mobileappformid" operator="in" >
            {FormIds}
        </condition>`

export const MOBILE_FORMS_BUSINESS_RULES = `
  <fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
    <entity name="workflow">
      <attribute name="description"/>
      <attribute name="clientdata"/>
      <attribute name="processtriggerformid"/>
      <filter type="and">
        <condition attribute="processtriggerformid"  operator="in" >
          {FormIDS}
        </condition>
      </filter>
    </entity>
  </fetch>`
