import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { NavigationService, ChildNavNames, PageName } from '@omni/services/navigation/navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceEmailTemplate } from '../../../classes/email-templates/email-template.class';
import { DeviceService } from '../../../services/device/device.service';
import { EmailService } from '../../../services/email-templates/email.service';
import { EmailTemplateDetailsComponent } from '../email-template-details/email-template-details';
import { FooterService } from '@omni/services/footer/footer.service';

/**
 * Generated class for the EmailTemplatePageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'email-template-page[base-page]',
  templateUrl: 'email-template-page.html',
  styleUrls: ['email-template-page.scss']
})

export class EmailTemplatePageComponent {

  destroy$: Subject<boolean> = new Subject<boolean>();
  template: ResourceEmailTemplate;
  @ViewChild('emailtemplaterightpane', { static: true }) navCtrl: IonNav;
  showNothingSelectedView = true;

  constructor(
    public navService: NavigationService,
    private readonly emailService: EmailService,
    public device: DeviceService,
    private readonly footerService: FooterService
  ) { }

  ngOnInit() {

    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.EmailTemplateNavigation, PageName.EmailTemplatePageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.footerService.initButtons('');

    // const navOptions = { progressAnimation: true };
    this.emailService.reset();
    this.emailService.selectedTemplate.pipe(
      takeUntil(this.destroy$))
      .subscribe(template => {
        if (template) {
          this.showNothingSelectedView = false;
          this.template = template;
          this.gotoDetails();
        } else {
          this.showNothingSelectedView = true;
        }
        // this.navCtrl.setRoot(EmailTemplateDetailsComponent, { template });
        // this.navCtrl.popToRoot();
        // this.navCtrl.popToRoot(navOptions);
      });
  }

  private gotoDetails() {
    this.navService.setChildNavRoot(EmailTemplateDetailsComponent, PageName.EmailTemplateDetailsComponent, PageName.EmailTemplatePageComponent, { template: this.template });
    this.navService.setChildNavRightPaneView(true);
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.EmailTemplateNavigation)
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
