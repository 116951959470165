export class ActivityPresentationSlideContact{
    public contactid:string;
    public pagesentiment:string;
    public indskr_pagestartdate: string;
    public indskr_pageenddate: string;

    constructor(raw:object){
        this.contactid = raw['indskr_contactid'];
        this.pagesentiment = raw['indskr_pagesentiment'];
        this.indskr_pagestartdate = raw['indskr_pagestartdate'];
        this.indskr_pageenddate = raw['indskr_pageenddate'];
    }

    public get DTO():Object{
        let dto = {
            indskr_contactid : this.contactid,
            indskr_pagesentiment: this.pagesentiment,
        }
        if (this.indskr_pagestartdate) dto['indskr_pagestartdate'] = this.indskr_pagestartdate;
        if (this.indskr_pageenddate) dto['indskr_pageenddate'] = this.indskr_pageenddate;
        return dto;
    }
}