<ion-header>
    <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <ion-toolbar>
        <ind-tabs [data]="tabsData" [(selectedTab)]="opportunityDetailsSegment" (selectedTabChange)="segmentClicked($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div [ngSwitch]="opportunityDetailsSegment" class="switchConditionContainer" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <opportunity-info *ngSwitchCase="'info'" [opportunity]="opportunity" (handleUpdates)="updateDescription($event)" (isSurveyValidEvent)="captureIsSurveyValidEvent($event)"></opportunity-info>
        <opportunity-quotes *ngSwitchCase="'quotes'" [opportunity]="opportunity"></opportunity-quotes>
        <opportunity-agreements *ngSwitchCase="'agreement'" [opportunity]="opportunity"></opportunity-agreements>
        <opportunity-orders *ngSwitchCase="'orders'" [opportunity]="opportunity"></opportunity-orders>
    </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'opportunityDetails'"></footer-toolbar>
<!-- </ion-footer> -->
