<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" 
    [(searchText)]="searchInput" (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="handleSearch()"
    (onSearchCancel)="onSearchCancel()" [hideFilterButton]="false" (onFilterClick)="openMedicalInsightsFilter()"></ind-header-left>
  <ion-toolbar>
    <ind-tabs [data]="tabsData" [(selectedTab)]="medicalInsightListMode"
      (selectedTabChange)="segmentChanged($event)"></ind-tabs>
  </ion-toolbar>

  <div class="selectedSearchSuggestions" color="light" *ngIf="selectedFilterData && selectedFilterData.length ">
    <span *ngFor="let filter of filterNameBubble; let i=index">
      <ion-text class="category-name"> {{filter.categoryName}} : </ion-text>
      <span *ngFor="let facet of filter.suggestion" class="suggestionNameBubble">
        {{facet.selectedFacet}}
        <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(facet, filter)" />
      </span>
    </span>
  </div>


</ion-header>
<ion-content #Content [ngClass]="{'vectorimageleft': !searchInput && listCount == 0}">
  <div>
    <ind-section-header [viewData]="listHeader"></ind-section-header>
    <ion-list *ngIf="listCount > 0" class="medical-insight-list"
      [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
      <div *ngFor="let item of filteredViewItemsList">
        <main-card [viewData]='item'></main-card>
      </div>
    </ion-list>
  </div>
  <ng-container *ngIf="searchInput && (listCount === 0)">
    <div *ngIf="searchInput">
      <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </div>
  </ng-container>
  <div class="no-data-message" *ngIf="!searchInput && isTeamTabActive && !listCount">
    {{(device.isOffline?'APPROVALS_ONLINE_ONLY':'NO_APPROVALS') | translate}}
  </div>

  <div class="no-data-message" *ngIf="!searchInput && !isTeamTabActive && !listCount">
    {{noItemsMessage | translate}}
  </div>
</ion-content>
<footer-toolbar [footerView]="'masterView'" [selectedView]="'contacts'"></footer-toolbar>