import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../config/endpoints.config';
import { LocationOfflineService } from "../../services/location/location.service";
import { DiskService } from '../../services/disk/disk.service';
import { GlobalErrorHandler } from '../../services/error-handler/error-handler-service';
import { AuthenticationService } from "../../services/authentication.service";
import { EntitySyncInfo, EntityNames, DeltaService } from "../delta/delta.service";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
    constructor(
        private http: HttpClient,
        private locationService: LocationOfflineService,
        private disk: DiskService,
        private globalErrHandler:GlobalErrorHandler,
        private authenticationService: AuthenticationService,
        private deltaService: DeltaService,
    ) {

    }
    
    /**
     * Calls a get endpoint for a list of locations that I have no idea what are used for
     * 
     * @memberof LocationService
     */
    public async getLocations(loadFromDbOnly = false) {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.locations.GET_LOCATIONS;
        
        if (loadFromDbOnly) {
            //Offline
            await this.disk.retrieve('locations').then(locations =>{
                if(locations) this.locationService.mapLocations(locations['raw']); // this is where the mapping happens
            }).catch(err =>{
                console.log('Error occured whle retreiving locations from disk'+err);
            });
        } else {
            //Network
            const locationSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.location,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try{
                let response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER)
                .toPromise();
                this.locationService.mapLocations(response);
            }catch(error){
                this.globalErrHandler.handleError(error);
                this.deltaService.addSyncErrorToEntitySyncInfo(locationSyncInfo, url, error);
            }

            this.deltaService.addEntitySyncInfo(locationSyncInfo);
        }
    }
    public  locationData():Array<string>{
             return this.locationService.getLocationData;
    }
    /**
     * Fetchs locations from disk instead of network
     * 
     * @memberof LocationService
     */
    public async getOfflineLocations() {
        //if(this.disk.check('locations')){
            await this.disk.retrieve('locations').then(locations=>{
                if(locations) this.locationService.mapLocations(locations['raw']);
            }).catch (diskError=> {
            console.error('Caught a disk error trying to load locations from disk', diskError);
            });
        //}
    }
}