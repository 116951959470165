import { Contact } from "../contact/contact.class";
import { Page } from "../presentation/presentation.class";
import { Specialty } from '../contact/contact.class';

export interface IResourceBrand {
  productId: string;
  productName: string;
  resourceid: string;
  therapeuticAreas: TherapeuticArea[];
}

export interface IResourcePositionGroup {
  positiongroupid: string;
  positiongroupname: string;
  resourceid: string;
}

export interface IResource {
  indskr_ioresourceid: string;
  indskr_iodocumentid: string;
  indskr_title: string;
  modifiedon: string;
  indskr_ckmcreateddate: string;
  indskr_ckmassettype: string;
  indskr_ckmtitle: string;
  createdon: string;
  indskr_ckmthumbnailurl: string;
  indskr_ckmasseturl: string;
  indskr_ckmassetid: string;
  _indskr_ckmcreatedbyid_value: string;
  indskr_ckmdescription: string;
  _indskr_ckmpublishedbyid_value: string;
  indskr_ckmpublisheddate: string;
  _indskr_ckmversionbyid_value: string;
  indskr_ckmversiondate: string;
  indskr_ckmversionid: string;
  indskr_description: string;
  _indskr_languageid_value: string;
  statecode: string;
  statuscode: string;
  brands: IResourceBrand[];
  indskr_availablefrom: string;
  indskr_availableuntil: string;
  track_action?: number;
  isNew: boolean;
  therapeuticAreas?: TherapeuticArea[];
  diseaseStates?: DiseaseState[];
  //    positionGroups: IResourcePositionGroup[]
}

export enum ResourceParticipantStatus {
  NOTSENT,
  SENT,
  RECEIVED,
  REJECTED,
  ERROR
}

export interface IMeetingResourceStatus {
  indskr_contactid: string;
  indskr_ioresourceid: string;
  indskr_iodocumentid: string;
  indskr_resultstatus: number;
  indskr_resulttime: string;
  indskr_sharedtime: string;
}

export class Resource {
  title: string;
  ioResourceId: string;
  ioDocumentId: string;
  contentId: string;
  createdOn: Date;
  modifiedOn: Date;
  assetType: string;
  fileType: string;
  thumbnailURL: string;
  assetURL: string;
  assetID: string;
  description: string;
  stateCode: number;
  statusCode: number;
  brands: Array<any>;
  //  positionGroups: Array<string>;
  raw: any;
  isSelected: boolean;
  downloaded: boolean;
  thumbnailFailed: boolean;
  name: string;
  isAutoLoaded: boolean = true;
  specialties: Specialty[] = [];
  therapeuticAreas? : TherapeuticArea[] =[];
  diseaseStates?: DiseaseState[] = [];
  showCustomSecInfo: boolean = false;
  secondaryInfo: string = '';
  public customePageLocation: string;

  get availableFrom(): Date {
    return this.raw.indskr_availablefrom && this.raw.indskr_availablefrom.toString().length > 0 ? new Date(parseInt(this.raw.indskr_availablefrom)) : null;
  }

  get availableUntil(): Date {
    return this.raw.indskr_availableuntil && this.raw.indskr_availableuntil.toString().length > 0 ? new Date(parseInt(this.raw.indskr_availableuntil)) : null;
  }

  constructor(raw: any) {
    this.raw = raw;
    this.title = raw.indskr_title || raw.indskr_ckmtitle;
    this.name = this.title;
    this.ioResourceId = raw.indskr_ioresourceid;
    this.ioDocumentId = raw.indskr_iodocumentid;
    this.contentId = this.ioResourceId ? this.ioResourceId : this.ioDocumentId;
    this.createdOn = new Date(parseInt(raw.createdon));
    this.modifiedOn = new Date(parseInt(raw.modifiedon));
    this.assetType = raw.indskr_ckmassettype;
    this.thumbnailURL = raw.indskr_ckmthumbnailurl;
    this.assetURL = raw.indskr_ckmasseturl;
    this.assetID = raw.indskr_ckmassetid;
    this.description = raw.indskr_description;
    this.therapeuticAreas = raw.therapeuticAreas || [];
    this.diseaseStates = raw.diseaseStates || [];
    this.stateCode = parseInt(raw.statecode);
    this.statusCode = parseInt(raw.statuscode);
    //    this.positionGroups = raw.positionGroups.map(p=>p.positiongroupname);
    this.brands = raw.brands ? raw.brands.map(p => p.productName) : [];
    this.downloaded = false;
    this.fileType = this.assetURL && this.assetURL.toLowerCase().endsWith(".pdf") ? "pdf" : (["video", "image"].indexOf(this.assetType) >= 0 ? this.assetType : "unsupported");
    if (Array.isArray(raw['specialties'])) {
      this.specialties = raw['specialties'].map((specialtyDTO: ResourceSpecialtyDTO) => ({ id: specialtyDTO.indskr_lu_specialtyid, name: specialtyDTO.indskr_specialty, code: specialtyDTO.indskr_specialtycode }));
    }
    if (this.diseaseStates && Array.isArray(raw['diseaseStates'])){
      this.diseaseStates = raw['diseaseStates'].map((diseaseState: DiseaseState) => ({
        diseaseStateId: diseaseState.diseaseStateId,
        diseaseStateName: diseaseState.diseaseStateName,
        resourceid: diseaseState.resourceid
      }));
    }
  }

  get DTO() {
    //TODO: Update after confirmation from dynamics team
    return {
      indskr_ioresourceid: this.ioResourceId,
      indskr_iodocumentid: this.ioDocumentId,
      indskr_name: this.title,
      indskr_productid: (this.brands ? (this.brands.length > 0 ? this.brands[0] : null) : null)
    }
  }

  get emailAttachmentDTO(): IEmailResourcePayload {
    let res: IEmailResourcePayload = {
      indskr_ioresourceid: this.ioResourceId,
      indskr_ckmthumbnailurl: this.thumbnailURL,
      indskr_ckmasseturl: this.assetURL,
      indskr_ckmassetid: this.assetID,
      indskr_availablefrom: this.availableFrom ? this.availableFrom.toDateString() : '',
      indskr_availableuntil: this.availableUntil ? this.availableUntil.toDateString() : '',
      indskr_ckmtitle: this.title,
      _indskr_ckmversionbyid_value: this.raw._indskr_ckmversionbyid_value,
      indskr_ckmversiondate: this.raw.indskr_ckmversiondate,
      indskr_name: this.raw.indskr_title,
      indskr_ckmversionid: this.raw.indskr_ckmversionid,
      indskr_iodocumentid: this.ioDocumentId,
    }
    for (let k in res) {
      if (res[k] === null || res[k] === undefined) {
        delete res[k];
      }
    }
    return res;
  }

  get page(): Page {
    return {
      name: this.title,
      id: this.ioResourceId ? this.ioResourceId : this.ioDocumentId,
      thumbnail: this.thumbnailURL,
      pageUrl: this.assetURL,
      indskr_isvirtualpage: false,
      indskr_virtualpagevalue: null,
      indskr_virtualpagetype: null,
      pageSequence: "1",
      keyMessage: [],
      sentimentImgURL: null,
      sentiment: null,
      cameFromCarousel: false,
      contentTypeResource: true,
      fileType: this.assetURL && this.assetURL.toLowerCase().endsWith(".pdf") ? "pdf" : (["video", "image"].indexOf(this.assetType) >= 0 ? this.assetType : "unsupported")
    }
  }
}

export type SharedResourceStatus = {
  resource: Resource;
  contact: Contact;
  statuses: { status: ResourceParticipantStatus; date: Date }[];
};


export interface IEmailResourcePayload {
  indskr_ioresourceid?: string;
  indskr_ckmthumbnailurl: string;
  indskr_ckmasseturl: string;
  indskr_ckmassetid: string;
  indskr_availablefrom: string;
  indskr_availableuntil: string;
  indskr_ckmtitle: string;
  _indskr_ckmversionbyid_value: string;
  indskr_ckmversiondate: string;
  indskr_name: string;
  indskr_ckmversionid: string;
  indskr_iodocumentid?: string;
}

export interface OfflineResource {
  ioResourceId: string;
  name: string;
  downloadDate: Date;
  downloaded: boolean;
}

export interface ResourceSpecialtyDTO {
  documentid: string;
  indskr_lu_specialtyid: string;
  indskr_specialty: string;
  indskr_specialtycode: string;
  presentationid: string;
}

export interface TherapeuticArea {
  productId: string;
  therapeuticAreaId: string;
  therapeuticAreaName: string;
}

export interface DiseaseState {
  diseaseStateId: string;
  diseaseStateName: string;
  resourceid: string;
}
