<ion-item-group>
    <!-- <ion-item-divider>
    {{'NEXT_MEETING_OBJECTIVES_CAP' | translate}} ({{nextObjectivesList.length}})
    <ion-button *ngIf="!isMeetingReadOnly" fill="clear" slot="end" (click)="saveObjective()"
      [disabled]="!isSaveObjectiveEnabled">{{'SAVE' | translate}}</ion-button>
    <ion-button *ngIf="!isMeetingReadOnly" fill="clear" slot="end" (click)="clearObjective()"
      [disabled]="!isSaveObjectiveEnabled">{{'CANCEL' | translate}}</ion-button>
  </ion-item-divider> -->

    <ind-section-header [viewData]="ncoHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

    <ind-text-area id="activity-detail-13" [disabled]="isMeetingReadOnly" [placeHolder]="'ADD_OBJECTIVE' | translate" [maxLength]="1000" [(value)]="newObjectiveInput" (indFocus)="onObjectiveFocus()" (indChange)="objectiveChanged()" [rows]="4" [cols]="10">
    </ind-text-area>
    <ion-list *ngIf="nextObjectivesList && nextObjectivesList.length>0">
        <ion-item-sliding #slidingItem (ionDrag)="onSlideDragging($event)" *ngFor="let objective of nextObjectivesList; index as i" id="{{ 'objective-' + i }}" [disabled]="objective.isComplete || isMeetingReadOnly || !isMyObjective(objective)">
            <ion-item>
                <div class="product-wrapper">
                    <div [id]="objective.externalid" class="round main-text-right">
                        <input [id]="objective.externalid" type="checkbox" [ngModel]="objective.isComplete" />
                        <label [for]="objective.externalid" [ngClass]="{'selected':objective.isComplete}" (click)="completeObjectiveTogggle(objective.externalid)">
              </label>
                        <span class="main-primary-text-right" style="margin-left: 15px;">{{objective.objective}}</span>
                        <div class="main-secondary-text-right" style="margin-left: 29px;"> {{getFormattedDateTime(objective.createdOn)}} </div>
                    </div>
                </div>
            </ion-item>
            <!-- <ion-item-options *ngIf="!objective.isComplete && !isMeetingReadOnly && objective.userId === this.authService.user.systemUserID;"> -->
            <ion-item-options>
                <ion-item-option color="dark" (click)="editObjective(objective.externalid, slidingItem)">
                    <ion-icon slot="top" class="indegene-edit"></ion-icon>
                    {{'EDIT' | translate}}
                </ion-item-option>
                <ion-item-option style="background-color:#FF006E" (click)="deleteObjective(objective.externalid)">
                    <ion-icon slot="top" class="indegene-delete"></ion-icon>
                    {{'DELETE' | translate}}
                </ion-item-option>
            </ion-item-options>
        </ion-item-sliding>
    </ion-list>
</ion-item-group>
