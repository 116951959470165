<ind-popup-modal-header
  #modalWrapper
  [title]="header"
  (onCloseClick)="onDismiss(false)"
></ind-popup-modal-header>

<ion-content #content>
  <div
    #contentWrapper
    class="wrapper"
  >
  <ion-item class="form-field-container">
    <ion-label  class="form-field-header-label form-field-label-text">
      <!-- <div ng-reflect-ng-class="form-field-label-text" class="form-field-label-text"> -->
        <ion-text class="field-label">{{'SUGGESTED' | translate}}*</ion-text>
      <!-- </div> -->
    </ion-label>
    <!-- <div class="form-field-value" > -->
      <ion-text style="width:100%" *ngIf="isReadOnly">{{inputTextValue}}</ion-text>
      <ion-input *ngIf="!isReadOnly" type="number" [(ngModel)]="inputTextValue" min="0" class="input-text" (ngModelChange)="onTextChange($event)"></ion-input>
    <!-- </div> -->
  </ion-item>
  <ion-item *ngIf="isInvalidValue" class="message-container">
    <ion-label class="error-message-text">{{errorMessage}}</ion-label>
  </ion-item>
  <ion-item class="form-field-container">
    <ion-label class="form-field-header-label form-field-label-text">
      <ion-text class="field-label">{{'COMMENT' | translate}}</ion-text>
    </ion-label>

    <ion-textarea
      [placeholder]="textAreaPlaceholder"
      [(ngModel)]="textAreaValue"
      class="note-input"
      [ngClass]="{'readonly': isReadOnly}"
      maxlength="1000"
      cols="10"
      debounce="150"
      [readonly]="isReadOnly"
      [autoGrow]="isReadOnly"
      [rows]="isReadOnly ? 1 : 4"
    ></ion-textarea>
  </ion-item>
  <ion-item lines="none" *ngIf="!isReadOnly">
    <ion-button slot="end" expand="block" fill="clear" size="small" [disabled]="+this.inputTextValue === 0" (click)="onDismiss(true)" class="confirm-button">
        {{confirmText}}</ion-button>
  </ion-item>
  </div>
</ion-content>