import { Injectable } from "@angular/core";
import { OffTakeCheckDataService } from "@omni/data-services/offtake-check/offtake-check.data.service";
import { UIService } from "../ui/ui.service";
import _ from "lodash";
import { OffTakeCheck, OffTakeCheckCollection } from "@omni/classes/offtake-check/offtake-check.class";
import { Guid } from "typescript-guid";
import { AuthenticationService } from "../authentication.service";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class OffTakeCheckService {

  offTakeChecks: OffTakeCheck[] = [];
  offTakeCheckProducts: OffTakeCheckCollection[] = [];

  constructor(private offtakeCheckDataService: OffTakeCheckDataService,
    private uiService: UIService,
    private authService: AuthenticationService) { }

  public async getOfftakeCheckData(accountId: string, selectedDate: Date) {
    await this.uiService.displayLoader({duration: 30000});
    this.offTakeChecks = await this.offtakeCheckDataService.fetchOffTakeChecks(moment({ 'year': selectedDate.getFullYear() }).toDate(), selectedDate, accountId);
    if (_.isEmpty(this.offTakeCheckProducts)) {
      this.offTakeCheckProducts = await this.offtakeCheckDataService.fetchOfftakeCheckProducts();
      if (!_.isEmpty(this.offTakeCheckProducts)) {
        this.offTakeChecks.forEach(offtake => {
          const monthsDiff = moment().diff(moment(offtake.indskr_offtakedate), 'months');
          if (this.authService.user.buConfigs?.indskr_offtakecheckpreviousmonthlimit > 0 && (monthsDiff < this.authService.user.buConfigs.indskr_offtakecheckpreviousmonthlimit)) {
            this.offTakeCheckProducts.forEach(product => {
              if (!offtake.collections.find(coll => coll.productId === product.productId)) {
                offtake.collections.push(product);
              }
            });
            offtake.collections = _.sortBy(offtake.collections, obj => obj.productName);
          }
        })
      }
    }
    await this.uiService.dismissLoader();
  }

  public getEmptyOfftakeCollection(selectedDate: Date, accountId: string) {
    return new OffTakeCheck(selectedDate, null, null, accountId, 548910000, _.cloneDeep(this.offTakeCheckProducts));
  }

  public async getSelectedMonthOfftakeCheck(accountId: string, selectedDate: Date, onlyTopRecord: boolean = false) {
    // await this.uiService.displayLoader();
    const offTakeChecks = await this.offtakeCheckDataService.fetchOffTakeChecks(selectedDate, selectedDate, accountId, onlyTopRecord);
    // await this.uiService.dismissLoader();
    return offTakeChecks;
  }

  public async saveOffTakeCollection(offTakeCheck: OffTakeCheck): Promise<OffTakeCheck> {
    await this.uiService.displayLoader();
    //Incase of new product, generate new GUID
    offTakeCheck.collections.filter(coll => coll.indskr_inventoryofftake != null || coll.indskr_storeinventory != null || coll.indskr_distributedornot != null)
      .forEach(coll => coll.indskr_offtakecheckid = _.isEmpty(coll.indskr_offtakecheckid) ? Guid.create().toString() : coll.indskr_offtakecheckid);
    await this.offtakeCheckDataService.saveOffTakeCollections(offTakeCheck);
    offTakeCheck.modifiedOn = new Date();
    offTakeCheck.modifiedby = this.authService.user.displayName;
    const index = this.offTakeChecks.findIndex(offTake => offTake.indskr_offtakedate.getTime() === offTakeCheck.indskr_offtakedate.getTime());
    if (index >= 0) {
      this.offTakeChecks[index] = offTakeCheck;
    } else {
      this.offTakeChecks.push(offTakeCheck);
    }
    await this.uiService.dismissLoader();
    return offTakeCheck;
  }

  clear() {
    this.offTakeChecks = [];
    this.offTakeCheckProducts = [];
  }
}