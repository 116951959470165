<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
  <ion-toolbar>
    <ind-tabs [data]="tabsData" [selectedTab]="accountPlanDetailSegment" (selectedTabChange)="segmentChanged($event)">
    </ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div [ngSwitch]="accountPlanDetailSegment">
    <account-plan-info *ngSwitchCase="'info'" [data]="data" [selectedMode]="selectedMode"></account-plan-info>
    <account-plan-timeline *ngSwitchCase="'timeline'" [selectedAccountPlan]="data"></account-plan-timeline>
  </div>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'accountPlansDetail'"></footer-toolbar>