export class SettingsAbout {
    name: string;
    url: string;
    lastModifiedDate: string;

    constructor (raw: object) {
        this.name = raw['name'];
        this.url = raw['uri'];
        this.lastModifiedDate = raw['lastModifiedDate'];
    }
}