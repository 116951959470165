<ion-header>
    <!-- <ion-toolbar  class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
      <ion-buttons slot="start" *ngIf="showBackButton()">
          <ion-button icon-only (click)="poppage()" class="poppage back-btn">
              <ion-icon name="arrow-back"></ion-icon>
          </ion-button>
      </ion-buttons>
      <ion-title text-center>{{selectedSAP.scientificActivityPlanName}}</ion-title>
      <ion-buttons slot="end">
        <ion-button color="dark" icon-only (click)="handlePlusButton()">
          <ion-icon class="pluse-icon" color="dark"></ion-icon>
        </ion-button>
    </ion-buttons>
    </ion-toolbar> -->
    <ind-page-title [viewData]='{id:"sap-details",title: selectedSAP.scientificActivityPlanName, controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <ion-toolbar>
        <ind-tabs [data]="tabsData" [(selectedTab)]="scientificActivityDetailsSegment" (selectedTabChange)="segmentChanged($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>
<ion-content no-padding>
    <!-- <io-spinner [displayText]="uiService.scientificActivityDetailsSegment" *ngIf="!device.isOffline && ((!contactService.isContactDetailsLoaded && uiService.contactDetailsSegment==='timeline') || (!contactService.isContactProfileLoaded && uiService.contactDetailsSegment==='profile'))"></io-spinner> -->
    <div [ngSwitch]="scientificActivityDetailsSegment" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <scientific-info [selectedContactId]="selectedContactId" [selectedSAP]="selectedSAP" *ngSwitchCase="'info'" (openTaskDetails)="onOpenFollowupTask($event)"></scientific-info>
        <scientific-contact-list (selectContact)="openContactDetails($event)" [selectedSAP]="selectedSAP" *ngSwitchCase="'customer'"></scientific-contact-list>
        <scientific-activity-timeline *ngSwitchCase="'timeline'" [selectedSAP]="selectedSAP" [selectedContact]="contactService.contactInformation" (openEventDetail)="onTimeLineEventClick($event)"></scientific-activity-timeline>
        <!-- <scientific-activity-timeline *ngSwitchCase="'timeline_contact'" [selectedContact]="contactService.contactInformation" [selectedSAP]="selectedSAP"></scientific-activity-timeline> -->
    </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'scientificactivity'"></footer-toolbar>
<!-- </ion-footer> -->
