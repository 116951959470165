import { Component, Input } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { UIService } from '../../../services/ui/ui.service';
import { SampleService, SamplingDetailsViewMode } from '../../../services/sample/sample.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { DeviceService } from '../../../services/device/device.service';
import { format } from 'date-fns';
import { SampleOrderActivityMetas } from '../../../classes/sample/sample-allocation.class';
import { AllocationOrderFilter } from '../../../pipes/allocation-orders-filter.pipe';
import { ActivityService } from '../../../services/activity/activity.service';
import { SampleActivity } from '../../../classes/activity/sample.activity.class';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { EventsService } from '../../../services/events/events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';

const MIN_SEARCH_LENGTH = 3;

@Component({
    selector: 'allocation-orders-list',
    templateUrl: 'allocation-orders-list.html',
  styleUrls:['allocation-orders-list.scss']
})
export class AllocationOrdersList {

    @Input() searchEvent: Observable<string>;
    private allocationOrderFilter: AllocationOrderFilter;
    public allSampleOrders: Array<SampleActivity>;
    public filteredSampleOrders: Array<SampleActivity>;
    public visiblefilteredSampleOrders: Array<SampleActivity>;
    public multiSelectFilterPopoverData: { text: string; value: string; selectedValues: string[]; items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
    private searchEventSubscription: Subscription;
    // selectedFilterList: { text: string, value: string }[] = [];
    listFiltered: boolean = false;
    filterText: string = '';
    private appliedFilterCount: number = 0;
    noSearchResults: boolean = true;
    private filteredRecordCount = 20;
    public isSearchedInput: boolean = false;

    constructor(
        public uiService: UIService,
        public sampleService: SampleService,
        public repService: RepServices,
        private navService: NavigationService,
        private device: DeviceService,
        private activityService: ActivityService,
        private activityDataService: ActivityDataService,
        public trackingService: TrackService,
        private popover: PopoverController,
        private events: EventsService,
        private authenticationService: AuthenticationService,
        public translate:TranslateService,
        private datePipe: DatePipe,
        private dateTimeFormatsService: DateTimeFormatsService
    ) {
        this.allocationOrderFilter = new AllocationOrderFilter(this.sampleService);
        // this.events.subscribe('SKUSelectionChanged', ()=> {
        //     this.filteredSampleOrders = this.allocationOrderFilter.transform(this.sampleService.selectedSampleSKU.sampleOrderActivityMetas);
        // })
    }

    ngOnInit(){
        this.allSampleOrders = this.activityService.sampleActivities.filter(sample => sample.statusString === 'Completed' && sample.ownerId === this.authenticationService.user.systemUserID);
        this.filteredSampleOrders = this.allocationOrderFilter.transform(this.allSampleOrders);

        let items;
        items = this.activityService.sampleActivityMetasIndexedBySKU.map((o)=>{
        return { text: o.skuName, value: o.skuId }
        });
        items.sort((a,b)=> (a.text.toLowerCase() > b.text.toLowerCase()) ? 1: -1);
        this.filteredRecordCount = 20;
        this.visiblefilteredSampleOrders = this.filteredSampleOrders.slice(0, this.filteredRecordCount);

        this.multiSelectFilterPopoverData = [
            {
                text: '',
                selectedValues: ['0'],
                value:'',
                items: [
                    { text: this.translate.instant('ALL_ORDERS_S'), value: '0' }
                ],
                handler: (selectedItem, item, itemRef) => {
                    if (itemRef && itemRef.parent && itemRef.parent.items && itemRef.parent.items[1]) {
                        itemRef.parent.items[1].deSelectAll();
                    }
                    item.value = selectedItem.value;
                    this.sampleService.multiProductSelectionFilter = [];
                    this.sampleService.selectedFilterList = [];
                    this.listFiltered = false;
                    this.setFilterText();
                    this.filteredSampleOrders = this.allocationOrderFilter.transform(this.allSampleOrders);
                    this.updateEmptyMessage();
                }
            },
            {
              text: this.translate.instant('PRODUCTS'),
              selectedValues: [],
              value: '',
              items: items,
              multiselect: true,
              handler: (selectedItem, item, itemRef, selected?:boolean) => {
                //itemRef.parent.items[0].deSelectAll();
                if (itemRef && itemRef.parent && itemRef.parent.items && itemRef.parent.items[0] && itemRef.parent.items[0].items && itemRef.parent.items[0].items[0]) {
                    itemRef.parent.items[0].value = undefined;

                    itemRef.parent.items[0].items[0].checked =
                    itemRef.parent.items[0].items[0].item.checked = false;

                    itemRef.parent.items[0].items[0].selectedValues =
                    itemRef.parent.items[0].items[0].item.selectedValues = [];
                }
                this.onProductChange(selectedItem, selected, itemRef);
                this.updateEmptyMessage();
              }
            }
        ];

        this.listFiltered = this.sampleService.multiProductSelectionFilter.length > 0;
        if (this.listFiltered === true) {
          this.multiSelectFilterPopoverData[1].selectedValues = _.cloneDeep(this.sampleService.multiProductSelectionFilter);
          this.setFilterText();
        }
        else {
          this.multiSelectFilterPopoverData[0].value = '0';
        }

        if (this.searchEvent) {
            this.searchEventSubscription = this.searchEvent.subscribe(searchTxt => this.searchInput(searchTxt));
        }
        this.updateEmptyMessage();
    }

    updateEmptyMessage(){
        this.events.publish('updateAllocationsRHSEmptyMessage', this.filteredSampleOrders.length > 0 ? true : false)
    }

    ngOnDestroy(){
        // this.events.unsubscribe('SKUSelectionChanged');
        if (this.searchEventSubscription) {
            this.searchEventSubscription.unsubscribe();
        }
    }

    isSplitView() {
        return (
          this.device.isMobileDevice &&
          this.device.deviceOrientation === 'PORTRAIT'
        );
    }

    backToCustomerAllocations(){
        this.trackingService.tracking('AllocationOrdersBack',TrackingEventNames.ALLOCATIONS)
        //this.navService.popWithPageTracking();
        this.sampleService.selectedSampleSKU = null;
        this.uiService.activeView = this.uiService.prevView;
    }

    openSampleOrderDetails(sampleOrder: SampleActivity){
        //this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = 'Sample';
        this.trackingService.tracking('OrderHistoryOrderDetails', TrackingEventNames.ALLOCATIONS);
        if(this.navService.getCurrentMasterPageName()==PageName.AllocationComponent){
            let activity = <SampleActivity> this.activityService.sampleActivities.find(so => so.ID === sampleOrder.ID);
            if(activity) {
                this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
                this.activityDataService.activityDetailsLoaded = true;
                this.activityService.selectedActivity = activity;
                this.events.publish('selectedActivityChangedInBackround');
            }
        }
    }

    onProductChange(selectedItem, selected: boolean = false, itemRef = null){
        if(selectedItem && selectedItem.value){
            if(selected) {
                this.sampleService.multiProductSelectionFilter.push(selectedItem.value);
                this.sampleService.selectedFilterList.push(selectedItem);
                if(itemRef && itemRef.item && itemRef.item.selectedValues){
                    itemRef.item.selectedValues.push(selectedItem.value);
                }
            } else {
              let index = this.sampleService.multiProductSelectionFilter.findIndex(o => o === selectedItem.value);
              if (index>-1) {
                this.sampleService.multiProductSelectionFilter.splice(index, 1);

                const i = this.sampleService.selectedFilterList.findIndex(item => item.value === selectedItem.value);
                if (i >= 0) {
                  this.sampleService.selectedFilterList.splice(i, 1);
                }
              }
              if(itemRef && itemRef.item && itemRef.item.selectedValues){
                  let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
                  if(idx >= 0){
                    itemRef.item.selectedValues.splice(idx,1);
                  }
              }
            }
        } else {
            this.sampleService.multiProductSelectionFilter = [];
            this.sampleService.selectedFilterList = [];
        }
        this.setFilterText();
        this.listFiltered = this.sampleService.multiProductSelectionFilter.length > 0;
        if(!this.listFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]){
            itemRef.parent._value = itemRef.parent.items[0]._value = '0';
            this.multiSelectFilterPopoverData[0].value = '0';
        }
        this.filteredSampleOrders = this.allocationOrderFilter.transform(this.allSampleOrders);
        this.filteredRecordCount = 20;
        this.visiblefilteredSampleOrders = this.filteredSampleOrders.slice(0, this.filteredRecordCount);
        this.scrollToTop();
    }

    setFilterText() {
        let txt = '';
        this.appliedFilterCount = 0;
        if (Array.isArray(this.sampleService.selectedFilterList)) {
            for (let i = 0; i < this.sampleService.selectedFilterList.length; i++) {
                const item = this.sampleService.selectedFilterList[i];

                txt = txt + item.text;
                this.appliedFilterCount++;
                if (i < this.sampleService.selectedFilterList.length - 1) {
                    txt = txt + ', ';
                }
            }
        }
        this.filterText = txt;
    }

    getAppliedFilterCount() {
      return this.filterText ? this.appliedFilterCount : 0;
    }

  getFormattedDate(value) {
    return `${this.datePipe.transform(value,'MMM' , undefined, this.translate.currentLang)}  ${value.getDate()}`;
  }

    searchInput(val: string) {
      if(val.length < MIN_SEARCH_LENGTH) {
        this.isSearchedInput = false;
      }
      if (val.length >= MIN_SEARCH_LENGTH) {
        this.isSearchedInput = true;
        this.filteredSampleOrders = this.allocationOrderFilter.transform(this.allSampleOrders);
      }else if(this.filteredSampleOrders.length != this.allSampleOrders.length){
          this.filteredSampleOrders = this.allocationOrderFilter.transform(this.allSampleOrders);
      }
      this.filteredRecordCount = 20;
      this.visiblefilteredSampleOrders = this.filteredSampleOrders.slice(0, this.filteredRecordCount);
      this.noSearchResults = !this.filteredSampleOrders.length && !val.length;
      this.updateEmptyMessage();
    }

    public filterData(event){
        this.popover
          .create({component: MultiSelectPopover,componentProps: { root: this.multiSelectFilterPopoverData }, event: event})
          .then((data)=> data.present());
        this.visiblefilteredSampleOrders = this.filteredSampleOrders.slice(0, this.filteredRecordCount);
        this.sampleService.allocationOrdersSearchStr = '';
    }

    public getViewDataModel(order: SampleActivity){
        let viewData: MainCardViewDataModel;
        viewData = {
            id:order.ID,
            fixedHeight: true,
            primaryTextLeft: order.contactName,
            primaryTextRight: order.orderId?this.translate.instant('ALLOWCATION_ORDER') + ' - ' + order.orderId:this.translate.instant('ALLOWCATION_ORDER'),
            secondaryTextRight: this.getFormattedDate(order.scheduledStart),
            showArrow: false,
            isSelected: order === this.activityService.selectedActivity,
            clickHandler: (id: string, event, specificTarget) => {
                let foundOrder = this.allSampleOrders.find(order => id === order.ID);
                if(foundOrder){
                  this.openSampleOrderDetails(foundOrder);
                }
              }
        };
        return viewData;
    }


    doInfinite(event) {
        this.filteredRecordCount = this.visiblefilteredSampleOrders.length;
        this.visiblefilteredSampleOrders.push(...this.filteredSampleOrders.slice(this.filteredRecordCount, this.filteredRecordCount + 20));
        event.target.complete();
    }
    scrollToTop() {
        let scrollIntoViewOptions: object = {
          behaviour: 'smooth'
        };
        //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it
        //let ele = document.querySelector(currentDate);
        let ele = document.getElementById('listScroll');
        if (ele) {
          ele.scrollIntoView(scrollIntoViewOptions);
        }
      }
}
