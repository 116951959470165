<ng-container class="container">
  <ind-section-header #scrollTop [viewData]='opportunityDetailsHeader'
    (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ind-form-field *ngIf="primaryTargetFormField" [viewData]='primaryTargetFormField'></ind-form-field>
  <ind-form-field *ngIf="contactFormField" [viewData]='contactFormField'></ind-form-field>
  <ind-form-field [viewData]='descriptionFormField'></ind-form-field>
  <ind-form-field [viewData]='statusFormField'></ind-form-field>
  <ind-form-field [viewData]='reasonFormField'></ind-form-field>
  <ind-form-field [viewData]='stageFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='progressFormField'></ind-form-field>
  <ind-form-field [viewData]='progressStageFormField'></ind-form-field>
  <ind-form-field [viewData]='typeFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='subTypeFormField'></ind-form-field>
  <ind-form-field [viewData]='countryFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-datetime-form [viewData]='estimatedCloseDateFormField' (onInputClick)='onFieldClick($event)'></ind-datetime-form>
  <ind-form-field [viewData]='estimatedValueFormField'></ind-form-field>
  <ind-form-field [viewData]='actualValueFormField'></ind-form-field>
  <ind-form-field  *ngIf="stakeholderValueFormField" [viewData]='stakeholderValueFormField'></ind-form-field>
  <ind-form-field [viewData]='discountFormField'></ind-form-field>
  <ind-form-field [viewData]='procedureFormField'></ind-form-field>
  <ind-form-field [viewData]='productCategoryFormField'></ind-form-field>
  <ind-form-field [viewData]='productsFormField'></ind-form-field>
  <ind-form-field [viewData]='productManagerFormField'></ind-form-field>
  <!-- 
  <ng-container *ngIf="isCapitalSalesType">
    <ind-form-field [viewData]='sourceFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
    <ind-form-field *ngIf="opportunity.indskr_source_Formatted == 'Others'" [viewData]='sourceOthersFormField'>
    </ind-form-field>
    <ind-form-field [viewData]='affordabilityFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
    <ind-form-field [viewData]='userRatingFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
    <ind-form-field [viewData]='systemRatingFormField'></ind-form-field>
  </ng-container>
  <ind-form-field *ngIf="isSalesType" [viewData]='gemFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  -->
  <ind-form-field [viewData]='parentFormField'></ind-form-field>
  <ind-form-field [viewData]='accountFormField'></ind-form-field>
  <ind-form-field [viewData]='accountPlanFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='marketingPlanFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='accountPlanObjectiveFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='ownerFormField'></ind-form-field>
  <ind-form-field [viewData]='businessUnitFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='specialtyFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='subSpecialtyFormField' (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-form-field [viewData]='focusAreaFormField'></ind-form-field>
  <!-- Survey grid -->
  <ng-container *ngIf="opportunity && isSurveyConfigured && surveyService.surveyActiveFor == 'Opportunity'">
    <ind-section-header *ngIf="opportunitySurveyHeaderModel" [viewData]="opportunitySurveyHeaderModel">
    </ind-section-header>
    <ind-survey *ngIf="!device.isOffline" [surveyTemplate]="surveyTemplate"
      [opportunity]="opportunity" [tempLookupResult]="surveyLookupResults" (completeSurvey)="updateSurveyResult($event)"
      (surveyData)="updateSurveyData($event)"></ind-survey>
    <!-- <div *ngIf="opportunity.statusCode != 1 && !device.isOffline">
      <ng-container *ngFor="let result of opportunitySurveyResponseDataModel">
        <ind-form-field [viewData]="result"></ind-form-field>
      </ng-container>
    </div> -->
    <div class="no-data-message" *ngIf="device.isOffline"> {{ 'AVAILABLE_IN_ONLINE_ONLY' | translate}}</div>
  </ng-container>
  <!-- Stakeholder grid -->
  <ind-section-header [viewData]='stakeholderLineItemDetailsHeader'
    (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-item class="placeholderEmptyDiv" *ngIf="!allModelStakeholder || allModelStakeholder.length==0"></ion-item>
  <main-card *ngFor="let stakeholder of allModelStakeholder" [viewData]="stakeholder"></main-card>
  <ind-section-header [viewData]='collaboratorsLineItemDetailsHeader'
    (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-item class="placeholderEmptyDiv" *ngIf="!allModelCollaborator || allModelCollaborator.length==0"></ion-item>
  <main-card *ngFor="let collaborator of allModelCollaborator" [viewData]="collaborator"></main-card>
  <ng-container
    *ngIf="opportunity && (opportunity.isrevenuesystemcalculated || opportunity.isestimatedrevenuebasedonprocedureandproducts)">
    <ind-section-header [viewData]='productLineItemDetailsHeader'
      (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ion-item class="placeholderEmptyDiv" *ngIf="!allModelProducts || allModelProducts.length==0"></ion-item>
    <main-card *ngFor="let product of allModelProducts" [viewData]="product"></main-card>
  </ng-container>
  <ind-section-header [viewData]='followUpActionsHeader'></ind-section-header>
  <ion-item class="placeholderEmptyDiv" *ngIf="!allModelActivities || allModelActivities.length==0"></ion-item>
  <main-card *ngFor="let activity of allModelActivities" [viewData]="activity"></main-card>
  <ng-container *ngIf="isCompetitorSectionVisible">
    <ind-section-header [viewData]='competitorDetailsHeader' (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-section-header>
    <ind-form-field [viewData]='competitorsFormField'></ind-form-field>
    <ind-form-field [viewData]='competitorProducsFormField'></ind-form-field>
    <ind-form-field [viewData]='competitorBusinessValueFormField'></ind-form-field>
  </ng-container>
  <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-section-header>
  <ion-item class="placeholderEmptyDiv"
    *ngIf="(!opportunity.opportunityNotes || opportunity.opportunityNotes.length==0) && isReadOnlyMode"></ion-item>
  <ind-text-area *ngIf="!isReadOnlyMode" id="notesInput" [placeHolder]="notesPlaceholder"
    [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000"
    (indChange)="notesChanged($event)" [value]="tempNoteText" (removeAttachment)="removeAttachment($event)" [rows]="4"
    [cols]="10"> </ind-text-area>
  <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)" id="file-input">
  <io-note *ngFor="let note of opportunity.opportunityNotes" [isNoteAttachmentEnabled]="isNotesAttachmentEnabled"
    [inputNote]="note" [editable]="isNoteControlsEnabled(note)" [deletable]="isNoteControlsEnabled(note)"
    (saveNoteOut)="updateNote($event)">
  </io-note>
</ng-container>