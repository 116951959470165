<div class="ion-padding main-section">
  <img [src]="inputOptions.mainImgSrc" height="80" width="80" *ngIf="inputOptions.mainImgSrc" />
  <p class="label">{{ inputOptions.message }}</p>
  <p class="sub-text">{{ inputOptions.subText }}</p>
</div>

<ion-list class="list-wrapper" *ngIf="inputOptions.list">
  <ion-item lines="none" *ngFor="let item of inputOptions.list">
    <ion-grid>

      <ion-row class="ion-padding-top">
        <ion-col size="2">
          <img [src]="item.itemAvatarSrc" *ngIf="item.itemAvatarSrc" />
          <div [attr.data-letters]="item.initials" *ngIf="item.initials"></div>
        </ion-col>

        <ion-col class="ion-align-items-center ion-align-self-center" size="10">
          <ion-label class="item-label"> {{ item.itemText }}</ion-label>
          <p class="item-subtext" *ngFor="let subtext of item.subText">{{subtext}}</p>
        </ion-col>
      </ion-row>

    </ion-grid>
  </ion-item>
</ion-list>

<div class="btn-footer">
  <ion-button fill="clear" slot="end" (click)="modalController.dismiss()">{{ inputOptions.cancelBtnText }}</ion-button>
  <ion-button fill="clear" slot="end" class="scan-btn" (click)="onConfirm()">{{
    inputOptions.confirmBtnText}}</ion-button>
</div>