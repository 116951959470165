import { BehaviorSubject } from 'rxjs';
import { Chart } from 'angular-highcharts';
import _ from 'lodash';
import { format } from 'date-fns';
import { BaseMeasureData, ChartFilterOption, KPIDataBlock, MeasureConfig, MeasuresChartData, SystemUserDimensionDTO, YearMonthListItem, YearMonthOption } from '../../../../interfaces/edge-analytics/report.interface';
import { CommonDimensions } from '../../../enums/edge-analytics/edge-analytics.enum';
import { DefaultChartHeightForMobileView, DefaultChartHeightForSplitView, DefaultChartHeightOffset, DefaultChartOptions } from '../../../config/edge-analytics/report.config';
import { unsubscribeSubscriptionArray } from '../../../utility/common.utility';
import { IndReportGroupTileDataModel, IndReportTileDataModel } from '../../../models/IndReportTileDataModel';
import moment from 'moment';


/** ----------------------------------------------------------------------------------------
 *  YearMonth filter options helper
 */
export function initYearMonthOptions(dataCube: any, keyProp: string, langCode?: string): YearMonthOption[] {
  const yearMonthOptions: YearMonthOption[] = [];
  if (dataCube && keyProp) {
    const members = dataCube.getDimensionMembers(CommonDimensions.yearMonth);
    if (Array.isArray(members) && members.length > 0) {
      _.uniqBy(members, keyProp).forEach((member: any) => {
        const option = _getYearMonthOption(member[keyProp], langCode);
        if (option) {
          yearMonthOptions.push(option);
        }
      });
    } else {
      console.warn('initYearMonthOptions: No member found..');
      const thisYearMonth: number = parseInt(format(new Date(), 'YYYYMM'));
      const option = _getYearMonthOption(thisYearMonth, langCode);
      if (option) {
        yearMonthOptions.push(option);
      }
    }
  } else {
    console.warn('initYearMonthOptions: Invalid / missing input: ', dataCube, keyProp);
  }
  return yearMonthOptions;
}
function _getYearMonthOption(yearMonth: number, langCode?: string) {
  let option: YearMonthOption;
  const response = getYearMonthFromYYYYMM(yearMonth, langCode);
  if (response && response.year && response.month && response.displayText) {
    option = {
      value: yearMonth,
      text: response.displayText,
      year: response.year,
      month: response.month,
    };
  } else {
    console.warn('_getYearMonthOption: Invalid input: ', yearMonth);
  }
  return option;
}
function _parseYearMonth(yearMonth: number): { year: number, month: number } {
  let response;

  if (!isNaN(yearMonth) && yearMonth > 200000 && yearMonth < 1000000) {
    try {
      const year = parseInt(yearMonth.toString().slice(0,4));
      const month = parseInt(yearMonth.toString().slice(4,6));
      response = { year, month };
    } catch (error) {
      console.error('_parseYearMonth: ', error);
    }
  }
  return response;
}
export function getYearMonthFromYYYYMM(yearMonth: number, curlangCode?: string): { year: number, month: number, displayText: string } {
  let response;

  try {
    const yearMonthSplit = _parseYearMonth(yearMonth);
    if (yearMonthSplit) {
      const date = new Date(yearMonthSplit.year, yearMonthSplit.month - 1, 1);
      let displayText = '';
      if (date) {
        const langCode: string = curlangCode || 'en';
        const formattedDate = moment(date);
        displayText = _.capitalize(formattedDate.locale(langCode).format('MMMM, YYYY'));
      }
  
      response = { year: yearMonthSplit.year, month: yearMonthSplit.month, displayText };
    }
  } catch (error) {
    console.error('getYearMonthFromYYYYMM: ', error);
  }

  return response;
}
export function getDefaultSelectedYearMonths(yearMonthOptions: YearMonthOption[], desc = true): number[] {
  let defaultSelected = [];
  if (Array.isArray(yearMonthOptions)) {
    const thisYearMonth: number = parseInt(format(new Date(), 'YYYYMM'));
    if (desc) {
      yearMonthOptions.sort((a, b) => b.value - a.value);
    } else {
      yearMonthOptions.sort((a, b) => a.value - b.value);
    }

    const option = yearMonthOptions.find(o => o.value === thisYearMonth);
    if (option) {
      defaultSelected.push(option.value);
    } else if (yearMonthOptions[0] && yearMonthOptions[0].value) {
      // If somehow current month not avail, pick the latest
      defaultSelected.push(yearMonthOptions[0].value)
    } else {
      console.warn('getDefaultSelectedYearMonths: ', yearMonthOptions);
    }
  }

  return defaultSelected;
}
export function getDateDimensionMembers(dataCube: any, selectedYearMonths: number[], keyProp: string) {
  let dateDimensionMembers;
  if (dataCube && Array.isArray(selectedYearMonths) && keyProp) {
    try {
      dateDimensionMembers = dataCube.getDimensionMembers(CommonDimensions.yearMonth).filter(o => selectedYearMonths.some(s => s === o[keyProp]));
    } catch (error) {
      console.error('getDateDimensionMembers: ', error);
    }
  }
  return dateDimensionMembers;
}
export function getSelectedDateDimensionMembersInAscendingOrder(selectedYearMonths: number[], dateDimensionMembers: any[], keyProp: string): any[] {
  let selectedDateDimensionMembers = [];
  if (Array.isArray(selectedYearMonths) && Array.isArray(dateDimensionMembers) && keyProp) {
    selectedDateDimensionMembers = dateDimensionMembers.filter(d => selectedYearMonths.some(s => s === d[keyProp]));
    selectedDateDimensionMembers.sort((a, b) => a[keyProp] - b[keyProp]);
  } else {
    console.error('getSelectedDateDimensionMembersInAscendingOrder: Invalid input: ', selectedYearMonths, dateDimensionMembers, keyProp);
  }
  return selectedDateDimensionMembers;
}
function _incrementMonth(year: number, month: number): { year: number, month: number } {
  let newMonth: number;
  let newYear: number;
  let increaseYear: boolean = false;
  if (month >= 12) {
    newMonth = 1;
    increaseYear = true;
  } else {
    newMonth = month + 1;
  }

  newYear = increaseYear ? year + 1 : year;
  const newYearMonth = { year: newYear, month: newMonth };

  return newYearMonth;
}
export function getTotalYearMonthList(dataStartDate: string): YearMonthListItem[] {
  let totalYearMonthList = [];
  if (dataStartDate) {
    const dataStartDateInNumber = parseInt(dataStartDate);
    const thisYearMonthInNumber: number = parseInt(format(new Date(), 'YYYYMM'));

    if (dataStartDateInNumber > 200000 && dataStartDateInNumber <= thisYearMonthInNumber) {
      // Generate total year month rage
      let yearMonthIterator = dataStartDateInNumber;
      let yearMonthObjIterator: { year: number, month: number } = _parseYearMonth(dataStartDateInNumber);

      do {
        if (yearMonthIterator && yearMonthObjIterator && yearMonthObjIterator.year && yearMonthObjIterator.month) {
          totalYearMonthList.push({
            yearMonth: yearMonthIterator,
            year: yearMonthObjIterator.year,
            month: yearMonthObjIterator.month
          });
        } else {
          console.error('getTotalYearMonthList: Invalid iterator: ', yearMonthIterator, yearMonthObjIterator);
        }

        // increment
        yearMonthObjIterator = _incrementMonth(yearMonthObjIterator.year, yearMonthObjIterator.month);
        yearMonthIterator = yearMonthObjIterator.year * 100 + yearMonthObjIterator.month;
      } while (yearMonthIterator <= thisYearMonthInNumber);
    } else {
      console.error('getTotalYearMonthList: Invalid dates: ', dataStartDateInNumber, thisYearMonthInNumber);
    }
  }
  return totalYearMonthList;
}
export function getMissingYearMonths(totalYearMonthList: YearMonthListItem[], facts: any[], keyProp: string): YearMonthListItem[] {
  let missingYearMonths = [];
  if (Array.isArray(totalYearMonthList) && Array.isArray(facts) && keyProp) {
    for (let i = 0; i < totalYearMonthList.length; i++) {
      const yearMonthListItem = totalYearMonthList[i];
      try {
        if (!facts.some(f => f[keyProp] === yearMonthListItem.yearMonth)) {
          missingYearMonths.push(JSON.parse(JSON.stringify(yearMonthListItem)));
        }
      } catch (error) {
        console.error('getMissingYearMonths: ', error);
      }
    }
  }
  return missingYearMonths;
}
// Find date dimension members to fill in
export function getMissingDateMembers(dataStartDate: string, cube: any, keyProp: string): any[] {
  let missingDateMembers = [];
  if (dataStartDate && cube && keyProp) {
    try {
      const dateMembers = cube.getDimensionMembers(CommonDimensions.yearMonth);
      const dataStartDateInNumber = parseInt(dataStartDate);
      const thisYearMonth: number = parseInt(format(new Date(), 'YYYYMM'));

      if (dataStartDateInNumber > 200000 && dataStartDateInNumber <= thisYearMonth) {
        // Generate total year month rage
        let yearMonthIterator = dataStartDateInNumber;
        let yearMonthObjIterator: { year: number, month: number } = _parseYearMonth(dataStartDateInNumber);
        const totalYearMonthRange = [];

        do {
          const obj = {};
          obj[keyProp] = yearMonthIterator;
          totalYearMonthRange.push(obj);

          // increment
          yearMonthObjIterator = _incrementMonth(yearMonthObjIterator.year, yearMonthObjIterator.month);
          yearMonthIterator = yearMonthObjIterator.year * 100 + yearMonthObjIterator.month;
        } while (yearMonthIterator <= thisYearMonth);

        // Find missing yearMonths
        missingDateMembers = _.differenceBy(totalYearMonthRange, dateMembers, keyProp);

        console.log('--- missing date members: ', dateMembers, totalYearMonthRange, missingDateMembers);
      } else {
        console.error('getDateMembersToFillIn: Invalid dates: ', dataStartDateInNumber, thisYearMonth);
      }
    } catch (error) {
      console.error('getDateMembersToFillIn: ', error);
    }
  } else {
    console.error('getDateMembersToFillIn: Invalid input: ', dataStartDate, cube, keyProp);
  }
  return missingDateMembers;
}
export function fillInMissingDateMembers(missingDateMembers: any[], cube: any, keyProp: string) {
  if (Array.isArray(missingDateMembers) && cube && keyProp) {
    // Find missing members
    const cubeDateMembers = cube.getDimensionMembers(CommonDimensions.yearMonth);
    const missingMembers = _.differenceBy(missingDateMembers, cubeDateMembers, keyProp);
    if (Array.isArray(missingMembers)) {
      for (let i = 0; i < missingMembers.length; i++) {
        const missingMember = missingMembers[i];
        cube.addDimensionMember(CommonDimensions.yearMonth, missingMember);
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  KPI tile prep helper functions
 */
function _isKpiEnabled(kpiName: string, kpiConfig: MeasureConfig[]): boolean {
  let isEnabled = true;

  // Apply config only if exists
  if (Array.isArray(kpiConfig) && kpiConfig.length > 0) {
    isEnabled = kpiConfig.some(c => c.kpi === kpiName);
  }

  return isEnabled;
}
export function applyConfigToKPIMap(kpiMap: Map<string, KPIDataBlock>, config: MeasureConfig[]) {
  if (kpiMap) {
    for (const kpiDataBlock of kpiMap.values()) {
      kpiDataBlock.enabled = _isKpiEnabled(kpiDataBlock.id, config);
    }
  }
}
function _getDataForReportTile(tileId: string, kpiMap: Map<string, KPIDataBlock>): Partial<IndReportTileDataModel> {
  let data: Partial<IndReportTileDataModel>;

  const kpi = kpiMap.get(tileId);
  if (kpi) {
    data = { enabled: kpi.enabled, observableData: kpi.enabled ? kpi.data$ : undefined, };
  }

  return data;
}
function _fillEmptyTiles(tiles: IndReportTileDataModel[], reportTilesPerRow: number) {
  if (Array.isArray(tiles) && tiles.length > 1 && tiles.length < reportTilesPerRow) {
    for (let i = 0; i < reportTilesPerRow - tiles.length; i++) {
      tiles.push({
        id: '',
        firstHeading: '',
        dataValue: undefined,
        dataFieldName: '',
      });
    }
  }
}
export function generateReportGroupTiles( measureData: BaseMeasureData,
                                          reportTiles: IndReportTileDataModel[],
                                          reportTilesPerRow: number,
                                          tileGroupIdPrefix: string) {
  if (measureData && measureData.kpiMap && Array.isArray(reportTiles)) {
    const tileGroups: IndReportGroupTileDataModel[] = [];
    let tileGroup: IndReportGroupTileDataModel = null;
    let tileCount = 0;
    let tileGroupCount = 0;
    for (let i = 0; i < reportTiles.length; i++) {
      const tile: IndReportTileDataModel = reportTiles[i];

      const tileData: Partial<IndReportTileDataModel> = _getDataForReportTile(tile.id, measureData.kpiMap);
      if (tileData && tileData.enabled) {
        if (tileGroup === null) {
          tileGroup = {
            id: `${tileGroupIdPrefix}-${tileGroupCount}`,
            tiles: []
          };
          tileGroups.push(tileGroup);
          tileCount = 0;
        }

        // Merge tile template with data value
        tileGroup.tiles.push({ ...tile, ...tileData });
        tileCount++;
      } else if (!tileData) {
        // Not supposed to come here..
        console.warn('generateReportGroupTiles: No data available for ', tile);
      }

      if (tileCount === reportTilesPerRow) {
        tileGroup = null;
        tileGroupCount++;
      }
    }
    if (tileGroups && tileGroups[tileGroups.length - 1]) {
      _fillEmptyTiles(tileGroups[tileGroups.length - 1].tiles, reportTilesPerRow);  
    }
    measureData.tileGroups = tileGroups;
  } else {
    console.error('generateReportGroupTiles: Invalid / No data provided..', measureData,
                                                                            reportTiles,
                                                                            reportTilesPerRow);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Cube helper functions
 */
export function getFilteredSubCube(dataCube: any, filter: any) {
  let filteredSubCube;
  if (dataCube && filter) {
    try {
      filteredSubCube = dataCube.dice(filter);
    } catch (error) {
      console.error('getFilteredSubCube: ', error);
    }
  }
  return filteredSubCube;
}


/** ----------------------------------------------------------------------------------------
 *  KPI reducer helper functions
 */
export function reducerForKPIs(factName: string, subCubeData: any, typeOfReduction: 'summation' | 'count'): number {
  let initiator = 0;
  if(typeOfReduction === 'summation'){
    subCubeData.reduce((a, b)=>{
      initiator += !isNaN(b[factName]) ? b[factName] : 0;
    }, 0);
  }
  if(typeOfReduction === 'count'){
    subCubeData.reduce((a, b)=>{
      initiator += 1;
    }, 0);
  }
  return initiator;
}
export function reduceByProduct(productDimensionMembers: any[], uniqMeasures: any[], measure: string, operation: 'summation' | 'count'): {[key: string]: number} {
  let groupedData = null;

  if (Array.isArray(productDimensionMembers) && Array.isArray(uniqMeasures)) {
    groupedData = {};

    uniqMeasures.reduce((a, b) => {
      const productName = productDimensionMembers.find(prod => prod.id === b.products_id).product;
      if (groupedData[productName] !== undefined) {
        if (operation === 'count') {
          groupedData[productName]++
        } else if (operation === 'summation') {
          groupedData[productName] += b[measure] ?? 0;
        }
      } else {
        if (operation === 'count') {
          groupedData[productName] = 1;
        } else if (operation === 'summation') {
          groupedData[productName] = b[measure] ?? 0;
        }
      }
    }, groupedData);
  }
  return groupedData;
}


/** ----------------------------------------------------------------------------------------
 *  Chart helper functions
 */
export function calcChartWidth(isMobilePortrait: boolean, windowInnerWidth: number) {
  return isMobilePortrait
    ? (windowInnerWidth - 20)
    : ((windowInnerWidth * 0.6) - 20);
}
export function calcChartHeight(isMobilePortrait: boolean, windowInnerHeight: number) {
  return isMobilePortrait
    ? windowInnerHeight > 0
      // Half height for mobile portrait
      ? (windowInnerHeight - DefaultChartHeightOffset) / 2
      : DefaultChartHeightForMobileView
    : DefaultChartHeightForSplitView;
}
export function updateHighChartSeries(highChartRef: any, filterOptions: ChartFilterOption[], redraw = false) {
  if (highChartRef && Array.isArray(highChartRef.series) && Array.isArray(filterOptions)) {

    const chartType = highChartRef.userOptions.chart.type;

    if (chartType === 'pie') {
      const seriesItem = highChartRef.series.find(s => s.name === 'Surgery');
      const data = [];
      for (let i = 0; i < filterOptions.length; i++) {
        const filterOption = filterOptions[i];

        data.push({
          name: filterOption.displayText,
          y: filterOption.data
        });
      }

      if (seriesItem) {
        // Update
        seriesItem.update({ data });
      } else {
        // New
        highChartRef.addSeries({
          name: 'Surgery',
          colorByPoint: true,
          data
        });
      }
    } else {
      for (let i = 0; i < filterOptions.length; i++) {
        const filterOption = filterOptions[i];
        const seriesItem = highChartRef.series.find(s => s.name === filterOption.displayText);

        if (filterOption.isSelected) {
          if (seriesItem) {
            // Update
            seriesItem.update({ data: filterOption.data });
          } else {
            // Add
            highChartRef.addSeries({
              name: filterOption.displayText,
              data: filterOption.data,
            });
          }
        } else {
          // Maybe Remove
          if (seriesItem) {
            seriesItem.remove();
          }
        }
      }  
    }

    // Redraw chart
    if (redraw) {
      highChartRef.redraw();
    }
  }
}
export function updateHighChartOption(chart: Highcharts.Chart, option: any, redraw: boolean = false, oneToOne: boolean = false, animation: boolean = true) {
  if (chart && chart.options && option) {
    try {
      chart.update(option, redraw, oneToOne, animation);
    } catch (error) {
      console.error('updateHighChartOption: ', error);
    }
  }
}

export function generateChart(  chartFilterOptions: ChartFilterOption[],
                                chartTitle: string,
                                isMobilePortrait: boolean,
                                windowInnerWidth: number,
                                windowInnerHeight?: number,
                                xAxisCategories?: any,
                                customChartOptions: any = JSON.parse(JSON.stringify(DefaultChartOptions))): Chart {
  let chart;

  if (windowInnerWidth > 0) {
    const width = calcChartWidth(isMobilePortrait, windowInnerWidth);
    const height = calcChartHeight(isMobilePortrait, windowInnerHeight);
    const series = [];
    const chartOptions = customChartOptions;

    chartOptions.chart.width = width;
    chartOptions.chart.height = height;

    chartOptions.title.text = chartTitle;

    if (xAxisCategories) {
      chartOptions.xAxis.categories = xAxisCategories;
    }

    if (Array.isArray(chartFilterOptions)) {
      for (let i = 0; i < chartFilterOptions.length; i++) {
        const filterOption = chartFilterOptions[i];
  
        if (filterOption.isSelected) {
          series.push({
            name: filterOption.displayText,
            data: filterOption.data,
          });
        }
      }
    }

    chartOptions.series = series;

    chart = new Chart(chartOptions);
  }
  return chart;
}
export function getXAxisCategories(data: {}): string[] {
  const keys = [];
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      keys.push(key);
    }
  }
  return keys;
}
export function setChartFilter( chartData: MeasuresChartData,
                                filterOptions: ChartFilterOption[],
                                config: MeasureConfig[]) {
  if (chartData && Array.isArray(filterOptions) && Array.isArray(config)) {
    // Apply config only if exists
    if (config.length > 0) {
      filterOptions = filterOptions.filter(o => config.some(c => c.kpi === o.value));
      // If there's no default selected option, just select first one.
      if (filterOptions.length > 0 && !filterOptions.some(o => o.isSelected)) {
        filterOptions[0].isSelected = true;
      }
    }

    chartData._chartFilterOptions$.next(filterOptions);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Unsubscribe
 */
export function unSubMeasureData(measureData: BaseMeasureData) {
  if (measureData) {
    measureData.tileGroups = [];

    unsubscribeSubscriptionArray(measureData.subs);
    measureData.subs = [];

    if (Array.isArray(measureData.charts)) {
      for (let i = 0; i < measureData.charts.length; i++) {
        const chartData = measureData.charts[i];
        unSubMeasureChartData(chartData);
      }
    }
  }
}
export function unSubMeasureChartData(chartData: MeasuresChartData) {
  if (chartData.highChartSub) {
    chartData.highChartSub.unsubscribe();
    chartData.highChartSub = undefined;
  }
  chartData.highChartRef = undefined;
  if (chartData.chart) {
    if ((chartData.chart as any).refSubject) {
      (chartData.chart as any).refSubject.complete();
    }
    chartData.chart.destroy();
    chartData.chart = undefined;
  }
}


/** ----------------------------------------------------------------------------------------
 *  General helper functions
 */
export function getUserIdFromUserDimensions(userPrincipalName: string, systemUserDimensions: SystemUserDimensionDTO[]): number {
  let userId: number = null;
  if (Array.isArray(systemUserDimensions) && userPrincipalName) {
    const user = systemUserDimensions.find(s => s.internalemailaddress === userPrincipalName);
    userId = user ? user.sk_systemuserid : null;
  } else {
    console.error('getUserIdFromUserDimensions: invalid input: ', userPrincipalName, systemUserDimensions);
  }

  return userId;
}

export function getPercentageString(numerator: number, denominator: number, decimalPrecision: number = 2): string {
  let percentString = '0';
  if (numerator > 0 && denominator > 0) {
    const inNumber = numerator / denominator * 100;
    let decimalZeroes = '00';
    if (decimalPrecision !== 2 && decimalPrecision > 0) {
      decimalZeroes = '';
      for (let i = 0; i < decimalPrecision; i++) {
        decimalZeroes = decimalZeroes + '0';
      }
    }
    percentString = inNumber.toFixed(decimalPrecision).replace(`.${decimalZeroes}`, '');
  }
  return percentString;
}

export function addLoaderCount(subject: BehaviorSubject<number>, byCount: number = 1) {
  if (subject) {
    const curCount = subject.getValue();
    if (!isNaN(curCount)) {
      let newCount = curCount < 0 ? 0 : curCount;
      newCount = newCount + byCount;

      subject.next(newCount);
    }
  }
}
export function subLoaderCount(subject: BehaviorSubject<number>, byCount: number = 1) {
  if (subject) {
    const curCount = subject.getValue();
    if (!isNaN(curCount)) {
      let newCount = curCount - byCount;
      if (newCount < 0) {
        newCount = 0;
      }

      subject.next(newCount);
    }
  }
}
