<ion-item lines="none">
  <img src="assets/imgs/omni_appointment.svg" alt="list-icon" class="list-icon" slot="start">
  <ion-label class="list-header"> {{'CHECK_IN_OUT_APPEAL' | translate}} - {{approval.accountName}}</ion-label>
  <ion-button slot="end" fill="clear">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>
</ion-item>

<ion-row>
  <ion-col size="6">
    <omni-list-item label="{{ (selectedMode == 1 ? 'APPROVER' : 'REPRESENTATIVE') | translate }}" value="{{approval.ownerName}}" cssClass="list-item">
    </omni-list-item>
  </ion-col>
  <ion-col size="6">
    <omni-list-item label="{{ 'SENT_FOR_APPROVAL' | translate }}"
      value="{{appealService.getDateFormatted(approval.sentForApproval)}}" cssClass="list-item">
    </omni-list-item>
  </ion-col>
  <ion-col size="6">
    <omni-list-item label="{{ 'POSITION' | translate }}"
      value="{{approval.positionName}}" cssClass="list-item">
    </omni-list-item>
  </ion-col>
  <ion-col size="6">
    <omni-list-item label="{{ 'APPROVAL_STATUS' | translate }}"
      value="{{appealService.getStatusFormattedValue(approval.statuscode)}}" cssClass="list-item">
    </omni-list-item>
  </ion-col>
</ion-row>