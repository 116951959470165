import { EventsService } from './events/events.service';
import 'reflect-metadata';

import { Injector, OnDestroy, OnInit } from '@angular/core';
interface IComponentWithInjector extends OnInit, OnDestroy {
  injector: Injector;
}

import { Events } from '@omni/events';
import { uniqueId } from 'lodash';
export function IoEventListener(
  event: string,
  filter?: (...args) => boolean,
  selector?: (...args) => any
): MethodDecorator {
  return function (target: IComponentWithInjector, propertyKey, descriptor) {
    const method: Function = descriptor.value as any;
    const ngOnInit = target.constructor.prototype.ngOnInit;
    const ngOnDestroy = target.constructor.prototype.ngOnDestroy;
    const eventId = uniqueId(`event_listeners_[${event}]_`);
    target.constructor.prototype.ngOnInit = function (
      this: IComponentWithInjector
    ) {
      if (this.injector) {
        this[eventId] =
        filter || selector
          ? (...args) => {
              if (!filter || filter.apply(this, args)) {
                method.apply(
                  this,
                  !selector ? args : selector.apply(this, args)
                );
              }
            }
          : method.bind(this);
        const events = this.injector.get<Events>(Events);
        events.subscribe(event, this[eventId]);
        ngOnInit && ngOnInit.apply(this);
      }
    };

    target.constructor.prototype.ngOnDestroy = function (
      this: IComponentWithInjector
    ) {
      if (this.injector) {
        const events = this.injector.get<Events>(Events);
        events.unsubscribe(event, this[eventId]);
      }
      ngOnDestroy && ngOnDestroy.apply(this);
    };
  };
}
