import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService } from '../../../services/footer/footer.service';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { MedicalInsightService } from '@omni/services/medical-insight/medical-insight-service';

/**
 * Generated class for the MedicalInsightPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'medical-insight-page[base-page]',
  templateUrl: 'medical-insight-page.html',
  styleUrls:['medical-insight-page.scss']
})
export class MedicalInsightPageComponent {

  @ViewChild('medicalinsightpagerightpane', {static:true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    private footerService: FooterService,
    public agendaFooterService: AgendaFooterService,
    private readonly medicalInsightService: MedicalInsightService,
  ) {
  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.MedicalInsightPageNavigation, PageName.MedicalInsightPageComponent, false, {deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');
  }

  ionViewDidEnter() {
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  ngOnDestroy(){
    // this.agendaFooterService.initButtons(this.agendaFooterService.footerViewAtHome);
    // this.agendaFooterService.footerViewAtHome = null;
    this.medicalInsightService.myMedicalInsights = [];
    this.navService.popChildNavCtrlFromStack(ChildNavNames.MedicalInsightPageNavigation);
  }

}
