export class MeetingAsset {

  public indskr_activitycustomerassetid: string;
  public appointmentid: string;
  public msdyn_customerassetid: string;
  public msdyn_name: string;
  public indskr_serialnumber: string;
  public createdon: string;
  public statuscode: number;
  public statecode: number;
  public ownerid: string;
  public indskr_category: string;
  public indskr_product: string;
  public indskr_assetstatus: string;
  public _id: string;
  public _rev: string;

  constructor(raw) {
    this.indskr_activitycustomerassetid = raw['indskr_activitycustomerassetid'];
    this.appointmentid = raw['indskr_appointment_value'] || raw['appointmentid'];
    this.msdyn_customerassetid = raw['indskr_customerasset_value'] || raw['msdyn_customerassetid'];
    this.msdyn_name = raw['msdyn_name'] || raw['indskr_name'];
    this.indskr_serialnumber = raw['indskr_serialnumber'];
    this.statuscode = raw['statuscode'];
    this.statecode = raw['statecode'];
    this.indskr_category = raw['indskr_category'];
    this.indskr_assetstatus = raw['indskr_assetstatus'];
    this.indskr_product = raw['indskr_product'];
    this.ownerid = raw['ownerid_value'] || raw['ownerid'];
    this._id = raw['_id'];
    this._rev = raw['_rev'];
  }
}
