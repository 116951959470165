<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
  <ion-toolbar>
    <ind-tabs [data]="tabsData" [selectedTab]="marketingPlanDetailSegment" (selectedTabChange)="segmentChanged($event)">
    </ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div [ngSwitch]="marketingPlanDetailSegment">
    <marketing-plan-info *ngSwitchCase="'info'" [data]="planDataSetReference" [selectedMode]="selectedMode"></marketing-plan-info>
    <marketing-plan-timeline *ngSwitchCase="'timeline'" [selectedMarketingPlan]="planDataSetReference"></marketing-plan-timeline>
  </div>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'accountPlansDetail'"></footer-toolbar>