export class TImeOffReason {

    public reason: string;
    public reasonCode: number;

    constructor(raw:Object){
        this.reason = raw['reason'];
        this.reasonCode = raw['value'];
    }

}
