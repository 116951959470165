import { Component, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { AppealDetailsComponent } from '../appeal-details/appeal-details.component';
import { UIService } from '@omni/services/ui/ui.service';
import { GPSApprovalActivity } from '@omni/classes/approvals/gps-approval-activity.class';
import { AppealDataService } from '@omni/data-services/appeal/appeal.data.service';
import { PopoverController } from '@ionic/angular';
import { MultiSelectPopover } from '../multi-select-popover/multi-select-popover';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';

enum Mode {
  PENDING_APPROVALS,
  MY_REQUESTS
}
@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss'],
})
export class ApprovalListComponent implements OnInit {

  sectionHeader: IndSectionHeaderViewDataModel;
  approvalsSearchText: string = '';
  selectedMode: Mode = Mode.PENDING_APPROVALS;

  popoverHandle;
  approvals: GPSApprovalActivity[] = [];
  selectedApproval: GPSApprovalActivity;
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];

  constructor(public deviceService: DeviceService,
    private translate: TranslateService,
    private navService: NavigationService,
    private uiService: UIService,
    private appealService: AppealDataService,
    private popover: PopoverController,
    private readonly notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.uiService.showRightPane = false;
    this.fetchApprovals();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.refreshList && changes.refreshList.currentValue != changes.refreshList.previousValue) {
        if (changes.schedulerSearchText && changes.schedulerSearchText.currentValue.length >= 1
          && changes.schedulerSearchText.currentValue != changes.schedulerSearchText.previousValue) {
        }
      }
    }
  }

  private async fetchApprovals() {
    this.selectedMode = Mode.PENDING_APPROVALS;
    await this.uiService.displayLoader();
    let popoverObj = {
      text: "",
      value: "pendingApprovals",
      items: [],
      handler: (selectedItem, item) => {
        item.value = selectedItem.value;
        if (selectedItem.value === 'pendingApprovals')
          this.fetchPendingApprovals();
        else if (selectedItem.value === 'myReq')
          this.fetchMyApprovals();
        if (this.popoverHandle) {
          this.popoverHandle.dismiss();
          this.popoverHandle = null;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
      }
    }
    try {
      const [pendingApprovals, myApprovals] = await Promise.all([
        this.appealService.fetchPendingApprovals(),
        this.appealService.fetchMyApprovalReq()
      ]);
      if (pendingApprovals.length > 0) {
        popoverObj.items.push({ text: this.translate.instant('PENDING_APPROVALS'), value: 'pendingApprovals' });
        this.approvals = pendingApprovals;
      }
      if (myApprovals.length > 0) {
        popoverObj.items.push({ text: this.translate.instant('TIMEOFF_MY_REQUESTS'), value: 'myReq' });
      }

      if (pendingApprovals.length == 0) {
        this.selectedMode = Mode.MY_REQUESTS;
        popoverObj.value = 'myReq';
        this.approvals = myApprovals;
      }
    } catch (err) {
      console.log('Error occurred.. ', err);
    } finally {
      this.filterPopoverData = [popoverObj];
      this.initSectionHeader();
      await this.uiService.dismissLoader();
    }
  }

  private fetchPendingApprovals() {
    this.selectedMode = Mode.PENDING_APPROVALS;
    this.initSectionHeader();
    this.uiService.displayLoader();
    this.appealService.fetchPendingApprovals().then((resp) => {
      this.approvals = resp;
      this.uiService.dismissLoader();
      this.initSectionHeader();
    });
  }

  private fetchMyApprovals() {
    this.selectedMode = Mode.MY_REQUESTS;
    this.initSectionHeader();
    this.uiService.displayLoader();
    this.appealService.fetchMyApprovalReq().then((resp) => {
      this.approvals = resp;
      this.uiService.dismissLoader();
      this.initSectionHeader();
    });
  }

  private initSectionHeader() {
    let title;
    if (this.selectedMode === Mode.PENDING_APPROVALS)
      title = this.translate.instant('PENDING_APPROVALS') + ' (' + this.approvals.length + ')';
    if (this.selectedMode === Mode.MY_REQUESTS) {
      title = this.translate.instant('TIMEOFF_MY_REQUESTS') + ' (' + this.approvals.length + ')';
    }
    this.sectionHeader = {
      id: 'apprval-list-section',
      title: title,
      isFilter: this.filterPopoverData[0].items.length > 1,
      isFilterStyleHeading: false,
      showArrow: this.filterPopoverData[0].items.length > 1,
      arrowType: 'chevron-down-outline',
      controls: []
    }
  }

  async openApprovalDetails(approval: GPSApprovalActivity) {
    if (this.deviceService.isOffline) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Approval list', 'top', ToastStyle.DANGER);
      return;
    }
    this.uiService.showRightPane = true;
    try {
      await this.uiService.displayLoader();
      approval = await this.appealService.fetchAppealDetails(approval.indskr_approvalactivityid, this.selectedMode);
      // if (statuscode) approval.statuscode = statuscode
      await this.uiService.dismissLoader();
    } catch (error) {
      console.error("Failed to fetch latest approval status");
    }
    this.selectedApproval = approval;
    this.navService.setChildNavRoot(AppealDetailsComponent, PageName.AppealDetailsComponent, PageName.ActivitiesPageComponent,
      {
        selectedActivity: this.selectedApproval,
        selectedMode: this.selectedMode,
        callBackEvent: (data) => this.handleAppealDetailsCallBack(data)
      });
  }

  handleAppealDetailsCallBack(data: GPSApprovalActivity) {
    if (data) {
      const index = this.approvals.findIndex(approval => approval.indskr_approvalactivityid === data.indskr_approvalactivityid);
      if(index >= 0) {
        this.approvals.splice(index, 1);
        this.fetchPendingApprovals();
      }
    }
  }

  async openFilter(event) {
    if (this.deviceService.isOffline) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Approval list', 'top', ToastStyle.DANGER);
      return;
    }
    if (this.popoverHandle) return;
    this.popoverHandle = await this.popover.create({ component: MultiSelectPopover, componentProps: { root: this.filterPopoverData, multiselect: false }, event });
    await this.popoverHandle.present();
    await this.popoverHandle.onDidDismiss();
  }

  ngOnDestroy() {
    if(this.navService.getActiveChildNavViewPageName() === PageName.AppealDetailsComponent) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }
  }
}
