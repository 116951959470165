<!-- Use this component for a readonly right pane view. Just pass data as an array of sections containing header--fileds in siblings relation -->
<ion-header>
    <!-- <ion-toolbar  [ngClass]="{ 'offline-indicator': repService.isOfflineState }"> -->
        <!-- <ion-buttons slot="start">
            <ion-button (click)="onClosePage()" >
                <ion-icon class='arrow-icon' name="chevron-back-outline"></ion-icon>
            </ion-button>
        </ion-buttons> -->
        <ind-page-title [viewData]="agreementPageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
        <!-- <ion-title text-center>{{viewHeader}}</ion-title> -->
      <!-- </ion-toolbar> -->
</ion-header>
<ion-content>
  <ng-container *ngIf="viewData; else webResourceView">
    <div *ngFor="let section of viewData">
      <ind-section-header [viewData]='section.header'></ind-section-header>
      <ind-form-field *ngFor="let field of section.fields" [viewData]='field' ></ind-form-field>
    </div>
  </ng-container>
  <ng-template #webResourceView>
    <ng-container *ngIf="device.isOffline; else onlineView">
      <p class="middle-center">{{'PREVIEW_UNAVAILABLE_HAVE_TO_BE_ONLINE' |translate : {text : webResource.name} }}</p>
    </ng-container>
  </ng-template>
  <ng-template #onlineView>
    <iframe *ngIf="webResource && webResource.type && webResource.type=='doc'"
      class='web-container' frameborder="0" [src]="webResource.url | safe:'resourceUrl'">
    </iframe>
    <ng2-pdfjs-viewer *ngIf="webResource && webResource.type && webResource.type=='pdf'" #pdfViewer
      download="false" [pdfSrc]="webResource.url" openFile="false" viewBookmark="false">
    </ng2-pdfjs-viewer>
    <p class="middle-center" *ngIf="webResource && (!webResource.type || webResource.type=='unknown')">
      {{'CONTACT_NO_PREVIEW_AVAILABLE_FOR' | translate}} <i>{{webResource.name}} - {{'PARTICI_NOT_SUPPORTED' | translate}}</i>
    </p>
  </ng-template>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'detailView'" [selectedView]="'genericRightPane'"></footer-toolbar>
<!-- </ion-footer> -->
