<ion-header>
  <ind-page-title [viewData]='titleBar' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ind-section-header [viewData]="contactAddressHeaderModel"></ind-section-header>
  <div *ngFor="let dayRecord of data" class="dayContainer">
    <p>
      {{dayRecord.day}}<ion-icon size="small" (click)="addNewData(dayRecord)" class="blueFont" name="add-outline"></ion-icon>
    </p>
    <div class="timeWrapper">
      <p class="timeContainer" *ngFor="let timeRecord of dayRecord.data">
        {{timeRecord.indskr_from_Formatted +" - "+ timeRecord.indskr_to_Formatted}}
        <ion-icon size="small" class="blueFont" (click)="deleteRecord(timeRecord, dayRecord)" name="close-outline"></ion-icon>
      </p>
    </div>
    <p class="modifiedInfoContainer" *ngIf="dayRecord.modifiedBy">
      <span class="modifiedInfo">
        {{'PRIVACY_LAST_MODIFIED' | translate}}: <i>{{dayRecord.modifiedBy}} | {{ formattedDate(dayRecord.modifiedOn) }}</i>
      </span>
    </p>
  </div>
</ion-content>
