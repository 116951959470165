export class Country {
    
    public countryName:string;
    public countryId:string;
    public countryCode?: string;

    constructor(raw?:any){
        if(raw){
            this.countryId = (raw['countryID']) ? raw['countryID'] : null;
            this.countryName = (raw['countryName']) ? raw['countryName'] : null;
            this.countryCode = (raw['countryCode']) ? raw['countryCode'] : null;
        }
    }
}