<ion-item-group id='listScroll'>
  <ion-item-divider color="light" class="list-title normal-divider">
      <ion-label class="title-text" *ngIf="!listFiltered; else filtered">
          {{ !isSearchedInput ? ('AL_ALL_ADJUSTMENTS' | translate) : ('AL_RESULTS' | translate) }} ({{filteredAdjustments?.length || '0'}})
          <span *ngIf="!isAdjustmentDataReady" class="data-load-indicator"><ion-spinner name="dots"></ion-spinner></span>
      </ion-label>
      <ng-template #filtered>
          <ion-label class="title-text">
              {{ !isSearchedInput ? filterText : ('AL_RESULTS' | translate) }} ({{filteredAdjustments?.length || '0'}})
          </ion-label>
      </ng-template>
  </ion-item-divider>
  <ng-container *ngIf="filteredAdjustments?.length > 0 else noSearchResult;">
      <ion-list class="shipments-list">
          <ion-item-group *ngFor="let adjustment of visibleAdjustments" (click)="handleAdjustmentSelection(adjustment)" [ngClass]="{'itemSelected': allocFeatureService.selectedShipment && adjustment.indskr_usershipmentallocation_v2id == allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id}" [id]="adjustment.indskr_usershipmentallocation_v2id">
              <ion-item>

                  <ion-label class="text-wrapper">
                      <ion-text class="primary-text">

                          <h3>{{'AL_ADJUSTMENT_NUMBER' | translate}} {{adjustment.indskr_shipmentnumber}}</h3>
                      </ion-text>
                      <p class="secondary-text">
                          <ion-text>{{adjustment.at_indskr_skuname || adjustment.indskr_skuname}} ({{adjustment.indskr_quantityadjusted || 0}})</ion-text>
                      </p>
                      <p class="tertiary-text">
                          <ion-row>
                              <ion-col size='3' class="tert-text-overflow">
                                  <ion-text class="tert-text-first">{{getAdjustedDate(adjustment)}}</ion-text>
                              </ion-col>
                          </ion-row>
                      </p>
                  </ion-label>
                  <div slot="end" *ngIf="adjustment.indskr_adjustedstatus === adjustmentStatus.Approved" class="acknowledged-state">
                      <img src="assets/imgs/contact_conn.png" class="acknowledged-state-icon" />
                  </div>
              </ion-item>
              <div class="separationLine"></div>
          </ion-item-group>
      </ion-list>
      <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="20%">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
  </ng-container>

  <ng-template #noSearchResult>
      <ind-no-search-result *ngIf="!noSearchResults && filteredAdjustments?.length === 0 else noData;" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  </ng-template>
  <ng-template #noData>
      <ion-list>
          <ion-item class="orders-list-empty">
              <div class="no-data-message" *ngIf="filteredAdjustments?.length == 0 && noSearchResults">
                  {{'AL_NO_ADJUSTMENTS' | translate}}
              </div>
          </ion-item>
      </ion-list>
  </ng-template>
</ion-item-group>
