import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerPositionList } from '@omni/classes/territory-management-list/customer-position-list.class';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { TerritoryManagementDataService } from '@omni/data-services/territory-management/territory-management.data.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { Subject } from 'rxjs';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { UIService } from '@omni/services/ui/ui.service';
import { takeUntil } from 'rxjs/operators';
import { TerritoryManagementListComponent, TerritoryManagementTabs } from '../territory-management-list/territory-management-list';
import { DeviceService } from '@omni/services/device/device.service';
import { PopoverController } from '@ionic/angular';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input';
import { TerritoryManangementListDetailsComponent } from '../territory-manangement-list-details/territory-manangement-list-details';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { TerritoryManagementPageComponent } from '@omni/pages/territory-management-page/territory-management-page.component';
import { AuthenticationService } from '@omni/services/authentication.service';

@Component({
  selector: 'territory-management-details',
  templateUrl: './territory-management-details.html',
  styleUrls: ['./territory-management-details.scss'],
})
export class TerritoryManagementDetailsComponent implements OnInit {
  pageTitle: IndPageTitleViewDataModel;
  public selectedCustomerPositionList: CustomerPositionList;

  tabsData: IndTabsDataModel[] = [{
    displayText: this.translate.instant('MANAGE_LIST'),
    value: 'manage-list'
  }, {
    displayText: this.translate.instant('INFO'),
    value: 'info',
    disable: false,
  }];
  territorySegment: string = this.tabsData[0].value;
  private ngDestroy$: any = new Subject<boolean>();

  @Input() selectedTab: TerritoryManagementTabs;
  @Input() from: any;
  @ViewChild(TerritoryManangementListDetailsComponent) territoryManangementListDetailsComponent: TerritoryManangementListDetailsComponent;

  constructor(
    public readonly translate: TranslateService,
    public footerService: FooterService,
    private territoryService: TerritoryManagementService,
    private datePipe: DatePipe,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    public readonly uiService: UIService,
    public readonly navService: NavigationService,
    private territoryDataService: TerritoryManagementDataService,
    private readonly device: DeviceService,
    private readonly popoverCtrl: PopoverController,
    private notificationService: NotificationService,
    private authService : AuthenticationService
  ) { }

  ngOnInit() {
    this.territoryService.selectedCustomerPostionListSubject.pipe(takeUntil(this.ngDestroy$)).subscribe(async (data) => {
      if (data) {
        this.selectedCustomerPositionList = data;
        this.initPageTitle();
      }
    });

    this.initPageTitle(); 
  }

  private initPageTitle() {
    this.pageTitle = {
      id: 'manage-list-title',
      title: this.selectedCustomerPositionList.listPeriod.indskr_name,
      controls: [],
    };
    if (this.from == PageName.NotificationDetailsComponent) {
      this.pageTitle.controls.push(
        // {
        //   id: "open",
        //   imgSrc: 'assets/imgs/open_in_new_white.svg',
        //   name: this.translate.instant('OPEN'),
        //   isDisabled: false,
        //   align: "right"
        // },
        {
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left',
        }
      );
    }

  }

  segmentChanged(selectedTab) {
    this.territorySegment = selectedTab;
  }

  async footerButtonClicked(id) {
    switch (id) {
      case 'saveterritory':
        this.saveManageList();
        break;
      case 'scrapterritory':
        this.scrapCustomerPositionList();
        break;
      case 'sendTerritoryForApproval':
        await this.sendForApproval();
        break;
      case 'approveManageList':
        this.updateApprovalStatus();
        break;
      case 'rejectManageList':
        this.updateApprovalStatus(true);
        break;
      default:
        break;
    }
  }

  private async sendForApproval() {
    let adjustLimitExeed = this.territoryService.currentListPeriod.indskr_nextlistperiod?.indskr_adjustmentlimit != undefined && this.selectedCustomerPositionList.indskr_percentagechange > this.territoryService.currentListPeriod.indskr_nextlistperiod?.indskr_adjustmentlimit;
    
    if(this.territoryService.currentListPeriodCustomerList){
      adjustLimitExeed = this.territoryService.currentListPeriod.indskr_adjustmentlimit != undefined && this.selectedCustomerPositionList.indskr_percentagechange > this.territoryService.currentListPeriod.indskr_adjustmentlimit
    }
    if (adjustLimitExeed) {
      this.notificationService.notify(this.translate.instant('ADJUST_LIMIT_EXEED'), 'Territory Management', 'top', ToastStyle.DANGER);
      return;
    }
    await this.territoryDataService.sendForApproval();
  }

  private async scrapCustomerPositionList() {
    const response = await this.showAlert(this.translate.instant('MANAGE_LIST_SCRAP_MESSAGE'), this.translate.instant('MANAGE_LIST_SCRAP_TITLE'));
    if (!response) return;
    if (this.selectedCustomerPositionList.indskr_customerpositionlistid) {
      await this.territoryDataService.scrapCustomerPositionList();
      if (this.territoryService.customerPositionList.getValue())
        this.territoryService.customerPositionList.next(this.territoryService.customerPositionList.getValue().filter((cpl: CustomerPositionList) => cpl.indskr_customerpositionlistid != this.selectedCustomerPositionList.indskr_customerpositionlistid));
    }
    this.goBack();
  }

  private saveManageList() {
    if (this.selectedCustomerPositionList.positionListTargets.filter(plt => !plt['deleted']).length == 0) return;
    this.territoryDataService.manageCustomerPostionList().then(() => {
      this.territoryManangementListDetailsComponent.isModified = false;
      this.territoryManangementListDetailsComponent.updateFooterBtnStatus();
    });
  }

  private async updateApprovalStatus(reject: boolean = false) {
    const popover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: {
        header: (!reject ? this.translate.instant("CONFIRM_APPROVE") : this.translate.instant("CONFIRM_REJECT")),
        message: this.translate.instant("ENTER_COMMENTS"),
        inputs: [],
        cancelText: this.translate.instant('CANCEL'),
        confirmText: (!reject ? this.translate.instant("APPROVE") : this.translate.instant("REJECT")),
        Placeholder: this.translate.instant('Comments'),
        isMandatory: (!reject ? false : true),
      },
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? event : null
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok') {
        if (reject && !res.data.selectedOtherItem) return;
        const payload = {
          indskr_reason: res.data.selectedOtherItem,
          statuscode: (!reject ? 548910001 : 548910002),
        }
        await this.uiService.displayLoader();
        await this.territoryDataService.updateApprovalActivity(payload, this.selectedCustomerPositionList.approvalActivityId);
        this.selectedCustomerPositionList.approvalActivityStatusCode = payload.statuscode;
        this.territoryService.selectedCustomerPostionListSubject.next(this.selectedCustomerPositionList);
        await this.uiService.dismissLoader();
      }
    });
  }

  async showAlert(message, title) {
    return await this.uiService.displayAlertConfirmation({
      title: title,
      message,
      cancelButtonText: this.translate.instant("CANCEL"),
      confirmButtonText: this.translate.instant("SCRAP")
    });
  }

  headerClickHandler(id) {
    if (id === 'close') {
      this.goBack();
    }
    else if (id === 'open') {
      this.openManageList();
    }
  }

  goBack() {
    if (this.navService.getActiveChildNavName() === ChildNavNames.TerritoryNavigation) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.TerritoryManagementPageComponent);
      this.navService.setChildNavRightPaneView(false);
    } else {
      this.navService.popChildNavPageWithPageTracking();
    }
    this.territoryService.selectedCustomerPostionListSubject.next(null);
  }

  openManageList() {
    this.navService.pushWithPageTracking(TerritoryManagementPageComponent, PageName.TerritoryManagementPageComponent, { from: PageName.NotificationDetailsComponent }, PageName.TerritoryManagementPageComponent);
  }


  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.territoryService.currentListPeriodCustomerList = false;
  }

}
