
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { DiskService } from "../../services/disk/disk.service";
import { TimeOffService } from "../../services/time-off/time-off.service";
import { LogService } from "../../services/logging/log-service";
import { Endpoints } from "../../../config/endpoints.config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Observable } from "rxjs";
import { TimeOffActivity, TimeOffStatus } from "../../classes/activity/timeoff.class";
import * as moment from "moment";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DeviceService } from "../../services/device/device.service";
import { DeltaService, EntityNames, EntitySyncInfo } from "../delta/delta.service";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { ActivityType } from '@omni/classes/activity/activity.class';
import { EventsService } from '@omni/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TimeOffDataService {

    private readonly DAYS_IN_FUTURE = 14;
    private readonly DAYS_PAST = 7;

    constructor(
        private disk: DiskService,
        private timeOffService: TimeOffService,
        private authenticationService: AuthenticationService,
        private logService: LogService,
        private http: HttpClient,
        private device: DeviceService,
        private deltaService: DeltaService,
        private event: EventsService,
        private translate: TranslateService,
    ) { }

    public async getTimeOffReason(loadFromDbOnly = false) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
            return;
        }

        // Device is Offline, Fetch from the diskService and update the reasonsList
        if (loadFromDbOnly) {
            //if(this.disk.check('timeOffReasons')){
                let offlineResponse = await this.disk.retrieve(DB_KEY_PREFIXES.TIMEOFF_REASONS);
                if(offlineResponse) this.timeOffService.mapTimeOffReasons(offlineResponse.raw);
            //}
            return;
        }

        // Device is online update diskService and update the reasonsList
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.TOT_REASON;
        let offlineFallback: boolean = false;
        const timeOffReasonSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.timeOffReason,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };
        try {
            let response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
            //console.log(response);
            this.timeOffService.mapTimeOffReasons(response);
            if (Array.isArray(response)) {
                timeOffReasonSyncInfo.totalSynced = response.length;
            }
            this.disk.updateOrInsert(DB_KEY_PREFIXES.TIMEOFF_REASONS, doc => ({ raw: response }))
                .catch((err)=> {
                    console.log("Error saving time-ff reasons to offline db!" , err);
                });
        } catch (httpError) {
            this.logService.logDebug(
                "Error fetching Time off reasons! Trying from DB",
                httpError
            );
            offlineFallback = true;

            this.deltaService.addSyncErrorToEntitySyncInfo(timeOffReasonSyncInfo, url, httpError);
        }

        this.deltaService.addEntitySyncInfo(timeOffReasonSyncInfo);
    }

    public async getTimeOffFromActivities(raw: any, isOnline?: boolean) {
        //this.timeOffService.mapTot(raw, isOnline);
    }

    public async getTeamTimeOffFromActivities(raw: any, isOnline?: boolean) {
        //this.timeOffService.mapTeamTot(raw, isOnline);
    }

    public async getUsersPositions(raw: object, saveToOffline:boolean = false) {
        if(raw && saveToOffline){
            this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_POSITIONS, doc => ({raw: raw}))
                .catch((err)=> {
                    console.log("Error saving tuser positions data to offline db!" , err);
                });
        }
        if (raw["positions"]) {
            // mapping child positions to the authenticate service user object
            this.authenticationService.pushChildPositionsToCurrentUSer(raw['positions'])
            this.timeOffService.mapPositions(raw["positions"]);
        }
        if (raw["users"]) {
            this.timeOffService.mapUsers(raw["users"]);
        }
    }

    public createNewTimeOff(tot?: TimeOffActivity, offlineId?: string): Observable<any> {
        console.log(tot)
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;
        let payload = {
            indskr_starttime: tot === undefined ? this.getTimeStamp("start") : tot.totStartTime.valueOf(),
            indskr_endtime: tot === undefined ? this.getTimeStamp("end") : tot.totEndTime.valueOf(),
            indskr_name: this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT)? this.translate.instant('TIME_OFF') : this.translate.instant('TIME_OFF'),
            indskr_positionid: this.authenticationService.user.xPositionID,
            // indskr_reason: 100000001,
            indskr_isalldayevent: tot === undefined ? true : tot.totIsAlldayEvent,
            indskr_local_timeoffrequestid: tot === undefined ? offlineId : tot.offlineTimeOffRequestId,
        };
        if(tot?.timeOffReason) {
          payload['indskr_reason'] = tot.timeOffReason;
          if(this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT)) {
            payload['indskr_name'] = tot.reason + ` - ${this.translate.instant('TIME_OFF')}`;
          }
        }
        let response = this.http.post(url, payload).pipe(map(res => {
            return res;
        }));
        console.log('Initiated New TimeOff request');
        return response;
    }

    public reOpenTimeOff(tot: TimeOffActivity): Observable<any> {
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;
        let payload = {
            "indskr_timeoffrequestid": tot.timeOffRequestId,
            "statecode": 0,
            "statuscode": 100000000,
            "indskr_positionid": tot.positionId,
        }
        try {
            let response = this.http.patch(url, payload).pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }));
                console.log('Reoped timeoff');
                return response;
        } catch (error) {
            console.log(error);
        }
    }

    public updateTimeOff(tot: TimeOffActivity, isForReview: boolean, isAutoApproval: boolean): Observable<any> {
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;
        let payload = {
            "indskr_starttime": new Date(tot.totStartTime).getTime(),
            "indskr_endtime": new Date(tot.totEndTime).getTime(),
            "indskr_reason": tot.timeOffReason,
            "indskr_name": tot.name,
            "indskr_positionid": tot.positionId,
            "indskr_isalldayevent": tot.totIsAlldayEvent,
            "indskr_timeoffrequestid": tot.timeOffRequestId,
            "statecode": 0,
            "statuscode": tot.statuscode,
            "indskr_comments": tot.comments
        };
        if (isForReview && !isAutoApproval && tot.statuscode === TimeOffStatus.Open) {
            payload.statuscode = TimeOffStatus.InReview;
            payload.statecode = 1;
        } else if (isAutoApproval && tot.statuscode === TimeOffStatus.Open) {
            payload.statuscode = TimeOffStatus.Approved;
            payload.statecode = 1;
            payload['indskr_approver'] = this.authenticationService.user.xSystemUserID;
            payload['indskr_isautoapproved'] = true;
            payload['indskr_approvaldate'] = new Date().valueOf();
            console.log('Submitted TimeOff request for APPROVAL ');
        }
        try {
            let response = this.http.patch(url, payload).pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }));
                console.log('Submitted TimeOff request for review');
                return response;
        } catch (error) {
            console.log(error);
        }


    }

    public cancelReviewTimeOff(tot: TimeOffActivity): Observable<any> {
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;
        let payload = {
            "indskr_starttime": new Date(tot.totStartTime).getTime(),
            "indskr_endtime": new Date(tot.totEndTime).getTime(),
            "indskr_reason": tot.timeOffReason,
            "indskr_name": tot.name,
            "indskr_positionid": tot.positionId,
            "indskr_isalldayevent": tot.totIsAlldayEvent,
            "indskr_timeoffrequestid": tot.timeOffRequestId,
            "statecode": 0,
            "statuscode": TimeOffStatus.Open
        };

        try {
            let response = this.http.patch(url, payload).pipe(map(
                res => {
                    return res;
                },
                err => {
                    return err;
                }));
                console.log('Submitted TimeOff request for review');
                return response;
        } catch (error) {
            console.log(error);
        }


    }

    public approveTimeOff(tot: TimeOffActivity, approve: boolean): Observable<any> {
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;

        let payload = {
            "indskr_starttime": new Date(tot.totStartTime).getTime(),
            "indskr_endtime": new Date(tot.totEndTime).getTime(),
            "indskr_reason": tot.timeOffReason,
            "indskr_name": tot.name,
            "indskr_positionid": tot.positionId,
            "indskr_isalldayevent": tot.totIsAlldayEvent,
            "indskr_timeoffrequestid": tot.timeOffRequestId,
            "statecode": 1,
            "statuscode": TimeOffStatus.Approved,
            "indskr_approvercomments": tot.approverComments
        };
        if (!approve) {
            payload.statuscode = TimeOffStatus.Open;
            payload.statecode = 0;
        } else {
            payload['indskr_approver'] = this.authenticationService.user.xSystemUserID;
            payload['indskr_isautoapproved'] = false;
            payload['indskr_approvaldate'] = new Date().valueOf();
        }
        let response = this.http.patch(url, payload).pipe(map(
            res => {
                return res;
            },
            err => {
                return err;
            }));
        return response;
    }

    public async deleteTimeOff(timeOffRequestID): Promise<any> {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.DELETE_TOT.replace('{timeOffRequestID}', timeOffRequestID);

        let response = await this.http.delete(url).toPromise();
        console.log('Initiated Delete TimeOff request');
        return response;
    }

    public getTimeStamp(type: string, tot?: TimeOffActivity): number {
        if (tot != undefined && tot != null) {
            if (type === "start") {
                let start = moment(tot.totStartTime).set('hour', 0).set('minute', 0).set('second', 0).toDate();
                return start.valueOf();
            }
            else if (type === "end") {
                let end: Date = moment(tot.totStartTime).set('hour', 23).set('minute', 59).set('second', 59).toDate();
                return end.valueOf();
            }
            else if (type === "halfStart") {
                let meridian = moment(new Date).format('A');
                if (meridian === 'PM') {
                    let half: Date = moment(tot.totStartTime).set('hour', 12).set('minute', 0).set('second', 0).toDate();
                    return half.valueOf();
                }
                else {
                    let half: Date = moment(tot.totStartTime).set('hour', 0).set('minute', 0).set('second', 0).toDate();
                    return half.valueOf();
                }

            }
            else if (type === "halfEnd") {
                let meridian = moment(new Date).format('A');
                if (meridian === 'PM') {
                    let half: Date = moment(tot.totStartTime).set('hour', 23).set('minute', 59).set('second', 59).toDate();
                    return half.valueOf();
                }
                else {
                    let half: Date = moment(tot.totStartTime).set('hour', 11).set('minute', 59).set('second', 59).toDate();
                    return half.valueOf();
                }
            }
            else if (type === "otherStart") {
                let half: Date = moment(tot.totStartTime).set('hour', 8).set('minute', 0).set('second', 0).toDate();
                return half.valueOf();
            }
            else if (type === "otherEnd") {
                let half: Date = moment(tot.totStartTime).set('hour', 9).set('minute', 0).set('second', 0).toDate();
                return half.valueOf();
            }
            else if (type === "current") {
                let current: Date = moment(tot.totStartTime).toDate();
                return current.valueOf();
            }
            else if (type === "none") {
                let none: Date = moment(tot.totStartTime).set('hour', 12).set('minute', 0).set('second', 0).toDate();
                return none.valueOf();
            }
            else {
                let start = moment(tot.totStartTime).set('hour', 0).set('minute', 0).set('second', 0).toDate();
                return start.valueOf();
            }
        }
        else {
            let today: Date = new Date();
            if (type === "start") {
                let start = moment(today).set('hour', 0).set('minute', 0).set('second', 0).toDate();
                return start.valueOf();
            }
            else if (type === "end") {
                let end: Date = moment(today).set('hour', 23).set('minute', 59).set('second', 59).toDate();
                return end.valueOf();
            }
            else if (type === "halfStart") {
                let half: Date = moment(today).set('hour', 12).set('minute', 0).set('second', 0).toDate();
                return half.valueOf();
            }
            else if (type === "halfEnd") {
                let half: Date = moment(today).set('hour', 23).set('minute', 59).set('second', 59).toDate();
                return half.valueOf();
            }
            else if (type === "current") {
                let current: Date = moment(new Date()).toDate();
                return current.valueOf();
            }
            else if (type === "none") {
                let none: Date = moment().set('hour', 12).set('minute', 0).set('second', 0).toDate();
                return none.valueOf();
            }
            else {
                let start = moment(today).set('hour', 0).set('minute', 0).set('second', 0).toDate();
                return start.valueOf();
            }
        }
    }

    async createNewTimeOffFromOfflineData(tot?: TimeOffActivity): Promise<any> {
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;
        let payload = {
            indskr_starttime: tot.totStartTime.valueOf(),
            indskr_endtime: tot.totEndTime.valueOf(),
            indskr_name: tot.name,
            indskr_positionid: this.authenticationService.user.xPositionID,
            indskr_reason: tot.timeOffReason,
            indskr_isalldayevent: tot.totIsAlldayEvent,
            indskr_local_timeoffrequestid: tot.offlineTimeOffRequestId,
        };

        let response: any;
        try {
            response = await this.http.post(url, payload).toPromise();
        } catch (error) {
            console.error('createNewTimeOffFromOfflineData: ', error);
            return Promise.reject(error);
        }

        if (response) {
            this.timeOffService.saveMyTimeOff(tot, true, response);
            this.timeOffService.setSelectedTot(tot);
            this.timeOffService.deleteTimeOffFromOfflineDoc(tot);
        }
    }

    async uploadOneOfflineTimeOff(tot: TimeOffActivity): Promise<any> {
        if (this.device.isOffline) return;

        let offlineTimeOffs = await this.disk.loadOfflineTimeOffs();

        if (!offlineTimeOffs || !offlineTimeOffs['myTos'] || !Array.isArray(offlineTimeOffs['myTos']) || offlineTimeOffs['myTos'].length === 0) return;

        const dataIdx = offlineTimeOffs['myTos'].findIndex(t => t.indskr_local_timeoffrequestid === tot.offlineTimeOffRequestId);

        if (dataIdx < 0) {
            console.warn(`uploadOneOfflineTimeOff: Coulnd't find ID ${tot.offlineTimeOffRequestId} from offline data`);
            return;
        }

        let payload = {
            indskr_starttime: tot.totStartTime.valueOf(),
            indskr_endtime: tot.totEndTime.valueOf(),
            indskr_name: tot.name,
            indskr_positionid: this.authenticationService.user.xPositionID,
            indskr_reason: tot.timeOffReason,
            indskr_isalldayevent: tot.totIsAlldayEvent,
            indskr_local_timeoffrequestid: tot.offlineTimeOffRequestId,
        };

        let response: any;
        try {
            const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.tot.INITIATE_TOT;
            response = await this.http.post(url, payload).toPromise();
        } catch (error) {
            console.error('uploadOneOfflineTimeOff: ', error);
            return Promise.reject('');
        }

        if (response) {
            // Save to db
            await this.timeOffService.saveMyTimeOff(tot, true, response);
            // Remove from offline doc
            offlineTimeOffs['myTos'].splice(dataIdx, 1);
            await this.disk.updateDocWithIdAndRev(offlineTimeOffs);
        } else {
            console.error('uploadOneOfflineTimeOff: It seems request succeeded but there was no response from server.');
            return Promise.reject('');
        }
    }

    public async getTeamTimeOffOnline():Promise<any>{
      let headers = new HttpHeaders();
      headers = headers.set('Sync-Service', 'true');
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_TEAM_ACTIVITIES_FOR_TOOLS_BY_ACTIVITY_TYPES;
      const dataRange = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
      url = url.replace('{startDate}', dataRange.from);
      url = url.replace('{endDate}', dataRange.to);
      //url = url.replace('{activityTypes}', 'case');
      url = url.replace('{teamActivity}', 'timeOff');
      url = url.replace('{positionIDs}', positions.toString());
      try {
        const response =  await this.http.get(url, { headers }).toPromise();
        if(response && response['teamTimeOffs'] && Array.isArray(response['teamTimeOffs'])){
          this.timeOffService.teamTot = [];
          response['teamTimeOffs'].forEach(rawTeamTimeOff=> {
            /* Pouch db doesnt allow to store keyword starting with _ */
            const teamTimeOff = new TimeOffActivity(rawTeamTimeOff, ActivityType.TimeOff);
            this.timeOffService.teamTot.push(teamTimeOff);
          })
        }
      } catch (error) {
        console.log('Fetch Team Cases for Tool Error: '+error)
      }
      return;
    }
}
