<div #tableContainer class="table-container">
  <table class="static-table">
    <thead>
      <tr>
        <th class="static-column">{{'PRODUCT' | translate}}</th>
        <th class="static-column">{{'SKU_SKU_CODE' | translate}}</th>
        <th>{{'INVENTORY_OFFTAKE' | translate}}</th>
        <th>{{'STORE_INVENTORY' | translate}}</th>
        <th>{{'DISTRIBUTE_OR_NOT' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let offtake of offTakeCheckCollections">
        <td class="static-column">{{offtake.productName}}</td>
        <td class="static-column">
          <div>
            <label>{{offtake.skuName}}</label><br>
            <label class="secondary-text">{{offtake.skuCode}}</label>
          </div>
        </td>
        <td>
          <input [disabled]="!isEditable" class="input-control" (input)="handleInputEvent($event)"
            [(ngModel)]="offtake.indskr_inventoryofftake" type="number" min="1"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
          </td>
        <td>
          <input [disabled]="!isEditable" class="input-control" (input)="handleInputEvent($event)"
          [(ngModel)]="offtake.indskr_storeinventory" type="number" min="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
        </td>
        <td>
          <ion-select class="timelineFilter" [disabled]="!isEditable" class="input-control" interface="popover"
            (ionChange)="handleInputEvent($event)" [(ngModel)]="offtake.indskr_distributedornot">
            <ion-select-option *ngFor="let option of options" [value]="option.value">{{ option.label}}</ion-select-option>
        </ion-select>
        </td>
      </tr>
    </tbody>
  </table>
</div>