import {Injectable} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { differenceInDays, setSeconds } from "date-fns";
import { Subject } from "rxjs";
import * as moment from "moment";

export class DateFormat {
  public format: string;
  public dateOrder: string;
  public display: string;
  public description: string;
  public calendarFormat: string;
  public dateFnFormat: string;
}

export class TimeFormat {
  public format: string;
  public segment: string;
  public dateFnFormat: string;
  public display?: string;
}

export class SymbolPosition {
  public position: string;
  public value: string;
}

export class DecimalPosition {
  public precision: boolean;
  public toFixed: number;
  public value: string;
}

export enum DATE_TIME_CONSTANTS {
  DATE="DATE", TIME="TIME", DATE_TIME="DATE_TIME", PICKER_FORMAT="PICKER_FORMAT", DISPLAY_TIME="DISPLAY_TIME"
}

@Injectable({
  providedIn: 'root'
})
export class DateTimeFormatsService {

  public updateSelectedTime = new Subject<any>();
  updateSelectedTime$ = this.updateSelectedTime.asObservable();
  public updateSelectedNewTimeTimeOffRequest = new Subject<any>();
  updateSelectedNewTimeTimeOffRequest$ = this.updateSelectedNewTimeTimeOffRequest.asObservable();
  public updateSelectedTimeTimeOffRequest = new Subject<any>();
  updateSelectedTimeTimeOffRequest$ = this.updateSelectedTimeTimeOffRequest.asObservable();
  public updateSelectedTimeMessage = new Subject<any>();
  updateSelectedTimeMessage$ = this.updateSelectedTimeMessage.asObservable();
  public updateSelectedTimeSurgeryOrder = new Subject<any>();
  updateSelectedTimeSurgeryOrder$ = this.updateSelectedTimeSurgeryOrder.asObservable();
  public updateSelectedTimeEvent = new Subject<any>();
  updateSelectedTimeEvent$ = this.updateSelectedTimeEvent.asObservable();
  public updateSelectedTimeConfiguredForm = new Subject<any>();
  updateSelectedTimeConfiguredForm$ = this.updateSelectedTimeConfiguredForm.asObservable();
  public updateSelectedTimeConfiguredSectionForm = new Subject<any>();
  updateSelectedTimeConfiguredSectionForm$ = this.updateSelectedTimeConfiguredSectionForm.asObservable();
  public updateSelectedTimeAllocationOrder = new Subject<any>();
  updateSelectedTimeAllocationOrder$ = this.updateSelectedTimeAllocationOrder.asObservable();
  
  public isNewActivity: boolean = false;
  limitedMinTimeValue: string = "00:00 AM";
  limitedMinTimeValueForMessage: string = "00:00 AM";
  public updateLimitedMinTime = new Subject<any>();
  updateLimitedMinTime$ = this.updateLimitedMinTime.asObservable();

  public dateFormat = new Subject<string>();
  public timeFormat = new Subject<string>();
  public symbolPos = new Subject<string>();
  public decimalPos = new Subject<string>();
  public allFormats: DateFormat[] = [
    {
      "format": "1",
      "dateOrder": "MM/dd/yyyy",
      "display": "MM/DD/YY",
      "calendarFormat": "mm/dd/yy",
      "dateFnFormat": "MM/DD/YYYY",
      "description": "Month-Day-Year with leading zeros (02/17/2009)"
    },
    {
      "format": "2",
      "dateOrder": "dd/MM/yyyy",
      "display": "DD/MM/YY",
      "calendarFormat": "dd/mm/yy",
      "dateFnFormat": "DD/MM/YYYY",
      "description": "Day-Month-Year with leading zeros (17/02/2009)"
    },
    {
      "format": "3",
      "dateOrder": "yyyy/MM/dd",
      "display": "YY/MM/DD",
      "calendarFormat": "yy/mm/dd",
      "dateFnFormat": "YYYY/MM/DD",
      "description": "Year-Month-Day with leading zeros (2009/02/17)"
    },
    {
      "format": "4",
      "dateOrder": "MMMM d, yyyy",
      "display": "Month D, Yr",
      "calendarFormat": "MM dd, yy",
      "dateFnFormat": "MMMM d, YYYY",
      "description": "Month name-Day-Year with no leading zeros \n(February 17, 2009)"
    },
    {
      "format": "5",
      "dateOrder": "M/d/yyyy",
      "display": "M/D/YY",
      "calendarFormat": "m/d/yy",
      "dateFnFormat": "M/d/YYYY",
      "description": "Month-Day-Year with no leading zeros (2/17/2009)"
    },
    {
      "format": "6",
      "dateOrder": "d/M/yyyy",
      "display": "D/M/YY",
      "calendarFormat": "d/m/yy",
      "dateFnFormat": "d/M/YYYY",
      "description": "Day-Month-Year with no leading zeros (17/2/2009)"
    },
    {
      "format": "7",
      "dateOrder": "yyyy/M/d",
      "display": "YY/M/D",
      "calendarFormat": "yy/m/d",
      "dateFnFormat": "YYYY/M/d",
      "description": "Year-Month-Day with no leading zeros (2009/2/17)"
    },
    /*{
      "format": "8",
      "dateOrder": " M/d/yyyy",
      "display": "bM/bD/YY",
      "description": "Month-Day-Year with spaces instead of leading zeros \n( 2/17/2009)"
    },
    {
      "format": "9",
      "dateOrder": "d/ M/yyyy",
      "display": "MM/DD/YY",
      "description": "Day-Month-Year with spaces instead of leading zeros\n(17/ 2/2009)"
    },*/
    {
      "format": "default",
      "dateOrder": "MMM dd, yyyy",
      "display": "MMM DD, YYYY",
      "calendarFormat": "M dd, yy",
      "dateFnFormat": "MMM dd, YYYY",
      "description": "Month-Day-Year with no separators (Dec 27, 2019)"
    },
    /*{
      "format": "A",
      "dateOrder": "yyyy/ M/dd",
      "display": "MM/DD/YY",
      "description": "Year-Month-Day with spaces instead of leading zeros (2009/ 2/17)"
    },
    {
      "format": "B",
      "dateOrder": "MMddyyyy",
      "display": "MMDDYY",
      "description": "Month-Day-Year with no separators (02172009)"
    },
    {
      "format": "C",
      "dateOrder": "ddMMyyyy",
      "display": "DDMMYY",
      "description": "Day-Month-Year with no separators (17022009)"
    },
    {
      "format": "D",
      "dateOrder": "yyyyMMdd",
      "display": "YYMMDD",
      "description": "Year-Month-Day with no separators (20090217)"
    },
    {
      "format": "E",
      "dateOrder": "MMMddyyyy",
      "display": "MonDDYY",
      "description": "Month abbreviation-Day-Year with leading zeros (Feb172009)"
    },
    {
      "format": "F",
      "dateOrder": "ddMMMyyyy",
      "display": "DDMonYY",
      "description": "Day-Month abbreviation-Year with leading zeros (17Feb2009)"
    },
    {
      "format": "G",
      "dateOrder": "yyyyMMMdd",
      "display": "YYMonDD",
      "description": "Year-Month abbreviation-Day with leading zeros (2009Feb17)"
    },*/
    {
      "format": "J",
      "dateOrder": "dd MMMM, yyyy",
      "display": "D Month, Yr",
      "calendarFormat": "dd MM, yy",
      "dateFnFormat": "DD MMMM, YYYY",
      "description": "Day-Month name-Year (17 February, 2009)"
    },
    {
      "format": "K",
      "dateOrder": "yyyy, MMMM dd",
      "display": "Yr, Month D",
      "calendarFormat": "yy, MM dd",
      "dateFnFormat": "YYYY, MMMM DD",
      "description": "Year-Month name-Day (2009, February 17)"
    },
    {
      "format": "L *",
      "dateOrder": "MMM-dd-yyyy",
      "display": "Mon-DD-YYYY",
      "calendarFormat": "M-dd-yy",
      "dateFnFormat": "MMM-DD-YYYY",
      "description": "Month abbreviation, Day with leading zeros, Year\n(Feb 17, 2009)"
    },
    {
      "format": "M *",
      "dateOrder": "dd-MMM-yyyy",
      "display": "DD-Mon-YYYY",
      "calendarFormat": "dd-M-yy",
      "dateFnFormat": "DD-MMM-YYYY",
      "description": "Day with leading zeros, Month abbreviation, Year\n17 Feb, 2009."
    },
    {
      "format": "N",
      "dateOrder": "yyyy-MMM-dd",
      "display": "YYYYY-Mon-DD",
      "calendarFormat": "yy-M-dd",
      "dateFnFormat": "YYYY-MMM-DD",
      "description": "Year, Month abbreviation, Day with leading zeros\n(2009, Feb 17) This format defaults to a two-digit year, but can be overridden to have four digits."
    },
    /*{
      "format": "O",
      "dateOrder": "MMM dd, yyyy",
      "display": "Mon DD, YYYY",
      "description": "Month abbreviation, Day with leading zeros, Year\n(Feb 17, 2014)"
    },*/
    {
      "format": "P",
      "dateOrder": "dd MMM, yyyy",
      "display": "DD Mon, YYYY",
      "calendarFormat": "dd M, yy",
      "dateFnFormat": "DD MMM, YYYY",
      "description": "Day with leading zeros, Month abbreviation, Year\n(17 Feb, 2014)"
    },
    {
      "format": "Q",
      "dateOrder": "yyyy, MMM dd",
      "display": "YYYY, Mon DD",
      "calendarFormat": "yy, M dd",
      "dateFnFormat": "YYYY, MMM DD",
      "description": "Year, Month abbreviation, Day with leading zeros\n(2014, Feb 17)"
    }
  ];
  public timeFormatSegments: TimeFormat[] = [
    {
      format: "12_HOUR",
      display: "12-Hour",
      segment: "hh:mm aa",
      dateFnFormat: 'hh:mm A',
    },
    {
      format: "24_HOUR",
      display: "24-Hour",
      segment: "HH:mm",
      dateFnFormat: "HH:mm"
    }
  ];
  public symbolsPos: SymbolPosition[] = [{position: "left", value: "$0.00"}, {position: "right", value: "0.00$"}];
  public decimalsPos: DecimalPosition[] = [{precision: false, toFixed: 0, value: "0"}, {precision: true, toFixed: 1, value: "0.0"}, {precision: true, toFixed: 2, value: "0.00"}, {precision: true, toFixed: 3, value: "0.000"}, {precision: true, toFixed: 4, value: "0.0000"}];

  public selected: DateFormat;
  public timeFormatSelected: TimeFormat;
  public symbolPosSelected: SymbolPosition;
  public decimalPosSelected: DecimalPosition;
  private defaultFormat: DateFormat = {
    "format": "default",
    "dateOrder": "MMM dd, yyyy",
    "display": "MMM DD, YYYY",
    "calendarFormat": "M dd, yy",
    "dateFnFormat": "MMM DD, YYYY",
    "description": "Month-Day-Year with no separators (Dec 27, 2019)"
  };
  private defaultTimeFormat: TimeFormat = { format: "12_HOUR", segment: "hh:mm aa", dateFnFormat: 'hh:mm A' };
  private defaultSymbolPos: SymbolPosition = {position: "left", value: "$0.00"};
  private defaultDecimalPos: DecimalPosition = {precision: true, toFixed: 2, value: "0.00"};
  
  public isDateFormatSetViaApp:boolean =  false;

  constructor(
    public translate:TranslateService,
  ) {
    this.selected = this.defaultFormat;
    this.timeFormatSelected = this.defaultTimeFormat;
    this.symbolPosSelected = this.defaultSymbolPos;
    this.decimalPosSelected = this.defaultDecimalPos;
  }


  dateFormats(): DateFormat[] {
    return this.allFormats;
  }

  timeFormats(): TimeFormat[] {
    return this.timeFormatSegments;
  }

  symbolPositions(): SymbolPosition[] {
    return this.symbolsPos;
  }

  decimalPositions(): DecimalPosition[] {
    return this.decimalsPos;
  }

  public get selectedFormat(): DateFormat {
    return this.selected;
  }

  public setDateFormat(value: DateFormat) {
    this.selected = value;
  }

  public get selectedTimeFormat(): TimeFormat {
    return this.timeFormatSelected;
  }

  public setTimeFormat(value: TimeFormat) {
    this.timeFormatSelected = value;
  }

  public get selectedSymbolPos(): SymbolPosition {
    return this.symbolPosSelected;
  }

  public setSymbolPos(value: SymbolPosition) {
    this.symbolPosSelected = value;
  }

  public get selectedDecimalPos(): DecimalPosition {
    return this.decimalPosSelected;
  }

  public setDecimalPos(value: DecimalPosition) {
    this.decimalPosSelected = value;
  }

  public get date(): string {
    return this.selected.dateOrder;
  }

  public get dateToUpper(): string {
    return this.selected.dateOrder.toUpperCase();
  }

  public get time(): string {
    return this.timeFormatSelected.segment;
  }

  public get timeToUpper(): string {
    return this.timeFormatSelected.format == '12_HOUR' ? 'h:mm A' : 'HH:mm';
  }
  // formatted hour for ngx-material-timepicker
  public get timeToUpperForTimePicker(): number {
    return this.timeFormatSelected.format == '12_HOUR' ? 12 : 24;
  }

  public get dateTimeToUpper(): string {
    return this.dateToUpper + " " + this.timeToUpper;
  }

  public get dateTime(): string {
    return this.date + " " + this.time;
  }

  public get is12HourFormat(): boolean {
    return this.timeFormatSelected.format == '12_HOUR';
  }

  public getFormattedNumber(value:number) :string{
    let str = "0.00";
    if(value){
      str =  value.toFixed(this.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return str;
  }

  public getSymbolValueObj(value: string) : any {
    let symbol =  value.match(/[^\d,.]/g) ?value.match(/[^\d,.]/g).join('') : '';
    value = value.split('.')[0]+'.00'
    // @ts-ignore
    let val = (value.match(/\d/g).join('') / 100)
    .toFixed(this.selectedDecimalPos.toFixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return {value: val, symbol: symbol};
  }

  public formattedCurrency(value: string) : string {
    let obj = this.getSymbolValueObj(value);
    return this.selectedSymbolPos.position == "left"
      ? obj.symbol + obj.value
      : obj.value + obj.symbol;
  }

  getFormattedTimeInterval(startTime: Date | string, endTime: Date | string): string {
    let duration;
    let days: any = differenceInDays(endTime, startTime);
    let timeDifference: Date = new Date((new Date(endTime).setSeconds(0).valueOf() - new Date(startTime).setSeconds(0).valueOf()));
    let minutes: any = timeDifference.getUTCMinutes();
    let hours: any = timeDifference.getUTCHours();
    if (days === 0) {
      days = "";
    } else if (days <= 1) {
      if (hours !=0 || minutes !=0) {
        days = days + " " + this.translate.instant('DAY') + ", ";
      } else {
        days = days + " " + this.translate.instant('DAY');
      }
    } else {
      if (hours !=0 || minutes !=0) {
        days = days + " " + this.translate.instant('DAYS') + ", ";
      } else {
        days = days + " " + this.translate.instant('DAYS');
      }
    }
    if (hours === 0) {
      hours = "";
    } else if (hours <= 1) {
      if (minutes === 0) {
        hours = hours + " " + this.translate.instant('HOUR');
      } else {
        hours = hours + " " + this.translate.instant('HOUR') + ", ";
      }
    }
    else {
      if (minutes === 0) {
        hours = hours + " " + this.translate.instant('HOURS');
      } else {
        hours = hours + " " + this.translate.instant('HOURS') + ", ";
      }
    }
    if (minutes === 0) {
      minutes = "";
    } else {
      minutes = minutes + " " + this.translate.instant('MINUTES');
    }
    duration = days + "" + hours + "" + minutes;
    return duration;
  }

  getFormattedTimeValue(selectedTime: string|Date): string {
    let formattedTime: string;
    let dateTimeValue = new Date(selectedTime);
    if (this.timeToUpperForTimePicker == 24 && dateTimeValue.getHours() == 0) {
      formattedTime = "00:" + dateTimeValue.getMinutes();
    } else formattedTime = new Date(selectedTime).toLocaleTimeString('en-US', { hour12: this.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return formattedTime;
  }

  formatDateForFetchXML(date){
   return moment(date).format("YYYY-MM-DD")
  }

  formatDateForWithStartTime(date){
   return moment(date).startOf('day').toDate()
  }

  formatDateForWithEndTime(date){
    return moment(date).endOf('day').toDate()
   }

   private _isDateForamt(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  isTimestampFormat(str: string): boolean {
    // Check if the string is a valid timestamp (epoch time)
    const timestampRegex: RegExp = /^\d+$/;
  
    if (timestampRegex.test(str)) {
      const timestamp: number = Number(str);
      const tempDate: Date = new Date(timestamp);
  
      // Check if the timestamp is valid
      return !isNaN(timestamp) && this._isDateForamt(tempDate);
    }
  
    return false; // Not a valid timestamp format
  } 
}
