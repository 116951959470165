import _ from 'lodash';

export class AssetNote {

  public annotationid: string;
  public subject: string;
  public notetext: string;
  public filename: string;
  public isdocument: boolean;
  public modifiedby: string;
  public modifiedbyname: string;
  public modifiedon: string;
  public createdon: string;
  public ownerName: string;
  public ownerid: string;
  public filesize: number;
  public mimetype: string;

  constructor(raw) {
    this.annotationid = raw['annotationid'];
    this.createdon = raw['createdon'];
    this.subject = raw['subject'];
    this.notetext = raw['notetext'];
    this.filename = raw['filename'];
    this.ownerid = raw['ownerid_value'] || raw['ownerid'];
    this.filesize = raw['filesize'];
    this.mimetype = raw['mimetype'];
    this.isdocument = !_.isEmpty(raw['filename']);
    this.modifiedbyname = raw['modifiedby_value@OData.Community.Display.V1.FormattedValue'] || raw.modifiedbyname;
    this.modifiedby = raw['modifiedby_value'] || raw.modifiedby;
    this.modifiedon = raw['modifiedon'];
    this.ownerName = raw['createdby_value@OData.Community.Display.V1.FormattedValue'] || raw['ownerName'];
  }
}

export class CustomerAssetNote extends AssetNote {
  public customerassetid: string;
  constructor(raw) {
    super(raw);
    this.customerassetid = raw['customerassetid'];
  }
}

export class CustomerAssetTransferNote extends AssetNote {
  public activityid: string;
  constructor(raw) {
    super(raw);
    this.activityid = raw['activityid'];
  }
}