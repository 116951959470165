<div class="card-content">
  <div [ngClass]="{ 'card-label': viewData.primaryText, 'card-empty-label': viewData.primaryText === '' }" class="card-label">
    {{viewData.primaryText}}
  </div>

  <div class="child-data" *ngIf="viewData.relationLabel">
    <div class="child-data-label">
      {{viewData.relationLabel}}
    </div>
    <div class="child-data-value">
      {{viewData.relationValue}}
    </div>
  </div>

  <div class="child-data" *ngFor="let childItem of viewData.childItems">
    <div class="child-data-label">
      {{childItem.label}}
    </div>
    <div class="child-data-value">
      {{childItem.value}}
    </div>
  </div>
</div>
<div class="card-right-icon" *ngIf="viewData.showRightArrow" (click)="onContentClick($event)">
  <ion-icon slot="end" class="chevron-forward-outline" [ngClass]="!viewData.readOnly ? 'arrow-icon' : 'arrow-icon-disable'" name="chevron-forward-outline"></ion-icon>
</div>
