import { IAllocationAdjustment } from './../../../interfaces/allocation/allocation-adjustment.interface';
import { AllocationFeatureService } from './../../../services/sample/allocation.feature.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AllocationShipmentService } from '../../../services/sample/allocation-shipment.service';
import { SampleService } from '../../../services/sample/sample.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { UIService } from '../../../services/ui/ui.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { format, isValid, subDays } from 'date-fns';
import { Lot } from '../../../classes/sample/lot.class';
import { DeviceService } from '../../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, AlertController, ModalController, PopoverController } from '@ionic/angular';
import { SelectListDetail } from '../../../models/select-list-detail-model';
import { SelectListComponent } from '../../shared/select-list/select-list';
import { SelectListData } from '../../popover/popover';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import * as moment from 'moment';
import { AllocationAdjustService } from '@omni/services/sample/allocation-adjust.service';
import { AllocationAdjustForm } from '@omni/classes/sample/allocation-adjust.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { AuthenticationService } from '../../../services/authentication.service';
import { AllocationAdjustmentDataService } from '../../../data-services/sample/allocation-adjust.data.service';
import { Events } from '../../../services/events/events';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SampleDataService } from '../../../data-services/sample/sample.data.service';
import { DatePipe } from '@angular/common';
import { AdjustmentStatus, TransferType } from '../../../enums/allocation/allocation.enum';
import { isEmpty } from 'lodash';
import { ActivityService } from '@omni/services/activity/activity.service';
import _ from 'lodash';
import { AllocationInventoryDataService } from '@omni/data-services/sample/allocation-inventory.data.service';
import { AllocationInventoryService } from '@omni/services/sample/allocation-inventory.service';

@Component({
  selector: 'allocation-adjust',
  templateUrl: 'allocation-adjust.html',
  styleUrls: ['allocation-adjust.scss']
})
export class AllocationAdjustComponent implements OnInit {

  pillScrollContainer;

  adjustStatus = AdjustmentStatus;
  allocationAdjustmentForm: AllocationAdjustForm;
  alert: HTMLIonAlertElement;
  loader: HTMLIonLoadingElement;
  private _selectProductModal;
  private _selectReasonPopover;
  allocationAdjustPanePageTitle: IndPageTitleViewDataModel;
  detailsHeader: IndSectionHeaderViewDataModel;
  availableLotsHeader: IndSectionHeaderViewDataModel;
  selectedLotsHeader: IndSectionHeaderViewDataModel;
  private _updatedFieldData = false;
  focusedInputElement: HTMLElement;
  adjustStatusTxt: string = 'Open';
  subscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  private lotsUpdated = false;
  @ViewChild('Content', {static:true}) private content: HTMLIonContentElement;
  isAdjustment = false;
  private isClickedProduct: boolean = false;
  private sampleLastDispatchLimitNum: number = 0;
  formattedDateInfoLabelForSampleLot: string;

  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case 'adjustShipment':
        this.sendForAnApproval();
        break;
      case 'scrapAdjustment':
        this.onScrapAdjustment();
        break;
      default: break;
    }
  };

  constructor(

    public repService: RepServices,
    public uiService: UIService,
    public device: DeviceService,
    public footerService: FooterService,
    public trackingService: TrackService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public translate: TranslateService,
    private readonly el: ElementRef,
    public allocAdjustService: AllocationAdjustService,
    private readonly popoverCtrl: PopoverController,
    private sampleService: SampleService,
    private readonly modalCtrl: ModalController,
    private readonly authenticationService: AuthenticationService,
    private readonly adjustmentDataService: AllocationAdjustmentDataService,
    private readonly shipmentService: AllocationShipmentService,
    private readonly adjustmentService: AllocationAdjustService,
    private events: Events,
    private sampleDataService: SampleDataService,
    private datePipe: DatePipe,
    private allocFeatureService: AllocationFeatureService,
    private activityService: ActivityService,
    private allocationInventoryDataService: AllocationInventoryDataService,
    private allocationInventoryService: AllocationInventoryService,
  ) { }

  ngOnInit() {
    this.updateLatestUserLotInfo(true);
    this.sampleLastDispatchLimitNum = this.authenticationService.user.sampleLastDispatchLimit || 0;
    this.init();
    this.events.subscribe('adjustment-detail:footerEvents', this.footerHandler);

    if (!this.subscription) {
      this.subscription = this.shipmentService.newAdjustmentSelectedFromList$.subscribe({
        next: async () => await this.init()
      });
    }

    this.events.subscribe('quantityInputDone$', () => {
      this.enableDisableHeaderFooterIcons();
    });

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((offline) => {
      this.enableDisableHeaderFooterIcons(offline);
    });

    if(this.device.isNativeApp && this.device.deviceFlags.ios){
      window.addEventListener('keyboardDidHide', (event)=>{
        setTimeout(()=>{
          this.scrollToTop();
        },0);
      });


      window.addEventListener('keyboardDidShow', (event) => {
        setTimeout(() => {
          let elemnt = document.getElementById('alloc-adjust-content');
          elemnt.classList.remove('keyboardOffsetHide');

        }, 0);
      });
    }

  }

  private scrollToTop() {
    this.content.scrollToTop();
    let elemnt = document.getElementById('alloc-adjust-content');
    elemnt.classList.add('keyboardOffsetHide');
  }

  private enableDisableHeaderFooterIcons(offline: boolean = false) {
    this._initPageTitle(offline);
    if (this.allocationAdjustmentForm) {
      if (!this.allocationAdjustmentForm.isValid || this.allocationAdjustmentForm.quantityAdjusted === 0 || isNaN(this.allocationAdjustmentForm.quantityAdjusted) || this.allocationAdjustmentForm.quantityAdjusted === null) {
        if (this.allocationAdjustmentForm.adjustmentID) {
          if (this.device.isOffline || offline) {
            this.footerService.disableButton(['scrapAdjustment', 'saveAllocationAdjustment', 'adjustShipment']);
          } else {
            this.footerService.disableButton(['scrapAdjustment','saveAllocationAdjustment', 'adjustShipment']);
          }
        } else {
          if (this.device.isOffline || offline) {
            this.footerService.disableButton(['scrapAdjustment','saveAllocationAdjustment', 'adjustShipment']);
          } else {
            this.footerService.enableButtons(['scrapAdjustment']);
          }
        }
      } else {
        if (this.device.isOffline || offline) {
          if (this.allocationAdjustmentForm.adjustmentID) {
            this.footerService.disableButton(['scrapAdjustment', 'saveAllocationAdjustment', 'adjustShipment']);
          } else {
            this.footerService.disableButton(['scrapAdjustment','saveAllocationAdjustment', 'adjustShipment']);
          }
        } else {
          this.footerService.enableButtons(['scrapAdjustment','saveAllocationAdjustment', 'adjustShipment']);
        }
      }
    }
  }

  async init() {
    this.isAdjustment = this.allocFeatureService.selectedShipment?.indskr_transfertype === TransferType.AllocationAdjustment;
    this.allocationAdjustmentForm = this.allocAdjustService.initForm(this.isAdjustment ? this.allocFeatureService.selectedShipment as IAllocationAdjustment : undefined, this.authenticationService, this.sampleService);

    this.footerService.initButtons(FooterViews.AdjustDtails);

    this._initPageTitle();
    this._initSectionHeaderDetails();

    if (!this.shipmentService.isNewAdjustment && this.isAdjustment && (!this.device.isOffline && !this.device.isUserStateOffline)) { // if not a new one please get the detail of it.
      await this.uiService.displayLoader();
      const updatedAdjustment = await this.adjustmentDataService.getAdjustmentDetail(this.allocFeatureService.selectedShipment as IAllocationAdjustment);

      if (updatedAdjustment) {
        this.events.publish('adjustmentUpdated$');
      await this.uiService.dismissLoader();
      this._initPageTitle();
      this._initSectionHeaderDetails();
      this.footerService.initButtons(FooterViews.AdjustDtails);
      } else if (updatedAdjustment == null || updatedAdjustment == undefined){
         this.events.publish('adjustmentDeleted$', this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id)
      this.onClosePage()
      await this.uiService.dismissLoader();
      }
  }


    if (this.allocationAdjustmentForm.lot) {
      this.selectLot(this.allocationAdjustmentForm.lot)
    }
  }


  ngOnDestroy() {
    this.events.unsubscribe('adjustment-detail:footerEvents')
    if (this.allocationAdjustmentForm) {
      this.events.unsubscribe('quantityInputDone$');
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.ngDestroy$.next(true);
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      case 'scrap':
        this.onScrapAdjustment();
      case 'Adjust':
        this.sendForAnApproval();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  onClosePage() {
    this.allocAdjustService.destroyForm();
    this.uiService.activeView = this.uiService.prevView;
    this.allocFeatureService.selectedShipment = undefined;
  }

  onScrapAdjustment() {
    this.openScrapConfiramtionAlert();
  }

  async sendForAnApproval() {
    this.openSendForApprovalAlert();
  }

  private _initSectionHeaderDetails(): void {
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
    this.availableLotsHeader = {
      id: 'available-lots-header',
      title: this.translate.instant('AVAILABLE_LOTS_CAP'),
      isRequired: this.allocationAdjustmentForm?.sku && this.allocationAdjustmentForm?.availableLots?.length>0,
      controls: [
        {
          id: 'available_lots_clear',
          text: this.translate.instant('CLEAR_LOTS'),
          isDisabled: !this.allocationAdjustmentForm?.lot,
        },
      ],
    };
    this.selectedLotsHeader = {
      id: 'selected-lots-header',
      title: this.translate.instant('AL_SELECTED_LOT'),
      isRequired: this.allocationAdjustmentForm?.sku && !_.isEmpty(this.allocationAdjustmentForm?.lot),
      controls: [],
    };
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'available_lots_clear':
        this.clearLotSelection();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  sectionHeaderRefresh() {
    if (this._updatedFieldData) {
      this._initSectionHeaderDetails();
      this._updatedFieldData = false;
    }
    this._initSectionHeaderDetails();
  }

  public get statusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.translate.instant('OPEN'),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public get adjustNumberField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('AL_ADJUSTMENT_NUMBER'),
      inputText: this.allocationAdjustmentForm.adjustmentNumber ? this.allocationAdjustmentForm.adjustmentNumber : '--',
      id: 'transfer-number-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public get adjustDateField() {
    return {
      label: this.translate.instant('AL_ADJUSTMENT_DATE'),
      inputText: this.datePipe.transform(this.allocationAdjustmentForm?.adjustmentDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),
      id: 'adjust-date-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
  }

  formattedDate(date: string): string {
    return moment(date).format(this.dateTimeFormatsService.dateToUpper);
  }

  public getLotNumberField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('AL_LOT_NUMBER'),
      inputText: '',
      id: 'lot-number-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    viewData;
  }

  public getQtyField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUANTITY_ADJUSTED'),
      inputText: '',
      id: 'quantity-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    viewData;
  }


  public getadjustmentQuantityField():IndFormFieldViewDataModel {
    let isDisabled = false;

    let viewData: IndFormFieldViewDataModel = {
      label: this.allocationAdjustmentForm.lot.name,
      inputText: this.allocationAdjustmentForm.quantityAdjusted,
      secondaryInfoLabel: this.formattedDateInfoLabelForSampleLot,
      inputType: 'number',
      inputValue: this.allocationAdjustmentForm.quantityAdjusted,
      formattingSymbol: '%',
      id: 'adjustment-quantity',
      isReadOnly: false,
      isDisabled: false,
      placeholderLabel: '0',
      showArrow: true,
      inputRegEx: new RegExp('-?[0-9]*'),
      formFieldType: FormFieldType.INLINE_INPUT,
      skipPlaceholderTranslation: true,
      invalidInputValueTrigger: true,
      eventHandler: (id: string, event, eventName) => this.setAdjustmentQuantity(event.target),
    };
    return viewData;
  }

  private _validateQuantityEntered(id, value): boolean {
    try {
      value = parseInt(value);
    } catch (error) {

    }
    if (value === null || value === undefined || isNaN(value) || value < 1) {
      value = 1;
    }
    return value >=1;
  }


  public async setAdjustmentQuantity(target) {
    if (target && target.value != undefined) {
      this.allocationAdjustmentForm.quantityAdjusted = target.value;
      if (this.allocationAdjustmentForm.quantityAdjusted && !this.allocationAdjustmentForm.isQtyValid) {
        if (target instanceof HTMLElement) {
          target.classList.add('exceededlimits');
        } else {
          target.classList.remove('exceededlimits');
        }
      }
    }
  }


  public get selectedProductField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCT'),
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
      inputText: isEmpty(this.allocationAdjustmentForm?.sku) ? '' : this.allocationAdjustmentForm?.sku?.title,
      id: 'selected-product-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedProduct && isEmpty(this.allocationAdjustmentForm?.sku),
      eventHandler: (id: string, event, eventName) => this._handleFormFieldAdjustmemnt(id, event, eventName),
    };
    return viewData;
  }

  public get reasonField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('REASON'),
      customPlaceholderLabel :this.translate.instant('SELECT_REASON'),
      inputText: this.allocationAdjustmentForm.reason && this.allocationAdjustmentForm.reason.reason ? this.allocationAdjustmentForm.reason.reason : '',
      id: 'reason-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this._handleFormFieldAdjustmemnt(id, event, eventName),
    };
    return viewData;
  }

  private _handleFormFieldAdjustmemnt(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'selected-product-field':
          this.openProductSelection();
          break;
        case 'reason-field':
          this.openSelectReason(event);
          break;
        case 'comment-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.handleCommentsInput(event);
          }
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  public getCommentFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT_COMMENTS'),
      inputText: (this.allocationAdjustmentForm.lot && this.allocationAdjustmentForm.comments) ? this.allocationAdjustmentForm.comments : '',
      inputValue: (this.allocationAdjustmentForm.lot && this.allocationAdjustmentForm.comments) ? this.allocationAdjustmentForm.comments : '',
      id: 'comment-field',
      isReadOnly: !this.allocationAdjustmentForm.lot,
      isDisabled: !this.allocationAdjustmentForm.lot,
      placeholderLabel: this.translate.instant('ACCOUNT_COMMENTS'),
      showArrow: !(!this.allocationAdjustmentForm.lot),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(!this.allocationAdjustmentForm.lot || this.allocationAdjustmentForm.reason.value !==548910004),
      eventHandler: (id: string, event, eventName) => this._handleFormFieldAdjustmemnt(id, event, eventName),
    };
    return viewData;
  }

  handleCommentsInput(event) {
    if (this.allocationAdjustmentForm.lot) {
      // if (this.allocFeatureService.selectedShipment.indskr_shipmentstatus === this.shipmentStatus.Shipped && this.authenticationService.user.systemUserID === this.allocFeatureService.selectedShipment.ownerId) {
        let comment = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : "";
        if (this.allocationAdjustmentForm.comments != comment) {
          this.allocationAdjustmentForm.comments = comment;
          // this._cd.markForCheck();
        }
        this.allocationAdjustmentForm.validate();
        this._initPageTitle();
      // }
    }
  }

  async openProductSelection() {
    const selectListDetail: SelectListDetail = {
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('ACCOUNT_CALL_PLAN_ALL_PRODUCTS'),
      data: this.allocAdjustService.getAdjustProducts(this.adjustmentService.lots),
      navOptions: { animate: false }
    };
    this._selectProductModal = await this.modalCtrl.create({ component: SelectListComponent, componentProps: { viewData: selectListDetail }, backdropDismiss: false, animated: false });
    this._selectProductModal.present();

    this._selectProductModal.onDidDismiss().then(async (modalData: any) => {
      this.isClickedProduct = true;
      let data = modalData.data;
      if (data && data.isDone && Array.isArray(data.selectedItems)) {
        await this._onSkuSelection(data.selectedItems[0]);
      }
    });

  }

  selectLot(lot: Lot) {
    if (!this.allocationAdjustmentForm.lot || this.allocationAdjustmentForm.lot.id !== lot.id) {
      // Reset qty &  reason
      this.allocationAdjustmentForm.quantityAdjusted = null;
      this.allocationAdjustmentForm.reason = null;

      this.allocationAdjustmentForm.lot = lot;
      this.allocationAdjustmentForm.validate();
      this._initPageTitle();
    }  else if (this.allocationAdjustmentForm.lot.id === lot.id && this.shipmentService.isNewAdjustment) {
      this.clearLotSelection();
    }

    this.formattedDateInfoLabelForSampleLot = this._getDateInfoLabelForSampleLot(lot);

    this._updatedFieldData = true;
    this.sectionHeaderRefresh();
  }

  clearLotSelection(withoutValidation = false) {
    this.allocationAdjustmentForm.lot = undefined;
    this.formattedDateInfoLabelForSampleLot = '';
    this.allocationAdjustmentForm.quantityAdjusted = null;
    this.allocationAdjustmentForm.reason = null;

    if (!withoutValidation) {
      this.allocationAdjustmentForm.validate();
      this._initPageTitle();
    }
    this._updatedFieldData = true;
    this.sectionHeaderRefresh();
  }

  isLotSelected(lot: Lot) {
    return this.allocationAdjustmentForm.lot && lot && lot.id === this.allocationAdjustmentForm.lot.id;
  }

  isQtyValid() {
    this.allocationAdjustmentForm.validate();
    this._initPageTitle();
    return this.allocationAdjustmentForm.isQtyValid;
  }

  arrowClicked(direction: string) {
    if (!this.pillScrollContainer) {
      this.pillScrollContainer = this.el.nativeElement.querySelector('#lotsContainer');
    }
    if (this.pillScrollContainer) {
      const curXpos = this.pillScrollContainer.scrollLeft;
      const offsetWidth = this.pillScrollContainer.offsetWidth;
      const scrollWidth = this.pillScrollContainer.scrollWidth;
      let newXpos = 0;
      if (direction === 'left') {
        newXpos = curXpos - offsetWidth >= 0 ? curXpos - offsetWidth : 0;
      } else if (direction === 'right') {
        const maxXpos = scrollWidth;
        newXpos = curXpos + offsetWidth <= maxXpos ? curXpos + offsetWidth : maxXpos;
      }
      this.pillScrollContainer.scroll({ top: 0, left: newXpos, behavior: 'smooth' });
    }
  }

  private _initPageTitle(offline = false): void {
    const buttons = [];
    if (!this.isSplitView) {
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    buttons.push({
      id: "scrap",
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('SCRAP'),
      isDisabled: this.device.isOffline || offline,
      align: "right"
    });

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
      buttons.push({
        id: 'Adjust',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled: this.device.isOffline || !this.allocationAdjustmentForm?.isValid || !this.allocationAdjustmentForm.quantityAdjusted || this.allocationAdjustmentForm.quantityAdjusted === 0,
        align: 'right'
      });
    } else {
      buttons.push({
        id: 'Adjust',
        imgSrc: 'assets/imgs/header_send.svg',
        name: this.translate.instant('SEND_ADJUST_SHIPMENT'),
        isDisabled: this.device.isOffline || !this.allocationAdjustmentForm?.isValid || !this.allocationAdjustmentForm.quantityAdjusted || this.allocationAdjustmentForm.quantityAdjusted === 0,
        align: 'right'
      });
    }

    if (this.device.isOffline) {
      // if (this.shipmentService.isNewAdjustment) {
      //   this.footerService.disableButton(['scrapAdjustment']);
      // } else {
        this.footerService.disableButton(['scrapAdjustment']);
      // }
    } else {
      this.footerService.enableButtons(['scrapAdjustment']);
    }


    if (!this.allocationAdjustmentForm?.isValid || !this.allocationAdjustmentForm.quantityAdjusted || this.allocationAdjustmentForm.quantityAdjusted === 0) {
      this.footerService.disableButton(['saveAllocationAdjustment', 'adjustShipment'])
    } else {
      this.footerService.enableButtons(['saveAllocationAdjustment', 'adjustShipment']);
    }

    this.allocationAdjustPanePageTitle = {
      id: 'allocation-adjust-page-title',
      title: this.translate.instant('MATERIAL_ADJUSTMENT'),
      controls: buttons
    };
  }
  private async _onSkuSelection(sku: SelectListData) {
    if ((!this.allocationAdjustmentForm.sku && sku)
      || (this.allocationAdjustmentForm.sku && !sku)
      || (this.allocationAdjustmentForm.sku && sku && this.allocationAdjustmentForm.sku.id !== sku.id)) {
      this.clearLotSelection(true);
      this.allocationAdjustmentForm.sku = sku;
      this._initPageTitle();
      this.allocationAdjustmentForm.availableLots = []
      this.allocationAdjustmentForm.availableLots = await this._getAvailableLotsForSku(sku);
      this.allocationAdjustmentForm.validate();
      this._initSectionHeaderDetails();
    }
  }

  private async _getAvailableLotsForSku(sku: SelectListData) {

    //trying to get the latest info from Dynamics

    await this.fetchRealTimeLotDetails();
    await this.updateLatestUserLotInfo();

    let lots = [];
    if (sku) {

      lots = this.adjustmentService.lots.filter(lot => lot.sampleSKUId === sku.id && lot.status === 0 && lot.totalQuantityRemaining !== undefined && lot.totalQuantityRemaining !== null && lot.totalQuantityRemaining >= 0);
    }
    return lots;
  }

  private async fetchRealTimeLotDetails() {

     if (!this.loader) {
      this.loader = await this.loadingCtrl.create();
    }

    await this.loader.present();

    if (!this.device.isOffline && !this.device.isUserStateOffline) {

      const dataRangeWithFutureBoundBySixMonths = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
      await this.sampleDataService.syncLots(dataRangeWithFutureBoundBySixMonths);
    }
    await this.loader.dismiss();
    this.loader = undefined;
  }

  async openSelectReason(ev) {

    if (Array.isArray(this.allocAdjustService.adjustReasons) && this.allocAdjustService.adjustReasons.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Shipment-Reason-Select',
        data: this.allocAdjustService.adjustReasons.map(reason => {
          let obj = {
            title: reason.reason,
            id: reason.value.toString(),
            isSelected: false,
          };
          if (this.allocationAdjustmentForm.reason && reason.value == this.allocationAdjustmentForm.reason.value) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      this._selectReasonPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, event: event, cssClass: 'dropdown-list-view' });
      this._selectReasonPopover.onDidDismiss().then(async (selectData: any) => {
        let data = selectData.data;
        if (data && data.selectedItems && data.selectedItems.length === 1 && (!this.allocationAdjustmentForm.reason || data.selectedItems[0].id !== this.allocationAdjustmentForm.reason.value)) {
          this.allocationAdjustmentForm.reason = {
            value: data.selectedItems[0].id,
            reason: data.selectedItems[0].title,
          };
        }
        this.allocationAdjustmentForm.validate();
        this._initPageTitle();
        this._selectReasonPopover = undefined;
      });
      this._selectReasonPopover.present();
    }
  }

  isSplitView() {
    return (window.innerWidth > 500);
  }

  onInputFocus(focusInput) {
    this.focusedInputElement = focusInput as HTMLElement;
  }

  async openScrapConfiramtionAlert() {
    if (!this.alert) {
      let button = {
        text: this.translate.instant('SCRAP'),
        handler: await this._scrapConfirmed,
      }

      await this.createAlert(this.translate.instant('AL_SCRAP_ADJUSTMENT_ALERT_HEADER'), this.translate.instant('AL_ADJUSTMENT_SCRAP_MESSAGE'), button);
      this.alert.present();
      this.alert.onDidDismiss().then(() => this.alert = undefined);
    }
  }

  async openSendForApprovalAlert() {
    if (!this.alert) {
      let button = {
        text: this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ? this.translate.instant('SAVE') : this.translate.instant('SEND_ADJUST_SHIPMENT'),
        handler: this._sendConfirmed,
      }
      await this.createAlert(this.translate.instant('AL_ADJUST_MATERIAL'), this.translate.instant('AL_ADJUSTMENT_SEND_MESSAGE'), button);
      this.alert.present();
      this.alert.onDidDismiss().then(() => this.alert = undefined);
    }
  }

  async openApproveAlert() {
    if (!this.alert) {
      let button = {
        text: this.translate.instant('APPROVE'),
        handler: this._approveConfirmed,
      }

      await this.createAlert(this.translate.instant('AL_ADJUSTMENT_APPROVE_HEADER'), this.translate.instant('AL_R_U_SURE_TRANS_MATERIAL'), button);
      this.alert.present();
      this.alert.onDidDismiss().then(() => this.alert = undefined);
    }
  }

  async openNotApproveAlert() {
    if (!this.alert) {

      let button = {
        text: this.translate.instant('NOT_APPROVE'),
        handler: this._notApproveConfirmed,
      }

      await this.createAlert(this.translate.instant('AL_ADJUSTMENT_NOT_APPROVE_HEADER'), this.translate.instant('AL_R_U_SURE_TRANS_MATERIAL'), button);
      this.alert.present();
      this.alert.onDidDismiss().then(() => this.alert = undefined);
    }
  }
  private _scrapConfirmed = async () => {

    if (this.allocationAdjustmentForm.external_id && !this.allocationAdjustmentForm.adjustmentID) {
      this.uiService.activeView = 'CustomerAllocation';
      this.allocAdjustService.destroyForm();
      this.allocFeatureService.selectedShipment = undefined;
    } else {
      if (!this.device.isUserStateOffline && !this.device.isOffline) {
        await this.uiService.displayLoader();
        if (this.isAdjustment) {
          let deletedAdjustmentID = await this.adjustmentDataService.deleteAdjustment(this.allocFeatureService.selectedShipment as IAllocationAdjustment);
          this.events.publish('adjustmentDeleted$',deletedAdjustmentID)
          this.onClosePage()
          await this.uiService.dismissLoader();
        }
      }
    }
  }

  private _sendConfirmed = () => {
    this.sendForApproval()
  }

  async sendForApproval() {
    if (!this.loader) {
      this.loader = await this.loadingCtrl.create();
    }

    await this.loader.present();

    let  newAllocAdjustment: IAllocationAdjustment;

    try {

      if (this.isAdjustment && this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id === this.allocationAdjustmentForm.adjustmentID) {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
          newAllocAdjustment = await this.adjustmentDataService.updateStatus(AdjustmentStatus.Approved, this.allocationAdjustmentForm);
        } else {
          newAllocAdjustment = await this.adjustmentDataService.updateStatus(AdjustmentStatus.InReview, this.allocationAdjustmentForm);
        }
      } else {
        newAllocAdjustment = await this.adjustmentDataService.sendForApproval(this.allocationAdjustmentForm);
      }

      if (newAllocAdjustment?.indskr_transfertype === TransferType.AllocationAdjustment) {
        // Reset form

        this.allocFeatureService.selectedShipment = newAllocAdjustment;
        if (this.loader) {
          await this.loader.dismiss();
        }
        this.uiService.prevView = '';
        this.uiService.activeView = 'AllocationShipment';
        this.adjustmentService.newAllocAdjustmentAdded$.next(newAllocAdjustment);
        this.sampleService.selectedSampleSKU = null;
        this.allocAdjustService.destroyForm();
        this.events.publish('syncAllocationInventory');
      } else {
        console.error('AllocationTransferComponent: transferShipment: not created..', newAllocAdjustment);
        if (this.loader) {
          await this.loader.dismiss();
        }
      }
    } catch (error) {
      console.error('AllocationTransferComponent: transferShipment: error', error);
      if (this.loader) {
        await this.loader.dismiss();
      }
    }
    // this._initPageTitle();
  }

  private _approveConfirmed = () => {
  }
  private _notApproveConfirmed = () => {
  }

  private async createAlert(header, message, button: any) {

    let buttons = [{
      text: this.translate.instant('CANCEL'),
      role: 'cancel',
      handler: () => { }
    }]
    buttons.push(button);
    this.alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: buttons
    });
  }

  private _getDateInfoLabelForSampleLot(lot: Lot): string {
    let dateInfoLabel: string = '';
    let lastDispatchDateStr: string = '';
    let validToDateStr: string = '';
    
    if (lot.lastdispatchdate) {
      lastDispatchDateStr = this.translate.instant('AL_LAST_DISPATCH_DATE') + ' ' + this.datePipe.transform(lot.lastdispatchdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    } else if (this.sampleLastDispatchLimitNum > 0 && lot.validTo) {
      const calculatedDateWithLastDispatch: Date = subDays(new Date(lot.validTo), this.sampleLastDispatchLimitNum);
      if (calculatedDateWithLastDispatch && isValid(calculatedDateWithLastDispatch)) {
        lastDispatchDateStr = this.translate.instant('AL_LAST_DISPATCH_DATE') + ' ' + this.datePipe.transform(calculatedDateWithLastDispatch, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);  
      }
    }
    if (lot.validTo && isValid(lot.validTo)) {
      validToDateStr = this.translate.instant('AL_EXPIRES') + ' ' + this.datePipe.transform(lot.validTo, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    }

    if (lastDispatchDateStr && validToDateStr) {
      dateInfoLabel = `${lastDispatchDateStr} │ ${validToDateStr}`;
    } else if (!lastDispatchDateStr && validToDateStr) {
      dateInfoLabel = `${validToDateStr}`;
    } else if (lastDispatchDateStr && !validToDateStr) {
      dateInfoLabel = `${lastDispatchDateStr}`;
    } else {
      dateInfoLabel = '';
    }
    return dateInfoLabel;
  }

  private async updateLatestUserLotInfo(isRealTimeFetch: boolean = false) {
    if (this.device.isOffline || _.isEmpty(this.sampleService.lots)) return;
    if (!isRealTimeFetch) {
      if (!_.isEmpty(this.allocationInventoryService.userInventory)) {
        this.adjustmentService.lots.map(lot=>{
          if (lot.sampleSKUId) {
            lot.lastdispatchdate = this.allocationInventoryService.getLastDispatchDateString(lot.sampleSKUId, lot.id);
          }
          return lot;
        });
      } else {
        await this.fetchInventory().then(() => {
          if (!_.isEmpty(this.allocationInventoryService.userInventory)) {
            this.adjustmentService.lots.map(lot=>{
              if (lot.sampleSKUId) {
                lot.lastdispatchdate = this.allocationInventoryService.getLastDispatchDateString(lot.sampleSKUId, lot.id);
              }
              return lot;
            });
          }
        }).catch((err) => {
          console.log("fetching allocation Inventory failed: " + err);
        });
      }
    } else {
      await this.fetchInventory().then(() => {
        if (!_.isEmpty(this.allocationInventoryService.userInventory)) {
          this.adjustmentService.lots.map(lot=>{
            if (lot.sampleSKUId) {
              lot.lastdispatchdate = this.allocationInventoryService.getLastDispatchDateString(lot.sampleSKUId, lot.id);
            }
            return lot;
          });
        }
      }).catch((err) => {
        console.log("fetching allocation Inventory failed: " + err);
      });
    }
  }

  private async fetchInventory() {
    return await this.allocationInventoryDataService.fetchInventory(true, false);
  }
}
