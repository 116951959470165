import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { Component, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EmailActivity, EmailViewType } from '../../../classes/activity/email.activity.class';
import { ContentToken, ReplacementToken } from '../../../classes/email-templates/email-template.class';
import { DeviceService } from '../../../services/device/device.service';
import { EmailService } from '../../../services/email-templates/email.service';
import { EventsService } from '../../../services/events/events.service';
import { UIService } from '../../../services/ui/ui.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TemplatePreviewComponent } from '../template-preview/template-preview';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';

/**
 * Generated class for the ReplacementTokenListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'replacement-token-list',
  templateUrl: 'replacement-token-list.html',
  styleUrls: ['replacement-token-list.scss']
})

export class ReplacementTokenListComponent {

  @Input() public contentToken: ContentToken;
  public replacementTokenPageTitle: IndHeaderLeftDataModel;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private currentTokenTitle: string = '';

  constructor(
    private readonly emailService: EmailService,
    private readonly navService: NavigationService,
    private readonly uiService: UIService,
    private readonly navParams: NavParams,
    private readonly events: EventsService,
    private readonly translate: TranslateService,
    private readonly footerService: FooterService,
    private readonly device: DeviceService) {

    this.emailService.selectedContentToken.pipe(
      takeUntil(this.destroy$))
      .subscribe(token => {
        if (token) {
          this.contentToken = token;
          this.titleFromContentToken();
          this.initPageTitle();
        }
      });

    this.events.subscribe('updateReplacementTokenTitle', (title: string) => {
        if (title) {
          this.currentTokenTitle = title;
          this.initPageTitle();
        }
      });
  }

  private titleFromContentToken() {
    if (this.contentToken) {
      if (this.contentToken.indskr_iseditable){
        this.currentTokenTitle = this.translate.instant('PERSONALIZE');
      } else if (this.contentToken.replacementTokens.length) {
        let tokens = this.contentToken.replacementTokens;
        tokens.forEach(element => {
          if (element.is_default) {
            this.currentTokenTitle = element.replacement_value;
          }
        });
      }
    }
  }

  ngOnInit() {
    // this.showRightPanel();
    // this.navService.setChildNavRoot(TemplatePreviewComponent, PageName.TemplatePreviewComponent, PageName.ReplacementTokenListComponent, {});
    this.initPageTitle();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.events.unsubscribe('updateReplacementTokenTitle');
  }

  showRightPanel() {
    // if (this.device.shouldBeMobileView) {
    //   this.uiService.showRightPane = true;
    // }
    this.navService.setChildNavRightPaneView(true);
  }

  selectOption(rToken: ReplacementToken) {
    this.contentToken.replacementTokens.forEach(rt => {
      if (rt.is_default && rt.replacement_id !== rToken.replacement_id) {
        this.events.publish('replacement-selected',
          {
            prevSelection: { id: rt.replacement_id, value: rt.replacement_value },
            currentSelction: rToken
          }
        );
        if (this.device.isMobileDevicePortrait) this.emailService.resetContentToken();
      }
      rt.is_default = rToken.replacement_id === rt.replacement_id;
      if (rt.is_default) {
        this.currentTokenTitle = rt.replacement_value;
        this.initPageTitle();
      }
    });
    this.showRightPanel();
  }

  goToPreview() {
    this.emailService.resetContentToken();
    this.showRightPanel();
  }

  async goBack() {
    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      this.events.publish('replacement-back-clicked');
    } else {
      this.navService.popWithPageTracking().then(() => {
        this.footerService.initButtons(FooterViews.EmailDetails);
      });
    }
  }

  private initPageTitle(): void {
    let controls = [];
    if(this.device.isMobileDevice) {
      controls.push({
        id: 'back',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('Back'),
        isDisabled: false,
        align: 'left'
      });
    } else {
      controls.push({
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'left'
      });
    }
    this.replacementTokenPageTitle = {
      id: 'replacement-token-title',
      // cssClass: 'main-tool-header-title',
      title: this.currentTokenTitle,
      // mode: false,
      customHeaderProps:{hasCancel:true},
      controls: controls,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack();
        break;
      case 'back':
        this.goToPreview();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }
}
