<ion-header>
  <ind-page-title [viewData]='pageTitle' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ind-section-header [viewData]='opportunityDetailsHeader'></ind-section-header>
  <ind-form-field *ngFor="let field of newOpportunityFormFieldValues" [viewData]='field'
    (onInputClick)='onFieldClick($event)'></ind-form-field>

  <!-- Stakeholder grid -->
  <ind-section-header [viewData]='stakeholderLineItemDetailsHeader'
    (onControlClick)='handleStakeholderField()'></ind-section-header>
  <ion-item class="placeholderEmptyDiv" *ngIf="!allModelStakeholder || allModelStakeholder.length==0"></ion-item>
  <main-card *ngFor="let stakeholder of allModelStakeholder" [viewData]="stakeholder"></main-card>

</ion-content>