import { PipeTransform, Pipe } from '@angular/core';
import { PresentationService } from '../services/presentation/presentation.service';
import { DeviceService } from '../services/device/device.service';
import { Presentation } from '../classes/presentation/presentation.class';





@Pipe({
    name: 'offlinePresentationFilter',
    pure: false
})
export class OfflinePresentationFilter implements PipeTransform {
    constructor(
        private presentationService: PresentationService,
        private device: DeviceService
    ) {

    }

    /**
     *  This is the pipe for transforming the list of presentations, only show downloaded if offline
     *
     * @param {Array<RepCallPlan>} items
     * @returns
     * @memberof RepCallPlanFilterPipe
     */
    transform(items: Array<Presentation>) {
        if (this.device.isOffline) {
            //Only show downloaded
            return items.filter(pres => pres.downloaded);
        } else {
            //Show everything
            return items;
        }
    }
}