import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ind-list-grid',
  templateUrl: 'ind-list-grid.html',
  styleUrls:['ind-list-grid.scss']
})
export class IndListGridComponent {

  @Input() viewMetaData: ListGridDataModel;
  @Input() listType: string;
  @Input() listRef: any;
  @Input() gridRef: any;
  @Input() data: any[]


  // @ContentChild(TemplateRef) templateRef;
  // @ContentChild(TemplateRef) templateReff;

  constructor(
    public translate: TranslateService,
    ) {

      // console.log("LIST GRID");
  }

  ngOnInit() {
    // console.log(this.data);

  }

  public handleMainItemClick(event, item: any) {
    if (this.viewMetaData.clickHandler) {
      this.viewMetaData.clickHandler(event, item, this.viewMetaData);
      try {
        if (event) {
          event.stopPropagation();
        }
      } catch (error) {
        // Handle Error To Do
      }
    }
  }
}

export interface ListGridDataModel {
  clickHandler?:(event?:any, item?:any, dataRef?:any) => void;
}

