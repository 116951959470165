export class Meeting {
    /**
     * Dates
     */
    public createdDate: number;
    public lastModifiedDate: number;
    public startTime: number;
    public endTime: number;

    /**
     * IDs
     */
    public ID: number;
    public meetingOwner: string;
    public publicRoomID: number;
    public offlineMeetingID: string;
    public productID: number;
    public accountID: any;
    public CRMAppointmentID: string;

    /**
     * URL and access key
     */
    public privateKey: string;
    public meetingURL: string;

    /**
     * Connection
     */
    public meetingTokens: Array<object>;

    /**
     * Status
     */
    public meetingType: MeetingType;

    /**
     * Sessions
     */
    public practitionerSessions: Array<PractitionerSession>;

    /**
     * Details
     */
    public meetingDisposition: any;
    public meetingCategory: any;
    public meetingDetail: any;
    public meetingNotes: Array<string>;
    public location: string;
    public phone: string;

    /**
     * Presentations
     */
    public presentations: Array<MeetingPresentation>;

    constructor(raw: object) {

    }
}

export interface MeetingPresentation {
    createdDate: number;
    lastModifiedDate: number;
    startTime: number;
    modifiedStartTime: number;
    endTime: number;
    modifiedEndTime: number;

    ID: number;
    presentationID: number;
    productID: number;

    presentationName: string;
    productName: string;

    meetingPresentationAssets: Array<PresentationAsset>;
    assetMetaData: any;
}

export interface PresentationAsset {
    createdDate: number;
    lastModifiedDate: number;
    startTime: number;
    endTime: number;

    ID: number;
    assetID: number;
    meetingID?: number;
    meetingPresentationID?: number;
    meetingPresentationAssetId?: number;


    assetType: PresentationAssetType;
    viewType: any;

    reportable: boolean;
    billable: boolean;
    trackable: boolean;

    presentationName?: string;
    assetName: string;
    assetPath: string;
    assetFileRef: string;
    sequence: number;
    thumbnail: string;

    practitionerMood?: PractitionerSentiment;
    viewedStatus?: any;
}

export interface MeetingProduct {

}

export interface PractitionerSession {
    createdDate: number;
    lastModifiedDate: number;
    timezone: string;
    startTime: number;
    endTime: number;

    ID: number;
    practitionerID: string;

    accessType: string; //Enum?
    channelType: string; //Enum?
    accessKey: string;
    status: string; //Enum?

    guestPractitioner: boolean;
    inFieldPractitioner: boolean;

    practitionerAssetViewed: Array<PresentationAsset>;

    practitionerTokens: Array<object>;

    viewPortWidth: number;
    viewPortHeight: number;
    platform: string;
    platformResolution: string;
    sharedCursorXPosition: string;
    sharedCursorYPosition: string;
}

export enum PresentationAssetType {
    SLIDE
}

export enum MeetingType {
    IN_FIELD
}

export enum MeetingStatus {
    OPEN = 'Open',
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    SCHEDULED = "Scheduled"
}

export enum MeetingCode {
    DELETED
}

export enum PractitionerSentiment {
    SAD,
    OK,
    HAPPY
}

export interface MeetingState {
    ID: number;
    code: MeetingCode;
    description: string;
    status: MeetingStatus;
    reason: string;
}

export enum ResourceAssetType {
    DOCUMENT = "document",
    IMAGE  = "image",
    VIDEO  = "video"
}


