import { Component, ViewChild, HostListener, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DeviceService } from '@omni/services/device/device.service';
import { IndPhotoViewerDataModel } from '@omni/models/indPhotoViewerDataModel';
import { NotificationService } from '@omni/services/notification/notification.service';
import { Photo, PhotoResponse } from '@omni/classes/store-check/photo';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';

const DEFAULT_SLIDES_PER_VIEW = 6;

@Component({
  selector: 'ind-photo-viewer',
  templateUrl: 'ind-photo-viewer.html',
  styleUrls: ['ind-photo-viewer.scss']
})
export class IndPhotoViewerComponent {

  public pageTitle: IndPageTitleViewDataModel;

  @Input() photoModel: IndPhotoViewerDataModel;
  photos: PhotoResponse[] = [];

  selectedPhoto: string;
  public selPageNumber: string = "1";
  public totalPages: number;
  public slidesPerView: number = DEFAULT_SLIDES_PER_VIEW;
  private _carouselSlideWidth: number;
  public allPhotosSectionHeader: IndSectionHeaderViewDataModel;
  public removedPhotos: PhotoResponse[] = [];

  constructor(
    private navService: NavigationService,
    public translate: TranslateService,
    private notificationService: NotificationService,
    public device: DeviceService,
    private readonly el: ElementRef,
    public _cd: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.allPhotosSectionHeader = {
      id: 'all-photos',
      title: this.translate.instant('ALL_PHOTOS'),
      controls: []
    }
    this.photos = _.cloneDeep(this.photoModel.masterPhotos);
    const selected = this.photoModel.masterPhotos.find(photo => photo.isSelected);
    if (selected) {
      this.selectedPhoto = selected.indskr_photourl;
    } else {
      this.selectedPhoto = this.photoModel.masterPhotos[0].indskr_photourl;
    }
    this.initializePageTitle();
    this.resizeCarousel();
  }

  selectImage(photo: string): void {
    this.selectedPhoto = photo;
  }

  private initializePageTitle() {
    let buttons = [];
    if (this.photoModel.readOnly) {
      buttons.push({
        id: 'cancel',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    } else {
      buttons.push({
        id: "cancel",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        align: "right"
      },
      {
        id: "done",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: this.removedPhotos.length == 0,
        align: "right"
      });
    }
    this.pageTitle = {
      id: 'photo-capture-viewer-page-title',
      title: this.photoModel.pageTitle,
      controls: buttons,
    };
  }

  public ionViewDidEnter() {
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
      case 'cancel':
        this.navService.popWithPageTracking();
        break;
      case 'done':
        this.photoModel.callbackEvent(this.removedPhotos);
        this.navService.popWithPageTracking();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  @HostListener('window:resize', ["event"])
  onResize(event) {
    this.resizeCarousel();
  }

  resizeCarousel() {

    let width = window.innerWidth;
    let numSlidesPerView = DEFAULT_SLIDES_PER_VIEW;

    this._setCarouselSlideWidth(numSlidesPerView);
    this.slidesPerView = Math.ceil(width / this._carouselSlideWidth) + (this.device.isOrientationPortrait() ? 0 : 1);
    this._cd.detectChanges();
  }

  private _setCarouselSlideWidth(slidesPerView: number) {
    let shorterSideSize;
    if (window.innerWidth < window.innerHeight) {
      shorterSideSize = window.innerWidth;
    } else {
      shorterSideSize = window.innerHeight;
    }
    this._carouselSlideWidth = Math.ceil(shorterSideSize / slidesPerView);
  }

  public imgLoadCheck(ev: any, photo: PhotoResponse, id: number) {
    const thumbnailId = 'photo-thumbnail' + id;
    if (ev && ev.target && thumbnailId) {
      const currentThumbnail = document.getElementById(thumbnailId);
      const isDefaultImg = ev.target.currentSrc && ev.target.currentSrc.includes('thumbnail_default');
      if (!isDefaultImg && currentThumbnail) {
        photo['isLoaded'] = true;
      }
    }
  }

  public deletePhoto(photo: PhotoResponse): void {
    const index = this.photos.indexOf(photo);
    if (index > -1) {
      this.selectedPhoto = this.photos[index < (this.photos.length - 1) ? (index + 1) : (index - 1) ]?.indskr_photourl;
      this.photos.splice(index, 1);
      this.removedPhotos.push(photo)
    }
    this.initializePageTitle();
  }

}








