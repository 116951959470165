import { Component } from '@angular/core';
import {NavigationService, PageName} from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import {
  BlockBlobURL,
  Aborter,
  ContainerURL,
  ServiceURL,
  StorageURL,
  TokenCredential,
  AnonymousCredential
} from '@azure/storage-blob';
import { TransferProgressEvent } from '@azure/ms-rest-js';
import { AuthenticationService } from '../../../services/authentication.service';
import { DiskService } from '../../../services/disk/disk.service';
import { AzureStorageDetails } from '../../../classes/app-settings/azure-storage-details';
import { AzureStorageService } from '../../../services/app-settings/azure.storage.service';
import { EventsService } from '../../../services/events/events.service';
import { PopoverController } from '@ionic/angular';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import {AboutDetailsComponent} from "../about-page/about-details/about-details";
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';

/**
 * Generated class for the AppsettingsDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'appsettings-details',
  templateUrl: 'appsettings-details.html',
  styleUrls:['appsettings-details.scss']
})
export class AppSettingsDetailsComponent {
  public progress: any = 0;
  public contentLength: string;
  public isUploadDisabled: boolean = false;
  private anonymousCredentials: AnonymousCredential;
  private tokenCredentials: TokenCredential;
  private serviceURL: ServiceURL;
  private _azureStorageDetails: AzureStorageDetails;
  appSettingsDetailsPageTitle: IndPageTitleViewDataModel;
  diagnosticHeader: IndSectionHeaderViewDataModel;
  disableSendButton: boolean;

  constructor(
    public repService: RepServices,
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    public footerService: FooterService,
    private authenticationService: AuthenticationService,
    private disk: DiskService,
    private azureStorageService: AzureStorageService,
    private eventService: EventsService,
    public azureService: AzureStorageService,
    private popoverCtrl:PopoverController,
    private translate : TranslateService,

  ) {
    this.diagnosticHeader = {
      id:'diagnostic-header',
      title: this.translate.instant('SETTINGS_DIAGNOSTIC_DATA'),
      controls: [],
    };
  }

  ngOnInit() {
    this.footerService.initButtons(FooterViews.APP_SETTING_UPLOAD);
    this.eventService.subscribe("appsettings:upload", () => {
      this.sendDiagnosticData();
    });
    this.azureService.progress = 0;
    this.initAppSettingsDetailsPageTitle();
  }

  ionViewDidEnter() {
  }

  ngDoCheck() {
    // Enable or Disable footer buttons according to view
  }


  public goBack() {
    this.uiService.showRightPane = false;
    this.navService.setChildNavRightPaneView(false);

  }

  public sendDiagnosticData() {
    // this.azureService.isUploadDisabled = true;
    // this.footerService.disableButton(['appsettingupload']);
    this.disableSendButton = true;
    this.initAppSettingsDetailsPageTitle();
    this.azureService.progress = 0;
    this.azureStorageService.upload().then(() => {
      this.disableSendButton = false;
      this.initAppSettingsDetailsPageTitle();
    }).catch(error => {
      this.progress = 'error';
      this.disableSendButton = false;
      this.initAppSettingsDetailsPageTitle();
      this.azureService.isUploadDisabled = false;
      this.footerService.enableButtons(['appsettingupload']);
      console.log('Error in fetching the SAS token ' + error);
    })
  }

  ngOnDestroy() {
    this.eventService.unsubscribe("appsettings:upload");
  }

  openPrivacyPopover(event){
    if (event && event.stopPropagation) event.stopPropagation();
    this.navService.pushWithPageTracking(AboutDetailsComponent, PageName.AboutDetailsComponent, { aboutTitle: "PRIVACY_POLICY" }, PageName.AppSettingsDetailsComponent);
  }


  private initAppSettingsDetailsPageTitle(): void {
    this.appSettingsDetailsPageTitle = {
      id: 'app-settings-details-page-title',
      title: this.translate.instant('SETTINGS_ASSISTED_DIAGNOSTICS'),
      controls: [{
        id:'send',
        imgSrc: 'assets/imgs/header_send.svg',
        name:this.translate.instant('SEND'),
        isDisabled: this.azureService.isUploadDisabled || this.device.isOffline || (this.azureService.progress > 0 && this.azureService.progress < 100) || this.disableSendButton,
        align: "right"
    }],
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.goBack();
        break;
      case 'send':
        this.sendDiagnosticData();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }
}
