import { Component, ViewChild, ElementRef, Input, OnChanges, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';
import { SubRowData, Action, MarketScan, CaptureDataFor } from '../../../classes/market-scan/market-scan.class';
import _ from 'lodash';
import { MarketScanService } from '@omni/services/market-scan/market-scan.service';

@Component({
  selector: 'market-scan-table',
  templateUrl: 'market-scan-table.html',
  styleUrls: ['market-scan-table.scss']
})
export class MarketScanTableComponent implements OnInit, OnChanges {
  displayedColumns: any[] = [];
  displayedRows: any[] = [];
  @Input() columns: any[];
  @Input()
  rows: any[];
  @Input() subrows: SubRowData[];
  @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;
  @Output() dataChanged = new EventEmitter<any>();
  @Input() readonly: boolean = false;
  @Input() captureDataFor: number;
  APIAction = Action;
  placeHolderCellsToDisplay = [];
  focusedInputElement: HTMLElement;
  colWidth: number;
  isContentOverflowed = false;
  displayedColumnsLength: number;
  private visibleCount: number = 10;
  public filteredDisplayRows: any[] = [];
  public subRowsHeight: number[] = [];
  private marketScanWithMoreCustomerData: MarketScan;
  constructor(public device: DeviceService,
    public marketScanService: MarketScanService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.columns) {
      //sort columns [0-9 A-Z]
      this.displayedColumns = this.columns;
      this.displayedColumnsLength = this.columns.filter((c) => c.action !== Action.DELETE).length;
    }
    if (this.rows) {
      //sort rows [0-9 A-Z]
      this.displayedRows = this.rows;
    }
    this.showEmptyRow();
    this.setColumnWidth();
    this.setSubRowHeight();
    // this.updateVisibleRecords();
  }

  setSubRowHeight() {
    if (!this.subrows) return;
    setTimeout(() => {
      this.subRowsHeight = [];
      this.subrows.forEach((subrow, index) => {
        if (index) {
          if (subrow.action === this.APIAction.DELETE) {
            this.subRowsHeight.push(0);
          } else {
            const index = this.rows.findIndex(row => row['Customer']['action'] === Action.PATCH);
            if (document.getElementsByClassName("contact-" + subrow.value)  && index >=0) {
              const contactElement = document.getElementsByClassName("contact-" + subrow.value)[index] as HTMLElement;
              if (contactElement && contactElement.offsetHeight) {
                this.subRowsHeight.push(contactElement.offsetHeight);
              } else {
                this.subRowsHeight.push(14);
              }
            }
          }
        } else {
          //As contact font size
          this.subRowsHeight.push(14);
        }
      })
    }, 300);
  }

  setColumnWidth() {
    setTimeout(() => {
      if (this.tableContainer.nativeElement) {
        const availableSpace = (this.tableContainer.nativeElement as HTMLElement).clientWidth;
        const colums = this.displayedColumnsLength;
        if (availableSpace > (((colums - 1) * 100) + 200)) {
          this.colWidth = (availableSpace - 200) / (colums - 1);
          this.isContentOverflowed = false;
        } else {
          this.colWidth = 100;
          this.isContentOverflowed = true;
        }
      }
    }, 300)
  }

  ngAfterViewInit() {
    this.setColumnWidth();
    this.setSubRowHeight();
  }

  showEmptyRow() {
    setTimeout(() => {
      if (this.tableContainer.nativeElement) {
        let availableSpace = (this.tableContainer.nativeElement as HTMLElement).clientWidth;
        if (availableSpace) {
          const cellsToDisplay = (availableSpace / 110);
          this.placeHolderCellsToDisplay = Array(Math.trunc(cellsToDisplay)).fill(0);
          this.colWidth = (availableSpace - 200) / (this.columns.length - 1);
        }
      }
    }, 300);
  }


  onBlur(rowIndex: number, colIndex: number, measureIndex: number, subRowValue: string) {
    this.displayedRows[rowIndex]['Products'][colIndex][measureIndex][subRowValue] = this.validateInput(this.displayedRows[rowIndex]['Products'][colIndex][measureIndex][subRowValue]);
    const data = {
      productId: this.displayedRows[rowIndex]["Products"][colIndex]['id'],
      measureId: subRowValue,
      value: this.displayedRows[rowIndex]['Products'][colIndex][measureIndex][subRowValue],
      updateMarketScan: this.marketScanWithMoreCustomerData
    }
    if (this.captureDataFor === CaptureDataFor.ACCOUNTS) {
      data['accountId'] = this.displayedRows[rowIndex]['Customer']['id'];
    } else if (this.captureDataFor === CaptureDataFor.CUSTOMERS){
      data['contactId'] = this.displayedRows[rowIndex]['Customer']['id'];
    } else{
      data['accountId'] = this.displayedRows[rowIndex]['Customer']['id'];
      data['contactId'] = this.displayedRows[rowIndex]['Customer']['groupId'];
    }
    this.dataChanged.emit(data);
  }

  // resets value for negative number and truncates values after decimal point a float number
  validateInput(data) {
    let validatedData = null;
    if (data) {
      if (!isNaN(data)) {
        const integer = parseInt(data);
        if (integer >= 0) {
          validatedData = integer.toString()
        }
      } else if (!(/^[a-zA-Z0-9\s]*$/.test(data)) || data.trim().length == 0) { // special character check
        validatedData = null;
      } else {
        validatedData = data;
      }
    }
    return validatedData;
  }

  onPageScroll(event) {
    if (this.device.deviceFlags.ios) {
      if (this.focusedInputElement) {
        this.focusedInputElement.blur();
        this.focusedInputElement = null;
      }
    }
  }

  onInputFocus(inputRef) {
    this.focusedInputElement = inputRef as HTMLElement;
  }

}

