    <ion-item-group id='listScroll'>

        <ion-item-divider color="light" class="list-title normal-divider">
          <ion-label class="title-text" *ngIf="!listFiltered; else filtered">
            {{ !isSearchedInput ? ('ALL_ORDERS_S' | translate) : ('AL_RESULTS' | translate) }} ({{filteredSampleOrders ? filteredSampleOrders.length:''}})
          </ion-label>
          <ng-template #filtered>
            <ion-label class="title-text">
              {{ !isSearchedInput ? filterText : ('AL_RESULTS' | translate) }} ({{filteredSampleOrders ? filteredSampleOrders.length:''}})
            </ion-label>
          </ng-template>
        </ion-item-divider>

        <ng-container *ngIf="filteredSampleOrders.length > 0 else noSearchResult;">
        <ion-list>
            <div *ngFor="let sampleDrop of visiblefilteredSampleOrders">
                <main-card [viewData]='getViewDataModel(sampleDrop)'></main-card>
            </div>
        </ion-list>
        <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="20%">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
        </ng-container>

        <ng-template #noSearchResult>
          <ind-no-search-result *ngIf="!noSearchResults && filteredSampleOrders.length === 0 else noData;" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        </ng-template>
        <ng-template #noData>
        <ion-list>
            <ion-item class="orders-list-empty">
                <div class="no-data-message" *ngIf="filteredSampleOrders.length == 0 && noSearchResults">
                  {{'NO_ORDERS' | translate}}
                </div>
            </ion-item>
        </ion-list>
        </ng-template>

    </ion-item-group>
    