
import {map} from 'rxjs/operators';
import { DB_KEY_PREFIXES } from './../../config/pouch-db.config';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable ,  forkJoin } from "rxjs";
import { Endpoints } from "../../../config/endpoints.config";
import { AuthenticationService } from "../../services/authentication.service";
import { CaseManagementService } from "../../services/case-management/case-management.service";
import { isThisSecond } from "date-fns";
import { CaseActivity } from "../../classes/case-intake/case-activity.class";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DeviceService } from "../../services/device/device.service";
import { DiskService } from "../../services/disk/disk.service";
import { DB_SYNC_STATE_KEYS } from "../../config/pouch-db.config";
import { EntitySyncInfo, EntityNames, DeltaService } from "../delta/delta.service";
import * as moment from "moment";
import { EventsService } from "../../services/events/events.service";
import { Account } from '../../classes/account/account.class';
import { Contact } from '../../classes/contact/contact.class';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { CaseCategory } from '../../classes/case-intake/case-utility.class';
import { UIService } from '../../services/ui/ui.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { LoadingController } from '@ionic/angular';
import { LogService } from "../../services/logging/log-service";
import { GlobalErrorHandler } from "../../services/error-handler/error-handler-service";

@Injectable({
  providedIn: 'root'
})
export class CaseManagementDataService {

    constructor(
        private eventService: EventsService,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private caseManagementService: CaseManagementService,
        private deviceService: DeviceService,
        private disk: DiskService,
        private deltaService: DeltaService,
        private globalUtility: GlobalUtilityService,
        private uiService: UIService,
        private accountService: AccountOfflineService,
        private contactService: ContactOfflineService,
        private loadingCtrl: LoadingController,
        private logService: LogService,
        private errorHandler: GlobalErrorHandler,
    ) {

    }

    public async initCaseService(loadFromDBOnly = false) {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
            Promise.all([
                this.fetchCaseCategory(loadFromDBOnly),
                this.fetchResponsePreference(loadFromDBOnly),
            ]);
        }
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE) || this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_TOOL) || this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS) || this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_COMPETITOR_DATA) || this.authenticationService.user.buConfigs['indskr_competitorproduct']){
          await this.fetchCaseProducts(loadFromDBOnly)
        }
    }

    public async fetchCaseCategory(loadFromDBOnly = false) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
            return;
        }
        if (!(this.deviceService.isOffline || loadFromDBOnly)) {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.FETCH_EXPERT_CATEGORIES;
        return this.http.get(url).pipe(map(
            async (res: any) => {
                console.log(res);
                await this.caseManagementService.storeCaseType(res);
                this.disk.updateOrInsert(DB_KEY_PREFIXES.EXPERT_CATEGORIES, doc => ({ raw: res }));
            },
            async err => {
                console.log(err);
                console.log("error handling");
            })).toPromise();
        } else if (loadFromDBOnly) {
            const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.EXPERT_CATEGORIES, true);
            if (dbData) {
                await this.caseManagementService.storeCaseType(dbData.raw);
            }
        }
    }

    public async fetchResponsePreference(loadFromDBOnly = false) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
            return;
        }
        if (!(this.deviceService.isOffline || loadFromDBOnly)) {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.FETCH_RESPONSE_PREFERENCE;
        return this.http.get(url).pipe(map(
            async (res: any) => {
                console.log(res);
                await this.caseManagementService.storeResponsePreferences(res);
                this.disk.updateOrInsert(DB_KEY_PREFIXES.RESPONSE_PREFERENCE, doc => ({ raw: res }));
            },
            async err => {
                console.log(err);
                console.log("error handling");
            })).toPromise();
        } else if (loadFromDBOnly) {
            const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.RESPONSE_PREFERENCE, true);
            if (dbData) {
                await this.caseManagementService.storeResponsePreferences(dbData.raw);
            }
        }
    }

    public async createCaseOnline(data: CaseActivity) {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.CREATE_CASE;
        console.log(data.createCaseDTO);
        let response = await this.http.post(url, data.createCaseDTO).toPromise();
         // update for interaction
        await this.globalUtility.updateInteractionAccount(data._case_account, 'Inquiries');
        await this.globalUtility.updateInteractionContact(data._case_contact, 'Inquiries');
        return response;
    }

    // public async syncAllCases(dataRange: { from: string, to: string }, loadFromDBOnly = false) {
    //     if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
    //         return;
    //     }

    //     if (!(this.deviceService.isOffline || loadFromDBOnly)) {

    //         let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_INQUIRY);

    //         const doFullSync = !syncState || !syncState.lastUpdatedTime;

    //         let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.FETCH_ALL_CASES
    //             .replace('{startDate}', dataRange.from)
    //             .replace('{endDate}', dataRange.to)
    //             .replace('{casecategory}',`${CaseCategory.MEDICAL}`);
    //         url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

    //         const caseInqiurySyncInfo: EntitySyncInfo = {
    //             entityName: EntityNames.timeOff,
    //             totalFailed: 0,
    //             totalSynced: 0,
    //             errors: [],
    //             syncStatus: true
    //         };

    //         let response: any;
    //         try {
    //             response = await this.http.get(url).toPromise();
    //         } catch (error) {
    //             console.error('syncActivities: ', error);
    //             this.deltaService.addSyncErrorToEntitySyncInfo(caseInqiurySyncInfo, url, error);
    //             this.deltaService.addEntitySyncInfo(caseInqiurySyncInfo);
    //             return;
    //         }

    //         if (response) {
    //             const newLastUpdatedTime = new Date().getTime();
    //             if (doFullSync) {
    //                 /* Loading cases that were created but never sent */
    //                 await this.caseManagementService.loadMyCaseFromDb(dataRange);

    //                 if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE) && response['myCases']) {
    //                     await this.caseManagementService.mapFullSyncedMyCases(response['myCases'], newLastUpdatedTime);
    //                 }
    //                 if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAM_CASES) && response['teamCases']) {
    //                     await this.caseManagementService.mapFullSyncedTeamCases(response['teamCases'], newLastUpdatedTime);
    //                 }

    //                 if (response['myCases'] && Array.isArray(response['myCases'])) {
    //                     caseInqiurySyncInfo.totalSynced += response['myCases'].length;
    //                 }
    //                 if (response['teamCases'] && Array.isArray(response['teamCases'])) {
    //                     caseInqiurySyncInfo.totalSynced += response['teamCases'].length;
    //                 }
    //             }
    //             else {
    //                 if (!doFullSync) {
    //                     await this.caseManagementService.loadMyCaseFromDb(dataRange);
    //                     await this.caseManagementService.loadTeamCaseFromDb(dataRange);
    //                 }

    //                 // Sync the deltas
    //                 await this.caseManagementService.mapDeltaSyncedMyCases(response['myCases'], response['deletedCases'], newLastUpdatedTime);
    //                 if (Array.isArray(response['myCases'])) {
    //                     caseInqiurySyncInfo.totalSynced += response['myCases'].length;
    //                 }
    //                 if (Array.isArray(response['deletedCases'])) {
    //                     caseInqiurySyncInfo.totalSynced += response['deletedCases'].length;
    //                 }

    //                 await this.caseManagementService.mapDeltaSyncedTeamCases(response['teamCases'], newLastUpdatedTime);
    //                 if (Array.isArray(response['teamCases'])) {
    //                     caseInqiurySyncInfo.totalSynced += response['teamCases'].length;
    //                 }
    //             }

    //             this.deltaService.addEntitySyncInfo(caseInqiurySyncInfo);

    //           if (!this.uiService.toolsActivityActive) {
    //             this.eventService.publish("refreshAgenda");
    //           } else {
    //             this.uiService.agendaRefreshRequired = true;
    //           }
    //             // Done sync. Update sync state.
    //             if (caseInqiurySyncInfo.syncStatus) {
    //                 syncState.lastUpdatedTime = newLastUpdatedTime;
    //                 await this.disk.updateSyncState(syncState);
    //             }
    //         }
    //         return;
    //     }
    //     else {
    //         await this.caseManagementService.loadMyCaseFromDb(dataRange);
    //         await this.caseManagementService.loadTeamCaseFromDb(dataRange);
    //     }
    // }

    public async fetchCaseById(id) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
            return;
        }

        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.DELTA_FETCH_CASES_BY_ID.replace('{CASE_ID}', id);

        url += '?lastUpdatedTime=' + new Date().setHours(0, 0, 0, 0);

        return this.http.get(url).toPromise();
    }

    public async fetchAllOfflineCases() {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
            return;
        }

        let cases = await this.disk.loadOfflineCases();

        if (cases && cases['myCases'] && Array.isArray(cases['myCases']) && cases['myCases'].length) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.UPLOAD_OFFLINE_CASE;

            let payload: any[] = cases['myCases'];
                // .filter(e => { String(e.status).toLowerCase() != "draft" })
                // .map(c => { delete c.status });
            return this.http.post(url, payload).pipe(map(
                res => {
                    console.log(res);
                    //this.caseManagementService.mapOfflineCases(res);
                },
                err => {
                    console.log(err);
                }
            )).toPromise();
        }
        else
            return;
    }

    public async fetchCaseProducts(loadFromDBOnly = false) {
        if (!(this.deviceService.isOffline || loadFromDBOnly)) {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.FETCH_PRODUCT_SKU;
        const positions = this.authenticationService.user.positions.map((o) => {
            return o.ID
        })
        url = url.replace('{{positionIDs}}', positions.toString());
        return this.http.get(url).pipe(map(
            async (res: any) => {
                console.log(res);
                await this.caseManagementService.mapSkuProduct(res);
                this.disk.updateOrInsert(DB_KEY_PREFIXES.CASE_PRODUCTS, doc => ({ raw: res }));
            },
            async err => {
                console.log(err);
                console.log("error handling");
            })).toPromise();
        } else if (loadFromDBOnly) {
            const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.CASE_PRODUCTS, true);
            if (dbData) {
                await this.caseManagementService.mapSkuProduct(dbData.raw);
            }
        }
    }

    public async findCaseById(id) {

      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.FETCH_CASE_BY_ID.replace('{CASE_ID}', id);

      return this.http.get(url).toPromise();
    }

    public async getTeamCasesOnline():Promise<any>{
      let headers = new HttpHeaders();
      headers = headers.set('Sync-Service', 'true');
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_TEAM_ACTIVITIES_FOR_TOOLS_BY_ACTIVITY_TYPES;
      const dataRange = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
      url = url.replace('{startDate}', dataRange.from);
      url = url.replace('{endDate}', dataRange.to);
      //url = url.replace('{activityTypes}', 'case');
      url = url.replace('{teamActivity}', 'teamCase');
      url = url.replace('{positionIDs}', positions.toString());
      try {
        const response =  await this.http.get(url, { headers }).toPromise();
        if(response && response['teamCases'] && Array.isArray(response['teamCases'])){
          this.caseManagementService.teamCases = [];
          response['teamCases'].forEach(rawTeamCase=> {
            /* Pouch db doesnt allow to store keyword starting with _ */
            for (let key in rawTeamCase) {
              if (key.charAt(0) === "_") {
                  var a = key.substring(1, key.length);
                  rawTeamCase[a] = rawTeamCase[a] = rawTeamCase[key];
                  delete rawTeamCase[key];
                  delete rawTeamCase[key];
              }
            }
            const teamC = new CaseActivity(rawTeamCase);
            this.caseManagementService.teamCases.push(teamC);
          })
          this.caseManagementService.reMapTeamDataWithValues(this.accountService.accounts, this.contactService.contacts,this.caseManagementService.teamCases);
        }
      } catch (error) {
        console.log('Fetch Team Cases for Tool Error: '+error)
      }
      return;
    }

    // public async updateKitBookings(activity: CaseActivity, setBookings): Promise<boolean> {
    //   let isSuccess: boolean = true;
    //   let loader = await this.loadingCtrl.create();
    //   loader.present();
    //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.casemanagement.UPDATE_CASE_SETBOOKING.replace('{CASE_ID}', activity.ID);
  
    //   let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    //   headers.headers = headers.headers.set(
    //     "X-PositionId",
    //     this.authenticationService.user.xPositionID
    //   );
  
    //   try {
    //     let response = await this.http
    //       .put(url,setBookings , headers) // send only the required payload
    //       .toPromise();
    //     this.logService.logDebug(response);
    //     loader.dismiss();
    //   } catch (e) {
    //     isSuccess = false;
    //     this.errorHandler.handleError(e);
    //     loader.dismiss();
    //   }
  
    //   try { // I/O operations needs to be wrap in a try and catch block
    //     this.caseManagementService.upsertMyCases(activity); // offline saving
    //     loader.dismiss();
    //   } catch (e) {
    //     console.error('Caught error trying to save products and key messages offline', e);
    //     isSuccess = false;
    //     loader.dismiss();
    //   }
    //   return isSuccess;
    // }

}
