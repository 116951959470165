<ion-header>
  <ind-page-title [viewData]='{id:"opportunities-list",title: selectedAccount.accountName}'
    (onControlClick)='onClosePage()'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list class='opportunities-list' [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
    <ind-section-header *ngIf="allOpportunitesHeader" [viewData]='allOpportunitesHeader'
      (onFilterClick)="filterOpportunities($event)"></ind-section-header>
    <ion-item-sliding #slidingItem *ngFor="let opportunity of allModelOpportunities">
      <main-card [viewData]="opportunity">
        <div class='main-card-secondary-right'>
          <ng-container *ngIf="opportunity.customSecondaryTextRight">
            <!-- <span>Actual</span>&nbsp; -->
            <span class="ion-text-center displayPill">
              {{opportunity.customSecondaryTextRight}}
            </span>
          </ng-container>
          <ng-container *ngIf="opportunity.customSecondaryTextRightTwo">
            <span class="ion-text-center secondary-text-right-ellipsis">
              {{opportunity.customSecondaryTextRightTwo}}
            </span>&nbsp;
            <span>Estimated</span>
          </ng-container>
        </div>
      </main-card>
      <ion-item-options side="end" *ngIf="!device.isOffline && (device.isAndroid() || device.isIOS())">
        <div class="sliding-button" (click)="cloneOpprtunity(opportunity.id);slidingItem.close();">
          <img class="sliding-icon" src="assets/imgs/copyicon.svg" width="40" />
          <p class="sliding-btn-text">{{'CLONE'|translate}}</p>
        </div>
      </ion-item-options>
    </ion-item-sliding>

    <!-- <div *ngIf="allModelOpportunities.length == 0" class="no-data-message">{{'NO_OPPORTUNITIES' | translate}}</div> -->
  </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'opportunityList'">
</footer-toolbar>
<!-- </ion-footer> -->