import { createSelector } from '@ngrx/store';
import { fileState } from '../../application.state';

import { fileManagerState } from './file.state';

export const selectFileManager = (state: fileState) => state.fileManager;
export const selectIsDownloading = createSelector(selectFileManager, (state: fileManagerState) => state.isDownloading);
export const selectIsUnZipping = createSelector(selectFileManager, (state: fileManagerState) => state.isUnZipping);
export const selectPresentationId = createSelector(selectFileManager, (state: fileManagerState) => state.presentationId);
export const selectFileManagerError = createSelector(selectFileManager, (state: fileManagerState) => state.errorInfo);
export const selectDownloadQueue = createSelector(selectFileManager, (state: fileManagerState) => state.downloadQueue);
export const selectDownloadedQueue = createSelector(selectFileManager, (state: fileManagerState) => state.downloadedQueue);
