<ng-container *ngIf="!resource">
    <nothing-selected-view [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
</ng-container>
<ion-header *ngIf="resource">
    <ind-page-title [viewData]='{id:"resource-details",title: resource.title, controls: titleControls}' (onControlClick)='goBack()'></ind-page-title>
</ion-header>
<ion-content [hidden]="!resource" no-padding>
    <ng-container *ngIf="!resource; else checkIfsupported">
        <div class="no-data-message">{{'NOTHING_SELECTED' |translate}}</div>
    </ng-container>
    <ng-template #checkIfsupported>
        <ng-container *ngIf="!isSupportedFileType; else checkIfError">
            <p class="no-data-message">
                {{'CONTACT_NO_PREVIEW_AVAILABLE_FOR' | translate}} <i>{{resource.title}} - {{'PARTICI_NOT_SUPPORTED' | translate}}</i>
            </p>
        </ng-container>
    </ng-template>
    <ng-template #checkIfError>
        <ng-container *ngIf="error; else showPreview">
            <p class="no-data-message">{{'CONTACT_ERROR_IN_LOADING' | translate}} <i>{{resource.title}}</i>.<br />{{error}}</p>
        </ng-container>
    </ng-template>

    <ng-template #showPreview>
        <ng-container [ngSwitch]="assetType">
            <ng2-pdfjs-viewer #pdfViewer *ngSwitchCase="'pdf'" [pdfSrc]="assetURL" openFile="false" viewBookmark="false" download="false"
              viewerId="pdfViewerId"
              (onDocumentLoad)="onLoadDocument($event,'docLoad')"
              (onPageChange)="onLoadDocument($event,'pageChange')">
            </ng2-pdfjs-viewer>
            <div *ngSwitchCase="'image'" class='video-container'>
                <img [src]="assetURL | safe:'resourceUrl'" />
            </div>
            <iframe *ngSwitchCase="'web'" class='web-container' frameborder="0" [src]="assetURL | safe:'resourceUrl'">
      </iframe>
            <div class='video-container' *ngSwitchCase="'video'">
                <video controls [src]="assetURL | safe:'resourceUrl'" width="100%" oncontextmenu="return false;" controlsList="nodownload noremoteplayback">
          <source [src]="assetURL | safe:'resourceUrl'">
        </video>
            </div>
        </ng-container>
    </ng-template>
</ion-content>
<!-- <ion-footer *ngIf="resource"> -->
    <footer-toolbar *ngIf="resource" [footerView]="'detailView'" [selectedView]="'resources'"></footer-toolbar>
<!-- </ion-footer> -->
