import {Activity, ActivityType} from "../activity/activity.class";
import {Account} from "../account/account.class";
import {Contact, Email} from "../contact/contact.class";
import {Brand} from "../brand/brand.class";
import {
  CaseResponsePreference,
  CaseType,
  CategoryType,
  RawTimeline,
  TherapeuticArea,
  Timeline
} from "./case-utility.class";
import * as moment from 'moment';
import {Utility} from "../../utility/util";
import { CaseCategory } from './case-utility.class';

export class CaseActivity extends Activity {

    public _case_number: string;
    /* Meta data store from raw */
    public _case_type_id: string;
    public _case_response_preference_id;
    public _case_category_id;
    public _case_account_id;
    public _case_product_id;
    public _case_contact_id;
    public _case_status_id: any;
    public _case_therapeutic_id;
    public _case_timeline: Timeline[];

    /* Need to resolve this once the feature is opened */
    public _case_type: CaseType;
    public _case_account: Account;
    public _case_contact: Contact;
    public _case_product: Brand;
    public _case_response_preference: CaseResponsePreference;
    public _case_category: CategoryType;
    public _case_status_value: string = '';

    /* indskar_casecategory defines the type of case either or medical*/
    public indskr_casecategory: number = CaseCategory.MEDICAL;

    /* Not needed now */
    public _selected_email_id;
    public _selected_email: Email;

    public _selected_phone: string;

    public _selected_address_id;
    public _selected_address;

    public _case_notes: string;
    public _case_assignee: string = 'No Expert';
    public _case_therapeutic_area: TherapeuticArea;
    public isNew: boolean = false;
    public offline_ID: string;
    public _case_owner_id;
    public _case_resolve_date:Date;
    public raw;
    public _case_created_by: string;
    public signature: string;
    public _case_inquiry_response: any;
    public _case_inquiry_response_surveyData: any;
    public _case_inquiry_response_surveyLookupData: Array<any>;
    public _case_inquiry_additional_attributes: any;


    _case_title: string;

    _case_trans_customer_name;
    _case_trans_product_name;
    _case_trans_account_name;
    _case_trans_response_name;
    _case_trans_category_type;
    _case_trans_category;

    _online_meetingID;
    _offline_meetingID;

    offlineMeetingId: any;

    _case_trans_therapeutic_name;

    _case_overriddencreatedon: Date;
    _indskr_intakecreated: Date;

    _case_stage_value: string='';

    indskr_phonecallid;

    phoneCallOfflineId: string;
    indskr_kitbooking: string;

    constructor(raw: any) {
        super(raw);
        this.raw = raw;
        this.type = ActivityType.CaseIntake;
        this.indskr_casecategory = CaseCategory.MEDICAL;
        this.offline_ID = raw.indskr_externalid ? raw.indskr_externalid : raw.incidentid;
        this.offlineMeetingId = this.offline_ID;
        this._case_number = raw.ticketnumber;
        this._case_product_id = raw.productid_value;
        this.state = raw.statecode;
        this._case_status_id = raw.statuscode;
        this._case_status_value = raw['statuscode@OData.Community.Display.V1.FormattedValue'] ? raw['statuscode@OData.Community.Display.V1.FormattedValue'] : this.isNew ? 'Open' : '';
        this._case_stage_value = raw['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] ? raw['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] : this.isNew ? 'Open' : '';
        this.ID = raw.incidentid || raw['caseId'];
        this.scheduledStart = raw.createdon ? new Date(Number(raw.createdon)) : (raw['scheduledstart'] ? new Date(raw['scheduledstart']) : new Date());
        this.scheduledEnd = moment(this.scheduledStart).add( 30 , 'minute').toDate();
        this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'})
        this._case_type_id = raw.indskr_expertcategory_value;
        this._case_type = raw.indskr_expertcategory ? raw.indskr_expertcategory : undefined;
        this._case_notes = raw.description;
        this._case_response_preference_id = raw.indskr_responsepreference;
        this._case_category_id = raw.indskr_expertcategorytype_value;
        this.isNew = raw.isNew ? raw.isNew : false;
        this._case_account_id = raw.indskr_account_value ? raw.indskr_account_value : raw.accountid_value;
        this._case_contact_id = raw.customerid_value;
        this._case_owner_id = raw.ownerid_value || raw['indskr_ownerid'];
        this._case_assignee = raw['ownerid_value@OData.Community.Display.V1.FormattedValue'] ? raw['ownerid_value@OData.Community.Display.V1.FormattedValue'] : raw['caseAssignee'] ? raw['caseAssignee'] : '';
        this._online_meetingID = raw['indskr_meeting_value'] ? raw['indskr_meeting_value'] :undefined;
        this._case_inquiry_response = raw['expertInquiryResponse'] || '';
        this._case_inquiry_response_surveyData = raw['expertInquiryResponseSurveyData'];
        this._case_inquiry_response_surveyLookupData = raw['expertInquiryResponseSurveyLookupData'] || [];
        this._case_inquiry_additional_attributes = raw['caseInquiryLookup'] || {};
        this.indskr_kitbooking = raw['indskr_kitbooking_value'] || '';

        if(raw['indskr_phonecallid']){
            if (raw['indskr_phonecallid'].includes('offline')) {
                this.phoneCallOfflineId = raw['indskr_phonecallid'];
            }
            else {
              this.indskr_phonecallid = raw['indskr_phonecallid']
            }
        }
        else if(raw['phonecallid']){
            this.indskr_phonecallid = this.indskr_phonecallid = raw['phonecallid']
        }
        else if(raw['indskr_phonecallid_value']){
            this.indskr_phonecallid = raw['indskr_phonecallid_value']
        }
        else{
            this.indskr_phonecallid = undefined
        }

        this._case_trans_account_name = raw['indskr_account_value@OData.Community.Display.V1.FormattedValue'] ? raw['indskr_account_value@OData.Community.Display.V1.FormattedValue'] : "Account";
        this._case_trans_customer_name = raw['customerid_value@OData.Community.Display.V1.FormattedValue'] ? raw['customerid_value@OData.Community.Display.V1.FormattedValue'] : Utility.globalCustomerText;
        this._case_trans_category_type = raw['indskr_expertcategorytype_value@OData.Community.Display.V1.FormattedValue'] ? raw['indskr_expertcategorytype_value@OData.Community.Display.V1.FormattedValue'] : "Select";
        this._case_trans_category = raw['indskr_expertcategory_value@OData.Community.Display.V1.FormattedValue'] ? raw['indskr_expertcategory_value@OData.Community.Display.V1.FormattedValue'] : "Select";
        this._case_trans_product_name = raw['productid_value@OData.Community.Display.V1.FormattedValue'] ? raw['productid_value@OData.Community.Display.V1.FormattedValue'] : "Product";
        this._case_trans_response_name = raw['indskr_responsepreference@OData.Community.Display.V1.FormattedValue'] ? raw['indskr_responsepreference@OData.Community.Display.V1.FormattedValue'] : "Select";
        this._case_trans_therapeutic_name = raw['indskr_therapeuticarea_value@OData.Community.Display.V1.FormattedValue'] ? raw['indskr_therapeuticarea_value@OData.Community.Display.V1.FormattedValue'] : "Therapeutic Area";
        this._case_therapeutic_id = raw['indskr_therapeuticarea_value'] ? raw['indskr_therapeuticarea_value'] : "";

        this._selected_address = raw['indskr_customeraddress'];
        this._selected_email_id = raw['indskr_customeremail'];
        this._selected_phone = raw['indskr_customerphone'];
        this._case_created_by = raw['createdby_value'] ? raw['createdby_value'] : '';
        this._case_resolve_date = raw['caseResolutionDate'] ? new Date(Number(raw['caseResolutionDate'])) : undefined;

        this._case_overriddencreatedon = (raw['overriddencreatedon'] && !isNaN(new Date(Number(raw['overriddencreatedon'])).getTime()) ) ? new Date(Number(raw['overriddencreatedon'])) : undefined;
        this._indskr_intakecreated = (raw['indskr_intakecreated'] && !isNaN(new Date(Number(raw['indskr_intakecreated'])).getTime()) ) ? new Date(Number(raw['indskr_intakecreated'])) : undefined;
        this.signature = raw['indskr_signature'] ? raw['indskr_signature'] : raw['signature'];

        try {
            this._case_timeline = raw['inquiryTimeline'] ? this.getTimeline(raw['inquiryTimeline']) : [];
        } catch (error) {
            console.log('error converting timeline');
        }

    }

    getTimeline(raw: any[]): Timeline[] {
      return raw.map(e => new Timeline(e));
    }

    get createCaseDTO(): any {
        let obj = {
            "offlineCaseId": this.offline_ID,
            "productid": this._case_product ? this._case_product.ID : '',
            "customerid": this._case_contact.ID,
            "accountid": this._case_account ? this._case_account.id : '',
            "expertCategory": this._case_type_id,
            "expertCategoryType": this._case_category_id,
            "caseDescription": this._case_notes,
            "caseTitle": this._case_title,
            "therapeuticArea": this._case_therapeutic_id,
            "responsePreference": this._case_response_preference_id,
            'offlineMeetingId': undefined,
            'activityid': undefined,
            "createdby_value": this._case_created_by,
            "appCreatedDate": this._indskr_intakecreated.getTime().toString(),
            "indskr_casecategory": CaseCategory.MEDICAL,
            "expertInquiryResponse": this._case_inquiry_response,
            "caseInquiryLookup": this._case_inquiry_additional_attributes
        }
        if(this.signature){
            obj['signature'] = this.signature
         }

        //offline meeting ID
        if(this._online_meetingID && this._online_meetingID.includes('offline')) {
            obj['offlineMeetingId'] = this._online_meetingID;
        }
        //online meeting ID
        if(this._online_meetingID && !this._online_meetingID.includes('offline')) {
            obj['activityid'] = this._online_meetingID;
        }

        //Phone call activity id
        if(this.indskr_phonecallid){
            obj['indskr_phonecallid'] = this.indskr_phonecallid
        }

        //SetBooking activity id
        if(this.indskr_kitbooking){
          obj['indskr_kitbooking'] = this.indskr_kitbooking;
        }

        if (this._case_response_preference) {
            if (String(this._case_response_preference.name).toLowerCase().includes('email')) {
                obj["customerEmail"] = this._selected_email_id ? this._selected_email_id : ((this._selected_email)? this._selected_email.emailAddress: this._selected_email);
            }
            if (String(this._case_response_preference.name).toLowerCase().includes('phone')) {
                obj["customerPhone"] = this._selected_phone;
            }
            if (String(this._case_response_preference.name).toLowerCase().includes('visit')) {
                obj["customerAddress"] = this._selected_address;
            }
        }

        //remove keys with undefined value
        for (let key in obj) {
            if (!obj[key]) {
                delete obj[key];
            }
        }

        return obj;
    }

    get offlineDTO(): any {
        let obj: any = {
            "offlineCaseId": this.offline_ID,
            "productid": this._case_product_id,
            "accountid": this._case_account_id,
            "customerid": this._case_contact_id,
            "expertCategory": this._case_type_id,
            "expertCategoryType": this._case_category_id,
            "caseDescription": this._case_notes,
            "phoneCallOfflineId":this.phoneCallOfflineId,
            "caseTitle": this._case_title,
            "therapeuticArea": this._case_therapeutic_id,
            "responsePreference": this._case_response_preference_id,
            "status": this._case_status_value,
            'offlineMeetingId': undefined,
            'activityid': undefined,
            "createdby_value": this._case_created_by,
            "appCreatedDate": this._indskr_intakecreated.getTime().toString(),
            "indskr_casecategory":this.indskr_casecategory,
            "expertInquiryResponse": this._case_inquiry_response,
            "caseInquiryLookup": this._case_inquiry_additional_attributes
        };

        if(this.signature){
            obj['signature'] = this.signature
         }

        //offline meeting ID
        if(this._online_meetingID && this._online_meetingID.includes('offline')) {
            obj['offlineMeetingId'] = this._online_meetingID;
        }
        //online meeting ID
        if(this._online_meetingID && !this._online_meetingID.includes('offline')) {
            obj['activityid'] = this._online_meetingID;
        }

        //Phone call activity id
        if(this.indskr_phonecallid){
            obj['indskr_phonecallid'] = this.indskr_phonecallid
        }

        //SetBooking activity id
        if(this.indskr_kitbooking){
          obj['indskr_kitbooking'] = this.indskr_kitbooking;
        }

        if (this._case_response_preference) {
            if (String(this._case_response_preference.name).toLowerCase().includes('email')) {
                obj["customerEmail"] = this._selected_email_id ? this._selected_email_id : ((this._selected_email)? this._selected_email.emailAddress: this._selected_email);
            }
            if (String(this._case_response_preference.name).toLowerCase().includes('phone')) {
                obj["customerPhone"] = this._selected_phone;
            }
            if (String(this._case_response_preference.name).toLowerCase().includes('visit')) {
                obj["customerAddress"] = this._selected_address;
            }
        }

        for (let key in obj) {
            if (!obj[key]) {
                delete obj[key];
            }
        }

        return obj;
    }

    get generatedRaw(): any {
        let obj = {};
        Object.assign(obj, this.raw);
        obj['indskr_externalid'] = this.offline_ID;
        obj['incidentid'] = this.ID;
        obj['ticketnumber'] = this._case_number;
        obj['statuscode'] = this._case_status_id;
        obj['statecode'] = this.state;
        obj['statuscode@OData.Community.Display.V1.FormattedValue'] = this._case_status_value;
        obj['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] = this._case_stage_value;
        obj['createdon'] = this.scheduledStart.getTime();
        obj['indskr_intakecreated'] = this._indskr_intakecreated ? this._indskr_intakecreated.getTime() : this.scheduledStart.getTime();
        obj['productid_value'] = this._case_product_id;
        obj['indskr_expertcategory_value'] = this._case_type_id ? String(this._case_type_id) : this.getCaseType();
        obj['title'] = this._case_notes;
        obj['description'] = this._case_notes;
        obj['indskr_responsepreference'] = this._case_response_preference_id;
        obj['indskr_expertcategorytype_value'] = this._case_category_id;
        obj['isNew'] = this.isNew;
        obj['accountid_value'] = this._case_account_id;
        obj['customerid_value'] = this._case_contact_id;
        obj['indskr_therapeuticarea_value'] = this._case_therapeutic_id;
        obj['indskr_customeraddress'] = this._selected_address;
        obj['indskr_customeremail'] = this._selected_email_id;
        obj['indskr_customerphone'] = this._selected_phone;
        obj['indskr_expertcategory'] = this._case_type;
        obj['inquiryTimeline'] = this.getRawTimeline();
        obj['expertInquiryResponse'] = this._case_inquiry_response;
        obj['expertInquiryResponseSurveyData'] = this._case_inquiry_response_surveyData;
        obj['expertInquiryResponseSurveyLookupData'] = this._case_inquiry_response_surveyLookupData;
        obj['caseInquiryLookup'] = this._case_inquiry_additional_attributes;

        if(this._case_resolve_date) {
            obj['caseResolutionDate'] = new Date(Number(this._case_resolve_date)).getTime();
        }
        if (this.signature) {
          obj['signature'] = this.signature
        }

        obj['indskr_account_value@OData.Community.Display.V1.FormattedValue'] = this._case_trans_account_name;
        obj['customerid_value@OData.Community.Display.V1.FormattedValue']  = this._case_trans_customer_name;
        obj['indskr_expertcategorytype_value@OData.Community.Display.V1.FormattedValue']  = this._case_trans_category_type;
        obj['indskr_expertcategory_value@OData.Community.Display.V1.FormattedValue']  = this._case_trans_category;

        obj['productid_value@OData.Community.Display.V1.FormattedValue'] = this._case_trans_product_name;
        obj['indskr_responsepreference@OData.Community.Display.V1.FormattedValue'] = this._case_trans_response_name;
        obj['indskr_therapeuticarea_value@OData.Community.Display.V1.FormattedValue']  = this._case_trans_therapeutic_name;
        obj['createdby_value'] = this._case_created_by;

        for(let key in obj) {
            if(obj[key] === undefined) {
                delete obj[key];
            }
        }

        return obj;
    }

    getRawTimeline(): RawTimeline[] {
        let res: RawTimeline[] = [];

        this._case_timeline.forEach(timeline => {
            let obj: RawTimeline = {
              activityStatus : timeline.status ? timeline.status : "",
              activityDescription : timeline.description ? timeline.description : "",
              activitytypecode : timeline.activitytypecode ? timeline.activitytypecode : "",
              incidentid : timeline.incidentid ? timeline.incidentid : "",
              indskr_ownerfullname : timeline.indskr_ownerfullname ? timeline.indskr_ownerfullname : "",
              location : timeline.location ? timeline.location : "",
              scheduledstart : timeline.scheduledStart ? String(timeline.scheduledStart.getTime()) : "",
              subject : timeline.subject ? timeline.subject : "",
              emailSentOn : timeline.emailSentOn ? String(timeline.emailSentOn.getTime()) : "",
              readOn : timeline.readOn ? String(timeline.readOn.getTime()) : "",
              currentStatus: timeline.currentStatus ? timeline.currentStatus : "",
              partyId: timeline.partyId ? timeline.partyId : "",
              phoneNumber: timeline.phoneNumber ? timeline.phoneNumber : "",
              ownerId: timeline.ownerId ? timeline.ownerId : "",
              contactName: timeline.contactName ? timeline.contactName : "",
              activityId: timeline.activityId ? timeline.activityId : "",
            };
            res.push(obj);
        });

        return res;
    }

    getCaseType() {
        console.log(this._case_type_id);
        return String(this._case_type_id);
    }

    public get caseName(): string {
        //let caseNum = this._case_number ? this._case_number : '';
        try {
            return  this._case_number ? (this._case_type ? this._case_type.name + ' - ' : this._case_trans_category ? this._case_trans_category + ' - ' : '')  + this._case_number : (this._case_type && this._case_type.name) ? this._case_type.name : this._case_trans_category ? this._case_trans_category : '';
        } catch (error) {
            console.log("naming error at class");
            return "";
        }
    }

}
