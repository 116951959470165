import { IAllocationTransfer } from './../../../interfaces/allocation/allocation-transfer.interface';
import { AllocationFeatureService } from './../../../services/sample/allocation.feature.service';
import { SampleDataService } from './../../../data-services/sample/sample.data.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { SampleService } from '../../../services/sample/sample.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { UIService } from '../../../services/ui/ui.service';
import { FooterService } from '../../../services/footer/footer.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { AllocationTransferService } from '../../../services/sample/allocation-transfer.service';
import { AllocationTransferForm } from '../../../classes/sample/allocation-transfer.class';
import { isAfter, isValid, subDays } from 'date-fns';
import { Lot } from '../../../classes/sample/lot.class';
import { DeviceService } from '../../../services/device/device.service';
import { AllocationTransferDataService } from '../../../data-services/sample/allocation-transfer.data.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, AlertController, ModalController, PopoverController } from '@ionic/angular';
import { SelectListDetail } from '../../../models/select-list-detail-model';
import { SelectListComponent } from '../../shared/select-list/select-list';
import { SelectListData } from '../../popover/popover';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import * as moment from 'moment';
import { EventsService } from '@omni/services/events/events.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { DatePipe } from '@angular/common';
import { isEmpty } from 'lodash';
import { ShipmentStatus, TransferType } from '../../../enums/allocation/allocation.enum';
import { ActivityService } from './../../../services/activity/activity.service';
import _ from 'lodash';
import { NotificationService } from '@omni/services/notification/notification.service';
import { AllocationInventoryDataService } from '@omni/data-services/sample/allocation-inventory.data.service';
import { AllocationInventoryService } from '@omni/services/sample/allocation-inventory.service';

@Component({
    selector: 'allocation-transfer',
    templateUrl: 'allocation-transfer.html',
  styleUrls:['allocation-transfer.scss']
})
export class AllocationTransferComponent implements OnInit {

    pillScrollContainer;

    shipmentStatus = ShipmentStatus;
    allocationTransferForm: AllocationTransferForm;
    alert: HTMLIonAlertElement;
    loader: HTMLIonLoadingElement;
    private _selectProductModal;
    private _selectReasonPopover;
    allocationTransferPanePageTitle: IndPageTitleViewDataModel;
    detailsHeader: IndSectionHeaderViewDataModel;
    availableLotsHeader: IndSectionHeaderViewDataModel;
    selectedLotsHeader: IndSectionHeaderViewDataModel;
    private _updatedFieldData = false;
    focusedInputElement: HTMLElement;
    private readonly ngDestroy$: any = new Subject<boolean>();
    private isClickedSelectedUser: boolean = false;
    private isClickedProduct: boolean = false;
    private sampleLastDispatchLimitNum: number = 0;
    formattedDateInfoLabelForSampleLot: string;


    constructor(
        private readonly allocFeatureService: AllocationFeatureService,
        private readonly sampleService: SampleService,
        private readonly el: ElementRef,
        public device: DeviceService,
        public repService: RepServices,
        public uiService: UIService,
        public footerService: FooterService,
        public trackingService: TrackService,
        public allocTransferService: AllocationTransferService,
        public allocTransferDataService: AllocationTransferDataService,
        public loadingCtrl: LoadingController,
        public alertCtrl: AlertController,
        public translate: TranslateService,
        private readonly modalCtrl: ModalController,
        public dateTimeFormatsService: DateTimeFormatsService,
        private readonly popoverCtrl: PopoverController,
        private readonly events: EventsService,
        private sampleDataService: SampleDataService,
        private authService: AuthenticationService,
        private datePipe: DatePipe,
        private activityService: ActivityService,
        public notifyService: NotificationService,
        private allocationInventoryDataService: AllocationInventoryDataService,
        private allocationInventoryService: AllocationInventoryService,
    ) {}

    public get shipmentStatusTxt(): string{
        let statusTxt = '';
        if(this.allocationTransferForm){
            switch (this.allocationTransferForm.shipmentStatusTxt) {
                case 'Open':
                    statusTxt = this.translate.instant('OPEN');
                    break;
                case 'ForReview':
                    statusTxt = this.translate.instant('FOR_REVIEW');
                    break;
                case 'NotApproved':
                    statusTxt = this.translate.instant('NOT_APPROVED');
                    break;
                case 'Shipped':
                    statusTxt = this.translate.instant('SHIPPED');
                    break;
                default:
                    statusTxt = this.allocationTransferForm.shipmentStatusTxt;
                    break;
            }
        }
        return statusTxt;
    }

    ngOnInit() {
        this.updateLatestUserLotInfo(true);
        this.sampleLastDispatchLimitNum = this.authService.user.sampleLastDispatchLimit || 0;
        this.allocationTransferForm = this.allocTransferService.initForm();
        this.footerService.initButtons('Allocation Transfer');
        this.events.unsubscribe('openTransferConfirm');
        this.events.subscribe('openTransferConfirm', () => {
          this.openTransferConfirmAlert();
        });
        this._initPageTitleFooterButtons();
        this._initSectionHeaderDetails();

        this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((offline) => {
          this._initPageTitleFooterButtons(offline);
        });

    }

    ngOnDestroy() {
      this.events.unsubscribe('openTransferConfirm');
      this.ngDestroy$.next(true);
      this.ngDestroy$.complete();
    }

    public onPageTitleControlClick(id: string) {
        switch (id) {
            case 'close':
                this.onClosePage();
                break;
            case 'transfer':
                this.openTransferConfirmAlert();
                break;
            default:
                console.log('Unhandled switch case statement');
                break;
        }
    }
    onClosePage() {
        this.uiService.activeView = this.uiService.prevView;
        this.allocTransferService.destroyForm();
    }
    async openTransferConfirmAlert() {
        if (this.allocTransferService.isDisableTransfer) {
          this._initPageTitleFooterButtons();
          return;
        }
        if (!this.alert) {
            this.alert =await this.alertCtrl.create({
                header: this.translate.instant('AL_TRANSFER_MATERIAL'),
                message: this.translate.instant('AL_R_U_SURE_TRANS_MATERIAL'),
                buttons: [
                    {
                        text: this.translate.instant('CANCEL'),
                        role: 'cancel',
                        handler: () => { }
                    },
                    {
                        text: this.translate.instant('AL_TRANSFER_ALERT'),
                        handler: await this._confirmed,
                    }
                ]
            });
            this.alert.present();
            this.alert.onDidDismiss().then(() => this.alert = undefined);
        }
    }

    private _confirmed = () => {
        this.transferShipment();
    }

    async transferShipment() {
        if (!this.loader) {
            this.loader = await this.loadingCtrl.create();
        }
        await this.loader.present();

        try {
            const newAllocTransfer: IAllocationTransfer = await this.allocTransferDataService.transfer(this.allocationTransferForm);
            if (newAllocTransfer?.indskr_transfertype === TransferType.AllocationTransfer) {
                // Reset form
                this.allocationTransferForm = undefined;
                // Display newly created transfer
                this.sampleService.selectedSampleSKU = null;
                this.allocFeatureService.selectedShipment = newAllocTransfer;
                this.uiService.prevView = '';
                this.uiService.activeView = 'AllocationShipment';
                this.allocTransferService.newAllocTransferAdded$.next(true);
                this.allocTransferService.destroyForm();
                this.events.publish('syncAllocationInventory');
                if (this.authService.user.approvalForAllocationTransfer) {
                  this.notifyService.notify(this.translate.instant('ALLOCATION_TR_REQ_SUCCESS'), '', 'top', '');
                }
            } else {
                console.error('AllocationTransferComponent: transferShipment: not created..', newAllocTransfer);
                // Error Handling for the duplicate record when there is network fluctuation. The button will get disabled.
                if(this.allocTransferService.isDisableTransfer) {
                  this.footerService.enableButtons(['transferallocation'], false);
                  this._initPageTitle();
                  return;
                }
            }

            if (this.loader) {
                await this.loader.dismiss();
            }
        } catch (error) {
            console.error('AllocationTransferComponent: transferShipment: error', error);

            if (this.loader) {
                await this.loader.dismiss();
            }
        }
        this._initPageTitleFooterButtons();
    }

    private _initPageTitle(offline: boolean = false): void {
        const buttons = [];
        if(!this.isSplitView){
            buttons.push({
            id: 'close',
            icon: 'chevron-back-outline',
            isDisabled: false,
            align: 'left'
            });
        }
        buttons.push({
            id: 'transfer',
            imgSrc: 'assets/imgs/header_send.svg',
            name: this.translate.instant('TRANSFER'),
            isDisabled: this.device.isOffline || offline || this.allocTransferService.isDisableTransfer,
            align: 'right'
        });
        this.allocationTransferPanePageTitle = {
            id: 'allocation-order-transfer-page-title',
            title: this.translate.instant('AL_MATERIAL_TRANSFER'),
            controls: buttons
        };
    }

    private _initSectionHeaderDetails(): void {
        this.detailsHeader = {
            id:'details-header',
            title: this.translate.instant('DETAILS_CAP'),
            controls: [],
        };
        this.availableLotsHeader = {
            id:'available-lots-header',
            title: this.translate.instant('AVAILABLE_LOTS_CAP'),
            isRequired: this.allocationTransferForm?.sku && this.allocationTransferForm?.availableLots?.length>0,
            controls: [
                {
                    id:'available_lots_clear',
                    text:this.translate.instant('CLEAR_LOTS'),
                    isDisabled:!this.allocationTransferForm?.lot,
                },
             ],
        };
        this.selectedLotsHeader = {
            id:'selected-lots-header',
            title: this.translate.instant('AL_SELECTED_LOT'),
            isRequired: this.allocationTransferForm?.sku && !_.isEmpty(this.allocationTransferForm?.lot),
            controls: [],
        };
    }
    public onSectionHeaderControlClick(id: string){
        switch(id){
            case 'available_lots_clear':
                this.clearLotSelection();
                break;
            default:
                console.log('Unhandled switch case statement');
                break;
        }
    }
    sectionHeaderRefresh() {
        if(this._updatedFieldData) {
            this._initSectionHeaderDetails();
            this._updatedFieldData =false;
        }
        this._initSectionHeaderDetails();
    }
    public get statusFormField(): IndFormFieldViewDataModel {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('STATUS'),
          inputText: this.shipmentStatusTxt,
          id: 'status-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        return viewData;
      }
    public get transferNumberField(): IndFormFieldViewDataModel {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('TRANSFER_NUMBER'),
          inputText: '--',
          id: 'transfer-number-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        return viewData;
    }
    public get shipmentDateField(): IndFormFieldViewDataModel  {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('AL_TRANSFER_DATE'),
          inputText: this.datePipe.transform(this.allocationTransferForm?.shipmentDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),//this.formattedDate(this.allocationTransferForm?.shipmentDate.toISOString()),
          id: 'shipment-date-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        return viewData;
    }
    formattedDate(date: string): string {
        return moment(date).format(this.dateTimeFormatsService.dateToUpper);
    }
    public get selectedUserField(): IndFormFieldViewDataModel  {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('AL_TRANSFER_TO'),
          inputText: isEmpty(this.allocationTransferForm?.user) ? '':this.allocationTransferForm?.user?.title,
          customPlaceholderLabel: this.translate.instant('AL_SELECT_USER'),
          id: 'selected-user-field',
          isReadOnly: true,
          isDisabled: false,
          showArrow: true,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
          isRequired: true,
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isEmptyRequiredField: this.isClickedSelectedUser && isEmpty(this.allocationTransferForm?.user),
          eventHandler: (id: string, event, eventName) => this._handleFormFieldTransfer(id, event, eventName),
        };
        return viewData;
    }
    public get selectedProductField(): IndFormFieldViewDataModel  {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('PRODUCT'),
          inputText: isEmpty(this.allocationTransferForm?.sku) ? '':this.allocationTransferForm?.sku?.title,
          customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
          id: 'selected-product-field',
          isReadOnly: true,
          isDisabled: false,
          showArrow: true,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
          isRequired: true,
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isEmptyRequiredField: this.isClickedProduct && isEmpty(this.allocationTransferForm?.sku),
          eventHandler: (id: string, event, eventName) => this._handleFormFieldTransfer(id, event, eventName),
        };
        return viewData;
    }

    public get reasonField(): IndFormFieldViewDataModel  {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
            label: this.translate.instant('REASON'),
            inputText: this.allocationTransferForm?.reason ? this.allocationTransferForm.reason.reason : this.allocationTransferForm.shipmentStatus === this.shipmentStatus.Shipped?this.translate.instant('NO_REASON'):'',
            customPlaceholderLabel: this.allocationTransferForm?.shipmentStatus !== this.shipmentStatus.Shipped ? this.translate.instant('SELECT_REASON'):'',
            id:'reason-field',
            isReadOnly: true,
            isDisabled: false,
            showArrow: this.allocationTransferForm.shipmentStatus !== this.shipmentStatus.Shipped,
            formFieldType: FormFieldType.POPOVER_SELECT,
            eventHandler: (id: string, event, eventName) => this._handleFormFieldTransfer(id,event,eventName),
        };
        return viewData;
    }
    private _handleFormFieldTransfer(id, event, eventName) {
        if (id) {
          switch (id) {
            case 'selected-user-field':
              this.openUserSelection();
              break;
            case 'selected-product-field':
              this.openProductSelection();
              break;
            case 'reason-field':
              this.openSelectReason(event);
              break;
            case 'comment-field':
              if (eventName && eventName == 'input_value_confirm') {
                this.handleCommentsInput(event);
              }
              break;
            default:
              console.log('Unhandled switch case statement');
              break;
          }
        }
      }

    public getCommentFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('ACCOUNT_COMMENTS'),
        inputText: (this.allocationTransferForm.lot && this.allocationTransferForm.comments) ? this.allocationTransferForm.comments : '',
        inputValue: (this.allocationTransferForm.lot && this.allocationTransferForm.comments) ? this.allocationTransferForm.comments : '',
        id: 'comment-field',
        isReadOnly: !this.allocationTransferForm.lot,
        isDisabled: !this.allocationTransferForm.lot,
        placeholderLabel: this.translate.instant('ACCOUNT_COMMENTS'),
        showArrow: !(!this.allocationTransferForm.lot),
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: !(!this.allocationTransferForm.lot || this.allocationTransferForm.reason.value !==548910000),
        eventHandler: (id: string, event, eventName) => this._handleFormFieldTransfer(id, event, eventName),
      };
      return viewData;
    }

    handleCommentsInput(event) {
      if (this.allocationTransferForm.lot) {
        // if (this.allocationTransferForm.shipmentStatus === this.shipmentStatus.Shipped) {
          let comment = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : "";
          if (this.allocationTransferForm.comments != comment) {
            this.allocationTransferForm.comments = comment;
            // this._cd.markForCheck();
          }
          this.allocationTransferForm.validate();
          this._initPageTitleFooterButtons();
        // }
      }
    }

    async openUserSelection() {
        const selectListDetail: SelectListDetail = {
            isMultipleSelectionEnabled: false,
            isSearchEnabled: true,
            title: this.translate.instant('USERS'),
            dividerTitle: this.translate.instant('ALL_USERS'),
            data: this.allocTransferService.allocationTransferUsers,
            navOptions: { animate: false }
        };
        this._selectProductModal = await this.modalCtrl.create({component: SelectListComponent, componentProps:{ viewData: selectListDetail },animated:false, backdropDismiss: false });
        this._selectProductModal.present();

        this._selectProductModal.onDidDismiss().then(async (modalData: any) => {
            this.isClickedSelectedUser = true;
            let data = modalData.data;
            if (data && data.isDone && Array.isArray(data.selectedItems)) {
                this._onUserSelection(data.selectedItems[0]);
            }
        });
    }

    async openProductSelection() {
        const selectListDetail: SelectListDetail = {
            isMultipleSelectionEnabled: false,
            isSearchEnabled: true,
            title: this.translate.instant('PRODUCTS'),
            dividerTitle: this.translate.instant('ACCOUNT_CALL_PLAN_ALL_PRODUCTS'),
            data: this.allocTransferService.getTransferProducts(this.sampleService.lots),
            navOptions: { animate: false }
        };
        this._selectProductModal = await this.modalCtrl.create({component:SelectListComponent,componentProps: { viewData: selectListDetail }, backdropDismiss: false , animated:false});
        this._selectProductModal.present();

        this._selectProductModal.onDidDismiss().then(async (modalData: any) => {
            this.isClickedProduct = true;
            let data = modalData.data;
            if (data && data.isDone && Array.isArray(data.selectedItems)) {
                await this._onSkuSelection(data.selectedItems[0]);
            }
        });
    }

  selectLot(lot: Lot) {
    if (!this.allocationTransferForm.lot || this.allocationTransferForm.lot.id !== lot.id) {
      // Reset qty &  reason
      this.allocationTransferForm.quantityTransferred = null;
      this.allocationTransferForm.reason = null;

      this.allocationTransferForm.lot = lot;

      this.formattedDateInfoLabelForSampleLot = this._getDateInfoLabelForSampleLot(lot);
      
      this.allocationTransferForm.validate();
      this._initPageTitleFooterButtons();
    } else if (this.allocationTransferForm.lot.id === lot.id) {
      this.clearLotSelection();
    }
    this._updatedFieldData = true;
    this.sectionHeaderRefresh();
  }

    clearLotSelection(withoutValidation = false) {
        this.allocationTransferForm.lot = undefined;
        this.formattedDateInfoLabelForSampleLot = '';
        this.allocationTransferForm.quantityTransferred = null;
        this.allocationTransferForm.reason = null;

        if (!withoutValidation) {
            this.allocationTransferForm.validate();
            this._initPageTitleFooterButtons();
        }
        this._updatedFieldData = true;
        this.sectionHeaderRefresh();
    }
    isLotSelected(lot: Lot) {
        return this.allocationTransferForm.lot && lot && lot.id === this.allocationTransferForm.lot.id;
    }

    isQtyValid() {
        this.allocationTransferForm.validate();
        this._initPageTitleFooterButtons();
        return this.allocationTransferForm.isQtyValid;
    }

    arrowClicked(direction: string) {
        if (!this.pillScrollContainer) {
            this.pillScrollContainer = this.el.nativeElement.querySelector('#lotsContainer');
        }
        if (this.pillScrollContainer) {
            const curXpos = this.pillScrollContainer.scrollLeft;
            const offsetWidth = this.pillScrollContainer.offsetWidth;
            const scrollWidth = this.pillScrollContainer.scrollWidth;
            let newXpos = 0;
            if (direction === 'left') {
                newXpos = curXpos - offsetWidth >= 0 ? curXpos - offsetWidth : 0;
            } else if (direction === 'right') {
                const maxXpos = scrollWidth;
                newXpos = curXpos + offsetWidth <= maxXpos ? curXpos + offsetWidth : maxXpos;
            }
            this.pillScrollContainer.scroll({ top: 0, left: newXpos, behavior: 'smooth' });
        }
    }

    private _onUserSelection(user: SelectListData) {
        if ((!user && this.allocationTransferForm.user) || !this.allocationTransferForm.user || this.allocationTransferForm.user.id !== user.id) {
            this.allocationTransferForm.user = user;
            this.allocationTransferForm.validate();
            this._initPageTitleFooterButtons();
        }
    }
    private async _onSkuSelection(sku: SelectListData) {
        if ((!this.allocationTransferForm.sku && sku)
            || (this.allocationTransferForm.sku && !sku)
            || (this.allocationTransferForm.sku && sku && this.allocationTransferForm.sku.id !== sku.id)) {
            this.clearLotSelection(true);
            this.allocationTransferForm.sku = sku;
            this.allocationTransferForm.validate();
            this.allocationTransferForm.availableLots = []
            if(sku) this.allocationTransferForm.availableLots = await this._getAvailableLotsForSku(sku);
            this._initPageTitleFooterButtons();
            this._initPageTitle();
            this._initSectionHeaderDetails();
        }
    }
  private async _getAvailableLotsForSku(sku: SelectListData) {

    await this.fetchRealTimeLotDetails();
    await this.updateLatestUserLotInfo();

    const lots = this.sampleService.lots.filter(lot => lot.sampleSKUId === sku.id && this._checkValidDate(lot) && lot.status === 0 && lot.totalQuantityRemaining > 0);
    return lots;
  }

  private async fetchRealTimeLotDetails() {

     if (!this.loader) {
      this.loader = await this.loadingCtrl.create();
    }

    await this.loader.present();

    if (!this.device.isOffline && !this.device.isUserStateOffline) {

      const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
      await this.sampleDataService.syncLots(dataRangeWithFutureBoundBySixMonths);
    }
    await this.loader.dismiss();
    this.loader = undefined;
  }

  async openSelectReason(ev) {
    if (this.allocationTransferForm.shipmentStatus !== ShipmentStatus.Shipped) {
      if (Array.isArray(this.allocTransferService.transferReasons) && this.allocTransferService.transferReasons.length > 0) {
        let dropdownListDetail: IndDropdownListDetailModel = {
          id: 'Shipment-Reason-Select',
          data: this.allocTransferService.transferReasons.map(reason => {
            let obj = {
              title: reason.reason,
              id: reason.value.toString(),
              isSelected: false,
            };
            if (this.allocationTransferForm.reason && reason.value == this.allocationTransferForm.reason.value) {
              obj['isSelected'] = true;
            }
            return obj;
          }),
        };
        this._selectReasonPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, event: event, cssClass: 'dropdown-list-view' });
        this._selectReasonPopover.onDidDismiss().then(async (selectData: any) => {
          let data = selectData.data;
          if (data && data.selectedItems && data.selectedItems.length === 1 && (!this.allocationTransferForm.reason || data.selectedItems[0].id !== this.allocationTransferForm.reason.value)) {
            this.allocationTransferForm.reason = {
              value: data.selectedItems[0].id,
              reason: data.selectedItems[0].title,
            };
          }
          this.allocationTransferForm.validate();
          this._initPageTitleFooterButtons();
          this._initPageTitle();
          this._selectReasonPopover = undefined;
        });
        this._selectReasonPopover.present();
      }
    }
  }

    isSplitView() {
        return (window.innerWidth > 500);
    }

    onInputFocus(focusInput) {
      this.focusedInputElement = focusInput as HTMLElement;
    }

    private _initPageTitleFooterButtons(offline: boolean = false) {
      if(!this.device.isOffline || !offline) {
        if (!isEmpty(this.allocationTransferForm) && this.allocationTransferForm.isValid) {
          this.allocTransferService.isDisableTransfer = false;
          this.footerService.enableButtons(['transferallocation'], true);
        } else {
          this.allocTransferService.isDisableTransfer = true;
          this.footerService.enableButtons(['transferallocation'], false);
        }
      } else {
        this.allocTransferService.isDisableTransfer = true;
        this.footerService.enableButtons(['transferallocation'], false);
      }
      this._initPageTitle(offline);
    }

    onInput(event) {
      if(event && event.target) {
        this._initPageTitleFooterButtons();
      }
    }

    private _getDateInfoLabelForSampleLot(lot: Lot): string {
      let dateInfoLabel: string = '';
      let lastDispatchDateStr: string = '';
      let validToDateStr: string = '';
      
      if (lot.lastdispatchdate) {
        lastDispatchDateStr = this.translate.instant('AL_LAST_DISPATCH_DATE') + ' ' + this.datePipe.transform(lot.lastdispatchdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      } else if (this.sampleLastDispatchLimitNum > 0 && lot.validTo) {
        const calculatedDateWithLastDispatch: Date = subDays(new Date(lot.validTo), this.sampleLastDispatchLimitNum);
        if (calculatedDateWithLastDispatch && isValid(calculatedDateWithLastDispatch)) {
          lastDispatchDateStr = this.translate.instant('AL_LAST_DISPATCH_DATE') + ' ' + this.datePipe.transform(calculatedDateWithLastDispatch, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);  
        }
      }
      if (lot.validTo && isValid(lot.validTo)) {
        validToDateStr = this.translate.instant('AL_EXPIRES') + ' ' + this.datePipe.transform(lot.validTo, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      }
  
      if (lastDispatchDateStr && validToDateStr) {
        dateInfoLabel = `${lastDispatchDateStr} │ ${validToDateStr}`;
      } else if (!lastDispatchDateStr && validToDateStr) {
        dateInfoLabel = `${validToDateStr}`;
      } else if (lastDispatchDateStr && !validToDateStr) {
        dateInfoLabel = `${lastDispatchDateStr}`;
      } else {
        dateInfoLabel = '';
      }
      return dateInfoLabel;
    }

    private _checkValidDate(currentLot: Lot): boolean {
      let isValidDate: boolean = false;
      const today = new Date();
  
      if (currentLot && currentLot.validTo && isValid(currentLot.validTo)) {
        isValidDate = isAfter(new Date(currentLot.validTo),today);
      }    
      return isValidDate;
    }

    private async updateLatestUserLotInfo(isRealTimeFetch: boolean = false) {
      if (this.device.isOffline || _.isEmpty(this.sampleService.lots)) return;
      if (!isRealTimeFetch) {
        if (!_.isEmpty(this.allocationInventoryService.userInventory)) {
          this.sampleService.lots.map(lot=>{
            if (lot.sampleSKUId) {
              lot.lastdispatchdate = this.allocationInventoryService.getLastDispatchDateString(lot.sampleSKUId, lot.id);
            }
            return lot;
          });
        } else {
          await this.fetchInventory().then(() => {
            if (!_.isEmpty(this.allocationInventoryService.userInventory)) {
              this.sampleService.lots.map(lot=>{
                if (lot.sampleSKUId) {
                  lot.lastdispatchdate = this.allocationInventoryService.getLastDispatchDateString(lot.sampleSKUId, lot.id);
                }
                return lot;
              });
            }
          }).catch((err) => {
            console.log("fetching allocation Inventory failed: " + err);
          });
        }
      } else {
        await this.fetchInventory().then(() => {
          if (!_.isEmpty(this.allocationInventoryService.userInventory)) {
            this.sampleService.lots.map(lot=>{
              if (lot.sampleSKUId) {
                lot.lastdispatchdate = this.allocationInventoryService.getLastDispatchDateString(lot.sampleSKUId, lot.id);
              }
              return lot;
            });
          }
        }).catch((err) => {
          console.log("fetching allocation Inventory failed: " + err);
        });
      }
    }
  
    private async fetchInventory() {
      return await this.allocationInventoryDataService.fetchInventory(true, false);
    }
}
