import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SelectListData } from '../../../models/select-list-data-model';
import { SelectListDetail } from '../../../models/select-list-detail-model';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
const MIN_SEARCH_LENGTH = 1;
import * as _ from 'lodash';
import { DeviceService } from '../../../services/device/device.service';
import { IndHeaderLeftDataModel } from "../../../models/indHeaderLeftDataModel";
import { FooterService } from '@omni/services/footer/footer.service';

@Component({
  selector: 'select-list',
  templateUrl: 'select-list.html',
  styleUrls: ['select-list.scss']
})
export class SelectListComponent implements OnInit {

  @Input() viewData: SelectListDetail;
  public searchInput: string;
  public listData: Array<SelectListData> = [];
  public selectedItems: Array<SelectListData> = [];
  public isRightPaneNavActive = false;
  public searchActive = false;
  private tempSelectedItems: Array<SelectListData>;
  public readOnly = false;
  public competitorProduct: SelectListData = { id: 'indskr_competitorproduct', title: 'Competitor Product', isSelected: false };
  public showCompetitorProduct = false;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public subList: Array<SelectListData> = [];
  public tempMessage:string;

  constructor(
    private readonly params: NavParams,
    private readonly modalCtrl: ModalController,
    private readonly uiService: UIService,
    private readonly navService: NavigationService,
    public device: DeviceService,
    public translate: TranslateService,
    public footerService: FooterService
  ) {
    this.initSelectListHeaderLeft();
  }

  ngOnInit() {

    if (this.viewData.isReadOnly) {
      this.readOnly = this.viewData.isReadOnly;
    }
    this.listData = this.viewData.data;

    if (this.viewData.addCompetitorProduct) {
      this.showCompetitorProduct = true;
      if (this.viewData.isCompetitorProductSelected) {
        this.competitorProduct.isSelected = true;
      }
      this.listData.push(this.competitorProduct);
    }

    if(this.viewData.needcustomMessage){
      this.tempMessage = this.viewData.confirmationAlertData.message;
    }


    this.listData.forEach((item: SelectListData) => {
      if (item.isSelected) {
        this.selectedItems.push(item);
      }
    });
    if (!_.isEmpty(this.viewData.subData)) {
      this.subList = this.viewData.subData;
      this.subList.forEach((item: SelectListData) => {
        if (item.isSelected) {
          this.selectedItems.push(item);
        }
      });
      //Sort selected items by title
      this.selectedItems = this.selectedItems.sort((a, b) => {
        let nameA: string = a['title'], nameB: string = b['title'];
        nameA = nameA.toLowerCase();
        nameB = nameB.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA >= nameB)
          return 1;
      })
    }
    this.tempSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
    this.initSelectListHeaderLeft();
  }


  public searchText(ev): void {
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= MIN_SEARCH_LENGTH) {
      if (_.isEmpty(this.subList)) {
        this.listData = this.viewData.data.filter(data => {
          return data.title.trim().toLowerCase().includes(val.trim().toLowerCase());
        });
      } else {
        let data: SelectListData[] = _.cloneDeep(this.viewData.data);
        data.push(...this.subList);
        this.listData = data.filter(data => {
          return data.title.trim().toLowerCase().includes(val.trim().toLowerCase());
        });
      }
      this.searchActive = true;
    } else {
      this.listData = this.viewData.data;
      this.searchActive = false;
    }
  }

  public deselectItem(item: SelectListData): void {
    if (!item.isSelected) return;
    item.isSelected = false;
    const index = this.selectedItems.findIndex(data => data.id === item.id);
    if (index >= 0) {
      const listItem: SelectListData = this.listData.find(data => data.id === this.selectedItems[index].id);
      if (listItem) {
        listItem.isSelected = false;
      }
      const viewDatalistItem: SelectListData = this.viewData.data.find(data => data.id === item.id);
      if (viewDatalistItem) {
        viewDatalistItem.isSelected = false;
      }
      if (!_.isEmpty(this.subList)) {
        const subListItem = this.subList.find(data => data.id === this.selectedItems[index].id);
        if (subListItem) {
          subListItem.isSelected = false;
        }
      }
      this.selectedItems.splice(index, 1);
    }
    this.initSelectListHeaderLeft();
  }

  public selectItem(item: SelectListData): void {
    if (item.isSelected) return;
    if (!this.viewData.isMultipleSelectionEnabled && this.selectedItems.length > 0) {
      this.selectedItems = [];
      this.viewData.data.forEach(data => {
        data.isSelected = false;
      });
    } else {
      const listItem: SelectListData = this.listData.find(data => data.id === item.id);
      if (listItem) {
        listItem.isSelected = true;
      }
      const viewDatalistItem: SelectListData = this.viewData.data.find(data => data.id === item.id);
      if (viewDatalistItem) {
        viewDatalistItem.isSelected = true;
      }
      if (!_.isEmpty(this.subList)) {
        const subListItem = this.subList.find(data => data.id === item.id);
        if (subListItem) {
          subListItem.isSelected = true;
        }
      }
    }
    item.isSelected = true;
    this.selectedItems.push(item);
    this.initSelectListHeaderLeft();
  }
  public async goBack(flag: boolean) {
    let data;
    if (flag) {
      if (this.viewData && this.viewData.confirmationCheckRequired) {
        if(this.viewData.needcustomMessage){
          this.viewData.confirmationAlertData.message = _.clone(this.tempMessage).replace('{{customText}}', this.selectedItems[0].title)
        }
        await this.uiService.displayAlertConfirmation(this.viewData.confirmationAlertData).then(info => {
          if (info) {
            data = { selectedItems: this.selectedItems, isDone: true };
            this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
          }
        }).catch(error => {
          // Cancel button Action
        });
      } else {
        data = { selectedItems: this.selectedItems, isDone: true };
        this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
      }
    }
    else {
      this.revertChanges();
      data = { selectedItems: this.tempSelectedItems, isDone: false };
      this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
    }
    if (this.viewData && this.viewData.title && this.viewData.title === 'Measures') {
      console.log('embedded: measures selected time', new Date(), new Date().getTime());
      if (flag) this.uiService.displayLoader();
    }
  }

  get noChanges(): boolean {
    return _.isEqual(this.selectedItems, this.tempSelectedItems);
  }

  private revertChanges() {
    if (this.tempSelectedItems.length > 0) {
      for (let i = 0; i < this.listData.length; i++) {
        const listItem = this.listData[i];
        listItem.isSelected = _.some(this.tempSelectedItems, { 'id': listItem.id, 'isSelected': true });
      }
    } else {
      for (let i = 0; i < this.listData.length; i++) {
        const listItem = this.listData[i];
        listItem.isSelected = false;
      }
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack(false);
        break;
      case 'done':
        this.goBack(true);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private initSelectListHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "cancel",
      cssClass: 'seventyPercentWidth',
      imgSrc: 'assets/imgs/header_cancel.svg',
      isDisabled: false,
      align: "left",
    },
    );
    if (!this.readOnly) {
      buttons.push({
        id: "done",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: !this.readOnly ? this.noChanges : true,
        align: "right",
      });
    }
    this.indHeaderLeftModel = {
      id: 'select-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.viewData ? this.viewData.title : '',
      mode: false,
      customHeaderProps: { hasCancel: true },
      controls: buttons,
      isReadOnly: this.readOnly
    };
  }
}
