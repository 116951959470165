import { ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
import { debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import { TransferType } from './../../enums/allocation/allocation.enum';
import { IAllocationShipmentAndTransferBase, IAllocationShipmentBase } from './../../interfaces/allocation/allocation-shipment.interface';
import { IAllocationAdjustment } from './../../interfaces/allocation/allocation-adjustment.interface';
import { IAllocationTransfer } from './../../interfaces/allocation/allocation-transfer.interface';
import { Component, NgZone, OnInit } from '@angular/core';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { Subscription } from 'rxjs/internal/Subscription';
import { UIService } from '../../services/ui/ui.service';
import { MainCardViewDataModel } from '../../models/MainCardViewDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '../../models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from '../../models/indSectionHeaderDataModel';
import _ from 'lodash';
import { AgendaPlanListService, PlanTabActivityType } from '../../services/agenda-plan-list/agenda-plan-list.service';
import { Activity } from '@omni/classes/activity/activity.class';
import { ActivityType } from '../../classes/activity/activity.class';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { ActivitiesDetailsPaneComponent } from '../activity/activities-details-pane/activities-details-pane';
import { ActivityService } from '../../services/activity/activity.service';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '@omni/classes/activity/follow-up-action.activity.class';
import { TimeOffActivity } from '@omni/classes/activity/timeoff.class';
import format from 'date-fns/format';
import { DateTimeFormatsService } from '../../services/date-time-formats/date-time-formats.service';
import { FollowUpActivityDataService } from '../../data-services/follow-up-activity/follow-up-activity.data.service';
import { EventsService } from '@omni/services/events/events.service';
import { ScientificPlanFollowUpTask } from '../activity/scientific-activity/scientific-plan-task/scientific-plan-task';
import { FollowUpActionDetailComponent } from '../activity/follow-up-action-detail/follow-up-action-detail';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { PopoverController } from '@ionic/angular';
import { PendingActionType, unsubscribeSubscriptionArray } from '@omni/utility/common.utility';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { MultiSelectPopover } from '../multi-select-popover/multi-select-popover';
import { isWithinRange, isFuture } from 'date-fns';
import { DeltaService } from '@omni/data-services/delta/delta.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { AllocationShipmentService } from '../../services/sample/allocation-shipment.service';
import { DatePipe } from '@angular/common';
import { AllocationShipmentDetailsComponent } from '../sample/allocation-shipment-details/allocation-shipment-details';
import { DeviceService } from '../../services/device/device.service';
import { IAllocationShipment } from '../../interfaces/allocation/allocation-shipment.interface';
import { AllocationFeatureService } from '../../services/sample/allocation.feature.service';
import { combineLatest } from 'rxjs';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { EmailActivity, EmailViewType } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { EmailActivityDetailComponent } from '../activity/email-activity-detail/email-activity-detail';
import { SurgeryOrderDetailsComponent } from '../surgery-order/surgery-order-details/surgery-order-details';
import { PhoneCallDetailsComponent } from '../phonecall/phone-call-details/phone-call-details';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { Utility } from "../../utility/util";
import { SampleActivity } from '@omni/classes/activity/sample.activity.class';
import { NewSampleActivityComponent } from '../activity/new-sample-activity/new-sample-activity';
import { TimeOffDetailsComponent } from '../time-off/time-off-details/time-off-details';
import { CaseManagementDetailComponent } from '../case-management/case-management-detail/case-management-detail';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { TimeOffService } from '@omni/services/time-off/time-off.service';
import { OrderDetailsComponent } from '../order-management/order-details/order-details';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { CoachingActivity } from '@omni/classes/coaching/activity.class';
import { Report } from '@omni/classes/coaching/report.class';
import { CoachingReportService, COACHING_VIEW_TYPE } from '@omni/services/coaching/coaching.report.service';
import { CoachingDetailsComponent } from '../coaching/coaching-details/coaching-details';
import { MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

const NumberOfSkeletonCardsToDisplay = 5;
type PendingActions = FollowUpActivity | IAllocationShipment | IAllocationTransfer | IAllocationAdjustment | OrderActivity | Report;
@Component({
  selector: 'agenda-plan-list',
  templateUrl: './agenda-plan-list.component.html',
  styleUrls: ['./agenda-plan-list.component.scss'],
})

export class AgendaPlanListComponent implements OnInit {

  private itemExpandHeight: number = 25;
  tabs: IndTabsDataModel[] = [];
  private subs: Subscription[] = [];
  allPendingActions: Array<PendingActions> = [];

  allPendingActionCards: Array<MainCardViewDataModel> = new Array<MainCardViewDataModel>();

  visiblePendingActionscards: Array<MainCardViewDataModel> = new Array<MainCardViewDataModel>();
  // Pinned Items Array
  allPinnedItems: Array<MainCardViewDataModel> = Array<MainCardViewDataModel>();

  incompleteActivitiesSectionHeader: IndSectionHeaderViewDataModel;
  pendingActionsSectionHeader: IndSectionHeaderViewDataModel;
  pinnedItemsSectionheader: IndSectionHeaderViewDataModel;

  incompleteActivities: Array<Activity> = new Array<Activity>();
  filteredIncompleteActivities: Array<Activity> = new Array<Activity>();

  filteredPinnedItems: Array<MainCardViewDataModel> = new Array<MainCardViewDataModel>(); // Main Array - Pinned Items
  filteredPendingActioncards: Array<MainCardViewDataModel> = new Array<MainCardViewDataModel>(); // Main Array - Pending Actions
  visibleIncompleteActivities: Array<Activity> = new Array<Activity>(); // Main Array - Incomplete Activities

  private visibleCount: number = 0;
  public isSearched: boolean = false;
  private searchText: string;
  // Pending Actions Related items
  pendingFollowups: Array<FollowUpActivity> = [];// All follow up actions Pending today and onwards.
  pendingTimeOffs: Array<TimeOffActivity> = [];
  salesOrders: OrderActivity[] = [];
  pendingShipments: Array<IAllocationShipment | IAllocationTransfer | IAllocationAdjustment> = [];
  multiSelectFilterPopoverData: { text: string; value: string; items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  public incompleteActivitiesFilter: ActivityType;
  public pendingActionFilter: PendingActionType;
  public listFiltered: boolean = false;
  private appliedFilterCount: number = 0;

  isPendingActionsExpanded = true;
  isIncompleteActivitiesExpanded = true;
  isPinnedItemsSectionExpanded = true;
  private subscriptions: Subscription[] = [];
  private lastCurrentMasterPage: PageName = null;

  type = PlanTabActivityType;
  public numberOfSkeletonCardsToDisplay = NumberOfSkeletonCardsToDisplay;
  private translatedCustomerInquiryText: string = '';
  private translatedIncompleteCustomerInquiryText: string = '';
  private translatedNoIncompleteCustomerInquiryText: string = '';

  coachingActivities: Report[] = [];
  coachingSubscription: Subscription;


  constructor(public uiService: UIService,
    public translate: TranslateService,
    public todoService: AgendaPlanListService,
    public navService: NavigationService,
    public activityService: ActivityService,
    private caseManagementService: CaseManagementService,
    public timeOffService: TimeOffService,
    private dateTimeFormatService: DateTimeFormatsService,
    private followUpDataService: FollowUpActivityDataService,
    public events: EventsService,
    public popoverCtrl: PopoverController,
    private authService: AuthenticationService,
    public deltaService: DeltaService,
    private activityDataService: ActivityDataService,
    private shipmentService: AllocationShipmentService,
    private datePipe: DatePipe,
    public device: DeviceService,
    private allocFeatureService: AllocationFeatureService,
    private agendaFooterService: AgendaFooterService,
    private emailService: EmailService,
    private utilityService: GlobalUtilityService,
    private _ngZone: NgZone,
    public coachingReportService: CoachingReportService,
    public assistantService: MyAssistantService,
    public faService: FeatureActionsService,
  ) {
  }

  ngOnInit() {
    this.todoService.resetLastOpenActivity();
    this.tabs = this.uiService.getAgendaTabs();
    this.activityService.selectedActivity = undefined;
    this.allocFeatureService.selectedShipment = undefined;
    this.renderCustomerText()
    // There is a lag and image is shown, which is why I have uncommented this, It might be rmoved after analysing how it impacts the loading, though Data is refreshed ultimately and this would be a duplicate call, still for OMNI-23132: On navigating from act tab to plan tab, default image appears for a second, It is being uncommented.
    this.initPendingActionsHeader();
    this.reloadValues();
    this.pendingFollowups = this.todoService.getPendingFollowups();


    this.subscribeEvents();
  }

  ionViewWillEnter() {
    if (this.device.isMobileDevicePortrait) {
      this.unhighlightSelectedPendingCards();
      this.unhighlightSelectedPinnedItem();
    }
  }


  private reloadValues() {
    this.refreshIncompleteActivitiesData();
    this.refreshPendingActionsData();
    this.initPinnedItemsData();
    this.initPinnedItemsSectionHeader();
  }

  private validateShouldResetRightSideView(isFromParentActivity: any): boolean {
    // returning true means, Reset, this is default behaviour.
    if (isFromParentActivity) {
      // if isFromParentActivity is passed as an event argument
      if (isFromParentActivity === true) {
        return false;
      } else {
        return true;
      }
    }
    // if isFromParentActivity it self is not available.
    return true;
  }

  private handleSurgeryOrderDeletedSubscription() {
    this.events.subscribe('surgeryOrderActivityDeleted', (activity, isFromParentActivity) => {
      this.handleIncompleteActivitiesTransactions(activity, this.validateShouldResetRightSideView(isFromParentActivity));
    });
    this.events.subscribe('procedureTrackerActivityDeleted', (activity, isFromParentActivity) => {
      this.handleIncompleteActivitiesTransactions(activity, this.validateShouldResetRightSideView(isFromParentActivity));
    });

    this.events.subscribe('ProcedureTrackerActivityCompleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });
    this.events.subscribe('surgeryOrderActivityCompleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('surgeryOrderActivityTimeIsUpdated', (activity) => {
      this.replaceUpdatedActivityOnChange(activity);
    });
  }

  private handleMessageDeletedSubscription() {
    this.events.subscribe('messageActivityDeleted', (activity, isFromParentActivity) => {
      this.handleIncompleteActivitiesTransactions(activity, this.validateShouldResetRightSideView(isFromParentActivity));
    });

    this.events.subscribe('messageActivitySent', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('messageActivityTimeIsUpdated', (activity) => {
      this.replaceUpdatedActivityOnChange(activity);
    });
  }

  private handlePhoneCallDeletedSubscription() {
    this.events.subscribe('phoneCallActivityDeleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('phoneCallActivityCompleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('phoneCallActivityIsUpdated', (activity) => {
      this.replaceUpdatedActivityOnChange(activity);
    });
  }

  private handleCustomerInquiryChangesSubscription() {
    this.events.subscribe('customerInquiryActivityDeleted', (activity, isFromParentActivity) => {
      this.handleIncompleteActivitiesTransactions(activity, this.validateShouldResetRightSideView(isFromParentActivity));
    });

    this.events.subscribe('customerInquiryActivitySubmitted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('customerInquiryActivityIsUpdated', (activity) => {
      this.replaceUpdatedActivityOnChange(activity);
      // if (this.uiService.toolsActivityActive && activity) {
      //   this.openActivityDetails(activity)
      // }
    });

    this.events.subscribe('customerInquiryActivityOpen', (activity) => {
      if (activity) {
        this.openActivityDetails(activity)
      }
    });
  }

  private handleAllocationOrderChangesSubscription() {
    this.events.subscribe('allocationOrderActivityDeleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('allocationOrderActivityCompleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('allocationOrderActivityOpen', (activity) => {
      if (activity) {
        this.openActivityDetails(activity)
      }
    });

    this.events.subscribe('allocationOrderActivityIsUpdated', (activity) => {
      this.replaceUpdatedActivityOnChange(activity);
    });
  }

  private handleTimeOffChangesSubscription() {
    this.events.subscribe('timeOffActivityDeleted', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('timeOffActivityReopen', (activity) => {
      this.handleActivityReopened(activity);
    });

    // isSubmitted: timeoff is submitted.
    this.events.subscribe('timeOffActivityIsUpdated', (activity, isSubmitted) => {
      if (isSubmitted && isSubmitted === true) {
        this.handleIncompleteActivitiesTransactions(activity);
      } else {
        this.replaceUpdatedActivityOnChange(activity);
      }
      // if (isSubmitted === false && this.uiService.toolsActivityActive && activity) {
      //   this.openActivityDetails(activity)
      // }
    });
  }

  private handleNewActivityCreated() {
    this.events.subscribe('newActivityIsCreatedUpdatePlanTab', (activity) => {
      this.refreshIncompleteActivitiesData();
      this.refreshList();
    });
  }

  private subscribeEvents() {

    // Meeting Related Subscriptions
    this.events.subscribe('activityDeleted', (activity) => { this.handleIncompleteActivitiesTransactions(activity); });

    this.events.observe('meetingCompleted').subscribe((activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    this.events.subscribe('meetingReopened', (activity) => { this.handleActivityReopened(activity); });

    this.events.subscribe('aciviiyCreatedRefreshTODO', (activity) => {
      this.refreshAgendaPlanList(activity);

      if (activity?.type === ActivityType.FollowUp) {
        let todayStart = new Date(new Date().setHours(0, 0, 0, 0));
        if (activity?.scheduledStart >= todayStart) {
          this.refreshPendingActionsData();
        }
      }

    });
    this.translate.onLangChange.subscribe(data =>{
      this.tabs = this.uiService.getAgendaTabs();
    });

    // New Activity Created From Meeting Structure or Phone Call Activities
    this.handleNewActivityCreated();

    // Customer Inquiry Related Subscriptions
    this.handleCustomerInquiryChangesSubscription();

    // Allocation Order Related Subscriptions
    this.handleAllocationOrderChangesSubscription();

    // Time Off Related Subscriptions
    this.handleTimeOffChangesSubscription();

    // Surgery Order Related Subscriptions
    this.handleSurgeryOrderDeletedSubscription();

    // Message Related Subscriptions
    this.handleMessageDeletedSubscription();

    // Phone Call Related Subscriptions
    this.handlePhoneCallDeletedSubscription();

    // Follow-up Related Subscriptions
    this.handleFollowupActionDeletedSubscription();

    //sales order related subscriptions
    this.events.subscribe('updateOrdersList', (params) => {
      if (params.mode && params.mode === 'delete') {
        this.handleSalesOrderScrap(params.activity);
      } else {
        this.handleSalesOrderComplete(params.activity)
      }
    });

    this.events.subscribe('followupTask:assignmentHistoryUpdated', (activity) => {
      this.handleIncompleteActivitiesTransactions(activity);
    });

    // this is especially for folllow ups when due date is updated to teh future date, thsi has to be removed from incomplete activities and put that to pending actoions.
    this.events.subscribe('activityUpdated', (activity) => {
      this.updateFollowupPendingAction(activity);
    });

    // When Follow up is completed, it has to be removed from the Follow up action
    this.handleFollowupCompletedSubscription();

    // Allocation Order Related Subscriptions
    this.handleShipmentAcknowledgmentSubscription();

    this.handleToolsBackClickedSubscription();

    this.events.subscribe('closeShipmentDetail', () => {
      this.unhighlightSelectedPendingCards();
      this.unhighlightSelectedPinnedItem();
    })

    // Page navigation observer
    this.subscriptions.push(
      this.navService.currentPageStack$
        .subscribe(
          ((pageStacks) => {
            const curMasterPageName: PageName = this.navService.getCurrentMasterPageName();
            if (
              Array.isArray(pageStacks) && pageStacks.length > 0
              && curMasterPageName === PageName.ActivitiesPageComponent
              && this.lastCurrentMasterPage !== curMasterPageName
            ) {
              this.lastCurrentMasterPage = curMasterPageName;
              if (this.deltaService.masterSyncState.state !== 'Sync') {
                this.todoService.triggerPendingActionDataRefresh$.next(true);
              }
            } else {
              this.lastCurrentMasterPage = curMasterPageName;
            }
          })
        )
    );

    // Master sync state observer
    this.subscriptions.push(
      this.deltaService.masterSyncState.stateObservable
        .subscribe(
          (masterSyncState => {
            if (masterSyncState === 'Success' || masterSyncState === 'Fail') {
              this.renderCustomerText();
              this.clearFilters();
              this.todoService.triggerPendingActionDataRefresh$.next(true);
            }
          })
        )
    );

    // Data refresh observer
    this.subscriptions.push(
      combineLatest([
        this.todoService.triggerPendingActionDataRefresh$.asObservable()
          .pipe(
            distinctUntilChanged(),
          ),
        this.deltaService.masterSyncState.stateObservable,
        this.allocFeatureService.isPendingShipmentAndTransferDataReadyObs
          .pipe(
            distinctUntilChanged(),
          ),
      ])
        .pipe(
          debounceTime(0),
        )
        .subscribe(([trigger, mastSyncState, isPendingShipmentAndTransferDataReady]) => {
          if (trigger && mastSyncState !== 'Sync') {
            // Ready to use shipments data
            this.reloadValues();
            this.todoService.triggerPendingActionDataRefresh$.next(isPendingShipmentAndTransferDataReady);
          }
        })
    );

    this.handlePendingCoahingUpdatedSubscription();

    this.coachingSubscription = this.coachingReportService.myReportSubject.subscribe((value) => {
      if (value.length > 0) {
        console.log('this is coaching report', value);
        const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
        this.coachingActivities = value.filter((item) => item.statuscode === 548910002 || item.statuscode === 548910001 && new Date(item.indskr_periodstartdate) >= todayStart);
      }
    });

    this.todoService.triggerPendingActionDataRefresh$.next(true);
  }

  // ----- coaching ------- //

  handlePendingCoahingUpdatedSubscription() {
    this.events.subscribe('coaching-updated', (activity: Report) => {
      let isPinned = this.isPinned(activity?._id);
      if (isPinned) {
        this.removePinnedItemFromDBAdnArrays(activity._id);
      }

      let coachingActivitiesIndex = this.coachingActivities.findIndex(item => item._id === activity._id);
      if (coachingActivitiesIndex > -1) {
        this.coachingActivities.splice(coachingActivitiesIndex, 1);
      }

      let foundIndex = this.allPendingActionCards.findIndex(card => card.id === activity._id);
      if (foundIndex > -1) {
        this.allPendingActionCards.splice(foundIndex, 1);
        this.filterPendingActions();
        this.coachingReportService.selectedReport.next(null);
        this.coachingReportService.selectedCoachingPlanTab = null;
        this.navService.setChildNavRightPaneView(false);
        this.resetRightSideView();
      }
    })
  }

  getPendingCoachingActivities() {
    this.allPendingActions = this.allPendingActions.concat(this.coachingActivities);

    const pendingCoachinMainCards = this.coachingActivities.map(item => {
      let clickhandler = (id: string, event, specificTarget) => {
        this.coachingClickHandler(item)
      }
      return this.getCoachingMainCardModel(item, clickhandler)
    })
    this.allPendingActionCards = this.allPendingActionCards.concat(pendingCoachinMainCards);
  }

  coachingClickHandler(coaching) {
    this.activityService.selected = undefined;
    this.highlighSelectedCard(coaching._id);
    this.onCoachingReportSelection(coaching);
    this.coachingReportService.activeTab = 'myCoaching';
  }

  getCoachingMainCardModel(activity: Report, clickHandler): MainCardViewDataModel {
    return {
      id: activity._id,
      fixedHeight: true,
      isExpandable: true,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.coachingReportService.selectedCoachingPlanTab && this.coachingReportService.selectedCoachingPlanTab._id == activity._id),
      primaryTextLeft: this.coachingReportService.makeStatus(activity.statuscode),
      primaryTextRight: this.translate.instant('COACHING') + ' - ' + activity.created_by,
      secondaryTextRight: this.coachingReportService.getTertiaryInfo(activity, {
        value: activity.statuscode === 548910001 ? 'indskr_dateforreview' : 'indskr_shareddate'
      }),
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-coaching-icon',
      clickHandler: clickHandler,
      type: 'coaching',
      date: activity.statuscode === 548910001 ? new Date(activity.indskr_dateforreview) : new Date(activity.indskr_shareddate)
    };
  }

  async onCoachingReportSelection(report: Report) {
    this.coachingReportService.coachingViewtype = COACHING_VIEW_TYPE.FROM_TOOL;
    if ((report.statuscode === 548910001 || report.statuscode === 548910002 || report.statuscode === 548910003) && !this.coachingReportService.isOffline(report)) {
      await this.coachingReportService.checkCoachingOnline(report);
    }

    this.coachingReportService.selectedCoachingPlanTab = report;
    this.coachingReportService.selectedReport.next(report);
    this.uiService.showNewActivity = false;
    this.uiService.showRightPane = true;
    this.uiService.prevView = this.uiService.activeView; // need to check in mobile
    this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    this.navService.setChildNavRoot(CoachingDetailsComponent, PageName.CoachingDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView' });
  }
  // ----- coaching ------- //

  private handleFollowupActionDeletedSubscription() {
    this.events.subscribe('followupActionDeleted', (activity) => {
      if (activity) {
        let todayStart = new Date(new Date().setHours(0, 0, 0, 0));
        if (todayStart > activity.scheduledStart) { // Incomplete Activity
          this.handleIncompleteActivitiesTransactions(activity);
        } else {
          let index = this.allPendingActionCards.findIndex(card => card.id === activity.ID);

          if (index > -1) {
            this.allPendingActionCards.splice(index, 1);
          }

          index = this.filteredPendingActioncards.findIndex(card => card.id === activity.ID);
          if (index > -1) {
            this.filteredPendingActioncards.splice(index, 1);
          }

          let isPinned = this.isPinned(activity.ID);
          if (isPinned) {
            // this.removePinnedItemFromDBAdnArrays(activity.ID);
            this.initPinnedItemsData();
            this.initPinnedItemsSectionHeader();
          }
        }
      } else {
        this.todoService.triggerPendingActionDataRefresh$.next(true);
      }
    });
  }

  private handleFollowupCompletedSubscription() {
    this.events.subscribe('followupactioncompleted', (activity) => {

      let todayStart = new Date(new Date().setHours(0, 0, 0, 0));


      if (todayStart > activity.scheduledStart) {

        //Incomplete Activities
        // Here explicitely We do not need to handle the unpinning as it's already handled inside the Meeting transation method
        this.handleIncompleteActivitiesTransactions(activity);
      } else {
        // pending Actions

        let isPinned = this.isPinned(activity?.ID);
        if (isPinned) {
          // remove from the Pinned list
          this.removePinnedItemFromDBAdnArrays(activity.ID);
        }
        let foundIndex = this.allPendingActionCards.findIndex(card => card.id === activity.ID);
        if (foundIndex > -1) { //Found the index of the activity being completed.
          this.allPendingActionCards.splice(foundIndex, 1);
          this.filterPendingActions();
        }
        if (!this.uiService.toolsActivityActive) {
          this.resetRightSideView();
        }
      }

      this.initPinnedItemsSectionHeader();
    });
  }

  private handleSalesOrderScrap(activity) {

    if (activity) {
      const isIncompleteSalesOrder = this.incompleteActivities.findIndex(({ ID }) => ID === activity.ID);

      if (isIncompleteSalesOrder >= 0) { // Incomplete Activity
        this.handleIncompleteActivitiesTransactions(activity);
      } else {
        let index = this.allPendingActionCards.findIndex(card => card.id === activity.ID);

        if (index > -1) {
          this.allPendingActionCards.splice(index, 1);
        }

        index = this.filteredPendingActioncards.findIndex(card => card.id === activity.ID);
        if (index > -1) {
          this.filteredPendingActioncards.splice(index, 1);
        }

        let isPinned = this.isPinned(activity.ID);
        if (isPinned) {
          // this.removePinnedItemFromDBAdnArrays(activity.ID);
          this.initPinnedItemsData();
          this.initPinnedItemsSectionHeader();
        }
      }
    } else {
      this.todoService.triggerPendingActionDataRefresh$.next(true);
    }
  }

  private handleSalesOrderComplete(activity) {
    // let todayStart = new Date(new Date().setHours(0, 0, 0, 0));

    const isIncompleteSalesOrder = this.incompleteActivities.findIndex(({ ID }) => ID === activity.ID);

    if (isIncompleteSalesOrder >= 0) {

      //Incomplete Activities
      // Here explicitely We do not need to handle the unpinning as it's already handled inside the Meeting transation method
      this.handleIncompleteActivitiesTransactions(activity);
    } else {
      // pending Actions

      let isPinned = this.isPinned(activity?.ID);
      if (isPinned) {
        // remove from the Pinned list
        this.initPinnedItemsData();
        this.removePinnedItemFromDBAdnArrays(activity.ID);

      }
      let foundIndex = this.allPendingActionCards.findIndex(card => card.id === activity.ID);
      if (foundIndex > -1) { //Found the index of the activity being completed.
        this.allPendingActionCards.splice(foundIndex, 1);
        this.filterPendingActions();
      }
      if (!this.uiService.toolsActivityActive) {
        this.resetRightSideView();
      }
    }

    this.initPinnedItemsSectionHeader();
  }

  private handleShipmentAcknowledgmentSubscription() {
    this.events.observe('shipmentAcknowledged')
      .subscribe((shipment: IAllocationShipmentBase) => {
        if (shipment.indskr_transfertype !== TransferType.AllocationAdjustment) {

          // remove from all pinned Items
          // Remove from filtered Pinned Items
          let index = this.allPinnedItems.findIndex(card => card.id === shipment.indskr_usershipmentallocation_v2id);
          // This is a filtered item need to remove from the Saved pinned Section,
          // Need to remove from the filtered pinned Section.
          // Need to remove from the Pinned Items Saved in DB. The Method called below takes care of removing from the DB and corresponding Arrays.
          if (index > -1) {
            this.removePinnedItemFromDBAdnArrays(shipment.indskr_usershipmentallocation_v2id);

            this.initPinnedItemsSectionHeader();
          }

          // this.setSelectedShipmentAsUndefined();

          // Since it is not pinned Item we have to filter out from here
          index = this.allPendingActionCards.findIndex(card => card.id === shipment.indskr_usershipmentallocation_v2id);
          if (index > -1) {
            this.allPendingActionCards.splice(index, 1);
            // Unset selected shipment if ack was done on plan list view
            this.setSelectedShipmentAsUndefined();
          }

          this.filterPendingActions();
          this.uiService.showRightPane = false;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
      });
  }

  private handleToolsBackClickedSubscription() {
    this.events.subscribe("tools-back-clicked", () => {
      if (this.agendaFooterService.planTabSegment === 'planTabTodo') {
        if (this.allocFeatureService.selectedShipment) {
          if (!this.device.isMobileDevicePortrait) {
            this.openAllocationShipment(this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id);
          } else {
            this.unhighlightSelectedPendingCards();
            this.unhighlightSelectedPinnedItem();
          }
        } else if (this.activityService.selectedActivity) {
          if (!this.device.isMobileDevicePortrait) {
            if (this.todoService.lastOpenActivityType === ActivityType.CaseIntake) {
              this.events.publish('customerInquiryActivityOpen', this.activityService.selectedActivity);
            } else if (this.todoService.lastOpenActivityType === ActivityType.TimeOff) {
              this.openActivityDetails(this.activityService.selectedActivity);
            }
            if (this.emailService.viewType !== EmailViewType.EMAIL_FROM_MEETING_PRESENTATION && this.uiService.activeView !== 'Meeting'){
              this.activityService.selectedActivity = undefined;
            }
          }
        } else if (this.coachingReportService.selectedCoachingPlanTab) {
          this.resetRightSideView();
          this.onCoachingReportSelection(this.coachingReportService.selectedCoachingPlanTab);
        }

      }
    });
  }

  private setSelectedShipmentAsUndefined() {
    if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
      this.allocFeatureService.selectedShipment = undefined;
      this.allocFeatureService.selectedShipmentAtHome = undefined;
    }
  }

  private updateFollowupPendingAction(activity: any) {

    let clickhandler = (id: string, event, specificTarget) => {

      this.followUpPendingACtionClickhandler(id);
    };

    let followupactionCard = this.getActivityMainCardModel(activity, clickhandler);

    let todayStart = new Date(new Date().setHours(0, 0, 0, 0));

    // this will remove if pinned, conidtion is added inside teh method
    this.updatePinnedFollowupAction(activity, followupactionCard);

    if (activity.type === ActivityType.FollowUp && todayStart <= activity.scheduledStart) { //// Pending Action is to be updated

      let index = this.filteredPendingActioncards.findIndex(card => card.id === activity.ID);

      if (index > -1) {

        this.filteredPendingActioncards[index] = followupactionCard;

        // replace in the all cards so that in next filter it is not reverted.
        index = this.allPendingActionCards.findIndex(card => card.id === activity.ID);
        if (index > -1) this.allPendingActionCards[index] = followupactionCard;

        this.filteredPendingActioncards = this.sortPendingActionItems(this.filteredPendingActioncards);
      }
    }
  }

  private updatePinnedFollowupAction(activity: any, followupactionCard: MainCardViewDataModel) {
    let isPinned = this.isPinned(activity?.ID);
    if (isPinned) {
      let pinnedIndex = this.allPinnedItems.findIndex(card => card.id === activity.ID);
      if (pinnedIndex > -1)
        this.allPinnedItems[pinnedIndex] = followupactionCard;

      pinnedIndex = this.filteredPinnedItems.findIndex(card => card.id === activity.ID);
      if (pinnedIndex > -1)
        this.filteredPinnedItems[pinnedIndex] = followupactionCard;

      this.sortPinnedItems(this.filteredPinnedItems);
    }
  }

  private replaceUpdatedActivityOnChange(activity: any) {
    const index = this.incompleteActivities.findIndex(o => o.ID === activity?.ID);
    if (index > -1) {
      this.incompleteActivities[index] = activity;
    }
    this.refreshAgendaPlanList(activity);
  }

  private refreshAgendaPlanList(activity: any) {

    let meetingDateAdjustedWithGracePeriod = new Date(new Date((new Date().setDate(new Date().getDate() - this.authService.user.offlineDataDuration))).setHours(0, 0, 0, 0));

    let todayStart = new Date(new Date().setHours(0, 0, 0, 0));

    let isPinned = this.isPinned(activity?.ID)
    if (isPinned) {
      this.refreshPinnedIncompleteActivitiesActivity(activity);
    }

    // Is within Raneg does covers the equal dates so having anither condition to make sure we are checking on the date
    if (isWithinRange(activity?.scheduledStart, meetingDateAdjustedWithGracePeriod, todayStart) && todayStart > activity?.scheduledStart) {
      //fit for incomplete activities'
      let index = this.incompleteActivities.findIndex(o => o.ID === activity?.ID);
      if (index > -1) {
        this.incompleteActivities[index] = activity;
      } else {
        this.incompleteActivities.push(activity);
      }
    } else {
      if (isPinned && ((activity instanceof AppointmentActivity)
        || (activity instanceof EmailActivity)
        || (activity instanceof PhoneActivity)
        || (activity instanceof SurgeryOrderActivity)
        || (activity instanceof ProcedureTrackerActivity)
        || (activity instanceof TimeOffActivity)
        || (activity instanceof SampleActivity)
        || (activity instanceof CaseActivity)
      )) {
        this.todoService.unpinItem(activity.ID);
      }
      let index = this.incompleteActivities.findIndex(o => o.ID === activity?.ID);
      if (index > -1) {
        // this.incompleteActivities.splice(index, 1);
        // this.uiService.showRightPane = false;
        // this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      }
    }
    this.filterIncompleteActivities();
  }

  private refreshPinnedIncompleteActivitiesActivity(activity: any) {
    let index = this.allPinnedItems.findIndex(card => card.id === activity.ID);
    let card: MainCardViewDataModel;
    if (index > -1) {

      let clickhandler = (id: string, event, specificTarget) => {
        let foundActivity = this.incompleteActivities.find(activity => activity.ID === id);
        if (foundActivity) {
          this.openActivityDetails(foundActivity);
          this.unhighlightSelectedPinnedItem();
          this.unhighlightSelectedPendingCards();
          this.highlightPinnedItem(id);
        }
      };
      card = this.getActivityMainCardModel(activity, clickhandler);
      this.allPinnedItems[index] = card;

      index = this.filteredPinnedItems.findIndex(card => card.id === activity.ID);
      if (index > -1) this.filteredPinnedItems[index] = card;
    }
  }

  private refreshIncompleteActivitiesData() {
    this.incompleteActivities = this.todoService.filterIncompleteActivities();
    this.filteredIncompleteActivities = this.sortIncompleteActivities(this.incompleteActivities);

    this.refreshIncompleteActivitiesBasedOnPinnedItems();
    this.updateVisibleRecords();
    this.initSectionHeaders();
  }

  private refreshIncompleteActivitiesBasedOnPinnedItems() {
    let pinnedIDs = this.todoService.pinnedItems.map(item => item.id);
    let joinedPinnedIDs = pinnedIDs.join(",");
    this.filteredIncompleteActivities = this.filteredIncompleteActivities.filter(activity => joinedPinnedIDs.indexOf(activity.ID) === -1);
  }

  private refreshPendingActionsData(isPendingShipmentAndTransferDataReady = true) {

    this.allPendingActions = [];
    this.allPendingActionCards = [];
    this.filteredPendingActioncards = [];

    this.getPendingFollowupActions();
    this.getPendingSalesOrderActions();
    this.getPendingCoachingActivities();
    if (isPendingShipmentAndTransferDataReady) {
      this.getPendingShipmentsAndTransfers();
    }

    this.filteredPendingActioncards = this.sortPendingActionItems(this.allPendingActionCards)

    this.filterOutPendingActionsbasedOnpinnedItems();
    this.initPendingActionsHeader();
    if (this.allocFeatureService.selectedShipmentAtHome && !this.allocFeatureService.selectedShipment) {
      this.allocFeatureService.selectedShipment = this.allocFeatureService.selectedShipmentAtHome
      this.highlighSelectedCard(this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id)
    }
  }


  private filterOutPendingActionsbasedOnpinnedItems() {
    let pinnedIDs = this.todoService.pinnedItems.map(item => item.id);
    let joinedPinnedIDs = pinnedIDs.join(",");
    this.filteredPendingActioncards = this.filteredPendingActioncards.filter(card => joinedPinnedIDs.indexOf(card.id) === -1);
  }

  initPinnedItemsData() {
    let pinnedIDs = this.todoService.pinnedItems.map(item => item.id);
    this.allPinnedItems = _.cloneDeep(this.allPendingActionCards.filter(card => {
      let index = pinnedIDs.findIndex(id => id === card.id);
      return index > -1;
    }));

    this.allPinnedItems.forEach(pinnedCard => {
      let splittedtype = pinnedCard.type.toString().split('-')
      if (!_.isEmpty(splittedtype)) {
        let type = splittedtype[0]
        if (type) {
          type = `${type}-${PlanTabActivityType.PENDING_ACTIONS}`
        } else {
          // shipment is the only items which does not have any type so has to be hardcoded otherwise everting has the type.
          type = `shipment-${PlanTabActivityType.PENDING_ACTIONS}`
        }
        pinnedCard.type = type;
      }
    });


    let joinedPinnedIDs = pinnedIDs.join(",")
    let pinnedActivities = this.incompleteActivities.filter(activity => joinedPinnedIDs.indexOf(activity.ID) !== -1);

    pinnedActivities.forEach(activity => {

      let clickhandler = (id: string, event, specificTarget) => {
        let foundActivity = pinnedActivities.find(activity => activity.ID === id);
        if (foundActivity) {
          this.openActivityDetails(foundActivity);
          this.unhighlightSelectedPinnedItem();
          this.unhighlightSelectedPendingCards();
          this.highlightPinnedItem(id);
        }
      }

      let pinnedActivityCard = this.getActivityMainCardModel(activity, clickhandler);
      pinnedActivityCard.type = `${pinnedActivityCard.type}-${PlanTabActivityType.INCOMPLETE_ACTIVTIES}`
      this.allPinnedItems.push(pinnedActivityCard);
    })
    this.filterPinnedItems();
  }


  private getPendingFollowupActions() {
    let pendingFollowups = this.todoService.getPendingFollowups();

    this.allPendingActions = this.allPendingActions.concat(pendingFollowups);

    let pendingFollowupMaincards = pendingFollowups.map(item => {
      let clickhandler = (id: string, event, specificTarget) => {

        this.followUpPendingACtionClickhandler(id);
      }
      return this.getActivityMainCardModel(item, clickhandler)
    });

    this.allPendingActionCards = this.allPendingActionCards.concat(pendingFollowupMaincards);
  }

  private followUpPendingACtionClickhandler(id: string) {
    let foundActivity = this.allPendingActions.find((pendingActionItem) => {
      if (pendingActionItem instanceof FollowUpActivity) {
        return pendingActionItem.ID === id || pendingActionItem.offlineId === id;
      }
    });

    if (foundActivity) {
      this.openActivityDetails(foundActivity as FollowUpActivity);
    }

    this.highlighSelectedCard(id);
  }


  private getPendingSalesOrderActions() {
    let pendingSalesorders = this.todoService.getPendingSalesOrder();

    this.allPendingActions = this.allPendingActions.concat(pendingSalesorders);

    let pendingSalesOrderMaincards = pendingSalesorders.map(item => {
      let clickhandler = (id: string, event, specificTarget) => {

        this.salesOrderACtionClickhandler(id);
      }
      return this.getActivityMainCardModel(item, clickhandler)
    });

    this.allPendingActionCards = this.allPendingActionCards.concat(pendingSalesOrderMaincards);
  }

  private salesOrderACtionClickhandler(id: string) {
    let foundActivity = this.allPendingActions.find((pendingActionItem) => {
      if (pendingActionItem instanceof OrderActivity) {
        return pendingActionItem.ID === id || pendingActionItem.offlineId === id;
      }
    });

    if (foundActivity) {
      this.openActivityDetails(foundActivity as OrderActivity);
    }

    this.highlighSelectedCard(id);
  }

  private highlighSelectedCard(id: string) {
    let isPinned = (this.todoService.pinnedItems.findIndex(item => item.id === id)) >= 0;
    if (isPinned) {
      this.highlightPinnedItem(id);
    } else {
      this.highlightSelectedPendingItem(id);
    }
  }

  private highlightSelectedPendingItem(id: string) {
    this.unhighlightSelectedPendingCards();
    this.unhighlightSelectedPinnedItem();
    let index = this.filteredPendingActioncards.findIndex(card => { return card.id === id; });
    if (index > -1) {
      this.filteredPendingActioncards[index].isSelected = true;
    }
  }

  private unhighlightSelectedPendingCards() {
    let selectedCards = this.filteredPendingActioncards.filter(card => { return card.isSelected });
    if (!_.isEmpty(selectedCards)) {
      selectedCards.forEach(card => {
        let index = this.filteredPendingActioncards.findIndex(filteredCard => card.id === filteredCard.id);
        if (index > -1) {
          this.filteredPendingActioncards[index].isSelected = false;
        }
      });
    } else {
      selectedCards = this.allPendingActionCards.filter(card => { return card.isSelected });
      selectedCards.forEach(card => {
        let index = this.allPendingActionCards.findIndex(filteredCard => card.id === filteredCard.id);
        if (index > -1) {
          this.allPendingActionCards[index].isSelected = false;
        }
      });
    }
  }

  private highlightPinnedItem(id: string) {

    this.unhighlightSelectedPinnedItem();
    this.unhighlightSelectedPendingCards();
    let index = this.filteredPinnedItems.findIndex(card => { return card.id === id; });
    if (index > -1) {
      this.filteredPinnedItems[index].isSelected = true;
    }
  }

  private unhighlightSelectedPinnedItem() {
    let selectedCards = this.filteredPinnedItems.filter(card => { return card.isSelected });
    if (!_.isEmpty(selectedCards)) {
      selectedCards.forEach(card => {
        let index = this.filteredPinnedItems.findIndex(filteredCard => card.id === filteredCard.id);
        if (index > -1) {
          this.filteredPinnedItems[index].isSelected = false;
        }
      });
    } else {
      selectedCards = this.allPinnedItems.filter(card => { return card.isSelected });
      selectedCards.forEach(card => {
        let index = this.allPinnedItems.findIndex(filteredCard => card.id === filteredCard.id);
        if (index > -1) {
          this.allPinnedItems[index].isSelected = false;
        }
      });
    }
  }

  private getPendingShipmentsAndTransfers() {
    if ((this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)
      || this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER))) {
      this.allPendingActions.push(...this.allocFeatureService.pendingShipments);
      const pendingShipmemntCards = this.allocFeatureService.pendingShipments.map(shipment => {
        return this.getPendingActionShipmentMainCardModel(shipment);
      });
      this.allPendingActionCards.push(...pendingShipmemntCards);
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('activityDeleted');
    this.events.unsubscribe('meetingCompleted');
    this.events.unsubscribe('activityUpdated');
    this.events.unsubscribe('followupactioncompleted');
    this.events.unsubscribe('closeShipmentDetail');
    this.events.unsubscribe('shipmentAcknowledged');
    this.events.unsubscribe('followupTask:assignmentHistoryUpdated');
    this.events.unsubscribe('meetingReopened');
    this.events.unsubscribe('aciviiyCreatedRefreshTODO');
    this.events.unsubscribe('surgeryOrderActivityDeleted');
    this.events.unsubscribe('procedureTrackerActivityDeleted');
    this.events.unsubscribe('surgeryOrderActivityCompleted');
    this.events.unsubscribe('ProcedureTrackerActivityCompleted');
    this.events.unsubscribe('surgeryOrderActivityTimeIsUpdated');
    this.events.unsubscribe('phoneCallActivityCompleted');
    this.events.unsubscribe('phoneCallActivityDeleted');
    this.events.unsubscribe('phoneCallActivityIsUpdated');
    this.events.unsubscribe('messageActivityDeleted');
    this.events.unsubscribe('messageActivitySent');
    this.events.unsubscribe('messageActivityTimeIsUpdated');
    this.events.unsubscribe('newActivityIsCreatedUpdatePlanTab');
    this.events.unsubscribe('customerInquiryActivityDeleted');
    this.events.unsubscribe('customerInquiryActivitySubmitted');
    this.events.unsubscribe('customerInquiryActivityIsUpdated');
    this.events.unsubscribe('customerInquiryActivityOpen');
    this.events.unsubscribe('allocationOrderActivityDeleted');
    this.events.unsubscribe('allocationOrderActivityCompleted');
    this.events.unsubscribe('allocationOrderActivityIsUpdated');
    this.events.unsubscribe('allocationOrderActivityOpen');
    this.events.unsubscribe('timeOffActivityDeleted');
    this.events.unsubscribe('timeOffActivityReopen');
    this.events.unsubscribe('timeOffActivityIsUpdated');
    this.events.unsubscribe('updateOrdersList')
    this.events.unsubscribe('coaching-updated');
    this.coachingSubscription?.unsubscribe();
    if (Array.isArray(this.subscriptions)) {
      unsubscribeSubscriptionArray(this.subscriptions);
    }
  }

  private initSectionHeaders() {
    this.initIncompleteActivitiesHeader();
    this.initPendingActionsHeader();
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
    this.uiService.activitiesPageTab = selectedTab;
    this.allocFeatureService.selectedShipment = undefined;
    this.allocFeatureService.selectedShipmentAtHome = undefined;
    this.coachingReportService.selectedCoachingPlanTab = undefined;
  }

  get noPlanList(): boolean {
    return (_.isEmpty(this.incompleteActivities) && _.isEmpty(this.allPendingActionCards) && _.isEmpty(this.allPinnedItems));
  }

  initPendingActionsHeader() {
    this.pendingActionsSectionHeader = {
      id: 'account-plan-past-header',
      title: `${this.getPendingActionHeaderTitle()} (${this.filteredPendingActioncards.length})`,
      controls: [{
        id: 'pending-actions',
        icon: this.isPendingActionsExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
      isOptimizeButtonWidth: true,
    }
  }

  initPinnedItemsSectionHeader() {
    this.pinnedItemsSectionheader = {
      id: 'pinned-items-header',
      title: `${this.translate.instant('PINNED')} (${this.filteredPinnedItems.length})`,
      controls: [{
        id: 'pinned-items-actions',
        icon: this.isPinnedItemsSectionExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
      isOptimizeButtonWidth: true,
    }
  }

  private getIncompleteActivitiesHeaderCount() {

    let pinnedIDs = this.todoService.pinnedItems.map(item => item.id);
    let joinedPinnedIDs = pinnedIDs.join(',');

    let filteredActivities = this.filteredIncompleteActivities.filter(incompleActivity => joinedPinnedIDs.indexOf(incompleActivity.ID) <= -1);

    return filteredActivities.length

  }

  initIncompleteActivitiesHeader() {
    this.incompleteActivitiesSectionHeader = {
      id: 'account-plan-past-header',
      title: `${this.getIncompleteActivitiesSectionHeadertitle()} (${this.getIncompleteActivitiesHeaderCount()})`,
      controls: [{
        id: 'incomplete-activities',
        icon: this.isIncompleteActivitiesExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
      isOptimizeButtonWidth: true,
    }
  }

  private getIncompleteActivitiesSectionHeadertitle(): string {
    switch (this.incompleteActivitiesFilter) {
      case ActivityType.Appointment:
        return this.translate.instant('INCOMPLETE_MEETINGS');
      case ActivityType.FollowUp:
        return this.translate.instant('INCOMPLETE_FOFOLLOW_UP_ACTIONS');
      case ActivityType.SurgeryOrder:
        return this.translate.instant('INCOMPLETE_PROCEDURE_LOGS');
      case ActivityType.PhoneCall:
        return this.translate.instant('INCOMPLETE_PHONECALLS');
      case ActivityType.Email:
        return this.translate.instant('INCOMPLETE_MESSAGES');
      case ActivityType.CaseIntake:
        return this.translatedIncompleteCustomerInquiryText;
      case ActivityType.TimeOffRequest:
        return this.translate.instant('INCOMPLETE_TIME_OFF');
      case ActivityType.Sample:
        return this.translate.instant('INCOMPLETE_ALLOCATION_ORDER');
      case ActivityType.Order:
        return this.translate.instant('INCOMPLETE_SALES_ORDERS');
      default:
        break;
    }
    return this.translate.instant('INCOMPLETE_ACTIVITIES');
  }

  private getPendingActionHeaderTitle(): string {
    switch (this.pendingActionFilter) {
      case PendingActionType.FOLLOWUP:
        return this.translate.instant('PENDING_FOLLOWUPS');
        break;
      case PendingActionType.SHIPMENT:
        return this.translate.instant('PENDING_SHIPMENTS');
        break;
      case PendingActionType.TRANSFER:
        return this.translate.instant('PENDING_TRANSFERS');
        break;
      case PendingActionType.ORDER:
        return this.translate.instant('PENDING_SALES_ORDERS');
      case PendingActionType.COACHING:
        return this.translate.instant("PENDING_COACHING");
      default:
        break;
    }
    return this.translate.instant('PENDING_ACTIONS');
  }

  getisIncompleteActivitiesExpanded() {
    return this.isIncompleteActivitiesExpanded;
  }

  get indHeaderLeftModel(): IndHeaderLeftDataModel {
    return {
      id: 'account-plan-list-header-left',
      cssClass: 'main-tool-header-title',
      // title: this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab === 'plans' ? this.translate.instant('Home') : this.translate.instant('ACCOUNT_PLANS'),
      title: this.translate.instant('HOME'),
      mode: true,
      isOnActPage: true,
      controls: [],
    };
  }

  public onSectionHeaderControlClick(id) {
    switch (id) {
      case 'incomplete-activities':
        this.isIncompleteActivitiesExpanded = !this.isIncompleteActivitiesExpanded
        this.initIncompleteActivitiesHeader();
        break;
      case 'pending-actions':
        this.isPendingActionsExpanded = !this.isPendingActionsExpanded
        this.initPendingActionsHeader();
        break;
      case 'pinned-items-actions':
        this.isPinnedItemsSectionExpanded = !this.isPinnedItemsSectionExpanded
        this.initPinnedItemsSectionHeader();
      default:
        break;
    }
  }

  onInput(event) {
    if (event && event.target) {
      const val = event.target.value;
      if (val && val.trim() != '') {
        this.isSearched = true;
        this.searchText = val;
      } else {
        this.isSearched = false;
        this.searchText = ''
      }
    }
    this.refreshList();
    this.refreshPendingAactions();
    this.filterPinnedItems();
  }

  private refreshList() {
    this.filterIncompleteActivities();
    this.filteredIncompleteActivities = this.sortIncompleteActivities(this.filteredIncompleteActivities);
    this.refreshIncompleteActivitiesBasedOnPinnedItems();
    this.updateVisibleRecords();
    this.initIncompleteActivitiesHeader();
    this.initPinnedItemsData();
  }

  private refreshPendingAactions() {
    this.filterPendingActions()
    this.filteredPendingActioncards = this.sortPendingActionItems(this.filteredPendingActioncards);
    // this.filterOutPendingActionsbasedOnpinnedItems()
    this.initPendingActionsHeader();
  }

  async openActivitiesFilter(ev: Event) {
    this.popoverCtrl
      .create({ component: MultiSelectPopover, componentProps: { root: this.multiSelectFilterPopoverData }, event: ev })
      .then((data) => {
        data.present();
      });
    // this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);
    // this.adjustmentService.teamAdjustmentSearchString = '';
  }


  searchIncompleteActivities() {
    if (this.isSearchActive()) {
      this.filteredIncompleteActivities = this.incompleteActivities.filter(activity => {
        return this.activityService._checkIfActivityIncludesSearchText(activity, this.searchText)
      }) as Array<Activity>
    } else {
      this.filteredIncompleteActivities = this.incompleteActivities;
    }
  }

  seaarchPendingActions() {
    if (this.isSearchActive()) {
      this.filteredPendingActioncards = this.searchMaincards(this.allPendingActionCards);
    } else {
      this.filteredPendingActioncards = this.allPendingActionCards;
    }
  }

  filterPinnedItems() {

    if (!this.pendingActionFilter && !this.incompleteActivitiesFilter) {
      this.filteredPinnedItems = this.allPinnedItems;
    } else {

      // Filter Incomplete Activities Cards
      let filteredCards: MainCardViewDataModel[] = [];
      if (this.incompleteActivitiesFilter) {
        let pinnedItemFilterCriteria = `${this.incompleteActivitiesFilter}-${PlanTabActivityType.INCOMPLETE_ACTIVTIES}`;
        filteredCards = filteredCards.concat(this.allPinnedItems.filter(card => card.type === pinnedItemFilterCriteria));
      }
      // Filter Pending Action cards from the Pinned Section.
      if (this.pendingActionFilter) {
        let pinnedItemFilterCriteria;

        if (this.pendingActionFilter === PendingActionType.FOLLOWUP) {
          pinnedItemFilterCriteria = `${ActivityType.FollowUp}-${PlanTabActivityType.PENDING_ACTIONS}`;

        } else if (this.pendingActionFilter === PendingActionType.SHIPMENT) {
          pinnedItemFilterCriteria = `${TransferType.AllocationShipment}-${PlanTabActivityType.PENDING_ACTIONS}`;

        } else if (this.pendingActionFilter === PendingActionType.TRANSFER) {

          pinnedItemFilterCriteria = `${TransferType.AllocationTransfer}-${PlanTabActivityType.PENDING_ACTIONS}`;
        } else if(this.pendingActionFilter === PendingActionType.ORDER){
          pinnedItemFilterCriteria = `${ActivityType.Order}-${PlanTabActivityType.PENDING_ACTIONS}`;
        } else if(this.pendingActionFilter === PendingActionType.COACHING){
          pinnedItemFilterCriteria = `coaching-${PlanTabActivityType.PENDING_ACTIONS}`;
        }

        filteredCards = filteredCards.concat(this.allPinnedItems.filter(card => card.type === pinnedItemFilterCriteria));
      }
      this.filteredPinnedItems = this.sortPinnedItems(filteredCards);
    }

    if (this.isSearchActive()) {
      this.filteredPinnedItems = this.searchMaincards(this.filteredPinnedItems);
    }
    this.filteredPinnedItems = this.sortPinnedItems(this.filteredPinnedItems);
    this.initPinnedItemsSectionHeader();
  }

  private searchMaincards(cards: Array<MainCardViewDataModel>) {
    return cards.filter(data => {
      return ((data.primaryTextRight + data.primaryTextLeft + data.secondaryTextRight).toLowerCase()).includes(this.searchText.trim().toLowerCase());
    }) as Array<MainCardViewDataModel>;
  }

  private getTransferToFromName(shipment: IAllocationTransfer | IAllocationShipment) {
    return shipment.ownerId === this.authService.user.xSystemUserID ? this.translate.instant('TO') + ` ${shipment.userFullName}` : this.translate.instant('FROM') + ` ${shipment.ownerFullName}`;
  }

  public getFormattedDate(value) {
    return `${this.datePipe.transform(value, 'MMM dd', undefined, this.translate.currentLang)}`;
  }

  private getTransferIconPath(shipment: IAllocationTransfer | IAllocationShipment) {
    if (shipment.indskr_transfertype === TransferType.AllocationTransfer) {
      return shipment.ownerId === this.authService.user.xSystemUserID ? 'indegene-assets/imgs/transfer_To.svg' : 'indegene-assets/imgs/transfer_From.svg'
    } else if (shipment.indskr_transfertype !== TransferType.AllocationAdjustment) {
      return 'indegene-assets/imgs/transfer_down.svg';
    }
  }

  getActivityMainCard(activity) {

    let clickhandler = (id: string, event, specificTarget) => {
      // let foundActivity = this.incompleteActivities.find(activity => activity.ID === id);
      let foundActivity = this.activityService.getActivityByID(id);
      if (foundActivity) {
        this.openActivityDetails(foundActivity);
      }
    }
    return this.getActivityMainCardModel(activity, clickhandler);
  }

  openActivityDetails(activity: Activity) {

    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);

    let foundActivity = this.activityService.getActivityByID(activity.ID);
    this.coachingReportService.selectedCoachingPlanTab = undefined;

    if (foundActivity) {
      this.activityService.selected = foundActivity;
    } else {
      this.activityService.selected = activity;
    }

    if (!this.uiService.activitiesPagePlanTabSelectedPlan) {
      this.uiService.activitiesPagePlanTabSelectedPlan = 'planTabTodo'
    }

    if (foundActivity && foundActivity.ID) {
      this.todoService.lastOpenActivityType = foundActivity.type;
      this.todoService.lastOpenActivityID = foundActivity.ID;
    }

    if (activity.type === ActivityType.CaseIntake) {
      if (!activity.ID) {
        activity.ID = (activity as CaseActivity).offline_ID;
        (activity as CaseActivity)._case_status_value = "Open";
      };

      if (!this.authService.impersonatedUser) {
        this.activityDataService.fetchCaseDetails(activity);
      }
    }

    this._ngZone.runOutsideAngular(() => {
      if (!this.authService.impersonatedUser) {
        this.activityDataService.updateActivityDetails(activity);
      }
    });

    if (this.activityService.selectedActivity) {
      let selectedActivity = this.activityService.selectedActivity;
      switch (selectedActivity.type) {

        case ActivityType.Appointment: {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
          break;
        }

        case ActivityType.FollowUp: {
          let activity = selectedActivity as FollowUpActivity;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;

          if (activity.scientificPlanId || (activity.planType && activity.planType == FOLLOW_UP_TYPE.SCIENTIFIC_PLAN)) {
            this.events.publish('updateCurrentFollowUpActivity');
            this.navService.setChildNavRoot(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
          } else {
            this.events.publish('updateCurrentFollowUpActivity');
            this.navService.setChildNavRoot(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
          }
          break;
        }

        case ActivityType.Email: {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.emailService.selectedActivity = <EmailActivity>(selectedActivity as EmailActivity);
          this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
          break;
        }

        case ActivityType.SurgeryOrder: {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.navService.setChildNavRoot(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
          break;
        }

        case ActivityType.PhoneCall: {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.navService.setChildNavRoot(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ActivitiesPageComponent);
          break;
        }

        case ActivityType.TimeOffRequest: {
          let activity = foundActivity as TimeOffActivity
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.timeOffService.setSelectedTot(activity);
          this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent);
          break;
        }

        case ActivityType.Sample: {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.navService.setChildNavRoot(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
          break;
        }

        case ActivityType.CaseIntake: {
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          try {
            this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
            this.caseManagementService.assignSelectedCase(<CaseActivity>selectedActivity);
          } catch (error) { }
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.navService.setChildNavRoot(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ActivitiesPageComponent);
          break;
        }

        case ActivityType.Order:
          // this.trackingService.tracking('AgendaOrderInfo', TrackingEventNames.ORDERMANAGEMENT);
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });

          break;

        default:
          break;
      }

      this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
      this.unhighlightSelectedPendingCards();

      if (this.device.isMobileDevicePortrait) {
        setTimeout(() => {
          this.unhighlightSelectedPinnedItem();
        }, 1000);
      } else {
        this.unhighlightSelectedPinnedItem();
      }

      this.allocFeatureService.selectedShipment = undefined;
      this.allocFeatureService.selectedShipmentAtHome = undefined;
      if (!this.authService.impersonatedUser) {
        this.activityDataService.updateActivityDetails(foundActivity);
      }
    }
  }

  //
  public doInfinite(event) {
    this.visibleCount = this.visibleIncompleteActivities.length;

    this.visibleIncompleteActivities.push(...this.filteredIncompleteActivities.slice(this.visibleCount, this.visibleCount + 20));

    event.target.complete();
  }

  filterOutPinnedItemsFromIncompleteActivities() {
    let pinnedIDs = this.filteredPinnedItems.map(item => item.id);
    this.filteredIncompleteActivities = this.filteredIncompleteActivities.filter(activity => pinnedIDs.includes(activity.ID) === false);
  }

  private filterIncompleteActivities() {

    if (this.isSearchActive()) {
      this.searchIncompleteActivities();
      if (this.listFiltered && this.incompleteActivitiesFilter) {
        this.filteredIncompleteActivities = this.filteredIncompleteActivities.filter(activity => {
          return activity.type === this.incompleteActivitiesFilter;
        });
      }
    } else {
      if (this.listFiltered) {
        if (this.incompleteActivitiesFilter) {
          this.filteredIncompleteActivities = this.incompleteActivities.filter(activity => {
            return activity.type === this.incompleteActivitiesFilter
          });
        }
      } else {
        this.filteredIncompleteActivities = this.incompleteActivities;
      }
    }
    this.filterOutPinnedItemsFromIncompleteActivities();
    this.sortIncompleteActivities(this.filteredIncompleteActivities);
    this.initIncompleteActivitiesHeader();
    this.updateVisibleRecords();
  }

  private filterPendingActions() {

    if (this.isSearchActive()) {
      this.seaarchPendingActions();
      if (this.listFiltered && this.pendingActionFilter) {
        this.filterPendingActionCategorically(this.filteredPendingActioncards);
      }
    } else {
      if (this.listFiltered) {
        if (this.allPendingActionCards) {
          this.filterPendingActionCategorically(this.allPendingActionCards);
        }
      } else {
        this.filteredPendingActioncards = this.allPendingActionCards;
      }
    }

    this.filterOutPendingActionsbasedOnpinnedItems();
    this.sortPendingActionItems(this.filteredPendingActioncards);
    this.initPendingActionsHeader();
    // this.updatePendingActionsVisibleRecords()
  }

  private filterPendingActionCategorically(pendingActionCards: Array<MainCardViewDataModel>) {
    if (this.pendingActionFilter === PendingActionType.FOLLOWUP) {
      this.filteredPendingActioncards = pendingActionCards.filter(item => {
        return item.type === ActivityType.FollowUp;
      });
    } else if (this.pendingActionFilter === PendingActionType.SHIPMENT) {
      this.filteredPendingActioncards = pendingActionCards.filter(item => {
        return item.type === TransferType.AllocationShipment;
      });
    } else if (this.pendingActionFilter === PendingActionType.TRANSFER) {
      this.filteredPendingActioncards = pendingActionCards.filter(item => {
        return item.type === TransferType.AllocationTransfer;
      });
    } else if (this.pendingActionFilter === PendingActionType.ORDER) {
      this.filteredPendingActioncards = pendingActionCards.filter(item => {
        return item.type === ActivityType.Order;
      });
    } else if (this.pendingActionFilter === PendingActionType.COACHING) {
      this.filteredPendingActioncards = pendingActionCards.filter(item => {
        return item.type === 'coaching';
      });
    }
  }

  private updateVisibleRecords() {
    this.visibleCount = 20;
    if (this.filteredIncompleteActivities.length > 20) {
      this.visibleIncompleteActivities = [...this.filteredIncompleteActivities].slice(0, this.visibleCount);
    } else {
      this.visibleIncompleteActivities = this.filteredIncompleteActivities;
    }
  }

  // private updatePendingActionsVisibleRecords() {
  //   this.visibleCount = 20;
  //   if (this.filteredPendingActioncards.length > 20) {
  //     this.visiblePendingActionscards = [...this.filteredPendingActioncards].slice(0, this.visibleCount);
  //   } else {
  //     this.visiblePendingActionscards = this.filteredPendingActioncards;
  //   }
  // }

  private sortIncompleteActivities(incompleteActivities: Array<Activity>) {
    return incompleteActivities.sort((a, b) => {
      if (a.scheduledStart < b.scheduledStart) return 1
      else return -1
    });
  }

  private sortPendingActionItems(pendingActionCards: Array<MainCardViewDataModel>) {
    return this.sortMaincardsArray(pendingActionCards);
  }

  private sortPinnedItems(pinnedItemCards: Array<MainCardViewDataModel>) {
    return this.sortMaincardsArray(pinnedItemCards);
  }

  private sortMaincardsArray(pendingActionCards: MainCardViewDataModel[]) {
    return pendingActionCards.sort((a, b) => {
      if (a.date < b.date)
        return 1;
      else
        return -1;
    });
  }

  private isSearchActive() {
    return !_.isEmpty(this.searchText) && this.isSearched;
  }

  // Pending Items main card Models
  public getPendingAdjustmentMainCardModel(adjustment: IAllocationAdjustment, clickHandler) {

    return {
      id: adjustment.indskr_usershipmentallocation_v2id,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: true,
      expandableViewType: 'activitiesList',
      expandHeight: this.itemExpandHeight,
      isSelected: false,
      primaryTextRight: `${adjustment.at_indskr_skuname || adjustment.indskr_skuname} (${adjustment.indskr_quantityadjusted})`,
      secondaryTextRight: `${adjustment.at_indskr_skuname || adjustment.indskr_skuname}`,
      showArrow: false,
      showIcon: true,
      clickHandler: clickHandler
    }
  }

  getActivityMainCardModel(activity, clickHandler): MainCardViewDataModel {
    switch (activity.type) {
      case ActivityType.Appointment:
        return this.getAppointmentMainCardModel(activity, clickHandler);
      case ActivityType.FollowUp:
        return this.getFollowupMainCardModel(activity, clickHandler);
      case ActivityType.SurgeryOrder:
        return this.getProcedureLogMainCardModel(activity, clickHandler);
      case ActivityType.ProcedureTracker:
        return this.getProcedureTrackerMainCardModel(activity, clickHandler);
      case ActivityType.Email:
        return this.getMessageMainCardModel(activity, clickHandler);
      case ActivityType.PhoneCall:
        return this.getPhoneCallMainCardModel(activity, clickHandler);
      case ActivityType.CaseIntake:
        return this.getCustomerInquiryMainCardModel(activity, clickHandler);
      case ActivityType.Sample:
        return this.getAllocationOrderMainCardModel(activity, clickHandler);
      case ActivityType.TimeOffRequest:
        return this.getTimeOffMainCardModel(activity, clickHandler);
      case ActivityType.Order:
        return this.getSalesOrderMainCardModel(activity, clickHandler);
    }
  }

  private getSalesOrderMainCardModel(activity: Activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment),
      primaryTextRight: this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ' - ' + activity.subject,
      secondaryTextRight: (!(activity as OrderActivity).accountId) ? this.translate.instant('NO_ACCOUNT') : (activity as OrderActivity).accountNameString,
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  private getAppointmentMainCardModel(activity: Activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      noSeparationLine: false,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment),
      //secondaryTextLeft: activity.lengthOfActivity,
      primaryTextRight: activity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && activity.subject == 'Visit') ? this.translate.instant('VISIT') : activity.subject,
      secondaryTextRight: (!activity.location || activity.location == 'nan') ? this.translate.instant('NO_LOCATION') : (activity.location == 'No Location' ? this.translate.instant('NO_LOCATION') : activity.location),
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart

    };
  }

  private getFollowupMainCardModel(activity, clickHandler): MainCardViewDataModel {

    let setFollowUpTime = new Date();
    setFollowUpTime.setHours(24);
    setFollowUpTime.setMinutes(0);
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(setFollowUpTime, this.dateTimeFormatService.timeFormatSelected.segment),
      primaryTextRight: activity.subject == 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : activity.subject,
      secondaryTextRight: this.getActivitySecondaryTextStringForFollowup(activity as FollowUpActivity),
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    }
  }

  private getProcedureLogMainCardModel(activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment),
      primaryTextRight: activity.subject,
      secondaryTextRight: this.getSecondaryInfoProcedureLog(activity),
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  private getProcedureTrackerMainCardModel(activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment),
      primaryTextRight: activity.subject,
      secondaryTextRight: this.getSecondaryInfoProcedureTracker(activity),
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  private getPhoneCallMainCardModel(activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment),
      //secondaryTextLeft: activity.lengthOfActivity,
      primaryTextRight: this.activityService.getActivitysubject(activity),
      secondaryTextRight: ((activity as PhoneActivity).selectedMobileNumber == "" || (activity as PhoneActivity).selectedMobileNumber == null || (activity as PhoneActivity).selectedMobileNumber == undefined) ? this.translate.instant('NO_CONTACT_NUMBER') : (activity as PhoneActivity).selectedMobileNumber,
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  private getMessageMainCardModel(activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: this.emailService.setTimeForMessages(activity as EmailActivity), //Time sent
      primaryTextRight: this.emailService.setPrimaryTextRightForMessages(activity as EmailActivity), //Customer Name
      secondaryTextRight: this.emailService.setSecondaryTextForMessages(activity as EmailActivity), //Channel name
      secondaryTextRightTwo: this.emailService.setSecondaryTextTwoForMessages(activity as EmailActivity), //Template subject
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  private getCustomerInquiryMainCardModel(activity, clickHandler): MainCardViewDataModel {
    let viewData: MainCardViewDataModel = {
      id: activity.ID,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'customer_inquiry',
      fixedHeight: true,
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment),
      primaryTextRight: (activity as CaseActivity).caseName,
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
    if ((activity as CaseActivity)._case_stage_value) {
      viewData.secondaryTextRight = this.activityService.getCaseState((activity));
      viewData.secondaryTextRightTwo = ((activity as CaseActivity)._case_contact) ? (activity as CaseActivity)._case_contact.fullName : ((activity as CaseActivity)._case_trans_customer_name && (activity as CaseActivity)._case_trans_customer_name != Utility.globalCustomerText) ? (activity as CaseActivity)._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
    } else {
      viewData.secondaryTextRight = ((activity as CaseActivity)._case_contact) ? (activity as CaseActivity)._case_contact.fullName : ((activity as CaseActivity)._case_trans_customer_name && (activity as CaseActivity)._case_trans_customer_name != Utility.globalCustomerText) ? (activity as CaseActivity)._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
    }
    return viewData;
  }

  private getAllocationOrderMainCardModel(activity, clickHandler): MainCardViewDataModel {
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment),
      primaryTextRight: (activity as SampleActivity).orderId ? this.translate.instant('ALLOWCATION_ORDER') + ' - ' + (activity as SampleActivity).orderId : this.translate.instant('ALLOWCATION_ORDER'),
      secondaryTextRight: (!(activity as SampleActivity).contactName) ? this.translate.instant('NO_CONTACT') : (activity as SampleActivity).contactName,
      showArrow: false,
      showIcon: true,
      isSrcAttribute: true,
      iconName: this.activityService.getActivityIcon(activity),
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  private getTimeOffMainCardModel(activity, clickHandler): MainCardViewDataModel {
    let setTimeOffTime = new Date();
    let setTimeOffStartTime = new Date();
    let setTimeOffEndTime = new Date();
    let formattedTimeOffTimeText: string = '';
    let tot: TimeOffActivity = <TimeOffActivity>activity;
    if (activity.type === ActivityType.TimeOffRequest) {
      setTimeOffTime.setTime(activity.scheduledStart.getTime());
      switch (tot.durationType) {
        case 'All Day':
          setTimeOffTime.setHours(24);
          setTimeOffTime.setMinutes(0);
          formattedTimeOffTimeText = format(setTimeOffTime, this.dateTimeFormatService.timeFormatSelected.segment); // all day format
          break;
        case 'Half Day':
          setTimeOffTime.setMinutes(0);
          (activity.scheduledStart.getHours() < 12) ? setTimeOffTime.setHours(24) : setTimeOffTime.setHours(12);
          formattedTimeOffTimeText = format(setTimeOffTime, this.dateTimeFormatService.timeFormatSelected.segment); // half day format
          break;
        case 'Hourly':
          setTimeOffStartTime.setTime(activity.scheduledStart.getTime());
          setTimeOffEndTime.setTime(activity.scheduledEnd.getTime());
          // Check half day event again because there is no payload related to half day during initialization of agenda list.
          if (activity.scheduledStart.getHours() == 0 && activity.scheduledStart.getMinutes() == 0 && activity.scheduledEnd.getHours() == 11 && activity.scheduledEnd.getMinutes() == 59 ||
            activity.scheduledStart.getHours() == 12 && activity.scheduledStart.getMinutes() == 0 && activity.scheduledEnd.getHours() == 23 && activity.scheduledEnd.getMinutes() == 59) {
            setTimeOffTime.setMinutes(0);
            (activity.scheduledStart.getHours() < 12) ? setTimeOffTime.setHours(24) : setTimeOffTime.setHours(12);
            formattedTimeOffTimeText = format(setTimeOffTime, this.dateTimeFormatService.timeFormatSelected.segment); // half day format
          } else {
            formattedTimeOffTimeText = format(setTimeOffStartTime, this.dateTimeFormatService.timeFormatSelected.segment) + ' - ' + format(setTimeOffEndTime, this.dateTimeFormatService.timeFormatSelected.segment); // hourly format
          }
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
    return {
      id: activity.ID,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: activity.expanded,
      expandableViewType: 'activitiesList',
      expandableData: activity,
      expandHeight: this.itemExpandHeight,
      isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
      primaryTextLeft: formattedTimeOffTimeText,
      //secondaryTextLeft: this.timeOffService.getFormattedTimeInterval(activity as TimeOffActivity),
      primaryTextRight: activity.subject == 'Time Off' ? this.translate.instant('TIME_OFF') : activity.subject,
      secondaryTextRight: activity.reason ? activity.reason : this.translate.instant('NO_REASON'),
      showArrow: false,
      showIcon: true,
      iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
      isConflicting: activity.conflictingActivityIds instanceof Map && activity.conflictingActivityIds.size > 0 ? true : false,
      clickHandler: clickHandler,
      type: activity.type,
      date: activity.scheduledStart
    };
  }

  getPendingActionShipmentMainCardModel(item: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment): MainCardViewDataModel {
    let clickhandler = (id: string, event, specificTarget) => {

      this.openAllocationShipment(id);
    }

    let date: Date;
    if ((item as IAllocationShipmentAndTransferBase).indskr_shipmentdate) {
      date = new Date((item as IAllocationShipmentAndTransferBase).indskr_shipmentdate) ?? null;
    } else if ((item as IAllocationAdjustment).indskr_adjustmentdate) {
      date = new Date((item as IAllocationAdjustment).indskr_adjustmentdate) ?? null;
    }

    return {
      id: item.indskr_usershipmentallocation_v2id,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: true,
      expandableViewType: 'activitiesList',
      expandableData: item,
      expandHeight: this.itemExpandHeight,
      noSeparationLine: false,
      isSelected: (this.allocFeatureService.selectedShipment && item.indskr_usershipmentallocation_v2id === this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id),
      primaryTextRight: `${this.getShipmentName(item)} ${item.indskr_shipmentnumber}`,
      primaryTextLeft: item.indskr_skuname || item.at_indskr_skuname,
      secondaryTextRight: item.indskr_transfertype === TransferType.AllocationTransfer ? `${this.getFormattedDate(date)} ${this.getTransferToFromName(item as IAllocationTransfer)}` : this.getFormattedDate(date),
      showArrow: false,
      showIcon: true,
      iconName: this.getTransferIconPath(item as IAllocationShipment),
      clickHandler: clickhandler,
      type: item.indskr_transfertype ? item.indskr_transfertype : TransferType.AllocationShipment,
      date: date
    };
  }

  private getShipmentName(item: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment) {
    if (item.indskr_transfertype === TransferType.AllocationTransfer) {
      return this.translate.instant('TRANSFER_NUMBER');
    }
    return this.translate.instant('AL_SHIPMENT_NUM');
  }

  private openAllocationShipment(id: string) {

    let founditem: PendingActions = this.allPendingActions.find((item: PendingActions) => {
      if ((item as IAllocationShipment).indskr_transfertype !== TransferType.AllocationAdjustment) {
        return (item as IAllocationShipment).indskr_usershipmentallocation_v2id === id;
      }
    });

    // replace the item
    this.highlighSelectedCard(id);

    if (founditem) {
      this.allocFeatureService.selectedShipment = founditem as IAllocationShipment;
      this.allocFeatureService.selectedShipmentAtHome = this.allocFeatureService.selectedShipment;

      this.activityService.selectedActivity = undefined;
      this.activityService.selectedActivityAtHome = undefined;
      this.navService.setChildNavRoot(AllocationShipmentDetailsComponent, PageName.AllocationShipmentDetailsComponent, PageName.ActivitiesPageComponent, { masterPage: PageName.ActivitiesPageComponent });
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'AllocationShipment';
      this.uiService.showRightPane = true;
      this.shipmentService.fromAgendaPlanTab = true;
    }
  }

  private getActivitySecondaryTextStringForFollowup(activity: FollowUpActivity): string {
    if (activity) {
      return this.followUpDataService.getActivitySecondaryTextStringForFollowup(activity);
    }
  }

  private getSecondaryInfoProcedureLog(activity: SurgeryOrderActivity): string {
    if (activity.indskr_noprocedureday) {
      return this.translate.instant('NO_PROCEDURE_DAY');
    } else {
      return (!activity.accountId) ? this.translate.instant('NO_ACCOUNT') : activity.accountNameString;
    }
  }

  private getSecondaryInfoProcedureTracker(activity: ProcedureTrackerActivity): string {
    return (!activity.accountId) ? this.translate.instant('NO_ACCOUNT') : activity.accountNameString;
  }

  //Deletion cases

  // handleMeeting Deleted and anything whcih falls in future or today cases in case of scrap, complete and date change this method will be called.
  handleIncompleteActivitiesTransactions(activity, shouldResetRightView = true) {
    if (activity) {

      let isPinned = this.isPinned(activity.ID);

      if (isPinned) {
        this.removePinnedItemFromDBAdnArrays(activity.ID)
      }
      this.handleTransactionsOnIncompleteActivity(activity, shouldResetRightView);
      // this.initIncompleteActivitiesHeader();
    } else {
      this.incompleteActivities = this.todoService.filterIncompleteActivities();
      this.filterIncompleteActivities();
    }

    this.refreshPinnedSectionData();
  }

  private handleTransactionsOnIncompleteActivity(activity: any, shouldResetRightView = true) {
    let activityIndex = this.incompleteActivities.findIndex(a => a.ID === activity.ID);
    if (activityIndex > -1) {
      this.incompleteActivities.splice(activityIndex, 1);
    }

    // remove from the filtered List and then from VIsible list so that we do not have scrolling issues and all
    this.filterIncompleteActivities();

    if (shouldResetRightView) {
      if (!this.uiService.toolsActivityActive) {
        this.resetRightSideView();
      }
    }
  }

  private resetRightSideView() {
    this.todoService.resetLastOpenActivity();
    this.uiService.showRightPane = false;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
  }

  private refreshPinnedSectionData() {
    this.initPinnedItemsData();
    this.initPinnedItemsSectionHeader();
  }

  handleActivityReopened(activity) {
    if (activity) {
      let dateAdjustedWithOfflineDataDuration = new Date(new Date((new Date().setDate(new Date().getDate() - this.authService.user.offlineDataDuration))).setHours(0, 0, 0, 0));
      let todayStart = new Date(new Date().setHours(0, 0, 0, 0));
      if (this.todoService.isValidated(activity, dateAdjustedWithOfflineDataDuration, todayStart)) {
        let index = this.incompleteActivities.findIndex(o => o.ID === activity?.ID);
        if (index > -1) {
          this.incompleteActivities[index] = activity;
        } else {
          this.incompleteActivities.push(activity);
        }
        this.refreshList();
      }
    }
  }

  // Filter Popover preparation
  // Check If Any of teh Category for follow up action is enabled If none of them are enabled Folllwo up actions  will not be shown in the Filter
  private hasFollowupEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY) || this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK) || this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK)
  }

  private hasProcedureLogEnabled() {
    return this.authService.user.hasProcedureLog;
  }

  private hasPhoneCallsEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY);
  }

  private hasMessagesEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY);
  }

  private hasCustomerInquiryEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE);
  }

  private hasAllocationOrderEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL);
  }

  private hasTimeOffEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL);
  }

  private hasSalesOrderEnabled() {
    return this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT);
  }

  prepareFilterData() {

    let incompleteActivitiesItems = this.prepareIncompleteActivitiesFilterData();
    let pendingActionItems = this.preparePendingActionsFilterData();

    this.multiSelectFilterPopoverData = [
      {
        text: '',
        value: '',
        items: [
          { text: this.translate.instant('ALL'), value: 'all' }
        ],
        handler: (selectedItem, item, itemRef) => {
          if (itemRef && itemRef.parent && itemRef.parent.items) {

            itemRef.parent.items.map((o) => {
              o.value = '';
            });
          }
          item.value = selectedItem.value;
          this.clearFilters();
          this.filterIncompleteActivities();
          this.filterPendingActions();
          this.filterPinnedItems();
        }
      },
      {
        text: this.translate.instant('PENDING_ACTIONS'),
        value: '',
        items: pendingActionItems,
        multiselect: false,
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value && item.text.lowercased() === selectedItem.text.lowercased() ? '' : selectedItem.value;
          this.pendingActionFilter = item.value;
          if (item.value == '') {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            return;
          }
          this.listFiltered = true;
          this.appliedFilterCount = this.incompleteActivitiesFilter ? 2 : 1;
          this.filterPendingActions();
          this.filterPinnedItems();
        }
      },
      {
        text: this.translate.instant('INCOMPLETE_ACTIVITIES'),
        value: '',
        items: incompleteActivitiesItems,
        multiselect: false,
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value && item.text.lowercased() === selectedItem.text.lowercased() ? '' : selectedItem.value;
          this.incompleteActivitiesFilter = item.value;
          if (item.value == '') {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            return;
          }
          this.listFiltered = true;
          this.appliedFilterCount = this.pendingActionFilter ? 2 : 1;
          this.filterIncompleteActivities();
          this.filterPinnedItems();
        }
      }
    ];
  }

  private preparePendingActionsFilterData() {
    let pendingActionItems = [];

    if (this.hasFollowupEnabled()) {
      pendingActionItems.push({ text: this.translate.instant('FOLLOW_UP_ACTIONS'), value: PendingActionType.FOLLOWUP });
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)) {
      pendingActionItems.push({ text: this.translate.instant('AL_SHIPMENTS'), value: PendingActionType.SHIPMENT });
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER)) {
      pendingActionItems.push({ text: this.translate.instant('TRANSFERS'), value: PendingActionType.TRANSFER });
    }

    if (this.hasSalesOrderEnabled()) {
      pendingActionItems.push({ text: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS'), value: ActivityType.Order });
    }

    pendingActionItems.push({ text: this.translate.instant('COACHING'), value: 'coaching' });

    return pendingActionItems
  }

  private prepareIncompleteActivitiesFilterData() {
    let incompleteActivitiesItems = [{ text: this.translate.instant('MEETINGS'), value: ActivityType.Appointment }];
    if (this.hasFollowupEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('FOLLOW_UP_ACTIONS'), value: ActivityType.FollowUp });
    }
    if (this.hasProcedureLogEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('PROCEDURE_LOGS'), value: ActivityType.SurgeryOrder });
    }
    if (this.hasPhoneCallsEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('PHONE_CALLS'), value: ActivityType.PhoneCall });
    }
    if (this.hasMessagesEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('MESSAGES'), value: ActivityType.Email });
    }
    if (this.hasCustomerInquiryEnabled()) {
      incompleteActivitiesItems.push({ text: this.translatedCustomerInquiryText, value: ActivityType.CaseIntake });
    }
    if (this.hasAllocationOrderEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('ALLOWCATION_ORDERS'), value: ActivityType.Sample });
    }
    if (this.hasTimeOffEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('TIME_OFF'), value: ActivityType.TimeOffRequest });
    }
    if (this.hasSalesOrderEnabled()) {
      incompleteActivitiesItems.push({ text: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS'), value: ActivityType.Order });
    }

    return incompleteActivitiesItems;
  }

  private renderCustomerText() {
    let translatedglobaltext;
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        translatedglobaltext = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        translatedglobaltext = this.translate.instant('STAKEHOLDER');
        break;
      default:
        translatedglobaltext = this.utilityService.globalCustomerText;
        break;
    }
    this.translatedCustomerInquiryText = this.translate.instant('CUSTOMER_INQUIRY_WITH_TEXT', { globalCustomerText: translatedglobaltext });
    this.translatedIncompleteCustomerInquiryText = this.translate.instant('INCOMPLETE_CUSTOMER_INQUIRY', { globalCustomerText: translatedglobaltext });
    this.translatedNoIncompleteCustomerInquiryText = this.translate.instant('NO_INCOMPLETE_CUSTOMER_INQUIRY', { globalCustomerText: translatedglobaltext });

    this.prepareFilterData();
  }

  clearFilters() {
    this.appliedFilterCount = 0
    this.incompleteActivitiesFilter = undefined;
    this.pendingActionFilter = undefined;
    this.listFiltered = false
  }

  getFilterButtonBadgeCount() {
    return this.appliedFilterCount;
  }

  getNoIncompleteActivitiesmessage() {
    if (this.listFiltered) {
      switch (this.incompleteActivitiesFilter) {
        case ActivityType.Appointment:
          return this.translate.instant('NO_INCOMPLETE_MEETINGS');
        case ActivityType.FollowUp:
          return this.translate.instant('NO_INCOMPLETE_FOLLOWUPS');
        case ActivityType.SurgeryOrder:
          return this.translate.instant('NO_INCOMPLETE_PROCEDURE_LOGS');
        case ActivityType.PhoneCall:
          return this.translate.instant('NO_INCOMPLETE_PHONECALLS');
        case ActivityType.Email:
          return this.translate.instant('NO_INCOMPLETE_MESSAGES');
        case ActivityType.TimeOffRequest:
          return this.translate.instant('NO_INCOMPLETE_TIME_OFF');
        case ActivityType.Sample:
          return this.translate.instant('NO_INCOMPLETE_ALLOCATION_ORDER');
        case ActivityType.CaseIntake:
          return this.translatedNoIncompleteCustomerInquiryText;
        case ActivityType.Order:
          return this.translate.instant('NO_INCOMPLETE_SALES_ORDERS');
        default:
          break;
      }
    }
    return this.translate.instant('NO_INCOMPLETE_ACTIVITIES');
  }

  getNoPendingActionmessages() {
    if (this.listFiltered) {
      if (this.pendingActionFilter === PendingActionType.FOLLOWUP) {
        return this.translate.instant('NO_PENDING_FOLLOW_UPS');
      } else if (this.pendingActionFilter === PendingActionType.SHIPMENT) {
        return this.translate.instant('NO_PENDING_SHIPMENTS');
      } else if (this.pendingActionFilter === PendingActionType.TRANSFER) {
        return this.translate.instant('NO_PENDING_TRANSFERS');
      } else if (this.pendingActionFilter === PendingActionType.ORDER) {
        return this.translate.instant('NO_PENDING_SALES_ORDERS');
      }
    }
    return this.translate.instant('NO_PENDING_ACTIONS');
  }

  getNoPinnedItemsMessage() {
    return this.translate.instant('NO_PINNED_ITEMS')
  }

  onCancel(event) {
    this.isSearched = false;
    this.searchText = ''
    this.refreshList();
  }

  isPinned(id: string, type?: PlanTabActivityType) {
    let index = this.todoService.pinnedItems.findIndex(item => item.id === id);
    return index >= 0;
  }

  checkIfActivityInfuture(date: Date) {
    let todayStart = new Date(new Date().setHours(0, 0, 0, 0));
    return date >= todayStart
  }

  pinIncompleteActivity(id: string, activityType: string, type: PlanTabActivityType) {
    this.todoService.track('ToDoPinClick');
    let index = -1;

    switch (type) {
      case PlanTabActivityType.INCOMPLETE_ACTIVTIES:
        // if an id is pinned remove from the Main List
        index = this.filteredIncompleteActivities.findIndex(activity => activity.ID === id);
        let shouldSave = true;

        // Check if Pinned item is in Future and of type Appointment then unpin it agina so that
        // this is to avoid flooding of pinned Activities as on moving away from teh TO-DO tab, it will be removed and a dangling activity will be left.

        if (index > -1) {
          let activity = this.filteredIncompleteActivities[index];
          if (this.checkIfActivityInfuture(activity.scheduledStart) && activity instanceof AppointmentActivity) {
            shouldSave = false;
          }
        }
        this.todoService.savePinnedItem(id, `${activityType}`, type, shouldSave);
        this.initPinnedItemsData();
        if (index > -1) {
          this.updateVisibleRecords();
          this.initIncompleteActivitiesHeader();
        }

        break;
      case PlanTabActivityType.PENDING_ACTIONS:
        this.todoService.savePinnedItem(id, `${activityType}`, type);
        this.initPinnedItemsData();
        index = this.filteredPendingActioncards.findIndex(card => card.id === id);
        if (index > -1) {
          let pinnedCards = this.filteredPendingActioncards.splice(index, 1);
          if (!_.isEmpty(pinnedCards)) {
            // this.addToPinnedSection(pinnedCards);
          }
        }
        this.initPendingActionsHeader();
        break;
    }
    //Now Refresh The Pinned Action Header.
    // this.initPinnedItemsData();
    this.sortPinnedItems(this.filteredPinnedItems);
    this.initPinnedItemsSectionHeader();
  }

  private removeFromActivitiesAndAddtoPinnedSection(pinnedActivities: Activity[]) {

    let activity = pinnedActivities[0]; // Take the first activity of teh array

    let clickhandler = (id: string, event, specificTarget) => {
      let foundActivity = pinnedActivities.find(activity => activity.ID === id);
      if (foundActivity) {
        this.openActivityDetails(foundActivity);
      }
    };
    this.allPinnedItems.push(this.getActivityMainCardModel(activity, clickhandler));
    this.filteredPinnedItems = this.allPinnedItems;
  }

  private addToPinnedSection(pinnedCards: Array<MainCardViewDataModel>) {
    let card = pinnedCards[0];
    this.allPinnedItems.push(card);
  }

  unpinItem(id: string) {
    this.removePinnedItemFromDBAdnArrays(id);

    // get the id incompleteActivity from the incompleteActivitiesList
    let todayStart = new Date(new Date().setHours(0, 0, 0, 0));

    // filtering from Incomplete Aactivities because for follow up action
    let filteredActivity = this.incompleteActivities.find(activity => activity.ID === id);

    if (filteredActivity) {
      if (filteredActivity.scheduledStart >= todayStart) {
        let shoudlResetRightSide = false
        if (filteredActivity.type === ActivityType.Appointment) {
          shoudlResetRightSide = true
        }
        this.handleTransactionsOnIncompleteActivity(filteredActivity, shoudlResetRightSide);

        if (filteredActivity.type === ActivityType.FollowUp) {
          this.refreshPendingActionsData()
        }
      }
    }

    this.initPinnedItemsSectionHeader();
    this.refreshPendingAactions();
    this.filterIncompleteActivities();
    this.initIncompleteActivitiesHeader();
    this.initPendingActionsHeader();
  }

  private removePinnedItemFromDBAdnArrays(id: string) {

    this.todoService.unpinItem(id);

    let index = this.allPinnedItems.findIndex(card => card.id === id);
    if (index > -1) {
      this.allPinnedItems.splice(index, 1); // remove from the main Array
    }

    index = this.filteredPinnedItems.findIndex(card => card.id === id);
    if (index > -1) {
      let splicedRecords = this.filteredPinnedItems.splice(index, 1);
      if (!_.isEmpty(splicedRecords)) {
        let splicedPinnedItem = splicedRecords[0];
      }
    }
  }

  shouldHidePinnedSection() {
    return _.isEmpty(this.filteredPinnedItems) && !this.isSearchActive() && !this.incompleteActivitiesFilter && !this.pendingActionFilter || ((this.isSearchActive() || this.pendingActionFilter || this.incompleteActivitiesFilter) && _.isEmpty(this.todoService.pinnedItems));
  }
}
