<ion-grid [ngClass]="(selectedCase || caseManagementService.showNewCase ) ? 'preview-active' : 'preview-inactive'">
    <ion-row>
        <ion-col class="left-pane">
            <case-management-list></case-management-list>
        </ion-col>
        <ion-col class="right-pane">
            <!-- No case selected and new case is false -->
            <nothing-selected-view *ngIf="!selectedCase && !caseManagementService.showNewCase"></nothing-selected-view>
            <!-- No case selected and new case is true -->
            <new-case-management *ngIf="!selectedCase && caseManagementService.showNewCase" ></new-case-management>
            <!-- case is selected and new case is false -->
            <case-management-detail *ngIf="selectedCase"></case-management-detail>
        </ion-col>
    </ion-row>
</ion-grid>