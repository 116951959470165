import { Component, Output, EventEmitter } from "@angular/core";
import {  PopoverController } from "@ionic/angular";
import { Events } from '@omni/events';
import { PresentationService } from "../../../services/presentation/presentation.service"
import { LogService } from "../../../services/logging/log-service";
import { Presentation } from "../../../classes/presentation/presentation.class";
import { DeviceService } from "../../../services/device/device.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { TranslateService } from '@ngx-translate/core';
import { Resource } from '../../../classes/resource/resource.class';


@Component({
    selector:'io-carousel-popover',
    templateUrl:'io-carousel-popover.html',
  styleUrls:['io-carousel-popover.scss']
  })
  export class CarouselPopover {

    public meetingPres: (Presentation | Resource)[];

    constructor(
        public presentationService: PresentationService,
        private events: Events,
        public deviceService: DeviceService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        public popoverCtrl: PopoverController
    ) {

        this.presentationService.carouselBriefcase.subscribe(pres => {
            this.meetingPres = pres;
        });
    }

    @Output()
    changePresView = new EventEmitter();

    changeView(pres: Presentation) {
        if(!pres) return;

        if(this.deviceService.isNativeApp && (this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !pres.downloaded) {
            this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "activity-details", "top", ToastStyle.DANGER, 3000, true);
            return;
        }

        this.events.publish("changePresentation", pres);
        this.popoverCtrl.dismiss();
    }
  }
