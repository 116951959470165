<!-- Generated template for the PhoneCallTherapeuticAreaComponent component -->
<ind-section-header [viewData]="therapeuticAreaHeaderModel" (onControlClick)="onSaveTA()"></ind-section-header>

<!-- <ion-item-divider>
  {{'THERAPEUTIC_AREAS_CAPS' | translate}}
  <ion-button fill="clear" slot="end" (click)="onSaveTA()"
    [disabled]="!isDataDirty  || activityService.selectedActivity?.isCompleted">{{'SAVE' | translate}}</ion-button>
</ion-item-divider> -->

<ion-reorder-group [disabled]="!(!activityService.selectedActivity?.isCompleted && !isReadOnlyJointPhoneCall && !activityService.teamViewActive && !backgroundUploadInProgress)" (ionItemReorder)="reorderData($event.detail)" [ngClass]="{'empty-list':!therapeuticAreas.length}">
    <ng-container *ngIf="!(activityService.selectedActivity && activityService.selectedActivity.isCompleted && activityService.selectedActivity['activityTherapeuticAreas'] && activityService.selectedActivity['activityTherapeuticAreas'].length === 0)">
        <div *ngFor="let ta of therapeuticAreas">
            <ion-item *ngIf="therapeuticAreas.length > 0">
                <!--THIS WILL BE A FIELD TO CHECK IF THE PRODUCT SHOULD BE SHOWN -->
                <div class="product-wrapper">
                    <div #roundInput [id]="ta.therapeuticareaId" [ngClass]="{'round': ( !backgroundUploadInProgress && !activityService.teamViewActive && !isReadOnlyJointPhoneCall),
            'round-disabled':(backgroundUploadInProgress || activityService.teamViewActive || isReadOnlyJointPhoneCall || isPhoneCallCompleted)}" (click)="updateTA($event , ta)">
                        <input [id]="ta.therapeuticareaId" type="checkbox" [ngModel]="ta.isSelected" />
                        <label [for]="ta.therapeuticareaId" [ngClass]="{
                                    'selected':ta.isSelected && !ta.isAutoSelected && !ta.isGenieSelected,
                                    'auto-selected': ta.isAutoSelected,
                                    'genie-selected' : ta.isGenieSelected && !ta.isAutoSelected}">
              </label>
                        <span style="margin-bottom:35px; margin-left: 15px;" size="25" class="key-message-text">{{ta.name}}</span>
                    </div>
                </div>
                <ion-reorder *ngIf="!activityService.teamViewActive" slot="end"></ion-reorder>
            </ion-item>
        </div>
    </ng-container>
</ion-reorder-group>
