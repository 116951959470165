<!-- <ion-header>
    <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button (click)="closepage(false)" class="back-btn">
                <ion-icon name="chevron-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">{{ 'CONTACT_RELATIONSHIP_INSIGHTS' | translate}} +</ion-title>
        <ion-buttons *ngIf="!readOnly" slot="end">
            <ion-button slot="icon-only" (click)="closepage(true)" [disabled]="!relationship.contactid">{{ 'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header> -->

<ion-header>
    <ind-page-title [viewData]="relationshipDetailPageHeaderModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content class="ion-no-padding" toolbar-md-height>
    <ng-container>
        <ion-list>
            <!-- <ion-item-divider>
                <ion-label> {{'XPERIENCES_RELATIONSHIP_DETAILS' | translate}} </ion-label>
            </ion-item-divider> -->
            <ind-section-header [viewData]="insightsRelationshipHeaderModel"></ind-section-header>
            <ion-item-group>
                <!-- <ion-item>
                    <ion-label>{{ 'CUSTOMER' | translate: { globalCustomerText: utilityService.globalCustomerText } }}</ion-label>
                    <div slot="end">
                        <span *ngIf="!readOnly" class="right-label">{{ xpService.newInsight.fullname }}</span>
                        <span *ngIf="readOnly" [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ relationship.customerName }}</span>
                    </div>
                </ion-item> -->

                <ind-form-field [viewData]="customerFormField"></ind-form-field>
                <ind-form-field [viewData]="relatedCustomerFormField"></ind-form-field>
                <!-- <ion-item>
                    <ion-label>{{ 'CONTACT_RELATED_CUSTOMER' | translate: { globalCustomerText: utilityService.globalCustomerText } }}</ion-label>
                    <div slot="end" [ngClass]="{'disabledLabel': readOnly}" (click)="gotoContactPage(mode.FOR_INSIGHT_RELATIONSHIP)">
                        <span *ngIf="!relationship.contactid" class="right-label placeholder">{{ 'SELECT_RELATED_CUSTOMER' | translate }}</span>
                        <span *ngIf="relationship.contactid" class="right-label">{{ relationship.fullname }}</span>
                        <ion-icon *ngIf="!readOnly" name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                    </div>
                </ion-item> -->
                <ind-form-field [viewData]="relationshipCategoryFormField"></ind-form-field>
                <!-- <ion-item>
                    <ion-label>{{ 'CATEGORY' | translate}}</ion-label>
                    <div slot="end" (click)="selectSpeciality()">
                        <span *ngIf="!relationship.selectedSpeciality && !readOnly" class="right-label placeholder">{{ 'SELECT_CATEGORY' | translate }}</span>
                        <span *ngIf="relationship.selectedSpeciality && relationship.selectedSpeciality.title" [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ relationship.selectedSpeciality.title }}</span>
                        <span [ngClass]="{'disabledLabel': readOnly}" *ngIf="(!relationship.selectedSpeciality || (relationship.selectedSpeciality && !relationship.selectedSpeciality.title)) && readOnly" class="right-label">{{'XPERIENCES_RELATIONSHIP_NO_CATEGORY' | translate }}</span>
                        <ion-icon *ngIf="!readOnly" name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                    </div>
                </ion-item> -->
                <ind-form-field [viewData]="relationshipTypeFormField"></ind-form-field>
                <!-- <ion-item>
                    <ion-label>{{ 'RELATIONSHIP_TYPE' | translate}}</ion-label>
                    <div slot="end" (click)="selectRelationship()">
                        <span *ngIf="!relationship.title && !readOnly" class="right-label placeholder">{{ 'SELECT_RELATIONSHIP_TYPES' | translate }}</span>
                        <span *ngIf="relationship.title" [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ relationship.title }}</span>
                        <span [ngClass]="{'disabledLabel': readOnly}" *ngIf="!relationship.title && readOnly" class="right-label">{{'XPERIENCES_RELATIONSHIP_NO_RELATIONSHIP_TYPE' | translate }}</span>
                        <ion-icon *ngIf="!readOnly" name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                    </div>
                </ion-item> -->
                <!-- <ion-item>
                    <ion-label>{{ 'XPERIENCES_INSIGHTS_CONFIDENCE' | translate}}</ion-label>
                    <div slot="end">
                        <ionic5-star-rating [(ngModel)]="relationship.score" #rating activeIcon="ios-star" defaultIcon="ios-star" activeColor="#FFA736" defaultColor="#c3c3c3" fontSize="32px" readonly="{{readOnly}}" maxRating=3></ionic5-star-rating>
                    </div>
                </ion-item> -->

                <ion-item class="confidence">
                    <div>
                        <ion-label class="form-field-header-label">{{ 'XPERIENCES_INSIGHTS_CONFIDENCE' | translate}}</ion-label>
                        <!-- <app-star-rating [scale]="[1,2,3]"></app-star-rating> -->
                        <app-star-rating [rating]="relationship.score" (ratingChanged)="updateRelationshipConfidence($event)" activeIcon="star-sharp" defaultIcon="star-sharp" activeColor="#FFA736" defaultColor="#c3c3c3" fontSize="32px" maxRating=3 readonly="{{readOnly}}"></app-star-rating>
                    </div>
                </ion-item>
                <ind-form-field *ngIf="readOnly && relationship.sources.length > 0" [viewData]="otherSourcesFormField"></ind-form-field>
                <!-- <ion-item *ngIf="readOnly && relationship.sources.length > 0">
                    <ion-label class="form-field-header-label">{{ 'XPERIENCES_OTHER_SOURCES' | translate}}</ion-label>
                    <div (click)="openSourcesPopover($event)">
                        <span [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ relationship.sourcesText }}</span>
                    </div>
                </ion-item> -->
            </ion-item-group>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'" [fromWhichView]="'Xperience'"></footer-toolbar>
<!-- </ion-footer> -->