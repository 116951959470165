import _ from "lodash";

export enum MarketScanStatusCode {
    Active = 1,
    Cancelled = 2,
    PendingSync = 3,
    Expired = 548910001,
    Completed = 548910000
}

export enum MarketScanStateCode {
    Active = 0,
    InActive = 1
}

export class MarketScan {
    public _id: string;
    public lastUpdatedTime: string;
    public pendingPushToDynamics: boolean = false; // set this flag true if created / updated offline
    public isModified: boolean = false; //set this flag true when modified saved data
    public category: Category;
    public categoryId: string;
    public retainPage: boolean = false; // it's temp flag to retain the previous page on save/update
    //public selectedColumnData: ColumnData[];
    public indskr_externalid: string;
    public indskr_marketscanid: string;
    public indskr_name: string;
    public indskr_date: string;
    public indskr_enddate: string;
    public modifiedon: string;
    public createdon: string;
    public statecode: number;
    public statuscode: number;
    public ownerId: string;
    public indskr_positionid: string;
    public selectView: string;
    public therapeuticAreas: MarketScanTA[];
    public products: MarketScanProduct[];
    public customerSegmentations: MarketScanCustomerSegment[];
    public contacts: MarketScanContact[];
    public accounts: MarketScanAccount[];
    public marketScanCustomerDatas: MarketScanCustomerData[] = [];
    public segmentedContacts: MarketScanContact[];
    public competitors:MarketScanCompetitor[];

    constructor(raw) {
        this.indskr_externalid = raw['indskr_externalid'] ? raw['indskr_externalid'] : '';
        this.indskr_marketscanid = raw['indskr_marketscanid'] ? raw['indskr_marketscanid'] : '';
        this.createdon = raw['createdon'] || '';
        this.modifiedon = raw['modifiedon'] || '';
        this.indskr_name = raw['indskr_name'] ? raw['indskr_name'] : '';
        this.indskr_date = raw['indskr_date'] ? raw['indskr_date'] : '';
        this.indskr_enddate = raw['indskr_enddate'] ? raw['indskr_enddate'] : '';
        this.ownerId = raw['ownerId'] ? raw['ownerId'] : '';
        this.statecode = raw['statecode'];
        this.statuscode = raw['statuscode'];
        this.indskr_positionid = raw['indskr_positionid'] ? raw['indskr_positionid'] : '';
        this.marketScanCustomerDatas = raw['marketScanCustomerDatas'] ? raw['marketScanCustomerDatas'] : [];
        this.therapeuticAreas = _.isEmpty(raw.therapeuticAreas) ? [] : raw.therapeuticAreas;
        this.products = _.isEmpty(raw.products) ? [] : raw.products;
        this.customerSegmentations = _.isEmpty(raw.customerSegmentations) ? [] : raw.customerSegmentations;
        this.contacts = _.isEmpty(raw.contacts) ? [] : raw.contacts;
        this.accounts = _.isEmpty(raw.accounts) ? [] : raw.accounts;
        this.segmentedContacts = _.isEmpty(raw.segmentedContacts) ? [] : raw.segmentedContacts;
        this.categoryId = raw['categoryId'] ? raw['categoryId'] : '';
        this.category = raw['category'] ? raw['category'] : '';
        this.products = raw['products'] || [];
        this.accounts = raw['accounts'] || [];
        this.contacts = raw['contacts'] || [];
        this.pendingPushToDynamics = raw['pendingPushToDynamics'] || false;
        this.lastUpdatedTime = raw['lastUpdatedTime'];
        this.selectView = raw['selectView'] || undefined;
        this.isModified = raw['isModified'] || false;
        this.competitors = raw['competitors'] || [];
    }
}

// export class ColumnData {
//     constructor(
//         public id: string,
//         public name: string,
//         public action: Action
//     ) {

//     }
// }
export class Category {
    constructor(
        public indskr_assessmentcategoryid: string,
        public indskr_name: string,
        public indskr_capturedata: number,
        public indskr_capturedatafor: number,
        public measures: Measure[],
        public indskr_recordcapturedat? : string
        ) {

    }
}

export class Measure {
    constructor(
        public measureName: string,
        public measureId: string,
        public measureType: number,
        public action: Action,
        public captureFor: MeasureCaptureFor,// = MeasureCaptureFor.CUSTOMERS_ACCOUNTS
    ) {
    }
}

export class MarketScanTA {
    constructor(
        public therapeuticAreaId: string,
        public therapeuticAreaName: string,
        public action: Action
    ) { }
}

export class MarketScanProduct {
    constructor(
        public productId: string,
        public productName: string,
        public isCompetitorProduct: boolean,
        public action: Action
    ) { }
}

export class MarketScanCustomerSegment {
    constructor(
        public segmentName: string,
        public customerSegmentationId: string,
        public action: Action
    ) { }
}

export class MarketScanContact {
    constructor(
        public contact_fullName: string,
        public contactId: string,
        public action: Action,
        public sequence: Number = null,
        public isActive = true,
        public affiliatedAccounts: Array<MarketScanAccount> = [],
    ) { }

}

export class MarketScanAccount {
  constructor(
      public accountName: string,
      public accountId: string,
      public action: Action,
      public sequence: Number = null,
      public isActive = true,
      public affiliatedContacts: Array<MarketScanContact> = [],
  ) { }

}

export class MarketScanCompetitor {
  constructor(
      public competitorName: string,
      public competitorId: string,
      public action: Action,
      public sequence: Number = null,
      public isActive = true,
  ) { }
}

export class MarketScanCustomerData {
    constructor(
        public indskr_externalid:string,
        public indskr_assessmentcategoryid:string,
        public measureId:string,
        public categoryName:string,
        public measureName:string,
        public measureType:string,
        public indskr_capturedata:CaptureData,
        public indskr_capturedatafor:CaptureDataFor,
        public productId:string,
        public accountId: string,
        public contactId: string,
        public sequence: Number,
        public action: Action,
        public indskr_eventid: string,
        public indskr_campaignid: string,
        public rX,
        public competitorId:string,
        //public data: Data[]
    ) { }
}

// export class Data {
//     public eventName: string;
//     public campaignName: string;
//     public productId: string;
//     public indskr_eventid: string;
//     public indskr_campaignid: string;
//     public accountId: string;
//     public accountName: string;
//     constructor(
//         public rX,
//         public indskr_externalid: string,
//         public action: Action,
//         public indskr_assessmentcategoryid: string,
//         public measureId: string,
//         public categoryName: string,
//         public measureName: string,
//         public measureType: number,
//         public indskr_capturedata: number,
//         public indskr_capturedatafor: number
//     ) { }
// }


export class CustomerSegmentation {
    constructor(
        public indskr_customersegmentationid: string,
        public indskr_name: string,
        public productId: string,
        public contactId: string
    ) { }
}

export enum FilterType {
    THERAPEUTIC_AREA,
    PRODUCT,
    CUSTOMER_SEGMENTATION,
    CONTACT
}

export enum Action {
    PATCH = "PATCH",
    DELETE = "DELETE"
}

export interface SubRowData {
    name: string,
    value: string,
    action: Action
}

export enum CreateFrequency {
    DAILY = 548910000,
    WEEKLY = 548910001,
    MONTHLY = 548910002
}

export enum MeasureType {
    FREE_TEXT = 548910001,
    NUMERIC = 548910002
}

export enum CaptureData {
    PRODUCT = 548910000,
    EVENTS = 548910001,
    CAMPAIGN = 548910002,
    ACCOUNTS = 548910003,
    PROCEDURE = 548910004
}

export enum CaptureDataFor {
  CUSTOMERS = 548910000,
  ACCOUNTS = 548910001,
  CUSTOMERS_ACCOUNTS = 548910002,
  COMPETITOR = 548910003
}

export enum MeasureCaptureFor {
  CUSTOMERS_ACCOUNTS = 548910000,
  CUSTOMERS = 548910001
}
export class Event {
    constructor(
        public msevtmgt_eventid: string,
        public msevtmgt_name: string,
        public startDate: string,
        public action: Action
    ) {

    }
}
export class CustomerJourney {
    constructor(
        public msdyncrm_customerjourneyid: string,
        public msdyncrm_name: string,
        public startDate: string,
        public action: Action
    ) {

    }
}

export class AffiliatedAccount {
  constructor(
      public accountId: string,
      public accountName: string,
      public action: Action
  ) {
  }

}
export interface MarketScanMatrixModel {
  matrixId:string;
  matrixName:string;
  matrixTable:Array<MarketScanMatrixColumnModel>,
}

export interface MarketScanMatrixColumnModel {
  columdId:string,
  columnItems:Array<MarketScanMatricItemModel>,
}

export interface MarketScanMatricItemModel {
  columnItemId: string,
  isHeader: boolean,
  isTotal: boolean,
  isInputField: boolean,
  columnItemLabel: string,
  columnItemSecondaryLabel: string,
  isMoreHeight:boolean,
  childItems?:Array<MarketScanMatricChildItemModel>
}

export interface MarketScanMatricChildItemModel {
  childItemID:string;
  isHeader: boolean,
  isTotal: boolean,
  isInputField: boolean,
  childItemLabel:string;
  isMoreHeight:boolean;
}

export interface MarketScanDetailsScreenMatrixModel {
  matrixId:string;
  matrixColumns:Array<MarketScanDetailsScreenColumnModel>;
}

export interface MarketScanDetailsScreenColumnModel {
  columnId:string;
  columnLabel:string;
  columnItems:Array<MarketScanDetailsScreenColumnItemModel>;
}

export interface MarketScanDetailsScreenColumnItemModel {
  columnItemId:string;
  columnItemLabel:string;
  showExpandIcon:boolean;
  childItems:Array<MarketScanDetailsScreenColumnChildItemModel>;
}

export interface MarketScanDetailsScreenColumnChildItemModel {
  childItemId:string;
  childItemLabel:string;
  childItemSecondaryLabel:string;
  isMoreHeight:boolean;
  subchildItems?:Array<MarketScanDetailsScreenColumnSubChildItemModel>
}

export interface MarketScanDetailsScreenColumnSubChildItemModel {
  subchildItemID:string;
  subchildItemLabel:string;
  isMoreHeight:boolean;
}