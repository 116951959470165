export interface Photo {
  name: string;
  base64String: string;
  createdAt: number;
  photoOrigin: number;
  accountId?: string;
}

export interface PhotoResponse {
  name: string;
  indskr_photoattachmentid: string;
  indskr_photoorigin?: number;
  indskr_photourl: string;
  isSelected?: boolean
}

export enum PhotoOrigin {
  PROMO_CHECK = 548910000,
  F2F_MEETING = 548910001,
  INSTORE_MEETING = 548910002,
  BLANK = 548910003,
  GPS_CHECKIN = 548910004
}

export enum GPSActivity {
  CHECKIN = 548910000,
  CHECKOUT = 548910001
}

export interface GPSActivityPhoto {
  indskr_type?: number;
  indskr_gpscheckindetailsid?: string;
  photoAttachments?: PhotoResponse[]
}

export enum GPSActivityApprovalStatus {
  DRAFT = 1,
  PENDING = 548910000,
  APPROVED = 548910001,
  NOT_APPROVED = 548910002,
  OUT_OF_SUBMISSION_WINDOW_PERIOD = 548910003,
  WHITE_LIST_AUTO_APPROVED = 548910004
}