import { uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, skip } from 'rxjs/operators';
import { CoachingFactsDTO, CoachingMeasureData } from '../../../../../interfaces/edge-analytics/coaching-report.interface';
import { ChartFilterOption, KPIDataBlock, MeasureConfig, MeasuresChartData, SystemUserDimensionDTO, YearMonthListItem, YearMonthOption } from '../../../../../interfaces/edge-analytics/report.interface';
import { CoachingChartTitles, CoachingReportTilePerRow, CoachingReportTiles, CoachingTrendChartFilterTemplate, CoachingReportYearMonthKeyProp } from '../../../../config/edge-analytics/coaching-report/coaching-report.config';
import { DefaultChartOptions } from '../../../../config/edge-analytics/report.config';
import { CoachingChartId, CoachingDimensions, CoachingKPI } from '../../../../enums/edge-analytics/coaching/coaching-report.enum';
import { CommonDimensions, MeasureType } from '../../../../enums/edge-analytics/edge-analytics.enum';
import { unsubscribeSubscriptionArray } from '../../../../utility/common.utility';
import { generateChart, generateReportGroupTiles, getDateDimensionMembers, getDefaultSelectedYearMonths, initYearMonthOptions, applyConfigToKPIMap, reducerForKPIs, setChartFilter, getYearMonthFromYYYYMM, updateHighChartOption, updateHighChartSeries } from '../report.functions';

/** ----------------------------------------------------------------------------------------
 *  Initialize base measure data
 */
export function coachingGenerateMeasureData(coachingCube: any, systemUserDimensions: SystemUserDimensionDTO[], userPrincipalName: string, lastUpdatedDate: Date, dataStartDate: string, config: MeasureConfig[], langCode: string): CoachingMeasureData {
  let coachingMeasureData: CoachingMeasureData;
  if (coachingCube && Array.isArray(systemUserDimensions) && userPrincipalName && lastUpdatedDate && dataStartDate) {

    // Initial data setup & list card subtext initialization
    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(coachingCube, CoachingReportYearMonthKeyProp, langCode);
    const _dateFilterOptions$: BehaviorSubject<YearMonthOption[]> = new BehaviorSubject(_dateFilterOptions);

    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    const _selectedDateFilters$: BehaviorSubject<number[]> = new BehaviorSubject(_selectedDateFilters);

    const _dataCube$ = new BehaviorSubject(coachingCube);

    const _dateDimensionMembers = getDateDimensionMembers(coachingCube, _selectedDateFilters, CoachingReportYearMonthKeyProp);
    const _dateDimensionMembers$ = new BehaviorSubject(_dateDimensionMembers);

    const userId = _getUserId(systemUserDimensions, userPrincipalName);

    // User filtered subCube
    const userFilter = _getUserCubeFilter(coachingCube, userId);
    const _userFilteredCube = _getDicedSubCube(coachingCube, userFilter);
    const _userFilteredCube$ = new BehaviorSubject(_userFilteredCube);
    const _systemUserDimensions$ = new BehaviorSubject(systemUserDimensions);

    // Attended as Covisitor filtered subCube
    const covisitorFilter = _getAttendedAsCovisitorCubeFilter(coachingCube, userId);
    const _covisitorFilteredCube = _getDicedSubCube(coachingCube, covisitorFilter);
    _fillInDateMembersForCovisitorSubCube(_covisitorFilteredCube, coachingCube);
    const _covisitorFilteredCube$ = new BehaviorSubject(_covisitorFilteredCube);

    // Diced SubCubes
    const _coVisitorDiceFilter = _getCoVisitorDiceFilter(_userFilteredCube, _dateDimensionMembers);
    const _coVisitorDicedSubCube = _getDicedSubCube(_userFilteredCube, _coVisitorDiceFilter);
    const _coVisitorDicedSubCube$ = new BehaviorSubject(_coVisitorDicedSubCube);
    const _totalCoachingsDiceFilter = _getTotalCoachingsDiceFilter(_userFilteredCube, _dateDimensionMembers);
    const _totalCoachingsDicedSubCube = _getDicedSubCube(_userFilteredCube, _totalCoachingsDiceFilter);
    const _ackedCoachingsDicedSubCube$ = new BehaviorSubject(null);
    const _avgCoachingScoreDicedSubCube$ = new BehaviorSubject(null);
    const _uniqCustMetWithCovisitorDicedSubCube$ = new BehaviorSubject(null);
    const _attendedAsCovisitorDicedSubCube$ = new BehaviorSubject(null);

    // KPI data behavior subjects & observables
    const _coVisitorMeetingsCount = _getCoVisitorMeetingsCount(_coVisitorDicedSubCube);
    const _coVisitorMeetingsCount$ = new BehaviorSubject(_coVisitorMeetingsCount);
    const _totalCoachingsCount = _getUniqCoachingsCount(_totalCoachingsDicedSubCube);
    const _ackedCoachingsCount$ = new BehaviorSubject(0);
    const _avgCoachingScore$ = new BehaviorSubject('0');
    const _uniqCustMetWithCovisitor$ = new BehaviorSubject(0);
    const _attendedAsCovisitorCount$ = new BehaviorSubject(0);

    // KPI data observables map
    const kpiMap: Map<string, KPIDataBlock> = new Map();

    kpiMap.set(CoachingKPI.coVisitorMeetings, {
      id: CoachingKPI.coVisitorMeetings,
      data$: _coVisitorMeetingsCount$.asObservable(),
      enabled: false,
    });
    kpiMap.set(CoachingKPI.coachingsAcknowledged, {
      id: CoachingKPI.coachingsAcknowledged,
      data$: _ackedCoachingsCount$.asObservable(),
      enabled: false,
    });
    kpiMap.set(CoachingKPI.avgCoachingScore, {
      id: CoachingKPI.avgCoachingScore,
      data$: _avgCoachingScore$.asObservable(),
      enabled: false,
    });
    kpiMap.set(CoachingKPI.uniqCustMetWithCovisitor, {
      id: CoachingKPI.uniqCustMetWithCovisitor,
      data$: _uniqCustMetWithCovisitor$.asObservable(),
      enabled: false,
    });
    kpiMap.set(CoachingKPI.attendedAsCovisitor, {
      id: CoachingKPI.attendedAsCovisitor,
      data$: _attendedAsCovisitorCount$.asObservable(),
      enabled: false,
    });

    // List card subtext
    const listCardSubtextValue: string = '' + _totalCoachingsCount;
    const listCardSubtextValue$: BehaviorSubject<string> = new BehaviorSubject(listCardSubtextValue);

    coachingMeasureData = {
      dataStream: {
        _dataCube$,
        dataCube$: _dataCube$.asObservable(),
        _userFilteredCube$,
        userFilteredCube$: _userFilteredCube$.asObservable(),
        _covisitorFilteredCube$,
        covisitorFilteredCube$: _covisitorFilteredCube$.asObservable(),
        _dateDimensionMembers$,
        dateDimensionMembers$: _dateDimensionMembers$.asObservable(),
        _systemUserDimensions$,
        systemUserDimensions$: _systemUserDimensions$.asObservable(),

        _coVisitorDicedSubCube$,
        coVisitorDicedSubCube$: _coVisitorDicedSubCube$.asObservable(),
        _ackedCoachingsDicedSubCube$,
        ackedCoachingsDicedSubCube$: _ackedCoachingsDicedSubCube$.asObservable(),
        _avgCoachingScoreDicedSubCube$,
        avgCoachingScoreDicedSubCube$: _avgCoachingScoreDicedSubCube$.asObservable(),
        _uniqCustMetWithCovisitorDicedSubCube$,
        uniqCustMetWithCovisitorDicedSubCube$: _uniqCustMetWithCovisitorDicedSubCube$.asObservable(),
        _attendedAsCovisitorDicedSubCube$,
        attendedAsCovisitorDicedSubCube$: _attendedAsCovisitorDicedSubCube$.asObservable(),

        _coVisitorMeetingsCount$,
        _ackedCoachingsCount$,
        _avgCoachingScore$,
        _uniqCustMetWithCovisitor$,
        _attendedAsCovisitorCount$,
      },

      kpiMap,

      dateFilterOptionType: 'YearMonth',
      dateFilterMultiSelect: true,
      _dateFilterOptions$,
      dateFilterOptions$: _dateFilterOptions$.asObservable(),
      _selectedDateFilters$,
      selectedDateFilters$: _selectedDateFilters$.asObservable(),
      listCardSubtextValue$,
      measureType: MeasureType.coaching,
      isFilterDirty: false,
      lastUpdatedDate,
      dataStartDate,
      subs: [],
      tileGroups: [],
      charts: [],
      sortOrder: 3,
      userId,
    };

    // Apply configuration
    applyConfigToKPIMap(kpiMap, config);

    // Init charts data
    _initChartsData(coachingMeasureData);
  } else {
    console.warn('coachingGenerateMeasureData: Invalid / missing input: ', coachingCube, systemUserDimensions, userPrincipalName, lastUpdatedDate);
  }

  return coachingMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Update measure data after delta sync
 */
export function coachingUpdateMeasureDataAfterSync( coachingMeasureData: CoachingMeasureData,
                                                    coachingCube: any,
                                                    systemUserDimensions: SystemUserDimensionDTO[],
                                                    userPrincipalName: string,
                                                    lastUpdatedDate: Date,
                                                    dataStartDate: string,
                                                    chartFilterOptionData: ChartFilterOption[],
                                                    config: MeasureConfig[]): CoachingMeasureData {

  if (coachingMeasureData && coachingCube && Array.isArray(systemUserDimensions) && userPrincipalName && lastUpdatedDate && dataStartDate) {
    coachingMeasureData.dataStream._dataCube$.next(coachingCube);
    coachingMeasureData.dataStream._systemUserDimensions$.next(systemUserDimensions);
    coachingMeasureData.lastUpdatedDate = lastUpdatedDate;
    coachingMeasureData.dataStartDate = dataStartDate;
    applyConfigToKPIMap(coachingMeasureData.kpiMap, config);

    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(coachingCube, CoachingReportYearMonthKeyProp, coachingMeasureData.langCode);
    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    const _dateDimensionMembers = getDateDimensionMembers(coachingCube, _selectedDateFilters, CoachingReportYearMonthKeyProp);
    const _userFilteredCube = _getDicedSubCube(
      coachingCube,
      _getUserCubeFilter(coachingCube, coachingMeasureData.userId),
    );
    const _covisitorFilteredCube = _getDicedSubCube(
      coachingCube,
      _getAttendedAsCovisitorCubeFilter(coachingCube, coachingMeasureData.userId),
    );
    _fillInDateMembersForCovisitorSubCube(_covisitorFilteredCube, coachingCube);
    const _totalCoachingsDiceFilter = _getTotalCoachingsDiceFilter(_userFilteredCube, _dateDimensionMembers);
    const _totalCoachingsDicedSubCube = _getDicedSubCube(_userFilteredCube, _totalCoachingsDiceFilter);
    const _totalCoachingsCount = _getUniqCoachingsCount(_totalCoachingsDicedSubCube);
    coachingMeasureData.listCardSubtextValue$.next('' + _totalCoachingsCount);

    if (Array.isArray(coachingMeasureData.subs) && coachingMeasureData.subs.length === 0) {
      // Since there's no data stream subscription, do manual initialization with new data
      coachingMeasureData._dateFilterOptions$.next(_dateFilterOptions);

      coachingMeasureData._selectedDateFilters$.next(_selectedDateFilters);

      coachingMeasureData.dataStream._dateDimensionMembers$.next(_dateDimensionMembers);

      coachingMeasureData.dataStream._userFilteredCube$.next(_userFilteredCube);

      coachingMeasureData.dataStream._covisitorFilteredCube$.next(_covisitorFilteredCube);
    } else if (Array.isArray(coachingMeasureData.subs) && coachingMeasureData.subs.length > 0) {
      // Already on detail page. Reset necessary stuff
      _generateReportGroupTiles(coachingMeasureData);
      _setChartFilters(coachingMeasureData, chartFilterOptionData, config);
    }
  } else {
    console.warn('coachingUpdateMeasureDataAfterSync: Invalid input: ', coachingMeasureData, coachingCube, systemUserDimensions)
  }

  return coachingMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Subscribe to measure data
 */
export function coachingSubscribeToMeasureData(coachingMeasureData: CoachingMeasureData, userPrincipalName: string, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[], forceReSubscribe = false) {
  if (coachingMeasureData && userPrincipalName) {
    // Reset filters if necessary
    coachingResetFilters(coachingMeasureData, userPrincipalName, chartFilterOptionData, config);

    // Unsubscribe previous subscriptions if necessary
    if (forceReSubscribe && Array.isArray(coachingMeasureData.subs)) {
      unsubscribeSubscriptionArray(coachingMeasureData.subs);
      coachingMeasureData.subs = [];
    }

    // Subscribe
    if (coachingMeasureData.dataStream && coachingMeasureData.subs.length === 0) {
      // Data cube subscription
      coachingMeasureData.subs.push(
        coachingMeasureData.dataStream.dataCube$.subscribe(coachingCube => {
          if (coachingCube) {
            const newYearMonthOptions = initYearMonthOptions(coachingCube, CoachingReportYearMonthKeyProp, coachingMeasureData.langCode);
            coachingMeasureData._dateFilterOptions$.next(newYearMonthOptions);
          }
        })
      );

      coachingMeasureData.subs.push(
        coachingMeasureData.dateFilterOptions$.subscribe(dateFilterOptions => {
          if (Array.isArray(dateFilterOptions)) {
            const newSelectedYearMonths: number[] = getDefaultSelectedYearMonths(dateFilterOptions);
            coachingMeasureData._selectedDateFilters$.next(newSelectedYearMonths);
          }
        })
      );

      coachingMeasureData.subs.push(
        combineLatest([
          coachingMeasureData.dataStream.dataCube$,
          coachingMeasureData.selectedDateFilters$,
          coachingMeasureData.dataStream.systemUserDimensions$,
        ]).pipe(
          filter(([coachingCube, selectedDateFilters, systemUserDimensions]) => coachingCube && Array.isArray(selectedDateFilters) && Array.isArray(systemUserDimensions)),
          debounceTime(0),
          skip(1),
          map(([coachingCube, selectedDateFilters, systemUserDimensions]) => {
            const newDateDimensionMembers = getDateDimensionMembers(coachingCube, selectedDateFilters, CoachingReportYearMonthKeyProp);
            coachingMeasureData.dataStream._dateDimensionMembers$.next(newDateDimensionMembers);

            const newUserFilteredCube = _getDicedSubCube(
              coachingCube,
              _getUserCubeFilter(coachingCube, coachingMeasureData.userId),
            );
            const newCovisitorFilteredCube = _getDicedSubCube(
              coachingCube,
              _getAttendedAsCovisitorCubeFilter(coachingCube, coachingMeasureData.userId),
            );
            _fillInDateMembersForCovisitorSubCube(newCovisitorFilteredCube, coachingCube);
            coachingMeasureData.dataStream._userFilteredCube$.next(newUserFilteredCube);
            coachingMeasureData.dataStream._covisitorFilteredCube$.next(newCovisitorFilteredCube);
          }),
        ).subscribe()
      );

      coachingMeasureData.subs.push(
        combineLatest([
          coachingMeasureData.dataStream.userFilteredCube$,
          coachingMeasureData.dataStream.systemUserDimensions$,
          coachingMeasureData.dataStream.dateDimensionMembers$
        ]).pipe(
          filter(([userFilteredCube, systemUserDimensions, dateDimensionMembers]) => userFilteredCube && Array.isArray(systemUserDimensions) && Array.isArray(dateDimensionMembers)),
          debounceTime(0),
          map(([userFilteredCube, systemUserDimensions, dateDimensionMembers]) => {
            const _coVisitorDiceFilter = _getCoVisitorDiceFilter(userFilteredCube, dateDimensionMembers);
            const _coVisitorDicedSubCube = _getDicedSubCube(userFilteredCube, _coVisitorDiceFilter);
            coachingMeasureData.dataStream._coVisitorDicedSubCube$.next(_coVisitorDicedSubCube);

            const _ackedCoachingsDiceFilter = _getAckedCoachingsDiceFilter(userFilteredCube, dateDimensionMembers);
            const _ackedCoachingsDicedSubCube = _getDicedSubCube(userFilteredCube, _ackedCoachingsDiceFilter);
            coachingMeasureData.dataStream._ackedCoachingsDicedSubCube$.next(_ackedCoachingsDicedSubCube);

            const _avgCoachingScoreDiceFilter = _getAvgCoachingScoreDiceFilter(userFilteredCube, dateDimensionMembers);
            const _avgCoachingScoreDicedSubCube = _getDicedSubCube(userFilteredCube, _avgCoachingScoreDiceFilter);
            coachingMeasureData.dataStream._avgCoachingScoreDicedSubCube$.next(_avgCoachingScoreDicedSubCube);

            const _uniqCustMetWithCovisitorDiceFilter = _getUniqCustMetWithCovisitorCountDiceFilter(
              userFilteredCube,
              dateDimensionMembers,
            );
            const _uniqCustMetWithCovisitorDicedSubCube = _getDicedSubCube(
                                                            userFilteredCube,
                                                            _uniqCustMetWithCovisitorDiceFilter
                                                          );
            coachingMeasureData.dataStream._uniqCustMetWithCovisitorDicedSubCube$.next(_uniqCustMetWithCovisitorDicedSubCube);
          })
        ).subscribe()
      );

      coachingMeasureData.subs.push(
        combineLatest([
          coachingMeasureData.dataStream.covisitorFilteredCube$,
          coachingMeasureData.dataStream.systemUserDimensions$,
          coachingMeasureData.dataStream.dateDimensionMembers$,
        ]).pipe(
          filter(([covisitorFilteredCube, systemUserDimensions, dateDimensionMembers]) => covisitorFilteredCube && Array.isArray(systemUserDimensions) && Array.isArray(dateDimensionMembers)),
          debounceTime(0),
          map(([covisitorFilteredCube, systemUserDimensions, dateDimensionMembers]) => {
            const _attendedAsCovisitorDiceFilter = _getAttendedAsCovisitorDiceFilter(
              covisitorFilteredCube,
              dateDimensionMembers,
            );
            const _attendedAsCovisitorDicedSubCube = _getDicedSubCube(
              covisitorFilteredCube,
              _attendedAsCovisitorDiceFilter,
            );
            coachingMeasureData.dataStream._attendedAsCovisitorDicedSubCube$.next(_attendedAsCovisitorDicedSubCube);
          })
        ).subscribe()
      );

      coachingMeasureData.subs.push(
        coachingMeasureData.dataStream.coVisitorDicedSubCube$.pipe(
          skip(1)
        ).subscribe(subCube => {
          if (subCube) {
            const newCoVisitorMeetingsCount = _getCoVisitorMeetingsCount(subCube);
            coachingMeasureData.dataStream._coVisitorMeetingsCount$.next(newCoVisitorMeetingsCount);
          }
        })
      );

      coachingMeasureData.subs.push(
        coachingMeasureData.dataStream.ackedCoachingsDicedSubCube$.subscribe(subCube => {
          if (subCube) {
            const newAckedCoachingsCount = _getUniqCoachingsCount(subCube);
            coachingMeasureData.dataStream._ackedCoachingsCount$.next(newAckedCoachingsCount);
          }
        })
      );

      coachingMeasureData.subs.push(
        coachingMeasureData.dataStream.avgCoachingScoreDicedSubCube$.subscribe(subCube => {
          if (subCube) {
            const newAvgCoachingScore = _getAvgCoachingScoreInFormattedText(subCube);
            coachingMeasureData.dataStream._avgCoachingScore$.next(newAvgCoachingScore);
          }
        })
      );

      coachingMeasureData.subs.push(
        coachingMeasureData.dataStream.uniqCustMetWithCovisitorDicedSubCube$.subscribe(subCube => {
          if (subCube) {
            const uniqCustFilteredCells = _getUniqCustFilteredCells(subCube);
            let newUniqCustMetWithCovisitorCount = 0;
            if (Array.isArray(uniqCustFilteredCells)) {
              newUniqCustMetWithCovisitorCount = uniqCustFilteredCells.length;
            }
            coachingMeasureData.dataStream._uniqCustMetWithCovisitor$.next(newUniqCustMetWithCovisitorCount);
          } else {
            coachingMeasureData.dataStream._uniqCustMetWithCovisitor$.next(0);
          }
        })
      );

      coachingMeasureData.subs.push(
        coachingMeasureData.dataStream.attendedAsCovisitorDicedSubCube$.subscribe(subCube => {
          if (subCube) {
            const uniqActivitiesAttendedAsCovisitorCount = _getAttendedAsCovisitorCount(subCube);
            coachingMeasureData.dataStream._attendedAsCovisitorCount$.next(uniqActivitiesAttendedAsCovisitorCount);
          } else {
            coachingMeasureData.dataStream._attendedAsCovisitorCount$.next(0);
          }
        })
      );

      // Generate tile groups
      _generateReportGroupTiles(coachingMeasureData);

      // Set chart filter options
      _setChartFilters(coachingMeasureData, chartFilterOptionData, config);

      // Trend chart data subscriptions
      _trendChartDataSubscription(coachingMeasureData);
    }
  } else {
    console.warn('coachingSubscribeToMeasureData: Invalid input: ', coachingMeasureData, userPrincipalName);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Reset date & measure filters
 */
export function coachingResetFilters(coachingMeasureData: CoachingMeasureData, userPrincipalName: string, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (coachingMeasureData && userPrincipalName && coachingMeasureData.isFilterDirty) {
    const messageCube = coachingMeasureData.dataStream._dataCube$.getValue();
    const _systemUserDimensions = coachingMeasureData.dataStream._systemUserDimensions$.getValue();

    // Date filter reset
    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(messageCube, CoachingReportYearMonthKeyProp, coachingMeasureData.langCode);
    coachingMeasureData._dateFilterOptions$.next(_dateFilterOptions);

    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    coachingMeasureData._selectedDateFilters$.next(_selectedDateFilters);

    const _dateDimensionMembers = getDateDimensionMembers(messageCube, _selectedDateFilters, CoachingReportYearMonthKeyProp);
    coachingMeasureData.dataStream._dateDimensionMembers$.next(_dateDimensionMembers);

    // Chart filters reset
    _setChartFilters(coachingMeasureData, chartFilterOptionData, config);
    coachingMeasureData.isFilterDirty = false;
  }
}


/** ----------------------------------------------------------------------------------------
 *  Measure data prep & calculation helper functions
 */
export function coachingFillInMissingDateFacts(missingYearMonthList: YearMonthListItem[], facts: CoachingFactsDTO[], systemUserId: number) {
  if (Array.isArray(missingYearMonthList) && Array.isArray(facts) && !isNaN(systemUserId)) {
    for (let i = 0; i < missingYearMonthList.length; i++) {
      const yearMonthListItem = missingYearMonthList[i];
      const emptyFact = _generateEmptyFactForMissingYearMonth(yearMonthListItem, systemUserId);
      if (emptyFact) {
        facts.push(emptyFact);
      }
    }
  } else {
    console.error('coachingFillInMissingDateFacts: ', missingYearMonthList, facts, systemUserId);
  }
}
function _generateEmptyFactForMissingYearMonth(yearMonthListItem: YearMonthListItem, systemUserId: number): CoachingFactsDTO {
  let emptyFact: CoachingFactsDTO;

  if (yearMonthListItem && systemUserId !== null) {
    emptyFact = {
      activitydate: null,
      activityid: yearMonthListItem.yearMonth + '_empty',
      activitysubtype: null,
      coaching_days: null,
      coaching_score: null,
      covisitor: null,
      extractedon: null,
      id: systemUserId + '_' + yearMonthListItem.yearMonth + '_empty',
      meeting_type: null,
      sk_categoryid: null,
      sk_contactid: null,
      sk_measureid: null,
      sk_productid: null,
      sk_ratingid: null,
      sk_systemuserid: systemUserId,
      source_type: null,
      statuscode: null,
      uniquerowid: '',
      updatedon: null,
      yearandmonth: yearMonthListItem.yearMonth,
      is_fullrefresh: null
    }
  }

  return emptyFact;
}

function _getUserId(systemUserDimensions: SystemUserDimensionDTO[], userPrincipalName: string) {
  let userId: number = null;
  if (userPrincipalName && Array.isArray(systemUserDimensions)) {
    try {
      userId = systemUserDimensions.find(u => u.internalemailaddress === userPrincipalName)?.sk_systemuserid;
    } catch (error) {
      console.error('_getUserId: ', error);
    }
  }
  return userId;
}
function _getUserCubeFilter(dataCube: any, userId: number = null): { users: string[] } {
  let filter = null;
  if (dataCube && userId !== null) {
    try {
      const users = dataCube.getDimensionMembers(CommonDimensions.users).filter(user => user.sk_systemuserid === userId);
      if (Array.isArray(users) && users.length > 0) {
        filter = { users };
      }
    } catch (error) {
      console.error('_getUserCubeFilter: ', error);
    }
  }
  return filter;
}
function _getAttendedAsCovisitorCubeFilter(dataCube: any, covisitorId: number) {
  let filter = null;
  if (dataCube && !isNaN(covisitorId)) {
    try {
      const covisitor = dataCube.getDimensionMembers(CoachingDimensions.covisitor).filter(r => r.covisitor === covisitorId);
      if (Array.isArray(covisitor) && covisitor.length > 0) {
        filter = { covisitor };
      }
    } catch (error) {
      console.error('_getAttendedAsCovisitorCubeFilter: ', error);
    }
  }
  return filter;
}
function _fillInDateMembersForCovisitorSubCube(covisitorSubCube: any, coachingCube: any) {
  // This subCube can have missing date members after the filter.
  // Hence, manually replace the date members so that date filter can work.
  if (covisitorSubCube && coachingCube) {
    const origDateDimensionTree = coachingCube?.dimensionHierarchies?.find(d => d.dimensionTable?.dimension === CommonDimensions.yearMonth);
    const dateDimensionTree = covisitorSubCube?.dimensionHierarchies?.find(d => d.dimensionTable?.dimension === CommonDimensions.yearMonth);
    if (
      dateDimensionTree?.dimensionTable
      && Array.isArray(
        origDateDimensionTree?.dimensionTable?.members
      )
    ) {
      dateDimensionTree.dimensionTable.members = JSON.parse(JSON.stringify(origDateDimensionTree.dimensionTable.members));
    }
  }
}
function _getDicedSubCube(coachingCube: any, filter: any) {
  let subCube = null;

  if (coachingCube && filter) {
    try {
      const diced = coachingCube.dice(filter);
      subCube = diced ? diced : null;
    } catch (error) {
      console.error('_getDicedSubCube: ', error);
    }
  }
  return subCube;
}
function _getCoVisitorDiceFilter(coachingCube: any, dateMembers: any[], sourceTypeMember?: any) {
  let filter = null;
  if (coachingCube && Array.isArray(dateMembers)) {
    if (!sourceTypeMember) {
      sourceTypeMember = coachingCube.getDimensionMembers(CoachingDimensions.sourceType).filter(c => c.source_type === 'JM')[0];
    }

    if (sourceTypeMember) {
      filter = { yearMonth: dateMembers, sourceType: sourceTypeMember };
    } else {
      console.warn('_getCoVisitorDiceFilter: Invalid set: ', coachingCube, dateMembers, sourceTypeMember);
    }
  } else {
    console.warn('_getCoVisitorDiceFilter: Invalid input: ', coachingCube, dateMembers);
  }
  return filter;
}
function _getCoVisitorMeetingsCount(dicedCube: any) {
  let count = 0;
  if (dicedCube) {
    try {
      const uniqActivities = dicedCube.getDimensionMembers(CoachingDimensions.activityId);
      count = Array.isArray(uniqActivities) ? uniqActivities.length : 0;
    } catch (error) {
      console.error('_getCoVisitorMeetingsCount: ', error);
    }
  } else {
    console.warn('_getCoVisitorMeetingsCount: Invalid input: ', dicedCube);
  }
  return count;
}
function _getTotalCoachingsDiceFilter(coachingCube: any, dateMembers: any[], sourceTypeMember?: any, statusCodeMember?: any) {
  let filter = null;
  if (coachingCube && Array.isArray(dateMembers)) {
    if (!sourceTypeMember) {
      sourceTypeMember = coachingCube.getDimensionMembers(CoachingDimensions.sourceType).filter(c => c.source_type === 'FC')[0];
    }

    if (!statusCodeMember) {
      statusCodeMember = coachingCube.getDimensionMembers(CoachingDimensions.statusCode).filter(c => (c.statuscode !== 1 && c.statuscode !== 548910001))[0];
    }

    if (sourceTypeMember && statusCodeMember) {
      filter = { yearMonth: dateMembers, sourceType: sourceTypeMember, statusCode: statusCodeMember };
    } else {
      console.warn('_getTotalCoachingsDiceFilter: Invalid set: ', coachingCube, dateMembers, sourceTypeMember);
    }
  } else {
    console.warn('_getTotalCoachingsDiceFilter: Invalid input: ', coachingCube, dateMembers);
  }
  return filter;
}
function _getAckedCoachingsDiceFilter(coachingCube: any, dateMembers: any[], sourceTypeMember?: any, statusCodeMember?: any) {
  let filter = null;
  if (coachingCube && Array.isArray(dateMembers)) {
    if (!sourceTypeMember) {
      sourceTypeMember = coachingCube.getDimensionMembers(CoachingDimensions.sourceType).filter(c => c.source_type === 'FC')[0];
    }
    if (!statusCodeMember) {
      statusCodeMember = coachingCube.getDimensionMembers(CoachingDimensions.statusCode).filter(c => c.statuscode === 548910000)[0];
    }

    if (sourceTypeMember && statusCodeMember) {
      filter = { yearMonth: dateMembers, sourceType: sourceTypeMember, statusCode: statusCodeMember };
    } else {
      console.warn('_getAckedCoachingsDiceFilter: Invalid set: ', coachingCube, dateMembers, sourceTypeMember, statusCodeMember);
    }
  } else {
    console.warn('_getAckedCoachingsDiceFilter: Invalid input: ', coachingCube, dateMembers);
  }
  return filter;
}
function _getUniqCoachingsCount(dicedCube: any): number {
  let count: number = 0;
  if (dicedCube) {
    try {
      const uniqActivities = dicedCube.getDimensionMembers(CoachingDimensions.activityId);
      count = Array.isArray(uniqActivities) ? uniqActivities.length : 0;
    } catch (error) {
      console.error('_getUniqCoachingsCount: ', error);
    }
  } else {
    console.warn('_getUniqCoachingsCount: Invalid input: ', dicedCube);
  }
  return count;
}
function _getAvgCoachingScoreDiceFilter(coachingCube: any, dateMembers: any[], sourceTypeMember?: any, statusCodeMembers?: any[]) {
  let filter = null;
  if (coachingCube && Array.isArray(dateMembers)) {
    if (!sourceTypeMember) {
      sourceTypeMember = coachingCube.getDimensionMembers(CoachingDimensions.sourceType).filter(c => c.source_type === 'CS')[0];
    }
    if (!statusCodeMembers) {
      statusCodeMembers = coachingCube.getDimensionMembers(CoachingDimensions.statusCode)
                                      .filter(c => c.statuscode === 548910000 || c.statuscode === 548910001);
    }
    if (sourceTypeMember && statusCodeMembers) {
      filter = { yearMonth: dateMembers, sourceType: sourceTypeMember, statusCode: statusCodeMembers };
    }
  } else {
    console.warn('_getAvgCoachingScoreDiceFilter: Invalid input: ', coachingCube, dateMembers);
  }
  return filter;
}
function _calculateAvgCoachingScore(dicedCube: any): number {
  let avgScore = 0;

  if (dicedCube) {
    try {
      const uniqActivities = dicedCube.getDimensionMembers(CoachingDimensions.activityId);
      const count = Array.isArray(uniqActivities) ? uniqActivities.length : 0;
      if (count > 0) {
        let scoreSum = 0;
        const cells = dicedCube.getCells();
        if (Array.isArray(cells)) {
          scoreSum = reducerForKPIs('coaching_score', cells, 'summation');
        }

        if (scoreSum > 0) {
          const avgScoreNum = scoreSum / count * 100;
          avgScore = parseFloat(avgScoreNum.toFixed(2));
        }
      }
    } catch (error) {
      console.error('_calculateAvgCoachingScore: Invalid input: ', error);
    }
  } else {
    console.warn('_calculateAvgCoachingScore: Invalid input: ', dicedCube);
  }

  return avgScore;
}
function _getAvgCoachingScoreInFormattedText(dicedCube: any): string {
  let avgScore = '0%';
  try {
    avgScore = _calculateAvgCoachingScore(dicedCube).toFixed(2).replace('.00', '') + '%';
  } catch (error) {
    console.error('_getAvgCoachingScore: ', error);
  }
  return avgScore;
}
function _getUniqCustMetWithCovisitorCountDiceFilter(
  coachingCube: any, dateMembers: any[], sourceTypeMember?: any,
) {
  let filter = null;
  if (coachingCube && Array.isArray(dateMembers)) {
    if (!sourceTypeMember) {
      sourceTypeMember = coachingCube.getDimensionMembers(CoachingDimensions.sourceType)
                                      .filter(c => c.source_type === 'JM')[0];
    }

    if (sourceTypeMember) {
      filter = {
        yearMonth: dateMembers,
        sourceType: sourceTypeMember,
      };
    } else {
      console.warn('_getUniqCustMetWithCovisitorCountDiceFilter: Invalid set: ',
                    coachingCube,
                    dateMembers,
                    sourceTypeMember,
                  );
    }
  } else {
    console.warn('_getUniqCustMetWithCovisitorCountDiceFilter: Invalid input: ', coachingCube, dateMembers);
  }
  return filter;
}
function _getAttendedAsCovisitorDiceFilter(
  coachingCube: any, dateMembers: any[],
) {
  let filter = null;
  if (coachingCube && Array.isArray(dateMembers)) {
    filter = { yearMonth: dateMembers };
  }
  return filter;
}
function _getUniqCustFilteredCells(filteredSubCube: any): any[] {
  // Filter the cells because we introduced empty cells by filling in missing dates...
  return filteredSubCube ? uniqBy(
    filteredSubCube.getCells()
      .filter(({ sk_contactid, meeting_type }) => sk_contactid && meeting_type !== 'Phonecall'),
    'sk_contactid') : [];
}
function _getAttendedAsCovisitorCount(dicedCube: any): number {
  let count: number = 0;
  if (dicedCube) {
    try {
      const uniqActivities = dicedCube.getDimensionMembers(CoachingDimensions.activityId);
      count = Array.isArray(uniqActivities) ? uniqActivities.length : 0;
    } catch (error) {
      console.error('_getAttendedAsCovisitorCount: ', error);
    }
  }
  return count;
}

/** ----------------------------------------------------------------------------------------
 *  Report tile data connect & generation
 */
function _generateReportGroupTiles(coachingMeasureData: CoachingMeasureData, coachingReportTiles = JSON.parse(JSON.stringify(CoachingReportTiles)), reportTilesPerRow = CoachingReportTilePerRow) {
  generateReportGroupTiles(coachingMeasureData, coachingReportTiles, reportTilesPerRow, 'coaching-tile-group');
}


/** ----------------------------------------------------------------------------------------
 *  Chart data initialization
 */
function _initChartsData(coachingMeasureData: CoachingMeasureData) {
  if (coachingMeasureData) {
    _initTrendChartData(coachingMeasureData);
  }
}
function _initTrendChartData(coachingMeasureData: CoachingMeasureData) {
  const _chartFilterOptions$ = new BehaviorSubject(null);
  const _xAxisCategories$ = new BehaviorSubject(null);
  const _chartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);
  // Trend chart type is line
  const trendChartOptions = JSON.parse(JSON.stringify(DefaultChartOptions));
  trendChartOptions.chart.type = 'line';

  const trendChartData: MeasuresChartData = {
    id: CoachingChartId.trend,
    title: 'Trend',
    titleKey: CoachingChartTitles[CoachingChartId.trend],
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: JSON.parse(JSON.stringify(CoachingTrendChartFilterTemplate)),
    _chartFilterOptions$,
    chartFilterOptions$: _chartFilterOptions$.asObservable(),
    _xAxisCategories$,
    xAxisCategories$: _xAxisCategories$.asObservable(),
    _chartUpdated$,
    chartUpdated$: _chartUpdated$.asObservable(),
    customChartOption: trendChartOptions,
    sortOrder: 0
  };

  coachingMeasureData.charts.push(trendChartData);
}


/** ----------------------------------------------------------------------------------------
 *  Chart helper functions
 */
export function coachingGenerateCharts(coachingMeasureData: CoachingMeasureData, isMobilePortrait: boolean, windowInnerWidth: number, windowInnerHeight: number) {
  if (coachingMeasureData && Array.isArray(coachingMeasureData.charts)) {
    for (let i = 0; i < coachingMeasureData.charts.length; i++) {
      const chartData = coachingMeasureData.charts[i];
      chartData.chart = generateChart(  chartData._chartFilterOptions$.getValue(),
                                        chartData.title,
                                        isMobilePortrait,
                                        windowInnerWidth,
                                        windowInnerHeight,
                                        chartData._xAxisCategories$ && Array.isArray(chartData._xAxisCategories$.getValue())
                                          ? chartData._xAxisCategories$.getValue() : undefined,
                                        chartData.customChartOption ? chartData.customChartOption : undefined);

      if (chartData.chart && chartData.chart.ref$) {
        chartData.highChartSub = chartData.chart.ref$.subscribe(ref => {
          chartData.highChartRef = ref
          chartData._highChartRefReady$.next(true);
        });
      }
    }
  }
}
function _setChartFilters(coachingMeasureData: CoachingMeasureData, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (coachingMeasureData && Array.isArray(coachingMeasureData.charts)) {
    for (let i = 0; i < coachingMeasureData.charts.length; i++) {
      const chartData = coachingMeasureData.charts[i];

      switch (chartData.id) {
        case CoachingChartId.trend:
          setChartFilter(chartData, chartFilterOptionData, config);
          break;
        default:
          break;
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart helper functions
 */
function _assignCoVisitorMeetingsByMonthData(filterOption: ChartFilterOption, coachingMeasureData: CoachingMeasureData, dateMembers: any[]) {
  const dicedCube = coachingMeasureData.dataStream._coVisitorDicedSubCube$.getValue();

  if (Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = dicedCube ? dicedCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const count = _getCoVisitorMeetingsCount(sliced) || 0;
      byMonthData.push(count);
    }

    filterOption.data = byMonthData;
  }
}
function _assignAckedCoachingsByMonthData(filterOption: ChartFilterOption, coachingMeasureData: CoachingMeasureData, dateMembers: any[]) {
  const dicedCube = coachingMeasureData.dataStream._ackedCoachingsDicedSubCube$.getValue();

  if (Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = dicedCube ? dicedCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const count = _getUniqCoachingsCount(sliced) || 0;
      byMonthData.push(count);
    }

    filterOption.data = byMonthData;
  }
}
function _assignAvgCoachingScoreByMonthData(filterOption: ChartFilterOption, coachingMeasureData: CoachingMeasureData, dateMembers: any[]) {
  const dicedCube = coachingMeasureData.dataStream._avgCoachingScoreDicedSubCube$.getValue();

  if (Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = dicedCube ? dicedCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const avgScore = _calculateAvgCoachingScore(sliced);
      byMonthData.push(avgScore);
    }

    filterOption.data = byMonthData;
  }
}
function _assignUniqCustMetWithCovisitor(
  filterOption: ChartFilterOption,
  coachingMeasureData: CoachingMeasureData,
  dateMembers: any[],
) {
  const dicedCube = coachingMeasureData.dataStream._uniqCustMetWithCovisitorDicedSubCube$.getValue();

  if (Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = dicedCube ? dicedCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const uniqCustFilteredCells = _getUniqCustFilteredCells(sliced);
      let avgScore = 0;
      if (Array.isArray(uniqCustFilteredCells)) {
        avgScore = uniqCustFilteredCells.length;
      }
      byMonthData.push(avgScore);
    }

    filterOption.data = byMonthData;
  }
}
function _assignAttendedAsCovisitor(
  filterOption: ChartFilterOption,
  coachingMeasureData: CoachingMeasureData,
  dateMembers: any[],
) {
  const dicedCube = coachingMeasureData.dataStream._attendedAsCovisitorDicedSubCube$.getValue();

  if (Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = dicedCube ? dicedCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const uniqActivitiesAttendedAsCovisitorCount = _getAttendedAsCovisitorCount(sliced);
      byMonthData.push(uniqActivitiesAttendedAsCovisitorCount);
    }

    filterOption.data = byMonthData;
  }
}
function _getSelectedDateDimensionMembersInAscendingOrder(measureData: CoachingMeasureData) {
  let selectedDateDimensionMembers = [];
  if (measureData) {
    const selectedDateFilters: number[] = measureData._selectedDateFilters$.getValue();
    const dateDimensionMembers = measureData.dataStream._dateDimensionMembers$.getValue();
    selectedDateDimensionMembers = Array.isArray(dateDimensionMembers) && Array.isArray(selectedDateFilters)
                                          ? dateDimensionMembers.filter(d => selectedDateFilters.some(s => s === d.yearandmonth)) : [];
    selectedDateDimensionMembers.sort((a, b) => a.yearandmonth - b.yearandmonth);
  }
  return selectedDateDimensionMembers;
}
function _updateSelectedTrendChartFilterOptionsData(chartFilterOptions: ChartFilterOption[], coachingMeasureData: CoachingMeasureData, refresh = false) {
  if (Array.isArray(chartFilterOptions) && coachingMeasureData) {
    const selectedDateDimensionMembers = _getSelectedDateDimensionMembersInAscendingOrder(coachingMeasureData);

    for (let i = 0; i < chartFilterOptions.length; i++) {
      const filterOption = chartFilterOptions[i];

      if (filterOption.isSelected && (filterOption.data === undefined || refresh)) {
        try {
          switch (filterOption.value) {
            case CoachingKPI.coVisitorMeetings:
              _assignCoVisitorMeetingsByMonthData(filterOption, coachingMeasureData, selectedDateDimensionMembers);
              break;
            case CoachingKPI.coachingsAcknowledged:
              _assignAckedCoachingsByMonthData(filterOption, coachingMeasureData, selectedDateDimensionMembers);
              break;
            case CoachingKPI.avgCoachingScore:
              _assignAvgCoachingScoreByMonthData(filterOption, coachingMeasureData, selectedDateDimensionMembers);
              break;
            case CoachingKPI.uniqCustMetWithCovisitor:
              _assignUniqCustMetWithCovisitor(filterOption, coachingMeasureData, selectedDateDimensionMembers);
              break;
            case CoachingKPI.attendedAsCovisitor:
              _assignAttendedAsCovisitor(filterOption, coachingMeasureData, selectedDateDimensionMembers);
              break;
            default:
              break;
          }
        } catch (error) {
          console.error('_updateSelectedTrendChartFilterOptionsData: ', error);
        }
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart data subscription
 */
function _trendChartDataSubscription(coachingMeasureData: CoachingMeasureData) {
  const trendChartData = coachingMeasureData.charts.find(c => c.id === CoachingChartId.trend);
  if (trendChartData) {
    coachingMeasureData.subs.push(
      combineLatest([trendChartData.highChartRefReady$, coachingMeasureData.selectedDateFilters$]).pipe(
        filter(([highChartRefReady, selectedDateFilters]) => highChartRefReady && Array.isArray(selectedDateFilters)),
        map(([highChartRefReady, dateDimensionMembers]) => {
          dateDimensionMembers.sort((a, b) => a - b);
          const newXAxisCategories = [];
          for (let i = 0; i < dateDimensionMembers.length; i++) {
            const member = dateDimensionMembers[i];
            const response = getYearMonthFromYYYYMM(member, coachingMeasureData.langCode);
            if (response) {
              newXAxisCategories.push(response.displayText);
            } else {
              console.warn('_trendChartDataSubscription: ', member, response);
            }
          }
          trendChartData._xAxisCategories$.next(newXAxisCategories);
        })
      ).subscribe()
    )

    coachingMeasureData.subs.push(
      combineLatest([
        trendChartData.chartFilterOptions$,
        trendChartData.xAxisCategories$,
      ]).pipe(
        filter(([filterOptions, xAxisCategories]) => Array.isArray(filterOptions) && Array.isArray(xAxisCategories)),
        debounceTime(300),
        map(([chartFilterOptions, xAxisCategories]) => {
          _updateSelectedTrendChartFilterOptionsData(chartFilterOptions, coachingMeasureData, true);

          const xAxis = JSON.parse(JSON.stringify(DefaultChartOptions.xAxis));
          xAxis.categories = xAxisCategories;
          updateHighChartOption(trendChartData.highChartRef, { xAxis }, false, true);

          if (trendChartData.highChartRef) {
            updateHighChartSeries(trendChartData.highChartRef, chartFilterOptions);
            trendChartData._chartUpdated$.next(trendChartData.id);
          }
        })
      ).subscribe()
    );
  }
}
