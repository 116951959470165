import { abort } from "process";

export class AssessmentTemplate {
  public indskr_entityoptions: EntityOptions;
  public indskr_entity: string;
  public indskr_assessmenttemplateid: string;
  public indskr_metadata: Metadata;
  public indskr_name: string;
  public indskr_validfrom: string;
  public indskr_validto: string;
  public statuscode: number;
  public statecode: number;
  public role_statecode: number;
  public _id: string;
  public _rev: string;
  public createdon: string;
  public indskr_type: TemplateType;
  public indskr_surveytype: SurveyType;
  public indskr_surveyfrequency: SurveyFrequency;
  public filterQuery;
  public indskr_customerssurveyed: number;


  constructor(raw) {
    this.indskr_entityoptions = raw['indskr_entityoptions'] ? raw['indskr_entityoptions'] === EntityOptions.CONTACT ? EntityOptions.CONTACT : EntityOptions.ACCOUNT : null;
    this.indskr_assessmenttemplateid = raw['indskr_assessmenttemplateid'];
    if (this.indskr_entityoptions === EntityOptions.CONTACT) {
      this.indskr_entity = 'contact';
    } else if (this.indskr_entityoptions === EntityOptions.ACCOUNT) {
      this.indskr_entity = 'account';
    }
    this.indskr_metadata = raw['indskr_metadata'] ? new Metadata(JSON.parse(raw['indskr_metadata'])) : null;
    this.indskr_name = raw['indskr_name'];
    this.indskr_validfrom = raw['indskr_validfrom'];
    this.indskr_validto = raw['indskr_validto'];
    this.statuscode = raw['statuscode'];
    this.statecode = raw['statecode'];
    this.role_statecode = raw['role_statecode'] ? raw['role_statecode'] : 0;
    this._id = raw['_id'];
    this._rev = raw['_rev'];
    this.indskr_type = raw['indskr_type'];
    this.indskr_surveytype = raw['indskr_surveytype'];
    this.indskr_surveyfrequency = raw['indskr_surveyfrequency'];
    this.createdon = raw['createdon'];
    this.filterQuery = raw['ab.fetchxml'];
    this.indskr_customerssurveyed = raw['indskr_customerssurveyed'];
  }
}

export class Metadata {
  public EntityLogicalName: string;
  public FromDate: string;
  public ToDate: string;
  public Sections: AttributeSection[];
  public EligibleCustomers?: [];
  // public EligibleAccounts?: [];
  public TimeToCompleteSurvey?: number;
  constructor(raw) {
    this.EntityLogicalName = raw['EntityLogicalName'];
    this.FromDate = raw['FromDate'];
    this.ToDate = raw['ToDate'];
    this.Sections = raw['Sections'] ? raw['Sections'].map(section => new AttributeSection(section)) : [];
    this.EligibleCustomers = raw['EligibleCustomers'] ? raw['EligibleCustomers'] : [];
    // this.EligibleAccounts = raw['EligibleCustomers'] ? raw['EligibleCustomers'] : [];
    this.TimeToCompleteSurvey = raw['TimeToCompleteSurvey'] ? raw['TimeToCompleteSurvey'] : 0;
  }
}

export class AttributeSection {
  public DisplayName: string;
  public DisplayNames: {Description: string, LanguageCode: string}[];
  public Order: number;
  public PositionGroup: boolean;
  public Position: boolean;
  public Product: boolean;
  public TherapetuicArea: boolean;
  public Procedure: boolean;
  public SectionProperties: FieldProperties;
  public Controls: Control[];
  constructor(raw) {
    this.DisplayName = raw['DisplayName'];
    this.DisplayNames = raw['DisplayNames'];
    this.Order = raw['Order'];
    this.PositionGroup = raw['PositionGroup'];
    this.Position = raw['Position'];
    this.Product = raw['Product'];
    this.Procedure = raw['Procedure'];
    this.TherapetuicArea = raw['TherapetuicArea'];
    this.SectionProperties = raw['SectionProperties'][0];
    this.Controls = raw['Controls'] ? raw['Controls'].map(control => new Control(control)) : [];

  }
}

export class Control {
  public Attribute: FieldProperties;
  public Order: number;
  public Mandatory: boolean;
  public FlagDeafultvalue: boolean;
  public Override: boolean;
  public Choice: Choice;
  public Description: string;
  public DisplayName: string;
  public Descriptions: {Description: string, LanguageCode: string}[];
  public DisplayNames: {Description: string, LanguageCode: string}[];
  public Languagecode: string;
  public FromDate: string;
  public ToDate: string;
  public Type: AssessmentAttributeType;
  public Access: Access;
  public Searchable: boolean;
  public RangeMin: number;
  public RangeMax: number;
  constructor(raw) {
    this.Attribute = raw['Attribute'];
    this.Order = raw['Order'];
    this.Mandatory = raw['Mandatory'];
    this.FlagDeafultvalue = raw['FlagDeafultvalue'];
    this.Override = raw['Override'];
    this.Description = raw['Description'];
    this.DisplayName = raw['DisplayName'];
    this.Descriptions = raw['Descriptions'];
    this.DisplayNames = raw['DisplayNames'];
    this.Languagecode = raw['Languagecode'];
    this.FromDate = raw['FromDate'];
    this.ToDate = raw['ToDate'];
    this.Choice = null;
    this.Searchable = raw['Searchable'];
    this.RangeMin = raw ['RangeMin'];
    this.RangeMax = raw ['RangeMax'];
    switch (parseInt(raw['Type']['Value'])) {
      case 451680000:
        this.Type = AssessmentAttributeType.TEXT;
        break;
      case 451680001:
        this.Type = AssessmentAttributeType.NUMERIC;
        break;
      case 451680002:
        this.Type = AssessmentAttributeType.TIMESTAMP;
        break;
      case 451680003:
        this.Type = AssessmentAttributeType.MEMO;
        break;
      case 451680004:
        this.Type = AssessmentAttributeType.CHOICE;
        this.Choice = new Choice(raw['Choice']);
        break;
      case 451680005:
        this.Type = AssessmentAttributeType.FLAG;
        break;
      case 600000000:
        this.Type = AssessmentAttributeType.RANGE;
        break;
      default:
        console.error("Unhanled assessment attribute type");
    }
    switch (parseInt(raw['Access']['Value'])) {
      case 548910000:
        this.Access = Access.READ_ONLY;
        break;
      case 548910001:
        this.Access = Access.READ_WRITE;
    }
    if (!this.Access) {
      this.Access = Access.READ_WRITE;
    }
  }
}

export class Choice {
  public RatingScale: FieldProperties;
  public Options: ChoiceOption[];
  public Type: RatingScaleType;
  constructor(raw) {
    this.RatingScale = raw['RatingScale'];
    this.Options = raw['Options'] ? raw['Options'].map(op => new ChoiceOption(op['Label'], op['RatingProperties'], op['Value'],op['DisplayNames'],op['Order'], op['LogicalAttributes'])) : [];
    if (raw['Type'] == '451680000') {
      this.Type = RatingScaleType.SINGLE_SELECT
    } else {
      this.Type = RatingScaleType.MULTI_SELECT
    }
  }
}

export class ChoiceOption {
  constructor(
    public Label: string,
    public RatingProperties: FieldProperties[],
    public Value: string,
    public displayNames: {Description: string, LanguageCode: string}[],
    public Order: string,
    public LogicalAttributes?: LogicalAttributes[]
    ) {

  }
}

export enum RatingScaleType {
  SINGLE_SELECT = 451680000,
  MULTI_SELECT = 451680001
}

export enum EntityOptions {
  CONTACT = 548910001,
  ACCOUNT = 548910000
}

export interface FieldProperties {
  DisplayName: string;
  GUID: string;
  Languagecode: string;
  SchemaName: string;
  AccountType: any;
  ContactType: any;
  Designation: any;
  Specialty: any;
  SectionAttributeProducts: any;
  LogicalAttributes?: LogicalAttributes[];
}

export interface LogicalAttributes {
  Access: any;
  Attribute: string;
  Label: string;
  ParentAttribute: string;
}

export interface AttributesRelationModel {
  sectionId: string;
  controlDisplayName: string;
  controlType: AssessmentAttributeType;
  controlAttributeId: string;
  optionAttributeId: string;
  logicalAttrAccess: any;
  logicalAttributeLabel: string;
  logicalAttrParentAttributeId: string;
  logicalAttrChildAttributeId: string;
}

export enum AssessmentAttributeType {
  TEXT = 451680000,
  NUMERIC = 451680001,
  TIMESTAMP = 451680002,
  MEMO = 451680003,
  CHOICE = 451680004,
  FLAG = 451680005,
  RANGE = 600000000
}

export enum Access {
  READ_ONLY = 548910000,
  READ_WRITE = 548910001
}

export enum TemplateType {
  ASSESSMENT = 600000000,
  SURVEY = 600000001
}

export enum SurveyType {
  INTERNAL = 600000000,
  EXTERNAL = 600000001
}

export enum SurveyFrequency {
  ONCE = 600000000,
  RECURRING = 600000001
}

export enum SurveyToolTab {
  PREVIEW = "preview",
  ACTIVITIES = "activities"
}

export enum SurveyStatus {
  SAVED = 548910000,
  SUBMITTED = 548910001,
}

export enum PreviewMode {
  DEFAULT = "default",
  EDITABLE = "editable",
  READONLY = "readonly",
  READONLY_SUBMITTED = "readonlyAfterSubmitting",
}

export enum InternalSurveyStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export enum SurveyCategory {
  CONTACT = "contact",
  ACCOUNT = "account",
  INTERNAL = "internal"
}
