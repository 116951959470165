<ion-header>
    <ind-page-title [viewData]='{id:"more-plans-insights",title: pageTitle , controls: backButton}' (onControlClick)='closepage()'></ind-page-title>
</ion-header>
<ion-content #Content no-padding color="light" style="height: 84.4%;">
    <div [ngClass]="{'container-ios' : device.deviceFlags.ios}">
        <!--PLANS AND PREDICTIONS-->
        <ion-list>
            <ion-item-group>
                <div *ngIf="x_type === 'plans'" [ngClass]="{ 'min-section-ht' : planItemsCount === 0 }">
                    <ion-item-divider class="list-header" [ngClass]="{'list-header-ios' : device.deviceFlags.ios}" color="light">
                        <ion-button class="xpHeadingText padTop5" icon-only fill="clear" (click)='openPlanInsightFilterPopup("plans")'>
                            <span class="xpFilterStyleHeading"> {{ getTitle(xperiencesService.xpPlanFilterText)  | capitalizeFirstLetter}}</span>
                            <span class="filter-count">({{plans.length}})</span>
                            <ion-icon class="filterIcon" name="caret-down-outline"></ion-icon>
                        </ion-button>
                    </ion-item-divider>
                    <ion-item-sliding #item *ngFor="let plan of plans">
                        <ion-item (click)="displayActivityDetails(plan)" [ngClass]="{'feedback-item': plan.type === 'prediction', 'event-item': plan.activitytypecode === 'event' && plan.event?.isExpanded }" class="pad-20">
                            <div>
                                <div text-left class="plans-predictions">
                                    <img src="assets/imgs/xp_{{plan.type}}.svg" width="20" />
                                    <ion-icon [name]="plan.event?.isExpanded ? 'arrow-up': 'arrow-down'" *ngIf="plan.activitytypecode === 'event'" slot="end" (click)="expandEventDetails($event,plan.event)"></ion-icon>
                                    <h3 text-nowrap class="header">{{ plan.translatedTitle }}</h3>
                                </div>
                                <div text-left class="plans-predictions-secondary-info marginTop5">
                                    <span text-nowrap class='secondaryTextColorAndfont'>
                <ion-row class="marginX">
                    <span class="accountNameText header">{{ getLocalisedPlanDate(plan.date)+  (plan.productsText ? (' - ' + plan.productsText) : '')}}</span>
                                    <ng-container *ngIf="plan.strength">
                                        <ion-thumbnail *ngIf="plan.type !== 'teamplan'" class="left-float strength">
                                            <img src="assets/imgs/xp_{{plan.strength}}_strength_bar.svg" width="20" />
                                        </ion-thumbnail>
                                    </ng-container>
                                    </ion-row>
                                    <ion-row class="marginY" *ngIf="plan.captiontext && plan.captiontext.length > 0">
                                        <span *ngIf="plan.captiontext && plan.captiontext.length > 0">{{plan.captiontext}}</span>
                                        <span *ngIf="plan.relationshipContactText && plan.captiontext">
                       {{ (plan.captiontext.length > 0 ? ' | ' : '') + plan.relationshipContactText}}
                    </span>
                                    </ion-row>
                                    <ion-row class="marginY" *ngIf="plan.type === 'teamplan' && plan.topic && plan.topic.length > 0">
                                        <span>{{plan.topic}}</span>
                                    </ion-row>
                                    <ion-row class="caption-text" *ngIf="plan.contacts && plan.contacts.length > 0">
                                        <span class="marginY">{{plan.contactLabel}}</span>
                                    </ion-row>
                                    <ion-row class="caption-text" *ngIf="feedTopic">
                                        <span class="marginY">{{feedTopic}}</span>
                                    </ion-row>
                                    </span>
                                </div>
                            </div>
                        </ion-item>
                        <ion-item class="event-details" item-content *ngIf="plan.activitytypecode === 'event' && plan.event?.isExpanded">
                            <ng-container *ngTemplateOutlet="eventDetails;context: {eventData: plan.event}"></ng-container>
                        </ion-item>
                        <ion-item-options *ngIf="((plan.type === 'prediction') || (plan.type === 'centralplan'))" side="end">
                            <ion-button (click)="sendPlanFeedback(plan, 'positive')" [ngClass]="{'feedback-done' : plan.feedback_Flag || device.isOffline}">
                                <img src="assets/imgs/feedback_thumbs_up{{(plan.feedback_Flag && (plan.feedback_Flag === 'positive')) ? '_active' : ''}}.svg" />
                            </ion-button>
                            <ion-button (click)="sendPlanFeedback(plan, 'negative')" [ngClass]="{'feedback-done' : plan.feedback_Flag || device.isOffline}">
                                <img src="assets/imgs/feedback_thumbs_down{{(plan.feedback_Flag && (plan.feedback_Flag === 'negative')) ? '_active' : ''}}.svg" />
                            </ion-button>
                        </ion-item-options>
                    </ion-item-sliding>
                </div>
                <!--ALL INSIGHTS-->
                <div *ngIf="x_type != 'plans'" #parentScrolling>
                    <ion-item-divider color="light" class="insights-list-header" [ngClass]="{'insights-list-header-ios' : device.deviceFlags.ios}">
                        <ion-button class="xpHeadingText padTop5" icon-only fill="clear" (click)='openPlanInsightFilterPopup(x_type)'>
                            <span class="xpFilterStyleHeading">
            {{ insightFilterText | capitalizeFirstLetter}}
          </span>
                            <span class="filter-count">
            ({{filteredInsights.length}})
          </span>
                            <ion-icon class="filterIcon" name="caret-down-outline"></ion-icon>
                        </ion-button>
                    </ion-item-divider>
                    <!-- TODO: Add the pipe for sorting the data by date -->
                    <!--  -->
                    <div class="journey-insights" *ngIf="x_type === 'journey'">
                        <ion-item-sliding #item *ngFor="let insight of visibleInsights; let i=index">
                            <ion-item class="interest-item">
                                <div class="description" (click)="displayActivityDetails(insight)">
                                    <div text-left class="title">
                                        <h3 text-nowrap class="header insight-title">{{ insight.translatedTitle }}</h3>
                                    </div>
                                    <div text-left class="marginTop5">
                                        <span text-nowrap class='secondaryTextColorAndfont'>
              <ion-row class="marginX">
                  <span
                    class="accountNameText secondary-info">{{ getLocalisedInsightsDate(insight.date) + (insight.productsText ? (' - ' + insight.productsText) : '')}}
                  </span>
                                        </ion-row>
                                        <ion-row *ngIf="insight.journeyInsightsText" class="marginX">
                                            <span>
                    {{ insight.journeyInsightsText }}
                  </span>
                                        </ion-row>
                                        </span>
                                    </div>
                                </div>
                            </ion-item>
                            <ion-item-options *ngIf="x_type !== 'journey'" side="end">
                                <ion-button (click)="sendFeedback(insight, true)" [ngClass]="{'feedback-done' : insight.feedback_Flag || device.isOffline}">
                                    <img src="assets/imgs/feedback_thumbs_up{{(insight.feedback_Flag && (insight.feedback_Flag === 'positive')) ? '_active' : ''}}.svg" />
                                </ion-button>
                                <ion-button (click)="sendFeedback(insight, false, i)" [ngClass]="{'feedback-done' : insight.feedback_Flag || device.isOffline}">
                                    <img src="assets/imgs/feedback_thumbs_down{{(insight.feedback_Flag && (insight.feedback_Flag === 'negative')) ? '_active' : ''}}.svg" />
                                </ion-button>
                            </ion-item-options>
                        </ion-item-sliding>
                    </div>
                    <div class="insights" *ngIf="(x_type === 'interest' || x_type === 'relationship')">
                        <ion-item-sliding #item *ngFor="let insight of visibleInsights; let i=index">
                            <ion-item class="interest-item" [ngClass]="{'feedback-item': x_type === 'interest'}">
                                <div class="item-container" (click)="displayActivityDetails(insight)">
                                    <div class="relationship-insights" *ngIf="x_type === 'relationship'">
                                        <div text-left class="padTop relationship-header">
                                            <h3 text-nowrap class="header">{{ insight.fullname }}</h3>
                                            <ion-badge class="badge-ind">{{getOrdinalSuffix(insight.degree)}}</ion-badge>
                                        </div>
                                        <div text-left class="marginTop5">
                                            <span text-nowrap class='secondaryTextColorAndfont'>
                        <ion-row class="marginX" *ngIf="insight.latestInsight && insight.latestInsight.relationship_insight_type">
                          <span class="accountNameText secondary-info header">{{insight.latestInsight.relationship_insight_type}}</span>
                                            </ion-row>
                                            <ion-row class="marginX insight-sources" *ngIf="insight.sourcesText">
                                                <span class="accountNameText header insight-sources-text">{{insight.sourcesText}}</span>
                                            </ion-row>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="interest-insights" *ngIf="x_type === 'interest'">
                                        <div text-left class="padTop relationship-header">
                                            <h3 text-nowrap class="header title">{{ insight.title }}</h3>
                                            <ion-badge class="badge-ind" *ngIf="insight.strength">{{getSourceStrength(insight.strength)}}</ion-badge>
                                        </div>
                                        <div text-left class="marginTop5">
                                            <span text-nowrap class='secondaryTextColorAndfont'>
                        <ion-row class="marginX" *ngIf="insight.latestInsight && insight.latestInsight.category">
                          <span class="accountNameText secondary-info header">{{insight.latestInsight.category}}</span>
                                            </ion-row>
                                            <ion-row class="marginX" *ngIf="insight.sourcesText">
                                                <span class="accountNameText header interest-insights-sources">{{insight.sourcesText}}</span>
                                            </ion-row>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ion-item>
                            <ion-item-options side="end" *ngIf="xperiencesService.segmentType !== segmentType.FEED">
                                <ion-button (click)="sendFeedback(insight, true)" [ngClass]="{'feedback-done' : insight.feedback_Flag || device.isOffline}">
                                    <img src="assets/imgs/feedback_thumbs_up{{(insight.feedback_Flag && (insight.feedback_Flag === 'positive')) ? '_active' : ''}}.svg" />
                                </ion-button>
                                <ion-button (click)="sendFeedback(insight, false, i)" [ngClass]="{'feedback-done' : insight.feedback_Flag || device.isOffline}">
                                    <img src="assets/imgs/feedback_thumbs_down{{(insight.feedback_Flag && (insight.feedback_Flag === 'negative')) ? '_active' : ''}}.svg" />
                                </ion-button>
                            </ion-item-options>
                        </ion-item-sliding>
                    </div>
                    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="30%">
                        <ion-infinite-scroll-content></ion-infinite-scroll-content>
                    </ion-infinite-scroll>
                    <!--  -->
                </div>
            </ion-item-group>
        </ion-list>
    </div>
    <ng-template #eventDetails let-event="eventData">
        <ion-row class="row event-name">
            <h3>{{event.eventName}}</h3>
        </ion-row>
        <ion-row class="row">
            <h4 class="event-info">
                <span class="ellipsis event-start-date">
        {{'ON' | translate | titlecase }} {{event.eventStartDate | customDate:'DATE_TIME'}}
      </span>
                <span class="ellipsis">
        {{ 'CONTACT_REGISTERED_BY_OWNER' | translate : {text: event.registeredBy} }}
      </span>
            </h4>
        </ion-row>
        <expandable [viewType]="'eventDetails'" [customerEvent]="{ event:event }" [expandHeight]="'auto'" [expanded]="true">
        </expandable>
    </ng-template>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'" [fromWhichView]="'Xperience'"></footer-toolbar>
<!-- </ion-footer> -->