export enum ShipmentStatus {
  Shipped = 548910000,
  Acknowledged = 548910001,
  Open = 1, // This should only be used for local form status
  Draft = 600000000,
  ForReview = 600000001,
  NotApproved = 600000002
}
export enum AdjustmentStatus {
  Open = 548910002,
  InReview = 548910003,
  Approved = 548910004,
}
export enum TransferType {
  AllocationShipment = 1,
  AllocationTransfer = 548910000,
  AllocationAdjustment = 548910004,
}

export enum ShipmentDataSyncStateId {
  AllocationPage,
  PlanListPage,
}
