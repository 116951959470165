import { ApprovalStatus } from "../quotes/quotes.class";
import { CustomerListPeriod } from "./customer-list-period.class";
import { PositionListTarget } from "./position-list-target.class";

export enum CustomerPositionListType {
  ACCOUNT = 600000000,
  CONTACT = 600000001
}

export class CustomerPositionList {
  public indskr_customerpositionlistid: string;
  public createdon: string;
  public modifiedon: string;
  public indskr_type: CustomerPositionListType;
  public indskr_ttag: string;
  public positionId: string;
  public positionName: string;
  public ownerid: string;
  public indskr_netchange: number;
  public indskr_percentagechange: number;
  public indskr_numberadded: number;
  public indskr_numberremoved: number;
  public contact: PositionListTargetContact;
  public accounts: Account[];
  public approvalStatus: number;
  public listPeriod: CustomerListPeriod;
  public positionListTargets: PositionListTarget[];
  public statecode: number;
  public statuscode: number;
  public approvalActivityId: string;
  public approvalActivityStatusCode: ApprovalStatus;
  public approvalActivityCreatedOn: string;
  public approvalActivityModifiedOn: string;
  public ownerName: string;
  constructor(raw) {
    this.indskr_customerpositionlistid = raw['indskr_customerpositionlistid'];
    this.createdon = raw['createdon'];
    this.modifiedon = raw['modifiedon'];
    this.indskr_type = raw['indskr_type'];
    this.positionId = raw['position'];
    this.positionName = raw['position@OData.Community.Display.V1.FormattedValue'];
    this.ownerName = raw['ownerId@OData.Community.Display.V1.FormattedValue'];
    this.ownerid = raw['ownerId']
    this.indskr_netchange = raw['indskr_netchange'] || 0;
    this.indskr_percentagechange = raw['indskr_percentagechange'] || 0;
    this.indskr_numberadded = raw['indskr_numberadded'] || 0;
    this.indskr_numberremoved = raw['indskr_numberremoved'] || 0;
    this.accounts = raw['accounts'];
    this.contact = raw['contact'];
    this.listPeriod = raw['listPeriod'];
    this.approvalStatus = raw['indskr_approvalstatus'] || 548910000;
    this.positionListTargets = raw['positionListTargets'] || [];
    this.statecode = raw['statecode'] || 0;
    this.statuscode = raw['statuscode'] || 1;
    this.approvalActivityId = raw['approvalActivityId'];
    this.approvalActivityStatusCode = raw['approvalActivityStatusCode'];
    this.approvalActivityCreatedOn = raw['approvalActivityCreatedOn'];
    this.approvalActivityModifiedOn = raw['approvalActivityModifiedOn'];
  }
}

export enum StatusCode {
  OPEN = 1,
  FOR_REVIEW = 2,
  APPROVED = 600000001,
  EXPIRED = 600000002,
  CANCELED = 600000003
}

export class PositionListTargetContact {
  constructor(public id: string,
    public name: string,
    public accounts: Account[]) {

  }
}

export class Account {
  constructor(public id: string,
    public name: string) {

  }
}

export class ApprovalActivity {
  public indskr_approvalactivityid: string;
  public indskr_cycle: number;
  public statuscode: ApprovalStatus;
  public indskr_reason: string;
  public createdon: string;
  public createdOnFormatted: string;
  public statusCodeFormatted: string;
  public approver: string;
  constructor(raw) {
    this.indskr_approvalactivityid = raw['indskr_approvalactivityid'];
    this.indskr_cycle = raw['indskr_cycle'];
    this.statuscode = raw['statuscode'];
    this.indskr_reason = raw['indskr_reason'];
    this.createdon = raw['createdon'];
    this.createdOnFormatted = raw['createdOnFormatted'];
    this.statusCodeFormatted = raw['statusCodeFormatted'];
    this.approver = raw['ownerId@OData.Community.Display.V1.FormattedValue']
  }
}