<!-- <p class='section-heading-text'>{{viewData.sectionHeader}}</p> -->
<!-- <div class="container-h dataWrapper"> -->
  <!-- <div class="container-c iconContainer">
    <img [src]="viewData.iconImg" width="35"  height="35">
  </div> -->
  <!-- <ind-report-tile  *ngFor="let tile of viewData.tiles" [viewData]="tile"></ind-report-tile> -->
<!-- </div> -->
<!-- <div class="container-h moreInfoFooter">
  <img src="assets/imgs/bulb.svg" width="35"  height="35">
  <p>{{viewData.moreInfoText}}</p>
  <div class="container-v endIcon">
    <ion-icon name="ios-arrow-forward"></ion-icon>
  </div>
</div> -->

<ion-row class="data-raw">
  <ion-col class="dataWrapper" size="12" size-md="4" siz-lg="4" *ngFor="let tile of viewData.tiles">
    <ind-report-tile  [viewData]="tile"></ind-report-tile>
  </ion-col>
</ion-row>
