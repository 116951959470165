export class User {
    public id: string;
    public fullName: string;
    public firstName: string;

    constructor(raw: object) {
      this.id = raw['userid'];
      this.fullName = raw['userfullname'];
      this.firstName = raw['firstName'];
    }
  }
