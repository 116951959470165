

export class SourceTypeOptionset {
  constructor(
    public value: number,
    public label: string,
    public mdmOptionSet: MDMOptionSet[]
  ) {}
}


export class MDMOptionSet {
  constructor(
    public value: number,
    public label: string
  ) {
  }
}

export enum ChangeRequestType {
  BUSINESS = 548910000,
  MDM= 548910001,
}

export enum MDMType {
  ONEKEY = 548910000,
  VEEVA = 548910001
}

export enum ChangeRequestStatusCode {
  OPEN = 1,
  IN_PROGRESS = 548910004,
  SUBMITTED = 548910005,
  APPROVED = 548910006,
  REJECTED = 2,
  PENDING_APPROVAL = 548910009,
  FAILED = 100000001
}

export enum MDMRequestType {
  CREATE = 548910000,
  UPDATE = 548910001,
  ADD_AFFILIATION = 808210000
}

export enum ChangeRequestStateCode {
  ACTIVE = 0,
  INACTIVE = 1,
}
