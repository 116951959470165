import { PopoverController } from '@ionic/angular';
import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { ChildUsersPageComponent } from '../child-users-page/child-users-page';
import { Events } from '@omni/events';
import { ActivityService } from '../../services/activity/activity.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from "../../services/global-utility.service";
import { DeviceService } from '@omni/services/device/device.service';

/**
 * Generated class for the ActivitiesFilterPopoverComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'activities-filter-popover',
  templateUrl: 'activities-filter-popover.html',
  styleUrls:['activities-filter-popover.scss']
})
export class ActivitiesFilterPopoverComponent {

  text: string;
  expanded: boolean = true;
  hasTimeoffEnabled: boolean;
  hasJointMeetingEnabled: boolean;
  hasSamplingEnabled: boolean;
  activityTypeNameText: string;
  activityTypes: {
    activityTypeName: string,
    activityTypeValue: string
  }[]
  teamViewEnabled: boolean

  constructor(
    public authService: AuthenticationService,
    public navService: NavigationService,
    public popoverCtrl: PopoverController ,
    public events: Events,
    public activityService: ActivityService,
    public trackingService: TrackService,
    private translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public device: DeviceService,
  ) {
    this.teamViewEnabled = this.authService.hasFeatureAction(FeatureActionsMap.TEAMVIEW_FILTER)
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        this.activityTypeNameText = this.translate.instant('STAKEHOLDER');
        break;
      case 'Contact':
        this.activityTypeNameText = this.translate.instant('CONTACT');
        break;
      case 'Customer':
        this.activityTypeNameText = this.translate.instant('CUSTOMER');
        break;
      default:
        this.activityTypeNameText = this.utilityService.globalCustomerText;
        break;
    }
    this.activityTypes = [
      {
        activityTypeName: this.translate.instant('ALL_ACTIVITIES'),
        activityTypeValue: 'All Activities'
      },
      {
        activityTypeName: this.translate.instant('MEETINGS'),
        activityTypeValue: 'Appointment'
      },
      {
        activityTypeName: this.translate.instant('LIVETIME_MEETINGS'),
        activityTypeValue: 'RemoteMeeting'
      }
    ]
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('MESSAGES'),
        activityTypeValue: 'Email'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('PHONE_CALLS'),
        activityTypeValue: 'PhoneCall'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('TIME_OFF'),
        activityTypeValue: 'TimeOff'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.JOINT_MEETING)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('JOIN_MEETING'),
        activityTypeValue: 'Joint Meeting'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) && this.authService.hasFeatureAction(FeatureActionsMap.JOINT_PHONE_CALL)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('JOINT_PHONE_CALLS'),
        activityTypeValue: 'JointPhoneCall'
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('ALLOWCATION_ORDERS'),
        activityTypeValue: 'Sample'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('FOLLOW_UP_ACTIONS'),
        activityTypeValue: 'followuptask'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS'),
        activityTypeValue: 'indskr_order'
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT',{globalCustomerText:this.activityTypeNameText}),
        activityTypeValue: 'CaseIntake'
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.LIVEMEET)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('LIVE_MEETING'),
        activityTypeValue: 'LiveMeet'
      })
    }
    if (this.authService.user.hasProcedureLog || this.authService.user.hasBulkProcedureLog) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('PROCEDURE_LOGS'),
        activityTypeValue: 'SurgeryOrder'
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.STORE_CHECK)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('STORE_CHECKS'),
        activityTypeValue: 'StoreCheck'
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) {
      this.activityTypes.push({
        activityTypeName: this.translate.instant('KIT_BOOKINGS'),
        activityTypeValue: 'SetBooking'
      })
    }

  }
  ngOninit() {

  }

  onActivityTypeSelection(type: string) {
    if (this.activityService.activityFilter == type) return
    this.events.publish('filterActivitiesDataForSelectedUser', type);
  }

  goToUserSelection() {
    if(!this.device.isOffline){
      this.popoverCtrl.dismiss();
      this.trackingService.tracking('UserFilterClicked', TrackingEventNames.ACTIVITY, true);
      this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'ActivtiesPageFilter' })
    }
  }

}
