<ion-header>
    <ion-toolbar *ngIf="viewData && viewData.title" class="title-toolbar">
        <ion-title class="ion-text-center lookup-popover-title"> {{viewData.title}} </ion-title>
    </ion-toolbar>
    <ion-toolbar *ngIf="viewData && viewData.isSearchEnabled" class="search-toolbar">
        <ion-searchbar #searchbar 
            [debounce]="debounceTime" 
            (ionChange)="searchText($event)"
            placeholder="{{'SEARCH' | translate}}">
        </ion-searchbar>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-scroll class="lookup-options-list-scroll">
        <ion-list class="lookup-options-list">
            <ion-item *ngIf="dynamicFormService.lookupSearchInProgress" class="lookup-search-progress-spinner">
                <ind-block-level-loader *ngIf="dynamicFormService.lookupSearchInProgress"></ind-block-level-loader>
            </ion-item>
            <ion-item
                *ngIf="viewData && viewData.showUnselectOption && dynamicFormService.lookupSearchData && dynamicFormService.lookupSearchData.length != 0"
                (click)="clearValue()" class="unselect-option">
                <ion-label>{{viewData.unselectOptionText}}</ion-label>
                <ion-icon slot="end" color="primary" name="checkmark" *ngIf="!viewData.selectedValue"></ion-icon>
            </ion-item>
            <ion-item *ngFor="let val of dynamicFormService.lookupSearchData" (click)="setSelectedLookupValue(val)" class="selectable-option">
                <ion-label>{{val.name}}</ion-label>
                <ion-icon slot="end" color="primary" name="checkmark" *ngIf="viewData.selectedValue == val.id"></ion-icon>
            </ion-item>
            <ion-item *ngIf="dynamicFormService.lookupSearchData.length == 0 && !dynamicFormService.lookupSearchInProgress"
                class="no-results-option">
                <ion-label>{{'NO_RESULTS' | translate}}</ion-label>
            </ion-item>
        </ion-list>
    </ion-scroll>
</ion-content>

