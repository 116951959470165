import { ErrorCode } from './../../enums/exception/general-error-codes.enum';
export class GeneralException {
  name: string;
  code: ErrorCode;
  message: string;

  constructor(code: ErrorCode, message: string = '', name: string = '') {
    this.name = name;
    this.code = code;
    this.message = message;
  }
}
