<ion-grid no-padding [ngClass]="(isRightPaneNavActive)?'preview-active':'preview-inactive'">
    <ion-row no-padding>
        <ion-col class="select-list-left-pane" [ngClass]="(viewData.isSearchEnabled && !readOnly)?'searchActive':'searchInactive'" no-padding>
            <ion-header>
              <ind-header-left  [viewData]="indHeaderLeftModel"
                                [hideFilterButton]="true"
                                [searchDisabled]="!viewData.isSearchEnabled || readOnly"
                                [(searchText)]="searchInput"
                                (onControlClick)="onPageTitleControlClick($event)"
                                (onSearchInput)="searchText($event.detail)"
                                ></ind-header-left>

            </ion-header>
            <ion-content [ngClass]="{'vectorimageleft' : listData.length === 0 && subList?.length === 0}">
                <ion-list *ngIf="viewData" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                    <ind-section-header *ngIf="selectedItems.length > 0" [viewData]='{id:"selecteded_items",title: translate.instant("SELECTED")+" ("+selectedItems.length+")"}'></ind-section-header>
                    <ion-item-group *ngFor="let item of selectedItems">
                      <ion-item class='selector-item'>
                        <ion-label> {{item.title}} </ion-label>
                        <ion-icon slot="end" class="end-icon remove-icon" *ngIf="item.isSelected && !readOnly && !item.isReadOnly" (click)="deselectItem(item)">
                        </ion-icon>
                      </ion-item>
                      <div class="separationLine"></div>
                      <!-- <ng-template [ngTemplateOutlet]="selectorItem" [ngTemplateOutletContext]="{$implicit:item}"></ng-template> -->
                    </ion-item-group>
                    <ng-container *ngIf="!readOnly">
                        <ind-section-header [viewData]='{id:"items_list_header",title: (!searchActive? ((subList?.length > 0 && listData.length == 0) ? (viewData.subDividerTitle + " ("+subList.length+")") :
                          translate.instant(viewData.dividerTitle)+" ("+(showCompetitorProduct ? listData.length -1 : listData.length)+")")
                          :translate.instant("AL_RESULTS")+" ("+listData.length+")")}'></ind-section-header>
                        <ion-item-group>
                            <p *ngIf="!searchActive && listData.length == 0 && subList?.length == 0" justify-content-center class="no-data-message">
                                {{ 'NO' | translate}} {{((viewData)?viewData.title:'' )| lowercase}}
                            </p>
                        </ion-item-group>
                        <ng-container *ngFor="let item of listData">
                            <ind-section-header *ngIf="item.id === 'indskr_competitorproduct' && !searchInput" [viewData]='{id:"other_product",title: translate.instant("XPERIENCES_OTHER_PRODUCT")+" ("+1+")"}'></ind-section-header>
                            <ng-template [ngTemplateOutlet]="selectorItem" [ngTemplateOutletContext]="{$implicit:item}"></ng-template>
                        </ng-container>
                       <ng-container *ngIf="!searchActive && subList && subList.length > 0">
                          <ind-section-header *ngIf="listData.length > 0" [viewData]='
                                                {id:"subListHeader",
                                                title: viewData.subDividerTitle + " ("+subList.length+")"}'></ind-section-header>
                          <ng-container *ngFor="let item of subList">
                            <ng-template [ngTemplateOutlet]="selectorItem" [ngTemplateOutletContext]="{$implicit:item}"></ng-template>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                </ion-list>
                <ind-no-search-result *ngIf="searchInput && listData.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
            </ion-content>
            <ng-template #selectorItem let-item>
              <ion-item class='selector-item'>
                <ion-label> {{item.title}} </ion-label>
                <ion-icon slot="end" class="end-icon" [ngClass]="{'add-icon':!item.isSelected, 'checkmark-icon':item.isSelected}"
                  (click)="selectItem(item)"></ion-icon>
                  </ion-item>
                  <div class="separationLine"></div>
            </ng-template>
            <!-- <ion-footer> -->
                <footer-toolbar [footerView]="'masterView'" [selectedView]="'select-list'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>
        <ion-col class="select-list-right-pane" no-padding>
            <nothing-selected-view [textToDisplay]="''" [notShowText]="true" style="background-color: aqua;" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
        </ion-col>
    </ion-row>
</ion-grid>
