import { IndReportTileDataModel } from './../../../models/IndReportTileDataModel';
import { Component, Input } from '@angular/core';

/**
 * Generated class for the IndReportTileComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-report-tile',
  templateUrl: 'ind-report-tile.html',
  styleUrls:['ind-report-tile.scss']
})
export class IndReportTileComponent {

  @Input()  viewData: IndReportTileDataModel;

  constructor() {

  }

}
