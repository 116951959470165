<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [searchDebounce]="500" [hideFilterButton]="hideFilterButton"
    (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onSearch()" [(searchText)]="searchInput"
    (onSearchClear)="onCancel()"></ind-header-left>
</ion-header>


<ion-content>
  <ng-container *ngIf="selectedItems.length > 0">
    <ind-section-header [viewData]="selectedHeader"></ind-section-header>
    <main-card *ngFor="let item of selectedItems" [viewData]="item"></main-card>
  </ng-container>
  <ng-container *ngIf="procedureContractsToDisplay.length > 0; else noResult">
    <ion-list class="plans-list">
      <main-card *ngFor="let item of procedureContractsToDisplay" [viewData]="item"></main-card>
    </ion-list>
  </ng-container>

  <ng-template #noResult>
    <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  </ng-template>

</ion-content>