import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {

  @Input() rating = 0;
  @Output() ratingChanged: EventEmitter<number>;
  @Input() readonly: boolean;
  @Input() activeColor: string;
  @Input() defaultColor: string;
  @Input() activeIcon: string;
  @Input() defaultIcon: string;
  @Input() halfIcon: string;
  @Input() halfStar: boolean;
  @Input() maxRating: number;
  @Input() fontSize: string;
  iconsArray: number[];
  Math: Math;
  onChange: any;
  onTouched: any;

  constructor() {
    this.ratingChanged = new EventEmitter();
    this.readonly = false;
    this.activeColor = '#488aff';
    this.defaultColor = '#aaaaaa';
    this.activeIcon = 'star';
    this.defaultIcon = 'star-sharp';
    this.halfIcon = 'star-half';
    this.halfStar = false;
    this.maxRating = 5;
    this.fontSize = '28px';
    this.iconsArray = [];
    this.Math = Math;
  }

  ngOnInit() {
 //default after input`s initialization
    for (let i = 0; i < this.maxRating; i++) {
      this.iconsArray.push(i);
    }
  }

  writeValue(obj) {
    this.rating = obj;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled) {
    this.readonly = isDisabled;
  }
  rate(index: number) {
    // function used to change the value of our rating
    // triggered when user, clicks a star to change the rating

    this.rating = index;
    this.ratingChanged.emit(this.rating);
  }

  changeRating(event) {
    if (this.readonly === true){
      return;
    }
    const id = event.target.id ? parseInt(event.target.id) : parseInt(event.target.parentElement.id);
    if (this.halfStar ) {
      this.rating = ((this.rating - id > 0) && (this.rating - id <= 0.5)) ? id + 1 : id + .5;
    } else {
      this.rating = id + 1;
    }
    // unique event
    this.ratingChanged.emit(this.rating);
  }
}


export enum STAR_COLOR_ENUM {
  GREY = '#C3C3C3',
  YELLOW = '#FFCA28'
}
