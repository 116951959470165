import { AuthenticationService } from './../../../services/authentication.service';
import { TencentService } from './../../../services/video-streaming/tencent.service';
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { SafePipe } from '../../../pipes/safe.pipe';
import { OpentokService } from '@omni/services/video-streaming/opentok.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';

@Component({
  selector: 'io-participant-carousel',
  templateUrl: 'io-participant-carousel.component.html',
  styleUrls: ['io-participant-carousel.component.scss'],
  providers: [SafePipe],
})
export class IOParticipantCarousel {
  @Output() onButtonClick = new EventEmitter();

  public tencentEnabled: boolean = false;
  @Input()
  public hideContentToggle: boolean = false;

  constructor(
    public opentok: OpentokService,
    public tencentService: TencentService,
    private readonly authService: AuthenticationService
  ) {
    this.tencentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING);
   }

  get participantCount() {
    return this.tencentEnabled ? (this.tencentService.isInitilized ? this.tencentService.remoteStreamConfigList.length + 1 : 0) : this.opentok.streams.length + 1;
  }
}
