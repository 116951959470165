/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivityService } from '../../services/activity/activity.service';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { IoEventListener } from '@omni/services/io-event-listener.decorator';
import { OpentokService } from '../../services/video-streaming/opentok.service';
import { DeviceService } from '@omni/services/device/device.service';

@Component({
  selector: 'ot-subscriber',
  templateUrl: 'ot-subscriber.component.html',
  styleUrls: ['ot-publisher.component.scss'],
})
export class OTSubscriberComponent implements OnInit {
  @Input() stream: OT.Stream;
  @ViewChild('videoContainer', { static: true }) videoContainer: ElementRef;
  name: string;
  initials: string;
  @HostBinding('class.is-covisitor') isCovisitor: boolean;
  private _subscriber: OT.Subscriber;
  @HostBinding('style.order') get displayOrder() {
    return this.isCovisitor ? 2 : this._talking ? 3 : 4;
  }

  private _talking = false;
  @HostBinding('class.talkingiOS') get talkingiOS() {
    return this._talking && this.device.deviceFlags.nativeIOS;
  }
  @HostBinding('class.talkingNoniOS') talkingNoniOS() {
    return this._talking && !this.device.deviceFlags.nativeIOS;
  }

  private _hasVideo = false;
  private _hasAudio = false;
  private _timestamp: number;

  @HostBinding('class.has-video')
  get hasVideo(): boolean {
    return this._hasVideo;
  }

  @HostBinding('class.has-audio') get hasAudio(): boolean {
    return this._hasAudio;
  }

  private get contactId() {
    return this.stream?.name;
  }

  constructor(
    private readonly opentok: OpentokService,
    private readonly el: ElementRef,
    private readonly activityService: ActivityService,
    public injector: Injector,
    private readonly _ngZone: NgZone,
    private readonly device: DeviceService
  ) {}

  ngOnInit() {
    this.updateContactName();

    if (this.stream.hasAudio) {
      this._hasAudio = true;
    }
    if (this.stream.hasVideo) {
      this._hasVideo = true;
    }
    const subscriber = (this._subscriber = this.opentok.session?.subscribe(
      this.stream,
      this.videoContainer.nativeElement,
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        subscribeToAudio: true,
        subscribeToVideo: true,
        preferredResolution: { width: 150, height: 79 },
        style: {
          buttonDisplayMode: 'off',
          audioLevelDisplayMode: 'off',
          nameDisplayMode: 'off',
        },
      }
    ));

    let isAndroid = this.device.isAndroid();

    if (isAndroid) {
      subscriber.restrictFrameRate(true);
    }
    subscriber.setPreferredResolution({ width: 320, height: 180 });
    subscriber.setPreferredFrameRate(7);

    subscriber.on('audioLevelUpdated', (event) => {
      this._ngZone.run(() => {
        const now = Date.now();
        const oldValue = this._talking;
        if (event.audioLevel > 0.2) {
          this._talking = true;
          this._timestamp = now;
        } else if (!this._timestamp || now - (this._timestamp || 0) > 3000) {
          this._talking = false;
        }
        if (this._talking !== oldValue) this.opentok.updateViews();
      });
    });
  }

  ngOnDestroy() {
    if (this._subscriber) this.opentok.session?.unsubscribe(this._subscriber);
  }

  private updateContactName() {
    const contactId = this.contactId;
    if (!contactId) return;
    const activity = this.activityService.getActivityByID(
      this.activityService.selectedActivity.ID
    ) as AppointmentActivity;
    const joinedContact = activity.contacts.find(
      (contact) => contact.ID === contactId
    );

    this.isCovisitor = false;

    if (joinedContact === undefined) {
      const accompainedContact = activity.accompaniedUserList.find(
        (acc) => acc.id === contactId
      );
      if (accompainedContact !== undefined) {
        this.name = accompainedContact.name;
        this.isCovisitor = true;
      }
    }

    // console.log(joinedContact);
    if (joinedContact) {
      this.name = joinedContact.fullName ? joinedContact.fullName : '';
      this.isCovisitor = joinedContact.isCovisitor;
    }
    if (this.name) {
      const names = this.name.split(' ');
      this.initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        this.initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
    }
  }

  @IoEventListener('opentok-session-streamPropertyChanged')
  onStreamPropertyChanged(event) {
    if (this.stream?.streamId !== event.stream?.streamId) return;
    switch (event.changedProperty) {
      case 'hasAudio':
        this._hasAudio = event?.newValue;
        if (!this._hasAudio) {
          this._talking = false;
        }
        break;
      case 'hasVideo':
        this._hasVideo = event?.newValue;
        break;
    }
    this.opentok.updateViews();
  }

  handleUnmuteClicked() {
    this.opentok.sendSignal({
      data: `${this.contactId}_mute`,
    });
  }
}
