<!-- Generated template for the MenuFooterComponent component -->
<ion-footer>
    <ion-grid no-padding>
      <ion-row>
        <ion-col class="col-6">
             <ion-label>{{ 'STATUS' | translate}}</ion-label>   
         </ion-col>
        <ion-col class="col-6" text-right>
            <span>
                <ion-label (tap)="presentPopover($event)">{{title}}
                    <ion-icon  name="arrow-dropdown"></ion-icon>
                </ion-label>
            </span>
          </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="col-6">
             <ion-label>{{admin}}</ion-label>   
         </ion-col>
        <ion-col class="col-6" text-right>
            <span>
                <ion-label>
                    <ion-icon  name="exit"></ion-icon>
                </ion-label>
            </span>
          </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>

 