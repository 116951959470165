<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list>
    <ind-section-header [viewData]='{id:"appeal-details",title: translate.instant("DETAILS")}'>
    </ind-section-header>
    <ion-row>
      <ion-col size="6" size-lg="4">
        <ind-form-field [viewData]="accountFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6" size-lg="4">
        <ind-form-field [viewData]="locationFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6" size-lg="4">
        <ind-form-field [viewData]="statusFormField"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="selectedMode != 1" size="6" size-lg="4">
        <ind-form-field [viewData]="repFormField"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="selectedMode == 1" size="6" size-lg="4">
        <ind-form-field [viewData]="approverFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6" size-lg="4">
        <ind-form-field [viewData]="positionFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6" size-lg="4">
        <ind-form-field [viewData]="sentForApprovalFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6" size-lg="4">
        <ind-form-field [viewData]="reasonFormField"></ind-form-field>
      </ion-col>
    </ion-row>
    <ind-section-header [viewData]='{id:"photos",title: translate.instant("CHECK_IN_CHECK_OUT_PHOTOS_CAP")}'>
    </ind-section-header>
    <ion-row>
      <ion-col size="6">
        <ind-form-field [viewData]="checkInCoFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6">
        <ind-form-field [viewData]="checkOutCoFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6">
        <ind-form-field [viewData]="checkInStatusFormField"></ind-form-field>
      </ion-col>
      <ion-col size="6">
        <ind-form-field [viewData]="checkOutStatusFormField"></ind-form-field>
      </ion-col>
    </ion-row>
    <div class="separationLine"></div>
    <div class="photo-container">
      <div class="table">
        <ion-row>
          <ion-col size="6">
            <div class="table-header">
              <div class="col">{{'CHECK_IN_PHOTOS' | translate}}</div>
            </div>
            <div *ngFor="let pt of checkInPhotoAttachments">
              <div class="table-row">
                <div class="col photo-card" (click)="openPhotoViewerForGPSActivity(pt, 'CHECKIN')">
                  <img class="thumbnail" io-thumbnail [src]="pt.photos[0].indskr_photourl" />
                  <div class="count-badge" *ngIf="pt.photos.length > 1"> {{pt.photos.length}}</div>
                </div>
              </div>
            </div>
          </ion-col>
          <ion-col size="6" class="verticalSeparationLine">
            <div class="table-header">
              <div class="col">{{'CHECK_OUT_PHOTOS' | translate}}</div>
            </div>
            <div *ngFor="let pt of checkOutPhotoAttachments">
              <div class="table-row">
                <div class="col photo-card" (click)="openPhotoViewerForGPSActivity(pt, 'CHECKOUT')">
                  <img class="thumbnail" io-thumbnail [src]="pt.photos[0].indskr_photourl" />
                  <div class="count-badge" *ngIf="pt.photos.length > 1"> {{pt.photos.length}}</div>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <div class="separationLine"></div>
  </ion-list>
</ion-content>
<footer-toolbar #footer *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'appealDetails'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>