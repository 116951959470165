import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IonNav, LoadingController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { EmailActivity, EmailViewType } from '@omni/classes/activity/email.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { Channel } from '@omni/classes/consent/channel.class';
import { Contact } from '@omni/classes/contact/contact.class';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { CallPlanDataService } from '@omni/data-services/call-plan/call-plan.data.service';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import { EventsToolDataService } from "@omni/data-services/event/events-tool.data.service";
import { AuthenticationService } from '@omni/services/authentication.service';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { ConsentService } from '@omni/services/consent/consent.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DynamicFormsService } from "@omni/services/dynamic-forms/dynamic-forms-service";
import { EmailService } from '@omni/services/email-templates/email.service';
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";
import { SampleService } from '@omni/services/sample/sample.service';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { format, getDate, isValid } from "date-fns";
import _ from "lodash";
import { Activity, ActivityType, MeetingActivityState } from "../../../classes/activity/activity.class";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { ActivityDataService } from "../../../data-services/activity/activity.service";
import { CaseManagementDataService } from "../../../data-services/case-management/case-management.data.service";
import { ActivityService } from "../../../services/activity/activity.service";
import { AccesingMode, CaseManagementService } from "../../../services/case-management/case-management.service";
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { DeviceService } from "../../../services/device/device.service";
import { EventsService } from "../../../services/events/events.service";
import { FooterService, FooterViews } from "../../../services/footer/footer.service";
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { ComponentViewMode, UIService } from "../../../services/ui/ui.service";
import { ActivitiesDetailsPaneComponent } from "../../activity/activities-details-pane/activities-details-pane";

@Component({
  selector: 'customer-call-plan-timeline',
  templateUrl: './customer-call-plan-timeline.html',
  styleUrls: ['./customer-call-plan-timeline.scss'],
})
export class CustomerCallPlanTimeline {

  public contactTimelineFilter: string;
  public getday = getDate;

  public groupedActivities = [];
  public groupedActivitiesToDisplay = [];
  private timelineActivities = [];
  public filteredGroupedActivities = [];
  public filteredGroupedActivitiesToDisplay = [];
  public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  public weekDay: Array<String> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  public userName: string;
  public isLIveMeet: boolean = false;
  public isJourney: boolean = false;
  public filterOptions: Array<{ value: string, isSelected: boolean, displayText: string }> = [];
  private actListLength: number = 0;
  private filteredActListLength: number = 0;
  private totalListLength = 0;
  public contactTimelineFilterFormView: IndFilterMenuModalDataModel[] = [];
  public isTimelineFiltered: boolean = false;
  public selectedFilters: any= [];
  public filterButtonBadgeCount: number = 0;
  public filteredListLength = 0;
  private selectedDate: {startDate: string, endDate: string} = {startDate: '', endDate: ''};

  @Input() contactSelected: Contact;
  @Input () modalView: any
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;

  constructor(
    public authenticationService: AuthenticationService,
    public contactService: ContactOfflineService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public navCtrl: IonNav,
    public footerService: FooterService,
    public device: DeviceService,
    private uiService: UIService,
    public sampleService: SampleService,
    public emailService: EmailService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    private consentService: ConsentService,
    public navService: NavigationService,
    private translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public utilityService: GlobalUtilityService,
    public eventSrvice: EventsService,
    public datepipe: DatePipe,
    public eventsToolDataService: EventsToolDataService,
    public eventsToolService: EventsToolService,
    private dynamicFormService: DynamicFormsService,
    public modalCtrl: ModalController,
    private searchConfigService: SearchConfigService,
    public callPlanDataService: CallPlanDataService,
    public callPlanService:CallPlanOfflineService,
    private notificationService: NotificationService,
  ) {
    this.initList();
  }

  async ngOnInit() {
    this.contactTimelineFilter = this.sampleService.contactTimelineFilter; //bug cwd-3450
    this.isLIveMeet = this.authenticationService.hasFeatureAction(FeatureActionsMap.LIVEMEET, true);
    this.uiService.timelineRefreshRequired = true;
  }
  ngAfterViewInit(){
    setTimeout(() => {
      if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }, 200);
  }

  async ngDoCheck() {
    if(this.uiService.timelineRefreshRequired && !_.isEmpty(this.contactService.contactInformation)){
      this.uiService.timelineRefreshRequired = false;
      const contactId = this.contactService.contactInformation.ID;
      try {
        this.uiService.displayLoader();
          if(!this.device.isOffline) {
            await this.callPlanDataService.removeCallPlanActivitiesTimelineDB(contactId);
            await this.fetchCallPlanTimeline().then(()=>{
              this.initList();
              this.uiService.dismissLoader();
            });
          }else {
            await this.fetchCallPlanTimeline().then(()=>{
              this.initList();
              this.uiService.dismissLoader();
            });
          }
      } catch (error) {
        console.log("Error getting callplan activities for timeline", error);
        this.uiService.dismissLoader();
      }
      this.uiService.displayLoader();
    }
  }

  initList() {
    this.groupedActivities = [];
    this.groupedActivitiesToDisplay = [];
    if (!_.isEmpty(this.contactService.contactInformation)) {
      this.contactService.isContactDetailsLoaded = false;
      if (!this.footerService.isActivitiesButton && this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
        this.isJourney = true;
      }
      this._getTimelineActivities();
      this.onTimelineTypeChange(this.sampleService.contactTimelineFilter);
      this.contactService.isContactDetailsLoaded = true;
      this.initFilterOptions();  // filter on the sub section title
      this._initFilterFormViews(); // for the filter button
      this.authenticationService.getOfflineUser().then((user) => {
        if (user) {
          this.userName = user.displayName;
          console.log("see this" + this.userName);
        }
        return;
      });

      //Filter pre-selection based on the selected actiities from Contact List Filter 
      let refDataFilterMenuList = this.searchConfigService.timelineFilterMenuList;
      if(!_.isEmpty(this.contactService.selectedActivitiesFromContactFilter) && !_.isEmpty(refDataFilterMenuList)) {
        this.isTimelineFiltered = true;
        let targetTimelineActivities = _.cloneDeep(this.timelineActivities);
        let activities = [];
        let filterBadgeCount: number = 0;
        let isSelectedMeetingStatus: boolean = false;

        this.contactService.selectedActivitiesFromContactFilter.forEach(mainFilterValue => {
          //Update the value of the selected Activity displayed in the timeline filter menu(RHS)
          let foundFilterActivityValue = refDataFilterMenuList.find(d=>d.category == mainFilterValue);
          if(!_.isEmpty(foundFilterActivityValue) && !this.selectedFilters.some(d=>d.category == mainFilterValue)) {
            this.selectedFilters.push(foundFilterActivityValue);
          }
          if(!_.isEmpty(this.contactTimelineFilterFormView)) {
            this.contactTimelineFilterFormView.map(d=>{
              if(d.id == "filter-main" && !_.isEmpty(d.options)) {
                let idx = d.options.findIndex(o=>o.group == mainFilterValue);
                if(idx > -1) {
                  d.options[idx].isSelected = true;
                  filterBadgeCount++;
                }
              }
            });
          }
          //Update Filtered activities
          targetTimelineActivities.forEach(activity => {
            if(mainFilterValue == ActivityType.LiveMeet && activity.type == ActivityType.Appointment && activity.location == "LiveMeet") {
              activities.push(activity); 
            }else if(mainFilterValue == ActivityType.Appointment && activity.type == ActivityType.Appointment && activity.location != "LiveMeet") {
              //Check Meeting activity option value
              if(!_.isEmpty(this.contactService.selectedSubOptionsFromContactFilter.meetingStatus)) {
                if(this.contactService.selectedSubOptionsFromContactFilter.meetingStatus.includes(activity.status)) {
                  //Update the value of the selected Option displayed in the timeline filter menu(RHS)
                  let formattedOptionId = ActivityType.Appointment + '_' + activity.statusString;
                  let foundFilterOptionValue = refDataFilterMenuList.find(d=>d.parentId == mainFilterValue && d.id == formattedOptionId);
                  if(!_.isEmpty(foundFilterOptionValue) && !this.selectedFilters.some(d=>d.id == formattedOptionId)) {
                    this.selectedFilters.push(foundFilterOptionValue);
                  }
                  if(!_.isEmpty(this.contactTimelineFilterFormView)) {
                    this.contactTimelineFilterFormView.map(d=>{
                      if(d.id == "appointment-status" && !_.isEmpty(d.options)) {
                        let idx = d.options.findIndex(o=>o.id == formattedOptionId);
                        if(idx > -1) {
                          d.options[idx].isSelected = true;
                          isSelectedMeetingStatus = true;
                        }
                      }
                    });
                  }
                  activities.push(activity);
                }
              }else {
                activities.push(activity);
              }
            }else if(mainFilterValue == activity.type) {
              activities.push(activity); 
            }
          });
        });
        //Update filter badge count from selected option
        if(isSelectedMeetingStatus) {
          let idx = this.contactTimelineFilterFormView.findIndex(d=>d.id == "appointment-status");
          if(idx > -1) {
            this.contactTimelineFilterFormView[idx].options.forEach(o=>{
              if(o.isSelected) filterBadgeCount++;
            });
          }
        }

        targetTimelineActivities = activities;
        this.filterButtonBadgeCount = filterBadgeCount;
        /******* grouped filtered activities *******/
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(targetTimelineActivities);
        this.filteredGroupedActivities = this.groupedActivities;
        this.filteredListLength = this._getFilteredListLength(this.filteredGroupedActivities);
        this.filteredActListLength = this._calListLength(this.filteredGroupedActivities, 0);
        this.filteredGroupedActivitiesToDisplay = this.filteredListLength>30 ? this.sliceFilteredActivities(0, this.filteredActListLength) : this.filteredGroupedActivities;
        console.log("Applied filter pre-selection");
      }
    } else {
      this.notificationService.notify(this.translate.instant('CUSTOMER_NOT_MAPPED_TO_YOUR_POSITION'),  "Contact list", "top", ToastStyle.DANGER, 3000, true);
      this.groupedActivities = [];
      this.groupedActivitiesToDisplay = [];
    }
  }

  private _getTimelineActivities() {
    let items: any = [];
    if (this.contactService.contactInformation.callplanActivitesTimeline) {
      items = [...this.contactService.contactInformation.callplanActivitesTimeline];
    }
    //To check if selectedCustomerCallPlanId exist
    if(this.callPlanService.selectedCustomerCallPlanId !== '') {
      items = items.filter(item => item.customerCallPlanId == this.callPlanService.selectedCustomerCallPlanId);
    }
    this.timelineActivities = items;
  }

  private _calListLength(groupedActivities, activityIndex) {
    let listLength = 0;
    for(let idx=activityIndex;idx<groupedActivities.length;idx++){
      if(groupedActivities[idx].list) listLength += groupedActivities[idx].list.length;
      if(listLength>30) {
        return idx+1;
      }
    }
    return groupedActivities.length;
  }

  private initFilterOptions(): void {
    this.filterOptions = [];
    if (this.contactService.contactInformation && (!_.isEmpty(this.timelineActivities) ||!_.isEmpty(this.contactService.contactInformation.eventsAndRegistrationsByContact) || !_.isEmpty(this.contactService.contactInformation.eventsCheckInByContact) || !_.isEmpty(this.contactService.contactInformation.eventsCompletedByContact))){
      this.filterOptions.push({ value: 'All_Activities', isSelected: true, displayText: this.translate.instant('ALL_ACTIVITIES') });
      let meetingsDisplayText = this.translate.instant('MEETINGS');
      if(this.translate.currentLang == 'it') {
        meetingsDisplayText = this.translate.instant('MEETINGS_TIMELINE');
      }
      if (this.timelineActivities.some(activity => (activity.type == ActivityType.Appointment && activity.location != 'LiveMeet'))) {
        this.filterOptions.push({ value: 'Appointment', isSelected: false, displayText: meetingsDisplayText })
      }
      if (this.timelineActivities.some(activity => activity.type == ActivityType.Email)) {
        this.filterOptions.push({ value: 'Email', isSelected: false, displayText: this.translate.instant('MESSAGES') })
      }
      if (this.timelineActivities.some(activity =>
        (activity.type == ActivityType.PhoneCall))) {
        this.filterOptions.push({ value: ActivityType.PhoneCall, isSelected: false, displayText: this.translate.instant('PHONE_CALLS') })
      }
    }
  }

  public formatHeader(value): string {
    // let edge = window.navigator.userAgent.indexOf('Edge/');
    // let formatedDate: any;
    // if (edge > 0) {
    //   let tempDate = value.split("-");
    //   let currentMonthIndex: number;
    //   let filteredMonth = this.months.filter((month: string, index: number) => {
    //     let compare = month.substring(0, 3);
    //     currentMonthIndex = index;
    //     return compare === tempDate[1];
    //   });
    //   let realDate = new Date(Date.parse(tempDate[0] + " " + tempDate[1] + " " + tempDate[2]));
    //   let dayOfTheWeek = this.weekDay[realDate.getDay() - 1];
    //   tempDate = dayOfTheWeek + " " + filteredMonth[filteredMonth.length - 1] + " " + tempDate[0];
    //   formatedDate = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10) >= 16 ? tempDate : value;
    // } else {
    // debugger;
    // if(isValid(new Date(value))){
    //   formatedDate = format(value, 'MMMM YYYY');
    // }else{
    //   let tempDate = value.split("-");
    //   let currentMonthIndex: number;
    //   let filteredMonth = this.months.filter((month: string, index: number) => {
    //     let compare = month.substring(0, 3);
    //     currentMonthIndex = index;
    //     return compare === tempDate[1];
    //   });
    //   let realDate = new Date(Date.parse(tempDate[0] + " " + tempDate[1] + " " + tempDate[2]));
    //   let dayOfTheWeek = this.weekDay[realDate.getDay() - 1];
    //   tempDate = dayOfTheWeek + " " + filteredMonth[filteredMonth.length - 1] + " " + tempDate[0];
    //   formatedDate = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10) >= 16 ? tempDate : value;
    // }
    // }
    try {
      if (value != 'Invalid Date') return this.datepipe.transform(value, 'MMMM y', undefined, this.translate.currentLang)
      else return value;
    } catch (error) {
      console.error("error " + error);
    }
    return value;
  }

  public onTimelineTypeChange(activityType) {
    if (!this.timelineActivities) 
      return;
  
    switch (activityType) {
      case 'All_Activities': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.timelineActivities);
        break;
      }
      case 'Appointment': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.timelineActivities.filter(activity => activity.type == 'Appointment' && activity.location != 'LiveMeet'));
        break;
      }
      case 'Email': {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.timelineActivities.filter(activity => activity.type == 'Email'));
        break;
      }
      case ActivityType.PhoneCall: {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.timelineActivities.filter(activity => (activity.type == ActivityType.PhoneCall)));
        break;
      }
      default: {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.timelineActivities);
      }
    }
    this.groupedActivities.forEach((act) => {
      if(act.list) this.totalListLength += act.list.length;
    });
    this.actListLength = this._calListLength(this.groupedActivities, 0);
    this.groupedActivitiesToDisplay = this.totalListLength>30 ? this.sliceActivities(0, this.actListLength) : this.groupedActivities;
  }

  openActivityDetails = (selActivity) => {
    //prevent navigation issue
    if ((this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.READONLY)
      || this.contactService.contactPageMode === ComponentViewMode.PREVIEW
      || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
      || this.dynamicFormService.isOpenedAffiliatedContactOnContact
      || this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING 
      || this.caseService.accessedFrom == AccesingMode.INMEETING
      || (this.modalView && this.sampleService.inMeetingAllocationActivity)) {
      console.log("The current activity cannot be opened to avoid possible problems with navigation loop");
      return;
    }
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'ActivitySkeleton';
    setTimeout(() => {
      this.displayActivityDetails(selActivity);
    }, 0);
  }

  async displayActivityDetails(activity: Activity) {
    let foundActivity = this.activityService.getActivityByID(activity.ID);
    if (activity.type == ActivityType.Appointment) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selected = (foundActivity) ? foundActivity : activity;      
      this.uiService.showNewActivity = false;
      this.activityService.prevSelectedActivityOnTimeline = _.cloneDeep(this.activityService.selectedActivity);
      this.uiService.prevView = 'contactDetails';
      this.uiService.showCancelDoneOnActivityDetails = false;
      await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity);
      this.uiService.activeView = 'ContactPageRightPaneNav';
      if(!_.isEmpty(this.contactService.contactInformation)) {
        this.contactService.selectedContactOnTimeline = this.contactService.contactInformation;
      }
      //meeting owner position check to determin read-only
      if(activity.meetingOwnerId == this.authenticationService.user.systemUserID) {
        this.activityService.selectedActivity.isDiffPosition = false;
      }else {
        if(activity.state != MeetingActivityState.Completed) {
          let allPositionByOwner: any[] = [];
          if(!this.device.isOffline) {
            allPositionByOwner = await this.contactService.getAllPositionByOwnerId(activity.meetingOwnerId);
          }else {
            allPositionByOwner = activity.meetingOwnerPositionIds;
          }
          const userPosition = this.authenticationService.user.positions;
          if(userPosition && userPosition.length > 0 && allPositionByOwner && allPositionByOwner.length > 0) {
            this.activityService.selectedActivity.isDiffPosition = true;
            userPosition.forEach(p=> {
              let foundPosition = allPositionByOwner.find(o=>o.positionid == p.ID);
              if(foundPosition) this.activityService.selectedActivity.isDiffPosition = false;
            });
          }
          if(this.activityService.selectedActivity.isDiffPosition) {
            console.log("The current meeting owner's position is different");
          }else {
            console.log("The current meeting owner is in the same position");
          }
        }
      }
      this.eventSrvice.publish('activities:appConfigFields');
      if (this.activityService.selectedActivity) {
        if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'ScientificActivityPage', childFrom: PageName.ContactDetailsComponent });
        }
        else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'ScientificActivityPage', childFrom: PageName.ContactDetailsComponent });
        } else {
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: PageName.ContactTimelineComponent });
        }
        this.eventSrvice.publish('detectChangesOnActivityDetails', true); // To triger angular change detection manually
        this.activityService.selectedActivity.accessedFrom = PageName.ContactTimelineComponent;
        this.footerService.initButtons(FooterViews.Activities);
      }
    } else if (activity.type == ActivityType.Email) {
      if(this.device.isOffline) {
        this.activityService.selectedActivity = (foundActivity) ? foundActivity : activity;
      }
      await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity).then(() => {
        this.emailService.viewType = EmailViewType.FROM_CONTACT_TIMELINE;
        this.uiService.showNewActivity = false;
        this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        if(this.navService.getCurrentMasterPageName() !== PageName.ContactPageComponent){
          this.uiService.activeView = 'Email';
        }
        this.uiService.prevView = 'contactDetails';
        if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        if(!isValid(this.emailService.selectedActivity.scheduledStart) || !isValid(this.emailService.selectedActivity.scheduledEnd)){
          // OMNI-27162: Currently contact timeline activities fetch service is returning activities created on dynamics for customer enquiry
          // Those activities doesn't have any scheduled start and end time attribute value so added this check to prevent the UI to break
          // In future it will be handled on service end to omit those activities from response
            return;
        }
        this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });

      }).catch(() => {
        console.log("Failed to load email activity:" + activity.ID);
      });
    } else if (activity.type == ActivityType.PhoneCall) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      // await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity);
      await this.uiService.displayLoader();
      if(!this.device.isOffline) {
        await this.activityDataService.getPhonecallActivityByIdOnline(activity.ID, activity.scheduledStart.getTime().toString(), activity.scheduledEnd.getTime().toString(), activity.scheduledStart.getTime().toString()).then(async (response) => {
          activity = new PhoneActivity(response);
          await this.activityDataService.updateActivityForConfigField(activity, response, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false)
          this.activityDataService.activityDetailsLoaded = true;
        })
      }else {
        const activityDoc = await this.activityDataService.getOfflineActivityDocByKey(DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY+activity.ID);
        if(activityDoc){
          activity = new PhoneActivity(activityDoc);
          await this.activityDataService.updateActivityForConfigField(activity, activityDoc, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, activityDoc);
          this.activityDataService.activityDetailsLoaded = true;
        }
      }
      //In case of date value as string type, need to set date type. it happened in offline mode
      if(!_.isEmpty(activity.scheduledStart)) activity.scheduledStart = new Date(activity.scheduledStart);
      if(!_.isEmpty(activity.scheduledEnd)) activity.scheduledEnd = new Date(activity.scheduledEnd);

      if(!isValid(activity.scheduledStart) || !isValid(activity.scheduledEnd)){
      // OMNI-27162: Currently contact timeline activities fetch service is returning activities created on dynamics for customer enquiry
      // Those activities doesn't have any scheduled start and end time attribute value so added this check to prevent the UI to break
      // In future it will be handled on service end to omit those activities from response
        await this.uiService.dismissLoader();  
        return;
      }
      await this.uiService.dismissLoader();
      this.activityService.selected = activity;
      this.uiService.activeView = 'phoneCallTimelineDetails';
      this.uiService.prevView = 'contactDetails';
      if (this.activityService.selectedActivity) {
        if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }
    }
  }

  isDataAvailable() {
    return this.isTimelineFiltered ? this.filteredGroupedActivities.length > 0 : (this.groupedActivities.length > 0 || this.contactTimelineFilter != 'All_Activities');
  }

  getMeetingOrAllocationLabel(activity: Activity) {
    let label = '';
    let labelPostfix = activity.meetingOwnerName === this.userName ? this.translate.instant('BY_ME_SMALL') : this.translate.instant('BY').toLowerCase() + " " + activity.meetingOwnerName;

    switch (activity.type) {
      case ActivityType.Appointment:
        label = (activity.state != MeetingActivityState.Completed) ? activity.meetingOwnerName : this.translate.instant('MET') + " " + labelPostfix;
        break;

      case ActivityType.PhoneCall:
        labelPostfix = activity.ownerId === this.authenticationService.user.systemUserID ? this.translate.instant('BY_ME_SMALL') :
          this.translate.instant('BY') + " " + activity.meetingOwnerName;
        break

      default:
        break;
    }

    return label;
  }

  getPhoneCallPrimaryLabel(activity: Activity) {
    let labelPostfix = activity.ownerId === this.authenticationService.user.systemUserID ?
    this.translate.instant('BY_ME_SMALL') :
      this.translate.instant('BY') + " " + activity.meetingOwnerName;
    return this.translate.instant('MET') + " " + labelPostfix;

  }

  getSelectedText(value) {
    const tot = this.groupedActivities.reduce((total, value) => (total + value.list.length), 0);
    switch (value) {
      case 'All_Activities':
        return this.translate.instant('ALL_ACTIVITIES') + ` ` + `(${tot})`;
      case 'Appointment':
        let meetingsText = this.translate.instant('MEETINGS');
        if(this.translate.currentLang == 'it') {
          meetingsText = this.translate.instant('MEETINGS_TIMELINE');
        }
        return meetingsText + ` ` + `(${tot})`;
      case 'Email':
        return this.translate.instant('MESSAGES') + ` ` + `(${tot})`;
      case ActivityType.PhoneCall:
        return this.translate.instant('PHONE_CALLS') + ` ` + `(${tot})`;
    }
  }

  public doInfinite(eventDetails,event) {
    if(this.isTimelineFiltered) {
      let startIdx = this.filteredActListLength;
      this.filteredActListLength = this._calListLength(this.filteredGroupedActivities, this.filteredActListLength);
      this.filteredGroupedActivitiesToDisplay.push(...this.sliceFilteredActivities(startIdx, this.filteredActListLength));
    } else {
      let startIndex = this.actListLength;
      this.actListLength = this._calListLength(this.groupedActivities, this.actListLength);
      this.groupedActivitiesToDisplay.push(...this.sliceActivities(startIndex, this.actListLength));
    }

    event.target.complete();
  }

  private sliceActivities(startIndex: number, count: number) {
    return this.groupedActivities.length < count ? this.groupedActivities.slice(startIndex) : this.groupedActivities.slice(startIndex, count)
  }

  private sliceFilteredActivities(startIndex: number, count: number) {
    return this.filteredGroupedActivities.length < count ? this.filteredGroupedActivities.slice(startIndex) : this.filteredGroupedActivities.slice(startIndex, count)
  }

  formattedDate(val) {
    return format(val, this.dateTimeFormatsService.timeToUpper) || '';
  }

  getChannelType(activity): string {
    let activityId = activity.ID;
    let emailActivityData = this.activityService.activities.find(ea => ea instanceof EmailActivity && ea.ID == activityId) as EmailActivity;
    // if there is no email activity data with activity id in this.activityService.activities, check it with the parent email id in activitesTimeline
    if (!emailActivityData && this.contactService.contactInformation && this.contactService.contactInformation.callplanActivitesTimeline) {
      let currentActivityInTimeline = this.contactService.contactInformation.callplanActivitesTimeline.find(at=>at.ID == activityId) as EmailActivity;
      if(currentActivityInTimeline) {
        let parentemailid = currentActivityInTimeline.parentemailid || '';
        if (parentemailid) {
          emailActivityData = this.activityService.activities.find(ea => ea instanceof EmailActivity && ea.ID == parentemailid) as EmailActivity;
        }
      }
    }
    if (emailActivityData) {
      const channelId = emailActivityData?.indskr_channelid || '';
      if (channelId != undefined && channelId != "") {
        let cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
        if (cha?.indskr_consentType) return ' - ' + this.emailService.translateChannelType(cha.indskr_consentType.toString());
        else return '';
      } else if (emailActivityData?.channelType) {
          return ' - ' + this.emailService.translateChannelType(emailActivityData.channelType.toString());
      } else if (activity.channelTypeName) {
        return ' - ' + this.emailService.translateChannelType(activity.channelTypeName);
      } else return '';
    } else {
      if (activity.channelTypeName) {
        return ' - ' + this.emailService.translateChannelType(activity.channelTypeName);
      } else return '';
    }
  }

  getPhoneCallSubject(subject) {
    return  (subject == 'Phone Call' || subject.includes(' - Phone Call')) ? subject.replace('Phone Call', this.translate.instant('NEW_ACTIVITY_PHONECALL')): subject;
  }

  /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
  async fetchCallPlanTimeline(){
    let dataRangeWithFutureBoundBySixMonths: { from: string, to: string };
    const activeTab = this.callPlanService.currentFooterFilter;

    if(activeTab === 'call-plan-past') {
      dataRangeWithFutureBoundBySixMonths = this.authenticationService.getFromToDateRangeInUTCMiliSec(365,0);
    }
    else {
      dataRangeWithFutureBoundBySixMonths = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
    }

    await this.callPlanDataService.getCallPlanActivitiesForTimeline(this.contactService.contactInformation,dataRangeWithFutureBoundBySixMonths).then(async ()=>{
    }).catch((err) => {
     console.log("error : ", err);
    });
  }
  /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

  public async openTimelineFilter(event) {
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.ContactTimelineComponent,
          data: this.contactTimelineFilterFormView,
          filterTitle: this.translate.instant('ALL_FILTERS'),
          selectedFilters: this.selectedFilters,
          selectedDate: this.selectedDate
        },
      }
    });
    await modal.present().then(() => {});
    modal.onDidDismiss().then(async(res)=>{
      //Show Result
      if(res && res.data && res.data.isDone) {
        if(!_.isEmpty(res.data.selectedItems) || !_.isEmpty(res.data.selectedDate)) {
          this.filterButtonBadgeCount = 0;
          this.filteredListLength = 0;
          this.filteredGroupedActivities = [];
          this.contactTimelineFilterFormView = res.data.selectedItems;
          this.isTimelineFiltered = true;
          this.selectedFilters = res.data.selectedItemsAccordion;
          this.filterButtonBadgeCount = res.data.selectedItemsAccordion.length;
          if(!_.isEmpty(res.data.selectedDate) && (!_.isEmpty(res.data.selectedDate.startDate) || !_.isEmpty(res.data.selectedDate.endDate))) {
            this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
          }
          let selectedActivitiesFromMainFilter = [];
          res.data.selectedItemsAccordion.forEach(item => {
            if(item.type == 'filter-main') {
              selectedActivitiesFromMainFilter.push(item.category);
            }
          });

          /******* filtered activities by duration *******/
          let isFilteredByDate: boolean = !_.isEmpty(res.data.selectedDate) && !_.isEmpty(res.data.selectedDate.startDate) && !_.isEmpty(res.data.selectedDate.endDate) || false;
          let targetTimelineActivities = _.cloneDeep(this.timelineActivities);
          if(isFilteredByDate) {
            this.selectedDate = {
              startDate: res.data.selectedDate.startDate,
              endDate: res.data.selectedDate.endDate,
            };
            targetTimelineActivities = this._filterTimelineActivitiesByDate(targetTimelineActivities, this.selectedDate);
          }else {
            this.selectedDate = {
              startDate: '',
              endDate: '',
            };
          }

          /******* filtered activities by selected activity and options *******/
          const isSelectedMyActivity: boolean = !_.isEmpty(selectedActivitiesFromMainFilter) && selectedActivitiesFromMainFilter.includes(ActivityType.MyActivity);
          if(isSelectedMyActivity) targetTimelineActivities = targetTimelineActivities.filter(activity => activity.ownerId == this.authenticationService.user.systemUserID);
          if(!_.isEmpty(selectedActivitiesFromMainFilter)) {
            let activities = [];
            selectedActivitiesFromMainFilter.forEach(mainFilterValue => {
              if(mainFilterValue != ActivityType.MyActivity) {
                let filteredTargetTimelineActivity = [];
                if(mainFilterValue == ActivityType.LiveMeet) {
                  filteredTargetTimelineActivity = targetTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location == 'LiveMeet');
                }else {
                  filteredTargetTimelineActivity = targetTimelineActivities.filter(activity => activity.type == mainFilterValue);
                }
                const filteredSelectedItemsByActivity = res.data.selectedItemsAccordion.filter(item=>item.parentId == mainFilterValue);
                const groupedCategory = _.groupBy(filteredSelectedItemsByActivity, 'category');
                const groupedCategoryList = _.values(groupedCategory);
                if(!_.isEmpty(filteredSelectedItemsByActivity)) {
                  filteredTargetTimelineActivity.forEach(act => {
                    let isMatchedData: boolean = true;
                    groupedCategoryList.forEach(category => {
                      if(mainFilterValue == ActivityType.Appointment && act.location != 'LiveMeet') {
                        if(category[0]['type'] == 'appointment-status') {
                          isMatchedData = category.some(item => item.id == ActivityType.Appointment + '_' + act.statusString) && isMatchedData;
                        }else if(category[0]['type'] == 'appointment-address') {
                          const formattedAddressId = act.location != 'No Location' ?  ActivityType.Appointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
                          isMatchedData = category.some(item => item.id == formattedAddressId) && isMatchedData;
                        }
                      }
                      else if(mainFilterValue == ActivityType.Email) {
                        if(category[0]['type'] == 'messages-channelType') {
                          isMatchedData = category.some(item => item.id == ActivityType.Email + '_'+ act.channelTypeName) && isMatchedData;
                        }else if(category[0]['type'] == 'messages-status') {
                          let formattedStatus: string = '';
                          if(act.status == 548910000) {
                            if(act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                              formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SHARED_BY_ME') : this.translate.instant('CONTACT_SHARED_BY_OWNER', {meetingOwnerName : act.meetingOwnerName});
                            }
                          }else {
                            if(act.channelTypeName == 'Email' || act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp' || act.channelTypeName == undefined) {
                              formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SENT_BY_ME') : this.translate.instant('CONTACT_SENT_BY_OWNER', {meetingOwnerName : act.meetingOwnerName});
                            }
                          }
                          isMatchedData = category.some(item => item.id == ActivityType.Email + '_'+ formattedStatus) && isMatchedData;
                        }else if(category[0]['type'] == 'messages-transfer-status') {
                          let formattedTransferStatus: string = '';
                          if(act.status == 548910000) {
                            if(act.actualEnd && (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp')) {
                              formattedTransferStatus = this.translate.instant('SHARED');
                            }
                          }else {
                            if(act.channelTypeName == 'Email' || act.channelTypeName == undefined) {
                              if(act.senton && !act.readOn) {
                                formattedTransferStatus = this.translate.instant('DELIVERED');
                              }else if(act.readOn) {
                                formattedTransferStatus = this.translate.instant('READ');
                              }
                            }else if(act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                              if(act.senton && !act.readOn) {
                                formattedTransferStatus = this.translate.instant('SENT');
                              }else if(act.readOn) {
                                formattedTransferStatus = this.translate.instant('DELIVERED');
                              }
                            }
                          }
                          isMatchedData = category.some(item => item.id == ActivityType.Email + '_'+ formattedTransferStatus) && isMatchedData;
                        }
                      }
                      else if(mainFilterValue == ActivityType.PhoneCall) {
                        if(category[0]['type'] == 'phoneCall-phoneNumber') {
                          isMatchedData = category.some(item => item.id == ActivityType.PhoneCall + '_'+ act.phonecallphonenumber) && isMatchedData;
                        }
                      }
                    });
                    if(isMatchedData) activities.push(act);
                  });
                }else {
                  let filteredActivityMainOnly = targetTimelineActivities.filter(activity => activity.type == mainFilterValue);
                  filteredActivityMainOnly.forEach(act => {
                    activities.push(act);
                  });
                }
              }
            });
            if(!isSelectedMyActivity) targetTimelineActivities = activities;
          }
          
          /******* grouped filtered activities *******/
          this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(targetTimelineActivities);
          this.filteredGroupedActivities = this.groupedActivities;
          this.filteredListLength = this._getFilteredListLength(this.filteredGroupedActivities);
          this.filteredActListLength = this._calListLength(this.filteredGroupedActivities, 0);
          this.filteredGroupedActivitiesToDisplay = this.filteredListLength>30 ? this.sliceFilteredActivities(0, this.filteredActListLength) : this.filteredGroupedActivities;
        }
        console.log(res);
      }
    })
  }

  private _initFilterFormViews() {
    this.contactTimelineFilterFormView = [];
    if(!_.isEmpty(this.timelineActivities)) {
      let filterFormViewMain: IndFilterMenuModalDataModel = {
        id : 'filter-main',
        displayName: this.translate.instant('ACTIVITY'),
        isMultiSelect: true,
        options: []
      }
      this.contactTimelineFilterFormView.push(filterFormViewMain);

      let meetingsDisplayText = this.translate.instant('MEETINGS');
      if(this.translate.currentLang == 'it') {
        meetingsDisplayText = this.translate.instant('MEETINGS_TIMELINE');
      }
      //add filter options based on the timelineActivities
      if (this.timelineActivities.some(activity => (activity.type == ActivityType.Appointment && activity.location != 'LiveMeet'))) {
        filterFormViewMain.options.push({ id: 'meeting-main', group: ActivityType.Appointment, isSelected: false, displayName: meetingsDisplayText });
        let appointmentActs = this.timelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location != 'LiveMeet');
        appointmentActs.forEach((act) => {
          //status
          if(!_.isEmpty(act.statusString)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'appointment-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Appointment + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Appointment + '_' + act.statusString);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'appointment-status',
                parentId: ActivityType.Appointment,
                displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //address
          if(!_.isEmpty(act.location)) {
            const formattedAddressId = act.location != 'No Location' ?  ActivityType.Appointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'appointment-address');
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: formattedAddressId,
              displayName: act.location,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == formattedAddressId);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'appointment-address',
                parentId: ActivityType.Appointment,
                displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this.timelineActivities.some(activity => activity.type == ActivityType.Email)) {
        filterFormViewMain.options.push({ id: 'email-main', group: ActivityType.Email, isSelected: false, displayName: this.translate.instant('MESSAGES') });
        let messagesActs = this.timelineActivities.filter(activity => activity.type == ActivityType.Email);
        messagesActs.forEach((act) => {
          //channel type
          if(!_.isEmpty(act.channelTypeName)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'messages-channelType');
            let option = {
              group: this.translate.instant('CHANNELS'),
              id: ActivityType.Email + '_'+ act.channelTypeName,
              displayName: act.channelTypeName,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_'+ act.channelTypeName);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'messages-channelType',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('CHANNELS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //status
          if(act.status != '' || act.status != undefined || act.status != null) {
            let formattedStatus: string = '';
            if(act.status == 548910000) {
              if(act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SHARED_BY_ME') : this.translate.instant('CONTACT_SHARED_BY_OWNER', {meetingOwnerName : act.meetingOwnerName});
              }
            }else {
              if(act.channelTypeName == 'Email' || act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp' || act.channelTypeName == undefined) {
                formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SENT_BY_ME') : this.translate.instant('CONTACT_SENT_BY_OWNER', {meetingOwnerName : act.meetingOwnerName});
              }
            }
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'messages-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Email + '_'+ formattedStatus,
              displayName: formattedStatus,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_'+ formattedStatus);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'messages-status',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //transfer status
          if(act.status != '' || act.status != undefined || act.status != null) {
            let formattedTransferStatus: string = '';
            if(act.status == 548910000) {
              if(act.actualEnd && (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp')) {
                formattedTransferStatus = this.translate.instant('SHARED');
              }
            }else {
              if(act.channelTypeName == 'Email' || act.channelTypeName == undefined) {
                if(act.senton && !act.readOn) {
                  formattedTransferStatus = this.translate.instant('DELIVERED');
                }else if(act.readOn) {
                  formattedTransferStatus = this.translate.instant('READ');
                }
              }else if(act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                if(act.senton && !act.readOn) {
                  formattedTransferStatus = this.translate.instant('SENT');
                }else if(act.readOn) {
                  formattedTransferStatus = this.translate.instant('DELIVERED');
                }
              }
            }
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'messages-transfer-status');
            let option = {
              group: this.translate.instant('TRANSFER_STATUS'),
              id: ActivityType.Email + '_'+ formattedTransferStatus,
              displayName: formattedTransferStatus,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_'+ formattedTransferStatus);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'messages-transfer-status',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('TRANSFER_STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this.timelineActivities.some(activity => (activity.type ==  ActivityType.Appointment && activity.location === 'LiveMeet'))) {
        filterFormViewMain.options.push({ id: 'liveMeeting-main', group: ActivityType.LiveMeet, isSelected: false, displayName: this.translate.instant('LIVE_MEETING') });
        let liveMeetingActs = this.timelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location == 'LiveMeet');
        liveMeetingActs.forEach((act) => {
          //status
          if(!_.isEmpty(act.statusString)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'liveMeeting-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.LiveMeet + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.LiveMeet + '_' + act.statusString);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'liveMeeting-status',
                parentId: ActivityType.LiveMeet,
                displayName: this.translate.instant('LIVE_MEETING') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this.timelineActivities.some(activity =>(activity.type == ActivityType.PhoneCall))) {
        filterFormViewMain.options.push({ id: 'phoneCall-main', group: ActivityType.PhoneCall, isSelected: false, displayName: this.translate.instant('PHONE_CALLS') });
        let phoneCallActs = this.timelineActivities.filter(activity => activity.type == ActivityType.PhoneCall);
        phoneCallActs.forEach((act) => {
          //phone number
          if(!_.isEmpty(act.phonecallphonenumber)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'phoneCall-phoneNumber');
            let option = {
              group: this.translate.instant('PHONE_NUMBER'),
              id: ActivityType.PhoneCall + '_'+ act.phonecallphonenumber,
              displayName: act.phonecallphonenumber,
              isSelected: false,
            }
            if(idx>-1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.PhoneCall + '_'+ act.phonecallphonenumber);
              if(optionIdx<0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id : 'phoneCall-phoneNumber',
                parentId: ActivityType.PhoneCall,
                displayName: this.translate.instant('PHONE_CALLS') + ' - ' + this.translate.instant('PHONE_NUMBER'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
    }
  }

  public clearFilterResults() {
    this.isTimelineFiltered = false;
    this.selectedFilters = [];
    this.filteredGroupedActivities = [];
    this.filteredGroupedActivitiesToDisplay = [];
    this.selectedDate = {startDate: '', endDate: ''};
    this.onTimelineTypeChange(this.sampleService.contactTimelineFilter);
    this._initFilterFormViews();
  }

  private _filterTimelineActivitiesByDate(targetTimelineActivities: any, selectedDate: any) {
    const startDate: Date = new Date(selectedDate.startDate);
    const endDate: Date = new Date(selectedDate.endDate);
    targetTimelineActivities.map(activity => {
      if(activity.hasOwnProperty('scheduledStart') && activity['scheduledStart'] != '' && activity['scheduledStart'] != "Invalid Date" && activity['scheduledStart'] != undefined) {
        activity['isValidStartDate'] = true;
        activity['scheduledStart'] = new Date(activity['scheduledStart']);
      }
      if(activity.hasOwnProperty('scheduledEnd') && activity['scheduledEnd'] != '' && activity['scheduledEnd'] != "Invalid Date" && activity['scheduledEnd'] != undefined) {
        activity['isValidEndDate'] = true;
        activity['scheduledEnd'] = new Date(activity['scheduledEnd']);
      }
    });
    let filteredTimelineActivities = [];
    targetTimelineActivities.forEach(activity=>{
      if(activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate'] && activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if(activity['scheduledStart'] >= startDate && activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      }else if(activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate']) {
        if(activity['scheduledStart'] >= startDate && activity['scheduledStart'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      }else if(activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if(activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      }
    });
    // targetTimelineActivities = filteredTimelineActivities;
    return filteredTimelineActivities;
  }

  private _getFilteredListLength(filteredGroupedActivities): number {
    let filteredListLength: number = 0;
    filteredGroupedActivities.forEach(monthlyActs=>{
      filteredListLength += monthlyActs.list.length;
    });
    return filteredListLength;
  }
}
