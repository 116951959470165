<!-- Generated template for the LocationComponent component mode -->
<ion-header>
    <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content [ngClass]="{'vector-image-right' :!participantAdded || ( !searchActivated && locationData$.getValue().length == 0 || searchActivated && tempLocationList.length == 0 )}">
    <ion-item *ngIf="true" id="search-wrapper" lines="none">
        <ion-searchbar [(ngModel)]="searchTerm" [disabled]="isSearchBoxDisabled" id="search-dom-input-wrapper" [debounce]="500" class="searchlocation" placeholder="{{ 'Locations' | translate }}" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="search-control" #search [formControl]="searchControl"
            (ionInput)="searchForLocation()"></ion-searchbar>
        <ion-buttons slot="end" *ngIf="showCurrentLocationButton()">
            <ion-button (click)="getCurrentLocation()" class="current-location">
                <ion-icon name="locate"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-item>
    <!-- <ion-item-divider color="light">{{'LOCATION_SUGGESTED_LOCATIONS' | translate}}</ion-item-divider> -->
    <ind-section-header *ngIf="shouldShowSection()" [viewData]='{id:"location_suggestions",title: translate.instant("LOCATION_SUGGESTED_LOCATIONS")}'></ind-section-header>

    <div class="no-location-data-message" *ngIf="shouldShowNoLocationData()">{{'NO_SUGGESTED_LOCATIONS' | translate}}</div>
    <ion-item *ngIf="hasMeetingURL()" (click)='onLocationClick({compositeAdd:meetingURL()})' [ngClass]="{'itemSelected' :this.locationSelected === meetingURL()}">
        {{'ACTIVITY_DETAILS_LIVE_TIME' | translate}}
    </ion-item>

    <div *ngIf="participantAdded">
        <virtual-list *ngIf="!searchActivated" [height]="'700'" class="list1" [source$]="locationData$" (update)="scrollItems = $event" (change)="indices = $event">
          <ion-item *ngFor="let item of scrollItems">
            <ion-icon slot="start" *ngIf="activityService.selectedActivity && activityService.selectedActivity.type == 'Appointment'" class="favourite button-foreground" [name]="item.isFavourite ? 'star':'star-outline'"></ion-icon>
            <ion-label class="location-label" [ngClass]="{'itemSelected' :this.locationSelected === item.compositeAdd}" (click)='onLocationClick(item)'>{{item.compositeAdd}}</ion-label>
          </ion-item>
        </virtual-list>
        <ion-list *ngIf="tempLocationList.length > 0 && searchActivated" [ngStyle]="{'display': 'block'}">
          <ion-item *ngFor="let loc of tempLocationList;let i = index">
            <ion-icon slot="start" *ngIf="activityService.selectedActivity && activityService.selectedActivity.type == 'Appointment'" class="favourite button-foreground" [name]="loc.isFavourite ? 'star':'star-outline'"></ion-icon>
            <ion-label class="location-label" [attr.data-index]="i" [ngClass]="{'itemSelected' :this.locationSelected === loc}" (click)='onLocationClick(loc)'>
              {{loc.compositeAdd}}
            </ion-label>
          </ion-item>
        </ion-list>
    </div>

    <div *ngIf="selectionFor == 'ACCOUNT_ADDRESSES' && locationData.length > 0">
      <ion-list>
        <ion-item *ngFor="let location of locationData" (click)="selectAddressFromList(location)">
          <ion-label>{{ location.compositeAdd }}</ion-label>
          <ion-icon class="selected" tappable *ngIf="locationSelected === location.compositeAdd" name="checkmark" slot="end" ></ion-icon>
        </ion-item>
      </ion-list>
    </div>




    <!-- CWD-1561 for refrence
  <div *ngIf="!participantAdded">
    <virtual-list *ngIf="!searchActivated" class="list"
    [source$]="locationData$"
    (update)="scrollItems = $event"
    (change)="indices = $event">
    <div ion-item *ngFor="let item of scrollItems" [ngClass]="{'itemSelected' :this.locationSelected === item}" (click)='onLocationClick(item)'>{{item.name}}</div>
</virtual-list>
<ion-list *ngIf="tempLocationList.length > 0 && searchActivated" [ngStyle]="{'display': 'block'}">
  <ion-item *ngFor="let loc of tempLocationList;let i = index" [attr.data-index]="i" [ngClass]="{'itemSelected' :this.locationSelected === loc}"  (click)='onLocationClick(loc)'>
        {{loc.name}}
  </ion-item>
</ion-list>
</div> -->
</ion-content>
<!-- <ion-footer>
    <ion-toolbar class="toolbar-height">
    </ion-toolbar>
</ion-footer> -->