import { MeetingActivityState, ActivityType } from './../classes/activity/activity.class';
import { DurationType, TimeOffActivity } from '@omni/classes/activity/timeoff.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { Activity, PhoneCallActivityState } from "../classes/activity/activity.class";
import { EventActivity } from '../classes/events-tool/event.class';
import _ from 'lodash';

export function startRecursiveActivityScheduleConflictFlagging(sortedList: Activity[], startingIdx: number, userId: string, shouldCheckAccountVisit: boolean) {
  if (Array.isArray(sortedList) && sortedList.length > 0) {
    _flagActivityScheduleConflict(sortedList, startingIdx, startingIdx + 1, userId, shouldCheckAccountVisit);
  }
}

export function startRecursiveActivityCloseByActivityFlagging(sortedList: Activity[], startingIdx, userId: string, proximityRange: number, shouldCheckAccountVisit: boolean) {
  _warnCloseByMeeting(sortedList, startingIdx, startingIdx + 1, userId, proximityRange, shouldCheckAccountVisit);
}

export function isValidActivityToCheckTimeConflict(activity: Activity, userId: string): boolean {
  let isValid: boolean = false;
  if (activity instanceof AppointmentActivity) {
    isValid = (
      activity.state !== MeetingActivityState.Completed
      && (activity.meetingOwnerId === userId
        || (
          Array.isArray(activity.accompaniedUserList)
          && activity.accompaniedUserList.findIndex(a => a.id === userId) >= 0
      ))
    ) ? true : false;
  } else if (activity instanceof PhoneActivity) {
    isValid = (
      activity.state !== PhoneCallActivityState.Completed
      && (
        activity.ownerId === userId
        || (
          Array.isArray(activity.accompaniedUserList)
          && activity.accompaniedUserList.findIndex(a => a.id === userId) >= 0
        )
      )
    );
  } else if (activity instanceof TimeOffActivity) {
    isValid = activity.totOwnerId === userId;
  } else if (activity instanceof EventActivity) {
    isValid = (
      activity.statuscode === 1
      && (
        activity.ownerId === userId
        || (
          Array.isArray(activity.covisitors)
          && activity.covisitors.findIndex(a => a.id === userId) >= 0
        )
      ) ? true : false
    );
  } else {
    console.warn('isValidActivityToCheckTimeConflict: ', activity);
  }
  return isValid;
}
function _checkTwoDateRangesConflict(aStart: Date, aEnd: Date, bStart: Date, bEnd: Date): boolean | null {
  let isConflict: boolean | null = null;

  if (
    aStart instanceof Date
    && aEnd instanceof Date
    && bStart instanceof Date
    && bEnd instanceof Date
  ) {
    try {
      const aS = new Date(aStart);
      aS.setSeconds(0, 0);
      const aE = new Date(aEnd);
      aE.setSeconds(0, 0);

      const bS = new Date(bStart);
      bS.setSeconds(0, 0);
      const bE = new Date(bEnd);
      bE.setSeconds(0, 0);

      isConflict = _checkScheduleOverlap(
        aS?.getTime(),
        aE?.getTime(),
        bS?.getTime(),
        bE?.getTime(),
      );
    } catch (error) {
      console.error('_checkTwoDateRangesConflict: ', error);
    }
  }

  return isConflict;
}
function _getRealActivityEndDate(activity: Activity): Date {
  let end: Date;
  if (activity instanceof TimeOffActivity && activity.durationType === DurationType.AllDay) {
    end = new Date(activity?.scheduledStart);
    end.setHours(23, 59, 59, 999);
  } else {
    end = activity?.scheduledEnd;
  }
  return end;
}
function _isAccountVisitOrNestedMeetingActivity(activity: AppointmentActivity): {
  isAccountVisit: boolean,
  isNestedMeeting: boolean,
} {
  const response = {
    isAccountVisit: false,
    isNestedMeeting: false,
  };

  if (activity.indskr_isparentcall) {
    response.isAccountVisit = true;
  } else if (activity.indskr_parentcallid) {
    response.isNestedMeeting = true;
  }

  return response;
}
function _isAccountVisitParentChildOrSibling(a: AppointmentActivity, b: AppointmentActivity): boolean {
  return a.ID === b.indskr_parentcallid || b.ID === a.indskr_parentcallid || a.indskr_parentcallid === b.indskr_parentcallid;
}
function _flagActivityScheduleConflict(sortedList: Activity[], startingIdx: number, nextIdx: number, userId: string, shouldCheckAccountVisit: boolean) {
  try {
    if (nextIdx < sortedList.length) {
      const baseActivity = sortedList[startingIdx];
      const isBaseActivityValid = isValidActivityToCheckTimeConflict(baseActivity, userId);
      const isBaseAccountVisitCheck = shouldCheckAccountVisit
        && _isAccountVisitOrNestedMeetingActivity(baseActivity as AppointmentActivity);
      const activityToCheck = sortedList[nextIdx];
      const isActivityToCheckAccountVisitCheck = shouldCheckAccountVisit
        && _isAccountVisitOrNestedMeetingActivity(activityToCheck as AppointmentActivity);

      // Skip this if two activities have parent child account visit relationship
      // Or if one or both are nested meeting
      const isAnyNestedMeeting = isBaseAccountVisitCheck.isNestedMeeting || isActivityToCheckAccountVisitCheck.isNestedMeeting;
      // Check for account visit parent child relationship
      const isAccountVisitParentChild = (
        isBaseAccountVisitCheck.isAccountVisit && isActivityToCheckAccountVisitCheck.isNestedMeeting
        || isBaseAccountVisitCheck.isNestedMeeting && isActivityToCheckAccountVisitCheck.isAccountVisit
      )
        ? _isAccountVisitParentChildOrSibling(
            baseActivity as AppointmentActivity,
            activityToCheck as AppointmentActivity
          )
        : false;
      if (isAnyNestedMeeting || isAccountVisitParentChild) {
        _flagActivityScheduleConflict(sortedList, startingIdx, nextIdx + 1, userId, shouldCheckAccountVisit);
        return;
      }

      if (isBaseActivityValid) {
        // Need this logic because all day time offs' duration is 30 min. (for calendar display?)
        const baseActivityEnd: Date = _getRealActivityEndDate(baseActivity);
        const activityToCheckEnd: Date = _getRealActivityEndDate(activityToCheck);

        const isConflict = _checkTwoDateRangesConflict(
          baseActivity?.scheduledStart,
          baseActivityEnd,
          activityToCheck?.scheduledStart,
          activityToCheckEnd,
        );

        if (isConflict === null || isConflict === true) {
          const isActivityToCheckValid = isValidActivityToCheckTimeConflict(activityToCheck, userId);
          if (isConflict && isActivityToCheckValid) {
            if (baseActivity.offlineId && activityToCheck.offlineId) {
              if (baseActivity.conflictingActivityIds instanceof Map === false) {
                baseActivity.conflictingActivityIds = new Map();
              }
              baseActivity.conflictingActivityIds.set(activityToCheck.offlineId, true);
              if (activityToCheck.conflictingActivityIds instanceof Map === false) {
                activityToCheck.conflictingActivityIds = new Map();
              }
              activityToCheck.conflictingActivityIds.set(baseActivity.offlineId, true);
            }
          }
          _flagActivityScheduleConflict(sortedList, startingIdx, nextIdx + 1, userId, shouldCheckAccountVisit);
        }
      }
    }
  } catch (error) {
    console.error('_flagActivityScheduleConflict: ', sortedList, startingIdx, nextIdx);
  }
}
function _checkScheduleOverlap(aStart: number, aEnd: number, bStart: number, bEnd: number): boolean | null {
  if (!isNaN(aStart) && !isNaN(aEnd) && !isNaN(bStart) && !isNaN(bEnd)) {
    return !(aEnd <= bStart || aStart >= bEnd);
  } else {
    return null;
  }
}

export function startRecursiveEventScheduleConflictFlagging(sortedEvents: EventActivity[], activity: Activity, userId: string): number {
  const isActivityValid = isValidActivityToCheckTimeConflict(activity, userId);
  let lastIdx: number = 0;

  if (isActivityValid && Array.isArray(sortedEvents) && sortedEvents.length > 0) {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTimestamp: number = today.getTime();
      const activityStart: Date = activity instanceof EventActivity
        ? activity?._startDate
        : activity?.scheduledStart ?? undefined;
      const activityEnd: Date = activity instanceof EventActivity
        ? activity?.endDate
        : activity?.scheduledEnd ?? undefined;

      // Events are sorted in desc order
      for (let i = 0; i < sortedEvents.length; i++) {
        const eventActivity: EventActivity = sortedEvents[i];
        const eStartTimestamp: number = eventActivity?._startDate?.getTime();
        const eEndTimestamp: number = eventActivity?.endDate?.getTime();

        if (eStartTimestamp >= todayTimestamp || eEndTimestamp >= todayTimestamp) {
          const isConflict: boolean | null = _checkTwoDateRangesConflict(
            eventActivity?._startDate,
            eventActivity?.endDate,
            activityStart,
            activityEnd,
          );
          const isEventValid: boolean = isValidActivityToCheckTimeConflict(eventActivity, userId);

          if (isConflict && isEventValid) {
            if (eventActivity.conflictingActivityIds instanceof Map === false) {
              eventActivity.conflictingActivityIds = new Map();
            }
            eventActivity.conflictingActivityIds.set(activity.offlineId, true);

            if (activity.conflictingActivityIds instanceof Map === false) {
              activity.conflictingActivityIds = new Map();
            }
            activity.conflictingActivityIds.set(eventActivity.offlineId, true);
          }
        } else {
          lastIdx = i;
          break;
        }
      }
    } catch (error) {
      console.error('startRecursiveEventScheduleConflictFlagging: ', error);
    }
  }
  return lastIdx;
}

export function startRecursiveEventScheduleConflictFlaggingForEventsArray(sortedList: EventActivity[], currentIdx: number, lastIdxToBehChecked: number, userId: string): number {
  let lastIdx: number = lastIdxToBehChecked;
  if (Array.isArray(sortedList) && sortedList.length > 0) {
    if (lastIdxToBehChecked < 0 || lastIdxToBehChecked >= sortedList.length) {
      lastIdxToBehChecked = sortedList.length - 1;
    }
    try {
      if (currentIdx + 1 <= lastIdxToBehChecked) {
        const baseEvent = sortedList[currentIdx];
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayTimestamp: number = today.getTime();
        const eventStart: number = baseEvent?._startDate?.getTime();
        const eventEnd: number = baseEvent?.endDate?.getTime();
        const isTimeValid: boolean = (
          eventStart >= todayTimestamp
          || eventEnd >= todayTimestamp
        ) ? true : false;

        if (
          isValidActivityToCheckTimeConflict(baseEvent, userId)
          && isTimeValid
        ) {
          const eventToCheck = sortedList[currentIdx + 1];

          const isConflict = _checkTwoDateRangesConflict(
            baseEvent?._startDate,
            baseEvent?.endDate,
            eventToCheck?._startDate,
            eventToCheck?.endDate,
          );

          if (isConflict === null || isConflict === true) {
            const isActivityToCheckValid = isValidActivityToCheckTimeConflict(eventToCheck, userId);
            if (isConflict && isActivityToCheckValid) {
              if (baseEvent.offlineId && eventToCheck.offlineId) {
                if (baseEvent.conflictingEventIds instanceof Map === false) {
                  baseEvent.conflictingEventIds = new Map();
                }
                baseEvent.conflictingEventIds.set(eventToCheck.offlineId, true);

                if (eventToCheck.conflictingEventIds instanceof Map === false) {
                  eventToCheck.conflictingEventIds = new Map();
                }
                eventToCheck.conflictingEventIds.set(baseEvent.offlineId, true);
              }
            }
          }
        }
        if (!isTimeValid) {
          lastIdx = currentIdx - 1;
          if (lastIdx < 0) {
            lastIdx = 0;
          }
        } else {
          lastIdx = startRecursiveEventScheduleConflictFlaggingForEventsArray(sortedList, currentIdx + 1, lastIdxToBehChecked, userId);
        }
      }
    } catch (error) {
      console.error('startRecursiveEventScheduleConflictFlaggingForEventsArray: ', sortedList, currentIdx, lastIdxToBehChecked);
    }
  }
  return lastIdx + 1;
}
export function isDateRangeOverlapOrInFuture(startDate: Date, endDate: Date, timestampToCompare: number) {
  let isTrue: boolean = false;
  try {
    if (startDate instanceof Date && endDate instanceof Date) {
      isTrue = startDate.getTime() >= timestampToCompare || endDate.getTime() > timestampToCompare;
    }
  } catch (error) {
    console.warn('isDateRangeOverlapOrInFuture: ', error);
  }
  return isTrue;
}

export function newActivityConflictCheck(activities: Activity[], activityStartIdx: number, events: EventActivity[], checkStopIdx: number, start: Date, end: Date, userId: string): boolean {
  let isThereConflict: boolean = false;
  if (start instanceof Date && end instanceof Date && userId) {
    if (Array.isArray(events) && events.length > 0) {
      const stopIdx = checkStopIdx > -1 ? checkStopIdx : events.length - 1;
      for (let i = 0; i <= stopIdx; i++) {
        const event = events[i];
        const isConflict: boolean = _checkTwoDateRangesConflict(event?._startDate, event?.endDate, start, end);
        if (isConflict) {
          const isValidActivity: boolean = isValidActivityToCheckTimeConflict(event, userId);
          if (isValidActivity) {
            isThereConflict = true;
            break;
          }
        }
      }
    }
    if (Array.isArray(activities) && activities.length > 0 && !isThereConflict) {
      const startIdx = activityStartIdx > -1 ? activityStartIdx : 0;
      for (let i = startIdx; i < activities.length; i++) {
        const activity = activities[i];
        const isConflict: boolean = _checkTwoDateRangesConflict(activity?.scheduledStart, activity?.scheduledEnd, start, end);
        if (isConflict) {
          const isValidActivity: boolean = isValidActivityToCheckTimeConflict(activity, userId);
          if (isValidActivity) {
            isThereConflict = true;
            break;
          }
        }
      }
    }
  }
  return isThereConflict;
}

export function newActivityProximityWarningCheck(activities: Activity[], start: Date, end: Date, userId: string, proximityRange: number, contactId: string): boolean {
  let hasProximityWarning: boolean = false;
  if (proximityRange > 0 && !_.isEmpty(activities)) {
    const meetings = activities.filter(a => a.type == ActivityType.Appointment && !_.isEmpty(a['contacts']));
    if (!_.isEmpty(meetings)) {
      for (let i = 0; i < meetings.length; i++) {
        const activity = meetings[i];
        const isValidActivity = isValidMeetingToCheckCloseByMeeting(activity, userId);
        if (isValidActivity && activity['contacts'].some(c=> c.ID == contactId)) {
          const newActivityStart: Date = _.cloneDeep(start);
          newActivityStart.setHours(newActivityStart.getHours() - proximityRange);
          const newActivityEnd: Date = _.cloneDeep(end);
          newActivityEnd.setHours(newActivityEnd.getHours() + proximityRange);
          hasProximityWarning = _checkTwoDateRangesConflict(activity?.scheduledStart, activity?.scheduledEnd, newActivityStart, newActivityEnd);
          if (hasProximityWarning) { 
            break;
          }
        }
      }
    }
  }
  return hasProximityWarning;
}

export function checkConflictAgainstActivities(sortedList: Activity[], activity: Activity, checkStartIdx: number, userId: string) {
  try {
    if (Array.isArray(sortedList) && sortedList.length > 0 && userId) {
      if (checkStartIdx < 0 || checkStartIdx >= sortedList.length) {
        checkStartIdx = 0;
      }
      const startDate: Date = activity instanceof EventActivity ? activity._startDate : activity.scheduledStart;
      const endDate: Date = activity instanceof EventActivity ? activity.endDate : activity.scheduledEnd;

      if (
        activity instanceof TimeOffActivity
        && startDate?.getTime() === endDate?.getTime()
      ) {
        // 1 day time off record has same start & end date time
        // Need to manually set the end date properly
        endDate?.setHours(23, 59, 59, 999);
      }

      for (let i = checkStartIdx; i < sortedList.length; i++) {
        const activityToCheck = sortedList[i];

        if (activityToCheck.offlineId !== activity.offlineId && isValidActivityToCheckTimeConflict(activityToCheck, userId)) {
          const isConflict = _checkTwoDateRangesConflict(
            activityToCheck.scheduledStart,
            _getRealActivityEndDate(activityToCheck),
            startDate,
            endDate,
          );

          if (isConflict) {
            if (activityToCheck.conflictingActivityIds instanceof Map === false) {
              activityToCheck.conflictingActivityIds = new Map();
            }
            activityToCheck.conflictingActivityIds.set(activity.offlineId, true);

            if (activity.conflictingActivityIds instanceof Map === false) {
              activity.conflictingActivityIds = new Map();
            }
            activity.conflictingActivityIds.set(activityToCheck.offlineId, true);
          }
        }
      }
    }
  } catch (error) {
    console.error('checkConflictAgainstActivities: ', error);
  }
}

export function checkConflictAgainstEvents(sortedList: EventActivity[], activity: Activity, lastIdxToBehChecked: number, userId: string) {
  try {
    if (Array.isArray(sortedList) && sortedList.length > 0 && userId) {
      if (lastIdxToBehChecked < 0 || lastIdxToBehChecked >= sortedList.length) {
        lastIdxToBehChecked = sortedList.length - 1;
      }
      const startDate: Date = activity instanceof EventActivity ? activity._startDate : activity.scheduledStart;
      const endDate: Date = activity instanceof EventActivity ? activity.endDate : activity.scheduledEnd;

      for (let i = 0; i <= lastIdxToBehChecked; i++) {
        const eventToCheck = sortedList[i];

        if (eventToCheck.offlineId !== activity.offlineId && isValidActivityToCheckTimeConflict(eventToCheck, userId)) {
          const isConflict = _checkTwoDateRangesConflict(
            eventToCheck._startDate,
            eventToCheck.endDate,
            startDate,
            endDate,
          );

          if (isConflict) {
            if (activity instanceof EventActivity) {
              eventToCheck.conflictingEventIds.set(activity.offlineId, true);
              activity.conflictingEventIds.set(eventToCheck.offlineId, true);
            } else {
              eventToCheck.conflictingActivityIds.set(activity.offlineId, true);
              activity.conflictingActivityIds.set(eventToCheck.offlineId, true);
            }
          }
        }
      }
    }
  } catch (error) {
    console.error('checkConflictAgainstEvents: ', error);
  }
}

export function removeConflictIdFromOtherActivities(activity: Activity, activities: Activity[], events: EventActivity[]) {
  try {
    // Clear conflicting appointments, phone calls and time offs
    if (
      Array.isArray(activities)
      && activity?.conflictingActivityIds instanceof Map
      && activity?.conflictingActivityIds.size > 0
    ) {
      activity.conflictingActivityIds.forEach((value, activityId) => {
        const idx = activities.findIndex(a => a.offlineId === activityId);
        if (idx >= 0 && activities[idx]?.conflictingActivityIds.has(activity.offlineId)) {
          activities[idx]?.conflictingActivityIds.delete(activity.offlineId);
        }
      });
    }

    // Clear conflicting events
    if (
      Array.isArray(events)
      && activity instanceof EventActivity
      && activity?.conflictingEventIds instanceof Map && activity?.conflictingEventIds.size > 0
    ) {
      activity.conflictingEventIds.forEach((value, activityId) => {
        const idx = events.findIndex(a => a.offlineId === activityId);
        if (idx >= 0 && events[idx]?.conflictingEventIds.has(activity.offlineId)) {
          events[idx]?.conflictingEventIds.delete(activity.offlineId);
        }
      });
    }

    // Clear self at the end
    if (activity?.conflictingActivityIds instanceof Map) {
      activity.conflictingActivityIds.clear();
    }
    if (activity instanceof EventActivity && activity?.conflictingEventIds instanceof Map) {
      activity.conflictingEventIds.clear();
    }
  } catch (error) {
    console.error('removeConflictIdFromOtherActivities: ', error);
  }
}

export function checkTimeConflictWithOtherActivities(
  activity: Activity,
  activities: Activity[],
  activityConflictCheckStartIdx: number,
  eventsToolData: EventActivity[],
  eventConflictCheckLastIdx: number,
  userId: string,
) {
  try {
    if (isValidActivityToCheckTimeConflict(activity, userId)) {
      activity.conflictingActivityIds = new Map();
      if (activity instanceof EventActivity) {
        activity.conflictingEventIds = new Map();
      }

      checkConflictAgainstActivities(
        activities,
        activity,
        activityConflictCheckStartIdx,
        userId,
      );
      checkConflictAgainstEvents(
        eventsToolData,
        activity,
        eventConflictCheckLastIdx,
        userId,
      );
    }
  } catch (error) {
    console.error('checkTimeConflictWithOtherActivities: ', error);
  }
}

function _warnCloseByMeeting(sortedList: Activity[], startIdx: number, nextIdx: number, userId: string, proximityRange: number, shouldCheckAccountVisit: boolean) {
  try {
    const baseActivity: AppointmentActivity = sortedList[startIdx] as AppointmentActivity;
    const isBaseAccountVisitCheck = shouldCheckAccountVisit && _isAccountVisitOrNestedMeetingActivity(baseActivity as AppointmentActivity);
    for (let j = nextIdx; j < sortedList.length; j++) {
      const activityToCheck: AppointmentActivity = sortedList[j] as AppointmentActivity;
      const isActivityToCheckAccountVisitCheck = shouldCheckAccountVisit && _isAccountVisitOrNestedMeetingActivity(activityToCheck as AppointmentActivity);
      // Skip this if two activities have parent child account visit relationship
      // Or if one or both are nested meeting
      const isAnyNestedMeeting = isBaseAccountVisitCheck.isNestedMeeting || isActivityToCheckAccountVisitCheck.isNestedMeeting;
      // Check for account visit parent child relationship
      const isAccountVisitParentChild = (
        isBaseAccountVisitCheck.isAccountVisit && isActivityToCheckAccountVisitCheck.isNestedMeeting
        || isBaseAccountVisitCheck.isNestedMeeting && isActivityToCheckAccountVisitCheck.isAccountVisit
      ) ? _isAccountVisitParentChildOrSibling(baseActivity, activityToCheck) : false;
      if (isAnyNestedMeeting || isAccountVisitParentChild) {
        _warnCloseByMeeting(sortedList, startIdx, j+1, userId, proximityRange, shouldCheckAccountVisit);
        return;
      }
      // Check if appointments have the same contact
      if (_.intersectionBy(baseActivity['contacts'], activityToCheck['contacts'], 'ID').length > 0) {
        const baseActivityStart: Date = _.cloneDeep(baseActivity?.scheduledStart);
        baseActivityStart.setHours(baseActivityStart.getHours() - proximityRange);
        const baseActivityEnd: Date = _.cloneDeep(baseActivity?.scheduledEnd);
        baseActivityEnd.setHours(baseActivityEnd.getHours() + proximityRange);
        const activityToCheckStart: Date = activityToCheck?.scheduledStart;
        const activityToCheckEnd: Date = activityToCheck?.scheduledEnd;
        const isConflict = _checkTwoDateRangesConflict(baseActivityStart, baseActivityEnd, activityToCheckStart, activityToCheckEnd);
        if (isConflict === null || isConflict === true) {
          if (isConflict) {
            if (baseActivity.offlineId && activityToCheck.offlineId) {
              if (baseActivity.closeByActivityIds instanceof Map === false) {
                baseActivity.closeByActivityIds = new Map();
              }
              baseActivity.closeByActivityIds.set(activityToCheck.offlineId, true);
              if (activityToCheck.closeByActivityIds instanceof Map === false) {
                activityToCheck.closeByActivityIds = new Map();
              }
              activityToCheck.closeByActivityIds.set(baseActivity.offlineId, true);
              
              //closeByCompletedActivityIds is for Meeting proximity restriction based on completed meetings
              if (baseActivity.closeByCompletedActivityIds instanceof Map === false) {
                baseActivity.closeByCompletedActivityIds = new Map();
              }
              if(activityToCheck.isCompleted) {
                baseActivity.closeByCompletedActivityIds.set(activityToCheck.offlineId, true);
              }
              if (activityToCheck.closeByCompletedActivityIds instanceof Map === false) {
                activityToCheck.closeByCompletedActivityIds = new Map();
              }
              if(baseActivity.isCompleted) {
                activityToCheck.closeByCompletedActivityIds.set(baseActivity.offlineId, true);
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.error('_flagActivityScheduleConflict: ', error);
  }
}

export function checkProximityWarningAgainstActivities(activity: Activity, sortedList: Activity[], userId: string, proximityRange: number) {
  try {
    if(!activity) return;
    activity.closeByActivityIds = new Map();
    if (proximityRange > 0 && !_.isEmpty(sortedList)) {
      const meetings = sortedList.filter(a => a.type == ActivityType.Appointment && a.offlineId !== activity.offlineId && !_.isEmpty(a['contacts']));
      if (!_.isEmpty(meetings)) {
        const startDate: Date = _.cloneDeep(activity?.scheduledStart);
        startDate.setHours(startDate.getHours() - proximityRange);
        const endDate: Date = _.cloneDeep(activity?.scheduledEnd);
        endDate.setHours(endDate.getHours() + proximityRange);
        for (let i = 0; i < meetings.length; i++) {
          const activityToCheck = meetings[i];
          if (isValidMeetingToCheckCloseByMeeting(activityToCheck, userId) && _.intersectionBy(activity['contacts'], activityToCheck['contacts'], 'ID').length > 0) {
            const isConflict = _checkTwoDateRangesConflict(activityToCheck.scheduledStart, activityToCheck?.scheduledEnd, startDate, endDate);
            if (isConflict) {
              if (activityToCheck.closeByActivityIds instanceof Map === false) {
                activityToCheck.closeByActivityIds = new Map();
              }
              activityToCheck.closeByActivityIds.set(activity.offlineId, true);

              if (activity.closeByActivityIds instanceof Map === false) {
                activity.closeByActivityIds = new Map();
              }
              activity.closeByActivityIds.set(activityToCheck.offlineId, true);
            }
          }
        }
      }
    }
  } catch (error) {
    console.error('checkConflictAgainstActivities: ', error);
  }
}

function isValidMeetingToCheckCloseByMeeting(activity: Activity, userId: string): boolean {
  return activity instanceof AppointmentActivity && (activity.meetingOwnerId === userId ||
    (Array.isArray(activity.accompaniedUserList) && activity.accompaniedUserList.findIndex(a => a.id === userId) >= 0)) && !_.isEmpty(activity.contacts) ? true : false;
}
