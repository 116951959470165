<!-- Start/End Date field for Calendar view -->
<ion-item *ngIf="viewData && viewData.isDateField" [ngClass]="viewData.isNewActivityView?'form-field-container-newActivityView': (viewData.isEmptyRequiredField?'form-field-invalid-input-container':'form-field-container')">
  <ion-label>
    <p class="form-field-label-text">
      <ion-text>{{viewData.label}}</ion-text>
      <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
      <ion-icon  class="customer-assess-tooltip" *ngIf="viewData.ShowToolTipIcon" slot="end" src="assets/imgs/info_default.svg"  (click)="onClickOfToolTipIcon($event)" ></ion-icon> 
    </p>
    <div *ngIf="viewData.inputText" class="form-field-value" [ngClass]="viewData.isReadOnly?'':'hide-content'" (click)="onInputTextClick($event)" tappable>
      <div>{{viewData.inputText}}</div>
    </div>
    <p *ngIf="!viewData.inputText && viewData.customPlaceholderLabel" class="form-field-placeholder-text" [ngClass]="viewData.isReadOnly?'':'hide-content'" (click)="onInputTextClick($event)" tappable>
      <ion-text>{{viewData.customPlaceholderLabel}}</ion-text>
    </p>
  </ion-label>
  <ion-icon slot="end" class="ion-float-right arrow-icon chevron-down-outline" name="chevron-down-outline" [ngClass] = "viewData.isDisabled && viewData.infoText ? 'disabled' : ''" *ngIf="(viewData.showArrow && !viewData.isDisabled) || viewData.showArrowOffline" (click)="onInputTextClick($event)"></ion-icon>
</ion-item>

<!-- Start/End Time field for Time Clock view -->
<ion-item *ngIf="viewData && viewData.isTimeField" [ngClass]="viewData.isNewActivityView?'form-field-container-newActivityView':'form-field-container'">
  <ion-label>
    <p class="form-field-label-text">
      <ion-text>{{viewData.label}}</ion-text>
      <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
      <ion-icon  class="customer-assess-tooltip" *ngIf="viewData.ShowToolTipIcon" slot="end" src="assets/imgs/info_default.svg"  (click)="onClickOfToolTipIcon($event)" ></ion-icon> 
    </p>
    <div item-content class="form-field-input">
      <input *ngIf="viewData.id =='start-time-field'" class="timePicker-input" disabled readonly
        [placeholder]="(viewData.customPlaceholderLabel)?viewData.customPlaceholderLabel:''"
        [ngxTimepicker]="timePickerWithDefaultMinGap" 
        [format]="dateTimeFormatsService.timeToUpperForTimePicker"
        [disabled]="viewData.isDisabled || isSyncInProgress"
        [value]="formattedTimeValue(viewData)" 
        [min]="(viewData.fromViewPage == 'email')? dateTimeFormatsService.limitedMinTimeValueForMessage : defaultLimitedMinTimeValue"
        (click)="onInputTextClick($event)">
      <input *ngIf="viewData.id =='end-time-field'" class="timePicker-input" disabled readonly
        [placeholder]="(viewData.customPlaceholderLabel)?viewData.customPlaceholderLabel:''"
        [ngxTimepicker]="timePickerWithDefaultMinGap" 
        [format]="dateTimeFormatsService.timeToUpperForTimePicker"
        [disabled]="viewData.isDisabled || isSyncInProgress"
        [value]="formattedTimeValue(viewData)" 
        (click)="onInputTextClick($event)"
        [min]="dateTimeFormatsService.limitedMinTimeValue">

      <!-- Popover - Start/End Time picker -->
      <ngx-material-timepicker #timePickerWithDefaultMinGap
        (timeSet)="setTimePickerValue($event, viewData.id)"
        (opened)="openedTimePicker(viewData)"
        (closed)="closedTimePicker(viewData.id)"
        (hourSelected)="hourSelectedTimePicker($event)"
        [cancelBtnTmpl]="cancelBtn" 
        [confirmBtnTmpl]="confirmBtn"
        [timepickerClass]="dateTimeFormatsService.timeToUpperForTimePicker == 24 ? 'alignToCenterTimeDisplay' : ''" 
        [disableAnimation]="true"
      ></ngx-material-timepicker>
      <!-- Popover - Start/End Time picker buttons -->
      <ng-template #cancelBtn>
        <ion-button class="generalCancel" fill="clear" color="primary">{{ 'CANCEL' | translate}}</ion-button>
      </ng-template>
      <ng-template #confirmBtn>
        <ion-button [disabled]="!isEnabledDoneBtn" class="generalDone" fill="clear" color="primary">{{ 'DONE' | translate}}</ion-button>
      </ng-template>
    </div>
  </ion-label>
  <ion-icon slot="end" class="ion-float-right arrow-icon chevron-down-outline" name="chevron-down-outline" [ngClass] = "viewData.isDisabled && viewData.infoText ? 'disabled' : ''" *ngIf="viewData.showArrow && !viewData.isDisabled" (click)="onIconClick($event)"></ion-icon>
</ion-item>

<!-- Duration field -->
<ion-item *ngIf="viewData && (viewData.isDurationField || viewData.isTimePeriodField)" [ngClass]="viewData.isNewActivityView?'form-field-container-newActivityView':'form-field-container'">
  <ion-label>
    <p class="form-field-label-text">
      <ion-text>{{viewData.label}}</ion-text>
      <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
    </p>
    <div *ngIf="viewData.inputText" class="form-field-value" [ngClass]="viewData.isReadOnly?'':'hide-content'" (click)="onInputTextClick($event)">
      {{viewData.inputText}}
    </div>
  </ion-label>
  <ion-icon slot="end" class="ion-float-right arrow-icon chevron-down-outline" name="chevron-down-outline" *ngIf="viewData.showArrow && !viewData.isDisabled" (click)="onInputTextClick($event)"></ion-icon>
</ion-item>

<!-- Empty mandatory field - Error message -->
<ion-item *ngIf="errorMessageData && errorMessageData.isRequired && errorMessageData.isEmptyRequiredField && errorMessageData.errorMessage" class="error-message-container">
  <ion-label class="error-message-text">{{errorMessageData.errorMessage}}</ion-label>
</ion-item>

<!-- Popover - Start/End Date Calendar -->
<ion-content *ngIf="!viewData">
  <ion-header >
    <ion-toolbar>
      <ion-buttons slot="start">
          <ion-button class="generalCancel" fill="clear" color="primary" (click)="onDismiss(false)">{{ 'CANCEL' | translate}}</ion-button>
      </ion-buttons>
      <ion-title class="ion-text-center" class="generalTitle">{{datepickerTitle}}</ion-title>
      <ion-buttons slot="end">
          <ion-button class="generalDone" fill="clear" color="primary" (click)="onDismiss(true)">{{ 'DONE' | translate}}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-list>
    <ion-item class="datePicker" *ngIf="currentPickerType == 'startDateCalendarPicker'">
      <ion-calendar [(ngModel)]="selectedStartDate"                
                    (change)="handleStartDate($event)" 
                    (monthChange)="monthChange($event)"
                    [options]="optionsForStartDate"
                    [format]="'YYYY-MM-DD'"
      ></ion-calendar>
    </ion-item>
    <ion-item class="datePicker" *ngIf="currentPickerType == 'endDateCalendarPicker'">
      <ion-calendar [(ngModel)]="selectedEndDate"                
                    (change)="handleEndDate($event)"
                    (monthChange)="monthChange($event)"
                    [options]="optionsForEndDate"
                    [format]="'YYYY-MM-DD'"
      ></ion-calendar>
    </ion-item>
  </ion-list>
</ion-content>