export enum MeasureType {
  meeting = 'MEETING',
  message = 'MESSAGE',
  callPlan = 'CALL_PLAN',
  coaching = 'COACHING',
  procedure = 'PROCEDURE',
  proceduretracker= 'PROCEDURETRACKER',
  salesOrder='SALES_ORDER'

}

export enum MeasureIcon {
  MEETING = 'indegene-assets/imgs/appointment.svg',
  MESSAGE = 'indegene-assets/imgs/email.svg',
  COACHING = 'indegene-assets/imgs/tools_coaching.svg',
  PROCEDURE = 'indegene-assets/imgs/order_open.svg',
  SALES_ORDER = 'indegene-assets/imgs/sales-data.svg'

}

export enum CommonDimensions {
  yearMonth = 'yearMonth',
  users = 'users',
}

export type MeasureBoardTypesFromDynamics = 'Meetings & Phone Calls' | 'Messages' | 'Coachings & Covisitor Meetings' | 'Procedures';
