export enum ProcedureKPI {
  customerReach = 'Customer Reach (YTD)',
  ytd = 'YTD',
  mtd = 'MTD',
  wtd = 'WTD',
  yesterday = 'Yesterday',
  contacts = 'Total Contacts',
  accounts = 'Total Accounts',
  completedTrend = 'Completed Trend',
}

export enum ProcedureCardKpiId {
  myCustomers = 'myCustomers',
  byContact = 'byContact',
  byAccount = 'byAccount',
}

export enum ProcedureChartId {
  bySurgery = 'bySurgery',
  trend = 'trend',
}
