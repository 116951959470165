import { SampleAllocationDTO } from '../../models/sample-model';
import { CustomerSampleAllocation } from './customer-sample-allocation.class';
import { isWithinRange, isToday } from 'date-fns';
import { Utility } from '../../utility/util';

export class SampleAllocation {
    brandId: string;
    sampleSKUId: string;
    sampleSKUName: string;
    startDate: Date;
    endDate: Date;
    statecode: number;
    statuscode: number;

    totalRemaining: number = 0;
    needToUpdateTotalRemaining: boolean = true;

    // All of the customer sample allocations
    currentCustomerSampleAllocations: CustomerSampleAllocation[] = [];

    _id: string;
    _rev: string;
    lastUpdatedTime: number;

    constructor(raw: SampleAllocationDTO) {
        this._id = raw._id ? raw._id : null;
        this._rev = raw._rev ? raw._rev : null;
        this.lastUpdatedTime = raw.lastUpdatedTime ? raw.lastUpdatedTime : null;
        this.sampleSKUId = raw.indskr_skuid;
        this.sampleSKUName = raw.indskr_skuname;
        this.brandId = raw.indskr_productid;
        this.startDate = Utility.changeUTCDateToLocalDateWith0Time(parseInt(raw.indskr_startdate));
        this.endDate = Utility.changeUTCDateToLocalDateWith0Time(parseInt(raw.indskr_enddate),true);
        this.statecode = raw.statecode;
        this.statuscode = raw.statuscode;

        this.currentCustomerSampleAllocations = [];
    }
}

export class SampleAllocationMetas {
    skuId: string;
    skuName: string;
    sampleOrderActivityMetas: Array<SampleOrderActivityMetas>;
}

export class SampleOrderActivityMetas {
    id: string;
    subject: string;
    contactName: string;
    contactID:string;
    startDate: Date;
}