import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { Endpoints } from "../../../config/endpoints.config";
import { HttpClient } from "@angular/common/http";
import { DiskService } from "../disk/disk.service";
import { DeltaService, EntityNames, EntitySyncInfo } from "../../data-services/delta/delta.service";
import { Contact } from "../../classes/contact/contact.class";
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import _, {cloneDeep} from 'lodash';
import { Presentation } from "../../classes/presentation/presentation.class";
import { Resource } from "../../classes/resource/resource.class";
import {ResourceEmailTemplate} from "@omni/classes/email-templates/email-template.class";
import {EmailActivityParty} from "@omni/classes/activity/email.activity.class";
import {DeviceService} from "@omni/services/device/device.service";
import {EmailService} from "@omni/services/email-templates/email.service";
import { Utility } from "@omni/utility/util";

export enum CONTENTMATCHTYPE {
    "PRESENTATIONS", "RESOURCES", "DOCUMENTS", "EMAIL_TEMPLATES"
}

export interface IcontentMatch {
    contactId: string,
    presentations: Array<object>,
    resources: Array<object>,
    documents: Array<object>
}

export interface IContentMatchMessages {
    contactId: string,
    emailtemplates: Array<object>
}

@Injectable({
  providedIn: 'root'
})
export class ContentMatchingService {

    public contentMatching: Array<IcontentMatch> = [];
    public contentMatchingMessages: Array<IContentMatchMessages> = [];
    public isContentMatchingApplied: boolean = false;
    public isNewContactAddedToMeeting: boolean = false;
    constructor(
        private authenticationOfflineService: AuthenticationService,
        private http: HttpClient,
        private disk: DiskService,
        private deltaService: DeltaService,
        private user: AuthenticationService,
        private deviceService: DeviceService,
        private emailService: EmailService
    ) {

    }
    async getContentMatching(loadFromDbOnly = false):Promise<any>{

        if (!this.user.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) return;

        const contentMatchingSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.contentMatching,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };

        let response: any;
        let url;



        if (loadFromDbOnly) {
            const doc = await this.disk.retrieve(DB_KEY_PREFIXES.CONTENT_MATCHING, true);
            if (doc?.contentmatching) {
              this.mapContentMatching(doc.contentmatching, true, true);
            }
        }else{

            let contentMatchingSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTENT_MATCHING);
            const isInitialSyncContentMatch = (contentMatchingSyncState && contentMatchingSyncState.lastUpdatedTime) ? false : true;
            if (!isInitialSyncContentMatch) {
                url = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contentmatching.GET_CONTENT_MATCHING_DELTA;
                url = url.replace('{positionid}', this.user.user.xPositionID);
                url = url.replace('{lastupdatedTime}', contentMatchingSyncState.lastUpdatedTime);
                console.log("check this" + url)
            } else {
                url = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contentmatching.GET_CONTENT_MATCHING;
                url = url.replace('{positionid}', this.user.user.xPositionID);
            }

            const newLastUpdatedTime = new Date().getTime();

            try {
                response = await this.http.get(url).toPromise();
                if (response) {
                    contentMatchingSyncInfo.totalSynced = response.length;
                    await this.mapContentMatching(response, false);
                }
            } catch (err) {
                this.deltaService.addSyncErrorToEntitySyncInfo(contentMatchingSyncInfo, url, err);
            }

            this.deltaService.addEntitySyncInfo(contentMatchingSyncInfo);

            if (contentMatchingSyncInfo.syncStatus) {
                contentMatchingSyncState.lastUpdatedTime = newLastUpdatedTime;
                await this.disk.updateSyncState(contentMatchingSyncState);
            }
        }


    }

    async getContentMatchingMessages(loadFromDbOnly = false):Promise<any>{

      if (!this.user.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) return;

      let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;

      let messageContentsResponse;
      let messageTemplateContentsUrl;

      if (offlineFallback) {
        const doc = await this.disk.retrieve(DB_KEY_PREFIXES.CONTENT_MATCHING_MESSAGES, true);
        if (doc?.contentmatching_messages) {
          await this.mapContentMatchingMessages(doc.contentmatching_messages, true, true);
        }
      }else{

        let contentMatchingSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTENT_MATCHING_MESSAGES);
        const isInitialSyncContentMatch = !contentMatchingSyncState || !contentMatchingSyncState.lastUpdatedTime;
        const contentMatchingSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.contentMatchingMessages,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        if (!isInitialSyncContentMatch) {
          messageTemplateContentsUrl = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contentmatching.GET_MESSAGE_CONTENT_MATCHING_DELTA;
          messageTemplateContentsUrl = messageTemplateContentsUrl.replace('{positionid}', this.user.user.xPositionID);
          messageTemplateContentsUrl = messageTemplateContentsUrl.replace('{lastupdatedTime}', contentMatchingSyncState.lastUpdatedTime);
          console.log("check this" + messageTemplateContentsUrl)
        } else {
          messageTemplateContentsUrl = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contentmatching.GET_MESSAGE_CONTENT_MATCHING;
          messageTemplateContentsUrl = messageTemplateContentsUrl.replace('{positionid}', this.user.user.xPositionID);
        }

        const newLastUpdatedTime = new Date().getTime();

        try {
          messageContentsResponse = await this.http.get(messageTemplateContentsUrl).toPromise();
          if (messageContentsResponse) {
            await this.mapContentMatchingMessages(messageContentsResponse, false);
          }
          contentMatchingSyncState.lastUpdatedTime = newLastUpdatedTime;
          await this.disk.updateSyncState(contentMatchingSyncState);
          if (Array.isArray(messageContentsResponse)) {
            contentMatchingSyncInfo.totalSynced = messageContentsResponse.length;
          }
          this.deltaService.addEntitySyncInfo(contentMatchingSyncInfo);
          console.log(`Sync status : ${JSON.stringify(contentMatchingSyncState)} initial sync : ${isInitialSyncContentMatch}`);
        } catch (err) {
          this.deltaService.addSyncErrorToEntitySyncInfo(contentMatchingSyncInfo, messageTemplateContentsUrl, err);
          this.deltaService.addEntitySyncInfo(contentMatchingSyncInfo);
        }
      }

    }

    async mapContentMatching(response: IcontentMatch[], doNotSave?: boolean, loadFromDbOnly = false) {

        // this.contentMatching = [];
        let diskData = !loadFromDbOnly ? await this.disk.retrieve(DB_KEY_PREFIXES.CONTENT_MATCHING, true) : null;

        this.contentMatching = (diskData) ? diskData['contentmatching'] : [];

        if(response && Array.isArray(response)){
            response.map(contact => {
                let dbContact = this.contentMatching.find(data => data.contactId === contact.contactId);
                if (dbContact) {
                    dbContact = this.updateDbContentMatch('presentations', contact, dbContact);
                    dbContact = this.updateDbContentMatch('resources', contact, dbContact);
                    dbContact = this.updateDbContentMatch('documents', contact, dbContact);
                } else {
                    if (contact.presentations) contact.presentations = contact.presentations.filter((pres) => pres['statusCode'] === 'ACTIVE');
                    if (contact.resources) contact.resources = contact.resources.filter((res) => res['statusCode'] === 'ACTIVE');
                    if (contact.documents) contact.documents = contact.documents.filter((docs) => docs['statusCode'] === 'ACTIVE');

                    this.contentMatching.push(contact);
                }
            });
            // this.contentMatching.push(...response);
        }

        console.log(this.contentMatching);
        if (!doNotSave) {
            //Save to DB or update
            this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTENT_MATCHING, () => {
                return { contentmatching: this.contentMatching };
            });
        }
    }

    async mapContentMatchingMessages(response: IContentMatchMessages[], doNotSave?: boolean, loadFromDbOnly = false) {

      let diskData = !loadFromDbOnly ? await this.disk.retrieve(DB_KEY_PREFIXES.CONTENT_MATCHING_MESSAGES, true) : null;

      this.contentMatchingMessages = (diskData) ? diskData['contentmatching_messages'] : [];

      if(response && Array.isArray(response)){
        response.map(contact => {
          let dbContact = this.contentMatchingMessages.find(data => data.contactId === contact.contactId);
          if (dbContact) {
            dbContact = this.updateDbContentMatch('emailtemplates', contact, dbContact);
          } else {
            if (contact.emailtemplates) contact.emailtemplates = contact.emailtemplates.filter((docs) => docs['statusCode'] === 'ACTIVE');

            this.contentMatchingMessages.push(contact);
          }
        });
      }

      console.log(this.contentMatchingMessages);
      if (!doNotSave) {
        //Save to DB or update
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTENT_MATCHING_MESSAGES, () => {
          return {contentmatching_messages: this.contentMatchingMessages};
        });
      }
    }

    updateDbContentMatch(entity: string, contact, dbContact) {
        if (contact[entity]) {
            contact[entity].forEach(pres => {
                if (pres['statusCode'] === 'ACTIVE') {
                    dbContact[entity] = (dbContact[entity]) ? dbContact[entity] : [];
                    let dbPres = Array.isArray(dbContact[entity]) ? dbContact[entity].find(pr => pr.contentId === pres.contentId) : null;
                    if (dbPres) {
                        dbPres = pres;
                    } else {
                        dbContact[entity].push(pres);
                    }
                } else {
                    const idx = Array.isArray(dbContact[entity]) ? dbContact[entity].findIndex(dbPres => dbPres['contentId'] === pres['contentId']) : -1;
                    if (idx > -1) {
                        dbContact[entity].splice(idx, 1);
                    }
                }
            });
        } else {
            // if(dbContact[entity]){
            //     delete dbContact[entity];
            // };
        }
        return dbContact;
    }
    SelectedContactsPresentation(collection, matchType: CONTENTMATCHTYPE, selectedActivity) {
        let newArray = [];
        let resultArray = [];
        let BreakException = {};
        let guestCount = selectedActivity['contacts'].filter(contect => contect.isguest).length;
        this.isContentMatchingApplied = false;
        if (!selectedActivity || (selectedActivity['contacts'].length === 0)) {  //|| (selectedActivity['contacts'].length === guestCount)
            return [];
        }

        try {
            selectedActivity['contacts'].forEach((contact: Contact) => {
                if (!contact.isguest) {
                    let row = this.contentMatching.find(content => (content.contactId == contact.ID));
                    if (row) {
                        // this.isContentMatchingApplied = true;
                        let items = [];
                        if (matchType === CONTENTMATCHTYPE.PRESENTATIONS) {
                            // items = (row.presentations) ? row.presentations.map(pres=> pres['contentId']) : [];
                            if (row.presentations) {
                                this.isContentMatchingApplied = true;
                                items = row.presentations.map(pres => pres['contentId']);
                            } else {
                                items = [];
                            }
                        } else {
                            // items = (row.resources) ? [...row.resources.map(res=> res['contentId'])] : [];
                            // items = (row.documents) ? [...items,...row.documents.map(res=> res['contentId'])] : items;
                            if (row.resources) {
                                this.isContentMatchingApplied = true;
                                items = [...row.resources.map(res => res['contentId'])];
                            } else {
                                items = [];
                            }
                            if (row.documents) {
                                this.isContentMatchingApplied = true;
                                items = [...items, ...row.documents.map(res => res['contentId'])];
                            }
                        }
                        if (items.length > 0) newArray.push(items);
                    }
                }
            });

            if (!this.isContentMatchingApplied && matchType === CONTENTMATCHTYPE.RESOURCES) return collection;

            resultArray = _.intersection(...newArray);

            if (resultArray.length > 1) {
                resultArray = [... new Set(resultArray)];
            } else if (resultArray.length === 0) {
                return [];
            }

            if (matchType === CONTENTMATCHTYPE.PRESENTATIONS) {
                resultArray = collection.filter(item => resultArray.indexOf(item.ioPresentationId) > -1);
            } else if (matchType === CONTENTMATCHTYPE.RESOURCES) {
                resultArray = collection.filter(item => (resultArray.indexOf(item.ioResourceId) > -1) || (resultArray.indexOf(item.ioDocumentId) > -1));
            }

            return resultArray;

        } catch (err) {
            if (err !== BreakException) throw err;
            return [];
        }
    }

    getAllMatchedContents(presentations: Presentation[], resources: Resource[], contacts: Contact[]) {

        let BreakException = {};
        if ((presentations.length == 0 && resources.length == 0) || contacts.length === 0) return [];
        try {
            let contents: (Presentation | Resource)[] = resources.filter(r => r.fileType !== 'unsupported');
            contents = _.concat(contents, presentations);
            let matchedContents = [];
            contacts.forEach((contact: Contact) => {
                if (!contact.isguest) {
                    let row = this.contentMatching.find(content => (content.contactId == contact.ID));
                    if (row) {
                        let items = [];
                        if (row.presentations) items = row.presentations.map(pres => pres['contentId']);
                        if (row.resources) items = _.concat(items, row.resources.map(res => res['contentId']));
                        if (row.documents) items = _.concat(items, row.documents.map(res => res['contentId']));
                        if (items.length > 0) matchedContents.push(items);
                    }
                }
            });
            if (matchedContents.length === 0) return [];

            let resultArray = _.intersection(...matchedContents);
            resultArray = [...new Set(resultArray)];
            return contents.filter(item => resultArray.indexOf(item.contentId) > -1);

        } catch (err) {
            if (err !== BreakException) throw err;
            return [];
        }
    }

    fetchMatchedTemplates(contacts: EmailActivityParty[]) {
      let BreakException = {};
      if (!this.emailService.filteredTemplates.length || !contacts.length) return [];
      try {
        let matchedContents = [];
        contacts.forEach((contact: EmailActivityParty) => {
          // if (!contact.isguest) {
            let row = this.contentMatchingMessages.find(content => (content.contactId == contact.indskr_contactid));
            if (row) {
              let items = [];
              if (row.emailtemplates) items = row.emailtemplates.map(pres => pres['contentId']);
              if (items.length > 0) matchedContents.push(items);
            }
          // }
        });
        if (matchedContents.length === 0) return [];

        let resultArray = _.intersection(...matchedContents);
        resultArray = [...new Set(resultArray)];
        return this.emailService.filteredTemplates.filter(item => item.statuscode === 1 && resultArray.indexOf(item.indskr_emailtemplateid) > -1 && this.filterExpiredTemplates(item));

      } catch (err) {
        if (err !== BreakException) throw err;
        return [];
      }
    }

    private filterExpiredTemplates(template: ResourceEmailTemplate): boolean {
      if (!template.indskr_available_from && !template.indskr_available_until)
        return true;
  
      const today = Utility.changeUTCDateToLocalDateWith0Time(
        new Date().getTime(),
        true
      );
      const availableFrom = Number(template.indskr_available_from);
      const availableUntil = Number(template.indskr_available_until);
  
      console.log(Utility.changeUTCDateToLocalDateWith0Time(
        availableFrom,
        true
        ).getTime()
      );
  
      console.log(Utility.changeUTCDateToLocalDateWith0Time(
        availableUntil,
        true
        ).getTime()
      );
      if (!availableFrom && !availableUntil) {
        return true;
      } else if (availableFrom && availableUntil)
        return (
          Utility.changeUTCDateToLocalDateWith0Time(
            availableFrom,
            true
          ).getTime() <= today.getTime() &&
          Utility.changeUTCDateToLocalDateWith0Time(
            availableUntil,
            true
          ).getTime() >= today.getTime()
        );
      else if (availableFrom && !availableUntil) {
        return (
          Utility.changeUTCDateToLocalDateWith0Time(
            availableFrom,
            true
          ).getTime() <= today.getTime()
        );
      } else if (!availableFrom && availableUntil) {
        return (
          Utility.changeUTCDateToLocalDateWith0Time(
            availableUntil,
            true
          ).getTime() >= today.getTime()
        );
      }
    }
}
