export const ADDRESS_FETCH_QUERIES = {
  buildings: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="msevtmgt_building">
    <attribute name="msevtmgt_buildingid" />
    <attribute name="msevtmgt_name" />
    <link-entity name="indskr_lu_city" from="indskr_lu_cityid"
      to="indskr_city" link-type="outer" alias="eb">
      <attribute name="indskr_lu_cityid" alias="cityId"/>
      <attribute name="indskr_name" alias="cityName"/>
    </link-entity>
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
      <condition attribute="statuscode" operator="eq" value="1" />
    </filter>
  </entity>
</fetch>`,
};
