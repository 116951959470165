import { ControlDataType } from "../../../classes/dynamic-form/dynamic-form.class"

export const SHARED_CREATE_FORM = [
  // #region EMAIL ADDRESS 
  {
    indskr_entityid: null,
    indskr_entityname: "indskr_email_address",
    indskr_formid: "",
    indskr_formtype: 548910000,
    indskr_metadata: {
      EntityLogicalName: "indskr_email_address",
      EntitySetName: "indskr_email_addresses",
      SchemaName: "indskr_email_address",
      Tabs: [
        {
          Controls: [
            {
              AttributeName: "indskr_emailaddress",
              DataType: "StringType",
              DateFormat: null,
              Format: "Email",
              Descriptions: [
                {
                  Description: "Enter Email",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "Email",
                  LanguageCode: "0000"
                }
              ],
              IsList: false,
              LookupEntityPrimaryIdAttribute: null,
              LookupEntityPrimaryNameAttribute: null,
              LookupEntitySetName: null,
              LookupQueries: [],
              LookupTargets: null,
              MaxLength: 100,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              Precision: 0,
              ReadOnly: false,
              Required: true,
              SchemaName: "indskr_EmailAddress",
              Subgrid: null,
              Visible: true,
            },
            {
              AttributeName: "indskr_isprimary",
              DataType: "BooleanType",
              DateFormat: null,
              Descriptions: [
                {
                  Description: "Select ",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "Is Primary",
                  LanguageCode: "0000"
                }
              ],
              IsList: false,
              LookupEntityPrimaryIdAttribute: null,
              LookupEntityPrimaryNameAttribute: null,
              LookupEntitySetName: null,
              LookupQueries: [],
              LookupTargets: null,
              MaxLength: 0,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              Precision: 0,
              ReadOnly: false,
              Required: false,
              SchemaName: "indskr_IsPrimary",
              Subgrid: null,
              Visible: true,
            }
          ],
          DisplayNames: [
            {
              Description: "Email",
              LanguageCode: "0000"
            }
          ]
        }
      ]
    },
    indskr_mobileappformid: "",
    indskr_name: "EmailAddress - Create",
    indskr_validate: false,
    overriddencreatedon: null,
    statecode: 0,
    statuscode: 1,
  },
  // #endregion EmailAddress

  // #region CustomerAddress
  {
    indskr_entityid: null,
    indskr_entityname: "indskr_indskr_customeraddress_v2",
    indskr_formid: "",
    indskr_formtype: 548910000,
    indskr_metadata: {
      EntityLogicalName: "indskr_indskr_customeraddress_v2",
      EntitySetName: "indskr_indskr_customeraddress_v2s",
      SchemaName: "indskr_indskr_customeraddress_v2",
      Tabs: [
        {
          Controls: [],
          DisplayNames: [
            {
              Description: "ADDRESS",
              LanguageCode: "0000"
            }
          ]
        }
      ]
    },
    indskr_mobileappformid: "",
    indskr_name: "CustomerAddress - Create",
    indskr_validate: false,
    overriddencreatedon: null,
    statecode: 0,
    statuscode: 1,
  },
  //#endregion CustomerAddress

  //#region AccountContact
  {
    indskr_entityid: null,
    indskr_entityname: "indskr_accountcontactaffiliation",
    indskr_formid: "",
    indskr_formtype: 548910000,
    indskr_metadata: {
      EntityLogicalName: "indskr_accountcontactaffiliation",
      EntitySetName: "indskr_accountcontactaffiliations",
      Tabs: [
        {
          Controls: [
            {
              AttributeName: "indskr_accountid",
              DataType: "LookupType",
              DateFormat: null,
              Descriptions: [
                {
                  Description: "SELECT_ACCOUNT",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "ACCOUNT",
                  LanguageCode: "0000"
                }
              ],
              IsList: false,
              LookupEntityPrimaryIdAttribute: "accountid",
              LookupEntityPrimaryNameAttribute: "name",
              LookupEntitySetName: "accounts",
              LookupQueries: [
                {
                  EntityName: "account",
                  FetchXml: `
                    <fetch returntotalrecordcount='true' count="{0}" version="1.0" output-format="xml-platform" mapping="logical">
                      <entity name="account">
                        <attribute name="name" />
                        <attribute name="address1_city" />
                        <order attribute="name" descending="false" /><attribute name="telephone1" />
                        <filter type="or">
                          <condition attribute="name" operator="like" value="{0}" />
                        </filter>
                        <attribute name="accountid" />
                        <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" alias="customerposition">
                          <link-entity name="position" from="positionid" to="indskr_positionid" alias="position">
                            <filter type="and">
                              <condition attribute="positionid" operator="in">
                                {PositionFilter}
                              </condition>
                            </filter>
                          </link-entity>
                        </link-entity>
                      </entity>
                    </fetch>`,
                  ObjectTypeCode: 1
                }
              ],
              LookupTargets: [
                "account"
              ],
              MaxLength: 0,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              SchemaName: "indskr_accountid",
              Precision: 0,
              ReadOnly: false,
              Required: true,
              Subgrid: null,
              Visible: true,
            },
            {
              AttributeName: "indskr_contactid",
              DataType: "LookupType",
              DateFormat: null,
              Descriptions: [
                {
                  Description: "SELECT_CONTACT",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "CONTACT_FORM",
                  LanguageCode: "0000"
                }
              ],
              IsList: false,
              LookupEntityPrimaryIdAttribute: "contactid",
              LookupEntityPrimaryNameAttribute: "fullname",
              LookupEntitySetName: "contacts",
              LookupQueries: [
                {
                  EntityName: "contact",
                  FetchXml: `
                    <fetch returntotalrecordcount='true' count="{0}" version="1.0" output-format="xml-platform" mapping="logical">
                      <entity name="contact">
                        <attribute name="fullname" />
                        <attribute name="address1_city" />
                        <order attribute="fullname" descending="false" /><attribute name="telephone1" />
                        <filter type="or">
                          <condition attribute="fullname" operator="like" value="{0}" />
                        </filter>
                        <attribute name="contactid" />
                        <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" alias="customerposition">
                          <link-entity name="position" from="positionid" to="indskr_positionid" alias="position">
                            <filter type="and">
                              <condition attribute="positionid" operator="in">
                                {PositionFilter}
                              </condition>
                            </filter>
                          </link-entity>
                        </link-entity>
                      </entity>
                    </fetch>`,
                  ObjectTypeCode: 1
                }
              ],
              LookupTargets: [
                "contact"
              ],
              MaxLength: 0,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              SchemaName: "indskr_contactid",
              Precision: 0,
              ReadOnly: false,
              Required: true,
              Subgrid: null,
              Visible: true,
            },
            {
              AttributeName: "omnione_role",
              DataType: "LookupType",
              DateFormat: null,
              Descriptions: [
                {
                  Description: "SELECT_AFFILIATION_ROLE",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "AFFILIATION_ROLE",
                  LanguageCode: "0000"
                }
              ],
              Format: null,
              IsCustomAttribute: true,
              IsList: false,
              LookupEntityPrimaryIdAttribute: "omnione_roleid",
              LookupEntityPrimaryNameAttribute: "omnione_name",
              LookupEntitySetName: "omnione_roles",
              LookupQueries: [
                {
                  EntityName: "omnione_role",
                  FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="omnione_role"><attribute name="omnione_roleid" /><attribute name="omnione_name" /><attribute name="createdon" /><order attribute="omnione_name" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or" ><condition attribute="omnione_name" operator="like" value="{0}" /></filter></entity></fetch>`,
                  ObjectTypeCode: 11029
                }
              ],
              LookupTargets: [
                "omnione_role"
              ],
              MaxLength: 0,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              SchemaName: "omnione_Role",
              Precision: 0,
              ReadOnly: false,
              Required: false,
              Subgrid: null,
              Visible: true,
            },
          ],
          DisplayNames: [
            {
              Description: "ACCOUNT_CONTACT_AFFILIATION_CAP",
              LanguageCode: "0000"
            }
          ]
        }
      ]
    },
    indskr_mobileappformid: "",
    indskr_name: "AccountContact - Create",
    indskr_validate: false,
    overriddencreatedon: null,
    statecode: 0,
    statuscode: 1,
  },
  //#endregion Account Contact

  //#region  Account Account
  {
    indskr_entityid: null,
    indskr_entityname: "indskr_accountaccountaffiliation",
    indskr_formid: "",
    indskr_formtype: 548910000,
    indskr_metadata: {
      EntityLogicalName: "indskr_accountaccountaffiliation",
      EntitySetName: "indskr_accountaccountaffiliations",
      SchemaName: "indskr_accountaccountaffiliation",
      Tabs: [
        {
          Controls: [
            {
              AttributeName: "indskr_affiliatedtoaccountid",
              DataType: "LookupType",
              DateFormat: null,
              Descriptions: [
                {
                  Description: "SELECT_ACCOUNT",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "AFFILIATED_TO",
                  LanguageCode: "0000"
                }
              ],
              IsList: false,
              LookupEntityPrimaryIdAttribute: "accountid",
              LookupEntityPrimaryNameAttribute: "name",
              LookupEntitySetName: "accounts",
              LookupQueries: [
                {
                  EntityName: "account",
                  FetchXml: `
                    <fetch returntotalrecordcount='true' count="{0}" version="1.0" output-format="xml-platform" mapping="logical">
                      <entity name="account">
                        <attribute name="name" />
                        <attribute name="address1_city" />
                        <order attribute="name" descending="false" /><attribute name="telephone1" />
                        <filter type="or">
                          <condition attribute="name" operator="like" value="{0}" />
                        </filter>
                        <attribute name="accountid" />
                        <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" alias="customerposition">
                          <link-entity name="position" from="positionid" to="indskr_positionid" alias="position">
                            <filter type="and">
                              <condition attribute="positionid" operator="in">
                                {PositionFilter}
                              </condition>
                            </filter>
                          </link-entity>
                        </link-entity>
                      </entity>
                    </fetch>`,
                  ObjectTypeCode: 1
                }
              ],
              LookupTargets: [
                "account"
              ],
              MaxLength: 0,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              Precision: 0,
              ReadOnly: false,
              Required: true,
              SchemaName: "indskr_affiliatedtoaccountid",
              Subgrid: null,
              Visible: true,
            },
            {
              AttributeName: "omnione_relationship",
              DataType: "LookupType",
              DateFormat: null,
              Descriptions: [
                {
                  Description: "SELECT_AFFILIATION_TYPE",
                  LanguageCode: "0000"
                }
              ],
              DisplayNames: [
                {
                  Description: "AFFILIATION_TYPE",
                  LanguageCode: "0000"
                }
              ],
              Format: null,
              IsCustomAttribute: true,
              IsList: false,
              LookupEntityPrimaryIdAttribute: "omnione_workplaceaffiliationrelationshipid",
              LookupEntityPrimaryNameAttribute: "omnione_name",
              LookupEntitySetName: "omnione_workplaceaffiliationrelationships",
              LookupQueries: [
                {
                  EntityName: "omnione_workplaceaffiliationrelationship",
                  FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="omnione_workplaceaffiliationrelationship"><attribute name="omnione_workplaceaffiliationrelationshipid" /><attribute name="omnione_name" /><attribute name="createdon" /><order attribute="omnione_name" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or" ><condition attribute="omnione_name" operator="like" value="{0}" /></filter></entity></fetch>`,
                  ObjectTypeCode: 11039
                }
              ],
              LookupTargets: [
                "omnione_workplaceaffiliationrelationship"
              ],
              MaxLength: 0,
              MaxValue: null,
              MinValue: null,
              OptionSet: {
                Options: []
              },
              Precision: 0,
              ReadOnly: false,
              Required: false,
              SchemaName: "omnione_Relationship",
              Subgrid: null,
              Visible: true
            }
          ],
          DisplayNames: [
            {
              Description: "RELATED_ACCOUNT",
              LanguageCode: "0000"
            }
          ]
        }
      ]
    },
    indskr_mobileappformid: "",
    indskr_name: "AccountAccount - Create",
    indskr_validate: false,
    overriddencreatedon: null,
    statecode: 0,
    statuscode: 1,
  },
  //#endregion Account Account
  
]


export const CUSTOM_ADDRESS_FIELDS = [
  {
    AttributeName: "indskr_line1",
    DataType: "StringType",
    DateFormat: null,
    Descriptions: [
      {
        Description: "ENTER_STREET_1_ADDRESS",
        LanguageCode: "0000"
      }
    ],
    DisplayNames: [
      {
        Description: "STREET_1_ADDRESS",
        LanguageCode: "0000"
      }
    ],
    IsList: false,
    LookupEntityPrimaryIdAttribute: null,
    LookupEntityPrimaryNameAttribute: null,
    LookupEntitySetName: null,
    LookupQueries: [],
    LookupTargets: null,
    MaxLength: 250,
    MaxValue: null,
    MinValue: null,
    OptionSet: {
      Options: []
    },
    Precision: 0,
    ReadOnly: false,
    Required: true,
    SchemaName: "indskr_line1",
    Subgrid: null,
    Visible: true,
    IsCustom: true,
  },
  {
    AttributeName: "indskr_country_lu",
    DataType: "LookupType",
    DateFormat: null,
    Descriptions: [
      {
        Description: "SELECT_COUNTRY",
        LanguageCode: "0000"
      }
    ],
    DisplayNames: [
      {
        Description: "COUNTRY",
        LanguageCode: "0000"
      }
    ],
    IsList: false,
    LookupEntityPrimaryIdAttribute: "indskr_lu_countryid",
    LookupEntityPrimaryNameAttribute: "indskr_countryname",
    LookupEntitySetName: "indskr_lu_countries",
    LookupQueries: [
      {
        EntityName: "indskr_lu_country",
        FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical"><entity name="indskr_lu_country"><attribute name="indskr_countryname" /><attribute name="createdon" /><order attribute="indskr_countryname" descending="false" /><filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter><filter type="or"><condition attribute="indskr_countryname" operator="like" value="{0}" /></filter><attribute name="indskr_lu_countryid" /></entity></fetch>`,
        ObjectTypeCode: 10093
      }
    ],
    LookupTargets: [
      "indskr_lu_country"
    ],
    MaxLength: 0,
    MaxValue: null,
    MinValue: null,
    OptionSet: {
      Options: []
    },
    Precision: 0,
    ReadOnly: false,
    Required: false,
    SchemaName: "indskr_Country_LU",
    Subgrid: null,
    Visible: true,
    IsCustom: true,
  },
  {
    AttributeName: "indskr_state_lu",
    DataType: "LookupType",
    DateFormat: null,
    Descriptions: [
      {
        Description: "SELECT_STATE_OR_PROVINCE",
        LanguageCode: "0000"
      }
    ],
    DisplayNames: [
      {
        Description: "STATE_OR_PROVINCE",
        LanguageCode: "0000"
      }
    ],
    IsList: false,
    LookupEntityPrimaryIdAttribute: "indskr_lu_stateid",
    LookupEntityPrimaryNameAttribute: "indskr_state",
    LookupEntitySetName: "indskr_lu_states",
    LookupQueries: [
      {
        EntityName: "indskr_lu_state",
        FetchXml: `
        <fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical">
          <entity name="indskr_lu_state">
            <attribute name="indskr_state" />
            <attribute name="createdon" />
            <order attribute="indskr_state" descending="false" />
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <filter type="or">
              <condition attribute="indskr_statecode" operator="like" value="{0}" />
              <condition attribute="indskr_statecategory" operator="like" value="{0}" />
              <condition attribute="indskr_state" operator="like" value="{0}" />
              <condition attribute="indskr_lu_countryidname" operator="like" value="{0}" />
              <condition attribute="indskr_alternatenames" operator="like" value="{0}" />
            </filter>
            <attribute name="indskr_statecode" />
            <attribute name="indskr_statecategory" />
            <attribute name="indskr_lu_countryid" />
            <attribute name="indskr_alternatenames" />
            <attribute name="indskr_lu_stateid" />
            <link-entity name="indskr_lu_country" from="indskr_lu_countryid" to="indskr_lu_countryid" alias="bb">
              <filter type="and">
                <condition attribute="indskr_lu_countryid" operator="eq" uitype="indskr_lu_country" value="{addressfieldsfilter}"/>
              </filter>
            </link-entity>
          </entity>
        </fetch>`,
        ObjectTypeCode: 10101
      }
    ],
    LookupTargets: [
      "indskr_lu_state"
    ],
    MaxLength: 0,
    MaxValue: null,
    MinValue: null,
    OptionSet: {
      Options: []
    },
    Precision: 0,
    ReadOnly: false,
    Required: false,
    SchemaName: "indskr_State_lu",
    Subgrid: null,
    Visible: true,
    IsCustom: true,
    DependsOn: "indskr_Country_LU",
  },
  {
    AttributeName: "indskr_city_lu",
    DataType: "LookupType",
    DateFormat: null,
    Descriptions: [
      {
        Description: "SELECT_CITY",
        LanguageCode: "0000"
      }
    ],
    DisplayNames: [
      {
        Description: "CITY",
        LanguageCode: "0000"
      }
    ],
    IsList: false,
    LookupEntityPrimaryIdAttribute: "indskr_lu_cityid",
    LookupEntityPrimaryNameAttribute: "indskr_name",
    LookupEntitySetName: "indskr_lu_cities",
    LookupQueries: [
      {
        EntityName: "indskr_lu_city",
        FetchXml: `
          <fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical">
            <entity name="indskr_lu_city">
              <attribute name="indskr_name" />
              <attribute name="indskr_lu_cityid" />
              <order attribute="indskr_name" descending="false" />
              <filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>
              <filter type="or">
                <condition attribute="indskr_statename" operator="like" value="{0}" />
                <condition attribute="indskr_name" operator="like" value="{0}" />
                <condition attribute="indskr_alternatenames" operator="like" value="{0}" />
              </filter>
              <attribute name="indskr_state" />
              <attribute name="indskr_alternatenames" />
              <link-entity name="indskr_lu_state" from="indskr_lu_stateid" to="indskr_state" alias="bb">
                <filter type="and">
                  <condition attribute="indskr_lu_stateid" operator="eq" uitype="indskr_lu_state" value="{addressfieldsfilter}"/>
                </filter>
              </link-entity>
            </entity>
          </fetch>`,
        ObjectTypeCode: 10092
      }
    ],
    LookupTargets: [
      "indskr_lu_city"
    ],
    MaxLength: 0,
    MaxValue: null,
    MinValue: null,
    OptionSet: {
      Options: []
    },
    Precision: 0,
    ReadOnly: false,
    Required: false,
    SchemaName: "indskr_City_lu",
    Subgrid: null,
    Visible: true,
    IsCustom: true,
    DependsOn: "indskr_State_lu",
  },
  {
    AttributeName: "indskr_postalcode_lu",
    DataType: "LookupType",
    DateFormat: null,
    Descriptions: [
      {
        Description: "SELECT_POSTAL_CODE",
        LanguageCode: "0000"
      }
    ],
    DisplayNames: [
      {
        Description: "POSTAL_CODE",
        LanguageCode: "0000"
      }
    ],
    IsList: false,
    LookupEntityPrimaryIdAttribute: "indskr_lu_postalcodeid",
    LookupEntityPrimaryNameAttribute: "indskr_postalcode",
    LookupEntitySetName: "indskr_lu_postalcodes",
    LookupQueries: [
      {
        EntityName: "indskr_lu_postalcode",
        FetchXml: `
          <fetch returntotalrecordcount='true' count="{0}" version="1.0" mapping="logical">
            <entity name="indskr_lu_postalcode">
              <attribute name="indskr_postalcode" />
              <attribute name="indskr_lu_postalcodeid" />
              <attribute name="indskr_timezone" />
              <filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>
              <filter type="or">
                <condition attribute="indskr_postalcode" operator="like" value="{0}" />
              </filter>
              <attribute name="indskr_city" />
              <attribute name="statecode" />
              <order attribute="indskr_city" descending="false" />
              <link-entity name="indskr_lu_city" from="indskr_lu_cityid" to="indskr_city" alias="bb">
                <filter type="and">
                  <condition attribute="indskr_lu_cityid" operator="eq" uitype="indskr_lu_city" value="{addressfieldsfilter}"/>
                </filter>
              </link-entity>
            </entity>
          </fetch>`,
        ObjectTypeCode: 10097
      }
    ],
    LookupTargets: [
      "indskr_lu_postalcode"
    ],
    MaxLength: 0,
    MaxValue: null,
    MinValue: null,
    OptionSet: {
      Options: []
    },
    Precision: 0,
    ReadOnly: false,
    Required: false,
    SchemaName: "indskr_postalcode_lu",
    Subgrid: null,
    Visible: true,
    IsCustom: true,
    DependsOn: "indskr_City_lu",
  },
]
