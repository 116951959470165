export interface fileManagerState {
    isDownloading: boolean;
    isUnZipping: boolean;
    presentationId: string;
    downloadQueue: string[];
    downloadedQueue: string[];
    errorInfo: string;
}

export const INITIAL_FILE_MANAGER_STATE: fileManagerState = {
    isDownloading: false,
    isUnZipping: false,
    presentationId: null,
    downloadQueue: [],
    downloadedQueue: [],
    errorInfo: null
}