import { Presentation } from "../../classes/presentation/presentation.class";

export class SharePresentationPayload {
    public presentation: Presentation;

    constructor(presentation: Presentation) {
        this.presentation = presentation;
    }

    public getDTO() {
        let slides = [];
        if(this.presentation && this.presentation.presentationPages){
            this.presentation.presentationPages.map(page => {
                slides.push({
                    indskr_starttime: new Date().getTime(),
                    indskr_endtime: undefined,
                    indskr_slideid: page.id,
                    indskr_slidepath: page.pageUrl,
                    indskr_thumbnailurl: undefined,
                    indskr_slidefileref: undefined,
                    indskr_assettype: 100000000
                });
            });
        
            return {
                indskr_iopresentationid: this.presentation.ioPresentationId,
                indskr_name: this.presentation.name,
                indskr_startdate: new Date().getTime(),
                indskr_enddate: undefined,
                activityPresentationSlides: slides,
                indskr_pagewidth : this.presentation.width,
                indskr_pageheight : this.presentation.height,
                aspectRatio: this.presentation.width/this.presentation.height
            };
        }else{
            return {
                indskr_iopresentationid: undefined,
                indskr_name: undefined,
                indskr_startdate: new Date().getTime(),
                indskr_enddate: undefined,
                activityPresentationSlides: slides,
                aspectRatio: undefined
            };
        }
    }
}