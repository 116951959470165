<!-- <div class='email-template-split-pane' [ngClass]="{'details-active':!!template}" >
  <email-template-list></email-template-list>
  <ion-nav #detailsPage></ion-nav>
</div> -->
<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="left-pane">
      <email-template-list></email-template-list>
    </ion-col>
    <ion-col class="right-pane" no-padding>
      <nothing-selected-view *ngIf="showNothingSelectedView" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
      <ion-nav #emailtemplaterightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
