<ion-grid>
  <ion-row class="closeButtonContainer" justify-content-center>
    <ion-col col-12 col-sm-8>
      <div class="closeButtonsWrapper">
        <span class="sendToModeratorButton" (click)="sendEditsForApproval()" [ngClass]="{'disabled':(callnotesAssistantData.sentForApproval)}">
            <img src="assets/imgs/send_to_moderator.svg" width="25"  height="25"/>
            {{'CN_SEND_TO_MODERATOR' | translate}}
        </span>
        <img src="assets/imgs/closeGeneeScreen.png" width="27" class="closeButton" (click)="closepage()" height="27"/>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="suggestionsContainer" justify-content-center>
    <ion-col size="12">
      <!-- <ion-header>
        <ion-toolbar [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
          <ion-buttons left>
            <button ion-button (click)="closepage()" class="back-btn">
              <ion-icon name="arrow-back"></ion-icon>
            </button>
          </ion-buttons>
          <ion-title text-center>Genee Notes Assistant</ion-title>
        </ion-toolbar>
      </ion-header> -->
      <ion-content>
        <ion-list>
          <ion-item-divider *ngIf="!forCompletedMeeting">
            {{'PROCESSING' | translate}}
            <!-- <a margin-left ion-float-right class="list-header-btn" [ngClass]="{'disabled':(isEditing || callnotesAssistantData.sentForApproval)}"
              (click)="sendEditsForApproval()">
              Submit
            </a> -->

            <a margin-left slot="end" ion-float-right *ngIf="isEditing" class="list-header-btn" [ngClass]="{'disabled':(callnotesAssistantData.sentForApproval || userResponded)}"
              (click)="cancelEditing()">
              {{'CANCEL' | translate}}
            </a>
            <a margin-left slot="end" ion-float-right class="list-header-btn" [ngClass]="{'disabled':(callnotesAssistantData.sentForApproval || userResponded)}"
            (click)="editRecognition()">
            {{ (isEditing?'DONE':'CORRECT') | translate}}
          </a>

            <!-- <a margin-left ion-float-right class="list-header-btn"
              (click)="stopRecognition()">
              Stop
            </a>
            <a margin-left ion-float-right class="list-header-btn"
              (click)="startRecognition()">
              Listen
            </a> -->



          </ion-item-divider>
          <ion-item *ngIf="!forCompletedMeeting" lines="none">
            <ion-row>
            <!-- <div [gfdg]= *ngIf="!isEditing" class="transcriptionStringContainer">
              {{speechService.speechToTextInputStream}}
            </div>  -->
            <ion-col width-25 *ngIf='!isEditing'>
              <div class="inputStreamDisplayArea" id="inputStreamDisplayArea"
              [innerHTML]="callnotesAssistantData.textStreamWithRecognitionEffects" text-wrap>
              </div>
            </ion-col>
            <ion-col width-25 *ngIf='isEditing'>
              <div class="inputStreamDisplayArea" id="inputStreamEditArea"
              contenteditable="true" (input)="captureInputStreamEdits"text-wrap >
               {{callnotesAssistantData.textStream}}
             </div>
            </ion-col>
            <hr>
            <ion-col width-25>
            <div class="speechDisclaimer" text-wrap>
              {{'CN_CONFIRMATION_AND_GENEE_TRAINING_NO_TRANSCIPTS' | translate}}
            </div>
          </ion-col>
          </ion-row>
          </ion-item>

          <ion-item-divider *ngIf="!isEditing">
            {{'PRODUCTS' | translate}}
            <a ion-float-right slot="end" class="list-header-btn" (click)="addNewProductOrkey('brand')"
              [ngClass]="{'disabled':(isEditing || callnotesAssistantData.sentForApproval || forCompletedMeeting || userResponded)}">{{'CN_ADD_OTHERS' | translate}}</a>
          </ion-item-divider>
          <ion-item *ngIf="!isEditing" lines="none">
            <div class="productsContainer">
              <span *ngFor="let product of callnotesAssistantData.productsMatched"
                [ngClass]="{'selected': product.isSelected,'dottedSelection':product.isUserAdded}" class="productNameBubble"
                (click)="addToSelected(product)">
                {{product.entityName}}
                <span class="lowScoreIndicator" *ngIf="product.score < 0.3">{{'LOW' | translate}}</span>
                <ion-icon name="close-circle" (click)="removeSelected(product)"
                [ngClass]="{'disabled':(isEditing || callnotesAssistantData.sentForApproval || forCompletedMeeting || userResponded)}" class="removeButton"></ion-icon>
              </span>
            </div>
          </ion-item>
          <ion-item-divider *ngIf="!isEditing" lines="none">
            {{'KEY_MESSAGES' | translate}}
            <a ion-float-right slot="end" class="list-header-btn" (click)="addNewProductOrkey('keymessages')"
              [ngClass]="{'disabled':(isEditing || callnotesAssistantData.sentForApproval || forCompletedMeeting || userResponded)}">{{'CN_ADD_OTHERS' | translate}}</a>
          </ion-item-divider>
          <ion-item *ngIf="!isEditing" lines="none">
            <div class="keysContainer">
              <span *ngFor="let key of callnotesAssistantData.keysmatched"
                [ngClass]="{'selected': key.isSelected, 'dottedSelection':key.isUserAdded}" class="productNameBubble"
                (click)="addToSelected(key)">
                {{key.entityName}}
                <span class="lowScoreIndicator" *ngIf="key.score < 0.3">{{'LOW' | translate}}</span>
                <ion-icon name="close-circle" (click)="removeSelected(key)"
                  [ngClass]="{'disabled':(isEditing || callnotesAssistantData.sentForApproval || forCompletedMeeting || userResponded)}" class="removeButton"></ion-icon>
              </span>
            </div>
          </ion-item>
        </ion-list>
      </ion-content>
      <!-- <ion-footer>
        <footer-toolbar [footerView]="'detailView'" [selectedView]="'callnotes'"></footer-toolbar>
      </ion-footer>> -->
      <!-- <io-spinner [displayText]="'please wait...'" *ngIf="!hideSpinner"></io-spinner> -->
    </ion-col>
  </ion-row>
  <ion-row class="backgroundImageContainer" style="justify-content:center">
    <ion-col col-12 col-sm-8 *ngIf="callnotesAssistantData.productsMatched.length">
        <div class="geneeCommentWrapper">
            <img src="assets/imgs/ogeeneeChat.svg" width="35"  height="35"/>
            <!-- <img src="assets/imgs/chat_edge_left.svg" height="25" class="chatEdgeLeft"/> -->
            <p class="geneeCommentContainer" id="geneeFirstComment">{{'CN_DO_YOU_LIKE_MY_SUGGESTIONS' | translate}}</p>
        </div>
        <div class="actionButtonsWrapper" *ngIf="!userResponded">
          <div class="actionButtonsBox">
            <ion-button icon-only class="acceptSuggestionsButton" (click)="handleSuggestionclick(true)">
                <img tappable src="assets/imgs/thumbs_up.png" width="30"/>
            </ion-button>
            <ion-button icon-only class="acceptSuggestionsButton" (click)="handleSuggestionclick(false)">
                <img tappable src="assets/imgs/thumbs_down.png" width="30"/>
            </ion-button>
          </div>
        </div>
        <div class="userResponseContainer" *ngIf="userResponded">
          <div class="userResponseBox">
            <img src="assets/imgs/thumbs_up_active.png" *ngIf="callnotesAssistantData.userLikedStatus ==1" id="leaderboardimage">
            <img src="assets/imgs/thumbs_down_active.png" *ngIf="callnotesAssistantData.userLikedStatus ==2" id="leaderboardimage">
          </div>
          <!-- <img src="assets/imgs/chat_edge_right.svg" class="chatEdgeRight"  height="25"/> -->
          <div class="userInitialsBox" *ngIf="userInitials">
              {{userInitials}}
          </div>
        </div>
    </ion-col>
  </ion-row>
</ion-grid>
