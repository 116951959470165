import { BusinessProcessType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { ConsentService } from '@omni/services/consent/consent.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { IndDisplayFormAffiliationViewDataModel } from './../../../interfaces/dynamic-form/ind-display-form-affiliation-view-data.interface';
import { MultiSelectPopover } from './../../multi-select-popover/multi-select-popover';
import { PopoverController, ModalController } from '@ionic/angular';
import { DateFormat, SubgridLayout } from './../../../classes/dynamic-form/dynamic-form.class';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';
import { Subject, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Component, Input, SimpleChanges, Output, EventEmitter, Renderer2 } from '@angular/core';
import { DynamicForm, ControlDataType, Control, FormTabs, DisplayText } from '../../../classes/dynamic-form/dynamic-form.class';
import { format, isValid } from 'date-fns';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormType } from '../../../models/dynamic-form-component.model';
import { LocalizationService } from '../../../services/localization/localization.service';
import { DynamicFormsService } from '../../../services/dynamic-forms/dynamic-forms-service';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { DiskService } from '../../../services/disk/disk.service';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { OmniAccordionViewDataModel } from '@omni/models/omniAccordionViewDataModel';
import { RequiredAccountCRAttributes } from "@omni/config/dynamic-forms/default-account-display";
import { RequiredContactCRAttributes, multilingualLanguageToAttributeMapping } from "@omni/config/dynamic-forms/default-contact/default-contact-create";
import * as XML2JS from 'xml2js';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { DeviceService } from '@omni/services/device/device.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ContactDetailsComponent } from '@omni/components/contact/contact-details/contact-details';
import { AccountDetailsComponent } from '@omni/components/account/account-details/account-details';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { Account } from '@omni/classes/account/account.class';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
         ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID, ACCOUNT_CONTACT_AFFILIATIONS_VIEW_ID, CONTACT_CONTACT_AFFILIATIONS_VIEW_ID,
         CONTACT_ACCOUNT_AFFILIATIONS_VIEW_ID, ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME, CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME,
         ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME, CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME, ACCOUNT_ACCOUNT_SOURCE_TYPE_ATTRIBUTE_NAME,
         CONTACT_CONTACT_SOURCE_TYPE_ATTRIBUTE_NAME, ACCOUNT_CONTACT_SOURCE_TYPE_ATTRIBUTE_NAME, ACCOUNT_CONTACT_AFFILIATIONS_LABEL_ATTRIBUTE,
         CONTACT_ACCOUNT_AFFILIATIONS_LABEL_ATTRIBUTE, ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME,
         ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME, CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME,
         CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME, } from '../../../config/dynamic-forms/affiliations-contants';
import { AffiliationType } from '../../../enums/dynamic-forms/affiliations.enum';
import { AffiliationsFilters, FormView } from '../../../interfaces/dynamic-form/display-form.interface';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { AddressService } from '@omni/services/address/address.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { CustomerAvailabilityComponent } from '@omni/components/contact/customer-availability/customer-availability.component';
import { ConsentTermGenerateService } from '@omni/services/consent/consent-term-generate.service';
import { ChannelActivityType, ChannelType } from '@omni/classes/consent/channel.class';
import { Product } from '@omni/classes/consent/product.class';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MdmService } from '@omni/services/mdm/mdm.service';
import { MdmMaterialModal } from '@omni/components/contact/mdm-material/mdm-material-modal';
import { MDMRequestType } from '@omni/classes/mdm/source-type-optionset.class';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';

export const DEFAULT_FORM_LANGUAGE_CODE = "0000";

const compareBy = (...props) => (a, b) => {
  for (let i = 0; i < props.length; i++) {
    const ascValue = props[i].startsWith('-') ? -1 : 1;
    const prop = props[i].startsWith('-') ? props[i].substr(1) : props[i];
    if (a[prop]) {
      if (b[prop]) {
        if (a[prop] !== b[prop]) {
          return a[prop] > b[prop] ? ascValue : -ascValue;
        }
      } else {
        return ascValue;
      }
    } else {
      return ascValue === 1 ? -1 : 0;
    }
  }
  return 0;
};

/**
 * Generated class for the DisplayFormComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-display-form',
  templateUrl: 'ind-display-form.html',
  styleUrls: ['ind-display-form.scss']
})
export class DisplayFormComponent {

  private _currentFormValue: { [x: string]: any };
  private _currentFormStringValues: { [x: string]: any };
  private _currentFormStringValuesForBirthDate: { [x: string]: any };
  private _customFormValue: { [x: string]: any };

  private _activeLanguageCode: string = '1033';
  private isEditControlPushed: boolean = false;
  private isSaveFavdisabled: boolean = true;
  private initialFavAddress = [];
  private tempFavAddress = [];

  public currentFormView: FormView[] = [];

  public linkedEntityValues: {
    [x: string]: Array<OmniAccordionViewDataModel | IndDisplayFormAffiliationViewDataModel>;
  };

  @Input() formMetadata: DynamicForm;
  @Input() rawData;
  @Input() dynamicFormType;
  @Input() isEditEnabled: boolean;
  @Input() isEditDisabled: boolean;
  @Input() linkedEntityDBPrefix: string = '';
  @Input() referencingEntityName: string = '';
  @Input() scrollObservable: Observable<any>;
  @Output() onEditClick = new EventEmitter<any>();
  @Input() TYPE: BusinessProcessType;
  @Input() displayFormAccessedFrom: string;
  @Input() isTeamKitBooking: boolean;
  @Output() selectAccount = new EventEmitter<any>();
  private changeRequest: boolean = false;
  private SKIP_CHECK_THESE_FIELDS: any[] = [
    'indskr_firstname',
    'indskr_lastname',
    'indskr_name',
    'indskr_mdm',
    'indskr_mdmid',
    'indskr_requestcomments',
    'indskr_responsecomments',
    'indskr_responsecomments',
    'createdon'
  ]

  private dataUpdatedSub: Subscription;
  private dataUpdated$: Subject<boolean> = new Subject<boolean>();
  private filterPopoverData: {
    [x: string]: {
      text: string,
      expanded: boolean,
      value: string,
      items: any[],
      handler: Function
    }[]
  } = {};
  private sectionHeaderControlTextRx: {
    [x: string]: {
      subject: BehaviorSubject<string>,
      observable: Observable<string>,
    }
  } = {};

  affiliationsFilters: AffiliationsFilters = {};
  public isEnableCopyPaste: boolean = false;

  private scrollSubscription: Subscription;
  private popoverRef;
  private popoverTargetButtonEl: HTMLElement;
  private popOverRePosition = (scrollEvent) => {
    if (this.popoverRef) {
      const currentY = scrollEvent?.detail?.currentY ?? undefined;
      const scrollHeight = scrollEvent?.target?.firstChild?.scrollHeight ?? undefined;
      const viewPortHeight = scrollEvent?.target?.scrollHeight ?? undefined;
      if (currentY !== undefined && scrollHeight !== undefined && viewPortHeight !== undefined) {
        const el = this.popoverTargetButtonEl ?? undefined;
        if (el) {
          const popOverArrowEl = this.popoverRef.querySelector('.popover-wrapper>.popover-arrow');
          const arrowElTop = popOverArrowEl?.offsetTop ?? undefined;
          const popOverContentEl = this.popoverRef.querySelector('.popover-wrapper>.popover-content');
          const contentElTop = popOverContentEl?.offsetTop ?? undefined;
          const rect = el.getBoundingClientRect();
          const arrowClientHeight = popOverArrowEl.clientHeight ?? undefined;
          const contentClientHeight = popOverContentEl.clientHeight ?? undefined;

          if (arrowElTop !== undefined && contentElTop !== undefined && rect && rect.bottom && arrowClientHeight !== undefined && contentClientHeight !== undefined) {
            const newArrowLeft = rect.width / 2 + rect.x - popOverArrowEl.getBoundingClientRect().width / 2;

            if (viewPortHeight - rect.bottom - arrowClientHeight - contentClientHeight >= 0) {
              // popover goes to bottom
              const newArrowTop = rect.bottom;
              const newContentTop = newArrowTop + arrowClientHeight;

              // Remove "popover-bottom" class to ion-popover el
              this.rednerer.removeClass(this.popoverRef, 'popover-bottom');
              this.rednerer.setStyle(popOverArrowEl, 'top', newArrowTop + 'px');
              this.rednerer.setStyle(popOverArrowEl, 'left', newArrowLeft + 'px');
              this.rednerer.setStyle(popOverContentEl, 'top', newContentTop + 'px');
            } else {
              // popover goes to top
              const newArrowTop = rect.top - arrowClientHeight;
              const newContentTop = newArrowTop - contentClientHeight;

              // Add "popover-bottom" class to ion-popover el
              this.rednerer.addClass(this.popoverRef, 'popover-bottom');
              this.rednerer.setStyle(popOverArrowEl, 'top', newArrowTop + 'px');
              this.rednerer.setStyle(popOverArrowEl, 'left', newArrowLeft + 'px');
              this.rednerer.setStyle(popOverContentEl, 'top', newContentTop + 'px');
            }
          }
        }
      }
    }
  }
  custAvailabilityData: any;

  emailAddressListWithProductConsent: string[] = [];
  productsListWithProductConsent = [];

  emailAddressListWithChannelConsent: string[] = [];
  dictionaryAddressListWithProductConsent = {};
  public businessLine: number = -1;
  public worksCardHeader: IndSectionHeaderViewDataModel;
  public practiceCertificateHeader: IndSectionHeaderViewDataModel;
  public isWorkCardAdded = false;
  public isPracticeCertificateAdded = false;
  public attachment1: string;
  public attachment2: string;

  private readonly ngUnsubscribe$ = new Subject<boolean>();
  private _runInitViewDataAfterBusinessRuleLogic:boolean = false;

  constructor(
    private dateTimeFormatsService: DateTimeFormatsService,
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private dynamicFormService: DynamicFormsService,
    private disk: DiskService,
    private contactService: ContactOfflineService,
    public accountService: AccountOfflineService,
    public accountDataService: AccountDataService,
    public device: DeviceService,
    private uiService: UIService,
    private navService: NavigationService,
    public readonly footerService: FooterService,
    private popoverCtrl: PopoverController,
    public events: EventsService,
    private rednerer: Renderer2,
    private authService: AuthenticationService,
    private readonly dynamicsClient: DynamicsClientService,
    private datePipe: DatePipe,
    private readonly notificationService: NotificationService,
    private addressService: AddressService,
    private utilityService: GlobalUtilityService,
    private modalCtrl: ModalController,
    public consentTermGenerateService: ConsentTermGenerateService,
    public consentService: ConsentService,
    public readonly mdmService: MdmService,
    private territoryService: TerritoryManagementService,
    private secondaryInfoService: SecInfoConfigDataService,
    private setBookingDataService: SetBookingDataService,
  ) {
    this._activeLanguageCode = this.localizationService.selectedLanguage.localeID;
  }

  async ngOnInit() {
    this.dataUpdatedSub = this.dataUpdated$
      .pipe(
        debounceTime(0)
      )
      .subscribe(async () => {
        // this._clearFormValues();

        // ------------------Email Address flagging for Consents captured------------------//
        this.consentTermGenerateService.getSelectedContactConsents();
        if (this.consentTermGenerateService.selectedContactConsents) {
          this.consentTermGenerateService.selectedContactConsents.activeConsents.forEach(consent => {
            if (!_.isEmpty(consent.products) && !_.isEmpty(consent.channels)) {
              const emailChannels = consent.channels.filter(ch => (ch.indskr_consentType.toUpperCase() == ChannelType.EMAIL.toUpperCase() || ch.activityType == ChannelActivityType.EMAIL));
              if (!_.isEmpty(emailChannels)){
                emailChannels.forEach((emailChannel)=>{
                  emailChannel.values.forEach(v => {
                    this.emailAddressListWithProductConsent.push((''+ v.value));
                    this.productsListWithProductConsent.push(this.displayMedications(consent.products));
                    })
                  });
              }
            }
            else if (_.isEmpty(consent.products) && !_.isEmpty(consent.channels)) {
              const emailChannels = consent.channels.filter(ch => (ch.indskr_consentType.toUpperCase() == ChannelType.EMAIL.toUpperCase() || ch.activityType == ChannelActivityType.EMAIL));
              if (!_.isEmpty(emailChannels)){
              emailChannels.forEach((emailChannel)=>{
                emailChannel.values.forEach(v => {
                  this.emailAddressListWithChannelConsent.push((''+ v.value));
                  })
                });
              }
          }
          });

          if (!_.isEmpty(this.emailAddressListWithProductConsent) && !_.isEmpty(this.productsListWithProductConsent)) {
            await this.consentService.getProductsWithConsentsByContactId().then(()=>{
              this.getProductsWithConsentsByContactId();
            });
          }
        }//end of if (this.consentTermGenerateService.selectedContactConsents)
        // ------------------Email Address flagging for Consents captured------------------//

        this.linkedEntityValues = {};
        this.dynamicFormService.numOfLinkedEntity = {};
        if (_.isEmpty(this.localizationService.multiLingualFieldsData)) {
          await this.localizationService.initMultilingualFieldsData().then(() => {
            this._parseRawObjectDataToValues(this.rawData);
            this._initViewData();
          })
        } else {
          this._parseRawObjectDataToValues(this.rawData);
          this._initViewData();
        }
        this._runBusinessRulesLogic();
      });
    this.businessLine = this.authService.user.buConfigs && this.authService.user.buConfigs.indskr_businessline ? this.authService.user.buConfigs.indskr_businessline : -1;
    if (this.TYPE != BusinessProcessType.SanofiChina) {
      this.SKIP_CHECK_THESE_FIELDS.push('indskr_mdmrequesttype', 'statuscode');
    }
    if (this.TYPE == BusinessProcessType.SanofiChina) {
      if ((this.businessLine == 1 || this.businessLine == 3) && (this.rawData['indskr_pharmarequesttype'] && this.rawData['indskr_pharmarequesttype'] == 1) && this.rawData['indskr_mdmrequesttype'] == MDMRequestType.ADD_AFFILIATION) {
        this.SKIP_CHECK_THESE_FIELDS.push('indskr_gendercode');
      }
    }
    this._initAffiliationsFilters();
    this.events.subscribe('refreshDisplayForm', () => {
      this._resetFlagOpenedAffiliatedDataDetails();
      this.dataUpdated$.next(true)
    });

    if (this.scrollObservable) {
      this.scrollSubscription = this.scrollObservable.subscribe(ev => this.popOverRePosition(ev));
    }
    if (this.displayFormAccessedFrom) {
      this.isEnableCopyPaste = (this.displayFormAccessedFrom === 'configuredAccountInfo' || this.displayFormAccessedFrom === 'configuredContactInfo') ? true : false;
    }

    this.device.screenWidth
            .pipe(
                skip(1),
                takeUntil(this.ngUnsubscribe$),
                debounceTime(400),
            ).subscribe((newOrientation) => {
            this._initViewData();
        });
  }

  async ngAfterViewInit() {
    if (this.formMetadata) {
      if (this.rawData) {
        // await this._parseRawObjectDataToValues(this.rawData);
        this.dataUpdated$.next(true);
      } else {
        this._initViewData();
      }

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.rawData) {
        // this._parseRawObjectDataToValues(this.rawData);
        this.dataUpdated$.next(true);
      } else {
        this._clearFormValues();
        this._initViewData();
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
    if (this.dataUpdatedSub) {
      this.dataUpdatedSub.unsubscribe();
    }
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    this.events.unsubscribe('refreshDisplayForm');
  }

  private _initAffiliationsFilters() {
    this.affiliationsFilters = this.dynamicFormService.getDefaultAffiliationsFilters();
  }
  private _clearFormValues() {
    this.currentFormView = [];
    this._currentFormStringValues = {};
    this._currentFormValue = {};
    this._customFormValue = {};
    this.isEditControlPushed = false;
    this.linkedEntityValues = {};
  }

  private async _parseRawObjectDataToValues(rawObject: any) {
    if (this.formMetadata && this.formMetadata.metadata && this.formMetadata.metadata.length > 0) {
      this.formMetadata.metadata.forEach((tab, tabIndex) => {
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach(async control => {
            switch (control.dataType) {
              case ControlDataType.MoneyType:
              case ControlDataType.IntegerType:
              case ControlDataType.DecimalType:
              case ControlDataType.DoubleType:
              case ControlDataType.MemoType:
              case ControlDataType.StringType:
                if (rawObject[control.attributeName]) {
                  this._setAttributeValue(control, rawObject[control.attributeName]);
                }
                else this._setAttributeValue(control, "");
                break;
              case ControlDataType.LookupType:
                if(control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                  if(rawObject['_' + control.attributeName  + '_value'] && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                    let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == rawObject['_' + control.attributeName  + '_value']);
                    let translatedLang: string = '';
                    // if(foundRecord) translatedLang = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] ? foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] || '' : foundRecord['omnione_en_long_label'] || '';
                    let key = this.localizationService.getOneKeyTableAttributes();
                    if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                    if(foundRecord && foundRecord['omnione_onekeycodeslabelsid'] && translatedLang){
                      this._setAttributeValue(control, foundRecord['omnione_onekeycodeslabelsid']);
                      this._setAttributeStringValue(control.attributeName, translatedLang);
                    }
                  }
                }else if (rawObject['_' + control.attributeName + '_value']) {
                  this._setAttributeValue(control, rawObject['_' + control.attributeName + '_value']);
                  this._setAttributeStringValue(control.attributeName, rawObject['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue'])
                }
                else {
                  this._setAttributeValue(control, "");
                  this._setAttributeStringValue(control.attributeName, "")
                }
                break;
              case ControlDataType.BooleanType:
                if (rawObject.hasOwnProperty(control.attributeName)) {
                  (rawObject[control.attributeName]) ? this._setAttributeValue(control, true) : this._setAttributeValue(control, false);
                }
                break;
              case ControlDataType.MultiSelectPicklistType:
                if (rawObject[control.attributeName]) {
                  this._setAttributeValue(control, rawObject[control.attributeName]);
                  let str = rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',');
                  this._setAttributeStringValue(control.attributeName, str);
                }
                else {
                  this._setAttributeValue(control, "");
                  this._setAttributeStringValue(control.attributeName, "")
                }
                break;
              case ControlDataType.PicklistType:
                if (rawObject[control.attributeName]) {
                  this._setAttributeValue(control, rawObject[control.attributeName]);
                  this._setAttributeStringValue(control.attributeName, rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue'])
                }
                else {
                  this._setAttributeValue(control, "");
                  this._setAttributeStringValue(control.attributeName, "")
                }
                break;
              case ControlDataType.DateTimeType:
                if (rawObject[control.attributeName] || rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                  let date: Date;
                  if (rawObject[control.attributeName]) {
                    date = new Date(rawObject[control.attributeName]);
                    if (!isValid(date)) {
                      date = new Date(parseInt(rawObject[control.attributeName]));
                      if (!isValid(date) && rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                        date = new Date(rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                      }
                    }
                  } else if (rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                      date = new Date(rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                  }
                  if (isValid(date)) {
                    if (control.dateFormat == "DateAndTime") {
                      this._setAttributeValueDate(control, date);
                    } else if (control.dateFormat == "DateOnly") {
                      date.setHours(0, 0, 0, 0);
                      this._setAttributeValueDate(control, date);
                    }
                    if (control.attributeName =='omnione_yearofbirth') {
                      this._seteBirthDateStringValue('omnione_yearofbirth', format(date, 'YYYY'));
                    } else if(control.attributeName =='omnione_dayandmonthofbirth'){
                      this._seteBirthDateStringValue('omnione_dayandmonthofbirth', date);
                    }
                  } else {
                    this._setAttributeValue(control, "");
                    this._setAttributeStringValue(control.attributeName, "")
                  }
                }
                else {
                  this._setAttributeValue(control, "");
                  this._setAttributeStringValue(control.attributeName, "")
                }
                break;
              case ControlDataType.StateType:
              case ControlDataType.StatusType:
                if (rawObject[control.attributeName] || rawObject[control.attributeName] == 0) {
                  this._setAttributeValue(control, rawObject[control.attributeName]);
                  let str = rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue'];
                  if (rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue'] == "In Progress" && this.changeRequest) {
                    str = "In Process";
                  }
                  if (!_.isEmpty(str)) this._setAttributeStringValue(control.attributeName, str);
                }
                else {
                  this._setAttributeValue(control, "");
                  this._setAttributeStringValue(control.attributeName, "")
                }
                break;
              case null:
                // Handle Subgrid case
                await this._initializeSubgridData(control);

                // * To check the number of existing linked entity
                if(this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
                  if (this.dynamicFormService.numOfLinkedEntity && this.dynamicFormService.numOfLinkedEntity[control.lookupEntitySetName]) {
                    this.dynamicFormService.numOfLinkedEntity[control.lookupEntitySetName] = this.linkedEntityValues[control.attributeName].length;
                  } else if(this.dynamicFormService.numOfLinkedEntity){
                    this.dynamicFormService.numOfLinkedEntity[control.lookupEntitySetName] = this.linkedEntityValues[control.attributeName].length
                  } else {
                    this.dynamicFormService.numOfLinkedEntity = {
                      [control.lookupEntitySetName]: this.linkedEntityValues[control.attributeName].length
                    }
                  }
                }
            }
          });
        }
      })
    }
  }

  private _getCardDisplayTypeForLinkEntity(control: Control): 'Affiliation' | 'Accordion' {
    const affiliationsRefEntityNames = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
      CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
      ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY
    ];
    return affiliationsRefEntityNames.includes(control?.subgrid?.referencingEntity) ? 'Affiliation' : 'Accordion';
  }
  private async _initializeSubgridData(control: Control) {
    let rawLEData;
    // We need to merge A-A(from and to) OR C-C(from and to) affiliations fetch result
    const refEntityNamesAllowedToBeMerged = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
      CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
    ];
    const isThisControlToBeMergedWithOtherControl = refEntityNamesAllowedToBeMerged.includes(control.subgrid.referencingEntity);
    let dbKey: string = isThisControlToBeMergedWithOtherControl
    ? control.subgrid.relationshipName : control.subgrid.referencingEntity;
    if(control.attributeName=="CloseUpAddresses") dbKey = dbKey + "CloseUpAddresses";

    if (this.rawData.hasOwnProperty('globalSearchedDataLinkEntities')) {
      let raw = this.rawData['globalSearchedDataLinkEntities'][this.linkedEntityDBPrefix + control.subgrid.referencingEntity]
              ?? this.rawData['globalSearchedDataLinkEntities'][this.linkedEntityDBPrefix + control.subgrid.relationshipName]
              ?? [];

      rawLEData = {
        raw
      };
    } else {
      if(this.isTeamKitBooking)
        rawLEData= this.setBookingDataService.teamKitBookingLinkedEntityData[dbKey];
      else
        rawLEData = await this.disk.retrieve(this.linkedEntityDBPrefix + dbKey);
    }

    if (!rawLEData || !rawLEData.raw) return;

    let linkEntityData;

    if (!isThisControlToBeMergedWithOtherControl) {
      if(dbKey == 'appointment') linkEntityData = (rawLEData.raw).filter(x => x[this.referencingEntityName] == this.rawData[this.referencingEntityName]);
      else linkEntityData = (rawLEData.raw).filter(x => x[this.referencingEntityName] == this.rawData[this.referencingEntityName] && (!x.hasOwnProperty(control.subgrid.referencingEntity + '.statecode') || (x.hasOwnProperty(control.subgrid.referencingEntity + '.statecode') && x[control.subgrid.referencingEntity + '.statecode'] == 0)));
    } else {
      const referencingSubGridEntity: string = control?.subgrid?.referencingEntity ?? '';
      const referencingAttribute: string = control?.subgrid?.referencingEntity ?? '';
      const attributeToLookFor: string = control?.subgrid?.subgridLayout
                                        && control.subgrid.subgridLayout.length > 0
                                        ? control.subgrid.subgridLayout[0].attribute : '';

      if (referencingSubGridEntity && referencingAttribute && attributeToLookFor) {
        linkEntityData = (rawLEData.raw).filter(x =>
          x[this.referencingEntityName] === this.rawData[this.referencingEntityName]
            && (!x.hasOwnProperty(`${referencingSubGridEntity}.${referencingAttribute}`)
                || x[`${referencingSubGridEntity}.${referencingAttribute}`] !== x[this.referencingEntityName])
            && x.hasOwnProperty(`${referencingSubGridEntity}.${attributeToLookFor}`)
            && (!x.hasOwnProperty(referencingSubGridEntity + '.statecode')
                || (x.hasOwnProperty(referencingSubGridEntity + '.statecode') && x[referencingSubGridEntity + '.statecode'] === 0))
        );
        const id = this._getAggregatedViewId(control.subgrid.referencingEntity, control.subgrid.parentEntity);
        control.attributeName = id ?? control.attributeName;
      } else {
        console.warn('_initializeSubgridData: Invalid control: ', control);
      }
    }

    if (linkEntityData) {
      /*************Remove Duplicates from Display form - Offline Edit*************/
      if(this.formMetadata.entityName == 'contact'){
        if(dbKey === 'indskr_email_address') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_email_address.indskr_emailaddress'];
          });
        }
        else if(dbKey === 'indskr_accountcontactaffiliation') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_accountcontactaffiliation.indskr_accountid_Formatted'];
          });
        }
      }
      else if(this.formMetadata.entityName == 'indskr_contactcr'){
        if(dbKey === 'indskr_emailaddresscr') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_emailaddresscr.indskr_name'];
          });
        }
        else if(dbKey === 'indskr_accountcontactaffiliationcr') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_accountcontactaffiliationcr.indskr_accountid_Formatted'];
          });
        }
        else if(dbKey === 'indskr_customerspecialtychangerequest') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_customerspecialtychangerequest.omnione_lu_specialties_value'];
          });
        }
      }
      /*************Remove Duplicates from Display form - Offline Edit*************/

      // START
      const shouldAllowSorting: boolean = true;
      if (shouldAllowSorting) {
        let JSONQuery;
        let entityName: String = '';
        const sortOptions = [];
        XML2JS.parseString(control.subgrid.subgridQuery, (err, data) => {
          if (!err) {
            JSONQuery = data;
            if (JSONQuery) {
              entityName = JSONQuery.fetch.entity[0].$.name;
              if (JSONQuery.fetch.entity[0]['order'] && JSONQuery.fetch.entity[0]['order'].length) {
                JSONQuery.fetch.entity[0]['order'].forEach(sort => {
                  sortOptions.push(sort);
                });
              }
            }
          }
        });

        if (sortOptions.length) {
          let generatedSortingFields = [];
          sortOptions.forEach(field => {
            const fieldName = entityName + '.' + field.$.attribute;
            const formattedField = fieldName + '@OData.Community.Display.V1.FormattedValue';
            const hasFormattedField = (linkEntityData.filter(ret => ret[formattedField]).length > 0);
            if(field.$.attribute.includes('isprimary')) {
              generatedSortingFields.push(field.$.descending === 'false' ? (hasFormattedField ? '-' + formattedField : '-' + fieldName) : (hasFormattedField ? formattedField : fieldName));
            } else {
              generatedSortingFields.push(field.$.descending === 'true' ? (hasFormattedField ? '-' + formattedField : '-' + fieldName) : (hasFormattedField ? formattedField : fieldName));
            }
          });
          linkEntityData.sort(compareBy(...generatedSortingFields));
        }
      }
      // END

      if (this.linkedEntityValues
        && !isThisControlToBeMergedWithOtherControl
        && this.linkedEntityValues[control.attributeName]) this.linkedEntityValues[control.attributeName] = [];

      const cardDisplayType: 'Accordion' | 'Affiliation' = this._getCardDisplayTypeForLinkEntity(control);

      linkEntityData.forEach(leData => {
        let displayFormViewCard: OmniAccordionViewDataModel | IndDisplayFormAffiliationViewDataModel;
        let childItems: Array<any> = [];
        
        //
        let subgridLayout = control.subgrid.subgridLayout;
        this.dynamicFormService.runBussinessRulesForLinkedEntity(subgridLayout,control?.subgrid?.referencingEntity,leData);
        subgridLayout = subgridLayout.filter(a=> a['isVisible'] != false );
        subgridLayout.forEach(attrib => {
          let attribName;
          let foundAddressLinkedEntityIdAttribName: string;
          let existingAddressDataId: string;
          let index = attrib.attribute.indexOf(".");
          if (index !== -1) {
            attribName = leData[attrib.attribute + "@OData.Community.Display.V1.FormattedValue"] ?
              attrib.attribute + "@OData.Community.Display.V1.FormattedValue" : attrib.attribute;
          } else {
            attribName = control.subgrid.referencingEntity + "." + attrib.attribute;
            if(leData[attribName] && attrib.targetEntity == "omnione_onekeycodeslabels" && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == leData[attribName]);
                if(foundRecord && foundRecord['omnione_onekeycodeslabelsid']){
                  attribName += "@OData.Community.Display.V1.FormattedValue";
                  // if(foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]]) leData[attribName] = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]];
                  let key = this.localizationService.getOneKeyTableAttributes();
                  if(foundRecord[key]) leData[attribName] = foundRecord[key];
                }
            }
            // else if(leData[attribName + "_fallbackvalue"]){
            //   attribName += "_fallbackvalue"
            // }
            else if(leData[attribName + "@OData.Community.Display.V1.FormattedValue"]){
              attribName += "@OData.Community.Display.V1.FormattedValue";
            }
          }
          //As new requirement, New Lookup should be displayed instead of Role option set.
          if (attribName && !leData[attribName]) {
            //If display form is not updated, but create/edit form is updated
            switch (attribName) {
              case "indskr_accountcontactaffiliation.indskr_contactrole":
                attrib.attribute = "omnione_role";
                break;
              case "indskr_accountcontactaffiliation.omnione_role":
                attrib.attribute = "indskr_contactrole";
                break;
              case "indskr_contactrelationship.indskr_relationship":
                attrib.attribute = "omnione_relationship";
                break;
              case "indskr_contactrelationship.omnione_relationship":
                attrib.attribute = "indskr_relationship";
                break;
              case "indskr_accountaccountaffiliation.indskr_type":
                attrib.attribute = "omnione_relationship";
                break;
              case "indskr_accountaccountaffiliation.omnione_relationship":
                attrib.attribute = "indskr_type";
                break;
            }
            attribName = control.subgrid.referencingEntity + "." + attrib.attribute;
            attribName += leData[attribName + "@OData.Community.Display.V1.FormattedValue"] ? "@OData.Community.Display.V1.FormattedValue" : "";
          }
          if (attribName && leData[attribName]) {
            if (displayFormViewCard) {
              if(control.subgrid.referencingEntity=='indskr_customerposition') return;
              switch (cardDisplayType) {
                case 'Affiliation':
                  this._processAffiliationCardData(displayFormViewCard as IndDisplayFormAffiliationViewDataModel, childItems, attrib, control, attribName, leData);
                  break;

                case 'Accordion':
                default:
                  this._setViewCardData(childItems, attrib, control, attribName, leData);
                  (displayFormViewCard as OmniAccordionViewDataModel).showExpandIcon = true;
                  (displayFormViewCard as OmniAccordionViewDataModel).showCollapseIcon = true;
                  if(control.subgrid.referencingEntity=='indskr_indskr_customeraddress_v2') {
                    (displayFormViewCard as OmniAccordionViewDataModel).showFavouriteIcon = true;
                    foundAddressLinkedEntityIdAttribName = control.subgrid.referencingEntity + '.indskr_indskr_customeraddress_v2id';
                    if (foundAddressLinkedEntityIdAttribName) {
                      existingAddressDataId = leData[foundAddressLinkedEntityIdAttribName] || '';
                      if(existingAddressDataId) (displayFormViewCard as OmniAccordionViewDataModel).linkedEntityExistingDataAddressId = existingAddressDataId;
                    }

                    let index = this.initialFavAddress.findIndex(add => add.customerAddressId == existingAddressDataId)
                    if(existingAddressDataId && this.addressService.preferredAdress.some(add => add.customerAddressId == existingAddressDataId && !add.isDeleted)) {
                      (displayFormViewCard as OmniAccordionViewDataModel).isFavourite = true;
                      if(index<0) this.initialFavAddress.push({
                        customerAddressId: existingAddressDataId,
                        isFavourite: true
                      });
                      else this.initialFavAddress[index] = {
                        customerAddressId: existingAddressDataId,
                        isFavourite: true
                      };
                    } else {
                      (displayFormViewCard as OmniAccordionViewDataModel).isFavourite = false;
                      if(index<0) this.initialFavAddress.push({
                        customerAddressId: existingAddressDataId,
                        isFavourite: false
                      });
                      else this.initialFavAddress[index] = {
                        customerAddressId: existingAddressDataId,
                        isFavourite: false
                      };
                    }
                  }
                  if (cardDisplayType !== 'Accordion') {
                    console.warn('_initializeSubgridData: unknown cardDisplayType: ', cardDisplayType);
                  }
                  break;
              }
            } else {
              switch (cardDisplayType) {
                case 'Affiliation': {
                    const A_TO_A_OR_C_TO_C_RELATION_VIEW_IDS = [
                      ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID,
                      CONTACT_CONTACT_AFFILIATIONS_VIEW_ID,
                    ];
                    const FROM_FILTER_ATTRIBUTE_NAMES = [
                      ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME,
                      CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME,
                    ];
                    const aToAOrCtoCRelation = A_TO_A_OR_C_TO_C_RELATION_VIEW_IDS.includes(control.attributeName)
                      ? FROM_FILTER_ATTRIBUTE_NAMES.includes(attrib.attribute)
                        ? 'from'
                        : 'to'
                      : '';

                    let affiliationType: AffiliationType = this._getAffiliationType(control.subgrid.parentEntity,
                                                                                    control.subgrid.referencingEntity,
                                                                                    control.subgrid.referencingAttribute);

                    displayFormViewCard = {
                      displayType: cardDisplayType,
                      id: control.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                      primaryText: '',
                      showRightArrow: false,
                      childItems: [],
                      filter: {},
                      affiliationType,
                      readOnly: false,
                    };

                    switch (affiliationType) {
                      case AffiliationType.accountFromAccount:
                      case AffiliationType.accountToAccount:
                      case AffiliationType.contactFromContact:
                      case AffiliationType.contactToContact:
                        // A-A or C-C relation
                        displayFormViewCard.filter['fromToRelation'] = this._getFromToRelationFilterValue(affiliationType);
                        displayFormViewCard.showRightArrow = true;
                        // Check readOnly: style set as grey out forward-arrow
                        displayFormViewCard.readOnly = this._checkReadOnlyViewCard();
                        if (!displayFormViewCard.readOnly) {
                          if (affiliationType == AffiliationType.accountFromAccount || affiliationType == AffiliationType.accountToAccount) {
                            displayFormViewCard.clickHandler = (id: string) => this._aToAviewClickHandler(control, leData, id);
                          } else if (affiliationType == AffiliationType.contactFromContact || affiliationType == AffiliationType.contactToContact) {
                            displayFormViewCard.clickHandler = (id: string) => this._cToCviewClickHandler(control, leData, id);
                          }
                        }
                        break;

                      case AffiliationType.accountToContact:
                      case AffiliationType.contactToAccount:
                        // account - contact relation
                        displayFormViewCard.showRightArrow = true;
                         // Check readOnly: style set as grey out forward-arrow
                        displayFormViewCard.readOnly = this._checkReadOnlyViewCard();
                        // Attach click handler
                        if (!displayFormViewCard.readOnly) {
                          if (affiliationType == AffiliationType.accountToContact) {
                            if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT) && this.territoryService.currentListPeriod)
                              this.getAffiliatedContacts(control, leData);
                            displayFormViewCard.clickHandler = (id: string) => this._aToCviewClickHandler(control, leData, id);
                          } else if (affiliationType == AffiliationType.contactToAccount) {
                            displayFormViewCard.clickHandler = (id: string) => this._cToAviewClickHandler(control, leData, id);
                          }
                        }
                        break;

                      default:
                        console.warn('_initializeSubgridData: Invalid affiliation type: ', affiliationType);
                        break;
                    }

                    this._processAffiliationCardData(displayFormViewCard, childItems, attrib, control, attribName, leData);
                    this._addDefaultAffiliationFilterValue(displayFormViewCard, leData, control);
                  }
                  break;

                case 'Accordion':
                default:
                  let custAvailabilityEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_AVAILABILITY) && control.subgrid.referencingEntity=='indskr_indskr_customeraddress_v2';
                  displayFormViewCard = {
                    displayType: cardDisplayType,
                    id: control.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                    primaryText: leData[attribName],
                    showExpandIcon: control.subgrid.referencingEntity=='indskr_customerposition',
                    showCollapseIcon: control.subgrid.referencingEntity=='indskr_customerposition',
                    showRightIcon: custAvailabilityEnabled,
                    rightIconSrc: 'assets/imgs/customer_availability_icon.svg',
                    showFavouriteIcon: false,
                    isExpanded: false,
                    childItems: [],
                    readOnly: false,
                    rawObjData: leData,
                    isAccordionOnlineOnly:control.subgrid.referencingEntity=='indskr_customerposition'
                  };
                  if(control.subgrid.referencingEntity=='indskr_setbookingproduct'){
                    displayFormViewCard.primaryText += ' | '+this.translate.instant('Quantity')+' - '+leData['indskr_setbookingproduct.indskr_quantity'];
                  }
                  if(control.subgrid.referencingEntity=='indskr_customerposition'){
                    (displayFormViewCard as OmniAccordionViewDataModel).callbackForOnlineChildDataFetch = () => this.onlineAccordionDataFetchClickHandler(control, leData);
                  }
                  if(custAvailabilityEnabled){
                    (displayFormViewCard as OmniAccordionViewDataModel).clickHandler = (id, event, specificTarget, dataRef) => this.getCustomerAvailability(control, leData, specificTarget);
                  }

                  if(control.subgrid.referencingEntity=='indskr_email_address'){
                  let showViewConsentIcon = ((this.dictionaryAddressListWithProductConsent[leData[attribName]] !== undefined) || (this.emailAddressListWithChannelConsent.indexOf(leData[attribName]) > -1))
                    && this.uiService.tabsData.length > 2;

                    displayFormViewCard = {
                      displayType: cardDisplayType,
                      id: control.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                      primaryText: leData[attribName],
                      showExpandIcon: control.subgrid.referencingEntity=='indskr_email_address',
                      showCollapseIcon: control.subgrid.referencingEntity=='indskr_email_address',
                      showRightIcon: showViewConsentIcon,
                      rightIconSrc: 'assets/imgs/consent-signature.svg',
                      rightIconTooltip: this.translate.instant('VIEW_CONSENT'),
                      showFavouriteIcon: false,
                      isExpanded: false,
                      childItems: [],
                      readOnly: false,
                      rawObjData: leData,
                      isAccordionOnlineOnly:control.subgrid.referencingEntity=='indskr_email_address'
                    };

                    (displayFormViewCard as OmniAccordionViewDataModel).clickHandler = (id, event, specificTarget, dataRef) => this.viewConsent(control, leData, specificTarget);
                  }

                  if (cardDisplayType !== 'Accordion') {
                    console.warn('_initializeSubgridData: unknown cardDisplayType: ', cardDisplayType);
                  }
                  break;
              }
            }
          }
        });

        if (displayFormViewCard) {
          displayFormViewCard.childItems = childItems;

          if (this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
            this.linkedEntityValues[control.attributeName].push(displayFormViewCard);
          } else if (this.linkedEntityValues) {
            this.linkedEntityValues[control.attributeName] = [displayFormViewCard];
          } else {
            this.linkedEntityValues = {
              [control.attributeName]: [displayFormViewCard]
            };
          }
        }
      });
    }
  }
  private _processAffiliationCardData(displayFormViewCard: IndDisplayFormAffiliationViewDataModel, childItems: any[], attrib: SubgridLayout, control: Control, attribName: string, leData: any) {
    switch (displayFormViewCard.affiliationType) {
      case AffiliationType.accountFromAccount:
        if (attrib.attribute === ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME) {
          // This is main card label attribute
          displayFormViewCard.relationLabel = this._getRelationLabel(displayFormViewCard.affiliationType);
          displayFormViewCard.relationValue = leData[attribName];
        } else if (attrib.attribute !== ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME) {
          // Rest of the attributes
          this._setViewCardData(childItems, attrib, control, attribName, leData);
        }
        break;
      case AffiliationType.accountToAccount:
        if (attrib.attribute === ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME) {
          displayFormViewCard.relationLabel = this._getRelationLabel(displayFormViewCard.affiliationType);
          displayFormViewCard.relationValue = leData[attribName];
        } else if (attrib.attribute !== ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME) {
          this._setViewCardData(childItems, attrib, control, attribName, leData);
        }
        break;

      case AffiliationType.contactFromContact:
        if (attrib.attribute === CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME) {
          displayFormViewCard.relationLabel = this._getRelationLabel(displayFormViewCard.affiliationType);
          displayFormViewCard.relationValue = leData[attribName];
        } else if (attrib.attribute !== CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME) {
          this._setViewCardData(childItems, attrib, control, attribName, leData);
        }
        break;
      case AffiliationType.contactToContact:
        if (attrib.attribute === CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME) {
          displayFormViewCard.relationLabel = this._getRelationLabel(displayFormViewCard.affiliationType);
          displayFormViewCard.relationValue = leData[attribName];
        } else if (attrib.attribute !== CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME) {
          this._setViewCardData(childItems, attrib, control, attribName, leData);
        }
        break;
      case AffiliationType.accountToContact:
        if (attrib.attribute === ACCOUNT_CONTACT_AFFILIATIONS_LABEL_ATTRIBUTE) {
          displayFormViewCard.primaryText = leData[attribName];
        } else {
          this._setViewCardData(childItems, attrib, control, attribName, leData);
        }
        break;
      case AffiliationType.contactToAccount:
        if (attrib.attribute === CONTACT_ACCOUNT_AFFILIATIONS_LABEL_ATTRIBUTE) {
          displayFormViewCard.primaryText = leData[attribName];
        } else {
          this._setViewCardData(childItems, attrib, control, attribName, leData);
        }
        break;

      default:
        console.warn('_processAffiliationCardData: Invalid affiliationType: ', displayFormViewCard, childItems, attrib, control, attribName, leData);
        break;
    }
  }
  private _setViewCardData(childItems: any[], attrib: SubgridLayout, control: Control, attribName: string, leData: any) {
    const refEntityName: string = control?.subgrid?.referencingEntity ?? control?.subgrid?.targetEntityType;
    this.dynamicFormService.getCustomDisplayNameFromField(control, this.dynamicFormType);
    let displayName: string = this._getDisplayText(attrib.displayNames, false, attrib.attribute, refEntityName);

    let formattedValue: string = leData[attribName] || '';
    if(attrib.targetEntity == "omnione_onekeycodeslabels" && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0) {
      let targetAttribName: string = control.subgrid.referencingEntity + "." + attrib.attribute;
      const index = attrib.attribute.indexOf(".");
      if (index !== -1) { 
        targetAttribName = attrib.attribute;
      }
      const foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == leData[targetAttribName]);
      if(foundRecord && foundRecord['omnione_onekeycodeslabelsid']){
        const key = this.localizationService.getOneKeyTableAttributes();
        if(foundRecord[key]) {
          formattedValue = foundRecord[key];
        }
      }
    }

    if (childItems.findIndex(item => item.id === control.attributeName + '_' + attribName) == -1) {
      childItems.push({
        id: control.attributeName + '_' + attribName,
        label: displayName ? displayName : '',
        value: formattedValue,
      });
    }

    /*
      To display a custom section (Not coming from Dynamics - Display From)
      for Product with Consent in EmailAddressDisplay
    */
    if(control.attributeName === 'EmailAddressDisplay' && childItems.length === 2) {
      let showProductWithConsentSection = (this.dictionaryAddressListWithProductConsent[leData['indskr_email_address.indskr_emailaddress']] !== undefined);

      if(showProductWithConsentSection) {
        childItems.push({
          id: control.attributeName + '_' + 'productsWithConsent',
          label: this.translate.instant('PRODUCT_WITH_CONSENT'),
          value: this.dictionaryAddressListWithProductConsent[leData['indskr_email_address.indskr_emailaddress']]
        });
      }
    }//end of if(control.attributeName === 'EmailAddressDisplay' && childItems.length === 2)
  }
  //#1 open affiliated contact on account details
  private openContactOnAccountDetails(contact: Contact) {
    this._resetFlagOpenedAffiliatedDataDetails();
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = true;
    if (this.device.isOffline) {
      this.contactService.contactInformation = this.contactService.contacts.find(x => x.ID == contact.ID);
    }
    this.dynamicFormService.isNavAffiliatedContactFromAccount = false;
    this.contactService.contactInformation = contact;
    //this.uiService.contactDetailsSegment = 'info';
    this.uiService.isForcedHideGoToToolBtn = this.contactService.contactPageMode == ComponentViewMode.ADDNEW || this.contactService.contactPageMode == ComponentViewMode.SELECTION;
    this.contactService.contactPrevPageMode = this.contactService.contactPageMode;
    this.contactService.contactPageMode = ComponentViewMode.PREVIEW;
    this.contactService.accessedContactListFrom = PageName.AccountDetailsComponent;
    this.footerService.initButtons(FooterViews.Contacts);
    this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, { contactListMode: ComponentViewMode.READONLY });
  }
  //#2 open affiliated account on account details
  private openAccountOnAccountDetails(account: Account) {
    this._resetFlagOpenedAffiliatedDataDetails();
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = true;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'accountDetails';
    //this.uiService.accountDataSegment = 'info';
    this.uiService.isForcedHideGoToToolBtn = this.accountService.accountPageMode == ComponentViewMode.ADDNEW || this.accountService.accountPageMode == ComponentViewMode.SELECTION;
    this.accountService.accountPrevPageMode = this.accountService.accountPageMode;
    this.accountService.accountPageMode = ComponentViewMode.PREVIEW;
    // set it same as value when it opens Account tool in menu-toggle.ts
    this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
    if (this.accountService.selected) this.accountService.prevSelected = this.accountService.selected;
    this.accountService.selected = account;
    //this.accountDataService.getAccountTimelineInfo(account);
    this.footerService.initButtons(FooterViews.Accounts);
    this.events.publish('highlightAccount', account);
  }
  //#3 open affiliated account on contact details
  private openAccountOnContactDetails(account: Account) {
    this._resetFlagOpenedAffiliatedDataDetails();
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = true;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'accountDetails';
    //this.uiService.accountDataSegment = 'info';
    this.uiService.isForcedHideGoToToolBtn = this.accountService.accountPageMode == ComponentViewMode.ADDNEW || this.accountService.accountPageMode == ComponentViewMode.SELECTION;
    this.accountService.accountPrevPageMode = this.accountService.accountPageMode;
    this.accountService.accountPageMode = ComponentViewMode.PREVIEW;
    this.accountService.accessedAccountListFrom = PageName.ContactDetailsComponent;
    this.accountService.selected = account;
    //this.accountDataService.getAccountTimelineInfo(account);
    this.footerService.initButtons(FooterViews.Accounts);
    this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, { listMode: ComponentViewMode.READONLY });
  }
  //#4 open affiliated contact on contact details
  private openContactOnContactDetails(contact: Contact) {
    this._resetFlagOpenedAffiliatedDataDetails();
    this.dynamicFormService.isOpenedAffiliatedContactOnContact = true;
    if (this.device.isOffline) {
      this.contactService.contactInformation = this.contactService.contacts.find(x => x.ID == contact.ID);
    }
    if (this.contactService.contactInformation) this.contactService.prevSelected = this.contactService.contactInformation;
    this.uiService.showNewActivity = false;
    //this.uiService.contactDetailsSegment = 'info';
    this.uiService.isForcedHideGoToToolBtn = this.contactService.contactPageMode == ComponentViewMode.ADDNEW || this.contactService.contactPageMode == ComponentViewMode.SELECTION;
    this.contactService.contactPrevPageMode = this.contactService.contactPageMode;
    this.contactService.contactPageMode = ComponentViewMode.PREVIEW;
    this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
    this.footerService.initButtons(FooterViews.Contacts);
    this.events.publish('highlightAffiliatedContact', contact);
  }

  // private _handleLinkedEntityExpandClick(id,event,eventName,refData:MainCardViewDataModel){
  //     if(this.linkedEntityValues && this.linkedEntityValues.length != 0 && refData.controlAttributeName && Array.isArray(this.linkedEntityValues[refData.controlAttributeName])){
  //         let foundView = this.linkedEntityValues[refData.controlAttributeName].find(a=> a.id == refData.id);
  //         if(foundView){
  //             foundView.isExpanded = !foundView.isExpanded;
  //             if(foundView.isExpanded){
  //                 refData.arrowType = 'remove-outline';
  //               }else{
  //                 refData.arrowType = 'add-outline';
  //               }
  //         }
  //     }
  // }

  private _setAttributeStringValue(attributeName: string, value: any) {
    if (this._currentFormStringValues) {
      if (attributeName === 'statecode' || attributeName === 'statuscode') {
        this._currentFormStringValues[attributeName] = value !='' ? this.dynamicFormService.translatedValue(value) : value;
      } else {
        this._currentFormStringValues[attributeName] = value;
      }
    } else {
      this._currentFormStringValues = {
        [attributeName]: value,
      }
    }
  }

  private _setAttributeValue(control: Control, value: any) {
    if (control.isCustom) {
      this._setCustomAttributeValue(control, value);
      return;
    }
    let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
    let cfvObj = {
      name: objName,
      entity: control.lookupEntitySetName,
      id: value
    }
    if (this._currentFormValue) {
      if (control.dataType == ControlDataType.LookupType) {
        if (this._currentFormValue.hasOwnProperty('lookupfields')) {
          let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            this._currentFormValue['lookupfields'][idx] = cfvObj;
          } else {
            this._currentFormValue['lookupfields'].push(cfvObj);
          }
        } else {
          this._currentFormValue['lookupfields'] = [cfvObj];
        }
      } else {
        if ((value === "" || value == undefined || value == null) && control.isRequired) {
          delete this._currentFormValue[control.attributeName];
        } else if (control.attributeName === "tagLabels") {
          this._currentFormValue[control.attributeName] = value.join(", ");
        } else {
          this._currentFormValue[control.attributeName] = value;
        }
      }
    } else {

      if (control.dataType == ControlDataType.LookupType) {
        this._currentFormValue = { lookupfields: [cfvObj] };
      } else {
        if ((value === "" || value == undefined || value == null) && control.isRequired) return;
        this._currentFormValue = {
          [control.attributeName]: value,
        }
      }
    }
  }

  private _setCustomAttributeValue(control: Control, value: any) {

    let cfvObj = {
      name: control.schemaName,
      entity: control.lookupEntitySetName,
      id: value
    };

    if (this._customFormValue) {
      if (control.dataType == ControlDataType.LookupType) {
        if (this._customFormValue.hasOwnProperty('lookupfields')) {
          let idx = this._customFormValue['lookupfields'].findIndex(a => a.name == control.schemaName);
          if (idx >= 0) {
            this._customFormValue['lookupfields'][idx] = cfvObj;
          } else {
            this._customFormValue['lookupfields'].push(cfvObj);
          }
        } else {
          this._customFormValue['lookupfields'] = [cfvObj];
        }
      } else {
        this._customFormValue[control.attributeName] = value;
      }
    } else {
      if (control.dataType == ControlDataType.LookupType) {
        this._customFormValue = { lookupfields: [cfvObj] };
      } else {
        this._customFormValue = {
          [control.attributeName]: value,
        }
      }
    }
  }

  private _initViewData() {
    if (this.formMetadata && this.formMetadata.metadata && this.formMetadata.metadata.length > 0) {
      console.log("initialized display form");
      if (this.TYPE == BusinessProcessType.OneKey || this.TYPE == BusinessProcessType.SanofiChina) {
        this.changeRequest = true;
        if (this.rawData.hasOwnProperty('attachment1') && this.rawData['attachment1']) {
          this.worksCardHeader = {
            id: 'work-card-section-header',
            title: this.translate.instant('ATTACHMENT_1'),
            controls: []
          };
          this.isWorkCardAdded = true;
          this.attachment1 = this.rawData['attachment1'].includes('{contactcrid}') ? this.rawData['attachment1'].replace('{contactcrid}', this.rawData['indskr_contactcrid']) : this.rawData['attachment1'];
        }
        if (this.rawData.hasOwnProperty('attachment2') && this.rawData['attachment2']) {
          this.practiceCertificateHeader = {
            id: 'practice-certificate-section-header',
            title: this.translate.instant('ATTACHMENT_2'),
            controls: []
          };
          this.isPracticeCertificateAdded = true;
          this.attachment2 = this.rawData['attachment2'].includes('{contactcrid}') ? this.rawData['attachment2'].replace('{contactcrid}', this.rawData['indskr_contactcrid']) : this.rawData['attachment2'];
        }
      }
      const tempFormView = [];
      // this.currentFormView = [];
      this.isEditControlPushed = false;
      const formFieldWidthMaxChar:number = ((window.innerWidth > 500 ? (0.60*window.innerWidth) : window.innerWidth) - 64 - 10 ) / 19;
      const formFieldLabelWidthMaxChar:number = ((window.innerWidth > 500 ? (0.60*window.innerWidth) : window.innerWidth) - 64 - 10 ) / 14;
      const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == this.formMetadata.mobileFormId && !config['indskr_visibility']);
      this.formMetadata.metadata.forEach((tab, tabIndex) => {
        let isSectionHide: boolean = false;
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.displayNames?.forEach(section => {
            if(!_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase().trim() == section.description.toLowerCase().trim())) {
              isSectionHide = true;
            }
          })
          if(isSectionHide) return;
          let secHeader = this._getSectionHeaderViewForTab(tab);
          if (secHeader) {
            tempFormView.push(secHeader);
          }
          let controlPosition = 0;
          tab.controls.forEach(control => {
            let view = this._getViewForControl(control);
            if (view && !tempFormView.some(v => v.id === view.id)) {
              if(view.type ==  'form-field'){
                let prevView = tempFormView[tempFormView.length - 1];
                if(view.view && ((view.view.inputText && view.view.inputText.length > formFieldWidthMaxChar) || ( view.view.label && view.view.label.length > formFieldLabelWidthMaxChar))){
                  view.fullWidth = true;
                  if(controlPosition > 1 && controlPosition % 2 > 0){
                    if(prevView && prevView.type == 'form-field'){
                      prevView.fullWidth = true;
                      controlPosition++;
                    }
                  }
                }
              }         
              tempFormView.push(view);
              if(view.type ==  'form-field'){
                controlPosition++;
                if(view.fullWidth){
                  controlPosition++;
                }
              }
            }
          });
          this.currentFormView = tempFormView;
        }
      });
    }
  }

  private _getViewForControl(control: Control): FormView {
    if (control.dataType) {
      if (this.changeRequest && this.SKIP_CHECK_THESE_FIELDS.indexOf(control.attributeName) > -1) {
        console.log("change request required field validation");
      } else {
        if (this.dynamicFormService.checkIfControlShouldNotBeVisible(control) || control.forceHide || this._getInputTextForFormField(control) == '') {
          if (control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType) {
            if (!control.isVisible) return null
          } else return null;
        }
      }
      try {
        if (control.dataType != ControlDataType.DateTimeType) {
          // this.dynamicFormService.customizeLabelRequestResponseComments(control);
          let labelText: string = this._getDisplayText(control.displayNames, control.isCustom, control.attributeName);
          let placeholderText: string = this._getDisplayText(control.descriptions, control.isCustom);
          let inputText: string = this._getInputTextForFormField(control);
          let fieldView: IndFormFieldViewDataModel = {
            label: labelText,
            inputText: inputText,
            inputValue: inputText,
            maxLength: this.dynamicFormService.getFormFieldType(control) == FormFieldType.INLINE_INPUT ? control.maxLength : 100,
            id: control.attributeName,
            isReadOnly: true,
            isDisabled: true,
            customPlaceholderLabel: placeholderText || " ",
            showArrow: false,
            inputType: (control.dataType == ControlDataType.DecimalType || control.dataType == ControlDataType.DoubleType || control.dataType == ControlDataType.IntegerType || control.dataType == ControlDataType.MoneyType) ? 'number' : 'text',
            formFieldType: this.dynamicFormService.getFormFieldType(control),
          };
          if (this.changeRequest && fieldView.inputText == '') {
            return null;
          }
          return {
            id: fieldView.id,
            type: 'form-field',
            view: fieldView,
            control: control,
          };
        } else if (control.dataType == ControlDataType.DateTimeType) {
          let dateFieldView: IndFormFieldViewDataModel
          let timeFieldView: IndFormFieldViewDataModel
          const isBirthDateField: boolean = (control.attributeName == 'omnione_yearofbirth' || control.attributeName == 'omnione_dayandmonthofbirth');
          if (control.dateFormat == DateFormat.DateOnly) {
            const getDateTimeValue = this._getInputTextForFormField(control);
            const formattedDateTimeValue = moment(getDateTimeValue).format();
            let dateFieldValue: string ='';
            if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid date') {
              let currentDateTimeValue = new Date(formattedDateTimeValue);
              dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            }
            let labelText: string = this._getDisplayText(control.displayNames, control.isCustom);
            let placeholderText: string = this._getDisplayText(control.descriptions, control.isCustom);
            dateFieldView = {
              label: labelText,
              inputText: !isBirthDateField ? dateFieldValue : getDateTimeValue,
              inputValue: !isBirthDateField ? dateFieldValue : getDateTimeValue,
              maxLength: this.dynamicFormService.getFormFieldType(control) == FormFieldType.INLINE_INPUT ? control.maxLength : 100,
              id: control.attributeName,
              isReadOnly: true,
              isDisabled: true,
              customPlaceholderLabel: placeholderText || " ",
              showArrow: false,
              inputType: 'text',
              formFieldType: this.dynamicFormService.getFormFieldType(control),
            };
            if (this.changeRequest && dateFieldView.inputText == '') {
              return null;
            }
            return {
              id: dateFieldView.id,
              type: 'form-field',
              view: dateFieldView,
              control: control
            };
          } else if (control.dateFormat == DateFormat.DateAndTime) {
            const getDateTimeValue = this._getInputTextForFormField(control);
            const formattedDateTimeValue = moment(getDateTimeValue).format();
            let dateFieldValue: string ='';
            let timeFieldValue: string = '';
            if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid date') {
              let currentDateTimeValue = new Date(formattedDateTimeValue);
              dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
              timeFieldValue = currentDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
              if (!this.dateTimeFormatsService.is12HourFormat && timeFieldValue.includes("24:")) {
                timeFieldValue = timeFieldValue.replace("24:", "00:");
              }
            }
            let labelText: string = this._getDisplayText(control.displayNames, control.isCustom);
            let placeholderText: string = this._getDisplayText(control.descriptions, control.isCustom);
            const dateTextTrans = this.translate.instant('DATE');
            let labelDateText: string = labelText;
            if (labelText.includes('Date') || labelText.includes('date') || labelText.includes(dateTextTrans) || labelText.includes(dateTextTrans.toLowerCase())) {
              labelDateText = labelText;
            } else {
              labelDateText = labelDateText + ' ' + dateTextTrans;
            }
            dateFieldView = {
              label: labelDateText,
              inputText: !isBirthDateField ? dateFieldValue : getDateTimeValue,
              inputValue: !isBirthDateField ? dateFieldValue : getDateTimeValue,
              maxLength: this.dynamicFormService.getFormFieldType(control) == FormFieldType.INLINE_INPUT ? control.maxLength : 100,
              id: control.attributeName,
              isReadOnly: true,
              isDisabled: true,
              customPlaceholderLabel: placeholderText || " ",
              showArrow: false,
              inputType: 'text',
              formFieldType: this.dynamicFormService.getFormFieldType(control),
            };
            const timeTextTrans = this.translate.instant('TIME');
            let labelTimeText: string = labelText;
            if (labelText.includes('Date')) {
              labelTimeText = labelText.replace('Date', 'Time');
            } else if (labelText.includes('date')) {
              labelTimeText = labelText.replace('date', 'time');
            } else if (labelText.includes(dateTextTrans)) {
              labelTimeText = labelText.replace(dateTextTrans, timeTextTrans);
            } else if (labelText.includes(dateTextTrans.toLowerCase())) {
              labelTimeText = labelText.replace(dateTextTrans.toLowerCase(), timeTextTrans.toLowerCase());
            } else {
              labelTimeText = labelTimeText + ' ' + timeTextTrans;
            }
            timeFieldView = {
              label: labelTimeText,
              inputText: timeFieldValue,
              inputValue: timeFieldValue,
              maxLength: this.dynamicFormService.getFormFieldType(control) == FormFieldType.INLINE_INPUT ? control.maxLength : 100,
              id: control.attributeName,
              isReadOnly: true,
              isDisabled: true,
              customPlaceholderLabel: placeholderText || " ",
              showArrow: false,
              inputType: 'text',
              formFieldType: this.dynamicFormService.getFormFieldType(control),
            };
            if (this.changeRequest && (dateFieldView.inputText == '' || timeFieldView.inputText == '')) {
              return null;
            }
            return {
              id: dateFieldView.id,
              type: 'form-field',
              view: dateFieldView,
              timeView: timeFieldView,
              control: control
            };
          }
        }

      } catch (error) {
        console.error('Error occured while parsing controls view' + error);
      }
    } else {// Related entity field
      try {
        if (control.forceHide == true) {
          return null;
        } else {
          let view: FormView;
          const affiliationsRefNames = [
            ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
            ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY,
            CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
          ];
          const isAffiliationsData = affiliationsRefNames.includes(control?.subgrid?.referencingEntity)
            || affiliationsRefNames.includes(control?.subgrid?.targetEntityType);

          if (!isAffiliationsData) {
            let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(control.displayNames));
            view = {
              id: headerLabel,
              type: 'section-header',
              view: {
                id: headerLabel,
                title: headerLabel,
                controls: control?.subgrid?.referencingEntity != 'indskr_indskr_customeraddress_v2' ? [] : [
                  {
                    id: headerLabel + '_' + 'SAVE',
                    text: this.translate.instant('SAVE'),
                    isDisabled: this.isSaveFavdisabled,      // Need to add a condition to enable/disable button
                  },
                ],
              },
              data: control.attributeName,
            };
          } else {
            // We apply custom logic for affiliations data
            // Please refer to OMNI-18003
            this.dynamicFormService.getCustomDisplayNameFromField(control, this.dynamicFormType);
            view = this._getAffiliationsView(control);
          }

          return view;
        }
      } catch (error) {
        console.error('Error occured while related entity field view' + error);
      }
    }
  }

  private _getInputTextForFormField(control: Control): string {
    if (control && control.dataType) {
      switch (control.dataType) {
        case ControlDataType.StringType:
        case ControlDataType.MoneyType:
        case ControlDataType.IntegerType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.MemoType:
          if (control.isCustom) {
            return (this._customFormValue && this._customFormValue[control.attributeName]) ? this._customFormValue[control.attributeName] : '';
          } else {
            return (this._currentFormValue && this._currentFormValue[control.attributeName]) ? this._currentFormValue[control.attributeName] : '';
          }
        case ControlDataType.LookupType:
          return (this._currentFormValue && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        case ControlDataType.BooleanType:
          return (this._currentFormValue && this._currentFormValue.hasOwnProperty(control.attributeName)) ? (this._currentFormValue[control.attributeName] ? this.translate.instant('YES') : this.translate.instant('NO')) : '';
        case ControlDataType.StateType:
          return (this._currentFormValue && (this._currentFormValue[control.attributeName] || this._currentFormValue[control.attributeName]==0) && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        case ControlDataType.StatusType:
        case ControlDataType.MultiSelectPicklistType:
        case ControlDataType.PicklistType:
          return (this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        case ControlDataType.DateTimeType:
          if (this._currentFormStringValuesForBirthDate) {
            if (control.attributeName == 'omnione_yearofbirth') {
              return this._currentFormStringValuesForBirthDate['omnione_yearofbirth'] ? this._currentFormStringValuesForBirthDate['omnione_yearofbirth'] : '';
            } else if (control.attributeName == 'omnione_dayandmonthofbirth') {
              return this._currentFormStringValuesForBirthDate['omnione_dayandmonthofbirth'] ? this._currentFormStringValuesForBirthDate['omnione_dayandmonthofbirth'] : '';
            } else {
              return (this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
            }
          } else return (this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  private _getSectionHeaderViewForTab(tab: FormTabs) {
    if (tab.displayNames && tab.displayNames.length > 0) {
      try {
        let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(tab.displayNames));
        let view = {
          id: headerLabel+'_section_header',
          type: 'section-header',
          view: {
            id: headerLabel,
            title: headerLabel,
            controls: (this.isEditControlPushed || !this.isEditEnabled) ? [] : [
              {
                id: headerLabel + '_' + 'EDIT',
                text: this.translate.instant('EDIT'),
                isDisabled: this.isEditDisabled,
              },
            ],
          },
        };
        this.isEditControlPushed = true;
        return view;
      } catch (error) {
        console.log('Error parsing section header view from tab' + error);
      }
    }
  }

  private _getDisplayText(dtArray: DisplayText[], isCustomControl: boolean = false, attributeName: string = '', entityName: string = '') {
    if (!dtArray) return "";
    let displayText: string;
    //-------Custom label--hard coded start
    //statuscode
    if (attributeName && attributeName == 'statuscode' && this.formMetadata
      && (this.formMetadata.entityName == 'account' || this.formMetadata.entityName == 'contact'
        || this.formMetadata.entityName == 'indskr_accountcr' || this.formMetadata.entityName == 'indskr_contactcr')) {
          if (this.TYPE != BusinessProcessType.SanofiChina) {
            return displayText = this.translate.instant('VERIFICATION');
          }
    }
    const refEntityName: string = entityName;
    const crAffiliationsRefNames = [ ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY ];
    //Custom label - hard coded for A-A-Affiliation in account form and C-C-Affiliation in contact form: relabeled by _getRelationLabel()
    //A-A-Affiliation in account CR form
    const isCrAffiliationsData = crAffiliationsRefNames.includes(refEntityName);
    if (isCrAffiliationsData && attributeName) {
      if (refEntityName == ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY) {
        if (attributeName == ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormService.accountCrAffiliatedToLabelText)) {
          return displayText = this.dynamicFormService.accountCrAffiliatedToLabelText;
        } else if (attributeName == ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormService.accountCrAffiliatedFromLabelText)) {
          return displayText = this.dynamicFormService.accountCrAffiliatedFromLabelText;
        }
      }
      //C-C-Affiliation in contact CR form
      else if (refEntityName == CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY) {
        if (attributeName == CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormService.contactCrAffiliatedToLabelText)) {
          return displayText = this.dynamicFormService.contactCrAffiliatedToLabelText;
        } else if (attributeName == CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormService.contactCrAffiliatedFromLabelText)) {
          return displayText = this.dynamicFormService.contactCrAffiliatedFromLabelText;
        }
      }
    }
    //-------hard coded end

    const langCode = (this.dynamicFormType == DynamicFormType.DEFAULTFORM) ? DEFAULT_FORM_LANGUAGE_CODE : this._activeLanguageCode;
    const dt = dtArray.find(value => value.languagecode == langCode);

    if (dt) {
      displayText = dt.languagecode == DEFAULT_FORM_LANGUAGE_CODE ? this.translate.instant(dt.description) : dt.description;
    } else {
      let en = dtArray.find(value => value.languagecode == "1033"); // default to english if no translation for the specific language;
      if (en) {
        displayText = en.description
      } else {
        //recheck the default form language code in the case of custom fields
        const defalutLang = dtArray.find(value => value.languagecode == '0000');
        if(defalutLang && defalutLang.description) {
          displayText = this.translate.instant(defalutLang.description);
        } else {
          displayText = '';
        }
      }
    }

    return displayText;
  }

  public onSectionHeaderControlClick(id: string) {
    if (id) {
      if (id.includes('EDIT')) {
        this.onEditClick.emit(true);
      }
      if (id.includes('SAVE')) {
        this._saveFavAddress();
      }
    }
  }

  private _seteBirthDateStringValue(attributeName: string, value: any) {
    if (attributeName == 'omnione_yearofbirth') {
      if (this._currentFormStringValuesForBirthDate) {
        this._currentFormStringValuesForBirthDate[attributeName] = value;
      } else {
        this._currentFormStringValuesForBirthDate = {
          [attributeName]: value,
        }
      }
    } else if (attributeName == 'omnione_dayandmonthofbirth') {
      const getYearValue = value.getFullYear().toString();
      const getTranslateDate = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      const regExpFindYear = new RegExp('(\/?\-?\,?\s?' + getYearValue + '?\,?\s?\-?\/?)', 'gi');
      let dayAndMonthOnly = getTranslateDate.replace(regExpFindYear, '');
      dayAndMonthOnly = dayAndMonthOnly.replace(',', '');
      if (this._currentFormStringValuesForBirthDate) {
        this._currentFormStringValuesForBirthDate[attributeName] = dayAndMonthOnly;
      } else {
        this._currentFormStringValuesForBirthDate = {
          [attributeName]: dayAndMonthOnly,
        }
      }
    }
  }


  /**
   * Affiliations section header
   *****************************************************************************************************************************
   */
  private _createOrUpdateSectionHeaderControlTextRx(id: string, text: string): { subject: BehaviorSubject<string>, observable: Observable<string> } {
    let rxObj: {
      subject: BehaviorSubject<string>,
      observable: Observable<string>
      };

      if (id && text !== undefined) {

        if (this.sectionHeaderControlTextRx && this.sectionHeaderControlTextRx[id]) {
          rxObj = this.sectionHeaderControlTextRx[id];
          rxObj.subject.next(text);
        } else if (this.sectionHeaderControlTextRx) {
          let subject = new BehaviorSubject<string>(text);
          let observable = subject.asObservable();
          rxObj = {
            subject,
            observable
          }
        }
      }
      return rxObj;
  }
  private _updateSectionHeaderFilterControlText(id: string) {
    if (this.affiliationsFilters && this.affiliationsFilters[id] && Array.isArray(this.affiliationsFilters[id].filters)) {
      let newText = '';
      const hasFilterValueOtherThanAll = this.affiliationsFilters[id].filters.some(f => f.value !== 'all');
      if (!hasFilterValueOtherThanAll) {
        newText = this.affiliationsFilters[id].filters[0].text;
      } else {
        for (let i = 0; i < this.affiliationsFilters[id].filters.length; i++) {
          const filter = this.affiliationsFilters[id].filters[i];
          newText = newText === '' ? filter.text : newText + ', ' + filter.text;
        }
      }

      if (this.sectionHeaderControlTextRx
        && this.sectionHeaderControlTextRx[id]
        && this.sectionHeaderControlTextRx[id].subject) {
        this.sectionHeaderControlTextRx[id].subject.next(newText);
      }
    }
  }
  public async onSectionHeaderControlClickWithEventBubbleUp({ button, event, buttonElRef }) {
    if (button && button.id) {
      if (this.filterPopoverData && this.filterPopoverData[button.id]) {
        this.popoverTargetButtonEl = buttonElRef?.el ?? undefined;
        this.popoverRef = await this.popoverCtrl.create({
          component: MultiSelectPopover,
          componentProps: {
            root: this.filterPopoverData[button.id]
          },
          event
        });
        await this.popoverRef.present();
        await this.popoverRef.onDidDismiss();
        this.popoverRef = undefined;
        this.popoverTargetButtonEl = undefined;
      }
    }
  }


  /**
   * Affiliations related
   *****************************************************************************************************************************
   */
  private _getRelationLabel(affiliationType: AffiliationType): string {
    let label = '';
    switch (affiliationType) {
      case AffiliationType.accountFromAccount:
        label = this.dynamicFormService.accountAffiliatedFromLabelText;
        break;
      case AffiliationType.accountToAccount:
        label = this.dynamicFormService.accountAffiliatedToLabelText;
        break;
      case AffiliationType.contactFromContact:
        label = this.dynamicFormService.contactAffiliatedFromLabelText;
        break;
      case AffiliationType.contactToContact:
        label = this.dynamicFormService.contactAffiliatedToLabelText;
        break;

      default:
        console.warn('_getRelationLabel: Invalid affiliationType: ', affiliationType);
        break;
    }
    return label;
  }
  private _getFromToRelationFilterValue(affiliationType: AffiliationType): 'from' | 'to' | '' {
    let relation: 'from' | 'to' | '' = '';

    switch (affiliationType) {
      case AffiliationType.accountFromAccount:
      case AffiliationType.contactFromContact:
        relation = 'from';
        break;

      case AffiliationType.accountToAccount:
      case AffiliationType.contactToContact:
        relation = 'to';
        break;

      default:
        console.warn('_getFromToRelationFilterValue: Invalid affiliation type: ', affiliationType);
        break;
    }

    return relation;
  }
  private _getAggregatedViewId(refEntityName: string, parentEntityName: string): string {
    let id: string;

    switch (refEntityName) {
      case ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY:
        id = ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID;
        break;

      case CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY:
        id = CONTACT_CONTACT_AFFILIATIONS_VIEW_ID;
        break;

      case ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY:
        if (parentEntityName === 'contact') {
          id = CONTACT_ACCOUNT_AFFILIATIONS_VIEW_ID;
        } else if (parentEntityName === 'account') {
          id = ACCOUNT_CONTACT_AFFILIATIONS_VIEW_ID;
        }
        break;

      default:
        break;
    }

    return id;
  }
  private _getAffiliationType(parentEntity: string, refEntity: string, refAttribute: string): AffiliationType {
    let affiliationType: AffiliationType;
    let warn: boolean = false;
    if (parentEntity === 'account') {
      if (refEntity === ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY) {
        affiliationType = AffiliationType.accountToContact;
      } else if (refEntity === ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
        if (refAttribute === ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME) {
          affiliationType = AffiliationType.accountFromAccount;
        } else if (refAttribute === ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME) {
          affiliationType = AffiliationType.accountToAccount;
        } else {
          warn = true;
        }
      } else {
        warn = true;
      }
    } else if (parentEntity === 'contact') {
      if (refEntity === ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY) {
        affiliationType = AffiliationType.contactToAccount;
      } else if (refEntity === CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY) {
        if (refAttribute === CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME) {
          affiliationType = AffiliationType.contactFromContact;
        } else if (refAttribute === CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME) {
          affiliationType = AffiliationType.contactToContact;
        } else {
          warn = true;
        }
      } else {
        warn = true;
      }
    }
    if (warn) {
      console.warn('_getAffiliationType: Invalid parent entity value: ', parentEntity, refEntity, refAttribute);
    }
    return affiliationType;
  }
  private _addDefaultAffiliationFilterValue(displayFormViewCard: IndDisplayFormAffiliationViewDataModel, leData: any, control: Control) {
    if (displayFormViewCard && leData && control) {

      if (displayFormViewCard.affiliationType) {
        let attributeName = control.subgrid.referencingEntity + '.';
        switch (displayFormViewCard.affiliationType) {
          case AffiliationType.accountFromAccount:
          case AffiliationType.accountToAccount:
            attributeName = attributeName + ACCOUNT_ACCOUNT_SOURCE_TYPE_ATTRIBUTE_NAME;
            break;
          case AffiliationType.contactFromContact:
          case AffiliationType.contactToContact:
            attributeName = attributeName + CONTACT_CONTACT_SOURCE_TYPE_ATTRIBUTE_NAME;
            break;
          case AffiliationType.accountToContact:
          case AffiliationType.contactToAccount:
            attributeName = attributeName + ACCOUNT_CONTACT_SOURCE_TYPE_ATTRIBUTE_NAME;
            break;
          default:
            attributeName = '';
            break;
        }
        if (attributeName) {
          attributeName = attributeName + '@OData.Community.Display.V1.FormattedValue';
          const attributeValue = leData[attributeName];
          if (attributeValue) {
            displayFormViewCard.filter['sourceType'] = attributeValue;
          }
        }
      }
    }
  }
  private _getAffiliationsView(control: Control): FormView {
    let formView: FormView;
    let id: string;
    let view: { id: string, title: string, controls: any[] };
    const refEntityName = control?.subgrid?.referencingEntity ?? control?.subgrid?.targetEntityType;
    const parentEntityName = control?.subgrid?.parentEntity ?? '';

    switch (refEntityName) {
      case ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY: {
        let filterControlId: string = ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID + '_' + 'FILTER';
        id = ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID;
        const sectionHeaderControlTextRxObj = this._createOrUpdateSectionHeaderControlTextRx(id, this.translate.instant('ALL'));
        this.sectionHeaderControlTextRx[id] = sectionHeaderControlTextRxObj;

        view = {
          id: ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID,
          title: this._getDisplayText([{ description: 'ACCOUNT_AFFILIATIONS', languagecode: DEFAULT_FORM_LANGUAGE_CODE }], true),
          controls: [{
            id: filterControlId,
            textObservable: sectionHeaderControlTextRxObj?.observable,
            text: this.translate.instant('ALL'),
            isDisabled: false,
            img: "assets/imgs/sort_with_double_arrows.svg",
            bubbleUpEvent: true,
          }]
        };
        let accountAffiliatedFromFilterText = _.isEmpty(this.dynamicFormService.accountAffiliatedFromLabelText) ? this.translate.instant('AFFILIATED_FROM') : this.dynamicFormService.accountAffiliatedFromLabelText;
        let accountAffiliatedToFilterText = _.isEmpty(this.dynamicFormService.accountAffiliatedToLabelText) ? this.translate.instant('AFFILIATED_TO') : this.dynamicFormService.accountAffiliatedToLabelText;
        this.filterPopoverData[filterControlId] = [
          {
            text: this.translate.instant('RELATIONSHIP'),
            expanded: true,
            value: 'all',
            items: [
              { text: this.translate.instant('ALL'), value: 'all', filterBy: 'fromToRelation' },
              { text: accountAffiliatedFromFilterText, value: 'from', filterBy: 'fromToRelation' },
              { text: accountAffiliatedToFilterText, value: 'to', filterBy: 'fromToRelation' },
            ],
            handler: (selectedItem, item) => this._filterClickHandler(id, selectedItem, item)
          },
          {
            text: this.translate.instant('SOURCE_TYPE'),
            expanded: true,
            value: 'all',
            items: [
              { text: this.translate.instant('ALL'), value: 'all', filterBy: 'sourceType' },
              { text: this.translate.instant('BUSINESS_AFFILIATIONS'), value: 'Business', filterBy: 'sourceType' },
              { text: this.translate.instant('MDM_AFFILIATIONS'), value: 'MDM', filterBy: 'sourceType' },
            ],
            handler: (selectedItem, item) => this._filterClickHandler(id, selectedItem, item)
          }
        ];
        break;
      }

      case CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY: {
        let filterControlId: string = CONTACT_CONTACT_AFFILIATIONS_VIEW_ID + '_' + 'FILTER';
        id = CONTACT_CONTACT_AFFILIATIONS_VIEW_ID;
        const sectionHeaderControlTextRxObj = this._createOrUpdateSectionHeaderControlTextRx(id, this.translate.instant('ALL'));
        this.sectionHeaderControlTextRx[id] = sectionHeaderControlTextRxObj;

        view = {
          id: CONTACT_CONTACT_AFFILIATIONS_VIEW_ID,
          title: this._getDisplayText([{ description: 'CONTACT_AFFILIATIONS', languagecode: DEFAULT_FORM_LANGUAGE_CODE }], true),
          controls: [{
              id: CONTACT_CONTACT_AFFILIATIONS_VIEW_ID + '_' + 'FILTER',
              textObservable: sectionHeaderControlTextRxObj?.observable,
              text: this.translate.instant('ALL'),
              isDisabled: false,
              img: "assets/imgs/sort_with_double_arrows.svg",
              bubbleUpEvent: true,
          }]
        };
        let contactAffiliatedFromFilterText = _.isEmpty(this.dynamicFormService.contactAffiliatedFromLabelText) ? this.translate.instant('RELATED_FROM') : this.dynamicFormService.contactAffiliatedFromLabelText;
        let contactAffiliatedToFilterText = _.isEmpty(this.dynamicFormService.contactAffiliatedToLabelText) ? this.translate.instant('RELATED_TO') : this.dynamicFormService.contactAffiliatedToLabelText;
        this.filterPopoverData[filterControlId] = [
          {
            text: this.translate.instant('RELATIONSHIP'),
            expanded: true,
            value: 'all',
            items: [
              { text: this.translate.instant('ALL'), value: 'all', filterBy: 'fromToRelation' },
              { text: contactAffiliatedFromFilterText, value: 'from', filterBy: 'fromToRelation' },
              { text: contactAffiliatedToFilterText, value: 'to', filterBy: 'fromToRelation' },
            ],
            handler: (selectedItem, item) => this._filterClickHandler(id, selectedItem, item)
          },
          {
            text: this.translate.instant('SOURCE_TYPE'),
            expanded: true,
            value: 'all',
            items: [
              { text: this.translate.instant('ALL'), value: 'all', filterBy: 'sourceType' },
              { text: this.translate.instant('BUSINESS_AFFILIATIONS'), value: 'Business', filterBy: 'sourceType' },
              { text: this.translate.instant('MDM_AFFILIATIONS'), value: 'MDM', filterBy: 'sourceType' },
            ],
            handler: (selectedItem, item) => {
              this._filterClickHandler(id, selectedItem, item);
            }
          }
        ];
        break;
      }

      case ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY: {
        let title: string;

        if (parentEntityName === 'contact') {
          id = CONTACT_ACCOUNT_AFFILIATIONS_VIEW_ID;
          title = this._getDisplayText([{ description: 'ACCOUNT_AFFILIATIONS', languagecode: DEFAULT_FORM_LANGUAGE_CODE }], true);
        } else if (parentEntityName === 'account') {
          id = ACCOUNT_CONTACT_AFFILIATIONS_VIEW_ID;
          title = this._getDisplayText([{ description: 'CONTACT_AFFILIATIONS', languagecode: DEFAULT_FORM_LANGUAGE_CODE }], true);
        } else {
          console.warn('_getAffiliationsView: invalid parent entity', control);
        }
        if (id) {
          const sectionHeaderControlTextRxObj = this._createOrUpdateSectionHeaderControlTextRx(id, this.translate.instant('ALL'));
          this.sectionHeaderControlTextRx[id] = sectionHeaderControlTextRxObj;

          view = {
            id,
            title,
            controls: [{
                id: id + '_' + 'FILTER',
                textObservable: sectionHeaderControlTextRxObj?.observable,
                text: this.translate.instant('ALL'),
                isDisabled: false,
                img: "assets/imgs/sort_with_double_arrows.svg",
                bubbleUpEvent: true,
            }]
          };

          let filterControlId: string = id + '_' + 'FILTER';

          let filterItems = [
            { text: this.translate.instant('ALL'), value: 'all', filterBy: 'sourceType' },
            { text: this.translate.instant('BUSINESS_AFFILIATIONS'), value: 'Business', filterBy: 'sourceType' },
            { text: this.translate.instant('MDM_AFFILIATIONS'), value: 'MDM', filterBy: 'sourceType' },
          ];

          if(!_.isEmpty(this.dynamicFormService.targetContactIds)) {
            filterItems.push({ 
              text: this.translate.instant('TARGET_CUSTOMER'), 
              value: 'target', 
              filterBy: 'sourceType' 
            });
          }

          this.filterPopoverData[filterControlId] = [{
            text: '',
            expanded: true,
            value: 'all',
            items: filterItems,
            handler: (selectedItem, item) => this._filterClickHandler(id, selectedItem, item)
          }];
        }
        break;
      }

      default:
        break;
    }

    if (id && view) {
      // Overwrite attributeName
      control.attributeName = id;

      formView = {
        id,
        type: 'section-header',
        view,
        data: id
      };
    }

    return formView;
  }


  /**
   * Click handlers
   *****************************************************************************************************************************
   */
  private _filterClickHandler(id: string, selectedItem, item) {
    item.value = selectedItem.value;
    this.dynamicFormService.updateAffiliationsFilter(this.affiliationsFilters, id, selectedItem.filterBy, selectedItem.value, selectedItem.text);
    this._updateSectionHeaderFilterControlText(id);
    this.dynamicFormService.filterAffiliations(this.linkedEntityValues, this.affiliationsFilters, id);
  }
  //#1 View Click Handler: From Account Details to Contact Affiliation
  private async _aToCviewClickHandler(control: Control, leData, id: string) {
    if (control.subgrid.referencingAttribute === 'indskr_accountid') {
      await this.contactService.setErrorMessageOpenContact();
      this.contactService.isInGlobalSearch = false;
      this.dynamicFormService.isNavAffiliatedContactFromAccount = true; // This flag prevents new contacts from being created while fetching data.
      if (this.accountService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountService.accountPageMode == ComponentViewMode.PREVIEW) return
      const affiliatedContactId = leData["indskr_accountcontactaffiliation.indskr_contactid"];
      const foundContactFromLocal = this.contactService.getContactByID(affiliatedContactId);
      if (foundContactFromLocal) {
        this.contactService.isNotInMyContactList = false;
        this.openContactOnAccountDetails(foundContactFromLocal);
        // In case of not map to user position, try to get the affiliated contact by global search
      } else {
        this.uiService.displayLoader();
        this.contactService.isNotInMyContactList = true;
        const rawDetails =  await this.contactService.fetchContactsForConfiguredDisplay(true, false, affiliatedContactId, true);
        if (rawDetails && rawDetails[0]) {
          const foundContact = new Contact(rawDetails[0]);
          this.contactService.isInGlobalSearch = false;
          this.uiService.dismissLoader();
          if (foundContact) this.openContactOnAccountDetails(foundContact);
        } else if (this.device.isOffline){
          const targetText = this.translate.instant("CONTACT_SINGULAR");
          this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE_NEED_TO_BE_ONLINE', {text: targetText}), 'Contact Details', 'top', ToastStyle.INFO);
        } else {
          this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE'), 'Contact Details', 'top', ToastStyle.INFO);
        }
        this.uiService.dismissLoader();
      }
    }
  }
  //#2 View Click Handler: From Account details to Account Affiliation
  private async _aToAviewClickHandler(control: Control, leData, id: string) {
    if (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount && this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) return;
    await this.accountService.setErrorMessageOpenAccount();
    let affiliatedAccountId = '';
    if (control.subgrid.referencingAttribute === 'indskr_affiliatedtoaccountid') {
      affiliatedAccountId = leData["indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid"];
    } else if (control.subgrid.referencingAttribute === 'indskr_affiliatedfromaccountid') {
      affiliatedAccountId = leData["indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid"];
    }
    const foundAccountFromLocal = this.accountService.getAccountById(affiliatedAccountId);
    if (foundAccountFromLocal) {
      this.accountService.isNotInMyAccountList = false;
      this.openAccountOnAccountDetails(foundAccountFromLocal);
    } else {
      this.uiService.displayLoader();
      this.accountService.isNotInMyAccountList = true;
      const rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(affiliatedAccountId,true);
      if (rawDetails) {
        const foundAccount = new Account(rawDetails);
        this.uiService.dismissLoader();
        if (foundAccount) this.openAccountOnAccountDetails(foundAccount);
      } else if (this.device.isOffline){
        const targetText = this.translate.instant("ACCOUNT");
        this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE_NEED_TO_BE_ONLINE', {text: targetText}), 'Account Details', 'top', ToastStyle.INFO);
      } else {
        this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE'), 'Account Details', 'top', ToastStyle.INFO);
      }
      this.uiService.dismissLoader();
    }
  }
  //#3 View Click Handler: From Contact Details to Account Affiliationn
  private async _cToAviewClickHandler(control: Control, leData, id: string) {
    if (control.subgrid.referencingAttribute === 'indskr_contactid') {
      await this.accountService.setErrorMessageOpenAccount();
      if (this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.PREVIEW) return;
      const affiliatedAccountId = leData["indskr_accountcontactaffiliation.indskr_accountid"];
      const foundAccountFromLocal = this.accountService.getAccountById(affiliatedAccountId);
      if (foundAccountFromLocal) {
        this.accountService.isNotInMyAccountList = false;
        this.openAccountOnContactDetails(foundAccountFromLocal);
        // In case of not map to user position, try to get the affiliated account by global search
      } else {
        this.uiService.displayLoader();
        this.accountService.isNotInMyAccountList = true;
        const rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(affiliatedAccountId,true);
        if (rawDetails) {
          const foundAccount = new Account(rawDetails);
          this.uiService.dismissLoader();
          if (foundAccount) this.openAccountOnContactDetails(foundAccount);
        } else if (this.device.isOffline){
          const targetText = this.translate.instant("ACCOUNT");
          this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE_NEED_TO_BE_ONLINE', {text: targetText}), 'Account Details', 'top', ToastStyle.INFO);
        } else {
          this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE'), 'Account Details', 'top', ToastStyle.INFO);
        }
        this.uiService.dismissLoader();
      }
    }
  }
  //#4 View Click Handler: From Contact details to Contact Affiliation
  private async _cToCviewClickHandler(control: Control, leData, id: string) {
    if (this.dynamicFormService.isOpenedAffiliatedContactOnContact && this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) return;
    await this.contactService.setErrorMessageOpenContact();
    let affiliatedContactId = '';
    if (control.subgrid.referencingAttribute === 'indskr_contactid') {
      affiliatedContactId = leData['indskr_contactrelationship.indskr_relatedcontactid'];
    } else if (control.subgrid.referencingAttribute === 'indskr_relatedcontactid') {
      affiliatedContactId = leData['indskr_contactrelationship.indskr_contactid'];
    }
    const foundContactFromLocal = this.contactService.getContactByID(affiliatedContactId);
    if (foundContactFromLocal) {
      this.contactService.isNotInMyContactList = false;
      this.openContactOnContactDetails(foundContactFromLocal);
    } else {
      this.uiService.displayLoader();
      this.contactService.isNotInMyContactList = true;
      const rawDetails =  await this.contactService.fetchContactsForConfiguredDisplay(true, false, affiliatedContactId, true);
      if (rawDetails && rawDetails[0]) {
        const foundContact = new Contact(rawDetails[0]);
        this.contactService.isInGlobalSearch = false;
        this.uiService.dismissLoader();
        if (foundContact) this.openContactOnContactDetails(foundContact);
      } else if (this.device.isOffline){
        const targetText = this.translate.instant("CONTACT_SINGULAR");
        this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE_NEED_TO_BE_ONLINE', {text: targetText}), 'Contact Details', 'top', ToastStyle.INFO);
      } else {
        this.notificationService.notify(this.translate.instant('PREVIEW_UNAVAILABLE'), 'Contact Details', 'top', ToastStyle.INFO);
      }
      this.uiService.dismissLoader();
    }
  }

  private async onlineAccordionDataFetchClickHandler(control: Control, leData): Promise<any>{
    return new Promise(async (resolve, reject) => {
      switch(control.subgrid.referencingEntity){
        case "indskr_customerposition":
          if(!this.device.isOffline){
            const posID = leData['indskr_customerposition.indskr_positionid'];
            let res = [];
            if(posID){
              res = await this.dynamicsClient.retrieveAll('indskr_userpositions',
                                                          ['indskr_userid'],
                                                          `statecode eq 0 and indskr_userid/isdisabled eq false and indskr_positionid/positionid eq '${posID}'`
                                                          ).then(res => res.value).catch(()=> []);
              if(res && res.length){
                let data;
                data = res.map(o=>{
                  return {
                    id: o['_indskr_userid_value'],
                    label:this.translate.instant('NAME'),
                    value:o['_indskr_userid_value_Formatted']
                  }
                })
                resolve(data);
              }
              else{
                this.notificationService.notify(this.translate.instant('USERS_VIEW_EMPTY_ERROR'), 'Configured Form');
                resolve([]);
              }
            }
            else{
              this.notificationService.notify(this.translate.instant('USERS_VIEW_MISSING_POSITION_ERROR'), 'Configured Form');
              resolve([]);
            }
          }
          else{
            this.notificationService.notify(this.translate.instant('USERS_VIEW_OFFLINE_ERROR'), 'Configured Form');
            resolve([]);
          }
          break;
      }
    })
  }
  // set readOnly on display Form View Card
  private _checkReadOnlyViewCard(): boolean {
    let isReadOnly: boolean = false;
    if (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount
      || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
      || this.dynamicFormService.isOpenedAffiliatedContactOnContact
      || this.dynamicFormService.isOpenedAffiliatedAccountOnContact
      || this.contactService.contactPageMode === ComponentViewMode.PREVIEW
      || this.accountService.accountPageMode === ComponentViewMode.PREVIEW) {
      isReadOnly = true;
    }
    return isReadOnly;
  }
  // reset flag for affiliated account/contact details page
  private _resetFlagOpenedAffiliatedDataDetails() {
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
  }
  /**
   * formatted date and time values are based on stored values in _currentFormStringValues
   * formatted raw data causes invalid date issue because some formats are not supported on iOS and moment format
   * set formatted date and time values in _getViewForControl()
  */
  private _setAttributeValueDate(targetControl: Control, dateValue: any) {
    if (targetControl.dateFormat === DateFormat.DateAndTime) {
      this._setAttributeStringValue(targetControl.attributeName, format(dateValue, 'YYYY/MM/DD HH:mm'));
      let dateValueStr = _.isString(dateValue) ? dateValue : dateValue.toISOString();
      this._setAttributeValue(targetControl, dateValueStr);
    } else if (targetControl.dateFormat === DateFormat.DateOnly) {
      this._setAttributeStringValue(targetControl.attributeName, format(dateValue, 'YYYY/MM/DD'));
      if (targetControl.dateBehavior == 'UserLocal') {
        let dateValueStr = _.isString(dateValue) ? dateValue : dateValue.toISOString();
        this._setAttributeValue(targetControl, dateValueStr);
      } else {
        this._setAttributeValue(targetControl, format(dateValue, 'YYYY-MM-DD'));
      }
    }
  }

  public favoriteAdd(item) {
    if(!item.showFavouriteIcon) return;
    let positionId = this._getCommonPositionId();
    let payload = {
      customerAddressId: item.linkedEntityExistingDataAddressId,
      positionId: positionId,
      isDeleted: false,
      pendingPushOnDynamics: true,
    };
    if(item.isFavourite) {
      item.isFavourite = false;
      payload.isDeleted = true;
    } else {
      item.isFavourite = true;
      payload.isDeleted = false;
    }
    this._validateFavAddressPayload(payload);
    this._enableDisableSaveButton();
  }

  private _enableDisableSaveButton(){
    this.isSaveFavdisabled = true;
    let linkedEntityValue;
    this.formMetadata.metadata.forEach((tab, tabIndex) => {
      if (tab && tab.controls && tab.controls.length > 0) {
        let addressEntity = tab.controls.find(control => control.subgrid?.referencingEntity == 'indskr_indskr_customeraddress_v2');
        if(addressEntity) linkedEntityValue = addressEntity.attributeName
      }
    });
    if(this.initialFavAddress && this.initialFavAddress.length>0){
      this.initialFavAddress.forEach(add => {
        let customerAdd = this.linkedEntityValues[linkedEntityValue].find(value => value['linkedEntityExistingDataAddressId'] == add.customerAddressId);
        if(customerAdd && (customerAdd['isFavourite'] == add.isFavourite || (!customerAdd['isFavourite'] && !add.isFavourite))) this.isSaveFavdisabled = this.isSaveFavdisabled && true;
        else {
          this.isSaveFavdisabled = false;
        }
      })
    }
    this._initViewData();
  }

  private _getCommonPositionId(): string {
    let positionId;
    let customerPositions = [];
    const userPositionIds = this.authService.user.positions.map(o => {
      return {
        positionId : o.ID,
        isPrimary: o.primary
      }
    });
    const userChildPositionIds = this.authService.user.childPositions;
    let positionIds = [];
    userPositionIds.forEach(id => positionIds.push(id.positionId));
    userChildPositionIds.forEach(id => positionIds.push(id.childPositionId))
    let linkedEntityValue;
    this.formMetadata.metadata.forEach((tab, tabIndex) => {
      if (tab && tab.controls && tab.controls.length > 0) {
        let positionEntity = tab.controls.find(control => control.subgrid?.referencingEntity == 'indskr_customerposition');
        if(positionEntity) linkedEntityValue = positionEntity.attributeName;
      }
    });
    if(this.linkedEntityValues[linkedEntityValue] && this.linkedEntityValues[linkedEntityValue].length>0) {
      this.linkedEntityValues[linkedEntityValue].forEach(value => {
        if(value['rawObjData']) customerPositions.push(value['rawObjData']['indskr_customerposition.indskr_positionid'])
      });
    }
    let commonPositions = positionIds.filter(id => customerPositions.includes(id));
    if(commonPositions && commonPositions.length == 1) {
      positionId = commonPositions[0];
    } else {
      positionId = userPositionIds.find(position => position.isPrimary).positionId;
    }
    return positionId;
  }

  private _validateFavAddressPayload(payload) {
    let index = -1;
    if(this.tempFavAddress && this.tempFavAddress.length>0) {
      index = this.tempFavAddress.findIndex(add => add.customerAddressId.includes(payload.customerAddressId) && add.positionId.includes(payload.positionId));
    }
    if(index<0) this.tempFavAddress.push(payload);
    else this.tempFavAddress[index] = payload;
  }

  private async _saveFavAddress() {
    this.uiService.displayLoader();
    await this.addressService.updatePreferredAddressInOfflineDB(this.tempFavAddress).then(async() => {
      let linkedEntityValue;
      this.formMetadata.metadata.forEach((tab, tabIndex) => {
        if (tab && tab.controls && tab.controls.length > 0) {
          let addressEntity = tab.controls.find(control => control.subgrid?.referencingEntity == 'indskr_indskr_customeraddress_v2')
          if(addressEntity) linkedEntityValue = addressEntity.attributeName;
        }
      });
      if(this.initialFavAddress && this.initialFavAddress.length>0){
        this.initialFavAddress.forEach(add => {
          let customerAdd = this.tempFavAddress.find(t => t.customerAddressId.includes(add.customerAddressId) && t.positionId.includes(this._getCommonPositionId()));
          if(customerAdd) {
            this.linkedEntityValues[linkedEntityValue].find(value => value['linkedEntityExistingDataAddressId'] == add.customerAddressId)['isFavourite'] = !customerAdd['isDeleted'];
            add.isFavourite = !customerAdd['isDeleted'];
          }
        });
      }
      if(!this.device.isOffline) await this.addressService.uploadPreferredAddressOnline();
      this.tempFavAddress = [];
      this.isSaveFavdisabled = true;
      this._initViewData();
    });
    // if(!this.device.isOffline) await this.addressService.uploadPreferredAddressOnline();
    this.uiService.dismissLoader();
  }

  private getCustomerAvailability(control, leData, targetElmnt){
    if(targetElmnt != 'rightIcon') return
    if(this.device.isOffline){
     this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'),'');
     return;
    }
    if(!this.custAvailabilityData || (this.custAvailabilityData.custAddress != leData['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id'])){
      this.dynamicsClient.retrieveAll('indskr_customeravailability_v2s',
                                      ['indskr_dayofweek',
                                        'indskr_from','indskr_to',
                                        '_modifiedby_value','modifiedon',
                                        'indskr_dayorder',
                                        '_indskr_customeraddressid_value'
                                      ],
                                      `indskr_CustomerAddressId/indskr_indskr_customeraddress_v2id
                                        eq ${leData['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']}`
                                    )
      .then(async response=>{
        if(response){
          let data:any = _.sortBy(response.value,['indskr_dayorder','indskr_from'])
          //data = _.groupBy(data,'indskr_dayofweek@OData.Community.Display.V1.FormattedValue');
          let formattedData:{custAddress:string, availabilityData:{dayValue:any, day:string,data:any[],modifiedBy:string,modifiedOn:any}[]} = {custAddress:'',availabilityData:[]};
          formattedData.custAddress = leData['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id'];
          data.map(o=>{
            if(!formattedData.availabilityData.some(f=>f.day==o['indskr_dayofweek@OData.Community.Display.V1.FormattedValue'])){
              formattedData.availabilityData.push({
                dayValue:o['indskr_dayofweek'],
                day:o['indskr_dayofweek@OData.Community.Display.V1.FormattedValue'],
                data:[o],
                modifiedBy:o['_modifiedby_value_Formatted'],
                modifiedOn:o['modifiedon']
              })
            }
            else{
              let match = formattedData.availabilityData.find(f=>f.day==o['indskr_dayofweek@OData.Community.Display.V1.FormattedValue'])
              match.data.push(o);
              if(match.modifiedOn && match.modifiedOn<o['modifiedon']){
                match.modifiedBy = o['_modifiedby_value_Formatted']
                match.modifiedOn = o['modifiedon']
              }
            }
          })
          this.custAvailabilityData = formattedData
          this.presentCustAvailModal(leData['indskr_address.indskr_composite'], this.contactService.contactInformation.fullName);
        }
        // else if(response.value?.length ==0){
        //   this.notificationService.notify(this.translate.instant('NO_DATA_TO_DISPLAY'),'');
        // }
      })
    }
    else{
      this.presentCustAvailModal(leData['indskr_address.indskr_composite'], this.contactService.contactInformation.fullName);
    }
  }

  private viewConsent(control, leData, targetElmnt){
    if(targetElmnt != 'rightIcon') return

    if (this.navService.getCurrentPageName() === PageName.ContactPageComponent && this.uiService.isConsentFromToolDrawer){

      const hasConsentTab = this.uiService.tabsData.find((tab)=>{
        return tab.value ==='profile' && tab.hide === true
      });
      if(hasConsentTab)
        this.uiService.contactDetailsSegment = 'consent';
      else
        this.uiService.contactDetailsSegment = 'profile';


    } else if (this.navService.getCurrentPageName() === PageName.ContactPageComponent && !this.uiService.isConsentFromToolDrawer) {
      this.uiService.contactDetailsSegment = 'profile';
    }
    if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI,true)){
      this.events.publish(EventName.CONTACTDETAILSSEGMENTUPDATE,this.uiService.contactDetailsSegment);
    }
  }

  private async presentCustAvailModal(address:string, contactName:string){
    // const modal = await this.modalCtrl.create({
    //   component: CustomerAvailabilityComponent,
    //   componentProps: {data: this.custAvailabilityData.availabilityData, address, contactName},
    //   cssClass: 'customer-availability-modal'
    // });
    // await modal.present().then(() => {});

    this.navService.pushChildNavPageWithPageTracking(CustomerAvailabilityComponent, PageName.CustomerAvailabilityComponent,
                  PageName.ContactPageComponent,{data: this.custAvailabilityData.availabilityData, address, contactName, customerAddressId:this.custAvailabilityData.custAddress})
  }

  private displayMedications(products: any) {
    let productNames: string = "";
    if (products.length) {
      productNames = Array.prototype.map.call(products, p => p.indskr_productname).join(', ');
    }
    return productNames;
  }

  private async getProductsWithConsentsByContactId() {
    if (!this.contactService.contactInformation) return;
    let contactId = this.contactService.contactInformation.ID.toString();
    console.log(`getProductsWithConsentsByContactId contactId: ${contactId}`);

    if (!_.isEmpty(this.consentService.allConsentActivitiesWithProductsAndEmails)) {
      console.warn(`Filter Consent Activities By Contact Id`);
      // --------------------------------Products With Consents-------------------------------- //
      const allConsentActivitiesByContactId = this.consentService.allConsentActivitiesWithProductsAndEmails.filter(
        item => item.contactId === contactId);

      console.log(`allConsentActivitiesByContactId: ${allConsentActivitiesByContactId.length}`);

      if (!_.isEmpty(allConsentActivitiesByContactId)) {
        console.warn(`Grouped Products By EmailAddress`);

        const productsGroupedByEmailAddress = _.groupBy(allConsentActivitiesByContactId, (car) => car['emailAddress']);

        if (!_.isEmpty(productsGroupedByEmailAddress)) {
          for (let key in productsGroupedByEmailAddress) {
            this.dictionaryAddressListWithProductConsent[key] = this.displayMedications(productsGroupedByEmailAddress[key]);
            }
        }
      }//end of if (!_.isEmpty(allConsentActivitiesByContactId))
      // --------------------------------Products With Consents-------------------------------- //
    }
  }

  async downloadAttachment(event, attachment) {
    console.log(attachment);
    if (this.device.isOffline || !attachment) {
      this.notificationService.notify(this.translate.instant('MATERIAL_NO_OFFLINE_DOWNLOAD'), 'Contact Details', 'top', ToastStyle.INFO);
      return;
    }
    let fileName = attachment;
    if (fileName.includes('{contactcrid}')) {
      fileName = fileName.replace('{contactcrid}', this.rawData['indskr_contactcrid']);
    }
    let payload = {
      "imagePath": fileName
    }
    this.uiService.displayLoader();
    await this.mdmService.fetchMaterial(payload).then(async (res) => {
      if (res && res['status_code'] == 200) {
        const fileNameRegEx = /(?:\.([^.]+))?$/;
        let extension = fileNameRegEx.exec(fileName)[1];
        if (extension) {
          let base64Str = "data:image/" + extension + ";base64, " + res['content'];
          let popover = await this.popoverCtrl.create({
            component: MdmMaterialModal,
            componentProps: { materialData: base64Str },
            cssClass: "mdm-material-modal",
            backdropDismiss: true
          });
          popover.present();
        }
      }
    }).catch(err => {
      this.notificationService.notify(this.translate.instant('FAILED_DOWNLOAD_MATERIAL'), 'Contact Details', 'top', ToastStyle.INFO);
    })
  }

  async getAffiliatedContacts(control: Control, leData) {
    if (control.subgrid.referencingAttribute === 'indskr_accountid') {
      await this.contactService.setErrorMessageOpenContact();
      this.contactService.isInGlobalSearch = false;
      this.dynamicFormService.isNavAffiliatedContactFromAccount = true; // This flag prevents new contacts from being created while fetching data.
      if (this.accountService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountService.accountPageMode == ComponentViewMode.PREVIEW) return
      const affiliatedContactId = leData["indskr_accountcontactaffiliation.indskr_contactid"];
      const affiliatedContactName = leData['indskr_accountcontactaffiliation.indskr_contactid_Formatted'];

      let selIndex = this.dynamicFormService.affiliatedContacts.findIndex((actId) => actId.ID == affiliatedContactId); //Check for duplicates
      if (selIndex == -1) {
        this.dynamicFormService.affiliatedContacts.push({
          ID: affiliatedContactId,
          fullname: affiliatedContactName
        });
      }
    }
  } 


  private _getFormContext(){
    return {
      data: {
        entity: {
          attributes: {
            get: (fieldName) => this._getAttribute(fieldName),
          }
        }
      }
    }
  }

  private _getAttribute(fieldName){
    return {
      getValue: () => this._getAttributeValue(fieldName),
      setValue: (value) => this._setBusinessRuleAttributeValue(value,fieldName),
      controls: [
        {
          setVisible: (visible) => this._setVisible(visible,fieldName),
          setDisabled: (disabled) => this._setDisabled(disabled,fieldName),
        },
      ],
      setRequiredLevel: (required) => this._setIsRequired(required,fieldName),
    }
  }

  private _setVisible(visible, fieldName) {
    //let viewField = this.currentFormView.find(a => a.id == fieldName);
    // if (viewField && viewField.view) {
    //   viewField.view.isHidden = !visible;
    //   this._cd.detectChanges();
    // }
    for (let tabIdx = 0; tabIdx < this.formMetadata.metadata.length; tabIdx++) {
      const tab = this.formMetadata.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == fieldName);
        if (foundControl) {
          foundControl.forceHide = !visible;
          this._runInitViewDataAfterBusinessRuleLogic = true;
          break;
        }
      }
    };
    // if(targetControl){
    //   targetControl.isVisible = visible;
    //   this._runInitViewDataAfterBusinessRuleLogic = true;
    // }
  }

  private _setDisabled(disabled, fieldName) {
    // let viewField = this.currentFormView.find(a => a.id == fieldName);
    // if (viewField && viewField.view) {
    //   viewField.view.isDisabled = disabled;
    //   this._cd.detectChanges();
    // }
    // let targetControl: Control = this._getTargetControl(fieldName);
    // if(targetControl){
    //   targetControl.isReadOnly = disabled;
    //   this._runInitViewDataAfterBusinessRuleLogic = true;
    // }
    for (let tabIdx = 0; tabIdx < this.formMetadata.metadata.length; tabIdx++) {
      const tab = this.formMetadata.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == fieldName);
        if (foundControl) {
          foundControl.isReadOnly = disabled;
          this._runInitViewDataAfterBusinessRuleLogic = true;
          break;
        }
      }
    };
  }

  private _setIsRequired(required,fieldName) {
    // let viewField = this.currentFormView.find(a => a.id == fieldName);
    // if (viewField && viewField.view) {
    //   viewField.view.isRequired = required == 'required';
    //   this._cd.detectChanges();
    // }
    // let targetControl: Control = this._getTargetControl(fieldName);
    // if(targetControl){
    //   targetControl.isRequired = required == 'required';
    //   this._runInitViewDataAfterBusinessRuleLogic = true;
    // }
    for (let tabIdx = 0; tabIdx < this.formMetadata.metadata.length; tabIdx++) {
      const tab = this.formMetadata.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == fieldName);
        if (foundControl) {
          foundControl.isRequired = required == 'required';
          this._runInitViewDataAfterBusinessRuleLogic = true;
          break;
        }
      }
    };
  }

  private _getAttributeValue(fieldName){
    let targetControl: Control = this._getTargetControl(fieldName);
    if(targetControl.dataType == ControlDataType.PicklistType){
      if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName)) {
        return parseInt(this._currentFormValue[targetControl.attributeName]);
      }else{
        return null;
      }
    }
    // else if(targetControl.dataType == ControlDataType.LookupType){
    //   const comparisonValue = !this.isHandleEditAddressFormField ? (targetControl.isCustom ? this._customFormValue : this._currentFormValue) : (targetControl.isCustom ? this._existingCustomFormValue : this._existingFormValue);
    //   const objName = targetControl.isCustom ? targetControl.schemaName : (targetControl.isCustomAttribute ? targetControl.schemaName : targetControl.attributeName);
    //   if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
    //     let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName);
    //     if (idx >= 0) {
    //       return comparisonValue['lookupfields'][idx].id;
    //     }
    //   }
    //   return null;
    // }
    else {
      return this._getInputTextForFormField(targetControl);
    }
    
  }

  private _getTargetControl(attrName: string): Control {
    let targetControl: Control
    for (let tabIdx = 0; tabIdx < this.formMetadata.metadata.length; tabIdx++) {
      const tab = this.formMetadata.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == attrName);
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    };
    return targetControl;
  }

  private _setBusinessRuleAttributeValue(value,fieldName){
    // let targetControl: Control = this._getTargetControl(fieldName);
    // let currentValue = this._getAttributeValue(fieldName);
    // if(currentValue != value){
    //   this._setAttributeValue(targetControl,value,true,'',false);
    // }
  }

  private _sanitizeGuid(guid) {
    var guidRegex = /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/;
    // Check if the provided 'guid' matches the GUID format
    if (guid.match(guidRegex)) {
      // If it's a valid GUID, return it in the canonical format (lowercase with dashes)
      return guid.toLowerCase();
    } else {
      // If it's not a valid GUID, return null or handle the error as needed
      return null;
    }
  }
  private _runBusinessRulesLogic(){
    const businessRules = this.formMetadata && this.formMetadata.businessRules ? this.formMetadata.businessRules : [];
    const functionMap: Array<Function> = [];

    // Create a map of function names to actual functions
    businessRules.forEach((funcString) => {
      try {
        if(funcString && funcString.logicFunction){
          funcString.logicFunction = funcString.logicFunction.replace('(eventContext)','(eventContext,Mscrm,Xrm)')
          const func = new Function('return ' + funcString.logicFunction)();
          if (func) {
            functionMap.push(func);
          }
        }
        
      } catch (error) {
        console.error(`Error parsing function: ${error}`);
      }
    });

    for (const func of functionMap) {
      try {
        const Mscrm = {
          BusinessRules: {
            Utility: {
              isNull: (o) => o == null || o === undefined,
              sanitizeGuid: (guid) =>  this._sanitizeGuid(guid),
            },
            ErrorHandlerFactory: {
              getHandler: function (e, f) {
                return {
                  handleError: () => {
                    throw e;
                  },
                };
              },
            },
          },
        };
        
        const Xrm = {
          Page: {
            data: {
              entity: undefined,
            },
          },
        };
        const eventContext = {
          getFormContext: () => this._getFormContext()
        }
        func(eventContext,Mscrm,Xrm);
      } catch (error) {
        console.error('Error executing function:', error);
      }
    }
    if(this._runInitViewDataAfterBusinessRuleLogic){
      this._initViewData();
      this._runInitViewDataAfterBusinessRuleLogic = false;
    }
    
  }

}

