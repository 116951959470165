import { Action } from '@ngrx/store';

export const DOWNLOAD_FILE = '[file] Download Presentation';
export const DOWNLOAD_FILE_SUCCESS = '[file] Download Presentation Success';
export const DOWNLOAD_FILE_FAILURE = '[file] Download Presentation Failure';

export const UNZIP_FILE = '[file] Unzip Presentation';
export const UNZIP_FILE_SUCCESS = '[file] Unzip Presentation Success';
export const UNZIP_FILE_FAILURE = '[file] Unzip Presentation Failure';

export const UPDATE_FILE_RECORD = '[file] Update Presentation record';

export const FILE_DOWNLOAD_ENQUEUE = '[file] Download Presentation enqueue';
export const FILE_DOWNLOAD_DEQUEUE = '[file] Download Presentation dequeue';

export class downloadFile implements Action {
    readonly type = DOWNLOAD_FILE;
    constructor(public payload: { presentationId: string }) {
    }
}

export class downloadFileSuccess implements Action {
    readonly type = DOWNLOAD_FILE_SUCCESS;
    constructor(public payload: { presentationId: string }) {
    }
}

export class downloadFileFailed implements Action {
    readonly type = DOWNLOAD_FILE_FAILURE;
    constructor(public payload: { presentationId: string }) {
    }
}

export class unZipFile implements Action {
    readonly type = UNZIP_FILE;
}

export class unZipFileSuccess implements Action {
    readonly type = UNZIP_FILE_SUCCESS;
    constructor(public payload: { presentationId: string }) {
    }
}

export class unZipFileFailed implements Action {
    readonly type = UNZIP_FILE_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class updateFileRecord implements Action {
    readonly type = UPDATE_FILE_RECORD;
    constructor(public payload: { presentationId: string }) {
    }
}

export class downloadFileEnqueue implements Action {
    readonly type = FILE_DOWNLOAD_ENQUEUE;
    constructor(public payload: { presentationId: string }) {
    }
}

export class downloadFileDequeue implements Action {
    readonly type = FILE_DOWNLOAD_DEQUEUE;
}