import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { format, getDate, isValid, isBefore, isEqual } from 'date-fns'
import { ActivityService } from '../../../services/activity/activity.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DeviceService } from '../../../services/device/device.service';
import { IonNav, PopoverController } from '@ionic/angular';
import { ActivityType, Activity } from '../../../classes/activity/activity.class';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountPlan } from '@omni/classes/account-management/account-plan.class';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { AccountPlanActivities } from '../account-plan-activities/account-plan-activities';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '@omni/classes/activity/follow-up-action.activity.class';
import { UIService } from '../../../services/ui/ui.service';
import _ from 'lodash';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { Guid } from 'typescript-guid';
import { act } from '@ngrx/effects';
import { EventsService } from '@omni/services/events/events.service';

@Component({
  selector: 'account-plan-timeline',
  templateUrl: 'account-plan-timeline.html',
  styleUrls: ['account-plan-timeline.scss']
})
export class AccountPlanTimeline implements OnDestroy {
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;
  @Input() selectedAccountPlan: AccountPlan;

  private actListLength = 0;
  private totalListLength = 0;
  private ngUnSubscibe$ = new Subject<boolean>();

  isOwner = false;
  deviceOffline = false;
  accountPlanTimelineFilter: string;
  getday = getDate;
  groupedActivities = [];
  groupedActivitiesToDisplay = [];
  filterOptions: Array<{ value: string, isSelected: boolean, displayText: string }> = [];
  isLoading = false;

  activitiyMeeting = [];
  activityPhoneCall = [];
  activityFollowup = [];

  constructor(
    private translate: TranslateService,
    private accountManagementDataService: AccountManagementDataService,
    public authenticationService: AuthenticationService,
    public contactService: ContactOfflineService,
    public activityService: ActivityService,
    public popoverCtrl: PopoverController,
    public navCtrl: IonNav,
    public device: DeviceService,
    public navigationService: NavigationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public utilityService: GlobalUtilityService,
    public uiService: UIService,
    private events: EventsService,
  ) { }

  ngOnInit() {
    this.accountPlanTimelineFilter = 'All_Activities';
    this.isOwner = this.selectedAccountPlan.ownerID === this.authenticationService.user.systemUserID;
    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      this.deviceOffline = isOffline;
      if (!this.deviceOffline && !this.isLoading) {
        this.fetchActivities();
      }
    });

    this.events.observe('meetingCompleted').pipe(takeUntil(this.ngUnSubscibe$)).subscribe((meeting: AppointmentActivity) => {
      if (this.selectedAccountPlan.ID === meeting.accountPlanId) {
        this.activitiyMeeting.push(meeting);
        this.initList();
      }
    });

    this.events.observe('phoneCallActivityCompleted').pipe(takeUntil(this.ngUnSubscibe$)).subscribe((newPhoneCall: PhoneActivity) => {
      if (this.selectedAccountPlan.ID === newPhoneCall.accountPlanId) {
        this.activityPhoneCall.push(newPhoneCall);
        this.initList();
      }
    });

    this.events.observe('followupCreated').pipe(takeUntil(this.ngUnSubscibe$)).subscribe((followUp: FollowUpActivity) => {
      if (this.selectedAccountPlan.ID === followUp.accountPlanId) {
        this.activityFollowup.push(followUp);
        this.initList();
      }
    });

    this.events.observe('phone-call-reopened').pipe(takeUntil(this.ngUnSubscibe$)).subscribe((phoneCall: PhoneActivity) => {
      let index = this.activityPhoneCall.findIndex(({ ID }) => phoneCall.ID === ID);
      if (index >= 0) this.activityPhoneCall.splice(index);
      this.initList();
    });

    this.events.observe('meetingReopened').pipe(takeUntil(this.ngUnSubscibe$)).subscribe((meeting:AppointmentActivity) => {
      let index = this.activitiyMeeting.findIndex(({ ID }) => meeting.ID === ID);
      if (index >= 0) this.activitiyMeeting.splice(index);
      this.initList();
    });
  }

  async fetchActivities() {
    this.uiService.displayLoader();
    this.isLoading = true;
    this.activityFollowup = this.accountManagementDataService.getFollowupActivity(this.selectedAccountPlan);
    const responses = await Promise.all([
      this.accountManagementDataService.fetchMeetingsTimeline(this.selectedAccountPlan.ID),
      this.accountManagementDataService.fetchPhoneCallsTimeline(this.selectedAccountPlan.ID)
    ]).catch(() => {
      this.uiService.dismissLoader();
      this.isLoading = false;
    });

    if (responses) {
      this.activitiyMeeting = responses[0];
      this.activityPhoneCall = responses[1];
      this.initList();
      this.uiService.dismissLoader();
      this.isLoading = false;
    } else {
      this.uiService.dismissLoader();
      this.isLoading = false;
    }
  }

  getFollowupActivity() {
    this.activityFollowup = this.activityService.activities.filter((a) => {
      return (a.type === ActivityType.FollowUp && (a as FollowUpActivity).accountPlanId === this.selectedAccountPlan.ID
        && (a.ownerId === this.authenticationService.user.systemUserID
          || (a as FollowUpActivity).assignedTo.some(o => o.userId === this.authenticationService.user.systemUserID)
          || this.selectedAccountPlan.ownerID === this.authenticationService.user.systemUserID)
        && ((a as FollowUpActivity).planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN)
        && (a.state === 0));
    }).sort((a, b) => {
      return (a.subject > b.subject) ? 1 : -1;
    }).sort((a, b) => {
      if (isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        if (isEqual(a.scheduledEnd, b.scheduledEnd)) return 1;
        else return (isBefore(a.scheduledEnd, b.scheduledEnd) ? 1 : -1);
      } else if (isValid(a.scheduledEnd) && !isValid(b.scheduledEnd)) {
        return 1;
      } else if (!isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }, 200);
  }

  initList() {
    this.groupedActivities = [];
    this.groupedActivitiesToDisplay = [];
    this.onTimelineTypeChange(this.accountPlanTimelineFilter);
    this.initFilterOptions();
  }

  private _calListLength(groupedActivities, activityIndex) {
    let listLength = 0;
    for (let idx = activityIndex; idx < groupedActivities.length; idx++) {
      if (groupedActivities[idx].list) listLength += groupedActivities[idx].list.length;
      if (listLength > 30) return idx + 1;
    }
    return groupedActivities.length;
  }

  private initFilterOptions(): void {
    this.filterOptions = [];

    if (this.activitiyMeeting.length > 0 || this.activityPhoneCall.length > 0 || this.activityFollowup.length > 0) {
      this.filterOptions.push({ value: 'All_Activities', isSelected: true, displayText: this.translate.instant('ALL_ACTIVITIES') });
    }

    if (this.activitiyMeeting.length > 0) {
      this.filterOptions.push({ value: ActivityType.Appointment, isSelected: false, displayText: this.translate.instant('MEETINGS') });
    }

    if (this.activityPhoneCall.length > 0) {
      this.filterOptions.push({ value: ActivityType.PhoneCall, isSelected: false, displayText: this.translate.instant('PHONE_CALLS') })
    }

    if (this.activityFollowup.length > 0) {
      this.filterOptions.push({ value: ActivityType.FollowUp, isSelected: false, displayText: this.translate.instant('FOLLOW_UP_ACTIONS') })
    }
  }

  onTimelineTypeChange(activityType) {
    switch (activityType) {
      case 'All_Activities': {
        let items: any = [];
        items = this.combineEventsAndActivities(items);
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(items);
        break;
      }
      case ActivityType.Appointment: {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.activitiyMeeting);
        break;
      }
      case ActivityType.FollowUp: {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.activityFollowup);
        break;
      }
      case ActivityType.PhoneCall: {
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(this.activityPhoneCall);
        break;
      }
      default: {
        let items: any = [];
        items = this.combineEventsAndActivities(items);
        this.groupedActivities = this.contactService.getGroupedActivitiesByMonth(items);
        break;
      }
    }
    this.groupedActivities.forEach((act) => {
      if (act.list) this.totalListLength += act.list.length;
    });
    this.actListLength = this._calListLength(this.groupedActivities, 0);
    this.groupedActivitiesToDisplay = this.totalListLength > 30 ? this.sliceActivities(0, this.actListLength) : this.groupedActivities;
    this.accountPlanTimelineFilter = activityType;  //bug cwd-3450
  }

  private combineEventsAndActivities(items: any) {
    if (this.activitiyMeeting.length > 0) items.push(...this.activitiyMeeting);
    if (this.activityPhoneCall.length > 0) items.push(...this.activityPhoneCall);
    if (this.activityFollowup.length > 0) items.push(...this.activityFollowup)
    return items;
  }

  isDataAvailable() {
    return (this.groupedActivities.length > 0 || this.accountPlanTimelineFilter != 'All_Activities');
  }

  getMeetingPrimaryLabel(activity: Activity) {
    const labelPostfix = activity.ownerId === this.authenticationService.user.systemUserID ? this.translate.instant('BY_ME_SMALL') : this.translate.instant('BY') + " " + activity.meetingOwnerName;
    return this.translate.instant('MET') + " " + labelPostfix;
  }

  getPhoneCallPrimaryLabel(activity: Activity) {
    const labelPostfix = activity.ownerId === this.authenticationService.user.systemUserID ?
      this.translate.instant('BY_ME_SMALL') :
      this.translate.instant('BY') + " " + activity.meetingOwnerName;
    return this.translate.instant('MET') + " " + labelPostfix;
  }

  getPhoneFollowUpPrimaryLabel(activity: FollowUpActivity) {
    if (activity.assignedTo.length === 0) return this.translate.instant('COACHING_FOR') + " " + this.translate.instant("ME");
    return this.translate.instant('COACHING_FOR') + " " + (activity.assignedTo.length === 1 ? activity.assignedTo[0].userFullName : activity.assignedTo.length > 1 ? activity.assignedTo[0].userFullName + ' +' + (activity.assignedTo.length - 1) : '');
  }

  getSelectedText(value) {
    const tot = this.groupedActivities.reduce((total, value) => (total + value.list.length), 0);
    switch (value) {
      case 'All_Activities':
        return this.translate.instant('ALL_ACTIVITIES') + ` ` + `(${tot})`;
      case ActivityType.Appointment:
        return this.translate.instant('MEETINGS') + ` ` + `(${tot})`;
      case ActivityType.PhoneCall:
        return this.translate.instant('PHONE_CALLS') + ` ` + `(${tot})`;
      case ActivityType.FollowUp:
        return this.translate.instant('FOLLOW_UP_ACTIONS') + ` ` + `(${tot})`;
    }
  }

  doInfinite(eventDetails, event) {
    let startIndex = this.actListLength;
    this.actListLength = this._calListLength(this.groupedActivities, this.actListLength);
    this.groupedActivitiesToDisplay.push(...this.sliceActivities(startIndex, this.actListLength));
    event.target.complete();
  }

  private sliceActivities(startIndex: number, count: number) {
    return this.groupedActivities.length < count ? this.groupedActivities.slice(startIndex) : this.groupedActivities.slice(startIndex, count)
  }

  formattedDate(val) {
    return format(val, this.dateTimeFormatsService.timeToUpper) || '';
  }

  getPhoneCallSubject(subject) {
    return (subject == 'Phone Call' || subject.includes(' - Phone Call')) ? subject.replace('Phone Call', this.translate.instant('NEW_ACTIVITY_PHONECALL')) : subject;
  }

  async openActivityPopover(event) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'account-plan-activities',
      showClearAllBtn: false,
      data: [{
        title: this.translate.instant('ACCOUNT_TIMELINE_OPTION_MEETINGS'),
        id: 'meeting',
        isSelected: false,
      }, {
        title: this.translate.instant('FOLLOW_UP_ACTIONS'),
        id: 'followup',
        isSelected: false,
      }],
    };

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      dropdownListDetail.data.splice(1, 0, {
        title: this.translate.instant('PHONE_CALLS'),
        id: 'phonecall',
        isSelected: false,
      })
    }

    const moreOptionsPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event,
    });

    moreOptionsPopover.onDidDismiss().then(({ data }: any) => {
      if (data && data.selectedItems) {
        switch (data.selectedItems[0].id) {
          case "meeting":
            this.addAppointments();
            break;
          case "followup":
            this.addTasks();
            break;
          case "phonecall":
            this.addPhoneCalls();
            break;
          default:
            console.log('something else');
            break;
        }
      }
    })

    moreOptionsPopover.present();
  }

  public async addAppointments() {
    await this.uiService.displayLoader();
    const appointments = await this.accountManagementDataService.fetchAppointments(this.selectedAccountPlan);
    const existingActivities = this.activitiyMeeting.filter((meeting) => meeting.ownerId === this.authenticationService.user.systemUserID);
    if (!_.isEmpty(appointments) && !_.isEmpty(existingActivities)) {
      appointments.forEach(app => {
        const index = existingActivities.findIndex(exActivity => exActivity.ID == app.ID);
        if (index >= 0) {
          //Map activity meeting id
          app['activityMeetingId'] = existingActivities[index]['activityMeetingId'];
        }
      })
    }
    this.navigationService.pushWithPageTracking(AccountPlanActivities, PageName.AccountPlanActivities,
      {
        activities: appointments,
        activityType: ActivityType.Appointment,
        existingActivities: existingActivities,
        callbackEvent: (data) => this.saveActivitiesForAccountPlan(data)
      }, PageName.AccountPlanActivities).then(() => {
        this.uiService.dismissLoader();
      });
  }

  public async addPhoneCalls() {
    await this.uiService.displayLoader();
    const phonecalls = await this.accountManagementDataService.fetchPhoneCalls(this.selectedAccountPlan);
    const existingActivities = this.activityPhoneCall.filter((phoneCall) => phoneCall.ownerId === this.authenticationService.user.systemUserID);
    if (!_.isEmpty(phonecalls) && !_.isEmpty(existingActivities)) {
      phonecalls.forEach(app => {
        const index = existingActivities.findIndex(exActivity => exActivity.ID == app.ID);
        if (index >= 0) {
          //Map activity phonecall id
          app['activityPhoneCallId'] = existingActivities[index]['activityPhoneCallId'];
        }
      })
    }
    this.navigationService.pushWithPageTracking(AccountPlanActivities, PageName.AccountPlanActivities,
      {
        activities: phonecalls,
        activityType: ActivityType.PhoneCall,
        existingActivities: existingActivities,
        callbackEvent: (data) => this.saveActivitiesForAccountPlan(data)
      }, PageName.AccountPlanActivities).then(() => {
        this.uiService.dismissLoader();
      })
  }

  public async addTasks() {
    const followUpTasksForPlan = this.activityService.activities.filter((a) => {
      return (a.type === ActivityType.FollowUp
        && a.ownerId === this.authenticationService.user.systemUserID
        && ((a as FollowUpActivity).planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN)
        && a.state === 0
        && this.selectedAccountPlan.accountId == (a as FollowUpActivity).accountId)
    });
    this.navigationService.pushWithPageTracking(AccountPlanActivities, PageName.AccountPlanActivities,
      {
        activities: followUpTasksForPlan,
        activityType: ActivityType.FollowUp,
        existingActivities: this.activityFollowup.filter((followup) => followup.ownerId === this.authenticationService.user.systemUserID),
        callbackEvent: (data) => this.saveActivitiesForAccountPlan(data)
      }, PageName.AccountPlanActivities);

  }

  private saveActivitiesForAccountPlan(data) {
    if (this.deviceOffline) return;
    switch (data.activityType) {
      case ActivityType.Appointment:
        this.mapAppointments(data.selectedActivities);
        break;
      case ActivityType.PhoneCall:
        this.mapPhoneCalls(data.selectedActivities);
        break;
      case ActivityType.FollowUp:
        this.mapFollowups(data.selectedActivities);
        break;
      default:
        console.error("Unhandled case");
    }
    this.initList();
  }

  private mapAppointments(selectedAppointments: AppointmentActivity[]) {
    const existingActivities = this.activitiyMeeting.filter((meeting) => meeting.ownerId === this.authenticationService.user.systemUserID);
    const payload = [];
    const activityIdsToRemove = [];
    if (_.isEmpty(selectedAppointments)) {
      //All selected activities removed
      payload.push(...existingActivities.map(activity => {
        activityIdsToRemove.push(activity.ID);
        return {
          activityMeetingId: activity['activityMeetingId'],
          delete: true,
          activityId: activity.ID
        }
      }));

    } else {
      //Removed activities
      payload.push(..._.differenceWith(existingActivities, selectedAppointments, _.isEqual).map(activity => {
        activityIdsToRemove.push(activity.ID);
        return {
          activityMeetingId: activity['activityMeetingId'],
          delete: true,
          activityId: activity.ID
        }
      }));
      //Added activities
      payload.push(..._.differenceWith(selectedAppointments, existingActivities, _.isEqual).map(activity => {
        activity['activityMeetingId'] = Guid.create().toString();
        this.activitiyMeeting.push(activity);
        return {
          activityMeetingId: activity['activityMeetingId'],
          activityId: activity.ID
        }
      }));
    }
    if (!_.isEmpty(activityIdsToRemove)) {
      this.activitiyMeeting = this.activitiyMeeting.filter(activity => !activityIdsToRemove.includes(activity.ID));
    }
    if (!_.isEmpty(payload)) {
      this.uiService.displayLoader();
      this.accountManagementDataService.saveMeetingsForAccountPlan(this.selectedAccountPlan.ID, payload).finally(() => {
        this.uiService.dismissLoader();
      })
    }
  }

  private mapPhoneCalls(selectedPhoneCalls: PhoneActivity[]) {
    const existingActivities = this.activityPhoneCall.filter((phone) => phone.ownerId === this.authenticationService.user.systemUserID);
    const payload = [];
    const activityIdsToRemove = [];
    if (_.isEmpty(selectedPhoneCalls)) {
      //All selected activities removed
      payload.push(...existingActivities.map(activity => {
        activityIdsToRemove.push(activity.ID);
        return {
          activityPhoneCallId: activity['activityPhoneCallId'],
          delete: true,
          activityId: activity.ID
        }
      }));

    } else {
      //Removed activities
      payload.push(..._.differenceWith(existingActivities, selectedPhoneCalls, _.isEqual).map(activity => {
        activityIdsToRemove.push(activity.ID);
        return {
          activityPhoneCallId: activity['activityPhoneCallId'],
          delete: true,
          activityId: activity.ID
        }
      }));
      //Added activities
      payload.push(..._.differenceWith(selectedPhoneCalls, existingActivities, _.isEqual).map(activity => {
        activity['activityPhoneCallId'] = Guid.create().toString();
        this.activityPhoneCall.push(activity);
        return {
          activityPhoneCallId: activity['activityPhoneCallId'],
          activityId: activity.ID
        }
      }));
    }
    if (!_.isEmpty(activityIdsToRemove)) {
      this.activityPhoneCall = this.activityPhoneCall.filter(activity => !activityIdsToRemove.includes(activity.ID));
    }
    if (!_.isEmpty(payload)) {
      this.uiService.displayLoader();
      this.accountManagementDataService.savePhoneCallsForAccountPlan(this.selectedAccountPlan.ID, payload).finally(() => {
        this.uiService.dismissLoader();
      })
    }
  }

  private mapFollowups(selectedFollowups: FollowUpActivity[]) {
    const existingActivities: FollowUpActivity[] = this.activityFollowup.filter((followup) => followup.ownerId === this.authenticationService.user.systemUserID);
    const payload: FollowUpActivity[] = [];
    const activityIdsToRemove = [];
    if (_.isEmpty(selectedFollowups)) {
      //All selected activities removed
      existingActivities.forEach(activity => {
        activityIdsToRemove.push(activity.ID);
        activity.accountPlanId = null;
        activity.accountPlanNameString = '';
        activity.pendingPushToDynamics = true;
        payload.push(activity);
      });

    } else {
      //Removed activities
      _.differenceWith(existingActivities, selectedFollowups, _.isEqual).forEach(activity => {
        activityIdsToRemove.push(activity.ID);
        activity.accountPlanId = null;
        activity.accountPlanNameString = '';
        activity.pendingPushToDynamics = true;
        payload.push(activity);
      });
      //Added activities
      _.differenceWith(selectedFollowups, existingActivities, _.isEqual).forEach(activity => {
        activity['activityPhoneCallId'] = Guid.create().toString();
        this.activityFollowup.push(activity);
        activity.accountPlanId = this.selectedAccountPlan.ID;
        activity.accountPlanNameString = this.selectedAccountPlan.accountPlanName;
        activity.pendingPushToDynamics = true;
        payload.push(activity);
      });
    }
    if (activityIdsToRemove) {
      this.activityFollowup = this.activityFollowup.filter(activity => !activityIdsToRemove.includes(activity.ID));
    }
    if (!_.isEmpty(payload)) {
      this.uiService.displayLoader();
      this.accountManagementDataService.saveFollowupForAccountPlan(payload).finally(() => {
        this.uiService.dismissLoader();
      })
    }
  }

  ngOnDestroy(): void {
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
    // this.events.unsubscribe('meetingCompleted');
    // this.events.unsubscribe('meetingReopened');
    // this.events.unsubscribe('phoneCallActivityCompleted');
    // this.events.unsubscribe('phone-call-activity-updated');
    // this.events.unsubscribe('followupCreated');
  }

}
