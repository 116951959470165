<ion-header>
  <ind-page-title
    [viewData]="eventNotesPageTitle"
    (onControlClick)="onPageTitleControlClick($event)"
  ></ind-page-title>
</ion-header>
<ion-content #scroller>
  <ind-form-field *ngFor="let viewData of formFieldData" [viewData]="viewData"></ind-form-field>
  <!-- <ind-form-field [viewData]='approvalStatusField'></ind-form-field> -->
  <ind-form-field *ngIf="isAttendeeFormatProposed" [viewData]='passField'></ind-form-field>
  <ind-form-field *ngIf="isAttendeeFormatProposed" [viewData]='justificationField'></ind-form-field>
  <ind-section-header
    [viewData]="notesHeader"
    (onControlClick)='onSectionHeaderControlClick($event)'
  ></ind-section-header>
  <ind-text-area
    id="notesInput"
    [placeHolder]="notesPlaceholder"
    [showAttachment]="isAttachmentAdded"
    [attachmentTitle]="attachmentTitle"
    [maxLength]="1000"
    (indChange)="notesChanged($event)"
    [value]="tempNoteText"
    (removeAttachment)="removeAttachment($event)"
    [rows]="4"
    [cols]="10"
    [debounce]="150"
    [disabled]="!isEventOwnerOrTeamMember || isEventCompleted || isOffline"
  ></ind-text-area>
  <div *ngIf="eventsToolService.pastEventAttachments.length > 0" class="sectionsList section-event-attachments">
    <ng-container *ngFor="let event of eventsToolService.pastEventAttachments">
      <ind-section-header [viewData]='{id:event.eventId, title: event.eventName}'></ind-section-header>
      <ion-item-group *ngFor="let attachment of event.attachments" (click)="selectPastEventAttachment(attachment, $event)">
        <ion-item>
          <ion-checkbox [checked]="attachment.checked == true">
          </ion-checkbox>
          <ion-label>{{attachment.filename}}</ion-label>
        </ion-item>
      </ion-item-group>
    </ng-container>
  </div>
  <input class="hide-input" #attachInput type="file" (change)="loadFileFromDevice($event)">
  <io-note
    *ngFor="let note of currentCustomerNotes"
    [isNoteAttachmentEnabled]="true"
    [inputNote]="note"
    [editable]="canEditNote(note)"
    [readOnlyNote]="false"
    [readOnlyAttachment]="false"
    [deletable]="canDeleteNote(note)"
    (saveNoteOut)="handleUpdateNoteBtnClick($event)"
    [disableDownloadInOffline]="true"
    [from]="pageName"
  ></io-note>
</ion-content>
