import { SystemUserDimensionDTO } from './../../../../../interfaces/edge-analytics/report.interface';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, skip, withLatestFrom } from 'rxjs/operators';
import { MeetingMeasureData, MeetingMeasureDataFilter } from '../../../../../interfaces/edge-analytics/meeting-report.interface';
import { CallPlanDimensionDTO, ChartFilterOption, KPIDataBlock, MeasureConfig, MeasuresChartData, MeetingFactsDTO, YearMonthListItem, YearMonthOption } from '../../../../../interfaces/edge-analytics/report.interface';
import { MeetingByProductChartFilterOptions, MeetingReportTilePerRow, MeetingReportTiles, MeetingByProductChartFilterTemplate, MeetingChartTitles, MeetingTrendChartExtraFilterOptions, MeetingReportYearMonthKeyProp } from '../../../../config/edge-analytics/meeting-report/meeting-report.config';
import { DefaultChartOptions } from '../../../../config/edge-analytics/report.config';
import { CommonDimensions, MeasureType } from '../../../../enums/edge-analytics/edge-analytics.enum';
import { MeetingChartId, MeetingDimensions, MeetingKPI } from '../../../../enums/edge-analytics/meeting/meeting-report.enum';
import { getWorkingDaysOfMonth, unsubscribeSubscriptionArray } from '../../../../utility/common.utility';
import { reducerForKPIs, reduceByProduct, initYearMonthOptions, getDefaultSelectedYearMonths, getDateDimensionMembers, getFilteredSubCube, updateHighChartOption, updateHighChartSeries, generateChart, setChartFilter, getXAxisCategories, applyConfigToKPIMap, generateReportGroupTiles, getYearMonthFromYYYYMM, getPercentageString } from '../report.functions';

/** ----------------------------------------------------------------------------------------
 *  Initialize base measure data
 */
export function meetingGenerateMeasureData( meetingCube: any,
                                            workingDaysCalculationData: MeetingFactsDTO[],
                                            meetingCallPlansData: CallPlanDimensionDTO[],
                                            systemUserDimensions: SystemUserDimensionDTO[],
                                            positionContactCount: number,
                                            userPrincipalName: string,
                                            lastUpdatedDate: Date,
                                            dataStartDate: string,
                                            config: MeasureConfig[],
                                            langCode: string): MeetingMeasureData {
  let meetingMeasureData: MeetingMeasureData;
  if (meetingCube && Array.isArray(workingDaysCalculationData) && Array.isArray(meetingCallPlansData)
                  && Array.isArray(systemUserDimensions) && userPrincipalName && lastUpdatedDate && Array.isArray(config)) {

    // Initial data setup & list card subtext initialization
    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(meetingCube, MeetingReportYearMonthKeyProp, langCode);
    const _dateFilterOptions$: BehaviorSubject<YearMonthOption[]> = new BehaviorSubject(_dateFilterOptions);

    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    const _selectedDateFilters$: BehaviorSubject<number[]> = new BehaviorSubject(_selectedDateFilters);

    const _dataCube$ = new BehaviorSubject(meetingCube);

    const _workingDaysCalculationData$ = new BehaviorSubject(workingDaysCalculationData);

    const _meetingCallPlansData$ = new BehaviorSubject(meetingCallPlansData);

    const _systemUserDimensions$ = new BehaviorSubject(systemUserDimensions);

    const _positionContactCount$ = new BehaviorSubject(positionContactCount);
    const positionContactCount$ = _positionContactCount$.asObservable();

    const _dateDimensionMember = getDateDimensionMembers(meetingCube, _selectedDateFilters, MeetingReportYearMonthKeyProp);
    const _dateDimensionMember$ = new BehaviorSubject(_dateDimensionMember);

    const _initFilter = _getInitialCubeFilter(meetingCube, systemUserDimensions, userPrincipalName, _dateDimensionMember, _selectedDateFilters);
    const _initialCubeFilter$ = new BehaviorSubject(_initFilter);

    const _initFilteredSubCube = getFilteredSubCube(meetingCube, _initFilter);
    const _filteredSubCube$ = new BehaviorSubject(_initFilteredSubCube);

    const _uniqMeetings = _getUniqMeetings(_initFilteredSubCube);
    const _uniqMeetings$ = new BehaviorSubject(_uniqMeetings);
    const uniqMeetings$ = _uniqMeetings$.asObservable();

    const _callPlanDimensionsForCPA$ = new BehaviorSubject(_getCallPlanDimensionsForCPA(meetingCube, _dateDimensionMember, meetingCallPlansData));

    const listCardSubtextValue = Array.isArray(_uniqMeetings) ? '' + _uniqMeetings.length : '0';
    const listCardSubtextValue$: BehaviorSubject<string> = new BehaviorSubject(listCardSubtextValue);

    // KPI data behavior subjects & observables
    const _totalInPersonMeetingCount$ = new BehaviorSubject(0);
    const _totalLiveMeetingCount$ = new BehaviorSubject(0);
    const _totalPhoneCallCount$ = new BehaviorSubject(0);
    const _totalDaysInSelectedMonths$ = new BehaviorSubject(0);
    const totalDaysInSelectedMonths$ = _totalDaysInSelectedMonths$.asObservable();
    const _delayInMeeting$ = new BehaviorSubject('0');
    const _contentDurationTotal$ = new BehaviorSubject('0');
    const _contentSharedPercent$ = new BehaviorSubject('0');
    const _custReach$ = new BehaviorSubject(0);
    const custReach$ = _custReach$.asObservable();
    const _custReachGoal$ = new BehaviorSubject(0);
    const custReachGoal$ = _custReachGoal$.asObservable();
    const _totalCustReach$ = new BehaviorSubject(0);
    const totalCustReach$ = _totalCustReach$.asObservable();
    const _noCallPlan$ = new BehaviorSubject(0);

    const avgMeetingsPerDay$ = combineLatest([uniqMeetings$, totalDaysInSelectedMonths$]).pipe(
      debounceTime(0),
      map(([uniqMeetings, totalDaysInSelectedMonths]) => {
        if (Array.isArray(uniqMeetings) && uniqMeetings.length > 0 && !isNaN(totalDaysInSelectedMonths) && totalDaysInSelectedMonths > 0) {
          //console.log('**** sub12');
          return (uniqMeetings.length / totalDaysInSelectedMonths).toFixed(2);
        } else {
          return '0';
        }
      })
    );

    const coveragePercent$ = custReachGoal$.pipe(
      withLatestFrom(custReach$),
      debounceTime(0),
      map(([custReachGoal, custReach]) => {
        const percent = _getCoveragePercent(custReach, custReachGoal) + '%';
        //console.log('**** sub13 ');
        return percent;
      })
    );

    const _cpaAB$ = new BehaviorSubject('0');
    const _cpaABC$ = new BehaviorSubject('0');

    const totalCoveragePercent$: Observable<string> = combineLatest(([totalCustReach$, positionContactCount$])).pipe(
      debounceTime(0),
      map(([custReach, contactCount]) => {
        if (custReach > 0 && contactCount > 0) {
          return getPercentageString(custReach, contactCount) + '%';
        } else {
          return '0%';
        }
      })
    );

    // KPI data observables map
    const kpiMap: Map<string, KPIDataBlock> = new Map();

    kpiMap.set(MeetingKPI.totalMeeting, {
      id: MeetingKPI.totalMeeting,
      data$: uniqMeetings$.pipe(map(m => Array.isArray(m) ? m.length : 0)),
      enabled: false
    });
    kpiMap.set(MeetingKPI.avgMeeting, {
      id: MeetingKPI.avgMeeting,
      data$: avgMeetingsPerDay$,
      enabled: false
    });
    kpiMap.set(MeetingKPI.delayMeeting, {
      id: MeetingKPI.delayMeeting,
      data$: _delayInMeeting$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.totalContentDuration, {
      id: MeetingKPI.totalContentDuration,
      data$: _contentDurationTotal$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.contentShared, {
      id: MeetingKPI.contentShared,
      data$: _contentSharedPercent$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.totalCoveragePercent, {
      id: MeetingKPI.totalCoveragePercent,
      data$: totalCoveragePercent$,
      enabled: false
    });
    kpiMap.set(MeetingKPI.coverage, {
      id: MeetingKPI.coverage,
      data$: coveragePercent$,
      enabled: false
    });
    kpiMap.set(MeetingKPI.totalWorkingDays, {
      id: MeetingKPI.totalWorkingDays,
      data$: _totalDaysInSelectedMonths$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.totalInPerson, {
      id: MeetingKPI.totalInPerson,
      data$: _totalInPersonMeetingCount$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.totalLiveTime, {
      id: MeetingKPI.totalLiveTime,
      data$: _totalLiveMeetingCount$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.totalPhoneCall, {
      id: MeetingKPI.totalPhoneCall,
      data$: _totalPhoneCallCount$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.cpaAB, {
      id: MeetingKPI.cpaAB,
      data$: _cpaAB$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.cpaABC, {
      id: MeetingKPI.cpaABC,
      data$: _cpaABC$.asObservable(),
      enabled: false
    });
    kpiMap.set(MeetingKPI.noCallPlanMeeting, {
      id: MeetingKPI.noCallPlanMeeting,
      data$: _noCallPlan$.asObservable(),
      enabled: false,
    });

    // For by product chart
    const _productDimensionMembers$ = new BehaviorSubject(_initFilteredSubCube?.getDimensionMembers(MeetingDimensions.products));
    const _totalMeetingsByProduct$ = new BehaviorSubject(null);

    // measure data instantiation
    meetingMeasureData = {
      dataStream: {
        // KPI data sources
        _dataCube$,
        dataCube$: _dataCube$.asObservable(),
        _dateDimensionMember$,
        dateDimensionMember$: _dateDimensionMember$.asObservable(),
        _initialCubeFilter$,
        initialCubeFilter$: _initialCubeFilter$.asObservable(),
        _filteredSubCube$,
        filteredSubCube$: _filteredSubCube$.asObservable(),
        _uniqMeetings$,
        uniqMeetings$,
        _workingDaysCalculationData$,
        workingDaysCalculationData$: _workingDaysCalculationData$.asObservable(),
        _meetingCallPlansData$,
        meetingCallPlansData$: _meetingCallPlansData$.asObservable(),
        _systemUserDimensions$,
        systemUserDimensions$: _systemUserDimensions$.asObservable(),
        _callPlanDimensionsForCPA$,
        callPlanDimensionsForCPA$: _callPlanDimensionsForCPA$.asObservable(),
        _positionContactCount$,
        positionContactCount$,

        // KPI data behavior subjects
        _totalInPersonMeetingCount$,
        _totalLiveMeetingCount$,
        _totalPhoneCallCount$,
        _totalDaysInSelectedMonths$,
        _delayInMeeting$,
        _contentDurationTotal$,
        _contentSharedPercent$,
        _custReach$,
        _custReachGoal$,
        _cpaAB$,
        _cpaABC$,
        _totalCustReach$,
        _noCallPlan$,

        // Chart related
        _productDimensionMembers$,
        productDimensionMembers$: _productDimensionMembers$.asObservable(),
        _totalMeetingsByProduct$,
        totalMeetingsByProduct$: _totalMeetingsByProduct$.asObservable(),
      },

      // KPI data
      kpiMap,

      // From Base
      dateFilterOptionType: 'YearMonth',
      dateFilterMultiSelect: true,
      _dateFilterOptions$,
      dateFilterOptions$: _dateFilterOptions$.asObservable(),
      _selectedDateFilters$,
      selectedDateFilters$: _selectedDateFilters$.asObservable(),
      listCardSubtextValue$,
      measureType: MeasureType.meeting,
      isFilterDirty: false,
      lastUpdatedDate,
      dataStartDate,
      subs: [],
      tileGroups: [],
      charts: [],
      sortOrder: 1,
    };

    // Apply configuration
    applyConfigToKPIMap(kpiMap, config);

    // Init chart data
    _initChartsData(meetingMeasureData);
  } else {
    console.warn('meetingGenerateMeasureData: Invalid / missing input: ',  meetingMeasureData,
                                                                                  meetingCube,
                                                                                  workingDaysCalculationData,
                                                                                  meetingCallPlansData,
                                                                                  systemUserDimensions,
                                                                                  userPrincipalName,
                                                                                  lastUpdatedDate);
  }
  return meetingMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Update measure data after delta sync
 */
export function meetingUpdateMeasureDataAfterSync(meetingMeasureData: MeetingMeasureData,
                                                  meetingCube: any,
                                                  workingDaysCalculationData: MeetingFactsDTO[],
                                                  meetingCallPlansData: CallPlanDimensionDTO[],
                                                  systemUserDimensions: SystemUserDimensionDTO[],
                                                  positionContactCount: number,
                                                  userPrincipalName: string,
                                                  lastUpdatedDate: Date,
                                                  dataStartDate: string,
                                                  chartFilterOptionData: ChartFilterOption[],
                                                  config: MeasureConfig[]): MeetingMeasureData {

  if (meetingMeasureData && meetingCube && Array.isArray(workingDaysCalculationData)
      && Array.isArray(meetingCallPlansData) && Array.isArray(systemUserDimensions)
      && userPrincipalName && lastUpdatedDate) {

    meetingMeasureData.dataStream._dataCube$.next(meetingCube);
    meetingMeasureData.dataStream._workingDaysCalculationData$.next(workingDaysCalculationData);
    meetingMeasureData.dataStream._meetingCallPlansData$.next(meetingCallPlansData);
    meetingMeasureData.dataStream._systemUserDimensions$.next(systemUserDimensions);
    meetingMeasureData.dataStream._positionContactCount$.next(positionContactCount);
    meetingMeasureData.lastUpdatedDate = lastUpdatedDate;
    meetingMeasureData.dataStartDate = dataStartDate;
    applyConfigToKPIMap(meetingMeasureData.kpiMap, config);

    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(meetingCube, MeetingReportYearMonthKeyProp, meetingMeasureData.langCode);
    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    const _dateDimensionMember = getDateDimensionMembers(meetingCube, _selectedDateFilters, MeetingReportYearMonthKeyProp);
    const _initFilter = _getInitialCubeFilter(meetingCube, systemUserDimensions, userPrincipalName, _dateDimensionMember, _selectedDateFilters);
    const _initFilteredSubCube = getFilteredSubCube(meetingCube, _initFilter);
    const _uniqMeetings = _getUniqMeetings(_initFilteredSubCube);

    meetingMeasureData.listCardSubtextValue$.next(Array.isArray(_uniqMeetings) ? '' + _uniqMeetings.length : '0');

    if (Array.isArray(meetingMeasureData.subs) && meetingMeasureData.subs.length === 0) {
      // Since there's no data stream subscription, do manual initialization with new data
      meetingMeasureData._dateFilterOptions$.next(_dateFilterOptions);
      
      meetingMeasureData._selectedDateFilters$.next(_selectedDateFilters);
      
      meetingMeasureData.dataStream._dateDimensionMember$.next(_dateDimensionMember);
      
      meetingMeasureData.dataStream._initialCubeFilter$.next(_initFilter);
      
      meetingMeasureData.dataStream._filteredSubCube$.next(_initFilteredSubCube);
      
      meetingMeasureData.dataStream._uniqMeetings$.next(_uniqMeetings);

      const _callPlanDimensionsForCPA = _getCallPlanDimensionsForCPA(meetingCube, _dateDimensionMember, meetingCallPlansData);
      meetingMeasureData.dataStream._callPlanDimensionsForCPA$.next(_callPlanDimensionsForCPA);

      // By product chart
      const _productDimensionMembers = _initFilteredSubCube?.getDimensionMembers(MeetingDimensions.products);
      meetingMeasureData.dataStream._productDimensionMembers$.next(_productDimensionMembers);
    } else if (Array.isArray(meetingMeasureData.subs) && meetingMeasureData.subs.length > 0) {
      // Already on detail page. Reset necessary stuff
      _generateReportGroupTiles(meetingMeasureData);
      _setChartFilters(meetingMeasureData, chartFilterOptionData, config);
    }
  } else {
    console.warn('meetingUpdateMeasureDataAfterSync: Invalid / missing input: ',  meetingMeasureData,
                                                                                  meetingCube,
                                                                                  workingDaysCalculationData,
                                                                                  meetingCallPlansData,
                                                                                  systemUserDimensions,
                                                                                  userPrincipalName,
                                                                                  lastUpdatedDate);
  }
  return meetingMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Subscribe to measure data
 */
export function meetingSubscribeToMeasureData(meetingMeasureData: MeetingMeasureData, userPrincipalName: string, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[], forceReSubscribe = false) {
  if (meetingMeasureData && userPrincipalName) {
    // Reset filters if necessary
    meetingResetFilters(meetingMeasureData, userPrincipalName, chartFilterOptionData, config);

    // Unsubscribe previous subscriptions if necessary
    if (forceReSubscribe && Array.isArray(meetingMeasureData.subs)) {
      unsubscribeSubscriptionArray(meetingMeasureData.subs);
      meetingMeasureData.subs = [];
    }

    // Subscribe
    if (meetingMeasureData.dataStream && meetingMeasureData.subs.length === 0) {
      meetingMeasureData.subs.push(meetingMeasureData.dataStream.dataCube$.subscribe(meetingCube => {
        if (meetingCube) {
          //console.log('**** sub1');
          const newYearMonthOptions = initYearMonthOptions(meetingCube, MeetingReportYearMonthKeyProp, meetingMeasureData.langCode);
          meetingMeasureData._dateFilterOptions$.next(newYearMonthOptions);
        }
      }));

      meetingMeasureData.subs.push(meetingMeasureData.dateFilterOptions$.subscribe(dateFilterOptions => {
        if (Array.isArray(dateFilterOptions)) {
          //console.log('**** sub2');
          const newSelectedDateFilters: number[] = getDefaultSelectedYearMonths(dateFilterOptions);
          meetingMeasureData._selectedDateFilters$.next(newSelectedDateFilters);
        }
      }));

      meetingMeasureData.subs.push(
        combineLatest([meetingMeasureData.dataStream.dataCube$, meetingMeasureData._selectedDateFilters$]).pipe(
          filter(([meetingCube, selectedDateFilters]) => meetingCube && Array.isArray(selectedDateFilters)),
          debounceTime(0),
          skip(1),
          map(([meetingCube, selectedDateFilters]) => {
            //console.log('**** sub3');
            const newDateDimensionMember = getDateDimensionMembers(meetingCube, selectedDateFilters, MeetingReportYearMonthKeyProp);
            meetingMeasureData.dataStream._dateDimensionMember$.next(newDateDimensionMember);
          })
        ).subscribe()
      );

      meetingMeasureData.subs.push(
        combineLatest([ 
          meetingMeasureData.dataStream.dataCube$,
          meetingMeasureData.dataStream.systemUserDimensions$,
          meetingMeasureData.dataStream.dateDimensionMember$,
        ]).pipe(
          filter(([dataCube, systemUserDimensions, dateDimensionMember]) => dataCube && Array.isArray(systemUserDimensions) && dateDimensionMember),
          debounceTime(0),
          skip(1),
          map(([dataCube, systemUserDimensions, dateDimensionMember]) => {
            //console.log('**** sub4');
            const newFilter = _getInitialCubeFilter(dataCube,
                                                    systemUserDimensions,
                                                    userPrincipalName,
                                                    dateDimensionMember);
            meetingMeasureData.dataStream._initialCubeFilter$.next(newFilter);
          })
        ).subscribe()
      );

      meetingMeasureData.subs.push(meetingMeasureData.dataStream.initialCubeFilter$.subscribe(filter => {
        const meetingCube = meetingMeasureData.dataStream._dataCube$.getValue();
        if (filter && meetingCube) {
          //console.log('**** sub5');
          const newFilteredMeetingSubCube = getFilteredSubCube(meetingCube, filter);
          meetingMeasureData.dataStream._filteredSubCube$.next(newFilteredMeetingSubCube);
        }
      }));

      meetingMeasureData.subs.push(meetingMeasureData.dataStream.filteredSubCube$.subscribe(filteredSubCube => {
        if (filteredSubCube) {
          //console.log('**** sub6');
          const newUniqMeetings = _getUniqMeetings(filteredSubCube);
          meetingMeasureData.dataStream._uniqMeetings$.next(newUniqMeetings);

          const newCustReach = _getCustReach(filteredSubCube);
          meetingMeasureData.dataStream._custReach$.next(newCustReach);

          const newTotalCustReach = _getTotalCustReach(filteredSubCube);
          meetingMeasureData.dataStream._totalCustReach$.next(newTotalCustReach);
        }
      }));

      meetingMeasureData.subs.push(meetingMeasureData.dataStream.uniqMeetings$.subscribe(uniqMeetings => {
        if (Array.isArray(uniqMeetings)) {
          //console.log('**** sub7');
          meetingMeasureData.dataStream._totalInPersonMeetingCount$.next(uniqMeetings.filter(o => o.inperson === 1).length);
          meetingMeasureData.dataStream._totalLiveMeetingCount$.next(uniqMeetings.filter(o => o.isremote === true).length);
          meetingMeasureData.dataStream._totalPhoneCallCount$.next(uniqMeetings.filter(o => o.isPhoneCall === 1).length);
          meetingMeasureData.dataStream._noCallPlan$.next(uniqMeetings.filter(o =>
            o.callplan_meetings !== 1
            && o.source_type === 'MTG'
          ).length);

          const newDelayInMeetings = _getDelayInMeeting(uniqMeetings);
          meetingMeasureData.dataStream._delayInMeeting$.next(newDelayInMeetings);

          const newContentDurationTotal = _getContentDurationTotal(uniqMeetings);
          meetingMeasureData.dataStream._contentDurationTotal$.next(newContentDurationTotal);

          const newContentSharedPercent = _getContentSharedPercent(uniqMeetings) + '%';
          meetingMeasureData.dataStream._contentSharedPercent$.next(newContentSharedPercent);
        }
      }));

      meetingMeasureData.subs.push(
        combineLatest([meetingMeasureData.dataStream.productDimensionMembers$, meetingMeasureData.dataStream.uniqMeetings$]).pipe(
          filter(([productDimensionMembers, uniqMeetings]) => Array.isArray(productDimensionMembers) && Array.isArray(uniqMeetings)),
          debounceTime(0),
          map(([productDimensionMembers, uniqMeetings]) => {
            const newTotalMeetingsByProduct = _getTotalMeetingsByProduct(productDimensionMembers, uniqMeetings, 'total', 'count');
            meetingMeasureData.dataStream._totalMeetingsByProduct$.next(newTotalMeetingsByProduct);
            //console.log('**** sub7.5', newTotalMeetingsByProduct);
          })
        ).subscribe()
      );

      meetingMeasureData.subs.push(
        combineLatest([meetingMeasureData.dataStream.meetingCallPlansData$, meetingMeasureData.dataStream.dateDimensionMember$]).pipe(
          filter(([meetingCallPlansData, dateDimensionMember]) => meetingCallPlansData && dateDimensionMember),
          debounceTime(0),
          map(([meetingCallPlansData, dateDimensionMember]) => {
            const meetingCube = meetingMeasureData.dataStream._dataCube$.getValue();
            if (meetingCube) {
              //console.log('**** sub9');
              const newCustReachGoal = _getCustReachGoal(meetingCube, dateDimensionMember, meetingCallPlansData);
              meetingMeasureData.dataStream._custReachGoal$.next(newCustReachGoal);

              const newCallPlanDimensionsForCPA = _getCallPlanDimensionsForCPA(meetingCube, dateDimensionMember, meetingCallPlansData);
              meetingMeasureData.dataStream._callPlanDimensionsForCPA$.next(newCallPlanDimensionsForCPA);
            }
          })
        ).subscribe()
      );

      meetingMeasureData.subs.push(
        combineLatest([meetingMeasureData.dataStream.dateDimensionMember$, meetingMeasureData.dataStream.workingDaysCalculationData$]).pipe(
          filter(([selectedDateDimensionMember, workingDaysCalculationData]) => Array.isArray(selectedDateDimensionMember) && Array.isArray(workingDaysCalculationData)),
          debounceTime(0),
          map(([selectedDateDimensionMember, workingDaysCalculationData]) => {
            //console.log('**** sub10');
            const filteredWorkingDaysCalculationData = _getDateFilteredWorkingDaysCalcuationData(workingDaysCalculationData, selectedDateDimensionMember);
            const { holidaysWork, timeOff, workDays } = _getHolidaysWorkAndTimeOff(filteredWorkingDaysCalculationData);
            const newTotalDaysInSelectedMonths = _getTotalDaysInSelectedMonths(selectedDateDimensionMember, holidaysWork, timeOff, workDays);
            meetingMeasureData.dataStream._totalDaysInSelectedMonths$.next(newTotalDaysInSelectedMonths);
          })
      ).subscribe());

      meetingMeasureData.subs.push(
        meetingMeasureData.dataStream.callPlanDimensionsForCPA$.pipe(skip(1))
        .subscribe(callPlanDimensionsForCPA => {
          if (callPlanDimensionsForCPA) {
            _getCpaAB(callPlanDimensionsForCPA)
            .then(cpaAB => {
              meetingMeasureData.dataStream._cpaAB$.next(cpaAB + '%');
            });

            _getCpaABC(callPlanDimensionsForCPA)
            .then(cpaABC => {
              meetingMeasureData.dataStream._cpaABC$.next(cpaABC + '%');
            });
          }
      }));

      // Generate tile groups
      _generateReportGroupTiles(meetingMeasureData);

      // Set chart filter options
      _setChartFilters(meetingMeasureData, chartFilterOptionData, config);

      // By product chart data subscriptions
      _byProductChartDataSubscription(meetingMeasureData);
      // Trend chart data subscriptions
      _trendChartDataSubscription(meetingMeasureData);
    }
  } else {
    console.warn('subscribeToMeetingMeasureData: Invalid input: ', meetingMeasureData, userPrincipalName);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Reset date & measure filters
 */
export function meetingResetFilters(meetingMeasureData: MeetingMeasureData, userPrincipalName: string, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (meetingMeasureData && userPrincipalName && meetingMeasureData.isFilterDirty) {
    const meetingCube = meetingMeasureData.dataStream._dataCube$.getValue();
    const _systemUserDimensions = meetingMeasureData.dataStream._systemUserDimensions$.getValue();

    const _dateFilterOptions = initYearMonthOptions(meetingCube, MeetingReportYearMonthKeyProp, meetingMeasureData.langCode);
    meetingMeasureData._dateFilterOptions$.next(_dateFilterOptions);

    const _selectedDateFilters = getDefaultSelectedYearMonths(_dateFilterOptions);
    meetingMeasureData._selectedDateFilters$.next(_selectedDateFilters);

    const _dateDimensionMember = getDateDimensionMembers(meetingCube, _selectedDateFilters, MeetingReportYearMonthKeyProp);
    meetingMeasureData.dataStream._dateDimensionMember$.next(_dateDimensionMember);

    const _initFilter = _getInitialCubeFilter(meetingCube, _systemUserDimensions, userPrincipalName, _dateDimensionMember, _selectedDateFilters);
    meetingMeasureData.dataStream._initialCubeFilter$.next(_initFilter);

    const _initFilteredSubCube = getFilteredSubCube(meetingCube, _initFilter);
    meetingMeasureData.dataStream._filteredSubCube$.next(_initFilteredSubCube);

    const _uniqMeetings = _getUniqMeetings(_initFilteredSubCube);
    meetingMeasureData.dataStream._uniqMeetings$.next(_uniqMeetings);

    const _productDimensionMembers = _initFilteredSubCube?.getDimensionMembers(MeetingDimensions.products) ;
    meetingMeasureData.dataStream._productDimensionMembers$.next(_productDimensionMembers);

    _setChartFilters(meetingMeasureData, chartFilterOptionData, config);
    meetingMeasureData.isFilterDirty = false;
  }
}


/** ----------------------------------------------------------------------------------------
 *  Measure data prep & calculation helper functions
 */
export function meetingFillInMissingDateFacts(missingYearMonthList: YearMonthListItem[], facts: MeetingFactsDTO[], systemUserId: number) {
  if (Array.isArray(missingYearMonthList) && Array.isArray(facts) && !isNaN(systemUserId)) {
    for (let i = 0; i < missingYearMonthList.length; i++) {
      const yearMonthListItem = missingYearMonthList[i];
      const emptyFact = _generateEmptyFactForMissingYearMonth(yearMonthListItem, systemUserId);
      if (emptyFact) {
        facts.push(emptyFact);
      }
    }
  } else {
    console.error('meetingFillInMissingDateFacts: ', missingYearMonthList, facts, systemUserId)
  }
}
function _generateEmptyFactForMissingYearMonth(yearMonthListItem: YearMonthListItem, systemUserId: number): MeetingFactsDTO {
  let emptyFact: MeetingFactsDTO;

  if (yearMonthListItem && systemUserId !== null) {
    emptyFact = {
      callplan_meetings: null,
      content_duration: null,
      content_shared: null,
      days: null,
      delay_days: null,
      delay_flag: null,
      is_fullrefresh: null,
      isremote: null,
      jointmeeting: null,
      multiproduct_flag: null,
      product_position: null,
      sk_activityid: null,
      sk_contactid: null,
      sk_productid: null,
      sk_systemuserid: systemUserId,
      source_type: null,
      statecode: null,
      total_slides: null,
      uniquerowid: null,
      updatedon: null,
      voip_flag: null,
      year_month: yearMonthListItem.yearMonth,
      ym_position_product_customer: null,
      id: systemUserId + '_' + yearMonthListItem.yearMonth + '_empty'
    }
  }

  return emptyFact;
}
function _getInitialCubeFilter( meetingCube: any,
                                systemUserDimensions: any[],
                                userPrincipalName: string,
                                dateDimensionMembers: any,
                                selectedDateFilters: number[] = []): MeetingMeasureDataFilter {
  let filter: MeetingMeasureDataFilter = null;
  if (meetingCube && Array.isArray(systemUserDimensions) && userPrincipalName) {
    const yearMonth = dateDimensionMembers ? dateDimensionMembers : getDateDimensionMembers(meetingCube, selectedDateFilters, MeetingReportYearMonthKeyProp);
    const statecode = meetingCube.getDimensionMembers(MeetingDimensions.statecode).filter(stcd => stcd.statecode === 1);
    const products = meetingCube.getDimensionMembers(MeetingDimensions.products);
    const userId = systemUserDimensions.find(u => u.internalemailaddress === userPrincipalName)?.sk_systemuserid;
    const users = meetingCube.getDimensionMembers(CommonDimensions.users).filter(user => user.sk_systemuserid === userId)

    if (Array.isArray(users) && users.length > 0) {
      filter = { yearMonth, statecode, products, users };
    }
  }
  return filter;
}
function _getUniqMeetings(filteredSubCube: any): any[] {
  // Filter the cells because we introduced empty cells by filling in missing dates...
  return filteredSubCube ? _.uniqBy(filteredSubCube.getCells().filter(({ uniquerowid }) => uniquerowid), 'sk_activityid') : [];
}
function _getTotalCustReach(filteredSubCube: any): number {
  return filteredSubCube ? (_.uniqBy(filteredSubCube.getCells(), 'sk_contactid').length) || 0 : 0;
}
function _getCustReach(filteredSubCube: any): number {
  return filteredSubCube ? (_.uniqBy(filteredSubCube.getCells().filter(o => o.callplan_meetings === 1), 'sk_contactid').length) || 0 : 0;
}
function _getCustReachGoal(meetingCube: any, dateDimensionMember: any, meetingCallPlansData: CallPlanDimensionDTO[]): number {
  let custReachGoal = 0;

  if (meetingCube && dateDimensionMember && Array.isArray(meetingCallPlansData)) {
    const subCubeForAllMeetings = meetingCube.dice({ yearMonth: dateDimensionMember });
    const custReachGoalApplicableData = [];
    subCubeForAllMeetings.getCells().forEach(meeting => {
      const cpm = meetingCallPlansData.find(cp => cp.ym_position_product_customer === meeting.ym_position_product_customer);
      if (cpm) {
        custReachGoalApplicableData.push(cpm);
      }
    });

    custReachGoal = (_.uniqBy(custReachGoalApplicableData, 'sk_contactid').length) || 0;
  }
  return custReachGoal;
}
function _getDateFilteredWorkingDaysCalcuationData(workingDaysCalculationData: MeetingFactsDTO[], selectedDateDimensionMembers: any[]) {
  let filtered = [];
  if (Array.isArray(workingDaysCalculationData) && Array.isArray(selectedDateDimensionMembers)) {
    for (let i = 0; i < workingDaysCalculationData.length; i++) {
      const data = workingDaysCalculationData[i];
      if (selectedDateDimensionMembers.some(d => d.year_month === data.year_month)) {
        filtered.push(data);
      }
    }
  }
  return filtered;
}
function _getHolidaysWorkAndTimeOff(filteredWorkingDaysCalculationData: MeetingFactsDTO[]): { holidaysWork: number, timeOff: number, workDays: any } {
  let data = { holidaysWork: 0, timeOff: 0, workDays: undefined };
  if (Array.isArray(filteredWorkingDaysCalculationData)) {
    data = {
      holidaysWork: filteredWorkingDaysCalculationData.filter(o => o.source_type === 'HLD_WORK').length,
      timeOff: _.reduce(filteredWorkingDaysCalculationData.filter(o => o.source_type === 'TOFF'), ((a, b)=>{
                return a + b.days;
              }), 0),
      workDays: filteredWorkingDaysCalculationData.filter(o => o.source_type === 'WORK')
    };
  }
  return data;
}
function _getTotalDaysInSelectedMonths(selectedDateDimensionMember: any[], holidaysWork: number, timeOff: number, workDays: MeetingFactsDTO[]): number {
  let days = 0;
  if (Array.isArray(workDays)) {
    for (let i = 0; i < workDays.length; i++) {
      const workDayFact = workDays[i];
      days += workDayFact.days;
    }
  } else if (Array.isArray(selectedDateDimensionMember)){
    for (let i = 0; i < selectedDateDimensionMember.length; i++) {
      const dateMember = selectedDateDimensionMember[i];
      const daysInMonth = getWorkingDaysOfMonth(dateMember.year_month);
      days += daysInMonth;
    } 
  }
  if (days > 0) {
    days = days - timeOff + holidaysWork;
  }
  return days;
}
function _getDelayInMeeting(uniqMeetings: any[]): string {
  let delayInMeeting = '0';
  if (Array.isArray(uniqMeetings) && uniqMeetings.length > 0) {
    delayInMeeting = ((reducerForKPIs('delay_days', uniqMeetings, 'summation')) / uniqMeetings.length).toFixed(2).replace('.00', '');
  }
  return delayInMeeting;
}
function _getContentDurationTotal(uniqMeetings: any[]): string {
  let contentDurationTotal = '0';
  if (Array.isArray(uniqMeetings)) {
    contentDurationTotal = (reducerForKPIs('content_duration', uniqMeetings, 'summation') / 60).toFixed(2).replace('.00', '');
  }
  return contentDurationTotal;
}
function _getContentSharedPercent(uniqMeetings: any[]): string {
  let contentSharedPercent = '0';
  if (Array.isArray(uniqMeetings) && uniqMeetings.length > 0) {
    const inNumber = reducerForKPIs('content_shared', uniqMeetings, 'summation') / uniqMeetings.length * 100;
    contentSharedPercent = inNumber.toFixed(2).replace('.00', '');
  }
  return contentSharedPercent;
}
function _getCoveragePercent(custReach: number, custReachGoal: number): string {
  return getPercentageString(custReach, custReachGoal);
}
function _getCallPlanDimensionsForCPA(meetingCube: any, selectedDateDimensionMembers: any[], meetingCallPlansData: CallPlanDimensionDTO[]) {
  let cpaCallPlanDimensions: CallPlanDimensionDTO[] = [];
  if (meetingCube && Array.isArray(selectedDateDimensionMembers) && Array.isArray(meetingCallPlansData)) {
    try {
      const dateFilteredCube = meetingCube.dice({ yearMonth: selectedDateDimensionMembers });
      const dateFilteredFacts = dateFilteredCube?.getCells();
      if (Array.isArray(dateFilteredFacts)) {
        const key = x => x.ym_position_product_customer;
        const indexedCallPlansData: Map<any, any> = new Map(meetingCallPlansData.map(x => [key(x), x]));
        for (const fact of dateFilteredFacts) {
          let k = key(fact);
          if (indexedCallPlansData.has(k)) {
            cpaCallPlanDimensions.push({ ...fact, ...indexedCallPlansData.get(k) });
          }
        }
        cpaCallPlanDimensions = _.uniqBy(cpaCallPlanDimensions, 'sk_contactid');
      }
    } catch (error) {
      console.error('_getCallPlanDimensionsForCPA: ', error);
    }
  }
  return cpaCallPlanDimensions;
}
async function _getCpaAB(callPlansDataForCPA: CallPlanDimensionDTO[]): Promise<string> {
  let cpaAB = '0';
  if (Array.isArray(callPlansDataForCPA)) {
    try {
      // SUM(CPA FLAG) = SUM(mth_cpa_flag) WHERE mth_max_segment IN ('A', 'B')
      // ** mth_cpa_flag only one per sk_customercallplanid
      const segmentFiltered = callPlansDataForCPA.filter(c => c.mth_max_segment === 'A' || c.mth_max_segment === 'B');
      const uniqCallPlans = _.uniqBy(segmentFiltered, 'sk_customercallplanid');
      const sumCpaFlag = reducerForKPIs('mth_cpa_flag', uniqCallPlans, 'summation');

      // COUNT(Unique call plan id) = COUNT(DISTINCT sk_customercallplanid) WHERE mth_max_segment IN ('A', 'B')
      const uniqCount = Array.isArray(uniqCallPlans) ? uniqCallPlans.length : 0;

      // CPA(A+B) = SUM(CPA FLAG) / COUNT(Unique call plan id)
      const cpaABNumber = uniqCount > 0 && sumCpaFlag > 0 ? (sumCpaFlag / uniqCount) * 100 : 0;
      cpaAB = !isNaN(cpaABNumber) ? cpaABNumber.toFixed(2).replace('.00', '') : '0';
    } catch (error) {
      console.error('_getCpaAB: ', error);
    }
  }
  return cpaAB;
}
async function _getCpaABC(callPlansDataForCPA: CallPlanDimensionDTO[]): Promise<string> {
  let cpaABC = '0';
  if (Array.isArray(callPlansDataForCPA)) {
    try {
      // SUM(CPA FLAG) = SUM(mth_cpa_flag)
      // ** mth_cpa_flag only one per sk_customercallplanid
      const uniqCallPlans = _.uniqBy(callPlansDataForCPA, 'sk_customercallplanid');
      const sumCpaFlag = reducerForKPIs('mth_cpa_flag', uniqCallPlans, 'summation');

      // COUNT(Unique call plan id) = COUNT(DISTINCT sk_customercallplanid)
      const uniqCount = Array.isArray(uniqCallPlans) ? uniqCallPlans.length : 0;

      // CPA(A+B+C) = SUM(CPA FLAG) / COUNT(Unique call plan id)
      const cpaABCNumber = uniqCount > 0 && sumCpaFlag > 0 ? (sumCpaFlag / uniqCount) * 100 : 0;
      cpaABC = !isNaN(cpaABCNumber) ? cpaABCNumber.toFixed(2).replace('.00', '') : '0';
    } catch (error) {
      console.error('_getCpaABC: ', error);
    }
  }
  return cpaABC;
}


/** ----------------------------------------------------------------------------------------
 *  Report tile data connect & generation
 */
function _generateReportGroupTiles( meetingMeasureData: MeetingMeasureData,
                                    meetingReportTiles = JSON.parse(JSON.stringify(MeetingReportTiles)),
                                    reportTilesPerRow = MeetingReportTilePerRow) {

  generateReportGroupTiles(meetingMeasureData, meetingReportTiles, reportTilesPerRow, 'meeting-tile-group');
}


/** ----------------------------------------------------------------------------------------
 *  Chart data initialization
 */
function _initChartsData(meetingMeasureData: MeetingMeasureData) {
  if (meetingMeasureData) {
    _initByProductChartData(meetingMeasureData);
    _initTrendChartData(meetingMeasureData);
  }
}
function _initByProductChartData(meetingMeasureData: MeetingMeasureData) {
  // Init By Product chart
  const _byProductChartFilterOptions$ = new BehaviorSubject(null);
  const _byProductChartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);
  const chartData: MeasuresChartData = {
    id: MeetingChartId.byProduct,
    title: 'By Product',
    titleKey: MeetingChartTitles[MeetingChartId.byProduct],
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: JSON.parse(JSON.stringify(MeetingByProductChartFilterTemplate)),
    _chartFilterOptions$: _byProductChartFilterOptions$,
    chartFilterOptions$: _byProductChartFilterOptions$.asObservable(),
    _chartUpdated$: _byProductChartUpdated$,
    chartUpdated$: _byProductChartUpdated$.asObservable(),
    sortOrder: 0
  };

  meetingMeasureData.charts.push(chartData);
}
function _initTrendChartData(meetingMeasureData: MeetingMeasureData) {
  // Init trend chart
  const _chartFilterOptions$ = new BehaviorSubject(null);
  const _xAxisCategories$ = new BehaviorSubject(null);
  const _chartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);
  // Trend chart type is line
  const trendChartOptions = JSON.parse(JSON.stringify(DefaultChartOptions));
  trendChartOptions.chart.type = 'line';

  const trendChartData: MeasuresChartData = {
    id: MeetingChartId.trend,
    title: 'Trend',
    titleKey: MeetingChartTitles[MeetingChartId.trend],
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: JSON.parse(JSON.stringify(MeetingByProductChartFilterTemplate)),
    _chartFilterOptions$,
    chartFilterOptions$: _chartFilterOptions$.asObservable(),
    _xAxisCategories$,
    xAxisCategories$: _xAxisCategories$.asObservable(),
    _chartUpdated$,
    chartUpdated$: _chartUpdated$.asObservable(),
    customChartOption: trendChartOptions,
    sortOrder: 1
  };

  meetingMeasureData.charts.push(trendChartData);
}


/** ----------------------------------------------------------------------------------------
 *  Chart helper functions
 */
export function meetingGenerateCharts(meetingMeasureData: MeetingMeasureData,
                                      isMobilePortrait: boolean,
                                      windowInnerWidth: number,
                                      windowInnerHeight?: number,) {
  if (meetingMeasureData && Array.isArray(meetingMeasureData.charts)) {
    for (let i = 0; i < meetingMeasureData.charts.length; i++) {
      const chartData = meetingMeasureData.charts[i];
      chartData.chart = generateChart( chartData._chartFilterOptions$.getValue(),
                                        chartData.title,
                                        isMobilePortrait,
                                        windowInnerWidth,
                                        windowInnerHeight,
                                        chartData._xAxisCategories$ && Array.isArray(chartData._xAxisCategories$.getValue())
                                          ? chartData._xAxisCategories$.getValue() : undefined,
                                        chartData.customChartOption ? chartData.customChartOption : undefined);

      if (chartData.chart && chartData.chart.ref$) {
        chartData.highChartSub = chartData.chart.ref$.subscribe(ref => {
          chartData.highChartRef = ref;
          chartData._highChartRefReady$.next(true);
        });
      }
    }
  }
}

function _setChartFilters(meetingMeasureData: MeetingMeasureData, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (meetingMeasureData && Array.isArray(meetingMeasureData.charts)) {
    for (let i = 0; i < meetingMeasureData.charts.length; i++) {
      const chartData = meetingMeasureData.charts[i];
      switch (chartData.id) {
        case MeetingChartId.byProduct:
          let filterOptionsByProduct = chartFilterOptionData.filter(d => d.chartId != 'trend');
          setChartFilter(chartData, filterOptionsByProduct, config);
          break;

        case MeetingChartId.trend: {
          setChartFilter(chartData, chartFilterOptionData, config);
          break;
        }
        default:
          break;
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  By product chart helper functions
 */
function _getTotalMeetingsByProduct(productDimensionMembers: any, uniqMeetings: any[], measure: string, operation: 'summation' | 'count') {
  return reduceByProduct(productDimensionMembers, uniqMeetings, measure, operation);
}
function _getTotalCoveragePercentByProduct(meetingMeasureData: MeetingMeasureData): { [key: string]: number } {
  const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
  const filteredSubCube = meetingMeasureData.dataStream._filteredSubCube$.getValue();
  const positionContactCount: number = meetingMeasureData.dataStream._positionContactCount$.getValue();

  const reduced = filteredSubCube.getCells().reduce((acc, cur) => {
    const productName = productDimensionMembers.find(prod => prod.id === cur.products_id)?.product;
    if (productName && acc[productName] !== undefined) {
      acc[productName].push(cur.sk_contactid);
    } else {
      acc[productName] = [cur.sk_contactid];
    }
    return acc;
  }, {});

  for (const key in reduced) {
    if (Object.prototype.hasOwnProperty.call(reduced, key)) {
      const element = reduced[key];
      const newEl = _.uniq(element);
      const count = newEl.length;
      reduced[key] = parseFloat(getPercentageString(count, positionContactCount));
    }
  }
  return reduced;
}
function _prepCommonDataForByProductCalculation(meetingMeasureData: MeetingMeasureData, chartFilterOptions: ChartFilterOption[]): { xAxis: string[] } {
  let xAxis: string[] = [];
  const isTotalMeetingsByProductNeeded = chartFilterOptions.some(o => o.isSelected
    && (
      o.value === MeetingKPI.avgMeeting ||
      o.value === MeetingKPI.totalMeeting ||
      o.value === MeetingKPI.delayMeeting ||
      o.value === MeetingKPI.contentShared
    ));
  if (isTotalMeetingsByProductNeeded) {
    const totalMeetingsByProduct = meetingMeasureData.dataStream._totalMeetingsByProduct$.getValue();
    xAxis = getXAxisCategories(totalMeetingsByProduct);
  }
  return { xAxis };
}
function _createXAxisValuesAndUpdateXAxisLabels(xAxisCategories: string[], reducedData: Record<string, number>): number[] {
  const xAxisData = _.fill(Array(xAxisCategories.length), 0);
  for (const product in reducedData) {
    if (Object.prototype.hasOwnProperty.call(reducedData, product)) {
      const data = reducedData[product];
      const xAxisIdx = xAxisCategories.findIndex(x => x === product);
      if (xAxisIdx >= 0) {
        xAxisData[xAxisIdx] = data;
      } else {
        xAxisCategories.push(product);
        xAxisData.push(data);
      }
    }
  }
  return xAxisData;
}
function _getNoCallPlanMeetingByProduct(productDimensionMembers: any[], uniqMeasures: any[]) {
  let groupedData = null;

  if (Array.isArray(productDimensionMembers) && Array.isArray(uniqMeasures)) {
    groupedData = {};

    uniqMeasures.reduce((a, b) => {
      const productName = productDimensionMembers.find(prod => prod.id === b.products_id).product;
      if (groupedData[productName] !== undefined) {
        groupedData[productName] += (
          b.callplan_meetings !== 1
          && b.source_type === 'MTG'
        )
        ? 1 : 0;
      } else {
        groupedData[productName] = (
          b.callplan_meetings !== 1
          && b.source_type === 'MTG'
        )
        ? 1 : 0;
      }
    }, groupedData);
  }
  return groupedData;
}
function _updateSelectedChartFilterOptionsData( meetingMeasuresFilterOptions: ChartFilterOption[],
                                                meetingMeasureData: MeetingMeasureData,
                                                refresh = false): string[] {
  if (Array.isArray(meetingMeasuresFilterOptions) && meetingMeasureData) {
    let { xAxis } = _prepCommonDataForByProductCalculation(meetingMeasureData, meetingMeasuresFilterOptions);
    const totalMeetingsByProduct = meetingMeasureData.dataStream._totalMeetingsByProduct$.getValue();

    for (let i = 0; i < meetingMeasuresFilterOptions.length; i++) {
      const filterOption = meetingMeasuresFilterOptions[i];
      const kpi = meetingMeasureData.kpiMap.get(filterOption.value);
      if (filterOption.isSelected && (filterOption.data === undefined || refresh) && kpi && kpi.enabled) {
        switch (filterOption.value) {
          case MeetingKPI.avgMeeting: {
            const totalDaysInSelectedMonths = meetingMeasureData.dataStream._totalDaysInSelectedMonths$.getValue();
            if (totalMeetingsByProduct && totalDaysInSelectedMonths > 0) {
              const processedData = _.mapValues(totalMeetingsByProduct, (val: any) => {
                return parseFloat((val / totalDaysInSelectedMonths).toFixed(2));
              });
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.delayMeeting: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (totalMeetingsByProduct && productDimensionMembers && uniqMeetings) {
              const processedData = _.mapValues(reduceByProduct(productDimensionMembers, uniqMeetings, 'delay_days', 'summation'), (val: any, index) => {
                return parseFloat((val / totalMeetingsByProduct[index]).toFixed(2));
              });
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.contentShared: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (totalMeetingsByProduct && productDimensionMembers && uniqMeetings) {
              const processedData = _.mapValues(reduceByProduct(productDimensionMembers, uniqMeetings, 'content_shared', 'summation'), (val: any, index) => {
                return parseFloat((val / totalMeetingsByProduct[index] * 100).toFixed(2));
              })
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.totalContentDuration: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (productDimensionMembers && uniqMeetings) {
              const processedData = _.mapValues(reduceByProduct(productDimensionMembers, uniqMeetings, 'content_duration', 'summation'), (val: any) => {
                return parseFloat((val / 60).toFixed(2));
              });
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.totalMeeting:
            if (totalMeetingsByProduct) {
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, totalMeetingsByProduct);
              filterOption.data = xAxisAlignedData;
            }
            break;
          case MeetingKPI.totalInPerson: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (productDimensionMembers && uniqMeetings) {
              const processedData = _.mapValues(reduceByProduct(productDimensionMembers, uniqMeetings, 'inperson', 'summation'), (val: any) => {
                return parseFloat(val);
              });
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.totalLiveTime: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (productDimensionMembers && uniqMeetings) {
              const processedData = _.mapValues(reduceByProduct(productDimensionMembers, uniqMeetings, 'livetime', 'summation'), (val: any) => {
                return parseFloat(val);
              });
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.totalPhoneCall: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (productDimensionMembers && uniqMeetings) {
              const processedData = _.mapValues(reduceByProduct(productDimensionMembers, uniqMeetings, 'isPhoneCall', 'summation'), (val: any) => {
                return parseFloat(val);
              });
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.noCallPlanMeeting: {
            const productDimensionMembers = meetingMeasureData.dataStream._productDimensionMembers$.getValue();
            const uniqMeetings = meetingMeasureData.dataStream._uniqMeetings$.getValue();
            if (productDimensionMembers && uniqMeetings) {
              const processedData = _getNoCallPlanMeetingByProduct(productDimensionMembers, uniqMeetings);
              const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
              filterOption.data = xAxisAlignedData;
            }
            break;
          }
          case MeetingKPI.totalCoveragePercent: {
            const processedData = _getTotalCoveragePercentByProduct(meetingMeasureData);
            const xAxisAlignedData = _createXAxisValuesAndUpdateXAxisLabels(xAxis, processedData);
            filterOption.data = xAxisAlignedData;
            break;
          }
          default:
            console.warn('_updateSelectedChartFilterOptionsData: unhandled switch case: ', filterOption);
            break;
        }
      }
    }
    return xAxis;
  } else {
    console.warn('_updateSelectedChartFilterOptionsData: Invalid input', meetingMeasuresFilterOptions, meetingMeasureData);
    return null;
  }
}


/** ----------------------------------------------------------------------------------------
 *  By product chart data subscription
 */
function _byProductChartDataSubscription(meetingMeasureData: MeetingMeasureData) {
  const byProductChartData = meetingMeasureData.charts.find(c => c.id === MeetingChartId.byProduct);
  if (byProductChartData) {
    // meetingMeasureData.subs.push(
    //   combineLatest([byProductChartData.highChartRefReady$, meetingMeasureData.dataStream.totalMeetingsByProduct$]).pipe(
    //     filter(([highChartRefReady, totalMeetingsByProduct]) => highChartRefReady && totalMeetingsByProduct),
    //     map(([highChartRefReady, totalMeetingsByProduct]) => {
    //       const newXAxisCategories = getXAxisCategories(totalMeetingsByProduct);
    //       byProductChartData._xAxisCategories$.next(newXAxisCategories);
    //     })
    //   ).subscribe()
    // );
    meetingMeasureData.subs.push(
      combineLatest([ byProductChartData.chartFilterOptions$,
                      byProductChartData.highChartRefReady$,
                      meetingMeasureData.dataStream.totalMeetingsByProduct$,
                      meetingMeasureData.dataStream._totalDaysInSelectedMonths$.asObservable()]).pipe(
        filter(([ chartFilterOptions,
                  highChartRefReady,
                  totalMeetingsByProduct,
                  totalDaysInSelectedMonths]) => chartFilterOptions && highChartRefReady && totalMeetingsByProduct && totalDaysInSelectedMonths > 0),
        debounceTime(300),
        map(([chartFilterOptions, xAxisCategories, totalMeetingsByProduct, totalDaysInSelectedMonths]) => {
          //console.log('**** sub11', chartFilterOptions);
          const newXAxisCategories = _updateSelectedChartFilterOptionsData(chartFilterOptions, meetingMeasureData, true);

          if (Array.isArray(newXAxisCategories)) {
            const xAxis = JSON.parse(JSON.stringify(DefaultChartOptions.xAxis));
            xAxis.categories = newXAxisCategories;
            updateHighChartOption(byProductChartData.highChartRef, { xAxis }, false, true);
          } else {
            console.warn('_byProductChartDataSubscription: Invalid new x axis categories', newXAxisCategories, meetingMeasureData, chartFilterOptions);
          }

          if (byProductChartData.highChartRef) {
            updateHighChartSeries(byProductChartData.highChartRef, chartFilterOptions);
            byProductChartData._chartUpdated$.next(byProductChartData.id);
          }
        })
      ).subscribe()
    );
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart helper functions
 */
function _assignTotalMeetingsByMonthData(filterOption: ChartFilterOption, uniqMeetingsCountByMonth: number[]) {
  if (filterOption) {
    filterOption.data = uniqMeetingsCountByMonth ? uniqMeetingsCountByMonth : [];
  }
}
function _assignTotalInPersonMeetingsByMonthData(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption && Array.isArray(uniqMeetingsByMonth)) {
    const totalInPersonByMonth = [];
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const inPersonCount = uniqMeetings.filter(o => o.inperson).length;
      totalInPersonByMonth.push(!isNaN(inPersonCount) ? inPersonCount : 0);
    }
    filterOption.data = totalInPersonByMonth;
  }
}
function _assignTotalRemoteMeetingsByMonthData(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption && Array.isArray(uniqMeetingsByMonth)) {
    const totalRemoteByMonth = [];
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const remoteCount = uniqMeetings.filter(o => o.isremote).length;
      totalRemoteByMonth.push(!isNaN(remoteCount) ? remoteCount : 0);
    }
    filterOption.data = totalRemoteByMonth;
  }
}
function _assignTotalPhoneCallsByMonthData(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption && Array.isArray(uniqMeetingsByMonth)) {
    const totalPhoneCallsByMonth = [];
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const phoneCallsCount = uniqMeetings.filter(o => o.isPhoneCall).length;
      totalPhoneCallsByMonth.push(!isNaN(phoneCallsCount) ? phoneCallsCount : 0);
    }
    filterOption.data = totalPhoneCallsByMonth;
  }
}
function _assignUniqCustMetWithCovisitor(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption && Array.isArray(uniqMeetingsByMonth)) {
    const totalCountByMonth = [];
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const uniqCustMetWithCovisitor = uniqMeetings.filter(o =>
        o.callplan_meetings !== 1
        && o.source_type === 'MTG'
      ).length;
      totalCountByMonth.push(!isNaN(uniqCustMetWithCovisitor) ? uniqCustMetWithCovisitor : 0);
    }
    filterOption.data = totalCountByMonth;
  }
}
function _assignDelayInMeetingByMonthData(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption) {
    const delayInMeetingByMonth = [];
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const delayInMeeting = parseFloat(_getDelayInMeeting(uniqMeetings));
      delayInMeetingByMonth.push(!isNaN(delayInMeeting) ? delayInMeeting : 0);
    }
    filterOption.data = delayInMeetingByMonth;
  }
}
function _assignContentDurationTotalByMonthData(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption) {
    const contentDurationTotalByMonth = []
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const contentDurationTotal = parseFloat(_getContentDurationTotal(uniqMeetings));
      contentDurationTotalByMonth.push(!isNaN(contentDurationTotal) ? contentDurationTotal : 0);
    }
    filterOption.data = contentDurationTotalByMonth;
  }
}
function _assignContentSharedPercentByMonthData(filterOption: ChartFilterOption, uniqMeetingsByMonth: any[]) {
  if (filterOption) {
    const contentSharedPercentByMonth = [];
    for (let i = 0; i < uniqMeetingsByMonth.length; i++) {
      const uniqMeetings = uniqMeetingsByMonth[i];
      const contentSharedPercent = parseFloat(_getContentSharedPercent(uniqMeetings));
      contentSharedPercentByMonth.push(!isNaN(contentSharedPercent) ? contentSharedPercent : 0);
    }
    filterOption.data = contentSharedPercentByMonth;
  }
}
function _assignAvgMeetingsPerDayByMonthData(filterOption: ChartFilterOption, meetingMeasureData: MeetingMeasureData, uniqMeetingsCountByMonth: number[]) {
  if (filterOption && Array.isArray(uniqMeetingsCountByMonth) && meetingMeasureData) {
    const dateMembers = meetingMeasureData.dataStream._dateDimensionMember$.getValue();
    const workingDaysCalculationData = meetingMeasureData.dataStream._workingDaysCalculationData$.getValue();
    const avgMeetingsPerDayByMonth = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const filteredWorkingDaysCalculationData = _getDateFilteredWorkingDaysCalcuationData(workingDaysCalculationData, [dateMember]);
      const { holidaysWork, timeOff, workDays } = _getHolidaysWorkAndTimeOff(filteredWorkingDaysCalculationData);
      const totalDaysInSelectedMonths = _getTotalDaysInSelectedMonths([dateMember], holidaysWork, timeOff, workDays);
      const avgMeetingsPerDay = parseFloat((uniqMeetingsCountByMonth[i] / totalDaysInSelectedMonths).toFixed(2));
      avgMeetingsPerDayByMonth.push(!isNaN(avgMeetingsPerDay) ? avgMeetingsPerDay : 0);
    }
    filterOption.data = avgMeetingsPerDayByMonth;
  }
}
function _assignCoveragePercentByMonthData(filterOption: ChartFilterOption, meetingMeasureData: MeetingMeasureData) {
  const dateMembers = meetingMeasureData.dataStream._dateDimensionMember$.getValue();
  const meetingCube = meetingMeasureData.dataStream._dataCube$.getValue();
  const filteredSubCube = meetingMeasureData.dataStream._filteredSubCube$.getValue();
  const meetingCallPlansData: CallPlanDimensionDTO[] = meetingMeasureData.dataStream._meetingCallPlansData$.getValue();
  const coveragePercentByMonth = [];
  if (filterOption && Array.isArray(dateMembers) && meetingCube && filteredSubCube && Array.isArray(meetingCallPlansData)) {
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      // Get customer reach
      const sliced = filteredSubCube.slice(CommonDimensions.yearMonth, dateMember);
      const custReach = _getCustReach(sliced);
      let coveragePercent = 100;
      if (custReach > 0) {
        // Get customer reach goal
        const custReachGoal = _getCustReachGoal(meetingCube, dateMember, meetingCallPlansData);
        if (custReachGoal > 0) {
          coveragePercent = parseFloat(_getCoveragePercent(custReach, custReachGoal));
        }
      }
      coveragePercentByMonth.push(coveragePercent);
    }
    filterOption.data = coveragePercentByMonth;
  }
}
async function _assignCpaAbByMonthData(filterOption: ChartFilterOption, callPlansDataForCpaByMonth: CallPlanDimensionDTO[][]) {
  if (filterOption && Array.isArray(callPlansDataForCpaByMonth)) {
    const cpaAbByMonth = [];

    for (let i = 0; i < callPlansDataForCpaByMonth.length; i++) {
      const callPlansDataForCPA = callPlansDataForCpaByMonth[i];
      const cpaAB = parseFloat(await _getCpaAB(callPlansDataForCPA));
      cpaAbByMonth.push(cpaAB);
    }
    filterOption.data = cpaAbByMonth;
  }
}
async function _assignCpaAbcByMonthData(filterOption: ChartFilterOption, callPlansDataForCpaByMonth: CallPlanDimensionDTO[][]) {
  if (filterOption && Array.isArray(callPlansDataForCpaByMonth)) {
    const cpaAbcByMonth = [];

    for (let i = 0; i < callPlansDataForCpaByMonth.length; i++) {
      const callPlansDataForCPA = callPlansDataForCpaByMonth[i];
      const cpaABC = parseFloat(await _getCpaABC(callPlansDataForCPA));
      cpaAbcByMonth.push(cpaABC);
    }
    filterOption.data = cpaAbcByMonth;
  }
}
async function _assignTotalCoveragePercentByMonthData(filterOption: ChartFilterOption, meetingMeasureData: MeetingMeasureData) {
  const dateMembers = meetingMeasureData.dataStream._dateDimensionMember$.getValue();
  const meetingCube = meetingMeasureData.dataStream._dataCube$.getValue();
  const filteredSubCube = meetingMeasureData.dataStream._filteredSubCube$.getValue();
  const positionContactCount: number = meetingMeasureData.dataStream._positionContactCount$.getValue();
  const totalCoveragePercentByMonth = [];

  if (filterOption && Array.isArray(dateMembers) && meetingCube && filteredSubCube) {
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      // Get customer reach
      const sliced = filteredSubCube.slice(CommonDimensions.yearMonth, dateMember);
      const custReach = _getTotalCustReach(sliced);
      const coveragePercent = parseFloat(_getCoveragePercent(custReach, positionContactCount));
      totalCoveragePercentByMonth.push(coveragePercent);
    }
    filterOption.data = totalCoveragePercentByMonth;
  }
}
function _prepCommonDataForByMonthCalculation(meetingMeasureData: MeetingMeasureData, chartFilterOptions: ChartFilterOption[]) {
  const dateMembers = meetingMeasureData.dataStream._dateDimensionMember$.getValue();
  const filteredSubCube = meetingMeasureData.dataStream._filteredSubCube$.getValue();
  const meetingCube = meetingMeasureData.dataStream._dataCube$.getValue();
  const meetingCallPlansData = meetingMeasureData.dataStream._meetingCallPlansData$.getValue();
  const uniqMeetingsByMonth: any[] = [];
  const uniqMeetingsCountByMonth: number[] = [];
  const callPlanDimensionsForCpaByMonth: any[] = [];

  const isUniqMeetingsNeeded = chartFilterOptions.some(o => o.isSelected
                                                            && (o.value === MeetingKPI.totalMeeting ||
                                                                o.value === MeetingKPI.delayMeeting ||
                                                                o.value === MeetingKPI.totalContentDuration ||
                                                                o.value === MeetingKPI.contentShared ||
                                                                o.value === MeetingKPI.avgMeeting ||
                                                                o.value === MeetingKPI.totalInPerson ||
                                                                o.value === MeetingKPI.totalLiveTime ||
                                                                o.value === MeetingKPI.noCallPlanMeeting ||
                                                                o.value === MeetingKPI.totalPhoneCall));
  const isCpaNeeded = chartFilterOptions.some(o => o.isSelected && (o.value === MeetingKPI.cpaAB || o.value === MeetingKPI.cpaABC));

  // Uniq meetings
  if (isUniqMeetingsNeeded && Array.isArray(dateMembers) && filteredSubCube) {
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = filteredSubCube.slice(CommonDimensions.yearMonth, dateMember);
      const uniqMeetings = _getUniqMeetings(sliced);
      const count = Array.isArray(uniqMeetings) ? uniqMeetings.length : 0;
      uniqMeetingsByMonth.push(uniqMeetings);
      uniqMeetingsCountByMonth.push(count);
    }
  }

  if (isCpaNeeded && meetingCube && Array.isArray(dateMembers) && Array.isArray(meetingCallPlansData)) {
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const callPlanDimensionsForCPA = _getCallPlanDimensionsForCPA(meetingCube, [dateMember], meetingCallPlansData);
      callPlanDimensionsForCpaByMonth.push(callPlanDimensionsForCPA);
    }
  }

  return { uniqMeetingsByMonth, uniqMeetingsCountByMonth, callPlanDimensionsForCpaByMonth };
}
async function _updateSelectedTrendChartFilterOptionsData(chartFilterOptions: ChartFilterOption[], meetingMeasureData: MeetingMeasureData, refresh = false) {
  if (Array.isArray(chartFilterOptions) && meetingMeasureData) {
    const commonData = _prepCommonDataForByMonthCalculation(meetingMeasureData, chartFilterOptions);

    for (let i = 0; i < chartFilterOptions.length; i++) {
      const filterOption = chartFilterOptions[i];

      if (filterOption.isSelected && (filterOption.data === undefined  || refresh)) {
        try {
          switch (filterOption.value) {
            case MeetingKPI.totalMeeting:
              _assignTotalMeetingsByMonthData(filterOption, commonData ? commonData.uniqMeetingsCountByMonth : []);
              break;
            case MeetingKPI.totalInPerson:
              _assignTotalInPersonMeetingsByMonthData(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.totalLiveTime:
              _assignTotalRemoteMeetingsByMonthData(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.totalPhoneCall:
              _assignTotalPhoneCallsByMonthData(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.noCallPlanMeeting:
              _assignUniqCustMetWithCovisitor(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.delayMeeting:
              _assignDelayInMeetingByMonthData(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.totalContentDuration:
              _assignContentDurationTotalByMonthData(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.contentShared:
              _assignContentSharedPercentByMonthData(filterOption, commonData ? commonData.uniqMeetingsByMonth : []);
              break;
            case MeetingKPI.avgMeeting:
              _assignAvgMeetingsPerDayByMonthData(filterOption, meetingMeasureData, commonData ? commonData.uniqMeetingsCountByMonth : []);
              break;
            case MeetingKPI.coverage:
              _assignCoveragePercentByMonthData(filterOption, meetingMeasureData);
              break;
            case MeetingKPI.cpaAB:
              await _assignCpaAbByMonthData(filterOption, commonData ? commonData.callPlanDimensionsForCpaByMonth : []);
              break;
            case MeetingKPI.cpaABC:
              await _assignCpaAbcByMonthData(filterOption, commonData ? commonData.callPlanDimensionsForCpaByMonth : []);
              break;
            case MeetingKPI.totalCoveragePercent:
              await _assignTotalCoveragePercentByMonthData(filterOption, meetingMeasureData);
              break;

            default:
              break;
          }
        } catch (error) {
          console.error('meeting-report.functions: _updateSelectedTrendChartFilterOptionsData: ', error);
        }
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart data subscription
 */
function _trendChartDataSubscription(meetingMeasureData: MeetingMeasureData) {
  const trendChartData = meetingMeasureData.charts.find(c => c.id === MeetingChartId.trend);
  if (trendChartData) {
    meetingMeasureData.subs.push(
      combineLatest([trendChartData.highChartRefReady$, meetingMeasureData.dataStream.dateDimensionMember$]).pipe(
        filter(([highChartRefReady, dateDimensionMember]) => highChartRefReady && Array.isArray(dateDimensionMember)),
        map(([highChartRefReady, selectedDateDimensionMembers]) => {
          selectedDateDimensionMembers.sort((a, b) => a.year_month - b.year_month);
          const newXAxisCategories = [];
          for (let i = 0; i < selectedDateDimensionMembers.length; i++) {
            const dateMember = selectedDateDimensionMembers[i];
            const response = getYearMonthFromYYYYMM(dateMember.year_month, meetingMeasureData.langCode);
            if (response) {
              newXAxisCategories.push(response.displayText);
            } else {
              console.warn('meeting-report.functions: _trendChartDataSubscription: ', dateMember, response);
            }
          }
          trendChartData._xAxisCategories$.next(newXAxisCategories);
        })
      ).subscribe()
    );
    meetingMeasureData.subs.push(
      combineLatest([
        trendChartData.chartFilterOptions$,
        trendChartData.xAxisCategories$,
        meetingMeasureData.dataStream.dataCube$
      ])
      .pipe(
        filter(([
          chartFilterOptions,
          xAxisCategories,
          dataCube
        ]) => Array.isArray(chartFilterOptions) && Array.isArray(xAxisCategories) && dataCube),
        debounceTime(300),
        map(async ([
          chartFilterOptions,
          xAxisCategories,
          dataCube
        ]) => {
          await _updateSelectedTrendChartFilterOptionsData(chartFilterOptions, meetingMeasureData, true);

          const xAxis = JSON.parse(JSON.stringify(DefaultChartOptions.xAxis));
          xAxis.categories = xAxisCategories;
          updateHighChartOption(trendChartData.highChartRef, { xAxis }, false, true);

          if (trendChartData.highChartRef) {
            updateHighChartSeries(trendChartData.highChartRef, chartFilterOptions);
            trendChartData._chartUpdated$.next(trendChartData.id);
          }
        })
      ).subscribe()
    )
  }
}
