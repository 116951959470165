import { Contact } from "./../../classes/contact/contact.class";
import { Resource } from "../../classes/resource/resource.class";

export class PushResourcePayload {
  public static getDTO(
    resource: Resource,
    contacts: Contact[],
    sharedTime: number
  ): {
    contacts: { indskr_contactid: string }[];
    resource: {
      indskr_resulttime,
      indskr_resultstatus,
      indskr_sharedtime,
      indskr_ioresourceid,
      indskr_iodocumentid,
      indskr_title,
      modifiedon,
      indskr_ckmcreateddate,
      indskr_ckmassettype,
      indskr_ckmtitle,
      createdon,
      indskr_ckmthumbnailurl,
      indskr_ckmasseturl,
      indskr_ckmassetid,
      _indskr_ckmcreatedbyid_value,
      indskr_ckmdescription,
      _indskr_ckmpublishedbyid_value,
      indskr_ckmpublisheddate,
      _indskr_ckmversionbyid_value,
      indskr_ckmversiondate,
      indskr_ckmversionid,
      indskr_description,
      _indskr_languageid_value
    };
  } {
    let resourcePayload = ({
      indskr_resulttime,
      indskr_resultstatus,
      indskr_sharedtime,
      indskr_ioresourceid,
      indskr_iodocumentid,
      indskr_title,
      modifiedon,
      indskr_ckmcreateddate,
      indskr_ckmassettype,
      indskr_ckmtitle,
      createdon,
      indskr_ckmthumbnailurl,
      indskr_ckmasseturl,
      indskr_ckmassetid,
      _indskr_ckmcreatedbyid_value,
      indskr_ckmdescription,
      _indskr_ckmpublishedbyid_value,
      indskr_ckmpublisheddate,
      _indskr_ckmversionbyid_value,
      indskr_ckmversiondate,
      indskr_ckmversionid,
      indskr_description,
      _indskr_languageid_value
    }) => ({
      indskr_resulttime,
      indskr_resultstatus,
      indskr_sharedtime,
      indskr_ioresourceid,
      indskr_iodocumentid,
      indskr_title,
      modifiedon,
      indskr_ckmcreateddate,
      indskr_ckmassettype,
      indskr_ckmtitle,
      createdon,
      indskr_ckmthumbnailurl,
      indskr_ckmasseturl,
      indskr_ckmassetid,
      _indskr_ckmcreatedbyid_value,
      indskr_ckmdescription,
      _indskr_ckmpublishedbyid_value,
      indskr_ckmpublisheddate,
      _indskr_ckmversionbyid_value,
      indskr_ckmversiondate,
      indskr_ckmversionid,
      indskr_description,
      _indskr_languageid_value
    });

    let res = { indskr_sharedtime: `${sharedTime}`, indskr_resulttime: `${sharedTime}`, indskr_resultstatus: 0, ...resource.raw };

    return {
      contacts: contacts.map(contact => {
        return { indskr_contactid: contact.ID };
      }),
      resource: resourcePayload(res)
    };
  }
}
