export const SHIPMENT_ENTITY_NAME = 'indskr_usershipmentallocation_v2s';
export const SHIPMENT_INITIAL_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_shipmentstatus" />
    <attribute name="indskr_shipmentnumberauto" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_shipmentdate" />
    <attribute name="indskr_reasonforloss" />
    <attribute name="indskr_quantityreceived" />
    <attribute name="indskr_description" />
    <attribute name="indskr_dateacknowledged" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <attribute name="indskr_quantityshipped" alias="u_quantityshipped" />
    <order attribute="indskr_shipmentdate" descending="true" />
    <filter type="or">
      <filter type="and">
        <condition attribute="indskr_transfertype" operator="in">
          <value>548910000</value>
          <value>548910001</value>
        </condition>
      </filter>
      <filter type="and">
        <condition attribute="indskr_transfertype" operator="null" />
      </filter>
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="indskr_user" alias="ax2" link-type="inner">
      <attribute name="systemuserid" alias="indskr_userid" />
      <attribute name="fullname" alias="userFullName" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" alias="ow" link-type="inner">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="ownerFullName" />
    </link-entity>
    <filter type="or">
      <filter type="and">
        <condition attribute="indskr_user" operator="eq-userid" />
      </filter>
      {SHIPPED_BY_USER_FILTER}
    </filter>
    <filter type="or">
      <filter type="and">
        <condition attribute="validtodate" entityname="pl" operator="ge" value="{VALID_TO}" />
        <condition attribute="validfromdate" entityname="pl" operator="le" value="{VALID_FROM}" />
      </filter>
      <filter type="and">
        <condition attribute="validtodate" entityname="at_pl" operator="ge" value="{VALID_TO}" />
        <condition attribute="validfromdate" entityname="at_pl" operator="le" value="{VALID_FROM}" />
      </filter>
    </filter>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="indskr_allocationshipment_v2" from="indskr_allocationshipment_v2id" to="indskr_allocationshipment" link-type="outer" alias="ac">
      <attribute name="indskr_quantityshipped" alias="quantityshipped" />
      <filter type="and">
        <filter type="or">
          <condition attribute="indskr_type" operator="null" />
          <condition attribute="indskr_type" operator="eq" value="548910000" />
        </filter>
      </filter>
      <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="ps">
        <attribute name="name" alias="indskr_skuname" />
        <attribute name="productid" alias="indskr_skuid" />
        <attribute name="statecode" alias="sku_statecode" />
      </link-entity>
      <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="pl">
        <attribute name="productid" alias="indskr_lotid" />
        <attribute name="name" alias="indskr_lotname" />
        <attribute name="validtodate" alias="indskr_lotvalidtodate" />
        <attribute name="validfromdate" alias="indskr_lotvalidfromdate" />
        <attribute name="statecode" alias="lot_statecode" />
      </link-entity>
    </link-entity>
  </entity>
</fetch>`;

export const SHIPMENT_DELTA_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_shipmentstatus" />
    <attribute name="indskr_shipmentnumberauto" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_shipmentdate" />
    <attribute name="indskr_reasonforloss" />
    <attribute name="indskr_quantityreceived" />
    <attribute name="indskr_description" />
    <attribute name="indskr_dateacknowledged" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <filter type="or">
      <filter type="and">
        <condition attribute="indskr_transfertype" operator="in">
          <value>548910000</value>
          <value>548910001</value>
        </condition>
      </filter>
      <filter type="and">
        <condition attribute="indskr_transfertype" operator="null" />
      </filter>
    </filter>
    <attribute name="indskr_quantityshipped" alias="u_quantityshipped" />
    <link-entity name="systemuser" from="systemuserid" to="indskr_user" alias="ax2" link-type="inner">
      <attribute name="systemuserid" alias="indskr_userid" />
      <attribute name="fullname" alias="userFullName" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" alias="ow" link-type="inner">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="ownerFullName" />
    </link-entity>
    <filter type="or">
      <filter type="and">
        <condition attribute="indskr_user" operator="eq-userid" />
      </filter>
      {SHIPPED_BY_USER_FILTER}
    </filter>
    <filter type="or">
      <filter type="and">
        <condition attribute="validtodate" entityname="pl" operator="ge" value="{VALID_TO}" />
        <condition attribute="validfromdate" entityname="pl" operator="le" value="{VALID_FROM}" />
      </filter>
      <filter type="and">
        <condition attribute="validtodate" entityname="at_pl" operator="ge" value="{VALID_TO}" />
        <condition attribute="validfromdate" entityname="at_pl" operator="le" value="{VALID_FROM}" />
      </filter>
    </filter>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="indskr_allocationshipment_v2" from="indskr_allocationshipment_v2id" to="indskr_allocationshipment" link-type="outer" alias="aq">
      <attribute name="indskr_quantityshipped" alias="quantityshipped" />
      <filter type="and">
        <filter type="or">
          <condition attribute="indskr_type" operator="null" />
          <condition attribute="indskr_type" operator="eq" value="548910000" />
        </filter>
      </filter>
      <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="ps">
        <attribute name="name" alias="indskr_skuname" />
        <attribute name="productid" alias="indskr_skuid" />
        <attribute name="statecode" alias="sku_statecode" />
      </link-entity>
      <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="pl">
        <attribute name="productid" alias="indskr_lotid" />
        <attribute name="name" alias="indskr_lotname" />
        <attribute name="validtodate" alias="indskr_lotvalidtodate" />
        <attribute name="validfromdate" alias="indskr_lotvalidfromdate" />
        <attribute name="statecode" alias="lot_statecode" />
      </link-entity>
    </link-entity>
    <link-entity name="indskr_trackchange" from="indskr_entityid" to="indskr_usershipmentallocation_v2id" link-type="inner" alias="tcs">
      <attribute name="indskr_action" alias="track_action" />
      <order attribute="createdon" descending="true" />
      <filter type="and">
        <condition attribute="createdon" operator="ge" value="{LAST_UPDATED_DATE}" />
        <condition attribute="indskr_entityname" operator="eq" value="indskr_usershipmentallocation_v2" />
        <filter type="or">
        <condition attribute="indskr_userid" operator="eq" value="{userId}" />
          {USER_SHIPMENT_TRASFERRED_FILTER}
        </filter>
      </filter>
    </link-entity>
  </entity>
</fetch>`;

export const SHIPMENT_DELTA_DELETED_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_trackchange">
    <attribute name="indskr_trackchangeid" />
    <attribute name="indskr_action" alias="track_action" />
    <attribute name="indskr_entityid" alias="indskr_usershipmentallocationid" />
    <order attribute="createdon" descending="false" />
    <filter type="and">
      <condition attribute="indskr_action" operator="eq" value="548910001" />
      <condition attribute="createdon" operator="ge" value="{LAST_UPDATED_DATE}" />
      <condition attribute="indskr_entityname" operator="eq" value="indskr_usershipmentallocation_v2" />
    </filter>
    <filter type="or">
      <condition attribute="indskr_userid" operator="eq" value="{userId}" />
      {USER_SHIPMENT_TRASFERRED_FILTER}
    </filter>
  </entity>
</fetch>`;

export const SHIPPED_BY_USER_FILTER =
`<filter type="and"><condition attribute="indskr_shippedby" operator="eq-userid" /></filter>`;

export const USER_SHIPMENT_TRASFERRED_FILTER =
`<condition attribute="indskr_ownerid" operator="eq" value="{userId}" />`;
