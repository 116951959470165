<ion-list scrollY="true" *ngIf="source.length > 0">

  <ion-item-group id="{{item.indskr_contactcrid}}" *ngFor="let item of source | orderBy: 'createdon':false" [ngClass]="{'itemSelected':(item?.indskr_contactcrid===this.selected?.indskr_contactcrid)}">
    <ion-item (click)="viewDetails(item)">
<!--    <p data-letters="JW"> {{item.indskr_firstname + " " + item.indskr_lastname}}</p>-->
<!--    <ion-icon slot="start" class="main-icon">-->
      <p [attr.data-letters]="item.indskr_firstname?.charAt(0) + item.indskr_lastname?.charAt(0)"></p>
<!--    </ion-icon>-->
      <ion-label>
        <ion-text class="primary-text">
          <h3>{{item.indskr_firstname + " " + item.indskr_lastname}}</h3>
        </ion-text>
        <p class="secondary-text">
          <ion-text>{{getMDMType(item)}}</ion-text>
        </p>
        <p class="secondary-text">
          <ion-text>{{getDisplayDate(item.createdon)}}</ion-text>
          <ion-text class="tert-margin-text">{{mdmService.getStatusText(item.statuscode)}}</ion-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-group>
</ion-list>
