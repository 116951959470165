import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { MeetingSentimentHistoryDetailComponent } from '@omni/components/activity/meeting-sentiment-history-detail/meeting-sentiment-history-detail';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';


/**
 * Generated class for the Contact Sentiment History List component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-sentiment-history-list',
  templateUrl: 'contact-sentiment-history-list.html',
  styleUrls: ['contact-sentiment-history-list.scss']
})
export class ContactSentimentHistoryListComponent {

  public pageTitleViewData: IndPageTitleViewDataModel;
  public contactGlanceViewData: GlanceCardViewDataModel;
  public meetingHistorySectionHeader: IndSectionHeaderViewDataModel;
  public viewMeetingHistoryItems: Array<TimeLineItem> = [];
  private currentStartIndex: number = 30;
  private _allMeetingHistory: Array<AppointmentActivity> = [];
  private _filteredMeetingHistory: Array<AppointmentActivity> = [];
  private holdActivityDetailsPage:PageName;
  private ngDestroy$ = new Subject<boolean>();
  public searchPlaceholder: string;
  public filterButtonBadgeCount: number = 0;
  //public meetingHistoryFilterFormView: IndFilterMenuModalDataModel[] = [];
  //public selectedFilters: any = [];
  //private selectedDate: { startDate: string, endDate: string } = { startDate: '', endDate: '' };

  constructor(
    private readonly contactService: ContactOfflineService,
    private readonly navService: NavigationService,
    private readonly translate: TranslateService,
    private readonly customer360UIservice: Customer360UIService,
    private readonly device: DeviceService,
    public footerService: FooterService,
    public activityService: ActivityService,
    private secondaryInfoService: SecInfoConfigDataService,
  ) {

  }

  ngOnInit() {
    this._initPageTitle();
    this._initGlanceCardViewData();
    this._initMeetingHistoryItemsViewData();
    //this._initFilterFormViews();
    this._initSectionHeadersView();
    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && page == this.holdActivityDetailsPage && this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent){
        this._initMeetingHistoryItemsViewData();
        this._initSectionHeadersView();
      }
    })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private get meetings(): Array<AppointmentActivity> {
    return this._filteredMeetingHistory.sort((a,b)=> {
      if (a.scheduledStart > b.scheduledStart) return -1;
      if (a.scheduledStart < b.scheduledStart) return 1;
      return 0;
    });
  }

  private _initPageTitle() {
    this.pageTitleViewData = {
      id: "contact-meeting-history-list",
      title: this.contactService?.contactInformation?.fullName,
      controls: [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }]
    };
    this.searchPlaceholder = this.translate.instant('SEARCH');
  }

  private async _initGlanceCardViewData() {
    let glanceCardButtons = [];
    if (this.contactService.isAffiliationEnabled) {
      glanceCardButtons.push({
        id: "affiliation-explorer",
        imgSrc: 'assets/imgs/glance-affiliation-explorer.svg',
        name: this.translate.instant('AFFILIATION_EXPLORER'),
        isDisabled: !((this.contactService.linkEntityContactTo && this.contactService.linkEntityContactTo.length) || (this.contactService.linkEntityContactFrom && this.contactService.linkEntityContactFrom.length)
          || (this.contactService.affiliationAccount && this.contactService.affiliationAccount.length)),
        align: "right"
      });
    }
    const values = await this.contactService._getQuickGlanceValues();

    if(!this.device.isOffline){
      this.contactService.getEntityImageStringForContact(this.contactService.contactInformation).then((entityImageString => {
        if(entityImageString){ 
          this.contactService.contactInformation.entityImage = entityImageString;
          if(this.contactGlanceViewData){
            this.contactGlanceViewData.avatarURL = this.contactService.contactInformation.entityImage
          }
        }
      }));
    }
    this.contactGlanceViewData = {
      name: this.contactService.contactInformation.fullName,
      locationText: this.contactService.contactInformation.getPrimaryAddress,
      avatarURL: this.contactService.contactInformation.entityImage,
      thirdText: (values && values.length && values[0]) ? values[0].value : '',
        fourthText: (values && values.length && values[1]) ? values[1].value : '',
        fifthText: (values && values.length && values[2]) ? values[2].value : '',
        sixthText: (values && values.length && values[3]) ? values[3].value : '',
        thirdTextLabel: (values && values.length && values[0]) ? values[0].label : '',
        fourthTextLabel: (values && values.length && values[1]) ? values[1].label : '',
        fifthTextLabel: (values && values.length && values[2]) ? values[2].label : '',
        sixthTextLabel: (values && values.length && values[3]) ? values[3].label : '',
      buttons: glanceCardButtons,
      enableExpand: true,
      isExpanded: false,
    };
  }

  public async onQuickGlanceControlClick(data) {
    if (data.id) {
      switch (data.id) {
        case 'affiliation-explorer':
          this._handleAffiliationExplorer();
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private async _handleAffiliationExplorer() {
    let params = {
      from: 'ContactPageComponent',
      parent: this.contactService.contactInformation,
      relatedTo: await this.contactService.getContactRelatedToByContactId(this.contactService.contactInformation.ID),
      contactAccountAffiliation: await this.contactService.getContactToAccountByContactId(this.contactService.contactInformation.ID),
      relatedFrom: await this.contactService.getContactRelatedFromByContactId(this.contactService.contactInformation.ID),
    };
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent, PageName.AffiliationExplorerComponent, PageName.ContactPageComponent, params);
  }

  private _initSectionHeadersView() {
    this.meetingHistorySectionHeader = {
      id: 'contact-meeting-history-section',
      title: this.translate.instant('ALL_SENTIMENT_HISTORY')+' ('+this._filteredMeetingHistory.length+')',
      controls: []
    }
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this._closePage();
    }
  }

  private _closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  private _initMeetingHistoryItemsViewData() {
    if (this.contactService.contactInformation.ID) {
      this._allMeetingHistory = [];
      this._filteredMeetingHistory = [];
      if(!this.device.isOffline && this.contactService.isContactProfileLoaded){
        if(this.contactService.contactInformation && this.contactService.contactInformation.activitesByContact && this.contactService.contactInformation.activitesByContact.length>0){
          this._allMeetingHistory = this._filteredMeetingHistory = this.contactService.contactInformation.activitesByContact.filter(item => (item.type === 'Appointment' && item.subject)).map(a=> a as AppointmentActivity);
        }
      }
      this.viewMeetingHistoryItems = this._getViewForMeetings(this.meetings.slice(0, this.currentStartIndex))
    }
  }

  public doInfinite(event) {
    this.viewMeetingHistoryItems.push(...this._getViewForMeetings(this.sliceActivities(this.currentStartIndex + 1, this.currentStartIndex + 30)));
    this.currentStartIndex += 30;
    event.target.complete();
  }

  private _getViewForMeetings(meetings:Array<AppointmentActivity>):Array<TimeLineItem> {
    let viewItems:Array<TimeLineItem> = [];
    if(meetings && meetings.length > 0){
      meetings.forEach(meetingItem => {
        const view = this.customer360UIservice.getSentimentHistoryViewForMeetingActivity(meetingItem);
        if(view){
          viewItems.push(view);
        }
      });
    }
    return viewItems;
  }

  private sliceActivities(startIndex: number, count: number) {
    return this.meetings.length < count ? this.meetings.slice(startIndex) : this.meetings.slice(startIndex, count);
  }

  public async openItemDetails(ev) {
    let item: any = this._allMeetingHistory.find(a => ev && a.ID == ev.value);
    if (item) {
      const params = {
        from: 'ContactSentimentHistoryList',
        meeting: item,
      }
      this.navService.pushChildNavPageWithPageTracking(MeetingSentimentHistoryDetailComponent, PageName.MeetingSentimentHistoryDetailComponent, PageName.ContactPageComponent, params);
    }
  }

  // private _initFilterFormViews() {
  //   this.meetingHistoryFilterFormView = [];
  //   if (!_.isEmpty(this.meetings)) {
  //     let filterFormViewMain: IndFilterMenuModalDataModel = {
  //       id: 'filter-main',
  //       displayName: this.translate.instant('STATUS'),
  //       isMultiSelect: true,
  //       isExpanded: true,
  //       options: []
  //     }
  //     this.meetingHistoryFilterFormView.push(filterFormViewMain);
  //   }
  // }

  // public onFilterClick(ev) {
  //   this._openFilter(ev);
  // }

  // private async _openFilter(event) {
  //   const modal = await this.modalCtrl.create({
  //     component: IndFilterMenuModalComponent,
  //     cssClass: 'filter-menu-right-modal',
  //     backdropDismiss: true,
  //     componentProps: {
  //       viewData: {
  //         from: PageName.ContactSentimentHistoryListComponent,
  //         data: this.meetingHistoryFilterFormView,
  //         filterTitle: this.translate.instant('ALL_FILTERS'),
  //         selectedFilters: this.selectedFilters,
  //         selectedDate: this.selectedDate
  //       },
  //     }
  //   });
  //   await modal.present().then(() => { });
  //   modal.onDidDismiss().then(async (res) => {
  //     //Show Result
  //     if (res && res.data && res.data.isDone) {
  //       if (!_.isEmpty(res.data.selectedItems) || !_.isEmpty(res.data.selectedDate)) {
  //         //this.filterButtonBadgeCount = 0;
  //         //this._filteredMeetingHistory = [];
  //         this.meetingHistoryFilterFormView = res.data.selectedItems;
  //         this.selectedFilters = res.data.selectedItemsAccordion;
  //         // this.filterButtonBadgeCount = res.data.selectedItemsAccordion.length;
  //         // if (!_.isEmpty(res.data.selectedDate) && (!_.isEmpty(res.data.selectedDate.startDate) || !_.isEmpty(res.data.selectedDate.endDate))) {
  //         //   this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
  //         // }
  //         // let selectedActivitiesFromMainFilter = [];
  //         // res.data.selectedItemsAccordion.forEach(item => {
  //         //   if (item.type == 'filter-main') {
  //         //     selectedActivitiesFromMainFilter.push(item.category);
  //         //   }
  //         // });

  //         /******* filtered activities by duration *******/
  //         let isFilteredByDate: boolean = !_.isEmpty(res.data.selectedDate) && !_.isEmpty(res.data.selectedDate.startDate) && !_.isEmpty(res.data.selectedDate.endDate) || false;
  //         //let targetActivities = _.cloneDeep(this.meetings);
  //         if (isFilteredByDate) {
  //           this.selectedDate = {
  //             startDate: res.data.selectedDate.startDate,
  //             endDate: res.data.selectedDate.endDate,
  //           };
  //           //targetActivities = this._filterActivitiesByDate(targetActivities, this.selectedDate);
  //         } else {
  //           this.selectedDate = {
  //             startDate: '',
  //             endDate: '',
  //           };
  //         }
  //         this._applyAdvancedFilterOnActivities();
  //       }
  //       console.log(res);
  //     }
  //   })
  // }

  // private _applyAdvancedFilterOnActivities(){
  //   if (!_.isEmpty(this.meetingHistoryFilterFormView) || !_.isEmpty(this.selectedDate)) {
  //     this.filterButtonBadgeCount = 0;
  //     let _filteredTimelineActivities = [];
  //     this.filterButtonBadgeCount = this.selectedFilters.length;
  //     if (!_.isEmpty(this.selectedDate) && (!_.isEmpty(this.selectedDate.startDate) || !_.isEmpty(this.selectedDate.endDate))) {
  //       this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
  //     }
  //     let selectedActivitiesFromMainFilter = [];
  //     this.selectedFilters.forEach(item => {
  //       if (item.type == 'filter-main') {
  //         selectedActivitiesFromMainFilter.push(item.category);
  //       }
  //     });

  //     /******* filtered activities by duration *******/
  //     let isFilteredByDate: boolean = !_.isEmpty(this.selectedDate) && !_.isEmpty(this.selectedDate.startDate) && !_.isEmpty(this.selectedDate.endDate) || false;
  //     let targetActivities = _.cloneDeep(this._allMeetingHistory);
  //     if (isFilteredByDate) {
  //       // this.selectedDate = {
  //       //   startDate: this.selectedDate.startDate,
  //       //   endDate: this.selectedDate.endDate,
  //       // };
  //       targetActivities = this._filterActivitiesByDate(targetActivities, this.selectedDate);
  //     } else {
  //       // this.selectedDate = {
  //       //   startDate: '',
  //       //   endDate: '',
  //       // };
  //     }

  //     /******* filtered activities by selected activity and options *******/
  //     if (!_.isEmpty(selectedActivitiesFromMainFilter)) {
  //       let activities = [];
  //       selectedActivitiesFromMainFilter.forEach(mainFilterValue => {
  //         if (mainFilterValue == "eligible") {
  //           
  //         }
  //       });
  //       targetActivities = activities;
  //     }

  //     /******* grouped filtered activities *******/
  //     this._filteredMeetingHistory = targetActivities;
  //     this.viewMeetingHistoryItems = this._getViewForMeetings(this.meetings.slice(0, this.currentStartIndex));
  //     this._initSectionHeadersView();
  //   }else{
  //     this._filteredMeetingHistory = this._allMeetingHistory;
  //     this.viewMeetingHistoryItems = this._getViewForMeetings(this.meetings.slice(0, this.currentStartIndex));
  //     this._initSectionHeadersView();
  //   }
  // }

  // private _filterActivitiesByDate(targetActivities: any, selectedDate: any) {
  //   const startDate: Date = new Date(selectedDate.startDate);
  //   const endDate: Date = new Date(selectedDate.endDate);
  //   targetActivities.map(activity => {
  //     if (activity.hasOwnProperty('scheduledStart') && activity['scheduledStart'] != '' && activity['scheduledStart'] != "Invalid Date" && activity['scheduledStart'] != undefined) {
  //       activity['isValidStartDate'] = true;
  //       activity['scheduledStart'] = new Date(activity['scheduledStart']);
  //     }
  //     if (activity.hasOwnProperty('scheduledEnd') && activity['scheduledEnd'] != '' && activity['scheduledEnd'] != "Invalid Date" && activity['scheduledEnd'] != undefined) {
  //       activity['isValidEndDate'] = true;
  //       activity['scheduledEnd'] = new Date(activity['scheduledEnd']);
  //     }
  //   });
  //   let filteredTimelineActivities = [];
  //   targetActivities.forEach(activity => {
  //     if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate'] && activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
  //       if (activity['scheduledStart'] >= startDate && activity['scheduledEnd'] <= endDate) {
  //         filteredTimelineActivities.push(activity);
  //       }
  //     } else if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate']) {
  //       if (activity['scheduledStart'] >= startDate && activity['scheduledStart'] <= endDate) {
  //         filteredTimelineActivities.push(activity);
  //       }
  //     } else if (activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
  //       if (activity['scheduledEnd'] <= endDate) {
  //         filteredTimelineActivities.push(activity);
  //       }
  //     }
  //   });
  //   // targetTimelineActivities = filteredTimelineActivities;
  //   return filteredTimelineActivities;
  // }

}
