import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Account, AccountPlanMode } from '@omni/classes/account-management/account-plan.class';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { AccountManagementOfflineService } from '@omni/services/account-management/account-management.service';
import { AccountOfflineService, AccountSelectedFor } from '@omni/services/account/account.offline.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { format } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { AccountPlanDetails } from '../account-plan-details/account-plan-details';

@Component({
  selector: 'new-account-plan',
  templateUrl: 'new-account-plan.html',
  styleUrls: ['new-account-plan.scss'],
})
export class NewAccountPlanComponent implements OnInit {

  // private currentAccountPlan: {
  //   indskr_name: string,
  //   indskr_startdate: string,
  //   indskr_enddate: string,
  //   accountId: string,
  //   opportunities: [],
  //   products: [],
  //   contactAccountAffs: []
  // };
  private currentAccountPlan: any = {};
  private selectedAccounts: Account[] = [];
  public pageTitle: IndPageTitleViewDataModel;
  headerData: IndSectionHeaderViewDataModel = {
    id: 'new-account-plan-header',
    title: this.translate.instant('DETAILS_CAP'),
    controls: [
    ],
  };
  private _isClicked: { [x: string]: boolean } = {};
  constructor(private translate: TranslateService,
    private readonly device: DeviceService,
    private navService: NavigationService,
    private accountService: AccountOfflineService,
    private readonly popoverCtrl: PopoverController,
    private activityService: ActivityService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private accountManagementDataService: AccountManagementDataService,
    private uiService: UIService,
    private accountManagementOfflineService: AccountManagementOfflineService,
    private alertService: AlertService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.initPageTitle();
  }

  private initPageTitle() {
    const buttons = [{
      id: "scrap",
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('CANCEL'),
      isDisabled: false,
      align: "right"
    }, {
      id: 'account_plan_confirm',
      imgSrc: 'assets/imgs/header_complete.svg',
      name: this.translate.instant('CREATE'),
      isDisabled: !this.isValid,
      align: "right"
    }
    ]
    this.pageTitle = {
      id: 'new-account-plan-title',
      title: this.translate.instant("ACCOUNTPLAN"),
      controls: buttons,
    };
  }

  get isValid() {
    return (!_.isEmpty(this.selectedAccounts) &&
      !_.isEmpty(this.currentAccountPlan?.indskr_name) &&
        this.currentAccountPlan?.indskr_startdate != null &&
        this.currentAccountPlan?.indskr_enddate != null);
  }

  public getNameFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NAME'),
      inputText: this.currentAccountPlan?.indskr_name,
      inputValue: this.currentAccountPlan?.indskr_name,
      id: 'name-field',
      isDisabled: this.device.isOffline,
      placeholderLabel: this.translate.instant('NAME'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['name-field'] && _.isEmpty(this.currentAccountPlan.indskr_name),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.selectedAccounts.length > 0 ? this.selectedAccounts[0].name + (this.selectedAccounts.length > 1 ? `+${this.selectedAccounts.length - 1}` : '') : '',
      placeholderLabel: this.translate.instant('ACCOUNT'),
      id: 'account-field',
      isDisabled: this.device.isOffline,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.selectedAccounts) && this._isClicked['account-field'],
      isReadOnly: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get startDateField() {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('START_PERIOD'),
      customPlaceholderLabel: this.translate.instant('SELECT_START_PERIOD'),
      inputText: this.currentAccountPlan?.indskr_startdate ? this.datePipe.transform(this.currentAccountPlan.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : null,
      startDateTimeValue: this.currentAccountPlan?.indskr_startdate,
      endDateTimeValue: this.currentAccountPlan?.indskr_enddate,
      fromViewPage: CurViewPageType.AccountPlan,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.device.isOffline,
      showArrow: true,
      isRequired: true,
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.currentAccountPlan?.indskr_startdate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    if (viewData && viewData.isEmptyRequiredField) viewData.errorMessageField = this.errorMessageField;
    return viewData;
  }

  public get endDateField() {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('END_PERIOD'),
      customPlaceholderLabel: this.translate.instant('SELECT_END_PERIOD'),
      inputText: this.currentAccountPlan?.indskr_enddate ? this.datePipe.transform(this.currentAccountPlan.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : null,
      startDateTimeValue: this.currentAccountPlan?.indskr_startdate,
      endDateTimeValue: this.currentAccountPlan?.indskr_enddate,
      fromViewPage: CurViewPageType.AccountPlan,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.device.isOffline,
      showArrow: true,
      isRequired: true,
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.currentAccountPlan?.indskr_enddate) && this._isClicked[DateTimeFieldType.EndDateField],
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    if (viewData && viewData.isEmptyRequiredField)  viewData.errorMessageField = this.errorMessageField;
    return viewData;
  }

  private get errorMessageField() {
    return {
      id: DateTimeFieldType.ErrorMessageField,
      fromViewPage: CurViewPageType.AccountPlan,
      isReadOnly: true,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: true,
    }
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (this.device.isOffline) return;
    switch (id) {
      case 'name-field':
        this._isClicked[id] = true;
        if (eventName && eventName == 'input_value_confirm') {
          this.handleAccountPlanNameUpdate(event);
        }
        break;
      case 'account-field':
        this._isClicked[id] = true;
        this.openAccountSelection();
        break;
      case DateTimeFieldType.StartDateField:
        this._isClicked[id] = true;
        this.openStartDatePicker(event);
        break;
      case DateTimeFieldType.EndDateField:
        this.openEndDatePicker(event);
        break;
      default:
        console.error("unhandled case");
    }
  }

  private handleAccountPlanNameUpdate(event) {
    if (event && event.target.value !== undefined) {
      this.currentAccountPlan.indskr_name = event.target.value;
      this.initPageTitle();
    }
  }

  private openAccountSelection() {
    this.accountService.accountPageMode = ComponentViewMode.ADDNEW;
    this.accountService.accessedAccountListFrom = PageName.NewAccountPlanComponent;
    this.accountService.selectedFor = AccountSelectedFor.GENERAL_MULTIPLE_SELECTION
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      listMode: this.accountService.accountPageMode,
      selectedAccountIds: this.selectedAccounts ? this.selectedAccounts : [],
      callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
    }, PageName.AccountPageComponent);
  }

  private async _handleAccountComponentCallback(data) {
    this.selectedAccounts = data;
    if (_.isEmpty(this.selectedAccounts))
      this._isClicked["account-field"] = true;
    this.initPageTitle();
  }

  private async openStartDatePicker(event) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime);
        if (this.currentAccountPlan.indskr_enddate && valueDate > this.currentAccountPlan.indskr_enddate) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'New Account Plan', 'top', ToastStyle.DANGER);
        } else {
          this.currentAccountPlan.indskr_startdate = valueDate;
          this.initPageTitle();
        }
      }
      if (this.currentAccountPlan.indskr_startdate)  this._isClicked[DateTimeFieldType.StartDateField] = false;
    })
  }

  private async openEndDatePicker(event) {
    if (!this.currentAccountPlan.indskr_startdate) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    this._isClicked[DateTimeFieldType.EndDateField] = true;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.endTime)) {
        const valueDate = new Date(obj.data.endTime);
        if (this.currentAccountPlan.indskr_startdate > valueDate) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'New Account Plan', 'top', ToastStyle.DANGER);
        } else {
          this.currentAccountPlan.indskr_enddate = valueDate;
          this.initPageTitle();
        }
      }
      if (this.currentAccountPlan.indskr_enddate) this._isClicked[DateTimeFieldType.EndDateField] = false;
    })
  }

  private async getDateTimePopover() {
    return await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.AccountPlan,
          startDateTimeValue: this.currentAccountPlan?.indskr_startdate ? moment(this.currentAccountPlan.indskr_startdate).format() : null,
          endDateTimeValue: this.currentAccountPlan?.indskr_enddate ? moment(this.currentAccountPlan.indskr_enddate).format() : null,
        },
        cssClass: "datetime-popover"
      }
    );
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case "account_plan_confirm":
        this.saveAccountPlan();
        break;
      case "close":
        this.closePage();
        break;
      case "scrap":
        this.scrapAccountPlan();
        break;
    }
  }

  private scrapAccountPlan() {
    if (_.isEmpty(this.currentAccountPlan)) this.closePage();
    else
      this.alertService.showAlert({
        title: this.translate.instant('SCRAP_ACCOUNT_PLAN'),
        message: this.translate.instant('POP_R_U_SURE_SCRAP_ACCOUNT_PLAN')
      }, this.translate.instant('YES'), this.translate.instant('NO')
      ).then(async res => {
        if (res.role == "ok") {
          this.closePage();
        }
      });
  }

  private closePage() {
    this.accountManagementOfflineService.isRightPaneNavActive = false;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent, {
      deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
    });
  }

  private async saveAccountPlan() {
    const { indskr_startdate, indskr_enddate } = this.currentAccountPlan;
    this.currentAccountPlan.accountId = this.selectedAccounts[0].id;

    if (indskr_startdate) this.currentAccountPlan.indskr_startdate = format(indskr_startdate, 'YYYY-MM-DD');
    if (indskr_enddate) this.currentAccountPlan.indskr_enddate = format(indskr_enddate, 'YYYY-MM-DD');

    await this.uiService.displayLoader();
    await this.accountManagementDataService.saveAccountPlan(this.currentAccountPlan).then(async (accountPlan) => {
      this.accountManagementOfflineService.selectedMode = AccountPlanMode.NEW;
      await this.accountManagementDataService.getAccountPlans(false, accountPlan.ID);
      accountPlan = _.cloneDeep(this.accountManagementOfflineService.accountPlans.find(o => o.ID === accountPlan.ID));
      this.accountManagementOfflineService.selectedAccountPlan$.next(accountPlan);
    });
  }
}
