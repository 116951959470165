<ion-header [ngClass]="(isSearchBarEnabled) ? 'header-with-searchbar' : 'header-without-searchbar' ">
    <ind-page-title [viewData]="dynamicFormPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <!--    <ng-container *ngIf="isSearchBarEnabled">-->
    <ion-searchbar *ngIf="isSearchBarEnabled" [(ngModel)]="searchText" (ionChange)="onSearchInput($event.detail)" [debounce]="750" placeholder="{{'SEARCH' | translate}}"></ion-searchbar>
    <!--    </ng-container>-->
</ion-header>
<ion-content>
  <div [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
    <ng-container *ngFor="let viewData of currentFormView">
      <ind-section-header *ngIf="viewData.type == 'section-header'" [viewData]='viewData.view' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <div class='linked-entity-view' *ngIf="viewData.type == 'section-header' && viewData.data">
          <ng-container *ngIf="linkedEntityValues && linkedEntityValues[viewData.data];else linkedView">
              <!-- <div *ngFor="let item of linkedEntityValues[viewData.data]" [ngClass]="(item.isExpanded)?'accordion-expanded':''">
                  <div class="accordion-header">
                      <main-card [viewData]="item.view"></main-card>
                  </div>
                  <div class="linked-entity-accordian-expanded" *ngIf="item.isExpanded">
                      <ind-form-field *ngFor="let field of item.childItems" [viewData]="field"></ind-form-field>
                  </div>
              </div> -->
              <div *ngIf="!changeRequest && editConfig == 548910000 && !(formDetail.formMetadata.entityName == 'indskr_setbooking' && viewData.data == 'SetBookingProducts')">
                <ng-container *ngFor="let item of linkedEntityValues[viewData.data]">
                    <omni-accordion [viewData]="item"></omni-accordion>
                </ng-container>
              </div>
              <div *ngIf="((!changeRequest || (changeRequest && TYPE == 'OMNIPRESENCE' && MODE == 'EDIT_MODE')) && (editConfig == 548910001 || editConfig == 548910002 || (editConfig == 548910000 && formDetail.formMetadata.entityName == 'indskr_setbooking' && viewData.data == 'SetBookingProducts')))">
                <ng-container *ngFor="let item of linkedEntityValues[viewData.data]">
                  <ion-item class="editable-linked-entity separationLine">
                    <ion-label>{{item.primaryText}}</ion-label>
                    <ion-icon (click)="openEditFormExistingData($event, item)" name="chevron-forward-outline" class="arrow-icon" slot="end" ></ion-icon>
                  </ion-item>
                </ng-container>
              </div>

              <div *ngIf="changeRequest && (!(changeRequest && TYPE == 'OMNIPRESENCE' && MODE == 'EDIT_MODE')) && viewData.data != addressAttrName || (viewData.data == addressAttrName && (!(!isViewFromEditFormToAccordionForm && hasExistingLinkedDataForAddressAccountCR && !addedNewAddressForAccountCR)))">
                  <ng-container *ngFor="let item of linkedEntityValues[viewData.data]">
                      <omni-accordion [viewData]="item"></omni-accordion>
                  </ng-container>
              </div>
              <ng-container *ngIf="changeRequest && viewData.data == addressAttrName && !isViewFromEditFormToAccordionForm && hasExistingLinkedDataForAddressAccountCR && !addedNewAddressForAccountCR">
                  <ion-item [ngClass]="isExpandedCurrentEditFormView?'expanded accordion-header':'collapsed accordion-header'">
                      <ion-label>{{labelForExistingAddress}}</ion-label>
                      <ion-icon *ngIf='!isExpandedCurrentEditFormView' (click)="expandChildItems($event,true)" src='assets/imgs/accordion-add.svg' slot="end" class='expand-icon'></ion-icon>
                      <ion-icon *ngIf='isExpandedCurrentEditFormView' (click)="expandChildItems($event,false)" src='assets/imgs/accordion-minus.svg' slot="end" class='collapse-icon'></ion-icon>
                  </ion-item>
              </ng-container>
              <div *ngIf="changeRequest && viewData.data == addressAttrName && !isViewFromEditFormToAccordionForm && isExpandedCurrentEditFormView " class="accordion-body-editform">
                  <ng-container *ngFor="let editFormView of currentEditFormView">
                      <ind-form-field [viewData]='editFormView.view'></ind-form-field>
                      <!-- <ion-label *ngIf="editFormView.view.isInvalid" class="error-message-text">{{ errorMessage }}</ion-label> -->
                  </ng-container>
              </div>

          </ng-container>
          <ng-template #linkedView>
              <ion-item class='empty-item'></ion-item>
          </ng-template>
      </div>
      <ind-form-field *ngIf="viewData.type == 'form-field' && viewData.control && viewData.control.dataType != 'DateTimeType'" [viewData]='viewData.view' [ngClass]="viewData.id"></ind-form-field>
      <!-- Date and Time field -->
      <ng-container *ngIf="viewData.type == 'form-field' && viewData.control && viewData.control.dataType == 'DateTimeType'">
        <ind-form-field *ngIf="viewData.view.id =='omnione_yearofbirth' || viewData.view.id == 'omnione_dayandmonthofbirth'" [viewData]='viewData.view' [ngClass]="viewData.id"></ind-form-field>
        <ind-datetime-form *ngIf="viewData.control.dateFormat == 'DateOnly' && viewData.view.id !='omnione_yearofbirth' && viewData.view.id != 'omnione_dayandmonthofbirth'" [viewData]='viewData.view'></ind-datetime-form>
        <ion-row *ngIf="viewData.control.dateFormat == 'DateAndTime' && viewData.view.id !='omnione_yearofbirth' && viewData.view.id != 'omnione_dayandmonthofbirth'">
          <ion-col size="6"><ind-datetime-form *ngIf="viewData.view" [viewData]='viewData.view'></ind-datetime-form></ion-col>
          <ion-col size="6"><ind-datetime-form *ngIf="viewData.timeView" [viewData]='viewData.timeView'></ind-datetime-form></ion-col>
        </ion-row>
      </ng-container>
      <!-- <ion-label *ngIf="viewData.view.isInvalid" class="error-message-text">{{ errorMessage }}</ion-label> -->
  </ng-container>
  <ng-container *ngIf="MODE == 'EDIT_MODE' && TYPE== 'SANOFI_CHINA' && (businessLine == 2 || businessLine == 3)">
    <ind-section-header [viewData]="this.worksCardHeader" (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-section-header>
    <ind-text-area id="workCardInput" [placeHolder]="''" [showAttachment]="isWorkCardAdded" [readonly]="true"
      [attachmentTitle]="workCardTitle" [maxLength]="1000" [rows]="2" [cols]="10"
      (removeAttachment)="removeAttachment($event, 'attach_work_cards')"></ind-text-area>
    <input class="hide-input" #workcardAttachInput type="file" (change)="loadFileFromDevice($event, 'attach_work_cards')" id="work-card-file-input">
    <ind-section-header [viewData]="this.practiceCertificateHeader" (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-section-header>
    <ind-text-area id="practiceCertificateInput" [placeHolder]="''" [showAttachment]="isPracticeCertificateAdded" [readonly]="true"
      [attachmentTitle]="practiceCertificateTitle" [maxLength]="1000" [rows]="2" [cols]="10"
      (removeAttachment)="removeAttachment($event, 'attach_practice_certificate')"></ind-text-area>
    <input class="hide-input" #practiceCertificateAttachInput type="file" (change)="loadFileFromDevice($event, 'attach_practice_certificate')" id="work-card-file-input">
  </ng-container>
  <!-- <ion-item no-lines hidden="true">
      <ion-datetime #dynamicFormDate (ionCancel)="updateFormDate(false)" (ionChange)="updateFormDate($event.detail)" [displayFormat]="dateTimeFormatsService.dateTimeToUpper" [pickerFormat]="dateTimeFormatsService.dateTimeToUpper"
      [monthShortNames]="shortMonth | monthTranslate" [doneText]="this.translate.instant('DONE')" [cancelText]="this.translate.instant('CANCEL')"></ion-datetime>
  </ion-item> -->
  <div #searchPopover *ngIf="isSearchPopoverActive" class="main-search-popover">
      <ion-list *ngIf="isAddressPopover">
          <ion-item class="selector-item" *ngFor="let option of searchPopoverOptions" (click)="setSelectedSearchPopoverValue(option)" [ngClass]="{'itemSelected':(selectedSearchOption && option.indskr_addressid == selectedSearchOption.indskr_addressid)}">
              <ion-label>{{option.compositeAdd}}</ion-label>
              <ion-icon class='checkmark-icon' *ngIf="selectedSearchOption && option.indskr_addressid == selectedSearchOption.indskr_addressid" name="indegene-selectors-checkmark-icon" slot="end"></ion-icon>
          </ion-item>
      </ion-list>
      <ion-list *ngIf="isAddressOneKeyPopover">
          <ion-item class="selector-item" *ngFor="let option of searchPopoverOptions" (click)="setSelectedSearchPopoverValue(option)" [ngClass]="{'itemSelected':(selectedSearchOption && option.indskr_addressid == selectedSearchOption.indskr_addressid)}">
              <ion-label>{{option.indskr_composite}}</ion-label>
              <ion-icon class='checkmark-icon' *ngIf="selectedSearchOption && option.indskr_addressid == selectedSearchOption.indskr_addressid" name="indegene-selectors-checkmark-icon" slot="end"></ion-icon>
          </ion-item>
      </ion-list>
      <ion-list *ngIf="isAccountAccountAffiliationPopover">
          <ion-item class="selector-item" *ngFor="let option of searchPopoverOptions" (click)="setSelectedSearchPopoverValue(option)" [ngClass]="{'itemSelected':(selectedSearchOption && option._indskr_affiliatedtoaccountid_value == selectedSearchOption._indskr_affiliatedtoaccountid_value)}">
              <ion-label>{{option._indskr_affiliatedtoaccountid_value_Formatted}}</ion-label>
              <ion-icon class='checkmark-icon' *ngIf="selectedSearchOption && option._indskr_affiliatedtoaccountid_value == selectedSearchOption._indskr_affiliatedtoaccountid_value" name="indegene-selectors-checkmark-icon" slot="end"></ion-icon>
          </ion-item>
      </ion-list>
      <ion-list *ngIf="isAccountContactAffiliationPopover">
          <ion-item class="selector-item" *ngFor="let option of searchPopoverOptions" (click)="setSelectedSearchPopoverValue(option)" [ngClass]="{'itemSelected':(selectedSearchOption && option._indskr_accountid_value == selectedSearchOption._indskr_accountid_value)}">
              <ion-label>{{option._indskr_accountid_value_Formatted}}</ion-label>
              <ion-icon class='checkmark-icon' *ngIf="selectedSearchOption && option._indskr_accountid_value == selectedSearchOption._indskr_accountid_value" name="indegene-selectors-checkmark-icon" slot="end"></ion-icon>
          </ion-item>
      </ion-list>
  </div>
  </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="onFooterButtonClicked($event)" [footerView]="'detailView'" [selectedView]="'accounts'"></footer-toolbar>
<!-- </ion-footer> -->
