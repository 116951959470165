import {Injectable} from "@angular/core";

/** Log levels, in ascending order */
export enum LogLevel {
  Errors = 0, // production, log errors only
  Warnings,
  Info,
  Events,
  Debug
}

/** Handles logging actions, depending on the log level.
 * Default output target is a browser console.
 * Default log level is Debug.
 */
@Injectable({
  providedIn: 'root'
})
export class LogService {

  /** Log level */
  public logLevel: LogLevel = LogLevel.Debug;

  /** Log args if logLevel is Debug or higher */
  public logDebug(...args: any[]): void {
    if (this.logLevel >= LogLevel.Debug) {
      (<any>console).log(...args);
    }
  }

  /** Log args if logLevel is Events or higher */
  public logEvent(...args: any[]): void {
    if (this.logLevel >= LogLevel.Events) {
      (<any>console).log(...args);
    }
  }

  /** Log args if logLevel is Info or higher */
  public logInfo(...args: any[]): void {
    if (this.logLevel >= LogLevel.Info) {
      (<any>console).log(...args);      
    }
  }

  /** Log args if logLevel is Warnings or higher */
  public logWarning(...args: any[]): void {
    if (this.logLevel >= LogLevel.Warnings) {
      (<any>console).warn(...args);
    }
  }

  /** Log args if logLevel is Errors or higher */
  public logError(...args: any[]): void {
    if (this.logLevel >= LogLevel.Errors) {
      (<any>console).error(...args);
    }
  }

}
