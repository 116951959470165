import { fileManagerState, INITIAL_FILE_MANAGER_STATE } from './io-file-service/states/file.state';
import { ResourceManagerState, INITIAL_RESOURCE_MANAGER_STATE } from './io-file-service/states/resource.state';

export const INITIAL_APP_STATE: appState = {
    fileManager: INITIAL_FILE_MANAGER_STATE,
    resourceManager: INITIAL_RESOURCE_MANAGER_STATE
}
export const INITIAL_FILE_STATE: fileState = {
    fileManager: INITIAL_FILE_MANAGER_STATE
}

export interface fileState {
    fileManager: fileManagerState;
}

export const INITIAL_RESOURCE_STATE: ResourceState = {
    resourceManager: INITIAL_RESOURCE_MANAGER_STATE,
}

export interface ResourceState {
    resourceManager: ResourceManagerState;
}

export interface appState {
    fileManager: fileManagerState;
    resourceManager: ResourceManagerState;
}

