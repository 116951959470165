import { ChartFilterOption } from '../../../../interfaces/edge-analytics/report.interface';
import { CommonDimensions } from '../../../enums/edge-analytics/edge-analytics.enum';
import { MeetingDimensions, MeetingKPI } from '../../../enums/edge-analytics/meeting/meeting-report.enum';
import { IndReportTileDataModel } from '../../../models/IndReportTileDataModel';
import { SelectListDetail } from '../../../models/select-list-detail-model';

export const MeetingReportTilePerRow = 3;
export const MeetingReportYearMonthKeyProp = 'year_month';
export const MeetingDataCubeDimensionHierarchies = {
  dimensionHierarchies: [
    {
      dimensionTable: { dimension: CommonDimensions.yearMonth, keyProps: [MeetingReportYearMonthKeyProp] },
    },
    {
      dimensionTable: { dimension: MeetingDimensions.products, keyProps: ['sk_productid'] }
    },
    {
      dimensionTable: { dimension: MeetingDimensions.statecode, keyProps: ['statecode'] }
    },
    {
      dimensionTable: { dimension: CommonDimensions.users, keyProps: ['sk_systemuserid'] }
    }
  ]
}

export const MeetingReportTiles: IndReportTileDataModel[] = [
  {
    id: MeetingKPI.totalMeeting,
    firstHeading: "TOTAL_COMPLETED",
    dataValue: '',
    dataFieldName: "MEETINGS_PHONE_CALLS",
    order: 0
  },
  {
    id: MeetingKPI.avgMeeting,
    firstHeading: "AVERAGE_COMPLETION_PER_DAY",
    dataValue: '',
    dataFieldName: "MEETINGS_PHONE_CALLS",
    order: 1
  },
  {
    id: MeetingKPI.delayMeeting,
    firstHeading: "AVERAGE_DELAY_IN_RECORDING",
    dataValue: '',
    dataFieldName: "DAYS",
    order: 2
  },
  {
    id: MeetingKPI.totalContentDuration,
    firstHeading: "TOTAL_CONTENT_SHARE_DURATION",
    dataValue: '',
    dataFieldName: "MINUTES",
    order: 3
  },
  {
    id: MeetingKPI.contentShared,
    firstHeading: "CONTENT_SHARE",
    dataValue: '',
    dataFieldName: "MEETINGS",
    order: 4
  },
  {
    id: MeetingKPI.totalCoveragePercent,
    firstHeading: "TOTAL_COVERAGE_PCT",
    dataValue: '',
    dataFieldName: '',
    order: 5
  },
  {
    id: MeetingKPI.coverage,
    firstHeading: "CALL_PLAN_COVERAGE",
    dataValue: '',
    dataFieldName: "CUSTOMERS",
    order: 6
  },
  {
    id: MeetingKPI.totalWorkingDays,
    firstHeading: "WORKING_DAYS",
    dataValue: '',
    dataFieldName: "DAYS",
    order: 7
  },
  {
    id: MeetingKPI.totalInPerson,
    firstHeading: "IN_PERSON",
    dataValue: '',
    dataFieldName: "MEETINGS",
    order: 8
  },
  {
    id: MeetingKPI.totalLiveTime,
    firstHeading: "REMOTE",
    dataValue: '',
    dataFieldName: "MEETINGS",
    order: 9
  },
  {
    id: MeetingKPI.totalPhoneCall,
    firstHeading: "COMPLETED",
    dataValue: '',
    dataFieldName: "PHONE_CALLS",
    order: 10
  },
  {
    id: MeetingKPI.noCallPlanMeeting,
    firstHeading: 'MEETINGS_WITH_NO_CALL_PLAN',
    dataValue: '',
    dataFieldName: "MEETINGS",
    order: 11
  },
  {
    id: MeetingKPI.cpaAB,
    firstHeading: "CPA (A+B)",
    dataValue: '',
    dataFieldName: '',
    order: 12
  },
  {
    id: MeetingKPI.cpaABC,
    firstHeading: "CPA (A+B+C)",
    dataValue: '',
    dataFieldName: '',
    order: 13
  },
]

export const MeetingByProductChartFilterOptions: ChartFilterOption[] = [
  {
    value : MeetingKPI.avgMeeting,
    isSelected: false,
    displayText: 'Average Completion Per Day',
    displayTextKey: "AVERAGE_COMPLETION_PER_DAY",
    data: undefined,
  },
  {
    value : MeetingKPI.delayMeeting,
    isSelected: false,
    displayText: 'Average Delay In Recording',
    displayTextKey: "AVERAGE_DELAY_IN_RECORDING",
    data: undefined
  },
  {
    value : MeetingKPI.contentShared,
    isSelected: false,
    displayText: 'Content Share %',
    displayTextKey: "CONTENT_SHARE_PCT",
    data: undefined
  },
  {
    value : MeetingKPI.totalContentDuration,
    isSelected: false,
    displayText: 'Total Content Share Duration',
    displayTextKey: "TOTAL_CONTENT_SHARE_DURATION",
    data: undefined
  },
  {
    value : MeetingKPI.totalMeeting,
    isSelected: true,
    displayText: 'Total Completed',
    displayTextKey: "TOTAL_COMPLETED",
    data: undefined
  },
  {
    value : MeetingKPI.totalInPerson,
    isSelected: false,
    displayText: 'Total In-Person Meetings Completed',
    displayTextKey: "TOTAL_IN_PERSON_MEETINGS_COMPLETED",
    data: undefined
  },
  {
    value : MeetingKPI.totalLiveTime,
    isSelected: false,
    displayText: 'Total Remote Meetings Completed',
    displayTextKey: "TOTAL_REMOTE_MEETINGS_COMPLETED",
    data: undefined
  },
  {
    value : MeetingKPI.totalPhoneCall,
    isSelected: false,
    displayText: 'Total Phone Calls Completed',
    displayTextKey: "TOTAL_PHONE_CALLS_COMPLETED",
    data: undefined
  },
  {
    value : MeetingKPI.noCallPlanMeeting,
    isSelected: false,
    displayText: 'Meetings with No Call Plan',
    displayTextKey: "MEETINGS_WITH_NO_CALL_PLAN",
    data: undefined
  },
  {
    value : MeetingKPI.totalCoveragePercent,
    isSelected: false,
    displayText: 'Total Coverage %',
    displayTextKey: "TOTAL_COVERAGE_PCT",
    data: undefined
  },
]

export const MeetingTrendChartExtraFilterOptions: ChartFilterOption[] = [
  {
    value : MeetingKPI.cpaAB,
    isSelected: false,
    displayText: 'CPA (A+B)',
    displayTextKey: "CPA (A+B)",
    data: undefined,
    chartId: 'trend'
  },
  {
    value : MeetingKPI.cpaABC,
    isSelected: false,
    displayText: 'CPA (A+B+C)',
    displayTextKey: "CPA (A+B+C)",
    data: undefined,
    chartId: 'trend'
  },
]

export const MeetingByProductChartFilterTemplate: SelectListDetail = {
  title: 'Measure',
  titleKey: 'MEASURE',
  dividerTitle: 'All Measures',
  dividerTitleKey: 'ALL_MEASURES',
  isMultipleSelectionEnabled: true,
  data: []
}

export const MeetingChartTitles = {
  byProduct: 'ME_BY_PRODUCT',
  trend: 'TREND',
}
