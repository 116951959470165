import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Endpoints } from "../../../config/endpoints.config";
import { DeviceService } from "../../services/device/device.service";
import { PhoneActivity } from "../../classes/activity/phone.activity.class";
import { DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { ActivityService } from "../../services/activity/activity.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../../services/disk/disk.service";

import { Events } from '@omni/events';
import { UIService } from "../../services/ui/ui.service";
// import { UpdatePhoneCallPayload } from "../meeting/meeting.data.service";
import { LogService } from "../../services/logging/log-service";
import { GlobalErrorHandler } from "../../services/error-handler/error-handler-service";
import { GlobalUtilityService } from "../../services/global-utility.service";
import { UpdateTypeAndSubTypeActivityPayLoad } from '../meeting/meeting.data.service';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import { AccompainedUser } from "@omni/classes/activity/accompained-user.class";
import { MeetingActivityTypeCode } from '@omni/classes/activity/activity.class';
import _ from 'lodash';

export interface PhoneCallCreateResponseDTO {
  activityid: string,
  offlineId: string
};

export enum PhonecallStatus {
  OPEN = 'Open',
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export class UpdatePhoneCallPayload {
  public subject?: string;
  public scheduledstart?: Date;
  public scheduledend?: Date;
  public indskr_notes?: string;
  public indskr_contactid?: string;
  public products?: any[];
  public therapeuticareas?: any[];
  public accounts?: any[];
  public statecode: number;
  public offlineId: string;
  public activityid: string;
  public phonenumber: string;
  public appconfiglookupfields: ConfiguredFields[];
  public accompaniedUsersRaw: AccompainedUser[];
  public indskr_datecompleted: string;
  public indskr_actualdurationminutesandseconds?: string;

  constructor(
    subject?: string,
    start?: any,
    end?: any,
    notes?: string,
    contactId?: string,
    products?: any[],
    therapeuticareas?: any[],
    accounts?: any[],
    accompaniedUsersRaw?: AccompainedUser[],
    statecode?: number,
    offlineId?: string,
    activityid?: string,
    phonenumber?: string,
    appconfiglookupfields?: ConfiguredFields[]
  ) {
    this.scheduledstart = start;
    this.scheduledend = end;
    this.subject = subject;
    this.indskr_notes = notes;
    this.indskr_contactid = contactId;
    this.products = products;
    this.therapeuticareas = therapeuticareas;
    this.accounts = accounts
    this.statecode = statecode
    this.offlineId = offlineId;
    this.activityid = activityid;
    this.phonenumber = phonenumber;
    this.appconfiglookupfields = appconfiglookupfields;
    this.accompaniedUsersRaw = accompaniedUsersRaw;
  }

  public getRequestBody() {
    let requestBody = {
      scheduledstart: this.scheduledstart,
      scheduledend: this.scheduledend,
      subject: this.subject,
      indskr_notes: this.indskr_notes,
      indskr_contactid: this.indskr_contactid,
      products: this.products ? this.products : [],
      therapeuticareas: this.therapeuticareas ? this.therapeuticareas : [],
      accounts: this.accounts ? this.accounts : [],
      statecode: this.statecode ? this.statecode : 0,
      offlineId: this.offlineId ? this.offlineId : '',
      activityid: this.activityid ? this.activityid : '',
      phonenumber: this.phonenumber,
      appconfiglookupfields: this.appconfiglookupfields ? this.appconfiglookupfields : []
    };
    let activityParties = [];
    if (this.indskr_contactid) {
      activityParties.push({ contactId: this.indskr_contactid, participationtypemask: 2 });
    }
    if (this.accounts && this.accounts.length > 0) {
      for (let account of this.accounts) {
        activityParties.push({ accountId: account['indskr_accountid'], participationtypemask: 2 })
      }
    }
    if (this.indskr_datecompleted) requestBody['indskr_datecompleted'] = this.indskr_datecompleted;
    if (activityParties.length > 0) {
      requestBody['activityParty'] = activityParties;
    }
    let activityAccompaniedUsers: any[] = [];
    if (this.accompaniedUsersRaw) {
      this.accompaniedUsersRaw.map((e: AccompainedUser) => {
        let obj: object = { 'userId': e.id };
        activityAccompaniedUsers.push(obj);
      });
    }
    requestBody['activityAccompaniedUsers'] = activityAccompaniedUsers
    return requestBody;
  }
}


export class InitiatePhoneCallPayload {

  public scheduledstart: any;
  public scheduledend: any;
  public offlineId: string;
  public subject: string;
  public statecode: number;
  public accountPlanId?:string;

  constructor(
    scheduledstart: any,
    scheduledend: any,
    subject: string,
    offlineId: string,
    statecode: number,
    accountPlanId?:string
    ) {
    this.scheduledstart = scheduledstart;
    this.scheduledend = scheduledend;
    this.subject = subject;
    this.offlineId = offlineId;
    this.statecode = statecode;
    this.accountPlanId = accountPlanId
  }

  public getRequestBody() {
    return {
      scheduledstart: this.scheduledstart,
      scheduledend: this.scheduledend,
      subject: this.subject,
      offlineId: this.offlineId,
      statecode: this.statecode,
      accountPlanId : this.accountPlanId
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class PhoneCallDataService {
  rawNewPhoneCall: any;

  constructor(public http: HttpClient,
    public authService: AuthenticationService,
    private disk: DiskService,
    private events: Events,
    private uiService: UIService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService,
    private activityService: ActivityService,
    private logService: LogService,
    private errorHandler: GlobalErrorHandler,
    private globalUtility: GlobalUtilityService) {
  }

  // public async createPhoneCallActivity(payload: InitiatePhoneCallPayload, shouldWaitForMeetingCreationResponse = false): Promise<any> {
  public async createPhoneCallActivity(payload: InitiatePhoneCallPayload): Promise<any> {

    let newPhoneCall: PhoneActivity = undefined;
    this.rawNewPhoneCall = payload.getRequestBody();
    const reqBody = payload.getRequestBody();

    this.rawNewPhoneCall['indskr_positionid'] = this.authService.user.xPositionID;
    this.rawNewPhoneCall['statecode'] = 0;
    this.rawNewPhoneCall['statuscode'] = 1;
    this.rawNewPhoneCall['activityid'] = payload.offlineId;
    this.rawNewPhoneCall['indskr_ownerid'] = this.authenticationService.user.systemUserID;
    this.rawNewPhoneCall['indskr_ownerfullname'] = this.authenticationService.user.displayName;
    this.rawNewPhoneCall['activitytypecode'] = 'phonecall';
    // rawNewPhoneCall['eventId'] = payload.eventId;
    const activityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.PHONE_CALL);
    if (!_.isEmpty(activityTypes)) {
      const defaultActivityType = activityTypes.length == 1 && activityTypes[0].indskr_mandatory ? activityTypes[0] : activityTypes.find(at => at.indskr_default);
      if (!_.isEmpty(defaultActivityType)) {
        this.logService.logDebug("Creating meeting with activity type");
        reqBody["indskr_activitytype"] = this.rawNewPhoneCall["indskr_activitytype"] = defaultActivityType.indskr_activitytypeid;
        this.rawNewPhoneCall["activityTypeName"] = defaultActivityType.indskr_name;
        const activitySubTypes = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.PHONE_CALL, defaultActivityType.indskr_activitytypeid);
        if (!_.isEmpty(activitySubTypes)) {
          const defaultActivitySubType = activitySubTypes.length == 1 ? (activitySubTypes[0].indskr_mandatory ? activitySubTypes[0] : null) : activitySubTypes.find(ast => ast.indskr_default);
          if (!_.isEmpty(defaultActivitySubType)) {
            reqBody["indskr_activitysubtype"] = this.rawNewPhoneCall["indskr_activitysubtype"] = defaultActivitySubType.indskr_activitysubtypeid;
            this.rawNewPhoneCall["activitySubTypeName"] = defaultActivitySubType.indskr_name;
          }
        }
      }
    }

    if (this.deviceService.isNativeApp) {
      // App flow
      this.rawNewPhoneCall['indskr_isofflinemeeting'] = true;
      newPhoneCall = new PhoneActivity(this.rawNewPhoneCall);

      this.events.publish('insertNewActivity', newPhoneCall);
      if (this.deviceService.isOffline) {
        await this.activityService.upsertPhoneCallOfflineData(newPhoneCall);
      }

      // if (!shouldWaitForMeetingCreationResponse) {
      // Don't block UI for creation request
      // this._createNewPhoneCallRequest(reqBody)
      // .then((res: PhoneCallCreateResponseDTO) => payload.eventId ? this.msEventService.associateEvent(payload.eventId, res.activityid).then(() => res).catch(e => res) : res)
      // .then((res: PhoneCallCreateResponseDTO) => {
      //   // creation response is back from server.
      //   if (res) {
      //     this._handleMeetingCreationResponse(res, newPhoneCall);
      //   }
      // })
      // .catch(httpError => {
      //   console.error('createNewMeeting: ', httpError);
      // });
      // } else {
      // Wait for the response
      try {
        const response: PhoneCallCreateResponseDTO = await this._createNewPhoneCallRequest(reqBody);
        if (response) {
          await this._handlePhoneCallMeetingCreationResponse(response, newPhoneCall);
        }
      } catch (httpError) {
        console.error('createPhoneCallActivity: ', httpError);
      }
      // }
    }
    else {
      // Non app flow assuming no offline document as we're heading towards it
      try {
        // Blocing UI for non app flow
        const response: PhoneCallCreateResponseDTO = await this._createNewPhoneCallRequest(reqBody);

        if (response) {
          this.rawNewPhoneCall['activityid'] = response.activityid;
          this.rawNewPhoneCall['offlineId'] = response.offlineId;
          this.rawNewPhoneCall['lastUpdatedTime'] = new Date().getTime();
          this.rawNewPhoneCall['_id'] = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + response.activityid;

          newPhoneCall = new PhoneActivity(this.rawNewPhoneCall);
        }
      } catch (error) {
        console.log('createPhoneCalllActivity:', error)
      }
    }

    this.activityService.addActivity(newPhoneCall);
    return newPhoneCall;
  }

  private async _handlePhoneCallMeetingCreationResponse(res: PhoneCallCreateResponseDTO, activity: PhoneActivity, noAsyncFlow = false) {
    // Updates object and offline doc, and creates a meeting doc.
    if (res.activityid) {// && res.offlineId === activity.offlineMeetingId) {
      activity.ID = res.activityid;
      activity.lastUpdatedTime = new Date().getTime();
      activity.isOfflineMeeting = false;
      this.activityService.contactCenterContactInputs$.subscribe((value) => {
        if (value) {
          activity.contacts.push(value);
          this.activityService.selectedActivity = activity;
          // this.addContactsToMeeting(activity);
          this.events.publish("detectChangesOnActivityDetails"); // refresh meeting structure
          if (!this.uiService.toolsActivityActive) {
            this.events.publish("refreshAgenda");
          }
          else {
            this.uiService.agendaRefreshRequired = true;
          }
        }
      }).unsubscribe();
      this.activityService.contactCenterContactInputs$.next(null);

      const _id = activity._id = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + res.activityid;

      // Handle offline doc
      try {
        await this.disk.updateOrInsert('offlinePhoneCalls', (offlineMeetingsDoc: { meetings: any[] }) => {
          if (!offlineMeetingsDoc || !Array.isArray(offlineMeetingsDoc.meetings)) {
            console.warn('_handlePhoneCallMeetingCreationResponse: offline meeting document is empty..');
            offlineMeetingsDoc = { meetings: [] };
          } else {
            const idx = offlineMeetingsDoc.meetings.findIndex(m => m.offlineMeetingId === res.offlineId);
            if (idx >= 0) {
              const rawOfflineMeeting = offlineMeetingsDoc.meetings[idx];
              if (rawOfflineMeeting.hasOfflineChange) {
                // Something changed while waiting for the meeting creation response. Update offline doc and treat as offline doc
                rawOfflineMeeting['activityid'] = activity.ID;
                rawOfflineMeeting['lastUpdatedTime'] = activity.lastUpdatedTime;
                rawOfflineMeeting['indskr_isofflinemeeting'] = false;
              } else {
                // Nothing changed while waiting for the meeting creation response. Remove from offline doc
                this.activityService.deleteFromOfflinePhoneCallIds(activity.offlineMeetingId);
                offlineMeetingsDoc.meetings.splice(idx, 1);
              }
            } else {
              console.warn(`_handleMeetingCreationResponse: couldn't find ${res.offlineId} from offline document`);
            }
          }

          // Track offline data count
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PHONECALL_MEETING, offlineMeetingsDoc.meetings.length);
          return offlineMeetingsDoc;
        });
      } catch (error) {
        console.error('_handlePhoneCallMeetingCreationResponse: ', error);
      }

      // Create a meeting record
      try {
        await this.disk.createDocument(_id, activity.DTO);
      } catch (error) {
        console.error('_handlePhoneCallMeetingCreationResponse: createDocument: ', error);
      }

      // Stream to other pages
      // this.uiService.setUIServiceData({
      //   view: "new-activity", data: {}
      // });
    } else {
      console.warn(`_handleMeetingCreationResponse: offline meeting ID ${activity.offlineMeetingId} does not match with ${res.offlineId}`);
    }
  }

  private async _createNewPhoneCallRequest(reqBody): Promise<PhoneCallCreateResponseDTO> {
    if (this.deviceService.isOffline) {
      return;
    }
    let headers = new HttpHeaders();
    headers = headers
      .set('Sync-Service', 'true')
      .set('X-BusinessUnitId', this.authenticationService.user.xBusinessUnitId)
      .set("X-PositionId", this.authenticationService.user.xPositionID);
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.CREATE_PHONE_CALL_ACTIVITY;

    return await this.http.post<PhoneCallCreateResponseDTO>(url, reqBody, { headers }).toPromise();
  }

  public async getVoiceCallAgentToken() {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_VOICE_CALL_AGENT_TOKEN;
    const employeeId = this.authService.user.employeeId ? this.authService.user.employeeId.substring(1) : '';
    return await this.http.post(url, { username: "admin", cno: employeeId }).toPromise();
  }

  public async createCallActivity(phoneCallId: string, payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.CREATE_CALL_ACTIVITY;
    url = url.replace("{phoneCallId}", phoneCallId);
    return await this.http.post(url, payload).toPromise();
  }

  public async updateCallActivity(callActivityId: string, payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.UPDATE_CALL_ACTIVITY;
    url = url.replace("{callActivityId}", callActivityId);
    return await this.http.patch(url, payload).toPromise();
  }

  /**
     *
     * TO setup the payload details
     * @param {AppointmentActivity} activity
     *
     */
  setPhoneCallPayload(activity: PhoneActivity): UpdatePhoneCallPayload {
    let products: Array<any>;
    const phoneCallStartTime = new Date(activity.scheduledStart).getTime();
    const phoneCallEndTime = new Date(activity.scheduledEnd).getTime();
    const notes = activity.notes ? activity.notes : '';
    const contactId = (activity as PhoneActivity).contacts.length ? (activity as PhoneActivity).contacts[0].ID : '';
    products = ((activity as PhoneActivity).productsDTO && (activity as PhoneActivity).productsDTO.length) ? (activity as PhoneActivity).productsDTO : [];
    const stateCode = activity.state ? activity.state : 0;
    const activityid = activity.ID ? activity.ID : '';
    const offlineId = activity.offlineId ? activity.offlineId : '';
    const accompaniedUsers = activity.accompaniedUserList ? activity.accompaniedUserList : [];

    let selectedProducts = [];

    if (products) {
      products.map((product) => {
        if (product.indskr_productid !== undefined) {
          let keymessages = [];
          if (product.activityProductKeyMessages) {
            product.activityProductKeyMessages.map((keymsg) => {
              keymessages.push({
                indskr_keymessageid: keymsg.indskr_keymessageid,
              })
            })
          }

          selectedProducts.push({
            indskr_productid: product.indskr_productid,
            indskr_sequence: product.indskr_sequence,
            keymessages: keymessages
          })
        }
      })
    }

    const therapeuticareas = (activity as PhoneActivity).activityTherapeuticAreas.length ? (activity as PhoneActivity).activityTherapeuticAreas : [];
    let selectedTherapeuticareas = [];
    if (therapeuticareas) {
      therapeuticareas.map(therapeuticarea => {
        if (therapeuticarea.therapeuticareaId !== undefined) {
          selectedTherapeuticareas.push({
            indskr_TherapeuticArea: therapeuticarea.therapeuticareaId,
          })
        }
      })
    }
    const accounts = (activity as PhoneActivity).accounts.length ? (activity as PhoneActivity).accounts : [];
    let selectedAccount = [];
    if (accounts) {
      accounts.map(account => {
        selectedAccount.push({
          indskr_accountid: account.id || account.accountId,
        });
      });
    }

    const phoneCallPayLoad = new UpdatePhoneCallPayload(
      activity.subject,
      phoneCallStartTime,
      phoneCallEndTime,
      notes,
      contactId,
      selectedProducts,
      selectedTherapeuticareas,
      selectedAccount,
      accompaniedUsers,
      stateCode,
      offlineId,
      activityid,
    );
    return phoneCallPayLoad;
  }

  /**
   * Updates an activity with the payload details
   *
   * @param {AppointmentActivity} activity
   * @param {UpdatePhoneCallPayload} payload
   * @memberof MeetingDataService
   */
  public async updatePhoneCall(
    activity: PhoneActivity,
    payload: UpdatePhoneCallPayload
  ) {
    if (activity.selectedMobileNumber) {
      payload.phonenumber = activity.selectedMobileNumber
    }
    const hasOfflineData = this.activityService.hasOfflinePhoneCallData(activity.ID);

    // this.activityService.isActivityUpdate = true; //
    // We check if there's any existing offline data for this phonecall
    if (!this.deviceService.isOffline && !hasOfflineData) {
      // Normal flow
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.UPDATE_PHONE_CALL_ACTIVITY;

      url = url.replace("{activity_id}", activity.ID);
      try {
        let response = await this.http
          .patch(url, payload.getRequestBody())
          .toPromise();
        this.activityService.publishActivityEvent({action: "Update", activity: activity});
        this.logService.logDebug(response);
        this.events.publish('phoneCallActivityIsUpdated', activity);
        try {
          await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as PhoneActivity, true);
        } catch (error) {
          console.error('updatePhoneCall: ', error);
        }
      } catch (e) {
        this.errorHandler.handleError(e);
      }
    }
    else if (!this.deviceService.isOffline || hasOfflineData) {
      try {
        await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
      } catch (error) {
        console.error('updatePhoneCall: ', error);
      }
    }
  }

  public async updatePhoneCallDuration(activity: PhoneActivity, payload: any) {
    const hasOfflineData = this.activityService.hasOfflinePhoneCallData(activity.ID);
    if (!this.deviceService.isOffline && !hasOfflineData) {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.UPDATE_PHONE_CALL_DURATION;
      url = url.replace("{activity_id}", activity.ID);
      try {
        let response = await this.http.patch(url, payload).toPromise();
        this.activityService.publishActivityEvent({ action: "Update", activity: activity });
        this.logService.logDebug(response);
        this.events.publish('phoneCallActivityIsUpdated', activity);
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as PhoneActivity, true);
      } catch (e) {
        console.error('updatePhoneCall: ', e);
      }
    }
  }

  public async updateappConfigFields(
    activity: PhoneActivity,
    appConfigFields: Array<ConfiguredFields>,
    updateMeetingType: boolean = false
  ) {
    //hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);

    // this.activityService.isActivityUpdate = true; //
    // We check if there's any existing offline data for this meeting
    // if (!this.deviceService.isOffline) {
    // Normal flow

    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.UPDATE_APP_CONFIGFIELDS_TO_PHONE_CALL;

    url = url.replace("{activity_id}", activity.ID);

    let requestBody = {
      'appconfigfields': [...appConfigFields]
    };


    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );
    try {
      let response = await this.http
        .patch(url, requestBody, headers)
        .toPromise();
      this.activityService.publishActivityEvent({action: "Update", activity: activity});
      this.logService.logDebug(response);


    } catch (e) {
      this.errorHandler.handleError(e);
      if (updateMeetingType) {
        return Promise.reject(e);
      }
    }
    // }
    // else{
    //   this.activityService.appConfigFields(activity.ID);
    // }
  }

  // public async updateActivitySubActivityFields(
  //   activity: PhoneActivity,
  //   appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad,
  //   updateMeetingType: boolean = false
  // ) {
  //   //hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);

  //   this.activityService.isActivityUpdate = true; //
  //   // We check if there's any existing offline data for this meeting
  //  // if (!this.deviceService.isOffline) {
  //     // Normal flow

  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.UPDATE_APP_CONFIGFIELDS_TO_PHONE_CALL;

  //   url = url.replace("{activity_id}", activity.ID);

  //   let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
  //   headers.headers = headers.headers.set(
  //     "X-PositionId",
  //     this.authenticationService.user.xPositionID
  //   );
  //   try{
  //     let response = await this.http
  //     .patch(url, appconfiglookupfields.getRequestBody(), headers)
  //     .toPromise();

  //     this.logService.logDebug(response);


  //   }catch(e){
  //     this.errorHandler.handleError(e);
  //     if (updateMeetingType) {
  //       return Promise.reject(e);
  //     }
  //    }
  //  // }
  //   // else{
  //   //   this.activityService.appConfigFields(activity.ID);
  //   // }
  //  }

  public async updateActivitySubActivityFields(
    activity: PhoneActivity,
    appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad,
    updateMeetingType: boolean = false
  ) {
    const hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);

    //this.activityService.isActivityUpdate = true; //
    // We check if there's any existing offline data for this meeting
    if (!this.deviceService.isOffline && !hasOfflineData) {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.UPDATE_APP_CONFIGFIELDS_TO_PHONE_CALL;

      url = url.replace("{activity_id}", activity.ID);

      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        "X-PositionId",
        this.authenticationService.user.xPositionID
      );
      try {
        let response = await this.http
          .patch(url, appconfiglookupfields.getRequestBody(), headers)
          .toPromise();
        this.logService.logDebug(response);
      } catch (e) {
        this.errorHandler.handleError(e);
        if (updateMeetingType) {
          return Promise.reject(e);
        }
      }
    } else {
      try {
        await this.activityService.upsertPhoneCallOfflineData(activity as PhoneActivity);
      } catch (error) {
        console.error('updatePhonecall: ', error);
        if (updateMeetingType) {
          return Promise.reject(error);
        }
      }
    }
  }

  async saveMeeting(phoneCallActivity: PhoneActivity) {
    try {
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(phoneCallActivity, false, 'My');
    } catch (error) {
      console.error('saveMyTimeOff: ', error);
    }
  }

  async addContactsToPhoneCallActivity(phoneCall: PhoneActivity): Promise<boolean> {
    if (this.deviceService.isOffline || this.deviceService.isDeviceRealOffline ||
      this.activityService.hasOfflinePhoneCallData(phoneCall.ID) ||
      phoneCall.ID.includes('offline') || !(phoneCall instanceof PhoneActivity)) {
      return false;
    }
    // let subject: string = 'Phone Call';
    // let subPrefix = " - Phone Call";
    // switch (phoneCall.contacts.length) {
    //   case 0:
    //     break;
    //   case 1:
    //     subject = `${phoneCall.contacts[0].fullName}${subPrefix}`;
    //     break;
    //   default:
    //     if (phoneCall.contacts.length > 1) {
    //       phoneCall.contacts.sort((contactA, contactB): number => {
    //         let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
    //         let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
    //         if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
    //         if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

    //         return 0;
    //       });
    //       subject = `${phoneCall.contacts[0].fullName} + ${phoneCall.contacts.length - 1}${subPrefix}`;
    //     }
    // }
    // this.activityService.selectedActivity.subject = subject;

    // setting payload
    const phoneCallStartTime = new Date(this.activityService.selectedActivity.scheduledStart).getTime();
    const phoneCallEndTime = new Date(this.activityService.selectedActivity.scheduledEnd).getTime();
    const notes = '';
    const contactId = phoneCall.contacts.length ? phoneCall.contacts[0].ID : '';
    let phoneCallPayLoad = new UpdatePhoneCallPayload(
      phoneCall.subject,
      phoneCallStartTime,
      phoneCallEndTime,
      notes,
      contactId
    );
    //this.activityService.getActivityByID(phoneCall.ID).subject = subject;
    // this.activityService.isActivityUpdate = true;
    if (!this.deviceService.isOffline &&
      !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
      await this.updatePhoneCall(phoneCall, phoneCallPayLoad); //Update the activity subject if auto subject feature action is enabled
    }
    else {
        await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
    }
  }
  public getActivityStateCode(status: PhonecallStatus) {
    switch (status) {
      case PhonecallStatus.OPEN:
        return 0;
      case PhonecallStatus.COMPLETED:
        return 1;
      case PhonecallStatus.CANCELED:
        return 2;
      default:
        return 0;
    }
  }

  public async updatePhonecallActivityStatus(activity: PhoneActivity, status: PhonecallStatus, dateCompleted?: number) {

    const stateCode = this.getActivityStateCode(status);
    activity.state = stateCode;

    if (this.deviceService.isOffline || this.activityService.hasOfflinePhoneCallData(activity.ID)) {
      try {
        const idx = this.activityService.activities.findIndex(item => item.ID === activity.ID);

        this.activityService.activities[idx].state = stateCode;
        if (dateCompleted) {
          this.activityService.activities[idx].omnip_datecompleted = dateCompleted.toString();
          activity.omnip_datecompleted = activity.indskr_datecompleted = dateCompleted.toString();
        }

        await this.activityService.upsertPhoneCallOfflineData(activity);
        console.log('updatephonecall offline update DB success: ');
        if (PhonecallStatus.COMPLETED === status) {
          await this.globalUtility.updateInteraction(activity.contacts, activity.accounts, 'Phonecall');
        }
        return Promise.resolve();
      }
      catch (err) {
        return Promise.reject(err);
      }

    } else {
      let payload: UpdatePhoneCallPayload = this.setPhoneCallPayload(activity);
      if (dateCompleted) {
        payload.indskr_datecompleted = dateCompleted.toString();
      }
      await this.updatePhoneCall(activity, payload);

      // ----
      // const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      //   Endpoints.phonecall.UPDATE_PHONE_CALL_ACTIVITY.replace("{activity_id}", activity.ID);

      // const requestBody = { stateCode: status, omnip_datecompleted: dateCompleted };

      try {

        // await this.http.put(url, requestBody, Endpoints.headers.content_type.json).toPromise();
        if (PhonecallStatus.COMPLETED === status) {
          await this.globalUtility.updateInteraction(activity.contacts, activity.accounts, 'Meetings');
        }
        const idx = this.activityService.activities.findIndex(item => item.ID === activity.ID);

        this.activityService.activities[idx].state = stateCode;     //update activities array
        if (dateCompleted) {
          this.activityService.activities[idx].omnip_datecompleted = dateCompleted.toString();
          activity.omnip_datecompleted = activity.indskr_datecompleted = dateCompleted.toString();
        }
        console.log('PhonecallStatus: update service success: ');
        try {
          activity.state = stateCode;
          await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
          console.log('PhonecallStatus: update DB success: ');
        } catch (error) {
          console.warn('PhonecallStatus: db update error: ', error);
          // TODO
        }
        return Promise.resolve();
      }
      catch (err) {
        return Promise.reject(err);
      }
    }
  }

  public handleOfflinePushResponse(rawResponse: any): Promise<any> {
    return new Promise(async (resolve, reject) => {

    });
  }

  public async getVoiceCallRecordLink(uniqueId: string) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.GET_VOICE_CALL_RECORD_LINK;
    url += `?mainUniqueId=${uniqueId}`;
    return await this.http.get(url).toPromise();
  }

  public async getVoiceCallRecordLinks(uniqueIds: string) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.GET_VOICE_CALL_RECORD_LINKS;
    url += `?mainUniqueIds=${uniqueIds}`;
    return await this.http.get(url).toPromise();
  }

  public async getVoiceCallRecord(uniqueId: string) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.GET_VOICE_CALL_RECORD;
    url += `?mainUniqueId=${uniqueId}`;
    return await this.http.get(url).toPromise();
  }

  async getActiveConsent(contactId: string, consentType: string) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.GET_ACTIVE_CONSENT;
    url = url.replace('{contactId}', contactId);
    url = url.replace('{consentType}', consentType);
    const response: any = await this.http.get(url).toPromise();
    return response && response.indskr_consentid ? true : false;
  }

}
