<ion-header>
  <ion-toolbar [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="onCloseModal(false)">
          {{ 'BACK' | translate}}
      </ion-button>
    </ion-buttons>
    <ion-title text-center>{{ 'CONTACT_PARTICIPENTS' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content no-padding>
  <ion-item-group [ngClass]="{'selectedContactContainer':$any(activityService.selectedActivity)?.contacts?.length>0}">

    <ion-item-divider color="light" *ngIf="(contactListMode===compViewMode.ADDNEW || contactListMode===compViewMode.READONLY) && $any(activityService.selectedActivity)?.contacts.length>0">{{ 'SELECTED_CAP' | translate}}</ion-item-divider>
    <ion-list *ngIf="contactListMode===compViewMode.ADDNEW || contactListMode===compViewMode.READONLY">
      <ion-item class='selector-item' *ngFor="let selContact of $any(activityService.selectedActivity)?.contacts; let i = index;">
        {{selContact.firstName}} {{selContact.lastName}}
        <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" *ngIf="contactListMode === compViewMode.ADDNEW" slot="end"></ion-icon>
      </ion-item>
    </ion-list>
  </ion-item-group>
</ion-content>
