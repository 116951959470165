import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the MonthTranslatePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'monthTranslate',
})
export class MonthTranslatePipe implements PipeTransform {
  constructor(private translate : TranslateService) {}
  transform(value: string[], ...args) {
    let monthArry = [];
    value.forEach(element => {
          let month = this.translate.instant(element);
         monthArry.push(month);
    }); 
    return monthArry;
  }
}
