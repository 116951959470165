import { EmailTemplateDetailsComponent } from './../../email/email-template-details/email-template-details';
import { ResourceEmailTemplate } from '@omni/classes/email-templates/email-template.class';
import { takeUntil } from 'rxjs/operators';
import { EmailService } from '@omni/services/email-templates/email.service';
import { IonNav } from '@ionic/angular';
import { Subject } from 'rxjs';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NavigationService, ChildNavNames, PageName } from '@omni/services/navigation/navigation.service';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'calendar-invite-template-page[base-page]',
  templateUrl: 'calendar-invite-template-page.html',
  styleUrls: ['../../email/email-template-page/email-template-page.scss']
})
export class CalendarInviteTemplatePageComponent implements OnInit, OnDestroy {
  @ViewChild('rightPane', { static: true }) navCtrl: IonNav
  template: ResourceEmailTemplate;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showNothingSelectedView = true;

  constructor(
    public navService: NavigationService,
    public deviceService: DeviceService,
    private footerService: FooterService,
    private emailService: EmailService,
  ) {}

  ngOnInit() {
    this.navService.initRightPaneChildNav(
      this.navCtrl,
      ChildNavNames.CalendarInviteTemplateNavigation,
      PageName.CalendarInviteTemplatePageComponent,
      false,
      { deviceString: this.deviceService.deviceFlags.ios ? 'ios' : 'other' }
    );
    this.footerService.initButtons('');

    this.emailService.reset();
    this.emailService.selectedTemplate
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(template => {
        if (template) {
          this.showNothingSelectedView = false;
          this.template = template;
          this.goToDetails();
        } else {
          this.showNothingSelectedView = true;
        }
      });
  }
  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.CalendarInviteTemplateNavigation);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private goToDetails() {
    this.navService.setChildNavRoot(
      EmailTemplateDetailsComponent,
      PageName.EmailTemplateDetailsComponent,
      PageName.CalendarInviteTemplatePageComponent,
      { template: this.template }
    );
    this.navService.setChildNavRightPaneView(true);
  }
}
