import { Observable } from 'rxjs';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';

const DefaultWidth = '30%';
const DefaultLineHeight = '24px';

@Component({
  selector: 'ind-section-header',
  templateUrl: 'ind-section-header.html',
  styleUrls:['ind-section-header.scss']
})
export class IndSectionHeaderComponent {

  public defaultWidth = DefaultWidth;
  public defaultLineHeight = DefaultLineHeight;

  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: { headerSkeletonTextWidth: string,
                              headerSkeletonTextLineHeight: string }
            = { headerSkeletonTextWidth: DefaultWidth, headerSkeletonTextLineHeight: DefaultLineHeight };
  @Input() viewData: IndSectionHeaderViewDataModel;
  @Input() loader: Observable<number>;

  @Output() onControlClick = new EventEmitter<string>();
  @Output() onFilterClick = new EventEmitter<string>();
  @Output() onControlClickWithEventBubbleUp = new EventEmitter<{ button: any, event: any, buttonElRef: any }>();

  constructor(){
  }

  public onButtonClick(button, event, buttonElRef){
    if (button.bubbleUpEvent) {
      this.onControlClickWithEventBubbleUp.emit({ button, event, buttonElRef });
    } else if(button.id && !button.isDisabled){
        this.onControlClick.emit(button.id);
    }
  }

  public filterClicked(ev){
    if(this.viewData.isFilter){
      this.onFilterClick.emit(ev);
    }
  }

}
