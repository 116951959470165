import { SearchConfigService } from './../search/search-config.service';
import { Injectable } from "@angular/core";
import {
  DeltaService,
  EntityNames,
  EntitySyncInfo
} from "../../data-services/delta/delta.service";
import { NavigationService } from "../navigation/navigation.service";
import { AuthenticationService } from "../authentication.service";
import { EventsService } from "../events/events.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../disk/disk.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import {
  DB_ALLDOCS_QUERY_OPTIONS,
  DB_KEY_PREFIXES,
  DB_SYNC_STATE_KEYS
} from "../../config/pouch-db.config";
import { Endpoints } from "../../../config/endpoints.config";
import { ConsentDataService } from "../../data-services/consent/consent.data.service";
import { ContactConsent } from "../../classes/consent/contact-consent.class";
import { BehaviorSubject } from "rxjs";
import { ConsentTerm } from "../../classes/consent/consent-term.class";
import {Channel, ChannelType, ChannelValue, ChannelActivityType, BusinessUnitChannel} from "../../classes/consent/channel.class";
import { Brand } from "../../classes/brand/brand.class";
import { DeviceService } from "../device/device.service";
import {TrackAction} from "../../utility/common-enums";
import {NotificationService, ToastStyle} from "../notification/notification.service";
import {Contact, ContactAddress, ContactDTO, Email} from "../../classes/contact/contact.class";
import {ContactDataService} from "../../data-services/contact/contact.data.service";
import {LoadingController} from "@ionic/angular";
import {ContactOfflineService} from "../contact/contact.service";
import { TranslateService } from "@ngx-translate/core";
import _ from 'lodash';
import { isWithinRange, startOfDay, endOfDay, isFuture, isPast, differenceInHours } from "date-fns";
import {ConsentedContact} from "../../classes/activity/email.activity.class";
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { ConsentActivity } from '@omni/classes/consent/consent-activitiy.class';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  public allActiveConsentSubject = new BehaviorSubject<ContactConsent[]>([]);
  public allActiveConsentSubject$ = this.allActiveConsentSubject.asObservable();
  public allConsentChannelSubject = new BehaviorSubject<Channel[]>([]);
  public allConsentTermsSubject = new BehaviorSubject<ConsentTerm[]>([]);
  public allConsentTermsAllocationOrdersSubject = new BehaviorSubject<ConsentTerm[]>([]);
  public showAllConsentsFilter$ = new BehaviorSubject<boolean>(false);
  public showAllConsentsFilter: string = 'All';
  public showAllConsentFilterObserver$ = this.showAllConsentsFilter$.asObservable();
  public selectedConsentTerm = new BehaviorSubject<ConsentTerm>(null);
  public selectedCTObserver$ = this.selectedConsentTerm.asObservable();
  public currentConsentTerm = new BehaviorSubject<ConsentTerm>(null);
  public currentCTObserver$ = this.currentConsentTerm.asObservable();
  public consentProductFilter$: Brand;
  public consentChannelFilter$: Channel;
  public isProductFilterExpanded: boolean;
  public isChannelFilterExpanded: boolean;
  public consentTab: string = "";
  public allGeneratedTerms = new BehaviorSubject<ConsentTerm[]>([]);
  public allGeneratedTermsObs$ = this.allGeneratedTerms.asObservable();
  public savedConsents: ConsentTerm[];
  public savedChannels: Channel[];
  inMeetingConsentFlow: boolean = false;
  consentRemoteFlow: boolean = false;
  allocationRemoteFlow: boolean = false;
  public businessunitChannels = []
  public allConsentActivitiesWithProductsAndEmails = []

  constructor(
    private navService: NavigationService,
    private disk: DiskService,
    private authService: AuthenticationService,
    private events: EventsService,
    private deltaService: DeltaService,
    private consentDataService: ConsentDataService,
    private deviceService: DeviceService,
    public notificationService: NotificationService,
    private contactDataService: ContactDataService,
    public contactService: ContactOfflineService,
    private loadingCtrl: LoadingController,
    private translate: TranslateService,
    public searchConfig: SearchConfigService,
    private dynamics: DynamicsClientService,
  ) {
    this.events.observe("sync:completed").subscribe(() => {
      this.loadAndMapConsentsMasterDataFromDB();
    });
  }

  public async loadAndMapConsentsMasterDataFromDB() {
    await this.disk
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_CHANNELS)
      .then((data: Channel[]) => {
        this.allConsentChannelSubject.next(data);
        this.savedChannels = data;
        console.log(
          `active consent channels from disk : ${data ? data.length : 0}`
        );
      });
    if (this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL)) {
      await this.disk
        .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS)
        .then((data: ContactConsent[]) => {
          if (data) {
            data.forEach(cct => {
              if (Array.isArray(cct.activeConsents)) {
                cct.activeConsents.forEach(term => {
                  term.isOfflineSaved = false;
                  term.isSelectedTerm = false;
                });
              }
            });
          }
          this.allActiveConsentSubject.next(data);
          // console.log(`active consents from disk : ${data ? data.length : 0}`);
        });

      await this.disk
        .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_TERMS)
        .then((data: ConsentTerm[]) => {
          this.allConsentTermsSubject.next(data);
          console.log(
            `active consent terms from disk : ${data ? data.length : 0}`
          );
        });

      await this.disk
        .retrieve(DB_KEY_PREFIXES.BUSINESS_UNIT_CHANNELS)
        .then((doc) => {
          if (doc && doc.raw && doc.raw.length && this.businessunitChannels.length == 0) {
            this.businessunitChannels = doc.raw;
            console.log(`all business unit consents from disk : ${this.businessunitChannels ? this.businessunitChannels.length : 0}`);
          }
        });

      await this.disk
        .retrieve(DB_KEY_PREFIXES.CONSENT_ACTIVITIES)
        .then((doc) => {
          if (doc && doc.raw && doc.raw.length && this.allConsentActivitiesWithProductsAndEmails.length == 0) {
            this.allConsentActivitiesWithProductsAndEmails = doc.raw;
            console.log(`all consent activities with products and emails from disk : ${this.allConsentActivitiesWithProductsAndEmails ? this.allConsentActivitiesWithProductsAndEmails.length : 0}`);
          }
        });
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL) || this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ORDER_ACTIVITY)) {
      await this.disk
        .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_TERMS_ALLOCATION_ORDERS)
        .then((data: ConsentTerm[]) => {
          this.allConsentTermsAllocationOrdersSubject.next(data);
          console.log(
            `active consent terms for allocation orders from disk : ${data ? data.length : 0}`
          );
        });
    }
  }

  public async getAllActiveConsentsMasterData(forceFullSync, loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      await this.loadAndMapConsentsMasterDataFromDB();
    } else {
      await this.getAllActiveChannels();
      await this.getAllBusinessUnitChannelMappings(loadFromDbOnly);
      if (this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL)) {
        await this.getAllActiveConsents();
        await this.getAllConsentTerms();
        await this.getAllConsentActivitiesForProductsAndEmails(loadFromDbOnly);
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)
        || this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ORDER_ACTIVITY)
      ) {
        await this.getAllConsentTermsForAllocationOrders();
      }
      this.searchConfig.isConsentsMappedToContacts = false;
    }
  }

  private async getAllActiveConsents(forceFullSync = false) {
    if(forceFullSync) {
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS);
    }
    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_CONSENTS
    );
    const isInitialSync = !syncState || !syncState.lastUpdatedTime || forceFullSync;
    const newLastUpdatedTime = new Date().getTime();
    const consentSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.consent,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    await this.consentDataService
      .getActiveConsents(isInitialSync, syncState.lastUpdatedTime)
      .then(async (data: ContactConsent[]) => {
        console.log("Saving active consents to disk...");
        try {
          await this.saveConsentsToDisk(
            isInitialSync,
            DB_KEY_PREFIXES.CONSENTS,
            data
          );
        } catch (error) {
          // For now, only consider the service failure as sync failure..
          console.error('getAllActiveConsents: ', error);
        }

        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          consentSyncInfo.totalSynced = data.length;
        }
        console.log(
          `Sync status : ${JSON.stringify(
            syncState
          )} initial sync : ${isInitialSync}`
        );
        this.deltaService.addEntitySyncInfo(consentSyncInfo);
      })
      .catch(err => {
        console.error("Error occurred while fetching active consents...", err);
        this.deltaService.addSyncErrorToEntitySyncInfo(
          consentSyncInfo,
          this.authService.userConfig.activeInstance.entryPointUrl +
            Endpoints.consent.GET_ACTIVE_CONSENTS,
          err
        );
        this.deltaService.addEntitySyncInfo(consentSyncInfo);
      });
  }

  private async getAllActiveConsentsByContactId(contactId:string) {
    await this.consentDataService.getActiveConsentsByContactId(contactId)
      .then((data: ContactConsent[]) => {
        console.log("Saving active consents to disk...");
        try {
          if(!_.isEmpty(data)) {
            // set initial sync true since we get all consents for the customer again
            this.saveConsentsToDisk(
              true,
              DB_KEY_PREFIXES.CONSENTS,
              data
            );
          }          
        } catch (error) {
          console.error('getAllActiveConsentsById: ', error);
        }       
      })
  }


   public async getAllBusinessUnitChannelMappings(fullSync = false, loadFromDBOnly = false) {

    let lastModifiedForDeltaSync, hourDifference;

    await this.disk.retrieve(DB_KEY_PREFIXES.BUSINESS_UNIT_CHANNELS).then((doc)=>{
      if(doc && doc.raw && doc.raw.length){
        this.businessunitChannels = doc.raw;
        lastModifiedForDeltaSync = doc.lastUpdatedTime;
      }
      else{
        this.businessunitChannels = [];
        fullSync = true;
      }
    });

    if (loadFromDBOnly) return;
    let deltaSyncFilter = "";
    let fetchXML = fetchQueries.businessUnitChannels;
    let now = new Date();

    if (lastModifiedForDeltaSync) {
      hourDifference = differenceInHours(
        now,
        new Date(lastModifiedForDeltaSync)
      )
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1
      deltaSyncFilter = `<filter type="and">
                            <condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />
                          </filter>`
    }

    fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);

    let arrDeletedMappings = []

    await this.dynamics.executeFetchQuery('indskr_businessunitchannels', fetchXML).then(async (data)=>{
      if(!fullSync) {
        let deletedMappings = await this.fetchDeletedBusinessunitChannelmappings(lastModifiedForDeltaSync);
        deletedMappings.sort((a,b)=>{
          if(a['track_action_CreatedOn']>b['track_action_CreatedOn']) return 1;
          else return -1;
        });

        arrDeletedMappings = deletedMappings;
      }

      this.aggregateBusinessunitChannels(data, arrDeletedMappings);

      console.log(data);
      await this.updateOrInsertBUChannelMappings();
    }, (errror)=>console.log(errror));

  }

  private async fetchDeletedBusinessunitChannelmappings(lastModifiedDate){
    let hourDifference;
    let now = new Date();
    let deletedRecords;
    let fetchXML = fetchQueries.deletedBusinessunitChannelMappings;
    hourDifference = differenceInHours(
      now,
      new Date(lastModifiedDate)
    )
    //add one to make sure we take care of fractional difference in hours
    hourDifference += 1
    fetchXML = fetchXML.replace('{hourDifference}',hourDifference);
    await this.dynamics.executeFetchQuery('indskr_trackchanges', fetchXML).then((data)=>{
      console.log('deleted bu channels Mapping',data);
      deletedRecords = data;
    })
    return deletedRecords;
  }

  public aggregateBusinessunitChannels(rawData: any, arrDeletedMappings) {

    if (!_.isEmpty(rawData)) {

      rawData.map((businessUnitChannel) => {

        if (businessUnitChannel && businessUnitChannel.indskr_businessunitchannelid) {

          let index = this.businessunitChannels.findIndex(o => o.indskr_businessunitchannelid == businessUnitChannel['indskr_businessunitchannelid']);

          let buChannel = new BusinessUnitChannel(businessUnitChannel);

          if (index >= 0) {
            this.businessunitChannels[index] = buChannel;
          } else {
            this.businessunitChannels.push(buChannel);
          }
        }
      });

      arrDeletedMappings.map((o) => {

        let foundIndex = this.businessunitChannels.findIndex(item => item.indskr_businessunitchannelid === o.indskr_businessunitchannelid);
        if (foundIndex >= 0)this.businessunitChannels.splice(foundIndex, 1);
      });

    }
  }

  private updateOrInsertBUChannelMappings() {
    this.disk.updateOrInsert(DB_KEY_PREFIXES.BUSINESS_UNIT_CHANNELS, (doc) => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        };
      }
      doc.raw = this.businessunitChannels;
      doc.lastUpdatedTime = new Date().getTime();
      return doc;
    });
  }

  private async getAllActiveChannels() {
    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_CONSENT_CHANNELS
    );
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const consentChannelSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.channel,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    await this.consentDataService
      .getConsentChannels(isInitialSync, syncState.lastUpdatedTime)
      .then(async (data: Channel[]) => {
        console.log("Saving active consents to disk...");
        //OMNI-1519
        if(data && data.length > 0) data = data.filter(dt => (dt.indskr_displayasconsentchannel && dt.indskr_displayasconsentchannel === true) || (dt.indskr_externalchannel && dt.indskr_externalchannel === true));
        this.savedChannels = data;
        await this.saveConsentChannelsToDisk(
          isInitialSync,
          DB_KEY_PREFIXES.CHANNELS,
          data
        );
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          consentChannelSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(consentChannelSyncInfo);
        console.log(
          `Sync status : ${JSON.stringify(
            syncState
          )} initial sync : ${isInitialSync}`
        );
      })
      .catch(err => {
        console.error(
          "Error occurred while fetching active consent channels...", err
        );
        this.deltaService.addSyncErrorToEntitySyncInfo(
          consentChannelSyncInfo,
          this.authService.userConfig.activeInstance.entryPointUrl +
            Endpoints.consent.GET_CONSENT_CHANNELS,
          err
        );
        this.deltaService.addEntitySyncInfo(consentChannelSyncInfo);
      });
  }

  private async getAllConsentTerms() {
    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_CONSENT_TERMS
    );
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const consentSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.consentTerm,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    await this.consentDataService
      .getConsentTerms(isInitialSync, syncState.lastUpdatedTime)
      .then(data => data || [])
      .then(async (data: ConsentTerm[]) => {
        console.log("Saving active consents to disk...");
        this.updateTermChannels(data);
        await this.saveConsentTermsToDisk(
          isInitialSync,
          DB_KEY_PREFIXES.CONSENT_TERMS,
          data
        );
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          consentSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(consentSyncInfo);
        console.log(
          `Sync status : ${JSON.stringify(
            syncState
          )} initial sync : ${isInitialSync}`
        );
      })
      .catch(err => {
        console.error("Error occurred while fetching active consents...", err);
        this.deltaService.addSyncErrorToEntitySyncInfo(
          consentSyncInfo,
          this.authService.userConfig.activeInstance.entryPointUrl +
            Endpoints.consent.GET_ACTIVE_CONSENTS,
          err
        );
        this.deltaService.addEntitySyncInfo(consentSyncInfo);
      });
  }

  public async getAllConsentActivitiesForProductsAndEmails(isFullSync, loadFromDbOnly = false) {
    console.log(`getAllConsentActivitiesForProductsAndEmails`);

    let offlineDocs;
    let lastModifiedForDeltaSync, hourDifference;
    let now = new Date();

    await this.disk.retrieve(DB_KEY_PREFIXES.CONSENT_ACTIVITIES, true).then((doc)=>{
        offlineDocs = doc;
        if(doc && doc.raw){
          this.allConsentActivitiesWithProductsAndEmails = doc.raw
        }
        else {
          this.allConsentActivitiesWithProductsAndEmails = [];
          isFullSync = true;
        }
    });

    if(!loadFromDbOnly) {
      let fetchXML = fetchQueries.fetchAllConsentActivitiesForProductsAndEmails;
      if(isFullSync) {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      else {
        let deltaSyncFilter = `
          <filter type="and"> 
            <condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />
          </filter> 
        `;
        lastModifiedForDeltaSync = offlineDocs ? offlineDocs.lastDeltaSync : null;
        if(lastModifiedForDeltaSync){
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
          deltaSyncFilter = deltaSyncFilter.replace('{lastXHours}', hourDifference);
        }
        else deltaSyncFilter = ''
        fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
      }

      try {
        let response = await this.dynamics.executeFetchQuery('indskr_consentactivities', fetchXML);
        console.warn(`getAllConsentActivitiesForProductsAndEmails: executeFetchQuery`);
        console.log(response);

        if(!_.isEmpty(response)){
          const filtered = _.map(response,_.partialRight(_.pick, ['consentActivityId','offlineId', 'contactId','consentTypeId','indskr_type','indskr_optinsource', 'createdon','indskr_activitydate', '_createdby_value', 'indskr_name',
          'consentTermId', 'consentTermName', 'emailAddress', 'indskr_productname','indskr_phone','indskr_addressname','indskr_consentactivitysourcetype']));

          if (!_.isEmpty(this.allConsentActivitiesWithProductsAndEmails)) {
            await filtered.forEach(async (item) => {
              let existingConsentActivityIdx = this.allConsentActivitiesWithProductsAndEmails.findIndex(element => element['consentActivityId'] == item['consentActivityId']);
           
              if(existingConsentActivityIdx>-1) {
                this.allConsentActivitiesWithProductsAndEmails[existingConsentActivityIdx] = item;
              } else {
                this.allConsentActivitiesWithProductsAndEmails.push(item);
              }
            })
          }
          else {
            this.allConsentActivitiesWithProductsAndEmails = filtered;
          }

          this.disk.updateOrInsert(DB_KEY_PREFIXES.CONSENT_ACTIVITIES, (doc)=>{
            doc = {
              raw: this.allConsentActivitiesWithProductsAndEmails,
              lastDeltaSync: new Date().getTime()
            };
            return doc;
          })
        }
      } catch(error) {
        console.log("Error fetching all consent activities with products and emails: ", error)
      }
    }
  }

  public async getProductsWithConsentsByContactId() {
    if (
      this.deviceService.isOffline
      || !this.contactService.contactInformation
    ) return;

    console.log(`getProductsWithConsentsByContactId`);

    let offlineDocs;
    let lastModifiedForDeltaSync, hourDifference;
    let now = new Date();

    await this.disk.retrieve(DB_KEY_PREFIXES.CONSENT_ACTIVITIES, true).then((doc)=>{
      offlineDocs = doc;
    });

    let fetchXML = fetchQueries.consentActivitiesByContactId;
    fetchXML = fetchXML.replace('{contactId}', `${this.contactService.contactInformation.ID.toString()}`);

    lastModifiedForDeltaSync = offlineDocs ? offlineDocs.lastDeltaSync : null;
    if(lastModifiedForDeltaSync){
      hourDifference = differenceInHours(
        now,
        new Date(lastModifiedForDeltaSync)
      )
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1;
      fetchXML = fetchXML.replace('{lastXHours}', hourDifference);
    } else   
    fetchXML = fetchXML.replace('{lastXHours}', "3");

    try {
      let response = await this.dynamics.executeFetchQuery('indskr_consentactivities', fetchXML);
      console.warn(`getProductsWithConsentsByContactId: executeFetchQuery`);
      console.log(response);

      if(!_.isEmpty(response)){
        const filtered = _.map(response,_.partialRight(_.pick, ['consentActivityId','contactId',
        'consentTermId', 'emailAddress', 'indskr_productname']));

        if (!_.isEmpty(this.allConsentActivitiesWithProductsAndEmails)) {
          await filtered.forEach(async (item) => {
            let existingConsentActivity = this.allConsentActivitiesWithProductsAndEmails.find(element => element['consentActivityId'] == item['consentActivityId']);
          
            if(!existingConsentActivity) {
              this.allConsentActivitiesWithProductsAndEmails.push(item);
            }
          })
        }
        else {
          this.allConsentActivitiesWithProductsAndEmails = filtered;
        }

        this.disk.updateOrInsert(DB_KEY_PREFIXES.CONSENT_ACTIVITIES, (doc)=>{
          doc = {
            raw: this.allConsentActivitiesWithProductsAndEmails,
            lastDeltaSync: new Date().getTime()
          };
          return doc;
        })
      }
    } catch(error) {
      console.log("Error fetching consent activities by contactId: ", error)
    }
  }

  public async getConsentsByContactIdAndConsentTypes(contactId: string, consentTypes = []) {
    console.log(`getConsentsByContactIdAndConsentTypes`);
    let fetchXML = fetchQueries.fetchConsentsByContactIdAndTypes;
    fetchXML = fetchXML.replace('{contactId}', contactId);
    let consentTypeFilter = '';
    consentTypes.forEach(ct => { consentTypeFilter += '<value>' + ct + '</value>' });
    fetchXML = fetchXML.replace('{consentTypes}', consentTypeFilter);
    try {
      const consents = await this.dynamics.executeFetchQuery('indskr_consents', fetchXML);
      console.log(consents);
      return consents;
    } catch (error) {
      console.log("Error fetching consent activities by contactId: ", error)
    }
  }

  private async getAllConsentTermsForAllocationOrders() {
    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_CONSENT_TERMS_ALLOCATION_ORDERS
    );
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const consentSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.consentTerm,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    await this.consentDataService
      .getConsentTermsForAllocationOrders(isInitialSync, syncState.lastUpdatedTime)
      .then(data => data || [])
      .then(async (data: ConsentTerm[]) => {
        console.log("Saving active consents for allocation orders to disk...");
        // this.updateTermChannels(data);
        await this.saveConsentTermsToDisk(
          isInitialSync,
          DB_KEY_PREFIXES.CONSENT_TERMS_ALLOCATION_ORDERS,
          data
        );
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          consentSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(consentSyncInfo);
        console.log(
          `Sync status : ${JSON.stringify(
            syncState
          )} initial sync : ${isInitialSync}`
        );
      })
      .catch(err => {
        console.error("Error occurred while fetching active consents for allocation orders...", err);
        this.deltaService.addSyncErrorToEntitySyncInfo(
          consentSyncInfo,
          this.authService.userConfig.activeInstance.entryPointUrl +
            Endpoints.consent.GET_ACTIVE_CONSENTS,
          err
        );
        this.deltaService.addEntitySyncInfo(consentSyncInfo);
      });
  }

  updateTermChannels(data: ConsentTerm[]){
    data.forEach((term: ConsentTerm) => {
      let channels:Channel[] = [];
      if (term.channels) {
        term.channels.forEach(tch => {
          let ch: Channel = this.savedChannels.find(sch => sch.indskr_consenttypeid === tch.indskr_consenttypeid);
          if (ch) channels.push(ch);
        });
      }
      term.channels = channels;
    });
    console.log(data);
  }

  private saveConsentsToDisk(isInitialSync, dbKey, data: ContactConsent[]) {
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((consent: ContactConsent) => {
        consent._id = dbKey + consent.indskr_contactid;
        if (Array.isArray(consent.activeConsents) && consent.activeConsents.length > 0) {
          consent.activeConsents.forEach((term: ConsentTerm) => {

            term.createdon = parseFloat(term.createdon.toString());
            term.indskr_fromdate = parseFloat(
              term.indskr_fromdate.toString()
            );
            term.indskr_untildate = parseFloat(
              term.indskr_untildate.toString()
            );
            term.isSavedConsent = true;
            term.isAcceptedTerms = true;
            term.isExpanded = false;
            if (term.products && term.products.length) {
              term.products.forEach(product => {
                product.isChecked = true;
              });
            }
            if (term.channels && term.channels.length) {
              term.channels.forEach(channel => {
                channel.isChecked = true;
                if (channel.values.length) {
                  channel.values.forEach(value => {
                    value.isChecked = true;
                  });
                }
              });
            }

            if (term.track_action === TrackAction.Deleted || !this.isTermExpired(term)) {
              this.disk.retrieve(consent._id.toString()).then((conConsent: ContactConsent) => {
                if (conConsent) {
                  let index = conConsent.activeConsents.findIndex(
                    (cT: ConsentTerm) => {
                      return cT.indskr_consenttermsid === term.indskr_consenttermsid;
                    });
                  if (index !== -1) {
                    conConsent.activeConsents.splice(index, 1);
                    this.disk.updateDocWithIdAndRev(conConsent);
                  }
                }
              });
            }
            else if (term.track_action === TrackAction.Download) {
              this.disk.retrieve(consent._id.toString()).then((conConsent: ContactConsent) => {
                if (conConsent) {
                  let index = conConsent.activeConsents.findIndex(
                    (cT: ConsentTerm) => {
                      return cT.indskr_consenttermsid === term.indskr_consenttermsid;
                    });
                  if (index === -1) {
                    conConsent.activeConsents.push(term);
                    this.disk.updateDocWithIdAndRev(conConsent);
                  }
                }
              });
            }
          });
        }
        if (!isInitialSync) {
            this.disk.retrieve(consent._id.toString()).then((conConsent: ContactConsent) => {
                if(!conConsent) {
                  this.disk.updateOrInsert(consent._id.toString(), doc => consent)
                    .catch(error => console.error('saveConsentsToDisk: ', error));
                }
                else {
                  if(conConsent.activeConsents && consent.activeConsents) {
                    let filteredConsents: ConsentTerm[];
                    // Add existing consent activities & channels to delta consent
                    consent.activeConsents.forEach((deltaConsent: ConsentTerm) => {
                      const existingConsent = conConsent.activeConsents.find((con) => con.indskr_consenttermsid == deltaConsent.indskr_consenttermsid);
                      let existingConsentActivities = existingConsent?.consentActivity;
                      let exisitngConsentChannels = existingConsent?.channels;
                      let existingSignature = existingConsent?.indskr_signature;
                      if(!_.isEmpty(existingConsentActivities)) {
                        if(!_.isEmpty(deltaConsent.consentActivity)) {
                          let newValues = existingConsentActivities.filter(ea => !deltaConsent.consentActivity.some(da => ea.indskr_consentactivityid == da.indskr_consentactivityid));
                          deltaConsent.consentActivity.push(...newValues);
                        }
                        else deltaConsent.consentActivity = existingConsentActivities;
                      }
                      if(!_.isEmpty(exisitngConsentChannels)) {
                        if(!_.isEmpty(deltaConsent.channels)) {
                          exisitngConsentChannels.forEach(eCh => {
                            deltaConsent.channels.forEach((dCh, index) => {
                              if(eCh.indskr_consenttypeid == dCh.indskr_consenttypeid) {
                                if(!_.isEmpty(eCh.values)) {
                                  let newValues = dCh.values.filter(dc => !eCh.values.some(ec => ec.value == dc.value));
                                  if(!_.isEmpty(newValues))eCh.values.push(...newValues);
                                } else eCh.values = dCh.values;
                                deltaConsent.channels.splice(index,1);
                              }
                            })
                          })
                        if(!_.isEmpty(deltaConsent.channels)) exisitngConsentChannels.push(...deltaConsent.channels);
                        }
                        deltaConsent.channels = exisitngConsentChannels;
                      }
                      if(!_.isEmpty(existingSignature) && _.isEmpty(deltaConsent.indskr_signature)) {
                        deltaConsent.indskr_signature = existingSignature;                   
                      }
                      // Removing the old consents in case of duplicate during delta
                      filteredConsents = conConsent.activeConsents.filter((existingConsent: ConsentTerm) => {
                        return existingConsent.indskr_consenttermsid !== deltaConsent.indskr_consenttermsid;
                      });
                      if(filteredConsents) {
                        // Pushing the latest data present in delta
                        filteredConsents.push(deltaConsent);
                        filteredConsents.forEach(con => {
                          con.channels.forEach(ch => {
                            delete ch._id;
                            delete ch._rev;
                          })
                        })
                        conConsent.activeConsents = filteredConsents;
                        this.disk.updateDocWithIdAndRev(conConsent);
                      }
                    });
                  }
                }
              }, err => {
                console.log("Error occurred" + err);
              }
          );
        }
      });
      if (isInitialSync) {
        this.disk.bulk(data);
      }
      console.log(`${data.length} active consents has been written to disk`);
    }
  }

  isTermExpired(cntTerm: ConsentTerm) {
    let isValid = false;
    const sod = parseFloat(cntTerm.indskr_fromdate.toString());
    const eod = parseFloat(cntTerm.indskr_untildate.toString());
    const now = new Date();

    if (isNaN(sod) || isNaN(eod) || isPast(eod)) {}
    else {
      isValid = isWithinRange(now, startOfDay(sod), endOfDay(eod))
    }

    return isValid;
  }

  private async saveConsentChannelsToDisk(isInitialSync, dbKey, data: Channel[]) {
    if (data && data.length >= 0) {
      // await this.disk
      //   .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_CHANNELS)
      //   .then((ch: Channel[]) => {
      //     if (ch && ch.length) {
      //       ch.forEach(channel => {
      //         let index = data.findIndex(
      //           (c: Channel) => {
      //             return c.indskr_consenttypeid === channel.indskr_consenttypeid;
      //           });
      //         if (index === -1) {
      //           this.disk.remove(channel._id.toString());
      //         }
      //       });
      //     }
      //   });
      let diskCh = await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_CHANNELS)
      if(diskCh && Array.isArray(diskCh)){
        diskCh.forEach((ch: Channel) => {
          if (ch) {
            let index = data.findIndex(
              (c: Channel) => {
                return c.indskr_consenttypeid === ch.indskr_consenttypeid;
              });
            if (index === -1) {
              this.disk.remove(ch._id.toString());
            }
          }
        });
      }
      data.forEach((channel: Channel) => {
        channel._id = dbKey + channel.indskr_consenttypeid;
        if (!isInitialSync) {
          this.disk.retrieve(channel._id.toString()).then(
            exist => {
              // if (!exist) {
              this.disk.updateOrInsert(channel._id.toString(), doc => channel);
              // } else {
              //   if (dbKey === DB_KEY_PREFIXES.CHANNELS) {
              //     this.disk.updateOrInsert(channel._id.toString(), doc => exist);
              //   }
              // }
            },
            err => {
              console.log("Error occurred" + err);
            }
          );
        }
      });
      if (isInitialSync) {
        this.disk.bulk(data);
      }
      console.log(
        `${data.length} active consent channels has been written to disk`
      );
    }
  }

  private saveConsentTermsToDisk(isInitialSync, dbKey, data: ConsentTerm[]) {
    if (data && data.length > 0) {
      data.forEach( async (consent: ConsentTerm) => {
        consent._id = dbKey + consent.indskr_consenttermsid;
        if (consent.track_action == TrackAction.Deleted || !this.isTermExpired(consent)) {
          this.disk.retrieve(consent._id.toString()).then(async (exist) => {
            if (exist)
              await this.disk.remove(consent._id.toString());
          });
        } else {
          if (!isInitialSync) {
            let exist = await this.disk.retrieve(consent._id.toString()); //.then(
            //async exist => {
            if (!exist) {
              await this.disk.updateOrInsert(consent._id.toString(), doc => consent)
                .catch(error => console.error('saveConsentTermsToDisk: ', error));
            } else {
              if (dbKey === DB_KEY_PREFIXES.CONSENT_TERMS || dbKey === DB_KEY_PREFIXES.CONSENT_TERMS_ALLOCATION_ORDERS) {
                exist.indskr_consentTermName = consent.indskr_consentTermName;
                exist.indskr_fromdate = consent.indskr_fromdate;
                exist.indskr_untildate = consent.indskr_untildate;
                exist.indskr_alwaysrequired = consent.indskr_alwaysrequired;
                exist.indskr_body = consent.indskr_body;
                exist.indskr_signaturerequired = consent.indskr_signaturerequired;
                exist.indskr_recaptureconsent = consent.indskr_recaptureconsent;
                exist.indskr_confirmationmessage = consent.indskr_confirmationmessage;
                exist.indskr_agreementmessage = consent.indskr_agreementmessage;
                exist.products = consent.products;
                exist.channels = consent.channels;
                exist.channels.forEach(ch => {
                  delete ch._id;
                  delete ch._rev;
                })
                exist.indskr_allowpaperconsentcapture = consent.indskr_allowpaperconsentcapture || false;
                await this.disk.updateOrInsert(consent._id.toString(), doc => exist);

                // Update Contact Consent as well since delta sync doesn't catch this...
                const contactConsents = this.allActiveConsentSubject.getValue();
                for (let i = 0; i < contactConsents.length; i++) {
                  const contactConsent = contactConsents[i];
                  if (Array.isArray(contactConsent.activeConsents) && contactConsent.activeConsents.some(c => c.indskr_consenttermsid === consent.indskr_consenttermsid)) {
                    const contactConsentDoc = await this.disk.retrieve(DB_KEY_PREFIXES.CONSENTS + contactConsent.indskr_contactid, true);
                    if (contactConsentDoc && Array.isArray(contactConsentDoc.activeConsents)) {
                      const idx = contactConsentDoc.activeConsents.findIndex(c => c.indskr_consenttermsid === consent.indskr_consenttermsid);
                      if (idx >= 0) {
                        contactConsentDoc.activeConsents[idx].indskr_consentTermName = consent.indskr_consentTermName;
                        contactConsentDoc.activeConsents[idx].indskr_fromdate = consent.indskr_fromdate;
                        contactConsentDoc.activeConsents[idx].indskr_untildate = consent.indskr_untildate;
                        contactConsentDoc.activeConsents[idx].products = consent.products;
                        const existingChannels: Channel[] = _.cloneDeep(contactConsentDoc.activeConsents[idx].channels);
                        contactConsentDoc.activeConsents[idx].channels = consent.channels;
                        contactConsentDoc.activeConsents[idx].channels.forEach((ch: Channel) => {
                          const existingDataIndex = existingChannels.findIndex(exCh => exCh.indskr_consenttypeid === ch.indskr_consenttypeid);
                          if (existingDataIndex >= 0) {
                            delete ch._id;
                            delete ch._rev;
                            ch.values = existingChannels[existingDataIndex].values;
                          }
                        })
                        await this.disk.updateDocWithIdAndRev(contactConsentDoc);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
      if (isInitialSync) {
        this.disk.bulk(data);
      }
      console.log(
        `${data.length} active consent terms has been written to disk`
      );
    }
  }

  public async createOptOutConsentActivity(consentActivities) {
    if (this.deviceService.isOffline) return;   
    await this.consentDataService.saveOptOutConsentActivity(consentActivities).then(
      async (res) => {  
        let dbKey = DB_KEY_PREFIXES.CONSENTS + this.contactService.contactInformation.ID;
        Promise.all([ 
          this.disk.retrieve(dbKey.toString()).then(async (exist) => {
            if (exist)
              this.disk.remove(dbKey.toString());
          })
        ]).then(async () => {
          await this.getAllActiveConsentsByContactId(this.contactService.contactInformation.ID).then(async (res)=> {
            await this.disk
              .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS)
              .then((data: ContactConsent[]) => {
                if (Array.isArray(data)) {
                  data.forEach(cct => {
                    if (Array.isArray(cct.activeConsents)) {
                      cct.activeConsents.forEach(term => {
                        term.isOfflineSaved = false;
                        term.isSelectedTerm = false;
                      });
                    }
                  });
                }
                this.allActiveConsentSubject.next(data);
                // console.log(`active consents from disk : ${data ? data.length : 0}`);
              });
          });
          // Need to update the feeb tab view for consent activities
          await this.getAllConsentActivitiesForProductsAndEmails(false);
          this.notificationService.notify(
            this.translate.instant('CONSENT_OPTED_OUT_SUCCESSFULLY'),
            "Consent Service",
            "top",
            ToastStyle.INFO
          );
        })       
      }
    )
  }

  public async saveOrUpdateActivityInDB(consentActivities: ConsentActivity[]) {
    if(!_.isEmpty(consentActivities)) {
      consentActivities.forEach(async consentActivity => {
        let _id = DB_KEY_PREFIXES.CONSENTS + consentActivity.contactId;
        let consent: ContactConsent = await this.disk.retrieve(_id);
        if(!_.isEmpty(consent)) {
          consent.activeConsents.find(con => con.indskr_consenttermsid == consentActivity.consentTermId)
          .consentActivity.push(consentActivity)
        }
        await this.disk.updateDocWithIdAndRev(consent);
        let index = this.allActiveConsentSubject.value.findIndex(
          (c: ContactConsent) => {
            return c.indskr_contactid === consent.indskr_contactid;
          }
        );
        Object.assign(this.allActiveConsentSubject.value[index], consent);
        this.allActiveConsentSubject.next(this.allActiveConsentSubject.value);
      })
      
    }
  } 

  public async createConsentTerm(term: ConsentTerm, paperConsent?) {
    term.offlineId = DB_KEY_PREFIXES.CONSENTS + new Date().getTime();
    if (!this.deviceService.isOffline) {
      try {
        await this.consentDataService.saveConsent(term).then(
          async (res: ConsentTerm) => {
            term.isSavedConsent = true;
            this.notificationService.notify(
              this.translate.instant('CONSENT_SUBMITTED_SUCCESSFULLY'),
              "Consent Service",
              "top",
              ToastStyle.INFO
            );
            Promise.all([  
            await this.getAllConsentActivitiesForProductsAndEmails(false).then(async() => {
              if(!_.isEmpty(paperConsent)) {
                let notes = [];
                let consentActivities = this.allConsentActivitiesWithProductsAndEmails.filter(con => con.offlineId == term.offlineId);
                if(!_.isEmpty(consentActivities) && _.isArray(consentActivities)) {
                  consentActivities.forEach(activity => {
                    let note = _.cloneDeep(paperConsent);
                    note.consentActivityId = activity.consentActivityId;
                    notes.push(note);
                  })
                }
                await this.consentDataService.savePaperConsent(notes);
              }
            })
            ]).then(async () => {
              let _id = DB_KEY_PREFIXES.CONSENTS + term.indskr_contactid;
              const consent: ContactConsent = await this.disk.retrieve(_id);
              if (!consent) {
                const newConsent: ContactConsent = new ContactConsent("", "", "", []);
                newConsent._id = _id;
                newConsent.indskr_contactid = term.indskr_contactid;
                newConsent.activeConsents.push(term);
                await this.disk.updateOrInsert(_id.toString(), doc => newConsent)
                  .catch(error => console.error('saveOrUpdateConsentsInDB: ', error));
              }
              this.getAllActiveConsents().then(async ()=> {
                await this.disk
                  .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS)
                  .then((data: ContactConsent[]) => {
                    if (Array.isArray(data)) {
                      data.forEach(cct => {
                        cct.activeConsents.forEach(term => {
                          term.isOfflineSaved = false;
                          term.isSelectedTerm = false;
                        });
                      });
                    }
                    this.allActiveConsentSubject.next(data);
                    console.log(`active consents from disk : ${data ? data.length : 0}`);
                  });
              })
            })
          });
      } catch(error) {
        console.error('createConsentTerm: ', error);
      }
    } else {
      term.isOfflineSaved = true;
      await this.saveOrUpdateConsentsInDB(term);
      // Track offline data count
      this.trackOfflineConsentDataCount();
    }
  }

  public async saveOrUpdateConsentsInDB(term: ConsentTerm) {
    let _id = DB_KEY_PREFIXES.CONSENTS + term.indskr_contactid;
    term.isSavedConsent = true;
    this.notificationService.notify(
      this.translate.instant('CONSENT_SUBMITTED_SUCCESSFULLY'),
      "Consent Service",
      "top",
      ToastStyle.INFO
    );
    const consent: ContactConsent = await this.disk.retrieve(_id);
      if (!consent) {
        const newConsent: ContactConsent = new ContactConsent("", "", "", []);
        newConsent._id = _id;
        newConsent.indskr_contactid = term.indskr_contactid;
        newConsent.activeConsents.push(term);
        await this.disk.updateOrInsert(_id.toString(), doc => newConsent)
          .catch(error => console.error('saveOrUpdateConsentsInDB: ', error));
        let addedConsents: ContactConsent[] = [
          ...this.allActiveConsentSubject.value,
          newConsent
        ];
        this.allActiveConsentSubject.next(addedConsents);
        this.allActiveConsentSubject.next(this.allActiveConsentSubject.value);
      } else {
        consent.activeConsents.push(term);
        await this.disk.updateDocWithIdAndRev(consent);
        let index = this.allActiveConsentSubject.value.findIndex(
          (c: ContactConsent) => {
            return c.indskr_contactid === consent.indskr_contactid;
          }
        );
        Object.assign(this.allActiveConsentSubject.value[index], consent);
        this.allActiveConsentSubject.next(this.allActiveConsentSubject.value);
      }
      let index = this.allGeneratedTerms.value.findIndex(
        (t: ConsentTerm) => {
          return t.indskr_consenttermsid === term.indskr_consenttermsid;
        }
      );
      this.allGeneratedTerms.value[index].isSavedConsent = term.isSavedConsent;
      this.allGeneratedTerms.value[index].isOfflineSaved = term.isOfflineSaved;
      this.currentConsentTerm.next(term);
      this.allGeneratedTerms.next(this.allGeneratedTerms.value);

      this.showAllConsentsFilter$.next(true);
      await this.loadNextAvailableTermIfExist();
  }

  async loadNextAvailableTermIfExist() {
    const availableTerms: ConsentTerm[] = this.allGeneratedTerms.value.filter(term => !term.isSavedConsent);
    if (availableTerms.length) {
      availableTerms[0].isSelectedTerm = true;
      this.selectedConsentTerm.next(availableTerms[0]);
      this.allGeneratedTerms.value.forEach((generatedTerm: ConsentTerm) => {
        if (
          this.selectedConsentTerm.value.indskr_consenttermsid ===
          generatedTerm.indskr_consenttermsid
        ) {
          generatedTerm.isAcceptedTerms = false;
          generatedTerm.isSelectedTerm = true;
        } else {
          generatedTerm.isSelectedTerm = false;
        }
      });
    }
  }

  trackOfflineConsentDataCount() {
    const offlineDataCount = this.allActiveConsentSubject.value.reduce((acc, contactConsent) => {
      if (Array.isArray(contactConsent.activeConsents)) {
        acc += contactConsent.activeConsents.filter(c => c.isOfflineSaved === true).length;
      }
      return acc;
    }, 0);
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONSENT, offlineDataCount);
  }

  clearFilters() {
    this.showAllConsentsFilter = 'All';
    // this.isProductFilterExpanded = false;
    // this.isChannelFilterExpanded = false;
    this.consentChannelFilter$ = undefined;
    this.consentProductFilter$ = undefined;
    this.showAllConsentsFilter$.next(true);
  }

  /* Consent Related Offline Logic */
  /* =================================== */
  /* Used while performing the sync operation from master service */
  public async loadOfflineConsents(): Promise<any> {
    try {
      let offlineConsents: ConsentTerm[] = [];
      await this.disk
        .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS)
        .then((data: ContactConsent[]) => {
          if (data.length) {
            data.forEach(contactConsent => {
              if (contactConsent.activeConsents && contactConsent.activeConsents.length) {
                offlineConsents = [
                  ...offlineConsents,
                  ...contactConsent.activeConsents.filter(
                    consent => consent.isOfflineSaved
                  )
                ];
              }
            });
          }
        });
      console.log("Offline consent ", offlineConsents);
      // Track offline data count
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONSENT, offlineConsents.length);
      return offlineConsents;
    } catch (diskError) {
      console.log("Disk error caught", diskError);
    }
  }

  public async updateOfflineConsents(res, offlineConsents, consentSyncInfo) {
    this.savedConsents = [];
    if (res.offlineConsents && Array.isArray(res.offlineConsents)) {
      this.savedConsents = res.offlineConsents.filter(oC => !oC.errorMessage);
      let uploadedOfflineDataCount = this.savedConsents.length;
      const failedConsents = res.offlineConsents.filter(oC => oC.errorMessage);
      if (this.savedConsents && Array.isArray(this.savedConsents) && this.savedConsents.length) {
        this.savedConsents.forEach(consent => {
          offlineConsents.forEach(oC => {
            if (oC.offlineId == consent.offlineId) {
              if (consent.track_action && consent.track_action == TrackAction.Deleted) {
                let index = this.savedConsents.findIndex(
                  cT => {
                    return cT.offlineId === consent.offlineId;
                  });
                if (index !== -1) {
                  this.savedConsents.splice(index, 1);
                }
                this.disk.retrieve(DB_KEY_PREFIXES.CONSENTS + oC.indskr_contactid)
                  .then((conConsent: ContactConsent) => {
                    let index = conConsent.activeConsents.findIndex(
                      (cT: ConsentTerm) => {
                        return cT.indskr_consenttermsid === oC.indskr_consenttermsid;
                      });
                    if (index !== -1) {
                      conConsent.activeConsents.splice(index, 1);
                      this.disk.updateDocWithIdAndRev(conConsent);
                    }
                  });
              }
              else {
                this.disk.retrieve(DB_KEY_PREFIXES.CONSENTS + oC.indskr_contactid)
                  .then((conConsent: ContactConsent) => {
                    const idx = Array.isArray(conConsent.activeConsents) ? conConsent.activeConsents.findIndex(ac => ac.offlineId === consent.offlineId) : -1;
                    if (idx >= 0) {
                      conConsent.activeConsents[idx].isOfflineSaved = false;
                      conConsent.activeConsents[idx].isSelectedTerm = false;
                      conConsent.activeConsents = this.mergeDuplicateTerms(conConsent.activeConsents);
                      this.disk.updateOrInsert(DB_KEY_PREFIXES.CONSENTS + oC.indskr_contactid, doc => conConsent);
                    }
                });
              }
            }
          });
        });

        this.loadAndMapConsentsMasterDataFromDB();
      }
      if (failedConsents && Array.isArray(failedConsents) && failedConsents.length) {
        failedConsents.forEach(consent => {
          this.deltaService.addSyncErrorToEntitySyncInfo(consentSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl +
            Endpoints.offline.UPLOAD_MEETING, consent.errorMessage);
        });
      }
      // Track offline data count
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONSENT, offlineConsents.length - uploadedOfflineDataCount);
    }
  }

  mergeDuplicateTerms(terms: ConsentTerm[]): ConsentTerm[] {
    //get grouped consents
    let groupedConsents = _.chain(terms)
      .groupBy('indskr_consenttermsid')
      .map((value, key) => ({ termId: key, terms: value }))
      .value();
    console.log(groupedConsents);
    let uniqueTermSet = [];
    _.each(groupedConsents, (consent) => {
      if (consent.terms.length > 1) {
        console.log("duplicate term detected");
        this.processDuplicates(consent.terms, uniqueTermSet);
      }
      else {
        uniqueTermSet.push(consent.terms[0]);
      }
    });
    console.log("uniqueTermSet:")
    console.log(uniqueTermSet);
    return uniqueTermSet;
  }

  processDuplicates(terms: ConsentTerm[], uniqueTermSet: any[]) {
    let products = [];
    let channels = [];
    let uniqChannels = [];
    terms.forEach(term => {
      products.push(...term.products);
      channels.push(...term.channels);
    });
    terms[0].products = _.uniqBy(products, 'indskr_productid');

    //get grouped channels
    let groupedChannels = _.chain(channels)
      .groupBy('indskr_consenttypeid')
      .map((value, key) => ({ channelId: key, channels: value }))
      .value();

    //process channel values
    _.each(groupedChannels, (channel) => {
      if (channel.channels.length > 1) {
        let channelValues = [];
        channel.channels.forEach(ch => {
          channelValues.push(...ch.values);
        });
        channel.channels[0].values = _.uniqBy(channelValues, 'value');
        uniqChannels.push(channel.channels[0]);
      }
      else {
        uniqChannels.push(channel.channels[0]);
      }
    });

    terms[0].channels = _.uniqBy(uniqChannels, 'indskr_consenttypeid');
    uniqueTermSet.push(terms[0]);
  }

  public updateContact(contact: Contact, obj: any, type, address?: ContactAddress):Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.deviceService.isOffline) {
        const existingMobileNumber = contact.mobilePhone;
        let payload: any = {
          "indskr_externalid": contact.appId || '',
          "indskr_externalsource": contact.external_source || '',
          "contactid": contact.ID,
          "firstname": contact.firstName,
          "lastname": contact.lastName,
          "indskr_title": contact.indskr_title ? contact.indskr_title : null,
          "middlename": contact.middleName,
          "telephone1": contact.telephone,
          "mobilephone": ChannelType.PHONE ? obj['value'] : contact.mobilePhone,
          "indskr_lu_language": contact.primaryLanguage ? (contact.primaryLanguage.id || '')  : '',
          "indskr_lu_specialty": contact.primarySpecialty ? (contact.primarySpecialty.id || '')  : '',
          "indskr_speaker": contact.isSpeaker != 'No',
          "indskr_kol": contact.isKOL != 'No',
          "emails": type == ChannelActivityType.EMAIL ? [obj] : [],
          "addresses": type == ChannelActivityType.VISIT ? [obj] : [],
          "accounts": []
        };
        if (type == ChannelType.PHONE) {
          if (existingMobileNumber) {
            payload[obj['field']] = existingMobileNumber;
          }
        }
        let loader
        this.loadingCtrl.create().then((load)=>{
          loader = load
          loader.present();
          this.contactDataService.createContact(payload).then(res => {
            if (type == ChannelActivityType.EMAIL) {
              if (res && res[0].emails) {
                for (let email of res[0].emails) {
                  const emailObj: Email = {
                    emailAddress: email.indskr_emailaddress,
                    emailAddressId: email.indskr_email_addressid,
                    isPrimary: email.indskr_isprimary,
                    isVerified: true,
                  };
                  this.contactService.contactInformation.emailAddressList.push(emailObj);
                }
                this.allGeneratedTerms.value.forEach(term => {
                  if (term.channels.length) {
                    let eChannel: Channel = term.channels.find(c => c.activityType == type);
                    if (eChannel) {
                      eChannel.values.push(
                        new ChannelValue(res[0].emails[0].indskr_email_addressid, String(obj['indskr_emailaddress']), !!eChannel.isChecked)
                      );
                    }
                    let fChannel: Channel = term.channels.find(c => c.indskr_consentType == ChannelType.FACEBOOK);
                    if (fChannel) {
                      fChannel.values.push(
                        new ChannelValue(res[0].emails[0].indskr_email_addressid, String(obj['indskr_emailaddress']), !!fChannel.isChecked)
                      );
                    }
                  }
                });
              }
            }
            /*else if (type == ChannelType.PHONE || type == ChannelActivityType.SMS || type == ChannelActivityType.WHATSAPP) {
              this.contactService.contactInformation.mobilePhone = obj['value'];
              if (existingMobileNumber) {
                this.contactService.contactInformation[obj['field']] = existingMobileNumber;
              }
              this.allGeneratedTerms.value.forEach(term => {
                if (term.channels.length) {
                  let pChannel: Channel = term.channels.find(c => c.activityType == ChannelActivityType.PHONE);
                  if (pChannel) {
                    pChannel.values.push(
                      new ChannelValue(null, String(obj['value']), !!pChannel.isChecked)
                    );
                  }
                  let smsChannel: Channel = term.channels.find(c => c.activityType == ChannelActivityType.SMS);
                  if (smsChannel) {
                    smsChannel.values.push(
                      new ChannelValue(null, String(obj['value']), !!smsChannel.isChecked)
                    );
                  }
                  let waChannel: Channel = term.channels.find(c => c.activityType == ChannelActivityType.WHATSAPP);
                  if (waChannel) {
                    waChannel.values.push(
                      new ChannelValue(null, String(obj['value']), !!waChannel.isChecked)
                    );
                  }
                }
              });
          }*/
          else if (type == ChannelType.PHONE || type == ChannelActivityType.SMS || type == ChannelActivityType.WHATSAPP) {
            this.contactService.contactInformation.mobilePhone = obj['value'];
            if (existingMobileNumber) {
              this.contactService.contactInformation[obj['field']] = existingMobileNumber;
            }
            this.allGeneratedTerms.value.forEach(term => {
              if (term.channels.length) {
                let pChannel: Channel = term.channels.find(c => (c.activityType == ChannelActivityType.PHONE && c.indskr_consentType == (ChannelType.PHONE || type)));
                if (pChannel) {
                  pChannel.values.push(
                    new ChannelValue(null, String(obj['value']), !!pChannel.isChecked)
                  );
                }
                let smsChannel: Channel = term.channels.find(c => (c.activityType == ChannelActivityType.SMS && c.indskr_consentType == ChannelType.SMS));
                if (smsChannel) {
                  smsChannel.values.push(
                    new ChannelValue(null, String(obj['value']), !!smsChannel.isChecked)
                  );
                }
                let waChannel: Channel = term.channels.find(c => c.activityType == ChannelActivityType.WHATSAPP);
                if (waChannel) {
                  waChannel.values.push(
                    new ChannelValue(null, String(obj['value']), !!waChannel.isChecked)
                  );
                }
              }
            });
          }
          else if (type == ChannelActivityType.VISIT) {
            this.contactService.contactInformation.addressesList.push(address);
            this.allGeneratedTerms.value.forEach(term => {
              if (term.channels.length) {
                let visitChannel: Channel = term.channels.find(c => c.activityType == type);
                if (visitChannel) {
                  visitChannel.values.push(
                    new ChannelValue(null, String(address.compositeAdd), !!visitChannel.isChecked)
                  );
                }
              }
            });
          }
          this.contactService.contactInformation = this.contactService.getContactByID(this.contactService.contactInformation.ID);
          loader.dismiss();
          resolve(true);
        }).catch((err) => {
          loader.dismiss();
          console.log("Error updating Contact");
          resolve(false);
        });
        })
      }
    });
  }

  doesContactHaveConsentForChannel(
                                    contact: { ID: string, emailAddressList?: any[], mobilePhone?: string, indskr_facebookpsid?: string },
                                    channelType: ChannelType, scheduledStart: Date, productId?: string
                                  ): ConsentedContact {
    if (!contact || !ChannelType) {
      return;
    }

    let hasConsent = false;
    let consentedContact: ConsentedContact = new ConsentedContact();
    // const activeConsentsWithContactId = this.allActiveConsentSubject.getValue();

    let contactConsents: ContactConsent[] = [];
    this.allActiveConsentSubject.subscribe(cc => {
      contactConsents = cc;
    });
    const activeConsentsForTheContact = Array.isArray(contactConsents) ? _.chain(contactConsents)
                    .filter(a => a.indskr_contactid === contact.ID)
                    .map('activeConsents')
                    .flatMap()
                    .filter(cntTerm => {
                      const sod = parseFloat(cntTerm.indskr_fromdate.toString());
                      const eod = parseFloat(cntTerm.indskr_untildate.toString());

                      return !isNaN(sod) && !isNaN(eod) && isFuture(endOfDay(eod)) &&
                        isWithinRange(scheduledStart, startOfDay(sod), endOfDay(eod));
                    })
                    .filter(consent => Array.isArray(consent.channels) && consent.channels.some(channel => channel.indskr_consentType === channelType))
                    .value() : null;

    // Reset email selectable field if channel is Email
    if (channelType === ChannelType.EMAIL && Array.isArray(contact.emailAddressList)) {
      for (let i = 0; i < contact.emailAddressList.length; i++) {
        const emailItem = contact.emailAddressList[i];
        emailItem.isSelectable = false;
        emailItem.hasConsented = false;
      }
    }
    if (Array.isArray(activeConsentsForTheContact)) {
      for (let i = 0; i < activeConsentsForTheContact.length; i++) {
        const activeConsent = activeConsentsForTheContact[i];
      
        // To check whether Product Consent flag is true 
        // then check if template is selected, filter out by product first else skip
        if (this.authService.user.isProductConsent) {
          if (productId !== undefined && Array.isArray(activeConsent.products)) {
            const hasProduct = activeConsent.products.some(p => p.indskr_productid === productId);
            if (!hasProduct) {
              continue;
            }
          }
        }
        if (Array.isArray(activeConsent.channels)) {
          const channel = activeConsent.channels.find(c => c.indskr_consentType === channelType);
          if (channel) {
            switch (channelType) {
              case ChannelType.WHATSAPP:
              case ChannelType.SMS:
                hasConsent = contact.mobilePhone && contact.mobilePhone.length > 0 && Array.isArray(channel.values) && channel.values.some(p => p.value === contact.mobilePhone);
                break;

              case ChannelType.FACEBOOK:
                hasConsent = (contact.indskr_facebookpsid || '').length > 0;
                break;

              case ChannelType.EMAIL:
                if (Array.isArray(channel.values) && Array.isArray(contact.emailAddressList)) {
                  for (let i = 0; i < contact.emailAddressList.length; i++) {
                    const emailItem = contact.emailAddressList[i];
                    if (channel.values.some(v => (v.value === emailItem.emailAddress))) {
                      emailItem.isSelectable = true;
                      emailItem.hasConsented = true;
                      hasConsent = true;
                    }
                  }
                }
                break;

              default:
                break;
            }
          }
        }
      }
    }
    consentedContact.hasConsented = hasConsent;
    consentedContact.contact = contact;

    return consentedContact;
  }

  consentValidationBeforeSend (
                                contact: { ID: string, emailAddressList?: any[], mobilePhone?: string, indskr_facebookpsid?: string },
                                channelType: ChannelType, scheduledStart: Date, productId: string
                              ): boolean {
    const now = new Date();
    const activeConsentsWithContactId = this.allActiveConsentSubject.getValue();
    const activeConsentsForTheContact = Array.isArray(activeConsentsWithContactId) ? _.chain(activeConsentsWithContactId)
                    .filter(a => a.indskr_contactid === contact.ID)
                    .map('activeConsents')
                    .flatMap()
                    .filter(cntTerm => {
                      let isValid = false;
                      const sod = parseFloat(cntTerm.indskr_fromdate.toString());
                      const eod = parseFloat(cntTerm.indskr_untildate.toString());

                      if (isNaN(sod) || isNaN(eod) || isPast(eod)) {}
                      else if (channelType === ChannelType.EMAIL) {
                        isValid = isFuture(scheduledStart) ? isWithinRange(scheduledStart, startOfDay(sod), endOfDay(eod))
                                  : isWithinRange(now, startOfDay(sod), endOfDay(eod))
                      } else {
                        isValid = isWithinRange(now, startOfDay(sod), endOfDay(eod))
                      }

                      return isValid;
                    })
                    .filter(consent => Array.isArray(consent.channels) && consent.channels.some(channel => channel.indskr_consentType === channelType))
                    .filter(consent => !productId ? true : Array.isArray(consent.products) && consent.products.some(product => product.indskr_productid === productId))
                    .value() : null;
    return Array.isArray(activeConsentsForTheContact) && activeConsentsForTheContact.length > 0;
  }

  displayChannelLabels(label: string) {
    let displayName: string = "";
    let translateddisplayName: string = "";
    switch (label) {
      case ChannelType.EMAIL.toString():
        displayName = ChannelType.EMAIL_ADDRESS.toString();
        break;
      case ChannelType.VISIT.toString():
        displayName = ChannelType.ADDRESS.toString();
        break;
      case ChannelType.PHONE.toString():
        displayName = ChannelType.PHONE_NUMBER.toString();
        break;
      default:
        displayName = label;
    }
    switch (displayName) {
      case 'Email':
        translateddisplayName = this.translate.instant('EMAIL_ADDRESS');
        break;
      case 'Address':
        translateddisplayName = this.translate.instant('ADDRESS');
        break;
      case 'Phone':
        translateddisplayName = this.translate.instant('PHONE');
        break;
      case 'SMS':
        translateddisplayName = this.translate.instant('SMS');
        break;
      case 'Visit':
        translateddisplayName = this.translate.instant('VISIT');
        break;
      case 'WhatsApp':
        translateddisplayName = this.translate.instant('WHATSAPP');
        break;
      case 'Facebook':
        translateddisplayName = this.translate.instant('FACEBOOK');
        break;
      case 'Fax':
        translateddisplayName = this.translate.instant('FAX');
      case 'Allocation orders':
      case 'Allocation Orders':
        
        translateddisplayName = this.translate.instant('ALLOWCATION_ORDERS');

        break;
        break;
      default:
        translateddisplayName = displayName;
        break;
    }

    return translateddisplayName;
  }
}
