import { Injectable } from '@angular/core';
import { DiskService } from '../disk/disk.service';
import { FollowupActionStatusReason, FollowupActionType, FOLLOWUP_ACTION_TYPE_STATUS } from '../../classes/activity/follow-up-action.activity.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { Brand } from '../../classes/brand/brand.class';

@Injectable({
  providedIn: 'root'
})
export class FollowupService {

  followupActionTypes: FollowupActionType[] = [];
  public followupActionStatusReasons: FollowupActionStatusReason[] = [];
  brandProducts: Array<Brand> = new Array<Brand>();

  constructor(private disk: DiskService, private brandService: BrandOfflineService) {

    this.brandProducts = this.brandService.brands; // Thsi is to get the brands  mapped to user's position
    this._sortBrands(); // Apply sorting based on the name alphabetically ascending

  }

  public async getFollowupActionTypes() {

    if (!this.followupActionTypes) {
      await this.disk.retrieve(DB_KEY_PREFIXES.FOLLOW_UP_ACTION_TYPES).then((doc) => {
        if (doc && doc.raw && doc.raw.length) {
          this.followupActionTypes = doc.raw;
        }
      });

      this.followupActionTypes = this.followupActionTypes.filter((followup) => {
        return followup.statusCode == FOLLOWUP_ACTION_TYPE_STATUS.ACTIVE
      });

      this.followupActionTypes = this.followupActionTypes.sort((a, b) => {
        if (a.name && b.name) {
          return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
        }
      });
      return this.followupActionTypes;
    }
  }

  private _sortBrands() {
    if (!this.brandProducts || this.brandProducts.length == 0) return;
    this.brandProducts = this.brandProducts.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (b.name.toLowerCase() > a.name.toLowerCase()) {
        return -1;
      }
    });
  }
}
