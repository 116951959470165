import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { UIService } from "../../../services/ui/ui.service";
import { ActivityService } from '../../../services/activity/activity.service';
import { ContactDataService } from "../../../data-services/contact/contact.data.service";
import { AccountOfflineService } from "../../../services/account/account.offline.service";
import { ChildNavNames, NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { DeviceService } from '../../../services/device/device.service';
import { CallPlanDetails } from '../call-plan-details/call-plan-details';

@Component({
    selector: 'call-plan-page[base-page]',
    templateUrl: 'call-plan-page.html',
  styleUrls:['call-plan-page.scss']
})
export class CallPlanComponent implements OnInit, OnDestroy {
    @ViewChild('rightPaneNav', { static: true }) nav: IonNav;

    constructor(public callPlanService:CallPlanOfflineService,
                public uiService:UIService,
                public activityService: ActivityService,
                public contactService:ContactDataService,
                public accountOfflineService: AccountOfflineService,
                private events: Events,
                public device: DeviceService,
                private navService: NavigationService,
            ) {
    }

    @HostBinding("class.callPlan-selected")
    public get callPlanSelected() : boolean {
      return (!!this.callPlanService.selectedCallPlan) //&& this.callPlanService.callPlanPageView == "CallPlanDetail";
    }

    onCancelNewActivity(){
        this.uiService.activeView === 'CallPlanDetails'
        this.callPlanService.callPlanPageView === 'CallPlanDetail';
    }
    ngOnDestroy(){
        this.events.publish("tools-back-clicked", PageName.CallPlanComponent);
        this.navService.popChildNavCtrlFromStack(this.navService.getActiveChildNavName());
    }

    ionViewDidEnter() {
        if (this.uiService.isFlipButtonClicked) {
          this.uiService.isFlipButtonClicked = false;
        }
    }

    ngOnInit() {
      this.navService.initRightPaneChildNav(this.nav, ChildNavNames.CallPlanPageNavigation, PageName.CallPlanComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    }

    onCallPlanSelected(callPlan) {
      if (callPlan) {
        this.navService.setChildNavRoot(CallPlanDetails, PageName.CallPlanDetails, PageName.CallPlanComponent, { selectedCallPlan: callPlan });
      } else {
        this.navService.popToRootChildNavPageWithPageTracking();
      }
    }
}
