import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export type PopoListData = {
  id: string;
  title: string;
  detailObjectType?:string;
  detailObjectId?:string;
  thumbnailURL?: string;
}

@Component({
  selector: 'app-list-preview',
  templateUrl: './list-preview.component.html',
  styleUrls: ['./list-preview.component.scss'],
})
export class ListPreviewComponent implements OnInit {
  @Input() popupListData : PopoListData[];

  constructor(
    public popoverController : PopoverController,
  ) { }

  ngOnInit() {
    console.log(this.popupListData);
    
  }

  openContent(item: PopoListData){
    let data = { contentType: item.detailObjectType, contentId: item.detailObjectId };
    this.popoverController.dismiss(data);
  }

}
