import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '@omni/services/notification/notification.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { PhoneCallDataService } from '@omni/data-services/phone-call/phonecall.data.service';
import { DeviceService } from '@omni/services/device/device.service';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import * as moment from 'moment';
import { VoiceCallStatus } from '../../types/state/state.type';


@Injectable({
  providedIn: 'root',
})
export class VoiceCallService {

  public isInitializing: boolean = false;
  public isInitialized: boolean = false;
  private scriptsAdded: boolean = false;
  private _voicecallStatus$: BehaviorSubject<VoiceCallStatus> = new BehaviorSubject('Null');
  public voiceCallStatus$ = this._voicecallStatus$.asObservable();
  private config: any = {
    identifier: 'sainuofei1',
    bindType: 3,
    bindTel: '',
    agentToken: '',
    agentTokenLogin: 1,
    loginStatus: 1
  };
  private options: any = {
    isAutoLogin: true,
    toolbarMsgShowConfiguration: {
      showEnterprise: true,
      showTel: true,
      showAgentInitStatus: true,
      showLogout: false
    },
    showHold: false,
    showConsult: false,
    showTransfer: false,
    showSatisfaction: false,
    showMute: true
  };
  private lastActivity: string;
  private clinkToolbar: any;
  private callActivity: any;
  public toolbarInitilized: boolean = false;
  public toolbarInitilizing: boolean = false;
  hours: number;
  minutes: number;
  seconds: number;
  time: number;
  _formattedTime: string;
  intervalId: any;

  constructor(
    private readonly device: DeviceService,
    private readonly phoneCallDataService: PhoneCallDataService,
    private authService: AuthenticationService,
    private activityService: ActivityService,
    private notificationService: NotificationService
  ) {

  }

  private subscribeToEvents() {
    const callDisconnected = (async function (e) {
      console.log("ToolBar Status : ", e);
      if (e.code == 'WRAPUP' && this.callActivity) {
        this._voicecallStatus$.next('CALL_ENDED');
        const currentTime = new Date().getTime();
        const duration = currentTime - this.callActivity.indskr_starttime - 1000;
        let durationinSecs = moment.duration(duration).asSeconds();
        this.stopTimer();
        let payload: any = {
          indskr_endtime: currentTime,
          indskr_duration: durationinSecs + ''
        }
        let phoneActivity = this.activityService.selectedActivity as PhoneActivity;
        try {
          const callObs = async (uniqueId: string) => {
            const calldetails: any = await this.phoneCallDataService.getVoiceCallRecord(uniqueId);
            if (!_.isEmpty(calldetails) && !_.isEmpty(calldetails.cdrObs)) {
              return calldetails.cdrObs[0];
            } else {
              return await callObs(uniqueId);
            }
          }
          const callRecord = await callObs(e.mainUniqueId);
          payload = {
            indskr_starttime: callRecord.bridgeTime * 1000,
            indskr_endtime: callRecord.endTime * 1000,
            indskr_duration: callRecord.bridgeDuration + '',
            indskr_name: callRecord.uniqueId
          }
          if (callRecord.recordFile) {
            payload.indskr_recordinglink = callRecord.recordFile;
          }
          durationinSecs = callRecord.bridgeDuration;
        } catch (err) {
          console.log("Error while fetching call record by uniqueId : ", e.mainUniqueId);
        }
        const totalDuration = _.toNumber(phoneActivity.indskr_actualdurationminutesandseconds) + durationinSecs;
        phoneActivity.indskr_actualdurationminutesandseconds = _.round(totalDuration, 0)+ '';
        await Promise.all([
          this.phoneCallDataService.updatePhoneCallDuration(phoneActivity, { indskr_actualdurationminutesandseconds: phoneActivity.indskr_actualdurationminutesandseconds }),
          this.phoneCallDataService.updateCallActivity(this.callActivity.indskr_callactivityid, payload)
        ]).then((data) => {
          this._voicecallStatus$.next('REFRESH_CALL_RECORDS');
        })
        this.callActivity = null;
      } else if (e.code == 'IDLE') {
        this._voicecallStatus$.next('LOGGED_IN');
      }
      
    }).bind(this);

    const handleLogin = (async function (e) {
      console.log("Login : ", event);
      this._voicecallStatus$.next('LOGGED_IN');
    }).bind(this);

    const handleLogout = (async function (e) {
      console.log("Logout : ", event);
      this._voicecallStatus$.next('LOGGED_OUT');
    }).bind(this);

    //@ts-ignore
    this.clinkToolbar.userCustomEvent.on(ClinkAgent.ResponseType.LOGIN, handleLogin);
    //@ts-ignore
    this.clinkToolbar.userCustomEvent.on(ClinkAgent.ResponseType.LOGOUT, handleLogout);
    //@ts-ignore
    this.clinkToolbar.userCustomEvent.on(ClinkAgent.EventType.STATUS, callDisconnected);
  }

  public async initClinkToolBar() {
    if (!this.device.isOffline && !this.toolbarInitilized && !this.toolbarInitilizing  && this.authService.hasFeatureAction(FeatureActionsMap.VOICE_CALL)) {
      console.log("Initilizing ClinkToolBar...");
      if (!this.scriptsAdded) {
        const clinKTag = document.createElement('script');
        clinKTag.src = "assets/js/clink-client.js";
        document.head.appendChild(clinKTag);
        const clinKToolTag = document.createElement('script');
        clinKToolTag.src = "assets/js/ClinkToolbar.umd.min.js";
        document.head.appendChild(clinKToolTag);
        this.scriptsAdded = true;
      }
      const tokenConfig:any = await this.phoneCallDataService.getVoiceCallAgentToken();
      if (!_.isEmpty(tokenConfig) && !_.isEmpty(tokenConfig.agentToken)) {
        this.config.agentToken = tokenConfig.agentToken;
        this.config.bindTel = tokenConfig.cno;
        this.config.identifier = tokenConfig.identifier;
      }
      setTimeout(() => {
        const dom = document.getElementById('phone-action-bar');
        this._voicecallStatus$.next('INITIALIZING');
        try {
          //@ts-ignore
          this.clinkToolbar = new ClinkToolbar(this.options, dom, () => {
            console.log("ClinkToolBar has been Initialized");
            this.toolbarInitilized = true;
            this._voicecallStatus$.next('INITILIZED');
            //@ts-ignore
            ClinkAgent.login(this.config);
            this.subscribeToCallEvents();
            this.subscribeToEvents();
          });
          this.toolbarInitilizing = true;
        } catch (err) {
          console.error("Error while initilizing ClinkToolBar : ", err);
          this.toolbarInitilized = false;
          this._voicecallStatus$.next('INITILIZING_ERROR');
        }
      }, 5000);
    }
  }

  public login() {
    if (!this.device.isOffline && this.authService.hasFeatureAction(FeatureActionsMap.VOICE_CALL)) {
      this._voicecallStatus$.next('LOGGING_IN');
      //@ts-ignore
      ClinkAgent.login(this.config);
      this.subscribeToCallEvents();
      if (this.intervalId) clearInterval(this.intervalId);
    }
  }

  public logout() {
    if (!this.device.isOffline && this.authService.hasFeatureAction(FeatureActionsMap.VOICE_CALL)) {
      this._voicecallStatus$.next('LOGGED_OUT');
      //@ts-ignore
      ClinkAgent.logout(this.config);
    }
  }

  public async startVoiceCall(activity: PhoneActivity) {
    const recordingAllowed: boolean = await this.phoneCallDataService.getActiveConsent(activity.contacts[0].ID, "100000001");
    //@ts-ignore
    ClinkAgent.previewOutcall(
      {
        tel: activity.selectedMobileNumber,
        userField: JSON.stringify({ phone_activity_id: activity.ID }),
        isObRecord: recordingAllowed ? 1 : 0
      });
    this._voicecallStatus$.next('CALLING');
  }

  public stopVoiceCall() {
    //@ts-ignore
    ClinkAgent.previewOutcallCancel({});
  }

  public endVoiceCall() {
    //@ts-ignore
    ClinkAgent.previewOutcallCancel({});
    this._voicecallStatus$.next('CALL_ENDED');
  }

  public set voiceCallStatus(status: VoiceCallStatus) {
    this._voicecallStatus$.next(status);
  }

  public startTimer() {
    this.time = 0;
    this._formattedTime = `00:00:00`;
    this.intervalId = setInterval(() => {
      let t = this.convertSecondsToTime(this.time);
      this.hours = t.hours;
      this.minutes =t.minutes;
      this.seconds = t.seconds;
      this.time++;
      this._formattedTime = `${_.padStart(this.hours + '', 2, '0')}:${_.padStart(this.minutes + '', 2, '0')}:${_.padStart(this.seconds + '', 2, '0')}`;
    }, 1000);
  }

  public get formattedTime() {
    return this._formattedTime;
  }

  public stopTimer() {
    this.time = 0;
    clearInterval(this.intervalId);
  }

  private subscribeToCallEvents() {
    const callAttended = (async function (e) {
      this._voicecallStatus$.next('CALL_ATTENDED');
      this.startTimer();
      console.log("EventType.PREVIEW_OUTCALL_BRIDGE : ", e);
      const payload: any = {
        indskr_voicecallid: e.mainUniqueId,
        indskr_phonenumber: e.customerNumber,
        indskr_starttime: new Date().getTime()
      }
      const response = await this.phoneCallDataService.createCallActivity(this.activityService.selectedActivity.ID, payload);
      payload.indskr_callactivityid = response?.indskr_callactivityid;
      this.callActivity = payload;
    }).bind(this);

    const callConnected = (async function (e) { this._voicecallStatus$.next('CALL_CONNECTED'); console.log("EventType.PREVIEW_OUTCALL : ", e); }).bind(this);
    const callOutStarted = (async function (e) { this._voicecallStatus$.next('CALL_STARTED'); console.log("EventType.PREVIEW_OUTCALL_START : ", e); }).bind(this);
    const callOutRinging = (async function (e) { this._voicecallStatus$.next('RINGING'); console.log("EventType.PREVIEW_OUTCALL_RINGING : ", e); }).bind(this);
    const noAnswer = (async function (e) { this._voicecallStatus$.next('LOGGED_IN'); console.log("EventType.NO_ANSWER : ", e) }).bind(this);

   
    //@ts-ignore
    ClinkAgent.setup({ sipPhone: false, debug: false }, function () {
      // //@ts-ignore
      // ClinkAgent.registerListener(ClinkAgent.EventType.STATUS, function(e) {console.log("STATUS : ", e)});
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_START, callOutStarted); // Call out to the customer
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_RINGING, callOutRinging); // Customer Ringing the Bell when Call out
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_BRIDGE, callAttended); // Customer Answering when Call out
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.RINGING, function (e) { console.log("EventType.RINGING : ", e) }); // The pop-up、Ring the bell
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL, callConnected); // Call out
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.KICKOUT, function (token) { console.log("EventType.KICKOUT : ", token) }); // Kicked off the line
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.BREAK_LINE, function (token) { console.log("EventType.BREAK_LINE : ", token) });  // break line
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.INTERACT_RETURN, function (token) { console.log("EventType.INTERACT_RETURN : ", token)}); // Return of interaction
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.QUEUE_STATUS, function (token) { console.log("EventType.QUEUE_STATUS : ", token)}); // Status of queue
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.OB_REMEMBER_BUSY, function (token) { console.log("EventType.OB_REMEMBER_BUSY : ", token)}); // Call out memory failed to answer
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.NO_ANSWER, noAnswer); // Missed call
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.SIP_DISCONNECTED, function (token) { console.log("EventType.SIP_DISCONNECTED : ", token)}); // Soft phone break line
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.SIP_CONNECTED, function (token) { console.log("EventType.SIP_CONNECTED : ", token)}); // Soft phone Connection
      //@ts-ignore
      ClinkAgent.registerListener(ClinkAgent.EventType.SIP_REGISTERED, function (token) { console.log("EventType.SIP_REGISTERED : ", token) }); // Soft phone Registration
    });
  }

  convertSecondsToTime(durationInSeconds: number) {
    durationInSeconds = _.round(durationInSeconds, 0);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    return { hours, minutes, seconds };
  }
}