import {Component, ViewChild, AfterViewInit, ElementRef, OnDestroy, Input} from '@angular/core';
import {NavParams, PopoverController} from "@ionic/angular";
import {XperiencesService} from "../../../../services/xperiences/xperiences.service";
import {UIService} from "../../../../services/ui/ui.service";
import {DeviceService} from "../../../../services/device/device.service";
import {ActivityDataService} from "../../../../data-services/activity/activity.service";
import {ActivityService} from "../../../../services/activity/activity.service";
import {FooterService, FooterViews} from "../../../../services/footer/footer.service";
import {SampleService, SamplingDetailsViewMode} from "../../../../services/sample/sample.service";
import {EmailService} from "../../../../services/email-templates/email.service";
import {AccesingMode, CaseManagementService} from "../../../../services/case-management/case-management.service";
import {CaseManagementDataService} from "../../../../data-services/case-management/case-management.data.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../services/authentication.service";
import {ContactOfflineService} from "../../../../services/contact/contact.service";
import {NotificationService, ToastStyle} from "../../../../services/notification/notification.service";
import {NavigationService, PageName} from "../../../../services/navigation/navigation.service";
import {TrackService} from "../../../../services/logging/tracking.service";
import {CustomerEventsService} from "../../../../services/customer-event/customer-events.service";
import {
  CentralPlan, CustomerJourney,
  InterestInsight,
  JourneyInsight, RelationshipInsight,
  User
} from "../../../../classes/xperiences/trending.customer.class";
import {MultiSelectPopover} from "../../../multi-select-popover/multi-select-popover";
import {EventRegistration} from "../../../../classes/customer-event/customer-event.class";
import {Contact} from "../../../../classes/contact/contact.class";
import {MarketingEmailDetailsComponent} from "../../../email/marketing-email-details/marketing-email-details";
import {ChannelType} from "../../../../classes/consent/channel.class";
import {AppointmentActivity} from "../../../../classes/activity/appointment.activity.class";
import {ActivityType} from "../../../../classes/activity/activity.class";
import {EmailActivity, EmailViewType} from "../../../../classes/activity/email.activity.class";
import {CaseActivity} from "../../../../classes/case-intake/case-activity.class";
import {CustomerJourneyComponent} from "../../../contact/customer-insight/customer-journey/customer-journey";
import {MorePlansInsightsComponent} from "../more-plans-insights/more-plans-insights";
import {Subscription} from "rxjs";
import { SampleActivity } from '../../../../classes/activity/sample.activity.class';
import { DatePipe } from '@angular/common';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { NewSampleActivityComponent } from '@omni/components/activity/new-sample-activity/new-sample-activity';

/**
 * Generated class for the JourneyInsightsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'journey-insights',
  templateUrl: 'journey-insights.html',
  styleUrls:['journey-insights.scss']
})
export class JourneyInsightsComponent {

  @Input("source")
  public source: any;

  journeyInsightTypes: any[] = [
    { text: this.translate.instant('ALL_ACTIVITIES'), value: "All" },
    { text: this.translate.instant('MEETINGS'), value: "appointment" },
    { text: this.translate.instant('MESSAGES'), value: "email" },
    { text: this.translate.instant('TIME_OFF'), value: "TimeOff" },
    { text: this.translate.instant('XPERINECES_ALLOCATIONS'), value: "indskr_sampledrop" },
    { text: this.translate.instant('CUSTOMERINQUIRIES'), value: "medical_inquiry" },
  ];

  productFilterOptions: any[] = [
    { text: this.translate.instant('XPERIENCES_FILTER_ALL_PRODUCTS'), value: "All" },
    { text: this.translate.instant('XPERIENCES_FILTER_COMPETITOR_PRODUCT'), value: "Competitor Product", disableLabel: false },
  ];

  journeyInsightsFilterPopoverData: { text: string; value: string; displayIcon?: string; multiselect?: boolean; selectedValues?: string[]; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  event : EventRegistration;
  filterSubscription: Subscription;

  constructor(
    private popoverCtrl: PopoverController,
    public xperiencesService: XperiencesService,
    private navParams: NavParams,
    private uiService: UIService,
    public device: DeviceService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public sampleService: SampleService,
    private emailService: EmailService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private contactService: ContactOfflineService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    private trackingService: TrackService,
    public customerEventsService: CustomerEventsService,
    private datePipe: DatePipe

  ) { }

  ngOnInit() {
    if (this.source) {
      this.source.forEach((insight) => {
        insight.productsText = this.xperiencesService.getProductsText(insight.products);
        if (insight.activitytypecode === 'journey_insight') {
          insight.translatedTitle = this.getJourneyTouchPointTitle(insight.title);
          insight.journeyInsightsText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.xperiencesService.getUserNamesText(insight.users);
        } else {
          //Change in the future, if required: Temp fix for Bug: OMNI-16300
          insight.translatedTitle = this.constructInsightTitle(insight.activitytypecode, insight.users, insight.channeltype, insight.title);
        }
      });
    }

    this.xperiencesService.filterSubject.next(false);

    this.journeyInsightsFilterPopoverData = [
      {
        text: this.translate.instant('XPERIENCES_FILTER_JOURNEYS'),
        value: 'All',
        items: [
          { text: this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS'), value: 'All' },
          { text: this.translate.instant('XPERIENCES_FILTER_INSIGHTS_PLUS_JOURNEYS'), value: 'journey_insight' }
        ],
        handler: (selectedItem, item, itemRef) => {
          item.value = selectedItem.value;
          if(selectedItem.value === item.items[1].value &&
            (itemRef.parent.item.items[2].value ===  itemRef.parent.item.items[2].items[1].value)) {
            this.xperiencesService.xpJourneyInsightsFilterText = selectedItem.text + ' ' + this.translate.instant('WITH') + ' ' + itemRef.parent.item.items[2].items[1].text;
          } else {
            this.xperiencesService.xpJourneyInsightsFilterText = selectedItem.text;
          }
          // hiding the filter lables based on the selected value
          if (selectedItem.value === item.items[1].value) {
            itemRef.parent.items[2].items[1].disableLabel= false;
            itemRef.parent.items[1].items.forEach((item, index) => {
              if(index > 0) {
                item.disableLabel = true;
                item.checked = false;
              }
            });
            itemRef.parent.items[1].value = 'All';
          } else {
            itemRef.parent.items[2].items[1].disableLabel = true;
            itemRef.parent.items[2].items[1].checked = false;
            itemRef.parent.items[1].items.forEach((item,index) => {
              if(index > 0) {
                item.disableLabel = false;
              }
            });
            itemRef.parent.items[2].value = 'All';
          }
          this.xperiencesService.journeyInsightsFilterValue = selectedItem.value;
        },
      },
      {
        text: this.translate.instant('ACTIVITIES'),
        value: '',
        multiselect: true,
        items: this.journeyInsightTypes.map(b => ({ text: b.text, value: b.value })),
        handler: (selectedItem, item, itemRef) => {
          if (selectedItem.value === item.items[0].value) {
            itemRef.selectedValues = [item.items[0].value];
          } else {
            let index = itemRef.selectedValues.indexOf(item.items[0].value);
            if (index > -1) {
              itemRef.selectedValues.splice(index,1);
            }
          }
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          if(itemRef.selectedValues.length === 0) {
            itemRef.selectedValues = [item.items[0].value];
            this.xperiencesService.xpJourneyInsightsFilterText = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
          }
          this.xperiencesService.activitiesFilterValueList = itemRef.selectedValues;
          let labelText = '';
          if(this.xperiencesService.activitiesFilterValueList.length > 1) {
            labelText = this.translate.instant('XPERIENCES_FILTER_ALL_JOURNEY_INSIGHTS');
          } else {
            if (this.xperiencesService.activitiesFilterValueList.length === 1 && this.xperiencesService.activitiesFilterValueList[0] === 'All') {
              if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[0].value) {
                labelText = itemRef.parent.item.items[0].items[0].text;
              } else if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[1].value) {
                labelText = itemRef.parent.item.items[0].items[1].text;
              }
            } else {
              let activityType = this.getJourneyInsightsFilterLabel(this.xperiencesService.activitiesFilterValueList[0]);
              labelText = (this.translate.instant('ALL') + ' ' + activityType + ' ' +
                this.translate.instant('ACTIVITIES'));
            }
          }
          this.xperiencesService.xpJourneyInsightsFilterText = labelText;
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: 'All',
        items: this.productFilterOptions.map(b => ({ text: b.text, value: b.value, disableLabel: b.disableLabel })),
        handler: (selectedItem, item, itemRef) => {
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          item.value = selectedItem.value;
          this.xperiencesService.productFilterValue = item.value;
          if (selectedItem.value === item.items[1].value) {
            this.xperiencesService.xpJourneyInsightsFilterText = this.translate.instant('XPERIENCES_FILTER_INSIGHTS_PLUS_JOURNEYS') + ' ' + this.translate.instant('WITH') + ' ' + selectedItem.text;
          } else {
            if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[0].value) {
              this.xperiencesService.xpJourneyInsightsFilterText = itemRef.parent.item.items[0].items[0].text;
            } else if (itemRef.parent.item.items[0].value ===  itemRef.parent.item.items[0].items[1].value) {
              this.xperiencesService.xpJourneyInsightsFilterText = itemRef.parent.item.items[0].items[1].text;
            }
          }
        }
      }
    ];
    this.setJourneyInsightsFilterState();

    this.filterSubscription = this.xperiencesService.filterObs$.subscribe((val) => {
      if (val) this.setJourneyInsightsFilterState();
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  get filteredInsights(): any[] {
    let filteredObjs: any[] = [];
    if (this.source) {
      if (this.xperiencesService.journeyInsightsFilterValue == "All" && this.xperiencesService.productFilterValue == "All" &&
        (this.xperiencesService.activitiesFilterValueList.length === 1 && this.xperiencesService.activitiesFilterValueList[0] === 'All')) {
        filteredObjs = this.source;
      }
      else {
        if (this.xperiencesService.activitiesFilterValueList.length > 0) {
          if (this.xperiencesService.activitiesFilterValueList.length === 1 && this.xperiencesService.activitiesFilterValueList[0] === 'All') {
            filteredObjs = this.source;
          } else {
            filteredObjs = this.source.filter((e) => {
              if (this.xperiencesService.activitiesFilterValueList.findIndex((afl) => afl === e.activitytypecode) >= 0) {
                return e;
              }
            });
          }
        }
        if (this.xperiencesService.journeyInsightsFilterValue.length > 0 && this.xperiencesService.journeyInsightsFilterValue !== 'All') {
          filteredObjs = filteredObjs.filter((e) => e.activitytypecode === this.xperiencesService.journeyInsightsFilterValue);
        }
        if (this.xperiencesService.productFilterValue.length > 0 && this.xperiencesService.productFilterValue !== 'All') {
          filteredObjs = filteredObjs && filteredObjs.length > 0 ?
            this.source.filter((i) => {
              if (i.products && i.products.some((e) => e.productname === this.xperiencesService.productFilterValue)) {
                return i;
              }
            }): [];
        }
      }
    }

    return filteredObjs;
  }

  getJourneyTouchPointTitle(journeyTouchPoint: string) {
    switch (journeyTouchPoint) {
      case 'Meeting':
        return this.translate.instant('MEETING');
      case 'Email':
        return this.translate.instant('EMAIL');
      case 'Event Participant':
        return this.translate.instant('XPERIENCES_EVENT_PARTICIPANT');
      case 'Event Speaker':
        return this.translate.instant('XPERIENCES_EVENT_SPEAKER');
      case 'Event Consultant':
        return this.translate.instant('XPERIENCES_EVENT_CONSULTANT');
      case 'Website':
        return this.translate.instant('XPERIENCES_EVENT_WEBSITE');
      case 'Co Author':
        return this.translate.instant('XPERIENCES_CO_AUTHOR');
      case 'Co Investigator':
        return this.translate.instant('XPERIENCES_CO_INVESTIGATOR');
      case 'Hospital Department':
        return this.translate.instant('XPERIENCES_HOSPITAL_DEPARTMENT');
      case 'Co Presenter':
        return this.translate.instant('XPERIENCES_CO_PRESENTER');
      default:
        return this.translate.instant('XPERIENCES_JOURNEY_TOUCHPOINT');
    }
  }

  constructInsightTitle(activityTypeCode: string, users: User[], channeltype: string, title?:string): string {
    let activityName = '';
    let userNamesText = this.xperiencesService.getUserNamesText(users);
    switch (activityTypeCode) {
      case 'appointment':
        activityName = this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      case 'email':
        if (channeltype === 'email') activityName = this.translate.instant('XPERIENCES_EMAIL');
        else {
          let channel: string = "";
          if (channeltype === 'facebook') channel = this.translate.instant('XPERIENCES_FACEBOOK_MESSAGE');
          else if (channeltype === 'whatsapp') channel = this.translate.instant('XPERIENCES_WHATSAPP_MESSAGE');
          else if (channeltype === 'sms') channel = this.translate.instant('XPERIENCES_TEXT_MESSAGE');
          else if (channeltype === 'phone') channel = this.translate.instant('XPERIENCES_PHONE_MESSAGE');
          else if (channeltype === 'sms external') channel = this.translate.instant('XPERIENCES_SMS_EXTERNAL_MESSAGE');
          else if (channeltype === 'whatsapp share') channel = this.translate.instant('XPERIENCES_WHATSAPP_SHARE');
          else if (channeltype === 'sms share') channel = this.translate.instant('XPERIENCES_SMS_SHARE');
          else if (channeltype === 'sms-textlocal') channel = this.translate.instant('XPERIENCES_WHATSAPP_SMS_TEXTLOCAL');

          //Change in the future, if required: Temp fix for Bug: OMNI-16300
          else channel = title + " message by ";
          activityName = channel;
        }
        break;
      case 'medical_inquiry':
        return this.translate.instant('XPERIENCES_MEDICAL_INQUIRY');
      case 'indskr_sampledrop':
        activityName = this.translate.instant('XPERIENCES_ALLOCATION_ORDER');
        break;
      case 'marketing_email':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EMAIL');
        break;
      case 'event':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EVENT');
        break;
      case 'meeting':
        activityName =  this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      default:
        return '';
    }
    return activityName + ' ' + userNamesText;
  }

  openPlanInsightFilterPopup() {
    this.toggleFilterLabelsVisibility();
    this.popoverCtrl.create({component: MultiSelectPopover,componentProps: { root: this.journeyInsightsFilterPopoverData }, event:event, cssClass:'journey-inisghts-filter'}).then((data)=>data.present());
    this.setJourneyInsightsFilterState();
  }

  toggleFilterLabelsVisibility() {
    if (this.xperiencesService.xpJourneyInsightsFilterText.indexOf(this.translate.instant('XPERIENCES_FILTER_INSIGHTS_PLUS_JOURNEYS')) >= 0) {
      this.toggleLabelsVisibility(true);
    }
    else {
      this.toggleLabelsVisibility(false);
    }
  }

  toggleLabelsVisibility(disableLabel: boolean) {
    this.journeyInsightsFilterPopoverData[1].items = this.journeyInsightTypes.map((type, index) => {
      if(index > 0) {
        type.disableLabel = disableLabel;
      }
      return type;
    });
    this.journeyInsightsFilterPopoverData[2].items = this.productFilterOptions.map((option, index) => {
      if(index > 0) {
        option.disableLabel = !disableLabel;
      }
      return option;
    });
  }

  getJourneyInsightsFilterLabel(activitytype: string) {
    let activityName = '';
    switch(activitytype) {
      case "appointment":
        activityName = this.translate.instant('XPERIENCES_MEETING');
        break;
      case "email":
        activityName = this.translate.instant('MESSAGE');
        break;
      case "TimeOff":
        activityName = this.translate.instant('TIME_OFF');
        break;
      case "indskr_sampledrop":
        activityName = this.translate.instant('XPERINECES_ALLOCATIONS');
        break;
      case "medical_inquiry":
        activityName = this.translate.instant('XPERIENCES_CUSTOMER_INQUIRY');
        break;
      default:
        break;
    }
    return activityName;
  }

  async displayActivityDetails(data: any) {
    if (this.device.isOffline || data.activitytypecode === 'event') return;
    if(data.activitytypecode == 'journey_insight' || data['insights_plus']) {
      this.displayInsightsPlusDetails(data);
      return;
    }

    if (data.type == 'centralplan') {
      await this.uiService.displayLoader();
      await this.xperiencesService.getMarketingEmails(data.id).then(async (response) => {
        const centralPlanData: CentralPlan = {
          type: data.title,
          customer: this.contactService.getContactByID(this.xperiencesService.selectedXpContact.contactid),
          products: data.products,
          subject: response['msdyncrm_subject'],
          date: data.date,
          previewContent: response['msdyncrm_emailbody'] ? response['msdyncrm_emailbody'] : ''
        }
        this.navService.pushChildNavPageWithPageTracking(
          MarketingEmailDetailsComponent,
          PageName.MarketingEmailDetailsComponent,
          PageName.MarketingEmailDetailsComponent,
          {
            viewData: { email: centralPlanData, centralPlan: data }
          });
        await this.uiService.dismissLoader();
      }).catch(async () => {
        await this.uiService.dismissLoader();
      });
      return;
    }

    if (data.type == 'prediction' && (data.activitytypecode === 'appointment' ||
      data.activitytypecode === 'email' || data.activitytypecode === 'indskr_sampledrop')) {
      const contact: Contact = this.contactService.getContactByID(this.xperiencesService.selectedXpContact.contactid);
      if (data.activitytypecode == 'appointment') {
        await this.xperiencesService.createMeeting([contact], data.date);
        if (this.activityService.selectedActivity) this.footerService.initButtons(FooterViews.Activities);
      } else if (data.activitytypecode === 'indskr_sampledrop') {
        const contactCustomeAllocations = this.sampleService.contactCustomerSampleAllocations.find(o => {
          return o.contactId == contact.ID
        });
        if (!contactCustomeAllocations || !contactCustomeAllocations.currentCustomerSampleAllocations ||
          contactCustomeAllocations.currentCustomerSampleAllocations.length == 0) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_ACTIVE_ALLOCATIONS'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createAllocationOrder(data.date, contact);
        }
      }
      else {
        const channel: ChannelType = this.xperiencesService.getChannelType(data.channeltype);
        if (channel === ChannelType.EMAIL && (!contact.emailAddressList || contact.emailAddressList.length === 0)) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_EMAIL'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if (channel === ChannelType.FACEBOOK && (contact.indskr_facebookpsid || '') === '') {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_FACEBOOK_ID'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if ((channel === ChannelType.WHATSAPP || channel === ChannelType.SMS) && ((contact && contact.mobilePhone) || '') === '') {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_PHONE_NUMBER'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createEmail(data.date, data.channeltypeid, channel, [contact]);
        }
      }
      data.type = 'teamplan';
      data.id = this.activityService.selectedActivity.ID;
      let loggedInUser = this.authService.user;
      let users: User[] = [{
        userid: loggedInUser.systemUserID,
        username: loggedInUser.displayName
      }];
      data.users = users;
      data.translatedTitle = this.constructPlansTitle(data.type, data.activitytypecode, data.channeltype, users);
    } else {
      let foundActivity = this.activityService.getActivityByID(data.id);
      this.uiService.showRightPane = true;
      this.uiService.showNewActivity = false;
      if (data.activitytypecode == "appointment") {
        if (!foundActivity) {
          await this.activityDataService.getActivityByIdOnline(data.id, "appointment")
            .then(async response => {
              foundActivity = new AppointmentActivity(response);
            });
        }
        foundActivity.isFromXperiences = true;
        this.activityService.selected = foundActivity;
        this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
        this.uiService.showCancelDoneOnActivityDetails = true;
        await this.activityDataService.updateActivityDetails(foundActivity);
        if (this.activityService.selectedActivity) {
          this.footerService.initButtons(FooterViews.Activities);
        }
      }
      else if (data.activitytypecode == "email") {
        let activity = new AppointmentActivity({ activityid: data.id });
        activity.type = ActivityType.Email;
        activity.isFromXperiences = true;
        await this.uiService.displayLoader();
        await this.activityDataService.getRealTimeActivityDetails(activity).then(async(email) => {
          await this.uiService.dismissLoader();
          if (!email) {
            return;
          }
          this.activityService.selected = email;
          this.activityService.selectedActivity = email;
          this.emailService.selectedActivity = <EmailActivity>email;
          this.emailService.selectedActivity.isFromXperiences = true;
          this.emailService.setCurrentEmail(email);
          this.emailService.viewType = EmailViewType.FROM_XPERIENCES;
          this.uiService.showNewActivity = false;
          this.uiService.activeView = 'Email';
          this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent);
          this.activityService.updateEmailAddressInEmailActivityParties(email as EmailActivity);
        }).catch(async() => {
          await this.uiService.dismissLoader();
          console.log("Failed to load email activity:" + activity.ID);
        });
      }
      else if (data.activitytypecode == "medical_inquiry") {
        await this.uiService.displayLoader();
        await this.caseDataService.findCaseById(data.id).then(
          async res => {
            if (res) {
              for (let key in res) {
                if (key.charAt(0) === "_") {
                  let a = key.substring(1, key.length);
                  res[a] = res[key];
                  delete res[key];
                }
              }
              let iCase: CaseActivity;
              iCase = new CaseActivity(res);
              iCase = await this.caseService.reInitDataDependency(iCase);
              this.activityService.selectedActivity = iCase;
              this.caseService.assignSelectedCase(iCase);
              this.caseService.accessedFrom = AccesingMode.XPERIENCE_INSIGHTS;
              this.uiService.showNewActivity = false;
              this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.CaseManagementDetailComponent);
            }
            await this.uiService.dismissLoader();
          }).catch(async() => {
          console.log("Failed to load customer inquiry:" + foundActivity.ID);
          await this.uiService.dismissLoader();
        });
      }
      else {
        if (foundActivity && foundActivity.type == ActivityType.Sample) {
          this.activityService.selected = foundActivity;
        } else {
        // makes online call to fetch the allocation order details
          let foundActivity = new SampleActivity(await this.activityDataService.getActivityByIdOnline(data.id, "sampledrop"));
          this.activityService.selected = foundActivity;
          await this.activityDataService.updateActivityDetails(foundActivity);
      }
      this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'Sample';
      this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
      }
    }
  }

  displayInsightsPlusDetails(insight: JourneyInsight) {
    if((insight as JourneyInsight).activitytypecode == 'journey_insight') {
      let index = insight.users.findIndex((user) => user.userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayJourneyPlusInsights(insight as JourneyInsight);
      }
      return;
    }
  }

  constructPlansTitle(type: string, activitytypecode: string, channeltype: string, users: User[]) {
    let title = '';
    if (type === 'prediction') {
      switch (activitytypecode) {
        case "indskr_sampledrop":
          title = this.translate.instant('XPERIENCES_GENEE_ALLOCATION_LABEL');
          break;
        case "task":
          title = "Follow-up";
          break;
        case "appointment":
          title = this.translate.instant('XPERIENCES_GENEE_MEETING_LABEL');
          break;
        case "email":
          if (channeltype === 'email') title = this.translate.instant('XPERIENCES_GENEE_EMAIL_LABEL');
          else {
            if (channeltype === 'facebook') title = this.translate.instant('XPERIENCES_GENEE_FACEBOOK_LABEL');
            else if (channeltype === 'whatsapp') title = this.translate.instant('XPERIENCES_GENEE_WHATSAPP_LABEL');
            else if (channeltype === 'sms') title = this.translate.instant('XPERIENCES_GENEE_SMS_LABEL');
            else if (channeltype === 'sms external') title = this.translate.instant('XPERIENCES_SMS_EXTERNAL_MESSAGE');
            else if (channeltype === 'whatsapp share') title = this.translate.instant('XPERIENCES_WHATSAPP_SHARE');
            else if (channeltype === 'sms share') title = this.translate.instant('XPERIENCES_SMS_SHARE');
            else if (channeltype === 'sms-textlocal') title = this.translate.instant('XPERIENCES_WHATSAPP_SMS_TEXTLOCAL');
          }
          break;
        case "medical_inquiry":
          title = this.translate.instant('XPERIENCES_GENEE_MEDICAL_INQUIRY');
          break;
      }
      return title;
    } else if (type === 'teamplan' || type === 'centralplan') {
      if (activitytypecode === 'medical_inquiry') {
        return this.translate.instant('XPERIENCES_TEAM_MEDICAL_INQUIRY');
      }
      return this.constructInsightTitle(activitytypecode, users, channeltype);
    }
  }

  async displayJourneyPlusInsights(insight: JourneyInsight) {
    await this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getJourneyInsigthData(insight.id);
    await this.uiService.dismissLoader();
    let joureyInsightData: CustomerJourney = {
      type: insight.title,
      description: insightData['indskr_description'] && insightData['indskr_description'].length > 0 ? insightData['indskr_description']: this.translate.instant('NO_DESCRIPTION'),      date: insight.date,
      confidence:  insightData['indskr_strength'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strength']).label) : undefined,
      indskr_competitorproduct: insightData['indskr_competitorproduct'] ? insightData['indskr_competitorproduct']: undefined
    };
    if(insight.products.length > 0) {
      joureyInsightData.selectedProduct = {
        title: insight.productsText,
        id: insight.products[0].productid === 'competitor product' ? 'indskr_competitorproduct' : insight.products[0].productid
      }
    } else {
      if (insightData["indskr_product"]) {
        joureyInsightData.selectedProduct = {
          title: insightData["productName"],
          id: insightData["indskr_product"]
        }
      }
    }
    this.navService.pushChildNavPageWithPageTracking(CustomerJourneyComponent, PageName.CustomerJourneyComponent,
      PageName.ActivitiesPageComponent, { journey: joureyInsightData, readOnly: true });
  }

  openMorePlansInsights() {
    this.navService.pushChildNavPageWithPageTracking(MorePlansInsightsComponent,
      PageName.MorePlansInsightsComponent,
      PageName.JourneyInsightsComponent,
      {
        title: this.translate.instant('CONTACT_JOURNEY_INSIGHT'),
        data: this.source,
        selectedFilter: this.xperiencesService.xpJourneyInsightsFilterText,
        type: 'journey'
      })
  }

  setJourneyInsightsFilterState() {
    this.journeyInsightsFilterPopoverData[1].selectedValues =  this.xperiencesService.activitiesFilterValueList;
    this.journeyInsightsFilterPopoverData[0].value =  this.xperiencesService.journeyInsightsFilterValue;
    this.journeyInsightsFilterPopoverData[2].value =  this.xperiencesService.productFilterValue;
    this.toggleFilterLabelsVisibility();
  }

   getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, 'MMM dd', undefined, this.translate.currentLang);
  }

}
