<div class="wrapper">
  <ion-item lines="none" [ngClass]="inputData && inputData.customCssClass ? inputData.customCssClass : 'time-line-item' ">
    <ion-icon [src]="inputData.mainIcon" slot="start" class="item-icon"></ion-icon>
    <div class="header-text">
      <ion-text class="time-text" *ngIf="inputData.subheaderText"> {{ inputData.subheaderText }} 
        <span class="breaker">|</span>
        <ion-text class="date-text" [color]="inputData.subheaderText2Color" *ngIf="inputData.subheaderText2">{{ inputData.subheaderText2 }}</ion-text>
      </ion-text>
      <ion-label class="main-label">{{ inputData.headerText }}</ion-label>
    </div>
    <ion-button *ngIf="!inputData.hideEndArrow" [disabled]="inputData.disableEndArrow" fill="clear" slot="end" (click)="headerClick($event)">
      <ion-icon slot="icon-only" name="chevron-forward-outline" class="end-icon"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-row>
    <ion-col size="6" size-md="4" *ngFor="let item of inputData.dataToDisplay;let i=index" [ngClass]="{'ion-hide-sm-down' : i > 3}">
      <!-- show only 4 columns in mobile view -->
      <display-value (clicked)="popupClick($event)" [inputData]="item"></display-value>
    </ion-col>
  </ion-row>
</div>