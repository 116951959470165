export class LookupSearchResult {
  id: string;
  name: string;
  firstname: string;
  lastname: string;

  constructor(raw, idAttr, nameAttr) {
    this.id = raw[idAttr] || "";
    this.name = raw[nameAttr] || "";
    this.firstname = raw.firstname || "";
    this.lastname = raw.lastname || "";
  }
}