<div class="no-data-message" *ngIf="device.isOffline">{{'AVAILABLE_IN_ONLINE_ONLY' | translate}}</div>
<div class="timelineWrapper" *ngIf="!device.isOffline">
    <div *ngIf="!device.isOffline && (groupedActivitiesByAccount.length == 0 && accountTimelineFilter == 'All Activities') && accountOfflineService.isAccountTimelineLoaded" class="no-data-message">
        {{'ACCOUNT_TIMELINE_NO_DATA' | translate}}
    </div>
    <img *ngIf="!device.isOffline && groupedActivitiesByAccount.length == 0 && accountTimelineFilter == 'All Activities' && accountOfflineService.isAccountTimelineLoaded" class="os_background-list" [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios}" />

    <div class="loadingSpinnerContainer" *ngIf="!accountOfflineService.isAccountTimelineLoaded">
        <ion-spinner name="lines"></ion-spinner>
    </div>
    <div class="accountTimelineFilter" *ngIf="groupedActivitiesByAccount.length > 0 || (groupedActivitiesByAccount.length == 0 && accountTimelineFilter != 'All Activities')
        && accountOfflineService.isAccountTimelineLoaded">
        <ion-select #accountTimelineSelect (ionChange)="onTimelineTypeChange(accountTimelineSelect.value)" [(ngModel)]="accountTimelineFilter" interface="popover" class="timelineFilter" [selectedText]="getSelectedText(accountTimelineFilter)">
            <ion-select-option *ngFor="let option of filterOptions" [value]="option.value">{{option.displayText | translate: { globalCustomerText: utilityService.globalCustomerText } }}</ion-select-option>
        </ion-select>
    </div>
    <ion-list class="containerForData" *ngIf="groupedActivitiesByAccount.length > 0
      && accountOfflineService.isAccountTimelineLoaded">
        <ion-item-group class="timeline-group" *ngFor="let group of groupedActivitiesByAccount">

            <ion-item-divider slot="end" [id]="group.key">
                <div class="dividerBorder">

                </div>
                <div class="monthGroupName">
                    {{group.key}}
                </div>
            </ion-item-divider>
            <ion-item tappable *ngFor="let activity of group.list" (click)="openActivityDetails(activity)">
                <ion-grid>
                    <ion-row>
                        <ion-col size="3">
                            <div class="ion-float-center" size="3">
                                <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center">{{formattedDate(activity.scheduledStart)}}</h4>
                            </div>
                        </ion-col>
                        <ion-col size="8">
                            <div class="col-9 ion-float-left ion-margin-right" size="8">
                                <h3 class="wrap-text">
                                    <div *ngIf="activity.type == 'Appointment'">{{activity.subject}}</div>
                                    <div *ngIf="activity.type != 'Appointment' && activity.type != 'StoreCheck' && activity.type != 'CaseIntake' && activity.type != 'indskr_order' && activity.type != 'PhoneCall' && activity.type != 'SurgeryOrder' && activity.type != 'Event' && activity.type != 'ProcedureTracker' && activity.type != 'CustomerSurvey'">
                                        <span class="boldText" margin-right>{{activity.type}} </span>{{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'CaseIntake'"> <span class="ion-margin-right">{{'INQUIRY' | translate}} - </span>{{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'indskr_order'"><span class="ion-margin-right">{{'NEW_ACTIVITY_SALES_ORDER' | translate}} - </span>{{activity.orderNumber}}</div>
                                    <div *ngIf="activity.type == 'PhoneCall'"><span class="ion-margin-right">{{ 'NEW_ACTIVITY_PHONECALL' | translate}} -</span> {{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'SurgeryOrder' || activity.type == 'ProcedureTracker'"><span class="ion-margin-right">{{activity.subject}}</span></div>
                                    <div *ngIf="activity.type == 'Event'">{{ 'EVENT' | translate}} - {{activity.name}}</div>
                                    <div *ngIf="activity.type == 'StoreCheck'">{{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'CustomerSurvey'">{{ 'SURVEY' | translate}} - {{activity.subject}}</div>
                                </h3>
                                <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'PhoneCall' || activity.type == 'StoreCheck'">
                                    <div class="ion-margin-right">{{((activity.meetingOwner == userName)?'MET_BY_ME': 'MET_BY_WITH_TEXT' ) | translate : { text : activity.meetingOwner } }} </div>
                                    <div class="ion-margin-right">
                                        {{activity.selectedMobileNumber}} </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'CaseIntake'">
                                    <span class="ion-margin-right">
              {{'ASSIGNED_TO' | translate}} {{activity.meetingOwner}},
            </span>
                                    <span>{{activity.statusString}}</span>
                                </h4>

                                <h4 *ngIf="activity.type == 'indskr_order'">
                                    <span class="ion-margin-right">
                                      {{activity.formattedTotalAmount}}
                                    </span>
                                    <span>{{activity.orderStatusString}}</span>
                                </h4>
                                <h4 *ngIf="activity.type == 'ProcedureTracker'"><span class="ion-margin-right">{{activity.accountNameString}}</span></h4>
                                <h4 *ngIf="activity.type == 'SurgeryOrder'">
                                    <span class="ion-margin-right" *ngIf="activity.products && activity.products.length != 0">
                                    {{activity.products[0].productName + (activity.products.length>1?('+'+(activity.products.length-1)):'')}}, {{activity.accountNameString}}
                                  </span>
                                    <span class="ion-margin-right" *ngIf="!activity.products || activity.products.length == 0">{{activity.accountNameString}}</span>
                                </h4>
                              <h4>
                                <div *ngIf="activity.type == 'Event' && activity.completedby">
                                  {{ (activity.completedby == userName ? 'EVENT_COMPLETED_BY_ME': 'EVENT_COMPLETED_BY_OWNER') | translate : {text :
                                  activity.completedby} }}
                                </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'CustomerSurvey'">
                                <div>{{activity.surveyFrequency}}</div>
                                <div>{{activity.surveyedBy}}</div>
                              </h4>

                                <!-- <h4 *ngIf="activity.type == 'PhoneCall'">
                                    <span class="ion-margin-right">
              {{activity.selectedMobileNumber}} </span>
                                </h4> -->
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-item-group>
    </ion-list>
</div>