import { Component, OnDestroy, EventEmitter, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { InboundMeetingRequestDataForPopup } from '../../../models/inbound-meeing-request-data-model';
import { InboundMeetingService } from '../../../services/meeting/inbound-meeting.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'inbound-popover',
    templateUrl: 'inbound-popover.html',
  styleUrls:['inbound-popover.scss']
})
export class InboundPopoverComponent implements OnDestroy {
    title: string;
    name: string;
    inboundRequestArray: InboundMeetingRequestDataForPopup[];
    inboundRequestArray$: Subscription;
    @Input('evEmitter') evEmitter: any

    constructor(
      public inboundMeetingService: InboundMeetingService,
      public translate:TranslateService,
      public popoverCtrl: PopoverController
      ) {
        this.inboundRequestArray$ = inboundMeetingService.inboundMeetingRequestArrayObservable.subscribe((requestArray: InboundMeetingRequestDataForPopup[]) => {
            this.inboundRequestArray = requestArray;
            this.title = this.inboundRequestArray.length > 1 ? this.translate.instant('POP_INCM_CONNECTIONS') + `(${this.inboundRequestArray.length - 1})` : this.translate.instant('POP_INCM_CONNECTION');
            this.name = this.inboundRequestArray && this.inboundRequestArray[0] ? this.inboundMeetingService.getFormattedCallerName(this.inboundRequestArray[0]) : '';
        });

    }

    ngOnInit() {
      if (!this.evEmitter) {
        this.popoverCtrl.dismiss({action: 'NO_DATA'});
      }
    }

    ngOnDestroy() {
        if (this.inboundRequestArray$) {
            this.inboundRequestArray$.unsubscribe();
        }
    }

    accept() {
        this.evEmitter.emit({ action: 'ACCEPT' });
    }

    decline() {
        this.evEmitter.emit({ action: 'DECLINE' });
    }
}
