import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { DeviceService } from '@omni/services/device/device.service';
import {format, isToday, isTomorrow, isYesterday, startOfDay} from 'date-fns';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import * as moment from 'moment';
import { PopoverComponent } from '@omni/components/popover/popover';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { Brand } from '../../../../classes/brand/brand.class';
import { CustomerJourney } from '../../../../classes/xperiences/trending.customer.class';
import { RepServices } from '../../../../data-services/rep/rep.services';
import { IndPageTitleViewDataModel } from '../../../../models/indPageTitleDataModel';
import { SelectListData } from '../../../../models/select-list-data-model';
import { AlertService } from '../../../../services/alert/alert.service';
import { BrandOfflineService } from '../../../../services/brand/brand.service';
import {DateTimeFormatsService} from '../../../../services/date-time-formats/date-time-formats.service';
import { FooterService, FooterViews } from '../../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../../services/logging/tracking.service';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { UIService } from '../../../../services/ui/ui.service';
import { XperiencesService } from '../../../../services/xperiences/xperiences.service';
import { OptionSelectionMode, PopoverMode, Utility } from '../../../../utility/util';
import { SelectListComponent } from '../../../shared/select-list/select-list';
import { StarRatingComponent } from '../../../shared/star-rating/star-rating.component';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the CustomerJourneyComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'customer-journey',
  templateUrl: 'customer-journey.html',
  styleUrls:['customer-journey.scss']
})
export class CustomerJourneyComponent implements OnInit{

  public journey: CustomerJourney;
  public tempJourney: CustomerJourney;
  public journeyDate: string;
  public formattedJourneyTime: string;
  private createMode = false;
  private readonly popoverMode = PopoverMode;
  public todayDate: string = moment(new Date()).format();
  shortMonth: string[] = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
  public interfaceOptions= {cssClass: 'journey-touchpoint-select'};
  readOnly = false;
  journeyHeaderModel: IndSectionHeaderViewDataModel;
  journeyDetailPageHeaderModel: IndPageTitleViewDataModel;
  private popover: HTMLIonPopoverElement;
  @ViewChild('date', {static:false}) journeyDatePicker;
  private isClickedDescription: boolean = false;

  constructor(
    public repService: RepServices,
    private readonly trackingService: TrackService,
    public xpService: XperiencesService,
    private readonly navService: NavigationService,
    public readonly footerService: FooterService,
    private readonly uiService: UIService,
    private readonly navParams: NavParams,
    private readonly events: Events,
    private readonly alertService: AlertService,
    public translate: TranslateService,
    private readonly brandService: BrandOfflineService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly modalCtrl: ModalController,
    private readonly popoverCtrl: PopoverController,
    private activityService: ActivityService,
    ) {
  }

  ngOnInit() {
    this.journey = (this.navParams.data && this.navParams.data.journey);
    this.readOnly = (this.navParams.data && this.navParams.data.readOnly);
    let date = new Date();
    if (this.journey) {
      date = new Date(this.journey.date);
    } else {
      this.createMode = true;
      this.journey = new CustomerJourney();
      this.journey.date = startOfDay(date).getTime();
      this.journey.confidence = undefined;
    }

    this.journeyDate = moment(date).format(this.dateTimeFormatsService.dateToUpper);
    this.formattedJourneyTime = this.xpService.formattedDate(date.getTime(), true);
    this.events.subscribe(OptionSelectionMode.SELCET_JOURNEY_INSIGHT_PRODUCT.toString(), (res) => {
      this.journey.selectedProduct = res.selected;
    });
    this.tempJourney = cloneDeep(this.journey);
    this.footerService.initButtons(FooterViews.Contacts);
    this.initInterestDetailsPageTitle();
    this.intializeJourneyHeder();
  }

  private initInterestDetailsPageTitle(): void {
    let buttons = [];
    if (this.readOnly) {
      buttons.push({
        id: 'cancel',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    } else {
      buttons.push({
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'right'
      }, {
        id: 'done',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: !this.journey.description || _.isEmpty(this.journey.description),
        align: 'right'
      })
    }
    this.journeyDetailPageHeaderModel = {
      id: 'customer-insight-journey',
      title: this.translate.instant('CONTACT_JOURNEY_INSIGHT'),
      controls: buttons
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.closepage(false);
        break;
      case 'done':
        this.closepage(true);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private intializeJourneyHeder() {
    this.journeyHeaderModel = {
      id: 'insights-journey-header',
      title: this.translate.instant('XPERIENCES_JOURNEY_DETAILS'),
      controls: [],
    };
  }

 private get inputJourneyText(): string {
    let journeyText = '';
    if (this.readOnly && this.journey.type) {
      journeyText = this.journey.type;
    } else if (this.readOnly && !this.journey.type) {
      journeyText = `${this.translate.instant('NO')} ${this.translate.instant('XPERIENCES_JOURNEY_TOUCHPOINT')}`;
    } else if (!this.readOnly){
      journeyText = this.journey.type;
    }
    return journeyText;
  }

  private get inputJourneyProductText(): string {
    let journeyText = '';
    if (this.readOnly ) {
      journeyText = this.journey.selectedProduct ? this.journey.selectedProduct.title : this.translate.instant('NO_PRODUCT');
    } else {
      if (this.journey.selectedProduct) {
        journeyText = this.journey.selectedProduct.title;
      }
    }
    return journeyText;
  }


  public joiurneyTouchPointFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('XPERIENCES_JOURNEY_TOUCHPOINT'),
      inputText: this.journey.type,
      customPlaceholderLabel: this.readOnly && !this.journey.type ? `${this.translate.instant('NO')} ${this.translate.instant('XPERIENCES_JOURNEY_TOUCHPOINT')}` : this.translate.instant('XPERIENCES_SELECT_JOURNEY_TOUCH_POINT'),
      id: 'journey-touch-point-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public joiurneyDescriptionFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.journey.description,
      inputValue: this.journey.description,
      customPlaceholderLabel:this.translate.instant('ENTER_DESCRIPTION'),
      id: 'journey-description-field',
      isReadOnly: this.readOnly,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired:  !this.readOnly,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedDescription && _.isEmpty(this.journey.description),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }


  public journeyDateFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DATE'),
      inputText: this.formattedJourneyTime,
      customPlaceholderLabel:this.translate.instant('CUSTOMER_INSIGHT_JDATE'),
      id: 'journey-date-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: !this.readOnly,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

   public journeyProductsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label:  this.translate.instant('PRODUCT'),
      inputText: this.journey.selectedProduct ? this.journey.selectedProduct.title : '',
      customPlaceholderLabel: this.readOnly && !this.journey.selectedProduct ? this.translate.instant('NO_PRODUCT') : this.translate.instant('SELECT_PRODUCT'),
      id: 'journey-product-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

   public journeyCompetitorProductsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label:  this.translate.instant('CONTACT_COMPETITOR_PRODUCT'),
      inputText: this.journey.indskr_competitorproduct,
      customPlaceholderLabel:this.translate.instant('ENTER_PRODUCT'),
      id: 'journey-competitor-product-field',
      isReadOnly: false,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'journey-touch-point-field':
          this.openTouchPointPicker(event);
          break;
        case 'journey-description-field':
          if (eventName && eventName == 'input_value_change') {
            this.openDescriptionPopover(event);
          } else if(eventName && eventName == 'input_value_confirm') {
            this.isClickedDescription = true;
          }
          break;
        case 'journey-date-field':
          this.openJourneyDateFormField();
          break;
        case 'journey-product-field':
          this.selectJourneyProduct();
          break;
        case 'journey-competitor-product-field':
          this.openCompetitorproductPopover(event);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  updateJourneyDate() {
    // avoiding reset of date on initial page load in read only mode
    if (!this.readOnly && this.journeyDate) {
      const date: number = startOfDay(new Date(this.journeyDate).getTime()).getTime();
      this.formattedJourneyTime = this.xpService.formattedDate(date, true);
      this.journey.date = date;
    }
  }

  async openTouchPointPicker(myEvent) {

    if (this.readOnly) return;
    if (!this.popover && this.xpService.activityTypes && this.xpService.activityTypes.length > 0) {
      this.popover = await this.popoverCtrl.create({component: PopoverComponent,
                                                    componentProps: { journeyTouchPoints: this.xpService.activityTypes, field: 'journey-touchpoints' },
                                                    event: myEvent
                                                    });
      this.popover.onDidDismiss().then(async (data: any) => {
        data = data.data;
        if(data)
          this.journey.type = data.label;
        this.popover = undefined;
      });
      this.popover.present();
    }
  }

    async openDescriptionPopover(myEvent) {
      if (this.readOnly) return;
      this.isClickedDescription = true;
      this.journey.description = (myEvent && myEvent.target && myEvent.target.value && myEvent.target.value.length !== 0) ? myEvent.target.value : this.journey.description;
      this.initInterestDetailsPageTitle();

      this.journeyDetailPageHeaderModel.controls.filter(control => control.id == 'done')[0].isDisabled = _.isEmpty(this.journey.description);
  }

  public openJourneyDateFormField() {
    if (!this.readOnly) {
      this.journeyDatePicker.open();
    }
  }

  async openCompetitorproductPopover(myEvent) {
      if (this.readOnly) return;
      this.journey.indskr_competitorproduct = (myEvent && myEvent.target && myEvent.target.value && myEvent.target.value.length !== 0) ? myEvent.target.value : '';
  }

  public async selectJourneyProduct() {
    const products: Brand[] = cloneDeep(this.brandService.brands);
    let _iscompetitorProductSelected = false;
    if(this.journey.selectedProduct && this.journey.selectedProduct.id === 'indskr_competitorproduct') {
      _iscompetitorProductSelected = true;
    }
    const selected = this.journey.selectedProduct;
    const options: SelectListData[] = products.map(pr => {
    return { id: pr.ID.toString(), title: pr.name.toString(), isSelected: selected && selected.id === pr.ID.toString() }
    });
    this.footerService.forceHideMasterFooter = true;
    const categoryModal =await this.modalCtrl.create({
      component:SelectListComponent,
      componentProps:{
        viewData: {
          data: options,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('PRODUCT'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: true,
          addCompetitorProduct: true,
          isCompetitorProductSelected: _iscompetitorProductSelected
        }
      },
      backdropDismiss: false
    });
    categoryModal.present();
    categoryModal.onDidDismiss().then(async (data: any) => {
      this.footerService.forceHideMasterFooter = false;
      data = data.data;
      if (data && data.isDone) {
        if (data.selectedItems && data.selectedItems.length) {
          this.journey.selectedProduct = data.selectedItems[0];
        } else {
          this.journey.selectedProduct = null;
        }
      }
    });
  }

  public updateJourneyConfidence(event) {
    this.journey.confidence = event;
  }

  public closepage(add: boolean) {

    if (add) {
      if(!this.journey.description) {
        return;
      }
      if (this.journey.selectedProduct && this.journey.selectedProduct.id === 'indskr_competitorproduct' && !this.journey.indskr_competitorproduct) {
      this.xpService.showMandatoryCompetitorProductNamePopover();
      return;
    }
      if(this.journey.type) {
        const activity: string = this.xpService.activities.find(a => a.label === this.journey.type).label;
        if (this.journey.selectedProduct) {
          if(this.journey.selectedProduct.id === 'indskr_competitorproduct') {
            this.journey.title = `${activity} on ${this.journey.indskr_competitorproduct}`;
          } else {
            this.journey.title = `${activity} on ${this.journey.selectedProduct.title}`;
          }
        }
      }
      if (this.createMode) {
        this.xpService.newInsight.journeys.push(this.journey);
        this.events.publish('insights-journey-created')
        this.trackingService.tracking('XperiencesInsgihts+JourneyDone', TrackingEventNames.INSIGHTSPLUS);
      }
      this.navService.popChildNavPageWithPageTracking();
    } else {
      if (!_.isEqual(this.journey, this.tempJourney)) {
        this.discardChangesPopup();
      } else {
        if (this.tempJourney) this.journey = this.tempJourney;
        this.navService.popChildNavPageWithPageTracking();
      }
    }
  }

  discardChangesPopup() {
    this.alertService.showAlert({
      title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
      message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')}, this.translate.instant('DISCARD')
    ).then (res => {
      if(res.role === 'ok') {
        if (this.tempJourney) this.journey = this.tempJourney;
        this.navService.popChildNavPageWithPageTracking();
      }
    });
  }

  ngOnDestroy() {
    this.events.unsubscribe(OptionSelectionMode.SELECT_INTEREST_CATEGORY.toString());
  }

}
