
import {takeUntil} from 'rxjs/operators';
import { DateTimeFormatsService } from './../../../services/date-time-formats/date-time-formats.service';
import { RepServices } from './../../../data-services/rep/rep.services';
import { TranslateService } from '@ngx-translate/core';
import { AccountOfflineService } from './../../../services/account/account.offline.service';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { UIService, ComponentViewMode } from './../../../services/ui/ui.service';
import { OpportunityManagementService } from './../../../services/opportunity-management/opportunity-management.service';
import { AuthenticationService } from './../../../services/authentication.service';
import { AccountPlan } from './../../../classes/account-management/account-plan.class';
import { Component, Input } from '@angular/core';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { Opportunity } from '../../../classes/opportunity-management/opportunity.class';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { OpportunityManagementPageComponent } from '../../opportunity-management/opportunity-management-page/opportunity-management-page';
import { OpportunityDetailsComponent } from '../../opportunity-management/opportunity-details/opportunity-details';
import { Subject } from 'rxjs';
import { NavParams } from '@ionic/angular';
import { FooterService } from '@omni/services/footer/footer.service';
import { DeviceService } from '@omni/services/device/device.service';
import { OpportunitySelectionPageComponent } from '@omni/components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';

/**
 * Generated class for the AccountPlanOpportunityListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-plan-opportunity-list',
  templateUrl: 'account-plan-opportunity-list.html',
  styleUrls:['account-plan-opportunity-list.scss']
})
export class AccountPlanOpportunityListComponent {
  @Input() selectedAccountPlan: AccountPlan
  applicableOpportunities: Opportunity[] = [];
  openOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  wonOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  lostOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  openOpportunitiesHeader: MainCardViewDataModel;
  formattedOpportunities: {
    Open: Opportunity[],
    Won: Opportunity[],
    Lost: Opportunity[]
  } = {
    Open: [],
    Won: [],
    Lost:[]
  }
  wonOpportunitiesHeader: MainCardViewDataModel;
  lostOpportunitiesHeader: MainCardViewDataModel;
  opportunitiesHeader:IndSectionHeaderViewDataModel;
  expandedSection: string = 'open';
  ngUnSubscribe$ = new Subject<boolean>();
  public pageControls = [];

  constructor(
    public authService: AuthenticationService,
    public opportunityService: OpportunityManagementService,
    public uiService: UIService,
    public navService: NavigationService,
    public accountService: AccountOfflineService,
    public translate : TranslateService,
    public repService: RepServices,
    public dateTimeFormatsService: DateTimeFormatsService,
    private navParams: NavParams,
    public footerService: FooterService,
    private device: DeviceService,
    private accountManagementDataService: AccountManagementDataService,
    private opportunityDataService: OpportunityManagementDataService,
  ) {
    // if(this.viewCtrl.data && this.viewCtrl.data.selectedAccountPlan){
    //   this.selectedAccountPlan = this.viewCtrl.data.selectedAccountPlan
    // }
    if(this.navParams.data) {
      if(this.navParams.data.selectedAccountPlan){
        this.selectedAccountPlan = this.navParams.data.selectedAccountPlan;
      }
    }
    if(this)
    this.initViewData();
  }
  ngOnInit() {
    this.opportunityService.opportunities$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.length) {
        this.initViewData();
      }
    })

    this.device.isOfflineObservable.subscribe(isOffline => {
      this._initPageHeaderControls();
    })
  }
  ngOnDestroy() {
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }

  private _initPageHeaderControls () {
    this.pageControls = [
      {
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      },
      {
        id: "create",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('ADD'),
        isDisabled: this.device.isOffline || this.selectedAccountPlan.accountPlanTimeFrame == 'Past',
        align: "right"
      },
    ];
  }
  resetViewDataModels(){
    this.formattedOpportunities = {
      Open: [],
      Won: [],
      Lost:[]
    }
    this.applicableOpportunities = [];
    this.openOpportunitiesViewDataModels = [];
    this.wonOpportunitiesViewDataModels = [];
    this.lostOpportunitiesViewDataModels = [];
  }
  initViewData() {
    this._initPageHeaderControls();
    this.resetViewDataModels();
    if(this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)){
      this.applicableOpportunities = this.opportunityService.opportunities.filter((oppo)=>{
        return oppo.accountPlanID && oppo.accountPlanID == this.selectedAccountPlan.ID;
      })
      this.getFormattedOpportunitiesList();
      this.formattedOpportunities.Open.forEach((opportunity)=>{
        let viewModel : MainCardViewDataModel = {
            id: opportunity.ID,
            fixedHeight:true,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showIcon: false,
            iconName: '',
            primaryTextRight: opportunity.opportunityName,
            isSecondaryTextRightTwoPillType: true,
            showArrow: true,
            arrowType: 'arrow-forward',
            clickHandler: (id: string, event) => {
              this.openOpportunityDetail(id)
            }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.openOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Lost.forEach((opportunity)=>{
        let viewModel : MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight:true,
            primaryTextLeft:'',
            secondaryTextLeft: '',
            showIcon: false,
            iconName: '',
            primaryTextRight: opportunity.opportunityName,
            //secondaryTextRight:'',
            //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
            isSecondaryTextRightTwoPillType: true,
            showArrow: true,
            arrowType: 'arrow-forward',
            clickHandler: (id: string, event) => {
              this.openOpportunityDetail(id)
            }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.lostOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Won.forEach((opportunity)=>{
        let viewModel : MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight:true,
            primaryTextLeft:'',
            secondaryTextLeft: '',
            showIcon: false,
            iconName: '',
            primaryTextRight: opportunity.opportunityName,
            //secondaryTextRight: (opportunity.estimatedValueFormatted) ? 'Est. ' + opportunity.estimatedValueFormatted : '',
            //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
            isSecondaryTextRightTwoPillType: true,
            showArrow: true,
            arrowType: 'arrow-forward',
            clickHandler: (id: string, event) => {
              this.openOpportunityDetail(id)
            }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.wonOpportunitiesViewDataModels.push(viewModel)
      })
      this.openOpportunitiesHeader = {
            id: 'open-opportunties',
            primaryTextLeft: this.translate.instant('OPEN_OPPORTUNITIES'),
            showArrow: true,
            arrowType: this.expandedSection=='open'?'arrow-up':'arrow-down',
            clickHandler: (id: string, event, clickTarget, viewDataRef) => {
              this.setExpanded('open')
              viewDataRef.arrowType = viewDataRef.arrowType == 'arrow-up'? 'arrow-down':'arrow-up'
            }
      }
      this.wonOpportunitiesHeader = {
        id: 'won-opportunties',
        primaryTextLeft: this.translate.instant('WON_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection=='won'?'arrow-up':'arrow-down',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('won')
          viewDataRef.arrowType = viewDataRef.arrowType == 'arrow-up'? 'arrow-down':'arrow-up'
        }
      }
      this.lostOpportunitiesHeader = {
        id: 'lost-opportunties',
        primaryTextLeft: this.translate.instant('LOST_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection=='lost'?'arrow-up':'arrow-down',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('lost')
          viewDataRef.arrowType = viewDataRef.arrowType == 'arrow-up'? 'arrow-down':'arrow-up'
        }
      }
      this.opportunitiesHeader = {
        id : 'Opportunities',
        title : this.translate.instant('OPPORTUNITIES_CAPS'),
        controls:[]
      }
    }
  }

  getFormattedOpportunitiesList() {
    this.applicableOpportunities.forEach((oppo)=>{
      oppo.state == 'Open'?this.formattedOpportunities.Open.push(oppo):oppo.state=='Won'
                      ?this.formattedOpportunities.Won.push(oppo):this.formattedOpportunities.Lost.push(oppo)
    })
  }

  openOpportunityDetail(id:string){

    this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent,
                                                      PageName.AccountPageComponent,
                                                      { from: 'AccountPlanOpportunities', opportunity:
                                                      this.opportunityService.getOpportunityDetailsbyId(id)})

  }

  setExpanded(sectionName: string) {
    if (sectionName) {
      switch (sectionName){
        case 'open':
          this.wonOpportunitiesHeader.arrowType='arrow-down'
          this.lostOpportunitiesHeader.arrowType='arrow-down'
          this.expandedSection = this.expandedSection == 'open'?'':'open'
          break;
        case 'won':
          this.openOpportunitiesHeader.arrowType='arrow-down'
          this.lostOpportunitiesHeader.arrowType='arrow-down'
          this.expandedSection = this.expandedSection == 'won'?'':'won'
          break;
        case 'lost':
          this.openOpportunitiesHeader.arrowType='arrow-down'
          this.wonOpportunitiesHeader.arrowType = 'arrow-down';
          this.expandedSection = this.expandedSection == 'lost' ? '' : 'lost';
          break;
      }
    }
  }
  backToAccountPlanDetails(){
    this.navService.popChildNavPageWithPageTracking();
  }

  public getOpportunityDataModel(section: string): MainCardViewDataModel {
    return {
        id: section + '-opportunities',
        fixedHeight: true,
      primaryTextRight:
        section === 'open'
          ? this.translate.instant('OPEN_OPPORTUNITIES')
          : section === 'won'
          ? this.translate.instant('WON_OPPORTUNITIES')
          : section === 'lost'
          ? this.translate.instant('LOST_OPPORTUNITIES')
          : '',
      isAccordionForm: true,
      showAccordionIcon: true,
      accordionIconType:
        this.expandedSection === section ? 'accordion-minus' : 'accordion-add',
      isAccordionText: this.expandedSection === section,
      noSeparationLine: true,
      clickHandler: (id: string, ev) => {
        this.expandOpportunityList(section);
      },
      };
  }

  expandOpportunityList(section: string) {
    this.expandedSection = this.expandedSection === section ? 'none' : section;
  }

  public onPageControlClick(id: string) {
    switch (id) {
      case 'close':
        this.backToAccountPlanDetails();
        break;
      case 'create':
        if (!this.device.isOffline) {
          this._handleAddOpportunities();
        }
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private _handleAddOpportunities() {
    let opportunitiesDataSet = [] = this.opportunityService.opportunities.filter(item => this.selectedAccountPlan.accountId == item.accountID && item.state == "Open");
    const opportuntiesSelectionViewData:OpportunitySelectionViewDataModel = {
      isReadOnlyMode: false,
      opportunitiesDataSet: opportunitiesDataSet,
      selectedOpportunities: this.applicableOpportunities,
      disableDoneButtonInOffline: true,
      callbackFromSelectionComponent: (event:string, newSelectedOpportunities:Array<Opportunity>,data:any)=> this._handledOpportunitiesSelection(event,newSelectedOpportunities,data),
    };
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent,PageName.OpportunitySelectionPage,{viewData: opportuntiesSelectionViewData},PageName.OpportunitySelectionPage);
  }

  private async _handledOpportunitiesSelection(event,newSelectedOpportunities:Array<Opportunity>,data:any){
    if(event && event == 'DONEBUTTONCLICK'){
      let removedOpportunities = this.applicableOpportunities.filter(a=> !newSelectedOpportunities.some(b=> b.ID == a.ID));
      let newAddedOpportunities = newSelectedOpportunities.filter(a=> !this.applicableOpportunities.some(b=> a.ID == b.ID));
      let opportuntiesPayload = [];
      removedOpportunities.forEach(a=> {
        opportuntiesPayload.push({
          "action": "DELETE",
          "opportunityid": a.ID,
        });
      });
      newAddedOpportunities.forEach(a=> {
        opportuntiesPayload.push({
          "opportunityid": a.ID,
        });
      });
      this.uiService.displayLoader();
      const payload = {
        "opportunities" : opportuntiesPayload
      }
      await this.accountManagementDataService.addOpportunitiesToAccountPlan(this.selectedAccountPlan.ID,payload).then(async (accounPlan) => {
        let tobeupdatedOpportunities:Array<Opportunity> = [];
        opportuntiesPayload.forEach(item=> {
          let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(item['opportunityid']);
          if(item['action'] == "DELETE"){
            foundOpportunity.accountPlanID = '';
            foundOpportunity.accountPlanName = '';
          }else{
            foundOpportunity.accountPlanID = this.selectedAccountPlan.ID;
            foundOpportunity.accountPlanName = this.selectedAccountPlan.accountPlanName;
          }
          tobeupdatedOpportunities.push(foundOpportunity);
        })
        await this.opportunityDataService.updateOpportunity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true ,operationDetail: { code: 'addopportunitiestoaccountplan', message: 'Add Existing Opportunities to Account Plan'}},tobeupdatedOpportunities).then(async success => {
          //Handle successfull response
          // Calculation not required on app side // Account Plan Estimated and Actual Revenue Calculation For Offline View Details
          // await this.accountManagementDataService.updateEstimatedAndActualRevenueOfAccountPlans();
          this.initViewData();
          this.uiService.dismissLoader();
        }).catch(async error => {
          // Handle error occured
          this.uiService.dismissLoader();
        });
      });
    }
  }

}
