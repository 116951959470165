import _ from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, skip } from 'rxjs/operators';
import { MessageMeasureData, MessageMeasureDataFilter } from '../../../../../interfaces/edge-analytics/message-report.interface';
import { ChartFilterOption, EmailTemplateDimensionDTO, KPIDataBlock, MeasureConfig, MeasuresChartData, MeetingFactsDTO, MessageChannelDimensionDTO, MessageFactsDTO, SystemUserDimensionDTO, YearMonthListItem, YearMonthOption } from '../../../../../interfaces/edge-analytics/report.interface';
import { MessageByChannelChartFilterTemplate, MessageTrendChartFilterTemplate, MessageReportTilePerRow, MessageReportTiles, MessageChartTitles, MessageByChannelChartFilterOptions, MessageReportYearMonthKeyProp } from '../../../../config/edge-analytics/message-report/message-report.config';
import { DefaultChartOptions } from '../../../../config/edge-analytics/report.config';
import { CommonDimensions, MeasureType } from '../../../../enums/edge-analytics/edge-analytics.enum';
import { MessageChartId, MessageDimensions, MessageKPI } from '../../../../enums/edge-analytics/message/message-report.enum';
import { unsubscribeSubscriptionArray } from '../../../../utility/common.utility';
import { generateChart, generateReportGroupTiles, getDateDimensionMembers, getDefaultSelectedYearMonths, getFilteredSubCube, getXAxisCategories, initYearMonthOptions, applyConfigToKPIMap, reducerForKPIs, setChartFilter, updateHighChartOption, updateHighChartSeries, getSelectedDateDimensionMembersInAscendingOrder, getYearMonthFromYYYYMM } from '../report.functions';

/** ----------------------------------------------------------------------------------------
 *  Initialize base measure data
 */
export function messageGenerateMeasureData( messageCube: any,
                                            emailTemplateDimensions: EmailTemplateDimensionDTO[],
                                            messageChannelDimensions: MessageChannelDimensionDTO[],
                                            systemUserDimensions: SystemUserDimensionDTO[],
                                            userPrincipalName: string,
                                            lastUpdatedDate: Date,
                                            dataStartDate: string,
                                            config: MeasureConfig[],
                                            langCode: string): MessageMeasureData {

  let messageMeasureData: MessageMeasureData;
  if (messageCube && Array.isArray(emailTemplateDimensions) && Array.isArray(messageChannelDimensions)
                  && Array.isArray(systemUserDimensions) && userPrincipalName && lastUpdatedDate) {
    // Initial data setup & list card subtext initialization
    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(messageCube, MessageReportYearMonthKeyProp, langCode);
    const _dateFilterOptions$: BehaviorSubject<YearMonthOption[]> = new BehaviorSubject(_dateFilterOptions);

    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    const _selectedDateFilters$: BehaviorSubject<number[]> = new BehaviorSubject(_selectedDateFilters);

    const _dataCube$ = new BehaviorSubject(messageCube);

    const _emailTemplateDimensions$ = new BehaviorSubject(emailTemplateDimensions);
    const _messageChannelDimensions$ = new BehaviorSubject(messageChannelDimensions);
    const _systemUserDimensions$ = new BehaviorSubject(systemUserDimensions);

    const _dateDimensionMembers = getDateDimensionMembers(messageCube, _selectedDateFilters, MessageReportYearMonthKeyProp);
    const _dateDimensionMembers$ = new BehaviorSubject(_dateDimensionMembers);

    const _initFilter = _getInitialCubeFilter(messageCube, systemUserDimensions, userPrincipalName, _dateDimensionMembers, _selectedDateFilters);
    const _initialCubeFilter$ = new BehaviorSubject(_initFilter);

    const _initFilteredSubCube = getFilteredSubCube(messageCube, _initFilter);
    const _filteredSubCube$ = new BehaviorSubject(_initFilteredSubCube);

    const _templateFilter = _getTemplateFilter(messageCube, _dateDimensionMembers, systemUserDimensions, userPrincipalName, _selectedDateFilters);
    const _templateCubeFilter$ = new BehaviorSubject(_templateFilter);

    const _templateFilteredSubCube = getFilteredSubCube(messageCube, _templateFilter);
    const _templateFilteredSubCube$ = new BehaviorSubject(_templateFilteredSubCube);

    const _messageSentVolume = _getMessageSentVolume(_initFilteredSubCube);
    const listCardSubtextValue = '' + _messageSentVolume;
    const listCardSubtextValue$: BehaviorSubject<string> = new BehaviorSubject(listCardSubtextValue);

    // KPI data behavior subjects & observables
    const _messageSentVolume$ = new BehaviorSubject(_messageSentVolume);
    const _customerReach$ = new BehaviorSubject(0);
    const _openCount$ = new BehaviorSubject(0);
    const _openRate$ = new BehaviorSubject('0%');
    const _linksClickedCount$ = new BehaviorSubject(0);
    const _clickThroughRate$ = new BehaviorSubject('0%');
    const _templateUtilVolume$ = new BehaviorSubject(0);
    const _userEmailTemplateCount$ = new BehaviorSubject(0);
    const _templateUtilRate$ = new BehaviorSubject('0%');

    // KPI data observables map
    const kpiMap: Map<string, KPIDataBlock> = new Map();

    kpiMap.set(MessageKPI.sentVolume, {
      id: MessageKPI.sentVolume,
      data$: _messageSentVolume$.asObservable(),
      enabled: false
    });
    kpiMap.set(MessageKPI.customerReach, {
      id: MessageKPI.customerReach,
      data$: _customerReach$.asObservable(),
      enabled: false
    });
    kpiMap.set(MessageKPI.openRate, {
      id: MessageKPI.openRate,
      data$: _openRate$.asObservable(),
      enabled: false
    });
    kpiMap.set(MessageKPI.clickThroughRate, {
      id: MessageKPI.clickThroughRate,
      data$: _clickThroughRate$.asObservable(),
      enabled: false
    });
    kpiMap.set(MessageKPI.templateUtilizationVolume, {
      id: MessageKPI.templateUtilizationVolume,
      data$: _templateUtilVolume$.asObservable(),
      enabled: false
    });
    kpiMap.set(MessageKPI.templateUtilizationRate, {
      id: MessageKPI.templateUtilizationRate,
      data$: _templateUtilRate$.asObservable(),
      enabled: false
    });

    // For by channel chart
    const _messageChannelDimensionMembers = _initFilteredSubCube?.getDimensionMembers(MessageDimensions.channel);
    const _messageChannelDimensionMembers$ = new BehaviorSubject(_messageChannelDimensionMembers);
    const _totalMessagesByChannel$ = new BehaviorSubject(null);

    messageMeasureData = {
      dataStream: {
        // KPI data sources
        _dataCube$,
        dataCube$: _dataCube$.asObservable(),
        _dateDimensionMembers$,
        dateDimensionMembers$: _dateDimensionMembers$.asObservable(),
        _initialCubeFilter$,
        initialCubeFilter$: _initialCubeFilter$.asObservable(),
        _filteredSubCube$,
        filteredSubCube$: _filteredSubCube$.asObservable(),
        _templateCubeFilter$,
        templateCubeFilter$: _templateCubeFilter$.asObservable(),
        _templateFilteredSubCube$,
        templateFilteredSubCube$: _templateFilteredSubCube$.asObservable(),

        _emailTemplateDimensions$,
        emailTemplateDimensions$: _emailTemplateDimensions$.asObservable(),
        _messageChannelDimensions$,
        messageChannelDimensions$: _messageChannelDimensions$.asObservable(),
        _systemUserDimensions$,
        systemUserDimensions$: _systemUserDimensions$.asObservable(),

        // KPI data behavior subjects
        _messageSentVolume$,
        _customerReach$,
        _openCount$,
        _openRate$,
        _linksClickedCount$,
        _clickThroughRate$,
        _templateUtilVolume$,
        _userEmailTemplateCount$,
        _templateUtilRate$,

        // Chart related
        _messageChannelDimensionMembers$,
        messageChannelDimensionMembers$: _messageChannelDimensionMembers$.asObservable(),
        _totalMessagesByChannel$,
        totalMessagesByChannel$: _totalMessagesByChannel$.asObservable(),
      },

      // KPI data observables map
      kpiMap,

      dateFilterOptionType: 'YearMonth',
      dateFilterMultiSelect: true,
      _dateFilterOptions$,
      dateFilterOptions$: _dateFilterOptions$.asObservable(),
      _selectedDateFilters$,
      selectedDateFilters$: _selectedDateFilters$.asObservable(),
      listCardSubtextValue$,
      measureType: MeasureType.message,
      isFilterDirty: false,
      lastUpdatedDate,
      dataStartDate,
      subs: [],
      tileGroups: [],
      charts: [],
      sortOrder: 2,
    };

    // Apply configuration
    applyConfigToKPIMap(kpiMap, config);

    // Init charts data
    _initChartsData(messageMeasureData);
  } else {
    console.warn('messageGenerateMeasureData: Invalid / missing input: ', messageCube,
                                                                          emailTemplateDimensions,
                                                                          messageChannelDimensions,
                                                                          systemUserDimensions,
                                                                          lastUpdatedDate);
  }

  return messageMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Update measure data after delta sync
 */
export function messageUpdateMeasureDataAfterSync(messageMeasureData: MessageMeasureData,
                                                  messageCube: any,
                                                  emailTemplateDimensions: EmailTemplateDimensionDTO[],
                                                  messageChannelDimensions: MessageChannelDimensionDTO[],
                                                  systemUserDimensions: SystemUserDimensionDTO[],
                                                  userPrincipalName: string,
                                                  lastUpdatedDate: Date,
                                                  dataStartDate: string,
                                                  chartFilterOptionData: ChartFilterOption[],
                                                  config: MeasureConfig[]): MessageMeasureData {

  if (messageMeasureData && messageCube && Array.isArray(emailTemplateDimensions)
      && Array.isArray(messageChannelDimensions) && Array.isArray(systemUserDimensions)
      && userPrincipalName && lastUpdatedDate) {

    messageMeasureData.dataStream._dataCube$.next(messageCube);
    messageMeasureData.dataStream._emailTemplateDimensions$.next(emailTemplateDimensions);
    messageMeasureData.dataStream._messageChannelDimensions$.next(messageChannelDimensions);
    messageMeasureData.dataStream._systemUserDimensions$.next(systemUserDimensions);
    messageMeasureData.lastUpdatedDate = lastUpdatedDate;
    messageMeasureData.dataStartDate = dataStartDate;
    applyConfigToKPIMap(messageMeasureData.kpiMap, config);

    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(messageCube, MessageReportYearMonthKeyProp, messageMeasureData.langCode);
    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    const _dateDimensionMembers = getDateDimensionMembers(messageCube, _selectedDateFilters, MessageReportYearMonthKeyProp);
    const _initFilter = _getInitialCubeFilter(messageCube, systemUserDimensions, userPrincipalName, _dateDimensionMembers, _selectedDateFilters);
    const _initFilteredSubCube = getFilteredSubCube(messageCube, _initFilter);
    const _messageSentVolume = _getMessageSentVolume(_initFilteredSubCube);
    messageMeasureData.listCardSubtextValue$.next('' + _messageSentVolume);

    if (Array.isArray(messageMeasureData.subs) && messageMeasureData.subs.length === 0) {
      // Since there's no data stream subscription, do manual initialization with new data
      messageMeasureData._dateFilterOptions$.next(_dateFilterOptions);
      
      messageMeasureData._selectedDateFilters$.next(_selectedDateFilters);

      messageMeasureData.dataStream._dateDimensionMembers$.next(_dateDimensionMembers);

      messageMeasureData.dataStream._initialCubeFilter$.next(_initFilter);

      messageMeasureData.dataStream._filteredSubCube$.next(_initFilteredSubCube);

      const _templateFilter = _getTemplateFilter(messageCube, _dateDimensionMembers, systemUserDimensions, userPrincipalName, _selectedDateFilters);
      messageMeasureData.dataStream._templateCubeFilter$.next(_templateFilter);
    } else if (Array.isArray(messageMeasureData.subs) && messageMeasureData.subs.length > 0) {
      // Already on detail page. Reset necessary stuff
      _generateReportGroupTiles(messageMeasureData);
      _setChartFilters(messageMeasureData, chartFilterOptionData, config);
    }
  } else {
    console.warn('messageUpdateMeasureDataAfterSync: Invalid input: ',  messageMeasureData,
                                                                          messageCube,
                                                                          emailTemplateDimensions,
                                                                          messageChannelDimensions,
                                                                          systemUserDimensions,
                                                                          userPrincipalName,
                                                                          lastUpdatedDate);
  }
  return messageMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Subscribe to measure data
 */
export function messageSubscribeToMeasureData(messageMeasureData: MessageMeasureData,
                                              userPrincipalName: string,
                                              chartFilterOptionData: ChartFilterOption[],
                                              config: MeasureConfig[],
                                              forceReSubscribe = false) {
  if (messageMeasureData && userPrincipalName) {
    // Reset filters if necessary
    messageResetFilters(messageMeasureData, userPrincipalName, chartFilterOptionData, config);

    // Unsubscribe previous subscriptions if necessary
    if (forceReSubscribe && Array.isArray(messageMeasureData.subs)) {
      unsubscribeSubscriptionArray(messageMeasureData.subs);
      messageMeasureData.subs = [];
    }

    // Subscribe
    if (messageMeasureData.dataStream && messageMeasureData.subs.length === 0) {
      messageMeasureData.subs.push(messageMeasureData.dataStream.dataCube$.subscribe(messageCube => {
        if (messageCube) {
          //console.log('&&&& sub1');
          const newYearMonthOptions = initYearMonthOptions(messageCube, MessageReportYearMonthKeyProp, messageMeasureData.langCode);
          messageMeasureData._dateFilterOptions$.next(newYearMonthOptions);
        }
      }));

      messageMeasureData.subs.push(messageMeasureData.dateFilterOptions$.subscribe(dateFilterOptions => {
        if (Array.isArray(dateFilterOptions)) {
          //console.log('&&&& sub2');
          const newSelectedYearMonths: number[] = getDefaultSelectedYearMonths(dateFilterOptions);
          messageMeasureData._selectedDateFilters$.next(newSelectedYearMonths);
        }
      }));

      messageMeasureData.subs.push(
        combineLatest([messageMeasureData.dataStream.dataCube$, messageMeasureData.selectedDateFilters$]).pipe(
          filter(([messageCube, selectedDateFilters]) => messageCube && Array.isArray(selectedDateFilters)),
          debounceTime(0),
          skip(1),
          map(([messageCube, selectedDateFilters]) => {
            //console.log('&&&& sub3');
            const newDateDimensionMember = getDateDimensionMembers(messageCube, selectedDateFilters, MessageReportYearMonthKeyProp);
            messageMeasureData.dataStream._dateDimensionMembers$.next(newDateDimensionMember);
          })
        ).subscribe()
      );

      messageMeasureData.subs.push(
        combineLatest(([
          messageMeasureData.dataStream.dataCube$,
          messageMeasureData.dataStream.systemUserDimensions$,
          messageMeasureData.dataStream.dateDimensionMembers$
        ])).pipe(
          filter(([dataCube, systemUserDimensions, dateDimensionMembers]) => dataCube && Array.isArray(systemUserDimensions) && dateDimensionMembers),
          debounceTime(0),
          skip(1),
          map(([dataCube, systemUserDimensions, dateDimensionMembers]) => {
            //console.log('&&&& sub4');
            const newFilter = _getInitialCubeFilter(dataCube,
                                                    systemUserDimensions,
                                                    userPrincipalName,
                                                    dateDimensionMembers);
            messageMeasureData.dataStream._initialCubeFilter$.next(newFilter);

            const newTemplateCubeFilter = _getTemplateFilter(dataCube, dateDimensionMembers, systemUserDimensions, userPrincipalName);
            messageMeasureData.dataStream._templateCubeFilter$.next(newTemplateCubeFilter);
          })
        ).subscribe()
      );

      messageMeasureData.subs.push(messageMeasureData.dataStream.initialCubeFilter$.subscribe(filter => {
        const messageCube = messageMeasureData.dataStream._dataCube$.getValue();
        if (filter && messageCube) {
          //console.log('&&&& sub5');
          const newFilteredMeetingSubCube = getFilteredSubCube(messageCube, filter);
          messageMeasureData.dataStream._filteredSubCube$.next(newFilteredMeetingSubCube);
        }
      }));

      messageMeasureData.subs.push(messageMeasureData.dataStream.templateCubeFilter$.subscribe(filter => {
        const messageCube = messageMeasureData.dataStream._dataCube$.getValue();
        if (filter && messageCube) {
          //console.log('&&&& sub6');
          const newTemplateFilteredSubCube = getFilteredSubCube(messageCube, filter);
          messageMeasureData.dataStream._templateFilteredSubCube$.next(newTemplateFilteredSubCube);
        }
      }));

      messageMeasureData.subs.push(messageMeasureData.dataStream.filteredSubCube$.subscribe(filteredSubCube => {
        if (filteredSubCube) {
          //console.log('&&&& sub7');
          const newCustomerReach = _getUniqContactCount(filteredSubCube);
          const newMessageSentVolume = _getMessageSentVolume(filteredSubCube);
          const newOpenCount = _getMessageOpenCount(filteredSubCube);
          const newClickedCount = _getLinksClickedCount(filteredSubCube);

          messageMeasureData.dataStream._customerReach$.next(newCustomerReach);
          messageMeasureData.dataStream._messageSentVolume$.next(newMessageSentVolume);
          messageMeasureData.dataStream._openCount$.next(newOpenCount);
          messageMeasureData.dataStream._linksClickedCount$.next(newClickedCount);

          const _messageChannelDimensionMembers = filteredSubCube.getDimensionMembers(MessageDimensions.channel);
          messageMeasureData.dataStream._messageChannelDimensionMembers$.next(_messageChannelDimensionMembers);
        }
      }));

      messageMeasureData.subs.push(messageMeasureData.dataStream.templateFilteredSubCube$.subscribe(filteredSubCube => {
        if (filteredSubCube) {
          //console.log('&&&& sub8');
          const newTemplateUtilVolume = _getTemplateUtilVolume(filteredSubCube);
          messageMeasureData.dataStream._templateUtilVolume$.next(newTemplateUtilVolume);
        }
      }));

      messageMeasureData.subs.push(messageMeasureData.dataStream.emailTemplateDimensions$.subscribe(emailTemplateDimensions => {
        if (Array.isArray(emailTemplateDimensions)) {
          const distinctTemplatesCount = _getDistinctTemplateCount(emailTemplateDimensions);
          messageMeasureData.dataStream._userEmailTemplateCount$.next(distinctTemplatesCount);
          //console.log('&&&& sub8.5', distinctTemplatesCount);
        }
      }));

      // Template Util Rate
      messageMeasureData.subs.push(
        combineLatest([messageMeasureData.dataStream._templateUtilVolume$.asObservable(), messageMeasureData.dataStream._userEmailTemplateCount$.asObservable()]).pipe(
          filter(([volume, totalCount]) => !isNaN(volume) && !isNaN(totalCount) && volume >= 0 && totalCount >= 0),
          debounceTime(0),
          map(([volume, totalCount]) => {
            let utilRate = _getTemplateUtilRate(volume, totalCount).toFixed(2).replace('.00', '') + '%';
            messageMeasureData.dataStream._templateUtilRate$.next(utilRate);
            //console.log('&&&& sub9');
          })
        ).subscribe()
      );

      // Open rate
      messageMeasureData.subs.push(
        combineLatest([messageMeasureData.dataStream._openCount$.asObservable(), messageMeasureData.dataStream._messageSentVolume$.asObservable()]).pipe(
          filter(([openCount, sentCount]) => !isNaN(openCount) && !isNaN(sentCount) && openCount >= 0 && sentCount >=0),
          debounceTime(0),
          map(([openCount, sentCount]) => {
            //console.log('&&&& sub10');
            const openRate = _getMessageOpenRate(openCount, sentCount).toFixed(2).replace('.00', '') + '%';
            messageMeasureData.dataStream._openRate$.next(openRate);
          })
        ).subscribe()
      );

      // Message Click through rate
      messageMeasureData.subs.push(
        combineLatest([messageMeasureData.dataStream._linksClickedCount$.asObservable(), messageMeasureData.dataStream._messageSentVolume$.asObservable()]).pipe(
          filter(([clickCount, sentCount]) => !isNaN(clickCount) && !isNaN(sentCount) && clickCount >= 0 && sentCount >= 0),
          debounceTime(0),
          map(([clickCount, sentCount]) => {
            //console.log('&&&& sub11');
            let clickThroughRate = _getMessageClickThroughRate(clickCount, sentCount).toFixed(2).replace('.00', '') + '%';
            messageMeasureData.dataStream._clickThroughRate$.next(clickThroughRate);
          })
        ).subscribe()
      );

      messageMeasureData.subs.push(
        combineLatest([messageMeasureData.dataStream.filteredSubCube$, messageMeasureData.dataStream.messageChannelDimensionMembers$]).pipe(
          debounceTime(0),
          map(([filteredSubCube, channelDimensionMembers]) => {
            if (filteredSubCube && Array.isArray(channelDimensionMembers)) {
              const newTotalMessagesByChannel = _getTotalMessagesByChannel(channelDimensionMembers, filteredSubCube.getCells());
              messageMeasureData.dataStream._totalMessagesByChannel$.next(newTotalMessagesByChannel);
              //console.log('&&&& sub12 ', newTotalMessagesByChannel);
            }
          })
        ).subscribe()
      );

      // Generate tile groups
      _generateReportGroupTiles(messageMeasureData);

      // Set chart filter options
      _setChartFilters(messageMeasureData, chartFilterOptionData, config);

      // By channel chart data subscriptions
      _byChannelChartDataSubscription(messageMeasureData);
      // Trend chart data subscriptions
      _trendChartDataSubscription(messageMeasureData);
    }
  } else {
    console.error('messageSubscribeToMeasureData: Invalid input: ', messageMeasureData, userPrincipalName);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Reset date & measure filters
 */
export function messageResetFilters(messageMeasureData: MessageMeasureData, userPrincipalName: string, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (messageMeasureData && userPrincipalName && messageMeasureData.isFilterDirty) {
    const messageCube = messageMeasureData.dataStream._dataCube$.getValue();
    const _systemUserDimensions = messageMeasureData.dataStream._systemUserDimensions$.getValue();

    // Date filter reset
    const _dateFilterOptions: YearMonthOption[] = initYearMonthOptions(messageCube, MessageReportYearMonthKeyProp, messageMeasureData.langCode);
    messageMeasureData._dateFilterOptions$.next(_dateFilterOptions);
    
    const _selectedDateFilters: number[] = getDefaultSelectedYearMonths(_dateFilterOptions);
    messageMeasureData._selectedDateFilters$.next(_selectedDateFilters);

    const _dateDimensionMembers = getDateDimensionMembers(messageCube, _selectedDateFilters, MessageReportYearMonthKeyProp);
    messageMeasureData.dataStream._dateDimensionMembers$.next(_dateDimensionMembers);

    const _initFilter = _getInitialCubeFilter(messageCube, _systemUserDimensions, userPrincipalName, _dateDimensionMembers, _selectedDateFilters);
    messageMeasureData.dataStream._initialCubeFilter$.next(_initFilter);

    const _initFilteredSubCube = getFilteredSubCube(messageCube, _initFilter);
    messageMeasureData.dataStream._filteredSubCube$.next(_initFilteredSubCube);

    // Chart filters reset
    _setChartFilters(messageMeasureData, chartFilterOptionData, config);
    messageMeasureData.isFilterDirty = false;
  }
}


/** ----------------------------------------------------------------------------------------
 *  Measure data prep & calculation helper functions
 */
export function messageFillInMissingDateFacts(missingYearMonthList: YearMonthListItem[], facts: MessageFactsDTO[], systemUserId: number) {
  if (Array.isArray(missingYearMonthList) && Array.isArray(facts) && !isNaN(systemUserId)) {
    for (let i = 0; i < missingYearMonthList.length; i++) {
      const yearMonthListItem = missingYearMonthList[i];
      const emptyFact = _generateEmptyFactForMissingYearMonth(yearMonthListItem, systemUserId);
      if (emptyFact) {
        facts.push(emptyFact);
      }
    }
  } else {
    console.error('messageFillInMissingDateFacts: ', missingYearMonthList, facts, systemUserId);
  }
}
function _generateEmptyFactForMissingYearMonth(yearMonthListItem: YearMonthListItem, systemUserId: number): MessageFactsDTO {
  let emptyFact: MessageFactsDTO;

  if (yearMonthListItem && systemUserId !== null) {
    emptyFact = {
      dm_sent: null,
      dm_sentdate: yearMonthListItem.yearMonth,
      is_fullrefresh: null,
      linksclickedcount: null,
      meetings_with_dm: null,
      opencount: null,
      phonecalls_with_dm: null,
      sk_channelid: null,
      sk_contactid: null,
      sk_productid: null,
      sk_systemuserid: systemUserId,
      statuscode: null,
      systemuserid: null,
      templateid: null,
      title: null,
      uniquerowid: null,
      updatedon: null,
      id: systemUserId + '_' + yearMonthListItem.yearMonth + '_empty'
    }
  }

  return emptyFact;
}
function _getInitialCubeFilter( messageCube: any,
                                systemUserDimensions: SystemUserDimensionDTO[],
                                userPrincipalName: string,
                                dateDimensionMembers: any,
                                selectedDateFilters: number[] = []): MessageMeasureDataFilter {
  let filter: MessageMeasureDataFilter = null;
  if (messageCube && Array.isArray(systemUserDimensions) && userPrincipalName) {
    const yearMonth = dateDimensionMembers ? dateDimensionMembers : getDateDimensionMembers(messageCube, selectedDateFilters, MessageReportYearMonthKeyProp);
    const contacts = messageCube.getDimensionMembers(MessageDimensions.contacts).filter(m => m.sk_contactid);
    const channel = messageCube.getDimensionMembers(MessageDimensions.channel).filter(m => m.sk_channelid);
    const userId = systemUserDimensions.find(u => u.internalemailaddress === userPrincipalName)?.sk_systemuserid;
    const users = messageCube.getDimensionMembers(CommonDimensions.users).filter(u => u.sk_systemuserid === userId);

    if (Array.isArray(users) && users.length > 0) {
      filter = { yearMonth, contacts, channel, users };
    }
  }
  return filter;
}
function _getTemplateFilter(messageCube: any,
                            dateDimensionMembers: any,
                            systemUserDimensions: any[],
                            userPrincipalName: string,
                            selectedDateFilters: number[] = []) {
  let filter = null;
  const yearMonth = dateDimensionMembers ? dateDimensionMembers : getDateDimensionMembers(messageCube, selectedDateFilters, MessageReportYearMonthKeyProp);
  const templateId = messageCube.getDimensionMembers(MessageDimensions.templateId).filter(m => m.templateid);
  const userId = systemUserDimensions.find(u => u.internalemailaddress === userPrincipalName)?.sk_systemuserid;
  const users = messageCube.getDimensionMembers(CommonDimensions.users).filter(u => u.sk_systemuserid === userId);

  if (Array.isArray(users) && users.length > 0) {
    filter = { yearMonth, templateId, users };
  }
  return filter;
}
function _getUniqContactCount(filteredSubCube: any): number {
  let count = 0;
  if (filteredSubCube) {
    try {
      // Filter the cells because we introduced empty cells by filling in missing dates...
      const uniqContacts = _.uniqBy(filteredSubCube.getCells().filter(({ title }) => title), 'contacts_id');
      count = Array.isArray(uniqContacts) ? uniqContacts.length : 0;
    } catch (error) {
      console.error('_getUniqContactCount: ', error);
    }
  }
  return count;
}
function _getMessageSentVolume(filteredSubCube: any): number {
  let sentSum = 0;
  if (filteredSubCube) {
    // Not filtering empty cells here since they don't have the property and value to be summed
    sentSum = reducerForKPIs('dm_sent', filteredSubCube.getCells(), 'summation');
  }
  return sentSum;
}
function _getMessageOpenCount(filteredSubCube: any): number {
  let openCount = 0;
  if (filteredSubCube) {
    openCount = reducerForKPIs('opencount', filteredSubCube.getCells(), 'summation');
  }
  return openCount;
}
function _getLinksClickedCount(filteredSubCube: any): number {
  let linksClickedCount = 0;
  if (filteredSubCube) {
    linksClickedCount = reducerForKPIs('linksclickedcount', filteredSubCube.getCells(), 'summation');
  }
  return linksClickedCount;
}
function _getMessageOpenRate(openCount: number, sentCount: number): number {
  let openRateInNumber = 0;
  if (openCount !== 0 && sentCount !== 0) {
    openRateInNumber = openCount / sentCount * 100;
  }
  return openRateInNumber;
}
function _getMessageClickThroughRate(clickCount: number, sentCount: number) {
  let clickThroughRateInNumber = 0;
  if (clickCount !== 0 && sentCount !== 0) {
    clickThroughRateInNumber = clickCount / sentCount * 100;
  }
  return clickThroughRateInNumber;
}
function _getTemplateUtilVolume(templateFilteredSubCube: any): number {
  let volume = 0;
  if (templateFilteredSubCube) {
    try {
      // Filter the cells because we introduced empty cells by filling in missing dates...
      const uniqTemplates = _.uniqBy(templateFilteredSubCube.getCells().filter(({ title }) => title), 'templateId_id');
      volume = Array.isArray(uniqTemplates) ? uniqTemplates.length : 0;
    } catch (error) {
      console.error('_getTemplateUtilVolume: ', error);
    }
  }
  return volume;
}
function _getTemplateUtilRate(volume: number, totalCount: number) {
  let utilRateInNumber = 0;
  if (volume !== 0 && totalCount !== 0) {
    utilRateInNumber = volume / totalCount * 100;
  }
  return utilRateInNumber;
}
function _getDistinctTemplateCount(emailTemplateDimensions: any[]): number {
  let count = 0;
  if (Array.isArray(emailTemplateDimensions)) {
    const distinctTemplates = _.uniqBy(emailTemplateDimensions, 'emailtemplateid');
    count = distinctTemplates.length;
  }
  return count;
}


/** ----------------------------------------------------------------------------------------
 *  Report tile data connect & generation
 */
function _generateReportGroupTiles( messageMeasureData: MessageMeasureData,
                                    messageReportTiles = JSON.parse(JSON.stringify(MessageReportTiles)),
                                    reportTilesPerRow = MessageReportTilePerRow) {
  generateReportGroupTiles(messageMeasureData, messageReportTiles, reportTilesPerRow, 'message-tile-group');
}


/** ----------------------------------------------------------------------------------------
 *  Chart data initialization
 */
function _initChartsData(messageMeasureData: MessageMeasureData) {
  if (messageMeasureData) {
    _initByChannelChartData(messageMeasureData);
    _initTrendChartData(messageMeasureData);
  }
}
function _initByChannelChartData(messageMeasureData: MessageMeasureData) {
  const _chartFilterOptions$ = new BehaviorSubject(null);
  const _xAxisCategories$ = new BehaviorSubject(null);
  const _chartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);
  const byChannelChartData: MeasuresChartData = {
    id: MessageChartId.byChannel,
    title: 'By Channel',
    titleKey: MessageChartTitles[MessageChartId.byChannel],
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: JSON.parse(JSON.stringify(MessageByChannelChartFilterTemplate)),
    _chartFilterOptions$,
    chartFilterOptions$: _chartFilterOptions$.asObservable(),
    _xAxisCategories$,
    xAxisCategories$: _xAxisCategories$.asObservable(),
    _chartUpdated$,
    chartUpdated$: _chartUpdated$.asObservable(),
    sortOrder: 0
  };

  messageMeasureData.charts.push(byChannelChartData);
}
function _initTrendChartData(messageMeasureData: MessageMeasureData) {
  const _chartFilterOptions$ = new BehaviorSubject(null);
  const _xAxisCategories$ = new BehaviorSubject(null);
  const _chartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);
  // Trend chart type is line
  const trendChartOptions = JSON.parse(JSON.stringify(DefaultChartOptions));
  trendChartOptions.chart.type = 'line';

  const trendChartData: MeasuresChartData = {
    id: MessageChartId.trend,
    title: 'Trend',
    titleKey: MessageChartTitles[MessageChartId.trend],
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: JSON.parse(JSON.stringify(MessageTrendChartFilterTemplate)),
    _chartFilterOptions$,
    chartFilterOptions$: _chartFilterOptions$.asObservable(),
    _xAxisCategories$,
    xAxisCategories$: _xAxisCategories$.asObservable(),
    _chartUpdated$,
    chartUpdated$: _chartUpdated$.asObservable(),
    customChartOption: trendChartOptions,
    sortOrder: 1
  };

  messageMeasureData.charts.push(trendChartData);
}


/** ----------------------------------------------------------------------------------------
 *  Chart helper functions
 */
export function messageGenerateCharts(messageMeasureData: MessageMeasureData,
                                      isMobilePortrait: boolean,
                                      windowInnerWidth: number,
                                      windowInnerHeight?: number,) {
  if (messageMeasureData && Array.isArray(messageMeasureData.charts)) {
    for (let index = 0; index < messageMeasureData.charts.length; index++) {
      const chartData = messageMeasureData.charts[index];
      chartData.chart = generateChart(  chartData._chartFilterOptions$.getValue(),
                                        chartData.title,
                                        isMobilePortrait,
                                        windowInnerWidth,
                                        windowInnerHeight,
                                        chartData._xAxisCategories$ && Array.isArray(chartData._xAxisCategories$.getValue())
                                          ? chartData._xAxisCategories$.getValue() : undefined,
                                        chartData.customChartOption ? chartData.customChartOption : undefined);

      if (chartData.chart && chartData.chart.ref$) {
        chartData.highChartSub = chartData.chart.ref$.subscribe(ref => {
          chartData.highChartRef = ref;
          chartData._highChartRefReady$.next(true);
        });
      }
    }
  }
}

function _setChartFilters(messageMeasureData: MessageMeasureData, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (messageMeasureData && Array.isArray(messageMeasureData.charts)) {
    for (let i = 0; i < messageMeasureData.charts.length; i++) {
      const chartData = messageMeasureData.charts[i];

      switch (chartData.id) {
        case MessageChartId.byChannel:
          let filterOptionsByChannel = chartFilterOptionData.filter(d => d.chartId != 'trend');
          setChartFilter(chartData, filterOptionsByChannel, config);
          break;
        case MessageChartId.trend:
          setChartFilter(chartData, chartFilterOptionData, config);
          break;
        default:
          break;
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  By channel chart helper functions
 */
function _getTotalMessagesByChannel(channelDimensionMembers: any[], filteredMessages: any[]) {
  return _reduceByChannel(channelDimensionMembers, filteredMessages, 'dm_sent', 'summation');
}
function _reduceByChannel(channelDimensionMembers: any[], filteredMessages: any[], measure: string, operation: 'summation' | 'count') {
  let groupedData = null;

  if (Array.isArray(channelDimensionMembers) && Array.isArray(filteredMessages)) {
    groupedData = {};

    filteredMessages.reduce((a, b) => {
      const channelName = '' + channelDimensionMembers.find(c => c.id === b.channel_id)?.channel;
      if (channelName && groupedData[channelName] !== undefined) {
        (operation === 'count') ? groupedData[channelName]++ : groupedData[channelName] += b[measure];
      } else {
        groupedData[channelName] = operation === 'count' ? 1 : b[measure];
      }
    }, groupedData);
  }
  return groupedData;
}
function _updateSelectedByChannelChartFilterOptionsData(  chartFilterOptions: ChartFilterOption[],
                                                          messageMeasureData: MessageMeasureData,
                                                          refresh = false) {

  if (Array.isArray(chartFilterOptions) && messageMeasureData) {
    const totalMessagesSentByChannel = messageMeasureData.dataStream._totalMessagesByChannel$.getValue();
    for (let i = 0; i < chartFilterOptions.length; i++) {
      const filterOption = chartFilterOptions[i];

      if (filterOption.isSelected && (filterOption.data === undefined || refresh)) {
        try {
          switch (filterOption.value) {
            case MessageKPI.sentVolume:
              if (totalMessagesSentByChannel) {
                filterOption.data = _.values(totalMessagesSentByChannel);
              }
              break;
            case MessageKPI.customerReach: {
              const channelDimensionMembers = messageMeasureData.dataStream._messageChannelDimensionMembers$.getValue();
              const filteredSubCube = messageMeasureData.dataStream._filteredSubCube$.getValue();

              if (totalMessagesSentByChannel && channelDimensionMembers) {
                const reduced = filteredSubCube.getCells().reduce((result, value) => {
                  const channelName = '' + channelDimensionMembers.find(c => c.id === value.channel_id).channel;
                  if (Array.isArray(result[channelName]) && value.contacts_id) {
                    if (!result[channelName].some(r => r === value.contacts_id)) {
                      result[channelName].push(value.contacts_id);
                    }
                  } else {
                    result[channelName] = [];
                    if (value.contacts_id) {
                      result[channelName].push(value.contacts_id);
                    }
                  }
                  return result;
                }, {});

                filterOption.data = _.values(_.mapValues(reduced, (val: any[]) => {
                  return val.length;
                }));
              }
              break;
            }
            case MessageKPI.openRate: {
              const channelDimensionMembers = messageMeasureData.dataStream._messageChannelDimensionMembers$.getValue();
              const filteredSubCube = messageMeasureData.dataStream._filteredSubCube$.getValue();
              if (channelDimensionMembers && filteredSubCube) {
                filterOption.data = _.values(_.mapValues(_reduceByChannel(channelDimensionMembers, filteredSubCube.getCells(), 'opencount', 'summation'), (val: any, index) => {
                  return parseFloat((val / totalMessagesSentByChannel[index] * 100).toFixed(2));
                }));
              }
              break;
            }
            case MessageKPI.clickThroughRate: {
              const channelDimensionMembers = messageMeasureData.dataStream._messageChannelDimensionMembers$.getValue();
              const filteredSubCube = messageMeasureData.dataStream._filteredSubCube$.getValue();
              if (channelDimensionMembers && filteredSubCube) {
                filterOption.data = _.values(_.mapValues(_reduceByChannel(channelDimensionMembers, filteredSubCube.getCells(), 'linksclickedcount', 'summation'), (val: any, index) => {
                  return parseFloat((val / totalMessagesSentByChannel[index] * 100).toFixed(2));
                }));
              }
              break;
            }
            case MessageKPI.templateUtilizationVolume: {
              const channelDimensionMembers = messageMeasureData.dataStream._messageChannelDimensionMembers$.getValue();
              const templatesSubCube = messageMeasureData.dataStream._templateFilteredSubCube$.getValue();
              if (channelDimensionMembers && templatesSubCube) {
                const uniqTemplates = _.uniqBy(templatesSubCube.getCells(), 'templateId_id');
                const reducedByChannel = _reduceByChannel(channelDimensionMembers, uniqTemplates, '', 'count');
                const data = _.values(_.reduce(totalMessagesSentByChannel, (result, value, key) => {
                  if (reducedByChannel.hasOwnProperty(key)) {
                    result[key] = reducedByChannel[key];
                  } else {
                    result[key] = 0;
                  }
                  return result;
                }, {}));
                if (Array.isArray(data)) {
                  filterOption.data = data;
                }
              }
              break;
            }

            case MessageKPI.templateUtilizationRate: {
              const channelDimensionMembers = messageMeasureData.dataStream._messageChannelDimensionMembers$.getValue();
              const templatesSubCube = messageMeasureData.dataStream._templateFilteredSubCube$.getValue();
              const userEmailTemplateCount = messageMeasureData.dataStream._userEmailTemplateCount$.getValue();
              if (channelDimensionMembers && templatesSubCube) {
                const uniqTemplates = _.uniqBy(templatesSubCube.getCells(), 'templateId_id');
                const reducedByChannel = _reduceByChannel(channelDimensionMembers, uniqTemplates, '', 'count');
                const data = _.values(_.reduce(totalMessagesSentByChannel, (result, value, key) => {
                  if (userEmailTemplateCount > 0 && reducedByChannel.hasOwnProperty(key) && reducedByChannel[key] > 0) {
                    result[key] = parseFloat((reducedByChannel[key] / userEmailTemplateCount * 100).toFixed(2));
                  } else {
                    result[key] = 0;
                  }
                  return result;
                }, {}));

                if (Array.isArray(data)) {
                  filterOption.data = data;
                }
              }
            }
            default:
              break;
          }
        } catch (error) {
          console.error('_updateSelectedByChannelChartFilterOptionsData: ', error);
        }
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  By channel chart data subscription
 */
function _byChannelChartDataSubscription(messageMeasureData: MessageMeasureData) {
  const byChannelChartData = messageMeasureData.charts.find(c => c.id === MessageChartId.byChannel);
  if (byChannelChartData) {
    messageMeasureData.subs.push(
      combineLatest([byChannelChartData.highChartRefReady$, messageMeasureData.dataStream.totalMessagesByChannel$]).pipe(
        filter(([highChartRefReady, totalMessagesByChannel]) => highChartRefReady && totalMessagesByChannel),
        map(([highChartRefReady, totalMessagesByChannel]) => {
          const newXAxisCategories = getXAxisCategories(totalMessagesByChannel);
          byChannelChartData._xAxisCategories$.next(newXAxisCategories);  
        })
      ).subscribe()
    );
    messageMeasureData.subs.push(
      combineLatest([byChannelChartData.chartFilterOptions$, messageMeasureData.dataStream.totalMessagesByChannel$, byChannelChartData.xAxisCategories$]).pipe(
        filter(([chartFilterOptions, totalMessagesByChannel, xAxisCategories]) => Array.isArray(chartFilterOptions) && totalMessagesByChannel && Array.isArray(xAxisCategories)),
        debounceTime(300),
        map(([chartFilterOptions, totalMessagesByChannel, xAxisCategories]) => {
          _updateSelectedByChannelChartFilterOptionsData(chartFilterOptions, messageMeasureData, true);

          const xAxis = JSON.parse(JSON.stringify(DefaultChartOptions.xAxis));
          xAxis.categories = xAxisCategories;
          updateHighChartOption(byChannelChartData.highChartRef, { xAxis }, false, true);

          if (byChannelChartData.highChartRef) {
            updateHighChartSeries(byChannelChartData.highChartRef, chartFilterOptions);
            byChannelChartData._chartUpdated$.next(byChannelChartData.id);
          }
          //console.log('&&&& sub14');
        })
      ).subscribe()
    );
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart helper functions
 */
function _getTotalMessagesByMonth(filteredSubCube: any, dateMembers: any[]) {
  const result = [];
  if (Array.isArray(dateMembers)) {
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = filteredSubCube ? filteredSubCube.slice(CommonDimensions.yearMonth, dateMember): null;
      const sentSum = _getMessageSentVolume(sliced);
      result.push(sentSum);
    }
  }
  return result;
}
function _assignSentVolumeByMonthData(filterOption: ChartFilterOption, totalMessageSentByMonth: number[]) {
  if (filterOption && Array.isArray(totalMessageSentByMonth)) {
    filterOption.data = totalMessageSentByMonth;
  }
}
function _assignOpenRateByMonthData(filterOption: ChartFilterOption, totalMessageSentByMonth: number[], filteredSubCube: any, dateMembers: any[]) {
  if (filterOption && Array.isArray(totalMessageSentByMonth) && filteredSubCube && Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = filteredSubCube ? filteredSubCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const openCount = _getMessageOpenCount(sliced);
      const sentCount = totalMessageSentByMonth[i];
      const openRate = _getMessageOpenRate(openCount, sentCount);
      byMonthData.push(openRate);
    }
    filterOption.data = byMonthData;
  }
}
function _assignClickThroughRateByMonthData(filterOption: ChartFilterOption, totalMessageSentByMonth: number[], filteredSubCube: any, dateMembers: any[]) {
  if (filterOption && Array.isArray(totalMessageSentByMonth) && filteredSubCube && Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = filteredSubCube ? filteredSubCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const clickCount = _getLinksClickedCount(sliced);
      const sentCount = totalMessageSentByMonth[i];
      const clickThroughRate = _getMessageClickThroughRate(clickCount, sentCount);
      byMonthData.push(clickThroughRate);
    }
    filterOption.data = byMonthData;
  }
}
function _assignCustomerReachByMonthData(filterOption: ChartFilterOption, filteredSubCube: any, dateMembers: any[]) {
  if (filterOption && filteredSubCube && Array.isArray(dateMembers)) {
    const byMonthData = [];
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = filteredSubCube ? filteredSubCube.slice(CommonDimensions.yearMonth, dateMember) : null;
      const customerReach = _getUniqContactCount(sliced);
      byMonthData.push(customerReach);
    }
    filterOption.data = byMonthData;
  }
}
function _getTotalTemplateVolumeByMonth(templateFilteredSubCube: any, dateMembers: any[]): number[] {
  const result = [];
  if (Array.isArray(dateMembers)) {
    for (let i = 0; i < dateMembers.length; i++) {
      const dateMember = dateMembers[i];
      const sliced = templateFilteredSubCube ? templateFilteredSubCube.slice(CommonDimensions.yearMonth, dateMember): null;
      const volume = _getTemplateUtilVolume(sliced);
      result.push(volume);
    }
  }
  return result;
}
function _assignTemplateUtilVolByMonthData(filterOption: ChartFilterOption, totalTemplateVolumeByMonth: number[]) {
  if (filterOption && Array.isArray(totalTemplateVolumeByMonth)) {
    filterOption.data = totalTemplateVolumeByMonth;
  }
}
function _assignTemplateUtilRateByMonthData(filterOption: ChartFilterOption, totalTemplateVolumeByMonth: number[], userEmailTemplateCount: number) {
  if (filterOption && Array.isArray(totalTemplateVolumeByMonth) && !isNaN(userEmailTemplateCount)) {
    const byMonthData = [];
    for (let i = 0; i < totalTemplateVolumeByMonth.length; i++) {
      const volume = totalTemplateVolumeByMonth[i];
      let utilRate = 0;
      try {
        utilRate = parseFloat(_getTemplateUtilRate(volume, userEmailTemplateCount).toFixed(2));
      } catch (error) {
        console.error('_assignTemplateUtilRateByMonthData: ', error);
      }
      byMonthData.push(utilRate);
    }
    filterOption.data = byMonthData;
  }
}
function _updateSelectedTrendChartFilterOptions(chartFilterOptions: ChartFilterOption[], messageMeasureData: MessageMeasureData, refresh = false) {
  if (Array.isArray(chartFilterOptions) && messageMeasureData) {
    const selectedDateDimensionMembers = getSelectedDateDimensionMembersInAscendingOrder( messageMeasureData._selectedDateFilters$.getValue(),
                                                                                          messageMeasureData.dataStream._dateDimensionMembers$.getValue(),
                                                                                          MessageReportYearMonthKeyProp);
    // Common values that can be used in multiple cases
    let totalMessageSentByMonth;
    let totalTemplateVolumeByMonth;

    for (let i = 0; i < chartFilterOptions.length; i++) {
      const filterOption = chartFilterOptions[i];

      if (filterOption.isSelected && (filterOption.data === undefined || refresh)) {
        try {
          switch (filterOption.value) {
            case MessageKPI.sentVolume:
              if (!totalMessageSentByMonth) {
                totalMessageSentByMonth = _getTotalMessagesByMonth(messageMeasureData.dataStream._filteredSubCube$.getValue(), selectedDateDimensionMembers);
              }
              _assignSentVolumeByMonthData(filterOption, totalMessageSentByMonth);
              break;

            case MessageKPI.openRate:
              if (!totalMessageSentByMonth) {
                totalMessageSentByMonth = _getTotalMessagesByMonth(messageMeasureData.dataStream._filteredSubCube$.getValue(), selectedDateDimensionMembers);
              }
              _assignOpenRateByMonthData(filterOption, totalMessageSentByMonth, messageMeasureData.dataStream._filteredSubCube$.getValue(), selectedDateDimensionMembers);
              break;

            case MessageKPI.clickThroughRate:
              if (!totalMessageSentByMonth) {
                totalMessageSentByMonth = _getTotalMessagesByMonth(messageMeasureData.dataStream._filteredSubCube$.getValue(), selectedDateDimensionMembers);
              }
              _assignClickThroughRateByMonthData(filterOption, totalMessageSentByMonth, messageMeasureData.dataStream._filteredSubCube$.getValue(), selectedDateDimensionMembers);
              break;

            case MessageKPI.customerReach:
              _assignCustomerReachByMonthData(filterOption, messageMeasureData.dataStream._filteredSubCube$.getValue(), selectedDateDimensionMembers);
              break;

            case MessageKPI.templateUtilizationVolume:
              if (!totalTemplateVolumeByMonth) {
                totalTemplateVolumeByMonth = _getTotalTemplateVolumeByMonth(messageMeasureData.dataStream._templateFilteredSubCube$.getValue(), selectedDateDimensionMembers);
              }
              _assignTemplateUtilVolByMonthData(filterOption, totalTemplateVolumeByMonth);
              break;

            case MessageKPI.templateUtilizationRate:
              if (!totalTemplateVolumeByMonth) {
                totalTemplateVolumeByMonth = _getTotalTemplateVolumeByMonth(messageMeasureData.dataStream._templateFilteredSubCube$.getValue(), selectedDateDimensionMembers);
              }
              _assignTemplateUtilRateByMonthData(filterOption, totalTemplateVolumeByMonth, messageMeasureData.dataStream._userEmailTemplateCount$.getValue());
              break;

            default:
              break;
          }
        } catch (error) {
          console.error('', error);
        }
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart data subscription
 */
function _trendChartDataSubscription(messageMeasureData: MessageMeasureData) {
  const trendChartData = messageMeasureData.charts.find(c => c.id === MessageChartId.trend);
  if (trendChartData) {
    messageMeasureData.subs.push(
      combineLatest([trendChartData.highChartRefReady$, messageMeasureData.selectedDateFilters$]).pipe(
        filter(([highChartRefReady, selectedDateFilters]) => highChartRefReady && Array.isArray(selectedDateFilters)),
        map(([highChartRefReady, dateDimensionMembers]) => {
          dateDimensionMembers.sort((a, b) => a - b);
          const newXAxisCategories = [];
          for (let i = 0; i < dateDimensionMembers.length; i++) {
            const member = dateDimensionMembers[i];
            const response = getYearMonthFromYYYYMM(member, messageMeasureData.langCode);
            if (response) {
              newXAxisCategories.push(response.displayText);
            } else {
              console.warn('_trendChartDataSubscription: ', member, response);
            }
          }
          trendChartData._xAxisCategories$.next(newXAxisCategories);
        })
      ).subscribe()
    );

    messageMeasureData.subs.push(
      combineLatest([trendChartData.chartFilterOptions$, trendChartData.xAxisCategories$]).pipe(
        filter(([chartFilterOptions, xAxisCategories]) => Array.isArray(chartFilterOptions) && Array.isArray(xAxisCategories)),
        debounceTime(300),
        map(([chartFilterOptions, xAxisCategories]) => {
          _updateSelectedTrendChartFilterOptions(chartFilterOptions, messageMeasureData, true);

          const xAxis = JSON.parse(JSON.stringify(DefaultChartOptions.xAxis));
          xAxis.categories = xAxisCategories;
          updateHighChartOption(trendChartData.highChartRef, { xAxis }, false, true);

          if (trendChartData.highChartRef) {
            updateHighChartSeries(trendChartData.highChartRef, chartFilterOptions);
            trendChartData._chartUpdated$.next(trendChartData.id);
          }
          //console.log('&&&& sub16');
        })
      ).subscribe()
    );
  }
}
