
export class ProductKeyMessagesEntity{
    startIndex: number;
    endIndex: number;
    entityName: string;
    score: number;
    type: string;
    isSelected?:boolean = false;
    resolution:{values:string[]};
    isUserAdded?: boolean = false
      constructor(raw:JSON){
          this.startIndex = raw['startIndex']
          this.endIndex = raw['endIndex']
          this.entityName = raw['entity']
          this.score = raw['score'] || 1
          this.type = raw['type'];
          this.resolution = raw['resolution'] || {values:[]};
          this.isUserAdded = raw['useradded'] || false
      }
}

export interface CallNotesAssistantData {
    activityID: string,
    textStream: string,
    textStreamWithRecognitionEffects: string,
    productsMatched: ProductKeyMessagesEntity [],
    keysmatched: ProductKeyMessagesEntity [],
    sentForApproval: boolean,
    userLikedStatus:UserLikedStatus,
    assistantDataAdditionsOrEdits : {products: AssistantDataAddition[] ,keymessages: AssistantDataAddition[]}
}

export interface AssistantDataAddition {
    before : string,
    is_approve : boolean,
    status: string,
    after: string
}

export enum UserLikedStatus{
    NO_SELECTION,
    LIKED,
    DISLIKED
}
