import { AuthenticationService } from '@omni/services/authentication.service';
import { MeasureConfigData, MeasureConfig } from './../../../interfaces/edge-analytics/report.interface';
import { MeasureType, MeasureBoardTypesFromDynamics } from './../../enums/edge-analytics/edge-analytics.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { unSubMeasureData } from './functions/report.functions';
import { FeatureActionsMap } from '../../classes/authentication/user.class';

@Injectable({
  providedIn: 'root'
})
export class ReportDataManagementService {
  private _hasFeatureAction = false;
  private _measureSyncState$: BehaviorSubject<{ isSyncing: boolean, syncState: Map<MeasureType, boolean> }>
            = new BehaviorSubject({ isSyncing: false, syncState: new Map<MeasureType, boolean>() });
  readonly measureSyncStateObservable: Observable<{ isSyncing: boolean, syncState: Map<MeasureType, boolean> }> = this._measureSyncState$.asObservable();
  private _measures: Map<MeasureType, any> = new Map();
  private _measures$ = new BehaviorSubject(this._measures);
  readonly measures$ = this._measures$.asObservable();
  private _selectedMeasure: BehaviorSubject<MeasureType> = new BehaviorSubject(null);
  readonly selectedMeasure$: Observable<MeasureType> = this._selectedMeasure.asObservable();
  private _isSyncing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly isSyncing$: Observable<boolean> = this._isSyncing$.asObservable();
  private _isSyncSuccessful$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly isSyncSuccessful$: Observable<boolean> = this._isSyncSuccessful$.asObservable();
  private _configurations: MeasureConfigData;
  private _loadingCounter: number = 0;
  private _loadingCounter$: BehaviorSubject<number> = new BehaviorSubject(this._loadingCounter);
  readonly loadingCounterObservable: Observable<number> = this._loadingCounter$.asObservable();

  private _reportDataRefreshRequest$: Subject<MeasureType> = new Subject();
  readonly reportDataRefreshRequest$: Observable<MeasureType> = this._reportDataRefreshRequest$.asObservable();

  private _configurationsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly configurationsLoaded$: Observable<boolean> = this._configurationsLoaded$.asObservable();

  private _procedureLogDataReadyToBeLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly procedureLogDataReadyToBeLoaded$: Observable<boolean> = this._procedureLogDataReadyToBeLoaded$.asObservable();


  constructor(
    private authService: AuthenticationService,
  ) {}

  /** ----------------------------------------------------------------------------------------
   *  Getters & Setters
   */
  setSelectedMeasure(selected: MeasureType) {
    this._selectedMeasure.next(selected);
  }
  getSelectedMeasure(): MeasureType {
    return this._selectedMeasure.getValue();
  }
  addMeasure(measureType: MeasureType, measure: any) {
    if (measureType && measure) {
      this._measures.set(measureType, measure);
      this._measures$.next(this._measures);
    }
  }
  getMeasure(measureType: MeasureType): any {
    return this._measures.has(measureType) ? this._measures.get(measureType) : null;
  }
  hasMeasure(measureType: MeasureType): boolean {
    return !!this._measures.has(measureType);
  }
  removeMeasure(measureType: MeasureType) {
    const measure = this._measures.get(measureType);
    if (measure) {
      unSubMeasureData(measure);
    }
    this._measures.delete(measureType);
  }
  setConfigurations(configurations: MeasureConfigData) {
    this._configurations = configurations ? configurations : undefined;
  }
  getConfiguration(measureName: MeasureBoardTypesFromDynamics): MeasureConfig[] {
    const config = this._configurations && this._configurations[measureName];
    return Array.isArray(config) ? config : [];
  }
  setConfigurationsLoaded(isLoaded: boolean) {
    this._configurationsLoaded$.next(isLoaded);
  }
  setProcedureLogDataReadyToBeLoaded(isReady: boolean) {
    this._procedureLogDataReadyToBeLoaded$.next(isReady);
  }
  getProcedureLogDataReadyToBeLoaded(): boolean {
    return this._procedureLogDataReadyToBeLoaded$.getValue();
  }


  /** ----------------------------------------------------------------------------------------
   *  Sync state helper functions
   */
  // Helper function that can be used from other feature services to register they sync state
  updateSyncState(measureType: MeasureType, isSyncing: boolean) {
    const curSyncState = this._measureSyncState$.getValue();

    if (isSyncing) {
      curSyncState.syncState.set(measureType, isSyncing);
    } else {
      curSyncState.syncState.delete(measureType);
    }

    curSyncState.isSyncing = curSyncState.syncState.size > 0;

    this._measureSyncState$.next(curSyncState);
  }

  checkFeatureActionAndRegisterSyncTask(measureType: MeasureType) {
    if (this._hasFeatureAction || this.authService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)) {
      this._hasFeatureAction = true;
      this.updateSyncState(measureType, true);
    }
  }
  deRegisterSyncTask(measureType: MeasureType) {
    this.updateSyncState(measureType, false);
  }


  /** ----------------------------------------------------------------------------------------
   *  Local measure data refresh helper functions
   */
  requestLocalDataRefresh(measureType: MeasureType) {
    if (measureType) {
      this._reportDataRefreshRequest$.next(measureType);
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Data loading / processing state helper functions
   */
  incLoadingCounter(byCount?: number) {
    if (!isNaN(byCount) && byCount > 0) {
      this._loadingCounter = this._loadingCounter + byCount;
    } else {
      this._loadingCounter++;
    }

    this._loadingCounter$.next(this._loadingCounter);
  }
  decLoadingCounter(byCount? : number) {
    if (!isNaN(byCount) && byCount > 0) {
      this._loadingCounter = this._loadingCounter - byCount;
    } else {
      this._loadingCounter--;
    }

    if (this._loadingCounter < 0) {
      this._loadingCounter = 0;
    }
    this._loadingCounter$.next(this._loadingCounter);
  }
  resetLoadingCounter() {
    this._loadingCounter = 0;
    this._loadingCounter$.next(this._loadingCounter);
  }


  /** ----------------------------------------------------------------------------------------
   *  Unsubscribe helper functions
   */
  unSubAll() {
    this._measures.forEach((measure, measureType) => {
      unSubMeasureData(measure);
    });
  }
}
