
import LibGenerateTestUserSig from './lib-generate-test-usersig.min.js';
import { TENCENT_SDK_APP_ID, TENCENT_SECRET_KEY } from '../../../config/endpoints.config';
const EXPIRETIME = 604800;
const sdkAppId = parseInt(TENCENT_SDK_APP_ID);
const generator = new LibGenerateTestUserSig(sdkAppId, TENCENT_SECRET_KEY, EXPIRETIME);

export async function getLatestUserSig(userID) {
  const userSig = generator.genTestUserSig(userID);
  return {
    userSig,
    privateMapKey: 255,
  };
}
