<ion-header>
    <ion-toolbar class="activity-detail-menu-bar" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button icon-only class="back-btn" (click)="closePage()">
                <ion-icon name="arrow-back" large></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title text-center>{{uiService['preview'].indskr_attachmentname}}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>

    <ng-container *ngIf="!uiService['preview']; else checkOffline">
        <div class="no-data-message">{{ 'NOTHING_SELECTED' | translate}}</div>
    </ng-container>

    <ng-template #checkOffline>
        <ng-container *ngIf="device.isOffline; else checkIfsupported">
            <div class="no-data-message">{{ 'PREVIEW_UNAVAILABLE' | translate}}</div>
        </ng-container>
    </ng-template>

    <ng-template #checkIfsupported>
        <ng-container *ngIf="!isSupportedFileType; else checkIfError">
            <div class="no-data-message">
                {{ 'CONTACT_NO_PREVIEW_AVAILABLE_FOR' | translate}} <i>{{ 'CONTACT_NOT_SUPPORTED_FILE_TYPE'| translate :  {filename : uiService['preview'].indskr_filename} }}</i>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #checkIfError>
        <ng-container *ngIf="error; else showPreview">
            <div class="no-data-message">{{ 'CONTACT_ERROR_IN_LOADING' | translate}} <i>{{resource.title}}</i>{{ 'DOT' | translate}}<br />{{error}}</div>
        </ng-container>
    </ng-template>

    <ng-template #showPreview>
        <ng-container [ngSwitch]="assetType">
            <ng2-pdfjs-viewer #pdfViewer *ngSwitchCase="'pdf'" [pdfSrc]="urlSafe" openFile="false" viewBookmark="false" download="false"></ng2-pdfjs-viewer>
            <div *ngSwitchCase="'image'" class='video-container'>
                <img [src]="urlSafe" />
            </div>
            <iframe *ngSwitchCase="'web'" class='web-container' frameborder="0" [src]="urlSafe">
      </iframe>
            <div class='video-container' *ngSwitchCase="'video'">
                <video controls [src]="urlSafe" width="100%" oncontextmenu="return false;" controlsList="nodownload noremoteplayback">
          <source [src]="urlSafe">
        </video>
            </div>
        </ng-container>
    </ng-template>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"></footer-toolbar>
<!-- </ion-footer> -->