<ion-item lines="none" class="selector-item" [ngClass]="{itemSelected: isSelected, inactive: (resourceInActive || (viewMode == resourceView.ADDTOEMAIL && !isEmailResourceClickable)) }" [attr.detail-push]="allowPushResource ? '' : undefined" (click)="showDetails()">
    <span class="chk-container" *ngIf="checkBoxOption" [ngClass]="{'download-active': resourceService.isSelectMode}" slot="start">
    <ion-checkbox class="list-check-box" (click)="$event.stopPropagation()" (ngModelChange)="toggleIsResourceSelected($event)" [(ngModel)]="resource.isSelected"></ion-checkbox>
  </span>
    <div slot="start" class="image-wrapper">
        <progress *ngIf="(isDownloading$ | async) && (isActiveDownload$ | async)" class="progress-bar" [value]="progressPer" max="100"></progress>
        <img class="thumbnail" io-thumbnail [src]="thumbnailUrl" />
    </div>
    <ion-label class="primary-text"> {{resource.title}}
        <div class="sub-label secondary-text" *ngIf="sortedBy == 'createdOn'"> {{getFormattedAndLocalisedDate(resource.createdOn)}} </div>
        <div class="sub-label secondary-text" *ngIf="sortedBy == 'modifiedOn'"> {{getFormattedAndLocalisedDate(resource.modifiedOn)}} </div>
        <div class="sub-label secondary-text" *ngIf="sortedBy == 'assetType'"> {{getAssetType(resource.assetType)}} </div>
        <div class="sub-label secondary-text" *ngIf="sortedBy == '$downloaded'"> {{getAssetType(resource.assetType)}} </div>
    </ion-label>
    <img slot="end" *ngIf="downloadable && resource.downloaded" src="assets/imgs/contact_conn.png" width="11" class="green-dot" />
    <ion-icon class="add-icon" slot="end" *ngIf="viewMode == resourceView.ADDTOEMAIL && !isEmailResource && !isSelect && isEmailResourceClickable" name="indegene-selectors-add-icon" (click)="addResourceToEmail()"></ion-icon>
    <ion-icon class="remove-icon" slot="end" *ngIf="viewMode == resourceView.ADDTOEMAIL && isEmailResource && isEmailResourceClickable" name="indegene-selectors-remove-icon" (click)="addResourceToEmail()"></ion-icon>
    <ion-icon class="checkmark-icon" slot="end" *ngIf="viewMode == resourceView.ADDTOEMAIL && !isEmailResource && isSelect && isEmailResourceClickable" name="indegene-selectors-checkmark-icon"></ion-icon>
    <img slot="end" *ngIf="downloadable && !resource.downloaded" src="assets/imgs/pres_download_outline.svg" width="20" class="img-download button-foreground" (click)="download()" />
</ion-item>
<div class="separationLine"></div>