<ion-header>
    <div>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button icon-only padding-left (click)="closePopover()">
              {{ 'CLOSE' | translate}}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </div>
</ion-header>
<ion-content>
  <div class="ion-text-center qrCodeWrapper">
    <qrcode [qrdata]="qrCodeData" [width]="350" [errorCorrectionLevel]="'H'"></qrcode>
  </div>
</ion-content>
