import { Injectable } from '@angular/core';
import { ScientificActivityPlan, ScientificPlansUsers } from '../../classes/scientific-activity/scientific-activity.class';



@Injectable({
  providedIn: 'root'
})
export class ScientificActivityService {
    public isRightPaneNavActive = false;
    public selectedSAP: ScientificActivityPlan;
    private scientificActivities: ScientificActivityPlan[] = [];
    constructor(){}

    get sapActivities() {
        return this.scientificActivities;
    }

    set sapActivities(data) {
        this.scientificActivities = data;
    }
    
    get currentAndFutureSap() {
        return this.scientificActivities.filter(x => Number(x.endDate) > (new Date()).getTime());
    }
}

