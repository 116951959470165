import { AppointmentActivity } from "../activity/appointment.activity.class";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";

export class SchedulerActivityResponse implements ISchedulerResponseInterface {
    
    activityAccounts: any[];    
    activityPresentations: any[];
    contactAttendees: any[];
    activityId: string;
    ownerId: string;
    createdOffline: boolean;
    actualend: number;
    scheduledend: number;
    scheduledstart: number;
    actualstart: number;
    indskr_iomeetingtype: number;
    indskr_subtype: number;
    indskr_type: number;
    location: string;
    offlineMeetingId: string;
    stateCode: number;
    subject: string;
    activitySharedResources: any[];
    omnip_datecompleted?: any;
    activityAccompaniedUsers: any[];
    lastUpdatedTime: number;
    _id: string;
    indskr_activitytype: string;
    indskr_activitysubtype: string;
    activityTypeName: string;
    activitySubTypeName: string;

    constructor(raw: AppointmentActivity) {
        this.activityAccounts = [];
        this.activityPresentations = [];
        this.contactAttendees = raw.contacts;
        this.activityId = raw.ID;
        this.ownerId = raw.ownerId;
        this.createdOffline = false;
        this.actualend;
        this.scheduledend;
        this.actualstart;
        this.scheduledstart;
        this.indskr_iomeetingtype = 100000000;
        this.indskr_subtype = 100000001;
        this.indskr_type = 100000000;
        this.location = raw.location;
        this.offlineMeetingId = raw.offlineMeetingId;
        this.stateCode = 0;
        this.subject = raw.subject;
        this.activitySharedResources = [];
        this.omnip_datecompleted = null;
        this.activityAccompaniedUsers = [];
        this.lastUpdatedTime = 0;
        this._id = DB_KEY_PREFIXES.MEETING_ACTIVITY +raw.ID;
        this.activityTypeName = raw.activityTypeName;
        this.indskr_activitytype = raw.indskr_activitytype;
        this.activitySubTypeName = raw.activitySubTypeName;
        this.indskr_activitysubtype = raw.indskr_activitysubtype;
    }

}

export interface ISchedulerResponseInterface {
  activityAccounts: any[];
  activityPresentations: any[];
  contactAttendees: any[];
  activityId: string;
  ownerId: string;
  createdOffline: boolean;
  actualend: number;
  scheduledend: number;
  scheduledstart: number;
  actualstart: number;
  indskr_iomeetingtype: number;
  indskr_subtype: number;
  indskr_type: number;
  location: string;
  offlineMeetingId: string;
  stateCode: number;
  subject: string;
  activitySharedResources: any[];
  omnip_datecompleted?: any;
  activityAccompaniedUsers: any[];
  lastUpdatedTime: number;
  _id: string;
}