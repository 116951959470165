import { fetchQueries } from './../../config/dynamics-fetchQueries';
import { DB_KEY_PREFIXES } from './../../config/pouch-db.config';
import { EmailTemplate, ContentToken, HTMLEmailTemplate, ResourceEmailTemplate } from '../../classes/email-templates/email-template.class';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Endpoints } from '../../../config/endpoints.config';
import { Contact } from '../../classes/contact/contact.class';
import { AuthenticationService } from '../../services/authentication.service';
import { LogService } from '../../services/logging/log-service';
import { EmailAttachment } from '../../classes/activity/email.activity.class';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import {differenceInHours} from 'date-fns';
import { map, share } from 'rxjs/operators';
import { forkJoin ,  Observable } from 'rxjs';
import { ConsentDataService } from '../consent/consent.data.service';
import * as _ from 'lodash';
import { ChannelType, Channel } from '../../classes/consent/channel.class';
import { DeviceService } from '@omni/services/device/device.service';
import { DiskService } from '@omni/services/disk/disk.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { AccompainedUser } from '@omni/classes/activity/accompained-user.class';

@Injectable({
  providedIn: 'root'
})
export class EmailDataService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private dynamics: DynamicsClientService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService,
    private consentDataService: ConsentDataService,
    private disk: DiskService,
    private logService: LogService) {
  }

  public async createEmail(request: any, systemUserID: string) {
    let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
    headers.headers = headers.headers.set('X-SystemUserId', systemUserID);
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.CREATE_EMAIL_ACTIVITY;
    return await this.http.post(url, request, headers).toPromise();
  }

  public async fetchEmailProducts(loadFromDBOnly = false): Promise<Brand[]> {
    if (!(this.deviceService.isOffline || loadFromDBOnly)) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +  Endpoints.brands.GET_BRANDS;//Endpoints.email.FETCH_PRODUCT_SKU;
    const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
    })
    url = url.replace('{{positionIDs}}', positions.toString());
    return await this.http.get(url).pipe(map(
        async (res: any) => {
            console.log(res);
            this.disk.updateOrInsert(DB_KEY_PREFIXES.EMAIL_PRODUCTS, doc => ({ raw: res }));
            return res;
        },
        async err => {
            console.log(err);
            console.log("error handling");
        })).toPromise();
    } else if (loadFromDBOnly) {
        const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.EMAIL_PRODUCTS, true);
        if (dbData) {
          return await dbData.raw;
        }
    }
}

  public async sendEmailActivity(emailActivityId: string) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.SEND_EMAIL_ACTIVITY.replace("{EmailActivityId}", emailActivityId);
    let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
    headers.headers = headers.headers.set('X-SystemUserId', this.authService.user.systemUserID);
    return await this.http.put(url, "",headers).toPromise();
  }

  public async sendEmailExternalActivity(req:any, emailActivityId: string) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.UPDATE_EMAIL_ACTIVITY.replace("{EmailActivityId}", emailActivityId);
    return await this.http.put(url, req).toPromise();
  }

  public async getTemplates(initialSync: boolean, lastUpdatedTime?): Promise<ResourceEmailTemplate[]> {
    const positions = this.authService.user.positions.map((o) => {
      return o.ID
    });
    const url = this.authService.userConfig.activeInstance.entryPointUrl +
    // const url =
    //   'http://localhost:8888' +
      (initialSync
        ? Endpoints.email.GET_EMAIL_TEMPLATES.replace('{positionid}', positions.toString())
        : Endpoints.email.GET_DELTA_SYNC_EMAIL_TEMPLATES.replace('{lastUpdatedTime}', lastUpdatedTime).replace('{positionid}', positions.toString()));

    return await forkJoin<any>([this.http.get<ResourceEmailTemplate[]>(url, Endpoints.GLOBAL_SYNC_HEADER),
    this.consentDataService.getConsentChannels(true).then(channels => _.chain(channels).map(c => ([c.indskr_consenttypeid.valueOf(), c.indskr_consentType.valueOf()])).fromPairs().value())])
      .pipe(
        map(([data, channels]) => {
          return (<ResourceEmailTemplate[]>data).map(v => (
              {
                ...v,
                channelTypes: (!v.consent_ids || v.consent_ids.length == 0 ? [undefined] : v.consent_ids).map(vv => {
                  let body = v.indskr_body;
                  let channelType = channels[vv] as ChannelType;
                  let channelId = vv;
                  switch (channelType) {
                    case ChannelType.FACEBOOK:
                        body = v.indskr_fb_body;
                        break;
                    case ChannelType.SMS:
                        body = v.indskr_sms_body;
                        break;
                    case ChannelType.WHATSAPP:
                        body = v.indskr_whatsapp_body;
                        break;
                  }
                  return {channelType, body, channelId};
                }),
              }));
        })
      )
      .toPromise();
    //   let fetchXml = fetchQueries.messageTemplates.full(positions);
    //   return await this.dynamics.executeFetchQuery('indskr_emailtemplates', fetchXml, null);
    // } else if (lastUpdatedTime) {
    //   let hourDifference = differenceInHours(new Date(), new Date(lastUpdatedTime));
    //   let fetchXml = fetchQueries.messageTemplates.delta(positions, hourDifference + 1);
    //   return await this.dynamics.executeFetchQuery('indskr_emailtemplates', fetchXml, null);
  }

  public async fetchVersionUpdateTemplates(groupIds): Promise<ResourceEmailTemplate[]> {
    const positions = this.authService.user.positions.map((o) => {
      return o.ID
    });
    const url = this.authService.userConfig.activeInstance.entryPointUrl +
      Endpoints.email.GET_UPDATED_TEMPLATES.replace('{groupIds}', groupIds.toString()).replace('{positionIds}', positions.toString());
    return await forkJoin<any>([this.http.get<ResourceEmailTemplate[]>(url, Endpoints.GLOBAL_SYNC_HEADER),
      this.consentDataService.getConsentChannels(true).then(channels => _.chain(channels).map(c => ([c.indskr_consenttypeid.valueOf(), c.indskr_consentType.valueOf()])).fromPairs().value())])
      .pipe(
        map(([data, channels]) => {
          return (<ResourceEmailTemplate[]>data).map(v => (
            {
              ...v,
              channelTypes: (!v.consent_ids || v.consent_ids.length == 0 ? [undefined] : v.consent_ids).map(vv => {
                let body = v.indskr_body;
                let channelType = channels[vv] as ChannelType;
                let channelId = vv;
                switch (channelType) {
                  case ChannelType.FACEBOOK:
                    body = v.indskr_fb_body;
                    break;
                  case ChannelType.SMS:
                    body = v.indskr_sms_body;
                    break;
                  case ChannelType.WHATSAPP:
                    body = v.indskr_whatsapp_body;
                    break;
                }
                return {channelType, body, channelId};
              }),
            }));
        })
      )
      .toPromise();
  }

  public async getContentTokens(): Promise<ContentToken[]> {
    return await this.http.get<ContentToken[]>(this.authService.userConfig.activeInstance.entryPointUrl
      + Endpoints.email.GET_CONTENT_TOKENS).toPromise();
  }

  public async getEmailActivities(url: string) {
    const positions = this.authService.user.positions.map((o) => { return o.ID });
    url = url.replace('{positionid}', positions.toString());
    return await forkJoin<any>([this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER),
      this.consentDataService.getConsentChannels(true).then(channels => _.chain(channels).map(c => ([c.indskr_consenttypeid.valueOf(), c.indskr_consentType.valueOf()])).fromPairs().value())])
      .pipe(map(([emails, channels]:any[]) => emails.map(email => ({...email, channelType: channels[email.indskr_channel] || ChannelType.EMAIL })))).toPromise();
  }

  public async updateEmailAddress(request: any, systemUserID: string, emailActivityId: string) {
    let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
    headers.headers = headers.headers.set('X-SystemUserId', systemUserID);
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.UPDATE_EMAILADDRESS.replace("{EmailActivityId}", emailActivityId);
    return await this.http.put(url, request, headers).toPromise();
  }

  public async updateEmailActivityInDynamics(request: any, emailActivityId: string) {
    return await this.dynamics.update(emailActivityId, 'emails', request)
  }

  public async updateEmailActivitySMSPartiesInDynamics(payload:any ,emailActivityId:string){
    let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
    headers.headers = headers.headers.set('X-SystemUserId', this.authService.user.systemUserID);
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.UPDATE_SMS_PARTIES.replace("{EmailActivityId}", emailActivityId);
    return await this.http.put(url, payload, headers).toPromise();
  }

  public async updateEmailActivity(request: any, emailActivityId: string) {
    let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
    headers.headers = headers.headers.set('X-SystemUserId', this.authService.user.systemUserID);
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.UPDATE_EMAIL_ACTIVITY.replace("{EmailActivityId}", emailActivityId);
    return await this.http.put(url, request, headers).toPromise();
  }

  public async scrapEmailAddress(emailActivityId: string) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.SCRAP_EMAIL_ACTIVITY.replace("{EmailActivityId}", emailActivityId);
    return await this.http.delete(url).toPromise();
  }

  public async updateEmailTemplate(request: { template_id: string, subject: string, description: string }, emailActivityId: string) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.UPDATE_EMAIL_TEMPLATE.replace("{EmailActivityId}", emailActivityId);
    return await this.http.put(url, request).toPromise();
  }


  /**
       * Sends an email template to various participants
       *
       * @private
       * @param {EmailTemplate} template
       * @param {string[]} to
       * @memberof EmailDataService
       */
  public async sendEmail(template: HTMLEmailTemplate, to: Contact[], resourceURL: string) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.SEND_EMAIL;

    let emailHeaders = Endpoints.email.SEND_EMAIL_HEADERS;
    //Apparently we need a unique identifier for this, testing
    emailHeaders.headers.set('X-Correlation-Id', '4wcetiuesbtcfujrse');

    let contactMap = to.map(contact => {
      return contact.emailDTO;
    });
    let sendTime = new Date().getTime();
    let payload = {
      practitioners: contactMap,
      subject: template.subject,
      meetingUrl: resourceURL,
      startTime: sendTime,
      repName: this.authService.user.displayName,
      indskr_type: "100000000",
      messageBody: template.body

    }

    let response = await this.http.post(url, payload, Endpoints.headers.content_type.json)
      .toPromise();
    this.logService.logDebug(response);
  }

  public async addAttachment(resources: any[], id: string) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.ADD_ATTACHMENTS.replace("{EmailActivityId}", id);
    return await this.http.put(url, resources).toPromise();
  }

  public async removeAttachment(payload: EmailAttachment[]) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.REMOVE_ATTACHMENTS;
    let req: any[]=[];
    payload.forEach(a => {req.push({indskr_emailattachmentid: a.indskr_emailattachmentid})});
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: req
    };
    return await this.http.delete(url, options).toPromise();
  }

  public addAccompaniedUser(raw: AccompainedUser[], id: string, appointmentId: string): Observable<any> {
    let payload: any[] = [];
    raw.map((e: AccompainedUser) => {
      let obj: object = { 'indskr_user': e.id };
      payload.push(obj);
    });
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.ADD_ACCOMPANIED_USER;
    url = url.replace("{EmailActivityId}", id);
    url = url.replace("{appointmentId}", appointmentId);
    return this.http.put(url, payload);
  }

}



export enum EmailStatusCode {
  SEND = 'Send',
  DRAFT = 'Draft'
}
