export const REP_STATUS = {
    ONLINE: { userState: 'ONLINE', displayTxt: 'Online' },
    OFFLINE: { userState: 'OFFLINE', displayTxt: 'Offline' },
    AVAILABLE: { userState: 'AVAILABLE', displayTxt: 'Available' },
    STANDBY: { userState: 'STANDBY', displayTxt: 'Standby' },
    IN_MEETING: { userState: 'IN_MEETING', displayTxt: 'In Meeting' }
}
/**
 * 
 * 
 * @export
 * @interface RepStatusOptionModel
 */
export interface RepStatusOptionModel{
    statusDisplayTxt: string;
    userState: string
    selected: boolean;
    disabled: boolean;
}

export const LAST_REP_STATUS_KEY = 'LAST_REP_STATUS';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGED_OUT_AND_BACK_TO_LOGIN = 'LOGGED_OUT_AND_BACK_TO_LOGIN';
