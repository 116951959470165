import { Component, EventEmitter, Output, Input } from '@angular/core';

/**
 * Generated class for the InputBoxQuantComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'input-box-quant',
  templateUrl: 'input-box-quant.html',
  styleUrls:['input-box-quant.scss']
})
export class InputBoxQuantComponent {
  public valueFromParent: number = 0;
  @Output() confirmChanges = new EventEmitter;
  @Output() cancelChanges = new EventEmitter;
  @Input('currentVal') currentVal: number
  @Input('isInputDisabled') isInputDisabled: boolean

  constructor() {
    console.log('Hello InputBoxQuantComponent Component');
  }

  editInput(action:string){
    if(action === 'done'){
      this.confirmChanges.emit(this.currentVal);
    }
    else{
      this.cancelChanges.emit();
    }
  }

  ngAfterViewInit(){
    this.currentVal;
  }

}
