<!-- <ion-row class="top-card-row"> -->
<ion-row non-padding>
  <ion-col>
    <number-card cssClass="ion-margin-bottom" label="{{ 'TOTAL_ADJUSTED' | translate }}"
      [value]="selectedCustomerPositionList?.indskr_netchange"></number-card>
  </ion-col>
  <ion-col>
    <number-card label="{{ 'PERCENTAGE_ADJUSTED' | translate }}"
      [value]="selectedCustomerPositionList?.indskr_percentagechange+'%'"></number-card>
  </ion-col>
</ion-row>
<ion-row non-padding>
  <ion-col>
    <number-card label="{{ 'NEWLY_ADDED' | translate }}"
      [value]="selectedCustomerPositionList?.indskr_numberadded"></number-card>
  </ion-col>
  <ion-col>
    <number-card label="{{ 'REMOVED' | translate }}"
      [value]="selectedCustomerPositionList?.indskr_numberremoved"></number-card>
  </ion-col>
</ion-row>
<!-- </ion-row> -->

<ion-row class="contacts">
  <ion-col class="ion-padding" size="12" size-lg="6">
    <div class="list-wrapper">
      <ind-section-header [viewData]="myContactsHeader">
      </ind-section-header>
      <search-filter [placeholder]="'CONTACTS' | translate" [filterButtonBadgeCount]="myContactsFilterCount"
        [filterDisabled]="filterDisabled" (onFilterClick)="openContactsFilterPopup('myContact')"
        [(searchText)]="myContactSearchText" (onSearchChange)="onMyContactInput()"></search-filter>

      <!-- <ion-list class="scroll-list added-removed-row" (scroll)="onIonInfinite($event, 'my-contact')">

        <ion-item *ngIf="positionListTargets.length === 0">
          <ion-text class="no_list_item">{{ "NO_CONTACTS_AVAILABLE" | translate : {globalCustomerText:
            globalCustomerText} }}</ion-text>
        </ion-item>

        <div *ngFor="let positionListTarget of visiblePositionListTargets">

          <avatar-list-item [value]="positionListTarget.contact" headerSection="true" (clicked)="removeContact($event)"
            isParent="true" (tapped)="handleList($event)" [isReadOnly]="isReadOnly" (click)="highlightContact($event, positionListTarget.contact)">
          </avatar-list-item>

          <ion-item *ngIf="positionListTarget.contact.accounts.length > 0" lines="none" class=""
            [ngClass]="{ 'unexpanded-list': unexpandedList.includes(positionListTarget.contact.id), 'expanded-list' : !unexpandedList.includes(positionListTarget.contact.id) }">
            <ion-list class="sub-list">
              <avatar-list-item *ngFor="let account of positionListTarget.contact.accounts" [value]="account"
                (clicked)="accountUpdate($event)" (click)="highlightAccount(account)" [isReadOnly]="isReadOnly" [isSubItem]="true">
              </avatar-list-item>
            </ion-list>
          </ion-item>

        </div>
      </ion-list> -->
      <ng-container *ngIf="positionListTargets.length === 0"> <ion-item>
          <ion-text class="no_list_item">{{ "NO_CONTACTS_AVAILABLE" | translate : {globalCustomerText:
            globalCustomerText} }}</ion-text>
        </ion-item></ng-container>
      <ng-container *ngIf="positionListTargets.length > 0">
        <cdk-virtual-scroll-viewport class="ion-content-scroll-host" itemSize="100" minBufferPx="1200" maxBufferPx="1200">
          <ion-list class="scroll-list added-removed-row">
            <div *cdkVirtualFor="let positionListTarget of filteredPositionListTargets">

              <avatar-list-item [value]="positionListTarget.contact" headerSection="true"
                (clicked)="removeContact($event)" isParent="true" (tapped)="handleList($event)"
                [isReadOnly]="isReadOnly" (click)="highlightContact($event, positionListTarget.contact)">
              </avatar-list-item>

              <ion-item *ngIf="positionListTarget.contact.accounts.length > 0" lines="none" class=""
                [ngClass]="{ 'unexpanded-list': unexpandedList.includes(positionListTarget.contact.id), 'expanded-list' : !unexpandedList.includes(positionListTarget.contact.id) }">
                <ion-list class="sub-list">
                  <avatar-list-item *ngFor="let account of positionListTarget.contact.accounts" [value]="account"
                    (clicked)="accountUpdate($event)" (click)="highlightAccount(account)" [isReadOnly]="isReadOnly"
                    [isSubItem]="true">
                  </avatar-list-item>
                </ion-list>
              </ion-item>

            </div>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </div>
  </ion-col>

  <ion-col class="ion-padding" size="12" size-lg="6">
    <div class="list-wrapper">
      <ind-section-header [viewData]="availableContactsHeader">
      </ind-section-header>

      <search-filter [placeholder]="'CONTACTS' | translate" [filterButtonBadgeCount]="availableContactsFilterCount"
        [filterDisabled]="filterDisabled" (onFilterClick)="openContactsFilterPopup('available')"
        [(searchText)]="availableContactSearchText" (onSearchChange)="onAvailableContactInput()"></search-filter>
      <!-- <ion-list class="scroll-list added-removed-row" (scroll)="onIonInfinite($event, 'available')">

        <ion-item *ngIf="availableContacts.length === 0">
          <ion-text class="no_list_item">{{ "NO_CONTACTS_AVAILABLE" | translate : {globalCustomerText:
            globalCustomerText} }}</ion-text>
        </ion-item>


        <avatar-list-item *ngFor="let availableContact of visibleAvailabeContacts" [value]="availableContact.contact"
          [isSubItem]="false" [isSelectionList]="true" (clicked)="addContact($event)"
          [isReadOnly]="isReadOnly" (click)="highlightContact($event, availableContact.contact)"></avatar-list-item>
      </ion-list> -->
      <ng-container *ngIf="availableContacts.length === 0"> <ion-item>
          <ion-text class="no_list_item">{{ "NO_CONTACTS_AVAILABLE" | translate : {globalCustomerText:
            globalCustomerText} }}</ion-text>
        </ion-item></ng-container>
      <ng-container *ngIf="availableContacts.length > 0">
        <cdk-virtual-scroll-viewport class="ion-content-scroll-host" itemSize="45" minBufferPx="1200" maxBufferPx="1200">
          <ion-list class="scroll-list added-removed-row">
            <avatar-list-item *cdkVirtualFor="let availableContact of filteredAvailableContacts"
              [value]="availableContact.contact" [isSubItem]="false" [isSelectionList]="true"
              (clicked)="addContact($event)" [isReadOnly]="isReadOnly"
              (click)="highlightContact($event, availableContact.contact)"></avatar-list-item>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </div>
  </ion-col>

</ion-row>