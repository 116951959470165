import { AuthenticationService } from './authentication.service';
import { GlobalUtilityService } from './global-utility.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpeechConfig, AudioConfig, SpeechRecognizer, OutputFormat } from 'microsoft-cognitiveservices-speech-sdk';
import { DeviceService } from './device/device.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Endpoints } from '../../config/endpoints.config';
import { ProductKeyMessagesEntity, CallNotesAssistantData, UserLikedStatus } from '../classes/notes-assisstant/products-keymessages.class';
import { ActivityService } from './activity/activity.service';
import { Brand } from '../classes/brand/brand.class';
import { EventsService } from './events/events.service';
import { MeetingDataService } from '../data-services/meeting/meeting.data.service';
import { AppointmentActivity } from '../classes/activity/appointment.activity.class';
import { GlobalErrorHandler } from './error-handler/error-handler-service';
import { Subject, BehaviorSubject, Observable ,  forkJoin } from 'rxjs';

/*
  Generated class for the LaunchDarklyProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

declare var cordova;
@Injectable({
  providedIn: 'root'
})
export class SpeechSDKService {
  recognizer: any;
  speechToTextInputStream: string = '';
  speechToTextStreamWithRecognition: string = '';
  productEntitymatches: Array<ProductKeyMessagesEntity> = [];
  keyMessagesEntityMatches: Array<ProductKeyMessagesEntity> = [];
  isRecording: boolean;
  CallNotesAssistantDataMappedToMeeting: CallNotesAssistantData[];
  private recognized$ = new Subject<boolean>();
  private recognized = this.recognized$.toPromise();
  intermediateInputStream: string;
  iosSpeech:any
  SDK:any

  constructor(
    public device: DeviceService,
    public androidPermission: AndroidPermissions,
    public activityService: ActivityService,
    public events: EventsService,
    public meetingService: MeetingDataService,
    public globalErrorHandler: GlobalErrorHandler,
    public http: HttpClient,
    private globalUtility: GlobalUtilityService,
    public authService: AuthenticationService
    //public events : Event
  ) {
    this.CallNotesAssistantDataMappedToMeeting = [];
  }

  resetdata() {
    this.speechToTextInputStream = '';
    this.speechToTextStreamWithRecognition = '';
    this.productEntitymatches = [];
    this.keyMessagesEntityMatches = [];
    this.isRecording = false;
    this.intermediateInputStream = ''
  }

  private formatSpeechText(input: string, isAppendingStringHasLength: boolean): string {
    // Trim the input, check its length, format the string, first char will be in upper case, rest will be lower cases,
    // replace all the dots with an empty string of space,
    // trim the string again to remove the first and last dot replaced with an empty space,
    // append the dot at the end to complete the sentence.
    // isAppendingStringHasLength: when true, add a space before so we have a proper sentence.
    let trimmed = input.trim();
    if (trimmed.length > 0) {
      let formatted = (trimmed[0].toUpperCase() + trimmed.slice(1).toLowerCase()).replace(/[.]/g,' ');
      const trimmedWithEndingSentence = formatted.trim() + '.'
      let finalResult = isAppendingStringHasLength ? (' ' + trimmedWithEndingSentence) : trimmedWithEndingSentence;
      return finalResult;
    } else {
      return '';
    }
  }

  async initRecognizer() {
    //SpeechSDK.
    if (this.device.isNativeApp && this.device.deviceFlags.android) {
      await this.androidPermission.requestPermission(this.androidPermission.PERMISSION.RECORD_AUDIO);
    }
    if(!this.device.deviceFlags.ios){
      if(!this.authService.notesAssistantConfig || !this.authService.notesAssistantConfig.speechSDKSubscriptionID) return
      if (!this.recognizer) {
        const SpeechSDKSubscriptionID = this.authService.notesAssistantConfig.speechSDKSubscriptionID;
        const region = this.authService.notesAssistantConfig.speechRegion;
        const lang = this.authService.notesAssistantConfig.speechLanguage;

        const SpeechSDKCID = this.authService.notesAssistantConfig.speechCID;
        let speechConfig =  SpeechConfig.fromSubscription(SpeechSDKSubscriptionID, region);
        speechConfig.endpointId = SpeechSDKCID;
        speechConfig.speechRecognitionLanguage = lang;

        let audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        // let audioConfig = new ((<any>window).AudioContext || (<any>window).webkitAudioContext)();

        this.recognizer = new SpeechRecognizer(speechConfig, audioConfig);

        this.recognizer.recognizing = (s, e)=> {
            console.log('recognizing', e.result.text);
            this.intermediateInputStream = e.result.text;
            //this.recognized$ = new Subject<boolean>()
        };

        this.recognizer.recognized = (s, e)=> {
          //this.recognized$.next(true);
          //this.recognized$.complete();
          console.log('recognized',e.result.text);
          this.intermediateInputStream = '';
            if(e.result && e.result.text) {
              this.speechToTextInputStream += this.formatSpeechText(e.result.text, this.speechToTextInputStream.length > 0);
            }
        };

        this.recognizer.cancelled = function (s, e) {
            console.log('caceled',e);
        };

        this.recognizer.sessionStarted = (s, e)=> {
            console.log('sessionStarted',e);
        };

        this.recognizer.sessionStopped = function (s, e) {
            console.log('sessionStopped',s,e);
        };

        this.recognizer.speechStartDetected = function (s, e) {
            console.log('speechStartDetected',s, e);
        };

        this.recognizer.addEventListener = function (s, e) {
          console.log('addEventListener',s, e);
        };


        // Signals that the speech service has detected that speech has stopped.
        this.recognizer.speechEndDetected = function (s, e) {
            console.log('speechEndDetected',s, e);
        };
      }

      this.resetdata();
      this.isRecording = true;
      this.recognizer.startContinuousRecognitionAsync(() => {
      },
        (error) => {
          console.log(error);
        },
      );
      }
    else{
      if(!this.iosSpeech){
        this.iosSpeech = (<any>cordova).plugins.msSpeechService
      }
      this.iosSpeech.setupSpeechRecognizer((res)=>{
        this.isRecording = true;
        this.iosSpeech.startContinuousRecognition((res)=>{
          console.log(res)
          if(res && res.isFinal){
            this.speechToTextInputStream += this.formatSpeechText(res.value, this.speechToTextInputStream.length > 0);
          }
        },(err)=>console.log(err))
      }, (err)=>{
        console.log('init recog error', err);
      }, this.authService.notesAssistantConfig.speechSDKSubscriptionID,
          this.authService.notesAssistantConfig.speechRegion,
          this.authService.notesAssistantConfig.speechCID);
    }
  }

  async stopRecording(getIntent: boolean = false, activityID: string = '') {
    if(!this.device.deviceFlags.ios){
      if(this.recognizer) {
        // this.recognizer.AudioSource.TurnOff();
        this.recognizer.stopContinuousRecognitionAsync();
      }
      this.recognizer = undefined;
    }
    else{
      this.iosSpeech.stopContinuousRecognition((res)=>{
        console.log('ios stop recognition ', res)
        this.isRecording = false;
      },
      (err)=>{
        console.log('ios stop recog error ', err);
        this.isRecording = false;
      })
    }
  }

  parseRecordingIntoStreamOfServiceCalls ():Observable<any[]>{
    let recordingChunks = this.globalUtility.chunkString(this.speechToTextInputStream, 500)
    let arrayOfCalls = [];
    const LUISAppID: string = this.authService.notesAssistantConfig.LUISAppID;
    const LUISSubscriptionKey: string = this.authService.notesAssistantConfig.LUISSubscriptionKey;
    const region = this.authService.notesAssistantConfig.LUISRegion
    var url = Endpoints.notes_assisstant.LUISDetection.replace('{{appID}}', LUISAppID)
      .replace('{{subKey}}', LUISSubscriptionKey)
      .replace('{{LUISregion}}', region);
    recordingChunks.map(s=>{
      let currURL = url + s;
      let currCall = this.http.get(currURL)
      arrayOfCalls.push(currCall)
    })
    return forkJoin(arrayOfCalls)
  }

  async getEntityAndIntentMatches(activityId?: string, source?: string): Promise<any> {

    this.speechToTextStreamWithRecognition = this.speechToTextInputStream;

    let LUISResponseData = {
      entities: [],
      query: ''
    };
    this.parseRecordingIntoStreamOfServiceCalls().subscribe(
      resList => {
        this.productEntitymatches = [];
        this.keyMessagesEntityMatches = [];
        console.log(resList);
        resList.map(data=>{
          LUISResponseData.entities.push(...data.entities);
          LUISResponseData.query = LUISResponseData.query?LUISResponseData.query + ' ' + data.query: data.query;
        })
        this.mapLUISResponseToProducts(LUISResponseData, activityId, source);
      }
    )
  }

  mapLUISResponseToProducts(rawData: any, activityId: string, source: string) {
    if (rawData && rawData.entities && rawData.entities.length > 0) {
      //let currentText = document.getElementById('inputStreamDisplayArea').innerHTML;
      rawData.entities.map((e) => {
        if (e.type == "keymessages") {
          let foundEntryIndex = this.keyMessagesEntityMatches.findIndex(o => o.entityName == e['entity'].toLowerCase())
          if (foundEntryIndex < 0) {
            let newKey = new ProductKeyMessagesEntity(e);
            this.keyMessagesEntityMatches.push(newKey);
            this.speechToTextStreamWithRecognition =
              this.speechToTextStreamWithRecognition.split(newKey.entityName).join('<mark>' + newKey.entityName + '</mark>');
          }
          else {
            this.keyMessagesEntityMatches[foundEntryIndex].resolution.values.push(...e['resolution']['values'])
          }
        }
        else if (e.type == "brand") {
          let foundEntryIndex = this.productEntitymatches.findIndex(o => o.entityName == e['entity'].toLowerCase())
          if (foundEntryIndex < 0) {
            let newProduct = new ProductKeyMessagesEntity(e);
            this.productEntitymatches.push(newProduct);
            this.speechToTextStreamWithRecognition =
              this.speechToTextStreamWithRecognition.split(newProduct.entityName).join('<mark>' + newProduct.entityName + '</mark>');
          }
          else {
            this.productEntitymatches[foundEntryIndex].resolution.values.push(...e['resolution']['values'])
          }
        }
      })
      //document.getElementById('inputStreamDisplayArea').innerHTML = this.speechToTextStreamWithRecognition;
    }
    if (rawData && rawData.query) {
      let index = this.CallNotesAssistantDataMappedToMeeting.findIndex(o => o.activityID == activityId);
      if (index >= 0) {
        let currentDataObj = this.CallNotesAssistantDataMappedToMeeting[index];
        currentDataObj.textStream += ' ' + this.speechToTextInputStream;
        currentDataObj.textStreamWithRecognitionEffects += ' ' + this.speechToTextStreamWithRecognition;
        currentDataObj.userLikedStatus = UserLikedStatus.NO_SELECTION;
        this.productEntitymatches.map((p) => {
          let productIndexInActivityProds = currentDataObj.productsMatched.findIndex((o) => o.entityName == p.entityName);
          if (productIndexInActivityProds >= 0) {
            currentDataObj.productsMatched[productIndexInActivityProds].resolution.values.push(...p.resolution.values)
          }
          else {
            currentDataObj.productsMatched.push(p)
          }
        })
        this.keyMessagesEntityMatches.map((k) => {
          let keyIndexInActivityKeys = currentDataObj.keysmatched.findIndex((o) => o.entityName == k.entityName);
          if (keyIndexInActivityKeys >= 0) {
            currentDataObj.keysmatched[keyIndexInActivityKeys].resolution.values.push(...k.resolution.values)
          }
          else {
            currentDataObj.keysmatched.push(k)
          }
        })
      }
      else {
        this.CallNotesAssistantDataMappedToMeeting.push({
          activityID: activityId,
          textStream: this.speechToTextInputStream,
          textStreamWithRecognitionEffects: this.speechToTextStreamWithRecognition,
          productsMatched: this.productEntitymatches,
          keysmatched: this.keyMessagesEntityMatches,
          sentForApproval: false,
          userLikedStatus: UserLikedStatus.NO_SELECTION,
          assistantDataAdditionsOrEdits: { products: [], keymessages: [] }
        })
      }
      this.activityService.selectedActivity.isCallNotesAvailable = true;
      if (source && source == 'fromMeeting') {
        this.events.publish('LUISdetectionFinished', source);
      }
    }
    this.resetdata();
  }
}
