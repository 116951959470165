<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='pageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list>
    <ion-row>
      <ion-col>
        <ind-form-field [viewData]="getModifiedDateFormField()"></ind-form-field>
      </ion-col>
      <ion-col>
        <ind-form-field [viewData]="getModifiedByFormField()"></ind-form-field>
      </ion-col>
    </ion-row>
    <!-- <ind-section-header [viewData]='yearSectionHeader'
      (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header> -->
    <ion-item-divider class="yearHeader">
      <ion-icon name="chevron-back" color="primary" (click)="prevYear()"></ion-icon>
      <ion-label> {{selectedDate.getFullYear()}} </ion-label>
      <ion-icon name="chevron-forward" color="primary" (click)="nextYear()"></ion-icon>
    </ion-item-divider>
    <ion-row class="enable-scroll year">
      <ng-container *ngFor="let month of months">
        <ion-col size="1" class='month-control'
          [ngClass]="this.selectedDate.getMonth() == month.id ? 'primaryBackground' : 'inactiveBackground'">
          <ion-button fill="clear" [ngClass]="getMonthColorWithBackGround(month)"
            (click)="setCurrentMonth(month)">{{month.name}}</ion-button>
        </ion-col>
        <div class="rightBorder"></div>
      </ng-container>
    </ion-row>
    <app-offtake-check-product *ngIf="offTakeCheck" [offTakeCheckCollections]="offTakeCheck.collections"
      [isEditable]="isEditable" (onInputClick)="onUpdate($event)"></app-offtake-check-product>
    <p class="noDataAvailablePlaceholder" *ngIf="!offTakeCheck && !isEditable">{{'NO_DATA_TO_DISPLAY' | translate}}</p>
  </ion-list>
</ion-content>