import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { AddressService } from '@omni/services/address/address.service';
import { IONote } from "@omni/classes/io/io-note.class";
import { AttendeeFormat, EventRegistrationStatus, EventStateCode, EventStatus, EventApprovalStatus } from './../../../enums/event/event.enum';
import {
  MAXIMUM_NOTE_ATTACHMENT_SIZE,
  NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX,
  shortMonthArray, toBase64,
  Utility
} from "@omni/utility/util";
import { ReadOnlyEventConfiguredFieldNames } from './../../../config/configuredField.config';
import { PopoverComponent, SelectListData } from "./../../popover/popover";
import { ConfigFieldOptionValue } from './../../../classes/activity/activity.class';
import { ConfiguredFields } from './../../../classes/authentication/configured.field.class';
import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { addMinutes, format, isDate, isPast, isSameDay } from 'date-fns';
import _, { difference, isEmpty } from "lodash";
import moment from 'moment';
import { Subject } from 'rxjs';
import { skip, takeUntil, debounceTime } from 'rxjs/operators';
import { Account } from '../../../classes/account/account.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { Contact } from '../../../classes/contact/contact.class';
import { EventActivity } from '../../../classes/events-tool/event.class';
import { AccountPageComponent } from '../../../components/account/account-page/account-page';
import { ContactPageComponent } from '../../../components/contact/contact-page/contact-page';
import { LocationComponent } from '../../../components/location/location';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { MainToolTemplateComponent } from '../../../components/shared/main-tool-template/main-tool-template';
import { NothingSelectedView } from '../../../components/shared/nothing-selected-view/nothing-selected-view';
import { EventsToolDataService } from '../../../data-services/event/events-tool.data.service';
import { OperationDetail } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '../../../models/mainToolTemplateDetail.model';
import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { AccesingMode } from '../../../services/case-management/case-management.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventName, EventsService } from '../../../services/events/events.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { MeetingStructureService } from '../../../services/meeting-structure/meeting-structure.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { SIGNATURE_DOC_FILE_NAME_SUFFIX } from '../../../services/pdf.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { checkTimeConflictWithOtherActivities, removeConflictIdFromOtherActivities } from '../../../utility/activity.utility';
import { FooterToolbarComponent } from '../../footer-toolbar/footer-toolbar';
import { PdfPreviewCmpInputData, PdfPreviewCmpJsPDFInput, PdfPreviewCmpSourceType, PdfPreviewComponent } from '../../pdf-preview/pdf-preview';
import { CaptureEventsComponent } from '../capture-events/capture-events';
import { EventSubType, EventType } from './../../../classes/events-tool/events-tool.event.class';
import { EventsToolService } from './../../../services/events-tool/events-tool.service';
import { getConfigFormFieldIsReadOnly, getConfigFormFieldType, getConfigFormFieldViewDataModelId, getConfigFormInputType } from '../../../utility/common.utility';
import { CustomerEventsService } from '@omni/services/customer-event/customer-events.service';
import { MarketingPlanManagementDataService } from "@omni/data-services/marketing-plan/marketing-plan-management.data.service";
import { EventGoal } from '../../../interfaces/event-tool/event-tool.interface';
import { EventAttendiesListComponent } from '../event-attendies-list/event-attendies-list.component';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService } from '@omni/services/disk/disk.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';

@Component({
    selector: 'event-details',
    templateUrl: 'event-details.html',
    styleUrls:['event-details.scss']
})
export class EventDetailsComponent {
    @ViewChild('footer', {static:true}) footer: FooterToolbarComponent;
    public eventDetailsPageTitle: IndPageTitleViewDataModel;
    public eventDetailsHeader: IndSectionHeaderViewDataModel;
    public eventNotesHeader: IndSectionHeaderViewDataModel;
    // public eventFormFieldValues: Array<IndFormFieldViewDataModel> = [];
    public statusField: IndFormFieldViewDataModel;
    public subjectField: IndFormFieldViewDataModel;
    public startDateField: IndDateTimeFormViewDataModel;
    public startTimeField: IndDateTimeFormViewDataModel;
    public endDateField: IndDateTimeFormViewDataModel;
    public endTimeField: IndDateTimeFormViewDataModel;
    public attendeeFormatField: IndFormFieldViewDataModel;
    public cutOffDateField: IndFormFieldViewDataModel;
    public cutOffDateTime: Record<string, any> = {};
    public selectionCriteriaField: IndFormFieldViewDataModel;
    public locationField: IndFormFieldViewDataModel;
    public goalField: IndFormFieldViewDataModel;
    public typeField: IndFormFieldViewDataModel;
    public subTypeField: IndFormFieldViewDataModel;
    public accountsField: IndFormFieldViewDataModel;
    public participantsField: IndFormFieldViewDataModel;
    public eventCapacityField: IndFormFieldViewDataModel;
    public productsField: IndFormFieldViewDataModel;
    public covisitorsField: IndFormFieldViewDataModel;
    public speakersField: IndFormFieldViewDataModel;
    public budgetAllocatedField: IndFormFieldViewDataModel;
    public currencyField: IndFormFieldViewDataModel;
    public buildingField: IndFormFieldViewDataModel;
    public configFields: IndFormFieldViewDataModel[] = [];
    public justificationField: IndFormFieldViewDataModel;
    public isCutOffDateFeatureEnabled = false;
    public isParticipantNotesFeatureEnabled = false;
    public currentEvent: EventActivity;
    public currentNotesString = '';
    public currentPartnersInputString = '';
    public partnersFormattedArray: Array<{
        id: number,
        text: string,
        editingInputText: string,
        isEditing: boolean,
    }> = [];
    public isReadOnlyMode = false;
    private isOwner = false;
    public isNotesEnabled = false;
    private isCovisitorView = false;
    public availableEventSubTypes: EventSubType[];
    public data: any;
    private initializeViewRequired = true;

    private readonly ngUnsubscribe$ = new Subject<boolean>();
    @Input() event: EventActivity;
    @Input() from: any;
    teamMemberCanUpdate: boolean = false;
    private isClickedProducts: boolean = false;
    private isClickedParticipants: boolean = false;
    private isClickedCutOffDate = false;
    private _isNewlyCreatedActivity: boolean = false;
    get isNewlyCreatedActivity(): boolean {
      return this._isNewlyCreatedActivity;
    }
    @Input() set isNewlyCreatedActivity(newValue: boolean) {
      this._isNewlyCreatedActivity = newValue;
    }
    public displayConflictMsg: boolean = false;
    public startTime: string;
    public endTime: string;
    public prevStartTime: string;
    public prevEndTime: string;
    @ViewChild('appConfigFieldDatePicker', { static: true }) configFieldDatePicker;
    public configFieldDateValue: string | Date;
    @ViewChild('genericDatePicker', { static: true }) genericDatePicker;
    public genericDatePickerValue: string | Date;
    public genericDatePickerMaxValue: string;
    public genericDatePickerDisplayFormat;
    public genericDatePickerPickerFormat;
    public shortMonth: string[] = shortMonthArray;
    private currentDateConfigFieldName: string;
    private isConfigDatePickerOpen: boolean = false;
    private booleanConfigFieldOptionValues: any[];
    private requiredFields: { [key: string]: { isEvaluated: boolean } } = {};
    private originalFieldValues: {} = {};
    private shouldBlockUserInput = false;

    @ViewChild('attachInput') attachInput: ElementRef;
    pageName = PageName.EventDetailsPageComponent;
    notesHeader: IndSectionHeaderViewDataModel;
    isEventOwnerOrTeamMember = false;
    isOffline = false;
    isEventCompleted = false;
    isEventCompleteButtonHidden = false;

    notesPlaceholder = '';
    isAttachmentAdded = false;
    isSaveNotesEnabled = false;
    attachmentTitle = '';
    tempNoteText = '';
    base64str: any;
    attachmentFile: any;
    currentCustomerNotes: IONote[] = [];
    isApprovalEnabled: boolean = false;
    isEventGoalEnabled: boolean = false;
    isAdditionalMandatoryFieldsEnabled: boolean = false;

    private lookupConfigFieldsData;
    private lookupFieldsDataInitialized:boolean = false;

    constructor(
        private readonly navService: NavigationService,
        public readonly translate: TranslateService,
        private readonly device: DeviceService,
        private readonly popoverCtrl: PopoverController,
        public readonly dateTimeFormatsService: DateTimeFormatsService,
        private readonly accountService: AccountOfflineService,
        private readonly contactService: ContactOfflineService,
        private readonly uiService: UIService,
        private readonly brandService: BrandOfflineService,
        private readonly activityService: ActivityService,
        public eventsToolService: EventsToolService,
        private readonly eventsToolDataService: EventsToolDataService,
        public readonly footerService: FooterService,
        public trackingService: TrackService,
        private readonly authService: AuthenticationService,
        public events: EventsService,
        private readonly meetingStructureService: MeetingStructureService,
        private readonly notificationService: NotificationService,
        private readonly alertService: AlertService,
        private _CD : ChangeDetectorRef,
        private datePipe: DatePipe,
        public toast: NotificationService,
        private customerEventsService: CustomerEventsService,
        private marketingPlanManagementDataService: MarketingPlanManagementDataService,
        private addressService: AddressService,
        private marketingMgmService: MarketingPlansManagementOfflineService,
        private readonly disk: DiskService,
        private activityDataService: ActivityDataService,
    ){
        this.eventsToolService.selectedEventUpdatesObserver$
        this.eventsToolService.selectedEventUpdatesObserver$
            .pipe(
                takeUntil(this.ngUnsubscribe$)
            ).subscribe((eventActivity: EventActivity)=>{
            // Close PDF viewer if it is open from previous event detail page
            if (this.navService.getActiveChildNavViewPageName() === PageName.PdfPreviewComponent) {
              this.navService.popChildNavPageWithPageTracking();
            }
            if(eventActivity){
                this.displayConflictMsg = false;
                this.isClickedParticipants = false;
                this.isClickedProducts = false;
                this.isClickedCutOffDate = false;
                this.currentDateConfigFieldName = undefined;
                this.isConfigDatePickerOpen = false;
                this.requiredFields = {};
                this.originalFieldValues = {};
                if(!(
                  (eventActivity.statecode === 0 && eventActivity.statuscode === EventStatus.DRAFT)
                  || (eventActivity.statecode === 0 && eventActivity.statuscode === EventStatus.REJECTED)
                  || (eventActivity.statecode === 1 && eventActivity.statuscode === EventStatus.FOR_REVIEW)
                  || (eventActivity.statecode === 0 && eventActivity.statuscode === EventStatus.APPROVED)
                  || (eventActivity.statecode === 1 && eventActivity.statuscode === EventStatus.COMPLETED)
                )) {
                    this._closePage();
                }else if (
                    !this.authService.user.positions.some(pos=> pos.ID === eventActivity.positionId) && //Event Mapped to user position
                    !this.authService.user.childPositions.some(childpos=> childpos.childPositionId === eventActivity.positionId) && // Event Mapped to child user position
                    !( eventActivity.covisitors && eventActivity.covisitors.length !== 0 && eventActivity.covisitors.some(cov => cov.teamMemberStatecode === 0 && cov.id === this.authService.user.systemUserID)) // Event Mapped as Covisitor
                ){
                    this._closePage();
                }else{
                    this.currentEvent = eventActivity;
                    this.isCovisitorView = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_UPDATE)
                                            && this.currentEvent.covisitors.some(c=> c.id === this.authService.user.systemUserID
                                            && c.teamMemberStatecode === 0 && c.isDeleted === false)
                                            && this.currentEvent.ownerId !== this.authService.user.systemUserID;
                    this.eventsToolService.isCovisitorViewMode = (this.isCovisitorView) ? true : false;
                    if(!this.isCovisitorView && !this._isEventCompleted){
                        this.availableEventSubTypes = this._getAvailableEventSubtypes(this.currentEvent.eventType);
                    }
                    this._setViewData(false);
                    if(this.navService.getCurrentMasterPageName() === PageName.EventsToolPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.EventDetailsPageComponent){
                        this.footerService.initButtons(FooterViews.EVENTS_DETAILS, { showPdfPreview: this.isOwner || this.isCovisitorView, isNotApprovedOrCompleted: this._isNotApprovedOrCompleted(), isEventCompleteButtonHidden: this.isEventCompleteButtonHidden });
                        this._updateFooterButtons();
                    }
                }
            }
        });

        this.device.screenWidth
            .pipe(
                skip(1),
                takeUntil(this.ngUnsubscribe$),
                debounceTime(400),
            ).subscribe((newOrientation) => {
            this._initHeaderView();
            this._updateFooterButtons(true);
        });

        this.footerService.buttonIdObserver
            .pipe(
                takeUntil(this.ngUnsubscribe$)
            ).subscribe(id => {
            if(id){
                switch(id){
                    case 'event_tool_activity_scrap':
                        this._handleEventScrap();
                        break;
                    case 'event_tool_activity_mark_complete':
                        this._handleMarkComplete();
                        break;
                    case 'event_tool_send_for_approval':
                        this._handleSubmitForApproval();
                        break;
                    case 'signatureEvents':
                        if(this._currentParticipantsString)
                          this._handleSignatureCapture();
                        break;
                    case 'attendeeEvents':
                    if (this._currentParticipantsString)
                      this._handleEventsAttendies();                       
                        break;
                    case 'checkInEvents':
                        if(this._currentParticipantsString)
                          this._handleSignatureCapture();
                        break;
                    case 'flipToEventDetails':
                        this.navService.popWithPageTracking();
                        break;
                    default:
                      break;
                }
            }
        });

        this.events.observe('updateMandatoryField').pipe(takeUntil(this.ngUnsubscribe$)).subscribe((field: PageName) => {
          this._setViewData(false);
        });

        this.events.observe('callBackFromParticipantSave').pipe(takeUntil(this.ngUnsubscribe$)).subscribe((data) => {
          this._handleContactComponentCallback(data, PageName.EventParticipantComponent);
        });

        this.meetingStructureService.accessedFrom = AccesingMode.EVENT_TOOL;
    }

    ngOnInit() {
        this.displayConflictMsg = this.isNewlyCreatedActivity && this.eventsToolService.hasTimeConflict(this.event);
        this.isNewlyCreatedActivity = false;
        this.eventsToolService.isEventParticipationDirty = false;
        this.currentEvent = this.event;
        this.currentCustomerNotes = this.currentEvent.annotations?.slice() || [];
        this.isOffline = this.device.isOffline;
        this.isEventCompleted = this.currentEvent?.statecode === 1
          && this.currentEvent?.statuscode === EventStatus.COMPLETED;
        this.isEventOwnerOrTeamMember =
          this.currentEvent.ownerId === this.authService.user.systemUserID
          || (
            Array.isArray(this.currentEvent.covisitors)
            && (
              this.currentEvent.covisitors.findIndex(
                c => c.id === this.authService.user.systemUserID
              ) >= 0
            )
          ) ? true : false;
        this.isNotesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES);
        this.isCovisitorView = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_UPDATE)
                                && this.currentEvent.covisitors?.some(c=> c.id === this.authService.user.systemUserID
                                && c.teamMemberStatecode === 0 && c.isDeleted === false)
                                && this.currentEvent.ownerId !== this.authService.user.systemUserID;
        this.isCutOffDateFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_CUT_OFF_DATE);
        this.isParticipantNotesFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_PARTICIPANT_NOTES);
        this.isApprovalEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_APPROVAL);
        this.isEventGoalEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_GOALS);
        this.isAdditionalMandatoryFieldsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ADDITIONAL_MANDATORY_FIELDS_FOR_EVENTS);
        this.isEventCompleteButtonHidden = this.authService.hasFeatureAction(FeatureActionsMap.REMOVE_COMPLETE_EVENT_BUTTON);
        if(!this.isCovisitorView && !this._isEventCompleted){
            this.availableEventSubTypes = this._getAvailableEventSubtypes(this.currentEvent.eventType);
        }
        this._setViewData(false);
        this.footerService.initButtons(FooterViews.EVENTS_DETAILS, { showPdfPreview: this.isOwner || this.isCovisitorView, isNotApprovedOrCompleted: this._isNotApprovedOrCompleted(), isEventCompleteButtonHidden: this.isEventCompleteButtonHidden });
        this._updateFooterButtons(true);

        this.startTime = moment(this.currentEvent.startDate).format();
        this.endTime = moment(this.currentEvent.endDate).format();
        this.dateTimeFormatsService.updateSelectedTimeEvent$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((timeData) => {
          if (timeData) {
            if (this.currentEvent && this.eventsToolService.selectedEventOnEventsTool) {
              const selectedEventId = this.eventsToolService.selectedEventOnEventsTool.ID || '';
              const selectedEventOfflineId = this.eventsToolService.selectedEventOnEventsTool.offlineId || '';
              if (!this.device.isOffline && (!_.isEmpty(selectedEventId) && this.currentEvent.ID == selectedEventId)
                || (this.device.isOffline && !_.isEmpty(selectedEventOfflineId) && this.currentEvent.offlineId == selectedEventOfflineId)) {
                if (timeData.id == DateTimeFieldType.StartTimeField) {
                  this.startTime = timeData.time;
                  setTimeout(() => {
                    this.handleStartDateTimeValue();
                  }, 10);
                } else if (timeData.id == DateTimeFieldType.EndTimeField) {
                  this.endTime = timeData.time;
                  setTimeout(() => {
                    this.handleEndDateTimeValue();
                  }, 10);
                }
              }
            }
          }
        });

        this.booleanConfigFieldOptionValues = [
          {
            label: this.translate.instant('YES'),
            value: true,
          },
          {
            label: this.translate.instant('NO'),
            value: false,
          },
        ];
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.complete();
    }

    ngDoCheck() {
      if ((this.navService.getCurrentMasterPageName() === PageName.EventsToolPageComponent || this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent || this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) && this.navService.getActiveChildNavViewPageName() === PageName.EventDetailsPageComponent) {
            if(!this.footerService.getActiveButtonsList().some(id => id === 'event_tool_activity_scrap' || id === 'event_tool_activity_mark_complete' || id === 'event_tool_send_for_approval')){
                this.footerService.initButtons(FooterViews.EVENTS_DETAILS, { showPdfPreview: this.isOwner || this.isCovisitorView, isNotApprovedOrCompleted: this._isNotApprovedOrCompleted(), isEventCompleteButtonHidden: this.isEventCompleteButtonHidden });
                this._updateFooterButtons(true);
            }
        }
    }

    private _shouldDisableScrapButton(): boolean {
      return this.from === 'FollowUpDetail'
        || (!this.isApprovalEnabled && this.isReadOnlyMode)
        || (this.isApprovalEnabled && !this._isEventStatusGoodForApprovalSubmit());
    }
    private _shouldDisableSubmitButton(): boolean {
      return this._isEventCompleted
        || this.from === 'FollowUpDetail'
        || (!this.isApprovalEnabled && this.isReadOnlyMode)
        || this.currentEvent.offlineApprovalSubmit
        || (this.isApprovalEnabled && !this._isEventStatusGoodForApprovalSubmit())
        ? true : false;
    }
    private _isEventStatusGoodForApprovalSubmit(): boolean {
      return this.isOwner
        && this.currentEvent.approvalStatus === EventApprovalStatus.Draft
        && (
          this.currentEvent.statuscode === EventStatus.DRAFT
          || this.currentEvent.statuscode === EventStatus.REJECTED
          || this.currentEvent.statuscode === EventStatus.FOR_REVIEW
        ) ? true : false;
    }
    private _isNotApprovedOrCompleted() {
      return !(
        this.currentEvent.statuscode === EventStatus.APPROVED
        || this.currentEvent.statuscode === EventStatus.COMPLETED
      ) ? true : false;
    }

    private _updateFooterButtons(setAlignment = false): void {
      const disableButtonList: Array<string> = [];
      const attendeeEvents = 'attendeeEvents';
      const submitBtn = this.isApprovalEnabled
        && this._isNotApprovedOrCompleted()
        ? 'event_tool_send_for_approval'
        : !this.isEventCompleteButtonHidden
          ? 'event_tool_activity_mark_complete'
          : '';

      const buttonsToDisplay = [attendeeEvents, 'event_tool_activity_scrap'];
      submitBtn && buttonsToDisplay.push(submitBtn);
      if (this.isOwner || this.isCovisitorView) {
          buttonsToDisplay.unshift('pdfPreview');
          if (this.from === 'FollowUpDetail') {
            disableButtonList.push('pdfPreview');
          }
      }

      if (this._shouldDisableSubmitButton() && submitBtn) {
          disableButtonList.push(submitBtn);
      }
      if(this._shouldDisableScrapButton()){
          disableButtonList.push('event_tool_activity_scrap');
      }
      let checkinEligible: boolean = false;
      if (this._currentParticipantsString) {
        checkinEligible = true;
        if (this.currentEvent.attendeeFormat === AttendeeFormat.propose && !this.currentEvent.participants.some(p => p.indskr_reasons == EventRegistrationStatus.Invited || p.indskr_reasons == EventRegistrationStatus.Accepted)) {
          checkinEligible = false;
        }

      }
        if(!checkinEligible || this.from == 'EventSelectionPage' || this.from === 'FollowUpDetail'){
            disableButtonList.push(attendeeEvents);
        }
        if(disableButtonList.length !== 0){
            this.footerService.disableButton(disableButtonList);
        }else{
            this.footerService.enableButtons(buttonsToDisplay);
        }

        if (setAlignment) {
            const numOfExtraButtons = this.footerService.getNumOfMoreButtonsOnly();
            const buttonsToBeAligned: { id: string, align: 'left' | 'right' }[] = [];
            for (let i = 0; i < buttonsToDisplay.length; i++) {
                const buttonId = buttonsToDisplay[i];
                if (i <= numOfExtraButtons - 1) {
                    buttonsToBeAligned.push({ id: buttonId, align: 'left' });
                } else {
                    buttonsToBeAligned.push({ id: buttonId, align: 'right' });
                }
            }
            this.footerService.alignButtons(buttonsToBeAligned);
            if (this.footer) {
              this.footer.refresh();
            }
        }
    }

    private fomatPartnersString(): void {
        this.partnersFormattedArray = [];
        let len = 0;
        this.currentEvent.partners.split(',').forEach((item)=> {
            if(item && item.trim()){
                const temp = {
                    id:len,
                    text: item.trim(),
                    editingInputText: item.trim(),
                    isEditing: false,
                };
                this.partnersFormattedArray.push(temp);
                len = len + 1;
            }
        });
    }

    private _updateGoalFieldData() {
      if (this.isEventGoalEnabled) {
        const isRequired = !this.isReadOnlyMode && this.isAdditionalMandatoryFieldsEnabled;
        this.goalField = {
          label: this.translate.instant('EVENT_GOAL'),
          inputText: this.currentEvent.eventGoalFormatted,
          id:'event-goal-field',
          isReadOnly: true,
          isDisabled: !(!this.isReadOnlyMode && this.eventsToolService.eventGoals.length !== 0),
          customPlaceholderLabel: !this.isReadOnlyMode && this.eventsToolService.eventGoals.length !== 0? this.translate.instant('SELECT_EVENT_GOAL'):this.translate.instant('NO_EVENT_GOAL'),
          showArrow:!this.isReadOnlyMode && this.eventsToolService.eventGoals.length !== 0,
          formFieldType: FormFieldType.POPOVER_SELECT,
          isRequired: isRequired,
          errorMessage: (!this.isReadOnlyMode || (this.isCovisitorView && !this._isEventCompleted)) ? this.activityService.getErrorMessageRequiredField() : undefined,
          isEmptyRequiredField: isRequired
                                && this.requiredFields['event-goal-field']?.isEvaluated === true
                                && !this.currentEvent.eventGoalFormatted,
          eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
        };
      }
    }
    private _updateTypeFieldData() {
      const isDisabled = this.isReadOnlyMode
        || (
          this.isEventGoalEnabled
          && _.isEmpty(this.currentEvent.eventGoal)
        )
        || !Array.isArray(this.eventsToolService.eventTypes)
        || this.eventsToolService.eventTypes.length <= 0;
      const isRequired = !this.isReadOnlyMode && this.isAdditionalMandatoryFieldsEnabled;
      this.typeField = {
        label: this.translate.instant('EVENT_TYPE'),
        inputText:this.currentEvent.eventTypeFormatted,
        id:'event-type-field',
        isReadOnly:true,
        isDisabled,
        customPlaceholderLabel: !this.isReadOnlyMode && this.eventsToolService.eventTypes.length !== 0? this.translate.instant('SELECT_EVENT_TYPE'):this.translate.instant('NO_EVENT_TYPE'),
        showArrow: !isDisabled,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: isRequired,
        errorMessage: (isRequired || (this.isCovisitorView && !this._isEventCompleted)) ? this.activityService.getErrorMessageRequiredField() : undefined,
        isEmptyRequiredField: isRequired
                              && this.requiredFields['event-type-field']?.isEvaluated === true
                              && !this.currentEvent.eventTypeFormatted,
        eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
      };
    }
    private _updateSubTypeFieldData() {
      this.subTypeField = {
        label: this.translate.instant('EVENT_SUBTYPE'),
        inputText:this.currentEvent.eventSubTypeFormatted,
        id:'event-sub-type-field',
        isReadOnly:true,
        isDisabled:this.isReadOnlyMode || _.isEmpty(this.currentEvent.eventType) || _.isEmpty(this.availableEventSubTypes),
        customPlaceholderLabel:(_.isEmpty(this.currentEvent.eventTypeFormatted)) ? this.translate.instant('NO_EVENT_SUBTYPE')
                                      : (this.isReadOnlyMode || _.isEmpty(this.availableEventSubTypes))
                                          ? this.translate.instant('NO_EVENT_SUBTYPE')
                                          :this.translate.instant('SELECT_EVENT_SUBTYPE'),
        showArrow:!this.isReadOnlyMode && !_.isEmpty(this.currentEvent.eventType) && !_.isEmpty(this.availableEventSubTypes),
        formFieldType: FormFieldType.POPOVER_SELECT,
        eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
      };
    }
    private _updateSpeakersFieldData() {
      const isSpeakerRequired = !!(
        !this.isReadOnlyMode
        && this.isAdditionalMandatoryFieldsEnabled
        && this.currentEvent.eventTypeFormatted
        && this.eventsToolService.eventTypeNeededForParticipantsMandatory.includes(this.currentEvent.eventTypeFormatted)
      );
      this.speakersField = {
          label: this.translate.instant('SPEAKERS'),
          inputText: this._currentSpeakersString,
          id:'speakers-field',
          isReadOnly:true,
          isDisabled: (this.isReadOnlyMode && this.currentEvent.speakers.filter(a=> !a.isDeleted).length === 0) || (!this.isReadOnlyMode && this.eventsToolService.eventSpeakers.length === 0),
          customPlaceholderLabel:(this.isReadOnlyMode || this.eventsToolService.eventSpeakers.length === 0)?this.translate.instant('NO_SPEAKERS'):this.translate.instant('SELECT_SPEAKERS'),
          showArrow:(!this.isReadOnlyMode && this.eventsToolService.eventSpeakers.length !== 0) || this.currentEvent.speakers.filter(a=> !a.isDeleted).length !== 0,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
          isRequired: isSpeakerRequired,
          errorMessage: (isSpeakerRequired || (this.isCovisitorView && !this._isEventCompleted)) ? this.activityService.getErrorMessageRequiredField() : undefined,
          isEmptyRequiredField: isSpeakerRequired
                                && this.requiredFields['speakers-field']?.isEvaluated === true
                                && !this._currentSpeakersString,
          eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
      };
    }

    private _setViewData(skipConfigListRefresh = true){
        if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_UPDATE)) {
            if (this.authService.user.systemUserID === this.currentEvent.ownerId) {
                this.isOwner = this.authService.user.systemUserID === this.currentEvent.ownerId;
                this.isReadOnlyMode = !(
                  this.isOwner
                  && (
                    (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.DRAFT)
                    || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.REJECTED)
                    || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.APPROVED)
                  ));
                this.teamMemberCanUpdate = this.currentEvent.statecode === 0 ? true : false;
            } else {
              this.isOwner = false;
              this.isReadOnlyMode = true;
              this.teamMemberCanUpdate = this.currentEvent.statecode === 0 ? true : false;
            }
        } else {
            this.isOwner = this.authService.user.systemUserID === this.currentEvent.ownerId;
            this.isReadOnlyMode = !(
              this.isOwner
              && (
                (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.DRAFT)
                || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.REJECTED)
                || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.APPROVED)
              ));
            this.teamMemberCanUpdate = (this.isOwner
              && (
                (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.DRAFT)
                || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.REJECTED)
                || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === EventStatus.APPROVED)
              ));
        }
        if (this.from === 'EventSelectionPage' || this.from === 'FollowUpDetail') {
          this.isReadOnlyMode = true;
        }

        this.fomatPartnersString();
        this.currentNotesString = this.currentEvent.notes;
        // Initialize view for Event Activity Header
        this._initHeaderView();

        this._updateFooterButtons();

        // Initialize view for Event Activity Fields
        if(this.initializeViewRequired) {
          // this.eventFormFieldValues = [
            this.statusField = {
                label: this.translate.instant('STATUS'),
                inputText: this.events.getFormattedStatus(
                  this.currentEvent,
                  this.currentEvent.offlineApprovalSubmit ? EventStatus.FOR_REVIEW : undefined
                )+((this.currentEvent.pendingPushToDynamics)?` - ${this.translate.instant('PENDING_SYNC')}`:''),
                id:'status-field',
                isReadOnly:true,
                isDisabled:false,
                showArrow:false,
            };
            this.subjectField = {
                label: this.translate.instant('SUBJECT'),
                inputText: this.events.getTranslatedSubject(this.currentEvent),
                inputValue :this.currentEvent.name,
                id:'subject-field',
                isReadOnly:this.isReadOnlyMode,
                isDisabled:this.isReadOnlyMode,
                customPlaceholderLabel:this.translate.instant('XPERIENCES_RELATIONSHIP_EVENT'),
                showArrow:!this.isReadOnlyMode,
                formFieldType: FormFieldType.INLINE_INPUT,
                isRequired: !this.isReadOnlyMode,
                errorMessage: !!this.isReadOnlyMode ? this.activityService.getErrorMessageRequiredField() : undefined,
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
            };
            this.displayConflictMsg = this.eventsToolService.hasTimeConflict(this.currentEvent);
            this.startTime = moment(this.currentEvent.startDate).format();
            this.endTime = moment(this.currentEvent.endDate).format();
            const dateTime = {startDateTime: this.startTime, endDateTime: this.endTime};
            this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
            const currentEventDateTime = this.getFormattedDateTimeText();
            this.startDateField = {
              isDateField: true,
              label: this.translate.instant('START_DATE'),
              customPlaceholderLabel: this.translate.instant('SELECT_START_DATE'),
              inputText: currentEventDateTime.startDate,
              startDateTimeValue: currentEventDateTime.startDateTime,
              endDateTimeValue: currentEventDateTime.endDateTime,
              fromViewPage: CurViewPageType.Event,
              id: DateTimeFieldType.StartDateField,
              isReadOnly: true,
              isDisabled: this.isReadOnlyMode,
              showArrow: !this.isReadOnlyMode,
              isRequired: !this.isReadOnlyMode,
              eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
            };
            this.startTimeField = {
              isTimeField: true,
              label: this.translate.instant('START_TIME'),
              customPlaceholderLabel: this.translate.instant('SELECT_START_TIME'),
              inputText: currentEventDateTime.startTime,
              startDateTimeValue: currentEventDateTime.startDateTime,
              endDateTimeValue: currentEventDateTime.endDateTime,
              fromViewPage: CurViewPageType.Event,
              id: DateTimeFieldType.StartTimeField,
              isDisabled: this.isReadOnlyMode,
              showArrow: !this.isReadOnlyMode,
              isRequired: !this.isReadOnlyMode,
            };
            this.endDateField = {
              isDateField: true,
              label: this.translate.instant('END_DATE'),
              customPlaceholderLabel: this.translate.instant('SELECT_END_DATE'),
              inputText: currentEventDateTime.endDate,
              startDateTimeValue: currentEventDateTime.startDateTime,
              endDateTimeValue: currentEventDateTime.endDateTime,
              fromViewPage: CurViewPageType.Event,
              id: DateTimeFieldType.EndDateField,
              isReadOnly: true,
              isDisabled: this.isReadOnlyMode,
              showArrow: !this.isReadOnlyMode,
              isRequired: !this.isReadOnlyMode,
              eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
            };
            this.endTimeField = {
              isTimeField: true,
              label: this.translate.instant('END_TIME'),
              customPlaceholderLabel: this.translate.instant('SELECT_END_TIME'),
              inputText: currentEventDateTime.endTime,
              startDateTimeValue: currentEventDateTime.startDateTime,
              endDateTimeValue: currentEventDateTime.endDateTime,
              fromViewPage: CurViewPageType.Event,
              id: DateTimeFieldType.EndTimeField,
              isReadOnly: true,
              isDisabled: this.isReadOnlyMode,
              showArrow: !this.isReadOnlyMode,
              isRequired: !this.isReadOnlyMode,
            };
            this.locationField = {
                label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
                inputText:this.currentEvent.locationFormatted,
                id:'location-field',
                isReadOnly:true,
                isDisabled:this.isReadOnlyMode,
                placeholderLabel:!this.isReadOnlyMode?this.translate.instant('ACTIVITY_DETAILS_LOCATION'):this.translate.instant('NO_LOCATION'),
                showArrow:!this.isReadOnlyMode,
                formFieldType: FormFieldType.NEW_PAGE_SELECT,
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
            };
            this.eventCapacityField = {
            label: this.translate.instant('EVENT_CAPACITY'),
            inputText: this.currentEvent.msevtmgt_maximumeventcapacity != null ? this.currentEvent.msevtmgt_maximumeventcapacity + "" : "",
            inputValue: this.currentEvent.msevtmgt_maximumeventcapacity != null ? this.currentEvent.msevtmgt_maximumeventcapacity : "",
            inputType: 'number',
            id: 'eventCapacity-field',
            isReadOnly: this.isReadOnlyMode,
            isDisabled: this.currentEvent.ownerId !== this.authService.user.systemUserID,
            customPlaceholderLabel: this.translate.instant('ENTER_EVENT_CAPACITY'),
            showArrow: !this.isReadOnlyMode,
            formFieldType: FormFieldType.INLINE_INPUT,
            eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
            };

            this._updateGoalFieldData();
            this._updateTypeFieldData();
            this._updateSubTypeFieldData();

            if (this.isCutOffDateFeatureEnabled) {
              const isAttendeeFormatPropose = this.currentEvent.attendeeFormat === AttendeeFormat.propose;
              const isPastEventStartDate: boolean = new Date().getTime() >= this.currentEvent.startDate.getTime();
              this.attendeeFormatField = {
                id: 'attendeeFormat',
                label: this.translate.instant('ATTENDEE_FORMAT'),
                inputText: this.getAttendeeFormatText(this.currentEvent.attendeeFormat),
                customPlaceholderLabel: this.translate.instant('SELECT_ATTENDEE_FORMAT'),
                formFieldType: FormFieldType.POPOVER_SELECT,
                isReadOnly: true,
                isDisabled: true,
                showArrow: false,
                // eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
              };
              const isEmptyRequiredField = this.isClickedCutOffDate && _.isEmpty(this.currentEvent.cutOffDate);
              this.cutOffDateField = {
                id: 'cutOffDate',
                label: this.translate.instant('CUT_OFF_DATE'),
                inputText: this.currentEvent.cutOffDate
                  ? this.datePipe.transform(this.currentEvent.cutOffDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
                  : '',
                customPlaceholderLabel: this.translate.instant('SELECT_CUT_OFF_DATE'),
                formFieldType: FormFieldType.POPOVER_SELECT,
                isReadOnly: true,
                isDisabled: !this.isOwner || this.isReadOnlyMode || isPastEventStartDate,
                showArrow: this.isOwner && !this.isReadOnlyMode && !isPastEventStartDate,
                isRequired: !this.isReadOnlyMode && isAttendeeFormatPropose,
                isEmptyRequiredField,
                errorMessage: isEmptyRequiredField ? this.activityService.getErrorMessageRequiredField() : undefined,
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
              };
              this.selectionCriteriaField = {
                id: 'selectionCriteria',
                label: this.translate.instant('SELECTION_CRITERIA'),
                inputText: this.currentEvent.selectionCriteria ?? '',
                inputValue: this.currentEvent.selectionCriteria ?? '',
                customPlaceholderLabel: this.translate.instant('ENTER_SELECTION_CRITERIA'),
                formFieldType: FormFieldType.INLINE_INPUT,
                isDisabled: this.isReadOnlyMode || !this.isOwner,
                showArrow: this.isOwner && !this.isReadOnlyMode,
                inlineInputCssClass: 'no-ellipses',
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
              }
            }
            // Building
            this.buildingField = {
              id: 'building',
              label: this.translate.instant('BUILDING'),
              inputText: this.currentEvent.building?.msevtmgt_name,
              secondaryInfoText: this.currentEvent.building?.cityName ?? '',
              customPlaceholderLabel: this.translate.instant('SELECT_BUILDING'),
              formFieldType: FormFieldType.NEW_PAGE_SELECT,
              isReadOnly: true,
              isDisabled: this.isReadOnlyMode || !this.isOwner,
              showArrow: this.isOwner && !this.isReadOnlyMode,
              eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
            };
            // Approval
            if (this.isApprovalEnabled) {
              const budgetAllocatedInputText = this.currentEvent.budgetAllocated === undefined || this.currentEvent.budgetAllocated === null ? '' : '' + this.currentEvent.budgetAllocated;
              const budgetAllocatedInputValue = this.currentEvent.budgetAllocated === undefined ? null : this.currentEvent.budgetAllocated;
              this.budgetAllocatedField = {
                id: 'budgetAllocated',
                label: this.translate.instant('TOTAL_BUDGET'),
                inputText: budgetAllocatedInputText,
                inputValue: budgetAllocatedInputValue,
                inputType: 'number',
                inputMin: 0,
                inputDebounceTime: 300,
                customPlaceholderLabel: this.translate.instant('ENTER_TOTAL_BUDGET'),
                showArrow: this.isOwner && !this.isReadOnlyMode,
                FormFieldType: FormFieldType.INLINE_INPUT,
                isReadOnly: this.isReadOnlyMode,
                isDisabled: this.isReadOnlyMode || !this.isOwner,
                isRequired: !this.isReadOnlyMode,
                errorMessage: (!this.isReadOnlyMode || (this.isCovisitorView && !this._isEventCompleted)) ? this.activityService.getErrorMessageRequiredField() : undefined,
                isEmptyRequiredField: this.requiredFields['budgetAllocated']?.isEvaluated === true
                                      && (
                                        !budgetAllocatedInputText
                                        || budgetAllocatedInputValue === null
                                      ),
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
              };
              let currencyInputText = '';
              if (this.currentEvent.currencyId) {
                const currency = this.marketingMgmService.getCurrencyById(this.currentEvent.currencyId);
                currencyInputText = currency
                  ? `${currency.name} (${currency.code})`
                  : '';
              }
              this.currencyField = {
                id: 'currency',
                label: this.translate.instant('CURRENCY'),
                inputText: currencyInputText,
                customPlaceholderLabel: this.translate.instant('SELECT_CURRENCY'),
                showArrow: this.isOwner && !this.isReadOnlyMode,
                formFieldType: FormFieldType.NEW_PAGE_SELECT,
                isReadOnly: true,
                isDisabled: this.isReadOnlyMode || !this.isOwner,
                isRequired: !this.isReadOnlyMode,
                errorMessage: (!this.isReadOnlyMode || (this.isCovisitorView && !this._isEventCompleted)) ? this.activityService.getErrorMessageRequiredField() : undefined,
                isEmptyRequiredField: this.requiredFields['currency']?.isEvaluated === true
                                      && !currencyInputText,
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
              }
            }
            // Configured Fields
            if (!skipConfigListRefresh) {
              this.configFields.length = 0;
              if (Array.isArray(this.authService.user.eventConfiguredFields)) {
                for (let index = 0; index < this.authService.user.eventConfiguredFields.length; index++) {
                  const configuredField: ConfiguredFields = this.authService.user.eventConfiguredFields[index];
                  const id = getConfigFormFieldViewDataModelId(configuredField.fieldType, configuredField.fieldName);
                  const { inputText, inputValue } = this.getConfigFieldInputTextAndValue(configuredField.fieldType, configuredField.fieldName);
                  const formFieldType = getConfigFormFieldType(configuredField.fieldType);
                  const forceReadOnly: boolean = ReadOnlyEventConfiguredFieldNames.includes(configuredField.fieldName);
                  const isReadOnly: boolean = forceReadOnly
                                              || getConfigFormFieldIsReadOnly(configuredField.fieldType, configuredField.readOnly);
                  const isDisabled: boolean = formFieldType === FormFieldType.POPOVER_SELECT && configuredField.readOnly
                                              ? true
                                              : (this.isReadOnlyMode || configuredField.readOnly);
                  const isRequired: boolean = !this.isReadOnlyMode && configuredField.mandatory === 'true';
                  const isEmptyRequiredField: boolean = this.requiredFields[id]?.isEvaluated === true
                                                        && (inputText === '' || inputText === undefined || inputText === null);
                  const field: IndFormFieldViewDataModel = {
                    id,
                    formFieldType,
                    label: configuredField.fieldLabel,
                    inputType: getConfigFormInputType(configuredField.fieldType),
                    inputText,
                    inputValue,
                    placeholderLabel: !this._isEventCompleted ? configuredField.fieldLabel : `${this.translate.instant('NO')} ${configuredField.fieldLabel}`,
                    isReadOnly,
                    isDisabled,
                    isRequired,
                    showArrow: !this.isReadOnlyMode && !isDisabled && !forceReadOnly,
                    errorMessage: isRequired ? this.activityService.getErrorMessageRequiredField() : undefined,
                    isEmptyRequiredField,
                    eventHandler: (id: string, event, eventName) => this._handleConfigFieldEvent(
                      id,
                      event,
                      eventName,
                      configuredField.fieldType,
                      configuredField.fieldName,
                    ),
                  }
                  this.configFields.push(field);
                }
              }
            }

            this.accountsField = {
                label: this.translate.instant('ACCOUNTS'),
                inputText: this._currentAccountsString,
                id:'accounts-field',
                isReadOnly:true,
                isDisabled:this.isReadOnlyMode && this.currentEvent.accounts.filter(a=> !a.isDeleted).length === 0,
                customPlaceholderLabel:this.isReadOnlyMode?this.translate.instant('NO_ACCOUNTS'):this.translate.instant('SELECT_ACCOUNTS'),
                showArrow:!this.isReadOnlyMode || this.currentEvent.accounts.filter(a=> !a.isDeleted).length !== 0,
                formFieldType: FormFieldType.NEW_PAGE_SELECT,
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
            };
            this.participantsField = {
                label: this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS'),
                inputText: this._currentParticipantsString,
                id:'participants-field',
                isReadOnly:true,
                isDisabled:this.isReadOnlyMode
                  && this.currentEvent.participants
                    .filter(a=> !(
                      a.isDeleted
                      || a.indskr_reasons === EventRegistrationStatus.Cancelled
                      || a.indskr_reasons === EventRegistrationStatus.Declined
                      || a.indskr_reasons === EventRegistrationStatus['Cancelled late'])).length === 0 && !this.isCovisitorView,
                customPlaceholderLabel:this.isReadOnlyMode ? ((this.isCovisitorView && !this._isEventCompleted)
                                                              ? this.translate.instant('SELECT_PARTICIPANTS')
                                                              :this.translate.instant('NO_PARTICIPANTS'))
                                                              : this.translate.instant('SELECT_PARTICIPANTS'),
                showArrow:!this.isReadOnlyMode
                  || (this.isCovisitorView && !this._isEventCompleted)
                  || this.currentEvent.participants
                      .filter(a=> !(
                        a.isDeleted
                        || a.indskr_reasons === EventRegistrationStatus.Cancelled
                        || a.indskr_reasons === EventRegistrationStatus.Declined
                        || a.indskr_reasons === EventRegistrationStatus['Cancelled late'])).length !== 0,
                formFieldType: FormFieldType.NEW_PAGE_SELECT,
                isRequired: !this.isReadOnlyMode || (this.isCovisitorView && !this._isEventCompleted),
                errorMessage: (!this.isReadOnlyMode || (this.isCovisitorView && !this._isEventCompleted)) ? this.activityService.getErrorMessageRequiredField() : undefined,
                isEmptyRequiredField: this.isClickedParticipants && _.isEmpty(this._currentParticipantsString),
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
            };
            this.productsField = {
                label: this.translate.instant('PRODUCTS'),
                inputText: this._currentProductsString,
                id:'products-field',
                isReadOnly:true,
                isDisabled:(this.isReadOnlyMode && this.currentEvent.products.filter(a=> !a.isDeleted).length === 0) || (!this.isReadOnlyMode && this.brandService.brands.length === 0),
                customPlaceholderLabel:(this.isReadOnlyMode || this.brandService.brands.length === 0)?this.translate.instant('NO_PRODUCTS'):this.translate.instant('SELECT_PRODUCTS'),
                showArrow:(!this.isReadOnlyMode && this.brandService.brands.length !== 0) || this.currentEvent.products.filter(a=> !a.isDeleted).length !== 0,
                formFieldType: FormFieldType.NEW_PAGE_SELECT,
                isRequired: !this.isReadOnlyMode,
                errorMessage: !this.isReadOnlyMode ? this.activityService.getErrorMessageRequiredField() : undefined,
                isEmptyRequiredField: this.isClickedProducts && _.isEmpty(this._currentProductsString),
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
            };
            this.covisitorsField = {
                label: this.translate.instant('EVENT_TEAM_MEMBERS'),
                inputText: this._currentCovisitorsString,
                id:'covisitors-field',
                isReadOnly:true,
                isDisabled: (this.isReadOnlyMode && this.currentEvent.covisitors.filter(a=> !a.isDeleted).length === 0) || (!this.isReadOnlyMode && this.activityService.repUserList.length === 0),
                customPlaceholderLabel:(this.isReadOnlyMode || this.activityService.repUserList.length === 0)?this.translate.instant('NO_EVENT_TEAM_MEMBERS'):this.translate.instant('SELECT_EVENT_TEAM_MEMBERS'),
                showArrow:(!this.isReadOnlyMode && this.activityService.repUserList.length !== 0) || this.currentEvent.covisitors.filter(a=> !a.isDeleted).length !== 0,
                formFieldType: FormFieldType.NEW_PAGE_SELECT,
                eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id,event,eventName),
            };
            this._updateSpeakersFieldData();
          // this.meetingStructureService.createEmbeddedInteractionsForEventCheckIn(this.currentEvent);
          this.meetingStructureService.createEmbeddedInteractionsEvents(this.currentEvent);
        }
        this.initializeViewRequired = true;
    }

    private _initHeaderView(): void {
        let titleButtons = [];
      if (!this._isSplitPaneView || this.from === 'AccountTimeline' || this.from === 'ContactTimeline' || this.from === 'FollowUpDetail') {
            titleButtons = [{
                id: 'close',
                icon: 'chevron-back-outline',
                isDisabled: false,
                align: 'left'
            }];
        }
        titleButtons.push(
          {
            id: 'event_scrap',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: this._shouldDisableScrapButton(),
            align: 'right'
          }
        );
        if (
          this.isApprovalEnabled
          && this._isNotApprovedOrCompleted()
        ) {
          titleButtons.push({
            id: 'event_send_for_approval',
            imgSrc: 'assets/imgs/header_send.svg',
            name: this.translate.instant('SEND'),
            isDisabled: this._shouldDisableSubmitButton(),
            align: 'right'
          });
        } else if (!this.isEventCompleteButtonHidden) {
          titleButtons.push({
            id: 'event_mark_complete',
            imgSrc: 'assets/imgs/header_complete.svg',
            name: this.translate.instant('COMPLETE'),
            isDisabled: this._isEventCompleted || this.isReadOnlyMode,
            align: 'right'
          });
        }
        this.eventDetailsPageTitle = {
            id: 'event_page_details_title',
            title:this.events.getTranslatedSubject(this.currentEvent),
            controls: titleButtons,
        };
        // Initialize view for Event Activity Details Section Header
        this.eventDetailsHeader = {
            id: 'event-details-header',
            title: this.translate.instant('DETAILS_CAP'),
            controls: [],
        };


        // Initialize view for Event Activity Notes Section Header
        if (this.isNotesEnabled) {
            let noteHeaderControls: Array<any> = [];
            if (!this.isReadOnlyMode) {
                noteHeaderControls = [
                    {
                        id: 'cancel_note',
                        text: this.translate.instant('CANCEL'),
                        isDisabled: this.currentNotesString === this.currentEvent.notes,
                    },
                    {
                        id: 'save_note',
                        text: this.translate.instant('SAVE'),
                        isDisabled: this.currentNotesString === this.currentEvent.notes,
                    },
                ];
            }
            this.eventNotesHeader = {
                id: 'event-notes-header',
                title: this.translate.instant('EVENT_DESCRIPTION'),
                controls: noteHeaderControls,
            };
        }

      const controls: any[] = [
        {
          id: 'attach_note',
          text: this.translate.instant('ATTACH'),
          isDisabled: this.isEventCompleted || this.isOffline
            || !this.isEventOwnerOrTeamMember
            || this.from === 'FollowUpDetail',
        },
        {
          id: 'save_event_note',
          text: this.translate.instant('SAVE'),
          isDisabled: this.isEventCompleted || this.isOffline || this.from === 'FollowUpDetail'
            || (isEmpty(this.tempNoteText) && !this.isAttachmentAdded),
        }
      ];

      this.notesHeader = {
        id: 'event-participant-section-header',
        title: this.translate.instant('NOTES'),
        controls,
      };
    }

    public toggleEditMode(flag: boolean,item): void {
        const idx = this.partnersFormattedArray.findIndex(a=> a.id === item.id);
        if(idx >= 0){
            this.partnersFormattedArray[idx].isEditing = flag;
        }
    }

    public deletePartner(item): void {
        const idx = this.partnersFormattedArray.findIndex(a=> a.id === item.id);
        if(idx >= 0 && !this.isReadOnlyMode){
            this.partnersFormattedArray.splice(idx,1);
            let str = '';
            const len = this.partnersFormattedArray.length;
            this.partnersFormattedArray.forEach((a,idx)=>{
                str += a.text+((idx === len - 1)?'':',');
            });
            this.currentEvent.partners = str;
            this.initializeViewRequired = false;
            this._updateCurrentEvent({
              indskr_partners: this.currentEvent.partners,
            });
        }
    }

    public confirmChanges(item): void {
        const idx = this.partnersFormattedArray.findIndex(a=> a.id === item.id);
        if(idx >= 0 && !this.isReadOnlyMode){
            this.partnersFormattedArray[idx].isEditing = false;
            this.partnersFormattedArray[idx].text = this.partnersFormattedArray[idx].editingInputText;
            let str = '';
            const len = this.partnersFormattedArray.length;
            this.partnersFormattedArray.forEach((a,idx)=>{
                str += a.text+((idx === len - 1)?'':',');
            });
            this.currentEvent.partners = str;
            this._updateCurrentEvent({
              indskr_partners: this.currentEvent.partners,
            });
        }
    }

    public onPageTitleControlClick(id: string): void {
      switch (id) {
        case 'close':
          this._closePage();
          break;
        case 'event_scrap':
          this._handleEventScrap();
          break;
        case 'event_mark_complete':
          this._handleMarkComplete();
          break;
        case 'event_send_for_approval':
          this._handleSubmitForApproval();
          break;
        default:
          console.warn('event-details: onPageTitleControlClick: unknown button id: ', id);
          break;
      }
    }

    public triggerSectionHeaderUpdate(ev:any = null): void {
      if(ev && ev.target){
        this.currentNotesString = ev.target.value;
      }
        this._initHeaderView();
    }

    public onSectionHeaderControlClick(id: string): void {
        switch(id){
            case 'event_mark_complete':
                this._handleMarkComplete();
                break;
            case 'event_scrap':
                this._handleEventScrap();
                break;
            case 'events_capture':
                this._handleSignatureCapture();
                break;
            case 'cancel_note':
                this.currentNotesString = this.currentEvent.notes;
                this._initHeaderView();
                break;
            case 'save_note':
                this.currentEvent.notes = this.currentNotesString;
                this.initializeViewRequired = false;
                this._updateCurrentEvent({
                  msevtmgt_description: this.currentEvent.notes,
                });
                break;
            case 'add_partner':
                this._handleAddPartners();
                break;
          case 'attach_note':
            this.openAttachOptionsPopover(id);
            break;
          case 'save_event_note':
            this.handleSaveNoteBtnClick();
            break;
            default:
                console.log('Unhandled switch case statement in SectionHeaderControlClick');
        }
    }

  private async handleSaveNoteBtnClick() {
    if (!isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      await this.saveNote();
    }
  }

  private async saveNote() {
    // const loader = await this.loadingCtrl.create();
    // await loader.present();
    this.isSaveNotesEnabled = false;

    const now = new Date().getTime().toString();
    const payload: any = {
      eventid: this.currentEvent.ID,
      notetext: this.tempNoteText,
      createdon: now,
      ownerName: this.authService.user.displayName,
      ownerid: this.authService.user.systemUserID,
    };

    if (this.base64str) {
      payload['isdocument'] = true;
      payload['documentbody'] = this.base64str;
      payload['filename'] = this.attachmentFile.name;
      // payload['filesize'] = this.attachmentFile.size;
      payload['mimetype'] = this.attachmentFile.type;
    }
    await this.fetchPastEventAttachment(payload);

    try {
      this.uiService.displayLoader();
      const response: any[] = await this.eventsToolDataService.createUpdateDeleteEventNote([payload]);
      if (Array.isArray(response) && response.length === 1) {
        const rawNote = response[0];
        if (rawNote.noteid) {
          payload.annotationid = rawNote.noteid;
          const newNote = new IONote(payload);
          if (!Array.isArray(this.currentEvent.annotations)) {
            this.currentCustomerNotes = this.currentEvent.annotations = [];
          }
          this.currentEvent.annotations.unshift(newNote);
          // Update local DB
          this.eventsToolDataService.updateEventsData(
            {
              onDynamics: false,
              onLocalDatabase: true,
              onLocalCopy: true,
            },
            [this.currentEvent],
          ).then((value: Array<EventActivity>)=>{
              this.currentEvent = value[0];
              this.uiService.dismissLoader();
              this._setViewData();
              // Reset form
              this.tempNoteText = '';
              this.removeAttachment(null);
              this.isSaveNotesEnabled = false;
          }).catch(err=>{
              this.uiService.dismissLoader();
              this._setViewData();
              console.log('Error updating event notes');
          });
        } else {
          console.error('handleSaveNoteBtnClick: noteid missing: ', response);
        }
      }
    } catch (error) {
      console.error('handleSaveNoteBtnClick: ', error);
      this.isSaveNotesEnabled = true;
      this.uiService.dismissLoader();
    } finally {
      this._initHeaderView();
    }
  }

  async fetchPastEventAttachment(payload) {
    let annotationId: string = '';
    if (this.eventsToolService.pastEventAttachments.length && this.isAttachmentAdded) {
      this.eventsToolService.pastEventAttachments.forEach(ev => {
        ev.attachments.forEach(at => {
          if (at.checked) {
            annotationId = at.annotationid;
          }
        })
      })
      if (annotationId) {
        await this.eventsToolDataService.getAnnotationById(annotationId).then(() => {
          payload['isdocument'] = true;
          payload['documentbody'] = this.eventsToolService.annotation['documentbody'];
          payload['filename'] = this.eventsToolService.annotation['filename'];
          payload['mimetype'] = this.eventsToolService.annotation['mimetype'];
        })
      }
    }
  }

  async openAttachOptionsPopover(myEvent) {
    const options = [
      {
        id: 'fromDevice',
        title: this.translate.instant('FROM_DEVICE'),
        value: 'fromDevice',
      },
      {
        id: 'pastRegistration',
        title: this.translate.instant('PREVIOUS_EVENTS'),
        value: 'pastRegistration',
      },
    ];
    const selectOption = async (data) => {
      this.popoverCtrl.dismiss();
      if (data?.id) {
        if (data.id == 'fromDevice') {
          this.eventsToolService.pastEventAttachments = [];
          this.eventsToolService.annotation = null;
          this.handleAttachmentBtnClick();
        } else {
          await this.handlePastEventAttachments();
        }
      }
    }
    const popOver = await this.popoverCtrl.create({
      component: PopoverComponent,
      componentProps: { selectListPopupData: options, field: 'select-list-popup', selectEvent: selectOption },
      cssClass: 'account-plan-select',
      event: event,
    });
    popOver.present();
  }

  private handleAttachmentBtnClick() {
    this.attachInput?.nativeElement?.click();
  }

  async handlePastEventAttachments() {
    if (!this.currentEvent.participants.length) return;
    let contactIds = this.currentEvent.participants.map(o => {
      return o.customerId
    });
    await this.eventsToolDataService.getPastEventAttachments(
      contactIds
    );
    if (!this.eventsToolService.pastEventAttachments.length) {
      this.notificationService.notify(
        this.translate.instant('NO_ATTACHMENTS_FOUND_FROM_PREVIOUS_EVENTS'),
        'Event Notes',
        'top',
        ToastStyle.INFO,
      );
    }
  }

    public get isGetSignatureDisabled(): boolean {
        return true;
    }

    private _handleMarkComplete(): void {
        if(!this.isReadOnlyMode && !this._isEventCompleted && this._checkIfEventCanBeCompleted()){
            this.currentEvent.statuscode = EventStatus.COMPLETED;
            this.currentEvent.statecode = EventStateCode.Inactive;
            this.currentEvent.formattedStatus = 'Completed';
            // Remove conflict ID from other activities
            removeConflictIdFromOtherActivities(
              this.currentEvent,
              this.activityService.activities,
              this.eventsToolService.eventsToolData,
            );
            this.displayConflictMsg = this.eventsToolService.hasTimeConflict(this.currentEvent);
            this._updateCurrentEvent({
              statecode: this.currentEvent.statecode,
              statuscode: this.currentEvent.statuscode,
            }).then(()=>{
                this.notificationService.notify(this.translate.instant('EVENT_COMPLETED_SUCCESSFULLY'),'Event Details','top',ToastStyle.INFO);
            });
        }
    }

    private async _handleSubmitForApproval() {
      if (
        !this.shouldBlockUserInput
        && !this.isReadOnlyMode
        && !this._isEventCompleted
        && this._checkIfEventCanBeCompleted()
      ) {
        this.shouldBlockUserInput = true;
        try {
          await this.uiService.displayLoader();
          await this.eventsToolDataService.sendForApproval(this.currentEvent);

          this.statusField.inputText = this.events.getFormattedStatus(this.currentEvent)+((this.currentEvent.pendingPushToDynamics)?` - ${this.translate.instant('PENDING_SYNC')}`:'');
          this._setViewData();

          await this.uiService.dismissLoader();
          this.notificationService.notify(this.translate.instant('SUBMITTED_FOR_APPROVAL'), 'Event Details', 'top', ToastStyle.INFO);
        } catch (error) {
          console.error('_handleSubmitForApproval: ', error);
          let errorMessage = this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE');
          if (
            error?.error?.errorCode
            && error.error.errorCode.includes('ERR_EV_APPROVE')
          ) {
            errorMessage = this.translate.instant(error.error.errorCode);
          }
          this.uiService.dismissLoader();
          this.notificationService.notify(errorMessage, 'Event Details', 'top', ToastStyle.DANGER);
        } finally {
          this.shouldBlockUserInput = false;
        }
      }
    }

    private _handleEventScrap(): void {
        if (!this.isReadOnlyMode && !this._isEventCompleted) {
            this.alertService.showAlert(
                {
                    title: this.translate.instant('SCRAP_EVENT'),
                    message: this.translate.instant('R_U_SURE_SCRAP_EVENT'),
                },
                this.translate.instant('SCRAP')
            ).then(res => {
                if (res.role === 'ok') {
                    this.uiService.displayLoader();
                    this.currentEvent.statecode = 1;
                    this.currentEvent.statuscode = EventStatus.CANCELED;
                    this.currentEvent.formattedStatus = 'Cancelled';
                    const action: OperationDetail = {
                        onDynamics: !this.device.isOffline,
                        onLocalDatabase: true,
                        onLocalCopy: true,
                        operationDetail: {
                            code: 'scrapeventactivity',
                            message: 'Scrap Event Activity',
                            payload: {
                              statecode: this.currentEvent.statecode,
                              statuscode: this.currentEvent.statuscode,
                            },
                        }
                    };
                    this.currentEvent.modifiedOn = new Date();
                    this.currentEvent.pendingPushToDynamics = true;
                    this.eventsToolDataService.updateEventsData(action, [this.currentEvent]).then(async (value: Array<EventActivity>) => {
                        await this.customerEventsService.deleteCustomerEventsForDeletedEvent(this.currentEvent);
                        this.marketingPlanManagementDataService.removeScrappedEvents(this.currentEvent);
                        this._closePage();
                        await this.uiService.dismissLoader();
                    }).catch(err => {
                        this._closePage();
                        this.uiService.dismissLoader();
                        console.log('Error updating event activity');
                    });
                }
            });
        }
    }

    private _checkIfEventCanBeCompleted(): boolean {
        const flag: boolean = true;

        // Check configured fields
        let isValid: boolean = true;
        for (const configField of this.configFields) {
          if (configField.isRequired) {
            this.requiredFields[configField.id] = {
              isEvaluated: true
            };

            isValid = isValid && ((!configField.inputText) ? false : true);
          }
        }

        // Approval specific required fields
        if (this.isApprovalEnabled) {
          if (
            this.currentEvent.budgetAllocated === null
            || isNaN(this.currentEvent.budgetAllocated)
            || this.currentEvent.budgetAllocated < 0
          ) {
            this.requiredFields[this.budgetAllocatedField.id] = { isEvaluated: true };
            this.notificationService.notify(this.translate.instant('PLEASE_ENTER_TOTAL_BUDGET'), 'Event Details', 'top', ToastStyle.DANGER);
            isValid = false;
          }
          if (!this.currentEvent.currencyId) {
            this.requiredFields[this.currencyField.id] = { isEvaluated: true };
            this.notificationService.notify(this.translate.instant('PLEASE_SELECT_CURRENCY'), 'Event Details', 'top', ToastStyle.DANGER);
            isValid = false;
          }
        }

        if (
          this.currentEvent.attendeeFormat === AttendeeFormat.propose
          && !this.currentEvent.cutOffDate
        ) {
          this.notificationService.notify(this.translate.instant('PLEASE_SELECT_CUT_OFF_DATE_IN_THIS_EVENT'),'Event Details','top',ToastStyle.DANGER);
          this.isClickedCutOffDate = true;
          this._setViewData();
          return false;
        }
        if (this.currentEvent.participants && this.currentEvent.participants.length !== 0 && this.currentEvent.participants.some(c =>  c.statecode !== 0)) {
            this.notificationService.notify(this.translate.instant('YOU_NO_LONGER_HAVE_ACCESS',{globalCustomerText:this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS')}),'Event Details','top',ToastStyle.DANGER);
            return false;
        }
        if (this.currentEvent.products.filter(a=> !a.isDeleted).length === 0 && this.currentEvent.participants.filter(a=> !a.isDeleted).length === 0) {
            this.notificationService.notify(this.translate.instant('PLEASE_SELECT_PARTICIPANT_AND_PRODUCT_IN_THIS_EVENT'),'Event Details','top',ToastStyle.DANGER);
            this.isClickedParticipants = true;
            this.isClickedProducts = true;
            this._setViewData();
            return false;
        }
        if (
          this.currentEvent.participants.filter(a=> !(
            a.isDeleted
            || a.indskr_reasons === EventRegistrationStatus.Cancelled
            || a.indskr_reasons === EventRegistrationStatus.Declined
            || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
          )).length === 0
        ) {
            this.notificationService.notify(this.translate.instant('PLEASE_SELECT_PARTICIPANT_IN_THIS_EVENT'),'Event Details','top',ToastStyle.DANGER);
            this.isClickedParticipants = true;
            this._setViewData();
            return false;
        }
        if (this.currentEvent.products.filter(a=> !a.isDeleted).length === 0) {
            this.notificationService.notify(this.translate.instant('PLEASE_SELECT_PRODUCT_IN_THIS_EVENT'),'Event Details','top',ToastStyle.DANGER);
            this.isClickedProducts = true;
            this._setViewData();
            return false;
        }
        if (!this.isApprovalEnabled && this.currentEvent.startDate.getTime() > new Date().getTime()) {
            this.notificationService.notify(this.translate.instant('ACTIVITY_EVENT_SCHEDULED_IN_FUTURE'),'Event Details','top',ToastStyle.DANGER);
            return false;
        }
        // Additional required fields based on FA
        if (this.isAdditionalMandatoryFieldsEnabled) {
          if (
            this.isEventGoalEnabled
            && !this.currentEvent.eventGoalFormatted
          ) {
            this.requiredFields[this.goalField.id] = { isEvaluated: true };
            this.notificationService.notify(
              this.translate.instant('PLEASE_SELECT_EVENT_GOAL'),
              'Event Details',
              'top',
              ToastStyle.DANGER
            );
            isValid = false;
          }
          if (!this.currentEvent.eventTypeFormatted) {
            this.requiredFields[this.typeField.id] = { isEvaluated: true };
            this.notificationService.notify(
              this.translate.instant('PLEASE_SELECT_EVENT_TYPE'),
              'Event Details',
              'top',
              ToastStyle.DANGER
            );
            isValid = false;
          }
          if (
            this.currentEvent.eventTypeFormatted
            && this.eventsToolService.eventTypeNeededForParticipantsMandatory.includes(this.currentEvent.eventTypeFormatted)
            && !this._currentSpeakersString
          ) {
            this.requiredFields[this.speakersField.id] = { isEvaluated: true };
            this.notificationService.notify(
              this.translate.instant('PLEASE_SELECT_SPEAKER'),
              'Event Details',
              'top',
              ToastStyle.DANGER
            );
            isValid = false;
          }
        }

        if (isValid === false) {
          // Toast

          this._setViewData();
        }

        return flag && isValid;
    }

    private _handleSignatureCapture(): void {
        this.navService.pushWithPageTracking(CaptureEventsComponent, PageName.CaptureEventsComponent,{currentEvent:this.currentEvent},PageName.CaptureEventsComponent, { animate: false });
    }

  private _handleEventsAttendies(): void {
    this.navService.pushChildNavPageWithPageTracking(EventAttendiesListComponent, PageName.EventAttendiesListComponent, PageName.EventDetailsPageComponent,
      {
        currentEvent: this.currentEvent
      });
  }

    private _handleAddPartners(): void {
        if(this.currentPartnersInputString && this.currentPartnersInputString.length !== 0){
            let le = 0;
            if(this.partnersFormattedArray.length !== 0){
                le = this.partnersFormattedArray[this.partnersFormattedArray.length-1].id;
            }
            this.currentPartnersInputString.split(',').forEach((item)=> {
                if(item && item.trim()){
                    le = le+1;
                    const temp = {
                        id:le,
                        text: item.trim(),
                        editingInputText: item.trim(),
                        isEditing: false,
                    };
                    this.partnersFormattedArray.push(temp);
                }
            });
            this.currentPartnersInputString = '';
            let str = '';
            const len = this.partnersFormattedArray.length;
            this.partnersFormattedArray.forEach((a,idx)=>{
                str += a.text+((idx === len - 1)?'':',');
            });
            this.currentEvent.partners = str;
            this.initializeViewRequired = false;
            this._updateCurrentEvent({
              indskr_partners: this.currentEvent.partners,
            });
        }
    }

    private async _handleBuildingFieldCallback(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
      if (
        eventTarget === 'RightHeaderButtonClick'
        && data?.isDone
        && !this._isEventCompleted
      ) {
        if (Array.isArray(data?.selectedItems)) {
          await this.uiService.displayLoader();

          try {
            if (data.selectedItems.length === 1) {
              // Select
              const selectedItem: MainCardViewDataModel = data.selectedItems[0];
              const selectedBuilding = this.addressService.getBuildingById(selectedItem.id);
              if (selectedBuilding) {
                this.buildingField.inputText = selectedBuilding.msevtmgt_name;
                this.buildingField.secondaryInfoText = selectedBuilding.cityName;
                this.currentEvent.building = {
                  ...selectedBuilding,
                };
              }
            } else if (
              data.selectedItems.length === 0
              && data.removedItems.length === 1
            ) {
              // DeSelect
              this.buildingField.inputText = '';
              this.buildingField.secondaryInfoText = '';
              delete this.currentEvent.building;
            } else {
              console.error('_handleBuildingFieldCallback: invalid flow');
              return;
            }

            await this.eventsToolDataService.updateEvent(
              this.currentEvent,
              {
                msevtmgt_buildingid: this.currentEvent.building?.msevtmgt_buildingid ?? null,
              },
            );
          } catch (error) {
            console.error('_handleBuildingFieldCallback: ', error);
          }

          await this.uiService.dismissLoader();
        }
      }
    }
    private _handleBuildingSearchHandler(text: string): string[] {
      let ids: string[] = [];
      const buildings = this.addressService.getBuildings();
      if (text.length >= 1) {
        ids = buildings
          .filter(building => {
            return building.msevtmgt_name.trim().toLowerCase().includes(text.trim().toLowerCase());
          })
          .map(building => building.msevtmgt_buildingid);
      } else {
        ids = buildings.map(building => building.msevtmgt_buildingid);
      }
      return ids;
    }
    private _handleBuildingFieldClick(event) {
      const buildings = this.addressService.getBuildings();
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('BUILDINGS'),
        dividerTitle: this.translate.instant('ALL_BUILDINGS'),
        isSearchEnabled: !this.isReadOnlyMode,
        searchTitle: this.translate.instant('SEARCH_BUILDINGS'),
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) =>
          this._handleBuildingFieldCallback(data, eventTarget, refData),
        searchHandler: (text: string) =>
          this._handleBuildingSearchHandler(text),
        data: buildings.map(building => {
          const isSelected = this.currentEvent?.building?.msevtmgt_buildingid === building.msevtmgt_buildingid;
          return {
            id: building.msevtmgt_buildingid,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: building.msevtmgt_name,
            secondaryTextRight: building.cityName ?? '',
            showArrow: false,
            arrowType: '',
          };
        }),
      };

      this._openMainTool(listDetail);
    }

    private async _handleBudgetAllocatedField(event) {
      const newValue: number = event?.target?.value !== '' && !isNaN(event?.target?.value) ? Number(event.target.value) : null;
      const oldValue: number | null | undefined = !isNaN(this.currentEvent.budgetAllocated) ? this.currentEvent.budgetAllocated : null;
      // Mandatory field check
      if (this.budgetAllocatedField.isRequired) {
        this.requiredFields[this.budgetAllocatedField.id] = { isEvaluated: true };
        this.budgetAllocatedField.isEmptyRequiredField = newValue === null ? true : false;
      }

      if (
        !(
          newValue !== null
          && !isNaN(this.currentEvent.budgetAllocated)
          && this.currentEvent.budgetAllocated === newValue
        )
        && !(
          newValue === null
          && isNaN(this.currentEvent.budgetAllocated)
        )
      ) {
        await this.uiService.displayLoader();
        if (newValue !== null) {
          this.budgetAllocatedField.inputText = '' + newValue;
          this.budgetAllocatedField.inputValue = newValue;
          this.currentEvent.budgetAllocated = newValue;
        } else {
          this.budgetAllocatedField.inputText = '';
          this.budgetAllocatedField.inputValue = null;
          this.currentEvent.budgetAllocated = null;
        }

        let errorMessage: string;
        try {
          const success = await this.eventsToolDataService.updateEvent(
            this.currentEvent,
            {
              msevtmgt_budgetallocated: this.currentEvent.budgetAllocated,
            },
          );
          if (!success) {
            errorMessage = this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE');
          }
        } catch (error) {
          console.error('_handleBudgetAllocatedField: ', error);
          errorMessage = this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE');
          if (
            error?.errorCode?.includes('ERR_EV_APPROVE')
          ) {
            errorMessage = error.errorMessage;
          }
        }

        await this.uiService.dismissLoader();

        // Revert value
        if (errorMessage) {
          this.budgetAllocatedField.inputText = oldValue !== null ? '' + oldValue : '';
          this.budgetAllocatedField.inputValue = oldValue;
          this.currentEvent.budgetAllocated = oldValue;
          errorMessage && this.notificationService.notify(errorMessage, 'Event Details', 'top', ToastStyle.DANGER);
        }
      }
    }

    private async _handleCurrencyFieldCallback(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
      if (
        eventTarget === 'RightHeaderButtonClick'
        && data?.isDone
        && !this._isEventCompleted
      ) {
        if (Array.isArray(data?.selectedItems)) {
          this.requiredFields[this.currencyField.id] = { isEvaluated: true };
          if (data.selectedItems.length === 1) {
            await this.uiService.displayLoader();
            try {
              const selectedCurrency: MainCardViewDataModel = data.selectedItems[0];

              this.currencyField.inputText = `${selectedCurrency.primaryTextRight} (${selectedCurrency.secondaryTextRight})`;
              this.currencyField.isEmptyRequiredField = this.requiredFields['currency']?.isEvaluated === true && !this.currencyField.inputText,
              this.currentEvent.currencyId = selectedCurrency.id;
              this.currentEvent.currencyName = selectedCurrency.primaryTextRight;

              await this.eventsToolDataService.updateEvent(
                this.currentEvent,
                {
                  transactioncurrencyid: this.currentEvent.currencyId,
                },
              );
            } catch (error) {
              console.error('_handleCurrencyFieldCallback: ', error);
            }
            await this.uiService.dismissLoader();
          } else {
            console.error('_handleCurrencyFieldCallback: Not supposed to be here as we prevent deselect..');
          }
        }
      }
    }
    private _handleCurrencySearchHandler(text: string): string[] {
      let ids: string[] = [];
      const currencies = this.marketingMgmService.currencies;
      if (text.length >= 1) {
        ids = currencies
          .filter(currency => {
            return currency.name.trim().toLowerCase().includes(text.trim().toLowerCase());
          })
          .map(currency => currency.id);
      } else {
        ids = currencies.map(currency => currency.id);
      }
      return ids;
    }
    private _handleCurrencyFieldClick(event) {
      this.requiredFields['currency'] = { isEvaluated: true };
      const currencies = this.marketingMgmService.currencies;
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('CURRENCIES'),
        dividerTitle: this.translate.instant('ALL_CURRENCIES'),
        isSearchEnabled: !this.isReadOnlyMode,
        searchTitle: this.translate.instant('SEARCH_CURRENCIES'),
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) =>
          this._handleCurrencyFieldCallback(data, eventTarget, refData),
        searchHandler: (text: string) =>
          this._handleCurrencySearchHandler(text),
        data: currencies.map(currency => {
          const isSelected = this.currentEvent?.currencyId === currency.id;
          return {
            id: currency.id,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: currency.name,
            secondaryTextRight: currency.code,
            showArrow: false,
            arrowType: '',
          };
        }),
        preventDeSelect: true,
      };

      this._openMainTool(listDetail);
    }

    private _openMainTool(viewData: MainToolTemplateDetail) {
      setTimeout(() => {
        this.navService.pushWithPageTracking(
          MainToolTemplateComponent,
          PageName.NothingSelectedView,
          {
            viewData,
          },
          PageName.MainToolTemplateComponent
        );
      }, 10);
    }

    private _handleFormFieldEvent(id,event,eventName): void {
        if(id){
            switch(id){
                case 'subject-field':
                    if (eventName && eventName === 'input_value_confirm') {
                        this._handleEditSubject(event);
                    }
                    break;
                case 'start-date-field':
                    this._openStartDatePicker(event);
                    break;
                case 'end-date-field':
                    this._openEndDatePicker(event);
                    break;
                case 'location-field':
                    this._handleLocationField(event);
                    break;
                case 'building':
                    this._handleBuildingFieldClick(event);
                    break;
                case 'event-goal-field':
                    this._handleEventGoalField(event);
                    break;
                case 'event-type-field':
                    this._handleEventTypeField(event);
                    break;
                case 'event-sub-type-field':
                    this._handleEventSubTypeField(event);
                    break;
                case 'budgetAllocated':
                    if (eventName && eventName === 'input_value_confirm') {
                      this._handleBudgetAllocatedField(event);
                    }
                    break;
                case 'currency':
                    this._handleCurrencyFieldClick(event);
                    break;
                case 'accounts-field':
                    this._handleAccountsField(event);
                    break;
                case 'participants-field':
                    this._handleParticipantsField(event);
                    this.isClickedParticipants = true;
                    break;
                case 'products-field':
                    this._handleProductsField(event);
                    break;
                case 'covisitors-field':
                    this._handleCovisitorsField(event);
                    break;
                case 'speakers-field':
                    this._handleSpeakersField(event);
                    break;
                case 'eventCapacity-field':
                if (eventName && eventName === 'input_value_confirm') {
                  this._handleEventCapacityInputValidations(event)
                }
                    break;
                case 'attendeeFormat':
                  this._handleAttendeeFormatField(event);
                  break;
                case 'cutOffDate':
                  this._handleCutOffDateField(event);
                  break;
                case 'selectionCriteria':
                  if (eventName === 'input_value_confirm') {
                    this._handleSelectionCriteriaField(event);
                  }
                  break;
                default:
                    console.log('Unhandled switch case statement');
                    break;
            }
        }
    }
  private getAttendeeFormatText(value: AttendeeFormat): string {
    let text = '';

    if (value === AttendeeFormat.open) {
      text = this.translate.instant('OPEN');
    } else if (value === AttendeeFormat.propose) {
      text = this.translate.instant('PROPOSE');
    }

    return text;
  }
  private async _handleAttendeeFormatField(event) {
    if (!this.isReadOnlyMode && this.currentEvent) {
      const options = [
        {
          id: 'open',
          title: this.getAttendeeFormatText(AttendeeFormat.open),
          isSelected: this.currentEvent.attendeeFormat === AttendeeFormat.open,
          value: AttendeeFormat.open,
        },
        {
          id: 'propose',
          title: this.getAttendeeFormatText(AttendeeFormat.propose),
          isSelected: this.currentEvent.attendeeFormat === AttendeeFormat.propose,
          value: AttendeeFormat.propose,
        },
      ];

      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'segment-select',
        data: options,
        isMultipleSelectionEnabled: false
      };
      const dropdownPopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent,
        componentProps:{ viewData: dropdownListDetail },
        cssClass: 'dropdown-list-view',
        event});
      dropdownPopover.onDidDismiss().then(async (popoverData: any) => {
          const data = popoverData.data;
          let isSelected = Array.isArray(data?.selectedItems) && data.selectedItems[0]?.value !== this.currentEvent.attendeeFormat;
          let payloadValue = isSelected ? data.selectedItems[0].value : null;
          const success = await this._patchEventField(
            'attendeeFormat',
            'indskr_attendeeformat',
             payloadValue,
          );
          if (success) {
            this.currentEvent.attendeeFormat = payloadValue;
            this.attendeeFormatField.inputText = this.getAttendeeFormatText(this.currentEvent.attendeeFormat);
            const isAttendeeFormatPropose = this.currentEvent.attendeeFormat === AttendeeFormat.propose;
            const isPastEventStartDate: boolean = new Date().getTime() >= this.currentEvent.startDate.getTime();
            this.cutOffDateField.isDisabled = this.isReadOnlyMode || !this.isOwner || isPastEventStartDate;
            this.cutOffDateField.showArrow = this.isOwner && !this.isReadOnlyMode && !isPastEventStartDate;
            this.cutOffDateField.isRequired = !this.isReadOnlyMode && isAttendeeFormatPropose;
            this._updateFooterButtons();
          }
      });
      await dropdownPopover.present();
    }
  }
  private _handleCutOffDateField(event) {
    if (this.currentEvent.cutOffDate) {
      this.genericDatePickerValue = this.currentEvent.cutOffDate;
    } else {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      this.genericDatePickerValue = now.toISOString();
    }
    this.genericDatePickerMaxValue = this.currentEvent.startDate ? format(this.currentEvent.startDate) : undefined;
    this.genericDatePickerDisplayFormat = this.dateTimeFormatsService.dateToUpper;
    this.genericDatePickerPickerFormat = this.dateTimeFormatsService.dateToUpper;
    setTimeout(async () => {
      await this.genericDatePicker?.open();
      this.isConfigDatePickerOpen = true;
    }, 10);
  }
  private async _handleSelectionCriteriaField(event) {
    try {
      if (event?.target?.value !== undefined) {
        const newValue = event.target.value ?? '';
        if (
          this.currentEvent.selectionCriteria !== newValue
          && !(
            this.currentEvent.selectionCriteria === undefined
            && newValue === ''
          )
        ) {
          const success = await this._patchEventField(
            'selectionCriteria',
            'indskr_selectioncriteria',
            newValue,
          );
          this.currentEvent.selectionCriteria = newValue;
          this.selectionCriteriaField.inputText = newValue;
        }
      }
    } catch (error) {
      console.error('_handleSelectionCriteriaField: ', error);
    }
  }
  private _handleEventCapacityInputValidations(event) {
    try {
      if ((event.target.value == null || event.target.value < 0 || (event.target.value && event.target.value.includes('.')))) {
        this.eventCapacityField.inputText = this.eventCapacityField.inputValue = this.currentEvent.msevtmgt_maximumeventcapacity + "";
      }
      if (event.target.value != this.currentEvent.msevtmgt_maximumeventcapacity || (event.target.value == '' && this.currentEvent.msevtmgt_maximumeventcapacity != null)) {
        const validParticipants = this.currentEvent.participants.filter(contact =>
          contact.indskr_reasons !== EventRegistrationStatus.Declined && contact.indskr_reasons !== EventRegistrationStatus['Cancelled late']
          && contact.indskr_reasons !== EventRegistrationStatus.Cancelled);

        if (event.target.value < validParticipants.length) {
          this.toast.notify(`${this.translate.instant("PLEASE_ADJUST_THE_NUMBER_OF_PARTICIPANTS")}`, "");
          this.eventCapacityField.inputText = this.eventCapacityField.inputValue = (this.currentEvent.msevtmgt_maximumeventcapacity || '') + '';
          return;
        }
        this.currentEvent.msevtmgt_maximumeventcapacity = !_.isEmpty(event.target.value) ? parseInt(event.target.value) : null;
        this._updateCurrentEvent({
          msevtmgt_maximumeventcapacity: this.currentEvent.msevtmgt_maximumeventcapacity,
        });
      }

    } catch (error) { }
  }

  private _updateConfigFieldInputValues(fieldType: string, fieldName: string) {
    const fieldId = getConfigFormFieldViewDataModelId(fieldType, fieldName);
    const configFormFieldData = this.configFields.find(f => f.id === fieldId);

    if (configFormFieldData) {
      const { inputText, inputValue } = this.getConfigFieldInputTextAndValue(fieldType, fieldName);
      configFormFieldData.inputText = inputText;
      configFormFieldData.inputValue = inputValue;

      // Mandatory field check
      if (configFormFieldData.isRequired) {
        this.requiredFields[configFormFieldData.id] = { isEvaluated: true };
        configFormFieldData.isEmptyRequiredField = !inputText ? true : false;
      }
      let payload = {
        [fieldName]: this.currentEvent.configuredFields[fieldName]
      };
      if(fieldType == 'Lookup'){
        payload = {
          "lookupfields": this.currentEvent.lookupConfiguredFields.filter(a=> a.name == fieldName).map(b=> {
            return {
              "name": b.name,
              "entity": b.entity,
              "id": b.id,
              "indskr_referencingentitynavigationpropertyname": b.indskr_referencingentitynavigationpropertyname,
            };
          })
        }
      }
      this._patchEventConfigFields(payload);
    }
  }

  private _configFieldMandatoryCheck(fieldType: string, fieldName: string) {
    const fieldId = getConfigFormFieldViewDataModelId(fieldType, fieldName);
    const configFormFieldData = this.configFields.find(f => f.id === fieldId);

    if (configFormFieldData && configFormFieldData.isRequired) {
      this.requiredFields[configFormFieldData.id] = { isEvaluated: true };
      configFormFieldData.isEmptyRequiredField = !configFormFieldData.inputText ? true : false;
    }
  }

  async updateGenericDatePickerField(isDone: boolean, value: string) {
    if (this.isConfigDatePickerOpen) {
      if (isDone && value) {
        try {
          const date = new Date(value);
          if (isDate(date)) {
            const newCutOffDateStr: string = format(date, 'YYYY-MM-DD');
            if (this.currentEvent.cutOffDate !== newCutOffDateStr) {
              const newCutOffDate = new Date(newCutOffDateStr);
              newCutOffDate.setHours(0, 0, 0, 0);
              const now = new Date();
              now.setSeconds(0, 0);

              if (
                newCutOffDate.getTime() > this.currentEvent.startDate.getTime()
              ) {
                this.notificationService.notify(
                  this.translate.instant('CUT_OFF_DATE_RANGE_ERROR_FUTURE'),
                  'Event Details',
                  'top',
                  ToastStyle.DANGER
                );
              } else if (
                !isSameDay(
                  newCutOffDate,
                  now,
                )
                && newCutOffDate.getTime() < now.getTime()
              ) {
                this.notificationService.notify(
                  this.translate.instant('CUT_OFF_DATE_RANGE_ERROR_PAST'),
                  'Event Details',
                  'top',
                  ToastStyle.DANGER
                );
              } else {
                const success = await this._patchEventField(
                  'cutOffDate',
                  'indskr_cutoffdate',
                  newCutOffDateStr,
                );
                if (success) {
                  this.currentEvent.cutOffDate = newCutOffDateStr;
                  const newFormattedText = this.datePipe.transform(this.currentEvent.cutOffDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
                  this.cutOffDateField.inputText = newFormattedText;
                }
              }
            }
          }
        } catch (error) {
          console.error('updateGenericDatePickerField: ', error);
        }
      }
      this.isConfigDatePickerOpen = false;
    }
  }

  updateDateConfigField(isDone: boolean, value: string) {
    if (this.isConfigDatePickerOpen) {
      if (isDone) {
        if (this.currentEvent.configuredFields) {
          try {
            const date = new Date(value);
            if (moment.isDate(date)) {
              // 'value' is coming in as an UTC string with timezone independent date time value
              // Converting it to the format that matches with dynamics
              const utcDateString: string = moment(date).utc().format();
              if (this.isConfigFieldValueChanging(this.currentDateConfigFieldName, utcDateString)) {
                this.originalFieldValues[this.currentDateConfigFieldName] = utcDateString;
                this.currentEvent.configuredFields[this.currentDateConfigFieldName] = utcDateString;
                this._updateConfigFieldInputValues('DateTime', this.currentDateConfigFieldName);
              }
            }
          } catch (error) {
            console.error('updateDateConfigField: ', value, error);
          }
        }
      } else {
        // Mandatory field check
        this._configFieldMandatoryCheck('DateTime', this.currentDateConfigFieldName);
      }
      this.currentDateConfigFieldName = undefined;
      this.isConfigDatePickerOpen = false;
    }
  }

  private async _openConfigFieldOptionSetPopover(event, fieldType: string, fieldName: string) {
    const configuredFieldOptionValues = fieldType === 'Boolean'
    ? this.booleanConfigFieldOptionValues
    : this.eventsToolService.configFieldOptionsValues[fieldName];

    if (Array.isArray(configuredFieldOptionValues)) {
      let currentSelectedValue = this.currentEvent.configuredFields[fieldName];
      let currentSelectedMultiOptionValues: number[] = [];
      let data: SelectListData[];

      try {
        switch (fieldType) {
          case 'Boolean':
          case 'Picklist':
            data = configuredFieldOptionValues.map(option => (
              {
                id: option.label,
                title: option.label,
                isSelected: currentSelectedValue === option.value,
                value: option.value,
              }
            ));
            break;

          case 'Virtual':{
            if (currentSelectedValue) {
              currentSelectedMultiOptionValues = currentSelectedValue.split(',').map(str => Number(str));
            }

            data = configuredFieldOptionValues.map(option => (
              {
                id: option.label,
                title: option.label,
                isSelected: currentSelectedMultiOptionValues.some(v => v === option.value),
                value: option.value,
              }
            ));
            break;
          }

          default:
            console.warn(
              'event-details: _openConfigFieldOptionSetPopover: unsupported fieldType: ',
              fieldType, fieldName, event
            );
            break;
        }
      } catch (error) {
        console.error('_openConfigFieldOptionSetPopover: ', error, event, fieldType, fieldName);
      }

      if (Array.isArray(data)) {
        const isMultiSelect = fieldType === 'Virtual';
        const viewData: IndDropdownListDetailModel = {
          id: 'configuredPicklist',
          data,
          isMultipleSelectionEnabled: isMultiSelect,
          showCancelandDoneBtn: isMultiSelect,
        };

        const popover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData },
          cssClass: 'dropdown-list-view',
          event: event,
        });
        await popover.present();
        const response = await popover.onDidDismiss();

        let shouldCheckForMandatoryField: boolean = true;
        if (response?.data?.isDone === true) {
          if (isMultiSelect) {
            if (Array.isArray(response.data.selectedItems)) {
              const newValues: number[] = response.data.selectedItems.map(i => i.value);
              const diff = difference(currentSelectedMultiOptionValues, newValues)
                .concat(difference(newValues, currentSelectedMultiOptionValues));
              if (Array.isArray(diff) && diff.length > 0) {
                const newValueStr: string = newValues.join();
                this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
                this.currentEvent.configuredFields[fieldName] = newValueStr;
                this._updateConfigFieldInputValues(fieldType, fieldName);
                shouldCheckForMandatoryField = false;
              }
            }
          } else {
            if (
              Array.isArray(response.data.selectedItems)
              && response.data.selectedItems.length > 0
              && currentSelectedValue !== response.data.selectedItems[0].value
            ) {
              this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
              this.currentEvent.configuredFields[fieldName] = response.data.selectedItems[0].value;
              this._updateConfigFieldInputValues(fieldType, fieldName);
              shouldCheckForMandatoryField = false;
            }
          }
        }

        if (shouldCheckForMandatoryField) {
          // Mandatory field check
          this._configFieldMandatoryCheck(fieldType, fieldName);
        }
      }
    }
  }

  private _handleConfigFieldEvent(id, event, eventName, fieldType, fieldName) {
    if (id) {
      const currentValue = this.currentEvent.configuredFields[fieldName] ?? null;

      try {
        switch (fieldType) {
          case 'String':
          case 'Memo':
            if (eventName === 'input_value_confirm') {
              const newValue = event?.target?.value?.length > 0 ? event.target.value : null;
              if (currentValue !== newValue) {
                this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
                this.currentEvent.configuredFields[fieldName] = newValue;
                this._updateConfigFieldInputValues(fieldType, fieldName);
              } else {
                this._configFieldMandatoryCheck(fieldType, fieldName);
              }
            }
            break;

          case 'Integer':
          case 'BigInt':
          case 'Decimal':
          case 'Money':
          case 'Currency':
            if (eventName === 'input_value_confirm') {
              let newValue: number = null;
              if (
                event?.target?.value !== null
                && event?.target?.value !== undefined
                && event?.target?.value !== ''
              ) {
                try {
                  newValue = Number(event.target.value);
                } catch (error) {
                  console.error('_handleConfigFieldEvent: not a number: ', error, event, fieldType, fieldName);
                }
              }
              if (currentValue !== newValue) {
                this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
                this.currentEvent.configuredFields[fieldName] = newValue;
                this._updateConfigFieldInputValues(fieldType, fieldName);
              } else {
                this._configFieldMandatoryCheck(fieldType, fieldName);
              }
            }
            break;

          case 'Boolean':
          case 'Picklist':
          case 'Virtual':
            this._openConfigFieldOptionSetPopover(event, fieldType, fieldName);
            break;

          case 'DateTime':
            if (eventName === 'DetectChange') {
              if (currentValue !== null) {
                this.configFieldDateValue = currentValue;
              } else {
                const now = new Date();
                now.setSeconds(0,0);
                // Get UTC string with current local date time values
                this.configFieldDateValue = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString()
              }
              this.currentDateConfigFieldName = fieldName;

              // Doing this because it seems the default value (configFieldDateValue)
              // does not get reflected in the same tick
              setTimeout(async () => {
                await this.configFieldDatePicker?.open();
                this.isConfigDatePickerOpen = true;
              }, 10);
            }
            break;
          case 'Lookup':
            this.openLookup(event,fieldName);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('_handleConfigFieldEvent: ', error, event, fieldType, fieldName);
      }
    }
  }

  private async _closePage(): Promise<void> {
    switch (this.from) {
      case 'AccountTimeline':
        this.navService.popChildNavPageWithPageTracking().then(() => this.footerService.initButtons(FooterViews.Accounts));
        return;
      case 'ContactTimeline':
        this.navService.popChildNavPageWithPageTracking().then(() => this.footerService.initButtons(FooterViews.Contacts));
        return;
      case 'FollowUpDetail':
        this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
        this.activityService.prevSelectedActivity = undefined;
        await this.navService.popChildNavPageWithPageTracking();
        this.events.publish(EventName.BACK_TO_FOLLOW_UP_DETAIL_FROM_PUSHED_PAGE);
        return;
      default:
        break;
    }
    this.eventsToolService.selectedEventOnEventsTool = null;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NothingSelectedView).then(() => {
      this.navService.setChildNavRightPaneView(false);
      this.uiService.updateNothingSelectedScreenMessageFor(1);
    });
  }

    private get _isSplitPaneView(): boolean {
        return window.innerWidth > 500;
    }

    private get _isEventCompleted(): boolean {
        return (this.currentEvent.statecode === EventStateCode.Inactive && this.currentEvent.statuscode === EventStatus.COMPLETED);
    }

    private get _currentAccountsString(): string {
        let str = '';
        const currentAccounts = this.currentEvent.accounts.filter(a=> !a.isDeleted);
        if (currentAccounts) {
            if (currentAccounts.length === 1) {
                str = `${currentAccounts[0].name}`;
                if(currentAccounts[0].statecode !== 0){
                    str += ' (Inactive)';
                }
            } else if (currentAccounts.length >= 2) {
                currentAccounts.sort((a, b): number => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                });
                let frstStr = '';
                let scndStr = '';
                if(currentAccounts[0].statecode !== 0){
                    frstStr = ' (Inactive)';
                }else if(currentAccounts.some(a=> a.statecode !== 0)){
                    scndStr = ' (Inactive)';
                }
                str = `${currentAccounts[0].name}${frstStr} +${currentAccounts.length - 1}${scndStr}`;
            }
        }
        return str;
    }

    private get _currentParticipantsString(): string {
        let str = '';
      const currentParticipants = this.currentEvent.participants
        .filter(a => !(
          a.isDeleted
          || a.indskr_reasons === EventRegistrationStatus.Cancelled
          || a.indskr_reasons === EventRegistrationStatus.Declined
          || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
            || a.indskr_reasons === EventRegistrationStatus['No show']));
        if (currentParticipants) {
            if (currentParticipants.length === 1) {
                str = `${currentParticipants[0].name}`;
                if(currentParticipants[0].statecode !== 0){
                    str += ' (Inactive)';
                }
            } else if (currentParticipants.length >= 2) {
                currentParticipants.sort((a, b): number => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                });
                let frstStr = '';
                let scndStr = '';
                if(currentParticipants[0].statecode !== 0){
                    frstStr = ' (Inactive)';
                }else if(currentParticipants.some(a=> a.statecode !== 0)){
                    scndStr = ' (Inactive)';
                }
                str = `${currentParticipants[0].name}${frstStr} +${currentParticipants.length - 1}${scndStr}`;
            }
        }
        return str;
    }
    private get _currentProductsString(): string {
        let str = '';
        let currentProducts = this.currentEvent.products.filter(a=> !a.isDeleted);
        if(!this.isReadOnlyMode){
            currentProducts = currentProducts.filter(a=> a.statecode === 0);
        }
        if (currentProducts) {
            if (currentProducts.length === 1) {
                str = `${currentProducts[0].name}`;
            } else if (currentProducts.length >= 2) {
                currentProducts.sort((a, b): number => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                });
                str = `${currentProducts[0].name} +${currentProducts.length - 1}`;
            }
        }
        return str;
    }

    private get _currentCovisitorsString(): string {
        let str = '';
        const currentCovisitors = this.currentEvent.covisitors.filter(a => !a.isDeleted);
        if (currentCovisitors) {
            if (currentCovisitors.length === 1) {
                str = `${currentCovisitors[0].name}`;
            } else if (currentCovisitors.length >= 2) {
                currentCovisitors.sort((a, b): number => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                });
                str = `${currentCovisitors[0].name} +${currentCovisitors.length - 1}`;
            }
        }
        return str;
    }

    private get _currentSpeakersString(): string {
        let str = '';
        let currentSpeakers = this.currentEvent.speakers.filter(a=> !a.isDeleted);
        if(!this.isReadOnlyMode){
            currentSpeakers = currentSpeakers.filter(a=> a.statecode === 0);
        }
        if (currentSpeakers) {
            if (currentSpeakers.length === 1) {
                str = `${currentSpeakers[0].name}`;
            } else if (currentSpeakers.length >= 2) {
                currentSpeakers.sort((a, b): number => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                });
                str = `${currentSpeakers[0].name} +${currentSpeakers.length - 1}`;
            }
        }
        return str;
    }

    private async _patchEventConfigFields(dto: {}) {
      // Redirect to _updateCurrentEvent fn if it has pendingPush flag on
      // It's easier and safer this way than creating another complex logic
      // to pretty much do the same thing just a little bit more efficiently.
      if (this.currentEvent.pendingPushToDynamics) {
        this._updateCurrentEvent(dto);
      } else if (dto) {
        try {
          const action: OperationDetail = {
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: false,
          };

          await this.uiService.displayLoader();
          const response = await this.eventsToolDataService.patchEventAttributes(action, this.currentEvent, dto, true);
        } catch (error) {
          console.error('_patchEventConfigFields: ', error);
          let errorMsg: string = this.translate.instant('SOMETHING_REALLY_BAD_HAPPENED');;

          if (error?.error?.errorCode === 'ERR_IO_400') {
            if (
              error?.error?.errorMessage
              && !error?.error?.errorMessage.includes('Microsoft.Crm.CrmException')
            ) {
              // Show generic error notification since error message is probably too long and not end user friendly.
              // Display the error message from dynamics.
              errorMsg = error.error.errorMessage;
            }
          }

          // Revert values
          for (const fieldName in dto) {
            if (
              Object.prototype.hasOwnProperty.call(dto, fieldName)
              && Object.prototype.hasOwnProperty.call(this.currentEvent?.configuredFields, fieldName)
            ) {
              const oldValue = dto[fieldName];
              this.currentEvent.configuredFields[fieldName] = oldValue;
            }
          }

          this.notificationService.notify(errorMsg, 'Event details', 'top', ToastStyle.DANGER);
        } finally {
          await this.uiService.dismissLoader();
        }
      }
    }
    private async _patchEventField(
      classFieldName: string,
      dtoFieldName: string,
      newValue: any,
    ): Promise<boolean> {
      let success = false;
      const dto: Record<string, any> = {
        [dtoFieldName]: newValue,
      };
      if (this.currentEvent.pendingPushToDynamics) {
        this.currentEvent[classFieldName] = newValue;
        await this._updateCurrentEvent(dto);
        success = true;
      } else {
        try {
          const action: OperationDetail = {
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: false,
          };

          await this.uiService.displayLoader();
          const response = await this.eventsToolDataService.patchEventAttributes(action, this.currentEvent, dto, true);
          this.currentEvent[classFieldName] = newValue;
          success = true;
        } catch (error) {
          console.error('_patchEventField: ', error);
          let errorMsg: string = this.translate.instant('SOMETHING_REALLY_BAD_HAPPENED');;

          if (error?.error?.errorCode === 'ERR_IO_400') {
            if (
              error?.error?.errorMessage
              && !error?.error?.errorMessage.includes('Microsoft.Crm.CrmException')
            ) {
              // Show generic error notification since error message is probably too long and not end user friendly.
              // Display the error message from dynamics.
              errorMsg = error.error.errorMessage;
            }
          }
          this.notificationService.notify(errorMsg, 'Event details', 'top', ToastStyle.DANGER);
        } finally {
          await this.uiService.dismissLoader();
        }
      }
      return success;
    }

    private _updateCurrentEvent(payload: {}, omitLoader = false): Promise<any>{
        return new Promise(async (resolve,reject)=> {
            const action: OperationDetail = {
                onDynamics: !this.device.isOffline,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: '',
                  message: '',
                  payload,
                }
            };
            this.currentEvent.modifiedOn = new Date();
            this.currentEvent.pendingPushToDynamics = true;
            !omitLoader ? this.uiService.displayLoader() : undefined;
            this.eventsToolDataService.updateEventsData(action,[this.currentEvent]).then((value: Array<EventActivity>)=>{
                this.currentEvent = value[0];
                !omitLoader ? this.uiService.dismissLoader() : undefined;
                this._setViewData();

                resolve(value);
            }).catch(err=>{
                !omitLoader ? this.uiService.dismissLoader() : undefined;
                this._setViewData();
                resolve(err);
                console.log('Error updating event activity');
            });
        });
    }

    private _handleEditSubject(event): void {
        if(!this.isReadOnlyMode){
            event = event.target
            const name = event?.value ? event.value : 'Event';
            if (name !== this.currentEvent.name) {
                this.currentEvent.name = name;
                this._updateCurrentEvent({
                  msevtmgt_name: name,
                });
            }
        }
    }
    
    private _handleLocationField(event): void {
        if(!this.isReadOnlyMode){
            const contactIds = this.currentEvent.participants.map(p=> p.id);
            const accountIds = this.currentEvent.accounts.map(a=> a.id);
            this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.EventsToolPageComponent, { from: 'EventsToolPage', for: 'GENERAL_LOCATION_SELECTION',selectedAddress:{id: this.currentEvent.locationId, name: this.currentEvent.locationFormatted},contactIds, accountIds, callbackEvent: (data: any)=> this._handleLocationComponentCallback(data) });
        }
    }

    private _handleLocationComponentCallback(data): void{
        if(data && data !== this.currentEvent.locationFormatted){
            this.currentEvent.locationFormatted = data;
            this._updateCurrentEvent({
              indskr_location: data,
            });
            this._CD.detectChanges();
        }
    }

    private async _handleEventGoalField(event) {
      if (!this.isReadOnlyMode) {
        const eventGoals: EventGoal[] = _.orderBy(_.cloneDeep(this.eventsToolService.eventGoals), 'indskr_name', 'asc');
        const options = [];
        eventGoals.forEach((g: EventGoal) => {
          let isSelected = false;
          if (this.currentEvent?.name) {
            isSelected = this.currentEvent.eventGoal === g.indskr_eventgoalid;
          }
          options.push({ id: g.indskr_eventgoalid, title: g.indskr_name, isSelected, state: g.statecode });
        });

        const dropdownListDetail: IndDropdownListDetailModel = {
          id: 'segment-select',
          data: options,
          isMultipleSelectionEnabled: false
        };
        const dropdownPopover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData: dropdownListDetail },
          cssClass: 'dropdown-list-view',
          event,
        });
        await dropdownPopover.present();

        const popoverData = await dropdownPopover.onDidDismiss();
        if (
          Array.isArray(popoverData?.data?.selectedItems)
          && popoverData?.data?.selectedItems[0]
        ) {
          const selectedData = popoverData.data.selectedItems[0];
          if (selectedData.id && this.currentEvent.eventGoal !== selectedData.id) {
            await this.uiService.displayLoader();

            const oldEventGoal = this.currentEvent.eventGoal;
            const oldEventGoalFormatted = this.currentEvent.eventGoalFormatted;
            const oldEventGoalStateCode = this.currentEvent.eventGoalStateCode;
            const oldEventType = this.currentEvent.eventType;
            const oldEventTypeFormatted = this.currentEvent.eventTypeFormatted;
            const oldEventTypeStateCode = this.currentEvent.eventTypeStateCode;
            const oldEventSubType = this.currentEvent.eventSubType;
            const oldEventSubTypeFormatted = this.currentEvent.eventSubTypeFormatted;
            const oldEventSubTypeStateCode = this.currentEvent.eventSubTypeStateCode;
            const oldAvailableEventSubTypes = this.availableEventSubTypes;

            this.currentEvent.eventGoalFormatted = selectedData.title;
            this.currentEvent.eventGoal = selectedData.id;
            this.currentEvent.eventGoalStateCode = selectedData.state;
            this.currentEvent.eventType = '';
            this.currentEvent.eventTypeFormatted = '';
            this.currentEvent.eventTypeStateCode = undefined;
            this.currentEvent.eventSubType = '';
            this.currentEvent.eventSubTypeFormatted = '';
            this.currentEvent.eventSubTypeStateCode = undefined;
            this.availableEventSubTypes = [];

            this._updateGoalFieldData();
            this._updateTypeFieldData();
            this._updateSubTypeFieldData();
            this._updateSpeakersFieldData();

            let errorMessage: string;
            try {
              const success = await this.eventsToolDataService.updateEvent(
                this.currentEvent,
                {
                  indskr_eventgoal: selectedData.id,
                  indskr_event_type: '',
                  indskr_eventsubtype: '',
                }
              );
              if (!success) {
                errorMessage = this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE');
              }
            } catch (error) {
              console.error('_handleEventGoalField: ', error);
              errorMessage = this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE');
            }

            // Revert
            if (errorMessage) {
              this.currentEvent.eventGoal = oldEventGoal;
              this.currentEvent.eventGoalFormatted = oldEventGoalFormatted;
              this.currentEvent.eventGoalStateCode = oldEventGoalStateCode;
              this.currentEvent.eventType = oldEventType;
              this.currentEvent.eventTypeFormatted = oldEventTypeFormatted;
              this.currentEvent.eventTypeStateCode = oldEventTypeStateCode;
              this.currentEvent.eventSubType = oldEventSubType;
              this.currentEvent.eventSubTypeFormatted = oldEventSubTypeFormatted;
              this.currentEvent.eventSubTypeStateCode = oldEventSubTypeStateCode;
              this.availableEventSubTypes = oldAvailableEventSubTypes;

              this._updateGoalFieldData();
              this._updateTypeFieldData();
              this._updateSubTypeFieldData();
              this._updateSpeakersFieldData();
            }

            await this.uiService.dismissLoader();
          }
        }
      }
    }

    private async _handleEventTypeField(event){
        if (
          !this.isReadOnlyMode
          && (
            !this.isEventGoalEnabled
            || (
              this.isEventGoalEnabled
              && this.currentEvent.eventGoal
            )
          )
        ){
            let eventTypes: EventType[] = _.orderBy(
              _.cloneDeep(this.eventsToolService.eventTypes), 'indskr_name', 'asc'
            );
            if (this.isEventGoalEnabled) {
              const evGoal = this.eventsToolService.eventGoals.find(eg => eg.indskr_eventgoalid === this.currentEvent.eventGoal);
              eventTypes = eventTypes?.filter(et =>
                evGoal.indskr_indskr_eventgoal_indskr_event_type.some(t => t.indskr_event_typeid === et.id)
              );
            }
            const options = [];
            eventTypes.forEach((e: EventType) => {
                let isSelected = false;
                if (this.currentEvent && this.currentEvent.name) {
                    isSelected = this.currentEvent.eventType === e.id;
                }
                options.push({ id: e.id, title: e.name, isSelected, state: e.state });
            });
            const dropdownListDetail: IndDropdownListDetailModel = {
                id: 'segment-select',
                data: options,
                isMultipleSelectionEnabled: false
            };
            const dropdownPopover = await this.popoverCtrl.create({
              component: IndDropdownListComponent,
              componentProps: { viewData: dropdownListDetail },
              cssClass: 'dropdown-list-view',
              event });
            dropdownPopover.onDidDismiss().then((popoverData: any) => {
              const data = popoverData.data;
              if(data!=null){
                    if(this.currentEvent.eventType !== data.selectedItems[0].id){
                        this.currentEvent.eventTypeFormatted = data.selectedItems[0].title;
                        this.currentEvent.eventType = data.selectedItems[0].id;
                        this.currentEvent.eventTypeStateCode = data.selectedItems[0].state;
                        this.currentEvent.eventSubTypeFormatted = '';
                        this.currentEvent.eventSubType = '';
                        this.currentEvent.eventSubTypeStateCode = undefined;
                        this.availableEventSubTypes = this._getAvailableEventSubtypes(this.currentEvent.eventType);
                        this._updateCurrentEvent({
                          indskr_event_type: this.currentEvent.eventType,
                          indskr_eventsubtype: this.currentEvent.eventSubType,
                        });
                    }
                }
            });
            dropdownPopover.present();
        }
    }

    private _getAvailableEventSubtypes(eventType: string) {
        const eventTypes: EventType[] = this.eventsToolService.eventTypes;
        const availbeEventTypes: EventSubType[] = [];
        if(this.currentEvent && this.currentEvent.eventType){
            const selectedEventType = eventTypes.find(e=>e.id === this.currentEvent.eventType);
            if(selectedEventType){
                selectedEventType.subTypes.forEach((es: EventSubType) => {
                    availbeEventTypes.push({ id: es.id, name: es.name, state: es.state });
                });
            }
        }
        return availbeEventTypes;
    }

    private async _handleEventSubTypeField(event) {
        if(!this.isReadOnlyMode){
            const eventTypes: EventType[] = _.orderBy(_.cloneDeep(this.eventsToolService.eventTypes), 'indskr_name', 'asc');
            const options = [];
            if(this.currentEvent && this.currentEvent.eventType){
                const selectedEventType = eventTypes.find(e=>e.id === this.currentEvent.eventType);
                let isSelected = false;
                selectedEventType.subTypes.forEach((es: EventSubType) => {
                    if (this.currentEvent.eventSubType) {
                        isSelected = this.currentEvent.eventSubType === es.id;
                    }
                    options.push({ id: es.id, title: es.name, isSelected, state: es.state });
                });
                const dropdownListDetail: IndDropdownListDetailModel = {
                    id: 'segment-select',
                    data: options,
                    isMultipleSelectionEnabled: false
                };
                const dropdownPopover = await this.popoverCtrl.create({
                  component: IndDropdownListComponent,
                  componentProps:{ viewData: dropdownListDetail },
                  cssClass: 'dropdown-list-view',
                  event});
                dropdownPopover.onDidDismiss().then((popoverData: any) => {
                    const data = popoverData.data;
                    if(data!=null && this.currentEvent.eventSubType !== data.selectedItems[0].id){
                        this.currentEvent.eventSubTypeFormatted = data.selectedItems[0].title;
                        this.currentEvent.eventSubType = data.selectedItems[0].id;
                        this.currentEvent.eventSubTypeStateCode = data.selectedItems[0].state;
                        this._updateCurrentEvent({
                          indskr_eventsubtype: this.currentEvent.eventSubType,
                        });
                    }
                });
                dropdownPopover.present();
            }
        }
    }

    private _handleAccountsField(data): void {
        if(!this.isReadOnlyMode || this.currentEvent.accounts.length !== 0){
            const accountIds = [];
            const contactIds = [];
            if(this.currentEvent.accounts){
                this.currentEvent.accounts.forEach(acc=> {
                    if(acc.id && !acc.isDeleted){
                        accountIds.push({
                            id: acc.id,
                            name: acc.name,
                            statecode: acc.statecode,
                        });
                    }
                });
            }
            if(this.currentEvent.participants){
                this.currentEvent.participants.forEach(p=> {
                    if (
                      p.id
                      && !(
                        p.isDeleted
                        || p.indskr_reasons === EventRegistrationStatus.Cancelled
                        || p.indskr_reasons === EventRegistrationStatus.Declined
                        || p.indskr_reasons === EventRegistrationStatus['Cancelled late']
                      )
                    ){
                        contactIds.push(p.id);
                    }
                });
            }
            let mode = ComponentViewMode.READONLY;
            this.accountService.selectedFor = AccountSelectedFor.GENERAL_MULTIPLE_SELECTION;
            if(!this.isReadOnlyMode){
                mode = ComponentViewMode.ADDNEW;
            }
            this.accountService.accessedAccountListFrom = PageName.EventDetailsPageComponent;
            this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent,
                                                { listMode: mode, type: 'PushedAccountSelectionView',
                                                selectedAccountIds: accountIds, selectedContactIds:contactIds,
                                                callbackEvent: (data: any)=> this._handleAccountComponentCallback(data) },
                                                PageName.EventDetailsPageComponent, { animate: false }, null);
        }
    }

    private async _handleAccountComponentCallback(data) {
        if(!this._isEventCompleted && data && (data.selectedItems || data.clearValue)){
            if(data.clearValue){
                data.selectedItems = [];
            }
            let updateContactsForEventFlag = false;
            if(!this.isCovisitorView){
                // To Add/Remove affiliated contacts for event
                let contactsToUpdate: Contact[] = [];
                const alreadySelectedAccounts: Account[] = [];
                this.currentEvent.accounts.forEach(ac=>{
                    const foundAcc = this.accountService.getAccountById(ac.id);
                    if(foundAcc){
                        alreadySelectedAccounts.push(foundAcc);
                    }
                });
                //Check for Remove
                if(this.currentEvent.participants
                    .filter(a=> !(
                      a.isDeleted
                      || a.indskr_reasons === EventRegistrationStatus.Cancelled
                      || a.indskr_reasons === EventRegistrationStatus.Declined
                      || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
                    )).length === 1 && this.currentEvent.accounts.filter(a=> !a.isDeleted).length === 1){
                    const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(alreadySelectedAccounts);
                    if (
                      affiliatedContacts.length === 1
                      && affiliatedContacts[0].ID === this.currentEvent.participants
                                                        .filter(a=> !(
                                                          a.isDeleted
                                                          || a.indskr_reasons === EventRegistrationStatus.Cancelled
                                                          || a.indskr_reasons === EventRegistrationStatus.Declined
                                                          || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
                                                        ))[0].id
                    ) {
                        // Remove the only added affiliated contact
                        updateContactsForEventFlag = true;
                        contactsToUpdate = [];
                    }
                }
                //Check for add
                if (
                  this.currentEvent.accounts.filter(a=> !a.isDeleted).length === 0
                  && data.selectedItems
                  && data.selectedItems.length === 1
                  && this.currentEvent.participants.filter(a=> !(
                    a.isDeleted
                    || a.indskr_reasons === EventRegistrationStatus.Cancelled
                    || a.indskr_reasons === EventRegistrationStatus.Declined
                    || a.indskr_reasons === EventRegistrationStatus['Cancelled late']
                  )).length === 0
                ) {
                    const nowSelectedAccounts: Account[] = [];
                    data.selectedItems.forEach(ac=>{
                        const foundAcc = this.accountService.getAccountById(ac.id);
                        if(foundAcc){
                            nowSelectedAccounts.push(foundAcc);
                        }
                    });
                    const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(nowSelectedAccounts);
                    if(affiliatedContacts.length === 1){
                        // Add the only affiliated contact
                        updateContactsForEventFlag = true;
                        contactsToUpdate = affiliatedContacts;
                    }
                }
                if(updateContactsForEventFlag){
                  const newParticipantList = [];
                  const addedParticipantIds = [];
                  const removedParticipantIds = [];
                  for (let i = 0; i < this.currentEvent.participants.length; i++) {
                    const participant = this.currentEvent.participants[i];
                    if (contactsToUpdate.length > 0) {
                      const selectedContact = contactsToUpdate.find(c => c.id === participant.id);
                      if (selectedContact) {
                        participant.isDeleted = false;
                        if (
                          participant.indskr_reasons === EventRegistrationStatus.Cancelled
                          || participant.indskr_reasons === EventRegistrationStatus.Declined
                        ) {
                          participant.isAddedBack = true;
                          addedParticipantIds.push(participant.id);
                        }
                        participant.indskr_reasons = EventRegistrationStatus.Proposed;
                        participant.indskr_reasons_formatted = 'Proposed';
                      } else {
                        if (
                          participant.indskr_reasons !== EventRegistrationStatus.Cancelled
                          && participant.indskr_reasons !== EventRegistrationStatus.Declined
                          && participant.indskr_reasons !== EventRegistrationStatus['Cancelled late']
                        ) {
                          participant.isDeleted = true;
                          participant.shouldClearResponse = participant.indskr_reasons !== EventRegistrationStatus.Proposed;
                          participant.indskr_reasons = EventRegistrationStatus.Cancelled;
                          participant.indskr_reasons_formatted = "Canceled";
                          removedParticipantIds.push(participant.id);
                        }
                      }

                      newParticipantList.push(participant);
                    } else {
                      if (
                        participant.indskr_reasons !== EventRegistrationStatus.Cancelled
                        && participant.indskr_reasons !== EventRegistrationStatus.Declined
                        && participant.indskr_reasons !== EventRegistrationStatus['Cancelled late']
                      ) {
                          participant.isDeleted = true;
                          participant.shouldClearResponse = participant.indskr_reasons !== EventRegistrationStatus.Proposed;
                          participant.indskr_reasons = EventRegistrationStatus.Cancelled;
                          participant.indskr_reasons_formatted = "Canceled";
                          removedParticipantIds.push(participant.id);
                      }
                      newParticipantList.push(participant);
                    }
                  }
                  for (let i = 0; i < contactsToUpdate.length; i++) {
                    const newParticipant = contactsToUpdate[i];
                    if (this.currentEvent.participants.length > 0) {
                      const participant = this.currentEvent.participants.find(c => c.id === newParticipant.id);
                      if (!participant) {
                        newParticipantList.push({
                          id: newParticipant.ID,
                          name: newParticipant.fullname,
                          statecode: newParticipant.isActive ? 0 : 1,
                          signature: '',
                          checkinId: '',
                          ownerId: newParticipant.ownerId,
                          isDeleted: false,
                          isAddedBack: false,
                          registrationId: null,
                          customerId: newParticipant.appId,
                          title: newParticipant.title,
                          firstName: newParticipant.firstName,
                          middleName: newParticipant.middleName,
                          lastName: newParticipant.lastName,
                          primaryAccount: newParticipant.getPrimaryAccountRelationship,
                          primarySpecialty: newParticipant.primarySpecialtyName,
                          msevtmgt_checkintime: null,
                          indskr_reasons: EventRegistrationStatus.Proposed,
                          indskr_reasons_formatted: 'Proposed',
                          primaryEmail:newParticipant.primaryEmail
                        });
                        addedParticipantIds.push(newParticipant.id);
                      }
                    } else {
                      newParticipantList.push({
                        id: newParticipant.ID,
                        name: newParticipant.fullname,
                        statecode: newParticipant.isActive ? 0 : 1,
                        signature: '',
                        checkinId: '',
                        ownerId: newParticipant.ownerId,
                        isDeleted: false,
                        isAddedBack: false,
                        registrationId: null,
                        customerId: newParticipant.appId,
                        title: newParticipant.title,
                        firstName: newParticipant.firstName,
                        middleName: newParticipant.middleName,
                        lastName: newParticipant.lastName,
                        primaryAccount: newParticipant.getPrimaryAccountRelationship,
                        primarySpecialty: newParticipant.primarySpecialtyName,
                        msevtmgt_checkintime: null,
                        indskr_reasons: EventRegistrationStatus.Proposed,
                        indskr_reasons_formatted :'Proposed',
                        primaryEmail:newParticipant.primaryEmail
                      });
                      addedParticipantIds.push(newParticipant.id);
                    }
                  }
                  this.currentEvent.participants = newParticipantList;
                }
            }
            this.currentEvent.accounts.forEach(a=>{
                a.isDeleted = true;
                return a;
            });
            data.selectedItems.forEach(item=> {
                const idx = this.currentEvent.accounts.findIndex(a=> a.id === item.id);
                if(idx >= 0){
                    this.currentEvent.accounts[idx].isDeleted = false;
                    this.currentEvent.accounts[idx].name = item.name;
                }else{
                    this.currentEvent.accounts.push({
                        id: item.id,
                        name: item.name,
                        statecode: item.statecode,
                        isDeleted: false,
                    });
                }
            });

            let payload;
            const accountsDTO = this.eventsToolDataService.getAccountsDTO(this.currentEvent);
            if (accountsDTO) {
              payload = { ...accountsDTO };
            }
            if (updateContactsForEventFlag) {
              const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
              if (contactsDTO) {
                payload = { ...payload, ...contactsDTO };
              }
            }
            this._updateCurrentEvent(payload);
        }
    }

    private _handleParticipantsField(data): void {
      if (this.currentEvent.msevtmgt_maximumeventcapacity === 0) {
        this.toast.notify(`${this.translate.instant("WE_CAN_NOT_ADD_CUSTOMERS")}`, "");
        return;
      }
        if(!this.isReadOnlyMode || this.currentEvent.participants.length !== 0 || (this.isCovisitorView && !this._isEventCompleted)){
            const customerIds = [];
            const accountIds = [];
            if(this.currentEvent.participants){
                this.currentEvent.participants.forEach(con=> {
                    if (con.id
                        && !(
                          con.isDeleted
                          || con.indskr_reasons === EventRegistrationStatus.Cancelled
                          || con.indskr_reasons === EventRegistrationStatus.Declined
                          || con.indskr_reasons === EventRegistrationStatus['Cancelled late'])) {
                        customerIds.push({
                            id: con.id,
                            ownerId: con.ownerId,
                            customerId: con.customerId,
                            signatureCaptured: con.signature,
                            title: con.title,
                            name: con.name,
                            firstName: con.firstName,
                            middleName: con.middleName,
                            lastName: con.lastName,
                            primaryAccount: con.primaryAccount,
                            primarySpecialty: con.primarySpecialty,
                            statecode: con.statecode,
                            reason:con.indskr_reasons
                        });
                    }
                });
            }
            if(this.currentEvent.accounts){
                this.currentEvent.accounts.forEach(a=> {
                    if(a.id && !a.isDeleted){
                        accountIds.push({
                            id: a.id,
                            name: a.name,
                            statecode: a.statecode,
                        });
                    }
                });
            }
            this.uiService.prevView = this.uiService.activeView;
            this.uiService.activeView = '';
            this.uiService.showNewActivity = false;
            this.contactService.eventsId = this.currentEvent.ID;
            const isCutOffDateInEffect = this.getIsCutOffDateInEffect();
            const currentEventDateTime = this.getFormattedDateTimeText();
            if((!this.isReadOnlyMode || this.isCovisitorView) && !this._isEventCompleted && !isCutOffDateInEffect && !isPast(currentEventDateTime.endDateTime)){
                this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
                this.eventsToolService.clearPendingParticipantDetails();
            }else {
                this.contactService.contactPageMode = ComponentViewMode.READONLY;
            }
            //to retain old count in case user cancels to save passess
            if (this.currentEvent.passes) this.currentEvent.passes.forEach(pass => pass['temp_msevtmgt_noOfPassesLeft'] = _.cloneDeep(pass.msevtmgt_noOfPassesLeft));
            this.contactService.accessedContactListFrom = PageName.EventDetailsPageComponent;
            this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
                                                { type: 'PushedContactSelectionView',selectedContactIds: customerIds,
                                                selectedAccountIds:accountIds,isCovisitorView:this.isCovisitorView,
                                                maximumeventcapacity:this.currentEvent.msevtmgt_maximumeventcapacity,
                                                callbackEvent: (data: any)=> this._handleContactComponentCallback(data) }, PageName.DynamicFormComponent, { animate: false }, null);
        }
    }

    private async _handleContactComponentCallback(data, isFrom?) {
        if(!this._isEventCompleted && data && (data.selectedItems || data.clearValue)){
            await this.uiService.displayLoader();
            if(data.clearValue){
                data.selectedItems = [];
            }
          let values = await this.eventsToolDataService._handleContactComponentCallback(data, this.isCovisitorView, this.currentEvent, false)
          let eventPayload = values[0];
          let addedParticipantIds = values[1];
          let removedParticipantIds = isFrom == PageName.EventParticipantComponent ? [] : values[2];

            await this._updateCurrentEvent(eventPayload, true).then(() => {
              this.notificationService.notify(
                this.translate.instant('SAVED_SUCCESSFULLY'),
                'Event Details',
                'top',
                ToastStyle.INFO
              );
              const savedEventParticipant = this.eventsToolService.getEventParticipantByContactId(
                this.currentEvent.participants,
                this.contactService.contactInformation.ID,
              );
              this.events.publish('updateEventParticipantHeader', savedEventParticipant);
            });
            if (!this.device.isOffline) {
              // Update Event Pass
              const participantEventPassesToSave = data.selectedItems.filter(item => !_.isEmpty(item['eventPasses']));
              if (!_.isEmpty(participantEventPassesToSave)) {
                const payload: any[] = [];
                participantEventPassesToSave.forEach(participantEventPass => {
                  const participant = this.currentEvent.participants.find(participant => participant.id == participantEventPass.id);
                  if (participant && participant.registrationId) {
                    payload.push(...participantEventPass['eventPasses'].map(pass => {
                      return {
                        eventRegId: participant.registrationId,
                        passId: pass['passId'],
                        msevtmgt_attendeepassid: pass['msevtmgt_attendeepassid'],
                        msevtmgt_passName: pass['msevtmgt_passName'],
                        deleted: pass['deleted']
                      }
                    }))
                  }
                });
                if (!_.isEmpty(payload)) {
                  await this.eventsToolDataService.saveEventAttendeePasses(payload, this.currentEvent);
                }
              }
              // Update Justification - Temporary / before changing registration flow
              data.selectedItems.forEach(item => {
                let details = this.eventsToolService.getPendingParticipantDetails(item.id);
                if(details && details.indskr_justification) {
                  let selectedParticipant = this.currentEvent.participants.find(participant => participant.id == item.id);
                  if(selectedParticipant && selectedParticipant.registrationId) {
                    selectedParticipant.indskr_justification = details.indskr_justification;
                    this.eventsToolDataService.saveJustification(selectedParticipant.registrationId, details.indskr_justification, this.currentEvent);
                  }
                }
              })
            } else this.currentEvent['eventPassesToSave'] = [];

            if (this.eventsToolService.hasPendingParticipantDetails()) {
              await this._savePendingNotes(data.selectedItems);

              this.eventsToolService.clearPendingParticipantDetails();
            }

            if (addedParticipantIds.length > 0) {
              await this.customerEventsService.addCustomerEventsForAddedEventParticipants(this.currentEvent, addedParticipantIds);
            }
            if (removedParticipantIds.length > 0) {
              await this.customerEventsService.removeCustomerEventsForRemovedEventParticipants(this.currentEvent, removedParticipantIds);
            }
            await this.uiService.dismissLoader();
            this.eventsToolService.isEventParticipationDirty = false;
        }
    }
    private async _savePendingNotes(selectedItems) {
      if (Array.isArray(selectedItems)) {
        let isUpdated = false;
        let failFlag = false;
        for (const item of selectedItems) {
          const pendingDetails = this.eventsToolService.getPendingParticipantDetails(item.id);
          if (pendingDetails) {
            const participant = this.currentEvent.participants.find(p => p.id === item.id);
            if (
              participant
              && participant.registrationId
            ) {
              const notePayloads = [];
              let pendingNotes = pendingDetails.notes;
              for (const pendingNote of pendingNotes) {
                const payload: any = {
                  eventregistrationid: participant.registrationId,
                  notetext: pendingNote.noteText,
                  createdon: pendingNote.createdTime.getTime().toString(),
                  ownerName: this.authService.user.displayName,
                  ownerid: this.authService.user.systemUserID,
                };
                if (pendingNote.documentDataURL) {
                  payload['isdocument'] = true;
                  payload['documentbody'] = pendingNote.documentDataURL;
                  payload['filename'] = pendingNote.documentName;
                  payload['mimetype'] = pendingNote.documentMimeType;
                }
                notePayloads.unshift(payload);
              }

              if (notePayloads.length > 0) {
                try {
                  const responses: {
                    createdon: number,
                    noteid: string,
                  }[] = await this.eventsToolDataService.createUpdateDeleteEventNote(notePayloads);
                  if (Array.isArray(responses)) {
                    for (const response of responses) {
                      const pendingNote = pendingNotes.find(n => n.createdTime.getTime() === response.createdon);
                      if (pendingNote) {
                        pendingNote.noteId = response.noteid;
                      }
                    }

                    participant.notes = pendingNotes.filter(n => !n.noteId.includes('pending_note_'));
                    if (participant.notes.length !== pendingNotes.length) {
                      failFlag = true;
                    }
                    isUpdated = true;
                  }
                } catch (error) {
                  console.error('_savePendingNotes: Error saving notes', error);
                  failFlag = true;
                }
              }
            }
          }
        }
        this.uiService.displayLoader();
        if (isUpdated) {
          // Update local DB
          this.eventsToolDataService.updateEventsData(
            {
              onDynamics: false,
              onLocalDatabase: true,
              onLocalCopy: true,
            },
            [this.currentEvent],
          ).then((value: Array<EventActivity>)=>{
              this.currentEvent = value[0];
              this.uiService.dismissLoader();
              this._setViewData();
              // Reset form
              this.tempNoteText = '';
              this.removeAttachment(null);
              this.isSaveNotesEnabled = false;
          }).catch(err=>{
              this.uiService.dismissLoader();
              this._setViewData();
              console.log('Error updating event notes');
          });
        }
        if (failFlag) {
          this.notificationService.notify(
            this.translate.instant('EVENT_NOTE_UPLOAD_FAIL'),
            'Event details',
            'top',
            ToastStyle.DANGER
          );
        }
      }
    }

    private _handleProductsField(event): void {
        if(((!this.isReadOnlyMode && this.brandService.brands.length !== 0) || this.currentEvent.products.filter(a=> !a.isDeleted).length !== 0)){
            const listDetail: MainToolTemplateDetail = {
              title: this.translate.instant('PRODUCTS'),
              dividerTitle:this.translate.instant('ALL_PRODUCTS_CAP'),
              isSearchEnabled:this.teamMemberCanUpdate,
              showLeftHeaderButton: true,
              leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
              leftHeaderBtnText: this.translate.instant('CANCEL'),
              showRightHeaderButton: this.teamMemberCanUpdate,
              rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
              rightHeaderBtnText: this.translate.instant('DONE'),
              orderByPropertyName: 'primaryTextRight',
              searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
              hideAllItemsList: !this.teamMemberCanUpdate,
              isListSelectionEnabled:this.teamMemberCanUpdate,
              listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
              navOptions: { animate: false },
              updateOptionForTeamMember : this.teamMemberCanUpdate,
              eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData),
              searchHandler:(text: string) => this._handleProductsComponentSearch(text),
              data: this.brandService.brands && this.brandService.brands.map(pro => {
                let isSelected = false;
                if(this.currentEvent && this.currentEvent.products && this.currentEvent.products.some(evpro => (evpro.id === pro.ID && !evpro.isDeleted && evpro.statecode === 0))){
                  isSelected = true;
                }
                return {
                  id: pro.ID,
                  primaryTextLeft: '',
                  secondaryTextLeft: '',
                  showEndIcon: this.teamMemberCanUpdate,
                  mainItemCssClass: 'selector-item',
                  isItemSelectedForSelectionView: isSelected,
                  endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
                  endIconCssClass: isSelected?'checkmark-icon':'add-icon',
                  primaryTextRight: pro.name,
                  showArrow: false,
                  arrowType: '',
                  eventOwnerId: this.currentEvent.ownerId
                };
              }),
            };
            if(this.teamMemberCanUpdate){
                this.currentEvent.products.forEach(pro=>{
                    const idx = listDetail.data.findIndex(it=> it.id === pro.id);
                    if(idx<0){
                        const viewData: MainCardViewDataModel = {
                            id: pro.id,
                            primaryTextLeft: '',
                            secondaryTextLeft: '',
                            showEndIcon: this.teamMemberCanUpdate,
                            mainItemCssClass: 'selector-item',
                            isItemSelectedForSelectionView: true,
                            endIconType: 'indegene-selectors-checkmark-icon',
                            endIconCssClass: 'checkmark-icon',
                            primaryTextRight: pro.name,
                            showArrow: false,
                            arrowType: '',
                            eventOwnerId: this.currentEvent.ownerId,
                            unMappedProduct: true
                          };
                        listDetail.data.push(viewData);
                    }
                });
            }
            if(this.isReadOnlyMode){
                this.currentEvent.products.forEach(pro=>{
                    const idx = listDetail.data.findIndex(it=> it.id === pro.id);
                    if(idx<0){
                        const viewData: MainCardViewDataModel = {
                            id: pro.id,
                            primaryTextLeft: '',
                            secondaryTextLeft: '',
                            showEndIcon: !this.isReadOnlyMode,
                            mainItemCssClass: 'selector-item',
                            isItemSelectedForSelectionView: true,
                            endIconType: 'indegene-selectors-checkmark-icon',
                            endIconCssClass: 'checkmark-icon',
                            primaryTextRight: pro.name,
                            showArrow: false,
                            arrowType: '',
                          };
                        listDetail.data.push(viewData);
                    }
                });
            }
            this._openMainTool(listDetail);
            this.isClickedProducts = true;
        }
    }

    private _handleProductsComponentSearch(text: string): string[] {
        let ids: Array<string> = [];
        if (text.length >= 1) {
            ids = this.brandService.brands && this.brandService.brands.filter(pro => {
                return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(pro => pro.ID);
        } else {
            ids = this.brandService.brands && this.brandService.brands.map(pro => pro.ID);
        }
        return ids;
    }

    private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
        if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
            if (!this._isEventCompleted && data && data.isDone) {
                if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems, length >= 0) {
                    this.currentEvent.products = this.currentEvent.products.map(p=>{
                        if(p.statecode === 0){
                            p.isDeleted = true;
                        }
                        return p;
                    });
                    data.selectedItems.forEach(item => {
                        const foundProduct = this.brandService.brands.find(brand => brand.ID === item.id);
                        if (foundProduct) {
                            const idx = this.currentEvent.products.findIndex(p=> p.id === foundProduct.ID);
                            if(idx >= 0){
                                this.currentEvent.products[idx].name = foundProduct.name;
                                this.currentEvent.products[idx].isDeleted = false;
                            }else{
                                this.currentEvent.products.push({
                                    id: foundProduct.ID,
                                    name: foundProduct.name,
                                    statecode: 0,
                                    isDeleted:false,
                                });
                            }
                        } else {
                            this.currentEvent.products.push({
                                id: item.id,
                                name: item.primaryTextRight,
                                statecode: 0,
                                isDeleted:false,
                            });
                        }
                    });
                }

                let payload;
                const productsDTO = this.eventsToolDataService.getProductsDTO(this.currentEvent);
                if (productsDTO) {
                  payload = { ...productsDTO };
                }
                this._updateCurrentEvent(payload);
            }
        }
    }

    private _handleCovisitorsField(event): void {
        if(((!this.isReadOnlyMode && this.activityService.repUserList.length !== 0) || this.currentEvent.covisitors.filter(a=> !a.isDeleted).length !== 0)){
            const listDetail: MainToolTemplateDetail = {
                title: this.translate.instant('EVENT_TEAM_MEMBERS'),
                dividerTitle: this.translate.instant('ALL_EVENT_TEAM_MEMBERS_CAP'),
                isSearchEnabled: !this.isReadOnlyMode,
                showLeftHeaderButton: true,
                leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
                leftHeaderBtnText: this.translate.instant('CANCEL'),
                showRightHeaderButton: !this.isReadOnlyMode,
                rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
                rightHeaderBtnText: this.translate.instant('DONE'),
                orderByPropertyName: 'primaryTextRight',
                searchTitle: this.translate.instant('SEARCH_EVENT_TEAM_MEMBERS'),
                hideAllItemsList: this.isReadOnlyMode,
                isListSelectionEnabled: !this.isReadOnlyMode,
                listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
                navOptions: { animate: false },
                eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleCovisitorsComponentEvent(data, eventTarget, refData),
                searchHandler: (text: string) => this._handleCovistorsComponentSearch(text),
                data: this.activityService.repUserList && this.activityService.repUserList.map(user => {
                    let isSelected = false;
                    if (this.currentEvent && this.currentEvent.covisitors && this.currentEvent.covisitors.some(cov => (cov.id === user.id && !cov.isDeleted))) {
                        isSelected = true;
                    }
                    return{
                        id: user.id,
                        primaryTextLeft: '',
                        secondaryTextLeft: '',
                        showEndIcon: !this.isReadOnlyMode,
                        mainItemCssClass: 'selector-item',
                        isItemSelectedForSelectionView: isSelected,
                        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
                        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
                        primaryTextRight: user.name,
                        showArrow: false,
                        arrowType: '',
                    };
                }),
            };
            if (this.isReadOnlyMode) {
                this.currentEvent.covisitors.forEach(cov => {
                    const idx = listDetail.data.findIndex(it => it.id === cov.id);
                    if (idx < 0) {
                        const viewData: MainCardViewDataModel = {
                            id: cov.id,
                            primaryTextLeft: '',
                            secondaryTextLeft: '',
                            showEndIcon: !this.isReadOnlyMode,
                            mainItemCssClass: 'selector-item',
                            isItemSelectedForSelectionView: true,
                            endIconType: 'indegene-selectors-checkmark-icon',
                            endIconCssClass: 'checkmark-icon',
                            primaryTextRight: cov.name,
                            showArrow: false,
                            arrowType: '',
                        };
                        listDetail.data.push(viewData);
                    }
                });
            }
            this._openMainTool(listDetail);
        }
    }

    private _handleCovisitorsComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail){
        if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
            if (!this._isEventCompleted && data && data.isDone) {
                if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems, length >= 0) {
                    this.currentEvent.covisitors = this.currentEvent.covisitors.map(s=>{
                        s.isDeleted = true;
                        return s;
                    });
                    data.selectedItems.forEach(item => {
                        const foundUser = this.activityService.repUserList.find(user => user.id === item.id);
                        if (foundUser) {
                            const idx = this.currentEvent.covisitors.findIndex(a=> a.id === foundUser.id);
                            if(idx>=0){
                                this.currentEvent.covisitors[idx].isDeleted = false;
                                this.currentEvent.covisitors[idx].name = foundUser.name;
                            }else{
                                this.currentEvent.covisitors.push({
                                    id: foundUser.id,
                                    name: foundUser.name,
                                    teamMemberId: null,
                                    isDeleted: false,
                                    teamMemberStatecode: 0,
                                });
                            }
                        }
                    });
                }

                let payload;
                const covisitorsDTO = this.eventsToolDataService.getCovisitorsDTO(this.currentEvent);
                if (covisitorsDTO) {
                  payload = { ...covisitorsDTO };
                }
                this._updateCurrentEvent(payload);
            }
        }
    }

    private _handleCovistorsComponentSearch(text: string): string[]{
        let ids: Array<string> = [];
        if (text.length >= 1) {
            ids = this.activityService.repUserList && this.activityService.repUserList.filter(user => {
                return user.name.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(user => user.id);
        } else {
            ids = this.activityService.repUserList && this.activityService.repUserList.map(user => user.id);
        }
        return ids;
    }

    private _handleSpeakersField(event): void {
        if(((!this.isReadOnlyMode && this.eventsToolService.eventSpeakers.length !== 0) || this.currentEvent.speakers.filter(a=> !a.isDeleted).length !== 0)){
            const listDetail: MainToolTemplateDetail = {
              title: this.translate.instant('SPEAKERS'),
              dividerTitle:this.translate.instant('ALL_SPEAKERS_CAP'),
              isSearchEnabled:!this.isReadOnlyMode,
              showLeftHeaderButton: true,
              leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
              leftHeaderBtnText: this.translate.instant('CANCEL'),
              showRightHeaderButton: !this.isReadOnlyMode,
              rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
              rightHeaderBtnText: this.translate.instant('DONE'),
              orderByPropertyName: 'primaryTextRight',
              searchTitle: this.translate.instant('SEARCH_SPEAKERS'),
              hideAllItemsList: this.isReadOnlyMode,
              isListSelectionEnabled:!this.isReadOnlyMode,
              listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
              navOptions: { animate: false },
              eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleSpeakersComponentEvent(data,eventTarget,refData),
              searchHandler:(text: string) => this._handleSpeakersComponentSearch(text),
              data: this.eventsToolService.eventSpeakers && this.eventsToolService.eventSpeakers.map(pro => {
                let isSelected = false;
                if(this.currentEvent && this.currentEvent.speakers && this.currentEvent.speakers.some(s => (s.id === pro.id && !s.isDeleted && s.statecode === 0))){
                  isSelected = true;
                }
                return {
                  id: pro.id,
                  primaryTextLeft: '',
                  secondaryTextLeft: '',
                  showEndIcon: !this.isReadOnlyMode,
                  mainItemCssClass: 'selector-item',
                  isItemSelectedForSelectionView: isSelected,
                  endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
                  endIconCssClass: isSelected?'checkmark-icon':'add-icon',
                  primaryTextRight: pro.name,
                  showArrow: false,
                  arrowType: '',
                };
              }),
            };
            if(this.isReadOnlyMode){
                this.currentEvent.speakers.forEach(speaker=>{
                    const idx = listDetail.data.findIndex(it=> it.id === speaker.id);
                    if(idx<0){
                        const viewData: MainCardViewDataModel = {
                            id: speaker.id,
                            primaryTextLeft: '',
                            secondaryTextLeft: '',
                            showEndIcon: !this.isReadOnlyMode,
                            mainItemCssClass: 'selector-item',
                            isItemSelectedForSelectionView: true,
                            endIconType: 'indegene-selectors-checkmark-icon',
                            endIconCssClass: 'checkmark-icon',
                            primaryTextRight: speaker.name,
                            showArrow: false,
                            arrowType: '',
                          };
                        listDetail.data.push(viewData);
                    }
                });
            }
            this._openMainTool(listDetail);
          }
    }

    private _handleSpeakersComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail){
        if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
            if (!this._isEventCompleted && data && data.isDone) {
                if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems, length >= 0) {
                    this.currentEvent.speakers = this.currentEvent.speakers.map(s=>{
                        if(s.statecode === 0){
                            s.isDeleted = true;
                        }
                        return s;
                    });
                    data.selectedItems.forEach(item => {
                        const foundSpeakers = this.eventsToolService.eventSpeakers.find(speaker => speaker.id === item.id);
                        if (foundSpeakers) {
                            const idx = this.currentEvent.speakers.findIndex(a=> a.id === foundSpeakers.id);
                            if(idx>=0){
                                this.currentEvent.speakers[idx].isDeleted = false;
                                this.currentEvent.speakers[idx].name = foundSpeakers.name;
                            }else{
                                this.currentEvent.speakers.push({
                                    id: foundSpeakers.id,
                                    engagementId: null,
                                    name: foundSpeakers.name,
                                    statecode: foundSpeakers.state,
                                    isDeleted: false,
                                });
                            }
                        }
                    });
                }

                let payload;
                const speakersDTO = this.eventsToolDataService.getSpeakersDTO(this.currentEvent);
                if (speakersDTO) {
                  payload = { ...speakersDTO };
                }
                this._updateCurrentEvent(payload);
            }
        }
    }

    private _handleSpeakersComponentSearch(text: string): string[]{
        let ids: Array<string> = [];
        if (text.length >= 1) {
            ids = this.eventsToolService.eventSpeakers && this.eventsToolService.eventSpeakers.filter(pro => {
                return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(pro => pro.id);
        } else {
            ids = this.eventsToolService.eventSpeakers && this.eventsToolService.eventSpeakers.map(pro => pro.id);
        }
        return ids;
    }

    private _handlePdfPreview() {
        const data: PdfPreviewCmpInputData = {
            type: PdfPreviewCmpSourceType.jsPDF,
            data: {
                data: this.currentEvent,
                jsPdfGenerator: async (data, pdfService) => {
                  const doc = await pdfService.generateEventSignatureListPdfDoc(data);
                  return doc;
                },
                allowExport: true,
                exportHandler: (jsPdfDoc, fileName, pdfService) => {
                  return pdfService.exportPDF(jsPdfDoc, fileName);
                },
                exportFileName: `${this.currentEvent.name}${SIGNATURE_DOC_FILE_NAME_SUFFIX}`
              } as PdfPreviewCmpJsPDFInput
        };

        this.navService.pushChildNavPageWithPageTracking(PdfPreviewComponent,
          PageName.PdfPreviewComponent, PageName.EventsToolPageComponent, {pdfData:data});
    }

    openNewActivityPage(){
        this.uiService.showNewActivity = true;
    }

    footerButtonClicked(clickedButton: string) {
        switch (clickedButton) {
            case 'pdfPreview':
                this._handlePdfPreview();
                break;

            default:
                break;
        }
    }

    getFormattedDateTimeText(): any {
      if (!this.currentEvent) return '';
      const startDateTimeValue: Date = new Date(this.currentEvent.startDate);
      const endDateTimeValue: Date = new Date(this.currentEvent.endDate);
      const eventStartDay = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      const eventEndDay = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      const eventStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      const eventEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      const formattedDuration = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

      let dateTimeValue: any = {
        startDateTime: '',
        startDate: '',
        startTime: '',
        endDateTime: '',
        endDate: '',
        endTime: '',
        duration: '',
      };

      dateTimeValue = {
        startDateTime: startDateTimeValue,
        startDate: eventStartDay,
        startTime: eventStartTime,
        endDateTime: endDateTimeValue,
        endDate: eventEndDay,
        endTime: eventEndTime,
        duration: formattedDuration,
      };
      return dateTimeValue;
    }
    async _openStartDatePicker(myEvent) {
      if (!this.isReadOnlyMode) {
        this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.Event,
              startDateTimeValue: this.currentEvent.startDate,
              endDateTimeValue: this.currentEvent.endDate,
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            const selectedStartDate = new Date(data.data.startTime);
            const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(this.currentEvent.startDate);
            // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
            // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
            const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
            const setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
            if (this.currentEvent?._startDate?.getTime() !== (new Date(setCombinedStartDateTime)).getTime()) {
             // Remove conflict ID from other activities
              removeConflictIdFromOtherActivities(
                this.currentEvent,
                this.activityService.activities,
                this.eventsToolService.eventsToolData,
              );
              this.currentEvent._startDate = new Date(setCombinedStartDateTime);
              if (
                this.currentEvent.cutOffDate
                && !isSameDay(
                  this.currentEvent._startDate,
                  new Date(this.currentEvent.cutOffDate),
                )
                && this.currentEvent._startDate.getTime() < new Date(this.currentEvent.cutOffDate).getTime()
              ) {
                this.currentEvent.cutOffDate = null;
              }
              this.endTime = format(addMinutes(this.startTime, 30));
              this.currentEvent.endDate = new Date(this.endTime); // update added 30min from the start date and time
              // Re-check conflict
              checkTimeConflictWithOtherActivities(
                this.currentEvent,
                this.activityService.activities,
                this.activityService.conflictCheckStartIdx,
                this.eventsToolService.eventsToolData,
                this.eventsToolService.conflictCheckLastIdx,
                this.authService.user.xSystemUserID,
              );
              this.displayConflictMsg = this.eventsToolService.hasTimeConflict(this.currentEvent);
              this._updateCurrentEvent({
                msevtmgt_eventstartdate: format(this.currentEvent.startDate, 'YYYY-MM-DDTHH:mm:SS'),
                msevtmgt_eventenddate: format(this.currentEvent.endDate, 'YYYY-MM-DDTHH:mm:SS'),
              });
            }
          }
        });
        popover.present();
      }
    }
    async _openEndDatePicker(myEvent) {
      if (!this.isReadOnlyMode) {
        this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.Event,
              startDateTimeValue: this.currentEvent.startDate,
              endDateTimeValue: this.currentEvent.endDate,
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            const selecteEnddDate = new Date(data.data.endTime);
            const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(this.currentEvent.endDate);
            // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
            // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
            const getEndDate = this.datePipe.transform(selecteEnddDate, "MMM dd, yyyy", undefined, 'en-US');
            const setCombinedDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
            if (this.currentEvent?.endDate?.getTime() !== (new Date(setCombinedDateTime)).getTime()) {
             // Remove conflict ID from other activities
              removeConflictIdFromOtherActivities(
                this.currentEvent,
                this.activityService.activities,
                this.eventsToolService.eventsToolData,
              );
              this.currentEvent.endDate = new Date(setCombinedDateTime);
              // Re-check conflict
              checkTimeConflictWithOtherActivities(
                this.currentEvent,
                this.activityService.activities,
                this.activityService.conflictCheckStartIdx,
                this.eventsToolService.eventsToolData,
                this.eventsToolService.conflictCheckLastIdx,
                this.authService.user.xSystemUserID,
              );
              this.displayConflictMsg = this.eventsToolService.hasTimeConflict(this.currentEvent);
              this._updateCurrentEvent({
                msevtmgt_eventenddate: format(this.currentEvent.endDate, 'YYYY-MM-DDTHH:mm:SS'),
              });
            }
          }
        });
        popover.present();
      }
    }
    private handleStartDateTimeValue() {
      let m = moment(this.startTime);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Event details', 'top', ToastStyle.DANGER);
        this.startTime = this.prevStartTime;
      } else {
        if (this.startTime != this.prevStartTime) {
          // Remove conflict ID from other activities
           removeConflictIdFromOtherActivities(
             this.currentEvent,
             this.activityService.activities,
             this.eventsToolService.eventsToolData,
           );
           this.prevStartTime = this.startTime;
           this.currentEvent._startDate = new Date(this.startTime);
           // update added 30min from the start date and time
           this.endTime = format(addMinutes(this.startTime, 30));
           this.currentEvent.endDate = new Date(this.endTime);
           this._setViewData();
           // Re-check conflict
           checkTimeConflictWithOtherActivities(
             this.currentEvent,
             this.activityService.activities,
             this.activityService.conflictCheckStartIdx,
             this.eventsToolService.eventsToolData,
             this.eventsToolService.conflictCheckLastIdx,
             this.authService.user.xSystemUserID,
           );
           this.displayConflictMsg = this.eventsToolService.hasTimeConflict(this.currentEvent);
           setTimeout(() => {
            this._updateCurrentEvent({
              msevtmgt_eventstartdate: format(this.currentEvent.startDate, 'YYYY-MM-DDTHH:mm:SS'),
              msevtmgt_eventenddate: format(this.currentEvent.endDate, 'YYYY-MM-DDTHH:mm:SS'),
            });
           }, 20);
         }
      }
    }

    private handleEndDateTimeValue() {
      let m = moment(this.endTime);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Event details', 'top', ToastStyle.DANGER);
        this.endTime = this.prevEndTime;
      } else {
        if (this.endTime != this.prevEndTime) {
          // Remove conflict ID from other activities
           removeConflictIdFromOtherActivities(
             this.currentEvent,
             this.activityService.activities,
             this.eventsToolService.eventsToolData,
           );
           this.prevEndTime = this.endTime;
           this.currentEvent.endDate = new Date(this.endTime);
           // Re-check conflict
           checkTimeConflictWithOtherActivities(
             this.currentEvent,
             this.activityService.activities,
             this.activityService.conflictCheckStartIdx,
             this.eventsToolService.eventsToolData,
             this.eventsToolService.conflictCheckLastIdx,
             this.authService.user.xSystemUserID,
           );
           this.displayConflictMsg = this.eventsToolService.hasTimeConflict(this.currentEvent);
           setTimeout(() => {
            this._updateCurrentEvent({
              msevtmgt_eventenddate: format(this.currentEvent.endDate, 'YYYY-MM-DDTHH:mm:SS'),
            });
           }, 20);
         }
      }
    }

    private getOptionSetValueText(fieldType: string, fieldName: string, fieldValue: any): string {
      let valueText: string = '';

      const configFieldOptionValues: ConfigFieldOptionValue[] = this.eventsToolService.configFieldOptionsValues[fieldName];
      if (Array.isArray(configFieldOptionValues)) {

        try {
          if (fieldType === 'Picklist') {
            const option = configFieldOptionValues.find(o => o.value === fieldValue);
            if (option) {
              valueText = option.label;
            }
          } else if (fieldType === 'Virtual' && fieldValue) {
            const fieldValues: number[] = fieldValue.split(',').map(v => Number(v));
            const fieldLabels: string[] = [];
            for (const field of configFieldOptionValues) {
              if (fieldValues.includes(field.value)) {
                fieldLabels.push(field.label);
              }
            }

            if (fieldLabels.length > 0) {
              valueText = fieldLabels[0];

              if (fieldLabels.length > 1) {
                valueText = valueText + ' + ' + (fieldLabels.length - 1);
              }
            }
          }
        } catch (error) {
          console.error(
            'getOptionSetValueText: ',
            error,
            fieldType, fieldName, fieldValue,
          );
        }
      }

      return valueText;
    }

    private getConfigFieldInputTextAndValue(fieldType: string, fieldName: string): { inputText: string, inputValue: any } {
      let inputText: string = '';
      let inputValue: any = null;

      if (
        this.currentEvent
        && this.currentEvent.configuredFields
        && fieldType && fieldName
      ) {
        const fieldValue = this.currentEvent.configuredFields[fieldName];
        inputValue = fieldValue;

        switch (fieldType) {
          case 'String':
          case 'Memo':
          case 'Currency':
          case 'Money':
          case 'Integer':
          case 'BigInt':
          case 'Decimal':
            if (!(fieldValue === undefined || fieldValue === null)) {
              inputText = '' + fieldValue;
            }
            break;

          case 'Boolean':
            inputText = fieldValue === true ? this.translate.instant('YES') : fieldValue === false ? this.translate.instant('No') : '';
            break;

          case 'DateTime':
            try {
              // Convert UTC string to timezone-independent-ish Date object (same number in local timezone)
              // so that we can display the formatted string
              const offsetDate = new Date(fieldValue);
              const tzIndependentDateObj = new Date(
                offsetDate.getUTCFullYear(),
                offsetDate.getUTCMonth(),
                offsetDate.getUTCDate(),
                offsetDate.getUTCHours(),
                offsetDate.getUTCMinutes(),
                0,
                0
              );

              inputText = fieldValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
              inputValue = inputText;
            } catch (error) {
              console.error('getConfigFieldInputTextAndValue: DateTime type: ', error);
            }
            break;

          case 'Picklist':
          case 'Virtual':
            inputText = this.getOptionSetValueText(fieldType, fieldName, fieldValue);
            break;

          // Unsupported
          case 'Uniqueidentifier':
          case 'Lookup':
            if (fieldName && this.currentEvent) {
              if (this.currentEvent.lookupConfiguredFields && this.currentEvent.lookupConfiguredFields.length) {
                let fieldFilter = this.currentEvent.lookupConfiguredFields.filter(o => { return o.name === fieldName });
                if (fieldFilter && fieldFilter.length) {
                  let field = fieldFilter[0];
                  if (field.stringValue) {
                    inputText = field.stringValue;
                    inputValue = field.id;
                  }
                }
              }
            }
            break;
          case 'Owner':
          case 'EntityName':
            break;
          default:
            console.error('getConfigFieldInputTextAndValue: Unhandled switch case statement: ', fieldType, fieldName);
            break;
        }
      }

      return { inputText, inputValue };
    }

    private isConfigFieldValueChanging(fieldName: string, value: any) {
      let currentValue: any = undefined;

      if (this.originalFieldValues.hasOwnProperty(fieldName)) {
        currentValue = this.originalFieldValues[fieldName];
      } else if (this.currentEvent.configuredFields.hasOwnProperty(fieldName)) {
        currentValue = this.currentEvent.configuredFields[fieldName];
      }
      const isChanging = !(currentValue === value);
      return isChanging;
    }

    private getIsCutOffDateInEffect() {
      let isCutOffDateInEffect = false;
      if (
        this.isCutOffDateFeatureEnabled
        && this.currentEvent.attendeeFormat === AttendeeFormat.propose
      ) {
        try {
          if (this.currentEvent.cutOffDate) {
            // es: 3.25 9am
            // cd: 3.24
            // today: 3.24
            const cutOffDateTimeStamp = new Date(this.currentEvent.cutOffDate).setHours(23, 59, 59, 59);
            const now = new Date();
            const eventStart = this.currentEvent.startDate;
            if (
              isSameDay(
                new Date(cutOffDateTimeStamp),
                now,
              )
              && isSameDay(
                now,
                eventStart,
              )
            ) {
              isCutOffDateInEffect = now.getTime() > eventStart.getTime();
            } else {
              isCutOffDateInEffect = now.getTime() > cutOffDateTimeStamp;
            }
          } else {
            isCutOffDateInEffect = true;
          }
        } catch (error) {
          console.error('_handleParticipantsField: ', error);
        }
      }

      return isCutOffDateInEffect;
    }

  notesChanged(event) {
    if (event?.target) {
      this.tempNoteText = event.target.value;
    } else {
      this.tempNoteText = '';
    }
    if (this.tempNoteText.length > 0) {
      this.isSaveNotesEnabled = true;
    } else {
      this.isSaveNotesEnabled = false;
    }
    this._initHeaderView();
  }

  removeAttachment(event) {
    if (this.attachInput?.nativeElement?.value) {
      this.attachInput.nativeElement.value = null;

      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      this._initHeaderView();
    }
    if (this.eventsToolService.pastEventAttachments.length && !this.attachInput?.nativeElement?.value) {
      this.eventsToolService.pastEventAttachments.forEach(ev => {
        ev.attachments.forEach(att => {
          att.checked = false;
        })
      })
      this.attachInput.nativeElement.value = null;

      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      this._initHeaderView();
    }
  }

  selectPastEventAttachment(attachment, $event) {
    this.eventsToolService.pastEventAttachments.forEach(ev => {
      ev.attachments.forEach(att => {
        if (att.annotationid == attachment.annotationid) {
          att.checked = true;
          this.attachmentTitle = att.filename;
        } else {
          att.checked = false;
        }
      })
    })
    if ($event.target.checked) {
      this.isAttachmentAdded = true;
    } else {
      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
    }
    this._initHeaderView();
  }

  async loadFileFromDevice(event) {
    if (event?.target?.files) {
      try {
        const file = event.target.files[0];
        if (file && (file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            const b64Str: any = await toBase64(file);
            const b64StrSplitArray = b64Str.split(',');
            this.base64str = Array.isArray(b64StrSplitArray)
            && b64StrSplitArray.length > 1
              ? b64StrSplitArray[1] : b64Str || null;
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this._initHeaderView();
          } else {
            this.notificationService.notify(
              this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),
              'Event Notes',
              'top',
              ToastStyle.INFO,
            );
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(
            this.translate.instant(
              'MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',
              { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }
            ),
            'Event Notes',
            'top',
            ToastStyle.INFO,
          );
          this.removeAttachment(false);
        }
      } catch (error) {
        console.error('loadFileFromDevice: ', error);
      }
    }
  }

  canEditNote(note: IONote) {
    return !this.isEventCompleted && !this.isOffline && note.ownerId === this.authService.user.systemUserID;
  }
  canDeleteNote(note: IONote) {
    return !this.isEventCompleted && !this.isOffline && note.ownerId === this.authService.user.systemUserID;
  }
  handleUpdateNoteBtnClick(event) {
      this.updateNote(event);
  }

  async updateNote(event) {
    if (event?.action) {
      try {
        const noteIdx: number = this.currentEvent.annotations?.findIndex(n => n.noteId === event.noteId);
        if (noteIdx >= 0) {
          const note: IONote = this.currentEvent.annotations[noteIdx];
          const origNote: IONote = JSON.parse(JSON.stringify(note));
          const payload: any = {
            noteid: note.noteId,
            eventid: this.currentEvent.ID,
          };

          if (event.action === 'DELETE') {
            payload.deleted = true;
            this.currentEvent.annotations.splice(noteIdx, 1);
          } else if (event.action === 'SAVE') {
            note.noteText = payload.notetext = event.updatedText;

            if (event.attachmentFileUpdated) {
              note.hasDocument = payload.isdocument = true;
              note.documentName = payload.filename = event.documentName;
              note.documentMimeType = payload.mimetype = event.documentMimeType;
              // note.documentSize = payload.filesize = event.documentSize;
              const b64StrSplitArray = event.attachmentFileDataUrl?.split(',');
              payload.documentbody = Array.isArray(b64StrSplitArray)
              && b64StrSplitArray.length > 1
                ? b64StrSplitArray[1] : event.attachmentFileDataUrl || null;
              payload.fileupdated = true;
            } else if (event.isAttachmentRemoved) {
              note.hasDocument = payload.isdocument = false;
              note.documentName = payload.filename
                = note.documentMimeType = payload.mimetype = '';
              note.documentSize = payload.filesize = null;
              payload.fileremoved = true;
            }
          }

          // let loader: any;
          try {
            // loader = await this.loadingCtrl.create();
            // await loader.present();
            await this.uiService.displayLoader();

            const response = await this.eventsToolDataService.createUpdateDeleteEventNote([payload]);
            if (Array.isArray(response) && response[0]?.noteid) {
              // Update local DB
              this.eventsToolDataService.updateEventsData(
                {
                  onDynamics: false,
                  onLocalDatabase: true,
                  onLocalCopy: true,
                },
                [this.currentEvent],
              ).then((value: Array<EventActivity>)=>{
                  this.currentEvent = value[0];
                  this.uiService.dismissLoader();
                  this._setViewData();
                  // Reset form
                  this.tempNoteText = '';
                  this.removeAttachment(null);
                  this.isSaveNotesEnabled = false;
              }).catch(err=>{
                  this.uiService.dismissLoader();
                  this._setViewData();
                  console.log('Error updating event notes');
              });
            }
          } catch (error) {
            console.error('events: updateNote: createUpdateDeleteEventNote: ', error);
            // Revert
            this.currentEvent.annotations[noteIdx] = origNote;
            this.uiService.dismissLoader();
          } finally {
            // await loader?.dismiss();
            this._initHeaderView();
          }
        }
      } catch (error) {
        console.error('events: updateNote: ', error);
      }
    }
  }

  private async initConfigFieldsLookupData(){
    let lookupFields = [];
    if(this.authService.user.eventConfiguredFields && this.authService.user.eventConfiguredFields.length > 0){
      lookupFields = this.authService.user.eventConfiguredFields.filter(a=> a.datatype == 'Lookup');
    }
    if(lookupFields && lookupFields.length > 0){
      await this.disk.retrieve(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, true).then((doc)=>{
        if(doc && doc.raw){
          this.lookupConfigFieldsData = doc.raw['msevtmgt_event'];
        }
      })
    }
    this.lookupFieldsDataInitialized = true;
  }

  public async openLookup(event,fieldName) {
    const configField = this.authService.user.eventConfiguredFields.find(a=> a.fieldName == fieldName);
    if(configField){
        let results = [];
        if(this.device.isOffline){
          if(!this.lookupFieldsDataInitialized){
            await this.initConfigFieldsLookupData();
          }
          if(this.lookupConfigFieldsData && this.lookupConfigFieldsData[configField.fieldName]){
            results = this.lookupConfigFieldsData[configField.fieldName]
          }
        }else{
          results = await this.activityDataService.getConfigFieldLookupResults(configField,null);
        }
        let currentSelectedValue:string;
        if(this.currentEvent && this.currentEvent.lookupConfiguredFields && this.currentEvent.lookupConfiguredFields.length > 0){
          const currentConfigField = this.currentEvent.lookupConfiguredFields.find(a=> a.name == fieldName);
          if(currentConfigField && currentConfigField.id){
            currentSelectedValue = currentConfigField.id;
          }
        }
        let lookupPopoverModel:IndDropdownListDetailModel = {
          id: 'config-fields-lookup-select',
          isSearchEnabled:true,
          searchInputPlaceholder: this.translate.instant('SEARCH'),
          data: results.map(item=> {
            let data:SelectListData = {
              title: item[configField.indskr_lookupentityprimarynameattribute],
              id: item[configField.indskr_lookupentityprimaryidattribute],
              isSelected: currentSelectedValue == item[configField.indskr_lookupentityprimaryidattribute] ? true : false,
            }
            return data;
          })
        };
        let lookupPopover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData: lookupPopoverModel },
          cssClass: 'dropdown-list-view',
          event: event
        });
        lookupPopover.onDidDismiss().then(async (data: any) => {
          data = data.data;
          let shouldCheckForMandatoryField: boolean = true;
          if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != currentSelectedValue) {
            let selectedPopOverValue = data.selectedItems[0].id;
            let selectedPopOverStringValue = data.selectedItems[0].title;
            this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
            let idx = this.currentEvent.lookupConfiguredFields.findIndex(a=> a.name == fieldName);
            if(idx>=0){
              this.currentEvent.lookupConfiguredFields[idx].id = selectedPopOverValue;
              this.currentEvent.lookupConfiguredFields[idx].stringValue = selectedPopOverStringValue;
              this.currentEvent.lookupConfiguredFields[idx].entity = configField.indskr_lookupentitysetname;
              this.currentEvent.lookupConfiguredFields[idx].indskr_referencingentitynavigationpropertyname = configField.indskr_referencingentitynavigationpropertyname;
            }else{
              this.currentEvent.lookupConfiguredFields.push({
                id:selectedPopOverValue,
                name: fieldName,
                entity: configField.indskr_lookupentitysetname,
                stringValue: selectedPopOverStringValue,
                indskr_referencingentitynavigationpropertyname: configField.indskr_referencingentitynavigationpropertyname
              })
            }
            this.currentEvent.configuredFields[fieldName] = selectedPopOverStringValue;
            shouldCheckForMandatoryField = false;
            this._updateConfigFieldInputValues(configField.fieldType, configField.fieldName);
          }
          //if(targetControl.isRequired && this.dynamicFormsService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT){
          lookupPopover = undefined;
          if (shouldCheckForMandatoryField) {
            // Mandatory field check
            this._configFieldMandatoryCheck(configField.fieldType, configField.fieldName);
          }
        });
        lookupPopover.present();
      }
    
  }
}
