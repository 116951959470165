<ion-header>
    <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content *ngIf="callPlanService.selectedRepCallPlan">
    <ion-list>

        <ion-item-group class='plan-fields'>

            <ind-section-header [viewData]="detailsHeaderModel"></ind-section-header>
            <ind-form-field [viewData]=getStatusFormField()></ind-form-field>
            <ind-form-field [viewData]=getCustNameFormField()></ind-form-field>
            <ion-row>
              <ion-col size="6"><ind-form-field [viewData]="getStartDateFormField()"></ind-form-field></ion-col>
              <ion-col size="6"><ind-form-field [viewData]="getEndDateFormField()"></ind-form-field></ion-col>
            </ion-row>
            <ind-form-field [viewData]=getProductFormField()></ind-form-field>
            <ind-form-field [viewData]=getSegmentFormField()></ind-form-field>
            <ind-form-field [viewData]=getPositionFormField()></ind-form-field>
        </ion-item-group>

        <ion-item-group>
            <ind-section-header [viewData]="meetingsHeaderModel"></ind-section-header>
            <ind-form-field [viewData]=getMeetingGoalFormField()></ind-form-field>
            <ind-form-field [viewData]=getMeetingSuggestedFormField()></ind-form-field>
            <ind-form-field [viewData]=getMeetingCompletedFormField()></ind-form-field>
            <ind-form-field [viewData]=getMeetingCompletionFormField()></ind-form-field>
        </ion-item-group>
        <ion-item-group>
            <ind-section-header [viewData]="messagingHeaderModel"></ind-section-header>

            <ind-form-field [viewData]=getMessageGoalFormField()></ind-form-field>
            <ind-form-field [viewData]=getMessageSuggestedFormField()></ind-form-field>
            <ind-form-field [viewData]=getMessageCompletedFormField()></ind-form-field>
            <ind-form-field [viewData]=getMessageCompletionFormField()></ind-form-field>
        </ion-item-group>
        <ion-item-group *ngIf="otherRepCallPlans && otherRepCallPlans.length > 0">
            <ind-section-header [viewData]="coverageHeaderModel"></ind-section-header>
            <div *ngFor="let otherRepCallPlan of otherRepCallPlans">
                <main-card [viewData]='getCoverateTeamDataModel(otherRepCallPlan)'></main-card>
                <!-- <div text-left class="padTop">
          <div col-md-1>
            <h5>{{otherRepCallPlan.firstName + ' ' + otherRepCallPlan.lastName}}</h5>
          </div>
        </div>
        <div text-left class="account-secondary-info">
          <ion-row>
              <span class="compPercent">{{otherRepCallPlan.callCompletedPercentage}}%</span>
              <span class="statusText">{{'STATUS' | translate}} - {{otherRepCallPlan.statusCode  | repCallPlanState}}</span>
          </ion-row>
          <ion-row>
              <span class="positionText">{{otherRepCallPlan.positionName}}</span>
          </ion-row>
        </div> -->
            </div>
        </ion-item-group>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'customercallplan'"></footer-toolbar>
<!-- </ion-footer> -->
