<ion-header>
  <ind-page-title
    [viewData]="pageTitleViewData"
    (onControlClick)="onPageTitleControlClick($event)"
  ></ind-page-title>
</ion-header>
<!-- <ion-item no-lines hidden="true">
  <ion-datetime #genericDatePicker
    [displayFormat]="genericDatePickerDisplayFormat"
    (ionCancel)="updateGenericDatePickerField(false, genericDatePicker.value)"
    (ionChange)="updateGenericDatePickerField(true, genericDatePicker.value)"
    [(ngModel)]="genericDatePickerValue"
    [pickerFormat]="genericDatePickerPickerFormat"
    [monthShortNames]="shortMonth | monthTranslate"
    [doneText]="this.translate.instant('DONE')"
    [cancelText]="this.translate.instant('CANCEL')"
    [max]="genericDatePickerMaxValue"
  >
  </ion-datetime>
</ion-item> -->
<ion-content>
  <ind-form-field [viewData]="recontactReportField"></ind-form-field>
  <ind-form-field [viewData]="reporterNameField"></ind-form-field>
  <ind-form-field [viewData]="phoneField"></ind-form-field>
  <ind-form-field [viewData]="addressField"></ind-form-field>
  <ind-form-field [viewData]="emailField"></ind-form-field>
  <ind-form-field [viewData]="dateOfAwarenessField"></ind-form-field>
  <ind-form-field [viewData]="acinoDrugTakenField"></ind-form-field>
  <ind-form-field [viewData]="patientIdField"></ind-form-field>
  <ind-form-field [viewData]="eventDescriptionField"></ind-form-field>
</ion-content>
<footer-toolbar
  #footer
  [footerView]="'detailView'"
  (buttonClicked)="onFooterButtonClick($event)"
></footer-toolbar>
