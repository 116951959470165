import { Injectable } from '@angular/core';
import { DiskService } from '../disk/disk.service';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from '../../config/pouch-db.config';
import { KOLStatus } from '@omni/classes/contact/kol-status.class';
import { AuthenticationService } from '../authentication.service';
import { Endpoints } from 'src/config/endpoints.config';
import { CONTACT_FETCH_QUERIES } from '@omni/config/fetch-xml/contact-fetchXMLs';
import { differenceInHours } from 'date-fns';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { DeviceService } from '../device/device.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DeltaService, EntityNames, EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KOLStatusService {

  public selectedKOLStatus: KOLStatus;
  public myKOLStatusRecords: Array<KOLStatus> = [];
  public diseaseAreasList:Array<{
    id:string,
    title:string,
  }> = [];
  public teamKOL: Array<KOLStatus> = [];
  public isKOLCreateInProgress: boolean = false;

  constructor(
    private disk: DiskService,
    private readonly authenticationService: AuthenticationService,
    private readonly dynamics: DynamicsClientService,
    private readonly deviceService: DeviceService,
    private readonly deltaService: DeltaService,
    private readonly http: HttpClient,
  ) {
  }

  public async syncMyKOLStatuses(fullSync?: boolean, loadFromDBOnly = false) {
    if (!this.deviceService.isOffline && this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_KOL_STATUS)) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_KOL_STATUSES);
      //let offlineDataCount: number = 0;

      let lastUpdatedTime;
      if (offlineData && offlineData.raw && !fullSync) {
        if (offlineData.lastUpdatedTime) {
          lastUpdatedTime = offlineData.lastUpdatedTime;
        }
        //offlineDataCount = offlineData.raw.filter(o => o && o.hasOwnProperty('pendingPushToDynamics') && o.pendingPushToDynamics === true).length;
      }

      //this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EVENT_TOOL_ACTIVITY, offlineDataCount);

      if (!loadFromDBOnly) {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_KOL_STATUSES);
        const bulkKOLStatusesSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.kol_statuses,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        const now = new Date().getTime();
        let fetchXML = CONTACT_FETCH_QUERIES.myKOLStatuses;
        fetchXML = fetchXML.replace("{userId}", this.authenticationService.user.xSystemUserID);

        if (lastUpdatedTime && !fullSync) {
          let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
        } else {
          fetchXML = fetchXML.replace('<condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />', '');
        }

        let response = await this.dynamics.executeFetchQuery('indskr_kolstatuses', fetchXML);
        lastUpdatedTime = new Date().getTime();
        if (response && Array.isArray(response) && response.length != 0 && response[0]) {
          bulkKOLStatusesSyncInfo.totalSynced += response.length;
          if (offlineData && offlineData.raw && !fullSync) {
            response.forEach(item => {
              let idx = offlineData.raw.findIndex(a => a.indskr_kolstatusid == item.indskr_kolstatusid);
              if (idx >= 0) {
                offlineData.raw[idx] = item;
              } else {
                offlineData.raw.push(item);
              }
            });
            offlineData.lastUpdatedTime = lastUpdatedTime;
          } else {
            offlineData = {
              raw: response,
              lastUpdatedTime: lastUpdatedTime,
            }
          }
          syncState.lastUpdatedTime = lastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          await this.deltaService.addEntitySyncInfo(bulkKOLStatusesSyncInfo);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_KOL_STATUSES, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save kol statuses data in offline db error: ', error));
        }
      }
    }
  }

  public async syncDiseaseAreaRecords(fullSync?: boolean, loadFromDBOnly = false) {
    if (!this.deviceService.isOffline && (this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_KOL_STATUS) || this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_DISEASE_AREA))) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_DISEASE_AREAS);
      let lastUpdatedTime;
      if (offlineData && offlineData.raw && !fullSync) {
        if (offlineData.lastUpdatedTime) {
          lastUpdatedTime = offlineData.lastUpdatedTime;
        }
      }
      if (!loadFromDBOnly) {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_DISEASE_AREAS);
        const bulkKOLStatusesSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.disease_areas,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        const now = new Date().getTime();
        let fetchXML = CONTACT_FETCH_QUERIES.fetchDiseaseAreas;
        //fetchXML = fetchXML.replace("{userId}", this.authenticationService.user.xSystemUserID);

        if (lastUpdatedTime && !fullSync) {
          let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
        } else {
          fetchXML = fetchXML.replace('<condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />', '');
        }

        let response = await this.dynamics.executeFetchQuery('indskr_diseaseareas', fetchXML);

        lastUpdatedTime = new Date().getTime();
        if (response && Array.isArray(response) && response.length != 0 && response[0]) {
          bulkKOLStatusesSyncInfo.totalSynced += response.length;
          response = this.aggregateDiseaseArea(response);
          if (offlineData && offlineData.raw && !fullSync) {
            response.forEach(item => {
              let idx = offlineData.raw.findIndex(a => a.indskr_kolstatusid == item.indskr_kolstatusid);
              if (idx >= 0) {
                if(item.statecode == 0){
                  offlineData.raw[idx] = item;
                }else{
                  offlineData.raw.splice(idx,1);
                }
              } else {
                if(item.statecode == 0){
                  offlineData.raw.push(item);
                }
              }
            });
            offlineData.lastUpdatedTime = lastUpdatedTime;
          } else {
            offlineData = {
              raw: response,
              lastUpdatedTime: lastUpdatedTime,
            }
          }
          syncState.lastUpdatedTime = lastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          await this.deltaService.addEntitySyncInfo(bulkKOLStatusesSyncInfo);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_DISEASE_AREAS, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save diesease areas data in offline db error: ', error));
        }
      }
    }
  }

  aggregateDiseaseArea(data):Array<any>{
    let result = [];
    if(data && data.length){
      data.forEach(record=>{
        let idx = result.findIndex(a=> a['indskr_diseaseareaid'] == record['indskr_diseaseareaid']);
        if(idx >= 0 && record['indskr_indskr_diseasearea_indskr_positiongroup1.indskr_positiongroupsid']){
          result[idx]['positionGroups'].push(record['indskr_indskr_diseasearea_indskr_positiongroup1.indskr_positiongroupsid']);
        }else{
          result.push({
            'indskr_diseaseareaid' : record['indskr_diseaseareaid'],
            'indskr_name': record['indskr_name'],
            'statecode': record['statecode'],
            'positionGroups': record['indskr_indskr_diseasearea_indskr_positiongroup1.indskr_positiongroupsid'] ? [record['indskr_indskr_diseasearea_indskr_positiongroup1.indskr_positiongroupsid']]:[],
          });
        }
      })
    }
    return result;
  }

  public async getMyKOLDataOffline() {
    let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_KOL_STATUSES);
    if(offlineData && offlineData.raw && Array.isArray(offlineData.raw)){
      this.myKOLStatusRecords = [];
      offlineData.raw.forEach(item => {
        if(item['_ownerid_value'] == this.authenticationService.user.xSystemUserID){
          let kol = new KOLStatus(item);
          this.myKOLStatusRecords.push(kol);
        }
      });
      this.myKOLStatusRecords.sort((a,b)=> a.createdOn.getTime() - b.createdOn.getTime());
    }
  }

  public async getDiseaseAreaDataOffline() {
    let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_DISEASE_AREAS);
    if(offlineData && offlineData.raw && Array.isArray(offlineData.raw)){
      this.diseaseAreasList = [];
      offlineData.raw.forEach(item => {
        if(item['indskr_diseaseareaid'] && item['indskr_name']){
          this.diseaseAreasList.push({
            id: item['indskr_diseaseareaid'],
            title: item['indskr_name'],
          })
        }
      });
    }
  }

  public async getTeamKOLDataOnline() {
    if (this.deviceService.isOffline) return;
    let fetchXML = CONTACT_FETCH_QUERIES.teamKOLStatuses;
    fetchXML = fetchXML.replace("{userId}", this.authenticationService.user.xSystemUserID);
    await this.dynamics.executeFetchQuery('indskr_kolstatuses', fetchXML)
      .then(async (res) => {
        if (res) {
          if (Array.isArray(res)) {
            this.teamKOL = [];
            res.forEach(record => {
              let kol = new KOLStatus(record);
              kol.isApprovalRecord = true;
              this.teamKOL.push(kol);
            })
          }
        }
      })
      .catch(async (err) => {
        console.log('Error while fetching team kol statuses' + err);
      });
  }

  public async saveNewKOLStatus(kol:KOLStatus):Promise<any> {
    let res = await this._saveKOLStatusOnline(kol);
    if (res && res['indskr_kolstatusid']) {
      kol.ID = res['indskr_kolstatusid'];
      // Save in db
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_KOL_STATUSES);
      if (offlineData && offlineData.raw && Array.isArray(offlineData.raw) && offlineData.raw.length != 0) {
        let idx = offlineData.raw.findIndex(insight => insight['indskr_kolstatusid'] == kol.ID);
        if (idx >= 0) {
          offlineData.raw[idx] = kol.getOfflineDataDTO;
        } else {
          offlineData.raw.push(kol.getOfflineDataDTO);
        }
      } else {
        offlineData = {
          raw: [kol.getOfflineDataDTO],
        };
      }
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_KOL_STATUSES, doc => {
        doc = {
          raw: offlineData.raw,
        };
        return doc;
      }).catch(error => console.error('Save Default LE to DB error: ', error));

      // Add to local
      let idx = this.myKOLStatusRecords.findIndex(a => a.ID == kol.ID);
      if (idx >= 0) {
        this.myKOLStatusRecords[idx] = kol;
      } else {
        this.myKOLStatusRecords.push(kol);
      }
    }
  }

  public async updateKOLRecordStatusOnline(payload,kol:KOLStatus):Promise<any>{
    return new Promise(async (resolve, reject) => {
      let headers = Endpoints.headers.content_type.json;
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.UPDATE_KOL_STATUS;
      url = url.replace('{approvalActivityId}',kol.approvalActivityId);
      let response =  await this.http.patch(url, payload, headers).toPromise().catch(err=>{
        reject(err);
      });
      // Add to local
      let idx = this.teamKOL.findIndex(a => a.ID == kol.ID);
      if (idx >= 0) {
        let myKOL = this.myKOLStatusRecords[idx];
        if (myKOL) {
          this.myKOLStatusRecords[idx].approvalStatus = (payload.statuscode == 548910001 ? 'Approved' : 'Rejected');
        }
        this.teamKOL[idx].approvalStatus = (payload.statuscode == 548910001 ? 'Approved' : 'Rejected');
        kol.statusString = (payload.statuscode == 548910001 ? 'Approved' : 'Rejected');
      }
      resolve(response);
    });
    
  }

  private async _saveKOLStatusOnline(kol: KOLStatus): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let headers = Endpoints.headers.content_type.json;
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.SAVE_KOL_STATUS;
      const payload = kol.getServiceDTO;
      let response = await this.http.post(url, payload, headers).toPromise().catch(err=>{
        reject(err);
      });
      resolve(response);
    });
  }
}