
export class TimeOffResponse {
  public startTime: Date;
  public endTime: Date;
  public subject: string;
  public isAllDayEvent: boolean;
  public reason: number;
  public positionId: string;
  public timeOffRequestId: string;

  constructor(raw:Object){
    this.timeOffRequestId = raw['indskr_timeoffrequestid'];
    this.startTime = new Date(raw['indskr_starttime']);
    this.positionId = raw['indskr_positionid'];
    this.isAllDayEvent = raw['indskr_isalldayevent'];
    this.endTime = new Date(raw['indskr_endtime']);
    this.reason = raw['indskr_reason'];
    this.subject = raw['indskr_name'];
  }
}
