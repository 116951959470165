
import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentToken } from '../../../classes/email-templates/email-template.class';
import { DeviceService } from '../../../services/device/device.service';
import { EmailService } from '../../../services/email-templates/email.service';
import { ReplacementTokenListComponent } from '../replacement-token-list/replacement-token-list';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { TemplatePreviewComponent } from '../template-preview/template-preview';

/**
 * Generated class for the TemplatePersonalizePageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'template-personalize-page',
  templateUrl: 'template-personalize-page.html',
  styleUrls: ['template-personalize-page.scss']
})
export class TemplatePersonalizePageComponent {

  destroy$: Subject<boolean> = new Subject<boolean>();
  contentToken: ContentToken;
  // @ViewChild('templatePreview', {static:true}) public navCtrl: IonNav;
  @ViewChild('templatepersonalizepagerightpane', {static:true}) public navCtrl: IonNav;
  constructor(
    private readonly emailService: EmailService,
    private readonly device: DeviceService,
    public readonly navService: NavigationService,
    private readonly footerService: FooterService,
  ) { }

  ngOnInit() {

    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.TemplatePreviewNavigation, PageName.TemplatePreviewComponent, false, {deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');

    const navOptions = { progressAnimate: true };
    if (this.device.isMobileDevicePortrait) {
      this.emailService.resetContentToken();
    } else {
      const preview = this.emailService.selectedActivity.description;
      const index: number = preview.indexOf('%custom.');
      if (index !== -1) {
        // %Custom. --> length = 8 & GUID token.length = 36
        const tokens: string[] = preview.substring(index + 8, index + 81).split('.');
        const token: ContentToken = cloneDeep(this.emailService.contentTokens.find(ct => ct.indskr_contenttokenid == tokens[0]));
        if (token) {
          token.replacementTokens = token.replacementTokens.map(rt => { return { ...rt, is_default: rt.replacement_id == tokens[1] } });
          this.emailService.selectContentToken(token);
        }
      }
    }

    this.navService.setChildNavRoot(TemplatePreviewComponent, PageName.TemplatePreviewComponent, PageName.TemplatePersonalizePageComponent, { token: this.contentToken });
    this.navService.setChildNavRightPaneView(true);

    this.emailService.selectedContentToken.pipe(
      takeUntil(this.destroy$))
      .subscribe(token => {
        if (token) {
          this.contentToken = token;
        }
      });
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.TemplatePreviewNavigation);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
