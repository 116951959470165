import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { format, isToday, isYesterday } from "date-fns";
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { MyAssistantService } from '../../../services/my-assistant/my-assistant.service';
import { MyAssistantDataService } from '../../../services/my-assistant/my-assistant.data.service';

@Component({
  selector: 'genee-notification',
  templateUrl: 'genee-notification.html',
  styleUrls:['genee-notification.scss']
})

export class GeneeNotificationComponent {

  groupedData: any;
  noDataToDisplay: boolean = true;
  @Input() closeToolsFunctionRef: any;
  constructor(public dateTimeFormatsService: DateTimeFormatsService,
    public assistantService: MyAssistantService,
    public myAssistantDataService: MyAssistantDataService,
    public modalCtrl: ModalController) {

  }

  ngOnInit() {
    this.getGroupedData();
  }

  /**
  * Action when user close the notification component.
  *
  * @memberOf GeneeNotificationComponent
  */
  actionGoBack() {
    this.assistantService.resetSearchText();
    this.modalCtrl.dismiss({
      'isClosed': false
    });
    this.assistantService.markAllAsRead();
  }

  /**
  * Action when user clear the notification component.
  *
  * @memberOf GeneeNotificationComponent
  */
  clearNotifications() {
    this.assistantService.resetSearchText();
    this.assistantService.myAssistantData = [];
    this.noDataToDisplay = true;
    this.groupedData = [];
    this.assistantService.saveMyAssistantData();
  }

  /**
  * Action when user dismiss the notification component.
  *
  * @memberOf GeneeNotificationComponent
  */
  actionDismissGeneeAssistant() {
    this.assistantService.resetSearchText();
    this.assistantService.markAllAsRead();
    this.modalCtrl.dismiss({
      'isClosed': true
    });
  }

  /**
  * Notification date format.
  *
  * @memberOf GeneeNotificationComponent
  */
  formatDate(date) {
    if (isToday(date)) {
      return 'Today - ' + format(date, this.dateTimeFormatsService.dateToUpper);
    }
    if (isYesterday(date)) {
      return 'Yesterday - ' + format(date, this.dateTimeFormatsService.dateToUpper);
    }
    else {
      return format(date, 'ddd, MMM D - ' + this.dateTimeFormatsService.dateToUpper);
    }
  }

  /**
  * Private helper when we get notification data to show Notifications.
  *
  * @memberOf GeneeNotificationComponent
  */
  getGroupedData() {
    this.assistantService.getGroupedDataByMonth('My Assistant').then((data) => {
      if (data && data.length) {
        this.noDataToDisplay = false;
        this.groupedData = data;
      } else {
        this.noDataToDisplay = true;
        this.groupedData = [];
      }
    });
  }

  onSearchClear(event) {
    this.assistantService.resetSearchText();
    this.getGroupedData();
  }

  onInput(event) {
    this.assistantService.notificationsSearchText = event.target.value;
    this.getGroupedData();
  }
}
