import { FeatureActionsMap } from './../classes/authentication/user.class';
export interface CategoryValuePairModel{
  categoryValuePairID?: string;
  categoryName:string;
  value:string;
}
export interface searchIndexDataModel {
  categoryName:string,
  categoryNameInEng?:string,
  translationKey?:string,
  categoryDisplayName?: string,
  categoryDisplayNameRaw?: string,
  categoryRelativePath:string,
  controlDataType?:string;
  values:string[],
  valuesRaw?:string[],
  optionValuesInEng?:string[],
  mappingValues?:Array<{actualValue:string,formattedValue:string}>;
  isBooleanTypeCategory?:boolean,
  customizedBypassGateEnabled?: boolean,
  parentEntity?: string, // top level parent entity
  attributeName?: string,
  entity?: string, // attribute entity
  featureActionDependant?:boolean,
  featureActionName?: FeatureActionsMap,
  linkEntityFetchXML?: string,
  numOfLinkEntity?: number,
  fromAttribute?: string,
  toAttribute?: string,
  isThisToBeAggregated?: boolean,
  searchAttributeNames?: string[],
  relationshipName?: string,
  isMultilingualLookup?:boolean,
  configuredFrom?: string,
  isFixedFilter?: boolean,
  filterOrder?: string,
  isForcedHide?: boolean,
  isSubCategory?: boolean,
  isSingleSelect?: boolean
}

export interface SelectedSuggestionPillDataModel{
    selectedFacet:string,
    categoryPath: string,
    categoryName: string,
    categoryDisplayName: string,
    type?:SuggestionPillType;
    value?: any;
    charSearchText?: string;
    isComingFromRecentSearch?:boolean,
    createdOn?: number,
    isAggregatedSection?: boolean,
    searchAttributeNames?: string[],
    configuredFrom?: string,
    subCategories?: any[],
    isSubCategory?: boolean,
    isSingleSelect?: boolean,
    selectedFacetInEng?: string,
    categoryNameInEng?: string,
    isSavedFilter?: boolean,
    savedFilterName?: string,
}

export enum UserSavedSearchToolNames{
  "CONTACT",
  "CONSENT",
  "ACCOUNT",
  "RESOURCE",
  "PRESENTATION",
  "EMAIL_TEMPLATE",
  "CONTENT",
  "MY_CALL_PLANS",
  "TEAM_CALL_PLANS",
  "MY_TIME_OFF",
  "TEAM_TIME_OFF",
  "MY_COACHING",
  "TEAM_COACHING"
}

export enum SuggestionPillType{
  "CATEGORY_VALUESEARCH" = 548910000,
  "ENTITY_LEVEL_CHARACTERSEARCH" = 548910001,
  "CATEGORY_CHARACTERSEARCH" = 548910002,
  "BOOLEAN_FIELD" = 548910003,
  "CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH" = 548910004,
}

export enum UserSavedSearchTypes{
  "OWNED" = 548910000,
  "SHARED" = 548910001
}

export enum UserSearchToolnames{
  CONTACT = "Contacts & Consents",
  ACCOUNTS= "Accounts",
  RESOURCES= "Resources",
  PRESENTATIONS= "Presentations",
  EMAIL_TEMPLATES = "Email Templates",
  CONTENTS = "Contents",
  TIME_OFF = "My Time Off & Team Time Off",
  MY_CALL_PLANS = "Call Plans",
  TEAM_CALL_PLANS = "Team Call Plans",
  MY_COACHING = "My Coaching",
  TEAM_COACHING = "Team Coaching"
}

export interface UserSearchOfflineDTO{
  searchID:string,
  searchName : string,
  searchType : UserSavedSearchTypes,
  searchToolName: string
  pendingSync?: boolean,
  isDeleted?: boolean,
  offlineID : string,
  createdon:number,
  categoryValuePairs: SelectedSuggestionPillDataModel[],
  modifiedon? : string,
  createdby? : string,
  createdbyValue? : string
}

export interface UserSearchServiceDTO{
  indskr_name : string,
  indskr_usersearchtype : number,
  indskr_externalid : string,
  indskr_searchtool: string,
  createdon?: number,
  usersearchparameter: {
      indskr_category: string,
      indskr_name: string,
      indskr_datatype: SuggestionPillType,
			createdon? : number
    }[],
  modifiedon? : string,
  createdby? : string,
  createdbyValue? : string
}
