import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OTPublisherComponent } from './ot-publisher.component';
import { OTSubscriberComponent } from './ot-subscriber.component';

@NgModule({
  declarations: [OTSubscriberComponent, OTPublisherComponent],
  exports: [OTSubscriberComponent, OTPublisherComponent],
  imports: [
    CommonModule,
    TranslateModule, // translate Module
    FormsModule,
    IonicModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
})
export class OpentokModule {
  constructor() {
    console.log('module');
  }
}
