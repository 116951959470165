<!-- Generated template for the TimeOffListComponent component -->
<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="timeOffSearchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput($event.detail)" (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)"></ind-header-left>

    <ind-key-controls-area id="allTimeOff" [viewData]="listKeyControlAreaModel"></ind-key-controls-area>
    <!-- selected serach suggestions area -->
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
        <ion-icon name="ios-close-circle" (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
        <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
      {{suggestion.selectedFacet}}
      <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)" />
    </span>
    </div>

    <!-- Segment Tabs -->
    <ion-toolbar no-padding>
        <ind-tabs [data]="tabsData" [(selectedTab)]="timeOffActivityModel" (selectedTabChange)="checkActivityModel($event)">
        </ind-tabs>
    </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft': (!searching &&
    ((timeOffActivityModel == 'myRequests' && (timeOffService.displayNoTimeOffActivity || totList?.length === 0))
    || (timeOffActivityModel == 'teamRequests' && (timeOffService.displayNoTeamTimeOffActivity || teamTotList?.length === 0))))}">
    <div [ngSwitch]="timeOffActivityModel" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">

        <!-- My requests tab -->
        <div *ngSwitchCase="'myRequests'">
            <ng-container *ngIf="!searching">
                <ind-section-header [viewData]="allTimeOffSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ion-item-group>
                    <div *ngIf="totList?.length == 0 && !searching" class="no-data-message">
                        {{ 'TIMEOFF_NO_TIME_OFF_ACTIVITIES' | translate}}
                    </div>
                    <ion-list *ngIf="!timeOffService.displayNoTimeOffActivity">
                        <div *ngFor="let timeOff of totList">
                            <main-card [viewData]='getTimeOffMainCardModel(timeOff)'></main-card>
                        </div>
                    </ion-list>
                </ion-item-group>
            </ng-container>
            <ng-container *ngIf="searching">
                <ind-section-header [viewData]="resultsTimeOffSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ion-item-group>
                    <ind-no-search-result *ngIf="filteredList?.length === 0 && !timeOffService.displayNoTeamTimeOffActivity && searching" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                    <ion-list *ngIf="!timeOffService.displayNoTimeOffActivity">
                        <div *ngFor="let timeOff of filteredList">
                            <main-card [viewData]='getTimeOffMainCardModel(timeOff)'></main-card>
                        </div>
                    </ion-list>
                </ion-item-group>
            </ng-container>
        </div>

        <!-- Team Requests Tab  -->
        <div *ngSwitchCase="'teamRequests'">
            <ng-container *ngIf="!searching">
                <ind-section-header [viewData]="allTeamTimeOffSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ion-item-group>
                    <div *ngIf="teamTotList?.length == 0 && !timeOffSearchText" class="no-data-message" [hidden]="searching">
                        {{ (device.isOffline?'TEAM_TIMEOFFS_ONLINE_ONLY':'NO_TEAM_TIME_OFF_ACTIVITIES') | translate}}
                    </div>
                    <ion-list *ngIf="!timeOffService.displayNoTeamTimeOffActivity">
                        <div *ngFor="let timeOff of teamTotList">
                            <main-card [viewData]='getTimeOffMainCardModel(timeOff)'></main-card>
                        </div>
                    </ion-list>
                </ion-item-group>
            </ng-container>
            <ng-container *ngIf="searching">
                <ind-section-header [viewData]="resultsTimeOffSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ion-item-group>
                    <ind-no-search-result *ngIf="filteredList?.length === 0 && !timeOffService.displayNoTeamTimeOffActivity && searching" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                    <ion-list *ngIf="!timeOffService.displayNoTeamTimeOffActivity">
                        <div *ngFor="let timeOff of filteredList">
                            <main-card [viewData]='getTimeOffMainCardModel(timeOff)'></main-card>
                        </div>
                    </ion-list>
                </ion-item-group>
            </ng-container>
        </div>
    </div>
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'Timeoff'"></footer-toolbar>
<!-- </ion-footer> -->

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)" [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive' (selectSavedSearch)="handleSavedSearchSelection($event)">
</search-suggestion-popover>
