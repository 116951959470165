import { ReportDataManagementService } from './../../../services/reports/report-data-management.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseMeasureData } from '../../../../interfaces/edge-analytics/report.interface';
import { DeltaService } from '../../../data-services/delta/delta.service';
import { MeasureIcon, MeasureType } from '../../../enums/edge-analytics/edge-analytics.enum';
import { IndTabsDataModel } from '../../../models/ind-tabs-data-model';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { ActivityService } from '../../../services/activity/activity.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ReportService } from '../../../services/reports/report.service';
import { UIService } from '../../../services/ui/ui.service';
import { unsubscribeSubscriptionArray } from '../../../utility/common.utility';
import { EdgeAnalyticsDetailComponent } from '../edge-analytics-detail/edge-analytics-detail';
import { EventsService } from '../../../services/events/events.service';
import { NothingSelectedView } from "../../shared/nothing-selected-view/nothing-selected-view";
import { FooterService } from '@omni/services/footer/footer.service';
import { MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import { OrderActivityDataService } from '@omni/data-services/order-activity/order-activity.data.service';

@Component({
  selector: 'edge-analytics-list',
  templateUrl: 'edge-analytics-list.html',
  styleUrls: ['edge-analytics-list.scss']
})
export class EdgeAnalyticsListComponent implements OnInit, OnDestroy {
  private isPageInitDone = false;
  private subs: Subscription[] = [];
  private cardTextChangeSubs: Subscription[] = [];
  private clickHandler = (id: MeasureType, event, specificTarget) => {
    this.openSelectedMeasureDetail(id);
  }
  private initMeasureCards = (measures: Map<MeasureType, any>) => {
    // No state management for now
    // Pop detail page if there was any pushed before
    if (this.isDetailPagePushed() && !this.isPageInitDone) {
      this.navService.popChildNavPageWithPageTracking();
      this.reportDataMgmService.unSubAll();
      this.reportDataMgmService.setSelectedMeasure(null);
    }

    if (this.cardTextChangeSubs.length > 0) {
      unsubscribeSubscriptionArray(this.cardTextChangeSubs);
      this.cardTextChangeSubs = [];
    }

    // Let's just recreate
    this.measureCards = [];
    measures.forEach((measure: BaseMeasureData, measureType: MeasureType) => {
      // Reset date & measure filters
      measure.langCode = this.translate.currentLang;
      this.reportService.resetFilters(measure);

      // Create a card
      this.measureCards.push({
        id: measure.measureType,
        isSelected: measure.measureType === this.reportDataMgmService.getSelectedMeasure(),
        primaryTextRight: this.getMeasureCardTitle(measureType),
        primaryTextLeft: this.getMeasureCardSubTxt(measure),
        isDataLoading$: measure.listCardSubtextLoading$ ?? undefined,
        showIcon: true,
        iconName: MeasureIcon[measureType],
        clickHandler: this.clickHandler,
        order: measure.measureType === MeasureType.salesOrder ? 0 : 1,
      });

      this.measureCards.sort((a, b) => {
        return a.order - b.order;
      });

      if (measure.listCardSubtextValue$) {
        this.cardTextChangeSubs.push(measure.listCardSubtextValue$.asObservable().subscribe(value => {
          const card = this.measureCards.find(c => c.id === measure.measureType);
          if (card) {
            // TODO: Update other measures to adopt new card sub text structure
            card.primaryTextLeft = this.getMeasureCardSubTxt(measure);
          }
        }));
      }
    });
    this.isPageInitDone = true;
  };

  sectionHeaderModel: IndSectionHeaderViewDataModel;
  searchSectionHeaderModel: IndSectionHeaderViewDataModel;
  measureCards: MainCardViewDataModel[] = [];
  searchedMeasureCards: MainCardViewDataModel[] = [];
  searchText: string = '';
  tabs: IndTabsDataModel[] = [];
  isAndroid: boolean = false;
  public backgroundUploadInProgress: boolean = false;
  syncInProgress: boolean = false;

  constructor(
    public uiService: UIService,
    public deltaService: DeltaService,
    public activityService: ActivityService,
    public authenticationOfflineService: AuthenticationService,
    private translate: TranslateService,
    public device: DeviceService,
    private navService: NavigationService,
    private reportService: ReportService,
    private reportDataMgmService: ReportDataManagementService,
    private trackService: TrackService,
    public eventsService: EventsService,
    public footerService: FooterService,
    private _cd: ChangeDetectorRef,
    public assistantService: MyAssistantService,
  ) {
    reportDataMgmService.resetLoadingCounter();
    reportDataMgmService.requestLocalDataRefresh(MeasureType.procedure);
  }

  ngOnInit() {
    this.tabs = this.uiService.getAgendaTabs();
    this.eventsService.observe("sync:completed")
    .subscribe(() => {
      this.tabs = this.uiService.getAgendaTabs();
    });
    this.subs.push(this.reportDataMgmService.measures$.subscribe(this.initMeasureCards));
    this.subs.push(this.reportDataMgmService.selectedMeasure$.subscribe(selected => {
      if (selected === null) {
        // Closed the detail page
        const curSelectedMeasureCard = this.measureCards.find(c => c.isSelected);
        if (curSelectedMeasureCard) {
          curSelectedMeasureCard.isSelected = false;
        }
      }
    }));
    this.subs.push(this.device.isOfflineObservable.subscribe(isOffline => {
      this.tabs = this.uiService.getAgendaTabs();
    }))

    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    this.isAndroid = this.device.isAndroid();
    this.setSectionHeader();
    this.resetView();
    this.device.isBackgroundUploadInProgressObservable.subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });
    this.translate.onLangChange.subscribe(data =>{
      this.tabs = this.uiService.getAgendaTabs();
      this.setSectionHeader();
      this.reportDataMgmService.measures$.subscribe(this.initMeasureCards);
    });
    this.device.syncInProgress$.subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this._cd.detectChanges();
    });
  }
  ngOnDestroy() {
    unsubscribeSubscriptionArray(this.subs);
    unsubscribeSubscriptionArray(this.cardTextChangeSubs);
    if (this.isDetailPagePushed()) {
      this.navService.popChildNavPageWithPageTracking();
    }
    this.reportDataMgmService.unSubAll();
    this.reportDataMgmService.setSelectedMeasure(null);

    // Remove measures that use local data within the scope
    this.reportDataMgmService.removeMeasure(MeasureType.procedure);
  }


  private resetView(showRightPane: boolean = false) {
    this.activityService.selectedActivity = null;
    this.activityService.selectedActivityAtHome = null;
    this.uiService.showRightPane = showRightPane;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'ActivitiesPageRightPaneNav';
  }
  private setSectionHeader() {
    this.sectionHeaderModel = {
      id: 'edge-analytics-list-section-header',
      title: this.translate.instant('ALL_MEASURES'),
      controls: []
    };
    this.searchSectionHeaderModel = {
      id: 'edge-analytics-list-search-section-header',
      title: this.getSearchSectionHeaderTitle(),
      controls: []
    };
  }
  private getSearchSectionHeaderTitle(): string {
    return `${this.translate.instant('RESULT')} (${this.searchedMeasureCards.length})`
  }
  private isDetailPagePushed() {
    return (this.navService.getActiveChildNavViewPageName() !== undefined) && (this.navService.getActiveChildNavViewPageName() === PageName.EdgeAnalyticsDetailComponent) ? true : false;
  }
  private getMeasureCardTitle(measureType: MeasureType): string {
    let title = '';

    switch (measureType) {
      case MeasureType.meeting:
        // 'Meetings & Phone Calls'
        title = this.translate.instant('MEETINGS') + ' & ' + this.translate.instant('PHONE_CALLS');
        break;
      case MeasureType.message:
        title = this.translate.instant('MESSAGES');
        break;
      case MeasureType.coaching:
        title = this.translate.instant('ANALYTICS_COACHING_TITLE');
        break;
      case MeasureType.procedure:
        title = this.translate.instant('PROCEDURES');
        break;
      case MeasureType.salesOrder:
        title = this.translate.instant('SALES_DATA');
        break;
      default:
        break;
    }
    return title;
  }
  private getMeasureCardSubTxt(measure: BaseMeasureData): string {
    let txt = '';

    if (measure && measure.measureType) {
      switch (measure.measureType) {
        case MeasureType.meeting:
          txt = this.translate.instant('MEETING_MEASURE_CARD_SUBTEXT', { count: measure.listCardSubtextValue$.getValue() });
          break;
        case MeasureType.message:
          txt = this.translate.instant('MESSAGE_MEASURE_CARD_SUBTEXT', { count: measure.listCardSubtextValue$.getValue() });
          break;
        case MeasureType.coaching:
          txt = this.translate.instant('ANALYTICS_COACHING_MEASURE_CARD_SUBTEXT', { count: measure.listCardSubtextValue$.getValue() });
          break;
        case MeasureType.procedure:
          txt = this.translate.instant('ANALYTICS_PROCEDURE_MEASURE_CARD_SUBTEXT', { count: measure.listCardSubtextValue$.getValue() });
          break;
        default:
          break;
      }
    }
    return txt;
  }
  private openSelectedMeasureDetail(measureType: MeasureType, showRightPane = true) {
    const curSelectedMeasure = this.reportDataMgmService.getSelectedMeasure();
    if (curSelectedMeasure && measureType === curSelectedMeasure) {
      return;
    }
    else if (curSelectedMeasure && measureType !== curSelectedMeasure) {
      const curSelectedMeasureCard = this.measureCards.find(c => c.isSelected);
      if (curSelectedMeasureCard) {
        curSelectedMeasureCard.isSelected = false;
      }
    }

    const newSelectedMeasureCard = this.measureCards.find(c => c.id === measureType);
    if (newSelectedMeasureCard) {
      newSelectedMeasureCard.isSelected = true;
      this.reportDataMgmService.setSelectedMeasure(measureType);

      // Overwrite data updated time to current time as we're using local data for certain types
      if (measureType === MeasureType.procedure) {
        this.reportService.overwriteMeasureUpdateTime();
      }

      this.uiService.showRightPane = showRightPane;
      if (!this.isDetailPagePushed()) {
        this.navService.pushChildNavPageWithPageTracking(EdgeAnalyticsDetailComponent, PageName.EdgeAnalyticsDetailComponent, PageName.ActivitiesPageComponent);
      }
      this.trackMeasureClickEvent(measureType);
    }
  }


  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
    this.uiService.activitiesPageTab = selectedTab;
  }

  private trackMeasureClickEvent(measureType: MeasureType) {
    switch (measureType) {
      case MeasureType.meeting:
        this.trackService.tracking('EdgeMeetingsClick', TrackingEventNames.EDGE_ANALYTICS);
        break;
      case MeasureType.message:
        this.trackService.tracking('EdgeMessagesClick', TrackingEventNames.EDGE_ANALYTICS);
        break;
      case MeasureType.coaching:
        this.trackService.tracking('EdgeCoachingsClick', TrackingEventNames.EDGE_ANALYTICS);
        break;
      case MeasureType.procedure:
        this.trackService.tracking('EdgeProceduresClick', TrackingEventNames.EDGE_ANALYTICS);
        break;
      default:
        break;
    }
  }

  onInput(ev: any) {
    if (ev && ev.target) {
      if (ev.target.value) {
        this.searchedMeasureCards = this.measureCards.filter(card => {
          if (card.primaryTextRight) {
            return (card.primaryTextRight.toLowerCase().includes(this.searchText));
          }
        });
      } else {
        this.searchedMeasureCards = [];
      }
      this.searchSectionHeaderModel.title = this.getSearchSectionHeaderTitle();
    }
  }
}
