import { Component , OnInit , EventEmitter , Output ,Input, AfterViewInit} from '@angular/core';
import {PopoverController , MenuController} from '@ionic/angular';
//import { PopoverComponent } from '../popover/popover';
import { RepServices } from '../../data-services/rep/rep.services';
import { AuthenticationService } from '../../services/authentication.service';
import { LogService } from '../../services/logging/log-service';

/**
 * Generated class for the MenuFooterComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'menu-footer',
  templateUrl: 'menu-footer.html',
  styleUrls:['menu-footer.scss']
})
export class MenuFooterComponent implements OnInit {
  //@Output() selectOptions = new EventEmitter<any>();

  text: string;
  title:string;
  admin:String;
  private tempStatus: string;

  constructor(
    private repService:RepServices,
    private authenticationService: AuthenticationService,
    private popoverCtrl: PopoverController,
    private logService:LogService
  ) {
  }

  async ngOnInit(){
    await this.repService.getCurrentState();
    this.title = this.repService.getCurrentUserState();
    this.tempStatus = this.title;
   //this.title = "TESTIING";
   this.admin = this.authenticationService.user.displayName; // should provide a get mechanism, this is dangerous
  }
  presentPopover(option:UIEvent){
    //emit the event to the menu toggle which might emit another event to app.component
   if(option != undefined){
      this.logService.logInfo("From Menu Footer" , this.title);
      this.logService.logDebug(option);
    }else{
      this.logService.logError("What are you trying to do");
    }
  }

}
