import { MeetingAsset } from './../../classes/field-materials/meeting-asset-mapping';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomerAssetNote } from "@omni/classes/field-materials/asset-notes.class";
import { AssetTransfer } from "@omni/classes/field-materials/asset-transfer.class";
import {
  AccountManagerUser,
  AMConfig,
  CustomerAsset,
  LocationContract
} from "@omni/classes/field-materials/customer-asset.class";
import { DynamicsClientService } from "@omni/data-services/dynamics-client/dynamics-client.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { Endpoints } from "../../../config/endpoints.config";

@Injectable({
  providedIn: 'root'
})
export class FieldMaterialManagementDataService {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private http: HttpClient,
    private dynamics: DynamicsClientService,) {
  }

  public async getCustomerAssets(fetchXml: string): Promise<CustomerAsset[]> {
    return await this.dynamics.executeFetchQuery('msdyn_customerassets', fetchXml);
  }

  public async getAMConfig(fetchXml: string): Promise<AMConfig[]> {
    return await this.dynamics.executeFetchQuery('indskr_ioconfigurations', fetchXml);
  }

  public async getLocationContracts(fetchXml: string): Promise<LocationContract[]> {
    return await this.dynamics.executeFetchQuery('indskr_assetcontracts', fetchXml);
  }

  public async getAccountManagerUsers(fetchXml: string): Promise<AccountManagerUser[]> {
    return await this.dynamics.executeFetchQuery('systemusers', fetchXml);
  }

  public async getAssetTransfers(fetchXml: string): Promise<AssetTransfer[]> {
    return await this.dynamics.executeFetchQuery('indskr_assettransfers', fetchXml);
  }

  public async getLatestAssetTransfers(fetchXml: string): Promise<AssetTransfer[]> {
    return await this.dynamics.executeFetchQueryWithPageNumber('indskr_assettransfers', fetchXml, 1);
  }

  public async updateCustomerAsset(assetId: string, payload: object): Promise<any> {
    let uri = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.UPDATE_CUSTOMER_ASSET;
    uri = uri.replace("{assetId}", assetId);
    return await this.http.patch(uri, payload).toPromise();
  }

  public async getAssetNotes(fetchXml: string): Promise<CustomerAssetNote[]> {
    return await this.dynamics.executeFetchQuery('annotations', fetchXml);
  }

  public async getAssetTransferNotes(fetchXml: string): Promise<any[]> {
    return await this.dynamics.executeFetchQuery('annotations', fetchXml);
  }

  public async deleteNotes(noteId: string) {
    const headers = Endpoints.headers.content_type.json;
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.DELETE_NOTES;
    url = url.replace('{noteId}', noteId);
    return await this.http.delete(url).toPromise();
  }
  public async uploadAssetNotesOnline(payload): Promise<any>{
    const headers = Endpoints.headers.content_type.json;
    const url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.CREATE_ASSET_NOTES;
    return await this.http.post(url, payload, headers).toPromise();
  }

  public async uploadTransferNotesOnline(payload) {
    const headers = Endpoints.headers.content_type.json;
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.UPLOAD_CONTACT_NOTES;
    return await this.http.post(url, payload, headers).toPromise();
  }

  public async updateNotesOnline(payload, deleted) {
    const headers = Endpoints.headers.content_type.json;
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.UPDATE_NOTES;
    url = url.replace("{noteId}", payload.noteid);
    if (deleted) return await this.http.delete(url, headers).toPromise();
    return await this.http.patch(url, payload, headers).toPromise();
  }

  public async updateAssetTrasferStatus(payload, transferId) {
    let headers = new HttpHeaders();
    headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.UPDATE_TRANSFER_REQUEST_STATUS;
    url = url.replace("{assetTransferId}", transferId);
    return await this.http.patch(url, payload, { headers }).toPromise();
  }

  public async createTransferRequest(payload, assetId) {
    let headers = new HttpHeaders();
    headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.CREATE_TRANSFER_REQUEST;
    url = url.replace("{assetId}", assetId);
    return await this.http.post(url, payload, { headers }).toPromise();
  }

  public async cancelAssetDeployment(payload, assetId) {
    let uri = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAsset.CANCEL_CUSTOMER_ASSET_DEPLOYMENT;
    uri = uri.replace("{assetId}", assetId);
    return await this.http.patch(uri, payload).toPromise();
  }

  public async getMeetingAssets(fetchXml: string): Promise<MeetingAsset[]> {
    return await this.dynamics.executeFetchQuery('indskr_activitycustomerassets', fetchXml);
  }

  public async mapMeetingAssets(payload, activityId): Promise<MeetingAsset[]> {
    let uri = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.MAP_CUSTOMER_ASSETS_TO_MEETING;
    uri = uri.replace("{activityId}", activityId);
    return await this.http.put<MeetingAsset[]>(uri, payload).toPromise();
  }

}
