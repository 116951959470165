<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list>
    <ind-section-header [viewData]='{id:"store-check-details",title: translate.instant("DETAILS")}'>
    </ind-section-header>
    <ind-form-field [viewData]="subjectFormField"></ind-form-field>
    <ion-row>
      <ion-col size="6">
        <ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form>
      </ion-col>
      <ion-col size="6">
        <ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form>
      </ion-col>
    </ion-row>
    <ind-form-field [viewData]="accountFormField"></ind-form-field>
    <ind-form-field [viewData]="categoryFormField"></ind-form-field>
    <ng-container *ngIf="isPromoCheckFAEnable">
      <ind-section-header [viewData]="promoCheckSectionHeader"
        (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <div class="photo-container">
        <div *ngIf="photoAttachments?.length > 0" class="table">
          <div class="table-header">
            <div class="col">{{'IMAGE' | translate}}</div>
            <div class="col text-ellipsis">{{'PRODUCTS_ASSOCIATED' | translate}}</div>
            <div class="col text-ellipsis">{{'DATE' | translate}}</div>
            <div class="col"></div>
          </div>
          <div *ngFor="let pt of photoAttachments">
            <div class="table-row">
              <div class="col photo-card" (click)="openPhotoViewer(pt)">
                <img class="thumbnail" io-thumbnail [src]="pt.photos[0].indskr_photourl" />
                <div class="count-badge" *ngIf="pt.photos.length > 1"> {{pt.photos.length}}</div>
              </div>
              <div class="col text-ellipsis">{{pt.productString}}</div>
              <div class="col text-ellipsis">{{pt.formattedDate}}</div>
              <div class="col">
                <img tappable class="delete-icon" src="assets/imgs/delete_photo.svg" width="24" [ngClass]="{'disabled': device.isOffline || activityService.selectedActivity?.isCompleted
                  || this.activityService.selectedActivity?.ownerId != this.authService.user.xSystemUserID}" (click)="removeSelectedPhotos(pt)" />
                <img tappable class="edit-icon" src="assets/imgs/edit_photo.svg" width="24" [ngClass]="{'disabled': device.isOffline || activityService.selectedActivity?.isCompleted
                  || this.activityService.selectedActivity?.ownerId != this.authService.user.xSystemUserID}" (click)="editPhotoAttachments(pt)" />
              </div>
            </div>
            <div class="separationLine"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </ion-list>
</ion-content>
<footer-toolbar #footer *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'storecheck'" (buttonClicked)="onPageTitleControlClick($event)"></footer-toolbar>