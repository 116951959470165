<!-- <ion-header>
    <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
        <ion-buttons slot="start">
            <ion-button (click)="closepage(false)" class="back-btn">
                <ion-icon name="chevron-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">{{ 'CONTACT_INTEREST_INSIGHTS' | translate}} +</ion-title>
        <ion-buttons slot="end" *ngIf="!readOnly">
            <ion-button slot="icon-only" (click)="closepage(true)" [disabled]="!interest.description">{{ 'DONE' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header> -->
<ion-header>
    <ind-page-title [viewData]="insightsDetailPageHeaderModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content class="ion-no-padding" toolbar-md-height>
    <ng-container>
        <ion-list>
            <!-- <ion-item-divider>
          <ion-label> {{ 'XPERIENCES_INTEREST_DETAILS' | translate}} </ion-label>
        </ion-item-divider> -->
            <ind-section-header [viewData]="insightsDetailsHeaderModel"></ind-section-header>

            <ion-item-group>
                <ind-form-field [viewData]="interestDescriptionFormField"></ind-form-field>

                <!-- <ion-item>
                    <ion-label position="fixed">{{ 'DESCRIPTION' | translate}}</ion-label>
                    <ion-label *ngIf="readOnly" [ngClass]="{'disabledLabel no-description-text': readOnly}"> {{ interest.description }}</ion-label>
                    <ion-input *ngIf="!readOnly" class="text-input" placeholder="{{'ENTER_DESCRIPTION' | translate}}" [(ngModel)]="interest.description"></ion-input>
                    <ion-icon *ngIf="!readOnly" slot="end" name="arrow-back" color="indgray" class="light-color"></ion-icon>
                </ion-item> -->
                <ind-form-field [viewData]="interestCategoryFormField"></ind-form-field>

                <!-- <ion-item>
                    <ion-label>{{ 'CATEGORY' | translate}}</ion-label>
                    <div slot="end" [ngClass]="{'disabledLabel': readOnly}" (click)="selectInterestCategory()">
                        <span *ngIf="!interest.selectedCategory" class="right-label placeholder">{{ 'SELECT_CATEGORY' | translate }}</span>
                        <span *ngIf="interest.selectedCategory" [ngClass]="{'disabledLabel': readOnly}" class="right-label">{{ interest.selectedCategory.title }}</span>
                        <ion-icon *ngIf="!readOnly" name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                    </div>
                </ion-item> -->
                <ion-item class="confidence">
                    <div>
                        <ion-label class="form-field-header-label">{{ 'XPERIENCES_INSIGHTS_CONFIDENCE' | translate}}</ion-label>
                        <app-star-rating [rating]="interest.score" (ratingChanged)="updateInterestsConfidence($event)" activeIcon="star-sharp" defaultIcon="star-sharp" activeColor="#FFA736" defaultColor="#c3c3c3" fontSize="32px" maxRating=3 readonly="{{readOnly}}"></app-star-rating>
                    </div>
                </ion-item>
                <ind-form-field *ngIf="readOnly && interest.sources.length > 0" [viewData]="otherSourcesFormField"></ind-form-field>

            </ion-item-group>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar>
<!-- </ion-footer> -->