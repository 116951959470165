<ion-grid>
  <ion-row class="table-head-row">
    <ion-col *ngFor="let cell of (tableData | async)?.tableHeadData?.cells" size="{{cell.size}}" class="{{cell.class}}">
      {{cell.label | translate}}
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content
  [scrollEvents]="true">
  <ion-grid>
    <ion-row *ngFor="let row of (tableData | async)?.tableBodyData" class="table-data-row">
      <ion-col *ngFor="let cell of row.cells" size="{{cell.size}}" class="{{cell.class}}">
        {{cell.label | translate}}
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
