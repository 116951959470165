import { ModalController, PopoverController } from '@ionic/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { Subscription } from 'rxjs';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ChildUsersPageComponent } from '../../child-users-page/child-users-page';
import { RepServices } from '../../../data-services/rep/rep.services';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';

@Component({
  selector: 'email-popover',
  templateUrl: 'email-popover.html',
  styleUrls:['email-popover.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EmailPopoverComponent implements OnInit{

  /* Defines the popover type to be opened */
  type: string;
  /* Used to setting the user selection */
  listSelection: any;
  /* Used to set the array that will be used to display the options */
  optionList: any[] = [];

  selectedEmail: EmailActivity;
  ICaseSubscription: Subscription;
  teamViewEnabled: boolean;
  subType: string;
  searchText ='';
  @Input() value: any;
  emailSelectHeaderModel: IndSectionHeaderViewDataModel;
  emailAllProductHeaderModel: IndSectionHeaderViewDataModel;
  emailResultHeaderModel: IndSectionHeaderViewDataModel;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public contactSearchText: string = '';

  constructor(
    public repService: RepServices,
    public authenticationService: AuthenticationService,
    public device: DeviceService,
    public platform: Platform,
    public emailService: EmailService,
    private readonly navService: NavigationService,
    public productService: BrandOfflineService,
    private _cd: ChangeDetectorRef,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public translate: TranslateService,
  ) {
    this.teamViewEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAMVIEW_FILTER);
  }

  ngOnInit() {
    this.popupInitHandler(this.value.field);
    this.selectedEmail = this.emailService.selectedActivity;
    /* Preselcting product in modal */
    this.listSelection = this.selectedEmail.emailProduct;

    this.initSectionHeader();
    this.initProductsHeaderLeft();
  }

  initSectionHeader() {
    this.emailSelectHeaderModel = {
      id:'selected-header',
      title: this.translate.instant('SELECTED') + " " + "(1)",
      controls: []
    };

    this.emailAllProductHeaderModel = {
      id:'all-header',
      title: this.translate.instant('ALL_PRODUCTS_CAP') + " " + "(" + String(this.productSize) + ")",
      controls: []
    };

    this.emailResultHeaderModel = {
      id:'result-header',
      title: this.translate.instant('RESULTS_CAP') + " " + "(" + String(this.productSize) + ")",
      controls: []
    };
  }

  private initProductsHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      imgSrc: 'assets/imgs/header_cancel.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: false,
      align: "left",
    },{
      id: "done",
      imgSrc: 'assets/imgs/header_complete.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: this.isProductSelectionDoneDisabled,
      align: "right",
    });

    this.indHeaderLeftModel = {
      id: 'product-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('PRODUCTS'),
      mode: true,
      controls: buttons,
    };
  }

  public get isProductSelectionDoneDisabled(): boolean {
    let flag = false;
    if(this.listSelection === this.selectedEmail.emailProduct){
      flag = true;
    }
    return flag;
  }

  public onCompletion(isDone: boolean) {
    this.modalCtrl.dismiss({'selection': this.listSelection, 'isDone': isDone});
  }

  onPageTitleControlClick(id){
    if(id==='close'){
      this.onCompletion(false);
    }
    if(id==='done'){
      this.onCompletion(true);
    }
  }

  private popupInitHandler(type) {
    this.optionList = this.emailService.emailProductSKU.filter((product) => product.isCompetitorProduct != "true");
  }

  onOptionSelection(event) {
    console.log(event);
    this._cd.detectChanges();
    this.listSelection = event;
    this._cd.detectChanges();
    this._cd.markForCheck();
    this.initProductsHeaderLeft();
  }

  unselect(){
    console.log(event);
    this._cd.detectChanges();
    this.listSelection = null;
    this._cd.detectChanges();
    this._cd.markForCheck();
    this.initProductsHeaderLeft();
  }

  onSearch() {
    const e = String(this.searchText).toLowerCase();
    if(e.trim() !== ''){
      try {

        let temp: any[] = [];
        this.emailService.emailProductSKU.forEach(p => {
          if (p.isCompetitorProduct != "true") {
            /* Tetsing if product name or the sku in the current product contains the entered text */
            if (String(p.name).toLowerCase().includes(e) || p.skus.some(s => String(s.name).toLowerCase().includes(e))) {
              let prod = Object.assign({}, p);
              prod.skus = [];
              temp.push(prod);
            }
          }
        });
        this.optionList =  temp;
      } catch (error) {
        this.popupInitHandler(this.type);
      }
    }
    else {
      this.popupInitHandler(this.type);
      this.initSectionHeader()
      return;
    }
    this.initSectionHeader()
  }

  get productSize(): number {
    let l = 0;
    l = this.optionList.length;
    this.optionList.forEach(p => {
      if(p.hasOwnProperty('skus') && Array.isArray(p['skus'])){
        l += p['skus'].length;
      }
    });
    return l;
  }

}
