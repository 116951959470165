import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, IonInput } from '@ionic/angular';

@Component({
  selector: 'ind-text-input-popup',
  templateUrl: 'ind-text-input-popup.html',
  styleUrls: ['ind-text-input-popup.scss'],
})
export class IndTextInputPopupComponent implements OnInit {
  @Input() viewData: IIndTextInputPopoverViewData;
  @Input() inputFieldAttributes: IonInput;
  noChanges: boolean = true;
  inputValue: number | string | null | undefined = '';
  initialValue: number | string | null | undefined = '';
  private isNumber: boolean = false;
  private min: number;
  private max: number;

  constructor(
    private popoverCtrl: PopoverController,
  ) {}

  ngOnInit() {
    this.initialValue = this.inputFieldAttributes?.value;
    this.inputValue = this.inputFieldAttributes?.value;
    this.isNumber = this.inputFieldAttributes?.type === 'number';
    this.min = (this.inputFieldAttributes?.min) !== undefined ? Number(this.inputFieldAttributes.min) : undefined;
    this.max = this.inputFieldAttributes.max !== undefined ? Number(this.inputFieldAttributes.max) : undefined;
  }

  onChange(event: CustomEvent) {
    if (this.isNumber) {
      const newValue = event?.detail?.value;
      if (
        newValue !== ''
        && newValue !== undefined
        && newValue !== null
      ) {
        // Number range check
        const newValueNumber = Number(newValue);
        if (this.min !== undefined && newValueNumber < this.min) {
          this.inputValue = '' + this.min;
        } else if (this.max !== undefined && newValueNumber > this.max) {
          this.inputValue = '' + this.max;
        }
      }
    }

    this.dirtyCheck();
  }

  onCancelClick() {
    this.popoverCtrl.dismiss({
      isDone: false,
    });
  }

  onDoneClick() {
    this.popoverCtrl.dismiss({
      isDone: true,
      value: this.inputValue,
    });
  }

  private dirtyCheck() {
    if (
      (this.initialValue === null || this.initialValue === undefined || this.initialValue === '')
      && (this.inputValue === null || this.inputValue === undefined || this.inputValue === '')
      || this.initialValue === this.inputValue
    ) {
      this.noChanges = true;
    } else {
      this.noChanges = false;
    }
  }
}

export interface IIndTextInputPopoverViewData {
  id?: string;
  label?: string;
  showCancelAndDoneBtn?: boolean;
  cancelButtonText?: string;
  doneButtonText?: string;
}
