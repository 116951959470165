
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable, Injector } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { FeatureActionsMap } from "../classes/authentication/user.class";
import { SESSIONSTACK_TOKEN } from '../../config/endpoints.config';
import { EventName, EventsService } from "./events/events.service";
import { Utility } from "../utility/util";
import { Contact } from '../classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '../config/pouch-db.config';
import { DiskService } from './disk/disk.service';
import { Account } from '../classes/account/account.class';
import { TranslateService } from '@ngx-translate/core';

(window as any).SessionStackKey = (window as any).SessionStackKey || undefined;
(window as any).SessionStack = (window as any).SessionStack || undefined;

declare var SessionStack: { start?: Function, stop?: Function, identify?: Function, getSessionId?: Function, log?: Function, setOnDataCallback?: Function, t?: string, q?: any };
declare var SessionStackKey: string;

@Injectable({
  providedIn: 'root'
})
export class GlobalUtilityService {

  public sync_error: string[] = [];
  public sync_service: string[] = [];

  private sessionStackInitialized: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  constructor(
    private authService: AuthenticationService,
    private events: EventsService,
    private disk: DiskService,
    private injector: Injector
  ) {
    this.events.observe("session-stack:start").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (!this.sessionStackInitialized) return;
        SessionStack && SessionStack.start && SessionStack.start()
      })
    this.events.observe("session-stack:stop").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (!this.sessionStackInitialized) return;
        SessionStack && SessionStack.stop && SessionStack.stop()
      })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private initSessionStack(key: string) {
    if (this.sessionStackInitialized)
      return;
    this.sessionStackInitialized = true;
    SessionStackKey = "SessionStack";
    SessionStack = SessionStack || {
      t: key,
      q: []
    };
    var wrappedMethods = ["start", "stop", "identify", "getSessionId", "log", "setOnDataCallback"];
    var i = 0;
    for (var i = 0; i < wrappedMethods.length; i++) {
      if (!SessionStack[wrappedMethods[i]]) {
        SessionStack[wrappedMethods[i]] = function () {
          SessionStack.q.push([wrappedMethods[i]].concat([].slice.call(arguments, 0)));
        }
      }
    }
    var node = <any>document.createElement("script");
    node.async = 1;
    node.src = "https://cdn.sessionstack.com/sessionstack.js";
    var wafCss = document.getElementsByTagName("script")[0];
    wafCss.parentNode.insertBefore(node, wafCss);
  }

  public setupSessionStack() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ERROR_REPORTING_REPLAY)) {
      this.initSessionStack(SESSIONSTACK_TOKEN)
      // SessionStack && SessionStack.identify({
      //     userId: this.authService.UserID, // Replace the USER-ID with the user id from your app
      //     displayName: this.authService.userConfig.fullName, // Not required
      // });
    } else {
      // SessionStack && SessionStack.stop();
    }

  }

  public chunkString(str, len) {
    let input = str.trim().split(' ');
    let [index, output] = [0, []]
    output[index] = '';
    input.forEach(word => {
      let temp = `${output[index]} ${word}`.trim()
      if (temp.length <= len) {
        output[index] = temp;
      } else {
        index++;
        output[index] = word;
      }
    })
    return output
  }

  public get globalCustomerText(): string {
    let translate = this.injector.get(TranslateService);
    if (Utility.globalCustomerText) {
      return translate.instant(Utility.globalCustomerText);
    }
  }

  public get globalCustomersText(): string {
    let translate = this.injector.get(TranslateService);
    if (Utility.globalCustomersText) {
      return translate.instant(Utility.globalCustomersText);
    }
  }

  /**
   * update interaction date and type for completed meetings, orders, allocation order, sent Message and case intake created
   * @param contact list of contact
   * @param account list of account
   * @param interaction interaction type
   */
  public async updateInteraction(contacts: Contact[] = [], accounts: Account[] = [], interaction: string): Promise<void> {
    try {
      if (contacts && contacts.length > 0) {
        contacts.forEach(con => { this.updateInteractionContact(con, interaction).then() });
      }
      if (accounts && accounts.length > 0) {
        accounts.forEach(acc => {/*await*/ this.updateInteractionAccount(acc, interaction).then() });
      }
    } catch (error) {
      console.error('interaction update error: ', error);
    }
  }
  /**
   * for updating account with interaction type and date. It is updating local db and session account
   * @param account account
   */
  public async updateInteractionAccount(account: Account, interaction: string): Promise<void> {
    try {
      if (account) {
        let dbAccounts = [];
        let rawAccountDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);
        if (rawAccountDoc && rawAccountDoc.raw && Array.isArray(rawAccountDoc.raw)) {
          dbAccounts = rawAccountDoc.raw;
        }
        //let sessionAccount ;//= this.accountService.getAccountById(account.id);
        account.interactionType = interaction;
        account.interactionDate = new Date().getTime();
        let dbIndx = dbAccounts.findIndex(ac => ac.accountid === account.id);
        if (dbIndx >= 0) {
          let dbAccount = dbAccounts[dbIndx];
          dbAccount['interactionType'] = account.interactionType;
          dbAccount['interactionDate'] = account.interactionDate;
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT, doc => ({ raw: dbAccounts })).catch(error => {
            console.error('updateInteractionAccount updateOrInsert for account : ', error);
          });
          this.events.publish(EventName.ACCOUNTUPDATEINTERACTION, new Account(dbAccount));
        }
      }
    } catch (error) {
      console.error('updateInteractionAccount : ', error);
    }
  }
  /**
   * for updating contact with interaction type and date. It is updating local db and session contact
   * @param contact contact
   */
  public async updateInteractionContact(contact: Contact, interaction: string): Promise<void> {
    try {
      if (contact) {
        let dbContacts = [];
        let rawContactsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);
        if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
          dbContacts = rawContactsDoc.raw;
        }
        //let sessionContact //= this.contactService.getContactByID(contact.ID);
        contact.interactionDate = new Date().getTime();
        contact.interactionType = interaction;
        const dbIndx = dbContacts.findIndex(c => c.contactid === contact.ID);
        if (dbIndx >= 0) {
          let dbContact = dbContacts[dbIndx];
          dbContact['interactionType'] = contact.interactionType;
          dbContact['interactionDate'] = contact.interactionDate;
          await this.disk.saveContactsToDB(dbContacts);
        }
      }
    } catch (error) {
      console.error('updateInteractionContact : ', error);
    }
  }

}
