import { Directive, HostListener, ElementRef, Renderer2, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';

/**
 * Generated class for the ProductKeyClickedDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[test]' // Attribute selector
})
export class ProductKeyClickedDirective implements OnInit{

  constructor(private el :ElementRef , private renderer: Renderer2)  {
    //let element = this.viewContainer.createEmbeddedView(this.templateRef).rootNodes[0];
    console.log(el);
    //this.renderer.listen(el , 'click' , this.onClick);
  }
  // tslint:disable-next-line:prefer-function-over-method
  ngOnInit(){
    console.log("Directive is active");
  }
  onClick=(event:Event)=>{
    console.log(event);
    if(this.el.nativeElement.contains(event.target)){

    }else{
      console.log("native element  DOM");
    }
  }

}
