<ion-header>
  <ind-page-title [viewData]="userInventoryDetailPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ion-list class='details-list'>
      <ind-section-header [viewData]='generaSectionTitle'></ind-section-header>
      <ind-form-field [viewData]="qtyReceivedField"></ind-form-field>
      <ind-form-field [viewData]="qtyDroppedField"></ind-form-field>
      <ind-form-field [viewData]="qtyAdjustedField"></ind-form-field>
      <ind-form-field [viewData]="qtyTransferredField"></ind-form-field>
      <ind-form-field [viewData]="qtyRemainingField"></ind-form-field>
  </ion-list>
</ion-content>