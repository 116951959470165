import { UIService } from '@omni/services/ui/ui.service';
import { Injectable, EventEmitter } from "@angular/core";
import { PopoverController, LoadingController,  ToastController } from "@ionic/angular";
import { Events } from '@omni/events';
import {MyAssistantService, NOTIFICATION} from "../my-assistant/my-assistant.service";
import { InboundMeetingDataService } from "../../data-services/meeting/inbound-meeting.data.service";
import { ActivityService } from "../activity/activity.service";
import { ActivityDataService } from "../../data-services/activity/activity.service";
import { AlertService } from "../alert/alert.service";
import { NavigationService } from "../navigation/navigation.service";
import { InboundMeetingRequestWebsocketPayload, InboundMeetingRequestDataForPopup } from "../../models/inbound-meeing-request-data-model";
import { InboundPopoverComponent } from "../../components/popover/inbound-popover/inbound-popover";
import { AppointmentActivity } from "../../classes/activity/appointment.activity.class";
import { HttpErrorResponse } from "@angular/common/http";
import { format } from "date-fns";
import { InboundMeetingService, REQUEST_STREAM_ACTIONS } from "./inbound-meeting.service";
import { Subscription } from "rxjs";
import { RepServices } from "../../data-services/rep/rep.services";
import { REP_STATUS } from "../../models/rep-status-model";
import { TranslateService } from "@ngx-translate/core";
import {DateTimeFormatsService} from "../date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from "../notification/notification.service";
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";

@Injectable({
  providedIn: 'root'
})
export class InboundMeetingControllerService {
    inboundMeetingRequestPopover: HTMLIonPopoverElement;
    popoverEv$: Subscription;
    inboundMeetingRequestToast: HTMLIonToastElement;
    tempToast: HTMLIonToastElement;
    private _loading: HTMLIonLoadingElement;
    private _requestCount = 0;
    private _curRequest: InboundMeetingRequestDataForPopup;
    private _inboundMeetingRequestArray$: Subscription = this.inboundMeetingService.inboundMeetingRequestArrayObservable.subscribe(
        (requestArray: InboundMeetingRequestDataForPopup[]) => {
            this._requestCount = requestArray.length;
            this._curRequest = this._requestCount > 0 ? requestArray[0] : undefined;
        }
    );
    private  _inboundMeetingRequest$: Subscription = this.inboundMeetingService.inboundMeetingRequestChangeObservable.subscribe(
        async (changedData: { request: InboundMeetingRequestDataForPopup, action: REQUEST_STREAM_ACTIONS }) => {
            if (changedData) {
                if (changedData.action === REQUEST_STREAM_ACTIONS.QUEUED) {
                    if (this.repService.getCurrentUserState() === REP_STATUS.AVAILABLE.userState) {
                        await this.createOrUpdatePopover(changedData.request);
                    } else if (this.repService.getCurrentUserState() === REP_STATUS.STANDBY.userState) {
                        await this.createOrUpdateToast(changedData.request);
                    } else {
                        console.warn('_inboundMeetingRequest$: wrong user status', this.repService.getCurrentUserState());
                    }
                } else if (changedData.action === REQUEST_STREAM_ACTIONS.ACCEPTED) {
                    if (this.inboundMeetingRequestPopover) {
                        this.inboundMeetingRequestPopover.dismiss();
                        this.inboundMeetingRequestPopover = null;
                    }
                } else if (changedData.action === REQUEST_STREAM_ACTIONS.DECLINED || changedData.action === REQUEST_STREAM_ACTIONS.TIMED_OUT) {
                    if (this.inboundMeetingRequestPopover) {
                        if (this._requestCount > 1) {
                            this._createRequestMissedOrDeclinedNotification(changedData.request, changedData.action === REQUEST_STREAM_ACTIONS.DECLINED);
                        } else {
                            if (changedData.action === REQUEST_STREAM_ACTIONS.DECLINED) {
                                this._createRequestMissedOrDeclinedNotification(changedData.request, true);
                            } else {
                                this._createMissedNotificationAndToast(changedData.request);
                            }
                            this.inboundMeetingRequestPopover.dismiss();
                            this.inboundMeetingRequestPopover = null;
                        }
                    } else if (this.inboundMeetingRequestToast) {
                        this._createRequestMissedOrDeclinedNotification(changedData.request);
                        if (this._requestCount >= 2) {
                            this.inboundMeetingRequestToast.message = `Incoming Call from ${this.inboundMeetingService.getFormattedCallerName(this._curRequest)} (${this._requestCount - 1})`;
                        } else if (this._requestCount === 1) {
                            this.inboundMeetingRequestToast.message = `Incoming Call from ${this.inboundMeetingService.getFormattedCallerName(this._curRequest)}`;
                        } else if (this._requestCount === 0 && this.inboundMeetingRequestToast) {
                            this.inboundMeetingRequestToast.dismiss();
                            // this.inboundMeetingRequestToast = null;
                        }
                    } else {
                        console.warn('_inboundMeetingRequest$: No prompt exists for ', changedData);
                    }
                } else if (changedData.action === REQUEST_STREAM_ACTIONS.CANCELED) {
                    if (this._requestCount === 0) {
                        if (this.inboundMeetingRequestPopover) {
                            this.inboundMeetingRequestPopover.dismiss();
                            this.inboundMeetingRequestPopover = null;
                        } else if (this.inboundMeetingRequestToast) {
                            this.inboundMeetingRequestToast.dismiss();
                            // this.inboundMeetingRequestToast = null;
                        } else {
                            console.warn('_inboundMeetingRequest$: No prompt exists for ', changedData);
                        }
                    }
                } else {
                    console.warn('_inboundMeetingRequest$: wrong change detection action..', changedData);
                }
            }
    });
    private _inboundMeetingRequestTimeout$: Subscription = this.inboundMeetingService.inboundMeetingRequestTimeoutObservable.subscribe((timedOutReq: { inboundRequestId: string, timeoutId: number }) => {
        if (timedOutReq) {
            this.inboundMeetingService.dequeueInboundMeetingRequest(timedOutReq.inboundRequestId, REQUEST_STREAM_ACTIONS.TIMED_OUT);
        }
    });
    private inboundNotificationModel: IndNotificationDataModel;

    constructor(
        private popoverCtrl: PopoverController,
        private myAssistantService: MyAssistantService,
        private inboundMeetingDataService: InboundMeetingDataService,
        private inboundMeetingService: InboundMeetingService,
        private activityService: ActivityService,
        private activityDataService: ActivityDataService,
        private navService: NavigationService,
        private loadingCtrl: LoadingController,
        private events: Events,
        private alertService: AlertService,
        private repService: RepServices,
        public translate:TranslateService,
        public dateTimeFormatsService: DateTimeFormatsService,
        private notificationService: NotificationService,
        private toastCtrl: ToastController,
        private readonly uiService: UIService
    ) {}
    private _createMissedNotificationAndToast(data: InboundMeetingRequestDataForPopup) {
        // Create a missed call notification & toast
        this._createRequestMissedOrDeclinedNotification(data);
        this.notificationService.notify(this.translate.instant('INBOUND_YOU_MISSED_CALL_FROM',{text:this.inboundMeetingService.getFormattedCallerName(data)}),'Inbound Service','top',ToastStyle.DANGER);
    }
    private _createRequestMissedOrDeclinedNotification(data: InboundMeetingRequestDataForPopup, wasItDeclineAction = false) {
        const today = new Date();
        const actionString = wasItDeclineAction ? 'Declined' : 'Missed';
      this.inboundNotificationModel = {
        type: NOTIFICATION.MISSED_CALL,
        name: this.translate.instant("MISSED_CALL", {callType: actionString, customerName: this.inboundMeetingService.getFormattedCallerName(data)}),
        DateTime: Date.now(),
        id: NOTIFICATION.MISSED_CALL + Date.now(),
        data: data,
        icon: 'assets/imgs/phone-call-activity.svg',
        isRed: false,
        params: {callType: actionString, customerName: this.inboundMeetingService.getFormattedCallerName(data)}
      };
      this.myAssistantService.saveNotificationToDisk(this.inboundNotificationModel);
        /*this.myAssistantService.addNewNotification(
            this.translate.instant('YOU_CAL_FROM_CALLERNAME_FORMATDATE',{actionString:actionString, CallerName:this.inboundMeetingService.getFormattedCallerName(data),formatdate:format(today, this.dateTimeFormatsService.dateTimeToUpper)})
        );*/
    }

    private async _acceptInboundMeetingRequest() {
        // Start loading
        if (!this._loading) {
            this._loading = await this.loadingCtrl.create();
            this._loading.onDidDismiss().then(() => {
                this._loading = null;
            });
            this._loading.present();
        }

        this.inboundMeetingDataService.acceptInboundRequest(this._curRequest.inboundRequestId)
        .then(async (rawActivity: any) => {
            // prepare and enter the meeting
            await this._addInboundMeetingToDbAndService(rawActivity);
            this.stopLoading();
            this.navService.popToRootWithPageTracking().then(() => {
                this.activityService.inboundMeetingFlagForActivitiesPane = true;
            });
        })
        .catch((err: HttpErrorResponse) => {
            console.warn('_acceptInboundMeetingRequest: Inbound Accept Error: ', err);
            if (err && err.error) {
                this.alertService.alert(
                    'Error',
                    err.error.errorMessage ? err.error.errorMessage : 'Please contact <strong>admin/support</strong>. Refrenece ID: ' + err.error.errorId,
                    ['OK']
                );
            }

            this.stopLoading();
        });
    }
    private async _addInboundMeetingToDbAndService(rawActivityResponse) {
        // Add to the activity service
        let inboundAppointmentActivity = new AppointmentActivity(rawActivityResponse);
        // Since service doesn't send 'statecode', we set it manually here because it will always be an 'Open' state.
        inboundAppointmentActivity.state = 0;
        this.activityService.selectedActivity = inboundAppointmentActivity;
        this.activityService.addActivity(inboundAppointmentActivity);
        await this.activityDataService._appendMeetingDetailsToActivity(inboundAppointmentActivity, rawActivityResponse, true, true);
        // Reset display activities
        this.activityService.filterActivities(this.activityService.activityFilter);
        // Refresh UI
        if (!this.uiService.toolsActivityActive){
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
    }

    private _declineCurrentRequest() {
        this.inboundMeetingDataService.declineInboundRequest(this._curRequest.inboundRequestId)
        .then(() => {
            // Stop loading
            this.stopLoading();
        })
        .catch((err: any) => {
            console.error('_declineCurrentRequest: ', err);
            // Stop loading
            this.stopLoading();
        });
        this.inboundMeetingService.dequeueInboundMeetingRequest(this._curRequest.inboundRequestId, REQUEST_STREAM_ACTIONS.DECLINED);
    }

    private _unSubPopoverEvEmiiter() {
        if (this.popoverEv$) {
            this.popoverEv$.unsubscribe();
            this.popoverEv$ = null;
        }
    }

    stopLoading() {
        if (this._loading) {
            this._loading.dismiss();
        }
    }

    requestReceived(data: InboundMeetingRequestWebsocketPayload) {
        if (this.repService.getCurrentUserState() === REP_STATUS.AVAILABLE.userState || this.repService.getCurrentUserState() === REP_STATUS.STANDBY.userState) {
            this.inboundMeetingService.queueInboundMeetingRequest(data);
        }
    }

    requestCanceled(inboundRequestId: string) {
        this.inboundMeetingService.cancelTimeout(inboundRequestId);
        this.inboundMeetingService.dequeueInboundMeetingRequest(inboundRequestId, REQUEST_STREAM_ACTIONS.CANCELED);
    }

    async createOrUpdatePopover(data: InboundMeetingRequestDataForPopup) {
        if (!this.inboundMeetingRequestPopover) {
            // Create
            this._unSubPopoverEvEmiiter();
            const popoverEvEmitter = new EventEmitter<any>();
            this.popoverEv$ = popoverEvEmitter.subscribe(async (arg) => {
                // console.log('*&*&** popoever ev: ', arg);
                if (this.inboundMeetingRequestPopover) {
                    if (arg.action === 'DECLINE') {
                        // Stop timeout
                        this.inboundMeetingService.cancelTimeout(this._curRequest.inboundRequestId);
                        this._declineCurrentRequest();
                    } else if (arg.action === 'ACCEPT') {
                        await this._acceptInboundMeetingRequest();
                        this.inboundMeetingService.cancelAllTimeoutAndDeclineOtherRequests();
                    }
                }
            });

            // Create a popover
            this.inboundMeetingRequestPopover = await this.popoverCtrl.create({
                component: InboundPopoverComponent,
                componentProps:{ evEmitter: popoverEvEmitter },
                cssClass: 'inbound-meeting-popover', backdropDismiss: false }
            );
            this.inboundMeetingRequestPopover.onDidDismiss().then(() => {
                this.inboundMeetingRequestPopover = null;
                this._unSubPopoverEvEmiiter();
            });

            // Present
            this.inboundMeetingRequestPopover.present();

            // Start timeout
            this.inboundMeetingService.initiateTimeout(data);
        } else {
            // Update
            this.inboundMeetingService.initiateTimeout(data);
        }
    }

    async createOrUpdateToast(data: InboundMeetingRequestDataForPopup) {
        if (!this.inboundMeetingRequestToast) {
            // Create
            this.inboundMeetingRequestToast =await  this.toastCtrl.create({
                message: this.translate.instant('INCOMING_CALL_FROM_CALLERNAME',{CallerName:this.inboundMeetingService.getFormattedCallerName(this._curRequest)}),
                position: 'top',
                cssClass: 'toast-success inbound-meeting-toast',
                buttons:[
                  {
                    side: 'end',
                    text: this.translate.instant('POP_ACCEPT'),
                    role:'close',
                  }
                ],
                //showCloseButton: true,
                //closeButtonText: this.translate.instant('POP_ACCEPT'),
                color : 'dark',
            });

            this.inboundMeetingRequestToast.onWillDismiss().then(async (role) => {
                this.inboundMeetingRequestToast = null;
                if (role.role === 'close') {
                    // User Accepted
                    await this._acceptInboundMeetingRequest();
                    this.inboundMeetingService.cancelAllTimeoutAndDeclineOtherRequests();
                }
            });

            this.inboundMeetingRequestToast.present();

            // Start timeout
            this.inboundMeetingService.initiateTimeout(data);
        } else {
            // Update
            this.inboundMeetingService.initiateTimeout(data);
            if (this._requestCount >= 2) {
                this.inboundMeetingRequestToast.message = `Incoming Call from ${this.inboundMeetingService.getFormattedCallerName(this._curRequest)} (${this._requestCount - 1})`;
            }
        }
    }


    // Helper fns to do mock testing
    generateMockInboundRequest() {
        const timeout = this._getRandomIntInclusive(5, 15);
        const req: InboundMeetingRequestWebsocketPayload = {
            contactId: 'contact-' + Math.floor(Math.random() * 10),
            filteredRepIds: [],
            timeoutDuration: timeout,
            inboundRequestId: 'inbound-' + Math.floor(Math.random() * 100),
            meetingTopicName: 'Inbound ' + Math.floor(Math.random() * 50),
            firstName: 'Jane ' + Math.floor(Math.random() * 100),
            lastName: 'Doe ' + Math.floor(Math.random() * 100),
            campaignName: 'Campaign ' + Math.floor(Math.random() * 100),
            email: 'Email' + Math.floor(Math.random() * 100) + '@email.com',
            phone: '647-' + Math.floor(Math.random() * 10) + '32-9876'
        }

        console.log('****** timeout in : ' + timeout + 's');
        this.inboundMeetingService.queueInboundMeetingRequest(req);
        return req.inboundRequestId;
    }
    private _getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
