<ion-grid no-padding class="content-page"
  [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row no-padding>
    <ion-col class="survey-left-pane" no-padding>
      <survey-list></survey-list>
    </ion-col>
    <ion-col class="survey-right-pane" no-padding>
      <ion-nav #surveyrightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
