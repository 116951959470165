export class ActivityResourceContact{
    public indskr_contactid:string;
    public indskr_enddate:string;
    public indskr_startdate:string;
    public indskr_pagestartdate:string;
    public indskr_pageenddate:string;
    public indskr_pagesentiment:string;
    public indskr_pagenumber:string;

    constructor(raw:object){
        this.indskr_contactid = raw['indskr_contactid'];
        this.indskr_enddate = raw['indskr_enddate'];
        this.indskr_startdate = raw['indskr_startdate'];
        this.indskr_pagestartdate = raw['indskr_pagestartdate'];
        this.indskr_pageenddate = raw['indskr_pageenddate'];
        this.indskr_pagesentiment = raw['indskr_pagesentiment'];
        this.indskr_pagenumber = raw['indskr_pagenumber'];
    }

    public get DTO():Object{
        return {
            indskr_contactid : this.indskr_contactid,
            indskr_pagestartdate: this.indskr_pagestartdate,
            indskr_pageenddate: this.indskr_pageenddate,
            indskr_pagesentiment: this.indskr_pagesentiment,
            indskr_pagenumber: this.indskr_pagenumber
        }
    }
}