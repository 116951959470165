export enum MessageDimensions {
  templateId = 'templateId',
  contacts = 'contacts',
  channel = 'channel'
}

export enum MessageKPI {
  sentVolume = 'Total Sent',
  customerReach = 'Customer Reach',
  openRate = 'Open Rate',
  clickThroughRate = 'Click-through Rate',
  templateUtilizationVolume = 'Template Utilization Volume',
  templateUtilizationRate = 'Template Utilization Rate',
}

export enum MessageChartId {
  byChannel = 'byChannel',
  trend = 'trend',
}
