import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as LDClient from 'ldclient-js';
import { Endpoints, LAUNCH_DARKLY_CLIENT_ID } from '../../../config/endpoints.config';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import * as _ from 'lodash';

/*
  Generated class for the LaunchDarklyProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyProvider {

  private client: LDClient.LDClient;
  public initialized: boolean = false;
  public featureFlags: LDClient.LDFlagSet;
  private _promise: Promise<void>;

  constructor(
    ) {
      this.initialize();
  }

  private async _initialize() {
    if(localStorage.getItem("region") === "China") return;
    this.client = LDClient.initialize(LAUNCH_DARKLY_CLIENT_ID, {"key":"anonymous", "anonymous": true});
    await this.client.waitUntilReady();
    this.initialized = true;
  }

  public async initialize() {
    if (!this._promise) {
      this._promise = this._initialize();
    }
    return this._promise;
  }

  refreshUser(user:LDClient.LDUser) {
    this.client.identify(user)
  }

  isFeatureEnabled(featureAction: FeatureActionsMap){
    //key = _.kebabCase(featureAction)
    let key = featureAction.toLowerCase().split(" ").join('-');
    return this.client.variation(key, false)
  }

}
