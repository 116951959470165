<ng-template [ngIf]="selectedSAP.contacts.length > 0" [ngIfElse]="noData">
    <ion-searchbar  [(ngModel)]="searchText" placeholder="{{'CONTACTS' | translate}}" debounce="500" (ionInput)="onInput($event.detail)"></ion-searchbar>
    <ion-item-group>
        <ind-section-header  [viewData]='customerHeader'></ind-section-header>
        <ind-no-search-result *ngIf="searchText && contactsList.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        <ion-list>
            <ion-item *ngFor="let contact of contactsList" (click)="openContact(contact)">
                <div>{{contact.fullname || contact.fullName}}</div>
            </ion-item>
        </ion-list>
    </ion-item-group>
    <img *ngIf="!isSearching && contactsList.length === 0" class="os_background-list " [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios, 'os_background-list-android': device.deviceFlags.android}" />

</ng-template>

<ng-template #noData>
  <ng-container *ngIf="!searchText">
    <div class="no-data-message"> {{'NO_ALIGNED_FOR_SLECTED_SCINTIFIC' | translate : {text : globalCustomerText} }}</div>
    <img class="os_background-list " [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios, 'os_background-list-android': device.deviceFlags.android}" />
  </ng-container>
</ng-template>
