import { AppealDataService } from '@omni/data-services/appeal/appeal.data.service';
import { AddressDataService } from './../address/address.data.service';
import { FieldMaterialManagementService } from "@omni/services/field-materials/field-material-management.service";
import { ProcedureTrackerActivityDataService } from './../procedure-tracker-activity/procedure-tracker-activity.data.service';
import { EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { LocalizationService } from './../../services/localization/localization.service';
import { Injectable } from '@angular/core';
import { NextCallObjectiveDataService } from '../../data-services/next-call-objective/next.call.objective.data.service';
import { AllocationShipmentDataService } from '../../data-services/sample/allocation-shipment.data.service';
import { SchedulerDataService } from '../../data-services/scheduler/scheduler.data.service';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { ActivityService } from '../../services/activity/activity.service';
import { SettingsService } from '../../services/app-settings/settings.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { CaseManagementService } from '../../services/case-management/case-management.service';
import { CoachingReportService } from '../../services/coaching/coaching.report.service';
import { ConsentService } from '../../services/consent/consent.service';
import { ContentMatchingService } from '../../services/content-matching/content-matching.service';
import { CustomerEventsService } from '../../services/customer-event/customer-events.service';
import { DeviceService } from '../../services/device/device.service';
import { DynamicFormsService } from '../../services/dynamic-forms/dynamic-forms-service';
import { EmailService } from '../../services/email-templates/email.service';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { MarketScanService } from '../../services/market-scan/market-scan.service';
import { PollService } from '../../services/poll/poll.service';
import { PresentationService } from '../../services/presentation/presentation.service';
import { SampleService } from '../../services/sample/sample.service';
import { SchedulerService } from '../../services/scheduler/scheduler.service';
import { TimeOffService } from '../../services/time-off/time-off.service';
import { XperiencesService } from '../../services/xperiences/xperiences.service';
import { AccountManagementDataService } from '../account-management/account-management.data.service';
import { AccountDataService } from '../accounts/account.data.service';
import { ActivityDataService } from '../activity/activity.service';
import { BrandDataService } from '../brand/brand.data.service';
import { CallPlanDataService } from '../call-plan/call-plan.data.service';
import { CaseManagementDataService } from '../case-management/case-management.data.service';
import { ContactDataService } from '../contact/contact.data.service';
import { EventsToolDataService } from '../event/events-tool.data.service';
import { FollowUpActivityDataService } from '../follow-up-activity/follow-up-activity.data.service';
import { LocationService } from '../location/location.data.service';
import { PresentationDataService } from '../presentation/presentation.data.service';
import { ReportDataService } from '../report/report.data.service';
import { ResourceDataService } from '../resource/resource.data.service';
import { AllocationTransferDataService } from '../sample/allocation-transfer.data.service';
import { SampleDataService } from '../sample/sample.data.service';
import { ScientificActivityDataService } from '../scientific-activity/scientific-activity.data.service';
import { TherapeuticAreaDataService } from '../therapeutic-area/therapeutic-area.data.service';
import { TimeOffDataService } from '../time-off/time-off.data.service';
import { WebsocketDataService } from '../websocket/websocket.data.service';
import { AuthenticationDataService } from './../authentication/authentication.service';
import { OpportunityManagementDataService } from './../opportunity-management/opportunity-management.data.service';
import { OrderActivityDataService } from './../order-activity/order-activity.data.service';
import { SearchConfigDataService } from './../search-config/search-config-data-service';
import { SharedDataService } from './../shared-data/shared.data.service';
import { SecInfoConfigDataService } from '../sec-info-config/sec-info-config-data-service';
import {MdmService} from "@omni/services/mdm/mdm.service";
import { ActivityType } from '@omni/classes/activity/activity.class';
import { DeltaService } from '../delta/delta.service';
import { SurgeryOrderActivityDataService } from '../surgery-order-activity/surgery-order-activity.data.service';
import { ExternallinksDataService } from '../externallink/externallinks.data.service';
import { AllocationAdjustmentDataService } from '../sample/allocation-adjust.data.service';
import { AllocationFeatureService } from './../../services/sample/allocation.feature.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import {MyAssistantService} from "@omni/services/my-assistant/my-assistant.service";
import { MyAssistantDataService } from './../../services/my-assistant/my-assistant.data.service';
import { VeevaWidgetService } from "@omni/services/veeva-widget/veeva-widget.service";
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { AddressService } from '@omni/services/address/address.service';
import { MeetingDataService } from '@omni/data-services/meeting/meeting.data.service';
import { MarketingPlanManagementDataService } from "../marketing-plan/marketing-plan-management.data.service";
import { AllocationInventoryDataService } from "../sample/allocation-inventory.data.service";
import { QuoteToolDataService } from "../quote-tool/quote-tool-data.service";
import { PharmacovigilanceDataService } from '../pharmacovigilance-reporting/pharmacovigilance.data.service';
import { KOLStatusService } from '@omni/services/kol-status/kol-status-service';
import { TerritoryManagementDataService } from '../territory-management/territory-management.data.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { MedicalInsightService } from '@omni/services/medical-insight/medical-insight-service';
import { StoreCheckService } from '@omni/services/store-check/store-check.service';
import { ProcedureContractService } from '../procedure-contract/procedure-contract.service';
import { SetBookingDataService } from '../set-booking/set-booking.data.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
    constructor(
        private readonly accountService: AccountDataService,
        private readonly accountOfflineService: AccountOfflineService,
        private readonly activityService: ActivityDataService,
        private readonly activityOfflineService: ActivityService,
        private readonly brandService: BrandDataService,
        private readonly brandOfflineService: BrandOfflineService,
        private readonly contactService: ContactDataService,
        private readonly presentationService: PresentationDataService,
        private readonly resourceService: ResourceDataService,
        private readonly callPlanService: CallPlanDataService,
        private readonly device: DeviceService,
        private readonly locationService: LocationService,
        private readonly callPlanOfflineService: CallPlanOfflineService,
        private readonly presentationOfflineService: PresentationService,
        private readonly websocketService: WebsocketDataService,
        private readonly timeOffDataService: TimeOffDataService,
        private readonly timeOffService: TimeOffService,
        private readonly schedulerDataService: SchedulerDataService,
        private readonly schedulerService: SchedulerService,
        private readonly sampleDataService: SampleDataService,
        private readonly sampleService: SampleService,
        private readonly allocationShipmentDataService: AllocationShipmentDataService,
        private readonly allocFeatureService: AllocationFeatureService,
        private readonly allocationAdjustmentDataService: AllocationAdjustmentDataService,
        private readonly coachingReportService: CoachingReportService,
        private readonly consentService: ConsentService,
        private readonly utilityService: GlobalUtilityService,
        private readonly emailService: EmailService,
        private readonly contentMatchingService: ContentMatchingService,
        private readonly authenticationService: AuthenticationService,
        private readonly pollService: PollService,
        private readonly accountManagementDataService: AccountManagementDataService,
        private readonly followUpActivityDataService: FollowUpActivityDataService,
        private readonly caseManagementDataService: CaseManagementDataService,
        private readonly therapeuticAreaDataService: TherapeuticAreaDataService,
        private readonly orderActivityDataService: OrderActivityDataService,
        public sharedDataService: SharedDataService,
        private readonly xperiencesService: XperiencesService,
        private readonly caseManagementService: CaseManagementService,
        private readonly customerEventsService: CustomerEventsService,
        private readonly allocTransferDataService: AllocationTransferDataService,
        public authDataService: AuthenticationDataService,
        private readonly sapDataService: ScientificActivityDataService,
        public opportunityService: OpportunityManagementDataService,
        public settingsService: SettingsService,
        public searchConfigDataService: SearchConfigDataService,
        private readonly dynamicsFormService: DynamicFormsService,
        private readonly reportsDataService: ReportDataService,
        private readonly nextCallObjectiveDataService: NextCallObjectiveDataService,
        private readonly marketScanService: MarketScanService,
        private readonly eventsToolDataService: EventsToolDataService,
        private readonly secInfoDataService: SecInfoConfigDataService,
        private readonly mdmService: MdmService,
        private readonly deltaService: DeltaService,
        private readonly surgeryOrderDataService: SurgeryOrderActivityDataService,
        private readonly procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
        private readonly externallinksDataService: ExternallinksDataService,
        private readonly localizationService: LocalizationService,
        private readonly surveyService :SurveyService,
        private readonly myAssistantService :MyAssistantService,
        private readonly myAssistantDataService: MyAssistantDataService,
        private readonly veevaService: VeevaWidgetService,
        private readonly customerAssessService: CustomerAssessService,
        private readonly addressService: AddressService,
        private readonly fieldMaterialManagementService: FieldMaterialManagementService,
        private readonly meetingDataService: MeetingDataService,
        private readonly marketingPlanManagementDataService:MarketingPlanManagementDataService,
        private readonly allocationInventoryDataService: AllocationInventoryDataService,
        private quoteService : QuoteToolDataService,
        private readonly addressDataService: AddressDataService,
        private readonly salesOrderDataService: OrderActivityDataService,
        private readonly pharmacovigilanceDataService: PharmacovigilanceDataService,
        private readonly kolStatusService: KOLStatusService,
        private readonly territoryDataService: TerritoryManagementDataService,
        private readonly territoryService: TerritoryManagementService,
        private readonly medicalInsightService: MedicalInsightService,
        private readonly storeCheckService: StoreCheckService,
        private readonly appealDataService: AppealDataService,
        private readonly procedureContractService : ProcedureContractService,
        private readonly setBookingDataService: SetBookingDataService,
    ) {
    }

    async uploadOfflineData(syncDebugSyncInfo: EntitySyncInfo, isPartialUpload = false) {
        // Default partial upload data count per entity
        const maxRecordCountForPartialUpload = 10;
        /*
            Added payload for offline medical inquiries
            TC-1063
        */
        return Promise.all([
            /**
             *  PLEASE READ this before adding another offline upload function here.
             *
             *  When a new offline feature gets added, please keep in mind that
             *  offline data count should be tracked during local CRUD process.
             *  There is offline data check that happens before triggering a sync.
             *  However, due to bad pouchDB performance, checking all offline entities
             *  from pouchDB takes almost 10 seconds with Samsung A6. (our target device)
             *  Hence, as a work around, we've changed to track offline data count on the fly
             *  at the time of each entity's CRUD operation.
             *
             *  Tracking can be done anyway you want but it has to be efficient.
             *  Please update "OFFLINE_DATA_COUNT_ENTITY_NAME" and
             *  "isThereOfflineDataToBeUploaded" function in DiskService.
             *  Your new offline feature should use "getOfflineDataCount" & "setOfflineDataCount" to track the count.
             *
             *  Also, "isPartialUpload" flag is needed for small data upload in a certain scenario.
             *  All of the offline upload function should implement the functionality.
             *  Please refer existing offline upload functions for better understanding.
             */
            await this.opportunityService.uploadOfflineOpportunities(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 1: ', e)),
            this.activityService.uploadOfflineMeetingsTimeOffsAllocationsEmailsFollowupsConsents(syncDebugSyncInfo, isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 3: ', e))
              .then(() => this.meetingDataService.uploadMeetingNotesOnline().catch(e => console.error('uploadOfflineData: 15: ', e))),
            this.activityService.uploadOfflineDataForOrders(isPartialUpload ,maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 3: ', e)),
            this.presentationService.syncOfflineFavourites().catch(e => console.error('uploadOfflineData: 4: ', e)),
            this.coachingReportService.uploadOfflineCoachingReports(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 6: ', e)),
            this.searchConfigDataService.uploadOfflineSavedSearchData().catch(e => console.error('uploadOfflineData: 7: ', e)),
            this.contactService.uploadCustomerNotesOnline().catch(e => console.error('uploadOfflineData: 8: ', e)),
            this.nextCallObjectiveDataService.uploadOfflineNextCallObjectives().catch(e => console.error('uploadOfflineData: 9: ', e)),
            this.marketScanService.uploadOfflineMarketScans(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineMarketScan: 10', e)),
            this.eventsToolDataService.uploadOfflineEventsToolData(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineEventsToolData: 11', e))
              .then(() => this.customerEventsService.uploadOfflineEventRegistrationResponse(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 5: ', e))),
            this.accountService.uploadAccountNotesOnline().catch(e => console.error('uploadOfflineData: 12: ', e)),
            this.contactService.uploadOfflineContactTag().catch(e => console.error('uploadOfflineData: 13: ', e)),
            this.addressService.uploadPreferredAddressOnline().catch(e => console.error('uploadOfflineData: 14: ', e)),
            this.contactService.uploadOfflineContacts(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 16: ', e)),
            this.contactService.uploadOfflineLinkedEntities(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 17: ', e)),
            this.customerAssessService.uploadOfflineContactAssessments(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 18: ', e)),
            this.contactService.uploadOfflineBusinessCRs(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 19: ', e)),
            this.contactService.uploadOfflineOneKeyCRs(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 20: ', e)),
            this.accountService.uploadOfflineAccountTag().catch(e => console.error('uploadOfflineData: 21: ', e)),
            this.customerAssessService.uploadOfflineContactSurveys(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 22: ', e)),
            this.customerAssessService.uploadOfflineAccountSurveys(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 23: ', e)),
            this.procedureContractService.UploadOfflineProcedureContracts().catch(e => console.error('uploadOfflineData: 24: ', e)),
            // this.customerAssessService.uploadOfflineAccountAssessments(isPartialUpload, maxRecordCountForPartialUpload).catch(e => console.error('uploadOfflineData: 19: ', e)),
            /**
             *  PLEASE READ ABOVE!!
             */
        ]).then(() => true).catch((e) => { console.error('uploadOfflineData: ', e); return false; });
    }

    async purgeData(maxEndDateUnixTimestamp: number): Promise<any> {
        return Promise.all([
            this.activityOfflineService.purgeData(maxEndDateUnixTimestamp),
            this.timeOffService.purgeData(maxEndDateUnixTimestamp),
            this.sampleService.purgeData(),
            this.allocFeatureService.purgeData(maxEndDateUnixTimestamp),
            this.callPlanOfflineService.purgeData(maxEndDateUnixTimestamp),
            this.schedulerService.purgeData(maxEndDateUnixTimestamp),
            this.caseManagementService.purgeData(maxEndDateUnixTimestamp),
            this.customerEventsService.purgeData(maxEndDateUnixTimestamp),
            this.accountManagementDataService.purgeData(maxEndDateUnixTimestamp),
            this.sapDataService.purgeData(maxEndDateUnixTimestamp),
            this.marketScanService.purgeData(maxEndDateUnixTimestamp),
            this.eventsToolDataService.purgeData(maxEndDateUnixTimestamp),
            this.reportsDataService.purgeData(maxEndDateUnixTimestamp),
            this.surgeryOrderDataService.purgeData(maxEndDateUnixTimestamp),
            this.procedureTrackerActivityDataService.purgeData(maxEndDateUnixTimestamp),
            this.procedureContractService.purgeData(maxEndDateUnixTimestamp)
        ]);
    }

    async checkUserDataChangeAndCleanDbIfFullSyncRequired(didOfflineDataUploadFail: boolean): Promise<boolean> {
        await this.activityService.getUserData(didOfflineDataUploadFail);
        return this.authenticationService.shouldFullSync;
    }

    /**
     * Pulls data without blocking UI
     */
    async nonBlockingSyncData(forceFullSync = false): Promise<any> {
        return Promise.all([
            this.xperiencesService.fetchXperiencesData(),
        ]);
    }

    async onlyContactSync(forceFullSync = false, loadFromDBOnly = false): Promise<any> {
      return Promise.all([this.contactService.syncContacts(forceFullSync, loadFromDBOnly)]);
    }

  async syncData(forceFullSync = false, loadFromDBOnly = false, forceNetwork?: boolean): Promise<any> {
      const dataRangeWithFutureBoundBySixMonths = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);

        this.utilityService.sync_error = [];
        this.utilityService.sync_service = [];
        this.deltaService.resetSyncEntityList();
        this.myAssistantDataService.resetBotConnectionForSync();
        this.syncLazyLoading(loadFromDBOnly);
        return Promise.all([
          Promise.all([
            this.presentationService.getPresentation(forceNetwork, loadFromDBOnly),
            this.resourceService.getResources(loadFromDBOnly),
            this.activityService.syncActivities(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly, forceFullSync)
          ]).then(() => {
            Promise.all([
              this.activityService.populateHCPInteractionField(),
              this.activityService.getAppointmentFieldOptionSetValues(loadFromDBOnly),
              this.activityService.getRecommendedEngagementPeriodFieldOptionSetValues(loadFromDBOnly),
              this.activityService.getPhoneCallFieldOptionSetValues(loadFromDBOnly),
              this.activityService.fetchAccountVisitFormatFieldConfig(loadFromDBOnly),
              this.activityService.syncConfigFieldLookupValues(loadFromDBOnly,forceFullSync),
            ]).then(() => Promise.all([
                this.activityService.updateActivitiesForConfigField(ActivityType.Appointment, forceFullSync, loadFromDBOnly),
                this.activityService.updateActivitiesForConfigField(ActivityType.PhoneCall, forceFullSync, loadFromDBOnly)
              ]))
          }),
            this.followUpActivityDataService.getSameLevelAndChildUsersListOnline(loadFromDBOnly),
            this.callPlanService.getCallPlans(dataRangeWithFutureBoundBySixMonths, forceFullSync, loadFromDBOnly),
            this.activityService.getUsersPositionsMasterData(loadFromDBOnly),

            // this.activityService.getAccompaniedUsers(loadFromDBOnly),
            this.activityService.getUserDateTimeSettingsByBusinessUnitId(loadFromDBOnly),
            this.activityService.getContactsToolSettingsByBusinessUnitId(loadFromDBOnly),
            this.activityService.getMeetingContentReasonsByBusinessUnitId(loadFromDBOnly),
            this.schedulerDataService.fetchCustomerSegment(loadFromDBOnly),
            this.schedulerDataService.fetchPrioritize(loadFromDBOnly),
            this.schedulerDataService.fetchZip(loadFromDBOnly),
            this.schedulerDataService.fetchCity(loadFromDBOnly),
            this.contactService.fetchSpecialties(loadFromDBOnly),
            this.contactService.syncContactProfileData(forceFullSync, loadFromDBOnly),
            // this.contactService.syncCustomerTag(loadFromDBOnly),
            this.accountService.syncAllTags(loadFromDBOnly),
            // this.presentationService.syncPresentationTag(loadFromDBOnly),
            // this.resourceService.syncResourceTag(loadFromDBOnly),
            this.sampleDataService.syncSamples(forceFullSync, loadFromDBOnly),
            this.sampleDataService.syncLots(dataRangeWithFutureBoundBySixMonths, forceFullSync, loadFromDBOnly),
            this.sampleDataService.syncCustomerLicenses(forceFullSync, loadFromDBOnly),
            this.activityService.getMeetingTypes(loadFromDBOnly),
            this.activityService.getActivitySubjects(loadFromDBOnly),
            this.activityService.getActivityTypes(loadFromDBOnly),
            this.activityService.getSubActivityTypes(loadFromDBOnly),
            this.brandService.getKeyMessagesByPositionGroup(loadFromDBOnly).then(()=> {
              Promise.all([
                this.brandService.getBrands(loadFromDBOnly),
              ])
            }),
            this.brandService.syncProductIndications(forceFullSync,loadFromDBOnly),
            this.locationService.getLocations(loadFromDBOnly),
            this.timeOffDataService.getTimeOffReason(loadFromDBOnly),
            this.coachingReportService.getCoachingReportsMasterData(loadFromDBOnly),
            this.coachingReportService.getCoachingPlansMasterData(forceFullSync, loadFromDBOnly),
            this.allocationShipmentDataService.getShipmentLossReasons(loadFromDBOnly),
            this.allocationAdjustmentDataService.getAdjustmentReasons(loadFromDBOnly),
            this.allocationShipmentDataService.sync(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly),
            this.allocTransferDataService.getTransferReasons(loadFromDBOnly),
            this.allocTransferDataService.getAllocationTransferUsers(loadFromDBOnly),
            this.allocationInventoryDataService.fetchInventory(forceFullSync, loadFromDBOnly),
            this.consentService.getAllActiveConsentsMasterData(forceFullSync, loadFromDBOnly),
            this.emailService.syncEmailActivitiesData(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly),
            this.eventsToolDataService.syncEventsToolActivities(dataRangeWithFutureBoundBySixMonths,forceFullSync,loadFromDBOnly),
            //this.surgeryOrderDataService.syncSurgeryOrderActivities(dataRangeWithFutureBoundBySixMonths,forceFullSync,loadFromDBOnly),
            //this.sampleDataService.getConsentType(),
            // this.sampleDataService.getConsentTerms(loadFromDBOnly),
            this.contentMatchingService.getContentMatching(loadFromDBOnly),
            this.contentMatchingService.getContentMatchingMessages(loadFromDBOnly),
            this.schedulerDataService.fetchAllSchedulerPatterns(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly),
            this.pollService.sync(forceFullSync, loadFromDBOnly),
            this.caseManagementDataService.initCaseService(loadFromDBOnly),
            this.surveyService.getSurveyForms(forceFullSync, loadFromDBOnly),
            //this.caseManagementDataService.syncAllCases(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly),
            this.xperiencesService.fetchXperiencesData(loadFromDBOnly),
            this.authDataService.fetchNotesAssistantConfiguration(loadFromDBOnly),
            this.veevaService.fetchConfig(loadFromDBOnly),
            this.authDataService.fetchOKIntegrationConfig(loadFromDBOnly),
            this.localizationService.fetchMultilingualLabels(forceFullSync, loadFromDBOnly),
            this.localizationService.fetchMultilingualEntities(forceFullSync, loadFromDBOnly),
            this.sharedDataService.fetchLanguages(loadFromDBOnly),
            this.reportsDataService.syncFactsAndDimensions(loadFromDBOnly),
            this.reportsDataService.fetchAndProcessConfigurations(loadFromDBOnly),
            this.searchConfigDataService.getToolNamesForSearch(loadFromDBOnly),
            //assk simmya to check internal sequencing
            Promise.all([
              this.dynamicsFormService.getDynamicForms(forceFullSync, loadFromDBOnly),
              this.secInfoDataService.getSecInfoConfig(loadFromDBOnly),
              this.setBookingDataService.syncFormSectionConfiguration(loadFromDBOnly),
            ]).then(()=> Promise.all([
              this.accountService.syncAccounts(forceFullSync, loadFromDBOnly),
              this.contactService.syncContacts(forceFullSync, loadFromDBOnly),
              this.customerAssessService.syncAssessMasterData(forceFullSync, loadFromDBOnly),
              this.addressService.fetchPreferredAddress(forceFullSync, loadFromDBOnly),
              this.setBookingDataService.syncSetBookingActivities(dataRangeWithFutureBoundBySixMonths,forceFullSync,loadFromDBOnly),
            ])).then(()=> Promise.all([
              this.localizationService.getOneKeyCodesAndLabelsData(forceFullSync, loadFromDBOnly),
              this.searchConfigDataService.getAllSavedSearches(loadFromDBOnly),
              this.sapDataService.getScientificActivities(forceFullSync, loadFromDBOnly),
              this.marketScanService.syncMarketScanMasterData(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly, forceFullSync),
              //OMNI-30257: Edit business information in offline - Non-OneKey contacts
              this.dynamicsFormService.populateLookupFieldsDataForDynamicForms(loadFromDBOnly),
              this.territoryDataService.fetchCurrentListPeriod(loadFromDBOnly).then(() => {
                this.territoryDataService.fetchContactsForConfiguredDisplay(forceFullSync, loadFromDBOnly),
                this.territoryDataService.fetchCustomerPositionListNotifications(loadFromDBOnly)
              }).then(()=>{
                this.territoryDataService.fetchTargetContactIds(loadFromDBOnly)
              })
            ])),

            //check internal sequence
            this.surgeryOrderDataService.getProductsHierarchyForSurgeryOrder(forceFullSync,loadFromDBOnly)
              .then(()=> {
                this.opportunityService.getOpportunitiesData(forceFullSync, null, loadFromDBOnly)
              }),
            this.externallinksDataService.syncHyperlinkData(forceFullSync, loadFromDBOnly),
            this.therapeuticAreaDataService.getTherapeuticArea(loadFromDBOnly),
            this.sharedDataService.getAllCountries(forceFullSync, loadFromDBOnly),
            this.customerEventsService.syncContactEvents(loadFromDBOnly),
            this.opportunityService.getConnectionRoles(true, loadFromDBOnly),
            this.opportunityService.getOpportunityTypes(true, loadFromDBOnly),
            this.opportunityService.getOpportunityProductsByPositions(forceFullSync, loadFromDBOnly),
            this.opportunityService.getCompetitorsData(true, loadFromDBOnly),
            this.opportunityService.getOpportunityReasons(loadFromDBOnly),
            // this.opportunityService.getOpportunityOptionSets(true, loadFromDBOnly),
            this.settingsService.downloadSettings(loadFromDBOnly).catch(err=> console.log('setting service failed',err)),
            this.followUpActivityDataService.getFollowupActiontypes(forceFullSync,loadFromDBOnly),
            this.followUpActivityDataService.getFollowupActionStatusReasons(forceFullSync,loadFromDBOnly),
            this.eventsToolDataService.syncEventToolData(forceFullSync, loadFromDBOnly),
            this.activityService.getUnsolicitedMeetingsData(true,loadFromDBOnly),
            // this.sapDataService.getScientificActivities(forceFullSync, loadFromDBOnly),
            this.fieldMaterialManagementService.syncAssetsMasterData(forceFullSync, loadFromDBOnly),
            //check internal sequence
            this.accountManagementDataService.getAccountPlans(forceFullSync, null, loadFromDBOnly),
            this.marketingPlanManagementDataService.getMarketingBusinessPlans(forceFullSync, null, loadFromDBOnly),
            this.marketingPlanManagementDataService.syncMarketingBusinessPlanTypeRecords(forceFullSync, loadFromDBOnly),
            this.marketingPlanManagementDataService.getCurrencies(loadFromDBOnly),
            this.marketingPlanManagementDataService.getBusinesUnitsMasterList(true, loadFromDBOnly),

            //check internal sequence
            this.orderActivityDataService.fetchPriceLists(forceFullSync, loadFromDBOnly),
            this.mdmService.getSourceTypeOptions(loadFromDBOnly),
            this.myAssistantService.offlineFallbackDataNotifications(loadFromDBOnly),
            this.mdmService.syncMDMMasterData(dataRangeWithFutureBoundBySixMonths, loadFromDBOnly),
            this.nextCallObjectiveDataService.getnextCallObjectives(forceFullSync, loadFromDBOnly),
            this.procedureTrackerActivityDataService.fetchAllUsers(forceFullSync, loadFromDBOnly),

            this.quoteService.getQuotesTools(forceFullSync, loadFromDBOnly),
            this.quoteService.getPriceLists(forceFullSync, loadFromDBOnly),
            this.addressDataService.getBuildings(loadFromDBOnly),
            this.salesOrderDataService.getTeamOrdersNotifications(loadFromDBOnly),
            this.myAssistantService.getCustomNotification(loadFromDBOnly),
            this.salesOrderDataService.getSalesOrderMeasures(loadFromDBOnly),
            this.pharmacovigilanceDataService.fetchPharmacovigilanceReportings(loadFromDBOnly),
            this.pharmacovigilanceDataService.fetchInfoButtonsData(loadFromDBOnly),
            this.kolStatusService.syncMyKOLStatuses(forceFullSync,loadFromDBOnly),
            this.kolStatusService.syncDiseaseAreaRecords(forceFullSync,loadFromDBOnly),
            this.meetingDataService.fetchMeetingObjectives(loadFromDBOnly),
            this.followUpActivityDataService.fetchFollowupActionObjectives(loadFromDBOnly),
            this.contactService.getDCRApprovalRequestsOnline(forceFullSync, loadFromDBOnly),
            this.medicalInsightService.syncMyMedicalInsights(forceFullSync,loadFromDBOnly),
            this.surgeryOrderDataService.getActivityOutcomes(loadFromDBOnly),
            this.contactService.syncSubSpecialtiesRecords(forceFullSync,loadFromDBOnly),
            this.surgeryOrderDataService.fetchProceduerTypes(loadFromDBOnly),
            this.procedureContractService.fetchContractTypes(forceFullSync, loadFromDBOnly),
            this.surgeryOrderDataService.fetchProcedureSubTypes(loadFromDBOnly),
            this.surgeryOrderDataService.fetchShoreiMasterData(loadFromDBOnly),
            this.procedureContractService.fetchProcedureSignatureType(loadFromDBOnly),
            this.procedureContractService.fetchProcedureContracts(forceFullSync, loadFromDBOnly),
            this.procedureContractService.fetchProcedureContractPositionGroupProducts(forceFullSync, loadFromDBOnly),
            this.accountService.fetchCustomerAddresses(forceFullSync, loadFromDBOnly),
            this.activityService.fetchActivityCancellationReasons(forceFullSync, loadFromDBOnly),
            this.storeCheckService.getProductFamilyCategories(loadFromDBOnly),
            this.appealDataService.fetchAppealStatusNotifications(loadFromDBOnly),
            this.appealDataService.getfetchApprovalReasonsConfigurationByBusinessUnitId(loadFromDBOnly),
            this.presentationService.getPresentaionUserTagHierarchy(loadFromDBOnly),
            this.procedureContractService.syncProcedureContractConfigFieldOptionSetValues(loadFromDBOnly)
        ]);
    }

    public async syncLazyLoading(loadFromDBOnly) {
      try {
        this.activityService.getAccompaniedUsers(loadFromDBOnly);
      } catch(err) {
        console.log("failed lazy loading...")
      }
    }

    public stopAllData() {
        this.accountOfflineService.clear();
        this.activityOfflineService.clear();
        this.brandOfflineService.clear();
        //this.callPlanOfflineService.clear();
        this.presentationOfflineService.clear();
    }
    public async connectWebsocket() {
        if (!this.device.isOffline) {
          this.websocketService.connect();
        }
    }
}
