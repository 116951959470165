

export class OkSearchkeys {
  constructor() {
  }
}


export enum OKEntityType {
  ACTIVITY="ACTIVITY",
  WORKPLACE="WORKPLACE",
  INDIVIDUAL="INDIVIDUAL"
}

export enum OKVRType {
  CREATE = "I",
  UPDATE = "U",
  DELETE = "D"
}

export enum VRProcess {
  DIRECT="Q",
  CALL="C",
  CALL_NO_COMPARE="N"
}

export enum LinkedEntity {
  AFFILIATED_TO_PARENT_ACCOUNT="indskr_accountaccountaffiliationcrs",
  ACCOUNT_CONTACT_AFFILIATION="indskr_accountcontactaffiliationcrs",
  CUST_ADDRESS="indskr_customeraddresscrs",
  CUST_SPECIALITY="indskr_customerspecialtychangerequests"
}

export enum VALIDATION_PROCESS {
  REQUEST_PROCESSED="REQUEST_PROCESSED",
  REQUEST_PENDING_JMS="REQUEST_PENDING_JMS",
  REQUEST_PENDING_OKE="REQUEST_PENDING_OKE",
  REQUEST_RESPONDED="REQUEST_RESPONDED",
  RESPONSE_SENT="RESPONSE_SENT"
}

export enum REQUEST_PROCESS {
  VAS_FOUND="VAS_FOUND",
  VAS_FOUND_BUT_INVALID="VAS_FOUND_BUT_INVALID",
  VAS_NOT_FOUND="VAS_NOT_FOUND",
  VAS_INCOHERENT_REQUEST="VAS_INCOHERENT_REQUEST",
  VAS_DUPLICATE_PROCESS="VAS_DUPLICATE_PROCESS"
}

export enum OKWorkplaceSearchKeys {
  WORKPLACE_ID="workplace.workplaceEid",
  WORKPLACE_TYPE="workplace.typeCode",
  ACTIVITY_LOCATION="workplace.activityLocationCode",
  ACTIVITY_STRUCTURE="workplace.activityStructureTypeCode",
  CHECK_STATUS_CODE="workplace.checkStatusCode",
  OFFER_TRAINING="workplace.isOfferingTraining",
  MANAGER_ACCOUNT="workplace.managerWorkplaceEid",
  MANAGER_ACCOUNT_OFFL_NAME="workplace.managerWorkplaceOfficialName",
  MANAGER_ACCOUNT_OFFL_NAME2="workplace.managerWorkplaceOfficialName2",
  MANAGER_ACCOUNT_USL_NAME="workplace.managerWorkplaceUsualName",
  MANAGER_ACCOUNT_USL_NAME2="workplace.managerWorkplaceUsualName2",
  OFFICIAL_NAME="workplace.officialName",
  OFFICIAL_NAME2="workplace.officialName2",
  USUAL_NAME="workplace.usualName",
  USUAL_NAME2="workplace.usualName2",
  NO_OF_BEDS="workplace.profileDatas.NB_BEDS1",
  LEGAL_CATEGORY="workplace.qualifications.CAJ1",
  REASON_CODE="workplace.reasonCode",
  STRUCTURE_TYPE="workplace.structureTypeCode",
  TYPE_CODE="workplace.typeCode",
  TELEPHONE_TYPE_CODE="workplace.telephones.typeCode",
  TELEPHONE_RANK="workplace.telephones.rank",
  TELEPHONE_OFC_NUM="workplace.telephones.officeNumber",
  AFFL_TO_ACC="workplace.relations.parent",
  AFFL_FROM_ACC="workplace.relations.child",
  AFFL_LINK_CODE="workplace.relations.linkCode",
  TELEPHONE_CALL_NO_FOR_DISPLAY="workplace.telephones.callNumberForDisplay",
  TELEPHONE_CALL_NO_FOR_SEARCH="workplace.telephones.callNumberForSearch",
  TELEPHONE_CONSENT="workplace.telephones.consent"
}

export enum OmnipresenceAccountSearchKeys {
  WORKPLACE_ID="indskr_mdmid",
  WORKPLACE_TYPE="omnione_lu_workplacetypemostdetailedcategory",
  ACTIVITY_LOCATION="omnione_lu_ActivityLocation",
  ACTIVITY_STRUCTURE="omnione_lu_ActivityoftheStructure",
  CHECK_STATUS_CODE="omnione_qualitycheckstatus",
  OFFER_TRAINING="omnione_ProvidesTraining",
  MANAGER_ACCOUNT="omnione_AccountManagerMDMID",
  MANAGER_ACCOUNT_OFFL_NAME="omnione_AccountManagerOfficialName",
  MANAGER_ACCOUNT_OFFL_NAME2="omnione_AccountManagerOfficialName2",
  MANAGER_ACCOUNT_USL_NAME="omnione_AccountManagerName",
  MANAGER_ACCOUNT_USL_NAME2="omnione_AccountManageName2",
  OFFICIAL_NAME="omnione_OfficialWorkplaceName",
  OFFICIAL_NAME2="omnione_OfficialWorkplaceNameina2ndAlphabetLangua",
  NO_OF_BEDS="indskr_noofbeds",
  LEGAL_CATEGORY="omnione_LegalCategory",
  REASON_CODE="omnione_ReasonforLastWorkplaceUpdate",
  STRUCTURE_TYPE="omnione_workplacestructuretype",
  TYPE_CODE="omnione_workplacetypemostdetailedcategory",
  TELEPHONE_TYPE_CODE="omnione_Type",
  TELEPHONE_RANK="omnione_Rank",
  TELEPHONE_OFC_NUM="omnione_OfficeNumber",
  AFFL_TO_ACC="indskr_affiliatedtoaccountid",
  AFFL_FROM_ACC="indskr_affiliatedfromaccountid",
  AFFL_LINK_CODE="omnione_Relationship",
  TELEPHONE_CALL_NO_FOR_DISPLAY="omnione_DisplayNumber",
  TELEPHONE_CALL_NO_FOR_SEARCH="omnione_NumberforSearch",
  TELEPHONE_CONSENT="omnione_Consent"
}

export enum OKActivitySearchKeys {
  INDIVIDUAL_ID="individual.individualEid",
  FIRST_NAME="individual.firstName",
  FIRST_NAME2="individual.firstName2",
  USL_FIRST_NAME="individual.usualFirstName",
  USL_LAST_NAME="individual.usualLastName",
  LAST_NAME="individual.lastName",
  LAST_NAME2="individual.lastName2",
  MIDDLE_NAME="individual.middleName",
  GENDER_CODE="individual.genderCode",
  PREFERRED_LANGUAGE="individual.preferredLanguageEid",
  NAME_PREFIX="individual.prefixNameCode",
  TITLE_CODE="individual.titleCode",
  TYPE_CODE="individual.typeCode",
  REASON_CODE="individual.reasonCode",

}

export enum OmnipresenceContactSearchKeys {
  INDIVIDUAL_ID="indskr_mdmid",
  FIRST_NAME="firstname",
  GENDER_CODE="gendercode",
  PREFERRED_LANGUAGE="indskr_lu_languageid",
  NAME_PREFIX="indskr_title",
  TITLE_CODE="omnione_lu_title",
  TYPE_CODE="customertypecode",
  REASON_CODE="omnione_reasonforlastindividualupdate",


}

export enum SEARCH_OPERATOR {
  AND="AND",
  OR="OR"
}

export enum COD_BASE {
  GERMANY="WDE",
  DENMARK="WDK",
  NETHERLAND="WNL"
}
