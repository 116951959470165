<ion-segment mode='{{type}}' class="tabs-container" [(ngModel)]="selectedTab" tappable (ionChange)="segmentChanged()" [scrollable]="true">
    <ng-container *ngFor="let d of data">
            <ion-segment-button style="text-transform: none;" id="{{d.value}}" value="{{d.value}}" [hidden]="d.hide" [disabled]="d.disable">
              <ion-skeleton-text *ngIf="d.showSkeleton" class="img-skl skeleton-text-animated" [animated]="true" style="line-height:20px"></ion-skeleton-text>
              <ion-icon *ngIf="d.icon" [src]="d.icon"></ion-icon>
              <ion-icon *ngIf="d.topIcon" [src]="d.topIcon"></ion-icon>
              <ion-label class="text" *ngIf="d.displayText"> {{ d.displayText | translate}} </ion-label>
            </ion-segment-button>
    </ng-container>
</ion-segment>
