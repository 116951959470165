import { AuthenticationService } from './../../services/authentication.service';
import { Injectable } from '@angular/core';
import DynamicsWebApi, { RetrieveMultipleRequest } from 'dynamics-web-api'
import { AuthenticationDataService } from '../authentication/authentication.service';

function delay(t) {
  return new Promise<void>((resolve) => {
      setTimeout(()=> resolve(), t);
    });
}
function handle429(callback:()=>Promise<any>){
  return ((err)=> {
    if(err && err.status && err.status==429){
      const waitTime = (err.headers['retry-after']) ? parseInt(err.headers['retry-after']) : 1000;
      console.log('call failed with 429, retrying after ', waitTime);
      return delay(waitTime).then(()=>callback())
    }
    else{
      return Promise.reject(err);
    }
  })
}
@Injectable({
  providedIn: 'root'
})
export class DynamicsClientService  {

  private _dwa: DynamicsWebApi;
  get dwa() {
    return this._dwa || this.getDwa({
      returnRepresentation : true,
      includeAnnotations:'*'
    });
  }

  private getDwa(config?: DynamicsWebApi.Config) {
    return new DynamicsWebApi({
      webApiUrl:`${this.authService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.authDataService.getTokenForSelectedInstance().then(at => at.token).then(tokenRefresh),
      ...config ? config : {},
    })
  }

  constructor(
    public authService: AuthenticationService,
    public authDataService: AuthenticationDataService
  ) {
    // super({
    //   returnRepresentation : true,
    //   webApiUrl:`${authService.userConfig.activeInstance.url}/api/data/v9.1/`,
    //   onTokenRefresh: tokenRefresh => tokenRefresh(authService.access)
    // });
  }

  async executeFetchQuery(entityName: string, fetchXML:string, includeAnnotations: string = '*'){
    let response
    let newDynamicsConnection = new DynamicsWebApi({
      returnRepresentation : true,
      webApiUrl:`${this.authService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.authDataService.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
      includeAnnotations: includeAnnotations
    })
    fetchXML = fetchXML.replace(/\s+/g, ' ').trim();
    //newDynamicsConnection.setConfig()
    response = await newDynamicsConnection.fetchAll(entityName, fetchXML)
                  .catch(handle429(()=>newDynamicsConnection.fetchAll(entityName, fetchXML)))
                  .then(res => res.value)
    return response;
  }

  async executeFetchQueryWithPageNumber(entityName: string, fetchXML:string,pageCount,includeAnnotations: string = '*'){
    let response
    let newDynamicsConnection = new DynamicsWebApi({
      returnRepresentation : true,
      webApiUrl:`${this.authService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.authDataService.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
      includeAnnotations: includeAnnotations
    })
    fetchXML = fetchXML.replace(/\s+/g, ' ').trim();
    //newDynamicsConnection.setConfig()
    response = await newDynamicsConnection.fetch(entityName, fetchXML,includeAnnotations,pageCount)
                      .catch(handle429(()=>newDynamicsConnection.fetch(entityName, fetchXML,includeAnnotations,pageCount)))
                      .then(res => res.value)
    return response;
  }

  async executeFetchXml<T>(entityName: string, fetchXml: string, includeAnnotations: string = '*', pageNumber?: number, pagingCookie?: string): Promise<DynamicsWebApi.FetchXmlResponse<T>> {
    let newDynamicsConnection = new DynamicsWebApi({
      returnRepresentation : true,
      webApiUrl:`${this.authService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.authDataService.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
      includeAnnotations: includeAnnotations
    });
    fetchXml = fetchXml.replace(/\s+/g, ' ').trim();
    return await newDynamicsConnection.fetch(entityName, fetchXml, includeAnnotations, pageNumber, pagingCookie);
  }

  // async executeWebApi(guidKey: string, entityToSearch: string){
  //   let response
  //   //newDynamicsConnection.setConfig()
  //   // https://io-dev.crm3.dynamics.com/api/data/v8.1/GlobalOptionSetDefinitions(4bff62ec-95b4-4c16-a398-4d582e776d40)
  //   // response = await newDynamicsConnection.retrieve("4bff62ec-95b4-4c16-a398-4d582e776d40","GlobalOptionSetDefinitions");
  //   response = await this.dwa.retrieve(guidKey,entityToSearch);
  //   return response;
  // }


  async retrieveAll<T = any>(collection: string, select?: string[], filter?: string){
    return await this.dwa.retrieveAll<T>(collection, select, filter)
                .catch(handle429(()=>this.dwa.retrieveAll<T>(collection, select, filter)));
  }

  async retrieveAllRequest<T = any>(request: RetrieveMultipleRequest): Promise<DynamicsWebApi.RetrieveMultipleResponse<T>> {
    return await this.dwa.retrieveAllRequest(request)
                .catch(handle429(()=>this.dwa.retrieveAllRequest(request)));
  }

  async retrieveMultipleRequest<T = any>(request: RetrieveMultipleRequest): Promise<DynamicsWebApi.RetrieveMultipleResponse<T>> {
    return await this.dwa.retrieveMultipleRequest(request)
                .catch(handle429(()=>this.dwa.retrieveMultipleRequest(request)));
  }

  async retrieve<T = any>(entityID: string, collection: string, select: string[]){
    return await this.dwa.retrieve<T>(entityID, collection, select)
                .catch(handle429(()=>this.dwa.retrieve<T>(entityID, collection, select)));
  }

  async retrieveGlobalOptionSet(globalOptionSetKey: string, castType?: string, select?: string[]): Promise<any>{
    return await this.dwa.retrieveGlobalOptionSet(globalOptionSetKey, castType, select);
  }

  async retrieveAttributes(entity: string, type?: string, select?: string[], filter?, expand?): Promise<any>{
    return await this.dwa.retrieveAttributes(entity, type, select, filter, expand);
  }

  async update(key?: string, collection?: string, object?, prefer?) {
    return await this.dwa.update(key, collection, object, prefer)
  }

  async create<T = any>(object?, collection?: string, prefer?, select?) {
    return <T>(await this.dwa.create(object, collection, prefer, select))
  }

  delete(key: string, collection: string, propertyName?: string) {
    return this.dwa.deleteRecord(key, collection, propertyName);
  }

  startBatch() {
    this._dwa = this.getDwa();
    this.dwa.startBatch()
  }

  executeBatch() {
    const res = this.dwa.executeBatch()
    this._dwa = undefined;
    return res;
  }

  async createRequest(request: DynamicsWebApi.CreateRequest) {
    return await this.dwa.createRequest(request)
  }

  async executeUnboundAction<T = any>(actionName: string, requestObject?: any) {
    return <T>(await this.dwa.executeUnboundAction(actionName, requestObject))
  }

  async executeUnboundFunction<T = any>(functionName: string, requestObject?: any) {
    return <T>(await this.dwa.executeUnboundFunction(functionName, requestObject))
  }

  async retrieveRequest(request: DynamicsWebApi.RetrieveRequest) {
    return await this.dwa.retrieveRequest(request);
  }

  async associateSingleValued(collection: string, key: string, singleValuedNavigationPropertyName: string, relatedCollection: string, relatedKey: string){
    return await this.dwa.associateSingleValued(collection, key, singleValuedNavigationPropertyName, relatedCollection, relatedKey)
  }

  async deleteSinglePropertyValue(id,entityName,property){
    return await this.dwa.deleteRecord(id,entityName,property);
  }
}
