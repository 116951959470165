import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { EventHistory } from "@omni/classes/contact/scientific-info.class";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { CustomerEventsService } from "@omni/services/customer-event/customer-events.service";
import { DiskService } from "@omni/services/disk/disk.service";
import _ from "lodash";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { EventActivity } from "../../../classes/events-tool/event.class";
import { EventsToolDataService } from "../../../data-services/event/events-tool.data.service";
import { OperationDetail } from "../../../data-services/follow-up-activity/follow-up-activity.data.service";
import { IndPageTitleViewDataModel } from "../../../models/indPageTitleDataModel";
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { AuthenticationService } from "../../../services/authentication.service";
import { DeviceService } from "../../../services/device/device.service";
import { EventsToolService } from "../../../services/events-tool/events-tool.service";
import { FooterService, FooterViews } from "../../../services/footer/footer.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { SIGNATURE_DOC_FILE_NAME_SUFFIX } from "../../../services/pdf.service";
import { UIService } from "../../../services/ui/ui.service";
import { ConsentSignatureModalComponent } from "../../contact/consent-signature-modal/consent-signature-modal";
import {
  PdfPreviewCmpInputData,
  PdfPreviewCmpJsPDFInput,
  PdfPreviewCmpSourceType,
  PdfPreviewComponent
} from "../../pdf-preview/pdf-preview";
import { AttendeeFormat, EventRegistrationStatus } from "./../../../enums/event/event.enum";
import { EventsService } from "./../../../services/events/events.service";

/**
 * Generated class for the CaptureEventsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'capture-events',
  templateUrl: 'capture-events.html',
  styleUrls:['capture-events.scss']
})
export class CaptureEventsComponent {

  private ngUnsubscribe$ = new Subject<boolean>();
  public captureEventsPageTitle:IndPageTitleViewDataModel;
  @Input() currentEvent:EventActivity;
  public viewModel:Array<MainCardViewDataModel> = [];
  private isReadOnlyMode:boolean = false;
  isCompletedEvent: boolean = false;
  isEventAttendanceCapture: boolean = false;

  constructor(public translate: TranslateService,
    public footerService: FooterService,
    public events: EventsService, // ionic-angular
    private device: DeviceService,
    private navService: NavigationService,
    public popoverCtrl: PopoverController,
    private eventsToolDataService: EventsToolDataService,
    private authService: AuthenticationService,
    private UIService: UIService,
    private eventsToolService: EventsToolService,
    private contactService:ContactOfflineService,
    private disk: DiskService,
    private customerEventsService: CustomerEventsService,
  ) {

    this.eventsToolService.selectedEventUpdatesObserver$
      .pipe(
        takeUntil(this.ngUnsubscribe$)
        ).subscribe((eventActivity: EventActivity) => {
      if (eventActivity) {
        this.currentEvent = eventActivity;
        const isCovisitor = this.currentEvent.covisitors.some(c=> c.id === this.authService.user.systemUserID && c.teamMemberStatecode === 0 && c.isDeleted === false)
                              && this.currentEvent.ownerId !== this.authService.user.systemUserID;
        this.isReadOnlyMode = (this.currentEvent.ownerId != this.authService.user.systemUserID && !isCovisitor) || !((this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 1) || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 548910007));
        if (this.currentEvent.statuscode == 548910005 && this.currentEvent.statecode == 1) {
          this.isCompletedEvent = true;
          this.isReadOnlyMode = true;
        }
        //this._initViewModels();
      }
    });

    this.device.screenWidth.pipe(
      takeUntil(this.ngUnsubscribe$),
      debounceTime(400),
    ).subscribe((newOrientation) => {
      this._initHeaderView();
    });
  }
  ngOnInit() {
    const isCovisitor = this.currentEvent.covisitors.some(c=> c.id === this.authService.user.systemUserID && c.teamMemberStatecode === 0 && c.isDeleted === false)
                        && this.currentEvent.ownerId !== this.authService.user.systemUserID;
      this.isReadOnlyMode = (this.currentEvent.ownerId != this.authService.user.systemUserID && !isCovisitor) || !((this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 1) || (this.currentEvent.statecode === 0 && this.currentEvent.statuscode === 548910007));
      if(this.currentEvent.statuscode == 548910005 && this.currentEvent.statecode == 1){
        this.isCompletedEvent = true;
        this.isReadOnlyMode = true;
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_ATTENDANCE_CAPTURE)) this.isEventAttendanceCapture = true;
      this._initViewModels();
  }

  ionViewWillEnter() {
    this.footerService.initButtons(FooterViews.CAPTURE_EVENTS);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  private async _updateCurrentEvent(payload: {}):Promise<any>{
    return new Promise(async (resolve,reject)=> {
        let action:OperationDetail = {
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: false,
            operationDetail: {
              code: '',
              message: '',
              payload,
            }
        };
        this.currentEvent.modifiedOn = new Date();
        this.currentEvent.pendingPushToDynamics = true;
        await this.UIService.displayLoader();
        this.eventsToolDataService.updateEventsData(action,[this.currentEvent]).then(async (value:Array<EventActivity>)=>{
            this.currentEvent = value[0];
            this.eventsToolService.setSelectedEventUpdates(value[0]);
          const removeParticipantsID: any = value[0].participants.reduce((result, participant) => {
            if (participant.msevtmgt_checkintime) {
              result.push(participant.id)
            }
            return result;
          }, [])
          await this.customerEventsService.removeCustomerEventsForRemovedEventParticipants(this.currentEvent, removeParticipantsID);
          await this.UIService.dismissLoader();
            //this._initViewModels();
            resolve('');
        }).catch(err=>{
          //this._initViewModels();
          this.UIService.dismissLoader();
          resolve('');
          console.log('Error updating event activity');
        })
    });
  }

  public onPageTitleControlClick(id:string):void {
    if(id && id == 'close'){
        this._closePage();
    }
  }

  private _closePage():void{
    this.navService.popWithPageTracking();
  }

  private _initHeaderView():void {
    let titleButtons = []
      titleButtons = [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }];

    this.captureEventsPageTitle = {
        id:'capture_events_title',
        title: this.currentEvent.name,
        controls: titleButtons,
        isHCPFacing: true
    };
  }

  private _initViewModels(): void {
    this._initHeaderView();
    this.viewModel = [];
    this.currentEvent.participants.forEach(value => {
      /*if ((value.isDeleted || value.indskr_reasons === EventRegistrationStatus.Cancelled
        || value.indskr_reasons === EventRegistrationStatus.Declined
        || value.indskr_reasons === EventRegistrationStatus['Cancelled late']) || (this.currentEvent.attendeeFormat === AttendeeFormat.propose && (value.indskr_reasons === EventRegistrationStatus.Proposed
          || value.indskr_reasons === EventRegistrationStatus['No show']))) {
        return;
      }*/
      const isRemoved = value.isDeleted || value.indskr_reasons === EventRegistrationStatus.Cancelled
        || value.indskr_reasons === EventRegistrationStatus.Declined
        || value.indskr_reasons === EventRegistrationStatus['Cancelled late'];
      if (!isRemoved
        && ((this.currentEvent.attendeeFormat === AttendeeFormat.propose && (value.indskr_reasons === EventRegistrationStatus.Accepted || value.indskr_reasons === EventRegistrationStatus.Invited))
          || this.currentEvent.attendeeFormat !== AttendeeFormat.propose)) {
        let viewDataModel: MainCardViewDataModel = ({
          id: value.id,
          showCheckBox: true,
          checkboxChecked: (value.signature || value.checkinId || value.msevtmgt_checkintime) ? true : false,
          checkboxDisabled: true,
          primaryTextLeft: value.name,
          expandableData: value.signature,
          showAccordionIcon: this.isReadOnlyMode && !value.signature ? false : true,
          disableCheckInBtn: value.checkinId || value.msevtmgt_checkintime,
          accordionIconCssClass: (value.indskr_reasons === EventRegistrationStatus['Checked-in'] && !value.signature) ? 'accordion-icon-disabled' : '',
          accordionIconType: 'accordion-add',
          isExpanded: false,
          clickHandler: (id, eventName, viewDataModel) => this._handleActiveConsentExpandClick(id, eventName, viewDataModel),
        });
        this.viewModel.push(viewDataModel);
      }
    });
  }

  private _handleActiveConsentExpandClick(id,eventName,viewDataModel){
    if(eventName && eventName == 'accordionIcon'){
      let targetConsent = this.viewModel.find(value => value.id == id);
      if(targetConsent){
        targetConsent.isExpanded = !targetConsent.isExpanded;
        if(targetConsent.isExpanded){
          viewDataModel.accordionIconType = 'accordion-minus';
          viewDataModel.isExpanded = true;
        }else{
          viewDataModel.accordionIconType = 'accordion-add';
          viewDataModel.isExpanded = false;
        }
      }
    }
  }

  async launchSignaturePad(participantsId: string){
    //signature capture
    let popover = await this.popoverCtrl.create({component: ConsentSignatureModalComponent,componentProps: {}, cssClass: 'signature-pad',backdropDismiss:false});
    popover.present();
    popover.onDidDismiss().then(async (signData: any) => {
      let data = signData.data;
      if(data && data.completedFlow){
        const participant = this.currentEvent.participants.find(e => e.id == participantsId)
        participant.signature = data.signatureData;
        participant.msevtmgt_checkintime = new Date();
        delete participant.indskr_reasons;
        let model = this.viewModel.find(e => e.id == participantsId);
        if(model){
          model.expandableData = data.signatureData;
          model.checkboxChecked = true;
        }
        const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
        await this._updateCurrentEvent(contactsDTO);
        this._initViewModels();
        participant.indskr_reasons = EventRegistrationStatus["Checked-in"];
        this.viewModel.find(e => e.id == participantsId).isExpanded = true;
        this.viewModel.find(e => e.id == participantsId).accordionIconType ='accordion-minus';
        let contact = this.contactService.getContactByID(participantsId);
        if (!contact.scientificInfo.eventHistory.find(e => e.indskr_eventid == this.currentEvent.ID)) {
          let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
          let eventData = new EventHistory(this.currentEvent.ID, this.currentEvent.name, participant.msevtmgt_checkintime.getTime().toString(), this.currentEvent.endDate.getTime().toString(), this.currentEvent.startDate.getTime().toString());
          eventData["indskr_contactid"] = participant.id;
          offlineData.raw.scientificInformation.eventHistory.push(eventData);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save contact profile data in offline db error: ', error));
          contact.scientificInfo.eventHistory.push(eventData);
        }

      }
    })
  }

  footerButtonClicked(buttonId: string) {
    if (buttonId === 'pdfPreview') {
      this._handlePdfPreview();
    }
  }

  private _handlePdfPreview() {
    let data: PdfPreviewCmpInputData = {
      type: PdfPreviewCmpSourceType.jsPDF,
      data: {
        data: this.currentEvent,
        jsPdfGenerator: async (data, pdfService) => {
          let doc = await pdfService.generateEventSignatureListPdfDoc(data);
          return doc;
        },
        allowExport: true,
        exportHandler: (jsPdfDoc, fileName, pdfService) => {
          return pdfService.exportPDF(jsPdfDoc, fileName);
        },
        exportFileName: `${this.currentEvent.name}${SIGNATURE_DOC_FILE_NAME_SUFFIX}`
      } as PdfPreviewCmpJsPDFInput
    };

    this.navService.pushWithPageTracking(PdfPreviewComponent, PageName.PdfPreviewComponent, {pdfData:data}, PageName.PdfPreviewComponent);
  }

  public async onCheckIn(item) {
    const participant = this.currentEvent.participants.find(e => e.id == item.id)
    participant.signature = '';
    participant.msevtmgt_checkintime = new Date();
    delete participant.indskr_reasons;
    item.expandableData = null;
    item.checkboxChecked = true;
    const contactsDTO = this.eventsToolDataService.getContactsDTO(this.currentEvent);
    await this._updateCurrentEvent(contactsDTO);
    this._initViewModels();
    participant.indskr_reasons = EventRegistrationStatus["Checked-in"];
    let contact = this.contactService.getContactByID(item.id);
    if (!contact.scientificInfo?.eventHistory?.find(e => e.indskr_eventid == this.currentEvent.ID)) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
      let eventData = new EventHistory(this.currentEvent.ID, this.currentEvent.name, participant.msevtmgt_checkintime.getTime().toString(), this.currentEvent.endDate.getTime().toString(), this.currentEvent.startDate.getTime().toString());
      eventData["indskr_contactid"] = participant.id;
      offlineData.raw.scientificInformation.eventHistory.push(eventData);
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
        doc = offlineData;
        return doc;
      }).catch(error => console.error('Save contact profile data in offline db error: ', error));
      contact.scientificInfo.eventHistory.push(eventData);
    }
  }

}
