import { AssessmentTemplate, Access, AssessmentAttributeType, AttributeSection, ChoiceOption, Control, RatingScaleType, SurveyFrequency, SurveyType, SurveyStatus, PreviewMode, AttributesRelationModel, InternalSurveyStatus, SurveyCategory, SurveyToolTab } from './../../../classes/customer-assessment/assessment-template.class';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { CustomerAssessment, InternalSurvey } from '@omni/classes/customer-assessment/customer-assessment.class';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { format } from 'date-fns';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { Guid } from 'typescript-guid';
import { ActivityService, ActivitySource } from '@omni/services/activity/activity.service';
import { PopoverController } from '@ionic/angular';
import { SelectListData } from '@omni/components/popover/popover';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { EventsService } from '@omni/services/events/events.service';
import { ContactOfflineService, ContactSelectionObservableModel } from '@omni/services/contact/contact.service';
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { AlertService } from '@omni/services/alert/alert.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { DeviceService } from '@omni/services/device/device.service';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { Contact } from '@omni/classes/contact/contact.class';

@Component({
  selector: 'survey-preview',
  templateUrl: 'survey-preview.html',
  styleUrls: ['survey-preview.scss']
})
export class SurveyPreviewComponent {
  @Input() rawData: CustomerAssessment | InternalSurvey;
  @Input() fromPage: PageName;
  @Output() isFormDirty = new EventEmitter<boolean>();
  @Output() savedData = new EventEmitter<CustomerAssessment | InternalSurvey>();
  @Output() submittedData = new EventEmitter<CustomerAssessment | InternalSurvey>();
  @Output() isValidToSubmit = new EventEmitter<boolean>();

  selectedTemplate: AssessmentTemplate;
  private IcurrentSurveySubscription: Subscription;
  private IpreviewSurveyModeSubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  surveySectionDetails: IndSectionHeaderViewDataModel;
  detailsGridFormView: Array<{
    id: string,
    label: string,
    value: string,
  }> = [];
  private _currentFormValue: { [x: string]: any } = {};
  public currentFormView: Array<{
    id: string,
    type: string,
    isSharedSection: boolean,
    view: any,
    data?: string,
    control?: Control,
    sectionId?: string
  }> = [];
  private _deletedCurrentFormValue: { [x: string]: any } = {};
  private _isClicked: { [x: string]: boolean } = {};
  private _dateTimePickerActiveControl: Control;
  currentEventId: string = '';
  private _dropdownPopover: HTMLIonPopoverElement;
  public previewMode: PreviewMode = PreviewMode.DEFAULT;
  private logicalAttributesList: AttributesRelationModel[] = [];
  private selectedContactNameForSurvey: string = '';
  private selectedAccountNameForSurvey: string = '';
  private isFormDirtyForMeetingFlow: boolean = false;
  private isValidToSubmitSurvey: boolean = false;
  public startTime: { [x: string]: any } = {};
  public prevStartTime: { [x: string]: any } = {};
  public detailViewData: any = [];
  public isMeetingPresentation: boolean = false;
  public isMobileDevicePortrait: boolean = false;
  public isHCPEnabled: boolean = false;
  public hcpFormField:IndFormFieldViewDataModel;
  public selectedHCP: Contact;
  private IallSurveyedContactInfoSubscription: Subscription;
  
  constructor(
    public footerService: FooterService,
    private uiService: UIService,
    private customerSurveyService: CustomerSurveyService,
    public translate: TranslateService,
    public customerAssessService: CustomerAssessService,
    private authService: AuthenticationService,
    private readonly localizationService: LocalizationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private activityService: ActivityService,
    private readonly popoverCtrl: PopoverController,
    public events: EventsService,
    public contactService: ContactOfflineService,
    private meetingStructureService: MeetingStructureService,
    private navService: NavigationService,
    private alertService: AlertService,
    private _cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    public device: DeviceService,
  ) {  }

  /* When setting the value, the initView is required due to the logical attributes(jumping logic).
    It caused scroll to the top after relendering. To prevent this behaviour, added trackBy:trackId */
  trackId(index: number, fieldObject: any) {
    return fieldObject.id ? fieldObject.id : undefined;
  }

  ngOnInit() {
    this.IcurrentSurveySubscription = this.customerSurveyService.currentSurveyObserver.pipe(takeUntil(this.ngDestroy$)).subscribe((value: AssessmentTemplate) => {
      if (value) {
        this.selectedTemplate = value;
        this.isHCPEnabled = this.selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT && this.authService.user.buConfigs['indskr_capturehcpinfoforaccountsurvey'] && (this.previewMode == PreviewMode.READONLY || this.previewMode == PreviewMode.EDITABLE);
        this.validateForm();
      }
    });
    this.IpreviewSurveyModeSubscription = this.customerSurveyService.surveyPreviewObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      this.previewMode = value[0] ? PreviewMode.DEFAULT : PreviewMode.EDITABLE;
      if (!_.isEmpty(value[1])) {
        this.rawData = value[1];
        if (value[1].surveyStatus == SurveyStatus.SUBMITTED) {
          this.previewMode = PreviewMode.READONLY;
          if(value[1]['indskr_surveytype'] == SurveyType.EXTERNAL && !_.isEmpty(value[1].responses)) {
            if(this.selectedTemplate.indskr_entity == 'contact') {
              this.selectedContactNameForSurvey = value[1].responses[0]['ca.indskr_customer_Formatted'] || '';
            } else {
              this.selectedAccountNameForSurvey = value[1].responses[0]['ca.indskr_customer_Formatted'] || '';
            }
          }else if(value[1]['indskr_surveytype'] == SurveyType.INTERNAL) {
            this.selectedContactNameForSurvey = '';
            this.selectedAccountNameForSurvey = '';
          }
        } else if (value[1].surveyStatus == SurveyStatus.SAVED) {
          this.previewMode = PreviewMode.EDITABLE;
        }
        if (!_.isEmpty(value[1].responses)) {
          value[1].responses.forEach(response => {
            let attributeId = response.indskr_attribute? response.indskr_attribute : response._indskr_attribute_value;
            let sectionId = response.indskr_assessmentcategory ? response.indskr_assessmentcategory : response._indskr_assessmentcategory_value;
            if(sectionId) {
              if(_.isEmpty(this._currentFormValue[sectionId])) this._currentFormValue[sectionId] = {};
              this._currentFormValue[sectionId][attributeId] = response;
            } else this._currentFormValue[attributeId] = response;
          });
          let selectedHCPId = value[1].responses[0]['ca.indskr_affiliatedcontact'] || '';
          this.selectedHCP = selectedHCPId ? this.contactService.getContactByID(selectedHCPId) : null;
        }
      }else {
        this.rawData = null;
        this._currentFormValue = {};
        this.selectedHCP = null;
      }
      this._initLogicalAttributesList();
      this._initViewData();
      if(this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) {
        this.isMeetingPresentation = true;
        this._initSurveySectionDetailsHeader(value[3]);
      }
      this.isHCPEnabled = this.selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT && this.authService.user.buConfigs['indskr_capturehcpinfoforaccountsurvey'] && (this.previewMode == PreviewMode.READONLY || this.previewMode == PreviewMode.EDITABLE);
      // if(!_.isEmpty(value[1]) && value[1].surveyStatus == SurveyStatus.SAVED && this.isHCPEnabled && !this.selectedHCP) this.validateForm();
      if(_.isEmpty(value[1]) && this.isHCPEnabled && !this.selectedHCP) this.setHCP();
      this._initDetailSectionView();
      if(this.previewMode == PreviewMode.EDITABLE && !value[3]) this.validateForm();
      else if (this.previewMode == PreviewMode.EDITABLE && value[2] == SurveyToolTab.ACTIVITIES) {
        this.validateForm();
        this.events.publish('toggleSurveyButtons', {scrapDisabled:false, saveDisabled:true, submitDisabled:!this.isValidToSubmitSurvey });
      }
      this._initFormFields();
    });
    this.dateTimeFormatsService.updateSelectedTimeConfiguredForm$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        this._handleTimeFormField(timeData);
      }
    })
    this.contactService.selectedContactObserver.pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((data: ContactSelectionObservableModel) => {
        if (data && data.for == 'accountSurveyDetails' && data.id == this.selectedTemplate.indskr_assessmenttemplateid) {
          this.selectedHCP = data.contacts[0];
          this._initFormFields();
        } 
      });

    this.IallSurveyedContactInfoSubscription = this.customerAssessService.numOfSurveyedInfo$.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      if (!_.isEmpty(value)) {
        this._initDetailSectionView();
      }
    });
    this.isHCPEnabled = this.selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT && this.authService.user.buConfigs['indskr_capturehcpinfoforaccountsurvey'] && (this.previewMode == PreviewMode.READONLY || this.previewMode == PreviewMode.EDITABLE);
    this._initDetailSectionView();
    this._initViewData();
    this._initFormFields();
  }

  ngOnDestroy() {
    if (this.IcurrentSurveySubscription) this.IcurrentSurveySubscription.unsubscribe();
    if (this.IpreviewSurveyModeSubscription) this.IpreviewSurveyModeSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private getStatus(survey: AssessmentTemplate): string {
    const getCurrentStatus: InternalSurveyStatus = this.customerAssessService.getInternalSurveyStatus(survey);
    const currentStatus: string = getCurrentStatus == InternalSurveyStatus.PENDING ? this.translate.instant("PENDING") :this.translate.instant("COMPLETED");
    return currentStatus;
  }

  private getTimeToCompleteSurvey(survey: AssessmentTemplate): number {
    let timeTocloseValue: number = 5; //default value?
    if(survey.indskr_metadata.hasOwnProperty('TimeToCompleteSurvey')) {
      timeTocloseValue= survey.indskr_metadata['TimeToCompleteSurvey'];
    }
    return timeTocloseValue;
  }

  private _initSurveySectionDetailsHeader(disableSave:boolean = false) {
    this.surveySectionDetails = {
      id: 'survey-section-details',
      title: this.translate.instant('DETAILS'),
      controls: []
    }
    if(this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) {
      this.isMeetingPresentation = true;
      const isEnabledScrapSaveBtn: boolean = PreviewMode.EDITABLE && this.isFormDirtyForMeetingFlow;
      this.surveySectionDetails.controls.push({
        id:'scrapSurvey',
        text: this.translate.instant('SCRAP'),
        isDisabled: this.previewMode == PreviewMode.READONLY || this.previewMode == PreviewMode.READONLY_SUBMITTED || !isEnabledScrapSaveBtn
      }, {
        id:'saveSurvey',
        text: this.translate.instant('SAVE'),
        isDisabled: (this.previewMode == PreviewMode.READONLY || this.previewMode == PreviewMode.READONLY_SUBMITTED) || !isEnabledScrapSaveBtn || disableSave
      }, {
        id:'submitSurvey',
        text: this.translate.instant('SUBMIT'),
        isDisabled: !this.isValidToSubmitSurvey
      })
    }
  }

  private _initDetailSectionView() {
    
    // this._initSurveySectionDetailsHeader();
    if(this.device.isMobileDevice && this.device.isMobileDevicePortrait) {
      this.isMobileDevicePortrait = true;
    }
    if(!_.isEmpty(this.selectedTemplate)) {
      let surveyType = this.selectedTemplate['indskr_surveytype'] == SurveyType.INTERNAL ? this.translate.instant("SURVEY_INTERNAL_SURVEY") : this.selectedTemplate['indskr_entity'] == 'contact' ? this.translate.instant("SURVEY_CUSTOMER_SURVEY") : this.translate.instant("SURVEY_ACCOUNT_SURVEY");
      let valueStatus =  this.selectedTemplate['indskr_surveytype'] == SurveyType.INTERNAL ? this.getStatus(this.selectedTemplate) : this.selectedTemplate['indskr_entity'] == 'contact' ? this.customerAssessService.getNumOfCustomerSurveyed(this.selectedTemplate) : this.customerAssessService.getNumOfAccountSurveyed(this.selectedTemplate);
      let surveyFrequency = this.selectedTemplate['indskr_surveyfrequency'] == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING");
      let getTimeToComplete = this.getTimeToCompleteSurvey(this.selectedTemplate);
      let timeToCompleteSurvey = getTimeToComplete != 0 ? getTimeToComplete : '-';
      let totalQuestions: number = 0;
      if (!_.isEmpty(this.selectedTemplate) && !_.isEmpty(this.selectedTemplate.indskr_metadata.Sections)) {
        this.selectedTemplate.indskr_metadata.Sections.forEach(x => {
          if (!_.isEmpty(x.Controls)) { totalQuestions += x.Controls.length; }
        });
      }
      let createdOn = this.selectedTemplate['createdon'] ? this._getFormattedDate(this.selectedTemplate['createdon']) : '-';
      let expiresOn = this.selectedTemplate['indskr_validto'] ? this._getFormattedDate(this.selectedTemplate['indskr_validto']) : '-';
      let duration = `${createdOn} - ${expiresOn}`;
      let nameValue: string = '';
      if(this.previewMode != PreviewMode.DEFAULT) {
        if(this.selectedTemplate.indskr_surveytype == SurveyType.INTERNAL) {
          nameValue = this.authService.user.displayName;
        }else {
          if(this.selectedTemplate.indskr_entity == 'contact') {
            if(!_.isEmpty(this.customerSurveyService.selectedContactForSurvey) && this.customerSurveyService.selectedContactForSurvey.fullName) {
              nameValue = this.customerSurveyService.selectedContactForSurvey.fullName;
            }else if(!_.isEmpty(this.selectedContactNameForSurvey)) {
              nameValue = this.selectedContactNameForSurvey;
            }
          } else {
            if(!_.isEmpty(this.customerSurveyService.selectedAccountForSurvey) && this.customerSurveyService.selectedAccountForSurvey.accountName) {
              nameValue = this.customerSurveyService.selectedAccountForSurvey.accountName;
            }else if(!_.isEmpty(this.selectedAccountNameForSurvey)) {
              nameValue = this.selectedAccountNameForSurvey;
            }
          }         
        }
      }
      const surveyBubbleTextValue = timeToCompleteSurvey != '-' ? `${totalQuestions} ${this.translate.instant('QUESTIONS_SHORT')} │ ${timeToCompleteSurvey} ${this.translate.instant('MINUTES_SHORT')}` : `${totalQuestions} ${this.translate.instant('QUESTIONS_SHORT')}`;

      this.detailViewData = {
        id: 'survey-detail-view',
        startIconInline: this.selectedTemplate['indskr_surveytype'] != SurveyType.INTERNAL ? "assets/imgs/survey_blue_bluedot.svg" : "assets/imgs/survey_blue.svg",
        surveyName: this.selectedTemplate['indskr_name'] ? this.selectedTemplate['indskr_name'] : '-',
        surveyContact: nameValue,
        surveyType: `${surveyType} │ ${valueStatus}`,
        surveyFrequency: surveyFrequency,
        surveyDuration: duration,
        surveyBubbleText: surveyBubbleTextValue
      };
    }
  }

  private _getFormattedDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    return formattedDate;
  }

  private _initLogicalAttributesList() {
    this.logicalAttributesList = [];
    if (this.selectedTemplate && !_.isEmpty(this.selectedTemplate.indskr_metadata)) {
      this.selectedTemplate.indskr_metadata.Sections.forEach(section => {
          this._getAttributeRelationList(section, section.Controls);
      });
    }
  }

  private _initViewData() {
    if (this.selectedTemplate && !_.isEmpty(this.selectedTemplate.indskr_metadata)) {
      this.selectedTemplate.indskr_metadata.Sections.forEach(section => {
        if (section && !_.isEmpty(section.Controls)) {
          let secHeader = this._getSectionHeaderViewForTab(section);
          if (secHeader) {
            const index: number = this.currentFormView.findIndex(view => view.id == section.SectionProperties.GUID);
            if (index > - 1) {
              this.currentFormView[index] = secHeader;
            } else {
              this.currentFormView.push(secHeader);
            }
          }
          this._getCurrentFormViewForControl(section, section.Controls);
        }
      })
    }
  }

  private _getSectionHeaderViewForTab(section: AttributeSection) {
    try {
      const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);
      let sectionHeader = {
        id: section.SectionProperties.GUID,
        type: 'section-header',
        isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
        view: {
          id: headerLabel,
          title: headerLabel,
          controls: [],
          doNotModifyTitleCase: true
        },
      };
      return sectionHeader;
    } catch (error) {
      console.log('Error parsing section header view from tab' + error);
    }
  }

  private _getCurrentFormViewForControl(section: AttributeSection, controls: Control[], contactID?: string) {
    controls.forEach(control => {
      let view = this._getViewForControl(control, section);
      if (view) {
        let index: number = -1;
        if(view.sectionId) index = this.currentFormView.findIndex(view => view.sectionId == section.SectionProperties.GUID && view.id == control.Attribute.GUID);
        else index = this.currentFormView.findIndex(view => view.id == control.Attribute.GUID);
        if (index > -1) {
          this.currentFormView[index] = view;
        } else {
          this.currentFormView.push(view);
        }
      }
    });
  }

  private _getViewForControl(control: Control, section: AttributeSection) {
    if (control.Type) {
      try {
        let labelText: string = this._getLabeltext(control);
        let placeholderText: string = this._getPlaceholdertext(control);
        const fieldType = this.customerAssessService.getFormFieldType(control);
        const inputText = this._getInputTextForFormField(control, section);
        const isNotEditable: boolean = this._isNotValidToEdit(control);
        const controlId = control.Attribute.GUID;
        const sectionId = section.SectionProperties.GUID;
        const isForcedHide = this._isForcedHideAttribute(section, control);
        if (control.Type != AssessmentAttributeType.TIMESTAMP) {
          let fieldView: IndFormFieldViewDataModel = {
            isHidden: isForcedHide,
            label: labelText,
            inputText: inputText,
            inputValue: inputText,
            id: controlId,
            subId: sectionId,
            isReadOnly: fieldType != FormFieldType.INLINE_INPUT,
            isDisabled: this._isControlDisabled(control, section) || isNotEditable || this.previewMode != PreviewMode.EDITABLE,
            showArrow: this._isShowArrow(control, section) && this.previewMode == PreviewMode.EDITABLE,
            inputType: (control.Type == AssessmentAttributeType.NUMERIC || control.Type == AssessmentAttributeType.RANGE) ? 'number' : 'text',
            formFieldType: fieldType,
            isRequired: control.Mandatory,
            isInvalid: false,
            isEmptyRequiredField: this.previewMode == PreviewMode.EDITABLE && control.Mandatory && !isForcedHide ? this._isClicked && this._isClicked[`${sectionId}_${controlId}`] && _.isEmpty(inputText) : false,
            errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
            inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(id, value, event, this.currentFormView, sectionId) : true,
            eventHandler: (id: string, event, eventName, subId) => this._handleFormFieldEvent(id, event, eventName, sectionId),
            infoText: this._validateAttributeAccess(control) === Access.READ_ONLY ? this.translate.instant('READ_ONLY_FIELD_MSG') : isNotEditable ? this.translate.instant('NON_EDITABLE_FIELD_MSG') : null,
            displayInfoText: this._isClicked[`${sectionId}_${controlId}`],
            infoTextEventHandler: (id: string, value: any, event, subId) => this.infoTextEventHandler(id, value, event, subId)
          };

          if (placeholderText && placeholderText.trim() != '') {
            fieldView.customPlaceholderLabel = placeholderText;
          } else {
            fieldView.placeholderLabel = labelText;
          }
          if (control.Type == AssessmentAttributeType.RANGE) {
            fieldView.inputMin = control.RangeMin;
            fieldView.inputMax = control.RangeMax;
            fieldView.customPlaceholderLabel = fieldView.placeholderLabel = this.translate.instant('ENTER_RANGE_BETWEEN', { min: control.RangeMin, max: control.RangeMax });
          }
          return {
            id: fieldView.id,
            sectionId: fieldView.subId,
            type: 'form-field',
            isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
            view: fieldView,
            control: control
          };
        } else if (control.Type == AssessmentAttributeType.TIMESTAMP) {
          return this._getViewForTimeStampFormField(control, section, inputText, labelText, isNotEditable, controlId);
        }
      } catch (error) {
        console.log('Error occured while parsing controls view' + error);
      }
    }
  }

  /*Survey questions - jumping logic with logical attributes
    -----General----------------------------------------------------------------------------------
    Flag Type - LogicalAttributes under Attribute
    Choice Type - LogicalAttributes under Options
    ParentAttribute is the 1st question, Attribute is the 2nd questions
    Show related ParentAttribute(1st question) after selecting one of options(Choice Type) or true(Flag Type)
    Show related Attribute(2nd question) after selecting ParentAttribute(1st question)
    -----Code flow----------------------------------------------------------------------------------
    Check Type >> Check LogicalAttributes, if attribute has LogicalAttributes, go to the following step
    1. No selected value:  Hide related Attributes(Both ParentAttribute and Attribute)
    2. Selected value(Option or Flag): Show related ParentAttribute
    3. Selected value(ParentAttribute): Show related Attribute
    */
  private _getAttributeRelationList(section: AttributeSection, controls: Control[]) {
    const currentSectionId: string = section?.SectionProperties?.GUID || '';
    controls.forEach(control => {
      const currentDisplayName: string = control.Attribute.DisplayName;
      const currentAttributeId: string = control.Attribute.GUID;
      if(control.Type != AssessmentAttributeType.CHOICE) {
        if(control.hasOwnProperty('Attribute') && control.Attribute.hasOwnProperty('LogicalAttributes') && !_.isEmpty(control.Attribute.LogicalAttributes)) {
          control.Attribute.LogicalAttributes.forEach(attr=>{
            if(attr.ParentAttribute == currentAttributeId) {
              let flagLogicalAttrData: AttributesRelationModel = {
                sectionId: currentSectionId,
                controlDisplayName: currentDisplayName,
                controlType: control.Type,
                controlAttributeId: currentAttributeId,
                optionAttributeId: '',
                logicalAttrAccess: attr.Access,
                logicalAttributeLabel: attr.Label,
                logicalAttrParentAttributeId: attr.ParentAttribute,
                logicalAttrChildAttributeId: attr.Attribute
              }
              this.logicalAttributesList.push(flagLogicalAttrData);
            }
          });
        }
      }else if(control.Type == AssessmentAttributeType.CHOICE) {
        if(control.hasOwnProperty('Choice') && control.Choice.hasOwnProperty('Options') && !_.isEmpty(control.Choice.Options)) {
          control.Choice.Options.forEach(option=>{
            if(option.hasOwnProperty('LogicalAttributes') && !_.isEmpty(option.LogicalAttributes) && option.hasOwnProperty('RatingProperties') && !_.isEmpty(option.RatingProperties)) {
              const optionAttributeId: string = option.RatingProperties[0].GUID;
              option.LogicalAttributes.forEach(attr=>{
                if(attr.ParentAttribute == currentAttributeId) {
                  let choiceLogicalAttrData: AttributesRelationModel = {
                    sectionId: currentSectionId,
                    controlDisplayName: currentDisplayName,
                    controlType: control.Type,
                    controlAttributeId: currentAttributeId,
                    optionAttributeId: optionAttributeId,
                    logicalAttrAccess: attr.Access,
                    logicalAttributeLabel: attr.Label,
                    logicalAttrParentAttributeId: attr.ParentAttribute,
                    logicalAttrChildAttributeId: attr.Attribute,
                  }
                  this.logicalAttributesList.push(choiceLogicalAttrData);
                }
              });
            }
          });
        }
      }
    });
  }

  private _isForcedHideAttribute(section: AttributeSection, control: Control): boolean {
    let isForcedHide: boolean = false;
    if(_.isEmpty(section) && _.isEmpty(control)) return isForcedHide; 
    const curSectionId: string = section?.SectionProperties.GUID;
    const curControlAttributeId: string = control.Attribute.GUID;
    const curControlType: AssessmentAttributeType = control.Type;
    
    if(!_.isEmpty(this.logicalAttributesList)) {
      const tempCurrentFormValue = !_.isEmpty(this._currentFormValue) ? Object.entries(this._currentFormValue) : null;
      //1. filter - parentAttribute/childAttribute level
      const parentLevelList: AttributesRelationModel[] = this.logicalAttributesList.filter((attr: AttributesRelationModel) => 
        attr.sectionId == curSectionId && attr.logicalAttrParentAttributeId == curControlAttributeId);
      const childLevelList: AttributesRelationModel[] = this.logicalAttributesList.filter((attr: AttributesRelationModel) => 
        attr.sectionId == curSectionId && attr.logicalAttrChildAttributeId == curControlAttributeId);
      //2. check - current form values with ParentAttribute level
      if(!_.isEmpty(parentLevelList)) {
        //Base Question has the parent level list only. 
        isForcedHide = _.isEmpty(childLevelList) ? false : true;

        //check - current form values with the attribute in the parent level list 
        if(!_.isEmpty(tempCurrentFormValue)) {
          parentLevelList.forEach(attr => {
            const foundValue: boolean = tempCurrentFormValue.some(obj => {
              //check attribute under section category
              if(obj[0] == curSectionId) {
                const curFormObj = Object.entries(obj[1]);
                const foundValueUnderSection: boolean = curFormObj.some(cfo => {
                  const tarrgetObjAttributeId = cfo[1].hasOwnProperty('indskr_attribute') ? cfo[1]['indskr_attribute'] 
                                                  : cfo[1].hasOwnProperty('_indskr_attribute_value') ? cfo[1]['_indskr_attribute_value'] : '';
                  if(tarrgetObjAttributeId == attr.controlAttributeId) {
                    if(cfo[1].hasOwnProperty('indskr_type') && cfo[1]['indskr_type'] == AssessmentAttributeType.CHOICE) {
                      const selectedChoiceValuesArray: string[] = cfo[1]['indskr_choicevalues'] ? cfo[1]['indskr_choicevalues'].split(',') : [];
                      return selectedChoiceValuesArray.includes(attr.optionAttributeId);
                    }else {
                      return true;
                    }
                  } 
                });
                return foundValueUnderSection;
              }else if(obj[0] == attr.controlAttributeId) {
                if(obj[1].hasOwnProperty('indskr_type') && obj[1]['indskr_type'] == AssessmentAttributeType.CHOICE) {
                  const selectedChoiceValuesArray: string[] = obj[1]['indskr_choicevalues'] ? obj[1]['indskr_choicevalues'].split(',') : [];
                  return selectedChoiceValuesArray.includes(attr.optionAttributeId);
                }else {
                  return true;
                }
              }
            });
            if(foundValue) {
              isForcedHide = false;
            }
          });
        }
      }
      //3. check - current form values on the parent level with the attribute in the child level list
      if(!_.isEmpty(childLevelList)) {
        isForcedHide = true;
        if(!_.isEmpty(tempCurrentFormValue)) {
          childLevelList.forEach(attr => {
            let foundValue: boolean = tempCurrentFormValue.some(obj => {
              //check attribute under section category
              if(obj[0] == curSectionId) {
                const curFormObj = Object.entries(obj[1]);
                const foundValueUnderSection: boolean = curFormObj.some(cfo => {
                  const tarrgetObjAttributeId = cfo[1].hasOwnProperty('indskr_attribute') ? cfo[1]['indskr_attribute'] 
                                                  : cfo[1].hasOwnProperty('_indskr_attribute_value') ? cfo[1]['_indskr_attribute_value'] : '';
                  if(tarrgetObjAttributeId == attr.logicalAttrParentAttributeId) {
                    if(cfo[1].hasOwnProperty('indskr_type') && cfo[1]['indskr_type'] == AssessmentAttributeType.CHOICE) {
                      const selectedChoiceValuesArray: string[] = cfo[1]['indskr_choicevalues'] ? cfo[1]['indskr_choicevalues'].split(',') : [];
                      return selectedChoiceValuesArray.includes(attr.optionAttributeId);
                    }else {
                      return true;
                    }
                  }
                });
                return foundValueUnderSection;
              }else if(obj[0] == attr.logicalAttrParentAttributeId) {
                if(obj[1].hasOwnProperty('indskr_type') && obj[1]['indskr_type'] == AssessmentAttributeType.CHOICE) {
                  const selectedChoiceValuesArray: string[] = obj[1]['indskr_choicevalues'] ? obj[1]['indskr_choicevalues'].split(',') : [];
                  return selectedChoiceValuesArray.includes(attr.optionAttributeId);
                }else {
                  return true;
                }
              }
            });
            if(foundValue) {
              isForcedHide = false;
            }
          });
        }
      }
    }
    return isForcedHide;
  }

  private _getLabeltext(control: Control) {

    let labelText = control.DisplayName;

    if (control.Attribute.hasOwnProperty('DisplayNames')) {

      const displayNames = control.Attribute['DisplayNames'];

      if (!_.isEmpty(displayNames)) {
        const displayName = displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        if (displayName) {
          labelText = displayName.Description;
        } else {
          let foundTranslatedStr = this.localizationService.getTranslatedString(labelText);
          if (!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
        }
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(labelText);
        if (!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
      }
    }
    return labelText;
  }

  private _getPlaceholdertext(control: Control) {
    let placeholderText = control.Description;

    if (control.hasOwnProperty('Descriptions')) {

      const descriptions = control['Descriptions'];

      if (!_.isEmpty(descriptions)) {
        const placeholder = descriptions.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        if (placeholder) {
          placeholderText = placeholder.Description;
        }
      }
    }
    return placeholderText;
  }

  private _getInputTextForFormField(control: Control, section: AttributeSection): string {
    let value = '';
    if (control && control.Type) {
      let formValue = this._getFormValue(control, section.SectionProperties.GUID);
      if (formValue || control.Type == AssessmentAttributeType.FLAG) {
        switch (control.Type) {
          case AssessmentAttributeType.TEXT:
            value = formValue['indskr_text'];
            break;
          case AssessmentAttributeType.FLAG:
            if (formValue && formValue.hasOwnProperty('indskr_flag')) {
              value = formValue['indskr_flag'] ? this.translate.instant("YES") : this.translate.instant("NO");
            } else {
              //default flag value, if logicalAttribute exists, the default value cannot be used
              let isLogicalAttribute: boolean = false;
              if(!_.isEmpty(this.logicalAttributesList)) {
                const contrilAttributeId = control.Attribute.GUID;
                isLogicalAttribute = this.logicalAttributesList.some(attr=>attr.controlAttributeId == contrilAttributeId);
              }
              if(!isLogicalAttribute) {
                value = control['FlagDeafultvalue'] ? this.translate.instant("YES") : this.translate.instant("NO")
                if (!section.TherapetuicArea && !section.PositionGroup && !section.Position && !section.Product && !section.Procedure) {
                  this._setAttributeValue(control, control.FlagDeafultvalue, false, true, section.SectionProperties.GUID);
                }
              }
            }
            break;
          case AssessmentAttributeType.MEMO:
            value = formValue['indskr_memo'];
            break;
          case AssessmentAttributeType.NUMERIC:
          case AssessmentAttributeType.RANGE:
            value = '' + formValue['indskr_numeric'];
            break;
          case AssessmentAttributeType.TIMESTAMP:
            value = formValue['indskr_timestamp'] ? format(formValue['indskr_timestamp'], this.dateTimeFormatsService.dateTimeToUpper) : '';
            break;
          case AssessmentAttributeType.CHOICE:
            const choiceValues: string[] = formValue['indskr_choicevalues'] ? formValue['indskr_choicevalues'].split(",") : [];
            const selectedOptions: ChoiceOption[] = control.Choice.Options.filter(option => choiceValues.includes(option.RatingProperties[0].GUID));
            if (selectedOptions.length)
              value = selectedOptions.length == 1 ? this._getChoiceLabel(selectedOptions[0]) : this._getChoiceLabel(selectedOptions[0]) + ' +' + (selectedOptions.length - 1);
            break;
        }
      }
      return value;
    }
  }

  private _getTargetControl(id: string, sectionId?: string): Control {
    let targetControl: Control;
    let tab: any;
    if(sectionId) {
      tab = this.selectedTemplate.indskr_metadata.Sections.find(section => section.SectionProperties.GUID == sectionId);
      if (tab && tab.Controls && tab.Controls.length > 0) {
        let foundControl = tab.Controls.find(c => id.includes(c.Attribute.GUID));
        if (foundControl) {
          targetControl = foundControl;
        }
      }
    } else {
      for (let tabIdx = 0; tabIdx < this.selectedTemplate.indskr_metadata.Sections.length; tabIdx++) {
        const tab = this.selectedTemplate.indskr_metadata.Sections[tabIdx];
        if (tab && tab.Controls && tab.Controls.length > 0) {
          let foundControl = tab.Controls.find(c => id.includes(c.Attribute.GUID));
          if (foundControl) {
            targetControl = foundControl;
            break;
          }
        }
      }
    }
    return targetControl;
  }

  private _getViewForTimeStampFormField(control: Control, section: AttributeSection, inputText: string, labelText: string, isNotEditable: boolean, controlId: string) {
    let dateFieldView: IndDateTimeFormViewDataModel;
    let timeFieldView: IndDateTimeFormViewDataModel;
    this._dateTimePickerActiveControl = control;
    const sectionId = section.SectionProperties.GUID;
    let dateFieldValue: string = '';
    let timeFieldValue: string = '';
    if (!_.isEmpty(inputText)) {
      const formattedDateTimeValue = moment(inputText).format();
      if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid Date') {
        let currentDateTimeValue = new Date(formattedDateTimeValue);
        dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        timeFieldValue = currentDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        if (!this.dateTimeFormatsService.is12HourFormat && timeFieldValue.includes("24:")) {
          timeFieldValue = timeFieldValue.replace("24:", "00:");
        }
      }
    }
    const dateTextTrans = this.translate.instant('DATE');
    let labelDateText: string = labelText;
    if (labelText.includes('Date') || labelText.includes('date') || labelText.includes(dateTextTrans) || labelText.includes(dateTextTrans.toLowerCase())) {
      labelDateText = labelText;
    } else {
      labelDateText = labelDateText + ' ' + dateTextTrans;
    }
    const isForcedHide = this._isForcedHideAttribute(section, control);
    dateFieldView = {
      isHidden: isForcedHide,
      isDateField: true,
      label: labelDateText,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      inputText: dateFieldValue,
      inputValue: dateFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartDateField,
      controlId: controlId,
      sectionId: sectionId,
      isReadOnly: true,
      isDisabled: this._isControlDisabled(control, section) || isNotEditable || this.previewMode != PreviewMode.EDITABLE,
      showArrow: this._isShowArrow(control, section) && this.previewMode == PreviewMode.EDITABLE,
      isRequired: control.Mandatory,
      isInvalid: false,
      isEmptyRequiredField: this.previewMode == PreviewMode.EDITABLE && control.Mandatory && !isForcedHide ? this._isClicked && this._isClicked[`${sectionId}_${controlId}`] && _.isEmpty(inputText) : false,
      errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false, sectionId) : true,
      eventHandler: (id: string, event, eventName, dataRef) => this._handleFormFieldEvent(control.Attribute.GUID, event, eventName, sectionId),
      infoText: this._validateAttributeAccess(control) === Access.READ_ONLY ? this.translate.instant('READ_ONLY_FIELD_MSG') : isNotEditable ? this.translate.instant('NON_EDITABLE_FIELD_MSG') : null,
      displayInfoText: this._isClicked[`${sectionId}_${controlId}`],
      infoTextEventHandler: (id: string, value: any, event, sectionId) => this.infoTextEventHandler(id, value, event, sectionId)
    };
    const timeTextTrans = this.translate.instant('TIME');
    let labelTimeText: string = labelText;
    if (labelText.includes('Date')) {
      labelTimeText = labelText.replace('Date', 'Time');
    } else if (labelText.includes('date')) {
      labelTimeText = labelText.replace('date', 'time');
    } else if (labelText.includes(dateTextTrans)) {
      labelTimeText = labelText.replace(dateTextTrans, timeTextTrans);
    } else if (labelText.includes(dateTextTrans.toLowerCase())) {
      labelTimeText = labelText.replace(dateTextTrans.toLowerCase(), timeTextTrans.toLowerCase());
    } else {
      labelTimeText = labelTimeText + ' ' + timeTextTrans;
    }
    timeFieldView = {
      isHidden: isForcedHide,
      isTimeField: true,
      label: labelTimeText,
      customPlaceholderLabel: this.translate.instant('SELECT_TIME'),
      inputText: timeFieldValue,
      inputValue: timeFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartTimeField,
      controlId: controlId,
      sectionId: sectionId,
      isReadOnly: true,
      isDisabled: _.isEmpty(dateFieldValue) ? true : this._isControlDisabled(control, section) || isNotEditable || this.previewMode != PreviewMode.EDITABLE,
      showArrow: dateFieldView.inputText != '' && this._isShowArrow(control, section) && this.previewMode == PreviewMode.EDITABLE,
      isRequired: control.Mandatory,
      isInvalid: false,
      isEmptyRequiredField: this.previewMode == PreviewMode.EDITABLE && control.Mandatory && !isForcedHide ? this._isClicked && this._isClicked[`${sectionId}_${controlId}`] && _.isEmpty(inputText) : false,
      errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false, sectionId) : true,
      infoText: this._validateAttributeAccess(control) === Access.READ_ONLY ? this.translate.instant('READ_ONLY_FIELD_MSG') : isNotEditable ? this.translate.instant('NON_EDITABLE_FIELD_MSG') : null,
      displayInfoText: this._isClicked[`${sectionId}_${controlId}`],
      infoTextEventHandler: (id: string, value: any, event, sectionId) => this.infoTextEventHandler(id, value, event, sectionId)
    };
    return {
      id: dateFieldView.controlId,
      sectionId:dateFieldView.sectionId,
      type: 'form-field',
      isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
      view: dateFieldView,
      timeView: timeFieldView,
      control: control,
    };
  }

  private _getFormValue(control: Control, sectionId) {
    const attributeId: string = control.Attribute.GUID;
    if (this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][attributeId]) {
      return this._currentFormValue[sectionId][attributeId];
    } else if(this._currentFormValue && this._currentFormValue[attributeId]) {
      return this._currentFormValue[attributeId];
    } else if (this.rawData) {
      if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId))
        return this.rawData.responses?.find(resp => {
          return (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === attributeId || resp['_indskr_attribute_value'] === attributeId)
        });
      else return this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === attributeId || resp['_indskr_attribute_value'] === attributeId));
    }
    return null;
  }

  private _getChoiceLabel(choiceOption: ChoiceOption) {
    let label = choiceOption.Label;
    if (choiceOption.displayNames && !_.isEmpty(choiceOption.displayNames)) {
      const multilingualLabel = choiceOption.displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
      if (multilingualLabel) {
        label = multilingualLabel.Description;
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(label);
        if (!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
      }
    } else {
      let foundTranslatedStr = this.localizationService.getTranslatedString(label);
      if (!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
    }
    return label
  }

  private _setAttributeValue(control: Control, value: any, initViewData: boolean = true, defaultValues: boolean = false, sectionId?: string) {
    //logicalAttribute parent value change check >> child attribute values should be reset
    const curControlAttributeId: string = control.Attribute.GUID;
    const tempCurrentFormValue = !_.isEmpty(this._currentFormValue) ? Object.entries(this._currentFormValue) : null;
    let foundValue: boolean = false;
    if(!_.isEmpty(tempCurrentFormValue)) {
      let isSectionSpecificAttribute = tempCurrentFormValue.some(obj => obj[0] == sectionId);
      if(isSectionSpecificAttribute) foundValue = tempCurrentFormValue.some(obj => obj[0] == sectionId && Object.keys(obj[1])[0] == curControlAttributeId);
      else foundValue = tempCurrentFormValue.some(obj => obj[0] == curControlAttributeId);
    }
    const filteredLogicalAttributesList: AttributesRelationModel[] = this.logicalAttributesList.filter((attr: AttributesRelationModel) => attr.controlAttributeId == curControlAttributeId);
    if(foundValue && !_.isEmpty(filteredLogicalAttributesList)) {
      this._recursiveClearAttributeValue(curControlAttributeId, sectionId);
    }

    let responseCaptured = {
      indskr_assessmentcategory: sectionId,
      indskr_attribute: control.Attribute.GUID,
      indskr_type: control.Type,
      indskr_name: control.Attribute.DisplayName
    };
    this._setAttributeResponseValue(control, responseCaptured, value);

    if (!this._currentFormValue) this._currentFormValue = {};
    if(_.isEmpty(this._currentFormValue[sectionId])) this._currentFormValue[sectionId] = {};

    if (this._currentFormValue[sectionId] && this._currentFormValue[sectionId][control.Attribute.GUID] && this._currentFormValue[sectionId][control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
      responseCaptured[this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL ? 'indskr_customerassessmentresponseid' : 'indskr_usersurveyresponseid'] = this._currentFormValue[sectionId][control.Attribute.GUID][this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL ? 'indskr_customerassessmentresponseid' : 'indskr_usersurveyresponseid'];
    } else if (this._currentFormValue[control.Attribute.GUID] && this._currentFormValue[control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
      responseCaptured[this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL ? 'indskr_customerassessmentresponseid' : 'indskr_usersurveyresponseid'] = this._currentFormValue[control.Attribute.GUID][this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL ? 'indskr_customerassessmentresponseid' : 'indskr_usersurveyresponseid'];
    } else {
      let index = -1;
      if (this.rawData && this.rawData.responses) {
        if (defaultValues) {
          index = this.rawData.responses?.findIndex(resp => (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === control.Attribute.GUID || resp['_indskr_attribute_value'] === control.Attribute.GUID));
          if(index<0) index = this.rawData.responses.findIndex(resp => (resp.indskr_attribute === control.Attribute.GUID || resp['_indskr_attribute_value'] === control.Attribute.GUID));
        } else
          index = this.rawData.responses?.findIndex(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === control.Attribute.GUID || resp['_indskr_attribute_value'] === control.Attribute.GUID));
      }
      responseCaptured[this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL ? 'indskr_customerassessmentresponseid' : 'indskr_usersurveyresponseid'] = index >= 0 ? this.rawData.responses[index][this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL ? 'indskr_customerassessmentresponseid' : 'indskr_usersurveyresponseid'] : Guid.create().toString();
    }
    this._currentFormValue[sectionId][control.Attribute.GUID] = responseCaptured;

    if (!defaultValues) {
      this.customerAssessService.isSurveyFormDirty = true;
    }
    if (initViewData && !defaultValues) {
      this._initViewData();
    }
  }

  private _recursiveClearAttributeValue(attributeId: string, sectionId: string) {
    const curControlAttributeId: string = attributeId;
    const filteredLogicalAttributesList: AttributesRelationModel[] = this.logicalAttributesList.filter((attr: AttributesRelationModel) => 
        attr.controlAttributeId == curControlAttributeId
      );
    if(!_.isEmpty(filteredLogicalAttributesList)) {
      filteredLogicalAttributesList.forEach(attr=>{
        const parentAttributeId = attr.logicalAttrParentAttributeId;
        const childAttributeId = attr.logicalAttrChildAttributeId;
        this._clearAttributeValueById(parentAttributeId, sectionId);
        this._clearAttributeValueById(childAttributeId, sectionId);
        
        const tempCurrentFormValue = !_.isEmpty(this._currentFormValue) ? Object.entries(this._currentFormValue) : null;
        if(!_.isEmpty(tempCurrentFormValue)) {
          let foundValue: boolean = tempCurrentFormValue.some(obj => obj[0] == childAttributeId);
          if(foundValue) {
            this._recursiveClearAttributeValue(childAttributeId, sectionId);
          }
        }
      });
    }
  }

  private _setAttributeResponseValue(control: Control, responseCaptured: any, value: any) {
    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
        responseCaptured['indskr_text'] = value;
        break;
      case AssessmentAttributeType.FLAG:
        responseCaptured['indskr_flag'] = value;
        break;
      case AssessmentAttributeType.MEMO:
        responseCaptured['indskr_memo'] = value;
        break;
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        responseCaptured['indskr_numeric'] = value;
        break;
      case AssessmentAttributeType.TIMESTAMP:
        responseCaptured['indskr_timestamp'] = value;
        break;
      case AssessmentAttributeType.CHOICE:
        responseCaptured['indskr_choice'] = value['indskr_choice'];
        responseCaptured['indskr_choicevalues'] = value['indskr_choicevalues'];
        responseCaptured['indskr_choicelabel'] = value['indskr_choicelabel'];
        break;
    }
  }

  private _handleFormFieldEvent(id, event, eventName, sectionId?) {
    this.currentEventId = id;
    const targetControl: Control = this._getTargetControl(id);
    if (targetControl) {
      switch (targetControl.Type) {
        case AssessmentAttributeType.TEXT:
        case AssessmentAttributeType.MEMO:
        case AssessmentAttributeType.NUMERIC:
        case AssessmentAttributeType.RANGE:
          this._handleInlineInputFormField(id, event, eventName, targetControl, sectionId);
          this._isClicked[`${sectionId}_${targetControl.Attribute.GUID}`] = true;
          break;
        case AssessmentAttributeType.TIMESTAMP:
          this._handleDateTimeFormField(id, event, eventName, targetControl, sectionId);
          this._isClicked[`${sectionId}_${targetControl.Attribute.GUID}`] = true;
          break;
        case AssessmentAttributeType.CHOICE:
          this._handleChoiceFormField(id, event, targetControl, false, sectionId);
          this._isClicked[`${sectionId}_${targetControl.Attribute.GUID}`] = true;
          break;
        case AssessmentAttributeType.FLAG:
          this._handleFlagDropdownListFormField(id, event, eventName, targetControl, sectionId);
          this._isClicked[`${sectionId}_${targetControl.Attribute.GUID}`] = true;
          break;
        default:
          console.error("unhanled type");
      }
    }
  }

  private infoTextEventHandler(id, value, event, sectionId) {
    this._isClicked[`${sectionId}_${id}`] = true;
    const targetControl: Control = this._getTargetControl(id, sectionId);
    if (targetControl && targetControl.Type == AssessmentAttributeType.CHOICE && targetControl.Choice.Type == RatingScaleType.MULTI_SELECT)
      this._handleChoiceFormField(id, event, this._getTargetControl(id), true, sectionId);
  }

  private _handleInlineInputFormField(id, event, eventName, control: Control, sectionId?) {
    if (eventName == 'input_value_confirm' && control) {
      let value;
      if (event.value) {
        if (control.Type == AssessmentAttributeType.RANGE && (Number(event.value) < control.RangeMin || Number(event.value) > control.RangeMax)) {
          value = '';
        } else {
          value = event.value;
        }
      } else if (event.target) {
        if (control.Type == AssessmentAttributeType.RANGE && (Number(event.target.value) < control.RangeMin || Number(event.target.value) > control.RangeMax)) {
          value = '';
        } else {
          value = event.target.value;
        }
      }
      if (value) {
        this._setAttributeValue(control, value, true, false, sectionId);
      } else {
        this._clearAttributeValue(control, sectionId);
      }
      this.validateForm();
    }
  }

  private async _handleDateTimeFormField(id, event, eventName, targetControl: Control, sectionId) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let currentStartDateTime;
    let currentEndDateTime;
    // if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
    //   currentStartDateTime = this._currentFormValue[targetControl.Attribute.GUID].indskr_timestamp
    // } else if (this.rawData && this.rawData.responses.findIndex(resp => (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID)) > -1) {
    //   currentStartDateTime = this.rawData.responses.find(resp => (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID)).indskr_timestamp;
    // }
    if(this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][targetControl.Attribute.GUID]) {
      currentStartDateTime = this._currentFormValue[sectionId][targetControl.Attribute.GUID].indskr_timestamp;
    } else if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
      currentStartDateTime = this._currentFormValue[targetControl.Attribute.GUID].indskr_timestamp
    } else if (this.rawData && this.rawData.responses) {
      let resp;
      if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId))
       resp = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
      else resp = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
      currentStartDateTime = resp;
    }
    if (currentStartDateTime && !currentEndDateTime) {
      currentEndDateTime = currentStartDateTime;
    }
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ConfiguredForm,
          startDateTimeValue: currentStartDateTime ? moment(currentStartDateTime).format() : '',
          endDateTimeValue: currentEndDateTime ? moment(currentEndDateTime).format() : '',
          controlId: targetControl.Attribute.GUID,
          sectionId: sectionId
        },
        cssClass: "datetime-popover"
      }
    );
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime);
        this._setAttributeValue(targetControl, valueDate.toISOString(), true, false, sectionId);
        if (targetControl.Mandatory) {
          this.customerAssessService._validateMandatoryField(targetControl.Attribute.GUID, null, { value: this._currentFormValue[targetControl.Attribute.GUID] }, this.currentFormView, sectionId);
        }
        this.validateForm();
      }
    })
  }

  private async _handleChoiceFormField(id, event, targetControl: Control, isReadOnly: boolean = false, sectionId?: string) {
    if (this._dropdownPopover) return;
    let selectedValue: string[] = [];

    if(this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][targetControl.Attribute.GUID]) {
      selectedValue = this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_choicevalues'] ? this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_choicevalues'].split(",") : [];
    } else if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
      selectedValue = this._currentFormValue[targetControl.Attribute.GUID]['indskr_choicevalues'] ? this._currentFormValue[targetControl.Attribute.GUID]['indskr_choicevalues'].split(",") : [];
    } else if (this.rawData && this.rawData.responses) {
      let resp;
      if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId))
        resp = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
      else resp = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
      selectedValue = resp ? resp['indskr_choicevalues'].split(",") : []
    }
    //sort choice options by order value
    const targetChoiceOptions: ChoiceOption[] = targetControl.Choice.Options || [];
    const sortedOptionsWithOrders = targetChoiceOptions.filter(op => op.Order !='').sort((a,b) => {
      const rawOrderA: string = a.Order;
      const rawOrderB: string = b.Order;
      const numOrderA: number = parseFloat(rawOrderA);
      const numOrderB: number = parseFloat(rawOrderB);

      if(!isNaN(numOrderA) && !isNaN(numOrderB)) {
        if(numOrderA !== numOrderB) {
          return numOrderA - numOrderB;
        }
        // If 'Order' values are the same, compare 'Value'
        const rawValueA: string = a.Value;
        const rawValueB: string = b.Value;
        const numValueA: number = parseFloat(rawValueA);
        const numValueB: number = parseFloat(rawValueB);
  
        if(!isNaN(numValueA) && !isNaN(numValueB)) {
          if(numValueA !== numValueB) {
            return numValueA - numValueB;
          }
        }
      }
      // If 'Order' and 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });

    const sortedOptionsWithoutOrders = targetChoiceOptions.filter(op => op.Order =='').sort((a,b) => {
      const rawValueA: string = a.Value;
      const rawValueB: string = b.Value;
      const numValueA: number = parseFloat(rawValueA);
      const numValueB: number = parseFloat(rawValueB);

      if(!isNaN(numValueA) && !isNaN(numValueB)) {
        if(numValueA !== numValueB) {
          return numValueA - numValueB;
        }
      }
      // If 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });
    
    let sortedOptions = sortedOptionsWithOrders.concat(sortedOptionsWithoutOrders);
    let data: SelectListData[] = sortedOptions.map(op => {
      return {
        id: op.RatingProperties[0].GUID,
        title: this._getChoiceLabel(op),
        isSelected: selectedValue.includes(op.RatingProperties[0].GUID)
      }
    });
    if (isReadOnly) {
      if (_.isEmpty(selectedValue)) return;
      data = data.filter(d => d.isSelected);
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-choice-field-select',
      data: data,
      isMultipleSelectionEnabled: targetControl.Choice.Type == RatingScaleType.MULTI_SELECT && !isReadOnly,
      showCancelandDoneBtn: targetControl.Choice.Type == RatingScaleType.MULTI_SELECT && !isReadOnly,
      isReadOnly: isReadOnly
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        let newSelectedValue = {};
        if (!_.isEmpty(data.selectedItems)) {
          const choiceValues: string[] = [];
          const choiceLabels: string[] = []
          data.selectedItems.forEach(item => {
            choiceValues.push(item.id);
            choiceLabels.push(item.title);
          })
          newSelectedValue = {
            indskr_choice: targetControl.Choice.RatingScale.GUID,
            indskr_choicevalues: choiceValues.join(","),
            indskr_choicelabel: choiceLabels.join(",")
          }
          this._setAttributeValue(targetControl, newSelectedValue, true, false, sectionId);
        } else if (data.selectedItems && data.selectedItems.length == 0) {
          this._clearAttributeValue(targetControl, sectionId);
          newSelectedValue = null;
        }
        if (targetControl.Mandatory && this.customerAssessService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT) {
          this.customerAssessService._validateMandatoryField(id, null, { value: newSelectedValue }, this.currentFormView, sectionId);
        }
        this.validateForm();
      }
      this._dropdownPopover = undefined;
    });
  }

  private async _handleFlagDropdownListFormField(id, event, eventName, targetControl: Control, sectionId: string) {
    if (this._dropdownPopover) return;
    let selectedValue: boolean = null;
    // if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
    //   selectedValue = this._currentFormValue[targetControl.Attribute.GUID].indskr_flag;
    // } else if (this.rawData && this.rawData.responses.some(resp => (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID))) {
    //   const resp = this.rawData.responses.find(resp => (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
    //   selectedValue = resp ? resp.indskr_flag : null;
    // } else {
    //   let isLogicalAttribute: boolean = false;
    //   if(!_.isEmpty(this.logicalAttributesList)) {
    //     const contrilAttributeId = targetControl.Attribute.GUID;
    //     isLogicalAttribute = this.logicalAttributesList.some(attr=>attr.controlAttributeId == contrilAttributeId);
    //   }
    //   if(!isLogicalAttribute) {
    //     selectedValue = targetControl.FlagDeafultvalue;
    //   }
    // }
    if(this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][targetControl.Attribute.GUID]) {
      selectedValue = this._currentFormValue[sectionId][targetControl.Attribute.GUID].indskr_flag;
    } else if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
      selectedValue = this._currentFormValue[targetControl.Attribute.GUID].indskr_flag;
    } else if (this.rawData && this.rawData.responses) {
      let resp;
      if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId))
       resp = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
      else resp = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === targetControl.Attribute.GUID || resp['_indskr_attribute_value'] === targetControl.Attribute.GUID));
      selectedValue = resp ? resp.indskr_flag : null;
    } else {
      let isLogicalAttribute: boolean = false;
      if(!_.isEmpty(this.logicalAttributesList)) {
        const contrilAttributeId = targetControl.Attribute.GUID;
        isLogicalAttribute = this.logicalAttributesList.some(attr=>attr.controlAttributeId == contrilAttributeId && attr.sectionId == sectionId);
      }
      if(!isLogicalAttribute) {
        selectedValue = targetControl.FlagDeafultvalue;
      }
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-boolean-field-select',
      data: [{
        id: 'Yes',
        title: this.translate.instant("YES"),
        isSelected: selectedValue != null ? selectedValue : false
      },
      {
        id: 'No',
        title: this.translate.instant("NO"),
        isSelected: selectedValue != null ? !selectedValue : false
      }]
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data) {
        let data = obj.data;
        let newSelectedValue = selectedValue;
        if (data.selectedItems && data.selectedItems.length == 1) {
          newSelectedValue = data.selectedItems[0].id == 'Yes' ? true : false;
          this._setAttributeValue(targetControl, newSelectedValue, true, false, sectionId);
        } else if (data.selectedItems && data.selectedItems.length == 0) {
          this._clearAttributeValue(targetControl, sectionId);
          newSelectedValue = null;
        }
        if (targetControl.Mandatory && this.customerAssessService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT) {
          this.customerAssessService._validateMandatoryField(id, null, { value: newSelectedValue }, this.currentFormView, sectionId);
        }
        this.validateForm();
      }
      this._dropdownPopover = undefined;
    });
  }

  private _clearAttributeValue(control: Control, sectionId: string) {
    if (this.rawData) {
      let respSaved;
      if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId))
        respSaved = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === control.Attribute.GUID || resp['_indskr_attribute_value'] === control.Attribute.GUID));
      else respSaved = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === control.Attribute.GUID || resp['_indskr_attribute_value'] === control.Attribute.GUID));      
      if (respSaved) {
        const responseCaptured = {
          statecode: 1,
          statuscode: 2
        };
        if (!this._deletedCurrentFormValue) this._deletedCurrentFormValue = {};
        if (!this._deletedCurrentFormValue[sectionId]) this._deletedCurrentFormValue[sectionId] = {};
        responseCaptured['indskr_customerassessmentresponseid'] = respSaved.indskr_customerassessmentresponseid;
        this._deletedCurrentFormValue[sectionId][control.Attribute.GUID] = responseCaptured;
        this.customerAssessService.isSurveyFormDirty = true;
      }
    }
    if(this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][control.Attribute.GUID]) {
      delete this._currentFormValue[sectionId][control.Attribute.GUID];
    } else if (this._currentFormValue && this._currentFormValue[control.Attribute.GUID] && this._currentFormValue[control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
      delete this._currentFormValue[control.Attribute.GUID];
    }
    this._initViewData();
  }

  private _clearAttributeValueById(attributeId: string, sectionId: string) {
    if (this.rawData) {
      let respSaved;
      if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId))
        respSaved = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == sectionId || resp['_indskr_assessmentcategory_value'] == sectionId) && (resp.indskr_attribute === attributeId || resp['_indskr_attribute_value'] === attributeId));
      else respSaved = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === attributeId || resp['_indskr_attribute_value'] === attributeId));      
      if (respSaved) {
        const responseCaptured = {
          statecode: 1,
          statuscode: 2
        };
        if (!this._deletedCurrentFormValue) this._deletedCurrentFormValue = {};
        responseCaptured['indskr_customerassessmentresponseid'] = respSaved.indskr_customerassessmentresponseid;
        this._deletedCurrentFormValue[attributeId] = responseCaptured;
        this.customerAssessService.isSurveyFormDirty = true;
      }
    }
    if (this._currentFormValue && this._currentFormValue[attributeId] && this._currentFormValue[attributeId]['indskr_customerassessmentresponseid']) {
      delete this._currentFormValue[attributeId];
    }
  }

  private validateForm() {
    let isValid: boolean = false;
    let isValidToSubmit: boolean = true;
    if (this.currentFormView) {
      //For save button, the mandatory field not required
      for (let formView of this.currentFormView.filter(formView => formView.isSharedSection && formView.type === 'form-field' && !formView.view?.isDisabled)) {
        if (!isValid) {
          if (this._currentFormValue && this._currentFormValue.hasOwnProperty(formView.sectionId) && this._currentFormValue[formView.sectionId][formView.id]) {
            isValid = true;
          } else if (this._currentFormValue && this._currentFormValue.hasOwnProperty(formView.id)) {
            isValid = true;
          } else if (this.rawData) {
            // isValid = this.rawData.responses.some(resp => (resp.indskr_attribute === formView.id || resp['_indskr_attribute_value'] === formView.id));
            if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == formView.sectionId || resp['_indskr_assessmentcategory_value'] == formView.sectionId))
              isValid = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == formView.sectionId || resp['_indskr_assessmentcategory_value'] == formView.sectionId) && (resp.indskr_attribute === formView.id || resp['_indskr_attribute_value'] === formView.id));
            else isValid = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === formView.id || resp['_indskr_attribute_value'] === formView.id));           
          } else {
            isValid = false;
          }
        } else {
          break;
        }
      }
      this.isFormDirtyForMeetingFlow = isValid;
      this.isFormDirty.emit(isValid);
      
      //For the submit button, check that all mandatory fields are filled in after activating the save button
      if(isValid) {
        for (let formView of this.currentFormView.filter(formView => formView.isSharedSection && formView.type === 'form-field' && !formView?.view?.isHidden && formView.control?.Mandatory && !formView.view?.isDisabled)) {
          if (isValidToSubmit) {
            if (this._currentFormValue && this._currentFormValue.hasOwnProperty(formView.sectionId) && this._currentFormValue[formView.sectionId][formView.id]) {
              isValidToSubmit = true;
            } else if (this._currentFormValue && this._currentFormValue.hasOwnProperty(formView.id)) {
              isValidToSubmit = true;
            } else if (this.rawData) {
              // isValidToSubmit = this.rawData.responses.some(resp => (resp.indskr_attribute === formView.id || resp['_indskr_attribute_value'] === formView.id));
              if(this.rawData.responses?.some(resp => resp.indskr_assessmentcategory == formView.sectionId || resp['_indskr_assessmentcategory_value'] == formView.sectionId))
                isValidToSubmit = this.rawData.responses.find(resp => (resp.indskr_assessmentcategory == formView.sectionId || resp['_indskr_assessmentcategory_value'] == formView.sectionId) && (resp.indskr_attribute === formView.id || resp['_indskr_attribute_value'] === formView.id));
              else isValidToSubmit = this.rawData.responses?.find(resp => !resp.indskr_assessmentcategory && !resp['_indskr_assessmentcategory_value'] && (resp.indskr_attribute === formView.id || resp['_indskr_attribute_value'] === formView.id));           
            } else {
              isValidToSubmit = false;
            }
          } else {
            break;
          }
        }
        this.isValidToSubmitSurvey = isValidToSubmit;
        this.isValidToSubmit.emit(isValidToSubmit);
      }
    }
    if(this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) {
      this._initSurveySectionDetailsHeader();
    }
  }

  private _validateAttributeAccess(control: Control): Access {
    //1. check main Access field value
    let checkedAttributeAccess: Access = control.Access == Access.READ_ONLY ? Access.READ_ONLY : Access.READ_WRITE;
    //2. check attributes access roles and user's security roles
    const accessSecurityRoleAttributes = control.hasOwnProperty('Attribute') && control.Attribute.hasOwnProperty('AccessRole') ? control.Attribute['AccessRole'] : [];
    const userBusinessUnit = this.authService.user?.xBusinessUnitId || '';
    const userSecurityRoles = this.authService.user?.securityRoles || [];
    //3. validate access security roles with a user's security roles
    if (!_.isEmpty(accessSecurityRoleAttributes) && !_.isEmpty(userBusinessUnit) && !_.isEmpty(userSecurityRoles)) {
      let isAccessReadOnly: boolean = false;
      let isAccessReadWrite: boolean = false;
      accessSecurityRoleAttributes.forEach(attr => {
        if (attr.BusinessUnit == userBusinessUnit) {
          let foundSecurityRole = userSecurityRoles.some(sr => sr.roleid == attr.SecurityRole);
          if (foundSecurityRole) {
            if (attr.Access == Access.READ_ONLY) {
              isAccessReadOnly = true;
            } else if (attr.Access == Access.READ_WRITE) {
              isAccessReadWrite = true;
            }
          }
        }
      });
      //Read & Write take precedence over Read Only
      if (isAccessReadWrite) {
        checkedAttributeAccess = Access.READ_WRITE;
      } else if (isAccessReadOnly) {
        checkedAttributeAccess = Access.READ_ONLY;
      }
    }
    return checkedAttributeAccess;
  }

  private _isNotValidToEdit(control: Control) {
    let isValid: boolean = true;
    if (!_.isEmpty(control.FromDate)) {
      isValid = new Date(control.FromDate) <= new Date();
      if (!_.isEmpty(control.ToDate)) {
        isValid = isValid && new Date(control.ToDate) >= new Date();
      }
    }
    return !isValid;
  }

  private _isControlDisabled(control: Control, section: AttributeSection) {
    return this._validateAttributeAccess(control) == Access.READ_ONLY;
  }

  private _isShowArrow(control: Control, section: AttributeSection) {
    return this._validateAttributeAccess(control) != Access.READ_ONLY && !section.PositionGroup && !section.Position && !section.Product && !section.TherapetuicArea && !section.Procedure;
  }

  public async scrapSurveyForm(templateId, entityId, selectedSurveyID, from?) {
    this.uiService.displayLoader();
    this.isValidToSubmitSurvey = false;
    if(this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL) {
      if(!_.isEmpty(selectedSurveyID)) {
        try {
          await Promise.all([
            this.customerAssessService.scrapSurvey(selectedSurveyID).then((res) => {
              this.customerAssessService.deleteCustomerSurveyInDB(selectedSurveyID),
              this.customerAssessService.deleteAccountSurveyInDB(selectedSurveyID);
              this.customerAssessService.deleteCustomerSurveyApptById(selectedSurveyID)  
            })
          ]).then(() => {
              let selectedSurvey = this.customerAssessService.getCustomerSurveyById(selectedSurveyID);
              if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent && from == ActivitySource.MEETING_DETAIL) {
                this.navService.popChildNavPageWithPageTracking();
                this.customerSurveyService.selectedContactForSurvey = null;
                this.customerSurveyService.selectedAccountForSurvey = null;
              }
              if(from == ActivitySource.MEETING_DETAIL || from == ActivitySource.MEETING_PRESENTATION) {
                if(!_.isEmpty(selectedSurvey)) this.meetingStructureService.removeSurvey(selectedSurvey);
                this.customerSurveyService.inMeetingSelectedSurveyId = null;
              }
              this.customerAssessService.isSurveyFormDirty = false;
              this._initPreview();
              this.uiService.dismissLoader();
            });
        } catch(error) {
          console.log("Error scrap survey form ", error);
          this.uiService.dismissLoader();
        }
      } else {
        if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent && from == ActivitySource.MEETING_DETAIL) {
          this.navService.popChildNavPageWithPageTracking();
          this.customerSurveyService.selectedContactForSurvey = null;
          this.customerSurveyService.selectedAccountForSurvey = null;
        }
        if(from == ActivitySource.MEETING_DETAIL || from == ActivitySource.MEETING_PRESENTATION) {
          this.customerSurveyService.inMeetingSelectedSurveyId = null;
        }
        this.customerAssessService.isSurveyFormDirty = false;
        this._initPreview();
        this.uiService.dismissLoader();
      }     
    }else {
      try {
        await Promise.all([
          this.customerAssessService.scrapInternalSurvey(selectedSurveyID).then((res) => {
            this.customerAssessService.deleteInternalSurveyInDB(selectedSurveyID);
          })
        ]).then(() => {
            this.customerAssessService.isSurveyFormDirty = false;
            this._initPreview();
            this.uiService.dismissLoader();
          });
      } catch(error) {
        console.log("Error scrap survey form ", error);
        this.uiService.dismissLoader();
      }
    }
  }

  public async closePage() {
    this._initPreview();
  }

  private _initPreview() {
    this.rawData = null;
    this.currentFormView = [];
    this._currentFormValue = {};
    this._deletedCurrentFormValue = {};
    this.isFormDirtyForMeetingFlow = false;
    this.isValidToSubmitSurvey = false;
    this._initViewData();
  }

  // public async saveSurveyForm(entitydetails) {
  //   this.uiService.displayLoader();
  //   if (this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL) {
  //     try {
  //       await this.customerAssessService.saveSurvey(entitydetails.entity, entitydetails.id, this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue, entitydetails.appointmentId, this.isValidToSubmitSurvey).then(async (data) => {
  //         if (!_.isEmpty(data)) {
  //           this.validateForm();
  //           this.isFormDirty.emit(false);
  //           this.savedData.emit(data);
  //           this.rawData = _.cloneDeep(data);
  //           this.customerAssessService.isSurveyFormDirty = false;
  //           if(entitydetails.from && (entitydetails.from == ActivitySource.MEETING_DETAIL || entitydetails.from == ActivitySource.MEETING_PRESENTATION)) {
  //             let savedSurvey = new CustomerAssessment(
  //               data.indskr_customerassessmentid,
  //               data.indskr_template,
  //               data.indskr_entity,
  //               data.indskr_name,
  //               data.indskr_entityid,
  //               data.indskr_assessmentdate,
  //               data.modifiedon,
  //               data.responses,
  //               data.indskr_type,
  //               data.indskr_surveytype,
  //               data.indskr_surveyfrequency,
  //               data.indskr_appointmentid,
  //               data.isValidToSubmit
  //             )
  //             savedSurvey.surveyStatus = data.surveyStatus;
  //             if (this.meetingStructureService.checkExistingSurveyById(data.indskr_customerassessmentid)) {
  //               if(this.navService.getCurrentMasterPageName() == PageName.SurveyPageComponent) {
  //                 this.meetingStructureService.updateEmbeddedSurveyFromSurveyPage(<CustomerAssessment>data);
  //               } else {
  //                 this.meetingStructureService.updateEmbeddedSurvey(<CustomerAssessment>data);
  //               }
  //             } else {
  //               this.meetingStructureService.createInMeetingEmbededSurveyFromPresentation(savedSurvey);
  //             }
  //             if(entitydetails.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) {
  //               this.isMeetingPresentation = true;
  //               let targetButtons = [{id:'scrapSurvey', isDisabled: false}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: false}];
  //               this.toggleButtons(targetButtons);
  //             }
  //             this.customerSurveyService.inMeetingSelectedSurveyId = data.indskr_customerassessmentid;
  //           }
  //         }
  //         this.uiService.dismissLoader();
  //       });
  //     } catch (error) {
  //       console.log("Error saving survey form ", error);    
  //     }  
  //   } else {
  //     try {
  //       await this.customerAssessService.saveInternalSurvey(this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue,entitydetails.appointmentId, this.isValidToSubmitSurvey).then((data) => {
  //         if (!_.isEmpty(data)) {
  //           this.isFormDirty.emit(false);
  //           this.savedData.emit(data);
  //           this.rawData = _.cloneDeep(data);
  //           this.customerAssessService.isSurveyFormDirty = false;
  //         }
  //         this.uiService.dismissLoader();
  //       })
  //     } catch (error) {
  //       console.log("Error saving survey form ", error);
  //         this.uiService.dismissLoader();
  //     }
  //   }

  // }

  // public async submitSurveyForm(entitydetails) {
  //   this.uiService.displayLoader();
  //   try {
  //     if (this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL) {
  //       await this.customerAssessService.saveSurvey(entitydetails.entity, entitydetails.id, this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue, entitydetails.appointmentId, this.isValidToSubmitSurvey).then(async(data) => {
  //         if(!_.isEmpty(data)) {
  //           await this.customerAssessService.submitSurvey(entitydetails.entity, entitydetails.id, this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue, entitydetails.appointmentId).then((data) => {
  //             if (!_.isEmpty(data)) {
  //               this.submittedData.emit(data);
  //               this.customerSurveyService.setSurveyPreviewMode(true, data);
  //               this.customerAssessService.isSurveyFormDirty = false;
  //               if(entitydetails.from && (entitydetails.from == ActivitySource.MEETING_DETAIL || entitydetails.from == ActivitySource.MEETING_PRESENTATION)) {
  //                 let submittedSurvey = new CustomerAssessment(
  //                   data.indskr_customerassessmentid,
  //                   data.indskr_template,
  //                   data.indskr_entity,
  //                   data.indskr_name,
  //                   data.indskr_entityid,
  //                   data.indskr_assessmentdate,
  //                   data.modifiedon,
  //                   data.responses,
  //                   data.indskr_type,
  //                   data.indskr_surveytype,
  //                   data.indskr_surveyfrequency,
  //                   data.indskr_appointmentid,
  //                   data.isValidToSubmit
  //                 )
  //                 submittedSurvey.surveyStatus = data.surveyStatus;
  //                 if (this.meetingStructureService.checkExistingSurveyById(data.indskr_customerassessmentid)) {
  //                   if(this.navService.getCurrentMasterPageName() == PageName.SurveyPageComponent) {
  //                     this.meetingStructureService.updateEmbeddedSurveyFromSurveyPage(submittedSurvey);
  //                   } else {
  //                     this.meetingStructureService.updateEmbeddedSurvey(submittedSurvey);
  //                   }
  //                 } else {
  //                   this.meetingStructureService.createInMeetingEmbededSurveyFromPresentation(submittedSurvey);
  //                 }
  //                 this.isSurveyFormDirty(false);
  //                 if(entitydetails.from == ActivitySource.MEETING_PRESENTATION) {
  //                   this.customerSurveyService.inMeetingSelectedSurveyId = data.indskr_customerassessmentid;
  //                   if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) this.footerService.initButtons(FooterViews.PreviewMeeting);
  //                 }
  //                 this.customerSurveyService.inMeetingSelectedSurveyId = data.indskr_customerassessmentid;
  //               }
  //             }
  //             this.uiService.dismissLoader();
  //           });
  //         }
  //         this.uiService.dismissLoader();
  //       });
  //     } else {
  //       await this.customerAssessService.saveInternalSurvey(this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue,entitydetails.appointmentId, this.isValidToSubmitSurvey).then(async(data) => {
  //         if(!_.isEmpty(data)) {
  //           await this.customerAssessService.submitInternalSurvey(this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue, entitydetails.appointmentId).then((data) => {
  //             if (!_.isEmpty(data)) {
  //               this.submittedData.emit(data);
  //               this.customerSurveyService.setSurveyPreviewMode(true, data);
  //               this.customerAssessService.isSurveyFormDirty = false;
  //             }
  //             this.uiService.dismissLoader();
  //           });
  //         }
  //         this.uiService.dismissLoader();
  //       });
  //     }
  //   } catch (error) {
  //     console.log("Error submitting survey form ", error);
  //     this.uiService.dismissLoader();
  //   }
  // }

  public async saveAndsubmitSurveyForm(entitydetails, surveyStatus:SurveyStatus) {
    this.uiService.displayLoader();
    try {
      if (this.selectedTemplate.indskr_surveytype === SurveyType.EXTERNAL) {
        await this.customerAssessService.saveAndSubmitSurvey(entitydetails.entity, entitydetails.id, this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue, surveyStatus, entitydetails.appointmentId, this.isValidToSubmitSurvey, this.selectedHCP?.ID).then((data) => {
          if (!_.isEmpty(data)) {
            let isSubmitted: boolean = surveyStatus == SurveyStatus.SUBMITTED;
            if(!isSubmitted) data.isValidToSubmit = this.isValidToSubmitSurvey;
            this.submittedData.emit(data);
            this.customerSurveyService.setSurveyPreviewMode(isSubmitted, data, 'preview', !isSubmitted);
            this.customerAssessService.isSurveyFormDirty = false;
            if(entitydetails.from && (entitydetails.from == ActivitySource.MEETING_DETAIL || entitydetails.from == ActivitySource.MEETING_PRESENTATION)) {
              let submittedSurvey = new CustomerAssessment(
                data.indskr_customerassessmentid,
                data.indskr_template,
                data.indskr_entity,
                data.indskr_name,
                data.indskr_entityid,
                data.indskr_assessmentdate,
                data.modifiedon,
                data.responses,
                data.indskr_type,
                data.indskr_surveytype,
                data.indskr_surveyfrequency,
                data.indskr_appointmentid,
                data.surveyStatus,
                data.isValidToSubmit,
                data.indskr_affiliatedcontactid
              )
              submittedSurvey.surveyStatus = data.surveyStatus;
              if (this.meetingStructureService.checkExistingSurveyById(data.indskr_customerassessmentid)) {
                if(this.navService.getCurrentMasterPageName() == PageName.SurveyPageComponent) {
                  this.meetingStructureService.updateEmbeddedSurveyFromSurveyPage(submittedSurvey);
                } else {
                  this.meetingStructureService.updateEmbeddedSurvey(submittedSurvey);
                }
              } else {
                this.meetingStructureService.createInMeetingEmbededSurveyFromPresentation(submittedSurvey);
              }
              if(isSubmitted) this.isSurveyFormDirty(false);
              if(entitydetails.from == ActivitySource.MEETING_PRESENTATION) {
                    this.customerSurveyService.inMeetingSelectedSurveyId = data.indskr_customerassessmentid;
                    if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) this.footerService.initButtons(FooterViews.PreviewMeeting);
                  }
                  this.customerSurveyService.inMeetingSelectedSurveyId = data.indskr_customerassessmentid;
                }
                this.isFormDirtyForMeetingFlow = isSubmitted ? false : true;
              this.uiService.dismissLoader();
            }
          });
          this.uiService.dismissLoader();
      } else {
        await this.customerAssessService.saveAndSubmitInternalSurvey(this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue, surveyStatus, this.isValidToSubmitSurvey, entitydetails.appointmentId).then((data) => {
          if (!_.isEmpty(data)) {
            this.submittedData.emit(data);
            this.customerSurveyService.setSurveyPreviewMode(true, data);
            this.customerAssessService.isSurveyFormDirty = false;
          }
          this.uiService.dismissLoader();
        });
      }
    } catch (error) {
      console.log("Error submitting survey form ", error);
      this.uiService.dismissLoader();
    }
  }

  onSectionHeaderControlClick(id: string) {
    const targetEntity = this.selectedTemplate.indskr_entity;
    const templateId = this.selectedTemplate.indskr_assessmenttemplateid;
    const entityId = targetEntity == 'contact' ? this.customerSurveyService.selectedContactForSurvey?.ID : this.customerSurveyService.selectedAccountForSurvey?.id ? this.customerSurveyService.selectedAccountForSurvey.id : '';
    switch (id) {
      case 'scrapSurvey':
        this.alertService.showAlert({
          title: this.translate.instant('SCRAP_SURVEY'),
          message: this.translate.instant('R_U_SURE_SCRAP_SURVEY')
        }, this.translate.instant('SCRAP')
        ).then(async res => {
          if (res.role == "ok") {
            this.customerAssessService.scrapSurvey((<CustomerAssessment>this.rawData).indskr_customerassessmentid).then((res) => {
              if(targetEntity == SurveyCategory.CONTACT) this.customerAssessService.deleteCustomerSurveyInDB((<CustomerAssessment>this.rawData).indskr_customerassessmentid);
              else this.customerAssessService.deleteAccountSurveyInDB((<CustomerAssessment>this.rawData).indskr_customerassessmentid);
              this.customerAssessService.deleteCustomerSurveyAppt(<CustomerAssessment>this.rawData);
              })
            this.meetingStructureService.removeSurvey(<CustomerAssessment>this.rawData);
            this.customerSurveyService.setSurveyPreviewMode(true);
            this.customerSurveyService.selectedContactForSurvey = null;
            this.customerSurveyService.selectedAccountForSurvey = null;
            this.customerSurveyService.inMeetingSelectedSurveyId = null;
            this.customerAssessService.isSurveyFormDirty = false;
            this.isFormDirtyForMeetingFlow = false;
            this.isValidToSubmitSurvey = false;
          }
        });
        break;
      // case 'saveSurvey':
      //   this.saveSurveyForm({ entity: targetEntity, id: entityId, appointmentId: this.rawData?.indskr_appointmentid, from: ActivitySource.MEETING_PRESENTATION });
      //   break;
      // case 'submitSurvey':
      //   this.submitSurveyForm({ entity: targetEntity, id: entityId, appointmentId: this.rawData?.indskr_appointmentid, from: ActivitySource.MEETING_PRESENTATION });       
      //   break;
      case 'saveSurvey':
        this.saveAndsubmitSurveyForm({ entity: targetEntity, id: entityId, appointmentId: this.rawData?.indskr_appointmentid, from: ActivitySource.MEETING_PRESENTATION, affiliatedContact: this.selectedHCP }, SurveyStatus.SAVED);
        break;
      case 'submitSurvey':
        this.saveAndsubmitSurveyForm({ entity: targetEntity, id: entityId, appointmentId: this.rawData?.indskr_appointmentid, from: ActivitySource.MEETING_PRESENTATION, affiliatedContact: this.selectedHCP }, SurveyStatus.SUBMITTED);       
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public isSurveyFormDirty(isDirty: boolean) {
    const targetButtons = [{id:'scrapSurvey', isDisabled: !isDirty}, {id:'saveSurvey', isDisabled: !isDirty}, {id:'submitSurvey', isDisabled: true}];
    this.toggleButtons(targetButtons);
  }

  public toggleButtons(targetButtons: Array<{id:string, isDisabled:boolean}>) {
    if(!_.isEmpty(targetButtons)) {
      targetButtons.forEach(targetBtn => {
        const index = this.surveySectionDetails.controls.findIndex(button => button.id == targetBtn.id);
        if (index > -1) this.surveySectionDetails.controls[index].isDisabled = targetBtn.isDisabled;
      });
      this._cd.detectChanges();
    }
  }

  private async _handleTimeFormField(timeData) {
    let targetControl: Control;
    if (timeData.controlId) {
      targetControl = this._getTargetControl(timeData.controlId, timeData.sectionId);
      if (timeData.id == DateTimeFieldType.StartTimeField) {
        if (this.startTime && this.startTime.hasOwnProperty(targetControl.Attribute.GUID)) {
          this.startTime[targetControl.Attribute.GUID] = timeData.time;
        } else {
          this.startTime = {
            [targetControl.Attribute.GUID]: timeData.time
          }
        }
        setTimeout(() => {
          this._handleStartDateTimeValue(targetControl, timeData.sectionId);
        }, 0);
      }
    }
  }

  private _handleStartDateTimeValue(targetControl: Control, sectionId?: string) {
    if (!_.isEmpty(targetControl)) {
      let m = moment(this.startTime[targetControl.Attribute.GUID]);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Configured Form', 'top', ToastStyle.DANGER);
        this.startTime[targetControl.Attribute.GUID] = this.prevStartTime[targetControl.Attribute.GUID];
      } else {
        this.prevStartTime[targetControl.Attribute.GUID] = this.startTime[targetControl.Attribute.GUID];
        let selectedDate = this.startTime[targetControl.Attribute.GUID];
        let selectedDateUTC = moment(selectedDate).utc().format();
        let formattedDate = (new Date(selectedDateUTC).toISOString());
        this._setAttributeValue(targetControl, formattedDate, true, false, sectionId);
        this.validateForm();
      }
    }
  }

  private _initFormFields() {
    let placeholder = this.previewMode == PreviewMode.READONLY && !_.isEmpty(this.selectedHCP) ? this.translate.instant('NO_AFFILIATED_CONTACT') : this.translate.instant('AFFILIATED_CONTACT');
    this.hcpFormField = {
      label: this.translate.instant('AFFILIATED_CONTACT'),
      inputText: this.selectedHCP && this.selectedHCP.fullname ? this.selectedHCP.fullname : '',
      customPlaceholderLabel: placeholder,
      id: 'affiliated-contact-field',
      isReadOnly: true,
      isDisabled: this.previewMode != PreviewMode.EDITABLE,
      showArrow: this.previewMode == PreviewMode.EDITABLE,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private handleFormFieldEvent(id, event, eventName, fieldName?, fieldLabel?) {
    if (id) {
      switch (id) {
        case 'affiliated-contact-field':
          this.openContacts();
          break;
        default:
          break;
      }
    }
  }

  openContacts() {
    if (!this.contactService.loadedContacts) return;
    this.uiService.activeView = 'surveyDetails';
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.showNewActivity = false;
    this.contactService.accessedContactListFrom = PageName.SurveyDetailsComponent;
    if (this.previewMode != PreviewMode.EDITABLE) {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
    } else {
      this.contactService.contactPageMode = ComponentViewMode.SELECTION;
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { template: this.selectedTemplate, contactId: this.selectedHCP?.ID, account:this.customerSurveyService.selectedAccountForSurvey }, PageName.ContactPageComponent);
    }
  }

  async setHCP() {
    let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.customerSurveyService.selectedAccountForSurvey]);
    if(affiliatedContacts && affiliatedContacts.length == 1) {
      this.selectedHCP = affiliatedContacts[0];
      this._initFormFields()
    }
  }
}

