import { Product } from "./product.class";
import { Channel } from "./channel.class";
import { ConsentActivity } from "./consent-activitiy.class";
import { IONote } from "../io/io-note.class";

export class ConsentTerm {
  constructor(
    public _id: String,
    public _rev: String,
    public indskr_consenttermsid: String,
    public indskr_consentTermName: string,
    public indskr_fromdate: number,
    public indskr_untildate: number,
    public createdon: number,
    public indskr_alwaysrequired: boolean,
    public indskr_recaptureconsent: boolean,
    public indskr_body: String,
    public indskr_contactid: string,
    public offlineId: String,
    public indskr_signature: String,
    public indskr_positionid: String,
    public userid: String,
    public indskr_signaturerequired: number,
    public products: Product[],
    public channels: Channel[],
    public track_action: number,
    public isExpanded?: boolean,
    public isSavedConsent?: boolean,
    public isOfflineSaved?: boolean,
    public isSelectedTerm?: boolean,
    public isAcceptedTerms?: boolean,
    public indskr_confirmationmessage?: string,
    public indskr_agreementmessage?: string,
    public indskr_allocationordersubmission?: boolean,
    public indskr_allowpaperconsentcapture?: boolean,
    public modifiedon?: number,
    public consentActivity?: ConsentActivity[],
    public indskr_optinsource?: boolean,
    public isPaperConsent?: boolean,
    public annotations?: IONote[],
  ) {}
}

export enum TermTokens {
  PRODUCT_NAME_TOKEN = "%product.name%",
  CONTACT_TITLE_TOKEN = "%contact.title%",
  CONTACT_FIRST_NAME_TOKEN = "%contact.firstname%",
  CONTACT_LAST_NAME_TOKEN = "%contact.lastname%",
  CONTACT_FULL_NAME_TOKEN = "%contact.fullname%",
  CONTACT_EMAIL_TOKEN = "%contact.email%",
  CONTACT_ADDRESS_TOKEN = "%contact.businessaddress%",
  CONTACT_PHONE_TOKEN = "%contact.mainphone%",
  CONTACT_FAX_TOKEN = "%contact.fax%",
}

export enum Signaturerequired {
  YES = 548910000,
  OPTIONAL = 548910001,
  NO = 548910002
}

export enum ConsentSource {
  OMNIPRESENCE_GO = 548910000,
  PAPER_CONSENT = 548910003
}
