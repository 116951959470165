import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { Component, Input, ViewChild} from '@angular/core';
import { IonNav } from '@ionic/angular';
import * as _ from 'lodash';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '../../../models/mainToolTemplateDetail.model';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { NothingSelectedView } from '../nothing-selected-view/nothing-selected-view';
import { DeviceService } from '../../../services/device/device.service';
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import { AuthenticationService } from '@omni/services/authentication.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { EventsService } from '@omni/services/events/events.service';
import { SampleService } from '@omni/services/sample/sample.service';
const MIN_SEARCH_LENGTH = 1;

@Component({
  selector: 'main-tool-template[base-page]',
  templateUrl: 'main-tool-template.html',
  styleUrls:['main-tool-template.scss']
})
export class MainToolTemplateComponent {

    @Input() viewData:MainToolTemplateDetail;
    public searchInput:string;
    public listData:Array<MainCardViewDataModel> = [];
    public filteredData:Array<MainCardViewDataModel> = [];
    public itemsToDisplay:Array<MainCardViewDataModel> = [];
    public selectedItems:Array<MainCardViewDataModel> = [];
    private tempSelectedItems:Array<MainCardViewDataModel> = [];
    public searchActive:boolean = false;
    indHeaderLeftModel: IndHeaderLeftDataModel;
    @Input() isNavWithinModalView: boolean;
    @Input() navWithinModalRef: any;
    @Input() isGroupedView:boolean = false;
    public childrenLength:number;
    @Input() viewDataParam:MainToolTemplateDetail;
    @ViewChild('maintooltemplaterightpane', {static: true}) public navCtrl: IonNav;
    sectionHeader: { id: string; title: string; controls: any[]; };
    @Input() isHierarchyView:boolean = false;
    @Input() disableSorting:boolean = false;
    totalLength: number = 0;
    private preventDeSelect = false;
    private recordCount: number = 30;

    constructor(
        public navService: NavigationService,
        private uiService : UIService,
        private device : DeviceService,
        private modalCtrl : ModalController,
        public translate: TranslateService,
        private readonly authService: AuthenticationService,
        public navControl: IonNav,
        public footerService: FooterService,
        public events: EventsService,
        public sampleService: SampleService,
    ){
    }

    ngAfterViewInit(){
        if(this.viewDataParam){
            this.viewData = this.viewDataParam;
            this.initListData();
        }
    }

  ngOnInit() {
    this.initListData();
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.AccountPageNavigation, PageName.AccountPageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NothingSelectedView, { notShowText: true });
      this.initSectionHeader();
  }
  initSectionHeader(){
    let sectionHeader: any[] = [];
    sectionHeader.push({
      id: 'clear-all',
      text: `${this.translate.instant('CLEAR_ALL')}`,
      isDisabled: !(this.selectedItems.some(item => item.showEndIcon === true)),
    });
    this.sectionHeader = {
      id: 'select-all-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: sectionHeader
    };
  }
  onSectionHeaderControlClick(type: string){
    if(type === 'clear-all'){
       this.selectedItems = this.selectedItems.filter((selectedItem, index) =>{
        if(selectedItem.showEndIcon){
       let foundRemovedItemIdx = this.filteredData.findIndex(filterItem => filterItem.id == selectedItem.id);
       if (foundRemovedItemIdx >= 0) {
           let selectorItem: MainCardViewDataModel = {
               ...this.filteredData[foundRemovedItemIdx],
               endIconType: 'indegene-selectors-add-icon',
               endIconCssClass: 'add-icon',
               isMainToolTemplate: true,
               noSeparationLine: false,
               clickHandler: (id: string, event, target, data) => this._handleSelectionEvents(id, event, target, data),
           }
           this.filteredData.splice(foundRemovedItemIdx, 1, selectorItem);
       }
       let foundMainRemovedItemIdx = this.listData.findIndex(item => item.id == selectedItem.id);
       if (foundMainRemovedItemIdx >= 0) {
           let selectorItem: MainCardViewDataModel = {
               ...this.listData[foundMainRemovedItemIdx],
               endIconType: 'indegene-selectors-add-icon',
               endIconCssClass: 'add-icon',
               isMainToolTemplate: true,
               noSeparationLine: false,
               clickHandler: (id: string, event, target, data) => this._handleSelectionEvents(id, event, target, data),
           }
           this.listData.splice(foundMainRemovedItemIdx, 1, selectorItem);
       }
       } else {
        return (!selectedItem.showEndIcon);
       }
       if(this.isHierarchyView){
        try {
          let foundAddedItem = this.findHierarchyItem(selectedItem.id, selectedItem, this.filteredData);
          let foundMainAddedItem = this.findHierarchyItem(selectedItem.id, selectedItem, this.listData);
          foundAddedItem.endIconType = 'indegene-selectors-add-icon';
          foundAddedItem.endIconCssClass = 'add-icon';
          foundMainAddedItem.endIconType = 'indegene-selectors-add-icon';
          foundMainAddedItem.endIconCssClass = 'add-icon';
        } catch (error) {
          console.log(error);
        }
       }
       });
       this.initSelectListHeaderLeft();
        this.initSectionHeader();
    }
  }

    ngOnDestroy() {
        if(this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
          //navigated from meeting then set right pane
          this.uiService.showRightPane = true;
        }
        this.navService.popChildNavCtrlFromStack(ChildNavNames.AccountPageNavigation);
      }

    private initListData():void {
        if(this.viewData){
            if(this.viewData.isListSelectionEnabled){
                if(this.viewData.updateOptionForTeamMember){
                  if (!_.isEmpty(this.viewData.data)) {
                    this.viewData.data.forEach(item => {
                      if (!_.isEmpty(item)) {
                        let selectorItem:MainCardViewDataModel = {
                          ...item,
                          isMainToolTemplate: true,
                          noSeparationLine: false,
                          clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
                        }
                        if(!selectorItem.unMappedProduct){
                            this.listData.push(selectorItem);
                        }
                        if(item.isItemSelectedForSelectionView && item.eventOwnerId && (item.eventOwnerId == this.authService.user.systemUserID)){
                            let selectedItem:MainCardViewDataModel = {
                                ...selectorItem,
                                endIconType: 'indegene-selectors-remove-icon',
                                endIconCssClass: 'remove-icon',
                                isMainToolTemplate: true,
                                noSeparationLine: false,
                                clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
                            }
                            this.selectedItems.push(selectedItem);
                            this.tempSelectedItems.push(selectedItem);
                        } else if(item.isItemSelectedForSelectionView  && item.eventOwnerId && (item.eventOwnerId != this.authService.user.systemUserID)) {
                            let selectedItem:MainCardViewDataModel = {
                                ...selectorItem,
                                showEndIcon: false,
                                isMainToolTemplate: true,
                                noSeparationLine: false,
                                clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
                            }
                            this.selectedItems.push(selectedItem);
                            this.tempSelectedItems.push(selectedItem);
                        }
                        else if (item.isItemSelectedForSelectionView  && !item.eventOwnerId ){
                            let selectedItem:MainCardViewDataModel = {
                                ...selectorItem,
                                endIconType: 'indegene-selectors-remove-icon',
                                endIconCssClass: 'remove-icon',
                                isMainToolTemplate: true,
                                noSeparationLine: false,
                                clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
                            }
                            this.selectedItems.push(selectedItem);
                            this.tempSelectedItems.push(selectedItem);
                        }
                      }
                    });
                  }
                } else {
                this.listData = [];
                this.selectedItems = [];
                this.tempSelectedItems = [];
                if (!_.isEmpty(this.viewData.data)) {
                  this.viewData.data.forEach(item => {
                    if (!_.isEmpty(item)) {
                      let selectorItem:MainCardViewDataModel = {
                        ...item,
                        isMainToolTemplate: true,
                        noSeparationLine: false,
                        clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
                      }
                      if(selectorItem.childItems) {
                        selectorItem.noLeftMarginLabel = true;
                        selectorItem.showIcon = true;
                        selectorItem.iconName = 'chevron-down-outline';
                        selectorItem.isExpanded = true;
                        selectorItem.childItems.forEach(o=>{
                          o.isMainToolTemplate = true;
                          o.noSeparationLine = false;
                          o.clickHandler = (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data)
                        })
                      }
                      this.listData.push(selectorItem);
                      //construct selected Items array
                      if(!this.isHierarchyView && item.isItemSelectedForSelectionView){
                          let selectedItem:MainCardViewDataModel = {
                              ...selectorItem,
                              endIconType: 'indegene-selectors-remove-icon',
                              endIconCssClass: 'remove-icon',
                              isMainToolTemplate: true,
                              noSeparationLine: false,
                              noLeftMarginLabel: this.sampleService.isHandleElligibleProductSelect ? false : selectorItem.noLeftMarginLabel,
                              showIcon: this.sampleService.isHandleElligibleProductSelect ? false : selectorItem.showIcon,
                              iconName: this.sampleService.isHandleElligibleProductSelect ? '' : selectorItem.iconName,
                              isExpanded: this.sampleService.isHandleElligibleProductSelect ? false : selectorItem.isExpanded,
                              clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
                          }
                          this.selectedItems.push(selectedItem);
                          this.tempSelectedItems.push(selectedItem);
                      }
                    }
                  });
                }
            }
            }else{
                this.listData = this.viewData?.data || [];
                this.selectedItems = [];
                if (!_.isEmpty(this.viewData.data)) {
                  this.viewData.data.forEach(item => {
                    if (!_.isEmpty(item)) {
                      if(!this.isHierarchyView && item.isItemSelectedForSelectionView){
                        this.selectedItems.push(item);
                    } 
                    }
                  });
                }
            }
            this.filteredData = this.listData;
            this.itemsToDisplay = this.sliceItems(0, this.recordCount);
            this.initSelectListHeaderLeft();
              this.initSectionHeader();
            if(!this.isGroupedView && !this.isHierarchyView && !this.disableSorting) this._sortItemsList(true);
            else if(this.isGroupedView) this.childrenLength = this.filteredData.filter(o=>o.showEndIcon).length;
            else this.totalLength = this.getLengthRecursively(this.filteredData);
            if(this.isHierarchyView){
              this.getSelectedItemsRecursively(this.listData);
            }
            this.preventDeSelect = !!this.viewData.preventDeSelect;
            try {
              setTimeout(()=> {
                this.uiService.dismissLoader();
              },200)
            } catch (error) {}
        }
    }

    private _sortItemsList(isInitial:boolean = false){
        let sortByPropertyName:string;
        if(this.filteredData && this.filteredData.length != 0){
            if(this.filteredData[0].primaryTextRight){
                sortByPropertyName = 'primaryTextRight';
            }else{
                sortByPropertyName = 'primaryTextLeft';
            }
        }
        if(this.viewData && this.viewData.orderByPropertyName){
            sortByPropertyName = this.viewData.orderByPropertyName
        }
        if(sortByPropertyName){
            if(this.filteredData.length !== 0){
                this.filteredData = this.filteredData.sort((a,b)=> (a[sortByPropertyName]?.toLowerCase()>b[sortByPropertyName]?.toLowerCase()?1:-1));
            }
            if(this.selectedItems.length !== 0){
                this.selectedItems = this.selectedItems.sort((a,b)=> (a[sortByPropertyName]?.toLowerCase()>b[sortByPropertyName]?.toLowerCase()?1:-1));
            }
            if(this.listData.length !== 0 && isInitial){
                this.listData = this.listData.sort((a,b)=> (a[sortByPropertyName]?.toLowerCase()>b[sortByPropertyName]?.toLowerCase()?1:-1));
            }
        }
        this.itemsToDisplay = this.sliceItems(0, this.recordCount);
    }

    private _handleAddSelectionEvent(data,id) {
        data.endIconType = 'indegene-selectors-checkmark-icon';
        data.endIconCssClass = 'checkmark-icon';
        let foundAddedItem;
        let foundMainAddedItem;
        if(!this.isHierarchyView){
          foundAddedItem = this.filteredData.find(item => item.id == id && (!data.parentID || data.parentID == item.parentID));
          foundMainAddedItem = this.listData.find(item => item.id == id && (!data.parentID || data.parentID == item.parentID));
        }
        else{
          foundAddedItem = this.findHierarchyItem(id, data, this.filteredData);
          // this.filteredData.forEach(item => {
          //   if(item.id == id && (!data.parentID || data.parentID == item.parentID)) foundAddedItem = item;
          //   else (item.childItems.forEach(o=>{
          //     if(o.id == id && (!data.parentID || data.parentID == o.parentID)) foundAddedItem = o;
          //   }))
          // });
          foundMainAddedItem = this.findHierarchyItem(id, data, this.listData);
          // this.listData.forEach(item => {
          //   if(item.id == id && (!data.parentID || data.parentID == item.parentID)) foundMainAddedItem = item;
          //   else (item.childItems.forEach(o=>{
          //     if(o.id == id && (!data.parentID || data.parentID == o.parentID)) foundMainAddedItem = o;
          //   }))
          // });
        }
        if (foundAddedItem) {
          data.endIconType = 'indegene-selectors-checkmark-icon';
          data.endIconCssClass = 'checkmark-icon';
        }
        if (foundMainAddedItem) {
          data.endIconType = 'indegene-selectors-checkmark-icon';
          data.endIconCssClass = 'checkmark-icon';
        }
        let selectorItem: MainCardViewDataModel = {
            ...foundMainAddedItem,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            isMainToolTemplate: true,
            noSeparationLine: false,
            clickHandler: (id: string, event, target, data) => this._handleSelectionEvents(id, event, target, data),
        }
        selectorItem.showIcon = false;
        selectorItem.noLeftMarginLabel = false;
        this.selectedItems.push(selectorItem);
        this.initSectionHeader();
    }

    private _handleRemoveSelectionEvent(id, data?) {
        let idx = this.selectedItems.findIndex(item => item.id == id && (!data.parentID || data.parentID == item.parentID));
        if (idx >= 0) {
            this.selectedItems.splice(idx, 1);
        }
        if(!this.isHierarchyView){
          let foundRemovedItemIdx = this.filteredData.findIndex(item => item.id == id && (!data.parentID || data.parentID == item.parentID));
          if (foundRemovedItemIdx >= 0) {
              let selectorItem: MainCardViewDataModel = {
                  ...this.filteredData[foundRemovedItemIdx],
                  endIconType: 'indegene-selectors-add-icon',
                  endIconCssClass: 'add-icon',
                  isMainToolTemplate: true,
                  noSeparationLine: false,
                  clickHandler: (id: string, event, target, data) => this._handleSelectionEvents(id, event, target, data),
              }
              this.filteredData.splice(foundRemovedItemIdx, 1, selectorItem);
          }
          let foundMainRemovedItemIdx = this.listData.findIndex(item => item.id == id && (!data.parentID || data.parentID == item.parentID));
          if (foundMainRemovedItemIdx >= 0) {
              let selectorItem: MainCardViewDataModel = {
                  ...this.listData[foundMainRemovedItemIdx],
                  endIconType: 'indegene-selectors-add-icon',
                  endIconCssClass: 'add-icon',
                  isMainToolTemplate: true,
                  noSeparationLine: false,
                  clickHandler: (id: string, event, target, data) => this._handleSelectionEvents(id, event, target, data),
              }
              this.listData.splice(foundMainRemovedItemIdx, 1, selectorItem);
          }
          this.itemsToDisplay = this.sliceItems(0, this.recordCount);
        }
        else{
          let foundAddedItem = this.findHierarchyItem(id, data, this.filteredData);
          let foundMainAddedItem = this.findHierarchyItem(id, data, this.listData);
          foundAddedItem.endIconType = 'indegene-selectors-add-icon';
          foundAddedItem.endIconCssClass = 'add-icon';
          foundMainAddedItem.endIconType = 'indegene-selectors-add-icon';
          foundMainAddedItem.endIconCssClass = 'add-icon';
        }
        this.initSectionHeader();
    }

    private _handleSelectionEvents(id,event,target,data:MainCardViewDataModel){
        if(id && target && target == 'endIcon'){
          if(data && data.showEndIcon){
            switch (data.endIconType){
              case 'indegene-selectors-add-icon':
                if(this.viewData.listSelectionType == MainToolTemplateListSelectionType.SINGLESELECTION && this.selectedItems && this.selectedItems.length != 0){
                    this._handleRemoveSelectionEvent(this.selectedItems[0].id, this.selectedItems[0]);
                }
                this._handleAddSelectionEvent(data,id);
                break;
              case 'indegene-selectors-remove-icon':
                this._handleRemoveSelectionEvent(id, data);
                break;
            }
          }
          this.initSelectListHeaderLeft();
          if(!this.isGroupedView && !this.disableSorting) this._sortItemsList();
        }
        if(target && target=='startIcon'){
          data.isExpanded = !data.isExpanded;
          data.iconName = data.isExpanded?'chevron-down-outline':'chevron-forward-outline'
        }
        if(target && target=='mainItem'){
          this.listData.forEach(data=>{
            data.isSelected = false;
            if(data.id === id) data.isSelected = true;
          });
          this.filteredData.forEach(data=>{
            data.isSelected = false;
            if(data.id === id) data.isSelected = true;
          });
        }
        if(this.viewData && this.viewData.eventsHandler){
            let passedData = {
                id: id,
                event: event,
                target: target,
                data : data,
            };
            this.viewData.eventsHandler(passedData,`MainItemEvents-${target}`,this.viewData);
        }
      }

    public searchText(ev):void {
        let val:string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
        if(this.viewData.minSearchLength){
            if(val.length >= this.viewData.minSearchLength){
                this.doSearch(val);
                 this.searchActive = true;
            }else {
                this.filteredData = this.listData;
                if(this.isHierarchyView) this.totalLength = this.getLengthRecursively(this.filteredData);
                this.searchActive = false;
            }
        }else if(val.length >= MIN_SEARCH_LENGTH){
            this.doSearch(val);
             this.searchActive = true;
        }else {
            this.filteredData = this.listData;
            if(this.isHierarchyView) this.totalLength = this.getLengthRecursively(this.filteredData);
            this.searchActive = false;
        }
        this.itemsToDisplay = this.sliceItems(0, this.recordCount);
    }

    public clearSearch(ev){
      this.filteredData = this.listData;
      if(this.isHierarchyView) this.totalLength = this.getLengthRecursively(this.filteredData);
      this.itemsToDisplay = this.sliceItems(0, this.recordCount);
      this.searchActive = false;
    }

    private doSearch(val:string){
        if(this.viewData.searchHandler){
          let ids:string[] = this.viewData.searchHandler(val);
          if(this.viewData.title == this.translate.instant("SEARCH_ELIGIBLE_PRODUCTS")){
            this.filteredData = this.listData.filter( data => {
                return ids.some(str=> str.concat('_').concat(data.skuId) == data.id);
            });
          }else{
            this.filteredData = this.listData.filter( data => {
                return ids.some(str=> str == data.id);
            });
          }
        }else{
          if(!this.isHierarchyView){
            this.filteredData = this.listData.filter( data => {
                let str:string = '';
                if(data.primaryTextLeft) str += data.primaryTextLeft.trim();
                if(data.primaryTextRight) str += data.primaryTextRight.trim();
                return str.toLowerCase().includes(val.toLowerCase());
            });
          }
          else{
            this.filteredData = this.searchRecursively(val, this.listData);
            this.totalLength = this.getLengthRecursively(this.filteredData);
          }
        }
    }

    public get noChanges(): boolean {
      if (
        this.preventDeSelect
        && this.tempSelectedItems.length > 0
        && this.selectedItems.length === 0
      ) {
        // DeSelection is not allowed
        return true;
      }
      if(!this.isGroupedView && !this.isHierarchyView) return (_.xor(this.selectedItems.map(item=> item.id),this.tempSelectedItems.map(item=> item.id)).length === 0);
      else if(!this.isHierarchyView){
        let selectedIDs = this.selectedItems.map(item=> 'id_'+item.id +'parent_'+item.parentID)
        let tempSelectedIDs = this.tempSelectedItems.map(item=> 'id_'+item.id +'parent_'+item.parentID)
        return (_.xor(selectedIDs,tempSelectedIDs).length === 0);
      }
      else{
        let selectedIDs = this.selectedItems.map(item=> 'id_'+item.id +'parent_'+item.parentID+'uniqueHierarchyIdentifier_'+item.uniqueHierarchyIdentifier)
        let tempSelectedIDs = this.tempSelectedItems.map(item=> 'id_'+item.id +'parent_'+item.parentID+'uniqueHierarchyIdentifier_'+item.uniqueHierarchyIdentifier)
        return (_.xor(selectedIDs,tempSelectedIDs).length === 0);
      }
    }

    public async goBack(flag:boolean){
        let data;
        if (!flag) {
          this.events.publish('updateMandatoryField', PageName.MainToolTemplateComponent);
        }
        if(flag){
            let removedItems:MainCardViewDataModel[] = this.tempSelectedItems.filter((c) =>!this.selectedItems.some(t => t.id == c.id));
            if(this.viewData && this.viewData.confirmationCheckRequired){
                await this.uiService.displayAlertConfirmation(this.viewData.confirmationAlertData).then(info => {
                    data = {
                        selectedItems: this.selectedItems,
                        removedItems: removedItems,
                        isDone:true,
                    };
                }).catch(error => {
                    // Cancel button Action
                });
            }else{
                data = {
                    selectedItems: this.selectedItems,
                    removedItems: removedItems,
                    isDone:true,
                };
            }
        }
        //this.viewCtrl.dismiss(data, null, this.viewData.navOptions);
        if(this.viewData && this.viewData.eventsHandler){
            this.viewData.eventsHandler(data,(flag)?'RightHeaderButtonClick':'LeftHeaderButtonClick',this.viewData);
        }
        if(this.navService.getCurrentMasterPageName() === PageName.MainToolTemplateComponent){
            this.navService.popWithPageTracking();
        } else if (this.isNavWithinModalView) {
            if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
                document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
            }
            this.navControl.pop({ progressAnimation: false }).then(() => {});
        }
        else{
          this.modalCtrl.dismiss(data, null, this.viewData.navOptions);
        }
    }
    public onPageTitleControlClick(id: string) {
      switch (id) {
        case 'cancel':
          this.goBack(false);
          break;
        case 'done':
          this.goBack(true);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }

    private initSelectListHeaderLeft(): void {
      let buttons = [];
      if(this.viewData.showLeftHeaderButton) {
        buttons.push({
            id: "cancel",
            imgSrc: this.viewData.leftHeaderBtnImgSrc ? this.viewData.leftHeaderBtnImgSrc : '',
            cssClass: 'seventyPercentWidth',
            name: this.viewData.leftHeaderBtnText ? this.viewData.leftHeaderBtnText : this.translate.instant('CANCEL'),
            isDisabled: false,
            align: "left",
          },
        );
      }
      if(this.viewData.showRightHeaderButton) {
        buttons.push({
          id: "done",
          imgSrc: this.viewData.rightHeaderBtnImgSrc ? this.viewData.rightHeaderBtnImgSrc : '',
          cssClass: 'seventyPercentWidth',
          name: this.viewData.rightHeaderBtnText ? this.viewData.rightHeaderBtnText : this.translate.instant('DONE'),
          isDisabled: this.noChanges,
          align: "right",
          }
        );
      }
      this.indHeaderLeftModel = {
        id: 'main-tool-template-header-left',
        cssClass: 'main-tool-header-title',
        title: this.viewData ? this.viewData.title : '',
        mode: false,
        customHeaderProps: { hasCancel: true },
        controls: buttons,
      };

      if(this.viewData && this.viewData.searchTitle){
        this.searchInput = this.viewData.searchTitle;
      }
    }

    private findHierarchyItem(id, data, masterList){
      let foundAddedItem;
      masterList.forEach(item => {
        if(!foundAddedItem){
          if(item.id == id
            && (!data.parentID || data.parentID == item.parentID)
            && (!data.uniqueHierarchyIdentifier || data.uniqueHierarchyIdentifier == item.uniqueHierarchyIdentifier)
          ) foundAddedItem = item;
          if(!foundAddedItem && item.childItems && item.childItems.length){
            foundAddedItem = this.findHierarchyItem(id, data, item.childItems);
          }
        }
      });
      return foundAddedItem;
    }

    private searchRecursively(val:string, list:MainCardViewDataModel[]){
      let matchedResultsCurrentLevel = [];
      list.forEach(data=>{
        if(!data.childItems || !data.childItems.length) {
          let str:string = '';
          if(data.primaryTextLeft) str += data.primaryTextLeft.trim();
          if(data.primaryTextRight) str += data.primaryTextRight.trim();
          if(str.toLowerCase().includes(val.toLowerCase())) matchedResultsCurrentLevel.push(data);
        }
        else {
          let matchedChildRes =  this.searchRecursively(val, data.childItems);
          let _data:MainCardViewDataModel = JSON.parse(JSON.stringify(data));
          _data.childItems = matchedChildRes;
          _data.clickHandler = data.clickHandler;
          if(matchedChildRes.length){
            _data.showIcon = true;
            _data.noLeftMarginLabel = true;
            matchedResultsCurrentLevel.push(_data)
          }
          else{
            _data.showIcon = false;
            _data.noLeftMarginLabel = false;
            let str:string = '';
            if(_data.primaryTextLeft) str += _data.primaryTextLeft.trim();
            if(_data.primaryTextRight) str += _data.primaryTextRight.trim();
            if(str.toLowerCase().includes(val.toLowerCase())) matchedResultsCurrentLevel.push(_data);
          }
        }
      })
      return matchedResultsCurrentLevel;
    }

    private getLengthRecursively(list){
      let counter = 0;
      list.forEach(o=>{
        counter++;
        if(o.childItems && o.childItems.length){
          counter += this.getLengthRecursively(o.childItems);
        }
      })
      return counter;
    }

    private getSelectedItemsRecursively(list: MainCardViewDataModel[]){
      list.forEach(o=>{
        if(o.isItemSelectedForSelectionView){
          let selectedItem:MainCardViewDataModel = {
            ...o,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            isMainToolTemplate: true,
            noSeparationLine: false,
            clickHandler: (id: string, event,target,data) => this._handleSelectionEvents(id,event,target,data),
        }
        this.selectedItems.push(selectedItem);
        this.tempSelectedItems.push(selectedItem);
        }
        if(o.childItems && o.childItems.length) this.getSelectedItemsRecursively(o.childItems);
      })
    }

    public doInfinite(eventDetails,event) {
      this.itemsToDisplay.push(...this.sliceItems(this.recordCount, 30));
      this.recordCount = this.itemsToDisplay.length;
      event.target.complete();
    }

    private sliceItems(startIndex: number, count: number) {
      return this.filteredData.length < startIndex + count ? this.filteredData.slice(startIndex) : this.filteredData.slice(startIndex, startIndex + count);;
    }
}
