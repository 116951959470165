export interface ISecInfoConfig{

  seperator?: string;
  entityName?: string;
  customText?: string;
  alias?: string;
  name?: string;
  from?: string;
  to?: string;
  navigationPropertyName?: string;
  attributes?: ISecInfoConfig[];
  filter?: IFilter;

}

export enum SecondaryInfoEntityName {
  "Contact" = "contact",
  "Account" = "account",
  "Appointment" = "appointment",
  "SalesOrder" = "salesorder",
  "Kitbooking" = "indskr_setbooking"
}

export interface IFilterCondition {
  attribute?: string;
  op?: string;
  value?: string;
 }
 export interface IFilter {
  type: 'and' | 'or'
  conditions: IFilterCondition[]
 }

 export const DEFAULT_ACCOUNT_SCONDARY_INFO= {
  "indskr_entityname": "account",
  "indskr_secondaryinfoconfigdata": {
    "seperator": ",",
    "entityName": "account",
    "attributes": [
      {
        "id": "97013a60-1699-498d-bee8-b4516f7b9123",
        "type": "Picklist",
        "typeName": { "Value": "PicklistType" },
        "name": "indskr_accounttype",
        "displayName": "Type",
        "schemaName": "indskr_accounttype",
        "alias": "_aa"
      },
      {
        "collectionName": "indskr_indskr_customeraddress_v2s",
        "displayCollectionName": "Customer Addresses",
        "id": "1b2364f4-f3c0-e811-a978-000d3af49637",
        "schemaName": "indskr_account_indskr_indskr_customeraddress_v2_Customer",
        "name": "indskr_indskr_customeraddress_v2",
        "displayName": "Customer Addresses",
        "attributes": [
          {
            "collectionName": "indskr_addresses",
            "displayCollectionName": "Addresses (Omnipresence)",
            "id": "6c2160fa-f3c0-e811-a978-000d3af49637",
            "schemaName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
            "name": "indskr_address",
            "displayName": "Address (Omnipresence)",
            "attributes": [
              {
                "id": "8863c284-2245-40a3-ad64-e3cf3c761194",
                "type": "Lookup",
                "typeName": { "Value": "LookupType" },
                "name": "indskr_country_lu",
                "displayName": "Country",
                "schemaName": "indskr_Country_LU",
                "alias": "_ab"
              }
            ],
            "related": {
              "oneToMany": [
                {
                  "id": "6c2160fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Customer Addresses",
                  "name": "indskr_indskr_customeraddress_v2",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
                  "schemaName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "752160fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Recommended Engagement Periods",
                  "name": "indskr_rec_engagement_period",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_rec_engagement_period_Address",
                  "schemaName": "indskr_indskr_address_indskr_rec_engagement_period_Address",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "50c13b19-2cae-e911-a812-000d3af46757",
                  "displayName": "Adverse Events",
                  "name": "indskr_adverseevent",
                  "reverseNavigationPropertyName": "ind_ManufacturerAddress",
                  "navigationPropertyName": "ind_indskr_address_indskr_adverseevent_ManufacturerAddress",
                  "schemaName": "ind_indskr_address_indskr_adverseevent_ManufacturerAddress",
                  "to": "indskr_addressid",
                  "from": "ind_manufactureraddress",
                  "type": "oneToMany"
                },
                {
                  "id": "c90a3be0-0b96-ea11-a811-000d3a1abbdd",
                  "displayName": "Address Positions",
                  "name": "indskr_addressposition",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_addressposition",
                  "schemaName": "indskr_indskr_address_indskr_addressposition",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "e3580d91-1f96-ea11-a811-000d3a1abbdd",
                  "displayName": "Position Addresses",
                  "name": "indskr_positionaddress",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_positionaddress",
                  "schemaName": "indskr_indskr_address_indskr_positionaddress",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "cb72f85f-5803-eb11-a813-000d3a1ab78a",
                  "displayName": "Customer Address Change Requests",
                  "name": "indskr_customeraddresscr",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_customeraddresscr_Address",
                  "schemaName": "indskr_indskr_address_indskr_customeraddresscr_Address",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                }
              ],
              "manyToOne": [
                {
                  "id": "bb2260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Area",
                  "name": "indskr_lu_area",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_area_indskr_address_subbrick",
                  "navigationPropertyName": "indskr_subbrick",
                  "schemaName": "indskr_indskr_lu_area_indskr_address_subbrick",
                  "from": "indskr_lu_areaid",
                  "to": "indskr_subbrick",
                  "type": "manyToOne"
                },
                {
                  "id": "c42260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "City",
                  "name": "indskr_lu_city",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_city_indskr_address_City_lu",
                  "navigationPropertyName": "indskr_City_lu",
                  "schemaName": "indskr_indskr_lu_city_indskr_address_City_lu",
                  "from": "indskr_lu_cityid",
                  "to": "indskr_city_lu",
                  "type": "manyToOne"
                },
                {
                  "id": "df2260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Country",
                  "name": "indskr_lu_country",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_country_indskr_address_Country_LU",
                  "navigationPropertyName": "indskr_Country_LU",
                  "schemaName": "indskr_indskr_lu_country_indskr_address_Country_LU",
                  "from": "indskr_lu_countryid",
                  "to": "indskr_country_lu",
                  "type": "manyToOne"
                },
                {
                  "id": "e82260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "County/District",
                  "name": "indskr_lu_district",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_district_indskr_address_district",
                  "navigationPropertyName": "indskr_district",
                  "schemaName": "indskr_indskr_lu_district_indskr_address_district",
                  "from": "indskr_lu_districtid",
                  "to": "indskr_district",
                  "type": "manyToOne"
                },
                {
                  "id": "f12260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Geographical Classification",
                  "name": "indskr_lu_geographicclassification",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_geographicclassification_indskr_address_GeographicClassification",
                  "navigationPropertyName": "indskr_geographic_classification",
                  "schemaName": "indskr_indskr_lu_geographicclassification_indskr_address_GeographicClassification",
                  "from": "indskr_lu_geographicclassificationid",
                  "to": "indskr_geographic_classification",
                  "type": "manyToOne"
                },
                {
                  "id": "fa2260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Postal Code",
                  "name": "indskr_lu_postalcode",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_postalcode_indskr_address_postalcode_lu",
                  "navigationPropertyName": "indskr_postalcode_lu",
                  "schemaName": "indskr_indskr_lu_postalcode_indskr_address_postalcode_lu",
                  "from": "indskr_lu_postalcodeid",
                  "to": "indskr_postalcode_lu",
                  "type": "manyToOne"
                },
                {
                  "id": "142360fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Region",
                  "name": "indskr_lu_region",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_region_indskr_address_Region",
                  "navigationPropertyName": "indskr_luregion",
                  "schemaName": "indskr_indskr_lu_region_indskr_address_Region",
                  "from": "indskr_lu_regionid",
                  "to": "indskr_luregion",
                  "type": "manyToOne"
                },
                {
                  "id": "212360fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "State",
                  "name": "indskr_lu_state",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_state_indskr_address_State_lu",
                  "navigationPropertyName": "indskr_State_lu",
                  "schemaName": "indskr_indskr_lu_state_indskr_address_State_lu",
                  "from": "indskr_lu_stateid",
                  "to": "indskr_state_lu",
                  "type": "manyToOne"
                }
              ],
              "manyToMany": []
            },
            "reverseNavigationPropertyName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
            "navigationPropertyName": "indskr_Address",
            "from": "indskr_addressid",
            "to": "indskr_address",
            "type": "manyToOne",
            "alias": "_ac"
          }
        ],
        "related": {
          "oneToMany": [
            {
              "id": "9aba627f-96f4-e811-a975-000d3af49211",
              "displayName": "Allocation Orders",
              "name": "indskr_sampledrop",
              "reverseNavigationPropertyName": "indskr_customeraddress_indskr_sampledrop",
              "navigationPropertyName": "indskr_indskr_indskr_customeraddress_v2_indskr_sampledrop_customeraddress",
              "schemaName": "indskr_indskr_indskr_customeraddress_v2_indskr_sampledrop_customeraddress",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddress",
              "type": "oneToMany"
            },
            {
              "id": "d82160fa-f3c0-e811-a978-000d3af49637",
              "displayName": "Customer Availabilities",
              "name": "indskr_customeravailability_v2",
              "reverseNavigationPropertyName": "indskr_CustomerAddressId",
              "navigationPropertyName": "indskr_indskr_customeraddressv2_customeravailabi",
              "schemaName": "indskr_indskr_customeraddressv2_customeravailabi",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddressid",
              "type": "oneToMany"
            },
            {
              "id": "7b903194-d70e-ea11-a811-000d3af46fff",
              "displayName": "Approvals",
              "name": "indskr_approvalactivity",
              "reverseNavigationPropertyName": "indskr_CustomerAddress",
              "navigationPropertyName": "indskr_indskr_indskr_customeraddress_v2_indskr_approvalactivity_CustomerAddress",
              "schemaName": "indskr_indskr_indskr_customeraddress_v2_indskr_approvalactivity_CustomerAddress",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddress",
              "type": "oneToMany"
            },
            {
              "id": "e0478ceb-d879-ea11-a811-000d3a1ab78a",
              "displayName": "Customer Licenses",
              "name": "indskr_customerlicense",
              "reverseNavigationPropertyName": "indskr_CustomerAddress",
              "navigationPropertyName": "indskr_indskr_indskr_customeraddress_v2_indskr_customerlicense_CustomerAddress",
              "schemaName": "indskr_indskr_indskr_customeraddress_v2_indskr_customerlicense_CustomerAddress",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddress",
              "type": "oneToMany"
            }
          ],
          "manyToOne": [
            {
              "id": "1b2364f4-f3c0-e811-a978-000d3af49637",
              "displayName": "Account",
              "name": "account",
              "reverseNavigationPropertyName": "indskr_account_indskr_indskr_customeraddress_v2_Customer",
              "navigationPropertyName": "indskr_Customer_account",
              "schemaName": "indskr_account_indskr_indskr_customeraddress_v2_Customer",
              "from": "accountid",
              "to": "indskr_customer",
              "type": "manyToOne"
            },
            {
              "id": "c12564f4-f3c0-e811-a978-000d3af49637",
              "displayName": "Contact",
              "name": "contact",
              "reverseNavigationPropertyName": "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
              "navigationPropertyName": "indskr_Customer_contact",
              "schemaName": "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
              "from": "contactid",
              "to": "indskr_customer",
              "type": "manyToOne"
            },
            {
              "id": "6c2160fa-f3c0-e811-a978-000d3af49637",
              "displayName": "Address (Omnipresence)",
              "name": "indskr_address",
              "reverseNavigationPropertyName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
              "navigationPropertyName": "indskr_Address",
              "schemaName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
              "from": "indskr_addressid",
              "to": "indskr_address",
              "type": "manyToOne"
            },
            {
              "id": "e1723230-dd0e-ea11-a811-000d3af46fff",
              "displayName": "Approval Configuration",
              "name": "indskr_approval_configuration",
              "reverseNavigationPropertyName": "indskr_indskr_approval_configuration_indskr_indskr_customeraddress_v2_ApprovalConfiguration",
              "navigationPropertyName": "indskr_ApprovalConfiguration",
              "schemaName": "indskr_indskr_approval_configuration_indskr_indskr_customeraddress_v2_ApprovalConfiguration",
              "from": "indskr_approval_configurationid",
              "to": "indskr_approvalconfiguration",
              "type": "manyToOne"
            },
            {
              "id": "810d79b6-5803-eb11-a813-000d3a1ab78a",
              "displayName": "Contact Change Request",
              "name": "indskr_contactcr",
              "reverseNavigationPropertyName": "indskr_indskr_contactcr_indskr_indskr_customeraddress_v2_ContactCR",
              "navigationPropertyName": "indskr_ContactCR",
              "schemaName": "indskr_indskr_contactcr_indskr_indskr_customeraddress_v2_ContactCR",
              "from": "indskr_contactcrid",
              "to": "indskr_contactcr",
              "type": "manyToOne"
            },
            {
              "id": "7ddcddc8-5803-eb11-a813-000d3a1ab78a",
              "displayName": "Account Change Request",
              "name": "indskr_accountcr",
              "reverseNavigationPropertyName": "indskr_indskr_accountcr_indskr_indskr_customeraddress_v2_AccountCR",
              "navigationPropertyName": "indskr_AccountCR",
              "schemaName": "indskr_indskr_accountcr_indskr_indskr_customeraddress_v2_AccountCR",
              "from": "indskr_accountcrid",
              "to": "indskr_accountcr",
              "type": "manyToOne"
            }
          ],
          "manyToMany": []
        },
        "reverseNavigationPropertyName": "indskr_Customer_account",
        "navigationPropertyName": "indskr_account_indskr_indskr_customeraddress_v2_Customer",
        "to": "accountid",
        "from": "indskr_customer",
        "type": "oneToMany",
        "filter": {
          "type": "and",
          "conditions": [
            { "attribute": "indskr_isprimary", "op": "eq", "value": "true" }
          ]
        },
        "alias": "_ad"
      }
    ]
  }
};

 export const DEFAULT_CONTACT_SECONDARY_INFO = {
  "indskr_entityname": "contact",
  "indskr_secondaryinfoconfigdata": {
    "seperator": ",",
    "entityName": "contact",
    "attributes": [
      {
        "id": "ee7d17aa-7306-4c44-b8f4-dbb9931980c3",
        "type": "Lookup",
        "typeName": { "Value": "LookupType" },
        "name": "indskr_lu_specialtyid",
        "displayName": "Primary Specialty",
        "schemaName": "indskr_lu_specialtyid",
        "alias": "_ca"
      },
      {
        "id": "d364f768-e655-4f1b-9cbf-4e92dd882837",
        "type": "Lookup",
        "typeName": { "Value": "LookupType" },
        "name": "indskr_primaryaccount",
        "displayName": "Primary Account",
        "schemaName": "indskr_PrimaryAccount",
        "alias": "_cb"
      },
      {
        "collectionName": "indskr_indskr_customeraddress_v2s",
        "displayCollectionName": "Customer Addresses",
        "id": "c12564f4-f3c0-e811-a978-000d3af49637",
        "schemaName": "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
        "name": "indskr_indskr_customeraddress_v2",
        "displayName": "Customer Addresses",
        "attributes": [
          {
            "collectionName": "indskr_addresses",
            "displayCollectionName": "Addresses (Omnipresence)",
            "id": "6c2160fa-f3c0-e811-a978-000d3af49637",
            "schemaName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
            "name": "indskr_address",
            "displayName": "Address (Omnipresence)",
            "attributes": [
              {
                "id": "cc371eb6-1c61-406b-b105-e025d61f3824",
                "type": "Lookup",
                "typeName": { "Value": "LookupType" },
                "name": "indskr_city_lu",
                "displayName": "City",
                "schemaName": "indskr_City_lu",
                "alias": "_cd"
              },
              {
                "id": "7c9970fb-6fda-461e-b4a7-cc145c41ac7b",
                "type": "Lookup",
                "typeName": { "Value": "LookupType" },
                "name": "indskr_state_lu",
                "displayName": "State/Province",
                "schemaName": "indskr_State_lu",
                "alias": "_ce"
              }
            ],
            "related": {
              "oneToMany": [
                {
                  "id": "6c2160fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Customer Addresses",
                  "name": "indskr_indskr_customeraddress_v2",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
                  "schemaName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "752160fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Recommended Engagement Periods",
                  "name": "indskr_rec_engagement_period",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_rec_engagement_period_Address",
                  "schemaName": "indskr_indskr_address_indskr_rec_engagement_period_Address",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "50c13b19-2cae-e911-a812-000d3af46757",
                  "displayName": "Adverse Events",
                  "name": "indskr_adverseevent",
                  "reverseNavigationPropertyName": "ind_ManufacturerAddress",
                  "navigationPropertyName": "ind_indskr_address_indskr_adverseevent_ManufacturerAddress",
                  "schemaName": "ind_indskr_address_indskr_adverseevent_ManufacturerAddress",
                  "to": "indskr_addressid",
                  "from": "ind_manufactureraddress",
                  "type": "oneToMany"
                },
                {
                  "id": "c90a3be0-0b96-ea11-a811-000d3a1abbdd",
                  "displayName": "Address Positions",
                  "name": "indskr_addressposition",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_addressposition",
                  "schemaName": "indskr_indskr_address_indskr_addressposition",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "e3580d91-1f96-ea11-a811-000d3a1abbdd",
                  "displayName": "Position Addresses",
                  "name": "indskr_positionaddress",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_positionaddress",
                  "schemaName": "indskr_indskr_address_indskr_positionaddress",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                },
                {
                  "id": "cb72f85f-5803-eb11-a813-000d3a1ab78a",
                  "displayName": "Customer Address Change Requests",
                  "name": "indskr_customeraddresscr",
                  "reverseNavigationPropertyName": "indskr_Address",
                  "navigationPropertyName": "indskr_indskr_address_indskr_customeraddresscr_Address",
                  "schemaName": "indskr_indskr_address_indskr_customeraddresscr_Address",
                  "to": "indskr_addressid",
                  "from": "indskr_address",
                  "type": "oneToMany"
                }
              ],
              "manyToOne": [
                {
                  "id": "bb2260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Area",
                  "name": "indskr_lu_area",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_area_indskr_address_subbrick",
                  "navigationPropertyName": "indskr_subbrick",
                  "schemaName": "indskr_indskr_lu_area_indskr_address_subbrick",
                  "from": "indskr_lu_areaid",
                  "to": "indskr_subbrick",
                  "type": "manyToOne"
                },
                {
                  "id": "c42260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "City",
                  "name": "indskr_lu_city",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_city_indskr_address_City_lu",
                  "navigationPropertyName": "indskr_City_lu",
                  "schemaName": "indskr_indskr_lu_city_indskr_address_City_lu",
                  "from": "indskr_lu_cityid",
                  "to": "indskr_city_lu",
                  "type": "manyToOne"
                },
                {
                  "id": "df2260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Country",
                  "name": "indskr_lu_country",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_country_indskr_address_Country_LU",
                  "navigationPropertyName": "indskr_Country_LU",
                  "schemaName": "indskr_indskr_lu_country_indskr_address_Country_LU",
                  "from": "indskr_lu_countryid",
                  "to": "indskr_country_lu",
                  "type": "manyToOne"
                },
                {
                  "id": "e82260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "County/District",
                  "name": "indskr_lu_district",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_district_indskr_address_district",
                  "navigationPropertyName": "indskr_district",
                  "schemaName": "indskr_indskr_lu_district_indskr_address_district",
                  "from": "indskr_lu_districtid",
                  "to": "indskr_district",
                  "type": "manyToOne"
                },
                {
                  "id": "f12260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Geographical Classification",
                  "name": "indskr_lu_geographicclassification",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_geographicclassification_indskr_address_GeographicClassification",
                  "navigationPropertyName": "indskr_geographic_classification",
                  "schemaName": "indskr_indskr_lu_geographicclassification_indskr_address_GeographicClassification",
                  "from": "indskr_lu_geographicclassificationid",
                  "to": "indskr_geographic_classification",
                  "type": "manyToOne"
                },
                {
                  "id": "fa2260fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Postal Code",
                  "name": "indskr_lu_postalcode",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_postalcode_indskr_address_postalcode_lu",
                  "navigationPropertyName": "indskr_postalcode_lu",
                  "schemaName": "indskr_indskr_lu_postalcode_indskr_address_postalcode_lu",
                  "from": "indskr_lu_postalcodeid",
                  "to": "indskr_postalcode_lu",
                  "type": "manyToOne"
                },
                {
                  "id": "142360fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "Region",
                  "name": "indskr_lu_region",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_region_indskr_address_Region",
                  "navigationPropertyName": "indskr_luregion",
                  "schemaName": "indskr_indskr_lu_region_indskr_address_Region",
                  "from": "indskr_lu_regionid",
                  "to": "indskr_luregion",
                  "type": "manyToOne"
                },
                {
                  "id": "212360fa-f3c0-e811-a978-000d3af49637",
                  "displayName": "State",
                  "name": "indskr_lu_state",
                  "reverseNavigationPropertyName": "indskr_indskr_lu_state_indskr_address_State_lu",
                  "navigationPropertyName": "indskr_State_lu",
                  "schemaName": "indskr_indskr_lu_state_indskr_address_State_lu",
                  "from": "indskr_lu_stateid",
                  "to": "indskr_state_lu",
                  "type": "manyToOne"
                }
              ],
              "manyToMany": []
            },
            "reverseNavigationPropertyName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
            "navigationPropertyName": "indskr_Address",
            "from": "indskr_addressid",
            "to": "indskr_address",
            "type": "manyToOne",
            "alias": "_cf"
          }
        ],
        "related": {
          "oneToMany": [
            {
              "id": "9aba627f-96f4-e811-a975-000d3af49211",
              "displayName": "Allocation Orders",
              "name": "indskr_sampledrop",
              "reverseNavigationPropertyName": "indskr_customeraddress_indskr_sampledrop",
              "navigationPropertyName": "indskr_indskr_indskr_customeraddress_v2_indskr_sampledrop_customeraddress",
              "schemaName": "indskr_indskr_indskr_customeraddress_v2_indskr_sampledrop_customeraddress",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddress",
              "type": "oneToMany"
            },
            {
              "id": "d82160fa-f3c0-e811-a978-000d3af49637",
              "displayName": "Customer Availabilities",
              "name": "indskr_customeravailability_v2",
              "reverseNavigationPropertyName": "indskr_CustomerAddressId",
              "navigationPropertyName": "indskr_indskr_customeraddressv2_customeravailabi",
              "schemaName": "indskr_indskr_customeraddressv2_customeravailabi",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddressid",
              "type": "oneToMany"
            },
            {
              "id": "7b903194-d70e-ea11-a811-000d3af46fff",
              "displayName": "Approvals",
              "name": "indskr_approvalactivity",
              "reverseNavigationPropertyName": "indskr_CustomerAddress",
              "navigationPropertyName": "indskr_indskr_indskr_customeraddress_v2_indskr_approvalactivity_CustomerAddress",
              "schemaName": "indskr_indskr_indskr_customeraddress_v2_indskr_approvalactivity_CustomerAddress",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddress",
              "type": "oneToMany"
            },
            {
              "id": "e0478ceb-d879-ea11-a811-000d3a1ab78a",
              "displayName": "Customer Licenses",
              "name": "indskr_customerlicense",
              "reverseNavigationPropertyName": "indskr_CustomerAddress",
              "navigationPropertyName": "indskr_indskr_indskr_customeraddress_v2_indskr_customerlicense_CustomerAddress",
              "schemaName": "indskr_indskr_indskr_customeraddress_v2_indskr_customerlicense_CustomerAddress",
              "to": "indskr_indskr_customeraddress_v2id",
              "from": "indskr_customeraddress",
              "type": "oneToMany"
            }
          ],
          "manyToOne": [
            {
              "id": "1b2364f4-f3c0-e811-a978-000d3af49637",
              "displayName": "Account",
              "name": "account",
              "reverseNavigationPropertyName": "indskr_account_indskr_indskr_customeraddress_v2_Customer",
              "navigationPropertyName": "indskr_Customer_account",
              "schemaName": "indskr_account_indskr_indskr_customeraddress_v2_Customer",
              "from": "accountid",
              "to": "indskr_customer",
              "type": "manyToOne"
            },
            {
              "id": "c12564f4-f3c0-e811-a978-000d3af49637",
              "displayName": "Contact",
              "name": "contact",
              "reverseNavigationPropertyName": "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
              "navigationPropertyName": "indskr_Customer_contact",
              "schemaName": "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
              "from": "contactid",
              "to": "indskr_customer",
              "type": "manyToOne"
            },
            {
              "id": "6c2160fa-f3c0-e811-a978-000d3af49637",
              "displayName": "Address (Omnipresence)",
              "name": "indskr_address",
              "reverseNavigationPropertyName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
              "navigationPropertyName": "indskr_Address",
              "schemaName": "indskr_indskr_address_indskr_indskr_customeraddress_v2_Address",
              "from": "indskr_addressid",
              "to": "indskr_address",
              "type": "manyToOne"
            },
            {
              "id": "e1723230-dd0e-ea11-a811-000d3af46fff",
              "displayName": "Approval Configuration",
              "name": "indskr_approval_configuration",
              "reverseNavigationPropertyName": "indskr_indskr_approval_configuration_indskr_indskr_customeraddress_v2_ApprovalConfiguration",
              "navigationPropertyName": "indskr_ApprovalConfiguration",
              "schemaName": "indskr_indskr_approval_configuration_indskr_indskr_customeraddress_v2_ApprovalConfiguration",
              "from": "indskr_approval_configurationid",
              "to": "indskr_approvalconfiguration",
              "type": "manyToOne"
            },
            {
              "id": "810d79b6-5803-eb11-a813-000d3a1ab78a",
              "displayName": "Contact Change Request",
              "name": "indskr_contactcr",
              "reverseNavigationPropertyName": "indskr_indskr_contactcr_indskr_indskr_customeraddress_v2_ContactCR",
              "navigationPropertyName": "indskr_ContactCR",
              "schemaName": "indskr_indskr_contactcr_indskr_indskr_customeraddress_v2_ContactCR",
              "from": "indskr_contactcrid",
              "to": "indskr_contactcr",
              "type": "manyToOne"
            },
            {
              "id": "7ddcddc8-5803-eb11-a813-000d3a1ab78a",
              "displayName": "Account Change Request",
              "name": "indskr_accountcr",
              "reverseNavigationPropertyName": "indskr_indskr_accountcr_indskr_indskr_customeraddress_v2_AccountCR",
              "navigationPropertyName": "indskr_AccountCR",
              "schemaName": "indskr_indskr_accountcr_indskr_indskr_customeraddress_v2_AccountCR",
              "from": "indskr_accountcrid",
              "to": "indskr_accountcr",
              "type": "manyToOne"
            }
          ],
          "manyToMany": []
        },
        "reverseNavigationPropertyName": "indskr_Customer_contact",
        "navigationPropertyName": "indskr_contact_indskr_indskr_customeraddress_v2_Customer",
        "to": "contactid",
        "from": "indskr_customer",
        "type": "oneToMany",
        "filter": {
          "type": "and",
          "conditions": [
            { "attribute": "indskr_isprimary", "op": "eq", "value": "true" }
          ]
        },
        "alias": "_cg"
      }
    ]
  }
};
