import { Component, OnInit, OnChanges, OnDestroy, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { DeviceService } from '../../../services/device/device.service';
import { IoUserSelection } from '../../../services/io-userselection/io-userselection.service';
import { Subscription } from "rxjs";
import { NavigationService } from '../../../services/navigation/navigation.service';
import { TrackService } from "../../../services/logging/tracking.service";
import { ActivityService } from "../../../services/activity/activity.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { FeatureActionsMap, User } from "../../../classes/authentication/user.class";
import { RepServices } from "../../../data-services/rep/rep.services";
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ContactMeetingState } from "../../../classes/contact/contact.class";
import { IndHeaderLeftDataModel } from '../../../models/indHeaderLeftDataModel';
import { UIService } from '@omni/services/ui/ui.service';
import { IonNav } from '@ionic/angular';
import { EmailService } from '@omni/services/email-templates/email.service';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { PhoneActivity } from "@omni/classes/activity/phone.activity.class";
import { AppointmentActivity, CovisitorAccess } from "@omni/classes/activity/appointment.activity.class";
import { FooterService } from '@omni/services/footer/footer.service';
@Component({
    selector: 'io-multiselect',
    templateUrl: 'io-multiselect.html',
    styleUrls: ['io-multiselect.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IOMultiSelect implements OnChanges, OnInit, OnDestroy {

    @Input() caseSelector: string;
    @Input() selectionData: any[];
    @Input() selectedInput: any[];
    @Input() selectionFor: string;
    @Output() emitResult = new EventEmitter<any[]>(null);
    @Output() closeMultiSelect = new EventEmitter<boolean>(false);

    public isSearching: boolean = false;
    public itemsToDisplay:any[] = [];

    iCaseSelection: Subscription;
    iSelectedInput: Subscription;
    iSelectionData: Subscription;
    public MIN_SEARCH_LENGTH = 3;

    public userSelection: any[] = [];
    public searchResult: any[] = [];
    public searchValue: string = "";
    public isReadOnlyMode: boolean = false;
    public remoteMeetingState = ContactMeetingState;
    public sectionHeaderTitle: string = "";
    public isFromAdditionalConfigFields: boolean = false;
    @Input() remoteMeetingConnectionMapping = false;
    @Input() onSelectDone: (data:any) => void;
    indHeaderLeftModel: IndHeaderLeftDataModel;
    private recordCount: number = 30;

    constructor(
        public repService: RepServices,
        public navCtrl: IonNav,
        public deviceService: DeviceService,
        private ioUserSelection: IoUserSelection,
        private navigationService: NavigationService,
        public trackingService: TrackService,
        private cd: ChangeDetectorRef,
        public uiService: UIService,
        private activityService: ActivityService,
        private authenticationService: AuthenticationService,
        public translate: TranslateService,
        public device: DeviceService,
        public emailService: EmailService,
        public footerService: FooterService
    ) {
    }

    ngOnInit() {
        this.userSelection = [];
        this.iCaseSelection = this.ioUserSelection.caseSelectorSourceObserver.subscribe(value => {
            if (value) {
                this.caseSelector = value;
                if (value === "Team Members") {
                    this.caseSelector = this.translate.instant('COACHING_TEAM_MEMBERS');
                    this.sectionHeaderTitle = this.translate.instant('COACHING_TEAM_MEMBERS');
                    this.isFromAdditionalConfigFields = false;
                    this.resetActivityReadOnlyStatus();
                } else if (value === 'Activity Type') {
                    this.caseSelector = this.translate.instant('ACTIVITY_TYPE');
                    this.sectionHeaderTitle = this.translate.instant('ACTIVITY_TYPE');
                    this.isFromAdditionalConfigFields = true;
                } else if (value === 'Activity Sub Type') {
                    this.caseSelector = this.translate.instant('ACTIVITY_SUB_TYPE');
                    this.sectionHeaderTitle = this.translate.instant('ACTIVITY_SUB_TYPE');
                    this.isFromAdditionalConfigFields = true;
                }
            }
        });
        this.iSelectedInput = this.ioUserSelection.selectedInputObserver.subscribe(value => {
            this.selectedInput = value;
        });
        this.iSelectionData = this.ioUserSelection.selectionDataObserver.subscribe(value => {
            this.selectionData = this.remoteMeetingConnectionMapping ? JSON.parse(JSON.stringify(value)) : value;
            this.itemsToDisplay = this.sliceItems(0, this.recordCount);
            this.initiazeComponent();
        });

        this.initHeaderLeft();
    }

    ngOnChanges() {
        this.initiazeComponent();
    }

    public get isDoneButtonDisabled(): boolean {
        try {
            return (_.xor(this.selectedInput.map(a => a.id), this.userSelection.map(a => a.id)).length === 0);
        } catch (error) {
            console.log('Error occured while validating done button' + error);
            return false;
        }
    }

    initiazeComponent() {
        this.cd.detectChanges();
        this.userSelection = [];
        this.selectionData.map(e => {
            const found = this.getChecked(e);
            if (found) {
                e.checked = true;
                // To avoid duplicacy.
                let idx = this.userSelection.findIndex(u => u.id === e.id);
                if (idx === -1) {
                    if (this.remoteMeetingConnectionMapping) {
                        // For remote meeting connection status display, we've already duplicated
                        // 'selectionData' so that we don't touch the original user list.
                        // Here, we're simply mapping the user's meeting specific connection status data
                        // to the cloned 'selectionData' for display purpose only.
                        e.remoteMeetingJoinStatus = found.remoteMeetingJoinStatus;
                    }
                    this.userSelection.push(e);
                }
            }
            else
                e.checked = false;
        });
        this.addUnMappedUsers();
        this.itemsToDisplay = this.sliceItems(0, this.recordCount);
        this.cd.detectChanges();
        this.cd.markForCheck();
        console.log(this.userSelection);
    }

    resetActivityReadOnlyStatus() {
        let activity = this.activityService.selectedActivity;
        let isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authenticationService.user.systemUserID) : false;
        let coVisitorAccess = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_covisitoraccessonmeeting'];

        if (activity) {
            if (!activity.isCompleted) {
                //check for feature action
                //If the feature action mapped, check other clause
                if ((activity instanceof AppointmentActivity && this.authenticationService.hasFeatureAction(FeatureActionsMap.JOINT_MEETING)) ||
                    (activity instanceof PhoneActivity && this.authenticationService.hasFeatureAction(FeatureActionsMap.JOINT_PHONE_CALL))) {
                    //requester is not the owner
                    if (activity['meetingOwnerId'] != this.authenticationService.user.systemUserID) {
                        //If Joint meeting tagged then readonly
                        if ((activity instanceof AppointmentActivity && activity['isJointmeeting']) ||
                            (activity instanceof PhoneActivity && activity.jointphonecall)) {
                            if(isCovisitor && (coVisitorAccess == CovisitorAccess.FULL_ACCESS)){
                              this.isReadOnlyMode = false;
                            }else{
                              this.isReadOnlyMode = true;
                            }
                        }
                        //Allow user to access list only once as directed by product
                        else {
                            this.isReadOnlyMode = false;
                        }
                    }
                }
                else {
                    // feature not mapped, so the user will access in readonly mode regardless of the fact that he is the owner of not
                    this.isReadOnlyMode = true;
                }
            }
            else
                this.isReadOnlyMode = true;
        }
    }

    ngOnDestroy() {
        this.ioUserSelection.setCaseSelectorSource('');
        this.ioUserSelection.setSelectedInputSource([]);
        this.ioUserSelection.setSelectionDataSource([]);
        this.iCaseSelection.unsubscribe();
        this.iSelectedInput.unsubscribe();
        this.iSelectionData.unsubscribe();
    }

    getChecked(e: any): any {
        return this.selectedInput?.find(el => el.id === e.id);
    }

    close() {

        if (this.selectionFor == 'EmailActivity' && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
            this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION ||
            this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {
            if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
                this.uiService.activeView = this.uiService.prevView;
                document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
                this.navCtrl.pop({});
            }
        } else {
            // if (!this.deviceService.isMobileDevice) {
            //     this.closeMultiSelect.emit(true);
            // }
            // else
            this.navigationService.popWithPageTracking();
        }

    }

    doSelection(item: any, check: boolean) {
        if (!this.isReadOnlyMode) {
            item.checked = check;
            if (check) {
                this.userSelection.push(item);
            }
            else {
                let index = this.userSelection.findIndex(e => e.id === item.id);
                this.userSelection.splice(index, 1);
            }
            this.cd.detectChanges();
            this.cd.markForCheck();

            let doneButton = this.indHeaderLeftModel.controls.find(c => c.id === 'done');
            if (doneButton) {
                doneButton.isDisabled = this.isDoneButtonDisabled;
            }
        }
        else
            return;
    }

    done() {
        if (this.selectionFor == 'EmailActivity' && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
            this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION ||
            this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {
            if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
                this.uiService.activeView = this.uiService.prevView;
                document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
                this.ioUserSelection.setAccompaniedUserSelectedData(this.userSelection);
                this.navCtrl.pop({});
            }
        } else {
            // if (!this.deviceService.isMobileDevice) {
            //     this.emitResult.emit(this.userSelection);
            // }
            // else
            if (!this.isFromAdditionalConfigFields) {
                this.onSelectDone(this.userSelection);
            } else {
                this.ioUserSelection.setAdditionalConfigData(this.userSelection);
            }
            this.navigationService.popWithPageTracking();
        }
    }
    public searchText(ev): void {
        let val: string = (ev.target.value) ? ev.target.value : '';
        if (val.length >= this.MIN_SEARCH_LENGTH) {
            this.searchResult = this.selectionData.filter(e => e.name.toLowerCase().includes(val.toLowerCase().trim()));
            this.isSearching = true;
        } else {
            this.searchResult = [];
            this.isSearching = false;
        }
    }

    addUnMappedUsers() {
        if (this.isReadOnlyMode) {
            /* If the user is present in the list of selected accompanied user from meeting bms
            But the accompanied user mapped to owner does not have the data then simply add it to the
            list of selected User, but if in readonly mode only */
            this.selectedInput.forEach(e => {
                //check if the current input is available in the owner accompanied user list
                let index = this.selectionData.findIndex(d => d.id === e.id);
                //If the selection is not available via accompanied user list then simply add it to user selection.
                if (index === -1) {
                    // To avoid duplicacy.
                    let idx = this.userSelection.findIndex(u => u.id === e.id);
                    if (idx === -1) {
                        this.userSelection.push(e);
                    }
                }
            });
        }
    }

    private initHeaderLeft() {
        let buttons = [];
        if (this.userSelection) {
            buttons.push({
                id: 'close',
                cssClass: 'seventyPercentWidth',
                imgSrc: 'assets/imgs/header_cancel.svg',
                isDisabled: false,
                align: 'left'
            });
        } else {
            buttons.push({
                id: 'close',
                icon: "chevron-back-outline",
                isDisabled: false,
                align: 'left'
            });
        }

        if (this.selectionData && this.selectionData.length > 0 && !(this.isReadOnlyMode || !this.userSelection)) {
            buttons.push({
                id: 'done',
                cssClass: 'seventyPercentWidth',
                imgSrc: 'assets/imgs/header_complete.svg',
                isDisabled: this.isDoneButtonDisabled,
                align: 'right'
            });
        }

        this.indHeaderLeftModel = {
            id: 'io-multiselect-header-left',
            title: this.caseSelector,
            mode: true,
            controls: buttons
        };
    }

    onHeaderControlClick(id) {
        if (id === 'close') {
            this.close();
        } else if (id === 'done') {
            this.done();
        }
    }

    public doInfinite(eventDetails,event) {
      this.itemsToDisplay.push(...this.sliceItems(this.recordCount, 30));
      this.recordCount = this.itemsToDisplay.length;
      event.target.complete();
    }

    private sliceItems(startIndex: number, count: number) {
      return this.selectionData.length < startIndex + count ? this.selectionData.slice(startIndex) : this.selectionData.slice(startIndex, startIndex + count);;
    }
}
