import { PageName, NavigationService, ChildNavNames } from './../../../services/navigation/navigation.service';
import { AllocationFeatureService } from './../../../services/sample/allocation.feature.service';
import { Component, OnDestroy, ViewChild } from '@angular/core';

import { Events } from '@omni/events';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { SampleService } from '../../../services/sample/sample.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AllocationShipmentService } from '../../../services/sample/allocation-shipment.service';
import { DeviceService } from '../../../services/device/device.service';
import { SampleDataService } from '../../../data-services/sample/sample.data.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { IonNav } from '@ionic/angular';

@Component({
    selector: 'allocation-page[base-page]',
    templateUrl: 'allocation-page.html',
  styleUrls:['allocation-page.scss']
})
export class AllocationComponent implements OnDestroy {
    public compViewMode:ComponentViewMode;
    pageName = PageName;

    @ViewChild('customerallocationtoolrightpane', {static:true}) public navCtrl: IonNav;

    constructor(
        public uiService: UIService,
        public sampleService: SampleService,
        public sampleDataService: SampleDataService,
        public activityService: ActivityService,
        public shipmentsService: AllocationShipmentService,
        private events: Events,
        public device: DeviceService,
        public authService: AuthenticationService,
        public allocFeatureService: AllocationFeatureService,
        private navService: NavigationService,
    ) {
    }

    ngOnInit(){
        this.events.subscribe('updateAllocationsRHSEmptyMessage', (shouldShow) =>{
            this.uiService.updateNothingSelectedScreenMessageFor(shouldShow ? 1 : 0)
        });
        this.navService.initRightPaneChildNav(this.navCtrl,ChildNavNames.CustomerAllocationsToolNavigation,PageName.AllocationComponent);
    // this.fetchRealTimeLotDetails();
    }

  private async fetchRealTimeLotDetails() {
    if (!this.device.isOffline && !this.device.isUserStateOffline) {
      // this.uiService.displayLoader();
      const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
      this.sampleDataService.syncLots(dataRangeWithFutureBoundBySixMonths);
    }
  }

    ngOnDestroy(){
        this.allocFeatureService.setIsReadyToClearMemory(true);
        this.sampleService.selectedSampleSKU = undefined;
        this.sampleService.allocationOrdersSearchStr = '';
        this.events.unsubscribe('updateAllocationsRHSEmptyMessage');
        this.navService.popChildNavCtrlFromStack(ChildNavNames.CustomerAllocationsToolNavigation);
    }

}
