import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import _ from 'lodash';

@Component({
  selector: 'app-add-customer-availability',
  templateUrl: './add-customer-availability.component.html',
  styleUrls: ['./add-customer-availability.component.scss'],
})
export class AddCustomerAvailabilityComponent implements OnInit {
  @Input() slotsData: [];
  @Input() dayRecord: any;
  fromDataSlotDetails:{
    id:string,
    inputText: string,
    customPlaceholderLabel:string,
    label:string
  }
  toDataSlotDetails:{
    id:string,
    inputText: string,
    customPlaceholderLabel:string,
    label:string
  }
  selectedFromSlot: any;
  selectedToSlot: any;
  unSavedChanges: {
    startTimeLabel:string,
    startTimeValue:string,
    endTimeLabel:string,
    endTimeValue:string,
    dataRecordGuid?:string,
    isDeleted:boolean
  }[] = [];
  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController
  ) {
    this.fromDataSlotDetails = {
      id:'from-slot',
      label: this.translate.instant('START_TIME'),
      inputText:'',
      customPlaceholderLabel: this.translate.instant('SELECT_START_TIME')
    };
    this.toDataSlotDetails = {
      id:'to-slot',
      label: this.translate.instant('END_TIME'),
      inputText:'',
      customPlaceholderLabel: this.translate.instant('SELECT_END_TIME')
    };
  }

  ngOnInit() {

  }

  closemodal(tobeSaved: boolean){
    if(tobeSaved && this.unSavedChanges.length){
      this.modalCtrl.dismiss({newDataAdded: tobeSaved, changes: this.unSavedChanges, dayOfWeek:this.dayRecord});
    }
    else this.modalCtrl.dismiss({newDataAdded: tobeSaved});
  }
  async onInputTextClick(event, fieldDetails){
    let selectionOptions;
    if(fieldDetails.id=='from-slot') selectionOptions = this.slotsData;
    else{
      selectionOptions = this.slotsData.filter(o=>{
        return parseInt(o['Value'])>= parseInt(this.selectedFromSlot.id)
      })
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'timeslot-field',
      data: selectionOptions.map(option => {
        return { title: option['Label'], id: option['Value'] }
      })
    };
    const popover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view2', event: event });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if(data.data && data.data.selectedItems?.length){
        fieldDetails['inputText'] = data.data.selectedItems[0].title;
        if(fieldDetails.id=='from-slot') this.selectedFromSlot = data.data.selectedItems[0];
        else this.selectedToSlot = data.data.selectedItems[0];
      }
    });
  }
  addData(){
    this.unSavedChanges.push({
      startTimeLabel:this.selectedFromSlot.title,
      startTimeValue:this.selectedFromSlot.id,
      endTimeLabel:this.selectedToSlot.title,
      endTimeValue:this.selectedToSlot.id,
      isDeleted:false,
    });
    this.selectedFromSlot = undefined;
    this.selectedToSlot = undefined;
    this.fromDataSlotDetails.inputText = '';
    this.toDataSlotDetails.inputText = '';
  }

  deleteRecord(record, dataset){
    if(record['startTimeLabel']){
      _.remove(this.unSavedChanges,(o)=>{
        return o==record
      })
    }
    // else{
    //   let match = this.unSavedChanges.find(o=>o['indskr_customeravailability_v2id']==record['indskr_customeravailability_v2id'])
    //   if(!match){
    //     this.unSavedChanges.push({
    //       startTimeLabel:'',
    //       endTimeLabel:'',
    //       startTimeValue:'',
    //       endTimeValue:'',
    //       isDeleted:true,
    //       dataRecordGuid:record['indskr_customeravailability_v2id']
    //     })
    //   }
    //   else{
    //     match.isDeleted = true;
    //   }
    // }
  }
}
