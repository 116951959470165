import { PipeTransform, Pipe } from '@angular/core';
import { CallPlanOfflineService } from '../services/call-plan/call-plan.offline.service';
import { AccountOfflineService, AccountSelectedFor } from '../services/account/account.offline.service';
import { from } from 'rxjs';

@Pipe({
    name: 'callPlanFilter',
    pure: true
})
export class CallPlanFilterPipe implements PipeTransform {
    constructor(
        private callPlanService: CallPlanOfflineService,
        private accountService: AccountOfflineService
    ) {

    }

    transform(items: Array<any>, team?: boolean, searchQuery? : string) {
        // If CallPlan List is not empty
        // If user has not selected any filter for the call plan from dropdown
        // If the user has not selected any account for filtering.
        if (!items || !this.callPlanService.callPlanFilter) {

            //Custom selection made by user
            if (this.callPlanService.callPlanFilter !== 'All Products') {
                let distinctItems: any[] = this.filterDistinctItems(items);
                //If Team call plan
                if (team) {
                    return distinctItems.filter(callPlan => callPlan.isTeamCallPlan);
                } //For User based plan
                else {
                    return distinctItems.filter(callPlan => !callPlan.isTeamCallPlan)
                }
                //return items.filter(callPlan => callPlan.productID === this.callPlanService.callPlanFilter);
            }
            //Default Selection made
            else {
                //If Team call plan
                if (team) {
                    return items.filter(callPlan => callPlan.isTeamCallPlan);
                } //For User based plan
                else {
                    return items.filter(callPlan => !callPlan.isTeamCallPlan)
                }
            }

        };

        if (this.accountService.selectedAccounts.length > 0 && this.accountService.selectedFor === AccountSelectedFor.CALLPLAN_FILTER ) {
            //For each selected account
            let tempItems = [];
            this.accountService.selectedAccounts.map(selectedAccount => {

                //filter our items
                const filteredItems = items.filter(callPlan => {
                    //If call plan has an account id included in our selected account list.
                    const foundAccountID = callPlan.accountIDs.find(accountID => accountID == selectedAccount.id);
                    if (foundAccountID) return true;
                    return false;
                });
                if (filteredItems && filteredItems.length > 0) {
                    tempItems = [...tempItems, ...filteredItems];
                }
            });
            // Filter out possible duplicates
            items = tempItems.filter((item, idx) => tempItems.findIndex((it) => item.cyclePlanID === it.cyclePlanID) >= idx);
        }

        //Only show team call plans
        if (team) {
            items = items.filter(callPlan => callPlan.isTeamCallPlan);
        } else {
            items = items.filter(callPlan => !callPlan.isTeamCallPlan);
        }
        if(this.callPlanService.callPlanSearchText) {
          items = items.filter(cp=>{
            return cp.cycleplanname.toLowerCase().includes(this.callPlanService.callPlanSearchText.trim().toLowerCase())
          })
        }
        // single select cproduct filter logic getting obsolete, replacign with multi-select logic
        //if (this.callPlanService.callPlanFilter === 'All Products') return items;
        if (this.callPlanService.multiProductSelectionFilter && !this.callPlanService.multiProductSelectionFilter.length) return items;
        return items.filter(callPlan => this.callPlanService.multiProductSelectionFilter.indexOf(callPlan.repPlans[0].productid_Formatted)>-1);
    }

    filterDistinctItems(items: any[]): any[] {
        let distinctItem: any[] = [];
        let itemObservable = from(items)
            .subscribe((callplan: any) => {
                if (callplan.repPlans[0].productid_Formatted === this.callPlanService.callPlanFilter) {
                    distinctItem.push(callplan)
                }
            });
        itemObservable.unsubscribe();
        console.log(distinctItem);
        return distinctItem;
    }
}
