<ion-list scrollY="true" *ngIf="source.length > 0">

  <ion-item *ngFor="let item of source | orderBy: 'createdon':false" (click)="viewDetails(item)" [ngClass]="{'itemSelected':(item?.indskr_accountcrid===this.selected?.indskr_accountcrid)}" class='selector-item'>
<!--    <p data-letters="JW"> {{item.indskr_firstname + " " + item.indskr_lastname}}</p>-->
<!--    <ion-icon slot="start" class="main-icon">-->
      <p [attr.data-letters]="item.indskr_name.charAt(0)"></p>
<!--    </ion-icon>-->
    <ion-label>
      <ion-text class="primary-text">
        <h3>{{item.indskr_name}}</h3>
      </ion-text>
      <p class="secondary-text">
        <ion-text>{{item.indskr_mdmrequesttype === 548910000 ? translate.instant('NEW') : translate.instant('UPDATE')}} Account</ion-text>
      </p>
      <p class="secondary-text">
        <ion-text>{{getDisplayDate(item.createdon)}}</ion-text>
        <ion-text class="tert-margin-text">{{mdmService.getStatusText(item.statuscode)}}</ion-text>
      </p>
    </ion-label>
  </ion-item>
</ion-list>
