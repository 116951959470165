export const ADJUSTMENT_ENTITY_NAME = 'indskr_usershipmentallocation_v2s';

export const ADJUSTMENT_INITIAL_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_adjustedby" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_externalid" />
    <attribute name="indskr_reasonforadjustment" />
    <attribute name="indskr_comment" />
    <attribute name="indskr_quantityadjusted" />
    <attribute name="indskr_adjustmentdate" />
    <attribute name="indskr_approvedby" />
    <attribute name="indskr_adjustmentapproveddate" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <attribute name="indskr_adjustedstatus" />
    <order attribute="indskr_adjustmentdate" descending="true" />
    <filter type="and">
      <condition attribute="indskr_adjustedby" operator="eq-userid" />
      <condition attribute="createdon" operator="on-or-after" value="{START_DATE}" />
      <condition attribute="createdon" operator="on-or-before" value="{END_DATE}" />
      <condition attribute="indskr_transfertype" operator="eq" value="548910004" />
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" alias="ow" link-type="inner">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="ownerFullName" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="indskr_approvedby" alias="ap" link-type="outer">
      <attribute name="fullname" alias="approvedByName" />
    </link-entity>
  </entity>
</fetch>`;

export const TEAM_ADJUSTMENT_INITIAL_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_adjustedby" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_externalid" />
    <attribute name="indskr_reasonforadjustment" />
    <attribute name="indskr_comment" />
    <attribute name="indskr_quantityadjusted" />
    <attribute name="indskr_adjustmentdate" />
    <attribute name="indskr_approvedby" />
    <attribute name="indskr_adjustmentapproveddate" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <attribute name="indskr_adjustedstatus" />
    <order attribute="indskr_adjustmentdate" descending="true" />
    <filter type="and">
      <condition attribute="indskr_adjustedstatus" operator="neq" value="548910002" />
      <condition attribute="createdon" operator="on-or-after" value="{START_DATE}" />
      <condition attribute="createdon" operator="on-or-before" value="{END_DATE}" />
      <condition attribute="indskr_transfertype" operator="eq" value="548910004" />
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" link-type="inner" alias="ai">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="ownerFullName" />
      <link-entity name="indskr_position_systemuser" from="systemuserid" to="systemuserid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="aj">
          <link-entity name="position" from="positionid" to="parentpositionid" alias="ac">
            <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" alias="ad">
              <filter type="and">
                <condition attribute="indskr_userid" operator="eq-userid" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="indskr_approvedby" alias="ap" link-type="outer">
      <attribute name="fullname" alias="approvedByName" />
    </link-entity>
  </entity>
</fetch>`;

export const ADJUSTMENT_DELTA_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_adjustedby" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_externalid" />
    <attribute name="indskr_reasonforadjustment" />
    <attribute name="indskr_comment" />
    <attribute name="indskr_quantityadjusted" />
    <attribute name="indskr_adjustmentdate" />
    <attribute name="indskr_approvedby" />
    <attribute name="indskr_adjustmentapproveddate" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <attribute name="indskr_adjustedstatus" />
    <order attribute="indskr_adjustmentdate" descending="true" />
    <filter type="and">
      <condition attribute="indskr_adjustedby" operator="eq-userid" />
      <condition attribute="modifiedon" operator="ge" value="{LAST_UPDATED_DATE}" />
      <condition attribute="createdon" operator="on-or-after" value="{START_DATE}" />
      <condition attribute="createdon" operator="on-or-before" value="{END_DATE}" />
      <condition attribute="indskr_transfertype" operator="eq" value="548910004" />
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" alias="ax2" link-type="inner">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="userFullName" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="indskr_approvedby" alias="ap" link-type="outer">
      <attribute name="fullname" alias="approvedByName" />
    </link-entity>
  </entity>
</fetch>`;

export const TEAM_ADJUSTMENT_DELTA_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_adjustedby" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_externalid" />
    <attribute name="indskr_reasonforadjustment" />
    <attribute name="indskr_comment" />
    <attribute name="indskr_quantityadjusted" />
    <attribute name="indskr_adjustmentdate" />
    <attribute name="indskr_approvedby" />
    <attribute name="indskr_adjustmentapproveddate" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <attribute name="indskr_adjustedstatus" />
    <order attribute="indskr_adjustmentdate" descending="true" />
    <filter type="and">
      <condition attribute="modifiedon" operator="ge" value="{LAST_UPDATED_DATE}" />
      <condition attribute="createdon" operator="on-or-after" value="{START_DATE}" />
      <condition attribute="createdon" operator="on-or-before" value="{END_DATE}" />
      <condition attribute="indskr_transfertype" operator="eq" value="548910004" />
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" link-type="inner" alias="ai">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="ownerFullName" />
      <link-entity name="indskr_position_systemuser" from="systemuserid" to="systemuserid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="aj">
          <link-entity name="position" from="positionid" to="parentpositionid" alias="ac">
            <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" alias="ad">
              <filter type="and">
                <condition attribute="indskr_userid" operator="eq-userid" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="indskr_approvedby" alias="ap" link-type="outer">
      <attribute name="fullname" alias="approvedByName" />
    </link-entity>
  </entity>
</fetch>`;

export const ADJUSTMENT_DELTA_DELETED_FETCH_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_trackchange">
    <attribute name="indskr_trackchangeid" />
    <attribute name="indskr_action" alias="track_action" />
    <attribute name="indskr_entityid" alias="indskr_usershipmentallocationid" />
    <order attribute="createdon" descending="false" />
    <filter type="and">
      <condition attribute="indskr_action" operator="eq" value="548910001" />
      <condition attribute="createdon" operator="ge" value="{LAST_UPDATED_DATE}" />
      <condition attribute="indskr_entityname" operator="eq" value="indskr_usershipmentallocation_v2" />
    </filter>
  </entity>
</fetch>`;

export const ADJUSTMENT_FETCH_BY_ID_TEMPLATE =
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usershipmentallocation_v2">
    <attribute name="indskr_usershipmentallocation_v2id" />
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_adjustedby" />
    <attribute name="indskr_shipmentnumber" />
    <attribute name="indskr_externalid" />
    <attribute name="indskr_reasonforadjustment" />
    <attribute name="indskr_comment" />
    <attribute name="indskr_quantityadjusted" />
    <attribute name="indskr_adjustmentdate" />
    <attribute name="indskr_approvedby" />
    <attribute name="indskr_adjustmentapproveddate" />
    <attribute name="indskr_reasonfortransfer" />
    <attribute name="indskr_comments" />
    <attribute name="indskr_transfertype" />
    <attribute name="indskr_adjustedstatus" />
    <order attribute="indskr_adjustmentdate" descending="true" />
    <filter type="and">
      <condition attribute="indskr_usershipmentallocation_v2id" operator="eq" value="{ADJUSTMENT_ID}" />
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="ownerid" alias="ow" link-type="inner">
      <attribute name="systemuserid" alias="ownerId" />
      <attribute name="fullname" alias="ownerFullName" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_skuproduct" link-type="outer" alias="at_ps">
      <attribute name="name" alias="at_indskr_skuname" />
      <attribute name="productid" alias="at_indskr_skuid" />
      <attribute name="statecode" alias="at_sku_statecode" />
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="at_pl">
      <attribute name="productid" alias="at_indskr_lotid" />
      <attribute name="name" alias="at_indskr_lotname" />
      <attribute name="validtodate" alias="at_indskr_lotvalidtodate" />
      <attribute name="validfromdate" alias="at_indskr_lotvalidfromdate" />
      <attribute name="statecode" alias="at_lot_statecode" />
    </link-entity>
    <link-entity name="systemuser" from="systemuserid" to="indskr_approvedby" alias="ap" link-type="outer">
      <attribute name="fullname" alias="approvedByName" />
    </link-entity>
  </entity>
</fetch>`;
