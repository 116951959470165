<ion-header>
    <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<!-- <ion-item-divider class="page-header">
  {{ 'XPERIENCES_HEADER_PLANS_AND_PREDICTIONS' | translate}} ({{activities.length}})
</ion-item-divider> -->
<ind-section-header [viewData]='{id:"plants-and-predictions",title: translate.instant("XPERIENCES_HEADER_PLANS_AND_PREDICTIONS") + "(" + activities.length + ")"}'>
</ind-section-header>
<ion-content>
    <ion-list>
        <ng-container *ngFor="let activity of activities | orderBy:'date':false">
            <ion-item>
                <!-- <ion-thumbnail><img src="assets/imgs/xp_{{activity.type}}.svg" width="20"/></ion-thumbnail> -->
                <div>
                    <div text-left class="padTop">
                        <img src="assets/imgs/xp_{{activity.type}}.svg" width="20" height="20px" />
                        <h3 text-nowrap class="header">{{ activity.title }}</h3>
                    </div>
                    <div text-left class="account-secondary-info">
                        <span text-nowrap class='secondaryTextColorAndfont'>
        <ion-row>
            <span class="date-product">{{getFormattedAndLocalisedDate(activity.date)  + ' - ' + (activity.products[0].productname)}}</span>
                        </ion-row>
                        <ion-row>
                            <span class="description"> {{activity.second_line_description.split(',').length > 1 ?
                                           (activity.second_line_description.split(',')[0] + ' +'+ (activity.second_line_description.split(',').length - 1)) : activity.second_line_description}}</span>
                            <ng-container *ngIf="activity.strength">
                                <ion-thumbnail *ngIf="activity.type !== 'teamplan'" class="left-float strength">
                                    <img src="assets/imgs/xp_{{activity.strength}}_strength_bar.svg" width="20" />
                                </ion-thumbnail>
                            </ng-container>
                        </ion-row>
                        </span>
                    </div>
                </div>
            </ion-item>
        </ng-container>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar>
<!-- </ion-footer> -->
