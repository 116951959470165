import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface AvatarListOptions {
  id: string;
  imageUrl: string;
  name: string;
  isSelected: boolean;
  parentId? : string;
}

@Component({
  selector: 'avatar-list-item',
  templateUrl: './avatar-list-item.html',
  styleUrls: ['./avatar-list-item.scss'],
})
export class AvatarListItemComponent implements OnInit {
  @Input() value: AvatarListOptions;
  @Input() isSelectionList: boolean = false;
  @Input() headerSection = false;
  @Input() isReadOnly: boolean = false;
  @Input() isSubItem : boolean = false;
  @Input() isParent : boolean = false;

  @Output() clicked = new EventEmitter();
  @Output() tapped = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  getFirstLetter(inputString: string) {
    let inputStringArray = inputString.split(' ');
    if(inputStringArray.length > 1){
      return inputStringArray[0].charAt(0) + inputStringArray[1].charAt(0);
    }
    return inputString.charAt(0);
  }

  clikedButton(mode){
    event.stopPropagation();
    this.clicked.emit({ value : this.value , mode });
  }

  onTap(value){
    event.stopPropagation();
    this.tapped.emit(value);
  }

}
