import { Component, ElementRef, ViewChild } from '@angular/core';
import { CoachingReportService } from '../../../services/coaching/coaching.report.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { Report } from '../../../classes/coaching/report.class';
import { User } from '../../../classes/account/child.user.class';
import { DeviceService } from '../../../services/device/device.service';
import * as moment from "moment";
import { TranslateService } from '@ngx-translate/core';
import { RepServices } from '../../../data-services/rep/rep.services';
import { IonList } from '@ionic/angular';
import { UIService } from '@omni/services/ui/ui.service';

/**
 * Generated class for the CoachingForSelectComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'coaching-for-select',
  templateUrl: 'coaching-for-select.html',
  styleUrls:['coaching-for-select.scss']
})
export class CoachingForSelectComponent {

  public selectedCoachingFor:User = null;
  public selectedCoaching:Report = null;
  public teamMembers:User[] = [];
  public filteredMembers:User[] = [];
  public searchTerm:String = '';
  private readonly MIN_SEARCH_LENGTH = 1;
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;

  constructor(
    private coachingReportService: CoachingReportService,
    private navService: NavigationService,
    private device:DeviceService,
    private translate : TranslateService,
    public repService: RepServices,
    private uiService: UIService,

  ) {
    coachingReportService.selectedReport.subscribe(coaching => {
      if (coaching) {
      this.selectedCoachingFor = new User( {
        userid: coaching.indskr_coachingfor_value,
        userfullname: coaching.created_for,
        firstName: coaching.created_for_firstname
      });
      this.selectedCoaching = coaching;
    }
    });
    this.teamMembers = coachingReportService.users;
    this.filteredMembers = this.teamMembers;
  }

  ngAfterViewInit(){
    this.uiService.scrollListToView(this.list);
  }
  public get isDoneDisabled():boolean {
    if(this.selectedCoaching && this.selectedCoaching.indskr_coachingfor_value){
      return (this.selectedCoachingFor && this.selectedCoaching.indskr_coachingfor_value == this.selectedCoachingFor.id);
    }else{
      return !(this.selectedCoachingFor && this.selectedCoachingFor.id);
    }
  }

  public addSelected(user:User) {
    if (!this.selectedCoachingFor) {
      this.selectedCoachingFor = new User( {
        userid: user.id,
        userfullname: user.fullName,
        firstName: user.firstName
      });
    } else {
      if (this.selectedCoachingFor.id === user.id) return;
      this.selectedCoachingFor.id = user.id;
      this.selectedCoachingFor.fullName = user.fullName;
      this.selectedCoachingFor.firstName = user.firstName;
    }
  }

  public getItems() {
    if (this.searchTerm && this.searchTerm.trim().length > this.MIN_SEARCH_LENGTH) {
      this.filteredMembers = this.teamMembers.filter((user: User) => {
          return user.fullName.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1;
      });
    } else {
      this.filteredMembers = this.teamMembers;
    }
  }

  public closeModal(saveSelection: boolean) {
    if (saveSelection) {
      if (this.selectedCoachingFor) {
        if (this.selectedCoachingFor.id && this.selectedCoachingFor.id != this.selectedCoaching.indskr_coachingfor_value) {
          const coaching:Report =<Report> { ...this.selectedCoaching };
          coaching.indskr_coachingfor_value = this.selectedCoachingFor.id;
          coaching.indskr_name = this.translate.instant("COACHING_FOR") + " " + this.selectedCoachingFor.fullName + " " + this.translate.instant("ON") + " " + moment(this.selectedCoaching.createdon).format("MMM DD" + ", " + "YYYY");
          coaching.created_for = this.selectedCoachingFor.fullName;
          coaching.created_for_firstname = this.selectedCoachingFor.firstName;
         this.coachingReportService.updateCoachingFor(coaching);
        }
      } else {
        this.selectedCoaching.created_for = null;
        this.selectedCoaching.indskr_coachingfor_value = null;
      }
    } else {
      this.navService.popWithPageTracking();
    }
  }

}
