<ion-grid [ngClass]="navService.isActiveChildNavRightPaneViewDisplayed?'preview-active':'preview-inactive'">
    <ion-row>
      <ion-col class="field-material-left-pane">
        <field-material-management-list [viewData]="viewData" (closeModal)="onCloseModal($event)"></field-material-management-list>
      </ion-col>
      <ion-col class="field-material-right-pane">
        <ion-nav #fieldmaterialrightpane></ion-nav>
      </ion-col>
    </ion-row>
</ion-grid>
