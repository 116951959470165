<ion-row>
  <ng-container *ngFor="let viewData of currentFormView">
    <ion-col size="12" *ngIf="viewData.type == 'section-header'">
      <ind-section-header *ngIf="viewData.type == 'section-header'" [viewData]='viewData.view' (onControlClick)='onSectionHeaderControlClick($event)' (onControlClickWithEventBubbleUp)="onSectionHeaderControlClickWithEventBubbleUp($event)"></ind-section-header>
      <div class='linked-entity-view' *ngIf="viewData.type == 'section-header' && viewData.data">
        <ng-container *ngIf="linkedEntityValues && linkedEntityValues[viewData.data]
                              && (!affiliationsFilters[viewData.data]
                                  || (affiliationsFilters[viewData.data]
                                      && affiliationsFilters[viewData.data].filteredRecordCount !== 0));
                              else linkedView;">
            <ng-container *ngFor="let item of linkedEntityValues[viewData.data]">
              <ng-container [ngSwitch]="item.displayType">

                <ng-container *ngSwitchCase="'Affiliation'">
                  <ind-affiliations-card *ngIf="!item.isFiltered" [viewData]="item" class="enableCopyPaste"></ind-affiliations-card>
                </ng-container>
                <omni-accordion *ngSwitchDefault [viewData]="item" [ngClass]="{'enableCopyPaste': isEnableCopyPaste}" (favouriteAdd)="favoriteAdd(item)"></omni-accordion>

              </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #linkedView>
            <ion-item class='empty-item'></ion-item>
        </ng-template>
      </div>
    </ion-col>
    <ion-col *ngIf="viewData.type == 'form-field'" [size]="viewData.fullWidth ? 12 : 6">
      <ind-form-field *ngIf="viewData.type == 'form-field' && viewData.control && viewData.control.dataType != 'DateTimeType'" [viewData]='viewData.view' [ngClass]="{'enableCopyPaste': isEnableCopyPaste}"></ind-form-field>
      <ng-container *ngIf="viewData.type == 'form-field' && viewData.control && viewData.control.dataType == 'DateTimeType'">
        <ind-form-field *ngIf="viewData.view.id =='omnione_yearofbirth' || viewData.view.id == 'omnione_dayandmonthofbirth'" [viewData]='viewData.view' [ngClass]="{'enableCopyPaste': isEnableCopyPaste}"></ind-form-field>
        <ind-form-field *ngIf="viewData.control.dateFormat == 'DateOnly' && viewData.view.id !='omnione_yearofbirth' && viewData.view.id != 'omnione_dayandmonthofbirth'" [viewData]='viewData.view' [ngClass]="{'enableCopyPaste': isEnableCopyPaste}"></ind-form-field>
        <ion-row *ngIf="viewData.control.dateFormat == 'DateAndTime' && viewData.view.id !='omnione_yearofbirth' && viewData.view.id != 'omnione_dayandmonthofbirth'">
          <ion-col size="6"><ind-form-field *ngIf="viewData.view" [viewData]='viewData.view' [ngClass]="{'enableCopyPaste': isEnableCopyPaste}"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field *ngIf="viewData.timeView" [viewData]='viewData.timeView' [ngClass]="{'enableCopyPaste': isEnableCopyPaste}"></ind-form-field></ion-col>
        </ion-row>
      </ng-container>
    </ion-col>  
  </ng-container>
</ion-row>


<ng-container *ngIf="TYPE == 600000000 && (businessLine == 2 || businessLine ==3) && (isWorkCardAdded || isPracticeCertificateAdded)">
  <ind-section-header [viewData]="worksCardHeader">
  </ind-section-header>
  <ind-text-area id="workCardInput" [placeHolder]="''" [showAttachment]="isWorkCardAdded" [readonly]="true" [hasRemove]="false" [hasDownload]="true" (downloadNotesAttachment)="downloadAttachment($event, rawData['attachment1'])"
    [attachmentTitle]="attachment1" [maxLength]="1000" [rows]="2" [cols]="10"></ind-text-area>
  <ind-section-header [viewData]="practiceCertificateHeader">
  </ind-section-header>
  <ind-text-area id="practiceCertificateInput" [placeHolder]="''" [showAttachment]="isPracticeCertificateAdded" [readonly]="true" [hasRemove]="false" [hasDownload]="true" (downloadNotesAttachment)="downloadAttachment($event, rawData['attachment2'])"
    [attachmentTitle]="attachment2" [maxLength]="1000" [rows]="2" [cols]="10"></ind-text-area>
</ng-container>