import { Component, AfterViewInit, ElementRef, ContentChild, ViewChild } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';

/**
 * Generated class for the IndArrowScrollerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-arrow-scroller',
  templateUrl: 'ind-arrow-scroller.html',
  styleUrls:['ind-arrow-scroller.scss']
})
export class IndArrowScrollerComponent implements  AfterViewInit {

  @ContentChild('scrollableContent', { read: ElementRef }) contentChild: ElementRef;
  showRightButton = false;
  showLeftButton = false;
  @ViewChild('leftButton', {read: ElementRef }) leftButton: ElementRef;
  scrollElementsOffsetLeft: number;

  constructor(public device: DeviceService) { }

  ngAfterViewInit() {
    if(!this.device.isNativeApp) {
      setTimeout(() => {
        if(this.contentChild) {
          if((this.contentChild.nativeElement as HTMLElement).scrollWidth > (this.contentChild.nativeElement as HTMLElement).clientWidth ) {
              this.showLeftButton = true;
          }
          if(this.leftButton) {
            this.scrollElementsOffsetLeft = (this.contentChild.nativeElement as HTMLElement).offsetLeft;
          }
        }
      }, 300);
    }
  }

  scrollLeft() {
    this.showRightButton = true;
    const  widthToScroll = this.getWidthToScroll();
    (this.contentChild.nativeElement as HTMLElement).scrollLeft -= (widthToScroll);
    if((this.contentChild.nativeElement as HTMLElement).scrollLeft === 0 ) {
      this.showRightButton = true;
      this.showLeftButton = false;
    }
  }

  scrollRight() {
    this.showLeftButton = true;
    const widthToScroll = this.getWidthToScroll();
    (this.contentChild.nativeElement as HTMLElement).scrollLeft += (widthToScroll);
    if((this.contentChild.nativeElement as HTMLElement).scrollLeft +(this.contentChild.nativeElement as HTMLElement).clientWidth ===
    (this.contentChild.nativeElement as HTMLElement).scrollWidth) {
      this.showRightButton = false;
      this.showLeftButton = true;
    }
  }

  getWidthToScroll(): number {
   const scrollElement = (this.contentChild.nativeElement as HTMLElement);
   const children =  scrollElement.children;
   const childrenLength =  children.length;
   let width = 0;
    for(let i = 0; i < childrenLength; i++) {
      // checks element is fully visible and calculates the width to scroll
      if(children.item(i).getBoundingClientRect().left > this.scrollElementsOffsetLeft &&
          children.item(i).getBoundingClientRect().right <= scrollElement.getBoundingClientRect().right) {
        width += (children.item(i) as HTMLElement).offsetWidth;
      }
   }
   return width;
  }

}
