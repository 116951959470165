<div #videobox class="videobox"></div>
<div class="transbox">
  <p>
    {{initials}}
  </p>
</div>
<div class="text-element">
  <p>
    {{name}}
  </p>
  <ion-button *ngIf="tencentConfig?.streamType == 'remote'" fill="clear" icon-only (click)="handleUnmuteClicked()">
    <ion-icon class="mute-icon" slot="icon-only" [src]="tencentConfig?.hasAudio && (!tencentConfig.mutedAudio || tencentConfig?.audioVolume > 2 ) ? 'assets/imgs/unmute-subscriber.svg' : 'assets/imgs/mute-subscriber.svg'">
    </ion-icon>
  </ion-button>
</div>
