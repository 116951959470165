<ion-header>
  <ind-page-title [viewData]="medicalInsightsDetailsPanePageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ng-container>
    <ion-list class='details-list' *ngIf="currentMedicalInsight">
        <ind-section-header [viewData]='detailsHeader'></ind-section-header>
        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="customerFormField"></ind-form-field></ion-col>
          <ion-col size="6"><ind-datetime-form [viewData]="dateFormField"></ind-datetime-form></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6"><ind-form-field  [viewData]="productCatalogFormField"></ind-form-field ></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="insightCategoryFormField"></ind-form-field></ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="specialityFormField"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="priorityFormField"></ind-form-field></ion-col>
        </ion-row>
        <ind-form-field [viewData]="summaryFormField"></ind-form-field>
        <ind-form-field [viewData]="descriptionFormField"></ind-form-field>
        
        <div class="disclaimer" text-wrap>
          <span class="disclaimer-header">{{'DISCLAIMER' | translate}} : </span> <span class="disclaimer-text">{{'MEDICAL_INSIGHT_DISCLAIMER' | translate}}</span>
        </div>
    </ion-list>
  </ng-container>
</ion-content>
<footer-toolbar [footerView]="'detailView'" [selectedView]="''" (buttonClicked)="onFooterButtonClicked($event)"></footer-toolbar>