import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { AlertService } from '@omni/services/alert/alert.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { DeviceService } from '../../../services/device/device.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
import { AccountPlan, AccountPlanMode } from './../../../classes/account-management/account-plan.class';
import { AuthenticationService } from './../../../services/authentication.service';
import { TrackingEventNames, TrackService } from './../../../services/logging/tracking.service';
import { AccountPlanOpportunityListComponent } from './../account-plan-opportunity-list/account-plan-opportunity-list';
import * as _ from 'lodash';
import { format } from 'date-fns';
import { EventsService } from '@omni/services/events/events.service';

@Component({
  selector: 'account-plan-details',
  templateUrl: 'account-plan-details.html',
  styleUrls: ['account-plan-details.scss']
})
export class AccountPlanDetails implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() selectedMode: AccountPlanMode;
  public isOpportunitiesVisible = false;
  //calling it a reference as its a copy by reference and not by value
  public planDataSetReference: AccountPlan;
  private ngUnSubscibe$ = new Subject<boolean>();
  public pageTitle: IndPageTitleViewDataModel;
  public deviceOffline: boolean = false;
  tabsData: IndTabsDataModel[] = [
    {
      displayText: this.translate.instant('INFO'),
      value: 'info'
    },
    {
      displayText: this.translate.instant('TIMELINE'),
      value: 'timeline',
      disable: false,
    }
  ];
  public accountPlanDetailSegment: string = this.tabsData[0].value;
  private currentAccountPlan:AccountPlan;
  validForm = false;
  public hasAccountPlanCreation: boolean = false;

  constructor(
    public device: DeviceService,
    private readonly accountManagementService: AccountManagementOfflineService,
    private readonly navService: NavigationService,
    public trackingService: TrackService,
    public readonly translate: TranslateService,
    public footerService: FooterService,
    private readonly uiService: UIService,
    private readonly accountManagementDataService: AccountManagementDataService,
    private alertService: AlertService,
    public authService: AuthenticationService,
    private events : EventsService
  ) {
    this.hasAccountPlanCreation = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_PLAN_CREATION);
   }

  ngOnInit() {
    this.trackingService.tracking('AccountPlanInfo', TrackingEventNames.ACCOUNTPLANNING);

    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      // if the device offlice diisable account plan cancel button
      this.deviceOffline = isOffline;
      this.initPageTitle();
    });
    this.isOpportunitiesVisible = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    const accountPlan = this.data;
    this.planDataSetReference = this.accountManagementService.accountPlans.find(o => o.ID === accountPlan.ID);
    this.currentAccountPlan = _.cloneDeep(this.planDataSetReference);
    this.accountManagementService.currentAccountPlan = this.currentAccountPlan;

    this.events.observe('account-field-change').pipe(takeUntil(this.ngUnSubscibe$)).subscribe(() => {
      this.validForm = this.accountManagementService.tempReqPayload && this.accountManagementService.tempReqPayload.accountPlanName === "" ? false : true;
      this.initPageTitle();
    });

    this.accountManagementService.accountPlans$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((data) => {
      if (data && data.length) {
        this.planDataSetReference = data.find(o => o.ID === accountPlan.ID);
        this.initPageTitle();
      }
    });
    this.initPageTitle();
  }

  ngOnDestroy() {
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
  }

  public backToAccountPlanList(): void {
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.AccountManagementPageComponent);
    this.uiService.showRightPane = false;
    this.accountManagementService.isRightPaneNavActive = false;
  }

  private initPageTitle(): void {
    const controls = [];
    const isPast = this.data.endDate ? parseInt(this.data.endDate) < new Date().getTime() : false;
    if (this.accountManagementService.selectedMode === AccountPlanMode.VIEW && this.hasAccountPlanCreation) {
      controls.push({
        id: "edit",
        imgSrc: 'assets/imgs/header_edit.svg',
        name: this.translate.instant('EDIT'),
        isDisabled: this.deviceOffline || this.authService.user.systemUserID !== this.data.ownerID || this.data.status === "Completed" || isPast,
        align: "right",
      });
    }

    if (this.accountManagementService.selectedMode !== AccountPlanMode.EDIT) {
      if (this.isOpportunitiesVisible) {
        controls.push({
          id: 'ap-opportunities',
          imgSrc: 'assets/imgs/header_opportunities.svg',
          name: this.translate.instant('OPPORTUNITIES'),
          isDisabled: false,
          align: 'right',
        });
      }
      if (this.hasAccountPlanCreation) {
        controls.push({
          id: "scrap",
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: this.deviceOffline || (this.authService.user.systemUserID !== this.data.ownerID) || this.data.status === "Completed",
          align: "right"
        });
      }
    }

    if (this.accountManagementService.selectedMode === AccountPlanMode.EDIT) {
      controls.push({
        id: "cancel",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        // isDisabled: this.deviceOffline || (this.authService.user.systemUserID !== this.data.ownerID) || this.data.status === "Completed",
        align: "right"
      });

      controls.push({
        id: "save",
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled:this.deviceOffline || this.accountManagementService.tempReqPayload === undefined || !this.validForm,
        align: "right"
      });
    }



    if (this.device.isMobileDevicePortrait) {
      controls.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left',
      });
    }

    if (this.accountManagementService.selectedMode === AccountPlanMode.VIEW) {
      controls.push({
        id: 'pluse-icon',
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE'),
        iconName: 'pluse-icon',
        isDisabled: this.data.status === "Completed",
        align: 'right',
      });
    }


    this.pageTitle = {
      id: 'account-plan-details',
      title: this.planDataSetReference?.accountPlanName,
      controls: controls,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.backToAccountPlanList();
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'ap-opportunities':
        this.handleOpportunities();
        break;
      case 'scrap':
        this.showConfirmAlert();
        break;
      case 'edit':
        this.enableEdit();
        break;
      case 'cancel':
        this.onCancel();
        break;
      case 'save':
        this.onSave()
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  enableEdit(){
    this.selectedMode = this.accountManagementService.selectedMode = AccountPlanMode.EDIT;
    this.events.publish('account-plan-mode-changed');
    this.initPageTitle();
  }

  showConfirmAlert() {
    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_ACCOUNT_PLAN'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_ACCOUNT_PLAN')
    }, this.translate.instant('YES'), this.translate.instant('NO')
    ).then(async res => {
      if (res.role == "ok") this.scrapAccountPlan();
    });
  }

  scrapAccountPlan() {
    let payload = { statecode: 1, statuscode: 548910002 };
    this.uiService.displayLoader();
    this.accountManagementDataService.saveAccountPlan(payload, this.data.ID).then(async () => {
      this.uiService.dismissLoader();
      await this.accountManagementDataService.scrapAccountPlanInLocal(this.data.ID);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.AccountManagementPageComponent);
      this.accountManagementService.isRightPaneNavActive = false;
    }).catch(err => console.log('error from scrap', err));
  }

  public handleOpportunities(): void {
    this.navService.pushChildNavPageWithPageTracking(AccountPlanOpportunityListComponent, PageName.AccountPlanOpportunityListComponent,
      PageName.AccountManagementPageComponent, { selectedAccountPlan: this.planDataSetReference });
  }

  public handlePlusButton(): void {
    this.trackingService.tracking('AccountPlanNewActivity', TrackingEventNames.ACCOUNTPLANNING);
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool', plan: this.planDataSetReference });
  }

  async segmentChanged(selectedTab: string) {
    this.accountPlanDetailSegment = selectedTab;
  }

  onCancel(){
    if(this.accountManagementService.tempReqPayload !== undefined){
      this.accountManagementService.showConfirmAlert();
    } else {
      this.accountManagementService.selectedMode = AccountPlanMode.VIEW;
      this.accountManagementService.selectedAccountPlan$.next(this.currentAccountPlan);
      this.events.publish('account-plan-mode-changed');
    }
  }

  onSave(){
    let reqPayload = {
      indskr_name : null,
      accountId: this.accountManagementService.tempReqPayload.accountId,
      contactAccountAffs: [],
      indskr_startdate: null,
      products: [],
      indskr_enddate: null,
      indskr_plannedbudget: 0,
      indskr_strategy: null
    };

    this.accountManagementService.tempReqPayload.contactAccountAffs.forEach(element => {
      reqPayload.contactAccountAffs.push({ indskr_accountcontactaffiliationid: element.indskr_accountcontactaffiliationid })
    });

    this.currentAccountPlan.contactAccountAffs.filter((item) => !_.find(this.accountManagementService.tempReqPayload.contactAccountAffs, item)).forEach(item => {
      reqPayload.contactAccountAffs.push({ indskr_accountcontactaffiliationid: item.indskr_accountcontactaffiliationid , action: "DELETE" });
    });

    this.accountManagementService.tempReqPayload.products.forEach(element => {
      reqPayload.products.push({ productId : element.productID })
    });

    this.currentAccountPlan.products.filter((item) => !_.find(this.accountManagementService.tempReqPayload.products, item)).forEach(item => {
      reqPayload.products.push({ productId: item.productID , action: "DELETE" });
    });

    if(this.accountManagementService.tempReqPayload.startDate) reqPayload.indskr_startdate = format(new Date(parseInt(this.accountManagementService.tempReqPayload.startDate)), 'YYYY-MM-DD');
    if(this.accountManagementService.tempReqPayload.endDate) reqPayload.indskr_enddate = format(new Date(parseInt(this.accountManagementService.tempReqPayload.endDate)), 'YYYY-MM-DD');
    reqPayload.indskr_plannedbudget = this.accountManagementService.tempReqPayload.indskr_plannedbudget;
    reqPayload.indskr_name = this.accountManagementService.tempReqPayload.accountPlanName;

    if(this.accountManagementService.tempReqPayload.strategy) reqPayload.indskr_strategy = this.accountManagementService.tempReqPayload.strategy;

    this.uiService.displayLoader();
    this.accountManagementDataService.saveAccountPlan(reqPayload, this.planDataSetReference.ID).then(async() => {
      this.accountManagementDataService.saveAccountPlanInLocal(this.accountManagementService.tempReqPayload);
      await this.accountManagementDataService.getAccountPlans(false, this.planDataSetReference.ID);
      this.selectedMode = AccountPlanMode.VIEW;
      this.accountManagementService.selectedMode = AccountPlanMode.VIEW;
      this.events.publish('account-plan-mode-changed');
      this.accountManagementService.selectedAccountPlan$.next(this.accountManagementService.tempReqPayload);
      this.accountManagementService.tempReqPayload = undefined;
      this.initPageTitle();
      this.uiService.dismissLoader();
    });
  }

}
