<ion-header>
    <ion-toolbar class='page-title'>
        <ion-buttons slot="start">
            <ion-button (click)="closepage(true)">
                {{ 'CANCEL' | translate}}
            </ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{ 'CONTACT_CREATE_AFFILIATION' | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closepage(false)" [disabled]="!selectedAccount">
                {{ 'DONE' | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-item-group>
        <ion-item-divider>
            {{ 'OVERVIEW_CAP' | translate}}
        </ion-item-divider>
        <ion-item>
            <ion-label>{{ 'ACCOUNT' | translate}}</ion-label>
             <div item-content class="order-field-text" (click)="openAccountSelection()" [ngClass]="{'placeholderTextStyle':!selectedAccount}">
                {{ ((selectedAccount?.accountName) ? selectedAccount?.accountName : 'SELECT_ACCOUNT') | translate}}
            </div>
            <!-- <ion-input #accInput text-right [value]="selectedAccount?.accountName" (input)="getItems($event)" placeholder="Search Account"></ion-input> -->
            <ion-icon slot="end" style="color:#c8c7cc; padding-left:10px;" name="arrow-forward" (click)="openAccountSelection()"></ion-icon>
        </ion-item>
        <!-- <ion-item>
            <ion-label>Contact</ion-label>
            <ion-input readonly text-right [value]="contactFullName"></ion-input>
        </ion-item> -->
        <ion-item>
            <ion-label>{{ 'ROLE' | translate}}</ion-label>
            <div item-content class="detailsValueLabel">
                <ion-select mode="ios" [(ngModel)]="contactRole" interface="popover">
                    <ion-select-option *ngFor="let role of contactRoles" [value]="role">{{role.value}}</ion-select-option>
                </ion-select>
            </div>
        </ion-item>
        <!-- <ion-item>
            <ion-label>Direct Affiliation</ion-label>
            <ion-toggle [(ngModel)]="isDirectAffiliation" checked="isDirectAffiliation"></ion-toggle>
        </ion-item> -->
        <!-- <ion-item *ngIf="currentEvent != 'Half Day' && currentEvent != 'All Day' && currentEvent != 'Other'">
            <ion-label>Start Date</ion-label>
            <div item-content>
                <ion-datetime displayFormat="MM/DD/YYYY" (ionChange)="handleEndDateTime($event.detail,$event)" min="2000" [max]="max" [(ngModel)]="startTime"></ion-datetime>
            </div>
        </ion-item>
        <ion-item *ngIf="currentEvent != 'Half Day' && currentEvent != 'All Day' && currentEvent != 'Other'">
            <ion-label>End Date</ion-label>
            <div item-content>
                <ion-datetime displayFormat="MM/DD/YYYY"  [min]="startTime" [max]="max" [(ngModel)]="endTime"></ion-datetime>
            </div>
        </ion-item> -->
        <!-- <ion-item>
            <ion-label>Comments</ion-label>
            <ion-textarea #notesInput maxlength="1000" placeholder="Enter Notes"
                [(ngModel)]="comments" rows="4" cols="10"></ion-textarea>
        </ion-item> -->
    </ion-item-group>
<!--
    <ion-list #accList class="accountList" [ngClass]="{'showAccList':accountList.length>0}" radio-group [(ngModel)]="selectedAccount">
        <ion-item *ngFor="let account of accountList">
            <ion-label>{{account.accountName}}</ion-label>
            <ion-radio [value]="account" (ionSelect)="setAccount($event.detail,$event)"></ion-radio>
        </ion-item>
    </ion-list> -->
</ion-content>
