<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed) ? 'preview-active' : 'preview-inactive'" >
  <ion-row>
    <ion-col class="left-pane">
      <calendar-invite-template-list></calendar-invite-template-list>
    </ion-col>
    <ion-col class="right-pane" no-padding>
      <nothing-selected-view *ngIf="showNothingSelectedView" [deviceString]="deviceService.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
      <ion-nav #rightPane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
