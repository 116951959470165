import { ActivityPresentationSlide } from "./activity-presentation-slide.class";

export class ActivityPresentation{
    public presentationId:string;
    private name:string;
    private prodId:string;

    public activityPresentationSlides: Array<ActivityPresentationSlide>;

    constructor(raw:object){
        this.presentationId = raw['indskr_iopresentationid'];
        this.name = raw['indskr_name'];
        this.prodId = raw['indskr_productid'];

        if(Array.isArray(raw['activityPresentationSlides'])){
            this.activityPresentationSlides = [];
            raw['activityPresentationSlides'].forEach(item => {
                this.activityPresentationSlides.push(new ActivityPresentationSlide(item));
            });
        }
    }

    get activityPresentationSlidesDTO(): Array<Object> {
        let activityPresentationSlidesArray = [];

        if (!this.activityPresentationSlides) return;

        activityPresentationSlidesArray = this.activityPresentationSlides.map(pres => pres.DTO);
        return activityPresentationSlidesArray;
    }

    public get DTO():Object{
        return{
            indskr_iopresentationid: this.presentationId,
            indskr_name: this.name,
            indskr_productid: this.prodId,
            activityPresentationSlides: this.activityPresentationSlidesDTO
        }
    }
}
