import {Component, Input} from '@angular/core';
import {EventRegistration} from "../../../../classes/customer-event/customer-event.class";
import {NavParams, PopoverController} from "@ionic/angular";
import {XperiencesService, IMPACT_SEGMENT} from "../../../../services/xperiences/xperiences.service";
import {UIService} from "../../../../services/ui/ui.service";
import {DeviceService} from "../../../../services/device/device.service";
import {ActivityDataService} from "../../../../data-services/activity/activity.service";
import {ActivityService} from "../../../../services/activity/activity.service";
import {FooterService, FooterViews} from "../../../../services/footer/footer.service";
import {SampleService, SamplingDetailsViewMode} from "../../../../services/sample/sample.service";
import {EmailService} from "../../../../services/email-templates/email.service";
import {AccesingMode, CaseManagementService} from "../../../../services/case-management/case-management.service";
import {CaseManagementDataService} from "../../../../data-services/case-management/case-management.data.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../services/authentication.service";
import {ContactOfflineService} from "../../../../services/contact/contact.service";
import {NotificationService, ToastStyle} from "../../../../services/notification/notification.service";
import {NavigationService, PageName} from "../../../../services/navigation/navigation.service";
import {TrackService} from "../../../../services/logging/tracking.service";
import {CustomerEventsService} from "../../../../services/customer-event/customer-events.service";
import {
  CentralPlan, CustomerRelationship, InterestInsight, OptionSet,
  RelationshipInsight,
  RelationshipInsightsPlus,
  RelationshipSources, User, XpCustomer
} from "../../../../classes/xperiences/trending.customer.class";
import {MultiSelectPopover} from "../../../multi-select-popover/multi-select-popover";
import {Contact, Specialty} from "../../../../classes/contact/contact.class";
import {MarketingEmailDetailsComponent} from "../../../email/marketing-email-details/marketing-email-details";
import {ChannelType} from "../../../../classes/consent/channel.class";
import {AppointmentActivity} from "../../../../classes/activity/appointment.activity.class";
import {ActivityType} from "../../../../classes/activity/activity.class";
import {EmailActivity, EmailViewType} from "../../../../classes/activity/email.activity.class";
import {CaseActivity} from "../../../../classes/case-intake/case-activity.class";
import {SelectListData} from "../../../../models/select-list-data-model";
import {CustomerRelationshipComponent} from "../../../contact/customer-insight/customer-relationship/customer-relationship";
import {cloneDeep} from "lodash";
import {PopoverComponent} from "../../../popover/popover";
import {MorePlansInsightsComponent} from "../more-plans-insights/more-plans-insights";
import {Subscription} from "rxjs";
import _ from 'lodash';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { NewSampleActivityComponent } from '@omni/components/activity/new-sample-activity/new-sample-activity';

/**
 * Generated class for the RelationshipInsightsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'relationship-insights',
  templateUrl: 'relationship-insights.html',
  styleUrls:['relationship-insights.scss']
})
export class RelationshipInsightsComponent {

  @Input("source")
  public source: any;

  relationInsightSortOptions: any[] = [
    { text: this.translate.instant('XPERIENCES_SORT_DEGREE_1_TO_3'), value: "asc" },
    { text: this.translate.instant('XPERIENCES_SORT_DEGREE_3_TO_1'), value: "des" },
  ];
  relationInsightsFilterPopoverData: { text: string; value: string; displayIcon?: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];

  event : EventRegistration;
  filterSubscription: Subscription;
  public segmentType = IMPACT_SEGMENT;

  constructor(
    private popoverCtrl: PopoverController,
    public xperiencesService: XperiencesService,
    private navParams: NavParams,
    private uiService: UIService,
    public device: DeviceService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public sampleService: SampleService,
    private emailService: EmailService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private contactService: ContactOfflineService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    private trackingService: TrackService,
    public customerEventsService: CustomerEventsService,

  ) { }

  ngOnInit() {

    if (this.source) {
      this.source.forEach((insight) => {
        insight.sources = this.getRelationshipInsightsSources(insight);
        insight.latestInsight = ([...insight.sources.insightsPlusByLoggedInUser, ...insight.sources.otherInsightsPlus] as RelationshipInsightsPlus[])
          .sort((a, b) => (b as RelationshipInsightsPlus).date - (a as RelationshipInsightsPlus).date)[0];
        insight.sourcesText = this.getInsightsPlusSourcesText(insight.sources);
        if (!insight.fullname) {
          const contacts  = _.sortBy(insight.contacts, 'fullname');
          insight.fullname = contacts.length > 2 ? `${contacts[0].fullname} +${contacts.length - 1}`: contacts.map(c => c.fullname).join(", ");
        }
      });
      this.source = this.xperiencesService.sortObjs(this.source, 'score');
    }

    this.xperiencesService.filterSubject.next(false);

    this.relationInsightsFilterPopoverData = [
      {
        text: "",
        value: "All",
        items: [
          { text: this.translate.instant('XPERIENCES_ALL_RELATIONSHIPS'), value: 'All' },
          { text: this.translate.instant('XPERIENCES_INTEREST_PLUS_RELATIONSHIPS'), value: 'relationship_insights' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          if (selectedItem.value === 'All') {
            this.xperiencesService.xpRelationshipInsightsFilterText = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS')
          } else {
            this.xperiencesService.xpRelationshipInsightsFilterText = selectedItem.text;
          }
          this.xperiencesService.xpRelshipInsightsFilterValue = selectedItem.value;
        }
      },
      {
        text: this.translate.instant('SORT_BY'),
        value: "",
        items: this.relationInsightSortOptions.map(b => ({ text: b.text, value: b.value })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.xperiencesService.xpRelationshipInsightsFilterText = item.text + " " + selectedItem.text;
          this.xperiencesService.relshipSelectedSortOption = item.value;
          if (this.xperiencesService.xpRelationshipInsightsFilterText == '' || !this.xperiencesService.relshipSelectedSortOption) {
            itemRef.parent.items[0].value = 'All';
            this.xperiencesService.xpRelationshipInsightsFilterText = this.translate.instant('XPERIENCES_ALL_RELATIONSHIPS');
          }
          this.xperiencesService.xpRelshipInsightsFilterValue = selectedItem.value;
        }
      }
    ];

    this.setRelationshipsFilterState();

    this.filterSubscription = this.xperiencesService.filterObs$.subscribe((val) => {
      if (val) this.setRelationshipsFilterState();
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  get filteredInsights(): any[] {
    let filteredObjs: any[] = [];

    if (this.source) {
      if (this.xperiencesService.xpRelshipInsightsFilterValue === 'All') {
        filteredObjs = this.source.sort((a, b) => b.score - a.score);
      }
      else if (this.xperiencesService.xpRelshipInsightsFilterValue === 'relationship_insights') {
        filteredObjs = this.source.filter((insight) => insight.insights_plus && insight.insights_plus.length > 0).sort((a, b) => b.score - a.score);
      }
      else {
        if (this.xperiencesService.xpRelshipInsightsFilterValue === "asc") {
          filteredObjs = this.source.sort((a, b) => a.degree - b.degree);
        } else if (this.xperiencesService.xpRelshipInsightsFilterValue === "des") {
          filteredObjs = this.source.sort((a, b) => b.degree - a.degree);
        }
      }
    }
    return filteredObjs;
  }

  openPlanInsightFilterPopup() {
    this.popoverCtrl.create({component:MultiSelectPopover,componentProps: { root: this.relationInsightsFilterPopoverData }, cssClass: 'relationship-insigts-popover', event:event }).then((data=>data.present()));
    this.setRelationshipsFilterState();
  }

  getRelationshipInsightsSources(insight: RelationshipInsight) {
    let sources: RelationshipSources= {
      insightsPlusByLoggedInUser: [],
      otherInsightsPlus: [],
      otherSources: [],
    };
    if (insight.meetings && insight.meetings.length > 0) {
      sources.otherSources.push(`${insight.meetings.length > 1 ? this.translate.instant('XPERIENCES_RELATIONSHIP_GROUP_METTINGS') : this.translate.instant('XPERIENCES_RELATIONSHIP_GROUP_METTING')}`);
    }
    if (insight.events && insight.events.length > 0) {
      sources.otherSources.push(`${insight.events.length > 1 ? this.translate.instant('XPERIENCES_RELATIONSHIP_EVENTS') : this.translate.instant('XPERIENCES_RELATIONSHIP_EVENT')}`);
    }

    if (insight.accounts && insight.accounts.length > 0) {
      sources.otherSources.push(`${insight.accounts.length > 1 ? this.translate.instant('XPERIENCES_RELATIONSHIP_AFFILIATIONS') : this.translate.instant('XPERIENCES_RELATIONSHIP_AFFILIATION', {accountname: insight.accounts[0].accountname})}`);
    }

    if (insight.insights_plus && insight.insights_plus.length > 0) {
      insight.insights_plus.forEach((ip) => {
        if(ip.users[0].userid === this.authService.user.systemUserID && sources.insightsPlusByLoggedInUser.length < 1) {
          ip.displayText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.translate.instant('ME');
          sources.insightsPlusByLoggedInUser.push(ip);
        } else if (ip.users[0].userid !== this.authService.user.systemUserID) {
          ip.displayText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + ip.users[0].username;
          sources.otherInsightsPlus.push(ip);
        }
      });
    }
    return sources;
  }

  getInsightsPlusSourcesText(insightsPlusSources: RelationshipSources): string {
    let sourcesText = [];
    sourcesText.push(...insightsPlusSources.otherSources);
    if(insightsPlusSources.insightsPlusByLoggedInUser.length > 0) {
      sourcesText.push(this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.translate.instant('ME'));
    }
    if (insightsPlusSources.otherInsightsPlus.length > 0) {
      let insightsPlusText = (insightsPlusSources.otherInsightsPlus.length > 1) ? this.translate.instant('XPERIENCES_MULTIPLE_INSIGHTS_PLUS') :
        this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY');
      insightsPlusText = insightsPlusText +  ' ' + insightsPlusSources.otherInsightsPlus[0].users[0].username;
      if (insightsPlusSources.otherInsightsPlus.length > 1) {
        insightsPlusText = insightsPlusText + ' +' + (insightsPlusSources.otherInsightsPlus.length -1);
      }
      sourcesText.push(insightsPlusText);
    }
    return sourcesText.join(' | ');
  }

  async displayActivityDetails(data: any) {
    if (this.device.isOffline || data.activitytypecode === 'event') return;
    if(data.activitytypecode == 'journey_insight' || data['insights_plus']) {
      this.displayInsightsPlusDetails(data);
      return;
    }
    if (data.type == 'centralplan') {
      this.uiService.displayLoader();
      await this.xperiencesService.getMarketingEmails(data.id).then((response) => {
        const centralPlanData: CentralPlan = {
          type: data.title,
          customer: this.contactService.getContactByID(this.xperiencesService.selectedXpContact.contactid),
          products: data.products,
          subject: response['msdyncrm_subject'],
          date: data.date,
          previewContent: response['msdyncrm_emailbody'] ? response['msdyncrm_emailbody'] : ''
        }
        this.navService.pushChildNavPageWithPageTracking(
          MarketingEmailDetailsComponent,
          PageName.MarketingEmailDetailsComponent,
          PageName.MarketingEmailDetailsComponent,
          {
            viewData: { email: centralPlanData, centralPlan: data }
          });
        this.uiService.dismissLoader();
      }).catch(() => {
        this.uiService.dismissLoader();
      });
      return;
    }

    if (data.type == 'prediction' && (data.activitytypecode === 'appointment' ||
      data.activitytypecode === 'email' || data.activitytypecode === 'indskr_sampledrop')) {
        const contact: Contact = this.contactService.getContactByID(this.xperiencesService.selectedXpContact.contactid);
      if (data.activitytypecode == 'appointment') {
        await this.xperiencesService.createMeeting([contact], data.date);
        if (this.activityService.selectedActivity) this.footerService.initButtons(FooterViews.Activities);
      } else if (data.activitytypecode === 'indskr_sampledrop') {
        const contactCustomeAllocations = this.sampleService.contactCustomerSampleAllocations.find(o => {
          return o.contactId == contact.ID
        });
        if (!contactCustomeAllocations || !contactCustomeAllocations.currentCustomerSampleAllocations ||
          contactCustomeAllocations.currentCustomerSampleAllocations.length == 0) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_ACTIVE_ALLOCATIONS'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createAllocationOrder(data.date, contact);
        }
      } else {
        const channel: ChannelType = this.xperiencesService.getChannelType(data.channeltype);
        if (channel === ChannelType.EMAIL && (!contact.emailAddressList || contact.emailAddressList.length === 0)) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_EMAIL'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if (channel === ChannelType.FACEBOOK && (contact.indskr_facebookpsid || '') === '') {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_FACEBOOK_ID'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if ((channel === ChannelType.WHATSAPP || channel === ChannelType.SMS) && ((contact && contact.mobilePhone) || '') === '') {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_PHONE_NUMBER'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createEmail(data.date, data.channeltypeid, channel, [contact]);
        }
      }
      data.type = 'teamplan';
      data.id = this.activityService.selectedActivity.ID;
      let loggedInUser = this.authService.user;
      let users: User[] = [{
        userid: loggedInUser.systemUserID,
        username: loggedInUser.displayName
      }];
      data.users = users;
      data.translatedTitle = this.constructPlansTitle(data.type, data.activitytypecode, data.channeltype, users);
    } else {
      let foundActivity = this.activityService.getActivityByID(data.id);
      this.uiService.showRightPane = true;
      this.uiService.showNewActivity = false;
      if (data.activitytypecode == "appointment") {
        if (!foundActivity) {
          await this.activityDataService.getActivityByIdOnline(data.id, "appointment")
            .then(async response => {
              foundActivity = new AppointmentActivity(response);
            });
        }
        foundActivity.isFromXperiences = true;
        this.activityService.selected = foundActivity;
        this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
        await this.activityDataService.updateActivityDetails(foundActivity);
        if (this.activityService.selectedActivity) {
          this.footerService.initButtons(FooterViews.Activities);
        }
      } else if (data.activitytypecode == "email") {
        let activity = new AppointmentActivity({ activityid: data.id });
        activity.type = ActivityType.Email;
        activity.isFromXperiences = true;
        await this.uiService.displayLoader();
        await this.activityDataService.getRealTimeActivityDetails(activity).then(async (email) => {
          await this.uiService.dismissLoader();
          if (!email) {
            return;
          }
          this.activityService.selected = email;
          this.activityService.selectedActivity = email;
          this.emailService.selectedActivity = <EmailActivity>email;
          this.emailService.selectedActivity.isFromXperiences = true;
          this.emailService.setCurrentEmail(email);
          this.emailService.viewType = EmailViewType.FROM_XPERIENCES;
          this.uiService.showNewActivity = false;
          this.uiService.activeView = 'Email';
          this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent);
          this.activityService.updateEmailAddressInEmailActivityParties(email as EmailActivity);
        }).catch(async() => {
          await this.uiService.dismissLoader();
          console.log("Failed to load email activity:" + activity.ID);
        });
      } else if (data.activitytypecode == "medical_inquiry") {
        this.uiService.displayLoader();
        await this.caseDataService.findCaseById(data.id).then(
          async res => {
            if (res) {
              for (let key in res) {
                if (key.charAt(0) === "_") {
                  let a = key.substring(1, key.length);
                  res[a] = res[key];
                  delete res[key];
                }
              }
              let iCase: CaseActivity;
              iCase = new CaseActivity(res);
              iCase = await this.caseService.reInitDataDependency(iCase);
              this.activityService.selectedActivity = iCase;
              this.caseService.assignSelectedCase(iCase);
              this.caseService.accessedFrom = AccesingMode.XPERIENCE_INSIGHTS;
              this.uiService.showNewActivity = false;
              this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.CaseManagementDetailComponent);
            }
            this.uiService.dismissLoader();
          }).catch(() => {
          console.log("Failed to load customer inquiry:" + foundActivity.ID);
          this.uiService.dismissLoader();
        });
      } else if (foundActivity.type == ActivityType.Sample) {
        if (foundActivity) {
          this.activityService.selected = foundActivity;
        } else {
          await this.activityDataService.updateActivityDetails(foundActivity);
        }
        this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'Sample';
        this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
      }
    }
  }

  displayInsightsPlusDetails(insight: RelationshipInsight) {
    if(insight['events']) {
      let index = (insight as RelationshipInsight).insights_plus.findIndex((ip) => ip.users[0].userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayRelationInsightsPlusDetails(insight as RelationshipInsight);
      }
      return;
    }
  }

  async displayRelationInsightsPlusDetails(insight: RelationshipInsight) {
    const latestInsight = ([...insight.sources.insightsPlusByLoggedInUser, ...insight.sources.otherInsightsPlus] as RelationshipInsightsPlus[])
      .sort((a, b) => (b as RelationshipInsightsPlus).date - (a as RelationshipInsightsPlus).date)[0];
    this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getRelationshipInsigthData(latestInsight.insightid.toString());
    this.uiService.dismissLoader();
    let insightPlusSources = insight.sources.otherInsightsPlus.map((oip) => oip.displayText);
    let allSources = [];
    if(insight.sources.otherSources.length > 0) {
      allSources.push(...insight.sources.otherSources);
    }
    if (insight.sources.otherInsightsPlus) {
      allSources.push(...insightPlusSources);
    }
    const relationshipTypes: SelectListData[] = insight.insights_plus.map((ip)=> {
      const relationship = this.xperiencesService.relationships.find((relationship) => relationship.title === ip.relationship_insight_type);
      return {...relationship, isSelected: true };
    });
    const selectedSpeaciality: Specialty = insightData["indskr_specialty"] ? this.contactService.specialties.find((sp) => sp.id === insightData["indskr_specialty"]) : null;
    const relationshipInsightData: CustomerRelationship = {
      customerName: this.xperiencesService.selectedXpContact.fullname,
      contactid: insight.contactid,
      fullname: insight.fullname,
      contacts: insight.contacts,
      sources: allSources,
      sourcesText:  allSources.length > 1 ? allSources[0] + ' +' + (allSources.length - 1) : allSources[0],
      types: relationshipTypes.length === 0 ? undefined : relationshipTypes,
      title: relationshipTypes.length === 0 ? undefined : (relationshipTypes.length === 1 ? relationshipTypes[0].title : relationshipTypes[0].title + ' + ' + (relationshipTypes.length - 1)),
      score: insightData['indskr_strength'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strength']).label): undefined,
      selectedSpeciality: {
        title: selectedSpeaciality ? selectedSpeaciality.name : "",
        id: selectedSpeaciality ? selectedSpeaciality.id : "",
        isSelected: true
      }
    }
    this.navService.pushChildNavPageWithPageTracking(CustomerRelationshipComponent, PageName.CustomerJourneyComponent,
      PageName.ActivitiesPageComponent, { relationship: relationshipInsightData, readOnly: true });
  }

  constructPlansTitle(type: string, activitytypecode: string, channeltype: string, users: User[]) {
    let title = '';
    if (type === 'prediction') {
      switch (activitytypecode) {
        case "indskr_sampledrop":
          title = this.translate.instant('XPERIENCES_GENEE_ALLOCATION_LABEL');
          break;
        case "task":
          title = "Follow-up";
          break;
        case "appointment":
          title = this.translate.instant('XPERIENCES_GENEE_MEETING_LABEL');
          break;
        case "email":
          if (channeltype === 'email') title = this.translate.instant('XPERIENCES_GENEE_EMAIL_LABEL');
          else {
            if (channeltype === 'facebook') title = this.translate.instant('XPERIENCES_GENEE_FACEBOOK_LABEL');
            else if (channeltype === 'whatsapp') title = this.translate.instant('XPERIENCES_GENEE_WHATSAPP_LABEL');
            else if (channeltype === 'sms') title = this.translate.instant('XPERIENCES_GENEE_SMS_LABEL');
          }
          break;
        case "medical_inquiry":
          title = this.translate.instant('XPERIENCES_GENEE_MEDICAL_INQUIRY');
          break;
      }
      return title;
    } else if (type === 'teamplan' || type === 'centralplan') {
      if (activitytypecode === 'medical_inquiry') {
        return this.translate.instant('XPERIENCES_TEAM_MEDICAL_INQUIRY');
      }
      return this.constructInsightTitle(activitytypecode, users, channeltype);
    }
  }

  constructInsightTitle(activityTypeCode: string, users: User[], channeltype: string): string {
    let activityName = '';
    let userNamesText = this.xperiencesService.getUserNamesText(users);
    switch (activityTypeCode) {
      case 'appointment':
        activityName = this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      case 'email':
        if (channeltype === 'email') activityName = this.translate.instant('XPERIENCES_EMAIL');
        else {
          let channel: string = "";
          if (channeltype === 'facebook') channel = this.translate.instant('XPERIENCES_FACEBOOK_MESSAGE');
          else if (channeltype === 'whatsapp') channel = this.translate.instant('XPERIENCES_WHATSAPP_MESSAGE');
          else if (channeltype === 'sms') channel = this.translate.instant('XPERIENCES_TEXT_MESSAGE');
          activityName = channel;
        }
        break;
      case 'medical_inquiry':
        return this.translate.instant('XPERIENCES_MEDICAL_INQUIRY');
      case 'indskr_sampledrop':
        activityName = this.translate.instant('XPERIENCES_ALLOCATION_ORDER');
        break;
      case 'marketing_email':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EMAIL');
        break;
      case 'event':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EVENT');
        break;
      case 'meeting':
        activityName =  this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      default:
        return '';
    }
    return activityName + ' ' + userNamesText;
  }

  public async sendFeedback(insight: RelationshipInsight, upFeedback: boolean, index?: number) {
    const xpCustomer: XpCustomer = this.xperiencesService.selectedXpContact;
    if (insight.feedback_Flag || this.device.isOffline) return;
    if (upFeedback) {
      this.uiService.displayLoader();
      this.sendRelationshipInsightFeedback(xpCustomer.contactid, (<RelationshipInsight>insight).contactid, 'positive').then(() => {
        insight.feedback_Flag = 'positive';
        insight.feedbackGiven = true;
        insight.feedbackThumbsUp = true;
        this.uiService.dismissLoader();
        this.notificationService.notify(this.translate.instant('XPERIENCES_THANKS_FOR_FEEDBACK'), 'Xperience Plan');
      }).catch(() => {
        this.uiService.dismissLoader();
        this.displayErrorToastMessage();
      });
    } else {
      this.uiService.displayLoader();
      this.sendRelationshipInsightFeedback(xpCustomer.contactid, (<RelationshipInsight>insight).contactid, 'negative').then(() => {
        const index = this.source.findIndex(x => x.contactid === (<RelationshipInsight>insight).contactid);
        if (index > -1) {
          this.source.splice(index, 1);
          this.notificationService.notify(this.translate.instant('XPERIENCES_RELATIONSHIP_REMOVED'), 'Xperience Plan');
        }
        this.uiService.dismissLoader();
      }).catch(() => {
        this.uiService.dismissLoader();
        this.displayErrorToastMessage();
      });
    }
  }

  sendRelationshipInsightFeedback(contactId: string, relatedContactId: string, feedback: string): Promise<any> {
    return this.xperiencesService.sendRelationshipInsightFeedback(contactId, relatedContactId, feedback);
  }

  displayErrorToastMessage() {
    this.notificationService.notify(this.translate.instant('XPERIENCES_ERROR'), 'Xperience Plan', 'top', ToastStyle.DANGER);
  }

  getOrdinalSuffix(n: number): string {
    let j = n % 10,
      k = n % 100;
    if (j == 1 && k != 11) {
      return n + "st";
    }
    if (j == 2 && k != 12) {
      return n + "nd";
    }
    if (j == 3 && k != 13) {
      return n + "rd";
    }
    return n + "th";
  }

  openMorePlansInsights() {
    this.navService.pushChildNavPageWithPageTracking(MorePlansInsightsComponent,
      PageName.MorePlansInsightsComponent,
      PageName.RelationshipInsightsComponent,
      {
        title: this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS'),
        data: this.source,
        selectedFilter: this.xperiencesService.xpRelationshipInsightsFilterText,
        type: 'relationship'
      })
  }

  setRelationshipsFilterState() {
    if (this.xperiencesService.xpRelshipInsightsFilterValue === 'All') {
      this.relationInsightsFilterPopoverData[1].value = '';
      this.relationInsightsFilterPopoverData[0].value = 'All';
      this.xperiencesService.xpRelationshipInsightsFilterText = this.translate.instant('All') + ' ' + this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS');
    } else {
      if (this.relationInsightsFilterPopoverData[0].value) {
        this.relationInsightsFilterPopoverData[0].value = this.xperiencesService.xpRelshipInsightsFilterValue;
      } else {
        this.relationInsightsFilterPopoverData[0].value = '';
      }
      this.relationInsightsFilterPopoverData[1].value = this.xperiencesService.xpRelshipInsightsFilterValue;
      this.xperiencesService.relshipSelectedSortOption = this.xperiencesService.xpRelshipInsightsFilterValue;
    }
  }
}
