import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { CustomerListPeriod } from '@omni/classes/territory-management-list/customer-list-period.class';
import { CustomerPositionList, CustomerPositionListType, StatusCode } from '@omni/classes/territory-management-list/customer-position-list.class';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { TerritoryManagementDataService } from '@omni/data-services/territory-management/territory-management.data.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewManageListComponent } from '../new-manage-list/new-manage-list.component';
import { TerritoryManagementDetailsComponent } from '../territory-management-details/territory-management-details';

export enum TerritoryManagementTabs {
  MANAGE_LIST = "Manage List",
  APPROVALS = "Approvals"
}

@Component({
  selector: 'territory-management-list',
  templateUrl: './territory-management-list.html',
  styleUrls: ['./territory-management-list.scss'],
})
export class TerritoryManagementListComponent implements OnInit {
  indHeaderLeftModel: IndHeaderLeftDataModel;
  quotesListKeyControlAreaModel: IndKeyControlsAreaModel;
  tabsData: IndTabsDataModel[];
  manageListSectionHeader: IndSectionHeaderViewDataModel;
  selectedTab: TerritoryManagementTabs = TerritoryManagementTabs.MANAGE_LIST;
  private currentTab: TerritoryManagementTabs = TerritoryManagementTabs.MANAGE_LIST;
  public customerPositionLists: CustomerPositionList[] = [];
  public approverTab: CustomerPositionList[] = [];
  public filteredListToDisplay: CustomerPositionList[] = [];
  public customerPositionListForApproval: CustomerPositionList[] = [];
  public currentListPeriod: CustomerListPeriod;
  searchText: string;
  public selectedCustomerPositionList: CustomerPositionList;
  public formattedNextWindowStartDate: string;
  public formattedNextWindowEndDate: string;
  public formattedStartDate: string;
  public formattedEndDate: string;
  private ngDestroy$: any = new Subject<boolean>();
  isSearching: boolean = false;
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  filterCount: number = 0;
  private statusFilter: StatusCode;
  private listPeriodFilter: string;
  private sortBy: { text: string, value: string, asc: boolean };
  private sortPopoverData;
  disableCreatebtn = false;
  private allCustomerListPeriods: CustomerListPeriod[] = [];

  constructor(
    private navService: NavigationService,
    public translate: TranslateService,
    public uiService: UIService,
    public territoryDataService: TerritoryManagementDataService,
    public territoryService: TerritoryManagementService,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private popoverCtrl: PopoverController,
    private contactService: ContactOfflineService,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.territoryDataService.fetchCustomerPositionList();

    this.uiService.displayLoader().then(() => {

      this.territoryService.customerPositionList.subscribe((customerPositionList: CustomerPositionList[]) => {
        if (customerPositionList) this.customerPositionLists = customerPositionList;
        else this.customerPositionLists = [];
        this.filteredListToDisplay = this.customerPositionLists;
        this.sortManageList();
        // if (this.currentListPeriod) {
        this.disableCreatebtn = this.disableCreateCustomerPositionList();
        this.initHeaderLeft();
        // }
      });

      Promise.all([
        this.territoryDataService.fetchCurrentListPeriod().then((resp: CustomerListPeriod) => {
          if (resp) {
            this.currentListPeriod = resp;
            this.disableCreatebtn = this.disableCreateCustomerPositionList();
            this.formattedNextWindowStartDate = this.currentListPeriod.indskr_nextlistmanagementwindowstartdate ? this.datePipe.transform(new Date(this.currentListPeriod.indskr_nextlistmanagementwindowstartdate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '';
            this.formattedNextWindowEndDate = this.currentListPeriod.indskr_nextlistmanagementwindowenddate ? this.datePipe.transform(new Date(this.currentListPeriod.indskr_nextlistmanagementwindowenddate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '';
            this.formattedStartDate = this.datePipe.transform(new Date(this.currentListPeriod.indskr_liststartdate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            this.formattedEndDate = this.datePipe.transform(new Date(this.currentListPeriod.indskr_listenddate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            this.initHeaderLeft();
            this.initSectionHeader();
            this.initFilterData();
          }
        }).then(() => {
          if (_.isEmpty(this.territoryService.contactsForNextPeriod)) this.territoryDataService.fetchContactsForConfiguredDisplay(true);
        })
      ]).then(() => {
        this.uiService.dismissLoader();
      })
    })
    this.contactService.tempContactInformation = this.contactService.contactInformation;
    this.initHeaderLeft();
    this.initSectionHeader();
    this.setTabsData();
    this.initSortOptions();
    this.territoryService.selectedCustomerPostionListSubject.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.selectedCustomerPositionList = data;
    });
  }

  onPageTitleControlClick(id) {
    if (id === 'back') {
      this.goBack();
    } else if (id == 'create') {
      this.createCustomerPositionList();
    }
  }

  private disableCreateCustomerPositionList() {

    if (this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_CURRENT_UPDATE)) {
      return (this.territoryService.isNextCustomerListPeriodExists() && this.territoryService.isCurrentCustomerListPeriodExists());
    }

    return this.territoryService.isNextCustomerListPeriodExists();

    // if(this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_CURRENT_UPDATE)){
    //   return false;
    // }

    // if (!this.currentListPeriod || !this.currentListPeriod.indskr_nextlistperiod) return true;
    // if (!this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_NO_WINDOW)) {
    //   const currentDate = new Date().setHours(0, 0, 0, 0);
    //   const windowOpen = currentDate > new Date(this.currentListPeriod.indskr_nextlistmanagementwindowstartdate).setHours(0, 0, 0, 0) && currentDate < new Date(this.currentListPeriod.indskr_nextlistmanagementwindowenddate).setHours(0, 0, 0, 0);
    //   if (!windowOpen) return true;
    // }
    // return this.customerPositionLists.some((customerPositionList) => customerPositionList.listPeriod.indskr_customerlistperiodid === this.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid);
  }

  openFilterPopup(event) {
    this.popoverCtrl.create({
      component: MultiSelectPopover,
      componentProps: { root: this.filterPopoverData },
      cssClass: 'dropdown-list-view',
      event: event
    }).then((data) => data.present())
    this.searchText = '';
  }

  onInput() {
    if (this.searchText && this.searchText.trim().length > 0) {
      this.isSearching = true;
      const dataToFilter = this.getListBasedOnTabSelected();
      this.filteredListToDisplay = dataToFilter.filter(data =>
        data.ownerName.toLowerCase().includes(this.searchText.toLowerCase())
        || (this.territoryService.getStatusCodeFormatted(data.statuscode).toLowerCase()).includes(this.searchText.toLowerCase())
        || this.datePipe.transform(new Date(data.createdon), this.dateTimeFormatsService.date, undefined, this.translate.currentLang).toLowerCase().includes(this.searchText.toLowerCase())
        || this.datePipe.transform(new Date(data.modifiedon), this.dateTimeFormatsService.date, undefined, this.translate.currentLang).toLowerCase().includes(this.searchText.toLowerCase())
      )
      this.sortManageList();
    } else {
      this.isSearching = false;
      this.filteredListToDisplay = this.getListBasedOnTabSelected();
      this.sortManageList();
    }
  }

  onSectionControlClick(id) {
    if (id == 'sort') {
      this.openSortPopup();
    }
  }

  private openSortPopup() {
    this.popoverCtrl
      .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event })
      .then((data) => {
        data.present();
      })
  }

  private sortManageList() {
    if (this.sortBy.value == 'modifiedon') {
      this.filteredListToDisplay = _.orderBy(this.filteredListToDisplay, obj => new Date(obj.modifiedon), "asc");
    } else {
      this.filteredListToDisplay = _.orderBy(this.filteredListToDisplay, obj => new Date(obj.createdon), "desc");
    }
  }

  setTabsData() {
    this.tabsData = [{
      displayText: this.translate.instant('MANAGE_LIST'),
      value: TerritoryManagementTabs.MANAGE_LIST
    }, {
      displayText: this.translate.instant('APPROVALS'),
      value: TerritoryManagementTabs.APPROVALS,
    }];


  }

  private initSortOptions() {
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "createon",
        items: [
          { text: this.translate.instant('DATE_CREATED'), value: "createon", asc: false },
          { text: this.translate.instant('DATE_MODIFIED'), value: "modifiedOn", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.sortManageList();
          this.initSectionHeader();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
  }

  private initFilterData() {
    this.filterCount = 0;
    this.filterPopoverData = [
      {
        text: "",
        value: "all",
        items: [{ text: this.translate.instant('ALL_MANAGE_LIST'), value: 'all' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          this.applyFilters('all', selectedItem.value);
        }
      },
      {
        text: this.translate.instant("LIST_PERIODS"),
        value: "",
        items: this.listPeriodsFilters(),
        handler: (selectedItem, item, itemRef) => {
          this.listPeriodFilter = selectedItem.value;
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.applyFilters("listPeriods", selectedItem.value);
        }
      },
      {
        text: this.translate.instant('Status'),
        value: "",
        items: [
          {
            text: this.translate.instant('OPEN'),
            value: StatusCode.OPEN
          },
          {
            text: this.translate.instant('FOR_REVIEW'),
            value: StatusCode.FOR_REVIEW
          },
          {
            text: this.translate.instant('APPROVED'),
            value: StatusCode.APPROVED
          },
          {
            text: this.translate.instant('EXPIRED_LOT'),
            value: StatusCode.EXPIRED
          }
        ],
        handler: (selectedItem, item, itemRef) => {
          this.statusFilter = selectedItem.value;
          item.value = item.value === selectedItem.value && item.text.lowercased() === selectedItem.text.lowercased() ? '' : selectedItem.value;
          itemRef.parent.items[0].value = ''
          this.applyFilters("status", selectedItem.value);
        }
      }
    ];
    this.filterPopoverData[0].value = 'all';
  }

  private listPeriodsFilters() {
    const values = [];
    if (this.currentListPeriod) {
      if (this.currentListPeriod.indskr_previouslistperiod) {
        values.push({
          text: this.currentListPeriod.indskr_previouslistperiod.indskr_name,
          value: this.currentListPeriod.indskr_previouslistperiod.indskr_customerlistperiodid
        })
      }
      values.push({
        text: this.currentListPeriod.indskr_name,
        value: this.currentListPeriod.indskr_customerlistperiodid
      })
      if (this.currentListPeriod.indskr_nextlistperiod) {
        values.push({
          text: this.currentListPeriod.indskr_nextlistperiod.indskr_name,
          value: this.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid
        })
      }
    }
    if (this.customerPositionLists)
      values.push(...this.customerPositionLists.map(cpl => cpl.listPeriod).map(listPeriod => {
        return {
          text: listPeriod.indskr_name,
          value: listPeriod.indskr_customerlistperiodid
        }
      }));
    return _.orderBy(_.uniqBy(values, 'value'), 'text');
  }

  private async applyFilters(filterType, value) {
    const data = this.getListBasedOnTabSelected();
    this.filterCount = this.statusFilter && this.listPeriodFilter ? 2 : 1;
    switch (filterType) {
      case 'all':
        this.listPeriodFilter = null;
        this.statusFilter = null;
        this.filteredListToDisplay = data;
        this.filterCount = 0;
        break;
      case 'listPeriods':
        if (this.statusFilter) {
          this.filteredListToDisplay = data.filter(d => d.listPeriod.indskr_customerlistperiodid === value && d.statuscode == this.statusFilter);
        } else {
          this.filteredListToDisplay = data.filter(d => d.listPeriod.indskr_customerlistperiodid === value);
        }
        break;
      case 'status':
        if (this.listPeriodFilter) {
          this.filteredListToDisplay = data.filter(d => d.statuscode === value && d.listPeriod.indskr_customerlistperiodid === value);
        } else
          this.filteredListToDisplay = data.filter(d => d.statuscode === value);
        break;
    }
    this.sortManageList();
  }

  private initHeaderLeft() {
    this.indHeaderLeftModel = {
      id: 'manage-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('MANAGE_LIST'),
      mode: true,
      controls: [{
        id: 'back',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left',
      }]
    };
    if (this.currentTab == TerritoryManagementTabs.MANAGE_LIST) {
      this.indHeaderLeftModel.controls.push({
        id: "create",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        isDisabled: this.disableCreatebtn,
        // isDisabled : false,
        align: "right",
      })
    }
  }

  private initSectionHeader() {
    this.manageListSectionHeader = {
      id: 'manage-list-header',
      title: this.currentTab === TerritoryManagementTabs.APPROVALS ? this.translate.instant('APPROVALS') : this.translate.instant('MANAGE_LIST'),
      controls: [{
        id: 'sort',
        text: this.sortBy?.text,
        isDisabled: false,
        img: "assets/imgs/sort_with_double_arrows.svg",
      }]
    }
  }

  async checkModel(tappedTab) {
    if (this.currentTab === tappedTab) return;
    this.selectedCustomerPositionList = null;
    if (this.navService.getActiveChildNavViewPageName() != PageName.NothingSelectedView) this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    if (this.selectedTab === TerritoryManagementTabs.MANAGE_LIST) {
      this.filteredListToDisplay = this.customerPositionLists;
    } else {
      this.filteredListToDisplay = this.approverTab = await this.territoryDataService.fetchApprovalTabData();
    }
    this.currentTab = this.selectedTab;
    this.searchText = '';
    this.initFilterData();
    this.initSortOptions();
    this.sortManageList();
    this.initSectionHeader();
  }

  private getListBasedOnTabSelected() {
    if (this.selectedTab === TerritoryManagementTabs.MANAGE_LIST) {
      return this.customerPositionLists;
    } else {
      return this.approverTab;
    }
  }

  async goBack() {
    this.contactService.contactInformation = this.contactService.tempContactInformation;
    if (this.navParams.data && this.navParams.data.from == PageName.NotificationDetailsComponent) {
      // this.contactService.contactInformation = null;
      // this.contactService.prevSelected = null;
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      // this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
      // this.uiService.isContactsFromToolDrawer = true;
      this.navService.popWithPageTracking();
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { from: 'ContactTool' }, PageName.ContactPageComponent);
    }
    else {
      this.navService.popWithPageTracking();
      this.territoryService.selectedCustomerPostionListSubject.next(null);
    }
  }

  async openCustomerPositionListDetails(customerPositionList: CustomerPositionList) {
    await this.uiService.displayLoader();
    if (this.navService.getActiveChildNavViewPageName() === PageName.TerritoryManagementDetailsComponent) {
      await this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.TerritoryManagementPageComponent)
    }

    this.territoryService.currentListPeriodCustomerList = this.territoryService.currentListPeriod && this.territoryService.currentListPeriod.indskr_customerlistperiodid === customerPositionList.listPeriod.indskr_customerlistperiodid;

    let isReadOnly = this.selectedTab === TerritoryManagementTabs.APPROVALS
      || new Date().setHours(0, 0, 0, 0) > new Date(customerPositionList.listPeriod.indskr_listenddate).setHours(0, 0, 0, 0)
      || this.territoryService.currentListPeriod.indskr_nextlistperiod?.indskr_customerlistperiodid !== customerPositionList.listPeriod.indskr_customerlistperiodid
      || (!this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_NO_WINDOW) && new Date().setHours(0, 0, 0, 0) > new Date(this.territoryService.currentListPeriod.indskr_nextlistmanagementwindowenddate).setHours(0, 0, 0, 0));


    if (this.territoryService.currentListPeriodCustomerList) {
      // ? there could be muliple customer position list with same list period and only open status one should be editable
      isReadOnly = isReadOnly && customerPositionList.statuscode !== StatusCode.OPEN;
    }

    const resp = await this.territoryDataService.fetchMyContactsByCustomerPositionList(customerPositionList, isReadOnly);
    if (customerPositionList.statuscode != StatusCode.OPEN || isReadOnly) {
      this.territoryService.setAvailableContacts(resp);
    } else {
      const configuredAccountIds = await this.territoryDataService.getAvailableContacts(customerPositionList.listPeriod.indskr_customerlistperiodid);
      let condition = customerPositionList.listPeriod.indskr_customerlistperiodid === this.currentListPeriod.indskr_nextlistperiod?.indskr_customerlistperiodid && _.isEmpty(this.territoryService.currentQAccountIds);


      if (this.territoryService.currentListPeriodCustomerList) {
        condition = customerPositionList.listPeriod.indskr_customerlistperiodid === this.currentListPeriod.indskr_customerlistperiodid && _.isEmpty(this.territoryService.currentQAccountIds);
      }

      if (condition) {
        let params = this.currentListPeriod.indskr_nextlistperiod?.indskr_customerlistperiodid;

        if (this.territoryService.currentListPeriodCustomerList) {
          params = this.currentListPeriod.indskr_customerlistperiodid;
        }

        this.territoryService.basePositionListTargets = await this.territoryDataService.fetchCurrentquarterContacts(this.currentListPeriod.indskr_customerlistperiodid, params, true, configuredAccountIds);
        //Keep only selected as BASE
        this.territoryService.basePositionListTargets.forEach(plt => {
          const selectedAcc = plt.contact.accounts.filter(acc => acc['isSelected']);
          plt.contact.accounts = selectedAcc;
        });
        await this.territoryDataService.fetchConfiguredAccounts(this.currentListPeriod.indskr_customerlistperiodid).then((resp) => {
          this.territoryService.currentQAccountIds = resp.map(res => res['account.accountid']);
        });
      }
    }
    customerPositionList.positionListTargets = resp;
    this.territoryService.setMyContacts(customerPositionList)
    await this.uiService.dismissLoader()
    this.selectedCustomerPositionList = customerPositionList;
    this.navService.setChildNavRightPaneView(true);
    this.navService.setChildNavRoot(TerritoryManagementDetailsComponent, PageName.TerritoryManagementDetailsComponent, PageName.TerritoryManagementPageComponent, {
      selectedTab: this.currentTab
    });
  }

  async createCustomerPositionList() {

    if (this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_CURRENT_UPDATE)) {
      this.selectedCustomerPositionList = null;
      this.navService.setChildNavRightPaneView(true);
      this.navService.setChildNavRoot(NewManageListComponent, PageName.TerritoryManagementDetailsComponent, PageName.TerritoryManagementPageComponent);
      return;
    }

    await this.uiService.displayLoader({}, true);
    if (this.navService.getActiveChildNavViewPageName() === PageName.TerritoryManagementDetailsComponent) {
      await this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.TerritoryManagementPageComponent)
    }
    this.selectedCustomerPositionList = null;
    const customerPositionList = this.territoryService.createCustomerPositionList();
    // this.territoryService.selectedCustomerPostionListSubject.next(customerPositionList);
    const configuredAccountIds = await this.territoryDataService.getAvailableContacts(this.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid);
    await this.territoryDataService.fetchCurrentquarterContacts(this.currentListPeriod.indskr_customerlistperiodid, this.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid, false, configuredAccountIds);
    await this.territoryDataService.fetchConfiguredAccounts(this.currentListPeriod.indskr_customerlistperiodid).then((resp) => {
      this.territoryService.currentQAccountIds = resp.map(res => res['account.accountid'])
    });
    this.territoryService.basePositionListTargets = _.cloneDeep(this.selectedCustomerPositionList.positionListTargets);
    //Keep only selected as BASE
    this.territoryService.basePositionListTargets.forEach(plt => {
      const selectedAcc = plt.contact.accounts.filter(acc => acc['isSelected']);
      plt.contact.accounts = selectedAcc;
    });
    await this.uiService.dismissLoader();
    this.navService.setChildNavRightPaneView(true);
    this.navService.setChildNavRoot(TerritoryManagementDetailsComponent, PageName.TerritoryManagementDetailsComponent, PageName.TerritoryManagementPageComponent, {
      selectedTab: this.currentTab
    });
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.territoryService.clear();
  }

}
