import { SelectListData } from "../../models/select-list-data-model";
import { Contact } from "../contact/contact.class";
import { EventRegistration } from "../customer-event/customer-event.class";
import { Observable } from 'rxjs';
import { IMPACT_SEGMENT } from '@omni/services/xperiences/xperiences.service';

/* Data Model Xperiences  Starts here */
export class XpCustomer {
    public contactid: string;
    public fullname: string;
    public description: string;
    public score: number;
    public xperienceFlag: string;
    public savedFlag: boolean;
    public geneeActions: GeneeAction[];
    public primaryAddress: string;
    public trending_category: string;

    public plans: (JourneyPlan | InterestPlan | RelationshipPlan)[];
    public predictions: (JourneyPlan | InterestPlan | RelationshipPlan)[];
    public interests: Interests;
    public journey: Journeys;
    public relationships: Relationships;
    public location: any;
}


export class Journeys {
    public insights: JourneyInsight[];
}

export class Interests {
    public insights: InterestInsight[];
}

export class Relationships {
    public insights: RelationshipInsight[];
}

export class JourneyPlan {
    public id: string;
    public activitytype: string;
    public type: string;
    public strength: number;
    public date: number;
    public products: string[];
    public description: string;
    public feedback: string;
    public feedback_Flag: string;
    public productsText: string;
    public title: string;
    public channeltype: string;
    public translatedTitle: string;
}

export class JourneyInsight {
    public id: string;
    public type: string;
    public date: number;
    public products: Product[];
    public description: string;
    public users: User[];
    public title: string;
    public productsText: string;
    public journeyInsightsText: string;
    public translatedTitle: string;
    public confidence?: number;
    public activitytypecode: string;
}

export class InterestPlan {
    public id: string;
    public type: string;
    public date: number;
    public product: string;
    public description: string;
    public activitytype: string;
    public strength: number;
    public feedback: string;
    public feedback_Flag: string;
    public translatedTitle: string;
}

export class InterestInsight {
    public title: string;
    public description: string;
    public strength: string;
    public score: number;
    public feedback_Flag: string;
    public feedbackGiven: boolean = false;
    public feedback: string;
    public feedbackThumbsUp: boolean = false;
    public emails: InterestInsightsPlus[];
    public medical_inquries: InterestInsightsPlus[];
    public insights_plus: InterestInsightsPlus[];
    public meeting: any[];
    public users: User[];
    public sources: InterestSources;
    public sourcesText: string;
    public latestInsight: InterestInsightsPlus;
}

export class RelationshipPlan {
    public id: string;
    public type: string;
    public date: number;
    public product: string;
    public description: string;
    public activitytype: string;
    public strength: number;
    public feedback: string;
    public feedback_Flag: string;
    public translatedTitle: string;
    public contacts: RelationshipContact[];
    public relationshipContactText: string;
    public event?: EventRegistration;
}

export class RelationshipInsight {
    public fullname: string;
    public contactid: string;
    public description: string;
    public degree: number;
    public score: number;
    public feedback_Flag: string;
    public feedbackGiven: boolean = false;
    public feedbackThumbsUp: boolean = false;
    public accounts: any[];
    public insights_plus: RelationshipInsightsPlus[];
    public meetings: RelationshipInsightsPlus[];
    public events: RelationshipInsightsPlus[];
    public users: User[];
    public contacts: RelationshipContact[];
    public sources?: RelationshipSources;
    public sourcesText: string;
}

export class GeneeAction {
    public activitytypecode: string;
    public strength: string;
    public channeltype: string;
    public score: number;
    public date: number;
    public channeltypeid: string;
}
/* Data Model Xperiences  Ends here */


//Note: Remove it later
export class TrendingCustomer {
    public contactid: string;
    public fullname: string;
    public description: string;
    public activitytypecode: string;
    public interests: CustomerInterest[] = [];
    public journeys: CustomerJourney[] = [];
    public relationships: CustomerRelationship[] = [];
}

export class CustomerJourney {
    public description: string;
    public type: string;
    public date: number;
    public selectedProduct?: SelectListData;
    public indskr_competitorproduct?: string;
    public product?: string;
    public source?: string;
    public degree?: number;
    public title?: string;
    public isJointMeeting?: boolean;
    public confidence: number;
}

export class CustomerInterest {
    public title?: string;
    public description: string;
    public degree?: number;
    public category?: string;
    public selectedCategory: SelectListData;
    public score: number;
    public feedbackGiven?: boolean = false;
    public feedback?: string;
    public feedbackThumbsUp?: boolean = false;
    public sources?: string[];
    public sourcesText: string;
}

export class CustomerRelationship {
    public contactid?: string;
    public customerName?: string;
    public fullname: string;
    public description?: string;
    public degree?: number;
    public types?: SelectListData[] = [];
    public selectedSpeciality?: SelectListData;
    public score?: number = 0;
    public feedbackGiven?: boolean = false;
    public feedback?: string;
    public feedbackThumbsUp?: boolean = false;
    public title?: string;
    public sources?: string[];
    public sourcesText: string;
    public contacts: RelationshipContact[];
}

export class OptionSet {
    constructor(public value: string, public label: string) {

    }
}

export interface Product {
    productid: string;
    productname: string;
}

export interface User {
    userid: string;
    username: string;
}

export interface CentralPlan {
    type: string;
    date: number;
    customer: Contact;
    subject: string;
    products: Product[],
    previewContent: string;
}

export interface RelationshipContact {
    contactid: string;
    fullname: string;
}

export interface InterestInsightsPlus {
    date: number;
    id: string;
    users: User[];
    displayText?: string;
    category?: string;
}

export interface RelationshipInsightsPlus {
    date: number;
    insightid: string;
    users: User[];
    relationship_insight_type?: string;
    displayText?: string;
}

export interface InterestSources {
    insightsPlusByLoggedInUser?: InterestInsightsPlus[];
    otherInsightsPlus?: InterestInsightsPlus[];
    otherSources?: string[];
}

export interface RelationshipSources {
    insightsPlusByLoggedInUser?: RelationshipInsightsPlus[];
    otherInsightsPlus?: RelationshipInsightsPlus[];
    otherSources?: string[];
}
export class GeneralInsight {
    description: string;
    date: number;
    selectedProduct: SelectListData;
    product?: string;
    speciality?: string;
    confidence: number;
    selectedSpeciality: SelectListData;
    indskr_competitorproduct: string;
}

export enum InsightType {
    InisghtsPlus,
    GeneralInsightsPlus
}

export interface TrendingAccount {
    accountName: string,
    accountid: string,
    health: AccountHealth,
    plans: AccountPlan[],
    predictions: any[],
    score: number,
    trending_description: string
}
export interface TrendingFeed {
    feed_id:string,
    description: string,
    likeCount: number,
    likedFlag: boolean,
    plans: AccountPlan[],
    predictions: any[],
    insights:any[],
    interests: Interests,
    journey: Journeys,
    relationships: Relationships,
    second_line_description: string,
    feed_type: string,
    product_topic: string
}

export enum AccountHealth {
    high = 'High health',
    medium = 'Medium health',
    low = 'Low health'
}

export enum AccountPotential {
  high = 'High potential',
  medium = 'Medium potential',
  low = 'Low potential'
}

export enum ContactPotential {
  high = 'High',
  medium = 'Medium',
  low = 'Low'
}

export interface AccountPlan {
    activitytypecode: string,
    date: number,
    products: Product[]
    title: string,
    type: string,
    second_line_description: string
}

export interface AccountPrediction extends AccountPlan {
    strength: AccountPredictionStrength
}

export enum AccountPredictionStrength {
    high = 'high',
    medium ='medium',
    low = 'low'
}

export interface ImpactDetails {
  segment: IMPACT_SEGMENT,
  title: string,
  data: { contactId: string },
  plans: any[],
  predictions: any[],
  insights: { journey: JourneyInsight[], interest: InterestInsight[], relationship: RelationshipInsight[] }
}

