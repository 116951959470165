import { Component, Input } from '@angular/core';
import { ResourceEmailTemplate } from '../../../classes/email-templates/email-template.class';
import { EmailService } from '../../../services/email-templates/email.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the EmailTemplateItemComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'email-template-item',
  templateUrl: 'email-template-item.html',
  styleUrls:['email-template-item.scss']
})
export class EmailTemplateItemComponent {

  @Input() public template: ResourceEmailTemplate;
  @Input() public sortedBy:string;
  public isSelected:boolean;
  private selectedTemplateSubscription: Subscription;

  constructor(private emailService:EmailService,
              private datePipe: DatePipe,
              private translate: TranslateService,
              private dateTimeFormatsService: DateTimeFormatsService) {
  }

  ngOnInit() {
    this.selectedTemplateSubscription = this.emailService.selectedTemplate.subscribe(template=>{
      this.isSelected = template == this.template;
    })
  }

  ngOnDestroy() {
    this.selectedTemplateSubscription.unsubscribe();
  }

  getFormattedLocalisedDate(): string {
    // actually template modied/create date is string and not num it's actually mapped wrong from parsing till usage
    // right now we have to convert that to a string and then back to Int and then to Date
    let datenumber = parseInt((this.template.modifiedon ? this.template.modifiedon : this.template.createdon).toString())
    let date = new Date(datenumber);
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }
}
