import { Observable ,BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Endpoints, APP_VERSION } from "../../../config/endpoints.config";
import { DiskService } from "../../services/disk/disk.service";
import { DB_SYNC_STATE_KEYS } from "../../config/pouch-db.config";
import { AuthenticationService } from '../../services/authentication.service';
import { MyAssistantService } from "../../services/my-assistant/my-assistant.service";
import { DeviceService } from "../../services/device/device.service";
import { timeout } from "rxjs/operators";
import {GlobalUtilityService} from "../../services/global-utility.service";
import { TranslateService } from "@ngx-translate/core";
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { State } from '../../classes/shared/state.class';
import { DataSyncState } from '../../types/state/state.type';

export enum EntityNames {
  'user' = 'systemuser',
  'userPositions' = 'userPositions',
  'accompaniedList' = 'accompaniedList',
  'customerSeg' = 'customerSegment',
  'priority' = 'priority',
  'zip' = 'zip',
  'city' = 'city',
  'presentation' = 'presentation',
  'resource' = 'resource',
  'location' = 'location',
  'timeOffReason' = 'timeOffReason',
  'coachingReport' = 'indskr_coachingreport',
  'assessmentMatrix' = 'assessmentMatrix',
  'teamCoachingReport' = 'teamCoachingReport',
  'coachingTemplate' = 'coachingTemplate',
  'shipmentLossReason' = 'shipmentLossReason',
  'consentTerm' = 'consentTerm',
  'schedulerPattern' = 'schedulerPattern',
  'account' = 'account',
  'secInfoConfig' = 'secInfoConfig',
  'appointment' = 'appointment',
  'contact' = 'contact',
  'callPlan' = 'indskr_cycleplan',
  'teamCallPlan' = 'teamCallPlan',
  'product' = 'product',
  'sampleProduct' = 'indskr_customersampleproduct',
  'shipment' = 'allocShipment',
  'adjustment' = 'allocAdjustment',
  'teamAdjustment' = 'allocTeamAdjustment',
  'timeOff' = 'indskr_timeoffrequest',
  'lot' = 'lot',
  'coachingScales' = 'coachingScales',
  'coachingPlans' = 'coachingPlans',
  'allocationOrder' = 'indskr_sampledrop',
  'consent' = 'indskr_consent',
  'channel' = 'indskr_consenttype',
  'emailTemplate' = 'indskr_emailtemplate',
  'email' = 'email',
  'contentMatching' = 'contentMatching',
  'contentMatchingMessages' = 'contentMatchingMessages',
  'poll_results' = 'poll_results',
  'contentToken' = 'contentToken',
  'followup' = 'followup',
  'progressreports' = 'progressreports',
  'case_inquiry' = 'case_inquiry',
  'scientific_plans' = 'scientific_plans',
  'therapeutic_area' = 'therapeutic_area',
  'order' = 'order',
  'contactEvent' = 'contact_event',
  'allocTransferReason' = 'allocTransferReason',
  'allocAdjustReason' = 'allocAdjustReason',
  'allocTransferUser' = 'allocTransferUSer',
  'settingsAbout' = 'settingsAbout',
  'customerLicense' = 'customerLicense',
  'contactInteraction' = 'contactInteraction',
  'accountInteraction' = 'accountInteraction',
  'meetingType' = 'meetingType',
  'phonecall' = 'phonecall',
  'bulk_profile' = 'bulk_profile',
  'marketScan' = 'marketScan',
  'eventsTool' = 'eventsTool',
  'contactCR' = 'contactCR',
  'accountCR' = 'accountCR',
  'configField' = 'configField',
  'meetingFacts' = 'meetingFacts',
  'messageFacts' = 'messageFacts',
  'hyperlink' = 'hyperlink',
  'surgeryorder' = 'surgeryorder',
  'proceduretracker' = 'proceduretracker',
  'customer_tag' = 'customer_tag',
  'assessmentTemplates' = 'indskr_assessmenttemplates',
  'eventPicklistAttributes' = 'eventPicklistAttributes_',
  'customerassessmentresponse' = 'indskr_customerassessmentresponse',
  'customerassessment' = 'indskr_customerassessment',
  'customerAssets' = 'customerAssets_',
  'assetTransfers' = 'assetTransfers_',
  'assetNotes' = 'annotations_',
  'syncDebug' = 'syncDebug',
  'meetingAssets' = 'meetingAssets',
  'linkedEntities' = 'linkedEntities',
  'contactAssessment' = 'contactAssessment',
  'accountAssessment' = 'accountAssessment',
  'account_tag' = 'account_tag',
  'pharmacovigilance' = 'indskr_pharmacovigilancereportings',
  'kol_statuses' = 'kol_statuses',
  'disease_areas' = 'disease_areas',
  'customerPositionList'='indskr_customerpositionlist',
  'internal_user_survey_response'='indskr_usersurveyresponse',
  'product_indications' = 'product_indications',
  'medical_insights' = 'medical_insights',
  'sub_specialties' = 'sub_specialties',
  'appealStatus'='appealStatus',
  'marketing_business_plan_types' = 'marketing_business_plan_types',
  'setbooking' = 'setbooking',
  'procedure_contract_position_group_products' = 'procedure_contract_position_group_products',
  'coachingPlanActivity' = 'coachingPlanActivity',
}

export enum SyncState {
  'SyncStarted' = 1,
  'BlockingSyncDone',
  'NonBlockingSyncDone',
  'AllSyncDone',
  'SyncNotInProgress'
}

export interface SyncInfoDTO {
  indskr_totalsyncedrecords: number,
  indskr_totalfailedrecords: number,
  indskr_errormessage: string,
  indskr_syncstarttime: string,
  indskr_syncendtime: string,
  indskr_device: string,
  indskr_operatingsystem: string,
  indskr_syncstatus: boolean,
  indskr_initialsync: boolean,
  indskr_pushorpull: boolean,
  indskr_appversion: string,
  overriddencreatedon: string,
  createdon: string,
}
export interface DeltaSyncStatusDTO {
  entityCount: number,
  entityName: EntityNames,
}
export interface DeltaRecordsDTO {
  accounts: string[],
  contacts: string[],
  presentations: string[],
  resources: string[]
}
export interface SyncError {
  serviceUrl: string,
  statusCode: string,
  response: string;
}
export interface EntitySyncInfo {
  entityName: EntityNames | string,
  totalSynced: number,
  totalFailed: number,
  errors: SyncError[],
  syncStatus: boolean,
}
export interface SyncInfo {
  totalSynced: number,
  totalFailed: number,
  isInitialSync: boolean,
  syncStatus: boolean,
  isPush: boolean,
  device: string,
  osVersion: string,
  syncStartTime: string,
  syncEndTime: string,
  appVersion: string,
  errors: SyncError[],
  entities: EntitySyncInfo[],
}

@Injectable({
  providedIn: 'root'
})
export class DeltaService {
  private _pushSyncInfo: SyncInfo;
  private _pullSyncInfo: SyncInfo;
  private _deltaStatusResponse: DeltaSyncStatusDTO[];
  public deltaRecordsDTO: DeltaRecordsDTO;
  public isInitialSyncFinished: boolean = false;
  private _syncEntityList: Map<SyncFeatureCategory, string> = new Map();
  private _syncEntityList$: BehaviorSubject<Map<SyncFeatureCategory, string>> = new BehaviorSubject(this._syncEntityList);

  public readonly syncEntityListObservable: Observable<Map<SyncFeatureCategory, string>> = this._syncEntityList$.asObservable();
  readonly masterSyncState: State<string, DataSyncState> = new State<string, DataSyncState>('masterSyncState', 'Null');

  constructor(private http: HttpClient, private disk: DiskService, private device: DeviceService,
    private authenticationService: AuthenticationService, private myAssistantService: MyAssistantService, public utilityService:GlobalUtilityService,public translate:TranslateService) {
        this.deltaRecordsDTO = {
          accounts: [], contacts: [], presentations: [], resources: []
        }
    }

  wasPushFailed() {
    return this._pushSyncInfo ? !this._pushSyncInfo.syncStatus : false;
  }

  wasPullFailed() {
    return this._pullSyncInfo ? !this._pullSyncInfo.syncStatus : false;
  }

  startSyncInfoRecording(isPush = false, isInitialSync = false) {
    const newSyncInfo: SyncInfo = {
      totalSynced: 0,
      totalFailed: 0,
      isInitialSync,
      syncStatus: true,
      isPush,
      device: this.device.deviceInfo.info,
      osVersion: this.device.deviceInfo.osVersion,
      syncStartTime: new Date().getTime().toString(),
      syncEndTime: null,
      appVersion: APP_VERSION,
      errors: [],
      entities: []
    }

    if (isPush) {
      this._pushSyncInfo = newSyncInfo;
    } else {
      this._pullSyncInfo = newSyncInfo;
    }
  }

  addEntitySyncInfo(entitySyncInfo: EntitySyncInfo, isPush = false) {
    if (!entitySyncInfo) {
      return;
    }
    const syncInfo = isPush ? this._pushSyncInfo : this._pullSyncInfo;
    if (syncInfo) {
      syncInfo.entities.push(entitySyncInfo);
      syncInfo.syncStatus = syncInfo.syncStatus ? entitySyncInfo.syncStatus : false;
      syncInfo.errors = [...syncInfo.errors, ...entitySyncInfo.errors];

      if (isPush) {
        // Failed count is only for push
        syncInfo.totalFailed += entitySyncInfo.totalFailed;
        syncInfo.totalSynced += entitySyncInfo.totalSynced;
      } else {
        // For pull, only count sync success
        if (!entitySyncInfo.errors || entitySyncInfo.errors.length === 0) {
          syncInfo.totalSynced += entitySyncInfo.totalSynced;
          /*if (syncInfo.isInitialSync) {
            // Initial sync uses actual record count of each entity response
            syncInfo.totalSynced += entitySyncInfo.totalSynced;
          } else {
            // Delta sync uses 'deltachanges' service reseponse to get the accurate sync count
            if (this._deltaStatusResponse && Array.isArray(this._deltaStatusResponse)) {
              const entityResponse = this._deltaStatusResponse.find(e => e.entityName === entitySyncInfo.entityName);
              if (entityResponse) {
                syncInfo.totalSynced += entityResponse.entityCount;
              }
            } else {
              console.warn('addEntitySyncInfo: delta status response does not exist');
            }
          }*/
        }
      }
    } else {
      console.warn('addEntitySyncInfo: syncInfo or entitySyncInfo not available..', syncInfo, entitySyncInfo);
    }
  }

  endSyncInfoRecording(isPush = false) {
    const syncInfo = isPush ? this._pushSyncInfo : this._pullSyncInfo;
    syncInfo.syncEndTime = new Date().getTime().toString();
  }

  addSyncErrorToEntitySyncInfo(entitySyncInfo: EntitySyncInfo, url: string, error: any) {
    if (!entitySyncInfo) {
      return;
    }
    if (!error) {
      error = 'Empty error..';
    }

    let errorObj: SyncError;

    if (error instanceof HttpErrorResponse) {
      errorObj = {
        serviceUrl: url,
        statusCode: error.status ? error.status.toString() : '',
        response: error.error ? error.error : error
      };
    } else if (error.hasOwnProperty('stack') && error.hasOwnProperty('message') && typeof error.toString === 'function') {
      errorObj = {
        serviceUrl: url,
        statusCode: error && error.errorCode ? error.errorCode : '',
        response: JSON.stringify({ message: error.toString(), stack: error.stack })
      };
    } else if (error.hasOwnProperty('debug')) {
      const stack: string = error.debug.error?.hasOwnProperty('stack') ? error.debug.error.stack : '';
      const message: string = error.debug.error?.toString() ? error.debug.error?.toString() : '';
      errorObj = {
        serviceUrl: url,
        statusCode: '',
        response: JSON.stringify({ message, stack, record: error.debug.record, description: error.debug.description })
      }
    } else {
      errorObj = {
        serviceUrl: url,
        statusCode: error && error.errorCode ? error.errorCode : '',
        response: error && error.errorMessage ? error.errorMessage : error.hasOwnProperty('e') ? JSON.stringify(error.e) : error
      };
    }

    entitySyncInfo.errors.push(errorObj);
    entitySyncInfo.syncStatus = false;
  }

  cleanUpSyncInfoRecording() {
    this._pullSyncInfo = null;
    this._pushSyncInfo = null;
    this._deltaStatusResponse = null;
  }

  async uploadSyncStatus(isPush = false, isPartialUpload = false): Promise<void> {
    const syncInfo = isPush ? this._pushSyncInfo : this._pullSyncInfo;
    if (!syncInfo) {
      console.warn('uploadSyncStatus: no syncInfo to upload');
      return;
    }
    if (!isPartialUpload && this.device.isOffline) {
      return;
    }

    try {
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.delta.UPLOAD_SYNC_INFO;
      const payload: SyncInfoDTO = {
        indskr_totalsyncedrecords: syncInfo.totalSynced,
        indskr_totalfailedrecords: syncInfo.totalFailed,
        indskr_errormessage: JSON.stringify(syncInfo.errors),
        indskr_syncstarttime: syncInfo.syncStartTime,
        indskr_syncendtime: syncInfo.syncEndTime,
        indskr_syncstatus: syncInfo.syncStatus,
        indskr_initialsync: syncInfo.isInitialSync,
        indskr_pushorpull: syncInfo.isPush,
        indskr_device: syncInfo.device,
        indskr_appversion: syncInfo.appVersion,
        indskr_operatingsystem: syncInfo.osVersion,
        overriddencreatedon: null,
        createdon: syncInfo.syncStartTime,
      };
      console.log('uploadSyncStatus: ', payload);
      await this.http.post(url, payload).toPromise();
    } catch (error) {
      console.error('uploadSyncStatus: ', error);
      // TODO: handle error..
    }

    return;
  }

  /**
   * This fn will call the endpoint to determine the new entity count to be synced.
   *
   * @memberof DeltaService
   */
  public async updateDeltaSyncStatus() {
    const deltaSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_STATUS);
    const newLastUpdatedTime = new Date().getTime();

    let lastUpdatedTime = deltaSyncState && deltaSyncState.lastUpdatedTime ? deltaSyncState.lastUpdatedTime : null;

    if (!lastUpdatedTime) {
      const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACTIVITY);
      lastUpdatedTime = syncState && syncState.lastUpdatedTime ? syncState.lastUpdatedTime : null;
    }

    //We have a syncState, grab the lastUpdatedTime and replace url
    if (lastUpdatedTime) {
      this.deltaRecordsDTO = {
        accounts: [],contacts: [] , presentations: [], resources: []
      }
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.delta.GET_DELTA_SYNC_NOTIFICATIONS.replace("{lastUpdatedTime}", lastUpdatedTime);

      try {
        //Grab the new entity counts. Giving 10s timeout so that it doesn't block the whole sync process in case of service failure
        this._deltaStatusResponse = await this.http.get<DeltaSyncStatusDTO[]>(url).pipe(timeout(10000),).toPromise();

        if (this._deltaStatusResponse && this._deltaStatusResponse.length > 0) {
            this.transformNotificationResponseIntoNotification(this._deltaStatusResponse);
        }
      } catch (httpError) {
        console.error(
          "updateDeltaSyncStatus: Caught an http error trying to get the notification count for delta sync",
          httpError
        );
        // Got an error. Don't update syncState
        return;
      }
    }

    if (!deltaSyncState) {
      const doc = { lastUpdatedTime: newLastUpdatedTime };
      await this.disk.createDocument(DB_SYNC_STATE_KEYS.SYNC_STATUS, doc);
    } else {
      deltaSyncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(deltaSyncState);
    }
  }


  /**
   *  Will take a raw json object from our delta sync endpoint and output notifications to the user regarding the entities downloaded
   *
   * @private
   * @param {object} raw
   * @memberof DeltaService
   */
  private transformNotificationResponseIntoNotification(raw: Array<DeltaSyncStatusDTO>) {
    let notification = '';
    //Loop over every object in this array
    raw.map(rawObject => {
        //Create our notifcation string
        const entityName: string = this.getDisplayNameForEntity(rawObject.entityName);
        if(entityName.length > 0)
          notification += `\n${entityName} - ${rawObject.entityCount}`;
    });
    //We push the notification to our assistant service
    if (notification) {
      notification = this.translate.instant('UPDATED_DATA_AVAILABLE') + notification;
      // this.myAssistantService.addNewNotification(notification);
    }
  }

  /**
   *  Returns a display name string for various entities for a clean display for notifications
   *
   * @private
   * @param {string} entity
   * @returns {string}
   * @memberof DeltaService
   */
  private getDisplayNameForEntity(entity: string): string {
    switch(entity) {
        case 'account':
        return 'Accounts';

        case 'indskr_coachingreport':
        return 'Coachings';

        case 'indskr_cycleplan':
        return 'Call Plans';

        case 'appointment':
        return 'Meetings';

        case 'contact':
        return this.utilityService.globalCustomersText;

        case 'indskr_timeoffrequest':
        return 'Time Off';

        case 'systemuser':
        return 'System Users';

        case 'indskr_customersampleproduct':
        return this.utilityService.globalCustomerText+' Allocations';

        case 'indskr_usershipmentallocation_v2':
        return 'Allocation Shipments';

        case 'indskr_sampledrop':
        return 'Allocation Orders';

        case 'product':
        return 'Products'

        case 'indskr_consent':
        return 'Consents';

        case 'indskr_consenttype':
        return 'Channels';

        case 'indskr_iopresentation':
        return 'Presentations';

        case 'indskr_ioresource':
        return 'Resources';
        default:
          return '';
    }
  }

  /**
   * Sync entity list array observable helper
   */
  pushSyncEntityName(category: SyncFeatureCategory) {
    if (!this._syncEntityList.has(category)) {
      this._syncEntityList.set(category, category);
      this._syncEntityList$.next(this._syncEntityList);
    }
  }
  resetSyncEntityList() {
    this._syncEntityList.clear();
    this._syncEntityList$.next(this._syncEntityList);
  }
}
