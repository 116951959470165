import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { EmailTemplateServices } from '../../../data-services/email-templates/email-template.service';
import { PopoverController, NavController, LoadingController, } from '@ionic/angular';
import { Events } from '@omni/events';
import { Contact } from '../../../classes/contact/contact.class';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
@Component({
    selector: 'email-template',
    templateUrl: 'email-template.html',
  styleUrls:['email-template.scss']
})

export class RemoteURLEmailTemplate {

    public selectedTemplate: string;
    public selectedTemplateContacts: Contact[] = [];
    public contacts: Contact[];
    public emailTemplateBody: string;
    public emailTemplateSubject: string;
    public searching: boolean;
    private readonly MIN_SEARCH_LENGTH = 2;
    public searchModel: string='';
    public counter: number = 0;
    @Input() from: any

    @ViewChild('contactpopup', {static:true}) contactpopup: ElementRef;

    private isLayoverPushView:boolean = false;
    private layoverViewPushedFrom:string;

    constructor(public emailtemplateService: EmailTemplateServices,
        public popoverCtrl: PopoverController,
        public contactService: ContactOfflineService,
        public activityService: ActivityService,
        public uiService: UIService,
        public navCtrl: NavController,
        public loadingCtrl: LoadingController,
        public device: DeviceService,
        public footerService: FooterService,
        public events: Events,
        private navService: NavigationService,
        private translate : TranslateService,
        private notificationService: NotificationService,
    ) {
    }

    async ngOnInit() {
        if (this.from == 'AccountManagementTool') {
          this.isLayoverPushView = true;
          this.layoverViewPushedFrom = 'AccountManagementTool';
        }
        this.footerService.initButtons(FooterViews.MeetingURLEmail);
        let loading = await this.loadingCtrl.create();
        loading.present();
        this.emailtemplateService.getAllEmailTemplates()
            .subscribe(data => {

                if (this.emailtemplateService.RemoteURLEmailTemplates && this.emailtemplateService.RemoteURLEmailTemplates.length > 0) {
                    this.selectedTemplate = this.emailtemplateService.RemoteURLEmailTemplates[0].ID;
                    this.emailTemplateBody = this.emailtemplateService.RemoteURLEmailTemplates[0].body;
                    this.emailTemplateSubject = 'Remote Meeting Connection Information'; //this.emailtemplateService.RemoteURLEmailTemplates[0].subject;

                    this.emailtemplateService.emailContacts = (this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'].slice() : [];
                    this.emailtemplateService.remoteMeetingURL = (this.activityService.selectedActivity['meetingURL'] != undefined) ? this.activityService.selectedActivity['meetingURL'] : '';
                    this.contacts = this.contactService.contacts.slice(0,100);
                }
                loading.dismiss();
            });
    }

    getItems(ev) {
        let val = (ev.value) ? ev.value : '';
        if (val.length <= this.MIN_SEARCH_LENGTH) {
           this.contactpopup.nativeElement.style.display = 'none';
            return;
        }else{
            this.filterContacts(val);
            this.contactpopup.nativeElement.style.display = 'block';
            this.contactpopup.nativeElement.style.height = this.setContactpopupHeight();
        }
    }

    setContactpopupHeight(){
        switch(this.contacts.length){
            case 0: return("0px");
            case 1: return("45px");
            case 2: return("90px");
            case 3: return("135px");
            default: return("180px");
        }
    }

    onCancel(event) {
        this.closePopover();
    }

    filterContacts(query: string) {
        query = query.toUpperCase();
        this.contacts = this.contactService.contacts.filter(contact => {
            if (contact.firstName && contact.lastName) {
                let name = contact.firstName + ' ' + contact.lastName;
                //let email = (contact.getPrimaryEmail) ? contact.getPrimaryEmail : undefined;
                return (name.toUpperCase()
                    .includes(query)); //|| email.toUpperCase().includes(query)
            } else {
                return false;
            }
        });
    }
    sendEmail() {
        if(this.device.isOffline){
          this.notificationService.notify(this.translate.instant("EMAIL_TEMPLATE_CAN_NOT_SEND_EMAIL"),'Email Template','top',ToastStyle.DANGER);
          return;
        }
        this.emailtemplateService.sendRemoteURLEmail(this.selectedTemplate);
        this.footerService.initButtons(FooterViews.MeetingURLEmail);
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'Appointment';
    }

    deleteContact(chip: string,contact:Contact) {
        let ele = document.getElementById(chip);
        if (ele) ele.remove();
        let selectedIndex = this.emailtemplateService.emailContacts.findIndex(emailContact => emailContact.ID === contact.ID);
        if (selectedIndex < 0) return;
        this.emailtemplateService.emailContacts.splice(selectedIndex, 1);
    }
    selectContact(selContact) {
        this.emailtemplateService.emailContacts.push(selContact);
        this.searchModel = '';
        //if(this.activityService.selectedActivity['contacts']) this.activityService.selectedActivity['contacts'].push(selContact)
        this.contactpopup.nativeElement.style.display = 'none';
    }
    closePopover() {
        setTimeout(() => {
            this.contactpopup.nativeElement.style.display = 'none';
        }, 200);

    }
    backToMeeting() {
        this.footerService.footerSelectedView = 'activities';
        //this.footerService.initButtons(FooterViews.Activities);
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'Appointment';
        if(this.isLayoverPushView){
            if(this.layoverViewPushedFrom == 'AccountManagementTool'){
              this.navService.popChildNavPageWithPageTracking();
            }
        }else if (this.device.isMobileDevice) {
            this.navService.popWithPageTracking().then(()=>{
                this.uiService.activeView = this.uiService.prevView;
                if(this.uiService.activeView === 'Appointment') this.footerService.initButtons(FooterViews.Activities);
                if(this.uiService.activeView === 'Meeting') this.footerService.initButtons(FooterViews.PreviewMeeting);
            });
        }
    }
    updateEmailTemplate() {
        let resultTemplate = this.emailtemplateService.getEmailTemplateDetailById(this.selectedTemplate);
        this.emailTemplateBody = (resultTemplate) ? resultTemplate.body : '';
        this.emailTemplateSubject = 'Remote Meeting Connection Information'; //(resultTemplate) ? resultTemplate.subject : '';
    }
    openContacts() {
        if (!this.contactService.loadedContacts) return;
        this.uiService.prevView = 'EmailTemplate';
        this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent);
    }
    doInfinite(infiniteScroll) {
        if (this.contacts.length < this.contactService.contacts.length) {
            for (let i = this.counter; i < (this.counter + 50); i++) {
                this.contacts.push(this.contactService.contacts[i]);
            }
            this.counter = this.counter + 50;
        }
        infiniteScroll.complete();
    }

    // addNewEmail(ev){
    //     if(ev.code == "Enter"){
    //         let email = ev.target.value;
    //         let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    //         if(regexp.test(email)){
    //             this.emailtemplateService.emailContacts.push
    //         }
    //     }
    // }
}
