import { Component, ElementRef, HostBinding, Input, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { POPUP_MODAL_HEADER_HEIGHT, POPUP_MODAL_MAX_HEIGHT_PERCENT } from '@omni/config/shared.config';
import { PharmacovigilanceInfoModalData } from '@omni/interfaces/pharmacovigilance-reporting/pharmacovigilance.interface';

@Component({
  selector: 'pharmacovigilance-info-modal',
  templateUrl: 'pharmacovigilance-info-modal.html',
  styleUrls: ['pharmacovigilance-info-modal.scss']
})
export class PharmacovigilanceInfoModalComponent {
  @Input() data: PharmacovigilanceInfoModalData;
  @HostBinding('class.ion-page') pageClass = false;
  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;

  constructor(
    private modalCtrl: ModalController,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      const maxContentHeight = window.innerHeight * POPUP_MODAL_MAX_HEIGHT_PERCENT - POPUP_MODAL_HEADER_HEIGHT;
      const contentHeight = Math.min(maxContentHeight, this.contentWrapper.nativeElement.offsetHeight);
      this.setContentHeight(contentHeight);
    }, 50);
  }

  onCloseClick(ev: any) {
    this.modalCtrl.dismiss();
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }
}
