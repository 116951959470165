<ion-grid>
    <ion-row>
        <ion-col class="captureScreenWrapper">
            <ion-header>
                <ind-page-title [viewData]="captureEventsPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
            </ion-header>
            <ion-content>
                <ind-section-header [viewData]='{id:"event_participants",title: translate.instant("PARTICIPANTS_CHECKIN_CAP")}'></ind-section-header>
                <div *ngFor="let item of viewModel | orderBy:'name':false">
                    <div class="accordion-header">
                        <ion-item [ngClass]="item.isExpanded?'accordion-item':''">
                            <ion-icon class="checkboxStyleIcons" name="checkmark-circle" *ngIf="item.checkboxChecked"></ion-icon>
                            <ion-icon class="checkboxStyleIcons" name="ellipse-outline" *ngIf="!item.checkboxChecked"></ion-icon>
                            <ion-label item-content [ngClass]="item.isExpanded?'accordion-primary-text':'primary-text'">{{item.primaryTextLeft}}</ion-label>
                            <ion-icon [ngClass]="item.accordionIconCssClass" class="accordion-icon" *ngIf="!isEventAttendanceCapture && item.showAccordionIcon" slot="end" [name]="item.accordionIconType" (click)="item.clickHandler(item.id,'accordionIcon',item)"></ion-icon>
                            <ion-buttons slot="end" *ngIf="isEventAttendanceCapture">
                              <ion-button #rightSectionButton [disabled]="item.disableCheckInBtn" (click)="onCheckIn(item)">
                                <div class="button-content-wrapper">
                                  <span class="section-header-button-text">{{('CHECK_IN') | translate}}</span>
                                </div>
                              </ion-button>
                            </ion-buttons>
                        </ion-item>
                    </div>
                    <ion-item class="accordian-expanded" *ngIf="item.isExpanded">
                        <div class="signatureImageContainer" *ngIf="!item.expandableData">
                            <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
                            <div class="signatureWrapper ion-text-center">
                                <p text-center (click)="launchSignaturePad(item.id)">{{('CONSENT_TAP_TO_SIGN') | translate}}</p>
                            </div>
                        </div>
                        <div class="signatureImageContainer" *ngIf="item.expandableData">
                            <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
                            <div class="capturedImage signatureWrapper ion-text-center">
                                <img [src]="item.expandableData" width="100" height="100">
                            </div>
                        </div>
                    </ion-item>
                </div>
            </ion-content>
            <!-- <ion-footer> -->
            <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'captureEvents'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>
    </ion-row>
</ion-grid>