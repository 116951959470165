import { Component, OnInit } from '@angular/core';
import { EmailAddress, EmailViewType } from '../../classes/activity/email.activity.class';
import { EmailActivity } from '../../classes/activity/email.activity.class';
import { EmailService } from '../../services/email-templates/email.service';
import { UIService } from '../../services/ui/ui.service';
import { TrackingEventNames, TrackService } from '../../services/logging/tracking.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { GlobalUtilityService } from "../../services/global-utility.service";
import { IonNav } from '@ionic/angular';
import { ChildNavNames, NavigationService } from '../../services/navigation/navigation.service';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ChannelActivityType } from '@omni/classes/consent/channel.class';
import _ from 'lodash';


/**
 * Generated class for the EmailActivityDetailComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'resource-interaction-detail',
  templateUrl: 'resource-interaction-detail.html',
  styleUrls: ['resource-interaction-detail.scss']
})
export class ResourceInteractionDetailComponent implements OnInit {
  public resourceName: string = "";
  public thumbnailURL: string = "";
  public interactionTimeDetails: EmailAddress[] = [];
  public interactedAttachments = [];
  public resourceInteractionDetails;
  private inMeetingFlow: boolean = false;
  public allCustomersHeaderModel: IndSectionHeaderViewDataModel;
  public attachmentsHeaderModel: IndSectionHeaderViewDataModel;

  constructor(public emailService: EmailService,
    private trackingService: TrackService,
    public uiService: UIService,
    public repService: RepServices,
    public utilityService: GlobalUtilityService,
    private navCtrl: IonNav,
    private navService: NavigationService,
    public footerService: FooterService,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    console.log("Here ResourceInteractionDetailComponent");
    console.log(this.emailService.resourceInteractionDetails);
    this.resourceInteractionDetails = this.emailService.resourceInteractionDetails;
    this.resourceName = this.emailService.resourceInteractionDetails.emailAttachment.indskr_ckmtitle;
    this.thumbnailURL = this.emailService.resourceInteractionDetails.emailAttachment.indskr_ckmthumbnailurl;

    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType === EmailViewType.EMAIL_FROM_MEETING_PRESENTATION || this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      this.inMeetingFlow = true;
    }
    if (this.emailService.viewType != EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
      this.footerService.initButtons("")
    }
    this.initializeAllCustomersHeader();
    this.initializeAttachmentsHeader();
  }

  public backButton = [{
    id: "close",
    icon: "chevron-back-outline",
    isDisabled: false,
    align: "left"
  }];

  public get pageTitle() {
    if (this.uiService.activeView === 'ResourceContactList') {
      return this.resourceName;
    }
    else {
      return this.translate.instant('RESOURCE_SHARE_HISTORY');
    }
  }

  private initializeAllCustomersHeader() {
    this.allCustomersHeaderModel = {
      id: 'resource-interaction-details-title1',
      title: `${this.translate.instant('ALL') + ' ' + this.utilityService.globalCustomersText.toUpperCase()}`,
      controls: [],
    };
  }

  private initializeAttachmentsHeader() {
    this.attachmentsHeaderModel = {
      id: 'resource-interaction-details-title2',
      title: `${this.translate.instant('ATTACHMENTS')}`,
      controls: [],
    };
  }

  goBack() {
    if (this.uiService.activeView === 'ResourceContactList') {
      this.closeResourceContactList();
    } else {
      this.closeResourceInteractionList();
    }
  }

  closeResourceContactList() {
    if (this.inMeetingFlow) {
      this.uiService.activeView = this.uiService.prevView;
      if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING || this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.footerService.initButtons(FooterViews.EmailDetails);
        });
      } else {
        this.navService.popChildNavPageWithPageTracking();
      }
    } else {
      this.uiService.activeView = 'Email';
      this.footerService.initButtons(FooterViews.EmailDetails);
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  closeResourceInteractionList() {
    this.interactionTimeDetails = [];
    this.uiService.activeView = 'ResourceContactList';
  }

  openInteractionDetails(contactId: string) {
    this.trackingService.tracking('EmailAttachmentHistory', TrackingEventNames.EMAIL, null, true);
    if (this.emailService.selectedActivity.channelActivityType === ChannelActivityType.WECOM) {
      this.interactedAttachments = this.emailService.selectedActivity.emailAttachments
      .filter(attachment => attachment.indskr_emailattachmentid === this.resourceInteractionDetails.emailAttachment.indskr_emailattachmentid);
    } else {
      this.resourceInteractionDetails.interactionDetails.filter(emailParty =>
        emailParty.indskr_contactid === contactId
      ).forEach(emailParty => {
        this.interactionTimeDetails = this.interactionTimeDetails.concat(emailParty.emailAddresses)
      });
    }
    this.uiService.activeView = 'ResourceInteractionDetails';
  }

  ngOnDestroy() {
    console.log('destroying ResourceInteractionDetailComponent');
  }
}
