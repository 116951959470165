import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ContactMedicalInsight } from "@omni/classes/contact/contact.class";
import { MultiSelectPopover } from "@omni/components/multi-select-popover/multi-select-popover";
import { IndFilterMenuComponent } from "@omni/components/shared/ind-filter-menu/ind-filter-menu";
import { IndTabsDataModel } from "@omni/models/ind-tabs-data-model";
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { AlertService } from "@omni/services/alert/alert.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { DeviceService } from "@omni/services/device/device.service";
import { EventsService } from "@omni/services/events/events.service";
import { FooterService } from "@omni/services/footer/footer.service";
import { MedicalInsightService } from "@omni/services/medical-insight/medical-insight-service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { log } from "console";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ContactMedicalInsightComponent } from "../contact-medical-insight/contact-medical-insight";
import _, {isEmpty} from 'lodash';
import { SelectedSuggestionPillDataModel } from "@omni/models/search-config-data-model";

/**
 * Generated class for the MedicalInsightListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
 @Component({
  selector: 'medical-insight-list',
  templateUrl: 'medical-insight-list.html',
  styleUrls:['medical-insight-list.scss']
})
export class MedicalInsightListComponent implements OnInit {

  private myMedicalInsightItemsList: Array<ContactMedicalInsight> = [];
  public filteredItemsList:Array<ContactMedicalInsight> = [];
  public filteredViewItemsList: Array<MainCardViewDataModel> = [];
  public isTeamMedicalInsightViewEnabled = false;
  public medicalInsightListMode:string = 'my_medical_insight';

  public searchInput ='';
  public isTeamTabActive = false;
  public noItemsMessage = 'NO_INSIGHTS';
  public listCount = 0;
  public tabsData: IndTabsDataModel[];
  public indHeaderLeftModel: IndHeaderLeftDataModel;
  public listHeader: IndSectionHeaderViewDataModel;

  selectedFilterData: SelectedSuggestionPillDataModel[] = [];
  public filterNameBubble: { categoryName: string; suggestion: SelectedSuggestionPillDataModel[]}[] = [];
  filtering = false;

  private unsubscribe$ = new Subject<void>();
  popoverData:{ text: string; value: string;  items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];;

  constructor(
    public navService: NavigationService,
    private readonly authService: AuthenticationService,
    private readonly uiService: UIService,
    public readonly device: DeviceService,
    private readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    public medicalInsightService: MedicalInsightService,
    public footerService: FooterService,
    private readonly alertService: AlertService,
    private readonly events: EventsService,
    public contactService: ContactOfflineService,
    public popover: PopoverController,
    private modalCtrl:ModalController,
  ){

    this._initHeaderLeft();
    this.myMedicalInsightItemsList = this.medicalInsightService.myMedicalInsights;
    this.medicalInsightService.mapMedicalInsightsToSearchIndex(this.myMedicalInsightItemsList);
  }

  ngOnInit() {
    this._setTabsData();
    this.updateItemsToDisplay();
    // this.initFilter();

    // this.events.subscribe(EventName.NEW_MEDICAL_INSIGHT_SUBMITTED, (params) => {
    //   this.updateItemsToDisplay();
    // });

    this.medicalInsightService.newMedicalInsightObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(async (medicalInsight:ContactMedicalInsight)=> {
      if(medicalInsight){
        this._handledNewMedicalInsightCreation(medicalInsight);
        this.updateItemsToDisplay();
      }
    });
  }

  ngOnDestroy() {
    //this.medicalInsightService.teamMedicalInsight = [];
    this.medicalInsightService.selectedMedicalInsight = null;
    this.medicalInsightService.isInsightCreateInProgress = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _initHeaderLeft(): void {  
    this.indHeaderLeftModel = {
      id: 'medical-insight-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('COLLABORATIVE_INSIGHTS'),
      mode: true,
      controls: [
        {
          id: "back",
          imgSrc: 'assets/imgs/header_cancel.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: false,
          align: "left",
        },
        {
          id: "plusNew",
          imgSrc: 'assets/imgs/ios_add_3x.svg',
          isDisabled: false,
          align: "right",
        }
      ],
    };
  }

  private _initListHeader() {
    let title:string = 'ALL_INSIGHTS';
    if(this.searchInput.length){
      title = 'RESULTS_CAP'
    }else if(this.isTeamTabActive){
      title = 'ALL_APPROVALS'
    }
    this.listHeader = {
      id: 'selected-header',
      title: this.translate.instant(title) + ' (' + this.listCount + ')',
      controls: [],
    };
  }

  private _setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('ALL_INSIGHTS'),
         value: "my_medical_insight"
      },
      {
       displayText: this.translate.instant('APPROVALS'),
       value: "team_medical_insight",
       disable: !this.isTeamMedicalInsightViewEnabled,
      },
    ];
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'back':
        this._onClosePage();
        break;
      case 'plusNew':
        this._handleMedicalInsightCreate();
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public segmentChanged(selectedTab: string): void {
    if (selectedTab === 'my_medical_insight') {
      this.isTeamTabActive = false;
      this.updateItemsToDisplay();
      this.noItemsMessage = 'NO_INSIGHTS';
    } else if (selectedTab === 'team_medical_insight') {
      this.isTeamTabActive = true;
      this.noItemsMessage = 'NO_APPROVALS';
      this.getTeamMedicalInsightData();
    }
    this.searchInput = '';
  }

  public updateItemsToDisplay() {
    // if(this.isTeamTabActive){
    //   this.filteredItemsList = this.medicalInsightService.teamMedicalInsight.filter((a) => {
    //     return (a.name.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
    //   });
    //   this.filteredViewItemsList = this.filteredItemsList.sort((a, b) => (b.createdOn.getTime()) - (a.createdOn.getTime())).map(item=>{
    //     return this.getMainCardDataModel(item);
    //   });
    // }else{
      this.filteredItemsList = this.myMedicalInsightItemsList.filter((a) => {
        return ((a.contactNameString?a.contactNameString:a.summary).toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
      });
      this.filteredViewItemsList = this.filteredItemsList.sort((a, b) => (b.dateCreated.getTime()) - (a.dateCreated.getTime())).map(item=>{
        return this.getMainCardDataModel(item);
      });
    //}
    this.updateEmptyMessage();
    this._initListHeader();
  }

  public getMainCardDataModel(item: ContactMedicalInsight) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: item.ID,
      fixedHeight: true,
      primaryTextRight: item.contactId ? item.contactNameString : item.summary,
      secondaryTextRight: this.getFormattedDate(item.dateCreated),
      secondaryTextRightTwo: item.ownerNameString,
      secondaryTextRightThree: '',
      isSelected: item.ID === this.medicalInsightService.selectedMedicalInsight?.ID,
      showArrow: false,
      clickHandler: (id: string, event, specificTarget,refData) => {
        this.handleCardItemClick(id, event, specificTarget, refData)
      }
    };
    return viewData;
  }

  private handleCardItemClick(id: string, event, specificTarget,refData){
    if(specificTarget === 'mainItem'){
      if(this.medicalInsightService.isInsightCreateInProgress){
        this._showDiscardConfirmationPopover().then(res=> {
          this.openMedicalInsightDetails(id);
        }).catch(err=> {
          return;
        })
      }else{
        this.openMedicalInsightDetails(id);
      }
    }
  }

   filterItemsToDisplay(field, values) {
    // ? need optimization and should support save filter later.
    if(this.searchInput && field){
      this.filteredItemsList = this.myMedicalInsightItemsList.filter((medicalInsight) => {
        return ((medicalInsight.contactNameString?medicalInsight.contactNameString:medicalInsight.summary).toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1) && values.includes(medicalInsight[field]);
      });
    } else {
      this.filteredItemsList = this.myMedicalInsightItemsList.filter((medicalInsight) => {
        return values.includes(medicalInsight[field])
      });
    }

    
     this.filteredViewItemsList = this.filteredItemsList.sort((a, b) => (b.dateCreated.getTime()) - (a.dateCreated.getTime())).map(item => {
       return this.getMainCardDataModel(item);
     });
     this.updateEmptyMessage();
     this._initListHeader();
   }

  async initFilter(){
      // Add: if there is no search categories available return with the message
      let options;
      const filterMenuModal = await this.modalCtrl.create({
        component: IndFilterMenuComponent,
        componentProps: {
          viewData: {
            data: options,
            isMultipleSelectionEnabled: true,
            title: this.translate.instant('FILTERS'),
            isSearchEnabled: true
          },
          selectedFilter: this.selectedFilterData,
          from: PageName.MedicalInsightPageComponent
        },
        backdropDismiss: false
      });
      filterMenuModal.present();
      filterMenuModal.onDidDismiss().then(async (obj: any) => {
        if (obj && obj.data && obj.data.isDone) {
          let data = obj.data;
          if (_.isEmpty(data.selectedItems)) {
            this.clearFilters();
            this.updateItemsToDisplay();
            this.filtering = false;
          } else {
            this.selectedFilterData = [];
            this.filterNameBubble = [];
            this.filtering = true;
            data.selectedItems.forEach((selected) => {
              if (!this.selectedFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
                this.selectedFilterData.unshift(selected);
            });
            
            const groupedFilter = _.groupBy(this.selectedFilterData, filterData => filterData.categoryPath);
            this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));

            for(let key in groupedFilter){
              this.filterItemsToDisplay(key, groupedFilter[key].map(item => item['selectedFacet']));
            }
            
          }
        }
      });
  }

  removeSelectedSuggestion(suggestion, filter){
    let selectedSuggestions = this.filterNameBubble.find((bubble) => bubble == filter).suggestion;
    _.remove(this.selectedFilterData,(o)=>_.isEqual(o,suggestion));
    _.remove(this.filterNameBubble.find((bubble) => bubble == filter).suggestion,(o)=>_.isEqual(o,suggestion));
    if(_.isEmpty(selectedSuggestions)) {
      let index = this.filterNameBubble.findIndex((b) => b.categoryName == filter.categoryName);
      if(index>-1) this.filterNameBubble.splice(index,1);
      this.updateItemsToDisplay();
    }else{
      const groupedFilter = _.groupBy(this.selectedFilterData, filterData => filterData.categoryPath);
      for(let key in groupedFilter){
        this.filterItemsToDisplay(key, groupedFilter[key].map(item => item['selectedFacet']));
      }
    }
  }

  private clearFilters() {
    this.filtering = false;
    this.selectedFilterData = [];
    this.filterNameBubble = [];
  }

  private _updateFilterNameBubble(item) {
    if(item.categoryName == 'Assessed' || item.categoryName == 'UnAssessed') {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == this.translate.instant('ASSESSMENTS'));
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: this.translate.instant('ASSESSMENTS'), suggestion: [item]};
        this.filterNameBubble.push(selected);
      }
    } else {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == item.categoryName);
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: item.categoryName, suggestion: [item]};
        this.filterNameBubble.push(selected);
      }
    }
  }

  openMedicalInsightsFilter(){
    this.initFilter();
    // this.popover
    //   .create({ component: MultiSelectPopover, componentProps: { root: this.popoverData }, event })
    //   .then((data) => data.present());
  }

   private openMedicalInsightDetails(id) {
     let foundMedicalInsight: ContactMedicalInsight;
    //  if (this.isTeamTabActive) {
    //    foundMedicalInsight = this.medicalInsightService.teamMedicalInsight.find(a => a.ID == id);
    //  } else {
       foundMedicalInsight = this.myMedicalInsightItemsList.find(a => a.ID == id);
     //}
     if (foundMedicalInsight) {
       this.medicalInsightService.selectedMedicalInsight = foundMedicalInsight;
       this.filteredViewItemsList.forEach(order => {
         order.isSelected = false;
         if (order.id === foundMedicalInsight.ID) order.isSelected = true;
       });
       this.navService.setChildNavRightPaneView(true);
       this.navService.setChildNavRoot(ContactMedicalInsightComponent, PageName.ContactMedicalInsightComponent, PageName.MedicalInsightPageComponent, { from: 'MedicalInsightTool', medicalInsight: foundMedicalInsight });
     }
   }

  public getFormattedDate(value): string {
    return this.datePipe.transform(value, 'MMM dd yyyy', undefined, this.translate.currentLang);
  }

  public handleSearch() {
    this.updateItemsToDisplay();
  }

  updateEmptyMessage() {
    this.listCount = this.filteredItemsList.length;
    this.uiService.updateNothingSelectedScreenMessageFor(this.filteredItemsList.length);
  }

  private _onClosePage(): void {
    if(this.medicalInsightService.isInsightCreateInProgress){
      this._showDiscardConfirmationPopover().then(res=> {
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.navService.popWithPageTracking().then(() => {
            this.footerService.initButtons(this.footerService.previousFooterView);
        });
      }).catch(err=> {
        return;
      })
    }else{
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      this.navService.popWithPageTracking().then(() => {
        this.footerService.initButtons(this.footerService.previousFooterView);
      });;
    }
    
    
  }

  private _handleMedicalInsightCreate(){
    if(this.medicalInsightService.isInsightCreateInProgress){
      this._showDiscardConfirmationPopover().then(res=> {
        this._createMedicalInsight();
      }).catch(err=> {
        return;
      })
    }else{
      this._createMedicalInsight();
    }
  }

  private _createMedicalInsight(){
    if(!this.device.isOffline){
      let newMedicalInsight:ContactMedicalInsight = new ContactMedicalInsight({
        // "indskr_customer" : this.contactService.contactInformation.ID,
        // "indskr_customer_Formatted" : this.contactService.contactInformation.fullName,
        "indskr_datecreated" : new Date(),
        "_ownerid_value" : this.authService.user.systemUserID,
        "_ownerid_value_Formatted" : this.authService.user.displayName,
        "statecode" : 0,
        "statuscode" : 1,
        "statuscode_Formatted": "Active",
        "indskr_priority": false,
      });
      this.filteredViewItemsList.forEach(order=>{
        order.isSelected = false;
      });
      this.navService.setChildNavRightPaneView(true);
      this.navService.setChildNavRoot(ContactMedicalInsightComponent,PageName.ContactMedicalInsightComponent,PageName.MedicalInsightPageComponent,{from:'MedicalInsightTool',medicalInsight:newMedicalInsight});
    }
  }

  private async getTeamMedicalInsightData() {
    // if (this.medicalInsightService.teamMedicalInsight.length == 0) {
    //   this.uiService.displayLoader();
    //   this.medicalInsightService.getTeamMedicalInsightDataOnline().then(succ => {
    //     this.updateItemsToDisplay();
    //     this.uiService.dismissLoader();
    //   }).catch(err => {
    //     this.uiService.dismissLoader();
    //   });
    // }else{
    //   this.updateItemsToDisplay();
    // }
    
  }

  private _showDiscardConfirmationPopover():Promise<any>{
    let cancelText = this.translate.instant("CANCEL");
    if(this.translate.currentLang == 'it') {
      cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
    }
    return new Promise(async (resolve,reject)=> {   
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
      ).then (res => {
        if(res.role == "ok") {
          this.medicalInsightService.isInsightCreateInProgress = false;
          resolve(true);
        }else{
          reject(null);
        }
      });
    });
  }

  onSearchCancel() {}

  private async _handledNewMedicalInsightCreation(medicalInsight: ContactMedicalInsight) {
    this.medicalInsightService.saveNewMedicalInsight(medicalInsight).then(async (res) => {
      //Add to local
      let idx = this.myMedicalInsightItemsList.findIndex(insight => insight.ID == medicalInsight.ID);
      if (idx >= 0) {
        this.myMedicalInsightItemsList[idx] = medicalInsight;
      } else {
        this.myMedicalInsightItemsList.push(medicalInsight);
      }
      this.medicalInsightService.newMedicalInsight = null;
      this.medicalInsightService.selectedMedicalInsight = medicalInsight;
      this.updateItemsToDisplay();
    }).catch(err => {
      console.log(err);
      this.navService.setChildNavRightPaneView(true);
      this.navService.setChildNavRoot(ContactMedicalInsightComponent,PageName.ContactMedicalInsightComponent,PageName.MedicalInsightPageComponent,{from:'MedicalInsightTool',medicalInsight:medicalInsight});
    })
  }
  
}