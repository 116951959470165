<div class="timelineWrapper">
  <div *ngIf="!isTimelineFiltered">
    <div class="contactTimelineFilter" #scrollTop>
        <ion-select #contactTimelineSelect (ionChange)="onTimelineTypeChange(contactTimelineSelect.value)" [(ngModel)]="contactTimelineFilter" interface="popover" [interfaceOptions]="{ cssClass: 'contact-timeline-filter'}" class="timelineFilter" [selectedText]="getSelectedText(contactTimelineFilter)"
            [value]="contactTimelineFilter">
            <ion-select-option *ngFor="let option of filterOptions" [value]="option.value">
                {{option.displayText | translate:{ globalCustomerText: utilityService.globalCustomerText } }}
            </ion-select-option>
        </ion-select>
        <div>
          <ion-buttons class="contactTimelineButtons" slot="end">
            <ion-icon class="contactTimelineFilterIcon" src="assets/imgs/filter-blue.svg" (click)="openTimelineFilter($event)"> </ion-icon>
          </ion-buttons>
        </div>
    </div>
    <ion-list class="containerForData">
        <div class="no-data-message" *ngIf="!isDataAvailable() && contactService.isContactDetailsLoaded"> {{(isJourney? 'CONTACT_NO_JOURNEY' : 'NO_TIMELINE') | translate}}</div>
        <ion-item-group class="timeline-group" *ngFor="let group of groupedActivitiesToDisplay">
            <ion-item-divider sticky='true' [id]="group.key">
                <div class="dividerBorder"></div>
                <div class="monthGroupName">{{group.key}}</div>
            </ion-item-divider>
            <ion-item *ngFor="let activity of group.list" (click)="openActivityDetails(activity)">
                <ion-grid>
                    <ion-row>
                        <ion-col size="3">
                            <div class="ion-float-center" size="3">
                                <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center time-label">{{formattedDate(activity.scheduledStart)}}</h4>
                            </div>
                        </ion-col>
                        <ion-col size="8">
                            <div class="col-9 ion-float-left ion-margin-right" size="8">
                                <h3>
                                    <div *ngIf="activity.type == 'Appointment'">{{ 'MEETING' | translate}} - {{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'Email'">{{ 'MESSAGE' | translate}}{{getChannelType(activity)}} - {{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'PhoneCall'">{{ 'NEW_ACTIVITY_PHONECALL' | translate}} - {{getPhoneCallSubject(activity.subject)}}</div>
                                </h3>

                              <!-- To show customerCallPlanName on Timeline tab -->
                              <ng-container *ngIf="this.callPlanService.selectedCustomerCallPlanId === ''">                                
                                <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'Sample'">
                                    <div>{{ getMeetingOrAllocationLabel(activity) }} - {{activity.customerCallPlanName}}</div>
                                    <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                    <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                    <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                    <div *ngIf="activity.senton && !activity.readOn">
                                        {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                    </div>
                                    <div *ngIf="activity.readOn">
                                        {{ 'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.channelTypeName == 'WeCom'">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate :
                                    {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                  <div>
                                    {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.scheduledStart)} }}
                                  </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status == 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SHARED_BY_ME': 'CONTACT_SHARED_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                  <div *ngIf="activity.actualEnd">
                                    {{'CONTACT_SHARED_ON' | translate : {time : emailService.getFormattedTime(activity.actualEnd)} }}
                                  </div>
                              </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'Email' || activity.channelTypeName == undefined)">
                                    <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                    <div *ngIf="activity.senton && !activity.readOn">
                                        {{'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                    </div>
                                    <div *ngIf="activity.readOn">
                                        {{ 'CONTACT_READ_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                    </div>
                                    <div *ngIf="activity.email_linksclickedcount > 0">
                                        {{ 'ATTACHMENT_ACCESSED' | translate }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'PhoneCall'">
                                    <div>{{getPhoneCallPrimaryLabel(activity)}} - {{activity.customerCallPlanName}}</div>
                                    <div>{{activity.phonecallphonenumber}}</div>
                                </h4>
                              </ng-container>
                              <!-- To show customerCallPlanName on Timeline tab -->

                              <!-- To hide customerCallPlanName on Activities tab -->
                              <ng-container *ngIf="this.callPlanService.selectedCustomerCallPlanId !== ''">
                                <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'Sample'">
                                  <div>{{ getMeetingOrAllocationLabel(activity) }}</div>
                                  <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                  <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                    <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                    <div *ngIf="activity.senton && !activity.readOn">
                                        {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                    </div>
                                    <div *ngIf="activity.readOn">
                                        {{ 'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.channelTypeName == 'WeCom'">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate :
                                    {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div>
                                    {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.scheduledStart)} }}
                                  </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status == 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SHARED_BY_ME': 'CONTACT_SHARED_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div *ngIf="activity.actualEnd">
                                    {{'CONTACT_SHARED_ON' | translate : {time : emailService.getFormattedTime(activity.actualEnd)} }}
                                  </div>
                              </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'Email' || activity.channelTypeName == undefined)">
                                    <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                    <div *ngIf="activity.senton && !activity.readOn">
                                        {{'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                    </div>
                                    <div *ngIf="activity.readOn">
                                        {{ 'CONTACT_READ_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                    </div>
                                    <div *ngIf="activity.email_linksclickedcount > 0">
                                        {{ 'ATTACHMENT_ACCESSED' | translate }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'PhoneCall'">
                                    <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                                    <div>{{activity.phonecallphonenumber}}</div>
                                </h4>
                              </ng-container>
                              <!-- To hide customerCallPlanName on Activities tab -->

                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-item-group>
        <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-list>
  </div>
  <div *ngIf="isTimelineFiltered">
    <div class="contactTimelineFilter" #scrollTop>
      <ion-label>
        <div>{{ 'RESULTS_SMALL' | translate }} ({{filteredListLength}}) </div>
      </ion-label>
      <div class="searchbar-overlay">
        <ion-buttons class="searchbar-overlay-button contactTimelineButtons" slot="end">
          <div class="section-header-button-text" (click)="clearFilterResults()"> {{ 'CLEAR_FILTER' | translate }} </div>
          <ion-icon class="searchbar-overlay-button-filter-icon contactTimelineFilterIcon" src="assets/imgs/filter-blue.svg" (click)="openTimelineFilter($event)"> </ion-icon>
          <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge">{{ filterButtonBadgeCount }}</span>
        </ion-buttons>
      </div>
  </div>
  <ion-list class="containerForData">
      <div class="no-data-message" *ngIf="!isDataAvailable() && contactService.isContactDetailsLoaded"> {{(isJourney? 'CONTACT_NO_JOURNEY' : 'NO_TIMELINE') | translate}}</div>
      <ion-item-group class="timeline-group" *ngFor="let group of filteredGroupedActivitiesToDisplay">
          <ion-item-divider sticky='true' [id]="group.key">
              <div class="dividerBorder"></div>
              <div class="monthGroupName">{{group.key}}</div>
          </ion-item-divider>
          <ion-item *ngFor="let activity of group.list" (click)="openActivityDetails(activity)">
              <ion-grid>
                  <ion-row>
                      <ion-col size="3">
                          <div class="ion-float-center" size="3">
                              <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                              <h4 class="ion-text-center time-label">{{formattedDate(activity.scheduledStart)}}</h4>
                          </div>
                      </ion-col>
                      <ion-col size="8">
                          <div class="col-9 ion-float-left ion-margin-right" size="8">
                              <h3>
                                  <div *ngIf="activity.type == 'Appointment'">{{ 'MEETING' | translate}} - {{activity.subject}}</div>
                                  <div *ngIf="activity.type == 'Email'">{{ 'MESSAGE' | translate}}{{getChannelType(activity)}} - {{activity.subject}}</div>
                                  <div *ngIf="activity.type == 'PhoneCall'">{{ 'NEW_ACTIVITY_PHONECALL' | translate}} - {{getPhoneCallSubject(activity.subject)}}</div>
                              </h3>

                            <!-- To show customerCallPlanName on Timeline tab -->
                            <ng-container *ngIf="this.callPlanService.selectedCustomerCallPlanId === ''">                                                            
                              <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'Sample'">
                                  <div>{{ getMeetingOrAllocationLabel(activity) }} - {{activity.customerCallPlanName}}</div>
                                  <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                  <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                  <div *ngIf="activity.senton && !activity.readOn">
                                      {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                  </div>
                                  <div *ngIf="activity.readOn">
                                      {{ 'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status == 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SHARED_BY_ME': 'CONTACT_SHARED_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                <div *ngIf="activity.actualEnd">
                                  {{'CONTACT_SHARED_ON' | translate : {time : emailService.getFormattedTime(activity.actualEnd)} }}
                                </div>
                            </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'Email' || activity.channelTypeName == undefined)">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }} - {{activity.customerCallPlanName}}</div>
                                  <div *ngIf="activity.senton && !activity.readOn">
                                      {{'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                  </div>
                                  <div *ngIf="activity.readOn">
                                      {{ 'CONTACT_READ_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                  </div>
                                  <div *ngIf="activity.email_linksclickedcount > 0">
                                    {{ 'ATTACHMENT_ACCESSED' | translate }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'PhoneCall'">
                                  <div>{{getPhoneCallPrimaryLabel(activity)}} - {{activity.customerCallPlanName}}</div>
                                  <div>{{activity.phonecallphonenumber}}</div>
                              </h4>
                            </ng-container>
                            <!-- To show customerCallPlanName on Timeline tab -->

                            <!-- To hide customerCallPlanName on Activities tab -->
                            <ng-container *ngIf="this.callPlanService.selectedCustomerCallPlanId !== ''">
                              <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'Sample'">
                                <div>{{ getMeetingOrAllocationLabel(activity) }}</div>
                                <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div *ngIf="activity.senton && !activity.readOn">
                                      {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                  </div>
                                  <div *ngIf="activity.readOn">
                                      {{ 'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status == 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SHARED_BY_ME': 'CONTACT_SHARED_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                <div *ngIf="activity.actualEnd">
                                  {{'CONTACT_SHARED_ON' | translate : {time : emailService.getFormattedTime(activity.actualEnd)} }}
                                </div>
                            </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'Email' || activity.channelTypeName == undefined)">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div *ngIf="activity.senton && !activity.readOn">
                                      {{'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                  </div>
                                  <div *ngIf="activity.readOn">
                                      {{ 'CONTACT_READ_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                  </div>
                                  <div *ngIf="activity.email_linksclickedcount > 0">
                                    {{ 'ATTACHMENT_ACCESSED' | translate }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'PhoneCall'">
                                  <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                                  <div>{{activity.phonecallphonenumber}}</div>
                              </h4>
                            </ng-container>
                            <!-- To hide customerCallPlanName on Activities tab -->
                          </div>
                      </ion-col>
                  </ion-row>
              </ion-grid>
          </ion-item>
      </ion-item-group>
      <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-list>
  </div>
</div>