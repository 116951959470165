<ng-template #genee_button>
  <ion-button fill="clear" class="microphone-btn" (click)="openMyAccount()">
    <img tappable src="assets/imgs/ogeenee.svg" width="75" />
    <!--<ion-badge color="primary" class="ogeeneeBtnBadge rightPosition" *ngIf="(getCountUnreadNotifications()>0)">
      {{ getCountUnreadNotifications() }}</ion-badge>-->
  </ion-button>
</ng-template>
<ng-template #action_button let-button>
  <ion-button fill="clear" [disabled]="(!button.isactive || (device.isOffline && button.disableWhenOffline))"
    class="actionBtn footer-vert-class" [ngClass]="button.id" (click)="singleClickListener(button, $event)">
    <div class="actionItem" [ngClass]="{'rightPadding' : applyRightPaddingWhenApplicable }">
      <ion-badge color="primary" class="actionBadge" *ngIf="shouldShowBadge(button)">
        {{ getBadgeData(button) }}
      </ion-badge>
      <ion-icon *ngIf="button.id.indexOf('plantab-notification') > -1 && assistantService.hasAnyUnRedNotifications" 
        slot="end" class="notif-icon indegene-assets/imgs/notifications-active.svg" [src]="'assets/imgs/notification_bluedot.svg'" 
        [name]="'assets/imgs/notification_bluedot.svg'"></ion-icon>
      <img class="actionIcon" [ngClass]="footerIconStyle(button)" tappable [src]="button.iconurl" slot="icon-only" width="24" />
      <span [style]="footerTextWidth(button)" class="actionTitle ion-text-center"
        [ngClass]="button.id.indexOf('active') > 0 ? 'footer-button-text-active' : 'footer-button-text-inactive'">
        {{ getButtonValue(button) }}</span>
    </div>
  </ion-button>
</ng-template>

<!-- Only Genee Button: for Details -->
<div *ngIf="footerView === 'detailView' && footerService.hasVisibleFooterIcons() === false && shouldHideGeneeInDetails === false && !hideGenie">
  <ion-button fill="clear" class="ogeenee-btn-only" (click)="openMyAccount()">
    <img tappable src="assets/imgs/ogeenee.svg" width="90" />
    <!--<ion-badge color="primary" class="ogeeneeBtnBadge rightPosition" *ngIf="(getCountUnreadNotifications()>0)">
      {{getCountUnreadNotifications()}}</ion-badge>-->
  </ion-button>
</div>
<ng-container [ngSwitch]="footerView">

  <ng-container *ngSwitchCase="'masterView'">
<!-- Keeping this separate to prevent any conflicts : for Masters -->
<div *ngIf="footerView === 'masterView' && hasVisibleFooterIconsInMaster() === false && !hideGenie">
  <ion-button fill="clear" class="ogeenee-btn-only" (click)="openMyAccount()">
    <img tappable src="assets/imgs/ogeenee.svg" width="90" />
    <!--<ion-badge color="primary" class="ogeeneeBtnBadge rightPosition" *ngIf="(getCountUnreadNotifications()>0)">
      {{getCountUnreadNotifications()}}</ion-badge>-->
  </ion-button>
</div>

<!-- master view -->
<div class="fabViewContainer" *ngIf="footerView === 'masterView' && hasVisibleFooterIconsInMaster() === true">
  <ion-button fill="clear" class="ogeenee-btn" (click)="openMyAccount()" *ngIf="!hideGenie">
    <img tappable src="assets/imgs/ogeenee.svg" width="90" />
  </ion-button>
  <ion-fab [ngClass]="{'ion-fab-master' : footerView === 'masterView', 'ion-fab-position': applyAdditionalCssForIonFab()}" vertical="bottom" horizontal="left">
    <ion-row float="right" class="{{ !hideGenie  ? 'alignment-for-row' : 'alignment-for-row-presentation'}}">
      <ion-col #actionBtnCol class="ion-no-padding" [size]="getMasterScreenActionItemSize()"
        *ngFor="let button of (selectedView === 'activities' || selectedView === 'xperiences' || selectedView === 'plans' || selectedView === 'customercallplans' || selectedView === 'indFilterMenu' ? (agendaFooterIcons | filterBy: buttonsFilter.master) : [])"
        [ngClass]="button.id.indexOf('active') > 0 ? 'footer-button-bg-active' : 'footer-button-bg-inactive'">
        <ng-container [ngTemplateOutlet]="action_button" [ngTemplateOutletContext]="{$implicit:button}">
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-fab>
</div>
  </ng-container>

  <!-- Short Call Launcher Footer Tab -->
  <div class="fabViewContainer short-call-container" *ngSwitchCase="'shortCallLauncherView'">
    <ion-fab [ngClass]="{'ion-fab-master' : footerView === 'masterView', 'ion-fab-position': applyAdditionalCssForIonFab()}" vertical="bottom" horizontal="left">
      <ion-row float="right" class="alignment-for-row-presentation">
        <ion-col #actionBtnCol class="ion-no-padding" [size]="getMasterScreenActionItemSize()"
          *ngFor="let button of (selectedView === 'activities' || selectedView === 'plans' || selectedView === 'shortCallHome' || selectedView === 'notifications' ? (agendaFooterIcons | filterBy: buttonsFilter.master) : [])"
          [ngClass]="button.id.indexOf('active') > 0 ? 'footer-button-bg-active' : 'footer-button-bg-inactive'">
          <ng-container [ngTemplateOutlet]="action_button" [ngTemplateOutletContext]="{$implicit:button}">
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-fab>
  </div>


  <ng-container *ngSwitchCase="'detailView'">
  <!-- Only Genee Button: for Details -->
<div *ngIf="footerService.hasVisibleFooterIcons() === false && shouldHideGeneeInDetails === false">
  <ion-button fill="clear" class="ogeenee-btn-only" (click)="openMyAccount()">
    <img tappable src="assets/imgs/ogeenee.svg" width="90" />
    <!--<ion-badge color="primary" class="ogeeneeBtnBadge rightPosition" *ngIf="(getCountUnreadNotifications()>0)">
      {{getCountUnreadNotifications()}}</ion-badge>-->
  </ion-button>
</div>

<!-- Detail view -->
<div class="fabViewContainer" *ngIf="footerView === 'detailView' && footerService.hasVisibleFooterIcons() === true">
  <ion-button fill="clear" class="ogeenee-btn" (click)="openMyAccount()" [ngClass]="{'visibilityHidden': shouldHideGeneeInDetails === true}" *ngIf="!hideGenie">
    <img tappable src="assets/imgs/ogeenee.svg" width="90" />
  </ion-button>
  <ion-fab id="detailsFAB" [ngClass]="{'ion-fab-master': isDetailsViewInMobile() === true, 'ion-fab-details': isDetailsViewInMobile() === false, 'ion-fab-position': applyAdditionalCssForIonFab()}" vertical="bottom" horizontal="left">
    <ion-row float="right" [ngClass]="{'alignment-for-row': applyAlignmentForRow(), 'alignment-for-row-without-genie': hideGenie }">
      <ion-col class="ion-no-padding" *ngFor="let button of footerIcons | filterBy:buttonsFilter.details" [size]="footerService.getDetailsScreenActionItemSize(hideGenie)">
        <ng-container [ngTemplateOutlet]="action_button" [ngTemplateOutletContext]="{$implicit:button}">
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-fab>
</div>
  </ng-container>


<!-- Full Screen -->
<div class="fabViewContainer-fullscreen" *ngSwitchCase="'fullScreenView'" [ngClass]="{'meeting-sharing-indicator': websocket.meetingInProgress === true && uiService.activeView === 'Meeting'}">
  <ion-fab id="fullscreenFAB" vertical="bottom" horizontal="left"
    [ngClass]="{'nonroundedTopCorners ion-fab-presentation': this.selectedView === 'presentations', 'disableChildButtons': activityService.teamViewActive, 'ion-fab-position': applyAdditionalCssForIonFab()}">
    <ion-row float="right" [ngClass]="{'alignment-for-row-presentation': applyAlignmentForRow()}">
      <ion-col class="ion-no-padding" *ngFor="let button of footerIcons | filterBy:buttonsFilter.fullscreen"
        [size]="footerService.getFullScreenActionItemSize()">
        <ng-container [ngTemplateOutlet]="action_button" [ngTemplateOutletContext]="{$implicit:button}">
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-fab>
</div>

</ng-container>