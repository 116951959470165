<ion-row>
  <div
    class="conflict-alert-wrapper"
    [class.align-left]="alignment == 'left'"
    [class.align-half]="alignment == 'half'"
    [class.align-right]="alignment == 'right'"
  >
    <div class="conflict-alert">{{msg}}</div>
  </div>
</ion-row>
