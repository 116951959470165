import { DateTimeFormatsService } from './../../../services/date-time-formats/date-time-formats.service';
import { format } from 'date-fns';
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ComponentViewMode } from '../../../services/ui/ui.service';
import { Account } from '../../../classes/account/account.class';
import { AccountOfflineService } from "../../../services/account/account.offline.service";
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'account-list-item',
    templateUrl: 'account-list-item.html',
    styleUrls:['account-list-item.scss']
})
export class AccountListItemComponent {
    @Input('account') account: Account;
    @Input('accountListMode') listMode: ComponentViewMode;
    @Input('depth') depth: number = 0;
    //@Output() OnCheckboxChanged = new EventEmitter;
    @Output() OnAccountSelected = new EventEmitter;
    //@Output() OnChildCheckboxChanged = new EventEmitter;
    @Output() OnAccountListItemClicked = new EventEmitter;
    @Output() OnCheckAccountForTag = new EventEmitter;
    public compViewMode = ComponentViewMode;
    @Input('expanded')  expanded:boolean;
    @Input('expandedChildren')  expandedChildren:boolean;
    @Input('showChildren')  showChildren:boolean;
    @Input('showTertiary')  showTertiary:boolean;
    @Input('showCheckboxForTag')  showCheckboxForTag:boolean;
    @Input('sortBy') sortBy:{text: string, value: string, asc: boolean};
    @Input() accountIdfromIndDynamic: any;
    constructor(
        public accountService: AccountOfflineService,
        private dateTimeFormatsService : DateTimeFormatsService,
        private secondaryInfoService: SecInfoConfigDataService,
        private translate: TranslateService,
        private datePipe: DatePipe,
      ) {}

    onChecked(account){
      account.isSelected = true;
      this.OnAccountSelected.emit(account || this.account.isSelected)
    }

    checkAccountForTag(account,value){
      account.isSelectedForTag = value;
      this.OnCheckAccountForTag.emit({id:account.id,value: account.isSelectedForTag});
    }

    clicked(ev) {
        this.OnAccountListItemClicked.emit(this.account);
        if(this.accountService.isCaseManagementInvoked) {
            this.onChecked(this.account);
        }
    }

    childAccountListItemClicked(account) {
        this.OnAccountListItemClicked.emit(account);
    }

  getTertiaryInfo(account: Account) {
    let str: string = '';
    if (this.sortBy) {
      switch (this.sortBy.value) {
        case 'modifiedOn':
          str = account.modifiedOn ? this.getFormattedAndLocalisedDate(account.modifiedOn) : '';
          break;
        case 'interactionDate':
          str = account.interactionDate ? this.getFormattedAndLocalisedDate(account.interactionDate) + ' ' + account.interactionType : this.translate.instant('NO_INTERACTION');
          break;
        case 'createdOn':
          str = (account.interactionDate && account.interactionDate > 0) ? this.getDateDifference(account.interactionDate) : this.translate.instant('NO_INTERACTION');
          break;
      }
    } else {
      console.error('no sort option found');
    }
    return str;
  }

  getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  /**
   * to calculate no of days between today and @param interactionDate
   * @param interactionDate
   */
  getDateDifference(interactionDate: number): string {
    if (interactionDate) {
      let dateDiff = new Date().getTime() - interactionDate;
      const day = 1000 * 60 * 60 * 24;
      let numberOfDays = Math.ceil(dateDiff / day);
      return (numberOfDays > 1) ? 'No. of days - '+ numberOfDays.toString() : "Today";
    }
    return "";
  }

  public getSecondaryInfo(account:Account){
    return this.secondaryInfoService.getSecondaryInfoFormatedText(account, SecondaryInfoEntityName.Account);
  }

  onTagCheckboxValueChange(event) {
    this.OnCheckAccountForTag.emit(event);
  }

}
