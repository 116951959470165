import { Pipe, PipeTransform } from '@angular/core';
import {
  format,
  addMinutes,
  differenceInMinutes,
  isToday,
  isTomorrow
} from "date-fns";
/**
 * Generated class for the GroupActivityPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'groupActivity',
})
export class GroupActivityPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, groupByKey: string) {
    const events: any[] = [];
    const groupedElements: any = {};

    value.forEach((obj: any) => {
      let item = format(obj[groupByKey],'DD-MMM-YYYY');
      if (!(item in groupedElements)) {
        groupedElements[item] = [];
      }
      groupedElements[item].push(obj);
    });

    for (let prop in groupedElements) {
      if (groupedElements.hasOwnProperty(prop)) {
        //let meetingDay = isToday(prop) ? "Today" : isTomorrow(prop) ? "Tomorrow" : format(prop,'dddd MMM D');
        events.push({
          key: prop,
          list: groupedElements[prop]
        });
      }
    }

    return events;
  }
}
