import { FeatureActionsMap } from "@omni/classes/authentication/user.class"

export const CONTACT_FETCH_QUERIES = {
    searchAllContacts: `
        <fetch version="1.0" output-format="xml-platform" returntotalrecordcount='true' count="1000" mapping="logical" distinct="true">
            <entity name="contact">
                <attribute name="contactid" />
                <attribute name="firstname"/>
                <attribute name="lastname" />
                <attribute name="indskr_title" />
                <attribute name="statuscode" />
                <attribute name="ownerid" />
                <attribute name="fullname" />
                <attribute name="modifiedon" />
                <attribute name="indskr_lu_specialtyid" />
                {{businessUnitCondition}}
                <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="outer" alias="customerposition">
                </link-entity>

                {{LinkEntity}}

                <filter type="and">
                    {{FullNameCondition}}
                    {{SearchConditions}}
                    {{PositionCondition}}
                    {{GlobalSearchConfigFilter}}
                    <condition attribute="statecode" operator="eq" value="0" />
                </filter>

                <order attribute="firstname" descending="false" />
                {{secondaryinfoFetchXml}}
            </entity>
        </fetch>`,
    indskr_customerspecialty:
        `<link-entity name="indskr_customerspecialty" from="indskr_customerid" to="contactid" link-type="outer" alias="indskr_customerspecialty">
            <attribute name="indskr_specialtyidname"/>
            <attribute name="indskr_specialtyid" />
            <attribute name="indskr_isprimary" />
        </link-entity>`,
    indskr_indskr_customeraddress_v2:
        `<link-entity name="indskr_indskr_customeraddress_v2" from="indskr_customer" to="contactid" link-type="outer" alias="indskr_indskr_customeraddress_v2">
            <attribute name="indskr_isprimary" alias="indskr_primary"/>
            <link-entity name="indskr_address" from="indskr_addressid" to="indskr_address" link-type="outer" alias="indskr_address">
                <attribute name="indskr_city_lu"/>
                <link-entity name="indskr_lu_country" from="indskr_lu_countryid" to="indskr_country_lu" link-type="outer" alias="indskr_lu_country">
                    <attribute name="indskr_countryname" alias="countryName" />
                    <attribute name="indskr_countrycodealpha3" alias="countryCode" />
                </link-entity>
                <link-entity name="indskr_lu_state" from="indskr_lu_stateid" to="indskr_state_lu" link-type="outer" alias="indskr_lu_state">
                    <attribute name="indskr_state" alias="stateName" />
                </link-entity>
            </link-entity>
        </link-entity>`,
    indskr_lu_language:
        `<link-entity name="indskr_lu_language" from="indskr_lu_languageid" to="indskr_lu_languageid" link-type="outer" alias="indskr_lu_language">
            <attribute name="indskr_languagename"/>
        </link-entity>`,
    indskr_lu_professional_designation:
        `<link-entity name="indskr_lu_professional_designation" from="indskr_lu_professional_designationid" to="indskr_lu_professional_designation_contact" link-type="outer" alias="indskr_lu_professional_designation">
            <attribute name="indskr_professionaldesignation"/>
        </link-entity>`,
    indskr_productrating:
        `<link-entity name="indskr_productrating" from="indskr_contact" to="contactid" link-type="outer" alias="indskr_productrating" >
            <filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
            </filter>
        </link-entity>`,
    indskr_accountcontactaffiliation:
        `<link-entity name="indskr_accountcontactaffiliation" from="indskr_contactid" to="contactid" link-type="outer" alias="indskr_accountcontactaffiliation">
            <attribute name="indskr_isprimaryaccount" alias="aca_isprimary" />
            <link-entity name="account" from="accountid" to="indskr_accountid" link-type="outer">
                <attribute name="name" alias="aca_name" />
            </link-entity>
        </link-entity>`,
    indskr_indskr_scientificplan_contact:
        `<link-entity name="indskr_indskr_scientificplan_contact" from="contactid" to="contactid" link-type="outer" alias="indskr_indskr_scientificplan_contact">
            <link-entity name="indskr_scientificplan" from="indskr_scientificplanid" to="indskr_scientificplanid" >
            </link-entity>
        </link-entity>`,
    indskr_consent:
        `<link-entity name="indskr_consent" from="indskr_contact" to="contactid" link-type="outer" alias="indskr_consent">
        </link-entity>`,
    indskr_customerposition:
        `<link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" alias="indskr_customerposition">
        </link-entity>`,
    indskr_contactrelationship:
        `<link-entity name="indskr_contactrelationship" from="indskr_contactid" to="contactid" alias="indskr_contactrelationship">
        </link-entity>`,
    indskr_customercallplan:
        `<link-entity name="indskr_customercallplan" from="indskr_customerid" to="contactid" alias="indskr_customercallplan">
        </link-entity>`,
    indskr_email_address:
        `<link-entity name="indskr_email_address" from="indskr_customer" to="contactid" alias="indskr_email_address">
        </link-entity>`,
    indskr_customersampleproduct:
        `<link-entity name="indskr_customersampleproduct" from="indskr_customerid" to="contactid" alias="indskr_customersampleproduct">
        </link-entity>`,
    searchAllAddresses:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="indskr_address">
                <attribute name="indskr_addressid" />
                <attribute name="indskr_name" />
                <attribute name="indskr_timezone" />
                <attribute name="indskr_line3" />
                <attribute name="indskr_line2" />
                <attribute name="indskr_line1" />
                <attribute name="indskr_state_lu" />
                <attribute name="indskr_postalcode_lu" />
                <attribute name="indskr_postofficebox" />
                <attribute name="indskr_isverified" />
                <attribute name="indskr_country_lu" />
                <attribute name="indskr_city_lu" />
                <attribute name="indskr_addresstypecode" />
                <attribute name="indskr_composite" />
                <order attribute="indskr_name" descending="false" />
                <filter type="and">
                    {{CompositeAddressCondition}}
                </filter>
            </entity>
        </fetch>`,
    searchAllAddressesForOneKey:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="indskr_address">
              <attribute name="indskr_addressid" />
              <attribute name="omnione_account" />
              <attribute name="indskr_composite" />
              <attribute name="indskr_name" />
              <attribute name="indskr_compositecitystate" />
              <attribute name="indskr_city_lu" />
              <attribute name="indskr_country_lu" />
              <attribute name="indskr_state_lu" />
              <attribute name="indskr_postalcode_lu" />
              <attribute name="indskr_postofficebox" />
              <attribute name="indskr_district" />
              <attribute name="indskr_line3" />
              <attribute name="indskr_line2" />
              <attribute name="indskr_line1" />
              <order attribute="indskr_name" descending="false" />
              <filter type="and">
                  <condition attribute="indskr_composite" operator="like" value="%{{query}}%" />
              </filter>
              {{linked-entities}}
            </entity>
        </fetch>`,
    customerLinkedEntitiesAccountCR:
        `<link-entity name="indskr_indskr_customeraddress_v2" from="indskr_address" to="indskr_addressid" link-type="inner" alias="ao">
            <attribute name="indskr_shipto" />
            <attribute name="indskr_isprimary" />
            <attribute name="indskr_mdm" />
            <filter type="and">
                  <condition attribute="indskr_mdm" operator="eq" value="548910000" />
                  <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="account" from="accountid" to="indskr_customer" link-type="inner" alias="ap">
                <attribute name="accountid" />
                <filter type="and">
                  <condition attribute="indskr_mdmid" operator="eq" value="{mdmid}" />
                </filter>
            </link-entity>
        </link-entity>`,
    customerLinkedEntitiesContactCR:
        `<link-entity name="indskr_indskr_customeraddress_v2" from="indskr_address" to="indskr_addressid" link-type="inner" alias="ao">
            <attribute name="indskr_shipto" />
            <attribute name="indskr_isprimary" />
            <attribute name="indskr_mdm" />
            <filter type="and">
                  <condition attribute="indskr_mdm" operator="eq" value="548910000" />
                  <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="contact" from="contactid" to="indskr_customer" link-type="inner" alias="ap">
                <filter type="and">
                  <condition attribute="indskr_mdmid" operator="eq" value="{mdmid}" />
                </filter>
            </link-entity>
        </link-entity>`,
    searchAccountAccountAffiliations:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_accountaccountaffiliation">
              <all-attributes/>
              <filter type="or">
              <filter type="and">
                <condition attribute="indskr_affiliatedfromaccountidname" operator="like" value="%{{query}}%" />
                <condition attribute="indskr_affiliatedtoaccountid" operator="eq" value="{accountid}" />
              </filter>
              <filter type="and">
                <condition attribute="indskr_affiliatedtoaccountidname" operator="like" value="%{{query}}%" />
                <condition attribute="indskr_affiliatedfromaccountid" operator="eq" value="{accountid}" />
              </filter>
              <filter type="and">
                <condition attribute="omnione_relationshipname" operator="like" value="%{{query}}%" />
                <condition attribute="indskr_affiliatedtoaccountid" operator="eq" value="{accountid}" />
              </filter>
              <filter type="and">
                <condition attribute="omnione_relationshipname" operator="like" value="%{{query}}%" />
                <condition attribute="indskr_affiliatedfromaccountid" operator="eq" value="{accountid}" />
              </filter>
              </filter>
          </entity>
        </fetch>`,
    searchAccountContactAffiliations:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_accountcontactaffiliation">
            <all-attributes/>
            <filter type="and">
              <condition attribute="indskr_contactid" operator="eq" value="{contactid}" />
              <condition attribute="indskr_affiliationsourcetype" operator="eq" value="548910001" />
              <condition attribute="indskr_mdm" operator="eq" value="548910000" />
              <condition attribute="statecode" operator="eq" value="0" />
              <condition attribute="indskr_accountidname" operator="like" value="%{{query}}%" />
            </filter>
          </entity>
        </fetch>`,
    indskr_professionaldesignation:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="indskr_lu_professional_designation">
                <attribute name="indskr_professionaldesignation" />
                <attribute name="createdon" />
                <attribute name="indskr_lu_professional_designationid" />
                <order attribute="indskr_professionaldesignation" descending="false" />
            </entity>
            </fetch>`,
  fetchContactPraviteTags:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                <entity name="indskr_usertag">
                  <attribute name="indskr_usertagid" />
                  <attribute name="indskr_name" />
                  <attribute name="createdon" />
                  <attribute name="statecode" />
                  <attribute name="ownerid" />
                  <attribute name="indskr_externalid" />
                  <attribute name= "indskr_taggedfor"/>
                  <attribute name="indskr_visibility" />
                  <attribute name="indskr_filter" />
                  <order attribute="indskr_name" descending="false" />
                  <filter type="and">
                    <condition attribute="ownerid" operator="eq-userid" />
                    <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
                    <condition attribute="statecode" operator="eq" value="0" />
                </filter>
                <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                <attribute name="contactid" alias="contactId" />
              </link-entity>   
                </entity>
              </fetch>`,

  fetchContactPublicTags:`
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
              <entity name="indskr_usertag">
                <attribute name="indskr_usertagid" />
                <attribute name="indskr_name" />
                <attribute name="indskr_externalid" />
                <attribute name="modifiedon" />
                <attribute name="statecode" />
                <attribute name= "indskr_taggedfor"/>
                <attribute name="indskr_visibility" />
                <attribute name="indskr_filter" />
                <filter type="and">
                  <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
                  <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
                  <condition attribute="statecode" operator="eq" value="0" />
              </filter>
            <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
                <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
                  <filter type="and">
                    <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
                  </filter>
                </link-entity>
              </link-entity>
                <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                  <attribute name="contactid" alias="contactId" />
                </link-entity>         
              </entity>
            </fetch>`,
   fetchAssignedContactTagsForBU: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="indskr_usertag">
            <attribute name="indskr_usertagid" />
            <attribute name="indskr_name" />
            <attribute name="createdon" />
            <attribute name= "indskr_taggedfor"/>
            <attribute name="indskr_visibility" />
            <attribute name="indskr_filter" />
            <order attribute="indskr_name" descending="false" />
            <filter type="and">
              <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
              <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true" link-type="outer">
            <filter type="and">
            <condition attribute="indskr_bulevelconfigurationid" operator="in">
            <value>{buId}</value>
            </condition>
            </filter>
            </link-entity>
            <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
            <attribute name="contactid" alias="contactId" />
            <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" alias="am">
             <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
               <filter type="and">
                 <condition attribute="positionid" operator="in">
                 {positionIDs}
                 </condition>
               </filter>
             </link-entity>
           </link-entity>
        </link-entity>
          </entity>
        </fetch> `,
        fetchPositionTag:`
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_externalid" />
    <attribute name="modifiedon" />
    <attribute name="statecode" />
    <attribute name="indskr_taggedfor" />
    <attribute name="indskr_visibility" />
    <attribute name="indskr_filter" />
    <filter type="and">
      <condition attribute="indskr_visibility" operator="eq" value="600000000" />
      <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
      <filter type="and">
        <condition attribute="positionid" operator="in">
        {positionIDs}
        </condition>
      </filter>
    </link-entity>
    <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="contactid" alias="contactId" />
  </link-entity> 
  </entity>
</fetch>`,
        updatedAssignedContactTags: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
           <entity name="indskr_usertag">
           <attribute name="indskr_usertagid" />
           <attribute name="indskr_name" />
           <attribute name="createdon" />
           <attribute name= "indskr_taggedfor"/>
           <attribute name="indskr_visibility" />
           <attribute name="indskr_filter" />
           <order attribute="indskr_name" descending="false" />
           <filter type="and">
             <condition attribute="indskr_visibility" operator="eq" value="600000000" />
             <condition attribute="indskr_source" operator="eq" value="600000000" />
             <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
             <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
           </filter>
           <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true" link-type="outer">
            <filter type="and">
            <condition attribute="indskr_bulevelconfigurationid" operator="in">
            <value>{buId}</value>
            </condition>
            </filter>
            </link-entity>
            <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
            <attribute name="contactid" alias="contactId" />
            <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" alias="am">
             <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
               <filter type="and">
                 <condition attribute="positionid" operator="in">
                 {positionIDs}
                 </condition>
               </filter>
             </link-entity>
           </link-entity>
        </link-entity>
          </entity>
        </fetch> `,
  updatedContactPrivateTags:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
              <entity name="indskr_usertag">
                <attribute name="indskr_usertagid" />
                <attribute name="indskr_name" />
                <attribute name="indskr_externalid" />
                <attribute name="modifiedon" />
                <attribute name="statecode" />
                <attribute name= "indskr_taggedfor"/>
                <attribute name="indskr_visibility" />
                <attribute name="indskr_filter" />
                <filter type="and">
                  <condition attribute="ownerid" operator="eq-userid" />
                  <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
                  <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
              </filter>
                <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                  <attribute name="contactid" alias="contactId" />
                </link-entity>         
              </entity>
            </fetch>`,

 updatedContactPublicTags:`
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                        <entity name="indskr_usertag">
                          <attribute name="indskr_usertagid" />
                          <attribute name="indskr_name" />
                          <attribute name="indskr_externalid" />
                          <attribute name="modifiedon" />
                          <attribute name="statecode" />
                          <attribute name= "indskr_taggedfor"/>
                          <attribute name="indskr_visibility" />
                          <attribute name="indskr_filter" />
                          <filter type="and">
                            <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
                            <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
                            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
                         </filter>
                      <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
                          <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
                            <filter type="and">
                              <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
                            </filter>
                          </link-entity>
                        </link-entity>
                          <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                            <attribute name="contactid" alias="contactId"/>
                          </link-entity>         
                        </entity>
                      </fetch>`,
 updatedContactPosTags:`
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                        <entity name="indskr_usertag">
                          <attribute name="indskr_usertagid" />
                          <attribute name="indskr_name" />
                          <attribute name="indskr_externalid" />
                          <attribute name="modifiedon" />
                          <attribute name="statecode" />
                          <attribute name= "indskr_taggedfor"/>
                          <attribute name="indskr_visibility" />
                          <attribute name="indskr_filter" />
                          <filter type="and">
                            <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
                            <condition attribute="indskr_taggedfor" operator="eq" value="CONTACT" />
                            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
                         </filter>
                         <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
                         <filter type="and">
                           <condition attribute="positionid" operator="in">
                           {positionIDs}
                           </condition>
                         </filter>
                         </link-entity> 
                       <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                       <attribute name="contactid" alias="contactId" />
                       </link-entity>        
                        </entity>
                      </fetch>`,

  deletedUserTagsFetchXml:
              `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
              <entity name="indskr_trackchange">
                <attribute name="indskr_entityid" alias="indskr_usertagid" />
                <filter>
                  <condition attribute="indskr_entityname" operator="eq" value="indskr_usertag" />
                  <condition attribute="createdon" operator="ge" value="{lastUpdatedTime}" />
                  <condition attribute="indskr_userid" operator="eq" value="{userId}" />
                  <condition attribute="indskr_action" operator="eq"  value=" 548910001" />
                </filter>
              </entity>
            </fetch>`,
  teamKOLStatuses: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_kolstatus">
        <attribute name="indskr_name" />
        <attribute name="ownerid" />
        <attribute name="statecode" />
        <attribute name="indskr_customer" />
        <attribute name="indskr_therapeuticarea" />
        <attribute name="indskr_specialty" />
        <attribute name="indskr_diseasearea" />
        <attribute name="indskr_koltype" />
        <attribute name="statuscode" />
        <attribute name="createdon" />
        <attribute name="modifiedon" />
        <attribute name="indskr_comments" />
        <attribute name="indskr_approvalstatus" />
        <attribute name="indskr_kolstatusid" />
        <filter type="and">
          <condition attribute="statuscode" operator="eq" value="600000000" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_approvalactivity" from="indskr_kolstatus" to="indskr_kolstatusid" link-type="inner" alias="aa">
          <attribute name="indskr_approvalactivityid" />
          <attribute name="statuscode" />
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="548910000" />
          </filter>
          <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner" alias="ba">
                <filter type="and">
                  <condition attribute="ownerid" operator="eq" value="{userId}" />
                </filter>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
  `,
  myKOLStatuses: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_kolstatus">
        <attribute name="indskr_name" />
        <attribute name="ownerid" />
        <attribute name="statecode" />
        <attribute name="indskr_customer" />
        <attribute name="indskr_therapeuticarea" />
        <attribute name="indskr_specialty" />
        <attribute name="indskr_diseasearea" />
        <attribute name="indskr_koltype" />
        <attribute name="statuscode" />
        <attribute name="createdon" />
        <attribute name="modifiedon" />
        <attribute name="indskr_comments" />
        <attribute name="indskr_approvalstatus" />
        <attribute name="indskr_kolstatusid" />
        <filter type="and">
          <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="ownerid" operator="eq" value="{userId}" />
        </filter>
      </entity>
    </fetch>
  `,
  myMedicalInsights: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customerinteractioninsights">
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_customerinteractioninsightsid"/>
        <attribute name="indskr_name"/>
        <attribute name="createdon"/>
        <attribute name="ownerid"/>
        <attribute name="indskr_datecreated"/>
        <attribute name="indskr_insightcategory"/>
        <attribute name="indskr_specialty"/>
        <attribute name="indskr_productcatalog"/>
        <attribute name="indskr_priority"/>
        <attribute name="indskr_summary"/>
        <attribute name="indskr_description"/>
        <attribute name="indskr_customer"/>
        <attribute name="indskr_feedback"/>
        <attribute name="indskr_interaction"/>
        <attribute name="indskr_impact"/>
        <attribute name="indskr_othersource"/>
        <filter type="and">
          <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="ownerid" operator="eq" value="{userId}" />
        </filter>
      </entity>
    </fetch>
  `,
  childMedicalInsights:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">  
      <entity name="indskr_customerinteractioninsights">    
        <attribute name="statecode"/>    
        <attribute name="statuscode"/>    
        <attribute name="indskr_customerinteractioninsightsid"/>    
        <attribute name="indskr_name"/>    
        <attribute name="createdon"/>    
        <attribute name="ownerid"/>    
        <attribute name="indskr_datecreated"/>    
        <attribute name="indskr_insightcategory"/>    
        <attribute name="indskr_specialty"/>    
        <attribute name="indskr_productcatalog"/>    
        <attribute name="indskr_priority"/>    
        <attribute name="indskr_summary"/>    
        <attribute name="indskr_description"/>    
        <attribute name="indskr_customer"/>    
        <attribute name="indskr_feedback"/>    
        <attribute name="indskr_interaction"/>    
        <attribute name="indskr_impact"/>    
        <attribute name="indskr_othersource"/>    
        <filter type="and">      
          <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
          <condition attribute="statecode" operator="eq" value="0" />         
        </filter>    
        <link-entity name="indskr_userposition" from="indskr_userid" to="owninguser" link-type="inner" alias="ak">      
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="al">        
            <filter type="or">          
              <condition attribute="parentpositionid" operator="in">
                {parentPosition}
              </condition>        
            </filter>      
          </link-entity>    
        </link-entity>  
      </entity>
    </fetch>
  `,
  fetchDiseaseAreas: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_diseasearea">
        <attribute name="statecode" />
        <attribute name="statuscode" />
        <attribute name="indskr_diseaseareaid" />
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <filter type="and">
          <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
        </filter>
        <link-entity name="indskr_indskr_diseasearea_indskr_positiongroup" to="indskr_diseaseareaid" from="indskr_diseaseareaid" link-type="outer">
          <attribute name="indskr_positiongroupsid"/>
      	</link-entity>
        <order attribute="indskr_name" descending="false" />
      </entity>
    </fetch>
  `,
  approveDCRRequests: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_contactcr">
        <attribute name="indskr_lastname" />
        <attribute name="ownerid" />
        <attribute name="statecode" />
        <attribute name="statuscode" />
        <attribute name="createdon" />
        <attribute name="modifiedon" />
        <attribute name="indskr_approvalstatus" />
        <attribute name="indskr_contactcrid" />
        <attribute name="indskr_attachment1" />
        <attribute name="indskr_attachment2" />
        <filter type="and">
          <condition attribute="statuscode" operator="eq" value="548910009" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_approvalactivity" from="indskr_contactcr" to="indskr_contactcrid" link-type="inner" alias="aa">
          <attribute name="indskr_approvalactivityid" />
          <attribute name="statuscode" />
          <filter type="and">
            {lastUpdatedTimeCondition}
            <condition attribute="statuscode" operator="eq" value="548910000" />
          </filter>
          <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner" alias="ba">
                <filter type="and">
                  <condition attribute="ownerid" operator="eq" value="{userId}" />
                </filter>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
  `,
  fetchSubSpecialties: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_lu_specialty">
      <attribute name="statecode"/>
      <attribute name="indskr_specialty"/>
      <attribute name="indskr_lu_specialtyid"/>
      <order attribute="indskr_specialty" descending="false"/>
      <link-entity name="indskr_indskr_subspecialties_indskr_lu_special" intersect="true" visible="false" to="indskr_lu_specialtyid" from="indskr_lu_specialtyid">
        <link-entity name="indskr_subspecialties" from="indskr_subspecialtiesid" to="indskr_subspecialtiesid" alias="a">
          <attribute name="statecode" />
          <attribute name="statuscode" />
          <attribute name="indskr_subspecialtiesid" />
          <attribute name="indskr_name" />
          <filter type="and">
            <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
          </filter>
          <order attribute="indskr_name" descending="false" />
        </link-entity>
      </link-entity>
    </entity>
  </fetch>
  `,
}

export const FETCH_CONTACTS_LINK_ENTITES = [
  {
    entityName: "indskr_consent",
    fetchXML: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="contact">
                <attribute name="contactid"/>
                <link-entity name="indskr_consent" from="indskr_contact" to="contactid" link-type="inner" >
                  <attribute name="statuscode" />
                  <attribute name="indskr_consenttermsname" />
                  <filter type="and" >
                    <condition attribute="indskr_consentterms" operator="not-null" />
                    <condition attribute="statuscode" operator="eq" value="1" />
                  </filter>
                </link-entity>
            </entity>
        </fetch>`,
        isFetch: true,
},
    {
        entityName: "indskr_contactrelationships",
        fetchXML:
            `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                <entity name="indskr_contactrelationship">
                    <attribute name="indskr_contactrelationshipid" />
                    <attribute name="indskr_name" />
                    <attribute name="createdon" />
                    <attribute name="statecode" />
                    <attribute name="statuscode" />
                    <attribute name="indskr_relationship" />
                    <attribute name="omnione_relationship" />
                    <attribute name="indskr_relatedcontactname" />
                    <attribute name="indskr_relatedcontactid" />
                    <attribute name="indskr_contactid" />

                    <order attribute="indskr_name" descending="false" />
                    <filter type="and">
                        <condition attribute="statecode" operator="eq" value="0" />
                    </filter>

                    {DeltaSyncFilter}
                    <link-entity name="contact" from="contactid" to="indskr_relatedcontactid" link-type="inner">
                        <filter type="and">
                            <condition attribute="statuscode" operator="eq" value="1" />
                        </filter>
                    </link-entity>
                    <link-entity name="contact" from="contactid" to="indskr_contactid" link-type="inner">

                        <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="outer">
                            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
                                <filter type="and">
                                    <condition attribute="positionid" operator="in">
                                        {PositionIds}
                                    </condition>
                                </filter>
                            </link-entity>
                        </link-entity>
                        <filter type="and">
                            <condition attribute="statuscode" operator="eq" value="1" />
                        </filter>
                    </link-entity>
                </entity>
            </fetch>`,
        isFetch: false,
    },
    {
        entityName: "indskr_productratings",
        fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" >
                <entity name="indskr_productrating">
                    <attribute name="indskr_productratingid" />
                    <attribute name="indskr_product" />
                    <attribute name="indskr_contact" />
                    <attribute name="statecode" />
                    <attribute name="statuscode" />
                    <attribute name="indskr_marketpotential_new" />
                    <order attribute="indskr_product" descending="false" />
                    <link-entity name="contact" from="contactid" to="indskr_contact">
                        <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid">
                            <link-entity name="position" from="positionid" to="indskr_positionid">
                                <filter type="and">
                                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                                    <condition attribute="positionid" operator="in">
                                        {PositionIds}
                                    </condition>
                                </filter>
                            </link-entity>
                        </link-entity>
                    </link-entity>
                    <link-entity name="indskr_productintimacy" from="indskr_productintimacyid" to="indskr_intimacy" link-type="outer">
                        <attribute name="indskr_intimacy" />
                        <attribute name="indskr_productintimacyid" />
                    </link-entity>
                    <link-entity name="indskr_customersegmentation" from="indskr_customersegmentationid" to="indskr_segmentation" visible="false" link-type="outer" >
                        <attribute name="indskr_name" />
                        <attribute name="indskr_customersegmentationid" />
                    </link-entity>
                    <link-entity name="product" from="productid" to="indskr_product" link-type="outer" alias="ah" >
                        <attribute name="name" alias="productname"/>
                    </link-entity>
                    <filter type="and">
                        <condition attribute="statecode" operator="eq" value="0" />
                    </filter>
                </entity>
            </fetch>`,
        isFetch: false,
    },
    {
        entityName: "indskr_customercallplans",
        fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                <entity name="indskr_customercallplan">
                    <attribute name="indskr_customercallplanid"/>
                    <attribute name="indskr_cycleplanid" />
                    <attribute name="indskr_customerid" />
                    <attribute name="indskr_callplanaccounts"/>
                    <attribute name="indskr_name"/>
                    <attribute name="createdon"/>
                    <attribute name="indskr_startdate"/>
                    <attribute name="indskr_enddate"/>
                    <attribute name="indskr_hocalls"/>
                    <attribute name="indskr_totalcompletedmeetings"/>
                    <attribute name="indskr_meetingcompletionpercent"/>
                    <attribute name="indskr_hoemails"/>
                    <attribute name="indskr_totalcompletedemails"/>
                    <attribute name="indskr_emailcompletionpercent"/>
                    <attribute name="indskr_state"/>
                    <attribute name="statecode"/>
                    <attribute name="statuscode" />
                    <attribute name="indskr_productid"/>
                    <attribute name="indskr_segment_v2" />
                    <attribute name="indskr_positionid" />
                    <attribute name="indskr_suggestedcalls" />
                    <attribute name="indskr_suggestedemails" />
                    <attribute name="indskr_isrepcallplan" />
                    <attribute name="indskr_totalpositionmeetinggoals" />
                    <attribute name="indskr_totalpositionemailgoals" />
                    <attribute name="indskr_actualcalls" />
                    <attribute name="indskr_actualemails" />
                    <attribute name="indskr_timeremaining" />
                    <filter type="and">
                        <condition attribute="indskr_isrepcallplan" operator="eq" value="true" />
                    </filter>
                    <link-entity name="position" from="positionid" to="indskr_positionid" alias="ar">
                        <attribute name="name" alias="positionname" />
                        <attribute name="parentpositionid" alias="parentposid" />
                        <link-entity name="position" from="positionid" to="parentpositionid" alias="az" link-type="outer">
                            <attribute name="name" alias="parentpositionname" />
                        </link-entity>
                    </link-entity>
                    <link-entity name="indskr_cycleplan" from="indskr_cycleplanid" to="indskr_cycleplanid" alias="scp">
                        <filter type="and">
                            <condition attribute="indskr_segmentationtype" operator="ne" value="0" />
                        </filter>
                    </link-entity>
                    <link-entity name="product" from="productid" to="indskr_productid" alias="ag">
                        <attribute name="name" alias="productName" />
                    </link-entity>
                    <link-entity name="contact" from="contactid" to="indskr_customerid" alias="ap">
                        <attribute name="contactid" alias="contactid" />
                        <attribute name="firstname" alias="firstName" />
                        <attribute name="lastname" alias="lastName" />
                        <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid">
                            <link-entity name="position" from="positionid" to="indskr_positionid">
                                <filter type="and">
                                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                                    <condition attribute="positionid" operator="in">
                                        {PositionIds}
                                    </condition>
                                </filter>
                            </link-entity>
                        </link-entity>
                    </link-entity>
                </entity>
            </fetch>`,
        isFetch: false,
    },
    {
        entityName: "indskr_email_address",
        fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                <entity name="contact">
                    <attribute name="contactid"/>
                    <attribute name="statecode" />
                    <attribute name="statuscode" />

                    {PositionFilterPH}
                    {DeltaSyncFilter}

                    <link-entity name="indskr_email_address" from="indskr_customer" to="contactid" link-type="inner" alias="indskr_email_address">
                        <attribute name="indskr_isprimary"/>
                        <attribute name="indskr_emailaddress"/>
                        <attribute name="indskr_type"/>
                        <attribute name="indskr_customer"/>
                        <attribute name="indskr_email_addressid"/>
                    </link-entity>
                </entity>
            </fetch>`,
        entityFetchXML:
            `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                <entity name="indskr_email_address">
                <order attribute="indskr_isprimary" descending="true" />
                <order attribute="indskr_emailaddress" descending="false" />
                <attribute name="indskr_isprimary" />
                <attribute name="indskr_emailaddress" />
                <attribute name="indskr_type" />
                <attribute name="indskr_email_addressid" />
                <filter>
                    <condition attribute="indskr_customer" operator="eq" value="{0}" />
                </filter>
                </entity>
            </fetch>`,
        isFetch: true,
    },
    {
        entityName: "indskr_indskr_customeraddress_v2",
        fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                <entity name="contact">
                    <attribute name="contactid"/>

                    {DeltaSyncFilter}
                    {PositionFilterPH}
                    <link-entity name="indskr_indskr_customeraddress_v2" from="indskr_customer" to="contactid" link-type="inner" alias="indskr_indskr_customeraddress_v2">
                        <attribute name="indskr_name"/>
                        <attribute name="indskr_address"/>
                        <attribute name="indskr_indskr_customeraddress_v2id"/>
                        <attribute name="indskr_isprimary" />
                        <attribute name="statecode" />
                        <attribute name="statuscode" />
                        <link-entity name='indskr_address' from='indskr_addressid' to='indskr_address' link-type="outer" alias="indskr_address">
                            <attribute name="indskr_composite"/>
                            <attribute name="indskr_line1"/>
                            <attribute name="indskr_state_lu"/>
                            <attribute name="indskr_postalcode_lu"/>
                            <attribute name="indskr_country_lu"/>
                            <attribute name="indskr_city_lu"/>
                            <attribute name="indskr_longitude" />
                            <attribute name="indskr_latitude" />
                        </link-entity>
                    </link-entity>
                </entity>
            </fetch>`,
        entityFetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical">
                <entity name="indskr_indskr_customeraddress_v2">
                    <attribute name="indskr_name" />
                    <order attribute="indskr_name" descending="false" />
                    <attribute name="indskr_address" />
                    <attribute name="indskr_isprimary" />
                    <link-entity alias="alias_placeholder" name="indskr_address" from="indskr_addressid" to="indskr_address" link-type="outer" visible="false">
                        <attribute name="indskr_composite"/>
                        <attribute name="indskr_line1" />
                        <attribute name="indskr_state_lu" />
                        <attribute name="indskr_postalcode_lu" />
                        <attribute name="indskr_country_lu" />
                        <attribute name="indskr_city_lu" />
                        <attribute name="indskr_longitude" />
                        <attribute name="indskr_latitude" />
                    </link-entity>
                    <attribute name="indskr_indskr_customeraddress_v2id" />
                    <filter>
                        <condition attribute="indskr_customer" operator="eq" value="{0}" />
                    </filter>
                </entity>
            </fetch>
        `,
        isFetch: true,
    },
    {
        entityName: "indskr_accountcontactaffiliation",
        fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                <entity name="contact">
                    <attribute name="contactid"/>

                    {DeltaSyncFilter}
                    {PositionFilterPH}
                    <link-entity name="indskr_accountcontactaffiliation" from="indskr_contactid" to="contactid" link-type="inner" alias="indskr_accountcontactaffiliation">
                        <attribute name="createdon"/>
                        <attribute name="statecode"/>
                        <attribute name="statuscode" />
                        <attribute name="indskr_startdate"/>
                        <attribute name="indskr_enddate"/>
                        <attribute name="indskr_comments"/>
                        <attribute name="indskr_accountid"/>
                        <attribute name="indskr_contactrole"/>
                        <attribute name="omnione_role"/>
                        <attribute name="createdby"/>
                        <attribute name="modifiedon"/>
                        <attribute name="modifiedby"/>
                        <attribute name="indskr_isprimaryaccount"/>
                        <attribute name="indskr_accountcontactaffiliationid"/>
                    </link-entity>
                </entity>
            </fetch>`,
        entityFetchXML:
            `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                <entity name="indskr_accountcontactaffiliation">
                <order attribute="indskr_contactid" descending="false" />
                <attribute name="statecode" />
                <attribute name="indskr_contactrole" />
                <attribute name="omnione_role"/>
                <attribute name="indskr_accountid" />
                <attribute name="indskr_accountcontactaffiliationid" />
                <attribute name="indskr_isprimaryaccount"/>
                <attribute name="createdon" />
                <filter>
                    <condition attribute="indskr_contactid" operator="eq" value="{0}" />
                </filter>
                <link-entity name='account' from='accountid' to='indskr_accountid' link-type='inner' alias='accountaffiliation'>
                  <link-entity name='indskr_customerposition' from='indskr_customerid' to='accountid' link-type='inner' alias='cp'>
                    <attribute name="indskr_approvalstatus"/>
                    <attribute name="indskr_positionid"/>
                    <link-entity name='account' from='accountid' to='indskr_customerid' link-type='inner' alias='ca'></link-entity>
                  </link-entity>
                </link-entity>
                </entity>
            </fetch>`,
        isFetch: true,
    },
    {

        entityName:"annotation",
        fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                <entity name="contact">
                    <attribute name="contactid"/>

                    {DeltaSyncFilter}
                    {PositionFilterPH}

                    <link-entity name="annotation" from="objectid" to="contactid" link-type="inner" alias="annotation" >
                        <attribute name="subject" />
                        <attribute name="notetext" />
                        <attribute name="filename" />
                        <attribute name="annotationid" />
                        <attribute name="ownerid" />
                        <attribute name="createdon" />
                        <attribute name="objectid" />
                        <attribute name="filesize"/>
                        <attribute name="mimetype"/>
                        <attribute name="isdocument"/>
                        
                    </link-entity>
                </entity>
            </fetch>`,
        entityFetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                <entity name="annotation">
                    <attribute name="subject" />
                    <attribute name="notetext" />
                    <attribute name="filename" />
                    <attribute name="annotationid" />
                    <attribute name="ownerid" />
                    <attribute name="createdon" />
                    <attribute name="objectid" />
                    <attribute name="filesize"/>
                    <attribute name="mimetype"/>
                    <attribute name="isdocument"/>
                    <order attribute="subject" descending="false" />
                    <filter>
                        <condition attribute="objectid" operator="eq" value="{0}" />
                    </filter>
                </entity>
            </fetch>`,
        isFetch: true,
    },
  {
    entityName: "indskr_kolstatus",
    fetchXML: `
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                <entity name="contact">
                    <attribute name="contactid"/>
                  
                    {PositionFilterPH}
                    <link-entity name="indskr_kolstatus" from="indskr_customer" to="contactid" link-type="inner" alias="indskr_kolstatus">
                    <attribute name="indskr_name" />
                    <attribute name="ownerid" />
                    <attribute name="statecode" />
                    <attribute name="indskr_customer" />
                    <attribute name="indskr_therapeuticarea" />
                    <attribute name="indskr_specialty" />
                    <attribute name="indskr_diseasearea" />
                    <attribute name="indskr_koltype" />
                    <attribute name="statuscode" />
                    <attribute name="createdon" />
                    <attribute name="modifiedon" />
                    <attribute name="indskr_comments" />
                    <attribute name="indskr_approvalstatus" />
                    <attribute name="indskr_kolstatusid" />
                    {DeltaSyncFilter}
                    <filter type="and">
                      <condition attribute="statecode" operator="eq" value="0" />
                      <condition attribute="statuscode" operator="eq" value="600000001" />
                    </filter>
                  </link-entity>
                </entity>
            </fetch>`,
            isFetch: true,
            featureAction: FeatureActionsMap.CUSTOMER_KOL_STATUS,
  },
    // {
    //     entityName: "indskr_customerinteractioninsights",
    //     fetchXML: `
    //         <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    //             <entity name="contact">
    //                 <attribute name="contactid"/>
    //                 {PositionFilterPH}
    //                 <link-entity name="indskr_customerinteractioninsights" from="indskr_customer" to="contactid" link-type="inner" alias="customerinsights" >
    //                     <attribute name="statecode"/>
    //                     <attribute name="indskr_customerinteractioninsightsid"/>
    //                     <attribute name="indskr_name"/>
    //                     <attribute name="createdon"/>
    //                     <attribute name="ownerid"/>
    //                     <attribute name="indskr_datecreated"/>
    //                     <attribute name="indskr_insightcategory"/>
    //                     <attribute name="indskr_specialty"/>
    //                     <attribute name="indskr_productcatalog"/>
    //                     <attribute name="indskr_priority"/>
    //                     <attribute name="indskr_summary"/>
    //                     <attribute name="indskr_description"/>
    //                     <attribute name="indskr_customer"/>
    //                     <attribute name="indskr_feedback"/>
    //                     <attribute name="indskr_interaction"/>
    //                     <attribute name="indskr_impact"/>
    //                     <attribute name="indskr_othersource"/>
    //                     {DeltaSyncFilter}
    //                 </link-entity>
    //             </entity>
    //         </fetch>`,
    //      isFetch: true,
    // }
]

export const FETCH_CONTACT_CR_LINK_ENTITIES = [
    {
      entityName: "indskr_emailaddresscr",
      fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_contactcr">
                      <attribute name="indskr_contactcrid"/>
                      <attribute name="statecode" />
                      <attribute name="statuscode" />

                      {PositionFilterPH}
                      {DeltaSyncFilter}

                      <link-entity name="indskr_emailaddresscr" from="indskr_contactcr" to="indskr_contactcrid" link-type="inner" alias="indskr_emailaddresscr">
                          <attribute name="indskr_isprimary"/>
                          <attribute name="indskr_emailaddress"/>
                          <attribute name="indskr_type"/>
                          <attribute name="indskr_contactcr"/>
                          <attribute name="indskr_emailaddresscrid"/>
                      </link-entity>
                  </entity>
              </fetch>`,
      entityFetchXML:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_emailaddresscr">
                  <order attribute="indskr_isprimary" descending="true" />
                  <order attribute="indskr_emailaddress" descending="false" />
                  <attribute name="indskr_isprimary" />
                  <attribute name="indskr_emailaddress" />
                  <attribute name="indskr_type" />
                  <attribute name="indskr_emailaddresscrid" />
                  <filter>
                      <condition attribute="indskr_contactcr" operator="eq" value="{0}" />
                  </filter>
                  </entity>
              </fetch>`,
      isFetch: true,
    },
    {
      entityName: "indskr_customeraddresscr",
      fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_contactcr">
                      <attribute name="indskr_contactcrid"/>

                      {DeltaSyncFilter}
                      {PositionFilterPH}
                      <link-entity name="indskr_customeraddresscr" from="indskr_contact" to="indskr_contactcrid" link-type="inner" alias="indskr_customeraddresscr">
                          <attribute name="indskr_name"/>
                          <attribute name="indskr_addresscr"/>
                          <attribute name="indskr_customeraddresscrid"/>
                          <attribute name="statecode" />
                          <attribute name="statuscode" />
                          <link-entity name='indskr_addresscr' from='indskr_addresscrid' to='indskr_addresscr' link-type="outer" alias="indskr_addresscr">
                              <attribute name="indskr_composite"/>
                              <attribute name="indskr_line1"/>
                              <attribute name="indskr_state_lu"/>
                              <attribute name="indskr_postalcode_lu"/>
                              <attribute name="indskr_country_lu"/>
                              <attribute name="indskr_city_lu"/>
                              <attribute name="indskr_longitude" />
                              <attribute name="indskr_latitude" />
                          </link-entity>
                      </link-entity>
                  </entity>
              </fetch>`,
      entityFetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_customeraddresscr">
                      <attribute name="indskr_name" />
                      <order attribute="indskr_name" descending="false" />
                      <attribute name="indskr_addresscr" />
                      <link-entity alias="alias_placeholder" name="indskr_addresscr" from="indskr_addresscrid" to="indskr_addresscr" link-type="outer" visible="false">
                          <attribute name="indskr_composite"/>
                          <attribute name="indskr_line1" />
                          <attribute name="indskr_state_lu" />
                          <attribute name="indskr_postalcode_lu" />
                          <attribute name="indskr_country_lu" />
                          <attribute name="indskr_city_lu" />
                          <attribute name="indskr_longitude" />
                          <attribute name="indskr_latitude" />
                      </link-entity>
                      <attribute name="indskr_customeraddresscrid" />
                      <filter>
                          <condition attribute="indskr_contactcr" operator="eq" value="{0}" />
                      </filter>
                  </entity>
              </fetch>
          `,
      isFetch: true,
    },
    {
      entityName: "indskr_accountcontactaffiliationcr",
      fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_contactcr">
                      <attribute name="indskr_contactcrid"/>

                      {DeltaSyncFilter}
                      {PositionFilterPH}
                      <link-entity name="indskr_accountcontactaffiliationcr" from="indskr_contactcr" to="indskr_contactcrid" link-type="inner" alias="indskr_accountcontactaffiliationcr">
                          <attribute name="createdon"/>
                          <attribute name="statecode"/>
                          <attribute name="statuscode" />
                          <attribute name="indskr_startdate"/>
                          <attribute name="indskr_enddate"/>
                          <attribute name="indskr_comments"/>
                          <attribute name="indskr_accountcr"/>
                          <attribute name="indskr_contactrole"/>
                          <attribute name="createdby"/>
                          <attribute name="modifiedon"/>
                          <attribute name="modifiedby"/>
                          <attribute name="indskr_isprimaryaccount"/>
                          <attribute name="indskr_accountcontactaffiliationcrid"/>
                      </link-entity>
                  </entity>
              </fetch>`,
      entityFetchXML:
        `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_accountcontactaffiliationcr">
                  <order attribute="indskr_contactcrid" descending="false" />
                  <attribute name="statecode" />
                  <attribute name="indskr_contactrole" />
                  <attribute name="indskr_accountcr"/>
                  <attribute name="indskr_accountcontactaffiliationcrid" />
                  <attribute name="indskr_isprimaryaccount"/>
                  <attribute name="createdon" />
                  <filter>
                      <condition attribute="indskr_contactcrid" operator="eq" value="{0}" />
                  </filter>
                  </entity>
              </fetch>`,
      isFetch: true,
    },
    {
      entityName: "indskr_approvalactivity",
      fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_contactcr">
                      <attribute name="indskr_contactcrid"/>

                      {DeltaSyncFilter}
                      {PositionFilterPH}
                      <link-entity name="indskr_approvalactivity" alias="indskr_approvalactivity" to="indskr_contactcrid" from="indskr_contactcr" link-type="outer">
                          <attribute name="indskr_approvalactivityid" />
                          <attribute name="createdon" />
                          <attribute name="indskr_reason" />
                          <attribute name="indskr_approver" />
                          <attribute name="createdonbehalfby" />
                      </link-entity>
                  </entity>
              </fetch>`,
      entityFetchXML:`
              <fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_approvalactivity">
                  <order attribute="indskr_approvalactivityId" descending="false" />
                      <attribute name="indskr_approvalactivityid" />
                      <attribute name="createdon" />
                      <attribute name="indskr_reason" />
                      <attribute name="indskr_approver" />
                      <attribute name="createdonbehalfby" />
                      <filter>
                          <condition attribute="indskr_contactcr" operator="eq" value="{0}" />
                      </filter>
                  </entity>
              </fetch>`,
      isFetch: true,
    }
]

export const DefaultPositionFilter =
  `<link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner" alias="indskr_customerpositionfilter">
      {approvalStatusFilter}
      {filterOutFromAppSyncFilter}
      <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
            <filter type="or">
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />  
                  <condition attribute="positionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="parentpositionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
            </filter>
        </link-entity>
    </link-entity>`;

export const PositionFilter =
  `<link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner" alias="indskr_customerpositionfilter">
      {approvalStatusFilter}
      {filterOutFromAppSyncFilter}
      <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
      <attribute name="positionid" alias="customer_positionid" />
      <attribute name="name" alias="customer_positionname" />
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />  
          <condition attribute="positionid" operator="in">
            {positionIDs}
          </condition>
        </filter>
      </link-entity>
    </link-entity>`;

export const FETCH_CONTACT_CHECKEDIN_EVENTS_QUERIES = {
  fetchCheckedInEventsForContact: `
<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
<entity name="msevtmgt_event">
<attribute name="msevtmgt_eventid"/>
<attribute name="msevtmgt_name"/>
<order descending="false" attribute="msevtmgt_name"/>
<filter type="and">
<condition attribute="msevtmgt_istemplate" value="100000002" operator="eq"/>
</filter>
<link-entity name="msevtmgt_checkin" alias="ac" link-type="inner" to="msevtmgt_eventid" from="msevtmgt_event">
<attribute name="createdby"/>
<attribute name="msevtmgt_checkintime"/>
<attribute name ="createdon"/>
<filter type="and">
<condition attribute="msevtmgt_contact" value="{contactid}" operator="eq" />
</filter>
</link-entity>
</entity>
</fetch>`
}
export const FETCH_CONTACT_COMPLETED_EVENTS_QUERIES = {
  fetchCompletedEventsForContact: `
  <fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
  <entity name="msevtmgt_event">
  <attribute name="msevtmgt_eventid"/>
  <attribute name="msevtmgt_name"/>
  <attribute name="msevtmgt_eventstartdate"/>
  <attribute name="indskr_completedby"  alias ="completedby" />
  <order descending="false" attribute="msevtmgt_name"/>
  <filter type="and">
  <condition attribute="msevtmgt_istemplate" value="100000002" operator="eq"/>
  <condition attribute="statuscode" value="548910005" operator="eq"/>
  </filter>
  <link-entity name="msevtmgt_eventregistration" alias="ao" link-type="inner" to="msevtmgt_eventid" from="msevtmgt_eventid">
  <filter type="and">
  <condition attribute="msevtmgt_contactid" value="{contactid}" operator="eq" />
  </filter>
  </link-entity>
  </entity>
  </fetch>`
}

export const ACCOUNT_CONTACT_AFFILIATION_BY_MDMID = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_accountcontactaffiliation">
    <attribute name="indskr_accountcontactaffiliationid" />
    <attribute name="indskr_mdmid" />
    <attribute name="indskr_accountcontaffil" />
    <attribute name="indskr_accountid" />
    <link-entity name="account" from="accountid" to="indskr_accountid" link-type="inner" alias="ae">
      <filter type="and">
        <condition attribute="accountid" operator="eq" value="{0}" />
      </filter>
    </link-entity>
  </entity>
</fetch>`;


export const FETCH_WEBSITE_ACCESS_LOGS = {
  fetchWebsiteAcessLogsForContact: ` <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_websiteaccesslogs">
  <attribute name="indskr_websiteaccesslogsid"/>
  <attribute name="indskr_name"/>
  <attribute name="createdon"/>
  <attribute name="indskr_pageurl"/>
  <attribute name="indskr_pagetitle"/>
  <attribute name="indskr_department"/>
  <attribute name="indskr_customer"/>
  <attribute name="indskr_channel"/>
  <attribute name="indskr_additionalinfo"/>
  <attribute name="indskr_accessdatetime"/>
  <order attribute="indskr_name" descending="false"/>
  <link-entity name="contact" from="contactid" to="indskr_customer" link-type="inner" alias="ac">
    <filter type="and">
       <condition attribute="contactid" operator="eq"  value="{contactid}"/>
    </filter>
  </link-entity>
  </entity>
  </fetch> `
}


export const FETCH_CONTACTS_LINK_ENTITES_BY_CONTACTID = [
  {
      entityName: "indskr_accountcontactaffiliation",
      fetchXML: `
          <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
              <entity name="contact">
                  <attribute name="contactid"/>
                  <link-entity name="indskr_accountcontactaffiliation" from="indskr_contactid" to="contactid" link-type="inner" alias="indskr_accountcontactaffiliation">
                      <attribute name="createdon"/>
                      <attribute name="statecode"/>
                      <attribute name="statuscode" />
                      <attribute name="indskr_startdate"/>
                      <attribute name="indskr_enddate"/>
                      <attribute name="indskr_comments"/>
                      <attribute name="indskr_accountid"/>
                      <attribute name="indskr_contactrole"/>
                      <attribute name="omnione_role"/>
                      <attribute name="createdby"/>
                      <attribute name="modifiedon"/>
                      <attribute name="modifiedby"/>
                      <attribute name="indskr_isprimaryaccount"/>
                      <attribute name="indskr_accountcontactaffiliationid"/>
                      {ContactFilter}
                      <link-entity name='account' from='accountid' to='indskr_accountid' link-type='inner' alias='accountaffiliation'>
                        <link-entity name='indskr_customerposition' from='indskr_customerid' to='accountid' link-type='inner' alias='cp'>
                          <attribute name="indskr_approvalstatus"/>
                          <attribute name="indskr_positionid"/>
                        <link-entity name='account' from='accountid' to='indskr_customerid' link-type='inner' alias='ca'></link-entity>
                      </link-entity>
                    </link-entity>
                  </link-entity>
              </entity>
          </fetch>`,
  },
]

export const CONTACT_LIST_PERIOD_TAG = `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="contact">
    <attribute name="contactid"/>
    {positionFilterlevel1}
    {customFilterLevel1}
    {deltasyncFilterLevel1}
    <link-entity name="indskr_positionlisttarget" from="indskr_contact" to="contactid">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
     <link-entity name="indskr_customerpositionlist" from="indskr_customerpositionlistid" to="indskr_customerpositionlist">
          <filter type="and">
            <condition attribute="ownerid" operator="eq-userid" />
            <condition attribute="indskr_position" operator="eq" value="{positionId}"/>
            <condition attribute="indskr_type" operator="eq" value="600000001" />
          </filter>
          <filter type="or">
            <condition attribute="statuscode" operator="eq" value="600000001" />
            <condition attribute="statuscode" operator="eq" value="600000002" />
          </filter>
       <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod">
         <attribute name="indskr_taglabel" alias="tagLabel"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
       </link-entity>
     </link-entity>
   </link-entity>
  </entity>
</fetch>
`
