export class ProcedureContract {
  indskr_enddate?: Date;
  indskr_startdate?: Date;
  indskr_procedurecontractid: string;
  indskr_account_value: string;
  indskr_product_value: string;
  indskr_maximumnoofassistance?: number;
  indskr_noofassistanceavailed?: number;
  indskr_proceduresubtype?: number;
  indskr_name?: string;
  indskr_procedure_value?: string;
  indskr_postsurgerysignature?: string;
  indskr_presurgerysignature?: string;
  indskr_validatornamepostsurgerysignature?: string;
  indskr_validatornamepresurgerysignature?: string;
  indskr_documentname?: string;
  accountName?: string;
  productName?: string;
  procedureName?: string;
  procedureTypeString?: string;
  proceudreSubTypeString?: string;
  public pendingPushToDynamics?: boolean = false;
  indskr_signaturecapturemode?: number;
  indskr_presurgerysignaturecaptured?: boolean;
  indskr_postsurgerysignaturecaptured?: boolean;
  isNew?: boolean;
  statecode?: number;
  indskr_parentprocedurecontractid?: string;
  parentProcedureContractName?: string;
  indskr_Specialty?: string;
  specialityName?: string;
  indskr_Address?: string;
  customerAddressName?: string;
  indskr_consenttaken?: boolean;
  indskr_complywithhospitalrulesandregulations?: boolean;
  indskr_postsurgerysignaturecapturedate?: Date;
  indskr_presurgerysignaturecapturedate?: Date;
  indskr_productid?: string;
  business_unit?: string;
  indskr_contracttypes: string;
  contractTypeString: string;
  statuscode: number;
  createdon: Date;
  indskr_postsurgerysignaturedocument: string;
  indskr_presurgerysignaturedocument: string;
  indskr_contact: string;
  contactName: string;
  statusString: string;
  public configuredFields: {[key: string]: any} = {};
  constructor(raw) {
    this.statecode = raw['statecode'];
    this.indskr_name = raw['indskr_name'];
    this.indskr_enddate = raw['indskr_enddates'] || raw['indskr_enddate'];
    this.indskr_startdate = raw['indskr_startdates'] || raw['indskr_startdate'];
    this.indskr_account_value = raw['_indskr_account_value'] || raw['indskr_account_value'];
    this.indskr_product_value = raw['_indskr_product_value'];
    this.accountName = raw['_indskr_account_value_Formatted'] || raw['accountName'];
    this.productName = raw['_indskr_product_value_Formatted'];
    this.indskr_documentname = raw['indskr_documentname'] || '';
    this.indskr_procedure_value = raw['_indskr_procedure_value'];
    this.procedureName = raw['_indskr_procedure_value_Formatted'];
    this.pendingPushToDynamics = raw['pendingPushToDynamics'] || false;
    this.indskr_presurgerysignature = raw['indskr_presurgerysignature'];
    this.indskr_procedurecontractid = raw['indskr_procedurecontractid'];
    this.indskr_proceduresubtype = raw['_indskr_proceduresubtypes_value'];
    this.indskr_postsurgerysignature = raw['indskr_postsurgerysignature'];
    this.procedureTypeString = raw['_indskr_proceduretypes_value_Formatted'];
    this.indskr_maximumnoofassistance = raw['indskr_maximumnoofassistance'];
    this.indskr_signaturecapturemode = raw['indskr_signaturecapturemode'] || '';
    this.indskr_noofassistanceavailed = raw['indskr_noofassistanceavailed'] || 0;
    this.proceudreSubTypeString = raw['_indskr_proceduresubtypes_value_Formatted'];
    this.indskr_parentprocedurecontractid = raw['_indskr_parentprocedurecontractid_value'] || '';
    this.indskr_presurgerysignaturecaptured = raw['indskr_presurgerysignaturecaptured'] || false;
    this.indskr_validatornamepresurgerysignature = raw['indskr_validatornamepresurgerysignature'];
    this.indskr_postsurgerysignaturecaptured = raw['indskr_postsurgerysignaturecaptured'] || false;
    this.indskr_validatornamepostsurgerysignature = raw['indskr_validatornamepostsurgerysignature'];
    this.parentProcedureContractName = raw['_indskr_parentprocedurecontractid_value_Formatted'] || '';

    this.indskr_Specialty = raw['_indskr_lu_specialty_value'] || raw['indskr_Specialty'] || '';
    this.specialityName = raw['_indskr_lu_specialty_value_Formatted'] || raw['specialityName'] || '';
    this.indskr_Address = raw['_indskr_address_value'] || raw['indskr_Address'] || '';
    this.customerAddressName = raw['_indskr_address_value_Formatted'] || raw['customerAddressName'] || '';
    this.indskr_consenttaken = raw['indskr_consenttaken'];
    this.indskr_complywithhospitalrulesandregulations = raw['indskr_complywithhospitalrulesandregulations'];
    this.indskr_postsurgerysignaturecapturedate = raw['indskr_postsurgerysignaturecapturedate'] || '';
    this.indskr_presurgerysignaturecapturedate = raw['indskr_presurgerysignaturecapturedate'] || '';
    this.indskr_productid = raw['indskr_productid'] || '';
    this.business_unit = raw['_owningbusinessunit_value'] || raw['business_unit'] || '';

    this.indskr_contracttypes = raw['_indskr_contracttypes_value'] || raw['indskr_contracttypes'] || '';
    this.contractTypeString = raw['_indskr_contracttypes_value_Formatted'] || raw['contractTypeString'] || '';
    this.indskr_presurgerysignaturedocument = raw['indskr_presurgerysignaturedocument'] || '';
    this.indskr_postsurgerysignaturedocument = raw['indskr_postsurgerysignaturedocument'] || '';

    this.indskr_contact = raw['_indskr_contact_value'] || null;
    this.contactName = raw['_indskr_contact_value_Formatted'] || null;

    this.statuscode = raw['statuscode'] || 548910000;
    this.createdon = raw['createdon'] || new Date();
    this.statusString = raw['statuscode_Formatted'] || null;
  }
}

export enum PROCEDURE_CONTRACT_STATUS {
  DRAFT = 548910000,
  PRE_SIGNED = 548910001,
  // COMPLETED = 548910004,
  CANCELLED = 548910005,
  EXPIRED = 548910009,
  ACTIVE = 548910010,
  POST_SIGNED = 548910006
}

export enum PROCEDURE_CONTRACT_TYPES {
  PAID_SINGLE_DAY_CONTRACT = 548910001,
  FREE_CONTRACT = 548910000,
  PAID_MULTIPLE_DAY_CONTRACT = 548910002
}

export class ContractType {
  indskr_contracttypeid: string;
  indskr_name: string;
  indskr_usagetype: number;
  statecode: number;
  indskr_presignature: boolean;
  indskr_postsignature: boolean;
  indskr_associatedentitytypecode: string;
  indskr_presignaturesectionname: string;
  indskr_presurgerytext: string;
  indskr_postsignaturesectionname: string;
  indskr_postsurgerytext: string;
  procedureLogApplicability: any = [];
  configuredFields:any = [];
  constructor(raw) {
    this.indskr_contracttypeid = raw['indskr_contracttypeid'] || '';
    this.indskr_name = raw['indskr_name'] || '';
    this.indskr_usagetype = raw['indskr_usagetype'] || '';
    this.statecode = raw['statecode'];
    this.indskr_presignature = raw['indskr_presignature'] || false;
    this.indskr_postsignature = raw['indskr_postsignature'] || false;
    this.indskr_associatedentitytypecode = raw['indskr_associatedentitytypecode'] || '';
    this.indskr_presignaturesectionname = raw['indskr_presignaturesectionname'] || null;
    this.indskr_presurgerytext = raw['indskr_presurgerytext'] || null;
    this.indskr_postsignaturesectionname = raw['indskr_postsignaturesectionname'] || null;
    this.indskr_postsurgerytext = raw['indskr_postsurgerytext'] || null;

    if (raw) {
      if (raw['indskr_statusforprocedurelogapplicability']) {
        this.procedureLogApplicability = raw['indskr_statusforprocedurelogapplicability'].split(",");
      }

      if(raw['aa.indskr_appfieldid']){
        this.configuredFields.push(raw['aa.indskr_appfieldid']);
      }
    }
  }
}

export enum PROCEDURE_CONTRACT_HEIRACRCHY {
  PARENT =  548910000,
  CHILD = 548910001,
}