<ion-header>
    <ind-page-title [viewData]=""></ind-page-title>
</ion-header>
<ion-content class="vector-image-right">
    <div *ngIf="!notShowText" class="nothing-slected">{{textToDisplay}}</div>
    <!-- <img class="os_background" [ngClass]="{'os_background_ios': deviceService.deviceFlags.ios}" /> -->
</ion-content>
<!-- <ion-footer>
    <ion-toolbar class="toolbar-height">
    </ion-toolbar>
</ion-footer> -->
