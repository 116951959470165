<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="hideFilterButton" [(searchText)]="sampleService.allocationOrdersSearchStr" (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="searchInput($event.detail)" (onFilterClick)="customerAllocationMode === 'receipts' ? openShipmentsFilter($event) : customerAllocationMode === 'adjustments' ? openAdjustmentsFilter($event) : customerAllocationMode === 'inventory' ? openInventoryFilter($event) : openOrdersFilter($event)"
      [filterButtonBadgeCount]="getFilterButtonBadgeCount()" (ionBlur)="onSearchBlur($event)"></ind-header-left>

  <ion-item-divider class="keyControlsArea" color="light">
      <ion-button *ngIf="isTransferEnabled" slot="end" fill="clear" float-right class="no-padding" [disabled]="customerAllocationMode == 'adjustments' || customerAllocationMode == 'history' || customerAllocationMode == 'inventory' || uiService.activeView == 'AllocationTransfer' || uiService.activeView == 'SimpleEntitySelect' || device.isOffline"
          (click)='openAllocationTransfer()'>{{'TRANSFER' | translate}}
      </ion-button>
      <ion-button *ngIf="isAdjustEnabled" slot="end" fill="clear" float-right class="no-padding" [disabled]="customerAllocationMode == 'adjustments' || customerAllocationMode == 'history' || customerAllocationMode == 'inventory' || uiService.activeView == 'AllocationAdjust' || device.isOffline" (click)='openAllocationAdjust()'>
          {{'ADJUST' | translate}}
      </ion-button>
  </ion-item-divider>
  <ion-toolbar>
      <ind-tabs [data]="tabsData" [(selectedTab)]="customerAllocationMode" (selectedTabChange)="trackEvent($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content #content [ngClass]="{'vectorimageleft': sampleService.allocationOrdersSearchStr?.length === 0 && (customerAllocationMode == 'receipts' && filteredList?.length === 0) || customerAllocationMode === 'history'}">
  <div [ngSwitch]="customerAllocationMode">
      <div *ngIf="customerAllocationMode === 'history'">
          <allocation-orders-list #allocOrderList [searchEvent]="orderSearchEventSubject.asObservable()"></allocation-orders-list>
      </div>

      <div *ngIf="customerAllocationMode === 'adjustments'">
          <team-adjustments-list #adjustmentList [searchEvent]="adjustmentListSearchEventSubject.asObservable()"></team-adjustments-list>
      </div>
      <div *ngIf="customerAllocationMode === 'inventory'">
        <user-inventory-list #inventoryList [searchEvent]="inventoryListSearchEventSubject.asObservable()"></user-inventory-list>
      </div>
      <div *ngIf="customerAllocationMode === 'receipts'">
          <ion-item-group>
              <ion-item-divider coaslor="light" class="list-title normal-divider">
                  <ion-label cls="title-text" *ngIf="!listFiltered; else filtered">
                      {{ !isSearchedInput ? (customerAllocationMode === 'receipts' ? ('AL_ALL_SHIPMENTS' | translate) : ('AL_ALL_ADJUSTMENTS' | translate)) : ('AL_RESULTS' | translate) }} ({{filteredList?.length}})
                      <span *ngIf="!isShipmentDataReady" class="data-load-indicator"><ion-spinner name="dots"></ion-spinner></span>
                  </ion-label>
                  <ng-template #filtered>
                      <ion-label class="title-text">
                          {{ !isSearchedInput ? filterTxt : ('AL_RESULTS' | translate) }} ({{filteredList?.length}})
                      </ion-label>
                  </ng-template>
              </ion-item-divider>
              <ion-list *ngIf="!sampleService.allocationOrdersSearchStr.length && isShipmentDataReady && filteredList?.length==0" class="shipments-list-empty">
                  <ion-item>
                      <div *ngIf="customerAllocationMode === 'receipts'" class="no-data-message" [hidden]="filterValueSelected">
                          {{'AL_NO_SHIPMENTS' | translate}}
                      </div>
                  </ion-item>
              </ion-list>
              <ng-container *ngIf="sampleService.allocationOrdersSearchStr.length > 0 && isShipmentDataReady && filteredList?.length === 0">
                  <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
              </ng-container>
              <ion-list *ngIf="isShipmentDataReady && filteredList?.length > 0" class="shipments-list">
                  <ion-item-group *ngFor="let shipment of filteredListToBeDisplayed" (click)="handleSelection(shipment)" [ngClass]="{'itemSelected': allocFeatureService.selectedShipment && shipment.indskr_usershipmentallocation_v2id == allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id}">
                    <ion-item>
                        <ion-thumbnail slot="start">
                            <img [src]="getShipmentIconPath(shipment)" class="main-icon">
                        </ion-thumbnail>
                        <ion-label class="text-wrapper">
                            <ion-text class="primary-text">

                                <h3 *ngIf="shipment.indskr_transfertype == transferType.AllocationTransfer">{{'TRANSFER_NUMBER' | translate}} {{shipment.indskr_shipmentnumber}}</h3>

                                <h3 *ngIf="shipment.indskr_transfertype != transferType.AllocationTransfer && shipment.indskr_transfertype != transferType.AllocationAdjustment">{{'AL_SHIPMENT_NUM' | translate}} {{shipment.indskr_shipmentnumber}}</h3>

                                <h3 *ngIf="shipment.indskr_transfertype == transferType.AllocationAdjustment">{{'AL_ADJUSTMENT_NUMBER' | translate}} {{shipment.indskr_shipmentnumber}}</h3>
                            </ion-text>
                            <p class="secondary-text">
                                <ion-text *ngIf="shipment.indskr_transfertype != transferType.AllocationAdjustment">{{shipment.indskr_skuname || shipment.at_indskr_skuname}}
                                </ion-text>

                                <ion-text *ngIf="shipment.indskr_transfertype === transferType.AllocationAdjustment">{{shipment.at_indskr_skuname}} ({{shipment.indskr_quantityadjusted}})
                                </ion-text>
                            </p>
                            <p class="tertiary-text">
                                <ion-row>
                                    <ion-col size='3' class="tert-text-overflow">
                                        <ion-text *ngIf="shipment.indskr_transfertype != transferType.AllocationAdjustment" class="tert-text-first">{{getFormattedDate(shipment.indskr_shipmentdate)}}</ion-text>
                                        <ion-text *ngIf="shipment.indskr_transfertype == transferType.AllocationAdjustment" class="tert-text-first">{{getFormattedAdjustedDate(shipment)}}</ion-text>
                                    </ion-col>
                                    <ion-col size='9' class="tert-text-overflow">
                                        <ion-text class="tert-text-second" *ngIf="shipment.indskr_transfertype == transferType.AllocationTransfer">{{getShipmentTransferToFrom(shipment)}}</ion-text>
                                    </ion-col>
                                </ion-row>
                            </p>
                        </ion-label>
                        <div slot="end" *ngIf="isShipmentApproved(shipment)" class="acknowledged-state">
                            <img src="assets/imgs/contact_conn.png" class="acknowledged-state-icon" />
                        </div>
                    </ion-item>
                    <div class="separationLine"></div>
                </ion-item-group>
              </ion-list>
              <ion-infinite-scroll *ngIf="filteredList?.length > 0" (ionInfinite)="doInfinite($event)" threshold="20%">
                  <ion-infinite-scroll-content></ion-infinite-scroll-content>
              </ion-infinite-scroll>
          </ion-item-group>
      </div>
  </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar [footerView]="'masterView'" [selectedView]="'allocations'"></footer-toolbar>
<!-- </ion-footer> -->
