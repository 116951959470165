<ion-grid class='sync-popover-grid'>
    <ion-card>
        <ion-card-content class='sync-popover'>
            <ion-card-title class='loading-text'>
                {{loadingText}}
            </ion-card-title>
            <ion-row class='loading-sub-text' *ngIf="loadingSubText">
                {{loadingSubText}}
            </ion-row>
            <ion-row class='loading-spinner'>
                <ion-spinner color='light'></ion-spinner>
            </ion-row>
        </ion-card-content>
    </ion-card>
</ion-grid>
