<ion-header>
  <ind-header-left
      [viewData]="headerData"
      [hideFilterButton]="true"
      (onControlClick)="onHeaderControlClick($event)"
      [(searchText)]="searchText"
      (onSearchChange)="onSearchInput($event)">
  </ind-header-left>
</ion-header>

<ion-content
    [ngClass]="{'vectorimageleft': !searchText && templates.length === 0}"
    no-padding>

  <!-- Selected templates -->
  <ng-container *ngIf="selectedTemplate">
    <ind-section-header [viewData]="selectedTemplateSectionHeaderData"></ind-section-header>
    <ion-item class='selector-item' *ngIf="selectedTemplate" [ngClass]="{itemSelected: selectedTemplate.indskr_emailtemplateid == highlightedTemplateId, inactive: selectedTemplate.shouldBeGreyedOut}">
      <email-template-item #templateItem tappable (click)="previewTemplate(selectedTemplate)" [template]="selectedTemplate"></email-template-item>
      <ion-icon class="remove-icon" slot="end" name="indegene-selectors-remove-icon" (click)="deSelectTemplate(selectedTemplate)"></ion-icon>
    </ion-item>
  </ng-container>


  <!-- Template list -->
  <ng-container *ngIf="!isSearching else searchResult;">
    <ind-section-header
        [viewData]="templateListSectionHeaderData">
    </ind-section-header>

    <ion-item-group *ngIf="templates.length > 0 else noData;">
      <ion-list>
        <ion-item
            class="selector-item"
            *ngFor="let template of templates | orderBy: sortBy?.value:sortBy?.asc"
            [ngClass]="{itemSelected: template.indskr_emailtemplateid == highlightedTemplateId, inactive: template.shouldBeGreyedOut}">

          <email-template-item #templateItem tappable
              (click)="previewTemplate(template)"
              [template]="template"
              [sortedBy]="sortBy.value">
          </email-template-item>
          <ion-icon class="checkmark-icon"
              *ngIf="selectedTemplate && selectedTemplate.indskr_emailtemplateid == template.indskr_emailtemplateid"
              slot="end"
              name="indegene-selectors-checkmark-icon">
          </ion-icon>
          <ion-icon
              class="add-icon"
              slot="end"
              (click)="selectTemplate(template)"
              name="indegene-selectors-add-icon"
              *ngIf="!selectedTemplate || selectedTemplate.indskr_emailtemplateid != template.indskr_emailtemplateid">
          </ion-icon>
        </ion-item>
      </ion-list>
    </ion-item-group>

    <ng-template #noData>
      <p class="no-data-message"> {{ 'NO_TEMPLATES' | translate}}</p>
    </ng-template>
  </ng-container>


  <!-- Template search result -->
  <ng-template #searchResult>
    <ind-section-header
        [viewData]="searchResultSectionHeaderData">
    </ind-section-header>

    <ion-list *ngIf="searchedTemplates.length > 0 else noSearchResult;">
      <ion-item
          class="selector-item"
          *ngFor="let template of searchedTemplates | orderBy: sortBy?.value:sortBy?.asc"
          [ngClass]="{itemSelected: template.indskr_emailtemplateid == highlightedTemplateId, inactive: template.shouldBeGreyedOut}">
        <email-template-item #templateItem tappable
            (click)="previewTemplate(template)"
            [template]="template"
            [sortedBy]="sortBy.value">
        </email-template-item>
        <ion-icon class="checkmark-icon"
            *ngIf="selectedTemplate && selectedTemplate.indskr_emailtemplateid == template.indskr_emailtemplateid"
            slot="end"
            name="indegene-selectors-checkmark-icon">
        </ion-icon>
        <ion-icon
            class="add-icon"
            slot="end"
            (click)="selectTemplate(template)"
            name="indegene-selectors-add-icon"
            *ngIf="!selectedTemplate || selectedTemplate.indskr_emailtemplateid != template.indskr_emailtemplateid">
        </ion-icon>
      </ion-item>
    </ion-list>

    <ng-template #noSearchResult>
      <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </ng-template>
  </ng-template>
</ion-content>

<!-- <ion-footer> -->
  <footer-toolbar [footerView]="'masterView'" [selectedView]="'calendar-invite-templates'"></footer-toolbar>
<!-- </ion-footer> -->
