import { DeviceService } from '@omni/services/device/device.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { AddressService } from '@omni/services/address/address.service';
import { Injectable } from "@angular/core";
import { CONTACT_FETCH_QUERIES } from "../../config/fetch-xml/contact-fetchXMLs";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { Subject, Observable } from "rxjs";
import { ContactAddress } from "../../classes/contact/contact.class";
import { ADDRESS_FETCH_QUERIES } from '../../config/fetch-xml/address-fetchXMLs';
import { BUILDING_ENTITY_NAME } from '../../config/fetch-xml/shared-fetchXML-entity-names';
import { FeatureActionsMap } from '../../classes/authentication/user.class';

@Injectable({
  providedIn: 'root'
})
export class AddressDataService{

    private ADDRESS_FETCH_XML = CONTACT_FETCH_QUERIES.searchAllAddresses;
    $addAddress = new Subject<ContactAddress>();

    constructor(
        private authService: AuthenticationService,
        private deviceService: DeviceService,
        private addressService: AddressService,
        public dynamics: DynamicsClientService,
    ){}
      
    getGlobalAddresses(query:string):Promise<any[]>{
      let compositeAddressFilter = `<condition attribute="indskr_composite" operator="like" value="%${query}%"/>`;
      let FETCH_XML = this.ADDRESS_FETCH_XML.replace('{{CompositeAddressCondition}}', compositeAddressFilter);
      return this.dynamics.executeFetchQuery('indskr_addresses',FETCH_XML);
    }
  
    selectAddress(selectedAddress){
        this.$addAddress.next(selectedAddress);
    }

    getAddressSub():Observable<ContactAddress>{
        return this.$addAddress.asObservable();
    }

    mapAddress(result) {
        let addressResult = []
        result.forEach(address => {
            let addObj = {
                indskr_addressid: address['indskr_addressid'] ? address['indskr_addressid'] : '',
                indskr_name: address['indskr_name'] ? address['indskr_name'] : '', 
                indskr_line1: address['indskr_line1'] ? address['indskr_line1'] : '',
                indskr_country_lu: address['_indskr_country_lu_value'] ? address['_indskr_country_lu_value'] : '',
                indskr_country_lu_value: address['_indskr_country_lu_value@OData.Community.Display.V1.FormattedValue'] ? address['_indskr_country_lu_value@OData.Community.Display.V1.FormattedValue']  : '',
                indskr_state_lu: address['_indskr_state_lu_value'] ? address['_indskr_state_lu_value'] : '',
                indskr_state_lu_value: address['_indskr_state_lu_value@OData.Community.Display.V1.FormattedValue'] ? address['_indskr_state_lu_value@OData.Community.Display.V1.FormattedValue'] : '',
                indskr_city_lu: address['_indskr_city_lu_value'] ? address['_indskr_city_lu_value'] : '',
                indskr_city_lu_value: address['_indskr_city_lu_value@OData.Community.Display.V1.FormattedValue']? address['_indskr_city_lu_value@OData.Community.Display.V1.FormattedValue'] : '',
                indskr_postalcode_lu: address['_indskr_postalcode_lu_value'] ? address['_indskr_postalcode_lu_value'] : '',
                indskr_postalcode_lu_value: address['_indskr_postalcode_lu_value@OData.Community.Display.V1.FormattedValue'] ? address['_indskr_postalcode_lu_value@OData.Community.Display.V1.FormattedValue'] : '',
                isPrimary: false,
                compositeAdd: address['indskr_composite'] ? address['indskr_composite'] : '',
            };
            if(addObj.indskr_addressid)addressResult.push(addObj);
        });
        return addressResult;
    }

    // Always full sync
    async getBuildings(loadFromDBOnly = false) {
      // FA check
      if (!this.authService.hasFeatureAction(FeatureActionsMap.EVENT_APPROVAL)) {
        return;
      }

      // Offline
      if (this.deviceService.isOffline || loadFromDBOnly) {
        this.addressService.loadOfflineBuildings();
        return;
      }

      // Online
      const fetchXML = ADDRESS_FETCH_QUERIES.buildings;

      // Unblocking call since not a primary data required for initial loading
      this.dynamics.executeFetchQuery(BUILDING_ENTITY_NAME, fetchXML)
      .then(response => this.addressService.processBuildingsFetchResponse(response))
      .catch(error => {
        console.error('getBuildings: ', error);
      });
    }
}