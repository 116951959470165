import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { DiskService } from './../../../services/disk/disk.service';

import {takeUntil} from 'rxjs/operators';
import { Component, Output, EventEmitter, OnInit, OnChanges, OnDestroy, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import { Scheduler, schedulePayload } from '../../../classes/scheduler/scheduler.class';
import { SchedulerService, SchedulingPop } from '../../../services/scheduler/scheduler.service';
import { PopoverController, LoadingController } from '@ionic/angular';
import { PopoverComponent } from '../../popover/popover';
import { SchedulerDataService } from '../../../data-services/scheduler/scheduler.data.service';
import * as moment from "moment";
import { DeviceService } from '../../../services/device/device.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { Subscription ,  Subject } from 'rxjs';
import { SchedulerPopoverComponent } from '../../popover/scheduler-popover/scheduler-popover';
import { zipcode } from '../../../classes/scheduler/scheduler-zip.class';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { Contact } from '../../../classes/contact/contact.class';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { FooterService, FooterViews } from "../../../services/footer/footer.service";
import { AccountSelectedFor, AccountOfflineService } from '../../../services/account/account.offline.service';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { REP_STATUS } from '../../../models/rep-status-model';
import { RepServices } from '../../../data-services/rep/rep.services';
import { city } from '../../../classes/scheduler/scheduler-city.class';
import { EventsService } from '../../../services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { AlertService } from '../../../services/alert/alert.service';
import { DatePipe } from '@angular/common';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';

@Component({
  selector: 'scheduler-detail',
  templateUrl: 'scheduler-detail.html',
  styleUrls:['scheduler-detail.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerDetailComponent implements OnInit, OnDestroy, OnChanges {

  @Input() isDeviceOnline: boolean = true;
  @Output() exit = new EventEmitter<any>(null);
  @Input() selectedSchedule: Scheduler;
  private staticSchedule: any = {};
  public isSchedulePopup: boolean = false;
  public schedulerList: any[] = [];
  private selectedPriority: string = '';
  private selectedDuration;
  public startDate: string = moment(new Date()).format();
  public endDate: string = moment(new Date(this.startDate)).add(6, 'd').format();
  private discardChangesSuscription: Subscription;
  private IresultSubscription: Subscription;
  private selectedScheduleObs: Subscription;
  public globalCustomerText: string;
  public schedulerDetailsPageTitle: IndPageTitleViewDataModel;
  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case 'savescheduler':
        if (this.schedulerService.isNewPattern) {
          this.save();
        } else {
          this.onUpdate();
        }
        break;
      case 'scrapScheduler':
        this.scrap();
        break;
      case 'suggestscheduler':
        this.suggestSchedules();
        break;
      case 'copyscheduler':
        this.copyRoutePlan();
        break;
      default:
    }
  };
  ngDestroy$: any = new Subject<boolean>();
  public routeDatesHeader: IndSectionHeaderViewDataModel;
  public routPlanDetailsHeader: IndSectionHeaderViewDataModel;
  // date time picker
  public startDateField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  dateFormatSubscription: Subscription;
  private isClickedDescription: boolean = false;
  private isClickedPriortize: boolean = false;

  constructor(
    private activityService: ActivityService,
    private schedulerService: SchedulerService,
    private popoverCtrl: PopoverController,
    private schedulerDataService: SchedulerDataService,
    public contactService: ContactOfflineService,
    public deviceService: DeviceService,
    private navService: NavigationService,
    private cd: ChangeDetectorRef,
    private loadingCtrl: LoadingController,
    private trackingService: TrackService,
    private uiService: UIService,
    public footerService: FooterService,
    private accountService: AccountOfflineService,
    private events: EventsService,
    public repService: RepServices,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private readonly disk: DiskService,
    private readonly callPlanService: CallPlanOfflineService,
  ) { 
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this._initDateTimeFormFields();
        this.cd.detectChanges();
      }
    });
  }

  ngOnInit() {
    /*
      Fetch the users selected pattern or set/reset edit and new pattern marker
    */
    this.initialiseCustomerFormat();
    this.selectedScheduleObs = this.schedulerService.schedulerObs.subscribe(async value => {
      if (value) {
        //user makes changes but clicks on the tile
        //revert to the original state and discard the changes made
        if ((this.staticSchedule && this.staticSchedule.ID && (this.staticSchedule.ID != value.ID) && !this.schedulerService.areChangeSaved) || (this.staticSchedule && this.staticSchedule.ID && (this.staticSchedule.ID == value.ID) && !this.schedulerService.areChangeSaved)) {
          console.log("User tried to switch without updating the data to dynamics");
        }
        else {
          //User has no unsaved data or maybe visiting for the first time.
          this.staticSchedule = Object.assign({}, value);
          this.selectedSchedule = value;
          this.schedulerService.ifEditMode = this.schedulerService.isNewPattern = this.selectedSchedule.isNewScheduler;
        }
        this.initSchedulerDetailsPageTitle();
        this.schedulerService.areChangeSaved = true;
        this.footerService.initButtons(FooterViews.SchedulingDetails);
        this.selectedDuration = this.schedulerService.getDurationString(value.durationValue);
        if (value.priority) {
          if (Array.isArray(value.priority)) {
            if (value.priority.length > 0) {
              this.selectedPriority = value.priority[0];
            }
            else
              this.selectedPriority = this.translate.instant('SCHEDULER_SELECT_PRIORITY');
          }
          else
            this.selectedPriority = value.priority.value;
        }
        else {
          this.selectedPriority = this.translate.instant('SCHEDULER_SELECT_PRIORITY');
        }
        this.startDate = moment(this.selectedSchedule.startdate).format();
        this.endDate = moment(this.selectedSchedule.enddate).format();

        this.resetSelections();
        this._initSectionHeaderDetails();
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
      else {
        /* If the value is undefined then probably close the component */
        this.resetSelections();
      }
    });

    this.IresultSubscription = this.schedulerService.selectionResultObserver.subscribe(res => {
      if (res) {
        let type: string;
        this.schedulerService.selectionTypeObserver.subscribe(value => type = value).unsubscribe();
        this.updateSelectedResult(type, res);
        this.schedulerService.areChangeSaved = false;
        this._initSectionHeaderDetails();
        this.footerService.initButtons(FooterViews.SchedulingDetails);
        this.cd.detectChanges();
      }
    });

    this.discardChangesSuscription = this.schedulerService.changesMapObserver.subscribe(res => {
      if (res) {
        this.cancelEdit(res);
      }
    });

    this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        // the network service indicate that the device is offline so no arguments.
        this.isDeviceOnline = false;
        this._initSectionHeaderDetails();
        this.cd.detectChanges();
        this.cd.markForCheck();
      });

      this.translate.onLangChange.subscribe(data => {
        this._initSectionHeaderDetails();
        this.initSchedulerDetailsPageTitle();
        this.initialiseCustomerFormat();
        this.cd.detectChanges();
        this.cd.markForCheck();
      });

    this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
          this.isDeviceOnline = false;
          this._initSectionHeaderDetails();
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
        else {
          this.isDeviceOnline = true;
          this._initSectionHeaderDetails();
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
      });
    this._initSectionHeaderDetails();
    this.events.unsubscribe('scheduler-detail:footerEvents', this.footerHandler);
    this.events.subscribe('scheduler-detail:footerEvents', this.footerHandler);
  }

  private initialiseCustomerFormat() {
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }
  }

  ngOnChanges(_changes: SimpleChanges) {
    if (this.selectedSchedule != undefined) {
      this.cd.detectChanges();
      this.schedulerService.ifEditMode = this.schedulerService.isNewPattern = this.selectedSchedule.isNewScheduler;
      this.footerService.initButtons(FooterViews.SchedulingDetails);
      this.cd.detectChanges();
      this.cd.markForCheck();
    }

  }

  ngOnDestroy() {
    this.selectedScheduleObs.unsubscribe();
    this.IresultSubscription.unsubscribe();
    this.discardChangesSuscription.unsubscribe();
    this.events.unsubscribe('scheduler-detail:footerEvents', this.footerHandler);
    this.dateFormatSubscription.unsubscribe();
    this.activityService.selectedActivity = undefined;
    //please do not unsubscribe to these events. These are crucial events that need to be detected by app.
    //unsubsribing it like this will unsubscribe it from all the places.
    //this.events.unsubscribe('device:deviceIsOffline');
    //this.events.unsubscribe('device:deviceIsOnline');
    /*if (this.selectedSchedule != undefined) {
      if (this.selectedSchedule.isNewScheduler) {
        this.closeWithExit();
      }
    }*/
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.callPlanService.segmentCallPlans = []
  }

  // ngAfterContentChecked() {
  //   if (this.navService.getCurrentPageName() != PageName.ActivitiesPageComponent) return;
  //   if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
  //     this._initSectionHeaderDetails();
  //   }
  //   this.initSchedulerDetailsPageTitle();
  //   this.footerService.initButtons(FooterViews.SchedulingDetails);
  // }

  private _initSectionHeaderDetails() {
    this._initRoutPlanDetailsHeader();
    this._initRouteDatesHeader();
    this._initDateTimeFormFields();
    this.initSchedulerDetailsPageTitle();
    this.footerService.initButtons(FooterViews.SchedulingDetails);
  }

  private _initRoutPlanDetailsHeader() {
    this.routPlanDetailsHeader = {
      id: 'route-plan-details-header',
      title: this.translate.instant('ROUTE_PLAN_DETAILS'),
      controls: [
        {
          id: 'route_plan_save',
          text: this.schedulerService.isNewPattern ? this.translate.instant('SAVE_ROUTE_PLAN') : this.translate.instant('UPDATE'),
          isDisabled: this.deviceService.isOffline ? true : false || this.isSaveDiabled,
        }
      ],
    }
  }

  private _initRouteDatesHeader(){
    this.routeDatesHeader = {
      id: 'route-dates-header',
      title: this.translate.instant('SCHEDULING_DATES'),
      controls: [],
    }
  }

  closepage() {
    this.trackingService.tracking('Back from Scheduling Route', TrackingEventNames.SCHEDULER);
    this.clearChanges()
  }

  private clearChanges() {
    if (!this.schedulerService.areChangeSaved) {
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('SCHEDULER_LIST_ALERT_MESSAGE')
      }, this.translate.instant('DISCARD')
      ).then(res => {
        if (res.role == "ok") {
          this.schedulerService.discardCurrentChanges()
            .then(e => {
              this.schedulerService.setSchedule(undefined);
              this.closeScheduler(true)
              console.log(e);
            }).catch(e => {
              console.log(e);
            });
        }
      });
    } else {
      this.schedulerService.setSchedule(undefined);
      this.closeScheduler(true)
    }
  }

  closeScheduler(event): void {
    if(event) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = '';
      if (this.deviceService.isMobileDevice) {
        this.uiService.showRightPane = false;
      }
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }
  }

  private initSchedulerDetailsPageTitle(): void {
    this.schedulerDetailsPageTitle = {
      id: 'scheduler-details-page-title',
      title: this.selectedSchedule.name,
      controls: [
        {
          id: 'route_plan_scrap',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: this.schedulerService.isNewPattern || this.deviceService.isOffline ? true : false,
          align: 'right'
        },
        {
          id: 'suggest_schedule',
          imgSrc: 'assets/imgs/header_suggest_schedule.svg',
          name: this.translate.instant('SUGGEST'),
          isDisabled: this.schedulerService.isNewPattern || !this.schedulerService.areChangeSaved || this.deviceService.isOffline ? true : false,
          align: 'right'
        }
      ],
    };

    if(!this.selectedSchedule.isNewScheduler) {
      const copyButton = {
        id: 'route_plan_copy',
        imgSrc: 'assets/imgs/copy.svg',
        name: this.translate.instant('COPY'),
        align: 'right',
        isDisabled: this.deviceService.isOffline ? true : false
      };
      this.schedulerDetailsPageTitle.controls.splice(1,0,copyButton);
    }
  }

  get isSaveDiabled() {
    if (this.schedulerService.isNewPattern) {
      return false;
    } else if (this.schedulerService.isProcessing || this.schedulerService.areChangeSaved) {
      return true;
    }
    return false;
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'route_plan_save':
        if (this.schedulerService.isNewPattern) {
          this.save();
        } else {
          this.onUpdate();
        }
        break;
      default:
        break;
    }
  }

  private scrap() {
    this.alertService.showAlert({
      title: this.translate.instant('SCHEDULER_SCRAP_SCHEDULE_ROUTE'),
      message: this.translate.instant('SCHEDULER_SCRAP_ALERT_MESSAGE_ROUTE')
    }, this.translate.instant('SCRAP')
    ).then(async res => {
      if (res.role == "ok") {
        const loader = await this.loadingCtrl.create();
        loader.present();
        if (!this.deviceService.isOffline) {
          let selectedSchedule: Scheduler = this.selectedSchedule;
          this.schedulerDataService.scrapSchedulerPattern(selectedSchedule.ID).subscribe(
            () => {
              loader.dismiss();
              if (this.schedulerService.deleteSchedule(selectedSchedule)) {
                this.schedulerService.setSchedule(undefined);
                this.selectedSchedule = undefined;
                this.activityService.selectedActivity = undefined;
                this.uiService.showNewActivity = false;
                this.uiService.activeView = '';
                if (this.deviceService.isMobileDevice) {
                  this.uiService.showRightPane = false;
                }
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              }
            },
            err => {
              console.log(err);
              if (err.error.errorCode === "ERROR_IN_SCHEDULING_PATTERN_DELETE") {
                try {
                  this.schedulerService.deleteSchedule(selectedSchedule)
                  this.schedulerService.setSchedule(undefined);
                  this.selectedSchedule = undefined;
                  this.activityService.selectedActivity = undefined;
                  this.uiService.showNewActivity = false;
                  this.uiService.activeView = '';
                  if (this.deviceService.isMobileDevice) {
                    this.uiService.showRightPane = false;
                  }
                  this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
                } catch (error) {
                  console.log(error)
                }
              }
              else {
                this.notificationService.notify(this.translate.instant("SCHEDULER_ERROR_WHILE_DELETING_ROUTE"), 'Scheduler Details', 'top', ToastStyle.DANGER);
              }
              loader.dismiss();
            }
          )
        }
      }
    });
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closepage();
        break;
      case 'suggest_schedule':
        this.suggestSchedules();
        break;
      case 'route_plan_scrap':
        this.scrap();
        break;
      case 'route_plan_copy':
        this.copyRoutePlan();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  /* ====================================== */
  /* ====================================== */
  /* Opening popups and full page selection for various fields */
  /* ====================================== */
  /* ====================================== */

  /* Popover control for subject */
  openDescription(myEvent) {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;
    if (myEvent && myEvent.target && myEvent.target.value && this.selectedSchedule.name != myEvent.target.value) {
      this.trackingService.tracking('SchedulerDescriptionUpdated', TrackingEventNames.SCHEDULER)
      this.selectedSchedule.name = myEvent.target.value;
      this.schedulerService.areChangeSaved = false;
      this._initSectionHeaderDetails();
      this.initSchedulerDetailsPageTitle();
      this.cd.detectChanges();
      this.cd.markForCheck();
    }
    this.isClickedDescription = true;
  }

  /* Popover control for Duration */
  async openSchedulePeriod(e) {
    //alert("open schedulling period coming soon");
    let popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      componentProps:{ scheduledDuration: this.selectedDuration.id, field: 'SchedulerDuration' },
      event:e});
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      const data = obj.data;
      if (data) {
        this.trackingService.tracking('SchedulerPeriodUpdated', TrackingEventNames.SCHEDULER)
        const duration = this.schedulerService.getDurationNumber(data);
        // Get the UI string
        this.selectedDuration = this.schedulerService.getDurationString(duration);
        this.selectedSchedule.duration = duration + 'd';
        this.selectedSchedule.durationValue = duration;
        const end = moment(new Date(this.selectedSchedule.startdate)).add((duration - 1), 'd').toDate();
        this.selectedSchedule.enddate = new Date(end);
        this.endDate = moment(this.selectedSchedule.enddate).format();
        this.schedulerService.areChangeSaved = false;
        this._initSectionHeaderDetails();
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
    });
  }

  /* Popover control for Priority */
  async openPrioritySelection(e) {
    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;

    let popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      componentProps:{ priorityId: this.selectedSchedule.priority.id, field: 'SchedulerPrioritize' },
      event:e
    });
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      const data = obj.data;
      this.isClickedPriortize = true;
      if (data && this.selectedSchedule.priority != data) {
        this.selectedSchedule.priority = data;
        // Get the UI string
        this.schedulerService.priorityList.forEach(e => {
          if (e.id === data.id) {
            this.selectedPriority = e.value;
          }
        });
        this.schedulerService.areChangeSaved = false;
        this._initSectionHeaderDetails();
        this.cd.markForCheck();
      }
    });

  }

  openCustomerSegmentSelector(e) {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;
    this.schedulerService.setType(SchedulingPop.CS);
    this.schedulerService.setSelectionData(this.schedulerService.getArrayValue(SchedulingPop.CS));
    this.navService.pushWithPageTracking(SchedulerPopoverComponent, PageName.SchedulerPopoverComponent);
  }

  openAccountSelector(e) {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;

    this.schedulerService.setType(SchedulingPop.SA);
    this.accountService.selectedFor = AccountSelectedFor.SCHEDULER_SELECTOR;
    this.accountService.isSchedulerInvoked = true;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW }, PageName.AccountPageComponent);
  }

  openCallPlanSelector(e) {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;

    this.schedulerService.setType(SchedulingPop.CP);
    this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(res=>{
      if(res && res.raw && res.raw.length){
        var a = [];
        res.raw.map((c)=>{
            var id = a.findIndex(o=>o.cycleplanid==c.cycleplanid);
            c.customerName = c.contactFirstName + " " + c.contactLastName
            if(id>-1) {
              a[id].repPlans.push(c)
              a[id].actualCalls += c.indskr_actualcalls?c.indskr_actualcalls:0
              a[id].totalGoalCalls += c.indskr_hocalls?c.indskr_hocalls:0
              a[id].actualEmails += c.indskr_actualemails?c.indskr_actualemails:0
              a[id].totalGoalEmails += c.indskr_hoemails?c.indskr_hoemails:0
              a[id].allRepCallPlansApproved = a[id].allRepCallPlansApproved==true && c.statuscode == 2?true:false
            }
            else{
                a.push({'cycleplanid': c.cycleplanid,
                         'cycleplanname':c.cycleplanname,
                         'repPlans':[c],
                         'indskr_enddate':c.indskr_enddate,
                         'indskr_startdate':c.indskr_startdate,
                         'actualCalls':c.indskr_actualcalls || 0,
                         'totalGoalCalls':c.indskr_hocalls || 0,
                         'actualEmails':c.indskr_actualemails || 0,
                         'totalGoalEmails':c.indskr_hoemails || 0,
                         'allRepCallPlansApproved': c.statuscode == 2?true:false,
                         'productName':c.productid_Formatted || '',
                         'suggestionApprovalneeded': c.cycleplansuggestionapprovalneeded 

                        })
            }
        })
        this.callPlanService.segmentCallPlans = a
      }
      else this.callPlanService.segmentCallPlans = [];
      this.schedulerService.setSelectionData(this.schedulerService.getArrayValue(SchedulingPop.CP));
      this.navService.pushWithPageTracking(SchedulerPopoverComponent, PageName.SchedulerPopoverComponent);
    });
  }

  openBrandSelector() {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;

    this.schedulerService.setType(SchedulingPop.SB);
    this.schedulerService.setSelectionData(this.schedulerService.getArrayValue(SchedulingPop.SB));
    this.navService.pushWithPageTracking(SchedulerPopoverComponent, PageName.SchedulerPopoverComponent);
  }

  openScheduleZip(e) {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;

    this.schedulerService.setType(SchedulingPop.SZ);

    /*
      Case 1 - User simply selects a city and zipcode is not selected

      Case 2 - User selects city and a filtered value from zip

      Case 3 - User selects only the zip and leaves city empty

      Case 4 - User selects zip first and then selects a city or another filtered zip
    */
    //All zipcodes fetched
    let allPostalCodes: zipcode[] = this.schedulerService.getArrayValue(SchedulingPop.SZ);
    //No city selected so show all the available zip codes
    if (Array.isArray(this.selectedSchedule.cityList) && this.selectedSchedule.cityList.length === 0) {
      this.schedulerService.setSelectionData(allPostalCodes);
    }
    //Some city selection has been made by user
    else if (Array.isArray(this.selectedSchedule.cityList) && this.selectedSchedule.cityList.length) {

      let selectedPostalCodes: any[] = [];
      // list of zipcodes pertaining to selected city
      this.selectedSchedule.cityList.forEach(e => {
        selectedPostalCodes = selectedPostalCodes.concat(e.zipCodeList);
      });
      //since the list is of id, we fetch the objects
      let res: zipcode[] = [];
      selectedPostalCodes.forEach(e => {
        let zip: zipcode = allPostalCodes.find(z => z.id === e);
        if (zip) {
          let idx = res.findIndex(e => e.id === zip.id);
          if (idx === -1) {
            res.push(zip);
          }
        }
      })
      this.schedulerService.setSelectionData(res);
    }

    this.navService.pushWithPageTracking(SchedulerPopoverComponent, PageName.SchedulerPopoverComponent);
  }

  async openScheduleCity(e) {

    /* Omni-2957 */
    if (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) return;

    this.schedulerService.setType(SchedulingPop.SCi);

    let allCities: city[] = this.schedulerService.getArrayValue(SchedulingPop.SCi);
    //No zip selected
    if (Array.isArray(this.selectedSchedule.zipcodeList) && this.selectedSchedule.zipcodeList.length === 0) {
      this.schedulerService.setSelectionData(allCities);
    }
    //Some zip selection has been made by user
    else if (Array.isArray(this.selectedSchedule.zipcodeList) && this.selectedSchedule.zipcodeList.length) {

      let selectedCities: any[] = [];
      // list of zipcodes pertaining to selected city
      this.selectedSchedule.zipcodeList.forEach(e => {
        let currentCity: city = allCities.find(a => a.id === e.cityId)
        if (currentCity) {
          let idx = selectedCities.findIndex(e => e.id === currentCity.id);
          if (idx === -1) {
            selectedCities.push(currentCity);
          }
        }
      });

      this.schedulerService.setSelectionData(selectedCities);
    }

    let popover = await this.popoverCtrl.create({ component: SchedulerPopoverComponent, cssClass: 'scheduler-city-popover', event: e });
    popover.present();
    popover.onDidDismiss().then((data: any) => {
      this._initSectionHeaderDetails();
      this.cd.detectChanges();
    });

    //this.navService.pushWithPageTracking(SchedulerPopoverComponent, PageName.SchedulerPopoverComponent);
  }

  cancelEdit(e?: boolean): any {
    this.schedulerService.ifEditMode = !this.schedulerService.ifEditMode;
    let restoredValue: Scheduler = this.schedulerService.restoreData(this.staticSchedule, this.selectedSchedule);
    this.selectedSchedule = restoredValue;
    this.updateServiceSchedulerList();
    this.schedulerService.areChangeSaved = true;

    //Reset period
    this.selectedDuration = this.schedulerService.getDurationString(this.selectedSchedule.durationValue);

    //Reset priority
    if (this.selectedSchedule.priority) {
      if (Array.isArray(this.selectedSchedule.priority)) {
        if (this.selectedSchedule.priority.length > 0) {
          this.selectedPriority = this.selectedSchedule.priority[0];
        }
        else
          this.selectedPriority = this.translate.instant('SCHEDULER_SELECT_PRIORITY');
      }
      else
        this.selectedPriority = this.selectedSchedule.priority.value;
    }
    else {
      this.selectedPriority = this.translate.instant('SCHEDULER_SELECT_PRIORITY');
    }
    this._initSectionHeaderDetails();
    this.cd.detectChanges();
    this.cd.markForCheck();
    if (e) {
      this.schedulerService.setRestoreMap(false)
    };
  }

  closeWithExit() {
    //reset value to enable the new pattern to create a new pattern
    this.schedulerService.setSchedule(undefined);
    this.schedulerService.revertCount();
    this.schedulerService.areChangeSaved = true;
    this.closepage();
    this.trackingService.tracking('SchedulerCancelled', TrackingEventNames.SCHEDULER, null, true);
    // if (this.schedulerService.deleteSchedule(this.selectedSchedule)) {

    // }
  }

  async save() {
    if (this.schedulerService.isProcessing) return;
    if (!this.isDeviceOnline || this.deviceService.isOffline) return;
    if (!this.deviceService.isOffline) {
      this.trackingService.tracking('SchedulerPatternSaved', TrackingEventNames.SCHEDULER);
      const loader = await this.loadingCtrl.create();
      loader.present();
      this.schedulerService.isProcessing = true;
      let payload: schedulePayload = {
        indskr_duration: this.schedulerService.getDurationValue(this.selectedSchedule.startdate, this.selectedSchedule.enddate),
        statecode: 0,
        indskr_enddate: moment(this.selectedSchedule.enddate).format('YYYY-MM-DD'),
        indskr_startdate: moment(this.selectedSchedule.startdate).format('YYYY-MM-DD'),
        indskr_name: this.selectedSchedule.name,
        products: this.schedulerService.formatPayload(this.selectedSchedule.productsList, SchedulingPop.SB),
        customersegments: this.schedulerService.formatPayload(this.selectedSchedule.customersegmentsList, SchedulingPop.CS),
        prioritize: this.schedulerService.formatPayload(this.selectedSchedule.priority, SchedulingPop.SP),
        zip: this.schedulerService.formatPayload(this.selectedSchedule.zipcodeList, SchedulingPop.SZ),
        city: this.schedulerService.formatPayload(this.selectedSchedule.cityList, SchedulingPop.SCi),
        accounts: this.schedulerService.formatPayload(this.selectedSchedule.accountList, SchedulingPop.SA),
        cycleplans: this.schedulerService.formatPayload(this.selectedSchedule.cycleplansList, SchedulingPop.CP),
        indskr_externalid: this.selectedSchedule.ID
      };

      await this.schedulerDataService.createNewSchedule(payload).then(
        res => {
          if (res) {
            this.schedulerService.isProcessing = false;
            loader.dismiss();
            this.selectedSchedule = res;
            this.schedulerService.ifEditMode = false;
            this.schedulerService.isNewPattern = false;
            this.exit.emit(false);
            this._initSectionHeaderDetails();
            this.cd.detectChanges();
            this.cd.markForCheck();
          }
          else {
            this.schedulerService.isProcessing = false;
            loader.dismiss();
            this.notificationService.notify(this.translate.instant("SCHEDULER_ERROR_WHILE_CREATING_ROUTE"), 'Scheduler Details', 'top', ToastStyle.DANGER);
          }
        },
        err => {
          this.schedulerService.isProcessing = false;
          this.cd.detectChanges();
          this.cd.markForCheck();
          loader.dismiss();
          this.notificationService.notify(this.translate.instant("SCHEDULER_ERROR_WHILE_CREATING_ROUTE"), 'Scheduler Details', 'top', ToastStyle.DANGER);
        });

    }
    else
      return;
  }

  onUpdate() {
    if (this.schedulerService.isProcessing || this.schedulerService.areChangeSaved || (!this.isDeviceOnline || this.deviceService.isOffline)) return;
    this.update();
  }

  async update(isLocalChange?: boolean) {
    if (this.schedulerService.isNewPattern) return
    this.trackingService.tracking('SchedulerPatternUpdate', TrackingEventNames.SCHEDULER);
    if (!this.deviceService.isOffline) {
      const loader = await this.loadingCtrl.create();
      loader.present();
      this.schedulerService.isProcessing = true;

      let payload: schedulePayload = {
        indskr_duration: this.schedulerService.getDurationValue(this.selectedSchedule.startdate, this.selectedSchedule.enddate),
        statecode: 0,
        indskr_enddate: moment(this.selectedSchedule.enddate).format('YYYY-MM-DD'),
        indskr_startdate: moment(this.selectedSchedule.startdate).format('YYYY-MM-DD'),
        indskr_name: this.selectedSchedule.name,
        products: this.schedulerService.formatPayload(this.selectedSchedule.productsList, SchedulingPop.SB),
        customersegments: this.schedulerService.formatPayload(this.selectedSchedule.customersegmentsList, SchedulingPop.CS),
        prioritize: this.schedulerService.formatPayload(this.selectedSchedule.priority, SchedulingPop.SP),
        zip: this.schedulerService.formatPayload(this.selectedSchedule.zipcodeList, SchedulingPop.SZ),
        city: this.schedulerService.formatPayload(this.selectedSchedule.cityList, SchedulingPop.SCi),
        accounts: this.schedulerService.formatPayload(this.selectedSchedule.accountList, SchedulingPop.SA),
        cycleplans: this.schedulerService.formatPayload(this.selectedSchedule.cycleplansList, SchedulingPop.CP),
      };

      this.schedulerDataService.updateSchedule(payload, this.selectedSchedule.ID).subscribe(
        res => {
          console.log(res);
          this.updateServiceSchedulerList();
          this.schedulerService.isProcessing = false;
          this.schedulerService.areChangeSaved = true;
          this._initSectionHeaderDetails();
          loader.dismiss();
          if (!isLocalChange) {
            this.schedulerService.ifEditMode = !this.schedulerService.ifEditMode;
          }
          if (this.schedulerService.areChangeSaved) {
            Object.assign(this.staticSchedule, res);
          }
          this.exit.emit(false);
          this.cd.detectChanges();
          this.cd.markForCheck();
        },
        err => {
          this.schedulerService.isProcessing = false;
          this.schedulerService.areChangeSaved = false;
          this._initSectionHeaderDetails();
          loader.dismiss();
          console.error("an error occured please handle");
        });
    }
    this.footerService.initButtons(FooterViews.SchedulingDetails);
  }

  formattedDate(value: Date): string {
      return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  /* ====================================== */
  /* ====================================== */
  /* Logic For Display of customized string */
  /* ====================================== */
  /* ====================================== */
  get createCallPlan(): string {
    let resultC: string;
    let l: number = this.selectedSchedule.cycleplansList.length;
    if (l) {
      if (l === 1) {
        resultC = this.selectedSchedule.cycleplansList[0].cycleplanname;
      }
      else {
        resultC = this.selectedSchedule.cycleplansList[0].cycleplanname + " +" + (l - 1);
      }
    }
    return resultC;
  }

  get createBrand(): string {
    let resultB: string;
    let l: number = this.selectedSchedule.productsList.length;
    if (l) {
      if (l === 1) {
        resultB = this.selectedSchedule.productsList[0].value;
      }
      else {
        resultB = this.selectedSchedule.productsList[0].value + " +" + (l - 1);
      }
    }
    return resultB;
  }

  get createCustomerSegement(): string {
    let result: string;
    let l: number = this.selectedSchedule.customersegmentsList.length;
    if (l) {
      if (l === 1) {
        result = this.selectedSchedule.customersegmentsList[0].value;
      }
      else {
        result = this.selectedSchedule.customersegmentsList[0].value + " +" + (l - 1);
      }
    }
    return result;
  }

  get createAccount(): string {
    let result: string;
    let l: number = this.selectedSchedule.accountList.length;
    if (l) {
      if (l === 1) {
        result = this.selectedSchedule.accountList[0].value;
      }
      else {
        result = this.selectedSchedule.accountList[0].value + " +" + (l - 1);
      }
    }
    return result;
  }

  get createZipcode(): string {
    let result: string;
    let l: number = this.selectedSchedule.zipcodeList.length;
    if (l) {
      if (l === 1) {
        result = this.selectedSchedule.zipcodeList[0].value;
      }
      else {
        result = this.selectedSchedule.zipcodeList[0].value + " +" + (l - 1);
      }
    }
    return result;
  }

  get createCity(): string {
    let result: string;
    let l: number = this.selectedSchedule.cityList.length;
    if (l) {
      if (l === 1) {
        result = this.selectedSchedule.cityList[0].value;
      }
      else {
        result = this.selectedSchedule.cityList[0].value + " +" + (l - 1);
      }
    }
    return result;
  }

  /* ====================================== */
  /* ====================================== */
  /* Post Selection Methods for Edit Mode */
  /* ====================================== */
  /* ====================================== */
  updateSelectedResult(type: string, res: any[]) {
    this.uiService.activeView = 'Scheduler';
    switch (type) {
      case SchedulingPop.CP:
        this.updateCallPlan(res);
        break;

      case SchedulingPop.CS:
        this.updateCustomerSegment(res);
        break;

      case SchedulingPop.SA:
        this.updateAccount(res);
        break;

      case SchedulingPop.SB:
        this.updateBrand(res);
        break;

      case SchedulingPop.SCi:
        this.updateCity(res);
        break;

      case SchedulingPop.SZ:
        this.updateZip(res);
        break;

      default:
        //Dont perform any action
        break;

    }
  }

  updateCallPlan(e: any[]) {
    this.cd.detectChanges();
    this.selectedSchedule.cycleplansList = e;
    this.selectedSchedule.cycleplanString = this.createCallPlan;
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.trackingService.tracking('SchedulerCallPlansSelected', TrackingEventNames.SCHEDULER);
  }

  updateCustomerSegment(e: any[]) {
    this.cd.detectChanges();
    this.selectedSchedule.customersegmentsList = e;
    this.selectedSchedule.customerSegmentString = this.createCustomerSegement;
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.trackingService.tracking('SchedulerCustomerSegmentSelected', TrackingEventNames.SCHEDULER);
  }

  updateZip(e: any[]) {
    this.cd.detectChanges();
    this.selectedSchedule.zipcodeList = e;
    this.selectedSchedule.zipString = this.createZipcode;
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.trackingService.tracking('SchedulerZIPSelected', TrackingEventNames.SCHEDULER);
  }

  updateCity(e: any[]) {
    this.cd.detectChanges();
    this.selectedSchedule.cityList = e;
    this.selectedSchedule.cityString = this.createCity;
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.trackingService.tracking('SchedulerCitySelected', TrackingEventNames.SCHEDULER);
  }

  updateAccount(e: any[]) {
    if (e) {
      this.cd.detectChanges();
      this.selectedSchedule.accountList = e;
      this.selectedSchedule.accountString = this.createAccount;
      this.accountService.isSchedulerInvoked = false;
      this.schedulerService.areChangeSaved = false;
      this.schedulerService.setSchedule(this.selectedSchedule);
      this.cd.detectChanges();
      this.cd.markForCheck();
      this.trackingService.tracking('SchedulerAccountsSelected', TrackingEventNames.SCHEDULER);
    }
    else {
      /* Not sure someone messed up my code */
      /*       this.uiService.activeView = 'Scheduler';
            this.activityService.selectedActivity = this.selectedSchedule; */
    }
  }

  updateBrand(e: any[]) {
    this.cd.detectChanges();
    this.selectedSchedule.productsList = e;
    this.selectedSchedule.productString = this.createBrand;
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.trackingService.tracking('SchedulerProductsSelected', TrackingEventNames.SCHEDULER);
  }

  updateServiceSchedulerList() {
    this.cd.detectChanges();
    this.schedulerService.updateSchedulerPattern(this.selectedSchedule);
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  resetSelections(): any {
    this.schedulerService.setSelectionResult(undefined);
    this.schedulerService.setType(undefined);
    this.schedulerService.setSelectionData(undefined);
  }

  async suggestSchedules() {
    if (!this.isDeviceOnline || this.deviceService.isOffline) return;
    this.trackingService.tracking('SchedulerSuggestSchedule', TrackingEventNames.SCHEDULER);
    if (!this.deviceService.isOffline && this.isPatternSuggestable) {
      this.trackingService.tracking('Populate Matched Contacts', TrackingEventNames.SCHEDULER);
      const loader = await this.loadingCtrl.create();
      loader.present();
      this.schedulerService.isProcessing = true;
      this.schedulerDataService.fetchMatchedContacts(this.selectedSchedule).subscribe(
        res => {
          this.schedulerService.isProcessing = false;
          let contactsToShow: Contact[] = [];
          if (res) {

            //Mapping the matched contacts
            if (res.hasOwnProperty('matched') && res.matched && Array.isArray(res.matched)) {
              let matchedContactList: Contact[] = [];
              if (res.matched.length === 0) {
                this.notificationService.notify(this.translate.instant("SCHEDULER_NO_MATCHED_CUSTOMER_WITH_TEXT"), 'Scheduler Details', 'top', ToastStyle.INFO);
              }
              res.matched.forEach(e => {
                let obj: Contact = this.contactService.contacts.find(vc => vc.ID === e.contactid);
                if (obj) {
                  obj.schedulerFrequency = e.frequency;
                  matchedContactList.push(obj);
                }
              });

              loader.dismiss();
              this.activityService.selectedActivity['contacts'] = matchedContactList;
              this.schedulerService.matchedContacts = matchedContactList;
              this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
              this.contactService.isSchedulerInvoked = true;
            }
            else {
              this.schedulerService.matchedContacts = undefined;
            }

            //Mapping the other contacts
            //Should receive this data only if the priority selected is CPO
            if (res.hasOwnProperty('other') && res.other && Array.isArray(res.other)) {
              let otherContactList: Contact[] = [];

              res.other.forEach(e => {
                let obj: Contact = this.contactService.contacts.find(vc => vc.ID === e.contactid);
                if (obj) {
                  obj.schedulerFrequency = e.frequency;
                  otherContactList.push(obj);
                }
              });

              loader.dismiss();

              this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
              this.contactService.isSchedulerInvoked = true;
              this.schedulerService.otherContacts = otherContactList;
            }
            else {
              this.schedulerService.otherContacts = undefined;
            }

            /* As per the CPO requirement if we receive any data in other contacts node then dont show
              all contacts and instead show the combination of other and matched contacts
            */
            if (this.schedulerService.otherContacts) {
              //Since the value is not undefined that mean CPO is selected and we need to pass this data
              contactsToShow = this.schedulerService.otherContacts.concat(this.schedulerService.matchedContacts);
            }
            else {
              //other is not defined , non-CPO selection, display all contacts mapped.
              contactsToShow = this.contactService.contacts;
            }

            //display value setting for contact list component to be fed
            this.contactService.schedulerDisplayContacts = contactsToShow;
            this.contactService.accessedContactListFrom = PageName.SchedulerDetailComponent;
            this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
          }
        },
        err => {
          this.schedulerService.isProcessing = false;
          this.notificationService.notify(this.translate.instant("SCHEDULER_UNABLE_TO_COMPLETE_ACTION"), 'Scheduler Details', 'top', ToastStyle.DANGER);
          // this.activityService.selectedActivity['contacts'] = [];
          // this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
          // this.contactService.isSchedulerInvoked = true;
          // this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
          loader.dismiss();
        }
      );
    }
    else
      if (!this.isPatternSuggestable) {
        this.notificationService.notify(this.translate.instant("SCHEDULER_START_DATE_IS_PAST_ROUTE"), 'Scheduler Details', 'top', ToastStyle.DANGER);
      }
    return;
  }

  public get isPatternSuggestable(): boolean {
    let patternTime: number = moment(this.selectedSchedule.startdate).set('hour', 0).set('minute', 0).set('second', 0).toDate().getTime();
    let actualTime: number = moment(new Date()).set('hour', 0).set('minute', 0).set('second', 0).toDate().getTime();
    let diff = actualTime - patternTime;
    //buffer for 5 mins
    if (diff <= 5000) {
      return true;
    }
    else
      return actualTime < patternTime;
  }

  public getDescriptionFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: (this.selectedSchedule && this.selectedSchedule.name) ? this.selectedSchedule.name : this.translate.instant('SCHEDULE_NEW_PLAN'),
      inputValue: (this.selectedSchedule && this.selectedSchedule.name) ? this.selectedSchedule.name : this.translate.instant('SCHEDULE_NEW_PLAN'),
      id: 'description-field',
      isReadOnly: false,
      isDisabled: this.deviceService.isOffline,
      placeholderLabel: this.translate.instant('DESCRIPTION'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedDescription && _.isEmpty(this.selectedSchedule.name),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCityFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CITIES'),
      inputText: (this.selectedSchedule ? (this.selectedSchedule.cityString ? this.selectedSchedule.cityString : '') : ''),
      id: 'city-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      customPlaceholderLabel: this.translate.instant('SELECT_CITIES'),
      showArrow: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getSchedulingPeriodFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SCHEDULING_PERIOD'),
      inputText: this.selectedDuration ? this.selectedDuration.value : '',
      id: 'scheduling-period-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      showArrow: true,
      customPlaceholderLabel: this.translate.instant('SCHEDULING_PERIOD'),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getPostalCodeFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ZIP_POSTAL_CODES'),
      inputText: (this.selectedSchedule ? (this.selectedSchedule.zipString ? this.selectedSchedule.zipString : '') : ''),
      id: 'postal-code-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      customPlaceholderLabel: this.translate.instant('SELECT_ZIP_POSTAL_CODE'),
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCustomerSegmentFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CUSTOMER_SEGMENT_WITH_TEXT', { text: this.globalCustomerText }),
      inputText: (this.selectedSchedule ? (this.selectedSchedule.customerSegmentString ? this.selectedSchedule.customerSegmentString : '') : ''),
      id: 'customer-segment-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      customPlaceholderLabel: this.translate.instant('SELECT_CUSTOMER_SEGMENT_WITH_TEXT', { globalCustomertext: this.globalCustomerText }),
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAccountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS'),
      inputText: (this.selectedSchedule ? (this.selectedSchedule.accountString ? this.selectedSchedule.accountString : '') : ''),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      customPlaceholderLabel: this.translate.instant('SELECT_ACCOUNTS'),
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCallplanFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CALL_PLANS'),
      inputText: (this.selectedSchedule ? (this.selectedSchedule.cycleplanString ? this.selectedSchedule.cycleplanString : '') : ''),
      id: 'callplan-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      customPlaceholderLabel: this.translate.instant('SELECT_CALL_PLANS'),
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCTS'),
      inputText: (this.selectedSchedule ? (this.selectedSchedule.productString ? this.selectedSchedule.productString : '') : ''),
      id: 'product-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCTS'),
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getPriortizeFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRIORITIZE'),
      inputText: (this.selectedSchedule ? (this.selectedPriority ? this.selectedPriority : '') : ''),
      id: 'priortize-field',
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)),
      placeholderLabel: this.translate.instant('PRIORITIZE'),
      showArrow: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedPriortize && _.isEmpty(this.selectedPriority),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'description-field':
          if (eventName && (eventName == 'input_value_change' || eventName == 'input_value_confirm')) {
            this.openDescription(event);
          }
          break;
        case 'city-field':
          this.openScheduleCity(event);
          break;
        case 'scheduling-period-field':
          this.openSchedulePeriod(event);
          break;
        case 'postal-code-field':
          this.openScheduleZip(event);
          break;
        case 'customer-segment-field':
          this.openCustomerSegmentSelector(event);
          break;
        case 'account-field':
          this.openAccountSelector(event);
          break;
        case 'callplan-field':
          this.openCallPlanSelector(event);
          break;
        case 'product-field':
          this.openBrandSelector();
          break;
        case 'priortize-field':
          this.openPrioritySelection(event);
          break;
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  copyRoutePlan() {
    if(this.deviceService.isOffline) return;
    this.trackingService.tracking('Copy Scheduling Route', TrackingEventNames.SCHEDULER);
    this.alertService.showAlert({
      title: this.translate.instant('SCHEDULER_COPY_ROUTE'),
      message: this.translate.instant('SCHEDULER_COPY_ALERT_ROUTE')}, this.translate.instant('COPY')
    ).then (async res => {
      if(res.role == "ok") {
        await this.uiService.displayLoader();
        //No Internet revoke request and update the user
        if (this.deviceService.isOffline) {
          //window.alert("Unable to performed selected activity in offline mode, please turn on the internet connection and try again.");
        }
        else {
          let selectedSchedule: Scheduler = this.selectedSchedule;
          let duration = Number(selectedSchedule.durationValue); // OMNI-4178: Copied pattern duration should be same as original pattern
          let startDate= moment().format('YYYY-MM-DD');
          let endDate = moment().add((duration - 1 ), "d").format('YYYY-MM-DD')
          let objSchedule: schedulePayload = {
            indskr_duration: duration,
            statecode: 0,
            indskr_enddate: endDate,
            indskr_startdate: startDate,
            indskr_name: `${this.selectedSchedule.name} ${this.translate.instant("COPY")}`,
            products: this.schedulerService.formatPayload(this.selectedSchedule.productsList, SchedulingPop.SB),
            customersegments: this.schedulerService.formatPayload(this.selectedSchedule.customersegmentsList, SchedulingPop.CS),
            prioritize: this.schedulerService.formatPayload(this.selectedSchedule.priority, SchedulingPop.SP),
            zip: this.schedulerService.formatPayload(this.selectedSchedule.zipcodeList, SchedulingPop.SZ),
            city: this.schedulerService.formatPayload(this.selectedSchedule.cityList, SchedulingPop.SCi),
            accounts: this.schedulerService.formatPayload(this.selectedSchedule.accountList, SchedulingPop.SA),
            cycleplans: this.schedulerService.formatPayload(this.selectedSchedule.cycleplansList, SchedulingPop.CP),
            indskr_externalid: "offline_scheduler_" + (new Date().valueOf()) + (Math.floor(100000 + Math.random() * 900000))
          };
          await this.schedulerDataService.createNewSchedule(objSchedule).then(
            async (res) => {
              console.log(res);
              this.cd.detectChanges();
              this.cd.markForCheck();
              await this.uiService.dismissLoader();
            },
            async (err) => {
              console.log("error handling stuffs here");
              this.notificationService.notify(this.translate.instant("SCHEDULER_ERROR_WHILE_COPYING_ROUTE"),'Scheduler Details','top',ToastStyle.DANGER);
              await this.uiService.dismissLoader();
            }
          )
        }
      }
    });
  }

  private _initDateTimeFormFields() {
    const dateTimeValue = this.getSchedulerDateTimeText();
    if (dateTimeValue) {
      this.startDate = dateTimeValue.startDateTime;
      this.endDate = dateTimeValue.endDateTime;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: dateTimeValue.startDate,
      startDateTimeValue: this.startDate,
      endDateTimeValue: this.endDate,
      fromViewPage: CurViewPageType.Scheduler,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) || this.deviceService.isOffline),
      showArrow: true,
      showArrowOffline: true,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: dateTimeValue.endDate,
      startDateTimeValue: this.startDate,
      endDateTimeValue: this.endDate,
      fromViewPage: CurViewPageType.Scheduler,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: (!this.isDeviceOnline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) || this.deviceService.isOffline),
      showArrow: true,
      showArrowOffline: true,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };    
  }
  getSchedulerDateTimeText = (): any => {
    const startDateTimeValue: Date = new Date(this.selectedSchedule.startdate);
    const endDateTimeValue: Date = new Date(this.selectedSchedule.enddate);
    const schedulerStartDay = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const schedulerEndDay = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);

    let dateTimeValue: any = {
      startDateTime: '',
      startDate: '',
      endDateTime: '',
      endDate: '',
    };
    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: schedulerStartDay,
      endDateTime: endDateTimeValue,
      endDate: schedulerEndDay,
    };
    return dateTimeValue;
  }
  async openStartDatePicker(myEvent) {
    if (this.deviceService.isOffline) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.Scheduler,
          startDateTimeValue: this.selectedSchedule.startdate,
          endDateTimeValue: this.selectedSchedule.enddate
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
          this.startDate = data.data.startTime;
          const diffDays = differenceInCalendarDays(this.selectedSchedule.startdate, this.startDate);
          if (diffDays == 0) return;
          this.trackingService.tracking('SchedulerStartDateUpdated', TrackingEventNames.SCHEDULER);
          this.selectedSchedule.startdate = new Date(this.startDate);
          this.endDate = data.data.endTime;
          this.selectedSchedule.enddate = new Date(this.endDate);
          this.selectedSchedule.durationValue = this.schedulerService.getDurationValue(this.selectedSchedule.startdate, this.selectedSchedule.enddate);
          this.selectedDuration = this.schedulerService.getDurationString(this.selectedSchedule.durationValue);
          this.schedulerService.areChangeSaved = false;
          this._initSectionHeaderDetails();
          this.cd.detectChanges();
          this.cd.markForCheck();
      }
    });
    popover.present();
  }

  async openEndDatePicker(myEvent) {
    if (this.deviceService.isOffline) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.Scheduler,
          startDateTimeValue: this.selectedSchedule.startdate,
          endDateTimeValue: this.selectedSchedule.enddate
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        this.endDate = data.data.endTime;
        const diffDays = differenceInCalendarDays(this.selectedSchedule.enddate, this.endDate);
        if (diffDays == 0) return;
        this.selectedSchedule.enddate = new Date(this.endDate);
        this.selectedSchedule.durationValue = this.schedulerService.getDurationValue(this.selectedSchedule.startdate, this.selectedSchedule.enddate);;
        this.selectedDuration = this.schedulerService.getDurationString(this.selectedSchedule.durationValue);
        this.schedulerService.areChangeSaved = false;
        this._initSectionHeaderDetails();
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
    });
    popover.present();
  }

}

//this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity)
