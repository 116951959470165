import { Brand } from "../brand/brand.class";
import { CustomerSegment } from "../customer-segment/customer-segment.class";
import { prioritize } from "../scheduler/scheduler-prioritize.class";
import { city } from "../scheduler/scheduler-city.class";
import { zipcode } from "../scheduler/scheduler-zip.class";
import { Account } from "../account/account.class";
import { Contact } from "../contact/contact.class";
import * as moment from "moment";

export enum SchedulingPop {
  SD = "SchedulerDuration",
  SP = "SchedulerPrioritize",
  CS = "SchedulerCustomerSegment",
  SA = "SchedulerAccount",
  CP = "SchedulerCallPlan",
  SB = "SchedulerBrand",
  SZ = "SchedulerZip",
  SCi = "SchedulerCity",
  //SS = "SchedulingSubject",
}


export class Scheduler {

    public duration: string;
    public durationValue: number;
    public state: number;
    public name: string;
    public createdon: Date;
    public ID: string;
    public isNewScheduler: boolean = false;

    public enddate: Date;
    public startdate: Date;

    public productsList: Brand[];
    public productString: string;

    public customersegmentsList: CustomerSegment[];
    public customerSegmentString: string;

    public priority: prioritize;

    public cycleplansList: any[];
    public cycleplanString: string;

    public cityList: city[];
    public cityString: string;

    public zipcodeList: zipcode[];
    public zipString: string;

    public accountList: Account[];
    public accountString: string;

    public contacts: Contact[] = [];

    public contactList: any = { matched: [], other: [] };

    constructor(
        raw: rawScheduler,
        private systemUserData: any
    ) {
        this.durationValue = raw.indskr_duration;
        this.state = raw.statecode;
        this.name = raw.indskr_name;
        this.createdon = new Date(raw.createdon);
        this.ID = raw.indskr_schedulingpatternid;
        this.enddate = new Date(raw.indskr_enddate);
        this.startdate = new Date(raw.indskr_startdate);
        this.productsList = this.getProduct(raw.products);
        this.productString = this.getString(this.productsList, SchedulingPop.SB);
        this.customersegmentsList = this.getCustomerSegment(raw.customersegments);
        this.customerSegmentString = this.getString(this.customersegmentsList, SchedulingPop.CS);
        this.priority = this.getPriority(raw.prioritize);
        this.cycleplansList = this.getCallPlanDetails(raw.cycleplans);
        this.cycleplanString = this.getString(this.cycleplansList, SchedulingPop.CP);
        this.accountList = this.getAccount(raw.accounts);
        this.accountString = this.getString(this.accountList, SchedulingPop.SA);
        this.cityList = this.getCity(raw.cities);
        this.cityString = this.getString(this.cityList, SchedulingPop.SCi);
        this.zipcodeList = this.getZipCode(raw.postalCodes);
        this.zipString = this.getString(this.zipcodeList, SchedulingPop.SZ);
        this.duration = this.generateDuration;
    }


    private getPriority(raw: any): prioritize {

        //got an array as response from dynamics or user then select its value.
        if (raw && Array.isArray(raw) && raw.length) {
            let prioritize: prioritize = this.systemUserData["userPriorities"].find(pr => pr.id == raw[0]);
            return prioritize;
        }
        //else return the first value from the priority list
        else
            return this.systemUserData["userPriorities"][1]; // position chnage to get Accounts and Segements as Customize is coming as first option
    }

    private getCity(raw: string[]): city[] {
        let citiesList: city[] = [];
        raw.map((e: string) => {
            let city: city = this.systemUserData["userCitites"].find(pr => pr.id == e);
            if (city) {
                citiesList.push(city);
            }
        });
        return citiesList;
    }

    private getZipCode(raw: string[]): zipcode[] {
        let zipList: zipcode[] = [];
        raw.map((e: string) => {
            let zipcode: zipcode = this.systemUserData["userPostalCodes"].find(pr => pr.id == e);
            if (zipcode) {
                zipList.push(zipcode);
            }
        });
        return zipList;
    }

    private getProduct(productId: string[]): Brand[] {
        let productList: Brand[] = [];
        productId.map((e: any) => {
            let product: Brand = this.systemUserData["userProducts"].find(b => b.ID === e);
            if (product) {
                productList.push(product);
            }
        });
        return productList;
    }

    private getCallPlanDetails(raw: string[]){
        let callPlanList: any[] = [];
        raw.map((e: any) => {
            let callPlan = this.systemUserData["userCallPlans"].find(c => c.cycleplanid === e);
            if (callPlan) {
                callPlanList.push(callPlan);
            }
        });
        return callPlanList;
    }

    private getCustomerSegment(raw: string[]): CustomerSegment[] {
        let customerSegmentList: CustomerSegment[] = [];
        raw.map((e: any) => {
            let customerSegment: CustomerSegment = this.systemUserData["userCustomerSegments"].find(cs => cs.id == e);
            if (customerSegment) {
                customerSegmentList.push(customerSegment);
            }
        });
        return customerSegmentList;
    }

    private getAccount(raw: string[]): Account[] {
        let accountList: Account[] = [];
        raw.map((e: any) => {
            let account: Account = this.systemUserData["userAccounts"].find(ac => ac.id == e);
            if (account) {
                accountList.push(account);
            }
        });
        return accountList;
    }

    private get generateDuration(): string {
        let duration: string = "d";
        let num: number = moment(this.enddate).diff(moment(this.startdate), 'days') > 0 ? (moment(this.enddate).diff(moment(this.startdate), 'days') + 1) : 1;
        // if (num > 4) {
        //     return 4 + duration;
        // }
        // else
        return num + duration;
    }

    private getString(data: any[], type: string): string {
        let result: string;
        let l: number = data.length;

        if (data) {
            if (l === 1 && type != SchedulingPop.CP) {
                result = data[0].value;
            }
            else if(l === 1 && type == SchedulingPop.CP){
              result = data[0].cycleplanname;
            }
            else if (l > 1) {
                result = data[0].value + " +" + (l - 1);
            }
        }

        return result;
    }

    public toIdArray(value: any[], key?: string): Array<Object> {
        //if some value is set then use it else default value is id
        let id: string = key ? key : 'id';
        let response: any[] = [];
        //null pointer check
        if(value) {
            value.forEach(ele => {
                response.push(ele[id]);
            });
        }

        return response;
    }

    public get contactFetchDTO(): IContactFetchPayload {
        let obj: IContactFetchPayload = {
            "startDate": moment(this.startdate).format("YYYY-MM-DD"),
            "days": moment(this.enddate).diff(moment(this.startdate), 'days') > 0 ? (moment(this.enddate).diff(moment(this.startdate), 'days') + 1) : 1,
            "priority": this.priority ? this.priority.value : '',
            "callPlans": this.toIdArray(this.cycleplansList, "cycleplanid"),
            "products": this.toIdArray(this.productsList, "ID"),
            "segments": this.toIdArray(this.customersegmentsList),
            "accounts": this.toIdArray(this.accountList),
            "cities": this.toIdArray(this.cityList),
            "postalcodes": this.toIdArray(this.zipcodeList),
        };
        return obj;
    }

    public get suggestMeeetingDTO(): IsuggestMeeetingPayload {
        let obj: IsuggestMeeetingPayload = {
            "startDate": moment(this.startdate).format("YYYY-MM-DD"),
            "days": moment(this.enddate).diff(moment(this.startdate), 'days') > 0 ? (moment(this.enddate).diff(moment(this.startdate), 'days') + 1) : 1,
            "timezonecode": "190",
            "priority": this.priority ? this.priority.value : '',
            "customers": {
                "matched": this.toIdArray(this.contactList.matched, "ID"),
                "other": this.toIdArray(this.contactList.other, "ID")
            }
        };
        return obj;
    }
}


interface IContactFetchPayload {
    startDate: string;
    days: number;
    priority: string;
    callPlans: any[];
    products: any[];
    segments: any[];
    accounts: any[];
    cities: any[];
    postalcodes: any[];
    timezonecode?: string;
}
export interface rawScheduler {
    indskr_duration: number;
    statecode: number;
    indskr_name: string;
    createdon: number;
    indskr_schedulingpatternid: string;
    indskr_enddate: number;
    indskr_startdate: number;
    cycleplans: string[];
    customersegments: string[];
    products: string[];
    accounts: string[];
    cities: string[];
    postalCodes: any[];
    prioritize: any[];
}

export interface schedulePayload {
    indskr_duration: number;
    statecode: number;
    indskr_enddate: string;
    indskr_startdate: string;
    indskr_name: string;
    products: any[];
    customersegments: any[];
    cycleplans: any[];
    accounts: any[];
    city: any[];
    zip: any[];
    prioritize: any;
    indskr_externalid?: string;
}

interface IsuggestMeeetingPayload {
    startDate: string;
    days: number;
    customers: any;
    timezonecode?: string,
    priority: string,
}
