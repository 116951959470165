import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GPSApprovalActivity } from '@omni/classes/approvals/gps-approval-activity.class';
import { AppealService } from '@omni/services/appeal/appeal.service';

@Component({
  selector: 'app-approval-list-item',
  templateUrl: './approval-list-item.component.html',
  styleUrls: ['./approval-list-item.component.scss'],
})
export class ApprovalListItemComponent implements OnInit {

  @Input() approval: GPSApprovalActivity;
  @Input() selectedMode;
  constructor(public translate: TranslateService,
    public appealService: AppealService) { }

  ngOnInit() {
    // console.log(this.approval);
  }




}
