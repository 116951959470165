import { Component, Output, EventEmitter } from '@angular/core';
import { VideoSettingsComponent } from '../video-settings/video-settings';
import { UIService } from '../../../services/ui/ui.service';

/**
 * Generated class for the SettingsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'settings',
  templateUrl: 'settings.html',
  styleUrls:['settings.scss']
})
export class SettingsComponent {

  @Output()
  onSettingClick = new EventEmitter();
  public showVideoDetails = false;
  constructor(public uiService:UIService) {

  }
  /**
   * Opens child setting screens
   * @param value
   */
  openSettingsDetail(value:string) {
    switch(value){
      case 'Settings_Language':
        //this.onSettingClick.emit(value);
        this.uiService.showNewActivity = false;
        this.uiService.activeView = "Settings_Language";
        break;
      default:
          break;
    }
  }
}
