import { TrackService, TrackingEventNames } from './../../../services/logging/tracking.service';
import { IndRightpaneReadonlyComponent } from './../../shared/ind-rightpane-readonly/ind-rightpane-readonly';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { Agreement } from './../../../classes/opportunity-management/opportunity.class';
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { IndFormFieldViewDataModel } from "../../../models/indFormFieldDataModel";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { Opportunity } from "../../../classes/opportunity-management/opportunity.class";
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device/device.service';

@Component({
    selector: 'opportunity-agreements',
    templateUrl: 'opportunity-agreements.html',
  styleUrls:['opportunity-agreements.scss']
})
export class OpportunityAgreementsComponent {

    public isLayoverPushView:boolean = false;
    public layoverViewPushedFrom:string = '';
    public allAgreementsHeader:IndSectionHeaderViewDataModel;
    public opportunityAgreements:Array<MainCardViewDataModel> = [];
    public selectedOpportunity: Opportunity;
    public applicableAgreements: Agreement[] = [];
    public noAgreements:string;
    @Input() from:any
    @Input() opportunity:any
    @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;
    constructor(
        public opportunityService: OpportunityManagementService,
        public navService: NavigationService,
        private translate: TranslateService,
        public device: DeviceService,
        private trackingService: TrackService,
    ) {
      this.noAgreements=this.translate.instant('NO_AGREEMENTS');
    }

    ngOnInit(){
      if (this.from) {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = this.from;
      }
      if (this.opportunity) {
        this.selectedOpportunity = this.opportunity;
      }
      this.initOpportunityAgreementsFormFields();

    }

    ngAfterViewInit(){
      if(this.scrollTop){
        setTimeout(() => {
          this.scrollTop.nativeElement.scrollIntoView(false);
        }, 200);
      }
    }
    public initOpportunityAgreementsFormFields(){
        if(!this.opportunityService.agreements) return;
        this.applicableAgreements = this.opportunityService.agreements.filter(a=> {
          return a.opportunityID==this.selectedOpportunity.ID && a.state == 0
        })
        this.opportunityAgreements = this.applicableAgreements.map((a)=>{
          let agreement: MainCardViewDataModel = {
            id: a.agreementID,
            fixedHeight: true,
            primaryTextRight: a.agreementName,
            showArrow: true,
            arrowType:'chevron-forward-outline',
            clickHandler: (id)=>{
              this.goToAgreementDetails(id);
            }
          }
          return agreement;
        })

      if(this.applicableAgreements && this.applicableAgreements.length != 0){
        this.allAgreementsHeader = {
          id: 'all-agreements-header',
          title: this.translate.instant('ALL_AGREEMENTS')+' ('+this.opportunityAgreements.length+')',
          controls: [],
        };
      }
    }
  goToAgreementDetails(id: string) {
    this.trackingService.tracking('SelectAgreement', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let selectedAgreement = this.applicableAgreements.find(a=>a.agreementID==id);
    let type = selectedAgreement.agreementURL.endsWith('.pdf')?'pdf':(selectedAgreement.agreementURL.endsWith('.doc')||selectedAgreement.agreementURL.endsWith('.docx'))?'doc':'unknown';
    let webResource = {url: selectedAgreement.agreementURL, type: type, name:selectedAgreement.agreementName}
    this.navService.pushChildNavPageWithPageTracking(IndRightpaneReadonlyComponent, PageName.IndRightpaneReadonlyComponent,
                                                    PageName.OpportunityManagementPage, {viewHeader:selectedAgreement.agreementName,webResource:webResource})
  }

}
