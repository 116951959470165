import { IShipmentReason } from './../../interfaces/allocation/allocation.shared.interface';
import { uniqBy } from 'lodash';
import { AllocationFeatureService } from './allocation.feature.service';
import { IAllocationTransfer, IAllocationTransferCreateResponseDTO } from './../../interfaces/allocation/allocation-transfer.interface';
import { Injectable, Injector } from '@angular/core';
import { DiskService } from "../disk/disk.service";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { AllocationTransferForm } from "../../classes/sample/allocation-transfer.class";
import { Subject } from "rxjs";
import { AuthenticationService } from "../authentication.service";
import { SampleService } from "./sample.service";
import { Lot } from "../../classes/sample/lot.class";
import { IrawAccompainedUser } from "../../classes/activity/accompained-user.class";
import { SelectListData } from "../../components/popover/popover";
import { AllocationAdjustService } from './allocation-adjust.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { isWithinRange } from "date-fns";
import { convertTimestampStringToISODateFormat } from '../../utility/common.utility';

@Injectable({
  providedIn: 'root'
})
export class AllocationTransferService {
    public newAllocTransferAdded$: Subject<boolean> = new Subject<boolean>();
    public transferReasons: IShipmentReason[] = [];
    public transferForm: AllocationTransferForm;
    public allocationTransferUsers: SelectListData[] = [];
    public isDisableTransfer: boolean = false;

    constructor(private disk: DiskService,
                private authService: AuthenticationService,
                private allocFeatureService: AllocationFeatureService,
                private sampleService: SampleService,
                private injector: Injector) {}

    getTransferProducts(lots: Lot[]): SelectListData[] {
        const availLots = lots.filter(lot => lot.totalQuantityRemaining > 0);
        const uniqueLots = uniqBy(availLots, 'sampleSKUId');
        let arrayToReturn: SelectListData[] = [];
        arrayToReturn = uniqueLots.map((lot: Lot) => ({ id: lot.sampleSKUId, title: lot.sampleSKUName, isSelected: this.checkIfProductSelectedInTransferForm(lot.sampleSKUId) }));
        arrayToReturn = arrayToReturn.sort((a,b)=> a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1 );
        return arrayToReturn;
    }

    private checkIfProductSelectedInTransferForm(productId: string): boolean {
        return this.transferForm && this.transferForm.sku && this.transferForm.sku.id === productId;
    }

    async loadFromDBAndMapTransferReasons() {
        this.transferReasons.length = 0;
        const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOC_TRANSFER_REASONS, true);
        if (Array.isArray(dbData?.rawReasons)) {
          this.transferReasons = dbData.rawReasons;
        }
    }
    async mapTransferReasons(rawReasons: IShipmentReason[]) {
        // Doesn't have delta sync yet.
        this.transferReasons.length = 0;
        if (Array.isArray(rawReasons)) {
            this.transferReasons = rawReasons;

            this.disk.updateOrInsert(DB_KEY_PREFIXES.ALLOC_TRANSFER_REASONS, (doc) => {
                return { rawReasons };
            });
        }
    }

    async loadFromDBAndMapTransferUsers() {
        this.allocationTransferUsers = [];
        const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOC_TRANSFER_USERS, true);

        if (dbData && Array.isArray(dbData.raw)) {
            for (let i = 0; i < dbData.raw.length; i++) {
                const rawUser = dbData.raw[i];

                //since the service is returning duplicate data informed vipin
                //check if the id exist, if not then push
                let idx = this.allocationTransferUsers.findIndex(el => el.id === rawUser.userid);
                if (idx < 0) {
                    this.allocationTransferUsers.push({ id: rawUser.userid, title: rawUser.userfullname });
                } else {
                    console.warn("duplicate data from server detected, report MSE");
                }
            }

            if (this.allocationTransferUsers.length > 0) {
                this.sortUserList();
            }
        }
    }
    async mapAllocationTransferUsers(rawUsers: IrawAccompainedUser[], isOffline = false) {
        this.allocationTransferUsers = [];
        if (Array.isArray(rawUsers)) {
            for (let i = 0; i < rawUsers.length; i++) {
                const rawUser = rawUsers[i];

                //since the service is returning duplicate data informed vipin
                //check if the id exist, if not then push
                let idx = this.allocationTransferUsers.findIndex(el => el.id === rawUser.userid);
                if (idx < 0) {
                    this.allocationTransferUsers.push({ id: rawUser.userid, title: rawUser.userfullname });
                } else {
                    console.warn("duplicate data from server detected, report MSE");
                }
            }

            if (!isOffline) {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.ALLOC_TRANSFER_USERS, doc => ({ raw: rawUsers }));
            }

            if (this.allocationTransferUsers.length > 0) {
                this.sortUserList();
            }
        }
    }

    private sortUserList() {
        this.allocationTransferUsers.sort(function (a, b) {
            const nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        });
    }

    async handlePostAllocTransfer(response: IAllocationTransferCreateResponseDTO, form: AllocationTransferForm): Promise<IAllocationTransfer> {
        const lotDTO = form.lot.DTO;
        const rawNewAllocTransfer: IAllocationTransfer = {
          at_indskr_lotid: response.indskr_lotid,
          at_indskr_lotname: form.lot.name,
          at_indskr_lotvalidfromdate: convertTimestampStringToISODateFormat(lotDTO.indskr_lotvalidfromdate, 'date'),
          at_indskr_lotvalidtodate: convertTimestampStringToISODateFormat(lotDTO.indskr_lotvalidtodate, 'date'),
          at_indskr_skuid: response.indskr_skuid,
          at_indskr_skuname: form.sku.title,

          indskr_externalid: response.indskr_externalid,
          indskr_name: response.indskr_name,
          indskr_reasonfortransfer: response.indskr_reasonfortransfer,
          indskr_comments: response.indskr_comments,
          indskr_shipmentdate: convertTimestampStringToISODateFormat(response.indskr_shipmentdate, 'dateTime'),
          indskr_shipmentnumber: response.indskr_shipmentnumber,
          indskr_shipmentstatus: response.indskr_shipmentstatus,
          indskr_transfertype: response.indskr_transfertype,
          indskr_userid: response.indskr_user,
          indskr_usershipmentallocation_v2id: response.indskr_usershipmentallocationid,

          ownerFullName: this.authService.user.displayName,
          ownerId: this.authService.user.xSystemUserID,
          userFullName: form.user.title,
          u_quantityshipped: response.indskr_quantityshipped,

          at_lot_statecode: null,
          at_sku_statecode: null,
          statecode: null,
          statuscode: null,

          lastUpdatedTime: (new Date()).getTime(),
        };

        // Add to array
        this.allocFeatureService.addToOrReplaceInShipmentsArray(rawNewAllocTransfer, true);

        // Add to DB
        await this.allocFeatureService.accessLocalDB(rawNewAllocTransfer, 'shipmentOrTransfer', 'upsert', true);

        // Update Lot
        const foundLotDetail = this.sampleService.lots.find(l => l.id === rawNewAllocTransfer.at_indskr_lotid);
        if (foundLotDetail) {
            foundLotDetail.totalQuantityRemaining -= response.indskr_quantityshipped;
            foundLotDetail.totalQuantityDropped += response.indskr_quantityshipped;

            await this.sampleService.updateOrInsertLotQuantityDetailToOfflineDB(foundLotDetail, true);


          if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT)) {
            const now = new Date().getTime();
            let adjustedDate = new Date(new Date((new Date().setDate(new Date().getDate() - this.authService.user.allocationAdjustmentDuration))).setHours(0, 0, 0, 0))
            if ((isWithinRange(now, foundLotDetail.validFrom, foundLotDetail.validTo) || isWithinRange(adjustedDate, foundLotDetail.validFrom, foundLotDetail.validTo)) && foundLotDetail.status == 0) {
              const adjustmentService = this.injector.get<AllocationAdjustService>(AllocationAdjustService);
              let index = adjustmentService.lots.findIndex((adjustment) => adjustment.id === foundLotDetail.id)
              if (index > -1) {
                adjustmentService.lots[index] = foundLotDetail;
              } else {
                adjustmentService.lots.push(foundLotDetail);
              }

              index = adjustmentService.allLots.findIndex((adjustment) => adjustment.id === foundLotDetail.id)
              if (index > -1) {
                adjustmentService.allLots[index] = foundLotDetail;
              } else {
                adjustmentService.allLots.push(foundLotDetail);
              }
            }
          }
        }
        return rawNewAllocTransfer;
    }

    getReasonTxt(value: number) {
        const reason = this.transferReasons.find(r => r.value == value);
        return reason && reason.reason ? reason.reason : '';
    }


    // initForm(allocTransfer?: AllocationTransfer) {
    initForm() {
        if (!this.transferForm) {
            this.transferForm = new AllocationTransferForm();
        }
        return this.transferForm;
    }

    doesFormExist() {
        return !!this.transferForm;
    }

    destroyForm() {
        if (this.transferForm && this.transferForm.user) {
            this.transferForm.user.isSelected = false;
        }
        this.transferForm = undefined;
    }
}
