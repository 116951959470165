/* eslint-disable sort-imports */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { format, isBefore } from 'date-fns';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IndPageTitleViewDataModel } from '../../..//models/indPageTitleDataModel';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { DB_KEY_PREFIXES } from '../../../config/pouch-db.config';
import { RepServices } from '../../../data-services/rep/rep.services';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import {
  FormFieldType,
  IndFormFieldViewDataModel,
} from '../../../models/indFormFieldDataModel';
import { SelectListData } from '../../../models/select-list-data-model';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { AlertService } from '../../../services/alert/alert.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import {
  EventName,
  EventsService,
} from '../../../services/events/events.service';
import {
  TrackingEventNames,
  TrackService,
} from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { PopoverComponent } from '../../popover/popover';
import {
  AccountPlan,
  AccountPlanObjective,
  AccountPlanObjectiveReport,
  AccountPlanReportState,
} from './../../../classes/account-management/account-plan.class';
import { AccountManagementDataService } from './../../../data-services/account-management/account-management.data.service';
import { AuthenticationService } from './../../../services/authentication.service';
import { DiskService } from './../../../services/disk/disk.service';
import {
  FooterService,
  FooterViews,
} from './../../../services/footer/footer.service';
import { MultiSelectFilterPopoverComponent } from './../../multi-select-filter-popover/multi-select-filter-popover';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { truncate } from 'fs';
import { DatePipe } from '@angular/common';
import { ActivityService } from '@omni/services/activity/activity.service';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { NotificationService } from '@omni/services/notification/notification.service';
import { IONote } from '@omni/classes/io/io-note.class';

@Component({
  selector: 'plan-progress-report',
  templateUrl: 'plan-progress-report.html',
  styleUrls: ['plan-progress-report.scss'],
})
export class PlanProgressReport implements OnInit, OnDestroy {

    public isApprovalEnabled = true;
    private CSFPopover: any;
  textPlaceholder: string;
    public csfOptionsList;
    public selectedObjective: AccountPlanObjective;
    public selectedCSF: any;
    completedValue: any;
    reportData: AccountPlanObjectiveReport;
    changesPendingSync: boolean;
    unsyncedProgressNotes: string;
    public viewType:string;
    unsavedNotes: boolean = false;
   // @ViewChild ('notesInput', {static:true}) notesTextareaRef: ElementRef
    csfString: string;
    selectedPlan: AccountPlan;
    public selectedPlanOnPlanTab:AccountPlan;
    public selectedObjectiveOnPlanTab: AccountPlanObjective;
    public reportDataOnPlanTab: AccountPlanObjectiveReport;
    @ViewChild ('compValueInput', {static: true}) compValueInput: ElementRef;
    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    // public isEditingQuantity: boolean = false;
    // public isEditingPercent: boolean = false;
    private initialCSFDataBeforeSelection: Array<SelectListData> = [];
    ngUnsubscribe$ = new Subject<boolean>();
    public pageTitle: IndPageTitleViewDataModel;
    public reportDetailHeaderModel: IndSectionHeaderViewDataModel;
    public reportNotesHeaderModel: IndSectionHeaderViewDataModel;
  public pageControls = [];
  public backgroundUploadInProgress: boolean = false;
  private isClickedCompletedValue: boolean = false;
  private isClickedCompletedPercent: boolean = false;
  @ViewChild('attachInput') attachInput: ElementRef;
  public attachmentTitle: string = '';
  public isNotesAttachmentEnabled: boolean = true;
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;
  public tempNoteText: string;
  public isSaveNotesEnabled:boolean = false;

  public backButton = {
    id: 'report-back',
    icon: 'chevron-back-outline',
    isDisabled: false,
    align: 'left'
  };

    constructor(
        public device: DeviceService,
        public repService: RepServices,
        public readonly UIService: UIService,
        private readonly accountManagementService: AccountManagementOfflineService,
        public popoverCtrl: PopoverController,
        public navParams: NavParams,
        public accountManagementDataService: AccountManagementDataService,
        public footerService: FooterService,
        public authService: AuthenticationService,
        private readonly navService: NavigationService,
        private readonly alertService: AlertService,
        public trackingService: TrackService,
        private readonly translate: TranslateService,
        public dateTimeFormatsService: DateTimeFormatsService,
        private readonly events: EventsService,
        private readonly disk: DiskService,
        private datePipe: DatePipe,
        private activityService: ActivityService,
        private readonly notificationService: NotificationService,
    ) {

      this.events.subscribe(EventName.DROPDOWNLISTDISMISSDATA,(data)=>{
        this._handleCSFPopoverDismiss(data);
      });
      this.events.subscribe('resetPlanProgressReportFooter', ()=>{
        this.selectedPlan = this.selectedPlanOnPlanTab;
        this.selectedObjective = this.selectedObjectiveOnPlanTab;
        this.reportData = this.reportDataOnPlanTab;
        this.setFooter();
      });
      this.textPlaceholder = this.translate.instant('ENTER_PROGRESS_NOTES');
    }

    ngOnInit(){
      this.selectedPlan = (this.navParams.data && this.navParams.data.accountPlanRef);
      this.selectedObjective = (this.navParams.data && this.navParams.data.objective);
      this.reportData = (this.navParams.data && this.navParams.data.report);
      this.csfOptionsList = this.selectedObjective.CSFs.map((o)=>{
        o['text'] = o.CSFName;
        o['value'] = o.CSFId;
        o['checked'] = this.reportData.CSFs.some(p=> p.CSFId === o.CSFId);
        return o;
      });

      this.changesPendingSync = false;
      //@ts-ignore

      this.device.isOfflineObservable.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((bool)=>{
        this.setFooter();
        this.initPageTitle();
      });
      this.csfString = this.reportData.CSFs && this.reportData.CSFs.length === 1 ? this.reportData.CSFs[0].CSFName :
                       this.reportData.CSFs && this.reportData.CSFs.length > 1? this.reportData.CSFs[0].CSFName+'+'+(this.reportData.CSFs.length -1):'';

      if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.UIService.activitiesPageTab === 'plans' && this.UIService.activitiesPagePlanTabSelectedPlan == 'accountPlansTab') {
        this.selectedPlanOnPlanTab = this.selectedPlan;
        this.selectedObjectiveOnPlanTab = this.selectedObjective;
        this.reportDataOnPlanTab = this.reportData ;
        this.UIService.activeViewOnPlanTab = 'planProgressReports';
      }

      this.initPageTitle();
      this.initHeaderModels();
      this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(inProgress => {
        if (this.reportData) {
          this.backgroundUploadInProgress = inProgress && this.reportData.syncPending;
          this.initPageTitle();
          this.initreportNotesHeaderModel();
          this.setFooter();
        }
      });
    }

    setFooter() {
      this.isApprovalEnabled = this.reportData.status === 'Open'
                              && !(this.selectedObjective.type==='QUANTITATIVE' && !this.reportData.completedValue)
                              && !(this.selectedObjective.type==='QUALITATIVE' && !this.reportData.completedPercent);

      if (this.reportData.createdByUserID === this.authService.user.systemUserID) {
        if(this.reportData.status === 'Open' || this.reportData.status === 'Approved'){
          this.footerService.initButtons(FooterViews.AccountPlanReport, { status: this.reportData.status, isApprovalEnabled: this.isApprovalEnabled, backgroundUploadInProgress: this.backgroundUploadInProgress });
        } else if (this.reportData.status === 'For Review') {
          this.footerService.initButtons(FooterViews.AccountPlanReportCancelReview);
        }
        this.viewType = 'creatorView';
      } else if (this.selectedPlan.ownerID === this.authService.user.systemUserID) {
        this.footerService.initButtons(FooterViews.AccountPlanReportApproval, { status: this.reportData.status, backgroundUploadInProgress: this.backgroundUploadInProgress });
        this.viewType = 'managerView';
      } else{
        this.viewType = 'teammateView';
      }
    }

    ngOnDestroy(){
      this.ngUnsubscribe$.next(true);
      this.ngUnsubscribe$.complete();
    }

    private initPageControls() {


      this.pageControls = [];
      this.pageControls.push(this.backButton);

      if (this.viewType === 'creatorView') {
        if (this.reportData.status === 'For Review') {
          this.pageControls.push({
            id: 'report-cancel-review',
            imgSrc: 'assets/imgs/header_cancel_review.svg',
            name: this.translate.instant('CANCEL'),
            isDisabled: this.backgroundUploadInProgress || this.device.isOffline,
            align: 'right'
          });
        } else {
          this.pageControls.push({
            id: 'report-scrap',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: this.backgroundUploadInProgress || this.reportData.status === 'Approved',
            align: 'right'
          });

          this.pageControls.push({
            id: 'report-send-for-approval',
            name: this.translate.instant('SEND'),
            imgSrc: 'assets/imgs/header_send.svg',
            isDisabled: this.backgroundUploadInProgress
                        || this.device.isOffline
                        || this.reportData.status === 'Approved'
                        || (this.selectedObjective.type==='QUANTITATIVE' && this.reportData.completedValue == 0)
                        || (this.selectedObjective.type==='QUALITATIVE' && this.reportData.completedPercent == 0),
            align: 'right'
          });
        }
      }

      if ((this.viewType==='managerView' && this.reportData.status === 'For Review') || (this.viewType==='managerView' && this.reportData.status === 'Approved')) {
        this.pageControls.push({
          id: 'report-manager-not-approve',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('NOT_APPROVE'),
          isDisabled: (this.backgroundUploadInProgress || this.reportData.status === 'Approved' || this.device.isOffline),
          align: 'right'
        });
      }

      if ((this.viewType==='managerView' && this.reportData.status === 'For Review') || (this.viewType==='managerView' && this.reportData.status === 'Approved')) {
        this.pageControls.push({
          id: 'report-manager-approve',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('APPROVE'),
          isDisabled: (this.backgroundUploadInProgress || this.reportData.status === 'Approved' || this.device.isOffline),
          align: 'right'
        });
      }
    }


    private initPageTitle(): void {
      this.initPageControls();
      this.pageTitle = {
        id: 'account-plan-details',
        title: `${this.reportData.createdBy} - ${this.datePipe.transform(this.reportData.createdOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)}`,
        controls: this.pageControls
      };
  }

  private initHeaderModels() {
    this.initReportDetailHeaderModel();
    this.initreportNotesHeaderModel();
  }

  private initReportDetailHeaderModel() {
    this.reportDetailHeaderModel = {
      id: 'report-notes-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: []
    };
  }

  private initreportNotesHeaderModel() {
    const controls = [];
    //if (this.reportData.status === 'Open') {
      controls.push({
        id: 'attach_note',
        text: this.translate.instant('ATTACH'),
        isDisabled: this.backgroundUploadInProgress || !this.unsavedNotes,
        isVisible: this.isNotesAttachmentEnabled,
      });
      controls.push({
        id: 'report-notes-save',
        text: this.translate.instant('SAVE'),
        isDisabled: this.backgroundUploadInProgress || !this.unsavedNotes || !this.isSaveNotesEnabled,
      });
    //}
    this.reportNotesHeaderModel = {
      id: 'report-notes-header',
      title: this.translate.instant('ACCOUNT_PLAN_PROGRESS_NOTE'),
      controls: controls,
    };
  }

    async openCSFPicker(myEvent) {
        if(Array.isArray(this.csfOptionsList) && this.csfOptionsList.length !== 0 ){
          this.initialCSFDataBeforeSelection = [];
          const dropdownListDetail: IndDropdownListDetailModel = {
            id: 'Progress-Report-CSF-Select',
            isMultipleSelectionEnabled: true,
            showClearAllBtn: true,
            clearAllBtnText: this.translate.instant('CLEAR_ALL'),
            data: this.csfOptionsList.map(csf=>{
                const obj = {
                    title: csf.text,
                    id: csf.value,
                    isSelected: false,
                };
                if(this.reportData.CSFs && this.reportData.CSFs.length !== 0 && this.reportData.CSFs.some(a=> a.CSFId === csf.value)){
                  obj.isSelected = true;
                  this.initialCSFDataBeforeSelection.push(obj);
                }
                return obj;
            }),
          };
          this.CSFPopover = await this.popoverCtrl.create({component:IndDropdownListComponent,
                                                           componentProps: {viewData:dropdownListDetail},
                                                           cssClass:'dropdown-list-view',
                                                           event: event});
          this.CSFPopover.present();
          this.CSFPopover.onDidDismiss().then(async (data) => {
            if (this.backgroundUploadInProgress) return;
            this._handleCSFPopoverDismiss(data.data);
          });
        }
    }

  private _handleCSFPopoverDismiss(data) {
    let updatedData = [];
    if (data && data.selectedItems) {
      updatedData = _.xor(this.initialCSFDataBeforeSelection, data.selectedItems);
    }
    if (Array.isArray(updatedData) && updatedData.length !== 0) {
      if (this.reportData.CSFs && this.reportData.CSFs.length > 0) {
        data.selectedItems.forEach((r) => {
          if (!this.reportData.CSFs.some(o => o.CSFId === r.id)) {
            this.reportData.CSFs.push({
              CSFName: r.title,
              CSFId: r.id
            });
          }
        });
        this.reportData.CSFs.map((c) => {
          if (!data.selectedItems.some((t) => t.id === c.CSFId)) {
            c.deleted = true;
          }
        });
        this.reportData.removedCSFs = this.reportData.CSFs.filter(c => c.deleted);
        this.reportData.CSFs = this.reportData.CSFs.filter(c => !c.deleted);
      } else {
        this.reportData.CSFs = data.selectedItems.map((m) => {
          return {
            CSFName: m.title,
            CSFId: m.id,
          };
        });
      }
      this.csfString = this.reportData.CSFs && this.reportData.CSFs.length === 1 ? this.reportData.CSFs[0].CSFName :this.reportData.CSFs && this.reportData.CSFs.length > 1 ? this.reportData.CSFs[0].CSFName + '+' + (this.reportData.CSFs.length - 1) : '';

      this.changesPendingSync = true;
      this.reportData.syncPending = true;
      this.accountManagementDataService.updateProgressReport({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true
      }, this.reportData);
      this.CSFPopover = undefined;
    }
  }

    public progresNotesChanged(ev): void {
      if (this.backgroundUploadInProgress) return;
      if (ev && ev.target) {
        this.tempNoteText = ev.target.value;
        this.isSaveNotesEnabled = true;

      } else {
        this.tempNoteText = '';
        this.isSaveNotesEnabled = true;

      }
      this.unsavedNotes = true;
      this.initHeaderModels();
    }

    public get getCompletedPercent(){
      return  Number((this.reportData.completedValue)/this.selectedObjective.target*100).toFixed(2);
    }

    public async  setCompletedValueForReport(event) {
      if(this.reportData.status !== 'Open') return;

      if (event) {
        if ((event.value == null && event.target.value == null) || event.value === '' || event.target.value === '') {
          this.resetProgressReportCompletedValue(event);
        } else {
          event.value = event.value ? event.value : event.target.value;
          this.isApprovalEnabled = true;
          if(!this.device.isOffline && event.value != 0)this.footerService.enableButtons(['sendprogressreport'], true);
        }
      } else {
        this.resetProgressReportCompletedValue(event);
      }

      if(event.value < 0) event.value = 0;
      this.reportData.completedValue = Math.floor(event.value);
      this.reportData.formattedCompletedValue = this.dateTimeFormatsService.selectedSymbolPos.value == 'left'
        ? this.selectedObjective.currencySymbol + this.reportData.completedValue.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.reportData.completedValue.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.selectedObjective.currencySymbol;
      this.reportData.completedPercent = Math.floor(event.value / this.selectedObjective.target * 100);
      this.changesPendingSync = true;
      this.reportData.syncPending = true;
      this.isClickedCompletedValue = true;
      this.initPageTitle();
      await this.accountManagementDataService.updateProgressReport({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true
      }, this.reportData);
    }

  private resetProgressReportCompletedValue(event: any) {
    event.value = 0;
    this.isApprovalEnabled = false;
    this.footerService.enableButtons(['sendprogressreport'], false);
  }

    setCompletedPercentForQaualitativeReport(event){
      if(this.reportData.status !== 'Open') return;

      if (event) {
        if ((event.value == null && event.target.value == null) || event.value === '' || event.target.value === '') {
          this.resetCompletedPercentageForQualitative(event);
        } else {
          event.value = event.value ? event.value : event.target.value;
          this.isApprovalEnabled = true;
          if(!this.device.isOffline && event.value != 0) this.footerService.enableButtons(['sendprogressreport'], true);
        }
      } else {
        this.resetCompletedPercentageForQualitative(event);
      }

      if(event.value > 100) event.value = 100;
      if(event.value < 0) event.value = 0;
      this.reportData.completedPercent = Math.floor(event.value);
      this.changesPendingSync = true;
      this.reportData.syncPending = true;
      this.isClickedCompletedPercent = true;
      this.initPageTitle();
      this.accountManagementDataService.updateProgressReport({
        onDynamics:!this.device.isOffline,
        onLocalCopy:true,
        onLocalDatabase:true
      }, this.reportData);
    }

  private resetCompletedPercentageForQualitative(event: any) {
    event.value = 0;
    this.isApprovalEnabled = false;
    this.footerService.enableButtons(['sendprogressreport'], false);
  }

    public goBackToObjectiveDetails(): void {
      this.trackingService.tracking('AccountPlanProgressReportBack', TrackingEventNames.ACCOUNTPLANNING);
      if(this.changesPendingSync && !this.device.isOffline){
        this.accountManagementDataService.updateProgressReport({onDynamics: !this.device.isOffline,onLocalDatabase:true,onLocalCopy:true},this.reportData).then((info)=>{
          this.changesPendingSync = false;
        });
      }
      this.navService.popChildNavPageWithPageTracking().then(() => {
        this.footerService.initButtons(FooterViews.AccountPlanObjectives);
      });
    }

    public async saveNotes(){
      if (this.isSaveNotesEnabled && ((this.tempNoteText && !_.isEmpty(this.tempNoteText)) || !_.isEmpty(this.base64str))) {
        this.isSaveNotesEnabled = false;
        this.initreportNotesHeaderModel();
        this.unsavedNotes = !this.unsavedNotes;


        let action = {
          onDynamics: !this.device.isOffline,
          onLocalCopy: true,
          onLocalDatabase: true,
        };

        let tempNote: IONote = new IONote({
          annotationid: 'offline_note_' + new Date().getTime(),
          activityid: this.reportData.reportId,
          createdon: new Date().getTime(),
          notetext: this.tempNoteText,
          ownerName: this.authService.user.displayName,
          ownerid: this.authService.user.systemUserID,
        });

        if (this.base64str) {
          tempNote.hasDocument = true;
          tempNote.documentDataURL = this.base64str;
          tempNote.documentName = this.attachmentFile.name;
          tempNote.documentSize = this.attachmentFile.size;
          tempNote.documentMimeType = this.attachmentFile.type;
        } else {
          tempNote.hasDocument = false;
        }
        tempNote.pendingPushForDynamics = true;
        this.reportData.notes.push(tempNote);
        this.reportData.syncPending = true;
        //this.changesPendingSync = true;
        this.removeAttachment(null);
        this.tempNoteText = '';
        this._sortNotes();
        await this._displayDismissLoader(true);
        await this.accountManagementDataService.updateProgressReport({
          onDynamics:!this.device.isOffline,
          onLocalCopy:true,
          onLocalDatabase:true,
          message: 'UPDATE_NOTES',
        }, this.reportData);
        await this._displayDismissLoader(false);
        this.initreportNotesHeaderModel();
      }

    }

    // onNotesBlur() {
    //   if (_.isEmpty(this.tempNoteText)) {
    //     this.isSaveNotesEnabled = false;
    //     this.initreportNotesHeaderModel();
    //   }
    // }

    public scrapReport(fromFooter: boolean = false){
      if (this.reportData.status !== 'Open') return;

      this.alertService.showAlert({
        header: this.translate.instant('ACCOUNT_PLAN_PROGRESS_SCRAP_REPORT_TITLE'),
        message: this.translate.instant('ACCOUNT_PLAN_PROGRESS_SCRAP_REPORT_MESSGE')
      }, this.translate.instant('SCRAP')
      ).then(res => {
        if (res.role === 'ok') {
          if (this.backgroundUploadInProgress) return;
          if (fromFooter) this.trackingService.tracking('AccountPlanProgressReportScrap', TrackingEventNames.ACCOUNTPLANNING);
          else this.trackingService.tracking('AccountPlanProgressReportScrapActionBar', TrackingEventNames.ACCOUNTPLANNING);
          this.reportData.state = 1;
          this.reportData.statusCode = 2;
          this.footerService.hideAllButtons();
          this.accountManagementDataService.deleteProgressReport({
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: true
          }, this.reportData).then(() => {
            this.accountManagementService.allProgressReports$.next(this.accountManagementService.allProgressReports);
            this.navService.popChildNavPageWithPageTracking();
          });
        }

      });
    }

    public sendForApproval(fromFooter: boolean = false): void {
      if (this.device.isOffline || this.reportData.status !== 'Open') return;
      if(fromFooter) this.trackingService.tracking('AccountPlanProgressReportSendforApproval', TrackingEventNames.ACCOUNTPLANNING);
      else this.trackingService.tracking('AccountPlanProgressReportSendforApprovalActionBar', TrackingEventNames.ACCOUNTPLANNING);

      let autoAprroval = false;
      this.alertService.showAlert({
          header: this.translate.instant('ACCOUNT_PLAN_SEND_FOR_APPROVAL'),
          message: this.translate.instant('ACCOUNT_PLAN_PROGRESS_SEND_REPORT_MESSAGE')},
          this.translate.instant('SEND')
      ).then (res => {
        this.initPageTitle();
        if(res.role === 'ok'){
          if (this.backgroundUploadInProgress) return;
          if(this.selectedPlan.ownerID === this.authService.user.systemUserID){
            autoAprroval = true;
            this.reportData.state = 1;
            this.reportData.status = 'Approved';
            this.reportData.statusCode = AccountPlanReportState.Approved;
            this.selectedObjective.comletedValue += this.reportData.completedValue;
            this.selectedObjective.completedPercent += this.reportData.completedPercent;
            const currSymbol = this.selectedObjective.currencySymbol;
            /*this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLANS, (doc)=>{
              doc = {
                  raw: []
              };
              doc.raw = this.accountManagementService.accountPlans;
              return doc;
            });*/
            this.accountManagementDataService.saveAccountPlanInDB();
            this.initPageTitle();
            this.initHeaderModels();
            if(typeof(this.selectedObjective.comletedValue) === 'string'){
              this.selectedObjective.formattedCompletedValue = this.dateTimeFormatsService.selectedSymbolPos.value === 'left'
                ? currSymbol+ (parseInt(this.selectedObjective.comletedValue).toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                : (parseInt(this.selectedObjective.comletedValue).toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + currSymbol;
            } else {
              this.selectedObjective.formattedCompletedValue = currSymbol+ (this.selectedObjective.comletedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
            }
            this.footerService.enableButtons(['cancelprogressreport', 'sendprogressreport'], false);
          }
          else {
            this.reportData.status = 'For Review';
            this.reportData.statusCode = AccountPlanReportState['For review'];
            this.footerService.hideAllButtons();
            this.footerService.initButtons(FooterViews.AccountPlanReportCancelReview);
          }
          this.isApprovalEnabled = !this.isApprovalEnabled;
          this.footerService.enableButtons(['sendprogressreport'], this.isApprovalEnabled);
          this.changesPendingSync = false;
          this.initPageTitle();
          this.accountManagementDataService.updateProgressReport({
            onDynamics:true,
            onLocalDatabase:true,
            onLocalCopy: true
          },this.reportData, false)
          .then(()=>{
          });
        }
      });
    }
    public cancelreview(){
      if (this.device.isOffline) return;

      this.alertService.showAlert({
        header: this.translate.instant('CANCEL_REVIEW'),
        message: this.translate.instant('ACCOUNT_PLAN_PROGRESS_CANCEL_REVIEW_MESSGE')
      },
      ).then(res => {
        if (res.role === 'ok') {
          if (this.backgroundUploadInProgress) return;
          this.isApprovalEnabled = true;
          this.reportData.status = 'Open';
          this.reportData.statusCode = AccountPlanReportState.Open;
          this.footerService.initButtons(FooterViews.AccountPlanReport, { status: this.reportData.status, isApprovalEnabled: this.isApprovalEnabled });
          this.changesPendingSync = false;
          this.initPageTitle();
          this.accountManagementDataService.updateProgressReport({
            onDynamics: true,
            onLocalDatabase: true,
            onLocalCopy: true
          }, this.reportData, false).then((success) => {
          }, (error) => {
          });
        }

      });
    }
    public approveReport(){
      if (this.device.isOffline || this.reportData.status !== 'For Review') return;
      this.alertService.showAlert({
        header: this.translate.instant('ACCOUNT_PLAN_PROGRESS_APPROVE_REPORT_TITLE'),
        message: this.translate.instant('ACCOUNT_PLAN_PROGRESS_APPROVE_REPORT_MESSGE')
      }, this.translate.instant('APPROVE')
      ).then(res => {
        if (res.role === 'ok') {
          if (this.backgroundUploadInProgress) return;
          this.reportData.status = 'Approved';
          this.reportData.statusCode = AccountPlanReportState.Approved;
          this.reportData.state = 1;
          this.selectedObjective.comletedValue += this.reportData.completedValue;
          this.selectedObjective.completedPercent += this.reportData.completedPercent;
          const currSymbol = this.selectedObjective.currencySymbol;
          if (typeof (this.selectedObjective.comletedValue) === 'string') {
            this.selectedObjective.formattedCompletedValue = this.dateTimeFormatsService.selectedSymbolPos.value === 'left'
              ? currSymbol + (parseInt(this.selectedObjective.comletedValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
              : (parseInt(this.selectedObjective.comletedValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + currSymbol;
          }
          else {
            this.selectedObjective.formattedCompletedValue = currSymbol + (this.selectedObjective.comletedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
          }
          this.footerService.enableButtons(['notapproveprogressreport', 'approveprogressreport'], false);
          this.accountManagementDataService.updateProgressReport({
            onDynamics: true,
            onLocalDatabase: true,
            onLocalCopy: true
          }, this.reportData, false).then((success) => {
          }, (error) => { });
          this.initPageTitle();
        }
      });
    }

    public disApproveReport() {
      if (this.device.isOffline || this.reportData.status !== 'For Review') return;

      this.alertService.showAlert({
        header: this.translate.instant('ACCOUNT_PLAN_PROGRESS_DISAPPROVE_TITLE'),
        message: this.translate.instant('ACCOUNT_PLAN_PROGRESS_DISAPPROVE_MESSGE')}
      ).then (res => {
        if(res.role === 'ok') {
          if (this.backgroundUploadInProgress) return;
          this.reportData.state = 0;
          this.reportData.status = 'Not Approved';
          this.reportData.statusCode = AccountPlanReportState.Open;
          this.accountManagementDataService.updateProgressReport({
            onDynamics:true,
            onLocalDatabase:true,
            onLocalCopy: true
          },this.reportData, false).then(()=>{
            this.accountManagementService.allProgressReports$.next(this.accountManagementService.allProgressReports);
            this.footerService.initButtons(FooterViews.AccountPlanObjectives);
            this.navService.popChildNavPageWithPageTracking();
          });
          this.initPageTitle();
        }
      });
    }

    public setFocus(flag: boolean, ev){
      if(flag){
        console.log('focus', this.compValueInput);
        //@ts-ignore
        const value = this.compValueInput.value;
        //@ts-ignore
        //this.compValueInput.type='number';
        const firstChar = value[0];
        if(isNaN(firstChar)){
          //@ts-ignore
          this.compValueInput.value = parseInt(value.split(',').join('').substring(1));
        }
        //@ts-ignore
        else this.compValueInput.value = parseInt(value.split(',').join(''));
      }
      if(!flag){
        console.log('blur', this.compValueInput);
        //@ts-ignore
        const value = this.compValueInput.value;
        //@ts-ignore
        //this.compValueInput.type = 'text';
        //@ts-ignore
        this.compValueInput.value = (parseInt(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        this.reportData.formattedCompletedValue = this.reportData.completedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }

    formattedDate(val): string {
      return this.datePipe.transform(val, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) || '';
    }

    public getCompletedValueFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      const formattedCompletedValue = this.reportData.formattedCompletedValue ? this.dateTimeFormatsService.formattedCurrency(this.reportData.formattedCompletedValue) : '';
      viewData = {
        label: this.translate.instant('COMPLETE_VALUE'),
        inputText: formattedCompletedValue,
        inputType: 'number',
        inputValue: `${(this.reportData.completedValue)?this.reportData.completedValue:0}`,
        id:'completed-value-field',
        isReadOnly: this.reportData.status !== 'Open',
        isDisabled: this.backgroundUploadInProgress || this.reportData.status !== 'Open',
        placeholderLabel:this.translate.instant('VALUE'),
        showArrow: this.reportData.status === 'Open',
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: this.reportData.status === 'Open',
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isClickedCompletedValue && this.reportData.completedValue<=0,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      };
      return viewData;
    }

    public getCompletedPercentFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('COMPLETED'),
        inputText: ((this.reportData.completedPercent)?this.reportData.completedPercent+'%':'0%'),
        inputType: 'number',
        inputValue: (this.reportData.completedPercent)?this.reportData.completedPercent:0,
        formattingSymbol: '%',
        id:'completed-percent-field',
        isReadOnly: this.reportData.status !== 'Open',
        isDisabled: this.backgroundUploadInProgress || this.reportData.status !== 'Open',
        placeholderLabel:this.translate.instant('VALUE'),
        showArrow: this.reportData.status === 'Open',
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: this.reportData.status === 'Open',
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isClickedCompletedPercent && this.reportData.completedPercent<=0,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      };
      return viewData;
    }

    public getCSFFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('ACCOUNT_PLAN_PROGRESS_CSF'),
        inputText: this.csfString,
        placeholderLabel: (this.reportData.status === 'Open' && Array.isArray(this.csfOptionsList) && this.csfOptionsList.length !== 0)?this.translate.instant('ACCOUNT_PLAN_PROGRESS_CSF'):this.translate.instant('NO_CSF'),
        id:'csf-field',
        isReadOnly: true,
        isDisabled: this.backgroundUploadInProgress || this.reportData.status !== 'Open' || !(Array.isArray(this.csfOptionsList) && this.csfOptionsList.length !== 0),
        showArrow: this.reportData.status === 'Open' && Array.isArray(this.csfOptionsList) && this.csfOptionsList.length !== 0,
        formFieldType: FormFieldType.POPOVER_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      };
      return viewData;
    }

    public getStatusFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('STATUS'),
        inputText: this.getStatus(this.reportData.status),
        id:'status-field',
        isReadOnly:true,
        isDisabled:true,
      };
      return viewData;
    }

    private getStatus(status): string{
      switch (status) {
        case 'Open':
          status = this.translate.instant('OPEN');
          break;
        case 'For Review':
          status = this.translate.instant('FOR_REVIEW');
          break;
        case 'Approved':
          status = this.translate.instant('APPROVED');
          break;
        case 'Inactive':
          status = this.translate.instant('OPEN');
          break;
        default:
          status = this.translate.instant('OPEN');
          break;
      }
      return status;
    }

    public getCreatedByFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('CREATED_BY'),
        inputText: this.reportData.createdBy,
        id:'created-by-field',
        isReadOnly:true,
        isDisabled:true,
      };
      return viewData;
    }

    public getCompletedPercentQuantFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('COMPLETED_PERCENT'),
        inputText: this.reportData.completedPercent+'%',
        id:'completed-percent-quant-field',
        isReadOnly:true,
        isDisabled:true,
      };
      return viewData;
    }

    public getDateFormField(): IndFormFieldViewDataModel {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('DATE'),
        inputText: this.formattedDate(this.reportData.createdOn),
        id:'date-field',
        isReadOnly:true,
        isDisabled:true,
      };
      return viewData;
    }



    private handleFormFieldEvent(id, event, eventName) {
      if (id) {
        switch (id) {
          case 'completed-value-field':
            if (eventName === 'input_value_confirm') {
              this.setCompletedValueForReport(event);
            }
            break;
          case 'completed-percent-field':
            if (eventName === 'input_value_confirm') {
              this.setCompletedPercentForQaualitativeReport(event);
            }
            break;
          case 'csf-field':
            this.openCSFPicker(event);
            break;
          default:
            console.log('Unhandled switch case statement');
            break;
        }
      }
    }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'report-back':
        this.goBackToObjectiveDetails();
        break;
      case 'report-scrap':
        this.scrapReport();
        break;
      case 'report-send-for-approval':
        this.sendForApproval();
        break;
      case 'report-cancel-review':
        this.cancelreview();
        break;
      case 'report-manager-not-approve':
        this.disApproveReport();
        break;
      case 'report-manager-approve':
        this.approveReport();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  onSectionHeaderControlClick(id: string) {
    if (this.backgroundUploadInProgress) return;
    if (id === 'report-notes-save') {
        this.saveNotes();
    }else if (id == 'attach_note') {
      this.handleNoteAttachment();
    }
  }

  public removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
  }

  private handleNoteAttachment() {
    if (this.isNotesAttachmentEnabled) {
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        if ((event.target.files[0].size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)) {
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            // this.notesChanged();
            this.isSaveNotesEnabled = true;
            this.initreportNotesHeaderModel();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Note Attachment', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Note Attachment', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  get nonDeletedNotes() {
    return this.reportData.notes.filter(note => !note.isDeleted)
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return  note.ownerId == this.authService.user.systemUserID;
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      if (this.backgroundUploadInProgress) return;
      const idx = this.reportData.notes.findIndex(note => note.noteId === ev.noteId);
      let note = this.reportData.notes[idx];

      if (ev.action === 'DELETE' && idx >= 0) {
        note.isDeleted = true;
        if (note.hasDocument)
          note = this._resetDocumentDetailsOfNote(note);
      } else if (ev.action === 'SAVE' && idx >= 0) {
        note.noteText = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev);
        } else if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev)
        } else if (ev.isAttachmentRemoved) {
          note.hasDocument = false;
          note = this._resetDocumentDetailsOfNote(note);
        }
      }

      note.pendingPushForDynamics = true;
      this.reportData.notes[idx] = note;
      this.reportData.syncPending = true;
      await this._displayDismissLoader(true);

      await this.accountManagementDataService.updateProgressReport({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true,
        message: 'UPDATE_NOTES',
      },this.reportData).then(succ => {
        // Successfull action
      }).catch(err => {
        // To do error handling
      });
      await this._displayDismissLoader(false);
    }
  }

  private _updateAttachmentinNotes(note: IONote, ev: any) {
    note.hasDocument = true;
    note.documentSize = ev.documentSize;
    note.documentName = ev.documentName;
    note.documentMimeType = ev.documentMimeType;
    note.documentDataURL = ev.attachmentFileDataUrl;
    return note;
  }

  private _resetDocumentDetailsOfNote(note: IONote) {
    note.documentSize = 0;
    note.documentName = '';
    note.documentMimeType = '';
    note.documentDataURL = '';
    note.hasDocument = false;
    return note;
  }

  private async _displayDismissLoader(displayLoader: boolean) {

    if (displayLoader) {
      if (!this.device.isDeviceRealOffline && !this.device.isOffline) {
        await this.UIService.displayLoader();
      }
    } else {
      await this.UIService.dismissLoader();
    }
  }

  private _sortNotes(): void {
    if (this.reportData.notes && this.reportData.notes.length > 1) {
      this.reportData.notes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
    }
  }
}
