<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
        <ion-button class="back-btn" *ngIf="device.shouldBeMobileView " icon-only (click)="goBack()">
          <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-title text-center>
        {{'SETTINGS_SELECT_LANGUAGE' | translate}}
      </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list [(ngModel)]="selectedLanguageCode" (ionChange)="changeLanguage($event)">
<ion-radio-group>
    <ion-item *ngFor="let language of languages" (click)="changeLanguage(language.code)">
      <ion-label>{{language.name}}</ion-label>
      <ion-radio slot="start" [value]="language.code"></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
</ion-content>
