import { Component, ViewChild } from '@angular/core';
import { NavController,  IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService } from '../../../services/footer/footer.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { OrderManagementService } from '../../../services/order-management/order-management.service';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';

/**
 * Generated class for the OrderManagementPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'order-management-page[base-page]',
  templateUrl: 'order-management-page.html',
  styleUrls:['order-management-page.scss']
})
export class OrderManagementPageComponent {

  @ViewChild('ordermanagementrightpane', {static:true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    private footerService: FooterService,
    public trackingService: TrackService,
    public orderManagementService: OrderManagementService,
    private events: Events
  ) {
  }

  ngOnInit() {
    if (this.navService.getActiveChildNavViewPageName() == PageName.OrderDetailsComponent) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.OrderManagementNavigation, PageName.OrderManagementPageComponent, false, {deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');
  }

  ionViewDidEnter() {
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  ngOnDestroy(){
    this.events.publish("tools-back-clicked", PageName.OrderManagementPageComponent);
    this.orderManagementService.isSelectionPane = false;
  }

}
