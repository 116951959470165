<ng-container>
  <ng-container *ngIf="!device.isNativeApp">
    <button #leftButton [style.visibility]="showLeftButton ? 'visible' : 'hidden'" class="left-arrow" (click)="scrollLeft()">
      <ion-icon src="assets/imgs/arrow-left-white.svg"></ion-icon>
    </button>
  </ng-container>
  <ng-content>
  </ng-content>
  <ng-container *ngIf="!device.isNativeApp">
    <button [style.visibility]="showRightButton ? 'visible' : 'hidden'" class="right-arrow" (click)="scrollRight()">
      <ion-icon src="assets/imgs/arrow-right-white.svg"></ion-icon>
    </button>
  </ng-container>
</ng-container>