<div class='container'>
  <div class="videobox">
    <div #videoContainer class="videocontainer">
    </div>
  </div>
  <div class="transbox">
    <p style="font-size: 22px; text-align: center">
      {{initials}}
    </p>
  </div>
  <div class="text-element">
    <p>
      {{name}}
    </p>
  </div>
</div>