import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IndChipsDataModel } from '../../../models/ind-chips-data-model';
import { IonSlides } from '@ionic/angular';

/**
 * Generated class for the IndChipsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-chips',
  templateUrl: 'ind-chips.html',
  styleUrls:['ind-chips.scss']
})
export class IndChipsComponent {
  @Input() viewData: IndChipsDataModel[];
  @Input() selectedChip: IndChipsDataModel;
  @Input() size: "small" | "medium" | "large";
  @Output() selectedChipChanged = new EventEmitter<IndChipsDataModel>();
  @ViewChild(IonSlides) slides: IonSlides;
  
  constructor() {
  }

  chipSelected(value: IndChipsDataModel, index: number) {
    if (value.disable && !value.disable) {
      this.selectedChip = value;
    }
    if(index === (this.viewData.length - 1))
    this.slides.slideTo(index);
    this.selectedChipChanged.emit(value);
  }

}
