import { UIService } from './../../services/ui/ui.service';
import { Component, Input } from '@angular/core';

/**
 * Generated class for the MultiSelectFilterPopoverComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'multi-select-filter-popover',
  templateUrl: 'multi-select-filter-popover.html',
  styleUrls:['multi-select-filter-popover.scss']
})
export class MultiSelectFilterPopoverComponent {

  @Input() headerText: string = '';
  @Input() optionsData: any;
  filterOptions: {
    text: string,
    value: string,
    checked: boolean
  }[]

  constructor(
    public uiService: UIService
  ) {
    console.log('Hello MultiSelectFilterPopoverComponent Component');
  }
  ngOnInit() {
    this.filterOptions = this.optionsData;
  }
  multiSelectOptionClicked(item){
    item.checked = !item.checked;
    this.uiService.multiSelectFilterSelectedData = this.filterOptions.filter(o=> o.checked)
  }
}
