import { Component } from "@angular/core";
import { DeviceService } from "@omni/services/device/device.service";

const NumberOfSkeletonCardsToDisplay = 5;

@Component({
  selector: 'left-pane-undefined-default',
  templateUrl: 'left-pane-undefined-default.html',
  styleUrls: ['left-pane-undefined-default.scss'],
})
export class LeftPaneUndefinedDefaultComponent {
  isAndroid = false;
  numberOfSkeletonCardsToDisplay = NumberOfSkeletonCardsToDisplay;

  constructor(
    private device: DeviceService,
  ) {
    this.isAndroid = this.device.isAndroid();
  }
}
