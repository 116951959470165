import { ApprovalReasonsConfigFormat } from './../../classes/approvals/approval-reasons-config.class';
import { Component, Input, OnInit } from '@angular/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { TranslateService } from '@ngx-translate/core';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { UIService } from '@omni/services/ui/ui.service';
import { PhotoAttachmentDto } from '@omni/classes/store-check/photo-attachment';
import { IndPhotoViewerDataModel } from '@omni/models/indPhotoViewerDataModel';
import { IndPhotoViewerComponent } from '../shared/ind-photo-viewer/ind-photo-viewer';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import _ from 'lodash';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { Activity } from '@omni/classes/activity/activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { ActivitiesDetailsPaneComponent } from '../activity/activities-details-pane/activities-details-pane';
import { AppealService } from '@omni/services/appeal/appeal.service';
import { GPSApprovalActivity } from '@omni/classes/approvals/gps-approval-activity.class';
import { GPS_STATUS } from '@omni/services/location/location.service';
import { DatePipe } from '@angular/common';
import { GPSActivity } from '@omni/classes/store-check/photo';
import { AppealDataService } from '@omni/data-services/appeal/appeal.data.service';
import { DeviceService } from '@omni/services/device/device.service';
import { AlertWithInput } from '../shared/alert-with-input/alert-with-input';
import { PopoverController } from '@ionic/angular';
import { ApprovalStatus } from '@omni/classes/quotes/quotes.class';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ApprovalReasonsConfigType } from '@omni/classes/approvals/approval-reasons-config.class';

@Component({
  selector: 'app-appeal-details',
  templateUrl: './appeal-details.component.html',
  styleUrls: ['./appeal-details.component.scss'],
})
export class AppealDetailsComponent implements OnInit {

  public pageTitle: IndPageTitleViewDataModel;

  @Input()
  from;
  @Input()
  selectedMode;
  @Input()
  callBackEvent;
  @Input()
  selectedActivity: GPSApprovalActivity;
 
  accountFormField: IndFormFieldViewDataModel;
  locationFormField: IndFormFieldViewDataModel;
  statusFormField: IndFormFieldViewDataModel;
  repFormField: IndFormFieldViewDataModel;
  approverFormField: IndFormFieldViewDataModel;
  sentForApprovalFormField: IndFormFieldViewDataModel;
  positionFormField: IndFormFieldViewDataModel;
  reasonFormField: IndFormFieldViewDataModel;

  checkInCoFormField: IndFormFieldViewDataModel;
  checkOutCoFormField: IndFormFieldViewDataModel;
  checkInStatusFormField: IndFormFieldViewDataModel;
  checkOutStatusFormField: IndFormFieldViewDataModel;
  public checkInPhotoAttachments: PhotoAttachmentDto[] = [];
  public checkOutPhotoAttachments: PhotoAttachmentDto[] = [];
  ngUnsubscribe$ = new Subject<boolean>();

  constructor(
    private navService: NavigationService,
    public translate: TranslateService,
    private uiService: UIService,
    public footerService: FooterService,
    private activityDataService: ActivityDataService,
    private activityService: ActivityService,
    private appealService: AppealService,
    private datePipe: DatePipe,
    private appealDataService: AppealDataService,
    private device: DeviceService,
    private readonly popoverCtrl: PopoverController,
    private readonly notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.footerService.initButtons(FooterViews.APPEAL_DETAILS);
    this.initPageTitle();
    this.initAccountFormField();
    this.initLocationFormField();
    this.initStatusFormField();
    this.initRepFormField();
    this.initApproverFormField();
    this.initSentForApprovalFormField();
    this.initPositionFormField();
    this.initReasonFormField();
    this.initCheckInCoFormField();
    this.initCheckOutCoFormField();
    this.initCheckInStatusFormField();
    this.initCheckOutStatusFormField();
    this.checkInPhotoAttachments = [];
    this.checkOutPhotoAttachments = [];
    const gpsActivityPhotos = this.selectedActivity.gpsActivityPhotos ?? [];
    gpsActivityPhotos.map(at => {
      if (at['indskr_type'] == GPSActivity.CHECKIN) {
        this.checkInPhotoAttachments.push({ photos: at['photoAttachments'] });
      } else {
        this.checkOutPhotoAttachments.push({ photos: at['photoAttachments'] });
      }
    });
    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(isOffline => {
      this.updateFooterButtonStatus();
    })
    this.updateFooterButtonStatus();
  }

  private initPageTitle() {
    const controls = [];
    if(this.from === PageName.NotificationDetailsComponent) {
      controls.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      })
    }
    this.pageTitle = {
      id: 'appeal-details-page-title',
      title: this.translate.instant('CHECK_IN_OUT_APPEAL') + ' - ' + this.selectedActivity.accountName,
      controls: controls
    }
  }

  private initAccountFormField() {
    this.accountFormField = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.selectedActivity.accountName,
      id: 'account-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initLocationFormField() {
    this.locationFormField = {
      label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
      inputText: _.isEmpty(this.selectedActivity.location) ? this.translate.instant('NO_LOCATION') : this.selectedActivity.location.substring(0, 18).concat("..."),
      id: 'location-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initStatusFormField() {
    this.statusFormField = {
      label: this.translate.instant('APPROVAL_STATUS'),
      inputText: this.appealService.getStatusFormattedValue(this.selectedActivity.statuscode),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initRepFormField() {
    this.repFormField = {
      label: this.translate.instant('REPRESENTATIVE'),
      inputText: this.selectedActivity.ownerName,
      id: 'rep-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false
    }
  }

  private initApproverFormField() {
    this.approverFormField = {
      label: this.translate.instant('APPROVER'),
      inputText: this.selectedActivity.ownerName,
      id: 'approver-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false
    }
  }

  private initSentForApprovalFormField() {
    this.sentForApprovalFormField = {
      label: this.translate.instant('SENT_FOR_APPROVAL'),
      inputText: this.appealService.getDateFormatted(this.selectedActivity.sentForApproval),
      id: 'sent-for-approval-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initPositionFormField() {
    this.positionFormField = {
      label: this.translate.instant('POSITION'),
      inputText: this.selectedActivity.positionName,
      id: 'position-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initReasonFormField() {
    if (this.selectedActivity.reason)
      this.reasonFormField = {
        label: this.translate.instant('REASON'),
        inputText: this.selectedActivity.reason,
        id: 'reason-field',
        isReadOnly: true,
        isDisabled: true,
        showArrow: false,
      }
  }

  private initCheckInCoFormField() {
    this.checkInCoFormField = {
      label: this.translate.instant('CHECK_IN_COORDINATES'),
      inputText: '(' + this.selectedActivity.gpsDetails.indskr_checkinlatitude + ', ' + this.selectedActivity.gpsDetails.indskr_checkinlongitude + ')',
      id: 'checkin-coordinate-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initCheckOutCoFormField() {
    this.checkOutCoFormField = {
      label: this.translate.instant('CHECK_OUT_COORDINATES'),
      inputText: '(' + this.selectedActivity.gpsDetails.indskr_checkoutlatitude + ', ' + this.selectedActivity.gpsDetails.indskr_checkoutlongitude + ')',
      id: 'checkout-coordinate-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isHidden: !this.selectedActivity.gpsDetails.indskr_checkoutlatitude && !this.selectedActivity.gpsDetails.indskr_checkoutlongitude
    }
  }

  private initCheckInStatusFormField() {
    this.checkInStatusFormField = {
      label: this.translate.instant('CHECK_IN__STATUS'),
      inputText: this.datePipe.transform(this.selectedActivity.gpsDetails.indskr_checkindatetime, 'shortTime') + ' | ' + this.translate.instant(this.selectedActivity.gpsDetails.indskr_checkinstatus === GPS_STATUS.VALID ? 'VALID' : 'MANUAL'),
      id: 'checkin-status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private initCheckOutStatusFormField() {
    this.checkOutStatusFormField = {
      label: this.translate.instant('CHECK_OUT__STATUS'),
      inputText: this.datePipe.transform(this.selectedActivity.gpsDetails.indskr_checkoutdatetime, 'shortTime') + ' | ' + this.translate.instant(this.selectedActivity.gpsDetails.indskr_checkoutstatus === GPS_STATUS.VALID ? 'VALID' : 'MANUAL'),
      id: 'checkout-status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    }
  }

  private updateFooterButtonStatus() {
    //To reset state of buttons 
    this.footerService.enableButtons(['viewMeeting', 'approve', 'reject']);

    this.footerService.initButtons(FooterViews.APPEAL_DETAILS);
    if (this.device.isOffline) {
      this.footerService.disableButton(['viewMeeting', 'approve', 'reject']);
    }
    else if (this.selectedActivity.statuscode != ApprovalStatus.PENDING || this.selectedMode == 1) {
      this.footerService.disableButton(['approve', 'reject']);
    }
  }

  onPageTitleControlClick(id) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      default:
    }
  }

  private closePage() {
    if (this.from === PageName.NotificationDetailsComponent) {
      this.navService.popChildNavPageWithPageTracking();
    } else {
      this.uiService.showRightPane = false;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }
  }

  public openPhotoViewerForGPSActivity(photoAttachment, activityType) {
    let header;
    let source;
    let activityPhotos
    if (activityType == 'CHECKIN') {
      header = 'MANUAL_CHECKIN';
      activityPhotos = this.selectedActivity.gpsActivityPhotos.find(activityPhoto => activityPhoto.indskr_type == 548910000);
    } else {
      header = 'MANUAL_CHECKOUT';
      activityPhotos = this.selectedActivity.gpsActivityPhotos.find(activityPhoto => activityPhoto.indskr_type == 548910001);
    }
    activityPhotos.photoAttachments.forEach(photo => photo.isSelected = photoAttachment.indskr_photoattachmentid === photo.indskr_photoattachmentid);
    source = _.cloneDeep(activityPhotos.photoAttachments);
    const photoModel: IndPhotoViewerDataModel = {
      pageTitle: this.translate.instant(header),
      masterPhotos: source,
      readOnly: true,
      callbackEvent: (data: []) => this._handlePhotoViewerCallback(data, photoAttachment)
    };
    this.navService.pushWithPageTracking(IndPhotoViewerComponent, PageName.IndPhotoViewerComponent, { photoModel: photoModel }, PageName.IndPhotoViewerComponent);
  }

  private _handlePhotoViewerCallback(data, photoAttachment) {
  }

  footerButtonClicked(id) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Approval list', 'top', ToastStyle.DANGER);
      return;
    }
    switch (id) {
      case 'viewMeeting':
        this.openMeetingDetails();
        break;
      case 'approve':
        this.updateApprovalStatus();
        break;
      case 'reject':
        this.updateApprovalStatus(true);
        break;
      default:
        break;
    }
  }

  private async openMeetingDetails() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Approval list', 'top', ToastStyle.DANGER);
      return;
    }
    await this.uiService.displayLoader();
    const activity = new AppointmentActivity({ activityId: this.selectedActivity.activityId, activitytypecode: 'appointment' });
    this.activityService.selectedActivity = await this.activityDataService.getRealTimeActivityDetails(activity);
    await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent,
      PageName.AppealDetailsComponent,
      {
        from: PageName.AppealDetailsComponent,
        callbackEvent: () => this.updateFooterButtonStatus()
      });
    await this.uiService.dismissLoader();
  }

  private async updateApprovalStatus(reject: boolean = false) {
    this.uiService.showRightPane = false;
    let reasons = [];

    if(!reject) {
      reasons = this.appealDataService.approvalReasonsConfigForGPSCheckinDetails.filter((item) =>{
        return item.type == ApprovalReasonsConfigType.APPROVE_REASON 
      });
    } else {
      reasons = this.appealDataService.approvalReasonsConfigForGPSCheckinDetails.filter((item) =>{
        return item.type == ApprovalReasonsConfigType.NON_APPROVE_REASON 
      });
    }

    if (!_.isEmpty(reasons)) {
      const isReasonMandatory = reasons[0].isMandatory;
      const isReasonFreeText = reasons[0].format === ApprovalReasonsConfigFormat.FREE_TEXT ? true: false;
      let fetchedReasons = [];

      if(!isReasonFreeText) {
        fetchedReasons = reasons.map(res => {
          return {
            type: 'radio',
            name: res.name,
            label: res.name,
            value: res.indskr_approvalreasonlistid
          }
        })
      }

      const popover = await this.popoverCtrl.create({
        component: AlertWithInput,
        componentProps: {
          header: (!reject ? this.translate.instant("CONFIRM_APPROVE") : this.translate.instant("CONFIRM_NON_APPROVE")),
          message:  isReasonFreeText ? this.translate.instant("ENTER_COMMENTS") : '',
          inputs: isReasonFreeText ? [] : fetchedReasons,
          cancelText: this.translate.instant('CANCEL'),
          confirmText: (!reject ? this.translate.instant("APPROVE") : this.translate.instant("NON_APPROVE")),
          Placeholder: isReasonFreeText ? this.translate.instant('COMMENTS') : '',
          isMandatory: isReasonMandatory,
        },
        cssClass: isReasonFreeText ? 'alert-with-input-list-view' : 'alert-with-input-list-view-for-meeting-content-reason',
        backdropDismiss: true,
        event: this.device.isNativeApp ? event : null
      });
      popover.present();
      popover.onDidDismiss().then(async (res) => {
        if (res.data && res.data.role && res.data.role == 'ok') {
          if (this.device.isOffline) {
            this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Approval list', 'top', ToastStyle.DANGER);
            return;
          }

          let payload = {
            statuscode: (!reject ? 548910001 : 548910002)
          };
          if (!isReasonFreeText) {
            if(res.data.inputs) {
              payload['indskr_reason'] =  res.data.inputs.label;
              payload['indskr_ApprovalReason@odata.bind'] =  `indskr_approvalreasonlists(${res.data.inputs.value})`;
            }
            else {
              payload['indskr_reason'] = null;
            }
          } else {
            payload['indskr_reason'] =  res.data.selectedOtherItem ? res.data.selectedOtherItem : null;
          }

          //To force user to enter reason for rejection in case of Free T
          if(reject && payload['indskr_reason'] === null) {
            return this.notificationService.notify(this.translate.instant('PLEASE_ENTER_REASON_FOR_REJECTION'), 'Meeting Details', 'top', ToastStyle.DANGER, 3000);
          }

          await this.uiService.displayLoader();
          try {
            await this.appealDataService.updateApprovalActivity(payload, this.selectedActivity.indskr_approvalactivityid);
          } catch (error) {
            console.error('updateApprovalStatus: ', error);
            await this.uiService.dismissLoader();
            return;
          }

          console.warn('updateApprovalStatus: update service success: ');
          this.selectedActivity.statuscode = payload.statuscode;
          this.initStatusFormField();
          this.updateFooterButtonStatus();
          if (this.callBackEvent) {
            this.callBackEvent(this.selectedActivity);
          }
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
          await this.uiService.dismissLoader();
        }
      });
    } else {
      console.error('Approval Reason Configuration is not found. Please contact admin.');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
