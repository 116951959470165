<ion-header>
    <ind-page-title [viewData]="headerModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content no-padding>
    <plans-predictions [source]="plansAndPredictions" [segment]="impactDetails.segment"></plans-predictions>
    <journey-insights [source]="impactDetails.insights.journey"></journey-insights>
    <interest-insights [source]="impactDetails.insights.interest"></interest-insights>
    <relationship-insights [source]="impactDetails.insights.relationship"></relationship-insights>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'impact-details'"></footer-toolbar>
<!-- </ion-footer> -->