import { MbscModule } from '@mobiscroll/angular';
import { CdkTableModule } from '@angular/cdk/table';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuildInfo } from '@awesome-cordova-plugins/build-info/ngx';
import { ChartModule } from 'angular-highcharts';
import { ServerErrorsInterceptor } from './services/error-handler/server-error.interceptor';
import { GlobalErrorHandler } from './services/error-handler/error-handler-service';
import { FileManagerEffects } from './store/io-file-service/effects/file.effect';
import { ResourceManagerEffects } from './store/io-file-service/effects/resource.effect';

import { ErrorHandler, Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { MyApp } from './app.component';


import { Network } from '@awesome-cordova-plugins/network/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';

//Resources

import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './store/application.reducer';
import { INITIAL_APP_STATE } from './store/application.state';

import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { NativePageTransitions } from '@awesome-cordova-plugins/native-page-transitions/ngx';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
// idle module and moment js stuff

import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import {
  SecureStorage,
} from '@awesome-cordova-plugins/secure-storage/ngx';

//

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { IonicRouteStrategy } from '@ionic/angular';

import { LaunchDarklyProvider } from './providers/launch-darkly/launch-darkly';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
//

import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import Hammer from 'hammerjs';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class hammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { velocity: 0.4, threshold: 20, direction: Hammer.DIRECTION_ALL}, // override default settings
  } as any;
}

import { Device } from '@awesome-cordova-plugins/device/ngx';


import { VirtualListModule } from './components/angular-virtual-list';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';


import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { OfflineSyncUtility } from './utility/offline-sync.utility';
import { components, pipes } from './components';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { PaginatorModule } from 'primeng/paginator';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { OpentokModule } from './components/opentok/ot.module';
import { DatePipe } from '@angular/common';
import { CalendarModule } from '@exeevo/ion2-calendar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TooltipModule } from '@amin-karimi/ng2-tooltip-directive';
import { QRCodeModule } from 'angularx-qrcode';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    MbscModule,
    ScrollingModule,
    OpentokModule,
    ReactiveFormsModule,
    VirtualListModule,
    PdfJsViewerModule,
    PdfViewerModule,
    TranslateModule, // translate Module
    DragDropModule,
    TranslateModule, // translate Module
    RouterModule.forRoot([], {}),
    FormsModule,
    IonicModule.forRoot({animated: false, mode: 'ios', swipeBackEnabled: false, _forceStatusbarPadding: true }),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChartModule,
    CdkTableModule,
    HammerModule,
    PaginatorModule,
    StoreModule.forRoot(appReducers, { initialState: INITIAL_APP_STATE }),
    EffectsModule.forRoot([FileManagerEffects, ResourceManagerEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EditorModule,
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true,
      canBackwardsSelected: true,
    }),
    NgxMaterialTimepickerModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TooltipModule.forRoot({
      'placement': 'bottom',
      'show-delay': 500,
      'theme': 'light',
      'displayTouchscreen': true,
      'tooltip-class': 'custom-tooltip',
      'offset':4
    }),
    QRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  bootstrap: [MyApp],
  providers: [
    CameraPreview,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StatusBar,
    BuildInfo,
    Network,
    DatePipe,
    Geolocation,
    NativeGeocoder,
    File,
    Deeplinks,
    SecureStorage,
    FileTransfer,
    FileOpener,
    Clipboard,
    NativePageTransitions,
    InAppBrowser,
    Diagnostic,
    ScreenOrientation,
    ErrorHandler,
    BarcodeScanner,
    //{ provide: ErrorHandler, useClass: IonicErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    AndroidPermissions,

    LaunchDarklyProvider,

    OfflineSyncUtility,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: hammerConfig
    },

    Device,
    DatePipe,
    SocialSharing,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    Keyboard
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
  constructor(){
    console.log('module');
  }
}
