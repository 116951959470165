<ion-header>
    <ind-page-title [viewData]="calendarInvitePageTitle" (onControlClick)="onPageTitleControlClick($event)">
    </ind-page-title>
</ion-header>
<ion-content color="light" #scroller>
    <ion-list id="details">

        <ind-section-header [viewData]="detailsHeader">
        </ind-section-header>

        <ind-form-field [viewData]="statusFormField"></ind-form-field>

        <div class="contact-selection-main">
            <div class="contact-wrapper">
                <p class="toLabel">{{globalCustomerText}}</p>
                <div item-content>
                    <ion-row>
                        <ion-col class="fat-col">
                            <ng-container *ngIf="activity?.contacts.length > 0 else contactPlaceholder">
                                <div class="contact-selected-list" id="contactSelectedArea">
                                    <div class="contacts-text ion-text-end" slot="end">

                                        <span class="contact-details" *ngFor="let contact of activity?.contacts" (click)="onContactClick($event, contact)">
                      <span [id]="contact.ID" class="contact-name">
                        <span class="custom-contact">
                          <span class="text-margin">{{contact.fullname}}</span>
                                        </span>
                                        </span>
                                        </span>

                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #contactPlaceholder>
                                <p class="form-field-placeholder-text">
                                    <ion-text>{{globalCustomerText}}</ion-text>
                                </p>
                            </ng-template>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
            <div class="contact-bottom-border"></div>
        </div>

        <div class="contact-selection-main">
            <div class="contact-wrapper">
                <p class="toLabel">{{'ACTIVITY_DETAILS_COVISITORS' | translate}}</p>
                <div item-content>
                    <ion-row>
                        <ion-col class="fat-col">
                            <ng-container *ngIf="activity?.accompaniedUserList.length > 0 else covisitorPlaceholder">
                                <div class="contact-selected-list" id="contactSelectedArea">
                                    <div class="contacts-text ion-text-end" slot="end">

                                        <span class="contact-details" *ngFor="let coVisitor of activity?.accompaniedUserList">
                        <span [id]="coVisitor.id" class="contact-name">
                          <span class="custom-covisitor">
                            <span class="text-margin">{{coVisitor.name}}</span>
                                        </span>
                                        </span>
                                        </span>

                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #covisitorPlaceholder>
                                <p class="form-field-placeholder-text">
                                    <ion-text>{{'ACTIVITY_DETAILS_COVISITORS' | translate}}</ion-text>
                                </p>
                            </ng-template>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
            <div class="contact-bottom-border"></div>
        </div>

        <ind-form-field *ngFor="let formField of formFields" [viewData]="formField"></ind-form-field>

        <ind-section-header id="agenda-header" [viewData]='agendaHeader' (onControlClick)='onAgendaSectionHeaderControlClick($event)'>
        </ind-section-header>

        <div class="agenda-editor">
            <editor #editor [(ngModel)]="editorContent" [init]="tinyMceConfig"></editor>
        </div>
    </ion-list>
</ion-content>

<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'calendarInvite'" (buttonClicked)="onFooterButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->

<io-spinner *ngIf="(isLoading | async) > 0"></io-spinner>