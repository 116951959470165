import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShortCallField, ShortCallFilterField } from '@omni/classes/short-call/short-call.class';
import _ from 'lodash';

@Component({
  selector: 'io-content-filter',
  templateUrl: './io-content-filter.html',
  styleUrls: ['./io-content-filter.scss'],
})
export class IoContentFilter implements OnInit {

  @Input()
  public tags: ShortCallFilterField[] = [];
  private clonedInputTags: ShortCallFilterField[];
  public confirmDisabled: boolean = true;
  public resetDisabled: boolean = false;
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { 
    this.clonedInputTags = _.cloneDeep(this.tags);
  }

  

  public closeModal(type: string) {
    if (type == 'reset') {
      this.tags.forEach(p => {
        p.selected = false;
        p.tags.forEach(t => t.selected = false);
      })
    }
    this.modalCtrl.dismiss({ type: type, tags: this.tags, modified: !_.isEqual(this.tags, this.clonedInputTags) });
  }

  public selectParentTag(event: Event, parent: ShortCallField) {
    event.stopPropagation();
    const selectedParent = this.tags.find(field => field.value === parent.value);
    selectedParent.selected = !selectedParent.selected;
    if (selectedParent) {
      selectedParent.tags.forEach(tag => {
        tag.selected = selectedParent.selected;
      });
    }
    this.confirmDisabled = _.isEqual(this.tags, this.clonedInputTags);
  }

  public selectChildTag(event: Event, parent: ShortCallFilterField, child: ShortCallField) {
    event.stopPropagation();
    child.selected = !child.selected;
    parent.selected = parent.tags.filter(t => !t.selected).length == 0;
    this.confirmDisabled = _.isEqual(this.tags, this.clonedInputTags);
  }

}
