<ion-header>
    <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
      <ion-buttons slot="start">
        <ion-button icon-only (click)="onCloseModal()" class="back-btn">
            <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title text-center>{{'ACCOUNT_LIST_RO_MEETING_ACCOUNT' | translate}}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list>
      <ion-item *ngFor="let selAccount of $any(activityService.selectedActivity)?.accounts" (click)="openAccountDetails(selAccount)">
        {{selAccount.accountName}}
      </ion-item>
    </ion-list>
  </ion-content>
