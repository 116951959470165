<ion-header>
    <ind-page-title [viewData]="eventDetailsPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<!-- <ion-item no-lines hidden="true">
  <ion-datetime #appConfigFieldDatePicker
    [displayFormat]="dateTimeFormatsService.dateTimeToUpper"
    (ionCancel)="updateDateConfigField(false, appConfigFieldDatePicker.value)"
    (ionChange)="updateDateConfigField(true, appConfigFieldDatePicker.value)"
    [(ngModel)]="configFieldDateValue"
    [pickerFormat]="dateTimeFormatsService.dateTimeToUpper"
    [monthShortNames]="shortMonth | monthTranslate"
    [doneText]="this.translate.instant('DONE')"
    [cancelText]="this.translate.instant('CANCEL')"
    display-timezone="utc"
  >
  </ion-datetime>
</ion-item> -->
<!-- <ion-item no-lines hidden="true">
  <ion-datetime #genericDatePicker
    [displayFormat]="genericDatePickerDisplayFormat"
    (ionCancel)="updateGenericDatePickerField(false, genericDatePicker.value)"
    (ionChange)="updateGenericDatePickerField(true, genericDatePicker.value)"
    [(ngModel)]="genericDatePickerValue"
    [pickerFormat]="genericDatePickerPickerFormat"
    [monthShortNames]="shortMonth | monthTranslate"
    [doneText]="this.translate.instant('DONE')"
    [cancelText]="this.translate.instant('CANCEL')"
    [max]="genericDatePickerMaxValue"
  >
  </ion-datetime>
</ion-item> -->
<ion-content>
    <ind-section-header [viewData]='eventDetailsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ind-form-field [viewData]='statusField'></ind-form-field>
    <ind-form-field [viewData]='subjectField'></ind-form-field>
    <ion-row>
      <ion-col size="6"><ind-datetime-form [viewData]="startDateField"></ind-datetime-form></ion-col>
      <ion-col size="6"><ind-datetime-form [viewData]="startTimeField"></ind-datetime-form></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6"><ind-datetime-form [viewData]="endDateField"></ind-datetime-form></ion-col>
      <ion-col size="6"><ind-datetime-form [viewData]="endTimeField"></ind-datetime-form></ion-col>
    </ion-row>
    <activity-conflict-alert-msg *ngIf="displayConflictMsg"></activity-conflict-alert-msg>
    <ng-container *ngIf="isCutOffDateFeatureEnabled">
      <ind-form-field [viewData]="attendeeFormatField"></ind-form-field>
      <ind-form-field [viewData]="cutOffDateField"></ind-form-field>
    </ng-container>
    <ind-form-field [viewData]='locationField'></ind-form-field>
    <ind-form-field [viewData]='buildingField'></ind-form-field>
    <ind-form-field [viewData]='eventCapacityField'></ind-form-field>
    <ind-form-field *ngIf="isEventGoalEnabled" [viewData]='goalField'></ind-form-field>
    <ind-form-field [viewData]='typeField'></ind-form-field>
    <ind-form-field [viewData]='subTypeField'></ind-form-field>
    <ind-form-field [viewData]='budgetAllocatedField'></ind-form-field>
    <ind-form-field [viewData]='currencyField'></ind-form-field>

    <ind-form-field *ngFor="let formField of configFields" [viewData]="formField"></ind-form-field>

    <ind-form-field [viewData]='accountsField'></ind-form-field>
    <ng-container *ngIf="isCutOffDateFeatureEnabled">
      <ind-form-field [viewData]="selectionCriteriaField"></ind-form-field>
    </ng-container>
    <ind-form-field [viewData]='participantsField'></ind-form-field>
    <ind-form-field [viewData]='productsField'></ind-form-field>
    <ind-form-field [viewData]='covisitorsField'></ind-form-field>
    <ind-form-field [viewData]='speakersField'></ind-form-field>
    <meeting-structure (openNewActivityPage)="openNewActivityPage()" [meetingStructureAccessedFrom]="'EventDetailsPage'"></meeting-structure>
    <ind-section-header *ngIf="isNotesEnabled"[viewData]='eventNotesHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ind-text-area *ngIf="!isReadOnlyMode && isNotesEnabled" [placeHolder]="'ENTER_EVENT_DESCRIPTION' | translate" [disabled]="isReadOnlyMode" [maxLength]="1000" [(value)]="currentNotesString" (indChange)="triggerSectionHeaderUpdate($event)"  [rows]="4" [cols]="10">
    </ind-text-area>
    <ion-item *ngIf="isReadOnlyMode && isNotesEnabled" class="emptysection">
        <ion-label text-wrap>{{currentNotesString}}</ion-label>
    </ion-item>
    <!-- Event Notes-->
    <ind-section-header
      [viewData]="notesHeader"
      (onControlClick)='onSectionHeaderControlClick($event)'
    ></ind-section-header>
    <ind-text-area
      id="notesInput"
      [placeHolder]="notesPlaceholder"
      [showAttachment]="isAttachmentAdded"
      [attachmentTitle]="attachmentTitle"
      [maxLength]="1000"
      (indChange)="notesChanged($event)"
      [value]="tempNoteText"
      (removeAttachment)="removeAttachment($event)"
      [rows]="4"
      [cols]="10"
      [debounce]="150"
      [disabled]="!isEventOwnerOrTeamMember || isEventCompleted || isOffline"
    ></ind-text-area>
    <div *ngIf="eventsToolService.pastEventAttachments.length > 0" class="sectionsList section-event-attachments">
      <ng-container *ngFor="let event of eventsToolService.pastEventAttachments">
        <ind-section-header [viewData]='{id:event.eventId, title: event.eventName}'></ind-section-header>
        <ion-item-group *ngFor="let attachment of event.attachments" (click)="selectPastEventAttachment(attachment, $event)">
          <ion-item>
            <ion-checkbox [checked]="attachment.checked == true">
            </ion-checkbox>
            <ion-label>{{attachment.filename}}</ion-label>
          </ion-item>
        </ion-item-group>
      </ng-container>
    </div>
    <input class="hide-input" #attachInput type="file" (change)="loadFileFromDevice($event)">
    <io-note
      *ngFor="let note of currentEvent?.annotations"
      [isNoteAttachmentEnabled]="true"
      [inputNote]="note"
      [editable]="canEditNote(note)"
      [readOnlyNote]="false"
      [readOnlyAttachment]="false"
      [deletable]="canDeleteNote(note)"
      (saveNoteOut)="handleUpdateNoteBtnClick($event)"
      [disableDownloadInOffline]="true"
      [from]="pageName"
    ></io-note>

</ion-content>
<!-- <ion-footer> -->
<footer-toolbar  #footer [footerView]="'detailView'" [selectedView]="'activities'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->
