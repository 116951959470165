import { createSelector } from '@ngrx/store';
import { CoachingActivity } from "./activity.class";
import { AssessmentCategory } from "./assessment-category.class";
import { AssessmentRating } from "./assessment-rating.class";
import { IONote } from '@omni/classes/io/io-note.class';

export class Report {
    constructor(
        public _id: string,
        public _rev: String,
        public indskr_coachingreportid: String,
        public indskr_periodenddate: number,
        public indskr_coachingfor_value: String,
        public indskr_periodstartdate: number,
        public owninguser_value: String,
        public indskr_name: String,
        public indskr_ratingssummary: String,
        public indskr_recommendations: String,
        public statuscode: number,
        public statecode: number,
        public statusName: string,
        public min_rating: CoachingRating,
        public max_rating: CoachingRating,
        public created_by: string,
        public createdon: number,
        public created_for: string,
        public created_for_firstname: String,
        public indskr_dateacknowledged: string | number,
        public indskr_shareddate: string | number,
        public indskr_accepteddate: string | number,
        public indskr_dateforreview: string | number,
        public track_action: number,
        public formattedTrack_action: String,
        public offlineCoachingReportId: String,
        public meetings: CoachingActivity[],
        public phonecalls: CoachingActivity[],
        public procedureLogs: CoachingActivity[],
        public categories: AssessmentCategory[],
        public indskr_coachingtemplateid: string,
        public indskr_coachingtemplateidname: string,
        public indskr_coachingqualifyingscore: string,
        public indskr_coachingscore: string,
        public indskr_coachingsessions?: number,
        public indskr_coachingduration?: Number,
        public indskr_coachingrefno?: string,
        public indskr_coachingplan?: string,
        public indskr_coachingplanname?: string,
        public indskr_overallrating?: number,
        public indskr_coachingtype?: number,
        public indskr_contact?: string,
        public indskr_contactname?: string,
        public indskr_account?: string,
        public indskr_accountname?: string,
        public annotations?: IONote[],
        public deleted: Boolean = false) { }

    get DTO(): Object {
        let DTOResponse = {
            offlineCoachingReportId: this.offlineCoachingReportId,
            indskr_coachingreportid: this.indskr_coachingreportid,
            deleted: this.deleted,
            indskr_periodenddate: this.indskr_periodenddate,
            indskr_coachingfor_value: this.indskr_coachingfor_value,
            indskr_periodstartdate: this.indskr_periodstartdate,
            indskr_name: this.indskr_name,
            indskr_ratingssummary: this.indskr_ratingssummary,
            indskr_recommendations: this.indskr_recommendations,
            statuscode: this.statuscode,
            statecode: this.statecode,
            min_rating: this.min_rating,
            max_rating: this.max_rating,
            indskr_coachingtemplateid: this.indskr_coachingtemplateid ? this.indskr_coachingtemplateid : "",
            indskr_coachingtemplateidname: this.indskr_coachingtemplateidname ? this.indskr_coachingtemplateidname : "",
            indskr_coachingqualifyingscore: this.indskr_coachingqualifyingscore ? this.indskr_coachingqualifyingscore : "",
            indskr_coachingscore: this.indskr_coachingscore ? this.indskr_coachingscore : "",
            meetings: (this.meetings && this.meetings.length > 0) ? this.meetings : null,
            phonecalls: (this.phonecalls && this.phonecalls.length > 0) ? this.phonecalls : null,
            categories: (this.categories && this.categories.length > 0) ? this.categories : [],
            _id: this._id,
            indskr_dateacknowledged: this.indskr_dateacknowledged,
            indskr_shareddate: this.indskr_shareddate,
            indskr_accepteddate: this.indskr_accepteddate,
            indskr_dateforreview: this.indskr_dateforreview,
            createdon: this.createdon,
            indskr_coachingsessions: this.indskr_coachingsessions,
            indskr_coachingduration: this.indskr_coachingduration,
            indskr_coachingrefno: this.indskr_coachingrefno,
            indskr_coachingplan: this.indskr_coachingplan,
            indskr_coachingplanname: this.indskr_coachingplanname,
            indskr_overallrating: this.indskr_overallrating,
            indskr_contact: this.indskr_contact,
            indskr_contactname: this.indskr_contactname,
            indskr_account: this.indskr_account,
            indskr_accountname: this.indskr_accountname,
        }
        return DTOResponse;
    }

}

export enum CoachingReportStatus {
  Status_1 = 'OPEN',
  Status_2 = 'PENDING_SYNC', // Open - Shared Offline
  Status_3 = 'PENDING_SYNC', // Shared - Accept Offline
  Status_4 = 'PENDING_SYNC', // Accept - For Review Offline
  Status_5 = 'PENDING_SYNC', // For Review - Acknowledge Offline
  Status_548910002 = 'SHARED',
  Status_548910003 = 'ACCEPTED',
  Status_548910001 = 'FOR_REVIEW',
  Status_548910000 = 'ACKNOWLEDGED',
  Status_548910004 = 'CLOSED',
  Status_548910005 = 'COMPLETED'
}

export enum CoachingType {
  Type_548910000 = 'Initial',
  Type_548910001 = 'Final',
  Type_548910002 = 'Discussion Notes',
  Type_548910003 = 'Field Travel Notes'
}

export enum CoachingTrackStatus {
    Removed = 548910001
}

export class CoachingRating {
    constructor(public value: number = 0, public exist: boolean = false) { }
}

