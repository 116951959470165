import { Injectable } from "@angular/core";
import { AuthenticationService } from '@omni/services/authentication.service';

const SYNC_FLOW_WENT_OFFLINE_AT = 'offline.sync.flow.wentOfflineAt';
const SYNC_FLOW_CLICK_REMIND_ME_LATER_AT = 'offline.sync.flow.remindLaterAt';

@Injectable({
  providedIn: 'root'
})
export class OfflineSyncUtility {

  constructor(private authService: AuthenticationService) {

  }

  public clear() {
    localStorage.setItem(SYNC_FLOW_WENT_OFFLINE_AT, null);
    localStorage.setItem(SYNC_FLOW_CLICK_REMIND_ME_LATER_AT, null);
  }

  public setOffline() {
    // ----- testing environment values setup - starts -------
    // const minus10Hours = 60 * 60 * 9.92 * 1000;
    // const date2 = new Date(new Date().getTime() - minus10Hours).toString();
    // localStorage.setItem(SYNC_FLOW_WENT_OFFLINE_AT, date2);
    // ----- testing environment values setup - ends -------

    localStorage.setItem(SYNC_FLOW_WENT_OFFLINE_AT, Date());
    localStorage.setItem(SYNC_FLOW_CLICK_REMIND_ME_LATER_AT, null);
  }

  public setRemindMe() {
    // ----- testing environment values setup - starts -------
    // every time we do Reminder me later, it will keep reducing two hours from the last offline date/time.
    // let wentOfflineTime = localStorage.getItem(SYNC_FLOW_WENT_OFFLINE_AT);
    // if (wentOfflineTime !== null) {
    //   let lastOfflineDate = new Date(wentOfflineTime);
    //   const minus2Hours = 60 * 60 * 1.92 * 1000;
    //   const date2 = new Date(lastOfflineDate.getTime() - minus2Hours).toString();
    //   localStorage.setItem(SYNC_FLOW_WENT_OFFLINE_AT, date2);
    // }

    // to add +2 hours once every time user say Remind me in 2 hours.

    // const minus2Hours = 60 * 60 * 1.92 * 1000;
    // const date2 = new Date(new Date().getTime() - minus2Hours).toString();
    // localStorage.setItem(SYNC_FLOW_CLICK_REMIND_ME_LATER_AT, date2);
    // ----- testing environment values setup - ends -------

    localStorage.setItem(SYNC_FLOW_CLICK_REMIND_ME_LATER_AT, Date());
  }

  public isSyncRequire() {
    if (this.differenceSinceLastOffline() >= this.authService.user.thresholdOfflineTime) {

      if (this.isRemindMeSet() && this.differenceSinceLastRemindLater() < this.authService.user.thresholdOffsetOfflineTime) {
        return false;
      }
      return true;
    } else if (this.isRemindMeSet() && this.differenceSinceLastRemindLater() >= this.authService.user.thresholdOffsetOfflineTime) {
      return true;
    } else {
      return false;
    }
  }

  private isRemindMeSet(): boolean {
    return ((localStorage.getItem(SYNC_FLOW_CLICK_REMIND_ME_LATER_AT) !== null) ? true : false);
  }

  // Check for the time Difference Since Last Offline and current time
  private differenceSinceLastOffline() {
    let wentOfflineTime = localStorage.getItem(SYNC_FLOW_WENT_OFFLINE_AT);
    if (wentOfflineTime === null) {
      return 0;
    } else {
      let lastOfflineDate = new Date(wentOfflineTime);
      let currentDate = new Date();
      var sinceLastOfflineDiff = Math.abs((currentDate.getTime() - lastOfflineDate.getTime()) / 1000);
      sinceLastOfflineDiff /= (60 * 60);
      sinceLastOfflineDiff = Math.round(sinceLastOfflineDiff);
      return (sinceLastOfflineDiff);
    }
  }

  //Check for the time Difference Since Last Remind later button clicked and current time
  private differenceSinceLastRemindLater() {
    let remindMeLaterTime = localStorage.getItem(SYNC_FLOW_CLICK_REMIND_ME_LATER_AT);
    if (remindMeLaterTime === null) {
      return 0;
    } else {
      let lastReminderDate = new Date(remindMeLaterTime);
      let currentDate = new Date();
      var sinceLastReminderDiff = Math.abs((currentDate.getTime() - lastReminderDate.getTime()) / 1000);
      sinceLastReminderDiff /= (60 * 60);
      sinceLastReminderDiff = Math.round(sinceLastReminderDiff);
      return (sinceLastReminderDiff);
    }
  }

  get totalOfflineHours(): number {
    return this.differenceSinceLastOffline();
  }

  get totalRemindHours(): number {
    return this.authService.user.thresholdOffsetOfflineTime;
  }
}
