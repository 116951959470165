import { Component, Input } from '@angular/core';
import { IndReportGroupTileDataModel } from '../../../models/IndReportTileDataModel';

/**
 * Generated class for the IndReportGroupTileComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-report-group-tile',
  templateUrl: 'ind-report-group-tile.html',
  styleUrls:['ind-report-group-tile.scss']
})
export class IndReportGroupTileComponent {
  @Input()  viewData: IndReportGroupTileDataModel;
  constructor() {

  }

}
