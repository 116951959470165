export const DefaultAccountDisplayForm = {
    value: [
        {
            createdon: "2020-04-09T13:53:41Z",
            importsequencenumber: null,
            indskr_entityid: null,
            indskr_entityname: "account",
            indskr_formid: "iO - App Form (e819476b-54bf-412a-8b77-d0b6179cf4eb)",
            indskr_formtype: 548910002,
            indskr_metadata: {
                EntityLogicalName: "account",
                EntitySetName: "accounts",
                SchemaName: "Account",
                Tabs: [
                    {
                        Controls: [
                            {
                               AttributeName:"name",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_NAME",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:250,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:true,
                               SchemaName:"Name",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_accountsegment",
                               DataType:"PicklistType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_SEGMENT",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_AccountSegment",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_accreditationagency",
                               DataType:"LookupType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_ACCREDITATION_AGENCY",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:"indskr_accreditationagencyid",
                               LookupEntityPrimaryNameAttribute:"indskr_name",
                               LookupEntitySetName:"indskr_accreditationagencies",
                               LookupQueries:[],
                               LookupTargets:[
                                  "indskr_accreditationagency"
                               ],
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[
                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_accreditationagency",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_customaccountid1",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_CUSTOM_ID",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:30,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_CustomAccountID1",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                              AttributeName:"indskr_legacyid",
                              DataType:"StringType",
                              DateBehavior:null,
                              DateFormat:null,
                              Descriptions:[],
                              DisplayNames:[
                                 {
                                    Description:"ACCOUNT_ETMS_CODE",
                                    LanguageCode:"0000"
                                 }
                              ],
                              Format:"Text",
                              IsCustomAttribute:true,
                              IsList:false,
                              LookupEntityPrimaryIdAttribute:null,
                              LookupEntityPrimaryNameAttribute:null,
                              LookupEntitySetName:null,
                              LookupQueries:[

                              ],
                              LookupTargets:null,
                              MaxLength:30,
                              MaxValue:null,
                              MinValue:null,
                              OptionSet:{
                                 Options:[

                                 ]
                              },
                              Precision:0,
                              ReadOnly:false,
                              Required:false,
                              SchemaName:"indskr_LegacyId",
                              Subgrid:null,
                              Visible:true
                           },
                            {
                               AttributeName:"indskr_deanumber",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_DEA_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_DEANumber",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_deanumberexpirationdate",
                               DataType:"DateTimeType",
                               DateBehavior:"UserLocal",
                               DateFormat:"DateOnly",
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_DEA_NUMBER_EXPIRATION",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_DEANumberExpirationDate",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"fax",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_FAX_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:50,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"Fax",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_glnnumber",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_GLN_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_GLNNumber",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_hinnumber",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_HIN_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_hinnumber",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_hospitaltype",
                               DataType:"PicklistType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_HOSPITAL_TYPE",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_HospitalType",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_idn",
                               DataType:"LookupType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_IDN",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:"indskr_idnid",
                               LookupEntityPrimaryNameAttribute:"indskr_name",
                               LookupEntitySetName:"indskr_idns",
                               LookupQueries:[
                                  {
                                     EntityName:"indskr_idn",
                                     FetchXml:"<fetch returntotalrecordcount='true' count=\"{0}\" version=\"1.0\" mapping=\"logical\"><entity name=\"indskr_idn\"><attribute name=\"indskr_idnid\" /><attribute name=\"indskr_name\" /><attribute name=\"createdon\" /><order attribute=\"indskr_name\" descending=\"false\" /><filter type=\"and\"><condition attribute=\"statecode\" operator=\"eq\" value=\"0\" /></filter><filter type=\"or\" ><condition attribute=\"indskr_name\" operator=\"like\" value=\"{0}\" /></filter></entity></fetch>",
                                     ObjectTypeCode:10078
                                  }
                               ],
                               LookupTargets:[
                                  "indskr_idn"
                               ],
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_IDN",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_idnparent",
                               DataType:"LookupType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_IDN_PARENT",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:"indskr_idnid",
                               LookupEntityPrimaryNameAttribute:"indskr_name",
                               LookupEntitySetName:"indskr_idns",
                               LookupQueries:[
                                  {
                                     EntityName:"indskr_idn",
                                     FetchXml:"<fetch returntotalrecordcount='true' count=\"{0}\" version=\"1.0\" mapping=\"logical\"><entity name=\"indskr_idn\"><attribute name=\"indskr_idnid\" /><attribute name=\"indskr_name\" /><attribute name=\"createdon\" /><order attribute=\"indskr_name\" descending=\"false\" /><filter type=\"and\"><condition attribute=\"statecode\" operator=\"eq\" value=\"0\" /></filter><filter type=\"or\" ><condition attribute=\"indskr_name\" operator=\"like\" value=\"{0}\" /></filter></entity></fetch>",
                                     ObjectTypeCode:10078
                                  }
                               ],
                               LookupTargets:[
                                  "indskr_idn"
                               ],
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_IDNParent",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_ownershiptype",
                               DataType:"PicklistType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_MANAGED_LEASED_OWNED",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_OwnershipType",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                              AttributeName: "indskr_accountsourcetype",
                              DataType: "PicklistType",
                              DateBehavior: null,
                              DateFormat: null,
                              Descriptions: [
                                {
                                  Description: "ACCOUNT_SOURCE_TYPE",
                                  LanguageCode: "0000"
                                }
                              ],
                              DisplayNames: [
                                {
                                  Description: "ACCOUNT_SOURCE_TYPE",
                                  LanguageCode: "0000"
                                }
                              ],
                              Format: null,
                              IsCustomAttribute: true,
                              IsList: false,
                              LookupEntityPrimaryIdAttribute: null,
                              LookupEntityPrimaryNameAttribute: null,
                              LookupEntitySetName: null,
                              LookupQueries: [],
                              LookupTargets: null,
                              MaxLength: 0,
                              MaxValue: null,
                              MinValue: null,
                              OptionSet: {
                                Options: []
                              },
                              Precision: 0,
                              ReadOnly: true,
                              Required: false,
                              SchemaName: "indskr_AccountSourceType",
                              Subgrid: null,
                              Visible: true
                            },
                            {
                               AttributeName:"indskr_externalid",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_MDM_ID",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_externalid",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_npinumber",
                               DataType:"IntegerType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_NPI_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:2147483647,
                               MinValue:-2147483648,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_NPINumber",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_numberofdischarges",
                               DataType:"DecimalType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_NUMBER_OF_DISCHARGES",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:100000000000,
                               MinValue:-100000000000,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_NumberofDischarges",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_numberofstaffedbeds",
                               DataType:"DecimalType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_NUMBER_OF_STAFFED_BEDS",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:100000000000,
                               MinValue:-100000000000,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_NumberofStaffedBeds",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"emailaddress1",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"EMAIL",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Email",
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"EMailAddress1",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"emailaddress2",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_OTHER_EMAIL_ADD",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Email",
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"EMailAddress2",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"emailaddress3",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_OTHER_EMAIL_ADD",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Email",
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"EMailAddress3",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"parentaccountid",
                               DataType:"LookupType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_PARENT_ACCOUNT",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:"accountid",
                               LookupEntityPrimaryNameAttribute:"name",
                               LookupEntitySetName:"accounts",
                               LookupQueries:[
                                  {
                                     EntityName:"account",
                                     FetchXml:"<fetch returntotalrecordcount='true' count=\"{0}\" version=\"1.0\" output-format=\"xml-platform\" mapping=\"logical\"><entity name=\"account\"><attribute name=\"name\" /><attribute name=\"address1_city\" /><order attribute=\"name\" descending=\"false\" /><attribute name=\"telephone1\" /><filter type=\"or\" ><condition attribute=\"name\" operator=\"like\" value=\"{0}\" /></filter><attribute name=\"accountid\" /></entity></fetch>",
                                     ObjectTypeCode:1
                                  }
                               ],
                               LookupTargets:[
                                  "account"
                               ],
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"ParentAccountId",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"telephone3",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_PHONE_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:50,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"Telephone3",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"ownershipcode",
                               DataType:"PicklistType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_PROPRIETOR",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:false,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"OwnershipCode",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_providernumber",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_PROVIDER_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_ProviderNumber",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_solecommunityhospital",
                               DataType:"BooleanType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_SOLE_COMMUNITY_HOSPITAL",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_SoleCommunityHospital",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_accounttype",
                               DataType:"PicklistType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"TYPE",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:null,
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:0,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_accounttype",
                               Subgrid:null,
                               Visible:true
                            },
                            {
                               AttributeName:"indskr_340bidnumber",
                               DataType:"StringType",
                               DateBehavior:null,
                               DateFormat:null,
                               Descriptions:[],
                               DisplayNames:[
                                  {
                                     Description:"ACCOUNT_340B_ID_NUMBER",
                                     LanguageCode:"0000"
                                  }
                               ],
                               Format:"Text",
                               IsCustomAttribute:true,
                               IsList:false,
                               LookupEntityPrimaryIdAttribute:null,
                               LookupEntityPrimaryNameAttribute:null,
                               LookupEntitySetName:null,
                               LookupQueries:[

                               ],
                               LookupTargets:null,
                               MaxLength:100,
                               MaxValue:null,
                               MinValue:null,
                               OptionSet:{
                                  Options:[

                                  ]
                               },
                               Precision:0,
                               ReadOnly:false,
                               Required:false,
                               SchemaName:"indskr_340BIDNumber",
                               Subgrid:null,
                               Visible:true
                            },
                              {
                                 AttributeName:"indskr_deniedinpatientclaims_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_DENIED_INPATIENT_CLAIMS",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_DeniedInpatientClaims_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_deniedoutpatientclaims_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_DENIED_OUTPATIENT_CLAIMS",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_DeniedOutpatientClaims_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_inpatientrevenue_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_INPATIENT_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_InpatientRevenue_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_netmedicaidrevenue_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_NET_MEDICAID_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_NetMedicaidRevenue_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_netmedicarerevenue_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_NET_MEDICARE_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_NetMedicareRevenue_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_netoperatingprofitmargin_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_NET_OPERATING_PROFIT_MARGIN",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_NetOperatingProfitMargin_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_netpatientrevenue_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_NET_PATIENT_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_NetPatientRevenue_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_outpatientrevenue_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_NET_PATIENT_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_OutpatientRevenue_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_patientdiscounts_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_PATIENT_DISCOUNT",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_PatientDiscounts_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_patientdiscountpercentage",
                                 DataType:"DecimalType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_PATIENT_DISCOUNT_PERCENTAGE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:100,
                                 MinValue:0,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:2,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_PatientDiscountPercentage",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_publiccorporation",
                                 DataType:"BooleanType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_PUBLIC_CORPORATOR",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:null,
                                 MinValue:null,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:0,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_PublicCorporation",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_stocksymbol",
                                 DataType:"StringType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_STOCK_SYMBOL",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:"Text",
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:100,
                                 MaxValue:null,
                                 MinValue:null,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:0,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_StockSymbol",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_totalmedicaidpatientcharge_v2_base",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_TOTAL_MEDICAID_PATIENT_CHARGE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_totalmedicaidpatientcharge_v2_Base",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_totalmedicarepatientcharge_v2_base",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_TOTAL_MEDICARE_PATIENT_CHARGE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_totalmedicarepatientcharge_v2_Base",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_totalpatientrevenue_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_TOTAL_PATIENT_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_TotalPatientRevenue_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                              {
                                 AttributeName:"indskr_totalrevenues_v2",
                                 DataType:"MoneyType",
                                 DateBehavior:null,
                                 DateFormat:null,
                                 Descriptions:[],
                                 DisplayNames:[
                                    {
                                       Description:"ACCOUNT_TOTAL_REVENUE",
                                       LanguageCode:"0000"
                                    }
                                 ],
                                 Format:null,
                                 IsCustomAttribute:true,
                                 IsList:false,
                                 LookupEntityPrimaryIdAttribute:null,
                                 LookupEntityPrimaryNameAttribute:null,
                                 LookupEntitySetName:null,
                                 LookupQueries:[

                                 ],
                                 LookupTargets:null,
                                 MaxLength:0,
                                 MaxValue:922337203685477,
                                 MinValue:-922337203685477,
                                 OptionSet:{
                                    Options:[

                                    ]
                                 },
                                 Precision:4,
                                 ReadOnly:false,
                                 Required:false,
                                 SchemaName:"indskr_TotalRevenues_v2",
                                 Subgrid:null,
                                 Visible:true
                              },
                            {
                                AttributeName: "Addresses",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "ADDRESSES",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                isSearchable:true,
                                searchAttributeName:'indskr_indskr_customeraddress_v2.indskr_address',
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_customer",
                                    ReferencingEntity: "indskr_indskr_customeraddress_v2",
                                    ReferencingEntityNavigationPropertyName: "indskr_Customer_account",
                                    RelationshipName: "indskr_account_indskr_indskr_customeraddress_v2_Customer",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                          {
                                            Attribute: "indskr_address.indskr_composite",
                                            Descriptions: [],
                                            DisplayNames: []
                                          },
                                          {
                                            Attribute: "indskr_address.indskr_line1",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "STREET_1_ADDRESS",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                          {
                                            Attribute: "indskr_address.indskr_country_lu",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "COUNTRY",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                          {
                                            Attribute: "indskr_address.indskr_state_lu",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "STATE_OR_PROVINCE",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                          {
                                            Attribute: "indskr_address.indskr_city_lu",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "CITY",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                          {
                                            Attribute: "indskr_address.indskr_postalcode_lu",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "POSTAL_CODE",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                        ]
                                    },
                                    SubgridQuery: `<fetch mapping="logical" output-format="xml-platform" version="1.0">
                                                                <entity name="indskr_indskr_customeraddress_v2">
                                                                    <order descending="false" attribute="indskr_address" />
                                                                    <attribute name="indskr_address" />
                                                                    <link-entity name="indskr_address" visible="false" link-type="outer" to="indskr_address" from="indskr_addressid" alias="a_814e681c5c3de811813d480fcfeae051">
                                                                    <attribute name="indskr_line3" />
                                                                    <attribute name="indskr_line2" />
                                                                    <attribute name="indskr_line1" />
                                                                    <attribute name="indskr_state_lu" />
                                                                    <attribute name="indskr_postalcode_lu" />
                                                                    <attribute name="indskr_postofficebox" />
                                                                    <attribute name="indskr_telephone2" />
                                                                    <attribute name="indskr_telephone1" />
                                                                    <attribute name="indskr_longitude" />
                                                                    <attribute name="indskr_latitude" />
                                                                    <attribute name="indskr_isverified" />
                                                                    <attribute name="indskr_secondaryfax" />
                                                                    <attribute name="indskr_fax" />
                                                                    <attribute name="indskr_country_lu" />
                                                                    <attribute name="indskr_city_lu" />
                                                                    <attribute name="indskr_subbrick" />
                                                                    <attribute name="indskr_district" />
                                                                    <attribute name="indskr_timezone" />
                                                                    </link-entity>
                                                                    <attribute name="indskr_samplingeligible" />
                                                                    <attribute name="indskr_isprimary" />
                                                                    <attribute name="indskr_indskr_customeraddress_v2id" />
                                                                    <filter>
                                                                    <condition attribute="indskr_customer" operator="eq" value="{0}" />
                                                                    </filter>
                                                                </entity>
                                                                </fetch>`,
                                    TargetEntityType: "indskr_indskr_customeraddress_v2",
                                    ViewId: "{8AE5AF13-6F8E-E811-816B-480FCFF40721}"
                                },
                                Visible: true,
                            },
                            {
                              AttributeName: "CustomerPosition",
                              DataType: null,
                              DateBehavior: null,
                              DateFormat: null,
                              Descriptions: [],
                              DisplayNames: [
                                {
                                  Description: "COVERAGE_TEAM",
                                  LanguageCode: "0000"
                                }
                              ],
                              Format: null,
                              IsCustomAttribute: false,
                              IsList: true,
                              isSearchable:true,
                              searchAttributeName:'indskr_customerposition.indskr_positionid',
                              LookupEntityPrimaryIdAttribute: null,
                              LookupEntityPrimaryNameAttribute: null,
                              LookupEntitySetName: null,
                              LookupQueries: [],
                              LookupTargets: null,
                              MaxLength: 0,
                              MaxValue: null,
                              MinValue: null,
                              OptionSet: {
                                Options: []
                              },
                              Precision: 0,
                              ReadOnly: false,
                              Required: false,
                              SchemaName: null,
                              Subgrid: {
                                ParentAttribute: "accountid",
                                ParentEntity: "account",
                                ReferencingAttribute: "indskr_customerid",
                                ReferencingEntity: "indskr_customerposition",
                                ReferencingEntityNavigationPropertyName: "indskr_customerid_account",
                                RelationshipName: "indskr_account_customerposition_Customer",
                                RelationshipType: 0,
                                SubgridLayout: {
                                  Attributes: [
                                    {
                                      Attribute: "indskr_positionid",
                                      Descriptions: [],
                                      DisplayNames: []
                                    },
                                  ]
                                },
                                SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                                  <entity name="indskr_customerposition">
                                    <attribute name="indskr_name" />
                                    <order attribute="indskr_name" descending="false" />
                                    <attribute name="indskr_positionid" />
                                    <attribute name="indskr_customerpositionid" />
                                    <filter>
                                      <condition attribute="indskr_customerid" operator="eq" value="{0}" />
                                    </filter>
                                  </entity>
                                </fetch>`,
                                TargetEntityType: "indskr_customerposition",
                                ViewId: "{D30CC7F1-36C0-EA11-A812-000D3A1ABBDD}"
                              },
                              Visible: true
                            },
                            {
                                AttributeName: "ioAffiliatedContact",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "CONTACT_AFFILIATION",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                isSearchable:true,
                                searchAttributeName:'indskr_accountcontactaffiliation.indskr_contactid',
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_accountid",
                                    ReferencingEntity: "indskr_accountcontactaffiliation",
                                    ReferencingEntityNavigationPropertyName: "indskr_accountid",
                                    RelationshipName: "indskr_account_accountcontactaffiliation_account",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                            {
                                                Attribute: "indskr_contactid",
                                                Descriptions: [],
                                                DisplayNames: []
                                            },
                                          {
                                            Attribute: "omnione_role",
                                            Descriptions: [],
                                            DisplayNames: [
                                                {
                                                    Description: "AFFILIATION_ROLE",
                                                    LanguageCode: "0000"
                                                }
                                            ]
                                          },
                                          {
                                            Attribute: "indskr_affiliationsourcetype",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "AFFILIATION_SOURCE_TYPE",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                          {
                                            Attribute: "indskr_mdm",
                                            Descriptions: [],
                                            DisplayNames: [
                                              {
                                                Description: "AFFILIATION_MDM",
                                                LanguageCode: "0000"
                                              }
                                            ]
                                          },
                                        ]
                                    },
                                    SubgridQuery: `<fetch version="1.0" mapping="logical">
                                            <entity name="indskr_accountcontactaffiliation">
                                                <attribute name="createdon" />
                                                <order attribute="indskr_contactid" descending="false" />
                                                <attribute name="statecode" />
                                                <attribute name="indskr_contactid" />
                                                <attribute name="indskr_contactrole" />
                                                <attribute name="omnione_role" />
                                                <attribute name="createdby" />
                                                <attribute name="indskr_affiliationsourcetype" />
                                                <attribute name="indskr_mdm" />
                                                <attribute name="indskr_accountcontactaffiliationid" />
                                                <attribute name="indskr_startdate" />
                                                <attribute name="indskr_enddate" />
                                                <filter>
                                                <condition attribute="indskr_accountid" operator="eq" value="{0}" />
                                                </filter>
                                            </entity>
                                            </fetch>`,
                                    TargetEntityType: "indskr_accountcontactaffiliation",
                                    ViewId: "{4CF09727-6219-E711-8112-480FCFEBC391}"
                                },
                                Visible: true,
                            },
                            {
                                AttributeName: "Account_To_Relation",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "ACCOUNT_RELATED_ACCOUNTS",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                isSearchable:true,
                                searchAttributeName:'indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid',
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_affiliatedtoaccountid",
                                    ReferencingEntity: "indskr_accountaccountaffiliation",
                                    ReferencingEntityNavigationPropertyName: "indskr_affiliatedtoaccountid",
                                    RelationshipName: "indskr_account_accountaccountaffiliation_affiliatedtoaccountid",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                            {
                                                Attribute: "indskr_affiliatedfromaccountid",
                                                Descriptions: [],
                                                DisplayNames: []
                                            },
                                            {
                                              Attribute: "omnione_relationship",
                                              Descriptions: [],
                                              DisplayNames: [
                                                {
                                                  Description: "AFFILIATION_TYPE",
                                                  LanguageCode: "0000"
                                                }
                                              ]
                                            },
                                            {
                                              Attribute: "indskr_affiliationsourcetype",
                                              Descriptions: [],
                                              DisplayNames: [
                                                {
                                                  Description: "AFFILIATION_SOURCE_TYPE",
                                                  LanguageCode: "0000"
                                                }
                                              ]
                                            },
                                            {
                                              Attribute: "indskr_mdm",
                                              Descriptions: [],
                                              DisplayNames: [
                                                {
                                                  Description: "AFFILIATION_MDM",
                                                  LanguageCode: "0000"
                                                }
                                              ]
                                            },
                                        ]
                                    },
                                    SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                                            <entity name="indskr_accountaccountaffiliation">
                                                <order attribute="indskr_affiliatedfromaccountid" descending="false" />
                                                <attribute name="indskr_type" />
                                                <attribute name="statecode" />
                                                <attribute name="createdon" />
                                                <attribute name="createdby" />
                                                <attribute name="modifiedon" />
                                                <attribute name="modifiedby" />
                                                <attribute name="indskr_affiliatedfromaccountid" />
                                                <attribute name="indskr_affiliatedtoaccountid" />
                                                <attribute name="omnione_relationship" />
                                                <attribute name="indskr_mdm" />
                                                <attribute name="indskr_affiliationsourcetype" />
                                                <attribute name="indskr_accountaccountaffiliationid" />
                                                <attribute name="statecode" />
                                                <attribute name="indskr_startdate" />
                                                <attribute name="indskr_enddate" />
                                                <attribute name="indskr_description" />
                                                <filter>
                                                <condition attribute="indskr_affiliatedtoaccountid" operator="eq" value="{0}" />
                                                </filter>
                                            </entity>
                                            </fetch>`,
                                    TargetEntityType: "indskr_accountaccountaffiliation",
                                    ViewId: "{71184A8B-409B-E811-8155-480FCFF4F6A1}"
                                },
                                Visible: true,
                            },
                            {
                                AttributeName: "Account_From_Relation",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "ACCOUNT_RELATED_ACCOUNTS",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                isSearchable:true,
                                searchAttributeName:'indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid',
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_affiliatedfromaccountid",
                                    ReferencingEntity: "indskr_accountaccountaffiliation",
                                    ReferencingEntityNavigationPropertyName: "indskr_affiliatedfromaccountid",
                                    RelationshipName: "indskr_account_accountaccountaffiliation_affiliatedfromaccountid",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                            {
                                                Attribute: "indskr_affiliatedtoaccountid",
                                                Descriptions: [],
                                                DisplayNames: []
                                            },
                                            {
                                              Attribute: "omnione_relationship",
                                              Descriptions: [],
                                              DisplayNames: [
                                                {
                                                  Description: "AFFILIATION_TYPE",
                                                  LanguageCode: "0000"
                                                }
                                              ]
                                            },
                                            {
                                              Attribute: "indskr_affiliationsourcetype",
                                              Descriptions: [],
                                              DisplayNames: [
                                                {
                                                  Description: "AFFILIATION_SOURCE_TYPE",
                                                  LanguageCode: "0000"
                                                }
                                              ]
                                            },
                                            {
                                              Attribute: "indskr_mdm",
                                              Descriptions: [],
                                              DisplayNames: [
                                                {
                                                  Description: "AFFILIATION_MDM",
                                                  LanguageCode: "0000"
                                                }
                                              ]
                                            },
                                        ]
                                    },
                                    SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                                            <entity name="indskr_accountaccountaffiliation">
                                                <order attribute="indskr_affiliatedtoaccountid" descending="false" />
                                                <attribute name="statecode" />
                                                <attribute name="createdon" />
                                                <attribute name="createdby" />
                                                <attribute name="modifiedon" />
                                                <attribute name="modifiedby" />
                                                <attribute name="indskr_affiliatedfromaccountid" />
                                                <attribute name="indskr_affiliatedtoaccountid" />
                                                <attribute name="omnione_relationship" />
                                                <attribute name="indskr_mdm" />
                                                <attribute name="indskr_affiliationsourcetype" />
                                                <attribute name="indskr_accountaccountaffiliationid" />
                                                <attribute name="statecode" />
                                                <attribute name="indskr_startdate" />
                                                <attribute name="indskr_enddate" />
                                                <attribute name="indskr_description" />
                                                <filter>
                                                  <condition attribute="indskr_affiliatedfromaccountid" operator="eq" value="{0}" />
                                                </filter>
                                            </entity>
                                            </fetch>`,
                                    TargetEntityType: "indskr_accountaccountaffiliation",
                                    ViewId: "{71184A8B-409B-E811-8155-480FCFF4F6A1}"
                                },
                                Visible: true,
                            },
                        ],
                        DisplayNames: [
                            {
                                Description: "OVERVIEW_CAP",
                                LanguageCode: "0000"
                            }
                        ],
                    }
                ]
            },
            indskr_mobileappformid: "206cbc84-697a-ea11-a812-000d3a1ab78a",
            indskr_name: "Default Account - Display",
            indskr_validate: false,
            modifiedon: "2020-05-01T14:39:14Z",
            overriddencreatedon: null,
            statecode: 0,
            statuscode: 1,
        }
    ]
}

export const RequiredAccountAttributes = [
    "name",
    "parentaccountid",
    "statuscode",
    "statecode",
    "modifiedon",
    "createdon",
    "indskr_mdm",
    "indskr_accountsourcetype",
    "indskr_mdmid",
    "indskr_accounttype",
    "indskr_accountlevel"
]

export const RequiredAccountCRAttributes = [
  "indskr_name",
  "indskr_requestcomments",
  "indskr_responsecomments",
  "statuscode",
  "statecode",
  "createdon",
  "modifiedon",
  "indskr_mdm",
  "indskr_mdmrequesttype",
  "indskr_accountsourcetype",
  "indskr_mdmid",
  "indskr_accountdcr"
]

export const AccountSearchConfigExcludedAttributes = [
    "name",
]

export const AccountsPositionFilter =
    `<link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="indskr_customerpositionfilter">
        {approvalStatusFilter}
        {filterOutFromAppSyncFilter}
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
            <filter type="or">
                <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="positionid" operator="in">
                    {positionIDs}
                </condition>
                </filter>
                <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="parentpositionid" operator="in">
                    {positionIDs}
                </condition>
                </filter>
            </filter>
        </link-entity>
    </link-entity>`

    export const FETCH_ACCOUNT_DEFAULT_LINK_ENTITES = [
        {
            entityName: "indskr_indskr_customeraddress_v2",
            fetchXML: `
                <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                    <entity name="account">
                        <attribute name="accountid"/>


                        {DeltaSyncFilter}
                        {PositionFilterPH}
                        <link-entity name="indskr_indskr_customeraddress_v2" from="indskr_customer" to="accountid" link-type="inner" alias="indskr_indskr_customeraddress_v2">
                            <attribute name="indskr_name"/>
                            <attribute name="indskr_address"/>
                            <attribute name="indskr_indskr_customeraddress_v2id"/>
                            <attribute name="indskr_isprimary" />
                            <attribute name="statecode"/>
                            <attribute name="statuscode" />
                            <link-entity name='indskr_address' from='indskr_addressid' to='indskr_address' link-type="inner" alias="indskr_address">
                                <attribute name="indskr_composite"/>
                                <attribute name="indskr_line1"/>
                                <attribute name="indskr_state_lu"/>
                                <attribute name="indskr_postalcode_lu"/>
                                <attribute name="indskr_country_lu"/>
                                <attribute name="indskr_city_lu"/>
                                <attribute name="indskr_longitude" />
                                <attribute name="indskr_latitude" />
                            </link-entity>
                        </link-entity>
                    </entity>
                </fetch>`,
            entityFetchXML: `
                <fetch version="1.0" output-format="xml-platform" mapping="logical">
                    <entity name="indskr_indskr_customeraddress_v2">
                        <attribute name="indskr_name" />
                        <order attribute="indskr_name" descending="false" />
                        <attribute name="indskr_address" />
                        <attribute name="indskr_isprimary" />
                        <link-entity alias="alias_placeholder" name="indskr_address" from="indskr_addressid" to="indskr_address" link-type="outer" visible="false">
                            <attribute name="indskr_composite"/>
                            <attribute name="indskr_line1" />
                            <attribute name="indskr_state_lu" />
                            <attribute name="indskr_postalcode_lu" />
                            <attribute name="indskr_country_lu" />
                            <attribute name="indskr_city_lu" />
                            <attribute name="indskr_longitude" />
                            <attribute name="indskr_latitude" />
                        </link-entity>
                        <attribute name="indskr_indskr_customeraddress_v2id" />
                        <filter>
                            <condition attribute="indskr_customer" operator="eq" value="{0}" />
                        </filter>
                    </entity>
                </fetch>
            `,
            isFetch: true,
        },
        {
            entityName: "indskr_accountcontactaffiliation",
            fetchXML: `
                <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                    <entity name="account">
                        <attribute name="accountid"/>

                        {DeltaSyncFilter}
                        {PositionFilterPH}
                        <link-entity name="indskr_accountcontactaffiliation" from="indskr_accountid" to="accountid" link-type="inner" alias="indskr_accountcontactaffiliation">
                            <attribute name="createdon"/>
                            <attribute name="statecode"/>
                            <attribute name="statuscode" />
                            <attribute name="indskr_startdate"/>
                            <attribute name="indskr_enddate"/>
                            <attribute name="indskr_comments"/>
                            <attribute name="indskr_accountid"/>
                            <attribute name="indskr_contactid"/>
                            <attribute name="indskr_contactrole"/>
                            <attribute name="omnione_role" />
                            <attribute name="createdby"/>
                            <attribute name="modifiedon"/>
                            <attribute name="modifiedby"/>
                            <attribute name="indskr_isprimaryaccount"/>
                            <attribute name="indskr_accountcontactaffiliationid"/>
                        </link-entity>
                    </entity>
                </fetch>`,
            entityFetchXML:
                `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                    <entity name="indskr_accountcontactaffiliation">
                    <order attribute="indskr_accountid" descending="false" />
                    <attribute name="statecode" />
                    <attribute name="indskr_contactrole" />
                    <attribute name="omnione_role" />
                    <attribute name="indskr_accountid" />
                    <attribute name="indskr_contactid"/>
                    <attribute name="indskr_accountcontactaffiliationid" />
                    <attribute name="indskr_isprimaryaccount"/>
                    <attribute name="createdon" />
                    <filter>
                        <condition attribute="indskr_accountid" operator="eq" value="{0}" />
                    </filter>
                    <link-entity name='contact' from='contactid' to='indskr_contactid' link-type='inner' alias='contactaffiliation'>
                    <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='cp'>
                      <attribute name="indskr_approvalstatus"/>
                      <attribute name="indskr_positionid"/>
                      <link-entity name='contact' from='contactid' to='indskr_customerid' link-type='inner' alias='cc'></link-entity>
                    </link-entity>
                    </link-entity>
                    </entity>
                </fetch>`,
            isFetch: true,
        },{

          entityName:"annotation",
          fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="account">
                      <attribute name="accountid"/>
                      {PositionFilterPH}
                      <link-entity name="annotation" from="objectid" to="accountid" link-type="inner" alias="annotation" >
                          <attribute name="subject" />
                          <attribute name="notetext" />
                          <attribute name="filename" />
                          <attribute name="mimetype" />
                          <attribute name="annotationid" />
                          <attribute name="ownerid" />
                          <attribute name="createdon" />
                          <attribute name="objectid" />
                          {DeltaSyncFilter}
                      </link-entity>
                  </entity>
              </fetch>`,
          entityFetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                  <entity name="annotation">
                      <attribute name="subject" />
                      <attribute name="notetext" />
                      <attribute name="filename" />
                      <attribute name="mimetype" />
                      <attribute name="annotationid" />
                      <attribute name="ownerid" />
                      <attribute name="createdon" />
                      <attribute name="objectid" />
                      <order attribute="subject" descending="false" />
                      <filter>
                          <condition attribute="objectid" operator="eq" value="{0}" />
                      </filter>
                  </entity>
              </fetch>`,
          isFetch: true,
      }
    ]

export const FETCH_ACCOUNT_CR_LINK_ENTITIES = [
  {
    entityName: "indskr_emailaddresscr",
    fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_accountcr">
                      <attribute name="indskr_accountcrid"/>
                      <attribute name="statecode" />
                      <attribute name="statuscode" />

                      {PositionFilterPH}
                      {DeltaSyncFilter}

                      <link-entity name="indskr_emailaddresscr" from="indskr_accountcr" to="indskr_accountcrid" link-type="inner" alias="indskr_emailaddresscr">
                          <attribute name="indskr_isprimary"/>
                          <attribute name="indskr_emailaddress"/>
                          <attribute name="indskr_type"/>
                          <attribute name="indskr_accountcr"/>
                          <attribute name="indskr_emailaddresscrid"/>
                      </link-entity>
                  </entity>
              </fetch>`,
    entityFetchXML:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_emailaddresscr">
                  <order attribute="indskr_isprimary" descending="true" />
                  <order attribute="indskr_emailaddress" descending="false" />
                  <attribute name="indskr_isprimary" />
                  <attribute name="indskr_emailaddress" />
                  <attribute name="indskr_type" />
                  <attribute name="indskr_emailaddresscrid" />
                  <filter>
                      <condition attribute="indskr_accountcr" operator="eq" value="{0}" />
                  </filter>
                  </entity>
              </fetch>`,
    isFetch: true,
  },
  {
    entityName: "indskr_customeraddresscr",
    fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_accountcr">
                      <attribute name="indskr_accountcrid"/>

                      {DeltaSyncFilter}
                      {PositionFilterPH}
                      <link-entity name="indskr_customeraddresscr" from="indskr_accountcr" to="indskr_accountcrid" link-type="inner" alias="indskr_customeraddresscr">
                          <attribute name="indskr_name"/>
                          <attribute name="indskr_addresscr"/>
                          <attribute name="indskr_customeraddresscrid"/>
                          <attribute name="statecode" />
                          <attribute name="statuscode" />
                          <link-entity name='indskr_addresscr' from='indskr_addresscrid' to='indskr_addresscr' link-type="inner" alias="indskr_addresscr">
                              <attribute name="indskr_composite"/>
                              <attribute name="indskr_line1"/>
                              <attribute name="indskr_state_lu"/>
                              <attribute name="indskr_postalcode_lu"/>
                              <attribute name="indskr_country_lu"/>
                              <attribute name="indskr_city_lu"/>
                              <attribute name="indskr_longitude" />
                              <attribute name="indskr_latitude" />
                          </link-entity>
                      </link-entity>
                  </entity>
              </fetch>`,
    entityFetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_customeraddresscr">
                      <attribute name="indskr_name" />
                      <order attribute="indskr_name" descending="false" />
                      <attribute name="indskr_addresscr" />
                      <link-entity alias="alias_placeholder" name="indskr_addresscr" from="indskr_addresscrid" to="indskr_addresscr" link-type="outer" visible="false">
                          <attribute name="indskr_composite"/>
                          <attribute name="indskr_line1" />
                          <attribute name="indskr_state_lu" />
                          <attribute name="indskr_postalcode_lu" />
                          <attribute name="indskr_country_lu" />
                          <attribute name="indskr_city_lu" />
                          <attribute name="indskr_longitude" />
                          <attribute name="indskr_latitude" />
                      </link-entity>
                      <attribute name="indskr_customeraddresscrid" />
                      <filter>
                          <condition attribute="indskr_accountcr" operator="eq" value="{0}" />
                      </filter>
                  </entity>
              </fetch>
          `,
    isFetch: true,
  },
  {
    entityName: "indskr_accountcontactaffiliationcr",
    fetchXML: `
                <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                    <entity name="indskr_accountcr">
                        <attribute name="indskr_accountcrid"/>

                        {DeltaSyncFilter}
                        {PositionFilterPH}
                        <link-entity name="indskr_accountcontactaffiliationcr" from="indskr_accountcr" to="indskr_accountcrid" link-type="inner" alias="indskr_accountcontactaffiliationcr">
                            <attribute name="createdon"/>
                            <attribute name="statecode"/>
                            <attribute name="statuscode" />
                            <attribute name="indskr_startdate"/>
                            <attribute name="indskr_enddate"/>
                            <attribute name="indskr_comments"/>
                            <attribute name="indskr_accountcr"/>
                            <attribute name="indskr_contactrole"/>
                            <attribute name="createdby"/>
                            <attribute name="modifiedon"/>
                            <attribute name="modifiedby"/>
                            <attribute name="indskr_isprimaryaccount"/>
                            <attribute name="indskr_accountcontactaffiliationcrid"/>
                        </link-entity>
                    </entity>
                </fetch>`,
    entityFetchXML:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                    <entity name="indskr_accountcontactaffiliationcr">
                    <order attribute="indskr_accountcrid" descending="false" />
                    <attribute name="statecode" />
                    <attribute name="indskr_contactrole" />
                    <attribute name="indskr_accountcr" />
                    <attribute name="indskr_accountcontactaffiliationcrid" />
                    <attribute name="indskr_isprimaryaccount"/>
                    <attribute name="createdon" />
                    <filter>
                        <condition attribute="indskr_accountcrid" operator="eq" value="{0}" />
                    </filter>
                    </entity>
                </fetch>`,
    isFetch: true,
  },
  {
    entityName: "indskr_approvalactivity",
    fetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_accountcr">
                      <attribute name="indskr_accountcrid"/>

                      {DeltaSyncFilter}
                      {PositionFilterPH}
                      <link-entity name="indskr_approvalactivity" alias="indskr_approvalactivity" to="indskr_accountcrid" from="indskr_accountcr" link-type="outer">
                          <attribute name="indskr_approvalactivityid" />
                          <attribute name="createdon" />
                          <attribute name="indskr_reason" />
                          <attribute name="indskr_approver" />
                          <attribute name="createdonbehalfby" />
                      </link-entity>
                  </entity>
              </fetch>`,
    entityFetchXML: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical">
                  <entity name="indskr_approvalactivity">
                  <order attribute="indskr_approvalactivityId" descending="false" />
                      <attribute name="indskr_approvalactivityid" />
                      <attribute name="createdon" />
                      <attribute name="indskr_reason" />
                      <attribute name="indskr_approver" />
                      <attribute name="createdonbehalfby" />
                      <filter>
                          <condition attribute="indskr_accountcr" operator="eq" value="{0}" />
                      </filter>
                  </entity>
              </fetch>`,
    isFetch: true,
  }
]
export const ACCOUNT_EVENT_FETCH_QUERIES = {
  fetchEventsForAccount: `
<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
    <entity name="msevtmgt_event">
      <attribute name="msevtmgt_eventid"/>
      <attribute name="msevtmgt_name"/>
      <attribute name="msevtmgt_eventstartdate"/>
      <attribute name="indskr_completedby"  alias ="completedby"/>
      <filter type="and">
    <condition attribute="msevtmgt_istemplate" value="100000002" operator="eq"/>
    <condition attribute="statuscode" value="548910005" operator="eq"/>
   </filter>
<link-entity name="indskr_msevtmgt_event_account" intersect="true" visible="false" to="msevtmgt_eventid" from="msevtmgt_eventid">
<filter type="and">
<condition attribute="accountid" value="{accountid}" operator="eq" />
</filter>
</link-entity>
</entity>
</fetch>`
}

export const ACCOUNT_LIST_PERIOD_TAG = `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="account">
    <attribute name="accountid"/>
    {positionFilterlevel1}
    {customFilterLevel1}
    {deltasyncFilterLevel1}
    <link-entity name="indskr_indskr_customerpositionlist_account" from="accountid" to="accountid" link-type="inner">
      <link-entity name="indskr_customerpositionlist" from="indskr_customerpositionlistid" to="indskr_customerpositionlistid" link-type="inner">
        <filter type="and">
          <condition attribute="indskr_position" operator="eq" value="{positionId}"/>
          <condition attribute="indskr_type" operator="eq" value="600000000" />
        </filter>
        <filter type="or">
          <condition attribute="statuscode" operator="eq" value="600000001" />
          <condition attribute="statuscode" operator="eq" value="600000002" />
        </filter>
        <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" link-type="inner">
          <attribute name="indskr_taglabel" alias="tagLabel"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
</fetch>
`