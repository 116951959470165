import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingController,  ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { isToday, startOfDay } from 'date-fns';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Brand } from '../../classes/brand/brand.class';
import { Specialty } from '../../classes/contact/contact.class';
import { GeneralInsight } from '../../classes/xperiences/trending.customer.class';
import { XperiencesDataService } from '../../data-services/xperiences/xperiences.data.service';
import { FormFieldType, IndFormFieldViewDataModel } from '../../models/indFormFieldDataModel';
import { SelectListData } from '../../models/select-list-data-model';
import { AlertService } from '../../services/alert/alert.service';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { DateTimeFormatsService } from '../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../services/device/device.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { NotificationService } from '../../services/notification/notification.service';
import { XperiencesService } from '../../services/xperiences/xperiences.service';
import { OptionSelectionMode } from '../../utility/util';
import { SelectListComponent } from '../shared/select-list/select-list';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UIService } from '@omni/services/ui/ui.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the GeneralInsightsPlusComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'general-insights-plus',
  templateUrl: 'general-insights-plus.html',
  styleUrls:['general-insights-plus.scss']
})
export class GeneralInsightsPlusComponent implements OnInit {
  generalInsightsPageHeaderModel: IndPageTitleViewDataModel;
  headerData = {
    id: 'new-insights-header',
    title: this.translate.instant('XPERIENCES_GENERAL_INSIGHTS_DETAILS'),
    controls: []
  };

  tempInsight: GeneralInsight;
  insightDate: string;
  formattedInsightTime: string;
  createMode = false;
  public maxDate: string = moment(new Date()).add(1, 'year').format();
  shortMonth: string[] = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
  readOnly = false;
  @ViewChild('generalInsights', {static:false}) generalInsightsDatePicker;
  private ngDestroy$ = new Subject<boolean>();
  private isClickedDescription: boolean = false;


  constructor(public translate: TranslateService,
              public xpService: XperiencesService,
              private readonly brandService: BrandOfflineService,
              private readonly modalCtrl: ModalController,
              public dateTimeFormatsService: DateTimeFormatsService,
              private readonly navService: NavigationService,
              private readonly alertService: AlertService,
              private readonly contactService: ContactOfflineService,
              private readonly events: Events,
              private readonly loadingCtrl: LoadingController,
              private readonly notificationService: NotificationService,
              private readonly device: DeviceService,
              private readonly uiService: UIService,
              public footerService: FooterService,
              private readonly navParams: NavParams,
              private readonly activityService: ActivityService) {
  }

  ngOnInit() {
    let date = new Date();
    if (this.xpService.generalInsight) {
      date = new Date(this.xpService.generalInsight.date);
    } else {
      this.createMode = true;
      this.xpService.generalInsight = new GeneralInsight();
      this.xpService.generalInsight.date = startOfDay(date).getTime();
      this.xpService.generalInsight.confidence = undefined;
    }
    this.insightDate = moment(date).format(this.dateTimeFormatsService.dateToUpper);
    this.formattedInsightTime = this.xpService.formattedDate(date.getTime(), true);
    this.events.subscribe(OptionSelectionMode.SELECT_SPECIALITY.toString(), (res) => {
      this.xpService.generalInsight.selectedSpeciality = res.selected;
    });
    this.xpService.tempInsight = cloneDeep(this.xpService.generalInsight);
    this.initGeneralInsightsPageTitle();
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      case 'done':
        this.createGeneralInsight();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private initGeneralInsightsPageTitle(): void {
    this.generalInsightsPageHeaderModel = {
      id: 'general-insights-page-title',
      title: this.translate.instant('XPERIENCES_GENERAL_INSIGHTS_PLUS'),
      controls:[
        {
          id: 'close',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: false,
          align: 'right'
        },
        {
          id: 'done',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('DONE'),
          isDisabled: !this.xpService.generalInsight.description || _.isEmpty(this.xpService.generalInsight.description),
          align: 'right'
        }
      ]
    };
  }

  updateInsightDate() {
    if (this.insightDate) {
      const date: number = startOfDay(new Date(this.insightDate).getTime()).getTime();
      this.formattedInsightTime = this.xpService.formattedDate(date, true);
      this.xpService.generalInsight.date = date;
    }
  }

  async selectInsightProduct() {
    const products: Brand[] = cloneDeep(this.brandService.brands);
    let _iscompetitorProductSelected = false;
    if(this.xpService.generalInsight.selectedProduct && this.xpService.generalInsight.selectedProduct.id === 'indskr_competitorproduct') {
      _iscompetitorProductSelected = true;
    }
    const selected = this.xpService.generalInsight.selectedProduct;
    const options: SelectListData[] = products.map(pr => {
      return { id: pr.ID.toString(), title: pr.name.toString(), isSelected: selected && selected.id === pr.ID.toString() };
    });
    this.footerService.forceHideMasterFooter = true;
    const categoryModal = await this.modalCtrl.create({component: SelectListComponent,
      componentProps:{
        viewData: {
          data: options,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('PRODUCT'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: true,
          addCompetitorProduct: true,
          isCompetitorProductSelected: _iscompetitorProductSelected
        }
      },
      backdropDismiss: false
    });
    categoryModal.present();
    categoryModal.onDidDismiss().then(async (data: any) => {
      this.footerService.forceHideMasterFooter = false;
      data = data.data;
      if (data && data.isDone) {
        if (data.selectedItems && data.selectedItems.length) {
          this.xpService.generalInsight.selectedProduct = data.selectedItems[0];
        } else {
          this.xpService.generalInsight.selectedProduct = null;
        }
      }
    });
  }

  closePage() {
      if (!_.isEqual(this.xpService.generalInsight, this.xpService.tempInsight)) {
        this.alertService.showAlert({
          title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
          message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')}, this.translate.instant('DISCARD')
        ).then (async res => {
          if(res.role === 'ok') {
            if (this.xpService.tempInsight) this.xpService.generalInsight = this.xpService.tempInsight;
            if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
              this.events.publish('SegmentChnaged');
              this.uiService.showRightPane = false;
            }
            await this.navService.popChildNavPageWithPageTracking();
          }
        });
      } else {
        if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
          this.uiService.showRightPane = false;
          this.events.publish('SegmentChnaged');
        }
        if (this.xpService.tempInsight) this.xpService.generalInsight = this.xpService.generalInsight;
        this.navService.popChildNavPageWithPageTracking();
      }

  }

  async createGeneralInsight() {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('CI_DEVICE_SHOULD_ONLINE'),'Customer Insight');
        return;
      }
      if (!this.xpService.generalInsight.description) {
        return;
      }
      if (this.xpService.generalInsight.selectedProduct && this.xpService.generalInsight.selectedProduct.id == 'indskr_competitorproduct' && !this.xpService.generalInsight.indskr_competitorproduct) {
        this.xpService.showMandatoryCompetitorProductNamePopover();
        return;
      }
      const loader =await this.loadingCtrl.create();
      loader.present();
      await this.xpService.createGeneralInsight(this.xpService.generalInsight).then(() => {
        loader.dismiss();
        if (this.xpService.tempInsight) this.xpService.generalInsight = this.xpService.tempInsight;
        if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
          this.uiService.showRightPane = false;
          this.events.publish('SegmentChnaged');
        }
        this.notificationService.notify(this.translate.instant('XPERIENCES_GENERAL_INSIGHTS_CREATED'),'General Insights');
      },
      (error)=> {
        loader.dismiss();
        console.log('Error occurred while creating general insight', error);
      });
      this.navService.popChildNavPageWithPageTracking().then(() => {
        setTimeout(() => {
          if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
            this.uiService.activeView = null;
            this.events.publish('SegmentChnaged');
          }
        }, 10);
      });
  }

  public async selectInsightSpeciality() {
    const specialities: Specialty[] = cloneDeep(this.contactService.specialties);
    const selected = this.xpService.generalInsight.selectedSpeciality;
    const options: SelectListData[] = specialities.map(sp => {
      return { id: sp.id, title: sp.name, isSelected: selected && selected.id == sp.id }
    });
    this.footerService.forceHideMasterFooter = true;
    const categoryModal =await this.modalCtrl.create({component: SelectListComponent,
      componentProps:{
        viewData: {
          data: options,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('XPERINECES_ALL_CATEGORY'),
          dividerTitle: this.translate.instant('XPERINECES_ALL_CATEGORY'),
          isSearchEnabled: true
        }
      },
      backdropDismiss: false
    });
    categoryModal.present();
    categoryModal.onDidDismiss().then(async (data: any) => {
      this.footerService.forceHideMasterFooter = false;
      data = data.data;
      if (data && data.isDone) {
        if (data.selectedItems && data.selectedItems.length) {
          this.xpService.generalInsight.selectedSpeciality = data.selectedItems[0];
        } else {
          this.xpService.generalInsight.selectedSpeciality = null;
        }
      }
    });
  }

  // onDescriptionChange(value: string) {
  //   if(value) {
  //     this.title.controls[1].isDisabled = false;
  //   } else {
  //     this.title.controls[1].isDisabled = true;
  //   }
  // }

  public get generalInsightsDescription(): IndFormFieldViewDataModel {
    return  {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.xpService.generalInsight.description,
      inputValue: this.xpService.generalInsight.description,
      customPlaceholderLabel:this.translate.instant('ENTER_DESCRIPTION'),
      id: 'general-insights-description',
      isReadOnly: false,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !this.readOnly,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedDescription && _.isEmpty(this.xpService.generalInsight.description),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

   public get generalInsightCompetitorFormField(): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant('CONTACT_COMPETITOR_PRODUCT'),
      inputText: this.xpService.generalInsight.indskr_competitorproduct,
      inputValue: this.xpService.generalInsight.indskr_competitorproduct,
      customPlaceholderLabel:this.translate.instant('ENTER_PRODUCT'),
      id: 'general-insights-competitor-product',
      isReadOnly: false,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  public get generalDateFormField(): IndFormFieldViewDataModel {
    return  {
      label: this.translate.instant('DATE'),
      inputText: this.formattedInsightTime,
      placeholderLabel:this.translate.instant('CUSTOMER_INSIGHT_JDATE'),
      id: 'general-insights-date',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  public get generalCategoryFormField(): IndFormFieldViewDataModel {
    return  {
      label: this.translate.instant('CATEGORY'),
      inputText: this.xpService.generalInsight.selectedSpeciality ? this.xpService.generalInsight.selectedSpeciality.title : '',
      customPlaceholderLabel: this.translate.instant('SELECT_CATEGORY'),
      id: 'general-insights-category',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  public get generalProductFormField(): IndFormFieldViewDataModel {
    return  {
      label: this.translate.instant('PRODUCT'),
      inputText: this.xpService.generalInsight.selectedProduct ? this.xpService.generalInsight.selectedProduct.title: '',
      customPlaceholderLabel:this.translate.instant('SELECT_PRODUCT'),
      id: 'general-insights-products',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  handleFormFieldEvent(id, event, eventname) {
    if (id) {
      switch (id) {
        case 'general-insights-description':
          if (eventname && eventname == 'input_value_change') {
            this.openDescriptionPopover(event);
          } else if(eventname && eventname == 'input_value_confirm') {
            this.isClickedDescription = true;
          }
          break;
        case 'general-insights-date':
          this.openinsightsDateFormField();
          break;
        case 'general-insights-category':
          this.selectInsightSpeciality();
          break;
        case 'general-insights-products':
          this.selectInsightProduct();
          break;
        case 'general-insights-competitor-product':
          if (eventname && eventname == 'input_value_change') {
            this.openCompetitorProductPopover(event);
          }
          break;
        default:
          break;
      }
    }
  }

  async openDescriptionPopover(myEvent) {
      if (this.readOnly) return;
      this.isClickedDescription = true;
      this.xpService.generalInsight.description =  (myEvent && myEvent.target.value && myEvent.target.value.length !== 0) ? myEvent.target.value : "";
      this.initGeneralInsightsPageTitle();
  }

  async openCompetitorProductPopover(myEvent) {
      if (this.readOnly) return;
      this.xpService.generalInsight.indskr_competitorproduct = (myEvent && myEvent.target.value && myEvent.target.value.length !== 0) ? myEvent.target.value : '';
  }

  public openinsightsDateFormField() {
    if (!this.readOnly) {
      this.generalInsightsDatePicker.open();
    }
  }

    public updateJourneyConfidence(event) {
    this.xpService.generalInsight.confidence = event;
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
