import { Utility } from './../../utility/util';
import { isPast, isFuture, format } from 'date-fns';
import { Resource } from '../resource/resource.class';
import { Presentation } from '../presentation/presentation.class';
import { IONote } from '../io/io-note.class';
import * as _ from 'lodash'

export class AccountPlan {
  public ID: string;
  public accountPlanName: string;
  public startDate: string;
  public endDate: string;
  public accountId: string;
  public accountName: string;
  public accountState: string;
  public parentAccountId: string;
  public state: number;
  public status: string;
  public statusCode: any;
  public accountPlanTimeFrame: string;
  public strategy: string;
  public documents: Array<Presentation | Resource> = [];
  public products: any[];
  public accountPlanObjectives: AccountPlanObjective[];
  public trackAction: string;
  public lastUpdated: number;
  public ownerID: string;
  public estimatedRevenue: number;
  public estimatedRevenureFormatted: string;
  public actualRevenue: number;
  public actualRevenueFormatted: string;
  public indskr_plannedbudget: number;
  public plannedBudgetFormatted: string;
  public contactAccountAffs: any[];
  public expenses: Expense[];
  public events: Events[];

  constructor(raw: JSON) {
    this.accountPlanName = raw['indskr_name'] || '';
    this.ID = raw['indskr_accountplanid'];
    this.startDate = raw['indskr_startdate'] ? Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_startdate']).getTime()).getTime().toString() : '';
    this.endDate = raw['indskr_enddate'] ? Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_enddate']).getTime(), true).getTime().toString() : '';
    this.accountName = raw['account.name'];
    this.parentAccountId = raw['account.parentaccountid'] || '';
    this.accountId = raw['account.accountid'];
    this.accountState = raw['account.statecode'];
    this.accountPlanObjectives = [];
    this.state = raw['statecode'] || 0;
    this.statusCode = raw['statusCode'] || 1
    this.status = raw['statuscode'] && raw['statuscode'] == 548910001 ? 'Completed' : raw['statuscode'] && raw['statuscode'] == 2 ? 'Inactive' : raw['statuscode'] && raw['statuscode'] == 548910002 ? 'Cancelled' : 'Open';
    this.strategy = raw['indskr_strategy'] || '';
    this.products = [];
    this.trackAction = raw['track_action@OData.Community.Display.V1.FormattedValue'] ? raw['track_action@OData.Community.Display.V1.FormattedValue'] : '';
    this.accountPlanTimeFrame = isPast(Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_enddate']).getTime(), true)) ? 'Past'
      : isFuture(Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_startdate']).getTime())) ? 'Future' : 'Present';
    this.ownerID = raw['_ownerid_value'] || '';
    this.estimatedRevenue = raw['indskr_estimatedrevenue'] || 0.00;
    this.estimatedRevenureFormatted = raw['indskr_estimatedrevenue@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.actualRevenue = raw['indskr_actualrevenue'] || 0.00;
    this.actualRevenueFormatted = raw['indskr_actualrevenue@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.indskr_plannedbudget = raw['indskr_plannedbudget'] || 0.00;
    this.plannedBudgetFormatted = raw['indskr_plannedbudget@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.contactAccountAffs = [];
    this.expenses = [];
    this.events = [];
  }

  get planID() {
    return this.ID;
  }

}
export class Account {
  constructor(public id: string,
    public name: string,
    public statecode: number) { }
}

export class Events {
  constructor(public ID: string, public name: string, public statuscode: number) { }
}



export class Expense {
  constructor(public expensesId: string,
    public description: string,
    public amount: number,
    public amountFormatted: string,
    public notes: IONote[],
    public currencySymbol: string) {

  }
}
export class AccountPlanObjective {
  objectiveID: string;
  createdBy: string;
  comletedValue: number;
  formattedCompletedValue: string;
  completedPercent: number;
  target: number;
  formattedTarget: string;
  type: accounPlanObjectiveType
  name: string;
  currency: string;
  currencySymbol: string;
  status: string;
  productId:string;
  productName:string;
  achievementActualOne:number;
  achievementProjectedOne:number;
  achievementTotalOne:number;
  achievementTwo:number;
  achievementActualOneFormatted:string;
  achievementProjectedOneFormatted:string;
  achievementTotalOneFormatted:string;
  achievementTwoFormatted:string;
  ownerId:string;
  ownerManagerId:string;
  ownerName:string;
  
  CSFs: {
    CSFName: string,
    CSFId: string
  }[]

  constructor(raw: JSON) {
    this.objectiveID = raw['PO.indskr_accountplanobjectiveid'] || '';
    this.createdBy = raw['PO.createdby'] || '';
    this.comletedValue = raw['PO.indskr_completedvalue'] || 0;
    this.formattedCompletedValue = raw['PO.indskr_completedvalue_Formatted'] || '';
    this.completedPercent = raw['PO.indskr_completedpercent'] || 0;
    this.target = raw['PO.indskr_targetamount'] || ''
    this.formattedTarget = raw['PO.indskr_targetamount_Formatted'] || '';
    this.name = raw['PO.indskr_name'] || '';
    this.type = accounPlanObjectiveType[('type_' + raw['PO.indskr_type'])]
    this.currency = raw['PO.transactioncurrencyid_Formatted'] || '';
    this.currencySymbol = raw["PO.transactioncurrencysymbol"];
    this.status = raw['statuscode'] && raw['statuscode'] == 548910000 ? 'Completed' : 'Open';
    this.productId = raw['PO.indskr_product'] ? raw['PO.indskr_product'] : ''
    this.productName = raw['PO.indskr_product_Formatted'] ? raw['PO.indskr_product_Formatted'] : '';
    this.achievementActualOne = raw['PO.indskr_achievement1yractual'] || null;
    this.achievementProjectedOne = raw['PO.indskr_achievement1yrprojected'] || null;
    this.achievementTotalOne = raw['PO.indskr_achievement1yryotal'] || null;
    this.achievementTwo = raw['PO.indskr_achievement2yr'] || null;
    this.achievementActualOneFormatted = raw['PO.indskr_achievement1yractual_Formatted'] || null;
    this.achievementProjectedOneFormatted = raw['PO.indskr_achievement1yrprojected_Formatted'] || null;
    this.achievementTotalOneFormatted = raw['PO.indskr_achievement1yryotal_Formatted'] || null;
    this.achievementTwoFormatted = raw['PO.indskr_achievement2yr_Formatted'] || null;
    this.ownerId = raw['objectiveowner.systemuserid'] || null;
    this.ownerName = raw['objectiveowner.fullname'] || null;
    this.ownerManagerId = raw['objectiveowner.parentsystemuserid'] || null;
    this.CSFs = [];
  }
}

export enum CurrencySymbolInHtml {
  'US Dollar' = '$',
  'Rupee' = '&#8377;',
  'Pound' = '&#163;',
}
export enum accounPlanObjectiveType {
  type_548910000 = 'QUANTITATIVE',
  type_548910001 = 'QUALITATIVE'
}

export enum AccountPlanMode {
  VIEW,
  EDIT,
  NEW
}

export class AccountPlanObjectiveReport {
  private onlineId: string;
  public offlineId: string;
  public reportId: string;
  reportName: string;
  planObjectiveID: string;
  planID: string
  createdBy: string;
  createdByUserID: string;
  completedValue: number;
  formattedCompletedValue: string;
  completedPercent: number;
  progressNotes: string;
  state: number;
  status: string;
  statusCode: any;
  CSFs: {
    CSFName: string,
    CSFId: string,
    deleted?: boolean
  }[];
  removedCSFs: {
    CSFName: string,
    CSFId: string
  }[];
  lastModifiedOn: string;
  formattedDate: string;
  createdOn: string;
  syncPending: boolean;
  trackAction: string;
  deleted?: boolean = false;
  public notes:Array<IONote> = [];
  constructor(raw: any) {
    this.planID = raw['indskr_accountplanid'] || '';
    this.planObjectiveID = raw['PO.indskr_accountplanobjectiveid'] || '';
    this.createdByUserID = raw['report.createdby'] || '';
    this.createdBy = raw['report.createdby_Formatted'] || '';
    this.reportName = raw['report.indskr_name'] || ''
    this.completedValue = raw['report.indskr_completedval'] || 0;
    if (raw['report.indskr_completedval@OData.Community.Display.V1.FormattedValue']) {
      this.formattedCompletedValue = raw['report.indskr_completedval@OData.Community.Display.V1.FormattedValue']
    }
    else this.formattedCompletedValue = '0.00'
    this.completedPercent = raw['report.indskr_completedpercent'] || 0;
    this.statusCode = raw['report.statuscode'] || 1;
    switch (raw['report.statuscode']) {
      case 1:
        this.status = 'Open';
        break;
      case 548910000:
        this.status = 'For Review'
        break;
      case 548910002:
        this.status = 'Approved'
        break;
      case 2:
        this.status = 'Inactive'
        break;
      default:
        this.status = 'Open'
        break
    }
    this.state = raw['report.statecode'] || 0;
    this.progressNotes = raw['report.indskr_progressnotes'] || '';
    this.reportId = raw['report.indskr_accountplanprogressreportid'] || ''
    this.offlineId = raw['report.indskr_externalid'] || ''
    this.lastModifiedOn = raw['report.modifiedon'] ? new Date(raw['report.modifiedon']).getTime().toString() : '';
    this.createdOn = raw['report.createdon'] ? new Date(raw['report.createdon']).getTime().toString() : ''
    this.formattedDate = format(parseInt(this.createdOn), 'MMM DD, YYYY') || '';
    this.syncPending = raw['syncPending'] ? raw['syncPending'] : false;
    this.trackAction = raw['track_action@OData.Community.Display.V1.FormattedValue'] ? raw['track_action@OData.Community.Display.V1.FormattedValue'] : '';
    this.CSFs = [];
    this.removedCSFs = [];
    this.notes = [];
    // if (Array.isArray(raw['notes']) && raw['notes'].length > 0) {
    //   raw['notes'].forEach(note => {

    //     if (note.hasOwnProperty('filesize') && note['filesize'] > 0) { // check if File size is greter than 0 then it has valid document
    //       note['isdocument'] = true;
    //     }

    //     let filteredNotes = this.notes.filter(noteFromObject => noteFromObject.noteId === note['annotationid'])
    //     //Some how duplicate notes are coming from service, So filter out the notes which are already present in teh activity.
    //     if (_.isEmpty(filteredNotes))
    //       this.notes.push(new IONote(note));
    //   });
    // }
  }

  public get idToCompare(): string {
    return this.onlineId + this.offlineId;
  }

}

export class CreateProgressReportRequestBody {
  statecode: number;
  statuscode: any;
  indskr_name?: string;
  indskr_completedval: number;
  indskr_completedpercent: number
  indskr_progressnotes: string;
  indskr_accountplanobjectiveid: string;
  indskr_externalid: string;
  indskr_accountplanid?: string;
  addCriticalSuccessFactors: {
    indskr_criticalsuccessfactorsid: string
  }[]
  removeCriticalSuccessFactors: {
    indskr_criticalsuccessfactorsid: string
  }[]
  deleted?: boolean = false;
  notes:Array<any> =[];
  //indskr_accountplanprogressreportid: string;
  constructor(raw: AccountPlanObjectiveReport) {
    this.statecode = raw.state;
    this.statuscode = raw.statusCode;
    this.indskr_completedval = raw.completedValue || 0;
    this.indskr_completedpercent = raw.completedPercent || 0
    this.indskr_accountplanobjectiveid = raw.planObjectiveID || '';
    //this.indskr_accountplanprogressreportid = raw.reportId || '';
    this.indskr_progressnotes = raw.progressNotes || '';
    this.indskr_externalid = raw.offlineId || '';
    //this.indskr_accountplanid = raw.planID || ''
    this.addCriticalSuccessFactors = raw.CSFs.map((o) => {
      return { indskr_criticalsuccessfactorsid: o.CSFId }
    })
    this.removeCriticalSuccessFactors = raw.removedCSFs.map((o) => {
      return { indskr_criticalsuccessfactorsid: o.CSFId }
    })
    this.indskr_name = raw.reportName || '';
    if (raw.deleted) this.deleted = raw.deleted;
    if (raw.notes && !_.isEmpty(raw.notes)) {
      let payloadNotes = [];
      raw.notes.map(note => {
        // if (note.noteId && note.noteId.includes('offline') || note.pendingPushForDynamics) {
          payloadNotes = this.getNotesPayload(note, payloadNotes);
        // }
      });
      this.notes = payloadNotes;
    }
  }

  private getNotesPayload(note: IONote, payloadNotes: any[], forOffline: boolean = false) {

    let documentNote
    if (note.hasDocument) {
      documentNote = this._getNotesWithDocumentJSON(note);
      if (forOffline) {
        documentNote['isdocument'] = true;
      }
    } else {
      documentNote = this._getNotesWithoutDocument(note)
    }

    if (forOffline) {
      documentNote['ownerName'] = note.ownerName;
      documentNote['noteid'] = note.noteId;
      documentNote['pendingPushForDynamics'] = note.pendingPushForDynamics;
    }

    documentNote['createdon'] = `${note.createdTime.getTime()}`;

    if (note.isDeleted) {
      if (note.noteId && !note.noteId.includes('offline')){
        payloadNotes.push(documentNote);
      }
    } else {
      payloadNotes.push(documentNote);
    }

    return payloadNotes;
  }

  private _getNotesWithoutDocument(note: IONote): any {

    let noteWithoutFilePayload = {
      notetext: note.noteText,
      noteid: note.noteId && !note.noteId.includes('offline') ? note.noteId : null,
      ownerid: note.ownerId,
      ownerName: note.ownerName,
      deleted: note.isDeleted,
      filesize: 0,
      mimetype: '',
      documentbody: '',
      filename: '',
    }
    return noteWithoutFilePayload;
  }

  private _getNotesWithDocumentJSON(note: IONote): any {

    let noteWithFilePayload = {
      notetext: note.noteText,
      ownerid: note.ownerId,
      ownerName: note.ownerName,
      deleted: note.isDeleted,
      filename: note.documentName,
      filesize: note.documentSize,
      mimetype: note.documentMimeType,
      noteid: note.noteId && !note.noteId.includes('offline') ? note.noteId : null
    }

    if (note.pendingPushForDynamics) {
      if (!_.isEmpty(note.documentDataURL)) {
        noteWithFilePayload['documentbody'] = note.documentDataURL
      }
    }
    return noteWithFilePayload;
  }
}
export enum AccountPlanReportState {
  'Open' = 1,
  'For review' = 548910000,
  'Approved' = 548910002,
  'Inactive' = 2
}
