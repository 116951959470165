<ion-header mode="md">
  <ion-toolbar class="header">
    <ion-title>{{ header }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="icon-only" (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-row>
  <ion-col>
    <ind-form-field [viewData]="monthField"></ind-form-field>
  </ion-col>
  <ion-col>
    <ind-form-field [viewData]="yearField"></ind-form-field>
  </ion-col>
</ion-row>

<div>
  <ion-button fill="clear" class="ion-float-right" (click)="onConfirm()" [disabled]="shouldDisable()">{{ buttonLabel }}</ion-button>
</div>

