import { TrendingFeed } from './../../classes/xperiences/trending.customer.class';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Endpoints } from "../../../config/endpoints.config";
import { TrendingCustomer, XpCustomer, TrendingAccount } from "../../classes/xperiences/trending.customer.class";

@Injectable({
  providedIn: 'root'
})
export class XperiencesDataService {
    constructor(private http: HttpClient, private authService: AuthenticationService) {

    }

    public async fetchTrendingCustomers(actions: string[]): Promise<TrendingCustomer[]> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_TRENDING_CUTOMERS;
        const request: { featureActions: string[] } = { "featureActions": actions };
        return await this.http.post<TrendingCustomer[]>(url, request).toPromise();
    }

    public async fetchXpCustomers(actions: string[]): Promise<XpCustomer[]> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_TRENDING_CUTOMERS;
        const request: { featureActions: string[], userid: string } = { "featureActions": actions, "userid": this.authService.user.systemUserID };
        return await this.http.post<XpCustomer[]>(url, request).toPromise();
    }

    public async fetchOptionSets(): Promise<any> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_INSIGHT_OPTIONSETS;
        return await this.http.get<any>(url).toPromise();
    }

    public async fetchInterestCategories(): Promise<any> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_INSIGHT_INTEREST_CATEGORIES;
        return await this.http.get<any>(url).toPromise();
    }

    public async createCustomerInsight(request: any): Promise<any> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.CREATE_CUSTOMER_INSIGHT;
        return await this.http.post(url, request).toPromise();
    }

    public async saveCustomer(customer: XpCustomer, save: string): Promise<any> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.SAVE_CUSTOMER;
        const request = { "contactid": customer.contactid, "userid": this.authService.user.systemUserID, "action": save };
        return await this.http.post(url, request).toPromise();
    }
    
    public async sendFeedback(contactid: string, feedback: string, topic: string, reason?: string): Promise<any> {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.SEND_FEEDBACK;
        let request: any = { 
            "contactid": contactid, 
            "feedback": feedback, 
            "userid": this.authService.user.systemUserID, 
            "title": topic
        };
        if (feedback == 'negative') {
            request.negative_feedback_reason = reason;
        }
        return await this.http.post(url, request).toPromise();
    }

    public async sendRelationshipInsightFeedback(contactId: string, relatedContactId: string, feedback: string) {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.RELATIONSHIP_INSIGHT_FEEDBACK;
        let request: any = { 
            "contactid": contactId,
            "related_contactid": relatedContactId,
            "feedback": feedback, 
            "userid": this.authService.user.systemUserID, 
        };
        return await this.http.post(url, request).toPromise();
    }

    public async getMarketingEmails(id: string) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.MARKETING_EMAIL_DETAILS;
        url = url.replace("{email_id}", id);
        return await this.http.get(url).toPromise()
    }

    public async createGeneralInsight(request: any) {
        const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.CREATE_GENERAL_INSIGHT;
        return await this.http.post(url, request).toPromise();
    }

    public async fetchJourneyInsigthData(id: string) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_JOURNEY_INSIGHTS;
        url = url.replace("{insight_id}", id);
        return await this.http.get(url).toPromise();
    }

    public async fetchInterestInsigthData(id: string) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_INTEREST_INSIGHTS;
        url = url.replace("{insight_id}", id);
        return await this.http.get(url).toPromise();
    }

    public async fetchRelationshipInsigthData(id: string) {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_RELATIONSHIP_INSIGHTS;
        url = url.replace("{insight_id}", id);
        return await this.http.get(url).toPromise();
    }

    public async fetchTrendigAccountsData(): Promise<TrendingAccount[]> {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_TRENDING_ACCOUNTS;
        const request: { userid: string } = {"userid": this.authService.user.systemUserID };
        return await this.http.post<TrendingAccount[]>(url, request).toPromise();
    }

    public async fetchTrendingFeedsData(): Promise<TrendingFeed[]> {
        let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.xperiences.FETCH_TRENDING_FEEDS;
        const request: { userid: string } = {"userid": this.authService.user.systemUserID };
        return await this.http.post<TrendingFeed[]>(url, request).toPromise();
    }
}
