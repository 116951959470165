import {Injectable} from "@angular/core";
import {DeviceService} from "@omni/services/device/device.service";
import {OnekeyApiDataService} from "@omni/data-services/onekey/onekey-api.data.service";
import {OKEntityType} from "@omni/classes/onekey/ok-searchkeys.class";
import {BehaviorSubject} from "rxjs";
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class OnekeyApiService {

  public oneKeyResultSet: Array<object> = [];
  public oneKeyAccounts: Array<object> = [];
  public oneKeyContacts: Array<object> = [];
  public okSearchResult = new BehaviorSubject<Array<object>>([]);
  okSearchResultObserver = this.okSearchResult.asObservable();

  constructor(
    public device: DeviceService,
    public onekeyApiDataService: OnekeyApiDataService,
  ) {
  }

  async searchOneKey(payload) {
    if (this.device.isOffline) return;
    // await this.onekeyApiDataService.authenticate().then(async (res) => {
    //   if (res) {
    //     console.log("Established connection to OneKey Global API...");
        await this.onekeyApiDataService.searchOneKey(payload).then(async (result) => {
          if (result && result['response'] && result['response']['results'] && Array.isArray(result['response']['results'])) {
            this.oneKeyResultSet = result['response']['results'];
            if (this.oneKeyResultSet[0]['entityType'] == OKEntityType.WORKPLACE) {
              await this.transformDataToAccounts();
            } else if (this.oneKeyResultSet[0]['entityType'] == OKEntityType.ACTIVITY) {
              await this.transformDataToContacts();
            }
            console.log(this.oneKeyResultSet);
          }
        }).catch(e => console.log(e));

        //testing purpose
        /*let o = {
          "isoCod2": "NL",
          "resultSize": 1000
        };
        await this.onekeyApiDataService.refreshOneKey(o).then(async (result) => {
          if (result) {
            console.log("refresh OK successful");
          }
        }).catch(e => console.log(e));*/

    //   }
    // }).catch(e => {
    //   console.log("Failed connecting to OneKey Global API...", e);
    // });
  }

  async downloadOnekey(payload): Promise<any> {
    if (this.device.isOffline) return;
    let response = null;
    await this.onekeyApiDataService.searchOneKey(payload).then(async (result) => {
      if (result && result['response'] && result['response']['results'] && Array.isArray(result['response']['results'])) {
        response = result['response']['results'];
      }
    });
    return response;
  }

  async submitPop(payload) {
    if (this.device.isOffline) return;
    await this.onekeyApiDataService.submitPop(payload).then((res) => {
      if (res['response'] && res['response']['status'] == "SUCCESS") {
        console.log('added to client subscribed population');
      }
    }).catch(e => console.log(e));
  }

  async transformDataToAccounts() {
    this.oneKeyAccounts = [];
    this.oneKeyResultSet.forEach((obj, i) => {
      obj['workplace']['isInContract'] = obj['isInContract'];
      obj['workplace']['isSelected'] = false;
      this.oneKeyAccounts.push(obj['workplace']);
    });
    this.oneKeyAccounts = _.uniqBy(this.oneKeyAccounts, 'workplaceEid');
    this.okSearchResult.next(this.oneKeyAccounts);
  }

  async transformDataToContacts() {
    this.oneKeyContacts = [];
    this.oneKeyResultSet.forEach(obj => {
      obj['individual']['isInContract'] = obj['isInContract'];
      obj['individual']['activityEid'] = obj['onekeyEid'];
      obj['individual']['address'] = obj['workplace']['workplaceAddresses'];
      obj['individual']['isSelected'] = false;
      this.oneKeyContacts.push(obj['individual']);
    });
    this.oneKeyContacts = _.uniqBy(this.oneKeyContacts, 'individualEid');
    this.okSearchResult.next(this.oneKeyContacts);
  }
}
