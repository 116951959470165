import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
export type EventHandler = (...args: any[]) => any;
@Injectable({
  providedIn: 'root',
})
export class Events {
  // protected readonly subjects = new Map<string, (args: any[]) => any>();
  private readonly subscriptions = new Map<
    string,
    Map<EventHandler, () => void>
  >();
  constructor() {}
  /**
   * Subscribe to an event topic. Events that get posted to that topic will trigger the provided handler.
   *
   * @param topic the topic to subscribe to
   * @param handler the event handler
   */
  subscribe(topic: string, ...handlers: EventHandler[]) {
    if (!this.subscriptions.has(topic)) {
      this.subscriptions.set(topic, new Map<EventHandler, () => void>());
    }
    const subscriptions = this.subscriptions.get(topic);
    function addEventListener(
      type: string,
      eventHandler: EventListenerOrEventListenerObject
    ) {
      const newType = 'omni:' + type;
      window.addEventListener(newType, eventHandler);
      return () => window.removeEventListener(newType, eventHandler);
    }
    handlers.forEach((handler) => {
      const eventHandler = (event: CustomEvent<any[]>) => {
        handler(...event.detail);
      };
      if (subscriptions.has(handler)) {
        subscriptions.get(handler)();
      }
      subscriptions.set(handler, addEventListener(topic, eventHandler));
    });
  }
  /**
   * Unsubscribe from the given topic. Your handler will no longer receive events published to this topic.
   *
   * @param topic the topic to unsubscribe from
   * @param handler the event handler
   *
   * @return true if a handler was removed
   */
  unsubscribe(topic: string, handler?: EventHandler): boolean {
    const subscriptions = this.subscriptions.get(topic);
    if (!subscriptions) {
      return false;
    }
    if (!handler) {
      let result = true;
      subscriptions.forEach((_, key) => {
        result = this.unsubscribe(topic, key) && result;
      });
      return result;
    }
    // We need to find and remove a specific handler
    const unsub = subscriptions.get(handler);
    if (!unsub) {
      return false;
    }
    unsub();
    return subscriptions.delete(handler);  // && this.subjects.delete(topic);
  }
  /**
   * Publish an event to the given topic.
   *
   * @param topic the topic to publish to
   * @param eventData the data to send as the event
   */
  publish(topic: string, ...args: any[]) {
    const event = new CustomEvent<any[]>('omni:' + topic, { detail: args });
    return window.dispatchEvent(event);
  }
}
