<ion-header>
    <ion-toolbar class="page-title">
        <ion-buttons left>
            <ion-button icon-only (click)="goBack()" class='back-btn'>
                <ion-icon name="chevron-back-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title text-center>{{pdfData.pageTitle}}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content no-padding>
    <ng-container *ngIf="!isSourceAvail; else checkIfError">
    </ng-container>
    <ng-template #checkIfError>
        <ng-container *ngIf="error; else showPreview">
            <p class="no-data-message">{{'CONTACT_ERROR_IN_LOADING' | translate}} <i>PDF</i>.<br />{{error}}</p>
        </ng-container>
    </ng-template>
    <ng-template #showPreview>
        <ng2-pdfjs-viewer #pdfViewer openFile="false" viewBookmark="false" download="false">
        </ng2-pdfjs-viewer>
    </ng-template>

</ion-content>
<!-- <ion-footer *ngIf="isSourceAvail"> -->
    <footer-toolbar *ngIf="isSourceAvail" [footerView]="'detailView'" [selectedView]="'pdfPreview'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->
