import { Injectable, NgZone } from '@angular/core';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import * as _ from 'lodash';
import { Endpoints } from 'src/config/endpoints.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UIService } from '@omni/services/ui/ui.service';
import { Account, ApprovalActivity, PositionListTargetContact, CustomerPositionList, CustomerPositionListType, StatusCode } from '@omni/classes/territory-management-list/customer-position-list.class';
import { CustomerListPeriod } from '@omni/classes/territory-management-list/customer-list-period.class';
import moment from 'moment';
import { PositionListTarget } from '@omni/classes/territory-management-list/position-list-target.class';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { TranslateService } from '@ngx-translate/core';
import { DiskService } from '@omni/services/disk/disk.service';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from '@omni/config/pouch-db.config';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { Control, ControlDataType, DynamicForm, FormType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { DEFAULT_CONTACT_DISPLAY_FORM, RequiredContactAttributes } from '@omni/config/dynamic-forms/default-contact/default-contact-create';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import * as XML2JS from 'xml2js';
import { searchIndexDataModel } from '@omni/models/search-config-data-model';
import { FETCH_CONTACTS_LINK_ENTITES } from '@omni/config/fetch-xml/contact-fetchXMLs';
import { CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY } from '@omni/config/dynamic-forms/affiliations-contants';
import { Contact } from '@omni/classes/contact/contact.class';
import { data } from 'jquery';
import { DeviceService } from '@omni/services/device/device.service';
import { ScientificInfo } from '@omni/classes/contact/scientific-info.class';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { isValid } from 'date-fns';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';
import { MyAssistantService, NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import { ContactDataService } from '../contact/contact.data.service';


@Injectable({
  providedIn: 'root'
})
export class TerritoryManagementDataService {
  private _defaultLinkedEntityMappingData = [];
  private _advancedSearchConfigServiceWorker;
  private _defaultLinkedEntityDataMappingServiceWorker;
  private customerPositionNotificationModel: IndNotificationDataModel;
  constructor(
    private http: HttpClient,
    private uiService: UIService,
    private dynamics: DynamicsClientService,
    private authService: AuthenticationService,
    private contactService: ContactOfflineService,
    private territoryManagementService: TerritoryManagementService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private translate: TranslateService,
    private diskService: DiskService,
    private readonly dynamicFormsService: DynamicFormsService,
    private searchConfigService: SearchConfigService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private readonly device: DeviceService,
    public deltaService: DeltaService,
    private myAssistantService: MyAssistantService,
    private contactDataService: ContactDataService,
  ) {
    this._initAdvancedSearchServiceWorker();
    this._initDefaultLinkedEntityDataMappingServiceWorker();
  }

  public async getAvailableContacts(customerListPeriodId: string) {
    const configuredAccounts: any = await this.fetchConfiguredAccounts(customerListPeriodId);
    if (configuredAccounts && configuredAccounts.length > 0) {
      const accountIds = configuredAccounts.map((o) => o['account.accountid']);
      const affiliatedContacts = await this.getAffiliatedContacts(accountIds, 'account',[], accountIds);
      if (affiliatedContacts.length) {
        this.territoryManagementService.setAvailableContacts(affiliatedContacts);
      }
      return accountIds;
    }
  }

  public async fetchConfiguredAccounts(customerListPeriodId: string) {
    const positionIds = this.authService.user.positions.map(o => {
      return o.ID
    });
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>'
    })
    let fetchXML = fetchQueries.territoryManagementLists.fetchConfigAccountsByCustomerPositionList;
    fetchXML = fetchXML.split('{positionId}').join(this.authService.user.xPositionID).split('{positionIDs}').join(positionString);
    fetchXML = fetchXML.replace('{customerListPeriodId}', customerListPeriodId);
    return await this.dynamics.executeFetchQuery('indskr_customerpositionlists', fetchXML).then(async (response: any) => {
      if (response) return response;
    }).catch((err) => {
      console.error('Failed to fetch available accounts', err);
      return [];
    });
  }

  private async getAffiliatedContacts(ids, mode, fetchedContacts?, configuredAccountIds=[]): Promise<PositionListTarget[]> {
    console.log("In getAffiliatedContacts :")
    let startTime = new Date().getTime();
    let contactAccAffs = await this.contactService.getAccountContactAffiliationsFromDB();
    console.log("Total time taken to fetch C-A Affiliations from DB in ms: ", (new Date().getTime() - startTime));
    startTime = new Date().getTime();
    const myActiveContactIds = this.contactService.contacts.map(contact => { if (contact.isActive) return contact.ID });
    if (mode === 'account') {
      contactAccAffs = contactAccAffs.filter((contactAccAff) => ids.includes(contactAccAff['indskr_accountcontactaffiliation.indskr_accountid']) &&
        myActiveContactIds.includes(contactAccAff['indskr_accountcontactaffiliation.indskr_contactid']));
    } else {
      contactAccAffs = contactAccAffs.filter((contactAccAff) => ids.includes(contactAccAff['indskr_accountcontactaffiliation.indskr_contactid'])
        && configuredAccountIds.includes(contactAccAff['indskr_accountcontactaffiliation.indskr_accountid']));
    }
    console.log("Total time taken to filter config C-A in ms: ", (new Date().getTime() - startTime));
    startTime = new Date().getTime();
    contactAccAffs = _.uniqBy(contactAccAffs, (item) => [item['indskr_accountcontactaffiliation.indskr_accountid'], item['indskr_accountcontactaffiliation.indskr_contactid']].join());
    let grouped = _.groupBy(contactAccAffs, (item) => item['indskr_accountcontactaffiliation.indskr_contactid']);
    console.log("Total time taken to unique & groupBy config C-A in ms: ", (new Date().getTime() - startTime));
    startTime = new Date().getTime();
    const positionListTargets: PositionListTarget[] = [];
    for (let key in grouped) {
      let positionListTarget: PositionListTarget = null;
      const index = positionListTargets.findIndex(target => target.positionListTargetId === key);
      if (index >= 0) {
        positionListTarget = positionListTargets[index];
      } else {
        const contact = new PositionListTargetContact(key, grouped[key][0]['indskr_accountcontactaffiliation.indskr_contactid_Formatted'], []);
        contact['isSelected'] = mode === 'contact';
        positionListTarget = new PositionListTarget(grouped[key][0]['positionListTargetId'], contact);
      }
      _.orderBy(grouped[key], 'indskr_accountcontactaffiliation.indskr_accountid_Formatted').forEach(accountResp => {
          const account = new Account(accountResp['indskr_accountcontactaffiliation.indskr_accountid'], accountResp['indskr_accountcontactaffiliation.indskr_accountid_Formatted']);
          account['isSelected'] = mode === 'contact' && fetchedContacts.some((fetchedContact) => fetchedContact['account4.accountid'] === accountResp['indskr_accountcontactaffiliation.indskr_accountid'] && key === fetchedContact['_indskr_contact_value']);
          account['parentId'] = key;
          positionListTarget.contact.accounts.push(account);
      });
      if (index == -1) positionListTargets.push(positionListTarget);
      else positionListTargets[index] = positionListTarget;
    }
    console.log("Total time taken to prepare PositionListTargets in ms: ", (new Date().getTime() - startTime));
    return _.orderBy(positionListTargets, obj => obj.contact.name);
  }

  public async fetchCurrentquarterContacts(customerListPeriodId: string, nextCustomerListPeriodId: string, isForMatrix: boolean = false, configuredAccountIds): Promise<PositionListTarget[]> {
    let fetchXML = fetchQueries.territoryManagementLists.fetchPostionList;
    const positionIds = this.authService.user.positions.map(o => {
      return o.ID
    });
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>'
    })
    fetchXML = fetchXML.split('{positionID}').join(this.authService.user.xPositionID)
      .replace('{customerListPeriodId}', customerListPeriodId)
      .replace('{nextCustomerListPeriodId}', nextCustomerListPeriodId)
      .split('{positionIDs}').join(positionString);
    return await this.dynamics.executeFetchQuery('indskr_positionlisttargets', fetchXML).then(async (response: any) => {
      if (response && response.length > 0) {
        let contactIds = response.map((o) => o['_indskr_contact_value']);
        contactIds = _.uniq(contactIds);
        const affiliatedContacts = await this.getAffiliatedContacts(contactIds, 'contact', response, configuredAccountIds);
        if (affiliatedContacts.length && !isForMatrix) {
          let selectCustomerPositionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
          selectCustomerPositionList.positionListTargets = affiliatedContacts;
          this.territoryManagementService.setMyContacts(selectCustomerPositionList);
        }
        return affiliatedContacts;
      } return [];
    }).catch((err) => {
      console.error('Failed to fetch available accounts', err);
      return [];
    });
  }

  public async fetchMyContactsByCustomerPositionList(customerPositionList: CustomerPositionList, isReadOnly: boolean) {
    const positionListTargets: PositionListTarget[] = [];
    const fetchXML = fetchQueries.territoryManagementLists.fetchMyContactsByCustomerPositionList.replace("{customerPositionListId}", customerPositionList.indskr_customerpositionlistid);
    await this.dynamics.executeFetchQuery('indskr_positionlisttargets', fetchXML).then(async (responses: any) => {
      if (!_.isEmpty(responses)) {
        customerPositionList.statecode = responses[0]['indskr_customerpositionlist.statecode'];
        customerPositionList.statuscode = responses[0]['indskr_customerpositionlist.statuscode'];
        customerPositionList.approvalStatus = responses[0]['indskr_customerpositionlist.indskr_approvalstatus'];
        customerPositionList.indskr_numberadded = responses[0]['indskr_customerpositionlist.indskr_numberadded'] || 0;
        customerPositionList.indskr_netchange = responses[0]['indskr_customerpositionlist.indskr_netchange'] || 0;
        customerPositionList.indskr_percentagechange = responses[0]['indskr_customerpositionlist.indskr_percentagechange'] || 0;
        customerPositionList.indskr_numberremoved = responses[0]['indskr_customerpositionlist.indskr_numberremoved'] || 0;
        const positionListTargetMap: any = _.groupBy(responses, 'positionListTargetId');
        const contactIds = responses.map((resp) => resp.contactId);
        if (isReadOnly) {
          for (let key in positionListTargetMap) {
            let positionListTarget: PositionListTarget = null;
            const index = positionListTargets.findIndex(target => target.positionListTargetId === key);
            if (index >= 0) {
              positionListTarget = positionListTargets[index];
            } else {
              const contact = new PositionListTargetContact(positionListTargetMap[key][0]['contactId'], positionListTargetMap[key][0]['contactName'], []);
              contact['isSelected'] = true;
              positionListTarget = new PositionListTarget(positionListTargetMap[key][0]['positionListTargetId'], contact);
            }
            _.orderBy(positionListTargetMap[key], 'accountName').forEach(accountResp => {
              const account = new Account(accountResp['accountId'], accountResp['accountName']);
              account['isSelected'] = true;
              account['parentId'] = accountResp['contactId'];
              positionListTarget.contact.accounts.push(account);
            });

            if (index == -1) positionListTargets.push(positionListTarget);
            else positionListTargets[index] = positionListTarget;
          }
        } else {
          const configuredAccounts: any = await this.fetchConfiguredAccounts(customerPositionList.listPeriod.indskr_customerlistperiodid);
          if (configuredAccounts && configuredAccounts.length > 0) {
            const accountIds = configuredAccounts.map((o) => o['account.accountid']);
            let contactAccAffs = await this.contactService.getAccountContactAffiliationsFromDB();
            contactAccAffs = contactAccAffs.filter((contactAccAff) => contactIds.includes(contactAccAff['indskr_accountcontactaffiliation.indskr_contactid']) 
            && accountIds.includes(contactAccAff['indskr_accountcontactaffiliation.indskr_accountid']));
            contactAccAffs = _.uniqBy(contactAccAffs, (item) => [item['indskr_accountcontactaffiliation.indskr_accountid'], item['indskr_accountcontactaffiliation.indskr_contactid']].join());
            let grouped = _.groupBy(contactAccAffs, (item) => item['indskr_accountcontactaffiliation.indskr_contactid']);
            

            for (let key in positionListTargetMap) {
              let positionListTarget: PositionListTarget = null;
              const index = positionListTargets.findIndex(target => target.positionListTargetId === key);
              if (index >= 0) {
                positionListTarget = positionListTargets[index];
              } else {
                const contact = new PositionListTargetContact(positionListTargetMap[key][0]['contactId'], positionListTargetMap[key][0]['contactName'], []);
                contact['isSelected'] = true;
                positionListTarget = new PositionListTarget(positionListTargetMap[key][0]['positionListTargetId'], contact);
              }

              let selectedAccountsIds = positionListTargetMap[key].map((account) => account['accountId']);
              _.orderBy(grouped[positionListTargetMap[key][0]['contactId']], 'indskr_accountcontactaffiliation.indskr_accountid_Formatted').forEach(accountResp => {
                const account = new Account(accountResp['indskr_accountcontactaffiliation.indskr_accountid'], accountResp['indskr_accountcontactaffiliation.indskr_accountid_Formatted']);
                account['isSelected'] = selectedAccountsIds.includes(accountResp['indskr_accountcontactaffiliation.indskr_accountid']);
                account['parentId'] = positionListTargetMap[key][0]['contactId'];
                positionListTarget.contact.accounts.push(account);
              });

              if (index == -1) positionListTargets.push(positionListTarget);
              else positionListTargets[index] = positionListTarget;
            }
          }
        }
      }
    }).catch((err) => {
      console.error('Failed to fetch my contact by customer position list', err);
    });
    return _.orderBy(positionListTargets, obj => obj.contact.name);
  }

  public async fetchCurrentListPeriod(loadFromDBOnly?: boolean): Promise<CustomerListPeriod> {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) return;
    if (loadFromDBOnly) {
      this.diskService.retrieve(DB_KEY_PREFIXES.CURRENT_LIST_PERIOD).then((doc) => {
        if (doc?.raw) this.territoryManagementService.currentListPeriod = doc.raw;
      })
    } else {
      const todayDate = moment().format("YYYY-MM-DD");
      let fetchXML = fetchQueries.territoryManagementLists.fetchCurrentCustomerListPeriod
        .replace('{date}', todayDate)
        .replace('{date}', todayDate)
        .split('{positionId}').join(this.authService.user.xPositionID);
      return await this.dynamics.executeFetchQuery('indskr_customerlistperiods', fetchXML).then((responses) => {
        if (!_.isEmpty(responses) && responses.length == 1) {
          const customerListPeriodResp = responses[0];
          const customerListPeriod = new CustomerListPeriod(customerListPeriodResp);
          if (customerListPeriodResp['prevCustomerListPeriodId']) {
            customerListPeriod.indskr_previouslistperiod = new CustomerListPeriod({
              customerListPeriodId: customerListPeriodResp['prevCustomerListPeriodId'],
              customerListPeriodEndDate: customerListPeriodResp['prevCustomerListPeriodEndDate'],
              customerListPeriodStartDate: customerListPeriodResp['prevCustomerListPeriodStartDate'],
              tagLabel: customerListPeriodResp['prevTagLabel'],
              customerListPeriodName: customerListPeriodResp['prevCustomerListPeriodName'],
              indskr_adjustmentlimit: customerListPeriodResp['prevAdjustmentLimit']
            });
          }
          if (customerListPeriodResp['nextCustomerListPeriodId']) {
            customerListPeriod.indskr_nextlistperiod = new CustomerListPeriod({
              customerListPeriodId: customerListPeriodResp['nextCustomerListPeriodId'],
              customerListPeriodEndDate: customerListPeriodResp['nextCustomerListPeriodEndDate'],
              customerListPeriodStartDate: customerListPeriodResp['nextCustomerListPeriodStartDate'],
              tagLabel: customerListPeriodResp['nextTagLabel'],
              customerListPeriodName: customerListPeriodResp['nextCustomerListPeriodName'],
              indskr_adjustmentlimit: customerListPeriodResp['nextAdjustmentLimit']
            });
          }
          this.territoryManagementService.currentListPeriod = customerListPeriod;

          this.diskService.updateOrInsert(DB_KEY_PREFIXES.CURRENT_LIST_PERIOD, (doc) => {
            doc = {
              raw: []
            };
            doc.raw = customerListPeriod;
            return doc;
          })
          return customerListPeriod;
        }
      }).catch((er) => {
        console.error("Failed to fetch customer position list due to : ", er);
        this.territoryManagementService.currentListPeriod = null;
        return null;
      });
    }
  }

  public async fetchCustomerPositionList(): Promise<CustomerPositionList[]> {
    let customerPositionLists: CustomerPositionList[] = [];
    let fetchXML = fetchQueries.territoryManagementLists.fetchCustomerPositionList.replace('{positionId}', this.authService.user.xPositionID);
    await this.dynamics.executeFetchQuery('indskr_customerpositionlists', fetchXML).then(async (responses: []) => {
      this.formatCustomerPositionList(responses, customerPositionLists);
    }).catch((er) => {
      console.error("Failed to fetch customer position list due to : ", er);
    });
    this.territoryManagementService.customerPositionList.next(customerPositionLists);
    return customerPositionLists;
  }

  public async fetchApprovalTabData(): Promise<CustomerPositionList[]> {
    await this.uiService.displayLoader();
    let customerPositionLists: CustomerPositionList[] = [];
    let fetchXML = fetchQueries.territoryManagementLists.fetchApprovalTab;
    await this.dynamics.executeFetchQuery('indskr_customerpositionlists', fetchXML).then(async (responses: []) => {
      this.formatCustomerPositionList(responses, customerPositionLists);
      customerPositionLists = _.orderBy(customerPositionLists, obj => obj.approvalActivityModifiedOn, "desc");
      customerPositionLists = _.uniqBy(customerPositionLists, obj=> obj.indskr_customerpositionlistid);
    }).catch((er) => {
      console.error("Failed to fetch approval tab customer position list due to : ", er);
    });
    await this.uiService.dismissLoader();
    return customerPositionLists;
  }

  private formatCustomerPositionList(responses: [], customerPositionLists: CustomerPositionList[]) {
    if (!_.isEmpty(responses)) {
      responses.forEach((resp: any) => {
        const listPeriod: CustomerListPeriod = new CustomerListPeriod(resp);
        listPeriod['startDateFormatted'] = this.datePipe.transform(new Date(listPeriod.indskr_liststartdate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        listPeriod['endDateFormatted'] = this.datePipe.transform(new Date(listPeriod.indskr_listenddate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        if (resp['prevCustomerListPeriodId']) {
          listPeriod.indskr_previouslistperiod = new CustomerListPeriod({
            customerListPeriodId: resp['prevCustomerListPeriodId'],
            nextListManagementWindowStartDate: resp['prevListPeriodManagementWindowStartDate'],
            nextListManagementWindowEndDate: resp['prevListPeriodManagementWindowEndDate']
          })
        }
        resp['listPeriod'] = listPeriod;
        const customerPositionList = new CustomerPositionList(resp);
        customerPositionLists.push(customerPositionList);
      })
    }
    return customerPositionLists;
  }

  async setPayload() {
    const selectedCustomerPostionList: CustomerPositionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    let positionListTargets = [];
    let isInvalid = false;

    for (let positionListTarget of selectedCustomerPostionList.positionListTargets) {

      const contact = positionListTarget.contact;
      let accounts = [];

      const isContactUpdated = this.isContactUpdated(positionListTarget);
      if (isContactUpdated) {

        if (!positionListTarget.contact.accounts.some(account => account['isSelected'])) {
          this.notificationService.notify(this.translate.instant('ATLEAST_ONE_ACCOUNT'), 'Territory Management', 'top', ToastStyle.DANGER);
          isInvalid = true;
          break;
        }

        if (positionListTarget.positionListTargetId && selectedCustomerPostionList.indskr_customerpositionlistid) {
          accounts = this.setAccountPayload(positionListTarget);
        } else {
          accounts = contact.accounts.filter(account => account['isSelected']).map(account => ({ accountId: account.id }));
        }

        positionListTargets.push({
          contactId: contact.id,
          ...(positionListTarget.positionListTargetId && { positionListTargetId: positionListTarget.positionListTargetId }), // if positionListTargetId exist add that property
          accounts
        });
      }
    }
    if(isInvalid) return;

    if (this.territoryManagementService.rawCustomerPositionList && selectedCustomerPostionList.indskr_customerpositionlistid) {
      positionListTargets = [...positionListTargets, ...this.setContactPayload()]
    }
    if (!positionListTargets.length) return;

    let payload = {
      'indskr_type': CustomerPositionListType.CONTACT,
      "indskr_netchange": selectedCustomerPostionList.indskr_netchange,
      "indskr_percentagechange": selectedCustomerPostionList.indskr_percentagechange,
      "indskr_numberadded": selectedCustomerPostionList.indskr_numberadded,
      "indskr_numberremoved": selectedCustomerPostionList.indskr_numberremoved,
      "customerListPeriodId": selectedCustomerPostionList.listPeriod.indskr_customerlistperiodid,
      positionListTargets
    }

    return payload;
  }

  setAccountPayload(positionListTarget) {
    let accounts = [];
    const rawPositionList = this.territoryManagementService.rawCustomerPositionList.positionListTargets.find((item) => item.positionListTargetId === positionListTarget.positionListTargetId);
    const updatedAccounts = _.differenceWith(positionListTarget.contact.accounts, rawPositionList.contact.accounts, _.isEqual);
    for (let updatedAccount of updatedAccounts) {
      if (updatedAccount.isSelected) {
        accounts.push({ accountId: updatedAccount.id });
      } else {
        accounts.push({ accountId: updatedAccount.id, deleted: true });
      }
    }
    return accounts;
  }

  isContactUpdated(positionListTarget) {
    if (!positionListTarget.positionListTargetId) return true; // if positionListTargetId not exist, that will be new contact
    const rawPositionList = this.territoryManagementService.rawCustomerPositionList.positionListTargets.find((item) => item.positionListTargetId === positionListTarget.positionListTargetId);
    return !_.isEqual(positionListTarget, rawPositionList);
  }

  setContactPayload() {
    let payload = [];
    const selectedCustomerPostionList: CustomerPositionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    const contactsRemoved: any = _.differenceWith(this.territoryManagementService.rawCustomerPositionList.positionListTargets, selectedCustomerPostionList.positionListTargets, (rawItem, selectedItem) => rawItem.contact.id === selectedItem.contact.id)

    for (let contactRemoved of contactsRemoved) {
      payload.push({
        contactId: contactRemoved.contact.id,
        positionListTargetId: contactRemoved.positionListTargetId,
        deleted: true
      });
    }

    return payload;
  }

  async fetchApprovalActivities(): Promise<ApprovalActivity[]> {
    const approvalActivities: ApprovalActivity[] = [];
    const selectedCustomerPostionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    if (selectedCustomerPostionList?.indskr_customerpositionlistid) {
      await this.uiService.displayLoader();
      const fetchXML = fetchQueries.territoryManagementLists.fetchApprovalActivities.replace('{customerListPeriodId}', selectedCustomerPostionList.indskr_customerpositionlistid);
      await this.dynamics.executeFetchQuery('indskr_approvalactivities', fetchXML).then(async (responses: []) => {
        if (responses) {
          responses.forEach((resp: any) => {
            resp['createdOnFormatted'] = this.datePipe.transform(new Date(resp['createdon']), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            resp['statusCodeFormatted'] = this.territoryManagementService.getApprovalActiviyStatusCodeFormatted(resp['statuscode']);
            approvalActivities.push(new ApprovalActivity(resp));
          })
        }
      }).catch((er) => {
        console.error("Failed to fetch approval activies due to : ", er);
      });
      await this.uiService.dismissLoader();

    }
    return approvalActivities;
  }

  async updateApprovalActivity(payload, approvalActivityId: string) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.quotes.UPDATE_APPROVAL_ACTIVITY.replace('{approvalActivityId}', approvalActivityId);
    return await this.http.patch<any>(url, payload).toPromise();
  }

  async manageCustomerPostionList() {
    const payload = await this.setPayload();

    if (!payload) return;

    const selectedCustomerPostionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    let headers = new HttpHeaders();
    headers = headers.set("X-PositionId", this.authService.user.xPositionID);
    try {
      await this.uiService.displayLoader({}, true);
      if (selectedCustomerPostionList.indskr_customerpositionlistid) {
        await this.updateCustomerPositionList(payload, selectedCustomerPostionList.indskr_customerpositionlistid, headers);
      } else {
        await this.saveCustomerPositionList(payload, headers);
      }
      await this.uiService.dismissLoader();
    } catch (error) {
      console.error("failed to save customer position list: ", error);
      await this.uiService.dismissLoader();
      throw error;
    }
  }

  async saveCustomerPositionList(payload, headers) {
    const selectedCustomerPostionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.territoryManagement.CREATE_CUSTOMER_POSITION_LIST;
    let response: any = await this.http.post(url, payload, { headers }).toPromise();

    response.positionListTargets.forEach(element => {
      const index = selectedCustomerPostionList.positionListTargets.findIndex((positionListTarget) => positionListTarget.contact.id === element.contactId);
      selectedCustomerPostionList.positionListTargets[index].positionListTargetId = element.positionListTargetId;
    });
    payload['indskr_customerpositionlistid'] = response.customerPositionListId;
    selectedCustomerPostionList.indskr_customerpositionlistid = response.customerPositionListId
    this.territoryManagementService.selectedCustomerPostionListSubject.next(selectedCustomerPostionList);
    this.territoryManagementService.rawCustomerPositionList = _.cloneDeep(selectedCustomerPostionList);
    selectedCustomerPostionList.listPeriod['startDateFormatted'] = this.datePipe.transform(new Date(selectedCustomerPostionList.listPeriod.indskr_liststartdate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    selectedCustomerPostionList.listPeriod['endDateFormatted'] = this.datePipe.transform(new Date(selectedCustomerPostionList.listPeriod.indskr_listenddate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);

    this.territoryManagementService.addCustomerPositionList(selectedCustomerPostionList);
  }

  async updateCustomerPositionList(payload, customerPositionListId, headers) {
    const selectedCustomerPostionList = _.cloneDeep(this.territoryManagementService.selectedCustomerPostionListSubject.getValue());
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.territoryManagement.UPDATE_CUSTOMER_POSITION_LIST.replace('{customerPostionListId}', customerPositionListId);
    const response: any = await this.http.patch(url, payload, { headers }).toPromise();
    response.positionListTargets.forEach(element => {
      const index = selectedCustomerPostionList.positionListTargets.findIndex((contact) => contact.contact.id === element.contactId);

      if (element.deleted) {
        selectedCustomerPostionList.positionListTargets.splice(index, 1);
      } else if (element.accounts.some(account => account.deleted)) {
        let accountTobeRemoved = element.accounts.filter((account) => account.deleted).map((account) => account.accountId);
        selectedCustomerPostionList.positionListTargets[index].contact.accounts = selectedCustomerPostionList.positionListTargets[index].contact.accounts.filter((account) => !accountTobeRemoved.includes(account.id))
      }

      selectedCustomerPostionList.positionListTargets[index].positionListTargetId = element.positionListTargetId;

    });
    this.territoryManagementService.selectedCustomerPostionListSubject.next(selectedCustomerPostionList);
    this.territoryManagementService.rawCustomerPositionList = _.cloneDeep(selectedCustomerPostionList);
    return;
  }

  async scrapCustomerPositionList() {
    const selectedCustomerPostionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.territoryManagement.DELETE_CUSTOMER_POSITION_LIST.replace('{customerPostionListId}', selectedCustomerPostionList.indskr_customerpositionlistid);
    await this.http.delete(url).toPromise();
    let customerPositionLists = this.territoryManagementService.customerPositionList.getValue();
    customerPositionLists = customerPositionLists.filter((item) => {
      return item.indskr_customerpositionlistid != selectedCustomerPostionList.indskr_customerpositionlistid
    });
    this.territoryManagementService.customerPositionList.next(customerPositionLists);
    return;
  }

  async sendForApproval() {
    await this.uiService.displayLoader();
    const selectedCustomerPostionList: CustomerPositionList = this.territoryManagementService.selectedCustomerPostionListSubject.getValue();
    const payload = {
      "statecode": 1,
      "statuscode": StatusCode.FOR_REVIEW
    }
    let headers = new HttpHeaders();
    headers = headers.set("X-PositionId", this.authService.user.xPositionID);
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.territoryManagement.UPDATE_CUSTOMER_POSITION_LIST.replace('{customerPostionListId}', selectedCustomerPostionList.indskr_customerpositionlistid);
    const response: any = await this.http.patch(url, payload, { headers }).toPromise();
    selectedCustomerPostionList.statecode = payload.statecode;
    selectedCustomerPostionList.statuscode = payload.statuscode;
    this.territoryManagementService.selectedCustomerPostionListSubject.next(selectedCustomerPostionList);
    await this.uiService.dismissLoader();
  }

  async fetchContactsForConfiguredDisplay(forceFullSync: boolean = false, loadFromDBOnly = false) {
    if (loadFromDBOnly) {
      return;
    }
    if (!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) return;
    this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig = _.cloneDeep(this.searchConfigService.contactConfiguredSearchIndexConfig);
    // if (!this.territoryManagementService.currentListPeriod || !this.territoryManagementService.currentListPeriod.indskr_customerlistperiodid || this.device.isOffline) return;
    // ! need change
    if (!this.territoryManagementService.currentListPeriod || this.territoryManagementService.currentListPeriodCustomerList ? !this.territoryManagementService.currentListPeriod?.indskr_customerlistperiodid : !this.territoryManagementService.currentListPeriod?.indskr_nextlistperiod?.indskr_customerlistperiodid || this.device.isOffline) return;
  
   
    if (!forceFullSync && !_.isEmpty(this.territoryManagementService.contactsForNextPeriod)) return;
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!contactForm) {
      contactForm = new DynamicForm(DEFAULT_CONTACT_DISPLAY_FORM['value'][0]);
      formType = DynamicFormType.DEFAULTFORM;
    }

    let formDef = contactForm;
    let parentEntityFetchXml = fetchQueries.territoryManagementLists.fetchContactsByConfiguredFields.replace('{positionID}', this.authService.user.xPositionID);

    let parentEntityAttributesStr = [...(function* () {
      for (const attr of RequiredContactAttributes) {
        yield attr;
      }
    }).call(this)].reduce((p, x) => `${p}<attribute name="${x}"/>`, '');

    let linkEntityAttributesArray = [];
    formDef.metadata.forEach(tab => {
      tab.controls.forEach(control => {
        if (!control.dataType && control.subgrid) {
          linkEntityAttributesArray.push(control);
        }
      })
    })
    formDef.metadata.forEach(tab => {
      tab.controls.forEach(control => {
        if (control.dataType) {
          if (control.attributeName) {
            parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
          }
        }
      });
    });
    parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
    parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');
    // parentEntityFetchXml = parentEntityFetchXml.replace('{customerListPeriodId}', this.territoryManagementService.currentListPeriod.indskr_customerlistperiodid);
    // ! need change
    parentEntityFetchXml = parentEntityFetchXml.replace('{customerListPeriodId}',this.territoryManagementService.currentListPeriodCustomerList ? this.territoryManagementService.currentListPeriod.indskr_customerlistperiodid : this.territoryManagementService.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid);
// 
    parentEntityFetchXml = parentEntityFetchXml.replace(/\s+/g, ' ');
    this.territoryManagementService.contactsForNextPeriod = [];
    this._defaultLinkedEntityMappingData = [];
    await this.dynamics.executeFetchQuery('indskr_customerpositionlists', parentEntityFetchXml).then(async (res) => {
      if (res) {
        res = res.map((item) => {
          let newItem = _.mapKeys(item, function (value, key) {
            if (key == 'contactId') return 'contactid';
            if (key.includes('con.')) return key.replace('con.', '');
            return key;
          });
          return newItem;
        });
        // ! need change
        let listPeriodId = this.territoryManagementService.currentListPeriodCustomerList ? this.territoryManagementService.currentListPeriod.indskr_customerlistperiodid : this.territoryManagementService.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid 
        // await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, formType, this.territoryManagementService.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid);
        // await this.fetchContactsDefaultLinkEntityData(linkEntityAttributesArray, this.territoryManagementService.currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid);
        await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, formType,listPeriodId);
        await this.fetchContactsDefaultLinkEntityData(linkEntityAttributesArray, listPeriodId);
        this.mapDFContact(res);
        this._defaultLinkedEntityDataMappingServiceWorker.postMessage({ type: 'mapDefaultLinkedEntityDataToContact', rawList: this._defaultLinkedEntityMappingData });
      }
    }).catch(err => {
      console.error("Failed to fetch myContacts configured fields: ", err);
    })

  }

  private _initDefaultLinkedEntityDataMappingServiceWorker() {
    this._defaultLinkedEntityDataMappingServiceWorker = new Worker('./assets/workers/contact-linked-entity-mapping-worker.js');
    this._defaultLinkedEntityDataMappingServiceWorker.onmessage = (event) => {
      this.ngZone.run(() => {
        if (event && event.data && Array.isArray(event.data) && event.data.length != 0) {
          event.data.forEach(item => {
            if (item.hasOwnProperty('contactid')) {
              let idx = this.territoryManagementService.contactsForNextPeriod.findIndex(con => con.ID == item['contactid']);
              if (idx >= 0) {
                if (item.hasOwnProperty('emailAddressList')) {
                  this.territoryManagementService.contactsForNextPeriod[idx].emailAddressList = item['emailAddressList'];
                }
                if (item.hasOwnProperty('addressesList')) {
                  this.territoryManagementService.contactsForNextPeriod[idx].addressesList = item['addressesList'];
                }
                if (item.hasOwnProperty('accountRelationships')) {
                  this.territoryManagementService.contactsForNextPeriod[idx].accountRelationships = item['accountRelationships'];
                }
              }
            }
          })
        }
      })
    };
  }

  public mapDFContact(raw) {
    let dataTomMap = raw;
    dataTomMap = _.uniqBy(dataTomMap, 'contactid');
    // dataTomMap.forEach(el => {
    for (let el of dataTomMap) {
      if (el && el.hasOwnProperty('contactid')) {
        let tempCon = new Contact(el);
        let idx = this.territoryManagementService.contactsForNextPeriod.findIndex(a => a == el.contactid);
        if (idx >= 0) {
          tempCon.accountRelationships = this.territoryManagementService.contactsForNextPeriod[idx].accountRelationships;
          tempCon.addressesList = this.territoryManagementService.contactsForNextPeriod[idx].addressesList;
          tempCon.emailAddressList = this.territoryManagementService.contactsForNextPeriod[idx].emailAddressList;
          tempCon.activitesByContact = this.territoryManagementService.contactsForNextPeriod[idx].activitesByContact;
          tempCon.activitesTimeline = this.territoryManagementService.contactsForNextPeriod[idx].activitesTimeline;
          tempCon.productSegmentations = this.territoryManagementService.contactsForNextPeriod[idx].productSegmentations;
          this.territoryManagementService.contactsForNextPeriod[idx] = tempCon;
        } else {
          this.territoryManagementService.contactsForNextPeriod.push(tempCon);
        }
        let contactDetailEntries = _.entries(el);
        this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.forEach(config => {
          let suffix: string = '';
          let prefix: string = '';
          if (config.controlDataType) {
            if (config.controlDataType == ControlDataType.PicklistType || config.controlDataType == ControlDataType.MultiSelectPicklistType
              || config.controlDataType == ControlDataType.BooleanType
              || config.controlDataType == ControlDataType.StatusType || config.controlDataType == ControlDataType.StateType) {
              suffix = '@OData.Community.Display.V1.FormattedValue'
            } else if (config.controlDataType == ControlDataType.LookupType) {
              if (config.isMultilingualLookup) {
                suffix = '_value';
                prefix = '';
              } else {
                suffix = '_value@OData.Community.Display.V1.FormattedValue'
                prefix = '_';
              }
            }
          }
          let idx = contactDetailEntries.findIndex(entry => entry[0] == prefix + config.categoryRelativePath + suffix);
          if (idx >= 0) {
            if (!config.values.find(a => a == String(contactDetailEntries[idx][1]))) {
              config.values.push(String(contactDetailEntries[idx][1]));
              let mappingValue = {
                actualValue: el[config.categoryRelativePath],
                formattedValue: String(contactDetailEntries[idx][1]),
              };
              if (config.controlDataType == ControlDataType.LookupType) {
                mappingValue = {
                  actualValue: el['_' + config.categoryRelativePath + '_value'],
                  formattedValue: String(contactDetailEntries[idx][1]),
                };
              }
              config.mappingValues.push(mappingValue);
            }
          }
        });
      }
    }
  }

  private async fetchContactsDefaultLinkEntityData(linkedEntityFromForm: any[], customerListPeriodId: string) {
    return Promise.all(FETCH_CONTACTS_LINK_ENTITES.map(async (linkEntity) => {
      if (!linkEntity.isFetch || linkedEntityFromForm.find(l => l.subgrid.referencingEntity == linkEntity.entityName)) return;
      if (linkEntity.featureAction && !this.authService.hasFeatureAction(linkEntity.featureAction)) return;
      let fetchXML = fetchQueries.territoryManagementLists.fetchContactsByConfiguredFields.replace('{positionID}', this.authService.user.xPositionID).replace('{customerListPeriodId}', customerListPeriodId);
      let linkeEntity = linkEntity.fetchXML.substring(linkEntity.fetchXML.indexOf('<link-entity'), linkEntity.fetchXML.indexOf('</entity>'));
      fetchXML = fetchXML.replace('{linkEntityPlaceholder}', linkeEntity);
      fetchXML = fetchXML.replace('{parentLevelAttributes}', '');
      fetchXML = fetchXML.replace('{DeltaSyncFilter}', '');
      fetchXML = fetchXML.replace(/\s+/g, ' ');
      await this.dynamics.executeFetchQuery("indskr_customerpositionlists", fetchXML).then(async (res) => {
        if (res) {
          res = res.map((item) => {
            let newItem = _.mapKeys(item, function (value, key) {
              if (key == 'contactId') return 'contactid';
              if (key.includes('con.')) return key.replace('con.', '');
              return key;
            });
            return newItem;
          });
          // Add to default Linked entity data for mapping to contact object
          if (linkEntity.entityName == 'indskr_email_address' || linkEntity.entityName == 'indskr_indskr_customeraddress_v2' || linkEntity.entityName == 'indskr_accountcontactaffiliation') {
            this._defaultLinkedEntityMappingData.push(...res);
          }
        }
      },
        (err) => {
          console.error(err);
        })

    }));
  }

  private getAggregatedCategoryName(linkEntity: Control): string {
    let categoryName: string = '';
    if (linkEntity) {
      if (linkEntity.subgrid?.referencingEntity === CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY) {
        categoryName = 'CONTACT_AFFILIATIONS';
      }

      categoryName = this.translate.instant(categoryName);
    }
    return categoryName;
  }

  private async fetchContactsLinkedEntityData(linkedEntityDefArray: Control[], dynamicFormType: DynamicFormType, customerListPeriodId: string) {
    return Promise.all(linkedEntityDefArray.map(async (linkEntity) => {
      let searchCategory: searchIndexDataModel;
      //#region fetchxml replace
      let fetchXML = fetchQueries.territoryManagementLists.fetchContactsByConfiguredFields.replace('{positionID}', this.authService.user.xPositionID);
      fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
      fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
      fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
      fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
      fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
      fetchXML = fetchXML.replace('{customFilterLevel2}', '');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
      fetchXML = fetchXML.replace('{parentLevelAttributes}', '');
      fetchXML = fetchXML.replace('{customerListPeriodId}', customerListPeriodId);

      //check if it linkentity is also a mandatory data
      let requiredLinkEntity = FETCH_CONTACTS_LINK_ENTITES.find(x => x.entityName == linkEntity.subgrid.referencingEntity)
      let shouldMerge = false;
      let requiredJSONQuery;
      if (requiredLinkEntity) {
        if (requiredLinkEntity.entityFetchXML) {
          shouldMerge = true;
          XML2JS.parseString(requiredLinkEntity.entityFetchXML, (err, data) => {
            requiredJSONQuery = data;
          })
        }
      }

      //generate attributes list and next level link-entities
      let queryString = linkEntity.subgrid.subgridQuery;
      let JSONQuery;
      let linkEntityAttributesStr = '';
      XML2JS.parseString(queryString, (err, data) => {
        JSONQuery = data;
      })

      JSONQuery.fetch.entity[0].attribute.forEach(attr => {
        linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
      });

      let numOfLinkEntity = 0; //keep track the number of link entity. Needed for building global search fetchxml
      //Do it for the required data
      if (shouldMerge) {
        requiredJSONQuery.fetch.entity[0].attribute.forEach(attr => {
          linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
        });
        if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
          JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity++;
            try {
              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

              linEnt.attribute.forEach(linEntAttr => {
                linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
              });

              if (Array.isArray(requiredJSONQuery.fetch.entity[0]['link-entity'])) {
                let reqLE = requiredJSONQuery.fetch.entity[0]['link-entity'].find(x => x.$.name == linEnt.$.name);
                if (reqLE) {
                  reqLE.attribute.forEach(reqLinEntAttr => {
                    if (!linEnt.attribute.some(x => x.$.name == reqLinEntAttr.$.name)) {
                      linkEntityAttributesStr += '<attribute name="' + reqLinEntAttr.$.name + '"/>'
                    }
                  });
                }
              }
              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.error(error);
            }
          });
        }

        //now iterate through the filter and append the sort criteria defined in the
        linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
        //now iterate through the filter and append the filter criteria defined in the
        linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);


        if (requiredJSONQuery.fetch.entity[0]['link-entity'] && requiredJSONQuery.fetch.entity[0]['link-entity'].length) {
          requiredJSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity++;
            try {
              linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(linEnt, linkEntityAttributesStr, true);
              if (Array.isArray(JSONQuery.fetch.entity[0]['link-entity']) && JSONQuery.fetch.entity[0]['link-entity'].some(x => x.$.name == linEnt.$.name)) return;

              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

              if (linEnt.attribute) {
                linEnt.attribute.forEach(linEntAttr => {
                  linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                });
              }

              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.error(error);
            }
          });
        }
      } else {
        // if no reqired data
        if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
          JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity++;
            try {
              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
              if (linEnt.attribute) {
                linEnt.attribute.forEach(linEntAttr => {
                  linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                });
              }

              linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(linEnt, linkEntityAttributesStr, true);

              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }

          });
        }
        //now iterate through the filter and append the sort criteria defined in the
        linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
        //now iterate through the filter and append the filter criteria defined in the
        linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);
      }

      let globalSearchFetch = fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder;
      globalSearchFetch = globalSearchFetch.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
      globalSearchFetch = globalSearchFetch.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
      globalSearchFetch = globalSearchFetch.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
      globalSearchFetch = globalSearchFetch.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
      globalSearchFetch = globalSearchFetch.replace('{customFilterLevel2}', '');
      globalSearchFetch = globalSearchFetch.replace('{deltasyncFilterLevel2}', '');
      globalSearchFetch = globalSearchFetch.replace('{linkEntityAttributes}', linkEntityAttributesStr);

      if (linkEntity.isSearchable && linkEntity.searchAttributeName) {
        const refEntityNamesToBeAggregated = [
          CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
        ];
        let idx = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.findIndex(conf => conf.categoryRelativePath == linkEntity.searchAttributeName);
        if (idx >= 0) {
          let tempConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig[idx];
          tempConfig.linkEntityFetchXML = globalSearchFetch;
          searchCategory = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig[idx];
        } else {
          const isThisToBeAggregated = refEntityNamesToBeAggregated.includes(linkEntity.subgrid.referencingEntity);
          let categoryName: string = isThisToBeAggregated
            ? this.getAggregatedCategoryName(linkEntity)
            : this.dynamicFormsService.getSearchConfigDisplayText(linkEntity.displayNames, dynamicFormType, linkEntity.isCustom);
        }
      }

      linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
      linkEntityAttributesStr = linkEntityAttributesStr.replace('<condition attribute=\"statecode\" operator=\"eq\" value=\"0\"/>', '');
      fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);

      fetchXML = fetchXML.replace(/\s+/g, ' ');
      await this.dynamics.executeFetchQuery('indskr_customerpositionlists', fetchXML).then(async (res) => {
        if (res) {
          res = res.map((item) => {
            let newItem = _.mapKeys(item, function (value, key) {
              if (key == 'contactId') return 'contactid';
              if (key.includes('con.')) return key.replace('con.', '');
              return key;
            });
            return newItem;
          });
          res = res.filter((data) => data['statecode'] == 0 || data[linkEntity.subgrid.referencingEntity + '.statecode'] == 0);
          this.mapLinkEntityToSearch(linkEntity, searchCategory, res);
        }
      },
        (err) => {
          console.error(err);
        })
    }))
  }

  private mapLinkEntityToSearch(linkEntity, searchCategory, linkEntityData) {
    // Add to default Linked entity data for mapping to contact object
    if (linkEntity.subgrid.referencingEntity == 'indskr_email_address' || linkEntity.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2' || linkEntity.subgrid.referencingEntity == 'indskr_accountcontactaffiliation') {
      this._defaultLinkedEntityMappingData.push(...linkEntityData);
    }
    if (searchCategory) {
      this._advancedSearchConfigServiceWorker.postMessage({ type: 'mapLinkEntitySearchIndex', rawList: linkEntityData, configIndex: searchCategory });
    }
    if (linkEntity.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2') {
      this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.forEach(defaultConfig => {
        let currentConfig;
        let idx = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.findIndex(conf => conf.categoryRelativePath == defaultConfig.categoryRelativePath);
        if (idx >= 0) {
          currentConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig[idx];
        } else {
          const clonedDefaultConfig: searchIndexDataModel = JSON.parse(JSON.stringify(defaultConfig));
          if (clonedDefaultConfig.translationKey) {
            clonedDefaultConfig.categoryDisplayName = this.translate.instant(clonedDefaultConfig.translationKey);
          }
          else {
            clonedDefaultConfig.categoryDisplayName = clonedDefaultConfig.categoryName;
          }

          // Custom assignment for Address
          clonedDefaultConfig.fromAttribute = 'indskr_addressid';
          clonedDefaultConfig.toAttribute = 'indskr_address';
          currentConfig = clonedDefaultConfig;
          this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.push(clonedDefaultConfig);
        }
        //Filter out inactive address from customer
        linkEntityData = linkEntityData.filter((data) => data['indskr_indskr_customeraddress_v2.statecode'] == 0);
        this._advancedSearchConfigServiceWorker.postMessage({ type: 'mapLinkEntitySearchIndex', rawList: linkEntityData, configIndex: currentConfig });
      });
    }
  }

  private _initAdvancedSearchServiceWorker() {
    this._advancedSearchConfigServiceWorker = new Worker('./assets/workers/advanced-search-worker.js');
    this._advancedSearchConfigServiceWorker.onmessage = (event) => {
      this.ngZone.run(() => {
        let index = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.findIndex(o => o.categoryRelativePath == event.data.categoryRelativePath);
        if (index > -1) {
          this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig[index] = event.data
        }
        else this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.push(event.data);
      })
    };
  }

  fetchCustomerPositionListNotifications(loadFromDBOnly = false){
    if (loadFromDBOnly) {
      return;
    }
    if (!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) return;
    this.fetchForReviewCustomerPositions();
    this.fetchApprovedCustomerPositions();
    this.fetchRejectedCustomerPositions();
  }

  async fetchForReviewCustomerPositions() {
    let parentEntityFetchXml = fetchQueries.territoryManagementLists.fetchForReviewCustomerPositionList;
    const customerPositionSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerPositionList,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_REVIEW_CUSTOMER_POSITION_LIST);
    if (!syncState || !syncState.lastUpdatedTime) {
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.diskService.updateSyncState(syncState);
      return;
    }
    let lastSync = moment(parseInt(syncState.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    parentEntityFetchXml = parentEntityFetchXml.replace('{lastUpdatedTime}', lastSync);
    return await this.dynamics.executeFetchQuery('indskr_customerpositionlists', parentEntityFetchXml).then(async (res) => {
      console.log(res);
      // let displayName = this.translate.instant('MANAGE_LIST');
      let customerPositionLists: CustomerPositionList[] = [];
      let formattedRes = this.formatCustomerPositionList(res, customerPositionLists);
      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.customerPositionNotificationModel = {
          type: NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED,
          name: this.translate.instant("NEW_MANAGE_LIST_FOR_REVIEW_NOTIFICATION", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED + formattedRes[0].indskr_customerpositionlistid,
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/manage_list.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.customerPositionNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }
    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(customerPositionSyncInfo, 'indskr_customerpositionlists', err);
    })

  }

  async fetchApprovedCustomerPositions() {
    let parentEntityFetchXml = fetchQueries.territoryManagementLists.fetchApprovedCustomerPositionList;
    const customerPositionSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerPositionList,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_APPROVED_CUSTOMER_POSITION_LIST);
    if (!syncState || !syncState.lastUpdatedTime) {
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.diskService.updateSyncState(syncState);
      return;
    }
    let lastSync = moment(parseInt(syncState.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    parentEntityFetchXml = parentEntityFetchXml.replace('{lastUpdatedTime}', lastSync);
    return await this.dynamics.executeFetchQuery('indskr_customerpositionlists', parentEntityFetchXml).then(async (res) => {
      console.log(res);
      // let displayName = this.translate.instant('MANAGE_LIST');
      let customerPositionLists: CustomerPositionList[] = [];
      let formattedRes = this.formatCustomerPositionList(res, customerPositionLists);
      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.customerPositionNotificationModel = {
          type: NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED,
          name: this.translate.instant("CUSTOMER_POSITION_LIST_APPROVED", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED + formattedRes[0].indskr_customerpositionlistid,
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/manage_list.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.customerPositionNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }
    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(customerPositionSyncInfo, 'indskr_customerpositionlists', err);
    })

  }

  async fetchRejectedCustomerPositions() {
    let parentEntityFetchXml = fetchQueries.territoryManagementLists.fetchRejectedCustomerPositionList;
    const customerPositionSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerPositionList,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_REJECTED_CUSTOMER_POSITION_LIST);
    if (!syncState || !syncState.lastUpdatedTime) {
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.diskService.updateSyncState(syncState);
      return;
    }
    let lastSync = moment(parseInt(syncState.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionId}', this.authService.user.xPositionID);
    parentEntityFetchXml = parentEntityFetchXml.replace('{lastUpdatedTime}', lastSync);
    return await this.dynamics.executeFetchQuery('indskr_customerpositionlists', parentEntityFetchXml).then(async (res) => {
      console.log(res);
      // let displayName = this.translate.instant('MANAGE_LIST');
      let customerPositionLists: CustomerPositionList[] = [];
      let formattedRes = this.formatCustomerPositionList(res, customerPositionLists);
      let showCount = formattedRes.length == 1 ? '' : formattedRes.length;
      if (formattedRes.length > 0) {
        this.customerPositionNotificationModel = {
          type: NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED,
          name: this.translate.instant("CUSTOMER_POSITION_LIST_REJECTED", { count: showCount }),
          DateTime: Date.now(),
          id: NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED + formattedRes[0].indskr_customerpositionlistid,
          data: {
            data: formattedRes
          },
          icon: 'assets/imgs/manage_list.svg',
          isRed: false,
          params: { count: showCount }
        }
        this.myAssistantService.saveNotificationToDisk(this.customerPositionNotificationModel);
        const newLastUpdatedTime = new Date().getTime().toString();
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.diskService.updateSyncState(syncState);
        return formattedRes;
      }

    }).catch(err => {
      console.log(err);
      this.deltaService.addSyncErrorToEntitySyncInfo(customerPositionSyncInfo, 'indskr_customerpositionlists', err);
    })

  }

  public async fetchTargetContactIds(loadFromDBOnly) {
    console.warn('fetchTargetContactIds');

    if(!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT) && !this.territoryManagementService.currentListPeriod)
      return;

    if(loadFromDBOnly)
      return;
      
    let currentCustomerListPeriodId = this.territoryManagementService.currentListPeriod ? this.territoryManagementService.currentListPeriod.indskr_customerlistperiodid : '';
    let contactsBasedOnPositionId = await this.contactDataService.getTargetContactsBasedOnCurrentCustomerListPeriod(this.authService.user.positionID, currentCustomerListPeriodId);

    // reset this array
    this.dynamicFormsService.targetContactIds = [];

    if (!_.isEmpty(contactsBasedOnPositionId)) {
      contactsBasedOnPositionId = contactsBasedOnPositionId.map((contact)=> {
        contact['ID'] = contact['contactid'];
        return contact;
      }); 

      this.dynamicFormsService.targetContactIds = contactsBasedOnPositionId;
    }
  } 

}
