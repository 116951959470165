export class ApprovalReasonConfig {
  public indskr_approvalreasonlistid: string;
  public name: string;
  public approvalItem: string;
  public isMandatory: boolean;
  public format: number;
  public type: number;

  constructor(raw) {
    this.indskr_approvalreasonlistid = raw['ac.indskr_approvalreasonlistid'];
    this.name = raw['ac.indskr_name'];
    this.approvalItem = raw['indskr_approvalitem'];
    this.isMandatory = raw['indskr_mandatory'];
    this.format = raw['indskr_format'];
    this.type = raw['indskr_type'];
  }
}

export enum ApprovalReasonsConfigApprovalItem {
  GPS_CHECK_IN_DETAILS = 548910035
}

export enum ApprovalReasonsConfigFormat {
  FREE_TEXT = 548910000,
  CONFIGURABLE_LIST = 548910001
}

export enum ApprovalReasonsConfigType {
  APPROVE_REASON = 548910000,
  NON_APPROVE_REASON = 548910001
}