// import { EventNotesComponent } from './../event-note/event-notes';
import { ProcedureTrackerDetailsComponent } from './../surgery-order/procedure-tracker-details/procedure-tracker-details';
import { ProcedureTrackerActivityDataService } from './../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { Component, ViewChild, Input } from '@angular/core';
import { NavController, IonNav } from '@ionic/angular';
import { NewSampleActivityComponent } from '../activity/new-sample-activity/new-sample-activity';
import { NavParams } from '@ionic/angular';
import { Activity, ActivityType } from '../../classes/activity/activity.class';
import { EmailActivityDetailComponent } from '../activity/email-activity-detail/email-activity-detail';
import { EventRegistrationComponent } from '../event-registration/event-registrations';
import { EventsService } from '@omni/services/events/events.service';
import { EmailService } from '@omni/services/email-templates/email.service';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { CalendarInviteComponent } from '../calendar-invite/calendar-invite';
import { SurgeryOrderDetailsComponent } from '../surgery-order/surgery-order-details/surgery-order-details';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { FollowUpActionDetailComponent } from '../activity/follow-up-action-detail/follow-up-action-detail';
import { FollowUpActivityDataService } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';

/**
 * Generated class for the InMeetingAllocationComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'in-meeting-allocation',
  templateUrl: 'in-meeting-allocation.html',
  styleUrls:['in-meeting-allocation.scss']
})
export class InMeetingAllocationComponent {

  public workflowStage: inMeetingWorkflowStages;
  @ViewChild("allocationForm", {static:true}) navCtrl: IonNav;
  public isViewCentered: boolean;
  @Input() type: ActivityType | 'Calendar Invite' | 'EventNote';
  @Input() viewData: any;
  @Input() from: string;
  @Input() activity: Activity;
  constructor(private readonly events: EventsService,
    private readonly emailService: EmailService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
    private followupActivityDataService: FollowUpActivityDataService,
    private activityService: ActivityService

  ) {
    this.workflowStage = 0;

    // when a message activity create from the Meeting, for the personalisation it could't go back so have to implement this logic.
    if (!(
        this.type === 'Calendar Invite'
        || this.type === 'EventNote'
    )) {
    this.events.subscribe('inmeeting-activity-back', (args) => {
      if (this.type === ActivityType.Email) {
        if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
          this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
            const email = args['email'];
              this.navCtrl.pop({ progressAnimation: false }).then(() => {
                if (email) {
                  this.emailService.setCurrentEmail(email);
                }
              });
        }
      }
    });
    }
  }
  ngOnInit() {

    switch (this.type) {
      case ActivityType.Sample:
          this.navCtrl.setRoot(NewSampleActivityComponent, { modalView: {} });
        break;
      case ActivityType.Email:
          this.navCtrl.setRoot(EmailActivityDetailComponent, { modalView: {}});
        break;
      case ActivityType.EventRegistration:
        this.navCtrl.setRoot(EventRegistrationComponent)
        break;
      case 'Calendar Invite':
        this.navCtrl.setRoot(CalendarInviteComponent, { modalView: {} });
        break;
      case ActivityType.SurgeryOrder:
        this.navCtrl.setRoot(SurgeryOrderDetailsComponent, { from : 'InMeetingProcedure', activity: this.surgeryOrderDataService.inMeetingSurgeryOrderActivity});
        break;
      case ActivityType.ProcedureTracker:
        this.navCtrl.setRoot(ProcedureTrackerDetailsComponent, { from : 'InMeetingProcedure', activity: this.procedureTrackerActivityDataService.inMeetingProcedureTrackerActivity});
        break;
      case ActivityType.FollowUp:
        this.navCtrl.setRoot(FollowUpActionDetailComponent, { from : this.from || 'InMeetingFollowup', activity: this.activity || this.followupActivityDataService.inMeetingFollowupActionActivity});
        break;
      case 'EventNote':
        // this.navCtrl.setRoot(EventNotesComponent, { eventParticipant: this.viewData?.eventParticipant, contact: this.viewData?.contact });
        break;
      default:
        break;
    }


    this.isViewCentered = true;
  }

  ngOnDestroy() {
    this.activityService.activitySource = null;
    if (!(
      this.type === 'Calendar Invite'
      || this.type === 'EventNote'
  )) {
    this.events.unsubscribe('inmeeting-activity-back');
    }
  }

}
export enum inMeetingWorkflowStages {
  DETAILS_PAGE,
  PRODUCTS_PAGE,
  QUANTITIES_PAGE,
  CONTACT_SELECTION,
  CAPTURE_SIGNATURE
}
