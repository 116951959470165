import { Component } from '@angular/core';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';

import { Events } from '@omni/events';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService, DEVICE_ORIENTATION } from '../../../services/device/device.service';
import { TimeOffDataService } from '../../../data-services/time-off/time-off.data.service'
import { TimeOffService } from '../../../services/time-off/time-off.service'
import { TimeOffActivity } from '../../../classes/activity/timeoff.class';
import { TimeOffResponse } from "../../../classes/activity/timeoff.response.class";
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TimeOffUtilityService } from "../../../services/time-off/time-off-utility.service";
import { ActivityType } from '../../../classes/activity/activity.class';
import { Subscription } from 'rxjs';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 * Generated class for the TimeOffComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'time-off-home[base-page]',
  templateUrl: 'time-off-home.html',
  styleUrls:['time-off-home.scss']
})

export class TimeOffComponent {

  text: string;
  public checkStatus: any;
  public selectedTot: TimeOffActivity;
  public totResponse: TimeOffResponse;
  public forceReload: boolean = false;

  private _screenOrientation: Subscription;
  public isPortrait: boolean = false;

  constructor(
    public uiService: UIService,
    public device: DeviceService,
    private timeOffDataService: TimeOffDataService,
    public timeOffService: TimeOffService,
    private navService: NavigationService,
    private activityService: ActivityService,
    private footerService: FooterService,
    private authenticationService: AuthenticationService,
    private timeOffUtilityService: TimeOffUtilityService,
    private events: Events,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {

    //Some Activity Has been selected and requester has been registered for callback
    if ((this.activityService.selectedActivity != null || this.activityService.selectedActivity != undefined)) {
      if (this.timeOffService.getMobileTracker() != '') {
        console.log(this.activityService.selectedActivity);
        if ((this.activityService.selectedActivity.type === ActivityType.TimeOff || this.activityService.selectedActivity.type === ActivityType.TimeOffRequest)) {
          this.onTimeOffSelection(<TimeOffActivity>this.activityService.selectedActivity);
        }
      }
    }

    this._screenOrientation = this.device.screenOrientation.subscribe((orientation: number) => {

      if ((orientation === DEVICE_ORIENTATION["landscape-primary"] || orientation === DEVICE_ORIENTATION["landscape-secondary"])) {
        this.isPortrait = false;
      } else {
        this.isPortrait = true;
      }
    });

    this.isPortrait = this.device.isOrientationPortrait();
  }

  ngOnInit() {
    this.uiService.toolsActivityActive = true;
    this.timeOffService.selectedTot.subscribe(tot => {
      if (tot == null) {
        this.uiService.showNewActivity = true;
        this.uiService.activeView = "TimeOff";
        if (this.device.isMobileDevice) {
          this.timeOffService.totShowRightPane = false;
        }
      }
      this.selectedTot = tot;
    });

    if (this.device.isMobileDevice) {
      this.timeOffService.totShowRightPane = false;
    }

    this.events.subscribe('updateTimeOffRHSEmptyPage', (shouldShow)=>{
      this.uiService.updateNothingSelectedScreenMessageFor(shouldShow ? 1 : 0)
    })

  }

  ngOnDestroy() {
    this._screenOrientation.unsubscribe();

    this.timeOffService.totMode = 'myRequests';
    this.events.unsubscribe('updateTimeOffRHSEmptyPage')

  }

  /**
   * This is how i know if i have to enable the cancel and send buttons
   *
   * @memberof TimeOffComponent
   */
  onNewAppointment = async (event) => {
    console.log("received request for new TIme Off");
    await this.uiService.displayLoader();
    let offlineTimeOff = new TimeOffActivity({
      indskr_timeoffrequestid: "",
      statecode: 0,
      statuscode: 100000000,
      "statuscode@OData.Community.Display.V1.FormattedValue": "Open",
      "indskr_starttime@OData.Community.Display.V1.FormattedValue": this.timeOffDataService.getTimeStamp("start"),
      indskr_starttime: this.timeOffDataService.getTimeStamp("start"),
      _indskr_positionid_value: this.authenticationService.user.xPositionID,
      // "_indskr_positionid_value@OData.Community.Display.V1.FormattedValue": this.authenticationService.user.positionName,
      indskr_isalldayevent: true,
      "indskr_endtime@OData.Community.Display.V1.FormattedValue": this.timeOffDataService.getTimeStamp("end"),
      indskr_endtime: this.timeOffDataService.getTimeStamp("end"),
      indskr_reason: undefined, //(this.timeOffService.defaultTimeOffReason) ? this.timeOffService.defaultTimeOffReason.reasonCode : 100000001,
      "indskr_reason@OData.Community.Display.V1.FormattedValue":  undefined, //(this.timeOffService.defaultTimeOffReason) ? this.timeOffService.defaultTimeOffReason.reason : "Sick",
      //indskr_name: this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT) ? ("Time Off"+((this.timeOffService.defaultTimeOffReason) ? " - "+this.timeOffService.defaultTimeOffReason.reason : "")): "Time Off",
      indskr_name: "Time Off",
      _ownerid_value: this.authenticationService.user.systemUserID,
      "_ownerid_value@OData.Community.Display.V1.FormattedValue": "",
      indskr_local_timeoffrequestid: "offline_tot_" + new Date().getTime(),
    }, ActivityType.TimeOffRequest);
    //If device is offline
    if (this.device.isOffline) {
      this.timeOffService.saveMyTimeOff(offlineTimeOff, false).then(() => {
        this.uiService.dismissLoader();
      });
    }
    //If device is online
    else if (!this.device.isOffline) {
      this.timeOffDataService.createNewTimeOff(undefined, offlineTimeOff.offlineTimeOffRequestId).subscribe(success => {
        console.log(success);
        this.timeOffService.mapTOTResponse(success);
        this.timeOffService.display = true;
        this.timeOffService.saveMyTimeOff(offlineTimeOff, true, success).then(() => {
          this.uiService.dismissLoader();
        });
      },
        error => {
          //some logic to handle the error
          console.log("inform user the update has failed and hhe needs to sync the data for updating the keys");
          //store the data offline for sync
          this.timeOffService.saveMyTimeOff(offlineTimeOff, false).then(() => {
            this.uiService.dismissLoader();
          })
        });
    }
    this.forceReload = !this.forceReload;
    this.onTimeOffSelection(offlineTimeOff);
    this.footerService.initButtons(FooterViews.TimeOffDetails);
  };

  /**
   *
   *
   * @memberof TimeOffComponent
   */
  onCloseModal = (event) => {
    this.uiService.showNewActivity = false;
    this.uiService.toolsActivityActive = false;
    //this.activityService.selectedActivity = null;
    this.uiService.activeView = this.uiService.prevView;
    this.navService.popToRootWithPageTracking()
      .then(() => {
        if (this.activityService.selectedActivity?.ID === this.activityService.selectedActivityAtHome?.ID) {
          this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
        }
        this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
        this.uiService.showNewActivity = false;
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          this.footerService.initButtons(FooterViews.Activities);
        } else if (this.activityService.selectedActivity instanceof TimeOffActivity) {
          this.timeOffService.setSelectedTot(this.activityService.selectedActivity);
        } else if (this.activityService.selectedActivity instanceof EmailActivity) {
          // this.footerService.initButtons(FooterViews.EmailDetails);
          this.events.publish('update-email-footer');
        } else if (this.activityService.selectedActivity instanceof CaseActivity) {
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        } else {
          // Short call feature requires to bring the tab back to short call home
          if (this.faService.isShortCallLauncherEnabled) {
            this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
            this.uiService.setAgendaTab('shortCallHome');
          }
        }
        //update time off request and send for review
        if (this.timeOffService.isTotModified) this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
        this.events.publish("tools-back-clicked", PageName.TimeOffComponent);
      });
  }

  onTimeOffSelection(event: TimeOffActivity): void {
    console.log("received TO on home");
    console.log(event);
    //Invoking update service if the request being modified
    //update time off request and send for review
    if (this.timeOffService.isTotModified) this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
    this.timeOffService.setSelectedTot(event);
    this.footerService.initButtons(FooterViews.TimeOffDetails);
    //For DateTimePicker Component to function.
    this.activityService.selectedActivity = event;
    //this.timeOffService.setMobileTracker('timeOff');
    if (this.device.isMobileDevice) {
      // this.navService.pushWithPageTracking(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, { activityType: event });
      this.timeOffService.totShowRightPane = true;
    }
    this.uiService.showNewActivity = false;
    this.uiService.activeView = "TimeOffDetail";
  }

}
