import { ChangeDetectorRef, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import _, { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { Brand } from '../../../classes/brand/brand.class';
import { TherapeuticArea } from '../../../classes/case-intake/case-utility.class';
import {
  Action,
  CaptureData,
  CaptureDataFor,
  Category,
  CreateFrequency,
  CustomerSegmentation,
  FilterType,
  MarketScan,
  MarketScanAccount,
  MarketScanCompetitor,
  MarketScanContact,
  MarketScanCustomerSegment,
  MarketScanDetailsScreenColumnItemModel,
  MarketScanDetailsScreenMatrixModel,
  MarketScanProduct,
  MarketScanStatusCode,
  MarketScanTA,
  SubRowData
} from '../../../classes/market-scan/market-scan.class';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { SelectListData } from '../../../models/select-list-data-model';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { EventName, EventsService } from '../../../services/events/events.service';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { MarketScanService } from '../../../services/market-scan/market-scan.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { IndDropdownListComponent } from '../../shared/ind-dropdown-list/ind-dropdown-list';
import { SelectListComponent } from '../../shared/select-list/select-list';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { NothingSelectedView } from '../../../components/shared/nothing-selected-view/nothing-selected-view';
import { format } from 'date-fns';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { AlertService } from '../../../services/alert/alert.service';
import { MarketScanTableComponent } from '../market-scan-table/market-scan-table';
import { takeUntil } from 'rxjs/operators';
import { DeviceService } from '@omni/services/device/device.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { Paginator } from 'primeng/paginator';
import { DatePipe } from '@angular/common';
import { AccountOfflineService, AccountSelectedFor } from '@omni/services/account/account.offline.service';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { ActivityService } from '@omni/services/activity/activity.service';
import { IndDateTimeFormViewDataModel, IndDateTimeFormViewErrorMessage } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { MarketScanEditPageComponent } from '../market-scan-edit-page/market-scan-edit-page';
import { Contact } from '@omni/classes/contact/contact.class';
import { Account } from '@omni/classes/account/account.class';
import { TrackingEventNames, TrackService } from '@omni/services/logging/tracking.service';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';


@Component({
  selector: 'market-scan-details',
  templateUrl: 'market-scan-details.html',
  styleUrls: ['market-scan-details.scss']
})
export class MarketScanDetailsComponent implements OnDestroy {

  marketScanDate: string;
  public selectedMarketScan: MarketScan;
  private IcurrentMarketScanSubscription: Subscription;
  private allowedUpdateDuration: string = '';
  // rows = [];
  // columns = [];
  markScanDetailsPageTitle: IndPageTitleViewDataModel;
  //subrows: SubRowData[] = [];
  headerData: IndSectionHeaderViewDataModel = {
    id: 'market-scan-header',
    title: this.translate.instant('DETAILS_CAP'),
    controls: [
    ],
  };
  public tableHeaderData: IndSectionHeaderViewDataModel;
  formattedDate = '';
  private ngDestroy$ = new Subject<boolean>();
  private categorySelection: boolean = false;
  @ViewChild(MarketScanTableComponent) marketScanTable: MarketScanTableComponent;
  @ViewChild('paginator', { static: false }) paginator: Paginator;
  @ViewChild('content', { static: true }) content: IonContent;
  contentWidth: number;
  scrollWidth: number;
  // @HostListener('touchmove', ['$event'])
  // touchMove(eve) {
  //   this.marketScanTable.onPageScroll(event);
  // }
  public showPaginator: boolean = false
  public visibleRowsLength: number = 0;
  private lastScroll: number = 0;
  public backgroundUploadInProgress: boolean = false;
  private isClickedDate: boolean = false;
  private isClickedCategories: boolean = false;
  private isClickedTA: boolean = false;
  private isClickedProduct: boolean = false;
  private isClickedSegment: boolean = false;
  private isClickedCustomer: boolean = false;
  private isClickedAccount: boolean = false;
  private isClickedSelectView:boolean = false;
  private isClickedCompetitor:boolean = false;
  private isClickedProcrdure:boolean = false;
  public errorMessageField: IndDateTimeFormViewErrorMessage;
  public isWeeklyType: boolean = false;
  public isMarketScanMatrixViewAvailable:boolean = false;
  public matrixScanData:MarketScanDetailsScreenMatrixModel;
  public masterMatrixScanData:MarketScanDetailsScreenMatrixModel;

  isRecordCapturedAt = null;
  showJustAccounts = false;

  constructor(
    private navService: NavigationService,
    public translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private utilityService: GlobalUtilityService,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private contactService: ContactOfflineService,
    private events: EventsService,
    private marketScanService: MarketScanService,
    public _cd: ChangeDetectorRef,
    public footerService: FooterService,
    private authService: AuthenticationService,
    public uiService: UIService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    private device: DeviceService,
    private datePipe: DatePipe,
    private accountService: AccountOfflineService,
    private activityService: ActivityService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private readonly trackingService: TrackService,
    private readonly opportunityService: OpportunityManagementService,
  ) {
    this.events.observe(EventName.DROPDOWNLISTDISMISSDATA)
      .pipe(
        takeUntil(this.ngDestroy$)
      ).subscribe(async (data) => {
        if (data && data['isDone'] && !this.isReadOnlyView && !this.categorySelection) {
          let selectedCustSegms: MarketScanCustomerSegment[] = [];
          data['selectedItems'].forEach(selectedItem => {
            selectedCustSegms.push(new MarketScanCustomerSegment(selectedItem.title, selectedItem.id, Action.PATCH));
          });
          if (this.selectedMarketScan && !_.isEqual(this.marketScanService.sortListByFielName(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE), 'segmentName'), selectedCustSegms)) {
            //if (!_.isEmpty(this.selectedMarketScan.contacts.filter(c => c.action != Action.DELETE)))
              //&& !await this.showResetAlert(this.translate.instant('CUSTOMER_SEGMENT_SINGULAR_WITH_TEXT', { text: this.utilityService.globalCustomerText }))) return;
            await this.uiService.displayLoader();
            if (this.selectedMarketScan.pendingPushToDynamics || this.selectedMarketScan.indskr_marketscanid)
              this.selectedMarketScan.isModified = true;
            this.marketScanService.unMapReMapSegments(selectedCustSegms, this.selectedMarketScan);
            this.selectedMarketScan.customerSegmentations = _.uniqBy(selectedCustSegms, 'customerSegmentationId');
            await this.marketScanService.mapFilteredDataToSelectedMarketScan(this.selectedMarketScan, FilterType.CUSTOMER_SEGMENTATION);
            await this.uiService.dismissLoader();
          }
        }
      });

    this.events.observe('updateMandatoryField')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((field: PageName) => {
        if (field == PageName.AccountPageComponent) this.isClickedAccount = true
        else if (field == PageName.ContactPageComponent) this.isClickedCustomer = true;
      });
  }

  ngOnInit() {
    this.isWeeklyType = this.authService.user.marketScanCreateFrequency === CreateFrequency.WEEKLY;
    this.marketScanService.marketScanCopy = null;
    this.IcurrentMarketScanSubscription = this.marketScanService.currentMarketScanObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      if (value) {
        this.selectedMarketScan = value;
        const categories: Category[] = _.cloneDeep(this.marketScanService.categories);
        const category: Category = categories.find(c => c.indskr_assessmentcategoryid === this.selectedMarketScan.categoryId);
        this.isRecordCapturedAt = category?.indskr_recordcapturedat ?? null;
        this.showJustAccounts = this.isRecordCapturedAt != null && this.selectedMarketScan.accounts.length === 0;

        if (this.selectedMarketScan.indskr_date) {
          // this.formattedDate = format(new Date(parseInt(this.selectedMarketScan.indskr_date)), this.dateTimeFormatsService.dateToUpper);
          this.formattedDate = this.getFormattedAndLocalisedDate(new Date(parseInt(this.selectedMarketScan.indskr_date)));
          // this.allowedUpdateDuration = moment(new Date(parseInt(this.selectedMarketScan.indskr_date))).add(this.authService.user.marketScanUpdateDuration, 'days').format();
          this.allowedUpdateDuration = moment(new Date(parseInt(this.selectedMarketScan.indskr_date))).endOf('month').add(this.authService.user.marketScanUpdateDuration, 'days').format();
          this.marketScanDate = moment(parseInt(this.selectedMarketScan.indskr_date)).format();
        }
        this.initMarketScanDetailsPageTitle();
        this.footerService.initButtons(FooterViews.MARKET_SCAN_DETAILS);
        this.updateButtonStatus();
        // Init New View
        //this.constructTableData(value);
        this._cd.detectChanges();
      }
    });
    this.events.subscribe("marketScan:footerEvents", this.footerHandler);
    if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
      this.selectedMarketScan.isModified = false;
      this.marketScanService.marketScanCopy = cloneDeep(this.selectedMarketScan);
    }
    // this.device.screenOrientation.pipe(takeUntil(this.ngDestroy$))
    //   .subscribe((orientation: number) => {
    //     this.marketScanTable?.setColumnWidth();
    //     this.setWidthofContent();
    //     this._cd.detectChanges();
    //   });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.selectedMarketScan) {
        this.backgroundUploadInProgress = inProgress && this.selectedMarketScan.pendingPushToDynamics;
        this.updateButtonStatus();
        this._cd.detectChanges();
      }
    });
  }

  updateButtonStatus() {
    if (this.backgroundUploadInProgress) {
      this.markScanDetailsPageTitle.controls[1].isDisabled = true;
      this.markScanDetailsPageTitle.controls[0].isDisabled = true;
      this.footerService.disableButton(['updateMarketScan', 'saveMarketScan', 'editMarketScan', 'scrapMarketScan']);
      return;
    }
    let disableFooterButtons = [];
    let enableFooterButtons = [];
    if (this.selectedMarketScan.statuscode === MarketScanStatusCode.Expired) {
      //If it is saved in dynamics, status is expired. update save button to EDIT
      this.markScanDetailsPageTitle.controls[1].name = this.translate.instant('EDIT');
      this.markScanDetailsPageTitle.controls[1].imgSrc = 'assets/imgs/header_edit.svg';
      this.markScanDetailsPageTitle.controls[1].isDisabled = true;
      disableFooterButtons.push('editMarketScan');
    } else if (this.selectedMarketScan.statuscode === MarketScanStatusCode.Completed ||
      this.selectedMarketScan.statuscode === MarketScanStatusCode.PendingSync) {
      //If it is saved in dynamics, status is completed/ pending sync. update save button to EDIT
      this.markScanDetailsPageTitle.controls[1].name = this.translate.instant('EDIT');
      this.markScanDetailsPageTitle.controls[1].imgSrc = 'assets/imgs/header_edit.svg';
      this.markScanDetailsPageTitle.controls[1].isDisabled = !this.isEditEnabled;
      if (this.markScanDetailsPageTitle.controls[1].isDisabled)
        disableFooterButtons.push('editMarketScan');
      else
        enableFooterButtons.push('editMarketScan');
    }
    else if ((this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) && this.selectedMarketScan.statuscode === MarketScanStatusCode.Active) {
      //If it is saved => editing
      this.markScanDetailsPageTitle.controls[1].name = this.translate.instant('UPDATE');
      this.markScanDetailsPageTitle.controls[1].imgSrc = 'assets/imgs/update.svg';
      this.markScanDetailsPageTitle.controls[1].isDisabled = ! (this.selectedMarketScan.isModified && this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan));
      if (this.markScanDetailsPageTitle.controls[1].isDisabled)
        disableFooterButtons.push('updateMarketScan');
      else
        enableFooterButtons.push('updateMarketScan');
    }
    else if (this.selectedMarketScan.statuscode === MarketScanStatusCode.Active && !this.selectedMarketScan.indskr_marketscanid) {
      //Unsaved data with open status
      this.markScanDetailsPageTitle.controls[1].name = this.translate.instant('SAVE');
      this.markScanDetailsPageTitle.controls[1].imgSrc = 'assets/imgs/header_save.svg';
      this.markScanDetailsPageTitle.controls[1].isDisabled = !this.isSaveEnabled;
      if (this.markScanDetailsPageTitle.controls[1].isDisabled)
        disableFooterButtons.push('saveMarketScan');
      else
        enableFooterButtons.push('saveMarketScan');
    }
    //Scrap button, enable it if some data has been modified
    this.markScanDetailsPageTitle.controls[0].isDisabled = !(this.isScrapEnabled || this.selectedMarketScan.isModified);
    if (this.markScanDetailsPageTitle.controls[0].isDisabled)
      disableFooterButtons.push('scrapMarketScan');
    else
      enableFooterButtons.push('scrapMarketScan');
    if (enableFooterButtons.length)
      this.footerService.enableButtons(enableFooterButtons);
    if (disableFooterButtons.length)
      this.footerService.disableButton(disableFooterButtons);
    this._cd.detectChanges();
  }

  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case "scrapMarketScan":
        this.scrapMarketScan();
        break;
      case "saveMarketScan":
      case "updateMarketScan":
        this.selectedMarketScan.indskr_marketscanid ? this.updatableActionHandler() : this.saveMarketScan();
        break;
      case "editMarketScan":
        this.editableActionHandler();
        break;
      default:
        break;
    }
  };

  public get isReadOnlyView() {
    return this.selectedMarketScan
      && (!this.marketScanService.isOwner()
        || this.selectedMarketScan.statuscode !== MarketScanStatusCode.Active || this.backgroundUploadInProgress);
  }

  private get isScrapEnabled() {
    return this.selectedMarketScan
      && this.marketScanService.isOwner()
      && (!this.selectedMarketScan.indskr_marketscanid
        || this.selectedMarketScan.indskr_date && new Date(this.allowedUpdateDuration) >= new Date())
      && this.selectedMarketScan.statuscode != MarketScanStatusCode.Expired;
  }

  private get isSaveEnabled() {
    return this.selectedMarketScan
      && this.marketScanService.isOwner()
      && (this.selectedMarketScan.statuscode === MarketScanStatusCode.Active
        //&& !_.isEmpty(this.selectedMarketScan.therapeuticAreas)
        //&& !_.isEmpty(this.selectedMarketScan.selectedColumnData)
        && !_.isEmpty(this.selectedMarketScan.indskr_date)
        && this.selectedMarketScan.category
        && this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan)
        // && ((this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS && !_.isEmpty(this.selectedMarketScan.accounts)) ||
        //   (this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS && !_.isEmpty(this.selectedMarketScan.contacts) ||
        //   (this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS_ACCOUNTS && !_.isEmpty(this.selectedMarketScan.contacts)&& !_.isEmpty(this.selectedMarketScan.accounts))
        //     && !_.isEmpty(this.selectedMarketScan.customerSegmentations)))
        );
  }

  private get isEditEnabled() {
    return this.selectedMarketScan
      && this.marketScanService.isOwner()
      && (this.selectedMarketScan.statuscode === MarketScanStatusCode.Completed ||
        this.selectedMarketScan.statuscode === MarketScanStatusCode.PendingSync)
      && new Date(this.allowedUpdateDuration) >= new Date();
  }

  private initMarketScanDetailsPageTitle(): void {
    this.markScanDetailsPageTitle = {
      id: 'marketscan-details-page-title',
      title: this.selectedMarketScan && this.selectedMarketScan.indskr_name,
      controls: [
        {
          id: 'scrap',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: false,
          align: 'right'
        },
        {
          id: 'save',
          imgSrc: 'assets/imgs/header_save.svg',
          name: this.translate.instant('SAVE'),
          isDisabled: false,
          align: 'right'
        }],
    };
    this.tableHeaderData = {
      id: 'market-scan-table-header',
      title: this.translate.instant('SCAN'),
      controls: [
        {
          id: 'scan-refresh',
          icon: 'assets/imgs/refreshIconGrey.svg',
          isDisabled: !this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan),
          isVisible: this.authService.user.systemUserID == this.selectedMarketScan.ownerId && this.selectedMarketScan.statuscode == MarketScanStatusCode.Active && this.selectedMarketScan.category && this.selectedMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS,
        },
        {
          id: 'scan-expand',
          icon: 'assets/imgs/expand-icon.svg',
          isDisabled: !this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan),
        },
        {
          id: 'left-arrow',
          text: '',
          icon: 'assets/imgs/arrow-left-grey.svg',
          isDisabled: false,
        },
        {
          id: 'right-arrow',
          text: ``,
          icon: 'assets/imgs/arrow-right-grey.svg',
          isDisabled: false,
        }
      ]
    };

    this.isMarketScanMatrixViewAvailable = this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan);

    if(this.isMarketScanMatrixViewAvailable) {
      this.masterMatrixScanData = this.marketScanService.currentMarketScanDetailsViewMatrix;
      this.setUpPaginator();
    }else{
      this.masterMatrixScanData = null;
      this.matrixScanData = null;
    }
  }

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.marketScanService.getStatusText(this.selectedMarketScan.statuscode),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getSubjectFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: this.selectedMarketScan && this.selectedMarketScan.indskr_name ? this.selectedMarketScan.indskr_name : '',
      inputValue: this.selectedMarketScan && this.selectedMarketScan.indskr_name ? this.selectedMarketScan.indskr_name : '',
      id: 'subject-field',
      isReadOnly: this.isReadOnlyView,
      isDisabled: this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_AUTO_SUBJECT) || this.isReadOnlyView,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_AUTO_SUBJECT) || this.isReadOnlyView),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_AUTO_SUBJECT) || this.isReadOnlyView),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.selectedMarketScan && _.isEmpty(this.selectedMarketScan.indskr_name),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private updateSubjectBasedOnFrequency(catergoryUpdated: boolean = false) {
    this.marketScanService.updateSubjectBasedOnFrequency(this.selectedMarketScan, catergoryUpdated);
    if (this.selectedMarketScan.statuscode === MarketScanStatusCode.Active) {
      this.markScanDetailsPageTitle.title = this.selectedMarketScan.indskr_name;
    }
  }

  public getDateField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: !this.isWeeklyType ? this.translate.instant('DATE') : this.translate.instant('START_DATE'),
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      inputText: this.dateToDisplay,
      fromViewPage: CurViewPageType.MarketScan,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: !this.isReadOnlyView ? !this.sameYearMarketScan : true,
      showArrow: !this.isReadOnlyView ? this.sameYearMarketScan : false,
      isRequired: !this.isReadOnlyView ? this.sameYearMarketScan : false,
      isEmptyRequiredField: this.isClickedDate && _.isEmpty(this.dateToDisplay),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.errorMessageField = {
      id: DateTimeFieldType.ErrorMessageField,
      fromViewPage: CurViewPageType.MarketScan,
      isReadOnly: true,
      isRequired: !this.isReadOnlyView ? this.sameYearMarketScan : false,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedDate && _.isEmpty(this.dateToDisplay),
    }
    return viewData;
  }

  public getEndDateField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      customPlaceholderLabel: this.translate.instant('END_DATE'),
      inputText: this.endDateToDisplay,
      fromViewPage: CurViewPageType.MarketScan,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    };
    return viewData;
  }

  get sameYearMarketScan() {
    return _.isEmpty(this.selectedMarketScan.indskr_date) ? true : new Date(parseInt(this.selectedMarketScan.indskr_date)).getFullYear() === new Date().getFullYear();
  }

  // getTherapeuticAreaFormField() {
  //   let viewData: IndFormFieldViewDataModel;
  //   viewData = {
  //     label: this.translate.instant('THERAPEUTIC_AREAS'),
  //     inputText: this.marketScanService.displayText(this.selectedMarketScan.therapeuticAreas, 'therapeuticAreaName'),
  //     id: 'therapeutic-area-field',
  //     isReadOnly: true,
  //     isDisabled: !this.selectedMarketScan.category || this.marketScanService.therapeuticAreasMappedData.length == 0 || (this.selectedMarketScan.statuscode != MarketScanStatusCode.Active && _.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))) || (!this.marketScanService.isOwner() && _.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))),
  //     customPlaceholderLabel: (this.isReadOnlyView || this.marketScanService.therapeuticAreasMappedData.length == 0) ? `${this.translate.instant('NO')} ${this.translate.instant('THERAPEUTIC_AREAS')}` : this.translate.instant('SELECT_THERAPEUTIC_AREAS'),
  //     showArrow: (!(this.marketScanService.therapeuticAreasMappedData.length == 0 || (this.selectedMarketScan.statuscode != MarketScanStatusCode.Active && _.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))))),
  //     formFieldType: FormFieldType.NEW_PAGE_SELECT,
  //     isRequired: this.selectedMarketScan.category && !this.isReadOnlyView,
  //     errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
  //     isEmptyRequiredField: this.isClickedTA && _.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE)),
  //     eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
  //   };
  //   return viewData;
  // }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCTS'),
      inputText: this.marketScanService.displayText(this.selectedMarketScan.products, 'productName'),
      customPlaceholderLabel: ((this.isReadOnlyView || this.marketScanService.brands.length == 0) ? `${this.translate.instant('NO')} ${this.translate.instant('PRODUCTS')}` : this.translate.instant('SELECT_PRODUCTS')),
      id: 'products-field',
      isReadOnly: true,
      isDisabled: !this.selectedMarketScan.category || this.marketScanService.brands.length == 0
                  //|| _.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))
                  || (!this.marketScanService.isOwner() && _.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE))),
      showArrow: (!(this.marketScanService.brands.length == 0 || (this.selectedMarketScan.statuscode != MarketScanStatusCode.Active && _.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE))))),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: this.selectedMarketScan.category
                  //&& !_.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))
                  && !this.isReadOnlyView,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedProduct && _.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getSegmentFormField() {
    let viewData: IndFormFieldViewDataModel;
    const fieldDisabled = !this.selectedMarketScan.category || this.marketScanService.customerSegmentations.length == 0 || _.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE)) || (!this.marketScanService.isOwner() && _.isEmpty(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE)));
    viewData = {
      label: this.translate.instant('CUSTOMER_SEGMENT_WITH_TEXT', { text: this.utilityService.globalCustomerText }),
      inputText: this.marketScanService.displayText(this.selectedMarketScan.customerSegmentations, 'segmentName'),
      id: 'segment-field',
      isReadOnly: true,
      isDisabled: fieldDisabled,
      customPlaceholderLabel: ((this.isReadOnlyView || this.marketScanService.customerSegmentations.length == 0) ? `${this.translate.instant('NO')} ${this.translate.instant('CUSTOMER_SEGMENT_WITH_TEXT', { text: this.utilityService.globalCustomerText })}` : this.translate.instant('SELECT_CUSTOMER_SEGMENT_WITH_TEXT', { globalCustomertext: this.utilityService.globalCustomerText })),
      showArrow: (!(this.marketScanService.customerSegmentations.length == 0 || (this.selectedMarketScan.statuscode != MarketScanStatusCode.Active && _.isEmpty(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE)))) && !fieldDisabled),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: this.selectedMarketScan.category && !_.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE)) && !this.isReadOnlyView,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedSegment && _.isEmpty(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getViewFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('VIEW'),
      inputText: this.selectedMarketScan.selectView ? this.translate.instant(this.selectedMarketScan.selectView):'',
      id: 'select-view-field',
      isReadOnly: true,
      isDisabled: this.selectedMarketScan.category && this.selectedMarketScan.category.indskr_capturedatafor !== 548910002,
      customPlaceholderLabel: this.translate.instant('SELECT_VIEW'),
      showArrow: (this.selectedMarketScan.category && this.selectedMarketScan.category.indskr_capturedatafor === 548910002),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: this.selectedMarketScan.category && this.selectedMarketScan.category.indskr_capturedatafor === 548910002 && !this.isReadOnlyView,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedSelectView && !this.selectedMarketScan.selectView,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCustomerFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomersText),
      inputText: this.marketScanService.displayText(this.selectedMarketScan.contacts, 'contact_fullName'),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: !this.selectedMarketScan.category || this.contactService.contacts.length == 0 || _.isEmpty(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE)) || (!this.marketScanService.isOwner() && _.isEmpty(this.selectedMarketScan.contacts.filter(c => c.action != Action.DELETE))),
      customPlaceholderLabel: ((this.isReadOnlyView || this.contactService.contacts.length == 0) ? (this.translate.instant('NO') + ' ' + this.translate.instant(this.utilityService.globalCustomersText.toUpperCase())) : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.utilityService.globalCustomersText})),
      showArrow: (!(this.contactService.contacts.length == 0 || _.isEmpty(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE)) || (this.selectedMarketScan.statuscode != MarketScanStatusCode.Active && _.isEmpty(this.selectedMarketScan.contacts.filter(c => c.action != Action.DELETE))))),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: this.selectedMarketScan.category && !_.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE)) && !this.isReadOnlyView,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCustomer && (_.isEmpty(this.selectedMarketScan.contacts.filter(c => c.action != Action.DELETE)) || (this.selectedMarketScan.contacts && this.selectedMarketScan.contacts.length < 1)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAccountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS'),
      inputText: this.marketScanService.displayText(this.selectedMarketScan.accounts, 'accountName'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: !this.selectedMarketScan.category || (!this.marketScanService.isOwner() && _.isEmpty(this.selectedMarketScan.accounts.filter(c => c.action != Action.DELETE))),
      customPlaceholderLabel: (this.isReadOnlyView) ? this.translate.instant('NO_ACCOUNT') : this.translate.instant('SELECT_ACCOUNTS'),
      showArrow: (!(this.accountService.accounts.length == 0 || !this.selectedMarketScan.category || (this.selectedMarketScan.statuscode != MarketScanStatusCode.Active && _.isEmpty(this.selectedMarketScan.accounts.filter(c => c.action != Action.DELETE))))),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: !this.isReadOnlyView,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedAccount && _.isEmpty(this.selectedMarketScan.accounts.filter(c => c.action != Action.DELETE)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCategoriesFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CATEGORY'),
      inputText: this.selectedMarketScan.category ? this.selectedMarketScan.category.indskr_name : "",
      id: 'category-field',
      isReadOnly: true,
      isDisabled: (this.isReadOnlyView && !this.selectedMarketScan.category) || _.isEmpty(this.marketScanService.categories),
      customPlaceholderLabel: (((this.isReadOnlyView && !this.selectedMarketScan.category) || _.isEmpty(this.marketScanService.categories)) ? this.translate.instant('NO') + ' ' : '') + this.translate.instant('SELECT_CATEGORY'),
      showArrow: !(this.isReadOnlyView || _.isEmpty(this.marketScanService.categories)),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: !this.isReadOnlyView,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCategories && _.isEmpty(this.selectedMarketScan.category),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'subject-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.handleSubject(event, eventName);
          }
          break;
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        // case 'therapeutic-area-field':
        //   this.getTherapeuticAreaSelection();
        //   break;
        case 'products-field':
          this.gotoProductSelection();
          break;
        case 'segment-field':
          this.gotoSegmentSelection(event);
          break;
        case 'customer-field':
          this.gotoCustomerSelection();
          break;
        case 'category-field':
          this.gotoCategorySelection(event);
          break;
        case 'account-field':
          this.gotoAccountSelection();
          break;
        case 'select-view-field':
          this.handleSelectView(event);
          break;
        case 'competitors-field':
          this.openCompetitorsSelect();
          break;
        case 'procedure-field':
          this.openProcedureSelect();
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  get dateToDisplay() {
    return this.formattedDate;
  }

  get endDateToDisplay() {
    if (this.selectedMarketScan.indskr_date && this.authService.user.marketScanCreateFrequency === CreateFrequency.WEEKLY) {
      if (!this.selectedMarketScan.indskr_enddate)
        this.selectedMarketScan.indskr_enddate = new Date(moment(new Date(parseInt(this.selectedMarketScan.indskr_date))).endOf('month').format()).getTime().toString();
      const endDateFormatted = this.getFormattedAndLocalisedDate(new Date(parseInt(this.selectedMarketScan.indskr_enddate)));
      return endDateFormatted;
    }
  }

  // async getTherapeuticAreaSelection() {
  //   let options: SelectListData[] = [];
  //   if (this.isReadOnlyView) {
  //     //For completed record, use existing data
  //     this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE).forEach(ta => {
  //       options.push({ id: ta.therapeuticAreaId, title: ta.therapeuticAreaName, isSelected: true });
  //     })
  //   } else {
  //     const therapeuticAreas: TherapeuticArea[] = cloneDeep(this.marketScanService.therapeuticAreasMappedData);
  //     therapeuticAreas.forEach((t: TherapeuticArea) => {
  //       let isSelected: boolean = false;
  //       if (this.selectedMarketScan && this.selectedMarketScan.therapeuticAreas) {
  //         const index = this.selectedMarketScan.therapeuticAreas.findIndex(ta => ta.therapeuticAreaId === t.therapeuticareaid && ta.action != Action.DELETE);
  //         if (index >= 0)
  //           isSelected = true;
  //       }
  //       options.push({ id: t.therapeuticareaid, title: t.therapeuticarea_name, isSelected: isSelected });
  //     });
  //   }
  //   options = this.marketScanService.sortListByFielName(options, 'title');
  //   const therapeuticAreasModal = await this.modalCtrl.create({
  //     component: SelectListComponent,
  //     componentProps: {
  //       viewData: {
  //         data: options,
  //         isMultipleSelectionEnabled: true,
  //         title: this.translate.instant('THERAPEUTIC_AREAS'),
  //         dividerTitle: this.translate.instant('ALL_THERAPEUTIC_AREAS'),
  //         isSearchEnabled: true,
  //         isReadOnly: this.isReadOnlyView
  //       }
  //     },
  //     backdropDismiss: false
  //   });
  //   therapeuticAreasModal.present();
  //   therapeuticAreasModal.onDidDismiss().then(async (obj: any) => {
  //     this.isClickedTA = true;
  //     this.isClickedProduct = this.isClickedSegment = this.isClickedCustomer = false;
  //     const data = obj.data;
  //     if (data && data.isDone) {
  //       let selectedTAs: MarketScanTA[] = [];
  //       data.selectedItems.forEach(selectedItem => {
  //         selectedTAs.push(new MarketScanTA(selectedItem.id, selectedItem.title, Action.PATCH));
  //       });
  //       if (!_.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE))
  //         && !await this.showResetAlert(this.translate.instant('THERAPEUTIC_AREA'))) {
  //         return;
  //       }
  //       await this.uiService.displayLoader();
  //       if (this.selectedMarketScan.pendingPushToDynamics || this.selectedMarketScan.indskr_marketscanid)
  //         this.selectedMarketScan.isModified = true;
  //       if (this.selectedMarketScan.indskr_marketscanid) {
  //         this.validateTACurrentPrevious(selectedTAs);
  //       }
  //       this.selectedMarketScan.therapeuticAreas = _.uniqBy(selectedTAs, 'therapeuticAreaId');
  //       await this.marketScanService.mapFilteredDataToSelectedMarketScan(this.selectedMarketScan, FilterType.THERAPEUTIC_AREA);
  //       await this.uiService.dismissLoader();
  //       this.notifyForNoDataToCapture();
  //     }
  //     this.footerService.initButtons(FooterViews.MARKET_SCAN_DETAILS);
  //   });
  // }

  private notifyForNoDataToCapture(): boolean {
    if (_.isEmpty(this.selectedMarketScan.indskr_date) || _.isEmpty(this.selectedMarketScan.category) || _.isEmpty(this.selectedMarketScan.products)) return false;
    //Show notification only in case of event/campaign
    if (!(this.selectedMarketScan.category.indskr_capturedata === CaptureData.EVENTS || this.selectedMarketScan.category.indskr_capturedata === CaptureData.CAMPAIGN)) return false;
    if (!this.selectedMarketScan.marketScanCustomerDatas || this.selectedMarketScan.marketScanCustomerDatas.filter(selectedColumn => selectedColumn.action === Action.PATCH).length === 0) {
      let captureData = '';
      switch (this.selectedMarketScan.category.indskr_capturedata) {
        case CaptureData.EVENTS:
          captureData = this.translate.instant("EVENTS");
          break;
        case CaptureData.CAMPAIGN:
          captureData = this.translate.instant("CAMPAIGNS");
          break;
        default:
          console.log("unhandled case");
      }
      if (!_.isEmpty(captureData))
        this.notificationService.notify(this.translate.instant("NO_DATA_TO_CAPTURE_CUSTOMER_SCAN", { captureData: captureData.toLowerCase() }), 'Market Scan', 'top', ToastStyle.DANGER);
      return true;

    }
  }

  validateTACurrentPrevious(ta: MarketScanTA[]) {
    if (!ta.length) {
      ta.push(...this.selectedMarketScan.therapeuticAreas);
      ta.forEach(t => t.action = Action.DELETE);
    }
    // this.marketScanService.marketScanCopy.therapeuticAreas
    let alreadySelectedTAs = _.map(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action && ta.action != Action.DELETE), 'therapeuticAreaId');
    let currentSelectedTAs = _.map(ta, 'therapeuticAreaId');
    if (!alreadySelectedTAs.length && _.every(this.selectedMarketScan.therapeuticAreas, ['action', Action.DELETE])) {
      ta.push(...this.selectedMarketScan.therapeuticAreas);
    }
    let diffTAs = _.differenceWith(alreadySelectedTAs, currentSelectedTAs, _.isEqual);
    if (diffTAs && diffTAs.length) {
      diffTAs.forEach((diff) => {
        let deletableTA = this.selectedMarketScan.therapeuticAreas.find((ta) => ta.therapeuticAreaId == diff);
        if (deletableTA) {
          deletableTA.action = Action.DELETE;
          ta.push(deletableTA);
        }
      });
    } else {
      ta.forEach(t => {
        t.action = t.action ? t.action : Action.PATCH;
      })
    }
    // const uniqueDeletableTAs = _.uniqBy(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action && ta.action == Action.DELETE), 'therapeuticAreaId');
    // ta.push(...uniqueDeletableTAs);
    // add unique deletable objects which gets missed in the above logic
    // scenario: when "marketScan.contacts" has one object with action patch and the other as delete
    const deletableTAs = this.selectedMarketScan.therapeuticAreas.filter(t => t.action && t.action == Action.DELETE);
    if (deletableTAs.length > 0) {
      const ids = ta.map((t) => t.therapeuticAreaId);
      deletableTAs.forEach((t) => {
        if (!ids.includes(t.therapeuticAreaId)) {
          ta.push(t);
        }
      });
    }
  }

  async gotoProductSelection() {
    let options = [];
    let competitorProductsOptions = [];
    if (this.isReadOnlyView) {
      //For completed record, use existing data
      this.selectedMarketScan.products.filter(p => p.action != Action.DELETE).forEach(p => {
        options.push({ id: p.productId, title: p.productName, isSelected: true });
      })
    } else {
      const products: Brand[] = cloneDeep(this.marketScanService.brands);
      products.forEach((b: Brand) => {
        let isSelected: boolean = false;
        if (this.selectedMarketScan && this.selectedMarketScan.therapeuticAreas) {
          const index = this.selectedMarketScan.products.findIndex(ta => ta.productId === b.ID && ta.action != Action.DELETE);
          if (index >= 0)
            isSelected = true;
        }
        if (b.isCompetitorProduct === "true") {
          competitorProductsOptions.push({ id: b.ID, title: b.name, isSelected: isSelected });
        } else {
          options.push({ id: b.ID, title: b.name, isSelected: isSelected });
        }
      });
    }
    competitorProductsOptions = this.marketScanService.sortListByFielName(competitorProductsOptions, 'title');
    options = this.marketScanService.sortListByFielName(options, 'title');

    const productsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('PRODUCTS'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: true,
          isReadOnly: this.isReadOnlyView,
          subData: competitorProductsOptions,
          subDividerTitle: this.translate.instant('COMPETITOR_PRODUCTS')
        }
      },
      backdropDismiss: false
    });
    productsModal.present();
    productsModal.onDidDismiss().then(async (obj: any) => {
      this.isClickedProduct = true;
      this._handleProducts(obj.data, competitorProductsOptions)
    });
  }

  async _handleProducts(data,competitorProductsOptions?){
    if (data && data.isDone) {
      let selectedProducts: MarketScanProduct[] = [];
      data.selectedItems.forEach(selectedItem => {
        let flag = false;
        let name = selectedItem.title;
        if(competitorProductsOptions){ // when selecting product
          flag = competitorProductsOptions.some(c=> c.id == selectedItem.id);
        } else if(this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.COMPETITOR) {
          name = selectedItem.primaryTextRight // when selected procedures
        }
        selectedProducts.push(new MarketScanProduct(selectedItem.id,name ,flag, Action.PATCH));
      });
      if (!_.isEmpty(this.selectedMarketScan.products.filter(p => p.action != Action.DELETE))
        && ((this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS
          && (!_.isEmpty(this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE))
            || !_.isEmpty(this.selectedMarketScan.contacts.filter(c => c.action != Action.DELETE))))
          || (this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS
            && !_.isEmpty(this.selectedMarketScan.accounts.filter(ac => ac.action != Action.DELETE))) ||
            (this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.COMPETITOR
              &&!_.isEmpty(this.selectedMarketScan.competitors.filter(ac => ac.action != Action.DELETE)))
            )) {
        if (!await this.showResetAlert(this.translate.instant('PRODUCT')))
          return;
      }
      await this.uiService.displayLoader();
      if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
        this.selectedMarketScan.isModified = true;
      }
      this.marketScanService.unMapReMapProducts(selectedProducts, this.selectedMarketScan);
      this.selectedMarketScan.products = _.uniqBy(selectedProducts, 'productId');
      await this.marketScanService.mapFilteredDataToSelectedMarketScan(this.selectedMarketScan, FilterType.PRODUCT);
      await this.uiService.dismissLoader();
      this.notifyForNoDataToCapture();
    }
    this.footerService.initButtons(FooterViews.MARKET_SCAN_DETAILS);
  }

  async gotoSegmentSelection(event) {
    let options = [];
    this.categorySelection = false;
    if (this.isReadOnlyView) {
      //For completed record, use existing data
      this.selectedMarketScan.customerSegmentations.filter(cs => cs.action != Action.DELETE).forEach(cs => {
        options.push({ id: cs.customerSegmentationId, title: cs.segmentName, isSelected: true });
      })
    } else {
      const custSegmentations: CustomerSegmentation[] = _.cloneDeep(this.marketScanService.customerSegmentations);
      custSegmentations.forEach((c: CustomerSegmentation) => {
        let isSelected: boolean = false;
        if (this.selectedMarketScan && this.selectedMarketScan.customerSegmentations) {
          const index = this.selectedMarketScan.customerSegmentations.findIndex(ta => ta.customerSegmentationId === c.indskr_customersegmentationid && ta.action != Action.DELETE);
          if (index >= 0)
            isSelected = true;
        }
        options.push({ id: c.indskr_customersegmentationid, title: c.indskr_name, isSelected: isSelected });
      });
    }
    options = this.marketScanService.sortListByFielName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'segment-select',
      data: options,
      isMultipleSelectionEnabled: true,
      isReadOnly: this.isReadOnlyView,
      showClearAllBtn: !this.isReadOnlyView,
      clearAllBtnText: this.translate.instant('CLEAR_ALL')
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'segment-dropdown-list-view',
      event: event
    });

    dropdownPopover.onDidDismiss().then(async (data) => {
      this.isClickedSegment = true;
    });
    dropdownPopover.present();
  }

  async gotoCustomerSelection() {
    this.uiService.prevView = 'market-scan-details';
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'contactDetails';
    if (this.isReadOnlyView) {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
    } else {
      this.isClickedCustomer = true;
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      if (_.isEmpty(this.selectedMarketScan.segmentedContacts)) {
        this.marketScanService.mapFiltersForCustomerSegmentation(this.selectedMarketScan, false);
      }
    }
    this.contactService.accessedContactListFrom = PageName.MarketScanDetailsComponent;
    await this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent)
  }

  async gotoCategorySelection(event) {
    let options = [];
    if (this.isReadOnlyView) return;
    const categories: Category[] = _.cloneDeep(this.marketScanService.categories);
    categories.forEach((c: Category) => {
      let isSelected: boolean = this.selectedMarketScan && this.selectedMarketScan.category && this.selectedMarketScan.category.indskr_assessmentcategoryid === c.indskr_assessmentcategoryid;
      options.push({ id: c.indskr_assessmentcategoryid, title: c.indskr_name, isSelected: isSelected });
    });
    options = this.marketScanService.sortListByFielName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'category-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.isReadOnlyView
    };
    this.categorySelection = true;
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (obj) => {
      this.isClickedCategories = true;
      const data = obj.data;
      if (!this.isReadOnlyView && data && data.selectedItems && data.selectedItems.length == 1) {
        if (this.selectedMarketScan.category && data.selectedItems[0].id === this.selectedMarketScan.category.indskr_assessmentcategoryid) return;
        const category: Category = categories.find(c => c.indskr_assessmentcategoryid === data.selectedItems[0].id);

        this.isRecordCapturedAt = category.indskr_recordcapturedat;
        this.showJustAccounts = this.isRecordCapturedAt != null;

        if(!this.isRecordCapturedAt){
          let selMatrixScanCopy = cloneDeep(this.selectedMarketScan);
          selMatrixScanCopy.category = category;
          selMatrixScanCopy.categoryId = category.indskr_assessmentcategoryid;
          if (this.marketScanService.checkIfMarketScanExists(selMatrixScanCopy.categoryId,selMatrixScanCopy)) {
            this.notificationService.notify(this.marketScanService.getErrorMessageAsPerConf(new Date(this.marketScanDate), data.selectedItems[0].title), 'Market Scan', 'top', ToastStyle.DANGER);
            return;
          }
        }

        if (this.selectedMarketScan.category
          //&& !_.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))
          && !await this.showResetAlert(this.translate.instant('CATEGORY'))) {
          return;
        }
        await this.uiService.displayLoader();

        if (this.selectedMarketScan.category) {
          //If category changed and not saved in dynamics, clear customer data
          if (!this.selectedMarketScan.indskr_marketscanid) {
            this.selectedMarketScan.marketScanCustomerDatas = [];
          } else {
            // if (this.selectedMarketScan.category.indskr_capturedata !== category.indskr_capturedata) {
            //   this.selectedMarketScan.selectedColumnData.forEach(c => c.action = Action.DELETE);
            // }
            //If it has saved record in dynamics, set action as DELETE to remove corresponding customer data
            // this.selectedMarketScan.category.measures.forEach(m => {
            //   //Set action to delete
            //   this.selectedMarketScan.marketScanCustomerDatas.forEach(cData => {
            //     // cData.data.filter(data => data.indskr_assessmentcategoryid == this.selectedMarketScan.category.indskr_assessmentcategoryid && data.measureId === m.measureId).forEach(data => {
            //     //   data.action = Action.DELETE;
            //     // })
            //     if(cData.indskr_assessmentcategoryid == this.selectedMarketScan.category.indskr_assessmentcategoryid && data.measureId === m.measureId)
            //     cData.action = Action.DELETE;
            //   });
            // });
            this.selectedMarketScan.marketScanCustomerDatas.forEach(cData => {
              cData.action = Action.DELETE;
            });

          }
          this.resetFilterData(category);
        }
        this.selectedMarketScan.category = category;
        this.selectedMarketScan.categoryId = category.indskr_assessmentcategoryid;
        if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
          this.selectedMarketScan.isModified = true;
        }
        this.updateSubjectBasedOnFrequency(true);
        //this.marketScanService.mapCaptureDataToProducts(this.selectedMarketScan);
        if (this.selectedMarketScan.category.indskr_capturedata === CaptureData.ACCOUNTS) {
          if ((_.isEmpty(this.selectedMarketScan.marketScanCustomerDatas) || this.selectedMarketScan.marketScanCustomerDatas.filter(columnData => columnData.action === Action.PATCH).length === 0)
            && (!_.isEmpty(this.selectedMarketScan.contacts) && this.selectedMarketScan.contacts.filter(contact => contact.action === Action.PATCH).length > 0)) {
            this.notificationService.notify(this.translate.instant("NO_DATA_TO_CAPTURE_CUSTOMER_SCAN", { captureData: this.translate.instant("ACCOUNTS").toLowerCase() }), 'Market Scan', 'top', ToastStyle.DANGER);
          }
        } else
          this.notifyForNoDataToCapture();


        this.marketScanService.createUpdateMarketScanCustomerDataPayload(this.selectedMarketScan);
        await this.marketScanService.updateMarketScanInPouchDB(this.selectedMarketScan);
        this.marketScanService.upsertMarketScans(this.selectedMarketScan);
        //this.constructTableData(this.selectedMarketScan);
        this.initMarketScanDetailsPageTitle();
        this.updateButtonStatus();

        this.uiService.dismissLoader();
      }
    });
    dropdownPopover.present();
  }

  private resetFilterData(category: Category) {
    //if (this.selectedMarketScan.category.indskr_capturedatafor != category.indskr_capturedatafor) {
      this.isClickedProduct = this.isClickedTA = this.isClickedAccount = this.isClickedCustomer = this.isClickedSelectView = this.isClickedSegment = this.isClickedCompetitor = this.isClickedProcrdure = false;
      this.selectedMarketScan.selectView = null;
      if (!this.selectedMarketScan.indskr_marketscanid) {
        this.selectedMarketScan.therapeuticAreas = [];
        this.selectedMarketScan.products = [];
        this.selectedMarketScan.customerSegmentations = [];
        this.selectedMarketScan.contacts = [];
        this.selectedMarketScan.accounts = [];
        this.selectedMarketScan.marketScanCustomerDatas = [];
        this.selectedMarketScan.competitors = [];
        // this.selectedMarketScan.procedures = [];
      } else {
        this.selectedMarketScan.therapeuticAreas.map(ta => ta.action = Action.DELETE);
        this.selectedMarketScan.products.map(p => p.action = Action.DELETE);
        this.selectedMarketScan.customerSegmentations.map(cs => cs.action = Action.DELETE);
        this.selectedMarketScan.contacts.map(c => c.action = Action.DELETE);
        this.selectedMarketScan.accounts.map(ac => ac.action = Action.DELETE);
        this.selectedMarketScan.marketScanCustomerDatas.map(cData => cData.action = Action.DELETE);
        this.selectedMarketScan.competitors.map(cData => cData.action = Action.DELETE);
        // this.selectedMarketScan.procedures.map(cData => cData.action = Action.DELETE);
      }
      this.isMarketScanMatrixViewAvailable = false;
      this.matrixScanData = null;
      this.masterMatrixScanData = null;
    //}
  }

  gotoAccountSelection() {
    const contactIds = [];
    if(this.selectedMarketScan.category?.indskr_capturedatafor === 548910002) {
      if(this.selectedMarketScan.contacts){
        this.selectedMarketScan.contacts.forEach(p=> {
            if(p.contactId && p.action === Action.PATCH){
                contactIds.push(p.contactId);
            }
        });
    }
    }
    this.uiService.showNewActivity = false;
    this.accountService.selectedFor = AccountSelectedFor.MARKET_SCAN;
    if (this.isReadOnlyView) {
      this.accountService.accountPageMode = ComponentViewMode.READONLY;
    } else {
      this.accountService.accountPageMode = this.selectedMarketScan.category.indskr_capturedatafor === 548910003 ? ComponentViewMode.SELECTION  :  ComponentViewMode.ADDNEW;
    }
    this.accountService.accessedAccountListFrom = PageName.MarketScanDetailsComponent;
    this.isClickedAccount = true;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': this.accountService.accountPageMode, selectedContactIds:contactIds, callbackEvent: (data: any) => this._handleAccountComponentCallback(data) }, PageName.AccountPageComponent);
  }

  private async _handleAccountComponentCallback(data) {
    if (this.isReadOnlyView || this.backgroundUploadInProgress) return;

    if (data) {
      await this.uiService.displayLoader();
      let marketScanSelectedAccounts: MarketScanAccount[] = [];
      if (!_.isEmpty(data.selectedItems)) {

        if (this.isRecordCapturedAt !== null) {
          let selMatrixScanCopy = cloneDeep(this.selectedMarketScan);
          const categories: Category[] = _.cloneDeep(this.marketScanService.categories);
          const category: Category = categories.find(c => c.indskr_assessmentcategoryid === this.selectedMarketScan.categoryId);
          marketScanSelectedAccounts = data.selectedItems.map(item => new MarketScanAccount(item.name, item.id, Action.PATCH, item.sequence,true,[]))
          selMatrixScanCopy.accounts = marketScanSelectedAccounts;
          if (this.marketScanService.checkIfMarketScanExists(selMatrixScanCopy.categoryId, selMatrixScanCopy)) {
            this.notificationService.notify(this.marketScanService.getErrorMessageAsPerConf(new Date(this.marketScanDate), category.indskr_name, category.indskr_recordcapturedat), 'Market Scan', 'top', ToastStyle.DANGER);
            await this.uiService.dismissLoader();
            return;
          }
        }
        marketScanSelectedAccounts = [];


        //data.selectedItems.map(async item => {
        for (let l=0;l<data.selectedItems.length;++l){
          let item = data.selectedItems[l];
          if(item){
            let marketScanSelectedContacts: MarketScanContact[] = [];
            if (this.selectedMarketScan.category && this.selectedMarketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && this.selectedMarketScan.selectView == 'ACCOUNT_VIEW') {
              let affiliatedContacts:Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById(item.id)]);
              if (affiliatedContacts && affiliatedContacts.length > 0) {
                //affiliatedContacts.map(contact => {
                for (let m=0;m<affiliatedContacts.length;++m){
                  let contact = affiliatedContacts[m];
                  if(contact && !marketScanSelectedContacts.some(c=> c.contactId == contact.ID)){
                    marketScanSelectedContacts.push(new MarketScanContact(contact.fullName, contact.ID, Action.PATCH, contact['sequence']))
                  }
                }
              }
            }
            marketScanSelectedAccounts.push(new MarketScanAccount(item.name, item.id, Action.PATCH, item.sequence,true,marketScanSelectedContacts))
          }
        }
      }


      // if(marketScanSelectedAccounts.length === 0 && this.selectedMarketScan.products.length > 0 && this.isRecordCapturedAt !== null){
      //   this._handleProducts({ isDone : true, selectedItems: [] })
      // }

      if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
        this.selectedMarketScan.isModified = true;
      }
      this.marketScanService.mapAccountsToMarketScan(this.selectedMarketScan, marketScanSelectedAccounts);
      this.uiService.dismissLoader();
    }
  }

  async updateMarketScanDate(isDone: boolean) {
    if (isDone) {
      if (_.isEmpty(this.marketScanDate)) return;
      if (this.selectedMarketScan.indskr_date && this.marketScanDate && new Date(this.marketScanDate).setHours(0, 0, 0, 0) === new Date(parseInt(this.selectedMarketScan.indskr_date)).setHours(0, 0, 0, 0)) {
        return;
      }
      const m = moment(this.marketScanDate);
      if (!m.isValid()) {
        this.marketScanDate = this.selectedMarketScan.indskr_date ? moment(parseInt(this.selectedMarketScan.indskr_date)).format() : undefined;
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Market Scan', 'top', ToastStyle.DANGER);
        return;
      }
      let selMatrixScanCopy = cloneDeep(this.selectedMarketScan);
      selMatrixScanCopy.indskr_date = new Date(this.marketScanDate).getTime().toString();
      selMatrixScanCopy.indskr_enddate = this.marketScanService.getEndDateAsPerConf(new Date(this.marketScanDate)).getTime().toString();
      if (!this.marketScanService.checkIfMarketScanExists(selMatrixScanCopy.categoryId,selMatrixScanCopy)) {
        //if future month date is selected
        this.selectedMarketScan.indskr_date = new Date(this.marketScanDate).getTime().toString();
        this.selectedMarketScan.indskr_enddate = this.marketScanService.getEndDateAsPerConf(new Date(this.marketScanDate)).getTime().toString();
        this.formattedDate = this.getFormattedAndLocalisedDate(new Date(parseInt(this.selectedMarketScan.indskr_date)))//format(new Date(parseInt(this.selectedMarketScan.indskr_date)), this.dateTimeFormatsService.dateToUpper);
        if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
          this.selectedMarketScan.isModified = true;
        }
        this.updateSubjectBasedOnFrequency();
        //this.marketScanService.mapCaptureDataToProducts(this.selectedMarketScan);
        this.marketScanService.upsertMarketScans(this.selectedMarketScan);
        this.notifyForNoDataToCapture();
        await this.marketScanService.updateMarketScanInPouchDB(this.selectedMarketScan);
        //this.constructTableData(this.selectedMarketScan);
        this.updateButtonStatus();
      } else {
        this.notificationService.notify(this.marketScanService.getErrorMessageAsPerConf(new Date(this.marketScanDate), this.selectedMarketScan.category.indskr_name.replace(" (" + this.translate.instant('INACTIVE') + ")", "")), 'Market Scan', 'top', ToastStyle.DANGER);
        this.formattedDate = this.selectedMarketScan.indskr_date ? this.getFormattedAndLocalisedDate(new Date(parseInt(this.selectedMarketScan.indskr_date))) : '';
        return;
      }
    }
  }

  async handleSubject(event, eventName) {
    if (event && event.target.value !== undefined) {
      //Keep default subject as Market Scan if user enters empty
      let name = event.target.value;
      if (eventName === 'input_value_confirm' && _.isEmpty(name)) {
        name = this.translate.instant("CUSTOMER_SCAN");
      }
      if (name !== this.selectedMarketScan.indskr_name) {
        this.selectedMarketScan.indskr_name = name;
        this.markScanDetailsPageTitle.title = this.selectedMarketScan.indskr_name;
        if (this.selectedMarketScan.indskr_name && (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) && this.selectedMarketScan.indskr_name !== this.marketScanService.marketScanCopy.indskr_name) {
          this.selectedMarketScan.isModified = true;
        }
        this.updateButtonStatus();
        await this.marketScanService.updateMarketScanInPouchDB(this.selectedMarketScan);
        this.marketScanService.upsertMarketScans(this.selectedMarketScan);
      }
    }
  }

  // async updatetableData(raw) {
  //   if (this.selectedMarketScan.statuscode === MarketScanStatusCode.Active && raw) {
  //     const startTime = new Date().getTime();
  //     if (raw.updateMarketScan) {
  //       this.selectedMarketScan = raw.updateMarketScan;
  //     }
  //     if ((this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) && !this.selectedMarketScan.isModified) {
  //       this.selectedMarketScan.isModified = true;
  //       this.updateButtonStatus();
  //     }
  //     let cDataIndex = -1;
  //     if (this.selectedMarketScan.category.indskr_capturedatafor == CaptureDataFor.ACCOUNTS) {
  //       cDataIndex = this.selectedMarketScan.marketScanCustomerDatas.findIndex(cData => cData.accountId === raw.accountId);
  //     } else {
  //       cDataIndex = this.selectedMarketScan.marketScanCustomerDatas.findIndex(cData => cData.contactId === raw.contactId);
  //     }
  //     if (cDataIndex >= 0) {
  //       let dataIndex: number = -1;
  //       switch (this.selectedMarketScan.category.indskr_capturedata) {
  //         case CaptureData.EVENTS:
  //           dataIndex = this.selectedMarketScan.marketScanCustomerDatas[cDataIndex].data.findIndex(data => data.measureId === raw.measureId && data.indskr_eventid === raw.productId && data.action != Action.DELETE);
  //           break;
  //         case CaptureData.CAMPAIGN:
  //           dataIndex = this.selectedMarketScan.marketScanCustomerDatas[cDataIndex].data.findIndex(data => data.measureId === raw.measureId && data.indskr_campaignid === raw.productId && data.action != Action.DELETE);
  //           break;
  //         case CaptureData.ACCOUNTS:
  //           dataIndex = this.selectedMarketScan.marketScanCustomerDatas[cDataIndex].data.findIndex(data => data.measureId === raw.measureId && data.accountId === raw.productId && data.action != Action.DELETE);
  //           break;
  //         default:
  //           dataIndex = this.selectedMarketScan.marketScanCustomerDatas[cDataIndex].data.findIndex(data => data.measureId === raw.measureId && data.productId === raw.productId && data.action != Action.DELETE);

  //       }
  //       if (dataIndex >= 0) {
  //         this.selectedMarketScan.marketScanCustomerDatas[cDataIndex].data[dataIndex].action = Action.PATCH;
  //         this.selectedMarketScan.marketScanCustomerDatas[cDataIndex].data[dataIndex].rX = raw.value;
  //         await this.marketScanService.updateMarketScanInPouchDB(this.selectedMarketScan);
  //       }
  //     }
  //     const endTime = new Date().getTime();
  //     console.log("Time taken to updatetableData: " + (endTime - startTime));
  //   }
  // }

  // public async constructTableData(value: MarketScan) {
  //   if (_.isEmpty(value.indskr_date) || _.isEmpty(value.category) || _.isEmpty(value.selectedColumnData) ||
  //     (value.category?.indskr_capturedatafor === CaptureDataFor.ACCOUNTS && _.isEmpty(value.accounts)) ||
  //     (value.category?.indskr_capturedatafor === CaptureDataFor.CUSTOMERS && _.isEmpty(value.contacts)) ||
  //     (value.category?.indskr_capturedatafor === CaptureDataFor.CUSTOMERS_ACCOUNTS && _.isEmpty(value.contacts) && _.isEmpty(value.accounts))) {
  //     this.rows = [];
  //     this.subrows = [];
  //     this.columns = this.marketScanService.getColumns(value);
  //     this.setWidthofContent();
  //     await this.uiService.dismissLoader();
  //     return;
  //   }
  //   const startTime = new Date().getTime();
  //   // create data for table
  //   const subrows = this.marketScanService.getSubrows(value);
  //   // set columns to the table
  //   const columns = this.marketScanService.getColumns(value);
  //   const page = this.getPage();
  //   //Clear data before creating
  //   const data = await this.marketScanService.constructTableData([], columns, subrows, page.from, page.to);
  //   value.retainPage = false;
  //   value = data.marketScan;
  //   this.rows = data.rows;
  //   this.subrows = subrows;
  //   this.columns = columns;
  //   const endTime = new Date().getTime();
  //   console.log("Time taken to construct table: " + (endTime - startTime));
  //   await this.uiService.dismissLoader();
  //   this.setUpPaginator();
  //   this.setWidthofContent();
  // }

  // private getPage(): { from: number, to: number } {
  //   this.visibleRowsLength = this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.CUSTOMERS ? this.selectedMarketScan.contacts.filter(c => c.action === Action.PATCH).length : this.selectedMarketScan.accounts.filter(c => c.action === Action.PATCH).length;
  //   let from: number = 0;
  //   let to: number = 10;
  //   if (this.visibleRowsLength > 10 && this.paginator) {
  //     if (this.selectedMarketScan.retainPage) {
  //       from = (this.paginator.getPage() * this.paginator.rows);
  //       to = (this.paginator.getPage() + 1) * this.paginator.rows;
  //       if (this.paginator.getPage() == this.paginator.getPage() + 1 && to > this.visibleRowsLength) {
  //         to = this.visibleRowsLength;
  //       }
  //     } else {
  //       this.paginator.changePage(0);
  //     }
  //   }
  //   return { from: from, to: to };
  // }

  onSectionHeaderControlClick(id: string) {
    const scrollJump = 50;
    let target = document.getElementById('marketScanDetailsTableRow');
    switch (id) {
      case this.markScanDetailsPageTitle.controls[1].id:
        this.trackingService.tracking('CustomerScanSave', TrackingEventNames.CUSTOMER_SCAN,true);
        if (this.markScanDetailsPageTitle.controls[1].name.includes(this.translate.instant('EDIT'))) {
          this.editableActionHandler();
        } else {
          this.selectedMarketScan.indskr_marketscanid ? this.updatableActionHandler() : this.saveMarketScan();
        }
        break;
      case this.markScanDetailsPageTitle.controls[0].id:
        this.scrapMarketScan();
        break;
      case 'close':
        this.onClosePage();
        break;
      case 'scan-expand':
        this.handleScanExpandButton();
        break;
      case 'scan-refresh':
        this.handleScanRefreshButton();
        break;
       case 'left-arrow':
          if(target){
            target.scrollLeft = target.scrollLeft - scrollJump;
          }
        break;
       case 'right-arrow':
          if(target){
            target.scrollLeft = target.scrollLeft + scrollJump;
          }
        break;
      default:
        break;
    }
  }

  async editableActionHandler() {
    const foundInActive = await this.marketScanService.checkForInActiveEntitites(this.selectedMarketScan);
    this.selectedMarketScan.statuscode = MarketScanStatusCode.Active;
    if (foundInActive) {
      await this.uiService.displayLoader();
      //this.constructTableData(this.selectedMarketScan);
    }
    this.marketScanService.createUpdateMarketScanCustomerDataPayload(this.selectedMarketScan);
    this.marketScanService.upsertMarketScans(this.selectedMarketScan);
    this.initMarketScanDetailsPageTitle();
    this.footerService.initButtons(FooterViews.MARKET_SCAN_DETAILS);
    this.updateButtonStatus();
  }

  private async updatableActionHandler() {
    if (!this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan)) return;
    await this.uiService.displayLoader({ duration: 300000 });
    //Untill save to dynamics, keep it as pending sync
    this.selectedMarketScan.statuscode = MarketScanStatusCode.PendingSync;
    this.selectedMarketScan.retainPage = true;
    await this.marketScanService.updateMarketScan(this.selectedMarketScan);
    await this.uiService.dismissLoader();
  }

  // anyDataIsMissing() {
  //   let message = this.translate.instant('UPDATE_CUSTOMER_SCAN_TOAST_MESSAGE');
  //   let missingData: string[] = [];
  //   if (_.isEmpty(this.selectedMarketScan.indskr_date)) {
  //     missingData.push(this.translate.instant('DATE'));
  //   }
  //   if (_.isEmpty(this.selectedMarketScan.category)) {
  //     missingData.push(this.translate.instant('CATEGORY'));
  //     this.isClickedCategories = true;
  //   }
  //   // if (_.isEmpty(this.selectedMarketScan.therapeuticAreas.filter(ta => ta.action != Action.DELETE))) {
  //   //   missingData.push(this.translate.instant('THERAPEUTIC_AREA'));
  //   //   this.isClickedTA = true;
  //   // }
  //   if (_.isEmpty(this.selectedMarketScan.products.filter(ta => ta.action != Action.DELETE))) {
  //     missingData.push(this.translate.instant('PRODUCT'));
  //     this.isClickedProduct = true;
  //   }
  //   if (this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
  //     if (_.isEmpty(this.selectedMarketScan.accounts.filter(ta => ta.action != Action.DELETE))) {
  //       missingData.push(this.translate.instant('ACCOUNT'));
  //       this.isClickedAccount = true;
  //     }
  //   } else {
  //     if (_.isEmpty(this.selectedMarketScan.customerSegmentations.filter(ta => ta.action != Action.DELETE))) {
  //       missingData.push(this.translate.instant('CUSTOMER_SEGMENT_SINGULAR_WITH_TEXT', { text: this.utilityService.globalCustomerText }));
  //       this.isClickedSegment = true;
  //     }
  //     if (_.isEmpty(this.selectedMarketScan.contacts.filter(ta => ta.action != Action.DELETE))) {
  //       missingData.push(this.translate.instant(this.utilityService.globalCustomerText.toUpperCase()));
  //       this.isClickedCustomer = true;
  //     }
  //   }
  //   if (missingData.length) {
  //     message = message.replace("{fieldName}", missingData.join(", ").toLowerCase());
  //     this.notificationService.notify(message, "Market Scan Details", "top", ToastStyle.DANGER);
  //     return true;
  //   } else {
  //     if (this.selectedMarketScan.category.indskr_capturedata === CaptureData.ACCOUNTS && (_.isEmpty(this.selectedMarketScan.marketScanCustomerDatas) || this.selectedMarketScan.marketScanCustomerDatas.filter(columnData => columnData.action === Action.PATCH).length === 0)) {
  //       this.notificationService.notify(this.translate.instant("NO_DATA_TO_CAPTURE_CUSTOMER_SCAN", { captureData: this.translate.instant("ACCOUNTS").toLowerCase() }), 'Market Scan', 'top', ToastStyle.DANGER);
  //       return true;
  //     } else if (this.notifyForNoDataToCapture()) return true;
  //   }
  //   return false
  // }

  public onClosePage(isFromScrap = false): void {
    if (!isFromScrap && this.selectedMarketScan.isModified) {
      this.marketScanService.showDiscardChangesPopup().then(async res => {
        if (res.role === "ok") {
          await this.marketScanService.discardChanges();
          this.navigateBack();
        }
      });
    } else {
      this.navigateBack();
    }
  }

  private navigateBack() {
    this.marketScanService.setCurrentMarketScan(null);
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.AccountPageComponent);
    this.navService.setChildNavRightPaneView(false);
  }

  private async saveMarketScan() {
    if (!this.marketScanService.checkIfMarketScanCustomerDataCanBePopulated(this.selectedMarketScan)) return;
    await this.uiService.displayLoader({ duration: 300000 });
    //Untill save to dynamics, keep it as pending sync
    this.selectedMarketScan.statuscode = MarketScanStatusCode.PendingSync;
    this.selectedMarketScan.retainPage = true;
    await this.marketScanService.createMarketScan(this.selectedMarketScan);
    await this.uiService.dismissLoader();
  }

  showResetAlert(filterName: string) {
    //show alert only on editing
    if (this.selectedMarketScan.statuscode === MarketScanStatusCode.Active && !(this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics)) return true;
    return new Promise<Boolean>(resolve => {
      this.alertService.showAlert({
        title: this.translate.instant('POP_R_U_SURE_TITLE').replace("{FilterName}", filterName),
        message: this.translate.instant('POP_R_U_SURE_RESET_MESSAGE').replace(/\{FilterName\}/g, filterName.toLowerCase())
      }, this.translate.instant('RESET')
      ).then(res => {
        if (this.backgroundUploadInProgress) {
          resolve(false);
        }
        else if (res.role == "ok") {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  private async scrapMarketScan() {
    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_CUSTOMER_SCAN'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_CUSTOMER_SCAN')
    }, this.translate.instant('SCRAP')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.backgroundUploadInProgress) return;
        await this.uiService.displayLoader();
        await this.marketScanService.scrapMarketScan(this.selectedMarketScan).then(() => {
          this.onClosePage(true);
        });
        await this.uiService.dismissLoader();
      }
    });
  }

  // get isTableReadOnly() {
  //   return this.isReadOnlyView || this.backgroundUploadInProgress;
  // }

  setWidthofContent() {
    setTimeout(() => {
      const table = document.getElementById('marketScanTable');
      if (table && table.scrollWidth) {
        //this.scrollWidth = table.scrollWidth;
        this.contentWidth = table.clientWidth;
      }
      this._cd.detectChanges();
    }, 400);
  }

  //async paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    // if (event.pageCount == 1) return;
    // const from: number = (event.page * event.rows);
    // let to: number = (event.page + 1) * event.rows;
    // if (event.pageCount == event.page + 1 && to > this.visibleRowsLength) {
    //   if (this.selectedMarketScan.category.indskr_capturedatafor === CaptureDataFor.ACCOUNTS) {
    //     to = this.selectedMarketScan.accounts.filter(c => c.action != Action.DELETE).length;
    //   } else {
    //     to = this.selectedMarketScan.contacts.filter(c => c.action != Action.DELETE).length;
    //   }
    // }
    // // await this.uiService.displayLoader();
    // if (from == 0) {
    //   this.columns = this.marketScanService.getColumns(this.selectedMarketScan);
    //   this.subrows = this.marketScanService.getSubrows(this.selectedMarketScan);
    // }
    // const data = await this.marketScanService.constructTableData([], this.columns, this.subrows, from, to);
    // this.rows = data.rows;
    // // await this.uiService.dismissLoader();
    // const tableElement = document.getElementById("marketScanTable");
    // if (tableElement && this.lastScroll > 624) {
    //   tableElement.scrollIntoView();
    // }
  //}

  // ngAfterViewInit() {
  //   if (this.content) {
  //     this.content.scrollEvents = true;
  //     this.content.ionScrollEnd.subscribe(event => {
  //       this.content.getScrollElement().then(contentElement => {
  //         this.lastScroll = contentElement.scrollTop;
  //         this.setUpPaginator();
  //       });
  //     });
  //   }
  // }

  // private setUpPaginator() {
  //   const tableElement = document.getElementById("marketScanTable");
  //   if (!tableElement || this.visibleRowsLength <= 10) {
  //     this.showPaginator = false;
  //     return;
  //   }
  //   if (window.innerHeight > 875)
  //     this.showPaginator = true;
  //   else if (window.innerHeight + this.lastScroll > 875)
  //     this.showPaginator = true;
  //   else
  //     this.showPaginator = false;
  // }

  ngDoCheck(): void {
    if (this.visibleRowsLength > 10 && window.innerHeight > 875 && !this.showPaginator) this.showPaginator = true;
  }

  ngOnDestroy() {
    this.events.unsubscribe("marketScan:footerEvents", this.footerHandler);
    if (this.IcurrentMarketScanSubscription)
      this.IcurrentMarketScanSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    const startDate: string = this.selectedMarketScan.indskr_date ? this.selectedMarketScan.indskr_date : new Date().getTime().toString();
    const selectedMarketScanStartDate = format(new Date(parseInt(startDate)));
    const endDate: string = this.selectedMarketScan.indskr_enddate ? this.selectedMarketScan.indskr_enddate : this.marketScanService.getEndDateAsPerConf(new Date(selectedMarketScanStartDate)).getTime().toString();
    const selectedMarketScanEndDate = format(new Date(parseInt(endDate)));

    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.MarketScan,
          startDateTimeValue: selectedMarketScanStartDate,
          endDateTimeValue: selectedMarketScanEndDate
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime != '') {
        this.marketScanDate = data.data.startTime;
        this.updateMarketScanDate(true);
      }
      this.isClickedDate = true;
    });
    popover.present();
  }

  private async handleSelectView(data) {

    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'marketscan-view-select',
      data: [
        {
          title: this.translate.instant('ACCOUNT_VIEW'),
          id: 'ACCOUNT_VIEW',
          isSelected: this.selectedMarketScan.selectView == this.translate.instant('ACCOUNT_VIEW'),
        },
        {
          title: this.translate.instant('CUSTOMER_VIEW'),
          id: 'CUSTOMER_VIEW',
          isSelected: this.selectedMarketScan.selectView == this.translate.instant('CUSTOMER_VIEW'),
        }
      ],
    };

    let selectViewPopover: any = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
    selectViewPopover.onDidDismiss().then(async (data) => {
      data = data.data;
      this.isClickedSelectView = true;
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.selectedMarketScan.selectView) {
        this.selectedMarketScan.selectView = data.selectedItems[0].id;
        await this._resetAccountsAndCustomersDataAfterViewSelect();
      }
      selectViewPopover = undefined;
    })
    selectViewPopover.present();
  }

  private handleScanExpandButton(selectedItemId:string = ''){
    this.navService.pushWithPageTracking(MarketScanEditPageComponent, PageName.MarketScanEditPageComponent, { from: 'MarketScanDetailsPage', currentMarketScan: this.selectedMarketScan, selectedItemId: selectedItemId }, PageName.MarketScanDetailsComponent);
  }

  private async handleScanRefreshButton(){
    if(this.selectedMarketScan.selectView){
      if(this.selectedMarketScan.selectView == 'ACCOUNT_VIEW'){
        let selectedItems = this.selectedMarketScan.accounts.filter(a => a.action != 'DELETE').map(acc => {
          return {
            id: acc.accountId,
            name: acc.accountName,
            sequence: acc['sequence']
          }
        });
        const data = { selectedItems: selectedItems, isDone: true };
        this._handleAccountComponentCallback(data);
      }else if(this.selectedMarketScan.selectView == 'CUSTOMER_VIEW'){
        this.uiService.displayLoader();
        let marketScanSelectedContacts: MarketScanContact[] = [];
        let curSelContacts = this.selectedMarketScan.contacts.filter(contact => contact.action != 'DELETE');
        for (let l=0; l < curSelContacts.length; ++l){
          let contact = this.contactService.getContactByID(curSelContacts[l].contactId);
          if(contact){
            const marketScanSelectedAccounts: MarketScanAccount[] = [];
            let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([contact]);
              if(affiliatedAccounts && affiliatedAccounts.length > 0){
                //affiliatedAccounts.forEach(item => {
                for (let m=0;m<affiliatedAccounts.length;++m){
                  let item = affiliatedAccounts[m];
                  if(item && item.raw['indskr_accounttype'] === 100000001 && !marketScanSelectedAccounts.some(a=> a.accountId == item.id)){
                    marketScanSelectedAccounts.push(new MarketScanAccount(item.accountName, item.id, Action.PATCH))
                  }
                }
              }
            marketScanSelectedContacts.push(new MarketScanContact(contact.fullName,contact.ID, Action.PATCH, contact['sequence'],true,marketScanSelectedAccounts));
          }
        }
        if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
          this.selectedMarketScan.isModified = true;
        }
        this.marketScanService.unMapReMapContacts(marketScanSelectedContacts, this.selectedMarketScan);
        this.marketScanService.mapContactsToMarketScan(this.selectedMarketScan, marketScanSelectedContacts);
        this.uiService.dismissLoader();
      }
    }
  }

  private async _resetAccountsAndCustomersDataAfterViewSelect (){
    if(this.selectedMarketScan.selectView == 'CUSTOMER_VIEW'){
      await this.marketScanService.mapFilteredDataToSelectedMarketScan(this.selectedMarketScan, FilterType.PRODUCT);
      let allAffiliatedCustomers:Array<MarketScanContact> = [];
      //this.selectedMarketScan.accounts.forEach(async selAcc => {
      for (let l=0;l<this.selectedMarketScan.accounts.length;++l){
        let selAcc = this.selectedMarketScan.accounts[l];
        if(selAcc && selAcc.affiliatedContacts && selAcc.affiliatedContacts.length > 0){
          //selAcc.affiliatedContacts.forEach(async affCon => {
          for (let m=0;m<selAcc.affiliatedContacts.length;++m){
            let affCon = selAcc.affiliatedContacts[m];
            if(affCon){
              if(this.isReadOnlyView){
                let idx  = allAffiliatedCustomers.findIndex(a=> a.contactId == affCon.contactId)
                if(idx >= 0){
                  let newidx = allAffiliatedCustomers[idx].affiliatedAccounts.findIndex(b => b.accountId == selAcc.accountId);
                  if(newidx < 0 ){
                    allAffiliatedCustomers[idx].affiliatedAccounts.push(new MarketScanAccount(selAcc.accountName,selAcc.accountId,Action.PATCH));
                  }
                }else{
                  allAffiliatedCustomers.push(new MarketScanContact(affCon.contact_fullName,affCon.contactId,Action.PATCH,affCon.sequence,affCon.isActive,[new MarketScanAccount(selAcc.accountName,selAcc.accountId,Action.PATCH)]));
                }
              }else{
                const marketScanNewAccounts: MarketScanAccount[] = [];
                let foundContact = this.contactService.getContactByID(affCon.contactId);
                const contact = foundContact ? foundContact : new Contact({contactid: affCon.contactId, firstname: affCon.contact_fullName})
                let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([contact]);
                  if(affiliatedAccounts && affiliatedAccounts.length > 0){
                    affiliatedAccounts.forEach(item => {
                      if(item.raw['indskr_accounttype'] === 100000001 && !marketScanNewAccounts.some(a=> a.accountId == item.id)){
                        marketScanNewAccounts.push(new MarketScanAccount(item.accountName, item.id, Action.PATCH))
                      }
                    });
                  }
                let idx  = allAffiliatedCustomers.findIndex(a=> a.contactId == affCon.contactId)
                if(idx < 0){
                  allAffiliatedCustomers.push(new MarketScanContact(contact.fullName,contact.ID, Action.PATCH, contact['sequence'],true,marketScanNewAccounts))
                }
              }
            }
          }
        }
      }
      this.marketScanService.unMapReMapContacts(allAffiliatedCustomers, this.selectedMarketScan);
      let nowSelectedAccounts:Array<MarketScanAccount> = [];
      this.marketScanService.mapAccountsToMarketScan(this.selectedMarketScan, nowSelectedAccounts);
      this.marketScanService.mapContactsToMarketScan(this.selectedMarketScan, allAffiliatedCustomers);
    }else if(this.selectedMarketScan.selectView == 'ACCOUNT_VIEW'){
      let allAffiliatedAccounts:Array<MarketScanAccount> = [];
      //this.selectedMarketScan.contacts.forEach(async selCon => {
      for (let n=0;n<this.selectedMarketScan.contacts.length;++n){
        let selCon = this.selectedMarketScan.contacts[n];
        if(selCon && selCon.affiliatedAccounts && selCon.affiliatedAccounts.length > 0){
          //selCon.affiliatedAccounts.forEach(async affAcc => {
          for (let o=0;o<selCon.affiliatedAccounts.length;++o){
            let affAcc = selCon.affiliatedAccounts[o];
            if(affAcc){
              if(this.isReadOnlyView){
                let idx  = allAffiliatedAccounts.findIndex(a=> a.accountId == affAcc.accountId)
                if(idx >= 0){
                  let newidx = allAffiliatedAccounts[idx].affiliatedContacts.findIndex(b => b.contactId == selCon.contactId);
                  if(newidx < 0 ){
                    allAffiliatedAccounts[idx].affiliatedContacts.push(new MarketScanContact(selCon.contact_fullName,selCon.contactId,Action.PATCH));
                  }
                }else{
                  allAffiliatedAccounts.push(new MarketScanAccount(affAcc.accountName,affAcc.accountId,Action.PATCH,affAcc.sequence,affAcc.isActive,[new MarketScanContact(selCon.contact_fullName,selCon.contactId,Action.PATCH)]));
                }
              }else{
                const marketScanNewContacts: MarketScanContact[] = [];
                let foundAccount = this.accountService.getAccountById(affAcc.accountId);
                if (foundAccount) {
                  let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([foundAccount]);
                  if (affiliatedContacts && affiliatedContacts.length > 0) {
                    affiliatedContacts.forEach(item => {
                      if (item.isActive) {
                        marketScanNewContacts.push(new MarketScanContact(item.fullName, item.ID, Action.PATCH))
                      }
                    });
                  }
                  let idx = allAffiliatedAccounts.findIndex(a => a.accountId == affAcc.accountId)
                  if (idx < 0) {
                    allAffiliatedAccounts.push(new MarketScanAccount(foundAccount.accountName, foundAccount.id, Action.PATCH, 1, true, marketScanNewContacts))
                  }
                }
              }
            }
          }
        }
      }
      let nowSelectedContacts:Array<MarketScanContact> = [];
      this.marketScanService.unMapReMapContacts(nowSelectedContacts, this.selectedMarketScan);
      this.marketScanService.mapContactsToMarketScan(this.selectedMarketScan, nowSelectedContacts);
      this.marketScanService.mapAccountsToMarketScan(this.selectedMarketScan, allAffiliatedAccounts);
    }
  }

  public openScanItemDetails(item:MarketScanDetailsScreenColumnItemModel){
    if(item && item.columnItemId){
      this.handleScanExpandButton(item.columnItemId);
    }
  }

  private setUpPaginator() {
    this.setWidthofContent()
    let pages = this.getPage();
    const tableElement = document.getElementById("marketScanTable");
    if (!tableElement || this.visibleRowsLength <= 10) {
      this.matrixScanData = cloneDeep(this.masterMatrixScanData);
      this.showPaginator = false;
    }else{
      this.matrixScanData = cloneDeep(this.masterMatrixScanData);
    this.matrixScanData.matrixColumns.forEach(col => {
      //let firstColItem = col.columnItems[0];
      col.columnItems = col.columnItems.slice(pages.from, pages.to);
      // if (pages.from != 0) {
      //   col.columnItems.unshift(firstColItem);
      // }
    })
      this.showPaginator = true;
    }
  }

  private getPage(): { from: number, to: number } {
    let from: number = 0;
    let to: number = 10;
    this.visibleRowsLength = this.masterMatrixScanData.matrixColumns[0].columnItems.length;
    if (this.visibleRowsLength > 10 && this.paginator) {
      if (this.selectedMarketScan.retainPage) {
        from = (this.paginator.getPage() * this.paginator.rows);
        to = (this.paginator.getPage() + 1) * this.paginator.rows;
        if (this.paginator.getPage() == this.paginator.getPage() + 1 && to > this.visibleRowsLength) {
          to = this.visibleRowsLength;
        }
      } else {
        this.paginator.changePage(0);
      }
    }
    return { from: from, to: to };
  }

  async paginate(event) {
    // event.first = Index of the first record
    // event.rows = Number of rows to display in new page
    // event.page = Index of the new page
    // event.pageCount = Total number of pages
    if (event.pageCount == 1) return;
    const from: number = (event.page * event.rows);
    let to: number = (event.page + 1) * event.rows;
    if (event.pageCount == event.page + 1 && to > this.visibleRowsLength) {
      to = this.masterMatrixScanData.matrixColumns[0].columnItems.length;
    }
    this.matrixScanData = cloneDeep(this.masterMatrixScanData);
    this.matrixScanData.matrixColumns.forEach(col => {
      //let firstColItem = col.columnItems[0];
      col.columnItems = col.columnItems.slice(from, to);
      // if (from != 0) {
      //   col.columnItems.unshift(firstColItem);
      // }
    })
  }

  getCompetitorField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COMPETITORS'),
      inputText: this.marketScanService.displayText(this.selectedMarketScan.competitors, 'competitorName'),
      customPlaceholderLabel: ((this.isReadOnlyView || this.opportunityService.opportunityCompetitors.length == 0) ? this.translate.instant('NO_COMPETITORS') : this.translate.instant('SELECT_COMPETITORS')),
      id: 'competitors-field',
      isReadOnly: true,
      isRequired: !this.isReadOnlyView,
      showArrow: !(this.isReadOnlyView || _.isEmpty(this.opportunityService.opportunityCompetitors)),
      isDisabled: (this.isReadOnlyView && !this.selectedMarketScan.competitors) || _.isEmpty(this.opportunityService.opportunityCompetitors),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCompetitor && _.isEmpty(this.selectedMarketScan.competitors),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData
  }

  private openCompetitorsSelect(): void {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('COMPETITORS'),
      dividerTitle: this.translate.instant('ALL_COMPETITORS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: (!this.isReadOnlyView && this.opportunityService.opportunityCompetitors),
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('COMPETITORS'),
      hideAllItemsList: this.isReadOnlyView,
      isListSelectionEnabled: !this.isReadOnlyView,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleCompetitorComponentEvent(data, eventTarget, refData, 'competitors'),
      searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'competitor'),
      data: this.opportunityService.opportunityCompetitors.map(c => {
        let isSelected = false;
        if (this.selectedMarketScan && this.selectedMarketScan.competitors && this.selectedMarketScan.competitors.some(oco => (oco.competitorId === c.id && oco.action !== Action.DELETE))) {
          isSelected = true;
        }
        return {
          id: c.id,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: c.name,
          showArrow: false,
          arrowType: '',
        };
      }),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
    this.isClickedCompetitor = true;
  }

  private async _handleCompetitorComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (data && data.isDone) {
        console.log(data, eventTarget, refData, fieldName)
        const marketScanSelectedCompetitors: MarketScanCompetitor[] = [];
        if (data.selectedItems && data.selectedItems.length) {
          data.selectedItems.forEach(competitor => {
            marketScanSelectedCompetitors.push(new MarketScanCompetitor(competitor.primaryTextRight, competitor.id, Action.PATCH))
          });
        }
        await this.uiService.displayLoader();
        if (this.selectedMarketScan.indskr_marketscanid || this.selectedMarketScan.pendingPushToDynamics) {
          this.selectedMarketScan.isModified = true;
        }
        this.marketScanService.unMapReMapCompetitors(marketScanSelectedCompetitors, this.selectedMarketScan);
        this.marketScanService.mapCompetitorToMarketScan(this.selectedMarketScan, marketScanSelectedCompetitors);
        this.uiService.dismissLoader();
      }
    }
  }

  private _handleProductsComponentSearch(text: string, fieldName: string): string[] {
    let ids;
    switch (fieldName) {
      case 'competitor':
        if (text.length >= 1) {
          ids = this.opportunityService.opportunityCompetitors && this.opportunityService.opportunityCompetitors.filter(com => {
            return com.name.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(com => com.id);
        } else {
          ids = this.opportunityService.opportunityCompetitors && this.opportunityService.opportunityCompetitors.map(com => com.id);
        }
        break;
      case 'procedure':
        if (text.length >= 1) {
          ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.filter(pro => {
            return pro.surgeryName.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(pro => pro.surgeryId);
        } else {
          ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.map(pro => pro.surgeryId);
        }
        break;
      default:
        ids = [];
        break;
    }
    return ids;
  }

  public getProcedureFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE'),
      inputText: this.marketScanService.displayText(this.selectedMarketScan.products, 'productName'),
      customPlaceholderLabel: ((this.isReadOnlyView || this.selectedMarketScan.products.length == 0) ? this.translate.instant('NO_PROCEDURE') : this.translate.instant('SELECT_PROCEDURE')),
      id: 'procedure-field',
      isReadOnly: true,
      showArrow: !(this.isReadOnlyView || _.isEmpty(this.surgeryOrderDataService.productHierarchies)),
      isRequired: !this.isReadOnlyView,
      isDisabled: (this.isReadOnlyView && !this.selectedMarketScan.products) || _.isEmpty(this.surgeryOrderDataService.productHierarchies),
      errorMessage: !this.isReadOnlyView ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedProcrdure && _.isEmpty(this.selectedMarketScan.products),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private openProcedureSelect(): void {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PROCEDURE'),
      dividerTitle: this.translate.instant('ALL_PROCEDURE'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('PROCEDURE'),
      hideAllItemsList: this.isReadOnlyView,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProducts(data),
      searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'procedure'),
      data: this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.map(pro => {
        let isSelected = false;
        if (this.selectedMarketScan && this.selectedMarketScan.products && this.selectedMarketScan.products.some(oco => (oco.productId === pro.surgeryId && oco.action !== Action.DELETE))) {
          isSelected = true;
        }
        return {
          id: pro.surgeryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyView,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: pro.surgeryName,
          showArrow: false,
          arrowType: '',
        };
      }),
    }
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
    this.isClickedProcrdure = true;
  }

}
