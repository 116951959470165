import { isYesterday } from 'date-fns';
import { filter, debounceTime, map } from 'rxjs/operators';
import { DefaultChartOptions } from './../../../../config/edge-analytics/report.config';
import { IndReportCardTableData, IndReportCardTableRowData } from './../../../../../interfaces/edge-analytics/ind-report-card-table.interface';
import { IndReportCardCompBaseData } from './../../../../../interfaces/edge-analytics/ind-report-card-base.interface';
import { SurgeryOrderReportTiles, SurgeryOrderReportTilePerRow, SurgeryOrderReportCards, SurgeryOrderCardSegments, ProcedureTrendChartFilterOptions, SurgeryOrderChartTitles } from './../../../../config/edge-analytics/surgery-order-report/surgery-order-report.config';
import { ProcedureKPI, ProcedureCardKpiId, ProcedureChartId } from './../../../../enums/edge-analytics/surgery-order/surgery-order-report.enum';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { MeasureConfig, KPIDataBlock, MeasuresChartData, ChartFilterOption, xToDateOption } from './../../../../../interfaces/edge-analytics/report.interface';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderMeasureData } from '../../../../../interfaces/edge-analytics/surgery-order-report.interface';
import { MeasureType } from '../../../../enums/edge-analytics/edge-analytics.enum';
import { unsubscribeSubscriptionArray } from '../../../../utility/common.utility';
import { addLoaderCount, applyConfigToKPIMap, generateChart, generateReportGroupTiles, setChartFilter, subLoaderCount, updateHighChartOption, updateHighChartSeries } from '../report.functions';
import { orderBy } from 'lodash';
import * as moment from 'moment';
import _ from 'lodash';

/** ----------------------------------------------------------------------------------------
 *  Initialize base measure data
 */
export function procedureGenerateMeasureData(surgeryOrders: SurgeryOrderActivity[], userId: string, config?: MeasureConfig[]): SurgeryOrderMeasureData {
  let surgeryOrderMeasureData: SurgeryOrderMeasureData;

  if (Array.isArray(surgeryOrders) && userId) {
    // Initial data setup
    // We don't need yearMonth for surgery order measure, but empty one is needed as it's required 
    const _dateFilterOptions$: BehaviorSubject<xToDateOption[]> = new BehaviorSubject([
      {
        text: 'MTD',
        value: 'mtd',
      },
      {
        text: 'YTD',
        value: 'ytd'
      },
    ]);
    const _selectedDateFilters$: BehaviorSubject<string[]> = new BehaviorSubject(['mtd']);

    const _allOrders$: BehaviorSubject<any[]> = new BehaviorSubject(surgeryOrders);

    const listCardSubtextValue$: BehaviorSubject<string> = new BehaviorSubject('0');
    const listCardSubtextLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);


    // KPI data behavior subjects & observables
    const _customerReachYtd$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _completedYtd$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _completedMtd$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _completedWtd$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _completedYesterday$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _myContacts$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _myAccounts$: BehaviorSubject<any> = new BehaviorSubject(0);
    const _myCustomersCardData$: BehaviorSubject<any> = new BehaviorSubject(null);
    const _byContactCardData$: BehaviorSubject<any> = new BehaviorSubject(null);
    const _byAccountCardData$: BehaviorSubject<any> = new BehaviorSubject(null);
    const _bySurgeryChartData$: BehaviorSubject<any> = new BehaviorSubject(null);
    const _weeklyTrendChartData$: BehaviorSubject<any> = new BehaviorSubject(null);

    // KPI data observables map
    const kpiMap: Map<string, KPIDataBlock> = new Map();

    kpiMap.set(ProcedureKPI.customerReach, {
      id: ProcedureKPI.customerReach,
      data$: _customerReachYtd$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(false),
    });
    kpiMap.set(ProcedureKPI.ytd, {
      id: ProcedureKPI.ytd,
      data$: _completedYtd$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(false),
    });
    kpiMap.set(ProcedureKPI.mtd, {
      id: ProcedureKPI.mtd,
      data$: _completedMtd$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(false),
    });
    kpiMap.set(ProcedureKPI.wtd, {
      id: ProcedureKPI.wtd,
      data$: _completedWtd$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(false),
    });
    kpiMap.set(ProcedureKPI.yesterday, {
      id: ProcedureKPI.yesterday,
      data$: _completedYesterday$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(false),
    });
    kpiMap.set(ProcedureKPI.contacts, {
      id: ProcedureKPI.contacts,
      data$: _myContacts$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(true),
    });
    kpiMap.set(ProcedureKPI.accounts, {
      id: ProcedureKPI.accounts,
      data$: _myAccounts$.asObservable(),
      enabled: true,
      isDataLoading$: new BehaviorSubject(true),
    });


    // For charts


    surgeryOrderMeasureData = {
      dataStream: {
        _allOrders$,
        allOrders$: _allOrders$.asObservable(),
        _customerReachYtd$,
        _completedYtd$,
        _completedMtd$,
        _completedWtd$,
        _completedYesterday$,
        _myContacts$,
        _myAccounts$,
        _myCustomersCardData$,
        _byContactCardData$,
        _byAccountCardData$,
        _bySurgeryChartData$,
        _weeklyTrendChartData$,
      },

      // KPI data observables map
      kpiMap,

      sectionHeaderConfigs: {
        'top': {
          id: 'edge-analytics-detail-section-header',
          title: '',
          rightLabelTxt: '',
          isFilter: false,
          isFilterStyleHeading: true,
          showArrow: false,
          arrowType: 'caret-down-outline',
          controls: [],
          hasRightLabelTxt: true,
          visible: true,
        },
        'card': {
          id: 'edge-analytics-detail-card-section-header',
          title: '',
          rightLabelTxt: '',
          isFilter: true,
          isFilterStyleHeading: true,
          showArrow: true,
          arrowType: 'caret-down-outline',
          controls: [],
          hasRightLabelTxt: false,
          visible: true,
        }
      },

      dateFilterOptionType: 'xToDate',
      dateFilterMultiSelect: false,
      _dateFilterOptions$,
      dateFilterOptions$: _dateFilterOptions$.asObservable(),
      _selectedDateFilters$,
      selectedDateFilters$: _selectedDateFilters$.asObservable(),
      listCardSubtextValue$,
      listCardSubtextLoading$,
      measureType: MeasureType.procedure,
      isFilterDirty: false,
      lastUpdatedDate: null,
      dataStartDate: null,
      subs: [],
      tileGroups: [],
      charts: [],
      sortOrder: 4,
      isTileDataLoading$: new BehaviorSubject(0),
    };

    // Apply configuration
    applyConfigToKPIMap(kpiMap, config);

    // Init charts data
    _initChartsData(surgeryOrderMeasureData);
  } else {
    console.warn('procedureGenerateMeasureData: Invalid / missing input: ', );
  }

  return surgeryOrderMeasureData;
}


/** ----------------------------------------------------------------------------------------
 *  Update measure data after delta sync
 */
export function procedureUpdateMeasureData(measureData: SurgeryOrderMeasureData, surgeryOrders: SurgeryOrderActivity[], userId: string, config: MeasureConfig[]) {
  if (measureData && Array.isArray(surgeryOrders) && userId) {
    measureData.dataStream._allOrders$.next(surgeryOrders);
    applyConfigToKPIMap(measureData.kpiMap, config);

    if (Array.isArray(measureData.subs) && measureData.subs.length === 0) {
      // Since there's no data stream subscription, do manual initialization with new data
      measureData._selectedDateFilters$.next(['mtd']);
    } else if (Array.isArray(measureData.subs) && measureData.subs.length > 0) {
      // Already on detail page. Reset necessary stuff
      _generateReportGroupTiles(measureData);
    }

    measureData.listCardSubtextLoading$.next(true);
    _getMtd(surgeryOrders).then(newValue => {
      // Update list card text
      measureData.listCardSubtextValue$.next('' + newValue);
      measureData.listCardSubtextLoading$.next(false);

      if (Array.isArray(measureData.subs) && measureData.subs.length === 0) {
        measureData.dataStream._completedMtd$.next(newValue);
      }
    }).catch(e => console.error('procedureUpdateMeasureData: _getMtd: ', e));
  } else {
    console.warn('procedureUpdateMeasureData: Invalid input: ', measureData, surgeryOrders, userId);
  }

  return measureData;
}

export function procedureUpdateTotalAccountContactCount(measureData: SurgeryOrderMeasureData, type: 'account' | 'contact', count: number) {
  if (measureData && type && count) {
    if (type === 'contact') {
      measureData.dataStream._myContacts$.next(count ?? 0);
      const kpiData = measureData.kpiMap.get(ProcedureKPI.contacts);
      kpiData?.isDataLoading$?.next(false);
      subLoaderCount(measureData.isTileDataLoading$);
    } else if (type === 'account') {
      measureData.dataStream._myAccounts$.next(count ?? 0);
      const kpiData = measureData.kpiMap.get(ProcedureKPI.accounts);
      kpiData?.isDataLoading$?.next(false);
      subLoaderCount(measureData.isTileDataLoading$);
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  Subscribe to measure data
 */
export function procedureSubscribeToMeasureData(measureData: SurgeryOrderMeasureData, userId: string, chartFilterOptionData: ChartFilterOption[], forceReSubscribe = false) {
  if (measureData && userId) {
    // Reset filters if necessary
    procedureResetFilters(measureData, userId);

    // Reset loader just in case..
    measureData.isTileDataLoading$.next(0);

    // Unsubscribe previous subscriptions if necessary
    if (forceReSubscribe && Array.isArray(measureData.subs)) {
      unsubscribeSubscriptionArray(measureData.subs);
      measureData.subs = [];
    }

    // Subscribe
    if (measureData.dataStream && measureData.subs.length === 0) {
      measureData.subs.push(
        measureData.dataStream.allOrders$.pipe(
          filter((allOrders) => Array.isArray(allOrders)),
          debounceTime(0),
          map((allOrders) => {
            const customerReachYtdKpiData = measureData.kpiMap.get(ProcedureKPI.customerReach);
            customerReachYtdKpiData.isDataLoading$.next(true);
            addLoaderCount(measureData.isTileDataLoading$);
            _getCustomerReachYtd(allOrders).then(newValue => {
              customerReachYtdKpiData.isDataLoading$.next(false);
              measureData.dataStream._customerReachYtd$.next(newValue);
              subLoaderCount(measureData.isTileDataLoading$);
            }).catch(e => subLoaderCount(measureData.isTileDataLoading$));

            const completedYtdKpiData = measureData.kpiMap.get(ProcedureKPI.ytd);
            completedYtdKpiData.isDataLoading$.next(true);
            addLoaderCount(measureData.isTileDataLoading$);
            _getYtd(allOrders).then(newValue => {
              completedYtdKpiData.isDataLoading$.next(false);
              measureData.dataStream._completedYtd$.next(newValue);
              subLoaderCount(measureData.isTileDataLoading$);
            }).catch(e => subLoaderCount(measureData.isTileDataLoading$));
 
            const completedMtdKpiData = measureData.kpiMap.get(ProcedureKPI.mtd);
            completedMtdKpiData.isDataLoading$.next(true);
            addLoaderCount(measureData.isTileDataLoading$);
            _getMtd(allOrders).then(newValue => {
              completedMtdKpiData.isDataLoading$.next(false);
              measureData.dataStream._completedMtd$.next(newValue);
              subLoaderCount(measureData.isTileDataLoading$);
            }).catch(e => subLoaderCount(measureData.isTileDataLoading$));

            const completedWtdKpiData = measureData.kpiMap.get(ProcedureKPI.wtd);
            completedWtdKpiData.isDataLoading$.next(true);
            addLoaderCount(measureData.isTileDataLoading$);
            _getWtd(allOrders).then(newValue => {
              completedWtdKpiData.isDataLoading$.next(false);
              measureData.dataStream._completedWtd$.next(newValue);
              subLoaderCount(measureData.isTileDataLoading$);
            }).catch(e => subLoaderCount(measureData.isTileDataLoading$));

            const completedYesterdayKpiData = measureData.kpiMap.get(ProcedureKPI.yesterday);
            completedYesterdayKpiData.isDataLoading$.next(true);
            addLoaderCount(measureData.isTileDataLoading$);
            _getYesterday(allOrders).then(newValue => {
              completedYesterdayKpiData.isDataLoading$.next(false);
              measureData.dataStream._completedYesterday$.next(newValue);
              subLoaderCount(measureData.isTileDataLoading$);
            }).catch(e => subLoaderCount(measureData.isTileDataLoading$));
          })
        ).subscribe()
      );

      measureData.subs.push(
        combineLatest([measureData.dataStream.allOrders$, measureData.selectedDateFilters$]).pipe(
          filter(([allOrders, selectedDateFilters]) => Array.isArray(allOrders) && Array.isArray(selectedDateFilters)),
          debounceTime(0),
          map(([allOrders, selectedDateFilters]) => {
            if (Array.isArray(allOrders)) {
              _getByContactData(measureData, allOrders, selectedDateFilters[0]);
              _getByAccountData(measureData, allOrders, selectedDateFilters[0]);
            }
          })
        ).subscribe()
      );

      // Generate tile groups
      _generateReportGroupTiles(measureData);

      // Generate cards
      _generateReportCards(measureData);

      // Set chart filter options
      _setChartFilters(measureData, chartFilterOptionData, [{ measure: '', kpi: ProcedureKPI.completedTrend }]);

      // By surgery chart data subscriptions
      _bySurgeryChartDataSubscription(measureData);

      // Trend chart data subscriptions
      _trendChartDataSubscription(measureData);
    } else {
      // Re selected from other measure detail
      const _selectedDateFilters = ['mtd'];
      measureData._selectedDateFilters$.next(_selectedDateFilters);
    }
  } else {
    console.error('procedureSubscribeToMeasureData: Invalid input: ', measureData, userId);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Reset date & measure filters
 */
export function procedureResetFilters(measureData: SurgeryOrderMeasureData, userId: string, config?) {
  if (measureData && userId && measureData.isFilterDirty) {
    const _selectedDateFilters = ['mtd'];
    measureData._selectedDateFilters$.next(_selectedDateFilters);

    measureData.isFilterDirty = false;
  }
}


/** ----------------------------------------------------------------------------------------
 *  Measure data prep & calculation helper functions
 */
async function _getCustomerReachYtd(allOrders: SurgeryOrderActivity[]): Promise<number> {
  let customerReachYtd: number;
  const uniqContacts = allOrders.reduce((acc, cur) => {
    if (Array.isArray(cur.customers)) {
      for (let i = 0; i < cur.customers.length; i++) {
        const contact = cur.customers[i];
        if (!acc.some(id => id === contact.ID)) {
          acc.push(contact.ID);
        }  
      }
    }
    return acc;
  }, []);
  customerReachYtd = uniqContacts.length ?? 0;
  return customerReachYtd;
}
async function _getYtd(allOrders: SurgeryOrderActivity[]): Promise<number> {
  let ytd: number = allOrders.length ?? 0;
  return ytd;
}
async function _getMtd(allOrders: SurgeryOrderActivity[]): Promise<number> {
  let mtd: number;
  const thisMonth: number = new Date().getMonth() + 1;
  const monthFiltered = allOrders.filter(o => o.month === thisMonth);
  mtd = monthFiltered.length ?? 0;
  return mtd;
}
async function _getWtd(allOrders: SurgeryOrderActivity[]): Promise<number> {
  let wtd: number;
  const thisWeek: number = moment().week();
  const weekFiltered = allOrders.filter(o => o.weekOfYear === thisWeek);
  wtd = weekFiltered.length ?? 0;
  return wtd;
}
async function _getYesterday(allOrders: SurgeryOrderActivity[]): Promise<number> {
  let yesterday: number;
  const yesterdayFiltered = allOrders.filter(o => isYesterday(o.scheduledEnd));
  yesterday = yesterdayFiltered.length ?? 0;
  return yesterday;
}

function _getByContactData(measureData: SurgeryOrderMeasureData, orders: SurgeryOrderActivity[], selectedDateFilter: string) {
  if (measureData && Array.isArray(orders)) {
    const curMonth: number = new Date().getMonth() + 1;
    const reduced = orders.reduce((acc, cur) => {
      const skip = selectedDateFilter === 'mtd' && cur.month !== curMonth;

      if (!skip && Array.isArray(cur.customers)) {
        for (let i = 0; i < cur.customers.length; i++) {
          const customer = cur.customers[i];
          const idx = acc.findIndex(c => c.id == customer.ID);
          if (idx >= 0) {
            acc[idx]['count']++;
          } else {
            const data = { id: customer.ID, name: customer.fullname, count: 1 };
            acc.push(data);
          }  
        }
      }
      return acc;
    }, []);
    const sorted: { id: string, name: string, count: number }[] = orderBy(reduced, ['count'], ['desc']);

    let byContactCardData: IndReportCardTableData = measureData.dataStream._byContactCardData$.getValue();
    if (byContactCardData === null) {
      const tableHeadData: IndReportCardTableRowData = {
        class: 'table-head-row',
        cells: [
          {
            size: 10,
            label: "CUSTOMER",
          },
          {
            size: 2,
            label: '#',
            class: 'ion-text-end',
          }
        ],
      };
      byContactCardData = {
        tableHeadData,
        tableBodyData: null,
      };
    }

    byContactCardData.tableBodyData = [];

    for (let i = 0; i < sorted.length; i++) {
      const contactData = sorted[i];
      const tableBodyRow: IndReportCardTableRowData = {
        class: 'table-data-row',
        cells: [
          {
            size: 10,
            label: contactData.name,
          },
          {
            size: 2,
            label: '' + contactData.count,
            class: 'ion-text-end',
          },
        ],
      };

      byContactCardData.tableBodyData.push(tableBodyRow);
    }

    measureData.dataStream._byContactCardData$.next(byContactCardData);
  } else {
    console.warn('_getByContactData: Invalid input: ', measureData);
  }
}
function _getByAccountData(measureData: SurgeryOrderMeasureData, orders: SurgeryOrderActivity[], selectedDateFilter: string) {
  if (measureData && Array.isArray(orders)) {
    const curMonth: number = new Date().getMonth() + 1;
    const reduced = orders.reduce((acc, cur) => {
      const skip = selectedDateFilter === 'mtd' && cur.month !== curMonth;

      if (!skip && cur.accountId) {
        const idx = acc.findIndex(c => c.id === cur.accountId);
        if (idx >= 0) {
          acc[idx]['count']++;
        } else {
          const data = { id: cur.accountId, name: cur.accountNameString, count: 1 };
          acc.push(data);
        }
      }
      return acc;
    }, []);
    const sorted: { id: string, name: string, count: number }[] = orderBy(reduced, ['count'], ['desc']);

    let byAccountCardData: IndReportCardTableData = measureData.dataStream._byAccountCardData$.getValue();
    if (byAccountCardData === null) {
      const tableHeadData: IndReportCardTableRowData = {
        class: 'table-head-row',
        cells: [
          {
            size: 10,
            label: "ACCOUNT",
          },
          {
            size: 2,
            label: '#',
            class: 'ion-text-end',
          }
        ],
      };
      byAccountCardData = {
        tableHeadData,
        tableBodyData: null,
      };
    }

    byAccountCardData.tableBodyData = [];

    for (let i = 0; i < sorted.length; i++) {
      const contactData = sorted[i];
      const tableBodyRow: IndReportCardTableRowData = {
        class: 'table-data-row',
        cells: [
          {
            size: 10,
            label: contactData.name,
          },
          {
            size: 2,
            label: '' + contactData.count,
            class: 'ion-text-end',
          },
        ],
      };

      byAccountCardData.tableBodyData.push(tableBodyRow);
    }

    measureData.dataStream._byAccountCardData$.next(byAccountCardData);
  } else {
    console.warn('_getByAccountData: Invalid input: ', measureData);
  }
}


/** ----------------------------------------------------------------------------------------
 *  Report tile data connect & generation
 */
function _generateReportGroupTiles(measureData: SurgeryOrderMeasureData,
    reportTiles = JSON.parse(JSON.stringify(SurgeryOrderReportTiles)),
    reportTilesPerRow = SurgeryOrderReportTilePerRow) {
  generateReportGroupTiles(measureData, reportTiles, reportTilesPerRow, 'surgery-tile-group');
}


/** ----------------------------------------------------------------------------------------
 *  Report card data generation
 */
function _generateReportCards(measureData: SurgeryOrderMeasureData,
    reportCards = JSON.parse(JSON.stringify(SurgeryOrderReportCards)),
    cardSegments = JSON.parse(JSON.stringify(SurgeryOrderCardSegments))) {

  measureData.reportCards = reportCards;
  for (let i = 0; i < measureData.reportCards.length; i++) {
    const cardData: IndReportCardCompBaseData = measureData.reportCards[i];

    switch (cardData.id) {
      case ProcedureCardKpiId.myCustomers:
        cardData.cardBodyCompData = measureData.dataStream._myCustomersCardData$;
        break;
      case ProcedureCardKpiId.byContact:
        cardData.cardBodyCompData = measureData.dataStream._byContactCardData$.asObservable();
        cardData.segmentData = cardSegments[cardData.id];
        break;
      case ProcedureCardKpiId.byAccount:
        cardData.cardBodyCompData = measureData.dataStream._byAccountCardData$.asObservable();
        cardData.segmentData = cardSegments[cardData.id];
        break;

      default:
        console.warn('_generateReportCards: Invalid card data id: ', cardData);
        break;
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  Chart data initialization
 */
function _initChartsData(measureData: SurgeryOrderMeasureData) {
  if (measureData) {
    _initBySurgeryChartData(measureData);
    _initWeeklyTrendChartData(measureData);
  }
}
function _initBySurgeryChartData(measureData: SurgeryOrderMeasureData) {
  const _chartFilterOptions$ = new BehaviorSubject(null);
  const _yAxisCategories$ = new BehaviorSubject(null);
  const _chartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);
  // Trend chart type is line
  const trendChartOptions = JSON.parse(JSON.stringify(DefaultChartOptions));
  trendChartOptions.chart.type = 'pie';
  trendChartOptions['plotOptions']['series']['dataLabels'] = {
    enabled: true,
    format: '<b>{point.name}</b>: {point.y}'
  };
  trendChartOptions['plotOptions']['pie'] = {
    size: '60%'
  };

  const bySurgeryChartData: MeasuresChartData = {
    id: ProcedureChartId.bySurgery,
    title: 'By Surgery',
    titleKey: SurgeryOrderChartTitles.bySurgery,
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: null,
    _chartFilterOptions$,
    chartFilterOptions$: _chartFilterOptions$.asObservable(),
    _yAxisCategories$,
    xAxisCategories$: _yAxisCategories$.asObservable(),
    _chartUpdated$,
    chartUpdated$: _chartUpdated$.asObservable(),
    customChartOption: trendChartOptions,
    sortOrder: 0,
  };

  measureData.charts.push(bySurgeryChartData);
}
function _initWeeklyTrendChartData(measureData: SurgeryOrderMeasureData) {
  const _chartFilterOptions$ = new BehaviorSubject(null);
  const _xAxisCategories$ = new BehaviorSubject(null);
  const _chartUpdated$ = new BehaviorSubject(null);
  const _highChartRefReady$ = new BehaviorSubject(false);

  const weeklyTrendChartData: MeasuresChartData = {
    id: ProcedureChartId.trend,
    title: 'Trend',
    titleKey: SurgeryOrderChartTitles.trend,
    chart: undefined,
    highChartRef: undefined,
    _highChartRefReady$,
    highChartRefReady$: _highChartRefReady$.asObservable(),
    highChartSub: undefined,
    chartFilterTemplate: null,
    _chartFilterOptions$,
    chartFilterOptions$: _chartFilterOptions$.asObservable(),
    _xAxisCategories$,
    xAxisCategories$: _xAxisCategories$.asObservable(),
    _chartUpdated$,
    chartUpdated$: _chartUpdated$.asObservable(),
    sortOrder: 1
  };

  measureData.charts.push(weeklyTrendChartData);
}


/** ----------------------------------------------------------------------------------------
 *  Chart helper functions
 */
export function procedureGenerateCharts(measureData: SurgeryOrderMeasureData, isMobilePortrait: boolean, windowInnerWidth: number, windowInnerHeight?: number,) {
  if (measureData && Array.isArray(measureData.charts)) {
    for (let index = 0; index < measureData.charts.length; index++) {
      const chartData = measureData.charts[index];

      if (isMobilePortrait && chartData?.customChartOption?.chart?.type === 'pie') {
        chartData.customChartOption['plotOptions']['pie'] = {
          size: null
        };
      }

      chartData.chart = generateChart(  chartData._chartFilterOptions$.getValue(),
                                        chartData.title,
                                        isMobilePortrait,
                                        windowInnerWidth,
                                        windowInnerHeight,
                                        chartData._xAxisCategories$ && Array.isArray(chartData._xAxisCategories$.getValue())
                                          ? chartData._xAxisCategories$.getValue() : undefined,
                                        chartData.customChartOption ? chartData.customChartOption : undefined);

      if (chartData.chart && chartData.chart.ref$) {
        chartData.highChartSub = chartData.chart.ref$.subscribe(ref => {
          chartData.highChartRef = ref;
          chartData._highChartRefReady$.next(true);
        });
      }
    }
  }
}
function _setChartFilters(measureData: SurgeryOrderMeasureData, chartFilterOptionData: ChartFilterOption[], config: MeasureConfig[]) {
  if (measureData && Array.isArray(measureData.charts)) {
    for (let i = 0; i < measureData.charts.length; i++) {
      const chartData = measureData.charts[i];

      switch (chartData.id) {
        case ProcedureChartId.bySurgery:

          break;
        case ProcedureChartId.trend:
          setChartFilter(chartData, chartFilterOptionData, config);
          break;
        default:
          break;
      }
    }
  }
}


/** ----------------------------------------------------------------------------------------
 *  By surgery chart helper functions
 */


/** ----------------------------------------------------------------------------------------
 *  By surgery chart data subscription
 */
function _bySurgeryChartDataSubscription(measureData: SurgeryOrderMeasureData) {
  const chartData = measureData.charts.find(c => c.id === ProcedureChartId.bySurgery);
  if (chartData) {
    measureData.subs.push(
      combineLatest([chartData.highChartRefReady$, measureData.dataStream.allOrders$, measureData.selectedDateFilters$]).pipe(
        filter(([highChartRefReady, allOrders, selectedDateFilters]) => highChartRefReady && Array.isArray(allOrders) && Array.isArray(selectedDateFilters)),
        debounceTime(300),
        map(([highChartRefReady, allOrders, selectedDateFilters]) => {
          const reduced: ChartFilterOption[] = [];
          const selectedDateFilter = selectedDateFilters[0];
          const curMonth: number = new Date().getMonth() + 1;
          allOrders.reduce((acc, cur) => {
            const skip = selectedDateFilter === 'mtd' && cur.month !== curMonth;

            if (!skip && cur.surgeryId && cur.surgeryNameString) {
              const idx = acc.findIndex(s => s.id === cur.surgeryId);
              if (idx >= 0) {
                acc[idx]['data']++;
              } else {
                acc.push({
                  id: cur.surgeryId,
                  displayText: cur.surgeryNameString,
                  data: 1,
                  value: cur.surgeryId,
                  isSelected: true
                });
              }
            }
            return acc;
          }, reduced);
          const sorted: ChartFilterOption[] = orderBy(reduced, ['data'], ['desc']);

          if (chartData.highChartRef) {
            updateHighChartSeries(chartData.highChartRef, sorted);
            chartData._chartUpdated$.next(chartData.id);
          }
        })
      ).subscribe()
    );
  }
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart helper functions
 */
 function _getWeekLabel(week: number, langCode: string): string {
  const startOfWeek = moment().week(week).weekday(0);
  const endOfWeek = moment().week(week).weekday(6);
  const label = `W${week} (${_.capitalize(startOfWeek.locale(langCode).format('MMM D'))} - ${endOfWeek.format('D')})`;
  return label;
}
function _updateTrendChartData(measureData: SurgeryOrderMeasureData, orders: SurgeryOrderActivity[], chartFilterOption: ChartFilterOption) {
  const chartData = measureData.charts.find(c => c.id === ProcedureChartId.trend);
  if (chartData && Array.isArray(orders)) {
    const currentWeek: number = moment().week();
    const currentYear: number = new Date().getFullYear();
    let numberOfPastWeeksToRetrieve: number = 5;
    let minWeek: number = currentWeek - numberOfPastWeeksToRetrieve;
    if (currentWeek <= 5) {
      numberOfPastWeeksToRetrieve = currentWeek;
      minWeek = 1;
    }

    const xAxisCategories: string[] = [];
    const values: number[] = [];
    const langCode: string = measureData.langCode || 'en';
    for (let i = 0; i <= numberOfPastWeeksToRetrieve; i++) {
      xAxisCategories.unshift(_getWeekLabel(currentWeek - i, langCode));
      values.push(0);
    }

    orders.reduce((acc, cur) => {
      if (cur.year === currentYear) {
        if (cur.weekOfYear >= minWeek) {
          const idx = cur.weekOfYear - minWeek;
          if (!isNaN(acc[idx])) {
            acc[idx]++;
          }
        }
      }
      return acc;
    }, values);

    chartFilterOption.data = values;

    chartData._xAxisCategories$.next(xAxisCategories);
    return xAxisCategories;
  }
}
function _xAxisLabelFormatter() {
  let label: string = this.value;
  const len: number = label?.length;
  const firstSpaceIdx = label?.indexOf(' ') ?? -1;
  if (firstSpaceIdx >= 0 && len >= 0) {
    label = label.slice(0, firstSpaceIdx) + '<br/>' + label.slice(firstSpaceIdx, len);
  }
  return label;
}


/** ----------------------------------------------------------------------------------------
 *  Trend chart data subscription
 */
function _trendChartDataSubscription(measureData: SurgeryOrderMeasureData) {
  const chartData = measureData.charts.find(c => c.id === ProcedureChartId.trend);
  if (chartData) {
    measureData.subs.push(
      combineLatest([chartData.highChartRefReady$, chartData.chartFilterOptions$, measureData.dataStream.allOrders$]).pipe(
        filter(([highChartRefReady, chartFilterOptions, allOrders]) => highChartRefReady && Array.isArray(chartFilterOptions) && Array.isArray(allOrders)),
        debounceTime(300),
        map(([highChartRefReady, chartFilterOptions, allOrders]) => {
          const xAxisCategories = _updateTrendChartData(measureData, allOrders, chartFilterOptions[0]);

          if (xAxisCategories) {
            const xAxis = JSON.parse(JSON.stringify(DefaultChartOptions.xAxis));
            const yAxis = JSON.parse(JSON.stringify(DefaultChartOptions.yAxis));
            xAxis.categories = xAxisCategories;
            xAxis.labels.style.fontSize = '9px';
            xAxis.labels.formatter = _xAxisLabelFormatter;
            yAxis['allowDecimals'] = false;
            updateHighChartOption(chartData.highChartRef, { xAxis, yAxis }, false, true);
          }
          if (chartData.highChartRef) {
            updateHighChartSeries(chartData.highChartRef, chartFilterOptions);
            chartData._chartUpdated$.next(chartData.id);
          }
        })
      ).subscribe()
    );
  }
}

