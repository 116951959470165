import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { ADVERSE_EVENTS_PREFIX, CONTACT_US_PREFIX, SPECIAL_SITUATIONS_PREFIX } from '@omni/config/pharmacovigilance-report/pharmacovigilance-report.config';
import { POPUP_MODAL_CLASS_NAME } from '@omni/config/shared.config';
import { PharmacovigilanceDataService } from '@omni/data-services/pharmacovigilance-reporting/pharmacovigilance.data.service';
import { Pharmacovigilance, PharmacovigilanceCreateDTO, PharmacovigilanceCreateResponseDTO, PharmacovigilanceInfoModalData } from '@omni/interfaces/pharmacovigilance-reporting/pharmacovigilance.interface';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { PageViewMode, PharmacovigilanceService } from '@omni/services/pharmacovigilance-reporting/pharmacovigilance.service';
import { UIService } from '@omni/services/ui/ui.service';
import { shortMonthArray } from '@omni/utility/util';
import { format, isValid } from 'date-fns';
import { isDate } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PharmacovigilanceInfoModalComponent } from '../info-modal/pharmacovigilance-info-modal';

@Component({
  selector: 'pharmacovigilance-detail',
  templateUrl: 'pharmacovigilance-detail.html',
  styleUrls: ['pharmacovigilance-detail.scss'],
})
export class PharmacovigilanceDetail implements OnInit, OnDestroy {
  pageTitleViewData: IndPageTitleViewDataModel;
  recontactReportField: IndFormFieldViewDataModel;
  reporterNameField: IndFormFieldViewDataModel;
  phoneField: IndFormFieldViewDataModel;
  addressField: IndFormFieldViewDataModel;
  emailField: IndFormFieldViewDataModel;
  dateOfAwarenessField: IndFormFieldViewDataModel;
  acinoDrugTakenField: IndFormFieldViewDataModel;
  patientIdField: IndFormFieldViewDataModel;
  eventDescriptionField: IndFormFieldViewDataModel;

  @ViewChild('genericDatePicker', { static: true }) genericDatePicker;
  genericDatePickerValue: string | Date;
  genericDatePickerDisplayFormat;
  genericDatePickerPickerFormat;
  genericDatePickerMaxValue;
  shortMonth: string[] = shortMonthArray;

  private currentRecord: Pharmacovigilance;
  private createForm: PharmacovigilanceCreateDTO;
  private ngDestroy$: Subject<boolean> = new Subject();
  private isOffline: boolean = false;
  private viewMode: PageViewMode = 'ReadOnly';
  private isGenericDatePickerOpen: boolean = false;
  private genericPickerFormFieldId: string;
  private requiredFieldsEvaluationStatus: { [key: string]: { isEvaluated: boolean } } = {};
  private isInfoButtonsEnabled = false;
  private isFooterInitRun = false;
  private infoModal: HTMLIonModalElement;

  constructor(
    private pharmacovigilanceService: PharmacovigilanceService,
    private pharmacovigilanceDataService: PharmacovigilanceDataService,
    private device: DeviceService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private navService: NavigationService,
    private uiService: UIService,
    private popoverCtrl: PopoverController,
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private footerService: FooterService,
    private modalCtrl: ModalController,
    public translate: TranslateService,
  ) {}

  private getCurrentDataObj() {
    return this.viewMode === 'Create' ? this.createForm : this.currentRecord;
  }

  private setPageTitleViewData() {
    const controls: any[] = [];
    if (
      !this.uiService.isSplitView()
    ) {
      controls.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    if (this.viewMode === 'Create') {
      controls.push({
        id: 'cancel_report',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'right'
      });
      controls.push({
        id: 'submit_report',
        imgSrc: 'assets/imgs/header_send.svg',
        name: this.translate.instant('SUBMIT'),
        isDisabled: this.isOffline,
        align: 'right'
      });
    }
    this.pageTitleViewData = {
      id: 'detail_page_title',
      title: this.getHeaderTitle(),
      controls,
    };
  }
  private setFieldData() {
    const isReadOnly = this.viewMode === 'ReadOnly';
    const isCreateForm = this.viewMode === 'Create';
    const dataObj = isCreateForm ? this.createForm : this.currentRecord;
    this.recontactReportField = {
      id: 'recontact-report-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('SELECT_RECONTACT_REPORT') : '',
      label: this.translate.instant('RECONTACT_REPORT'),
      inputText: this.getRecontactReportFormattedText(dataObj),
      inputValue: dataObj?.indskr_recontactreport ?? false,
      isReadOnly: true,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.reporterNameField = {
      id: 'reporter-name-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_REPORTER_NAME') : '',
      label: this.translate.instant('REPORTER_NAME'),
      inputText: dataObj?.indskr_name ?? '',
      inputValue: dataObj?.indskr_name ?? '',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      isRequired: !isReadOnly,
      isEmptyRequiredField: !dataObj?.indskr_name && this.requiredFieldsEvaluationStatus['reporter-name-field']?.isEvaluated === true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.phoneField = {
      id: 'phone-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_PHONE_NUMBER') : '',
      label: this.translate.instant('PHONE'),
      inputText: dataObj?.indskr_phone ?? '',
      inputValue: dataObj?.indskr_phone ?? '',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.addressField = {
      id: 'address-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_ADDRESS') : '',
      label: this.translate.instant('ADDRESS'),
      inputText: dataObj?.indskr_address ?? '',
      inputValue: dataObj?.indskr_address ?? '',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.emailField = {
      id: 'email-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('CONSENT_ENTER_EMAIL') : '',
      label: this.translate.instant('EMAIL'),
      inputText: dataObj?.indskr_email ?? '',
      inputValue: dataObj?.indskr_email ?? '',
      inputType: 'email',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.dateOfAwarenessField = {
      id: 'date-of-awareness',
      isDateField: true,
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_DATE_OF_AWARENESS') : '',
      label: this.translate.instant('DATE_OF_AWARENESS'),
      inputText: this.getDateFormattedText(dataObj?.indskr_dateofawareness),
      isReadOnly: true,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.acinoDrugTakenField = {
      id: 'acino-drug-taken-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_ACINO_DRUG_TAKEN') : '',
      label: this.translate.instant('ACINO_DRUG_TAKEN'),
      inputText: dataObj?.indskr_acinodrugtaken ?? '',
      inputValue: dataObj?.indskr_acinodrugtaken ?? '',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.patientIdField = {
      id: 'patient-id-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_PATIENT_IDENTIFIER') : '',
      label: this.translate.instant('PATIENT_IDENTIFIER'),
      inputText: dataObj?.indskr_patientidentifier ?? '',
      inputValue: dataObj?.indskr_patientidentifier ?? '',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.eventDescriptionField = {
      id: 'event-description-field',
      customPlaceholderLabel: !isReadOnly ? this.translate.instant('ENTER_EVENT_DESCRIPTION') : '',
      label: this.translate.instant('EVENT_DESCRIPTION'),
      inputText: dataObj?.indskr_descriptionofevent ?? '',
      inputValue: dataObj?.indskr_descriptionofevent ?? '',
      isReadOnly,
      isDisabled: isReadOnly,
      showArrow: !isReadOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }
  private setFooter() {
    const moreOptionsView = window.innerWidth < 800 ? true : false;
    const isReadOnly = this.viewMode === 'ReadOnly';
    const buttonOptions = new Map();
    if (this.isInfoButtonsEnabled) {
      buttonOptions.set(
        'pharmacovigilance-adverse-events',
        {
          isvisible: true,
          availableOnFullScreen: !moreOptionsView || (moreOptionsView && isReadOnly)
        }
      );
      buttonOptions.set(
        'pharmacovigilance-special-situations',
        {
          isvisible: true,
          availableOnFullScreen: !moreOptionsView || (moreOptionsView && isReadOnly)
        }
      );
      buttonOptions.set(
        'pharmacovigilance-contacts-us',
        {
          isvisible: true,
          availableOnFullScreen: !moreOptionsView || (moreOptionsView && isReadOnly)
        }
      );
    }
    buttonOptions.set(
      'generalCancel',
      {
        isvisible: !isReadOnly,
        availableOnFullScreen: !moreOptionsView || (moreOptionsView && !isReadOnly),
      }
    );
    buttonOptions.set(
      'generalSend',
      {
        isvisible: !isReadOnly,
        isactive: !this.isOffline,
        availableOnFullScreen: !moreOptionsView || (moreOptionsView && !isReadOnly),
      }
    );
    if (buttonOptions.size > 2) {
      buttonOptions.set(
        'openmoreoptions',
        {
          isvisible: moreOptionsView && !isReadOnly,
          isactive: moreOptionsView && !isReadOnly,
          availableOnFullScreen: moreOptionsView && !isReadOnly,
        }
      );
    }

    this.footerService.updateButtonsWithOptions(buttonOptions, !this.isFooterInitRun);
    this.isFooterInitRun = true;
  }

  ngOnInit(): void {
    this.isInfoButtonsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.PHARMACOVIGILANCE_INFORMATION_BUTTONS);

    this.device.isOfflineObservable.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(isOffline => {
      this.isOffline = isOffline;
      this.setPageTitleViewData();
      this.setFooter();
    });

    this.pharmacovigilanceService.selectedRecord$.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(selectedRecord => {
      this.currentRecord = selectedRecord;

      this.isGenericDatePickerOpen = false;
      this.requiredFieldsEvaluationStatus = {};
      this.setPageTitleViewData();
      this.setFieldData();
      this.setFooter();
    });

    this.pharmacovigilanceService.detailPageViewMode$.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(viewMode => {
      this.viewMode = viewMode;

      if (this.viewMode === 'Create') {
        this.resetCreateForm();
      }
      this.setPageTitleViewData();
      this.setFieldData();
      this.setFooter();
    });

    const now = new Date();
    this.genericDatePickerMaxValue = format(new Date(
      now.getFullYear() + 1,
      now.getMonth(),
      now.getDate(),
    ));
  }
  ngOnDestroy(): void {
    this.pharmacovigilanceService.setSelectedRecord(null);
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }


  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      case 'cancel_report':
        this.cancelReportSubmit();
        break;
      case 'submit_report':
        this.submitReport();
        break;
      default:
        break;
    }
  }
  updateGenericDatePickerField(isDone: boolean, value: string) {
    if (this.isGenericDatePickerOpen) {
      if (isDone && value) {
        switch (this.genericPickerFormFieldId) {
          case 'date-of-awareness':
            this.updateDateOfAwarenessField(value);
            break;

          default:
            break;
        }
      }
      this.isGenericDatePickerOpen = false;
      this.genericPickerFormFieldId = undefined;
    }
  }
  onFooterButtonClick(buttonId: string) {
    switch (buttonId) {
      case 'generalSend':
        this.submitReport();
        break;
      case 'generalCancel':
        this.cancelReportSubmit();
        break;
      case 'pharmacovigilance-contacts-us': {
        const data = this.pharmacovigilanceService.getInfoButtonData(CONTACT_US_PREFIX);
        this.openInfoModal({
          title: this.translate.instant('CONTACT_US'),
          dataType: 'text',
          data: data?.value,
        });
        break;
      }
      case 'pharmacovigilance-special-situations': {
        const data = this.pharmacovigilanceService.getInfoButtonData(SPECIAL_SITUATIONS_PREFIX);
        this.openInfoModal({
          title: this.translate.instant('SPECIAL_SITUATIONS'),
          dataType: 'table',
          data: data?.value,
        });
        break;
      }
      case 'pharmacovigilance-adverse-events': {
        const data = this.pharmacovigilanceService.getInfoButtonData(ADVERSE_EVENTS_PREFIX);
        this.openInfoModal({
          title: this.translate.instant('ADVERSE_EVENTS'),
          dataType: 'text',
          data: data?.value,
        });
        break;
      }
      default:
        break;
    }
  }


  private async closePage() {
    await this.navService.setChildNavRoot(
      NothingSelectedView,
      PageName.NothingSelectedView,
      PageName.NothingSelectedView,
    );
    this.navService.setChildNavRightPaneView(false);
    this.uiService.updateNothingSelectedScreenMessageFor(1);
    this.pharmacovigilanceService.setSelectedRecord(null);
    this.pharmacovigilanceService.setDetailPageViewMode('ReadOnly');
  }
  private cancelReportSubmit() {
    this.createForm = undefined;
    if (this.pharmacovigilanceService.hasSelectedRecord()) {
      this.pharmacovigilanceService.setDetailPageViewMode('ReadOnly');
      return;
    }
    this.closePage();
  }
  private async submitReport() {
    if (this.viewMode === 'Create') {
      await this.uiService.displayLoader();
      // Filter out empty fields.
      for (const key in this.createForm) {
        if (Object.prototype.hasOwnProperty.call(this.createForm, key)) {
          const value = this.createForm[key];
          if (!value) {
            delete this.createForm[key];
          }
        }
      }

      if (this.validateForm()) {
        try {
          const response: PharmacovigilanceCreateResponseDTO
            = await this.pharmacovigilanceDataService.createPharmacovigilanceReport(
            this.createForm,
          );

          if (response) {
            const newRecord: Pharmacovigilance = {
              ...this.createForm,
              indskr_pharmacovigilancereportingid: response.indskr_pharmacovigilancereportingid,
              modifiedon: response.modifiedon,
            };

            try {
              await this.pharmacovigilanceService.saveCreatedRecord(newRecord);
              // Display created record
              this.pharmacovigilanceService.setSelectedRecord(newRecord);
              this.pharmacovigilanceService.setDetailPageViewMode('ReadOnly');
            } catch (error) {
              console.error('submitReport: ', error);
            }
          }
        } catch (error) {
          this.notificationService.notify(
            this.translate.instant(''),
            'Pharmacovigilance Detail',
            'top',
            ToastStyle.DANGER,
          );
        }
      }
      await this.uiService.dismissLoader();
    }
  }
  private async handleRecontactReportField(event) {
    const currentDataObj = this.getCurrentDataObj();
    const isValueTrue = currentDataObj?.indskr_recontactreport === true ?? false;
    const viewData: IndDropdownListDetailModel = {
      id: 'segment-select',
      data: [
        {
          id: 'yes',
          title: this.translate.instant('YES'),
          isSelected: isValueTrue,
        },
        {
          id: 'no',
          title: this.translate.instant('NO'),
          isSelected: !isValueTrue,
        }
      ],
      isMultipleSelectionEnabled: false,
    };
    const dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData },
      cssClass: 'dropdown-list-view',
      event,
    });
    await dropdownPopover.present();

    const selectionData = await dropdownPopover.onDidDismiss();
    if (
      Array.isArray(selectionData?.data?.selectedItems)
      && selectionData?.data?.selectedItems[0]
    ) {
      const selectedData = selectionData.data.selectedItems[0];
      const isSelectedValueTrue: boolean = selectedData.id === 'yes';
      if (currentDataObj?.indskr_recontactreport !== isSelectedValueTrue) {
        currentDataObj.indskr_recontactreport = isSelectedValueTrue;
        this.recontactReportField.inputText = this.getRecontactReportFormattedText(currentDataObj);
        if (this.viewMode === 'Create') {
          return;
        }
        // If need to implement update feature later, can continue here.
      }
    }
  }
  private handleReporterNameField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_name)) {
        this.reporterNameField.inputText = this.reporterNameField.inputValue = currentDataObj.indskr_name = newValue;
      }
    } catch (error) {
      console.error('handleReporterNameField: ', error);
    }
  }
  private handlePhoneField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_phone)) {
        this.phoneField.inputText = this.phoneField.inputValue = currentDataObj.indskr_phone = newValue;
      }
    } catch (error) {
      console.error('handlePhoneField: ', error);
    }
  }
  private handleAddressField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_address)) {
        this.addressField.inputText = this.addressField.inputValue = currentDataObj.indskr_address = newValue;
      }
    } catch (error) {
      console.error('handleAddressField: ', error);
    }
  }
  private handleEmailField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_email)) {
        this.emailField.inputText = this.emailField.inputValue = currentDataObj.indskr_email = newValue;
      }
    } catch (error) {
      console.error('handleEmailField: ', error);
    }
  }
  private handleDateOfAwarenessField() {
    const currentDataObj = this.getCurrentDataObj();
    const now = new Date();
    try {
      if (currentDataObj?.indskr_dateofawareness) {
        this.genericDatePickerValue = currentDataObj.indskr_dateofawareness;
      } else {
        this.genericDatePickerValue = format(now, 'YYYY-MM-DD');
      }
      this.genericDatePickerDisplayFormat = this.dateTimeFormatsService.dateToUpper;
      this.genericDatePickerPickerFormat = this.dateTimeFormatsService.dateToUpper;

      // Doing this because it seems the default value (configFieldDateValue)
      // does not get reflected in the same tick
      setTimeout(async () => {
        await this.genericDatePicker?.open();
        this.isGenericDatePickerOpen = true;
        this.genericPickerFormFieldId = 'date-of-awareness';
      }, 10);
    } catch (error) {
      console.error('handleDateOfAwarenessField: ', error);
    }
  }
  private updateDateOfAwarenessField(newValue: string) {
    try {
      const date: Date = new Date(newValue);
      if (isDate(date)) {
        const isoString: string = date.toISOString();
        const currentDataObj = this.getCurrentDataObj();

        currentDataObj.indskr_dateofawareness = isoString;
        this.dateOfAwarenessField.inputText = this.getDateFormattedText(isoString);
      }
    } catch (error) {
      console.error('updateDateOfAwarenessField: ', error);
    }
  }
  private handleAcinoDrugTakenField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_acinodrugtaken)) {
        this.acinoDrugTakenField.inputText = this.acinoDrugTakenField.inputValue = currentDataObj.indskr_acinodrugtaken = newValue;
      }
    } catch (error) {
      console.error('handleAcinoDrugTakenField: ', error);
    }
  }
  private handlePatientIdField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_patientidentifier)) {
        this.patientIdField.inputText = this.patientIdField.inputValue = currentDataObj.indskr_patientidentifier = newValue;
      }
    } catch (error) {
      console.error('handlePatientIdField: ', error);
    }
  }
  private handleEventDescriptionField(event) {
    try {
      const currentDataObj = this.getCurrentDataObj();
      const newValue = event?.target?.value;
      if (this.shouldUpdateNewTextInputValue(newValue, currentDataObj?.indskr_descriptionofevent)) {
        this.eventDescriptionField.inputText = this.eventDescriptionField.inputValue = currentDataObj.indskr_descriptionofevent = newValue;
      }
    } catch (error) {
      console.error('handleEventDescriptionField: ', error);
    }
  }

  private handleFormFieldEvent(id: string, event, eventName: string) {
    if (this.viewMode === 'ReadOnly') {
      return;
    }
    switch (id) {
      case 'recontact-report-field':
          this.handleRecontactReportField(event);
        break;
      case 'reporter-name-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleReporterNameField(event);
          this.validateReporterNameField();
        }
        break;
      case 'phone-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handlePhoneField(event);
        }
        break;
      case 'address-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleAddressField(event);
        }
        break;
      case 'email-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleEmailField(event);
        }
        break;
      case 'date-of-awareness':
        this.handleDateOfAwarenessField();
        break;
      case 'acino-drug-taken-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleAcinoDrugTakenField(event);
        }
        break;
      case 'patient-id-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handlePatientIdField(event);
        }
        break;
      case 'event-description-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleEventDescriptionField(event);
        }
        break;
      default:
        break;
    }
  }


  private getHeaderTitle(): string {
    let title = '';
    switch (this.viewMode) {
      case 'ReadOnly':
      case 'Update':
        title = this.currentRecord?.indskr_name ?? '';
        break;
      case 'Create':
        title = this.translate.instant('NEW_PHARMACOVIGILANCE_REPORTING');
        break;
      default:
        break;
    }
    return title;
  }
  private getRecontactReportFormattedText = (dataObj: Pharmacovigilance | PharmacovigilanceCreateDTO): string => {
    let formattedText = this.translate.instant('NO');
    try {
      if (
        dataObj?.indskr_recontactreport === true
      ) {
        formattedText = this.translate.instant('YES');
      }
    } catch (error) {
      console.error('getRecontactReportFormattedText')
    }
    return formattedText;
  }
  private getDateFormattedText = (dateStr: string): string => {
    let formattedText = '';
    try {
      const offsetDate = new Date(dateStr);
      if (isValid(offsetDate)) {
        formattedText = format(
          offsetDate,
          this.dateTimeFormatsService.dateToUpper,
        );
      }
    } catch (error) {
      console.error('getDateFormattedText: ', error);
    }
    return formattedText;
  }
  private resetCreateForm() {
    this.createForm = {
      indskr_recontactreport: false,
      indskr_name: '',
    };
  }
  private shouldUpdateNewTextInputValue(newValue: string, curValue: string): boolean {
    let shouldUpdate = false;
    if (!newValue && !curValue) {
    } else if (
      newValue !== curValue
      && !(
        newValue === null
        || newValue === undefined
      )
    ) {
      shouldUpdate = true;
    }
    return shouldUpdate;
  }
  private validateReporterNameField(): boolean {
    let isValid = true;
    const currentDataObj = this.getCurrentDataObj();
    this.requiredFieldsEvaluationStatus[this.reporterNameField.id] = { isEvaluated: true };
    if (!currentDataObj?.indskr_name) {
      this.reporterNameField.isEmptyRequiredField = true;
      isValid = false;
    } else {
      this.reporterNameField.isEmptyRequiredField = false;
    }
    return isValid;
  }
  private validateForm() {
    let isValid = true;

    if (!this.validateReporterNameField()) {
      this.notificationService.notify(
        this.translate.instant('PLEASE_ENTER_REPORTER_NAME'),
        'Pharmacovigilance Detail',
        'top',
        ToastStyle.DANGER,
      );
      isValid = false;
    }

    return isValid;
  }
  private async openInfoModal(data: PharmacovigilanceInfoModalData) {
    if (this.infoModal) return;

    this.infoModal = await this.modalCtrl.create({
      component: PharmacovigilanceInfoModalComponent,
      componentProps: {
        data,
      },
      backdropDismiss: false,
      cssClass: POPUP_MODAL_CLASS_NAME,
    });
    await this.infoModal.present();

    await this.infoModal.onDidDismiss();
    this.infoModal = undefined;
  }
}
