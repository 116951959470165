<ion-header>
  <ind-page-title [viewData]='titleBar' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content [ngClass]="{'mobileContent': device.isMobileDevice}">
  <ion-list>
    <!-- <ind-form-field [viewData]="presentationTitle"></ind-form-field> -->
    <ind-form-field *ngIf="description" [viewData]="presentationDescription"></ind-form-field>
    <ind-form-field *ngIf="availableFrom" [viewData]="presentationAvailableFrom"></ind-form-field>
    <ind-form-field *ngIf="availableTo" [viewData]="presentationAvailableTo"></ind-form-field>
    <ind-form-field *ngIf="product" [viewData]="presentationProduct"></ind-form-field>
    <ind-form-field *ngIf="pageNumber" [viewData]="presentationPageCount"></ind-form-field>
    <ind-form-field *ngIf="languageName" [viewData]="presentationLanguge"></ind-form-field>
    <!-- <ind-form-field *ngIf="specialityName" [viewData]="presentationSpeciality"></ind-form-field> -->
    <!-- <ind-form-field *ngIf="presTagCount" [viewData]="presentationTagCount"></ind-form-field> -->
  </ion-list>
  <ind-section-header [viewData]="specialtiesHeader" *ngIf="selectedPresentation.specialties.length>0"></ind-section-header>
  <ion-item-group *ngFor="let sp of selectedPresentation.specialties">
    <ion-item>
      {{sp.name}}
    </ion-item>
    <div class="separationLine"></div>
  </ion-item-group>
  <ind-section-header [viewData]="tagsHeader" *ngIf="userTagForPres.length>0"></ind-section-header>
  <ion-item-group *ngFor="let tag of userTagForPres">
    <ion-item>
      {{tag.indskr_name}}
    </ion-item>
    <div class="separationLine"></div>
  </ion-item-group>
</ion-content>