import { Component, Input } from '@angular/core';
import { IndKeyControlsAreaModel } from '../../../models/indKeyControlsAreaModel';

@Component({
  selector: 'ind-key-controls-area',
  templateUrl: 'ind-key-controls-area.html',
  styleUrls:['ind-key-controls-area.scss']
})
export class IndKeyControlsAreaComponent {

  @Input() viewData:IndKeyControlsAreaModel;

  constructor(){
  }

  public onButtonClick(button,event){
    if(button.id && !button.isDisabled && this.viewData.eventHandler){
        this.viewData.eventHandler(button.id,event,'controls-click');
    }
  }

}
