import { Injectable } from "@angular/core";
import { Inventory, InventoryLot, UserInventory } from "@omni/classes/sample/allocation-inventory.class";
import { Lot } from "@omni/classes/sample/lot.class";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import _ from "lodash";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AllocationInventoryService {
  public userInventory: UserInventory[] = [];
  public selectedLot$: BehaviorSubject<InventoryLot> = new BehaviorSubject(null);
  public selectedLotObservable$ = this.selectedLot$.asObservable();

  constructor(

  ) {}

  public aggregateUserInventory(inventory:Inventory[]) {
    inventory.forEach((inv) => {
      let index = this.userInventory?.findIndex(uerInv => uerInv.skuId == inv.skuId);
      if(index>-1){
        let lotIdx = this.userInventory[index].lot.findIndex(l => l.lotId == inv.lot.lotId)
        if(lotIdx>-1) {
          this.userInventory[index].lot[lotIdx] = inv.lot;
        } else {
          this.userInventory[index].lot.push(inv.lot)
        }
      } else {
        let tempLot: InventoryLot[] = [];
        tempLot.push(inv.lot)
        this.userInventory.push({
          userId: inv.userId,
          skuName: inv.skuName,
          skuId: inv.skuId,
          expanded: true,
          lot: tempLot,
          inventoryViewData: this.getInvSectionHeaderDataModel(inv)
        })
      }
    });
    this.userInventory = _.orderBy(this.userInventory, 'skuName');
    this.userInventory.forEach(inventory => {
      if(inventory.lot && inventory.lot.length>1) {
        inventory.lot.sort((a,b) => {
          return a.validTo > b.validTo ? 1 : -1; 
        })
      }
    })
  }

  private getInvSectionHeaderDataModel(inventory: Inventory): IndSectionHeaderViewDataModel {
    let viewData: IndSectionHeaderViewDataModel;
    viewData = {
      id: 'inventory_' + inventory.skuId,
      title: inventory.skuName,
      controls: [{
        id: 'inventory_expand_'+ inventory.skuId,
        isDisabled: false,
        icon: 'assets/imgs/up-arrow.svg',
      }]
    }
    return viewData;
  }

  public getLastDispatchDateString(skuId, lotId): string {
    let lastDispatchDateStr: string = '';
    if (!_.isEmpty(this.userInventory)) {
      let foundSku = this.userInventory.find(x=>x.skuId == skuId);
      if (foundSku && foundSku.lot) {
        let foundLot = foundSku.lot.find(a=>a.lotId == lotId);
        if (foundLot && foundLot.lastdispatchdate) {
          lastDispatchDateStr = foundLot.lastdispatchdate;
        }
      }    
    }
    return lastDispatchDateStr;
  }

}