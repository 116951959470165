<ion-grid class="content-page">
  <ion-row>
    <ion-col *ngIf="fromMultiUpdate" [ngClass]="{'section-specific-left-pane':fromMultiUpdate}">
      <ion-header>
        <ind-header-left [viewData]="indHeaderLeftModel" [searchHidden]="true" (onControlClick)='onPageTitleControlClick($event)'></ind-header-left>
      </ion-header>
      <ion-content>
      </ion-content>
    </ion-col>
    <ion-col [ngClass]="{'section-specific-right-pane': fromMultiUpdate}">
      <ion-header>
        <ind-page-title [viewData]='titleBar' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
      </ion-header>      
      <ion-content>
        <ng-container *ngIf="!template?.isEdit">
          <ind-section-header [viewData]='{id: "general", title: translate.instant("GENERAL")}'></ind-section-header>
          <ind-form-field *ngFor="let formField of generalAttributes" [viewData]='formField'></ind-form-field>
        </ng-container>
        <ind-section-header [viewData]='sectionHeaderData'></ind-section-header>
        <ng-container *ngFor="let formView of currentFormView">
          <ion-item-divider *ngIf="!template?.isEdit">
            <ion-label>{{formView.name}}</ion-label>
          </ion-item-divider>
          <ng-container *ngFor="let viewData  of formView.controls">
            <ng-container *ngIf="viewData.type == 'form-field'">
              <ind-form-field *ngIf="viewData.control?.Type != '451680002'" [viewData]='viewData.view'
                [ngClass]="viewData.id">
              </ind-form-field>
              <ng-container *ngIf="viewData.control?.Type == '451680002'">
                <ion-row>
                  <ion-col size="6">
                    <ind-datetime-form *ngIf="viewData.view" [viewData]='viewData.view'></ind-datetime-form>
                  </ion-col>
                  <ion-col size="6">
                    <ind-datetime-form *ngIf="viewData.timeView" [viewData]='viewData.timeView'></ind-datetime-form>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ion-content>
    </ion-col>
  </ion-row>
</ion-grid>
