<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [searchDebounce]="500" (onSearchInput)="onInput($event.detail)"
    (onSearchChange)=" searchCallPlans($event.detail)" (onSearchClear)="onCancel($event)"
    (onFilterClick)="openGoalsPlansFilter($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()">
  </ind-header-left>
  <ion-toolbar class="container-c">
    <ion-button fill="clear" size="small" (click)="onSelectedTabChange('notifications')" class="notification-btn">
      <ion-icon slot="icon-only" src="assets/imgs/notification_bluedot.svg" class="tab-notification-dot" *ngIf="assistantService.hasAnyUnRedNotifications"></ion-icon>
      <ion-icon slot="icon-only" src="assets/imgs/notifications-inactive.svg"></ion-icon>
    </ion-button>
    <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab"
      (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft': ( (filteredScientificPlans | sapFilter: therapeuticAreaFilterList: productFilterList).length <= 0 &&(filteredAccountPlans).length<=0 && (filteredCallPlans).length<=0) }">
  <ion-item-group>
    <ng-container *ngIf="isAccountPlansFA">
      <ind-section-header [viewData]="accountHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ion-list class="plans-list">
        <ion-item *ngIf="filteredAccountPlans.length === 0 && isAccountPlansExpanded">
          <div class="no_goals_plans">{{'NO_CURRENT_ACCOUNT_PLANS_ASSOCIATED' | translate}}</div>
        </ion-item>
        <div *ngIf="isAccountPlansExpanded">
          <ion-item-sliding #slidingItem *ngFor="let plan of filteredAccountPlans" class="sliding-item">
            <main-card [viewData]="getAccountPlanmainCardModel(plan)"></main-card>
            <ion-item-options side="end" *ngIf="!device.isOffline && (device.isAndroid() || device.isIOS())">
              <div class="sliding-button" (click)="cloneAccountPlan(plan.ID);slidingItem.close();">
                <img class="sliding-icon" src="assets/imgs/copyicon.svg" width="40" />
                <p class="sliding-btn-text">{{'CLONE'|translate}}</p>
              </div>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </ion-list>
    </ng-container>
    <ng-container *ngIf="isCallPlansFA">
      <ind-section-header [viewData]="callHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ion-list class="plans-list">
        <ion-item *ngIf="filteredCallPlans?.length === 0 && isCallPlansExpanded">
          <div class="no_goals_plans">{{'NO_CURRENT_CALL_PLANS' | translate}}</div>
        </ion-item>
        <div *ngIf="isCallPlansExpanded">
          <main-card *ngFor="let plan of  filteredCallPlans " [viewData]="getCallPlanmainCardModel(plan)"
          [ngClass]="{'itemSelected' : plan?.customerId === callPlanOfflineService.selectedCallPlan?.customerId}">
          </main-card>
        </div>
      </ion-list>
    </ng-container>
    <ng-container *ngIf="isScientificPlansFA">
      <ind-section-header [viewData]="scientificHeaderModel " (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ion-list class="plans-list">
        <ion-item *ngIf="filteredScientificPlans.length === 0 && isScientificPlansExpanded">
          <div class="no_goals_plans">{{'SCIENTIFIC_ACTIVITY_NO_CURRENT_SCIENTIFIC_PLANS' | translate}}</div>
        </ion-item>
        <div *ngIf="isScientificPlansExpanded">
          <main-card [ngClass]="{itemSelected : saPlan.ID === selectedPlanId}"
            *ngFor="let saPlan of filteredScientificPlans | sapFilter: therapeuticAreaFilterList: productFilterList  | orderBy: sortBy?.value:sortBy?.asc"
            [viewData]="getScientificPlanmainCardModel(saPlan)">
          </main-card>
        </div>
      </ion-list>
    </ng-container>
</ion-item-group>
</ion-content>


<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)"
  [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive'
  (selectSavedSearch)="handleSavedSearchSelection($event)">
</search-suggestion-popover>