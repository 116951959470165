<ion-content>
  <ion-list>
      <ind-section-header [viewData]="filterMenuSectionHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <div *ngIf="isDropDownField">
        <div *ngFor="let viewData of allFilterFormView">
          <ind-form-field *ngIf='viewData' [viewData]="getFilterMenuFormField(viewData)"></ind-form-field>
        </div>
      </div>
      <div *ngIf="isAccordionField">
        <div *ngFor="let viewData of allFilterFormViewAccordion">
          <omni-accordion *ngIf='viewData' [viewData]="viewData" (onToggleItem)="toggleCategoryValue(viewData, $event)"></omni-accordion>
          <ion-row *ngIf="viewData.id == 'filter-main' && viewData.isExpanded && isDisplayDateField">
            <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
            <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
          </ion-row>
          <div *ngIf="viewData.id == 'filter-main' && viewData.isExpanded && isDisplayDateField" class="separationLine"></div>
        </div>
      </div>
  </ion-list>
</ion-content>