import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: 'ion-textarea[autosize]'
})
export class TextareaAutosizeDirective {

  @HostListener('input', ['$event.target'])
  onInput(textArea:HTMLTextAreaElement):void {
    setTimeout(() => this.adjust(), 0);
  }

  constructor(public element:ElementRef) {
  }

  ngOnInit():void {
    setTimeout(() => this.adjust(), 0);
  }

  adjust():void {
    const textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];
    if(textArea && textArea.style) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    }

    /*let textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];
    textArea.style.height = 'auto';
    if (textArea.scrollHeight < 50) {
      textArea.style.height = textArea.scrollHeight + "px";
      textArea.style.overflowY = 'hidden';
    } else {
      textArea.style.height = "50px";
      textArea.style.overflowY = 'scroll';
    }*/
  }

}
