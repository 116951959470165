import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Pharmacovigilance } from '@omni/interfaces/pharmacovigilance-reporting/pharmacovigilance.interface';
import { DeviceService } from '@omni/services/device/device.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { PharmacovigilanceDetail } from '../detail/pharmacovigilance-detail';

@Component({
  selector: 'pharmacovigilance-page[base-page]',
  templateUrl: 'pharmacovigilance-page.html',
  styleUrls: ['pharmacovigilance-page.scss'],
})
export class PharmacovigilanceComponent implements OnInit, OnDestroy {
  @ViewChild('rightPaneNav', { static: true }) nav: IonNav;
  constructor(
    private device: DeviceService,
    public navService: NavigationService,
  ) {}

  ngOnInit(): void {
      this.navService.initRightPaneChildNav(
        this.nav,
        ChildNavNames.PharmacovigilanceNavigation,
        PageName.PharmacovigilancePage,
        false,
        {
          deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
        }
      );
  }
  ngOnDestroy(): void {
      this.navService.popChildNavCtrlFromStack(
        this.navService.getActiveChildNavName()
      );
  }

  onListItemSelect(record: Pharmacovigilance) {
    if (record) {
      this.navService.setChildNavRoot(
        PharmacovigilanceDetail,
        PageName.PharmacovigilanceDetail,
        PageName.PharmacovigilancePage,
        {
          selectedRecord: record,
        }
      );
    } else {
      this.navService.popToRootChildNavPageWithPageTracking();
    }
  }
}