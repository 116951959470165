import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';
@Component({
  selector: 'omni-option-alert',
  templateUrl: './omni-option-alert.component.html',
  styleUrls: ['./omni-option-alert.component.scss'],
})
export class OmniOptionAlertComponent implements OnInit {
  @Input() title: string;
  @Input() note: string;
  @Input() buttonLabel: string;
  @Input() options: { value: any, label: string, isChecked: boolean }[] = [];
  @Output() selectedOptions = new EventEmitter();

  selectedOption;

  constructor(
    public modalController: ModalController,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    console.log(this.title);
  }

  onConfirm() {
    let selectedOptions = this.options.filter(({ isChecked }) => isChecked === true);
    this.modalController.dismiss(selectedOptions);
  }

  shouldDisable() {
    const selectedOptions = this.options.filter(({ isChecked }) => isChecked === true);
    return selectedOptions.length === 0;
  }

  onCheck(option) {
    this.options.forEach(element => {
      if (option.value === element.value) {
        element.isChecked = true;
      } else {
        element.isChecked = false;
      }
    });

    this.options = _.cloneDeep(this.options);
  }

}
