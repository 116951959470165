import { Pipe, PipeTransform } from "@angular/core";

/**
 * Generated class for the OrderBy pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: "filterReasons"
})
export class FilterReasonsPipe implements PipeTransform {
  transform<T>(items: Array<T>, config, duration) {
    if(config && config.configValue){
      let configValues = JSON.parse(config.configValue);
      if(configValues && configValues.length){
        items = items.filter(i=>{
          let applicability = true;
          let foundMatch = configValues.find(o=> o["Reason"]==i["reason"]);
          if(foundMatch && foundMatch["Type"] && foundMatch["Type"].split(", ").indexOf(duration)<0) applicability = false;
          return applicability;
        })
      }
    }
    return items;
  }
}
