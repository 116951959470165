import { AdjustmentStatus, ShipmentStatus } from './../../enums/allocation/allocation.enum';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DB_KEY_PREFIXES } from './../../config/pouch-db.config';
import { DiskService } from './../disk/disk.service';
import { IAllocationTransfer } from './../../interfaces/allocation/allocation-transfer.interface';
import { ISkuFilterOptions } from './../../interfaces/allocation/allocation.shared.interface';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { State } from '../../classes/shared/state.class';
import { DataReadState, DataSyncState } from '../../types/state/state.type';
import { IAllocationShipment } from '../../interfaces/allocation/allocation-shipment.interface';
import { IAllocationAdjustment } from '../../interfaces/allocation/allocation-adjustment.interface';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { TransferType } from '../../enums/allocation/allocation.enum';

@Injectable({
  providedIn: 'root'
})
export class AllocationFeatureService {
  readonly shipmentFetchState: State<string, DataSyncState> = new State<string, DataSyncState>('shipmentFetch', 'Null');
  readonly shipmentDataReadState: State<string, DataReadState> = new State<string, DataReadState>('shipmentRead', 'Null');
  readonly pendingShipmentDataReadState: State<string, DataReadState> = new State<string, DataReadState>('pendingShipmentRead', 'Null');
  readonly shipmentLossReasonFetchState: State<string, DataSyncState> = new State<string, DataSyncState>('shipmentLossReasonFetch', 'Null');
  readonly shipmentLossReasonDataReadState: State<string, DataReadState> = new State<string, DataReadState>('shipmentLossReasonRead', 'Null');
  readonly adjustmentFetchState: State<string, DataSyncState> = new State<string, DataSyncState>('adjustmentFetch', 'Null');
  readonly adjustmentDataReadState: State<string, DataReadState> = new State<string, DataReadState>('adjustmentRead', 'Null');
  readonly teamAdjustmentFetchState: State<string, DataSyncState> = new State<string, DataSyncState>('teamAdjustmentFetch', 'Null');
  readonly teamAdjustmentDataReadState: State<string, DataReadState> = new State<string, DataReadState>('teamAdjustmentRead', 'Null');
  readonly adjustmentReasonFetchState: State<string, DataSyncState> = new State<string, DataSyncState>('adjustmentReasonFetch', 'Null');
  readonly adjustmentReasonDataReadState: State<string, DataReadState> = new State<string, DataReadState>('adjustmentReasonRead', 'Null');

  readonly shipmentAndAdjustmentDataFetchStateObs: Observable<[DataSyncState, DataSyncState, DataSyncState, DataSyncState]> =
    combineLatest([
      this.shipmentFetchState.stateObservable,
      this.shipmentLossReasonFetchState.stateObservable,
      this.adjustmentFetchState.stateObservable,
      this.adjustmentReasonFetchState.stateObservable,
    ])
    .pipe(
      debounceTime(0),
    );
  readonly shipmentAndAdjustmentLocalDataReadStateObs: Observable<[DataReadState, DataReadState, DataReadState, DataReadState]> =
    combineLatest([
      this.shipmentDataReadState.stateObservable,
      this.shipmentLossReasonDataReadState.stateObservable,
      this.adjustmentDataReadState.stateObservable,
      this.adjustmentReasonDataReadState.stateObservable,
    ])
    .pipe(
      debounceTime(0),
    );

  readonly pendingShipmentAndTransferDataFetchStateObs: Observable<[DataSyncState, DataSyncState]> =
    combineLatest([
      this.shipmentFetchState.stateObservable,
      this.shipmentLossReasonFetchState.stateObservable,
    ])
    .pipe(
      debounceTime(0),
    );
  readonly pendingShipmentAndTransferLocalDataReadStateObs: Observable<[DataReadState, DataReadState]> =
    combineLatest([
      this.pendingShipmentDataReadState.stateObservable,
      this.shipmentLossReasonDataReadState.stateObservable,
    ])
    .pipe(
      debounceTime(0),
    );

  readonly teamAdjustmentDataFetchStateObs: Observable<[DataSyncState, DataSyncState]> =
    combineLatest([
      this.teamAdjustmentFetchState.stateObservable,
      this.adjustmentReasonFetchState.stateObservable,
    ])
    .pipe(
      debounceTime(0)
    );
  readonly teamAdjustmentLocalDataReadStateObs: Observable<[DataReadState, DataReadState]> =
    combineLatest([
      this.teamAdjustmentDataReadState.stateObservable,
      this.adjustmentReasonDataReadState.stateObservable,
    ])
    .pipe(
      debounceTime(0)
    );

  private _isReadyToClearMemory: Subject<boolean> = new Subject();

  readonly isReadyToClearMemoryObs: Observable<boolean> =
    combineLatest([
      this.shipmentAndAdjustmentLocalDataReadStateObs,
      this.teamAdjustmentLocalDataReadStateObs,
      this._isReadyToClearMemory.asObservable(),
    ])
    .pipe(
      debounceTime(0),
      filter(
        ([
          [
            shipmentReadState,
            shipmentLossReasonReadState,
            adjustmentReadState,
            adjustmentReasonReadState,
          ],
          [
            teamAdjustmentReadState,
          ],
          isReadyToClearMemory
        ]) =>
          shipmentReadState !== 'Read' &&
          shipmentLossReasonReadState !== 'Read' &&
          adjustmentReadState !== 'Read' &&
          adjustmentReasonReadState !== 'Read' &&
          teamAdjustmentReadState !== 'Read' &&
          isReadyToClearMemory === true
      ),
      switchMap(() => of(true))
    );

  readonly isShipmentDataReadyObs: Observable<boolean> =
    combineLatest([
      this.shipmentAndAdjustmentDataFetchStateObs,
      this.shipmentAndAdjustmentLocalDataReadStateObs,
    ])
    .pipe(
      debounceTime(0),
      switchMap(
        ([
          [
            shipmentFetchState,
            shipmentLossReasonFetchState,
            adjustmentFetchState,
            adjustmentReasonFetchState,
          ],
          [
            shipmentReadState,
            shipmentLossReasonReadState,
            adjustmentReadState,
            adjustmentReasonReadState,
          ]
        ]) => {
          if (
            shipmentFetchState !== 'Sync'
            && shipmentLossReasonFetchState !== 'Sync'
            && adjustmentFetchState !== 'Sync'
            && adjustmentReasonFetchState !== 'Sync'
            && shipmentReadState !== 'Read'
            && shipmentLossReasonReadState !== 'Read'
            && adjustmentReadState !== 'Read'
            && adjustmentReasonReadState !== 'Read'
          ) {
            return of(true);
          } else {
            return of(false);
          }
        }
      ),
    );

  readonly isPendingShipmentAndTransferDataReadyObs: Observable<boolean> =
    combineLatest([
      this.pendingShipmentAndTransferDataFetchStateObs,
      this.pendingShipmentAndTransferLocalDataReadStateObs,
    ])
    .pipe(
      debounceTime(0),
      switchMap(
        ([
          [
            shipmentFetchState,
            shipmentLossReasonFetchState,
          ],
          [
            shipmentReadState,
            shipmentLossReasonReadState,
          ]
        ]) => {
          if (
            !(this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB) || this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER))
            || (shipmentFetchState !== 'Sync'
              && shipmentLossReasonFetchState !== 'Sync'
              && shipmentReadState !== 'Read'
              && shipmentLossReasonReadState !== 'Read')
          ) {
            return of(true);
          } else {
            return of(false);
          }
        }
      ),
    );

  readonly isTeamAdjustmentDataReadyObs: Observable<boolean> =
    combineLatest([
      this.teamAdjustmentDataFetchStateObs,
      this.teamAdjustmentLocalDataReadStateObs,
    ])
    .pipe(
      debounceTime(0),
      switchMap(
        ([
          [
            teamAdjustmentFetchState,
            adjustmentReasonFetchState,
          ],
          [
            teamAdjustmentDataReadState,
            adjustmentReasonDataReadState,
          ]
        ]) => {
          if (
            teamAdjustmentFetchState !== 'Sync'
            && adjustmentReasonFetchState !== 'Sync'
            && teamAdjustmentDataReadState !== 'Read'
            && adjustmentReasonDataReadState !== 'Read'
          ) {
            // Data ready
            return of(true);
          } else {
            return of(false);
          }
        }
      ),
    );

  currentTab$: Subject<'history' | 'receipt' | 'teamAdjustment' | 'inventory'> = new BehaviorSubject('history');

  shipments: (IAllocationShipment | IAllocationTransfer | IAllocationAdjustment)[] = [];
  pendingShipments: (IAllocationShipment | IAllocationTransfer)[] = [];
  teamAdjustments: IAllocationAdjustment[] = [];
  shipmentsSkuFilterOptionsMap: Map<string, ISkuFilterOptions> = new Map();
  teamAdjustmentsSkuFilterOptionsMap: Map<string, ISkuFilterOptions> = new Map();
  selectedShipment: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment;
  selectedShipmentAtHome: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment;

  constructor(
    private diskService: DiskService,
    private authService: AuthenticationService,
  ) {
    this.isReadyToClearMemoryObs.subscribe(() => {
      // Tool closing or closed and no data read happening.
      // Safe to remove in-memory data.
      this.clearToolDataFromMemory();
      this.setIsReadyToClearMemory(false);
    });
  }

  setIsReadyToClearMemory(isReady: boolean) {
    this._isReadyToClearMemory.next(isReady);
  }

  private _updateShipmentDataReadStates(state: DataReadState, isAdjustmentEnabled: boolean) {
    this.shipmentDataReadState.setState(state);
    if (isAdjustmentEnabled) {
      this.adjustmentDataReadState.setState(state);
    }
  }
  async loadShipmentsForAllocationTool() {
    const isAdjustmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT);

    try {
      this._updateShipmentDataReadStates('Read', isAdjustmentEnabled);

      const dataLoadResult: [(IAllocationShipment | IAllocationTransfer)[], IAllocationAdjustment[]] = await Promise.all([
        this._loadShipmentAndTransfer(),
        this._loadAdjustments(isAdjustmentEnabled),
      ]);

      if (Array.isArray(dataLoadResult)) {
        await this._readShipments(dataLoadResult[0] ?? [], dataLoadResult[1] ?? []);

        this._updateShipmentDataReadStates('Success', isAdjustmentEnabled);
      } else {
        console.warn('loadShipments: Invalid data load result: ', dataLoadResult);
        this._updateShipmentDataReadStates('Fail', isAdjustmentEnabled);
      }
    } catch (error) {
      console.error('loadShipments: ', error);
      this._updateShipmentDataReadStates('Fail', isAdjustmentEnabled);
    }
  }

  async loadPendingShipmentAndTransfer(shipmentsData?: (IAllocationShipment | IAllocationTransfer)[]) {
    this.pendingShipments.length = 0;
    try {
      this.pendingShipmentDataReadState.setState('Read');

      this.pendingShipments = await this._readPendingShipments(
        shipmentsData
        ? shipmentsData
        : await this._loadShipmentAndTransfer() ?? []
      );

      this.pendingShipmentDataReadState.setState('Success');
    } catch (error) {
      console.error('loadPendingShipmentAndTransfer: ', error);
      this.pendingShipmentDataReadState.setState('Fail');
    }
  }

  private async _loadShipmentAndTransfer(): Promise<(IAllocationShipment | IAllocationTransfer)[]> {
    if (!(this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB) || this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER))) {
      return [];
    }
    const localDbData: { raw: (IAllocationShipment | IAllocationTransfer)[] } = await this.diskService.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_AND_TRANSFER, true);
    return localDbData?.raw ?? [];
  }
  private async _loadAdjustments(isAdjustmentEnabled: boolean): Promise<IAllocationAdjustment[]> {
    if (!isAdjustmentEnabled) {
      return [];
    }
    const localDbData: { raw: IAllocationAdjustment[] } = await this.diskService.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_ADJUSTMENT, true);
    return localDbData?.raw ?? [];
  }

  async loadTeamAdjustments() {
    try {
      this.teamAdjustmentDataReadState.setState('Read');

      const localDbData: { raw: IAllocationAdjustment[] } = await this.diskService.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_TEAM_ADJUSTMENT, true);

      await this._readTeamAdjustments(localDbData?.raw ?? []);

      this.teamAdjustmentDataReadState.setState('Success');
    } catch (error) {
      console.error('loadTeamAdjustments: ', error);
      this.teamAdjustmentDataReadState.setState('Fail');
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Shipments tab Helpers
   */
  addToOrReplaceInShipmentsArray(newData: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment, appendToFront: boolean = false, idx: number = -2) {
    if (newData) {
      if (idx <= -2) {
        // Idx not provided. Do idx search.
        const idxFound = this.shipments.findIndex(s => s.indskr_usershipmentallocation_v2id === newData.indskr_usershipmentallocation_v2id);
        this.addToOrReplaceInShipmentsArray(newData, appendToFront, idxFound);
      } else if (idx >= 0) {
        // Exists
        this.shipments[idx] = newData;
      } else {
        // Not found
        if (appendToFront) {
          this.shipments.unshift(newData);
        } else {
          this.shipments.push(newData);
        }

        this._addToSkuFilterOptionsMap(
          newData.indskr_skuid || newData.at_indskr_skuid,
          newData.indskr_skuname || newData.at_indskr_skuname,
          'Shipments'
        );
      }
    }
  }
  removeFromShipmentsArray(rawData: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment): boolean {
    const idx = this.shipments.findIndex(s => s.indskr_usershipmentallocation_v2id === rawData?.indskr_usershipmentallocation_v2id);
    if (idx >= 0) {
      this._removeFromSkuFilterOptionsMap(
        rawData.indskr_skuid || rawData.at_indskr_skuid,
        'Shipments'
      );
      this.shipments.splice(idx, 1);
      return true;
    } else {
      console.error(`removeFromShipmentsArray: Record not found`, rawData);
      return false;
    }
  }
  private async _readShipments(shipments: (IAllocationShipment | IAllocationTransfer)[], adjustments: IAllocationAdjustment[]) {
    try {
      this.shipments.length = 0;
      const pastDataLimitDateInMillisecond = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();
      const now = new Date().getTime();

      for (let i = 0; i < shipments.length; i++) {
        const shipment: IAllocationShipment | IAllocationTransfer = shipments[i];
        const lotValidTo = shipment.indskr_lotvalidtodate || shipment.at_indskr_lotvalidtodate;
        const lotValidToNumber: number = new Date(lotValidTo).getTime();

        // Display shipments if
        // Shipped & lot is valid
        // Acknowledged & lot expiry date is still within offline data duration
        if (
          !isNaN(lotValidToNumber)
          && (
            (shipment.indskr_shipmentstatus === ShipmentStatus.Shipped && lotValidToNumber >= now)
            || (shipment.indskr_shipmentstatus !== ShipmentStatus.Shipped && lotValidToNumber >= pastDataLimitDateInMillisecond)
          )
        ) {
          this.shipments.push(shipment);
          this._addToSkuFilterOptionsMap(
            shipment.indskr_skuid || shipment.at_indskr_skuid,
            shipment.indskr_skuname || shipment.at_indskr_skuname,
            'Shipments'
          );
        }
      }

      for (let i = 0; i < adjustments.length; i++) {
        const adjustment: IAllocationAdjustment = adjustments[i];
        const lotValidTo = adjustment.indskr_lotvalidtodate || adjustment.at_indskr_lotvalidtodate;
        const lotValidToNumber: number = new Date(lotValidTo).getTime();

        // Display my adjustment if
        // Open & lot is valid
        // or Approved & lot expiry date is still within offline data duration
        // or In Review
        if (
          !isNaN(lotValidToNumber)
          && (
            (adjustment.indskr_adjustedstatus === AdjustmentStatus.Open && lotValidToNumber >= now)
            || (adjustment.indskr_adjustedstatus === AdjustmentStatus.Approved && lotValidToNumber >= pastDataLimitDateInMillisecond)
            || (adjustment.indskr_adjustedstatus === AdjustmentStatus.InReview)
          )
        ) {
          this.shipments.push(adjustment);
          this._addToSkuFilterOptionsMap(
            adjustment.at_indskr_skuid || adjustment.indskr_skuid,
            adjustment.at_indskr_skuname || adjustment.indskr_skuname,
            'Shipments'
          );
        }
      }
    } catch (error) {
      console.error('_readShipments: ', error);
      Promise.reject();
    }
  }
  private async _readPendingShipments(shipments: (IAllocationShipment | IAllocationTransfer)[]): Promise<(IAllocationShipment | IAllocationTransfer)[]> {
    let pendingShipmentAndTransfer: (IAllocationShipment | IAllocationTransfer)[] = [];
    try {
      const pastDataLimitDateInMillisecond = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();
      const now = new Date().getTime();

      if (Array.isArray(shipments)) {
        pendingShipmentAndTransfer = shipments.filter(shipment => {
          const userId: string = this.authService.user.xSystemUserID;
          const isPendingShipmentOrTransfer: boolean =
            shipment.indskr_shipmentstatus !== ShipmentStatus.Acknowledged
            && (
                (shipment.indskr_transfertype !== TransferType.AllocationTransfer)
                || (shipment.indskr_transfertype === TransferType.AllocationTransfer && shipment.ownerId !== userId)
              );
          const lotValidTo: string = shipment.indskr_lotvalidtodate || shipment.at_indskr_lotvalidtodate;
          const lotValidToNumber: number = new Date(lotValidTo).getTime();

          if (
            isPendingShipmentOrTransfer
            && ((shipment.indskr_shipmentstatus === ShipmentStatus.Shipped && (!isNaN(lotValidToNumber) && (lotValidToNumber >= now)))
            || (shipment.indskr_shipmentstatus !== ShipmentStatus.Shipped && (!isNaN(lotValidToNumber) && (lotValidToNumber >= pastDataLimitDateInMillisecond))))
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
    } catch (error) {
      console.error('_readPendingShipments: ', error);
      Promise.reject();
    } finally {
      return pendingShipmentAndTransfer;
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Team Adjustment tab Helpers
   */
  private async _readTeamAdjustments(teamAdjustments: IAllocationAdjustment[]) {
    try {
      this.teamAdjustments.length = 0;
      const pastDataLimitDateInMillisecond = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();

      for (let i = 0; i < teamAdjustments.length; i++) {
        const teamAdjustment: IAllocationAdjustment = teamAdjustments[i];
        const lotValidTo = teamAdjustment.indskr_lotvalidtodate || teamAdjustment.at_indskr_lotvalidtodate;
        const lotValidToNumber: number = new Date(lotValidTo).getTime();

        if (
          !isNaN(lotValidToNumber)
          && (
            teamAdjustment.indskr_adjustedstatus === AdjustmentStatus.InReview
            || (
              teamAdjustment.indskr_adjustedstatus === AdjustmentStatus.Approved
              && lotValidToNumber >= pastDataLimitDateInMillisecond
            )
          )
        ) {
          this.teamAdjustments.push(teamAdjustment);
          this._addToSkuFilterOptionsMap(
            teamAdjustment.at_indskr_skuid || teamAdjustment.indskr_skuid,
            teamAdjustment.at_indskr_skuname || teamAdjustment.indskr_skuname,
            'TeamAdjustment'
          );
        }
      }
    } catch (error) {
      console.error('_readTeamAdjustments: ', error);
      Promise.reject();
    }
  }
  addToOrReplaceInTeamAdjustmentsArray(teamAdjustment: IAllocationAdjustment, idx: number = -2) {
    if (teamAdjustment) {
      if (idx <= -2) {
        // Idx not provided. Do idx search.
        const idxFound = this.teamAdjustments.findIndex(a => a.indskr_usershipmentallocation_v2id === teamAdjustment.indskr_usershipmentallocation_v2id);
        this.addToOrReplaceInTeamAdjustmentsArray(teamAdjustment, idxFound);
      } else if (idx >= 0) {
        // Exists
        this.teamAdjustments[idx] = teamAdjustment;
      } else {
        // Not found
        this.teamAdjustments.push(teamAdjustment);
        this._addToSkuFilterOptionsMap(
          teamAdjustment.at_indskr_skuid || teamAdjustment.indskr_skuid,
          teamAdjustment.at_indskr_skuname || teamAdjustment.indskr_skuname,
          'TeamAdjustment'
        );
      }
    }
  }
  removeFromTeamAdjustmentsArray(teamAdjustment: IAllocationAdjustment): boolean {
    const idx = this.teamAdjustments.findIndex(a => a.indskr_usershipmentallocation_v2id === teamAdjustment?.indskr_usershipmentallocation_v2id);
    if (idx >= 0) {
      this._removeFromSkuFilterOptionsMap(
        teamAdjustment.at_indskr_skuid || teamAdjustment.indskr_skuid,
        'TeamAdjustment'
      );
      this.teamAdjustments.splice(idx, 1);
      return true;
    } else {
      console.error(`removeFromTeamAdjustmentsArray: Record not found`, teamAdjustment);
      return false;
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Plan list pending actions Helpers
   */
  removeFromPendingShipmentsArray(id: string): boolean {
    let isRemoved: boolean = false;
    const idx = this.pendingShipments.findIndex(s => s.indskr_usershipmentallocation_v2id === id);

    if (idx >= 0) {
      this.pendingShipments.splice(idx, 1);
      isRemoved = true;
    }
    return isRemoved;
  }



  /** ----------------------------------------------------------------------------------------
   *  Shared Helpers
   */
  async accessLocalDB(rawData: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment, type: 'shipmentOrTransfer' | 'adjustment' | 'teamAdjustment', action: 'upsert' | 'delete', appendToFront: boolean = false) {
    let key: string;
    switch (type) {
      case 'shipmentOrTransfer':
        key = DB_KEY_PREFIXES.ALLOC_SHIPMENT_AND_TRANSFER;
        break;
      case 'adjustment':
        key = DB_KEY_PREFIXES.ALLOC_SHIPMENT_ADJUSTMENT;
        break;
      case 'teamAdjustment':
        key = DB_KEY_PREFIXES.ALLOC_SHIPMENT_TEAM_ADJUSTMENT;
        break;
      default:
        break;
    }
    try {
      if (key) {
        let doc: { raw: (IAllocationShipment | IAllocationTransfer | IAllocationAdjustment)[], _id?: string, _rev?: string } = await this.diskService.retrieve(key, true);
        let isNewDoc: boolean = false;
        if (!doc) {
          doc = {
            raw: []
          };
          isNewDoc = true;
        } else if (doc && !Array.isArray(doc.raw)) {
          doc.raw = [];
        }

        if (Array.isArray(doc?.raw)) {
          if (action === 'upsert') {
            this._upsertLocalDB(doc, rawData, key, appendToFront, isNewDoc);
          } else if (action === 'delete') {
            this._deleteFromLocalDB(doc, rawData);
          }
        } else {
          console.error(`AllocationFeatureService: accessLocalDB (Type: ${type} Action: ${action}): Local document does not exist. `, doc);
        }
      } else {
        console.error(`AllocationFeatureService: accessLocalDB (Type: ${type} Action: ${action}): Invalid type.`);
      }
    } catch (error) {
      console.error(`AllocationFeatureService: accessLocalDB (Type: ${type} Action: ${action}): `, error);
    }
  }
  private async _upsertLocalDB(
                                doc: { raw: (IAllocationShipment | IAllocationTransfer | IAllocationAdjustment)[], _id?: string, _rev?: string },
                                rawData: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment,
                                key: string,
                                appendToFront: boolean,
                                isNewDoc: boolean = false,
  ) {
    try {
      const idx = doc.raw.findIndex(s => s.indskr_usershipmentallocation_v2id === rawData.indskr_usershipmentallocation_v2id);
      if (idx >= 0) {
        doc.raw[idx] = rawData;
      } else {
        if (appendToFront) {
          doc.raw.unshift(rawData);
        } else {
          doc.raw.push(rawData);
        }
      }

      if (!isNewDoc && doc._id && doc._rev) {
        await this.diskService.updateDocWithIdAndRev(doc, false, true);
      } else {
        await this.diskService.updateOrInsert(key, doc => ({ raw: doc.raw }));
      }
    } catch (error) {
      console.error('AllocationFeatureService: upsertLocalDB: ', error);
    }
  }
  private async _deleteFromLocalDB(doc: { raw: (IAllocationShipment | IAllocationTransfer | IAllocationAdjustment)[] }, rawData: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment) {
    try {
      const idx = doc.raw.findIndex(s => s.indskr_usershipmentallocation_v2id === rawData.indskr_usershipmentallocation_v2id);
      if (idx >= 0) {
        doc.raw.splice(idx, 1);
        await this.diskService.updateDocWithIdAndRev(doc);
      } else {
        console.warn('deleteFromLocalDB: Record not found from document', doc);
      }
    } catch (error) {
      console.error('deleteFromLocalDB: ', error);
    }
  }

  private _addToSkuFilterOptionsMap(skuId: string, skuName: string, filterType: 'Shipments' | 'TeamAdjustment') {
    const filterHandle: Map<string, ISkuFilterOptions> =
      filterType === 'Shipments'
      ? this.shipmentsSkuFilterOptionsMap
      : filterType === 'TeamAdjustment'
        ? this.teamAdjustmentsSkuFilterOptionsMap
        : undefined;

    if (skuId && skuName && filterHandle) {
      if (filterHandle.has(skuId)) {
        const option = filterHandle.get(skuId);
        if (!isNaN(option?.value?.allocShipmentCount)) {
          option.value.allocShipmentCount++;
        }
      } else {
        filterHandle.set(skuId, {
          text: skuName,
          value: {
            skuId,
            skuName,
            isSelected: false,
            allocShipmentCount: 1
          }
        });
      }
    }
  }
  private _removeFromSkuFilterOptionsMap(skuId: string, filterType: 'Shipments' | 'TeamAdjustment') {
    const filterHandle: Map<string, ISkuFilterOptions> =
      filterType === 'Shipments'
      ? this.shipmentsSkuFilterOptionsMap
      : filterType === 'TeamAdjustment'
        ? this.teamAdjustmentsSkuFilterOptionsMap
        : undefined;

    if (skuId && filterHandle) {
      if (filterHandle.has(skuId)) {
        const option = filterHandle.get(skuId);
        if (!isNaN(option?.value?.allocShipmentCount)) {
          option.value.allocShipmentCount--;

          if (option.value.allocShipmentCount < 1) {
            filterHandle.delete(skuId);
          }
        }
      } else {
        console.warn('_removeFromSkuFilterOptionsMap: No ShipmentsSKU to delete: ', filterType);
      }
    }
  }


  private _clearShipmentDataFromMemory() {
    this.shipments.length = 0;
    if (!this.selectedShipmentAtHome) {
      this.selectedShipment = undefined;
    }
    this.shipmentsSkuFilterOptionsMap.clear();
  }

  private _clearTeamAdjustmentDataFromMemory() {
    this.teamAdjustments.length = 0;
    this.teamAdjustmentsSkuFilterOptionsMap.clear();
  }

  clearToolDataFromMemory() {
    this._clearShipmentDataFromMemory();
    this._clearTeamAdjustmentDataFromMemory();
  }

  private async _purgeShipmentsAndTransfers(maxEndDateUnixTimestamp: number) {
    try {
      const doc: { raw: (IAllocationShipment | IAllocationTransfer)[] } = await this.diskService.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_AND_TRANSFER, true);

      if (Array.isArray(doc?.raw)) {
        const now = new Date().getTime();
        const idxToBeDeleted: number[] = [];

        for (let i = 0; i < doc.raw.length; i++) {
          const shipment: IAllocationShipment | IAllocationTransfer = doc.raw[i];
          const lotValidTo = shipment.indskr_lotvalidtodate || shipment.at_indskr_lotvalidtodate;
          const lotValidToNumber: number = new Date(lotValidTo).getTime();

          if (
            isNaN(lotValidToNumber)
            || (
              (shipment.indskr_shipmentstatus === ShipmentStatus.Shipped && lotValidToNumber < now)
              || (shipment.indskr_shipmentstatus !== ShipmentStatus.Shipped && lotValidToNumber < maxEndDateUnixTimestamp)
            )
          ) {
            idxToBeDeleted.push(i);
          }
        }

        if (idxToBeDeleted.length > 0) {
          for (let i = idxToBeDeleted.length - 1; i >= 0; i--) {
            const idx = idxToBeDeleted[i];
            doc.raw.splice(idx, 1);
          }

          await this.diskService.updateDocWithIdAndRev(doc);
        }
      }
    } catch (error) {
      console.error(`_purgeShipmentsAndTransfers: `, error);
    }
  }
  private async _purgeMyAdjustments(maxEndDateUnixTimestamp: number) {
    try {
      const doc: { raw: IAllocationAdjustment[] } = await this.diskService.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_ADJUSTMENT, true);

      if (Array.isArray(doc?.raw)) {
        const now = new Date().getTime();
        const idxToBeDeleted: number[] = [];

        for (let i = 0; i < doc.raw.length; i++) {
          const adjustment: IAllocationAdjustment = doc.raw[i];
          const lotValidTo = adjustment.indskr_lotvalidtodate || adjustment.at_indskr_lotvalidtodate;
          const lotValidToNumber: number = new Date(lotValidTo).getTime();

          // Remove only Open but with expired lot or
          // Approved records with the lot expiry date is older than offline duration
          // Leaving InReview records regardless of lot expiry just in case
          if (
            isNaN(lotValidToNumber)
            || (
              adjustment.indskr_adjustedstatus === AdjustmentStatus.Open
              && lotValidToNumber < now
            )
            || (
              adjustment.indskr_adjustedstatus === AdjustmentStatus.Approved
              && lotValidToNumber < maxEndDateUnixTimestamp
            )
          ) {
            idxToBeDeleted.push(i);
          }
        }

        if (idxToBeDeleted.length > 0) {
          for (let i = idxToBeDeleted.length - 1; i >= 0; i--) {
            const idx = idxToBeDeleted[i];
            doc.raw.splice(idx, 1);
          }

          await this.diskService.updateDocWithIdAndRev(doc);
        }
      }
    } catch (error) {
      console.error(`_purgeMyAdjustments: `, error);
    }
  }
  private async _purgeTeamAdjustments(maxEndDateUnixTimestamp: number) {
    try {
      const doc: { raw: IAllocationAdjustment[] } = await this.diskService.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_TEAM_ADJUSTMENT, true);

      if (Array.isArray(doc?.raw)) {
        const idxToBeDeleted: number[] = [];

        for (let i = 0; i < doc.raw.length; i++) {
          const adjustment: IAllocationAdjustment = doc.raw[i];
          const lotValidTo = adjustment.indskr_lotvalidtodate || adjustment.at_indskr_lotvalidtodate;
          const lotValidToNumber: number = new Date(lotValidTo).getTime();

          // Remove Open status records or
          // Approved records with the lot expiry date is older than offline duration
          // Leaving InReview records regardless of lot expiry just in case
          if (
              isNaN(lotValidToNumber)
              || adjustment.indskr_adjustedstatus === AdjustmentStatus.Open
              || (
                adjustment.indskr_adjustedstatus === AdjustmentStatus.Approved
                && lotValidToNumber < maxEndDateUnixTimestamp
              )
          ) {
            idxToBeDeleted.push(i);
          }
        }

        if (idxToBeDeleted.length > 0) {
          for (let i = idxToBeDeleted.length - 1; i >= 0; i--) {
            const idx = idxToBeDeleted[i];
            doc.raw.splice(idx, 1);
          }

          await this.diskService.updateDocWithIdAndRev(doc);
        }
      }
    } catch (error) {
      console.error(`_purgeTeamAdjustments: `, error);
    }
  }
  async purgeData(maxEndDateUnixTimestamp: number) {
    await Promise.all([
      this._purgeShipmentsAndTransfers(maxEndDateUnixTimestamp),
      this._purgeMyAdjustments(maxEndDateUnixTimestamp),
      this._purgeTeamAdjustments(maxEndDateUnixTimestamp),
    ]);
  }
}
