<!-- <ion-icon item-left class="ion-float-left" *ngIf="account.subAccounts && account.subAccounts.length == 0"  name="arrow-forward"></ion-icon> -->
<span class="item-label primary-text"
  [ngClass]="{'non-add-select-mode':(listMode==compViewMode.LISTVIEW) && (account?.subAccounts && account?.subAccounts.length == 0),
              'non-add-select-mode-has-child':(listMode==compViewMode.LISTVIEW) && (account?.subAccounts && account?.subAccounts.length > 0),
              'add-select-mode':(listMode==compViewMode.ADDNEW || listMode==compViewMode.SELECTION), 'has-child': (account?.subAccounts && account?.subAccounts.length > 0), 
              'single-select-mode': (listMode==compViewMode.ADDNEW || listMode==compViewMode.SELECTION) && !account.isSelected}"
  tappable (click)="clicked($event)">{{account.accountName}} <span class="distance" *ngIf="account['distance'] != null">  | {{account['distance']}}m</span>
  <div class="overflowHiddenAndEllipsis secondaryTextColorAndfont">{{getSecondaryInfo(account)}}</div>
  <div *ngIf="showTertiary" class="overflowHiddenAndEllipsis secondaryTextColorAndfont">{{getTertiaryInfo(account)}}
  </div>
</span>
<ion-icon slot="end" *ngIf="(listMode==compViewMode.ADDNEW || listMode==compViewMode.SELECTION) && !account.isSelected  && accountIdfromIndDynamic!=account.id && !account.isForcedHideAddIcon"
  class='add-icon' (click)="onChecked(account)"></ion-icon>
<ion-icon class='checkmark-icon' slot="end"
  *ngIf="(listMode==compViewMode.ADDNEW || listMode==compViewMode.SELECTION) && account.isSelected"></ion-icon>
<ion-icon class="tag-icons tag-round-item" tappable name="ellipse-outline" slot="start" *ngIf="showCheckboxForTag && !account.isSelectedForTag" (click)="checkAccountForTag(account,true)"></ion-icon>
<ion-icon class="tag-icons" tappable color="primary" name="checkmark-circle" slot="start" *ngIf="showCheckboxForTag && account.isSelectedForTag" (click)="checkAccountForTag(account,false)"></ion-icon>
<!-- <ion-checkbox class="tag-check-box ion-float-left" slot="end" *ngIf="showCheckboxForTag" (ionChange)="checkAccountForTag(account)" [(ngModel)]='account.isSelectedForTag'> </ion-checkbox> -->
<ng-container *ngIf="showChildren">
  <ion-icon class="ion-float-left light-color" *ngIf="account?.subAccounts && account?.subAccounts.length > 0"
    style="margin-top:23px;" [ngClass]="{'boldText': expanded}" tappable
    [name]="expanded ? 'chevron-down-outline' : 'chevron-forward-outline' "
    (click)="expanded = !expanded; expandedChildren = false;" slot="start">
  </ion-icon>
  <ion-list *ngIf="account?.subAccounts && account?.subAccounts.length > 0"
    [ngClass]="[account.id, 'depth-' + depth, (listMode==compViewMode.ADDNEW || listMode==compViewMode.SELECTION) ? 'add-select-mode' : '']"
    class="child-account" [hidden]="!expanded">
    <div class="separationLine-firstChild"></div>
    <ion-item-group id="{{subAccount.id}}"
      *ngFor="let subAccount of account?.subAccounts | orderBy: 'accountName' :true; let last = last"
      [ngClass]="{'itemSelected':(subAccount?.id === (accountService?.accountPageMode != compViewMode.PREVIEW ? accountService?.selected?.id : accountService?.prevSelected?.id))}">
      <ion-item>
        <account-list-item [showTertiary]="showTertiary" [account]="subAccount" [depth]="depth + 1"
          [showChildren]="showChildren" [expanded]='expandedChildren' [expandedChildren]='expandedChildren' [showCheckboxForTag]="showCheckboxForTag"
          [accountListMode]="listMode" (OnAccountSelected)="onChecked($event)"
          (OnCheckAccountForTag)="onTagCheckboxValueChange($event)"
          (OnAccountListItemClicked)="childAccountListItemClicked($event)"
           [sortBy]="sortBy"></account-list-item>
      </ion-item>
      <div *ngIf="!last" class="separationLine"></div>
    </ion-item-group>
  </ion-list>
</ng-container>
