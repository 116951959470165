<!-- <div class='resource-split-pane' [ngClass]="{'details-active':!!selectedResource}" >
  <resource-list [viewMode]="viewMode" [resourcesFromNav]="resources" [collectionName]="collectionName"></resource-list>
  <ion-nav #resourcerightpane></ion-nav>
</div> -->
<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="left-pane">
      <resource-list [viewMode]="viewMode" [resourcesFromNav]="resources" [collectionName]="collectionName"></resource-list>
    </ion-col>
    <ion-col class="right-pane">
      <ion-nav #resourcerightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
