import { SelectedSuggestionPillDataModel } from './../models/search-config-data-model';

export function getActualValueFromMapping(valueMappings: { actualValue: any, formattedValue: string }[], formattedValueToFind): string {
  let valueToReturn: string;
  if (Array.isArray(valueMappings) && formattedValueToFind) {
    const valueMapping = valueMappings.find(m => m.formattedValue === formattedValueToFind)
    if (valueMapping) {
      valueToReturn = '' + valueMapping.actualValue;
    }
  }
  return valueToReturn;
}

// Appending 'name' to PickList / Boolean type of attributes allow applying 'like' filter with string value
export function getAttributeNameForCharacterSearch(categoryPath: string, addNameSuffix = false): string {
  let attributeName = categoryPath;
  const lastTwoChars = attributeName.slice(-2);

  // For address, search is more accurate with 'indskr_composite'
  if (attributeName === 'indskr_address') {
    attributeName = 'indskr_composite';
  } else if (attributeName !== 'indskr_mdmid' && (lastTwoChars === 'id' || lastTwoChars === 'lu' || addNameSuffix)) {
    attributeName = attributeName + 'name';
  }

  return attributeName;
}

export function  getSearchSuggestionsData(data: SelectedSuggestionPillDataModel): SelectedSuggestionPillDataModel[] {
  let suggestions: SelectedSuggestionPillDataModel[] = [];
  if (data?.isAggregatedSection && Array.isArray(data?.searchAttributeNames) && data?.searchAttributeNames.length > 1) {
    // Create search configs for each search attribute
    const { searchAttributeNames, isAggregatedSection, ...rest } = data;

    for (let i = 0; i < data.searchAttributeNames.length; i++) {
      const searchAttributeName = data.searchAttributeNames[i];
      const newSuggestionObj = {
        ...rest,
        ...{
          categoryPath: searchAttributeName
        }
      };
      suggestions.push(newSuggestionObj);
    }
  } else if (data) {
    suggestions.push(data);
  }
  return suggestions;
}
