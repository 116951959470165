<ion-header>
  <ind-page-title [isSkeleton]="true"></ind-page-title>
</ion-header>
<ion-content>
  <ion-list id="details">
    <ind-section-header [isSkeleton]="true" [skeletonConfig]="sectionHeaderConfig"></ind-section-header>
    <ng-container *ngFor="let config of formFieldConfigs">
      <ind-form-field [isSkeleton]="true" [skeletonConfig]="config"></ind-form-field>
    </ng-container>
  </ion-list>
</ion-content>
<!-- <ion-footer> -->
  <footer-toolbar  [footerView]="'detailView'" [selectedView]="'emptySkeleton'"></footer-toolbar>
<!-- </ion-footer> -->
