import { ActivityResourceContact } from './activity-resource-contact.class';

export class ActivityResourceSlide {
  public indskr_starttime: string;
  public indskr_endtime: string;
  public indskr_iodocumentid: string;
  public indskr_ioresourceid: string;
  public activityResourceSlideContacts: ActivityResourceContact[];
  public indskr_pagenumber: number;

  constructor(raw: object) {
    this.indskr_starttime = raw['indskr_starttime'];
    this.indskr_endtime = raw['indskr_endtime'];
    this.indskr_iodocumentid = raw['indskr_iodocumentid'];
    this.indskr_ioresourceid = raw['indskr_ioresourceid'];
    this.activityResourceSlideContacts = raw['activityResourceSlideContacts'];
    this.indskr_pagenumber = raw['indskr_pagenumber'];
  }

  public get DTO(): Object {
    return {
      indskr_starttime: this.indskr_starttime,
      indskr_endtime: this.indskr_endtime,
      indskr_iodocumentid: this.indskr_iodocumentid,
      indskr_ioresourceid: this.indskr_ioresourceid,
      indskr_pagenumber: this.indskr_pagenumber,
      activityResourceSlideContacts: this.activityResourceSlideContacts
    }
  }
}