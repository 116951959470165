import { IndReportCardCompBaseData } from './../../../../interfaces/edge-analytics/ind-report-card-base.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ind-report-card',
  templateUrl: 'ind-report-card.html',
  styleUrls: ['ind-report-card.scss']
})
export class IndReportCardComponent {
  @Input() cardData: IndReportCardCompBaseData;
  @Output() onSegmentChange: EventEmitter<{ segmentEvent: any, cardId: string }> = new EventEmitter();
  constructor() {}

  onSegmentChanged(segmentEvent: any) {
    this.onSegmentChange.emit({
      segmentEvent,
      cardId: this.cardData.id
    });
  }
}
