<ion-header>
    <ind-page-title [viewData]='{id:"cr-details",title: rawFormData.indskr_firstname + " " + rawFormData.indskr_lastname, controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
</ion-header>

<ion-content no-padding>
    <ind-display-form #scrollTop [formMetadata]='displayFormDetails' [rawData]='rawFormData' [referencingEntityName]='"indskr_contactcrid"' [linkedEntityDBPrefix]='contactCRLinkedEntityDBPrefix' [isEditEnabled]="isEditFeatureEnabled" (onEditClick)="editContactCR($event)" [TYPE]="type" [dynamicFormType]='dynamicFormType'>
    </ind-display-form>
    <ng-container *ngIf="noteTextHistory && noteTextHistory.length>0">
      <ind-section-header [viewData]="notesSectionHeaderViewData"></ind-section-header>
      <io-note *ngFor="let note of noteTextHistory" [inputNote]="note" [editable]="false" [readOnlyNote]="true" 
          [deletable]="false" [disableDownloadInOffline]="true" [from]="uiService.page.CrDetailsComponent"></io-note>
  </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="onFooterButtonClicked($event)" [footerView]="'detailView'" [selectedView]="'crDetails'"></footer-toolbar>
<!-- </ion-footer> -->