<!-- Generated template for the AccountDetailsComponent component -->
<ion-header>
    <ind-page-title *ngIf="accountService.selected" [viewData]='{id:"account-details",title: accountService?.selected?.accountName , controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <ind-page-title *ngIf="accountService.selectedOKAccount" [viewData]='{id:"ok-account-details",title: accountService.getAccountName(accountService?.selectedOKAccount), controls: okPageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>

    <!--Account Glance Card-->  
    <glance-card *ngIf="accountGlanceViewData" [viewData]="accountGlanceViewData" (onControlClick)="onQuickGlanceControlClick($event)"> </glance-card>

    <ion-toolbar *ngIf="uiService.accountDataSegment !='customer-assess-teamview'">
        <ind-tabs [data]="tabsData" [selectedTab]="accountDetailSegment" (selectedTabChange)="segmentChanged($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>


<ion-content no-padding [scrollEvents]="true" (ionScroll)="onScroll($event)">
    <div *ngIf="accountService.selected || accountService.selectedOKAccount" [ngSwitch]="uiService.accountDataSegment" class="switchConditionContainer" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <configured-account-info *ngSwitchCase="'info'" [refreshAccountNotesObservable]="refreshAccountNotesObservable" [scrollObservable]="scrollObservable"></configured-account-info>
        <customer-assess #accountAssessment [isLoading]="customerAssessmentIsLoading" [templates]="accountAssessmentTemplates" [templatesTeamview]="accountAssessmentTemplatesTeamview" [rawData]="assessRawData" *ngSwitchCase="tabsData[1].value" (isFormDirty)="isAccountAssessmentFormDirty($event)" (savedData)="savedCustomerAssessment($event)"></customer-assess>
        <customer-assess-teamview #accountAssessmentTeamview [isLoading]="customerAssessmentTeamviewIsLoading" [templates]="accountAssessmentTemplates" [templatesTeamview]="accountAssessmentTemplatesTeamview" 
          [rawData]="assessRawData" *ngSwitchCase="'customer-assess-teamview'"></customer-assess-teamview>
        <account-profile *ngSwitchCase="tabsData[2].value" [readOnly]="isFromChat"></account-profile>
        <account-feed *ngSwitchCase="'feed'" [readOnly]="isFromChat"></account-feed>
        <account-timeline [accountActivities]="accountService.selected.activitesByAccount" *ngSwitchCase="'timeline'"></account-timeline>
    </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'accounts'"></footer-toolbar>
<!-- </ion-footer> -->
