/**
 *
 *
 * @interface IUserRoutingDTO
 * @extends {IUserState}
 * @extends {IUserPreviousState}
 */
interface IUserRoutingDTO extends IUserState, IUserPreviousState {
  id: number;
  userId: string;
}
/**
 *
 *
 * @interface IUserState
 */
interface IUserState {
  userState: string;
}
/**
 *
 *
 * @interface IUserPreviousState
 */
interface IUserPreviousState {
  userPreviousState: string;
}
/**
 *
 *
 * @interface ITargetStates
 * @extends {IUserState}
 */
interface ITargetStates extends IUserState {
  targetID: number;
}
/**
 *
 *
 * @interface ITargetUserState
 */
interface ITargetUserState {
  id: number;
  targetStateID: number;
  targetUserState: string;
  viewState: boolean;
  defaultState: boolean;
}

/**
 *
 *
 * @export
 * @class TargetStates
 * @implements {ITargetUserState}
 */
export class TargetStates implements ITargetUserState {
  id: number;
  targetStateID: number;
  targetUserState: string;
  viewState: boolean;
  defaultState: boolean;

  constructor(
    id: number,
    targetStateID: number,
    targetUserState: string,
    viewState: boolean,
    defaultState: boolean
  ) {
    this.id = id;
    this.targetStateID = targetStateID;
    this.targetUserState = targetUserState;
    this.viewState = viewState;
    this.defaultState = defaultState;
  }
}
/**
 *
 *
 * @export
 * @class RepStatus
 * @implements {IUserRoutingDTO}
 * @implements {ITargetStates}
 */
/*export class RepStatus implements IUserRoutingDTO, ITargetStates {
  userState: string;
  userPreviousState: string;
  id: number;
  userId: string;
  targetID: number;
  targetState: Array<TargetStates> = new Array<TargetStates>();
  /**
   * Creates an instance of RepStatus.
   * @param {object} raw
   * @memberof RepStatus
   *
  constructor(raw: object) {
    this.id = raw["userRoutingStateDTO"].id;
    this.userId = raw["userRoutingStateDTO"].userId;
    this.userState = raw["userRoutingStateDTO"]["userState"].userState; //common
    this.userPreviousState =
      raw["userRoutingStateDTO"]["userPreviousState"].userState;
    if (Array.isArray(raw["targetStates"])) {
      //retrieve the array object
      let tempArray = raw["targetStates"];
      tempArray.map(data => {
        let tempStates = new TargetStates(
          data.id,
          data.targetUserState.id,
          data.targetUserState.targetUserState,
          data.targetUserState.viewState,
          data.targetUserState.default
        );
        this.targetState.push(tempStates);
      });
    }
  }*/

export class RepStatus {
  currentStatus: string;
  appUserStatusId: string;
  domainName: string;
  username: string;
  previousStatus: string;
  stateCode: number;
  statusCode: number;

  // Making status uppercase since MSE somehow changed to return display text
  // except for the 'In Meeting' status.. Just uppercase all for consistency.
  constructor(raw: object) {
    this.currentStatus = raw['currentstatus'].toUpperCase();
    this.appUserStatusId = raw['indskr_appuserstatusid'];
    this.domainName = raw['indskr_domainname'];
    this.username = raw['indskr_name'];
    this.previousStatus = raw['previousstatus'] ? raw['previousstatus'].toUpperCase() : 'ONLINE'; // No previous status means it's a new user. Just set previous Status to Online.
    this.stateCode = raw['statecode'];
    this.statusCode = raw['statuscode'];
  }
  /**
   *
   *
   * @readonly
   * @type {Array<TargetStates>}
   * @memberof RepStatus
   *
  public get getTargetStates(): Array<TargetStates> {
    return this.targetState;
  }*/
  /**
   *
   *
   * @readonly
   * @private
   * @type {string}
   * @memberof RepStatus
   */
  private get currentRepStatus(): string {
    return this.currentStatus;
  }
  /**
   *
   *
   * @readonly
   * @private
   * @type {string}
   * @memberof RepStatus
   */
  private get previousRepState(): string {
    return this.previousStatus;
  }
}
