import { Injectable } from "@angular/core";
import { DiskService } from "../disk/disk.service";
import { BehaviorSubject, Observable ,  of } from "rxjs";
import { Scheduler, rawScheduler } from '../../classes/scheduler/scheduler.class';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { AccountOfflineService } from '../../services/account/account.offline.service'
import * as moment from "moment";
import { CustomerSegmentPayload, CustomerSegment } from "../../classes/customer-segment/customer-segment.class";
import { prioritize, priorityPayload } from "../../classes/scheduler/scheduler-prioritize.class";
import { zipcode, zipPayload } from "../../classes/scheduler/scheduler-zip.class";
import { city, cityPayload } from "../../classes/scheduler/scheduler-city.class"
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { Activity, ActivityType } from "../../classes/activity/activity.class";
import { Contact } from "../../classes/contact/contact.class";
import { CalendarAppointment } from "../../classes/scheduler/calendarAppointment.class";
import { InitiateMeetingPayload } from "../../data-services/meeting/meeting.data.service";
import { AppointmentActivity, OFFLINE_ID_PREFIX } from "../../classes/activity/appointment.activity.class";
import { ActivityService } from "../activity/activity.service";
import { AuthenticationService } from "../authentication.service";
import { SchedulerActivityResponse } from "../../classes/scheduler/scheduler-activity-response.class";
import { TranslateService } from "@ngx-translate/core";

export enum SchedulingPop {
    SD = "SchedulerDuration",
    SP = "SchedulerPrioritize",
    CS = "SchedulerCustomerSegment",
    SA = "SchedulerAccount",
    CP = "SchedulerCallPlan",
    SB = "SchedulerBrand",
    SZ = "SchedulerZip",
    SCi = "SchedulerCity",
    //SS = "SchedulingSubject",
}

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

    public schedulerList: Scheduler[] = [];
    private count: number = 1;
    private lastCount: number = 0;
    priorities: any[] = [
        {
            text: "ReadOnly Meeting",
            id: 0,
            color: "#D8D8D8"
        },
        {
            text: "Proposed Meeting",
            id: 1,
            color: "#CAE1F3"
        },
        {
            text: "ReadOnly Time Off",
            id: 2,
            color: "#D8D8D8"
        }
    ];
    public ifEditMode: boolean = false;
    public isNewPattern: boolean = false;
    public isProcessing: boolean = false;
    public areChangeSaved: boolean = true;
    /*
    Used for fetching the current selected scheduler pattern
    to communicate across all the components
    */
    public schedulerDataSource = new BehaviorSubject<Scheduler>(undefined);
    public schedulerObs = this.schedulerDataSource.asObservable();

    /*
    Used to accessing the list of scheduler patterns and update the same as soon
    as the update or delete method is trigged
    */
    private schedulerListDataSource = new BehaviorSubject<Scheduler[]>([]);
    public schedulerListObservable = this.schedulerListDataSource.asObservable();

    /*
        For Filter options on scheduler popover component
    */
    private selectionTypeDataSource = new BehaviorSubject<any>(undefined);
    public selectionTypeObserver = this.selectionTypeDataSource.asObservable();

    /*
        For filter option to populate the list from where the user can make selection
    */
    private selectionListDataSource = new BehaviorSubject<any>(undefined);
    public selectionListObserver = this.selectionListDataSource.asObservable();

    /*
        For filter to informt the user selection to other components
    */
    private selectionResultDataSource = new BehaviorSubject<any>(undefined);
    public selectionResultObserver = this.selectionResultDataSource.asObservable();

    private changesMapDataSource = new BehaviorSubject<boolean>(false);
    public changesMapObserver = this.changesMapDataSource.asObservable();

    /* System user level info as per mapped on dynamics */
    public schedulerSelectedZip: zipcode[];
    public schedulerSelectedCity: city[];
    public customerSegmentList: CustomerSegment[] = [];
    public priorityList: prioritize[] = [];
    public zipList: zipcode[] = [];
    public cityList: city[] = [];
    public schedulingPeriods = [];

    public matchedContacts: Contact[] = undefined;
    public otherContacts: Contact[] = undefined;

    /*
        Store the raw data received from MSE and to be converted to Object
        when all dependent services are instantiated (Callplan, Account, Product)
    */
    private rawStorage: rawScheduler[] = [];

    /*
        Boolean that decide whether the raw data has been converted or not
    */
    public hasMappedData: boolean = false;

    /*
        For handling the datepicker component
    */
    public isOpenDateTimePicker: boolean = false;

    constructor(
        private brandOfflineService: BrandOfflineService,
        private callPlanOfflineService: CallPlanOfflineService,
        private accountOfflineService: AccountOfflineService,
        private diskService: DiskService,
        public translate: TranslateService,
        private activityService: ActivityService,
        private authenticationService: AuthenticationService,
    ) {

     }

    async initCount() {
        try {
            const stateDoc = await this.diskService.retrieve(DB_KEY_PREFIXES.SYNC_SCHEDULE_COUNT, true);
            //If value does not exist then save the value on db
            if (!stateDoc) {
                const doc = { count: 1 };
                await this.diskService.createDocument(DB_KEY_PREFIXES.SYNC_SCHEDULE_COUNT, doc);
            }
            else {
                //user already has some count registered on DB use the old value.
                this.count = stateDoc.count;
            }
        } catch (error) {

        }
    }

    /* Save the data to service and pouch DB when we receive the response from the MSE for pattern details */
    async saveRawResponse(raw: rawScheduler[], doMap?: boolean) {
        this.rawStorage = raw;
        //trigger the mapping function whenever user tries to access the list view
        this.hasMappedData = false;
        await this.initCount();
        if (doMap) { this.mapSchedulerPatterns(); }
    }

    /* Common map method for all scheduler pattern from data service */
    public async mapSchedulerPatterns() {
        if (!this.hasMappedData) {
            this.schedulerList = []; //clean existing data to avoid duplicacy
            // abstract and encapsulate raw data to class object
            let callplans;
            await this.diskService.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(data=>{
              callplans = data.raw
            })
            this.rawStorage.forEach((element: rawScheduler) => {
                //reducing the reoccurence of logic and abstraction
                this.schedulerList.push(this.rawObjectificationForSchedulingPattern(element, callplans));
            });
            this.hasMappedData = true;
            this.schedulerListDataSource.next(this.schedulerList);
            // return to inform that process has been completed
            return of(this.schedulerList);
        }
    }

    /* store the postal code response to service level data */
    storeZip(raw: zipPayload[]): Observable<zipcode[]> {
        this.zipList = [];
        raw.forEach((el: zipPayload) => {
            this.zipList.push(new zipcode(el))
        })
        return of(this.zipList);
    }

    /* store city data to service level data */
    storeCity(raw: cityPayload[]): Observable<city[]> {
        this.cityList = [];
        raw.forEach((el: cityPayload) => {
            this.cityList.push(new city(el))
        });
        function compare(a, b) {
            if (a.value < b.value)
                return -1;
            if (a.value > b.value)
                return 1;
            return 0;
        }

        this.cityList.sort(compare);
        return of(this.cityList);
    }

    /* store the customer segement releated data from MSE to service level data */
    storeCustomerSegment(raw: CustomerSegmentPayload[]): Observable<CustomerSegment[]> {
        this.customerSegmentList = [];
        raw.forEach((el: CustomerSegmentPayload) => {
            this.customerSegmentList.push(new CustomerSegment(el));
        });
        return of(this.customerSegmentList);
    }

    /* Store the user priority to service level from dynamics */
    storePriority(raw: priorityPayload[]): Observable<prioritize[]> {
        this.priorityList = [];
        raw.forEach((el: priorityPayload) => {
            this.priorityList.push(new prioritize(el));
        })
        return of(this.priorityList);
    }

    //return a copy of the actual list of entries
    //All data must be immutable and can be accessed only via services
    public get getAllSchedules(): Scheduler[] {
        return Object.assign([], this.schedulerList);
    }

    public getFilteredSchedulesBySearchText(text: string): Scheduler[] {
        if (!text) return Object.assign([], this.schedulerList);
        try {
            text = text.toLowerCase().trim();
            let filteredSchedules: Scheduler[] = [];
            filteredSchedules = this.schedulerList.filter(schedule => {
                let str: string = schedule.name.toLowerCase();
                if (schedule.productsList && schedule.productsList.length > 0) {
                    schedule.productsList.forEach(product => {
                        if (product.value) {
                            str += product.value.toLowerCase();
                        }
                    });
                }
                return str.trim().includes(text);
            });
            return Object.assign([], filteredSchedules);
        }
        catch (err) {
            console.error(err);
        }
    }

    /* counting for the name, maybe discarded by product */
    public getCount(): number {
        const p: number = this.lastCount;
        const c: number = this.count;

        if (p < c) {
            this.lastCount = c;
            this.count = c + 1;
            return c;
        }
        else if (p == c) {
            this.count = c + 1;
            return this.count;
        }
        else {
            this.lastCount = p - 1;
            this.count = p;
            return p;
        }
    }

    //decrement the count by one on user temp deletion
    public revertCount() {
        if (this.count > 1) {
            this.lastCount--;
            this.count--;
        }
    }

    //Get duration
    public getDurationValue(startDate: Date, endDate: Date): number {
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(endDate).format('YYYY-MM-DD');
        let duration: number = 1;
        const diff = moment(end).diff(moment(start), 'days');
        if (diff > 0) {
            duration = diff + 1;
        }
        return duration;
    }

    /* Method to create a new scheduler pattern using default values */
    public get newSchedule(): Scheduler {
        let raw: rawScheduler = {
            "indskr_duration": 7,
            "statecode": 0,
            "indskr_name": this.translate.instant('ROUTE_PLAN'),
            "createdon": 0,
            "indskr_schedulingpatternid": "offline_scheduler_" + (new Date().valueOf()) + (Math.floor(100000 + Math.random() * 900000)),
            "indskr_enddate": moment(new Date()).add(6, "d").valueOf(),
            "indskr_startdate": new Date().valueOf(),
            "cycleplans": [],
            "customersegments": [],
            "products": [],
            "accounts": [],
            "cities": [],
            "postalCodes": [],
            "prioritize": []
        };

        return this.rawObjectificationForSchedulingPattern(raw,[]);
    }

    /* public method exposed to other users */
    public convertToSheduler(raw: rawScheduler, callplans): Scheduler {
        return this.rawObjectificationForSchedulingPattern(raw, callplans);
    }

    /* Add the scheduler pattern to service level data */
    addSchedule(newSchedule: Scheduler): boolean {
        try {
            this.schedulerList.push(newSchedule);
            this.schedulerListDataSource.next(this.schedulerList);
            return true;
        }
        catch (err) {
            return false;
        }
    }

    /* Logic to remove the scheduler pattern from service level */
    deleteSchedule(schedule: Scheduler): boolean {
        let index = this.findSchedule(schedule.ID);
        if (index > -1) {
            this.schedulerList.splice(index, 1);
            this.schedulerListDataSource.next(this.schedulerList);
            return true;
        }
        return false;
    }

    /* update the scheduler pattern on the service, later can add the code to update MSE as well */
    updateSchedulerPattern(schedule: Scheduler) {
        let index = this.findSchedule(schedule.ID);
        if (index > -1) {
            //replace the pattern on list
            this.schedulerList[index] = schedule;
            this.schedulerListDataSource.next(this.schedulerList);
        }
    }

    setListDataManually(data: Scheduler[]) {
        this.schedulerListDataSource.next(data);
    }

    /* Find the index of the selected scheduler pattern */
    public findSchedule(id: string, isDataRequired?: any): any {
        if (isDataRequired) {
            return this.schedulerList.find((e: Scheduler) => e.ID === id);
        }
        else
            return this.schedulerList.findIndex((e: Scheduler) => e.ID === id);
    }

    /* Set the value for the selected scheduler pattern observable */
    public setSchedule(data: Scheduler) {
        this.schedulerDataSource.next(data);
    }

    public getArrayValue(value: string): any[] {
        if (value === SchedulingPop.SD) {
            return this.schedulingPeriods;
        }
        else if (value === SchedulingPop.SP) {
            return this.priorityList.filter(e => e.stateCode == 0);
        }
        else if (value === SchedulingPop.CS) {
            return this.customerSegmentList;
        }
        else if (value === SchedulingPop.SA) {
            return this.accountOfflineService.accounts;
        }
        else if (value === SchedulingPop.CP) {
          let presentCallPlans: Array<any> = Object.getOwnPropertyDescriptor(this.callPlanOfflineService.formattedCallPlans, "present").value;
          let futureCallPlans: Array<any> = Object.getOwnPropertyDescriptor(this.callPlanOfflineService.formattedCallPlans, "future").value;
          return presentCallPlans.concat(futureCallPlans);
        }
        else if (value === SchedulingPop.SB) {
            return this.brandOfflineService.brands;
        }
        else if (value === SchedulingPop.SZ) {
            return this.zipList.filter(e => e.stateCode == 0);
        }
        else if (value === SchedulingPop.SCi) {
            return this.cityList.filter(e => e.stateCode == 0);
        }
    }

    /* The commmon method or entry point to be used to create a new scheduler pattern from the raw object */
    private rawObjectificationForSchedulingPattern(raw: rawScheduler, callplans): Scheduler {

        let systemUserData: any = {
            'userCustomerSegments': this.customerSegmentList,
            'userPriorities': this.priorityList,
            'userCitites': this.cityList,
            'userPostalCodes': this.zipList,
            'userProducts': this.brandOfflineService.brands,
            'userCallPlans': callplans,
            'userAccounts': this.accountOfflineService.accounts,
        };
        return new Scheduler(
            raw,
            systemUserData
        );
    }

    /* Set the Observable Value for the scheduler popup to
    understand which popup to open and which data to be updated */
    public setType(data) {
        this.selectionTypeDataSource.next(data);
    }

    /* Set the observable to select the data that the user can make the selection from */
    public setSelectionData(data) {
        this.selectionListDataSource.next(Object.assign([], data));
    }

    /* Set the observable from which the user can fetch the selected response */
    public setSelectionResult(data) {
        this.selectionResultDataSource.next(data);
    }

    /* Get the duration string from the selected duration ID */
    public getDurationString(duration: number) {
        if (duration <= 7) {
            return this.getSchedulingPeriodById("1W");
        } else if (duration > 7 && duration <= 14) {
            return this.getSchedulingPeriodById("2W");
        } else if (duration > 14 && duration <= 21) {
            return this.getSchedulingPeriodById("3W");
        } else if (duration > 21 && duration <= 28) {
            return this.getSchedulingPeriodById("4W");
        }
    }

    public getSchedulingPeriodById(id: string) {
        this.schedulingPeriods = [
            { value: "1 " + this.translate.instant("WEEK"), id: "1W" },
            { value: "2 " + this.translate.instant("WEEKS"), id: "2W" },
            { value: "3 " + this.translate.instant("WEEKS"), id: "3W" },
            { value: "4 " + this.translate.instant("WEEKS"), id: "4W" },
        ];
        const index = this.schedulingPeriods.findIndex(s => s.id === id);
        if(index >= 0) {
            return this.schedulingPeriods[index];
        }

    }

    /* Get the duration number from the selected duration ID */
    public getDurationNumber(id): number {
        let result: number = 7;
        switch (id) {
            case "1W": {
                return result;

            }
            case "2W": {
                return result * 2;

            }
            case "3W": {
                return result * 3;

            }
            case "4W": {
                return result * 4;

            }
            default:
                return result;
        }
    }

    /* convert the selected data as per the requirement raised from MSE, universal method */
    public formatPayload(input: any, type: string): any[] {
        let k: string = '';
        let key: string = "";
        switch (type) {
            case SchedulingPop.SB:
                k = "indskr_schedulingpattern_product";
                key = "ID";
                break;
            case SchedulingPop.CS:
                k = "indskr_schedulingpattern_customersegment";
                key = "id";
                break;
            case SchedulingPop.CP:
                k = "indskr_schedulingpattern_cycleplan";
                key = "cycleplanid";
                break;
            case SchedulingPop.SA:
                k = "indskr_indskr_schedulingpattern_account";
                key = "id";
                break;
            case SchedulingPop.SCi:
                k = "indskr_lu_cityid";
                key = "id";
                break;
            case SchedulingPop.SZ:
                k = "indskr_lu_postalcodeid";
                key = "id";
                break;
            case SchedulingPop.SP:
                k = "indskr_schedulerpriorityid";
                key = "ID";
                break;
            default:
                break;
        }
        let result: any[] = [];
        if (input && Array.isArray(input) && key) {
            input.forEach(e => {
                let obj: Object = {};
                obj[k] = e[key];
                result.push(obj);
            });
        }
        else {
            //single input detected.
            if (input) {
                let obj: Object = {};
                if (type === SchedulingPop.SP) {
                    obj[k] = input.id;
                    result.push(obj);
                }
            }
        }
        return result;
    }

    public formatCalendarAppointment(data: Activity[]): CalendarAppointment[] {
        let calendarActivities: CalendarAppointment[] = [];
        data.map(e => {
            if (e.type === ActivityType.Appointment || e.type === ActivityType.TimeOff) {
                calendarActivities.push(new CalendarAppointment(e));
            }
        });
        return calendarActivities;
    }

    public getScheduleInfo(id: string): Scheduler {
        let data: Scheduler = this.newSchedule;
        Object.assign(data, this.schedulerList.find(e => e.ID === id));
        return data;
    }

    public restoreData(staticSchedule: any, selectedSchedule: Scheduler): Scheduler {
        selectedSchedule.duration = staticSchedule.duration;
        selectedSchedule.durationValue = staticSchedule.durationValue;
        selectedSchedule.name = staticSchedule.name;
        selectedSchedule.enddate = staticSchedule.enddate;
        selectedSchedule.startdate = staticSchedule.startdate;
        selectedSchedule.productsList = staticSchedule.productsList;
        selectedSchedule.productString = staticSchedule.productString;
        selectedSchedule.customersegmentsList = staticSchedule.customersegmentsList;
        selectedSchedule.customerSegmentString = staticSchedule.customerSegmentString;
        selectedSchedule.priority = staticSchedule.priority;
        selectedSchedule.cycleplansList = staticSchedule.cycleplansList;
        selectedSchedule.cycleplanString = staticSchedule.cycleplanString;
        selectedSchedule.accountList = staticSchedule.accountList;
        selectedSchedule.accountString = staticSchedule.accountString;
        selectedSchedule.cityList = staticSchedule.cityList;
        selectedSchedule.cityString = staticSchedule.cityString;
        selectedSchedule.zipcodeList = staticSchedule.zipcodeList;
        selectedSchedule.zipString = staticSchedule.zipString;

        console.log(selectedSchedule);
        return selectedSchedule;
    }

    public async discardCurrentChanges() {
        this.setRestoreMap(true);
        return await this.haveChangesBeenSaved();
    }

    public async haveChangesBeenSaved() {
        this.changesMapObserver.subscribe(value => {
            if (value === false) {
                return true;
            }
        }).unsubscribe();
    }

    public setRestoreMap(value: boolean) {
        this.changesMapDataSource.next(value);
    }

    public selectCallPlan(callplan: any) {
        this.callPlanOfflineService.callPlanAccessedFromScheduler = true;
        this.callPlanOfflineService.selectedCallPlan = callplan;
    }

    public async convertToOfflineMeetingsDTO(req: CalendarAppointment[], res: any) {
        //let payloadTransfer: InitiateMeetingPayload[] = [];
        let createdAppointment: AppointmentActivity[] = [];
        let errorCount: number = 0;
        for (let key in res) {
            let responseValue = res[key];
            let instance: CalendarAppointment = req.find(e => e.id === key);
            let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
                instance.meetingName,
                instance.location,
                instance.startDate.getTime(),
                instance.endDate.getTime(),
                "",
                OFFLINE_ID_PREFIX + new Date().getTime()
            );
            let reqBody = payload.getRequestBody();
            reqBody['statecode'] = 0;
            reqBody['offlineMeetingId'] = instance.id;

            if (!responseValue.hasOwnProperty('errorId')) {
                if (responseValue.hasOwnProperty('activityId')) {

                    reqBody['activityId'] = responseValue.activityId;
                    let appointmentActivity = new AppointmentActivity(reqBody);
                    appointmentActivity.ownerId = this.authenticationService.user.systemUserID;
                    appointmentActivity.meetingOwner = this.authenticationService.user.displayName;
                    appointmentActivity.meetingOwnerId = this.authenticationService.user.systemUserID;
                    appointmentActivity.offlineMeetingId = instance.id;
                    appointmentActivity.ID = responseValue.activityId;
                    appointmentActivity.contacts = instance.contactAttendees;
                    appointmentActivity.indskr_dnascheduler = true;
                    appointmentActivity.activityTypeName = instance.activityTypeName;
                    appointmentActivity.indskr_activitytype = instance.indskr_activitytype;
                    appointmentActivity.activitySubTypeName = instance.activitySubTypeName;
                    appointmentActivity.indskr_activitysubtype = instance.indskr_activitysubtype;
                    this.activityService.addActivity(appointmentActivity);
                    createdAppointment.push(appointmentActivity);

                    try {
                        let localDBData = new SchedulerActivityResponse(appointmentActivity);
                        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.MEETING_ACTIVITY + responseValue.activityId, (doc) => {
                            return localDBData;
                        });
                    } catch (error) {
                        console.log("Some error occured while updating the pouch db with scheduler activity");
                    }
                }
                else {
                    errorCount++;
                    reqBody['activityId'] = instance.id;
                    await this.storeScheduledMeetingOffline(reqBody, instance);
                    console.log("response does not have activity id so ignoring this and adding value to offline");
                }
            }
            //Error ID detected
            else {
                errorCount++;
                reqBody['activityId'] = instance.id;
                await this.storeScheduledMeetingOffline(reqBody, instance);
                console.log("An error occured while creating meeting so ignoring this and adding value to offline");
            }
        }
        return errorCount;
    }

    public async storeScheduledMeetingOffline(req, instance) {
        // Record position id
        if (!req.indskr_positionid && this.authenticationService.user && this.authenticationService.user.xPositionID) {
            req['indskr_positionid'] = this.authenticationService.user.xPositionID;
        }

        let appointmentActivity = new AppointmentActivity(req);
        appointmentActivity.ownerId = this.authenticationService.user.systemUserID;
        appointmentActivity.meetingOwner = this.authenticationService.user.displayName;
        appointmentActivity.meetingOwnerId = this.authenticationService.user.systemUserID;
        appointmentActivity.contacts = instance.contactAttendees;
        let rawOfflineAppt = await this.diskService.createOfflineMeeting(appointmentActivity);
        this.activityService.addToOfflineMeetingIds(appointmentActivity.ID);
        this.activityService.addActivity(appointmentActivity);
    }

    async purgeData(maxEndDateUnixTimestamp: number) {
        await this.purgeSchedulingPatterns(maxEndDateUnixTimestamp.toString());
    }

    private async purgeSchedulingPatterns(maxEndDateUnixTimestampInString: string) {
        const document = await this.diskService.retrieve((DB_KEY_PREFIXES.SCHEDULER_PATTERS));
        let filteredPatterns;

        if (document && Array.isArray(document.raw)) {
            filteredPatterns = document.raw.filter(raw => raw['indskr_enddate'] < maxEndDateUnixTimestampInString);

            if (filteredPatterns.length < document.raw.length) {
                // Update data observable
                this.saveRawResponse(filteredPatterns, !this.hasMappedData);
                document.raw = filteredPatterns;

                try {
                    await this.diskService.updateDocWithIdAndRev(document);
                } catch (error) {
                    console.error('purgeSchedulingPatterns: ', error);
                }
            }
        }
    }
}
