import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';
import { FooterService } from '../../services/footer/footer.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { MyAssistantService } from '../../services/my-assistant/my-assistant.service';
import { DeviceService, DEVICE_EVENT } from '../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { RepServices } from '../../data-services/rep/rep.services';
import { REP_STATUS, LAST_REP_STATUS_KEY } from '../../models/rep-status-model';
import { OfflineSyncUtility } from '../../utility/offline-sync.utility';
import { interval, Subscription } from 'rxjs';
import { debounceTime, delay, filter } from 'rxjs/operators';
import { DeltaService } from '../../data-services/delta/delta.service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';

export const MESSAGE_ROTATION_STARTS_AFTER = 1000;
export const MESSAGE_ROTATION_INTERVAL = 1500;

/**
 * Generated class for the GeneeNotificationPopoverComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'genee-notification-popover',
  templateUrl: 'genee-notification-popover.html',
  styleUrls:['genee-notification-popover.scss']
})
export class GeneeNotificationPopoverComponent implements OnInit, OnDestroy {

  geneeData: any;
  isGeneeButtonEnabled: boolean = false;
  @Input() heading: any;
  @Input() mainButtontext: any;
  @Input() notificationText: any;
  @Input() showLoader: any = false;
  @Input() secondaryButtontext;
  @Input() closeBtn: any = false;
  @Input() actions;
  @Input() defaultAction;
  @Input() from: PageName;

  private messageRotationSub: Subscription;
  private syncEntityListSub: Subscription;
  private syncEntityList: string[] = [];
  private msgRotationIdx: number = 0;

  constructor(
    private offlineSync: OfflineSyncUtility,
    public repService: RepServices,
    public events: EventsService,
    public footerService: FooterService,
    public navService: NavigationService,
    public assistantService: MyAssistantService,
    public device: DeviceService,
    private translate: TranslateService,
    public popoverCtrl: PopoverController,
    private deltaService: DeltaService,
  ) {
  }

  ngOnInit() {
    this.geneeData =  {
      heading:this.heading || this.translate.instant('CN_SUGGESTED_NOTES_ARE_AVAILABLE'),
      notificationText:this.notificationText|| this.translate.instant('THREE_PRODUCTS_TWO_KEY'),
      mainButtontext:this.mainButtontext,
      showLoader: this.showLoader,
      closeBtn : this.closeBtn,
      actions : this.actions,
      defaultAction : this.defaultAction
    };

    // Listen to sync entity list observable and start message rotation
    // once the list starts to get filled
    if (!this.from || this.from != PageName.TrendingCustomersComponent) {
      this.syncEntityListSub = this.deltaService.syncEntityListObservable
                                  .pipe(
                                    debounceTime(0),
                                    filter(map => map && map.size > 0)
                                  )
                                  .subscribe((newSyncEntityMap: Map<SyncFeatureCategory, string>) => {
                                    this.syncEntityList = [];
                                    for (const [key, value] of newSyncEntityMap) {
                                      this.syncEntityList.push(value);
                                    }
                                    if (!this.messageRotationSub) {
                                      //
                                      // if the popup is for product/key messages the we shouldn't rotate the messages.
                                      if (this.geneeData && this.geneeData.notificationText) {
                                        let text = this.geneeData.notificationText;
                                        if (text) {
                                          const compareText = this.translate.instant('GENEE_NOTIFICATION_TEXT');
                                          if (text.indexOf(compareText) > -1) {
                                            return;
                                          }
                                        }
                                      }
                                      //
                                      this.startMessageRotation();
                                    }
                                  });
    }
  }
  ngOnDestroy() {
    if (this.messageRotationSub) {
      this.messageRotationSub.unsubscribe();
    }
    if (this.syncEntityListSub) {
      this.syncEntityListSub.unsubscribe();
    }
  }

  async handleMainButtonClick() {

    if (this.offlineSync.isSyncRequire()) {
      this.offlineSync.clear();
      this.device.isOffline = this.device.isUserStateOffline = this.repService.isOfflineState = false;
      this.events.publish('home:runUpdateSync', { syncType: DEVICE_EVENT.userStatusBackToOnline });
      //this.repService.setCurrentUserState(REP_STATUS.ONLINE.userState);
      await this.repService.setCurrentState({ userState: REP_STATUS.ONLINE.userState }).then(res => {
        window.localStorage.setItem(LAST_REP_STATUS_KEY, REP_STATUS.ONLINE.userState);
        }).catch(error => { });
    }

    if (this.geneeData && this.geneeData.mainButtontext && this.geneeData.mainButtontext == this.translate.instant('GENEE_SHOW_ME')) {
      this.popoverCtrl.dismiss({ viewNotes: true });
    }
    else if (this.geneeData && this.geneeData.mainButtontext && this.geneeData.mainButtontext == this.translate.instant('GENEE_SYNC_NOW')) {
      this.geneeData = {
        heading: this.translate.instant('GENEE_SYNC_IN_PROGRESS_CAP'),
        // notificationText: 'I am syncing the data, please wait...',
        notificationText: this.translate.instant('GENEE_SYNCING_DATA_PLEASE_WAIT'),
        showLoader: true
      }
      this.secondaryButtontext = ''
      this.events.publish('home:runUpdateSync');
      //this.viewCtrl.dismiss();
    }

  }

  handleActionClick(action) {
    this.popoverCtrl.dismiss({ preferredAction: action });
  }

  getCountUnreadNotifications() {
    return this.assistantService.countUnreadNotifications
  }
  closePopover() {
    this.popoverCtrl.dismiss();
    this.device.checkNetworkConnectivity()
      .then(isConnected => {
        if (isConnected && this.device.isUserStateOffline) {
          this.offlineSync.setRemindMe();
        }
      })
  }

  private startMessageRotation() {
    this.messageRotationSub = interval(MESSAGE_ROTATION_INTERVAL).pipe(delay(MESSAGE_ROTATION_STARTS_AFTER)).subscribe(() => {
      this.geneeData.notificationText = 'I am syncing your ' + this.syncEntityList[this.msgRotationIdx];
      this.msgRotationIdx++;

      if (this.syncEntityList.length - 1 < this.msgRotationIdx) {
        this.msgRotationIdx = 0;
      }
    });
  }
}
