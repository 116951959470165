<ion-item-group id='listScroll'>
  <div *ngIf="filteredUserInventory && filteredUserInventory.length>0">
    <ng-container *ngFor="let inventory of filteredUserInventory">
      <ind-section-header [viewData]="inventory.inventoryViewData" (onControlClick)='onSectionHeaderControlClick($event, inventory.skuId)'>
      </ind-section-header>
      <ng-container *ngIf="getInventoryExpanded(inventory)">
        <div *ngFor="let lot of inventory.lot"> 
          <main-card [viewData]="getLotMaincardDataModel(lot)"></main-card>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="!this.isLoading && filteredUserInventory && filteredUserInventory.length==0">
    <ion-list>
      <ion-item class="orders-list-empty">
          <div class="no-data-message">
            {{'NO_RESULTS' | translate}}
          </div>
      </ion-item>
  </ion-list>
  </div>
</ion-item-group>
