import { Component, Input, OnInit } from '@angular/core';

// interface NumberCardOptions{
//   label:string;
//   value: string | number;
//   config?:any;
// }
@Component({
  selector: 'number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss'],
})
export class NumberCardComponent implements OnInit {
  // @Input() options:NumberCardOptions;
  @Input() label : string;
  @Input() value : string;
  @Input() cssClass : string;
  constructor() { }

  ngOnInit() {}

}
