import { Injectable } from "@angular/core";
import { DeviceService } from "../../services/device/device.service";
import { DiskService } from "../../services/disk/disk.service";
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { AuthenticationService } from "../../services/authentication.service";
import { Endpoints } from "../../../config/endpoints.config";
import { EntitySyncInfo, EntityNames } from "../delta/delta.service";
import { HttpClient } from "@angular/common/http";
import { TherapeuticArea } from "../../classes/therapeutic-area/therapeutic-area.class";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { fetchQueries } from "../../config/dynamics-fetchQueries";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { AppointmentActivity } from "../../classes/activity/appointment.activity.class";
import { ActivityService } from "../../services/activity/activity.service";
import { SearchConfigService } from "../../services/search/search-config.service";
import { PhoneActivity } from "../../classes/activity/phone.activity.class";
import { AssessTeamviewRefData } from "@omni/classes/customer-assessment/customer-assessment.class";
import _ from "lodash";


@Injectable({
  providedIn: 'root'
})
export class TherapeuticAreaDataService{

    public therapeuticAreas:TherapeuticArea[] = [];
    public therapeuticAreasTeamview: AssessTeamviewRefData[] = [];

    constructor(
        private deviceService: DeviceService,
        private dynamics: DynamicsClientService,
        private disk: DiskService,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private activityService: ActivityService,
        private searchConfigService: SearchConfigService
    ){}


    async getTherapeuticArea(loadFromDbOnly = false){
        if (loadFromDbOnly) {
            await this.loadTherapeuticAreaFromDBAndMap();
        } else {
        let positionIds = this.authenticationService.user.positions.map(o=>{
            return o.ID
        });
        let positionString = '';
        positionIds.forEach(p=>{
            positionString += '<value>'+p+'</value>'
        })

       let fetchXML = fetchQueries.therapeuticAreaByUserMapping.split('{positionIDs}').join(positionString);
        if (!this.deviceService.isOffline) {
            try{
                let response = await this.dynamics.executeFetchQuery('indskr_therapeuticareas',fetchXML);
                if(response){
                    await this.mapTherapeuticArea(response,false);
                }
            }catch(err){
                console.error("Online Therapeutic Fetch : " + err);
            }
        }else{
            await this.loadTherapeuticAreaFromDBAndMap();
        }
        }
    }

    private async loadTherapeuticAreaFromDBAndMap() {
        try{
            await this.disk.retrieve(DB_KEY_PREFIXES.THERAPEUTIC_AREA, true).then((doc)=>{
              let therapeuticAreas;
              if(doc){
                therapeuticAreas = doc.contentmatching;
              }
              if(therapeuticAreas){
                this.mapTherapeuticArea(therapeuticAreas, true);
              }
            })
        }catch(err){
            console.error("Offline Therapeutic Fetch : " + err);
        }
    }

    async mapTherapeuticArea(response,doNotSave?:boolean){

        this.therapeuticAreas = [];
        try{
            if(response && Array.isArray(response)){
                let sequence = 1;
                response.map(ta => {
                    let findIndex = this.therapeuticAreas.findIndex(item => {
                        return item.therapeuticareaId === ta['indskr_therapeuticareaid'];
                    });
                    ta['indskr_sequence'] = sequence++;

                    if(findIndex === -1){
                        this.therapeuticAreas.push(new TherapeuticArea(ta));
                    }else{
                        this.therapeuticAreas[findIndex] = new TherapeuticArea(ta);
                    }
                });
                this.searchConfigService.isTherapeuticAreasMappedToPresentations = false;
            }

            this.therapeuticAreas.sort((a,b)=>{
                return (a.name === b.name ) ? ((a.name > b.name ) ? 1 : -1 ) : 0;
            });

            console.log(this.therapeuticAreas);
            if (!doNotSave) {
                //Save to DB or update
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.THERAPEUTIC_AREA, () => {
                  return {contentmatching: response};
                });
            }
        }catch(err){
            console.error("Map Therapeutic Areas : " + err);
        }
    }

    updateMeetingTherapeuticArea(selectedActivity:AppointmentActivity | PhoneActivity): Promise<any>{

        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                try {
                    return this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
                    // return Promise.resolve();
                } catch(e) {
                    console.log("Failed updating therapeutic area in offline");
                    return Promise.reject(e);
                }
            }
        }

        if (this.activityService.selectedActivity instanceof PhoneActivity) {
          if (this.deviceService.isOffline || this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
              try {
                  return this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity);
                  // return Promise.resolve();
              } catch(e) {
                  console.log("Failed updating therapeutic area in offline");
                  return Promise.reject(e);
              }
          }
      }


        let taobj = [];
        selectedActivity['activityTherapeuticAreas'].forEach(obj => {
            if(obj.isSelected) {
                taobj.push(obj.TherapeuticAreaMeetingDTO);
            }
        });

        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scientificActivities.UPDATE_MEETING_THERAPEUTICAREA;

        url = url.replace('{{ActivityId}}',selectedActivity.ID);

        try{
            return this.http.put(url,taobj).toPromise();
        }
        catch(err){
            console.error("Update Meeting Therapeutic area : " + err);
            return Promise.reject(err);
        }
    }
    //Therapeutic Area for teamview (position includes childposition) - online only
    async getTherapeuticAreaTeamview(positionString: string){
     let fetchXML = fetchQueries.therapeuticAreaByUserMapping.split('{positionIDs}').join(positionString);
    if (!this.deviceService.isOffline) {
        try{
            let response = await this.dynamics.executeFetchQuery('indskr_therapeuticareas',fetchXML);
            if(response){
                await this.mapTherapeuticAreaTeamview(response);
            }
        }catch(err){
            console.error("Online Therapeutic areas for Teamview Fetch : " + err);
        }
      }
    }

    async mapTherapeuticAreaTeamview(response){
      this.therapeuticAreasTeamview = [];
      try{
          if(response && Array.isArray(response)){
              let sequence = 1;
              response.map(ta => {
                  let findIndex = this.therapeuticAreasTeamview.findIndex(item => {
                      return item.ID === ta['indskr_therapeuticareaid'];
                  });
                  ta['indskr_sequence'] = sequence++;
                  
                  let data = {
                    ID: ta['indskr_therapeuticareaid'],
                    name: ta['indskr_name']
                  }
                  if(findIndex === -1){
                      this.therapeuticAreasTeamview.push(data);
                  }else{
                      this.therapeuticAreasTeamview[findIndex] = data;
                  }
              });
              this.searchConfigService.isTherapeuticAreasMappedToPresentations = false;
          }
          this.therapeuticAreasTeamview = _.sortBy(this.therapeuticAreasTeamview, 'name');
      }catch(err){
          console.error("Map Therapeutic Areas for Teamview : " + err);
      }
  }
}
