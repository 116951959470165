import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


export type AlertOptions =
  {
    mainImgSrc?: string;
    message?: any;
    subText?: string,
    list?:
    {
      itemAvatarSrc?:string;
      initials?: string;
      itemText?: string;
      subText?: any[];
    }[];
  }


@Component({
  selector: 'app-omni-alert',
  templateUrl: './omni-alert.component.html',
  styleUrls: ['./omni-alert.component.scss'],
})
export class OmniAlertComponent implements OnInit {
  @Input() inputOptions;

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  onConfirm(){
    this.modalController.dismiss('confirmed');
  }

}
