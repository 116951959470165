import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { OffTakeCheckService } from '@omni/services/offtake-check/offtake-check.service';
import { Account } from '@omni/classes/account/account.class';
import { OffTakeCheck } from '@omni/classes/offtake-check/offtake-check.class';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import moment from 'moment';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import _ from 'lodash';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '@omni/services/authentication.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { DeviceService } from '@omni/services/device/device.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';

@Component({
  selector: 'app-offtake-check',
  templateUrl: './offtake-check.component.html',
  styleUrls: ['./offtake-check.component.scss'],
})
export class OfftakeCheckComponent implements OnInit {
  @Input() selectedAccount: Account;
  offTakeCheck: OffTakeCheck;
  public pageTitle: IndPageTitleViewDataModel;
  yearSectionHeader: IndSectionHeaderViewDataModel;
  selectedDate: Date;
  months: { id: number, name: string }[];
  isSavedEnabled: boolean = false;
  constructor(public modalCtrl: ModalController,
    public device: DeviceService,
    private notificationService: NotificationService,
    public navService: NavigationService,
    public offtakeCheckService: OffTakeCheckService,
    public readonly translate: TranslateService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    public _modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.selectedDate = moment().startOf('month').toDate();
    this.initPageTitle();
    this.offtakeCheckService.getOfftakeCheckData(this.selectedAccount.id, this.selectedDate).then(() => {
      this.setOffTakeCheck()
      if (!this.offTakeCheck) {
        this.offTakeCheck = this.offtakeCheckService.getEmptyOfftakeCollection(this.selectedDate, this.selectedAccount.id);
      }
    });
    this.yearSectionHeader = {
      id: 'year-header',
      title: this.selectedDate.getFullYear() + '',
      controls: []
    }
    this.months = moment.monthsShort().map((m, index) => {
      return {
        id: index,
        name: this.translate.instant(m.toUpperCase()).toUpperCase()
      }
    });
  }

  private initPageTitle() {
    this.pageTitle = {
      id: 'offtake',
      isHCPFacing: true,
      title: this.translate.instant('OFFTAKECHECK_CAPS') + " - " + this.selectedAccount?.accountName,
      controls: [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      },
      {
        id: "save",
        icon: 'checkmark',
        name: this.translate.instant('SAVE'),
        isDisabled: !this.isSavedEnabled,
        align: "right"
      }
      ]
    };
  }

  public async pageTitleControlClick(id) {
    if (id === 'close') {
      if (!await this.discardPopup()) return;
      const currentMonthData = this.offtakeCheckService.offTakeChecks.find(offTake => offTake.indskr_offtakedate.getTime() === moment().startOf('month').toDate().getTime());
      this.modalCtrl.dismiss({currentMonthData}).then(() => this.navService.isModalViewOpen = false);
    } else if (id === 'save') {
      if(this.device.isOffline){
        let toastMsg = this.translate.instant("OFFLINE_OFFTAKE_CLICK_SAVE")
        .replace('{{month}}', this.translate.instant(moment(this.selectedDate).format('MMM').toUpperCase()))
        .replace('{{year}}', this.selectedDate.getFullYear());
        this.notificationService.notify(toastMsg, "Meeting Details", "top", ToastStyle.DANGER , 3000);
      }
      this.offtakeCheckService.saveOffTakeCollection(this.offTakeCheck).then((savedOffTakeCheck) => {
        if (savedOffTakeCheck) {
          this.updateNotification();
          this.offTakeCheck = savedOffTakeCheck;
          this.isSavedEnabled = false;
          this.initPageTitle();
        }
      });
    }
  }

  public getModifiedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MODIFIED_BY'),
      inputText: _.isEmpty(this.offTakeCheck?.modifiedby) ? '-' : this.offTakeCheck.modifiedby,
      id: 'modified-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getModifiedDateFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MODIFIED_DATE'),
      inputText: this.offTakeCheck?.modifiedOn ? this.datePipe.transform(this.offTakeCheck.modifiedOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '-',
      id: 'modified-date-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  async setCurrentMonth(month) {
    if (!await this.discardPopup()) return;
    this.selectedDate = moment([this.selectedDate.getFullYear(), month.id]).startOf('months').toDate();
    this.setOffTakeCheck();
  }

  setOffTakeCheck() {
    this.offTakeCheck = _.cloneDeep(this.offtakeCheckService.offTakeChecks.find(offTake => offTake.indskr_offtakedate.getTime() === this.selectedDate.getTime()));
    if (this.isEditable) {
      if (this.offTakeCheck) {
        const emptyOffTake = this.offtakeCheckService.getEmptyOfftakeCollection(this.selectedDate, this.selectedAccount.id);
        emptyOffTake.collections.forEach(offTakeCollection => {
          if (!this.offTakeCheck.collections.find(coll => coll.productId === offTakeCollection.productId && coll.skuId === offTakeCollection.skuId)) {
            this.offTakeCheck.collections.push(offTakeCollection);
          }
        })
        this.offTakeCheck.collections = _.sortBy(this.offTakeCheck.collections, ['productName', 'skuName']);
      } else {
        this.offTakeCheck = this.offtakeCheckService.getEmptyOfftakeCollection(this.selectedDate, this.selectedAccount.id);
      }
    }
  }

  get isEditable() {
    const monthsDiff = moment().diff(moment(this.selectedDate), 'months');
    return monthsDiff > 0 ? this.authService.user.buConfigs?.indskr_offtakecheckpreviousmonthlimit > 0 && (monthsDiff <= this.authService.user.buConfigs.indskr_offtakecheckpreviousmonthlimit) : true;
  }

  private async updateNotification() {
    const modal = await this._modalCtrl.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: this.translate.instant('OFFTAKE_UPDATED'),
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        notificationMessage: this.translate.instant("OFFTAKE_SUBMITTED")
          .replace('{{month}}', this.translate.instant(moment(this.selectedDate).format('MMM').toUpperCase()))
          .replace('{{year}}', this.selectedDate.getFullYear())
      }
    });
    await modal.present();

    await modal.onDidDismiss().then(({ data }) => {
    })
  }

  getMonthColorWithBackGround(month) {
    // console.log(month)
    const date = moment([this.selectedDate.getFullYear(), month.id]).startOf('months').toDate();
    if (moment().startOf('months').toDate().getTime() === date.getTime()) {
      return 'currentMonth'
    }
    if (date > new Date()) {
      //Future
      return 'futureMonth';
    }
    else if (this.offtakeCheckService.offTakeChecks.some(offTake => offTake.indskr_offtakedate.getTime() === date.getTime())) {
      //Past/Present
      return 'dataAvailable';
    } else {
      return 'noDataAvailable';
    }
  }

  onUpdate(event) {
    this.isSavedEnabled = true;
    if(this.device.isOffline){
      this.isSavedEnabled = false ;
      this.notificationService.notify(this.translate.instant('OFFLINE_OFFTAKE_CLICK_ADD'), "Meeting Details", "top", ToastStyle.DANGER, 3000);
    }
    this.initPageTitle();
  }

  async prevYear() {
    if (!await this.discardPopup()) return;
    // if(this.selectedDate.getFullYear() == new Date().getFullYear) {
    this.selectedDate = moment(this.selectedDate).subtract(1, 'years').endOf('years').startOf('months').toDate();
    // }
    this.offtakeCheckService.getOfftakeCheckData(this.selectedAccount.id, this.selectedDate).then(() => {
      this.setOffTakeCheck()
      if (!this.offTakeCheck && this.isEditable) {
        this.offTakeCheck = this.offtakeCheckService.getEmptyOfftakeCollection(this.selectedDate, this.selectedAccount.id);
      }
    });
  }

  async nextYear() {
    if (!await this.discardPopup()) return;
    if (this.selectedDate.getFullYear() === new Date().getFullYear()) {
      return; // No Data will be available for Future
    }
    if (this.selectedDate.getFullYear() < new Date().getFullYear()) {
      //Go untill current year
      this.selectedDate = moment(this.selectedDate).add(1, 'years').endOf('years').startOf('months').toDate();
      if (this.selectedDate.getTime() > moment().startOf('months').toDate().getTime()) {
        //Future month ? -> set to current month
        this.selectedDate = moment().startOf('months').toDate();
      }
      this.offtakeCheckService.getOfftakeCheckData(this.selectedAccount.id, this.selectedDate).then(() => {
        this.setOffTakeCheck()
        if (!this.offTakeCheck && this.isEditable) {
          this.offTakeCheck = this.offtakeCheckService.getEmptyOfftakeCollection(this.selectedDate, this.selectedAccount.id);
        }
      });
    }
  }

  async discardPopup() {
    if (this.isSavedEnabled) {
      const res = await this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
      }, this.translate.instant('DISCARD')
      );
      if (res.role == "ok") {
        this.setOffTakeCheck()
        if (!this.offTakeCheck && this.isEditable) {
          this.offTakeCheck = this.offtakeCheckService.getEmptyOfftakeCollection(this.selectedDate, this.selectedAccount.id);
        }
        this.isSavedEnabled = false;
        this.initPageTitle();
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}