
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { DiskService } from "../../services/disk/disk.service";
import { LogService } from "../../services/logging/log-service";
import { Endpoints } from "../../../config/endpoints.config";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Observable ,  of } from "rxjs";
import { SchedulerService, SchedulingPop } from '../../services/scheduler/scheduler.service';
import * as moment from "moment";
import { schedulePayload, Scheduler, rawScheduler } from "../../classes/scheduler/scheduler.class";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { EntitySyncInfo, EntityNames, DeltaService } from "../delta/delta.service";
import { CalendarAppointment } from "../../classes/scheduler/calendarAppointment.class";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { DeviceService } from "../../services/device/device.service";

@Injectable({
  providedIn: 'root'
})
export class SchedulerDataService {

    constructor(
        private disk: DiskService,
        private authenticationService: AuthenticationService,
        private logService: LogService,
        private http: HttpClient,
        private schedulerService: SchedulerService,
        private deltaService: DeltaService
    ) { }

    public async fetchAllSchedulerPatterns(dataRange: { from: string, to: string }, loadFromDbOnly = false) {
        let offlineFallback: boolean = false;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && !loadFromDbOnly) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.ALL_SCHEDULES +
                '?fromDate=' + dataRange.from + '&toDate=' + dataRange.to;
            const schedulerPatternSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.schedulerPattern,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let response: any = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.SCHEDULER_PATTERS, doc => ({ raw: response }))
                    .catch((err) => {
                        console.log("Error saving scheduler route data to offline db!", err);
                    });

                if (Array.isArray(response)) {
                    schedulerPatternSyncInfo.totalSynced = response.length;
                }
                //No pattern detected currently so save for mapping later
                if (this.schedulerService.schedulerList.length === 0) {
                    this.schedulerService.saveRawResponse(response);
                }
                else {
                    //Some data already available and now check for the page user is in
                    this.schedulerService.saveRawResponse(response, true);
                }
            } catch (httpError) {
                offlineFallback = true;
                this.logService.logDebug(
                    "Error fetching scheduler route from server",
                    httpError
                );
                this.deltaService.addSyncErrorToEntitySyncInfo(schedulerPatternSyncInfo, url, httpError);
            }
            this.deltaService.addEntitySyncInfo(schedulerPatternSyncInfo);
        }
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && (offlineFallback || loadFromDbOnly)) {
            await this.disk.retrieve(DB_KEY_PREFIXES.SCHEDULER_PATTERS).then(response => {
                if (response && response['raw']) {
                    if (this.schedulerService.schedulerList.length === 0) {
                        this.schedulerService.saveRawResponse(response['raw']);
                    }
                    else {
                        //Some data already available and now check for the page user is in
                        this.schedulerService.saveRawResponse(response['raw'], true);
                    }
                    return;
                }
            }).catch(dbError => {
                this.logService.logDebug("Error pulling scheduler route data from DB!", dbError);
            });
        }
    }

    public async fetchCustomerSegment(loadFromDbOnly = false) {
        let offlineFallback: boolean = false;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && !loadFromDbOnly) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.CUSTOMER_SEGMENT;

            const customerSegSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.customerSeg,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let response: any = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
                this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_SEGMENT, doc => ({ raw: response }))
                    .catch((err) => {
                        console.log("Error saving customer segment for scheduler data to offline db!", err);
                    });

                if (Array.isArray(response)) {
                    customerSegSyncInfo.totalSynced = response.length;
                }
                this.schedulerService.storeCustomerSegment(response);
                return;
            } catch (httpError) {
                offlineFallback = true;
                this.logService.logDebug(
                    "Error fetching scheduler route from server",
                    httpError
                );
                this.deltaService.addSyncErrorToEntitySyncInfo(customerSegSyncInfo, url, httpError);
            }

            this.deltaService.addEntitySyncInfo(customerSegSyncInfo);
        }
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && (offlineFallback || loadFromDbOnly)) {
            await this.disk.retrieve(DB_KEY_PREFIXES.CUSTOMER_SEGMENT).then(response => {
                if (response && response['raw']) {
                    this.schedulerService.storeCustomerSegment(response['raw']);
                    return;
                }
            }).catch(dbError => {
                this.logService.logDebug("Error pulling customer segment for scheduler data from DB!", dbError);
            });
        }
    }

    public async fetchPrioritize(loadFromDbOnly = false) {
        let offlineFallback: boolean = false;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && !loadFromDbOnly) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.PIORITIES;
            const prioritySyncInfo: EntitySyncInfo = {
                entityName: EntityNames.priority,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let res: any = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.SCHEDULER_PRIORITIES, doc => ({ raw: res }))
                    .catch((err) => {
                        console.log("Error saving scheduler priorities data to offline db!", err);
                    });
                if (Array.isArray(res)) {
                    prioritySyncInfo.totalSynced = res.length;
                }
                this.schedulerService.storePriority(res);
                return;
            } catch (httpError) {
                offlineFallback = true;
                this.logService.logDebug(
                    "Error fetching scheduler route from server",
                    httpError
                );
                this.deltaService.addSyncErrorToEntitySyncInfo(prioritySyncInfo, url, httpError);
            }

            this.deltaService.addEntitySyncInfo(prioritySyncInfo);
        }
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && (offlineFallback || loadFromDbOnly)) {
            await this.disk.retrieve(DB_KEY_PREFIXES.SCHEDULER_PRIORITIES).then(response => {
                if (response && response['raw']) {
                    this.schedulerService.storePriority(response['raw']);
                    return;
                }
            }).catch(dbError => {
                this.logService.logDebug("Error pulling scheduler priorities data from DB!", dbError);
            });
        }
    }

    public async fetchZip(loadFromDbOnly = false) {
        let offlineFallback: boolean = false;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && !loadFromDbOnly) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.ZIP;
            const zipSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.zip,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let res: any = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.SCHEDULER_ZIP, doc => ({ raw: res }))
                    .catch((err) => {
                        console.log("Error saving scheduler zip data to offline db!", err);
                    });

                if (Array.isArray(res)) {
                    zipSyncInfo.totalSynced = res.length;
                }
                this.schedulerService.storeZip(res);
                return;
            } catch (httpError) {
                offlineFallback = true;
                this.logService.logDebug(
                    "Error fetching scheduler route from server",
                    httpError
                );
                this.deltaService.addSyncErrorToEntitySyncInfo(zipSyncInfo, url, httpError);
            }

            this.deltaService.addEntitySyncInfo(zipSyncInfo);
        }
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && (offlineFallback || loadFromDbOnly)) {
            await this.disk.retrieve(DB_KEY_PREFIXES.SCHEDULER_ZIP).then(response => {
                if (response && response['raw']) {
                    this.schedulerService.storeZip(response['raw']);
                    return;
                }
            }).catch(dbError => {
                this.logService.logDebug("Error pulling scheduler zip data from DB!", dbError);
            });
        }
    }

    public async fetchCity(loadFromDbOnly = false) {
        let offlineFallback: boolean = false;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && !loadFromDbOnly) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.CITY;
            const citySyncInfo: EntitySyncInfo = {
                entityName: EntityNames.zip,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let res: any = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.SCHEDULER_CITY, doc => ({ raw: res }))
                    .catch((err) => {
                        console.log("Error saving scheduler city data to offline db!", err);
                    });
                if (Array.isArray(res)) {
                    citySyncInfo.totalSynced = res.length;
                }
                this.schedulerService.storeCity(res);
                return;
            } catch (httpError) {
                offlineFallback = true;
                this.logService.logDebug(
                    "Error fetching scheduler route from server",
                    httpError
                );
                this.deltaService.addSyncErrorToEntitySyncInfo(citySyncInfo, url, httpError);
            }

            this.deltaService.addEntitySyncInfo(citySyncInfo);
        }
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && (offlineFallback || loadFromDbOnly)) {
            await this.disk.retrieve(DB_KEY_PREFIXES.SCHEDULER_CITY).then(response => {
                if (response && response['raw']) {
                    this.schedulerService.storeCity(response['raw']);
                    return;
                }
            }).catch(dbError => {
                this.logService.logDebug("Error pulling scheduler city data from DB!", dbError);
            });
        }
    }

    public async createNewSchedule(obj: schedulePayload) {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.CREATE_SCHEDULE;
            let headers = Endpoints.headers.content_type.json;
            headers.headers = headers.headers.set(
                'No-Retry', 'true'
            );
            try {
              let  callplans = [];
              await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(data=>{
                if(data?.raw?.length) callplans = data.raw
              })
                return this.http.post(url, obj, headers).pipe(map(
                    (res: any) => {
                        let raw: rawScheduler = {
                            "indskr_duration": obj.indskr_duration,
                            "statecode": obj.statecode,
                            "indskr_name": obj.indskr_name,
                            "createdon": 0,
                            "indskr_schedulingpatternid": res.indskr_schedulingpatternid,
                            "indskr_enddate":  new Date(moment(obj.indskr_enddate).toDate()).valueOf(),
                            "indskr_startdate": new Date(moment(obj.indskr_startdate).toDate()).valueOf(),
                            "cycleplans": this.convertToIdList(obj.cycleplans, SchedulingPop.CP),
                            "customersegments": this.convertToIdList(obj.customersegments, SchedulingPop.CS),
                            "products": this.convertToIdList(obj.products, SchedulingPop.SB),
                            "accounts": this.convertToIdList(obj.accounts, SchedulingPop.SA),
                            "cities": this.convertToIdList(obj.city, SchedulingPop.SCi),
                            "postalCodes": this.convertToIdList(obj.zip, SchedulingPop.SZ),
                            "prioritize": this.convertToIdList(obj.prioritize, SchedulingPop.SP),
                        };

                        let newSchedule: Scheduler = this.schedulerService.convertToSheduler(raw, callplans);
                        this.schedulerService.addSchedule(newSchedule);
                        this.schedulerService.setSchedule(newSchedule);
                        return newSchedule;
                    },
                    err => {
                        console.log(err);
                    }
                )).toPromise();
            }
            catch (httpError) {
                console.log(httpError);
            }
        }
        return;
    }

    public updateSchedule(obj: schedulePayload, ID: string): Observable<any> {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.UPDATE_SCHEDULE.replace('{schedulerID}', ID);
            let headers = Endpoints.headers.content_type.json;
            headers.headers = headers.headers.set(
                'No-Retry', 'true'
            );
            try {
                return this.http.patch(url, obj, headers).pipe(map(
                    (res: any) => {
                        console.log(res);
                        return res;
                    },
                    err => {
                        console.log(err);
                    }
                ));
            }
            catch (httpError) {
                console.log(httpError);
            }
        }
        return;
    }

    public scrapSchedulerPattern(payload: string): Observable<any> {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.DELETE_SCHEDULE.replace('{schedulerID}', payload);
            let headers = Endpoints.headers.content_type.json;
            headers.headers = headers.headers.set(
                'No-Retry', 'true'
            );
            try {
                return this.http.delete(url, headers).pipe(map(
                    res => {
                        return res;
                    },
                    err => {
                        return err;
                    }
                ));
            }
            catch (httpError) {
                console.log(httpError);
                return httpError;
            }
        }
        return;
    }

    private convertToIdList(data: any[], type: string): any[] {
        let result: any[] = [];
        let k: string = "";
        //If the data exists only then check else revert empty
        if (data && Array.isArray(data)) {
            switch (type) {
                case SchedulingPop.SB:
                    k = "indskr_schedulingpattern_product";
                    break;
                case SchedulingPop.CS:
                    k = "indskr_schedulingpattern_customersegment";
                    break;
                case SchedulingPop.CP:
                    k = "indskr_schedulingpattern_cycleplan";
                    break;
                case SchedulingPop.SA:
                    k = "indskr_indskr_schedulingpattern_account";
                    break;
                case SchedulingPop.SCi:
                    k = "indskr_lu_cityid";
                    break;
                case SchedulingPop.SZ:
                    k = "indskr_lu_postalcodeid";
                    break;
                case SchedulingPop.SP:
                    k = "indskr_schedulerpriorityid";
                    break;
                default:
                    break;
            }
            data.forEach(el => {
                result.push(el[k]);
            });
        }
        return result;
    }

    public fetchMatchedContacts(data: Scheduler): any {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.FETCH_CONTACT_SUGGESTION;
            let req = data.contactFetchDTO;
            req.timezonecode = this.authenticationService.user.timezonecode;
            try {
                return this.http.post(url, req).pipe(map(
                    res => {
                        return res;
                    },
                    err => {
                        return err;
                    }
                ));
            } catch (error) {

            }
        }
        else
            return;
    }

    public fetchSuggestedMeetings(data: Scheduler): any {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.FETCH_MEETING_SUGGESTION;
            let req = data.suggestMeeetingDTO;
            req.timezonecode = this.authenticationService.user.timezonecode;
            try {
                return this.http.post(url, req).pipe(map(
                    res => {
                        return res;
                    },
                    err => {
                        return err;
                    }
                ));
            } catch (error) {

            }
        }
        else
            return;
    }

    public createBulkMeetings(data: CalendarAppointment[]): any {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.SAVE_MEETINGS_BULK;
            let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
            headers.headers = headers.headers.set(
                'X-BusinessUnitId',
                this.authenticationService.user.xBusinessUnitId
            ).set(
                "X-PositionId",
                this.authenticationService.user.xPositionID
            );

            let req: any[] = [];
            data.forEach(e => {
                req.push(e.meetingSaveDTO)
            });
            try {
                return this.http.post(url, req, headers).pipe(map(
                    res => {
                        return res;
                    },
                    err => {
                        return err;
                    }
                ));
            } catch (error) {

            }
        }
        else
            return;
    }

    public dnaFeedbackCall(data: CalendarAppointment[], schedule: Scheduler) {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.scheduler.DNA_FEEDBACK;
            let payload: any[] = [];
            data.forEach(e => {
                payload.push(e.feedbackDTO)
            });

            let dnaFeedback: any = {
                "startDate": new Date(schedule.startdate).getTime(),
                "days": schedule.durationValue,
                "timezonecode": this.authenticationService.user.timezonecode,
                "systemuserid": this.authenticationService.user.systemUserID,
                "timeslots": payload,
                "priority": schedule.priority ? schedule.priority.value : '',
                "callPlans": schedule.toIdArray(schedule.cycleplansList, "cycleplanid"),
                "products": schedule.toIdArray(schedule.productsList, "ID"),
                "segments": schedule.toIdArray(schedule.customersegmentsList),
                "accounts": schedule.toIdArray(schedule.accountList),
                "cities": schedule.toIdArray(schedule.cityList),
                "postalcodes": schedule.toIdArray(schedule.zipcodeList),
            }

            try {
                return this.http.post(url, dnaFeedback).toPromise();
            } catch (error) {
                console.log(error)
            }
        }
        else {
            return;
        }
    }

}
