<ion-grid [ngClass]="(!!uiService.showRightPane)?'preview-active':'preview-inactive'">
  <ion-row [ngClass]="{ 'team-view-enabled': authenticationOfflineService.impersonatedUser }">
    <ion-col class="left-pane">
      <ng-container [ngSwitch]="leftPaneView">

        <ng-container *ngSwitchCase="'default'">
      <activities-pane *ngIf="!leftFooter.selectedView || leftFooter.selectedView == 'activities'"
        (onAddNewActivityClick)="onAddNewActivityClick()"></activities-pane>
      <trending-customers *ngIf="leftFooter.selectedView == 'xperiences'"></trending-customers>
      <edge-analytics-list *ngIf="leftFooter.selectedView == 'edgeAnalytics'"></edge-analytics-list>
      <plans-list *ngIf="leftFooter.selectedView == 'plans'"></plans-list>
      <notification-list *ngIf="leftFooter.selectedView == 'notifications'"></notification-list>
      <footer-toolbar [hidden]="!hasBaselineEnabled" #leftFooter [footerView]="'masterView'"
        [selectedView]="selectedView" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
        </ng-container>

        <ng-container *ngSwitchCase="'shortCallLauncher'">
          <short-call-home *ngIf="!leftFooter.selectedView || leftFooter.selectedView == 'shortCallHome'"></short-call-home>
          <plans-list *ngIf="leftFooter.selectedView == 'plans'"></plans-list>
          <activities-pane *ngIf="leftFooter.selectedView == 'activities'"
            (onAddNewActivityClick)="onAddNewActivityClick()"></activities-pane>
          <notification-list *ngIf="leftFooter.selectedView == 'notifications'"></notification-list>
          <footer-toolbar [hidden]="!hasBaselineEnabled" #leftFooter [footerView]="'shortCallLauncherView'"
            [selectedView]="selectedView" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>
        </ng-container>

        <left-pane-undefined-default *ngSwitchDefault ></left-pane-undefined-default>
      </ng-container>
    </ion-col>
    <ion-col class="right-pane">
      <ion-nav #activitiespagerightpane></ion-nav>
      <div [hidden]="true" id="phone-action-bar"></div>
    </ion-col>
  </ion-row>
</ion-grid>
<ogenie-messages></ogenie-messages>
