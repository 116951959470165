
<ind-section-header [viewData]="viewData.sectionHeaderViewData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

<ion-reorder-group [ngClass]="{'empty-list': viewData.items.length == 0}"
    (ionItemReorder)="reorderData($event.detail)" [disabled]="viewData.isDisabled">
    <ng-container *ngIf="viewData.items.length">
        <div *ngFor="let item of viewData.items">
            <ion-item>
              <div class="product-key-msg-wrapper">
                <div class="product-wrapper">
                  <div #roundInput [id]="item.id" [ngClass]="{'round': !viewData.isDisabled,
                                  'round-disabled':viewData.isDisabled}" (click)="updateItem($event , item)">
                    <input [id]="item.id" type="checkbox" [ngModel]="item.isSelected" />
                    <label [for]="item.id" [ngClass]="{
                                                          'selected':item.isSelected && !item.isAutoSelected && !item.isGenieSelected,
                                                          'auto-selected': item.isAutoSelected,
                                                          'genie-selected' :item.isGenieSelected && !item.isAutoSelected}">
                    </label>
                    <span style="margin-bottom:35px; margin-left: 15px;" size="25" class="key-message-text">{{item.label}}</span>
                  </div>
                </div>
                <div class="key-messages-wrapper" *ngIf="item.childItems && item.childItems.length != 0">
                  <div class="keyMessage-container">
                      <span *ngFor="let childItem of item.childItems" (click)="updatedChildItem($event,item,childItem)" [attr.disabled]="viewData.isDisabled" id="{{item.id + '-' + childItem.id}}"
                          class="customized-button" [ngClass]="{
                    'selected':childItem.isSelected && !childItem.isAutoSelected && !childItem.isGenieSelected,
                    'auto-selected': childItem.isAutoSelected,
                    'genie-selected': childItem.isGenieSelected && !childItem.isAutoSelected}">
                    {{childItem.label}}
                    <img width="10" class="geneeWhiteFont" *ngIf="childItem.isGenieSelected" src="assets/imgs/genee_white.svg">
                  </span>
                  </div>
                  <!-- <div [id]="product.name" *ngIf="showKeyMessageArrowButtons && product.keyMessages.length != 0" (click)="slideUp($event , product.name)" class="swiper-button-next"></div> -->
              </div>
              </div>
                
                <ion-reorder slot="end"></ion-reorder>
            </ion-item>
        </div>
    </ng-container>
</ion-reorder-group>
