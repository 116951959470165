import { ActivityType } from './../activity/activity.class';
import _ from 'lodash';


export class WebsiteAccessLogs {
  public ID: string;
  public contactId: string;
  public accessDateTime: Date;
  public _createdOn: Date;
  public channel: string;
  public department: string;
  public pageTitle: string;
  public pageURL: string;
  public additionalInfo: string
  public contactName: string
  public type: ActivityType;

  constructor(raw) {
    this.ID = raw['indskr_websiteaccesslogsid'];
    this.contactId = raw['_indskr_customer_value'];
    this.channel = raw['indskr_channel'] || '';
    this.department = raw['indskr_department'] || '';
    this.pageTitle = raw['indskr_pagetitle'] || '';
    this.pageURL = raw['indskr_pageurl'] || '';
    this.additionalInfo = raw['indskr_additionalinfo'] || '';
    this.contactName = raw['_indskr_customer_value@OData.Community.Display.V1.FormattedValue'] || '';
    try {
      this._createdOn = raw['createdon'] ? new Date(raw['createdon']) : null;
    } catch {
      this._createdOn = raw['createdon'] ? new Date(parseInt(raw['createdon'])) : null;
    }

    try {
      this.accessDateTime = raw['indskr_accessdatetime'] ? new Date(raw['indskr_accessdatetime']) : null;
    } catch (error) {
      this.accessDateTime = raw['indskr_accessdatetime'] ? new Date(parseInt(raw['indskr_accessdatetime'])) : null;
    }


  }


  

  public get startDate(): Date {
    return this.accessDateTime;
  }

}

