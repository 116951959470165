<!--Genee Notifications View-->
<ion-grid no-padding>
  <ion-row>
    <ion-col class="left-pane">
      <div *ngIf="noDataToDisplay" class="no-notifications-area">
        <div class="middle-center">
          {{'MY_ASSISTANT_NO_NOTIFICATIONS' | translate}}
        </div>
      </div>
      <!--Genee Notifications Header-->
      <div class="geneeNotifcationListHeader">
        <ion-toolbar class="genee-toolbar">
          <ion-buttons slot="start">
            <ion-button (click)="actionGoBack()">
              <ion-icon class="back-button-icon" name="chevron-back-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="clearNotifications()" class="backButton">
              <ion-badge class="clearButton">
                {{'CLEAR' | translate}}
              </ion-badge>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="actionDismissGeneeAssistant()" class="backButton">
              <img src="assets/imgs/closeGeneeScreen.svg" width="25" height="25" />
            </ion-button>
          </ion-buttons>
          <ion-title>{{'NOTIFICATIONS' | translate}}</ion-title>
        </ion-toolbar>
        <div class="searchBoxContainer">
          <ion-searchbar [value]="assistantService.notificationsSearchText" [debounce]="500" [disabled]="!assistantService.myAssistantData.length"
            (ionInput)="onInput($event)" (ionClear)="onSearchClear($event)" placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}">
          </ion-searchbar>
        </div>
      </div>
      <!--  -->
      <!-- Notifications List -->
      <div no-padding class="geneeNotifcationListContainer" *ngIf="!noDataToDisplay">
        <ion-content>
          <div *ngFor="let group of groupedData">
            <ion-item-divider [id]="group.key">
              <div class="monthSeparator">
                <div class="dividerBorder"></div>
                <div class="monthGroupName" text-center>
                  {{group.key}}
                </div>
              </div>
            </ion-item-divider>
            <div *ngFor="let item of group.list">
              <div class="description-wrapper">
                <div class="description-container">
                  <ul>
                    <li class="blueDots" *ngIf="!item.read">{{item.Description}}</li>
                    <li *ngIf="item.read">{{item.Description}}</li>
                  </ul>
                </div>
              </div>
              <p class="notificationDate">{{formatDate(item.timeStamp)}}</p>
            </div>
          </div>
        </ion-content>
      </div>
      <!--  -->
    </ion-col>
  </ion-row>
</ion-grid>
