
import { ActivityService } from './../../../services/activity/activity.service';
import { AppointmentActivity } from "./../../../classes/activity/appointment.activity.class";
import { Component, Input } from "@angular/core";
import { NavController } from "@ionic/angular";
import { ParticipantListComponent } from "../participant-list/participant-list";
import { UIService } from "../../../services/ui/ui.service";
import { Resource } from '../../../classes/resource/resource.class';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';

@Component({
  selector: "shared-resources",
  templateUrl: "shared-resources.html",
  styleUrls:['shared-resources.scss']
})
export class SharedResourcesComponent {
  @Input()
  public activity: AppointmentActivity;
  // public resources: Resource[] = [];

  @Input() isReadOnlyJointMeeting:boolean = false;

  constructor(public navCtrl: NavController,
    private uiService: UIService,
    private activityService: ActivityService,
    public translate: TranslateService,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    // this.resources = [...new Set(this.activity.sharedResources.map(sr=>sr.resource))];
  }

  openSharedResourceDetails(resource) {
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = "ActivitiesPageRightPaneNav";
    this.activityService.selectedSharedResource = resource;
    if (this.navService.getCurrentMasterPageName() === PageName.CallPlanComponent) {
      this.navService.pushChildNavPageWithPageTracking(ParticipantListComponent, PageName.ParticipantListComponent, PageName.CallPlanComponent, {resource: resource, appointment: this.activity});
    } else if(this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
      this.navService.pushChildNavPageWithPageTracking(ParticipantListComponent, PageName.ParticipantListComponent, PageName.ContactPageComponent, {resource: resource, appointment: this.activity});
    } else if(this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
      this.navService.pushChildNavPageWithPageTracking(ParticipantListComponent, PageName.ParticipantListComponent, PageName.ActivitiesPageComponent, {resource: resource, appointment: this.activity});
    }
    // this.navCtrl.push(ParticipantListComponent,
    //   {
    //     resource: sharedResource.resource,
    //     appointment: this.activity
    //   });
//    participantsModal.present({ ev: event });
    event.stopPropagation();
  }
}
