import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { TimeOffDetailsComponent } from '../time-off-details/time-off-details';
import { ActivityService } from '../../../services/activity/activity.service';
import { TimeOffService, filterModel } from '../../../services/time-off/time-off.service'
import { TimeOffDataService } from '../../../data-services/time-off/time-off.data.service';
import { TimeOffActivity, TimeOffStatus } from '../../../classes/activity/timeoff.class';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { format, isTomorrow, isToday } from "date-fns";
import { PopoverComponent } from "../../popover/popover";
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import {
  PopoverController,
  NavController, IonList
} from "@ionic/angular";
import { Events } from '@omni/events';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { TimeOffUtilityService } from '../../../services/time-off/time-off-utility.service';
import { TranslateService } from '@ngx-translate/core';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { TImeOffReason } from '../../../classes/timeoff/timeoffReasons.class';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from '../../../models/search-config-data-model';
import { SearchConfigService } from '../../../services/search/search-config.service';
import { DB_KEY_PREFIXES } from '../../../config/pouch-db.config';
import { DiskService } from '../../../services/disk/disk.service';
import * as _ from 'lodash';
import { AlertService } from '../../../services/alert/alert.service';
import { SearchConfigDataService } from '../../../data-services/search-config/search-config-data-service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalizationService } from '@omni/services/localization/localization.service';

/**
 * Generated class for the TimeOffListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'time-off-list',
  templateUrl: 'time-off-list.html',
  styleUrls:['time-off-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeOffListComponent implements OnInit, OnChanges {

  public timeOffActivityModel: string = "";
  displayNoTimeOffActivity: boolean = false;

  @Output() closeModal = new EventEmitter<string>();
  @Output() onNewTimeOff = new EventEmitter<any>();
  @Output() timeOffSelected = new EventEmitter<TimeOffActivity>(null);

  public totList: TimeOffActivity[] = [];
  public teamTotList: TimeOffActivity[] = [];
  public filteredList: TimeOffActivity[] = [];
  // public teamFilteredList: TimeOffActivity[] = [];

  public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  public weekDay: Array<String> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  public istoday = isToday;
  public hasMyTimeOff: boolean = false;
  public hasTeamTimeOff: boolean = false;
  private filterValue: any = "";

  public filter: filterModel = { all: "all", status: "", position: "", user: "" };
  public filterPopoverData: any;
  teamFilterPopoverData: any;

  @Input() forceReload: boolean = false;
  public itemExpandHeight: number = 25;
  @ViewChild('expWrapper', {static: true}) expandWrapper: ElementRef;
  @ViewChildren('searchResult') filteredItems;

  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;

  // searchTextModel: string;
  searchText: string;
  //@ViewChild(Content, {static: true}) content:Content;
  // filterValueSelected:boolean = false;
  // public isOfflineState: boolean = false;

  sortPopoverData: any;
  teamSortPopoverData: any;
  sortBy:{text: string, value: string, asc: boolean} = {text: this.translate.instant('TIME_OFF_DATE'), value: "totStartTime", asc: false}
  teamSortBy:{text: string, value: string, asc: boolean} = {text: this.translate.instant('TIME_OFF_DATE'), value: "totStartTime", asc: false}

  public searching: boolean = false;
  timeOffSearchText: string;
  shouldFireSearchChangeEvent: boolean = true;
  suggestionsData: {
      header:IndSectionHeaderViewDataModel,
      listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey : string = '';
  selectedSuggestionsData:  SelectedSuggestionPillDataModel[] = [];
  suggestionsActive: boolean = false;
  disableSaveSearch: boolean = false;
  public filterMetadata = { count: 0 };
  tabsData: IndTabsDataModel[];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  listKeyControlAreaModel: IndKeyControlsAreaModel;
  allTimeOffSectionHeaderData: IndSectionHeaderViewDataModel;
  resultsTimeOffSectionHeaderData: IndSectionHeaderViewDataModel;
  allTeamTimeOffSectionHeaderData: IndSectionHeaderViewDataModel;
  TimeOffSectionHeaderData: IndSectionHeaderViewDataModel;
  private ngDestroy$ = new Subject<boolean>();

  constructor(
    private navCtrl: NavController,
    public footerService: FooterService,
    private uiServices: UIService,
    public device: DeviceService,
    public activityService: ActivityService,
    public timeOffService: TimeOffService,
    private navService: NavigationService,
    private changeDetectionRef: ChangeDetectorRef,
    public authenticationService: AuthenticationService,
    private popoverCtrl: PopoverController,
    public trackingService: TrackService,
    private TimeOffDataService: TimeOffDataService,
    public repService: RepServices,
    private activityDataService: ActivityDataService,
    private notifyService: NotificationService,
    private timeOffUtilityService: TimeOffUtilityService,
    private translate: TranslateService,
    public searchConfigService: SearchConfigService,
    public searchConfigDataService: SearchConfigDataService,
    public timeOffListElement: ElementRef,
    public disk: DiskService,
    private alertService: AlertService,
    private events: Events,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private localizationService: LocalizationService,
  ) {
    this.uiServices.showNewActivity = true;
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
      this.hasMyTimeOff = true;
    }
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TEAM_REQUESTS)) {
      this.hasTeamTimeOff = true;
    }
    // this.isOfflineState = this.repService.isOfflineState; //Not used?
  }

  ngOnInit() {
    this.initTimeOffHeaderLeft();
    this.setTabsData();
    this.timeOffSearchText = "";
    this.activityService.selectedActivity = null;
    this.timeOffService.filterObserver.subscribe((filter: filterModel) => {
      this.changeDetectionRef.detectChanges();
      if (filter) {
        this.forceReload = !this.forceReload;
        this.filter = filter;
        this.forceReload = !this.forceReload;
      }
      this.initSectionHeaders();
      this.changeDetectionRef.detectChanges();
      this.changeDetectionRef.markForCheck();
    });

    this.timeOffService.totObserver.subscribe((tot: TimeOffActivity[]) => {
      this.changeDetectionRef.detectChanges();
      if (tot) {
        this.totList = tot;
        this.totList.map(timeoff => { timeoff.expanded = false; timeoff.expandIcon = 'accordion-add'});
        this.forceReload = !this.forceReload;
      }
      if(this.searching){
        this.searchTimeOffList();
        if(this.timeOffService.totMode != "teamRequests"){
          this.searchTimeOffSuggestions(this.totList, this.searchKey);
        } else {
          this.searchTimeOffSuggestions(this.teamTotList, this.searchKey);
        }
      }
      this.initSectionHeaders();
      this.changeDetectionRef.detectChanges();
      this.changeDetectionRef.markForCheck();
      this.sortMyTimeOffBySortSelection(this.sortBy);
      this.updateEmptyMessage()
    });
    this.timeOffService.teamTotObserver.subscribe((tot: TimeOffActivity[]) => {
      this.changeDetectionRef.detectChanges();
      if (tot) {
        this.teamTotList = tot.filter(t => t.statuscode != TimeOffStatus.Open);
        this.forceReload = !this.forceReload;
      }
      this.initSectionHeaders();
      this.changeDetectionRef.detectChanges();
      this.changeDetectionRef.markForCheck();
      this.sortTeamTimeOffBySortSelection(this.teamSortBy);
      this.updateEmptyMessage()
    });

    if(this.searchConfigService.configUpdateRequired){
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }

    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "totStartTime",
        items: [
          { text: this.translate.instant('TIME_OFF_DATE'), value: "totStartTime", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.sortMyTimeOffBySortSelection(selectedItem);
          this.timeOffService.refreshMyTimeOffList();
        }
      }
    ]
    this.sortBy = this.sortPopoverData[0].items[0];
    this.teamSortPopoverData=[
      {
        text: "",
        expanded: true,
        value: "totStartTime",
        items: [
          { text: this.translate.instant('TIME_OFF_DATE'), value: "totStartTime", asc: false },
          { text: this.translate.instant('CREATED_BY'), value: "totOwnerValue", asc: true }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.teamSortBy = selectedItem;
          this.sortTeamTimeOffBySortSelection(selectedItem);
          this.timeOffService.refreshTeamTimeOffList();
        }
      }
    ]
    this.teamSortBy = this.teamSortPopoverData[0].items[0];
    if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT)){
      this.sortPopoverData[0].items.push({ text: this.translate.instant('SUBJECT'), value: "subject", asc: true })
    }
    this.updateEmptyMessage();

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if(this.timeOffActivityModel != 'myRequests'){
        this.uiServices.dismissLoader();
      }
      this.setTabsData();
      this.changeDetectionRef.detectChanges();
    });
  }
  private initSectionHeaders() {
    this.allTimeOffSectionHeaderData = {
      id: 'all-timeOff',
      title: this.translate.instant("ALL_TIME_OFF") + " (" + this.totList.length + ")",
      controls: [
        {
          id: 'all-timeoff-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img:'assets/imgs/sort_with_double_arrows.svg'
        }
      ]
    };

    this.resultsTimeOffSectionHeaderData = {
      id: 'results-timeOff',
      title: this.translate.instant("RESULTS_CAP") + " (" + this.filteredList.length + ")",
      controls: [
        {
          id: 'all-timeoff-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img:'assets/imgs/sort_with_double_arrows.svg'
        }
      ]
    };
    this.allTeamTimeOffSectionHeaderData = {
      id: 'results-timeOff',
      title: this.translate.instant("ALL_TIME_OFF") + " (" + this.teamTotList.length + ")",
      controls: [
        {
          id: 'all-timeoff-sort',
          text: this.teamSortBy.text,
          isDisabled: false,
          img:'assets/imgs/sort_with_double_arrows.svg'
        }
      ]
    };
  }
  public onSectionHeaderControlClick(id: string) {
    if (id === 'all-timeoff-sort') {
        this.sortTimeOffList();
    }
  }
  private _initKeyControlsAreaModel() {
    this.listKeyControlAreaModel = {
      id: 'timeoff-list-key-controls-area',
      isHidden: false,
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
      controls: [
        {
          id: 'save-search',
          text: this.translate.instant('SAVE_SEARCH'),
          isHidden: false,
          leftAligned: true,
          isDisabled: !this.searching || this.disableSaveSearch
        }
      ]
    };
  }

  private handleControlsAreaEvent(id, event, eventName) {
    if (id && eventName && eventName == 'controls-click') {
      switch (id) {
        case 'save-search':
          this.saveAdvancedSearch();
          break;
        default:
          console.log('Unhandled switch case statement');
      }
    }
  }


  updateEmptyMessage(){
      let dataSize = 0
      if(this.searching){
        dataSize = this.filteredList.length
      }
      else{
        if(this.timeOffActivityModel === 'myRequests'){
          dataSize = this.totList.length
        }
        else{
          dataSize = this.teamTotList.length
        }
      }
      this.uiServices.updateNothingSelectedScreenMessageFor(dataSize);
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
    this.checkActivityModel("myRequests");
    this.changeDetectionRef.detectChanges();
    this.changeDetectionRef.markForCheck();
  }

  sortMyTimeOffBySortSelection(selectedSortOption){
    let list: any;
    if(!this.searching) list = this.totList
    else list = this.filteredList;

    if(selectedSortOption.value === 'totStartTime'){
      list.sort((a, b) => {
        let sort = a.totStartTime.getTime() - b.totStartTime.getTime();
        if(sort === 0)return (a.subject.toLowerCase() > b.subject.toLowerCase()) ? 1 : -1;
        else return sort>0 ? -1 : 1;
      })
    } else if(selectedSortOption.value === 'subject'){
      list.sort((a, b) => {
        if(a.subject.toLowerCase() === b.subject.toLowerCase()){
           return (a.totStartTime.getTime() > b.totStartTime.getTime()) ? -1 : 1;
        } else return (a.subject.toLowerCase() > b.subject.toLowerCase()) ? 1 : -1;
      })
    } else {
      if (selectedSortOption.asc) {
        list.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
          else return -1;
        })
      }
      else {
        list.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
          else return 1;
        })
      }
    }
  }

  sortTeamTimeOffBySortSelection(selectedSortOption){
    let list: any;
    if(!this.searching) list = this.teamTotList
    else list = this.filteredList;

    if(selectedSortOption.value === 'totStartTime'){
      list.sort((a, b) => {
        let sort = a.totStartTime.getTime() - b.totStartTime.getTime();
        if(sort === 0) return (a.totOwnerValue.toLowerCase() > b.totOwnerValue.toLowerCase()) ? 1 : -1;
        else return sort>0 ? -1 : 1;
      })
    } else if(selectedSortOption.value === 'totOwnerValue'){
      list.sort((a, b) => {
        if(a.totOwnerValue.toLowerCase() === b.totOwnerValue.toLowerCase()){
           return (a.totStartTime.getTime() > b.totStartTime.getTime()) ? -1 : 1;
        } else return (a.totOwnerValue.toLowerCase() > b.totOwnerValue.toLowerCase()) ? 1 : -1;
      })
    } else {
      if (selectedSortOption.asc) {
        list.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
          else return -1;
        })
      }
      else {
        list.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
          else return 1;
        })
      }
    }
  }

  /**
   *
   *
   * @param {string} model
   * @memberof TimeOffListComponent
   */
  checkActivityModel(model: string) {
    if (model === 'myRequests') {
      this.searchText = this.translate.instant("SEARCH_MY_REQUEST");
      this.allTimeOffSectionHeaderData.title = this.translate.instant("ALL_TIME_OFF") + " (" + this.totList.length + ")";
    } else {
      this.searchText = this.translate.instant("SEARCH_TEAM_REQUEST");
      this.allTeamTimeOffSectionHeaderData.title = this.translate.instant("ALL_TIME_OFF") + " (" + this.teamTotList.length + ")";
      if(this.timeOffService.teamTot.length == 0) {
        this.uiServices.displayLoader();
        this.getTeamOffData();
      }
    }
    this.timeOffSearchText = "";
    if(model === 'myRequests') this.sortMyTimeOffBySortSelection(this.sortBy);
    else this.sortTeamTimeOffBySortSelection(this.teamSortBy);
    this.removeAllSelectedSuggestions();
    this.changeDetectionRef.detectChanges();
    this.uiServices.showNewActivity = true; // nothing selected
    this.timeOffActivityModel = model;
    this.activityService.selectedActivity = null;
    this.timeOffService.setTOTMode(model);
    this.changeDetectionRef.detectChanges();
    this.changeDetectionRef.markForCheck();
    this.updateEmptyMessage()
    this.uiServices.scrollListToView(this.list);
  }

  /**
   * Set seleted Time off
   * @memberof TimeOffListComponent
   */
  async openTimeOffActivityDetails(tot: TimeOffActivity) {
    let timeOff: TimeOffActivity = tot;
    if (!this.device.isOffline && !timeOff.ID.includes("offline_")) {
      if (!(this.timeOffService.totMode == "teamRequests")) {
        await this.activityDataService.updateActivityDetails(tot);
      } else {
        this.activityService.selectedActivity = timeOff;
      }
      if (this.activityService.selectedActivity)
        timeOff = <TimeOffActivity>this.activityService.selectedActivity;
      if (timeOff.statusValue !== tot.statusValue) this.timeOffService.updateSelectedTot(timeOff);
    }
    this.activityService.selected = timeOff;
    if (this.timeOffService.totMode == "teamRequests") {
      //If team time off is Open in latest record, notify user and remove from DB
      if (timeOff.statecode == 0) {
        this.notifyService.notify(this.translate.instant('TIME_OFF_REQUEST_REOPENED', { Ownervalue: timeOff.totOwnerValue }), '', 'top', '')
        await this.timeOffService.onTimeOffReopened(timeOff, this.activityService.teamViewActive, !this.device.isOffline);
      }
    }
    console.log("selected from list");
    this.timeOffService.setMobileTracker("timeOff");
    this.timeOffSelected.emit(timeOff);
    this.footerService.initButtons(FooterViews.TimeOffDetails);
    /* to update the parent about the selected TO */
  }

  /**
   *
   *
   * @memberof TimeOffListComponent
   */
  makeNewTimeOffActivity() {
    this.timeOffService.totMode = 'myRequests';
    this.timeOffActivityModel = this.timeOffService.totMode;
    this.trackingService.tracking('TimeOffTimeOffTool', TrackingEventNames.TIMEOFF);
    this.onNewTimeOff.emit(null);
  }

  /**
   *
   *
   * @memberof TimeOffListComponent
   */
  onCloseModal(event) {
    // this.timeOffService.resetAllRepTimeOffFilters();
    this.closeModal.emit(event);
    // emit this event to the time-off component
  }

  public formatHeader(value): string {
    let edge = window.navigator.userAgent.indexOf('Edge/');
    let formatedDate: any;
    if (edge > 0) {
      let tempDate = value.split("-");
      let currentMonthIndex: number;
      let filteredMonth = this.months.filter((month: string, index: number) => {
        let compare = month.substring(0, 3);
        currentMonthIndex = index;
        return compare === tempDate[1];
      });
      let realDate = new Date(Date.parse(tempDate[0] + " " + tempDate[1] + " " + tempDate[2]));
      let dayOfTheWeek = this.weekDay[realDate.getDay() - 1];
      tempDate = dayOfTheWeek + " " + filteredMonth[filteredMonth.length - 1] + " " + tempDate[0];
      formatedDate = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10) >= 16 ? tempDate : value;
    } else {
      formatedDate = isToday(value) ? "Today" : isTomorrow(value) ? "Tomorrow" : format(value, 'dddd MMM D');
    }
    return formatedDate;
  }

  public setHeaderColor(value) {
    let headerColor = isToday(value) ? "primary" : 'light';
    return headerColor;
  }

  /**
   * Expand activity time and delete options
   *
   * @memberof ActivitiesPaneComponent
   */
  expandList = (event, activityItem: TimeOffActivity): void => {
    event.stopPropagation();
    activityItem.expanded = !activityItem.expanded;
    activityItem.expandIcon = (activityItem.expanded) ? 'accordion-minus' : 'accordion-add';
  }
  scrapTimeOff(timeoff: TimeOffActivity) {
    let view: string = this.timeOffService.getMobileTracker();
    this.timeOffUtilityService.scrapTimeOff(timeoff, view);
  }

  /* Reason for team view format */
  public getReasonTeam(timeOffData): string {
    let reason: string = "";
    this.timeOffService.timeOffReasonList.map((item: TImeOffReason) => {
      if (timeOffData.timeOffReason === item.reasonCode) {
        reason = item.reason;
      }
    });
    return this.localizationService.getTranslatedString(reason) + ": " + timeOffData.name;
  }

  /* Reason for my-request format */
  public getReason(timeOffData): string {
    let reason: string = "";
    this.timeOffService.timeOffReasonList.map((item: TImeOffReason) => {
      if (timeOffData.timeOffReason === item.reasonCode) {
        reason = item.reason;
      }
    });

    //let timeOffreason = this.getReasontranslation(reason);
    return this.localizationService.getTranslatedString(reason);
  }

  public getTimeOffMainCardModel(timeOff: TimeOffActivity): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    let reason = this.getReason(timeOff);
    if (timeOff) {
      viewData = {
        id: timeOff.ID,
        fixedHeight: true,
        primaryTextLeft: _.isEmpty(timeOff.reason) ? this.getTimeOffstatusValue(timeOff) : this.timeOffActivityModel == 'myRequests' ? this.getReason(timeOff) : this.getReasonTeam(timeOff),
        // secondaryTextLeft: this.timeOffService.getFormattedTimeInterval(timeOff),
        //showIcon: true,
        //iconName : timeOff.statuscode != 100000004 ? "indegene-assets/imgs/tot_unapproved_status.svg":"indegene-assets/imgs/tot_approved_status.svg",
        primaryTextRight: this.timeOffActivityModel == 'myRequests' ? (timeOff.reason ? this.localizationService.getTranslatedString(timeOff.reason) : '') ? timeOff.subject == 'Time Off' ? this.translate.instant('TIME_OFF') : timeOff.subject  :timeOff.subject=='Time Off'?this.translate.instant('TIME_OFF'):timeOff.subject:timeOff.totOwnerValue,
        secondaryTextRight: (timeOff.totStartTime) ? this.getFormattedAndLocalisedDate(timeOff.totStartTime) : '',
        secondaryTextRightTwo: !_.isEmpty(timeOff.reason) && (timeOff.statusValue) ? this.getTimeOffstatusValue(timeOff) : '',
        isSelected: this.activityService.selectedActivity && timeOff.timeOffRequestId === (this.activityService.selectedActivity as TimeOffActivity).timeOffRequestId,
        isExpandable: this.timeOffActivityModel == 'myRequests',
        isExpanded: this.timeOffActivityModel == 'myRequests' ? timeOff.expanded : false,
        expandableData: timeOff,
        expandableViewType: this.timeOffActivityModel == 'myRequests' ? 'activitiesList' : '',
        showArrow: false,

        expandHeight: this.itemExpandHeight,
        clickHandler: (id: string, event, specificTarget) => {

          let foundTimeOff: TimeOffActivity;
          if (this.timeOffActivityModel == 'myRequests') {
            foundTimeOff = this.timeOffService.tot.find(timeoff => timeoff.ID == id);
          } else {
            foundTimeOff = this.timeOffService.teamTot.find(timeoff => timeoff.ID == id);
          }
          if (foundTimeOff) {
            this.openTimeOffActivityDetails(foundTimeOff);
          }
        }
      };
    }
    return viewData;
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, 'MMM dd', undefined, this.translate.currentLang);
  }

  public getTimeOffstatusValue(timeOff): string {
    let statusValue;
    switch (timeOff.statusValue) {
      case 'Open':
        statusValue = this.translate.instant('TIMEOFF_OPEN');
        break;
      case 'InReview':
        statusValue = this.translate.instant('POP_IN_REVIEV');
        break;
      case 'NotApproved':
        statusValue = this.translate.instant('NOT_APPROVED');
        break;
      case 'Approved':
        statusValue = this.translate.instant('APPROVED');
        break;
      case 'For Review':
        statusValue = this.translate.instant('FOR_REVIEW');
        break;
      default:
        statusValue = timeOff.statusValue;
        break;
    }
    return statusValue;
  }

  sortTimeOffList(){
    if(this.timeOffService.totMode == "teamRequests"){
      this.popoverCtrl
      .create({component: MultiSelectPopover,componentProps: { root: this.teamSortPopoverData },event:event})
      .then((data)=>data.present());
    } else {
      this.popoverCtrl
      .create({component:MultiSelectPopover,componentProps: { root: this.sortPopoverData },event:event})
      .then((data)=>data.present());
    }
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
      if(this.suggestionsActive) {
          this.suggestionsActive = false
      }
      this.changeDetectionRef.detectChanges();
  }

  onInput(event) {
      this.searchKey = '';
      let params = (event.value) ? event.value : '';
      if(!this.shouldFireSearchChangeEvent && !params){
        this.shouldFireSearchChangeEvent = true;
        return;
      }
      //for length > 2
      if (params.length > 2) {
        if (params.trim().length == 0) return;
        this.searching = true;
        this.searchKey = params;
        this.shouldFireSearchChangeEvent = true;
        if(this.timeOffService.totMode != "teamRequests"){
          this.searchTimeOffSuggestions(this.totList, params, event);
        } else {
          this.searchTimeOffSuggestions(this.teamTotList, params, event);
        }
      }
      // for length 0 to 2
      else {
          this.suggestionsData = [];
          if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
            this.filteredList = [];
            this.searching = false;
          }
          else{
            this.searchTimeOffList();
          }
          if (this.timeOffService.totMode != "teamRequests") {
            if(!this.timeOffService.recentSearches || !this.timeOffService.recentSearches.length){
              let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Time Off')
              if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                this.suggestionsActive = false;
              }
            }
          }
          else {
            if(!this.timeOffService.teamRecentSearches || !this.timeOffService.teamRecentSearches.length){
              let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Time Off');
              if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                this.suggestionsActive = false;
              }
            }
          }
      }
      this._initKeyControlsAreaModel();
      this.resultsTimeOffSectionHeaderData.title = this.translate.instant("RESULTS_CAP") + " (" + this.filteredList.length + ")";
      this.changeDetectionRef.detectChanges();
      this.updateEmptyMessage();
  }

  //search the facets list to display suggestions and also do text search for contacts list
  searchTimeOffSuggestions(timeOffs: TimeOffActivity[], searchText: string, event?){
    if (searchText && timeOffs) {
      searchText = searchText.toUpperCase();
      let searchIndexConfig:any;
      if(this.timeOffService.totMode == "myRequests") {
        searchIndexConfig = this.searchConfigService.myTimeOffSearchIndexesConfig;
      } else {
        searchIndexConfig = this.searchConfigService.teamTimeOffSearchIndexesConfig;
      }
      this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig,this.selectedSuggestionsData,this.translate.instant("TIME_OFF"),this.timeOffSearchText)
      if(this.suggestionsData.length) this.suggestionsActive = true;
      if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
        this.filteredList = timeOffs
      }
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).toLowerCase();
      this.filteredList = this.filteredList.filter((timeOff)=>{
        let timeOffString = this.getOjectValues(timeOff);
        return timeOffString.includes(formattedSearchText);
      })
    } else {
      return [];
    }

    this.resultsTimeOffSectionHeaderData.title = this.translate.instant("RESULTS_CAP") + " (" + this.filteredList.length + ")";

    this.filterMetadata.count = this.filteredList.length;
  }


  //search the list based off selected facets
  searchTimeOffList(){
    //second level search for time Off
    this.searching = true;
    let filteredTimeOff:any;
    if(this.timeOffService.totMode != "teamRequests") {
      filteredTimeOff = this.totList;
    } else {
      filteredTimeOff = this.teamTotList;
    }
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toLowerCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredTimeOff = filteredTimeOff.filter(timeOff => {
          let timeOffEntityLevel = this.getOjectValues(timeOff);
           return timeOffEntityLevel.includes(searchText);
         });
      });
    }
    filteredTimeOff = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy,filteredTimeOff)
    this.filteredList = filteredTimeOff;
    this.resultsTimeOffSectionHeaderData.title = this.translate.instant("RESULTS_CAP") + " (" + this.filteredList.length + ")";
  }

  getOjectValues(timeOffObject): string {
    let objString: string[] = [];
    let timeOffInclude: any;
    if(this.timeOffService.totMode == "myRequests") timeOffInclude = ['subject'];
    else timeOffInclude = ['totOwnerValue'];
    for (let timeOff in timeOffObject) {
      if (timeOffInclude.indexOf(timeOff) > -1 && timeOffObject[timeOff]) {
        if (Array.isArray(timeOffObject[timeOff])) {
          for (let i = 0; i < timeOffObject[timeOff].length; i++) {
            objString.push(this.getOjectValues(timeOffObject[timeOff][i]));
          }
        } else {
          objString.push(timeOffObject[timeOff]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toLowerCase());
  }

  ionSearchFocus(ev) {
    try {
      let el = this.timeOffListElement.nativeElement.ownerDocument.getElementById('allTimeOff');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  clickSearchArea(ev){
    ev.stopPropagation();
    if(!this.suggestionsActive) {
      this.suggestionsActive = true;
      if(this.timeOffService.totMode != "teamRequests" && (!this.timeOffService.recentSearches || !this.timeOffService.recentSearches.length)){
        let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Time Off')
        if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
          this.suggestionsActive = false;
        }
      } else if(this.timeOffService.totMode == "teamRequests" && (!this.timeOffService.teamRecentSearches || !this.timeOffService.teamRecentSearches.length)) {
        let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Time Off')
        if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
          this.suggestionsActive = false;
        }
      }
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }

  handleFacetSelection(data:SelectedSuggestionPillDataModel){
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a,b)=>{
      if(a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.timeOffSearchText = '';
    this.suggestionsData= [];
    this.shouldFireSearchChangeEvent = false;
    this.searchTimeOffList();
    if(!data.isComingFromRecentSearch){
      let recentSearches;
      if(this.timeOffService.totMode == "myRequests") {
        recentSearches = this.timeOffService.recentSearches
        if(!recentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
          recentSearches.unshift(data);
          _.remove(recentSearches, (o, index)=>index>2);
          this.disk.updateOrInsert(DB_KEY_PREFIXES.TIME_OFF_RECENT_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc={
                raw:[]
              }
            }
            doc.raw = recentSearches;
            return doc;
          })
        }
      } else {
        let teamRecentSearches = this.timeOffService.teamRecentSearches
        if(!teamRecentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
          teamRecentSearches.unshift(data);
          _.remove(teamRecentSearches, (o, index)=>index>2);
          this.disk.updateOrInsert(DB_KEY_PREFIXES.TIME_OFF_TEAM_RECENT_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc={
                raw:[]
              }
            }
            doc.raw = teamRecentSearches;
            return doc;
          })
        }

      }
    }
    this._initKeyControlsAreaModel();
  }

  handleSavedSearchSelection(data){
    this.selectedSuggestionsData = [];
    if(data && data.categoryValuePairs){
      data.categoryValuePairs.forEach(catValPair=>{
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a,b)=>{
        if(a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.timeOffSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchTimeOffList();
    }
  }

  removeSelectedSuggestion(suggestion){
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData,(o)=>_.isEqual(o,suggestion));
    if(this.selectedSuggestionsData.length == 0){
      //this.content.resize();
      this.filteredList = [];
      this.timeOffSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
    }
    else{
      this.searchTimeOffList();
    }
    this._initKeyControlsAreaModel();
  }

  removeAllSelectedSuggestions(){
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.timeOffSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this._initKeyControlsAreaModel();
  }

  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let toolName;
    if(this.timeOffService.totMode == "teamRequests") {
      toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Team Time Off');
    } else {
      toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='My Time Off');
    }
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==toolName.searchToolNameID);
    this.alertService.showAlert({ title:this.translate.instant('SAVE_SEARCH'),
                                  subTitle:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          this.disableSaveSearch = true;
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                toolName?toolName.searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.selectedSuggestionsData.slice())
        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
        this._initKeyControlsAreaModel();
      }
    })
  }

  setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('TIMEOFF_MY_REQUESTS'),
         value: "myRequests",
         hide: !this.hasMyTimeOff
      },
      {
       displayText: this.hasTeamTimeOff ? this.translate.instant('TIMEOFF_TEAM_REQUESTS') : '',
       value: "teamRequests",
       disable: !this.hasTeamTimeOff || (this.device.isOffline && this.timeOffService.teamTot.length == 0),
      },
    ];
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onCloseModal(id);
        break;
      case 'plusNew':
        this.makeNewTimeOffActivity();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private initTimeOffHeaderLeft(): void {
    let buttons = [];
    buttons.push({
        id: "close",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      },
      {
        id: "plusNew",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        isDisabled: false,
        align: "right",
        tooltip: this.translate.instant('NEW_TIME_OFF'),
      });
    this.indHeaderLeftModel = {
      id: 'timeoff-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('TIME_OFF'),
      mode: true,
      controls: buttons,
    };
  }

  private getTeamOffData(){
    this.TimeOffDataService.getTeamTimeOffOnline().then(succ=> {
      this.timeOffService.refreshTeamTimeOffList();
      if (!_.isEmpty(this.timeOffService.teamTot)) {
        this.timeOffService.teamTot.forEach((data: TimeOffActivity) => {
          if (data.statuscode != TimeOffStatus.Open){
            this.timeOffService.mapTeamTimeOffFieldsToSearchIndex(data);
          }
        });
      }
      this.uiServices.dismissLoader();
    }).catch(error => console.log('Error occured while fetch team time off online: '+error));
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.timeOffService.teamTot = [];
    this.timeOffService.refreshTeamTimeOffList();
  }

}
