<ion-header>
  <ind-page-title [viewData]="affiliationPageTitle" (onControlClick)='navService.popChildNavPageWithPageTracking()'>
  </ind-page-title>
</ion-header>

<ion-content>
    <ind-section-header sticky='true' [viewData]='affiliationFilterViewData' (onControlClick)="onFilterControlClick($event)">
                </ind-section-header>
  <div #chart id="render" class="inner-scroll"></div>
</ion-content>
