import { SurveyService } from './../../services/survey/survey.service';

import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceService, DEVICE_ORIENTATION } from '../../services/device/device.service';
import { Subject, Subscription } from 'rxjs';
import { CaseManagementService } from '../../services/case-management/case-management.service';
import { CaseActivity } from '../../classes/case-intake/case-activity.class';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { PageName } from '../../services/navigation/navigation.service';
import { Events } from '@omni/events';
import { UIService } from '../../services/ui/ui.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { NavigationService } from '@omni/services/navigation/navigation.service';

/**
 * Generated class for the CaseManagementHomePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-case-management-home[base-page]',
  templateUrl: 'case-management-home.html',
  styleUrls:['case-management-home.scss'],
})
export class CaseManagementHomePage implements OnInit, OnDestroy {

  public isPortrait: boolean = false;
  public destroy$: any = new Subject<boolean>();
  public IcurrentCaseSubscription: Subscription;
  public selectedCase: CaseActivity;

  constructor(
    private device: DeviceService,
    public caseManagementService: CaseManagementService,
    private brandOfflineService: BrandOfflineService,
    private events: Events,
    private uiService : UIService,
    private surveyService: SurveyService,
    private navService: NavigationService
  ) {
    this.device.screenOrientation.pipe(
      takeUntil(this.destroy$))
      .subscribe((orientation: number) => {
        console.log(orientation);
        this.isPortrait = !(orientation === DEVICE_ORIENTATION["landscape-primary"] || orientation === DEVICE_ORIENTATION["landscape-secondary"]);
      });

    this.isPortrait = this.device.isOrientationPortrait();
  }

  async ngOnInit() {
    this.caseManagementService.isCaseSelected = false;
    /*await this.caseManagementService.reMapDataWithValues(
      this.accountOfflineService.accounts,
      this.contactOfflineService.contacts
    );*/
    if (this.navService.getActiveChildNavViewPageName() == PageName.CaseManagementDetailComponent) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }

    this.IcurrentCaseSubscription = this.caseManagementService.currentSelectedCase.subscribe(
      (value: CaseActivity) => {
        this.selectedCase = value;
        if(value) {
          this.caseManagementService.showRightPane = this.device.shouldBeMobileView;
        }
      }
    )
    this.events.subscribe('updateInquiriesRHSEmptyMessage', (shouldShow)=>{
      this.uiService.updateNothingSelectedScreenMessageFor(shouldShow ? 1 : 0)
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.caseManagementService.assignSelectedCase(undefined);
    this.surveyService.assignLookupResult(undefined);
    this.IcurrentCaseSubscription.unsubscribe();
    this.events.publish("tools-back-clicked", PageName.CaseManagementHomePage);
    this.events.unsubscribe('updateInquiriesRHSEmptyMessage')
  }

}
