<ion-header>
    <ion-toolbar><label>{{ 'COMPLETE_MEETING' | translate }}</label></ion-toolbar>
</ion-header>
<ion-content>
  <div class="fields-contatiner">
    <div class="sc-field" *ngFor="let field of fields">
      <div class="field-label">{{field.label | translate}}</div>
      <div class="field-value"> {{field.value}}</div>
    </div>
  </div>
  <div class="action-bar">
    <ion-button slot="end" (click)="closeModal('submit')"><ion-icon class="submit-icon" src="assets/imgs/short_call_submit.svg" alt="Submit"></ion-icon> {{'SUBMIT' | translate}}</ion-button>
    <ion-button class="cncl-btn" (click)="closeModal('cancel')" fill="clear" slot="end"><ion-icon class="cncl-icon" src="assets/imgs/short_call_cancel.svg" alt="Close"></ion-icon> {{'CLOSE' | translate}}</ion-button>
  </div>
</ion-content>
