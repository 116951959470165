<!-- Generated template for the FieldMaterialManagementListComponent component -->
<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="false"
                   (onControlClick)="onPageTitleControlClick($event)"
                   (onSearchChange)="onInput($event.detail,$event)"
                   (onSearchFocus)="ionSearchFocus($event)"
                   (onFilterClick)="openFilters($event)"></ind-header-left>

  <!-- Segment Tabs -->
  <ion-toolbar no-padding *ngIf="viewData.viewMode == compViewMode.LISTVIEW">
    <ind-tabs [data]="tabsData" [(selectedTab)]="fieldMaterialModel" (selectedTabChange)="checkFieldMaterialModel($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content [ngClass]="{'vectorimageleft': showVectorImage()}">
  <div [ngSwitch]="fieldMaterialModel">
    <div *ngSwitchCase="'myAssets'">
      <ind-section-header *ngIf="selectedAssetsModel?.length > 0 && viewData.viewMode!=compViewMode.LISTVIEW" [viewData]="selectedAssetsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <div *ngIf="selectedAssetsModel?.length>0 && viewData.viewMode!=compViewMode.LISTVIEW">
        <ng-container  *ngFor="let item of selectedAssetsModel">
          <main-card [viewData]='item'></main-card>
          <ind-hierarchy-list-item [hidden]="!item.isExpanded" [viewData]="item.childItems"></ind-hierarchy-list-item>
        </ng-container>
      </div>
      <ind-section-header [viewData]="assetSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)">
      </ind-section-header>
      <div *ngIf="!searching && (!filteredAssets || (filteredAssets && filteredAssets.length === 0))" class="no-data-message">{{'NO_ASSETS' | translate}}</div>
      <ind-no-search-result *ngIf="searching && filteredAssets.length === 0"
        [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
      <ng-container *ngFor="let item of filteredAssetsModel">
        <main-card [viewData]='item'></main-card>
        <ind-hierarchy-list-item [hidden]="!item.isExpanded" [viewData]="item.childItems"></ind-hierarchy-list-item>
      </ng-container>
    </div>
    
    <div *ngSwitchCase="'transferRequests'">
      <ind-section-header [viewData]="transferSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <div *ngIf="!searching && (!filteredTransfers || (filteredTransfers && filteredTransfers.length === 0))" class="no-data-message">{{'NO_TRANSFER_REQUESTS' | translate}}</div>
      <ind-no-search-result *ngIf="searching && filteredTransfers.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
      <div *ngFor="let transfer of filteredTransfers | orderBy: 'createdon' :false">
        <main-card [viewData]='getMainCardDataModelForTransfer(transfer)'></main-card>
      </div>
    </div>
  </div>
</ion-content>

<footer-toolbar [footerView]="'masterView'" [selectedView]="'FieldMaterialManagementList'"></footer-toolbar>


