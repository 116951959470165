import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'texfilter'
})
export class TextSearchFilterPipe implements PipeTransform {
    constructor(private translate:TranslateService){}
    transform(items: any[], searchText: string, searchParam?: string, filterMetaData?: any ): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        // search parameter for team coaching
        if (searchParam === 'created_for') {
            return items.filter(item => {
                if (item.created_for) {
                    return (item.created_for.toLowerCase().includes(searchText))
                }
            });
        }
        // search parameter for my coaching 
        else if (searchParam === 'created_by') {
            return items.filter(item => {
                if (item.created_by) {
                    return (item.created_by.toLowerCase().includes(searchText))
                }
            });
        } 
        else if (searchParam === 'settingTabHeader'){
            return items.filter(item => {
                if (item.settingTabHeader) {
                    return ( this.translate.instant(item.settingTabHeader).toLowerCase().includes(searchText))
                }
            });
        } else if (searchParam === 'primaryTextRight') {
          return items.filter(item => {
            if (item.primaryTextRight) {
              return (item.primaryTextRight.toLowerCase().includes(searchText));
            }
          });
        } else {
            const filterItems = items.filter(item => {
                if (item.hasOwnProperty('value')) {
                    return item.value.toLowerCase().includes(searchText);
                }
                // if(it.hasOwnProperty('name')) {
                //     return it.name.toLowerCase().includes(searchText);
                // }
            });
            filterMetaData.count = filterItems.length;
            return filterItems;
        }
    }
}