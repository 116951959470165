import { ActivityPresentationSlideContact } from "./activity-slide-contacts.class";

export class ActivityPresentationSlide{

    public starttime:string;
    public endtime:string;
    public ckmpageid:string;
    private ckmpagesequence:string;
    private ckmpageurl:string;
    private id:string;
    private name:string
    private isVirtualPage: boolean
    public pagesentiment:string;
    public activityPresentationSlideContacts:Array<ActivityPresentationSlideContact>;

    constructor(raw:Object){
        this.starttime = raw['indskr_starttime'];
        this.endtime = raw['indskr_endtime'];
        this.ckmpageid = raw['indskr_ckmpageid'];
        this.ckmpagesequence = raw['indskr_ckmpagesequence'];
        this.ckmpageurl = raw['indskr_ckmpageurl'];
        this.id = raw['activitypresentationslideid'] || raw['id'];
        this.starttime = raw['indskr_starttime'];
        this.name = raw['indskr_name'];
        this.isVirtualPage = raw['indskr_virtualpage'] || false;
        this.pagesentiment = raw['indskr_pagesentiment'];
        if(Array.isArray(raw['activityPresentationSlideContacts'])){
            this.activityPresentationSlideContacts = [];
            raw['activityPresentationSlideContacts'].forEach(item => {
                this.activityPresentationSlideContacts.push(new ActivityPresentationSlideContact(item));
            });
        }

    }

    get activityPresentationSlideContactsDTO(): Array<Object> {
        let activityPresentationSlideContactsArray = [];

        if (!this.activityPresentationSlideContacts) return;

        activityPresentationSlideContactsArray = this.activityPresentationSlideContacts.map(pres => pres.DTO);
        return activityPresentationSlideContactsArray;
    }

    public get DTO():Object{
        return{
            indskr_starttime: this.starttime,
            indskr_endtime: this.endtime,
            activityPresentationSlideContacts: this.activityPresentationSlideContactsDTO,
            indskr_ckmpageid: this.ckmpageid,
            indskr_ckmpagesequence: this.ckmpagesequence,
            indskr_ckmpageurl: this.ckmpageurl,
            activitypresentationslideid : this.id,
            indskr_virtualpage: this.isVirtualPage,
            indskr_name : this.name,
            indskr_pagesentiment:this.pagesentiment,
        }
    }
}
