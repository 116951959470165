/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DeviceService } from '@omni/services/device/device.service';
import { IoEventListener } from '@omni/services/io-event-listener.decorator';
import { LogService } from '@omni/services/logging/log-service';
import {
  NotificationService,
  ToastStyle,
} from '@omni/services/notification/notification.service';
import {
  OTState,
  OpentokService,
} from '@omni/services/video-streaming/opentok.service';
import { Endpoints } from '../../../config/endpoints.config';

@Component({
  selector: 'ot-publisher',
  templateUrl: 'ot-publisher.component.html',
  styleUrls: ['ot-publisher.component.scss'],
})
export class OTPublisherComponent implements OnInit, OnDestroy, OnChanges {
  @Input() audio = false;
  @Input() video = false;
  @Input() pauseVideo = false;
  @Input() type: 'screen' | 'camera' = 'camera';
  private _publisher: OT.Publisher;
  @ViewChild('videoContainer', { static: true }) videoContainer: ElementRef<
    HTMLDivElement
  >;
  private get _videoElement() {
    let element = this.videoContainer.nativeElement;
    if (this.device.deviceFlags.nativeIOS) {
      if (!element.firstElementChild) {
        const child = this.renderer.createElement('div');
        this.renderer.appendChild(element, child);
        this.renderer.setStyle(child, 'position', 'absolute');
        this.renderer.setStyle(child, 'left', '0');
        this.renderer.setStyle(child, 'top', '0');
        this.renderer.setStyle(child, 'width', '100%');
        this.renderer.setStyle(child, 'height', '100%');
        element = child;
      } else {
        element = element.firstElementChild as HTMLDivElement;
      }
    }
    return element;
  }

  private _publisherProperties: OT.PublisherProperties;
  private _timestamp: number;
  @HostBinding('hidden') get isScreensharing() {
    return this.type === 'screen';
  }
  private _talking = false;
  @HostBinding('class.talkingiOS') get talkingiOS() {
    return this._talking && this.device.deviceFlags.nativeIOS;
  }
  @HostBinding('class.talkingNoniOS') talkingNoniOS() {
    return this._talking && !this.device.deviceFlags.nativeIOS;
  }

  private _videoStartTime: number = undefined;
  private _videoEndTime: number = undefined;

  @HostBinding('class.has-video') get hasVideo(): boolean {
    return !!this._publisher?.stream?.hasVideo;
  }

  @HostBinding('style.order') get displayOrder() {
    return 1;
  }

  @HostBinding('class.has-audio') get hasAudio(): boolean {
    return !!this._publisher?.stream?.hasAudio;
  }

  private get _audioProp(): keyof OTState {
    return this.type === 'camera' ? 'audio' : 'screenaudio';
  }
  private get _videoProp(): keyof OTState {
    return this.type === 'camera' ? 'video' : 'screenshare';
  }

  private readonly _cleanupActions: (() => void)[] = [];

  name: string;
  initials: string;

  private _pendingChanges = false;

  constructor(
    private readonly opentok: OpentokService,
    private readonly authService: AuthenticationService,
    public injector: Injector,
    private readonly notif: NotificationService,
    private readonly translate: TranslateService,
    private readonly activityService: ActivityService,
    private readonly http: HttpClient,
    private readonly log: LogService,
    private readonly renderer: Renderer2,
    private readonly _ngZone: NgZone,
    private readonly device: DeviceService
  ) {}

  ngOnInit() {
    this.updateName();
    // const handler = (ev) => this.onStreamPropertyChanged(ev);
    // this.opentok.session.on('streamPropertyChanged', handler);
    // this._cleanupActions.push(() =>
    //   this.opentok.session.off('streamPropertyChanged', handler)
    // );
  }

  private updateName() {
    this.name = this.authService.user.displayName;
    const names = this.name.split(' ');
    this.initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      this.initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.video || changes.audio) {
      if (!this._pendingChanges) {
        this.refreshPublisher();
      } else {
        this._pendingChanges = true;
      }
    }

    if (
      changes.pauseVideo &&
      !changes.pauseVideo.isFirstChange &&
      this._publisher
    ) {
      if (changes.pauseVideo.currentValue) {
        this.freezeVideo();
        this._publisher?.publishVideo(false);
        this.opentok.setOTState({ [this._videoProp]: 'paused' });
      } else {
        this.unfreezeVideo();
        this._publisher?.publishVideo(true);
        this.opentok.setOTState({ [this._videoProp]: 'playing' });
      }
    }
  }

  private freezeVideo() {
    this._publisher?.setStyle(
      'backgroundImageURI',
      this._publisher?.getImgData()
    );
  }

  private unfreezeVideo() {
    this._publisher?.setStyle('backgroundImageURI', null);
  }

  private async unpublish() {
    await this.opentok.unpublish(this._publisher);
    this._publisher = undefined;
  }

  private async refreshPublisher() {
    const adding =
      (!this._publisher && (this.audio || this.video)) ||
      (!this._publisherProperties?.publishAudio && this.audio) ||
      (!this._publisherProperties?.publishVideo && this.video);
    this.opentok.setOTState({
      ...((this._publisherProperties?.publishAudio ?? false) !== this.audio
        ? { [this._audioProp]: 'waiting' }
        : {}),
      ...((this._publisherProperties?.publishVideo ?? false) !== this.video
        ? { [this._videoProp]: 'waiting' }
        : {}),
    });
    if (adding) {
      this._publisher?.off('streamDestroyed');
      this.unpublish();
      if (this.audio || this.video) {
        try {
          const publisher = await this.publish();
          if (this._pendingChanges) {
            return this.refreshPublisher();
          }
          this._publisher = publisher;
          this.log.logDebug(
            `Publisher stream created: ${publisher?.stream?.streamId}`
          );
          publisher.on('destroyed', (event) =>
            this.opentok.clearOTState(
              ...[
                ...(this.audio ? [] : [this._audioProp]),
                ...(this.video ? [] : [this._videoProp]),
              ]
            )
          );
          publisher.on('streamDestroyed', (event) => {
            this.log.logDebug(
              `Publisher stream destroyed: ${event?.stream?.streamId}`
            );
            if (event.reason === 'networkDisconnected') {
              this._ngZone.run(() => {
                this.notif.notify(
                  this.translate.instant('POOR_INTERNET'),
                  'activity-details',
                  'top',
                  ToastStyle.INFO
                );
              });
            }
            if(!event['track']){
              this._publisherProperties.publishAudio = this.audio = false;
              this._publisherProperties.publishVideo = this.video = false;
            }
            this.opentok.clearOTState(
              ...[
                ...(this.audio ? [] : [this._audioProp]),
                ...(this.video ? [] : [this._videoProp]),
              ]
            );
          });
          publisher.on('audioLevelUpdated', (event) => {
            this._ngZone.run(() => {
              const now = Date.now();
              const oldValue = this._talking;

              if (event.audioLevel > 0.2) {
                this._talking = true;
                this._timestamp = now;
              } else if (
                !this._timestamp ||
                now - (this._timestamp || 0) > 3000
              ) {
                this._talking = false;
              }
              if (this._talking !== oldValue) this.opentok.updateViews();
            });
          });
        } catch (ex) {
          console.error('Unable to publish', ex);
          if(ex.name && ex.name != "OT_USER_MEDIA_ACCESS_DENIED"){
            this._ngZone.run(() => {
              this.notif.notify(
                ex.message || ex,
                'activity-details',
                'top',
                ToastStyle.INFO
              );
            });
          }
          (this.opentok.session as any).alreadyPublishing = false;
          this._publisherProperties.publishAudio = this.audio = false;
          this._publisherProperties.publishVideo = this.video = false;
        }
        this.opentok.setOTState(
          {
            ...(this.video ? { [this._videoProp]: 'playing' } : {}),
            ...(this.audio ? { [this._audioProp]: 'playing' } : {}),
          },
          this._audioProp,
          this._videoProp
        );
      }
    } else if (this._publisher) {
      if (this._pendingChanges) {
        return this.refreshPublisher();
      }
      this._publisherProperties.publishAudio = this.audio;
      this._publisherProperties.publishVideo = this.video;
      this.freezeVideo();
      this._publisher.publishAudio(this.audio);
      this._publisher.publishVideo(this.video);
    }

    if (!this._publisher?.stream?.hasAudio) {
      this._talking = false;
    }
    this._pendingChanges = false;
  }

  private async publish() {
    this.resetPublisherProperties();
    return this.opentok.publish(this._videoElement, this._publisherProperties);
  }

  @IoEventListener('opentok-session-streamPropertyChanged')
  onStreamPropertyChanged(event) {
    const stream: OT.Stream = event.stream;
    if (stream?.streamId !== this._publisher?.stream?.streamId) return;
    switch (event.changedProperty) {
      case 'hasAudio':
        if (event.newValue) {
          if (this._publisherProperties) {
            this._publisherProperties.publishAudio = true;
          }
          this.opentok.setOTState({ [this._audioProp]: 'playing' });
        } else {
          if (this._publisherProperties) {
            this._publisherProperties.publishAudio = false;
          }
          this._talking = false;
          this.opentok.clearOTState(this._audioProp);
        }
        this.unfreezeVideo();
        break;
      case 'hasVideo':
        if (event.newValue) {
          if (this._publisherProperties) {
            this._publisherProperties.publishVideo = true;
          }
          this.opentok.setOTState({ [this._videoProp]: 'playing' });
        } else {
          if (this._publisherProperties) {
            this._publisherProperties.publishVideo = false;
          }
          this.opentok.clearOTState(this._videoProp);
        }
        this.unfreezeVideo();
        break;
      default:
    }
    this.opentok.updateViews();
  }

  ngOnDestroy() {
    this.audio = false;
    this.video = false;
    this._cleanupActions.forEach((fn) => fn());
    this.refreshPublisher();
  }

  private resetPublisherProperties() {
    if (!this.name) {
      this.updateName();
    }
    this._publisherProperties = {
      style: {
        buttonDisplayMode: 'off',
        archiveStatusDisplayMode: 'off',
        audioLevelDisplayMode: 'off',
        nameDisplayMode: 'off',
      },
      publishAudio: this.audio,
      publishVideo: this.video,
      audioSource: this.audio ? undefined : false,
      videoSource: this.video ? undefined : false,
      ...(this.type === 'screen'
        ? {
            name: `${this.name}_SS`,
            videoSource: this.video ? 'screen' : false,
          }
        : {
            name: this.name,
            width: '100%',
            height: '100%',
            frameRate: 15,
            insertMode: 'append',
            resolution: '320x240',
            audioBitrate: 8000,
            facingMode: 'user',
          }),
    };
  }

  @IoEventListener('opentok-state-changed')
  public async opentok_stateChanged(state: OTState, oldState: OTState) {
    if ((state || {})[this._audioProp] !== (oldState || {})[this._audioProp]) {
      if ((state || {})[this._audioProp] === 'playing') {
        this.updateAudioStartTime();
      } else if (!(state || {})[this._audioProp]) {
        this.updateAudioEndTime();
      }
    }
    if ((state || {})[this._videoProp] !== (oldState || {})[this._videoProp]) {
      if ((state || {})[this._videoProp] === 'playing') {
        this._videoStartTime = Date.now();
      } else if (!(state || {})[this._videoProp]) {
        this._videoEndTime = Date.now();
        if (this._videoProp != 'screenshare') {
          await this.updateRepVideoDuration();
        }
        this._videoStartTime = undefined;
        this._videoEndTime = undefined;
      }
    }
  }

  private updateAudioStartTime() {
    const url: string =
      this.authService.userConfig.activeInstance.entryPointUrl +
      Endpoints.meeting.VIDEO_SHARE.replace(
        '{activity_id}',
        this.activityService.selectedActivity.ID
      );
    let payload: Object;
    payload = {
      startTime: new Date().getTime(),
      indskr_contenttype: 3,
    };
    const headers = Endpoints.headers.content_type.json;
    this.http.post(url, payload, headers).subscribe(
      (response) => this.log.logDebug(response),
      (err) => this.log.logError(err)
    );
  }

  private async updateAudioEndTime() {
    const url: string =
      this.authService.userConfig.activeInstance.entryPointUrl +
      Endpoints.meeting.VIDEO_SHARE.replace(
        '{activity_id}',
        this.activityService.selectedActivity.ID
      );
    let payload: Object;
    payload = {
      endTime: new Date().getTime(),
      indskr_contenttype: 3,
    };
    const headers = Endpoints.headers.content_type.json;
    return this.http.post(url, payload, headers).toPromise();
  }

  private async updateRepVideoDuration() {
    const url: string = Endpoints.meeting.REP_VIDEO_DURATION;
    if (!this._videoStartTime) return;
    try {
      return await this.http
        .post<any>(
          url.replace(
            '{activity_id}',
            this.activityService.selectedActivity.ID
          ),
          {
            ...(this._videoStartTime
              ? { indskr_videostarttime: `${this._videoStartTime || 0}` }
              : {}),
            ...(this._videoEndTime
              ? { indskr_videoendtime: `${this._videoEndTime || 0}` }
              : {}),
            indskr_contactid: '',
            indskr_user: this.authService.user.systemUserID,
            indskr_contenttype: '1',
          }
        )
        .toPromise();
    } catch (error) {}
  }
}
