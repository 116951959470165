import { ActivityResourceContact } from "./activity-resource-contact.class"
import { ActivityResourceSlide } from './activity-resource-slide.class';

export class ActivityResource {
    public indskr_ioresourceid: string;
    public indskr_iodocumentid: string;
    public indskr_ckmtitle: string;
    public indskr_starttime: string;
    public indskr_endtime: string;

    public activityResourceContacts: Array<ActivityResourceContact>;
    public activityResourceSlides: Array<ActivityResourceSlide>;

    constructor(raw: object) {
        this.indskr_ioresourceid = raw['indskr_ioresourceid'];
        this.indskr_iodocumentid = raw['indskr_iodocumentid'];
        this.indskr_ckmtitle = raw['indskr_ckmtitle'];
        this.indskr_starttime = raw['indskr_starttime'];
        this.indskr_endtime = raw['indskr_endtime'];

        if (Array.isArray(raw['activityResourceSlides'])) {
            this.activityResourceSlides = [];
            raw['activityResourceSlides'].forEach(item => {
                this.activityResourceSlides.push(new ActivityResourceSlide(item));
            });
        }

        if (Array.isArray(raw['activityResourceContacts'])) {
          this.activityResourceContacts = [];
          raw['activityResourceContacts'].forEach(item => {
              this.activityResourceContacts.push(new ActivityResourceContact(item));
          });
      }
    }

    public get DTO(): Object {
        return {
            indskr_ioresourceid: this.indskr_ioresourceid,
            indskr_iodocumentid: this.indskr_iodocumentid,
            indskr_starttime: this.indskr_starttime,
            indskr_ckmtitle: this.indskr_ckmtitle,
            indskr_endtime: this.indskr_endtime,
            activityResourceContacts: this.activityResourceContacts,
            activityResourceSlides: this.activityResourceSlides
        }
    }
}