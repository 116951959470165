export enum TrackAction {
    Download = 548910000,
    Deleted = 548910001,
}

export enum StatusCode {
    Active = 1,
    Inactive
}

export enum TimeInMilliseconds {
    Minute = 60000,
    Hour = 3600000,
    Day = 86400000
}

export enum DynamicsBoolean {
  false = 548910000,
  true
}
