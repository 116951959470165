import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from 'rxjs';
import { DiskService } from '../disk/disk.service';
import * as _ from 'lodash';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../events/events.service';
import { PopoverController } from '@ionic/angular';
import { Currency, MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { AccountOfflineService } from "../account/account.offline.service";
import { ContactOfflineService } from "../contact/contact.service";
import { BrandOfflineService } from "../brand/brand.service";
import { SurgeryOrderActivityDataService } from "@omni/data-services/surgery-order-activity/surgery-order-activity.data.service";
import { isFuture, isPast } from "date-fns";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
/**
 * Offline data service for Marketing Buniness Plans Management
 *
 *
 */
@Injectable({
  providedIn: 'root'
})

export class MarketingPlansManagementOfflineService {
  public marketingPlans$: BehaviorSubject<MarketingPlan[]> = new BehaviorSubject([]);
  public selectedMarketingPlan$: BehaviorSubject<MarketingPlan> = new BehaviorSubject(null);
  public marketingPlans: MarketingPlan[] = [];
  public currencies : Currency[] = [];
  public isRightPaneNavActive: boolean = false;
  public marketingBusinessPlanTypes:Array<{
    id: string;
    name: string;
  }> = []

  mappedAccountIds : string[] = [];
  mappedContactIds : string[] = [];
  mappedProductsIds : string[] = [];

  constructor(
    public disk: DiskService,
    public alertService: AlertService,
    public translate: TranslateService,
    public events: EventsService,
    public popoverCtrl: PopoverController,
    private accountService :AccountOfflineService,
    private contactService:ContactOfflineService,
    private readonly brandService: BrandOfflineService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService

  ) { }


  public getMarketingBuninessPlanByPlanId(id: string): MarketingPlan {
    let marketingPlan: MarketingPlan = undefined;
    marketingPlan = this.marketingPlans.find(ap => ap.ID == id);
    return marketingPlan;
  }

  sortListByFielName(options, fieldName: string) {
    if (_.isEmpty(options)) return [];
    return options.sort((a, b) => {
      let nameA: string = a[fieldName], nameB: string = b[fieldName];
      if (!nameA || !nameB) return 1;
      nameA = nameA.toLowerCase();
      nameB = nameB.toLowerCase();
      if (nameA < nameB)
        return -1;
      if (nameA >= nameB)
        return 1;
    });
  }

  isProductMappedToUser(productId) {
    // products
    let productExists = this.brandService.brands.some(({ ID }) => ID === productId);
    if (productExists) return true;

    // product bundle
    if (this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.length > 0) {
      productExists = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.some(({ surgeryId }) => surgeryId === productId);
    }
    return productExists;
  }

  isAccountMappedToUser(accountId): boolean {
    return this.accountService.accounts.some(({ id }) => id == accountId);
  }

  isContactMappedToUser(contactId){
    const foundContact = this.contactService.getContactByID(contactId);
    return foundContact != undefined;
  }

  getCurrencyById(id: string): Currency {
    let currency: Currency;

    currency = this.currencies?.find(c => c.id === id);

    return currency;
  }

  filterMarketingBusinessPlanByPosition(marketingPlans): MarketingPlan[] {
    this.mappedAccountIds = this.accountService.accounts.map(({ id }) => id);
    this.mappedContactIds = this.contactService.contacts.map((contact) => contact.ID);
    this.mappedProductsIds = this.brandService.brands.map((brand) => brand.ID);
    let surgeryIds = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.map((product) => product.surgeryId); // product bundle
    this.mappedProductsIds = [...this.mappedProductsIds, ...surgeryIds];

    const filteredMarketingBusinessPlans = marketingPlans.filter((marketingPlan) => {
      if (this.isMarketBusinessPlanMappedToUserPosition(marketingPlan)) return true;
      return false;
    });

    return filteredMarketingBusinessPlans;
  }

  filterMarketingBusinessPlanByTime(marketingPlans : MarketingPlan[]): MarketingPlan[]{
    let filteredMarketingBusinessPlans = marketingPlans.filter(plan => {
        plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
        return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
      });

    return filteredMarketingBusinessPlans;
  }

  isMarketBusinessPlanMappedToUserPosition(marketingBuninessPlan: MarketingPlan) {
    if (marketingBuninessPlan.accounts.length > 0) {
      let accountIds = marketingBuninessPlan.accounts.map((account) => account.id);
      return accountIds.some((account) => this.mappedAccountIds.includes(account));
    } else if (marketingBuninessPlan.contacts.length > 0) {
      let contactIds = marketingBuninessPlan.contacts.map((contact) => contact.id);
      return contactIds.some((contact) => this.mappedAccountIds.includes(contact));
    } else if (marketingBuninessPlan.products.length > 0) {
      let productsIds = marketingBuninessPlan.products.map((product) => product.id);
      return productsIds.some((product) => this.mappedAccountIds.includes(product));
    }
    return false;
  }

  public async getMarketingBusinessPlanTypeDataOffline() {
    let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.MARKETING_BUSINESS_PLAN_TYPES);
    if(offlineData && offlineData.raw && Array.isArray(offlineData.raw)){
      this.marketingBusinessPlanTypes = [];
      offlineData.raw.forEach(item => {
        if(item['indskr_marketingbusinessplantypesid'] && item['indskr_name']){
          this.marketingBusinessPlanTypes.push({
            id: item['indskr_marketingbusinessplantypesid'],
            name: item['indskr_name'],
          })
        }
      });
    }
  }

  deleteMarketingBusinessPlanFromDB(mbpId:string){
    this.disk.retrieve(DB_KEY_PREFIXES.MARKETING_PLANS, true).then((doc) => {
     if (doc?.raw) {
       let idx = doc.raw.findIndex(element=>element.ID == mbpId)
       doc.raw.splice(idx,1);
       this.disk.updateOrInsert(DB_KEY_PREFIXES.MARKETING_PLANS, (updatedDoc) => {
         updatedDoc = doc
         updatedDoc.lastModified =new Date().getTime(); 
       
     
       return updatedDoc;
     })
     this.marketingPlans =doc.raw;
     this.marketingPlans$.next(this.marketingPlans);
   } 
   });
 }

}