export class CustomerListPeriod {
  public indskr_customerlistperiodid: string;
  public indskr_name: string;
  public indskr_listenddate: string;
  public indskr_liststartdate: string;
  public createdon: string;
  public indskr_previouslistperiod: CustomerListPeriod;
  public indskr_nextlistperiod: CustomerListPeriod;
  public indskr_nextlistmanagementwindowstartdate: string;
  public indskr_nextlistmanagementwindowenddate: string;
  public tagLabel: string;
  public indskr_adjustmentlimit: number;

  constructor(raw: Object) {
    this.indskr_customerlistperiodid = raw['customerListPeriodId'];
    this.indskr_name = raw['customerListPeriodName'];
    this.indskr_listenddate = raw['customerListPeriodEndDate'];
    this.indskr_liststartdate = raw['customerListPeriodStartDate'];
    this.createdon = raw['customerListPeriodCreatedOn'];
    this.indskr_previouslistperiod = raw['prevCustomerListPeriod'];
    this.indskr_nextlistperiod = raw['nextCustomerListPeriod'];
    this.indskr_nextlistmanagementwindowstartdate = raw['nextListManagementWindowStartDate'];
    this.indskr_nextlistmanagementwindowenddate = raw['nextListManagementWindowEndDate'];
    this.tagLabel = raw['tagLabel'];
    this.indskr_adjustmentlimit = raw['indskr_adjustmentlimit'];
  }
}