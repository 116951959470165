<ion-toolbar *ngIf="viewData">
    <!-- <ion-segment mode='ios' class="tabs-container" [(ngModel)]="activeBusinessProcess" tappable scrollable> -->
        <ion-row class="main-row">
            <ng-container *ngFor="let btn of viewData;let i = index">
                <span id="{{btn.id}}" (click)="businessProcessChanged($event,btn)" [ngClass]="btn.isActive?'active':'inactive'" class="outer-process" >
                    <ion-row class="inner-process" [ngClass]="i == 0 ?'inner-left-radius':(i == viewData.length-1 ? 'inner-right-radius':'')">
                        <span class="outer-oval">
                            <span class="inner-oval">
                                <span></span>
            </span>
            </span>
            <span class="process-text">
                            {{ btn.title}}
                        </span>
    </ion-row>
    </span>
    </ng-container>
    </ion-row>
    <!-- </ion-segment> -->
</ion-toolbar>