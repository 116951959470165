<ion-grid [ngClass]="(!!accountManagementService.isRightPaneNavActive)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="account-mgmt-left-pane">
      <!-- <account-list [accountListMode]="accountListMode" [accountListAccessedFrom]="'AccountManagementPage'"
        (closeModal)="closeAccountModal($event)" (selectAccount)="openAccountPlanDetail($event)"></account-list> -->
      <account-plan-list [viewData]="viewData" [accountListMode]="accountListMode" [accountListAccessedFrom]="'AccountManagementPage'"
        (selectAccount)="openAccountPlanDetail($event)" ></account-plan-list>
    </ion-col>
    <ion-col class="account-mgmt-right-pane">
      <ion-nav #accountmanagementrightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
