<ion-header>
    <ind-page-title [viewData]="title" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content class="ion-no-padding" toolbar-md-height>
    <ng-container>
        <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
            <ion-item-group>
                <ind-section-header [viewData]="insightsDetailsHeaderModel"></ind-section-header>
                <!-- <ion-item-divider color="light">{{ 'DETAILS_CAP' | translate: { globalCustomerText : utilityService.globalCustomerText } }}</ion-item-divider> -->

                <ind-form-field [viewData]="customerFormField"></ind-form-field>


                <!-- <ion-item-divider color="light">{{ 'CONTACT_JOURNEY_INSIGHT' | translate}}
                    <ion-button fill="clear" slot="end" [disabled]="!xpService.newInsight.contactid" (click)="addJourney(null)">{{ 'ADD' | translate}}</ion-button>
                </ion-item-divider> -->
                <ind-section-header [viewData]="insightsJourneyHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                <ion-list *ngIf="xpService.newInsight.journeys && xpService.newInsight.journeys.length > 0">
                    <ng-container *ngFor="let journey of xpService.newInsight.journeys">
                        <!-- <ion-label>{{ journey.title ? journey.title : journey.description }}</ion-label>
                        <div slot="end">
                            <ion-icon name="chevron-forward" color="indgray" class="light-color"></ion-icon>
                        </div> -->
                        <main-card [viewData]="getJourneyMainCardDataModel(journey)"></main-card>
                    </ng-container>
                </ion-list>
                <ion-item no-lines *ngIf="!xpService.newInsight.journeys || xpService.newInsight.journeys.length == 0" class="height-item"></ion-item>

                <!-- <ion-item-divider color="light">{{ 'CONTACT_INTEREST_INSIGHTS' | translate}}
                    <ion-button fill="clear" slot="end" [disabled]="!xpService.newInsight.contactid">{{ 'ADD' | translate}}</ion-button>
                </ion-item-divider> -->
                <ind-section-header [viewData]="insightsInterestsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                <ion-list *ngIf="xpService.newInsight.interests && xpService.newInsight.interests.length > 0">
                    <div *ngFor="let interest of xpService.newInsight.interests" (click)="addInterest(interest)">
                        <!-- <ion-label>{{ interest.selectedCategory ? interest.selectedCategory.title : interest.description}}</ion-label>
                        <div slot="end">
                            <ion-icon name="chevron-forward" color="indgray" class="light-color"></ion-icon>
                        </div> -->
                        <main-card [viewData]="getInterestsMainCardDataModel(interest)"></main-card>
                    </div>
                </ion-list>
                <ion-item no-lines *ngIf="!xpService.newInsight.interests || xpService.newInsight.interests.length == 0" class="height-item"></ion-item>

                <!-- <ion-item-divider color="light">{{ 'CONTACT_RELATIONSHIP_INSIGHTS' | translate}}
                    <ion-button fill="clear" slot="end" [disabled]="!xpService.newInsight.contactid" (click)="addRelationship(null)">{{ 'ADD' | translate}}</ion-button>
                </ion-item-divider> -->
                <ind-section-header [viewData]="insightsRelationshipHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>


                <ion-list *ngIf="xpService.newInsight.relationships && xpService.newInsight.relationships.length > 0">
                    <div *ngFor="let relationship of xpService.newInsight.relationships" (click)="addRelationship(relationship)">
                        <!-- <ion-label>{{ relationship.fullname}}</ion-label>
                        <div slot="end">
                            <ion-icon name="arrow-forward" color="indgray" class="light-color"></ion-icon>
                        </div> -->
                        <main-card [viewData]="getRelationshipsMainCardDataModel(relationship)"></main-card>
                    </div>
                </ion-list>
                <ion-item *ngIf="!xpService.newInsight.relationships || xpService.newInsight.relationships.length == 0" class="height-item"></ion-item>
            </ion-item-group>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'" [fromWhichView]="'Xperience'"></footer-toolbar>
<!-- </ion-footer> -->
