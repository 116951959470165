
import {throwError as observableThrowError, interval as observableInterval,  Observable } from 'rxjs';

import {concat, take, mergeMap, catchError, retryWhen} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { ERROR_MSG_MAP } from '../../config/error-msg.config';
import {MyAssistantService, NOTIFICATION} from '../my-assistant/my-assistant.service';
import { format } from 'date-fns';
import { GlobalUtilityService } from '../global-utility.service';
import { TranslateService } from '@ngx-translate/core';
import {DateTimeFormatsService} from "../date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from '../notification/notification.service';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";

@Injectable({
  providedIn: 'root'
})
export class ServerErrorsInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService,
    private myAssistantService: MyAssistantService,
    private utilityService: GlobalUtilityService,
    private injector: Injector,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) { }

  private translate: TranslateService
  private ERROR_TITLE: string;
  private ERROR_MESSAGE: string;
  private serviceErrNotificationModel: IndNotificationDataModel;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.translate = this.injector.get(TranslateService)
    this.ERROR_TITLE = this.translate.instant('ERROR_TITLE');
    this.ERROR_MESSAGE = this.translate.instant('ERROR_MESSAGE');
    if (request.headers.has('Sync-Service')) {
      this.utilityService.sync_service.push(request.url);
      request = request.clone({
        headers: request.headers.delete('Sync-Service')
      });
    }
    // By-pass retry if 'No-Retry' header is set
    if (request.headers.has('No-Retry')) {
      request = request.clone({
        headers: request.headers.delete('No-Retry')
      });
      return next.handle(request);
    } else {
      return next.handle(request).pipe(catchError(this.handleError));
    }
  }

  private handleError = (error: any) => {
    console.error("Error occurred...", error);
    if (error['error'] && error.error['errorId'] && ERROR_MSG_MAP[error.error['errorCode']]) {
      let msg: string = ERROR_MSG_MAP[error.error['errorCode']];
      if (this.utilityService.sync_service.indexOf(error.url) > -1) {
        this.utilityService.sync_error.push(error.error['errorId']);
        return observableThrowError(error);
      }
      if (ERROR_MSG_MAP.EXCLUDE_ERRORS.indexOf(error.error['errorCode']) == -1) {
        if (msg.includes("errorId")) {
          msg = msg.replace(`{errorId}`, error.error['errorId']);
        }
        this.serviceErrNotificationModel = {
          type: NOTIFICATION.SERVICE_ERR,
          name: this.translate.instant("SERVICE_ERR", {errorCode: error.error['errorId']}),
          DateTime: Date.now(),
          id: NOTIFICATION.SERVICE_ERR + Date.now(),
          icon: "assets/imgs/service_error.svg",
          data: {data: this.translate.instant("SERVICE_ERR_DETAILS", {errorCode: error.error['errorId']})},
          isRed: false,
          params: {errorCode: error.error['errorId']}
        };
        this.myAssistantService.saveNotificationToDisk(this.serviceErrNotificationModel);
        /*this.myAssistantService.addNewNotification(
          this.translate.instant('ERROR_OCCURRED_AT',{format:format(new Date(), this.dateTimeFormatsService.dateToUpper + " HH:mm:ss"),ErroId:error.error['errorId']})
        );*/
        switch (error.error['errorCode']) {
          case 'ERR_IO_INVALID_INPUT_401':
            msg = this.translate.instant('ERR_IO_INVALID_INPUT_401');
            break;
          case 'ERR_IO_PWD_EXPIRED_400':
            msg = this.translate.instant('ERR_IO_PWD_EXPIRED_400' , {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_REFRESH_TOKEN_EXPIRED_400':
            msg = this.translate.instant('ERR_IO_REFRESH_TOKEN_EXPIRED_400', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_ME06':
            msg = this.translate.instant('ERR_IO_ME06', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_ME05':
            msg = this.translate.instant('ERR_IO_ME05');
            break;
          case 'ERR_IO_ME04':
            msg = this.translate.instant('ERR_IO_ME04');
            break;
          case 'ERR_IO_ME03':
            msg = this.translate.instant('ERR_IO_ME03');
            break;
          case 'ERR_IO_ME02':
            msg = this.translate.instant('ERR_IO_ME02');
            break;
          case 'ERR_IO_ME01':
            msg = this.translate.instant('ERR_IO_ME01');
            break;
          case 'ERR_IO_500':
            msg = this.translate.instant('ERR_IO_500', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_403':
            msg = this.translate.instant('ERR_IO_403', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_401':
            msg = this.translate.instant('ERR_IO_401', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_400':
            msg = this.translate.instant('ERR_IO_400', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_ME07':
            msg = this.translate.instant('ERR_IO_ME07', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_ACCOUNT_LOCKED_400':
            msg = this.translate.instant('ERR_IO_ACCOUNT_LOCKED_400');
            break;
          case 'ERR_IO_TNF_403':
            msg = this.translate.instant('ERR_IO_TNF_403');
            break;
          case 'ERR_IO_ACCOUNT_DISABLED_400':
            msg = this.translate.instant('ERR_IO_ACCOUNT_DISABLED_400', {errorId : error.error['errorId']});
            break;
          case 'RECORD_NOT_FOUND_404':
            msg = this.translate.instant('RECORD_NOT_FOUND_404', {errorId : error.error['errorId']});
            break;
          case 'ERR_IO_COACHING_400':
            msg = this.translate.instant('ERR_IO_COACHING_400');
            break;
          case 'NO_COACHING_METRICS_CONFIGURED_400':
            msg = this.translate.instant('NO_COACHING_METRICS_CONFIGURED_400');
            break;
          case 'MORE_THAN_ONE_COACHING_METRICS_FOUND_400':
            msg = this.translate.instant('MORE_THAN_ONE_COACHING_METRICS_FOUND_400');
            break;
          default:
            return
        }
        this.notificationService.notify(msg,'Error Interceptor','top',ToastStyle.DANGER);
      } else {
        return observableThrowError(error);
      }
    }
    return observableThrowError(error);
  }
}
