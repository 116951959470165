import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OperationDetail } from "../order-activity/order-activity.data.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME,OFFLINE_DB_LINKED_ENTITY_NAME } from "@omni/services/disk/disk.service";
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from "@omni/config/pouch-db.config";
import { ActivityService } from "@omni/services/activity/activity.service";
import { RequiredSetBookingAttributes, SetBookingActivity, SetBookingStatus } from "@omni/classes/activity/set-booking.activity.class";
import { TrackAction } from "@omni/utility/common-enums";
import { EmbeddedInteraction, Activity, EmbeddedInteractionType, ActivityType } from "@omni/classes/activity/activity.class";
import { AuthenticationService } from "@omni/services/authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { DeviceService } from "@omni/services/device/device.service";
import { DeltaService, EntityNames, EntitySyncInfo } from "../delta/delta.service";
import { DynamicForm, FormType } from "@omni/classes/dynamic-form/dynamic-form.class";
import { DynamicFormsService } from "@omni/services/dynamic-forms/dynamic-forms-service";
import { DynamicFormType } from "@omni/models/dynamic-form-component.model";
import { addMinutes, differenceInHours, format, isBefore } from "date-fns";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { EventName, EventsService } from "@omni/services/events/events.service";
import { Endpoints } from "src/config/endpoints.config";
import { IONote } from "@omni/classes/io/io-note.class";
import { isInteger } from "lodash";
import * as XML2JS from 'xml2js';
import { LinkedEntity } from "@omni/classes/onekey/ok-searchkeys.class";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@omni/services/alert/alert.service";
import { BehaviorSubject } from "rxjs";
import { AccesingMode, CaseManagementService } from "@omni/services/case-management/case-management.service";
import { CaseActivity } from "./../../classes/case-intake/case-activity.class";
import { ModalController, LoadingController } from "@ionic/angular";
import { ActivityDataService } from "../../data-services/activity/activity.service";
import { InMeetingCasesComponent } from "../../components/case-management/in-meeting-cases/in-meeting-cases";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { SecInfoConfigDataService } from "../sec-info-config/sec-info-config-data-service";
import { SecondaryInfoEntityName } from "@omni/classes/sec-info-config/sec-info.class";

@Injectable({
  providedIn: 'root'
})

export class SetBookingDataService {

  public refreshSelectedSetBookingDetails:boolean = false;
  public _isCreateEditKitBookingFormDirty:boolean = false;
  private currentKitBookingMeetingStructureSource = new BehaviorSubject<EmbeddedInteraction[]>(undefined);
  public currentKitBookingMeetingStructureObserver = this.currentKitBookingMeetingStructureSource.asObservable();
  private selectedKitBookingMeetingActivitySource = new BehaviorSubject<EmbeddedInteraction>(null);
  public selectedKitBookingMeetingActivityObserver = this.selectedKitBookingMeetingActivitySource.asObservable();
  public formSectionConfigure: any[] = [];
  public teamKitBookingLinkedEntityData= {};

  constructor(
    private http: HttpClient,
    private disk: DiskService,
    private activityOfflineService: ActivityService,
    private readonly authService: AuthenticationService,
    private readonly deviceService: DeviceService,
    private readonly dynamicFormsService: DynamicFormsService,
    private readonly dynamics: DynamicsClientService,
    private readonly deltaService: DeltaService,
    private readonly eventsService: EventsService,
    private readonly translate: TranslateService,
    private readonly alertService: AlertService,
    private caseManagementService: CaseManagementService,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private activityDataService: ActivityDataService,
    private footerService: FooterService,
    private secondaryInfoService : SecInfoConfigDataService
  ) { }

  public async syncSetBookingActivities(dataRange: { from: string, to: string }, forceFullSync, loadFromDBOnly): Promise<any> {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) return;
    if (!(this.deviceService.isOffline || loadFromDBOnly)) {
      // await this._loadFormSectionConfiguration();
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_SET_BOOKING_ACTIVITIES);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync || this.secondaryInfoService.isKitBookingSecInfoUpdated;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync ? syncState['lastUpdatedTime'] : null;
      // const positions = this.authService.user.positions.map((o) => {
      //   return o.ID
      // })

      const setBookingActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.setbooking,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        const response = await this._fetchSetBookingActivitiesForUserOnline(dataRange.from, dataRange.to, lastUpdatedTime, doFullSync).catch(err=> {
          console.log(err);
        });
        await this.syncSetBookingActivitiesLinkedEntities(dataRange, lastUpdatedTime, doFullSync);
        if (response && Array.isArray(response)) {
          const newLastUpdatedTime = new Date().getTime();
          if (lastUpdatedTime) {
            let setBookings: Array<SetBookingActivity> = [];
            await this._loadSetBookingActivitiesFromDb();
            response.forEach(record => {
              let setBookingObj = new SetBookingActivity(record);
              setBookingObj.type = ActivityType.SetBooking;
              if (setBookingObj.offlineDBId) {
                setBookings.push(setBookingObj);
              }
            });
            let action: OperationDetail = {
              onDynamics: false,
              onLocalDatabase: true,
              onLocalCopy: true,
              operationDetail: {
                code: 'SBIADEL101',
                message: 'DELTA_SYNC_SETBOOKINGS_UPDATE',
              }
            };
            if (setBookings.length > 0) {
              await this.updateSetBookingActivity(action, setBookings, newLastUpdatedTime);
            }
          } else {
            let setBookings: Array<SetBookingActivity> = [];

            response.forEach(record => {
              let setBookingObj = new SetBookingActivity(record);
              setBookingObj.type = ActivityType.SetBooking;
              if (setBookingObj.offlineDBId && (
                this.authService.user.buSettings['indskr_displaycancelledkitbookingoncalendar'] || 
                setBookingObj.indskr_status != SetBookingStatus.CANCELLED
              )) {
                setBookings.push(setBookingObj);
              }
            });
            if (setBookings.length > 0) {
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'SBAINI101',
                  message: 'Set Booking Activities Initial Sync Creation',
                }
              };
              await this.createSetBookingActivity(action, setBookings, newLastUpdatedTime);
            }
          }
          this.deltaService.addEntitySyncInfo(setBookingActivitySyncInfo);
          // Done sync. Update sync state.
          if (setBookingActivitySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
      }
    }else if(loadFromDBOnly){
      // await this._loadFormSectionConfiguration();
      await this._loadSetBookingActivitiesFromDb();
    }
    this.eventsService.publish('refreshAgenda')
  }

  public async syncFormSectionConfiguration(loadFromDBOnly): Promise<any> {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) return;
    let fetchXml = fetchQueries.setBooking.formSectionConfig;
    if(!(this.deviceService.isOffline || loadFromDBOnly)) {
      try {
        const response = await this.dynamics.executeFetchQuery('indskr_formsectionsconfigurations', fetchXml).catch(err=> {
          console.log(err);
        });
        if (response && Array.isArray(response)) {
          const filtered = _.map(response,_.partialRight(_.pick, ['indskr_formsectionsconfigurationid','indskr_name', '_indskr_mobileappform_value','indskr_visibility']));
          this.formSectionConfigure = filtered;
          this.disk.updateOrInsert(DB_KEY_PREFIXES.FORM_SECTION_CONFIG, (doc)=>{
            doc = {
                raw: []
            };
            doc.raw = this.formSectionConfigure;
            return doc;
          })
        }
      } catch (error) {
        console.log('failed to fetch App Form Section Config : ' + error);
      }
    } else {
      await this._loadFormSectionConfiguration();
    }
  }

  public async _loadFormSectionConfiguration(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.disk.retrieve(DB_KEY_PREFIXES.FORM_SECTION_CONFIG, true).then((doc)=>{
        //offlineDataStored = doc
          if(doc && doc.raw){
            this.formSectionConfigure = doc.raw
          } else {
             this.formSectionConfigure = [];
          }
          resolve('');
        })
      } catch  (error) {
        this.formSectionConfigure = [];
        reject('Error occured while fetching form section config data from offline db');
      }
    })
  }

  private async _loadSetBookingActivitiesFromDb(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let option = {
          selector: {
              '_id': {
                  $gte: DB_KEY_PREFIXES.SET_BOOKING,
                  $lte: DB_KEY_PREFIXES.SET_BOOKING + PREFIX_SEARCH_ENDKEY_UNICODE
              },
          }
      };
      try {
          // Fetch from DB and do mapping
          const rawSetBookings: any[] = await this.disk.find(option);
          if (rawSetBookings && Array.isArray(rawSetBookings) && rawSetBookings.length > 0) {
              let setBookings = [];
              rawSetBookings.forEach(record => {
                if(record.rawData && (
                  this.authService.user.buSettings['indskr_displaycancelledkitbookingoncalendar'] ||
                  record.rawData.indskr_status != SetBookingStatus.CANCELLED)
                ){
                  let newSetBooking = new SetBookingActivity(record.rawData);
                  newSetBooking.type = ActivityType.SetBooking;
                  setBookings.push(newSetBooking);
                }
              });
              if(setBookings.length > 0){
                  let action: OperationDetail = {
                      onDynamics: false,
                      onLocalDatabase: false,
                      onLocalCopy: true,
                      operationDetail: {
                        code: 'SBAOFDBMAP101',
                        message: 'Set Booking Activity Offline DB data mapping'                            }
                  };
                  // Track Offline data count
                  let offlineDataCount = setBookings.filter(o => o.pendingPushToDynamics === true).length;
                  this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SET_BOOKING, offlineDataCount);
                  await this.createSetBookingActivity(action, setBookings, null, false).then(success => {
                      resolve('');
                      return;
                  });
              }else{
                  resolve('');
              }
          } else {
              resolve('');
          }
      } catch (error) {
          reject('Error occured while fetching order activities data from offline db');
      }
  });
  }

  private async _fetchSetBookingActivitiesForUserOnline(fromDate, toDate, lastUpdatedTime, fullSync) {
    let setBookingDisplayForm: DynamicForm = this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!setBookingDisplayForm) {
      return [];
    }

    //let offlineSetBookings = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING);

    if (isBefore(lastUpdatedTime, new Date(setBookingDisplayForm.modiefiedOn))) {
      fullSync = true;
    }

    let fetchXML = this._getSetBookingFetchXMLForConfiguredForm(setBookingDisplayForm);
    let dateAndUserFilter = fetchQueries.setBooking.userAndDateFilter;
    dateAndUserFilter = dateAndUserFilter.replace('{startDate}', format(new Date(parseInt(fromDate)), 'YYYY-MM-DD'));
    dateAndUserFilter = dateAndUserFilter.replace('{endDate}', format(new Date(parseInt(toDate)), 'YYYY-MM-DD'));
    if ((fullSync || !lastUpdatedTime) && !this.authService.user.buSettings['indskr_displaycancelledkitbookingoncalendar']) {
      // Custom check to filter Cancelled Status Set Booking Records
      dateAndUserFilter = dateAndUserFilter.replace('{DeltaSyncFilter}', '<condition attribute="indskr_status" operator="neq" value="808210005" />');
    } else {
      let now = new Date();
      let hourDifference = differenceInHours(
        now,
        new Date(lastUpdatedTime)
      )
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1;
      dateAndUserFilter = dateAndUserFilter.replace('{DeltaSyncFilter}',`<condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />`);
    }
    fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', dateAndUserFilter);
    //fetchXML = fetchXML.replace(/\s+/g, ' ');
    return await this.dynamics.executeFetchQuery('indskr_setbookings', fetchXML);
  }

  private _getSetBookingFetchXMLForConfiguredForm(setBookingDisplayForm) {
    let parentEntityFetchXml = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;
    let nonVisibleFormSection = this.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == setBookingDisplayForm.mobileFormId && !config['indskr_visibility']);
    let parentEntityAttributesStr = [...(function* () {
      yield 'indskr_setbookingid';
      for (const attr of RequiredSetBookingAttributes) {
        yield attr;
      }
    }).call(this)].reduce((p, x) => `${p}<attribute name="${x}"/>`, '');

    setBookingDisplayForm.metadata.forEach(tab => {
      let isSectionHide = false;
      tab.displayNames?.forEach(section => {
        if(!_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase().trim() == section.description.toLowerCase().trim())) {
          isSectionHide = true;
        }
      })
      if(!isSectionHide) {
        tab.controls.forEach(control => {
          if (control.dataType) {
            let linkedAttrFetchXML = '';
            if (control.attributeName) {
              if(_.isEmpty(nonVisibleFormSection) || (!_.isEmpty(nonVisibleFormSection) && !nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())))
              parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
            }
          }
        });
      }
    });

    parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
    parentEntityFetchXml = parentEntityFetchXml.replace('{parentEntityName}', setBookingDisplayForm.entityName);
    parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{secondaryInfoPlaceholder}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Kitbooking));
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', '');

    return parentEntityFetchXml;
  }

  public async syncSetBookingActivitiesLinkedEntities(dataRange,lastUpdatedTime, forceFullSync,setBookingId:string = '', isTeamKitBooking?:boolean): Promise<any> {
    let setBookingDisplayForm: DynamicForm = this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!setBookingDisplayForm) {
      return [];
    }
    if (isBefore(lastUpdatedTime, new Date(setBookingDisplayForm.modiefiedOn)) || !lastUpdatedTime) {
      forceFullSync = true;
    }
    const linkedEntityControls = [];
    const nonVisibleFormSection = this.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == setBookingDisplayForm.mobileFormId && !config['indskr_visibility'])
    setBookingDisplayForm.metadata.forEach(tab => {
      let isSectionHide = false;
      tab.displayNames?.forEach(section => {
        if(!_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase().trim() == section.description.toLowerCase().trim())) {
          isSectionHide = true;
        }
      })
      if(!isSectionHide) {
        tab.controls.forEach(control => {
          if (!control.dataType && control.subgrid) {
            if(_.isEmpty(nonVisibleFormSection) || (!_.isEmpty(nonVisibleFormSection) && !nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())))
            linkedEntityControls.push(control);
          }
        });
      }
    });
    // Pushing Hard Coded Notes Fetch Query
    linkedEntityControls.push({
      referencingEntity: 'annotation',
      subgrid: {
        referencingEntity: 'annotation',
        referencingAttribute: 'objectid',
        parentAttribute: 'indskr_setbookingid',
        subgridQuery: `
          <fetch version="1.0" mapping="logical">
            <entity name="annotation">
              <attribute name="subject" />
              <attribute name="notetext" />
              <attribute name="filename" />
              <attribute name="annotationid" />
              <attribute name="ownerid" />
              <attribute name="createdon" />
              <attribute name="objectid" />
              <attribute name="filesize"/>
              <attribute name="mimetype"/>
              <attribute name="isdocument"/>
            </entity>
          </fetch>
        `,
      }
    })
    if (linkedEntityControls.length > 0) {
      return Promise.all(linkedEntityControls.map(async (linkEntity) => {
        let linkEntityFetchXML = this._getSetBookingLinkedEntityFetchXML(linkEntity,dataRange,lastUpdatedTime,setBookingId);
        let pagingInfo: any = null;
        let rawAllData = [];
        do {
          try {
            let response = await this.dynamics.executeFetchXml('indskr_setbookings', linkEntityFetchXML, undefined, pagingInfo?.nextPage ?? 0, pagingInfo?.cookie ?? null,);
            if (response) {
              pagingInfo = response.PagingInfo ?? null;
              if (Array.isArray(response.value)) {
                rawAllData.push(...response.value);
              }
            }
          } catch (error) {
            console.log("Error retreiving linked entity data: ", error);
            pagingInfo = null;
            rawAllData = null;
          }
        } while (pagingInfo !== null);
        let dbKey = linkEntity.subgrid.referencingEntity;
        let dataToSave = rawAllData;
        if ((lastUpdatedTime || setBookingId || !forceFullSync) && Array.isArray(rawAllData) && !isTeamKitBooking) {
          let temp = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + dbKey);
          let leIdAttrName = linkEntity.subgrid.referencingEntity == 'appointment' ? linkEntity.subgrid.referencingEntity + ".activityid" : linkEntity.subgrid.referencingEntity + "." + linkEntity.subgrid.referencingEntity + "id";
          let localRaw;
          if (temp) {
            localRaw = temp.raw ? temp.raw : []
            rawAllData.forEach(rawRes => {
              if (rawRes && rawRes.hasOwnProperty('indskr_setbookingid') && rawRes.hasOwnProperty('indskr_setbookingid') && rawRes[leIdAttrName]) {
                if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.SetBooking && this.activityOfflineService.selectedActivity.ID == rawRes['indskr_setbookingid']) {
                  this.refreshSelectedSetBookingDetails = true;
                }
                let idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_setbookingid') && a[leIdAttrName] == rawRes[leIdAttrName]);

                if (idx >= 0) {
                  if ((rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) || (rawRes[linkEntity.subgrid.referencingEntity + '.statecode'] == 1 && rawRes[linkEntity.subgrid.referencingEntity + '.statuscode'] == 2)) {
                    localRaw.splice(idx, 1);
                  } else {
                    localRaw[idx] = rawRes;
                  }
                } else {
                  if (rawRes['statuscode'] != 2 && rawRes[linkEntity.subgrid.referencingEntity + '.statuscode'] != 2) {
                    localRaw.push(rawRes);
                  }
                }
              }
            });
            dataToSave = localRaw;
          }
        }
        if(isTeamKitBooking){
          if(dataToSave && dataToSave.length > 0)
            this.teamKitBookingLinkedEntityData[dbKey]={"raw" : [...dataToSave]};
            //this.teamKitBookingLinkedEntityData[dbKey]["raw"] =  dataToSave;
        }
        else{
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + dbKey, doc => {
            doc = {
              raw: dataToSave,
            };
            return doc;
          }).catch(error => console.error('Save Set Booking LE to DB error: ', error));
        }
      }));
    }
  }

  private _getSetBookingLinkedEntityFetchXML(linkEntity,dataRange,lastUpdatedTime,setBookingId){
    let fetchXML = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntityPaging;
    fetchXML = fetchXML.replace('{parentEntityName}', 'indskr_setbooking');
    fetchXML = fetchXML.replace('{parentLevelAttributes}', '<attribute name="indskr_setbookingid"/>');
    fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
    fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
    fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
    fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
    fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
    fetchXML = fetchXML.replace('{customFilterLevel2}', '');
    fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}','');
    if(setBookingId) {
      let setbookingidfilter = `<filter type="and"><condition attribute="indskr_setbookingid" operator="eq" value="` + setBookingId + `" /></filter>`
      fetchXML = fetchXML.replace('{customFilterLevel1}', setbookingidfilter);
      fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
    } else {
      let dateAndUserFilter = fetchQueries.setBooking.userAndDateFilter;
      dateAndUserFilter = dateAndUserFilter.replace('{startDate}', format(new Date(parseInt(dataRange.from)), 'YYYY-MM-DD'));
      dateAndUserFilter = dateAndUserFilter.replace('{endDate}', format(new Date(parseInt(dataRange.to)), 'YYYY-MM-DD'));
      fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
      fetchXML = fetchXML.replace('{customFilterLevel1}', dateAndUserFilter);
    }
    let hourDifference;
    if(lastUpdatedTime){
      let now = new Date();
      hourDifference = differenceInHours(now,new Date(lastUpdatedTime))
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1;
    }
    if (hourDifference && !setBookingId) {
      // let lastModifiedFilter =
      //   `<filter type="or">
      //     <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="` + linkEntity.subgrid.referencingEntity + `"/>
      //   </filter>`
      fetchXML = fetchXML.replace('{DeltaSyncFilter}', `<condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="` + linkEntity.subgrid.referencingEntity + `"/>`);
      fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
    }// No modified on filter for intial sync
    else if (!hourDifference && !setBookingId) {
      fetchXML = fetchXML.replace('{DeltaSyncFilter}', '<condition attribute="indskr_status" operator="neq" value="808210005" />');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
    }
    // Modified on filter for usual real time details fetch - filter at only linked entity level
    else {
      let lastModifiedFilter = setBookingId ? '' :
        `<filter type="or">
          <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `"/>
        </filter>`
      fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', lastModifiedFilter);
    }
    let queryString = linkEntity.subgrid.subgridQuery;
    let JSONQuery;
    let linkEntityAttributesStr = '';
    XML2JS.parseString(queryString, (err, data) => {
      JSONQuery = data;
    });
    JSONQuery.fetch.entity[0].attribute.forEach(attr => {
      linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>';
    });
    let numOfLinkEntity = 0;
    if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
      JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
        numOfLinkEntity++;
        try {
          linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
            + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
          if (linEnt.attribute) {
            linEnt.attribute.forEach(linEntAttr => {
              linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
            });
          }
          linkEntityAttributesStr += "</link-entity>"
        } catch (error) {
          console.log(error);
        }
      });
    }
    linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
    linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);
    if(linkEntity.subgrid && linkEntity.subgrid.referencingEntity != 'annotation'){
      linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
    }
    linkEntityAttributesStr = linkEntityAttributesStr.replace('<condition attribute=\"statecode\" operator=\"eq\" value=\"0\"/>', '');
    fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);
    if(linkEntity.subgrid && linkEntity.subgrid.referencingEntity == 'indskr_setbookingteammember'){
      fetchXML = fetchXML.replace(`<link-entity name="indskr_setbookingteammember" from="indskr_setbooking" to="indskr_setbookingid" visible="false" intersect="true" link-type="outer"></link-entity>`, '');
    }
    return fetchXML;
  }

  public async fetchRealTimeSetBookingDetails(setBooking:SetBookingActivity){
    let setBookingDisplayForm: DynamicForm = this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!setBookingDisplayForm) {
      return [];
    }
    let fetchXML = this._getSetBookingFetchXMLForConfiguredForm(setBookingDisplayForm);
    fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', `<filter type="and">
                  <condition attribute="indskr_setbookingid" operator="eq" value="${setBooking.ID}" />
                </filter>`);
    let res = await this.dynamics.executeFetchQuery('indskr_setbookings', fetchXML);
    if(!setBooking.isTeamKitBooking)
      await this.syncSetBookingActivitiesLinkedEntities(null, null, true,setBooking.ID);
    else
      await this.syncSetBookingActivitiesLinkedEntities(null, null, true,setBooking.ID, setBooking.isTeamKitBooking);
    if(res && Array.isArray(res) && res[0]){
      setBooking.rawData = res[0];
      setBooking.indskr_status = res[0]['indskr_status'];
      setBooking.serviceDTO = null;
      setBooking.statusString = res[0]['indskr_status_Formatted'];
      setBooking.subject = (res[0]['indskr_name']) ? res[0]['indskr_name'] : 'Kit Booking'
      if (res[0]['indskr_operationstartdate']) {
        setBooking.rawData['indskr_startdate'] = setBooking.rawData['indskr_operationstartdate'];
        if(isInteger(res[0]['indskr_operationstartdate'])){
          setBooking.scheduledStart = new Date(parseInt(res[0]['indskr_operationstartdate']));
        }else{
          setBooking.scheduledStart = new Date(res[0]['indskr_operationstartdate']);
        }
        setBooking.scheduledEnd = addMinutes(setBooking.scheduledStart, 30);
        setBooking.scheduledStartLocale = setBooking.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
      }
      if (res[0]['indskr_operationenddate']) {
        setBooking.rawData['indskr_enddate'] = setBooking.rawData['indskr_operationenddate'];
        if(isInteger(res[0]['indskr_operationenddate'])){
          setBooking.scheduledEnd = new Date(parseInt(res[0]['indskr_operationenddate']));
        }else{
          setBooking.scheduledEnd = new Date(res[0]['indskr_operationenddate']);
        }
      }
      if(!setBooking.isTeamKitBooking){
        let action: OperationDetail = {
          onDynamics: false,
          onLocalDatabase: true,
          onLocalCopy: true,
          operationDetail: {
            code: 'SBIARTD101',
            message: 'REAL_TIME_SETBOOKINGS_UPDATE',
          }
        };
        await this.updateSetBookingActivity(action, [setBooking], new Date().getTime());
      }
    }
  }

  private async _createKitBookingActivityOnline(payload: object): Promise<any> {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.setbooking.CREATE_UPDATE_SET_BOOKING;
    return this.http.post(url, payload).toPromise();
  }

  private async _updateKitBookingActivityOnline(payload: object): Promise<any> {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.setbooking.CREATE_UPDATE_SET_BOOKING;
    return this.http.post(url, payload).toPromise();
  }

  public async createSetBookingActivity(action: OperationDetail, data: Array<SetBookingActivity>, newLastUpdatedTime: number, isInitialSync: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject('NO_DATA_PASSED_FOR_OPERATION');
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) {
          let serviceDTO = data[0].serviceDTO;
          await this._createKitBookingActivityOnline(serviceDTO).then(info => {
            // Succesfully created on dynamics
            if (info && info['indskr_setbookingid']) {
              data[0].ID = info['indskr_setbookingid'];
              data[0].pendingPushToDynamics = false;
            }
            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve('NO_SUCCESSFULLY_CREATED_ON_SERVER');
              checkNextAction = false;
            }
          }).catch(error => {
            // Handle any error scenario
            // checkNextAction = true; // Will need this when offline is supported for kit booking
          });
        } else {
          console.log('Got offline data as:' + data);
          resolve('');
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        if (isInitialSync) {
          try {
            await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SET_BOOKING_ACTIVITIES);
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 0);
          } catch (error) {
            reject('ERROR_WHILE_CLEARING_OFFLINE_DB' + error);
            return;
          }
        }
        //Initialise document id and get offline DTO for each activity
        if (data) {
          let offlineData = [];
          for (let i = 0; i < data.length; i++) {
            let setBooking = data[i];
            let offlineDTO = {
              ...setBooking,
              _id: setBooking.offlineDBId,
              lastUpdatedTime: newLastUpdatedTime,
            };
            offlineData.push(offlineDTO);
          };

          if (offlineData && Array.isArray(offlineData)) {
            try {
              // Bulk save docs to DB
              await this.disk.bulk(offlineData);
              // Track offline data count
              const count: number = offlineData.filter(o => o.pendingPushToDynamics).length;
              if (count > 0) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SET_BOOKING, count);
              }
            } catch (error) {
              reject('ERROR_WHILE_SAVING_OFFLINE_DATA' + error);
              checkNextAction = false;
            }
            if (!action.onLocalCopy) {
              resolve('SUCCESSFULLY_CREATED_ON_OFFLINE_DB');
              checkNextAction = false;
            }
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        // push into local array of order-activities
        if (data) {
          let callForFilterActivities = true;
          if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'SBAOFDBMAP101' || action.operationDetail.code == 'SBAINI101')){
            callForFilterActivities = false;
          }
          data.forEach(setBooking => {
            this.activityOfflineService.addActivity(setBooking, false, false, null, callForFilterActivities).then(success => {
              resolve('Successfully created order activity');
            }).catch(err => {
              reject('Error Occured while saving order activity into local array' + err);
            })
          })
        }
      }
    });
  }

  public async updateSetBookingActivity(action: OperationDetail, data: Array<SetBookingActivity>, newLastUpdatedTime: number, isInitialSync: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject('NO_DATA_PASSED_FOR_OPERATION');
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) {
          let serviceDTO = data[0].serviceDTO;
          await this._updateKitBookingActivityOnline(serviceDTO).then(info => {
            // Succesfully created on dynamics
            if (info && info['indskr_setbookingid']) {
              data[0].ID = info['indskr_setbookingid'];
              data[0].pendingPushToDynamics = false;
            }
            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve('NO_SUCCESSFULLY_UPDATED_ON_SERVER');
              checkNextAction = false;
            }
          }).catch(error => {
            // Handle any error scenario
            checkNextAction = false;
            //data[0].pendingPushToDynamics = true;
            reject({
              errorCode: 'ONLINEONLYUPDATEFAILED',
              operationCode: action.operationDetail.code,
              errorMessage: 'Failed to perform the operation on dynamics',
              errorDetails: error,
            })
          });
        } else {
          console.log('Got offline data as:' + data);
          resolve('');
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        //Initialise document id and get offline DTO for each activity
        if (data) {
          let offlineData = [];
          for (let i = 0; i < data.length; i++) {
            let setBooking = data[i];
            let offlineDTO = {
              ...setBooking,
              _id: setBooking.offlineDBId,
              lastUpdatedTime: newLastUpdatedTime,
            };
            let savedOfflineObject = await this.disk.retrieve(setBooking.offlineDBId);
            if (action.operationDetail && action.operationDetail.code == 'SBIADEL101' && savedOfflineObject && savedOfflineObject.pendingPushToDynamics) {
              // In delta Sync set booking activity update, do not update activities that have pending changes to be pushed
              //checkNextAction = false;
              data[i].pendingPushToDynamics = true;
              resolve('');
            }
            else if (savedOfflineObject) {
              // Handle Deleted activities and track action
              if (action.operationDetail && 
                  action.operationDetail.code && 
                  action.operationDetail.code == 'scrapsetbooking' && 
                  setBooking.pendingPushToDynamics == false && 
                  setBooking.indskr_status == SetBookingStatus.CANCELLED) { // Will reach here only if the updates have been pushed to dynamics and we can remove the object from offline db
                offlineData.push(getDeletedPayloadObejct(savedOfflineObject));
              } else if(action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'removesetbookingonassignmentchange' && setBooking.pendingPushToDynamics == false){
                  savedOfflineObject._deleted = true;
              } else {
                offlineDTO._id = savedOfflineObject['_id'];
                offlineDTO._rev = savedOfflineObject['_rev'];
                offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                offlineData.push(offlineDTO);

                // If a new update in offline, add the offline data count
                if (!savedOfflineObject.pendingPushToDynamics && offlineDTO.pendingPushToDynamics) {
                  this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SET_BOOKING, 1);
                }
              }
            } else if (action.operationDetail && action.operationDetail.code == 'SBIADEL101') {
              offlineDTO.lastUpdatedTime = newLastUpdatedTime;
              offlineData.push(offlineDTO);

              // If a new update in offline, add the offline data count
              if (offlineDTO.pendingPushToDynamics) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SET_BOOKING, 1);
              }
            }
          };

          if (offlineData && Array.isArray(offlineData)) {
            try {
              // Bulk save docs to DB
              await this.disk.bulk(offlineData);
              // Track offline data count
              const count: number = offlineData.filter(o => o.pendingPushToDynamics).length;
              if (count > 0) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SET_BOOKING, count);
              }
            } catch (error) {
              reject('ERROR_WHILE_SAVING_OFFLINE_DATA' + error);
              checkNextAction = false;
            }
            if (!action.onLocalCopy) {
              resolve('SUCCESSFULLY_CREATED_ON_OFFLINE_DB');
              checkNextAction = false;
            }
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        // push into local array of order-activities
        if (data) {
          let callForFilterActivities = true;
          if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'SBAOFDBMAP101' || action.operationDetail.code == 'SBAINI101')){
            callForFilterActivities = false;
          }
          data.forEach(setBooking => {
            // Check for deletion case
            if (action.operationDetail && action.operationDetail.code == 'SBIADEL101' && setBooking.pendingPushToDynamics) {
              // In delta Sync order activity update, do not update activities that have pending changes to be pushed
              //checkNextAction = false;
              resolve('');
            } else {
              if (setBooking.indskr_status == SetBookingStatus.CANCELLED && 
                !this.authService.user.buSettings['indskr_displaycancelledkitbookingoncalendar']
              ) {// If order activity is deleted or track action is deletion one
                this.activityOfflineService.publishActivityEvent({ action: "Delete", activity: setBooking });
                if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.SetBooking && this.activityOfflineService.selectedActivity.ID == setBooking.ID) {
                  this.activityOfflineService.selectedActivity = null;
                }
                this.activityOfflineService.removeActivity(setBooking, false, null, callForFilterActivities).then(success => {
                  resolve('Successfully updated set booking activity');
                }).catch(err => {
                  reject('Error Occured while updating set booking activity into local array' + err);
                })
              } else {
                this.activityOfflineService.addActivity(setBooking, true, false, null, callForFilterActivities).then(success => {
                  if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.SetBooking && this.activityOfflineService.selectedActivity.ID == setBooking.ID) {
                    this.refreshSelectedSetBookingDetails = true;
                    this.activityOfflineService.selectedActivity = setBooking;
                  }
                  resolve('Successfully updated setBooking activity');
                }).catch(err => {
                  reject('Error Occured while updating setBooking activity into local array' + err);
                })
              }
            }
          })
        }
      }
    });
  }

  public async uploadKitBookingNotesOnline(currentNote: IONote):Promise<any> {
    //push to dynamics
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.setbooking.SET_BOOKING_NOTES;
    const payload = {
        notetext: currentNote.noteText,
        objectid: currentNote.objectId,
        createdon: currentNote.createdTime.getTime(),
        ownerid: currentNote.ownerId,
        deleted: currentNote.isDeleted,
        filename:currentNote.documentName,
        filesize:currentNote.documentSize,
        documentbody: currentNote.documentDataURL,
        mimetype: currentNote.documentMimeType,
        isdocument: currentNote.hasDocument
    };
    // if(currentNote.documentName){
    //   payload["filename"]= currentNote.documentName;
    // }
    // if(currentNote.documentSize){
    //   payload["filesize"]= currentNote.documentSize;
    // }
    // if(currentNote.documentDataURL){
    //   payload["documentbody"]= currentNote.documentDataURL;
    // }
    // if(currentNote.documentMimeType){
    //   payload["mimetype"]=currentNote.documentMimeType;
    // }
    if(currentNote.noteId){
      payload["noteid"]= currentNote.noteId;
    }
    // if(currentNote.hasDocument){
    //   payload["isdocument"]=currentNote.hasDocument;
    // }
    return this.http.post(url, [payload]).toPromise();
}

  public async saveKitBookingNotesOffline(currentNote: IONote): Promise<any>{
    let dbNotes = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.KIT_BOOKING_NOTES).catch(
      error => console.error('No Kit booking notes in offline db', error)
    );
    if(!dbNotes){
      dbNotes={
        raw:[],
      };
    }
    try {
        if(dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw)){
          let dbIdx = dbNotes.raw.findIndex(item=>item['annotation.annotationid']==currentNote.noteId);
          //if currentNote is deleted
          if(currentNote.isDeleted == true && dbIdx >= 0){
              dbNotes.raw.splice(dbIdx,1);
          }
          //else its new or update existing
          else{
            if(dbIdx >= 0){
              dbNotes.raw[dbIdx]['indskr_setbookingid'] = currentNote.objectId;
              dbNotes.raw[dbIdx]['annotation.annotationid'] = currentNote.noteId;
              dbNotes.raw[dbIdx]['annotation.objectid'] = currentNote.objectId;
              dbNotes.raw[dbIdx]['annotation.notetext'] = currentNote.noteText;
              dbNotes.raw[dbIdx]['annotation.filename'] = currentNote.documentName;
              dbNotes.raw[dbIdx]['annotation.filesize'] = currentNote.documentSize;
              dbNotes.raw[dbIdx]['annotation.documentbody'] = currentNote.documentDataURL;
              dbNotes.raw[dbIdx]['annotation.mimetype'] = currentNote.documentMimeType;
              dbNotes.raw[dbIdx]['annotation.deleted'] = currentNote.isDeleted;
              dbNotes.raw[dbIdx]['annotation.createdon'] = currentNote.createdTime;
              dbNotes.raw[dbIdx]['annotation.ownerid'] = currentNote.ownerId;
              dbNotes.raw[dbIdx]['annotation.ownerid@OData.Community.Display.V1.FormattedValue'] = currentNote.ownerName;
              dbNotes.raw[dbIdx]['annotation.isdocument']=currentNote.hasDocument;
            }
            else{
              dbNotes.raw.push({
                'indskr_setbookingid': currentNote.objectId,
                'annotation.annotationid': currentNote.noteId,
                'annotation.objectid': currentNote.objectId,
                'annotation.notetext': currentNote.noteText,
                'annotation.filename': currentNote.documentName,
                'annotation.filesize': currentNote.documentSize,
                'annotation.documentbody' : currentNote.documentDataURL,
                'annotation.mimetype': currentNote.documentMimeType,
                'annotation.deleted': currentNote.isDeleted,
                'annotation.createdon': currentNote.createdTime.getTime(),
                'annotation.ownerid@OData.Community.Display.V1.FormattedValue': currentNote.ownerName,
                'annotation.ownerid': currentNote.ownerId,
                'annotation.isdocument': currentNote.hasDocument,
              });
            }
          }
        }
        // else if(currentNote.isDeleted!=true){
        //   dbNotes={
        //     raw:[{
        //       'annotation.annotationid': currentNote.noteId,
        //       'annotation.objectid': currentNote.objectId,
        //       'annotation.notetext': currentNote.noteText,
        //       'annotation.filename': currentNote.documentName,
        //       'annotation.filesize': currentNote.documentSize,
        //       'annotation.documentbody' : currentNote.documentDataURL,
        //       'annotation.mimetype': currentNote.documentMimeType,
        //       'annotation.deleted': currentNote.isDeleted,
        //       'annotation.createdon': currentNote.createdTime.getTime(),
        //       'annotation.ownerid@OData.Community.Display.V1.FormattedValue': currentNote.ownerName,
        //       'annotation.ownerid': currentNote.ownerId,
        //     }],
        //   }
        // }
    } catch (error) {
        console.log(error);
    }
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.KIT_BOOKING_NOTES, doc => {
        doc = dbNotes;
        return doc;
    }).catch(error => console.error('Save KitBooking Notes Linked Entity to DB error: ', error));

  }

  public async checkSetBookingDiscardConfirmationRequired():Promise<any> {
    return new Promise(async (resolve, reject) => {
      if(this._isCreateEditKitBookingFormDirty){
        let cancelText = this.translate.instant("CANCEL");
        if(this.translate.currentLang == 'it') {
          cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
        }
        this.alertService.showAlert({
          header: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
          message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
      ).then (res => {
          if(res.role == "ok") {
            this._isCreateEditKitBookingFormDirty = false;
            resolve(true);
          }else{
            reject(false)
          }
      });
      }else {
        resolve(true);
      }
    });
  }

  public setCurrentKitBookingMeetingActivity(activity: EmbeddedInteraction) {
    this.selectedKitBookingMeetingActivitySource.next(activity);
  }

  public setCurrentKitBookingMeetingStructure(input) {
    this.currentKitBookingMeetingStructureSource.next(input);
  }

  private async saveToDisk(embeddedInteractions: EmbeddedInteraction[]) {
    const activity: SetBookingActivity = <SetBookingActivity>this.activityOfflineService.selectedActivity;
    const activityId = activity.offlineId && activity.offlineId.length > 0 ? activity.offlineId : activity.ID;
    let key = DB_KEY_PREFIXES.EMBEDDED_INTERACTIONS + activityId;
    await this.disk.updateOrInsert(key, doc => ({ embeddedInteractions }));
  }

  private updateEmbeddedInteraction(embeddedInteractions: EmbeddedInteraction[]) {
    this.setCurrentKitBookingMeetingStructure(embeddedInteractions);
    this.saveToDisk(embeddedInteractions);
  }

  public async openInKitBookingMeetingCaseModal(activity: Activity){
    this.caseManagementService.assignSelectedCase(<CaseActivity>activity);
    let source = AccesingMode.IN_SET_BOOKING

    this.caseManagementService.accessedFrom = source;
    let loader = await this.loadingController.create();
    if(!this.authService.impersonatedUser){
      this.activityDataService.fetchCaseDetails(activity);
    }
    let modal =await this.modalCtrl.create({
      component: InMeetingCasesComponent,
      componentProps: {
        type: activity.type,
        view: 'case-details'
    },
    backdropDismiss: false, cssClass: this.deviceService.isOffline ? "inMeetingCases offline-padding" : "inMeetingCases" })
    modal.present();
    modal.onDidDismiss().then((data) => {
        if (source == AccesingMode.IN_SET_BOOKING) {
            this.footerService.initButtons(FooterViews.SetBooking);
        }
    });
  }

  private reInitializeSequence(embeddedInteractions: EmbeddedInteraction[]) {
    if (embeddedInteractions && embeddedInteractions.length > 0) {
        let sequence = embeddedInteractions.length - 1;
        embeddedInteractions = _.orderBy(embeddedInteractions, "sequence", "desc");
        embeddedInteractions.forEach(eI => {
            eI.sequence = sequence;
            sequence--;
        });
    }
    return embeddedInteractions;
}

  public createInKitBookingMeetingEmbededActivity(embeddedActivity: Activity){
    let currentEmbeddedInteraction = this.currentKitBookingMeetingStructureSource.getValue() ?
    this.currentKitBookingMeetingStructureSource.getValue() : [];
    let seq = this.getKitBookingMeetingActivitySequence(currentEmbeddedInteraction);
    let embeddedInteraction: EmbeddedInteraction = this.getKitBookingMeetingActivity(embeddedActivity, seq);
    currentEmbeddedInteraction.push(embeddedInteraction);
    this.setCurrentKitBookingMeetingActivity(embeddedInteraction);
    this.updateEmbeddedInteraction(this.reInitializeSequence(currentEmbeddedInteraction))
  }

  private getKitBookingMeetingActivitySequence(embeddedActivities: EmbeddedInteraction[]) {
    let seq = 0;
    const selectedMeetingActivity: EmbeddedInteraction = this.selectedKitBookingMeetingActivitySource.getValue();
    if (!selectedMeetingActivity) {
        // get the highest sequence
        if (embeddedActivities && embeddedActivities.length > 0) {
            seq = embeddedActivities
                .filter(embed => embed.sequence != null)
                .sort((a, b) => (b.sequence) - (a.sequence))[0].sequence;
            seq++;
        }
    }
    else {
        seq = selectedMeetingActivity.sequence;
        if (embeddedActivities && embeddedActivities.length > 0) {
            embeddedActivities.filter(embed => embed.sequence < seq).forEach(embed => embed.sequence = --embed.sequence);
            this.setCurrentKitBookingMeetingStructure(embeddedActivities);
        }
        seq = seq - 1;
    }
    return seq;
  }

  private getActivityId(activity: Activity) {
    let id = '';
    switch (activity.type) {
        case ActivityType.CaseIntake:
            id = (<CaseActivity>activity).offline_ID;
            break;
        // case ActivityType.Email:
        //     id = (<EmailActivity>activity).offlineActivityId;
        //     break;
        default:
            console.log("Unhandled swithc case");
    }
    return id;
  }

  private activityTitle(activity: Activity) {
    let title: string = "";
    switch (activity.type) {
        // case ActivityType.Email:
        //     let emailActivity: EmailActivity = new EmailActivity(activity);
        //     title = this.translate.instant('MESSAGE') + (emailActivity.subject == 'Message' ? '' : ' - ' +
        //     emailActivity.subject)
        //     break;
        case ActivityType.CaseIntake:
            let caseActivity: CaseActivity = <CaseActivity>activity;
            title = this.translate.instant('INQUIRY');
            if (caseActivity._case_type) {
                title = caseActivity._case_type.name + ((caseActivity && caseActivity._case_contact) ?
                (' - ' + caseActivity._case_contact.fullName) : '');
            }
            break;
        default:
            console.log("Unhandled activity case");
    }
    return title;
  }

  private getActivityIconName(activity: Activity) {
    let iconName: string = '';
    switch (activity.type) {
        // case ActivityType.Email:
        //     iconName = "email";
        //     if (activity.status === 1) {
        //         iconName = "email";
        //     }
        //     else if (activity.status === 3 || activity.status === 548910000) {
        //         iconName = "email_blue";
        //     }
        //     else if (activity.status === 6 || activity.status === 9) {
        //         iconName = "email_orange"
        //     }
        //     else if (activity.status === 8) {
        //         iconName = "email_red"
        //         if (activity instanceof EmailActivity && activity.channelType !== ChannelType.EMAIL && activity.status == 8 && activity.emailActivityParties.length > 1) {
        //             iconName = "email";
        //         }
        //     }
        //     else if (activity.status === 0) {
        //         iconName = "email_green"
        //     }
        //     break;
        case ActivityType.CaseIntake:
            iconName = (<CaseActivity>activity)._case_status_value === 'Open' ? "case-intake" : "case-intake-completed";
            break;
        default:
            console.log("Unhandled activity icon case");
    }
    return "indegene-" + iconName;
  }

  private getKitBookingMeetingActivity(embeddedActivity: Activity, seq: number) {
      let embeddedInteraction: EmbeddedInteraction;
      if (embeddedActivity instanceof Activity) {
          embeddedInteraction = new EmbeddedInteraction(this.getActivityId(embeddedActivity),
          this.activityTitle(embeddedActivity), this.activityOfflineService.getActivityIcon(embeddedActivity),
          EmbeddedInteractionType.Activity, seq, embeddedActivity, this.getActivityIconName(embeddedActivity));
      }
      return embeddedInteraction;
  }

  public async getLimitedReleaseInfoForSelectedProduct(productId: string[]) {
    let fetchXML = fetchQueries.setBooking.productLimitiedRelease;
    let productIDValues = ''
    productId.forEach(p => { productIDValues += '<value>' + p + '</value>' })
    fetchXML = fetchXML.replace('{productIDs}', productIDValues);
    let response;
    try {
      let data = await this.dynamics.executeFetchQuery('products', fetchXML);
      response = data?.value ? data.value : data;
    } catch (error) {
      console.log(error);
    }
    return response;
  }

}

function getDeletedPayloadObejct(raw) {
  let responsePayload = {
    _id: raw._id,
    _rev: raw._rev,
    offlineId: raw.offlineId,
    statecode: raw.statecode,
    statuscode: raw.statuscode,
    track_action: TrackAction.Deleted,
    _deleted: true,
    pendingPushToDynamics: false,
  };
  if (raw['indskr_setbookingid']) {
    responsePayload['indskr_setbookingid'] = raw['indskr_setbookingid'];
  }
  return responsePayload;
}
