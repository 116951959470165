<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'child-nav-active':'child-nav-inactive'">
  <ion-row>
    <ion-col class="contact-left-pane" no-padding>
      <contact-list [contactListMode]="contactListMode" [participantsList]="voiceSignalViaNavParams"
        [selectedContactIds]="selectedContactIds" [isCovisitorView]="isCovisitorView"
        [selectedAccountIds]="selectedAccountIds" [callbackEvent]="callbackEvent" [maximumeventcapacity]="maximumeventcapacity"
        (addNewContactHandler)="onAddNewContactHandler($event)"></contact-list>
    </ion-col>
    <ion-col class="contact-right-pane" no-padding>
      <!-- <div class="activity-height"> -->
        <!-- <nothing-selected-view class="ion-page show-page" *ngIf="!contactSelected"></nothing-selected-view>
        <contact-details (openScientificPlanDetails)="onOpenScientificPlanDetails($event)" (editContactHandler)="onEditContactHandler()" [contactListMode]="contactListMode" *ngIf="(contactService.contactInformation || contactService.isOneKeySearching) && uiService.activeView == 'contactDetails' && !uiService.showNewActivity"></contact-details>
        <activity-skeleton-pane *ngIf="uiService.activeView == 'ActivitySkeleton' && !uiService.showNewActivity"></activity-skeleton-pane>
        <location *ngIf="!uiService.showNewActivity && uiService.activeView == 'Location'"></location>
        <contact-list-ro *ngIf="!uiService.showNewActivity && activityService?.selectedActivity && uiService.activeView == 'ReadOnlyContact'"></contact-list-ro>
        <account-list-ro *ngIf="!uiService.showNewActivity && activityService?.selectedActivity && uiService.activeView == 'ReadOnlyAccount'"></account-list-ro>
        <new-activity *ngIf="uiService.showNewActivity" [NewActivitySource]="activityService?.activitySource" (onCancelNewActivity)="onCancelNewActivity()"></new-activity>
        <new-sample-activity *ngIf="!uiService.showNewActivity && activityService?.selectedActivity && uiService.activeView == 'Sample'"></new-sample-activity>
        <email-activity-detail *ngIf="!uiService.showNewActivity && activityService?.selectedActivity && uiService.activeView == 'Email'  && contactService.contactInformation"></email-activity-detail>
        <resource-interaction-detail *ngIf="activityService?.selectedActivity && !uiService.showNewActivity && (uiService.activeView == 'ResourceContactList' || uiService.activeView == 'ResourceInteractionDetails')"></resource-interaction-detail> -->
        <ion-nav #contactpagerightpane></ion-nav>
        <!-- <scientific-info-details *ngIf="uiService.activeView == 'scientificInfoDetails' && !uiService.showNewActivity"></scientific-info-details>
        <publication-preview *ngIf="uiService.activeView == 'publicationPreview' && !uiService.showNewActivity"></publication-preview>
        <phone-call-details [showBackButton]="true" *ngIf="!uiService.showNewActivity && uiService.activeView =='phoneCallTimelineDetails' && activityService?.selectedActivity"></phone-call-details>
        <ion-nav #participantListNav [root]='participantListComponent' [rootParams]='participantListComponentParams' *ngIf="!uiService.showNewActivity && activityService?.selectedActivity && activityService?.selectedSharedResource && uiService.activeView == 'SharedResources'"></ion-nav>
        <account-details *ngIf="!uiService.showNewActivity && uiService.activeView == 'accountDetails'"></account-details> -->
      <!-- </div> -->
    </ion-col>
  </ion-row>
</ion-grid>
