<ion-item-group>
  <ind-section-header
    [viewData]='{id:"allocation-eligibilities-header", title: translate.instant("MATERIALS_AND_SAMPLES")}'></ind-section-header>
  <ion-item class='empty-item' *ngIf="(!sampleEligibilities || sampleEligibilities.length == 0)"> </ion-item>
  <ion-list>
    <ion-item-group *ngFor="let sampleAllocation of sampleEligibilities" class="eligibility-grid">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-lg="6">
            <ion-icon [style.width.px]="30" [style.height.px]="30" [style.marginBottom.px]="-10"
              name="indegene-eligibility" class="indegene-eligibility-icon"></ion-icon>
            <ion-text> {{sampleAllocation.allocationName}} </ion-text>
          </ion-col>
          <ion-col size="12" size-lg="6"
            *ngIf="!sampleAllocation.isUnlimited || sampleAllocation.totalsamplevaluelimit > 0">
            <ion-text>
              <span class="child-item-label">{{ 'VALUE_REMAINING' | translate}}</span>
              <ng-container *ngIf="sampleAllocation.totalsamplevaluelimit > 0">
                <ion-text
                  class="child-item-value">{{sampleAllocation.indskr_totalsamplevalueremaining}}/{{sampleAllocation.totalsamplevaluelimitFormatted}}
                </ion-text>
                <ion-progress-bar class="value-remaining"
                  [value]="1 - ((sampleAllocation.totalsamplevaluelimit - sampleAllocation.indskr_totalsamplevaluedelivered)/sampleAllocation.totalsamplevaluelimit)">
                </ion-progress-bar>
              </ng-container>
              <ng-container
                *ngIf="!sampleAllocation.isUnlimited && (!sampleAllocation.totalsamplevaluelimit || sampleAllocation.totalsamplevaluelimit <= 0)">
                <ion-text class="child-item-value">{{sampleAllocation.remaining}}/{{sampleAllocation.limit}}
                </ion-text>
                <ion-progress-bar class="value-remaining"
                  [value]="1 - (sampleAllocation.remaining/sampleAllocation.limit)">
                </ion-progress-bar>
              </ng-container>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6" [sizeLg]="sampleAllocation.sampleSKUId ? '4' : '6'" *ngIf="sampleAllocation.buId">
            <ion-label class="child-item-label">{{ 'BUSINESS_UNIT' | translate}}</ion-label>
            <ion-label>{{sampleAllocation.buName}}</ion-label>
          </ion-col>
          <ion-col size="6" [sizeLg]="sampleAllocation.buId ? '4' : '6'" *ngIf="sampleAllocation.sampleSKUId">
            <ion-label class="child-item-label">{{ 'PRODUCT' | translate}}</ion-label>
            <ion-label>{{sampleAllocation.sampleSKUName}}</ion-label>
          </ion-col>
          <ion-col size="8" [sizeLg]="sampleAllocation.buId || sampleAllocation.sampleSKUId ? '4' : '6'">
            <ion-label class="child-item-label">{{ 'PERIOD' | translate}}</ion-label>
            <ion-label>
              {{
              'CALL_PLAN_DURATION' | translate : {startDate: sampleAllocation.startDateFormatted,
              endDate:sampleAllocation.endDateFormatted}
              }}
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item-group>
  </ion-list>
</ion-item-group>