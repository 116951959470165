export class ScientificInfo {
  constructor(
    public publications: Publication[],
    public researches: Research[],
    public speakerEngagements: SpeakerEngagement[],
    public eventHistory: EventHistory[],
    public sessionHistory: SessionHistory[]
  ){}
}

export class Publication {
  constructor(
    public indskr_contactid: string,
    public indskr_subject: string,
    public indskr_name: string,
    public indskr_description: string,
    public indskr_publicationsid: string,
    public statecode: number,
    public indskr_dateofpublication: string,
    public indskr_articleidentified: string,
    public indskr_author: string,
    public attachmentDetails: AttachmentDetail[]
  ){}
}

export class Research {
  constructor(
    public indskr_name: string,
    public indskr_researchtype: string,
    public indskr_studytype: string,
    public indskr_fundedby: string,
    public indskr_roles: string,
    public indskr_sponsor: string
  ){}
}

export class SpeakerEngagement {
  constructor(
    public indskr_sessionid: string,
    public indskr_sessionname: string,
    public indskr_speakerengagementname: string,
    public indskr_speakerengagementid: string,
    public indskr_eventid: string,
    public indskr_eventname: string,
    public indskr_sessionstarttime: string,
    public indskr_sessionendtime: string
  ){}
}

export class EventHistory {
  constructor(
    public indskr_eventid: string,
    public indskr_eventname: string,
    public indskr_checkInTime: string,
    public indskr_eventEndDate: string,
    public indskr_eventStartDate: string
  ){}
}

export class SessionHistory {
  constructor(
    public indskr_sessionid: string,
    public indskr_sessionname: string,
    public indskr_eventname: string,
    public indskr_eventid: string,
    public indskr_audiencetypeid: string,
    public indskr_audiencetypename: string,
    public indskr_sessionformat: string,
    public indskr_sessiontitle: string
  ){}
}

export class PublicationPreview {
  constructor(
    public indskr_filename: string,
    public indskr_mimetype: string,
    public indskr_documentbody: string,
    public indskr_notetext: string,
    public indskr_name: string
  ) {}
}

export class AttachmentDetail {
  constructor(
    public indskr_attachmentid: string,
    public indskr_attachmentname: string,
    public indskr_publicationsid: string
  ) {}
}

