<ion-header>
  <ion-toolbar class="left-header-toolbar" [ngClass]="{ 'toolbar-android': isAndroid}">
    <ion-buttons class="start-slot" slot="start">
      <ion-menu-toggle>
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" src="assets/imgs/menu-toggle.svg"></ion-icon>
      </ion-menu-toggle>
    </ion-buttons>

    <div class="searchbar-wrapper">
        <ion-searchbar [debounce]="300" placeholder="{{'HOME' | translate}}"></ion-searchbar>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
  <ind-section-header [isSkeleton]="true"></ind-section-header>
  <ng-container *ngFor="let i of [].constructor(numberOfSkeletonCardsToDisplay)">
      <main-card [isSkeleton]="true"></main-card>
  </ng-container>
  </ion-list>
</ion-content>
