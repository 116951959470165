<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" >
            <ion-button (click)="backToMeeting()" class="back-btn">
                <ion-icon name="arrow-back" ></ion-icon>
                {{'EMAIL_TEMPLATE_NEW_MEETING' | translate}}
            </ion-button>
        </ion-buttons>
        <ion-title text-center>
            {{'EMAIL_TEMPLATE_CONTACT_EMAIL' | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="sendEmail()">
                {{'SEND' | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>

    <div class="itemRows">
        <ion-label col-2 class="itemLabel">{{'TO' | translate}}</ion-label>
        <!--ion-input #searchInput (keypress)="addNewEmail($event)" (click)="presentContactsPopover($event)" (ionInput)="getItems($event.detail,$event)" placeholder="Tap here to add contact"></ion-input-->
        <ion-searchbar col-md-9 col-sm-8  (ionBlur)="closePopover()" [(ngModel)]="searchModel"
            debounce="250" (ionInput)="getItems($event.detail)" (ionClear)="onCancel($event)" placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}"></ion-searchbar>
        <button col-md-1 col-sm-2 pull-right margin-vertical icon-only fill="clear" color="default" (click)="openContacts()" class="addContactBtn">
            <ion-icon name="add"></ion-icon>
        </button>
        <br/>
    </div>
    <div class="itemRows" *ngIf="emailtemplateService.emailContacts.length>0">
        <div class="chipContainer" col-10 push-2>
            <ion-chip [attr.id]="'chip'+i" color="primary" *ngFor="let selContact of emailtemplateService.emailContacts; let i=index">
                <ion-label>{{selContact.fullName}}</ion-label>
                <ion-button fill="clear" color="light" (click)="deleteContact('chip'+i,selContact)">
                    <ion-icon name="close-circle"></ion-icon>
                </ion-button>
            </ion-chip>
        </div>
    </div>
    <div class="itemRows">
        <ion-label col-2 class="itemLabel">{{'SUBJECT' | translate}}</ion-label>
        <ion-input col-9 placeholder="{{'SUBJECT' | translate}}" [value]="emailTemplateSubject"></ion-input>
    </div>
    <div class="itemRows">
        <ion-label col-2 class="itemLabel">{{'TEMPLATE' | translate}}</ion-label>
        <ion-select col-4 [disabled]="!selectedTemplate" [(ngModel)]="selectedTemplate" interface="popover" (ionChange)="updateEmailTemplate()">
            <ion-select-option *ngFor="let emailTemp of emailtemplateService.RemoteURLEmailTemplates" [value]="emailTemp.ID">{{emailTemp.name}}</ion-select-option>
        </ion-select>
    </div>
    <!-- <div class="itemRows"> -->
        <div *ngIf="emailTemplateBody?.length>0" [innerHTML]="emailTemplateBody" class="emailTempContainer">

        </div>
    <!-- </div> -->

    <div #contactpopup class="contact-popover">
        <ion-content>
            <ion-list>
                <ion-item *ngFor="let contact of contacts" (click)="selectContact(contact)">
                    <h2>{{contact.firstName}} {{contact.lastName}}</h2>
                    <p>{{contact.getPrimaryEmail?.emailAddress}}</p>
                </ion-item>
            </ion-list>
            <!-- <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold='100px'>
                <ion-infinite-scroll-content loadingSpinner="bubbles">
                </ion-infinite-scroll-content>
            </ion-infinite-scroll> -->
        </ion-content>
    </div>
</ion-content>
