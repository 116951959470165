import { TransferType } from './../../../enums/allocation/allocation.enum';
import { AllocationFeatureService } from './../../../services/sample/allocation.feature.service';
import { IAllocationAdjustment } from './../../../interfaces/allocation/allocation-adjustment.interface';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { AllocationShipmentService } from '../../../services/sample/allocation-shipment.service';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '../../../services/ui/ui.service';
import { AllocationAdjustmentFilter } from '../../../pipes/allocation-shipments-filter.pipe';
import { EventsService } from '@omni/services/events/events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AllocationAdjustService } from '../../../services/sample/allocation-adjust.service';
import { PopoverController } from '@ionic/angular';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { OrderByPipe } from '@omni/pipes/orderby.pipe';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AdjustmentStatus } from '../../../enums/allocation/allocation.enum';
import { PageName } from '../../../services/navigation/navigation.service';

const MIN_SEARCH_LENGTH = 3;

@Component({
  selector: 'team-adjustments-list',
  templateUrl: './adjustments-list.component.html',
  styleUrls: ['./adjustments-list.component.scss'],
})
export class AdjustmentsListComponent implements OnInit {

  @Input() searchEvent: Observable<string>;
  public allAdjustments: IAllocationAdjustment[];
  public filteredAdjustments: IAllocationAdjustment[] = [];
  public visibleAdjustments: IAllocationAdjustment[];
  public adjustmentFilter: AllocationAdjustmentFilter;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public multiSelectFilterPopoverData: { text: string; value: string;  items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  noSearchResults: boolean = true;
  private filteredRecordCount = 20;
  listFiltered: boolean = false;
  public isSearchedInput: boolean = false;
  private appliedFilterCount: number = 0;
  filterText: string = '';
  adjustmentStatus = AdjustmentStatus;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  isAdjustmentDataReady: boolean = false;

  constructor(
    public readonly shipmentService: AllocationShipmentService,
    private readonly translate: TranslateService,
    private readonly uiService: UIService,
    private events: EventsService,
    private readonly authService: AuthenticationService,
    private readonly adjustmentService: AllocationAdjustService,
    public readonly allocFeatureService: AllocationFeatureService,
    private popover: PopoverController,
    private datePipe: DatePipe,
  ) {
    this.adjustmentFilter = new AllocationAdjustmentFilter(this.shipmentService, this.adjustmentService);
  }

  ngOnInit() {
    this.allocFeatureService.isTeamAdjustmentDataReadyObs
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((isReady) => {
        if (isReady) {
          // Data ready
          this.allAdjustments = this.allocFeatureService.teamAdjustments;
          this.prepareFilterData();
          this.allAdjustments = this.allAdjustments.filter(sample => sample.indskr_adjustedstatus === AdjustmentStatus.InReview || sample.indskr_adjustedstatus === AdjustmentStatus.Approved && sample.ownerId !== this.authService.user.systemUserID);

          this.filteredAdjustments = this.adjustmentFilter.transform(this.allAdjustments);
          this.filteredRecordCount = 20;
          this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);

          this.events.subscribe('adjustmentUpdated', () => {
            this.updateAdjustmentList();
          });

          this.subscriptions.push(this.adjustmentService.showAllAdjustmentsFilter$
            .subscribe(value => {
              this.filteredRecordCount = 20;
              this.filter();
            })
          );

          this.isAdjustmentDataReady = true;
        } else {
          this.isAdjustmentDataReady = false;
          if (Array.isArray(this.subscriptions) && this.subscriptions.length > 0) {
            this.subscriptions.forEach( subscription => {
              subscription.unsubscribe();
            });
            this.events.unsubscribe('adjustmentUpdated');
          }
        }
      }
    );
  }

  private updateAdjustmentList() {
    let idx = this.filteredAdjustments.findIndex((adjustment) => this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id === adjustment.indskr_usershipmentallocation_v2id);

    if (this.allocFeatureService.selectedShipment.indskr_adjustedstatus === AdjustmentStatus.Open) {

      const splicedAdjustments = this.filteredAdjustments.splice(idx, 1);
      this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);

      // Remove from All adjustments
      this.allocFeatureService.removeFromTeamAdjustmentsArray(splicedAdjustments[0]);
      this.allAdjustments = this.allocFeatureService.teamAdjustments;
      // idx = this.allAdjustments.findIndex((adjustment) => this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id === adjustment.indskr_usershipmentallocation_v2id);

      // if (idx > -1) {
      //   this.allAdjustments.splice(idx, 1);
      // }

      // this.adjustmentService.removeAdjustmentFromTeamAdjustments(splicedAdjustments[0]);
      // this.filter();

    } else {
      if (this.allocFeatureService.selectedShipment?.indskr_transfertype === TransferType.AllocationAdjustment) {

        if (idx >= 0) {
          this.filteredAdjustments[idx] = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
          this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);
        }

        this.allocFeatureService.addToOrReplaceInTeamAdjustmentsArray(this.allocFeatureService.selectedShipment as IAllocationAdjustment);
        // Remove from All adjustments
        // idx = this.allAdjustments.findIndex((adjustment) => this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id === adjustment.indskr_usershipmentallocation_v2id);

        // if (idx > -1) {
        //   this.allAdjustments[idx] = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
        // }

        //  idx = this.allocFeatureService.teamAdjustments.findIndex((adjustment) => this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id === adjustment.indskr_usershipmentallocation_v2id);

        // if (idx > -1) {
        //   this.allocFeatureService.teamAdjustments[idx] = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
        // }
      }
    }
  }

  filter() {

    this.filteredAdjustments = this.adjustmentFilter.transform(this.allAdjustments);
    let orderBy = new OrderByPipe();
    this.filteredAdjustments = orderBy.transform(this.filteredAdjustments, 'indskr_adjustmentdate', false);
    this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);

  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subscriptions.forEach( subscription => {
      subscription.unsubscribe();
    });
    this.events.unsubscribe('adjustmentUpdated');
    this.adjustmentService.clearFilters();
  }

  prepareFilterData() {

    let items = [{ text: this.translate.instant('APPROVED'), value: AdjustmentStatus.Approved }, { text: this.translate.instant('FOR_REVIEW'), value: AdjustmentStatus.InReview }];

    const itemsForProductFilter = Array.from(this.allocFeatureService.teamAdjustmentsSkuFilterOptionsMap.values());
    itemsForProductFilter.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1);

    this.multiSelectFilterPopoverData = [
      {
        text: '',
        value: '',
        items: [
          { text: this.translate.instant('AL_ALL_ADJUSTMENTS'), value: '0' }
        ],
        handler: (selectedItem, item, itemRef) => {
          if (itemRef && itemRef.parent && itemRef.parent.items ) {

            itemRef.parent.items.map((o) => {
              o.value = '';
            });
          }
          item.value = selectedItem.value;
           this.adjustmentService.clearFilters();
          this.listFiltered = false;
          this.setFilterText();
          this.updateEmptyMessage();
          this.adjustmentService.showAllAdjustmentsFilter$.next(true);
          // this.filteredAdjustments = this.adjustmentFilter.transform(this.allAdjustments);
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: '',
        items: itemsForProductFilter,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.adjustmentService.adjustmentSKUFilter = item.value;
          if (item.value == '' && !this.adjustmentService.adjustmentStatusFilter
            ) {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            this.setFilterText();
            this.filteredRecordCount = 20;
            this.adjustmentService.showAllAdjustmentsFilter$.next(true);
            this.filter();
            //
            return;
          }
          this.setFilterText();
          this.listFiltered = true;
          this.filteredRecordCount = 20;
         this.adjustmentService.showAllAdjustmentsFilter$.next(false);
          this.filter();

          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: items,
        multiselect: false,
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value && item.text.lowercased() === selectedItem.text.lowercased() ? '' : selectedItem.value;
          this.adjustmentService.adjustmentStatusFilter = item.value;
          if (item.value == '') {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            this.setFilterText();
            this.adjustmentService.showAllAdjustmentsFilter$.next(true);
            return;
          }
          this.setFilterText();
          this.listFiltered = true;
            this.adjustmentService.showAllAdjustmentsFilter$.next(false);
          this.updateEmptyMessage();
        }
      }
    ];

    if (this.searchEvent) {
      this.subscriptions.push(this.searchEvent.subscribe(searchTxt => this.searchInput(searchTxt)));
    }
    // this.updateEmptyMessage();
  }


  onStatusChnage(selectedItem, selected: boolean = false, itemRef = null) {
    if (selectedItem && selectedItem.value) {
      if (selected) {
        this.adjustmentService.multiProductSelectionFilter.push(selectedItem.value);
        this.adjustmentService.selectedFilterList.push(selectedItem);
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          itemRef.item.selectedValues.push(selectedItem.value);
        }
      } else {
        let index = this.adjustmentService.multiProductSelectionFilter.findIndex(o => o === selectedItem.value);
        if (index > -1) {
          this.adjustmentService.multiProductSelectionFilter.splice(index, 1);

          const i = this.adjustmentService.selectedFilterList.findIndex(item => item.value === selectedItem.value);
          if (i >= 0) {
            this.adjustmentService.selectedFilterList.splice(i, 1);
          }
        }
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
          if (idx >= 0) {
            itemRef.item.selectedValues.splice(idx, 1);
          }
        }
      }
    } else {
      this.adjustmentService.multiProductSelectionFilter = [];
      this.adjustmentService.selectedFilterList = [];
    }
    this.setFilterText();
    this.listFiltered = this.adjustmentService.multiProductSelectionFilter.length > 0;
    if (!this.listFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]) {
      itemRef.parent._value = itemRef.parent.items[0]._value = '0';
      this.multiSelectFilterPopoverData[0].value = '0';
    }
    this.filteredAdjustments = this.adjustmentFilter.transform(this.allAdjustments);
    this.filteredRecordCount = 20;
    this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);
    this.scrollToTop();
  }

  scrollToTop() {
    let scrollIntoViewOptions: object = {
      behaviour: 'smooth'
    };
    //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it
    //let ele = document.querySelector(currentDate);
    let ele = document.getElementById('listScroll');
    if (ele) {
      ele.scrollIntoView(scrollIntoViewOptions);
    }
  }

  doInfinite(event) {
    this.filteredRecordCount = this.visibleAdjustments.length;
    this.visibleAdjustments.push(...this.filteredAdjustments.slice(this.filteredRecordCount, this.filteredRecordCount + 20));
    event.target.complete();
  }

  public getViewDataModel(adjustment: IAllocationAdjustment) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: adjustment.indskr_usershipmentallocation_v2id,
      fixedHeight: true,
      primaryTextLeft: adjustment.at_indskr_skuname || adjustment.indskr_skuname,
      primaryTextRight: adjustment.indskr_shipmentnumber ? this.translate.instant('AL_ADJUSTMENT_NUMBER') + ' ' + adjustment.indskr_shipmentnumber : this.translate.instant('AL_ADJUSTMENT_NUMBER'),
      secondaryTextRight: this.getFormattedDate(adjustment.indskr_adjustmentdate),
      showArrow: false,
      showEndIcon: this.allocFeatureService.selectedShipment.indskr_adjustedstatus === AdjustmentStatus.Approved,
      isSelected: this.allocFeatureService.selectedShipment && this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id ? (adjustment.indskr_usershipmentallocation_v2id === this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id ? true : false) : false,
      clickHandler: (id: string, event, specificTarget) => {

      }
    };
    return viewData;
  }

  public getAdjustedDate(adjustment: IAllocationAdjustment) {
    if (adjustment.indskr_transfertype === TransferType.AllocationAdjustment) {
      return this.getFormattedDate(adjustment.indskr_adjustmentdate);
    }
  }

  public filterData(event) {
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.multiSelectFilterPopoverData }, event: event })
      .then((data) => {
        data.present();
      });
    this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);
    this.adjustmentService.teamAdjustmentSearchString = '';
  }

  public handleAdjustmentSelection(event: IAllocationAdjustment) {
    let foundAdjustment: IAllocationAdjustment = this.filteredAdjustments.find((adjustment) => event.indskr_usershipmentallocation_v2id === adjustment.indskr_usershipmentallocation_v2id);
    if (!this.allocFeatureService.selectedShipment || foundAdjustment.indskr_usershipmentallocation_v2id !== this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id) {
      this.openAllocationAdjustment(foundAdjustment);
    }
  }

  getFormattedDate(value: string) {
    const date = new Date(value);
    return `${this.datePipe.transform(date, 'MMM', undefined, this.translate.currentLang)}  ${date.getDate()}`;
  }


  openAllocationAdjustment(adjustment: IAllocationAdjustment) {

    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'AllocationShipment';
    this.allocFeatureService.selectedShipment = adjustment;

    this.adjustmentService.destroyForm();
    this.shipmentService.newShipmentSelectedFromList$.next(PageName.AllocationComponent);
    // this.adjustmentDataService.getAdjustmentDetail(adjustment).then(() => {
    //   this.shipmentService.selectedShipment = adjustment;
    // });
  }

  public setFilterText() {
    let str: string = '';
    this.appliedFilterCount = 0;

    if (!this.allocFeatureService.teamAdjustmentsSkuFilterOptionsMap) return;
    if (this.adjustmentService.adjustmentSKUFilter) {
      str += this.adjustmentService.adjustmentSKUFilter.skuName;
      this.appliedFilterCount++;
    }

    if (this.adjustmentService.adjustmentStatusFilter) {

      if (this.adjustmentService.adjustmentStatusFilter === AdjustmentStatus.InReview) {
        str += str.length > 0 ? this.translate.instant('AL_SHIPMENT_FILTERLIST_FOR_REVIEW') : this.translate.instant('FOR_REVIEW');
        this.appliedFilterCount++;
      }

      if (this.adjustmentService.adjustmentStatusFilter === AdjustmentStatus.Approved) {
        str += str.length > 0 ? this.translate.instant('AL_SHIPMENT_FILTERLIST_APPROVED') : this.translate.instant('Approved');
        this.appliedFilterCount++;
      }
    }
    this.filterText = str;
  }

  searchInput(val: string) {
    if (val.length < MIN_SEARCH_LENGTH) {
      this.isSearchedInput = false;
    }
    if (val.length >= MIN_SEARCH_LENGTH) {
      this.isSearchedInput = true;

      this.filteredAdjustments = this.adjustmentFilter.search(this.filteredAdjustments, val);

    } else if (this.filteredAdjustments.length != this.allAdjustments.length) {
      this.filteredAdjustments = this.adjustmentFilter.transform(this.allAdjustments);
    }
    this.filteredRecordCount = 20;
    this.visibleAdjustments = this.filteredAdjustments.slice(0, this.filteredRecordCount);
    this.noSearchResults = !this.filteredAdjustments.length && !val.length;
   this.updateEmptyMessage();
  }

  updateEmptyMessage() {
    this.events.publish('updateAllocationsRHSEmptyMessage', this.filteredAdjustments.length > 0 ? true : false)
  }

  getAppliedFilterCount() {
    return this.filterText ? this.appliedFilterCount : 0;
  }
}
