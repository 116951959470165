<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="back-btn" icon-only (click)="goBack()">
        <ion-icon name="arrow-back"></ion-icon>{{'SETTINGS' | translate}}
      </ion-button>
    </ion-buttons>
    <ion-title text-center>{{'VIDEO_SETTINGS' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>{{'CAMERA_RESOLUTION' | translate}}</ion-label>
      <ion-select [(ngModel)]="camResolution" interface="popover">
        <ion-select-option value="high">{{'HIGH' | translate}} (1280x720)</ion-select-option>
        <ion-select-option value="medium">{{'MEDIUM' | translate}} (640x480)</ion-select-option>
        <ion-select-option value="low">{{'LOW' | translate}} (352x288)</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{'CAMERA_FRAME_RATE' | translate}}</ion-label>
      <ion-select [(ngModel)]="camFramrate"  interface="popover">
        <ion-select-option value="1fps">1 {{'FPS' | translate}}</ion-select-option>
        <ion-select-option value="7fps">7 {{'FPS' | translate}}</ion-select-option>
        <ion-select-option value="15fps">15 {{'FPS' | translate}}</ion-select-option>
        <ion-select-option value="30fps">30 {{'FPS' | translate}}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>{{'AUDIO' | translate}}</ion-label>
      <ion-toggle [(ngModel)]="audioOnOff"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>{{'VIDEO' | translate}}</ion-label>
      <ion-toggle [(ngModel)]="videoOnOff"></ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>
