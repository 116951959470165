import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { Component, OnInit, Input } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@omni/services/events/events.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';

@Component({
  selector: 'ind-hierarchy-list-item',
  templateUrl: 'ind-hierarchy-list-item.html',
  styleUrls: ['ind-hierarchy-list-item.scss'],
})
export class IndHierarchyListItemComponent {

  @Input() viewData: MainCardViewDataModel[];

  constructor(
    public navService: NavigationService,
    public translate: TranslateService,
    public navControl: IonNav,
    public footerService: FooterService,
    public events: EventsService
  ) {

  }

  ngOnInit() {
    this.viewData.forEach(item => {
      if (item.childItems && item.childItems.length) {
        item.noLeftMarginLabel = true;
        item.showIcon = true;
        if (!item.isNotAutoExpanded) {
          item.iconName = 'chevron-down-outline'
          item.isExpanded = true;
        }
      }
      if (item.childItems?.length) item.childItems.forEach(childItem => {
        childItem.isMainToolTemplate = true;
        childItem.noSeparationLine = false;
        if (!childItem.clickHandler)
          childItem.clickHandler = (id: string, event, target, data) => this._handleSelectionEvents(id, event, target, data)
      })
    })
  }

  public async _handleSelectionEvents(id, event, target, data: MainCardViewDataModel) {
    if (target && target == 'startIcon') {
      data.isExpanded = !data.isExpanded;
      data.iconName = data.isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'
    }
    else if (target && target == 'endIcon') {
      console.log(id, event, target, data)
      let parentItem = this.viewData.find(o => o.id == data.parentID);
      if (parentItem && parentItem.clickHandler) parentItem.clickHandler(id, event, target, data);
    }
  }
}
