export class prioritize {
    public value: string
    public isSelected: boolean;
    public id: string;
    public stateCode: number;
    public description: string;
    public statuscode: number;
    public parameterValue:string;
    constructor(payload: priorityPayload) {
        this.value = payload.indskr_name;
        this.id = payload.indskr_schedulerpriorityid;
        this.stateCode = payload.statecode;
        this.description = payload.indskr_description;
        this.statuscode = payload.statuscode;
        this.parameterValue = payload.indskr_parametervalue;
    }

}
export interface priorityPayload {
    '@odata.etag': string;
    indskr_parametervalue: string;
    indskr_description: string;
    statecode: number;
    statuscode: number;
    indskr_schedulerpriorityid: string;
    _createdby_value: string;
    _ownerid_value: string;
    _modifiedby_value: string;
    createdon: string;
    modifiedon: string;
    indskr_name: string;
}