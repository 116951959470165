export enum FilterConditionOperators {
  like = 'like',
  between = 'between',
  notLike = 'not-like',
  eq = 'eq',
  notEqualTo = 'neq',
  equalsCurrentUser = 'eq-userid',
  ge = 'ge',
  gt = 'gt',
  in = 'in',
  le = 'le',
  lt = 'lt',
  notBetween = 'not-between',
  notEquals = 'ne',
  notEqualCurrentUser = 'ne-userid',
  notIn = 'not-in',
  notNull = 'not-null',
  'null' = 'null',
  on = 'on',
  onOrAfter = 'on-or-after',
  onOrBefore = 'on-or-before',
  today = 'today',
  tomorrow = 'tomorrow',
  yesterday = 'yesterday',
}
