import { Component, Input } from '@angular/core';
import { PersonalizeQuickGlanceViewDataModel } from '@omni/models/personalizeQuickGlanceViewModel';
import { NavigationService } from '@omni/services/navigation/navigation.service';

@Component({
  selector: 'personalize-quick-glance',
  templateUrl: 'personalize-quick-glance.html',
  styleUrls:['personalize-quick-glance.scss']
})
export class PersonalizeQuickGlanceComponent {

  @Input() viewData: PersonalizeQuickGlanceViewDataModel;

  constructor(
    private readonly navService:NavigationService,
  ) {}

  public ngOnInit() {
  }

  public onPageTitleControlClick(button){
    if(button == 'save' && this.viewData.callbackEvent) {
      this.viewData.callbackEvent('save',button,this.viewData.data);
      this.navService.popChildNavPageWithPageTracking();
    }else if(button == 'close') {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  public onCheckboxClick(item){
    if(item){
      item.isChecked = !item.isChecked;
    }
    try {
      if(this.viewData.data.filter(a=> a.isChecked).length != 4){
        this.viewData.pageTitle.controls.find(a=> a.id == 'save').isDisabled = true;
      }else {
        this.viewData.pageTitle.controls.find(a=> a.id == 'save').isDisabled = false;
      }
    } catch (error) {}
    
  }

}
