import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ListPreviewComponent, PopoListData } from '../list-preview/list-preview.component';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { PresentationService } from '@omni/services/presentation/presentation.service';
import { PresentationPreviewComponent } from '../presentation/presentation-preview/presentation-preview';
import { PresentationView } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ResourceService } from '@omni/services/resource/resource.service';
import { ResourceDetailsComponent } from '../resource/resource-details/resource-details';

export type DisplayValue = {
  label: string;
  labelIcon?: string;
  openDetailIcon?:string;
  detailIconType?:string;
  detailObjectId?:string;
  labelIconColor?: string;
  value: string;
  valuePopupText?: string;
  placeholderText?: string;
  popupListData?: PopoListData[];
  showLines?:boolean;
}

@Component({
  selector: 'display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
})
export class DisplayValueComponent implements OnInit {
  @Input() inputData : DisplayValue;
  @Output() clicked = new EventEmitter();

  showPopover = false;

  constructor(
    public popoverController : PopoverController,
    private readonly navService: NavigationService,
    private readonly presentationService: PresentationService,
    private readonly deviceService: DeviceService,
    private readonly translate: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly resourceService: ResourceService,
  ) { }

  ngOnInit() {
    this.showPopover = this.inputData.valuePopupText && this.inputData.popupListData && this.inputData.popupListData.length > 0;
  }

  async openPopup(ev){
    // this.clicked.emit(ev);
    if(!this.inputData.popupListData || this.inputData.popupListData.length === 0) return;
    const popover = await this.popoverController.create({
      component: ListPreviewComponent,
      cssClass: 'list-preview-popover',
      event: ev,
      translucent: true,
      componentProps: { popupListData : this.inputData.popupListData }
    });
    await popover.present();

    popover.onDidDismiss().then((data:any) => {
      if(data && data.data && data.data.contentType && data.data.contentId){
        this._openContentDetails(data.data.contentType,data.data.contentId)
      }
    });
  }

  public openInputDetails(inputData:DisplayValue){
    if(inputData && inputData.detailIconType && inputData.detailObjectId){
      this._openContentDetails(inputData.detailIconType,inputData.detailObjectId)
    }
  }

  private _openContentDetails(contentType,contentId){
    if(contentType && contentId){
      if(contentType == 'ActivityPresentation'){
        const pres = this.presentationService.getPresentationById(contentId);
        if((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !pres.downloaded) {
          this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "Presentation List", "top", ToastStyle.DANGER, 2000, true);
          return;
        }
        if(pres){
          this.presentationService.setCarouselBriefcase([pres]);
          this.presentationService.setCurrentSelectedPres(pres);
          let page = this.presentationService.presPages && this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0] : null ;
          if(pres.customePageLocation){
            if(isNaN(parseInt(pres.customePageLocation))){
              page = this.presentationService.presPages.find(o=> o.name == pres.customePageLocation)
            }
            else{
                page = this.presentationService.presPages.find(o=> o.name == 'Slide'+pres.customePageLocation)
            }
          }
          this.presentationService.setCurrentSelectedPresPage(page);
          this.navService.pushChildNavPageWithPageTracking(PresentationPreviewComponent, PageName.PresentationPreviewComponent,this.navService.getCurrentMasterPageName(), { from: 'DisplayValueComponent', viewMode: PresentationView.MENU });
        }
      }else if(contentType == 'ActivityResource'){
        const resource = this.resourceService.getResourceById(contentId,contentId);
        if(resource){
          if((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !resource.downloaded) {
            this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "Presentation List", "top", ToastStyle.DANGER, 2000, true);
            return;
          }
          this.navService.pushChildNavPageWithPageTracking(ResourceDetailsComponent, PageName.ResourceDetailsComponent, this.navService.getCurrentMasterPageName(), {  from: 'DisplayValueComponent' });
          this.resourceService.highlightResource(resource);
        }
      }
    }
  }

}
