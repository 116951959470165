<!-- Header Section consist of cancel button and header section -->
<ion-header>
    <ind-page-title [viewData]="schedulerDetailsPageTitle" (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
</ion-header>

<!-- Consist of the edit form and locators for popup targets -->
<ion-content>
    <ng-container>
        <ion-list *ngIf="selectedSchedule">
            <ind-section-header [viewData]='routeDatesHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
            <ion-row>
              <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
              <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
            </ion-row>
            <!-- Scheduling period -->
            <ind-form-field [viewData]="getSchedulingPeriodFormField()"></ind-form-field>
            <!-- Details -->
            <ind-section-header [viewData]='routPlanDetailsHeader' (onControlClick)='onSectionHeaderControlClick($event)'>
            </ind-section-header>
            <ind-form-field [viewData]="getDescriptionFormField()"></ind-form-field>
            <!-- City -->
            <ind-form-field [viewData]="getCityFormField()"></ind-form-field>
            <!-- ZIP/Postal Code -->
            <ind-form-field [viewData]="getPostalCodeFormField()"></ind-form-field>
            <!-- Customer Segment -->
            <ind-form-field [viewData]="getCustomerSegmentFormField()"></ind-form-field>
            <!-- Account -->
            <ind-form-field [viewData]="getAccountFormField()"></ind-form-field>
            <!-- Call Plan -->
            <ind-form-field [viewData]="getCallplanFormField()"></ind-form-field>
            <!-- Product -->
            <ind-form-field [viewData]="getProductFormField()"></ind-form-field>
            <!-- Prioritize -->
            <ind-form-field [viewData]="getPriortizeFormField()"></ind-form-field>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'SchedulingDetails'"></footer-toolbar>
<!-- </ion-footer> -->