import { AuthenticationService } from './../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './../../services/notification/notification.service';
import { DeviceService } from './../../services/device/device.service';
import { SearchConfigService } from './../../services/search/search-config.service';
import { ContactOfflineService } from './../../services/contact/contact.service';
import { DiskService } from './../../services/disk/disk.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { searchIndexDataModel, SelectedSuggestionPillDataModel, UserSavedSearchTypes, UserSearchOfflineDTO, UserSearchServiceDTO } from "./../../models/search-config-data-model";
import { Endpoints } from "../../../config/endpoints.config";
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from '../../config/pouch-db.config';
import { Subject } from 'rxjs';
import { LogService } from '../../services/logging/log-service';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SearchConfigDataService {
  public contactsSearchIndexesConfig: searchIndexDataModel[]
  public isAllocationsMappedToContacts: boolean = false;
  public isConsentsMappedToContacts: boolean = false;
  public sharedSearchColorIndicator = new Subject<any>();
  public filterMenuFilterClear = new Subject<any>();
  filterMenuFilterClear$ = this.filterMenuFilterClear.asObservable();
  
  public childUsersWithPositions = [];

  constructor(
    private http: HttpClient,
    private disk: DiskService,
    public contactOfflineService: ContactOfflineService,
    public searchConfigService: SearchConfigService,
    public device: DeviceService,
    public toast: NotificationService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private log: LogService
  ){

  }

  async getAllSavedSearches(loadFromDbOnly = false){
    if(!this.searchConfigService.isConfigInitiated){
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    await this.disk.retrieve(DB_KEY_PREFIXES.USER_SAVED_SEARCHES).then(doc=>{
      if(doc && doc.raw){
        this.searchConfigService.savedSearches = doc.raw
      }
      else{
        this.searchConfigService.savedSearches = [];
      }
    })

    // Track oflfine data count
    this.searchConfigService.trackOfflineSavedSearchDataCount();

    if (!(this.device.isOffline || loadFromDbOnly)) {
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.advanceSearch.GET_USER_SAVED_SEARCHES.replace('?lastUpdatedTime={lastUpdatedTime}', '');
      await this.http.get(url).subscribe((response)=>{       
        this.searchConfigService.mapUserSavedSearches(response);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES,doc=>{
          if(!doc || !doc.raw){
            doc = {
              raw:[]
            }
          }
          doc.raw = this.searchConfigService.savedSearches
          return doc;
        })
        //This method is  for the nofication for sharedsearches
         this.getSavedSearchFornotification();
      },(error)=>{
        this.log.logError('error is getting saved/shared searches',error);
      })
    }
  }

  /**
  * @description This method gets the savedSearches (includes both saved and shared searches)
  *  for user notification. later calls sharedSearchNotification method of searchConfigService class to show the notification
  * @param
  * @returns
  */
  private async getSavedSearchFornotification() {
    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_ADVANCED_SHARED_NOTIFICATION
    );
    let lastUpdatedTime = (syncState && syncState.lastUpdatedTime) ? syncState.lastUpdatedTime : '';
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.advanceSearch.GET_USER_SAVED_SEARCHES.replace('{lastUpdatedTime}', lastUpdatedTime);
    this.http.get(url).subscribe((response: any) => {
      if (response) {
        let newSearches = response.filter(search => Number(search['modifiedon']) > Number(lastUpdatedTime));
        this.searchConfigService.sharedSearchNotification(newSearches);
        syncState.lastUpdatedTime = new Date().getTime();
        this.disk.updateSyncState(syncState);
      }
    }, (error) => {
      this.log.logError('error is getting shared searches for notification',error);
    })
  }

  async saveAdvancedSearch(searchName:string,toolName:string,searchType:UserSavedSearchTypes,searchSelection:SelectedSuggestionPillDataModel[]){
    if(!this.searchConfigService.isConfigInitiated){
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    let now = new Date();
    let url = this.authService.userConfig.activeInstance.entryPointUrl +  Endpoints.advanceSearch.CREATE_SEARCH;
    let params:UserSearchServiceDTO ={
      indskr_name : searchName,
      indskr_usersearchtype : searchType,
      indskr_searchtool: toolName,
      //createdon:now.getTime(),
      indskr_externalid : "offline_saveSearch_"+now.getTime(),
      usersearchparameter: searchSelection.map(search=>{
        return {
          indskr_category: search.categoryDisplayName || search.categoryName,
          indskr_name: search.selectedFacet,
          //createdon: search.createdOn || now.getTime(),
          indskr_datatype: search.type
        }
      })
    }
    if(!this.device.isOffline){
      await this.http.post(url,params).subscribe(async (response)=>{
        if(response){
          params['syncPending'] = false;
          await this.handleSaveSearchResponse(params, searchSelection, response);
        }
      },async (error)=>{
        params['syncPending'] = true;
        await this.handleSaveSearchResponse(params, searchSelection);
      });
    }
    else{
      params['syncPending'] = true;
      await this.handleSaveSearchResponse(params, searchSelection);
    }
  }

  /**
   * @description This method updates the savedSearches by using the params passed
   * @param {string=} id of the search
   * @param {UserSavedSearchTypes=} searchType option param for the search type (owned/shared) from the enum UserSavedSearchTypes
   * @param {string=} searchName option param for the searchName
   * @param {string=} toolName option param for the search tool name
   * @param {SelectedSuggestionPillDataModel[]=} searchSelection option param for the search pill data as an array
   * @returns
  */
  async updateSavedSearch(id:string,searchType?:UserSavedSearchTypes,searchName?:string,toolName?:string,searchSelection?:SelectedSuggestionPillDataModel[]){
    if(!this.searchConfigService.isConfigInitiated){
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    let index = this.searchConfigService.savedSearches.findIndex(savedSearch => savedSearch.searchID == id);
    let offlineCreatedSearch = index == -1 ? true : false;
    let url = this.authService.userConfig.activeInstance.entryPointUrl +  Endpoints.advanceSearch.CREATE_SEARCH;
    let params = this.createParamsForUpdate(id,searchType,searchName,toolName,searchSelection);
    if(!this.device.isOffline && !offlineCreatedSearch){
      await this.http.patch(url,params).subscribe(async (response)=>{
        if(response){
          params['syncPending'] = false;
          this.sharedSearchColorIndicator.next(response);
          await this.handleUpdateSearchResponse(id, params);
        }
      },async (error)=>{
        params['syncPending'] = true;
        await this.handleUpdateSearchResponse(id, params);
      });
    }
    else{
      params['syncPending'] = true;
      await this.handleUpdateSearchResponse(id, params);
    }
  }

  createParamsForUpdate(id:string,searchType?:UserSavedSearchTypes,searchName?:string,toolName?:string,searchSelection?:SelectedSuggestionPillDataModel[]) {
    let params : any = {};
    let now = new Date();
    params.indskr_usersearchesid = id;
    params.indskr_sharedon = now.getTime();
    if(searchType) {
      params.indskr_usersearchtype = searchType;
    }
    if(searchName) {
      params.indskr_name = searchName;
    }
    if(toolName) {
      params.indskr_searchtool = toolName;
    }
    if(searchSelection) {
      params.usersearchparameter = searchSelection.map(search=>{
        return {
          indskr_category: search.categoryName,
          indskr_name: search.selectedFacet,
          indskr_datatype: search.type
        }
      })
    }
    return params;
  }

  async handleUpdateSearchResponse(id: string, params) {
    let index = this.searchConfigService.savedSearches.findIndex(savedSearch => savedSearch.searchID == id || savedSearch.offlineID == id);
    if(index == -1) return;

    this.searchConfigService.savedSearches[index].pendingSync = params['syncPending'];
    this.searchConfigService.savedSearches[index].modifiedon = params['indskr_sharedon'];
    if(params.indskr_usersearchtype) {
      this.searchConfigService.savedSearches[index].searchType = params.indskr_usersearchtype;
    }
    if(params.indskr_name) {
      this.searchConfigService.savedSearches[index].searchName = params.indskr_name;
    }
    if(params.indskr_searchtool) {
      this.searchConfigService.savedSearches[index].searchToolName = params.indskr_searchtool;
    }
    if(params.indskr_sharedon) {
      this.searchConfigService.savedSearches[index].modifiedon = params.indskr_sharedon;
    }
    if(params.usersearchparameter) {
      this.searchConfigService.savedSearches[index].categoryValuePairs = params.searchSelection.map(search=>{
        return {
          indskr_category: search.categoryName,
          indskr_name: search.selectedFacet,
          indskr_datatype: search.type
        }
      })
    }
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES, (doc)=>{
      if(!doc || !doc.raw){
        doc = {raw: []};
      }
      doc.raw = this.searchConfigService.savedSearches;
      return doc;
    })
  }

  async handleSaveSearchResponse(params, searchSelection, response?:any){
    let now = new Date();
    let offlineObject: UserSearchOfflineDTO = {
      searchID: response?response['indskr_usersearchesid']:'',
      searchName: params.indskr_name,
      searchType : params.indskr_usersearchtype,
      searchToolName : params.indskr_searchtool,
      offlineID : params.indskr_externalid,
      categoryValuePairs: searchSelection.slice(),
      createdon:now.getTime(),
      pendingSync:params['syncPending']
    }
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES, (doc)=>{
      if(!doc || !doc.raw){
        doc = {
          raw: []
        }
      }
      doc.raw = [offlineObject, ...doc.raw]
      return doc;
    })
    this.searchConfigService.savedSearches = [offlineObject, ...this.searchConfigService.savedSearches];
    this.searchConfigService.savedSearchesBehaviorSubject.next(this.searchConfigService.savedSearches);
    // Track offline data count
    this.searchConfigService.trackOfflineSavedSearchDataCount();
    this.toast.notify("'"+offlineObject.searchName+"' "+this.translate.instant("SAVED_SUCCESSFULLY"), '');
  }

  async deleteSavedSearch(id){
    if(!this.device.isOffline && id.indexOf('offline_')<0){
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.advanceSearch.DELETE_SEARCH.replace('{SEARCHID}',id)
      await this.http.delete(url).subscribe(()=>{
        let index = this.searchConfigService.savedSearches.findIndex(savedSearch => savedSearch.searchID == id || savedSearch.offlineID == id)
        if(index>-1) this.searchConfigService.savedSearches.splice(index,1);
        this.searchConfigService.savedSearchesBehaviorSubject.next(this.searchConfigService.savedSearches);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES,doc=>{
          if(!doc || !doc.raw){
            doc = {
              raw:[]
            }
          }
          doc.raw = this.searchConfigService.savedSearches
          return doc;
        })
      },(error)=>{
        let index = this.searchConfigService.savedSearches.findIndex(savedSearch => savedSearch.searchID == id || savedSearch.offlineID == id);
        this.searchConfigService.savedSearches[index].isDeleted = true;
        this.searchConfigService.savedSearches[index].pendingSync = true;
        this.searchConfigService.savedSearches = this.searchConfigService.savedSearches.filter(savedSearch=>{
          return !(savedSearch.searchID.indexOf('offline_')>-1 && savedSearch.isDeleted)
        })
        this.searchConfigService.savedSearchesBehaviorSubject.next(this.searchConfigService.savedSearches);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES,doc=>{
          if(!doc || !doc.raw){
            doc = {
              raw:[]
            }
          }
          doc.raw = this.searchConfigService.savedSearches
          return doc;
        })
      })
    }
    else if(id){
      // offline delete case
      let index = this.searchConfigService.savedSearches.findIndex(savedSearch => savedSearch.searchID == id || savedSearch.offlineID == id);
      this.searchConfigService.savedSearches[index].isDeleted = true;
      this.searchConfigService.savedSearches[index].pendingSync = true;
      this.searchConfigService.savedSearches = this.searchConfigService.savedSearches.filter(savedSearch=>{
        return !(savedSearch.searchID.indexOf('offline_')>-1 && savedSearch.isDeleted)
      })
      this.searchConfigService.savedSearchesBehaviorSubject.next(this.searchConfigService.savedSearches);
      this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES,doc=>{
        if(!doc || !doc.raw){
          doc = {
            raw:[]
          }
        }
        doc.raw = this.searchConfigService.savedSearches
        return doc;
      })
    }

    // Track offline data count
    this.searchConfigService.trackOfflineSavedSearchDataCount();
  }

  async getToolNamesForSearch (loadFromDbOnly = false){
    if(!this.searchConfigService.isConfigInitiated){
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    this.disk.retrieve(DB_KEY_PREFIXES.USER_SEARCH_TOOL_NAMES).then(doc=>{
      if(doc && doc.raw){
        this.searchConfigService.toolNames = doc.raw
      }
    })
    if(!(this.device.isOffline || loadFromDbOnly)){
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.advanceSearch.GET_TOOL_NAMES;
      await this.http.get(url).subscribe((response)=>{
        if(response){
          this.searchConfigService.mapToolNameData(response);
        }
        this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SEARCH_TOOL_NAMES, (doc)=>{
          if(!doc || !doc.raw){
            doc = {
              raw:[]
            }
          }
          doc.raw = this.searchConfigService.toolNames
          return doc
        })
      }, (error)=>{

      })
    }
  }

  async uploadOfflineSavedSearchData(){
    if(this.device.isOffline) return
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.advanceSearch.OFFLINE_UPLOAD_USER_SEARCHES;
    this.disk.retrieve(DB_KEY_PREFIXES.USER_SAVED_SEARCHES).then((doc)=>{
      let syncPendingObjects = [];
      if(doc && doc.raw){
        syncPendingObjects = doc.raw.filter(o=>o.pendingSync);
      }
      let serviceObject = [];
      syncPendingObjects.forEach(savedSearch=>{
        let serviceObjectModel;
        if(savedSearch.isDeleted && savedSearch.searchID.indexOf('offline_')<0){
          serviceObjectModel= {
            "indskr_usersearchesid": savedSearch.searchID,
		        "deleted" : true
          }
        }
        else if(savedSearch.isDeleted){
          let index = this.searchConfigService.savedSearches.findIndex(o => o.searchID == savedSearch.searchID);
          this.searchConfigService.savedSearches.splice(index,1)
        }
        else if(savedSearch.searchID && savedSearch.modifiedon) {
          serviceObjectModel= {
            "indskr_usersearchesid": savedSearch.searchID,
            "indskr_usersearchtype" : savedSearch.searchType,
            "indskr_sharedon" : savedSearch.modifiedon
          }
        }
        else {
          let usersearchparameters = [];
          savedSearch.categoryValuePairs.forEach(catValPair=>{
            usersearchparameters.push({
              indskr_category: catValPair.categoryName,
              indskr_name: catValPair.selectedFacet,
              indskr_datatype: catValPair.type,
              createdon : catValPair.createdOn
            })
          })
          serviceObjectModel ={
            indskr_name : savedSearch.searchName,
            indskr_usersearchtype : savedSearch.searchType,
            indskr_externalid : savedSearch.offlineID,
            indskr_searchtool: savedSearch.searchToolName,
            createdon: savedSearch.createdon,
            usersearchparameter: usersearchparameters,
            indskr_sharedon : savedSearch.modifiedon? savedSearch.modifiedon: ''
          }
        }
        serviceObject.push(serviceObjectModel)
      })
      if(serviceObject && serviceObject.length>0){
        this.http.post(url,serviceObject).subscribe((response:any)=>{
          if(response){
            response.forEach(rawResponse=>{
              if(rawResponse['indskr_usersearchesid'] && rawResponse['indskr_externalid'])  {
                //offline create case
                let idx = this.searchConfigService.savedSearches.findIndex(o=>o.offlineID == rawResponse['indskr_externalid']);
                if(idx>-1){
                  this.searchConfigService.savedSearches[idx].searchID = rawResponse['indskr_usersearchesid'];
                  this.searchConfigService.savedSearches[idx].pendingSync = false;
                }
              }
              else if(rawResponse['indskr_usersearchesid'] && !rawResponse['indskr_externalid'])  {
                let idx = this.searchConfigService.savedSearches.findIndex(o=>o.searchID == rawResponse['indskr_usersearchesid']);
                if(idx>-1){
                  let indexOfSyncPendingObject = syncPendingObjects.findIndex(o=>o.searchID == rawResponse['indskr_usersearchesid']);
                  if(indexOfSyncPendingObject != -1) {
                    if(syncPendingObjects[indexOfSyncPendingObject].searchID && syncPendingObjects[indexOfSyncPendingObject].modifiedon) {
                      //offline update case
                      this.searchConfigService.savedSearches[idx].searchID = rawResponse['indskr_usersearchesid'];
                      this.searchConfigService.savedSearches[idx].pendingSync = false;
                    }
                  }
                  else {
                    //offline delete case
                    this.searchConfigService.savedSearches.splice(idx,1);
                  }
                }
              }
              else{
                let idx = this.searchConfigService.savedSearches.findIndex(o=>o.offlineID == rawResponse['indskr_externalid'] || o.searchID==rawResponse['indskr_usersearchesid']);
                this.searchConfigService.savedSearches[idx].pendingSync = true;
              }
            })
          }
          this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_SAVED_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc = {
                raw: []
              }
            }
            doc.raw = this.searchConfigService.savedSearches;
            return doc
          })

          // Track offline data count
          this.searchConfigService.trackOfflineSavedSearchDataCount();
        })
      }
    })

  }

  /* Check if string is valid UUID */
  public checkIfValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
  }

  public findById(array, id) {
    for (const item of array) {
      if (item.userfullname === id) return item;
      if (item.childUsers?.length) {
        const innerResult = this.findById(item.childUsers, id);
        if (innerResult) return innerResult;
      }
    }
  }

  public updateAttributeById(array, id, depth, attributeName, attributeValue) {
    for (const item of array) {
      if (item.userfullname === id && item.depth == depth) {
        item[attributeName] = attributeValue;
        return item;
      }

      if (item.childUsers?.length) {
        const innerResult = this.updateAttributeById(item.childUsers, id, depth, attributeName, attributeValue);
        if (innerResult && item.depth == depth) {
          item[attributeName] = attributeValue;
          return innerResult;
        }
      }
    }
  }

  public updateElementById(array, id, depth, element) {
    for (const item of array) {
      if (item.userfullname === id && item.depth == depth) {
        item.childUsers = element;
        return item;
      }

      if (item.childUsers?.length) {
        const innerResult = this.updateElementById(item.childUsers, id, depth, element);
        if (innerResult && item.depth == depth) {
          item.childUsers = element;
          return innerResult;
        }
      }
    }
  }

  public unCheckAllChildUsersWithPositions() {
    this.childUsersWithPositions.forEach((child) => {
      return this.shouldUnCheckChild(child);
    });
  }

  public shouldUnCheckChild(item: any): boolean {
    item.isChecked = false;
    for (let i of item.childUsers) {
      if (this.shouldUnCheckChild(i)) {
        return i.isChecked = false;
      }
    }
    // Logic to display the item here based on result
    return item;
  }
}
