import { Component, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { SurgeryOrderContractDetailsComponent } from '../surgery-order-contract-details/surgery-order-contract-details.component';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';

@Component({
  selector: 'app-surgery-order-contract',
  templateUrl: './surgery-order-contract.component.html',
  styleUrls: ['./surgery-order-contract.component.scss'],
})
export class SurgeryOrderContractComponent implements OnInit {
  @ViewChild('procedurecontractpane', { static: true }) public navCtrl: IonNav;
  
  constructor(
    public navService: NavigationService,
    public device: DeviceService,
    private footerService: FooterService,
    public contractService : ProcedureContractService
  ) { }

  ngOnInit() {
    this.navService.initRightPaneChildNav(
      this.navCtrl, ChildNavNames.SurgeryContractNavigation, 
      PageName.SurgeryOrderContractComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.footerService.initButtons('');
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  ngOnDestroy() {
    this.contractService.isRightPaneNavActive = false;
    this.navService.popChildNavCtrlFromStack(ChildNavNames.SurgeryContractNavigation).then(() => {
      // this.events.publish("tools-back-clicked", PageName.MarketingPlanManagementPageComponent);
    });
  }

  public openContractDetails(event): void {
    if (event?.procedureContract) {
        this.contractService.isRightPaneNavActive = true;
      this.navService.setChildNavRoot(SurgeryOrderContractDetailsComponent,
        PageName.SurgeryOrderContractDetailsComponent,
        PageName.SurgeryOrderContractComponent, {
        procedureContract: event.procedureContract,
        viewMode: event.viewMode
      });
    }
  }

}
