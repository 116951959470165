import { Component} from '@angular/core';
import {NavigationService} from '../../services/navigation/navigation.service';
import {UIService} from "../../services/ui/ui.service";
import { DeviceService } from '../../services/device/device.service';

/**
 * Generated class for the CaseManagementHomePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'date-formats-page[base-page]',
  templateUrl: 'date-formats-page.html',
  styleUrls:['date-formats-page.scss'],
})
export class DateFormatsPage {

  text: string;

  constructor (
    public navService: NavigationService,
    private uiService: UIService,
    public device: DeviceService
  ) {
  }

}
