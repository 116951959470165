<!-- Generated template for the AppLanguageListComponent component -->
<ion-header>

    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="serachString" (onControlClick)="onHeaderControlClick($event)" (onSearchChange)="onInput($event.detail)">
    </ind-header-left>
</ion-header>
<ion-content [ngClass]="{'vectorimageleft' : !isSearching && filteredLanguages?.length === 0}">
    <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
        <ion-radio-group ngDefaultControl [(ngModel)]="selectedLanguageCode">
            <ind-section-header *ngIf="!isSearching" [viewData]="AllLanguageHeader"></ind-section-header>
            <ind-section-header *ngIf="isSearching" [viewData]='resultHeader'></ind-section-header>

            <ind-no-search-result *ngIf="filteredLanguages.length === 0 && isSearching" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>

            <ion-item *ngFor="let language of filteredLanguages" (click)="changeLanguage(language)" class="alignTextwithDivider">
                <ion-label>
                    <h3>
                        {{language.name}}
                    </h3>
                    <h4>
                        {{language.subName}}
                    </h4>
                </ion-label>
                <ion-radio slot="end" [value]="language.code"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar [footerView]="'masterView'" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->
