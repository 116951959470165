import { Component, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { UIService } from '@omni/services/ui/ui.service';
@Component({
  selector: 'territory-management-page[base-page]',
  templateUrl: './territory-management-page.component.html',
  styleUrls: ['./territory-management-page.component.scss'],
})
export class TerritoryManagementPageComponent implements OnInit {
  @ViewChild('territoryrightpane', { static: true }) public navCtrl: IonNav;
  constructor(
    private footerService: FooterService,
    public device: DeviceService,
    public navService: NavigationService,
    public uiService: UIService,
    private events: Events
  ) { }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.TerritoryNavigation, PageName.TerritoryManagementPageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.footerService.initButtons('');
    this.uiService.updateNothingSelectedScreenMessageFor(0); //TODO
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.TerritoryNavigation);
    
  }

}
