<!-- <ion-list>
    <ion-item-sliding> -->
<ion-item *ngIf="inputNote" class='item-container'>
    <ion-label class='note-label'>
        <p class="header-label">
            <ion-text class='owner-label'>{{inputNote.ownerName}}</ion-text>
            <ion-text class='date-label'>{{getFormattedDateTime(inputNote.createdTime)}}</ion-text>
        </p>
        <p class="note-text" *ngIf="readMode || readOnlyNote">
            <ion-text>{{inputNote.noteText}}</ion-text>
        </p>
        <textarea #noteInput *ngIf="!readMode && !readOnlyNote"
          class="note-input"
          value="{{inputText}}"
          maxlength="1000"
          (input)="onInput($event)"
          rows="4"
          cols="10"
          debounce="150"
        ></textarea>

        <ng-container *ngIf="isNoteAttachmentEnabled">
            <ion-chip *ngIf="(readMode || readOnlyAttachment) && inputNote.hasDocument" [ngClass]="{'download-disabled': disableDownload}">
                <ion-label>{{inputNote.documentName}}</ion-label>
                <ion-icon *ngIf="readMode || readOnlyAttachment" src="assets/imgs/download-icon.svg" (click)="downloadAttachment()"></ion-icon>
            </ion-chip>

            <ion-chip *ngIf="!readMode && !readOnlyAttachment && isAttachmentAdded">
                <ion-label>{{attachmentTitle}}</ion-label>
                <ion-icon src="assets/imgs/remove-icon.svg" (click)="removeAttachment(true)"></ion-icon>
            </ion-chip>

            <ion-chip *ngIf="!readMode && !readOnlyAttachment && !isAttachmentRemoved && !isAttachmentAdded  && inputNote.hasDocument">
                <ion-label>{{inputNote.documentName}}</ion-label>
                <ion-icon src="assets/imgs/remove-icon.svg" (click)="removeAttachment(true)"></ion-icon>
            </ion-chip>

            <input *ngIf="!readMode" class="hide-input" #attachInputNote type="file" (change)="loadImageFromDevice($event)" id="note-file-input">
        </ng-container>

        <!-- <textarea #noteInput *ngIf="!readMode" class="note-input" maxlength="1000" [(ngModel)]="inputText" rows="4" cols="10"></textarea> -->
        <p class="control-btns">
            <ion-text class="first-button" *ngIf="readMode && editable" (click)="toggleEditMode(true)"> {{'EDIT' | translate}} </ion-text>
            <ion-text class="first-button" *ngIf="!readMode && editable" (click)="toggleEditMode(false)"> {{'CANCEL' | translate}} </ion-text>
            <ion-text class="separator-bar" *ngIf="!readMode && editable && isNoteAttachmentEnabled && !readOnlyAttachment"> | </ion-text>
            <ion-text class="second-button" *ngIf="!readMode && editable && isNoteAttachmentEnabled && !readOnlyAttachment" (click)="handleNoteAttachment()"> {{'ATTACH' | translate}} </ion-text>
            <ion-text class="separator-bar" *ngIf="(readMode && deletable) || (!readMode && editable)"> | </ion-text>
            <ion-text class="second-button" *ngIf="readMode && deletable" (click)="deleteNote()"> {{'DELETE' | translate}} </ion-text>
            <ion-text class="second-button" [ngClass]="{'disabled': isSaveDisabled}" *ngIf="!readMode && editable" (click)="confirmChanges()"> {{'SAVE' | translate}} </ion-text>
        </p>
    </ion-label>
    <!-- <h2>
                <span class="owner-label"> {{inputNote.ownerName}} </span>
                <span class="date-label"> {{getFormattedDateTime(inputNote.createdTime)}} </span>
            </h2> -->
    <!-- <p class="note-text" *ngIf="readMode">
                {{inputNote.noteText}}
            </p> -->
    <!-- <ion-item *ngIf="!readMode" class="note-input">
                <ion-textarea #noteInput maxlength="1000" [(ngModel)]="inputText" rows="4" cols="10"></ion-textarea> -->
    <!-- <ion-input class='note-input' type="text" [(ngModel)]="inputText"></ion-input> -->
    <!-- </ion-item> -->
</ion-item>
<!-- <ion-item-options side="right" *ngIf="readMode && (editable || deletable)">
            <button *ngIf="readMode && editable" ion-button color="info" (click)="toggleEditMode(true)">
            <ion-icon name="indegene-edit-icon"></ion-icon>
            {{'EDIT' | translate}}
            </button>
            <button *ngIf="readMode && deletable" ion-button color="danger" (click)="deleteNote()">
            <ion-icon name="trash-outline"></ion-icon>
            {{'DELETE' | translate}}
            </button>
        </ion-item-options>
    </ion-item-sliding>
</ion-list> -->