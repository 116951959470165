import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.html',
  styleUrls: ['./search-filter.scss'],
})
export class SearchFilterComponent implements OnInit {

  private _filterButtonBadgeCount: number = 0;
  @Input() set filterButtonBadgeCount(value: number) {
    this._filterButtonBadgeCount = value;
    this.filterButtonBadgeCountChange.emit(this._filterButtonBadgeCount);
  }
  get filterButtonBadgeCount(): number {
    return this._filterButtonBadgeCount;
  }

  private _searchDisabled: boolean = false;
  @Input() set searchDisabled(value: boolean) {
    this._searchDisabled = value;
    this.searchDisabledChange.emit(this._searchDisabled);
  }
  get searchDisabled(): boolean {
    return this._searchDisabled;
  }

  private _searchText: string = '';
  @Input() set searchText(value: string) {
    this._searchText = value;
    this.searchTextChange.emit(this._searchText);
  }
  get searchText(): string {
    return this._searchText;
  }

  @Input() searchDebounce: number = 300;
  @Input() filterDisabled;
  @Input() placeholder;


  @Output() filterButtonBadgeCountChange = new EventEmitter<number>();
  @Output() searchDisabledChange = new EventEmitter<boolean>();
  @Output() onSearchChange = new EventEmitter<any>();
  @Output() onSearchFocus = new EventEmitter<any>();
  @Output() onSearchBlur = new EventEmitter<any>();
  @Output() onSearchCancel = new EventEmitter<any>();
  @Output() onSearchClear = new EventEmitter<any>();
  @Output() onSearchInput = new EventEmitter<any>();
  @Output() onSearchBarClick = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<any>();
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() onFilterClick = new EventEmitter<any>();

  shouldShowSearchBarFilterButton: boolean = true;

  constructor() { }

  ngOnInit() { }

  _onFilterClick(ev: any) {
    this.onFilterClick.emit(ev);
  }

  _onSearchChange(ev: any) {
    this.onSearchChange.emit(ev);
  }
  _onSearchFocus(ev: any) {
    this.shouldShowSearchBarFilterButton = false;
    this.onSearchFocus.emit(ev);
  }
  _onSearchBlur(ev: any) {
    this.shouldShowSearchBarFilterButton = true;
    this.onSearchBlur.emit(ev);
  }
  _onSearchCancel(ev: any) {
    this.onSearchCancel.emit(ev);
  }
  _onSearchClear(ev: any) {
    this.onSearchClear.emit(ev);
  }
  _onSearchInput(ev: any) {
    this.onSearchInput.emit(ev);
  }
  _onSearchBarClick(ev: any) {
    this.onSearchBarClick.emit(ev);
  }
  _onSearch(ev: any) {
    this.onSearch.emit(ev);
  }

}
