<ion-header>
  <ind-page-title [viewData]='{id:"call-plan-detail", title: plan?.customerName, controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
  <ion-toolbar>
    <ind-tabs [data]="uiService.tabsData" [selectedTab]="customerCallPlanSegment" (selectedTabChange)="segmentChanged($event)">
    </ind-tabs>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div [ngSwitch]="customerCallPlanSegment">
    <customer-call-plan-overview *ngSwitchCase="'plans'"></customer-call-plan-overview>
    <contact-info *ngSwitchCase="'contact-info'" [scrollObservable]="scrollObservable" [accessedFrom]="accessedFrom">
    </contact-info>
    <configured-account-info *ngSwitchCase="'account-info'" [scrollObservable]="scrollObservable" [refreshAccountNotesObservable]="refreshAccountNotesObservable">
    </configured-account-info>
    <customer-call-plan-timeline *ngSwitchCase="'timeline'"></customer-call-plan-timeline>
  </div>
</ion-content>