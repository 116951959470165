import { Component, Input } from '@angular/core';
import { EventsService } from '../../../services/events/events.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'sync-popover',
    templateUrl: 'sync-popover.html',
  styleUrls:['sync-popover.scss']
})
export class SyncPopoverComponent {

    public loadingText:string = this.translate.instant('SYNC_LOADING_PLZ_WAIT');
    public loadingSubText:string;
    //destroy$: Subject<boolean> = new Subject<boolean>();
    @Input() text: any;
    @Input() subText: any;

    constructor(
        private events: EventsService,
        public translate:TranslateService
        ) {
    }

    ngOnInit() {
      if(this.text){
        this.loadingText = this.text;
      }
      if(this.subText){
          this.loadingSubText = this.subText;
      }
    }

    // ngOnInit(){
    //     this.events.observe("sync:completed")
    //     .takeUntil(this.destroy$)
    //     .subscribe(error => {
    //         if(this.viewCtrl){
    //             this.viewCtrl.dismiss();
    //         }
    //     });
    // }

    // ngOnDestroy() {
    //     this.destroy$.next(true);
    //     this.destroy$.unsubscribe();
    // }

}
