<ion-header>
  <ind-page-title [viewData]="surgeryOrderDetailsPanePageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
  <ion-toolbar *ngIf="isOCETrackingEnabled && !noProcedureDay">
    <ind-tabs [data]="tabsData" [selectedTab]="surgeryOrderDetailSegment" (selectedTabChange)="segmentChanged($event)">
    </ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div [ngSwitch]="surgeryOrderDetailSegment" *ngIf="isOCETrackingEnabled && !noProcedureDay">
    <surgery-order-info *ngSwitchCase="'info'" [from]="from" [activity]="currentSurgeryOrderActivity"></surgery-order-info>
    <surgery-order-oce-tracking *ngSwitchCase="'oceTracking'" [currentSurgeryOrder]="currentSurgeryOrderActivity"></surgery-order-oce-tracking>
  </div>
  <surgery-order-info *ngIf="!isOCETrackingEnabled || noProcedureDay" [from]="from" [activity]="currentSurgeryOrderActivity"></surgery-order-info>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('SurgeryOrder')" [footerView]="'detailView'" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->
