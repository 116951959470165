import {Component, ElementRef, ViewChild} from "@angular/core";
import {ComponentViewMode, UIService} from "@omni/services/ui/ui.service";
import {Subject} from "rxjs";
import {DynamicFormType, DynamicFormComponentModel} from "@omni/models/dynamic-form-component.model";
import {ContactOfflineService} from "@omni/services/contact/contact.service";
import {AuthenticationService} from "@omni/services/authentication.service";
import {DeviceService} from "@omni/services/device/device.service";
import {TranslateService} from "@ngx-translate/core";
import {DynamicFormsService} from "@omni/services/dynamic-forms/dynamic-forms-service";
import {GlobalUtilityService} from "@omni/services/global-utility.service";
import {NavigationService, PageName} from "@omni/services/navigation/navigation.service";
import {LoadingController, ModalController, PopoverController, NavParams} from "@ionic/angular";
import {ContactDataService} from "@omni/data-services/contact/contact.data.service";
import {NotificationService, ToastStyle} from "@omni/services/notification/notification.service";
import {AlertService} from "@omni/services/alert/alert.service";
import {TrackingEventNames, TrackService} from "@omni/services/logging/tracking.service";
import {EventsService} from "@omni/services/events/events.service";
import {DiskService} from "@omni/services/disk/disk.service";
import {MdmService} from "@omni/services/mdm/mdm.service";
import {FooterService} from "@omni/services/footer/footer.service";
import {takeUntil} from "rxjs/operators";
import {DynamicForm, FormType, BusinessProcessType, EditConfig} from "@omni/classes/dynamic-form/dynamic-form.class";
import {DynamicFormComponent} from "@omni/components/shared/ind-dynamic-form/ind-dynamic-form";
import {DB_KEY_PREFIXES} from "@omni/config/pouch-db.config";
import { ChangeRequestType } from '@omni/classes/mdm/source-type-optionset.class';
import { IONote } from '@omni/classes/io/io-note.class';
import { isBefore } from 'date-fns';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import _ from 'lodash';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';


@Component({
  selector: 'account-cr-details',
  templateUrl: 'account-cr-details.html',
  styleUrls:['account-cr-details.scss']
})
export class AccountCrDetailsComponent {

  public compViewMode = ComponentViewMode;
  private unsubscribe$ = new Subject<void>();

  public displayFormDetails;
  public rawFormData;
  public dynamicFormType:DynamicFormType;
  public pageTitleControls = [];
  public accountCRLinkedEntityDBPrefix:string = DB_KEY_PREFIXES.ACCOUNT_CR_LINKED_ENTITY;
  public isEditFeatureEnabled: boolean = false;
  public isScrapEnabled: boolean = false;
  public currentNoteText: string;
  public noteTextHistory: Array<IONote> = [];
  public notesSectionHeaderViewData: IndSectionHeaderViewDataModel;
  private editAccountCrRecordLinkedEntities = [];
  public accessedFrom;

  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;

  constructor(
    public contactService: ContactOfflineService,
    public authService: AuthenticationService,
    private device: DeviceService,
    private translate: TranslateService,
    private dynamicFormsService: DynamicFormsService,
    private utilityService: GlobalUtilityService,
    private navService: NavigationService,
    private loadingCtrl: LoadingController,
    private contactDataService: ContactDataService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    public uiService: UIService,
    private trackingService: TrackService,
    private events: EventsService,
    public popoverCtrl: PopoverController,
    private disk: DiskService,
    private modalCtrl: ModalController,
    public mdmService: MdmService,
    public footerService: FooterService,
    private navParams: NavParams,
    private accountService: AccountOfflineService
  ) {

    this.mdmService.currentSelectedObs$.pipe(takeUntil(this.unsubscribe$)).subscribe(()=> {
      this.initAccountCRDetails();
    });
  }

  ngOnInit(){
    this.footerService.updateButtons([], true);
  }


  ngOnDestroy() {
    this.mdmService.currentSelected.next(undefined);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(){
    this.initAccountCRDetails();
    if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
  }

  private async initAccountCRDetails() {
    let selectedCR = this.mdmService.currentSelected.getValue();
    if (selectedCR) {
      let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_accountcr", FormType.DISPLAYFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if(!accountForm){
        this.notificationService.notify(this.translate.instant('ONE_KEY_DISPLAY_FORM_NO_FORMS_ERR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
        this.closepage();
      }
      
      if (!this.device.isOffline && selectedCR['indskr_accountsourcetype'] && selectedCR['indskr_accountsourcetype'] == ChangeRequestType.BUSINESS && selectedCR['statecode'] == 0 && selectedCR['statuscode'] == 548910009) {
        this.isScrapEnabled = this.isEditFeatureEnabled = true;
      } else {
        this.isScrapEnabled = this.isEditFeatureEnabled = false;
      }
      this.dynamicFormType = formType;
      this.displayFormDetails = accountForm;
      this.initNotes();
      this._initCustomerNotesViewData();
      this.initPageTitleControls();
      this.rawFormData = selectedCR;
    }
  }

  private _initCustomerNotesViewData() {
    this.notesSectionHeaderViewData = {
      id: 'contact_notes_section_header',
      title: this.translate.instant('NOTES'),
      controls: [],
    }
  }

  public async editAccountCR(event) {
    this.dynamicFormsService.okLookUpMapper = [];
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_accountcr", FormType.CREATEFORM, BusinessProcessType.Omnipresence);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!contactForm || !this.rawFormData) {
      return;
    }
    const contactRaw = this.rawFormData;
    this.mdmService.addLinkEntities([]);
    for (let i = 0; i < contactForm.metadata.length; i++) {
      const tab = contactForm.metadata[i];
      if (tab && tab.controls && tab.controls.length > 0) {
        for (let j = 0; j < tab.controls.length; j++) {
          let control = tab.controls[j];
          if (control.attributeName && (control.attributeName == "indskr_mdm" || control.attributeName == 'indskr_mdmid')) {
            control.forceHide = true;
          }
        }
      }
    }
    let formDetails: DynamicFormComponentModel = {
      title: this.translate.instant('EDIT') + " " + (this.rawFormData['indskr_name'] ? this.rawFormData['indskr_name'] : ''),
      LookupEntitySetName: 'contacts',
      showLeftHeaderButton: true,
      enableSubgridDataEditing: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: { indskr_accountcrid: this.rawFormData['indskr_accountcrid'], },
      dynamicFormType: formType,
      linkedEntityValue: [],
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleDataEditCRFormComponentEvent(data, eventTarget, refData),
    };

    await this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      TYPE: "OMNIPRESENCE",
      MODE: 'EDIT_MODE',
      FORM_DETAIL: formDetails,
      RAW_DATA: this.rawFormData,
      EDIT_CONFIG: EditConfig.BOTH,
      LINKED_ENTITY_DB_PREFIX: "account_cr_linked_entity",
      REF_ENTITY_NAME: 'indskr_accountcrid'
    });
  }


  private async initNotes() {
    this.noteTextHistory = [];
    this.currentNoteText = ''
    let dbNotes = await this.disk.retrieve('account_cr_linked_entityindskr_approvalactivity');
    if (dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0) {
      dbNotes.raw.forEach(item => {
        if (this.rawFormData &&
          item['indskr_accountcrid'] == this.rawFormData['indskr_accountcrid'] &&
          item['indskr_approvalactivity.indskr_approvalactivityid'] &&
          item['indskr_approvalactivity.indskr_approver'] &&
          item['indskr_approvalactivity.indskr_reason'] &&
          item['indskr_approvalactivity.createdon']) {
          const payload = {
            annotationid: item['indskr_approvalactivity.indskr_approvalactivityid'],
            createdon: item['indskr_approvalactivity.createdon'] ? new Date(item['indskr_approvalactivity.createdon']).getTime().toString() : null,
            notetext: item['indskr_approvalactivity.indskr_reason'],
            ownerName: item['indskr_approvalactivity.indskr_approver@OData.Community.Display.V1.FormattedValue'],
            ownerid: item['indskr_approvalactivity.indskr_approver'],
          };
          let currentNote = new IONote(payload);
          let idx = this.noteTextHistory.findIndex(note => note.noteId == currentNote.noteId);
          if (idx >= 0) {
            this.noteTextHistory[idx] = currentNote;
          } else {
            this.noteTextHistory.push(currentNote);
          }
        }
      });
      this._sortCustomerNotes();
      if (!_.isEmpty(this.noteTextHistory)) {
        this.currentNoteText = this.noteTextHistory[0].noteText;
      }
    }
  }

  private _sortCustomerNotes() {
    if (!_.isEmpty(this.noteTextHistory)) {
      this.noteTextHistory = this.noteTextHistory.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  private initPageTitleControls(): void {
    let buttons = [];
    if (this.accessedFrom === 'NotificationDetails') {
      buttons.push({
        id: 'close',
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });

      buttons.push({
        id: "goToAccountsTool",
        imgSrc: 'assets/imgs/open_in_new_white.svg',
        name: this.translate.instant('OPEN'),
        isDisabled: false,
        align: "right"
      });
    }
    if (this.isScrapEnabled) {
      buttons.push({
        id: 'scrap',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.device.isOffline,
        align: "right"
      })
    }
    this.pageTitleControls = buttons
  }

  private async _handleDataEditCRFormComponentEvent(data, eventTarget, refData: DynamicFormComponentModel) {
    if (eventTarget) {
      if (eventTarget == 'DONEBUTTONCLICK') {
        let selectedCR = this.mdmService.currentSelected.getValue();
        if (data && data.formValue && selectedCR) {
          this.uiService.displayLoader();
          let payload = _.cloneDeep(data.formValue);
          payload['indskr_accountcrid'] = selectedCR['indskr_accountcrid'];
          payload["linkEntities"] = this.getBusinessCREditReqPayloadLinkEntities();

          //add mandatory fields
          payload['indskr_accountsourcetype'] = BusinessProcessType.Omnipresence;
          if (payload['statecode']) delete payload.statecode;
          if (payload['statuscode']) delete payload.statuscode;

          // /*************Edit business Change Request in offline*************/
          // if (this.contactDataService.isOfflineBusinessCR(payload)) {
          //   this.contactDataService.addToOfflineBusinessCRIds(payload.indskr_accountcrid);
          //   await this.disk.createOfflineBusinessCR(payload);

          //   //Clear the entry of Contact Change Request from PouchDB and Service layer
          //   payload['modifiedon'] = new Date().getTime();
          //   payload['createdon'] = new Date().getTime();
          //   await this.mdmService.upsertContactCRInPouchDB(payload);
          //   // await this.diskService.upsertContactCRs(data);
          //   let contactCRs = this.mdmService.contactCRs.getValue();
          //   let idx;
          //   if (Array.isArray(contactCRs) && contactCRs.length) {
          //     idx = contactCRs.findIndex(a => a['indskr_accountcrid'] == payload['indskr_accountcrid']);
          //   }
          //   if (idx >= 0) {
          //     contactCRs[idx] = payload;
          //   } else {
          //     contactCRs.push(payload);
          //   }
          //   this.mdmService.contactCRs.next(contactCRs);

          //   this.editAccountCrRecordLinkedEntities = [];
          //   this.mdmService.currentSelected.next(selectedCR);

          //   let resok = {
          //     "indskr_accountcrid": payload['indskr_accountcrid']
          //   };
          //   data.eventsHandler(resok, 'DISABLEFORM');
          //   this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
          //   // this.notificationService.notify(this.translate.instant('CR_UPDATE_SUBMIT_MESSAGE', { crType: this.globalCustomerText.toLowerCase(), lastName: payload['indskr_lastname'] }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          //   this.navService.popChildNavPageWithPageTracking();
          //   await this.uiService.dismissLoader();
          // }
          // /*************Edit business Change Request in offline*************/
          // else {
          await this.mdmService.editBusinessAccountDataCR(payload, selectedCR['indskr_accountcrid']).then(async (resok: any) => {
              try {
                await this.mdmService.fetchAccountCRConfiguredDisplay(false, false, selectedCR).then((res) => {
                  if (res && Array.isArray(res)) {
                    selectedCR = res[0];
                  } else {
                    selectedCR = res;
                  }
                  this.uiService.dismissLoader();
                });
              } catch (e) {
                console.log("error occurred fetching contactCR online: ", e);
                await this.uiService.dismissLoader();
              }
              this.editAccountCrRecordLinkedEntities = [];
              this.mdmService.currentSelected.next(selectedCR);
              data.eventsHandler(resok, 'DISABLEFORM');
              this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
            this.notificationService.notify(this.translate.instant('ACCOUNT_CR_UPDATE_SUBMIT_MESSAGE', { accountName: data.formValue['indskr_name'] }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              this.navService.popChildNavPageWithPageTracking();
              await this.uiService.dismissLoader();
            }).catch((err) => {
              this.uiService.dismissLoader();
              console.log("Error creating Contact change request");
            });
          // }
        }
      } else if (eventTarget == 'CANCELBUTTONCLICK') {
        if (data && data.displayConfirmation) {
          let cancelText = this.translate.instant("CANCEL");
          if(this.translate.currentLang == 'it') {
            cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
          }
          this.alertService.showAlert({
            title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
            message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')
          }, this.translate.instant('DISCARD'), cancelText
          ).then(res => {
            if (res.role == "ok") {
              this.editAccountCrRecordLinkedEntities = [];
              this.navService.popChildNavPageWithPageTracking();
            }
          });
        } else {
          this.editAccountCrRecordLinkedEntities = [];
          this.navService.popChildNavPageWithPageTracking();
        }
      } else if (eventTarget == 'ADDEDLINKEDENTITY' || eventTarget == 'SCRAPLINKEDENTITY') {
        if (data) {
          if (_.isEmpty(data))
            this.editAccountCrRecordLinkedEntities = [];
          else {
            if (eventTarget == 'SCRAPLINKEDENTITY') {
              if (data['linkedEntityAddedNewDataId'] && !data['linkedEntityExistingDataId']) {
                let idx = this.editAccountCrRecordLinkedEntities.findIndex(a => a['linkedEntityAddedNewDataId'] == data['linkedEntityAddedNewDataId']);
                this.editAccountCrRecordLinkedEntities.splice(idx, 1);
              } else if (data['linkedEntityExistingDataId']) {
                let idx = this.editAccountCrRecordLinkedEntities.findIndex(a => a['linkedEntityExistingDataId'] == data['linkedEntityExistingDataId']);
                if (idx >= 0) {
                  this.editAccountCrRecordLinkedEntities[idx] = data;
                } else {
                  this.editAccountCrRecordLinkedEntities.push(data);
                }
              }
            } else if (eventTarget == 'ADDEDLINKEDENTITY') {
              if (data['linkedEntityAddedNewDataId'] && !data['linkedEntityExistingDataId']) {
                let idx = this.editAccountCrRecordLinkedEntities.findIndex(a => a['linkedEntityAddedNewDataId'] == data['linkedEntityAddedNewDataId']);
                if (idx >= 0) {
                  this.editAccountCrRecordLinkedEntities[idx] = data;
                } else {
                  this.editAccountCrRecordLinkedEntities.push(data);
                }
              } else if (data['linkedEntityExistingDataId']) {
                let idx = this.editAccountCrRecordLinkedEntities.findIndex(a => a['linkedEntityExistingDataId'] == data['linkedEntityExistingDataId']);
                if (idx >= 0) {
                  this.editAccountCrRecordLinkedEntities[idx] = data;
                } else {
                  this.editAccountCrRecordLinkedEntities.push(data);
                }
              }
            }
          }
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      }
    }
  }

  private getBusinessCREditReqPayloadLinkEntities() {
    let payload = [];
    this.editAccountCrRecordLinkedEntities.forEach(linkEntity => {
      if (linkEntity['values']) {
        let tempItem = linkEntity['values'];
        if (tempItem['guid']) {
          tempItem['id'] = tempItem['guid'];
          delete tempItem.guid;
        }
        if (tempItem['statecode'] == 1 && tempItem['statuscode'] == 2) {
          // Mark the status as Cancelled 
          switch (linkEntity['entity']) {
            case "indskr_accountcontactaffiliationcrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910000;
              break;
            case "indskr_accountaccountaffiliationcrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 2;
              break;
            case "indskr_emailaddresscrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 100000000;
              break;
            case "indskr_customerspecialtychangerequests":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910000;
              break;
            case "indskr_contactrelationshipcrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910003;
              break;
            case "indskr_customeraddresscrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910000;
              break;
          }
        }
        payload.push({
          values: tempItem,
          entity: linkEntity['entity']
        });
      }
    });
    let reduced = [];
    payload.forEach((cur) => {
      let idx = reduced.findIndex(a => a.entity == cur.entity);
      if (idx >= 0) {
        reduced[idx].values.push(cur.values);
      } else {
        reduced.push({
          values: [cur.values],
          entity: cur.entity,
        });
      }
    });
    return reduced;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closepage();
        break;
      case 'scrap':
        this.scrapChangeRequest();
        break;
      case 'goToAccountsTool':
        this.handleGoToAccountsTool();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  handleGoToAccountsTool() {
    this.contactService.isClickedGoToContactsTool = true;
    const selectedAccountCrId: string = this.navParams && this.navParams.data && this.navParams.get('accountCrId') ? this.navParams.get('accountCrId') : '';
    this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
    this.accountService.isClickedGoToAccountsTool = true;
    this.navService.popChildNavPageWithPageTracking();
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.LISTVIEW, from: 'AccountTool', openedToolFromCrDetails: true, accountCrId: selectedAccountCrId }, PageName.AccountPageComponent).then(() => {
      this.navService.setChildNavRightPaneView(true);
    });
  }

  private scrapChangeRequest() {
    if (this.rawFormData) {
      this.alertService.showAlert({
        header: this.translate.instant('SCRAP_CHANGE_REQUEST'),
        message: this.translate.instant('R_U_SURE_SCRAP_CR')
      }, this.translate.instant('SCRAP')
      ).then(async res => {
        if (res.role == "ok") {
          this.uiService.displayLoader();
          let data = {
            'statecode': 1,
            'statuscode': 548910007,
            "indskr_accountcrid": this.rawFormData['indskr_accountcrid']
          }
          await this.mdmService.scrapBusinessAccountCR(data, this.rawFormData['indskr_accountcrid']).then((res) => {
            if (res) {
              this.navService.setChildNavRightPaneView(false);
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
            }
            this.uiService.dismissLoader();
          }).catch((err) => {
            this.uiService.dismissLoader();
            console.log("Error scraping Contact change request");
          });
        }
      });
    }
  }

  closepage(){
    this.mdmService.currentSelected.next(undefined);
    this.uiService.activeView = "";
    this.navService.setChildNavRightPaneView(false);
    this.navService.popChildNavPageWithPageTracking();
  }
}
