import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HOST } from "../../../config/endpoints.config";
import { of } from "rxjs";
import { catchError, delay, map, retryWhen, tap } from "rxjs/operators";

export enum DEVICE_NETWORK_STATE {
    UNKNOWN,
    CONNECTED,
    DISCONNECTED
}

@Injectable({
  providedIn: 'root'
})
export class DeviceNetworkService {
    constructor(private http: HttpClient) {}

    async connectivityCheck() {
        const url = 'https://' + HOST;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('X-Skip-Interceptor', 'true');
        let retryCount = 0;
        return this.http.head(url, { responseType: 'text', headers }).pipe(
            retryWhen(errors =>
              errors.pipe(
                tap((e: any) => {
                  if ((e.status === 401 && e.statusText === "OK") || retryCount > 0) {
                    throw e;
                  } else {
                    retryCount++;
                  }
                }),
                delay(200)
              )
            ),
            map(() => true),
            catchError(
                e => {
                    if (e.status === 401 && e.statusText === 'OK') {
                        return of(true);
                    } else {
                        return of(false);
                    }
                }
            )
        ).toPromise();
    }    
}
