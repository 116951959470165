<ion-header>
  <ind-page-title
    [viewData]="viewData"
    (onControlClick)="onHeaderControlClick($event)"
  ></ind-page-title>
</ion-header>
<ion-content>
  <ng-container *ngIf="reference" [ngSwitch]="referenceType">
    <ng2-pdfjs-viewer
      #pdfViewer
      *ngSwitchCase="'pdf'"
      [pdfSrc]="reference.indskr_url"
      [page]="reference.indskr_documentpagenumber || 1"
      openFile="false"
      viewBookmark="false"
      download="false"
    ></ng2-pdfjs-viewer>
  </ng-container>
</ion-content>
