export enum MeetingDimensions {
  products = 'products',
  statecode = 'statecode',
}

export enum MeetingKPI {
  totalMeeting = 'Total Completed',
  avgMeeting = 'Average Completion Per Day',
  delayMeeting = 'Average Delay in Recording',
  coverage = 'Call Plan Coverage %',
  contentShared = 'Content Share %',
  totalContentDuration = 'Total Content Share Duration',
  totalWorkingDays = 'Working Days',
  totalInPerson = 'In-Person Meetings',
  totalLiveTime = 'Remote Meetings',
  totalPhoneCall = 'Phone Calls',
  cpaAB = 'CPA(A+B)',
  cpaABC = 'CPA(A+B+C)',
  totalCoveragePercent = 'Total Coverage %',
  noCallPlanMeeting = 'Meetings with No Call Plan',
}

export enum MeetingChartId {
  byProduct = 'byProduct',
  trend = 'trend',
}
