<ng-container *ngIf="!selectedCase">
    <nothing-selected-view [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
</ng-container>
<!-- Generated template for the CaseManagementDetailComponent component -->

<ion-header *ngIf="selectedCase">
    <ind-page-title [viewData]='{id:"case-management-details",title: caseHeader, controls: titleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content class="ion-no-padding ion-no-margin" *ngIf="selectedCase">
    <!--<ion-scroll>-->
    <ion-list class="ion-no-margin">
        <ind-section-header #scrollTop [viewData]="taskDetailsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

        <!-- Status -->
        <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>

        <!-- Stage -->
        <ind-form-field [viewData]="getStageFormField()"></ind-form-field>


        <!-- Case Number -->
        <ind-form-field [viewData]="getCaseNumberFormField()"></ind-form-field>

        <!-- Intake Created Date -->
        <!-- <ind-form-field [viewData]="getCreatedDateFormField()"></ind-form-field> -->
        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="getCreatedDateTimeFormField('date')"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="getCreatedDateTimeFormField('time')"></ind-form-field></ion-col>
        </ion-row>

        <!-- Intake Completed Date -->
        <!-- <ind-form-field [viewData]="getCompletedDateFormField()"></ind-form-field> -->
        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="getCompletedDateTimeFormField('date')"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="getCompletedDateTimeFormField('time')"></ind-form-field></ion-col>
        </ion-row>

        <!-- category -->
        <ind-form-field *ngIf="!isSurveyConfigured" [viewData]="getCategoryFormField()"></ind-form-field>

        <!-- Product -->
        <ind-form-field *ngIf="!isSurveyConfigured && !isNoProductSelection" [viewData]="getProductFormField()"></ind-form-field>

        <!-- Therapeutic Area -->
        <ind-form-field *ngIf="!isSurveyConfigured" [viewData]="getTherapeuticAreaFormField()"></ind-form-field>

        <!-- Contact -->
        <ind-form-field [viewData]="getContactFormField()"></ind-form-field>

        <!-- Account -->
        <ind-form-field *ngIf="!isSurveyConfigured" [viewData]="getAccountFormField()"></ind-form-field>


        <ind-form-field *ngIf="isKitBookingFAEnabled" [viewData]="getKitBookingFormField()"></ind-form-field>

        <!-- Response Preference -->
        <ind-form-field [viewData]="getResponsePreferenceFormField()"></ind-form-field>

        <div class="response-preference-field">
            <ind-form-field [viewData]="getResponsePreferenceTypeFormField()"></ind-form-field>
        </div>

        <ind-form-field [viewData]="getAssignedToFormField()"></ind-form-field>

        <ind-section-header *ngIf="!isSurveyConfigured" [viewData]="caseManagementInquiryHeaderModel"></ind-section-header>

        <ion-item class="text-area" *ngIf="selectedCase && selectedCase._case_status_value === 'Open' && !isSurveyConfigured">

            <ind-text-area class="notesText" [placeHolder]="enterEnquiryPlaceholder" (indBlur)='updateDataOnPouch()' [maxLength]="2000" [(value)]="notes" (valueChange)='inquiryNotesUpdated($event)' [rows]="4" [cols]="10" [ws]="4" [spellCheck]="false"></ind-text-area>
        </ion-item>
        <ion-item class="text-area" *ngIf="selectedCase && selectedCase._case_status_value != 'Open' && !isSurveyConfigured">
            <ind-text-area class="notesText" [(value)]="notes" [rows]="4" [cols]="10" [ws]="4" disabled="true"></ind-text-area>
        </ion-item>
    </ion-list>
    <!-- <ion-item-divider class="header-text ion-no-margin">
        {{'CASE_INQUIRY_TIMELINE' | translate}}
    </ion-item-divider> -->
    <div class="surveyWrapper" *ngIf="isSurveyConfigured && surveyService.surveyActiveFor == 'CaseManagement'">
      <ind-section-header *ngIf="((selectedCase._case_status_value === 'Open') || (selectedCase._case_status_value !== 'Open' && caseInquirySurveyResponseDataModel && caseInquirySurveyResponseDataModel.length))" [viewData]="caseInquirySurveyHeaderModel"></ind-section-header>
      <ind-survey *ngIf="selectedCase._case_status_value === 'Open'" [surveyTemplate]="surveyTemplate" [tempLookupResult]="surveyLookupResults" (completeSurvey)="updateSurveyResult($event)" (surveyData)="updateSurveyData($event)"></ind-survey>
      <div *ngIf="selectedCase._case_status_value !== 'Open'">
        <ng-container *ngFor="let result of caseInquirySurveyResponseDataModel">
          <ind-form-field [viewData]="result"></ind-form-field>
        </ng-container>
      </div>
    </div>

    <ind-section-header [viewData]="caseInquiryTimeLineHeaderModel"></ind-section-header>


    <div class="timelineWrapper">
        <ng-container *ngIf="!isDataAvailable()">
            <div class="no-data-message">
                {{'NO_TIMELINE' | translate}}
            </div>
        </ng-container>
        <div class="containerForData" *ngIf="isDataAvailable()">
            <ion-item-group class="timeline-group" *ngFor="let group of groupedActivities">
                <ion-item-divider class="ion-no-margin" [id]="group.key">
                    <div class="dividerBorder">
                    </div>
                    <div class="monthGroupName">
                        {{group.key}}
                    </div>
                </ion-item-divider>

                <ion-item class="margin-5" *ngFor="let activity of group.list">
                    <ion-grid *ngIf="!activity.activitytypecode">
                        <ion-row>
                            <ion-col size="3">
                                <h3 class="ion-text-center">{{parseDate(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center ion-text-wrap">{{formattedDate(activity.scheduledStart)}}</h4>
                            </ion-col>
                            <ion-col size="8">
                                <div class="ion-float-left ion-margin-start" *ngIf="activity.description && !activity.currentStatus">
                                    <h3 class="ion-text-nowrap">
                                        <span>{{activity.status}}</span>
                                    </h3>
                                    <h4>
                                        <span>{{activity.description}}</span>
                                    </h4>
                                </div>
                                <div class="ion-float-left ion-margin-start" *ngIf="activity.currentStatus">
                                    <h3 class="ion-text-nowrap">
                                        <span>{{activity.status}}</span>
                                    </h3>
                                    <h4>
                                        <span>{{activity.currentStatus}}</span>
                                    </h4>
                                </div>
                                <div class="ion-float-left ion-margin-start" *ngIf="!activity.description && !activity.currentStatus">
                                    <h3 class="padDown5 ion-text-nowrap">
                                        <span>{{activity.status}}</span>
                                    </h3>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid *ngIf="activity.activitytypecode == 'Email'">
                        <ion-row>
                            <ion-col size="3">
                                <h3 class="ion-text-center">{{parseDate(activity.emailSentOn)}}</h3>
                                <h4 class="ion-text-center ion-text-wrap">{{formattedDate(activity.emailSentOn)}}</h4>
                            </ion-col>
                            <ion-col size="9">
                                <div class="ion-float-left ion-margin-start" *ngIf="activity.subject.length > 0">
                                    <h3>
                                        <span>{{'MESSAGE' | translate}} - {{activity.subject}}</span>
                                    </h3>
                                    <h4>
                                        <span *ngIf="activity.indskr_ownerfullname == userName">
                      {{'CONTACT_SENT_BY_ME' | translate}}</span>
                                        <span margin-right *ngIf="activity.indskr_ownerfullname !== userName">
                      {{'SENT_BY' | translate}} {{activity.indskr_ownerfullname}} </span>

                                        <br *ngIf="device.shouldBeMobileView">
                                        <span *ngIf="activity.emailSentOn && !activity.readOn">
                      {{'DELIVERED' | translate}} - {{getFormattedTime(activity.emailSentOn)}}
                    </span>
                                        <span *ngIf="activity.readOn">
                      {{'READ' | translate}} - {{getFormattedTime(activity.readOn)}}
                    </span>
                                    </h4>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid *ngIf="activity.activitytypecode == 'Appointment'">
                        <ion-row>
                            <ion-col size="3">
                                <h3 class="ion-text-center">{{parseDate(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center ion-text-wrap">{{formattedDate(activity.scheduledStart)}}</h4>
                            </ion-col>
                            <ion-col size="9">
                                <!-- <div class="ion-float-left ion-margin-start" *ngIf="activity.description && !activity.currentStatus"> ion-margin-start"-->
                                <div class="ion-float-left ion-margin-start">
                                    <h3>
                                        <span>{{'MEETING' | translate}} - {{activity.subject}}</span>
                                    </h3>
                                    <h4>
                                        <span *ngIf="activity.indskr_ownerfullname == userName">
                  {{'MET_BY_ME' | translate}}
                </span>
                                        <span *ngIf="activity.indskr_ownerfullname !== userName">
                  {{'MET_BY_WITH_TEXT' | translate: {text : activity.indskr_ownerfullname} }}
                </span>
                                        <span>
                  {{activity.location}}
                </span>

                                    </h4>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid *ngIf="activity.activitytypecode == 'Task'">
                        <ion-row>
                            <ion-col size="3">
                                <h3 class="ion-text-center">{{parseDate(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center ion-text-wrap">{{formattedDate(activity.scheduledStart)}}</h4>
                            </ion-col>
                            <ion-col size="8">
                                <div class="ion-float-left ion-margin-start">
                                    <h3>
                                        <span>{{'CASE_TASK' | translate}} - {{activity.subject}}</span>
                                    </h3>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid *ngIf="activity.activitytypecode == 'Phonecall'">
                        <ion-row>
                            <ion-col size="3">
                                <h3 class="ion-text-center">{{parseDate(activity.scheduledStart)}}</h3>
                                <h4 class="ion-text-center ion-text-wrap">{{activity.scheduledStart | customDate:'TIME'}}</h4>
                            </ion-col>
                            <ion-col size="9">
                                <!-- <div class="ion-float-left ion-margin-start" *ngIf="activity.description && !activity.currentStatus"> ion-margin-start"-->
                                <div class="ion-float-left ion-margin-start">
                                    <h3>
                                        <span text-wrap>{{'NEW_ACTIVITY_PHONECALL' | translate}} - {{activity.subject}}</span>
                                    </h3>
                                    <h4>
                                        <span *ngIf="activity.indskr_ownerfullname == userName">
                  {{'MET_BY_ME' | translate}}
                </span>
                                        <span *ngIf="activity.indskr_ownerfullname !== userName">
                  {{'MET_BY_WITH_TEXT' | translate: {text : activity.indskr_ownerfullname} }}
                </span>
                                        <span *ngIf="activity.phoneNumber">
                  {{activity.phoneNumber}}
                </span>

                                    </h4>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-item>
            </ion-item-group>
        </div>
    </div>
</ion-content>

<!-- <ion-footer *ngIf="selectedCase"> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('CaseIntake')" [footerView]="'detailView'" [selectedView]="'CustomerInquiry'"></footer-toolbar>
<!-- </ion-footer> -->
