import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { ActivityPresentation } from '@omni/classes/presentation/activity-presentation.class';
import { ActivityPresentationSlideContact } from '@omni/classes/presentation/activity-slide-contacts.class';
import { Page } from '@omni/classes/presentation/presentation.class';
import { ActivityResourceContact } from '@omni/classes/resource/activity-resource-contact.class';
import { ActivityResource } from '@omni/classes/resource/activity-resource.class';
import { PopoverComponent } from '@omni/components/popover/popover';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { SENTIMENT } from '@omni/data-services/websocket/payloads/sentiment.payload';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsService } from '@omni/services/events/events.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { PresentationService } from '@omni/services/presentation/presentation.service';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { differenceInMilliseconds } from 'date-fns';
import _ from "lodash";

@Component({
  selector: 'meeting-sentiment-history-detail',
  templateUrl: './meeting-sentiment-history-detail.html',
  styleUrls: ['./meeting-sentiment-history-detail.scss'],
})
export class MeetingSentimentHistoryDetailComponent implements OnInit {

  public pageTitleViewData: IndPageTitleViewDataModel;
  private isReadOnlyMode: boolean = true;
  @Input() from: string;
  @Input() meeting: AppointmentActivity;
  private _isMeetingSentimentDisabled: boolean = false;
  public isMeetingContentDurationEnabled: boolean = false;

  public mainTimelineItem: TimeLineItem;
  public productsKeyMessagesSectionHeader: IndSectionHeaderViewDataModel;
  public keyMessagesSentimentSectionHeader: IndSectionHeaderViewDataModel;
  public contentSentimentSectionHeader: IndSectionHeaderViewDataModel;
  public productKeyMessagesViewData: Array<any> = [];
  public keyMessageSentimentsViewData: Array<any> = [];
  public contentSentimentsViewData: Array<any> = [];

  constructor(
    private readonly contactService: ContactOfflineService,
    private readonly translate: TranslateService,
    private readonly navService: NavigationService,
    private readonly customer360UIService: Customer360UIService,
    private readonly activityService: ActivityService,
    private readonly device: DeviceService,
    private readonly authService: AuthenticationService,
    private readonly popoverCtrl: PopoverController,
    private readonly events: EventsService,
    private readonly presentationService: PresentationService,
  ) { }

  ngOnInit() {
    if (this.meeting) {
      // const activity = this.activityService.getActivityByID(this.meeting.ID);
      // if (!activity) {
      //   this.isReadOnlyMode = true;
      // } else {
      //   this.meeting = activity as AppointmentActivity;
        
      // }
      if (this.device.isOffline || this.meeting.isCompleted || this.meeting.meetingOwnerId != this.authService.user.systemUserID) {
        this.isReadOnlyMode = true;
      }
      this._isMeetingSentimentDisabled = this.authService.hasFeatureAction(FeatureActionsMap.TURN_OFF_SENTIMENT_CAPTURE);
      this.isMeetingContentDurationEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONTENT_DURATION);
      this._initPageTitle();
      this._initTimelineItemViewData();
      this._initSectionHeadersView();
      this._initMeetingProductKeyMessagesViewData();
      this._initKeyMessageSentimentsViewData();
      this._initContentSentimentViewData();
    }
  }

  private _initPageTitle() {
    let buttons:Array<any> = [{
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    }];
    if(!this.isReadOnlyMode){
      buttons.push({
        id: "save",
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('CR_SAVE'),
        isDisabled: this.isReadOnlyMode,
        align: "right"
      })
    }
    this.pageTitleViewData = {
      id: "meeting-sentiment-history-details",
      title: this.translate.instant("SENTIMENT_HISTORY") + ' - ' + this.meeting.subject,
      controls: buttons,
    };
  }

  private _initTimelineItemViewData() {
    if (this.meeting) {
      let view = this.customer360UIService.getSentimentHistoryViewForMeetingActivity(this.meeting);
      if (view) {
        view.hideEndArrow = true;
        this.mainTimelineItem = view;
      }
    }
  }

  private _initSectionHeadersView() {
    this.productsKeyMessagesSectionHeader = {
      id: 'product-key-messages-section',
      title: this.translate.instant('PRODUCT_KEY_MESSAGES'),
      controls: []
    };
    this.keyMessagesSentimentSectionHeader = {
      id: 'key-messages-sentiment-section',
      title: this.translate.instant('KEY_MESSAGE_SENTIMENT'),
      controls: []
    };
    this.contentSentimentSectionHeader = {
      id: 'content-sentiment-section',
      title: this.translate.instant('CONTENT_SENTIMENT'),
      controls: []
    };
  }

  private _initMeetingProductKeyMessagesViewData() {
    const products = this.meeting && this.meeting['activityProducts'] ? this.meeting['activityProducts'] : [];
    if (products && products.length > 0) {
      this.productKeyMessagesViewData = [];
      products.forEach(product => {
        // Create Key Messages String
        let keyMsgs = [];
        let keyMsgsStr: string = '';
        if (!_.isEmpty(product['activityProductKeyMessages'])) {
          keyMsgs = product['activityProductKeyMessages'].map(km => km['indskr_name']);
        }
        if (!_.isEmpty(product['keyMessages'])) {
          keyMsgs = _.concat(keyMsgs, product['keyMessages'].map(keymsg => keymsg['name']));
        }
        keyMsgsStr = keyMsgs.join(', ');

        let obj = {
          id: product.ID || product['indskr_productid'],
          label: product.name || product['productName'],
          keyMessages: keyMsgsStr,
        }
        this.productKeyMessagesViewData.push(obj);
      });
    }
  }

  private _initKeyMessageSentimentsViewData() {
    if (this.meeting.activityContactSentiments && this.meeting.activityContactSentiments.length > 0 && this.contactService.contactInformation) {
      this.keyMessageSentimentsViewData = [];
      this.meeting.activityContactSentiments.forEach(actCon => {
        if (actCon.indskr_contactid == this.contactService.contactInformation.ID) {
          actCon.activityKeyMessageSentiments.forEach(conKeyMsg => {
            this.keyMessageSentimentsViewData.push({
              "indskr_name": conKeyMsg.indskr_name,
              "sentimentImgURL": this._getSentimentImageURLFromSentiment(conKeyMsg.indskr_keymessagesentiment),
              "sentiment": conKeyMsg.indskr_keymessagesentiment,
              "indskr_keymessageid": conKeyMsg.indskr_keymessageid,
              keyMessageObj: conKeyMsg,
            })
          })
        }
      })
    }
  }

  private _initContentSentimentViewData() {
    let activityContents;
    if (this.meeting.activityPresentations && this.meeting.activityPresentations.length > 0) {
      activityContents = this.meeting.activityPresentations.map(ap => { ap['type'] = 'presentation'; return ap });
    }
    if (this.meeting.activityResources && this.meeting.activityResources.length > 0) {
      activityContents = _.concat(activityContents, this.meeting.activityResources.map(ar => { ar['type'] = 'resource'; return ar }));
    }
    if(activityContents && activityContents.length > 0){
      activityContents.sort((c1, c2): number => {
        let startTime1: number = this._getStartTimeForContent(c1);
        let startTime2: number = this._getStartTimeForContent(c2);
        if (startTime1 > startTime2) return 1;
        else if (startTime1 < startTime2) return -1;
        else return 0;
      });
      activityContents.forEach(content=> {
        if(content){
          let obj = {
            contentName: content.indskr_name || content.name || content.indskr_ckmtitle,
            slidesData: [],
          };
          if(content.type == 'presentation'){
            const slides = [];
            if(content && content.activityPresentationSlides){
              content.activityPresentationSlides.sort((a, b) => a['starttime'] > b['starttime'] ? 1 : a['starttime'] === b['starttime'] ? 0 : -1).forEach(curSlide=> {
                // Create Key Messages String
                let keyMsgs = [];
                let keyMsgsStr: string = '';
                if (!_.isEmpty(curSlide['activityProductKeyMessages'])) {
                  keyMsgs = curSlide['activityProductKeyMessages'].map(km => km['indskr_name']);
                }
                if (!_.isEmpty(curSlide['keyMessages'])) {
                  keyMsgs = _.concat(keyMsgs, curSlide['keyMessages'].map(keymsg => keymsg['name']));
                }
                keyMsgsStr = keyMsgs.join(', ');
                let sentimentImageUrl:string = 'assets/imgs/omni_neutral_sentiment.svg';
                if(curSlide.sentimentImgURL){
                  sentimentImageUrl = curSlide.sentimentImgURL;
                }
                if(curSlide.activityPresentationSlideContacts){
                  let conSlide = curSlide.activityPresentationSlideContacts.find(a=> (a.contactid ? a.contactid : (a.indskr_contactid ? a.indskr_contactid : '')) == this.contactService.contactInformation.ID);
                  if(conSlide && (conSlide.pagesentiment || conSlide.indskr_pagesentiment)){
                    sentimentImageUrl = this._getSentimentImageURLFromSentiment(conSlide.pagesentiment ? conSlide.pagesentiment : conSlide.indskr_pagesentiment);
                  }
                }
                slides.push({
                  slideName: curSlide.indskr_name || curSlide.name,
                  slideDuration: this.getDuration(curSlide),
                  isChecked: true,
                  keyMessagesStr: keyMsgsStr,
                  sentimentImage: sentimentImageUrl,
                })
              });
            }
            obj.slidesData = slides;
          }else if(content.type == 'resource'){
            const slides = [];
            if(content && content.activityResourceSlides){
              content.activityResourceSlides.sort((a, b) => a['indskr_starttime'] > b['indskr_starttime'] ? 1 : a['indskr_starttime'] === b['indskr_starttime'] ? 0 : -1).forEach(curSlide=> {
                // Create Key Messages String
                let keyMsgs = [];
                let keyMsgsStr: string = '';
                if (!_.isEmpty(curSlide['activityProductKeyMessages'])) {
                  keyMsgs = curSlide['activityProductKeyMessages'].map(km => km['indskr_name']);
                }
                if (!_.isEmpty(curSlide['keyMessages'])) {
                  keyMsgs = _.concat(keyMsgs, curSlide['keyMessages'].map(keymsg => keymsg['name']));
                }
                keyMsgsStr = keyMsgs.join(', ');
                let sentimentImageUrl:string = 'assets/imgs/omni_neutral_sentiment.svg';
                if(curSlide.sentimentImgURL){
                  sentimentImageUrl = curSlide.sentimentImgURL;
                }
                if(curSlide.activityResourceSlideContacts){
                  let conSlide = curSlide.activityResourceSlideContacts.find(a=> (a.contactid ? a.contactid : (a.indskr_contactid ? a.indskr_contactid : '')) == this.contactService.contactInformation.ID);
                  if(conSlide && (conSlide.pagesentiment || conSlide.indskr_pagesentiment)){
                    sentimentImageUrl = this._getSentimentImageURLFromSentiment(conSlide.pagesentiment ? conSlide.pagesentiment : conSlide.indskr_pagesentiment);
                  }
                }
                slides.push({
                  slideName: content.indskr_ioresourceid ? content.indskr_ckmtitle: 'Page ' + curSlide.indskr_pagenumber,
                  slideDuration: this.getDuration(curSlide),
                  isChecked: true,
                  keyMessagesStr: keyMsgsStr,
                  sentimentImage: sentimentImageUrl,
                })
              });
            }
            obj.slidesData = slides;
          }
          this.contentSentimentsViewData.push(obj);
        }   
      });
    }
  }

  private _getSentimentImageURLFromSentiment(sentiment:string):string{
    switch (sentiment) {
      case "POSITIVE":
        return 'assets/imgs/omni_positive_sentiment.svg';
      case "NEGATIVE":
        return 'assets/imgs/omni_negative_sentiment.svg';
      default:
        return sentiment ? 'assets/imgs/omni_neutral_sentiment.svg' : this._isMeetingSentimentDisabled ? null : 'assets/imgs/omni_neutral_sentiment.svg' ;
    }
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this._closePage();
        break;
      case 'save':
        this._handleSentimentSave();
        break;
    }
  }

  private _closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  private _handleSentimentSave() {
    if (!this.isReadOnlyMode) {

    }
  }


  // Existing Code for Sentiment Update
  private _getStartTimeForContent(content: ActivityResource | ActivityPresentation): number {
    if (content instanceof ActivityPresentation) return Math.min(...content.activityPresentationSlides.map(as => Number(as.starttime)));
    else return content.activityResourceSlides && Math.min(...content.activityResourceSlides.map(r => Number(r.indskr_starttime)));
  }

  public getDuration(slide,content:any = null): string {
    let starttime = slide['indskr_starttime'] || slide['starttime'];
    let endtime = slide['indskr_endtime'] || slide['endtime'];

    if (starttime && endtime) {
      let milliseconds = differenceInMilliseconds(parseFloat(endtime), parseFloat(starttime));
      if (!isNaN(milliseconds) && milliseconds < 0) {
        return '00:00:00';
      }
      var hours = milliseconds / (1000 * 60 * 60);
      var absoluteHours = Math.floor(hours);
      var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

      //Get remainder from hours and convert to minutes
      var minutes = (hours - absoluteHours) * 60;
      var absoluteMinutes = Math.floor(minutes);
      var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

      //Get remainder from minutes and convert to seconds
      var seconds = (minutes - absoluteMinutes) * 60;
      var absoluteSeconds = Math.floor(seconds);
      var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

      let keyMsgStr:string = '';
      if(content && content['presentationId'] && slide && slide['ckmpageid']){
        const pres = this.presentationService.getPresentationById(content['presentationId']);
        if(pres && pres.presentationPages && pres.presentationPages.length > 0){
          const page = pres.presentationPages.find(pg => pg.id == slide['ckmpageid']);
          if(page && page.keyMessage && page.keyMessage.length > 0){
              keyMsgStr= ' |';
            page.keyMessage.forEach(kMsg => {
              keyMsgStr+= ' '+kMsg.keyMessageName+','
            });
            keyMsgStr = keyMsgStr.substring(0, keyMsgStr.length - 1);
          }
        }
      }

      return h + ':' + m + ':' + s + keyMsgStr;

    } else {
      return '00:00:00';
    }
  }

}
