export class PriceList{
    startDate: string;
    endDate: string;
    state: number;
    statusCode: number;
    score: number;
    createdOn: string;
    ID: string;
    name: string;
    currencySymbol: string;
    currencyID: string;
    products: {
      productID: string,
      productName?: string,
      productApplicabilityOnSalesOrder?: boolean,
      amount: string,
      formattedAmount: string,
      uomid: string,
      parentProductID: string
    }[];
    countryAndAccountPairs: CountryAndAccount[];
    trackAction? : string
      constructor(raw:JSON){
          this.ID = raw['pricelevelid'] || ''
          this.name = raw['name'] || ''
          this.startDate = raw['begindate']? new Date(raw['begindate']).getTime().toString() : ''
          this.endDate = raw['enddate'] ?  new Date(raw['enddate']).getTime().toString() : ''
          this.state = raw['statecode'] || 0
          this.createdOn = raw['createdon'] || '';
          this.countryAndAccountPairs = [];
          this.statusCode = raw['statuscode'];
          this.currencySymbol = raw['transactioncurrencysymbol'] || '';
          this.currencyID = raw['_transactioncurrencyid_value'] || '';
      }
}
export interface CountryAndAccount{
  countryName: string;
  countryID: string;
  accountName: string;
  accountID: string;
  countryAccountPricelistID: string
}
