import { TranslateService } from '@ngx-translate/core';
import { Component, Input } from "@angular/core";

@Component({
  selector: 'activity-conflict-alert-msg',
  templateUrl: 'activity-conflict-alert-msg.html',
  styleUrls: ['activity-conflict-alert-msg.scss']
})
export class ActivityConflictMsgComponent {
  @Input() alignment: 'left' | 'half' | 'right' = 'half';
  @Input() msg: string;
  public translatedMessage: string;
  constructor(public translate: TranslateService) {
    if (!this.msg) {
      this.msg = this.translate.instant('ACTIVITY_CONFLICT_MSG');
    }

  }
}
