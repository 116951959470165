<ion-header>
  <ind-page-title [viewData]="surveyDetailsPageTitle" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
</ion-header>
<ion-content no-padding>
  <ng-container>
    <ind-section-header [viewData]="surveyListSectionHeader"></ind-section-header>
    <ion-list *ngIf="filteredSurveyListToDisplay" class='survey-list-grid'>
      <div class="survey-card" *ngFor="let survey of filteredSurveyListToDisplay">
        <sub-card [viewData]="survey" [id]="survey.id"  (onControlClick)="onButtonControlClick($event)"></sub-card>
      </div>
    </ion-list>
  </ng-container>
</ion-content>