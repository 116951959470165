<ion-header>
  <ind-page-title [viewData]="pageTitleViewData" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
  <div class="searchbar-wrapper">
    <ion-searchbar placeholder="{{searchPlaceholder}}"[(ngModel)]="searchInput" (ionChange)="handleSearch()"></ion-searchbar>
    <div class="searchbar-overlay">
      <ion-buttons tooltip="{{'FILTERS' | translate}}" class="searchbar-overlay-button contactTimelineButtons" slot="end">
        <ion-icon class="searchbar-overlay-button-filter-icon contactTimelineFilterIcon" src="assets/imgs/filter-{{_allTimelineActivities.length == 0 ? 'grey' : 'blue'}}.svg" (click)="onFilterClick($event)"> </ion-icon>
        <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge" (click)="onFilterClick($event)">{{ filterButtonBadgeCount }}</span>
      </ion-buttons>
    </div>
  </div>
  <!--Account Glance Card-->
  <glance-card *ngIf="accountGlanceViewData" [viewData]="accountGlanceViewData"
    (onControlClick)="onQuickGlanceControlClick($event)"> </glance-card>
</ion-header>

<ion-content>

  <ind-section-header *ngIf="timelineSectionHeader" [viewData]='timelineSectionHeader' (onControlClickWithEventBubbleUp)='onSectionHeaderControlClick($event)'></ind-section-header>

  <ion-list class="time-line-list">
    <timeline-item *ngFor="let item of timelineItems" [inputData]="item"
      (clickedOnHeader)="openActivityDetails($event)"></timeline-item>
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="30%">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
  <ion-list>
    <div class="no-data-message" *ngIf="timelineItems.length == 0 && accountOfflineService.isAccountTimelineLoaded">
      {{'NO_TIMELINE' | translate}}</div>
  </ion-list>
  
</ion-content>