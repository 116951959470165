<ion-header>
    <ind-page-title [viewData]="newSampleActivityPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ion-list>
        <ind-section-header [viewData]='detailsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
        <ind-form-field [viewData]="statusFormField"></ind-form-field>
        <ind-form-field [viewData]="orderNumberFormField"></ind-form-field>
        <ion-row>
          <ion-col size="6"><ind-datetime-form *ngIf="orderDateField" [viewData]="orderDateField"></ind-datetime-form></ion-col>
          <ion-col size="6"><ind-datetime-form *ngIf="orderTimeField" [viewData]="orderTimeField"></ind-datetime-form></ion-col>
        </ion-row>
        <ind-form-field [viewData]="customerFormField"></ind-form-field>
        <ind-form-field [viewData]="addressFormField"></ind-form-field>
        <ind-form-field [viewData]="elligibleProductsFormField"></ind-form-field>
        <ind-section-header [viewData]='quantitiesHeader'></ind-section-header>
        <div *ngFor="let quantity of allocationQuantitiesData">
          <div class="accordion-header" [ngClass]="[quantity.isExpanded?'expanded':quantity.isInvalidValue? 'redLine-invalid-value':'collapsed',quantity.isQuantityInValid ? 'redLine-invalid-value':'' ]">
            <main-card [viewData]="quantity.view"></main-card>
          </div>
            <ion-item class="error-message-container" *ngIf="quantity.isInvalidValue || quantity.isQuantityInValid">
              <ion-label class="error-message-text">{{quantity.errorMessage}}</ion-label>
            </ion-item>
            <div class="quantity-accordian-expanded" *ngIf="quantity.isExpanded && !quantity.isInvalidValue">
                <ind-form-field *ngFor="let field of quantity.childItems" [viewData]="field.view" class="quantity-child"></ind-form-field>
            </div>
        </div>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('Sample')" [footerView]="'detailView'" [selectedView]="'activities'" (cancelAllocation)="onClosePage()"></footer-toolbar>
<!-- </ion-footer> -->