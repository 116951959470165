<div class='multi-select-popover popover-ios'>
    <ion-item *ngIf="showClear">
        <ion-button icon-only slot="end" fill="clear" (click)="clearAll($event.detail)" class="clearAllSectionsButton">{{'CLEAR_ALL' | translate}}</ion-button>
    </ion-item>
    <ng-container *ngTemplateOutlet="list_group;context:{$implicit:root}" width="250px !important"></ng-container>
    <ng-template #list_group let-item>
        <ion-list *ngIf="!item.customized && !item.multiselect">
            <ion-radio-group [(ngModel)]="item.value">
                <ion-list-header *ngIf="!!item.text" tappable (click)="item.expanded=!item.expanded">
                    <ion-label>{{item.text}} </ion-label>
                    <ion-button>
                        <ion-icon [name]="item.expanded ? 'chevron-up' : 'chevron-down'" slot="end"></ion-icon>
                    </ion-button>
                </ion-list-header>
                <div>
                    <ng-container *ngIf="!item.text || (item.expanded && item.items.length > 0)">
                        <ng-container *ngFor="let child of item.items">
                            <ion-item *ngIf="!child.items" [ngClass]="{'leftAlignedOption':item.text=='', 'filter-item': item.text!='', 'disabled':child.disableLabel}">
                                <ion-label *ngIf="!child.displayIcon">{{child.text}} </ion-label>
                                <ion-label *ngIf="child.displayIcon">
                                    <ion-thumbnail class="left-float">
                                        <img [src]="child.displayIcon" width="20" />
                                    </ion-thumbnail>
                                    <div text-left class="filter-text">
                                        {{child.text}}
                                    </div>
                                </ion-label>
                                <ion-radio slot="end" [value]="child.value" [disabled]="child.disabled"></ion-radio>
                            </ion-item>
                            <ng-container *ngIf="!!child.items">
                                <ng-container *ngTemplateOutlet="list_group;context:{$implicit:child}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ion-radio-group>
        </ion-list>
        <ion-list *ngIf="!!item.customized && !item.multiselect">
            <ion-radio-group>
                <ion-list-header *ngIf="!!item.text" tappable (click)="handleCustomizedSectionClick(item)">
                    <ion-label>{{item.text}}</ion-label>
                    <ion-button>
                        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
                    </ion-button>
                </ion-list-header>
            </ion-radio-group>
        </ion-list>
        <ion-list *ngIf="!!item.multiselect">
            <ion-list-header *ngIf="!!item.text" tappable (click)="item.expanded=!item.expanded">
                <ion-label>{{item.text}}</ion-label>
                <ion-button>
                    <ion-icon [name]="item.expanded ? 'chevron-up' : 'chevron-down'" slot="end"></ion-icon>
                </ion-button>
            </ion-list-header>
            <ng-container *ngIf="!item.text || (item.expanded && item.items.length > 0)">
                <ng-container *ngFor="let child of item.items">
                    <ion-item *ngIf="!child.items" [ngClass]="{'leftAlignedOption':item.text=='', 'filter-item': item.text!='', 'disabled':child.disableLabel}" (click)="handleMultiSelectOptionClick(child, item)">
                        <ion-label *ngIf="!child.displayIcon">{{child.text}}</ion-label>
                        <ion-label *ngIf="child.displayIcon">
                            <ion-thumbnail class="left-float">
                                <img [src]="child.displayIcon" width="20" />
                            </ion-thumbnail>
                            <div text-left class="filter-text" style="padding-top: 3px !important;">
                                {{child.text}}
                            </div>
                        </ion-label>
                        <!-- <ion-radio [checked]='item.multiselect ? child.checked : (child.value === item.value)'
              [value]="child.value" [disabled]="child.disabled"></ion-radio> -->
                        <ion-icon class="checkmarkIcon" src="assets/imgs/multi_select_filter_tick.svg" name="checkmark" slot="end" *ngIf="item.selectedValues.includes(child.value)"></ion-icon>
                    </ion-item>
                    <ng-container *ngIf="!!child.items">
                        <ng-container *ngTemplateOutlet="list_group;context:{$implicit:child}"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ion-list>
    </ng-template>
</div>