<ion-header>
    <div>
        <ion-toolbar [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
          <ion-buttons slot="start">
            <ion-button icon-only (click)="closePopover()">
              {{ 'CANCEL' | translate}}
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button icon-only [disabled]="!clearEnable" (click)="clearDraw()">
              {{ 'CLEAR' | translate}}
            </ion-button>
            <ion-button icon-only padding-left [disabled]="!submitEnable" (click)="openConfirmationPopup()">
              {{ 'SUBMIT' | translate}}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </div>
</ion-header>
<ion-content>
  <div class="ion-text-center signaturePadWrapper">
    <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
    <signature-pad #sigPad [options]='signaturePadOptions' (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
  </div>
</ion-content>
<ion-footer>
  <div class="ion-text-center popover-footer">
    {{ 'PLEASE_SIGN_ABOVE' | translate}}
  </div>
</ion-footer>
