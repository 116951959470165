<!-- Generated template for the PhoneCallMeetingStructureComponent component -->
<ind-section-header [viewData]='meetingStructureHeader' (onControlClick)='onSectionHeaderControlClick($event)'>
</ind-section-header>
<div class="activity-container" cdkDropListGroup>
  <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
  </div>
  <div cdkDropList *ngFor="let embeddedInteraction of embeddedInteractions"
    [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
    <div cdkDrag [cdkDragDisabled]="contentDisabled" class="embeddedActivity" (cdkDragMoved)="dragMoved($event);"
      [ngClass]="{'disabled':embeddedInteraction.type == 'Content' && contentDisabled}">
      <div class="thumbnail" (click)="openEmbeddedInteraction(embeddedInteraction)">
        <img class="downloaded-icon" src="assets/imgs/contact_conn.png"
          *ngIf="embeddedInteraction.type == 'Content' && embeddedInteraction.interaction.downloaded">
        <img class="ma-thumbnail" [src]="embeddedInteraction.thumbnailUrl" io-thumbnail [ngClass]="{'activityIcon': embeddedInteraction.type == 'Activity',
              'inactive': embeddedInteraction.type == 'Content' && !embeddedInteraction.interaction.downloaded && deviceService.isOffline,
              'activity-disabled': contentDisabled}">
      </div>
      <div class="activity-action">
        <div class="labels">
          <div class="name" float-left (click)="openEmbeddedInteraction(embeddedInteraction)">
            {{embeddedInteraction.name}}</div>
          <div class="remove" float-left>
             <ion-button *ngIf="embeddedInteraction.type == 'Activity'" [disabled]="embeddedInteraction.completed"
              (click)="handleInteractionRemove(embeddedInteraction)"
              [ngClass]="{'disabled': embeddedInteraction.completed}">
              {{'SCRAP' | translate}}
            </ion-button>
          </div>
        </div>
        <div class="actions" slot="end" cdkDragHandle>
          <div class="reorder">
            <ion-icon color="primary" name="reorder-three-outline" [ngClass]="{'disabled' : contentDisabled}">
            </ion-icon>
          </div>

          <div>
            <ion-icon (click)="plusButtonClicked(embeddedInteraction)" color="dark" class="pluse-icon"
              [ngClass]="{'disabled': (activityService.selectedActivity && (activityService.selectedActivity.isCompleted || activityService.selectedActivity.isReopened ) ||
                  activityService.selectedActivity && activityService.selectedActivity.ownerId !== authenticationService.user.systemUserID)}">
            </ion-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>