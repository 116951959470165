<ion-header>
    <ind-page-title [viewData]="activitiesDetailsPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <business-process *ngIf="meetingProcessView && meetingProcessView.length >= 4" [viewData]="meetingProcessView" [scroller]="scroller"></business-process>
</ion-header>
<!-- <ion-item no-lines hidden="true">
    <ion-datetime #appConfigFieldDatePicker [displayFormat]="dateTimeFormatsService.dateTimeToUpper" (ionCancel)="updateConfigFieldDate(false)" [(ngModel)]="appConfigFieldDate" [pickerFormat]="dateTimeFormatsService.dateTimeToUpper" (ionChange)="updateConfigFieldDate(true)"
        [max]="maxDate" [monthShortNames]="shortMonth | monthTranslate" [doneText]="this.translate.instant('DONE')" [cancelText]="this.translate.instant('CANCEL')">
    </ion-datetime>
</ion-item> -->
<ion-content #scroller>
    <ng-container *ngIf="activityService.selectedActivity && (activityService.selectedActivity.type == 'Appointment' || activityService.selectedActivity.type == 'LiveMeet' || activityService.selectedActivity.type == 'Joint Meeting' )">
        <ion-list id="{{detailsSectionId}}">
            <ind-section-header id="details" [viewData]='{id:"meeting-details",title: translate.instant("DETAILS")}'></ind-section-header>
            <ind-form-field *ngFor="let formField of firstLevelFormFields" [viewData]="formField"></ind-form-field>
            <ion-row>
                <ion-col size="6">
                    <ind-datetime-form *ngIf="meetingStartDateField" [viewData]="meetingStartDateField" (onUpdate)="onTimeUpdated()"></ind-datetime-form>
                </ion-col>
                <ion-col size="6">
                    <ind-datetime-form *ngIf="meetingStartTimeField" [viewData]="meetingStartTimeField" (onUpdate)="onTimeUpdated()"></ind-datetime-form>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="6">
                    <ind-datetime-form *ngIf="meetingEndDateField && !meetingEndDateField.isHidden" [viewData]="meetingEndDateField" (onUpdate)="onTimeUpdated()"></ind-datetime-form>
                </ion-col>
                <ion-col size="6">
                    <ind-datetime-form *ngIf="meetingEndTimeField && !meetingEndDateField.isHidden" [viewData]="meetingEndTimeField" (onUpdate)="onTimeUpdated()"></ind-datetime-form>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="meetingDurationField">
                <ion-col size="12">
                    <ind-form-field *ngIf="meetingDurationField" [viewData]="meetingDurationField" (onUpdate)="onTimeUpdated()"></ind-form-field>
                </ion-col>
            </ion-row>

            <activity-conflict-alert-msg [msg]="conflictMsg" *ngIf="displayConflictMsg"></activity-conflict-alert-msg>

            <!-- Checkin status  -->
            <ion-row>
              <ion-col size="6">
                  <ind-form-field *ngIf="checkInField && !isAccountVisitNestedMeeting && !this.disableGPSCheckInFlowForHCPMeeting" [viewData]="checkInField"></ind-form-field>
              </ion-col>
              <ion-col size="6">
                <ind-form-field *ngIf="checkOutField && !isAccountVisitNestedMeeting && !this.disableGPSCheckInFlowForHCPMeeting" [viewData]="checkOutField"></ind-form-field>
              </ion-col>
          </ion-row>

            <!-- -------------------------Best Time Recommendedations------------------------- -->
            <div class="dv-recommendations" *ngIf="authService.user.showBestTimeRecommendation && showTimeRecommendations && !device.isOffline && !(isAccountVisitRecord || isAccountVisitNestedMeeting)">
              <ion-row *ngIf="this.isTimeRecommendationEnabled && recommendedByCurrentTime.length > 0">
                <ion-col size="12">
                  <div class="dv-item-label">
                    <span class="span-recommendations-header"> {{'RECOMMENDATIONS' | translate}} </span>
                    <span class="span-recommendations-close" (click)="closeRecommendedations()">
                      <img src="assets/imgs/closeGeneeScreen.svg" width="25" height="25">
                    </span>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row *ngFor="let slot1 of recommendedByCurrentTime">
                <ion-col size="2">
                  <div class="dv-item">
                    <div>{{ slot1[0].substring(0, 3) }}:</div>
                  </div>
                </ion-col>
                <ion-col size="10">
                  <div class="dv-item">
                    <button class="btn-Timeslot" *ngFor="let slot2 of slot1[1]" (click)="setRecommendedDateTime(slot2)">
                      {{ slot2['timeSlot'] }}
                    </button>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <!-- -------------------------Best Time Recommendedations------------------------- -->
            <ind-form-field  *ngIf='hasCoachingPlan' [viewData]="coachingPlanField"></ind-form-field>
            <ind-form-field *ngIf='(!isLiveMeet && isMeetingTypeFAEnabled) && !isAccountVisitRecord && !isAccountVisitNestedMeeting && meetingTypeFormField' [viewData]="meetingTypeFormField"></ind-form-field>
            <ind-form-field  [viewData]="ownerFormField"></ind-form-field>
            <ind-form-field *ngIf='activityTypeFormField' [viewData]="activityTypeFormField"></ind-form-field>
            <ind-form-field *ngIf='activityTypeFormField' [viewData]="activitySubTypeFormField"></ind-form-field>
            <ind-form-field [viewData]="accountFormField"></ind-form-field>
            <ind-form-field [viewData]="contactFormField"></ind-form-field>
            <ind-form-field [viewData]="locationField"></ind-form-field>
            <ind-form-field [viewData]="meetinglinkedtoamarketingbusinessplanField"></ind-form-field>
            <ind-form-field [viewData]="marketingBusinessPlanField"></ind-form-field>
            <!-- <ind-form-field *ngIf="meetingLocationFormField" [viewData]="meetingLocationFormField"></ind-form-field> -->
            <!-- <ind-form-field [viewData]="getLivetimeFormField()" (handleAdditionalControls)="iconClick($event)"></ind-form-field> -->
            <ion-item class="meeting-url-item" *ngIf='!teamsLink && isRemoteEnabled && !isGpsEnabled && !isLiveTimeFieldEnabledForF2FMeetings' (click)="openURLPopover($event)">
                <ion-label class='form-field-header-label'>
                    <p class='form-field-label-text'>
                        <ion-text *ngIf='!isLiveMeet'>{{'ACTIVITY_DETAILS_LIVE_TIME' | translate}}</ion-text>
                        <ion-text *ngIf='isLiveMeet'>{{'LIVE_MEETING' | translate}}</ion-text>
                    </p>
                    <p class='form-field-value'>
                      <ion-text>{{meetingURL?.substring((meetingURL?.length - 4), meetingURL?.length)}}</ion-text>
                    </p>
                </ion-label>
                <div class="copy-link" (click)="onLiveMeetingLinkCopyClick($event)">
                  <ion-icon id="activity-detail-05" slot="end" tappable class="copy-icon" src="{{ nativeShareAvailable ? 'assets/imgs/share_social_icon.svg' : 'assets/imgs/link.svg' }}" width="20"></ion-icon>
                </div>
              </ion-item>
            <ion-item class="meeting-url-item" *ngIf='teamsLink' (click)="openTeamsURLPopover($event)">
              <ion-label class='form-field-header-label'>
                  <p class='form-field-label-text'><ion-text>{{'ACTIVITY_DETAILS_LIVE_TIME' | translate}}</ion-text></p>
                  <p class='form-field-value'><ion-text>{{teamsLink?.substring(0, 42)}}</ion-text></p>
              </ion-label>
              <ion-icon id="activity-detail-05" slot="end" tappable class="copy-icon" src="assets/imgs/link.svg" (click)="onTeamsLinkCopy($event)" width="20"></ion-icon>
          </ion-item>
            <div class="separationLine" *ngIf="isRemoteEnabled || teamsLink"></div>

            <ind-form-field *ngFor="let formField of secondLevelFormFields" [viewData]="formField"></ind-form-field>



            <!-- <ind-form-field [viewData]="getAccountsFormField()"></ind-form-field>
            <ind-form-field [viewData]="getContactsFormField()"></ind-form-field>
            <ind-form-field [viewData]="getCovisitorFormField()"></ind-form-field> -->

            <!-- appConfigFields for Appointment -->
            <div *ngFor="let appConfigField of appointmentConfigFields">
                <ind-form-field [viewData]="getAppontmentsFormField(appConfigField)"></ind-form-field>
            </div>
            <ion-row *ngIf="isManualGPSCheckinEnabled && !this.isAccountVisitNestedMeeting && !this.disableGPSCheckInFlowForHCPMeeting">
              <ion-col>
                <ind-form-field [viewData]="appealStatusFormField"></ind-form-field>
              </ion-col>
            </ion-row>
        </ion-list>
        <!-- InStore Meeting Photos-->
        <div *ngIf="isInStoreMeeting">
          <ind-section-header id="{{photosSectionId}}" [viewData]="photosSectionHeader"
            (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <div class="image-wrapper" [ngClass]="{'events-container':!activityService.selectedActivity['inStorePhotos']?.length}"
            lines="none">
            <ng-container *ngFor="let photo of activityService.selectedActivity['inStorePhotos']">
              <div class="image-container" (click)="openPhotoViewer(photo)">
                <img class="thumbnail" [id]="photo.indskr_photoattachmentid" io-thumbnail [src]="photo.indskr_photourl">
                <img tappable class="delete-icon" src="assets/imgs/delete_black.svg" width="24"
                [ngClass]="{'disabled': device.isOffline
                                    || backgroundUploadInProgress
                                    || activityService.selectedActivity.isCompleted
                                    || activityService.selectedActivity.ownerId != this.authService.user.xSystemUserID}"
                  (click)="deletePhotoAttachments($event, photo)" />
              </div>
            </ng-container>
          </div>
        </div>

        <div *ngIf="isManualGPSCheckinEnabled && !this.isAccountVisitNestedMeeting && !this.disableGPSCheckInFlowForHCPMeeting">          
          <ind-section-header id="{{gpsActivityPhotosSectionId}}" [viewData]="gpsActivityPhotosSectionHeader"
            (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <div class="photo-container">
            <div class="table">
              <ion-row>
                <ion-col size="6">
                  <div class="table-header">
                    <div class="col">{{'CHECK_IN_PHOTOS' | translate}}</div>
                  </div>
                  <div *ngFor="let pt of checkInPhotoAttachments">
                    <div class="table-row">
                      <div class="col photo-card" (click)="openPhotoViewerForGPSActivity(pt, GPSActivity.CHECKIN)">
                        <img class="thumbnail" io-thumbnail [src]="pt.photos[0].indskr_photourl" />
                        <div class="count-badge" *ngIf="pt.photos.length > 1"> {{pt.photos.length}}</div>
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col size="6" class="verticalSeparationLine">
                  <div class="table-header">
                    <div class="col">{{'CHECK_OUT_PHOTOS' | translate}}</div>
                  </div>
                  <div *ngFor="let pt of checkOutPhotoAttachments">
                    <div class="table-row">
                      <div class="col photo-card" (click)="openPhotoViewerForGPSActivity(pt, GPSActivity.CHECKOUT)">
                        <img class="thumbnail" io-thumbnail [src]="pt.photos[0].indskr_photourl" />
                        <div class="count-badge" *ngIf="pt.photos.length > 1"> {{pt.photos.length}}</div>
                      </div>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isMeetingObjectiveSelectionFAEnabled">
          <ind-section-header id="{{objectiveSectionId}}" [viewData]="objectiveSectionHeader"
            (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <div [ngClass]="{'events-container':!activityService.selectedActivity['activityObjectives']?.length}" lines="none">
            <ion-item *ngFor=" let objective of activityService.selectedActivity['activityObjectives']">
              <ion-label text-wrap>{{ objective.indskr_name}}</ion-label>
            </ion-item>
          </div>
        </ng-container>
        <meeting-structure id="{{meetingStSectionId}}" (openNewActivityPage)="openNewActivityPage()" [meetingStructureAccessedFrom]="'ActivitiesDetailsPanePage'" [backgroundUploadInProgress]="backgroundUploadInProgress"></meeting-structure>
        <shared-resources id="{{sharedResourcesSectionId}}" *ngIf="sharedResources && sharedResources?.length > 0" [isReadOnlyJointMeeting]="isReadOnlyJointMeeting" [activity]="activityService.selectedActivity">
        </shared-resources>
        <ng-container *ngIf="isProcedureSectionEnabled">
          <ind-section-header id="{{procedureSectionId}}" [viewData]="proceduresSectionHeader"
            (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <div [ngClass]="{'events-container':!(activityService.selectedActivity['activityProcedures']?.length)}" lines="none">
            <ion-item *ngFor=" let procedure of activityService.selectedActivity['activityProcedures']">
              <ion-label text-wrap>{{ procedure.name }}</ion-label>
            </ion-item>
          </div>
        </ng-container>
        <product-key-messages id="{{keyMessagesSectionId}}" (openKeyMessageSentiment)="openKeyMessageSentimentPage($event)" [isReadOnlyJointMeeting]="isReadOnlyJointMeeting" [backgroundUploadInProgress]="backgroundUploadInProgress" *ngIf="isKeyMessageSelectionEnabled && !authService.user.buConfigs?.indskr_disableproductsection" [isProductRequired]="requiredFields['product-key-messages']['isRequired'] && isNotReadOnly"></product-key-messages>

        <!-- Product Indications -->
        <reorder-items-list id="{{productIndicationSectionId}}" [ngClass]="{'notesemptysection':!isProductIndicationEnabled}" *ngIf="isProductIndicationEnabled && productIndicationSectionViewData" [viewData]="productIndicationSectionViewData" [selectedData]="selectedProductIndications" (saveItems)="onProductIndicationsSave($event)"></reorder-items-list>

        <!-- Therapeutic Areas -->
        <therapeutic-area-list id="{{therapeuticSectionId}}" [ngClass]="{'notesemptysection':!isTherapeuticAreaEnabled}" *ngIf="isTherapeuticAreaEnabled" (saveTA)="onSaveTA($event)" [therapeuticAreas]="therapeuticAreas" [isReadOnlyJointMeeting]="isReadOnlyJointMeeting"
            [backgroundUploadInProgress]="backgroundUploadInProgress" [isTARequired]="requiredFields['therapeutic-area']['isRequired'] && isNotReadOnly"></therapeutic-area-list>

        <!-- Disease Areas -->
        <reorder-items-list id="{{diseaseAreaSectionId}}" [ngClass]="{'notesemptysection':!isDiseaseAreaEnabled}" *ngIf="isDiseaseAreaEnabled && diseaseAreaSectionViewData" [viewData]="diseaseAreaSectionViewData" [selectedData]="selectedDiseaseAreas" (saveItems)="onDiseaseAreasSave($event)"></reorder-items-list>

        <ion-list *ngIf='hasMeetingEventsFA'>

          <ind-section-header id="{{meetingEventsSectionId}}" [viewData]="eventsSectionHeader"
                              (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <div [ngClass]="{'events-container':!this.applicableEvents.length}" lines="none">
            <ion-item *ngFor=" let event of applicableEvents">
              <ion-label text-wrap>{{ event.name}}</ion-label>
            </ion-item>
          </div>
        </ion-list>
            <ion-list *ngIf="isOppSectionEnabled">
            <!-- <ion-list *ngIf="true"> -->
              <ind-section-header id="{{meetingOpportunitiesSectionId}}" [viewData]="opportunitiesSectionTitle"
                (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
              <div [ngClass]="{'opportunities-container':!this.applicableOpportunities.length}" lines="none">
                <ion-item *ngFor=" let opportunities of applicableOpportunities">
                  <ion-label text-wrap>{{ opportunities.opportunityName ? opportunities.opportunityName : '' }}</ion-label>
                </ion-item>
              </div>
            </ion-list>
            <ion-list *ngIf="isMeetingAssetsEnabled">
              <ind-section-header id="{{meetingAssetsSectionId}}" [viewData]="assetsSectionTitle" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
              <div [ngClass]="{'opportunities-container':!applicableAssetsModel.length}" lines="none">
                <ng-container  *ngFor="let item of applicableAssetsModel">
                  <main-card [viewData]='item'></main-card>
                  <ind-hierarchy-list-item [hidden]="!item.isExpanded" [viewData]="item.childItems"></ind-hierarchy-list-item>
                </ng-container>
              </div>
            </ion-list>
        <ion-list *ngIf='isNotesEnabled'>

            <ind-section-header id="{{meetingNotesSectionId}}" [viewData]="notesSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
            <ion-item *ngIf="activityService.selectedActivity?.isCompleted || activityService.teamViewActive || activityService.selectedActivity?.isDiffPosition" class="notesemptysection" lines="none">
              <ind-text-area id="activity-detail-notes-attach" *ngIf="activityService.selectedActivity?.isCompleted || activityService.teamViewActive || backgroundUploadInProgress || activityService.selectedActivity?.isDiffPosition"
                             [readonly]="true" [maxLength]="1000"
                             [showAttachment]="isAttachmentAdded" [hasDownload]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" (removeAttachment)="removeAttachment($event)" (downloadNotesAttachment)="downloadAttachment($event)"
                             [(value)]="activityService.selectedActivity.notes" (indFocus)="onNotesFocus()" (indBlur)="saveNotes()" [rows]="4" [cols]="10">
              </ind-text-area>
            </ion-item>
            <ind-text-area id="activity-detail-11" *ngIf="!activityService.selectedActivity?.isCompleted && !activityService.teamViewActive && !activityService.selectedActivity?.isDiffPosition" [placeHolder]="'ENTER_NOTES' | translate" [disabled]="isReadOnlyJointMeeting || backgroundUploadInProgress" [maxLength]="1000"
               [showAttachment]="isAttachmentAdded" [hasDownload]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" (removeAttachment)="removeAttachment($event)" (downloadNotesAttachment)="downloadAttachment($event)"
                [(value)]="activityService.selectedActivity.notes" (indFocus)="onNotesFocus()" (indBlur)="saveNotes()" [rows]="4" [cols]="10">
            </ind-text-area>
            <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
        </ion-list>
        <ion-list *ngIf="isMeetingNotesEnabled">
            <ind-section-header id="{{meetingNotesAndAttachmentsSectionId}}" [viewData]="meetingNotesSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
            <ind-text-area id="activity-detail-11" [placeHolder]="'ENTER_NOTES' | translate" [readonly]="activityService.selectedActivity?.isCompleted"
                           [disabled]="backgroundUploadInProgress ? true : isCovisitor && (coVisitorAccess === 548910001 || coVisitorAccess === 548910002) ? false : isReadOnlyJointMeeting || activityService.teamViewActive || activityService.selectedActivity?.isDiffPosition" [maxLength]="1000"
                           [showAttachment]="isMeetingAttachmentAdded" [hasDownload]="isAttachmentAdded" [attachmentTitle]="meetingNoteAttachmentTitle" (removeAttachment)="removeMeetingNoteAttachment($event)" (downloadNotesAttachment)="downloadAttachment($event)"
                           (indChange)="notesChanged($event)" [value]="tempNoteText" [rows]="4" [cols]="10">
            </ind-text-area>
            <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
            <io-note *ngFor="let note of nonDeletedNotes" [isNoteAttachmentEnabled]="!backgroundUploadInProgress && isMeetingAttachmentEnabled" [inputNote]="note" [editable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)"
                     [readOnlyNote]="backgroundUploadInProgress || !isNoteControlsEnabled(note)" [readOnlyAttachment]="backgroundUploadInProgress || !isMeetingAttachmentEnabled || device.isOffline"
                     [deletable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)" (saveNoteOut)="updateNote($event)" [disableDownloadInOffline]="true" [from]="uiService.page.ActivitiesDetailsPaneComponent"></io-note>
        </ion-list>
        <ion-list id="activity-detail-12" *ngIf='isCoachingEnabled && !activityService.teamViewActive'>
            <!-- <ion-item-divider>
                {{'COACHING_CAP' | translate}}
            </ion-item-divider> -->
            <ind-section-header id="{{coachingDetailsSectionId}}" [viewData]="{id:'meeting-detail-coaching', title:translate.instant('COACHING')}"></ind-section-header>
            <ion-list *ngIf="coachingReportService.coachingReports.length == 0">
                <ion-item style="height: 88px;">
                </ion-item>
            </ion-list>
            <ion-list *ngIf="coachingReportService.coachingReports">
                <ion-item *ngFor="let report of coachingReportService.coachingReports | orderBy:'createdon':false" (click)="openCoachingReportDetails(report)">
                    <coaching-item [report]="report" [type]="'detailView'"></coaching-item>
                    <ion-icon class="arrow-icon" [ngClass]="{'disabled':enableCoachingView}" style="height:16px; width:16px; color:#007AFF" name="chevron-forward-outline" slot="end"></ion-icon>
                </ion-item>
            </ion-list>
        </ion-list>
        <next-call-objective id="{{callObjectiveSectionId}}" *ngIf="hasMeetingObjectives" [contacts]="selectedContacts" [isMeetingComplete]="activityService.selectedActivity.isCompleted" [backgroundUploadInProgress]="backgroundUploadInProgress"></next-call-objective>
    </ng-container>
</ion-content>
<!-- <footer-toolbar *ngIf="device.isMobileDevice" [footerView]="'detailView'" [selectedView]="'activities'"></footer-toolbar> -->
<!-- <ion-footer> -->
<footer-toolbar #footer *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'activities'" (buttonClicked)="onFooterButtonClicked($event)"></footer-toolbar>
<!-- </ion-footer> -->
<!-- <io-spinner *ngIf="!activityDataService.activityDetailsLoaded"></io-spinner> -->
