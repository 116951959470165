import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';

import { NavParams } from '@ionic/angular';
import { ActivityType } from '../../../classes/activity/activity.class';
import { CaseManagementDetailComponent } from '../case-management-detail/case-management-detail';
import { NewCaseManagementComponent } from '../new-case-management/new-case-management';
import { CaseManagementService, AccesingMode } from '../../../services/case-management/case-management.service';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the InMeetingAllocationComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'in-meeting-cases',
  templateUrl: 'in-meeting-cases.html',
  styleUrls:['in-meeting-cases.scss']
})
export class InMeetingCasesComponent {

  @ViewChild("caseForm", {static:true}) navCtrl: IonNav;
  public isViewCentered: boolean;
  private type:ActivityType;
  private view:string = '';
  public isFullscreen: boolean;

  constructor(
    private navParams: NavParams,
    private caseManagementService: CaseManagementService,
    private activityService: ActivityService
  ) {
    this.type = (this.navParams.data && this.navParams.data.type);
    this.view = (this.navParams.data && this.navParams.data.view)
  }
  ngOnInit() {

    switch (this.type) {
      case ActivityType.CaseIntake:
          if(this.view === 'new-case') {
            this.navCtrl.setRoot(NewCaseManagementComponent, {
              modalView: {},
              viewMode: 'modalView',
              isSemi: true
            });
          }
          else if(this.view === 'case-details') {
            this.navCtrl.setRoot(CaseManagementDetailComponent, {
              modalView: {},
              viewMode: 'modalView',
              isSemi: true
            });
          }
        break;

      default:
        break;
    }

    this.isFullscreen = this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION;
    this.isViewCentered = true;
  }

  ngOnDestroy() {
    this.activityService.activitySource = null;
  }

}
