import { CustomerAssetTransferNote } from '@omni/classes/field-materials/asset-notes.class';
import _ from 'lodash';

export class AssetTransfer {

  public indskr_assettransferid: string;
  public indskr_name: string;
  public indskr_acknowledged: boolean;
  public createdby: string;
  public indskr_approver: string;
  public indskr_assetname: string;
  public current_asset_owner: string;
  public current_asset_owner_name: string;
  public new_asset_owner: string;
  public new_asset_owner_name: string;
  public indskr_approvedoption: boolean;
  public indskr_customerconfirmation: number;
  public statuscode: number;
  public indskr_productcode: string;
  public indskr_typeoftransfer: number;
  public isLocationChange: boolean = false;
  public statuscode_Formatted: string;
  public asset_category: number;
  public asset_category_Formatted: string;
  public asset_status_Formatted: string;
  public asset_status: number;
  public current_asset_account: string;
  public current_asset_account_name: string;
  public new_asset_account: string;
  public new_asset_account_name: string;
  public new_location_startdate: string;
  public new_location_enddate: string;
  public createdon: string = '';
  public asset_id: string;
  public notes: CustomerAssetTransferNote[] = [];
  public _id: string;
  public _rev: string;

  constructor(raw) {
    this.indskr_assettransferid = raw['indskr_assettransferid'];
    this.indskr_name = raw['indskr_name'];
    this.new_asset_owner = raw['indskr_newassetowner_value'] || '';
    this.new_asset_owner_name = raw['indskr_newassetowner_value_Formatted'] || '';
    this.indskr_acknowledged = raw['indskr_acknowledged'];
    this.createdby = raw['createdby_value@OData.Community.Display.V1.FormattedValue'];
    this.indskr_approver = raw['indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    this.indskr_assetname = raw['indskr_assetname'];
    this.current_asset_owner = raw['indskr_currentassetowner_value'];
    this.current_asset_owner_name = raw['indskr_currentassetowner_value_Formatted'];
    this.indskr_approvedoption = raw['indskr_approvedoption'];
    this.indskr_customerconfirmation = raw['indskr_customerconfirmation'];
    this.statuscode = raw['statuscode'];
    this.indskr_productcode = raw['indskr_productcode_value@OData.Community.Display.V1.FormattedValue'];
    this.indskr_typeoftransfer = raw['indskr_typeoftransfer'];
    if (this.indskr_typeoftransfer == AssetTransferType.LOCATION) this.isLocationChange = true;
    this.statuscode_Formatted = raw['statuscode_Formatted'];
    this.current_asset_account = raw['indskr_currentlocation_value'] || '';
    this.current_asset_account_name = raw['indskr_currentlocation_value_Formatted'] || '';
    this.new_asset_account = raw['indskr_newlocation_value'] || '';
    this.new_asset_account_name = raw['indskr_newlocation_value_Formatted'] || '';
    this.asset_category = raw['indskr_assetcategory'];
    this.asset_category_Formatted = raw['indskr_assetcategory_Formatted'];
    this.asset_status = raw['indskr_assetstatus'];
    this.asset_status_Formatted = raw['indskr_assetstatus_Formatted'];
    this.asset_id = raw['indskr_customerasset_value'];
    this.notes = _.isEmpty(raw['notes']) ? [] : raw['notes'];
    try {
      if (raw.createdon) this.createdon = new Date(raw.createdon).getTime() + '';
      if (this.isLocationChange) {
        if (raw.indskr_newlocationstartdate) this.new_location_startdate = new Date(raw.indskr_newlocationstartdate).getTime() + '';
        if (raw.indskr_newlocationenddate) this.new_location_enddate = new Date(raw.indskr_newlocationenddate).getTime() + '';
      }
    } catch (error) {
      console.error('Error while parsing date : ', error)
    }
    this._id = raw['_id'];
    this._rev = raw['_rev'];
  }

}

export enum AssetTransferType {
  AM = 548910000,
  LOCATION = 548910001
}

export enum AssetTrasferStatus {
  Initiated = 1,
  Rejected = 548910001,
  Approved = 548910000,
  Completed = 548910002,
  Cancelled = 548910003,
  Inactive = 2
}
