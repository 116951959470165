import { PipeTransform, Pipe } from '@angular/core';
import { CallPlanOfflineService } from '../services/call-plan/call-plan.offline.service';
import { RepCallPlanState } from '../classes/call-plan/rep-call-plan.class';
import { AuthenticationService } from '../services/authentication.service';

import { AccountOfflineService } from '../services/account/account.offline.service';
import { BrandOfflineService } from '../services/brand/brand.service';

@Pipe({
    name: 'repCallPlanFilter',
    pure: false
})
export class RepCallPlanFilterPipe implements PipeTransform {
    constructor(
        private callPlanService: CallPlanOfflineService,
        private authenticationService: AuthenticationService,
        private accountService: AccountOfflineService,
    ) {

    }

    private stateSwitchFilter(items: Array<any>, status: string): Array<any> {
        //Status
        switch (status) {

            case RepCallPlanState.New.toString():
            items = items.filter(RepCallPlan => RepCallPlan.statuscode === RepCallPlanState.New);
            break;

            case RepCallPlanState.Open.toString():
            items = items.filter(RepCallPlan => RepCallPlan.statuscode === RepCallPlanState.Open);
            break;

            case RepCallPlanState.ForReview.toString():
            items = items.filter(RepCallPlan => RepCallPlan.statuscode === RepCallPlanState.ForReview);
            break;

            case RepCallPlanState.Approved.toString():
            items = items.filter(RepCallPlan => RepCallPlan.statuscode === RepCallPlanState.Approved);
            break;

            case RepCallPlanState.Locked.toString():
            items = items.filter(RepCallPlan => RepCallPlan.statuscode === RepCallPlanState.Locked);
            break;

            default: console.error('unhandled switch case for rep call plan filter pipe', status);
        }

        return items;
    }

    /**
     *  This is the pipe for filtering repCallPlan's in repCallPlanDetails
     *  Need to be able to perform a filter like 'all repCallPlan's that are complete AND status is x AND position is y'
     *
     * @param {Array<any>} items
     * @returns
     * @memberof RepCallPlanFilterPipe
     */
    transform(items: Array<any>) {
        if(!items) return;
        if (this.callPlanService.repCallPlanDetailFilterValue != 'all' || this.accountService.selectedAccounts.length != 0) {
            //Filter pretty much everything then, start with completed
            if (this.callPlanService.repCallPlanDetailNonCompletionFilter) items = items.filter(repCallPlan => repCallPlan.goalCalls != repCallPlan.actualCalls && repCallPlan.goalEmails != repCallPlan.actualEmails);
            if (this.callPlanService.repCallPlanDetailCompletionFilter) items = items.filter(repCallPlan => repCallPlan.goalCalls == repCallPlan.actualCalls && repCallPlan.goalEmails == repCallPlan.actualEmails);

            // if (this.callPlanService.repCallPlanDetailStatusFilterOpen) items = this.stateSwitchFilter(items, RepCallPlanState.Open.toString());
            // if (this.callPlanService.repCallPlanDetailStatusFilterApproved) items = this.stateSwitchFilter(items, RepCallPlanState.Approved.toString());
            // if (this.callPlanService.repCallPlanDetailStatusFilterForReview) items = this.stateSwitchFilter(items, RepCallPlanState.ForReview.toString());
            let filteredItems = [];
            filteredItems = filteredItems.concat(
                (this.callPlanService.repCallPlanDetailStatusFilterOpen ? this.stateSwitchFilter(items, RepCallPlanState.Open.toString()) : []),
                (this.callPlanService.repCallPlanDetailStatusFilterForReview ? this.stateSwitchFilter(items, RepCallPlanState.ForReview.toString()) : []),
                (this.callPlanService.repCallPlanDetailStatusFilterApproved ? this.stateSwitchFilter(items, RepCallPlanState.Approved.toString()) : [])
            );

            if (filteredItems && filteredItems.length > 0) {
                items = filteredItems;
            } else if (this.callPlanService.repCallPlanDetailStatusFilterOpen || this.callPlanService.repCallPlanDetailStatusFilterApproved || this.callPlanService.repCallPlanDetailStatusFilterForReview) {
                items = [];
            }

        // For Managers
        let positionNamesFiltered = this.authenticationService.childPositions.filter(position => position.selectedRepCallPlanFilter).map(position => position.childPosition);
        // For Non managers
        let positionNamesFilteredNonManagers = this.authenticationService.user.positions.filter(position => position.selectedRepCallPlanFilter).map(position => position.name);
        positionNamesFiltered.push(...positionNamesFilteredNonManagers);

        if (positionNamesFiltered && positionNamesFiltered.length > 0) {
            items = items.filter(repCallPlan => positionNamesFiltered.indexOf(repCallPlan.positionName) != -1);
        }

            //Accounts

            // if (this.accountService.selectedAccounts.length > 0) {
            //     let tempItems = [];
            //     //For each selected account
            //     this.accountService.selectedAccounts.map(selectedAccount => {

            //         //filter our items
            //         const filteredItems = items.filter(repCallPlan => {
            //             //If call plan has an account id included in our selected account list.
            //             const foundAccountID = repCallPlan.accountIDs.find(accountID => accountID == selectedAccount.id);
            //             if (foundAccountID) return true;
            //             return false;
            //         });

            //         tempItems = [...tempItems, ...filteredItems];
            //     });

            //     items = tempItems;
            // }
        }

        items.sort(function(a, b) {
            if(a.contactFirstName.toLowerCase() < b.contactFirstName.toLowerCase()) {
                return 1;
            }
            if(b.contactFirstName.toLowerCase() < a.contactFirstName.toLowerCase()) {
                return -1;
            }
        });

        //Sort by completion percent
        return items.sort((repCallPlanA, repCallPlanB) => {
            if (this.callPlanService.meetingOrEmailFilter == 'Engagements') {
                if ((repCallPlanA.indskr_meetingcompletionpercent || 0) > (repCallPlanB.callCompletedPercentage || 0)) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if ((repCallPlanA.emailCompletedPercentage || 0) > (repCallPlanB.emailCompletedPercentage || 0)) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });
    }
}
