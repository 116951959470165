import { Activity, ActivityType } from './activity.class';
import { SampleDetailInSampleActivity, SampleActivityDTO, LotDetailInSampleActivity } from '../../models/sample-model';
import { addMinutes, getDate, isAfter, isBefore, isFuture, isValid, subDays } from 'date-fns';
import { relativeTimeRounding } from 'moment';
import { Utility } from '../../utility/util';

export class SampleActivity extends Activity {
    activitytypecode: string;
    orderId: string;
    contactName: string;
    contactID: string;
    contactState: number
    orderDate: Date;
    signature: string;
    createdOffline: boolean = false;
    activityId: string;
    addressID: string;
    location : string
    samples: SampleDetailInSampleActivity[];
    appointmentID: string;
    actualStart: string;
    actualEnd: string;
    offlineActivityId: string;
    orderDateByUser: Date;

    constructor(raw: object) {
        super(raw);

        this.samples = [];
        this.contactID = raw['indskr_contactid'] || '';
        this.orderId = raw['indskr_orderid'] || '';
        this.contactName = raw['indskr_contactname'] || '';
        this.contactState = raw['contact_statecode']&&raw['indskr_contactid']?raw['contact_statecode']:0;
        this.location = raw['location'] || '';
        this.activitytypecode = 'indskr_sampledrop';
        this.signature = raw['indskr_signature'] || '';
        this.scheduledStart = new Date(parseInt(raw['scheduledstart']));
        this.scheduledEnd = addMinutes(this.scheduledStart, 30);
        this.orderDate = raw['actualend'] ? new Date(parseInt(raw['actualend'])) : this.scheduledStart;
        this.orderDateByUser = raw['indskr_utilizeddate'] ? new Date(parseInt(raw['indskr_utilizeddate'])) : this.scheduledStart;
        this.created = undefined;
        this.activityId = raw['activityid'] || raw['activityId'] || null;
        this.addressID = raw['indskr_customeraddress'] || '';
        this.location = raw['location']||'';
        this.appointmentID = raw['indskr_appointmentid'] || '';
        this.actualStart = raw['actualstart'];
        this.actualEnd = raw['actualend'] || '';
        this.offlineActivityId = raw['offlineActivityId'] || '';
        this.resetScheduledStartLocale();
        if (raw.hasOwnProperty('activitySampleDrops') && Array.isArray(raw['activitySampleDrops'])) {
            const orderDateByUser = new Date(this.orderDateByUser);
            raw['activitySampleDrops'].map((sampleSKU) => {
                sampleSKU.isInvalid = this.checkProductValidation(sampleSKU, orderDateByUser);
                this.samples.push(sampleSKU)
            });
        }
    }

    calculateLotsAndQuantitiesForSKU(samples:SampleDetailInSampleActivity[]){
        samples.map(o=>{
            if(!o.deleted && Array.isArray(o.lots)){
              let lotsCount = 0, totalQuant = 0;
              o.lots.map((p)=>{
                if(!p.deleted){

                  if(p['indskr_quantity'] && p['indskr_quantity'] > 0){
                    totalQuant += p.indskr_quantity
                    lotsCount++
                  }
                }
              })
              o.lotsCount = lotsCount
              o.totalQuantity = totalQuant;
            }
        })
        samples = samples.filter((o)=>{
            return !o.deleted
        })
        return samples;
    }

    checkProductValidation(data:SampleDetailInSampleActivity, orderDateByUser: Date, sampleLastDispatchLimitNum:number=0):boolean {
        // let flag = false;
        let isInvalidState = false;
        let isInvalidSampleDate = false;
        let isInvalidLotsDate =false;
        if(data.brand_statecode == 1 || data.customersample_statecode == 1 || data.sku_statecode == 1){
          // flag = true;
          // return flag;
          isInvalidState = true;
        }
        let tmpDate:Date = Utility.changeUTCDateToLocalDateWith0Time(parseInt(data.indskr_customersamplevalidtodate),true);
        if(data.indskr_customersamplevalidtodate && !isFuture(tmpDate)){
          // flag = true;
          // return flag;
          isInvalidSampleDate = true;
        }
        if(data.lots && data.lots.length > 0){
          data.lots.map(value=>{
            if(value.statecode == 1 || (!this._checkValidDateForActivityLot(value, orderDateByUser, sampleLastDispatchLimitNum))){
              // flag = true;
              // return flag;
              isInvalidLotsDate = true;
              if(isInvalidLotsDate) return;
            }
          })
        }
        // return flag;
        return (isInvalidState || isInvalidSampleDate || isInvalidLotsDate);
    }

    private _isDateForamt(date: Date): boolean {
      return date instanceof Date && !isNaN(date.getTime());
    }

    private _isTimestampFormat(str: string): boolean {
      // Check if the string is a valid timestamp (epoch time)
      const timestampRegex: RegExp = /^\d+$/;
    
      if (timestampRegex.test(str)) {
        const timestamp: number = Number(str);
        const tempDate: Date = new Date(timestamp);
    
        // Check if the timestamp is valid
        return !isNaN(timestamp) && this._isDateForamt(tempDate);
      }
    
      return false; // Not a valid timestamp format
    }

    private _checkValidDateForActivityLot(currentLot: LotDetailInSampleActivity, orderDateByUser: Date, sampleLastDispatchLimitNum:number=0): boolean {
      let isValidDate: boolean = false;
      const orderDate: Date = new Date(orderDateByUser);
      const today = new Date();
      const isOrderDateInFuture: boolean = isValid(orderDate) ? isAfter(new Date(orderDate),today) : false;

      if (currentLot.indskr_lastdispatchdate) {
        const isTimestamp: boolean = this._isTimestampFormat(currentLot.indskr_lastdispatchdate);
        if (isTimestamp) {
          const utcDate = new Date(parseInt(currentLot.indskr_lastdispatchdate));
          const formattedDate = new Date(utcDate.getUTCFullYear(),utcDate.getUTCMonth(),utcDate.getUTCDate(),23,59,59).toString() || '';
          const tmpDate = formattedDate;
          if (isOrderDateInFuture) {
            isValidDate = isBefore(orderDate,new Date(tmpDate));
          } else {
            isValidDate = isAfter(new Date(tmpDate),today);
          }
        } else {
          const tmpDate: Date = new Date(currentLot.indskr_lastdispatchdate);
          if (isValid(tmpDate)) {
            if (isOrderDateInFuture) {
              isValidDate = isBefore(orderDate,new Date(tmpDate));
            } else {
              isValidDate = isAfter(new Date(tmpDate),today);
            }
          }
        }
      } else if (sampleLastDispatchLimitNum > 0 && currentLot.indskr_lotvalidtodate) {
        let tmpDate: Date = Utility.changeUTCDateToLocalDateWith0Time(parseInt(currentLot.indskr_lotvalidtodate),true);
        const calculatedDateWithLastDispatch: Date = subDays(new Date(tmpDate), sampleLastDispatchLimitNum);
        if (calculatedDateWithLastDispatch && isValid(calculatedDateWithLastDispatch)) {
          if (isOrderDateInFuture) {
            isValidDate = isBefore(orderDate,new Date(calculatedDateWithLastDispatch));
          } else {
            isValidDate = isAfter(new Date(calculatedDateWithLastDispatch),today);
          }
      }
      } else if (currentLot.indskr_lotvalidtodate) {
        let tmpDate: Date = Utility.changeUTCDateToLocalDateWith0Time(parseInt(currentLot.indskr_lotvalidtodate),true);
        if (isOrderDateInFuture) {
          isValidDate = isBefore(orderDate,new Date(tmpDate));
        } else {
          isValidDate = isAfter(new Date(tmpDate),today);
        }
      }    
      return isValidDate;
    }

    get DTO(): Object {
        return {
            offlineActivityId: this.offlineActivityId || '',
            subject: this.subject || '',
            scheduledstart: '' + this.scheduledStart.getTime(),
            scheduledend: '' + this.scheduledEnd.getTime(),
            actualstart: '' + this.actualStart,
            actualend: '' + this.actualEnd,
            statecode: this.state || 0,
            statuscode: this.status || 1,
            indskr_contactid: this.contactID || "",
            indskr_contactname: this.contactName,
            indskr_orderid: this.orderId,
            indskr_customeraddress: this.addressID || "",
            indskr_ownerfullname: this.meetingOwner,
            indskr_ownerid:this.ownerId,
            contact_statecode: this.contactState,
            activityid: this.ID || '',
            indskr_signature: this.signature || '',
            indskr_appointmentid: this.appointmentID || '',
            activitySampleDrops: this.sampleSUKsDTO(),
            activitytypecode: "indskr_sampledrop",
            indskr_utilizeddate: '' + this.orderDateByUser.getTime() || '',
        };
    }

    private sampleSUKsDTO() {
        if (!this.samples || this.samples.length === 0) {
            return [];
        }
        else{
            return this.samples;
        }
    }
}
export class CreateSampleDropRequestBody{
    public subject: string;
    public scheduledstart?: string;
    public scheduledend?: string;
    public indskr_contactid?: string = '';
    public indskr_customeraddress?: string = '';
    public indskr_appointmentid?: string = '';
    public eventId?: string = '';
    public activityid: string = '';
    public actualstart: string = '';
    public actualend: string = '';
    public indskr_signature: string = '';
    public statecode: number = 0;
    public statuscode: number = 1;
    public offlineActivityId: string = '';
    public activitySampleDrops: Array<SampleDetailInSampleActivity> = [];
    public indskr_ownerid:string = '';
    contact_statecode: number = 0;
    public indskr_utilizeddate?: string;


    constructor(rawJSON: object){
        this.subject = rawJSON['subject'] || '';
        this.scheduledstart = (rawJSON['scheduledStart']).getTime();
        this.scheduledend = rawJSON['scheduledEnd']?(rawJSON['scheduledEnd']).getTime() : '';
        this.indskr_contactid = rawJSON['contactID'] || '';
        this.indskr_customeraddress = rawJSON['addressID'] || '';
        this.indskr_appointmentid = rawJSON['appointmentID'] || '';
        this.eventId = rawJSON['eventId'] || '';
        this.activityid = rawJSON['ID'] || '';
        this.actualstart = this.scheduledstart;
        this.actualend = rawJSON['actualEnd'] || '';
        this.indskr_signature = rawJSON['signature'] ||'';
        this.statecode = rawJSON['state'] || 0;
        this.statuscode = rawJSON['status'] || 1;
        this.activitySampleDrops = rawJSON['samples'] || [];
        this.offlineActivityId = rawJSON['offlineActivityId'] || '';
        this.contact_statecode = rawJSON['contactState'] || 0;
        this.indskr_utilizeddate = rawJSON['orderDateByUser'] ? (rawJSON['orderDateByUser']).getTime() : rawJSON['scheduledStart'] ? (rawJSON['scheduledStart']).getTime() : '';
    }
}
