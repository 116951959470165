import { PRODUCT_DISPLAY } from './../../../classes/activity/surgery-order.activity.class';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { OnInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OrderActivity, OrderProductClass } from '../../../classes/activity/order.activity.class';
import { EstimatedRevenueCalculationBasedOn, Opportunity, OpportunityProduct } from '../../../classes/opportunity-management/opportunity.class';
import { PriceList } from '../../../classes/order-management/price-list.class';
import { OpportunityManagementDataService } from '../../../data-services/opportunity-management/opportunity-management.data.service';
import { OperationDetail, OrderActivityDataService } from '../../../data-services/order-activity/order-activity.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import {DateTimeFormatsService} from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService } from '../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { OrderManagementService } from '../../../services/order-management/order-management.service';
import { UIService } from '../../../services/ui/ui.service';
import { Utility } from '../../../utility/util';
import { Quote } from './../../../classes/opportunity-management/opportunity.class';
import { BrandOfflineService } from './../../../services/brand/brand.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivityService } from './../../../services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import _ from 'lodash';
/**
 * Generated class for the OrderAddQuantityComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'order-add-quantity',
  templateUrl: 'order-add-quantity.html',
  styleUrls:['order-add-quantity.scss']
})
export class OrderAddQuantityComponent implements OnInit{

  public isRightPaneActive = false;
  public productsSearchStr = '';
  public elligiblePriceList: PriceList;
  public allProducts: Array<OrderProductClass> = [];
  public filteredProducts: Array<OrderProductClass> = [];
  public selectedProducts: Array<OrderProductClass> = [];
  public _selectedProduct: OrderProductClass;
  public enteredQuantity: number;
  public hardDisableAddButton = false;
  private isLayoverPushView = false;
  private layoverViewPushedFrom = '';
  public currentOrderActivity: OrderActivity;
  public currentOpportunity: Opportunity;
  public selectedProductCopy: OrderProductClass;
  private currentQuote: Quote;
  @Input() from: any;
  @Input() activity: any;
  @Input() opportunity: any;
  @Input() matchedPriceList: any;
  @Input() quote: any;
  @Input() onUpdate: any;
  @Input() selectedProduct: any;
  orderLineHeader: IndSectionHeaderViewDataModel;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  indHeaderRightModel: IndPageTitleViewDataModel;
  public backgroundUploadInProgress: boolean = false;
  private  ngDestroy$ = new Subject<boolean>();

  private isEstimatedRevenueCalculationBasedOnProcedureAndProducts:boolean = false;

  private isClickedQuantity: boolean = false;
  private isClickedUnitPrice: boolean = false;
  autoSubjectEnabled = false;

  constructor(
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    public readonly footerService: FooterService,
    public trackingService: TrackService,
    public orderManagementService: OrderManagementService,
    private readonly orderActivityService: OrderActivityDataService,
    public repService: RepServices,
    private readonly brandService: BrandOfflineService,
    private readonly _cd: ChangeDetectorRef,
    public translate: TranslateService,
    private readonly opportunityDataService: OpportunityManagementDataService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public modalCtrl: ModalController,
    private readonly activityService: ActivityService,
    private readonly authService: AuthenticationService,
    private readonly opportunityService: OpportunityManagementService,
    private readonly surgeryOrderDataService: SurgeryOrderActivityDataService,
  ) {
    this.initOrderProductsleftHeader();
  }

  ngOnInit() {
      if (this.from) {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = this.from;
      }
      if (this.activity) {
        this.currentOrderActivity = this.activity;
      }
      if (this.opportunity) {
        this.currentOpportunity = this.opportunity;
      }
      if(this.matchedPriceList){
        this.elligiblePriceList = this.matchedPriceList;
      }
      if (this.quote) {
        this.currentQuote = this.quote;
      }
      this.footerService.hideAllButtons();
      this.autoSubjectEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_AUTO_SUBJECT);

      if(this.opportunity && this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
        this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts = true;
        let selectedProdCategories;
        const selectedOppurtunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
        if (this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_BY_PRODUCTS)) {
          let products = !_.isEmpty(this.brandService?.brands) ? this.brandService?.brands : [];
          if (selectedOppurtunityType.indskr_productdisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
            products = products.filter(p => p.indskr_newproductintroduction);
          } else if (selectedOppurtunityType.indskr_productdisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
            products = products.filter(p => !p.indskr_newproductintroduction);
          }
          const brands = _.cloneDeep(_.sortBy(products, [function (o) { return o.name; }]));
          brands.forEach(prod => {
            let isSelected = false;
            if (this.opportunity && this.opportunity.products
              && this.opportunity.products.some(sopro => (sopro.productID === prod.ID))) {
              isSelected = true;
            }
            this.allProducts.push(new OrderProductClass({
              productId: prod.ID,
              productName: (prod.name) ? prod.name : '',
              total: 0,
              unitPrice: 0,
              formattedUnitPrice: (this.opportunity.currencySymbol ? this.opportunity.currencySymbol : '') + '0.00',
              quantity: 0,
              uomid: prod.defaultuomid || '',
            }));
          })
        }else{
          selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                                        .find(p=>p.surgeryId==this.opportunity.surgeryId)
                                        ?.productCategories.filter(c=>this.opportunity.productCategory.some(o=>o.productCategoryId==c.productCategoryId))
          selectedProdCategories.forEach(pro => {
            let categoryProducts = !_.isEmpty(pro.products) ? pro.products : [];
            if (selectedOppurtunityType.indskr_productdisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
              categoryProducts = categoryProducts.filter(p => p.indskr_newproductintroduction);
            } else if (selectedOppurtunityType.indskr_productdisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
              categoryProducts = categoryProducts.filter(p => !p.indskr_newproductintroduction);
            }
            categoryProducts.forEach(prod => {
              this.allProducts.push(new OrderProductClass({
                productId: prod.productId,
                productName: (prod.productName) ? prod.productName : '',
                total: 0,
                unitPrice: 0,
                formattedUnitPrice: (this.opportunity.currencySymbol ? this.opportunity.currencySymbol : '') + '0.00',
                quantity: 0,
                uomid: prod.uomid || '',
                parentProductID: prod.parentProductID || '',
                productCategoryID: prod.productCategoryId,
                productCategoryName: prod.productCategoryName,
              }));
            });
          });
        }
        this.filteredProducts = this.allProducts;
      }else if (this.elligiblePriceList && this.elligiblePriceList.products && this.elligiblePriceList.products.length > 0) {
      this.elligiblePriceList.products.forEach(product => {
        if(!product.productApplicabilityOnSalesOrder) return
        this.allProducts.push(new OrderProductClass({
          productId: product.productID,
          productName: (product.productName) ? product.productName : '',
          total: 0,
          unitPrice: (product.amount) ? product.amount : 0,
          formattedUnitPrice: (product.formattedAmount) ? product.formattedAmount : '',
          quantity: 0,
          uomid:product.uomid || '',
          parentProductID: product.parentProductID || ''
        }));
      });
      this.filteredProducts = this.allProducts.filter(p=>{
        return this.brandService.brands.some(b=> b.ID === p.productId || b.ID === p.parentProductID);
      });
    }
    // Add Selected Products From Current Order Activity
      if (this.currentOrderActivity && this.currentOrderActivity.products && this.currentOrderActivity.products.length > 0) {
      this.currentOrderActivity.products.forEach(product => {
        const idx = !product.isDeleted ? this.allProducts.findIndex(p => p.productId === product.productId) : -1;
        if (idx >= 0) {
          this.allProducts[idx].quantity = product.quantity;
          this.allProducts[idx].total = product.total;
          this.allProducts[idx].formattedTotal = product.formattedTotal;
          if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
            this.allProducts[idx].unitPrice = product.unitPrice;
            this.allProducts[idx].formattedUnitPrice = product.formattedUnitPrice;
          }
          this.selectedProducts.push(this.allProducts[idx]);
        } else {//Need to check whether we should still add this product or not

        }
      });
    }
    // Add Selected Products From Current Opportunity
      if (this.currentOpportunity && this.currentOpportunity.products && this.currentOpportunity.products.length > 0) {
      this.currentOpportunity.products.forEach(product => {
        const idx = !product.isDeleted ? this.allProducts.findIndex(p => p.productId === product.productID) : -1;
        if (idx >= 0) {

          this.allProducts[idx].quantity = product.quantity;
          this.allProducts[idx].total = product.total;
          this.allProducts[idx].formattedTotal = product.totalFormatted;
          if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
            this.allProducts[idx].unitPrice = product.unitPrice;
            this.allProducts[idx].formattedUnitPrice = product.unitPriceFormatted;
            this.allProducts[idx].total = product.quantity * product.unitPrice;
            this.allProducts[idx].formattedTotal  = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
            ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.allProducts[idx].total)
            : this.dateTimeFormatsService.getFormattedNumber(this.allProducts[idx].total) + this.transactionCurrencySymbol;
          }
          this.selectedProducts.push(this.allProducts[idx]);
        } else {
        }
      });
    }
    // Add Selected Products From Current Quote
      if (this.currentQuote && this.currentQuote.quoteProducts && this.currentQuote.quoteProducts.length > 0) {
      for (const product of this.currentQuote.quoteProducts) {
        const idx = !product.isDeleted ? this.allProducts.findIndex(p => p.productId === product.productID) : -1;
        if (idx >= 0) {

          this.allProducts[idx].quantity = product.quantity;
          this.allProducts[idx].total = product.total;
          this.allProducts[idx].formattedTotal = product.totalFormatted;
          this.selectedProducts.push(this.allProducts[idx]);
        } else {
        }
      }
    }
      if (this.selectedProduct && this.selectedProduct.productId) {
        this._selectedProduct = this.selectedProducts.find(product => product.productId === this.selectedProduct.productId);
        if(this._selectedProduct) {
          this.selectedProductCopy = JSON.parse(JSON.stringify(this._selectedProduct));
          if(!this.selectedProduct.formattedUnitPrice) this.selectedProduct.formattedUnitPrice = this._selectedProduct.formattedUnitPrice;
          this.isRightPaneActive = true;
        }
        this.setOrderLineHeader();
      }
      this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
        if (this.currentOrderActivity) {
          this.backgroundUploadInProgress = inProgress && this.currentOrderActivity.pendingPushToDynamics;
          this.initOrderProductsRightHeader();
        }
      });
      this.updateEmptyMessage();
  }

  updateEmptyMessage(){
    let dataSize = 0;
    if(this.filteredProducts && this.filteredProducts.length > 0){
      dataSize = this.filteredProducts.length;
    }
    else if(this.selectedProducts && this.selectedProducts.length > 0){
      dataSize = this.selectedProducts.length;
    }
    this.uiService.updateNothingSelectedScreenMessageFor(dataSize);
    this.initOrderProductsRightHeader()
  }

  public searchInput(ev): void {
    if(this.productsSearchStr.length>0){
      this.filteredProducts = this.allProducts.filter((p)=>{
        return this.brandService.brands.some(b=> b.ID === p.productId || b.ID === p.parentProductID)
              && p.productName.toLowerCase().indexOf(this.productsSearchStr.trim().toLowerCase())>-1
      });
    }
    else{
      this.filteredProducts = this.allProducts.filter(p=>{
        return this.brandService.brands.some(b=> b.ID === p.productId || b.ID === p.parentProductID);
      });
    }
    this.updateEmptyMessage();
  }

  public get isAddItemEnabled(): boolean {
    let flag = false;
    if(this.selectedProductCopy && this.selectedProductCopy.quantity !== this.selectedProduct?.quantity && this.selectedProductCopy.quantity >= 1 && !this.hardDisableAddButton){
      flag = true;
    }
    if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts && this.selectedProductCopy){
      if(this.selectedProductCopy.unitPrice !== this.selectedProduct?.unitPrice && this.selectedProductCopy.unitPrice >= 1){
        flag = true;
      }
    }
    return flag;
  }

  public openProductDetail(product: OrderProductClass): void {
    if(this.currentOpportunity) this.trackingService.tracking('SelectProduct', TrackingEventNames.OPPORTUNITYMANAGEMENT);
    if(product && product.productId){
      this.selectedProduct = this.allProducts.find(item => item.productId === product.productId);
      this.selectedProductCopy = JSON.parse(JSON.stringify(this.selectedProduct));
      if(!this.selectedProductCopy.quantity || !this.selectedProducts.some((prod)=>prod.productId===this.selectedProduct.productId)) {
        this.selectedProductCopy.quantity = 0;
        this.selectedProductCopy.total = 0;
        this.selectedProductCopy.formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
        ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total)
        : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total) + this.transactionCurrencySymbol;
        if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
          this.selectedProductCopy.unitPrice = 0;
          this.selectedProductCopy.formattedUnitPrice = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
          ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.unitPrice)
          : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.unitPrice) + this.transactionCurrencySymbol;
        }
      }

      if (this.selectedProduct){
        this.isClickedQuantity = false;
        this.isClickedUnitPrice = false;
        this.isRightPaneActive = true;
        this._cd.detectChanges();
      }
      this.setOrderLineHeader();
      this.initOrderProductsRightHeader()
    }
  }

  public async removeSelectedProduct(product: OrderProductClass) {
    if (this.backgroundUploadInProgress) return;
    const idx = this.selectedProducts.findIndex(p=> p.productId === product.productId);
    if(idx>=0){
      this.selectedProducts[idx].quantity = 0;
      this.selectedProducts[idx].total = 0;
      this.selectedProducts[idx].formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
      ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProducts[idx].total)
      : this.dateTimeFormatsService.getFormattedNumber(this.selectedProducts[idx].total) + this.transactionCurrencySymbol;
      this.selectedProducts.splice(idx,1);
    }
    this.selectedProduct = undefined;
    this.updateEmptyMessage();
    await this._updateProductsQuantitiesForCurrentEntity();
  }

  public get transactionCurrencySymbol(): string{
    let symbol = this.elligiblePriceList?this.elligiblePriceList.currencySymbol:'';
    if(this.currentOrderActivity && this.currentOrderActivity.currencySymbol){
      symbol = this.currentOrderActivity.currencySymbol;
    }else if(this.currentOpportunity && this.currentOpportunity.transactionCurrencySymbol){
      symbol = this.currentOpportunity.transactionCurrencySymbol;
    }
    return symbol;
  }

  public async addQuantityToProduct() {
    if(!this.selectedProduct || !this.isAddItemEnabled || this.backgroundUploadInProgress) return;
    if(this.currentOpportunity) this.trackingService.tracking('SelectAddItem', TrackingEventNames.OPPORTUNITYMANAGEMENT);
    this.selectedProduct.quantity = this.selectedProductCopy.quantity;
    if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
      this.selectedProduct.total = (this.selectedProductCopy.quantity * this.selectedProductCopy.unitPrice);
      this.selectedProduct.unitPrice = this.selectedProductCopy.unitPrice;
      this.selectedProduct.formattedUnitPrice = this.selectedProductCopy.formattedUnitPrice;
    }else{
      this.selectedProduct.total = (this.selectedProductCopy.quantity * this.selectedProduct.unitPrice);
    }
    this.selectedProduct.formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
      ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProduct.total)
      : this.dateTimeFormatsService.getFormattedNumber(this.selectedProduct.total) + this.transactionCurrencySymbol;
    const idx = this.selectedProducts.findIndex(p=> p.productId === this.selectedProduct.productId);
    if(idx<0){
      this.selectedProducts.push(this.selectedProduct);
    }else{
      this.selectedProducts[idx] = this.selectedProduct;
    }
    this.initOrderProductsRightHeader();
    await this._updateProductsQuantitiesForCurrentEntity();
    if(!this.isSplitView()){
      this.closeRightPane();
    }
  }

  public async handleQuantityEntered(ev){
    if(ev && parseInt(ev.value) && parseInt(ev.value) > 0){
      try {
        if(ev.value.includes('.')){
          this.hardDisableAddButton = true;
        }else{
          this.hardDisableAddButton = false;
        }
      } catch (error) {
        console.log('Error occured while checking entered quantity'+error);
      }
      this.selectedProductCopy.quantity = parseInt(ev.value);
      this.selectedProductCopy.total = this.selectedProductCopy.quantity * this.selectedProductCopy.unitPrice;
      this.selectedProductCopy.formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
        ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total)
        : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total) + this.transactionCurrencySymbol;
    }else {
      this.selectedProductCopy.quantity = 0;
      this.selectedProductCopy.total = this.selectedProductCopy.quantity * this.selectedProductCopy.unitPrice;
      this.selectedProductCopy.formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
        ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total)
        : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total) + this.transactionCurrencySymbol;
    }
    this.isClickedQuantity = true;
    this.initOrderProductsRightHeader();
    this._cd.detectChanges();
  }

  public async handleUnitPriceEntered(ev){
    if(ev && parseInt(ev.value) && parseInt(ev.value) > 0){
      try {
        if(ev.value.includes('.')){
          this.hardDisableAddButton = true;
        }else{
          this.hardDisableAddButton = false;
        }
      } catch (error) {
        console.log('Error occured while checking entered unit price'+error);
      }
      this.selectedProductCopy.unitPrice = parseInt(ev.value);
      this.selectedProductCopy.total = this.selectedProductCopy.quantity * this.selectedProductCopy.unitPrice;
      this.selectedProductCopy.formattedUnitPrice = this.selectedProductCopy.formattedUnitPrice = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
          ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.unitPrice)
          : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.unitPrice) + this.transactionCurrencySymbol;
      this.selectedProductCopy.formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
        ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total)
        : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total) + this.transactionCurrencySymbol;
    }else {
      this.selectedProductCopy.unitPrice = 0;
      this.selectedProductCopy.total = this.selectedProductCopy.quantity * this.selectedProductCopy.unitPrice;
      this.selectedProductCopy.formattedUnitPrice = this.selectedProductCopy.formattedUnitPrice = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
          ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.unitPrice)
          : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.unitPrice) + this.transactionCurrencySymbol;
      this.selectedProductCopy.formattedTotal = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
        ? this.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total)
        : this.dateTimeFormatsService.getFormattedNumber(this.selectedProductCopy.total) + this.transactionCurrencySymbol;
    }
    this.isClickedUnitPrice = true;
    this.initOrderProductsRightHeader();
    this._cd.detectChanges();
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  public closepage(): void {
    this.trackingService.tracking('TeamOrderViewProductBack', TrackingEventNames.ORDERMANAGEMENT);
    if (this.currentQuote) {
      this.modalCtrl.dismiss();
      return;
    }
    this.navService.popWithPageTracking();
  }

  public closeRightPane(): void {
    this.isRightPaneActive = false;
    this.selectedProduct = null;
    this.selectedProductCopy = null;
    this._cd.detectChanges();
  }

  private async _updateProductsQuantitiesForCurrentEntity(){
    if(this.currentOrderActivity){
      await this._updateProductsQuantitiesForCurrentOrder();
    }else if(this.currentOpportunity){
      await this._updateProductsQuantitiesForCurrentOpportunity();
    }
    else if (this.onUpdate) {
      return Promise.resolve(this.onUpdate(this.selectedProducts));
    }
  }

  private async _updateProductsQuantitiesForCurrentOrder(){
    this.currentOrderActivity.products = this.selectedProducts;
    this.currentOrderActivity.total = this.selectedProducts.map(product => product.total).reduce((a,b)=> (a-0)+(b-0),0);
    if(this.currentOrderActivity.elligibleDiscount){
      this.currentOrderActivity.total = parseFloat((this.currentOrderActivity.total*(1-(this.currentOrderActivity.elligibleDiscount/100))).toFixed(2));
    }
    const symbol = this.currentOrderActivity.currencySymbol?this.currentOrderActivity.currencySymbol:this.elligiblePriceList.currencySymbol;
    this.currentOrderActivity.formattedTotalAmount = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
      ? symbol + this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total)
      : this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total) + symbol;
    if(this.elligiblePriceList){
      this.currentOrderActivity.priceListId = this.elligiblePriceList.ID;
      this.currentOrderActivity.currencyId = this.elligiblePriceList.currencyID;
      if(this.elligiblePriceList.currencySymbol){
        this.currentOrderActivity.currencySymbol = this.elligiblePriceList.currencySymbol;
      }
    }
    this.currentOrderActivity.pendingPushToDynamics = true;
    const action: OperationDetail = {
      onDynamics:!this.device.isOffline,
      onLocalDatabase:true,
      onLocalCopy:true,
      operationDetail:{
        code:'ORDERPRODUCTADD',
        message:this.translate.instant('OM_UPDATE_PRODUCTS_QUANTITIES_FOR_AN_ORDER')
      }
    };
    this.uiService.displayLoader();
    await this.orderActivityService.updateOrderActivity(action,[this.currentOrderActivity],new Date().getTime()).then(succ=>{
      //Handle Successful response
    }).catch(error=>{
      //Handle error scenarios
    });
    this.uiService.dismissLoader();
  }

  private async _updateProductsQuantitiesForCurrentOpportunity(){
    this.currentOpportunity.products = this.selectedProducts.map(product => {
      let obj = {
        productID: product.productId,
        productName: product.productName,
        unitPrice: product.unitPrice,
        unitPriceFormatted: product.formattedUnitPrice,
        total: product.total,
        totalFormatted: product.formattedTotal,
        quantity: product.quantity,
        isDeleted:product.isDeleted,
        uomid: product.uomid,
      };
      if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
        obj['productCategoryID'] = product.productCategoryID;
        obj['productCategoryName'] = product.productCategoryName;
      }
      return obj;
    });
    if(this.currentOpportunity.isestimatedrevenuebasedonprocedureandproducts){
      this.currentOpportunity.estimatedValue = this.selectedProducts.map(product => product.total).reduce((a,b)=> (a-0)+(b-0),0);
      if(this.currentOpportunity.elligibleDiscount){
        this.currentOpportunity.estimatedValue = parseFloat((this.currentOpportunity.estimatedValue*(1-(this.currentOpportunity.elligibleDiscount/100))).toFixed(2));
      }
      if(!this.currentOpportunity.transactionCurrencyID && this.elligiblePriceList) this.currentOpportunity.transactionCurrencyID = this.elligiblePriceList.currencyID;
      const symbol = this.currentOpportunity.transactionCurrencySymbol?this.currentOpportunity.transactionCurrencySymbol:(this.elligiblePriceList?this.elligiblePriceList.currencySymbol:'');
      this.currentOpportunity.estimatedValueFormatted = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
        ? symbol + this.dateTimeFormatsService.getFormattedNumber(this.currentOpportunity.estimatedValue)
        : this.dateTimeFormatsService.getFormattedNumber(this.currentOpportunity.estimatedValue) + symbol;
    }
    if(this.elligiblePriceList){
      this.currentOpportunity.priceList = this.elligiblePriceList.ID;
      if(this.elligiblePriceList.currencySymbol && !this.currentOpportunity.transactionCurrencySymbol){
        this.currentOpportunity.transactionCurrencySymbol = this.elligiblePriceList.currencySymbol;
      }
    }
    this.currentOpportunity.pendingPushToDynamics = true;
    const action: OperationDetail = {
      onDynamics:!this.device.isOffline,
      onLocalDatabase:true,
      onLocalCopy:true,
      operationDetail:{
        code:'OPPORTUNITYPRODUCTADD',
        message:'Update products quantities for an opportunity'
      }
    };
    if(action.onDynamics){
      this.uiService.displayLoader();
    }
    if (this.autoSubjectEnabled) {
      this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
    }
    await this.opportunityDataService.updateOpportunity(action,[this.currentOpportunity]).then(succ=>{
      //Handle Successful response
    }).catch(error=>{
      //Handle error scenarios
    });
    this.uiService.dismissLoader();
  }

  setOrderLineHeader(){
    this.orderLineHeader = {
      id:'order_line_header',
      title: this.translate.instant('ORDER_LINE_CAP'),
      controls: []
    };
  }

  public getUnitPriceFormField(): IndFormFieldViewDataModel{
    let viewData: IndFormFieldViewDataModel;
    if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
      viewData = {
        label:this.translate.instant('NET_REVENUE'),
        inputText: this.dateTimeFormatsService.formattedCurrency(this.selectedProductCopy.formattedUnitPrice),
        inputType: 'number',
        inputValue: (this.selectedProduct.unitPrice)?this.selectedProductCopy.unitPrice:0,
        id:'unit-price-field',
        isReadOnly: false,
        isDisabled: this.backgroundUploadInProgress,
        customPlaceholderLabel:this.translate.instant('ENTER_NET_REVENUE'),
        showArrow: true,
        formFieldType:FormFieldType.INLINE_INPUT,
        isRequired: false,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isClickedUnitPrice && this.selectedProductCopy?.unitPrice<1,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      };
    }else{
      viewData = {
        label: this.translate.instant('OM_UNIT_PRICE'),
        inputText: this.dateTimeFormatsService.formattedCurrency(this.selectedProduct.formattedUnitPrice),
        id: 'unit-price-field',
        isReadOnly: true,
        showArrow: false
      };
    }
    return viewData;
  }

  public getQuantityFormField(): IndFormFieldViewDataModel{
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: (this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts)? this.translate.instant('NO_OF_PROCEDURES'):this.translate.instant('QUANTITY'),
      inputText: ((this.selectedProductCopy && this.selectedProductCopy.quantity) ? this.selectedProductCopy.quantity + '' : '0'),
      inputType: 'number',
      inputValue: ((this.selectedProductCopy && this.selectedProductCopy.quantity) ? this.selectedProductCopy.quantity : 0),
      id: 'quantity-field',
      isReadOnly: false,
      isDisabled: this.backgroundUploadInProgress,
      customPlaceholderLabel: (this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts)? this.translate.instant('ENTER_NO_OF_PROCEDURES'):this.translate.instant('ENTER_QUANTITY'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedQuantity && this.selectedProductCopy?.quantity<1,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getTotalFormField(): IndFormFieldViewDataModel{
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: (this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts)? this.translate.instant('TOTAL_REVENUE') : this.translate.instant('TOTAL'),
      inputText: this.dateTimeFormatsService.formattedCurrency(this.selectedProductCopy.formattedTotal),
      id: 'total-field',
      isReadOnly: true,
      showArrow: false
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (this.backgroundUploadInProgress) return;
    if (id && eventName === 'input_value_confirm') {
      if(id === 'quantity-field'){
        this.handleQuantityEntered(event.target);
      }else if(id == 'unit-price-field'){
        if(this.isEstimatedRevenueCalculationBasedOnProcedureAndProducts){
          this.handleUnitPriceEntered(event.target);
        }
      }

    }
  }

  private initOrderProductsleftHeader(): void {
    let buttons = [];
    buttons.push({
        id: "closepage",
        imgSrc: 'assets/imgs/header_cancel.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
    });
    this.indHeaderLeftModel = {
      id: 'ordersproducts-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('PRODUCTS'),
      mode: false,
      customHeaderProps:{hasCancel:true},
      controls: buttons,
    };
  }
  private initOrderProductsRightHeader(): void {
    let buttons = [];
    buttons.push(
      {
        id:'addProduct',
        imgSrc: this.selectedProducts.some(o=> o.productId == this.selectedProduct?.productId)?'assets/imgs/update.svg':'assets/imgs/ios_add_3x.svg',
        name:this.translate.instant(this.selectedProducts.some(o=> o.productId == this.selectedProduct?.productId) ? 'UPDATE_LINE':'ADD_LINE'),
        isDisabled: this.backgroundUploadInProgress || !this.isAddItemEnabled,
        align:'right'
      },
    )
    this.indHeaderRightModel = {
      id: 'ordersproducts-list-header-left',
      title: this.selectedProduct?this.selectedProduct.productName:'',
      controls: buttons,
    };
  }
  onPageTitleControlClick(id){
    if(id==='closepage'){
      this.closepage();
    }
    if(id==='close'){
      this.closeRightPane();
    }
    if(id==='addProduct'){
      this.addQuantityToProduct();
    }
  }
}
