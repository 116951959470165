import { Pipe, PipeTransform } from '@angular/core';
import { ScientificActivityPlan } from '../classes/scientific-activity/scientific-activity.class';

/**
 * Generated class for the SchedulerPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'sapFilter',
  pure: true
})
export class ScientificActivityPlanFilter implements PipeTransform {

  transform(sapList: Array<ScientificActivityPlan>, therapeuticFilter: any[] = [], productFilter: any[] = []) {

    // let filteredSapList

    if((!productFilter || productFilter.length <= 0) && (!therapeuticFilter || therapeuticFilter.length <= 0)) return sapList;

    return sapList.filter(sap => {
        let productMatched = false;
        let therapeuticMatched = false;

        if(productFilter && productFilter.length > 0) {
            productMatched = sap.products.some(el => {
                if(productFilter.some(x => x == el.ID)) return true;

                return false;
            });
        }else{
          productMatched = true;
        }

        if(therapeuticFilter && therapeuticFilter.length > 0) {
            therapeuticMatched = sap.therapeuticAreaList.some(el => {
                if(therapeuticFilter.some(x => x == el.therapeuticareaId)) return true;

                return false;
            });
        }else{
          therapeuticMatched = true;
        }

        return productMatched && therapeuticMatched;
    });
  }
}
