import { EventsService } from './../../../services/events/events.service';
import { Component, Input, ViewChild, HostListener, ElementRef, NgZone, Renderer2, EventEmitter, Output, Injector } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser';
import { IoFileService } from '../../../services/io-file-service/io-file-service';
import { DeviceService, DEVICE_ORIENTATION } from '../../../services/device/device.service';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { PresentationView, UIService } from '../../../services/ui/ui.service';
import { Subject, Subscription } from 'rxjs';
import { Presentation, Page, ContentMode, SwipeStatus } from '../../../classes/presentation/presentation.class';
import { WebsocketDataService } from '../../../data-services/websocket/websocket.data.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { DiskService } from '../../../services/disk/disk.service';
import { MeetingDataService } from '../../../data-services/meeting/meeting.data.service';
import { PollService } from '../../../services/poll/poll.service';
import { IoEventListener } from '../../../services/io-event-listener.decorator';
import { DomController } from '@ionic/angular';
import { Resource } from '../../../classes/resource/resource.class';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { takeUntil } from 'rxjs/operators';


/**
 * @export
 * @class IOIframe
 */
@Component({
  selector: 'io-iframe',
  templateUrl: 'io-iframe.html',
  styleUrls: ['io-iframe.scss']
})

export class IOIframe {

  @Input() viewMode: PresentationView
  private presViewMode = PresentationView;

  public _activeContent: Presentation | Resource;
  public link: string;

  private _touchStartX: number;
  private _touchStartY: number;

  private contentHeight: number = 768;
  private contentWidth: number = 1024;
  private frameHeight: number = 768;
  private frameWidth: number = 1024;

  public iframeOrigin: string = "0";

  private contentScaleFactor: number;

  private contentWrapperSize: number;
  private contentWrapperMargin: number;
  private alignVertical = true;
  public isSwipeEnable: boolean;
  

  //Subscription
  private _curSelPresSubscription: Subscription;
  private _curSelPresPageSubscription: Subscription;
  private _screenOrientation: Subscription;
  private presMeetingPlayClickedSub: { unsubscribe: Function };

  private orientation = 0;
  public deafultPdfPage: number = 1;

  private isPDFPresentation = false;
  public pdfPresentationPageNum: number = 1;
  private resizeTimer: NodeJS.Timeout = null;
  private isVirtualEvent: boolean = true;

  @ViewChild('iframeContainer') iframeContainer: ElementRef;
  @ViewChild('imageContainer') imageContainer: ElementRef;
  @ViewChild('imageTempContainer') imageTempContainer: ElementRef;
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChild('iframeWrapper') iframeWrapper: HTMLDivElement;
  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;
  @ViewChild('pdfPresentationViewer') pdfPresentationViewer: PdfViewerComponent;
  @ViewChild('swipeLayer') swipeLayer: ElementRef;
  iframeMessagePostListener: { unsubscribe: Function; };
  windowMessageListener: { unsubscribe: Function; };

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
  public initScreenRatio: boolean = true;
  public swipeStatus = SwipeStatus;
  swipeStatusSub: Subscription;
  private swipeInProgress: boolean = false;
  private delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  @Output() pdfPageChange = new EventEmitter<any>();
  private ngDestroy$: Subject<boolean> = new Subject<boolean>();

  public _isContentResponsive: boolean = true;

  public get wrapperStyle() {
    const style: any = {}
    if (!this.contentWrapperSize) {
      style.margin = '0px 30px';
    } else if (this.alignVertical) {
      style.marginTop = `${this.contentWrapperMargin}px`;
      style.height = `${this.contentWrapperSize}px`;
    } else {
      style.marginLeft = `${this.contentWrapperMargin}px`;
      style.width = `${this.contentWrapperSize}px`;
    }
    return style;
  }

  public get frameStyle() {
    const style: any = {}
    if (this.frameHeight) {
      style.height = `${this.frameHeight}px`;
    }
    if (this.frameWidth) {
      style.width = `${this.frameWidth}px`;
    }
    if (this.contentScaleFactor === 0 || this.contentScaleFactor) {
      style.transform = `scale(${this.contentScaleFactor})`
    }
    if (this.assetType === 'pdfPresentationViewer') {
      style.marginLeft = 'auto'
      style.marginRight = 'auto'
    }
    return style;
  }

  constructor(
    public sanitizer: DomSanitizer,
    private ioFile: IoFileService,
    private deviceService: DeviceService,
    private events: EventsService,
    public presentationService: PresentationService,
    public websocket: WebsocketDataService,
    public activityService: ActivityService,
    private _dom: DomController,
    public el: ElementRef,
    private _zone: NgZone,
    public disk: DiskService,
    pollService: PollService,
    public meeting: MeetingDataService,
    private renderer: Renderer2,
    public injector: Injector,
    private uiService: UIService,
  ) {
    pollService.init();
    this._curSelPresSubscription = this.presentationService.currentSelectedPres.subscribe(content => {
      this._activeContent = content;
      if (this._activeContent) {
        if (this._activeContent instanceof Presentation) {
          const presentation: Presentation = <Presentation>this._activeContent;
          this.contentHeight = presentation.height;
          this.contentWidth = presentation.width;
          this._isContentResponsive = presentation.isResponsive;
        } else {
          this._isContentResponsive = false;
          setTimeout(() => {
            this.handleVideoResourceEvents();
          }, 100);
        }
      }
    });

    this._screenOrientation = this.deviceService.screenOrientation.subscribe((orientation: number) => {
      this.orientation = orientation;
      this.resizeIframe();
    });

    // Component change detection doesn't work properly with current setup.
    // Thus, manually updating css to enable / disable swipe layer instead of using *ngIf.
    this.swipeStatusSub = this.presentationService.swipeStatusObservable.subscribe(status => {
      if (this.swipeLayer) {
        if (status === this.swipeStatus.ENABLED_BY_USER || status === this.swipeStatus.ENABLED_DEFAULT) {
          // Swipe enabled
          this.renderer.removeClass(this.swipeLayer.nativeElement, 'swipe-layer-disabled');
        } else {
          // Swipe disabled
          this.renderer.addClass(this.swipeLayer.nativeElement, 'swipe-layer-disabled');
        }
      }
    });
  }

  ngOnInit() {
    console.log(this.deviceService.deviceInfo.platform);
    this.isSwipeEnable = this.deviceService.deviceInfo.platform == "Web" ? false:true;
    this.presMeetingPlayClickedSub = this.events.subscribe('presentation-meeting::play-clicked', () => {
      //Play btn pressed
      //Find the htmlelement via document.getelementbyid and access contentwindow to send msg
      let contentIFrameElement: any = document.getElementById('content-iframe');
      if (contentIFrameElement) {
        let message = { type: 'play' };
        //This sends our message to the local content.
        contentIFrameElement.contentWindow.postMessage(message, '*');
      }
      /*if (this._activeContent instanceof Resource && this._activeContent.assetType === 'video' && this.videoContainer) {
        const videoElement = this.videoContainer.nativeElement as HTMLVideoElement;
        videoElement.play();
      }*/
    })[0];

    if (this._activeContent && this._activeContent instanceof Presentation) {
      const presentation: Presentation = <Presentation>this._activeContent;
      this.contentHeight = presentation.height;
      this.contentWidth = presentation.width;
      this._isContentResponsive = presentation.isResponsive;
    }

    this.iframeMessagePostListener = this.events.subscribe('iframe:message', message => {
      if (this.iframeContainer && this.iframeContainer.nativeElement) {
        (this.iframeContainer.nativeElement as HTMLIFrameElement).contentWindow.postMessage(message, "*");
      }
    })[0];
    this.windowMessageListener = this.events.subscribe('window:message', message => this.onWindowMessage(message))[0];

    this.events.observe("remote_meeting_participant_Joined_left").pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if (data['joinedStatus'] === 'joined' && this.videoContainer) {
        const videoElement = this.videoContainer.nativeElement as HTMLVideoElement;
        let payload = {
          type: videoElement.paused ? 'pause' : 'play',
          currentTime: videoElement.currentTime,
          muted: videoElement.muted,
          fullscreen: window.innerHeight == screen.height,
          playbackRate: videoElement.playbackRate,
          volume: videoElement.volume
        }
        this.websocket.shareVideoResourceEvents(this.activityService.selectedActivity, payload);
      }
    });

    this.events.observe("presentation-meeting::pause-clicked").pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      if (this._activeContent instanceof Resource && this._activeContent.assetType === 'video' && this.videoContainer) {
        const videoElement = this.videoContainer.nativeElement as HTMLVideoElement;
        videoElement.pause();
      }
    });

    this.events.observe("pauseResourceVideo").pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      if (this._activeContent instanceof Resource && this._activeContent.assetType === 'video' && this.videoContainer) {
        const videoElement = this.videoContainer.nativeElement as HTMLVideoElement;
        videoElement.pause();
        this.presentationService.videoResourceCurrentTime = videoElement.currentTime;
      }
    });

    this.events.observe("pdf-page-change-from-carousel").pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.isVirtualEvent = false;
    });
  }

  handleVideoResourceEvents() {
    if (this.videoContainer && this.presentationService.viewMode == PresentationView.MEETING) {
      const videoElement = this.videoContainer.nativeElement as HTMLVideoElement;
      if (this.presentationService.videoResourceCurrentTime) {
        videoElement.currentTime = this.presentationService.videoResourceCurrentTime;
      }
       /*
      Standard syntax - fullscreenchange,
      Firefox - mozfullscreenchange,
      Chrome, Safari and Opera - webkitfullscreenchange
      IE / Edge - msfullscreenchange
      */
      ['play', 'pause', 'ratechange', 'volumechange', 'fullscreenchange', 'mozfullscreenchange', 'webkitfullscreenchange', 'msfullscreenchange'].forEach(type => {
        videoElement.addEventListener(type, (event) => {
          let payload = {
            type: event.type,
            currentTime: event.target['currentTime'],
            muted: event.target['muted'],
            fullscreen: window.innerHeight == screen.height,
            playbackRate: event.target['playbackRate'],
            volume: event.target['volume']
          }
          this.websocket.shareVideoResourceEvents(this.activityService.selectedActivity, payload);
        });
      });
    }
  }

  public initLoadCompleted(event) {
    if (this.pdfViewer) {
      setTimeout(() => {
        try {
          const iframe = document.getElementsByTagName('iframe')[0];
          if (iframe) {
            if (window.frames[0].document.getElementById("pageNumber"))
              window.frames[0].document.getElementById("pageNumber").classList.remove("hiddenSmallView");
            if (window.frames[0].document.getElementById("numPages"))
              window.frames[0].document.getElementById("numPages").classList.remove("hiddenSmallView");
          }
        } catch (err) {
          console.error(err);
        }
      }, 0);
    }
  }

  injectSlidePageConfig(iframe: HTMLIFrameElement) {
    const message = {
      type: 'io-spa',
      action: 'init',
      configs: {
        disableNavigation: true
      }
    };
    if (iframe) {
      iframe.contentWindow.postMessage(message, "*");
    }
  }

  onPageChange(page) {
    this.pdfPageChange.emit(page);
  }
  onWindowMessage(message: { type: string; action: string; data }) {
    switch (message.type) {
      //Check if its a page-change event
      case "page-change":
        console.log("Carousel jumping event; ", message);

        let newPage: Page = this.presentationService.presPages.find(
          page => page.pageUrl == message.data
        );
        console.log("Found page to jump to", newPage);
        newPage.cameFromCarousel = true;

        //Upload events before
        // this.meeting.uploadInteractiveHTMLEvent();

        this.presentationService.setCurrentSelectedPresPage(newPage);
        return;
      case "ihtml-event":
        // console.log("Received a ihtml tracking message", message);

        //Append our slide ID, presentation ID & activity ID then save to disk.

        // let htmlEvent = new InteractiveHTMLEvent(
        //     new Date(),
        //     message.data,
        //     InteractiveHTMLEventEnum.TRACK,
        //     {
        //         activity: this.activityService.selectedActivity.ID,
        //         presentation: this.presentationService.activePresentation
        //             .ioPresentationId,
        //         slide: `${this.presentationService.activePresPage.id}`
        //     }
        // );

        // //Regardless of the network status we are going to save it offline and upload the whole batch later
        // this.disk.saveIHTMLEvent(htmlEvent);

        //Online
        // this.meeting.uploadInteractiveHTMLEvent(htmlEvent);
        return;
    }

    //The data is a json string which we need to send to HCP
    // if (this.activityService.selectedActivity) {
    //     this.websocket.sendMessageToTopic(
    //         Endpoints.websockets.END_REMOTE_MEETING.replace(
    //             "{activityId}",
    //             this.activityService.selectedActivity.ID
    //         ),
    //         { ihtml: message.data },
    //         true
    //     );
    // }
  }

  ngDoCheck() {
    if (this._activeContent && this._activeContent instanceof Resource && this.initScreenRatio) {
      if (this.assetType) {
        if (this.assetType === 'pdf' && this.pdfViewer) {
          this.setRatio(768, 1024);
          this.pdfViewer.pdfSrc = this.link;
          this.pdfViewer.page = 1;
          this.pdfViewer.refresh();
        } else if (this.assetType === 'image' && this.imageTempContainer && this.imageTempContainer.nativeElement && (this.imageTempContainer.nativeElement as HTMLImageElement).height > 0) {
          const element = this.imageTempContainer.nativeElement as HTMLImageElement;
          this.setRatio(element.height, element.width);
        } else if (this.assetType === 'video' && this.videoContainer && this.videoContainer.nativeElement && (this.videoContainer.nativeElement as HTMLVideoElement).videoHeight > 0) {
          const element = this.videoContainer.nativeElement as HTMLVideoElement;
          this.setRatio(element.videoHeight, element.videoWidth);
        }
      }
    }
  }
  setRatio(height, width) {
    this.contentHeight = height;
    this.contentWidth = width;
    this.resizeIframe();
    this.initScreenRatio = false;
  }

  ngOnDestroy() {
    if (this.presMeetingPlayClickedSub)
      this.presMeetingPlayClickedSub.unsubscribe();
    if (this.iframeMessagePostListener)
      this.iframeMessagePostListener.unsubscribe();
    if (this.windowMessageListener)
      this.windowMessageListener.unsubscribe();
    if (this._curSelPresSubscription)
      this._curSelPresSubscription.unsubscribe();
    if (this._curSelPresPageSubscription)
      this._curSelPresPageSubscription.unsubscribe();
    if (this._screenOrientation)
      this._screenOrientation.unsubscribe();
    if (this.swipeStatusSub) this.swipeStatusSub.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeIframe();
  }

  @IoEventListener("carousel:iframe-resize")
  resizeIframe(isLandscape?: boolean) {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null;
      let containerWidth = this.el.nativeElement.clientWidth;
      let containerHeight = this.el.nativeElement.clientHeight;
      const isPoll = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/poll\/index\.html$/ig.test(this.link || '')
      let resizeFrame = () => {
        this.contentScaleFactor = undefined
        this.contentWrapperMargin = undefined
        this.alignVertical = false
        this.contentWrapperSize = undefined
        this.frameWidth = undefined;
        this.frameHeight = undefined;
        if (this._isContentResponsive && !isPoll) return;
        if (this.el.nativeElement.clientWidth === 0) return;
        if (this.orientation === DEVICE_ORIENTATION["landscape-primary"] || this.orientation === DEVICE_ORIENTATION["landscape-secondary"]) {
          isLandscape = true;
        }

        containerWidth = this.el.nativeElement.clientWidth;
        containerHeight = this.el.nativeElement.clientHeight;
        // let heightPer = window.innerHeight > 660? 0.75 : 0.65;


        const containerAspectRatio = containerWidth / containerHeight;

        this.frameWidth = this.contentWidth;
        this.frameHeight = this.contentHeight;

        // if (isPoll && this.contentWidth < 1024) {
        //   this.frameWidth = 1024
        // }
        const contentAspectRatio = this.frameWidth / this.frameHeight;
        let scaleFactor = 1;
        let contentWrapperSize: number
        if (containerAspectRatio > contentAspectRatio) {
          scaleFactor = containerHeight / this.frameHeight;
          contentWrapperSize = scaleFactor * this.frameWidth;
          this.contentWrapperMargin = (containerWidth - contentWrapperSize) / 2;
          if (isPoll && this.contentWrapperMargin > containerWidth / 4) {
            this.contentWrapperMargin = Math.round(containerWidth / 4)
            contentWrapperSize = containerWidth - (this.contentWrapperMargin * 2)
            scaleFactor = contentWrapperSize / this.frameWidth
            this.frameHeight = containerHeight / scaleFactor
          }
        } else {
          this.alignVertical = true
          scaleFactor = containerWidth / this.frameWidth;
          contentWrapperSize = scaleFactor * this.frameHeight;
          this.contentWrapperMargin = (containerHeight - contentWrapperSize) / 2;
          if (isPoll && this.contentWrapperMargin > containerHeight / 4) {
            this.contentWrapperMargin = Math.round(containerHeight / 4)
            contentWrapperSize = containerHeight - (this.contentWrapperMargin * 2)
            scaleFactor = contentWrapperSize / this.frameHeight
            this.frameWidth = containerWidth / scaleFactor
          }
        }
        this.contentWrapperSize = contentWrapperSize;
        if (scaleFactor !== 1) {
          this.contentScaleFactor = scaleFactor
        }
      }
      this._dom.read(() => this._zone.run(resizeFrame));
      this._dom.read(() => {
        if (containerWidth == this.el.nativeElement.clientWidth && containerHeight == this.el.nativeElement.clientHeight) return;
        this._zone.run(resizeFrame);
      });
      setTimeout(() => {
        this.pdfPresentationViewer?.updateSize();
      }, 100);
    }, 100);
  }


  /**
   * @memberof IOIframe
   */
  @Input('selectedPageUrl')
  set setPresURL(url: string) {
    let fileExtSize = 5;

    if (url.includes(".pdf#")) {
      this.isPDFPresentation = true;
      fileExtSize = 4;
      if (url && this.isNewPDFFile(url)) {
        // this.uiService.displayLoader();
        if (this.deviceService.isOffline) {
          this.link = this.ioFile.getLocalURL(this.getRelativePath(url.substring(0, url.lastIndexOf("#")), fileExtSize));
        } else {
          this.link = url.substring(0, url.lastIndexOf("#"));
        }
      }
      this.pdfPresentationPageNum = Number(url.substring(url.lastIndexOf("#") + 1)) + 1;
      // this.pdfPresentationPageNum = Number(url.substring(url.lastIndexOf("#") + 1));

    }
    else {
      this.isPDFPresentation = false;
      this.link = url ? url : "";
      if (this.deviceService.isOffline && this._activeContent && this._activeContent.downloaded) {
        if (!this.link) {
          return;
        }
        if (this._activeContent instanceof Presentation) {
          this.link = this.ioFile.getLocalURL(this.getRelativePath(this.link, fileExtSize));
        } else {
          const file: string = this.link.substr(this.link.lastIndexOf('/') + 1);
          this.link = this.ioFile.getLocalURLForResource((<Resource>this._activeContent).page.id, file);
        }
      }
    }
    setTimeout(() => {
      this.initScreenRatio = true;
    }, 50);
    this.resizeIframe();
  }

  getRelativePath = (str, n) => {
    let L = str.length, i = -1;
    while (n-- && i++ < L) {
      i = str.indexOf("/", i);
      if (i < 0) break;
    }
    return str.substring(i + 1, str.length);
  }

  public async changePage(num) {
    if (await this.presentationService.canChangeSlideOrPresentation()) {
    this.presentationService.changePageBySequence(num);
    //to detected non-tracing events on pdf
    this.events.publish("pdf-page-change-from-carousel");
    }
  }

  public async swipe(action) {
    if (!this.swipeInProgress) {
      this.swipeInProgress = true;
      if (action === this.SWIPE_ACTION.LEFT) {
        this.changePage(1);
      }
      else if (action === this.SWIPE_ACTION.RIGHT) {
        this.changePage(-1);
      }
      await this.delay(500);
      this.swipeInProgress = false;
    }
    
    return;
  }

  private isNewPDFFile(url: string) {
    return this.link ? this.link.substr(this.link.lastIndexOf("/") + 1) !== url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("#")) : true;
  }

  public afterPDFLoaded() {
    console.log("afterPDFLoaded ====>");
    this.uiService.dismissLoader();
  }

  public pageRendered(e: any) {
    if (!this.swipeInProgress) {

      this.swipeInProgress = true;
      // this.changePage(e.pageNumber);
      // const page: Page = this.presentationService.presPages[e.pageNumber - 1];
      if (this.presentationService.activePresentation instanceof Presentation) {

        const vPage = this.presentationService.activePresentation.virtualPages.find(page => page.indskr_virtualpagevalue === `page_${e.pageNumber}`);
        if (vPage && this.isVirtualEvent) {
          const msg: any = {
            indeTrack: `page_${e.pageNumber}`,
          }
          window.postMessage({ type: 'io-spa', action: 'tracking-event', data: msg }, '*');
        }

        setTimeout(() => {
          const page = this.presentationService.presPages.find(page => Number(page.pageUrl?.substring(page.pageUrl?.lastIndexOf('#') + 1)) === e.pageNumber - 1);
          if (page) {
            this.presentationService.setCurrentSelectedPresPage(page);
          }
        }, 100);

        setTimeout(() => {
          this.pdfPresentationViewer?.updateSize();
          this.swipeInProgress = false;
        }, 200);
      }
      this.isVirtualEvent = true;
    }
  }
  get assetType(): string {
    if (!this._activeContent) return null;
    else if (this._activeContent instanceof Presentation && !this.isPDFPresentation) return "presentation";
    else if (this.isPDFPresentation) return "pdfPresentation";
    else if (this.link.toLowerCase().endsWith(".pdf")) return "pdf";
    else if ((<Resource>this._activeContent).assetType) {
      const type: string = (<Resource>this._activeContent).assetType.toLowerCase();
      return ["video", "image"].indexOf(type) >= 0 ? type : "unsupported";
    }
  }

  get hideNavigation(): boolean {
    return this._activeContent && this._activeContent instanceof Resource && this.presentationService.viewMode !== PresentationView.MEETING;
  }
}
