import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarketScan, CustomerSegmentation, Category } from '../../classes/market-scan/market-scan.class';
import { Endpoints } from '../../../config/endpoints.config';
import { AuthenticationService } from '../../services/authentication.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { fetchXmlEntity } from '@omni/config/dynamics-fetchQueries';

@Injectable({
  providedIn: 'root'
})
export class MarketScanDataService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private dynamics: DynamicsClientService) {
  }

  public async getMarketScans(url: string): Promise<MarketScan[]> {
    return await this.http.get<MarketScan[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getMarketScanCategories(url: string): Promise<Category[]> {
    return await this.http.get<Category[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getCustomerSegmentations(): Promise<CustomerSegmentation[]> {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.GET_CUSTOMER_SEGMENTATIONS;
    const positions = this.authService.user.positions.map((o) => { return o.ID });
    url = url.replace('{PositionIds}', positions.toString());
    return this.http.get<CustomerSegmentation[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getEvents(startDate: string, endDate: string): Promise<any> {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.EVENTS;
    const positions = this.authService.user.positions.map((o) => { return o.ID });
    url = url.replace('{PositionIds}', positions.toString()).replace('{startDate}', startDate).replace('{endDate}', endDate);
    return this.http.get<any>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getAffiliatedAccounts(): Promise<any> {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.AFFILIATED_ACCOUNTS;
    const positions = this.authService.user.positions.map((o) => { return o.ID });
    url = url.replace('{PositionIds}', positions.toString());
    return this.http.get<any>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getCampaigns(startDate: string, endDate: string): Promise<any> {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.CUSTOMER_JOURNEYS;
    const positions = this.authService.user.positions.map((o) => { return o.ID });
    url = url.replace('{PositionIds}', positions.toString()).replace('{startDate}', startDate).replace('{endDate}', endDate);
    return this.http.get<any>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async createMarketScan(marketScan) {
    const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.CREATE_MARKET_SCAN;
    return this.http.patch<any>(url, marketScan, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async updateMarketScan(marketScan) {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.UPDATE_MARKET_SCAN;
    //url = url.replace('{marketScanId}', marketScan.indskr_marketscanid);
    return this.http.patch<any>(url, marketScan, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async uploadOfflineMarketScan(marketScans) {
    const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.OFFLINE_UPLOAD_MARKET_SCAN;
    return this.http.post<any>(url, marketScans, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async scrapMarketScan(id: string) {
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.marketScan.DELETE_MARKET_SCAN;
    url = url.replace('{marketScanId}', id);
    return this.http.delete<any>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }


  public async getMarketScanDeltaById(url: string): Promise<MarketScan> {
    return this.http.get<MarketScan>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getCustomerDataAttributes() {
    let hasEvents: boolean = false;
    let hasCampaigns: boolean = false;
    let url: string = this.authService.userConfig.activeInstance.url + "/api/data/v9.0/EntityDefinitions(LogicalName='indskr_marketscancustomerdata')/Attributes?$select=LogicalName&$filter=LogicalName eq 'indskr_eventsid' or LogicalName eq 'indskr_campaignsid'";
    const response = await this.http.get<any[]>(url).toPromise();
    // const response = await this.dynamics.retrieveAttributes('indskr_marketscancustomerdata', "", ['LogicalName'], "LogicalName eq 'indskr_eventsid' or LogicalName eq 'indskr_campaignsid'");
    if (response && response['value'] && response['value'].length > 0) {
      response['value'].forEach(v => {
        if (v['LogicalName'] === 'indskr_campaignsid') {
          hasCampaigns = true;
        } else {
          hasEvents = true;
        }
      })
    }
    return { hasEvents, hasCampaigns }

  }

  public async getCreateFrequency(): Promise<number> {
    const response = await this.dynamics.executeFetchQuery('businessunits', fetchXmlEntity({
      "@": { "name": "businessunit" },
      "attribute": [
        { "@": { "name": "indskr_customerscancreatefrequency", "alias": "createFrequency" } }
      ],
      "filter": [
        {
          "@": { "type": "and" },
          "condition": [
            { "@": { "attribute": "businessunitid", "operator": "eq", "value": this.authService.user.xBusinessUnitId } },
          ]
        }
      ],
      "link-entity": []
    }), null);
    if (response && response.length == 1) {
      return response[0]['createFrequency'];
    }
    return null;
  }
}
