import { Injectable } from '@angular/core';
import { FeatureActionsMap } from './../../classes/authentication/user.class';
import { AuthenticationService } from './../../services/authentication.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from './../../services/disk/disk.service';
import { DB_KEY_PREFIXES } from './../../config/pouch-db.config';
import { NextCallObjectiveService } from '../../services/next-call-objectives/next.call.objectives.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { fetchQueries } from './../../config/dynamics-fetchQueries';
import { NextCallObjective, NextCallObjectiveServiceDTO } from '../../classes/Next-Call-Objective/next.call.objective';
import { differenceInHours } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from "../../../config/endpoints.config";
import * as _ from 'lodash';
import { DeviceService } from '../../services/device/device.service';


@Injectable({
  providedIn: 'root'
})
export class NextCallObjectiveDataService {

  constructor(
    private authService: AuthenticationService,
    private disk: DiskService,
    private nextCallObjectiveService: NextCallObjectiveService,
    private dynamics: DynamicsClientService,
    private http: HttpClient,
    public device: DeviceService,
  ) {

  }
  /**
   *loadOfflineNextCallObjectives
   *@param: Nonethis.nextCallObjectiveService.
   */
  async loadOfflineNextCallObjectives() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVES)) {
      try {
        await this.disk.retrieve(DB_KEY_PREFIXES.NEXT_CALL_OBJECTIVES, true).then((doc) => {
          if (doc && doc.raw) {
            this.nextCallObjectiveService.nextCallObjectives = doc.raw
          }
          else {
            this.nextCallObjectiveService.nextCallObjectives = [];
          }
        });
      }
      catch (error) {
        this.nextCallObjectiveService.nextCallObjectives = [];
      }
    }
  }

/**
 * getnextCallObjectives
 * Intial And Delta Sync for Next call Objective
 * @param: fullSync: boolean
 */
  async getnextCallObjectives(fullSync?: boolean, loadFromDbOnly = false) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVES)) {
      if (loadFromDbOnly) {

      } else {
        try {
          let offlineDataStored;
          let lastModifiedForDeltaSync, hourDifference;
          await this.disk.retrieve(DB_KEY_PREFIXES.NEXT_CALL_OBJECTIVES, true).then((doc) => {
            offlineDataStored = doc
            if (doc && doc.raw) {
              this.nextCallObjectiveService.nextCallObjectives = doc.raw;
            }
            else {
              this.nextCallObjectiveService.nextCallObjectives = [];
              fullSync = true;
            }
          })

          let fetchXML = fetchQueries.nextCallObjectives;
          const now = new Date();//this will be used to find teh Difference and Save the last modified time for

          if (fullSync) {
            // Intial Sync
            fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
          }
          else {
            // Else part is for Delta Sync
            let deltaSyncFilter
            lastModifiedForDeltaSync = offlineDataStored.lastModified;
            if (lastModifiedForDeltaSync) {
              hourDifference = differenceInHours(
                now,
                new Date(lastModifiedForDeltaSync)
              )
              hourDifference += 1
              const entityname = 'indskr_callobjective'
              deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
              deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference)
              deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id')
              // fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter)// has to be uncommented once track change for this entity is avaiable
              fetchXML = fetchXML.replace('{deltaSyncFilter}', '') // Since track change is not working hence always initial Sync, uncomment the line above once track change is available in Dynamics.

            } else {
              fetchXML = fetchXML.replace('{deltaSyncFilter}', '')
            }
          }

          await this.dynamics.executeFetchQuery('indskr_callobjectives', fetchXML)
            .then(async (res) => {

              await this.aggregateNextCallObjectives(res);

              this.disk.updateOrInsert(DB_KEY_PREFIXES.NEXT_CALL_OBJECTIVES, (doc) => {
                doc = {
                  raw: []
                };
                doc.raw =[...this.nextCallObjectiveService.nextCallObjectives];

                doc.lastModified = now.getTime();
                return doc;
              });

              // We have to consider only those Objectives Which are pending for listing in Meetings.
              // Completed will remain in teh system when we go to a meeitng detail will be displaying based on meeting ID, Contact ID

              // this.nextCallObjectiveService.nextCallObjectives = this.nextCallObjectiveService.nextCallObjectives.filter(co => {
              //   return co.isComplete == false;
              // })
            },
              (err) => {
              })
        } catch (error) {
        }
      }
    }
  }

  aggregateNextCallObjectives(nextCallObjectiveData) {
    let nextCallObjectives: NextCallObjective[] = [];
    nextCallObjectiveData.map(a=>{
      if(a.indskr_callobjectiveid){
        let nextCallObjective: NextCallObjective;
        nextCallObjective = nextCallObjectives.find(o=>o.objectiveID == a.indskr_callobjectiveid)
        if(!nextCallObjective) {
          nextCallObjective = new NextCallObjective(a);
          nextCallObjectives.push(nextCallObjective);
        }
      }
    });
    this.nextCallObjectiveService.nextCallObjectives = nextCallObjectives;
  }

  /**
   * @description This method is called to update offline nco changes
  */
  async uploadOfflineNextCallObjectives() {
    if(this.device.isOffline) return;
    await this.getDataFromIndexDB();
    let pendingList = this.nextCallObjectiveService.nextCallObjectives.filter(nco => {
      return nco.syncPending;
    });
    if(pendingList && pendingList.length > 0) {
      this.bulkNextCallService(pendingList);
    }
  }

  // createNextCallObjective(nextCallObjective) : any {
  //   this.updateObjectiveToServiceList(nextCallObjective);
  //   let data = this.getServiceDTO(nextCallObjective);
  //   if(!this.device.isOffline){
  //     let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.nextCallObjective.CREATE_NEXT_CALL_OBJECTIVE;
  //     this.http.post(url,data).subscribe(async (response)=>{
  //       if(response){
  //         nextCallObjective.objectiveID = response['indskr_callobjectiveid'];
  //         nextCallObjective.syncPending = false;
  //         this.updateObjectiveToServiceList(nextCallObjective);
  //         return response;
  //       }
  //     },async (error)=>{
  //       //
  //     });
  //   }
  // }

  // updateNextCallObjective(nextCallObjective) {
  //   this.updateObjectiveToServiceList(nextCallObjective);
  //   let data = this.getServiceDTO(nextCallObjective);
  //   if(!nextCallObjective.objectiveID || nextCallObjective.objectiveID == '') {
  //     return;
  //   }
  //   if(!this.device.isOffline){
  //     let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.nextCallObjective.UPDATE_DELETE_NEXT_CALL_OBJECTIVE.replace('{objective_id}',nextCallObjective.objectiveID);
  //     this.http.patch(url,data).subscribe(async (response)=>{
  //       if(response){
  //         nextCallObjective.syncPending = false;
  //         this.updateObjectiveToServiceList(nextCallObjective);
  //         return response;
  //       }
  //     },async (error)=>{
  //       //
  //     });
  //   }
  // }

  // deleteNextCallObjective(nextCallObjective) {
  //   this.updateObjectiveToServiceList(nextCallObjective);
  //   if(!this.device.isOffline){
  //     let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.nextCallObjective.UPDATE_DELETE_NEXT_CALL_OBJECTIVE.replace('{objective_id}',nextCallObjective.objectiveID);
  //     this.http.delete(url).subscribe(async (response)=>{
  //       if(response){
  //         nextCallObjective.syncPending = false;
  //         this.updateObjectiveToServiceList(nextCallObjective);
  //         return response;
  //       }
  //     },async (error)=>{
  //       //
  //     });
  //   }
  // }

  /**
   * @description This method bulk service which is called for create/update/delete nco
   * @param {nextCallObjective[]=} nextCallObjectiveList nco list for the service call
  */
  async bulkNextCallService(nextCallObjectiveList) {
    let data: any[] = [];
    nextCallObjectiveList.forEach(element => {
      this.updateObjectiveToServiceList(element);
      data.push(this.getServiceDTO(element));
    });
    if(!this.device.isOffline){
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.nextCallObjective.OFFLINE_NEXT_CALL_OBJECTIVE;
      await this.http.post(url,data).subscribe(async (response: any[])=>{
        if(response){
          response.forEach((element,i) => {
            if(!element.errorMessage && !element.errorId) {
              let ncoIndex =  _.findIndex(nextCallObjectiveList, { externalid : element.indskr_externalid});
              if(ncoIndex != -1) {
                nextCallObjectiveList[ncoIndex].objectiveID = element['indskr_callobjectiveid']
                nextCallObjectiveList[ncoIndex].syncPending = false;
                this.updateObjectiveToServiceList(nextCallObjectiveList[ncoIndex]);
              }
            }
          });
          return response;
        }
      },async (error)=>{
        //
      });
    }
  }

  /**
   * @description This method create nco service data for service call for create/update/delete nco
   * @param {NextCallObjective=} objective nco to convert it to service dto
   * @returns {NextCallObjectiveServiceDTO=} serive nco dto for service call
  */
  public getServiceDTO(objective : NextCallObjective) : NextCallObjectiveServiceDTO{
    if(!objective.objectiveID || objective.objectiveID == '') {
      let obj = _.find(this.nextCallObjectiveService.nextCallObjectives, {externalid : objective.externalid});
      if(obj) {
        objective.objectiveID = obj.objectiveID;
      }
    }
    return {
      indskr_callobjectiveid : objective.objectiveID,
      indskr_callobjectives : objective.objective,
      statuscode : objective.statusCode,
      statecode : objective.stateCode,
      indskr_externalid : objective.externalid,
      indskr_MeetingCaptured: objective.capturedMeetingId,
      indskr_MeetingCompleted: objective.completedMeetingId,
      indskr_User: this.authService.user.systemUserID,
      indskr_Customer_contact: objective.contactId,
      deletestate: objective.deletestate ? 1 : 0,
      createdon: objective.createdOn,
    }
  }

  /**
   * @description This method updates nco data to the index db from the local variable of the nco list
  */
  private updateIndexDB() {
    this.disk.updateOrInsert(DB_KEY_PREFIXES.NEXT_CALL_OBJECTIVES, (doc) => {
      doc = {
        raw: []
      };
      doc.raw =[...this.nextCallObjectiveService.nextCallObjectives];
      doc.lastModified = new Date().getTime();
      return doc;
    });
  }

  /**
   * @description This method get nco data from the index db and stores it in the local variable of the nco list
  */
  private async getDataFromIndexDB() {
    await this.disk.retrieve(DB_KEY_PREFIXES.NEXT_CALL_OBJECTIVES, true).then((doc) => {
      if (doc && doc.raw) {
        this.nextCallObjectiveService.nextCallObjectives = doc.raw;
      }
    })
  }

  /**
   * @description This method update the index db and the local variable of the nco list with the ncoId
   *  if the meeting is created offline
   * @param {any=} objective service response from bulk nco service
  */
  private updateObjectiveToServiceList(objective) {
    let index = _.findIndex(this.nextCallObjectiveService.nextCallObjectives, {externalid : objective.externalid});
    if(index != -1) {
      this.nextCallObjectiveService.nextCallObjectives[index] = objective;
    }
    else {
      let index_objective_id = _.findIndex(this.nextCallObjectiveService.nextCallObjectives, {objectiveID : objective.objectiveID});
      if(index_objective_id != -1 && objective.objectiveID && objective.objectiveID != '') {
        this.nextCallObjectiveService.nextCallObjectives[index] = objective;
      }
      else {
        this.nextCallObjectiveService.nextCallObjectives.push(objective);
      }
    }
    try {
      this.updateIndexDB();
    }
    catch (error) {
      console.log(error);
    }
    this.trackOfflineNextCallObjectiveDataCount();
  }

  async loadOfflineNextCallObjective() {
    await this.getDataFromIndexDB();
    let pendingList = this.nextCallObjectiveService.nextCallObjectives.filter(nco => {
      return nco.syncPending && (nco.capturedMeetingId.includes('offline_meeting') || nco.completedMeetingId.includes('offline_meeting'));
    });
    return pendingList;
  }

  updateNCOForOfflineMeetings(offlineMeetings) {
    // Loop through offlineMeetings response
    for (const key in offlineMeetings) {
      if (offlineMeetings.hasOwnProperty(key)) {
        const element = offlineMeetings[key];
        if(element.offlineNextCallObjectives) {
          element.offlineNextCallObjectives.forEach(nco => {
            let ncoIndex =  _.findIndex(this.nextCallObjectiveService.nextCallObjectives, { externalid : nco.indskr_externalid});
            if(ncoIndex != -1 && nco.indskr_callobjectiveid && nco.indskr_callobjectiveid != '') {
              this.nextCallObjectiveService.nextCallObjectives[ncoIndex].objectiveID = nco.indskr_callobjectiveid;
              this.nextCallObjectiveService.nextCallObjectives[ncoIndex].syncPending = false;
              if(nco['indskr_objectivecaptured@odata.bind']) {
                this.nextCallObjectiveService.nextCallObjectives[ncoIndex].capturedMeetingId = element.activityId;
              }
              if(nco['indskr_objectivecompleted@odata.bind']) {
                this.nextCallObjectiveService.nextCallObjectives[ncoIndex].completedMeetingId = element.activityId;
              }
            }
          });
        }
      }
    }
    try {
      this.updateIndexDB();
    }
    catch (error) {
      console.log(error);
    }
    this.trackOfflineNextCallObjectiveDataCount();
  }

  trackOfflineNextCallObjectiveDataCount() {
    const offlineDataCount: number = this.nextCallObjectiveService.nextCallObjectives.filter(s => s.syncPending === true).length;
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.NEXT_CALL_OBJECTIVES, offlineDataCount);
  }

}
