import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsentSignatureModalComponent } from '@omni/components/contact/consent-signature-modal/consent-signature-modal';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '@omni/services/disk/disk.service';
import _ from 'lodash';
import { DisplayValue } from '@omni/components/display-value/display-value.component';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { PdfService } from '@omni/services/pdf.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel'; differenceInMonths
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { CONTRACT_SUPPORTED_MIME_TYPES_SUPPORTED_REGEX, MAXIMUM_NOTE_ATTACHMENT_SIZE } from '@omni/utility/util';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { ActivityService } from '@omni/services/activity/activity.service';
import moment from 'moment';
import { OmniOptionAlertComponent } from '@omni/components/shared/omni-option-alert/omni-option-alert.component';
import { AlertService } from '@omni/services/alert/alert.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { addMonths, differenceInDays, differenceInMonths, endOfDay, format, lastDayOfMonth, startOfDay, subDays } from 'date-fns';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OmniYearMonthPickerComponent } from '@omni/components/shared/omni-year-month-picker/omni-year-month-picker.component';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { customerAddress } from '@omni/classes/account/account.class';
import { PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES, ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Specialty } from '@omni/classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { Guid } from 'typescript-guid';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import { getConfigFormFieldIsReadOnly, getConfigFormFieldType, getConfigFormFieldViewDataModelId, getConfigFormInputType } from '@omni/utility/common.utility';
import { SelectListData } from '@omni/components/popover/popover';
import { ConfigFieldOptionValue } from '@omni/classes/activity/activity.class';
import { ReadOnlyEventConfiguredFieldNames } from '@omni/config/configuredField.config';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';


@Component({
  selector: 'app-surgery-order-contract-details',
  templateUrl: './surgery-order-contract-details.component.html',
  styleUrls: ['./surgery-order-contract-details.component.scss'],
})
export class SurgeryOrderContractDetailsComponent implements OnInit, OnDestroy {
  @Input() procedureContract: ProcedureContract;
  @Input() viewMode: ComponentViewMode;
  @ViewChild('attachInput') attachInput: ElementRef;
  // ---- add title section here ---- //
  public procedureContractPageTitle: IndPageTitleViewDataModel;
  public procedureDeatialsHeader: IndSectionHeaderViewDataModel;
  public contractDetailsHeader: IndSectionHeaderViewDataModel;
  public preSignatureHeader: IndSectionHeaderViewDataModel;
  public postSignatureHeader: IndSectionHeaderViewDataModel;
  public procedureCoveredHeader: IndSectionHeaderViewDataModel;
  public childContractHeader: IndSectionHeaderViewDataModel;

  // ---- add Form fields here ---- //

  //---- Pre surgery form fields ----//
  public preValidatorFormField: IndFormFieldViewDataModel;
  public rulesField: IndFormFieldViewDataModel;
  public customerFormField: IndFormFieldViewDataModel;
  
  //---- Post surgery form fields ----//
  public postValidatorFormField: IndFormFieldViewDataModel;
  public preSurgeryDateField: IndDateTimeFormViewDataModel;
  public postSurgeryDateField: IndDateTimeFormViewDataModel;

  //---- variable declaration from here ----//
  private requiredFields: { [key: string]: { isEvaluated: boolean } } = {};
  private _isClicked: { [x: string]: boolean } = {};
  private autoNamingDisabled = false;
  private ngUnSubscribe$ = new Subject<boolean>();
  private globalCustomerText: string;
  private isFullReadOnlyMode = false;
  private associatedProcedureLogs: any = [];
  private currentSurgeryOrderActivity: SurgeryOrderActivity;
  private childContracts = [];
  private specialities = [];
  private customerAddresses = [];
  private partialReadOnlyMode = false;
  private hideProcedure = false;
  private isProcedureSubtypeAvailable = false;
  private procedureContractPositionGroupProducts = [];
  private lookupConfigFieldsData = [];
  private lookupFieldsDataInitialized = false;
  private contractDetailsFields = [];
  private procedureDetailsFields = [];
  private presurgeryDetailsFields = [];

  public procedureLogItems: ProcedureLogData[] = [];
  public displayChildContracts = [];
  public shouldShowPresurgerySection = false;
  public shouldShowPostsurgerySection = false;
  public procedureContractType = null;
  public presurgerytext = null;
  public postsurgerytext = null;
  public groupedFields = {};

  constructor(
    public navService: NavigationService,
    public translate: TranslateService,
    private utilityService: GlobalUtilityService,
    public footerService: FooterService,
    public popoverCtrl: PopoverController,
    private datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
    public surgeryOrderDataService: SurgeryOrderActivityDataService,
    public uiService: UIService,
    public device: DeviceService,
    public disk: DiskService,
    public pdfService: PdfService,
    public notificationService: NotificationService,
    private contractService: ProcedureContractService,
    private modalController: ModalController,
    public activityService: ActivityService,
    private alertService: AlertService,
    public authenticationService: AuthenticationService,
    private accountService: AccountOfflineService,
    private contactService: ContactOfflineService,
    private modalCtrl: ModalController,
    private activityDataService: ActivityDataService,
    // private brandService: BrandOfflineService,
  ) { }

  ngOnInit() {
    this.autoNamingDisabled = this.contractService.generateContractName(this.procedureContract) !== this.procedureContract.indskr_name;
    this.hideProcedure = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_hideprocedureproductcategory'];
    this.procedureContractType = this.contractService.getContractType(this.procedureContract.indskr_contracttypes);
    this.specialities = this.contactService.specialties;
    this.customerAddresses = this.accountService.customerAddresses;

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }

    this.initProcedureContractPositionProducts();
    this.setFlags();
    this.initAllDataModels();
    this.fetchAssociatedProcedureLogs();

    if (!this.procedureContract.indskr_parentprocedurecontractid) {
      this.contractService.setChildContracts(this.procedureContract.indskr_procedurecontractid);
      this.initChildContractList();
    }
  }

  public get areAllRequiredFieldsFilled() {
    let requiredFieldsFilled = this.procedureContract['accountName'] &&
      this.procedureContract['indskr_maximumnoofassistance'] &&
      (this.procedureContract['indskr_enddate'] ||
        (!this.procedureContract['indskr_enddate'] && this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT)) &&
      this.procedureContract['indskr_startdate'] &&
      this.procedureContract.contractTypeString &&
      ((this.isProcedureSubtypeAvailable && this.procedureContract.proceudreSubTypeString != "") || !this.isProcedureSubtypeAvailable);

    if (!_.isEmpty(this.groupedFields)) {
      const allFields: any = Object.values(this.groupedFields['Contract Details']).flat();
      if (allFields.some((field) => field.isRequired && !field.inputText)) {
        requiredFieldsFilled = false;
      }
    }

    return requiredFieldsFilled;
  }

  private get shouldDisable() {
    if (this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) return true;
    if (this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_MULTIPLE_DAY_CONTRACT && this.procedureContract.indskr_parentprocedurecontractid) return true;
    return false;
  }

  public get preSignFileName() {
    let fileName = this.procedureContract.indskr_presurgerysignaturedocument.match(/\/([^\/]+)$/)[1];
    return fileName ? fileName : '';
  }

  public get postSignFileName() {
    let fileName = this.procedureContract.indskr_postsurgerysignaturedocument.match(/\/([^\/]+)$/)[1];
    return fileName ? fileName : '';
  }

  private procedureSubTypeHasFrequency() {
    if (!this.procedureContract.indskr_contracttypes || !this.procedureContract.indskr_proceduresubtype) {
      return false;
    }
    const selectedProcedureSubType = this.surgeryOrderDataService.findProcedureSubTypeById(this.procedureContract.indskr_proceduresubtype);
    if (!selectedProcedureSubType || !selectedProcedureSubType.indskr_procedureallocated) return false;
    return true;
  }

  private procedureSubTypeHasDuration() {
    if (!this.procedureContract.indskr_contracttypes || !this.procedureContract.indskr_proceduresubtype) {
      return false;
    }
    const selectedProcedureSubType = this.surgeryOrderDataService.findProcedureSubTypeById(this.procedureContract.indskr_proceduresubtype);
    if (!selectedProcedureSubType || !selectedProcedureSubType.indskr_validity) return false;
    return true;
  }

  private _initHeaderView(): void {
    let titleButtons: any = [{
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    }, {
      id: "activate",
      imgSrc: 'assets/imgs/header_complete.svg',
      name: this.translate.instant('ACTIVATE'),
      isDisabled: this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.DRAFT || !this.areAllRequiredFieldsFilled,
      align: "right"
    }, {
      id: "refresh",
      imgSrc: 'assets/imgs/refreshIcon.svg',
      name: this.translate.instant('SYNC'),
      isDisabled: this.device.isOffline || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.EXPIRED,
      align: "right"
    }, {
      id: "cancel",
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('CANCEL'),
      isDisabled:
        this.device.isOffline ||
        this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.EXPIRED ||
        this.associatedProcedureLogs.length > 0 ||
        this.viewMode === ComponentViewMode.PREVIEW,
      align: "right"
    }];

    this.procedureContractPageTitle = {
      id: 'contract_page_title',
      title: this.procedureContract.indskr_name,
      controls: titleButtons,
    };
  }

  private initFooter() {
    this.footerService.initButtons(FooterViews.PROCEDURE_CONTRACT);

    if (this.viewMode == ComponentViewMode.PREVIEW ||
      !this.procedureContract.indskr_signaturecapturemode) {
      this.footerService.disableButton(['downloadPDF', 'uploadPDF']);
      return;
    }

    if (this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital ||
      (this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) ||
      !this.procedureContract.indskr_consenttaken ||
      !this.procedureContract.indskr_complywithhospitalrulesandregulations) {
      this.footerService.disableButton(['uploadPDF']);
    }
  }

  private initAllDataModels() {
    // ? to make contract details and procedure details section readonly
    this.partialReadOnlyMode = this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.DRAFT;

    this.isFullReadOnlyMode = this.viewMode === ComponentViewMode.PREVIEW ||
      (this.procedureContract.indskr_parentprocedurecontractid != null && this.procedureContract.indskr_parentprocedurecontractid != "");

    if (!this.autoNamingDisabled) {
      this.procedureContract.indskr_name = this.contractService.generateContractName(this.procedureContract);
    }

    this.groupedFields = {};
    this.contractDetailsFields = [];
    this.procedureDetailsFields = [];
    this.presurgeryDetailsFields = [];
    // this.postSurgeryDetailsFields = [];

    //---- Contract details section ----//
    this.initContractDetailSection();

    //---- Procedure detail section ----//
    this.getproductFormField();
    if (!this.hideProcedure) this.getProcedureFormField();
    this.getproductIdFormField();
    this.getProcedureDetailsHeader();
    this.setupContractView(CONTRACT_SECTION_NAMES.PROCEDURE_DETAILS, this.procedureDetailsFields);

    //---- Pre surgery section ----//
    this.getPreValidatorFormField();
    this.getpreSignatureDateField();
    this.getCustomerFormField();
    this.createConsentFormField();
    this.createComplyFormField();
    this.setupContractView(CONTRACT_SECTION_NAMES.PRE_SURGERY_SIGNATURE, this.presurgeryDetailsFields);

    //---- post surgery section ----// 
    this.getPostValidatorFormField();
    this.getpostSignatureDateField();
    this.setupContractView(CONTRACT_SECTION_NAMES.POST_SURGERY_SIGNATURE, []);
    this.setupContractView(CONTRACT_SECTION_NAMES.PROCEDURE_COVERED, []);

    //---- section headers ----//
    this.getPreSignatureHeader();
    this.getPostSignatureHeader();
    this.getProcedureCoveredHeader();
    this.getChildContractHeader();

    this.initFooter();
    this._initHeaderView();
  }

  private initContractDetailSection() {
    this.getStatusFormField();
    this.getNameFormField();
    if (this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_MULTIPLE_DAY_CONTRACT &&
      this.procedureContract.indskr_parentprocedurecontractid) {
      this.getParentNameFormField();
    }
    this.getAccountFormField();
    this.getSpecialitiesFormField();
    this.getAddressFormField();
    this.getprocedureAllocatedFormField();
    if (this.procedureContractType !== PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) {
      this.getProceduresUsedFormField();
      this.getProceduresAvailableFormField();
    }
    this.getcontractStartDateField();
    if (this.procedureContractType !== PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) {
      this.getcontractEndDateField();
    }
    this.getDurationFormField();
    this.getcontractTypeFormField();
    if (this.isProcedureSubtypeAvailable) this.getprocedureSubTypeFormField();
    if (this.areAllRequiredFieldsFilled) this.getSignatureMethodFormField();
    this.getContractDetailsHeader();
    this.setupContractView(CONTRACT_SECTION_NAMES.CONTRACT_DETAILS, this.contractDetailsFields);
  }

  private fetchAssociatedProcedureLogs() {
    if (this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) return;
    this.uiService.displayLoader();
    let apiCall = !this.procedureContract.indskr_parentprocedurecontractid ? this.contractService.getSurgeryInfoAssociatedWithContract(this.procedureContract.indskr_procedurecontractid) :
      this.contractService.getSurgeryInfoAssociatedWithContract(this.procedureContract.indskr_parentprocedurecontractid, new Date(this.procedureContract.indskr_startdate), new Date(this.procedureContract.indskr_enddate))

    apiCall.then((response) => {
      this.associatedProcedureLogs = [];
      if (response && response.length) {
        response.forEach(element => {
          const index = this.associatedProcedureLogs.findIndex((procLog) => procLog.orderId === element.salesorderid);
          if (index >= 0) {
            this.associatedProcedureLogs[index].coOwners.push(element['ac.fullname']);
          } else {
            this.associatedProcedureLogs.push({
              startDate: element.indskr_scheduleddate,
              ownerName: element._ownerid_value_Formatted,
              name: element.name,
              orderId: element.salesorderid,
              coOwners: element['ac.fullname'] ? [element['ac.fullname']] : []
            });
          }
        });
      }
      this.initViewForprocedureLog(this.associatedProcedureLogs);
      this._initHeaderView();
      this.uiService.dismissLoader();
    });
  }

  private initViewForprocedureLog(associatedProcedureLogs) {
    associatedProcedureLogs.forEach((item) => {
      let newProcedurelog: ProcedureLogData
      let contractStartDayValue = this.datePipe.transform(item.startDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);

      newProcedurelog = {
        headerText: item.name,
        values: [
          {
            label: this.translate.instant('PROCEDURE_DATE'),
            value: contractStartDayValue,
            placeholderText: this.translate.instant('NO_PROCEDURE_DATE'),
            showLines: true,
          },
          {
            label: this.translate.instant('START_TIME'),
            value: item.startDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }),
            placeholderText: this.translate.instant('NO_START_TIME'),
            showLines: true,
          },
          {
            label: this.translate.instant('USER'),
            value: item.ownerName,
            placeholderText: this.translate.instant('NO_USER'),
            showLines: true,
          },
          {
            label: this.translate.instant('CO_OWNERS'),
            value: item.coOwners.length >= 1 ? item.coOwners[0] : '',
            placeholderText: this.translate.instant('NO_CO_OWNERS'),
            showLines: true,
            valuePopupText: (item.coOwners && item.coOwners.length > 1) ? '+ ' + (item.coOwners.length - 1) : '',
            popupListData: (item.coOwners && item.coOwners.length > 1) ? item.coOwners.slice(1).map(a => {
              let obj = {
                id: Guid.create(),
                title: a,
              };
              return obj;
            }) : [],
          }
        ]
      }

      this.procedureLogItems.push(newProcedurelog);
    })
  }

  // ---- section headers ---- //
  private getProcedureDetailsHeader() {
    this.procedureDeatialsHeader = {
      id: 'procedure-details-header',
      title: this.translate.instant('PROCEDURE_DETAILS'),
      controls: []
    };
  }

  private getContractDetailsHeader() {
    this.contractDetailsHeader = {
      id: 'contract-details-header',
      title: this.translate.instant('CONTRACT_DETAILS'),
      controls: []
    };
  }

  private getPreSignatureHeader() {
    let title = this.translate.instant('PRE_SIGNATURE');
    if (this.procedureContract.indskr_contracttypes) {
      const contractType = this.contractService.findContractTypeById(this.procedureContract.indskr_contracttypes);
      if (contractType) {
        title = contractType.indskr_presignaturesectionname ? contractType.indskr_presignaturesectionname : title;
        this.presurgerytext = contractType.indskr_presurgerytext ? contractType.indskr_presurgerytext.split("\n") : this.presurgerytext;
      }
    }
    this.preSignatureHeader = {
      id: 'preSignature-header',
      title,
      doNotModifyTitleCase: true,
      controls: []
    };

  }

  private getPostSignatureHeader() {
    let title = this.translate.instant('POST_SIGNATURE');
    if (this.procedureContract.indskr_contracttypes) {
      const contractType = this.contractService.findContractTypeById(this.procedureContract.indskr_contracttypes);
      if (contractType) {
        title = contractType && contractType.indskr_postsignaturesectionname ? contractType.indskr_postsignaturesectionname : title;
        this.postsurgerytext = contractType.indskr_postsurgerytext ? contractType.indskr_postsurgerytext.split("\n") : this.presurgerytext;
      }
    }
    this.postSignatureHeader = {
      id: 'postSignature-header',
      title,
      doNotModifyTitleCase: true,
      controls: []
    };
  }

  private getProcedureCoveredHeader() {
    this.procedureCoveredHeader = {
      id: 'procedure-covered-header',
      title: this.translate.instant('PROCEDURE_COVERED_IN_THIS_CONTRACT'),
      controls: []
    };
  }

  private getChildContractHeader() {
    let buttons = [];
    buttons.push({
      id: "plus-icon",
      iconClass: 'pluse-icon',
      isDisabled: (differenceInMonths(new Date(this.procedureContract.indskr_enddate), new Date(this.procedureContract.indskr_startdate)) === this.childContracts.length - 1) || this.viewMode === ComponentViewMode.PREVIEW
    })
    this.childContractHeader = {
      id: 'child-contract-header',
      title: this.translate.instant('CHILD_CONTRACTS'),
      controls: buttons
    };
  }

  private initChildContractList() {
    this.contractService.childContracts.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((childContracts) => {
      if (childContracts && childContracts.length > 0) {
        this.childContracts = childContracts.sort((a, b) => new Date(b.indskr_startdate).getTime() - new Date(a.indskr_startdate).getTime());
        this.initViewForChildContract();
        this.getChildContractHeader();
      }
    });
  }

  // ---- contract details fields ----//
  private getAddressFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('ADDRESS'),
      inputText: this.procedureContract['customerAddressName'] ?? '',
      customPlaceholderLabel: this.translate.instant('SELECT_ADDRESS'),
      id: 'address-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getSpecialitiesFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SPECIALITY'),
      inputText: this.procedureContract['specialityName'] ?? '',
      customPlaceholderLabel: this.translate.instant('XPERIENCES_SPECIALITY'),
      id: 'speciality-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getprocedureSubTypeFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURE_CONTRACT_SUB_TYPE'),
      inputText: this.procedureContract['proceudreSubTypeString'],
      customPlaceholderLabel: this.translate.instant('SELECT_PROCEDURE_SUB_TYPE'),
      id: 'procedure-subType-field',
      isReadOnly: true,
      isRequired: this.procedureContractType === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT && !this.isFullReadOnlyMode ? true : this.isFullReadOnlyMode ? false : true,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isHidden: !this.isProcedureSubtypeAvailable,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getprocedureAllocatedFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_ALLOCATED'),
      inputText: this.procedureContract['indskr_maximumnoofassistance']?.toString() ?? "0",
      inputValue: this.procedureContract['indskr_maximumnoofassistance'],
      customPlaceholderLabel: this.translate.instant('NO_PROCEDURES_ALLOCATED'),
      id: 'procedure-allocation-field',
      isRequired: this.isFullReadOnlyMode ? false : true,
      isReadOnly: false,
      isDisabled: (this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasFrequency() || this.partialReadOnlyMode) ? true : false,
      showArrow: (this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasFrequency() || this.partialReadOnlyMode) ? false : true,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getProceduresUsedFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_USED'),
      inputText: this.procedureContract['indskr_noofassistanceavailed']?.toString(),
      placeholderLabel: this.translate.instant('NO_PROCEDURES_USED'),
      id: 'procedures-used-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
      // isHidden:this.procedureContract.indskr_proceduretype == 548910000 ? true :false,
    }
    this.contractDetailsFields.push(viewData);
  }

  private getProceduresAvailableFormField() {
    let contractAvailabe = this.procedureContract['indskr_maximumnoofassistance'];
    let contractUsed = this.procedureContract['indskr_noofassistanceavailed'];
    let proceduresContractAvailables = contractAvailabe - contractUsed || 0;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_AVAILABLE'),
      // label: this.translate.instant('PROCEDURES_AVAILABLE'),
      inputText: proceduresContractAvailables + '',
      placeholderLabel: this.translate.instant('NO_PROCEDURES_AVAILABLE'),
      id: 'procedures-available-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
      // isHidden:this.procedureContract.indskr_proceduretype == 548910000 ? true :false
    }
    // this.proceduresAvailables = viewData
    this.contractDetailsFields.push(viewData);
  }

  private getcontractStartDateField() {
    let contractStartDayValue = this.datePipe.transform(this.procedureContract['indskr_startdate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract?.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('START_DATE'),
      inputText: this.isFullReadOnlyMode ? contractStartDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_START_PERIOD'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      isRequired: this.isFullReadOnlyMode ? false : true,
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.procedureContract?.indskr_startdate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    // this.contractStartDateField = viewData
    this.contractDetailsFields.push(viewData);
  }

  private getcontractEndDateField() {
    let contractEndDayValue = this.datePipe.transform(this.procedureContract['indskr_enddate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('END_DATE'),
      inputText: this.isFullReadOnlyMode ? contractEndDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_END_PERIOD'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isRequired: this.isFullReadOnlyMode || this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT ? false : true,
      isDisabled: (this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasDuration() || this.partialReadOnlyMode) ? true : false,
      showArrow: (this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasDuration() || this.partialReadOnlyMode) ? false : true,
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.procedureContract?.indskr_startdate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    // this.contactEndDateField = viewData
    this.contractDetailsFields.push(viewData);
  }

  private getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.procedureContract['accountName'],
      placeholderLabel: this.translate.instant('NO_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: this.isFullReadOnlyMode ? false : true,
    };
    // this.accountFormField = viewData;
    this.contractDetailsFields.push(viewData);
  }

  private getNameFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT_NAME'),
      inputText: this.procedureContract.indskr_name,
      inputValue: this.procedureContract.indskr_name,
      placeholderLabel: this.translate.instant('NAME'),
      id: 'name-field',
      isReadOnly: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      isRequired: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    // this.nameFormField = viewData;
    this.contractDetailsFields.push(viewData);
  }

  private getStatusFormField() {
    const statusString = this.contractService.getStatusString(this.procedureContract.statuscode) ?? this.procedureContract.statusString;
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: statusString,
      inputValue: this.procedureContract.statuscode,
      placeholderLabel: this.translate.instant('STATUS'),
      id: 'status-field',
      isReadOnly: false,
      isDisabled: true,
      formFieldType: FormFieldType.INLINE_INPUT,
    };
    // this.statusFormField = viewData;
    this.contractDetailsFields.push(viewData);
  }

  private getParentNameFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PARENT_NAME'),
      inputText: this.procedureContract.parentProcedureContractName,
      inputValue: this.procedureContract.parentProcedureContractName,
      placeholderLabel: this.translate.instant('PARENT_NAME'),
      id: 'parent-name-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      // eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    // this.parentProcedureContractField = viewData;
    this.contractDetailsFields.push(viewData);
  }

  private getcontractTypeFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CONTRACT_TYPE'),
      inputText: this.procedureContract['contractTypeString'],
      customPlaceholderLabel: this.translate.instant('SELECT_CONTRACT_TYPE'),
      id: 'contract-type-field',
      isReadOnly: true,
      isRequired: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    // this.contractTypeField = viewData
    this.contractDetailsFields.push(viewData);
  }

  private getDurationFormField() {
    let viewData: IndFormFieldViewDataModel;
    const durationInDays = !this.procedureContract.indskr_enddate || !this.procedureContract.indskr_startdate ? 0 : differenceInDays(new Date(this.procedureContract.indskr_enddate), new Date(this.procedureContract.indskr_startdate)) + 1;
    viewData = {
      label: this.translate.instant('DURATION_CONTRACT_DAYS'),
      inputText: durationInDays >= 0 ? durationInDays.toString() : '0',
      inputValue: durationInDays,
      placeholderLabel: this.translate.instant('DURATION_CONTRACT_DAYS'),
      id: 'duration-field',
      isReadOnly: true,
      formFieldType: FormFieldType.INLINE_INPUT,
    };
    this.contractDetailsFields.push(viewData);
  }

  public getSignatureMethodFormField() {
    let viewData: IndFormFieldViewDataModel;
    let procedureSignatureType = this.contractService.findProcedureSignatureTypeById(this.procedureContract.indskr_signaturecapturemode);
    const hideField = (!this.areAllRequiredFieldsFilled ||
      (!this.procedureContract.indskr_parentprocedurecontractid && this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_MULTIPLE_DAY_CONTRACT));

    const procedureTypeLabel = procedureSignatureType?.label || '';
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT_SIGNATURE_MODE'),
      inputText: procedureTypeLabel,
      customPlaceholderLabel: false ? this.translate.instant('PROCEDURE_CONTRACT_SIGNATURE_MODE') : this.translate.instant('SELECT_PROCEDURE_CONTRACT_SIGNATURE_MODE'),
      id: 'contract-sign-method',
      isReadOnly: true,
      isHidden: hideField,
      isDisabled: this.viewMode == ComponentViewMode.PREVIEW || this.isFullReadOnlyMode || (this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.ACTIVE && this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.DRAFT),
      showArrow: this.viewMode != ComponentViewMode.PREVIEW && !this.isFullReadOnlyMode && (this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.ACTIVE || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.contractDetailsFields.push(viewData);
  }

// ---- Procedure details fields ----//

  private getproductFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.procedureContract['productName'],
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
      id: 'product-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.procedureDetailsFields.push(viewData);
  }

  private getproductIdFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT_ID'),
      inputText: this.procedureContract['indskr_productid'],
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
      id: 'product-id-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: true,
      showArrow: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.procedureDetailsFields.push(viewData);
  }

  private getProcedureFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE'),
      inputText: this.procedureContract['procedureName'],
      customPlaceholderLabel: this.translate.instant('SELECT_PROCEDURE'),
      id: 'procedure-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
      showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isHidden: this.hideProcedure,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.procedureDetailsFields.push(viewData);
  }

  // ---- pre signature fields ----//
  public getpreSignatureDateField() {
    let contractStartDayValue = this.datePipe.transform(this.procedureContract['indskr_presurgerysignaturecapturedate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract?.indskr_presurgerysignaturecapturedate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('SIGNATURE_DATE'),
      inputText: this.isFullReadOnlyMode ? contractStartDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.viewMode == ComponentViewMode.PREVIEW || this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital,
      showArrow: this.viewMode == ComponentViewMode.PREVIEW ? false : true,
      // isRequired: this.viewMode == ComponentViewMode.PREVIEW  ? false : true,
      // isEmptyRequiredField: this._isClicked && _.isEmpty(this.procedureContract?.indskr_presurgerysignaturecapturedate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.openPresignatureDatePicker(),
    }
    this.preSurgeryDateField = viewData
  }

  public getPreValidatorFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('VALIDATOR_NAME'),
      inputText: this.procedureContract['indskr_validatornamepresurgerysignature'],
      inputValue: this.procedureContract['indskr_validatornamepresurgerysignature'],
      placeholderLabel: this.isFullReadOnlyMode ? this.translate.instant('NO_VALIDATOR')  :  this.translate.instant('ENTER_VALIDATOR_NAME'),
      id: 'prevalidator-field',
      isReadOnly: false,
      isDisabled: this.procedureContract.indskr_contact != null || this.procedureContract.indskr_presurgerysignaturecaptured || this.isFullReadOnlyMode,
      showArrow: this.procedureContract.indskr_contact == null && !this.procedureContract.indskr_presurgerysignaturecaptured && !this.isFullReadOnlyMode,
      isRequired: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.preValidatorFormField = viewData;
  }

  public getCustomerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText: this.procedureContract.contactName != null ? this.procedureContract.contactName : '',
      customPlaceholderLabel: this.procedureContract.contactName == null && this.isFullReadOnlyMode ? this.translate.instant('NO_CUSTOMER') : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText }),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured,
      showArrow: !this.isFullReadOnlyMode && !this.procedureContract.indskr_presurgerysignaturecaptured,
      isRequired: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.customerFormField = viewData;
  }

  public createConsentFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CONSENT_TAKEN'),
      inputText: "true",
      inputValue: this.procedureContract.indskr_consenttaken,
      id: 'consent-field',
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured,
      showArrow: !this.isFullReadOnlyMode && !this.procedureContract.indskr_presurgerysignaturecaptured,
      isRequired: false,
      formFieldType: FormFieldType.CHECK_BOX,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.presurgeryDetailsFields.push(viewData);
  }

  public createComplyFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COMPLY_WITH_RULES'),
      inputText: "true",
      inputValue: this.procedureContract.indskr_complywithhospitalrulesandregulations,
      id: 'comply-field',
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured,
      showArrow: !this.isFullReadOnlyMode && !this.procedureContract.indskr_presurgerysignaturecaptured,
      isRequired: false,
      formFieldType: FormFieldType.CHECK_BOX,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.presurgeryDetailsFields.push(viewData);
  }

 // ---- post signature fields ---- //

  public getpostSignatureDateField() {
    let contractStartDayValue = this.datePipe.transform(this.procedureContract['indskr_postsurgerysignaturecapturedate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract?.indskr_postsurgerysignaturecapturedate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('SIGNATURE_DATE'),
      inputText: this.isFullReadOnlyMode ? contractStartDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.viewMode === ComponentViewMode.PREVIEW || this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital,
      showArrow: this.isFullReadOnlyMode ? false : true,
      isRequired: this.isFullReadOnlyMode ? false : true,
      // isEmptyRequiredField: this._isClicked && _.isEmpty(this.procedureContract?.indskr_postsurgerysignaturecapturedate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.openPostsignatureDatePicker(),
    }
    this.postSurgeryDateField = viewData
  }

  public getPostValidatorFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('VALIDATOR_NAME'),
      inputText: this.procedureContract['indskr_validatornamepostsurgerysignature'],
      inputValue: this.procedureContract['indskr_validatornamepostsurgerysignature'],
      placeholderLabel: this.isFullReadOnlyMode ? this.translate.instant('NO_VALIDATOR')  :  this.translate.instant('ENTER_VALIDATOR_NAME'),
      id: 'postvalidator-field',
      isReadOnly: false,
      isDisabled: this.procedureContract.indskr_postsurgerysignature != undefined || this.viewMode === ComponentViewMode.PREVIEW || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.CANCELLED,
      showArrow: false,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.postValidatorFormField = viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (!id) return;
    switch (id) {
      case 'prevalidator-field':
      case 'postvalidator-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleValidatorField(event, id);
        }
        break;
      case 'contract-sign-method':
        this.openContractSignOptions(event);
        break;
      case 'procedure-field':
        this.openSurgerySelect();
        break;
      case 'procedure-type-field':
        // this.handleProcedureTypesField(event);
        break;
      case 'procedure-subType-field':
        this.handleProcedureSubTypesField(event);
        break;
      case DateTimeFieldType.StartDateField:
        this.openStartDatePicker(event);
        break;
      case DateTimeFieldType.EndDateField:
        this.openEndDatePicker(event);
        break;
      case 'product-field':
        this.openProductSelect();
        break;
      case 'procedure-allocation-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleProcedureAllocatedField(event);
        }
        break;
      case 'name-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleProcedureContractNameField(event);
        }
        break;
      case 'speciality-field':
        this.openSpeciality();
        break;
      case 'address-field':
        this.openCustomerAddresses();
        break;
      case 'contract-type-field':
        this.handleContractTypesField(event);
        break;
      case 'customer-field':
        this.openCustomerSelect();
        break;
      case 'consent-field':
        this.onConsent(event);
        break;
      case 'comply-field':
        this.onComply(event);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private async handleProcedureAllocatedField(event) {
    if (event && event.target.value !== undefined && this.procedureContract.indskr_maximumnoofassistance != event.target.value) {
      const payload = {
        indskr_maximumnoofassistance: event.target.value
      }
      if (!this.device.isOffline) {
        await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
      } else {
        this.procedureContract.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }
      this.procedureContract.indskr_maximumnoofassistance = event.target.value;
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    }
  }

  private async handleProcedureContractNameField(event) {
    if (event && event.target.value !== undefined && this.procedureContract.indskr_name != event.target.value) {
      let payload = {
        indskr_name: event.target.value
      }
      if (!this.device.isOffline) {

        await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);

      } else {
        this.procedureContract.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }
      this.procedureContract.indskr_name = event.target.value;
      this.autoNamingDisabled = this.contractService.generateContractName(this.procedureContract) !== this.procedureContract.indskr_name;
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract)
      this.initAllDataModels();
    }
  }

  async handleValidatorField(event, id) {
    const letters = /^[^\d!@#$%^&*()_+={}[\]:;'"<>,.?\/\\`~|“”‘’„“«»-]*$/;
    let procedureContract = this.contractService.findProcedureContractById(this.procedureContract.indskr_procedurecontractid);
    let payload;
    if (event.target.value.match(letters)) {
      if (id === 'prevalidator-field') {
        procedureContract.indskr_validatornamepresurgerysignature = event.target.value;
        payload = {
          indskr_validatornamepresurgerysignature: procedureContract.indskr_validatornamepresurgerysignature
        }
      } else if (id === 'postvalidator-field') {
        procedureContract.indskr_validatornamepostsurgerysignature = event.target.value;
        payload = {
          indskr_validatornamepostsurgerysignature: procedureContract.indskr_validatornamepostsurgerysignature
        }
      }
      if (!this.device.isOffline) {
        await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
      }
      else {
        this.procedureContract.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }
      await this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, payload)
      this.procedureContract.indskr_validatornamepresurgerysignature = procedureContract.indskr_validatornamepresurgerysignature;
      this.procedureContract.indskr_validatornamepostsurgerysignature = procedureContract.indskr_validatornamepostsurgerysignature;
      this.initAllDataModels();
    }
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case "procedure_Contract_confirm":
        this.createProcedureContract();
        break;
      case "close":
        this._closePage();
        break;
      case "refresh":
        this.syncProcedureContract();
        break;
      case "cancel":
        const resp = await this.showCancelProcedureContractPopover();
        if (resp.role == 'ok') this.cancelProcedureContract();
        break;
      case 'activate':
        const activateResp = await this.showAcitvateProcedureContractPopover();
        if (activateResp) this.activateProcedureContract();
        break;
      default:
        console.log('no case');
    }
  }

  public syncProcedureContract() {
    this.uiService.displayLoader();
    this.contractService.fetchProcedureContracts(false, false, this.procedureContract.indskr_procedurecontractid).then((r) => {
      this.procedureContract = this.contractService.procedureContracts.find((pr) => pr.indskr_procedurecontractid === this.procedureContract.indskr_procedurecontractid);
      this.initAllDataModels();
      this.setFlags();
      if (this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) this.uiService.dismissLoader();
      this.fetchAssociatedProcedureLogs();
      if (!this.procedureContract.indskr_parentprocedurecontractid) {
        this.contractService.setChildContracts(this.procedureContract.indskr_procedurecontractid);
        this.initChildContractList();
      }
    });
  }

  async createProcedureContract() {
    // let payload = this.contractService.createContractPayload(this.procedureContract);
    // if (!this.device.isOffline) {
    //   this.uiService.displayLoader();
    //   try {
    //     const response: any = await this.contractService.createProcedureContractOnline(payload);
    //     if (response) {
    //       this.viewMode = ComponentViewMode.READONLY;
    //       this.procedureContract.indskr_procedurecontractid = response.indskr_procedurecontractid;
    //       this.contractService.isProcedureContractCreationActive = false;
    //       // this.footerService.enableButtons(['downloadPDF', 'uploadPDF']);
    //       this.uiService.dismissLoader();
    //     }
    //   } catch (error) {
    //     this.uiService.dismissLoader();
    //     this.notificationService.notify(this.translate.instant('PROCEDURE_CONTRACT_CREATION_FAILED'), 'Procedure contract', 'top', ToastStyle.DANGER);
    //     return
    //   }
    // }
    // else {
    //   this.procedureContract.pendingPushToDynamics = true;
    //   this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
    //   this.viewMode = ComponentViewMode.READONLY;
    //   this.procedureContract.isNew = true;
    //   this.contractService.isProcedureContractCreationActive = false;
    // }
    // this.procedureContract.indskr_name = this.contractService.createProcedureContractName(this.procedureContract);
    // this.notificationService.notify(this.translate.instant('THE_RECORD_IS_CREATED_SUCCESSFULLY'), 'Procedure contract',
    //   'top', ToastStyle.INFO);
    // await this.contractService.CreateProcedureContractInLocal(this.procedureContract);
    // this.initAllDataModels();
  }

  private _closePage(): void {
    if (this.navService.getActiveChildNavName() === ChildNavNames.SurgeryContractNavigation) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.SurgeryOrderDetailsComponent);
      this.uiService.showRightPane = false;
      this.contractService.isRightPaneNavActive = false;
    } else {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  async launchSignaturePad(signatureValue) {
    if (this.viewMode === ComponentViewMode.PREVIEW) return;
    if (!this.procedureContract.indskr_consenttaken || !this.procedureContract.indskr_complywithhospitalrulesandregulations) {
      this.notificationService.notify(this.translate.instant('PLEASE_CAPTURE_CONSENT'), ToastStyle.INFO);
      return;
    }
    //signature capture
    let popover = await this.popoverCtrl.create({
      component: ConsentSignatureModalComponent,
      componentProps: {},
      cssClass: 'signature-pad-procedure-contract',
      backdropDismiss: false,
    });
    popover.present();
    popover.onDidDismiss().then(async (signData: any) => {
      let data = signData.data;
      if (data && data.completedFlow) {
        let procedureContract = this.contractService.findProcedureContractById(this.procedureContract.indskr_procedurecontractid);
        let payload;
        if (signatureValue === "preSignature") {
          procedureContract.indskr_presurgerysignature = data.signatureData;
          payload = {
            indskr_presurgerysignature: procedureContract.indskr_presurgerysignature,
            statuscode: PROCEDURE_CONTRACT_STATUS.PRE_SIGNED
          }
        } else if (signatureValue === "postSignature") {
          procedureContract.indskr_postsurgerysignature = data.signatureData
          payload = {
            indskr_postsurgerysignature: procedureContract.indskr_postsurgerysignature,
            statuscode: PROCEDURE_CONTRACT_STATUS.POST_SIGNED,
            statecode: 0
          }
        }

        payload = { ...payload, "indskr_signaturecapturemode": this.procedureContract.indskr_signaturecapturemode };

        if (this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital) {
          const currentDate = new Date();
          if (signatureValue === "preSignature") {
            this.procedureContract.indskr_presurgerysignaturecapturedate = currentDate;
            this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.PRE_SIGNED;
            payload = { ...payload, indskr_presurgerysignaturecapturedate: currentDate.getTime() }
          } else if (signatureValue === "postSignature") {
            this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.POST_SIGNED;
            this.procedureContract.indskr_postsurgerysignaturecapturedate = currentDate;
            this.procedureContract.statecode = 0;
            payload = { ...payload, indskr_postsurgerysignaturecapturedate: currentDate.getTime() }
          }
        }

        if (!this.device.isOffline) {
          await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
        } else {
          this.procedureContract.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
        }

        if (procedureContract.indskr_presurgerysignature) {
          this.procedureContract.indskr_presurgerysignature = procedureContract.indskr_presurgerysignature;
          this.procedureContract.indskr_presurgerysignaturecaptured = true;
        }

        if (procedureContract.indskr_postsurgerysignature) {
          this.procedureContract.indskr_postsurgerysignature = procedureContract.indskr_postsurgerysignature;
          this.procedureContract.indskr_postsurgerysignaturecaptured = true;
        }

        this.surgeryOrderDataService.updateProcedureLogsWithContractStatus(procedureContract.indskr_procedurecontractid, this.procedureContract.statuscode)
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract)
        this.initAllDataModels();
      }
    })
  }

  private async openContractSignOptions(myEvent) {
    const contractMethods = this.contractService.procedureSignatureTypes;
    if (contractMethods && contractMethods.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-signature-types',
        isMultipleSelectionEnabled: false,
        data: contractMethods.map(signatureType => {
          return {
            title: signatureType.label,
            id: signatureType.value,
            isSelected: signatureType.value == this.procedureContract.indskr_signaturecapturemode,
          }
        }),
      };

      const procedureTypePopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent
      });

      await procedureTypePopover.present();

      await procedureTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length === 0) return;

        if (!this.device.isOffline) {
          await this.contractService.UpdateProcedureContractOnline({
            indskr_signaturecapturemode: data.selectedItems[0].id,
            indskr_presurgerysignaturecapturedate: "",
            indskr_postsurgerysignaturecapturedate: "",
            indskr_validatornamepresurgerysignature: "",
            indskr_validatornamepostsurgerysignature: "",
            indskr_contact: "",
            // indskr_complywithhospitalrulesandregulations: false,
            // indskr_consenttaken: false
          }, this.procedureContract.indskr_procedurecontractid);
        } else {
          this.procedureContract.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
        }

        this.resetPresurgerySection();
        this.resetPostsurgerySection();
        this.procedureContract.indskr_signaturecapturemode = data.selectedItems[0].id;
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);

        if (data.selectedItems[0].id === CONTRACT_SIGNATURE_MODE.Manual) {
          this.footerService.enableButtons(['downloadPDF', 'uploadPDF']);
        } else {
          this.footerService.disableButton(['uploadPDF']);
        }
        this.initAllDataModels();
        this.initFooter();
      });
    }
  }

  async openOptionModal(): Promise<boolean | any> {
    const modal = await this.modalController.create({
      component: OmniOptionAlertComponent,
      cssClass: 'omni-option-modal',
      componentProps: {
        title: this.translate.instant('UPLOAD_DOCUMENT'),
        note: this.translate.instant('NOTE_CONTRACT'),
        buttonLabel: this.translate.instant('CONFIRM'),
        options: this.setOptionsForModal()
      }
    });

    await modal.present();
    return await modal.onDidDismiss().then(({ data }) => {
      if (!data || data.length === 0) return false;
      return data;
    });
  }

  private setOptionsForModal() {
    let options = [];
    if (this.procedureContract.indskr_parentprocedurecontractid) {
      options.push({
        label: this.translate.instant('POST_SURGERY_SIGNATURE_CAPTURED'),
        value: 2,
        isChecked: this.procedureContract.indskr_postsurgerysignaturecaptured
      })
    } else {
      options.push(
        {
          label: this.translate.instant('PRE_SURGERY_SIGNATURE_CAPTURED'),
          value: 1,
          isChecked: this.procedureContract.indskr_presurgerysignaturecaptured
        }, {
        label: this.translate.instant('POST_AND_POST_SURGERY_SIGNATURE_CAPTURED'),
        value: 2,
        isChecked: this.procedureContract.indskr_presurgerysignaturecaptured && this.procedureContract.indskr_postsurgerysignaturecaptured
      }
      )
    }
    return options;
  }

  async footerButtonClicked(buttonId: string) {
    if (buttonId === 'uploadPDF') {
      this.attachInput.nativeElement.click();
    } else if (buttonId === 'downloadPDF') {
      this.downloadGeneratedContract();
    }
  }

  // to download pre and post contract
  public async downloadContracts(flag) {
    try {
      let resp;
      let fileName;
      if (flag == 'pre') {
        fileName = this.procedureContract.indskr_presurgerysignaturedocument.match(/\/([^\/]+)$/)[1];
      } else {
        fileName = this.procedureContract.indskr_postsurgerysignaturedocument.match(/\/([^\/]+)$/)[1];
      }
      await this.uiService.displayLoader();
      resp = await this.contractService.downloadContractDocument(this.procedureContract, fileName);
      await this.uiService.dismissLoader();
      if (resp) this.notificationService.notify(this.translate.instant('FILE_DOWNLOADED'), 'Procedure contract', 'top', ToastStyle.INFO);
    } catch (error) {
      this.uiService.dismissLoader();
      console.log('error in comp', error)
    }
  }

  public async downloadGeneratedContract() {
    try {
      let resp;
      await this.uiService.displayLoader();
      resp = await this.contractService.downloadGeneratedContract(this.procedureContract);
      await this.uiService.dismissLoader();
      if (resp) this.notificationService.notify(this.translate.instant('FILE_DOWNLOADED'), 'Procedure contract', 'top', ToastStyle.INFO);
    } catch (error) {
      this.uiService.dismissLoader();
      console.log('error in comp', error)
    }
  }

  async loadContractFromDevice(event) {
    if (!event || !event.target || !event.target.files || event.target.files.length === 0) return;
    try {
      if ((event.target.files[0].size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
        if (CONTRACT_SUPPORTED_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)) {
          const attachmentFile = event.target.files[0];

          const selectedOptions = await this.openOptionModal();
          if (!selectedOptions) return;

          const preAndPostSigned = selectedOptions.find((option) => option.value === 2);
          const onlyPreSigned = selectedOptions.find((option) => option.value === 1);
          this.procedureContract.indskr_presurgerysignaturecaptured = onlyPreSigned ? true : false;
          if (preAndPostSigned) {
            if (!this.procedureContract.indskr_parentprocedurecontractid) {
              this.procedureContract.indskr_presurgerysignaturecaptured = true;
            }
            this.procedureContract.indskr_postsurgerysignaturecaptured = true;
          }
          const contractType = this.contractService.findContractTypeById(this.procedureContract.indskr_contracttypes);
          let payload: any = {
            indskr_postsurgerysignaturecaptured: this.procedureContract.indskr_postsurgerysignaturecaptured,
            indskr_presurgerysignaturecaptured: this.procedureContract.indskr_presurgerysignaturecaptured,
            indskr_name: this.procedureContract.indskr_name,
            statuscode: onlyPreSigned ? PROCEDURE_CONTRACT_STATUS.PRE_SIGNED : PROCEDURE_CONTRACT_STATUS.POST_SIGNED,
            statecode: 0,
            usageType : contractType.indskr_usagetype
          }

          this.uiService.displayLoader();
          const response: any = await this.contractService.uploadContractDocument(attachmentFile, payload, this.procedureContract.indskr_procedurecontractid);
          this.uiService.dismissLoader();
          if (response) {
            if (onlyPreSigned) {
              this.procedureContract.indskr_presurgerysignaturedocument = response.blobUrl;
            } else {
              this.procedureContract.indskr_postsurgerysignaturedocument = response.blobUrl;
            }
            this.procedureContract.statecode = payload.statecode;
            this.procedureContract.statuscode = payload.statuscode;
            this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
            this.notificationService.notify(this.translate.instant('UPLOAD_SUCCESS_CONTRACT'), 'Procedure contract', 'top', ToastStyle.INFO)
            this.initAllDataModels();
            return;
          }
          this.notificationService.notify(this.translate.instant('UPLOAD_FAILED'), 'Procedure contract', 'top', ToastStyle.DANGER)
        } else {
          this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Procedure contract', 'top', ToastStyle.INFO);
        }
      } else {
        this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Procedure contract', 'top', ToastStyle.INFO);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async openSurgerySelect(): Promise<void> {
    // if (!this.isFullReadOnlyMode) {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PROCEDURE'),
      dividerTitle: this.translate.instant('ALL_PROCEDURE'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'surgery'),
      //searchHandler:(text: string) => this._handleProductsComponentSearch(text, 'surgery'),
      data: this.getSurgeriesSelectionData(),
    };
    // }
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getSurgeriesSelectionData() {
    let data: Array<MainCardViewDataModel> = [];
    let masterList = _.cloneDeep(this.surgeryOrderDataService.productHierarchies);
    masterList = masterList.sort((a, b): number => {
      if (a.surgeryName > b.surgeryName) return 1;
      if (a.surgeryName < b.surgeryName) return -1;
      return 0;
    });
    for (let i = 0; i < masterList.length; i++) {
      const item = masterList[i];
      let isSelected = item.surgeryId == this.procedureContract.indskr_procedure_value;
      let obj = {
        id: item.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.surgeryName,
        showArrow: false,
        childItems: [],
      };

      if (item.subProducts) {
        for (let j = 0; j < item.subProducts.length; j++) {
          const subProduct = item.subProducts[j];
          let childSubProduct: any = getProductFromList(subProduct.productId, item.surgeryId, this.procedureContract.indskr_procedure_value, this.isFullReadOnlyMode);
          if (childSubProduct) {
            obj.childItems.push(childSubProduct);
          }
        }
      }
      data.push(obj);
    }

    function getProductFromList(toBeAddedProductId: string, parentID: string, selectedId: string, isFullReadOnlyMode: boolean) {
      const foundSurgeryIdx = masterList.findIndex(a => a.surgeryId == toBeAddedProductId);
      let foundSurgery = masterList[foundSurgeryIdx];
      let prodIdxInTree = data.findIndex(p => p.id == toBeAddedProductId);
      if (prodIdxInTree >= 0) {
        let tobeaddedproduct = data[prodIdxInTree];
        tobeaddedproduct.parentId = parentID;
        data.splice(prodIdxInTree, 1);
        return tobeaddedproduct;
      }
      if (foundSurgery) {
        let isSelected = foundSurgery.surgeryId == selectedId;
        let obj = {
          id: foundSurgery.surgeryId,
          parentID: parentID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: foundSurgery.surgeryName,
          showArrow: false,
          childItems: [],
          arrowType: '',
        };
        let subProducts = foundSurgery.subProducts;
        masterList.splice(foundSurgeryIdx, 1);
        if (subProducts) {
          for (let j = 0; j < foundSurgery.subProducts.length; j++) {
            const subProduct = foundSurgery.subProducts[j];
            let childSubProduct: any = getProductFromList(subProduct.productId, foundSurgery.surgeryId, selectedId, isFullReadOnlyMode);
            if (childSubProduct) {
              obj.childItems.push(childSubProduct);
            }
          }
        }
        return obj;
      } else {
        return null;
      }
    }

    if (this.procedureContract.indskr_procedure_value && !this.surgeryOrderDataService.productHierarchies.some(pro => this.procedureContract.indskr_procedure_value == pro.surgeryId)) {
      console.log(this.currentSurgeryOrderActivity);
      data.push({
        id: this.procedureContract.indskr_procedure_value,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: true,
        endIconType: 'indegene-selectors-checkmark-icon',
        endIconCssClass: 'checkmark-icon',
        primaryTextRight: this.procedureContract.procedureName + '(Inactive)',
        showArrow: false,
        arrowType: '',
      })
    }
    return data;
  }

  private async handleContractTypesField(myEvent) {
    const contractTypes = this.contractService.contractTypes;
    if (contractTypes && contractTypes.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'contract-types',
        isMultipleSelectionEnabled: false,
        data: contractTypes.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_contracttypeid,
            isSelected: type.indskr_contracttypeid == this.procedureContract.indskr_contracttypes,
          }
        }),
      };

      const contractTypePopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'drop-down-list-view', event: myEvent
      });

      await contractTypePopover.present();

      await contractTypePopover.onDidDismiss().then(async ({ data }) => {
        let payload: any = {};
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.procedureContract.indskr_contracttypes === data.selectedItems[0].id) return;
        this.procedureContract.contractTypeString = data.selectedItems[0].title;
        const newContractType = this.contractService.getContractType(data.selectedItems[0].id);

        if (!this.autoNamingDisabled) {
          this.procedureContract.indskr_name = this.contractService.generateContractName(this.procedureContract);
          payload = { ...payload, indskr_name: this.procedureContract.indskr_name };
        }

        payload = {
          ...payload,
          indskr_maximumnoofassistance: 1,
          indskr_enddate: "",
          // indskr_startdate: "",
          indskr_contracttypes: data.selectedItems[0].id,
          indskr_proceduresubtypes: "",
          indskr_presurgerysignaturecapturedate: "",
          indskr_signaturecapturemode: "",
          // indskr_consenttaken: false,
          // indskr_complywithhospitalrulesandregulations: false,
          indskr_validatornamepresurgerysignature: ""
        }

        if (!this.device.isOffline) {
          await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
        } else {
          this.procedureContract.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
        }

        this.procedureContract.indskr_maximumnoofassistance = payload.indskr_maximumnoofassistance;
        this.procedureContract.indskr_enddate = payload.indskr_enddate;
        // this.procedureContract.indskr_startdate = payload.indskr_startdate;
        this.procedureContract.indskr_contracttypes = data.selectedItems[0].id;
        this.procedureContract.indskr_proceduresubtype = null;
        this.procedureContract.proceudreSubTypeString = "";
        this.procedureContractType = newContractType;

        this.procedureContract.indskr_signaturecapturemode = null;
        this.resetPresurgerySection();
        this.resetPostsurgerySection();
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.setFlags();
        this.initAllDataModels();

      });
    }
  }

  private async handleProcedureSubTypesField(myEvent) {
    if (!this.procedureContract.indskr_contracttypes) return;
    const procedureSubTypes = this.surgeryOrderDataService.showAssociatedProcedureSubTypes(this.procedureContract.indskr_contracttypes).sort((a, b) => {
      if (a.indskr_order < b.indskr_order) return -1;
      if (a.indskr_order > b.indskr_order) return 1;
      return a.indskr_name.localeCompare(b.indskr_name);
    });
    if (procedureSubTypes && procedureSubTypes.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-sub-types',
        isMultipleSelectionEnabled: false,
        data: procedureSubTypes.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_proceduresubtypeid,
            isSelected: type.indskr_proceduresubtypeid == this.procedureContract.indskr_proceduresubtype,
          }
        }),
      };

      let procedureSubTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });
      await procedureSubTypePopover.present();

      await procedureSubTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.procedureContract.indskr_proceduresubtype === data.selectedItems[0].id) return;
        let payload: any = {}

        const response = this.setAllocationAndDuration(data.selectedItems[0].id);
        if (response) {
          payload = {
            indskr_proceduresubtypes: data.selectedItems[0].id,
            indskr_maximumnoofassistance: response.indskr_maximumnoofassistance,
            indskr_enddate: response.indskr_enddate ? response.indskr_enddate.getTime() : "",
            indskr_startdate: response.indskr_startdate ? response.indskr_startdate.getTime() : "",
          }
        }

        if (!this.device.isOffline) {
          await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
        } else {
          this.procedureContract.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
        }

        this.procedureContract.indskr_proceduresubtype = data.selectedItems[0].id;
        this.procedureContract['proceudreSubTypeString'] = data.selectedItems[0].title;
        this.procedureContract.indskr_maximumnoofassistance = payload.indskr_maximumnoofassistance;
        this.procedureContract.indskr_enddate = payload.indskr_enddate;
        this.procedureContract.indskr_startdate = payload.indskr_startdate;

        this.procedureContract.indskr_signaturecapturemode = null;
        this.resetPresurgerySection();
        this.resetPostsurgerySection();
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initAllDataModels();
      });
    }
  }

  private async openStartDatePicker(event) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_startdate, this.procedureContract.indskr_enddate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime);
        if (this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) {
          this.procedureContract.indskr_startdate = startOfDay(valueDate);
          this.procedureContract.indskr_enddate = endOfDay(valueDate);
        } else {
          if (this.procedureContract.indskr_enddate && valueDate > this.procedureContract.indskr_enddate) {
            this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'Procedure contract', 'top', ToastStyle.DANGER);
            return;
          }
          this.procedureContract.indskr_startdate = startOfDay(valueDate);
          const procedureSubtype = this.surgeryOrderDataService.findProcedureSubTypeById(this.procedureContract.indskr_proceduresubtype);
          if (procedureSubtype && procedureSubtype.indskr_validity) {
            this.procedureContract.indskr_enddate = endOfDay(subDays(addMonths(this.procedureContract.indskr_startdate, procedureSubtype.indskr_validity), 1));
          }
        }
        let payload = {
          indskr_startdate: this.procedureContract.indskr_startdate.getTime(),
          indskr_enddate: this.procedureContract.indskr_enddate ? this.procedureContract.indskr_enddate.getTime() : ""
        }
        try {
          if (!this.device.isOffline) {
            await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
          } else {
            this.procedureContract.pendingPushToDynamics = true;
            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
          }
          this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
          this.initAllDataModels();
        } catch (error) {
          console.log('Procedure contract date updation failed');
        }
      }
    });
  }

  private async openEndDatePicker(event) {
    if (!this.procedureContract.indskr_startdate) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_startdate, this.procedureContract.indskr_enddate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.endTime)) {
        const valueDate = new Date(obj.data.endTime);
        if (this.procedureContract.indskr_startdate > valueDate) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'New Account Plan', 'top', ToastStyle.DANGER);
        } else {
          this.procedureContract.indskr_enddate = valueDate;
          let payload = {
            indskr_enddate: this.procedureContract.indskr_enddate.getTime()
          }
          try {
            if (!this.device.isOffline) {
              await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
            } else {
              this.procedureContract.pendingPushToDynamics = true;
              this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
            }
            this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
            this.initAllDataModels();
          } catch (error) {
            console.log('Procedure contract date updation failed');
          }
        }
      }
    })
  }

  private async openPresignatureDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_presurgerysignaturecapturedate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = startOfDay(new Date(obj.data.startTime));
        let procedureStartDate = new Date(this.procedureContract.indskr_startdate).getTime();
        const procedureEndDate = new Date(this.procedureContract.indskr_enddate).getTime();
        const postsignatureDate = new Date(this.procedureContract.indskr_postsurgerysignaturecapturedate).getTime();

        if (postsignatureDate && postsignatureDate < valueDate.getTime()) {
          this.notificationService.notify(this.translate.instant('PRE_SIGNATURE_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
          return;
        }

        if (procedureStartDate <= valueDate.getTime() && procedureEndDate >= valueDate.getTime()) {
          if (!this.device.isOffline) {
            await this.contractService.UpdateProcedureContractOnline({
              indskr_presurgerysignaturecapturedate: valueDate.getTime(),
            }, this.procedureContract.indskr_procedurecontractid);
          } else {
            this.procedureContract.pendingPushToDynamics = true;
            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
          }
          this.procedureContract.indskr_presurgerysignaturecapturedate = valueDate;
          await this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, {
            indskr_presurgerysignaturecapturedate: valueDate.getTime(),
          });

          this.initAllDataModels();
        } else {
          this.notificationService.notify(this.translate.instant('SURGERY_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
        }
      }
    })
  }

  private async openPostsignatureDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_postsurgerysignaturecapturedate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = endOfDay(new Date(obj.data.startTime));
        let procedureStartDate = new Date(this.procedureContract.indskr_startdate).getTime();
        const procedureEndDate = new Date(this.procedureContract.indskr_enddate).getTime();
        const presignatureDate = new Date(this.procedureContract.indskr_presurgerysignaturecapturedate).getTime();

        if (presignatureDate > valueDate.getTime()) {
          this.notificationService.notify(this.translate.instant('POST_SIGNATURE_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
          return;
        }

        if (procedureStartDate <= valueDate.getTime() && procedureEndDate >= valueDate.getTime()) {
          if (!this.device.isOffline) {
            await this.contractService.UpdateProcedureContractOnline({
              indskr_postsurgerysignaturecapturedate: valueDate.getTime(),
            }, this.procedureContract.indskr_procedurecontractid);

          } else {
            this.procedureContract.pendingPushToDynamics = true;
            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
          }
          this.procedureContract.indskr_postsurgerysignaturecapturedate = valueDate;
          await this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, {
            indskr_postsurgerysignaturecapturedate: valueDate.getTime(),
          });

          this.initAllDataModels();
        } else {
          this.notificationService.notify(this.translate.instant('SURGERY_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
        }
      }
    })
  }

  private async getDateTimePopover(startDate, endDate?) {
    return await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ProcedureContract,
          startDateTimeValue: startDate ? moment(startDate).format() : null,
          endDateTimeValue: endDate ? moment(endDate).format() : null,
        },
        cssClass: "datetime-popover"
      }
    );
  }

  private openProductSelect(): void {
    if (this.isFullReadOnlyMode || this.partialReadOnlyMode) return;

    let products = [];

    // if (!this.hideProcedure) {
    //   let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
    //     .find(p => p.surgeryId == this.procedureContract.indskr_procedure_value).productCategories
    //   products = this.getProductsSelectionData(selectedProdCategories);
    // } else {
    // fetching sku products
    products = this.getBrandProductsSelectionData();
    // }

    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
      // searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'product'),
      data: products
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
  }

  private async _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (data && data.isDone) {
        if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
          switch (fieldName) {
            case 'surgery':
              let surgeryProd = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == data.selectedItems[0].id)
              if (surgeryProd && this.procedureContract.indskr_procedure_value !== surgeryProd.surgeryId) {
                const payload = {
                  indskr_procedure_value: surgeryProd.surgeryId
                }
                if (!this.device.isOffline) {
                  await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
                } else {
                  this.procedureContract.pendingPushToDynamics = true;
                  this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
                }
                this.procedureContract.indskr_procedure_value = surgeryProd.surgeryId;
                this.procedureContract.procedureName = surgeryProd.surgeryName;
                this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
                this.initAllDataModels();
              }
              break;
            case 'product':
              // if (this.hideProcedure) {
              // let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
              //   .find(p => p.surgeryId == this.procedureContract.indskr_procedure_value)
              //   .productCategories
              // let selectedProdCategoriesProducts = [];
              // selectedProdCategories.forEach(pc => {
              //   selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
              // })
              data.selectedItems.forEach(async item => {
                let foundProduct = this.procedureContractPositionGroupProducts.find(prod => prod.ID == item.id);
                if (foundProduct) {
                  const payload = {
                    indskr_product_value: foundProduct.ID,
                    indskr_productid: foundProduct.productnumber
                  }
                  if (!this.device.isOffline) {
                    await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
                  } else {
                    this.procedureContract.pendingPushToDynamics = true;
                    this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
                  }
                  this.procedureContract.indskr_product_value = foundProduct.ID;
                  this.procedureContract.productName = foundProduct.name;
                  this.procedureContract.indskr_productid = foundProduct.productnumber.toString();
                  this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
                  this.initAllDataModels();
                }
              });
            // } else {
            //   let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
            //     .find(p => p.surgeryId == this.procedureContract.indskr_procedure_value)
            //     .productCategories
            //   let selectedProdCategoriesProducts = [];
            //   selectedProdCategories.forEach(pc => {
            //     selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
            //   })
            //   data.selectedItems.forEach(async item => {
            //     let foundProduct = selectedProdCategoriesProducts.find(prod => prod.productId == item.id && prod.productCategoryId == item.parentID);
            //     if (foundProduct) {
            //       const payload = {
            //         indskr_product_value: foundProduct.productId,
            //         indskr_productid: foundProduct.productNumber
            //       }
            //       if (!this.device.isOffline) {
            //         await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
            //       } else {
            //         this.procedureContract.pendingPushToDynamics = true;
            //         this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
            //       }
            //       this.procedureContract.indskr_product_value = foundProduct.productId;
            //       this.procedureContract.productName = foundProduct.productName;
            //       this.procedureContract.indskr_productid = foundProduct.productNumber;
            //       this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
            //       this.initAllDataModels();
            //     }
            //   });
            // }
          }
        }
        else if (data.selectedItems.length == 0) {
          let payload: any;
          switch (fieldName) {
            case 'surgery':
              payload = {
                indskr_procedure_value: "",
              }
              if (!this.device.isOffline) {
                await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
              } else {
                this.procedureContract.pendingPushToDynamics = true;
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
              }
              this.procedureContract.indskr_procedure_value = '';
              this.procedureContract.procedureName = ''
              break;
            case 'product':
              payload = {
                indskr_product_value: "",
                indskr_productid: ""
              }
              if (!this.device.isOffline) {
                await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
              } else {
                this.procedureContract.pendingPushToDynamics = true;
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
              }
              this.procedureContract.indskr_product_value = '';
              this.procedureContract.productName = '';
              this.procedureContract.indskr_productid = '';
              this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
              break;
          }
        }
        this.initAllDataModels();
      }
    }
  }

  public setAllocationAndDuration(procedureSubtTypeId) {
    const procedureSubtype = this.surgeryOrderDataService.findProcedureSubTypeById(procedureSubtTypeId);
    let indskr_enddate = null;
    let indskr_startdate = this.procedureContract.indskr_startdate ? new Date(this.procedureContract.indskr_startdate) : null;
    let indskr_maximumnoofassistance = 1;

    if (!procedureSubtype) {
      return ({ indskr_maximumnoofassistance, indskr_startdate, indskr_enddate })
    }

    if (procedureSubtype.indskr_procedureallocated) {
      indskr_maximumnoofassistance = procedureSubtype.indskr_procedureallocated;
    }

    if (procedureSubtype.indskr_validity) {
      indskr_startdate = startOfDay(indskr_startdate ? new Date(this.procedureContract.indskr_startdate) : new Date());
      indskr_enddate = endOfDay(subDays(addMonths(indskr_startdate, procedureSubtype.indskr_validity), 1));
    }

    return ({ indskr_maximumnoofassistance, indskr_startdate, indskr_enddate });
  }

  private navigateToCreateChildContract(startDate) {
    let item = this.contractService.generateContract(
      this.procedureContract.indskr_account_value, this.procedureContract.accountName, this.procedureContract);

    item.indskr_startdate = startDate;
    item.indskr_enddate = lastDayOfMonth(item.indskr_startdate)
    // if month is last set parent last date as end date of child contract
    if (startDate.getMonth() === new Date(this.procedureContract.indskr_enddate).getMonth()) {
      item.indskr_enddate = new Date(this.procedureContract.indskr_enddate);
    }

    this.contractService.isProcedureContractCreationActive = true;
    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent,
      PageName.SurgeryOrderContractDetailsComponent, PageName.AccountPageComponent,
      {
        procedureContract: item,
        viewMode: ComponentViewMode.ADDNEW
      });
  }

  public onChildContractHeaderClick(id) {
    if (id === 'plus-icon') {
      this.showYearMonthPopup();
    }
  }

  viewChildContract(index) {
    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent, PageName.SurgeryOrderContractDetailsComponent, PageName.AccountPageComponent,
      {
        procedureContract: this.childContracts[index],
        viewMode: ComponentViewMode.READONLY
      });
  }

  private async  showYearMonthPopup() {
    let alradyCreatedMonths = this.childContracts.map((cont) => {
      let startDate = new Date(cont.indskr_startdate);
      startDate.setHours(0, 0, 0);
      return startDate.getTime();
    });

    let parentCoveredYears = [];
    let minYear = new Date(this.procedureContract.indskr_startdate).getFullYear();
    let maxYear = new Date(this.procedureContract.indskr_enddate).getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      parentCoveredYears.push(i);
    }

    let minDate = new Date(this.procedureContract.indskr_startdate);
    minDate.setHours(0, 0, 0);
    let maxDate = new Date(this.procedureContract.indskr_enddate);
    maxDate.setHours(0, 0, 0);

    const modal = await this.modalController.create({
      component: OmniYearMonthPickerComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: 'Select Child Procedure Contracts',
        buttonLabel: this.translate.instant('CONFIRM'),
        isMultiSelect: false,
        params: {
          selectedMonths: alradyCreatedMonths,
          selectedYear: null,
          inputYears: parentCoveredYears,
          min: minDate,
          max: maxDate
        }
      }
    });

    await modal.present();

    await modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.navigateToCreateChildContract(new Date(data[0]))
      }
    });
  }

  private initViewForChildContract() {
    this.displayChildContracts = [];
    this.childContracts.forEach((item) => {
      let newChildContract: ProcedureLogData
      newChildContract = {
        headerText: item.indskr_name,
        values: [
          {
            label: this.translate.instant('MONTH'),
            value: this.datePipe.transform(item.indskr_startdate, 'MMMM'),
            placeholderText: this.translate.instant('NO_MONTH'),
            showLines: true,
          },
          {
            label: this.translate.instant('YEAR'),
            value: this.datePipe.transform(item.indskr_startdate, 'yyyy'),
            placeholderText: this.translate.instant('NO_YEAR'),
            showLines: true,
          }
        ]
      }
      this.displayChildContracts.push(newChildContract);
    })
  }

  private openSpeciality() {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('SPECIALITY'),
      dividerTitle: this.translate.instant('ALL_SPECIALTIES'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleSpecialityComponentEvent(data, eventTarget, refData),
      //searchHandler:(text: string) => this._handleProductsComponentSearch(text, 'surgery'),
      data: this.getSpecialitySelectionData(),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getSpecialitySelectionData() {
    let data = [];
    if (this.specialities.length == 0) return [];
    let filteredSpecialities = this.specialities.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    filteredSpecialities.forEach((speciality: Specialty) => {
      let isSelected = speciality.id == this.procedureContract.indskr_Specialty;
      data.push({
        id: speciality.id,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: speciality.name,
        showArrow: false,
        arrowType: '',
      });
    })
    return data;
  }

  private async _handleSpecialityComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    try {
      if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (data && data.isDone) {
          let payload = {
            indskr_Specialty: data.selectedItems[0] ? data.selectedItems[0].id : ""
          }
          if (!this.device.isOffline) {
            await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
          } else {
            this.procedureContract.pendingPushToDynamics = true;
            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
          }
          this.procedureContract.indskr_Specialty = data.selectedItems[0] ? data.selectedItems[0].id : "";
          this.procedureContract.specialityName = data.selectedItems[0] ? data.selectedItems[0].primaryTextRight : "";
          this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
          this.initAllDataModels();
        }
      }
    } catch (error) {
      console.log('Customer speciality update failed in procedure contract');
    }
  }

  private openCustomerAddresses() {
    // if (!this.isFullReadOnlyMode) {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('ADDRESS'),
      dividerTitle: this.translate.instant('ALL_ADDRESS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleCustomerAddressComponentEvent(data, eventTarget, refData),
      //searchHandler:(text: string) => this._handleProductsComponentSearch(text, 'surgery'),
      data: this.getCustomerAddressSelectionData(),
    };
    // }
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getCustomerAddressSelectionData() {
    let data = [];
    let filteredCustomerAddress = this.customerAddresses.filter((speciality: customerAddress) => speciality.accountId === this.procedureContract.indskr_account_value);

    filteredCustomerAddress.forEach((customerAddress: customerAddress) => {
      let isSelected = customerAddress.customerAddressId == this.procedureContract.indskr_Address;
      data.push({
        id: customerAddress.customerAddressId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: customerAddress.customerAddressString,
        showArrow: false,
        arrowType: '',
      });
    })
    return data;
  }

  private async _handleCustomerAddressComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    try {
      if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (data && data.isDone) {
          const payload = {
            indskr_Address: data.selectedItems[0] ? data.selectedItems[0].id : ""
          }
          if (!this.device.isOffline) {
            await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
          } else {
            this.procedureContract.pendingPushToDynamics = true;
            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
          }
          this.procedureContract.indskr_Address = data.selectedItems[0] ? data.selectedItems[0].id : "";
          this.procedureContract.customerAddressName = data.selectedItems[0] ? data.selectedItems[0].primaryTextRight : "";
          this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
          this.initAllDataModels();
        }
      }
    } catch (error) {
      console.log('error while updating customer address in procedure contract')
    }
  }

  public async onConsent(ev) {
    if (this.isFullReadOnlyMode) return;
    let consent = ev;
    if (!this.device.isOffline) {
      await this.contractService.UpdateProcedureContractOnline({
        indskr_consenttaken: consent,
      }, this.procedureContract.indskr_procedurecontractid);
    } else {
      this.procedureContract.pendingPushToDynamics = true;
      this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
    }

    this.procedureContract.indskr_consenttaken = consent;
    await this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
    this.initAllDataModels();
  }

  public async onComply(ev) {
    if (this.isFullReadOnlyMode) return;
    let comply = ev;

    if (!this.device.isOffline) {
      await this.contractService.UpdateProcedureContractOnline({
        indskr_complywithhospitalrulesandregulations: comply,
      }, this.procedureContract.indskr_procedurecontractid);
    } else {
      this.procedureContract.pendingPushToDynamics = true;
      this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
    }

    this.procedureContract.indskr_complywithhospitalrulesandregulations = comply
    await this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid,this.procedureContract);
    this.initAllDataModels();
  }

  public async showCancelProcedureContractPopover() {
    return await this.alertService.showAlert({
      title: this.translate.instant('CANCEL_PROCEDURE_CONTRACT'),
      message: this.translate.instant('PROCEDURE_CONTRACT_CANCEL'),
    }, this.translate.instant('OK')
    ).then(res => {
      return res;
    });
  }

  private async cancelProcedureContract() {
    if (this.device.isOffline) return;

    const payload = {
      statuscode: PROCEDURE_CONTRACT_STATUS.CANCELLED,
      statecode: 1
    }
    try {
      await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
      // this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.CANCELLED;
      // this.procedureContract.statecode = 1;
      this.contractService.removeProcedureContract(this.procedureContract.indskr_procedurecontractid);
      this._closePage();
      // this.initAllDataModels();
    } catch (error) {
      console.log('Error while cancelling procedure contract', error);
    }
  }

  public getBrandProductsSelectionData() {
    let data = [];
    this.procedureContractPositionGroupProducts.forEach(prod => {
      let isSelected = false;
      if (this.procedureContract && this.procedureContract.indskr_product_value
        && this.procedureContract.indskr_product_value === prod.ID) {
        isSelected = true;
      }
      data.push({
        id: prod.ID,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isFullReadOnlyMode || !this.partialReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: prod.name,
        showArrow: false,
        arrowType: '',
      });
    })
    return data;
  }

  private openCustomerSelect(): void {
    if (this.isFullReadOnlyMode) return;

    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'contactDetails';
    this.uiService.showNewActivity = false;
    this.contactService.accessedContactListFrom = PageName.SurgeryOrderContractDetailsComponent;
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
      {
        type: 'PushedContactSelectionView',
        selectedContactIds: [this.procedureContract.indskr_contact],
        selectedAccountIds: [{ id: this.procedureContract.indskr_account_value }],
        callbackEvent: (data: any) => this._handleContactComponentCallback(data)
      }, PageName.DynamicFormComponent, { animate: false }, null);
  }

  private async _handleContactComponentCallback(data) {
    try {
      if (!data || !data.selectedItems) return;
      let payload;

      if (data.selectedItems.length == 0) {
        payload = { indskr_contact: "" }
        if (this.procedureContract.indskr_validatornamepresurgerysignature == this.procedureContract.contactName) {
          payload = { ...payload, indskr_validatornamepresurgerysignature: "" }
        }
      } else {
        payload = { indskr_contact: data.selectedItems[0].ID, indskr_validatornamepresurgerysignature: data.selectedItems[0].fullname };
      }

      if (!this.device.isOffline) {
        await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
      } else {
        this.procedureContract.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }

      if (data.selectedItems.length == 0) {
        if (this.procedureContract.indskr_validatornamepresurgerysignature == this.procedureContract.contactName) {
          this.procedureContract.indskr_validatornamepresurgerysignature = null;
        }
        this.procedureContract.indskr_contact = null;
        this.procedureContract.contactName = null;
      } else {
        this.procedureContract.indskr_contact = data.selectedItems[0].ID;
        this.procedureContract.contactName = data.selectedItems[0].fullname;
        this.procedureContract.indskr_validatornamepresurgerysignature = data.selectedItems[0].fullname;
      }
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    } catch (error) {
      console.log(error);
    }
  }

  private setFlags() {
    if (!this.procedureContract.indskr_contracttypes) return;
    const contractType = this.contractService.findContractTypeById(this.procedureContract.indskr_contracttypes);
    if (!contractType) return;
    const procedureSubTypes = this.surgeryOrderDataService.showAssociatedProcedureSubTypes(this.procedureContract.indskr_contracttypes);
    this.shouldShowPresurgerySection = contractType.indskr_presignature;
    this.shouldShowPostsurgerySection = contractType.indskr_postsignature;
    this.isProcedureSubtypeAvailable = procedureSubTypes.length > 0;
  }

  private resetPresurgerySection() {
    this.procedureContract.contactName = null;
    this.procedureContract.indskr_contact = null;
    this.procedureContract.indskr_validatornamepresurgerysignature = null;
    this.procedureContract.indskr_presurgerysignaturecapturedate = null;
    // this.procedureContract.indskr_consenttaken = false;
    // this.procedureContract.indskr_complywithhospitalrulesandregulations = false;
  }

  private resetPostsurgerySection() {
    this.procedureContract.indskr_validatornamepostsurgerysignature = null;
    this.procedureContract.indskr_postsurgerysignaturecapturedate = null;
  }

  public async showAcitvateProcedureContractPopover() {
    const modal = await this.modalCtrl.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: this.translate.instant('ACTIVATE_PROCEDURE_CONTRACT'),
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        notificationMessage: this.translate.instant('ACTIVATE_PROCEDURE_CONTRACT_MESSAGE')
      }
    });

    modal.componentProps.buttons = [{ label: this.translate.instant('CONFIRM'), role: 'ok' }]
    await modal.present();

    return await modal.onDidDismiss().then(({ data }) => {
      if (!data) return false;
      if (data.role === 'ok') return true;
      else return false;
    });
  }

  private async activateProcedureContract() {
    const payload = {
      statuscode: PROCEDURE_CONTRACT_STATUS.ACTIVE,
      statecode: 0
    }
    try {
      if (!this.device.isOffline) {
        await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
      } else {
        this.procedureContract.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }
      this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.ACTIVE;
      this.procedureContract.statecode = 0;
      this.procedureContract.statusString = this.translate.instant('ACTIVE')
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);

      this.initAllDataModels();

    } catch (error) {
      console.log('Error while cancelling procedure contract', error);
    }
  }

  // ----------- config fields ------------ //

  private initConfigFields(configuration) {
    const configuredField: ConfiguredFields = configuration;
    const id = getConfigFormFieldViewDataModelId(configuredField.fieldType, configuredField.fieldName);
    const { inputText, inputValue } = this.getConfigFieldInputTextAndValue(configuredField.fieldType, configuredField.fieldName);
    const formFieldType = getConfigFormFieldType(configuredField.fieldType);
    const forceReadOnly: boolean = ReadOnlyEventConfiguredFieldNames.includes(configuredField.fieldName);
    const isReadOnly: boolean = forceReadOnly || getConfigFormFieldIsReadOnly(configuredField.fieldType, configuredField.readOnly) || this.shouldConfiguredFieldReadonly(configuredField);
    const isDisabled: boolean = formFieldType === FormFieldType.POPOVER_SELECT && configuredField.readOnly
      ? true
      : ( this.shouldConfiguredFieldReadonly(configuredField) || configuredField.readOnly);
    const isRequired: boolean = !this.isFullReadOnlyMode && configuredField.mandatory === 'true';
    const isEmptyRequiredField: boolean = this.requiredFields[id]?.isEvaluated === true
      && (inputText === '' || inputText === undefined || inputText === null);
    const field: IndFormFieldViewDataModel = {
      id,
      formFieldType,
      label: configuredField.fieldLabel,
      inputType: getConfigFormInputType(configuredField.fieldType),
      inputText,
      inputValue,
      placeholderLabel: this.shouldConfiguredFieldReadonly(configuredField) ? `${this.translate.instant('NO')} ${configuredField.fieldLabel}` : configuredField.fieldLabel,
      isReadOnly: isReadOnly || configuredField.fieldType === 'DateTime',
      isDisabled,
      isRequired,
      showArrow:  !this.isFullReadOnlyMode && !isDisabled && !forceReadOnly,
      errorMessage: isRequired ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField,
      eventHandler: (id: string, event, eventName) => this._handleConfigFieldEvent(
        id,
        event,
        eventName,
        configuredField.fieldType,
        configuredField.fieldName,
      ),
    }
    return field;
  }

  private getConfigFieldInputTextAndValue(fieldType: string, fieldName: string): { inputText: string, inputValue: any } {
    let inputText: string = '';
    let inputValue: any = null;

    // if (
    //   this.currentEvent
    //   && this.currentEvent.configuredFields
    //   && fieldType && fieldName
    // ) {
    const fieldValue = this.procedureContract.configuredFields[fieldName];
    inputValue = fieldValue;

    switch (fieldType) {
      case 'String':
      case 'Memo':
      case 'Currency':
      case 'Money':
      case 'Integer':
      case 'BigInt':
      case 'Decimal':
        if (!(fieldValue === undefined || fieldValue === null)) {
          inputText = '' + fieldValue;
        }
        break;
      case 'Boolean':
        inputText = fieldValue === true ? this.translate.instant('YES') : fieldValue === false ? this.translate.instant('No') : '';
        break;
      case 'DateTime':
        inputText = this.datePipe.transform(fieldValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        inputValue = fieldValue;
        break;
      case 'Picklist':
      case 'Virtual':
        inputText = this.getOptionSetValueText(fieldType, fieldName, fieldValue);
        inputValue = fieldValue;
        break;

      //     // Unsupported
      //     case 'Uniqueidentifier':
      case 'Lookup':
        //       if (fieldName && this.currentEvent) {
        //         if (this.currentEvent.lookupConfiguredFields && this.currentEvent.lookupConfiguredFields.length) {
        //           let fieldFilter = this.currentEvent.lookupConfiguredFields.filter(o => { return o.name === fieldName });
        //           if (fieldFilter && fieldFilter.length) {
        let field = fieldValue;
        if (field && field.stringValue) {
          inputText = field.stringValue;
          inputValue = field.id;
        }
        //           }
        //         }
        //       }
        break;
      //     case 'Owner':
      //     case 'EntityName':
      //       break;
      default:
        console.error('getConfigFieldInputTextAndValue: Unhandled switch case statement: ', fieldType, fieldName);
        break;
      //   }
    }

    return { inputText, inputValue };
  }

  private getOptionSetValueText(fieldType: string, fieldName: string, fieldValue: any): string {
    let valueText: string = '';
    const configFieldOptionValues: ConfigFieldOptionValue[] = this.contractService.configFieldOptionsValues[fieldName];

    if (Array.isArray(configFieldOptionValues)) {
      try {
        if (fieldType === 'Picklist') {
          const option = configFieldOptionValues.find(o => o.value === fieldValue);
          if (option) {
            valueText = option.label;
          }
        } else if (fieldType === 'Virtual' && fieldValue) {
          const fieldValues: number[] = fieldValue.split(',').map(v => Number(v));
          const fieldLabels: string[] = [];
          for (const field of configFieldOptionValues) {
            if (fieldValues.includes(field.value)) {
              fieldLabels.push(field.label);
            }
          }

          if (fieldLabels.length > 0) {
            valueText = fieldLabels[0];

            if (fieldLabels.length > 1) {
              valueText = valueText + ' + ' + (fieldLabels.length - 1);
            }
          }
        }
      } catch (error) {
        console.error(
          'getOptionSetValueText: ',
          error,
          fieldType, fieldName, fieldValue,
        );
      }
    }

    return valueText;
  }

  private async _handleConfigFieldEvent(id, event, eventName, fieldType, fieldName) {
    if (id) {
      const currentValue = this.procedureContract.configuredFields[fieldName] ?? null;

      try {
        switch (fieldType) {
          case 'String':
          case 'Memo':
            if (eventName === 'input_value_confirm') {
              const newValue = event?.target?.value?.length > 0 ? event.target.value : null;
              if (currentValue !== newValue) {
                this.updateConfigFieldsValues(fieldType, fieldName, newValue);
              }
              this._configFieldMandatoryCheck(fieldType, fieldName);
            }
            break;
          case 'Integer':
          case 'BigInt':
          case 'Decimal':
          case 'Money':
          case 'Currency':
            if (eventName === 'input_value_confirm') {
              let newValue: any = null;
              if (
                event?.target?.value !== null
                && event?.target?.value !== undefined
                && event?.target?.value !== ''
              ) {
                try {
                  newValue = Number(event.target.value);
                } catch (error) {
                  console.error('_handleConfigFieldEvent: not a number: ', error, event, fieldType, fieldName);
                }
              }
              this.updateConfigFieldsValues(fieldType, fieldName, newValue);
              this._configFieldMandatoryCheck(fieldType, fieldName);

            }
            break;
          case 'Boolean':
          case 'Picklist':
          case 'Virtual':
            this._openConfigFieldOptionSetPopover(event, fieldType, fieldName);
            break;

          case 'DateTime':
            let popover = await this.getDateTimePopover(currentValue);
            this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
            popover.componentProps.currentViewPage = CurViewPageType.ConfiguredForm
            popover.present();

            popover.onDidDismiss().then(async (obj: any) => {
              if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
                this.updateConfigFieldsValues(fieldType, fieldName, obj.data.startTime);
                this._configFieldMandatoryCheck(fieldType, fieldName);
              }
            });
            break;
          case 'Lookup':
            this.openLookup(event, fieldName);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('_handleConfigFieldEvent: ', error, event, fieldType, fieldName);
      }
    }
  }

  private async _openConfigFieldOptionSetPopover(event, fieldType: string, fieldName: string) {
    const configuredFieldOptionValues = this.contractService.configFieldOptionsValues[fieldName] ?? [];

    // const configuredFieldOptionValues = fieldType === 'Boolean'
    // ? this.booleanConfigFieldOptionValues
    // : this.eventsToolService.configFieldOptionsValues[fieldName];

    if (Array.isArray(configuredFieldOptionValues)) {
      let currentSelectedValue = this.procedureContract.configuredFields[fieldName];
      let currentSelectedMultiOptionValues: number[] = [];
      let data: SelectListData[];

      try {
        switch (fieldType) {
          case 'Boolean':
          case 'Picklist':
            data = configuredFieldOptionValues.map(option => (
              {
                id: option.label,
                title: option.label,
                isSelected: currentSelectedValue === option.value,
                value: option.value,
              }
            ));
            break;
          case 'Virtual': {
            if (currentSelectedValue) {
              currentSelectedMultiOptionValues = currentSelectedValue.split(',').map(str => Number(str));
            }

            data = configuredFieldOptionValues.map(option => (
              {
                id: option.label,
                title: option.label,
                isSelected: currentSelectedMultiOptionValues.some(v => v === option.value),
                value: option.value,
              }
            ));
            break;
          }

          default:
            console.warn(
              'event-details: _openConfigFieldOptionSetPopover: unsupported fieldType: ',
              fieldType, fieldName, event
            );
            break;
        }
      } catch (error) {
        console.error('_openConfigFieldOptionSetPopover: ', error, event, fieldType, fieldName);
      }

      if (Array.isArray(data)) {
        const isMultiSelect = fieldType === 'Virtual';
        const viewData: IndDropdownListDetailModel = {
          id: 'configuredPicklist',
          data,
          isMultipleSelectionEnabled: isMultiSelect,
          showCancelandDoneBtn: isMultiSelect,
        };

        const popover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData },
          cssClass: 'dropdown-list-view',
          event: event,
        });
        await popover.present();
        const response = await popover.onDidDismiss();

        //     let shouldCheckForMandatoryField: boolean = true;
        if (response?.data?.isDone === true) {
          if (isMultiSelect) {
            //         if (Array.isArray(response.data.selectedItems)) {
            //           const newValues: number[] = response.data.selectedItems.map(i => i.value);
            //           const diff = difference(currentSelectedMultiOptionValues, newValues)
            //             .concat(difference(newValues, currentSelectedMultiOptionValues));
            //           if (Array.isArray(diff) && diff.length > 0) {
            //             const newValueStr: string = newValues.join();
            //             this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
            //             this.currentEvent.configuredFields[fieldName] = newValueStr;
            //             this._updateConfigFieldInputValues(fieldType, fieldName);
            //             shouldCheckForMandatoryField = false;
            //           }
            //         }
          } else {
            if (
              Array.isArray(response.data.selectedItems)
              && response.data.selectedItems.length > 0
              && currentSelectedValue !== response.data.selectedItems[0].value
            ) {
              this._configFieldMandatoryCheck(fieldType, fieldName);

              this.updateConfigFieldsValues(fieldType, fieldName, response.data.selectedItems[0].value);
              // shouldCheckForMandatoryField = false;
            }
          }
        }



      }
    }
  }

  private async updateConfigFieldsValues(fieldType: string, fieldName: string, inputValue: any) {
    let payload: any = {
      "appconfigfields": [{
        "fieldname": fieldName,
        "datatype": fieldType,
        "value": inputValue
      }]
    }

    if (fieldType === 'DateTime') {
      const offsetDate = new Date(inputValue);
      const tzIndependentDateObj = new Date(
        offsetDate.getUTCFullYear(),
        offsetDate.getUTCMonth(),
        offsetDate.getUTCDate(),
        offsetDate.getUTCHours(),
        offsetDate.getUTCMinutes(),
        0,
        0
      );

      payload.appconfigfields[0].value = inputValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
    } else if (fieldType === 'Lookup') {
      payload.appconfigfields[0] = { ...payload.appconfigfields[0], ...payload.appconfigfields[0].value };
    }

    try {
      if (!this.device.isOffline) {
        await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid)
      } else {
        this.procedureContract.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }

      this.procedureContract.configuredFields[fieldName] = inputValue;
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    } catch (err) {
      console.log('config field updation failed in procedure contract', err);
    }
  }

  private async initConfigFieldsLookupData() {
    let lookupFields = [];
    if (this.authenticationService.user.procedureContractConfiguredFields && this.authenticationService.user.procedureContractConfiguredFields.length > 0) {
      lookupFields = this.authenticationService.user.procedureContractConfiguredFields.filter(a => a.fieldType == 'Lookup');
    }
    if (lookupFields && lookupFields.length > 0) {
      await this.disk.retrieve(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, true).then((doc) => {
        if (doc && doc.raw) {
          this.lookupConfigFieldsData = doc.raw['indskr_procedurecontract'];
        }
      })
    }
    this.lookupFieldsDataInitialized = true;
  }

  public async openLookup(event, fieldName) {
    const configField = this.authenticationService.user.procedureContractConfiguredFields.find(a => a.fieldName == fieldName);
    if (configField) {
      let results = [];
      if (this.device.isOffline) {
        if (!this.lookupFieldsDataInitialized) {
          await this.initConfigFieldsLookupData();
        }
        if (this.lookupConfigFieldsData && this.lookupConfigFieldsData[configField.fieldName]) {
          results = this.lookupConfigFieldsData[configField.fieldName]
        }
      } else {
        results = await this.activityDataService.getConfigFieldLookupResults(configField, null);
      }
      let currentSelectedValue: string;
      // if(this.currentEvent && this.currentEvent.lookupConfiguredFields && this.currentEvent.lookupConfiguredFields.length > 0){
      //   const currentConfigField = this.currentEvent.lookupConfiguredFields.find(a=> a.name == fieldName);
      //   if(currentConfigField && currentConfigField.id){
      //     currentSelectedValue = currentConfigField.id;
      //   }
      // }
      let lookupPopoverModel: IndDropdownListDetailModel = {
        id: 'config-fields-lookup-select',
        isSearchEnabled: true,
        searchInputPlaceholder: this.translate.instant('SEARCH'),
        data: results.map(item => {
          let data: SelectListData = {
            title: item[configField.indskr_lookupentityprimarynameattribute],
            id: item[configField.indskr_lookupentityprimaryidattribute],
            isSelected: currentSelectedValue == item[configField.indskr_lookupentityprimaryidattribute] ? true : false,
          }
          return data;
        })
      };
      let lookupPopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent,
        componentProps: { viewData: lookupPopoverModel },
        cssClass: 'dropdown-list-view',
        event: event
      });
      lookupPopover.onDidDismiss().then(async (data: any) => {
        data = data.data;
        // let shouldCheckForMandatoryField: boolean = true;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != currentSelectedValue) {
          let selectedPopOverValue = data.selectedItems[0].id;
          let selectedPopOverStringValue = data.selectedItems[0].title;
          const input = {
            id: selectedPopOverValue,
            name: fieldName,
            entity: configField.indskr_lookupentitysetname,
            stringValue: selectedPopOverStringValue,
            indskr_referencingentitynavigationpropertyname: configField.indskr_referencingentitynavigationpropertyname,
            fieldname: fieldName,
            datatype: configField.fieldType,
            value: selectedPopOverValue,
            indskr_lookupentitysetname: configField.indskr_lookupentitysetname,
          }
          // this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
          //   let idx = this.currentEvent.lookupConfiguredFields.findIndex(a=> a.name == fieldName);
          //   if(idx>=0){
          //     this.currentEvent.lookupConfiguredFields[idx].id = selectedPopOverValue;
          //     this.currentEvent.lookupConfiguredFields[idx].stringValue = selectedPopOverStringValue;
          //     this.currentEvent.lookupConfiguredFields[idx].entity = configField.indskr_lookupentitysetname;
          //     this.currentEvent.lookupConfiguredFields[idx].indskr_referencingentitynavigationpropertyname = configField.indskr_referencingentitynavigationpropertyname;
          //   }else{
          //     this.currentEvent.lookupConfiguredFields.push({
          //       id:selectedPopOverValue,
          //       name: fieldName,
          //       entity: configField.indskr_lookupentitysetname,
          //       stringValue: selectedPopOverStringValue,
          //       indskr_referencingentitynavigationpropertyname: configField.indskr_referencingentitynavigationpropertyname
          //     })
          //   }
          // this.currentEvent.configuredFields[fieldName] = selectedPopOverStringValue;
          // shouldCheckForMandatoryField = false;
          this._configFieldMandatoryCheck(configField.fieldType, fieldName);
          this.updateConfigFieldsValues(configField.fieldType, configField.fieldName, input);
        }
        lookupPopover = undefined;
        // if (shouldCheckForMandatoryField) {
        //   // Mandatory field check
        //   // this._configFieldMandatoryCheck(configField.fieldType, configField.fieldName);
        // }
      });
      lookupPopover.present();
    }

  }

  private _configFieldMandatoryCheck(fieldType: string, fieldName: string) {
    const fieldId = getConfigFormFieldViewDataModelId(fieldType, fieldName);
    const configFormFieldData = this.findFieldFromGroup(fieldId);

    if (configFormFieldData && configFormFieldData.isRequired) {
      this.requiredFields[configFormFieldData.id] = { isEvaluated: true };
      configFormFieldData.isEmptyRequiredField = !configFormFieldData.inputText ? true : false;
    }
  }

  private findFieldFromGroup(fieldId) {
    if (this.groupedFields) {
      const allFields: any = Object.values(this.groupedFields).flat();
      return allFields.find(f => f.id === fieldId);
    }
  }

  private shouldConfiguredFieldReadonly(configuredField: ConfiguredFields) {
    let shouldReadonly = false;

    if(this.isFullReadOnlyMode) return true;
    if (!configuredField.indskr_sectionname) return shouldReadonly;

    if ((configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.CONTRACT_DETAILS ||
      configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.PROCEDURE_DETAILS) &&
      this.partialReadOnlyMode
    ) {
      shouldReadonly = true;
    } else if (configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.PRE_SURGERY_SIGNATURE &&
      this.procedureContract.indskr_presurgerysignaturecaptured
    ) {
      shouldReadonly = true;
    } else if (configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.POST_SURGERY_SIGNATURE &&
      this.procedureContract.indskr_postsurgerysignaturecaptured
    ) {
      shouldReadonly = true;
    }
    return shouldReadonly;
  }

  // -------- Product ------- //

  private async initProcedureContractPositionProducts() {
    this.procedureContractPositionGroupProducts = [];
    const products = await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS);
    if (products && products.raw && products.raw.length > 0) {
      products.raw.forEach(item => {
        if (item && item['product.productid']) {
          let idx = this.procedureContractPositionGroupProducts.findIndex(p => p.ID == item['product.productid']);
          if (idx < 0) {
            this.procedureContractPositionGroupProducts.push({
              ID: item['product.productid'],
              name: item['product.name'],
              productnumber: item['product.productnumber']
            })
          }
        }
      })
    }
  }

  //----------- setting up view -------------//

  private setupContractView(sectionName: string, coreFieldArray : any) {
    try {
      const coreContractDetailsFields = coreFieldArray;
      const selectedContractType = this.contractService.findContractTypeById(this.procedureContract.indskr_contracttypes);
      let contractConfigFields = [];
      let customFieldWithOrder = [];
      let customFieldWithOutOrder = [];
      let coreFieldsIndex = 0;
      let maxLength = coreContractDetailsFields.length;
      if (selectedContractType) {
        contractConfigFields = this.authenticationService.user.procedureContractConfiguredFields.filter((configuredField) => {
          return selectedContractType.configuredFields.includes(configuredField.appFieldId) && configuredField.indskr_sectionname === sectionName;
        });

        customFieldWithOrder = contractConfigFields.filter((item) => item.order);
        customFieldWithOutOrder = contractConfigFields.filter((item) => !item.order || item.order === 10000);
        maxLength = coreContractDetailsFields.length + contractConfigFields.length;
      }

      for (let i = 1; i <= maxLength; i++) {
        let itemWithOrder = customFieldWithOrder.find((item) => item.order === i);
        if (!this.groupedFields[sectionName]) this.groupedFields[sectionName] = [];

        if (itemWithOrder) {
          this.groupedFields[sectionName].push(this.initConfigFields(itemWithOrder));
        } else if (coreFieldsIndex < coreContractDetailsFields.length) {
          this.groupedFields[sectionName].push(coreContractDetailsFields[coreFieldsIndex++]);
        }
      }
      if(customFieldWithOutOrder.length){
        this.groupedFields[sectionName].push(...customFieldWithOutOrder.map((item) => this.initConfigFields(item)));
      }
    } catch (error) {
      console.log('Error while creating field array in procedure contract', error);
    }
  }

  ngOnDestroy() {
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
    this.footerService.disableButton(['downloadPDF', 'uploadPDF']);
  }
}

export type ProcedureLogData = {
  headerText: string;
  values: DisplayValue[];
}

export enum CONTRACT_SIGNATURE_MODE {
  Manual = 548910000,
  Digital = 548910001
}

export enum CONTRACT_SECTION_NAMES {
  CONTRACT_DETAILS = 'Contract Details',
  PROCEDURE_DETAILS = 'Procedure Details',
  PRE_SURGERY_SIGNATURE = 'Pre Surgery Signature',
  POST_SURGERY_SIGNATURE = 'Post Surgery Signature',
  PROCEDURE_COVERED = "Procedure Covered in This Contract"
}