import { ActivityService } from '@omni/services/activity/activity.service';
import { SearchLookupPopoverComponent } from './../../popover/search-lookup-popover/search-lookup-popover';
import { Component } from '@angular/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndFormFieldViewDataModel, FormFieldType } from '@omni/models/indFormFieldDataModel';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@omni/services/device/device.service';
import { UIService } from '../../../services/ui/ui.service';
import { TrackingEventNames, TrackService } from '@omni/services/logging/tracking.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { PopoverController } from '@ionic/angular';
import { Opportunity, OpportunityCollaborators } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { SearchLookupListDetailModel } from '@omni/models/search-lookup-list-detail-model';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import _ from 'lodash';




@Component({
    selector: 'opportunity-collaborators',
    templateUrl: 'opportunity-collaborators.html',
    styleUrls:['opportunity-collaborators.scss']
})
export class OpportunityCollaboratorsComponent {

    opportunityCollaboratorsPanePageTitle: IndPageTitleViewDataModel;
    userCollaborators: IndFormFieldViewDataModel;
    userRoles: IndFormFieldViewDataModel;
    opportunity: Opportunity;
    private _lookupPopover: HTMLIonPopoverElement;
    private selectedCollaborator: any;
    private selectedRole: any;
    private readonly ngDestroy$: any = new Subject<boolean>();
    private isReadOnlyMode: boolean = false;
    private isCollaboratorsClicked: boolean = false;
    private isRolesClicked: boolean = false;

    constructor(
      public translate: TranslateService,
      public device: DeviceService,
      public trackingService: TrackService,
      public uiService: UIService,
      private navService: NavigationService,
      public opportunityService: OpportunityManagementService,
      private opportunityDataService: OpportunityManagementDataService,
      public popoverCtrl: PopoverController,
      private notificationService: NotificationService,
      private activityService: ActivityService,
    ){

    }

    ngOnInit(){
      this._initPageTitle();
      this.getUserCollaborators();
      this.getUserRoles();
      this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((offline) => {
        this.isReadOnlyMode = offline;
        this._initPageTitle(offline);
      });
    }

    private _initPageTitle(offline: boolean = false): void {
      const buttons = [];
      if(!this.isSplitView){
          buttons.push({
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
          });
      }
      buttons.push({
          id: 'cancel',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: false,
          align: 'right'
        }, {
          id: 'done',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('DONE'),
          isDisabled: this.device.isOffline || offline || !this.selectedCollaborator || !this.selectedRole,
          align: 'right'
      });
      this.opportunityCollaboratorsPanePageTitle = {
          id: 'opportunity-collaborators-page-title',
          title: this.translate.instant('COLLABORATORS'),
          controls: buttons
      };
    }

    public onPageTitleControlClick(id: string) {
      switch (id) {
          case 'close':
                this.onClosePage();
                break;
          case 'cancel':
                this.closepage(false);
              break;
          case 'done':
                this.closepage(true);
              break;
          default:
              console.log('Unhandled switch case statement');
              break;
      }
    }

    private handleFormFieldEvent(id, event, eventName) {
      if (id) {
        switch (id) {
          case 'collaborators-field':
            this._handleCollaboratorSelection(event);
            break;
          case 'roles-field':
            this._handleDropdownListFormField(event);
            break;
          default:
            console.log('Unhandled switch case statement');
            break;
        }
      }
      this.getUserCollaborators();
      this.getUserRoles();
    }

    public getUserCollaborators() {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('COLLABORATORS'),
        customPlaceholderLabel: this.translate.instant('COLLABORATORS'),
        inputText: this.selectedCollaborator ? this.selectedCollaborator.name : '',
        id: 'collaborators-field',
        isReadOnly: true,
        isDisabled: false,
        showArrow: !this.isReadOnlyMode,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: !this.isReadOnlyMode,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isCollaboratorsClicked && _.isEmpty(this.selectedCollaborator),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      this.userCollaborators = viewData;
    }

    public getUserRoles() {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('ROLES'),
        placeholderLabel: this.translate.instant('ROLES'),
        inputText: this.selectedRole ? this.selectedRole.title : '',
        id: 'roles-field',
        isReadOnly: true,
        isDisabled: false,
        showArrow: !this.isReadOnlyMode,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: !this.isReadOnlyMode,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isRolesClicked && _.isEmpty(this.selectedRole),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      this.userRoles = viewData;
    }

    private async _handleCollaboratorSelection(event) {
      if (this.isReadOnlyMode) return;
      this._initLookupSearchList();
      let selectedValueCollaborator = this.selectedCollaborator ? this.selectedCollaborator.id : '';

      let popoverListDetail: SearchLookupListDetailModel = {
        id: 'lookup_search_popover',
        title: this.translate.instant('COLLABORATORS'),
        isSearchEnabled: true,
        selectedValue: selectedValueCollaborator,
        searchInputPlaceholder: this.translate.instant('SEARCH'),
        showUnselectOption: true,
        unselectOptionText: this.translate.instant('SELECT'),
        callbackEvent: (data: any) => this._handleLookupComponentCallback(data),
      };
      let cssClassName = 'lookup-popover-list-view';
      if (this.device.isNativeApp) {
        cssClassName = 'native-lookup-popover-list-view';
      }
      this._lookupPopover = await this.popoverCtrl.create({
        component: SearchLookupPopoverComponent,
        componentProps: { viewData: popoverListDetail, accessedFrom: PageName.OpportunityCollaboratorsComponent},
        cssClass: cssClassName,
        backdropDismiss: true,
        event: this.device.isNativeApp ? event : undefined
      });
      this._lookupPopover.onDidDismiss().then(async (data) => {
        this._lookupPopover = undefined;
        this.opportunityDataService.lookupSearchData = [];
        this.isCollaboratorsClicked = true;
        this.getUserCollaborators();
        this._initPageTitle();
      });
      this._lookupPopover.present();
    }
    // select collaborator
    private _handleLookupComponentCallback(data) {
      if (data && data.selectedItem) {
        if (this.opportunityService.selectedCollaboratorList && this.opportunityService.selectedCollaboratorList.length > 0) {
          // check duplicate data
          const isExists = this.opportunityService.selectedCollaboratorList.some(sco => sco.ID == data.selectedItem.id);
          if (isExists) {
            this.notificationService.notify(this.translate.instant("COLLABORATOR_EXISTS"), 'Opportunity Collaborators', 'top', ToastStyle.DANGER);
            return;
          } else {
            this.selectedCollaborator = data.selectedItem;
          }
        } else {
          this.selectedCollaborator = data.selectedItem;
        }
      }
    }

    private _initLookupSearchList() {
      if (this.isReadOnlyMode) return;
      let searchParam = '';
      try {
        this.opportunityDataService.getLookupResults(searchParam);
      } catch (err) {
        console.error(`Error occured while searching lookup results: ${err}`);
      }
    }
    // select role
    private async _handleDropdownListFormField(myEvent) {
      if (this.isReadOnlyMode) return;
      let getCollaboratorRoles = this.opportunityService.collaboratorRoles;
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'collaborator-role-select',
        data: getCollaboratorRoles.map(role => {
          let isSelectedRole: boolean = this.selectedRole ? (this.selectedRole.id == role.connectionroleid) : false;
          let obj = {
            id: role.connectionroleid,
            title: role.name,
            isSelected: isSelectedRole,
          };
          return obj;
        }),
        isMultipleSelectionEnabled: false
      };
      const dropdownPopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent,
        componentProps: { viewData: dropdownListDetail },
        cssClass: 'dropdown-list-view',
        event: myEvent
      });
      dropdownPopover.onDidDismiss().then((popoverData: any) => {
        const data = popoverData.data;
        if (data!=null) {
          this.selectedRole = data.selectedItems[0];
        }
        this.isRolesClicked = true;
        this.getUserRoles();
        this._initPageTitle();
      });
      dropdownPopover.present();
    }

    isSplitView() {
      return (window.innerWidth > 500);
    }

    onClosePage() {
      this.trackingService.tracking('OpportunityInfoBack', TrackingEventNames.OPPORTUNITYMANAGEMENT);
      this.uiService.activeView = '';
      this.navService.popChildNavPageWithPageTracking();
    }

    closepage(add: boolean) {
      if (add) {
        if (this.selectedCollaborator && this.selectedRole) {
          let addedData: OpportunityCollaborators = {
            ID: this.selectedCollaborator.id,
            firstName: this.selectedCollaborator.firstname,
            lastName: this.selectedCollaborator.lastname,
            fullName: this.selectedCollaborator.name,
            role: {
              roleid: this.selectedRole.id,
              name: this.selectedRole.title ,
            }
          }
          this.opportunityService.setSelectedCollaborators(addedData);
        }
      } else {
        console.log('no added collaborator data');
      }
      this.navService.popChildNavPageWithPageTracking();
    }

}
