import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { Subject } from 'rxjs';
import { WebsocketDataService } from '../../../data-services/websocket/websocket.data.service';
import { PresentationService } from '../../../services/presentation/presentation.service';

@Component({
  selector: 'io-reference-viewer',
  templateUrl: './reference-viewer.component.html',
  styleUrls: [],
})
export class ReferenceViewerComponent implements OnInit, OnDestroy {
  @Input() reference: any;
  @Input() activityId: string;
  @Input() presentationId: string;
  @Input() pageIdx: number;
  @Input() pageId: string;
  @Input() isMeeting: boolean = false;
  @ViewChild("pdfViewer", {static: true}) pdfViewer: PdfJsViewerComponent;
  viewData: any;

  canBeMinimized = true;
  isPresenting = false;
  referenceType: 'pdf' | 'url';

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private translate: TranslateService,
    private presentationService: PresentationService,
    private websocketService: WebsocketDataService,
  ) { }

  private setViewData() {
    const controls = [
      {
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left',
      },
      {
        id: 'present',
        imgSrc: 'assets/imgs/header_button_present.svg',
        name: this.translate.instant('PRESENT'),
        align: 'right',
        // Disabled for now until we implement this feature
        isDisabled: this.websocketService.meetingInProgress ? false : true,
      }
    ];

    this.viewData = {
      id: 'reference-viewer',
      title: this.reference?.indskr_displayname || this.reference?.['_indskr_document_value_Formatted'] || '',
      controls,
    };
  }

  private dismiss() {
    this.presentationService.dismissReferenceModal(this.reference.indskr_referencesid);
  }

  private broadcastEvent(event: 'open' | 'show' | 'hide' | 'close') {
    this.websocketService.broadcastReferenceEvent(
      this.activityId,
      this.presentationId,
      this.pageIdx,
      this.pageId,
      this.reference.indskr_referencesid,
      event,
      this.referenceType,
      this.reference.indskr_url,
    );
  }

  ngOnInit() {
    this.canBeMinimized = this.reference?.indskr_referencesid ? true : false;
    this.referenceType = this.presentationService.getReferenceType(this.reference);
    this.setViewData();
  }
  ngOnDestroy() {
    if (this.isPresenting) {
      this.presentationService.removeFromReferenceBeingShared(this.reference);
      this.broadcastEvent('close');
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onHeaderControlClick(controlId: string) {
    switch(controlId) {
      case 'close':
        if (!this.isMeeting || !this.canBeMinimized || !this.isPresenting) {
          this.dismiss();
          break;
        }
        // Minimize by hiding the viewer
        this.presentationService.hideReferenceModal(this.reference.indskr_referencesid);
        this.presentationService.hideReferenceBeingShared(this.reference.indskr_referencesid);
        this.broadcastEvent('hide');
        break;
      case 'present':
        if (this.websocketService.meetingInProgress) {
          this.isPresenting = !this.isPresenting;
          if (this.isPresenting) {
            this.presentationService.addToReferenceBeingShared(this.reference);
            this.broadcastEvent('open');
          } else {
            this.presentationService.removeFromReferenceBeingShared(this.reference);
            this.broadcastEvent('close');
          }
          this.viewData.controls[1].name = this.isPresenting ? this.translate.instant('STOP') : this.translate.instant('PRESENT');
        }
        break;
      default:
        break;
    }
  }
}
