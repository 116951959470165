import { Injectable } from "@angular/core";
import { Subject, ReplaySubject, BehaviorSubject ,  Observable } from "rxjs";
import { AccompainedUser } from '@omni/classes/activity/accompained-user.class';

@Injectable({
  providedIn: 'root'
})
export class IoUserSelection {

    private caseSelectorSource = new BehaviorSubject<string>(undefined);
    caseSelectorSourceObserver = this.caseSelectorSource.asObservable();

    private selectionDataSource = new BehaviorSubject<any[]>(null);
    selectionDataObserver = this.selectionDataSource.asObservable();

    private selectedInputSource = new BehaviorSubject<any[]>(null);
    selectedInputObserver = this.selectedInputSource.asObservable();

    private userSelectionSource = new BehaviorSubject<any[]>(null);
    userSelectionObserver = this.userSelectionSource.asObservable();

    accompaniedUserSelectionSource: Subject<AccompainedUser[]> = new Subject();
    accompaniedUserSelectionObserver = this.accompaniedUserSelectionSource.asObservable();

    private additionalConfigSource = new BehaviorSubject<any[]>(null);
    additionalConfigObserver = this.additionalConfigSource.asObservable();

    constructor() {
    }


    setCaseSelectorSource(data: string) {
        this.caseSelectorSource.next(data);
    }

    setSelectionDataSource(data: any[]) {
        this.selectionDataSource.next(data);
    }

    setSelectedInputSource(data: any[]) {
        this.selectedInputSource.next(data);
    }

    setUserSelectedData(data: any[]) {
        this.userSelectionSource.next(data);
    }

    setAdditionalConfigData(data: any[]) {
      this.additionalConfigSource.next(data);
  }

  setAccompaniedUserSelectedData(data: any[]) {
    this.accompaniedUserSelectionSource.next(data);
}
}
