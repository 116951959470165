
export class OkIntegration {
  public typeOfIntegration: number;
  public okConfigDTO: OKConfig;
  constructor(raw) {
    this.typeOfIntegration = Number(raw['typeOfIntegration']);
    this.okConfigDTO = raw['okConfigDTO'];
  }
}


export class OKConfig {
  public apiEndPoint: string;
  public connectorId: number;
  public apiUserName: string;
  public apiPassword: string;

  constructor(raw, encoded = false) {
    let atob = encoded ? window.atob : data => data;
    this.apiEndPoint = raw['apiEndPoint'] ? raw['apiEndPoint'] : null;
    try {
      this.connectorId = raw['connectorId'] ? Number(raw['connectorId']) : null;
    } catch (error) {
      console.error('OKConfig constructor: connectorId: ', error);
    }
    try {
      this.apiUserName =  raw['apiUserName'] ? atob(raw['apiUserName']) : null;
    } catch (error) {
      console.error('OKConfig constructor: apiUserName: ', error);
    }
    try {
      this.apiPassword =  raw['apiPassword'] ? atob(raw['apiPassword']) : null;
    } catch (error) {
      console.error('OKConfig constructor: apiPassword: ', error);
    }
  }
}

export enum IntegrationType {
  ONE_KEY = 548910001,
  FLAT_FILE= 548910000
}
