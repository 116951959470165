<ng-container *ngIf="!isSkeleton; else skeleton">
  <ion-item *ngIf="viewData" class="wrapper-item"
    [ngClass]="[viewData.mainItemCssClass ? viewData.mainItemCssClass : '',  viewData.isSelected?'itemSelected':'']"
    (click)="handleMainItemClick($event, 'mainItem')">

    <!-- expand icon -->
   <ion-icon *ngIf = "viewData && viewData.isShowChildNotification" class="float-expand-icon"   tappable
   [name]="expanded ? 'chevron-down-outline' : 'chevron-forward-outline' "
   (click)="handleExpandClick($event)" slot="start">
 </ion-icon>


    <ion-icon *ngIf="viewData.showIcon && !viewData.isShowChildNotification" [ngClass]="(viewData.isMainToolTemplate?'main-tool-template-icon':'')"
      slot="start" class="main-icon {{viewData.iconName}}" [src]="(viewData.isSrcAttribute)?viewData.iconName:''"
      [name]="(!viewData.isSrcAttribute)?viewData.iconName:''" (click)="handleMainItemClick($event,'startIcon')">
    </ion-icon>
    <ion-icon *ngIf="viewData.showStartIcon" slot="start" class="start-icon {{viewData.startIconName}}"
      [src]="(viewData.isSrcAttribute)?viewData.startIconName:''"
      [name]="(!viewData.isSrcAttribute)?viewData.startIconName:''"></ion-icon>

    <ion-label class="{{labelClassName}}">
      <img *ngIf="viewData.startIconInline" class="start-inline-icon" [src]="viewData.startIconInline">
      <ion-text *ngIf="viewData.primaryTextRight"
        [ngClass]="viewData.isSecondaryGrid?'grid-primary-text':!viewData.isAccordionText?'primary-text':'accordion-primary-text'">
        <div>
          <h3 [ngClass]="viewData.primaryTextRightTwo || viewData.primaryTextRightThree ? 'primaryTextInLine' : ''">{{viewData.primaryTextRight}}</h3>
          <span *ngIf="viewData.primaryTextRightTwo" class="tert-margin-text"
            style="font-size: 13px;"
            [ngClass]="viewData.isprimaryTextRightTwoPillType ? 'displayPill': ''">
            {{viewData.primaryTextRightTwo}}
          </span>
          <span *ngIf="viewData.primaryTextRightThree" class="primary-margin-text"
            [ngClass]="viewData.isprimaryTextRightThreePillType ? 'displayPill': ''">
            {{viewData.primaryTextRightThree}}
          </span>
        </div>
      </ion-text>

      <p *ngIf="viewData.primaryTextLeft || viewData.primaryTextLeftTwo"
        [ngClass]="viewData.isPrimaryTextLeftPillType?'secondary-text secondary-text-with-pill':'secondary-text'"
        [class.conflict-highlight]=viewData.isConflicting>
        <ion-text *ngIf="viewData.primaryTextLeft && !viewData.isPrimaryTextLeftPillType">{{viewData.primaryTextLeft}}
        </ion-text>
        <ion-text class="displayPill" *ngIf="viewData.isPrimaryTextLeftPillType && viewData.primaryTextLeft">
          {{viewData.primaryTextLeft}}</ion-text>
        <ion-text class="tert-margin-text" *ngIf="viewData.primaryTextLeftTwo">{{viewData.primaryTextLeftTwo}}
        </ion-text>
      </p>
      <p *ngIf="(viewData.secondaryTextRight || viewData.secondaryTextRightTwo || viewData.secondaryTextRightThree || viewData.secondaryTextRightFour|| viewData.secondaryTextRightFive|| viewData.secondaryTextRightSix || viewData.secondaryTextLeft || viewData.secondaryTextLeftTwo) && !viewData.isSecondaryGrid"
        [ngClass]="(viewData.isSecondaryTextRightTwoPillType || viewData.isSecondaryTextRightPillType)?'secondary-text secondary-text-with-pill':'secondary-text'">
      
        <ion-text [ngClass]="!viewData.isAccordionText?'secondary-text':'accordion-secondary-text'"
          *ngIf="viewData.secondaryTextRight && !viewData.isSecondaryTextRightPillType">{{viewData.secondaryTextRight}}
        </ion-text>
        <ion-text class="displayPill secondary-pills" *ngIf="viewData.isSecondaryTextRightPillType && viewData.secondaryTextRight">
          {{viewData.secondaryTextRight}}</ion-text>
      
        <ion-text class="tert-margin-text" [ngClass]="!viewData.isAccordionText?'secondary-text':'accordion-secondary-text'"
          *ngIf="viewData.secondaryTextRightTwo && !viewData.isSecondaryTextRightTwoPillType">
          {{viewData.secondaryTextRightTwo}}</ion-text>
        <ion-text class="displayPill tert-margin-text secondary-pills"
          *ngIf="viewData.isSecondaryTextRightTwoPillType && viewData.secondaryTextRightTwo">
          {{viewData.secondaryTextRightTwo}}</ion-text>
        <ion-text class="tert-margin-text" [ngClass]="!viewData.isAccordionText?'secondary-text':'accordion-secondary-text'"
          *ngIf="viewData.secondaryTextRightThree">{{viewData.secondaryTextRightThree}}</ion-text>
      
        <ion-text class="displayPill tert-margin-text secondary-pills"
          *ngIf="viewData.secondaryTextRightFour">{{viewData.secondaryTextRightFour}}</ion-text>
      
        <ion-text class="tert-margin-text" [ngClass]="!viewData.isAccordionText?'secondary-text':'accordion-secondary-text'"
          *ngIf="viewData.secondaryTextRightFive">{{viewData.secondaryTextRightFive}}
        </ion-text>
        <ion-text class="displayPill tert-margin-text secondary-pills"
         [ngClass]="!viewData.isAccordionText?'secondary-text':'accordion-secondary-text'"
          *ngIf="viewData.secondaryTextRightSix">{{viewData.secondaryTextRightSix}}
        </ion-text>
      
        <ion-text *ngIf="viewData.secondaryTextLeft">{{viewData.secondaryTextLeft}}</ion-text>
        <ion-text class="tert-margin-text" *ngIf="viewData.secondaryTextLeftTwo">{{viewData.secondaryTextLeftTwo}}
        </ion-text>
      </p>

       <!-- custom notification description -->
      <p *ngIf="viewData.tertiaryTextRight && viewData.isShowTertiaryTextRight"
      [ngClass]="viewData.isTertiaryTextRightPillType ?'secondary-text secondary-text-with-pill':'secondary-text'">
    
      <ion-text>{{viewData.tertiaryTextRight}}</ion-text>
      </p>

      <ion-grid *ngIf="viewData.isSecondaryGrid">
        <ion-row>
          <ion-col *ngIf="viewData.secondaryTextRight || viewData.secondaryTextRightTwo || viewData.secondaryText || viewData.secondaryTextLeft || viewData.secondaryTextLeftTwo">
            <p class="tert-margin-text secondary-text">{{viewData.secondaryTextRight}}</p>
            <p class="tert-margin-text grid-secondary-text">{{viewData.secondaryTextRightTwo}}</p>   
          </ion-col>
          <ion-col *ngIf="viewData.secondaryTextRight || viewData.secondaryTextRightTwo || viewData.secondaryText || viewData.secondaryTextLeft || viewData.secondaryTextLeftTwo">
            <p class="tert-margin-text secondary-text">{{viewData.secondaryTextLeft}}</p>
            <p class="tert-margin-text grid-secondary-text" *ngIf="viewData.secondaryTextLeftTwo">{{viewData.secondaryTextLeftTwo}} </p>   
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col *ngIf="viewData.secondaryTextRightThreeLabel || viewData.secondaryTextRightThree">
            <p class="tert-margin-text secondary-text">{{viewData.secondaryTextRightThreeLabel}}</p>
            <p class="tert-margin-text grid-secondary-text">{{viewData.secondaryTextRightThree}}</p>   
          </ion-col>
        </ion-row>
      </ion-grid>
      <div *ngIf="viewData.isSecondaryGrid && viewData.gridItems && viewData.gridItems.length>0"class="secondary-grid">
        <div *ngFor="let item of viewData.gridItems" class="grid-item {{item.id}}">
          <div class="grid-title">
            <div class="grid-secondary-text-label">
              <label>{{item.label}}</label>
            </div>
            <div class="grid-secondary-text-value">
              <label>{{item.value}}</label>
            </div>
          </div>
        </div>
      </div>
    </ion-label>

  <ion-text *ngIf="viewData?.customerType == 'contact' && viewData?.initials" class="initials" slot="start">
    <p class="customer-goals">{{viewData.initials}}</p>
  </ion-text>
  <ion-text *ngIf="viewData?.customerType == 'account' && viewData?.initials" class="initials" slot="start">
    <p class="customer-goals-account">{{viewData.initials}}</p>
  </ion-text>
  
  <p>
    <ion-text slot="end" class="compPercent" [ngClass]="{'done': viewData.percentage == '100%'}"
      *ngIf="viewData.isPrimaryTextRightPillType && viewData.percentage">
      {{viewData.percentage }}</ion-text>
  </p>

    <ion-icon *ngIf='viewData.showAccordionIcon' class="viewData.accordionIconType"
      [ngClass]="(viewData.accordionIconCssClass?viewData.accordionIconCssClass:'')"
      (click)="handleMainItemClick($event, 'accordionIcon')" [name]='viewData.accordionIconType' slot="end"
      class='accordion-icon'></ion-icon>

    <ion-icon *ngIf='viewData.showEndIcon && !viewData.isMainToolTemplate && !viewData.isPrimaryTextRightPillType'
      [ngClass]="(viewData.endIconCssClass?viewData.endIconCssClass:'')"
      (click)="handleMainItemClick($event, 'endIcon')" [src]="(viewData.isSrcAttribute)?viewData.endIconType:''"
      [name]="(!viewData.isSrcAttribute)?viewData.endIconType:''" slot="end" class='end-icon'
      [tooltip]="viewData.tooltip" [display]="viewData.tooltip ? true : false"></ion-icon>

    <ion-icon *ngIf='viewData.showEndIcon && viewData.isMainToolTemplate && !viewData.isPrimaryTextRightPillType'
      [ngClass]="(viewData.endIconCssClass?viewData.endIconCssClass:'')"
      (click)="handleMainItemClick($event, 'endIcon')" [src]="(viewData.isSrcAttribute)?viewData.endIconType:''"
      [name]="(!viewData.isSrcAttribute)?viewData.endIconType:''" slot="end" class='end-icon-main-tool-template'>
    </ion-icon>

    <ion-icon *ngIf='viewData.showEndIcon && !viewData.isMainToolTemplate && viewData.isPrimaryTextRightPillType'
      [ngClass]="(viewData.endIconCssClass?viewData.endIconCssClass:'')"
      (click)="handleMainItemClick($event, 'endIcon')" [src]="(viewData.isSrcAttribute)?viewData.endIconType:''"
      [name]="(!viewData.isSrcAttribute)?viewData.endIconType:''" class='end-icon greenDot'></ion-icon>

      <!-- second end icon -->
      <ion-icon *ngIf='viewData.showSecondEndIcon'
      [ngClass]="(viewData.secondEndIconCssClass?viewData.secondEndIconCssClass:'')"
      (click)="handleMainItemClick($event, 'secondEndIcon')" [src]="(viewData.isSecondSrcAttribute)?viewData.secondEndIconType:''"
      [name]="(!viewData.isSecondSrcAttribute)?viewData.secondEndIconType:''" slot="end" class='end-icon'></ion-icon>
       <!-- second end icon -->

    <ion-icon *ngIf='viewData.showArrow && !viewData.noPaddingIcon' class="insights arrow-icon {{viewData.arrowType}}"
      (click)="handleMainItemClick($event, 'endArrow')" [name]='viewData.arrowType' slot="end"
      [src]="(viewData.isSrcAttribute)?viewData.iconName:''"></ion-icon>

    <ion-icon *ngIf='viewData.showArrow && viewData.noPaddingIcon'
      class="insights noPadding-arrow-icon {{viewData.arrowType}}" (click)="handleMainItemClick($event, 'endArrow')"
      [name]='viewData.arrowType' slot="end" [src]="(viewData.isSrcAttribute)?viewData.iconName:''"></ion-icon>

    <p>
      <ion-text slot="end" class="primaryTextRightPill"
        *ngIf="viewData.isPrimaryTextRightPillType && viewData.isPrimaryTextRightPillText">
        {{viewData.isPrimaryTextRightPillText}}</ion-text>
    </p>


 
  </ion-item>
  <ion-item *ngIf="viewData && viewData.showMoreInfo">
    <div class="more-info" [ngClass]="{'left-margin-text': viewData.showIcon}">
      <div *ngFor="let data of viewData.moreInfo"><p  class="secondary-text">{{data.label}}</p><ion-label><ion-text>{{data.value}}</ion-text></ion-label></div>
    </div>
  </ion-item>

  <div *ngIf="viewData && !viewData.noSeparationLine"
    [ngClass]="viewData.isAccordionForm?viewData.isExpanded?'accordionForm-separationLine-in-focus':'accordionForm-separationLine':'separationLine'">
  </div>

</ng-container>


<!-- display child notification for contact  -->
<ng-container *ngIf = "expanded && viewData && viewData.isShowChildNotification ">
  <ion-item-group *ngFor="let notification of viewData.childNotifications">
    <ion-item class="marL20">
      <main-card [viewData]="mapChildNotificationMainCard(notification)"></main-card>
   

    </ion-item>
    
  </ion-item-group>
    

</ng-container>

<ng-template #skeleton>
  <ion-item class="wrapper-item">
    <ion-thumbnail slot="start" class="main-icon">
      <ion-skeleton-text class="skeleton-text-animated"></ion-skeleton-text>
    </ion-thumbnail>

    <ion-label class="text-wrapper">
      <ion-text class="primary-text">
        <h3>
          <ion-skeleton-text class="skeleton-text-animated"
            [ngStyle]="{ width: skeletonConfig?.primaryTxtWidth ? skeletonConfig.primaryTxtWidth : defaultPrimaryTxtWidth }">
          </ion-skeleton-text>
        </h3>
      </ion-text>
      <p class="secondary-text">
        <ion-skeleton-text class="skeleton-text-animated"
          [ngStyle]="{ width: skeletonConfig?.firstSecondaryTxtWidth ? skeletonConfig.firstSecondaryTxtWidth : defaultFirstSecondaryTxtWidth }">
        </ion-skeleton-text>
      </p>
      <p class="secondary-text">
        <ion-skeleton-text class="skeleton-text-animated"
          [ngStyle]="{ width: skeletonConfig?.secondSecondaryTxtWidth ? skeletonConfig.secondSecondaryTxtWidth : defaultSecondSecondaryTxtWidth }">
        </ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <div class="separationLine"></div>
</ng-template>
<div *ngIf="(viewData?.isDataLoading$ | async) === true" class="data-load-indicator">
  <ion-spinner name="dots"></ion-spinner>
</div>