import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "@omni/services/authentication.service";
import { Endpoints } from "src/config/endpoints.config";
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { Photo } from '@omni/classes/store-check/photo';
import { UploadedPhoto } from '@omni/classes/store-check/uploaded-photo';

@Injectable({
  providedIn: 'root'
})
export class StoreCheckDataService {

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    public dynamics: DynamicsClientService) {

  }

  public async createStoreCheck(payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.storecheck.CREATE_STORE_CHECK;
    return await this.http.post(url, payload).toPromise();
  }

  public async updateStoreCheck(activityId, payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.storecheck.UPDATE_STORE_CHECK.replace('{activityId}', activityId);
    await this.http.patch(url, payload).toPromise();
  }

  public async updatePromoCheck(activityId, payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.storecheck.UPDATE_PROMO_CHECK.replace('{activityId}', activityId);
    await this.http.put(url, payload).toPromise();
  }

  public async getProductFamilyCategories() {
    const positionIds = this.authService.user.positions.map(position => `<value>${position.ID}</value>`).join('');
    const fetchXML = fetchQueries.storeCheck.productFamilyCategory.split('{positionIds}').join(positionIds);
    return await this.dynamics.executeFetchQuery('indskr_productfamilycategories', fetchXML);
  }

  public async getProductFamilyCategoryByStoreCheckID(stroreCheckId: string) {
    const fetchXML = fetchQueries.storeCheck.productFamilyCateoryByStoreCheckId.replace('{storeCheckId}', stroreCheckId);
    return await this.dynamics.executeFetchQuery('appointments', fetchXML);
  } 


  public async uploadPhotosToBlobStorage(payload: Photo[]): Promise<UploadedPhoto[]> {
    const headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.photos.UPLOAD_PHOTOS;
    return await this.http.post <UploadedPhoto[]>(url, payload, headers).toPromise();
  }

}