import { IONote } from '../io/io-note.class';
export class Quotes {
  public ID: string;
  public name: string;
  public accountId: string;
  public accountName: string;
  public state: number;
  public totalAmount: string;
  public statuscode: number;
  public qutoteStatus: string;
  public approvalStatus: number;
  public countryId: string;
  public countryName: string;
  public ownerId: string;
  public priceLevelId: string;
  public priceLevelName: string;
  public lastUpdated: number;
  public transactionCurrencyId: string;
  public currencyName: string;
  public quoteNotes: Array<IONote> = [];
  public discountFormat: string;
  public discountPercentage: number;
  public opportunityId: string;
  public opportunityName: string;
  public orderId: string;
  public orderName: string;
  public products: any[];
  public effectivefrom: string;
  public effectiveto: string;
  public suggestedAmount: string;
  public quoteNumber: string;
  public approvalActivityId: string;
  public createdon: string;

  constructor(raw: JSON) {
    this.ID = raw['quoteid'] || '';
    this.quoteNumber = raw['quoteNumber'] || raw['quotenumber'] || '';
    this.name = raw['name'] || '';
    this.totalAmount = raw['totallineitemamount'] || '';
    this.state = raw['statecode'] || 0;
    this.accountId = raw['_customerid_value'] || raw['accountId'] || '';
    this.accountName = raw['_customerid_value_Formatted'] || raw['accountName'] || '';
    this.statuscode = raw['statuscode'] || QuoteStatus.DRAFT;
    this.approvalStatus = raw['approvalStatus'];
    this.qutoteStatus = raw['statecode_Formatted'] || ApprovalStatus.DRAFT;
    this.countryId = raw['_indskr_countryid_value'] || '';
    this.countryName = raw['_indskr_countryid_value_Formatted'] || '';
    this.ownerId = raw['_ownerid_value'] || raw['ownerId'] || '';
    this.priceLevelId = raw['_pricelevelid_value'] || raw['priceLevelId'] || '';
    this.priceLevelName = raw['_pricelevelid_value@OData.Community.Display.V1.FormattedValue'] || raw['priceLevelName'] || '';
    this.transactionCurrencyId = raw['_transactioncurrencyid_value'] || raw['transactionCurrencyId'] || '';
    this.currencyName = raw['_transactioncurrencyid_value_Formatted'] || raw['currencyName'] || '';
    this.discountPercentage = raw['discountpercentage'] || 0;
    this.discountFormat = raw['discountpercentage_Formatted'] || null;
    this.effectiveto = raw['effectiveto'] || null;
    this.effectivefrom = raw['effectivefrom'] || null;
    this.products = raw['products'] || [];
    this.opportunityId = raw['opportunityId'] || raw['_opportunityid_value'] || null;
    this.opportunityName = raw['opportunityName'] || raw['_opportunityid_value_Formatted'] || null;
    this.orderId = raw['orderId'] || raw['_indskr_order_value'] || null;
    this.orderName = raw['orderName'] || raw['_indskr_order_value_Formatted'] || null;
    this.suggestedAmount = raw['suggestedAmount'] || raw['indskr_suggestedamount'] || null;
    this.approvalActivityId = raw['approvalActivityId'];
    this.createdon = raw['createdon'] || new Date();
  }
}
export class Account {
  constructor(public id: string,
    public name: string,
    public statecode: number) { }
}

export class Currency {
  public id: string;
  public symbol: string;
  public name: string;
  constructor(raw: JSON) {
    this.id = raw['transactioncurrencyid'];
    this.symbol = raw['currencysymbol'];
    this.name = raw['currencyname'];
  }
}

export class QuotePriceList {
  public id: string;
  public name: string;
  public currencyId: string;
  public currencyName: string;
  public products: PriceListProdcut[];
  constructor(raw: JSON) {
    this.id = raw['pricelevelid'] || '';
    this.name = raw['name'] || '';
    this.currencyId = raw['_transactioncurrencyid_value'] || '';
    this.currencyName = raw['_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue'] || '';
    this.products = []
  }
}

export enum ApprovalStatus {
  DRAFT = 1,
  PENDING = 548910000,
  APPROVED = 548910001,
  NOT_APPROVED = 548910002,
  ESCALATED = 548910003,
  CANCELED = 548910004,
  AUTO_APPROVED = 548910005,
  IN_ACTIVE = 2
}

export enum QuoteStatus {
  IN_PROGRESS = 1,
  DYNAMICS_IN_PROGRESS = 2,
  OPEN = 3,
  WON = 4,
  LOST = 5,
  DYNAMICS_CANCELED = 6,
  REVISED = 7,
  DRAFT = 8,
  PENDING_APPROVAL = 9,
  APPROVED = 10,
  REJECTED = 11,
  CANCELED = 12
}

export interface PriceListProdcut {
  id: string,
  name: string,
  amount: string,
  amountFormatted: string,
  uomId: string
}


export enum QuotesToolTab {
  QUOTES = "Quotes",
  APPROVALS = "Approvals"
}