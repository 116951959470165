<ion-list class="overrideIOSList">
<ion-radio-group>
    <ion-list-header *ngIf='headerText'>
      {{headerText}}
      <ion-icon class="light-color" tappable name="md-arrow-dropdown" slot="end" ></ion-icon>
      <!-- <ion-icon class="light-color" tappable name="md-arrow-dropup" slot="end" ></ion-icon> -->
    </ion-list-header>
    <ion-item *ngFor="let item of filterOptions">
      <ion-label class="smallerStatusText">{{item.text}}</ion-label>
      <ion-checkbox (click)="multiSelectOptionClicked(item)"
        [checked]="item.checked"></ion-checkbox>
    </ion-item>
  </ion-radio-group>
</ion-list>
