import { UIService } from './../../../../services/ui/ui.service';
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ScientificActivityPlan } from "../../../../classes/scientific-activity/scientific-activity.class";
import { ScientificActivityService } from "../../../../services/scientific-activity/scientific-activity.service";
import { BrandOfflineService } from "../../../../services/brand/brand.service";
import { format, isValid, isEqual, isBefore } from "date-fns";
import { TherapeuticAreaDataService } from "../../../../data-services/therapeutic-area/therapeutic-area.data.service";
import { FollowUpActivity } from "../../../../classes/activity/follow-up-action.activity.class";
import { ActivityService } from "../../../../services/activity/activity.service";
import { ActivityType } from "../../../../classes/activity/activity.class";
import { AuthenticationService } from "../../../../services/authentication.service";
import { EventsService } from "../../../../services/events/events.service";
import { Resource } from "../../../../classes/resource/resource.class";
import { NavigationService, PageName } from "../../../../services/navigation/navigation.service";
import { ResourcePageComponent } from "../../../resource/resource-page/resource-page";
import { ResourceView, ResourceService } from "../../../../services/resource/resource.service";
import {DateTimeFormatsService} from "../../../../services/date-time-formats/date-time-formats.service";
import { ActivityDataService } from '../../../../data-services/activity/activity.service';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

@Component({
    selector: 'scientific-info',
    templateUrl: 'scientific-info.html',
  styleUrls:['scientific-info.scss']
})

export class ScientificActivityInfo {

    @Input() selectedSAP: ScientificActivityPlan;
    @Input() selectedContactId: string;

    @Output() openTaskDetails:EventEmitter<FollowUpActivity> = new EventEmitter();

    formattedPeriod: string;
    public prodList: string;
    public taList: string;
    public allModelTasks: MainCardViewDataModel [] = [];
    public strategyDocumentHeader:IndSectionHeaderViewDataModel;

    followUpTasksForPlan: FollowUpActivity[];
    public allFormFieldValues: Array<IndFormFieldViewDataModel> = [];
    showMore = false;
    strategy_Text_default_length = 150;

    constructor(
        private brand: BrandOfflineService,
        private therapeuticService:TherapeuticAreaDataService,
        private activityService:ActivityService,
        private activityDataService: ActivityDataService,
        private authService:AuthenticationService,
        private events:EventsService,
        private navService: NavigationService,
        private resourceService: ResourceService,
        public dateTimeFormatsService: DateTimeFormatsService,
        public uiService: UIService,
        public translate: TranslateService,
        private datePipe: DatePipe
    ) {
        console.log(this.selectedSAP);
        this.events.subscribe("refreshFollowup",()=>{
          this.setViewdata();
          this._initTasksModel();
        })
    }

    ngOnInit() {
        this.prodList = this.selectedProduct();
        this.taList = this.selectedTherapeuticArea();
        this.setViewdata();
        this._initTasksModel();
    }

    private _getLocalisedString(status: string) {

      switch (status) {
        case 'Open':
        return this.translate.instant('OPEN');
        case 'Completed':
          return this.translate.instant('COMPLETED');
        case 'Canceled':
          return this.translate.instant('CANCELLED');
      }
    }

    private _initTasksModel() {
      this.allModelTasks = [];
      if (this.followUpTasksForPlan && this.followUpTasksForPlan.length !== 0) {
        this.allModelTasks = this.followUpTasksForPlan.map(task => {
          let viewModel: MainCardViewDataModel = {
            id: task.ID,
            primaryTextLeft: this._getLocalisedString(task.statusString) + ' ' +  this.getFormattedDueDate(task.scheduledEnd),
            primaryTextRight: _.isEqual(task.subject, 'Follow-up Action')? this.translate.instant('FOLLOW_UP_ACTION') : task.subject ,
            secondaryTextRight: '',
            isSecondaryTextRightPillType: false,
            showArrow: true,
            fixedHeight:true,
            arrowType: 'chevron-forward',
            clickHandler: (id: string, event) => {
              this.openFollowUpDetails(task);
            },
          }

          return viewModel;
        });
      }
    }

    selectedProduct() {
        return this.selectedSAP.products.map(prod => prod.name).join(', ');
    }

    selectedTherapeuticArea() {
        return this.selectedSAP.therapeuticAreaList.filter(taf => taf.name).map(ta=>ta.name).join(', ');
    }

  // get periodRange() {
  //   return this.getFormattedAndLocalisedDate(this.selectedSAP.startDate) + ' - ' + this.getFormattedAndLocalisedDate(this.selectedSAP.endDate);
  // }
  get startPeriod() {
    return this.getFormattedAndLocalisedDate(this.selectedSAP.startDate);
  }
  get endPeriod() {
    return this.getFormattedAndLocalisedDate(this.selectedSAP.endDate);
  }

    setViewdata(){
        if(this.selectedSAP.startDate && this.selectedSAP.endDate){
          this.formattedPeriod = format(parseInt(this.selectedSAP.startDate), this.dateTimeFormatsService.dateToUpper)
                                +' - '+format(parseInt(this.selectedSAP.endDate), this.dateTimeFormatsService.dateToUpper)
        }

        let followUpTasksForPlan = this.activityService.activities.filter((e)=>{
          if(this.selectedContactId){
            return (e.type === ActivityType.FollowUp
                  && e['scientificPlanId'] === this.selectedSAP.ID
                  && e['contactId'] === this.selectedContactId
                  && e['ownerId'] == this.authService.user.systemUserID);
          }else{
            return (e.type === ActivityType.FollowUp
                  && e['scientificPlanId'] === this.selectedSAP.ID
                  && e['ownerId'] == this.authService.user.systemUserID);
          }
          // return (a.type == ActivityType.FollowUp && (a as FollowUpActivity).scientificPlanId == this.selectedSAP.ID
          //       && (a.ownerId == this.authService.user.systemUserID)
          //       && ((a as FollowUpActivity).contactId === this.selectedContactId)
          //       || ((a as FollowUpActivity).assignedTo.some(o=> o.userId == this.authService.user.systemUserID)
          //       || this.selectedSAP.ownerID == this.authService.user.systemUserID)
          //       && (a.state==0 || a.state==1))
        }).sort((a,b)=>{
          return (a.subject > b.subject) ? 1: -1;
        })
        .sort((a,b)=>{
          if(isValid(a.scheduledEnd) && isValid(b.scheduledEnd)){
            if(isEqual(a.scheduledEnd,b.scheduledEnd)) return 1;
            else return (isBefore(a.scheduledEnd,b.scheduledEnd) ? 1 : -1)
           }else if(isValid(a.scheduledEnd) && !isValid(b.scheduledEnd)){
            return 1;
           }else if(!isValid(a.scheduledEnd) && isValid(b.scheduledEnd)){
            return -1;
           }else {
            return 1;
          }
        })
        this.followUpTasksForPlan = followUpTasksForPlan.map((f)=>{
          return f as FollowUpActivity
        })
        this.strategyDocumentHeader = {
          id: 'strategy-document-header',
          title: this.translate.instant('STRATEGY'),
          controls: [
              {
                  id: 'strategy-document',
                  text: this.translate.instant('DOCUMENTS'),
                  isDisabled: this.selectedSAP.documents.length == 0 ? true : false,
              }
          ],
      };
      this.getStartPeriodFormField();
      this.getEndPeriodFormField();
      
      this.allFormFieldValues = [
        // {
        //   label: this.translate.instant('PERIOD'),
        //   inputText: this.periodRange,
        //   id:'peroid-range-field',
        //   isReadOnly:true,
        //   isDisabled:true,
        // },
        {
          label: this.translate.instant('PRODUCTS'),
          inputText: this.prodList,
          id:'product-list-field',
          isReadOnly:true,
          isDisabled:true,
        },
        {
          label: this.translate.instant('THERAPEUTIC_AREAS'),
          inputText: this.taList,
          id:'therapeutic-area-field',
          isReadOnly:true,
          isDisabled:true,
        },
        {
          label: this.translate.instant('SCIENTIFIC_INFO_MEETINGS_GOALS'),
          inputText: this.selectedSAP.totalMeetingGoals+'',
          id:'meeting-goals-field',
          isReadOnly:true,
          isDisabled:true,
        },
        {
          label: this.translate.instant('SCIENTIFIC_INFO_MEETINGS_COMPLETED'),
          inputText: this.selectedSAP.totalCompletedMeetingGoals+'',
          id:'meeting-completed-field',
          isReadOnly:true,
          isDisabled:true,
        },
        {
          label: this.translate.instant('SCIENTIFIC_INFO_DIRECT_MESSGE_GOAL'),
          inputText: this.selectedSAP.totalEmailGoals+'',
          id:'message-goal-field',
          isReadOnly:true,
          isDisabled:true,
        },
        {
          label: this.translate.instant('SCIENTIFIC_INFO_DIRECT_MESSGE_COMPLETED'),
          inputText: this.selectedSAP.totalCompletedEmailGoals+'',
          id:'direct-msg-completed-field',
          isReadOnly:true,
          isDisabled:true,
        }
      ];

      }
  
  public getStartPeriodFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('START_PERIOD'),
      inputText: this.selectedSAP && this.selectedSAP.startDate ? this.startPeriod : '',
      id:'start-peroid-field',
      placeholderLabel: '-',
      isReadOnly:true,
      isDisabled:true,
    }
    return viewData;
  }

  public getEndPeriodFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('END_PERIOD'),
      inputText: this.selectedSAP && this.selectedSAP.endDate ? this.endPeriod : '',
      id:'end-peroid-field',
      placeholderLabel: '-',
      isReadOnly:true,
      isDisabled:true,
    }
    return viewData;
  }

  public getFormattedDueDate(value): string {
    if (isValid(value)) {
      return `${this.translate.instant('DUE')} - ${this.getFormattedAndLocalisedDate(value)}`;
    } else {
      return '';
    }
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

      public getUserTaskList(task){
        let usernamestr = '';
        if(task.assignedTo){
          if(task.assignedTo.length === 1){
            usernamestr = task.assignedTo.map(value => value.userFullName).join(', ');
          }else if(task.assignedTo.length > 1){
            usernamestr = task.assignedTo.slice(0,1).map(value => value.userFullName).join(', ') + '+' + (task.assignedTo.length-1);
          }
        }
        return usernamestr;
      }

      openFollowUpDetails(task){
        this.activityService.selectedActivity = task;

        this.activityDataService.updateActivityDetails(task); // This is added to get teh fresh detail from Dynamics as Part of https://indegene.atlassian.net/browse/OMNI-7833. Since on clicking on task was fetching it from local, Hence added this call.

        this.openTaskDetails.emit(task);
      }

      openResources(docs: Resource[]){
        this.uiService.prevView = this.uiService.activeView;
        if(this.selectedSAP.documents.length < 1) return;
        this.resourceService.viewMode = ResourceView.DOCUMENTS;
        this.navService.pushWithPageTracking( ResourcePageComponent, PageName.ResourcePageComponent,
                                              {resources: docs, collectionName: this.selectedSAP.scientificActivityPlanName},
                                              PageName.ScientificActivityDetail);
      }

      setShowMore(event) {
        // const startegytext = document.querySelector('#strategy-text');
        this.showMore = !this.showMore;
        // if (this.showMore) {
        //   startegytext.classList.add('show-more');
        // } else {
        //   startegytext.classList.remove('show-more');
        // }
      }

      // public getPeriodFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('PERIOD'),
      //     inputText: this.periodRange,
      //     id:'peroid-range-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }

      // public getProductsFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('PRODUCTS'),
      //     inputText: this.prodList,
      //     id:'product-list-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }

      // public getTherapeuticAreaFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('THERAPEUTIC_AREAS'),
      //     inputText: this.taList,
      //     id:'therapeutic-area-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }

      // public getMeetingGoalsFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('SCIENTIFIC_INFO_MEETINGS_GOALS'),
      //     inputText: this.selectedSAP.totalMeetingGoals+'',
      //     id:'meeting-goals-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }

      // public getMeetingCompletedFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('SCIENTIFIC_INFO_MEETINGS_COMPLETED'),
      //     inputText: this.selectedSAP.totalCompletedMeetingGoals+'',
      //     id:'meeting-completed-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }

      // public getMessageGoalFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('SCIENTIFIC_INFO_DIRECT_MESSGE_GOAL'),
      //     inputText: this.selectedSAP.totalEmailGoals+'',
      //     id:'message-goal-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }

      // public getDirectMsgCompletedFormField(): IndFormFieldViewDataModel {
      //   let viewData: IndFormFieldViewDataModel;
      //   viewData = {
      //     label: this.translate.instant('SCIENTIFIC_INFO_DIRECT_MESSGE_COMPLETED'),
      //     inputText: this.selectedSAP.totalCompletedEmailGoals+'',
      //     id:'direct-msg-completed-field',
      //     isReadOnly:true,
      //     isDisabled:true,
      //   };
      //   return viewData;
      // }
}
