<!-- <ion-item-divider>
    {{'THERAPEUTIC_AREAS_CAPS' | translate}}
    <ion-button fill="clear" slot="end" (click)="onSaveTA()" [disabled]="!isDataDirty || activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting || enableReadonlyViewForMeetings">{{'SAVE' | translate}}</ion-button>
</ion-item-divider> -->
<ind-section-header [viewData]="{id:'meeting-ta-header', title: translate.instant('THERAPEUTIC_AREAS_CAPS'),isRequired: isTARequired, controls:[{id:'ta-header-save',text: translate.instant('SAVE'),isDisabled: !isDataDirty || activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting || enableReadonlyViewForMeetings || backgroundUploadInProgress || activityService.selectedActivity?.isDiffPosition}]}"
    (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

<ion-reorder-group [ngClass]="{'empty-list':!therapeuticAreas.length || (activityService.selectedActivity.isCompleted && activityService.selectedActivity['activityTherapeuticAreas'] && activityService.selectedActivity['activityTherapeuticAreas'].length === 0)}"
    (ionItemReorder)="reorderData($event.detail)" [disabled]="!(!activityService.selectedActivity.isCompleted && !isReadOnlyJointMeeting && !enableReadonlyViewForMeetings && !backgroundUploadInProgress && !activityService.selectedActivity?.isDiffPosition)">
    <ng-container *ngIf="!(activityService.selectedActivity.isCompleted && activityService.selectedActivity['activityTherapeuticAreas'] && activityService.selectedActivity['activityTherapeuticAreas'].length === 0)">
        <div *ngFor="let ta of therapeuticAreas">
            <ion-item *ngIf="therapeuticAreas.length > 0">
                <!--THIS WILL BE A FIELD TO CHECK IF THE PRODUCT SHOULD BE SHOWN -->
                <div class="product-wrapper">
                    <!-- <h2> -->
                    <div #roundInput [id]="ta.therapeuticareaId" [ngClass]="{'round': (!activityService.selectedActivity.isCompleted  && !isReadOnlyJointMeeting && !enableReadonlyViewForMeetings && !backgroundUploadInProgress && !activityService.selectedActivity?.isDiffPosition),
            'round-disabled':(activityService.selectedActivity.isCompleted || isReadOnlyJointMeeting || enableReadonlyViewForMeetings || backgroundUploadInProgress || activityService.selectedActivity?.isDiffPosition)}" (click)="updateTA($event , ta)">
                        <input [id]="ta.therapeuticareaId" type="checkbox" [ngModel]="ta.isSelected" />
                        <label [for]="ta.therapeuticareaId" [ngClass]="{
                                    'selected':ta.isSelected && !ta.isAutoSelected && !ta.isGenieSelected,
                                    'auto-selected': ta.isAutoSelected,
                                    'genie-selected' :ta.isGenieSelected && !ta.isAutoSelected}">
              </label>
                        <span style="margin-bottom:35px; margin-left: 15px;" size="25" class="key-message-text">{{ta.name}}</span>
                    </div>
                    <!-- </h2> -->
                </div>
                <ion-reorder slot="end"></ion-reorder>
            </ion-item>
        </div>
    </ng-container>
</ion-reorder-group>
