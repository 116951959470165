import { Component, Input, HostBinding , ElementRef, Renderer2, Directive, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'ion-scroll',
  template: `<slot></slot>`,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class IonScrollComponent {
  @Input() scrollX: boolean;
  @Input() scrollY: boolean;

  @HostBinding("style.overflow-x")
  get overflowX() {
    return this.scrollX ? 'scroll' : 'hidden';
  }

  @HostBinding("style.overflow-y")
  get overflowY() {
    return this.scrollX ? 'scroll' : 'hidden';
  }
}



@Directive({ selector: '[color]' })
export class ColorDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  @Input() set color(child: HTMLElement) {
    // Array.from(this.el.nativeElement.children).forEach(child => {
    //   this.renderer.removeChild(this.el.nativeElement, child);
    // });
    // if (child) {
    //   this.renderer.appendChild(this.el.nativeElement, child);
    // }
  }
}
