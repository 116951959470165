import { Component } from '@angular/core';
import { UIService } from '../../services/ui/ui.service';

@Component({
    selector: 'ogenie-messages',
    templateUrl: 'ogenie-messages.html',
  styleUrls:['ogenie-messages.scss']
  })
  export class OGenieMessages {

    constructor(public uiService: UIService) {

    }


  }
