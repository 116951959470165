import {Component} from "@angular/core";
import {UIService} from "../../../services/ui/ui.service";
import {RepServices} from "../../../data-services/rep/rep.services";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DeviceService} from "../../../services/device/device.service";
import { FooterService } from '@omni/services/footer/footer.service';


@Component({
  selector: 'publication-preview',
  templateUrl: 'publication-preview.html',
  styleUrls:['publication-preview.scss']
})
export class PublicationPreviewComponent {

  public urlSafe: SafeResourceUrl;
  error: string;
  resource:any

  constructor(
    public repService: RepServices,
    public uiService: UIService,
    public sanitizer: DomSanitizer,
    public device: DeviceService,
    public footerService: FooterService
  ) {
  }

  get isSupportedFileType(): boolean {
    return (
      ['image/gif', 'image/jpeg', 'image/jpg','image/png', 'video/mp4', 'application/pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'video/webm'].indexOf(
        this.uiService['preview'].indskr_mimetype
      ) >= 0
    );
  }

  get assetType(): string {
    if (['image/gif', 'image/jpeg', 'image/jpg', 'image/png'].indexOf(
      this.uiService['preview'].indskr_mimetype)
      >= 0)
      return "image";
    if (['video/mp4', 'video/webm'].indexOf(
      this.uiService['preview'].indskr_mimetype)
      >= 0)
      return "video";
    if (['application/pdf'].indexOf(
      this.uiService['preview'].indskr_mimetype)
      >= 0)
      return "pdf";
    if (['application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].indexOf(
      this.uiService['preview'].indskr_mimetype)
      >= 0)
      return "web";
  }

  ngOnInit() {
    this.error = null;
    let base64URL = 'data:' + this.uiService['preview'].indskr_mimetype + ';base64,' + this.uiService['preview'].indskr_documentbody;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(base64URL);
  }

  closePage() {
    this.uiService.activeView = 'scientificInfoDetails';
  }

}
