import { Component, HostBinding, ViewChild, Input } from '@angular/core';
import { NavParams, NavController, IonNav } from '@ionic/angular';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { FooterViews, FooterService } from '../../../services/footer/footer.service';
import { UIService } from '../../../services/ui/ui.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventsService } from '../../../services/events/events.service';
import { ActivityType } from '../../../classes/activity/activity.class';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { EmailService } from '../../../services/email-templates/email.service';
import { EmailViewType } from '../../../classes/activity/email.activity.class';
import { ScientificActivityService } from '../../../services/scientific-activity/scientific-activity.service';
import { ScientificActivityDetail } from '../../activity/scientific-activity/scientific-activity-detail/scientific-activity-detail';
import { DynamicFormsService } from '../../../services/dynamic-forms/dynamic-forms-service';
import { ParticipantListComponent } from '../../../components/resource/participant-list/participant-list';
import { ContactDataService } from '../../../data-services/contact/contact.data.service';
import {MdmService} from "@omni/services/mdm/mdm.service";
import { LocalizationService } from '@omni/services/localization/localization.service';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';

/**
 * Generated class for the ContactPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-page[base-page]',
  templateUrl: 'contact-page.html',
  styleUrls:['contact-page.scss']
})
export class ContactPageComponent {

  public contactListMode:string;
  @Input() selectedContactIds: any;
  @Input() isCovisitorView: any;
  @Input() selectedAccountIds: any;
  @Input() callbackEvent:any;
  @Input() maximumeventcapacity:any;

  @ViewChild('contactpagerightpane', {static:true}) public navCtrl: IonNav;
  voiceSignalViaNavParams: any[];

  // @HostBinding("class.contact-selected")
  // public get contactSelected() : boolean {
  //   if(this.uiService.activeView === 'Scheduler')
  //   {
  //     this.contactService.contactInformation = null;
  //   }
  //   return !!this.contactService.contactInformation || this.uiService.activeView == 'ContactPageRightPaneNav';
  // }

  public participantListComponent: any = ParticipantListComponent;
  @ViewChild('participantListNav', {static:true}) participantListNav: NavController;
  public get participantListComponentParams() {
    return {
      appointment: this.activityService.selectedActivity,
      resource: this.activityService.selectedSharedResource,
    }
  }

  constructor(
    public contactService: ContactOfflineService,
    public contactDataService: ContactDataService,
    public activityService: ActivityService,
    private footerService: FooterService,
    public uiService: UIService,
    public device: DeviceService,
    public navParams: NavParams,
    private events: EventsService,
    public navService: NavigationService,
    private emailService: EmailService,
    public mdmService: MdmService,
    private localizationService: LocalizationService,
    private customerSurveyService: CustomerSurveyService,
    private agendaFooterService: AgendaFooterService
  ) {

    this.contactListMode = this.navParams.data && this.navParams.data.listMode;
    this.footerService.initButtons(FooterViews.Contacts);
    this.voiceSignalViaNavParams = this.navParams.data && this.navParams.data.voiceStatus;
  }

  async ngOnInit(){
    this.contactService.isInGlobalSearch = false;
    this.events.subscribe('showContactRHSEmptyPage', (shouldShow) =>{
      this.uiService.updateNothingSelectedScreenMessageFor(shouldShow ? 1 : 0)
    })
    await this.localizationService.initMultilingualFieldsData();
  }

  ngOnDestroy(){
    if(this.contactService.isInGlobalSearch && this.contactService.contactInformation) this.contactDataService.purgeContactFromDatabase(this.contactService.contactInformation.ID);
    this.navService.popChildNavCtrlFromStack(ChildNavNames.ContactPageNavigation);
    this.localizationService.resetMultilingualFieldsData();
    if (this.uiService.activitiesPageTab == 'agenda' && this.navParams.data && this.navParams.data.from == 'ContactTool' && this.activityService.selectedActivityAtHome) {
      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
      if (this.activityService.selectedActivity?.type === ActivityType.Appointment) {
        this.events.publish("detectChangesOnActivityDetails");
      }
    }
    this.contactService.contactInformation = null;
    this.customerSurveyService.selectedContactFromContactTool = null;
    if (this.navParams.data && this.navParams.data.from == 'ContactTool') {
      this.agendaFooterService.initAgendaFooterButtons();
    }
  }

  ionViewDidLoad(){}

  ionViewCanEnter(){
    if(!this.uiService.showNewActivity && this.uiService.activeView == 'activityTimelineDetails' && this.activityService.selectedActivity){
      this.footerService.initButtons(FooterViews.Activities);
    }else if (!this.uiService.showNewActivity && this.activityService.selectedActivity && this.uiService.activeView === 'Sample'){
      this.footerService.initButtons(FooterViews.SampleDropDetails);
    }else if (!this.uiService.showNewActivity && this.activityService.selectedActivity && this.uiService.activeView === ActivityType.Email && this.emailService.viewType === EmailViewType.FROM_CONTACT_TIMELINE){
      this.footerService.initButtons(FooterViews.EmailDetails);
    }else if (!this.uiService.showNewActivity && this.activityService.selectedActivity && this.uiService.activeView === ActivityType.CaseIntake){
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    }
    else{
      this.footerService.initButtons(FooterViews.Contacts);
    }
  }

  ionViewDidEnter() {
    setTimeout(()=>{
      this.events.publish('contactsPageDidEnter');
    }, 300);
  }

  ngAfterViewInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.ContactPageNavigation, PageName.ContactPageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
  }

  onCancelNewActivity() {
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'contactDetails';
  }

  onAddNewContactHandler(event) {
    this.uiService.activeView = 'ContactPageRightPaneNav';
    this.uiService.showNewActivity = false;
  }

  onEditContactHandler() {
    this.uiService.activeView = 'ContactPageRightPaneNav';
    this.uiService.showNewActivity = false;
  }

  onOpenScientificPlanDetails(plan) {
    if(plan){
      this.uiService.activeView = 'ContactPageRightPaneNav';
      this.uiService.showNewActivity = false;
      // this.scientificActivityService.isRightPaneNavActive = true;
      this.navService.setChildNavRoot(ScientificActivityDetail, PageName.ScientificActivityDetail, PageName.ContactPageComponent, { selectedSAP: plan, selectedContactId: this.contactService.contactInformation.ID, from: 'ContactTools' });
      // this.footerService.initButtons(FooterViews.AccountPlanObjectives);
    }
  }
}
