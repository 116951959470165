<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel"  [searchDebounce]="500" (onSearchInput)="onInput($event.detail)" (onSearchClear)="onCancel($event)"  (onFilterClick)="openCustomNotificationFilterPopup($event)"></ind-header-left>
  <!-- plan tab in activities page -->
  <ion-toolbar class="container-c" *ngIf="!faService.isShortCallLauncherEnabled">
    <ion-button fill="clear" size="small" (click)="onSelectedTabChange('notifications')" class="notification-btn tab-selected">
      <ion-icon slot="icon-only" src="assets/imgs/notification_bluedot.svg" class="tab-notification-dot" *ngIf="assistantService.hasAnyUnRedNotifications"></ion-icon>
      <ion-icon slot="icon-only" src="{{ uiService.activitiesPageTab === 'notifications' ? 'assets/imgs/notifications-active.svg' : 'assets/imgs/notifications-inactive.svg'}}"></ion-icon>
    </ion-button>
    <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab" (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft': visibleNotifications?.length === 0}">
    <ng-container>
      <div *ngIf="visibleNotifications?.length === 0" class="no-data-message">{{'NO_NOTIFICATIONS' | translate}}</div>
      <ion-list *ngIf="visibleNotifications.length > 0" class="notifications-list">
        <ind-section-header [viewData]="allNotificationsSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
        <div *ngFor="let notification of visibleNotifications">
          <ion-item-sliding #slidingItem>
            <main-card [viewData]="mapNotificationMainCard(notification)"></main-card>
            <ion-item-options side="end">
              <div class="sliding-button" (click)="clearNotification(notification); slidingItem.close();">
                <img class="sliding-icon" src="assets/imgs/clear_notification.svg" width="40" />
                <p class="sliding-btn-text">{{'SCRAP'|translate}}</p>
              </div>
              <div class="sliding-button" *ngIf="!notification.isRed" (click)="markReadNotification(notification); slidingItem.close();">
                <img class="sliding-icon" src="assets/imgs/markread_notification.svg" width="40" />
                <p class="sliding-btn-text">{{'READ'|translate}}</p>
              </div>
              <div class="sliding-button" *ngIf="notification.isRed" (click)="markUnReadNotification(notification); slidingItem.close();">
                <img class="sliding-icon" src="assets/imgs/markunread_notification.svg" width="40" />
                <p class="sliding-btn-text">{{'UNREAD'|translate}}</p>
              </div>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </ion-list>
      <ion-infinite-scroll *ngIf="filteredNotifications.length > 0" (ionInfinite)="doInfinite($event)" threshold="15%">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ng-container>
</ion-content>
