<ion-header>
  <ind-page-title [viewData]="kolStatusDetailsPanePageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ng-container>
    <ion-list class='details-list' *ngIf="currentKOLStatus">
        <ind-section-header [viewData]='detailsHeader'></ind-section-header>
        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="nameFormField"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="kolTypeFormField"></ind-form-field></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6"><ind-form-field [viewData]="diseaseAreaFormField"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="therapeuticAreaFormField"></ind-form-field ></ion-col>
          <!-- <ion-col size="6"><ind-form-field [viewData]="specialityFormField"></ind-form-field></ion-col> -->
        </ion-row>
        <!-- <ind-form-field [viewData]="commentsFormField"></ind-form-field> -->
    </ion-list>
    <ion-list>
      <ind-section-header  [viewData]="notesSectionHeader"></ind-section-header>
      <ion-item class='empty-item' *ngIf="isReadOnlyMode && !currentKOLStatus.comments"></ion-item>
      <ion-item *ngIf="!(isReadOnlyMode && !currentKOLStatus.comments)" class="notesemptysection" lines="none">
        <ind-text-area  [readonly]="isReadOnlyMode" [maxLength]="1000" [(value)]="currentKOLStatus.comments" (indBlur)="handleEditComments($event)" [rows]="4" [cols]="10"></ind-text-area>
      </ion-item>
    </ion-list>
    <ion-list *ngIf="currentKOLStatus.statuscode != 1">
      <ind-section-header  [viewData]="otherDetailsSectionHeader"></ind-section-header>
      <ion-row>
        <ion-col size="6"><ind-form-field [viewData]="dateFormField"></ind-form-field></ion-col>
        <ion-col size="6"><ind-form-field [viewData]="statusFormField"></ind-form-field></ion-col>
      </ion-row>
      <ind-form-field *ngIf="currentKOLStatus.isApprovalRecord" [viewData]="createdByFormField"></ind-form-field >
    </ion-list>
  </ng-container>
</ion-content>
<footer-toolbar [footerView]="'detailView'" [selectedView]="''" (buttonClicked)="onFooterButtonClicked($event)"></footer-toolbar>