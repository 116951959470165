import { Injectable } from "@angular/core";
import { DeviceService } from "../device/device.service";
import { NotificationService } from "../notification/notification.service";
import { IoFileService } from "../io-file-service/io-file-service";
import { DiskService } from "../disk/disk.service";
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { AuthenticationService } from "../authentication.service";
import { Endpoints, HOST, GLOBAL } from "../../../config/endpoints.config";
import { HttpClient } from "@angular/common/http";
import { SettingsAbout } from "../../classes/app-settings/settings-about";
import { LogService } from "../logging/log-service";
import { Observable, BehaviorSubject } from 'rxjs';

const DOCUMENT_KEY = "legalDocuments";
const DOCUMENT_LAST_UPDATE_KEY = "docLastUpdated";
@Injectable({
  providedIn: 'root'
})
export class SettingsService {


    private _isInitialMappingDone: boolean = false;
    public legalDocs: SettingsAbout[] = [];
    private _isDownloaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly isDownloaded: Observable<boolean> = this._isDownloaded$.asObservable();

    constructor(
        private deviceService: DeviceService,
        private ioFileService: IoFileService,
        private disk: DiskService,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private logService: LogService

    ) {

    }

    public async downloadSettings(loadFromDbOnly = false) {

      // China instance we dont have Documents configured in Azure, so bypassing
      // if(localStorage.getItem("region") && localStorage.getItem("region") === "China") return;
        // let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_SETTINGS_ABOUT);
        // const isInitialSync = !syncState || !syncState.lastUpdatedTime;

        if (loadFromDbOnly || this.deviceService.isOffline) {
            const docs = await this.disk.getFromSecureStorage(DOCUMENT_KEY);
            if(docs) {
                this.mapDocuments(JSON.parse(docs), true);
            }
        } else {

        try {

        //Clear secure storage on first app launch
        if(localStorage.getItem("firsttimeapploaded")=== null){
          await this.disk.removeFromSecureStorage(DOCUMENT_LAST_UPDATE_KEY);
          localStorage.setItem("firsttimeapploaded","true");
        }

        } catch (error) {
            console.error('downloadSettings: ', error);
        }

        let docLastUpdatedTime = await this.disk.getFromSecureStorage(DOCUMENT_LAST_UPDATE_KEY);
        const isInitialSync = !docLastUpdatedTime;


        // await this.saveToSecureStorage(storageKey, rawKey);

        let url: string = (this.authenticationService.userConfig ? this.authenticationService.userConfig.activeInstance.entryPointUrl : 'https://' + HOST) + Endpoints.appsetting.GET_ABOUT;

        // url += (isInitialSync) ? '' :'?lastUpdatedTime=' + syncState.lastUpdatedTime;
        url += (isInitialSync) ? '' :'?lastUpdatedTime=' + docLastUpdatedTime;

        let response: any;
        let headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
        headers.headers.set('Sync-Service', 'true');
        // const aboutSyncInfo: EntitySyncInfo = {
        //     entityName: EntityNames.settingsAbout,
        //     totalFailed: 0,
        //     totalSynced: 0,
        //     errors: [],
        //     syncStatus: true
        // };

        try {
            response = await this.http.get(url, headers).toPromise();
        } catch (error) {
            console.log("Error getting legal documents");
        }

        if(response) {

            // if(!this._isInitialMappingDone){
            //     try{
            //         // let docs = await this.disk.retrieve(DB_KEY_PREFIXES.SETTINGS_ABOUT);
            //         let docs = await this.disk.getFromSecureStorage(DOCUMENT_KEY);
            //         if(docs) {
            //             this.mapDocuments(docs, true);
            //         }
            //         this._isInitialMappingDone = true;
            //     } catch (error) {
            //         console.warn('Error reading about docs data from offline db');
            //     }
            // }

            let newLastUpdatedTime = new Date().getTime().toString();
            if(isInitialSync){
                this.mapDocuments(response);
            } else {
                this.mapDeltaDocuments(response);
            }

            try {
                this.disk.saveToSecureStorage(DOCUMENT_LAST_UPDATE_KEY, newLastUpdatedTime);
            } catch (error) {
                console.log("Failed saving document last updated");
            }

            // // Done sync. Update sync state.
            // if (aboutSyncInfo.syncStatus) {
            //     syncState.lastUpdatedTime = newLastUpdatedTime;
            //     aboutSyncInfo.totalSynced = response.length;
            //     await this.disk.updateSyncState(syncState);
            // }
            // this.deltaService.addEntitySyncInfo(aboutSyncInfo);

        } else {
            let docs = await this.disk.getFromSecureStorage(DOCUMENT_KEY);
            if(docs) {
                this.mapDocuments(JSON.parse(docs), true);
            }
        }
        }
    }

    public async mapDocuments(raw: any, doNotSave?: boolean) {
        if (Array.isArray(raw)) {
            await this.refreshDocumentList(raw, doNotSave);
            this._isDownloaded$.next(true);
        } else {
            console.error('Unsupported data format for about doc!');
        }

        if (!doNotSave) {
            //Save to DB or update
            try {
                this.disk.saveToSecureStorage(DOCUMENT_KEY, JSON.stringify(raw));
                this.logService.logInfo("Successfully saved Settings About");
            } catch (error) {
                this.logService.logError("Failed saving Settings About");
                console.log(error);
            }


        }
    }

    public async mapDeltaDocuments(raw:any) {
        if (raw && Array.isArray(raw)) {
            let docsString = await this.disk.getFromSecureStorage(DOCUMENT_KEY);
            let docsRaw = JSON.parse(docsString);

            if (docsRaw && docsRaw.length > 0) {
                if(this.legalDocs.length <= 0) {
                    for(let r of docsRaw){
                        this.legalDocs.push(new SettingsAbout(r));
                    }
                }
            }

            for (let i = 0; i < raw.length; i++) {
                const rawDoc = raw[i];

                let dbIndex;
                if(docsRaw) {
                    dbIndex = docsRaw.findIndex(a => a.name == rawDoc.name);
                    if(dbIndex >= 0) {
                        docsRaw.splice(dbIndex,1);
                        docsRaw.push(rawDoc);
                    }
                }

                const docIndex = this.legalDocs.findIndex(a => a.name == rawDoc.name);
                if(docIndex >= 0) {
                    this.legalDocs.splice(docIndex,1);
                    this.legalDocs.push(new SettingsAbout(rawDoc));

                    if(this.deviceService.isNativeApp && !(this.deviceService.isOffline || this.deviceService.isDeviceRealOffline)) {
                        console.log("downloading legal documents");
                        await this.ioFileService.downloadDocuments(rawDoc.uri, rawDoc.name + ".pdf").toPromise();
                    }
                }
            }

            if (docsRaw && docsRaw.length > 0) {
                this._isDownloaded$.next(true);
                try {
                    this.disk.saveToSecureStorage(DOCUMENT_KEY, JSON.stringify(docsRaw));
                    this.logService.logInfo("Successfully updated Settings About");
                } catch (error) {
                    this.logService.logError("Failed saving Settings About");
                }
            }

        }
    }

    public async refreshDocumentList(raw: any, doNotSave: boolean) {

        this.legalDocs = [];
        if (Array.isArray(raw)) {
            const promises = raw.map(async rawDoc => {
                this.legalDocs.push(new SettingsAbout(rawDoc));
                if(!doNotSave) {
                    if(this.deviceService.isNativeApp && !(this.deviceService.isOffline || this.deviceService.isDeviceRealOffline)) {
                        console.log("downloading legal documents");
                        await this.ioFileService.downloadDocuments(rawDoc.uri, rawDoc.name + ".pdf").toPromise();
                    }
                }
            });

            await Promise.all(promises);
        } else {
            console.error('Unsupported data format for legal documents!');
        }
    }



}
