
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { format } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountPlanObjective } from '../../../classes/account-management/account-plan.class';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { PlanProgressReport } from '../plan-progress-report/plan-progress-report';
import { AccountPlan, AccountPlanObjectiveReport } from './../../../classes/account-management/account-plan.class';
import { AccountManagementDataService } from './../../../data-services/account-management/account-management.data.service';
import { AuthenticationService } from './../../../services/authentication.service';
import { FooterService, FooterViews } from './../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from './../../../services/logging/tracking.service';
import { NotificationService } from './../../../services/notification/notification.service';


@Component({
  selector: 'account-objective-details',
  templateUrl: 'account-objective-details.html',
  styleUrls: ['account-objective-details.scss']
})
export class AccountObjectiveDetails implements OnInit, OnDestroy {

  public objectiveData: AccountPlanObjective;
  public reportsData: AccountPlanObjectiveReport[] = [];
  public allModelReports: MainCardViewDataModel[] = [];
  public objectiveDetailHeaderModel: IndSectionHeaderViewDataModel;
  public objectiveCSFHeaderModel: IndSectionHeaderViewDataModel;
  public objectiveReportsHeaderModel: IndSectionHeaderViewDataModel;
  selectedPlan: AccountPlan;
  ngUnsubscribe$ = new Subject<boolean>();
  public objectiveDataStatus: string;
  isReportCreataionEnabled = false;
  public backButton = [{
    id: 'close',
    icon: 'chevron-back-outline',
    isDisabled: false,
    align: 'left'
  }];

  constructor(
    public device: DeviceService,
    public repService: RepServices,
    private readonly UIService: UIService,
    private readonly accountManagementService: AccountManagementOfflineService,
    public accountManagementDataService: AccountManagementDataService,
    private readonly navParams: NavParams,
    public authService: AuthenticationService,
    public footerService: FooterService,
    private readonly navService: NavigationService,
    public trackingService: TrackService,
    public notifyService: NotificationService,
    private readonly translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) {
  }

  ngOnInit() {
    this.objectiveData = (this.navParams.data && this.navParams.data.data);
    this.selectedPlan = (this.navParams.data && this.navParams.data.accountPlanRef);
    this.reportsData = this.filterAndSortReports(this.accountManagementService.allProgressReports);
    this.isReportCreataionEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_PLAN_PROGRESS_REPORTS_CREATION);
    this.accountManagementService.allProgressReports$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((data) => {
      if (data && data.length > 0) {
        this.reportsData = this.filterAndSortReports(data);
      }
    });
    this.initHeaderTitles();
    this._initReportsModel();
    this.objectiveDataStatus = this.getStatus(this.objectiveData.status);
  }

  ionViewDidEnter() {
    this.reportsData = this.filterAndSortReports(this.accountManagementService.allProgressReports)
    this._initReportsModel();
  }

  filterAndSortReports(data: AccountPlanObjectiveReport[]) {
    let reportsData = data.filter(r => r.planObjectiveID === this.objectiveData.objectiveID && !(r.state === 1 && r.statusCode === 2)).sort((a, b): number => {
      return a.createdOn < b.createdOn ? 1 : -1;
    });
    //filter reports- open ones for own view, for review and approved ones for all
    reportsData = reportsData.filter((o) => {
      return o.status === 'Approved' || (o.createdByUserID === this.authService.user.systemUserID)
        || (o.status === 'For Review');
    });
    return reportsData;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  initHeaderTitles() {
    this.initDetailsheader();
    this.initCSFHeader();
    this.initProgressReportHeader();
  }

  private initDetailsheader() {
    this.objectiveDetailHeaderModel = {
      id: 'objective-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: []
    };
  }

  private initCSFHeader() {
    this.objectiveCSFHeaderModel = {
      id: 'objective-csf-header',
      title: this.translate.instant('ACCOUNTS_OBJECTIVE_CRITICAL_SUCCESS_FACTORS'),
      controls: []
    };
  }

  private initProgressReportHeader() {
    this.objectiveReportsHeaderModel = {
      id: 'objective-reports-header',
      title: this.translate.instant('ACCOUNTS_OBJECTIVE_PROGRESS REPORTS'),
      controls: [{
        id: 'progress-report-add',
        text: this.translate.instant('CREATE'),
        isDisabled: this.selectedPlan.status !== 'Open' || !this.isReportCreataionEnabled,
      }]
    };
  }

  public get statusFormField(): IndFormFieldViewDataModel {

    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.objectiveDataStatus,
      id: 'objective-status',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel: '',
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get objectiveFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS_OBJECTIVE'),
      inputText: this.objectiveData.name,
      id: 'objective-objective-field',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel:  '',
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get productFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: this.objectiveData.type !== 'QUANTITATIVE',
      label: this.translate.instant('PRODUCT'),
      inputText: this.objectiveData.productName,
      id: 'objective-product-field',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel:  !this.objectiveData.productName? this.translate.instant('NO_PRODUCT') :'',
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get targetFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS_OBJECTIVE_TARGET'),
      inputType: 'number',
      inputText: `${this.objectiveData.type === 'QUANTITATIVE' ? (this.objectiveData.formattedTarget ? this.dateTimeFormatsService.formattedCurrency(this.objectiveData.formattedTarget) : 0) : 100+'%'}`,
      inputValue: this.objectiveData.target,
      id: 'assigned-objective-field',
      isReadOnly: !(this.authService.user.editTheTargetValueFieldInObjectives && this.objectiveData.status == 'Open'),
      isDisabled: this.authService.user.editTheTargetValueFieldInObjectives && this.objectiveData.status == 'Open' && this.device.isOffline,
      customPlaceholderLabel: (this.objectiveData.status == 'Open' && this.authService.user.editTheTargetValueFieldInObjectives) ? this.translate.instant("ENTER_TARGET") : this.translate.instant("NO_TARGET"),
      showArrow: this.objectiveData.status == 'Open' && this.authService.user.editTheTargetValueFieldInObjectives && !this.device.isOffline,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get achievementActualOneFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !this.objectiveData.achievementActualOneFormatted,
      label: this.translate.instant('ACHIEVEMENT_ACTUAL_ONE'),
      inputText: `${(this.objectiveData.achievementActualOneFormatted) ? this.dateTimeFormatsService.formattedCurrency(this.objectiveData.achievementActualOneFormatted) : 0}`,
      id: 'achievement-actual-one',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel: '',
      showArrow:false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get achievementProjectedOneFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !(this.authService.user.editTheTargetValueFieldInObjectives && this.objectiveData.status == 'Open') && !this.objectiveData.achievementProjectedOne,
      label: this.translate.instant('ACHIEVEMENT_PROJECTED_ONE'),
      inputText: `${(this.objectiveData.achievementProjectedOneFormatted) ? this.dateTimeFormatsService.formattedCurrency(this.objectiveData.achievementProjectedOneFormatted) : 0}`,
      inputValue: this.objectiveData.achievementProjectedOne,
      id: 'achievement-projected-one',
      isReadOnly: !(this.authService.user.editTheTargetValueFieldInObjectives && this.objectiveData.status == 'Open'),
      isDisabled: this.authService.user.editTheTargetValueFieldInObjectives && this.objectiveData.status == 'Open' && this.device.isOffline,
      customPlaceholderLabel: (this.objectiveData.status == 'Open' && this.authService.user.editTheTargetValueFieldInObjectives) ? this.translate.instant("ENTER_ACHIEVEMENT_PROJECTED_ONE") : '',
      showArrow: this.objectiveData.status == 'Open' && this.authService.user.editTheTargetValueFieldInObjectives && !this.device.isOffline,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get achievementTotalOneFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !this.objectiveData.achievementTotalOneFormatted,
      label: this.translate.instant('ACHIEVEMENT_TOTAL_ONE'),
      inputText: `${(this.objectiveData.achievementTotalOneFormatted) ? this.dateTimeFormatsService.formattedCurrency(this.objectiveData.achievementTotalOneFormatted) : 0}`,
      id: 'achievement-total-one',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel: '',
      showArrow:false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get achievementTwoFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !this.objectiveData.achievementTwoFormatted,
      label: this.translate.instant('ACHIEVEMENT_TWO'),
      inputText: `${(this.objectiveData.achievementTwoFormatted) ? this.dateTimeFormatsService.formattedCurrency(this.objectiveData.achievementTwoFormatted) : 0}`,
      id: 'achievement-two',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel: '',
      showArrow:false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get completedValueFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COMPLETE_VALUE'),
      inputText: `${(this.objectiveData.formattedCompletedValue) ? this.dateTimeFormatsService.formattedCurrency(this.objectiveData.formattedCompletedValue) : 0}`,
      id: 'objective-completed-field',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel: '',
      showArrow:false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get completedPercent(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COMPLETED_PERCENT'),
      inputText: `${(this.objectiveData.completedPercent) ? this.objectiveData.completedPercent : 0}`,
      id: 'objective-completedPercent-field',
      isReadOnly: true,
      isDisabled: false,
      customPlaceholderLabel: '',
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

    private handleFormFieldEvent(id, event, eventName) {
      if(id == 'assigned-objective-field' && eventName === 'input_value_confirm'){
        this.setTargetValueForObjective(event);
      }
      if(id == 'achievement-projected-one' && eventName === 'input_value_confirm'){
        this.setAchievementProjectedOneValueForObjective(event);
      }
    }

  public backToAccountPlanDetails(): void {
    this.navService.popChildNavPageWithPageTracking();
  }

  public newReport(): void {
    this.trackingService.tracking('AccountPlanProgressReportCreate', TrackingEventNames.ACCOUNTPLANNING, true);
    const now = Date.now();
    const report = new AccountPlanObjectiveReport({
      'PO.indskr_accountplanobjectiveid': this.objectiveData.objectiveID,
      'report.statuscode': 1,
      'syncPending': true,
      'report.indskr_externalid': 'offline_progress_report_' + now,
      'report.createdon': now,
      'report.createdby': this.authService.user.systemUserID,
      'report.createdby_Formatted': this.authService.user.displayName,
      'indskr_accountplanid': this.selectedPlan.ID,
      'report.indskr_name': this.authService.user.displayName
    });
    this.UIService.displayLoader();
    this.accountManagementDataService.createProgressReport({
      onDynamics: !this.device.isOffline,
      onLocalCopy: true,
      onLocalDatabase: true
    }, report).then((success) => {
      this.reportsData.unshift(report);
      this._initReportsModel();
      this.UIService.dismissLoader();
      this.navService.pushChildNavPageWithPageTracking(PlanProgressReport, PageName.PlanProgressReportComponent, PageName.AccountManagementPageComponent,
        { accountPlanRef: this.selectedPlan, objective: this.objectiveData, report: report }).then(() => {
          this.footerService.initButtons(FooterViews.AccountPlanReport, {status:report.status, isApprovalEnabled:false});
      });
    }, (error) => {
      this.UIService.dismissLoader();
      if (error.errorCode && error.errorCode === 'ERR_IO_DELETE_APPR') {
        if (error.entityName === 'indskr_accountplans' && error.entityId && error.entityId === this.selectedPlan.ID) {
          const index = this.reportsData.findIndex(rep => rep.reportId === report.reportId);
          if(index>=0) this.reportsData.splice(index, 1);
          if (this.navService.getActiveChildNavViewPageName() === PageName.PlanProgressReportComponent) {
            this.notifyService.notify(this.translate.instant('ACCOUNTS_OBJECTIVE_NOTIFY_SYNC_UPDATE'), '', 'top', '');
            this.navService.popWithPageTracking();
          }
        }
      }
      else{

      }
    });
  }
  public goToReportdetails(report: AccountPlanObjectiveReport) {
    this.trackingService.tracking('AccountPlanProgressReportClicked', TrackingEventNames.ACCOUNTPLANNING)
    this.navService.pushChildNavPageWithPageTracking(PlanProgressReport, PageName.PlanProgressReportComponent, PageName.AccountManagementPageComponent,
      { accountPlanRef: this.selectedPlan, objective: this.objectiveData, report: report });
  }

  formattedDate(val): string {
    return format(parseInt(val), this.dateTimeFormatsService.dateToUpper) || '';
  }
  private getStatus(status): string {
    switch (status) {
      case 'Open':
        status = this.translate.instant('OPEN');
        break;
      case 'For Review':
        status = this.translate.instant('FOR_REVIEW');
        break;
      case 'Approved':
        status = this.translate.instant('APPROVED');
        break;
      case 'Inactive':
        status = this.translate.instant('OPEN');
        break;
      default:
        status = this.translate.instant('OPEN');
        break;
    }
    return status;
  }
  private _initReportsModel() {

    this.allModelReports = [];

    if (this.reportsData && this.reportsData.length !== 0) {
      this.allModelReports = this.reportsData.map(report => {
        const status = this.getStatus(report.status);
        const viewModel: MainCardViewDataModel = {
          id: report.reportId,
          primaryTextLeft: '',
          primaryTextRight: report.createdBy + ' - ' + this.formattedDate(report.createdOn),
          secondaryTextRightTwo: status,
          isSecondaryTextRightPillType: true,
          showArrow: true,
          fixedHeight: true,
          arrowType: 'chevron-forward',
          clickHandler: (id: string, event) => {
            this.goToReportdetails(this.reportsData.find(f => f.reportId === id));
          },
        };

        if (this.objectiveData.type === 'QUANTITATIVE') {
          viewModel.secondaryTextRight = report.formattedCompletedValue ? (this.dateTimeFormatsService.formattedCurrency(report.formattedCompletedValue)) : '0';
        } else {
          viewModel.secondaryTextRight = (report.completedPercent) ? report.completedPercent + '%' : '0%';
        }
        return viewModel;
      });
    }
  }

  onSectionHeaderControlClick(id: string) {
      if ( id === 'progress-report-add') {
        this.newReport();
      }
  }

  public async setTargetValueForObjective(event) {
    if (this.objectiveData.status !== 'Open' || this.device.isOffline) return;
    let newValue;
    if (event) {
      if ((event.value == null && event.target.value == null) || event.value === '' || event.target.value === '') {
        newValue = 0;
      } else {
        newValue = event.value ? event.value : event.target.value;
      }
    } else {
      newValue = 0;
    }

    if (newValue < 0) newValue = 0;
    if(newValue != this.objectiveData.target){
      this.UIService.displayLoader();
      this.objectiveData.target = parseInt(newValue);
      this.objectiveData.formattedTarget = this.dateTimeFormatsService.selectedSymbolPos.value == 'left'
        ? this.objectiveData.currencySymbol + this.objectiveData.target.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.objectiveData.target.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.objectiveData.currencySymbol;
      this.objectiveData.completedPercent = Math.floor(this.objectiveData.comletedValue / this.objectiveData.target * 100);
      await this.accountManagementDataService.updateObjectiveTarget(this.objectiveData);
      this.UIService.dismissLoader();
    }
  }

  public async setAchievementProjectedOneValueForObjective(event) {
    if (this.objectiveData.status !== 'Open' || this.device.isOffline) return;
    let newValue;
    if (event) {
      if ((event.value == null && event.target.value == null) || event.value === '' || event.target.value === '') {
        newValue = 0;
      } else {
        newValue = event.value ? event.value : event.target.value;
      }
    } else {
      newValue = 0;
    }

    if (newValue < 0) newValue = 0;
    if(newValue != this.objectiveData.achievementProjectedOne){
      this.UIService.displayLoader();
      this.objectiveData.achievementProjectedOne = parseInt(newValue);
      this.objectiveData.achievementProjectedOneFormatted = this.dateTimeFormatsService.selectedSymbolPos.value == 'left'
        ? this.objectiveData.currencySymbol + this.objectiveData.achievementProjectedOne.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.objectiveData.achievementProjectedOne.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.objectiveData.currencySymbol;
      this.objectiveData.achievementTotalOne = Math.floor(this.objectiveData.achievementProjectedOne + this.objectiveData.achievementActualOne);
      this.objectiveData.achievementTotalOneFormatted = this.dateTimeFormatsService.selectedSymbolPos.value == 'left'
        ? this.objectiveData.currencySymbol + this.objectiveData.achievementTotalOne.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.objectiveData.achievementTotalOne.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.objectiveData.currencySymbol;
      await this.accountManagementDataService.updateObjectiveTarget(this.objectiveData);
      this.UIService.dismissLoader();
    }
  }
}
