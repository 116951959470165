import { Component, Injector, Input, ViewChild } from '@angular/core';
import { NavParams, NavController, LoadingController, IonNav } from '@ionic/angular';
import { PresentationView } from '../../services/ui/ui.service'
import { FooterViews, FooterService } from '../../services/footer/footer.service';
import { PresentationService } from '../../services/presentation/presentation.service';
import { DeviceService, DEVICE_ORIENTATION } from '../../services/device/device.service';
import { NavigationService, PageName, ChildNavNames } from '../../services/navigation/navigation.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PresentationPreviewComponent } from '../../components/presentation/presentation-preview/presentation-preview';
import { IoEventListener } from '../../services/io-event-listener.decorator';
import { SwipeStatus } from '../../classes/presentation/presentation.class';


@Component({
  selector: 'presentation-page[base-page]',
  templateUrl: 'presentation.html',
  styleUrls:['presentation.scss']
})
export class PresentationPageComponent {

  public viewMode: any;
  public presView = PresentationView;

  private _screenOrientation: Subscription;
  public isPortrait = false;
  public presViewId:string;
  @Input() selectedContentIds: [];
  @Input() callbackEvent: any;

  @ViewChild('presentationtoolsrightpane', {static: true}) public navCtrl: IonNav;


  constructor(
    public injector: Injector,
    public navParams: NavParams,
    private footerService:FooterService,
    public device: DeviceService,
    public presentationService: PresentationService,
    private loadingCtrl: LoadingController,
    private navService: NavigationService,
    public translate:TranslateService
  ) {
    this.viewMode = this.presentationService.viewMode;
     if((this.navParams.data && this.navParams.data.presPreview))
     {
       this.presViewId=(this.navParams.data && this.navParams.data.presId)
     }
    if(this.presentationService.loadedPresentation) {
      this.loadingCtrl.create().then((load)=>{
        load.present();
        setTimeout(()=>{
          load.dismiss();
        }, 500);
      });
    }

    this._screenOrientation = this.device.screenOrientation.subscribe((orientation: number) => {

      if ((orientation === DEVICE_ORIENTATION["landscape-primary"] || orientation === DEVICE_ORIENTATION["landscape-secondary"]))  {
        this.isPortrait = false;
      } else {
        this.isPortrait = true;
      }
    });

    this.isPortrait = this.device.isOrientationPortrait();
  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.PresentationToolsNavigation, PageName.PresentationPageComponent, false, {deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    let pushedFrom;
    if(this.viewMode == PresentationView.MENU) {
      pushedFrom = 'PresentationToolsPage'
    } else {
      pushedFrom = 'ActivityPageComponent'
    }
    /**
     * Need to handle all the pages it's coming from
     * might be redundant with view mode
     */
    // this.navService.setChildNavRoot(PresentationPreviewComponent, PageName.PresentationPreviewComponent, PageName.PresentationPageComponent, { from: 'PresentationToolsPage', viewMode: this.viewMode }).then(() => {
    //   this.navService.setChildNavRightPaneView(true);
    // })
  }

  ngOnDestroy() {
    if (this.presentationService.viewMode !== PresentationView.MEETING) {
      this.presentationService.setCurrentSelectedPresPage(null);
    }
    this._screenOrientation.unsubscribe();
  }

  public goBack() {
    this.navService.popToRootWithPageTracking();
  }
  ionViewDidLoad(){
  }

  ionViewCanEnter(){
    this.presentationService.viewMode = this.viewMode;
    this.footerService.initButtons(FooterViews.Presentations);
    if(this.viewMode != this.presView.MENU){
      this.footerService.hideButton('play');

    }
  }

  @IoEventListener("window:message", message => message.type === 'io-spa' && message.action === 'page-init', message => [message.data])
  onSlidePageInit(initData) {
    // Update swipe button status for interactive presentations
    let { isInteractivePage } = initData;
    this.presentationService.swipeStatus = isInteractivePage ? SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION :
                                          this.presentationService.swipeStatus === SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION ? SwipeStatus.ENABLED_DEFAULT : this.presentationService.swipeStatus;
  }
}
