<ion-header>
  <ind-page-title [viewData]="pageTitleViewData" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <timeline-item [inputData]="mainTimelineItem"></timeline-item>

  <ind-section-header [viewData]='productsKeyMessagesSectionHeader'></ind-section-header>
  <ion-list class="product-key-messages-list">
    <ion-item *ngFor="let item of productKeyMessagesViewData">
      <ion-label>
        {{item.label}}
        <span class="key-messages-text">
          {{item.keyMessages}}
        </span>
      </ion-label>
    </ion-item>
    <ion-item class='empty-item' *ngIf="productKeyMessagesViewData.length == 0"></ion-item>
  </ion-list>

  <ind-section-header [viewData]='keyMessagesSentimentSectionHeader'></ind-section-header>
  <ion-list class="key-messages-sentiment-list">
    <ion-item *ngFor="let item of keyMessageSentimentsViewData">
      <ion-label>
        {{item.indskr_name}}
      </ion-label>
      <ion-icon [src]="item.sentimentImgURL" class="sentiments-icon" slot="end"> </ion-icon>
      <ion-icon src="assets/imgs/omni_arrow_down.svg" class="arrow-icon"  slot="end"></ion-icon>
    </ion-item>
    <ion-item class='empty-item' *ngIf="keyMessageSentimentsViewData.length == 0"></ion-item>
  </ion-list>

  <ind-section-header [viewData]='contentSentimentSectionHeader'></ind-section-header>
  <ion-list class="content-sentiments">
    <ion-row *ngFor=" let content of contentSentimentsViewData">
        <ion-item class="main-item">
            <ion-label class="main-label">
                {{content.contentName}}
            </ion-label>
        </ion-item>
        <ion-item class="slide-item" *ngFor="let slide of content.slidesData">
            <ion-icon *ngIf="slide.isChecked" src="assets/imgs/omni_slide_checked.svg" class="check-icon"> </ion-icon>
            <ion-icon *ngIf="!slide.isChecked" src="assets/imgs/omni_slide_unchecked.svg" class="check-icon"> </ion-icon>
            <div class="slide-div">
                <ion-text class="slide-label">
                    {{slide.slideName}}
                </ion-text>
                <ion-text class="slide-text">
                    <span class="slide-duration">
                        {{slide.slideDuration}}
                    </span>
                    <span class="slide-key-messages" *ngIf="slide.keyMessagesStr">
                       | {{slide.keyMessagesStr}}
                    </span>
                </ion-text>
            </div>
            <ion-icon [src]="slide.sentimentImage" class="sentiments-icon" slot="end"> </ion-icon>
            <ion-icon src="assets/imgs/omni_arrow_down.svg" class="arrow-icon" slot="end"></ion-icon>
        </ion-item>
    </ion-row>
    <ion-item class='empty-item' *ngIf="contentSentimentsViewData.length == 0"></ion-item>
  </ion-list>

</ion-content>