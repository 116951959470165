<ion-grid>
  <ion-row>
    <ion-col class="left-pane">
      <ion-header>
        <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
          <ion-buttons slot="start">
              <ion-button color="primary" (click)="onClosePage(false);">
                  Cancel
              </ion-button>
          </ion-buttons>
          <ion-title right class="ion-text-center leftScreenHeaderTitle">Filters</ion-title>
          <ion-buttons slot="end">
            <ion-button color="primary" (click)="onClosePage(true);">
                Done
            </ion-button>
        </ion-buttons>
      </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="container-c mainContainer">
          <div *ngFor="let filter of filterData" class="container-v">
            <h3>{{filter.filterName}}</h3>
            <ion-item class="ion-no-padding">
              <ion-select (ionChange)="filterChanged(filter)" [(ngModel)]="filter.modelVariable" interface="popover" class="timelineFilter ion-no-margin" multiple="true">
                <ion-select-option *ngFor="let option of filter.data" [value]="option.value">{{option.displayText}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <!-- <div class="container-v">
            <h3>Month</h3>
            <ion-item>
              <ion-select (ionChange)="filterChanged('month')" [(ngModel)]="selectedMonths"
                interface="popover" class="timelineFilter" multiple="true">
                <ion-option *ngFor="let option of monthFilterOptions" [value]="option.value" [selected]="option.isSelected">{{option.displayText}}</ion-option>
              </ion-select>
            </ion-item>
          </div> -->
        </div>
      </ion-content>
    </ion-col>
    <ion-col class="right-pane">
      <ion-header>

      </ion-header>
      <ion-content>

      </ion-content>
    </ion-col>
  </ion-row>
</ion-grid>
