
export class AssistantData {
    public Description: string;
    public name: string;
    public DateTime: Date
    public DateAndTime: string;
    public Type: string;
    public read: boolean;
    public timeStamp: number;

    constructor(raw: object) {
        this.Description = raw['Description'];
        this.name = raw['name'];
        this.DateTime = raw['DateTime'];
        this.DateAndTime = this.DateTime.toLocaleString();
        this.Type = raw['Type'];
        this.read = raw['read'];
        this.timeStamp = this.DateTime.getTime();
    }
}
export enum DistanceUnits{
  'KM' = 548910000,
  'Miles' = 548910001
}
