export class CustomerSegment {

    public value: string;
    public id:string;
    public isSelected:boolean;

    constructor(payload: CustomerSegmentPayload) {
        this.value = payload.indskr_name; // for accounts and brand its name ; for customer segment and call plan it is payload.idskr_name
        this.id = payload.indskr_customersegmentationid;
    }

}

export interface CustomerSegmentPayload {
  indskr_name: string;
  name:string; //for accounts
  createdon: string;
  indskr_customersegmentationid: string;
}
