import { Pipe, Injectable, PipeTransform } from "@angular/core";
import { CustomerSampleAllocation } from "../classes/sample/customer-sample-allocation.class";

@Pipe(
    {
        name:'eligibilitiesSearch'
    }
)
@Injectable({
  providedIn: 'root'
})
export class EligibilitiesSearchPipe implements PipeTransform{
    transform(eligibilities:CustomerSampleAllocation[],searchText:string,filterMetadata): CustomerSampleAllocation[]{

        let filteredEligibilities: CustomerSampleAllocation[];

        if(searchText && eligibilities){
            searchText = searchText.toUpperCase();
            filteredEligibilities = eligibilities.filter(el => {
                return el.sampleSKUName.toUpperCase().indexOf(searchText) >= 0;
             });
        }else{
            return eligibilities;
        }
        filterMetadata.Count = filteredEligibilities.length;
        
        return filteredEligibilities;
    }
}