import { FeatureActionsMap } from './../../../classes/authentication/user.class';
import { DeviceService } from './../../../services/device/device.service';
import { OrderProductClass } from './../../../classes/activity/order.activity.class';
import { OrderManagementService } from './../../../services/order-management/order-management.service';
import { OrderAddQuantityComponent } from './../../order-management/order-add-quantity/order-add-quantity';
import { NotificationService, ToastStyle } from './../../../services/notification/notification.service';
import { AlertService } from './../../../services/alert/alert.service';
import { QuoteDataService } from './../../../data-services/quote/quote.data.service';
import { ActivityService } from './../../../services/activity/activity.service';
import { AuthenticationService } from './../../../services/authentication.service';
import { UIService } from './../../../services/ui/ui.service';
import { SelectListDetail } from './../../../models/select-list-detail-model';
import { SharedDataService } from './../../../data-services/shared-data/shared.data.service';
import { TrackingEventNames, TrackService } from './../../../services/logging/tracking.service';
import { DateTimeFormatsService } from './../../../services/date-time-formats/date-time-formats.service';
import { IndRightpaneReadonlyComponent } from './../../shared/ind-rightpane-readonly/ind-rightpane-readonly';
import { MainCardViewDataModel } from './../../../models/MainCardViewDataModel';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { RepServices } from './../../../data-services/rep/rep.services';
import { Quote, QuoteState } from './../../../classes/opportunity-management/opportunity.class';
import { IndFormFieldViewDataModel, FormFieldType } from './../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { Component, ViewChild, Input } from '@angular/core';
import { format, getDay } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { FooterService } from '@omni/services/footer/footer.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { PopoverController } from '@ionic/angular';
import _ from 'lodash';
import { QuotesService } from '@omni/services/quote/quote-tool.service';

/**
 * Generated class for the OpportunityQuoteDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'opportunity-quote-details',
  templateUrl: 'opportunity-quote-details.html',
  styleUrls:['opportunity-quote-details.scss'],
})
export class OpportunityQuoteDetailsComponent {
  text: string;
  quoteDetailsHeader: IndSectionHeaderViewDataModel;
  quoteProductsHeader: IndSectionHeaderViewDataModel;
  // quoteDetailsFieldValues: IndFormFieldViewDataModel[];
  statusField: IndFormFieldViewDataModel;
  quoteNumberField: IndFormFieldViewDataModel;
  createdByField: IndFormFieldViewDataModel;
  countryField: IndFormFieldViewDataModel;
  currencyField: IndFormFieldViewDataModel;
  priceListField: IndFormFieldViewDataModel;
  accountField: IndFormFieldViewDataModel;
  effectiveFromField: IndDateTimeFormViewDataModel;
  effectiveToField: IndDateTimeFormViewDataModel;
  totalField: IndFormFieldViewDataModel;
  quoteProducts: MainCardViewDataModel[];
  selectedQuote: Quote;
  isReadOnlyMode: boolean = false;
  // private ignoreChange = false;
  _selectCountryModal: HTMLIonModalElement;
  public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  public weekDay: Array<String> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  public _isDemoFeature = false;

  groupedTimelineData = [];
  quotePageTitle: IndPageTitleViewDataModel;
  @Input() quote: any
  constructor(
    private sharedDataService: SharedDataService,
    public repService: RepServices,
    public navService: NavigationService,
    public uiService: UIService,
    private alertService: AlertService,
    public dateTimeFormatService: DateTimeFormatsService,
    private oms: OrderManagementService,
    private trackingService: TrackService,
    private activityService: ActivityService,
    private notifications: NotificationService,
    private quoteService: QuoteDataService,
    private authService: AuthenticationService,
    private device: DeviceService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private translate: TranslateService,
    public readonly footerService: FooterService,
    private popoverCtrl: PopoverController,
    private quoteOfflineService : QuotesService
  ) {
    this._isDemoFeature = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_QUOTES, true);

    this.quoteDetailsHeader = {
      id:'opportunity-quote-details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
  }
  ngOnInit() {
    if (this.quote) {
      this.selectedQuote = this.quote
    }
    this.refreshView();
    this.initAllDataModels();
  }

  public initAllDataModels(){
    this.getPageTitle();
  }

  public getPageTitle(){
    const buttons = [];
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        isVisible: true,
        align: 'left'
      });
    this.quotePageTitle = {
      id: 'activities-details-page-title',
      title: this.translate.instant('QOUTE_NUMBER_')+this.selectedQuote.quoteNumber,
      controls: buttons.filter(o => o.isVisible)
    };
  }


  public handleButtonClick(id: string): void {
    this.handleButtonEventForQuote(id);
  }


  private handleButtonEventForQuote(buttonId: string){
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
        default:
        return;
    }
  }

  // private getFormattedStatus(status: any) {
  //   // switch (status) {
  //   //   case QuoteState.DRAFT:
  //   //     return this.translate.instant('OM_DRAFT');
  //   //   case QuoteState.ACTIVE:
  //   //     return this.translate.instant('ACTIVE');
  //   //   case QuoteState.WON:
  //   //     return this.translate.instant('Won');
  //   //   case QuoteState.CLOSED:
  //   //     return this.translate.instant('CLOSED');
  //   // }
  //   switch (status) {
  //     case QuoteStatus.APPROVED:
  //       return this.translate.instant("APPROVED");
  //     case QuoteStatus.DRAFT:
  //       return this.translate.instant("OM_DRAFT");
  //     case QuoteStatus.REJECTED:
  //       return this.translate.instant("REJECTED");
  //     case QuoteStatus.DYNAMICS_CANCELED:
  //     case QuoteStatus.CANCELED:
  //       return this.translate.instant("CANCELLED");
  //     case QuoteStatus.PENDING_APPROVAL:
  //       return this.translate.instant("POP_IN_REVIEV");
  //     case QuoteStatus.IN_PROGRESS:
  //       return this.translate.instant("IN_PROGRESS");
  //     case QuoteStatus.DYNAMICS_IN_PROGRESS:
  //     case QuoteStatus.IN_PROGRESS:
  //       return this.translate.instant("IN_PROGRESS");
  //     case QuoteStatus.WON:
  //       return this.translate.instant("WON");
  //     case QuoteStatus.OPEN:
  //       return this.translate.instant("AL_SHIPMENT_FILTERLIST_OPEN");
  //     case QuoteStatus.LOST:
  //       return this.translate.instant("OPPORTUNITY_STATE_LOST");
  //   }
  // }

  private async refreshView() {
    if(this._isDemoFeature){
      let timelineData = [];
      timelineData = await this.quoteService.getQuoteTimeline(this.selectedQuote.quoteID).catch(()=>[]);
      this.groupedTimelineData = timelineData.length>0?this.getGroupedActivitiesByMonth(timelineData):[]
    }
    this.updateFooterViewButtons();
    this.isReadOnlyMode = this.isReadOnlyMode || this.device.isOffline;
    let readOnly = this.isReadOnlyMode || this.device.isOffline || !this._isDemoFeature;
    this.quoteProductsHeader = {
      id: 'opportunity-quote-products-header',
      title: this.translate.instant('PRODUCTS_CAP'),
      controls: readOnly ? [] : [
        {
          id: 'select-products',
          text: this.translate.instant('PRODUCTS'),

        }
      ],
    };

    let _this = this;

    this.statusField = {
        label: this.translate.instant('STATUS'),
        inputText: this.quoteOfflineService.getFormattedStatus(this.selectedQuote.statusCode),
        id: 'quote-status',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
    };
    this.quoteNumberField = {
        label: this.translate.instant('QOUTE_NUMBER'),
        inputText: this.selectedQuote.quoteNumber,
        id: 'quote-number',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
    };
    this.createdByField = {
        label: this.translate.instant('CREATED_BY'),
        inputText: this.selectedQuote.createdByUserName,
        id: 'created-by',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
    };
    this.countryField = {
        label: this.translate.instant('COUNTRY'),
        get inputText() { return _this.selectedQuote.countryName || '' },
        id: 'quote-country',
        isReadOnly: true,
        isDisabled: readOnly,
        showArrow: !readOnly,
        placeholderLabel: readOnly ? this.translate.instant('NO_COUNTRY') : this.translate.instant('COUNTRY'),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: () => this.openCountrySelect(),
    };
    this.currencyField = {
        label: 'Currency',
        get inputText() { return _this.selectedQuote.currencyName || '' },
        id: 'quote-currency',
        isReadOnly: true,
        isDisabled: readOnly,
        showArrow: !readOnly,
        placeholderLabel: (readOnly) ? 'No Currency' : 'Currency',
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: () => this.openCurrencySelect(),
    };
    this.priceListField = {
        label: 'Price List',
        get inputText() { return _this.selectedQuote.priceListName || '' },
        id: 'quote-priceList',
        isReadOnly: true,
        isDisabled: readOnly,
        showArrow: !readOnly,
        placeholderLabel: (readOnly) ? 'No Price List' : 'Price List',
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: () => this.openPriceListSelect(),
    },
    this.accountField = {
        label: this.translate.instant('ACCOUNT'),
        inputText: this.selectedQuote.accountName,
        id: 'quote-account',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
    };
    this.effectiveFromField = {
      isDateField: true,
      label: this.translate.instant('EFFECTIVE_FROM'),
      customPlaceholderLabel: this.translate.instant(readOnly ? 'NO_DATE_SELECTED' : 'EFFECTIVE_FROM'),
      get inputText() { return _this.selectedQuote.effectiveFrom ? format(new Date(parseInt(_this.selectedQuote.effectiveFrom)), 'MMM DD, YYYY') : ''},
      fromViewPage: CurViewPageType.OpportunityQuote,
      id: DateTimeFieldType.StartDateField,
      startDateTimeValue: _this.selectedQuote.effectiveFrom ? format(new Date(parseInt(_this.selectedQuote.effectiveFrom)), 'MMM DD, YYYY') : '',
      endDateTimeValue: _this.selectedQuote.effectiveTo ? format(new Date(parseInt(_this.selectedQuote.effectiveTo)), 'MMM DD, YYYY') : '',
      isReadOnly: true,
      isDisabled: readOnly,
      showArrow: !readOnly,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.effectiveToField = {
      isDateField: true,
      label: this.translate.instant('EFFECTIVE_TO'),
      customPlaceholderLabel: this.translate.instant(readOnly ? 'NO_DATE_SELECTED' : 'EFFECTIVE_TO'),
      get inputText() { return _this.selectedQuote.effectiveTo ? format(new Date(parseInt(_this.selectedQuote.effectiveTo)), 'MMM DD, YYYY') : ''},
      fromViewPage: CurViewPageType.OpportunityQuote,
      id: DateTimeFieldType.EndDateField,
      startDateTimeValue: _this.selectedQuote.effectiveFrom ? format(new Date(parseInt(_this.selectedQuote.effectiveFrom)), 'MMM DD, YYYY') : '',
      endDateTimeValue: _this.selectedQuote.effectiveTo ? format(new Date(parseInt(_this.selectedQuote.effectiveTo)), 'MMM DD, YYYY') : '',
      isReadOnly: true,
      isDisabled: readOnly,
      showArrow: !readOnly,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.totalField = {
        label: this.translate.instant('TOTAL'),
        inputText: this.selectedQuote.totalFormatted,
        id: 'quote-total',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
    };
    this.quoteProducts = this.selectedQuote.quoteProducts.map((p) => ({
        id: p.productID,
        fixedHeight: true,
        primaryTextLeft: p.productName,
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string) => (readOnly ? this.goToQuoteProductDetails(id) : this.openProductSelect(id))
    }));
  }

  private async updateFooterViewButtons() {
    if (!this.selectedQuote) return;
    switch (this.selectedQuote.statecodeFormatted) {
      case 'Draft':
        // if (this.isViewFromTeamOrder) {
        //   this.hardDisableFooterButtonsCheck = true;
        //   this.onClosePage();
        //   return;
        // }
        // this.footerService.disableButton(['scraporderactivity', 'sendorderactivityforreview']);
        // this.isSendForApprovalEnabled = false;
        if (this.selectedQuote.createdBy == this.authService.user.systemUserID && !this.activityService.teamViewActive) {
          // this.footerService.enableButtons(['scraporderactivity']);
          // this.isScrapEnabled = true;
          // if (this.selectedQuote.accountId && this.selectedQuote.countryId && !this.device.isOffline && this.selectedQuote.products && this.selectedQuote.products.length > 0 && this.selectedQuote.total > 0) {
          //   this.footerService.enableButtons(['sendorderactivityforreview']);
          //   this.isSendForApprovalEnabled = true;
          // }
          this.isReadOnlyMode = !this.device.isOffline;
          // this.isAddProductEnabled = (this.selectedQuote.countryId && this.selectedQuote.accountId) ? true : false;
        }
        break;
      case 'For Review':
        // if (!this.isViewFromTeamOrder) {
        //   this.isReadOnlyMode = true;
        //   this.isAddProductEnabled = false;
        //   this.isCancelReviewEnabled = false;
        //   if (!this.activityService.teamViewActive && !this.device.isOffline) {
        //     this.isCancelReviewEnabled = true;
        //     this.footerService.enableButtons(['cancelorderactivityreview']);
        //   }
        // } else {
        //   this.footerService.disableButton(['notapproveorderactivity', 'approveorderactivity']);
        //   this.isMarkOrderNotApprovedEnabled = false;
        //   this.isMarkOrderApprovedEnabled = false;
        //   if (this.authService.user.childUsers.some(user => user.userId == this.selectedQuote.ownerId && !this.device.isOffline && !this.activityService.teamViewActive)) {
        //     this.footerService.enableButtons(['notapproveorderactivity', 'approveorderactivity']);
        //     this.isMarkOrderNotApprovedEnabled = true;
        //     this.isMarkOrderApprovedEnabled = true;
        //   }
        // }
        break;
      case 'Approved':
        // if (!this.isViewFromTeamOrder) {
        //   this.isAddProductEnabled = false;
        //   this.footerService.disableButton(['fulfillorderactivity', 'reopenorderactivity']);
        //   this.isFulfillEnabled = false;
        //   this.isReopenEnabled = false;
        //   if (this.selectedQuote.ownerId == this.authService.user.systemUserID && !this.activityService.teamViewActive && !this.device.isOffline) {
        //     this.footerService.enableButtons(['fulfillorderactivity', 'reopenorderactivity']);
        //     this.isFulfillEnabled = true;
        //     this.isReopenEnabled = true;
        //     this.isReadOnlyMode = true;
        //   }
        // }
        break;
      case 'Fulfilled':
        // this.isReadOnlyMode = true;
        // this.isAddProductEnabled = false;
        break;
      case 'Canceled':
        // this.isReadOnlyMode = true;
        // this.isAddProductEnabled = false;
        break;
      default:
        break;
    }
  }

  goToQuoteProductDetails(id: string) {
    this.trackingService.tracking('SelectProduct', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let selectedProduct = this.selectedQuote.quoteProducts.find((p) => p.productID == id)
    let productViewData: { header: IndSectionHeaderViewDataModel, fields: IndFormFieldViewDataModel[] }[] = [
      {
        header: {
          id: 'product-details-header',
          title: this.translate.instant('OM_PRICING'),
          controls: []
        },
        fields: [
          {
            id: 'unit-price-field',
            label: this.translate.instant('OM_UNIT_PRICE'),
            inputText: selectedProduct.unitPriceFormatted,
            isReadOnly: true
          },
          {
            id: 'quantity-field',
            label: this.translate.instant('QUANTITY'),
            inputText: selectedProduct.quantity ? selectedProduct.quantity.toString() : '',
            isReadOnly: true
          },
          {
            id: 'total-field',
            label: this.translate.instant('TOTAL'),
            inputText: selectedProduct.totalFormatted,
            isReadOnly: true
          }
        ]
      }
    ]
    this.navService.pushChildNavPageWithPageTracking(IndRightpaneReadonlyComponent, PageName.IndRightpaneReadonlyComponent,
      PageName.OpportunityManagementPage, { displayData: productViewData, viewHeader: selectedProduct.productName })
  }

  onClosePage() {
    this.trackingService.tracking('Selectback', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    this.navService.popChildNavPageWithPageTracking();
  }

  public async openCountrySelect() {
    await this.openSelectList({
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: this.translate.instant('COUNTRY'),
      dividerTitle: this.translate.instant('OM_ALL_COUNTRIES'),
      confirmationCheckRequired: false,
      data: this.sharedDataService.allCountriesList.map(country => {
        return {
          id: country.countryId,
          title: country.countryName,
          isSelected: country.countryId == this.selectedQuote.countryId,
        }
      })
    }, ['countryId', 'countryName'])
  }

  public async openCurrencySelect() {
    let currencies = await this.quoteService.getCurrencyList();
    let currencyListData = currencies.map(currency => {
      return {
        id: currency.transactioncurrencyid,
        title: currency.currencyname,
        isSelected: currency.transactioncurrencyid == this.selectedQuote.currencyId,
      }
    });

    await this.openSelectList({
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: 'Currencies',
      dividerTitle: 'All Currencies',
      confirmationCheckRequired: false,
      data: currencyListData
    }, ['currencyId', 'currencyName'])
  }

  public async openPriceListSelect() {
    let priceListsData = this.oms.priceLists.map(priceList => {
      return {
        id: priceList.ID,
        title: priceList.name,
        isSelected: priceList.ID == this.selectedQuote.priceListId,
      }
    });

    await this.openSelectList({
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: 'Price Lists',
      dividerTitle: 'All Price Lists',
      confirmationCheckRequired: false,
      data: priceListsData
    }, ['priceListId', 'priceListName'])
  }

  public async openSelectList(selectListDetail: SelectListDetail, cols: ((keyof Quote))[]) {
    if (this.isReadOnlyMode) return;
    let idKey: string = cols[0];
    if (!selectListDetail || !selectListDetail.data || selectListDetail.data.length === 0) return;
    let data = await this.alertService.showSelectListModal(selectListDetail);
    if (!data || !data.isDone) return;
    if (!((data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.selectedQuote[idKey])
      || ((!data.selectedItems || data.selectedItems.length == 0) && this.selectedQuote[idKey]))) return;

    try {
      this.uiService.displayLoader();
      const id = ((data.selectedItems || [])[0] || {}).id;
      this.selectedQuote = await this.quoteService.updateQuote(this.selectedQuote.quoteID, { [idKey]: id })
      // if (id) {
      //   this.selectedQuote[idKey] = id;
      //   this.selectedQuote[titleKey] = title;
      // } else {
      //   this.selectedQuote[idKey] = '';
      //   this.selectedQuote[titleKey] = '';
      // }
    }
    catch (ex) {
      this.notifications.notify(ex.message, 'Quote-Details', 'top', ToastStyle.DANGER);
    }
    finally {
      //Successfull Action
      this.uiService.dismissLoader();
    }
  }

  async openProductSelect(productId?: string) {
    try {
      await this.alertService.showModal(OrderAddQuantityComponent, {
        quote: this.selectedQuote,
        matchedPriceList: await this.oms.findMatchingPriceListForOrder({ countryId: this.selectedQuote.countryId, accountId: this.selectedQuote.accountID, priceListId: this.selectedQuote.priceListId}),
        selectedProduct: { productId },
        onUpdate: async (selectedItems: OrderProductClass[]) => {
          this.uiService.displayLoader();
          let quote = await this.quoteService.updateQuote(this.selectedQuote.quoteID, {
            currencyId: this.selectedQuote.currencyId,
            products: selectedItems
          }).catch(() => false);
          this.uiService.dismissLoader();
          if (quote instanceof Quote) {
            this.selectedQuote = quote;
          }
        }
      });
      this.refreshView();
    }
    catch (ex) {
      this.notifications.notify(ex, 'Quote-Details', 'top', ToastStyle.DANGER);
    }
  }

  public getGroupedActivitiesByMonth(activities): Array<Object> {
    const events: any[] = [];
    const groupedElements: any = {};
    let groupByKey = 'createdon';
    if (activities) {
      let value: any = activities;
      if (value.length > 0) {
        value.sort((a, b) => (b.createdon.getTime()) - (a.createdon.getTime()));
        value.forEach((obj: any) => {
          let item = format(obj[groupByKey], 'MMMM YYYY');
          if (!(item in groupedElements)) {
            groupedElements[item] = [];
          }
          groupedElements[item].push(obj);
        });

        for (let prop in groupedElements) {
          if (groupedElements.hasOwnProperty(prop)) {
            events.push({
              key: prop,
              list: groupedElements[prop]
            });
          }
        }
      }
    }
    return events;
  }
  parseDate(activityDate) {
    return getDay(activityDate.toString());
  }
  public formatHeader(value): string {
    let edge = window.navigator.userAgent.indexOf('Edge/');
    let formatedDate: any;
    if (edge > 0) {
      let tempDate = value.split("-");
      let currentMonthIndex: number;
      let filteredMonth = this.months.filter((month: string, index: number) => {
        let compare = month.substring(0, 3);
        currentMonthIndex = index;
        return compare === tempDate[1];
      });
      let realDate = new Date(Date.parse(tempDate[0] + " " + tempDate[1] + " " + tempDate[2]));
      let dayOfTheWeek = this.weekDay[realDate.getDay() - 1];
      tempDate = dayOfTheWeek + " " + filteredMonth[filteredMonth.length - 1] + " " + tempDate[0];
      formatedDate = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10) >= 16 ? tempDate : value;
    } else {
      formatedDate = format(value, 'MMMM YYYY');
    }
    return formatedDate;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }
  
  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;  
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.OpportunityQuote,
          startDateTimeValue: this.selectedQuote.effectiveFrom ? format(new Date(parseInt(this.selectedQuote.effectiveFrom))) : '',
          endDateTimeValue: this.selectedQuote.effectiveTo ? format(new Date(parseInt(this.selectedQuote.effectiveTo))) : ''
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then( async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        const effectiveFromDateData = data.data.startTime;
        try {
          const effectiveFrom = new Date(effectiveFromDateData);
          this.uiService.displayLoader();
          this.selectedQuote = await this.quoteService.updateQuote(this.selectedQuote.quoteID, { effectiveFrom });
          if (!this.selectedQuote.effectiveTo) {
            const effectiveTo = new Date(effectiveFromDateData);
            this.selectedQuote = await this.quoteService.updateQuote(this.selectedQuote.quoteID, { effectiveTo });
          }
        }
        catch (ex) {
          this.notifications.notify(ex.message, 'Quote-Details', 'top', ToastStyle.DANGER);
        }
        finally {
          //Successfull Action
          this.uiService.dismissLoader();
        }
      }
    });
    popover.present();
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;  
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.OpportunityQuote,
          startDateTimeValue: this.selectedQuote.effectiveFrom ? format(new Date(parseInt(this.selectedQuote.effectiveFrom))) : '',
          endDateTimeValue: this.selectedQuote.effectiveTo ? format(new Date(parseInt(this.selectedQuote.effectiveTo))) : ''
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then( async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        const effectiveToDateData = data.data.endTime;
        try {
          const effectiveTo = new Date(effectiveToDateData);
          this.uiService.displayLoader();
          this.selectedQuote = await this.quoteService.updateQuote(this.selectedQuote.quoteID, { effectiveTo });
        }
        catch (ex) {
          this.notifications.notify(ex.message, 'Quote-Details', 'top', ToastStyle.DANGER);
        }
        finally {
          //Successfull Action
          this.uiService.dismissLoader();
        }
      }
    });
    popover.present();
  }

}
