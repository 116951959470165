import { ContactMeetingState } from "../contact/contact.class";

export class AccompainedUser{

  id: string;
  name: string;
  emailaddress?: string;
  businessunitid?: string;
  businessunitname?: string;
  checked?:boolean;
  activityId?:string;
  remoteMeetingJoinStatus?: ContactMeetingState;
  remoteMeetingJoinedDate?;
  remoteMeetingLeftDate?;
  positionId?:string;
  userType?:string[];

  constructor(e: IrawAccompainedUser, isFromAppointmentActivity = false){
    this.id = e.userid ? e.userid : e.indskr_user;
    this.name = e.userfullname;
    this.businessunitid = e.businessunitid;
    this.businessunitname = e.businessunitname;
    this.emailaddress = e.emailaddress;
    this.positionId = e.positionId;

    if(e.userType){
      this.userType = e.userType.split(',');
    }
    
    if (isFromAppointmentActivity) {
      this.remoteMeetingJoinStatus = e.indskr_joinstatus;
    }
  }
 
}

export interface IrawAccompainedUser {
  userid: string;
  indskr_user?: string;
  userfullname: string;
  businessunitid?: string;
  businessunitname?: string;
  emailaddress?: string;
  activityId?: string;
  indskr_joinstatus?: number;
  positionId?:string;
  userType?:string;
}
