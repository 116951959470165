
import {map} from 'rxjs/operators';
import { Resource } from './../../../classes/resource/resource.class';
import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ResourceService, ResourceView } from '../../../services/resource/resource.service';
import { Subscription, Observable } from 'rxjs';

import { Events } from '@omni/events';
import { EmailActivity } from '../../../classes/activity/email.activity.class';
import { EmailService } from '../../../services/email-templates/email.service';
import { selectIsDownloading, selectResourceId, selectDownloadQueue } from '../../../store/io-file-service/states/resource.selectors';
import { Store } from '@ngrx/store';
import { ResourceState } from '../../../store/application.state';
import { IoFileService } from '../../../services/io-file-service/io-file-service';
import { DeviceService } from '../../../services/device/device.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { TrackingEventNames } from '../../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import * as ResourceAction from '../../../store/io-file-service/actions/resource.actions';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { SafeUrl } from '@angular/platform-browser';
import _ from 'lodash';
import { SafePipe } from '../../../pipes/safe.pipe';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'resource-list-item',
  templateUrl: 'resource-list-item.html',
  styleUrls:['resource-list-item.scss'],
  providers: [SafePipe]
})
export class ResourceListItemComponent {
  @Input() public resource: Resource;
  @Input() public sortedBy: string;
  @Input() viewMode: ResourceView;
  @Input() isEmailResource: any;
  @Input() isSelect: boolean;

  @Input() emailActivity: EmailActivity;

  @Input() public allowPushResource: boolean;
  public isSelected: boolean = false;
  @Output() addToEmail = new EventEmitter();
  @Output() toggleResourceSelection = new EventEmitter();
  @Output() public onPushResource: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() togglePresSelection = new EventEmitter();

  public isEmailResourceClickable: boolean = false;
  public resourceView = ResourceView;
  public progressPer: number = 0;
  public isDownloading$: Observable<boolean>;
  public isDownloadPending$: Observable<boolean>;
  public isActiveDownload$: Observable<boolean>;
  private dlProgressSubscription: Subscription;
  public isMobile: boolean = false;
  public resourceInActive: boolean = false;
  public thumbnailUrl: SafeUrl;

  selectedResourceSubscription: Subscription;
  public downloadable: boolean = false;
  public checkBoxOption: boolean = false;
  constructor(
    public resourceService: ResourceService,
    public emailService: EmailService,
    public store: Store<ResourceState>,
    private ioFileDownload: IoFileService,
    public deviceService: DeviceService,
    private cdRef: ChangeDetectorRef,
    public trackingService: TrackService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public navService: NavigationService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private safePipe: SafePipe,
    private authService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.selectedResourceSubscription = this.resourceService.highlightedResource.subscribe(resource => {
      this.isSelected = resource == this.resource;
      if (this.emailService.selectedActivity && this.resource) {
        this.isEmailResourceClickable = this.checkResourceAttachable();
      }
    });
    this.isDownloading$ = this.store.select(selectIsDownloading);
    this.dlProgressSubscription = this.ioFileDownload.dlProgress$.subscribe(per => {
      this.progressPer = per;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
    this.isMobile = this.deviceService.isNativeApp;
    this.downloadable = this.resourceService.viewMode === ResourceView.MENU && this.isMobile && this.navService.getCurrentPageName() === PageName.ResourcePageComponent;
    this.checkBoxOption = this.resourceService.viewMode === ResourceView.MENU && this.navService.getCurrentPageName() === PageName.ResourcePageComponent;
    this.resourceInActive = this.downloadable && this.deviceService.isOffline && !this.resource.downloaded;
    this.isActiveDownload$ = this.store.select(selectResourceId).pipe(
      map(rs => this.resource && (rs === this.resource.ioDocumentId || rs === this.resource.ioResourceId)));
    let url = this.resource.thumbnailURL;
    if (!_.isEmpty(url) && this.resource.downloaded && this.deviceService.isOffline) {
      const thumbLocation = "thumbnail/" + this.resource.thumbnailURL.substr(this.resource.thumbnailURL.lastIndexOf('/') + 1);
      url = this.ioFileDownload.getLocalURLForResource(this.resource.ioResourceId ? this.resource.ioResourceId : this.resource.ioDocumentId, thumbLocation);
    }
    this.thumbnailUrl = this.safePipe.transform(url, 'url');
  }

  ngOnDestroy() {
    this.selectedResourceSubscription.unsubscribe();
    this.dlProgressSubscription.unsubscribe();
  }

  pushResource(event: Event) {
    this.onPushResource.emit(event);
    event.stopPropagation();
  }

  public addResourceToEmail() {
    event.stopPropagation();
    this.addToEmail.emit();
  }

  public toggleIsResourceSelected(ev: any) {
    this.toggleResourceSelection.emit();
    if (this.resourceService.isSelectMode && !(this.deviceService.isMobileDevice && this.deviceService.deviceOrientation === 'PORTRAIT')) {
      this.showDetails();
    }
  }

  /*
    Setting logic to check if the product id from template matches the product id for the brand
  */
  checkResourceAttachable(): boolean {
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) {
      return this.checkResourceForProductBundle();
    }
    if (this.resource.raw && this.resource.raw.brands && Array.isArray(this.resource.raw.brands)) {
      let productId = this.emailService.selectedActivity.templateRefProductId;
      //Some weird error, just to double check the logic
      if (!productId) {
        let emailTemplateIndex = this.emailService.emailTemplates.findIndex(e => {
          return e.indskr_emailtemplateid === this.emailService.selectedActivity.template_id
        });
        if (emailTemplateIndex >= 0)
          productId = this.emailService.emailTemplates[emailTemplateIndex].product_id;
      }

      let foundProduct = this.resource.raw.brands.find(ele => {
        return ele.productId === productId;
      });
      if (foundProduct) {
        return true;
      }
      else
        return false;
    }
    else
      return false;
  }

  public download() {
    event.stopPropagation();
    this.trackingService.tracking('ResourceDownlaod', TrackingEventNames.RESOURCES);
    const resourceId: string = this.resource.ioResourceId ? this.resource.ioResourceId : this.resource.ioDocumentId;
    if ((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !this.resource.downloaded) {
      this.notificationService.notify(this.translate.instant('CANNOT_DOWNLOAD_RESOURCE_WHILE_OFFLINE'), "Resource List", "top", ToastStyle.DANGER, 2000, true);
      return;
    }

    if (!this.resource.downloaded) {
      this.notificationService.notify((this.translate.instant('DOWNLOADING') + ' ' + this.resource.title), 'Resource Download');
      if (this.resource.assetURL) {
        this.store.dispatch(new ResourceAction.downloadResourceEnqueue({ resourceId: resourceId }));
      } else {
        this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + this.resource.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
      }
    }
  }

  public showDetails() {
    // Don't highlight the resource when user visits shared resource details from meeting details screen
    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent) return;

    if (this.deviceService.isNativeApp && !this.resource.downloaded && this.deviceService.isOffline) {
      this.notificationService.notify(this.translate.instant('PLEASE_DOWNLOAD_THE_RESOURCE_TO_VIEW_WHILE_OFFLINE'), "presentation-list", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    this.trackingService.tracking('ResourcePreview', TrackingEventNames.RESOURCES)
    this.resourceService.highlightResource(this.resource);
  }

  getFormattedAndLocalisedDate(date: Date): string {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  getAssetType(assetType: string): string {
    if(assetType == 'video') return this.translate.instant('VIDEO');
    else if(assetType == 'pdf') return this.resource.assetType;
    else return this.translate.instant('R_'+ this.resource.assetType.toUpperCase());
  }

  private checkResourceForProductBundle(): boolean {
    let isResourceInHierarchy: boolean = false;
    if (this.resource.raw && this.resource.raw.brands && Array.isArray(this.resource.raw.brands) && this.emailService.selectedActivity?.product_id) {
      isResourceInHierarchy =  this.resource.raw.brands.some(ele => {
        return (ele.productId === this.emailService.selectedActivity.product_id || ele.productId === this.emailService.selectedActivity.level1Product
          || ele.productId === this.emailService.selectedActivity.level2Product) 
      });
    }
     return isResourceInHierarchy;
  }

}
