import { ProcedureTracker } from './../../../classes/activity/procedure-tracker.activity.class';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { ProcedureTrackerActivityDataService } from './../../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { MainToolTemplateComponent } from './../../shared/main-tool-template/main-tool-template';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from './../../../models/mainToolTemplateDetail.model';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { ComponentViewMode } from './../../../services/ui/ui.service';
import { ContactPageComponent } from './../../contact/contact-page/contact-page';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Contact } from './../../../classes/contact/contact.class';
import { IndFormFieldViewDataModel, FormFieldType } from './../../../models/indFormFieldDataModel';
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { IonNav, LoadingController, ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from "@omni/services/activity/activity.service";
import { AlertService } from "@omni/services/alert/alert.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DeviceService } from '@omni/services/device/device.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { UIService } from "@omni/services/ui/ui.service";
import { takeUntil } from 'rxjs/operators';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { Subject, Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'procedure-tracker',
  templateUrl: 'procedure-tracker.html',
  styleUrls: ['procedure-tracker.scss']
})
export class ProcedureTrackerComponent {
  @Input() isEditMode: boolean;
  @Input() activity: ProcedureTrackerActivity;
  @Input() procedureTracker: ProcedureTracker = new ProcedureTracker(null);
  @Input() from: any;
  @Input() callbackEvent: Function;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isInMeetingProcedureTrackerFlow: boolean = false;

  public backgroundUploadInProgress: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  public isReadOnlyMode = false;
  public globalCustomerText: string;
  private _isClickedSurgery: boolean = false;
  private _isClickedQty: boolean = false;
  public procedureTrackerPageTitle: IndPageTitleViewDataModel;
  public customerFormField: IndFormFieldViewDataModel;
  public qtyFormField: IndFormFieldViewDataModel;
  public surgeryFormField: IndFormFieldViewDataModel;
  public casesSupportedFormField: IndFormFieldViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;
  private isConfirmEnabled: boolean = false;
  private isCancelEnabled: boolean = false;
  public  tracker :ProcedureTracker;
  private isFormDirty: boolean = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly alertService: AlertService,
    public activityService: ActivityService,
    public translate: TranslateService,
    private _CD: ChangeDetectorRef,
    public navService: NavigationService,
    private popoverCtrl: PopoverController,
    public uiService: UIService,
    public device: DeviceService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
    private loadingController: LoadingController,
    public modalCtrl: ModalController,
    public navCtrl: IonNav,
    private utilityService: GlobalUtilityService,
    private contactService: ContactOfflineService,

  ) {
  }

  ngOnInit() {
    this.tracker = _.cloneDeep(this.procedureTracker);
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.isInMeetingProcedureTrackerFlow = true;
      }
    }
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }
    this.initAllDataModels();
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
    });

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.getPageTitle();
    });
  }

  initAllDataModels() {
    this.isReadOnlyMode = this.device.isOffline || this.activity.status === 548910001;
    this.isFormDirtyButtonEnabled();
    this.getPageTitle();
    this.getDetailsHeader();
    this.getCustomerFormField();
    this.getQtyFormField();
    this.getCasesSupportedFormField();
    this.getSurgeryFormField();
    this._CD.detectChanges();

  }

  public getDetailsHeader() {
    let buttons = [];
    if (this.isInMeetingProcedureTrackerFlow) {
      buttons.push(
        {
          id:'cancel',
          text: this.isEditMode ? this.translate.instant('SCRAP'):this.translate.instant('CANCEL'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isCancelEnabled,
        },
        {
          id:'confirm',
          text: this.isEditMode ? this.translate.instant('UPDATE'): this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress ? true : this.isEditMode ? !this.isFormDirty: !this.isConfirmEnabled,
        });
    }
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: buttons,
    };
  }

  public getCustomerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText:this.tracker.currentCustomerString,
      customPlaceholderLabel: this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText }),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.customerFormField = viewData;
  }

  public getQtyFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NO_OF_PROCEDURES'),
      inputText:this.tracker.indskr_quantity ?this.tracker.indskr_quantity + '' : '',
      inputValue:this.tracker.indskr_quantity ?this.tracker.indskr_quantity + '' : '',
      placeholderLabel: this.translate.instant('NO_OF_PROCEDURES'),
      id: 'qty-field',
      inputType: 'number',
      isReadOnly: this.isReadOnlyMode,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedQty && _.isEmpty(this.tracker.indskr_quantity) ? true : false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.qtyFormField = viewData;
  }

  public getCasesSupportedFormField() {
    let viewData = {
      label: this.translate.instant('PROCEDURES_SUPPORTED'),
      inputText: _.isEmpty(this.tracker.indskr_casessupported) ? '' :this.tracker.indskr_casessupported,
      inputValue: _.isEmpty(this.tracker.indskr_casessupported) ? '' :this.tracker.indskr_casessupported,
      id: 'cases-supported-field',
      inputType: 'number',
      isReadOnly: this.isReadOnlyMode,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyMode,
      placeholderLabel: this.translate.instant('PROCEDURES_SUPPORTED'),
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.casesSupportedFormField = viewData;
  }

  public getSurgeryFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isSurgeryDataAvailable = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.length > 0
    viewData = {
      label: this.translate.instant('PROCEDURE'),
      inputText:this.tracker.procedureName,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PROCEDURE') : this.translate.instant('SELECT_PROCEDURE'),
      id: 'surgery-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || !isSurgeryDataAvailable,
      showArrow: (!this.isReadOnlyMode && isSurgeryDataAvailable),
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedSurgery && !this.tracker.procedureName ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.surgeryFormField = viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress || this.isReadOnlyMode) return;
      switch (id) {
        case 'customer-field':
          this.openCustomerSelect();
          break;
        case 'surgery-field':
          this._isClickedSurgery = true;
          this.openSurgerySelect();
          break;
        case 'cases-supported-field':
          if (eventName && eventName == 'input_value_confirm') {
            let val = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : '';
            if (val && _.toNumber(val) <= 0) val = '';
           this.tracker.indskr_casessupported = val;
            this.initAllDataModels();
          }
          break;
        case 'qty-field':
          this._isClickedQty = true;
          if (eventName && eventName == 'input_value_confirm') {
            let val = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : '';
            if (val && _.toNumber(val) <= 0) val = '';
           this.tracker.indskr_quantity = val;
            this.initAllDataModels();
          }
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private isFormDirtyButtonEnabled() {
    if (this.isEditMode) {
      if (this.tracker.currentCustomerString != this.procedureTracker.currentCustomerString || (this.tracker.procedureName && this.tracker.procedureName != this.procedureTracker.procedureName )||
        this.tracker.indskr_casessupported != this.procedureTracker.indskr_casessupported || (this.tracker.indskr_quantity &&  this.tracker.indskr_quantity != this.procedureTracker.indskr_quantity)) {
        this.isFormDirty = true;
      }
    }
  }

  private openSurgerySelect(): void {
    if (!this.isReadOnlyMode) {
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PROCEDURE'),
        dividerTitle: this.translate.instant('ALL_PROCEDURE'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('PROCEDURE'),
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'surgery'),
        searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'surgery'),
        data: this.surgeryOrderActivityDataService && this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.map(pro => {
          let isSelected = false;
          if (this.tracker.procedureId == pro.surgeryId) {
            isSelected = true;
          }
          return {
            id: pro.surgeryId,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: pro.surgeryName,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.activity.ownerId
          };
        }),
      };
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(()=>{
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true });
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
      }
    }
  }

  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
        switch (fieldName) {
          case 'surgery':
            let surgeryProd = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.find(p => p.surgeryId == data.selectedItems[0].id)
            if (surgeryProd &&this.tracker.procedureId !== surgeryProd.surgeryId) {
             this.tracker.procedureId = surgeryProd.surgeryId;
             this.tracker.procedureName = surgeryProd.surgeryName;
            }
            break;
        }

      }
      else if (data.selectedItems.length == 0) {
        switch (fieldName) {
          case 'surgery':
           this.tracker.procedureId = undefined;
           this.tracker.procedureName = '';
            break;
          default:
            break;
        }
      }
    }
    this.initAllDataModels();
  }

  private _handleProductsComponentSearch(text: string, formField: string): string[] {
    let ids: Array<string> = [];
    switch (formField) {
      case 'surgery':
        if (text.length >= 1) {
          ids = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies && this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.filter(pro => {
            return pro.surgeryName.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(pro => pro.surgeryId);
        } else {
          ids = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies && this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.map(pro => pro.surgeryId);
        }
        break;
    }
    return ids;
  }

  private openCustomerSelect(): void {
    if (!this.isReadOnlyMode) {
      const customerIds = [];
      const accountIds = [];
      if (!_.isEmpty(this.tracker.trackerContacts)) {
       this.tracker.trackerContacts.forEach(con => {
          customerIds.push({
            id: con.ID,
            title: con.title,
            firstName: con.firstName,
            middleName: con.middleName,
            lastName: con.lastName,
            fullname: con.fullname,
            isActive: con.isActive,
            statusCode: con.statusCode
          });
        });
      }
      if (this.activity.accountId) accountIds.push({ id: this.activity.accountId });
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'contactDetails';
      this.uiService.showNewActivity = false;
      this.contactService.accessedContactListFrom = PageName.ProcedureTrackerComponent;
      if (this.isReadOnlyMode) {
        this.contactService.contactPageMode = ComponentViewMode.READONLY;
      } else {
        this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(()=>{
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(ContactPageComponent, {
          type: 'InMeetingProcedurePushedContactSelectionView', selectedContactIds: customerIds,
          selectedAccountIds: accountIds, callbackEvent: (data: any) => this._handleContactComponentCallback(data)
        },
          {
            progressAnimation: false
          });
        return;
      } else {
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
          {
            type: 'PushedContactSelectionViewForProcedureTracker', selectedContactIds: customerIds,
            selectedAccountIds: accountIds,
            callbackEvent: (data: any) => this._handleContactComponentCallback(data)
          }, PageName.DynamicFormComponent, { animate: false }, null);
      }
    }
  }

  private async _handleContactComponentCallback(data) {
    if (!this.isReadOnlyMode && data && (data.selectedItems || data.clearValue)) {
      if (data.clearValue) {
        data.selectedItems = [];
      }
     this.tracker.trackerContacts = [];
      data.selectedItems.forEach(item => {
       this.tracker.trackerContacts.push({
          ID: item.id,
          title: item.title,
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
          fullname: item.fullname,
          isActive: item.isActive,
          statusCode: item.statusCode,
        });
      });
      this.initAllDataModels();
    }
  }

  public getPageTitle() {
    this.isConfirmEnabled =this.tracker.procedureId &&this.tracker.indskr_quantity >= 0 && this.activity.status == 1;
    this.isCancelEnabled =this.activity.status == 1;
    const controls = [
      {
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name:  this.isEditMode ? this.translate.instant('SCRAP'):this.translate.instant('CANCEL'),
        isDisabled: this.backgroundUploadInProgress || this.device.isOffline ? true : !this.isCancelEnabled,
        align: "right"
      },
      {
        id: 'confirm',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.isEditMode ? this.translate.instant('UPDATE'): this.translate.instant('SAVE'),
        isDisabled: this.backgroundUploadInProgress ? true :this.isEditMode ? !this.isFormDirty: !this.isConfirmEnabled,
        align: "right"
      },
        {
        id: "close",
        icon: this.isEditMode? "chevron-back-outline" :null,
        isDisabled: false,
        isHidden:this.isEditMode ? false:true,
        align: "left"
      }
    ]
   this.procedureTrackerPageTitle = {
      id: this.isEditMode ? 'procedure-tracker': 'procedure-tracker-title',
      title: this.isEditMode ? 'bulk-procedure-log': 'New Bulk Procedure Log',
      controls: controls
    };
  }

  public handleButtonClick(id: string) {
    let isDeleted = false;
    switch (id) {
      case 'confirm':
        if (this.isConfirmEnabled) {
          this.callbackEvent(this.tracker, isDeleted);
        }
        break;
      case 'cancel':
        if (this.isEditMode) {
          isDeleted = true;
          this.callbackEvent(this.tracker, isDeleted);
        }
      default:
        console.log('Unhandled switch case statement');
        break;
    }
    if (this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow) {
      this.navCtrl.pop();
    } else {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

}
