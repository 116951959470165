<ion-grid no-padding class="content-page" [ngClass]="(navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row no-padding>
      <ion-col class="left-pane" no-padding >
          <appsettings-list></appsettings-list>
      </ion-col>
      <ion-col class="right-pane" no-padding>
         <ion-nav #appsettingspagerightpane></ion-nav>
      </ion-col>
  </ion-row>
</ion-grid>
