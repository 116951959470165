import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Contact } from '../../../classes/contact/contact.class';
import { NavController,  LoadingController } from '@ionic/angular';
import { Events } from '@omni/events';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { FooterService } from '../../../services/footer/footer.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { ContactDetailsComponent } from '../contact-details/contact-details';
import { ContactDataService } from '../../../data-services/contact/contact.data.service';
import { MeetingDataService } from '../../../data-services/meeting/meeting.data.service';
import { DataSync, DataSyncType } from '../../../classes/data-sync/data-sync.class';
import { AppointmentActivity } from '../../../classes/activity/appointment.activity.class';
import { DiskService } from '../../../services/disk/disk.service';
import { RepServices } from '../../../data-services/rep/rep.services';

/**
 * Generated class for the ContactListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'meeting-attendies-list',
  templateUrl: 'meeting-attendies-list.html',
  styleUrls:['meeting-attendies-list.scss']
})
export class MeetingAttendiesListComponent {

  public contactSegment: string = "name";
  public enableRemote: boolean = false;
  public searching: boolean;
  public compViewMode = ComponentViewMode;
  private currentPage:number = 1;
  public contacts: Contact[] = [];
  private recordCount: number = 100;

  @Input()
  contactListMode:string;

  @Output() closeModal = new EventEmitter<string>();

  private readonly MIN_SEARCH_LENGTH = 1;

  constructor(public navCtrl: NavController,
              public contactService:ContactOfflineService,
              public repService: RepServices,
              private contactDataService:ContactDataService,
              private uiService: UIService,
              public events:Events,
              public device:DeviceService,
              private disk: DiskService,
              private meetingService:MeetingDataService,
              private loadingController:LoadingController,
              public activityService: ActivityService) {

  }

  // ngOnInit(){this.init();}

  // init(){
  //   this.searching = false;
  //   this.contactService.searchContacts = [];
  //   this.contacts = this.contactService.contacts.slice(0,this.recordCount);
  //   if(this.contactListMode == this.compViewMode.ADDNEW && this.activityService.selectedActivity['contacts']){
  //       this.contactService.contacts.forEach(o => {
  //         let item = this.activityService.selectedActivity['contacts'].find((contact)=>{
  //           return (o.ID === contact.ID);
  //         });
  //         o.isSelected = (item) || false;
  //       });
  //   }
  // };
  // addToSelected = (event,contact: Contact) => {
  //   event.stopPropagation();
  //   this.contactService.moveContactToSelected(contact);
  // }

  // removeFromSelected = (contact,contactIndex: number) => {
  //   this.contactService.removeContactFromSelected(contact);
  // }

  onCloseModal(isDone){

    this.uiService.showNewActivity = false;
    this.uiService.activeView === 'Appointment';
  }

  // /**
  //  * set val to the value of the ev target
  //  *
  //  * @param {any} ev
  //  * @returns
  //  * @memberof ContactListComponent
  //  */
  // getItems(ev) {
  //   let val = (ev.target.value) ? ev.target.value : '';

  //  if (val.length <= this.MIN_SEARCH_LENGTH) {
  //     this.searching = false;
  //     return;
  //  }

  //  this.searching = true;
  //  this.contactService.filterContacts(val);
  // }

  // highlightContact(contact){
  //   if(!this.device.isOffline){
  //     let loader = this.loadingController.create({});
  //     loader.present();
  //     this.contactService.contactInformation = contact;
  //     this.contactDataService.getContactById(contact.ID).then(()=>{
  //       loader.dismiss();
  //     });
  //   }else{
  //     this.contactService.contactInformation = contact;
  //   }
  //   this.uiService.showNewActivity = false;
  //   this.uiService.activeView = 'contactDetails';
  //   if(this.device.isMobileDevice){
  //     this.navCtrl.push(ContactDetailsComponent);
  //   }
  // }
  // // doInfinite(infiniteScroll): Promise<any>{
  // //   this.currentPage++;
  // //   return this.contactDataService.getContacts(true,this.currentPage.toString());
  // // }

  // doInfinite(event){
  //   if(this.contacts.length<(this.contactService.contacts.length+30)){
  //     this.contacts.push(...this.contactService.contacts.slice(this.recordCount,(this.recordCount+30)));
  //   }else{
  //     this.contacts.push(...this.contactService.contacts.slice(this.recordCount));
  //   }
  //   this.recordCount = this.recordCount + 30;
  //   event.complete();
  // }
}
