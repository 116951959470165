import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ind-popup-modal-header',
  templateUrl: 'ind-popup-modal-header.html',
  styleUrls: ['ind-popup-modal-header.scss'],
})
export class IndPopupModalHeaderComponent {
  @Input() title: string;
  @Output() onCloseClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  onCloseClicked(ev: any) {
    this.onCloseClick.emit(ev);
  }
}
