export interface ResourceManagerState {
    isDownloading: boolean;
    resourceId: string;
    downloadQueue: string[];
    downloadedQueue: string[];
    errorInfo: string;
}

export const INITIAL_RESOURCE_MANAGER_STATE: ResourceManagerState = {
    isDownloading: false,
    resourceId: null,
    downloadQueue: [],
    downloadedQueue: [],
    errorInfo: null
}