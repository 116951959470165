import { Component, Input } from "@angular/core";
import { PopoverController } from '@ionic/angular';

@Component({
  selector: "hop-qr-code-modal",
  templateUrl: "hop-qr-code-modal.html",
  styleUrls: ['hop-qr-code-modal.scss']
})
export class HopQRCodeModal {
  @Input() qrCodeData: string;
    
  constructor(
    public popoverCtrl: PopoverController,
  ) {
  }

  ngOnInit(): void {
    console.log("QRCode Data: ", this.qrCodeData);
    
  }

  closePopover() {
    this.popoverCtrl.dismiss({ completedFlow: false });
  }

}
