<ion-grid class="ion-no-padding">
    <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding">
            <ion-header>
                <ind-page-title [viewData]='{id:"capture-customer-inquire", title: pageTitle, controls: backButton, isHCPFacing: true}' (onControlClick)='goBack(false)'></ind-page-title>
            </ion-header>
            <ion-content>
                <div class="signature-content">
                    <ind-section-header [viewData]="signatureHeaderModel"></ind-section-header>
                    <div *ngIf="!capturedSignature" class="signature-secondary-message">
                        {{'INQUIRY_PROVIDE_CONSENT' | translate}}
                    </div>
                    <div *ngIf="capturedSignature" class="signature-secondary-message">
                        {{'INQUIRY_AFTER_CONSENT' | translate}}
                    </div>
                    <ion-list class="list-border">
                        <ion-item>
                            <div class="signatureImageContainer" text-center>
                                <div col-1>
                                    <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag" />
                                </div>
                                <div col-10 class="signature">
                                    <div>
                                        <p *ngIf="!capturedSignature" (click)="openSignaturePad()" class="sign-text pt-32">{{ 'CONSENT_TAP_TO_SIGN' | translate}}</p>
                                        <img *ngIf="capturedSignature" [src]="capturedSignature" width="100" height="100">
                                        <!-- <p (click)=submitInquiry() *ngIf="showTapToSubmit" class="sign-text pt-32">{{ 'TAP_HERE_SUBMIT_INQUIRY' |
                                              translate}}</p> -->
                                    </div>
                                </div>
                            </div>
                        </ion-item>
                    </ion-list>
                </div>

            </ion-content>
            <!-- <ion-footer> -->
            <footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('CaseIntake')" [footerView]="'detailView'" [selectedView]="'inquiryConsent'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>
    </ion-row>
</ion-grid>
