<ion-header id="ph-capture-header">
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
</ion-header>

<ion-content #ionContent>

  <div *ngIf="previewAvailable" class="preview-container">
    <img #previewImg [src]="capturedPhoto.base64String" [ngStyle]="imageStyle" (load)="onImageLoad()">
  </div>

</ion-content>

<footer class="footer" *ngIf="!previewAvailable">
  <ion-button class="capture-button" fill="clear" (click)="capturePhoto()">
    <img tappable src="assets/imgs/action_camera.svg" width="60" />
  </ion-button>
  <div class="ion-card" *ngIf="photos.length > 0">
    <img [src]="photos[photos.length - 1].base64String" alt="Latest Captured Photo" />
    <div class="count-badge">{{ photos.length }}</div>
  </div>
</footer>

<footer *ngIf="previewAvailable" class="footer">
  <ion-button class="retake-button" fill="clear" (click)="discardPhoto()">
    <img tappable src="assets/imgs/action_retake.svg" width="60" />
  </ion-button>
  <ion-button class="done-button" fill="clear" (click)="savePhoto()">
    <img tappable src="assets/imgs/action_photo_ok.svg" width="60" />
  </ion-button>
</footer>