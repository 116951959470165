import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DeviceService } from '@omni/services/device/device.service';
import { UIService } from '@omni/services/ui/ui.service';
import { AuthenticationService } from '../../../services/authentication.service';

const DefaultTitleWidth = '100%';

@Component({
  selector: 'ind-page-title',
  templateUrl: 'ind-page-title.html',
  styleUrls:['ind-page-title.scss']
})
export class IndPageTitleComponent {
  buttonsFilter = {
    left: { align: 'left' },
    right: { align: 'right' }
  };
  defaultTitleWidth = DefaultTitleWidth;

  @Input() viewData: any;
  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: { titleWidth: string } = { titleWidth: DefaultTitleWidth };

  @Output() onControlClick = new EventEmitter<string>();
  @Output() onFilterClick = new EventEmitter<string>();
  enableBackButton = false;
  public backButton = {
    id: 'close',
    icon: 'chevron-back-outline',
    isDisabled: false,
    align: 'left'
  };
  rightControls: any;
  leftControls: any;
  isAndroid: boolean = false;
  isHCPFacing = false;
  public backgroundUploadInProgress: boolean = false;
  public syncInProgress: boolean = false;
  constructor(public device: DeviceService, public authService: AuthenticationService, private _cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.isAndroid = this.device.isAndroid();
    if (this.viewData && !this.viewData.controls) this.viewData.controls = [];
    if(this.viewData && this.viewData.isHCPFacing) this.isHCPFacing = true;
    this.enableBackButton = this.isDefaultBackButtonReq();
    this.leftControls = this.viewData?.controls?.filter((btn)=> btn.align === 'left') || [];
    this.rightControls = this.viewData?.controls?.filter((btn)=> btn.align === 'right') || [];
    this.device.isBackgroundUploadInProgressObservable.subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });
    this.device.syncInProgress$.subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this._cd.detectChanges();
    });
  }

  // Fix to show tooltip on Mobile devices and hide when clicked on Back button //
  ngAfterViewInit() {
    var element = document.getElementsByTagName("tooltip"), index;

    for (index = element.length - 1; index >= 0; index--) {
        element[index].parentNode.removeChild(element[index]);
    }
  }
  // Fix to show tooltip on Mobile devices and hide when clicked on Back button //

  ngOnChanges(){
    if(this.viewData && !this.viewData.controls)
      this.viewData.controls = [];
    this.enableBackButton = this.isDefaultBackButtonReq();
    this.leftControls = this.viewData?.controls?.filter((btn)=> btn.align === 'left') || [];
    this.rightControls = this.viewData?.controls?.filter((btn)=> btn.align === 'right') || [];
  }

  private isDefaultBackButtonReq(): boolean {
    if(this.viewData && !this.viewData.controls) {
      this.viewData.controls = [];
    }
    if (this.viewData && this.viewData.id == 'veeva-widget') return true;
    if (this.viewData?.displayDefaultBackButton) return true;
    return (this.viewData && this.viewData.id !== "new-activity-page-title"
    && this.viewData.id !== "capture-allocation-order"
    && this.viewData.id !== "presentation-fullscreen"
    && this.viewData.id !== 'new-insights-plus-header'
    && this.viewData.id !== 'new-change-req-page-title'
    && this.viewData.id !== 'general-insights-page-title'
    && this.viewData.id !== 'customer-insight'
    && this.viewData.id !== 'customer-insight-journey'
    && this.viewData.id !== 'customer-relationship'
    && this.viewData.id !== 'dynamic-form-page-title'
    && this.viewData.id !== 'section-specific'
    && this.viewData.id !== 'new-manage-list-title'
    && this.viewData.id !== 'photo-capture-viewer-page-title'
    && this.viewData.controls.filter(control => control.align === this.backButton.align).length === 0)
      && (window.innerWidth < 620);
  }

  public onButtonClick(button) {
    if (button.id && !button.isDisabled) {
      this.onControlClick.emit(button.id);
    }
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.enableBackButton = this.isDefaultBackButtonReq();
  }

  public filterClicked(ev) {
    if (this.viewData.isFilter) {
      this.onFilterClick.emit();
    }
  }

}
