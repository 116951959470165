<div class="timelineWrapper">

  <div class="accountPlanTimelineFilter" #scrollTop>
    <ion-select #accountPlanTimelineSelect (ionChange)="onTimelineTypeChange(accountPlanTimelineSelect.value)"
      [(ngModel)]="accountPlanTimelineFilter" interface="popover" [disabled]="!isDataAvailable()"
      [interfaceOptions]="{ cssClass: 'contact-timeline-filter'}" class="timelineFilter"
      [selectedText]="getSelectedText(accountPlanTimelineFilter)" [value]="accountPlanTimelineFilter">
      <ion-select-option *ngFor="let option of filterOptions" [value]="option.value">
        {{option.displayText | translate:{ globalCustomerText: utilityService.globalCustomerText } }}
      </ion-select-option>
    </ion-select>

    <ion-button fill="clear" (click)="openActivityPopover($event)"
      [disabled]="deviceOffline || selectedAccountPlan.status == 'Completed'" size="small">
      {{'ADD' | translate }}</ion-button>
  </div>

  <ion-list class="containerForData" *ngIf="!deviceOffline">
    <ion-item-group class="timeline-group" *ngFor="let group of groupedActivitiesToDisplay">
      <ion-item-divider sticky='true' [id]="group.key">
        <div class="dividerBorder"></div>
        <div class="monthGroupName">{{group.key}}</div>
      </ion-item-divider>
      <ion-item *ngFor="let activity of group.list">
        <ion-grid>
          <ion-row>
            <ion-col size="3">
              <div class="ion-float-center" size="3">
                <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                <h4 class="ion-text-center time-label">{{formattedDate(activity.scheduledStart)}}</h4>
              </div>
            </ion-col>
            <ion-col size="8">
              <div class="col-9 ion-float-left ion-margin-right" size="8">
                <h3>
                  <div *ngIf="activity.type == 'Appointment'">{{ 'MEETING' | translate}} - {{activity.subject}}</div>
                  <div *ngIf="activity.type == 'PhoneCall'">{{ 'NEW_ACTIVITY_PHONECALL' | translate}} -
                    {{activity.subject}}</div>
                  <div *ngIf="activity.type == 'followuptask'">{{ 'FOLLOW_UP' | translate}} - {{activity.subject}}</div>
                </h3>

                <h4 *ngIf="activity.type == 'Appointment'">
                  <div>{{ getMeetingPrimaryLabel(activity) }}</div>
                  <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                  <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                </h4>

                <h4 *ngIf="activity.type == 'followuptask'">
                  <div>{{ 'DUE_DATE' | translate }} {{ activity.scheduledStart | date }}</div>
                  <div>{{ getPhoneFollowUpPrimaryLabel(activity) }}</div>
                </h4>

                <h4 *ngIf="activity.type == 'PhoneCall'">
                  <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                  <div>{{activity.selectedMobileNumber}}</div>
                </h4>

              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-item-group>
    <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
</div>
<div class="no-data-message" *ngIf="!deviceOffline && !isDataAvailable()">
  {{ 'NO_TIMELINE' | translate}}</div>
<div class="no-data-message" *ngIf="device.isOffline"> {{ 'AVAILABLE_IN_ONLINE_ONLY' | translate}}</div>
<img *ngIf="deviceOffline || !isDataAvailable()" class="os_background-list"
  [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios}" />