import { Component, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";
import { ReorderItemsViewDataModel } from "@omni/models/reorderItemsViewDataModel";
import { ActivityService } from "@omni/services/activity/activity.service";
import _, {isEmpty} from 'lodash';


@Component({
    selector: 'reorder-items-list',
    templateUrl:'reorder-items-list-component.html',
    styleUrls:['reorder-items-list-component.scss']
})

export class ReorderItemsListComponent {

    @Input() viewData:ReorderItemsViewDataModel;
    @Input() selectedData:any; //List of item's and child item's id which are selected

    @Output() saveItems:EventEmitter<any>=new EventEmitter();

    constructor(
        public _cd: ChangeDetectorRef,
        public translate: TranslateService,
        //private readonly activityService: ActivityService,
    ){
    }

    onSaveItem(){
      if(!this.viewData.isDisabled){
        this.saveItems.emit(this.viewData.items);
      }
      this._cd.detectChanges();
    }

    updateItem(ev,item:any){
      if(!this.viewData.isDisabled){
        if(!item.isAutoSelected){
            item.isSelected = !item.isSelected;
        }
        let currentData = [];
         this.viewData.items.forEach(a=> {
          if(a.isSelected || a.isAutoSelected){
            currentData.push(a.id);
            if(a.childItems){
              a.childItems.forEach(b=>{
                if(b.isSelected || b.isAutoSelected){
                  currentData.push(a.id+b.id);
                }
              })
            }
          }
        })
        try {
          this.viewData.sectionHeaderViewData.controls[0].isDisabled = _.xor(this.selectedData,currentData).length == 0;
        } catch (error) {
          console.log(error);
        }
      }
      this._cd.detectChanges();
    }

  public updatedChildItem = (ev: MouseEvent, item: any, childItem: any) => {
    //dont use the ev.target creates additonal buttons very wierd
    if (childItem.isAutoSelected || this.viewData.isDisabled) return;
    if (!childItem.isSelected) {
      childItem.isSelected = true;
      childItem.isAutoSelected = false;
      if (!(item.isAutoSelected || item.isSelected)) {
        item.isSelected = !item.isSelected;
      }
    } else {
      childItem.isSelected = false;
      if (item.childItems && !item.childItems.some(a => a.isSelected || a.isAutoSelected)) {
        item.isSelected = false;
      }
    }
    let currentData = [];
    this.viewData.items.forEach(a => {
      if (a.isSelected || a.isAutoSelected) {
        currentData.push(a.id);
        if (a.childItems) {
          a.childItems.forEach(b => {
            if (b.isSelected || b.isAutoSelected) {
              currentData.push(a.id+b.id);
            }
          })
        }
      }
    })
    try {
      this.viewData.sectionHeaderViewData.controls[0].isDisabled = _.xor(this.selectedData, currentData).length == 0;
    } catch (error) {
      console.log(error);
    }
    this._cd.detectChanges();
  }

    reorderData(indexes:any){
      if (this.viewData.isDisabled) return;
      let element = this.viewData.items[indexes.from];
      this.viewData.items.splice(indexes.from, 1);
      this.viewData.items.splice(indexes.to, 0, element);
      indexes.complete();
      try {
        this.viewData.sectionHeaderViewData.controls[0].isDisabled = false;
      } catch (error) {
        console.log(error);
      }
      this._cd.detectChanges();
    }

    onSectionHeaderControlClick(id) {
      if (id === 'ri-header-save') {
      this.onSaveItem();
      }
    }
}
