import { xToDateOption } from './../../../../interfaces/edge-analytics/report.interface';
import { ReportDataManagementService } from './../../../services/reports/report-data-management.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { AsyncSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, map, skip, filter } from 'rxjs/operators';
import { BaseMeasureData, ChartFilterOption, MeasuresChartData, YearMonthOption } from '../../../../interfaces/edge-analytics/report.interface';
import { MeasureType } from '../../../enums/edge-analytics/edge-analytics.enum';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { IndEdgeAnalyticsSectionHeader } from '../../../models/indSectionHeaderDataModel';
import { DeviceService } from '../../../services/device/device.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ReportService } from '../../../services/reports/report.service';
import { UIService } from '../../../services/ui/ui.service';
import { unsubscribeSubscriptionArray } from '../../../utility/common.utility';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { SelectListComponent } from '../../shared/select-list/select-list';
import { unSubMeasureChartData } from '../../../services/reports/functions/report.functions';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import _ from 'lodash';
import { format, isValid } from 'date-fns';

@Component({
  selector: 'edge-analytics-detail',
  templateUrl: 'edge-analytics-detail.html',
  styleUrls: ['edge-analytics-detail.scss']
})
export class EdgeAnalyticsDetailComponent implements OnInit, OnDestroy {
  pageTitleHeader: IndPageTitleViewDataModel;
  sectionHeader: IndEdgeAnalyticsSectionHeader;
  cardSectionHeader: IndEdgeAnalyticsSectionHeader;
  selectedMeasure: BaseMeasureData;
  dateFilterPopoverData: any[] = [];
  @ViewChild(IonContent, { static: false }) content: IonContent;

  private mainSubs: Subscription[] = [];
  private subsWithinData: Subscription[] = [];
  private _viewDidEnter$: AsyncSubject<boolean> = new AsyncSubject();
  private readonly viewDidEnter$: Observable<boolean> = this._viewDidEnter$.asObservable();
  private popoverHandle: any;
  private dateFormatSubscription: Subscription;
  private timeFormatSubscription: Subscription;
  private currentSelectedMeasureType: MeasureType;

  constructor(
    public uiService: UIService,
    private reportService: ReportService,
    private translate: TranslateService,
    private navService: NavigationService,
    private cd: ChangeDetectorRef,
    private popover: PopoverController,
    private modalCtrl: ModalController,
    private deviceService: DeviceService,
    private trackService: TrackService,
    public readonly footerService: FooterService,
    public reportDataMgmService: ReportDataManagementService,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) {}

  ngOnInit() {
    // Screen resize observe
    this.footerService.initButtons(FooterViews.None);
    this.mainSubs.push(this.deviceService.screenWidth.pipe(skip(1), debounceTime(400)).subscribe((width: number) => {
      if (width > 0 && this.selectedMeasure) {
        this.reportService.resizeCharts(this.selectedMeasure);
      }
    }));

    // Delta sync observe
    this.mainSubs.push(
      // combineLatest([this.reportDataMgmService.isSyncing$, this.reportDataMgmService.isSyncSuccessful$]).pipe(
      //   distinctUntilChanged(),
      this.reportDataMgmService.measureSyncStateObservable.pipe(
        debounceTime(0),
        skip(1),
        map(({ isSyncing, syncState }) => {
          if (!isSyncing) {
            // Sync done & was successful
            this.updateSectionHeaders();
          }
        })
        // map(([isSyncing, isSuccessful]) => {
        //   if (isSyncing) {
        //     if (!isSuccessful) {
        //       // Sync started
        //       //console.log('--- sync started!!');
        //     }
        //   } else {
        //     //console.log('--- sync done!!');
        //     if (isSuccessful) {
        //       // Sync done & was successful
        //       //console.log('--- sync successful!!');

        //       // Update last refresh date time if updated during delta sync
        //       this.updateSectionHeaders();
        //     }
        //   }
        // })
      ).subscribe()
    );

    // Selected measure change observe
    this.mainSubs.push(this.reportDataMgmService.selectedMeasure$.subscribe((selectedMeasureType: MeasureType) => {
      if (!this.selectedMeasure || (selectedMeasureType && this.selectedMeasure.measureType !== selectedMeasureType)) {
        if (this.selectedMeasure) {
          this.clearCharts();
        }
        if (this.content) {
          this.content.scrollToTop(0);
        }
        this.currentSelectedMeasureType = selectedMeasureType;
        this.initTitleHeader(selectedMeasureType);
        this.selectedMeasure = this.reportDataMgmService.getMeasure(selectedMeasureType);
        this.selectedMeasure.langCode = this.translate.currentLang;
        if(selectedMeasureType === MeasureType.salesOrder){
          this.selectedMeasure.tileGroups = this.setSalesData();

        }
        this.reportService.subscribeToMeasureData(this.selectedMeasure);
        this.reportService.translateMeasureData(this.selectedMeasure);
        this.reportService.generateCharts(this.selectedMeasure);
        this.initSectionHeader();
        this.setDateFilterData();

        // Unsubscribe any previous detail subscriptions
        if (this.subsWithinData.length > 0) {
          unsubscribeSubscriptionArray(this.subsWithinData);
          this.subsWithinData = [];
        }

        if(selectedMeasureType !== MeasureType.salesOrder){
          this.subsWithinData.push(
            this.selectedMeasure.dateFilterOptions$
            .pipe(
              skip(1),
              debounceTime(0),
            )
            .subscribe(changed => {
            this.setDateFilterData();
          }));

          this.subsWithinData.push(this.selectedMeasure.selectedDateFilters$.pipe(skip(1)).subscribe(updated => {
            this.updateSectionHeaders();
          }));
        }


        if (this.selectedMeasure && Array.isArray(this.selectedMeasure.charts)) {
          for (let i = 0; i < this.selectedMeasure.charts.length; i++) {
            const chartData = this.selectedMeasure.charts[i];
            if (chartData.chartUpdated$) {
              this.subsWithinData.push(combineLatest([chartData.chartUpdated$, this.viewDidEnter$]).pipe(
                debounceTime(100),
                filter(([chartUpdated, viewDidEnter]) => chartUpdated !== null && viewDidEnter),
                // debounceTime(500),
                map(([chartUpdated, viewDidEnter]) => {
                  if (this.selectedMeasure) {
                    this.cd.markForCheck();
                    if (chartData.highChartRef) {
                      this.reportService.redrawHighChart(chartData.highChartRef);
                    }
                    this.reportDataMgmService.decLoadingCounter();
                    // console.log('---- chart redraw: ', chartUpdated, chartData);
                  }
                })
              ).subscribe());
            }
          }
        }
      }
    }));
    this.translate.onLangChange.subscribe(data =>{
      if (this.currentSelectedMeasureType) {
        this.initTitleHeader(this.currentSelectedMeasureType);
      }
      this.selectedMeasure.langCode = data.lang;
      this.reportService.subscribeToMeasureData(this.selectedMeasure);
      this.reportService.translateMeasureData(this.selectedMeasure);
      this.reportService.generateCharts(this.selectedMeasure);
      this.initSectionHeader();
      this.setDateFilterData();
    });

    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this.updateSectionHeaders();
      }
    });
    this.timeFormatSubscription = this.dateTimeFormatsService.timeFormat.subscribe( tf => {
      if (tf) {
        this.updateSectionHeaders();
      }
    });
    this.initSectionHeader();
  }
  ionViewDidEnter() {
    this._viewDidEnter$.next(true);
    this._viewDidEnter$.complete();
  }
  ngOnDestroy() {
    this.clearCharts();
    unsubscribeSubscriptionArray(this.subsWithinData);
    unsubscribeSubscriptionArray(this.mainSubs);
    this.dateFormatSubscription.unsubscribe();
    this.timeFormatSubscription.unsubscribe();
  }

  private clearCharts() {
    if (this.selectedMeasure && Array.isArray(this.selectedMeasure.charts)) {
      for (let i = 0; i < this.selectedMeasure.charts.length; i++) {
        const chartData = this.selectedMeasure.charts[i];
        if (chartData) {
          unSubMeasureChartData(chartData);
        }
        chartData._highChartRefReady$.next(false);
        chartData._chartUpdated$.next(null);
      }
    }
  }

  setSalesData() {
    const fields = {
      'indskr_acapyrd': { label:"ACTUAL_TARGET" , dataFieldName: "YTD" },
      'indskr_acapytm': { label: 'ACTUAL_TARGET', dataFieldName: "YTM" },
      'indskr_apnoap': { label: 'AP_REVENUE' },
      'indskr_dailysales': { label: 'DAILY_SALES' },
      'indskr_expectedsalesremainingdays': { label: 'EXPECTED_SALES' }
    };
    let counter = 1;
    let tileGroups = [];
    let tiles = []
    Object.keys(fields).forEach((key) => {
      tiles.push({
        "id": key,
        "firstHeading": fields[key].label,
        "dataValue": this.selectedMeasure[key] ?? 0,
        "dataFieldName":fields[key].dataFieldName ?? "",
        "order": 0,
        "enabled": true,
      })

      if (counter % 3 === 0) {
        tileGroups = [...tileGroups, { id: `sales-data-${counter - 3}`, tiles }];
        tiles = [];
      }
      counter++;
    });

    if (tiles.length > 0) {
      tileGroups = [...tileGroups, { id: `sales-data-${counter - 3}`, tiles }];
    }
    console.log('x', tileGroups);
    return tileGroups;
  }

  private initTitleHeader(measureType: MeasureType) {
    this.pageTitleHeader = {
      id: 'edge-analytics-detail-page-header',
      title: this.getHeaderTitle(measureType),
      controls: []
    };
  }
  private initSectionHeader() {
    this.sectionHeader = this.selectedMeasure?.sectionHeaderConfigs
      && this.selectedMeasure?.sectionHeaderConfigs?.top
        ? this.selectedMeasure?.sectionHeaderConfigs?.top
        : {
            id: 'edge-analytics-detail-section-header',
            title: '',
            rightLabelTxt: '',
            isFilter: true,
            isFilterStyleHeading: true,
            showArrow: true,
            arrowType: 'caret-down-outline',
            controls: [],
            hasRightLabelTxt: true,
            visible: true,
          };

    this.cardSectionHeader = this.selectedMeasure?.sectionHeaderConfigs
      && this.selectedMeasure?.sectionHeaderConfigs?.card
        ? this.selectedMeasure?.sectionHeaderConfigs.card
        : {
            id: 'edge-analytics-detail-card-section-header',
            title: '',
            rightLabelTxt: '',
            isFilter: true,
            isFilterStyleHeading: true,
            showArrow: true,
            arrowType: 'caret-down-outline',
            controls: [],
            hasRightLabelTxt: false,
            visible: false,
          };

    this.updateSectionHeaders();
  }
  private setDateFilterData() {
    if (this.selectedMeasure) {
      if (this.selectedMeasure.dateFilterOptionType === 'YearMonth') {
        let items: YearMonthOption[] = this.selectedMeasure._dateFilterOptions$.getValue();
        const selectedValues = this.selectedMeasure._selectedDateFilters$.getValue();

        if (Array.isArray(items) && items.length > 0) {
          this.dateFilterPopoverData = [
            {
              text: '',
              selectedValues,
              value: '',
              items,
              multiselect: this.selectedMeasure.dateFilterMultiSelect,
              handler: (selectedItem, curPopoverData, popover, checked) => {
                if (Array.isArray(curPopoverData.selectedValues)) {
                  if (curPopoverData.selectedValues.length === 0 && !checked) {
                    // Make sure at least one option is selected
                    selectedItem.doNotTriggerHandler = true;
                    popover.setSelectedValue(selectedItem);
                  } else {
                    if (this.selectedMeasure && this.selectedMeasure.charts) {
                      // Since we update all the charts here, counter becomes number of charts.
                      this.reportDataMgmService.incLoadingCounter(this.selectedMeasure.charts.length);
                    }
                    this.reportService.updateSelectedDateFilter(this.selectedMeasure, curPopoverData.selectedValues);
                  }
                  this.trackFilterClick(this.selectedMeasure.measureType, 'date');
                }
              }
            }
          ];
        }
      } else if (this.selectedMeasure.dateFilterOptionType === 'xToDate') {
        const items: xToDateOption[] = this.selectedMeasure._dateFilterOptions$.getValue();
        const selectedValues = this.selectedMeasure._selectedDateFilters$.getValue();

        if (Array.isArray(items) && items.length > 0) {
          this.dateFilterPopoverData = [
            {
              text: '',
              value: selectedValues[0],
              items,
              multiselect: this.selectedMeasure.dateFilterMultiSelect,
              handler: (selectedItem, curPopoverData, popover, checked) => {
                if (selectedItem.value !== curPopoverData.value) {
                  curPopoverData.value = selectedItem.value;
                  if (this.selectedMeasure && this.selectedMeasure.charts) {
                    // Since we update all the charts here, counter becomes number of charts.
                    // TODO: Need to update this logic as not all charts are updated...
                    let refreshChartCount = this.selectedMeasure.measureType !== MeasureType.procedure ? this.selectedMeasure.charts.length : 1;
                    this.reportDataMgmService.incLoadingCounter(refreshChartCount);
                  }
                  this.reportService.updateSelectedDateFilter(this.selectedMeasure, [selectedItem.value]);

                  this.trackFilterClick(this.selectedMeasure.measureType, 'date');
                  if (this.popoverHandle) {
                    this.popoverHandle.dismiss();
                  }
                }
              }
            }
          ];
        }
      }
    }
  }
  private getHeaderTitle(measureType: MeasureType): string {
    let title = '';
    switch (measureType) {
      case MeasureType.meeting:
        // 'Meetings & Phone Calls'
        title = this.translate.instant('MEETING_AND_PHONE_CALL_MEASURES');//this.translate.instant('MEETING') + ' & ' + this.translate.instant('NEW_ACTIVITY_PHONECALL') + ' ' + this.translate.instant('MEASURES');
        break;
      case MeasureType.message:
        // 'Messages'
        title = this.translate.instant('MESSAGES_MEAASURES');//this.translate.instant('MESSAGE') + ' ' + this.translate.instant('MEASURES');
        break;
      case MeasureType.coaching:
        title = this.translate.instant('COACHING_AND_COVISITOR_MEASURES');//this.translate.instant('ANALYTICS_COACHING_DETAIL_TITLE') + ' ' + this.translate.instant('MEASURES');
        break;
      case MeasureType.procedure:
        title = this.translate.instant('PROCEDURES_MEASURES');//this.translate.instant('PROCEDURE') + ' ' + this.translate.instant('MEASURES');
        break;
      case MeasureType.salesOrder:
        title = this.translate.instant('SALES_DATA');
      default:
        break;
    }
    return title;
  }
  private onClosePage() {
    this.navService.popChildNavPageWithPageTracking();
    this.uiService.showRightPane = false;
    this.reportDataMgmService.setSelectedMeasure(null);
  }
  private updateSectionHeaders() {
    if (this.sectionHeader && this.selectedMeasure) {
      this.updateSectionHeader(this.sectionHeader);
    }
    if (this.cardSectionHeader && !!(this.selectedMeasure?.sectionHeaderConfigs && this.selectedMeasure?.sectionHeaderConfigs?.card?.visible)) {
      this.updateSectionHeader(this.cardSectionHeader);
    }
  }
  private updateSectionHeader(sectionHeader: IndEdgeAnalyticsSectionHeader) {

    if (sectionHeader.isFilter && this.selectedMeasure.dateFilterOptionType === 'YearMonth') {
      const options = this.selectedMeasure._dateFilterOptions$.getValue();
      const selectedValues = this.selectedMeasure._selectedDateFilters$.getValue();
      if (Array.isArray(options) && options.length > 0) {
        if (Array.isArray(selectedValues)) {
          let title = '';
          for (let i = 0; i < selectedValues.length; i++) {
            const value = selectedValues[i];
            const option = options.find(o => o.value === value);
            if (option && option.text) {
              const commaOmittedText = option.text.replace(',', '');
              title = title !== '' ? title + ', ' + commaOmittedText : commaOmittedText;
            }
          }

          sectionHeader.title = title;
        }
      } else {
        sectionHeader.showArrow = false;
      }
    } else if (sectionHeader.isFilter && this.selectedMeasure.dateFilterOptionType === 'xToDate') {
      const options: xToDateOption[] = this.selectedMeasure._dateFilterOptions$.getValue();
      const selectedValues = this.selectedMeasure._selectedDateFilters$.getValue();
      if (Array.isArray(options) && Array.isArray(selectedValues) && selectedValues[0]) {
        const option = options.find(o => o.value === selectedValues[0]);
        if (option) {
          sectionHeader.title = option.text;
          sectionHeader.doNotModifyTitleCase = true;
        }
      } else {
        sectionHeader.showArrow = false;
      }
    }

    if(this.selectedMeasure.measureType === MeasureType.salesOrder){
      sectionHeader.showArrow = false;
    }

    // Display last refresh date time
    if (sectionHeader.hasRightLabelTxt) {
      if (this.selectedMeasure.measureType === MeasureType.procedure) {
        this.reportService.overwriteMeasureUpdateTime();
      }else if(this.selectedMeasure.measureType === MeasureType.salesOrder){
        const lastSync = (localStorage.getItem('localStorageTimestampForSync')) ? new Date(parseInt(localStorage.getItem('localStorageTimestampForSync'))) : 'Not Synced';
        if (isValid(new Date(lastSync))) {
          this.reportService.overwriteMeasureUpdateTime(new Date(lastSync));
        }
      }
      sectionHeader.rightLabelTxt = this.reportService.getLastRefreshDateFormattedText(this.selectedMeasure.measureType);
    }
  }
  private trackFilterClick(measureType: MeasureType, filterType: 'date' | 'graph') {
    switch (measureType) {
      case MeasureType.meeting:
        if (filterType === 'date') {
          this.trackService.tracking('MeetingTimeFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        } else if (filterType === 'graph') {
          this.trackService.tracking('MeetingGraphFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        }
        break;

      case MeasureType.message:
        if (filterType === 'date') {
          this.trackService.tracking('MessageTimeFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        } else if (filterType === 'graph') {
          this.trackService.tracking('MessageGraphFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        }
        break;
      case MeasureType.coaching:
        if (filterType === 'date') {
          this.trackService.tracking('CoachingsTimeFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        } else if (filterType === 'graph') {
          this.trackService.tracking('CoachingGraphFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        }
        break;
      case MeasureType.procedure:
        if (filterType === 'date') {
          this.trackService.tracking('ProceduresTimeFilterClick', TrackingEventNames.EDGE_ANALYTICS);
        }
        break;
      default:
        break;
    }
  }


  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      default:
        console.warn('edge-analytics-detail: onPageTitleControlClick: unhandled switch case: ', id);
        break;
    }
  }
  async openDateFilter(event) {
    if (!this.popoverHandle) {
      this.popoverHandle = await this.popover.create({ component: MultiSelectPopover, componentProps: { root: this.dateFilterPopoverData }, event });
      await this.popoverHandle.present();
      await this.popoverHandle.onDidDismiss();
      this.popoverHandle = null;
    }
  }
  async openChartFilter(chartData: MeasuresChartData) {
    if (this.selectedMeasure && chartData) {
      const options: ChartFilterOption[] = chartData._chartFilterOptions$.getValue();
      if (Array.isArray(options) && chartData.chartFilterTemplate) {
        const filterOptions = { ...chartData.chartFilterTemplate, ...{ data: options.map(o => {
          return {
            id: o.value,
            title: this.translate.instant(o.displayTextKey),
            isSelected: o.isSelected
          }
        }) } };
        this.footerService.forceHideMasterFooter = true;
        let modal = await this.modalCtrl.create({ component: SelectListComponent, componentProps: { viewData: filterOptions }, backdropDismiss: false });
        modal.present();
        modal.onDidDismiss().then(
          async (data: { data: { isDone: boolean, selectedItems: any[] }, role: any }) => {
            this.footerService.forceHideMasterFooter = false;
            if (data && data.data?.isDone) {
              const newOptions = JSON.parse(JSON.stringify(options));
              if (Array.isArray(data.data?.selectedItems)) {
                for (let i = 0; i < newOptions.length; i++) {
                  const newOption = newOptions[i];
                  newOption.isSelected = data.data.selectedItems.some(i => i.id === newOption.value);
                }

                this.reportDataMgmService.incLoadingCounter();
                this.reportService.updateSelectedChartFilter(this.selectedMeasure, newOptions, chartData.id);
              }
              this.trackFilterClick(this.selectedMeasure.measureType, 'graph');
            }
        });
        // const { data } = await modal.onDidDismiss();
        this.uiService.dismissLoader();
      }
    }
  }
}
