import { parse } from 'js2xmlparser';

export const fetchQueries = {
  deltaSyncFilter: `<link-entity name="indskr_trackchange" from="indskr_entityid" to="{entityID}" link-type="inner" alias="tc">
  <order attribute='createdon' descending='false'/>
  <attribute name="indskr_action" alias="track_action"/>
  <attribute name="createdon" alias="track_action_CreatedOn"/>
  <filter type="and">
    <condition attribute="createdon" operator="last-x-hours" value="{hourDifference}" />
    <condition value="{entityName}" attribute="indskr_entityname" operator="eq" />
  </filter>
  </link-entity>`,
  events: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
            <entity name="msevtmgt_event">
              <attribute name="msevtmgt_eventid" alias="id" />
              <attribute name="msevtmgt_name" alias="name" />
              <attribute name="createdon" />
              <attribute name="ownerid" />
              <attribute name="createdby" />
              <attribute name="msevtmgt_eventtimezone" />
              <attribute name="msevtmgt_eventstartdate" alias="startDate" />
              <attribute name="msevtmgt_eventenddate" alias="endDate" />
              <order attribute="msevtmgt_name" descending="false" />
              <filter type="and">
                <condition attribute="msevtmgt_eventformat" operator="eq" value="100000002" />
                <filter type="or">
                  <condition attribute="ownerid" operator="eq-userid" />
                  <condition attribute="createdby" operator="eq-userid" />
                </filter>
              </filter>
            </entity>
          </fetch>
          `,
  nextCallObjectives: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_callobjective">
    <attribute name="indskr_callobjectiveid" />
    <attribute name="indskr_callobjectives" />
    <attribute name="createdon" />
    <attribute name="indskr_customer" />
    <attribute name="indskr_customername" />
    <attribute name="indskr_objectivecompleted" />
    <attribute name="indskr_objectivecaptured" />
    <attribute name="indskr_user" />
    <attribute name="indskr_username" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_externalid" />
    <order attribute="indskr_callobjectives" descending="false" />
    {deltaSyncFilter}
  </entity>
</fetch>`,
  meetingObjectives: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_meetingobjectives">
      <attribute name="indskr_meetingobjectivesid" />
      <attribute name="indskr_name" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="indskr_indskr_positiongroups_indskr_meetingobj" from="indskr_meetingobjectivesid" to="indskr_meetingobjectivesid" visible="false" intersect="true">
        <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
          <link-entity name="position" from="positionid" to="positionid" alias="ah">
            <filter type="and">
              <condition attribute="indskr_status" operator="eq" value="100000000" />
            </filter>
            <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="ai">
              <filter type="and">
                <condition attribute="indskr_userid" operator="eq-userid" />
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  folloupactionObjectives: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_followupactionobjectives">
    <attribute name="indskr_followupactionobjectivesid" />
    <attribute name="indskr_name" />
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="indskr_indskr_followupactionobjectives_indskr_" from="indskr_followupactionobjectivesid" to="indskr_followupactionobjectivesid" visible="false" intersect="true">
      <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="ah">
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
          </filter>
          <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="ai">
            <filter type="and">
              <condition attribute="indskr_userid" operator="eq-userid" />
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`,
  accountManagement: {
    fetchAccountPlans: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_accountplan">
      {planID}
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="statecode" />
      <attribute name="indskr_startdate"/>
      <attribute name="ownerid" />
      <attribute name="modifiedon" />
      <attribute name="modifiedby" />
      <attribute name="indskr_enddate" />
      <attribute name="createdby" />
      <attribute name="indskr_account" />
      <attribute name="indskr_accountplanid" />
      <attribute name="statuscode"/>
      <attribute name="statecode"/>
      <attribute name="indskr_strategy"/>
      <attribute name="indskr_estimatedrevenue"/>
      <attribute name="indskr_actualrevenue"/>
      <attribute name="indskr_plannedbudget"/>
      {deltaSyncFilter}
      <filter type="and">
        {statuscondition}
      </filter>
      <filter type="or">
        <condition attribute="indskr_enddate" operator="null" />
        <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
      </filter>
      <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
        <attribute name="name" />
        <attribute name="accountid" />
        <attribute name="parentaccountid" />
        <attribute name="statecode" />
        <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
            <filter type="or">
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="positionid" operator="in">{positionIDs}</condition>
              </filter>
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="parentpositionid" operator="in">{positionIDs}</condition>
              </filter>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_indskr_accountplan_product" from="indskr_accountplanid" to="indskr_accountplanid" link-type="outer" >
        <link-entity name="product" from="productid" to="productid" link-type="outer" alias="product">
          <attribute name="name"/>
          <attribute name="productid"/>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    fetchObjectives: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_accountplan">
        {planID}
        <attribute name="indskr_accountplanid" />
        <filter type="and">
            <condition attribute="statuscode" value="548910002" operator="ne"/>
          </filter>
        <filter type="or">
          <condition attribute="indskr_enddate" operator="null" />
          <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
        </filter>
        {deltaSyncFilter}
      <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
              <filter type="or">
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                   <condition attribute="positionid" operator="in">
                    {positionIDs}
                    </condition>
                </filter>
                <filter type="and">
                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                    <condition attribute="parentpositionid" operator="in">
                    {positionIDs}
                    </condition>
                </filter>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_accountplanobjective" from="indskr_accountplan" to="indskr_accountplanid" link-type="outer" alias="PO">
            <attribute name="indskr_accountplanobjectiveid"/>
            <attribute name="createdby" />
            <attribute name="indskr_completedvalue"/>
            <attribute name="indskr_completedpercent"/>
            <attribute name="indskr_targetpercent"/>
            <attribute name="indskr_targetamount"/>
            <attribute name="indskr_targetamount_base"/>
            <attribute name="indskr_type"/>
            <attribute name="indskr_name" />
            <attribute name="statuscode"/>
            <attribute name="transactioncurrencyid"/>
            <attribute name="indskr_product"/>
            <attribute name="indskr_achievement1yractual"/>
            <attribute name="indskr_achievement1yrprojected"/>
            <attribute name="indskr_achievement1yryotal"/>
            <attribute name="indskr_achievement2yr"/>
            <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="objectiveowner">
              <attribute name="systemuserid" />
              <attribute name="fullname" />
              <attribute name="parentsystemuserid" />
            </link-entity>
            <link-entity name="transactioncurrency" from="transactioncurrencyid" to="transactioncurrencyid" link-type="outer">
              <attribute name="currencysymbol" alias="PO.transactioncurrencysymbol"/>
            </link-entity>
            <link-entity name="indskr_indskr_criticalsuccessfactors_indskr_ac" from="indskr_accountplanobjectiveid" to="indskr_accountplanobjectiveid" link-type="outer" >
              <link-entity name="indskr_criticalsuccessfactors" from="indskr_criticalsuccessfactorsid" to="indskr_criticalsuccessfactorsid" link-type="outer" alias="PO.CSF">
                <attribute name="indskr_name"/>
                <attribute name="indskr_criticalsuccessfactorsid"/>
                <attribute name="statecode"/>
                <attribute name="statuscode"/>
              </link-entity>
            </link-entity>
        </link-entity>
      </entity>
    </fetch>`,
    fetchObjectiveReports: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_accountplan">
      {planID}
      <attribute name="indskr_accountplanid" />
      <filter type="and">
          <condition attribute="statuscode" value="548910002" operator="ne"/>
        </filter>
      <filter type="or">
        <condition attribute="indskr_enddate" operator="null" />
        <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
      </filter>
      <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
        <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
            <filter type="or">
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="positionid" operator="in">
                  {positionIDs}
                </condition>
              </filter>
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="parentpositionid" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_accountplanobjective" from="indskr_accountplan" to="indskr_accountplanid" link-type="inner" alias="PO">
          <attribute name="indskr_accountplanobjectiveid"/>
          <link-entity name="indskr_accountplanprogressreport" from="indskr_objective" to="indskr_accountplanobjectiveid" link-type="inner" alias="report">
            {deltaSyncFilter}
            <attribute name="statuscode"/>
            <attribute name="statecode"/>
            <attribute name="createdby"/>
            <attribute name="indskr_completedval"/>
            <attribute name="indskr_completedpercent"/>
            <attribute name="indskr_progressnotes"/>
            <attribute name="statecode"/>
            <attribute name="indskr_name"/>
            <attribute name="indskr_accountplanprogressreportid"/>
            <attribute name="modifiedon"/>
            <attribute name="indskr_externalid"/>
            <attribute name="createdon"/>
            <filter type="or">
              <condition attribute="statuscode" operator="eq" value="1"/>
              <condition attribute="statuscode" operator="eq" value="548910000"/>
              <condition attribute="statuscode" operator="eq" value="548910002"/>
            </filter>
            <link-entity name="indskr_indskr_accountplanprogressreport_indskr" from="indskr_accountplanprogressreportid" to="indskr_accountplanprogressreportid" link-type="outer" >
              <link-entity name="indskr_criticalsuccessfactors" from="indskr_criticalsuccessfactorsid" to="indskr_criticalsuccessfactorsid" link-type="outer" alias="report.CSF">
                <attribute name="indskr_name"/>
                <attribute name="indskr_criticalsuccessfactorsid"/>
                <attribute name="statecode"/>
                <attribute name="statuscode"/>
              </link-entity>
            </link-entity>
            <link-entity name="annotation" from="objectid" to="indskr_accountplanprogressreportid" link-type="outer" alias="progressReportNotes">
                <attribute name="annotationid"/>
                <attribute name="createdon"/>
                <attribute name="notetext"/>
                <attribute name="ownerid"/>
                <attribute name="isdocument"/>
                <attribute name="filename"/>
                <attribute name="filesize"/>
                <attribute name="mimetype"/>
            </link-entity>
          </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    fetchDocuments: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_accountplan">
    {planID}
    <attribute name="indskr_accountplanid" />
    {deltaSyncFilter}
    <filter type="and">
          <condition attribute="statuscode" value="548910002" operator="ne"/>
        </filter>
    <filter type="or">
        <condition attribute="indskr_enddate" operator="null" />
        <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
      </filter>
      <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
              <filter type="or">
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="positionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="parentpositionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
              </filter>
            </link-entity>
          </link-entity>
      </link-entity>
    <link-entity name="indskr_indskr_accountplan_indskr_iodocument" from="indskr_accountplanid" to="indskr_accountplanid" link-type="outer" alias="PO">
      <link-entity name="indskr_iodocument" from="indskr_iodocumentid" to="indskr_iodocumentid" link-type="outer" alias="ap_document">
        <attribute name="indskr_iodocumentid"/>
        <attribute name="indskr_ckmdocumenturl"/>
        <attribute name="indskr_ckmdocumentid"/>
        <attribute name="indskr_ckmdocumenttype"/>
        <attribute name="indskr_ckmthumbnailurl"/>
        <attribute name="indskr_ckmtitle"/>
        <attribute name="indskr_title"/>
        <attribute name="createdon"/>
        <attribute name="modifiedon"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_availablefrom" />
        <attribute name="indskr_availableuntil" />
        <attribute name="indskr_description"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="statuscode" operator="eq" value="1" />
        </filter>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_indskr_accountplan_indskr_iopresentatio" from="indskr_accountplanid" to="indskr_accountplanid" link-type="outer" alias="P1">
      <link-entity name="indskr_iopresentation" from="indskr_iopresentationid" to="indskr_iopresentationid" link-type="outer" alias="ap_ppt">
        <attribute name="indskr_iopresentationid"/>
        <attribute name="indskr_ckmzipurl"/>
        <attribute name="indskr_ckmthumbnailurl"/>
        <attribute name="indskr_ckmthumbnailszipurl"/>
        <attribute name="indskr_ckmtitle"/>
        <attribute name="indskr_title"/>
        <attribute name="createdon"/>
        <attribute name="modifiedon"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_availablefrom" />
        <attribute name="indskr_availableuntil" />
        <attribute name="indskr_description"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="statuscode" operator="eq" value="1" />
        </filter>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_indskr_accountplan_indskr_ioresource" from="indskr_accountplanid" to="indskr_accountplanid" link-type="outer" alias="P2">
      <link-entity name="indskr_ioresource" from="indskr_ioresourceid" to="indskr_ioresourceid" link-type="outer" alias="ap_resource">
        <attribute name="indskr_ioresourceid"/>
        <attribute name="indskr_ckmassetid"/>
        <attribute name="indskr_ckmthumbnailurl"/>
        <attribute name="indskr_ckmassetid"/>
        <attribute name="indskr_ckmassettype"/>
        <attribute name="indskr_ckmasseturl"/>
        <attribute name="indskr_ckmtitle"/>
        <attribute name="indskr_title"/>
        <attribute name="createdon"/>
        <attribute name="modifiedon"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_availablefrom" />
        <attribute name="indskr_availableuntil" />
        <attribute name="indskr_description"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="statuscode" operator="eq" value="1" />
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`,
    fetchEvents: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_accountplan">
    {planID}
      <attribute name="indskr_accountplanid" />
      {deltaSyncFilter}
      <filter type="and">
          <condition attribute="statuscode" value="548910002" operator="ne"/>
        </filter>
      <filter type="or">
        <condition attribute="indskr_enddate" operator="null" />
        <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
      </filter>
      <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
        <link-entity name="account" from="accountid" to="accountid" link-type="inner" alias="ac">
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
              <filter type="or">
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="positionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="parentpositionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_indskr_accountplan_msevtmgt_event" from="indskr_accountplanid" to="indskr_accountplanid" visible="false" intersect="true">
        <link-entity name="msevtmgt_event" from="msevtmgt_eventid" to="msevtmgt_eventid" alias="aa">
          <attribute name="msevtmgt_name" alias="event_name"/>
          <attribute name="msevtmgt_eventid" alias="eventid"/>
          <attribute name="statuscode" alias="statuscode"/>
        </link-entity>
    </link-entity>
  </entity>
</fetch>`,
    fetchContacts: `
    <fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
    <entity name="indskr_accountplan"> {planID}
      <attribute name="indskr_accountplanid"/> {deltaSyncFilter}
      <filter type="or">
        <condition attribute="indskr_enddate" operator="null" />
        <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
      </filter>
      <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
        <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ap">
            <filter type="or">
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="positionid" operator="in"> {positionIDs} </condition>
              </filter>
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="parentpositionid" operator="in"> {positionIDs} </condition>
              </filter>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_indskr_accountplan_indskr_accountcontac" intersect="true" visible="false" to="indskr_accountplanid" from="indskr_accountplanid">
        <link-entity name="indskr_accountcontactaffiliation" to="indskr_accountcontactaffiliationid" from="indskr_accountcontactaffiliationid" alias="ae">
        <attribute name="indskr_accountcontactaffiliationid"/>
          <filter type="and">
            <condition attribute="statecode" value="0" operator="eq"/>
          </filter>
          <link-entity name="contact" to="indskr_contactid" from="contactid" alias="af" link-type="inner">
            <attribute name="firstname"/>
            <attribute name="lastname"/>
            <attribute name="contactid"/>
            <filter type="and">
              <condition attribute="statecode" value="0" operator="eq"/>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    fetchExpenses: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_accountplan"> {planID}       <attribute name="indskr_accountplanid"/>{deltaSyncFilter}
    <filter type="and">
          <condition attribute="statuscode" operator="ne" value="548910002" />
      </filter>
    <filter type="or">
    <condition attribute="indskr_enddate" operator="null" />
    <condition attribute="indskr_enddate" operator="on-or-after" value="{compareToDate}" />
  </filter>
  <link-entity name="account" from="accountid" to="indskr_account" link-type="inner" alias="account">
    <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
      <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ap">
        <filter type="or">
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="positionid" operator="in"> {positionIDs} </condition>
          </filter>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="parentpositionid" operator="in"> {positionIDs} </condition>
          </filter>
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_expense" from="indskr_accountplanid" to="indskr_accountplanid" link-type="inner" alias="ab">
    <attribute name="indskr_amount" alias="amount"/>
    <attribute name="indskr_expenseid" alias="expenseId"/>
    <attribute name="indskr_name" alias="description"/>
    <filter type="and">
      <condition attribute="ownerid" operator="eq-userid" />
    </filter>
    <link-entity name="transactioncurrency" from="transactioncurrencyid" to="transactioncurrencyid" link-type="outer">
      <attribute name="currencysymbol" alias="transactioncurrencysymbol"/>
    </link-entity>
    <link-entity name="annotation" from="objectid" to="indskr_expenseid" link-type="outer" alias="ea">
      <attribute name="annotationid" alias="annotationId"/>
      <attribute name="filename" alias="fileName"/>
      <attribute name="filesize" alias="fileSize"/>
      <attribute name="createdon" alias="createdOn"/>
      <attribute name="notetext" alias="noteText"/>
    </link-entity>
  </link-entity>
  </entity>
  </fetch>`,
    fetchAppointmentsForAccounts: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="appointment">
    <attribute name="subject" />
    <attribute name="statecode" />
    <attribute name="scheduledstart" />
    <attribute name="scheduledend" />
    <attribute name="activityid" />
    <attribute name="location"/>
    <attribute name="indskr_jointmeeting"/>
    <attribute name="activitytypecode" />
    <attribute name="indskr_isremotedetailing" alias="isRemoteDetailing"/>
    <order attribute="subject" descending="false" />
    <filter type="and">
      <condition attribute="ownerid" operator="eq-userid" />
      <condition attribute="statecode" operator="eq" value="1" />
    </filter>
    <link-entity name="indskr_activityaccount" from="indskr_appointmentid" to="activityid" link-type="inner" alias="ab">
      <filter type="and">
        <condition attribute="indskr_accountid" operator="in" >
          {accountIds}
        </condition>
      </filter>
    </link-entity>
    </entity>
  </fetch>
  `,
    fetchPhoneCallsForAccounts: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="phonecall">
      <attribute name="subject" />
      <attribute name="statecode" />
      <attribute name="scheduledstart" />
      <attribute name="scheduledend" />
      <attribute name="activityid" />
      <attribute name="indskr_jointphonecall" alias="jointphonecall"/>
      <attribute name="phonenumber" />
      <attribute name="activitytypecode" />
      <order attribute="subject" descending="false" />
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="1" />
        <condition attribute="ownerid" operator="eq-userid" />
      </filter>
      <link-entity name="indskr_activityaccount" from="indskr_phonecallid" to="activityid" link-type="inner" alias="ae">
        <filter type="and">
          <condition attribute="indskr_accountid" operator="in" >
            {accountIds}
          </condition>
        </filter>
      </link-entity>
    </entity>
  </fetch>
  `,
    fetchMeetingForAccountPlan: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_accountplan">
      <filter type="and">
        <condition attribute="indskr_accountplanid" operator="eq" value="{planID}"/>
      </filter>
      <attribute name="indskr_accountplanid" />
      <link-entity name="indskr_activitymeeting" from="indskr_accountplanid" to="indskr_accountplanid" link-type="inner" alias="ac">
        <attribute name="indskr_activitymeetingid" alias="activityMeetingId"/>
        <link-entity name="appointment" from="activityid" to="indskr_appointmentid" link-type="inner" alias="meeting">
          <attribute name="activityid"/>
          <attribute name="location"/>
          <attribute name="subject"/>
          <attribute name="ownerid"/>
          <attribute name="scheduledstart"/>
          <attribute name="scheduledend"/>
          <attribute name="statecode" />
          <attribute name="indskr_jointmeeting"/>
          <attribute name="indskr_isremotedetailing" alias="isRemoteDetailing"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="1"/>
          </filter>
        </link-entity>
      </link-entity>
	</entity>
</fetch>
  `,
    fetchPhoneCallsForAccountPlan: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_accountplan">
    <filter type="and">
      <condition attribute="indskr_accountplanid" operator="eq" value="{planID}"/>
    </filter>
    <attribute name="indskr_accountplanid" />
    <link-entity name="indskr_activityphonecall" from="indskr_accountplanid" to="indskr_accountplanid" link-type="inner" alias="ad">
      <attribute name="indskr_activityphonecallid" alias="activityPhoneCallId"/>
      <link-entity name="phonecall" from="activityid" to="indskr_phonecallid" link-type="inner" alias="phonecall">
        <attribute name="activityid"/>
        <attribute name="phonenumber"/>
        <attribute name="subject"/>
        <attribute name="ownerid"/>
        <attribute name="scheduledstart"/>
        <attribute name="scheduledend"/>
        <attribute name="statecode" />
        <attribute name="indskr_jointphonecall" alias="jointphonecall"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="1"/>
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`
  },

  marketingPlanManagement: {
    fetchCurrencies: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="transactioncurrency">
      <attribute name="transactioncurrencyid" />
      <attribute name="currencyname" />
      <attribute name="isocurrencycode" />
      <attribute name="currencysymbol" />
      <attribute name="exchangerate" />
      <attribute name="currencyprecision" />
      <order attribute="currencyname" descending="false" />
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
    </entity>
  </fetch>
  `,
    fetchMarketingBusinessPlans: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_brandplan">
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_validto" />
    <attribute name="indskr_validfrom" />
    <attribute name="indskr_totalbudget" />
    <attribute name="ownerid" />
    <attribute name="transactioncurrencyid" />
    <attribute name="indskr_brandplanid" />
    <attribute name="statecode" />
    <attribute name="indskr_estimatedrevenuetotal" />
    <attribute name="indskr_actualrevenuetotal" />
    <attribute name="statuscode" alias="statusCode" />
    <filter type="and">
      <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
      {brandPlanIdCondition}
      {deltaSyncCondition}
      {statusCondition}
    </filter>
    {filterCondition}
  </entity>
  </fetch>
  `,
  fetchMarketingBusinessPlansbasedBU:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_brandplan">
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <attribute name="indskr_validto" />
        <attribute name="indskr_validfrom" />
        <attribute name="indskr_totalbudget" />
        <attribute name="indskr_productid" />
        <attribute name="createdby" />
        <attribute name="indskr_brandplanid" />
        <attribute name="indskr_parentmarketingbusinessplan" />
        <attribute name="ownerid" />
        <attribute name="transactioncurrencyid" />
        <attribute name="statecode" />
        <attribute name="indskr_estimatedrevenuetotal" />
        <attribute name="indskr_actualrevenuetotal" />
        <attribute name="statuscode" alias="statusCode" />
        <attribute name="indskr_focusarea" />
        <attribute name="indskr_primaryspecialty" />
        <attribute name="indskr_subspecialty" />
        <attribute name="indskr_type" />
        <attribute name="indskr_purpose" />
        <attribute name="indskr_hierarchyopportunityconversionrates" />
        <attribute name="indskr_opportunityconversionrates" />
        <attribute name="indskr_hcptrainingcompleted" />
        <attribute name="indskr_multiyearcampaign" />
        <attribute name="indskr_npiapprovedbyaccount" />
        <attribute name="indskr_npiprocessstarted" />
        <attribute name="modifiedby" />
        <order attribute="indskr_name" descending="false" />
        <filter type="and">
          <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
          {brandPlanIdCondition}
          {deltaSyncCondition}
          {statusCondition}
        </filter>
        {filterCondition}
        <link-entity name="indskr_associationbutomarketingbusinessplan" from="indskr_marketingbusinessplan" to="indskr_brandplanid" link-type="inner" alias="bu">
          <attribute name="indskr_businessunit" />
          <attribute name="indskr_associationbutomarketingbusinessplanid" />
        </link-entity>
      </entity>
    </fetch>
  `,
  fetchMarketingBusinessPlanTypes: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_marketingbusinessplantypes">
        <attribute name="statecode"/>
        <attribute name="indskr_marketingbusinessplantypesid"/>
        <attribute name="indskr_name"/>
        <attribute name="createdon"/>
        <filter type="and">
          <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
        </filter>
        <order attribute="indskr_name" descending="false" />
      </entity>
    </fetch>
  `,
  fetchSurgeryOrders:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="salesorder">
        <attribute name="name" />
        <attribute name="statuscode" />
        <attribute name="customerid" />
        <attribute name="salesorderid" />
        <attribute name="indskr_marketingbusinessplan" />
        <attribute name="indskr_scheduleddate" />
        <attribute name="indskr_scheduledenddate" />
        <attribute name="createdon" />
        <order attribute="name" descending="false" />
        <filter type="and">
          <condition attribute="indskr_ordertype" operator="eq" value="548910001" />
          <condition attribute="indskr_marketingbusinessplan" operator="eq"  value="{planID}"/>
          <condition attribute="ownerid" operator="ne-userid" />
        </filter>
      <link-entity name="contact" from="contactid" to="customerid" visible="false" link-type="outer" alias="salesordercustomeridcontactcontactid">
        <attribute name="emailaddress1" />
      </link-entity>
    </entity>
  </fetch>
`,
fetchAppointments: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="appointment">
  <attribute name="subject" />
  <attribute name="statecode" />
  <attribute name="scheduledstart" />
  <attribute name="scheduledend" />
  <attribute name="activityid" />
  <attribute name="location"/>
  <attribute name="indskr_jointmeeting"/>
  <attribute name="activitytypecode" />
  <attribute name="indskr_isremotedetailing" alias="isRemoteDetailing"/>
  <order attribute="subject" descending="false" />
  <filter type="and">
      <condition attribute="indskr_marketingbusinessplan" operator="eq"  value="{planID}"/>
      <condition attribute="ownerid" operator="ne-userid" />
      <condition attribute="statecode" operator="neq" value="2" />
  </filter>
  </entity>
</fetch>
`,
    fetchAccounts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_brandplan">
      <attribute name="indskr_brandplanid" />
      <filter type="and">
        <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
        {brandPlanIdCondition}
        {deltaSyncCondition}
        {statusCondition}
      </filter>
      {filterCondition}
      <link-entity name="indskr_brandplan_accountnn" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
        <link-entity name="account" from="accountid" to="accountid">
          <attribute name="name" alias="accountName"/>
          <attribute name="accountid" alias="accountId"/>
          <attribute name="statecode" alias="accountStatecode"/>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>
  `,
    fetchProducts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_brandplan">
      <attribute name="indskr_brandplanid" />
      <filter type="and">
        <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
        {brandPlanIdCondition}
        {deltaSyncCondition}
        {statusCondition}
      </filter>
      {filterCondition}
      <link-entity name="indskr_brandplan_product" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
        <link-entity name="product" from="productid" to="productid" alias="pr">
          <attribute name="name"/>
          <attribute name="productid"/>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    fetchContacts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_brandplan">
    <attribute name="indskr_brandplanid" />
    <filter type="and">
      <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
      {brandPlanIdCondition}
      {deltaSyncCondition}
      {statusCondition}
    </filter>
    {filterCondition}
    <link-entity name="indskr_brandplan_contact" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
      <link-entity name="contact" from="contactid" to="contactid" alias="ab" >
    	  <attribute name="contactid"/>
    	  <attribute name="fullname"/>
      </link-entity>
    </link-entity>
  </entity>
  </fetch>`,
    fetchOpportunities: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_brandplan">
    <attribute name="indskr_brandplanid" />
    <filter type="and">
      <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
      {brandPlanIdCondition}
      {deltaSyncCondition}
      {statusCondition}
    </filter>
    {filterCondition}
    <link-entity name="indskr_brandplan_opportunity" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
      <link-entity name="opportunity" from="opportunityid" to="opportunityid" alias="ab">
        <attribute name="opportunityid" />
        <attribute name="name" />
      </link-entity>
    </link-entity>
  </entity>
  </fetch>`,
    fetchEvents: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_brandplan">
    <attribute name="indskr_brandplanid" />
    <filter type="and">
      <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
      {brandPlanIdCondition}
      {deltaSyncCondition}
      {statusCondition}
    </filter>
    {filterCondition}
    <link-entity name="indskr_brandplan_msevtmgt_event" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
      <link-entity name="msevtmgt_event" from="msevtmgt_eventid" to="msevtmgt_eventid" alias="aa">
          <attribute name="msevtmgt_name" alias="event_name"/>
          <attribute name="msevtmgt_eventid" alias="eventid"/>
          <attribute name="statuscode" alias="eventStatusCode"/>
          <attribute name="msevtmgt_eventstartdate" alias="startDate"/>
      </link-entity>
    </link-entity>
  </entity>
  </fetch>`,
    fetchNotes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_brandplan">
    <attribute name="indskr_brandplanid" />
    <filter type="and">
      <condition attribute="indskr_validto" operator="on-or-after" value="{compareToDate}" />
      {brandPlanIdCondition}
      {deltaSyncCondition}
      {statusCondition}
    </filter>
    {filterCondition}
    <link-entity name="annotation" from="objectid" to="indskr_brandplanid" link-type="inner" alias="ae" visible="false" intersect="true">
      <attribute name="annotationid"/>
      <attribute name="subject" />
      <attribute name="notetext" />
      <attribute name="filename" />
      <attribute name="annotationid" />
      <attribute name="ownerid" />
      <attribute name="mimetype" />
      <attribute name="modifiedby" />
      <attribute name="isdocument" />
      <attribute name="filesize" />
      <attribute name="createdon" />
      <attribute name="createdby" />
  </link-entity>
  </entity>
  </fetch>`,
    accountPositionFilterCondition: `<link-entity name="indskr_brandplan_accountnn" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
  <link-entity name="account" from="accountid" to="accountid">
    <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner">
      <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
        <filter type="or">
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="positionid" operator="in">{positionIDs}</condition>
          </filter>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="parentpositionid" operator="in">{positionIDs}</condition>
          </filter>
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
  </link-entity>`,
    productPositionFilterCondition: `<link-entity name="indskr_brandplan_product" from="indskr_brandplanid" to="indskr_brandplanid" visible="false" intersect="true">
  <link-entity name="product" from="productid" to="productid">
    <link-entity name="indskr_position_product" from="productid" to="productid" visible="false" intersect="true">
      <link-entity name="position" from="positionid" to="positionid">
        <filter type="or">
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="positionid" operator="in">{positionIDs}</condition>
          </filter>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="parentpositionid" operator="in">{positionIDs}</condition>
          </filter>
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
  </link-entity>`,
    noAccountNoProductFilterCondition: `<link-entity name="indskr_brandplan_accountnn" from="indskr_brandplanid" to="indskr_brandplanid" intersect="true" link-type="outer">
  <link-entity name="account" from="accountid" to="accountid" link-type="outer" alias="noAccount" />
  </link-entity>
  <link-entity name="indskr_brandplan_product" from="indskr_brandplanid" to="indskr_brandplanid" intersect="true" link-type="outer">
  <link-entity name="product" from="productid" to="productid" link-type="outer" alias="noProduct" />
  </link-entity>
  <filter type="and">
  <condition entityname="noAccount" attribute="accountid" operator="null" />
  </filter>
  <filter type="and">
  <condition entityname="noProduct" attribute="productid" operator="null" />
  </filter>`,
    fetchMeetingForMarketingPlan: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="appointment">
        <attribute name="activityid"/>
        <attribute name="location"/>
        <attribute name="subject"/>
        <attribute name="ownerid"/>
        <attribute name="scheduledstart"/>
        <attribute name="scheduledend"/>
        <attribute name="statecode" />
        <attribute name="indskr_jointmeeting"/>
        <attribute name="indskr_isremotedetailing" alias="isRemoteDetailing"/>
        <filter type="and">
          <condition attribute="indskr_marketingbusinessplan" operator="eq" value="{planID}" />
          <condition attribute="statecode" operator="neq" value="2" />
        </filter>
    </entity>
  </fetch>
  `,
  fetchSurgeryOrderForMarketingPlan: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="salesorder">
        <attribute name="ownerid"/>
        <attribute name="name" />
        <attribute name="salesorderid" />
        <attribute name="indskr_scheduleddate" />
        <attribute name="indskr_scheduledenddate" />
        <attribute name="statecode" />
        <attribute name="statuscode" />
        <filter type="and">
          <condition attribute="indskr_marketingbusinessplan" operator="eq" value="{planID}" />
        </filter>
    </entity>
  </fetch>
  `,
    fetchPhoneCallsForMarketingPlan: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_brandplan">
    <filter type="and">
      <condition attribute="indskr_brandplanid" operator="eq" value="{planID}"/>
    </filter>
    <attribute name="indskr_brandplanid" />
    <link-entity name="indskr_activityphonecall" from="indskr_marketingbusinessplan" to="indskr_brandplanid" link-type="inner" alias="ad">
      <attribute name="indskr_activityphonecallid" alias="activityPhoneCallId"/>
      <link-entity name="phonecall" from="activityid" to="indskr_phonecallid" link-type="inner" alias="phonecall">
        <attribute name="activityid"/>
        <attribute name="phonenumber"/>
        <attribute name="subject"/>
        <attribute name="ownerid"/>
        <attribute name="scheduledstart"/>
        <attribute name="scheduledend"/>
        <attribute name="statecode" />
        <attribute name="indskr_jointphonecall" alias="jointphonecall"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="1"/>
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`,
    fetchBusinessUnits: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="businessunit">
          <attribute name="businessunitid" alias="businessunitid"/>
          <attribute name="name" alias="businessunitname"/>
        </entity>
    </fetch>`,
  },
  orderManagement: {
    fetchPriceListsWithAccounts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="pricelevel">
      <attribute name="name" />
      <attribute name="createdon" />
      <attribute name="statecode" />
      <attribute name="statuscode"/>
      <attribute name="begindate"/>
      <attribute name="organizationid" />
      <attribute name="modifiedon" />
      <attribute name="modifiedby" />
      <attribute name="enddate" />
      <attribute name="createdby" />
      <attribute name="pricelevelid"/>
      <attribute name = "transactioncurrencyid"/>
      <link-entity name="transactioncurrency" from="transactioncurrencyid" to="transactioncurrencyid" link-type="outer">
        <attribute name="currencysymbol" alias="transactioncurrencysymbol"/>
      </link-entity>
      <link-entity name="systemuser" from="systemuserid" to="createdby" link-type="inner">
        <link-entity name="businessunit" from="businessunitid" to="businessunitid" link-type="inner">
        <filter>
            <condition attribute="businessunitid" operator="eq" value="{busUnitID}" />
        </filter>
        </link-entity>
      </link-entity>
      {deltaSyncFilter}
      <link-entity name="indskr_accountpricelist" from="indskr_pricelist" to="pricelevelid" link-type="inner" alias="CAP">
          <attribute name="indskr_accountpricelistid"/>
          <attribute name="indskr_account" />
          <attribute name="indskr_accountname"/>
          <attribute name="indskr_country"/>
          <attribute name="indskr_name"/>
          <attribute name="indskr_pricelist"/>
          <link-entity name="account" from="accountid" to="indskr_account" link-type="outer" alias="account">
            <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="ad">
              <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
              <filter type="or">
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="positionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="parentpositionid" operator="in">
                    {positionIDs}
                  </condition>
                </filter>
              </filter>
              </link-entity>
            </link-entity>
          </link-entity>
      </link-entity>
      <link-entity name="productpricelevel" from="pricelevelid" to="pricelevelid" link-type="inner" alias="products">
        <attribute name="productid"/>
        <attribute name="amount"/>
        <attribute name="uomid"/>
        <link-entity name="product" from="productid" to="productid" link-type="inner" alias="productname">
          <attribute name="name"/>
          <attribute name="indskr_productapplicability"/>
          <attribute name="indskr_parentproductid"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    fetchPriceListsWithoutAccounts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="pricelevel">
      <attribute name="name" />
      <attribute name="createdon" />
      <attribute name="statecode" />
      <attribute name="statuscode"/>
      <attribute name="begindate"/>
      <attribute name="organizationid" />
      <attribute name="modifiedon" />
      <attribute name="modifiedby" />
      <attribute name="enddate" />
      <attribute name="createdby" />
      <attribute name="pricelevelid"/>
      <attribute name = "transactioncurrencyid"/>
      <link-entity name="transactioncurrency" from="transactioncurrencyid" to="transactioncurrencyid" link-type="outer">
        <attribute name="currencysymbol" alias="transactioncurrencysymbol"/>
      </link-entity>
      <link-entity name="systemuser" from="systemuserid" to="createdby" link-type="inner">
        <link-entity name="businessunit" from="businessunitid" to="businessunitid" link-type="inner">
        <filter>
            <condition attribute="businessunitid" operator="eq" value="{busUnitID}" />
        </filter>
        </link-entity>
      </link-entity>
      {deltaSyncFilter}
      <link-entity name="indskr_accountpricelist" from="indskr_pricelist" to="pricelevelid" link-type="inner" alias="CAP">
          <attribute name="indskr_accountpricelistid"/>
          <attribute name="indskr_account" />
          <attribute name="indskr_countryname"/>
          <attribute name="indskr_accountname"/>
          <attribute name="indskr_country"/>
          <attribute name="indskr_name"/>
          <attribute name="indskr_pricelist"/>
          <filter>
            <condition attribute="indskr_account" operator="null" />
          </filter>
      </link-entity>
      <link-entity name="productpricelevel" from="pricelevelid" to="pricelevelid" link-type="inner" alias="products">
        <attribute name="productid"/>
        <attribute name="amount"/>
        <attribute name="uomid"/>
        <link-entity name="product" from="productid" to="productid" link-type="inner" alias="productname">
          <attribute name="name"/>
          <attribute name="indskr_productapplicability"/>
          <attribute name="indskr_parentproductid"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  },
  countries: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" top="5000">
  <entity name="indskr_lu_country">
    <attribute name="indskr_countryname" alias="countryName"/>
    <attribute name="indskr_lu_countryid" alias="countryID"/>
    <attribute name="indskr_countrycodealpha2" alias="countryCode"/>
    
  </entity>
  </fetch>`,
  messageTemplates: {
    full: (positionIds) =>
      fetchXmlEntity({
        '@': {
          name: 'indskr_emailtemplate',
        },
        attribute: [
          ['indskr_name'],
          ['indskr_type'],
          ['indskr_editablebyuser'],
          ['createdon'],
          ['modifiedon'],
          ['indskr_emailtemplateid'],
          ['indskr_typename'],
          ['omnip_product', 'product_id'],
          ['indskr_email_subject'],
          ['indskr_description'],
          ['indskr_body'],
          ['indskr_fb_body'],
          ['indskr_whatsapp_body'],
          ['indskr_sms_body'],
          ['indskr_thumbnailurl'],
          ['statuscode'],
        ].map(([attr, alias]) => ({
          '@': alias ? { name: attr, alias: alias } : { name: attr },
        })),
        filter: [
          {
            '@': {
              type: 'and',
            },
            condition: [
              {
                '@': {
                  attribute: 'statuscode',
                  operator: 'eq',
                  value: '1',
                },
              },
            ],
          },
        ],
        'link-entity': [
          {
            '@': {
              name: 'indskr_emailtemplate_contenttoken',
              from: 'indskr_emailtemplateid',
              to: 'indskr_emailtemplateid',
              'link-type': 'outer',
              alias: 'ae',
            },
            attribute: [
              {
                '@': {
                  name: 'indskr_contenttokenid',
                  alias: 'indskr_content_tokenid',
                },
              },
            ],
          },
          {
            '@': {
              name: 'indskr_indskr_emailtemplate_indskr_positiongro',
              from: 'indskr_emailtemplateid',
              to: 'indskr_emailtemplateid',
              'link-type': 'inner',
              alias: 'ap',
            },
            'link-entity': [
              {
                '@': {
                  name: 'indskr_positiongroups',
                  from: 'indskr_positiongroupsid',
                  to: 'indskr_positiongroupsid',
                  'link-type': 'inner',
                  alias: 'apg',
                },
                filter: [
                  {
                    '@': {
                      type: 'and',
                    },
                    condition: [
                      {
                        '@': {
                          attribute: 'statuscode',
                          operator: 'eq',
                          value: '1',
                        },
                      },
                    ],
                  },
                ],
                'link-entity': [
                  {
                    '@': {
                      name: 'indskr_indskr_positiongroups_position_n2n',
                      from: 'indskr_positiongroupsid',
                      to: 'indskr_positiongroupsid',
                      alias: 'aq',
                    },
                    'link-entity': [
                      {
                        '@': {
                          name: 'position',
                          from: 'positionid',
                          to: 'positionid',
                        },
                        filter: [
                          {
                            '@': {
                              type: 'or',
                            },
                            condition: [
                              {
                                '@': {
                                  attribute: 'positionid',
                                  operator: 'in',
                                },
                                value: positionIds,
                              },
                              {
                                value: positionIds,
                                '@': {
                                  attribute: 'parentpositionid',
                                  operator: 'in',
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            '@': {
              name: 'indskr_consenttype_indskr_emailtemplate',
              from: 'indskr_emailtemplateid',
              to: 'indskr_emailtemplateid',
              visible: 'false',
              'link-type': 'outer',
            },
            attribute: [
              {
                '@': {
                  name: 'indskr_consenttypeid',
                  alias: 'indskr_consentTypeId',
                },
              },
            ],
            'link-entity': [
              {
                '@': {
                  name: 'indskr_consenttype',
                  from: 'indskr_consenttypeid',
                  to: 'indskr_consenttypeid',
                  'link-type': 'outer',
                },
                attribute: [
                  {
                    '@': {
                      name: 'indskr_name',
                      alias: 'indskr_consentType',
                    },
                  },
                ],
              },
            ],
          },
        ],
      }),
    delta: (positionIds, delta) =>
      fetchXmlEntity({
        '@': {
          name: 'indskr_emailtemplate',
        },
        attribute: [
          ['indskr_name'],
          ['indskr_type'],
          ['indskr_editablebyuser'],
          ['createdon'],
          ['modifiedon'],
          ['indskr_emailtemplateid'],
          ['indskr_typename'],
          ['omnip_product', 'product_id'],
          ['indskr_email_subject'],
          ['indskr_description'],
          ['indskr_body'],
          ['indskr_fb_body'],
          ['indskr_whatsapp_body'],
          ['indskr_sms_body'],
          ['indskr_thumbnailurl'],
          ['statuscode'],
        ].map(([attr, alias]) => ({
          '@': alias ? { name: attr, alias: alias } : { name: attr },
        })),
        'link-entity': [
          {
            '@': {
              name: 'indskr_emailtemplate_contenttoken',
              from: 'indskr_emailtemplateid',
              to: 'indskr_emailtemplateid',
              'link-type': 'outer',
              alias: 'ae',
            },
            attribute: [
              {
                '@': {
                  name: 'indskr_contenttokenid',
                  alias: 'indskr_content_tokenid',
                },
              },
            ],
          },
          {
            '@': {
              name: 'indskr_trackchange',
              from: 'indskr_entityid',
              to: 'indskr_emailtemplateid',
              alias: 'tc',
              'link-type': 'inner',
            },
            attribute: [
              {
                '@': {
                  name: 'indskr_action',
                  alias: 'track_action',
                },
              },
            ],
            filter: [
              {
                '@': {
                  type: 'and',
                },
                condition: [
                  {
                    '@': {
                      attribute: 'createdon',
                      operator: 'last-x-hours',
                      value: delta,
                    },
                  },
                  {
                    '@': {
                      attribute: 'indskr_entityname',
                      operator: 'eq',
                      value: 'indskr_emailtemplate',
                    },
                  },
                ],
              },
            ],
            order: [
              {
                '@': {
                  attribute: 'createdon',
                  descending: 'true',
                },
              },
            ],
            'link-entity': [
              {
                '@': {
                  name: 'indskr_positiongroups',
                  from: 'indskr_positiongroupsid',
                  to: 'indskr_positiongroup',
                  'link-type': 'inner',
                },
                'link-entity': [
                  {
                    '@': {
                      name: 'indskr_indskr_positiongroups_position_n2n',
                      from: 'indskr_positiongroupsid',
                      to: 'indskr_positiongroupsid',
                      'link-type': 'inner',
                    },
                    'link-entity': [
                      {
                        '@': {
                          name: 'position',
                          from: 'positionid',
                          to: 'positionid',
                          'link-type': 'inner',
                        },
                        filter: [
                          {
                            '@': {
                              type: 'or',
                            },
                            condition: [
                              {
                                value: positionIds,
                                '@': {
                                  attribute: 'positionid',
                                  operator: 'in',
                                },
                              },
                              {
                                value: positionIds,
                                '@': {
                                  attribute: 'parentpositionid',
                                  operator: 'in',
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            '@': {
              name: 'indskr_indskr_emailtemplate_indskr_positiongro',
              from: 'indskr_emailtemplateid',
              to: 'indskr_emailtemplateid',
              'link-type': 'outer',
              alias: 'ap',
            },
            attribute: [
              {
                '@': {
                  name: 'indskr_positiongroupsid',
                  alias: 'positiongroupid',
                },
              },
            ],
          },
        ],
      }),
  },
  // #region
  therapeuticAreaByUserMapping: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_therapeuticarea">
                    <attribute name="indskr_name" />
                    <attribute name="indskr_therapeuticareaid" />
                    <order attribute="indskr_name" descending="false" />
                    <filter type="and">
                        <condition attribute="statecode" operator="eq" value="0" />
                    </filter>
                    <link-entity name="indskr_indskr_therapeuticarea_systemuser" from="indskr_therapeuticareaid" to="indskr_therapeuticareaid" visible="false" intersect="true">
                        <link-entity name="systemuser" from="systemuserid" to="systemuserid" alias="af">
                              <link-entity name="position" from="positionid" to="positionid" alias="pf" distinct="true">
                                <filter type="or">
                                    <filter type="and">
                                      <condition attribute="indskr_status" operator="eq" value="100000000" />
                                      <condition attribute="positionid" operator="in">{positionIDs}</condition>
                                    </filter>
                                    <filter type="and">
                                      <condition attribute="indskr_status" operator="eq" value="100000000" />
                                      <condition attribute="parentpositionid" operator="in">{positionIDs}</condition>
                                    </filter>
                                </filter>
                              </link-entity>
                        </link-entity>
                    </link-entity>
                  </entity>
                </fetch>
              `,
  ScientificActivity: {
    fetchScientificActivities: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_scientificplan">
              {planID}
              <attribute name="indskr_scientificplanid" />
              <attribute name="indskr_name" />
              <attribute name="createdon" />
              <attribute name="indskr_scientificplanmeetinggoals" />
              <attribute name="indskr_scientificplantotalmeetings" />
              <attribute name="indskr_scientificplanmessagegoals" />
              <attribute name="indskr_scientificplantotalmessages" />
              <attribute name="indskr_therapeuticareas" />
              <attribute name="statuscode" />
              <attribute name="statecode" />
              <attribute name="indskr_strategy" />
              <attribute name="overriddencreatedon" />
              <attribute name="indskr_startdate" />
              <attribute name="indskr_enddate" />
              <attribute name="modifiedon" />
              <attribute name="modifiedby" />
              <attribute name="createdby" />
              <attribute name="ownerid" />
              <order attribute="indskr_name" descending="false" />
              {deltaSyncFilter}
              <link-entity name="indskr_accountplanobjective" from="indskr_scientificplansid" to="indskr_scientificplanid" link-type="outer" alias="PO">
                  <attribute name="indskr_accountplanobjectiveid"/>
                  <attribute name="createdby" />
                  <attribute name="indskr_name" />
                  <attribute name="statuscode"/>
              </link-entity>
              <link-entity name="indskr_indskr_scientificplan_contact" from="indskr_scientificplanid" to="indskr_scientificplanid" link-type="outer" >
              <link-entity name="contact" from="contactid" to="contactid" link-type="outer" alias="contact">
                  <attribute name="contactid"/>
              </link-entity>
              </link-entity>
              <link-entity name="indskr_scientificplan_product" from="indskr_scientificplanid" to="indskr_scientificplanid" link-type="outer" >
              <link-entity name="product" from="productid" to="productid" link-type="outer" alias="product">
                  <attribute name="name"/>
                  <attribute name="productid"/>
                  <filter type="and">
                    <condition attribute="statecode" operator="eq" value="0" />
                  </filter>
              </link-entity>
              </link-entity>
              <link-entity name="indskr_scientificplan_therapeuticarea" from="indskr_scientificplanid" to="indskr_scientificplanid" link-type="outer" >
              <link-entity name="indskr_therapeuticarea" from="indskr_therapeuticareaid" to="indskr_therapeuticareaid" link-type="outer" alias="therapeutic">
                  <attribute name="indskr_name"/>
                  <attribute name="indskr_therapeuticareaid"/>
                  <filter type="and">
                    <condition attribute="statecode" operator="eq" value="0" />
                  </filter>
              </link-entity>
              </link-entity>
              <link-entity name='indskr_scientificplan_systemuser' from='indskr_scientificplanid' to='indskr_scientificplanid' link-type="outer" intersect="true" alias="sap_systemuser">
                <attribute name="systemuserid"/>
              </link-entity>
              <filter type="and">
                {publishedCondition}
                <condition entityname="sap_systemuser" attribute="systemuserid" operator="eq" value="{currentUserId}" />
              </filter>
          </entity>
        </fetch>`,
    fetchScientifcPlanLinkedEntity: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_scientificplan">
            {planID}
            <attribute name="indskr_scientificplanid" />
            <order attribute="indskr_name" descending="false" />
            {deltaSyncFilter}
            <link-entity name="indskr_indskr_scientificplan_indskr_iodocument" from="indskr_scientificplanid" to="indskr_scientificplanid" link-type="inner" >
              <link-entity name="indskr_iodocument" from="indskr_iodocumentid" to="indskr_iodocumentid" link-type="outer" alias="sap_document">
                  <attribute name="indskr_iodocumentid"/>
                  <attribute name="indskr_ckmdocumenturl"/>
                  <attribute name="indskr_ckmdocumentid"/>
                  <attribute name="indskr_ckmdocumenttype"/>
                  <attribute name="indskr_ckmthumbnailurl"/>
                  <attribute name="indskr_ckmtitle"/>
                  <attribute name="indskr_title"/>
                  <attribute name="createdon"/>
                  <attribute name="modifiedon"/>
                  <attribute name="indskr_availablefrom" />
                  <attribute name="indskr_availableuntil" />
                  <attribute name="statecode"/>
                  <attribute name="statuscode"/>
                  <attribute name="indskr_description"/>
                  <filter type="and">
                    <condition attribute="statecode" operator="eq" value="0" />
                  </filter>
              </link-entity>
            </link-entity>
            <link-entity name='indskr_scientificplan_systemuser' from='indskr_scientificplanid' to='indskr_scientificplanid' link-type="outer" intersect="true" alias="sap_systemuser">
              <attribute name="systemuserid"/>
            </link-entity>
            <filter type="and">
              {publishedCondition}
              <condition entityname="sap_systemuser" attribute="systemuserid" operator="eq" value="{currentUserId}" />
            </filter>
          </entity>
        </fetch>`,
    fetchScientificActivitiesByContact: `
        <fetch top="50">
          <entity name="indskr_scientificplan">
          <attribute name="indskr_scientificplanid" />
          <attribute name="indskr_name" />
          <attribute name="createdon" />
          <attribute name="indskr_scientificplanmeetinggoals" />
          <attribute name="indskr_scientificplantotalmeetings" />
          <attribute name="indskr_scientificplanmessagegoals" />
          <attribute name="indskr_scientificplantotalmessages" />
          <attribute name="indskr_therapeuticareas" />
          <attribute name="statuscode" />
          <attribute name="statecode" />
          <attribute name="indskr_strategy" />
          <attribute name="overriddencreatedon" />
          <attribute name="indskr_startdate" />
          <attribute name="indskr_enddate" />
          <attribute name="modifiedon" />
          <attribute name="modifiedby" />
          <attribute name="createdby" />
          <attribute name="ownerid" />
            <filter>
              <condition entityname="indskr_indskr_scientificplan_contact" attribute="contactid" operator="eq" value="{contactId}" />
              <condition attribute="statuscode" operator="in">
                <value>2</value>
                <value>548910003</value>
              </condition>
            </filter>
            <order attribute="indskr_startdate" />
            <link-entity name="indskr_indskr_scientificplan_contact" from="indskr_scientificplanid" to="indskr_scientificplanid" intersect="true" />
          </entity>
        </fetch>`,
    fetchUserByScientificPlans: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
          <entity name="systemuser">
            <attribute name="fullname" />
            <attribute name="systemuserid" />
            <order attribute="fullname" />
            <link-entity name="indskr_scientificplan_systemuser" from="systemuserid" to="systemuserid" intersect="true" alias="sap_user">
              <attribute name="indskr_scientificplanid" />
            </link-entity>
            {getUsersForTask}
          </entity>
        </fetch>`,
  },

  opportunityManagement: {
    fetchOpportunities: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="opportunity">
        <attribute name="name" />
        <attribute name="createdon"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="estimatedclosedate"/>
        <attribute name="estimatedvalue"/>
        <attribute name="actualvalue"/>
        <attribute name="purchaseprocess"/>
        <attribute name="indskr_accountplan"/>
        <attribute name="indskr_accountplanobjective"/>
        <attribute name="createdby"/>
        <attribute name="ownerid"/>
        <attribute name="opportunityid"/>
        <attribute name="indskr_externalid"/>
        <attribute name="indskr_country"/>
        <attribute name="pricelevelid"/>
        <attribute name="totalamount"/>
        <attribute name="discountpercentage"/>
        <attribute name="totalamount"/>
        <attribute name="isrevenuesystemcalculated"/>
        <attribute name="indskr_opportunitytype"/>
        <attribute name="indskr_opportunitysubtype"/>
        <attribute name="customerid"/>
        <attribute name="indskr_procedure"/>
        <attribute name="indskr_opportunityprogress"/>
        <attribute name="indskr_competitorsbusinessvalue"/>
        <attribute name="indskr_bpfschemaname"/>
        <attribute name="indskr_bpfinstanceid"/>
        <attribute name="indskr_systemrating" />
        <attribute name="indskr_sourceothers" />
        <attribute name="indskr_source" />
        <attribute name="opportunityratingcode" />
        <attribute name="indskr_affordability" />
        <attribute name="indskr_gemnongem" />
        <attribute name="indskr_isercalculatedonpp"/>
        <attribute name="indskr_progressstatus"/>
        <attribute name="indskr_allowstagemovementbyuser"/>
        <attribute name="indskr_allowbackwardstagemovement"/>
        <attribute name="indskr_parentopportunity"/>
        <attribute name="indskr_primaryspecialty"/>
        <attribute name="indskr_subspecialty"/>
        <attribute name="indskr_contact"/>
        <attribute name="indskr_primarytarget"/>
        <attribute name="indskr_stakeholdervalue"/>
        <attribute name="indskr_focusarea"/>
        
        {accountFilter}
        {opportunityIdFilter}
        <link-entity name='businessunit' from='businessunitid' to='owningbusinessunit' link-type="outer">
          <attribute name="businessunitid" alias="businessUnitId"/>
          <attribute name="name" alias="businessUnitFormatted"/>
        </link-entity>
        <link-entity name="systemuser" from="systemuserid" to="indskr_productmanager" link-type="outer" alias="productManager">
          <attribute name="systemuserid"/>
          <attribute name="lastname"/>
          <attribute name="firstname"/>
        </link-entity>
        <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
          <attribute name="record2roleid" alias="roleid"/>
          <attribute name="statecode" alias="rolestatus"/>
          <link-entity name="contact" from="contactid" to="record2id" alias='opportunityContacts' link-type="outer">
            <attribute name="contactid"/>
            <attribute name="lastname"/>
            <attribute name="firstname"/>
            <attribute name="statuscode"/>
          </link-entity>
          <link-entity name="systemuser" from="systemuserid" to="record2id" alias='opportunityCollaborators' link-type="outer">
            <attribute name="systemuserid"/>
            <attribute name="lastname"/>
            <attribute name="firstname"/>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_opportunitystakeholders" to="opportunityid" from="indskr_opportunityid"  alias='opportunityStakeholderValues' link-type="outer">
            <attribute name="indskr_stakeholder" />
            <attribute name="indskr_value"/>
          </link-entity>
        <link-entity name="transactioncurrency" from="transactioncurrencyid" to="transactioncurrencyid" link-type="outer">
          <attribute name="currencysymbol" alias="transactioncurrencysymbol"/>
        </link-entity>
        <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
          <link-entity name="systemuser" from="systemuserid" to="record2id" alias='oc' link-type="outer">
          </link-entity>
        </link-entity>
        <filter type="or">
          <condition attribute="ownerid" operator="eq-userid" />
          <condition attribute="systemuserid" entityname="oc" operator="eq-userid" />
        </filter>
        {deltaSyncFilter}
        <link-entity name="opportunityclose" from="regardingobjectid" to="opportunityid" link-type="outer">
          <attribute name="description" alias="closeDescriptionText"/>
          <attribute name="createdon" alias="closedDate"/>
          <link-entity name="indskr_opportunityreason" from="indskr_opportunityreasonid" to="indskr_reason" link-type="outer">
            <attribute name='indskr_reason' alias="opportunityReason"/>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_opportunity_indskr_opportunityreason" from="opportunityid" to="opportunityid" link-type="outer" >
          <link-entity name="indskr_opportunityreason" from="indskr_opportunityreasonid" to="indskr_opportunityreasonid" link-type="outer" alias="opportunityReasons">
            <attribute name='indskr_reason'/>
			      <attribute name='indskr_opportunityreasonid'/>
          </link-entity>
        </link-entity>
      </entity>
      </fetch>`,
      a: `
      <fetch version="1.0" mapping="logical" returntotalrecordcount="true" page="1" count="4" no-lock="false">
        <entity name="opportunity">
          <attribute name="name" />
          <attribute name="createdon"/>
          <link-entity name="indskr_opportunitystakeholders" to="opportunityid" from="indskr_opportunityid" >
            <attribute name="indskr_stakeholder" />
            <attribute name="indskr_value"/>
            <filter type="and">
              <condition attribute="indskr_opportunityid" operator="eq" uitype="opportunity" value="8e6b5f6c-942b-ea11-a810-000d3af45c52"/>
            </filter>
          </link-entity>
        </entity>
      </fetch>
      `,
    fetchConfiguredAttributesForSurveyJSInfo: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="opportunity">
        {ATTRIBUTES}
        <filter type="and">
        <condition attribute="opportunityid" operator="eq" value="{opportunityId}" />
      </filter>
      </entity>
    </fetch>`,
    fetchOpportunitiesProductsAndNotes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="opportunity">
        <attribute name="opportunityid"/>
        {accountFilter}
        {opportunityIdFilter}
        <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
          <link-entity name="systemuser" from="systemuserid" to="record2id" alias='oc' link-type="outer">
          </link-entity>
        </link-entity>
        <filter type="or">
          <condition attribute="createdby" operator="eq-userid" />
          <condition attribute="systemuserid" entityname="oc" operator="eq-userid" />
        </filter>
        {deltaSyncFilter}
        <link-entity name="indskr_opportunity_product" from="opportunityid" to="opportunityid" link-type="outer" alias="opportunityProductCategories">
          <attribute name="productid"/>
          <link-entity name="product" from="productid" to="productid" link-type="outer">
            <attribute name="name" alias="opportunityProductCategories.productName"/>
            <attribute name="indskr_competitorproduct" alias="opportunityProductCategories.isCompetitorProduct"/>
          </link-entity>
        </link-entity>
        <link-entity name="opportunityproduct" from="opportunityid" to="opportunityid" link-type="outer" alias="opportunityProducts">
            <attribute name="priceperunit"/>
            <attribute name="opportunityproductname"/>
            <attribute name="quantity"/>
            <attribute name="extendedamount"/>
            <attribute name="indskr_productcategory"/>
            <attribute name="uomid"/>
            <attribute name="productid" alias="opportunityProducts.opportunityproductid"/>
        </link-entity>
        <link-entity name="annotation" from="objectid" to="opportunityid" link-type="outer" alias="opportunityNotes">
            <attribute name="annotationid"/>
            <attribute name="createdon"/>
            <attribute name="notetext"/>
            <attribute name="ownerid"/>
            <attribute name="isdocument"/>
            <attribute name="filename"/>
            <attribute name="filesize"/>
            <attribute name="mimetype"/>
        </link-entity>
        <link-entity name="activitypointer" from="regardingobjectid" to="opportunityid" link-type="outer" alias="opportunityActivities">
            <attribute name="activityid"/>
            <attribute name="subject"/>
            <attribute name="scheduledend"/>
            <attribute name="statecode"/>
            <attribute name="activitytypecode"/>
            <attribute name="ownerid"/>
            <filter type="and">
              <condition attribute="activitytypecode" operator="in">
                <value>4201</value>
              </condition>
              <condition attribute="createdby" operator="ne-userid"/>
            </filter>
        </link-entity>
        <link-entity name="task" from="indskr_opportunityid" to="opportunityid" link-type="outer" alias="opportunityTasks">
            <attribute name="activityid"/>
            <attribute name="subject"/>
            <attribute name="scheduledend"/>
            <attribute name="statecode"/>
            <attribute name="activitytypecode"/>
            <attribute name="ownerid"/>
            <filter type="and">
              <condition attribute="createdby" operator="ne-userid"/>
            </filter>
        </link-entity>
      </entity>
      </fetch>`,
    fetchOpportunitiesCompetitors: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="opportunity">
        <attribute name="opportunityid"/>
        {accountFilter}
        {opportunityIdFilter}
        <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
          <link-entity name="systemuser" from="systemuserid" to="record2id" alias='oc' link-type="outer">
          </link-entity>
        </link-entity>
        <filter type="or">
          <condition attribute="createdby" operator="eq-userid" />
          <condition attribute="systemuserid" entityname="oc" operator="eq-userid" />
        </filter>
        {deltaSyncFilter}
        <link-entity name="opportunitycompetitors" intersect="true" visible="false" to="opportunityid" from="opportunityid" link-type="outer">
          <link-entity name="competitor" from="competitorid" to="competitorid" alias="opportunityCompetitors" link-type="outer">
        		<attribute name="competitorid"/>
        		<attribute name="name"/>
       		</link-entity>
        </link-entity>
      </entity>
      </fetch>`,
    fetchOpportunitiesMarketingPlans: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="opportunity">
      <attribute name="opportunityid"/>
      {accountFilter}
      {opportunityIdFilter}
      <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
        <link-entity name="systemuser" from="systemuserid" to="record2id" alias='oc' link-type="outer">
        </link-entity>
      </link-entity>
      <filter type="or">
        <condition attribute="createdby" operator="eq-userid" />
        <condition attribute="systemuserid" entityname="oc" operator="eq-userid" />
      </filter>
      {deltaSyncFilter}
      <link-entity name="indskr_brandplan_opportunity" from="opportunityid" to="opportunityid" visible="false" intersect="true">
        <link-entity name="indskr_brandplan" from="indskr_brandplanid" to="indskr_brandplanid" alias="brandPlans" link-type="inner">
          <attribute name="indskr_brandplanid"/>
          <attribute name="indskr_name"/>
          <attribute name="statecode" />
          <attribute name="statuscode" />
         </link-entity>
      </link-entity>
    </entity>
    </fetch>`,
    fetchQuotes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="opportunity">
        <attribute name="opportunityid"/>
        <link-entity name="account" from="accountid" to="customerid" link-type="inner" alias="linkedAcc">
          {accountFilter}
          <attribute name="accountid"/>
          <attribute name="name"/>
        </link-entity>
        <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
          <link-entity name="systemuser" from="systemuserid" to="record2id" alias='oc' link-type="outer">
          </link-entity>
        </link-entity>
        <filter type="or">
          <condition attribute="createdby" operator="eq-userid" />
          <condition attribute="systemuserid" entityname="oc" operator="eq-userid" />
        </filter>
        <link-entity name="quote" from="opportunityid" to="opportunityid" link-type="inner" alias="quotes">
            {deltaSyncFilter}
            <attribute name="quoteid"/>
            <attribute name="quotenumber"/>
            <attribute name="statuscode"/>
            <attribute name = "statecode"/>
            <attribute name = "effectivefrom"/>
            <attribute name = "effectiveto"/>
            <attribute name = "totalamount"/>
            <attribute name = "indskr_countryid"/>
            <attribute name="pricelevelid" />
            <attribute name="transactioncurrencyid" />
            <attribute name = "createdby"/>
            <link-entity name="quotedetail" from="quoteid" to="quoteid" link-type="outer" alias="quoteProducts">
              <attribute name="productid"/>
              <attribute name="productname"/>
              <attribute name="priceperunit"/>
              <attribute name="quantity"/>
              <attribute name="extendedamount"/>
            </link-entity>
        </link-entity>
      </entity>
      </fetch>`,
    fetchAgreements: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="opportunity">
        <attribute name="opportunityid"/>
        <link-entity name="account" from="accountid" to="customerid" link-type="inner" alias="linkedAcc">
          {accountFilter}
          <attribute name="accountid"/>
          <attribute name="name"/>
        </link-entity>
        <link-entity name='connection' from='record1id' to='opportunityid' link-type="outer">
          <link-entity name="systemuser" from="systemuserid" to="record2id" alias='oc' link-type="outer">
          </link-entity>
        </link-entity>
        <filter type="or">
          <condition attribute="createdby" operator="eq-userid" />
          <condition attribute="systemuserid" entityname="oc" operator="eq-userid" />
        </filter>
        <link-entity name="indskr_indskr_agreement_opportunity" from="opportunityid" to="opportunityid" link-type="inner">
          <link-entity name="indskr_agreement" from="indskr_agreementid" to="indskr_agreementid" link-type="inner" alias="agreements">
              {deltaSyncFilter}
              <attribute name="indskr_agreementid"/>
              <attribute name="statecode"/>
              <attribute name="indskr_name"/>
              <attribute name="indskr_url"/>
          </link-entity>
        </link-entity>
      </entity>
      </fetch>`,
    fetchBusinessUnits: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="businessunit">
          <attribute name="businessunitid" alias="businessunitid"/>
          <attribute name="name" alias="businessunitname"/>
        </entity>
      </fetch>`,
    fetchCollaborators: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="systemuser">
          <attribute name="systemuserid" />
          <attribute name="fullname" />
          <attribute name="firstname" />
          <attribute name="lastname" />
          <order attribute="fullname" descending="false" />
          <filter type="and">
            <condition attribute="isdisabled" operator="eq" value="0" />
            <condition attribute="fullname" operator="like" value="%{{query}}%" />
            <condition attribute="fullname" operator="not-like" value="#%" />
            <condition attribute="accessmode" operator="ne" value="3" />
            <condition attribute="accessmode" operator="ne" value="5" />
          </filter>
        </entity>
      </fetch>`,
    fetchOpportunityTypes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_opportunitytype">
        <attribute name="indskr_opportunitytypeid"/>
        <attribute name="indskr_name"/>
        <attribute name="indskr_description"/>
        <order attribute="indskr_name" descending="false"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0"/>
        </filter>
        <link-entity name="indskr_assessmentbutoopptype" from="indskr_opportunitytype" to="indskr_opportunitytypeid" link-type="inner">
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0"/>
            <condition attribute="indskr_businessunit" operator="eq" value="{userBU}"/>
          </filter>
        </link-entity>
        <link-entity name="indskr_configurationopportunitybpf" from="indskr_opportunitytype" to="indskr_opportunitytypeid" link-type="outer" alias="opportunityBpfConfig">
          <attribute name="indskr_estimatedrevenueautocalculated"/>
          <attribute name="indskr_actualrevenueautocalculated"/>
          <attribute name="indskr_estimatedrevenuecalculationbasedon"/>
          <attribute name="indskr_actualrevenuecalculationbasedon"/>
          <attribute name="indskr_productdisplay"/>
          <attribute name="indskr_ordercreation"/>
          <attribute name="indskr_ordercreationprocess"/>
          <attribute name="indskr_nostageprogressafterdays"/>
          <attribute name="indskr_singlestakeholder"/>
          <attribute name="indskr_productmanager" />
          <attribute name="indskr_focusarea" />
        </link-entity>
        <link-entity name="indskr_expertinquirytemplate_indskr_opp" from="indskr_opportunitytypeid" to="indskr_opportunitytypeid" link-type="outer" alias="opportunitySurveyTemplate">
          <attribute name="indskr_expertinquirytemplateid"/>

          <link-entity name="indskr_associatebuandmobileapptemplates" to="indskr_expertinquirytemplateid" from="indskr_mobileapptemplates" link-type="outer" alias="opportunitySurveyTemplateBU">
            <attribute name="indskr_businessunit"/>
            <filter type="and">
          		<condition attribute="indskr_businessunit" operator="eq" value="{userBU}"/>
       	  	</filter>
		      </link-entity>

        </link-entity>
      </entity>
    </fetch>`,
    fetchOpportunitySubTypes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="indskr_opportunitytype">
          <attribute name="indskr_opportunitytypeid"/>
          <attribute name="indskr_name"/>
          <order attribute="indskr_name" descending="false"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0"/>
          </filter>
          <link-entity name="indskr_opportunitysubtype_indskr_opport"  visible="false" to="indskr_opportunitytypeid" from="indskr_opportunitytypeid">
            <link-entity name="indskr_opportunitysubtype" from="indskr_opportunitysubtypeid" to="indskr_opportunitysubtypeid">
              <attribute name="indskr_opportunitysubtypeid"/>
              <attribute name="indskr_name"/>
              <order attribute="indskr_name" descending="false"/>
              <filter type="and">
                <condition attribute="statecode" operator="eq" value="0"/>
              </filter>
              <link-entity name="indskr_associationbuandoppsubtype" from="indskr_opportunitysubtype" to="indskr_opportunitysubtypeid">
                <filter type="and">
                  <condition attribute="statecode" operator="eq" value="0"/>
                  <condition attribute="indskr_businessunit" operator="eq" value="{userBU}"/>
                </filter>
              </link-entity>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>`,
    fetchOpportunityCompetitors: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="competitor">
          <attribute name="name"/>
          <attribute name="competitorid"/>
          <order attribute="name" descending="false"/>
        </entity>
      </fetch>`,
    fetchOpportunityReasons: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_opportunityreason">
        <attribute name="indskr_opportunityreasonid" />
        <attribute name="indskr_statusreason" />
        <attribute name="createdon" />
        <attribute name='indskr_reason'/>
        <order attribute="indskr_reason" descending="false" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_configurationopportunitybpf_inds" from="indskr_opportunityreasonid" to="indskr_opportunityreasonid" visible="false" intersect="true">
          <link-entity name="indskr_configurationopportunitybpf" from="indskr_configurationopportunitybpfid" to="indskr_configurationopportunitybpfid" alias="as">
          <attribute name="indskr_opportunitytype"/>
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="indskr_associationbutobusinessprocessconfiguration" from="indskr_businessprocessconfiguration" to="indskr_configurationopportunitybpfid" link-type="inner" alias="at">
              <filter type="and">
                <condition attribute="indskr_businessunit" operator="eq-businessid" />
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>`,
    fetchOpportunityProductByPositions: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="product">
          <attribute name="productid" alias="categoryproductid"/>
          <attribute name="name" alias="categoryname"/>
          <attribute name="statuscode" alias="categorystatuscode"/>
          <attribute name="statecode" alias="categorystatecode"/>
          <attribute name="validfromdate" alias="categoryvalidfromdate"/>
          <attribute name="validtodate" alias="categoryvalidtodate"/>
          <attribute name="indskr_producttype" alias="categoryproducttype" />
          <attribute name="defaultuomid" alias="categoryuomid"/>
          <order attribute="name" descending="false" />
          <filter type="and">
            <condition attribute="indskr_producttype" operator="eq" value="548910000" />
            {deltaSyncFilter}
          </filter>
          <link-entity name="indskr_position_product" from="productid" to="productid" visible="false" intersect="true">
            <link-entity name="position" from="positionid" to="positionid" alias="ah">
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="positionid" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
          <link-entity name="indskr_product_product_child_parent" from="productidtwo" to="productid" link-type="inner">
            <link-entity name="product" from="productid" to="productidone" link-type="inner" alias="skuProduct">
              <attribute name="name"/>
              <attribute name="statecode"/>
              <attribute name="productid"/>
              <attribute name="defaultuomid"/>
              <attribute name="indskr_producttype" />
              <attribute name="indskr_newproductintroduction" />
              <filter type="and">
                <condition attribute="indskr_producttype" operator="eq" value="100000003"/>
              </filter>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>`,
    fetchProductsMappingDelta: `
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="indskr_trackchange">
          <attribute name="indskr_action"/>

          <order attribute='createdon' descending='false'/>

          <filter type="and">
            <condition attribute="createdon" operator="last-x-hours" value="{hourDifference}" />
            <condition attribute="indskr_entityname" operator="eq" value="product" />
          </filter>
          <link-entity name="indskr_position_product" from="productid" to="indskr_entityid" visible="false" intersect="true">
            <link-entity name="position" from="positionid" to="positionid" alias="ah">
              <filter type="and">
                <condition attribute="indskr_status" operator="eq" value="100000000" />
                <condition attribute="positionid" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
            <link-entity name="product" from="productid" to="productid">
              <attribute name="productid" alias="categoryproductid"/>
              <attribute name="name" alias="categoryname"/>
              <attribute name="statuscode" alias="categorystatuscode"/>
              <attribute name="statecode" alias="categorystatecode"/>
              <attribute name="validfromdate" alias="categoryvalidfromdate"/>
              <attribute name="validtodate" alias="categoryvalidtodate"/>
              <attribute name="indskr_producttype" alias="categoryproducttype" />
              <attribute name="defaultuomid" alias="categoryuomid"/>
              <order attribute="name" descending="false" />

              <filter type="and">
                <condition attribute="indskr_producttype" operator="eq" value="548910000" />
              </filter>

              <link-entity name="indskr_product_product_child_parent" from="productidtwo" to="productid" link-type="outer">
                <link-entity name="product" from="productid" to="productidone" link-type="outer" alias="skuProduct">
                  <attribute name="name"/>
                  <attribute name="statecode"/>
                  <attribute name="productid"/>
                  <attribute name="defaultuomid"/>
                  <attribute name="indskr_producttype" />
                  <attribute name="indskr_newproductintroduction" alias="indskr_newproductintroduction"/>
                  <filter type="and">
                    <condition attribute="indskr_producttype" operator="eq" value="100000003"/>
                  </filter>
                </link-entity>
              </link-entity>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>
    `,
    fetchActiveStageForOpportunity: `
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="{schemaName}">
          <attribute name="businessprocessflowinstanceid" />
          <attribute name="activestageid" />
          <attribute name="processid" />
          <filter type="and">
            <condition attribute="businessprocessflowinstanceid" operator="eq" value="{bpfinstanceid}"/>
          </filter>
        </entity>
      </fetch>
    `,
    fetchCustomerAssessmentRespForContact: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customerassessmentresponse" >
        <attribute name="indskr_customerassessmentresponseid"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="product" from="productid" to="indskr_procedures" link-type="inner" alias="aa">
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
        <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ab">
        <attribute name="indskr_customer" alias ="contactId" />
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
            <condition attribute="indskr_customer" operator="in">
            {contactIds}
            </condition>
          </filter>
        </link-entity>
      </entity>
    </fetch>
  `
  },
  configuredFormFetchXMLs: {
    fetchConfiguredFormEntity: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="{parentEntityName}">
        {parentLevelAttributes}
        {customFilterLevel1}
        {positionFilterlevel1}
        {linkEntityPlaceholder}
        {secondaryInfoPlaceholder}
        {deltasyncFilterLevel1}
      </entity>
    </fetch>`,
    fetchConfiguredFormEntityPaging: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" count="5000">
      <entity name="{parentEntityName}">
        {parentLevelAttributes}
        {customFilterLevel1}
        {positionFilterlevel1}
        {linkEntityPlaceholder}
        {secondaryInfoPlaceholder}
        {deltasyncFilterLevel1}
      </entity>
    </fetch>`,
    linkEntityPlaceholder: `<link-entity alias="{linkEntityAlias}" name="{linkEntityName}" from="{linkEntityAttr}" to="{prentEntityAttr}" link-type="inner">
      {linkEntityAttributes}
      {customFilterLevel2}
      {deltasyncFilterLevel2}
    </link-entity>`,
    positionFilterPlaceholder: ``,
    fetchSecondaryInfoConfiguration: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
              <entity name="indskr_secondaryinfoconfiguration">
                <attribute name="indskr_secondaryinfoconfigurationid" />
                <attribute name="indskr_name" />
                <attribute name="createdon" />
                <attribute name="modifiedon" />
                <attribute name="indskr_entityname" />
                <attribute name="indskr_secondaryinfoconfigdata" />
                <order attribute="modifiedon" descending="true" />
                <filter type="and">
                  <condition attribute="statecode" operator="eq" value="0" />
                  <condition attribute="indskr_entityname" operator="in">
                    <value>contact</value>
                    <value>account</value>
                    <value>appointment</value>
                    <value>salesorder</value>
                    <value>indskr_setbooking</value>
                  </condition>
                </filter>
                <link-entity name="indskr_secondary" from="indskr_secondaryinfoconfiguration" to="indskr_secondaryinfoconfigurationid" link-type="inner" >
                      <filter type="and">
                        <condition attribute="indskr_businessunit" operator="eq" value="{userBUId}" />
                        <condition attribute="statecode" operator="eq" value="0" uitype="indskr_secondary" />
                      </filter>
                      <link-entity name="role" alias="at" to="indskr_securityrole" from="roleid">
                        <link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">
                          <link-entity name="systemuser" alias="au" to="systemuserid" from="systemuserid">
                            <filter type="and">
                              <condition attribute="systemuserid" operator="eq" value="{systemUserId}" />
                            </filter>
                          </link-entity>
                        </link-entity>
                      </link-entity>
                </link-entity>
              </entity>
            </fetch>`,
  },
  eventCheckInFetchXMLs: {
    fetchEventType: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_event_type">
        <attribute name="indskr_event_typeid" />
        <attribute name="indskr_name" />
        <attribute name="statecode" />
        <attribute name="indskr_justificationmandatory" />
        <attribute name="indskr_passmandatory" />
        <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_eventsubtype" from="indskr_eventtype" to="indskr_event_typeid" link-type="outer">
          <attribute name="indskr_eventsubtypeid" />
          <attribute name="indskr_name" />
          <attribute name="statecode" />
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
      </entity>
    </fetch>`,
    //   fetchEventSubType:`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    //   <entity name="indskr_eventsubtype">
    //     <attribute name="indskr_eventsubtypeid" />
    //     <attribute name="indskr_name" />
    //     <attribute name="statecode" />
    //   </entity>
    // </fetch>`,
    fetchSpeaker: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="msevtmgt_speaker">
        <attribute name="msevtmgt_speakerid" />
        <attribute name="msevtmgt_name" />
        <attribute name="indskr_onboardedasspeaker" />
        <attribute name="statecode" />
        <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="contact" from="contactid" to="msevtmgt_contact" link-type="outer">
          <attribute name="contactid" />
        </link-entity>
        {deltaSyncFilter}
      </entity>
    </fetch>`,
  },
  edgeAnalytics: {
    fetchConfiguration: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_edgemeasures">
        <attribute name="indskr_edgemeasuresid" />
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <attribute name="modifiedon" />
        <attribute name="statecode" />

        <order attribute="indskr_name" descending="false" />
        <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
        </filter>

        <link-entity name="indskr_edgemeasuresrole" from="indskr_edgemeasure" to="indskr_edgemeasuresid" link-type="inner" alias="am">
            <attribute name="statecode" />
            <filter type="and">
                <condition attribute="indskr_businessunit" operator="eq-businessid" />
                <condition attribute="statecode" operator="eq" value="0" />
            </filter>

            <link-entity name="role" from="roleid" to="indskr_securityrole" link-type="inner" alias="an">
            <link-entity name="systemuserroles" from="roleid" to="roleid" visible="false" intersect="true">
                <link-entity name="systemuser" from="systemuserid" to="systemuserid" alias="ao">
                <filter type="and">
                    <condition attribute="systemuserid" operator="eq-userid" />
                </filter>
                </link-entity>
            </link-entity>
            </link-entity>
        </link-entity>
        <link-entity name="indskr_indskr_edgemeasures_indskr_measureboard" from="indskr_edgemeasuresid" to="indskr_edgemeasuresid" visible="false" intersect="true">
            <link-entity name="indskr_measureboardkpi" from="indskr_measureboardkpiid" to="indskr_measureboardkpiid" alias="mk" >
                <attribute name="indskr_name" />
                <attribute name="statecode" />

                <filter type="and">
                    <condition attribute="statecode" operator="eq" value="0" />
                </filter>
            </link-entity>
        </link-entity>
      </entity>
    </fetch>
    `,
fetchSalesOrderMeasuresByOwner: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_saleskpi">
  <attribute name="indskr_saleskpiid" />
  <attribute name="indskr_name" />
  <attribute name="createdon" />
  <attribute name="indskr_apnoap" />
  <attribute name="indskr_acapytm" />
  <attribute name="indskr_acapyrd" />
  <attribute name="indskr_expectedsalesremainingdays" />
  <attribute name="indskr_dailysales" />
  <attribute name="modifiedon" />
  <order attribute="indskr_name" descending="false" />
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    <condition attribute="ownerid" operator="eq-userid" />
  </filter>
</entity>
</fetch>
`,
  },
  surgeryOrders: {
    fetchNewProductsHierarchy: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="businessunit">
        <filter>
          <condition attribute="businessunitid" operator="eq" value="{userBU}"/>
        </filter>
        <link-entity name="indskr_businessunitproduct" from="indskr_businessunit" to="businessunitid" link-type="inner" alias="buproduct">
          <attribute name="statecode"/>
          <link-entity name="product" from="productid" to="indskr_product" link-type="outer" alias="surgery">
            {deltaSyncFilter}
            <attribute name="name"/>
            <attribute name="statecode"/>
            <attribute name="statuscode"/>
            <attribute name="productid"/>
            <link-entity name="indskr_product_product_child_parent" from="productidtwo" to="productid" link-type="inner">
              <link-entity name="product" from="productid" to="productidone" link-type="inner" alias="category">
                <attribute name="name"/>
                <attribute name="statecode"/>
                <attribute name="productid"/>
                <link-entity name="indskr_product_product_child_parent" from="productidtwo" to="productid" link-type="inner">
                  <link-entity name="product" from="productid" to="productidone" link-type="inner" alias="product">
                    <attribute name="name"/>
                    <attribute name="statecode"/>
                    <attribute name="productid"/>
                    <attribute name="defaultuomid"/>
                  </link-entity>
                </link-entity>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>`,
    fetchDeletedPositiongroupProductMapping: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_trackchange">
          <order attribute='createdon' descending='false'/>
          <attribute name="createdon" alias="track_action_CreatedOn"/>
          <attribute name="indskr_action" alias="track_action"/>
          <attribute name="indskr_entityid" alias="positiongroupproductID"/>
          <attribute name="indskr_additionalinfo" alias="track_action_info"/>
          <filter type="and">
            <condition attribute="createdon" operator="last-x-hours" value="{hourDifference}" />
            <condition attribute="indskr_entityname" operator="eq" value="indskr_positiongroupproduct" />
          </filter>
      </entity>
    </fetch>
    `,
    fetchProductHierarchy: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_positiongroups">
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_positiongroupsid" />
    <attribute name="statecode" />
    
    <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
      <link-entity name="position" from="positionid" to="positionid" alias="br">
      <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
        </filter>
        <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="bs">
        <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
          <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner" alias="bt">
            <filter type="and">
              <condition attribute="systemuserid" operator="eq" value="{UserID}" />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_positiongroupproduct" from="indskr_positiongroup" to="indskr_positiongroupsid" link-type="inner" alias="positiongroupProduct">
      <attribute name="statuscode"/>
      <attribute name="statecode"/>
      <attribute name="indskr_positiongroupproductid"/>
      {deltaSyncFilter}
      <link-entity name="product" from="productid" to="indskr_product" link-type="inner" alias="surgery">
        <attribute name="productid" />
        <attribute name="name" />
        <attribute name="productstructure" />
        <attribute name="statuscode" />
        <attribute name="statecode"/>
        <attribute name="validfromdate" />
        <attribute name="validtodate" />
        <attribute name="validtodate" />
        <attribute name="indskr_producttype" />
        <attribute name="indskr_bundletype" />
        <filter type="and">
          <condition attribute="productstructure" operator="eq" value="3" />
          <condition attribute="indskr_bundletype" operator="eq" value="548910000" />
        </filter>

        <link-entity name="indskr_indskr_expertinquirytemplate_product" link-type="outer" to="productid" from="productid" alias="surgeryTemplate">
          <attribute name="indskr_expertinquirytemplateid" />
          <link-entity name="indskr_associatebuandmobileapptemplates" to="indskr_expertinquirytemplateid" from="indskr_mobileapptemplates" link-type="outer" alias="surgeryBUTemplate">
             <link-entity name="indskr_expertinquirytemplate" from="indskr_expertinquirytemplateid" to="indskr_mobileapptemplates" link-type="outer" alias="surgeryTemplateAssets">
                <attribute name="indskr_tagassets" />
                <attribute name="statuscode" />
                <attribute name="statecode" />
             </link-entity>
             <filter type="and">
          		<condition attribute="indskr_businessunit" operator="eq" value="{userBU}"/>
       	  	</filter>
		      </link-entity>
        </link-entity>

        <link-entity name="productassociation" from="productid" to="productid" link-type="inner" alias="aw">
          <link-entity name="product" from="productid" to="associatedproduct" link-type="inner" alias="categoryProduct">
            <attribute name="productid" />
            <attribute name="name" />
            <attribute name="statuscode" />
            <attribute name="statecode"/>
            <attribute name="validfromdate" />
            <attribute name="validtodate" />
            <attribute name="indskr_producttype" />
            <attribute name="defaultuomid"/>
            <attribute name="indskr_newproductintroduction" />
            <filter type="and">
              <condition attribute="indskr_producttype" operator="in">
                <value>100000003</value>
                <value>548910000</value>
              </condition>
            </filter>
            <link-entity name="indskr_product_product_child_parent" from="productidtwo" to="productid" link-type="inner">
              <link-entity name="product" from="productid" to="productidone" link-type="inner" alias="skuProduct">
                <attribute name="name"/>
                <attribute name="statecode"/>
                <attribute name="productid"/>
                <attribute name="productnumber" />
                <attribute name="defaultuomid"/>
                <attribute name="indskr_producttype" />
                <attribute name="indskr_newproductintroduction" />
                <filter type="and">
                  <condition attribute="indskr_producttype" operator="eq" value="100000003"/>
                </filter>
                <order attribute="productid" descending="false" />
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
    </fetch>`,
    fetchProductHierarchySecond: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_positiongroups">
      <attribute name="indskr_name" />
      <order attribute="modifiedon" descending="false" />
      <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="br">
        <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
          </filter>
          <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="bs">
          <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner" alias="bt">
              <filter type="and">
                <condition attribute="systemuserid" operator="eq" value="{UserID}" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_positiongroupproduct" from="indskr_positiongroup" to="indskr_positiongroupsid" link-type="inner" alias="positiongroupProduct">
        {deltaSyncFilter1}
        <link-entity name="product" from="productid" to="indskr_product" link-type="inner" alias="surgery">
          <attribute name="productid" />
          <attribute name="name" />
          <attribute name="indskr_bundletype" />
          <filter type="and">
            <condition attribute="productstructure" operator="eq" value="3" />
            <condition attribute="indskr_bundletype" operator="eq" value="548910000" />
          </filter>

          <link-entity name="indskr_producthierarchy" to="productid" from="indskr_parentproduct" link-type="inner" alias="bb">
            {deltaSyncFilter2}
            <link-entity name="indskr_indskr_producthierarchy_product" to="indskr_producthierarchyid" link-type="outer" from="indskr_producthierarchyid" alias="pp">
              <link-entity name="product" to="productid" link-type="outer" from="productid" alias="subProducts">
                <attribute name="productid" />
                <attribute name="name" />
              </link-entity>
            </link-entity>
          </link-entity>

        </link-entity>
      </link-entity>
    </entity>
    </fetch>`,
    fetchSurgeryAndCategoryRelationship: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_positiongroups">
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_positiongroupsid" />
    <attribute name="statecode" />
    
    <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
      <link-entity name="position" from="positionid" to="positionid" alias="br">
      <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
        </filter>
        <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="bs">
        <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
          <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner" alias="bt">
            <filter type="and">
              <condition attribute="systemuserid" operator="eq" value="{UserID}" />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_positiongroupproduct" from="indskr_positiongroup" to="indskr_positiongroupsid" link-type="inner" alias="positiongroupProduct">
      <attribute name="statuscode"/>
      <attribute name="statecode"/>
      <attribute name="indskr_positiongroupproductid"/>
      {deltaSyncFilter}
      <link-entity name="product" from="productid" to="indskr_product" link-type="inner" alias="surgery">
        <attribute name="productid" />
        <attribute name="name" />
        <attribute name="productstructure" />
        <attribute name="statuscode" />
        <attribute name="statecode"/>
        <attribute name="validfromdate" />
        <attribute name="validtodate" />
        <attribute name="validtodate" />
        <attribute name="indskr_producttype" />
        <attribute name="indskr_bundletype" />
        <filter type="and">
          <condition attribute="productstructure" operator="eq" value="3" />
          <condition attribute="indskr_bundletype" operator="eq" value="548910000" />
        </filter>

        <link-entity name="indskr_indskr_expertinquirytemplate_product" link-type="outer" to="productid" from="productid" alias="surgeryTemplate">
          <attribute name="indskr_expertinquirytemplateid" />
          <link-entity name="indskr_associatebuandmobileapptemplates" to="indskr_expertinquirytemplateid" from="indskr_mobileapptemplates" link-type="outer" alias="surgeryBUTemplate">
             <link-entity name="indskr_expertinquirytemplate" from="indskr_expertinquirytemplateid" to="indskr_mobileapptemplates" link-type="outer" alias="surgeryTemplateAssets">
                <attribute name="indskr_tagassets" />
                <attribute name="statuscode" />
                <attribute name="statecode" />
             </link-entity>
             <filter type="and">
          		<condition attribute="indskr_businessunit" operator="eq" value="{userBU}"/>
       	  	</filter>
		      </link-entity>
        </link-entity>

        <link-entity name="productassociation" from="productid" to="productid" link-type="inner" alias="aw">
          <link-entity name="product" from="productid" to="associatedproduct" link-type="inner" alias="categoryProduct">
            <attribute name="productid" />
            <attribute name="name" />
            <attribute name="statuscode" />
            <attribute name="statecode"/>
            <attribute name="validfromdate" />
            <attribute name="validtodate" />
            <attribute name="indskr_producttype" />
            <attribute name="defaultuomid"/>
            <attribute name="indskr_newproductintroduction" />
            <filter type="and">
              <condition attribute="indskr_producttype" operator="in">
                <value>548910000</value>
              </condition>
            </filter>
            
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
    </fetch>`,
    fetchCategoryAndSKUProductRelationship: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_positiongroups">  
    <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
      <link-entity name="position" from="positionid" to="positionid" alias="br">
      <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
        </filter>
        <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="bs">
        <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
          <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner" alias="bt">
            <filter type="and">
              <condition attribute="systemuserid" operator="eq" value="{UserID}" />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_positiongroupproduct" from="indskr_positiongroup" to="indskr_positiongroupsid" link-type="inner" alias="positiongroupProduct">
      
      {deltaSyncFilter}
      <link-entity name="product" from="productid" to="indskr_product" link-type="inner" alias="surgery">
        
        <filter type="and">
          <condition attribute="productstructure" operator="eq" value="3" />
          <condition attribute="indskr_bundletype" operator="eq" value="548910000" />
        </filter>

        

        <link-entity name="productassociation" from="productid" to="productid" link-type="inner" alias="aw">
          <link-entity name="product" from="productid" to="associatedproduct" link-type="inner" alias="categoryProduct">
            <attribute name="productid" />
            <attribute name="name" />
            <attribute name="statuscode" />
            <attribute name="statecode"/>
            <attribute name="validfromdate" />
            <attribute name="validtodate" />
            <attribute name="indskr_producttype" />
            <attribute name="defaultuomid"/>
            <attribute name="indskr_newproductintroduction" />
            <filter type="and">
              <condition attribute="indskr_producttype" operator="in">
                <value>100000003</value>
                <value>548910000</value>
              </condition>
            </filter>
            <link-entity name="indskr_product_product_child_parent" from="productidtwo" to="productid" link-type="inner">
              <link-entity name="product" from="productid" to="productidone" link-type="inner" alias="skuProduct">
                <attribute name="name"/>
                <attribute name="statecode"/>
                <attribute name="productid"/>
                <attribute name="productnumber" />
                <attribute name="defaultuomid"/>
                <attribute name="indskr_producttype" />
                <attribute name="indskr_newproductintroduction" />
                <filter type="and">
                  <condition attribute="indskr_producttype" operator="eq" value="100000003"/>
                </filter>
                <order attribute="productid" descending="false" />
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
    </fetch>`,
    fetchDeletedMobileAppTemplateAndProductMapping: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_trackchange">
          <order attribute='createdon' descending='false'/>
          <attribute name="createdon" alias="track_action_CreatedOn"/>
          <attribute name="indskr_action" alias="track_action"/>
          <attribute name="indskr_entityid" alias="mobileapptemplateid"/>
          <attribute name="indskr_additionalinfo" alias="track_action_info"/>
          <attribute name="indskr_product" alias="productid"/>
          <filter type="and">
            <condition attribute="createdon" operator="last-x-hours" value="{hourDifference}" />
            <condition attribute="indskr_entityname" operator="eq" value="indskr_expertinquirytemplate" />
          </filter>
      </entity>
    </fetch>
    `,
    surgeryCategoryProductPositionRelationship: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="product" alias="surgery">
      <attribute name="name" />
      <attribute name="productid" />
      <link-entity name="indskr_positiongroupproduct" from="indskr_product" to="productid" link-type="inner" alias="ep">
        <link-entity name="indskr_positiongroups" from="indskr_positiongroupsid" to="indskr_positiongroup" link-type="inner" alias="eq">
          <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
            <link-entity name="position" from="positionid" to="positionid" alias="er">
              <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="es">
                <filter type="and">
                  <condition attribute="indskr_userid" operator="eq" value="{UserID}" />
                </filter>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="productassociation" from="productid" to="productid" link-type="inner" alias="et">
        <link-entity name="product" from="productid" to="associatedproduct" link-type="inner" alias="categoryProduct" >
          <attribute name="productid" />
          <attribute name="name" />
          <filter type="and">
            <condition attribute="indskr_producttype" operator="eq" value="548910000"/>
          </filter>
          <link-entity name="indskr_position_product" from="productid" to="productid" link-type="inner" alias="pp">
            <filter>
              <condition attribute="positionid" operator="in">
          {positionIds}
              </condition>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  allSurgeryCategoryProductPositionRelationship: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="product" alias="surgery">
      <attribute name="name" />
      <attribute name="productid" />
      <link-entity name="productassociation" from="productid" to="productid" link-type="inner" alias="et">
        <link-entity name="product" from="productid" to="associatedproduct" link-type="inner" alias="categoryProduct" >
          <attribute name="productid" />
          <attribute name="name" />
          <filter type="and">
            <condition attribute="indskr_producttype" operator="eq" value="548910000"/>
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    surgerySKUProductRelationship: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="product" alias="surgery">
        <attribute name="name" />
        <attribute name="productid" />
        <attribute name="productnumber" />
        {deltaSyncFilter}
        <link-entity name="indskr_positiongroupproduct" from="indskr_product" to="productid" link-type="inner" alias="ep">
          <link-entity name="indskr_positiongroups" from="indskr_positiongroupsid" to="indskr_positiongroup" link-type="inner" alias="eq">
            <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
              <link-entity name="position" from="positionid" to="positionid" alias="er">
                <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="es">
                  <filter type="and">
                    <condition attribute="indskr_userid" operator="eq" value="{UserID}" />
                  </filter>
                </link-entity>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
        <link-entity name="productassociation" from="productid" to="productid" link-type="inner" alias="et">
          <link-entity name="product" from="productid" to="associatedproduct" link-type="inner" alias="skuProduct" >
            <attribute name="productid" />
            <attribute name="name" />
            <attribute name="productnumber" />
            <filter type="and">
              <condition attribute="indskr_producttype" operator="eq" value="100000003"/>
            </filter>
            <order attribute="productid" descending="false" />
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
  `,
    fetchProductHierarchyProcedureTracker: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_positiongroups">
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="indskr_positiongroupsid" />
      <attribute name="statecode" />
      <order attribute="modifiedon" descending="false" />
      <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="br">
        <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
          </filter>
          <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="bs">
          <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner" alias="bt">
              <filter type="and">
                <condition attribute="systemuserid" operator="eq" value="{UserID}" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_positiongroupproduct" from="indskr_positiongroup" to="indskr_positiongroupsid" link-type="inner" alias="positiongroupProduct">
        <attribute name="statuscode"/>
        <attribute name="statecode"/>
        <attribute name="indskr_positiongroupproductid"/>
        {deltaSyncFilter}
        <link-entity name="product" from="productid" to="indskr_product" link-type="inner" alias="surgery">
          <attribute name="productid" />
          <attribute name="name" />
          <attribute name="productstructure" />
          <attribute name="statuscode" />
          <attribute name="statecode"/>
          <attribute name="validfromdate" />
          <attribute name="validtodate" />
          <attribute name="validtodate" />
          <attribute name="indskr_bundletype" />
          <filter type="and">
            <condition attribute="productstructure" operator="eq" value="3" />
            <condition attribute="indskr_bundletype" operator="eq" value="548910000" />
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    retiredRevisedBundleCategorySKUProduct: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="product">
    <attribute name="name" />
    <attribute name="productid" />
    <attribute name="productnumber" />
    <attribute name="description" />
    <attribute name="statecode" />
    <attribute name="statuscode" />
    <attribute name="productstructure" />
    <attribute name="indskr_producttype" />
    <filter type="and">
      <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
      <filter type="and">
        <condition attribute="statecode" operator="in">
          <value>1</value>
          <value>3</value>
        </condition>
        <filter type="or">
          <condition attribute="indskr_producttype" operator="in">
            <value>548910000</value>
            <value>100000003</value>
          </condition>
          <condition attribute="productstructure" operator="eq" value="3" />
        </filter>
      </filter>
    </filter>
  </entity>
</fetch>
  `,
    fetchConfiguredAttributesForSurveyJSInfo: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="salesorder">
        {ATTRIBUTES}
        <filter type="and">
        <condition attribute="salesorderid" operator="eq" value="{salesOrderId}" />
      </filter>
      </entity>
    </fetch>`,
    fetchActivityOutcomes: `
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="indskr_activityoutcome">
          <attribute name="indskr_activityoutcomeid"/>
          <attribute name="indskr_name"/>
          <attribute name="createdon"/>
          <attribute name="indskr_omnipresenceactivity"/>
          <attribute name="indskr_mandatory"/>
          <order attribute="indskr_name" descending="false"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
          <link-entity name="indskr_activityoutcomeroles" from="indskr_activityoutcome" to="indskr_activityoutcomeid" link-type="inner" alias="ad">
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="role" alias="at" to="indskr_securityrole" from="roleid">
              <link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">
                <link-entity name="systemuser" alias="au" to="systemuserid" from="systemuserid">
                  <filter type="and">
                    <condition attribute="systemuserid" operator="eq" value="{SystemUserId}" />
                  </filter>
                </link-entity>
              </link-entity>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>
    `,
  },
  salesOrders: {
    fetchTeamOrdersNotification: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="salesorder">
        <attribute name="name" />
        <attribute name="salesorderid" />
        <attribute name="statuscode" />
        <attribute name="ordernumber" />
        <attribute name="createdon" />
        <attribute name="customerid" />
        <order attribute="name" descending="false" />
        <filter type="and">
          <condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />
          <condition attribute="indskr_ordertype" operator="eq" value="548910000" />
          <condition attribute="statuscode" operator="eq" value="548910000" />
          <condition attribute="statecode" operator="eq" value="1" />
        </filter>
        <link-entity name="indskr_approvalactivity" from="indskr_order" to="salesorderid" link-type="inner" alias="aa">
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="548910000" />
          </filter>
          <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner" alias="ba">
                <filter type="and">
                	<condition attribute="ownerid" operator="eq" value="{userId}" />
              	</filter>
        	</link-entity>
        </link-entity>
      </entity>
    </fetch>
    `,
    validateAssetAssociation: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="salesorder">
        <attribute name="salesorderid" />
        <filter type="and">
          <condition attribute="indskr_scheduleddate" operator="on-or-after" value="{startDate}" />
          <condition attribute="indskr_scheduledenddate" operator="on-or-before" value="{endDate}" />
          <condition attribute="indskr_ordertype" operator="eq" value="548910001" />
          {orderIdCondition}
        </filter>
       <link-entity name="indskr_procedurelogcustomerasset" from="indskr_procedurelogid" to="salesorderid" link-type="inner" alias="al">
		      <link-entity name="msdyn_customerasset" from="msdyn_customerassetid" to="indskr_customerassetid" link-type="inner" alias="am">
		        <attribute name="msdyn_customerassetid" />
			      <filter type="and">
				      <condition attribute="msdyn_customerassetid" operator="in">
                {assetIds}
              </condition>
			      </filter>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
    `,
    fetchProcedureTypes : `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_proceduretype">
        <attribute name="indskr_proceduretypeid" />
        <attribute name="indskr_name" />
        <attribute name="indskr_procedurecontracttype" />
        <order attribute="indskr_name" descending="false" />
        <link-entity name="indskr_associationbutoprocedure" from="indskr_proceduretype" to="indskr_proceduretypeid" link-type="inner" alias="ac">
          <attribute name="indskr_isprocedurecontractenabled" />
          <attribute name="indskr_contracttype" />   
          <filter type="and">
            <condition attribute="indskr_businessunit" operator="eq-businessid" />
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
      <link-entity name="indskr_proceduresubtype" from="indskr_proceduretype" to="indskr_proceduretypeid" link-type="outer" alias="ah">
        <attribute name="indskr_proceduresubtypeid" />
          <link-entity name="indskr_associationbutoproceduresubtype" from="indskr_proceduresubtype" to="indskr_proceduresubtypeid" link-type="outer" alias="ad">
            <filter type="and">
              <condition attribute="indskr_businessunit" operator="eq-businessid" />
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
    `,
    fetchProcedureSubTypes:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_proceduresubtype">
        <attribute name="indskr_proceduresubtypeid" />
        <attribute name="indskr_name" />
        <attribute name="indskr_validity" />
        <attribute name="indskr_procedureallocated" />
        <attribute name="indskr_order" />
        <order attribute="indskr_name" descending="false" />
        <link-entity name="indskr_associationbutoproceduresubtype" from="indskr_proceduresubtype" to="indskr_proceduresubtypeid" link-type="inner" alias="am">
        <filter type="and">
          <condition attribute="indskr_businessunit" operator="eq-businessid" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      </link-entity>
      </entity>
    </fetch>
    `,
    fetchShoreiMaster:` <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="jnjjapan_shoreimaster">
    <attribute name="jnjjapan_shoreimasterid" />
    <attribute name="jnjjapan_name" />
    <attribute name="createdon" />
    <order attribute="jnjjapan_name" descending="false" />
    <link-entity name="jnjjapan_associationbutoshoreimaster" from="jnjjapan_shoreimaster" to="jnjjapan_shoreimasterid" link-type="inner" alias="aa">
      <filter type="and">
        <condition attribute="jnjjapan_businessunit" operator="eq-businessid"/>
      </filter>
    </link-entity>
  </entity>
</fetch>
    `,
    fetchProcedureContracts:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_procedurecontract">
      <attribute name="indskr_procedurecontractid" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="modifiedby" />
      <attribute name="indskr_enddates" />
      <attribute name="createdby" />
      <attribute name="indskr_account" />
      <attribute name="indskr_product" />
      <attribute name="indskr_startdates" />
      <attribute name="indskr_proceduretypes" />
      <attribute name="indskr_proceduresubtypes" />
      <attribute name="indskr_maximumnoofassistance" />
      <attribute name="indskr_noofassistanceavailed" />
      <attribute name="indskr_procedure" />
      <attribute name="indskr_validatornamepostsurgerysignature" />
      <attribute name="indskr_validatornamepresurgerysignature" />
      <attribute name="indskr_presurgerysignature" />
      <attribute name="indskr_postsurgerysignature" />
      <attribute name="indskr_postsurgeryguidelines" />
      <attribute name="indskr_presurgeryguidelines" />
      <attribute name="indskr_documentname" />
      <attribute name="indskr_presurgerysignaturecaptured" />
      <attribute name="indskr_postsurgerysignaturecaptured" />
      <attribute name="indskr_signaturecapturemode" />
      <attribute name="indskr_postsurgerysignaturecapturedate" />
      <attribute name="indskr_presurgerysignaturecapturedate" />
      <attribute name="indskr_lu_specialty" />
      <attribute name="indskr_address" />
      <attribute name="indskr_consenttaken" />
      <attribute name="indskr_complywithhospitalrulesandregulations" />
      <attribute name="statecode" />
      <attribute name="indskr_parentprocedurecontractid" />
      <attribute name="indskr_productid" />
      <attribute name="owningbusinessunit" />
      <attribute name="indskr_contracttypes" />
      <attribute name="statuscode" />
      <attribute name="indskr_contact" />
      <attribute name="indskr_presurgerysignaturedocument" />
      <attribute name="indskr_postsurgerysignaturedocument" />
      <order attribute="indskr_name" descending="false" />
      {configFields}
      <filter type="and">
        <filter type="or">
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="statuscode" operator="eq" value="548910004" />
        </filter>
        {deltaSyncFilter}
        {fullSyncFilter}
        {realTimeFilter}
      </filter>
      <link-entity name="indskr_associationbutoprocedurecontract" from="indskr_procedurecontract" to="indskr_procedurecontractid" link-type="inner" alias="ag">
        <filter type="and">
          <condition attribute="indskr_businessunits" operator="eq-businessid" />
        </filter>
      </link-entity>
    </entity>
  </fetch> `,
  fetchCancelReasons:`
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_opportunityreason">
    <attribute name="indskr_opportunityreasonid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_statusreason" />
    <attribute name="indskr_status" />
    <attribute name="indskr_reason" />
    <attribute name="indskr_activitytype" />
    <attribute name="statecode" />
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
      {deltaSyncFilter}
      {fullSyncFilter}
      <condition attribute="indskr_activitytype" operator="not-null" />
      <condition attribute="indskr_activitytype" operator="in">
        {activityIds}
      </condition>
    </filter>
  </entity>
</fetch>
  `,
  fetchContractTypes : `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_contracttype">
      <attribute name="indskr_contracttypeid" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="indskr_usagetype" />
      <attribute name="statecode" />
      <attribute name="indskr_presignature" />
      <attribute name="indskr_postsignature" />
      <attribute name="indskr_presignaturesectionname" />
      <attribute name="indskr_presurgerytext" />
      <attribute name="indskr_postsignaturesectionname" />
      <attribute name="indskr_postsurgerytext" />
      <attribute name="indskr_statusforprocedurelogapplicability" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
      
        {fullSyncFilter}
        {deltaSyncFilter}
      </filter>
      <link-entity name="indskr_indskr_contracttype_indskr_appfield" from="indskr_contracttypeid" to="indskr_contracttypeid" link-type="outer" alias="aa" intersect="true">
        <attribute name="indskr_appfieldid" />
      </link-entity>
    </entity>
  </fetch>
  `,
  fetchAssociatedSurgeryOrder:`
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="salesorder">
    <attribute name="indskr_scheduleddate" />
    <attribute name="ownerid" />
    <attribute name="name" />
    <attribute name="salesorderid" />
    <filter type="and">
      {condition}
    </filter>
    <link-entity name="indskr_procedurelogcoowner" from="indskr_orderid" to="salesorderid" link-type="outer" alias="ab">
      <link-entity name="systemuser" from="systemuserid" to="indskr_coownername" link-type="outer" alias="ac">
        <attribute name="fullname" />
      </link-entity>
    </link-entity>
  </entity>
  </fetch>
  `,
  fetchProcedureContractPositionGroupProducts:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_positiongroups">
        <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
          <link-entity name="position" from="positionid" to="positionid" alias="br">
          <filter type="and">
              <condition attribute="indskr_status" operator="eq" value="100000000" />
            </filter>
            <link-entity name="indskr_userposition" from="indskr_positionid" to="positionid" link-type="inner" alias="bs">
            <filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>
              <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner" alias="bt">
                <filter type="and">
                  <condition attribute="systemuserid" operator="eq" value="{UserID}" />
                </filter>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>

        <link-entity name="indskr_positiongroupproduct" from="indskr_positiongroup" to="indskr_positiongroupsid" link-type="inner" alias="positiongroupproduct">
          <attribute name="indskr_positiongroupproductid" />
          <attribute name="statecode" />
          <filter type="and">
            {deltaSyncFilter}
            <condition attribute="indskr_type" operator="eq" value="548910002" />
          </filter>
          <link-entity name="product" from="productid" to="indskr_product" link-type="inner" alias="product">
            <attribute name="productid" />
            <attribute name="name" />
            <attribute name="statecode" />
            <attribute name="productnumber" />
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
  `,
},
  customNotifications: {
    fetchcustomNotifications: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_customnotifications">
    <attribute name="indskr_customnotificationsid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
   <attribute name="indskr_description" />
    <attribute name="indskr_date" />
    <attribute name="indskr_channel" />
    <attribute name="indskr_department" />
    <attribute name="indskr_customnotificationsid" />
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
    <condition attribute="createdon" operator="last-x-days" value="30" />
    {deltaSyncFilter}
   </filter>
    <link-entity name="contact" from="contactid" to="indskr_customer" link-type="inner" alias="af">
       <attribute name="contactid" alias="contactId"/>
       <attribute name="firstname" alias="contactFirstName"/>
       <attribute name="lastname" alias="contactLastName"/>
      <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner" alias="ag">
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ah">
          <filter type="and">
          <condition attribute="positionid" operator="in">{positionIDs}</condition>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`
  },
  hyperlinkFetchXMLs: {
    fetchConfiguration: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_hyperlink">
        <attribute name="indskr_hyperlinkid" />
        <attribute name="indskr_lable" />
        <attribute name="indskr_name" />
        <attribute name="indskr_url" />
        <attribute name="statecode" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="indskr_businessunit" operator="eq" value="{BUID}" />
        </filter>
        <link-entity name="role" alias="at" to="indskr_securityrole" from="roleid">
          <link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">
            <link-entity name="systemuser" alias="au" to="systemuserid" from="systemuserid">
              <filter type="and">
                <condition attribute="systemuserid" operator="eq" value="{0}" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>`,
  },
  solicitationTracking: {
    unsolicitedMeetingsData: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="contact">
      <attribute name="fullname" />
      <attribute name="contactid" />
      <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner">
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
          <filter type="and">
          <condition attribute="positionid" operator="in">
            {positionIDs}
          </condition>
          </filter>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_activitycontact" from="indskr_contactid" to="contactid" link-type="inner">
        <link-entity name="appointment" from="activityid" to="indskr_appointmentid" link-type="inner" alias="meeting">
          <attribute name="activityid"/>
          <attribute name="scheduledend"/>
          <attribute name="indskr_hcpinteraction"/>
          <order attribute="scheduledend" descending="false" />
          <filter type="and">
            <condition attribute="indskr_hcpinteraction" operator="eq" value="548910001" />
            <condition attribute="scheduledend" operator="last-x-days" value="{unsolicitedAllowedPeriod}" />
            <condition attribute="ownerid" operator="eq-userid" />
            <condition attribute="statecode" operator="eq" value="1" />
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  },
  followupActions: {
    planType: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_followupactiontype">
    <attribute name="indskr_followupactiontypeid"/>
    <attribute name="indskr_name" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_plantype" />
    <attribute name="ownerid" />
    <attribute name="indskr_description" />
    <order attribute="indskr_name" descending="false" />
    {deltaSyncFilter}
    <link-entity name="indskr_associationbuandfollowupactiontype" from="indskr_followupactiontype" to="indskr_followupactiontypeid" link-type="inner">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0"/>
		    <condition attribute="indskr_businessunit" operator="eq" value="{userBu}"/>
      </filter>
    </link-entity>
  </entity>
</fetch>`,
  },
  callPlans: {
    myPositionCallPlans:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customercallplan">
    <filter type='and'>
    <condition attribute="indskr_isrepcallplan" operator="eq" value="1"/>
    {modifiedOnFilter}
      <filter type='or'>
        <filter type='and'>
          <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-after' value='{endDate}'/>
          <condition attribute='indskr_startdate' operator='on-or-before' value='{endDate}'/>
        </filter>
        <filter type='and'>
          <condition attribute='indskr_startdate' operator='on-or-before' value='{startDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-after' value='{startDate}'/>
        </filter>
        <filter type='and'>
          <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
        </filter>
      </filter>
    </filter>
        <attribute name="indskr_customercallplanid"/>
        <attribute name="indskr_name"/>
        <attribute name="createdon"/>
        <attribute name="modifiedon"/>
        <attribute name="indskr_startdate"/>
        <attribute name="indskr_enddate"/>
        <attribute name="indskr_hocalls"/>
        <attribute name="indskr_actualcalls" />
        <attribute name="indskr_meetingcompletionpercent"/>
        <attribute name="indskr_hoemails"/>
        <attribute name="indskr_actualemails" />
        <attribute name="indskr_emailcompletionpercent"/>
        <attribute name="indskr_state"/>
        <attribute name="statecode"/>
        <attribute name="statuscode" />
        <attribute name="indskr_productid" alias="productid"/>
        <attribute name="indskr_segment_v2" alias="segmentid"/>
        <attribute name="indskr_suggestedcalls" />
        <attribute name="indskr_suggestedemails" />
        <attribute name="indskr_suggestedf2fmeetings" />
        <attribute name="indskr_suggestedremotemeetings" />
        <attribute name="indskr_suggestedphonecallmeetings" />
        <attribute name="indskr_commentsforcalls"/>
        <attribute name="indskr_commentsforemails"/>
        <attribute name="indskr_commentsforf2fmeetings"/>
        <attribute name="indskr_commentsforremotemeetings"/>
        <attribute name="indskr_commentsforphonecallmeetings"/>
        <attribute name="indskr_isrepcallplan" />
        <attribute name="indskr_f2fgoals"/>
        <attribute name="indskr_remotemeetinggoals"/>
        <attribute name="indskr_phonecallgoals"/>
        <attribute name="indskr_parentcustomercallplanid" alias="customerPlanId"/>
        <attribute name="indskr_emailcompletionpercent"/>
        <attribute name="indskr_remotemeetingcompletion"/>
        <attribute name="indskr_f2fmeetingcompletion"/>
        <attribute name="indskr_phonecallscompletion" />
        <attribute name="indskr_completedf2fmeetings" alias="totalf2fmeetingscompleted"/>
        <attribute name="indskr_completedremotemeetings" alias="totalremotemeetingscompleted"/>
        <attribute name="indskr_completedphonecall" alias="totalphonecallscompleted"/>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
          <attribute name="positionid" alias="positionId"/>
          <attribute name="name" alias="positionName"/>
          <attribute name="parentpositionid" alias="parentposid"/>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="{positionAttributeIdentifier}" operator="in">
              {positionIDs}
            </condition>
          </filter>
        </link-entity>
        <link-entity name="contact" from="contactid" to="indskr_customerid" link-type="inner">
          <attribute name="contactid" alias="customerId"/>
          <attribute name="contactid" alias="contactId"/>
          <attribute name="firstname" alias="contactFirstName"/>
          <attribute name="lastname" alias="contactLastName"/>
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
              <filter type="and">
                <condition attribute="{positionAttributeIdentifier}" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_cycleplan" from="indskr_cycleplanid" to="indskr_cycleplanid" link-type="inner">
          {segmentPlanFilter}
          <attribute name="indskr_cycleplanid" alias="cycleplanid"/>
          <attribute name="indskr_name" alias="cycleplanname"/>
          <attribute name="indskr_engagementcalculation" alias="cycleplanengagementcalculation"/>
          <attribute name="indskr_manualchannels" alias="cycleplanmanualchannels"/>
          <attribute name="indskr_suggestionapprovals" alias="cycleplansuggestionapprovalneeded"/>
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="100000001" />
          </filter>
        </link-entity>
      </entity>
    </fetch>
    `,
    otherCoveragePositionCallPlans:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customercallplan">
        <filter type='and'>
        {modifiedOnFilter}
          <filter type='or'>
            <filter type='and'>
              <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-after' value='{endDate}'/>
              <condition attribute='indskr_startdate' operator='on-or-before' value='{endDate}'/>
            </filter>
            <filter type='and'>
              <condition attribute='indskr_startdate' operator='on-or-before' value='{startDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-after' value='{startDate}'/>
            </filter>
            <filter type='and'>
              <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
            </filter>
          </filter>
        </filter>

        {segmentPlanFilter}
        <link-entity name="indskr_cycleplan" from="indskr_cycleplanid" to="indskr_cycleplanid" link-type="inner">
        <attribute name="indskr_cycleplanid"/>
        <attribute name="indskr_name" alias="cycleplanname"/>
        <attribute name="indskr_engagementcalculation" alias="cycleplanengagementcalculation"/>
        <attribute name="indskr_manualchannels" alias="cycleplanmanualchannels"/>
        <attribute name="indskr_suggestionapprovals" alias="cycleplansuggestionapprovalneeded"/>
        </link-entity>

        <attribute name="indskr_cycleplanid" alias="cycleplanid"/>
        <attribute name="indskr_customercallplanid"/>
        <attribute name="indskr_name"/>
        <attribute name="indskr_meetingcompletionpercent"/>
        <attribute name="indskr_emailcompletionpercent"/>
        <attribute name="indskr_state"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_parentcustomercallplanid" alias="customerPlanId"/>
        <attribute name="modifiedon"/>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
          <attribute name="positionid" alias="positionId"/>
          <attribute name="name" alias="positionName"/>
          <attribute name="parentpositionid" alias="parentposid"/>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="{positionAttributeIdentifier}" operator="not-in">
              {positionIDs}
            </condition>
          </filter>
        </link-entity>
        <link-entity name="product" from="productid" to="indskr_productid" link-type="outer">
          <link-entity name="indskr_position_product" from="productid" to="productid" link-type="outer">
            <link-entity name="position" from="positionid" to="positionid" link-type="outer">
              <filter type="and">
                <condition attribute="{positionAttributeIdentifier}" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
        <link-entity name="contact" from="contactid" to="indskr_customerid" link-type="inner">
          <attribute name="contactid" alias="customerId"/>
          <attribute name="firstname" alias="contactFirstName"/>
          <attribute name="lastname" alias="contactLastName"/>
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
              <filter type="and">
                <condition attribute="{positionAttributeIdentifier}" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
    `,
    myPositionCallPlansAccounts:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customercallplan">
    <filter type='and'>
    <condition attribute="indskr_isrepcallplan" operator="eq" value="1"/>
    {modifiedOnFilter}
      <filter type='or'>
        <filter type='and'>
          <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-after' value='{endDate}'/>
          <condition attribute='indskr_startdate' operator='on-or-before' value='{endDate}'/>
        </filter>
        <filter type='and'>
          <condition attribute='indskr_startdate' operator='on-or-before' value='{startDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-after' value='{startDate}'/>
        </filter>
        <filter type='and'>
          <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
          <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
        </filter>
      </filter>
    </filter>
        <attribute name="indskr_customercallplanid"/>
        <attribute name="indskr_name"/>
        <attribute name="createdon"/>
        <attribute name="modifiedon"/>
        <attribute name="indskr_startdate"/>
        <attribute name="indskr_enddate"/>
        <attribute name="indskr_hocalls"/>
        <attribute name="indskr_actualcalls" />
        <attribute name="indskr_meetingcompletionpercent"/>
        <attribute name="indskr_hoemails"/>
        <attribute name="indskr_actualemails" />
        <attribute name="indskr_emailcompletionpercent"/>
        <attribute name="indskr_state"/>
        <attribute name="statecode"/>
        <attribute name="statuscode" />
        <attribute name="indskr_productid" alias="productid"/>
        <attribute name="indskr_segment_v2" alias="segmentid"/>
        <attribute name="indskr_suggestedcalls" />
        <attribute name="indskr_suggestedemails" />
        <attribute name="indskr_suggestedf2fmeetings" />
        <attribute name="indskr_suggestedremotemeetings" />
        <attribute name="indskr_suggestedphonecallmeetings" />
        <attribute name="indskr_commentsforcalls"/>
        <attribute name="indskr_commentsforemails"/>
        <attribute name="indskr_commentsforf2fmeetings"/>
        <attribute name="indskr_commentsforremotemeetings"/>
        <attribute name="indskr_commentsforphonecallmeetings"/>
        <attribute name="indskr_isrepcallplan" />
        <attribute name="indskr_f2fgoals"/>
        <attribute name="indskr_remotemeetinggoals"/>
        <attribute name="indskr_phonecallgoals"/>
        <attribute name="indskr_parentcustomercallplanid" alias="customerPlanId"/>
        <attribute name="indskr_emailcompletionpercent"/>
        <attribute name="indskr_remotemeetingcompletion"/>
        <attribute name="indskr_f2fmeetingcompletion"/>
        <attribute name="indskr_phonecallscompletion" />
        <attribute name="indskr_completedf2fmeetings" alias="totalf2fmeetingscompleted"/>
        <attribute name="indskr_completedremotemeetings" alias="totalremotemeetingscompleted"/>
        <attribute name="indskr_completedphonecall" alias="totalphonecallscompleted"/>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
          <attribute name="positionid" alias="positionId"/>
          <attribute name="name" alias="positionName"/>
          <attribute name="parentpositionid" alias="parentposid"/>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="{positionAttributeIdentifierLevel1}" operator="in">
              {positionIDsLevel1}
            </condition>
          </filter>
        </link-entity>
        <link-entity name="account" from="accountid" to="indskr_customerid" link-type="inner">
          <attribute name="accountid" alias="customerId"/>
          <attribute name="accountid" alias="accountId"/>
          <attribute name="name" alias="accountName"/>          
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
              <filter type="and">
                <condition attribute="{positionAttributeIdentifierLevel1}" operator="in">
                  {positionIDsLevel1}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_cycleplan" from="indskr_cycleplanid" to="indskr_cycleplanid" link-type="inner">
          {segmentPlanFilter}
          <attribute name="indskr_cycleplanid" alias="cycleplanid"/>
          <attribute name="indskr_name" alias="cycleplanname"/>
          <attribute name="indskr_engagementcalculation" alias="cycleplanengagementcalculation"/>
          <attribute name="indskr_manualchannels" alias="cycleplanmanualchannels"/>
          <attribute name="indskr_suggestionapprovals" alias="cycleplansuggestionapprovalneeded"/>
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="100000001" />
          </filter>
        </link-entity>
      </entity>
    </fetch>
    `,
    otherCoveragePositionCallPlansAccounts:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customercallplan">
        <filter type='and'>
        {modifiedOnFilter}
          <filter type='or'>
            <filter type='and'>
              <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-after' value='{endDate}'/>
              <condition attribute='indskr_startdate' operator='on-or-before' value='{endDate}'/>
            </filter>
            <filter type='and'>
              <condition attribute='indskr_startdate' operator='on-or-before' value='{startDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-after' value='{startDate}'/>
            </filter>
            <filter type='and'>
              <condition attribute='indskr_startdate' operator='on-or-after' value='{startDate}'/>
              <condition attribute='indskr_enddate' operator='on-or-before' value='{endDate}'/>
            </filter>
          </filter>
        </filter>

        {segmentPlanFilter}
        <link-entity name="indskr_cycleplan" from="indskr_cycleplanid" to="indskr_cycleplanid" link-type="inner">
        <attribute name="indskr_cycleplanid"/>
        <attribute name="indskr_name" alias="cycleplanname"/>
        <attribute name="indskr_engagementcalculation" alias="cycleplanengagementcalculation"/>
        <attribute name="indskr_manualchannels" alias="cycleplanmanualchannels"/>
        <attribute name="indskr_suggestionapprovals" alias="cycleplansuggestionapprovalneeded"/>
        </link-entity>

        <attribute name="indskr_cycleplanid" alias="cycleplanid"/>
        <attribute name="indskr_customercallplanid"/>
        <attribute name="indskr_name"/>
        <attribute name="indskr_meetingcompletionpercent"/>
        <attribute name="indskr_emailcompletionpercent"/>
        <attribute name="indskr_state"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_parentcustomercallplanid" alias="customerPlanId"/>
        <attribute name="modifiedon"/>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
          <attribute name="positionid" alias="positionId"/>
          <attribute name="name" alias="positionName"/>
          <attribute name="parentpositionid" alias="parentposid"/>
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute="{positionAttributeIdentifier}" operator="not-in">
              {positionIDs}
            </condition>
          </filter>
        </link-entity>
        <link-entity name="product" from="productid" to="indskr_productid" link-type="outer">
          <link-entity name="indskr_position_product" from="productid" to="productid" link-type="outer">
            <link-entity name="position" from="positionid" to="positionid" link-type="outer">
              <filter type="and">
                <condition attribute="{positionAttributeIdentifier}" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
        <link-entity name="account" from="accountid" to="indskr_customerid" link-type="inner">
          <attribute name="accountid" alias="customerId"/>
          <attribute name="name" alias="accountName"/>
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner">
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
              <filter type="and">
                <condition attribute="{positionAttributeIdentifier}" operator="in">
                  {positionIDs}
                </condition>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
    `
  },
  oneKey: {
    specialityById:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="omnione_onekeycodeslabels">
        <attribute name="omnione_onekeycodeslabelsid" />
        <attribute name="omnione_name" />
        <attribute name="omnione_isocodebase" />
        <filter type="and">
          <condition attribute="omnione_name" operator="eq" value="{specialityName}" />
        </filter>
        <filter type="or">
          <condition attribute="omnione_schemafieldname" operator="eq" value="omnione_lu_specialty" />
          <condition attribute="omnione_schemafieldname" operator="eq" value="omnione_lu_specialties" />
        </filter>
      </entity>
    </fetch>
    `,
    addressMetaData:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_lu_country">
        <attribute name="indskr_countryname" />
        <attribute name="indskr_lu_countryid" alias="countryId" />
        <filter type="and">
          <condition attribute="indskr_countrycodealpha2" operator="eq" value="{countryCode}" />
        </filter>
        <link-entity name="indskr_lu_state" from="indskr_lu_countryid" to="indskr_lu_countryid" link-type="inner" alias="ap">
          <attribute name="indskr_lu_stateid" alias="stateId" />
          <filter type="and">
            <condition attribute="indskr_state" operator="eq" value="{stateName}" />
          </filter>
          <link-entity name="indskr_lu_city" from="indskr_state" to="indskr_lu_stateid" link-type="inner" alias="aq">
            <attribute name="indskr_lu_cityid" alias="cityId" />
            <filter type="and">
              <condition attribute="indskr_name" operator="eq" value="{cityName}" />
            </filter>
            <link-entity name="indskr_lu_postalcode" from="indskr_city" to="indskr_lu_cityid" link-type="inner" alias="ar">
              <attribute name="indskr_lu_postalcodeid" alias="postalCode" />
              <filter type="and">
                <condition attribute="indskr_postalcode" operator="eq" value="{postalCode}" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
    `
  },
  recommendedEngagementPeriodByContactId: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_rec_engagement_period">
    <attribute name="indskr_time_slot"/>
    <attribute name="indskr_day_of_week"/>
    <attribute name="indskr_customer"/>
    <attribute name="indskr_score"/>
      <filter type="and">
      <condition attribute="indskr_customer" operator="eq" uitype="contact" value="{contactId}" />
      </filter>
  </entity>
</fetch>
`,
  address: {
    preferredAddress:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_positionpreferredaddress">
        <attribute name="indskr_positionpreferredaddressid"/>
        <attribute name="indskr_name"/>
        <attribute name="indskr_customeraddress"/>
        <attribute name="indskr_customeraddressname"/>
        <attribute name="indskr_position"/>
          <filter>
            <condition attribute="indskr_position" operator="in">
              {PositionFilter}
            </condition>
          </filter>
        <attribute name="indskr_positionname"/>
        <filter type="and">
          <condition attribute="statecode" value="0" operator="eq"/>
          {deltaSyncFilter}
        </filter>
      </entity>
    </fetch>`
  },
  multilingual: {
    multilingual_lookup_entities: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_multilinguallookup">
        <attribute name="indskr_multilinguallookupid" />
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <attribute name="statecode" />
        <attribute name="modifiedon" />
        <attribute name="modifiedby" />
        <attribute name="indskr_istranslationrequired" />
        <attribute name="indskr_entityname" />
        <order attribute="indskr_name" descending="false" />
        <filter type="and">
          {DeltaSyncFilter}
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      </entity>
    </fetch>`,
    multilingual_lookup_labels: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_multilinguallookuplabels">
        <attribute name="indskr_multilinguallookuplabelsid" />
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <attribute name="indskr_translation" />
        <attribute name="statuscode" />
        <attribute name="statecode" />
        <attribute name="modifiedon" />
        <attribute name="modifiedonbehalfby" />
        <attribute name="indskr_languagecode" />
        <order attribute="indskr_name" descending="false" />
        <filter type="and">
          <condition attribute="indskr_languagecode" operator="eq" value="{languageCode}" />
          {DeltaSyncFilter}
        </filter>
      </entity>
    </fetch>`
  },
  duplicateProceureCheck:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="salesorder">
        <attribute name="salesorderid" />
        <filter type="and">
          <condition attribute="indskr_scheduleddate" operator="on-or-after" value="{startDate}" />
          <condition attribute="indskr_scheduledenddate" operator="on-or-before" value="{endDate}" />
          <condition attribute="indskr_ordertype" operator="eq" value="548910002" />
        </filter>
        <link-entity name="account" from="accountid" to="customerid" link-type="inner" alias="ac">
        <filter type="and">
          <condition attribute="accountid" operator="eq" value ="{accountId}"/>
        </filter>
      </link-entity>
    </entity>
  </fetch>`,
  userDateTimeSettingsByBusinessUnitId: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_userdatetimesettings">
    <attribute name="indskr_userdatetimesettingsid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_level" />
    <attribute name="indskr_businessunit" />
    <attribute name="indskr_timeformat" />
    <attribute name="indskr_dateformat" />
    <attribute name="createdon" />
    <attribute name="modifiedon" />
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
      <filter type="or">
        <condition attribute="indskr_level" operator="eq" value="548910000" />
        <condition attribute="indskr_businessunit" operator="eq" uitype="indskr_businessunit" value="{businessUnitId}" />
      </filter>
    </filter>
  </entity>
  </fetch>`,
  contactsToolSettingsByBusinessUnitId: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="businessunit">
    <attribute name="indskr_labelforthebutton" />
    <attribute name="indskr_baseurl" />
    <attribute name="indskr_flagtopasstheguidoftherecord" />
    <attribute name="indskr_language" />
    <filter>
      <condition attribute="businessunitid" operator="eq" value="{businessUnitId}" />
    </filter>
  </entity>
  </fetch>`,
  meetingContentReasons: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_meetingcontentreasons">
      <attribute name="indskr_meetingcontentreasonsid" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="statuscode" />
      <attribute name="statecode" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="indskr_indskr_meetingcontentreasons_indskr_pos" from="indskr_meetingcontentreasonsid" to="indskr_meetingcontentreasonsid" visible="false" intersect="true">
        <link-entity name="indskr_positiongroups" from="indskr_positiongroupsid" to="indskr_positiongroupsid" alias="av">
          <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
            <link-entity name="position" from="positionid" to="positionid" alias="aw">
              <filter type="and">
                <condition attribute="positionid" operator="in">
                {positionIDs}
                </condition>
                <condition attribute="indskr_status" operator="eq" value="100000000" />
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  businessUnitChannels: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_businessunitchannel">
    <attribute name="indskr_businessunitchannelid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="statecode" />
    <attribute name="statuscode" />
    <attribute name="indskr_businessunit" />
    <attribute name="indskr_buchannel" />
    <filter type="and">
      <condition attribute="indskr_businessunit" operator="eq-businessid" />
    </filter>
    {deltaSyncFilter}
  </entity>
</fetch>`,
  deletedBusinessunitChannelMappings: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_trackchange">
      <order attribute='createdon' descending='false'/>
      <attribute name="createdon" alias="track_action_CreatedOn"/>
      <attribute name="indskr_action" alias="track_action"/>
      <attribute name="indskr_entityid" alias="indskr_businessunitchannelid"/>
      <attribute name="indskr_additionalinfo" alias="track_action_info"/>
      <filter type="and">
        <condition attribute="createdon" operator="last-x-hours" value="{hourDifference}" />
        <condition attribute="indskr_entityname" operator="eq" value="indskr_businessunitchannel" />
        <condition attribute="indskr_action" operator="eq" value="548910001" />
      </filter>
  </entity>
</fetch>
`,
  fetchAccountManagerUsers: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="systemuser">
    <attribute name="fullname" />
    <attribute name="systemuserid" />
    <order attribute="fullname" descending="false" />
    <link-entity name="systemuserroles" from="systemuserid" to="systemuserid" visible="false" intersect="true">
      <link-entity name="role" from="roleid" to="roleid" alias="af">
        <filter type="and">
          <condition attribute="roleid" operator="in">
            {1}
          </condition>
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`,
  fetchMyAssets: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="msdyn_customerasset">
    <attribute name="createdon" />
    <attribute name="msdyn_product" />
    <attribute name="msdyn_parentasset" />
    <attribute name="msdyn_masterasset" />
    <attribute name="msdyn_account" />
    <attribute name="msdyn_name" />
    <attribute name="msdyn_customerassetid" />
    <attribute name="indskr_tobeassetowner" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_serialnumber" />
    <attribute name="indskr_remarks" />
    <attribute name="msdyn_registrationstatus" />
    <attribute name="overriddencreatedon" />
    <attribute name="indskr_productcode" />
    <attribute name="owningbusinessunit" />
    <attribute name="ownerid" />
    <attribute name="indskr_newlocationstartdate" />
    <attribute name="indskr_newlocationenddate" />
    <attribute name="indskr_newassetlocation" />
    <attribute name="modifiedon" />
    <attribute name="modifiedonbehalfby" />
    <attribute name="modifiedby" />
    <attribute name="msdyn_longitude" />
    <attribute name="msdyn_latitude" />
    <attribute name="msdyn_lastcommandsenttime" />
    <attribute name="msdyn_lastcommandsent" />
    <attribute name="msdyn_lastalerttime" />
    <attribute name="indskr_installdate" />
    <attribute name="indskr_functionallocation" />
    <attribute name="msdyn_functionallocation" />
    <attribute name="indskr_filedmaterialstatus" />
    <attribute name="msdyn_deviceid" />
    <attribute name="indskr_description" />
    <attribute name="indskr_customerconfirmation" />
    <attribute name="createdonbehalfby" />
    <attribute name="createdby" />
    <attribute name="indskr_contact" />
    <attribute name="msdyn_customerassetcategory" />
    <attribute name="indskr_assetscategory" />
    <attribute name="indskr_assetstatus" />
    <attribute name="indskr_approvalrequired" />
    <attribute name="msdyn_alertcount" />
    <attribute name="msdyn_alert" />
    <attribute name="indskr_acknowledgerequired" />
    <order attribute="createdon" descending="true" />
    <filter type="and">
      {DeltaCondition}
      {DeltaById}
    </filter>
  </entity>
</fetch>`,
  fetchTransferRequests: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_assettransfer">
    <attribute name="indskr_assettransferid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_typeoftransfer" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_source" />
    <attribute name="indskr_serialnumber" />
    <attribute name="indskr_requester" />
    <attribute name="indskr_rejectedby" />
    <attribute name="overriddencreatedon" />
    <attribute name="indskr_productcode" />
    <attribute name="owningbusinessunit" />
    <attribute name="ownerid" />
    <attribute name="indskr_newlocationstartdate" />
    <attribute name="indskr_newlocationenddate" />
    <attribute name="indskr_newlocation" />
    <attribute name="indskr_newassetowner" />
    <attribute name="modifiedon" />
    <attribute name="modifiedonbehalfby" />
    <attribute name="modifiedby" />
    <attribute name="indskr_dateandtimeofapproval" />
    <attribute name="indskr_dateandtimeofacknowledgement" />
    <attribute name="indskr_customerconfirmation" />
    <attribute name="indskr_customerasset" />
    <attribute name="indskr_currentlocation" />
    <attribute name="indskr_currentassetowner" />
    <attribute name="createdonbehalfby" />
    <attribute name="createdby" />
    <attribute name="indskr_contract" />
    <attribute name="indskr_assetname" />
    <attribute name="indskr_approver" />
    <attribute name="indskr_approvedoption" />
    <attribute name="indskr_acknowledgedby" />
    <attribute name="indskr_acknowledged" />
    <attribute name="indskr_contract" />
    <attribute name="indskr_assetstatus" />
    <attribute name="indskr_assetname" />
    <attribute name="indskr_assetcategory" />
    <order attribute="createdon" descending="true" />
    <filter type="and">
      <filter type="or">
        <condition attribute="createdby" operator="eq-userid" />
        <filter type="and">
          <condition attribute="indskr_newassetowner" operator="eq-userid" />
          <condition attribute="indskr_approvedoption" operator="eq" value="1" />
        </filter>
      </filter>
      {InitialSyncCondition}
      {DeltaCondition}
      {DeltaById}
    </filter>
  </entity>
</fetch>
  `,
  fetchLatestAssetTransfer: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" count="1">
  <entity name="indskr_assettransfer">
    <attribute name="indskr_assettransferid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_typeoftransfer" />
    <attribute name="statuscode" />
    <attribute name="statecode" />
    <attribute name="indskr_source" />
    <attribute name="indskr_serialnumber" />
    <attribute name="indskr_requester" />
    <attribute name="indskr_rejectedby" />
    <attribute name="overriddencreatedon" />
    <attribute name="indskr_productcode" />
    <attribute name="owningbusinessunit" />
    <attribute name="ownerid" />
    <attribute name="indskr_newlocationstartdate" />
    <attribute name="indskr_newlocationenddate" />
    <attribute name="indskr_newlocation" />
    <attribute name="indskr_newassetowner" />
    <attribute name="modifiedon" />
    <attribute name="modifiedonbehalfby" />
    <attribute name="modifiedby" />
    <attribute name="indskr_dateandtimeofapproval" />
    <attribute name="indskr_dateandtimeofacknowledgement" />
    <attribute name="indskr_customerconfirmation" />
    <attribute name="indskr_customerasset" />
    <attribute name="indskr_currentlocation" />
    <attribute name="indskr_currentassetowner" />
    <attribute name="createdonbehalfby" />
    <attribute name="createdby" />
    <attribute name="indskr_contract" />
    <attribute name="indskr_assetname" />
    <attribute name="indskr_approver" />
    <attribute name="indskr_approvedoption" />
    <attribute name="indskr_acknowledgedby" />
    <attribute name="indskr_acknowledged" />
    <attribute name="indskr_contract" />
    <attribute name="indskr_assetstatus" />
    <attribute name="indskr_assetname" />
    <attribute name="indskr_assetcategory" />
    <order attribute="createdon" descending="true" />
    <link-entity name="msdyn_customerasset" from="msdyn_customerassetid" to="indskr_customerasset" link-type="inner" alias="ae">
      <filter type="and">
        {DeltaCondition}
        {DeltaById}
      </filter>
    </link-entity>
  </entity>
</fetch>
  `,
  fetchAssetNotes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="annotation">
    <attribute name="subject" />
    <attribute name="notetext" />
    <attribute name="filename" />
    <attribute name="annotationid" />
    <attribute name="overriddencreatedon" />
    <attribute name="owningbusinessunit" />
    <attribute name="ownerid" />
    <attribute name="mimetype" />
    <attribute name="modifiedon" />
    <attribute name="modifiedonbehalfby" />
    <attribute name="modifiedby" />
    <attribute name="isdocument" />
    <attribute name="filesize" />
    <attribute name="createdon" />
    <attribute name="createdonbehalfby" />
    <attribute name="createdby" />
    <order attribute="subject" descending="false" />
    <link-entity name="msdyn_customerasset" from="msdyn_customerassetid" to="objectid" link-type="inner" alias="af">
      <attribute name="msdyn_customerassetid" alias="customerassetid" />
      <filter type="and">
        <condition attribute="modifiedon" operator="last-x-months" value="3" />
        {DeltaCondition}
        {DeltaById}
      </filter>
    </link-entity>
  </entity>
</fetch>`,
  fetchAssetTransferNotes: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="annotation">
    <attribute name="subject" />
    <attribute name="notetext" />
    <attribute name="filename" />
    <attribute name="annotationid" />
    <attribute name="overriddencreatedon" />
    <attribute name="owningbusinessunit" />
    <attribute name="ownerid" />
    <attribute name="mimetype" />
    <attribute name="modifiedon" />
    <attribute name="modifiedonbehalfby" />
    <attribute name="modifiedby" />
    <attribute name="isdocument" />
    <attribute name="filesize" />
    <attribute name="createdon" />
    <attribute name="createdonbehalfby" />
    <attribute name="createdby" />
    <link-entity name="indskr_assettransfer" from="indskr_assettransferid" to="objectid" link-type="inner" alias="af">
      <attribute name="indskr_assettransferid" alias="activityid" />
      <filter type="and">
        {DeltaCondition}
        {DeltaById}
      </filter>
      <filter type="or">
        <condition attribute="ownerid" operator="eq-userid" />
        <condition attribute="indskr_newassetowner" operator="eq-userid" />
      </filter>
    </link-entity>
  </entity>
</fetch>`,
  fetchAssetsForAssetBooking: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="msdyn_customerasset">
      <attribute name="msdyn_customerassetid" />
      <filter type="and">
        <condition entityname="ad" attribute="indskr_fromdate" operator="null"/>
        {buFilter}
      </filter>
      <link-entity name="indskr_assetbookingdetails" from="indskr_asset" to="msdyn_customerassetid" link-type="outer" alias="ad">
        <filter type="and">
          <filter type="and">
            <condition attribute="indskr_fromdate" operator="on-or-before" value="{fromDate}" />
            <condition attribute="indskr_todate" operator="on-or-after" value="{toDate}" />
          </filter>
          <filter type="or">
            <condition attribute="statuscode" operator="eq" value="1" />
            <condition attribute="statuscode" operator="eq" value="548910000" />
          </filter>
        </filter>
      </link-entity>
    </entity>
</fetch>`,
  fetchUnavailableAssetsForAssetBooking: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="msdyn_customerasset">
      <attribute name="msdyn_customerassetid" />
      <link-entity name="indskr_assetbookingdetails" from="indskr_asset" to="msdyn_customerassetid" link-type="inner" alias="ad">
        <filter type="and">
          <filter type="and">
            <condition attribute="indskr_fromdate" operator="on-or-before" value="{fromDate}" />
            <condition attribute="indskr_todate" operator="on-or-after" value="{toDate}" />
          </filter>
          <filter type="or">
            <condition attribute="statuscode" operator="eq" value="1" />
            <condition attribute="statuscode" operator="eq" value="548910000" />
          </filter>
        </filter>
      </link-entity>
    </entity>
</fetch>`
,
  fetchContracts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_assetcontract">
    <attribute name="indskr_assetcontractid" />
    <attribute name="indskr_name" />
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
      <condition attribute="indskr_startdate" operator="on-or-before" value="{startDate}" />
      <condition attribute="indskr_contractclosedate" operator="on-or-after" value="{endDate}" />
      <condition attribute="indskr_accountname" operator="eq" value="{newLocation}" />
      <condition attribute="statuscode" operator="in">
        <value>1</value>
        <value>548910005</value>
      </condition>
    </filter>
  </entity>
</fetch>`,
  fetchPastEventAttachments: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
   <entity name="msevtmgt_eventregistration">
      <attribute name="msevtmgt_name" />
      <attribute name="createdon" />
      <attribute name="msevtmgt_eventregistrationid" />
      <attribute name="msevtmgt_eventid" />
      <order attribute="msevtmgt_name" descending="false" />
      <filter type="and">
         <condition attribute="msevtmgt_contactid" operator="in">
            {0}
         </condition>
         <condition attribute="createdon" operator="last-x-days" value="{1}" />
      </filter>
      <link-entity name="annotation" from="objectid" to="msevtmgt_eventregistrationid" link-type="inner" alias="ab">
         <attribute name="annotationid" alias="annotationId" />
         <attribute name="filename" alias="fileName" />
         <filter type="and">
            <condition attribute="filename" operator="not-null" />
         </filter>
      </link-entity>
   </entity>
</fetch>`,
  fetchAnnotation: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="annotation">
    <attribute name="documentbody" />
    <attribute name="mimetype" />
    <attribute name="filename" />
    <attribute name="annotationid" />
    <order attribute="subject" descending="false" />
    <filter type="and">
      <condition attribute="annotationid" operator="eq" value="{0}" />
    </filter>
  </entity>
</fetch>`,
  fetchAllUsers: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="systemuser">
      <attribute name="fullname"/>
      <attribute name="systemuserid"/>
      <attribute name="isdisabled"/> 
      <attribute name="indskr_usertype"/>
      <attribute name="positionid" /> 
      <filter type="and">
        <condition attribute="islicensed" operator="eq" value="1"/>
        <condition attribute="isdisabled" operator="eq" value="0" />
        {deltaSyncCondition}
      </filter>
    </entity>
  </fetch>
  `,
fetchAllRepNameByPositionIds: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
<entity name="systemuser">
  <attribute name="positionid" alias="positionid"/>
  <attribute name="fullname"/>
  <filter type="and">
    <condition attribute="isdisabled" operator="eq" value="0"/>
  </filter>
  <link-entity name="indskr_position_systemuser" from="systemuserid" to="systemuserid" visible="false" intersect="true">
    <link-entity name="position" from="positionid" to="positionid" alias="ag">
      <filter type="and">
        <condition attribute="parentpositionid" operator="eq" value="{parentPositionID}"/>
        <condition attribute="indskr_status" operator="eq" value="100000000"/>
      </filter>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`,
  fetchMeetingAssetMappings: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_activitycustomerasset">
      <attribute name="indskr_activitycustomerassetid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_serialnumber" />
      <attribute name="createdon" />
      <attribute name="statuscode" />
      <attribute name="statecode" />
      <attribute name="overriddencreatedon" />
      <attribute name="ownerid" />
      <attribute name="modifiedon" />
      <attribute name="indskr_customerasset" />
      <attribute name="createdby" />
      <attribute name="indskr_appointment" />
      <attribute name="indskr_category" />
      <attribute name="indskr_product" />
      <attribute name="indskr_assetstatus" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
        <filter type="or">
          <condition attribute="ownerid" operator="eq-userid" />
          <condition attribute="indskr_user" operator="eq-userid" entityname="ab" />
        </filter>
        {DeltaCondition}
      </filter>
      <link-entity name="appointment" from="activityid" to="indskr_appointment" link-type="outer" alias="aa">
        <link-entity name="indskr_accompanieduser" from="indskr_appointment" to="activityid" link-type="outer" alias="ab"></link-entity>
      </link-entity>
    </entity>
  </fetch>
  `,
  fetchMeetingAssetsByActivityId: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_activitycustomerasset">
      <attribute name="indskr_activitycustomerassetid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_serialnumber" />
      <attribute name="createdon" />
      <attribute name="statuscode" />
      <attribute name="statecode" />
      <attribute name="overriddencreatedon" />
      <attribute name="ownerid" />
      <attribute name="modifiedon" />
      <attribute name="indskr_customerasset" />
      <attribute name="createdby" />
      <attribute name="indskr_appointment" />
      <attribute name="indskr_category" />
      <attribute name="indskr_product" />
      <attribute name="indskr_assetstatus" />
      <order attribute="indskr_name" descending="false" />
      <link-entity name="appointment" from="activityid" to="indskr_appointment" link-type="inner" alias="aa">
        <filter type="and">
          <condition attribute="activityid" operator="eq" value="{activityId}" />
        </filter>
      </link-entity>
    </entity>
  </fetch>
  `,
  fetchAccountPlansByMeetingId: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
	<entity name="indskr_activitymeeting">
		<attribute name="indskr_appointmentid" alias="activity" />
		<attribute name="indskr_accountplanid" alias="accountPlan" />
		<filter type="and">
		  <condition attribute="indskr_appointmentid" operator="eq" value="{0}" />
		</filter>
	</entity>
</fetch>
  `,
  quotes: {
    fetchQuotes:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="quote">
      <attribute name="name" />
      <attribute name="customerid" />
      <attribute name="statecode" />
      <attribute name="totalamount" />
      <attribute name="quoteid" />
      <attribute name="createdon" />
      <attribute name="totallineitemamount" />
      <attribute name="statuscode" />
      <attribute name="overriddencreatedon" />
      <attribute name="quotenumber" />
      <attribute name="discountpercentage" />
      <attribute name="pricelevelid" />
      <attribute name="ownerid" />
      <attribute name="indskr_order" />
      <attribute name="opportunityid" />
      <attribute name="modifiedby" />
      <attribute name="effectiveto" />
      <attribute name="effectivefrom" />
      <attribute name="expireson" />
      <attribute name="transactioncurrencyid" />
      <attribute name="indskr_countryid" />
      <attribute name="indskr_approvalstatus" />
      <attribute name="indskr_suggestedamount" />
      <attribute name="modifiedon" />
      <order attribute="name" descending="false" />
     <filter type="and">
        {ownerIdCondition}
        {deltaSyncCondition}
        {quoteIdCondition}
      </filter>
    </entity>
  </fetch>`,
    fetchPriceListandProducts: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="pricelevel">
        <attribute name="name" />
        <attribute name="transactioncurrencyid" />
        <attribute name="enddate" />
        <attribute name="begindate" />
        <attribute name="indskr_approvalstatus" />
        <attribute name="pricelevelid" />
        <attribute name="statecode" />
        <order attribute="name" descending="false" />
        <filter type="and">
        {deltaSyncCondition}
        <filter type="or">
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
            <condition attribute="enddate" operator="on-or-after" value="{todayValue}" />
            <condition attribute="enddate" operator="not-null" />
          </filter>
              <filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
                <condition attribute="enddate" operator="null" />
              </filter>
        </filter>
            </filter>

        <link-entity name="productpricelevel" from="pricelevelid" to="pricelevelid" link-type="inner" alias="products">
          <attribute name="productid"/>
          <attribute name="amount"/>
          <attribute name="uomid"/>
          <link-entity name="product" from="productid" to="productid" link-type="inner" alias="productname">
            <attribute name="name"/>
            <attribute name="statecode" />
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="indskr_position_product" from="productid" to="productid" visible="false" intersect="true">
              <link-entity name="position" from="positionid" to="positionid">
                <filter type="or">
                  <filter type="and">
                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                    <condition attribute="positionid" operator="in">
                      {positionIDs}
                    </condition>
                  </filter>
                  <filter type="and">
                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                    <condition attribute="parentpositionid" operator="in">
                      {positionIDs}
                    </condition>
                  </filter>
                </filter>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>`,
    fetchNotes: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="quote">
        <attribute name="quoteid" />
          <filter type="and">
            {ownerIdCondition}
            {quoteIdCondition}
            {deltaSyncCondition}
          </filter>
          {filterCondition}
        <link-entity name="annotation" from="objectid" to="quoteid" link-type="inner" alias="ae" >
          <attribute name="annotationid"/>
          <attribute name="subject" />
          <attribute name="notetext" />
          <attribute name="filename" />
          <attribute name="annotationid" />
          <attribute name="ownerid" />
          <attribute name="mimetype" />
          <attribute name="modifiedby" />
          <attribute name="isdocument" />
          <attribute name="filesize" />
          <attribute name="createdon" />
          <attribute name="createdby" />
        </link-entity>
      </entity>
    </fetch>`,
    fetchQuoteProduct:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="quote">
        <attribute name="quoteid" />
        <order attribute="name" descending="false" />
        <filter type="and">
          {ownerIdCondition}
          {deltaSyncCondition}
          {quoteIdCondition}
        </filter>
      <link-entity name="quotedetail" from="quoteid" to="quoteid" link-type="inner" alias="ac">
          <attribute name="productname" />
          <attribute name="quotedetailid" />
          <attribute name="baseamount"  />
          <attribute name="quantity"  />
          <attribute name="productid" />
        </link-entity>
      </entity>
    </fetch>`,
    fetchQuotesForApprovals: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="quote">
      <attribute name="name" />
      <attribute name="quoteid"/>
      <attribute name="createdon" />
      <attribute name="quotenumber" />
      <attribute name="statuscode" />
      <attribute name="customerid" />
      <order attribute="name" descending="false" />
      <filter type="or">
        <condition attribute="statuscode" operator="eq" value="9" />
        <condition attribute="statuscode" operator="eq" value="10" />
        <condition attribute="statuscode" operator="eq" value="11" />
      </filter>
      <link-entity name="indskr_approvalactivity" from="indskr_quotation" to="quoteid">
        <attribute name="indskr_approvalactivityid" alias="approvalActivityId"/>
        <attribute name="statuscode" alias="approvalStatus"/>
        <filter>
          <condition attribute="statuscode" operator="neq" value="1"/>
          <condition attribute="indskr_expiresin" operator="ge" value="{startDate}"/>
        </filter>
        <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver">
          <filter type="and">
            <condition attribute="ownerid" operator="eq-userid" />
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`
  },
  accounts: {
    fetchAccountPrivateTags:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                <entity name="indskr_usertag">
                  <attribute name="indskr_usertagid" />
                  <attribute name="indskr_name" />
                  <attribute name="createdon" />
                  <attribute name="statecode" />
                  <attribute name="ownerid" />
                  <attribute name="indskr_externalid" />
                  <attribute name= "indskr_taggedfor"/>
                  <attribute name="indskr_visibility" />
                  <attribute name="indskr_filter" />
                  <order attribute="indskr_name" descending="false" />
                  <filter type="and">
                    <condition attribute="ownerid" operator="eq-userid" />
                    <condition attribute="indskr_visibility" operator="neq" value="600000000" />
                    <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
                    <condition attribute="statecode" operator="eq" value="0" />
                </filter>
                  <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
                      <attribute name="accountid"  alias="accountId" />
                  </link-entity>
                </entity>
              </fetch>`,
    fetchAccountPublicTags: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                          <entity name="indskr_usertag">
                            <attribute name="indskr_usertagid" />
                            <attribute name="indskr_name" />
                            <attribute name="indskr_externalid" />
                            <attribute name="modifiedon" />
                            <attribute name="statecode" />
                            <attribute name= "indskr_taggedfor"/>
                            <attribute name="indskr_visibility" />
                            <attribute name="indskr_filter" />
                            <filter type="and">
                              <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
                              <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
                              <condition attribute="statecode" operator="eq" value="0" />
                          </filter>
                        <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
                            <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
                              <filter type="and">
                                <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
                              </filter>
                            </link-entity>
                          </link-entity>
                          <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
                          <attribute name="accountid"  alias="accountId"  />
                      </link-entity>
                          </entity>
                        </fetch>`,

    fetchAssignedAccountTags: `
 <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name= "indskr_taggedfor"/>
    <attribute name="indskr_visibility" />
    <attribute name="indskr_filter" />
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
      <condition attribute="indskr_visibility" operator="eq" value="600000000" />
      <condition attribute="indskr_source" operator="eq" value="600000000" />
      <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
    </filter>
    <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
      <filter type="and">
        <condition attribute="positionid" operator="in">
        {positionIDs}
        </condition>
      </filter>
    </link-entity>
    <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
      <attribute name="accountid"  alias="accountId"  />
      <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" alias="am">
        <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
          <filter type="and">
            <condition attribute="positionid" operator="in">
            {positionIDs}
            </condition>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
</fetch> `,     
fetchAssignedAccountTagsForBU: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_usertag">
<attribute name="indskr_usertagid" />
<attribute name="indskr_name" />
<attribute name="createdon" />
<attribute name= "indskr_taggedfor"/>
<attribute name="indskr_visibility" />
<attribute name="indskr_filter" />
<order attribute="indskr_name" descending="false" />
<filter type="and">
  <condition attribute="indskr_visibility" operator="eq" value="600000000" />
  <condition attribute="indskr_source" operator="eq" value="600000000" />
  <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
</filter>
<link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true" link-type="outer">
<filter type="and">
<condition attribute="indskr_bulevelconfigurationid" operator="in">
<value>{buId}</value>
</condition>
</filter>
</link-entity>
<link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
  <attribute name="accountid"  alias="accountId"  />
  <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" alias="am">
    <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
      <filter type="and">
        <condition attribute="positionid" operator="in">
        {positionIDs}
        </condition>
      </filter>
    </link-entity>
  </link-entity>
</link-entity>
</entity>
</fetch> `,  

    updatedAccountPrivateTags:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_usertag">
            <attribute name="indskr_usertagid" />
            <attribute name="indskr_name" />
            <attribute name="indskr_externalid" />
            <attribute name="modifiedon" />
            <attribute name="statecode" />
            <attribute name= "indskr_taggedfor"/>
            <attribute name="indskr_visibility" />
            <attribute name="indskr_filter" />
            <filter type="and">
              <condition attribute="ownerid" operator="eq-userid" />
              <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
              <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
            </filter>
            <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
              <attribute name="accountid"  alias="accountId" />
            </link-entity>
          </entity>
        </fetch>`,

    updatedAccoutPublicTags: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                    <entity name="indskr_usertag">
                      <attribute name="indskr_usertagid" />
                      <attribute name="indskr_name" />
                      <attribute name="indskr_externalid" />
                      <attribute name="modifiedon" />
                      <attribute name="statecode" />
                      <attribute name= "indskr_taggedfor"/>
                      <attribute name="indskr_visibility" />
                      <attribute name="indskr_filter" />
                      <filter type="and">
                        <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
                        <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
                        <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
                     </filter>
                  <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
                      <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
                        <filter type="and">
                          <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
                        </filter>
                      </link-entity>
                    </link-entity>
                    <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
                    <attribute name="accountid"  alias="accountId" />
                 </link-entity>
                    </entity>
                  </fetch>`,

   updatedAssignedAccountTags: `
                  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                     <entity name="indskr_usertag">
                     <attribute name="indskr_usertagid" />
                     <attribute name="indskr_name" />
                     <attribute name="createdon" />
                     <attribute name= "indskr_taggedfor"/>
                     <attribute name="indskr_visibility" />
                     <attribute name="indskr_filter" />
                     <order attribute="indskr_name" descending="false" />
                     <filter type="and">
                       <condition attribute="indskr_visibility" operator="eq" value="600000000" />
                       <condition attribute="indskr_source" operator="eq" value="600000000" />
                       <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
                       <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
                     </filter>
                     <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
                       <filter type="and">
                       <condition attribute="positionid" operator="in">
                       {positionIDs}
                       </condition>
                       </filter>
                     </link-entity>
                     <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
                     <attribute name="accountid"  alias="accountId"  />
                     <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" alias="am">
                      <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
                        <filter type="and">
                          <condition attribute="positionid" operator="in">
                          {positionIDs}
                          </condition>
                        </filter>
                      </link-entity>
                    </link-entity>
                 </link-entity>
                   </entity>
                 </fetch> `,
     updatedAssignedAccountTagsForBU: `
     <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
     <entity name="indskr_usertag">
     <attribute name="indskr_usertagid" />
     <attribute name="indskr_name" />
     <attribute name="createdon" />
     <attribute name= "indskr_taggedfor"/>
     <attribute name="indskr_visibility" />
     <attribute name="indskr_filter" />
     <order attribute="indskr_name" descending="false" />
     <filter type="and">
       <condition attribute="indskr_visibility" operator="eq" value="600000000" />
       <condition attribute="indskr_source" operator="eq" value="600000000" />
       <condition attribute="indskr_taggedfor" operator="eq" value="ACCOUNT" />
       <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
     </filter>
     <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true" link-type="outer">
     <filter type="and">
     <condition attribute="indskr_bulevelconfigurationid" operator="in">
     <value>{buId}</value>
     </condition>
     </filter>
     </link-entity>
     <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
     <attribute name="accountid"  alias="accountId"  />
     <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" alias="am">
      <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
        <filter type="and">
          <condition attribute="positionid" operator="in">
          {positionIDs}
          </condition>
        </filter>
      </link-entity>
    </link-entity>
 </link-entity>
   </entity>
 </fetch> `,
  customerAddress: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_indskr_customeraddress_v2">
      <attribute name="indskr_indskr_customeraddress_v2id" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="indskr_account_guid" />
      <order attribute="indskr_name" descending="false" />
      <attribute name="indskr_address" />
      <filter type="and">
        {deltaSyncFilter}
        {fullSyncFilter}
    </filter>
      <link-entity name="account" from="accountid" to="indskr_account_guid" link-type="inner" alias="ac">
      <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner">
        <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
            <filter type="and">
              <condition attribute="positionid" operator="in">
                {positionIDs}
            </condition>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`

  },

  presentation: {
    fetchPresentationPrivateTags: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
      <attribute name="indskr_usertagid" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="statecode" />
      <attribute name="ownerid" />
      <attribute name="indskr_externalid" />
      <attribute name= "indskr_taggedfor"/>
      <attribute name="indskr_visibility" />
      <attribute name="indskr_filter" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
        <condition attribute="ownerid" operator="eq-userid" />
        <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
        <condition attribute="statecode" operator="eq" value="0" />
    </filter>
<link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
<attribute name="indskr_iopresentationid" alias="presentationId"/> 
  </link-entity>     
    </entity>
  </fetch>`,

    fetchPresentationPublicTags: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_externalid" />
    <attribute name="modifiedon" />
    <attribute name="statecode" />
    <attribute name= "indskr_taggedfor"/>
    <attribute name="indskr_visibility" />
    <attribute name="indskr_filter" />
    <filter type="and">
      <condition attribute="indskr_visibility" operator="eq" value="548910001"/>
      <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
      <condition attribute="statecode" operator="eq" value="0" />
  </filter>
<link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
    <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
      <filter type="and">
        <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
<attribute name="indskr_iopresentationid" alias="presentationId" /> 
 </link-entity>
  </entity>
</fetch>`,
    fetchPresentationBuTags: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
      <attribute name="indskr_usertagid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_externalid" />
      <attribute name="modifiedon" />
      <attribute name="statecode" />
      <attribute name= "indskr_taggedfor"/>
      <attribute name="indskr_visibility" />
      <attribute name="indskr_filter" />
      <filter type="and">
        <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
        <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
        <condition attribute="statecode" operator="eq" value="0" />
    </filter>
  <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
      <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
        <filter type="and">
          <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
        </filter>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
  <attribute name="indskr_iopresentationid" alias="presentationId" /> 
   </link-entity>
    </entity>
  </fetch>`,
fetchAssignedAccountTagsForPosition: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_externalid" />
    <attribute name="modifiedon" />
    <attribute name="statecode" />
    <attribute name="indskr_taggedfor" />
    <attribute name="indskr_visibility" />
    <attribute name="indskr_filter" />
    <filter type="and">
      <condition attribute="indskr_visibility" operator="eq" value="600000000" />
      <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
      <filter type="and">
        <condition attribute="positionid" operator="in">
        {positionIDs}
        </condition>
      </filter>
    </link-entity>
    <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
      <attribute name="indskr_iopresentationid" alias="presentationId" /> 
    </link-entity>
       </entity>
     </fetch> `,            
    updatedPresentationPrivateTags: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_usertag">
            <attribute name="indskr_usertagid" />
            <attribute name="indskr_name" />
            <attribute name="indskr_externalid" />
            <attribute name="modifiedon" />
            <attribute name="statecode" />
            <attribute name= "indskr_taggedfor"/>
            <attribute name="indskr_visibility" />
            <attribute name="indskr_filter" />
            <filter type="and">
              <condition attribute="ownerid" operator="eq-userid" />
              <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
              <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
            </filter>
            <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
		        <attribute name="indskr_iopresentationid" alias="presentationId" /> 
              </link-entity>    
          </entity>
        </fetch>`,
    updatedAssignedPresTagsForPosition: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="indskr_usertag">
        <attribute name="indskr_usertagid" />
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <attribute name= "indskr_taggedfor"/>
        <attribute name="indskr_visibility" />
        <attribute name="indskr_filter" />
        <order attribute="indskr_name" descending="false" />
        <filter type="and">
          <condition attribute="indskr_visibility" operator="eq" value="600000000" />
          <condition attribute="indskr_source" operator="eq" value="600000000" />
          <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
          <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
        </filter>
        <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer">
        <attribute name="indskr_iopresentationid" alias="presentationId"/> 
        <link-entity name="indskr_customerposition" from="indskr_customerid" to="indskr_iopresentationid" alias="am">
         <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
           <filter type="and">
             <condition attribute="positionid" operator="in">
             {positionIDs}
             </condition>
           </filter>
         </link-entity>
       </link-entity>
    </link-entity>
      </entity>
    </fetch> `,
    updatedPresentationPublicTags: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="indskr_usertag">
          <attribute name="indskr_usertagid" />
          <attribute name="indskr_name" />
          <attribute name="indskr_externalid" />
          <attribute name="modifiedon" />
          <attribute name="statecode" />
          <attribute name= "indskr_taggedfor"/>
          <attribute name="indskr_visibility" />
          <attribute name="indskr_filter" />
          <filter type="and">
            <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
            <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
         </filter>
      <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
          <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
            <filter type="and">
              <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
            </filter>
          </link-entity>
        </link-entity>
       <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_iopresentationid" alias="presentationId" /> 
          </link-entity>
        </entity>
      </fetch> `,
      updatedPresentationBuTags: `
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_usertag">
        <attribute name="indskr_usertagid" />
        <attribute name="indskr_name" />
        <attribute name="indskr_externalid" />
        <attribute name="modifiedon" />
        <attribute name="statecode" />
        <attribute name= "indskr_taggedfor"/>
        <attribute name="indskr_visibility" />
        <attribute name="indskr_filter" />
        <filter type="and">
        <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
        <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
        <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
      </filter>
    <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
        <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
          <filter type="and">
            <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
          </filter>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="indskr_iopresentationid" alias="presentationId" /> 
     </link-entity>
      </entity>
    </fetch>`,
  updatedTagsForPosition: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="indskr_usertag">
          <attribute name="indskr_usertagid" />
          <attribute name="indskr_name" />
          <attribute name="indskr_externalid" />
          <attribute name="modifiedon" />
          <attribute name="statecode" />
          <attribute name= "indskr_taggedfor"/>
          <attribute name="indskr_visibility" />
          <attribute name="indskr_filter" />
          <filter type="and">
            <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
            <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
         </filter>
         <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
         <filter type="and">
           <condition attribute="positionid" operator="in">
           {positionIDs}
           </condition>
         </filter>
       </link-entity>
       <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_iopresentationid" alias="presentationId" /> 
          </link-entity>
        </entity>
      </fetch>  `,          
    updatedPresentationBupublicTags: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="indskr_usertag">
          <attribute name="indskr_usertagid" />
          <attribute name="indskr_name" />
          <attribute name="indskr_externalid" />
          <attribute name="modifiedon" />
          <attribute name="statecode" />
          <attribute name= "indskr_taggedfor"/>
          <attribute name="indskr_visibility" />
          <attribute name="indskr_filter" />
          <filter type="and">
            <condition attribute="indskr_visibility" operator="eq" value="548910001"/>
            <condition attribute="indskr_taggedfor" operator="eq" value="Presentation" />
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
         </filter>
      <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
          <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
            <filter type="and">
              <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
            </filter>
          </link-entity>
        </link-entity>
       <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_iopresentationid" alias="presentationId" /> 
          </link-entity>
        </entity>
      </fetch> 
        `
  },
  resource: {
    fetchResourcePrivateTags: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
      <attribute name="indskr_usertagid" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="statecode" />
      <attribute name="ownerid" />
      <attribute name="indskr_externalid" />
      <attribute name= "indskr_taggedfor"/>
      <attribute name="indskr_visibility" />
      <attribute name="indskr_filter" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
        <condition attribute="ownerid" operator="eq-userid" />
        <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
        <condition attribute="statecode" operator="eq" value="0" />
    </filter>
  <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
  <attribute name="indskr_ioresourceid" alias="resourceId"/> 
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
  <attribute name="indskr_iodocumentid" alias="documentId"/> 
  </link-entity>     
    </entity>
  </fetch>`,
  fetchAssignedResourceTagsForBU: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usertag">
  <attribute name="indskr_usertagid" />
  <attribute name="indskr_name" />
  <attribute name="createdon" />
  <attribute name= "indskr_taggedfor"/>
  <attribute name="indskr_visibility" />
  <attribute name="indskr_filter" />
  <order attribute="indskr_name" descending="false" />
  <filter type="and">
    <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
    <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
    <condition attribute="statecode" operator="eq" value="0" />
  </filter>
  <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true" link-type="outer">
  <filter type="and">
  <condition attribute="indskr_bulevelconfigurationid" operator="in">
  <value>{buId}</value>
  </condition>
  </filter>
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
  <attribute name="indskr_ioresourceid" alias="resourceId" /> 
    <link-entity name="indskr_customerposition" from="indskr_customerid" to="indskr_ioresourceid" alias="am">
      <link-entity name="position" from="positionid" to="indskr_positionid" alias="an">
        <filter type="and">
          <condition attribute="positionid" operator="in">
          {positionIDs}
          </condition>
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
  </entity>
  </fetch> `,  
    fetchResourcePublicTags: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_externalid" />
    <attribute name="modifiedon" />
    <attribute name="statecode" />
    <attribute name= "indskr_taggedfor"/>
    <attribute name="indskr_visibility" />
    <attribute name="indskr_filter" />
    <filter type="and">
      <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
      <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
      <condition attribute="statecode" operator="eq" value="0" />
  </filter>
<link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
    <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
      <filter type="and">
        <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
<attribute name="indskr_ioresourceid" alias="resourceId" /> 
 </link-entity>
 <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
<attribute name="indskr_iodocumentid" alias="documentId"/> 
  </link-entity>
  </entity>
</fetch>`,
    fetchResourcePositionTags: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
      <attribute name="indskr_usertagid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_externalid" />
      <attribute name="modifiedon" />
      <attribute name="statecode" />
      <attribute name="indskr_taggedfor" />
      <attribute name="indskr_visibility" />
      <attribute name="indskr_filter" />
      <filter type="and">
        <condition attribute="indskr_visibility" operator="eq" value="600000000" />
        <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
        <filter type="and">
          <condition attribute="positionid" operator="in">
          {positionIDs}
          </condition>
        </filter>
      </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
      <attribute name="indskr_ioresourceid" alias="resourceId" /> 
        </link-entity>
        <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
        <attribute name="indskr_iodocumentid" alias="documentId"/> 
      </link-entity> 
    </entity>
  </fetch>
  `,

    updatedResourcePrivateTags: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
          <entity name="indskr_usertag">
            <attribute name="indskr_usertagid" />
            <attribute name="indskr_name" />
            <attribute name="indskr_externalid" />
            <attribute name="modifiedon" />
            <attribute name="statecode" />
            <attribute name= "indskr_taggedfor"/>
            <attribute name="indskr_visibility" />
            <attribute name="indskr_filter" />
            <filter type="and">
              <condition attribute="ownerid" operator="eq-userid" />
              <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
              <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
            </filter>
            <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
		        <attribute name="indskr_ioresourceid" alias="resourceId" /> 
              </link-entity>
              <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
              <attribute name="indskr_iodocumentid" alias="documentId"/> 
            </link-entity>    
          </entity>
        </fetch>`,
    updatedAssignedResourceTag: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
      <attribute name="indskr_usertagid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_externalid" />
      <attribute name="modifiedon" />
      <attribute name="statecode" />
      <attribute name="indskr_taggedfor" />
      <attribute name="indskr_visibility" />
      <attribute name="indskr_filter" />
      <filter type="and">
        <condition attribute="indskr_visibility" operator="eq" value="600000000" />
        <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
        <condition attribute="statecode" operator="eq" value="0" />
        <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
      </filter>
      <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
        <filter type="and">
          <condition attribute="positionid" operator="in">
          {positionIDs}
          </condition>
        </filter>
      </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
      <attribute name="indskr_ioresourceid" alias="resourceId" /> 
        </link-entity>
        <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
        <attribute name="indskr_iodocumentid" alias="documentId"/> 
      </link-entity> 
    </entity>
  </fetch> `,

    updatedResourcePublicTags: `
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="indskr_usertag">
          <attribute name="indskr_usertagid" />
          <attribute name="indskr_name" />
          <attribute name="indskr_externalid" />
          <attribute name="modifiedon" />
          <attribute name="statecode" />
          <attribute name= "indskr_taggedfor"/>
          <attribute name="indskr_visibility" />
          <attribute name="indskr_filter" />
          <filter type="and">
            <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
            <condition attribute="indskr_taggedfor" operator="eq" value="RESOURCE" />
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
         </filter>
      <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
          <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
            <filter type="and">
              <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
            </filter>
          </link-entity>
        </link-entity>
       <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_ioresourceid" alias="resourceId" /> 
          </link-entity>
          <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
          <attribute name="indskr_iodocumentid" alias="documentId"/> 
        </link-entity>
        </entity>
      </fetch> 
        `
  },

  allUserTag: {
    fetchPrivateTags:
`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_externalid" />
    <attribute name="modifiedon" />
    <attribute name="statecode" />
    <attribute name="indskr_taggedfor" />
    <attribute name="indskr_visibility" />
    <attribute name="indskr_filter" />
    <filter type="and">
      <condition attribute="ownerid" operator="eq-userid" />
      <condition attribute="indskr_visibility" operator="eq" value="548910000"/>
    </filter>
      <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
        <attribute name="indskr_ioresourceid" alias="resourceId" />
      </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
        <attribute name="indskr_iodocumentid" alias="documentId" />
      </link-entity>
      <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
          <attribute name="accountid"  alias="accountId" />
        </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
        <attribute name="indskr_iopresentationid" alias="presentationId"/> 
        </link-entity>   
      <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
          <attribute name="contactid" alias="contactId" />
      </link-entity> 
  </entity>
</fetch>`,
    fetchPublicTags: `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                      <entity name="indskr_usertag">
                            <attribute name="indskr_usertagid" />
                            <attribute name="indskr_name" />
                            <attribute name="indskr_externalid" />
                            <attribute name="modifiedon" />
                            <attribute name="statecode" />
                            <attribute name= "indskr_taggedfor"/>
                            <attribute name="indskr_visibility" />
                            <attribute name="indskr_filter" />
                            <filter type="and">
                              <condition attribute="indskr_visibility" operator="eq" value="548910001"/>
                              <condition attribute="statecode" operator="eq" value="0" />
                            </filter>
                        <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
                          <attribute name="indskr_ioresourceid" alias="resourceId" />
                        </link-entity>
                        <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
                          <attribute name="indskr_iodocumentid" alias="documentId" />
                        </link-entity>
                        <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                          <attribute name="accountid"  alias="accountId" />
                        </link-entity>
                        <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                          <attribute name="indskr_iopresentationid" alias="presentationId"/> 
                        </link-entity>   
                        <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
                          <attribute name="contactid" alias="contactId" />
                        </link-entity> 
                     </entity>
                </fetch>`,

    fetchAssignedTagsPosition: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
      <attribute name="indskr_usertagid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_externalid" />
      <attribute name="modifiedon" />
      <attribute name="statecode" />
      <attribute name="indskr_taggedfor" />
      <attribute name="indskr_visibility" />
      <attribute name="indskr_filter" />
      <filter type="and">
        <condition attribute="indskr_visibility" operator="eq" value="600000000" />
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
        <filter type="and">
          <condition attribute="positionid" operator="in">
          {positionIDs}
          </condition>
        </filter>
      </link-entity>
    <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
      <attribute name="indskr_ioresourceid" alias="resourceId" />
    </link-entity>
    <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
      <attribute name="indskr_iodocumentid" alias="documentId" />
    </link-entity>
    <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
      <attribute name="accountid"  alias="accountId" />
    </link-entity>
    <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
      <attribute name="indskr_iopresentationid" alias="presentationId"/> 
    </link-entity>   
    <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
      <attribute name="contactid" alias="contactId" />
    </link-entity> 
    </entity>
  </fetch>
   `,     
fetchAssignedTagsForBU: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
<entity name="indskr_usertag">
  <attribute name="indskr_usertagid" />
  <attribute name="indskr_name" />
  <attribute name="indskr_externalid" />
  <attribute name="modifiedon" />
  <attribute name="statecode" />
  <attribute name="indskr_taggedfor"/>
  <attribute name="indskr_visibility" />
  <attribute name="indskr_filter" />
  <filter type="and">
    <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
    <condition attribute="statecode" operator="eq" value="0" />
  </filter>
  <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
    <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
      <filter type="and">
        <condition attribute="indskr_bulevelconfigurationid" operator="eq"  value="{busUnitID}" />
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="indskr_ioresourceid" alias="resourceId" />
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="indskr_iodocumentid" alias="documentId"/>
  </link-entity>
  <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="accountid"  alias="accountId" />
  </link-entity>
  <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="indskr_iopresentationid" alias="presentationId"/>
  </link-entity>   
  <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
    <attribute name="contactid" alias="contactId" />
  </link-entity> 
</entity>
</fetch>
`,  

    updatedPrivateTags:
      `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_usertag">
        <attribute name="indskr_usertagid" />
        <attribute name="indskr_name" />
        <attribute name="indskr_externalid" />
        <attribute name="modifiedon" />
        <attribute name="statecode" />
        <attribute name="indskr_taggedfor" />
        <attribute name="indskr_visibility" />
        <attribute name="indskr_filter" />
        <filter type="and">
          <condition attribute="ownerid" operator="eq-userid" />
          <condition attribute="indskr_visibility" operator="eq" value="548910000"/>
          <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
        </filter>
          <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
            <attribute name="indskr_ioresourceid" alias="resourceId" />
          </link-entity>
          <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
            <attribute name="indskr_iodocumentid" alias="documentId" />
          </link-entity>
          <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
              <attribute name="accountid"  alias="accountId" />
            </link-entity>
          <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
            <attribute name="indskr_iopresentationid" alias="presentationId"/> 
            </link-entity>   
          <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
              <attribute name="contactid" alias="contactId" />
          </link-entity> 
      </entity>
    </fetch>`,

    updatedPublicTags: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_usertag">
          <attribute name="indskr_usertagid" />
          <attribute name="indskr_name" />
          <attribute name="indskr_externalid" />
          <attribute name="modifiedon" />
          <attribute name="statecode" />
          <attribute name= "indskr_taggedfor"/>
          <attribute name="indskr_visibility" />
          <attribute name="indskr_filter" />
          <filter type="and">
            <condition attribute="indskr_visibility" operator="eq" value="548910001"/>
            <condition attribute="statecode" operator="eq" value="0" />
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
          </filter>
      <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
        <attribute name="indskr_ioresourceid" alias="resourceId" />
      </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
        <attribute name="indskr_iodocumentid" alias="documentId" />
      </link-entity>
      <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
        <attribute name="accountid"  alias="accountId" />
      </link-entity>
      <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
        <attribute name="indskr_iopresentationid" alias="presentationId"/> 
      </link-entity>   
      <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
        <attribute name="contactid" alias="contactId" />
      </link-entity> 
   </entity>
</fetch>`,

   updatedAssignedTagPosition: `
   <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
   <entity name="indskr_usertag">
     <attribute name="indskr_usertagid" />
     <attribute name="indskr_name" />
     <attribute name="indskr_externalid" />
     <attribute name="modifiedon" />
     <attribute name="statecode" />
     <attribute name="indskr_taggedfor" />
     <attribute name="indskr_visibility" />
     <attribute name="indskr_filter" />
     <filter type="and">
       <condition attribute="indskr_visibility" operator="eq" value="600000000" />
       <condition attribute="statecode" operator="eq" value="0" />
       <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
     </filter>
   <link-entity name="indskr_indskr_usertag_position" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
     <attribute name="positionid" alias="position" />
   </link-entity>
   <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
     <attribute name="indskr_ioresourceid" alias="resourceId" />
   </link-entity>
   <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
     <attribute name="indskr_iodocumentid" alias="documentId" />
   </link-entity>
   <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="accountid"  alias="accountId" />
   </link-entity>
   <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_iopresentationid" alias="presentationId"/> 
   </link-entity>   
   <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="contactid" alias="contactId" />
   </link-entity> 
   </entity>
 </fetch>
  `,
     updatedAssignedTagsForBU: `
     <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
     <entity name="indskr_usertag">
       <attribute name="indskr_usertagid" />
       <attribute name="indskr_name" />
       <attribute name="indskr_externalid" />
       <attribute name="modifiedon" />
       <attribute name="statecode" />
       <attribute name= "indskr_taggedfor"/>
       <attribute name="indskr_visibility" />
       <attribute name="indskr_filter" />
       <filter type="and">
         <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
         <condition attribute="statecode" operator="eq" value="0" />
         <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
       </filter>
     <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
       <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
         <attribute name="indskr_bulevelconfigurationid" alias="bu" />
       </link-entity>
     </link-entity>
     <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
     <attribute name="indskr_ioresourceid" alias="resourceId" />
     </link-entity>
     <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
     <attribute name="indskr_iodocumentid" alias="documentId" />
     </link-entity>
     <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="accountid"  alias="accountId" />
     </link-entity>
     <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_iopresentationid" alias="presentationId"/> 
     </link-entity>   
     <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="contactid" alias="contactId" />
     </link-entity> 
     </entity>
     </fetch>`,
     updatedEmptyBuAndPosition:`
     <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
     <entity name="indskr_usertag">
       <attribute name="indskr_usertagid" />
       <attribute name="indskr_name" />
       <attribute name="indskr_externalid" />
       <attribute name="modifiedon" />
       <attribute name="statecode" />
       <attribute name= "indskr_taggedfor"/>
       <attribute name="indskr_visibility" />
       <attribute name="indskr_filter" />
       <filter type="and">
         <condition attribute="indskr_visibility" operator="eq" value="600000000"/>
         <condition attribute="statecode" operator="eq" value="0" />
         <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
       </filter>
     <link-entity name="indskr_indskr_usertag_indskr_ioresource" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
     <attribute name="indskr_ioresourceid" alias="resourceId" />
     </link-entity>
     <link-entity name="indskr_indskr_usertag_indskr_iodocument" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true">
     <attribute name="indskr_iodocumentid" alias="documentId" />
     </link-entity>
     <link-entity name="indskr_indskr_usertag_account" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="accountid"  alias="accountId" />
     </link-entity>
     <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="indskr_iopresentationid" alias="presentationId"/> 
     </link-entity>   
     <link-entity name="indskr_indskr_usertag_contact" from="indskr_usertagid" to="indskr_usertagid" link-type="outer" intersect="true" >
     <attribute name="contactid" alias="contactId" />
     </link-entity> 
     </entity>
     </fetch>`
},
  fetchAllConsentActivitiesForProductsAndEmails:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_consentactivity">
        <attribute name="indskr_consentactivityid" alias="consentActivityId"/>
        <attribute name="indskr_externalid" alias="offlineId" />
        <attribute name="indskr_name" />
        <attribute name="indskr_type" />
        <attribute name="createdon" />
        <attribute name="indskr_activitydate" />
        <attribute name="createdby" />
        <attribute name="indskr_phone" />
        <attribute name="indskr_addressname" />
        <attribute name="indskr_contact" alias="contactId" />
        <attribute name="indskr_consenttype" alias="consentTypeId" />
        <attribute name="indskr_optinsource" />
        <link-entity name="product" from="productid" to="indskr_product" link-type="outer">
          <attribute name="name" alias="indskr_productname" />
        </link-entity>
        <link-entity name="indskr_email_address" from="indskr_email_addressid" to="indskr_emailaddress" link-type="outer">
          <attribute name="indskr_name" alias="emailAddress" />
        </link-entity>
        <link-entity name="indskr_consentterms" from="indskr_consenttermsid" to="indskr_consentterms">
          <attribute name="indskr_name" alias="consentTermName" />
          <attribute name="indskr_consenttermsid" alias="consentTermId" />
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
        <link-entity name="indskr_consentactivitysource" from="indskr_consentactivitysourceid" to="indskr_source" link-type="outer">
            <attribute name="indskr_sourcetype" alias="indskr_consentactivitysourcetype" />
            <filter type="and">
               <condition attribute="statecode" operator="eq" value="0" />
            </filter>
        </link-entity>
        {deltaSyncFilter}
      </entity>
</fetch>`,
  fetchConsentsByContactId: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_consent">
        <attribute name="indskr_consentid" />
        <attribute name="indskr_name" />
        <attribute name="createdon" />
        <attribute name="statecode" />
        <attribute name="indskr_consentversion" />
        <order attribute="indskr_name" descending="false" />
        <filter type="and">
          <condition attribute="indskr_contact" operator="eq" uitype="contact" value="{contactId}" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_consentterms" from="indskr_consenttermsid" to="indskr_consentterms" visible="false" link-type="inner" alias="ct">
          <attribute name="statecode" alias="ct_statecode" />
          <attribute name="indskr_consenttype" alias="consenttype"/>
          <attribute name="indskr_name" alias="ct_name" />
          <attribute name="indskr_untildate" alias="untildate" />
          <attribute name="indskr_fromdate" alias="fromdate" />
        </link-entity>
      </entity>
    </fetch>`,
  fetchConsentsByContactIdAndTypes: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_consent">
        <attribute name="indskr_consentid" />
        <attribute name="indskr_name" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
          <condition attribute="indskr_contact" operator="eq" uitype="contact" value="{contactId}" />
        </filter>
        <link-entity name="indskr_consentterms" from="indskr_consenttermsid" to="indskr_consentterms" link-type="inner" alias="ag">
          <attribute name="indskr_consenttype" alias="consenttype" />
          <filter type="and">
            <condition attribute="indskr_consenttype" operator="in">{consentTypes}</condition>
          </filter>
        </link-entity>
      </entity>
    </fetch>`,
  consentActivitiesByContactId:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_consentactivity">
      <attribute name="indskr_consentactivityid" alias="consentActivityId"/>
      <attribute name="indskr_name" />
      <attribute name="indskr_contact" alias="contactId" />
      <filter type="and">
        <condition attribute="indskr_contact" operator="eq" value="{contactId}" />
      </filter>
      <link-entity name="product" from="productid" to="indskr_product">
        <attribute name="name" alias="indskr_productname" />
      </link-entity>
      <link-entity name="indskr_email_address" from="indskr_email_addressid" to="indskr_emailaddress">
        <attribute name="indskr_name" alias="emailAddress" />
      </link-entity>
      <link-entity name="indskr_consentterms" from="indskr_consenttermsid" to="indskr_consentterms">
        <attribute name="indskr_name" alias="consentTermName" />
        <attribute name="indskr_consenttermsid" alias="consentTermId" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      </link-entity>
      <filter type="and">
        <condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />
      </filter>
    </entity>
</fetch>`,
  positionGroupsByPositionIdsTeamview:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_positiongroups">
      <attribute name="indskr_name"/>
      <attribute name="createdon"/>
      <attribute name="indskr_coachingtemplate"/>
      <attribute name="indskr_positiongroupsid"/>
      <order attribute="indskr_name" descending="false"/>
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0"/>
      </filter>
      <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="ab">
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute='positionid' operator='in'>
              {positionsCondition}
            </condition>
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  productsByPositionIdsTeamview:
    `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="product">
      <attribute name="name"/>
      <attribute name="productid"/>
      <attribute name="productnumber"/>
      <attribute name="description"/>
      <attribute name="statecode"/>
      <attribute name="productstructure"/>
      <order attribute="productnumber" descending="false"/>
      <filter type="and">
        <condition attribute="indskr_competitorproduct" operator="ne" value="1"/>
      </filter>
      <link-entity name="indskr_position_product" from="productid" to="productid" visible="false" intersect="true">
        <link-entity name="position" from="positionid" to="positionid" alias="aj">
          <filter type="and">
            <condition attribute="indskr_status" operator="eq" value="100000000" />
            <condition attribute='positionid' operator='in'>
              {positionsCondition}
            </condition>
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
  productIndicationsAndKeyMessagesByPositionIds:
  `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="product">
          <attribute name="name" />
          <attribute name="productid" />
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="1" />
          </filter>
          <filter type="and">
            <condition attribute="indskr_competitorproduct" operator="ne" value="1" />
          </filter>
          <link-entity name="indskr_productassignment" from="indskr_productid" to="productid" alias="aa">
            <filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="position" from="positionid" to="indskr_positionid" alias="af" distinct="true">
                <filter type="or">
                  <filter type="and">
                      <condition attribute="indskr_status" operator="eq" value="100000000" />
                      <condition attribute="positionid" operator="in">
                        {PositionIds1}
                      </condition>
                  </filter>
                  <filter type="and">
                      <condition attribute="indskr_status" operator="eq" value="100000000" />
                      <condition attribute="parentpositionid" operator="in">
                        {PositionIds2}
                      </condition>
                  </filter>
                </filter>
            </link-entity>
          </link-entity>
          <link-entity name="indskr_productindication" from="indskr_product" to="productid" alias="productIndications">
            <attribute name="indskr_productindicationid" />
            <attribute name="indskr_name" />
            <attribute name="statecode" />
            <filter type="and"><condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" /></filter>
            <link-entity name="indskr_keymessage" from="indskr_productindication" to="indskr_productindicationid" alias="productIndicationsKeyMessages" link-type="outer">
              <attribute name="indskr_keymessageid" />
              <attribute name="indskr_name" />
              <attribute name="statecode" />
            </link-entity>

          </link-entity>
      </entity>
    </fetch>
  `,
  keyMessagesByPositionGroups: 
  `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_keymessage">
        <attribute name="indskr_keymessageid" />
        <attribute name="indskr_name" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_indskr_keymessage_indskr_positiongroups" intersect="true" visible="false" to="indskr_keymessageid" from="indskr_keymessageid">
          <link-entity name="indskr_positiongroups" from="indskr_positiongroupsid" to="indskr_positiongroupsid" alias="bb">
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
              <condition attribute="indskr_positiongroupsid" operator="in">
                {PositionGroupIds}
              </condition>
            </filter>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>
  `
  ,
  territoryManagementLists: {
    fetchPostionList: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_positionlisttarget">
      <attribute name="indskr_positionlisttargetid" />
      <attribute name="indskr_name" />
      <attribute name="createdon" />
      <attribute name="indskr_contact" />
      <order attribute="indskr_name" descending="false" />
      <link-entity name="contact" from="contactid" to="indskr_contact">
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      </link-entity>
      <link-entity name="indskr_customerpositionlist" from="indskr_customerpositionlistid" to="indskr_customerpositionlist">
        <filter type="and">
          <condition attribute="indskr_position" operator="eq" value="{positionID}" />
          <condition attribute="statuscode" operator="eq" value="600000001" />
          <condition attribute="indskr_customerlistperiod" operator="eq" value="{customerListPeriodId}" />
        </filter>
      </link-entity>
      <link-entity name="indskr_indskr_positionlisttarget_account" from="indskr_positionlisttargetid" to="indskr_positionlisttargetid" intersect="true">
      <link-entity name="account" from="accountid" to="accountid">
        <attribute name="accountid" />
        <attribute name="name" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_indskr_customerpositionlist_account" from="accountid" to="accountid" visible="false" intersect="true">
          <link-entity name="indskr_customerpositionlist" from="indskr_customerpositionlistid" to="indskr_customerpositionlistid">
            <filter type="and">
              <condition attribute="indskr_type" operator="eq" value="600000000" />
              <condition attribute="indskr_customerlistperiod" operator="eq" value="{nextCustomerListPeriodId}" />
              <condition attribute="statuscode" operator="eq" value="600000001" />
              <condition attribute="indskr_position" operator="eq" value="{positionID}" />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
    </entity>
  </fetch>`,
    fetchConfigAccountsByCustomerPositionList: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_customerpositionlist">
      <attribute name="indskr_customerpositionlistid" />
      <order attribute="indskr_name" descending="false" />
      <filter type="and">
        <condition attribute="indskr_type" operator="eq" value="600000000" />
        <condition attribute="statuscode" operator="eq" value="600000001" />
        <condition attribute="indskr_position" operator="eq" value="{positionId}"/>
      </filter>
      <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" link-type="inner" alias="aa">
        <filter type="and">
          <condition attribute="indskr_customerlistperiodid" operator="eq" value="{customerListPeriodId}" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      </link-entity>
      <link-entity name="indskr_indskr_customerpositionlist_account" from="indskr_customerpositionlistid" to="indskr_customerpositionlistid" link-type="inner">
        <link-entity name="account" from="accountid" to="accountid" link-type="inner" alias="account">
          <attribute name="name" />
          <attribute name="accountid" />
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
          <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner">
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0"/>
            </filter>
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ae">
              <filter type="or">
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="positionid" operator="in">{positionIDs}</condition>
                </filter>
                <filter type="and">
                  <condition attribute="indskr_status" operator="eq" value="100000000" />
                  <condition attribute="parentpositionid" operator="in">{positionIDs}</condition>
                </filter>
              </filter>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </entity>
    </fetch>`,
    fetchMyContactsByCustomerPositionList: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_positionlisttarget">
      <attribute name="indskr_positionlisttargetid" alias="positionListTargetId"/>
      <link-entity name="indskr_customerpositionlist" from="indskr_customerpositionlistid" to="indskr_customerpositionlist" alias="indskr_customerpositionlist">
        <attribute name="indskr_approvalstatus"/>
        <attribute name="statecode"/>
        <attribute name="statuscode"/>
        <attribute name="indskr_netchange"/>
        <attribute name="indskr_percentagechange"/>
        <attribute name="indskr_numberadded"/>
        <attribute name="indskr_numberremoved"/>
        <filter type="and">
          <condition attribute="indskr_customerpositionlistid" operator="eq" value="{customerPositionListId}" />
        </filter>
      </link-entity>
      <link-entity name="contact" from="contactid" to="indskr_contact">
          <attribute name="contactid" alias="contactId"/>
          <attribute name="fullname" alias="contactName"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
      <link-entity name="indskr_indskr_positionlisttarget_account" from="indskr_positionlisttargetid" to="indskr_positionlisttargetid">
        <link-entity name="account" from="accountid" to="accountid">
          <attribute name="accountid" alias="accountId"/>
          <attribute name="name" alias="accountName"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
          </filter>
        </link-entity>
    </link-entity>
    </entity>
  </fetch>`,
    fetchCustomerPositionList: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_customerpositionlist">
      <attribute name="indskr_customerpositionlistid" />
      <attribute name="createdon" />
      <attribute name="modifiedon" />
      <attribute name="ownerid" alias="ownerId"/>
      <attribute name="indskr_position" alias="position"/>
      <attribute name="indskr_approvalstatus"/>
      <attribute name="statecode"/>
      <attribute name="statuscode"/>
      <attribute name="indskr_netchange"/>
      <attribute name="indskr_percentagechange"/>
      <attribute name="indskr_numberadded"/>
      <attribute name="indskr_numberremoved"/>
      <filter type="and">
        <condition attribute="statuscode" operator="ne" value="600000003" />
        <condition attribute="ownerid" operator="eq-userid" />
        <condition attribute="indskr_position" operator="eq" value="{positionId}"/>
        <condition attribute="indskr_type" operator="eq" value="600000001" />
      </filter>
      <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" visible="false" >
        <attribute name="indskr_name" alias="customerListPeriodName"/>
        <attribute name="indskr_customerlistperiodid" alias="customerListPeriodId"/>
        <attribute name="indskr_liststartdate" alias="customerListPeriodStartDate"/>
        <attribute name="indskr_listenddate" alias="customerListPeriodEndDate"/>
      </link-entity>
    </entity>
  </fetch>
  `,
    fetchCurrentCustomerListPeriod: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_customerlistperiod">
    <attribute name="indskr_customerlistperiodid" alias="customerListPeriodId"/>
    <attribute name="indskr_name" alias="customerListPeriodName"/>
    <attribute name="indskr_nextlistmanagementwindowstartdate" alias="nextListManagementWindowStartDate"/>
    <attribute name="indskr_nextlistmanagementwindowenddate" alias="nextListManagementWindowEndDate"/>
    <attribute name="indskr_liststartdate" alias="customerListPeriodStartDate"/>
    <attribute name="indskr_listenddate" alias="customerListPeriodEndDate"/>
    <attribute name="indskr_taglabel" alias="tagLabel"/>
    <attribute name="indskr_adjustmentlimit" alias="indskr_adjustmentlimit"/>
    <filter type="and">
      <condition attribute="indskr_liststartdate" operator="on-or-before" value="{date}" />
      <condition attribute="indskr_listenddate" operator="on-or-after" value="{date}" />
    </filter>
    <link-entity name="indskr_indskr_customerlistperiod_position" from="indskr_customerlistperiodid" to="indskr_customerlistperiodid" visible="false" intersect="true">
      <link-entity name="position" from="positionid" to="positionid" >
        <filter type="and">
          <condition attribute="positionid" operator="eq" value="{positionId}"/>
        </filter>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_nextlistperiod" link-type="outer">
      <attribute name="indskr_liststartdate" alias="nextCustomerListPeriodStartDate"/>
      <attribute name="indskr_listenddate" alias="nextCustomerListPeriodEndDate"/>
      <attribute name="indskr_customerlistperiodid" alias="nextCustomerListPeriodId"/>
      <attribute name="indskr_taglabel" alias="nextTagLabel"/>
      <attribute name="indskr_name" alias="nextCustomerListPeriodName"/>
      <attribute name="indskr_adjustmentlimit" alias="nextAdjustmentLimit"/>
    </link-entity>
    <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_previouslistperiod" link-type="outer">
      <attribute name="indskr_liststartdate" alias="prevCustomerListPeriodStartDate"/>
      <attribute name="indskr_listenddate" alias="prevCustomerListPeriodEndDate"/>
      <attribute name="indskr_customerlistperiodid" alias="prevCustomerListPeriodId"/>
      <attribute name="indskr_taglabel" alias="prevTagLabel"/>
      <attribute name="indskr_name" alias="prevCustomerListPeriodName"/>
      <attribute name="indskr_adjustmentlimit" alias="prevAdjustmentLimit"/>
    </link-entity>
  </entity>
</fetch>`,
    fetchApprovalTab: `
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_customerpositionlist">
      <attribute name="indskr_customerpositionlistid" />
      <attribute name="createdon" />
      <attribute name="modifiedon" />
      <attribute name="ownerid" alias="ownerId"/>
      <attribute name="indskr_position" alias="position"/>
      <attribute name="indskr_approvalstatus"/>
      <attribute name="statecode"/>
      <attribute name="statuscode"/>
      <attribute name="indskr_netchange"/>
      <attribute name="indskr_percentagechange"/>
      <attribute name="indskr_numberadded"/>
      <attribute name="indskr_numberremoved"/>
      <filter type="and">
        <condition attribute="statuscode" operator="ne" value="600000003" />
        <condition attribute="statuscode" operator="ne" value="1" />
        <condition attribute="indskr_type" operator="eq" value="600000001" />
      </filter>
      <link-entity name="indskr_approvalactivity" from="indskr_customerpositionlist" to="indskr_customerpositionlistid" link-type="inner">
        <attribute name="indskr_approvalactivityid" alias="approvalActivityId"/>
        <attribute name="statuscode" alias="approvalActivityStatusCode"/>
        <attribute name="createdon" alias="approvalActivityCreatedOn"/>
        <attribute name="modifiedon" alias="approvalActivityModifiedOn"/>
        <filter type="and">
          <condition attribute="statuscode" operator="ne" value="1" />
        </filter>
        <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver">
        <filter type="and">
          <condition attribute="ownerid" operator="eq-userid" />
        </filter>
      </link-entity>
      </link-entity>
      <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" visible="false" >
        <attribute name="indskr_name" alias="customerListPeriodName"/>
        <attribute name="indskr_customerlistperiodid" alias="customerListPeriodId"/>
        <attribute name="indskr_liststartdate" alias="customerListPeriodStartDate"/>
        <attribute name="indskr_listenddate" alias="customerListPeriodEndDate"/>
        <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_previouslistperiod" link-type="inner">
          <attribute name="indskr_customerlistperiodid" alias="prevCustomerListPeriodId"/>
          <attribute name="indskr_nextlistmanagementwindowstartdate" alias="prevListPeriodManagementWindowStartDate"/>
          <attribute name="indskr_nextlistmanagementwindowenddate" alias="prevListPeriodManagementWindowEndDate"/>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    fetchApprovalActivities: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_approvalactivity">
      <attribute name="indskr_approvalactivityid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_cycle" />
      <attribute name="statuscode" />
      <attribute name="indskr_reason" />
      <attribute name="createdon" />
      <order attribute="modifiedon" descending="true" />
      <filter type="and">
        <condition attribute="indskr_customerpositionlist" operator="eq" value="{customerListPeriodId}"/>
      </filter>
      <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver">
        <attribute name="ownerid" alias="ownerId" />
      </link-entity>
    </entity>
  </fetch>
  `,
    fetchContactsByConfiguredFields: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_customerpositionlist">
        <filter type="and">
          <condition attribute="indskr_position" operator="eq" value="{positionID}" />
          <condition attribute="statuscode" operator="eq" value="600000001" />
          <condition attribute="indskr_type" operator="eq" value="600000000" />
        </filter>
        <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" link-type="inner">
          <filter type="and">
          <condition attribute="indskr_customerlistperiodid" operator="eq" value="{customerListPeriodId}" />
          </filter>
        </link-entity>
        <link-entity name="indskr_indskr_customerpositionlist_account" from="indskr_customerpositionlistid" to="indskr_customerpositionlistid">
          <link-entity name="indskr_accountcontactaffiliation" from="indskr_accountid" to="accountid">
              <link-entity name="contact" from="contactid" to="indskr_contactid" alias="con">
                <attribute name="contactid" alias="contactId"/>
                <filter type="and">
                  <condition attribute="statecode" operator="eq" value="0" />
                </filter>
                {parentLevelAttributes}
                {linkEntityPlaceholder}
              </link-entity>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>`,
    fetchApprovedCustomerPositionList: `
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_customerpositionlist">
    <attribute name="indskr_customerpositionlistid" />
    <attribute name="createdon" />
    <attribute name="modifiedon" />
    <attribute name="ownerid" alias="ownerId"/>
    <attribute name="indskr_position" alias="position"/>
    <attribute name="indskr_approvalstatus"/>
    <attribute name="statecode"/>
    <attribute name="statuscode"/>
    <attribute name="indskr_netchange"/>
    <attribute name="indskr_percentagechange"/>
    <attribute name="indskr_numberadded"/>
    <attribute name="indskr_numberremoved"/>
    <filter type="and">
      <condition attribute="statuscode" operator="eq" value="600000001" />
      <condition attribute="ownerid" operator="eq-userid" />
      <condition attribute="indskr_position" operator="eq" value="{positionId}"/>
      <condition attribute="indskr_type" operator="eq" value="600000001" />
    </filter>
    <link-entity name="indskr_approvalactivity" from="indskr_customerpositionlist" to="indskr_customerpositionlistid" link-type="inner" alias="aa">
      <filter type="and">
        <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
      </filter>
    </link-entity>
    <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" visible="false" >
    <attribute name="indskr_name" alias="customerListPeriodName"/>
    <attribute name="indskr_customerlistperiodid" alias="customerListPeriodId"/>
    <attribute name="indskr_liststartdate" alias="customerListPeriodStartDate"/>
    <attribute name="indskr_listenddate" alias="customerListPeriodEndDate"/>
      </link-entity>
  </entity>
</fetch>`,
    fetchRejectedCustomerPositionList: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_customerpositionlist">
    <attribute name="indskr_customerpositionlistid" />
    <attribute name="createdon" />
    <attribute name="modifiedon" />
    <attribute name="ownerid" alias="ownerId"/>
    <attribute name="indskr_position" alias="position"/>
    <attribute name="indskr_approvalstatus"/>
    <attribute name="statecode"/>
    <attribute name="statuscode"/>
    <attribute name="indskr_netchange"/>
    <attribute name="indskr_percentagechange"/>
    <attribute name="indskr_numberadded"/>
    <attribute name="indskr_numberremoved"/>
    <filter type="and">
      <condition attribute="statuscode" operator="eq" value="1" />
      <condition attribute="ownerid" operator="eq-userid" />
      <condition attribute="indskr_position" operator="eq" value="{positionId}"/>
      <condition attribute="indskr_type" operator="eq" value="600000001" />
    </filter>
    <link-entity name="indskr_approvalactivity" from="indskr_customerpositionlist" to="indskr_customerpositionlistid" link-type="inner" alias="aa">
      <filter type="and">
        <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
        <condition attribute="statuscode" operator="eq" value="548910002" />
      </filter>
    </link-entity>
    <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" visible="false" >
    <attribute name="indskr_name" alias="customerListPeriodName"/>
    <attribute name="indskr_customerlistperiodid" alias="customerListPeriodId"/>
    <attribute name="indskr_liststartdate" alias="customerListPeriodStartDate"/>
    <attribute name="indskr_listenddate" alias="customerListPeriodEndDate"/>
      </link-entity>
  </entity>
</fetch>
`,
    fetchForReviewCustomerPositionList: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_customerpositionlist">
    <attribute name="indskr_customerpositionlistid" />
    <attribute name="createdon" />
    <attribute name="modifiedon" />
    <attribute name="ownerid" alias="ownerId"/>
    <attribute name="indskr_position" alias="position"/>
    <attribute name="indskr_approvalstatus"/>
    <attribute name="statecode"/>
    <attribute name="statuscode"/>
    <attribute name="indskr_netchange"/>
    <attribute name="indskr_percentagechange"/>
    <attribute name="indskr_numberadded"/>
    <attribute name="indskr_numberremoved"/>
    <filter type="and">
      <condition attribute="indskr_type" operator="eq" value="600000001" />
      <condition attribute="statuscode" operator="eq" value="2" />
    </filter>
    <link-entity name="indskr_approvalactivity" from="indskr_customerpositionlist" to="indskr_customerpositionlistid" link-type="inner" alias="aa">
    <attribute name="indskr_approvalactivityid" alias="approvalActivityId"/>
    <attribute name="createdon" alias="approvalActivityCreatedOn"/>
    <attribute name="statuscode" alias="approvalActivityStatusCode"/>
    <filter type="and">
        <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
      </filter>
      <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner" alias="ab">
        <filter type="and">
          <condition attribute="ownerid" operator="eq-userid" />
        </filter>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_customerlistperiod" from="indskr_customerlistperiodid" to="indskr_customerlistperiod" visible="false" >
      <attribute name="indskr_name" alias="customerListPeriodName"/>
      <attribute name="indskr_customerlistperiodid" alias="customerListPeriodId"/>
      <attribute name="indskr_liststartdate" alias="customerListPeriodStartDate"/>
      <attribute name="indskr_listenddate" alias="customerListPeriodEndDate"/>
      <attribute name="indskr_nextlistmanagementwindowstartdate" alias="prevListPeriodManagementWindowStartDate"/>
      <attribute name="indskr_nextlistmanagementwindowenddate" alias="prevListPeriodManagementWindowEndDate"/>
      </link-entity>
  </entity>
</fetch>
`
  },
  fetchIoConfigurations: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_ioconfiguration">
    <attribute name="createdon" />
    <attribute name="indskr_configname" />
    <attribute name="indskr_configvalue" />
    <attribute name="indskr_language" />
    <attribute name="indskr_ioconfigurationid" />
    <order attribute="createdon" descending="false" />
    {filter}
  </entity>
</fetch>`,
  fetchVoiceCallRecords: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_callactivity">
    <attribute name="indskr_callactivityid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_voicecallid" />
    <attribute name="indskr_starttime" />
    <attribute name="indskr_recordinglink" />
    <attribute name="indskr_phonenumber" />
    <attribute name="indskr_endtime" />
    <attribute name="indskr_duration" />
    <order attribute="createdon" descending="false" />
    <filter type="and">
      <condition attribute="indskr_recordinglink" operator="not-null" />
    </filter>
    <link-entity name="phonecall" from="activityid" to="indskr_phonecall" link-type="inner" alias="ae">
      <filter type="and">
        <condition attribute="activityid" operator="eq"  value="{phoneActivityId}" />
      </filter>
    </link-entity>
  </entity>
</fetch>`,
fetchOrgSettings: `<fetch top="1" version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="systemuser">
    <attribute name="systemuserid" />
    <attribute name="photourl" />
    
    <filter type="and">
      <condition attribute="systemuserid" operator="eq-userid"/>
    </filter>
    <link-entity name="organization" from="organizationid" to="organizationid" link-type="inner" alias="uo">
      <link-entity name="indskr_organizationsetting" from="organizationid" to="organizationid" link-type="inner" alias="os">
      <attribute name="indskr_offlinenotificationindays" />
      <attribute name="indskr_offlinenotificationmessages" />
      <attribute name="indskr_recurringofflinenotificationindays" />
      <attribute name="indskr_contactdisplaysequence" />   
      <attribute name="indskr_samplelastdispatchlimit" />
      </link-entity>
    </link-entity>
  </entity>
</fetch>`,
  fetchContactJoinDetails: `
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_activityattendeejoiningdetail">
      <attribute name="indskr_activityattendeejoiningdetailid" />
      <attribute name="indskr_joineddate" />
      <attribute name="indskr_leftdate" />
      <filter type="and">
        <condition attribute="indskr_joineddate" operator="not-null" />
        <condition attribute="indskr_leftdate" operator="not-null" />
        <condition attribute="indskr_appointmentid" operator="eq" uitype="appointment" value="{activityId}" />
        <condition attribute="indskr_contactid" operator="eq" value="{contactId}" />
      </filter>
    </entity>
  </fetch>
  `,
  fetchContactsBasedOnPositionId: `
  <fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>
    <entity name='contact'>
    <attribute name='contactid' />
    <attribute name='firstname'/>
    <attribute name='lastname' />
    <attribute name='indskr_title' />
    <attribute name='statuscode' />
    <attribute name='ownerid' />
    <attribute name='fullname' />
    <attribute name='modifiedon' />
    <attribute name='indskr_lu_specialtyid' />
    <order attribute='firstname' descending='false' />
      <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='an'>
        <filter type='and'>
          <condition attribute='statecode' operator='eq' value='0'/>
        </filter>
        <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='ao'>
          <filter type='and'>
            <condition attribute='positionid' operator='eq'  value='{positionID}'/>
          </filter>
        </link-entity>
      </link-entity>
    </entity>
    {{secondaryinfoFetchXml}}
</fetch>
  `,
  fetchTargetContactsBasedOnCurrentCustomerListPeriod: `
    <fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>
      <entity name='contact'>
          <attribute name='contactid'/>
          <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='an'>
              <filter type='and'>
                  <condition attribute='statecode' operator='eq' value='0'/>
              </filter>
              <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='ao'>
                  <filter type='and'>
                      <condition attribute='positionid' operator='eq'  value='{positionID}'/>
                  </filter>
              </link-entity>
          </link-entity>
          <link-entity name='indskr_positionlisttarget' from='indskr_contact' to='contactid'>
              <link-entity name='indskr_customerpositionlist' from='indskr_customerpositionlistid' to='indskr_customerpositionlist' link-type='inner'>
                  <attribute name="indskr_name" alias="customerListPeriodName"/>      
                  <filter type='and'>
                      <condition attribute='statuscode' operator='eq' value='600000001' />
                      <condition attribute='indskr_position' operator='eq' value='{positionID}'/>
                      <condition attribute='indskr_customerlistperiod' operator='eq' value='{currentCustomerListPeriodId}'/>
                  </filter>
              </link-entity>
          </link-entity>
      </entity>
    </fetch>
    `,
fetchTargetContactsBasedOnPositionId: `
  <fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>
    <entity name='contact'>
      <attribute name='contactid' />
      <attribute name='firstname'/>
      <attribute name='lastname' />
      <attribute name='indskr_title' />
      <attribute name='statuscode' />
      <attribute name='ownerid' />
      <attribute name='fullname' />
      <attribute name='modifiedon' />
      <attribute name='indskr_lu_specialtyid' />
      <order attribute='firstname' descending='false' />
      <link-entity name='indskr_positionlisttarget' from='indskr_contact' to='contactid' link-type='inner' alias='aa'>
        <link-entity name='indskr_customerpositionlist' from='indskr_customerpositionlistid' to='indskr_customerpositionlist' link-type='inner' alias='ab'>
          <filter type='and'>
            <condition attribute='statuscode' operator='eq' value='600000001' />
            <condition attribute='indskr_position' operator='eq' value='{positionID}'/>
          </filter>
          <link-entity name='indskr_customerlistperiod' from='indskr_customerlistperiodid' to='indskr_customerlistperiod' link-type='inner' alias='ac'>
            <attribute name="indskr_name" alias="customerListPeriodName"/>  
            <filter type='and'>
              <condition attribute='indskr_liststartdate' operator='on-or-before' value='{date}' />
              <condition attribute='indskr_listenddate' operator='on-or-after' value='{date}' />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      {{secondaryinfoFetchXml}}
    </entity>
  </fetch>
  `,
  fetchAccountsBasedOnPositionId: `
  <fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>
    <entity name='account'>
      <attribute name='accountid'/>
      <attribute name='name'/>
      <attribute name='createdon'/>
      <attribute name='modifiedon'/>
      <attribute name='statuscode' />
      <filter type='and'>
        <condition attribute='statecode' operator='eq' value='0'/>
      </filter>
      <link-entity name='indskr_customerposition' from='indskr_customerid' to='accountid' link-type='inner' alias='ab'>
        <filter type='and'>
          <condition attribute='indskr_positionid' operator='eq'  value='{positionID}'/>
        </filter>
      </link-entity>
      {{secondaryinfoFetchXml}}
    </entity>
</fetch>
    `,
  fetchTargetAccountsBasedOnPositionId: `
  <fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>
	<entity name='account'>
		<attribute name='accountid'/>
		<attribute name='name'/>
		<attribute name='createdon'/>
		<attribute name='modifiedon'/>
    <attribute name='statuscode' />
		<link-entity name='indskr_indskr_customerpositionlist_account' from='accountid' to='accountid' visible='false' intersect='true'>
			<link-entity name='indskr_customerpositionlist' from='indskr_customerpositionlistid' to='indskr_customerpositionlistid' alias='ae'>
				<filter type='and'>
          <condition attribute='statuscode' operator='eq' value='600000001' />
					<condition attribute='indskr_position' operator='eq' value='{positionID}'/>
				</filter>
				<link-entity name='indskr_customerlistperiod' from='indskr_customerlistperiodid' to='indskr_customerlistperiod' link-type='inner' alias='ac'>
					<filter type='and'>
						<condition attribute='indskr_liststartdate' operator='on-or-before' value='{date}' />
						<condition attribute='indskr_listenddate' operator='on-or-after' value='{date}' />
					</filter>
				</link-entity>
			</link-entity>
		</link-entity>
    {{secondaryinfoFetchXml}}
	</entity>
</fetch>
    `,
fetchAllChildUsersBasedOnPositionId: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
	<entity name="indskr_userposition">
		<attribute name="indskr_userpositionid" />
		<attribute name="indskr_name" alias="childpositionname"/>
		<attribute name="indskr_positionid" alias="childpositionid"/>
		<filter type="and">
			<condition attribute="statecode" operator="eq" value="0" />
		</filter>
		<link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner">
			<attribute name="fullname" alias="userfullname" />
			<filter type="and">
				<condition attribute="positionid" operator="not-null" />
				<condition attribute="isdisabled" operator="eq" value="0" />
			</filter>
		</link-entity>
		<link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
			<filter type="and">
				<condition attribute="parentpositionid" operator="eq" value='{positionID}' />
			</filter>
		</link-entity>
	</entity>
</fetch>
`,
fetchAllChildPositionsBasedOnPositionId: `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
	<entity name="position">
		<attribute name="name" alias="userfullname"/>
		<attribute name="positionid" alias="childpositionid"/>
		<attribute name="parentpositionid" />
		<order attribute="name" descending="false" />
		<filter type="and">
			<condition attribute="indskr_status" operator="eq" value="100000000" />
			<condition attribute="parentpositionid" operator="eq"  value='{positionID}' />
		</filter>
	</entity>
</fetch>
`,
fetchAllSiblingPositionsBasedOnPositionId:`
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="position">
    <order attribute="name" descending="false" />
    <filter type="and">
      <condition attribute="positionid" operator="in">
      {positionIDstring1}
      </condition>
    </filter>
    <link-entity name="position" from="positionid" to="parentpositionid" link-type="inner" alias="parentposition">
      <attribute name="positionid" />
      <attribute name="name" />
      <link-entity name="position" from="parentpositionid" to="positionid" link-type="inner" alias="siblingposition" >
        <attribute name="positionid" />
        <attribute name="name" />
         <filter type="and">
          <condition attribute="positionid" operator="not-in">
          {positionIDstring2}
          </condition>
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>
`,
fetchAllSiblingUsersBasedOnPositionId:`
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
	<entity name="indskr_userposition">
		<attribute name="indskr_userpositionid" />
		<attribute name="indskr_name" alias="siblingPosition"/>
		<attribute name="indskr_positionid" alias="siblingPositionId"/>
    <attribute name="indskr_userid" alias="siblingUser" />
		<filter type="and">
			<condition attribute="statecode" operator="eq" value="0" />
      <condition attribute="indskr_positionid" operator="in">{siblingPositionIds}</condition>
		</filter>
    <link-entity name="systemuser" from="systemuserid" to="indskr_userid" link-type="inner">
			<attribute name="fullname" alias="siblingUserName" />
			<filter type="and">
				<condition attribute="positionid" operator="not-null" />
        <condition attribute="isdisabled" operator="eq" value="0" />
			</filter>
		</link-entity>
	</entity>
</fetch>
`,
  sampleUnitPricing: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="product">
    <attribute name="productid" />
    <attribute name="name" alias="skuName"/>
    <attribute name="productid"  alias= "indskr_skuid" />
    <filter type="and">
      <condition attribute="indskr_producttype" operator="eq" value="100000003" />
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="pricelevel" from="pricelevelid" to="pricelevelid" link-type="outer">
      <attribute name="transactioncurrencyid" alias="skuCurrencyId"/>
    </link-entity>
    <link-entity name="product" from="productid" to="indskr_parentproductid" link-type="inner" alias="bd">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="indskr_productassignment" from="indskr_productid" to="productid" alias="aa">
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="position" from="positionid" to="indskr_positionid" alias="product_position" distinct="true">
          <filter type="or">
            <filter type="and">
              <condition attribute="indskr_status" operator="eq" value="100000000" />
              <condition attribute="positionid" operator="in">{positionIds}</condition>
            </filter>
            <filter type="and">
              <condition attribute="indskr_status" operator="eq" value="100000000" />
              <condition attribute="parentpositionid" operator="in">{positionIds}</condition>
            </filter>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_sampleunitpricing" from="indskr_product" to="productid" link-type="inner" alias="ab">
      <attribute name="indskr_unitprice"  alias="skuUnitPrice"/>
      <attribute name="indskr_businessunit" alias="buId"/>
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
    </link-entity>
  </entity>
</fetch>`,
coaching: {
  coachingPlans: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_coachingplan">
    <attribute name="indskr_coachingplanid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_trainee" />
    <attribute name="statuscode" />
    <attribute name="indskr_requestdate" />
    <attribute name="indskr_objectiveandoutcome" />
    <attribute name="indskr_expectedcompletiondate" />
    <attribute name="indskr_approvaldate" />
    <attribute name="indskr_actualcompletiondate" />
    <attribute name='indskr_coachingtemplate'/>
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
      <condition attribute="statuscode" operator="eq" value="548910001" />
      <condition attribute="indskr_trainee" operator="not-null" />
      {DeltaCondition}
    </filter>
    <link-entity name="indskr_associated_coatchingplan_users_nn" from="indskr_coachingplanid" to="indskr_coachingplanid" visible="false" intersect="true">
      <link-entity name="systemuser" from="systemuserid" to="systemuserid" alias="ab">
        <filter type="and">
          <condition attribute="systemuserid" operator="eq-userid" />
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`,
coachingPlanAtivity:  `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
<entity name="indskr_coachingplanactivity">
  <attribute name="indskr_coachingplanactivityid"/>
  <attribute name="indskr_name"/>
  <attribute name="createdon"/>
  <attribute name="statecode"/>
  <attribute name="indskr_procedurelog"/>
  <attribute name="modifiedon"/>
  <attribute name="indskr_meeting"/>
  <attribute name="createdonbehalfby"/>
  <attribute name="createdby"/>
  <attribute name="indskr_coachingplan"/>
  <order attribute="indskr_name" descending="false"/>
  <filter type="and">
  {deltaSyncCond}
  </filter>
  <link-entity name="indskr_coachingplan" from="indskr_coachingplanid" to="indskr_coachingplan" link-type="inner" alias="an">
    <link-entity name="indskr_associated_coatchingplan_users_nn" from="indskr_coachingplanid" to="indskr_coachingplanid" visible="false" intersect="true">
      <link-entity name="systemuser" from="systemuserid" to="systemuserid" alias="ab">
        <filter type="and">
          <condition attribute="systemuserid" operator="eq-userid" />
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
</entity>
</fetch>`,
  },
  storeCheck: {
    productFamilyCategory: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_productfamilycategory">
        <attribute name="indskr_productfamilycategoryid" />
        <attribute name="indskr_name" />
        <attribute name="indskr_default" />
        <attribute name="createdon" />
        <order attribute="indskr_name" descending="false" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="indskr_indskr_productfamilycategory_product" from="indskr_productfamilycategoryid" to="indskr_productfamilycategoryid" link-type="inner">
          <link-entity name="product" from="productid" to="productid" alias="ag" link-type="inner" >
            <attribute name="name" alias="productName" /> 
            <attribute name="productid" alias="productId" /> 
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="indskr_position_product" from="productid" to="productid" link-type="inner">
              <link-entity name="position" from="positionid" to="positionid">
                <filter type="or">
                  <filter type="and">
                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                    <condition attribute="positionid" operator="in">
                      {positionIds}
                    </condition>
                  </filter>
                  <filter type="and">
                    <condition attribute="indskr_status" operator="eq" value="100000000" />
                    <condition attribute="parentpositionid" operator="in">
                      {positionIds}
                    </condition>
                  </filter>
                </filter>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
    </fetch>`,
    productFamilyCateoryByStoreCheckId: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="appointment">
        <attribute name="indskr_productfamilycategory" alias="productfamilycategory" />
        <filter type="and">
          <condition attribute="indskr_type" operator="eq" value="548910000" />
          <condition attribute="activityid" operator="eq" value="{storeCheckId}"/>
        </filter>
      </entity>
    </fetch>`
  },
  appeal: {
    pendingApprovals: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_approvalactivity">
      <attribute name="indskr_approvalactivityid" />
      <attribute name="createdon" alias="sentForApproval"/>
      <attribute name="statuscode" />
      <attribute name="modifiedon" />
      <order attribute="modifiedon" descending="true" />
      <filter type="and">
        <condition attribute="statuscode" operator="eq" value="548910000" />
      </filter>
      <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner">
        <filter type="and">
          <condition attribute="ownerid" operator="eq-userid" />
        </filter>
      </link-entity>
      <link-entity name="indskr_gpscheckindetails" from="indskr_gpscheckindetailsid" to="indskr_gpscheckindetails" link-type="inner">
        <link-entity name="appointment" from="activityid" to="indskr_appointment" link-type="inner">
          <attribute name="activityid" alias="activityId"/>
          <attribute name="ownerid" alias="ownerId"/>
          <link-entity name="indskr_activityaccount" from="indskr_appointmentid" to="activityid" link-type="inner">
            <attribute name="indskr_accountid" alias="accountId"/>
          </link-entity>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>  
  `,
  myApprovalReq: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_approvalactivity">
    <attribute name="indskr_approvalactivityid" />
    <attribute name="createdon" alias="sentForApproval"/>
    <attribute name="statuscode" />
    <attribute name="modifiedon" />
    <order attribute="modifiedon" descending="true" />
    <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner">
      <attribute name="ownerid" alias="ownerId"/>
    </link-entity>
    <link-entity name="indskr_gpscheckindetails" from="indskr_gpscheckindetailsid" to="indskr_gpscheckindetails" link-type="inner">
      <filter type="and">
        <condition attribute="createdby" operator="eq-userid" />
      </filter>
      <link-entity name="appointment" from="activityid" to="indskr_appointment" link-type="inner">
        <attribute name="activityid" alias="activityId"/>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
            <attribute name="name" alias="positionName"/>
        </link-entity>
        <link-entity name="indskr_activityaccount" from="indskr_appointmentid" to="activityid" link-type="inner" alias="ag">
          <attribute name="indskr_accountid" alias="accountId"/>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
</fetch>
`,
  fetchAppealDetails: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_approvalactivity">
    <attribute name="indskr_approvalactivityid" />
    <attribute name="createdon" alias="sentForApproval"/>
    <attribute name="statuscode" />
    <attribute name="modifiedon" />
    <attribute name="indskr_reason" />  
    <order attribute="modifiedon" descending="true" />
    <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner">
      <attribute name="ownerid" alias="approverName"/>
    </link-entity>
    <filter type="and">
      <condition attribute="indskr_approvalactivityid" operator="eq" value="{approvalActivityId}" />
    </filter>
    <link-entity name="indskr_gpscheckindetails" from="indskr_gpscheckindetailsid" to="indskr_gpscheckindetails" link-type="inner">
      <attribute name="indskr_gpscheckindetailsid" alias="indskr_gpscheckindetailid"/>
      <attribute name="indskr_checkindatetime" alias="checkindatetime"/>
      <attribute name="indskr_checkoutstatus" alias="checkoutstatus"/>
      <attribute name="indskr_checkinstatus" alias="checkinstatus"/>
      <attribute name="indskr_checkoutdatetime" alias="checkoutdatetime"/>
      <attribute name="indskr_checkinlatitude" alias="checkinlatitude"/>
      <attribute name="indskr_checkinlongitude" alias="checkinlongitude"/>
      <attribute name="indskr_checkoutlatitude" alias="checkoutlatitude"/>
      <attribute name="indskr_checkoutlongitude" alias="checkoutlongitude"/>
      <attribute name="statuscode"/>
    
      <link-entity name="appointment" from="activityid" to="indskr_appointment" link-type="inner">
        <attribute name="subject" alias="appSubject"/>
        <attribute name="activityid" alias="activityId"/>
        <attribute name="location" alias="appLocation"/>
        <attribute name="ownerid" alias="ownerId"/>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
            <attribute name="name" alias="positionName"/>
        </link-entity>
        <link-entity name="indskr_activityaccount" from="indskr_appointmentid" to="activityid" link-type="inner" alias="ag">
          <attribute name="indskr_accountid" alias="accountId"/>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_activityphotos" from="indskr_gpscheckindetails" to="indskr_gpscheckindetailsid" link-type="inner">
        <attribute name="indskr_type" alias="type"/>
        <filter type="and" >
          <condition attribute="indskr_gpscheckindetails" operator="not-null" />
        </filter>
        <link-entity name="indskr_indskr_activityphotos_indskr_photoattac" from="indskr_activityphotosid" to="indskr_activityphotosid" visible="false" intersect="true" >
          <link-entity name="indskr_photoattachment" from="indskr_photoattachmentid" to="indskr_photoattachmentid" visible="false" intersect="true" >
            <attribute name="indskr_photoattachmentid" alias="photoAttachmentId" />
            <attribute name="indskr_name" alias="name" />
            <attribute name="indskr_photourl" alias="photoUrl" />
            <attribute name="overriddencreatedon" alias="createdOn" />
            <filter type="and" >
              <condition attribute="indskr_photoorigin" operator="eq" value="548910004" />
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
  </entity>
  </fetch>`,
  fetchForReview:`
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="indskr_gpscheckindetails">
          <attribute name="indskr_gpscheckindetailsid"/>
          <attribute name="indskr_name"/>
          <attribute name="createdon"/>
          <link-entity name="indskr_approvalactivity" from="indskr_gpscheckindetails" to="indskr_gpscheckindetailsid">
            <attribute name="indskr_approvalactivityid" alias="approvalActivityId"/>
            <filter type="and">
              <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
              <condition attribute="statuscode" operator="eq" value="548910000"/>
            </filter>
            <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver">
              <filter type="and">
                <condition attribute="ownerid" operator="eq-userid" />
              </filter>
            </link-entity>
          </link-entity>
          <link-entity name="appointment" from="activityid" to="indskr_appointment" visible="false" link-type="outer" alias="meeting">
            <attribute name="activityid" />
            <attribute name="subject"/>
            <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
              <attribute name="name" alias="positionName"/>
            </link-entity>
            <link-entity name="systemuser" from="systemuserid" to="createdby" link-type="inner">
              <attribute name="fullname" alias="userfullname" />
            </link-entity>
          </link-entity>
        </entity>
    </fetch>
    `,
  fetchForApproved:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_gpscheckindetails">
        <attribute name="indskr_gpscheckindetailsid"/>
        <attribute name="indskr_name"/>
        <attribute name="createdon"/>
        <link-entity name="appointment" from="activityid" to="indskr_appointment" visible="false" link-type="outer" alias="meeting">
          <attribute name="activityid" />
          <attribute name="subject"/>
          <filter type="and">
            <condition attribute="indskr_positionid" operator="eq" value="{positionId}"/>
          </filter>
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
            <attribute name="name" alias="positionName"/>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_approvalactivity" from="indskr_gpscheckindetails" to="indskr_gpscheckindetailsid" link-type="inner" alias="ac">
          <attribute name="modifiedon" />
          <attribute name="indskr_approvalactivityid" alias="approvalActivityId"/>
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="548910001"/>
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
          </filter>
          <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner" alias="ad">
            <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="ae" >
              <attribute name="fullname" alias="userfullname" />
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
  </fetch>
  `,
  fetchForRejected:`
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_gpscheckindetails">
        <attribute name="indskr_gpscheckindetailsid"/>
        <attribute name="indskr_name"/>
        <attribute name="createdon"/>
        <link-entity name="appointment" from="activityid" to="indskr_appointment" visible="false" link-type="outer" alias="meeting">
          <attribute name="activityid" />
          <attribute name="subject"/>
          <filter type="and">
            <condition attribute="indskr_positionid" operator="eq" value="{positionId}"/>
          </filter>
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
            <attribute name="name" alias="positionName"/>
          </link-entity>
        </link-entity>
        <link-entity name="indskr_approvalactivity" from="indskr_gpscheckindetails" to="indskr_gpscheckindetailsid" link-type="inner" alias="ac">
          <attribute name="modifiedon" />
          <attribute name="indskr_approvalactivityid" alias="approvalActivityId"/>
          <attribute name="indskr_reason" />  
          <filter type="and">
            <condition attribute="statuscode" operator="eq" value="548910002"/>
            <condition attribute="modifiedon" operator="ge" value="{lastUpdatedTime}" />
          </filter>
          <link-entity name="indskr_approver_type" from="indskr_approver_typeid" to="indskr_approver" link-type="inner" alias="ad">
            <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="ae" >
              <attribute name="fullname" alias="userfullname" />
            </link-entity>
          </link-entity>
        </link-entity>
      </entity>
  </fetch>
`,
  fetchForPendingForSubmission:`
  <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_gpscheckindetails">
      <attribute name="indskr_gpscheckindetailsid"/>
      <attribute name="indskr_name"/>
      <attribute name="createdon"/>
      <order attribute="createdon" descending="true" />
      <filter type="and">
        <condition attribute="statuscode" operator="eq" value="1"/>
			  <condition attribute="ownerid" operator="eq-userid" />
        {DeltaCondition}
		  </filter>
      <link-entity name="appointment" from="activityid" to="indskr_appointment" visible="false" link-type="outer" alias="meeting">
        <attribute name="activityid" />
        <attribute name="subject"/>
        <attribute name="omnip_datecompleted"/>
        <filter type="and">
              <condition attribute="statecode" operator="eq" value="1"/>
              <condition attribute="indskr_positionid" operator="eq" value="{positionId}"/>
        </filter>
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
          <attribute name="name" alias="positionName"/>
        </link-entity>
        <link-entity name="systemuser" from="systemuserid" to="createdby" link-type="inner">
          <attribute name="fullname" alias="userfullname" />
        </link-entity>
      </link-entity>
    </entity>
</fetch>
  `
  },
  offtakeCheck: {
    offtakeProducts: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
    <entity name="indskr_businessunitproduct">
      <attribute name="indskr_businessunitproductid" />
      <filter type="and">
        <condition attribute="indskr_businessunit" operator="eq-businessid" />
        <condition attribute="statecode" operator="eq" value="0" />
        <condition attribute="indskr_offtakecheck" operator="eq" value="1" />
      </filter>
      <link-entity name="product" from="productid" to="indskr_product" link-type="inner">
        <attribute name="productid" alias="skuId"/>
        <attribute name="name" alias="skuName"/>
        <attribute name="productnumber" alias="skuNum"/>
        <filter type="and">
          <condition attribute="indskr_producttype" operator="eq" value="100000003" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <link-entity name="product" from="productid" to="indskr_parentproductid" link-type="inner">
          <attribute name="productid" alias="productId"/>
          <attribute name="name" alias="productName"/>
          <filter type="and">
            <condition attribute="statecode" operator="eq" value="0" />
            <condition attribute="indskr_producttype" operator="eq" value="100000002" />
          </filter>
        </link-entity>
      </link-entity>
    </entity>
  </fetch>`,
    offTakeCheckCollections: `<fetch {topPlaceholder} version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_offtakecheck">
    <attribute name="indskr_offtakecheckid" />
    <attribute name="indskr_storeinventory" />
    <attribute name="indskr_skucode" />
    <attribute name="indskr_sku" alias="skuId"/>
    <attribute name="indskr_offtakechecktype" />
    <attribute name="indskr_offtakedate" />
    <attribute name="modifiedon" />
    <attribute name="modifiedby" />
    <attribute name="indskr_inventoryofftake" />
    <attribute name="indskr_distributedornot" />
    <attribute name="indskr_brand" alias="productId"/>
    <attribute name="indskr_account" alias="accountId"/>
    <order attribute="modifiedon" descending="false" />
    <filter type="and">
      <condition attribute="indskr_account" operator="eq" value="{accountId}"/>
      <filter type="and">
        <condition attribute="indskr_offtakedate" operator="on-or-after" value="{startDate}" />
        <condition attribute="indskr_offtakedate" operator="on-or-before" value="{endDate}" />
      </filter>
    </filter>
  </entity>
</fetch>`
  },
  approvalreasonconfig: {
    gpscheckindetails:`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_approvalreasonconfiguration">
      <attribute name="indskr_name"/>
      <attribute name="indskr_approvalitem"/>
      <attribute name="indskr_mandatory"/>
      <attribute name="indskr_format"/>
      <attribute name="indskr_type"/>
      <filter type="and">
        <condition attribute="indskr_approvalitem" operator="eq" value="548910035"/>
        <condition attribute="statuscode" operator="eq" value="1" />
      </filter>
      <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfiguration" link-type="inner" alias="aj">
        <filter type="and">
          <condition attribute="indskr_bid" operator="eq" value="{businessUnitId}"/>
        </filter>
      </link-entity>
      <link-entity name="indskr_approvalreasonlist" from="indskr_approvalreasonconfiguration" to="indskr_approvalreasonconfigurationid" link-type="outer" alias="ac">
        <attribute name="indskr_approvalreasonlistid"/>
        <attribute name="indskr_name"/>
        <filter type="and">
          <condition attribute="statuscode" operator="eq" value="1" />
        </filter>
      </link-entity>
    </entity>
  </fetch>`
  },
  setBooking: {
    userAndDateFilter: `
      <link-entity name="indskr_setbookingteammember" from="indskr_setbooking" to="indskr_setbookingid" visible="false" intersect="true" link-type="outer"></link-entity>
      <filter type="and">
        <filter type="or">
            <condition attribute="ownerid" operator="eq-userid" />
            <condition attribute="indskr_salesconsultant" operator="eq-userid" />
            <condition entityname='indskr_setbookingteammember' attribute='indskr_member'  operator='eq-userid'/>  
        </filter>
        <filter type="and">
            {DeltaSyncFilter}
            <condition attribute="indskr_operationenddate" operator="on-or-after" value="{startDate}" />
            <condition attribute="indskr_operationenddate" operator="on-or-before" value="{endDate}" />
        </filter>
      </filter>
    `,
    userAndDateFilterForTeamView:`
      <link-entity name="indskr_setbookingteammember" from="indskr_setbooking" to="indskr_setbookingid" visible="false" intersect="true" link-type="outer"></link-entity>
      <filter type="and">
        <filter type="or">
            <condition attribute="ownerid" operator="in">{userIDs}</condition>
            <condition attribute="indskr_salesconsultant" operator="in">{userIDs}</condition>
            <condition entityname='indskr_setbookingteammember' attribute='indskr_member'  operator="in">{userIDs}</condition>  
        </filter>
        <filter type="and">
            {DeltaSyncFilter}
            <condition attribute="indskr_operationenddate" operator="on-or-after" value="{startDate}" />
            <condition attribute="indskr_operationenddate" operator="on-or-before" value="{endDate}" />
        </filter>
      </filter>
    `,
    productLimitiedRelease:`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="product">
        <attribute name="productid" />
        <attribute name="indskr_limitedrelease" />
        <filter type="and">
          <condition attribute="productid" operator="in">{productIDs}</condition>
        </filter>
      </entity>  
    </fetch>`,
    formSectionConfig:`<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
      <entity name="indskr_formsectionsconfiguration">
        <attribute name="indskr_formsectionsconfigurationid"/>
        <attribute name="indskr_name"/>
        <attribute name="indskr_mobileappform"/>
        <attribute name="indskr_visibility"/>
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0"/>
        </filter>
      </entity>
    </fetch>`,
    fetchAllProductsForProductTypeOnlySurgeon: `<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>
      <entity name='product'>
        <attribute name='name'/>
        <attribute name='productid'/>
        <attribute name='productnumber'/>
        <attribute name='indskr_recordtype'/>
        <attribute name='indskr_itemcount'/>
        <order attribute='productnumber' descending='false'/>
        <filter type='and'>
          <condition attribute='statecode' operator='ne' value='1'/>
        </filter>
      </entity>
    </fetch>`
  },
  fetchPresentationUserTagsHierarchy: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
  <entity name="indskr_usertag">
    <attribute name="indskr_usertagid" />
    <attribute name="indskr_name" />
    <filter type="and">
      <condition attribute="indskr_tagtype" operator="eq" value="548910001" />
      <condition attribute="indskr_parenttag" operator="not-null" />
      <condition attribute="indskr_tagged_for" operator="eq" value="548910000" />
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="indskr_indskr_usertag_indskr_iopresentation" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true" >
      <attribute name="indskr_iopresentationid" alias="presentation_id" />
    </link-entity>
    <link-entity name="indskr_usertag" from="indskr_usertagid" to="indskr_parenttag" link-type="inner" alias="aa">
      <attribute name="indskr_name" alias="parent_tag_name" />
      <attribute name="indskr_usertagid" alias="parent_tag_id" />
    </link-entity>
    <link-entity name="indskr_usertag_indskr_bulevelconfigurat" from="indskr_usertagid" to="indskr_usertagid" visible="false" intersect="true">
      <link-entity name="indskr_bulevelconfiguration" from="indskr_bulevelconfigurationid" to="indskr_bulevelconfigurationid" alias="ac">
        <filter type="and">
          <condition attribute="indskr_bid" operator="eq"  value="{businessUnitId}" />
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>`
};
//55b15eae-8fda-4a4e-9f76-45c66e85c76d
export function fetchXmlEntity(entity, pretty?) {
  return parse('fetch', {
    '@': {
      'version': '1.0',
      'output-format': 'xml-platform',
      'mapping': 'logical',
      'distinct': 'true',
    },
    entity,
  }, { declaration: { include: false }, format: { pretty: !!pretty } });
}

export function getDeltaSyncFilter(hourDifference, entityName, entityID) {
  let deltaSyncStr = fetchQueries.deltaSyncFilter;
  deltaSyncStr = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityName);
  deltaSyncStr = deltaSyncStr.replace('{hourDifference}', hourDifference);
  deltaSyncStr = deltaSyncStr.replace('{entityID}', entityID);
  return deltaSyncStr;
}
