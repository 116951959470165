<ion-header>
    <ind-page-title *ngIf="contactService?.contactInformation" [viewData]='{id:"contact-details",title: contactService?.contactInformation?.fullName, controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    <ind-page-title *ngIf="contactService?.selectedOKContact?.value" [viewData]='{id:"ok-contact-details",title: contactService.getFullName(contactService?.selectedOKContact?.value), controls: okPageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
    
    <!--Customer Glance Card-->  
    <glance-card *ngIf="contactGlanceViewData" [viewData]="contactGlanceViewData" (onControlClick)="onQuickGlanceControlClick($event)"> </glance-card>
    <ion-toolbar *ngIf="uiService.contactDetailsSegment !='customer-assess-teamview'">
        <ind-tabs [data]="uiService.tabsData" [selectedTab]="contactDetailsSegment" (selectedTabChange)="segmentChanged($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>
<ion-content no-padding [scrollEvents]="true" (ionScroll)="onScroll($event)">
    <!--<io-spinner [displayText]="uiService.contactDetailsSegment" *ngIf="!device.isOffline && ((!contactService.isContactDetailsLoaded && uiService.contactDetailsSegment==='timeline') || (!contactService.isContactProfileLoaded && uiService.contactDetailsSegment==='profile'))"></io-spinner>-->
    <div [ngSwitch]="contactDetailsSegment" class="switchConditionContainer" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin()}">
        <!--Customer Feed Tab-->
        <contact-feed *ngSwitchCase="'feed'" [readOnly]="isFromChat"></contact-feed>
        <contact-info *ngSwitchCase="'info'" (editContactHandler)="onEditContact()" [enableEditBtn]="!device.isOffline && (buLine == 1 || enableEditBtn)" [scrollObservable]="scrollObservable" [accessedFrom] = "accessedFrom"></contact-info>
        <contact-profile *ngSwitchCase="'profile'" (openScientificPlanDetails)="onOpenScientificPlanDetails($event)" [readOnly]="isFromChat"></contact-profile>
        <contact-timeline *ngSwitchCase="'timeline'"></contact-timeline>
        <contact-consent *ngSwitchCase="'consent'"></contact-consent>
        <contact-consent-capture #ccCapture *ngSwitchCase="'capture'" [from]="contactService.consentFrom"></contact-consent-capture>
        <customer-assess #contactAssessment [isLoading]="customerAssessmentIsLoading" [templates]="contactAssessmentTemplates" [templatesTeamview]="contactAssessmentTemplatesTeamview"
          [rawData]="assessRawData" *ngSwitchCase="'customer-assess'" (isFormDirty)="isContactAssessmentFormDirty($event)"
          (savedData)="savedCustomerAssessment($event)" [specialty]="contactInfo?.primarySpecialty"></customer-assess>
        <customer-assess-teamview #contactAssessmentTeamview [isLoading]="customerAssessmentTeamviewIsLoading" [templates]="contactAssessmentTemplates" [templatesTeamview]="contactAssessmentTemplatesTeamview"
          [rawData]="assessRawData" *ngSwitchCase="'customer-assess-teamview'" [specialty]="contactInfo?.primarySpecialty"></customer-assess-teamview>
    </div>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="onFooterButtonClicked($event)" [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar>
<!-- </ion-footer> -->
