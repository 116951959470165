import { fetchQueries } from '../config/dynamics-fetchQueries';
import { DynamicsClientService } from '../data-services/dynamics-client/dynamics-client.service';
import { Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';
import { timezones } from '../../assets/timezones/timezone';
@Injectable({
  providedIn: 'root'
})
export class MSEventsService {
  public readonly events: Observable<any>;
  constructor(private dynamics: DynamicsClientService) {
    this.events = Observable.create(observer => {
      this.dynamics.executeFetchQuery('msevtmgt_events', fetchQueries.events).catch(() => [])
        .then(data => {
          for (let event of data) {
            let startDate = event.startDate.toISOString();
            let endDate = event.endDate.toISOString();
            let offset = timezones.find((zone)=> zone.code === event.msevtmgt_eventtimezone);
            if(offset){
              startDate = startDate.replace('Z',offset.offset);
              endDate = endDate.replace('Z',offset.offset);
            }
            event.startDate = new Date(startDate);
            event.endDate = new Date(endDate);
          }
          console.log(data);
          observer.next(data)
        })
        .catch(err => observer.error(err));
    });
  }

  async associateEvent(eventId: string, activityId: any) {
    return await this.dynamics.update(activityId, 'appointments', {'indskr_Event_Appointment@odata.bind': `msevtmgt_events(${eventId})`});
  }

  async getParticipantRegistrations(eventId: string) {
    let dynamicsData = this.dynamics.retrieveAll('msevtmgt_eventregistrations', ['_msevtmgt_contactid_value'], `_msevtmgt_eventid_value eq ${eventId}`).catch(() => ({value:[]}));
    //@ts-ignore
    return (await dynamicsData).value.map(ev => ev._msevtmgt_contactid_value)
    return [];
  }
}
