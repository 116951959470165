import {Injectable} from "@angular/core";
import {ConsentService} from "./consent.service";
import {Channel, ChannelType, ChannelValue, ChannelActivityType} from "../../classes/consent/channel.class";
import {ConsentTerm} from "../../classes/consent/consent-term.class";
import {Product} from "../../classes/consent/product.class";
import {TrackingEventNames, TrackService} from "../logging/tracking.service";
import {NotificationService, ToastStyle} from "../notification/notification.service";
import {ContactConsent} from "../../classes/consent/contact-consent.class";
import {ContactOfflineService} from "../contact/contact.service";
import {FooterService} from "../footer/footer.service";
import _ from "lodash";
import {Contact} from "../../classes/contact/contact.class";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import { AuthenticationService } from "../authentication.service";


@Injectable({
  providedIn: 'root'
})
export class ConsentTermGenerateService {

  private consentTerms: ConsentTerm[] = [];
  public selectedProducts: Product[] = [];
  public selectedChannels: Channel[] = [];
  private filteredTerms: ConsentTerm[];
  private generatedTerms: ConsentTerm[];
  public contact: Contact = null;
  public selectedContactConsents: ContactConsent;
  private contactConsentSubscription: Subscription;

  constructor(
    private consentService: ConsentService,
    private contactService: ContactOfflineService,
    public footerService: FooterService,
    public notificationService: NotificationService,
    private trackingService: TrackService,
    public translate: TranslateService,
    private authService: AuthenticationService
  ) {
    this.getAllConsentTerms();
  }

  getAllConsentTerms() {
      this.consentService.allConsentTermsSubject.subscribe(
        (terms: ConsentTerm[]) => {
          if (terms) {
            this.consentTerms = terms;
          }
        }
      );
  }

  getSelectedContactConsents() {
      if (this.contactConsentSubscription)
        this.contactConsentSubscription.unsubscribe();
      this.contactConsentSubscription = this.consentService.allActiveConsentSubject.subscribe(
        (consents: ContactConsent[]) => {
          if (consents.length && this.contactService.contactInformation) {
            this.selectedContactConsents = consents.filter(
              (consent: ContactConsent) =>
                consent.indskr_contactid.toString() ===
                this.contactService.contactInformation.ID.toString()
            )[0];
          }
        }
      );
  }

  generateConsentTerms(isClickedGenerateBtn: boolean = false): ConsentTerm[] {
    this.contact = this.contactService.contactInformation;
    this.filteredTerms = [];
    this.generatedTerms = [];
    this.trackingService.tracking(
      "GenerateConsent",
      TrackingEventNames.CONSENT
    );
    if ((this.authService.user.isProductConsent && this.selectedProducts.length && this.selectedChannels.length) || (!this.authService.user.isProductConsent && this.selectedChannels.length)) {
      let isFbSelected = this.selectedChannels.some(e=> e.activityType === ChannelActivityType.FACEBOOK);
      if (isFbSelected) {
        let isEmailConsentGiven: boolean = this.checkIfFacebookChannelSelected();
        if (isEmailConsentGiven) {
          this.filterConsentTerms(isClickedGenerateBtn);
          this.noTermsNotification();
        } else {
          this.footerService.disableButton(["consentFlip"]);
          this.showMessageFacebook();
        }
      } else {
        this.filterConsentTerms(isClickedGenerateBtn);
        this.noTermsNotification();
      }
    }
    return this.generatedTerms;
  }

  showMessageFacebook() {
    this.notificationService.notify(
      this.translate.instant('EMAIL_CONSENT_REQUIRED_FOR_FACEBOOK'),
      "Consent Capture",
      "top",
      ToastStyle.DANGER,
      3000,
      true
    );
  }

  checkIfFacebookChannelSelected(): boolean {
    let isEmailConsentGiven: boolean = false;
    this.getSelectedContactConsents();
    if (this.selectedContactConsents) {
      isEmailConsentGiven = this.selectedContactConsents.activeConsents.some(consent => {
        if (consent.channels) {
          return consent.channels.some(ch => (ch.activityType == ChannelActivityType.EMAIL) || (ch.indskr_consentType.toUpperCase() == ChannelType.EMAIL.toUpperCase()));
        }
      });
    }
    return isEmailConsentGiven;
  }

  noTermsNotification() {
    if (!this.generatedTerms.length) {
      this.notificationService.notify(
        this.translate.instant('NO_CONSENT_TERMS_OR_ALREADY_TAKEN'),
        "Consent Capture",
        "top",
        ToastStyle.DANGER,
        3000,
        true
      );
      this.footerService.enableButtons(["consentFlip"], false);
    } else {
      this.footerService.enableButtons(["consentFlip"]);
    }
  }

  //Step 1: Filter Consent Terms
  filterConsentTerms(isClickedGenerateBtn: boolean = false) {
    if (this.consentTerms.length) {
      const cTerms: ConsentTerm[] = JSON.parse(JSON.stringify( this.consentTerms ));
      cTerms.forEach((term: ConsentTerm) => {
        if (term.channels.length) {
          let channelMatches: boolean = this.isChannelMatches(term, isClickedGenerateBtn);
          if (channelMatches) {
            if(!this.authService.user.isProductConsent){
                term.products = [...this.selectedProducts];
                term.isSavedConsent = false;
                term.isOfflineSaved = false;
                term.isSelectedTerm = false;
                term.isAcceptedTerms = false;
                this.filteredTerms.push(term);
            }else{
              let productMatches = this.isProductMatches(term, isClickedGenerateBtn);
              if (productMatches) {
                term.isSavedConsent = false;
                term.isOfflineSaved = false;
                term.isSelectedTerm = false;
                term.isAcceptedTerms = false;
                this.filteredTerms.push(term);
              }
            }
          }
        }
      });
      this.filterAlwaysRequiredTerms();
    }
  }

  //Step 2: Filter Always Required Terms
  filterAlwaysRequiredTerms() {
      if (this.filteredTerms.length) {
        this.generatedTerms = this.filteredTerms.filter(
          term => (term.indskr_alwaysrequired || term.indskr_recaptureconsent)
        );
        this.filteredTerms = this.filteredTerms.filter(
          term => !term.indskr_alwaysrequired
        );

        let filteredListBroadTerms: ConsentTerm[] = this.getBroadestTerms(
          this.filteredTerms
        );
        let combinedList: ConsentTerm[] = [
          ...this.generatedTerms,
          ...filteredListBroadTerms
        ];
        filteredListBroadTerms = [];
        filteredListBroadTerms = this.getBroadestTerms(combinedList);
        filteredListBroadTerms.forEach(term => {
          if (!term.indskr_alwaysrequired) {
            this.generatedTerms.push(term);
          }
        });
        this.mapChannelProductValuesToConsentTerms();

        // this.filterTermsBasedOnCommonProducts();
        // if (this.filteredTerms) {
        //   this.calculateWeightageSortTerms();
        // }
      }
  }

  //Step 3: map selected channel & product values to ResultSet<ConsentTerms>
  mapChannelProductValuesToConsentTerms() {
    if (this.generatedTerms.length) {
      this.generatedTerms.forEach(term => {
        delete term._id;
        delete term._rev;
        term.indskr_contactid = this.contact.ID;
        // Reset Already selected products & channels, If any
        term.products.forEach((prod: Product) => {
          if (prod) {
            prod.isChecked = false;
          }
        });

        term.channels.forEach((chan: Channel) => {
          let channelValue: ChannelValue = null;
          chan.values = [];
          chan.isChecked = false;
          this.mapEmailValuesToConsentTerms(chan, channelValue);
          this.mapPhoneValuesToConsentTerms(chan, channelValue);
          this.mapAddressValuesToConsentTerms(chan, channelValue);
          this.mapAnyOtherValuesToConsentTerms(chan, channelValue);
          this.mapProcedureValuesToConsentTerms(chan, channelValue);
        });

        // Set selected products & channels, If any
        this.selectedProducts.forEach(product => {
          term.products.filter(
                          prod =>
                            this.authService.user.isProductConsent ? (prod.indskr_productid === product.indskr_productid &&
                          product.isChecked) : (prod.indskr_productid === product.indskr_productid &&
                              !product.isChecked)
                      ).forEach(prod => (prod.isChecked = true));
        });

        this.selectedChannels.forEach(channel => {
          term.channels.forEach(chan => {
            if (chan.indskr_consenttypeid === channel.indskr_consenttypeid) {
              chan.isChecked = channel.isChecked;
              chan.values.forEach(value => {
                value.isChecked = channel.isChecked;
              });
            }
          });
        });
      });
      this.verifyTermsAgainstActiveConsents();
    }
  }

  //Step 5: Verify If Generated Terms does contains any exact match with Active Consents
  verifyTermsAgainstActiveConsents() {
    this.getSelectedContactConsents();
    if (this.selectedContactConsents) {
      const activeConsents = this.selectedContactConsents.activeConsents;
      if (activeConsents && activeConsents.length) {

        //Group Active Consent Data in case the same term being submitted multiple times
        let uniqueObjs = _.uniqBy(activeConsents, 'indskr_consenttermsid');
        uniqueObjs.forEach(uniq => {
          let prodChannelCombo = new Set<string>();
          activeConsents.forEach(consent => {
            if (uniq.indskr_consenttermsid == consent.indskr_consenttermsid) {

              if (!_.isEmpty(consent.products)) {
                consent.products.forEach(pro => {
                  consent.channels.forEach(cha => {
                    prodChannelCombo.add(pro.indskr_productid + ":" + cha.indskr_consenttypeid);
                  });
                });
              } else {
                consent.channels.forEach(cha => {
                  prodChannelCombo.add("" + cha.indskr_consenttypeid);
                });
              }
              uniq['prodChannelCombo'] = prodChannelCombo;
            }
          });
        });

        this.generatedTerms.forEach(term => {
          let prodChannelCombo = new Set<string>();
          if (!_.isEmpty(term.products)) {
            term.products.forEach(pro => {
              term.channels.forEach(cha => {
                prodChannelCombo.add(pro.indskr_productid + ":" + cha.indskr_consenttypeid);
              });
            });
          } else{
            term.channels.forEach(cha => {
              prodChannelCombo.add("" + cha.indskr_consenttypeid);
            });
          }
          
          term['prodChannelCombo'] = prodChannelCombo;
        });


        //`groupedConsents` Array does consist of unique terms with unique product/channels data
        //so that while generating terms we can eliminate Completed Consent
        uniqueObjs.forEach(consent => {
          this.generatedTerms.forEach((term, i) => {
            if (consent.indskr_consenttermsid == term.indskr_consenttermsid && !term.indskr_recaptureconsent) {
              if (consent['prodChannelCombo'].size == term['prodChannelCombo'].size) {
                this.generatedTerms.splice(i, 1);
              }
            }
            if (term) {
              if (!this.consentService.isTermExpired(term)) {
                this.generatedTerms.splice(i, 1);
              }
            }
          });
        });
      }
      //NOTE: For now, excluding this step Since we no need to deep verify all products,
      // channels & it's values are matched
      // this.checkIfEveryProductMatches(activeConsent, term)
      this.disableCapturedChannelValues();
    }
  }
  
//Step-6: If Term is a Re-capturable Term, and if it was captured already, disable captured channel values
  disableCapturedChannelValues() {
    let recapturedTerms = this.generatedTerms.filter(term => term.indskr_recaptureconsent);
    let activeConsents = this.selectedContactConsents.activeConsents;
    if (recapturedTerms && recapturedTerms.length && activeConsents && activeConsents.length) {
      recapturedTerms.forEach(recap => {
        activeConsents.forEach(consent => {
          if (recap.indskr_consenttermsid === consent.indskr_consenttermsid && !_.isEmpty(recap.channels) && !_.isEmpty(consent.consentActivity)) {
             recap.channels.forEach(rC => {
              if (rC.values)
                rC.values.forEach(rCV => {
                  consent.consentActivity.forEach(cA => {
                    if (rC.indskr_consenttypeid === cA.indskr_consenttypeid) {
                      if (rC.indskr_consentType === cA.indskr_consentTypeName) {
                        if (cA.indskr_consentTypeName === ChannelType.SURGERY_ORDER || cA.indskr_consentTypeName === ChannelType.EMAIL || cA.indskr_consentTypeName === ChannelType.EMAIL_ADDRESS || cA.indskr_consentTypeName.toLowerCase().includes(ChannelType.EMAIL.toLowerCase())) {
                          if (rCV.value === cA.indskr_emailaddress) {
                            rCV['block'] = true;
                            rCV.isChecked = false;
                          }
                        } else if (cA.indskr_consentTypeName === ChannelType.PHONE || cA.indskr_consentTypeName === ChannelType.PHONE_NUMBER) {
                          if (rCV.value === cA.indskr_phone) {
                            rCV['block'] = true;
                            rCV.isChecked = false;
                          }
                        } else if (cA.indskr_consentTypeName === ChannelType.ADDRESS || cA.indskr_consentTypeName === ChannelType.VISIT || cA.indskr_consentTypeName.toLowerCase().includes(ChannelType.VISIT.toLowerCase())) {
                          if (rCV.value === cA.indskr_addressname) {
                            rCV['block'] = true;
                            rCV.isChecked = false;
                          }
                        } else if (cA.indskr_consentTypeName === ChannelType.FAX) {
                          if (rCV.value === cA.indskr_faxnumber) {
                            rCV['block'] = true;
                            rCV.isChecked = false;
                          }
                        }
                      }
                    }
                  })
                })
            })
          }
        })
      })
    }
  }

  //getting the broadest set of terms
  getBroadestTerms(terms: ConsentTerm[]): ConsentTerm[] {
      let responseTerms: ConsentTerm[] = [];
      this.selectedProducts.forEach(product => {
        //filtering the terms containing selected product
        let filteredProductTerms: ConsentTerm[] = [];
        terms.forEach(term => {
          if (term.products != null) {
            let termIndex = term.products.findIndex(
              t => t.indskr_productid === product.indskr_productid
            );
            if (termIndex >= 0) {
              filteredProductTerms.push(term);
            }
          }
        });

        let max: number = 0;
        let broadestTerms: ConsentTerm[] = [];
        if (filteredProductTerms && filteredProductTerms.length) {
          if (filteredProductTerms.length > 1) {
            filteredProductTerms.filter(fTerm => {
              let weight: number = fTerm.channels.length * fTerm.products.length;
              if (weight > max) {
                max = weight;
                broadestTerms = [];
                broadestTerms.push(fTerm);
              } else if (weight == max) {
                broadestTerms.push(fTerm);
              }
            });
          } else if (filteredProductTerms.length == 1) {
            broadestTerms.push(filteredProductTerms.pop());
          }
          responseTerms.push(...broadestTerms);
        }
      });
      responseTerms = _.uniq(responseTerms);
      return responseTerms;
  }

  isChannelMatches(term: ConsentTerm, isClickedGenerateBtn: boolean = false): boolean {
    let isMatches: boolean = this.selectedChannels
      .map(channel => channel.indskr_consenttypeid)
      .every(id => {
        return (
          term.channels.map(ch => ch.indskr_consenttypeid).indexOf(id) >= 0
        );
      });
      if (!isMatches && !isClickedGenerateBtn) {
        isMatches = this.selectedChannels
        .map(channel => channel.indskr_consenttypeid)
        .some(id => {
          return (
            term.channels
                .map(ch => ch.indskr_consenttypeid)
                .indexOf(id) >= 0
          );
        });
      }
      return isMatches;
  }

  isProductMatches(term: ConsentTerm, isClickedGenerateBtn: boolean = false): boolean {
      let isMatches: boolean = this.selectedProducts
        .map(product => product.indskr_productid)
        .every(id => {
          return (
            term.products.map(product => product.indskr_productid).indexOf(id) >=
            0
          );
        });
      if (!isMatches && !isClickedGenerateBtn) {
        isMatches = this.selectedProducts
          .map(product => product.indskr_productid)
          .some(id => {
            return (
              term.products
                .map(product => product.indskr_productid)
                .indexOf(id) >= 0
            );
          });
      }
      return isMatches;
  }

  mapEmailValuesToConsentTerms(ch: Channel, chV: ChannelValue) {
    // if (ch.indskr_consentType == ChannelType.EMAIL.toString()) {
    if (ch.activityType == ChannelActivityType.EMAIL) {
      if (this.contact.emailAddressList) {
        // removing duplicates as the contact information holds duplicate values in emailaddresslist
        const uniqueEmailAddressList = _.uniqBy(this.contact.emailAddressList, 'emailAddressId');
        uniqueEmailAddressList.forEach(email => {
          chV = new ChannelValue(
            email.emailAddressId,
            email.emailAddress,
            false
          );
          ch.values.push(chV);
        });
      }
    }
  }

  mapPhoneValuesToConsentTerms(ch: Channel, chV: ChannelValue) {
    if (ch.indskr_consentType == ChannelType.PHONE.toString()) {
      if (this.contact.mobilePhone) {
        chV = new ChannelValue(null, this.contact.mobilePhone, false);
        ch.values.push(chV);
      }
      if (this.contact.telephone) {
        chV = new ChannelValue(null, this.contact.telephone, false);
        ch.values.push(chV);
      }
      if (this.contact.indskr_alternatephone1) {
        chV = new ChannelValue(null, this.contact.indskr_alternatephone1, false);
        ch.values.push(chV);
      }
      if (this.contact.indskr_alternatephone2) {
        chV = new ChannelValue(null, this.contact.indskr_alternatephone2, false);
        ch.values.push(chV);
      }
      if (this.contact.indskr_alternatephone3) {
        chV = new ChannelValue(null, this.contact.indskr_alternatephone3, false);
        ch.values.push(chV);
      }
    }
  }

  mapAddressValuesToConsentTerms(ch: Channel, chV: ChannelValue) {
    // if (ch.indskr_consentType == ChannelType.VISIT.toString()) {
    if (ch.activityType == ChannelActivityType.VISIT) {
      if (this.contact.addressesList) {
        this.contact.addressesList.forEach(address => {
          chV = new ChannelValue(null, address.compositeAdd, false);
          ch.values.push(chV);
        });
      }
    }
  }


  mapProcedureValuesToConsentTerms(ch: Channel, chV: ChannelValue) {
    if (ch.activityType == ChannelActivityType.SURGERY_ORDER) {
      if (this.contact.emailAddressList) {
        const uniqueEmailAddressList = _.uniqBy(this.contact.emailAddressList, 'emailAddressId');
        uniqueEmailAddressList.forEach(email => {
          chV = new ChannelValue(
            email.emailAddressId,
            email.emailAddress,
            false
          );
          ch.values.push(chV);
        });
      }
    }
  }
  
  mapAnyOtherValuesToConsentTerms(ch: Channel, chV: ChannelValue) {
    if (ch.indskr_consentType == ChannelType.FAX.toString()) {
      if (this.contact.fax) {
        chV = new ChannelValue(null, this.contact.fax, false);
        ch.values.push(chV);
      }
    }
    if (ch.activityType == ChannelActivityType.WHATSAPP) {
      this.pushPhoneNumbers(ch, chV);
    }
    if (ch.activityType == ChannelActivityType.SMS) {
      this.pushPhoneNumbers(ch, chV);
    }
    if (ch.activityType == ChannelActivityType.FACEBOOK) {
      this.pushEmailAddresses(ch, chV);
    }
    if (
      !(ch.activityType in ChannelActivityType)
    ) {
      ch.values = [];
    }
  }

  pushPhoneNumbers(ch: Channel, chV: ChannelValue) {
    let phoneNumber: String = null;
    if (this.contact.mobilePhone) phoneNumber = this.contact.mobilePhone;
    if (this.contact.telephone) phoneNumber = this.contact.telephone;
    if (this.contact.indskr_alternatephone1) phoneNumber = this.contact.indskr_alternatephone1;
    if (this.contact.indskr_alternatephone2) phoneNumber = this.contact.indskr_alternatephone2;
    if (this.contact.indskr_alternatephone3) phoneNumber = this.contact.indskr_alternatephone3;
    if (phoneNumber) {
      if (!_.isEmpty(ch.values) && _.findIndex(ch.values, (ch) => ch.value === phoneNumber) >= 0) return;
        chV = new ChannelValue(null, phoneNumber, false);
        ch.values.push(chV);
    }
  }

  pushEmailAddresses(ch: Channel, chV: ChannelValue) {
    if (this.contact.emailAddressList) {
      // removing duplicates as the contact information holds duplicate values in emailaddresslist
      const uniqueEmailAddressList = _.uniqBy(this.contact.emailAddressList, 'emailAddressId');
      uniqueEmailAddressList.forEach(email => {
        chV = new ChannelValue(
          email.emailAddressId,
          email.emailAddress,
          false
        );
        ch.values.push(chV);
      });
    }
  }

}
