import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayValue } from '../display-value/display-value.component';

export type TimeLineItem = {
  id : string,
  subheaderText: string;
  subheaderText2: string;
  subheaderText2Color?: string;
  headerText: string;
  mainIcon? : string;
  refObject?: any;
  customCssClass?: string;
  dataToDisplay: DisplayValue[];
  hideEndArrow?:boolean;
  disableEndArrow?:boolean;
}

@Component({
  selector: 'timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent implements OnInit {

  @Input() inputData : TimeLineItem;
  @Output() clickedOnHeader = new EventEmitter();
  @Output() clickedOndropdown = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  popupClick(ev){
    this.clickedOndropdown.emit({ event : ev, value : this.inputData.id });
  }

  headerClick(ev){
    this.clickedOnHeader.emit({ event : ev, value : this.inputData.id });
  }

}
