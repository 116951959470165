<ion-header class="alert-option">
  <ion-item lines="none" class="title-toolbar remove_inner_bottom">
    <ion-title class="title-header"> {{header}} </ion-title>
    <ion-icon slot="end" class="cancel-icon" name="indegene-alert-close" (click)="onDismiss(false)"> </ion-icon>
  </ion-item>
</ion-header>


<ion-content>
  <ion-title size="small" class="message-text">
    <ion-label><div [innerHTML]="message"></div></ion-label>
  </ion-title>

  <ion-item lines="none">
    <ion-button slot="end" expand="block" fill="clear" size="small" (click)="onDismiss(false)" class="cancel-button">
      {{cancelText}}</ion-button>
    <ion-button slot="end" expand="block" fill="clear" size="small" (click)="onDismiss(true)" class="confirm-button">
      {{confirmText}}</ion-button>
  </ion-item>
</ion-content>