<!-- Generated template for the NewCaseManagementComponent component -->
<!-- <ion-header [ngClass]="{ 'sideStack': !isModalView }">
  <ion-toolbar [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
    <ion-title text-center>{{'NEW_ENQUIRY' | translate}}</ion-title>
  </ion-toolbar> -->
<ion-header>
    <ind-page-title [viewData]='pageTitle' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
  <ion-toolbar>
    <ind-section-header [viewData]="newCaseHeaderModel"></ind-section-header>
  </ion-toolbar>
<ion-content>
  <ion-list>
    <!--<ion-item class="type-selection" *ngFor="let item of caseManagementService.caseTypeList">
      <ion-label>{{item.name}}</ion-label>
      <ion-checkbox [checked]="item.id == selectedType" (ionChange)="toggle(item, $event)">
      </ion-checkbox>
    </ion-item>-->
    <!--Due to Bug: TC-1118 > REVERTING TO PREVIOUS VERSION-->
    <div *ngFor="let item of caseManagementService.caseTypeList">
      <ion-item>
        <div #roundInput [ngClass]="{'round': type == item, 'round-disabled': type != item}" (click)="selectType(item)">
          <input type="checkbox" [ngModel]="type == item" />
          <label [ngClass]="{
                    'selected': type == item}">
          </label>
          <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{item.name}}</span>
        </div>
      </ion-item>
    </div>
  </ion-list>
</ion-content>
