import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

const QUERY = {
  xs: '(min-width: 0px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  never: '',
};

@Component({
  selector: 'ind-split-pane',
  template: `<div class="split-pane-side">
      <slot name="side"></slot>
    </div>
    <div class="split-pane-main">
      <slot></slot>
    </div>`,
  styleUrls: ['./ind-split-pane.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class IndSplitPaneComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
