import { Component, ViewChild, Input } from '@angular/core';
import { DynamicFormsService } from '../../../services/dynamic-forms/dynamic-forms-service';
import { PopoverController, IonSearchbar } from '@ionic/angular';
import { IndDynamicFormLookupListDetailModel } from '../../../models/dynamicFormLookupListDetailModel';
import { DeviceService } from '../../../services/device/device.service';
import { LookupSearchResult } from '@omni/classes/dynamic-form/dynamic-form.class';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import _ from 'lodash';

@Component({
  selector: 'dynamic-form-lookup-popover',
  templateUrl: 'dynamic-form-lookup-popover.html',
  styleUrls:['dynamic-form-lookup-popover.scss']
})
export class DynamicFormLookupPopoverComponent {

  @Input() viewData:IndDynamicFormLookupListDetailModel;

  @ViewChild(IonSearchbar, {static: false}) search : IonSearchbar;
  debounceTime: number = 500;

  constructor(
    public dynamicFormService: DynamicFormsService,
    private device: DeviceService,
    private readonly contactService: ContactOfflineService,
    private popoverCtrl: PopoverController,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.search){
        this.search.setFocus();
      }
    }, 500);
  }

  public searchText(ev): void {
    let val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (this.viewData && this.viewData.targetControl && this.viewData.targetControl.lookupQueries) {
      try {
        let filter = this.viewData.searchFilter;
        if (!this.device.isOffline) {
          this.dynamicFormService.getLookupResults(val, this.viewData.targetControl, filter, false);
        } else {
          let sr: any = undefined;
          if(this.dynamicFormService.allLookUpFieldsForOfflineEdit && this.dynamicFormService.allLookUpFieldsForOfflineEdit.hasOwnProperty(this.viewData.targetControl.attributeName)) {
            sr = this.dynamicFormService.allLookUpFieldsForOfflineEdit[this.viewData.targetControl.attributeName];
          }
          if(!_.isEmpty(sr)) {
            const dataFromLocal = this.dynamicFormService.allLookUpFieldsForOfflineEdit[this.viewData.targetControl.attributeName];
            if(val !='') {
              const formattedSearchText = this.dynamicFormService.convertFormattedString(val).trim().toLowerCase();
              const cntWordsInSearchText = formattedSearchText.split(" ").filter(sw => {return sw !=''}).length;
              //Add jumbled text to filter in local data
              let searchTextSplitWords = formattedSearchText.split(" ").filter(sw => {return sw !=''});
              searchTextSplitWords.forEach(sw => {
                if(sw.length > 3) {
                  let jumbledText = '';
                  const searchTextSplit = sw.split("");
                  searchTextSplit.forEach((c, idx)=> {
                    if(c && idx < sw.length - 2) {
                      jumbledText = sw.substring(idx, idx + 3);
                      if(jumbledText) searchTextSplitWords.push(jumbledText);
                    }
                  });
                }
              });
              //Filtered by searchTextSplitWords
              let filteredSearchResults = [];
              for(let r of dataFromLocal) {
                let nameForSearch = this.dynamicFormService.convertFormattedString(r.nameForSearch).toLowerCase();
                let foundResult: boolean = false;
                searchTextSplitWords.forEach(sw=>{
                  sw = sw.toLowerCase();
                  if(nameForSearch.includes(sw)) {
                    foundResult = true;
                  };
                });
                if(foundResult) filteredSearchResults.push(r);
              }
              //Add weighted value to sort search results
              for(let item of filteredSearchResults) {
                let weightedMatchChar: number = 0;
                searchTextSplitWords.forEach((sw, idx) => {
                  const searchWordLength = sw.length;
                  sw = sw.toLowerCase();
                  let nameForSearch = this.dynamicFormService.convertFormattedString(item.nameForSearch).toLowerCase();
                /** Weighted values(wv) for the matched letters
                   * 1. Input text by a user (determined by cntWordsInSearchText)
                   *  a. checking exact matched words or included search parameters: wv is searchWordLength + 20 or 10,
                   *  b. matched split word is more than 3 letters: wv is searchWordLength + 3,
                   *  c. matched split word is less than 3 letters: wv is searchWordLength
                   * 2. Jumbled text
                   *  a. matched split word is 3 letters : wv is 1
                   * 3. No matched split word: wv is 0
                */
                  if(idx == 0 && searchWordLength > 3 && nameForSearch && nameForSearch.includes(sw)) {
                    weightedMatchChar += nameForSearch.length == searchWordLength ? searchWordLength + 20 : searchWordLength + 10;
                  }else {
                    weightedMatchChar += nameForSearch.split(sw).length  > 1 ? idx < cntWordsInSearchText ? searchWordLength > 3 ? searchWordLength + 3 : searchWordLength : 1 : 0;
                  }
                });
                item.totalWeightedMatch = weightedMatchChar;    
              }
              filteredSearchResults.sort((a, b) => (b.totalWeightedMatch - a.totalWeightedMatch || a.nameForSearch.localeCompare(b.nameForSearch)));

              if(formattedSearchText && formattedSearchText.trim() !='') {
                filteredSearchResults = _.filter(filteredSearchResults, function (result:LookupSearchResult) {
                  return result.totalWeightedMatch > 0;
                });
              }

              console.warn('searchTextSplitWords in local search');
              console.log(searchTextSplitWords);
              // console.warn('filteredSearchResults');
              // console.log(sr);

              //Update serach results
              this.dynamicFormService.lookupSearchData = filteredSearchResults;
              this.dynamicFormService.lookupSearchInProgress = false;
            }else {
              //If search text is empty, display all
              this.dynamicFormService.lookupSearchData = sr;
              this.dynamicFormService.lookupSearchInProgress = false;
            }
          }
        }
      }
      catch (err) {
        console.error(`Error occured while searching lookup results: ${err}`);
      }
    }
  }

  public clearValue() {
    let data = { clearValue: true };
    this.viewData.selectedValue = '';
    if (this.viewData && this.viewData.callbackEvent) {
      this.viewData.callbackEvent(data);
      this.popoverCtrl.dismiss();
    }

  }

  public setSelectedLookupValue(value) {
    let data = { selectedItem: value, isDone: true };
    this.viewData.selectedValue == value.id;
    if (this.viewData && this.viewData.callbackEvent) {
      this.viewData.callbackEvent(data);
      this.popoverCtrl.dismiss();
    }
  }

}
