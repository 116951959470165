import {Component, Input} from "@angular/core";
import {ComponentViewMode, UIService} from "../../../services/ui/ui.service";
import {DeviceService} from "../../../services/device/device.service";
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { ContactMedicalInsight, MedicalInsightCategory } from "@omni/classes/contact/contact.class";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import _ from 'lodash';
import { TranslateService } from "@ngx-translate/core";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { AlertService } from "@omni/services/alert/alert.service";
import { DatePipe } from "@angular/common";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { ActivityService } from "@omni/services/activity/activity.service";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";
import { PopoverController } from "@ionic/angular";
import { IndDropdownListComponent } from "@omni/components/shared/ind-dropdown-list/ind-dropdown-list";
import { BrandOfflineService } from "@omni/services/brand/brand.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from "@omni/components/shared/ind-datetime-form/ind-datetime-form";
import * as moment from'moment';
import { MedicalInsightService } from "@omni/services/medical-insight/medical-insight-service";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { ContactPageComponent } from "../contact-page/contact-page";
import { AgendaFooterService } from "@omni/services/footer/agenda-footer.service";
import { skip, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'contact-medical-insight',
  templateUrl: 'contact-medical-insight.html',
  styleUrls:['contact-medical-insight.scss']
})
export class ContactMedicalInsightComponent {

  @Input() from: any;
  @Input() medicalInsight: ContactMedicalInsight;
  public currentMedicalInsight: ContactMedicalInsight;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = false;

  public medicalInsightsDetailsPanePageTitle: IndPageTitleViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;

  public dateFormField: IndFormFieldViewDataModel;
  public customerFormField: IndFormFieldViewDataModel;
  public insightCategoryFormField: IndFormFieldViewDataModel;
  public productCatalogFormField: IndFormFieldViewDataModel;
  public specialityFormField: IndFormFieldViewDataModel;
  public summaryFormField: IndFormFieldViewDataModel;
  public descriptionFormField: IndFormFieldViewDataModel;
  public priorityFormField: IndFormFieldViewDataModel;

  private _dropdownPopover:any;
  private ngDestroy$ = new Subject<boolean>();

  constructor(
    private readonly uiService: UIService,
    private readonly device: DeviceService,
    public readonly footerService: FooterService,
    private readonly translate: TranslateService,
    private readonly navService: NavigationService,
    private readonly alertService: AlertService,
    private datePipe: DatePipe,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly activityService: ActivityService,
    private readonly popoverCtrl: PopoverController,
    private readonly brandsService: BrandOfflineService,
    private readonly contactService: ContactOfflineService,
    public medicalInsightService: MedicalInsightService,
    private readonly agendaFooterService: AgendaFooterService,
  ) {
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if(this.medicalInsight){
      this.currentMedicalInsight = _.cloneDeep(this.medicalInsight);
      if(this.currentMedicalInsight.ID){
        this.isReadOnlyMode = true;
      }
    }

    this.device.isOfflineObservable.pipe(skip(1), takeUntil(this.ngDestroy$)).subscribe(isOffline => {
      this._initPageTitle();
      this._initFormFields();
    });

    this._initPageTitle();
    this._initFormFields();
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _initPageTitle() {
    let buttons = [];
    if(this.from != 'MedicalInsightTool'){
      buttons.push(
        {
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
        },
      );
    }
    buttons.push({
      id: "save",
      imgSrc: 'assets/imgs/header_save.svg',
      name: this.translate.instant('SAVE'),
      isDisabled: this.isSaveButtonDisabled,
      align: "right"
    });
    this.medicalInsightsDetailsPanePageTitle = {
      id: 'contact-medical-insight-details-page-title',
      title: this.translate.instant('COLLABORATIVE_INSIGHTS') + ' ' + this.currentMedicalInsight.contactNameString,
      controls: buttons,
    }
    this.footerService.initButtons(FooterViews.CONTACT_MEDICAL_INSIGHT);
    if(this.isSaveButtonDisabled){
      this.footerService.disableButton(['savecontactmedicalinsight']);
    }else{
      this.footerService.enableButtons(['savecontactmedicalinsight']);
    }
  }

  private _initFormFields(){
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: [],
    };

    this.customerFormField = {
      label: this.translate.instant('CUSTOMER'),
      inputText: this.currentMedicalInsight.contactNameString ? this.currentMedicalInsight.contactNameString : '',
      id:'customer-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_CUSTOMER'):this.translate.instant('SELECT_CUSTOMER'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      isRequired:false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };

    this.dateFormField = {
      isDateField: true,
      label: this.translate.instant('DATE'),
      inputText: this.datePipe.transform(this.currentMedicalInsight.dateCreated, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),
      //inputText: (this.currentFollowUpActivityDueDateString !== 'Select Due Date' && this.currentFollowUpActivityDueDateString !== 'None') ? this.currentFollowUpActivityDueDateString : '',
      fromViewPage: CurViewPageType.MedicalInsight,
      id:'date-field',
      isReadOnly: true,
      isDisabled: (!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel: this.currentMedicalInsight.dateCreated ? this.translate.instant('SELECT_DATE') : this.translate.instant('NO_DATE'),
      showArrow: (!this.isReadOnlyMode && !this.device.isOffline),
      isRequired: (!this.isReadOnlyMode && !this.device.isOffline),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.insightCategoryFormField = {
      label: this.translate.instant('INSIGHT_CATEGORY'),
      inputText: this.currentMedicalInsight.insightCategoryNameString ? this.currentMedicalInsight.insightCategoryNameString : '',
      id:'insight-category-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_INSIGHT_CATEGORY'):this.translate.instant('SELECT_INSIGHT_CATEGORY'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      isRequired:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.productCatalogFormField = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.currentMedicalInsight.productCatalogNameString ? this.currentMedicalInsight.productCatalogNameString : '',
      id:'product-catalog-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_PRODUCT'):this.translate.instant('SELECT_PRODUCT'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      isRequired:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.specialityFormField = {
      label: this.translate.instant('SPECIALITY'),
      inputText: this.currentMedicalInsight.specialityNameString ? this.currentMedicalInsight.specialityNameString : '',
      id:'speciality-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline && this.contactService.specialties.length != 0)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode || this.contactService.specialties.length == 0)?this.translate.instant('NO_SPECIALTY'):this.translate.instant('SELECT_SPECIALTY'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline && this.contactService.specialties.length != 0),
      isRequired:false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.summaryFormField = {
      label: this.translate.instant('SUMMARY'),
      inputText: this.currentMedicalInsight.summary,
      inputValue :this.currentMedicalInsight.summary,
      id:'summary-field',
      isReadOnly:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_SUMMARY'):this.translate.instant('ENTER_SUMMARY'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      maxLength: 200,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.descriptionFormField = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.currentMedicalInsight.description,
      inputValue :this.currentMedicalInsight.description,
      id:'description-field',
      isReadOnly:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_DESCRIPTION'):this.translate.instant('ENTER_DESCRIPTION'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !this.isReadOnlyMode,
      maxLength: 500,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.priorityFormField = {
      label: this.translate.instant('FOLLOW_UP_PRIORITY'),
      inputText: this.currentMedicalInsight.priority ? this.translate.instant('YES') : this.translate.instant('NO'),
      id:'priority-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      isRequired:false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
  }

  public get isSaveButtonDisabled():boolean {
    let flag = false;
    if(this.isReadOnlyMode || this.device.isOffline){
      flag = true;
    }else if(!this.currentMedicalInsight.insightCategory){
      flag = true;
    }else if(!this.currentMedicalInsight.productCatalogId){
      flag = true;
    }
    // else if(!this.currentMedicalInsight.specialityId){
    //   flag = true;
    // }
    else if(!this.currentMedicalInsight.summary){
      flag = true;
    }else if(!this.currentMedicalInsight.description){
      flag = true;
    }
    return flag;
  }

  public handleButtonClick(buttonId: string): void {
    let popupTitle = '';
    let popupMessage = '';
    let popupAction = '';
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
      case 'save': {
        if (this.isSaveButtonDisabled) return;
        popupTitle = this.translate.instant('SAVE_COLLABORATIVE_INSIGHT');
        popupMessage = this.translate.instant('R_U_SURE_WANT_SUB_COLLABORATIVE_INSIGHT');
        popupAction = this.translate.instant('SAVE');
        break;
      }
      default:
        return;
    }
    if (this.currentMedicalInsight) {
      this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage}, popupAction
      ).then (res => {
        if(res.role === 'ok' && !this.device.isOffline) {
          console.log('Handle Medical Insight Save')
          // Create Medical insight on Dynamics
          this.medicalInsightService.newMedicalInsight = this.currentMedicalInsight;
          this.isReadOnlyMode = true;
          this.medicalInsightService.isInsightCreateInProgress = false;
          this._initPageTitle();
          this._initFormFields();
          //this.onClosePage();
        }
      });
    }
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (eventName == 'input_value_confirm' && id) {
      switch (id) {
        case 'description-field':
          this.handleEditDescription(event);
          break;
        case 'summary-field':
          this.handleEditSummary(event);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    } else if (id) {
      switch (id) {
        case 'insight-category-field':
          this.handleInsightCategorySelect(event);
          break;
        case 'product-catalog-field':
          this.handleProductCatalogSelect(event);
          break;
        case 'speciality-field':
          this.handleSpecialitySelect(event);
          break;
        case 'priority-field':
          this.handlePrioritySelect(event);
          break;
        case 'date-field':
          this.handleDateField(event);
          break;
        case 'customer-field':
          this.handleContactField();
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private async handleEditDescription(data) {
    if (!this.isReadOnlyMode) {
      if (data && data.target.value != this.currentMedicalInsight.description) {
        this.currentMedicalInsight.description = data.target.value;
        this._updateCurrentMedicalInsight();
      }
    }
  }

  private async handleEditSummary(data) {
    if (!this.isReadOnlyMode) {
      if (data && data.target.value != this.currentMedicalInsight.summary) {
        this.currentMedicalInsight.summary = data.target.value;
        this._updateCurrentMedicalInsight();
      }
    }
  }

  private async handleInsightCategorySelect(data) {
    if (this.isReadOnlyMode) return;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'Medical-Insight-Category-Select',
      data: [
        {
          title: 'Competitive Product',
          id: MedicalInsightCategory.CompetitiveProduct.toString(),
          isSelected: MedicalInsightCategory.CompetitiveProduct == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Device',
          id: MedicalInsightCategory.Device.toString(),
          isSelected: MedicalInsightCategory.Device == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Disease/Diagnosis',
          id: MedicalInsightCategory["Disease/Diagnosis"].toString(),
          isSelected: MedicalInsightCategory["Disease/Diagnosis"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Sanofi Product (Efficacy/Safety/Other)',
          id: MedicalInsightCategory["Sanofi Product (Efficacy/Safety/Other)"].toString(),
          isSelected: MedicalInsightCategory["Sanofi Product (Efficacy/Safety/Other)"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Guidelines/treatment protocols',
          id: MedicalInsightCategory["Guidelines/treatment protocols"].toString(),
          isSelected: MedicalInsightCategory["Guidelines/treatment protocols"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Patient – Journey/Access/Advocacy',
          id: MedicalInsightCategory["Patient – Journey/Access/Advocacy"].toString(),
          isSelected: MedicalInsightCategory["Patient – Journey/Access/Advocacy"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Research/Study Design',
          id: MedicalInsightCategory["Research/Study Design"].toString(),
          isSelected: MedicalInsightCategory["Research/Study Design"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Healthcare Environment/Policy',
          id: MedicalInsightCategory["Healthcare Environment/Policy"].toString(),
          isSelected: MedicalInsightCategory["Healthcare Environment/Policy"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Benefit/Risk',
          id: MedicalInsightCategory["Benefit/Risk"].toString(),
          isSelected: MedicalInsightCategory["Benefit/Risk"] == this.currentMedicalInsight.insightCategory,
        },
        {
          title: 'Scientific Data',
          id: MedicalInsightCategory["Scientific Data"].toString(),
          isSelected: MedicalInsightCategory["Scientific Data"] == this.currentMedicalInsight.insightCategory,
        }
      ],
    };
    this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
    this._dropdownPopover.onDidDismiss().then(async (data) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentMedicalInsight.insightCategory.toString()) {
        this.currentMedicalInsight.insightCategory = parseInt(data.selectedItems[0].id);
        this.currentMedicalInsight.insightCategoryNameString = data.selectedItems[0].title
        this._updateCurrentMedicalInsight();
      }
      this._dropdownPopover = undefined;
    });
    this._dropdownPopover.present();
  }

  private async handleProductCatalogSelect(data) {
    if (this.isReadOnlyMode) return;
    if(Array.isArray(this.brandsService.brands) && this.brandsService.brands.length){
      let listData = [];
      let currentStageIndex:number;
      for(let i=0;i< this.brandsService.brands.length;i++){
        const product = this.brandsService.brands[i];
        let obj = {
          id: product.ID,
          title: product.name,
        }
        if(product.ID == this.currentMedicalInsight.productCatalogId){
          currentStageIndex = i;
          obj['isSelected'] = true;
        }
        listData.push(obj);
      }
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Medical-Insight-Product-Catalog-Select',
        data: listData,
      };
      this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
      this._dropdownPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentMedicalInsight.productCatalogId) {
          this.currentMedicalInsight.productCatalogId = data.selectedItems[0].id;
          this.currentMedicalInsight.productCatalogNameString = data.selectedItems[0].title;
          this._updateCurrentMedicalInsight();
        }
        this._dropdownPopover = undefined;
      });
      this._dropdownPopover.present();
    }
  }

  private async handleSpecialitySelect(data) {
    if (this.isReadOnlyMode) return;
    await this.uiService.displayLoader();
    setTimeout(() => {
      if(Array.isArray(this.contactService.specialties) && this.contactService.specialties.length > 0){
        let listData = [];
        for(let i=0;i< this.contactService.specialties.length;i++){
          const specialty = this.contactService.specialties[i];
          if(specialty.id && specialty.name){
            let isSelected:boolean = false;
            if(specialty.id == this.currentMedicalInsight.specialityId){
              isSelected = true;
            }
            let obj = {
              title: specialty.name,
  
              id: specialty.id,
              primaryTextRight: specialty.name ? specialty.name : '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.currentMedicalInsight.ownerId
            }
            listData.push(obj);
          }
        }
  
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('SPECIALTIES'),
          dividerTitle: this.translate.instant('ALL_SPECIALTIES'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: '',
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleSpecialityComponentEvent(data, eventTarget, refData, 'speciality'),
          searchHandler: (text: string) => this._handleSpecialityComponentSearch(text, 'speciality'),
          data: listData
        };
  
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
  
        // let dropdownListDetail: IndDropdownListDetailModel = {
        //   id: 'Medical-Insight-Sepcialty-Select',
        //   data: listData,
        // };
        // this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
        // this._dropdownPopover.onDidDismiss().then(async (data) => {
        //   data = data.data;
        //   if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentMedicalInsight.specialityId) {
        //     this.currentMedicalInsight.specialityId = data.selectedItems[0].id;
        //     this.currentMedicalInsight.specialityNameString = data.selectedItems[0].title;
        //     this._updateCurrentMedicalInsight();
        //   }
        //   this._dropdownPopover = undefined;
        // });
        // this._dropdownPopover.present();
      }
    }, 10);
  }

  private async _handleSpecialityComponentEvent(data:any,eventTarget:string, refData:MainToolTemplateDetail, fieldName:string){
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentMedicalInsight.specialityId) {
        this.currentMedicalInsight.specialityId = data.selectedItems[0].id;
        this.currentMedicalInsight.specialityNameString = data.selectedItems[0].title;
        this._updateCurrentMedicalInsight();
      }
    }
  }

  private _handleSpecialityComponentSearch(text: string, formField: string): string[] {
    return this.contactService.specialties.filter(speciality => {
      return speciality.name && speciality.id && speciality.name.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(speciality1 => speciality1.id);
  }

  private async handlePrioritySelect(data) {
    if(!this.isReadOnlyMode){
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Medical-Insight-Sepcialty-Select',
        data: [
          {
            id: 'true',
            title: this.translate.instant('YES'),
            isSelected: this.currentMedicalInsight.priority,
          },
          {
            id: 'false',
            title: this.translate.instant('NO'),
            isSelected: !this.currentMedicalInsight.priority,
          },
        ],
      };
      this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
      this._dropdownPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        let flag = this.currentMedicalInsight.priority ? 'true' : 'false'
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != flag) {
          this.currentMedicalInsight.priority = data.selectedItems[0].id == 'true' ? true:false;
          this._updateCurrentMedicalInsight();
        }
        this._dropdownPopover = undefined;
      });
      this._dropdownPopover.present();
    }
  }

  async handleDateField(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.MedicalInsight,
          startDateTimeValue: moment().subtract(5, 'years').format(),
          endDateTimeValue: this.currentMedicalInsight.dateCreated ? this.currentMedicalInsight.dateCreated : moment(new Date()).format(),
        },
        cssClass: "datetime-popover",
      }
    );
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
        if (this.device.isOffline) return;
        const valueDate = new Date(data.data.endTime);
        valueDate.setHours(0, 0, 0, 0);
        if (!(this.currentMedicalInsight.dateCreated && this.currentMedicalInsight.dateCreated.getDate() == valueDate.getDate() && this.currentMedicalInsight.dateCreated.getMonth() == valueDate.getMonth() && this.currentMedicalInsight.dateCreated.getFullYear() == valueDate.getFullYear())) {
          this.currentMedicalInsight.dateCreated = valueDate;
          this._updateCurrentMedicalInsight();
        }
      }
    });
    popover.present();
  }

  private async handleContactField(){
    if (this.isReadOnlyMode) return;
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = '';
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.ContactMedicalInsightComponent;
    let passedData = {
      type: 'PushedContactSelectionView', 
      callbackEvent: (data: any) => this._handleContactComponentCallback(data),
      contactListData:
      {
          for:'MedicalInsightContactSelection',
          medicalInsight: this.currentMedicalInsight,
      }
  };
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.KOLDetailsComponent);
    this.agendaFooterService.updateButtons([''],true);
  }

  private async _handleContactComponentCallback(data) {
    if (data?.isDone) {
      if (this.device.isOffline) return;
      if (data && data.selectedItem) {
        this.currentMedicalInsight.contactId = data.selectedItem.ID;
        this.currentMedicalInsight.contactNameString = data.selectedItem.fullName;       
      }else if(data && data.clearValue){
        this.currentMedicalInsight.contactId = '';
        this.currentMedicalInsight.contactNameString = '';
      }
      this._updateCurrentMedicalInsight();
    }
  }

  private onClosePage() {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'MedicalInsightTool') {
        this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.MedicalInsightPageComponent);
        this.navService.setChildNavRightPaneView(false);
        this.footerService.initButtons(FooterViews.Contacts);
      }
    }
  }

  private _updateCurrentMedicalInsight(){
    console.log("Updated Current Medical Insight");
    this.medicalInsightService.isInsightCreateInProgress = true;
    this._initPageTitle();
    this._initFormFields();
  }

  onFooterButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'savecontactmedicalinsight':
        this.handleButtonClick('save');
        break;
      default:
        break;
    }
  }

}
