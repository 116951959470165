import {Directive, Input, HostBinding} from '@angular/core'
@Directive({
    selector: '[io-thumbnail]',
    host: {
      '(error)':'updateUrl()',
      '[src]':'src'
     }
  })
  
 export class ImagePreloadDirective {
    @Input() src:string;
    // @HostBinding('class') className
  
    updateUrl() {
      this.src = "assets/imgs/thumbnail_default.png";
    }
  }