import {Component, Input, ChangeDetectorRef} from '@angular/core';
import {EventRegistration} from "../../../../classes/customer-event/customer-event.class";
import {NavParams, PopoverController} from "@ionic/angular";
import {XperiencesService, IMPACT_SEGMENT} from "../../../../services/xperiences/xperiences.service";
import {UIService} from "../../../../services/ui/ui.service";
import {DeviceService} from "../../../../services/device/device.service";
import {ActivityDataService} from "../../../../data-services/activity/activity.service";
import {ActivityService} from "../../../../services/activity/activity.service";
import {FooterService, FooterViews} from "../../../../services/footer/footer.service";
import {SampleService, SamplingDetailsViewMode} from "../../../../services/sample/sample.service";
import {EmailService} from "../../../../services/email-templates/email.service";
import {AccesingMode, CaseManagementService} from "../../../../services/case-management/case-management.service";
import {CaseManagementDataService} from "../../../../data-services/case-management/case-management.data.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../services/authentication.service";
import {ContactOfflineService} from "../../../../services/contact/contact.service";
import {NotificationService, ToastStyle} from "../../../../services/notification/notification.service";
import {NavigationService, PageName} from "../../../../services/navigation/navigation.service";
import {TrackService} from "../../../../services/logging/tracking.service";
import {CustomerEventsService} from "../../../../services/customer-event/customer-events.service";
import {
  CentralPlan,
  CustomerInterest,
  CustomerJourney,
  CustomerRelationship,
  InterestInsight,
  JourneyInsight,
  OptionSet,
  RelationshipContact,
  RelationshipInsight,
  RelationshipInsightsPlus,
  RelationshipSources,
  User,
  XpCustomer,
  JourneyPlan,
  InterestPlan,
  RelationshipPlan
} from "../../../../classes/xperiences/trending.customer.class";
import {MultiSelectPopover} from "../../../multi-select-popover/multi-select-popover";
import {Contact, Specialty} from "../../../../classes/contact/contact.class";
import {MarketingEmailDetailsComponent} from "../../../email/marketing-email-details/marketing-email-details";
import {ChannelType} from "../../../../classes/consent/channel.class";
import {AppointmentActivity} from "../../../../classes/activity/appointment.activity.class";
import {ActivityType} from "../../../../classes/activity/activity.class";
import {EmailActivity, EmailViewType} from "../../../../classes/activity/email.activity.class";
import {CaseActivity} from "../../../../classes/case-intake/case-activity.class";
import {SelectListData} from "../../../../models/select-list-data-model";
import {CustomerRelationshipComponent} from "../../../contact/customer-insight/customer-relationship/customer-relationship";
import {cloneDeep, default as _} from "lodash";
import {PopoverComponent} from "../../../popover/popover";
import {CustomerJourneyComponent} from "../../../contact/customer-insight/customer-journey/customer-journey";
import {CustomerInterestComponent} from "../../../contact/customer-insight/customer-interest/customer-interest";
import {ResourcePageComponent} from "../../../resource/resource-page/resource-page";
import {MorePlansInsightsComponent} from "../more-plans-insights/more-plans-insights";
import { SampleActivity } from '../../../../classes/activity/sample.activity.class';
import { DatePipe } from '@angular/common';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { NewSampleActivityComponent } from '@omni/components/activity/new-sample-activity/new-sample-activity';

/**
 * Generated class for the RelationshipInsightsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'plans-predictions',
  templateUrl: 'plans-predictions.html',
  styleUrls:['plans-predictions.scss']
})
export class PlansPredictionsComponent {

  @Input("source")
  public source: any[];

  @Input("segment")
  public segment: IMPACT_SEGMENT;

  selectedSortOption: any;
  planItemsCount: number = 0;
  public feedTopic: string = null;

  constructor(
    private popoverCtrl: PopoverController,
    public xperiencesService: XperiencesService,
    private uiService: UIService,
    public device: DeviceService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public sampleService: SampleService,
    private emailService: EmailService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private contactService: ContactOfflineService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    public customerEventsService: CustomerEventsService,
    public _cd: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    if (this.source) {
      if (this.segment == IMPACT_SEGMENT.FEED && this.xperiencesService.selectedTrendingFeed.feed_type == "Topics") {
        this.feedTopic = this.xperiencesService.selectedTrendingFeed.product_topic;
      }
      this.source.forEach((plan) => {
        if (plan.activitytypecode === 'event' && plan.type === 'centralplan') {
          plan.event = this.getContactEventsDetails(this.xperiencesService.selectedXpContact.contactid, plan.id);
        }
        const contacts = plan.contacts;
        if (contacts && contacts.length > 0) {
          plan.contactLabel = contacts.length > 2 ? `${contacts[0].fullname} +${contacts.length - 1}`: contacts.map(c => c.fullname).join(", ");
        }
        if (plan.activitytypecode === 'journey_insight') {
          plan.translatedTitle = this.getJourneyTouchPointTitle(plan.title);
          plan.productsText = this.translate.instant('XPERIENCES_INSIGHTS_PLUS_BY') + ' ' + this.xperiencesService.getUserNamesText(plan.users);
        } else {
          plan.productsText = this.xperiencesService.getProductsText(plan.products);
          plan.translatedTitle = this.constructPlansTitle(plan.type, plan.activitytypecode, plan.channeltype, plan.users);
          plan.relationshipContactText = this.getRelationshipContactText(plan.contacts);
        }
      });
    }
    this.xperiencesService.setPlansFilterState();
  }

  ngAfterViewInit() {
    this._cd.detectChanges();
  }

  public async sendPlanFeedback(plan: any, feedback: string) {
    if (plan.feedback_Flag || this.device.isOffline) return;
    if (feedback == 'negative') {
      const index = this.source.findIndex(x => x.id == plan.id);
      if (index > -1) {
        this.source.splice(index, 1);
        this.notificationService.notify( this.translate.instant('XPERIENCES_PREDICTION_REMOVED'), 'Xperience Plan');
        return;
      }
    }
    this.notificationService.notify( this.translate.instant('XPERIENCES_THANKS_FOR_FEEDBACK'), 'Xperience Plan');
    plan.feedback_Flag = feedback;
  }

  get plans(): any[] {
    let filteredObjs: any[] = [];
    if(this.source && this.source) {
      if (this.xperiencesService.xpPlanFilterText == "All") {
        filteredObjs = (this.source && this.source.length > 0) ? this.source.sort((a,b)=>b.date - a.date) : [];
      } else {
        filteredObjs = (this.source && this.source.length > 0) ? this.source.filter(e => e.type === this.xperiencesService.xpPlanFilterText).sort((a,b)=>b.date - a.date) : [];
      }
    }
    this.planItemsCount = filteredObjs.length;
    return filteredObjs;
  }

  expandEventDetails(event, customerEvent) {
    if (this.device.isOffline) {
      return;
    }
    customerEvent.isExpanded = !customerEvent.isExpanded;
  }

  openPlanInsightFilterPopup(e) {
    this.popoverCtrl.create({component:MultiSelectPopover,componentProps: { root: this.xperiencesService.filterOptions },cssClass: 'xpPopover', event:event }).then((data)=>data.present());
    this.xperiencesService.setPlansFilterState();
  }

  getTitle(option: string) {
    let label: string;
    switch (option) {
      case "All":
        label = String(this.translate.instant('XPERIENCES_HEADER_PLANS_AND_PREDICTIONS'));
        break;
      case "prediction":
        label = 'Predictions';
        break;
      case "teamplan":
        label = String(this.translate.instant('XPERIENCES_TEAM_PLANS'));
        break;
      case "centralplan":
        label = String(this.translate.instant('XPERIENCES_CENTRAL_PLANS'));
        break;
      default:
        label = String(this.translate.instant('XPERIENCES_HEADER_PLANS_AND_PREDICTIONS'));
        break;
    }
    return label;
  }

  async displayActivityDetails(data: any) {
    if (this.device.isOffline || data.activitytypecode === 'event') return;
    if(data.activitytypecode == 'journey_insight' || data['insights_plus']) {
      this.displayInsightsPlusDetails(data);
      return;
    }
    let contacts: Contact[] = [];
    if(this.segment == IMPACT_SEGMENT.FEED && data.contacts && data.contacts.length > 0) {
      data.contacts.forEach(c => {
        contacts.push(this.contactService.getContactByID(c.contactid));
      });
    } else {
      contacts.push(this.contactService.getContactByID(this.xperiencesService.selectedXpContact.contactid));
    }
    if (data.type == 'centralplan') {
      await this.uiService.displayLoader();
      await this.xperiencesService.getMarketingEmails(data.id).then(async (response) => {
        const centralPlanData: CentralPlan = {
          type: data.title,
          customer: contacts[0],
          products: data.products,
          subject: response['msdyncrm_subject'],
          date: data.date,
          previewContent: response['msdyncrm_emailbody'] ? response['msdyncrm_emailbody'] : ''
        }
        this.navService.pushChildNavPageWithPageTracking(MarketingEmailDetailsComponent, PageName.MarketingEmailDetailsComponent, PageName.MarketingEmailDetailsComponent,
          {
            viewData: { email: centralPlanData, centralPlan: data }
          });
        await this.uiService.dismissLoader();
      }).catch(async () => {
        await this.uiService.dismissLoader();
      });
      return;
    }

    if (data.type == 'prediction' && (data.activitytypecode === 'appointment' ||
      data.activitytypecode === 'email' || data.activitytypecode === 'indskr_sampledrop')) {
      if (data.activitytypecode == 'appointment') {
        await this.xperiencesService.createMeeting(contacts, data.date);
        if (this.activityService.selectedActivity) this.footerService.initButtons(FooterViews.Activities);
      } else if (data.activitytypecode === 'indskr_sampledrop') {
        const contactCustomeAllocations = this.sampleService.contactCustomerSampleAllocations.find(o => {
          return o.contactId == contacts[0].ID
        });
        if (!contactCustomeAllocations || !contactCustomeAllocations.currentCustomerSampleAllocations ||
          contactCustomeAllocations.currentCustomerSampleAllocations.length == 0) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_ACTIVE_ALLOCATIONS'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createAllocationOrder(data.date, contacts[0]);
        }
      } else {
        const channel: ChannelType = this.xperiencesService.getChannelType(data.channeltype);

        if (channel === ChannelType.EMAIL && contacts.filter(c => c.emailAddressList && c.emailAddressList.length > 0).length == 0) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_EMAIL'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if (channel === ChannelType.FACEBOOK && contacts.filter(c => (c.indskr_facebookpsid || '') !== '').length == 0) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_FACEBOOK_ID'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else if ((channel === ChannelType.WHATSAPP || channel === ChannelType.SMS) && contacts.some(c => !c.mobilePhone)) {
          this.notificationService.notify(this.translate.instant('XPERIENCES_NO_PHONE_NUMBER'), 'Xperience Plan', 'top', ToastStyle.DANGER);
          return;
        } else {
          await this.xperiencesService.createEmail(data.date, data.channeltypeid, channel, contacts);
        }
      }
      data.type = 'teamplan';
      data.id = this.activityService.selectedActivity.ID;
      let loggedInUser = this.authService.user;
      let users: User[] = [{
        userid: loggedInUser.systemUserID,
        username: loggedInUser.displayName
      }];
      data.users = users;
      data.translatedTitle = this.constructPlansTitle(data.type, data.activitytypecode, data.channeltype, users);
    } else {
      let foundActivity = this.activityService.getActivityByID(data.id);
      this.uiService.showRightPane = true;
      this.uiService.showNewActivity = false;
      if (data.activitytypecode == "appointment") {
        if (!foundActivity) {
          await this.activityDataService.getActivityByIdOnline(data.id, "appointment")
            .then(async response => {
              foundActivity = new AppointmentActivity(response);
            });
        }
        foundActivity.isFromXperiences = true;
        this.activityService.selected = foundActivity;
        this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
        this.uiService.showCancelDoneOnActivityDetails = true;
        await this.activityDataService.updateActivityDetails(foundActivity);
        if (this.activityService.selectedActivity) {
          this.footerService.initButtons(FooterViews.Activities);
        }
      } else if (data.activitytypecode == "email") {
        let activity = new AppointmentActivity({ activityid: data.id });
        activity.type = ActivityType.Email;
        activity.isFromXperiences = true;
        await this.uiService.displayLoader();
        await this.activityDataService.getRealTimeActivityDetails(activity).then(async (email) => {
        await this.uiService.dismissLoader();
          if (!email) {
            return;
          }
          this.activityService.selected = email;
          this.activityService.selectedActivity = email;
          this.emailService.selectedActivity = <EmailActivity>email;
          this.emailService.selectedActivity.isFromXperiences = true;
          this.emailService.setCurrentEmail(email);
          this.emailService.viewType = EmailViewType.FROM_XPERIENCES;
          this.uiService.showNewActivity = false;
          this.uiService.activeView = 'Email';
          this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent);
          this.activityService.updateEmailAddressInEmailActivityParties(email as EmailActivity);
        }).catch(async () => {
          await this.uiService.dismissLoader();
          console.log("Failed to load email activity:" + activity.ID);
        });
      } else if (data.activitytypecode == "medical_inquiry") {
        await this.uiService.displayLoader();
        await this.caseDataService.findCaseById(data.id).then(
          async res => {
            if (res) {
              for (let key in res) {
                if (key.charAt(0) === "_") {
                  let a = key.substring(1, key.length);
                  res[a] = res[key];
                  delete res[key];
                }
              }
              let iCase: CaseActivity;
              iCase = new CaseActivity(res);
              iCase = await this.caseService.reInitDataDependency(iCase);
              this.activityService.selectedActivity = iCase;
              this.caseService.assignSelectedCase(iCase);
              this.caseService.accessedFrom = AccesingMode.XPERIENCE_INSIGHTS;
              this.uiService.showNewActivity = false;
              this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.CaseManagementDetailComponent);
            }
            await this.uiService.dismissLoader();
          }).catch(async() => {
          console.log("Failed to load customer inquiry:" + foundActivity.ID);
          await this.uiService.dismissLoader();
        });
      } else {
        if (foundActivity && foundActivity.type == ActivityType.Sample) {
            this.activityService.selected = foundActivity;
        } else {
          // makes online call to fetch the allocation order details
          let foundActivity = new SampleActivity(await this.activityDataService.getActivityByIdOnline(data.id, "sampledrop"));
          this.activityService.selected = foundActivity;
          await this.activityDataService.updateActivityDetails(foundActivity);
        }
        this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'Sample';
        this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
      }
    }
  }

  displayInsightsPlusDetails(insight: JourneyInsight | InterestInsight | RelationshipInsight) {
    if((insight as JourneyInsight).activitytypecode == 'journey_insight') {
      let index = insight.users.findIndex((user) => user.userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayJourneyPlusInsights(insight as JourneyInsight);
      }
      return;
    }
    if(insight['events']) {
      let index = (insight as RelationshipInsight).insights_plus.findIndex((ip) => ip.users[0].userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayRelationInsightsPlusDetails(insight as RelationshipInsight);
      }
      return;
    }
    if(insight['emails']) {
      let index = (insight as InterestInsight).insights_plus.findIndex((ip) => ip.users[0].userid === this.authService.user.systemUserID);
      if(index >= 0) {
        this.displayInterestPlusInsights(insight as InterestInsight);
        return;
      }
    }
  }

  async displayJourneyPlusInsights(insight: JourneyInsight) {
    await this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getJourneyInsigthData(insight.id);
    await this.uiService.dismissLoader();
    let joureyInsightData: CustomerJourney = {
      type: insight.title,
      description: insightData['indskr_description'] && insightData['indskr_description'].length > 0 ? insightData['indskr_description']: this.translate.instant('NO_DESCRIPTION'),      date: insight.date,
      confidence:  insightData['indskr_strength'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strength']).label) : undefined,
      indskr_competitorproduct: insightData['indskr_competitorproduct'] ? insightData['indskr_competitorproduct']: undefined
    };
    if(insight.products.length > 0) {
      joureyInsightData.selectedProduct = {
        title: insight.productsText,
        id: insight.products[0].productid === 'competitor product' ? 'indskr_competitorproduct' : insight.products[0].productid
      }
    } else {
      if (insightData["indskr_product"]) {
        joureyInsightData.selectedProduct = {
          title: insightData["productName"],
          id: insightData["indskr_product"]
        }
      }
    }
    this.navService.pushChildNavPageWithPageTracking(CustomerJourneyComponent, PageName.CustomerJourneyComponent,
      PageName.ActivitiesPageComponent, { journey: joureyInsightData, readOnly: true });
  }

  async displayRelationInsightsPlusDetails(insight: RelationshipInsight) {
    const latestInsight = ([...insight.sources.insightsPlusByLoggedInUser, ...insight.sources.otherInsightsPlus] as RelationshipInsightsPlus[])
      .sort((a, b) => (b as RelationshipInsightsPlus).date - (a as RelationshipInsightsPlus).date)[0];
    await this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getRelationshipInsigthData(latestInsight.insightid.toString());
    await this.uiService.dismissLoader();
    let insightPlusSources = insight.sources.otherInsightsPlus.map((oip) => oip.displayText);
    let allSources = [];
    if(insight.sources.otherSources.length > 0) {
      allSources.push(...insight.sources.otherSources);
    }
    if (insight.sources.otherInsightsPlus) {
      allSources.push(...insightPlusSources);
    }
    const relationshipTypes: SelectListData[] = insight.insights_plus.map((ip)=> {
      const relationship = this.xperiencesService.relationships.find((relationship) => relationship.title === ip.relationship_insight_type);
      return {...relationship, isSelected: true };
    });
    const selectedSpeaciality: Specialty = insightData["indskr_specialty"] ? this.contactService.specialties.find((sp) => sp.id === insightData["indskr_specialty"]) : null;
    const relationshipInsightData: CustomerRelationship = {
      customerName: this.xperiencesService.selectedXpContact.fullname,
      contactid: insight.contactid,
      fullname: insight.fullname,
      contacts: insight.contacts,
      sources: allSources,
      sourcesText:  allSources.length > 1 ? allSources[0] + ' +' + (allSources.length - 1) : allSources[0],
      types: relationshipTypes.length === 0 ? undefined : relationshipTypes,
      title: relationshipTypes.length === 0 ? undefined : (relationshipTypes.length === 1 ? relationshipTypes[0].title : relationshipTypes[0].title + ' + ' + (relationshipTypes.length - 1)),
      score: insightData['indskr_strength'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strength']).label): undefined,
      selectedSpeciality: {
        title: selectedSpeaciality ? selectedSpeaciality.name : "",
        id: selectedSpeaciality ? selectedSpeaciality.id : "",
        isSelected: true
      }
    }
    this.navService.pushChildNavPageWithPageTracking(CustomerRelationshipComponent, PageName.CustomerJourneyComponent,
      PageName.ActivitiesPageComponent, { relationship: relationshipInsightData, readOnly: true });
  }

  async displayInterestPlusInsights(insight: InterestInsight) {
    await this.uiService.displayLoader();
    let insightData = await this.xperiencesService.getInterestInsigthData(insight.latestInsight.id.toString());
    await this.uiService.dismissLoader();
    let insightPlusSources = insight.sources.otherInsightsPlus.map((oip) => oip.displayText);
    let allSources = [];
    if(insight.sources.otherSources.length > 0) {
      allSources.push(...insight.sources.otherSources);
    }
    if (insight.sources.otherInsightsPlus) {
      allSources.push(...insightPlusSources);
    }
    let joureyInsightData: CustomerInterest = {
      description:  insightData['indskr_description'] && insightData['indskr_description'].length > 0 ? insightData['indskr_description']: this.translate.instant('NO_DESCRIPTION'),
      selectedCategory:  {
        title: insight.latestInsight.category
      },
      score: insightData['indskr_strengths'] ? parseInt(this.xperiencesService.strengths.find(st => st.value === insightData['indskr_strengths']).label) : undefined,
      sources: allSources,
      sourcesText:  allSources.length > 1 ? allSources[0] + ' +' + (allSources.length - 1) : allSources[0],
    };
    this.navService.pushChildNavPageWithPageTracking(CustomerInterestComponent, PageName.CustomerJourneyComponent,
      PageName.ActivitiesPageComponent, { interest: joureyInsightData, readOnly: true });
  }

  getContactEventsDetails(contactId: string, eventId: string) {
    const allEvents = this.customerEventsService.getEventsForContact(contactId);
    return [...allEvents.availableEvents, ...allEvents.upcomingEvents].find((event) => event.eventId === eventId.toLowerCase());
  }

  getJourneyTouchPointTitle(journeyTouchPoint: string) {
    switch (journeyTouchPoint) {
      case 'Meeting':
        return this.translate.instant('MEETING');
      case 'Email':
        return this.translate.instant('EMAIL');
      case 'Event Participant':
        return this.translate.instant('XPERIENCES_EVENT_PARTICIPANT');
      case 'Event Speaker':
        return this.translate.instant('XPERIENCES_EVENT_SPEAKER');
      case 'Event Consultant':
        return this.translate.instant('XPERIENCES_EVENT_CONSULTANT');
      case 'Website':
        return this.translate.instant('XPERIENCES_EVENT_WEBSITE');
      case 'Co Author':
        return this.translate.instant('XPERIENCES_CO_AUTHOR');
      case 'Co Investigator':
        return this.translate.instant('XPERIENCES_CO_INVESTIGATOR');
      case 'Hospital Department':
        return this.translate.instant('XPERIENCES_HOSPITAL_DEPARTMENT');
      case 'Co Presenter':
        return this.translate.instant('XPERIENCES_CO_PRESENTER');
      default:
        return this.translate.instant('XPERIENCES_JOURNEY_TOUCHPOINT');
    }
  }

  constructPlansTitle(type: string, activitytypecode: string, channeltype: string, users: User[]) {
    let title = '';
    if (type === 'prediction') {
      switch (activitytypecode) {
        case "indskr_sampledrop":
          title = this.translate.instant('XPERIENCES_GENEE_ALLOCATION_LABEL');
          break;
        case "task":
          title = "Follow-up";
          break;
        case "appointment":
          title = this.translate.instant('XPERIENCES_GENEE_MEETING_LABEL');
          break;
        case "email":
          if (channeltype === 'email') title = this.translate.instant('XPERIENCES_GENEE_EMAIL_LABEL');
          else {
            if (channeltype === 'facebook') title = this.translate.instant('XPERIENCES_GENEE_FACEBOOK_LABEL');
            else if (channeltype === 'whatsapp') title = this.translate.instant('XPERIENCES_GENEE_WHATSAPP_LABEL');
            else if (channeltype === 'sms') title = this.translate.instant('XPERIENCES_GENEE_SMS_LABEL');
            else if (channeltype === 'whatsapp share') title = this.translate.instant('XPERIENCES_WHATSAPP_SHARE');
            else if (channeltype === 'sms share') title = this.translate.instant('XPERIENCES_SMS_SHARE');
            else if (channeltype === 'sms-textlocal') title = this.translate.instant('XPERIENCES_WHATSAPP_SMS_TEXTLOCAL');
          }
          break;
        case "medical_inquiry":
          title = this.translate.instant('XPERIENCES_GENEE_MEDICAL_INQUIRY');
          break;
      }
      return title;
    } else if (type === 'teamplan' || type === 'centralplan') {
      if (activitytypecode === 'medical_inquiry') {
        return this.translate.instant('XPERIENCES_TEAM_MEDICAL_INQUIRY');
      }
      return this.constructInsightTitle(activitytypecode, users, channeltype);
    }
  }

  constructInsightTitle(activityTypeCode: string, users: User[], channeltype: string): string {
    let activityName = '';
    let userNamesText = this.xperiencesService.getUserNamesText(users);
    switch (activityTypeCode) {
      case 'appointment':
        activityName = this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      case 'email':
        if (channeltype === 'email') activityName = this.translate.instant('XPERIENCES_EMAIL');
        else {
          let channel: string = "";
          if (channeltype === 'facebook') channel = this.translate.instant('XPERIENCES_FACEBOOK_MESSAGE');
          else if (channeltype === 'whatsapp') channel = this.translate.instant('XPERIENCES_WHATSAPP_MESSAGE');
          else if (channeltype === 'sms') channel = this.translate.instant('XPERIENCES_TEXT_MESSAGE');
          else if (channeltype === 'whatsapp share') channel = this.translate.instant('XPERIENCES_WHATSAPP_SHARE');
          else if (channeltype === 'sms share') channel = this.translate.instant('XPERIENCES_SMS_SHARE');
          else if (channeltype === 'sms-textlocal') channel = this.translate.instant('XPERIENCES_WHATSAPP_SMS_TEXTLOCAL');
          activityName = channel;
        }
        break;
      case 'medical_inquiry':
        return this.translate.instant('XPERIENCES_MEDICAL_INQUIRY');
      case 'indskr_sampledrop':
        activityName = this.translate.instant('XPERIENCES_ALLOCATION_ORDER');
        break;
      case 'marketing_email':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EMAIL');
        break;
      case 'event':
        activityName = this.translate.instant('XPERIENCES_MARKETING_EVENT');
        break;
      case 'meeting':
        activityName =  this.translate.instant('XPERIENCES_MEETING_TITLE');
        break;
      default:
        return '';
    }
    return activityName + ' ' + userNamesText;
  }

  getRelationshipContactText(contacts: RelationshipContact[]) {
    let text = '';
    if(contacts && contacts.length) {
      let contactsCount = contacts.length;
      if(contactsCount > 1) {
        text = `${contacts[0].fullname} +${contactsCount - 1}`
      } else {
        text = `${contacts[0].fullname}`
      }
    }
    return text;
  }

  openMorePlansInsights() {
    this.navService.pushChildNavPageWithPageTracking(MorePlansInsightsComponent,
      PageName.MorePlansInsightsComponent,
      PageName.PlansPredictionsComponent,
      {
        title: this.translate.instant('XPERIENCES_HEADER_PLANS_AND_PREDICTIONS'),
        data: this.source,
        selectedFilter: this.xperiencesService.xpPlanFilterText,
        type: 'plans',
        segment: this.segment
      })
  }

  getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, 'MMM dd', undefined, this.translate.currentLang);
  }

  getLocalisedPlanDate(dateString: string){
    return this.getFormattedAndLocalisedDate(new Date(parseInt(dateString)));
  }

}
