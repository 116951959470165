
import {takeUntil} from 'rxjs/operators';
import { UIService } from "./../../../services/ui/ui.service";
import {
  FooterService,
  FooterViews
} from "./../../../services/footer/footer.service";
import {
  NavigationService,
  PageName
} from "./../../../services/navigation/navigation.service";
import { NavController, NavParams, IonNav } from "@ionic/angular";
import { Component, Input, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Resource } from "../../../classes/resource/resource.class";
import {
  ResourceService,
  ResourceView
} from "../../../services/resource/resource.service";
import { Subject, Observable } from "rxjs";
import { DeviceService } from "../../../services/device/device.service";
import { PdfJsViewerComponent } from "ng2-pdfjs-viewer";
import { ModalController } from "@ionic/angular";
import { ActivityService } from "../../../services/activity/activity.service";
import { AppointmentActivity } from "../../../classes/activity/appointment.activity.class";
import { ParticipantListComponent } from "../participant-list/participant-list";
import { EventsService } from "../../../services/events/events.service";
import { IoFileService } from "../../../services/io-file-service/io-file-service";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { electronApi } from "@omni/services/electron-api";
import _ from 'lodash';

@Component({
  selector: "resource-details",
  templateUrl: "resource-details.html",
  styleUrls:['resource-details.scss']
})
export class ResourceDetailsComponent {
  previewSrc: string;
  error: string;
  resource: Resource;
  //@ViewChild(Content, {static: true}) content: Content;
  @ViewChild("pdfViewer", {static: true})
  pdfViewer: PdfJsViewerComponent;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public link: string;
  view: string;

  public titleControls:Array<any> = [];
  @Input() from: any;


  async canGoBack() {
    return await this.navCtrl.canGoBack();
  }

  constructor(
    private resourceService: ResourceService,
    public device: DeviceService,
    private navCtrl: IonNav,
    private navService: NavigationService,
    private uiService: UIService,
    protected popoverCtrl: ModalController,
    public footerService: FooterService,
    private activityService: ActivityService,
    private events: EventsService,
    private navParams: NavParams,
    private changeDetectorRef: ChangeDetectorRef,
    private ioFile: IoFileService,
    private inAppBrowser: InAppBrowser,
  ) {
    this.resource = (this.navParams.data && this.navParams.data.resource);
    this.view = (this.navParams.data && this.navParams.data.from);
  }

  ngOnInit() {
    this.error = null;
    //        this.resource = resource;

      if(this.resourceService.viewMode !== ResourceView.MEETING) {
        // this.footerService.initButtons(
        // this.resourceService.viewMode === ResourceView.PUSH
        //   ? FooterViews.ResourcesInMeeting
        //   : FooterViews.Resources
        // );
        this.initFooter();
      }

    // if (this.resource) {
    //   this.footerService.enableButtons(["share-resource"]);
    // }

    this.initFooter();

    this.events
        .observe("share-resource").pipe(
        takeUntil(this.destroy$))
        .subscribe(() => this.pushResource());

    this.resourceService.highlightedResource.pipe(
    takeUntil(this.destroy$))
    .subscribe(resource => {
      this.resource = resource;
      this.initFooter();
      // this.footerService.enableButtons(["share-resource"]);
      // if(this.content){
      //   this.content.resize();
      // }
      if (this.pdfViewer) {
        this.pdfViewer.pdfSrc = resource.assetURL;
        this.pdfViewer.refresh();
      }
    });

    // if(this.content){
    //   this.content.resize();
    // }

    if(this.from && this.from == 'DisplayValueComponent'){
      this.titleControls = [{
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left',
      }];
    }

  }

  // ionViewDidEnter() {
    // this.footerService.hideAllButtons();
    // this.initFooter();
  // }

  private initFooter() {
    this.footerService.initButtons(
      this.resourceService.viewMode === ResourceView.PUSH
        ? FooterViews.ResourcesInMeeting
        : FooterViews.Resources
      );
    if (this.resource) {
      this.footerService.enableButtons(["share-resource"]);
    }
  }

  get assetURL(): string {
    if (!this.resource.assetURL) return "";
    if (this.device.isOffline && this.resource && this.resource.downloaded) {
      const file: string = this.resource.assetURL.substr(this.resource.assetURL.lastIndexOf('/') + 1);
      const id: string = this.resource.ioResourceId ? this.resource.ioResourceId : this.resource.ioDocumentId;
      const url = this.ioFile.getLocalURLForResource(id, file);
      return url;
    }
    return this.resource.assetURL;
  }

  get assetType(): string {
    if (this.assetURL.toLowerCase().endsWith(".pdf"))
      return "pdf";
    return this.resource.assetType.toLowerCase();
  }

  get isSupportedFileType(): boolean {
    return (
      ["video", "image", "pdf"].indexOf(
        this.assetType
      ) >= 0
    );
  }

  async goBack() {
    // if (await this.canGoBack()) this.navCtrl.pop();
    if(this.from && this.from == 'DisplayValueComponent'){
      this.resourceService.reset();
      this.navService.popChildNavPageWithPageTracking();
    }else{
      this.resourceService.reset();
      this.navService.setChildNavRightPaneView(false);
    }
  }

  pushResource() {
    this.navService.pushChildNavPageWithPageTracking(ParticipantListComponent, PageName.ParticipantListComponent, PageName.ResourceDetailsComponent, {
      resource: this.resource,
      viewMode: ResourceView.PUSH,
      appointment: this.activityService.selectedActivity
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public openUrl(url: string) {
    if(!_.isEmpty(url)) {
      console.log(url);
      if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
        if (this.device.deviceFlags.ios) {
          const browserRef = this.inAppBrowser.create(url, '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
        }else {
          const browserRef = this.inAppBrowser.create(url, '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });
        }
      }else if(this.device.deviceFlags.electron) {
        electronApi.openExternal(url);
      }
    }
  }

  private _formattedDocument() {
    if(!_.isEmpty(document) && !_.isEmpty(document.getElementsByTagName('iframe')[0])) {
      let targetElements = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById("viewerContainer").getElementsByTagName("a");
      if(!_.isEmpty(targetElements)) {
        for(let i=0; i<targetElements.length; i++) {
          if(this.device.deviceFlags.electron || this.device.isNativeApp) {
            const url: string = targetElements[i].getAttribute('href') || '';
            targetElements[i].removeAttribute('href');
            targetElements[i].removeAttribute('target');
            targetElements[i].addEventListener('click', ()=> {this.openUrl(url)});
          }else {
            targetElements[i].setAttribute('target', '_blank');
          }
        }
      }
    }
  }

  onLoadDocument(event, reloadStatus: string) {
    if(reloadStatus == "docLoad") {
      setTimeout(() => {
        this._formattedDocument();
      }, 1000);
    }else {
      this._formattedDocument();
    }
  }
}
