import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { EmailTemplateServices } from './../../../data-services/email-templates/email-template.service';
import { Component, ViewChildren, ChangeDetectorRef, HostListener } from '@angular/core';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { ResourceEmailTemplate, EmailTemplateType } from '../../../classes/email-templates/email-template.class';
import { EmailService } from '../../../services/email-templates/email.service';
import { PopoverController, ModalController, IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { OrderByPipe } from '../../../pipes/orderby.pipe';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { cloneDeep } from 'lodash';
import { EmailActivity, EmailActionType, EmailViewType } from '../../../classes/activity/email.activity.class';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { ConsentService } from '../../../services/consent/consent.service';
import * as _ from 'lodash';
import { ActivityService } from '../../../services/activity/activity.service';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert/alert.service';
import { IndKeyControlsAreaModel } from './../../../models/indKeyControlsAreaModel';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { SearchConfigService } from '../../../services/search/search-config.service';
import { SearchConfigDataService } from '../../../data-services/search-config/search-config-data-service';
import { SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from '../../../models/search-config-data-model';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { ElementRef } from '@angular/core';
import { TherapeuticAreaDataService } from '../../../data-services/therapeutic-area/therapeutic-area.data.service';
import { DB_KEY_PREFIXES } from '../../../config/pouch-db.config';
import { DiskService } from '../../../services/disk/disk.service';
import { FooterService } from '../../../services/footer/footer.service';
import { DeviceService } from '../../../services/device/device.service';
import { IndHeaderLeftDataModel } from '../../../models/indHeaderLeftDataModel';
import { Utility } from '@omni/utility/util';
import { Channel } from '@omni/classes/consent/channel.class';
import { NotificationService } from '@omni/services/notification/notification.service';

/**
 * Generated class for the EmailTemplateListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'email-template-list',
  templateUrl: 'email-template-list.html',
  styleUrls: ['email-template-list.scss']
})
export class EmailTemplateListComponent {

  templates: Array<ResourceEmailTemplate> = [];
  filteredList: ResourceEmailTemplate[] = [];
  matchedTemplates: Array<ResourceEmailTemplate> = [];
  currentTemplateId: string;
  selectedTemplate: ResourceEmailTemplate;
  originalTemplateBody: string;
  isTemplateChanged: boolean = false;
  filterPopoverData: any[];
  sortBy: { text: string; value: string; asc: boolean; };
  // templateFilter: { indskr_name: string, product_id?, indskr_type: EmailTemplateType } = { indskr_name: '', indskr_type: EmailTemplateType.Resource };
  public templatesListKeyControlAreaModel: IndKeyControlsAreaModel;
  public emailTemplateListKeyControlAreaModel: IndKeyControlsAreaModel;
  public isMessageProductBundleFeature: boolean = false;

  // public get messagingTemplateType() {
  //   return this.templateFilter.indskr_type;
  // }

  // public set messagingTemplateType(messagingTemplateType) {
  //   this.templateFilter.indskr_type = messagingTemplateType
  // }

  @ViewChildren('emailtemplateItem') displayedEmailTemplateItem;
  sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; } | { text: string; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  //@ViewChild(Content, {static:true}) content: Content;

  get hasTemplates(): boolean {
    return this.templates && this.templates.length > 0;
  }

  public searching: boolean = false;
  emailTemplateSearchText: string;
  shouldFireSearchChangeEvent: boolean = true;
  suggestionsData: {
    header: IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey: string = '';
  selectedSuggestionsData: SelectedSuggestionPillDataModel[] = [];
  suggestionsActive: boolean = false;
  disableSaveSearch: boolean = false;
  public filterMetadata = { count: 0 };
  indHeaderLeftModel: IndHeaderLeftDataModel;
  doneButtonRef: any;
  allTemplateListHeader: IndSectionHeaderViewDataModel;
  matchedTemplateListHeader: IndSectionHeaderViewDataModel;
  resultTemplateListHeader: IndSectionHeaderViewDataModel;

  constructor(private navService: NavigationService,
    public uiService: UIService,
    public emailService: EmailService,
    protected popoverCtrl: ModalController,
    protected popover: PopoverController,
    private brandService: BrandOfflineService,
    public navCtrl: IonNav,
    public events: Events,
    private trackingService: TrackService,
    private authService: AuthenticationService,
    private consentService: ConsentService,
    private alertService: AlertService,
    private _cd: ChangeDetectorRef,
    public utilityService: GlobalUtilityService,
    public translate: TranslateService,
    private callPlanService: CallPlanOfflineService,
    public searchConfigService: SearchConfigService,
    public searchConfigDataService: SearchConfigDataService,
    public emailTemplateListElement: ElementRef,
    public emailTemplateServices: EmailTemplateServices,
    public disk: DiskService,
    public footerService: FooterService
  ) {

    // this.events.subscribe("update-token-replacement",(body)=>{
    //   this.selectedTemplate.indskr_body = body;
    //   this.isTemplateChanged = (this.activityService.selectedActivity['template_id'] !== this.selectedTemplate.indskr_emailtemplateid || this.selectedTemplate.indskr_body !== this.originalTemplateBody);
    // })
    // this.originalTemplateBody = this.activityService.selectedActivity.description;

  }

  ngOnInit() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) {
      this.isMessageProductBundleFeature = true;
    }
    const email: EmailActivity = this.emailService.selectedActivity;
    const channelId = email.indskr_channelid
    if (channelId != undefined || channelId != "") {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
      const requiredTemplateId: string = String(cha.indskr_consenttypeid);
      this.templates = cloneDeep(this.emailService.emailTemplates.filter((template) => template.channelTypes.some((ct) => ct.channelId === requiredTemplateId)));
      if (this.isMessageProductBundleFeature ) {
        const productId = email.product_id || '';
        const level1Id = email.level1Product || '';
        const level2Id = email.level2Product || '';
        var acceptedIds = [productId, level1Id, level2Id];
        this.templates = this.templates.filter(et => {
          return ((et.product_id === null || acceptedIds.includes(et.product_id)) && et.statuscode === 1);
        });
      }
    }

    if (this.navService.getPreviousPageName() === PageName.ActivitiesPageComponent) {
      this.uiService.prevView = 'Email';
    }
    // if (email.isMessageExternal === true) {
    //    if (email.channelActivityType == ChannelActivityType.SMS || email.channelActivityType == ChannelActivityType.WHATSAPP) {
    //      let targetChannelType;
    //      if (email.channelActivityType == ChannelActivityType.SMS) {
    //       targetChannelType = ChannelType.SMS;
    //      } else if (email.channelActivityType == ChannelActivityType.WHATSAPP) {
    //       targetChannelType = ChannelType.WHATSAPP ;
    //      }
    //     this.templates = cloneDeep(this.emailService.emailTemplates.filter((template) => template.channelTypes.some((ct) => ct.channelType === targetChannelType)));
    //   } else {
    //     const requiredTemplate: string = email.type;
    //     this.templates = cloneDeep(this.emailService.emailTemplates.filter((template) => template.channelTypes.some((ct) => ct.channelType === requiredTemplate)));
    //   }
    // } else {
    //   this.templates = cloneDeep(
    //     this.emailService.emailTemplates.filter((template) =>
    //       template.channelTypes.some(
    //         (ct) => ct.channelType === email.channelType
    //       )
    //     )
    //   );
    // }
    const filterType =
      this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING &&
        this.emailService.isRemoteEmail
        ? EmailTemplateType.RemoteURL
        : EmailTemplateType.Resource;
    this.templates = this.templates.filter(
      (template) =>
        template.indskr_type === filterType &&
        this.filterExpiredTemplates(template)
    );
    if (email.template_id) {
      const temp = this.templates.find(
        (t) => t.indskr_emailtemplateid === email.template_id
      );
      // this.previewTemplate(temp);
      this.selectedTemplate = temp;
    }

    this.matchedTemplates = cloneDeep(
      this.emailService.matchedMessageTemplates.filter(
        (template) =>
          template.indskr_type === filterType &&
          this.filterExpiredTemplates(template)
      )
    );

    const orderBy = new OrderByPipe();
    const itemsForFilterDropdown = orderBy.transform(
      this.brandService.brands.map((b) => ({
        text: b.name,
        value: b.ID,
        disabled: false,
      })),
      'text',
      true
    );

    if (this.searchConfigService.configUpdateRequired) {
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }

    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "indskr_name",
        items: [
          { text: this.translate.instant('NAME'), value: "indskr_name", asc: true },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedon", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.initSectionHeader();
          // this._initKeyControlsAreaModel();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    // this.isTemplateChanged = this.selectedTemplate && (this.activityService.selectedActivity['template_id'] !== this.selectedTemplate.indskr_emailtemplateid || this.selectedTemplate.indskr_body !== this.originalTemplateBody);
    // this._initKeyControlsAreaModel();
    this.updateEmptyMessage()
    this.initHeaderLeft();
    this.initSectionHeader();
    this._initKeyControlsAreaModel();
  }

  private initHeaderLeft() {
    let buttons = [
      {
        id: 'cancel',
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_cancel.svg',
        isDisabled: false,
        align: 'left'
      },
      {
        id: 'done',
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: this.isDoneButtonDisabled(),
        align: 'right'
      }
    ];

    this.indHeaderLeftModel = {
      id: 'email-template-list-header-left',
      title: this.translate.instant('TEMPLATES'),
      mode: true,
      controls: buttons
    };
    this.doneButtonRef = this.indHeaderLeftModel.controls[1];
  }

  private initSectionHeader() {
    this.allTemplateListHeader = {
      id: 'email-template-list-all',
      title: this.translate.instant('ALL_MESSAGE_TEMPLATES') + " (" + this.templates.length + ")",
      controls: [
        {
          id: 'all-email-template-list-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img: 'assets/imgs/sort_with_double_arrows.svg',
          isVisible: (this.templates.length > 0)
        }
      ]
    };

    this.matchedTemplateListHeader = {
      id: 'matched-template-list-all',
      title: this.translate.instant('MATCHED_MESSAGE_TEMPLATES') + " (" + this.matchedTemplates.length + ")",
      controls: []
    };

    this.resultTemplateListHeader = {
      id: 'email-template-list-result',
      title: this.translate.instant('AL_RESULTS') + " (" + this.filterMetadata.count + ")",
      controls: [
        {
          id: 'all-email-template-list-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img: 'assets/imgs/sort_with_double_arrows.svg',
          isVisible: (this.templates.length > 0)
        }
      ]
    };
  }

  public onSectionHeaderControlClick(id: string) {
    this.sort();
  }

  private _initKeyControlsAreaModel() {
    this.emailTemplateListKeyControlAreaModel = {
      id: 'email-template-list-key-controls-area',
      isHidden: false,
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
      controls: [
        {
          id: 'save-search',
          text: this.translate.instant('SAVE_SEARCH'),
          isHidden: false,
          leftAligned: true,
          isDisabled: !this.searching || this.disableSaveSearch
        }
      ]
    };
  }

  private handleControlsAreaEvent(id, event, eventName) {
    if (id && eventName && eventName == 'controls-click') {
      switch (id) {
        case 'save-search':
          this.saveAdvancedSearch();
          break;
        default:
          console.log('Unhandled switch case statement');
      }
    }
  }

  private isDoneButtonDisabled() {
    return !this.selectedTemplate || this.selectedTemplate.indskr_emailtemplateid == this.emailService.selectedActivity['template_id'];
  }

  onHeaderControlClick(id) {
    if (id === 'cancel') {
      this.goBack(false);
    } else if (id === 'done') {
      this.goBack(true);
    }
  }

  public get filteredTemplateCount(): number {
    if (this.displayedEmailTemplateItem) {
      return this.displayedEmailTemplateItem.toArray().length;
    } else if (this.templates) {
      return this.templates.length;
    } else {
      return 0;
    }
  }

  // filter() {
  //   this.popover
  //     .create(MultiSelectPopover, { root: this.filterPopoverData })
  //     .present({ ev: event });

  //   this.templateFilter.indskr_name = '';
  // }
  sort() {
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event })
      .then((data) => data.present());
  }
  // handleSearch() {
  //   delete this.templateFilter.product_id;
  //   this.filterPopoverData.map(o => {
  //     o.value = ''
  //   })
  // }
  // getHeaderString() {
  //   if ((this.templateFilter.indskr_name || '') === ''
  //     && ((this.templateFilter.product_id || '') === '' || this.templateFilter.product_id === 'All')) {
  //     return `All ${this.emailService.selectedActivity ? (this.emailService.selectedActivity.channelType + ' ') : ''}Templates`;
  //   }

  //   let str;
  //   if (this.templateFilter.product_id) {
  //     let product = this.brandService.brands.find(o => o.ID == this.templateFilter.product_id);
  //     str = product.name
  //   }
  //   else if (this.templateFilter.indskr_name) {
  //     str = 'searched';
  //   }
  //   return str ? str.toUpperCase() : '';
  // }

  previewTemplate(template: ResourceEmailTemplate) {
    this.currentTemplateId = template.indskr_emailtemplateid;
    this.emailService.selectTemplate(template);

    if (!this.shouldAddButtonVisible(template)) return;
    // this.selectedTemplate =  {...template};
    // this.isTemplateChanged = (this.activityService.selectedActivity['template_id'] !== this.selectedTemplate.indskr_emailtemplateid || this.selectedTemplate.indskr_body !== this.originalTemplateBody);
  }

  updateEmptyMessage() {
    let dataSize = this.templates.length
    if (this.searching) {
      dataSize = this.filteredList.length
      if (this.selectedTemplate) {
        dataSize = 1
      }
    }
    this.uiService.updateNothingSelectedScreenMessageFor(dataSize)
  }

  removeTemplate(template: ResourceEmailTemplate) {
    this._cd.detectChanges();
    this.selectedTemplate = undefined;
    this._cd.detectChanges();
    this._cd.markForCheck();
    this.updateEmptyMessage()
    if (this.doneButtonRef) {
      this.doneButtonRef.isDisabled = this.isDoneButtonDisabled();
    }
    this._initKeyControlsAreaModel();
  }

  selectTemplate(template: ResourceEmailTemplate) {
    this._cd.detectChanges();
    this.selectedTemplate = template;
    this._cd.detectChanges();
    this._cd.markForCheck();
    if (this.doneButtonRef) {
      this.doneButtonRef.isDisabled = this.isDoneButtonDisabled();
    }
    this._initKeyControlsAreaModel();
    // this.isTemplateChanged = (this.activityService.selectedActivity['template_id'] !== this.selectedTemplate.indskr_emailtemplateid || this.activityService.selectedActivity.description !== this.originalTemplateBody);
  }

  async cancel() {
    return this.goBack(false);
  }

  async addOrUpdatetemplate(type: EmailActionType) {
    if (type == EmailActionType.CONTACT_AND_RESOURCE_REMOVE) {
      await Promise.all([
        this.emailService.detectResourceAddedOrRemoved([]),
        this.emailService.updateEmailActivityParties([], true)
      ]);
    } else if (type == EmailActionType.RESOURCE_REMOVE) {
      await this.emailService.detectResourceAddedOrRemoved([]);
    } else if (type == EmailActionType.CONTACT_REMOVE) {
      await this.emailService.updateEmailActivityParties([], true);
    }
    // let selectedChannel = this.selectedTemplate.channelTypes.find(ct => ct.channelType === this.emailService.selectedActivity.channelType);
    let selectedChannel;
    let emailBody;
    const email: EmailActivity = this.emailService.selectedActivity;
    const channelId = email.indskr_channelid
    if (channelId != undefined || channelId != "") {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
      const requiredTemplateId: string = String(cha.indskr_consenttypeid);
      selectedChannel = this.selectedTemplate.channelTypes.find(ct => ct.channelId === requiredTemplateId);
      switch (cha.activityType){
        case 548910003:
          emailBody = this.selectedTemplate.indskr_sms_body || '';
          break;
        case 548910004:
          emailBody = this.selectedTemplate.indskr_whatsapp_body || '';
          break;
        default:
          emailBody = this.selectedTemplate.indskr_body || '';
          break;
      }
    }
    //let emailBody = (selectedChannel ? selectedChannel.body : this.selectedTemplate.indskr_body);
    // if (email?.isPreviewEnabled) {
    //   if (email.channelActivityType == ChannelActivityType.SMS || email.channelActivityType == ChannelActivityType.WHATSAPP) {
    //     let targetChannelType;
    //     if (email.channelActivityType == ChannelActivityType.SMS) {
    //      targetChannelType = ChannelType.SMS;
    //     } else if (email.channelActivityType == ChannelActivityType.WHATSAPP) {
    //      targetChannelType = ChannelType.WHATSAPP ;
    //     }
    //     selectedChannel = this.selectedTemplate.channelTypes.find(ct => ct.channelType === targetChannelType);
    //   }
    // } else {
    //   selectedChannel = this.selectedTemplate.channelTypes.find(ct => ct.channelType === this.emailService.selectedActivity.channelType);
    // }

    // let emailBody = this.selectedTemplate.indskr_body;

    // if(this.activityService.selectedActivity.description){
    //   emailBody = this.activityService.selectedActivity.description;
    // }

    let request = {
      template_id: this.selectedTemplate.indskr_emailtemplateid,
      subject: this.selectedTemplate.indskr_email_subject,
      description: emailBody,
      product_id: this.isMessageProductBundleFeature? email.product_id : this.selectedTemplate.product_id,
      indskr_therapeuticareaid: this.selectedTemplate.therapeuticareaId
    }
    // if(this.isMessageProductBundleFeature && !this.selectedTemplate.product_id && !email.template_id){
    //   request['level1Product'] =  null;
    //   request['level2Product'] =  null;
    // }
    if (this.emailService.isActionTriggeredFromFooter) { this.trackingService.tracking('EmaiTemplateAddedActionBar', TrackingEventNames.EMAIL); }
    else { this.trackingService.tracking('EmaiTemplateAdded', TrackingEventNames.EMAIL); }
    //reset the value for next iteration
    this.emailService.isActionTriggeredFromFooter = false;

    // Commented this part as token replacement will be handled in dynamics only
    // if (this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity
    //   && request.description && request.description.indexOf(MeetingProperties.meetingURL)) {
    //   request.description = request.description.replace(MeetingProperties.meetingURL, this.activityService.selectedActivity.meetingURL);
    // }
    await this.emailService.updateEmailActivity(request, EmailActionType.TEMPLATE_ADD);
    if (type == EmailActionType.CONTACT_AND_RESOURCE_REMOVE || type == EmailActionType.CONTACT_REMOVE) {
      this.events.publish("refreshAgenda");
    }
    this.popToPreviousPage();
  }

  ngAfterViewChecked() {
    //this.content.resize();
  }

  async goBack(saveSelection: boolean) {
    if (saveSelection && this.selectedTemplate) {
      const email: EmailActivity = this.emailService.selectedActivity;
      if (this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL) && email.templateRefProductId
        && ((email.emailActivityParties && email.emailActivityParties.length) || (email.emailAttachments && email.emailAttachments.length))) {
        let type: EmailActionType = EmailActionType.TEMPLATE_ADD;
        if (email.templateRefProductId != this.selectedTemplate.product_id) {
          if (email.emailActivityParties.length && email.emailAttachments.length) type = EmailActionType.CONTACT_AND_RESOURCE_REMOVE;
          else if (email.emailActivityParties.length) type = EmailActionType.CONTACT_REMOVE;
          else if (email.emailAttachments.length) type = EmailActionType.RESOURCE_REMOVE;
        } else if (this.selectedTemplate.product_id == email.templateRefProductId && this.selectedTemplate.indskr_body.indexOf("%content.url%") == -1) {
          type = EmailActionType.RESOURCE_REMOVE;
        } else {
          this.isTemplateChanged = true;
          if (type == EmailActionType.TEMPLATE_ADD) this.emailService.isTemplateNewlyAdded = true;
          await this.addOrUpdatetemplate(type);
          return;
        }
        if (this.isMessageProductBundleFeature) {
          if (this.selectedTemplate.product_id !== email.product_id) {
            this.alertService.showAlert({
              title: this.translate.instant('CHANGE_TEMPLATE'),
              message: this.translate.instant('ON_CHANGING_TEMPLATE_PRODUCT', { productText: email.emailProductName })
            }, this.translate.instant('CHANGE')
            ).then(async res => {
              if (res.role == "ok") {
                this.uiService.activeView = this.uiService.prevView;
                this.isTemplateChanged = true;
                await this.addOrUpdatetemplate(EmailActionType.TEMPLATE_ADD);
                if (type == EmailActionType.TEMPLATE_ADD) this.emailService.isTemplateNewlyAdded = true;
              }
            });
          } else {
            this.uiService.activeView = this.uiService.prevView;
            this.isTemplateChanged = true;
            await this.addOrUpdatetemplate(EmailActionType.TEMPLATE_ADD);
            if (type == EmailActionType.TEMPLATE_ADD) this.emailService.isTemplateNewlyAdded = true;
          }
          if (email.emailAttachments?.length) {
            await this.emailService.detectResourceAddedOrRemoved([]);
          }
        }
        else {
          this.alertService.showAlert({
            title: this.translate.instant('CHANGE_TEMPLATE'),
            message: this.translate.instant('ON_CHANGING_TEMPLATE_CUTOMERS_ATTACHMENTS', { globalcustomertext: this.utilityService.globalCustomerText.toLowerCase() })
          }, this.translate.instant('CHANGE')
          ).then(async res => {
            if (res.role == "ok") {
              this.uiService.activeView = this.uiService.prevView;
              this.isTemplateChanged = true;
              await this.addOrUpdatetemplate(type);
              if (type == EmailActionType.TEMPLATE_ADD) this.emailService.isTemplateNewlyAdded = true;
            }
          });
        }
      } else if (email.emailAttachments && email.emailAttachments.length && email.templateRefProductId &&
        (email.templateRefProductId != this.selectedTemplate.product_id ||
          this.selectedTemplate.indskr_body.indexOf("%content.url%") == -1)) {

        this.alertService.showAlert({
          title: this.translate.instant('CHANGE_TEMPLATE'),
          message: this.translate.instant('CHANGING_TEMPLATE_WILL_GET_RESET')
        }, this.translate.instant('CHANGE')
        ).then(async res => {
          if (res.role == "ok") {
            this.uiService.activeView = this.uiService.prevView;
            await this.addOrUpdatetemplate(EmailActionType.RESOURCE_REMOVE);
          }
        });

      } else {
        this.uiService.activeView = this.uiService.prevView;
        this.isTemplateChanged = true;
        this.emailService.isTemplateNewlyAdded = true;
        await this.addOrUpdatetemplate(EmailActionType.TEMPLATE_ADD);
        if (email.emailAttachments?.length && this.isMessageProductBundleFeature) {
          await this.emailService.detectResourceAddedOrRemoved([]);
        }
      }

    } else {
      this.popToPreviousPage();
    }
  }

  private popToPreviousPage() {
    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      // //@ts-ignore
      // if (this.navCtrl._elementRef.nativeElement.classList.contains('fullStretchView')) {
      //   //@ts-ignore
      //   this.navCtrl._elementRef.nativeElement.classList.remove('fullStretchView')
      // }
      if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
      }
      this.navCtrl.pop({ progressAnimation: false }).then(() => {
        this.showNotification();
        this.uiService.activeView = this.uiService.prevView;
        this.emailService.setCurrentEmail(this.emailService.selectedActivity);
      });
    }
    else if (this.navService.getPreviousPageName() === PageName.CallPlanComponent) {
      this.uiService.activeView = 'Email';
      this.callPlanService.callPlanPageView = 'CallPlanNewEmailDetails';
      this.navService.popWithPageTracking();
      this.showNotification();
      // this.footerService.initButtons(FooterViews.EmailDetails);
      this.events.publish('update-email-footer');
    }
    else if (this.navService.getPreviousPageName() === PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans'){
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showRightPane = true;
      this.navService.popWithPageTracking();
      this.showNotification();
    }
    else if (this.navService.getPreviousPageName() === PageName.ContactPageComponent) {
      this.uiService.activeView = 'Email';
      this.navService.popWithPageTracking();
      this.showNotification();
      // this.footerService.initButtons(FooterViews.EmailDetails);
      this.events.publish('update-email-footer');
    }
    else if (this.navService.getPreviousPageName() === PageName.ScientificActivityPage) {
      this.uiService.activeView = 'Email';
      this.navService.popWithPageTracking();
      this.showNotification();
      // this.footerService.initButtons(FooterViews.EmailDetails)
      this.events.publish('update-email-footer');
    } else if (this.uiService.prevView === 'Email') {
      this.uiService.activeView = this.uiService.prevView;
      this.navService.popWithPageTracking().then(() => {
        // this.footerService.initButtons(FooterViews.EmailDetails);
        this.showNotification();
        // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
      });
    }
    else {
      this.navService.popWithPageTracking().then(() => {
        this.showNotification();
      });
    }
  }

  showNotification() {
    if (this.isTemplateChanged) {
      this.emailService.showNotification();
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('update-token-replacement');
  }

  shouldAddButtonVisible(template: ResourceEmailTemplate): boolean {
    // if (this.consentEnabled) {
    //   return template.shouldBeGreyedOut ? false : !this.selectedTemplate || this.selectedTemplate.indskr_emailtemplateid !== template.indskr_emailtemplateid;
    // } else {
    return !this.selectedTemplate || this.selectedTemplate.indskr_emailtemplateid !== template.indskr_emailtemplateid;
    // }
  }

  // private _initKeyControlsAreaModel() {
  //   this.templatesListKeyControlAreaModel = {
  //     id:'templates-list-key-controls-area',
  //     isHidden: this.templates.length == 0,
  //     eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
  //     controls:[
  //       {
  //         id:'sort-btn',
  //         text: (this.sortBy && this.sortBy['text'])?this.sortBy['text']:'',
  //         showImage: true,
  //         imageSRC: 'assets/imgs/sort_with_double_arrows.svg',
  //         showIcon: true,
  //         iconName: 'md-arrow-dropdown',
  //         leftAligned:false
  //       },
  //     ]
  //   };
  // }

  // private handleControlsAreaEvent(id,event,eventName){
  //   if(id && eventName && eventName == 'controls-click'){
  //     switch(id){
  //       case 'sort-btn':
  //         this.sort();
  //         break;
  //       default:
  //         console.log('Unhandled switch case statement');
  //     }
  //   }
  // }

  // Start Advanced Search Functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if (this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  onInput(event) {
    this.searchKey = '';
    if (!this.shouldFireSearchChangeEvent) {
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.value) ? event.value : '';
    //for length > 2
    if (params.length > 2) {
      if (params.trim().length == 0) return;
      this.searching = true;
      this.searchKey = params;
      this.searchEmailTemplateSuggestions(this.templates, params, event);
    }
    // for length 0 to 2
    else {
      this.suggestionsData = [];
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = [];
        this.searching = false;
        this.sortEmailTemplatesBySortSelection(this.sortBy);
      }
      else {
        this.searchEmailTemplatesList();
      }
      if (!this.emailTemplateServices.recentSearches || !this.emailTemplateServices.recentSearches.length) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Email Templates')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
    }
    this._initKeyControlsAreaModel();
    this.updateEmptyMessage();
    this._cd.detectChanges();
  }

  searchEmailTemplateSuggestions(emailTemplate: ResourceEmailTemplate[], searchText: string, event?): ResourceEmailTemplate[] {

    if (searchText && emailTemplate) {
      searchText = searchText.toUpperCase();
      //first search categories and category values
      let searchIndexConfig = this.searchConfigService.emailTemplatesSearchIndexesConfig;
      this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig, this.selectedSuggestionsData, this.translate.instant("EMAIL_TEMPLATES"), this.emailTemplateSearchText);
      if (this.suggestionsData.length) this.suggestionsActive = true;
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = emailTemplate;
      }
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
      this.filteredList = this.filteredList.filter(emailTemplate => {
        let templateString = this.getOjectValues(emailTemplate);
        return templateString.includes(formattedSearchText);
      });
    } else {
      return [];
    }
    this.filterMetadata.count = this.filteredList.length;
    this.initSectionHeader();
  }

  searchEmailTemplatesList() {
    //second level search for email templates
    this.searching = true;
    let filteredTemplates: any = this.templates;
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    // selectedSuggestionsDataCopy = selectedSuggestionsDataCopy.filter(suggestion=> suggestion.categoryName!='Therapeutic Areas');
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o => o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredTemplates = filteredTemplates.filter(template => {
          let resourceEntityLevel = this.getOjectValues(template);
           return resourceEntityLevel.includes(searchText);
         });
      });
    }
    filteredTemplates = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredTemplates);
    this.filteredList = filteredTemplates;
    this.filterMetadata.count = this.filteredList.length;
    this.initSectionHeader();
  }

  getOjectValues(templateObject): string {
    let objString: string[] = [];
    let emailInclude = ['indskr_name'];
    for (let template in templateObject) {
      if (emailInclude.indexOf(template) > -1 && templateObject[template]) {
        if (Array.isArray(templateObject[template])) {
          for (let i = 0; i < templateObject[template].length; i++) {
            objString.push(this.getOjectValues(templateObject[template][i]));
          }
        } else {
          objString.push(templateObject[template]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  clickSearchArea(ev) {
    ev.stopPropagation();
    if (!this.suggestionsActive) {
      this.suggestionsActive = true;
      if (!this.emailTemplateServices.recentSearches || !this.emailTemplateServices.recentSearches.length) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Email Templates')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
    }
  }

  ionSearchFocus(ev) {
    try {
      let el = this.emailTemplateListElement.nativeElement.ownerDocument.getElementById('allEmailTemplates');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  sortEmailTemplatesBySortSelection(selectedSortOption) {
    if (!this.searching) {
      if (selectedSortOption.value.includes('$')) {
        return;
      }
      if (selectedSortOption.asc) {
        this.templates.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
          else return -1;
        })
      }
      else {
        this.templates.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
          else return 1;
        })
      }
    }
  }

  removeAllSelectedSuggestions() {
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.emailTemplateSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this._initKeyControlsAreaModel();
  }

  removeSelectedSuggestion(suggestion) {
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData, (o) => _.isEqual(o, suggestion));
    if (this.selectedSuggestionsData.length == 0) {
      //this.content.resize();
      this.filteredList = [];
      this.emailTemplateSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
    }
    else {
      this.searchEmailTemplatesList();
    }
    this._initKeyControlsAreaModel();
  }

  async saveAdvancedSearch(failedText?: string) {
    let saveSearchName: string
    let toolName;
    toolName = this.searchConfigService.toolNames.find(o => o.toolName == 'Email Templates')
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o => o.searchToolName == toolName.searchToolNameID);
    this.alertService.showAlert({
      title: this.translate.instant('SAVE_SEARCH'),
      subTitle: failedText ? failedText : '',
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
      inputs: [{ type: 'text', name: "name", placeholder: this.translate.instant('ENTER_NAME') }]
    }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
          if (res.data && res.data.values.name) {
            saveSearchName = res.data.values.name.trim();
            let sameNameSearches = currentSavedSearches.filter(o => o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0);
            let incrementNumber: number = 1;
            if (sameNameSearches.length) {
              saveSearchName += ' (';
              _.each(sameNameSearches, (searchData => {
                if (searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0) {
                  let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                  if (!isNaN(currentIncrement) && currentIncrement >= incrementNumber) incrementNumber = currentIncrement + 1;
                }
              }))
              saveSearchName += incrementNumber + ')';
            }
            this.disableSaveSearch = true;
            await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
              toolName ? toolName.searchToolNameID : '',
              UserSavedSearchTypes.OWNED,
              this.selectedSuggestionsData.slice())
          }
          else if (res.data && res.data.values.name == "") {
            this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
          }
        }
      })
  }

  clickedInSuggestionsArea(ev) {
    ev.stopPropagation();
  }

  handleFacetSelection(data: SelectedSuggestionPillDataModel) {
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a, b) => {
      if (a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.emailTemplateSearchText = '';
    this.suggestionsData = [];
    this.shouldFireSearchChangeEvent = false;
    this.searchEmailTemplatesList();
    if (!data.isComingFromRecentSearch) {
      let recentSearches;
      recentSearches = this.emailTemplateServices.recentSearches
      if (!recentSearches.some(recSearch => recSearch.categoryName == data.categoryName && recSearch.selectedFacet == data.selectedFacet)) {
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index) => index > 2);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.EMAIL_TEMPLATE_RECENT_SEARCHES, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            }
          }
          doc.raw = recentSearches;
          return doc;
        })
      }
    }
  }

  handleSavedSearchSelection(data) {
    this.selectedSuggestionsData = [];
    if (data && data.categoryValuePairs) {
      data.categoryValuePairs.forEach(catValPair => {
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a, b) => {
        if (a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.emailTemplateSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchEmailTemplatesList();
    }
  }

  private filterExpiredTemplates(template: ResourceEmailTemplate): boolean {
    if (!template.indskr_available_from && !template.indskr_available_until)
      return true;

    const today = Utility.changeUTCDateToLocalDateWith0Time(
      new Date().getTime(),
      true
    );
    const availableFrom = Number(template.indskr_available_from);
    const availableUntil = Number(template.indskr_available_until);

    console.log(Utility.changeUTCDateToLocalDateWith0Time(
      availableFrom,
      true
      ).getTime()
    );

    console.log(Utility.changeUTCDateToLocalDateWith0Time(
      availableUntil,
      true
      ).getTime()
    );
    if (!availableFrom && !availableUntil) {
      return true;
    } else if (availableFrom && availableUntil)
      return (
        Utility.changeUTCDateToLocalDateWith0Time(
          availableFrom,
          true
        ).getTime() <= today.getTime() &&
        Utility.changeUTCDateToLocalDateWith0Time(
          availableUntil,
          true
        ).getTime() >= today.getTime()
      );
    else if (availableFrom && !availableUntil) {
      return (
        Utility.changeUTCDateToLocalDateWith0Time(
          availableFrom,
          true
        ).getTime() <= today.getTime()
      );
    } else if (!availableFrom && availableUntil) {
      return (
        Utility.changeUTCDateToLocalDateWith0Time(
          availableUntil,
          true
        ).getTime() >= today.getTime()
      );
    }
  }
}
