<ion-grid no-padding>
  <ion-row>
    <ion-col class="left-pane">

      <div class="geneeNotifcationListHeader">
        <ion-toolbar class="genee-toolbar">
          <ion-buttons slot="start">
            <ion-button (click)="clearChatHistory()" class="backButton">
              <ion-badge class="clearButton">
                {{'CLEAR' | translate}}
              </ion-badge>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="closeTools()" class="backButton" fill="clear">
              <img src="assets/imgs/closeGeneeScreen.svg" width="25" height="25" />
            </ion-button>
          </ion-buttons>
          <ion-title>{{'GENEE_ASSISTANT' | translate}}</ion-title>
        </ion-toolbar>
      </div>

      <div id="outer_div" *ngIf="assitantViewType!='notifications'" class="geneeKPIAssistantContainer">
        <ion-content #chatScroll class="messages" *ngIf="!showOfflineMsg">
          <div id="inner_parent_div"
            [ngClass]="shoudUseRunTimeStyle() ? 'parent-scroll-content-runtime' : 'parent-scroll-content'"
            class="parent-scroll-content">
            <div *ngFor="let message of myAssistantDataService.displayGeneeBotMessages"
              [ngClass]="{'child-scroll-content-for-self': message.from === userName, 'child-scroll-content-for-genee' : message.from !== userName && message.from !== userId }">
              <!-- User Message -->
              <div *ngIf="message.from === userName" class="userResponseContainer">
                <div class="text_wrapper">
                  <!-- Text Message -->
                  <p class="userResponseBox" *ngIf="message.text">{{message.text}}</p>
                  <!-- Feedback Message -->
                  <p class="userResponseBox" *ngIf="message.feedback">
                    <span *ngIf="message.feedback === 'positive'" class="emoji-feedback">👍</span>
                    <span *ngIf="message.feedback !== 'positive'" class="emoji-feedback">👎</span>
                  </p>
                  <div *ngIf="message.cards">
                    <ion-button fill="outline" shape="round" *ngFor="let card of message.cards"
                      (click)="sendMessage(card.value)">
                      {{card.title}}
                    </ion-button>
                  </div>
                </div>
                <div class="userInitialsBox_wrapper">
                  <div class="userInitialsBox" *ngIf="userInitials">
                    {{userInitials}}
                  </div>
                </div>
              </div>
              <!-- User Message -->
              <!-- Bot Message -->
              <div *ngIf="message.from !== userName && message.from !== userId" class="description-wrapper">
                <img src="assets/imgs/ogeeneeChat.svg" class="bot_img_size" width="40" />
                <div [attr.id]="message.id">
                  <p *ngIf="message.contentType !== 'adaptive'" class="description-container">{{message.text}}</p>
                  <!-- <div *ngIf="message.cards">
                        <button  outline round *ngFor="let card of message.cards"
                          (click)="sendMessage(card.value)">
                          {{card.title}}
                        </button>
                      </div> -->
                </div>
              </div>
              <!-- Bot Message -->
            </div>

            <div id="genee-illust" class="genee-illust-wrapper">
              <img src="assets/imgs/genee-illustration.svg" alt="Genee logo" />
            </div>
          </div>
        </ion-content>
        <div class="dv_suggestedActionsContainer">
          <!-- Typing Loader -->
          <div class="typingIndicator"
            *ngIf="myAssistantDataService.showTypingLoader && myAssistantDataService.suggestedActions.length === 0">
            <span>
              <div class="container">Genee is typing
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </span>
          </div>
          <!-- Suggestion pills -->
          <ng-container *ngIf="myAssistantDataService.suggestedActions.length">
            <!-- Arrow-Left Action -->
            <!-- <div style="float: left" *ngIf="suggestedActions.length != 0"> -->
            <ion-button @fadeInAndOut size="small" color="dark" fill="clear"
              *ngIf="myAssistantDataService.suggestedActions.length>0"
              [style.visibility]="showLeftButton ? 'visible' : 'hidden'" class="left-arrow" (click)="scrollLeft()">
              <ion-icon name="arrow-back"></ion-icon>
            </ion-button>
            <!-- </div> -->
            <!-- Bot Suggested Actions -->
            <div @fadeInAndOut class="dv_suggestedActions" *ngIf="!showOfflineMsg" #scrollableContent>
              <ng-container *ngFor="let action of myAssistantDataService.suggestedActions">
                <!--<span class="suggestionNameBubble" *ngIf="action.isNotification"
                  (click)="openGeneeNotification()">{{'NOTIFICATIONS'
                  | translate}}
                  <ion-badge color="light" class="notificationBadge" *ngIf="(getCountUnreadNotifications()>0)">
                    {{getCountUnreadNotifications()}}
                  </ion-badge>
                </span>-->
                <span *ngIf="isObject(action.value) === true && !action.isNotification" class="suggestionNameBubble"
                  (click)="sendAction(action.value,action.text)">
                  {{action.text}}
                </span>
                <span *ngIf="isObject(action.value) === false && !action.isNotification" class="suggestionNameBubble"
                  (click)="sendMessage(action.value)">
                  <img *ngIf="action.image" [src]="action.image" class="img-suggestion-action">
                  {{action.title}}
                </span>
              </ng-container>
            </div>
            <!-- Arrow-Right Action -->
            <!-- <div style="float: right" *ngIf="suggestedActions.length != 0"> -->
            <ion-button @fadeInAndOut size="small" color="dark" fill="clear"
              *ngIf="myAssistantDataService.suggestedActions.length>0"
              [style.visibility]="showRightButton ? 'visible' : 'hidden'" class="right-arrow" (click)="scrollRight()">
              <ion-icon name="arrow-forward"></ion-icon>
            </ion-button>
          </ng-container>

        </div>
        <!-- User Offline -->
        <div *ngIf="showOfflineMsg" class="description-wrapper">
          <img src="assets/imgs/ogeeneeOffline.svg" class="bot_img_size" width="35" />
          <div>
            <p class="description-container">{{ 'AVAILABLE_IN_ONLINE_ONLY' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="geneeNotifcationListFooter" justify-content-center align-items-end>
        <!-- Footer area -->
        <div *ngIf="hasKPIEnabled" class="buttonContainer">

          <!-- Speaker Start -->
          <!--KPI Voluem tab if Speaker active-->
          <ion-button fill="clear" class="disable-hover" *ngIf="!showOfflineMsg"
            (click)="footerButtonHandler('speaker', $event)">
            <img *ngIf="!isSpeakerMuted" src="assets/imgs/volume_unmute.svg" />
            <img *ngIf="isSpeakerMuted" src="assets/imgs/volume_mute.svg" />
          </ion-button>
          <!-- Speaker End -->

          <!-- User Input Section -->
          <div class="dv_userInput" *ngIf="!showOfflineMsg">
            <ion-input (keyup.enter)="sendMessage()" [(ngModel)]="finalText" type="text"
              placeholder="{{'ASK_ME_SOMETHING' | translate}}"
              [disabled]="(myAssistantDataService.showTypingLoader || isRecording || isGeneeSpeaking || showOfflineMsg) && myAssistantDataService.suggestedActions.length === 0">
            </ion-input>
            <img (click)="sendMessage()" src="assets/imgs/userResponseSend.svg" class="userInputSendIcon">
          </div>

          <!-- Mic active Loader -->
          <!-- <div class="dv_micIndicator" *ngIf="assitantViewType!='KPIAssistantKeyboard'">
            <img src="assets/imgs/ogeenee.png" class="bot_img_size" width="100" />
          </div> -->

          <!-- Mic Start -->

          <ion-button fill="clear"
            [disabled]="(isGeneeSpeaking || myAssistantDataService.showTypingLoader) && myAssistantDataService.suggestedActions.length === 0"
            *ngIf="(assitantViewType === 'KPIAssistantMic' || assitantViewType === 'KPIAssistantKeyboard') && !showOfflineMsg && !isRecording"
            (click)="footerButtonHandler('mic', $event)">
            <img src="assets/imgs/geneeListenerGIF.svg" />
          </ion-button>

          <ion-button fill="clear"
            [disabled]="(isGeneeSpeaking || myAssistantDataService.showTypingLoader) && myAssistantDataService.suggestedActions.length === 0"
            *ngIf="(assitantViewType === 'KPIAssistantMic' || assitantViewType === 'KPIAssistantKeyboard') && !showOfflineMsg && isRecording"
            (click)="footerButtonHandler('mic', $event)">
            <img src="assets/imgs/geneeListenerGIF.gif" />
          </ion-button>


          <!-- KPI Mic tab if keyboard active-->
          <!-- <ion-button fill="clear" class="disable-hover"
            *ngIf="assitantViewType=='KPIAssistantKeyboard' && !showOfflineMsg && !isGeneeSpeaking"
            (click)="footerButtonHandler('mic', $event)">
            <img src="assets/imgs/audioMic.svg" />
          </ion-button> -->
          <!--KPI Mic tab active-->
          <!-- <ion-button fill="clear" class="disable-hover"
            *ngIf="assitantViewType=='KPIAssistantMic' && !isRecording && !showOfflineMsg && !isGeneeSpeaking"
            (click)="footerButtonHandler('mic', $event)">
            <img src="assets/imgs/audioMic.svg" />
          </ion-button> -->
          <!--KPI Mic tab active and recording on-->
          <!-- <ion-button fill="clear" class="disable-hover" disabled
            *ngIf="assitantViewType=='KPIAssistantMic' && isRecording && !showOfflineMsg && !isGeneeSpeaking"
            (click)="footerButtonHandler('mic', $event)">
            <img src="assets/imgs/geneeListenerGIF.gif" />
          </ion-button> -->
          <!--KPI Mic disabled tab if notifications active or user is offline or genee is speaking-->
          <!-- <ion-button fill="clear" class="disable-hover" disabled
            *ngIf="assitantViewType=='notifications' || showOfflineMsg || isGeneeSpeaking || showTypingLoader">
            <img src="assets/imgs/audioMicDisabled.svg" />
          </ion-button> -->
          <!-- Mic End -->

        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>