<!-- <ion-item [ngClass]="{'itemSelected': isSelected}"> -->

  <!-- <div class="case-item">
    <h3>{{case?.scheduledStart | date:'MMM dd'}}</h3>
  </div>

  <div class="case-item">
    <h2 class="dark-color ellipsis" text-nowrap>{{caseName}}</h2>
  </div>
  <ion-icon class="rightArrow" tappable name="arrow-forward" slot="end" ></ion-icon> -->

  <!-- <div class="left-float">
    <h3>{{case?.scheduledStart | date:'MMM dd'}}</h3>
    <h4></h4>
  </div>
  <div class="right-float" text-left>
    <h3 class="header">{{case._case_notes ? case._case_notes : (case._case_type && case._case_type.name) ? case._case_type.name : ''}}</h3>
    <h4 class="lower-text" text-nowrap>
      <span>
          {{case?._case_stage_value}}
      </span>
      <span>
          {{case._case_contact ? case._case_contact.fullName : (case._case_trans_customer_name && case._case_trans_customer_name != 'Select '+this.utilityService.globalCustomerText) ? case._case_trans_customer_name : 'No '+this.utilityService.globalCustomerText}}
      </span>
    </h4>
  </div>
  <ion-icon class="rightArrow" tappable name="arrow-forward" slot="end" >
  </ion-icon>


</ion-item> -->
<main-card [viewData]="getMainCardDataModel()"></main-card>

<!-- <ion-item class="agenda-item" tappable *ngIf="!activity.isDivider && activity.type == 'CaseIntake'" [id]="activity.ID"
  (click)="confirmRemoteMeetingdisconnect(activity);"
  [ngClass]="{'itemSelected':activity.ID === activityService?.selectedActivity?.ID}">
  <div class="left-float">
    <h3>{{activity?.scheduledStartLocale}}</h3>
    <h4></h4>
  </div>
  <ion-thumbnail class="left-float">
    <img [src]="activityService.getActivityIcon(activity)" width="20">
  </ion-thumbnail>
  <div text-left>
    <h3>{{activity.caseName}}</h3>
    <h4 text-nowrap>
      {{activity?._case_assignee}}
    </h4>
  </div>
  <ion-icon class="light-color" tappable [name]="activity.expandIcon" slot="end" (click)="expandList($event,activity);">
  </ion-icon>
  <expandable #expWrapper [viewType]="'customer_inquiry'" (onDeleteActivity)="deleteCI(activity)" [activity]="activity"
    [expandHeight]="itemExpandHeight" [expanded]="activity.expanded">
  </expandable>
</ion-item> -->
