import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "@omni/services/authentication.service";
import {DynamicsClientService} from "@omni/data-services/dynamics-client/dynamics-client.service";
import {MarketScan} from "@omni/classes/market-scan/market-scan.class";
import {Endpoints} from "../../../config/endpoints.config";

@Injectable({
  providedIn: 'root'
})
export class MdmDataService{
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private dynamics: DynamicsClientService,) {
  }

  public async getSourceTypeOptionsets(): Promise<any[]> {
    return  await this.dynamics.retrieveGlobalOptionSet("Name='indskr_source'");
  }

  public async getMDMOptionsets(): Promise<any[]> {
    return  await this.dynamics.retrieveGlobalOptionSet("Name='indskr_mdmsystem'");
  }

  public async getContactChangeRequests(url: string): Promise<any> {
    return await this.http.get<any[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getAccountChangeRequests(url: string): Promise<any> {
    return await this.http.get<any[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async createChangeRequest(url: string, contactCR: any) {
    let headers = Endpoints.headers.content_type.json;
      headers.headers = headers.headers.set(
          'No-Retry', 'true'
      );
    return this.http.post(url, contactCR, headers).toPromise();
  }

  public async getHopQrCode(url: string) {
    return this.http.get(url).toPromise();
  }

  public async getMaterial(url: string, payload) {
    let headers = Endpoints.headers.content_type.json;
    headers.headers = headers.headers.set(
        'No-Retry', 'true'
    );
    return this.http.post(url, payload, headers).toPromise();
  }

  public async editChangeRequest(url: string, contactCR: any) {
    return this.http.put(url, contactCR).toPromise();
  }

  public async updateChangeRequest(url: string, contactCR: any) {
    return this.http.patch<any>(url, contactCR, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async scrapChangeRequest(url: string, cr: any) {
    return this.http.patch(url, cr).toPromise();
  }

}
