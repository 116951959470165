import { GpsDetails } from "../activity/appointment.activity.class";
import { GPSActivityPhoto } from "../store-check/photo";

export class GPSApprovalActivity {
  public indskr_approvalactivityid: string;
  public sentForApproval: Date;
  public statuscode: number;
  public gpsDetails: GpsDetails;
  public subject: string;
  public location: string
  public activityId: string;
  public ownerId: string;
  public ownerName: string;
  public accountId: string;
  public accountName: string;
  public modifiedon: Date;
  public gpsActivityPhotos: GPSActivityPhoto[];
  public reason?: string;
  public positionName: string;

  constructor(raw) {
    this.indskr_approvalactivityid = raw['indskr_approvalactivityid'];
    this.sentForApproval = raw['sentForApproval'];
    this.statuscode = raw['statuscode'];
    this.gpsDetails = raw['gpsDetails'];
    this.subject = raw['appSubject'];
    this.location = raw['appLocation'];
    this.activityId = raw['activityId'];
    this.ownerId = raw['ownerId'];
    this.ownerName = raw['ownerId@OData.Community.Display.V1.FormattedValue'];
    this.accountId = raw['accountId'];
    this.accountName = raw['accountId@OData.Community.Display.V1.FormattedValue'];
    this.gpsActivityPhotos = raw['gpsActivityPhotos'];
    this.modifiedon = raw['modifiedon'];
    this.reason = raw['indskr_reason'];
    this.positionName = raw['positionName'];
  }
}