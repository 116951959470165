import { ModalController, PopoverController } from '@ionic/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { CaseManagementService } from '../../../services/case-management/case-management.service';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { Subscription } from 'rxjs';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ChildUsersPageComponent } from '../../child-users-page/child-users-page';
import { RepServices } from '../../../data-services/rep/rep.services';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import * as _ from 'lodash';
import { FooterService } from '@omni/services/footer/footer.service';

/**
 * Generated class for the CasePopoverComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'case-popover',
  templateUrl: 'case-popover.html',
  styleUrls:['case-popover.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CasePopoverComponent implements OnInit{

  /* Defines the popover type to be opened */
  type: string;
  /* Used to setting the user selection */
  listSelection: any;
  /* Used to set the array that will be used to display the options */
  optionList: any[] = [];

  selectedCase: CaseActivity;
  ICaseSubscription: Subscription;
  teamViewEnabled: boolean;
  subType: string;
  searchText ='';
  @Input() value: any;
  caseSelectHeaderModel: IndSectionHeaderViewDataModel;
  caseAllProductHeaderModel: IndSectionHeaderViewDataModel;
  caseResultHeaderModel: IndSectionHeaderViewDataModel;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public contactSearchText: string = '';

  constructor(
    public repService: RepServices,
    public authenticationService: AuthenticationService,
    public device: DeviceService,
    public platform: Platform,
    public caseManagementService: CaseManagementService,
    private readonly navService: NavigationService,
    public productService: BrandOfflineService,
    private _cd: ChangeDetectorRef,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public translate: TranslateService,
    public footerService: FooterService
  ) {
    this.teamViewEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAMVIEW_FILTER);
  }

  ngOnInit() {
    this.popupInitHandler(this.value.field);
    this.selectedCase = this.caseManagementService.currentCase;
    /* Preselcting product in modal */
    if (this.type === CasePopupEvent.PRODUCTSELECT) {
      this.listSelection = this.selectedCase._case_product;
    }
    this.initSectionHeader();
    this.initProductsHeaderLeft();
  }

  initSectionHeader() {
    this.caseSelectHeaderModel = {
      id:'details-header',
      title: this.translate.instant('SELECTED') + " " + "(1)",
      controls: []
    };

    this.caseAllProductHeaderModel = {
      id:'details-header',
      title: this.translate.instant('ALL_PRODUCTS_CAP') + " " + "(" + String(this.productSize) + ")",
      controls: []
    };

    this.caseResultHeaderModel = {
      id:'details-header',
      title: this.translate.instant('RESULTS_CAP') + " " + "(" + String(this.productSize) + ")",
      controls: []
    };
  }

  private initProductsHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      imgSrc: 'assets/imgs/header_cancel.svg',
      cssClass: 'seventyPercentWidth',
      //name:this.translate.instant('CANCEL'),
      isDisabled: false,
      align: "left",
    },{
      id: "done",
      imgSrc: 'assets/imgs/header_complete.svg',
      cssClass: 'seventyPercentWidth',
      //name:this.translate.instant('DONE'),
      isDisabled: this.isProductSelectionDoneDisabled,
      align: "right",
    });

    this.indHeaderLeftModel = {
      id: 'product-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('PRODUCTS'),
      mode: true,
      controls: buttons,
    };
  }

  public get isProductSelectionDoneDisabled(): boolean {
    let flag = false;
    if (this.listSelection === this.selectedCase._case_product) {
      flag = true;
    }
    return flag;
  }

  public onCompletion(isDone: boolean) {
    let data = { selectedItems: this.listSelection, isDone: isDone };
    this.modalCtrl.dismiss(data);
  }

  onPageTitleControlClick(id){
    if(id==='close'){
      this.onCompletion(false);
    }
    if(id==='done'){
      this.onCompletion(true);
    }
  }


  private popupInitHandler(type) {
    switch(type){
      case CasePopupEvent.LISTFILTER: {
        this.type = CasePopupEvent.LISTFILTER;
        break;
      }

      case CasePopupEvent.PRODUCTSELECT: {
        this.type = CasePopupEvent.PRODUCTSELECT;
        this.optionList = this.caseManagementService.caseProductSKU.filter((product) => product.isCompetitorProduct != "true");
        break;
      }

      case CasePopupEvent.RESPONSEPREFENCE: {
        this.type = CasePopupEvent.RESPONSEPREFENCE;
        this.optionList = this.caseManagementService.responsePreferenceList
        break;
      }

      case CasePopupEvent.THERAPEUTICAREA: {
        this.type = CasePopupEvent.THERAPEUTICAREA;
        if (this.value.case._case_product && this.value.case._case_product.therapeuticAreas && this.value.case._case_product.therapeuticAreas.length) {
          this.optionList = this.value.case._case_product.therapeuticAreas;
        }
        break;
      }

      case CasePopupEvent.CASECATEGORY: {
        this.type = CasePopupEvent.CASECATEGORY;
        try {
          let catergories = this.caseManagementService.getCurrentCaseCategoryTypes();
          console.log(catergories);
          this.optionList = catergories;
        } catch (error) {
          console.log(error)
        }
        break;
      }

      case CasePopupEvent.CONTACTDETAILS: {
        this.type = CasePopupEvent.CONTACTDETAILS;
        this.selectedCase = this.caseManagementService.currentCase;

        if (this.selectedCase) {
          if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('email')) {
            this.optionList = this.selectedCase._case_contact ? this.selectedCase._case_contact.emailAddressList : [];
            this.subType = 'email';
          }
          if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('phone')) {
            let temp = [];
            this.subType = 'phone';
            if (this.selectedCase._case_contact && this.selectedCase._case_contact.mobilePhone) {
              temp.push(this.selectedCase._case_contact.mobilePhone);
            }
            if (this.selectedCase._case_contact && this.selectedCase._case_contact.telephone) {
              temp.push(this.selectedCase._case_contact.telephone);
            }
            if (this.selectedCase._case_contact && this.selectedCase._case_contact.indskr_alternatephone1) {
              temp.push(this.selectedCase._case_contact.indskr_alternatephone1);
            }
            if (this.selectedCase._case_contact && this.selectedCase._case_contact.indskr_alternatephone2) {
              temp.push(this.selectedCase._case_contact.indskr_alternatephone2);
            }
            if (this.selectedCase._case_contact && this.selectedCase._case_contact.indskr_alternatephone3) {
              temp.push(this.selectedCase._case_contact.indskr_alternatephone3);
            }

            this.optionList = temp;
          }
          if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('visit')) {
            this.subType = 'visit';
            this.optionList = this.selectedCase._case_contact ? this.selectedCase._case_contact.addressesList : [];
          }
        }

        break;
      }

      default: {
        break;
      }
    }
  }

  public listFilterUpdate(event: string) {
    console.log(event);
    this.caseManagementService.caseFilterText = event;
    this.caseManagementService.teamInquiriesFilter.type = event;
    //filter should not dismiss after selection
    //selcting All should remove other selections
    if(event=='All'){
      this.caseManagementService.teamInquiriesFilter.product = '';
      this.caseManagementService.filterObject = undefined;
      this.caseManagementService.teamInquiriesFilter.user = '';
    }
  }

  public teamListFilter(event: any, type: string) {
    this.caseManagementService.caseFilterText = type;
    this.caseManagementService.filterObject = event;
    this.caseManagementService.teamInquiriesFilter.product = event.name
    //type should not be All if a product gets selected
    if(this.caseManagementService.teamInquiriesFilter.type=='All'){
      this.caseManagementService.teamInquiriesFilter.type=''
    }
  }

  onOptionSelection(event) {
    console.log(event);
    this._cd.detectChanges();
    this.listSelection = event;
    this._cd.detectChanges();
    this._cd.markForCheck();
    this.initProductsHeaderLeft();
  }

  unselect(){
    console.log(event);
    this._cd.detectChanges();
    this.listSelection = null;
    this._cd.detectChanges();
    this._cd.markForCheck();
    this.initProductsHeaderLeft();
  }

  /*
    Single select option and close the popup
  */
  selectOptionAndClose(selection) {
    this.popoverCtrl.dismiss(selection);
  }

  goToUserSelection() {
    this.popoverCtrl.dismiss();
    this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'CasePageFilter' })
  }

  onSearch() {
    const e = String(this.searchText).toLowerCase();
    if(e.trim() !== ''){
      switch (this.type) {
        case CasePopupEvent.LISTFILTER: {
          break;
        }

        case CasePopupEvent.PRODUCTSELECT: {
          try {

            let temp: any[] = [];
            this.caseManagementService.caseProductSKU.forEach(p => {
              if (p.isCompetitorProduct != "true") {
                /* Tetsing if product name or the sku in the current product contains the entered text */
                if (String(p.name).toLowerCase().includes(e) || p.skus.some(s => String(s.name).toLowerCase().includes(e))) {
                  let prod = Object.assign({}, p);
                  prod.skus = [];
                  if (p.skus && !_.isEmpty(p.skus)) {
                    prod.skus = p.skus.filter(sku => sku.name.toLowerCase().includes(e.toLowerCase()));
                  }
                  temp.push(prod);
                }
              }
            });
            this.optionList =  temp;
          } catch (error) {
            this.popupInitHandler(this.type);
          }
          break;
        }

        case CasePopupEvent.RESPONSEPREFENCE: {
          break;
        }

        case CasePopupEvent.THERAPEUTICAREA: {
          break;
        }

        case CasePopupEvent.CASECATEGORY: {
          break;
        }

        case CasePopupEvent.CONTACTDETAILS: {
          break;
        }

        default: {
          break;
        }

      }
    }
    else {
      this.popupInitHandler(this.type);
      this.initSectionHeader()
      return;
    }
    this.initSectionHeader()
  }

  get productSize(): number {
    let l = 0;
    l = this.optionList.length;
    this.optionList.forEach(p => {
      if(p.hasOwnProperty('skus') && Array.isArray(p['skus'])){
        l += p['skus'].length;
      }
    });
    return l;
  }

}



export enum CasePopupEvent {
  LISTFILTER = 'list-filter',
  PRODUCTSELECT = 'product-select',
  RESPONSEPREFENCE = 'response-preference',
  CASECATEGORY = 'case-category',
  CONTACTDETAILS = 'contact-details',
  THERAPEUTICAREA = 'therapeutic-area'
}
