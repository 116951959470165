<ind-section-header [viewData]="{id: 'details', title:translate.instant('DETAILS') }">
</ind-section-header>
<ion-row class="details">
  <ng-container *ngIf="selectedTab == 'Approvals'">
    <ion-col size="4">
      <omni-list-item label="{{'SUBMITTED_BY' | translate}}" value="{{selectedCustomerPositionList.ownerName}}"
        cssClass="list-item">
      </omni-list-item>
    </ion-col>
    <ion-col size="4">
      <omni-list-item label="{{'SENT_FOR_APPROVAL' | translate}}" value="{{formattedApprovalCreatedOn}}"
        cssClass="list-item">
      </omni-list-item>
    </ion-col>
  </ng-container>
  <ion-col size="4">
    <omni-list-item label="{{'CREATED' | translate}}" value="{{formattedCreatedOn}}" cssClass="list-item">
    </omni-list-item>
  </ion-col>
  <ion-col size="4">
    <omni-list-item label="{{'STATUS' | translate}}"
      value="{{territoryService.getStatusCodeFormatted(selectedCustomerPositionList.statuscode)}}" cssClass="list-item">
    </omni-list-item>
  </ion-col>
</ion-row>
<ng-container *ngIf="approvalActivities?.length > 0">
  <ind-section-header [viewData]="{id: 'approvals', title: translate.instant('APPROVALS') }">
  </ind-section-header>
  <div class="table-wrapper">
    <table class="table">
      <tbody>
        <tr class="table-row table-head-row">
          <th class="table-column table-head">{{ 'APPROVER' | translate }}</th>
          <th class="table-column table-head">{{ 'ASSIGNED' | translate }}</th>
          <th class="table-column table-head">{{ 'CYCLE' | translate }}</th>
          <th class="table-column table-head">{{ 'STATUS' | translate }}</th>
          <th class="table-column table-head"></th>
        </tr>
        <tr class="table-row" *ngFor="let approvalActivity of approvalActivities">
          <td class="table-column">{{approvalActivity.approver}}</td>
          <td class="table-column">{{approvalActivity.createdOnFormatted}}</td>
          <td class="table-column">{{approvalActivity.indskr_cycle}}</td>
          <td class="table-column">{{approvalActivity.statusCodeFormatted}}</td>
          <td class="table-column">
            <ion-button *ngIf="approvalActivity.indskr_reason" fill="icon-only" size="small" no-margin
              (click)="openCommentModal(approvalActivity)">
              <ion-icon name="indegene-comments" class="table-icon"></ion-icon>
            </ion-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>