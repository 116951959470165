<!-- <ion-item class="scheduler-item" [ngClass]="{'itemSelected': isSelected}">
    <div class="left-float" (click)="select('open', $event)">
        <h3>{{schedule.startdate | date:'MMM dd'}}</h3>
        <p class="duration-text">{{schedule.durationValue}}d</p>
    </div>
    <div text-left  (click)="select('open', $event)" >
        <h2 class="dark-color ellipsis" text-nowrap>{{schedule.name}}</h2>
        <h4 class="light-color ellipsis" text-nowrap>
          {{productList}}
        </h4>
    </div>
    <ion-icon *ngIf="!schedule.isNewScheduler && !isExpanded" class="light-color" (click)="select('expand', $event)" tappable name="chevron-down-outline" slot="end" ></ion-icon>
    <ion-icon *ngIf="!schedule.isNewScheduler && isExpanded" class="light-color" (click)="select('expand', $event)" tappable name="chevron-up-outline" slot="end" ></ion-icon>
    <expandable *ngIf="!schedule.isNewScheduler" [viewType] ="'schedulerList'" (onScheduleAction)="actionSelected($event)" [expandHeight]="itemExpandHeight" [expanded]="isExpanded"></expandable>
</ion-item> -->
<main-card [viewData]='schdeduleMainCardModel' [isDeviceOnline]='isDeviceOnline'></main-card>