import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { POPUP_MODAL_HEADER_HEIGHT, POPUP_MODAL_MAX_HEIGHT_PERCENT } from '@omni/config/shared.config';

@Component({
  selector: 'customer-call-plan-popover',
  templateUrl: 'customer-call-plan-popover.html',
  styleUrls: ['customer-call-plan-popover.scss'],
})
export class CustomerCallPlanPopover implements OnInit {
  currentAlert: HTMLIonPopoverElement;
  @Input() inputs: any;
  @Input() header: string;
  @Input() cancelText;
  @Input() confirmText;
  @Input() isReadOnly: boolean;

  @Input() inputTextValue: string;

  @Input() textAreaPlaceholder: string
  @Input() textAreaValue: string;

  public isInvalidValue: boolean = false;
  @Input() errorMessage: string;

  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;

  constructor(
    private popoverCtrl: PopoverController,
    public alertController: AlertController,
    private renderer: Renderer2,
  ) {


  }

  onDismiss(flag) {
    if (flag && +this.inputTextValue === 0) {
      this.isInvalidValue = true;
      return;
    }
    if (!flag) {
      this.popoverCtrl.dismiss();
      return;
    }

    let payload = {
      suggestedValue: this.inputTextValue,
      comments: this.textAreaValue ? this.textAreaValue : null,
      role: 'ok'
    }

    this.popoverCtrl.dismiss(payload);

  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.updateHeight();
  }

  onTextChange (value)
  {
    this.inputTextValue = value;
    if(this.inputTextValue === null) {
      if (!this.isInvalidValue) {
        this.isInvalidValue = true;
        this.updateHeight();
      }
    } else {
      if (this.isInvalidValue) {
        this.isInvalidValue = false;
        this.updateHeight();
      }
    }
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }
  private updateHeight() {
    setTimeout(() => {
      const maxContentHeight = window.innerHeight * POPUP_MODAL_MAX_HEIGHT_PERCENT - POPUP_MODAL_HEADER_HEIGHT;
      const contentHeight = Math.min(maxContentHeight, this.contentWrapper.nativeElement.offsetHeight);
      this.setContentHeight(contentHeight);
    }, 50);
  }
}
