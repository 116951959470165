<ion-header>
    <ind-page-title [viewData]='{id:"account-cr-details",title: rawFormData.indskr_name, controls: pageTitleControls}' (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
</ion-header>

<ion-content no-padding>
    <ind-display-form #scrollTop [formMetadata]='displayFormDetails' [rawData]='rawFormData' [referencingEntityName]='"indskr_accountcrid"' [linkedEntityDBPrefix]='accountCRLinkedEntityDBPrefix' [TYPE]="'ONE_KEY'" [dynamicFormType]='dynamicFormType'
    [isEditEnabled]="isEditFeatureEnabled" (onEditClick)="editAccountCR($event)">
    </ind-display-form>
    <ng-container *ngIf="noteTextHistory && noteTextHistory.length>0">
      <ind-section-header [viewData]="notesSectionHeaderViewData"></ind-section-header>
      <io-note *ngFor="let note of noteTextHistory" [inputNote]="note" [editable]="false" [readOnlyNote]="true"
        [deletable]="false" [disableDownloadInOffline]="true" [from]="uiService.page.AccountCrDetailsComponent"></io-note>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'accountCRDetails'"></footer-toolbar>
<!-- </ion-footer> -->