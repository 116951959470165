import { Component, Input, NgZone } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { BusinessProcessViewDataModel } from '@omni/models/business-process-view-data.model';

@Component({
  selector: 'business-process',
  templateUrl: 'business-process.html',
  styleUrls: ['business-process.scss'],
})
export class BusinessProcessComponent {

  @Input() viewData: Array<BusinessProcessViewDataModel>;
  @Input() public scroller: IonContent;
  private isDynamicScrollTriggered:boolean = false;

  constructor(
    private ngZone: NgZone,
  ) {
  }

  ngAfterViewInit() {
    if (this.scroller) {
      this.scroller.scrollEvents = true;
      this.scroller.ionScrollEnd.subscribe(event => {
        this.scroller.getScrollElement().then(ele => {
          if(!this.isDynamicScrollTriggered){
            let fromTop = ele.scrollTop;
            if (this.viewData) {
              this.viewData = this.viewData.map(a=> {
                a.isActive = false;
                return a;
              });
              for(let i=0;i<this.viewData.length;i++){
                const section = this.viewData[i];
                let element = document.getElementById(section.bindingElementId);
                if (element) {
                  if (element.offsetTop + element.offsetHeight > fromTop) {
                    this.viewData[i].isActive = true;
                    this.businessProcessChanged(null,section,true);
                    break;
                  }
                }
              }
            }
          }
          this.isDynamicScrollTriggered = false;
        });
      });
    }
  }

  public businessProcessChanged(e,btn:BusinessProcessViewDataModel,isBusinessProcessItself:boolean = false): void {
    this.ngZone.run(() => {
      try {
        if(e){
          e.preventDefault();
        }
        let target = (isBusinessProcessItself)?document.getElementById(btn.id):document.getElementById(btn.bindingElementId);
        target.scrollIntoView();
        this.viewData = this.viewData.map(a=> {
          if(a.id == btn.id){
            a.isActive = true;
          }else{
            a.isActive = false;
          }
          return a;
        });
        if(!isBusinessProcessItself){
          this.isDynamicScrollTriggered = true;
          setTimeout(()=>{
            this.isDynamicScrollTriggered = false;
          },1000);
        }
      } catch (error) {
        console.log(error)
      }
    });
  }

}
