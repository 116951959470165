<img *ngIf="device.isOffline || !isDataAvailable()" class="os_background-list" [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios}" />
<div class="timelineWrapper">
  <div *ngIf="!isTimelineFiltered">
    <div class="contactTimelineFilter" #scrollTop>
        <ion-select #contactTimelineSelect (ionChange)="onTimelineTypeChange(contactTimelineSelect.value)" [(ngModel)]="contactTimelineFilter" interface="popover" [interfaceOptions]="{ cssClass: 'contact-timeline-filter'}" class="timelineFilter" [selectedText]="getSelectedText(contactTimelineFilter)"
            [value]="contactTimelineFilter">
            <ion-select-option *ngFor="let option of filterOptions" [value]="option.value">
                {{option.displayText | translate:{ globalCustomerText: utilityService.globalCustomerText } }}
            </ion-select-option>
        </ion-select>
        <div>
          <ion-buttons class="contactTimelineButtons" slot="end">
            <ion-icon class="contactTimelineFilterIcon" src="assets/imgs/filter-blue.svg" (click)="openTimelineFilter($event)"> </ion-icon>
          </ion-buttons>
        </div>
    </div>
    <ion-list class="containerForData">
        <div class="no-data-message" *ngIf="!isDataAvailable() && contactService.isContactDetailsLoaded"> {{(isJourney? 'CONTACT_NO_JOURNEY' : 'NO_TIMELINE') | translate}}</div>
        <ion-item-group class="timeline-group" *ngFor="let group of groupedActivitiesToDisplay">
            <ion-item-divider sticky='true' [id]="group.key">
                <div class="dividerBorder"></div>
                <div class="monthGroupName">{{group.key}}</div>
            </ion-item-divider>
            <ion-item *ngFor="let activity of group.list" (click)="openActivityDetails(activity)">
                <ion-grid>
                    <ion-row>
                        <ion-col size="3">
                            <div class="ion-float-center" size="3">
                                <h3 class="ion-text-center">{{getday(!activity.eventStartDate?activity.scheduledStart:activity.eventStartDate)}}</h3>
                                <h4 class="ion-text-center time-label">{{formattedDate(!activity.eventStartDate?activity.scheduledStart:activity.eventStartDate)}}</h4>
                            </div>
                        </ion-col>
                        <ion-col size="8">
                            <div class="col-9 ion-float-left ion-margin-right" size="8">
                                <h3>
                                    <div *ngIf="activity.type == 'Appointment'">{{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'Sample'">{{ 'ALLOWCATION_ORDER' | translate}} - {{activity.orderId}}</div>
                                    <div *ngIf="activity.type == 'Email'">{{ 'MESSAGE' | translate}}{{getChannelType(activity)}} - {{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'CaseIntake'">{{ 'INQUIRY' | translate}} - {{activity.subject}}</div>
                                    <div *ngIf="(activity.type == 'Event' || (activity && contactTimelineFilter == 'Contact_Event'))">{{ 'EVENT' | translate}} - {{activity.name ? activity.name :activity.eventName}} </div>
                                    <div *ngIf="activity.type == 'PhoneCall'">{{ 'NEW_ACTIVITY_PHONECALL' | translate}} - {{getPhoneCallSubject(activity.subject)}}</div>
                                    <div *ngIf="activity.type == 'SurgeryOrder'">{{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'ConsentActivity'">{{activity.subject}}</div>
                                    <div *ngIf="activity.type == 'WebsiteAccessLogs'" class="websiteAccessLog"> {{activity.channel}} - {{activity.department}} - {{activity.contactName}}</div>
                                    <div *ngIf="activity.type == 'CustomerSurvey'">{{ 'SURVEY' | translate}} - {{activity.subject}}</div>
                                    <!-- **************************Customer Journey************************** -->
                                    <div *ngIf="activity.type == 'CustomerJourneyAppointment'">{{ 'CUSTOMER_JOURNEY' | translate}} - {{ 'MEETING' | translate}} - {{activity.journeyName}}</div>
                                    <div *ngIf="activity.type == 'CustomerJourneyPhoneCall'">{{ 'CUSTOMER_JOURNEY' | translate}} - {{ 'NEW_ACTIVITY_PHONECALL' | translate}} - {{activity.journeyName}}</div>
                                    <div *ngIf="activity.type == 'CustomerJourneyEmail'">{{ 'CUSTOMER_JOURNEY' | translate}} - {{ 'EMAIL' | translate}} - {{activity.journeyName}}</div>
                                    <!-- **************************Customer Journey************************** -->

                                </h3>
                                <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'Sample'">
                                    <div>{{ getMeetingOrAllocationLabel(activity) }}</div>
                                    <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                    <div *ngIf="activity.location != 'No Location'" class="locationValue" >{{activity.location}}</div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                    <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                    <div *ngIf="activity.senton && !activity.readOn">
                                        {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                    </div>
                                    <div *ngIf="activity.readOn">
                                        {{ 'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.channelTypeName == 'WeCom'">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate :
                                    {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div>
                                    {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.scheduledStart)} }}
                                  </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status == 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SHARED_BY_ME': 'CONTACT_SHARED_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div *ngIf="activity.actualEnd">
                                    {{'CONTACT_SHARED_ON' | translate : {time : emailService.getFormattedTime(activity.actualEnd)} }}
                                  </div>
                              </h4>
                                <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'Email' || activity.channelTypeName == undefined)">
                                    <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                    <div *ngIf="activity.senton && !activity.readOn">
                                        {{'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                    </div>
                                    <div *ngIf="activity.readOn">
                                        {{ 'CONTACT_READ_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                    </div>
                                    <div *ngIf="activity.email_linksclickedcount > 0">
                                        {{ 'ATTACHMENT_ACCESSED' | translate }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'CaseIntake'">
                                    <span>{{ 'ASSIGNED_TO_OWNER'  | translate : {owner : activity.meetingOwnerName} }}</span>
                                    <span class="left-margin">{{activity.statusString}}</span>
                                </h4>
                                <h4 *ngIf="isCustomerEventsEnabled">
                                    <div *ngIf="activity.registrationresponseId">
                                        {{ (activity.indskr_ownername == userName ? 'CONTACT_SIGNEDUP_BY_ME': 'CONTACT_SIGNEDUP_BY_OWNER') | translate : {text : activity.indskr_ownername} }}
                                    </div>
                                    <div *ngIf="!activity.registrationresponseId && activity.eventRegistrationId">
                                        {{ (activity.registeredBy == userName ? 'CONTACT_REGISTERED_BY_ME': 'CONTACT_REGISTERED_BY_OWNER') | translate : {text : activity.registeredBy} }}
                                    </div>
                                </h4>
                                <h4 *ngIf="activity.type == 'PhoneCall'">
                                    <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                                    <div class="ion-margin-start left-margin">{{activity.phonecallphonenumber}}</div>
                                </h4>
                                <h4 *ngIf="activity.type == 'SurgeryOrder'">
                                    <span *ngIf="activity.products && activity.products.length != 0">
                                    {{activity.products[0].productName + (activity.products.length>1?('+'+(activity.products.length-1)):'')}}, {{activity.accountNameString}}
                                  </span>
                                    <span *ngIf="!activity.products || activity.products.length == 0">{{activity.accountNameString}}</span>
                                </h4>
                              <h4>
                                <div *ngIf="activity.type == 'Event' && activity.createdby">
                                  {{ (activity.createdby == userName ? 'EVENT_CHECKED_BY_ME': 'EVENT_CHECKED_BY_OWNER') | translate : {text :
                                  activity.createdby} }}
                                </div>
                                <div *ngIf="activity.type == 'Event' && activity.completedby">
                                  {{ (activity.completedby == userName ? 'EVENT_COMPLETED_BY_ME': 'EVENT_COMPLETED_BY_OWNER') | translate : {text :
                                  activity.completedby} }}
                                </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'ConsentActivity'">
                                <div>{{activity.source}}</div>
                                <div>{{activity.secondaryText}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'WebsiteAccessLogs'">
                                <div class ="websiteAccessLog">
                                  {{activity.pageTitle}} - {{activity.pageURL}} - {{activity.additionalInfo}}
                                </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'CustomerSurvey'">
                                <div>{{activity.surveyFrequency}}</div>
                                <div>{{activity.surveyedBy}}</div>
                              </h4>
                              <!-- **************************Customer Journey************************** -->
                              <h4 *ngIf="activity.type == 'CustomerJourneyAppointment'">
                                <div>{{ getCustomerJourneyMeetingLabel(activity) }}</div>
                                <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'CustomerJourneyPhoneCall'">
                                <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                                <div>{{activity.phonecallphonenumber}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'CustomerJourneyEmail'">
                                <div>{{getCustomerJourneyEmailPrimaryLabel(activity)}}</div>
                                <div>{{getCustomerJourneyEmailSecondaryLabel(activity)}}</div>
                              </h4>
                              <!-- **************************Customer Journey************************** -->
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-item-group>
        <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-list>
  </div>
  <div *ngIf="isTimelineFiltered">
    <div class="contactTimelineFilter" #scrollTop>
      <ion-label>
        <div>{{ 'RESULTS_SMALL' | translate }} ({{filteredListLength}}) </div>
      </ion-label>
      <div class="searchbar-overlay">
        <ion-buttons class="searchbar-overlay-button contactTimelineButtons" slot="end">
          <div class="section-header-button-text" (click)="clearFilterResults()"> {{ 'CLEAR_FILTER' | translate }} </div>
          <ion-icon class="searchbar-overlay-button-filter-icon contactTimelineFilterIcon" src="assets/imgs/filter-blue.svg" (click)="openTimelineFilter($event)"> </ion-icon>
          <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge">{{ filterButtonBadgeCount }}</span>
        </ion-buttons>
      </div>
  </div>
  <ion-list class="containerForData">
      <div class="no-data-message" *ngIf="!isDataAvailable() && contactService.isContactDetailsLoaded"> {{(isJourney? 'CONTACT_NO_JOURNEY' : 'NO_TIMELINE') | translate}}</div>
      <ion-item-group class="timeline-group" *ngFor="let group of filteredGroupedActivitiesToDisplay">
          <ion-item-divider sticky='true' [id]="group.key">
              <div class="dividerBorder"></div>
              <div class="monthGroupName">{{group.key}}</div>
          </ion-item-divider>
          <ion-item *ngFor="let activity of group.list" (click)="openActivityDetails(activity)">
              <ion-grid>
                  <ion-row>
                      <ion-col size="3">
                          <div class="ion-float-center" size="3">
                              <h3 class="ion-text-center">{{getday(activity.scheduledStart)}}</h3>
                              <h4 class="ion-text-center time-label">{{formattedDate(activity.scheduledStart)}}</h4>
                          </div>
                      </ion-col>
                      <ion-col size="8">
                          <div class="col-9 ion-float-left ion-margin-right" size="8">
                              <h3>
                                  <div *ngIf="activity.type == 'Appointment'">{{ 'MEETING' | translate}} - {{activity.subject}}</div>
                                  <div *ngIf="activity.type == 'Sample'">{{ 'ALLOWCATION_ORDER' | translate}} - {{activity.orderId}}</div>
                                  <div *ngIf="activity.type == 'Email'">{{ 'MESSAGE' | translate}}{{getChannelType(activity)}} - {{activity.subject}}</div>
                                  <div *ngIf="activity.type == 'CaseIntake'">{{ 'INQUIRY' | translate}} - {{activity.subject}}</div>
                                  <div *ngIf="(activity.type == 'Event' || (activity && contactTimelineFilter == 'Contact_Event'))">{{ 'EVENT' | translate}} - {{activity.name ? activity.name :activity.eventName}} </div>
                                  <div *ngIf="activity.type == 'PhoneCall'">{{ 'NEW_ACTIVITY_PHONECALL' | translate}} - {{getPhoneCallSubject(activity.subject)}}</div>
                                  <div *ngIf="activity.type == 'SurgeryOrder'">{{activity.subject}}</div>
                                  <div *ngIf="activity.type == 'ConsentActivity'">{{activity.subject}}</div>
                                  <div *ngIf="activity.type == 'WebsiteAccessLogs'" class ="websiteAccessLog"> {{activity.channel}} - {{activity.department}} - {{activity.contactName}}</div>
                                  <div *ngIf="activity.type == 'CustomerSurvey'">{{ 'SURVEY' | translate}} - {{activity.subject}}</div>
                                  <!-- **************************Customer Journey************************** -->
                                  <div *ngIf="activity.type == 'CustomerJourneyAppointment'">{{ 'CUSTOMER_JOURNEY' | translate}} - {{ 'MEETING' | translate}} - {{activity.journeyName}}</div>
                                  <div *ngIf="activity.type == 'CustomerJourneyPhoneCall'">{{ 'CUSTOMER_JOURNEY' | translate}} - {{ 'NEW_ACTIVITY_PHONECALL' | translate}} - {{activity.journeyName}}</div>
                                  <div *ngIf="activity.type == 'CustomerJourneyEmail'">{{ 'CUSTOMER_JOURNEY' | translate}} - {{ 'EMAIL' | translate}} - {{activity.journeyName}}</div>
                                  <!-- **************************Customer Journey************************** -->
                              </h3>
                              <h4 *ngIf="activity.type == 'Appointment' || activity.type == 'Sample'">
                                  <div>{{ getMeetingOrAllocationLabel(activity) }}</div>
                                  <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                                  <div *ngIf="activity.location != 'No Location'" class="locationValue">{{activity.location}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div *ngIf="activity.senton && !activity.readOn">
                                      {{'CONTACT_SENT_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                  </div>
                                  <div *ngIf="activity.readOn">
                                      {{ 'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status == 548910000 && (activity.channelTypeName == 'SMS' || activity.channelTypeName == 'Whatsapp')">
                                <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SHARED_BY_ME': 'CONTACT_SHARED_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                <div *ngIf="activity.actualEnd">
                                  {{'CONTACT_SHARED_ON' | translate : {time : emailService.getFormattedTime(activity.actualEnd)} }}
                                </div>
                            </h4>
                              <h4 *ngIf="activity.type == 'Email' && activity.status != 548910000 && (activity.channelTypeName == 'Email' || activity.channelTypeName == undefined)">
                                  <div>{{(activity.meetingOwnerName == userName ? 'CONTACT_SENT_BY_ME': 'CONTACT_SENT_BY_OWNER') | translate : {meetingOwnerName : activity.meetingOwnerName} }}</div>
                                  <div *ngIf="activity.senton && !activity.readOn">
                                      {{'CONTACT_DELIVERED_ON' | translate : {time : emailService.getFormattedTime(activity.senton)} }}
                                  </div>
                                  <div *ngIf="activity.readOn">
                                      {{ 'CONTACT_READ_ON' | translate : {time : emailService.getFormattedTime(activity.readOn)} }}
                                  </div>
                                  <div *ngIf="activity.email_linksclickedcount > 0">
                                    {{ 'ATTACHMENT_ACCESSED' | translate }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'CaseIntake'">
                                  <span>{{ 'ASSIGNED_TO_OWNER'  | translate : {owner : activity.meetingOwnerName} }}</span>
                                  <span class="left-margin">{{activity.statusString}}</span>
                              </h4>
                              <h4 *ngIf="isCustomerEventsEnabled">
                                  <div *ngIf="activity.registrationresponseId">
                                      {{ (activity.indskr_ownername == userName ? 'CONTACT_SIGNEDUP_BY_ME': 'CONTACT_SIGNEDUP_BY_OWNER') | translate : {text : activity.indskr_ownername} }}
                                  </div>
                                  <div *ngIf="!activity.registrationresponseId && activity.eventRegistrationId">
                                      {{ (activity.registeredBy == userName ? 'CONTACT_REGISTERED_BY_ME': 'CONTACT_REGISTERED_BY_OWNER') | translate : {text : activity.registeredBy} }}
                                  </div>
                              </h4>
                              <h4 *ngIf="activity.type == 'PhoneCall'">
                                  <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                                  <div class="ion-margin-start left-margin">{{activity.phonecallphonenumber}}</div>
                              </h4>
                              <h4 *ngIf="activity.type == 'SurgeryOrder'">
                                  <span *ngIf="activity.products && activity.products.length != 0">
                                  {{activity.products[0].productName + (activity.products.length>1?('+'+(activity.products.length-1)):'')}}, {{activity.accountNameString}}
                                </span>
                                  <span *ngIf="!activity.products || activity.products.length == 0">{{activity.accountNameString}}</span>
                              </h4>
                            <h4>
                              <div *ngIf="activity.type == 'Event' && activity.createdby">
                                {{ (activity.createdby == userName ? 'EVENT_CHECKED_BY_ME': 'EVENT_CHECKED_BY_OWNER') | translate : {text :
                                activity.createdby} }}
                              </div>
                              <div *ngIf="activity.type == 'Event' && activity.completedby">
                                {{ (activity.completedby == userName ? 'EVENT_COMPLETED_BY_ME': 'EVENT_COMPLETED_BY_OWNER') | translate : {text :
                                activity.completedby} }}
                              </div>
                            </h4>
                            <h4 *ngIf="activity.type == 'ConsentActivity'">
                              <div>{{activity.source}}</div>
                              <div>{{activity.secondaryText}}</div>
                            </h4>
                            <h4 *ngIf="activity.type == 'WebsiteAccessLogs'">
                              <div class ="websiteAccessLog">
                                {{activity.pageTitle}} - {{activity.pageURL}} - {{activity.additionalInfo}}
                              </div>
                            </h4>
                            <h4 *ngIf="activity.type == 'CustomerSurvey'">
                              <div>{{activity.surveyFrequency}}</div>
                              <div>{{activity.surveyedBy}}</div>
                            </h4>
                            <!-- **************************Customer Journey************************** -->
                             <h4 *ngIf="activity.type == 'CustomerJourneyAppointment'">
                              <div>{{ getCustomerJourneyMeetingLabel(activity) }}</div>
                              <div *ngIf="activity.location === 'No Location'">{{'NO_LOCATION' | translate}}</div>
                              <div *ngIf="activity.location != 'No Location'">{{activity.location}}</div>
                            </h4>
                            <h4 *ngIf="activity.type == 'CustomerJourneyPhoneCall'">
                              <div>{{getPhoneCallPrimaryLabel(activity)}}</div>
                              <div>{{activity.phonecallphonenumber}}</div>
                            </h4>
                            <h4 *ngIf="activity.type == 'CustomerJourneyEmail'">
                              <div>{{getCustomerJourneyEmailPrimaryLabel(activity)}}</div>
                              <div>{{getCustomerJourneyEmailSecondaryLabel(activity)}}</div>
                            </h4>
                            <!-- **************************Customer Journey************************** -->
                          </div>
                      </ion-col>
                  </ion-row>
              </ion-grid>
          </ion-item>
      </ion-item-group>
      <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-list>
  </div>
</div>