<!--
  Generated template for the LoginPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<ion-content class="loginContent" *ngIf="loginStage === 0" [scrollEvents]="true">
<div class="content-wrapper">
  <div class="loginWrapperFlexBox">
    <div class="login-wrapper">
      <img class="login-img" src="assets/imgs/Omnipresence - logo.svg" alt="">

      <div class="login-form" padding>
        <ion-item *ngIf="displayLoginError" class="login-error-toast">
          <span>{{loginErrorMessage}}</span>
          <ion-button slot="end" (click)="onErrorMsgClose()" icon-only>
            {{'CLOSE' | translate}}
          </ion-button>
        </ion-item>

        <ion-item class="transparentBackground usernameContainer no-padding-start">
            <ion-icon class="marginUnset mailIcon" slot="start" name="mail-outline"></ion-icon>
            <input [(ngModel)]="usernameText" id="loginUserName" autofocus #username type="email" class="input"
                placeholder="{{'LOGIN_USER_NAME_DOMAIN' | translate}}" autocomplete="off" (keyup.enter)="login()">
        </ion-item>

        <ion-item class="item-remember-me transparentBackground no-padding-start">
          <ion-checkbox class="checkbox marginUnset" slot="start" [(ngModel)]="rememberMeCheckbox" ></ion-checkbox>
          <ion-label class="rememberMe">{{'LOGIN_REMEMBER_ME' | translate}}</ion-label>
        </ion-item>

        <ion-button class="login-button" expand="block" [disabled]="disableLoginButton" (click)="login()">{{'LOGIN' | translate}}</ion-button>
      </div>
    </div>
  </div>

  <div class="bottom-fixed ion-text-center" >
    <!-- <img class="exeevo-logo" src="assets/imgs/exeevo@svg.svg" alt=""> -->

    <div class="footer">
      <a style="border-right:1px solid white" (click)="openPrivacyPopover()">{{'PRIVACY_POLICY' | translate}}</a>
      <a style="border-right:1px solid white" (click)="openTerms()">{{'TERMS_AND_CONDITIONS' | translate}}</a>
      <a (click)="openCopyright()">&copy; {{'COPYRIGHT_INFO' | translate}}</a>
    </div>

  </div>
</div>
</ion-content>

<ion-content *ngIf="loginStage === 1">
  <instance-selection
    (onContinueClick)='handleInstanceSelection($event)'
  ></instance-selection>
</ion-content>
