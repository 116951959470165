<ion-header>
  <ion-toolbar>
    <ion-title text-center>
      {{'SETTINGS' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list inset *ngIf="!showVideoDetails">
    <ion-item (click)="openSettingsDetail('Settings_Calendar')">
      <img src="assets/imgs/settings_calendar.png" width="24" class="settingIcons"/>
      <span>{{'CALENDAR' | translate}}</span>
    </ion-item>
    <ion-item (click)="openSettingsDetail('Settings_Remote')">
      <img src="assets/imgs/settings_remote_meeting.png" width="24" class="settingIcons"/>
      <span>{{'REMOTE_MEETING' | translate}}</span>
    </ion-item>
    <ion-item (click)="openSettingsDetail('Settings_Me')">
      <img src="assets/imgs/settings_me.png" width="24" class="settingIcons"/>
      <span>{{'ME' | translate}}</span>
    </ion-item>
    <ion-item (click)="openSettingsDetail('Settings_Language')">
      <img src="assets/imgs/settings_lang.png" width="24" class="settingIcons"/>
      <span>{{'LANGUAGE' | translate}}</span>
    </ion-item>
    <ion-item (click)="openSettingsDetail('Settings_Position')">
      <img src="assets/imgs/settings_position.png" width="24" class="settingIcons"/>
      <span>{{'POSITION' | translate}}</span>
    </ion-item>
    <ion-item (click)="openSettingsDetail('Settings_Password')">
      <img src="assets/imgs/settings_reset_pass.png" width="24" class="settingIcons"/>
      <span>{{'RESET_PASSWORD' | translate}}</span>
    </ion-item>
    <ion-item (click)="openSettingsDetail('Settings_Storage')">
      <img src="assets/imgs/settings_storage.png" width="24" class="settingIcons"/>
      <span>{{'STORAGE' | translate}}</span>
    </ion-item>
  </ion-list>
</ion-content>