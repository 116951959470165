import { TrackService, TrackingEventNames } from './../../../services/logging/tracking.service';
import { OpportunityManagementService } from './../../../services/opportunity-management/opportunity-management.service';
import { OpportunityManagementDataService } from './../../../data-services/opportunity-management/opportunity-management.data.service';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { ActivityService } from './../../../services/activity/activity.service';
import { OrderActivity } from './../../../classes/activity/order.activity.class';
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { Opportunity } from "../../../classes/opportunity-management/opportunity.class";
import { OrderDetailsComponent } from '../../order-management/order-details/order-details';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device/device.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';

@Component({
    selector: 'opportunity-orders',
    templateUrl: 'opportunity-orders.html',
  styleUrls:['opportunity-orders.scss']
})
export class OpportunityOrdersComponent {

    public isLayoverPushView:boolean = false;
    public layoverViewPushedFrom:string = '';
    public allOrdersHeader:IndSectionHeaderViewDataModel;
    public opportunityOrders: OrderActivity[] = [];
    public opportunityOrderDataModels:Array<MainCardViewDataModel> = [];
    public selectedOpportunity: Opportunity;
    public filterPopoverData
    public noOrder:string;
    opportunityOrdersFilter: string;
    isTotalOpportunityOrderEmpty: boolean = false;
    @Input() from: any
    @Input() opportunity: any
    @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;

    constructor(
        private activityService: ActivityService,
        private navService: NavigationService,
        private popover : PopoverController,
        private opportunityDataService: OpportunityManagementDataService,
        private opportunityService: OpportunityManagementService,
        private trackingService: TrackService,
        private translate: TranslateService,
        public device: DeviceService,
        private dateTimeFormatsService: DateTimeFormatsService
    ) {
       this.noOrder=this.translate.instant('OM_NO_ORDERS')

    }

    ngOnInit(){
        if (this.from) {
          this.isLayoverPushView = true;
          this.layoverViewPushedFrom = this.from;
        }
        if (this.opportunity) {
          this.selectedOpportunity = this.opportunity;
        }
      this.initOpportunityOrdersFormFields();
      this.filterPopoverData = [
        {
          text: "",
          value: "All",
          items: [{ text:this.translate.instant('ALL_ORDERS_S'), value: 'All' }],
          handler: (selectedItem, item, itemRef) => {
            this.trackingService.tracking('SelectOrderFilter', TrackingEventNames.OPPORTUNITYMANAGEMENT)
            itemRef.parent.items.map((o)=>{
              o.value = '';
            })
            item.value = selectedItem.value;
            this.opportunityOrdersFilter = '';
            this.initOpportunityOrdersFormFields()
          }
        },
        {
          text: this.translate.instant('STATUS'),
          value: "",
          items: [
                    { text:this.translate.instant('OM_DRAFT'), value: 'Draft' },
                    { text:this.translate.instant('FOR_REVIEW'), value: 'For Review' },
                    { text:this.translate.instant('APPROVED'), value: 'Approved' },
                    { text:this.translate.instant('OM_FULFILLED'), value: 'Fulfilled' }
                  ],
          handler: (selectedItem, item, itemRef) => {
            this.trackingService.tracking('SelectOrderFilter', TrackingEventNames.OPPORTUNITYMANAGEMENT)
            itemRef.parent.items[0].value=''
            item.value = item.value === selectedItem.value ? "" : selectedItem.value;
            this.opportunityOrdersFilter = item.value;
            if(!this.opportunityOrdersFilter){
              itemRef.parent.items[0].value='All'
            }
            this.initOpportunityOrdersFormFields();
          }
        },
      ];
    }
    ionViewDidEnter(){
      if(this.selectedOpportunity){
        this.initOpportunityOrdersFormFields();
      }
    }
    ngDoCheck() {
      if(this.opportunityDataService.isSelectedOpportunityUpdated){
        this.initOpportunityOrdersFormFields();
        this.opportunityDataService.isSelectedOpportunityUpdated = false;
      }
    }

    ngAfterViewInit(){
      if(this.scrollTop){
        setTimeout(() => {
          this.scrollTop.nativeElement.scrollIntoView(false);
        }, 200);
      }
    }
    public initOpportunityOrdersFormFields(){
      this.opportunityOrders = [];
        this.activityService.activities.map((o)=>{
          if(o instanceof  OrderActivity && !(o.state == 2 && o.status == 4) && ((o.opportunityId && o.opportunityId == this.selectedOpportunity.ID)
                                            ||(o.offlineOpportunityId && o.offlineOpportunityId == this.selectedOpportunity.offlineId))){
            this.opportunityOrders.push(o);
          }
        })
        if(this.opportunityOrders.length == 0) this.isTotalOpportunityOrderEmpty = true
        else this.isTotalOpportunityOrderEmpty = false;
        if(this.opportunityOrdersFilter){
          this.opportunityOrders = this.opportunityOrders.filter((o)=>{
            return o.orderStatusString == this.opportunityOrdersFilter
          })
        }
        this.opportunityOrderDataModels = [];
        this.opportunityOrderDataModels = this.opportunityOrders.map((o)=>{
          let viewModel: MainCardViewDataModel = {
            id: o.ID,
            fixedHeight: true,
            primaryTextLeft: o.orderNumber,
            secondaryTextLeft: o.orderStatusString,
            secondaryTextLeftTwo: this.translate.instant('TOTAL')+' '+ o.formattedTotalAmount ? this.dateTimeFormatsService.formattedCurrency(o.formattedTotalAmount) : '0',
            showArrow: true,
            arrowType: 'chevron-forward-outline',
            clickHandler: (id: string, event) => this.goToOrderDetails(id)
          }
          return viewModel
        })

        if(!this.isTotalOpportunityOrderEmpty){
          this.allOrdersHeader = {
            id: 'all-orders-header',
            title: this.headerString +' ('+this.opportunityOrders.length+')',
            isFilter: true,
            showArrow: true,
            arrowType: 'caret-down-sharp',
            controls: [],
          };
        }
        else this.allOrdersHeader = undefined;
    }
    goToOrderDetails(id: string): void {
      this.trackingService.tracking('SelectOrder', TrackingEventNames.OPPORTUNITYMANAGEMENT)
      let order = this.opportunityOrders.find((o)=> o.ID == id)
      this.activityService.selected = order;
      let fromIdentifier: string = (this.layoverViewPushedFrom == 'OpportunityManagementTool')?'OpportunityManagementTool':
                                    (this.layoverViewPushedFrom == 'AccountProfileOpportunities'?'AccountProfileOpportunities':'AccountPlanOpportunities');
      let masterPage = this.navService.getCurrentMasterPageName();
      this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent,
                                                        masterPage,
                                                        { from: fromIdentifier, activity: order });
    }
    filterOrders(){
      this.popover
        .create({component :MultiSelectPopover,componentProps: { root: this.filterPopoverData }, event: event})
        .then((data)=> data.present());
    }
    get headerString(){
      let str: string = this.translate.instant('ALL_ORDERS_S');
      if(this.opportunityOrdersFilter){
        switch(this.opportunityOrdersFilter){
          case 'Draft':
          str = this.translate.instant('OM_DRAFT');
          break;
          case 'For Review':
          str = this.translate.instant('FOR_REVIEW');
          break;
          case 'Approved':
          str = this.translate.instant('APPROVED');
          break;
          case 'Fulfilled':
          str = this.translate.instant('OM_FULFILLED');
          break;
        }
      }
      return str.toUpperCase();
    }
}
