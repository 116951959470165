import { PipeTransform, Pipe } from '@angular/core';


@Pipe({
    name: 'splitRepCallPlan',
    pure: true
})
export class SplitRepCallPlanPipe implements PipeTransform {

    /**
     *  This is the pipe for split the rep call plan name to get the segment call plan
     *
     */
    transform(repcallPlanName: String) {
        if(typeof repcallPlanName != 'undefined' && repcallPlanName.indexOf(":") != -1){
            return (repcallPlanName.split(":", 1)).pop();
        }
        return repcallPlanName;
    }
}