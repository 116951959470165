<ng-container *ngIf="uiService.isConsentFromToolDrawer && activeConsents.length == 0 && pendingSyncConsents.length == 0 && !isFilterApplied
                    || (uiService.isConsentFromToolDrawer && activeConsents.length == 0 && pendingSyncConsents.length > 0 && !isFilterApplied)">
  <div class="no-data-message">{{ 'CONTACT_NO_CONSENTS' | translate}}</div>
  <img *ngIf="uiService.isConsentFromToolDrawer && activeConsents.length == 0 && pendingSyncConsents.length == 0 && !isFilterApplied
                    || (uiService.isConsentFromToolDrawer && activeConsents.length == 0 && pendingSyncConsents.length > 0 && !isFilterApplied)" class="os_background-list-consent"/>
</ng-container>

<ion-item-group>
    <ind-section-header *ngIf="pendingSyncConsents.length > 0 && uiService.isConsentFromToolDrawer" [viewData]='contactConsentPendingHeader'></ind-section-header>
    <!-- <ion-list *ngIf="pendingSyncConsents.length > 0 && uiService.isConsentFromToolDrawer">
      <omni-accordion [viewData]="consent" *ngFor="let consent of pendingSyncConsentsModel"></omni-accordion>
    </ion-list> -->
    <ion-list class="consent-sub-header">
      <ion-item-group *ngFor="let consent of pendingSyncConsentsGrid" class="consent-grid">
        <ion-item lines="none" class="remove_inner_bottom ion-no-padding" (click)="handlePendingSyncConsentExpandClick(consent.id, $event, 'mainLabel')">
          <ion-icon slot="start" name="indegene-consent-main" class="consent-icon"> </ion-icon>
          <ion-label class="consent-name"> {{consent.consentTermName}} </ion-label>
          <ion-buttons slot="end" *ngIf="isOptOutEnabled"
            (click)="optOutFromConsent($event, consent)" class="section-header-button-text"
            [disabled]="true">
            <div> {{ 'OPT_OUT' | translate }} </div>
          </ion-buttons>
        </ion-item>
        <ion-item lines="none" class="remove_inner_bottom ion-no-padding" (click)="handlePendingSyncConsentExpandClick(consent.id, $event, 'mainLabel')">
          <ion-grid>
            <ion-row *ngFor="let childItem of consent.childItems">
              <ion-col size="4" *ngFor="let item of childItem" >
                <div class="display-grid" *ngIf="!item.isMultipleValue">
                  <ion-text class="child-item-label">{{ item.label }}</ion-text>
                  <ion-label class="child-item-text">{{ item.value }}</ion-label>
                </div>
                <div *ngIf="item.isMultipleValue" class="second-level-div">
                  <ion-row>
                    <ion-col size="8" size-xs="7">
                      <div class="display-grid">
                        <ion-text class="child-item-label">{{ item.label }}</ion-text>
                        <ion-label class="child-item-text">{{ item.value }}</ion-label>
                      </div>
                    </ion-col>
                    <ion-col size="4" size-xs="5">
                      <div class="display-grid">
                        <ion-text class="child-item-label drop-down-label">{{ item.label }}</ion-text>
                        <ion-buttons (click)="expandItemValues(item, $event)">
                          <ion-label class="child-item-text drop-down-text">{{ item.dropDownText }}</ion-label>
                          <ion-icon class="arrow-icon" name='chevron-down-outline' slot="start"></ion-icon>
                        </ion-buttons>
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-item-group>
    </ion-list>

    <!-- Active Consents - All list -->
    <div *ngIf="!isOptOutFiltered">
      <ind-section-header *ngIf="activeConsents.length > 0" [viewData]='contactConsentActiveHeader' (onFilterClick)="openActiveConsentsFilter($event)" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <!-- <ion-list>
          <omni-accordion [viewData]="consent" *ngFor="let consent of activeConsentsModel"></omni-accordion>
      </ion-list> -->
      <ion-list class="consent-sub-header">
        <ion-item-group *ngFor="let consent of activeConsentsGrid" class="consent-grid">
          <ion-item lines="none" class="remove_inner_bottom ion-no-padding" (click)="handleActiveConsentExpandClick(consent.id, $event, 'mainLabel')">
            <ion-icon slot="start" name="indegene-consent-main" class="consent-icon"> </ion-icon>
            <ion-label class="consent-name"> {{consent.consentTermName}} </ion-label>
            <ion-buttons slot="end" *ngIf="isOptOutEnabled"
              (click)="optOutFromConsent($event, consent)" class="section-header-button-text-card"
              [disabled]="consent.isOptOutDisabled">
              <div> {{ 'OPT_OUT' | translate }} </div>
            </ion-buttons>
            <ion-icon *ngIf="!readOnly" slot="end" src="assets/imgs/ellipsis.svg" class="consent-more-option-icon" (click)="openMoreOptions(consent.id, $event, 'mainLabel')"> </ion-icon>
          </ion-item>
          <ion-item lines="none" class="remove_inner_bottom ion-no-padding" (click)="handleActiveConsentExpandClick(consent.id, $event, 'mainLabel')">
            <ion-grid>
              <ion-row *ngFor="let childItem of consent.childItems">
                <ion-col size="4" *ngFor="let item of childItem">
                  <div class="display-grid" *ngIf="!item.isMultipleValue">
                    <ion-text class="child-item-label">{{ item.label }}</ion-text>
                    <ion-label class="child-item-text">{{ item.value }}</ion-label>
                  </div>
                  <div *ngIf="item.isMultipleValue" class="second-level-div">
                    <ion-row>
                      <ion-col size="8" size-xs="7">
                        <div class="display-grid">
                          <ion-text class="child-item-label">{{ item.label }}</ion-text>
                          <ion-label class="child-item-text">{{ item.value }}</ion-label>
                        </div>
                      </ion-col>
                      <ion-col size="4" size-xs="5">
                        <div class="display-grid">
                          <ion-text class="child-item-label drop-down-label">{{ item.label }}</ion-text>
                          <ion-buttons (click)="expandItemValues(item, $event)">
                            <ion-label class="child-item-text drop-down-text">{{ item.dropDownText }}</ion-label>
                            <ion-icon class="arrow-icon" name='chevron-down-outline' slot="start"></ion-icon>
                          </ion-buttons>
                        </div>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </div>
    <!-- Active Consents - Filtered list For Opt Out -->
    <div *ngIf="isOptOutFiltered">
      <ind-section-header [viewData]='filteredConsentActiveHeader' (onFilterClick)="openActiveConsentsFilter($event)" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
      <ion-item-group class="selected-filter">
        <ion-item lines="none" class="remove_inner_bottom ion-no-padding filter-header">
          <ion-grid>
            <ion-row>
              <ion-col size="auto" class="filter-item" *ngFor="let filter of selectedFilters">
                <div class="display-grid">
                  <ion-text class="child-item-label">{{ filter.label }}</ion-text>
                  <ion-label class="child-item-text">{{ filter.value }}</ion-label>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-buttons slot="end" (click)="optOutFromAllConsent($event)" class="section-header-button-text" [disabled]="filteredActiveConsentsGrid.length == 0">
            <div> {{ 'OPT_OUT_FROM_ALL' | translate }} </div>
          </ion-buttons>
        </ion-item>
      </ion-item-group>
      <ion-list class="consent-sub-header">
        <ion-item-group *ngFor="let consent of filteredActiveConsentsGrid" class="consent-grid">
          <ion-item lines="none" class="remove_inner_bottom ion-no-padding" (click)="handleActiveConsentExpandClick(consent.id, $event, 'mainLabel')">
            <ion-icon slot="start" name="indegene-consent-main" class="consent-icon"> </ion-icon>
            <ion-label class="consent-name"> {{consent.consentTermName}} </ion-label>
            <ion-buttons slot="end"
              (click)="optOutFromConsent($event, consent)" class="section-header-button-text-card"
              [disabled]="consent.isOptOutDisabled">
              <div> {{ 'OPT_OUT' | translate }} </div>
            </ion-buttons>
          </ion-item>
          <ion-item lines="none" class="remove_inner_bottom ion-no-padding" (click)="handleActiveConsentExpandClick(consent.id, $event, 'mainLabel')">
            <ion-grid>
              <ion-row *ngFor="let childItem of consent.childItems">
                <ion-col size="4" *ngFor="let item of childItem">
                  <div class="display-grid" *ngIf="!item.isMultipleValue">
                    <ion-text class="child-item-label">{{ item.label }}</ion-text>
                    <ion-label class="child-item-text">{{ item.value }}</ion-label>
                  </div>
                  <div class="display-grid" *ngIf="item.isMultipleValue">
                    <ion-row>
                      <ion-col size="8">
                        <div class="display-grid">
                          <ion-text class="child-item-label">{{ item.label }}</ion-text>
                          <ion-label class="child-item-text">{{ item.value }}</ion-label>
                        </div>
                      </ion-col>
                      <ion-col size="4">
                        <div class="display-grid">
                          <ion-text class="child-item-label drop-down-label">{{ item.label }}</ion-text>
                          <ion-buttons (click)="expandItemValues(item, $event)">
                            <ion-label class="child-item-text drop-down-text">{{ item.dropDownText }}</ion-label>
                            <ion-icon class="arrow-icon" name='chevron-down-outline' slot="start"></ion-icon>
                          </ion-buttons>
                        </div>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </div>
</ion-item-group>
