import { Component, Input } from '@angular/core';
import { NavController, IonNav, ModalController } from '@ionic/angular';

interface GeneeInfo {
  skill: string;
  info: string;
  suggestions: [GeneeSkillSuggestion];
}

interface GeneeSkillSuggestion {
  name: string;
  value: string;
}

@Component({
  selector: 'genee-info',
  templateUrl: 'genee-info.html',
  styleUrls:['genee-info.scss']
})

export class GeneeInfoComponent {

  realGeneeInfo: GeneeInfo[];
  geneeInfo: GeneeInfo[];
  tappedButtonIndex = -1;
  showMore: boolean = false;

  searchInput: string = '';
  showMoreCardsAfter = 4;
  suggestionsCardsAtMin = 3; //Consider index starts at 0
  @Input() closeToolsFunctionRef: any;
  @Input() info: any;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: IonNav) {

  }

  ngOnInit() {
    this.realGeneeInfo = this.info;
    this.resetData();
  }

  /**
  * Action when expand or collapse the list.
  *
  * @memberOf GeneeInfoComponent
  */
  expandAction(buttonIndex: number) {
    if (this.tappedButtonIndex === buttonIndex) {
      // same button, list should collapse.
      this.tappedButtonIndex = -1;
    } else {
      // different button, list should expand.
      this.tappedButtonIndex = buttonIndex;
    }
    this.showMore = false;
  }

  /**
   * Returns a string for the icon name.
   *
   * @returns {string}
   *
   * @memberOf GeneeInfoComponent
   */
  getIconName(index: number): string {
    if (this.tappedButtonIndex === index) {
      return 'arrow-up';
    } else {
      return 'arrow-down';
    }
  }

  /**
  * Action when click on the Show More button.
  *
  * @memberOf GeneeInfoComponent
  */
  actionShowMore(event) {
    this.showMore = true;
  }

  /**
  * Action when user close the info component.
  *
  * @memberOf GeneeInfoComponent
  */
  actionGoBack() {
    this.modalCtrl.dismiss();
  }
  /**
  * Action when user dismiss the info component.
  *
  * @memberOf GeneeInfoComponent
  */
  actionDismissGeneeAssistant() {
    let navOptions = { progressAnimation: false, }
    this.navCtrl.pop(navOptions);
    this.closeToolsFunctionRef();
  }

  /**
  * Action when user make any changes in search bar text field.
  *
  * @memberOf GeneeInfoComponent
  */
  actionSearchInfo(event: any) {
    this.searchInput = event.value;

    if (this.searchInput && this.searchInput.length) {
      const query = this.searchInput.toLowerCase().trim();

      if (query.length > 0) {

        this.geneeInfo = this.realGeneeInfo.filter(result => {
          return (result.skill.toLowerCase().indexOf(query) > -1
            || result.info.toLowerCase().indexOf(query) > -1
            || result.suggestions.find(suggestion => {
              return suggestion.name.toLowerCase().indexOf(query) > -1 || suggestion.value.toLowerCase().indexOf(query) > -1;
            }));
        });

      } else {
        this.resetData();
      }
    } else {
      this.resetData();
    }

  }

  /**
  * Action when user clear the search bar text field.
  *
  * @memberOf GeneeInfoComponent
  */
  actionSearchClear(event: any) {
    this.resetData();
  }

  /**
  * Action when user cancel the search bar.
  *
  * @memberOf GeneeInfoComponent
  */
  actionSearchCancel(event: any) {
    this.resetData();
  }

  /**
  * Private helper when we have to reset the data.
  *
  * @memberOf GeneeInfoComponent
  */
  resetData() {
    this.geneeInfo = this.realGeneeInfo;
  }

  /**
  * Private helper when we have to clear the data.
  *
  * @memberOf GeneeInfoComponent
  */
  clearData() {
    this.geneeInfo = [];

  }

}
