<ion-header>
    <ind-page-title [viewData]='{id:"presentation-fullscreen",title: selectedPage?.name}'></ind-page-title>
</ion-header>
<ion-content>
    <ng-container ion-fixed>
        <io-iframe class="iframe-container" #iframe [selectedPageUrl]="selectedPageUrl" [viewMode]="viewMode"> </io-iframe>
        <io-carousel class="carousel-container" #carousel [pages]="presentationService.presPages" [briefcase]="presList" [showGalleryBtn]="false" [viewMode]="viewMode">
        </io-carousel>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar *ngIf="!deviceService.shouldBeMobileView" [footerView]="'fullScreenView'" [selectedView]="'presentations'" [attachedComponent]="pageName.PresentationFullscreenComponent"></footer-toolbar>
    <footer-toolbar *ngIf="deviceService.shouldBeMobileView" [footerView]="'detailView'" [selectedView]="'presentations'" [attachedComponent]="pageName.PresentationFullscreenComponent"></footer-toolbar>
<!-- </ion-footer> -->
