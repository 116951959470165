
import {takeUntil} from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { NavController, IonNav } from '@ionic/angular';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { DeviceService, DEVICE_ORIENTATION } from '../../../services/device/device.service';
import { FooterService } from '../../../services/footer/footer.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { Subject } from 'rxjs';
import { AppSettingsDetailsComponent } from '../appsettings-details/appsettings-details';
import { ActivityService } from '@omni/services/activity/activity.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';

/**
 *
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'appsettings-page[base-page]',
  templateUrl: 'appsettings-page.html',
  styleUrls:['appsettings-page.scss']
})
export class AppSettingsPageComponent implements  OnInit {

 @ViewChild('appsettingspagerightpane', {static:true}) public navCtrl: IonNav;

 public isPortrait: boolean = false;
 public destroy$: any = new Subject<boolean>();

  constructor(
    public navService: NavigationService,
    private device: DeviceService,
    private footerService: FooterService,
    public trackingService: TrackService,
    private activityService: ActivityService,
  ) {
    this.device.screenOrientation.pipe(
     takeUntil(this.destroy$))
     .subscribe((orientation: number) => {
       console.log(orientation);
       if (orientation === DEVICE_ORIENTATION["landscape-primary"] || orientation === DEVICE_ORIENTATION["landscape-secondary"]) {
         this.isPortrait = false;
       } else {
         this.isPortrait = true;
       }
     });
   this.isPortrait = this.device.isOrientationPortrait();
  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.AppsettingNavigation, PageName.AppSettingsPageComponent,false,{deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');
    // this.navService.setChildNavRoot(AppSettingsDetailsComponent, PageName.AppSettingsDetailsComponent, PageName.AppSettingsPageComponent)
  }

  ngOnDestroy(){
    this.navService.popChildNavCtrlFromStack(ChildNavNames.AppsettingNavigation);
    if(!this.activityService.selectedActivity) {
      this.navService.setChildNavRightPaneView(false);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent, {
        deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
      });
    }
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

}
