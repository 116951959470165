import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OffTakeCheckCollection } from '@omni/classes/offtake-check/offtake-check.class';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';

@Component({
  selector: 'app-offtake-check-product',
  templateUrl: './offtake-check-product.component.html',
  styleUrls: ['./offtake-check-product.component.scss'],
})
export class OfftakeCheckProductComponent {
  @Input() isEditable: boolean = false;
  @Input() offTakeCheckCollections: OffTakeCheckCollection[];
  options = [];
  @Output() onInputClick = new EventEmitter<any>();

  constructor(public translate: TranslateService) {
    this.options = [
      { label: this.translate.instant('YES'), value: true },
      { label: this.translate.instant('NO'), value: false }
    ]
  }

  handleInputEvent(event) {
    if (event?.target?.value) {
      if (Number(event.target.value) <= 0) {
        event.target.value = null;
      } else {
        this.onInputClick.emit(event);
      }
    } else {
      this.onInputClick.emit(event);
    }
  }

}
