import { SearchLookupPopoverService } from './../../../services/popover/search-lookup-popover.service';
import { Component, ViewChild, Input } from '@angular/core';
import { PopoverController, IonSearchbar } from '@ionic/angular';
import { DeviceService } from '../../../services/device/device.service';
import { SearchLookupListDetailModel } from '@omni/models/search-lookup-list-detail-model';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { PageName } from '@omni/services/navigation/navigation.service';

@Component({
  selector: 'search-lookup-popover',
  templateUrl: 'search-lookup-popover.html',
  styleUrls:['search-lookup-popover.scss']
})
export class SearchLookupPopoverComponent {

  @Input() viewData: SearchLookupListDetailModel;
  @Input() accessedFrom: PageName;
  @Input() targetEntity: string;

  @ViewChild(IonSearchbar, {static: false}) search : IonSearchbar;

  public isAccessedFromOppCollabo: boolean = false;

  constructor(
    public opportunityDataService: OpportunityManagementDataService,
    private device: DeviceService,
    private popoverCtrl: PopoverController,
    public searchLookupPopoverService: SearchLookupPopoverService
  ) {
  }

  ngOnInit() {
    if(this.accessedFrom == PageName.OpportunityCollaboratorsComponent) this.isAccessedFromOppCollabo = true;
  }

  ngOnDestroy() {
    this.isAccessedFromOppCollabo = false;
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.search){
        this.search.setFocus();
      }
    }, 500);
  }

  public searchText(ev): void {
    let searchParams: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (this.viewData) {
      try {
        if(!this.device.isOffline && this.accessedFrom == PageName.OpportunityCollaboratorsComponent){
          this.opportunityDataService.getLookupResults(searchParams);
        } else if (this.accessedFrom == PageName.SurveyComponent) {
          this.searchLookupPopoverService.getLookupResultsForCaseSurvey(searchParams,this.targetEntity)
        }
      }
      catch (err) {
        console.error(`Error occured while searching lookup results: ${err}`);
      }
    }
  }

  public clearValue() {
    let data = { clearValue: true };
    this.viewData.selectedValue = '';
    if (this.viewData && this.viewData.callbackEvent) {
      this.viewData.callbackEvent(data);
      this.popoverCtrl.dismiss();
    }

  }

  public setSelectedLookupValue(value) {
    let data = { selectedItem: value, isDone: true };
    if (this.viewData && this.viewData.callbackEvent) {
      this.viewData.callbackEvent(data);
      this.popoverCtrl.dismiss();
    }
  }

}
