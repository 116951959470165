import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IONote } from '@omni/classes/io/io-note.class';
import { QuotePriceList, PriceListProdcut, Quotes } from '@omni/classes/quotes/quotes.class';
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DiskService } from '@omni/services/disk/disk.service';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { differenceInHours, format, isBefore } from 'date-fns';
import { Endpoints } from 'src/config/endpoints.config';
import { Guid } from 'typescript-guid';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import * as _ from 'lodash';
import { Events } from '@omni/events';
@Injectable({
  providedIn: 'root'
})
export class QuoteToolDataService {

  constructor(
    private authService: AuthenticationService,
    public dynamics: DynamicsClientService,
    private quotesOfflineService: QuotesService,
    public disk: DiskService,
    public http: HttpClient,
  ) { }

  async getQuotesTools(fullSync?: boolean, loadFromDbOnly = false, quoteId = null, fromApprovalTab: boolean = false) {
    if (loadFromDbOnly) {
      await this.loadOfflineDataForQuotesTools();
      return;
    }

    const offlineDataStored = await this.loadOfflineDataForQuotesTools();
    const lastModifiedTime = offlineDataStored?.lastModified ?? null;
    if (!offlineDataStored) fullSync = true;
    let fetchXML = fetchQueries.quotes.fetchQuotes;
    fetchXML = this.formatXml(fullSync, fetchXML, quoteId, lastModifiedTime);
    let fetchedQuotes = await this.fetchQuotes(fetchXML);

    const notesXML = this.getNotes(fullSync, quoteId, lastModifiedTime, "", "");
    const productXML = this.getProducts(fullSync, quoteId, lastModifiedTime);

    const responses = await Promise.all([
      this.fetchQuotes(notesXML),
      this.fetchQuotes(productXML)
    ])

    fetchedQuotes = [...fetchedQuotes, ...responses[0], ...responses[1]];
    if (fromApprovalTab) {
      return await this.aggregateQuotes(fetchedQuotes, quoteId, fullSync, fromApprovalTab);
    } else {
      await this.aggregateQuotes(fetchedQuotes, quoteId, fullSync, fromApprovalTab);
    }

    if (!fromApprovalTab)
      this.saveQuotesInDB()
  }

  private getNotes(fullSync: boolean, quoteId: string, lastModifiedTime: any, filterCondition: string, positionString: string) {
    let fetchXML = fetchQueries.quotes.fetchNotes.replace("{filterCondition}", filterCondition.split('{positionIDs}').join(positionString));
    fetchXML = this.formatXml(fullSync, fetchXML, quoteId, lastModifiedTime);
    return fetchXML;
  }

  private getProducts(fullSync: boolean, quoteId: string, lastModifiedTime: any) {
    let fetchXML = fetchQueries.quotes.fetchQuoteProduct;
    fetchXML = this.formatXml(fullSync, fetchXML, quoteId, lastModifiedTime);
    return fetchXML;
  }

  async fetchQuotes(fetchXML) {
    return await this.dynamics.executeFetchQuery('quotes', fetchXML).then(async (response) => {
      if (response) return response;
      return [];
    }).catch((err) => {
      console.error('Failed to fetch quotes', err);
    })
  }

  private formatXml(fullSync: boolean, fetchXML: any, quoteId: string, lastModifiedTime: any) {
    if (fullSync) {
      fetchXML = fetchXML.replace('{deltaSyncCondition}', '').replace('{quoteIdCondition}', '').replace('{ownerIdCondition}', `<condition attribute="ownerid" operator="eq-userid" />`);
    } else if (quoteId) {
      const quoteIdCondition = `<condition attribute="quoteid" operator="eq" value="` + quoteId + `"/>`;
      fetchXML = fetchXML.replace('{quoteIdCondition}', quoteIdCondition).replace('{deltaSyncCondition}', '').replace('{ownerIdCondition}', '');
    } else {
      const lastModifiedForDeltaSync = lastModifiedTime ?? '';
      let deltaSyncCondition;
      if (lastModifiedForDeltaSync) {
        const modifiedon = format(lastModifiedForDeltaSync, "YYYY-MM-DD");
        deltaSyncCondition = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
        fetchXML = fetchXML.replace('{deltaSyncCondition}', deltaSyncCondition).replace('{quoteIdCondition}', '').replace('{ownerIdCondition}', `<condition attribute="ownerid" operator="eq-userid" />`);;
      }
    }
    return fetchXML;
  }

  async getPriceLists(fullSync?: boolean, loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      await this.loadOfflinePriceLists();
      return;
    }

    const offlineDataStored = await this.loadOfflinePriceLists();
    const lastModifiedTime = offlineDataStored?.lastModified ?? null;
    const positionIds = this.authService.user.positions.map(({ ID }) => ID);
    let positionString = '';
    positionIds.forEach(positionId => positionString += '<value>' + positionId + '</value>');
    const fetchXML = this.formatPriceListXml(fullSync, lastModifiedTime, positionString);
    const fetchedPriceLists = await this.fetchPriceLists(fetchXML);

    await this.aggregatePriceLists(fetchedPriceLists);
    this.savePriceListsInDB();
    console.log('price list fetched', fetchedPriceLists)
  }

  async getQuotesApprovalTabData(): Promise<Quotes[]> {
    let fetchXml = fetchQueries.quotes.fetchQuotesForApprovals;
    const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
    const startDate = format(new Date(parseInt(dataRangeWithFutureBoundBySixMonths.from)), 'YYYY-MM-DD'), endDate = format(new Date(parseInt(dataRangeWithFutureBoundBySixMonths.to)), 'YYYY-MM-DD');
    fetchXml = fetchXml.replace('{startDate}', startDate).replace("{endDate}", endDate);
    return await this.dynamics.executeFetchQuery('quotes', fetchXml).then(async (response) => {
      if (response) return response.map(resp => new Quotes(resp));
      return [];
    }).catch((err) => {
      console.error('Failed to fetch approvals tab data', err);
      return [];
    })
  }

  private formatPriceListXml(fullSync: boolean, lastModifiedTime: any, positionString: string) {
    let fetchXML = fetchQueries.quotes.fetchPriceListandProducts.split('{positionIDs}').join(positionString).replace('{todayValue}', format(new Date(), 'YYYY-MM-DD'));
    const now = new Date();
    if (fullSync) {
      fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
    } else {
      // ? need to check lastmodified
      let deltaSyncCondition = '';
      if (lastModifiedTime) {
        let hourDifference = differenceInHours(now, new Date(lastModifiedTime));
        hourDifference += 1;
        deltaSyncCondition = `<condition attribute="createdon" operator="last-x-hours" value="` + hourDifference + `"/>`;
        fetchXML = fetchXML.replace('{deltaSyncCondition}', deltaSyncCondition);
      } else {
        fetchXML = fetchXML.replace('{deltaSyncCondition}', '');
      }
    }
    return fetchXML;
  }

  async fetchPriceLists(fetchXML) {
    return await this.dynamics.executeFetchQuery('pricelevels', fetchXML).then(async (response) => {
      if (response) return response;
      return [];
    }).catch((err) => {
      console.error('Failed to fetch price list fetched', err);
    })
  }

  async updateQuote(quoteToUpdate, quoteId) {
    try {
      let payload = _.cloneDeep(quoteToUpdate);
      if (payload.effectivefrom) {
        payload.effectivefrom = format(quoteToUpdate.effectivefrom, 'YYYY-MM-DD');
        quoteToUpdate.effectivefrom = new Date(quoteToUpdate.effectivefrom);
      }

      if (payload.effectiveto) {
        payload.effectiveto = format(quoteToUpdate.effectiveto, 'YYYY-MM-DD');
        quoteToUpdate.effectiveto = new Date(quoteToUpdate.effectiveto);
      }

      const response = await this.saveQuote(payload, quoteId);
      if (!response) return;
      quoteToUpdate = { ...quoteToUpdate, totalAmount: response.totalLineItemAmount };
      if (quoteToUpdate.products) {
        await this.quotesOfflineService.clearProductsAndUpdateLocal(quoteToUpdate, quoteId)
      } else {
        await this.quotesOfflineService.updateQuoteLocal(quoteToUpdate, quoteId);
      }
      this.saveSingleQuoteInDB(this.quotesOfflineService.selectedQuotes$.getValue());
    } catch (error) {
      return;
    }
  }

  async saveQuote(quote, quoteId?: string) {
    if (!quoteId) quoteId = Guid.create().toString();
    try {
      const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.quotes.SAVE_QUOTES.replace('{quoteId}', quoteId);
      const response = await this.http.put<any>(url, quote, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
      return response;
    } catch (error) {
      return;
    }
  }

  async createQuote(quote) {
    const response: any = await this.saveQuote(quote);
    if (response) {
      quote.quoteid = response.quoteId;
      quote.totalLineItemAmount = response.totalLineItemAmount;
      quote.quoteNumber = response.quoteNumber;
      quote.ownerId = this.authService.user.systemUserID
    }
    quote = new Quotes(quote);
    this.quotesOfflineService.selectedQuotes$.next(quote);
    this.saveSingleQuoteInDB(quote);
    return quote;
  }

  async addProductsToQuote(productsPayload, quoteId) {
    try {
      const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.quotes.SAVE_QUOTES_PRODUCTS.replace('{quoteId}', quoteId);
      const response = await this.http.put<any>(url, productsPayload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
      let product: any = { product: { ...productsPayload, quoteDetailId: response.quoteDetailId }, totalAmount: response.totalLineItemAmount }
      await this.quotesOfflineService.updateProduct(product, quoteId);
      this.saveSingleQuoteInDB(this.quotesOfflineService.selectedQuotes$.getValue());
      return response;
    } catch (error) {
      return;
    }
  }

  public async saveAttachments(payload, quoteId): Promise<void> {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.quotes.SAVE_QUOTES_ATTACHMENTS.replace('{quoteId}', quoteId);
    const response = await this.http.put<any>(url, payload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    return response[0];
  }

  // db/offline services
  async saveSingleQuoteInDB(quote) {
    const existingQuotes = this.quotesOfflineService.quotesList$.getValue();
    const index = existingQuotes.findIndex(({ ID }) => ID === quote.ID);
    if (index >= 0) {
      existingQuotes[index] = quote;
    } else {
      existingQuotes.push(quote);
    }

    this.quotesOfflineService.quotesList$.next(await this.sortQuotes(existingQuotes));
    this.saveQuotesInDB();
    return;
  }

  async sortQuotes(unsortedList) {
    let sorted = _.orderBy(unsortedList, (data) => {
      return new Date(data.createdon)
    }, ['desc']);
    return sorted;
  }

  async saveQuotesInDB() {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.QUOTES, (doc) => {
        doc = {
          raw: [],
          // lastModified: doc.lastModified
        };
        doc.raw = this.quotesOfflineService.quotesList$.getValue();
        doc.lastModified = doc.lastModified || new Date().getTime();
        return doc;
      });
    } catch (error) {
      console.log('Error in saving Quotes')
    }

  }

  async savePriceListsInDB() {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.QUOTE_PRICE_LISTS, (doc) => {
        doc = {
          raw: [],
          // lastModified: doc.lastModified
        };
        doc.raw = this.quotesOfflineService.priceLists;
        doc.lastModified = doc.lastModified || new Date().getTime();
        return doc;
      });
    } catch (error) {
      console.log('Error in saving Quote Price Lists')
    }
  }

  async loadOfflineDataForQuotesTools() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.QUOTES, true).then((doc) => {
        if (doc?.raw) {
          this.quotesOfflineService.quotesList$.next(doc.raw);
        } else {
          this.quotesOfflineService.quotesList$.next([]);
        }
        offlineDataStored = doc;
      });
    }
    catch (er) {
      console.error("Failed to fetch quotes from db!: ", er)
      this.quotesOfflineService.quotesList$.next([]);
    }
    return offlineDataStored;
  }

  async loadOfflinePriceLists() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.QUOTE_PRICE_LISTS, true).then((doc) => {
        if (doc?.raw) {
          this.quotesOfflineService.priceLists = doc.raw;
        } else {
          this.quotesOfflineService.priceLists = [];
        }
        offlineDataStored = doc;
      });
    }
    catch (er) {
      console.error("Failed to fetch quotes from db!: ", er)
      this.quotesOfflineService.priceLists = [];
    }
    return offlineDataStored;
  }

  private async aggregateQuotes(fetchedQuotes: any, quoteId, fullSync?: boolean, fromApprovalTab: boolean = false) {
    try {
      let quotes: Quotes[] = [];
      fetchedQuotes.forEach(fetchedQuote => {
        let newQuotes: Quotes;
        newQuotes = quotes.find(quote => quote.ID === fetchedQuote.quoteid);

        if (!newQuotes) {
          newQuotes = new Quotes(fetchedQuote);
          quotes.push(newQuotes);
        }

        if (fetchedQuote.hasOwnProperty('ac.productid')) {
          let product = newQuotes.products.find(({ id }) => id === fetchedQuote['ac.productid']);
          if (!product) {
            product = {
              id: fetchedQuote['ac.productid'],
              name: fetchedQuote['ac.productname'],
              quantity: fetchedQuote['ac.quantity'],
              quoteDetailId: fetchedQuote['ac.quotedetailid']
            }
            newQuotes.products.push(product);
            newQuotes.products = _.orderBy(newQuotes.products, 'productName')
          }
        }

        if (fetchedQuote.hasOwnProperty('ae.annotationid')) {
          const id = fetchedQuote['ae.annotationid']
          let note = newQuotes.quoteNotes.find(o => o.noteId == id)
          if (!note) {
            newQuotes.quoteNotes.push(new IONote({
              annotationid: id,
              activityid: '',
              contactid: '',
              accountid: '',
              createdon: new Date(fetchedQuote['ae.createdon']).getTime().toString(),
              notetext: fetchedQuote['ae.notetext'],
              ownerName: fetchedQuote['ae.ownerid@OData.Community.Display.V1.FormattedValue'],
              ownerid: fetchedQuote['ae.ownerid'],
              isdocument: fetchedQuote['ae.isdocument'],
              documentbody: '',
              filename: fetchedQuote['ae.filename'],
              filesize: fetchedQuote['ae.filesize'],
              mimetype: fetchedQuote['ae.mimetype'],
              isDeleted: false,
              pendingPushForDynamics: false,
              updated: false
            }));
            newQuotes.quoteNotes.sort((a, b) => {
              return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
            })
          }
        }
      });

      if (fullSync) {
        const now = new Date();
        quotes = quotes.map((quote) => {
          quote.lastUpdated = now.getTime();
          return quote
        });
        if (!fromApprovalTab) {
          const sortedList = await this.sortQuotes(quotes);
          this.quotesOfflineService.quotesList$.next(sortedList);
        }
      } else {
        if (fromApprovalTab) {
          return quotes[0];// Not required to update incase fromApprovalTab: Online only tab
        } else {
          let listedQuotes = this.quotesOfflineService.quotesList$.getValue();
          quotes.forEach((quote) => {
            const index = listedQuotes.findIndex(({ ID }) => ID === quote.ID);
            if (index > -1) {
              quote.lastUpdated = new Date().getTime();
              listedQuotes[index] = quote;
            } else {
              listedQuotes.push(quote);
            }
          });

          if (!quoteId) {
            const sortedList = await this.sortQuotes(listedQuotes);
            this.quotesOfflineService.quotesList$.next(sortedList);
          }
        }
      }
    } catch (error) {
      console.log('aggregate', error)
    }

  }

  private async aggregatePriceLists(fetchedPriceLists, fullSync?: boolean) {
    let priceLists: QuotePriceList[] = [];
    try {
      fetchedPriceLists.forEach(fetchedPriceList => {
        let newPriceList: QuotePriceList;
        newPriceList = priceLists.find(priceList => priceList.id === fetchedPriceList.pricelevelid);
        if (!newPriceList) {
          newPriceList = new QuotePriceList(fetchedPriceList);
          priceLists.push(newPriceList);
        }

        if (fetchedPriceList['products.productid']) {
          const productExists = newPriceList.products.some((product) => product.id === fetchedPriceList['products.productid']);
          if (!productExists) {
            const product: PriceListProdcut = {
              id: fetchedPriceList['products.productid'],
              name: fetchedPriceList['productname.name'],
              amount: fetchedPriceList['products.amount'],
              uomId: fetchedPriceList['products.uomid'],
              amountFormatted: fetchedPriceList['products.amount@OData.Community.Display.V1.FormattedValue']
            }
            newPriceList.products.push(product);
          }
        }
      });

      if (fullSync) {
        // this.quotesOfflineService.priceLists$.next(priceLists);
        this.quotesOfflineService.priceLists = priceLists;
      } else {
        let listedPriceLists = this.quotesOfflineService.priceLists;
        priceLists.forEach((priceList) => {
          const index = listedPriceLists.findIndex(({ id }) => id === priceList.id);
          if (index > -1) {
            listedPriceLists[index] = priceList;
          } else {
            listedPriceLists.push(priceList);
          }
        });
        // this.quotesOfflineService.priceLists$.next(listedPriceLists);
        this.quotesOfflineService.priceLists = listedPriceLists;
      }

    } catch (error) {
      console.log('aggregate price list fetched', error)
    }
  }

  async updateApprovalActivity(payload, approvalActivityId: string) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.quotes.UPDATE_APPROVAL_ACTIVITY.replace('{approvalActivityId}', approvalActivityId);
    return await this.http.patch<any>(url, payload).toPromise();
  }

  async getLatestQuoteStatus(quoteId: string) {
    return await this.dynamics.retrieveAll('quotes', ['statuscode'], `quoteid eq '${quoteId}'`)
      .then(res => res.value[0].statuscode).catch(() => null);
  }
}
