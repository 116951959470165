import { Injectable } from "@angular/core";
import { StoreCheckDataService } from "@omni/data-services/store-check/store-check.data.service";
import { ActivityService } from "../activity/activity.service";
import { StoreCheckActivity } from "@omni/classes/activity/store-check.activity.class";
import { DiskService } from "../disk/disk.service";
import _ from 'lodash';
import { ProductCategory } from '@omni/classes/store-check/product-category';
import { Product } from '@omni/classes/store-check/product';
import { ActivityTypeCode } from "@omni/classes/activity/activity.class";
import { AuthenticationService } from "../authentication.service";
import { Photo, PhotoResponse } from '@omni/classes/store-check/photo';
import { BehaviorSubject } from "rxjs";
import { UploadedPhoto } from '@omni/classes/store-check/uploaded-photo';
import { PhotoAttachment, PhotoAttachmentDto } from '@omni/classes/store-check/photo-attachment';
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";

@Injectable({
  providedIn: 'root'
})
export class StoreCheckService {

  public productCategories: ProductCategory[] = [];
  private selectedStoreCheck$ = new BehaviorSubject<StoreCheckActivity>(undefined);
  selectedStoreCheckActivity = this.selectedStoreCheck$.asObservable();

  constructor(private storeCheckDataService: StoreCheckDataService,
    private activityService: ActivityService,
    private disk: DiskService,
    private authService: AuthenticationService) {

  }

  public setCurrentStoreCheckActivity(input) {
    this.selectedStoreCheck$.next(input);
  }

  public async createStoreCheck(payload) {
    try {
      const response = await this.storeCheckDataService.createStoreCheck(payload);
      if (response && response['activityId']) {
        payload['activityId'] = response['activityId'];
        payload['indskr_type'] = ActivityTypeCode.STORE_CHECK;
        payload['indskr_ownerid'] = this.authService.user.xSystemUserID;
        payload['activitytypecode'] = 'appointment';
        const activity = new StoreCheckActivity(payload);
        if (payload.hasOwnProperty['accounts']) {
          activity['accounts'] = payload['accounts'];
        }
        this.activityService.addActivity(activity);
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity);
        return activity;
      }
    } catch (error) {
      console.error("Failed to create store check", error);
    }
    return null;
  }

  public async scrapStoreCheck(activity) {
    const payload = {
      "statuscode": 4
    }
    try {
      await this.storeCheckDataService.updateStoreCheck(activity.ID, payload);
      await this.disk.remove(DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY + activity.ID);
      this.activityService.removeActivity(activity, true);
    } catch (error) {
      console.error("Failed to scrap store check", error);
    }
  }

  public async completeStoreCheck() {
    const payload = {
      "statuscode": 3
    }
    try {
      await this.storeCheckDataService.updateStoreCheck(this.activityService.selectedActivity.ID, payload);
      this.activityService.selectedActivity.state = 1;
      this.activityService.addActivity(this.activityService.selectedActivity, true);
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as StoreCheckActivity);
    } catch (error) {
      console.error("Failed to complete store check", error);
    }
  }

  public async getProductFamilyCategories(loadFromDBOnly) {
    if (loadFromDBOnly) {
      // This fetch request was breaking offline launch scenario
      // Applying temporary fix to unblock
      // This will need to be handled properly by the feature owner
      return;
    }
    const data = await this.storeCheckDataService.getProductFamilyCategories();
    const groupedData = _.groupBy(data, 'indskr_productfamilycategoryid');
    const categories: ProductCategory[] = _.map(groupedData, (groupedItems) => {
      const firstItem = _.head(groupedItems);
      const { indskr_productfamilycategoryid, indskr_name, indskr_default } = firstItem;
      const products: Product[] = _.map(groupedItems, (item) => {
        const { productId, productName } = item;
        return { productId, productName, deleted: false };
      });
      return { indskr_productfamilycategoryid, indskr_name, indskr_default, products };
    });
    this.productCategories = _.isEmpty(categories) ? [] : categories;
    console.log(this.productCategories);

  }

  public async updateStoreCheck(payload) {
    try {
      const activity = this.activityService.selectedActivity as StoreCheckActivity;
      await this.storeCheckDataService.updateStoreCheck(activity.ID, payload);
      if (payload.hasOwnProperty('scheduledend')) {
        activity.scheduledEnd = new Date(payload['scheduledend']);
      }
      if (payload.hasOwnProperty('scheduledstart')) {
        activity.scheduledStart = new Date(payload['scheduledstart']);
      }
      if (payload.hasOwnProperty('subject')) {
        activity.subject = payload['subject'];
      }
      if (payload.hasOwnProperty('accounts')) {
        activity.accounts = payload['accounts'];
      }
      if (payload.hasOwnProperty('categoryId')) {
        activity.categoryId = payload['categoryId'];
      }
      if (payload.hasOwnProperty('categoryName')) {
        activity.categoryName = payload['categoryName'];
      }
      if (payload.hasOwnProperty('promoChecks')) {
        activity.photoAttachments = payload['promoChecks'];
      }
      this.activityService.addActivity(activity, true);
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity);
      return activity;
    } catch (error) {
      console.error("Failed to update store check", error);
    }
    return null;
  }

  public async deletePhotoAttachment(photoAttachement: PhotoAttachmentDto) {
    try {
      const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
      const products = photoAttachement.products.map(prod => ({ productId: prod.productId, deleted: true }));
      const payload = photoAttachement.photos.map(photo => ({ photoAttachmentId: photo.indskr_photoattachmentid, deleted: true, products: products }));
      await this.storeCheckDataService.updatePromoCheck(storeCheckActivity.ID, payload);
      storeCheckActivity.photoAttachments = storeCheckActivity.photoAttachments.filter(pa => pa.overriddenCreatedOn != photoAttachement.overriddenCreatedOn);
      this.activityService.addActivity(storeCheckActivity, true);
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(storeCheckActivity);
    } catch (error) {
      console.error("Failed to updatePromoCheck", error);
    }
  }

  async checkProductCategoryMismatch() {
    const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
    try {
      const data = await this.storeCheckDataService.getProductFamilyCategoryByStoreCheckID(storeCheckActivity.ID);
      if (data.length > 0 && data[0].productfamilycategory !== storeCheckActivity.categoryId) {
        console.log(`There is a mismatch in category. App category ${storeCheckActivity.categoryId} : Dynamics category ${data[0].productfamilycategory}`);
        const payload = {
          categoryId: storeCheckActivity.categoryId,
          categoryName: storeCheckActivity.categoryName,
          subject: storeCheckActivity.subject
        };
        await this.updateStoreCheck(payload);
      }
    } catch (error) {
      console.log(`error occurred `, error);
    }
  }

  public async updatePhotoAttachmentProducts(payload, selectedProducts: Product[], photoAttachment: PhotoAttachmentDto) {
    try {
      const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
      await this.storeCheckDataService.updatePromoCheck(storeCheckActivity.ID, payload);
      if (selectedProducts.length == 0) {
        storeCheckActivity.photoAttachments = storeCheckActivity.photoAttachments.filter(pa => pa.overriddenCreatedOn != photoAttachment.overriddenCreatedOn);
      } else {
        let prevAttachment: PhotoAttachment = storeCheckActivity.photoAttachments.find(pa => pa.overriddenCreatedOn == photoAttachment.overriddenCreatedOn);
        if (prevAttachment) {
          prevAttachment.products = selectedProducts;
        }
      }
      this.activityService.addActivity(storeCheckActivity, true);
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(storeCheckActivity);
    } catch (error) {
      console.error("Failed to updatePhotoAttachmentProducts", error);
    }
  }

  public async removePhotoAttachments(payload, removedPhotos: PhotoResponse[], photoAttachment: PhotoAttachmentDto) {
    try {
      const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
      await this.storeCheckDataService.updatePromoCheck(storeCheckActivity.ID, payload);
      if (removedPhotos.length == photoAttachment.photos.length) {
        storeCheckActivity.photoAttachments = storeCheckActivity.photoAttachments.filter(pa => pa.overriddenCreatedOn != photoAttachment.overriddenCreatedOn)
      } else {
        const removedAttachmentIds = removedPhotos.map((item) => item.indskr_photoattachmentid);
        let prevAttachment: PhotoAttachment = storeCheckActivity.photoAttachments.find(pa => pa.overriddenCreatedOn == photoAttachment.overriddenCreatedOn);
        if (prevAttachment) {
          prevAttachment.photos = prevAttachment.photos.filter(ph => !_.includes(removedAttachmentIds, ph.indskr_photoattachmentid));
        }
      }
      this.activityService.addActivity(storeCheckActivity, true);
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(storeCheckActivity);
    } catch (error) {
      console.error("Failed to removePhotoAttachments", error);
    }
  }

  public async uploadPhotosToBlobStorgae(payload: Photo[], promoCheckPayload) {
    const storeCheckActivity = this.activityService.selectedActivity as StoreCheckActivity;
    const createdAt = new Date().getTime();
    payload = payload.map((image) => {
      const base64String = image.base64String.replace(/^data:image\/\w+;base64,/, '');
      return { ...image, base64String, createdAt: createdAt };
    });
    try {
      const photosUploaded: UploadedPhoto[] = await this.storeCheckDataService.uploadPhotosToBlobStorage(payload);
      if (!_.isEmpty(photosUploaded) && Array.isArray(promoCheckPayload) && !_.isEmpty(promoCheckPayload) ) {
        if (_.isEmpty(storeCheckActivity.photoAttachments) || !promoCheckPayload.some(p => p['deleted'])) {
          promoCheckPayload.forEach(payload => {
            const photoAttachment = photosUploaded.find(resp => resp['name'] == payload['name']);
            if (photoAttachment?.photoId) {
              payload['photoAttachmentId'] = photoAttachment.photoId;
            }
          })
          await this.storeCheckDataService.updatePromoCheck(storeCheckActivity.ID, promoCheckPayload);
          const photos: PhotoResponse[] = photosUploaded.map(p => {
            return { indskr_photoattachmentid: p.photoId, indskr_photoorigin: p.photoOrigin, indskr_photourl: p.photoUrl, name: p.name }
          });
          const photoAttachment: PhotoAttachment = {
            overriddenCreatedOn: createdAt,
            photos: photos,
            products: promoCheckPayload[0].products
          }
          storeCheckActivity.photoAttachments.push(photoAttachment);
        } else {
          const photoAttachmentsPayload = [];
          promoCheckPayload.forEach(promoCheck => {
            //TODO: handle delete photo
            if (!promoCheck['deleted']) {
              const photoAttachment = photosUploaded.find(resp => resp['name'] == promoCheck['name']);
              if (photoAttachment?.photoId) {
                const photoAttSaved: any = storeCheckActivity.photoAttachments.find(photoAtt => photoAtt['name'] == promoCheck['name']);
                if (photoAttSaved) {
                  //Photo is already available, check if new products mapped
                  if (!_.isEmpty(photoAttSaved['activityPhotos']) && !_.isEmpty(promoCheck['activityPhotos'])) {
                    const productIdsInPayload = promoCheck['activityPhotos'].filter(prod => !prod['deleted']).map(prod => prod.productId);
                    const productIdsSaved = photoAttSaved['activityPhotos'].map(prod => prod.productId);
                    const productIdsToBeSaved = _.difference(productIdsInPayload, productIdsSaved);
                    if (!_.isEmpty(productIdsToBeSaved)) {
                      //Payload: only new products
                      const payload = {
                        photoAttachmentId: photoAttachment.photoId,
                        products: productIdsToBeSaved.map(prod => { return { productId: prod } })
                      }
                      photoAttachmentsPayload.push(payload);
                    }
                  }
                } else {
                  //Payload: new photo for selected products
                  const payload = {
                    photoAttachmentId: photoAttachment.photoId,
                    products: promoCheck['activityPhotos']
                  }
                  photoAttachmentsPayload.push(payload);
                }
              }
            }
          });
          if (!_.isEmpty(photoAttachmentsPayload)) {
            await this.storeCheckDataService.updatePromoCheck(storeCheckActivity.ID, photoAttachmentsPayload);
            //TODO: map to selectedActivity
          }
        }
      }
    } catch (error) {
      console.error("Failed to upload photos: ", error);
    }
  }
}