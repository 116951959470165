import { EntitySyncAction } from './../../types/action/action.type';
import { DB_ALLDOCS_QUERY_OPTIONS } from '@omni/config/pouch-db.config';
import { IShipmentReason } from './../../interfaces/allocation/allocation.shared.interface';
import { IAllocationShipmentAndTransferBase } from './../../interfaces/allocation/allocation-shipment.interface';
import { IAllocationTransfer } from './../../interfaces/allocation/allocation-transfer.interface';
import { AllocationAdjustService } from './../../services/sample/allocation-adjust.service';
import { IAllocationAdjustment } from './../../interfaces/allocation/allocation-adjustment.interface';
import { ADJUSTMENT_INITIAL_FETCH_TEMPLATE, ADJUSTMENT_ENTITY_NAME, TEAM_ADJUSTMENT_INITIAL_FETCH_TEMPLATE, ADJUSTMENT_DELTA_FETCH_TEMPLATE, TEAM_ADJUSTMENT_DELTA_FETCH_TEMPLATE } from '../../config/fetch-xml/allocation/adjustment-fetchXMLs';
import { AllocationFeatureService } from './../../services/sample/allocation.feature.service';
import { TRACK_CHANGE_ENTITY_NAME } from './../../config/fetch-xml/shared-fetchXML-entity-names';
import { SHIPMENT_ENTITY_NAME, SHIPMENT_INITIAL_FETCH_TEMPLATE, SHIPMENT_DELTA_FETCH_TEMPLATE, SHIPPED_BY_USER_FILTER, USER_SHIPMENT_TRASFERRED_FILTER, SHIPMENT_DELTA_DELETED_FETCH_TEMPLATE } from '../../config/fetch-xml/allocation/shipment-fetchXMLs';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { Injectable } from '@angular/core';
import { Endpoints } from '../../../config/endpoints.config';
import { AuthenticationService } from '../../services/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AllocationShipmentService } from '../../services/sample/allocation-shipment.service';
import { DiskService } from '../../services/disk/disk.service';
import { DeviceService } from '../../services/device/device.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { Observable } from 'rxjs';
import { IAllocationShipment } from '../../interfaces/allocation/allocation-shipment.interface';
import { ALLOCATION_SHIPMENT_DATA_RESTRUCTURE_REV, ALLOCATION_TOOL_REV, ALLOCATION_TOOL_REV_KEY } from '../../config/allocation/allocation.config';
import { convertTimestampStringToISODateFormat } from '../../utility/common.utility';
import { IEntitySyncDataRange, IEntitySyncState } from '../../interfaces/shared/shared.interface';


@Injectable({
  providedIn: 'root'
})
export class AllocationShipmentDataService {

    constructor(
        private authenticationOfflineService: AuthenticationService,
        private http: HttpClient,
        private allocationShipmentOffline: AllocationShipmentService,
        private disk: DiskService,
        private device: DeviceService,
        private deltaService: DeltaService,
        private allocAdjustService: AllocationAdjustService,
        private dynamics: DynamicsClientService,
        private allocationFeatureService: AllocationFeatureService,
    ){

    }

    // Allocation shipment/adjustment restructure involved db restructure as well
    // This function checks revision number to clear the data from old structure
    private async _checkRevisionAndClear(): Promise<boolean> {
      const allocToolRev: number = this.disk.getFeatureRevision(ALLOCATION_TOOL_REV_KEY);
      let isCleared: boolean = false;

      if (allocToolRev === null || allocToolRev < ALLOCATION_SHIPMENT_DATA_RESTRUCTURE_REV) {
        // Clear old shipments data (from previous architecture) from DB
        isCleared = await this._clearOldDbData();
        if (isCleared) {
          await this.disk.setFeatureRevision(ALLOCATION_TOOL_REV_KEY, ALLOCATION_TOOL_REV, true);
        }
      }

      return isCleared;
    }
    private async _clearOldDbData(): Promise<boolean> {
      try {
        await Promise.all([
          this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ALLOCATION_RELATED_DATA),
          this.disk.remove(DB_KEY_PREFIXES.TEAM_ADJUSTMENTS),
          this.disk.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOC_SHIPMENT),
          this.disk.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOC_ADJUSTMENT),
          this.disk.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOC_TEAM_ADJUSTMENT),
        ]);
        return true;
      } catch (error) {
        console.error('AllocationShipmentDataService: _clearOldDbData: ', error);
      }
      return false;
    }

    async sync(dataRange: IEntitySyncDataRange, loadFromDbOnly = false) {
      if (loadFromDbOnly) {
        // Init pending data
        await this.allocationFeatureService.loadPendingShipmentAndTransfer();
      } else if (!this.device.isOffline) {
        await this._checkRevisionAndClear();

        const receiptsEnabled: boolean = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB);
        const adjustmentEnabled: boolean = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT);
        const teamAdjustmentEnabled: boolean = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST);
        const { shipmentsSyncState, adjustmentSyncState, teamAdjustmentSyncState }
          = await this._getSyncStates(receiptsEnabled, adjustmentEnabled, teamAdjustmentEnabled);

        // Prep EntitySyncAction variables to determine which action to perform for each sub-type
        const shipmentSyncAction: EntitySyncAction =
          receiptsEnabled
          ? shipmentsSyncState?.lastUpdatedTime !== null
            ? 'Delta' : 'Initial'
          : 'NA';
        const shipmentSyncInfo: EntitySyncInfo = receiptsEnabled ? {
          entityName: EntityNames.shipment,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        } : undefined;

        const adjustmentSyncAction: EntitySyncAction =
          adjustmentEnabled
          ? adjustmentSyncState?.lastUpdatedTime !== null
            ? 'Delta' : 'Initial'
          : 'NA';
        const adjustmentSyncInfo: EntitySyncInfo = adjustmentEnabled ? {
          entityName: EntityNames.adjustment,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        } : undefined;

        const teamAdjustmentSyncAction: EntitySyncAction =
          teamAdjustmentEnabled
          ? teamAdjustmentSyncState?.lastUpdatedTime !== null
            ? 'Delta' : 'Initial'
          : 'NA';
        const teamAdjustmentSyncInfo: EntitySyncInfo = teamAdjustmentEnabled ? {
            entityName: EntityNames.teamAdjustment,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
          } : undefined;


        // Update state variable
        if (shipmentSyncAction !== 'NA') {
          this.allocationFeatureService.shipmentFetchState.setState('Sync');
        }
        if (adjustmentSyncAction !== 'NA') {
          this.allocationFeatureService.adjustmentFetchState.setState('Sync');
        }
        if (teamAdjustmentSyncAction !== 'NA') {
          this.allocationFeatureService.teamAdjustmentFetchState.setState('Sync');
        }


        // Prep delta date for deleted records
        // Since shipments, transfers, and adjustments are sub-types of a same entity,
        // deleted delta records will be fetch only once
        // with the oldest lastUpdatedTime value
        // and then each sub-type will check against it
        let lastUpdatedTimes: number[] = [];
        if (shipmentSyncAction === 'Delta') {
          lastUpdatedTimes.push(shipmentsSyncState.lastUpdatedTime);
        }
        if (adjustmentSyncAction === 'Delta') {
          lastUpdatedTimes.push(adjustmentSyncState.lastUpdatedTime);
        }
        if (teamAdjustmentSyncAction === 'Delta') {
          lastUpdatedTimes.push(teamAdjustmentSyncState.lastUpdatedTime);
        }
        lastUpdatedTimes = lastUpdatedTimes.sort((a, b) => a - b);
        const oldestLastUpdatedTime: number = lastUpdatedTimes.length > 0 ? lastUpdatedTimes[0] : null;
        const newLastUpdatedTime: number = new Date().getTime();

        try {
          // Since each sub-type record has individual FA,
          // trigger both initial & delta with EntitySyncAction variable to
          // run appropriate actions
          await Promise.all([
            // Initial
            this._initialSync(
              dataRange, newLastUpdatedTime,
              shipmentSyncAction, shipmentSyncInfo, shipmentsSyncState,
              adjustmentSyncAction, adjustmentSyncInfo, adjustmentSyncState,
              teamAdjustmentSyncAction, teamAdjustmentSyncInfo, teamAdjustmentSyncState
            ),
            // Delta
            oldestLastUpdatedTime !== null
              ? this._deltaSync(
                dataRange, newLastUpdatedTime, oldestLastUpdatedTime,
                shipmentSyncAction, shipmentSyncInfo, shipmentsSyncState,
                adjustmentSyncAction, adjustmentSyncInfo, adjustmentSyncState,
                teamAdjustmentSyncAction, teamAdjustmentSyncInfo, teamAdjustmentSyncState
              )
              : Promise.resolve(),
          ]);
        } catch (error) {
          console.error('AllocationShipmentDataService: sync: ', error);
        }


        // Update SyncInfo
        // FYI, it won't do anything if the sync info object is empty
        this.deltaService.addEntitySyncInfo(shipmentSyncInfo);
        this.deltaService.addEntitySyncInfo(adjustmentSyncInfo);
        this.deltaService.addEntitySyncInfo(teamAdjustmentSyncInfo);
      }
    }
    private async _initialSync(
      dataRange: IEntitySyncDataRange,
      newLastUpdatedTime: number,
      shipmentSyncAction: EntitySyncAction,
      shipmentSyncInfo: EntitySyncInfo,
      shipmentsSyncState: IEntitySyncState,
      adjustmentSyncAction: EntitySyncAction,
      adjustmentSyncInfo: EntitySyncInfo,
      adjustmentSyncState: IEntitySyncState,
      teamAdjustmentSyncAction: EntitySyncAction,
      teamAdjustmentSyncInfo: EntitySyncInfo,
      teamAdjustmentSyncState: IEntitySyncState,
    ) {
      // Initial
      // Each has different feature actions to enable/disable, hence
      // always check and run initial sync if needed
      try {
        const [shipmentsData, adjustmentData, teamAdjustmentData] = await Promise.all([
          shipmentSyncAction === 'Initial'
            ? this._initialSyncShipmentsTransfersData(dataRange, shipmentSyncInfo) : Promise.resolve(null),
          adjustmentSyncAction === 'Initial'
            ? this._initialSyncAdjustmentsData(dataRange, adjustmentSyncInfo) : Promise.resolve(null),
          teamAdjustmentSyncAction === 'Initial'
            ? this._initialSyncTeamAdjustmentsData(dataRange, teamAdjustmentSyncInfo) : Promise.resolve(null),
        ]);

        // Store data. Doesn't need to be synchronous.
        Promise.all([
          shipmentSyncAction === 'Initial'
          ? this.allocationShipmentOffline.saveInitialSyncedShipmentsTransfersData(shipmentsData) : Promise.resolve(null),
          adjustmentSyncAction === 'Initial'
          ? this.allocAdjustService.saveInitialSyncedAdjustmentsData(adjustmentData) : Promise.resolve(null),
          teamAdjustmentSyncAction === 'Initial'
          ? this.allocAdjustService.saveInitialSyncedAdjustmentsData(teamAdjustmentData, true) : Promise.resolve(null),
        ])
        .then(([shipmentSyncSuccess, adjustmentSyncSuccess, teamAdjustmentSyncSuccess]) => {
          if (shipmentSyncSuccess !== null) {
            if (shipmentSyncSuccess) {
              shipmentsSyncState.lastUpdatedTime = newLastUpdatedTime;
              this.disk.updateSyncState(shipmentsSyncState);
            }
            this.allocationFeatureService.shipmentFetchState.setState(shipmentSyncSuccess ? 'Success' : 'Fail');
          }
          if (adjustmentSyncSuccess !== null) {
            if (adjustmentSyncSuccess) {
              adjustmentSyncState.lastUpdatedTime = newLastUpdatedTime;
              this.disk.updateSyncState(adjustmentSyncState);
            }
            this.allocationFeatureService.adjustmentFetchState.setState(adjustmentSyncSuccess ? 'Success' : 'Fail');
          }
          if (teamAdjustmentSyncSuccess !== null) {
            if (teamAdjustmentSyncSuccess) {
              teamAdjustmentSyncState.lastUpdatedTime = newLastUpdatedTime;
              this.disk.updateSyncState(teamAdjustmentSyncState);
            }
            this.allocationFeatureService.teamAdjustmentFetchState.setState(teamAdjustmentSyncSuccess ? 'Success' : 'Fail');
          }
        });

        // Init pending data for plan tab
        this.allocationFeatureService.loadPendingShipmentAndTransfer(shipmentsData);
      } catch (error) {
        console.error('AllocationShipmentDataService: Initial Sync: ', error);
      }
    }
    private async _deltaSync(
      // Delta
      dataRange: IEntitySyncDataRange,
      newLastUpdatedTime: number,
      oldestLastUpdatedTime: number,
      shipmentSyncAction: EntitySyncAction,
      shipmentSyncInfo: EntitySyncInfo,
      shipmentsSyncState: IEntitySyncState,
      adjustmentSyncAction: EntitySyncAction,
      adjustmentSyncInfo: EntitySyncInfo,
      adjustmentSyncState: IEntitySyncState,
      teamAdjustmentSyncAction: EntitySyncAction,
      teamAdjustmentSyncInfo: EntitySyncInfo,
      teamAdjustmentSyncState: IEntitySyncState,
    ) {
      try {
        const deltaResponse = await Promise.all([
          shipmentSyncAction === 'Delta'
            ? this._deltaFetchShipmentsTransfersData(dataRange, shipmentsSyncState.lastUpdatedTime, shipmentSyncInfo)
            : Promise.resolve(null),
          adjustmentSyncAction === 'Delta'
            ? this._deltaFetchAdjustmentsData(dataRange, adjustmentSyncState.lastUpdatedTime, adjustmentSyncInfo)
            : Promise.resolve(null),
          teamAdjustmentSyncAction === 'Delta'
            ? this._deltaFetchTeamAdjustmentsData(dataRange, teamAdjustmentSyncState.lastUpdatedTime, teamAdjustmentSyncInfo)
            : Promise.resolve(null),
          !isNaN(oldestLastUpdatedTime) && oldestLastUpdatedTime > 0
            ? this._deltaFetchDeletedShipmentsTransfersAdjustmentsData(oldestLastUpdatedTime) : Promise.resolve(null),
        ]);

        const deletedDelta = Array.isArray(deltaResponse[3]) ? deltaResponse[3] : [];

        // Process delta response
        // Since plan tab in agenda page requires shipments data, we'll have to process it during the sync..
        const [shipmentSyncSuccess, adjustmentSyncSuccess, teamAdjustmentSyncSuccess] = await Promise.all([
          shipmentSyncAction === 'Delta'
            ? this.allocationShipmentOffline.processDeltaSyncedShipmentsTransfersData(deltaResponse[0], deletedDelta)
            : Promise.resolve(null),
          adjustmentSyncAction === 'Delta'
            ? this.allocAdjustService.processDeltaSyncedAdjustmentData(deltaResponse[1], deletedDelta)
            : Promise.resolve(null),
          teamAdjustmentSyncAction === 'Delta'
            ? this.allocAdjustService.processDeltaSyncedAdjustmentData(deltaResponse[2], deletedDelta, true)
            : Promise.resolve(null),
        ]);

        if (shipmentSyncSuccess !== null) {
          if (shipmentSyncSuccess.success === true) {
            shipmentsSyncState.lastUpdatedTime = newLastUpdatedTime;
            this.disk.updateSyncState(shipmentsSyncState);
          }
          this.allocationFeatureService.shipmentFetchState.setState(shipmentSyncSuccess.success ? 'Success' : 'Fail');

          // Init pending data for plan tab
          this.allocationFeatureService.loadPendingShipmentAndTransfer(
            shipmentSyncSuccess?.shipmentsData ?? undefined
          );
        }
        if (adjustmentSyncSuccess !== null) {
          if (adjustmentSyncSuccess) {
            adjustmentSyncState.lastUpdatedTime = newLastUpdatedTime;
            this.disk.updateSyncState(adjustmentSyncState);
          }
          this.allocationFeatureService.adjustmentFetchState.setState(adjustmentSyncSuccess ? 'Success' : 'Fail');
        }
        if (teamAdjustmentSyncSuccess !== null) {
          if (teamAdjustmentSyncSuccess) {
            teamAdjustmentSyncState.lastUpdatedTime = newLastUpdatedTime;
            this.disk.updateSyncState(teamAdjustmentSyncState);
          }
          this.allocationFeatureService.teamAdjustmentFetchState.setState(teamAdjustmentSyncSuccess ? 'Success' : 'Fail');
        }
      } catch (error) {
        console.error('AllocationShipmentDataService: Delta Sync: ', error);
      }
    }
    private async _getSyncStates(getShipment: boolean, getAdjustment: boolean, getTeamAdjustment: boolean)
                    : Promise<{ shipmentsSyncState: IEntitySyncState, adjustmentSyncState: IEntitySyncState, teamAdjustmentSyncState: IEntitySyncState }> {

      const response: { shipmentsSyncState: IEntitySyncState, adjustmentSyncState: IEntitySyncState, teamAdjustmentSyncState: IEntitySyncState } =
      { shipmentsSyncState: null, adjustmentSyncState: null, teamAdjustmentSyncState: null };

      try {
        const syncStates = await Promise.all([
          getShipment
            ? this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOC_SHIPMENT)
            : Promise.resolve(null),
          getAdjustment
            ? this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOC_ADJUSTMENT)
            : Promise.resolve(null),
          getTeamAdjustment
            ? this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOC_TEAM_ADJUSTMENT)
            : Promise.resolve(null),
        ]);

        if (Array.isArray(syncStates) && syncStates.length === 3) {
          response.shipmentsSyncState = syncStates[0] ?? null;
          response.adjustmentSyncState = syncStates[1] ?? null;
          response.teamAdjustmentSyncState = syncStates[2] ?? null;
        }
      } catch (error) {
        console.error('AllocationShipmentDataService: _getSyncStates: ', error);
      }
      return response;
    }

    private async _initialSyncShipmentsTransfersData(dataRange: IEntitySyncDataRange, syncInfo: EntitySyncInfo): Promise<(IAllocationShipment | IAllocationTransfer)[] | null> {
      let fetchXML: string = SHIPMENT_INITIAL_FETCH_TEMPLATE;
      const validTo: string = convertTimestampStringToISODateFormat(dataRange?.from, 'date');
      const validFrom: string = convertTimestampStringToISODateFormat(dataRange?.to, 'date');
      const isTransferFaOn: boolean = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER);

      fetchXML = fetchXML
        .replace(/{VALID_TO}/g, validTo)
        .replace(/{VALID_FROM}/g, validFrom)
        .replace(
          '{SHIPPED_BY_USER_FILTER}',
          isTransferFaOn ? SHIPPED_BY_USER_FILTER : ''
        );

      let pagingInfo: any = null;
      let data: (IAllocationShipment | IAllocationTransfer)[] = [];
      do {
        try {
          let response: DynamicsWebApi.FetchXmlResponse<IAllocationShipment | IAllocationTransfer> = await this.dynamics.executeFetchXml(
            SHIPMENT_ENTITY_NAME,
            fetchXML,
            undefined,
            pagingInfo?.nextPage ?? 0,
            pagingInfo?.cookie ?? null,
          );

          if (response) {
            pagingInfo = response.PagingInfo ?? null;

            if (Array.isArray(response.value)) {
              data.push(...response.value);
            }
          }
        } catch (error) {
          console.error('_initialSyncShipmentsData: ', error);
          pagingInfo = null;
          data = null;
          this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, fetchXML, error);
        }
      } while (pagingInfo !== null);

      if (syncInfo) {
        syncInfo.totalSynced = data !== null && Array.isArray(data) ? data.length : 0;
      }

      return data;
    }
    private async _initialSyncAdjustmentsData(dataRange: IEntitySyncDataRange, syncInfo: EntitySyncInfo): Promise<IAllocationAdjustment[]> {
      let fetchXML: string = ADJUSTMENT_INITIAL_FETCH_TEMPLATE;
      const startDate: string = convertTimestampStringToISODateFormat(dataRange?.from, 'date');
      const endDate: string = convertTimestampStringToISODateFormat(dataRange?.to, 'date');

      fetchXML = fetchXML
        .replace('{START_DATE}', startDate)
        .replace('{END_DATE}', endDate);

        let pagingInfo: any = null;
        let data: IAllocationAdjustment[] = [];
        do {
          try {
            let response: DynamicsWebApi.FetchXmlResponse<IAllocationAdjustment> = await this.dynamics.executeFetchXml(
              ADJUSTMENT_ENTITY_NAME,
              fetchXML,
              undefined,
              pagingInfo?.nextPage ?? 0,
              pagingInfo?.cookie ?? null,
            );

            if (response) {
              pagingInfo = response.PagingInfo ?? null;

              if (Array.isArray(response.value)) {
                data.push(...response.value);
              }
            }
          } catch (error) {
            console.error('_initialSyncShipmentsData: ', error);
            pagingInfo = null;
            data = null;
            this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, fetchXML, error);
          }
        } while (pagingInfo !== null);

        if (syncInfo) {
          syncInfo.totalSynced = data !== null && Array.isArray(data) ? data.length : 0;
        }

        return data;
    }
    private async _initialSyncTeamAdjustmentsData(dataRange: IEntitySyncDataRange, syncInfo: EntitySyncInfo): Promise<IAllocationAdjustment[] | null> {
      let fetchXML: string = TEAM_ADJUSTMENT_INITIAL_FETCH_TEMPLATE;
      const startDate: string = convertTimestampStringToISODateFormat(dataRange?.from, 'date');
      const endDate: string = convertTimestampStringToISODateFormat(dataRange?.to, 'date');

      fetchXML = fetchXML
        .replace('{START_DATE}', startDate)
        .replace('{END_DATE}', endDate);

        let pagingInfo: any = null;
        let data: IAllocationAdjustment[] = [];
        do {
          try {
            let response: DynamicsWebApi.FetchXmlResponse<IAllocationAdjustment> = await this.dynamics.executeFetchXml(
              ADJUSTMENT_ENTITY_NAME,
              fetchXML,
              undefined,
              pagingInfo?.nextPage ?? 0,
              pagingInfo?.cookie ?? null,
            );

            if (response) {
              pagingInfo = response.PagingInfo ?? null;

              if (Array.isArray(response.value)) {
                data.push(...response.value);
              }
            }
          } catch (error) {
            console.error('_initialSyncTeamAdjustmentsData: ', error);
            pagingInfo = null;
            data = null;
            this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, fetchXML, error);
          }
        } while (pagingInfo !== null);

        if (syncInfo) {
          syncInfo.totalSynced = data !== null && Array.isArray(data) ? data.length : 0;
        }

        return data;
    }

    private async _deltaFetchShipmentsTransfersData(dataRange: IEntitySyncDataRange, lastUpdatedTime: number, syncInfo: EntitySyncInfo): Promise<(IAllocationShipment | IAllocationTransfer)[] | null> {
      let fetchXML: string = SHIPMENT_DELTA_FETCH_TEMPLATE;
      const validTo: string = convertTimestampStringToISODateFormat(dataRange?.from, 'date');
      const validFrom: string = convertTimestampStringToISODateFormat(dataRange?.to, 'date');
      const isTransferFaOn: boolean = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER);
      const lastUpdatedTimeDate: string = lastUpdatedTime !== null && !isNaN(lastUpdatedTime)
                                          ? new Date(lastUpdatedTime).toJSON()
                                          : null;

      fetchXML = fetchXML
        .replace(/{VALID_TO}/g, validTo)
        .replace(/{VALID_FROM}/g, validFrom)
        .replace('{LAST_UPDATED_DATE}', lastUpdatedTimeDate)
        .replace(
          '{SHIPPED_BY_USER_FILTER}',
          isTransferFaOn ? SHIPPED_BY_USER_FILTER : ''
        )
        .replace(
          '{USER_SHIPMENT_TRASFERRED_FILTER}',
          isTransferFaOn ? USER_SHIPMENT_TRASFERRED_FILTER : ''
        ).replace(/{userId}/g, this.authenticationOfflineService.user.xSystemUserID);

      let pagingInfo: any = null;
      let data: (IAllocationShipment | IAllocationTransfer)[] = [];
      do {
        try {
          let response: DynamicsWebApi.FetchXmlResponse<IAllocationShipment | IAllocationTransfer> = await this.dynamics.executeFetchXml(
            SHIPMENT_ENTITY_NAME,
            fetchXML,
            undefined,
            pagingInfo?.nextPage ?? null,
            pagingInfo?.cookie ?? null,
          );

          if (response) {
            pagingInfo = response.PagingInfo ?? null;

            if (Array.isArray(response.value)) {
              data.push(...response.value);
            }
          }
        } catch (error) {
          console.error('_initialSyncShipmentsData: ', error);
          pagingInfo = null;
          data = null;
          this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, fetchXML, error);
        }
      } while (pagingInfo !== null);

      if (syncInfo) {
        syncInfo.totalSynced = data !== null && Array.isArray(data) ? data.length : 0;
      }

      return data;
    }
    private async _deltaFetchAdjustmentsData(dataRange: IEntitySyncDataRange, lastUpdatedTime: number, syncInfo: EntitySyncInfo): Promise<IAllocationAdjustment[] | null> {
      let fetchXML: string = ADJUSTMENT_DELTA_FETCH_TEMPLATE;
      const startDate: string = convertTimestampStringToISODateFormat(dataRange?.from, 'date');
      const endDate: string = convertTimestampStringToISODateFormat(dataRange?.to, 'date');
      const lastUpdatedTimeDate: string = lastUpdatedTime !== null && !isNaN(lastUpdatedTime)
                                          ? new Date(lastUpdatedTime).toJSON()
                                          : null;

      fetchXML = fetchXML
        .replace('{LAST_UPDATED_DATE}', lastUpdatedTimeDate)
        .replace('{START_DATE}', startDate)
        .replace('{END_DATE}', endDate);

      let pagingInfo: any = null;
      let data: IAllocationAdjustment[] = [];
      do {
        try {
          let response: DynamicsWebApi.FetchXmlResponse<IAllocationAdjustment> = await this.dynamics.executeFetchXml(
            ADJUSTMENT_ENTITY_NAME,
            fetchXML,
            undefined,
            pagingInfo?.nextPage ?? null,
            pagingInfo?.cookie ?? null,
          );

          if (response) {
            pagingInfo = response.PagingInfo ?? null;

            if (Array.isArray(response.value)) {
              data.push(...response.value);
            }
          }
        } catch (error) {
          console.error('_initialSyncShipmentsData: ', error);
          pagingInfo = null;
          data = null;
          this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, fetchXML, error);
        }
      } while (pagingInfo !== null);

      if (syncInfo) {
        syncInfo.totalSynced = data !== null && Array.isArray(data) ? data.length : 0;
      }

      return data;
    }
    private async _deltaFetchTeamAdjustmentsData(dataRange: IEntitySyncDataRange, lastUpdatedTime: number, syncInfo: EntitySyncInfo): Promise<IAllocationAdjustment[] | null> {
      let fetchXML: string = TEAM_ADJUSTMENT_DELTA_FETCH_TEMPLATE;
      const startDate: string = convertTimestampStringToISODateFormat(dataRange?.from, 'date');
      const endDate: string = convertTimestampStringToISODateFormat(dataRange?.to, 'date');
      const lastUpdatedTimeDate: string = lastUpdatedTime !== null && !isNaN(lastUpdatedTime)
                                          ? new Date(lastUpdatedTime).toJSON()
                                          : null;

      fetchXML = fetchXML
        .replace('{LAST_UPDATED_DATE}', lastUpdatedTimeDate)
        .replace('{START_DATE}', startDate)
        .replace('{END_DATE}', endDate);

      let pagingInfo: any = null;
      let data: IAllocationAdjustment[] = [];
      do {
        try {
          let response: DynamicsWebApi.FetchXmlResponse<IAllocationAdjustment> = await this.dynamics.executeFetchXml(
            ADJUSTMENT_ENTITY_NAME,
            fetchXML,
            undefined,
            pagingInfo?.nextPage ?? null,
            pagingInfo?.cookie ?? null,
          );

          if (response) {
            pagingInfo = response.PagingInfo ?? null;

            if (Array.isArray(response.value)) {
              data.push(...response.value);
            }
          }
        } catch (error) {
          console.error('_deltaFetchTeamAdjustmentsData: ', error);
          pagingInfo = null;
          data = null;
          this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, fetchXML, error);
        }
      } while (pagingInfo !== null);

      if (syncInfo) {
        syncInfo.totalSynced = data !== null && Array.isArray(data) ? data.length : 0;
      }

      return data;
    }
    private async _deltaFetchDeletedShipmentsTransfersAdjustmentsData(lastUpdatedTime: number): Promise<IAllocationShipmentAndTransferBase[] | null> {
      const isTransferFaOn: boolean = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER);
      const lastUpdatedTimeDate: string = new Date(lastUpdatedTime).toJSON() ?? null;

      let fetchXML: string = SHIPMENT_DELTA_DELETED_FETCH_TEMPLATE
        .replace('{LAST_UPDATED_DATE}', lastUpdatedTimeDate)
        .replace(
        '{USER_SHIPMENT_TRASFERRED_FILTER}',
        isTransferFaOn ? USER_SHIPMENT_TRASFERRED_FILTER : ''
      ).replace(/{userId}/g, this.authenticationOfflineService.user.xSystemUserID);
      

      let pagingInfo: any = null;
      let data: any[] = [];
      do {
        try {
          let response: DynamicsWebApi.FetchXmlResponse<any> = await this.dynamics.executeFetchXml(
            TRACK_CHANGE_ENTITY_NAME,
            fetchXML,
            undefined,
            pagingInfo?.nextPage ?? null,
            pagingInfo?.cookie ?? null,
          );

          if (response) {
            pagingInfo = response.PagingInfo ?? null;

            if (Array.isArray(response.value)) {
              data.push(...response.value);
            }
          }
        } catch (error) {
          console.error('_initialSyncShipmentsData: ', error);
          pagingInfo = null;
          data = null;
        }
      } while (pagingInfo !== null);
      return data;
    }

    async getShipmentLossReasons(loadFromDbOnly = false): Promise<void>{
        if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)){
            if(loadFromDbOnly){
                //if (this.disk.check('shipmentlossreasons')) {
                    let raw = await this.disk.retrieve(DB_KEY_PREFIXES.SHIPMENT_LOSS_REASONS);
                    if (raw && raw['rawReasons']) {
                      this.allocationShipmentOffline.mapShipmentLossReasons(raw.rawReasons,true)
                      this.allocationFeatureService.shipmentLossReasonDataReadState.setState('Success');
                    } else {
                      this.allocationFeatureService.shipmentLossReasonDataReadState.setState('Fail');
                    }
                //}
                return;
            }

            let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.acknowledgment_receipt.SHIPMENT_LOSS_REASONS;
            const shipmentLossReasonSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.shipmentLossReason,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            let headers: HttpHeaders = new HttpHeaders();
                headers = headers
                .set('Sync-Service', 'true');

            try {
                this.allocationFeatureService.shipmentLossReasonFetchState.setState('Sync');
                let response: IShipmentReason[] = await this.http.get<IShipmentReason[]>(url,{headers: headers}).toPromise();
                if (Array.isArray(response)) {
                    shipmentLossReasonSyncInfo.totalSynced = response.length;
                }
                this.allocationShipmentOffline.mapShipmentLossReasons(response,false);
                this.allocationFeatureService.shipmentLossReasonFetchState.setState('Success');
                this.allocationFeatureService.shipmentLossReasonDataReadState.setState('Success');
            } catch (error) {
                this.deltaService.addSyncErrorToEntitySyncInfo(shipmentLossReasonSyncInfo, url, error);
                this.allocationFeatureService.shipmentLossReasonFetchState.setState('Fail');
                this.allocationFeatureService.shipmentLossReasonDataReadState.setState('Fail');
            }

            this.deltaService.addEntitySyncInfo(shipmentLossReasonSyncInfo);
        }
        return;
    }

    acknowledgeShipment(shipmentId: string, payload: any):Observable<any>{
        if(!shipmentId) return;
        let url:string =this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.acknowledgment_receipt.ACKNOWLEDGE_SHIPMENT;
            url = url.replace('{shipment_id}', shipmentId);
        let headers = Endpoints.acknowledgment_receipt.ACKNOWLEDGE_HEADERS;
            headers.headers = headers.headers.set('X-SystemUserId', this.authenticationOfflineService.user.systemUserID);
        return this.http.patch(url,payload,headers);
    }
}
