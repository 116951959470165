<div class="searchbar-wrapper">
  <!-- <ion-item class="search-item">
    <ion-input placeholder="{{'CONTACT' | translate}}"></ion-input>
    <ion-button slot="end" fill="clear">
       <ion-icon name="indegene-search"></ion-icon> 
    </ion-button>
  </ion-item> -->
  <ion-toolbar>
    <ion-searchbar [(ngModel)]="searchText" (ionChange)="_onSearchChange($event)" (ionFocus)="_onSearchFocus($event)"
      (ionBlur)="_onSearchBlur($event)" (ionCancel)="_onSearchCancel($event)" (ionClear)="_onSearchClear($event)"
      (ionInput)="_onSearchInput($event)" (click)="_onSearchBarClick($event)" (search)="_onSearch($event)"
      [disabled]="searchDisabled" [debounce]="searchDebounce" [placeholder]="placeholder">
    </ion-searchbar>
    <div *ngIf="shouldShowSearchBarFilterButton" class="searchbar-overlay">
      <ion-button *ngIf="shouldShowSearchBarFilterButton" fill="clear" class="filter-btn" [disabled]="filterDisabled"
        (click)="_onFilterClick($event)">
        <!-- <ion-icon name="indegene-filter"></ion-icon> -->
        <img src="assets/imgs/filter-grey.svg" alt="filter">
        <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge">{{ filterButtonBadgeCount }}</span>
      </ion-button>
    </div>
  </ion-toolbar>
</div>