import * as _ from 'lodash';
import { Resource } from '../classes/resource/resource.class';
import { Presentation } from '../classes/presentation/presentation.class';

export class Utility {

    private static globalCustomerReplaceText: string = "";
    private static globalCustomersReplaceText: string = "";


    /**
     * Returns true if the current time subtract the lastModified date is greater than the tolerance (EXPIRED)
     *
     * @static
     * @param {Date} lastModifiedDate
     * @param {number} tolerance
     * @returns {boolean}
     * @memberof Utility
     */
    public static isExpired(lastModifiedDate: number, tolerance: number): boolean {
        const currentTime = new Date().getTime();
        if (currentTime - lastModifiedDate >= tolerance) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Lets ya know if your number is close to another number based off tolerance
     *
     * @static
     * @param {number} input the actual number you're checking: eg 64
     * @param {number} focus the number you want it to be close to: eg 75
     * @param {number} tol how far away its allowed to be from its focus: 25
     * @returns {boolean}
     * @memberof Utility
     */
    public static tolerance(input: number, focus: number, tol: number): boolean {
        let lowerBound = focus - tol;
        let upperBound = focus + tol;

        if (input >= lowerBound && input <= upperBound) return true;

        return false;
    }

    static changeUTCDateToLocalDateWith0Time(utcTimestamp: number,isEndDate:boolean = false) {
        const utc = new Date(utcTimestamp);
        const utcFullYear = utc.getUTCFullYear();
        const utcMonthIndex = utc.getUTCMonth();
        const utcDate = utc.getUTCDate();
        if(isEndDate){
            return new Date(utcFullYear, utcMonthIndex, utcDate, 23, 59, 59);
        }else{
            return new Date(utcFullYear, utcMonthIndex, utcDate, 0, 0, 0);
        }
    }

    static changeLocalDateToUTCDateWith0Time(localTimestamp: number,isEndDate:boolean = false) {
        var date = new Date(localTimestamp);
        var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
         0, 0, 0);
         return new Date(now_utc);
    }

    public static getFormattedNumber(value:number) :string{
        let str = "0.00"
        if(value){
            str =  value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return str;
    }

    public static delay(ms: number): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public static getRoundedDownTime(dateTime:Date) {
        if(dateTime.getMinutes() < 30){
          dateTime.setMinutes(0);
          dateTime.setSeconds(0);
          dateTime.setMilliseconds(0);
          return dateTime;
        }else{
          dateTime.setMinutes(30);
          dateTime.setSeconds(0);
          dateTime.setMilliseconds(0);
          return dateTime;
        }
    }

  public static getHalfHourBackTime(dateTime: Date) {
    if (dateTime.getMinutes() == 30) {
      dateTime.setMinutes(0);
      dateTime.setSeconds(0);
      dateTime.setMilliseconds(0);
      return dateTime;
    } else  {
      dateTime.setHours(dateTime.getHours() - 1);
      dateTime.setMinutes(30);
      dateTime.setSeconds(0);
      dateTime.setMilliseconds(0);
      return dateTime;
    } 
  }

    public static get globalCustomerText(): string {
      return this.globalCustomerReplaceText;
    }

    public static set globalCustomerText(text) {
      this.globalCustomerReplaceText = text;
    }

    public static get globalCustomersText(): string {
      return this.globalCustomersReplaceText;
    }

    public static set globalCustomersText(text) {
      this.globalCustomersReplaceText = text;
    }

    public static getUrlParams(search) {
      const hashes = search.slice(search.indexOf('?') + 1).split('&');
      const params = {};
      hashes.map(hash => {
        const [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
      });
      return params
    }

    public static getCommaSeparatedString(array: string[]): string {
        let returnString = '';
        try {
            let tempArray = _.compact(array);

            if (tempArray.length > 0) {
                returnString = _.join(tempArray, ', ');
            }
        } catch (error) {
            console.error('getCommaSeparatedString: ', error);
        }

        return returnString.trim();
    }

    public static filterByDate(resource: Resource | Presentation) {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (resource.availableFrom && resource.availableFrom > currentDate) {
          return false;
        }
        if (resource.availableUntil && resource.availableUntil < currentDate) {
          return false;
        }
        return true;
      }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = getFileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
  return zoneOriginalInstance || fileReader;
}

export const MAXIMUM_NOTE_ATTACHMENT_SIZE = 5120;

export const NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX = new RegExp("[^\\s]+(.*?)\\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|pdf|PDF|doc|DOC|docx|DOCX|txt|TXT|xls|XLS|xlsx|XLSX|csv|CSV|ppt|pptx|PPT|PPTX|rar|RAR|mp4|MP4|mp3|MP3)$");

export const NOTE_ATTACHMENT_MIME_TYPES_VIDEO_RESTRICTED_REGEX = new RegExp("[^\\s]+(.*?)\\.(mp4|MP4|mov|MOV|avi|AVI|mkv|MKV|wmv|WMV)$");

export const DCR_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX = new RegExp("[^\\s]+(.*?)\\.(jpg|png)$");

export const CONTRACT_SUPPORTED_MIME_TYPES_SUPPORTED_REGEX = new RegExp("[^\\s]+(.*?)\\.(PDF|pdf)$");

export const shortMonthArray =  ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export enum PopoverMode {
    FOR_JOURNEY_INSIGHT_DESCRIPTION,
    FOR_JOURNEY_INSIGHT_COMPETITOR_PRODUCT,
    FOR_INTEREST_INSIGHT_DESCRIPTION
}

export enum CustomerSelectionMode {
    FOR_INSIGHT, FOR_INSIGHT_RELATIONSHIP
}

export enum OptionSelectionMode {
    SELECT_SPECIALITY, SELECT_INTEREST_CATEGORY, SELCET_JOURNEY_INSIGHT_PRODUCT
}

export enum CustomerReplaceText {
    GLOBAL_CUSTOMER_REPLACE_TEXT = 'CUSTOMER',
    GLOBAL_STAKEHOLDER_REPLACE_TEXT = 'STAKEHOLDER',
    GLOBAL_CUSTOMERS_REPLACE_TEXT = 'CUSTOMERS',
    GLOBAL_STAKEHOLDERS_REPLACE_TEXT = 'STAKEHOLDERS',
}

export const MAXIMUM_PHOTO_CAPTURE_LIMIT = 10;

export enum DynamicsConstant {
  pagingCookie = "@Microsoft.Dynamics.CRM.fetchxmlpagingcookie",
  hasMoreRecords = "@Microsoft.Dynamics.CRM.morerecords"
}