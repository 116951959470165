<div #tableContainer id="marketScanTable" class="table-container">
  <table *ngIf="displayedColumnsLength > 1" [ngClass]="{ 'two-columns-sticky': captureDataFor===548910002}">
    <thead>
      <tr>
        <th *ngFor="let col of displayedColumns; let i = index"
          [hidden]="col.action === APIAction.DELETE ? true: false">
          <div class="header-column" [style.width.px]="i === 0 ? 200 : colWidth">
            {{col.name}}
          </div>
        </th>
      </tr>
    </thead>
    <tr *ngFor="let row of displayedRows; let rowIndex = index"
      [hidden]="row['Customer']['action'] === APIAction.DELETE ? true: false">
      <ng-container *ngFor="let col of displayedColumns; let colIndex = index">
        <td [hidden]="row['Products'][colIndex]['action'] === APIAction.DELETE ? true: false">
          <div [ngClass]="{'platform-android': device.deviceFlags.android }" class="column table-text"
            [style.width.px]="colIndex === 0 ? 200 : colWidth">
            <ng-container *ngIf="colIndex === 0 && captureDataFor==548910002">
              <span>{{row['Customer']['groupName']}}</span>
              <!-- <ng-container *ngFor="let sr of subrows; let i = index">
                <ng-container *ngIf="sr.action != APIAction.DELETE && i > 0">
                  <div class="sub-row" class="contact-{{sr.value}}">{{sr.name}}</div>
                </ng-container>
              </ng-container> -->
            </ng-container>
            <ng-container *ngIf="colIndex === 1 && captureDataFor===548910002">
              <span>{{row['Customer']['name']}}</span>
              <ng-container *ngFor="let sr of subrows; let i = index">
                <ng-container *ngIf="sr.action != APIAction.DELETE && i > 0 && (sr.measureCaptureFor == 548910001)">
                  <div class="sub-row" class="contact-{{sr.value}}">{{sr.name}}</div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="colIndex === 0 && captureDataFor!==548910002">
              <span>{{row['Customer']['name']}}</span>
              <ng-container *ngFor="let sr of subrows; let i = index">
                <ng-container *ngIf="sr.action != APIAction.DELETE && i > 0">
                  <div class="sub-row" class="contact-{{sr.value}}">{{sr.name}}</div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="(colIndex > 0 && captureDataFor!==548910002) || (colIndex > 1 && captureDataFor===548910002)">
              <ng-container *ngFor="let sr of subrows; let i = index">
                <div [style.height.px]="subRowsHeight[i]"
                  [ngClass]="{'sub-row': sr.action != APIAction.DELETE && i > 0,
                              'noDataSubRow': sr.action == APIAction.DELETE, 'disabled' :readonly, 'emptySubRow': i == 0}"
                  *ngIf="(i==0 || (sr.measureCaptureFor == 548910001))" >
                  <ng-container *ngIf="sr.action != APIAction.DELETE && i > 0">
                    <ng-container *ngIf="!device.deviceFlags.android">
                      <input [type]="sr.type" #inputRef [(ngModel)]="row['Products'][colIndex][i-1][sr.value]"
                        (blur)="onBlur(rowIndex, colIndex,i-1,sr.value)" (focus)="onInputFocus(inputRef)"
                        placeHolder="- - -">
                    </ng-container>
                    <ng-container *ngIf="device.deviceFlags.android">
                      <ion-input [type]="sr.type" #inputRef [(ngModel)]="row['Products'][colIndex][i-1][sr.value]"
                        (ionBlur)="onBlur(rowIndex, colIndex,i-1,sr.value)" (ionFocus)="onInputFocus(inputRef)"
                        placeHolder="- - -">
                      </ion-input>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </td>
      </ng-container>
    </tr>
  </table>
</div>
<ng-container *ngIf="displayedColumnsLength === 1">
  <div class="customer-column">
    {{captureDataFor === 548910001 ? ('ACCOUNT' | translate) : ('CUSTOMER' | translate)}}
  </div>
  <div class="empty-row">
    <span *ngFor="let i of placeHolderCellsToDisplay">
      - - -
    </span>
  </div>
</ng-container>
