<!-- Generated template for the AppLanguageListComponent component -->
<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" (onControlClick)="onHeaderControlClick($event)" (onSearchInput)="getItems($event)"></ind-header-left>
</ion-header>
<ion-content scrollY='true' no-padding toolbar-md-height>
    <ion-list class="list-height" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
        <ion-radio-group ngDefaultControl [(ngModel)]="selectedFormatCode">
            <ind-section-header *ngIf="!searching" [viewData]='dateFomatHeader'></ind-section-header>
            <ind-section-header *ngIf="searching" [viewData]='dateFomatResultHeader'></ind-section-header>

            <ind-no-search-result *ngIf="filteredList.length === 0 && searching" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>

            <ion-item *ngFor="let format of filteredList" (click)="changeFormat(format)">
                <ion-label>
                    <h3>
                        {{'DATE_FORMATS_VALUE' | translate : {formatValue : format.display} }}
                    </h3>
                </ion-label>
                <ion-radio slot="end" [value]="format.format"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->
