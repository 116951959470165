import { Activity } from "./activity.class";
import { PhotoAttachment } from '../store-check/photo-attachment';

export class StoreCheckActivity extends Activity {
  public categoryId: string;
  public categoryName: string;
  public offlineActivityId: string;
  public photoAttachments: PhotoAttachment[] = [];
  public accounts: any[] = [];
  constructor(raw) {
    super(raw);
    this.categoryId = raw['categoryId'];
    this.categoryName = raw['categoryName'];
    this.offlineActivityId = raw['offlineActivityId'];
    this.photoAttachments = raw['photoAttachments'] ?? [];
    this.accounts = raw['accounts'] ?? raw['activityAccounts'] ?? [];
  }

  get DTO(): Object {
    let DTOResponse = {
      activityAccounts: this.accounts ?? [],
      offlineActivityId: this.offlineActivityId || '',
      subject: this.subject || '',
      scheduledstart: '' + this.scheduledStart.getTime(),
      scheduledend: '' + this.scheduledEnd.getTime(),
      stateCode: this.state,
      statuscode: this.status,
      categoryId: this.categoryId,
      categoryName: this.categoryName,
      indskr_type: this.indskr_type,
      photoAttachments: this.photoAttachments,
      activityId: this.ID || '',
      activitytypecode: 'appointment',
      indskr_ownerid: this.meetingOwnerId
    }
    return DTOResponse;
  }

  /**
   * Returns a string depending on the current accounts associated with the appointment
   *
   * @readonly
   * @type {string}
   * @memberof AppointmentActivity
   */
  get accountString(): string {
    //Only one account, return his name
    if (this.accounts.length === 1) {
      let accountString: string = `${this.accounts[0].accountName ?? this.accounts[0].indskr_name}`;
      if (this.accounts[0].status === 2 || this.accounts[0].status == 548910003 || this.accounts[0].status == 548910010 || this.accounts[0].status == 548910011 || this.accounts[0].status == 548910012 || this.accounts[0].status == 548910013) {
        return accountString += ' (Inactive)';
      }
      return accountString;
    }
    return '';
  }
}