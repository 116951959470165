import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApprovalActivity, CustomerPositionList } from '@omni/classes/territory-management-list/customer-position-list.class';
import { OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { TerritoryManagementDataService } from '@omni/data-services/territory-management/territory-management.data.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'territory-manangement-info',
  templateUrl: './territory-manangement-info.html',
  styleUrls: ['./territory-manangement-info.scss'],
})
export class TerritoryManangementInfoComponent implements OnInit {
  public selectedCustomerPositionList: CustomerPositionList;
  public formattedCreatedOn: string;
  public formattedApprovalCreatedOn: string;
  private ngDestroy$: any = new Subject<boolean>();
  public approvalActivities: ApprovalActivity[] = [];
  @Input() selectedTab
  constructor(
    private modalController: ModalController,
    public territoryService: TerritoryManagementService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private territoryDataService: TerritoryManagementDataService
  ) { }

  async ngOnInit() {
    this.territoryService.selectedCustomerPostionListSubject.pipe(takeUntil(this.ngDestroy$)).subscribe(async (data) => {
      if (data) {
        this.selectedCustomerPositionList = data;
        this.formattedCreatedOn = this.datePipe.transform(new Date(this.selectedCustomerPositionList.createdon), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        if (this.selectedCustomerPositionList.approvalActivityCreatedOn)
          this.formattedApprovalCreatedOn = this.datePipe.transform(new Date(this.selectedCustomerPositionList.approvalActivityCreatedOn), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      }
    });
    this.approvalActivities = await this.territoryDataService.fetchApprovalActivities();
  }

  async openCommentModal(approvalActivity: ApprovalActivity) {
    const modal = await this.modalController.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-info-modal',
      componentProps: {
        header: this.translate.instant('COMMENT'),
        topLabel: this.translate.instant('APPROVER'),
        topValue: approvalActivity.approver,
        bottomLabel: this.translate.instant('COMMENT'),
        bottomValue: approvalActivity.indskr_reason
      }
    });
    return await modal.present();
  }


  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }


}
