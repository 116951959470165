import { takeUntil } from 'rxjs/operators';
import { Component, ViewChild } from "@angular/core";
import { ResourceService } from "../../../services/resource/resource.service";
import { Resource } from "../../../classes/resource/resource.class";
import { Subject } from "rxjs";
import { DeviceService } from "../../../services/device/device.service";
import { ResourceDetailsComponent } from "../resource-details/resource-details";
import { NavController, NavParams, IonNav } from "@ionic/angular";
import { Events } from '@omni/events';
import { PageName, NavigationService, ChildNavNames } from "../../../services/navigation/navigation.service";
import { FooterService } from '@omni/services/footer/footer.service';

@Component({
  selector: "resource-page[base-page]",
  templateUrl: "resource-page.html",
  styleUrls: ['resource-page.scss']
})

export class ResourcePageComponent {

  public viewMode: any;
  public resources: Resource[];
  public collectionName: string;

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedResource: Resource;
  @ViewChild('resourcerightpane', { static: true }) navCtrl: IonNav;

  constructor(
    private resourceService: ResourceService,
    public device: DeviceService,
    private events: Events,
    public navParams: NavParams,
    public navService: NavigationService,
    private footerService: FooterService
  ) {
    this.viewMode = this.resourceService.viewMode;
    this.resources = (this.navParams.data && this.navParams.data.resources);
    // name of that would be the header of the list i.e. Scientific Plan name or Account Plan name
    this.collectionName = (this.navParams.data && this.navParams.data.collectionName);
  }

  ngOnInit() {

    // this.navCtrl.setRoot(ResourceDetailsComponent);

    // let navOptions = {
    //   animate: true,
    //   animation: 'md-transition',
    //   direction: 'back'
    // }

    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.ResourceToolNavigation, PageName.ResourcePageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.footerService.initButtons('');

    this.resourceService.reset();
    this.resourceService.highlightedResource.pipe(
      takeUntil(this.destroy$))
      .subscribe(resource => {
        if (resource) {
          this.selectedResource = resource;
          this.gotoDetails();
        }

        // this.navCtrl.setRoot(ResourceDetailsComponent, { resource });
        // this.navCtrl.popToRoot();
        // this.navCtrl.popToRoot(navOptions);
      });
  }

  // ngAfterViewInit() {
  //   this.gotoDetails(false);
  // }

  private gotoDetails() {
    this.navService.setChildNavRoot(ResourceDetailsComponent, PageName.ResourceDetailsComponent, PageName.ResourcePageComponent, { resources: this.resources });
    this.navService.setChildNavRightPaneView(true);
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.ResourceToolNavigation);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
