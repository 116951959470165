<ion-header>
    <!-- <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
    <ion-buttons slot="start">
        <ion-button (click)="onClosePage()" >
            <ion-icon class='arrow-icon' name="chevron-back-outline"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title text-center>{{'QOUTE_NUMBER_' | translate}} {{selectedQuote.quoteNumber}}</ion-title>
  </ion-toolbar> -->
    <ind-page-title [viewData]="quotePageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ind-section-header [viewData]='quoteDetailsHeader'></ind-section-header>
    <!-- <ind-form-field *ngFor="let field of quoteDetailsFieldValues" [viewData]='field'></ind-form-field> -->
    <ind-form-field [viewData]='statusField'></ind-form-field>
    <ind-form-field [viewData]='quoteNumberField'></ind-form-field>
    <ind-form-field [viewData]='createdByField'></ind-form-field>
    <ind-form-field [viewData]='countryField'></ind-form-field>
    <ind-form-field *ngIf="_isDemoFeature" [viewData]='currencyField'></ind-form-field>
    <ind-form-field *ngIf="_isDemoFeature" [viewData]='priceListField'></ind-form-field>
    <ind-form-field [viewData]='accountField'></ind-form-field>
    <ion-row>
      <ion-col size="6"><ind-datetime-form [viewData]="effectiveFromField"></ind-datetime-form></ion-col>
      <ion-col size="6"><ind-datetime-form [viewData]="effectiveToField"></ind-datetime-form></ion-col>
    </ion-row>
    <ind-form-field [viewData]='totalField'></ind-form-field>

    <ind-section-header [viewData]='quoteProductsHeader' (onControlClick)="openProductSelect()"></ind-section-header>
    <!-- <ion-item no-lines hidden="true">
        <ion-datetime #effectiveFromDateControl [displayFormat]="dateTimeFormatsService.dateToUpper" [pickerFormat]="dateTimeFormatsService.dateToUpper" (ionChange)="updateEffectiveFrom()" [min]="todayDate" max="{{maxDate}}" [placeholder]="dateTimeFormatsService.dateToUpper">
        </ion-datetime>
        <ion-datetime #effectiveToDateControl [displayFormat]="dateTimeFormatsService.dateToUpper" [pickerFormat]="dateTimeFormatsService.dateToUpper" (ionChange)="updateEffectiveTo()" [min]="todayDate" max="{{maxDate}}" [placeholder]="dateTimeFormatsService.dateToUpper">
        </ion-datetime>
    </ion-item> -->
    <ion-item class="placeholderEmptyDiv" *ngIf="!quoteProducts || quoteProducts.length==0"></ion-item>
    <main-card *ngFor="let product of quoteProducts" [viewData]="product"></main-card>
    <div *ngIf="_isDemoFeature">
        <ion-item-divider class="no-margin">
            {{'TIMELINE' | translate}}
        </ion-item-divider>
        <p *ngIf="groupedTimelineData.length==0" class="middle-center">{{'NO_TIMELINE' | translate}}</p>
        <div *ngIf="groupedTimelineData.length>0" class="timelineWrapper">
            <div class="containerForData">
                <ion-item-group class="timeline-group" *ngFor="let group of groupedTimelineData">
                    <ion-item-divider class="no-margin" [id]="group.key">
                        <div class="dividerBorder">
                        </div>
                        <div class="monthGroupName">
                            {{formatHeader(group.key)}}
                        </div>
                    </ion-item-divider>
                    <ion-item class="margin-5" *ngFor="let activity of group.list">
                        <div col-2 float-left>
                            <h3 text-center>{{parseDate(activity.createdon)}}</h3>
                            <h4 text-center text-wrap>{{activity.createdon | customDate:'TIME'}}</h4>
                        </div>
                        <div col-10 float-right>
                            <h3 text-nowrap>
                                <span margin-right>{{activity._ind_activestage_value_Formatted}}</span>
                            </h3>
                            <h4>
                                <span>{{activity._createdby_value_Formatted}}</span>
                            </h4>
                        </div>
                    </ion-item>
                </ion-item-group>
            </div>
        </div>
    </div>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'opportunityQuoteDetails'"></footer-toolbar>
<!-- </ion-footer> -->