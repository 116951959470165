import { Contact } from "../contact/contact.class";

import { ActivityType } from "../activity/activity.class";
import { OFFLINE_ID_PREFIX } from "../activity/appointment.activity.class";
import { Presentation } from "../presentation/presentation.class";
import { Resource } from "../resource/resource.class";

export class CalendarAppointment {
    id: string;
    text: string;
    startDate: Date;
    actualStartDate: Date;
    endDate: Date;
    actualEndDate: Date;
    location: string = "No Location";
    priorityId: number = 0;
    contactid: string;
    reason: string;
    meetingName: string;
    isAllDay: boolean;
    refid: string;
    contactAttendees: Contact[] = [];
    isScrapped: boolean = false;
    isUpdated: boolean = false;
    presentations: (Presentation | Resource)[] = [];
    indskr_activitytype: string;
    indskr_activitysubtype: string;
    activityTypeName: string;
    activitySubTypeName: string;

    constructor(raw: any) {
        if (raw.type) {
            this.text = (raw.type === ActivityType.Appointment) ? raw.subject : raw.name;
            this.startDate = raw.scheduledStart;

            this.endDate = raw.scheduledEnd;

            this.location = (raw.type === ActivityType.Appointment) ? raw.location : "No Location";
            this.priorityId = (raw.type === ActivityType.Appointment) ? 0 : 2;
            this.reason = (raw.type === ActivityType.Appointment) ? "" : raw.reason;
            this.isAllDay = (raw.type === ActivityType.TimeOff) ? raw.totIsAlldayEvent : false;
            this.presentations = raw['presentations'] ? raw['presentations'] : [];
        }
        else {
            /*
                If the raw doesnt have type means its not a meeting activity and just a sugesstion
                from DNA team
            */
            this.text = raw.subject;
            if (raw.location == "nan") {
                raw.location = "No Location"
            }
            this.location = (raw.location && raw.location != "") ? raw.location : "No Location";
            this.endDate = new Date(raw.scheduledend);
            this.actualEndDate = new Date(raw.scheduledend);
            this.startDate = new Date(raw.scheduledstart);
            this.actualStartDate = new Date(raw.scheduledstart);
            this.contactid = raw.contactid;
            this.priorityId = 1;
            this.meetingName =  raw.subject + " - Meeting";
            if (raw.repName && (raw.isMeetingAutoSubject && raw.isMeetingAutoSubject === true)) {
              this.meetingName = raw.subject + " - Meeting - " + raw.repName;
            }
            this.id = OFFLINE_ID_PREFIX + (new Date().getTime() + Math.floor(Math.random() * 9000 + 1000));
            this.refid = raw["refid"];
            this.activityTypeName = raw.activityTypeName;
            this.indskr_activitytype = raw.indskr_activitytype;
            this.activitySubTypeName = raw.activitySubTypeName;
            this.indskr_activitysubtype = raw.indskr_activitysubtype;
        }
    }

    public get meetingSaveDTO(): any {
        let obj: any = {
            "location": this.location,
            "scheduledend": new Date(this.endDate).getTime(),
            "scheduledstart": new Date(this.startDate).getTime(),
            "subject": this.meetingName,
            "offlineMeetingId": this.id,
            "contactAttendees": this.contactDTO,
            "indskr_subtype": 100000001,
            "indskr_type": 100000000,
            "indskr_dnascheduler": true,
            "activityPlaylists": this.activityPlayList
        };
        if (this.indskr_activitytype) {
          obj.activityTypeName = this.activityTypeName;
          obj.indskr_activitytype = this.indskr_activitytype;
          obj.activitySubTypeName = this.activitySubTypeName;
          obj.indskr_activitysubtype = this.indskr_activitysubtype;
        }
        return obj;
    }

    private get activityPlayList(): any[] {
        let presDtoArray = [];
        for (let pres of this.presentations) {
            presDtoArray.push(pres.DTO);
        }
        return presDtoArray;
    }

    private get contactDTO(): any[] {
        let res = [];
        if (this.contactAttendees && this.contactAttendees.length > 0) {
            this.contactAttendees.forEach(e => {
                let obj = {
                    "indskr_contactid": e.ID,
                    "indskr_name": e.fullname
                };
                res.push(obj);
            });
            return res;
        }
        else {
            return res;
        }
    }

    public get lengthOfActivity(): string {
        return this._getMessageForTimeLength();
    }

    private _getMessageForTimeLength(): string {
        let difference = this.endDate.getTime() - this.startDate.getTime();
        if (difference === 0) return 'N/A';
        var days, hours, mins, secs;
        secs = Math.floor(difference / 1000);
        mins = Math.floor(secs / 60);
        secs = secs % 60;
        hours = Math.floor(mins / 60);
        mins = mins % 60;
        days = Math.floor(hours / 24);
        hours = hours % 24;
        if (days > 0) {
            if (hours > 0 && mins > 0) {
                return `${days}d ${hours}h ${mins}m`
            } else if (hours > 0 && mins <= 0) {
                return `${days}d ${hours}h`
            } else if (hours <= 0 && mins > 0) {
                return `${days}d ${mins}m`
            }
        } else {
            if (hours > 0 && mins > 0) {
                return `${hours}h ${mins}m`
            } else if (hours > 0 && mins <= 0) {
                return `${hours}h`
            } else if (hours <= 0 && mins > 0) {
                return `${mins}m`
            }
        }
        return 'N/A';
    }

    public get feedbackDTO(): any[] {
        let obj: any = {
            "location": this.location,
            "subject": this.meetingName,
            "contactAttendees": this.contactDTO,
            "isUpdated": this.isUpdated,
            "isScrapped": this.isScrapped,
            "refid": this.refid
        };

        //scrapped meeting suggestion
        if (this.isUpdated && this.isScrapped) {
            obj["actualscheduledstart"] = new Date(this.actualStartDate).getTime();
            obj["actualscheduledend"] = new Date(this.actualEndDate).getTime();
            obj["modifiedscheduledstart"] = undefined;
            obj["modifiedscheduledend"] = undefined;
        }
        //updated meeting suggestion
        if (this.isUpdated && !this.isScrapped) {
            obj["actualscheduledstart"] = new Date(this.actualStartDate).getTime();
            obj["actualscheduledend"] = new Date(this.actualEndDate).getTime();
            obj["modifiedscheduledstart"] = new Date(this.startDate).getTime()
            obj["modifiedscheduledend"] = new Date(this.endDate).getTime()
        }
        //no changes made to suggestion
        if (!this.isUpdated && !this.isScrapped) {
            obj["actualscheduledstart"] = new Date(this.actualStartDate).getTime();
            obj["actualscheduledend"] = new Date(this.actualEndDate).getTime();
            obj["modifiedscheduledstart"] = undefined
            obj["modifiedscheduledend"] = undefined
        }

        return obj;
    }

}


export interface ISuggestionMeetingResponse {
    contactid: string;
    scheduledstart: number;
    scheduledend: number;
    location: string;
    subject: string;
    isMeetingAutoSubject: boolean;
    repName: string;
}
