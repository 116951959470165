<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content *ngIf="callPlanService.selectedRepCallPlan">
  <ion-list>
    <ion-item-group class='plan-fields'>
      <!-- =============================Account Call Plan - Details============================= -->
      <ind-section-header [viewData]="detailsHeaderModel"></ind-section-header>
      <ind-form-field [viewData]=getStatusFormField()></ind-form-field>
      <ind-form-field [viewData]=getCustNameFormField()></ind-form-field>
      <ion-row>
        <ion-col size="6">
          <ind-form-field [viewData]="getStartDateFormField()"></ind-form-field>
        </ion-col>
        <ion-col size="6">
          <ind-form-field [viewData]="getEndDateFormField()"></ind-form-field>
        </ion-col>
      </ion-row>
      <ind-form-field [viewData]=getProductFormField()></ind-form-field>
      <ind-form-field [viewData]=getSegmentFormField()></ind-form-field>
      <ind-form-field [viewData]=getPositionFormField()></ind-form-field>
      <!-- =============================Account Call Plan - Details============================= -->
      <!-- =============================Account Call Plan - Overview============================= -->
      <ind-section-header [viewData]="overviewHeaderModel"></ind-section-header>
      <div class="overview">
        <!-- ENGAGEMENTS -->
        <div class="goal" *ngIf="callPlanService.selectedRepCallPlan.totalGoalCalls">
          <div class="goal-primary align-block">
            <div class="col-8 ion-float-left txtOverflow">
              <label class="title"> {{ meetingsHeaderModel.title}}</label>
            </div>
            <div class="col-4 ion-float-right">
              <div class="goal-secondary-new-goal ion-float-right">
                <label class="title">{{'GOAL' | translate}}</label>
                <span class="info">{{ getMeetingGoalValue() }}</span>
              </div>
              <div class="goal-secondary-new">
                <ion-button *ngIf="isFieldMandatory()" class="margin-none" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforcalls','indskr_suggestedcalls')">
                  <ion-icon slot="icon-only" name="chevron-back-outline" class="suggested-arrow-icon"></ion-icon>
                </ion-button>
                <ion-button [ngClass]="{ 'margin-none': true, 'disabledButton' : isFieldDisabled('indskr_commentsforcalls'), 'grayed-out-button': !hasComment('indskr_commentsforcalls') }" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforcalls','indskr_suggestedcalls')">
                  <ion-icon slot="icon-only" src="assets/imgs/customer-inquiry-active.svg"></ion-icon>
                </ion-button>
              </div>
             </div>
          </div>
          <div [ngClass]="{ 'goal-secondary dvPointer': true, 'disabledPointer' : isFieldDisabled('indskr_commentsforcalls') }" (click)="showAddSuggestedPopover('indskr_commentsforcalls','indskr_suggestedcalls')">
            <div class="goal-item">
              <div class="title small-text">{{'SUGGESTED' | translate}}
                  <ion-icon *ngIf="isFieldMandatory()" class='toggle mandatory-icon'></ion-icon>
              </div>
              <div class="value">
                <div class="completed" > {{ getMeetingSuggestedValue() }} </div>
              </div>
            </div>
          </div>
          <div class="goal-secondary">
              <div class="goal-item col-6">
                <div class="title small-text">{{'COMPLETED' | translate}}</div>
                <div class="value">
                  <div class="completed">{{ getMeetingCompletedValue() }} </div>
                </div>
              </div>
              <div class="goal-item col-6" *ngIf="!displaygoalsbeyond100">
                <div class="title small-text">{{'COMPLETION' | translate}}</div>
                <div class="value">
                  <div class="completed">{{ getMeetingCompletionValue() }}</div>
                </div>
              </div>
          </div>
        </div>
        <!-- ENGAGEMENTS -->
        <!-- MESSAGES -->
        <div class="goal" *ngIf="callPlanService.selectedRepCallPlan.totalGoalEmails">
            <div class="goal-primary align-block">
              <div class="col-8 ion-float-left txtOverflow">
                <label class="title"> {{ messagingHeaderModel.title}}</label>
              </div>
              <div class="col-4 ion-float-right">
                <div class="goal-secondary-new-goal ion-float-right">
                  <label class="title">{{'GOAL' | translate}}</label>
                  <span class="info">{{ getMessageGoalValue() }}</span>
                </div>
                <div class="goal-secondary-new">
                  <ion-button *ngIf="isFieldMandatory()" class="margin-none" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforemails','indskr_suggestedemails')">
                    <ion-icon slot="icon-only" name="chevron-back-outline" class="suggested-arrow-icon"></ion-icon>
                  </ion-button>
                  <ion-button [ngClass]="{ 'margin-none': true, 'disabledButton' : isFieldDisabled('indskr_commentsforemails'), 'grayed-out-button': !hasComment('indskr_commentsforemails') }" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforemails','indskr_suggestedemails')">
                    <ion-icon slot="icon-only" src="assets/imgs/customer-inquiry-active.svg"></ion-icon>
                  </ion-button>
                </div>
               </div>
            </div>
            <div [ngClass]="{ 'goal-secondary dvPointer': true, 'disabledPointer' : isFieldDisabled('indskr_commentsforemails') }" (click)="showAddSuggestedPopover('indskr_commentsforemails','indskr_suggestedemails')">
              <div class="goal-item">
                <div class="title small-text">{{'SUGGESTED' | translate}}
                    <ion-icon *ngIf="isFieldMandatory()" class='toggle mandatory-icon'></ion-icon>
                </div>
                <div class="value">
                  <div class="completed" > {{ getMessageSuggestedValue() }} </div>
                </div>
              </div>
            </div>
            <div class="goal-secondary">
                <div class="goal-item col-6">
                  <div class="title small-text">{{'COMPLETED' | translate}}</div>
                  <div class="value">
                    <div class="completed"> {{ getMessageCompletedValue() }} </div>
                  </div>
                </div>
                <div class="goal-item col-6" *ngIf="!displaygoalsbeyond100">
                  <div class="title small-text">{{'COMPLETION' | translate}}</div>
                  <div class="value">
                    <div class="completed"> {{ getMessageCompletionValue() }} </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- MESSAGES -->
        <!-- F2F_MEETINGS -->
        <div class="goal" *ngIf="callPlanService.selectedRepCallPlan.totalGoalF2FMeetings">
          <div class="goal-primary align-block">
            <div class="col-8 ion-float-left txtOverflow">
            <label class="title"> {{ F2FMeetingHeaderModel.title}}</label>
            </div>
            <div class="col-4 ion-float-right">
            <div class="goal-secondary-new-goal ion-float-right">
              <label class="title">{{'GOAL' | translate}}</label>
              <span class="info"> {{ getF2FMeetingGoalValue() }}</span>
            </div>
            <div class="goal-secondary-new">
              <ion-button *ngIf="isFieldMandatory()" class="margin-none" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforf2fmeetings','indskr_suggestedf2fmeetings')">
                <ion-icon slot="icon-only" name="chevron-back-outline" class="suggested-arrow-icon"></ion-icon>
              </ion-button>
              <ion-button [ngClass]="{ 'margin-none': true, 'disabledButton' : isFieldDisabled('indskr_commentsforf2fmeetings'), 'grayed-out-button': !hasComment('indskr_commentsforf2fmeetings') }" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforf2fmeetings','indskr_suggestedf2fmeetings')">
                <ion-icon slot="icon-only" src="assets/imgs/customer-inquiry-active.svg"></ion-icon>
              </ion-button>
            </div>
            </div>
          </div>
          <div [ngClass]="{ 'goal-secondary dvPointer': true, 'disabledPointer' : isFieldDisabled('indskr_commentsforf2fmeetings') }" (click)="showAddSuggestedPopover('indskr_commentsforf2fmeetings','indskr_suggestedf2fmeetings')">
            <div class="goal-item">
              <div class="title small-text">{{'SUGGESTED' | translate}}
                  <ion-icon *ngIf="isFieldMandatory()" class='toggle mandatory-icon'></ion-icon>
              </div>
              <div class="value">
                <div class="completed" > {{ getF2FMeetingSuggestedValue() }} </div>
              </div>
            </div>
          </div>
          <div class="goal-secondary">
              <div class="goal-item col-6">
                <div class="title small-text">{{'COMPLETED' | translate}}</div>
                <div class="value">
                  <div class="completed"> {{ getF2FMeetingCompletedValue() }} </div>
                </div>
              </div>
              <div class="goal-item col-6" *ngIf="!displaygoalsbeyond100">
                <div class="title small-text">{{'COMPLETION' | translate}}</div>
                <div class="value">
                  <div class="completed"> {{ getF2FMeetingCompletionValue() }} </div>
                </div>
              </div>
          </div>
       </div>
        <!-- F2F_MEETINGS -->
        <!-- REMOTE_MEETINGS -->
         <div class="goal" *ngIf="callPlanService.selectedRepCallPlan.totalGoalRemoteMeetings">
          <div class="goal-primary align-block">
            <div class="col-8 ion-float-left txtOverflow">
            <label class="title"> {{ remoteMeetingHeaderModel.title}}</label>
            </div>
            <div class="col-4 ion-float-right">
            <div class="goal-secondary-new-goal ion-float-right">
              <label class="title">{{'GOAL' | translate}}</label>
              <span class="info"> {{ getRemoteMeetingGoalValue() }}</span>
            </div>
            <div class="goal-secondary-new">
              <ion-button *ngIf="isFieldMandatory()" class="margin-none" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforremotemeetings','indskr_suggestedremotemeetings')">
                <ion-icon slot="icon-only" name="chevron-back-outline" class="suggested-arrow-icon"></ion-icon>
              </ion-button>
              <ion-button [ngClass]="{ 'margin-none': true, 'disabledButton' : isFieldDisabled('indskr_commentsforremotemeetings'), 'grayed-out-button': !hasComment('indskr_commentsforremotemeetings') }" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforremotemeetings','indskr_suggestedremotemeetings')">
                <ion-icon slot="icon-only" src="assets/imgs/customer-inquiry-active.svg"></ion-icon>
              </ion-button>
            </div>
            </div>
          </div>
          <div [ngClass]="{ 'goal-secondary dvPointer': true, 'disabledPointer' : isFieldDisabled('indskr_commentsforremotemeetings') }" (click)="showAddSuggestedPopover('indskr_commentsforremotemeetings','indskr_suggestedremotemeetings')">
            <div class="goal-item">
              <div class="title small-text">{{'SUGGESTED' | translate}}
                  <ion-icon *ngIf="isFieldMandatory()" class='toggle mandatory-icon'></ion-icon>
              </div>
              <div class="value">
                <div class="completed" > {{ getRemoteMeetingSuggestedValue() }} </div>
              </div>
            </div>
          </div>
          <div class="goal-secondary">
              <div class="goal-item col-6">
                <div class="title small-text">{{'COMPLETED' | translate}}</div>
                <div class="value">
                  <div class="completed"> {{ getRemoteMeetingCompletedValue() }} </div>
                </div>
              </div>
              <div class="goal-item col-6" *ngIf="!displaygoalsbeyond100">
                <div class="title small-text">{{'COMPLETION' | translate}}</div>
                <div class="value">
                  <div class="completed"> {{ getRemoteMeetingCompletionValue() }} </div>
                </div>
              </div>
          </div>
       </div>
        <!-- REMOTE_MEETINGS -->
         <!-- PHONE_CALLS -->
         <div class="goal" *ngIf="callPlanService.selectedRepCallPlan.totalGoalPhoneCalls">
          <div class="goal-primary align-block">
            <div class="col-8 ion-float-left txtOverflow">
            <label class="title"> {{ phoneCallHeaderModel.title}}</label>
            </div>
            <div class="col-4 ion-float-right">
            <div class="goal-secondary-new-goal ion-float-right">
              <label class="title">{{'GOAL' | translate}}</label>
              <span class="info">{{ getPhoneCallGoalValue() }}</span>
            </div>
            <div class="goal-secondary-new">
              <ion-button *ngIf="isFieldMandatory()" class="margin-none" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforphonecallmeetings','indskr_suggestedphonecallmeetings')">
                <ion-icon slot="icon-only" name="chevron-back-outline" class="suggested-arrow-icon"></ion-icon>
              </ion-button>
              <ion-button [ngClass]="{ 'margin-none': true, 'disabledButton' : isFieldDisabled('indskr_commentsforphonecallmeetings'), 'grayed-out-button': !hasComment('indskr_commentsforphonecallmeetings') }" fill="clear" size="small" (click)="showAddSuggestedPopover('indskr_commentsforphonecallmeetings','indskr_suggestedphonecallmeetings')">
                <ion-icon slot="icon-only" src="assets/imgs/customer-inquiry-active.svg"></ion-icon>
              </ion-button>
            </div>
            </div>
          </div>
          <div [ngClass]="{ 'goal-secondary dvPointer': true, 'disabledPointer' : isFieldDisabled('indskr_commentsforphonecallmeetings') }" (click)="showAddSuggestedPopover('indskr_commentsforphonecallmeetings','indskr_suggestedphonecallmeetings')">
            <div class="goal-item">
              <div class="title small-text">{{'SUGGESTED' | translate}}
                  <ion-icon *ngIf="isFieldMandatory()" class='toggle mandatory-icon'></ion-icon>
              </div>
              <div class="value">
                <div class="completed" > {{ getPhoneCallSuggestedValue() }} </div>
              </div>
            </div>
          </div>
          <div class="goal-secondary">
              <div class="goal-item col-6">
                <div class="title small-text">{{'COMPLETED' | translate}}</div>
                <div class="value">
                  <div class="completed"> {{ getPhoneCallCompletedValue() }} </div>
                </div>
              </div>
              <div class="goal-item col-6" *ngIf="!displaygoalsbeyond100">
                <div class="title small-text">{{'COMPLETION' | translate}}</div>
                <div class="value">
                  <div class="completed"> {{ getPhoneCallCompletionValue() }} </div>
                </div>
              </div>
          </div>
       </div>
        <!-- PHONE_CALLS -->
      </div>
      <!-- =============================Account Call Plan - Overview============================= -->
    </ion-item-group>
    <!-- =============================Account Call Plan - Coverage============================= -->
    <ion-item-group *ngIf="otherRepCallPlans && otherRepCallPlans.length > 0">
      <ind-section-header [viewData]="coverageHeaderModel"></ind-section-header>
      <div class="coverage" *ngIf="otherRepCallPlans && otherRepCallPlans.length > 0">
        <div class="item" *ngFor="let otherRepCallPlan of otherRepCallPlans">
          <div class="item-primary">
            <label class="title" *ngIf="otherRepCallPlan.accountName"> {{ otherRepCallPlan.accountName }}</label>
            <label class="title" *ngIf="otherRepCallPlan.contactFirstName && otherRepCallPlan.contactLastName"> {{ otherRepCallPlan.contactFirstName + ' ' + otherRepCallPlan.contactLastName }}</label>
            <label class="title" *ngIf="otherRepCallPlan.contactFirstName && !otherRepCallPlan.contactLastName"> {{ otherRepCallPlan.contactFirstName }}</label>
            <label class="title" *ngIf="!otherRepCallPlan.contactFirstName && otherRepCallPlan.contactLastName"> {{ otherRepCallPlan.contactLastName }}</label>
            <span class="percentage info"> {{ (otherRepCallPlan.indskr_meetingcompletionpercent || 0) + '%' }}</span>
          </div>
          <div class="item-secondary">
            <label class="title"> {{'STATUS' | translate}}  - {{ otherRepCallPlan.statuscode | repCallPlanState }}</label>
          </div>
          <div class="item-secondary">
            <label class="title">{{ otherRepCallPlan.positionName }}</label>
          </div>
        </div>
      </div>
    </ion-item-group>
    <!-- =============================Account Call Plan - Coverage============================= -->
    <!-- <<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> -->
    <ion-item-group *ngIf="isCallPlanActivitiesEnabled">
      <ind-section-header [viewData]="activitiesHeaderModel"></ind-section-header>
      <customer-call-plan-timeline></customer-call-plan-timeline>
    </ion-item-group>
    <!-- <<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> -->

  </ion-list>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'customercallplan'"></footer-toolbar>