import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@omni/services/events/events.service';
import _ from 'lodash';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { CustomerInterest, CustomerJourney, CustomerRelationship, TrendingCustomer } from '../../../classes/xperiences/trending.customer.class';
import { RepServices } from '../../../data-services/rep/rep.services';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { AlertService } from '../../../services/alert/alert.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import {GlobalUtilityService} from '../../../services/global-utility.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { XperiencesService } from '../../../services/xperiences/xperiences.service';
import { CustomerSelectionMode } from '../../../utility/util';
import { ContactPageComponent } from '../contact-page/contact-page';
import { CustomerInterestComponent } from './customer-interest/customer-interest';
import { CustomerJourneyComponent } from './customer-journey/customer-journey';
import { CustomerRelationshipComponent } from './customer-relationship/customer-relationship';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the CustomerInsightComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'customer-insight',
  templateUrl: 'customer-insight.html',
  styleUrls:['customer-insight.scss']
})
export class CustomerInsightComponent implements OnInit {

  public mode = CustomerSelectionMode;
  public disableContactSelection = false;
  public insightsDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public insightsJourneyHeaderModel: IndSectionHeaderViewDataModel;
  public insightsInterestsHeaderModel: IndSectionHeaderViewDataModel;
  public insightsRelationshipHeaderModel: IndSectionHeaderViewDataModel;
  public title: IndPageTitleViewDataModel;
  readOnly: boolean;
  private isClickedContacts: boolean = false;
  private keepMandatoryFlag: string;
  private isNothingSelected: boolean = true;

  constructor(
    public device: DeviceService,
    private readonly uiService: UIService,
    public readonly footerService: FooterService,
    private readonly contactService: ContactOfflineService,
    public xpService: XperiencesService,
    private readonly navService: NavigationService,
    private readonly loadingCtrl: LoadingController,
    private readonly alertService: AlertService,
    private readonly navParams: NavParams,
    public utilityService: GlobalUtilityService,
    private readonly translate: TranslateService,
    private readonly notificationService: NotificationService,
    public repService: RepServices,
    private readonly trackingService: TrackService,
    public events: EventsService,
    private activityService: ActivityService
    ) {
      this.initializeHeaders();
  }

  ngOnInit() {
    this.footerService.initButtons(FooterViews.None);
    this.keepMandatoryFlag = '';

    if (this.xpService.newInsight.contactid) this.disableContactSelection = true;
    this.initActivitiesDetailsPageTitle();

    this.subscribeToEvents();

  }
  private subscribeToEvents() {
    this.events.subscribe('insights_contact_assignment', () => {
      this.initializeHeaders();
    });

    this.events.subscribe('insights-journey-created', () => {
      if((this.xpService.newInsight.journeys && this.xpService.newInsight.journeys.length > 0) 
      && (!this.xpService.newInsight.interests || this.xpService.newInsight.interests.length == 0) 
      && (!this.xpService.newInsight.relationships || this.xpService.newInsight.relationships.length == 0)) {
        this.keepMandatoryFlag = 'insights-journey';
        this.isNothingSelected = false;
      }
      this.initActivitiesDetailsPageTitle();
      this.initializeHeaders();
    });

    this.events.subscribe('insights-interests-created', () => {
      if((!this.xpService.newInsight.journeys || this.xpService.newInsight.journeys.length == 0) 
      && (this.xpService.newInsight.interests && this.xpService.newInsight.interests.length > 0) 
      && (!this.xpService.newInsight.relationships || this.xpService.newInsight.relationships.length == 0)) {
        this.keepMandatoryFlag = 'insights-interests';
        this.isNothingSelected = false;
      }
      this.initActivitiesDetailsPageTitle();
      this.initializeHeaders();
    });

    this.events.subscribe('insights-relationship-created', () => {
      if((!this.xpService.newInsight.journeys || this.xpService.newInsight.journeys.length == 0) 
      && (!this.xpService.newInsight.interests || this.xpService.newInsight.interests.length == 0) 
      && (this.xpService.newInsight.relationships && this.xpService.newInsight.relationships.length > 0)) {
        this.keepMandatoryFlag = 'insights-relationship';
        this.isNothingSelected = false;
      }
      this.initActivitiesDetailsPageTitle();
      this.initializeHeaders();
    });

    this.events.subscribe('updateMandatoryField', (field) => {
      if(field == PageName.ContactPageComponent) {
        this.isClickedContacts = true;
      }
    })
  }

  ngOnDestroy() {
    this.events.unsubscribe('updateMandatoryField');
  }

  private initializeHeaders() {
    this.intializeInsightsDetailsHeader();
    this.intialiseInsightsJourneyHeder();
    this.intialiseInsightsInterestsHeder();
    this.intialiseInsightsRelationshipsHeder();
  }

  private intializeInsightsDetailsHeader() {

    this.insightsDetailsHeaderModel = {
      id: 'insights-details-header',
      title: this.translate.instant('DETAILS'),
      controls: [],
    };
  }

  private intialiseInsightsJourneyHeder() {
    this.insightsJourneyHeaderModel = {
      id: 'insights-journey-header',
      title: this.translate.instant('CONTACT_JOURNEY_INSIGHT'),
      isRequired: this.xpService.newInsight.contactid && (this.isNothingSelected || this.keepMandatoryFlag == 'insights-journey'),
      controls: [{
          id:'insights_journey_add',
          text:this.translate.instant('ADD'),
          isDisabled: !this.xpService.newInsight.contactid ? true: false,
        }],
    };
  }

  private intialiseInsightsInterestsHeder() {
    this.insightsInterestsHeaderModel = {
      id: 'insights-Interests-header',
      title: this.translate.instant('CONTACT_INTEREST_INSIGHTS'),
      isRequired: this.xpService.newInsight.contactid && (this.isNothingSelected || this.keepMandatoryFlag == 'insights-interests'),
      controls: [{
          id:'insights_interests_add',
          text:this.translate.instant('ADD'),
          isDisabled: !this.xpService.newInsight.contactid ? true: false,
        }],
    };
  }

  private intialiseInsightsRelationshipsHeder() {
    this.insightsRelationshipHeaderModel = {
      id: 'insights-relationship-header',
      title: this.translate.instant('CONTACT_RELATIONSHIP_INSIGHTS'),
      isRequired: this.xpService.newInsight.contactid && (this.isNothingSelected || this.keepMandatoryFlag == 'insights-relationship'),
      controls: [{
          id:'insights_relationships_add',
          text:this.translate.instant('ADD'),
          isDisabled:!this.xpService.newInsight.contactid ? true: false,
        }],
    };
  }

 public get customerFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomersText),
      inputText: _.isEmpty(this.xpService.newInsight.fullname) ? '' : this.xpService.newInsight.fullname,
      customPlaceholderLabel: this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', {globalCustomerText :this.utilityService.globalCustomersText}),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.disableContactSelection,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: !this.disableContactSelection,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedContacts && _.isEmpty(this.xpService.newInsight.fullname),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'customer-field':
          this.gotoContactPage(this.mode.FOR_INSIGHT);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  onSectionHeaderControlClick(id: string) {
    switch(id){
      case 'insights_journey_add':
        this.addJourney(null);
        break;
      case 'insights_interests_add':
        this.addInterest(null);
        break;
      case 'insights_relationships_add':
        this.addRelationship(null);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }
  public gotoContactPage(mode: CustomerSelectionMode) {
    if (this.disableContactSelection) return;
    this.trackingService.tracking('XperiencesInsights+SelectCustomer', TrackingEventNames.INSIGHTSPLUS);
    this.isClickedContacts = true;
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.CustomerInsightComponent;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { type: mode, contactId: this.xpService.newInsight.contactid }, PageName.ContactPageComponent);
  }

  public addJourney(journey: CustomerJourney) {
    this.trackingService.tracking('XperiencesInsgihts+JourneyAdd', TrackingEventNames.INSIGHTSPLUS);
    this.navService.pushChildNavPageWithPageTracking(CustomerJourneyComponent, PageName.CustomerJourneyComponent, PageName.ActivitiesPageComponent, { journey });
  }

  public addInterest(interest: CustomerInterest) {
    this.trackingService.tracking('XperiencesInsgihts+InterestAdd', TrackingEventNames.INSIGHTSPLUS);
    this.navService.pushChildNavPageWithPageTracking(CustomerInterestComponent, PageName.CustomerInterestComponent, PageName.ActivitiesPageComponent, { interest });
  }

  public addRelationship(relationship: CustomerRelationship) {
    this.trackingService.tracking('XperiencesInsgihts+RelationshipAdd', TrackingEventNames.INSIGHTSPLUS);
    this.navService.pushChildNavPageWithPageTracking(CustomerRelationshipComponent, PageName.CustomerRelationshipComponent, PageName.ActivitiesPageComponent, { relationship });
  }

  public async closepage(create: boolean) {
    const customer: TrendingCustomer = this.xpService.newInsight;
    if (create) {
      if (this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('CI_DEVICE_SHOULD_ONLINE'),'Customer Insight');
        return;
      }
      if (customer.contactid && (customer.journeys.length || customer.interests.length || customer.relationships.length)) {
        this.trackingService.tracking('XperiencesInsights+Done', TrackingEventNames.INSIGHTSPLUS);
        const loader = await this.loadingCtrl.create();
        loader.present();
        await this.createCustomerInsight(customer, loader);
      }
    } else {
      if (this.xpService.unSavedChanges && !this.xpService.shouldSkipDiscardAlert()) {
        this.alertService.showAlert({
          title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
          message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')}, this.translate.instant('DISCARD')
        ).then (res => {
          if(res.role === 'ok') {
            this.xpService.isFromCustomerXperience = false;
            // this.xpService.enableInsight = true;
            if(this.navParams.data && this.navParams.data.from === PageName.ActivitiesPageComponent ) {
              this.uiService.showRightPane = false;
            } else {
              this.uiService.showRightPane = (this.navParams.data && this.navParams.data.from) === PageName.GeneralInsightsPlusComponent || this.xpService.selectedXpContact !== null || this.xpService.selectedXpContact !== undefined;
            }

            this.navService.popChildNavPageWithPageTracking().then(() => {
              setTimeout(() => {
                this.xpService.newInsight = null;
                if ((this.navParams.data && this.navParams.data.from) === PageName.ContactPageComponent) {
                  this.uiService.showRightPane = false;
                  this.navService.popChildNavPageWithPageTracking();
                } else if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
                  this.uiService.activeView = null;
                  this.events.publish('SegmentChnaged');
                }
              }, 10);
            });
          }
        });
      } else {
        this.xpService.isFromCustomerXperience = false;
        // this.xpService.enableInsight = true;
        if ((this.navParams.data && this.navParams.data.from) === PageName.GeneralInsightsPlusComponent || this.xpService.selectedXpContact) {
          this.uiService.showRightPane = true;
        } else {
          this.uiService.showRightPane = false;
        }
        this.navService.popChildNavPageWithPageTracking().then(() => {
          setTimeout(() => {
            this.xpService.newInsight = null;
            if ((this.navParams.data && this.navParams.data.from) === PageName.ContactPageComponent) {
              // this.uiService.setActiveView('contactDetails');
              this.navService.popChildNavPageWithPageTracking();
            } else if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
              this.uiService.activeView = null;
              this.events.publish('SegmentChnaged');
            }
          }, 10);
        });
      }
    }
  }

  private async createCustomerInsight(customer: TrendingCustomer, loader: HTMLIonLoadingElement) {
    await this.xpService.createCustomerInsight(customer).then(() => {
      this.xpService.enableInsight = true;
      if ((this.navParams.data && this.navParams.data.from) === PageName.GeneralInsightsPlusComponent || this.xpService.selectedXpContact) {
        this.uiService.showRightPane = true;
      } else {
        this.uiService.showRightPane = false;
      }
      this.navService.popChildNavPageWithPageTracking().then(() => {
        setTimeout(() => {
          this.xpService.newInsight = null;
          if ((this.navParams.data && this.navParams.data.from) === PageName.ContactPageComponent) {
            // this.uiService.setActiveView('contactDetails');
            this.navService.popChildNavPageWithPageTracking();
          } else if((this.navParams.data && this.navParams.data.from) === PageName.ActivitiesPageComponent) {
            this.uiService.activeView = null;
            this.events.publish('SegmentChnaged');
          }
        }, 10);
      });
      loader.dismiss();
      this.notificationService.notify(this.translate.instant('CI_HAS_CREATED', { cfullname: customer.fullname }), 'Customer Insight');
    }, err => {
      loader.dismiss();
      console.log('Error occurred while creating customer insight', err);
    });
  }

  private initActivitiesDetailsPageTitle(): void {
    this.title = {
      id: 'customer-insight',
      title: this.translate.instant('XPERIENCES_INSIGHT'),
      controls: [{
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'right'
      },
      {
        id: 'done',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: !this.xpService.newInsight.contactid || (this.xpService.newInsight.journeys.length === 0 &&
          this.xpService.newInsight.relationships.length === 0 && this.xpService.newInsight.interests.length === 0),
        align: 'right'
      }]
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.closepage(false);
        break;
      case 'close':
        this.closepage(false);
        break;
      case 'done':
        this.closepage(true);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public getJourneyMainCardDataModel(journey: CustomerJourney) {
    let viewData: MainCardViewDataModel;
    if (journey) {
      viewData = {
        id: `${journey.description}-${journey.date}`,
        fixedHeight: true,
        primaryTextLeft: null,
        primaryTextRight: journey.description,
        secondaryTextRight: null,
        secondaryTextRightTwo: null,
        isSelected: false,
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event, specificTarget) => {
          this.addJourney(journey);
        }
      };
      return viewData;
    }
  }

  public getInterestsMainCardDataModel(interest: CustomerInterest) {
    let viewData: MainCardViewDataModel;
    if (interest) {
      viewData = {
        id: 'interest-main-card',
        fixedHeight: true,
        primaryTextLeft: null,
        primaryTextRight: interest.selectedCategory ? interest.selectedCategory.title : interest.description,
        secondaryTextRight: null,
        secondaryTextRightTwo: null,
        isSelected: false,
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event, specificTarget) => {
          this.addInterest(interest);
        }
      };
      return viewData;
    }
  }

  public getRelationshipsMainCardDataModel(relationship: CustomerRelationship) {
    let viewData: MainCardViewDataModel;
    if (relationship) {
      viewData = {
        id: 'relationship-main-card',
        fixedHeight: true,
        primaryTextLeft: null,
        primaryTextRight: relationship.fullname ? relationship.fullname : '',
        secondaryTextRight: null,
        secondaryTextRightTwo: null,
        isSelected: false,
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event, specificTarget) => {
          this.addRelationship(relationship);
        }
      };
      return viewData;
    }
  }
}
