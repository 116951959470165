import { AppointmentActivity } from './../../classes/activity/appointment.activity.class';
import { MeetingAsset } from './../../classes/field-materials/meeting-asset-mapping';
import * as moment from "moment";
import { AssetTrasferStatus } from './../../classes/field-materials/asset-transfer.class';
import { Injectable } from '@angular/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { CustomerAssetNote, CustomerAssetTransferNote } from "@omni/classes/field-materials/asset-notes.class";
import { AssetTransfer } from "@omni/classes/field-materials/asset-transfer.class";
import {
  AccountManagerUser,
  AMConfig, AssetCategory,
  AssetStatusPickList,
  CustomerAsset, LocationContract
} from "@omni/classes/field-materials/customer-asset.class";
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import {
  DB_ALLDOCS_QUERY_OPTIONS,
  DB_KEY_PREFIXES,
  DB_SYNC_STATE_KEYS
} from '@omni/config/pouch-db.config';
import {
  DeltaService,
  EntityNames,
  EntitySyncInfo
} from '@omni/data-services/delta/delta.service';
import { FieldMaterialManagementDataService } from '@omni/data-services/field-material-management/field-material-management.data.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DeviceService } from '@omni/services/device/device.service';
import { DiskService } from "@omni/services/disk/disk.service";
import _ from 'lodash';
import { IONote } from '@omni/classes/io/io-note.class';

@Injectable({
  providedIn: 'root',
})
export class FieldMaterialManagementService {

  public customerAssets: CustomerAsset[] = [];
  public assetTransfers: AssetTransfer[] = [];
  public assetNotes: CustomerAssetNote[] = [];
  public meetingAssets: MeetingAsset[] = [];
  public assetStatus: AssetStatusPickList[] = [];
  public selectedAsset: CustomerAsset;
  public selectedAssetTransfer: AssetTransfer;
  public amConfig: AMConfig = null;
  public amUsers: AccountManagerUser[] = [];
  public locationContracts: LocationContract[] = [];
  public customerAssetsForAssetBooking: CustomerAsset[] = [];

  constructor(
    private readonly authService: AuthenticationService,
    private readonly disk: DiskService,
    private readonly deltaService: DeltaService,
    private readonly device: DeviceService,
    private readonly fieldMaterialManagementDataService: FieldMaterialManagementDataService,
  ) { }

  public getAssetStatus(): Array<AssetStatusPickList> {
    if (this.assetStatus && this.assetStatus.length == 0) {
      this.assetStatus = [{ assetStatusKey: 548910000, assetStatusValue: "Inactive" }, { assetStatusKey: 548910001, assetStatusValue: "Obsolete" }, { assetStatusKey: 548910002, assetStatusValue: "Active" },
      { assetStatusKey: 548910003, assetStatusValue: "Damaged" }, { assetStatusKey: 548910004, assetStatusValue: "Lost by Sales Rep" }, { assetStatusKey: 548910005, assetStatusValue: "Lost by Hospital" }, { assetStatusKey: 548910006, assetStatusValue: "In Repair" }];
    }
    return this.assetStatus;
  }

  public async updateCustomerAsserDetail(payload, assetId: string, assetStatusTitle: string) {
    await this.fieldMaterialManagementDataService.updateCustomerAsset(assetId, payload).then(data => {
      const ind = this.customerAssets.findIndex(ast => ast.msdyn_customerassetid === assetId);
      if (ind !== -1) {
        let locAsset = this.customerAssets[ind];
        locAsset.indskr_assetstatus = assetStatusTitle;
        this.saveDeltaCustomerAssetsToDisk(this.customerAssets, null);
      }
    }).catch(err => console.error(err));
  }

  public async syncAssetsMasterData(forceFullSync = false, loadFromDBOnly = false) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.FIELD_MATERIAL_MANAGEMENT) ||
      (this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG) && this.authService.user.hasTagAssetsToProcedureLog)) {
      if (loadFromDBOnly || this.device.isOffline) {
        Promise.all([
          this.loadAssetsFromDB().then(() => {
            this.loadAssetsAMsFromDB(),
              this.loadNotesFromDB()
          }),
          this.loadTransfersFromDB()
        ]);
        if (this.authService.hasFeatureAction(FeatureActionsMap.TAG_ASSETS_TO_MEETINGS)) {
          await this.loadMeetingAssetsFromDB();
        }
      } else {
        Promise.all([
          this.getCustomerAssets(forceFullSync).then(() => {
            this.getAccountManagerConfig().then(() => {
              this.getAccountManagerUsers()
            })
          }),
          this.getAssetTransfers(forceFullSync),
        ]);
        if (this.authService.hasFeatureAction(FeatureActionsMap.TAG_ASSETS_TO_MEETINGS)) {
          this.getMeetingAssetMappings(forceFullSync = false);
        }
      }
    }
  }

  public async loadAssetsAMsFromDB() {
    // Fetch Asset Account Managers from db
    await this.disk
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_MANAGERS)
      .then((data: AccountManagerUser[]) => {
        if (!_.isEmpty(data)) {
          this.amUsers = data;
          console.log(
            `Asset Account Managers from disk : ${
            this.amUsers ? this.amUsers.length : 0
            }`
          );
        }
      });
  }

  public async loadAssetsFromDB() {
    // Fetch Assets from db
    await this.disk
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSETS)
      .then((data: CustomerAsset[]) => {
        if (!_.isEmpty(data)) {
          this.customerAssets = data;
          console.log(
            `Customer Assets from disk : ${
            this.customerAssets ? this.customerAssets.length : 0
            }`
          );
        }
      });
  }

  public async loadNotesFromDB() {
    // Fetch Notes from db
    await this.disk
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ASSET_NOTES)
      .then((data: CustomerAssetNote[]) => {
        if (!_.isEmpty(data)) {
          this.assetNotes = data;
          console.log(
            `Asset Notes from disk : ${
            this.assetNotes ? this.assetNotes.length : 0
            }`
          );
        }
      });
  }

  public async loadTransfersFromDB() {
    // Fetch Assets from db
    await this.disk
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ASSET_TRANSFERS)
      .then((data: AssetTransfer[]) => {
        if (!_.isEmpty(data)) {
          this.assetTransfers = data;
          console.log(
            `Asset Transfers from disk : ${
            this.assetTransfers ? this.assetTransfers.length : 0
            }`
          );
        }
      });
  }

  public async loadMeetingAssetsFromDB() {
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MEETING_ASSETS).then((data: MeetingAsset[]) => {
      if (!_.isEmpty(data)) {
        this.meetingAssets = data;
        console.log(`Meeting Assets from disk : ${this.meetingAssets.length}`);
      }
    });
  }

  private async getAccountManagerConfig() {
    let fetchXml = fetchQueries.fetchIoConfigurations;
    fetchXml = fetchXml.replace(
      '{filter}',
      '<filter type="and"><condition attribute="indskr_configname" operator="eq" value="accountManagerRoles" /></filter>',
    );
    await this.fieldMaterialManagementDataService
      .getAMConfig(fetchXml)
      .then(async (data: AMConfig[]) => {
        if (!_.isEmpty(data)) this.amConfig = data[0];
      }).catch((err) => {
        console.error('Error occurred while fetching AM IO Config...', err);
      });
  }

  public async getLocationContracts(
    newLocation: string,
    startDate: string,
    endDate: string
  ) {
    this.locationContracts = [];
    let fetchXml = fetchQueries.fetchContracts;
    fetchXml = fetchXml.replace('{startDate}', startDate)
      .replace('{endDate}', endDate)
      .replace('{newLocation}', newLocation);
    await this.fieldMaterialManagementDataService
      .getLocationContracts(fetchXml)
      .then(async (data: LocationContract[]) => {
        if (!_.isEmpty(data)) {
          data.forEach(d => {
            this.locationContracts.push(new LocationContract(d));
          })
        }
      }).catch((err) => {
        console.error('Error occurred while fetching AM IO Config...', err);
      });
  }

  private async getAccountManagerUsers() {
    if (!(this.amConfig && this.amConfig.indskr_configvalue)) return;
    await this.disk.deleteAllFromDbUsingAlldocsQuery(
      DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_MANAGERS
    );
    let fetchXml = fetchQueries.fetchAccountManagerUsers;
    const roleIds = _.uniq(
      _.compact(this.amConfig.indskr_configvalue.split(','))
    );
    let roleIdString = '';
    if (!_.isEmpty(roleIds)) {
      roleIds.forEach(p => {
        roleIdString += '<value>' + p + '</value>';
      })
    }
    fetchXml = fetchXml.replace('{1}', roleIdString);
    await this.fieldMaterialManagementDataService
      .getAccountManagerUsers(fetchXml)
      .then(async (data: AccountManagerUser[]) => {
        if (!_.isEmpty(data)) await this.saveAccountManagersToDisk(data);
      }).catch((err) => {
        console.error('Error occurred while fetching AM IO Config...', err);
      });
  }

  private async getCustomerAssets(forceFullSync: boolean) {
    let assetFetchXml = fetchQueries.fetchMyAssets;
    let assetNotesFetchXml = fetchQueries.fetchAssetNotes;

    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_ASSETS
    );
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();

    const customerAssetsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerAssets,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true,
    };
    assetFetchXml = assetFetchXml.replace('{DeltaById}', '');
    if (isInitialSync) {
      assetFetchXml = assetFetchXml.replace('{DeltaCondition}', '');
      assetNotesFetchXml = assetNotesFetchXml.replace('{DeltaCondition}', '');
      this.customerAssets = [];
      await this.disk.deleteAllFromDbUsingAlldocsQuery(
        DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSETS
      );
    } else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="${new Date(syncState.lastUpdatedTime).toISOString()}" />`;
      assetFetchXml = assetFetchXml.replace('{DeltaCondition}', deltaSyncFilter);
      assetNotesFetchXml = assetNotesFetchXml.replace('{DeltaCondition}', deltaSyncFilter);
      await this.loadAssetsFromDB();
    }
    Promise.all([
      this.fieldMaterialManagementDataService.getCustomerAssets(assetFetchXml),
      this.fieldMaterialManagementDataService.getAssetNotes(assetNotesFetchXml),
    ]).then(async (data: any[]) => {
      console.log('Saving asset transfers to disk...', data);
      if (isInitialSync) {
        await this.saveCustomerAssetsToDisk(data[0], data[1]);
      } else {
        await this.saveDeltaCustomerAssetsToDisk(data[0], data[1]);
      }
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(data)) {
        customerAssetsSyncInfo.totalSynced = data.length;
      }
      this.deltaService.addEntitySyncInfo(customerAssetsSyncInfo);
    }).catch((err) => {
      console.error('Error occurred while fetching asset transfers...', err);
      this.deltaService.addSyncErrorToEntitySyncInfo(customerAssetsSyncInfo, 'msdyn_customerassets', err);
      this.deltaService.addEntitySyncInfo(customerAssetsSyncInfo);
    });
  }

  public async getAssetTransfers(forceFullSync: boolean) {
    let transferRequestsfetchXml = fetchQueries.fetchTransferRequests;
    let transferRequestNotesfetchXml = fetchQueries.fetchAssetTransferNotes;

    const syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_ASSET_TRANSFERS
    );
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();

    const transfersSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.assetTransfers,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true,
    };
    transferRequestsfetchXml = transferRequestsfetchXml.replace('{DeltaById}', '');
    transferRequestNotesfetchXml = transferRequestNotesfetchXml.replace('{DeltaById}', '');
    if (isInitialSync) {
      transferRequestsfetchXml = transferRequestsfetchXml.replace('{DeltaCondition}', '');
      transferRequestsfetchXml = transferRequestsfetchXml.replace('{InitialSyncCondition}', '<condition attribute="statuscode" operator="ne" value="548910003" />');
      transferRequestNotesfetchXml = transferRequestNotesfetchXml.replace('{DeltaCondition}', '');
      this.assetTransfers = [];
      await this.disk.deleteAllFromDbUsingAlldocsQuery(
        DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ASSET_TRANSFERS
      );
    } else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="${new Date(syncState.lastUpdatedTime).toISOString()}" />`;
      transferRequestsfetchXml = transferRequestsfetchXml.replace('{DeltaById}', '').replace('{DeltaCondition}', deltaSyncFilter);
      transferRequestsfetchXml = transferRequestsfetchXml.replace('{InitialSyncCondition}', '');
      transferRequestNotesfetchXml = transferRequestNotesfetchXml.replace('{DeltaById}', '').replace('{DeltaCondition}', deltaSyncFilter);
      await this.loadTransfersFromDB();
    }
    Promise.all([
      this.fieldMaterialManagementDataService.getAssetTransfers(transferRequestsfetchXml),
      this.fieldMaterialManagementDataService.getAssetTransferNotes(transferRequestNotesfetchXml),
    ]).then(async (data: any[]) => {
      console.log('Saving asset transfers to disk...', data);
      if (isInitialSync) {
        await this.saveAssetTransfersToDisk(data[0], data[1]);
      } else {
        await this.saveDeltaAssetTransfersToDisk(data[0], data[1]);
      }
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(data)) {
        transfersSyncInfo.totalSynced = data.length;
      }
      this.deltaService.addEntitySyncInfo(transfersSyncInfo);
    }).catch((err) => {
      console.error('Error occurred while fetching asset transfers...', err);
      this.deltaService.addSyncErrorToEntitySyncInfo(transfersSyncInfo, 'indskr_assettransfers', err);
      this.deltaService.addEntitySyncInfo(transfersSyncInfo);
    });
  }

  public async getAssetTransferById(assetTransferId: string) {
    if (!this.device.isOffline) {
      let transferRequestsfetchXml = fetchQueries.fetchTransferRequests;
      let transferRequestNotesfetchXml = fetchQueries.fetchAssetTransferNotes;
      const deltaByIdFilter = `<condition attribute="indskr_assettransferid" operator="eq" value="${assetTransferId}" />`;
      transferRequestsfetchXml = transferRequestsfetchXml.replace('{DeltaCondition}', '').replace('{DeltaById}', deltaByIdFilter);
      transferRequestNotesfetchXml = transferRequestNotesfetchXml.replace('{DeltaCondition}', '').replace('{DeltaById}', deltaByIdFilter);
      await Promise.all([
        this.fieldMaterialManagementDataService.getAssetTransfers(transferRequestsfetchXml),
        this.fieldMaterialManagementDataService.getAssetTransferNotes(transferRequestNotesfetchXml),
      ]).then(async (data: any[]) => {
        await this.saveDeltaAssetTransfersToDisk(data[0], data[1]);
      });
    }
    const index = this.assetTransfers.findIndex(cs => cs.indskr_assettransferid === assetTransferId);
    return index >= -1 ? this.assetTransfers[index] : null;
  }

  private async saveCustomerAssetsToDisk(data: CustomerAsset[], notes: any[]) {
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      this.removeUnderscorePrefixes(data[i], rawData);
      rawData._id = DB_KEY_PREFIXES.CUSTOMER_ASSETS + rawData.msdyn_customerassetid;
      if (notes) {
        rawData.notes = notes
          .filter(note => rawData.msdyn_customerassetid == note.customerassetid)
          .map(n => {
            const rawNote = n;
            this.removeUnderscorePrefixes(n, rawNote);
            const tr = new CustomerAssetNote({ ...n, createdon: new Date(n.createdon).getTime() + '' })
            return tr;
          });
      }
      this.customerAssets.push(new CustomerAsset(rawData));
    }
    try {
      await this.disk.bulk(this.customerAssets);
    } catch (error) {
      console.log('error saving bulk assets', error);
    }
  }

  private async saveAccountManagersToDisk(data: AccountManagerUser[]) {
    this.amUsers = [];
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      if (rawData.systemuserid != this.authService.user.xSystemUserID) {
        rawData._id =
          DB_KEY_PREFIXES.ASSET_ACCOUNT_MANAGERS + rawData.systemuserid;
        this.amUsers.push(new AccountManagerUser(rawData))
      }
    }
    try {
      await this.disk.bulk(this.amUsers);
    } catch (error) {
      console.log('error saving bulk asset AM users', error);
    }
  }

  private async saveAssetTransfersToDisk(transfers: AssetTransfer[], notes: any[]) {
    for (let i = 0; i < transfers.length; i++) {
      const rawData = transfers[i];
      this.removeUnderscorePrefixes(transfers[i], rawData);
      if (!_.isEmpty(notes)) {
        rawData.notes = notes
          .filter(note => rawData.indskr_assettransferid == note.activityid)
          .map(n => {
            const rawNote = n;
            this.removeUnderscorePrefixes(n, rawNote);
            const tr = new CustomerAssetTransferNote({ ...n, createdon: new Date(n.createdon).getTime() + '' })
            return tr;
          });
      }
      rawData._id = DB_KEY_PREFIXES.ASSET_TRANSFERS + rawData.indskr_assettransferid;
      this.assetTransfers.push(new AssetTransfer(rawData));
    }
    try {
      await this.disk.bulk(this.assetTransfers);
    } catch (error) {
      console.log('error saving bulk asset transfers', error);
    }
  }

  private async saveDeltaCustomerAssetsToDisk(data: CustomerAsset[], notes: any[], latestTransfer?: any[]) {
    const deletedAssets = [];
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      if (!_.isEmpty(latestTransfer)) rawData.latestTransfer = latestTransfer[0];
      this.removeUnderscorePrefixes(data[i], rawData);
      rawData._id = DB_KEY_PREFIXES.CUSTOMER_ASSETS + rawData.msdyn_customerassetid;

      const index = this.customerAssets.findIndex(ca => ca.msdyn_customerassetid === rawData.msdyn_customerassetid);
      // if (rawData['ownerid_value'] == this.authService.user.xSystemUserID) {
        if (notes) {
          rawData.notes = notes
            .filter(note => rawData.msdyn_customerassetid == note.customerassetid)
            .map(n => {
              const rawNote = n;
              this.removeUnderscorePrefixes(n, rawNote);
              const tr = new CustomerAssetNote({ ...n, createdon: new Date(n.createdon).getTime() + '' })
              return tr;
            });
        }
        await this.disk.updateOrInsert(rawData._id, (doc) => {
          return new CustomerAsset(rawData);
        });
        if (index > -1) {
          this.customerAssets[index] = new CustomerAsset(rawData);
        } else {
          this.customerAssets.push(new CustomerAsset(rawData));
        }
      // } else {
      //   if (index > -1) {
      //     deletedAssets.push({
      //       _id: this.customerAssets[index]._id,
      //       _rev: this.customerAssets[index]._rev,
      //       _deleted: true
      //     });
      //     this.customerAssets.splice(index, 1);
      //   }
      // }
    }
    // if (!_.isEmpty(deletedAssets)) {
    //   try {
    //     // Bulk save/update docs to DB
    //     await this.disk.bulk(deletedAssets);
    //   } catch (error) {
    //     console.error('error occurred: saving deleted assets: ', error);
    //   }
    // }
  }

  private async saveDeltaAssetTransfersToDisk(data: AssetTransfer[], notes: any[]) {
    const deletedAssets = [];
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      this.removeUnderscorePrefixes(data[i], rawData);
      rawData._id = DB_KEY_PREFIXES.ASSET_TRANSFERS + rawData.indskr_assettransferid;
      const index = this.assetTransfers.findIndex((transfer) => transfer.indskr_assettransferid === rawData.indskr_assettransferid);
      if (rawData.statuscode == AssetTrasferStatus.Cancelled) {
        if (index > -1) {
          await this.disk.remove(rawData._id);
          this.assetTransfers.splice(index, 1);
        }
      } else {
        if (!_.isEmpty(notes)) {
          rawData.notes = notes
            .filter(note => rawData.indskr_assettransferid == note.activityid)
            .map(n => {
              const rawNote = n;
              this.removeUnderscorePrefixes(n, rawNote);
              const tr = new CustomerAssetTransferNote({ ...n, createdon: new Date(n.createdon).getTime() + '' })
              return tr;
            });
        }
        await this.disk.updateOrInsert(rawData._id, (doc) => {
          return new AssetTransfer(rawData);
        });
        if (index > -1) {
          this.assetTransfers[index] = new AssetTransfer(rawData);
        } else {
          this.assetTransfers.push(new AssetTransfer(rawData));
        }
        if (
          rawData['statuscode'] == AssetTrasferStatus.Completed &&
          rawData['indskr_currentassetowner_value'] &&
          rawData['indskr_currentassetowner_value'] ==
          this.authService.user.xSystemUserID &&
          rawData['indskr_newassetowner_value'] &&
          rawData['indskr_newassetowner_value'] !=
          this.authService.user.xSystemUserID
        ) {
          const idx = this.customerAssets.findIndex((asset) => asset.msdyn_customerassetid === rawData['indskr_customerasset_value']);
          const childIdx = this.customerAssets.findIndex((asset) => asset.msdyn_parentasset === rawData['indskr_customerasset_value']);
          if (idx > -1) {
            deletedAssets.push({
              _id: this.customerAssets[idx]._id,
              _rev: this.customerAssets[idx]._rev,
              _deleted: true
            });
            this.customerAssets.splice(idx, 1);
          }
          if (childIdx > -1) {
            deletedAssets.push({
              _id: this.customerAssets[childIdx]._id,
              _rev: this.customerAssets[childIdx]._rev,
              _deleted: true
            });
            this.customerAssets.splice(childIdx, 1);
          }
        }
      }
    }
    if (!_.isEmpty(deletedAssets)) {
      try {
        // Bulk save/update docs to DB
        await this.disk.bulk(deletedAssets);
      } catch (error) {
        console.error('error occurred: saving deleted assets: ', error);
      }
    }
  }

  public async getAssetDeltaById(assetId: string) {
    let customerAsset: CustomerAsset = null;
    if (!this.device.isOffline) {
      let assetFetchXml = fetchQueries.fetchMyAssets;
      let assetNotesFetchXml = fetchQueries.fetchAssetNotes;
      let latestAssetTransferFetchXml = fetchQueries.fetchLatestAssetTransfer;
      const deltaByIdFilter = `<condition attribute="msdyn_customerassetid" operator="eq" value="${assetId}" />`;
      assetFetchXml = assetFetchXml.replace('{DeltaCondition}', '').replace('{DeltaById}', deltaByIdFilter);
      assetNotesFetchXml = assetNotesFetchXml.replace('{DeltaCondition}', '').replace('{DeltaById}', deltaByIdFilter);
      latestAssetTransferFetchXml = latestAssetTransferFetchXml.replace('{DeltaCondition}', '').replace('{DeltaById}', deltaByIdFilter);
      await Promise.all([
        this.fieldMaterialManagementDataService.getCustomerAssets(assetFetchXml),
        this.fieldMaterialManagementDataService.getAssetNotes(assetNotesFetchXml),
        this.fieldMaterialManagementDataService.getLatestAssetTransfers(latestAssetTransferFetchXml)
      ]).then(async (data: any[]) => {
        await this.saveDeltaCustomerAssetsToDisk(data[0], data[1], data[2]);
      });
    }
    const index = this.customerAssets.findIndex(cs => cs.msdyn_customerassetid === assetId);
    customerAsset = this.customerAssets[index];
    return customerAsset;
  }

  public async createTransferRequest(asset: CustomerAsset, isLocationFlow) {
    let payload = {};
    payload['assetName'] = asset.msdyn_name;
    payload['assetSerialNumber'] = asset.indskr_serialnumber;
    payload['product'] = asset.msdyn_product;
    payload['currentAssetLocation'] = asset.msdyn_account;
    payload['assetCategory'] = asset.indskr_assetscategory;
    payload['assetStatus'] = asset.indskr_assetstatus_value;
    if (!isLocationFlow) {
      payload['toBeAssetOwner'] = asset.indskr_tobeassetowner;
    } else {
      payload['newAssetLocation'] = asset.indskr_newassetlocation;
      payload['newAssetLocationStartDate'] = moment(this.selectedAsset.indskr_newlocationstartdate).format('YYYY-MM-DD');
      payload['newAssetLocationEndDate'] = moment(this.selectedAsset.indskr_newlocationenddate).format('YYYY-MM-DD');
      if (asset.indskr_assetscategory == AssetCategory.LOANER){
        payload['contractId'] = asset.contractId;
      }

      if (asset.indskr_assetscategory !== AssetCategory.LOANER && this.authService.user.enddateautocalculation.includes(this.selectedAsset.indskr_assetscategory.toString()) && !this.selectedAsset.indskr_newlocationenddate) {
        delete payload['newAssetLocationEndDate'];
      }
    
    }
   
    return await this.fieldMaterialManagementDataService.createTransferRequest(payload, asset.msdyn_customerassetid).then(async data => {
      if (!_.isEmpty(data)) {
        await this.getAssetTransferById(data['assetTransferId']);
        const index = this.customerAssets.findIndex((asset) => asset.msdyn_customerassetid === asset.msdyn_customerassetid);
        const custAsset = this.customerAssets[index];
        custAsset.indskr_approvalrequired = true;
        custAsset.latestTransfer = null;
        await this.disk.updateOrInsert(custAsset._id, (doc) => {
          return custAsset;
        });
        this.customerAssets[index] = custAsset;
        return custAsset;
      }
    });
  }

  public async cancelAssetDeployment(asset: CustomerAsset) {
    const payload = {
      newAssetLocationEndDate: moment(new Date().getTime()).format('YYYY-MM-DD'),
      assetTransferId: asset.latestTransfer.indskr_assettransferid
    };
   
    return await this.fieldMaterialManagementDataService.cancelAssetDeployment(payload, asset.msdyn_customerassetid).then(async data => {
      if (!_.isEmpty(data)) {
        const index = this.customerAssets.findIndex((asset) => asset.msdyn_customerassetid === asset.msdyn_customerassetid);
        const custAsset = this.customerAssets[index];
        custAsset.msdyn_account = custAsset.accountName = custAsset.latestTransfer = null;
        await this.disk.updateOrInsert(custAsset._id, (doc) => {
          return custAsset;
        });
        this.customerAssets[index] = custAsset;
        return custAsset;
      }
    });
  }

  private removeUnderscorePrefixes(data, rawData) {
    /* Pouch db doesnt allow to store keyword starting with _ */
    for (const key in data) {
      if (key.charAt(0) === '_') {
        const a = key.substring(1, key.length);
        data[a] = rawData[a] = data[key];
        delete data[key];
        delete rawData[key];
      }
    }
  }

  public async removeNotes(noteId: string) {
    await this.fieldMaterialManagementDataService.deleteNotes(noteId).then(() => {
      // let indx = this.assetNotes.findIndex(an => an.annotationid === noteId);
      // if (indx !== -1) {
      //   this.assetNotes.splice(indx, 1);
      // }
      const assetIndx = this.customerAssets.findIndex((asset) => asset.msdyn_customerassetid === this.selectedAsset.msdyn_customerassetid);
      if (assetIndx !== -1) {
        let locAsset = this.customerAssets[assetIndx];
        const noteIndx = locAsset.notes.findIndex(nt => nt.annotationid === noteId);
        if (noteIndx != -1) {
          locAsset.notes.splice(noteIndx, 1);
        }
        this.customerAssets[assetIndx] = locAsset;
        this.disk.updateOrInsert(locAsset._id, (doc) => {
          return locAsset;
        });

      }

    }).catch((err) => console.log(err));
  }

  public uploadAssetNotesOnline(payload) {
    return this.fieldMaterialManagementDataService.uploadAssetNotesOnline(payload).then(async note => {
      if (!_.isEmpty(note)) {
        const assetNote = new CustomerAssetNote({
          customerassetid: note.customerAssetId,
          createdon: note.createdon,
          notetext: note.notetext,
          ownerid: note.ownerid,
          filename: note.filename,
          filesize: note.filesize,
          documentbody: note.documentbody,
          mimetype: note.mimetype,
          annotationid: note.noteid,
          ownerName: this.authService.user.displayName,
          modifiedbyname: this.authService.user.displayName,
          modifiedby: note.ownerid
        });
        const index = this.customerAssets.findIndex((asset) => asset.msdyn_customerassetid === note.customerAssetId);
        let customerAsset = this.customerAssets[index];
        // assetNote._id = DB_KEY_PREFIXES.ASSET_NOTES + assetNote.annotationid;
        customerAsset.notes.push(assetNote);
        //this.assetNotes.push(assetNote);
        // await this.disk.updateOrInsert(assetNote._id, (doc) => {
        //   return assetNote;
        // });
        this.customerAssets[index] = customerAsset;
        await this.disk.updateOrInsert(customerAsset._id, (doc) => {
          return customerAsset;
        });
        return assetNote;
      }
    }).catch(err => console.error(err));
  }

  public async updateAssetNoteOnline(payload, existingNote: IONote) {
    await this.fieldMaterialManagementDataService.updateNotesOnline(payload, false)
      .then(async nt => {
        //const ind = this.assetNotes.findIndex(an => an.annotationid === payload.noteid);
        // if (ind !== -1) {
        //   let locNote = this.assetNotes[ind];
        //   locNote.filename = existingNote.documentName;
        //   locNote.isdocument = existingNote.hasDocument;
        //   //locNote. payload['documentbody'];
        //   locNote.filesize = existingNote.documentSize;
        //   locNote.mimetype = existingNote.documentMimeType;
        //   locNote.notetext = existingNote.noteText;
        //   locNote._id = DB_KEY_PREFIXES.ASSET_NOTES + locNote.annotationid;
        //   this.disk.updateOrInsert(locNote._id, (doc) => {
        //     return locNote;
        //   });
        // }
        const ind = this.customerAssets.findIndex((asset) => asset.msdyn_customerassetid === existingNote.activityId); {
          if (ind !== -1) {
            let locAsset = this.customerAssets[ind];
            let locNote = locAsset.notes.find(cn => cn.annotationid === payload.noteid);
            locNote.filename = existingNote.documentName;
            locNote.isdocument = existingNote.hasDocument;
            locNote.filesize = existingNote.documentSize;
            locNote.mimetype = existingNote.documentMimeType;
            locNote.notetext = existingNote.noteText;

            this.customerAssets[ind] = locAsset;
            await this.disk.updateOrInsert(locAsset._id, (doc) => {
              return locAsset;
            });
          }
        }
      }).catch(err => console.log(err));
  }

  public editAssetTransferNotesOnline(payload, deleted: boolean, transferId) {
    return this.fieldMaterialManagementDataService.updateNotesOnline(payload, deleted).then(async note => {
      const index = this.assetTransfers.findIndex((asset) => asset.indskr_assettransferid === transferId);
      let assetTrasfer = this.assetTransfers[index];
      if (deleted) {
        assetTrasfer.notes = assetTrasfer.notes.filter(n => n.annotationid != payload.noteid);
      } else {
        const idx = assetTrasfer.notes.findIndex(note => note.annotationid == payload.noteid);
        let existingNote = assetTrasfer.notes[idx];
        existingNote = { ...existingNote, ...payload }
        assetTrasfer.notes[idx] = existingNote;
      }
      await this.disk.updateOrInsert(assetTrasfer._id, (doc) => {
        return assetTrasfer;
      });
      this.assetTransfers[index] = assetTrasfer;
      return assetTrasfer.notes;
    });
  }

  public uploadTransferNotesOnline(payload) {
    return this.fieldMaterialManagementDataService.uploadTransferNotesOnline(payload).then(async data => {
      if (!_.isEmpty(data)) {
        const note = data[0];
        const transferNote = new CustomerAssetTransferNote({
          activityid: note.assetTransferId,
          createdon: note.createdon,
          notetext: note.notetext,
          ownerid: note.ownerid,
          filename: note.filename,
          filesize: note.filesize,
          documentbody: note.documentbody,
          mimetype: note.mimetype,
          annotationid: note.noteid,
          ownerName: this.authService.user.displayName
        });
        const index = this.assetTransfers.findIndex((transfer) => transfer.indskr_assettransferid === note.assetTransferId);
        let assetTransfer = this.assetTransfers[index];
        assetTransfer.notes.push(transferNote);
        await this.disk.updateOrInsert(assetTransfer._id, (doc) => {
          return assetTransfer;
        });
        this.assetTransfers[index] = assetTransfer;
        return assetTransfer.notes;
      }
    });
  }

  public async cancelAssetTransfer(assetTransferId) {
    return await this.fieldMaterialManagementDataService.updateAssetTrasferStatus({transferStatusCode: 548910003}, assetTransferId).then(async data => {
      const index = this.assetTransfers.findIndex((transfer) => transfer.indskr_assettransferid === assetTransferId);
      const assetTransfer = this.assetTransfers[index];
      await this.disk.remove(assetTransfer._id);
      // this.assetTransfers.splice(index, 1);
      return assetTransfer;
    });
  }

  public async updateAssetTrasferStatus(payload, assetTransferId) {
    return await this.fieldMaterialManagementDataService.updateAssetTrasferStatus(payload, assetTransferId).then(async data => {
        const index = this.assetTransfers.findIndex((transfer) => transfer.indskr_assettransferid === assetTransferId);
        let assetTransfer = this.assetTransfers[index];
        assetTransfer.statuscode = payload.transferStatusCode;
        assetTransfer.statuscode_Formatted = AssetTrasferStatus[payload.transferStatusCode];
        await this.disk.updateOrInsert(assetTransfer._id, (doc) => {
          return assetTransfer;
        });
        this.assetTransfers[index] = assetTransfer;
        return assetTransfer;
    });
  }

  public async getMeetingAssetMappings(forceFullSync: boolean, activityId?: string){
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MEETING_ASSETS);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const syncInfo: EntitySyncInfo = { entityName: EntityNames.meetingAssets, totalFailed: 0, totalSynced: 0, errors: [], syncStatus: true };
    let fetchXml = activityId ? fetchQueries.fetchMeetingAssetsByActivityId : fetchQueries.fetchMeetingAssetMappings;
    if (isInitialSync) {
      fetchXml = fetchXml.replace('{DeltaCondition}', '');
      this.assetTransfers = [];
      await this.disk.deleteAllFromDbUsingAlldocsQuery(
        DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MEETING_ASSETS
      );
    } else if (activityId) { 
      fetchXml = fetchXml.replace('{activityId}', activityId);
    } else {
      fetchXml = fetchXml.replace('{DeltaCondition}', `<condition attribute="modifiedon" operator="ge" value="${new Date(syncState.lastUpdatedTime).toISOString()}" />`);
      await this.loadMeetingAssetsFromDB();
    }
    this.fieldMaterialManagementDataService.getMeetingAssets(fetchXml).then(async (data: any[]) => {
      console.log('Saving meeting assets to disk...', data);
      if (isInitialSync) {
        await this.saveMeetingAssetsToDisk(data);
      } else {
        await this.saveDeltaMeetingAssetsToDisk(data);
      }
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(data)) {
        syncInfo.totalSynced = data.length;
      }
      this.deltaService.addEntitySyncInfo(syncInfo);
    }).catch((err) => {
      console.error('Error occurred while fetching meeting assets...', err);
      this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, 'indskr_activitycustomerassets', err);
      this.deltaService.addEntitySyncInfo(syncInfo);
    });
  }

  private async saveMeetingAssetsToDisk(data: MeetingAsset[]) {
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      this.removeUnderscorePrefixes(data[i], rawData);
      rawData._id = DB_KEY_PREFIXES.MEETING_ASSETS + rawData.indskr_activitycustomerassetid;
      this.meetingAssets.push(new MeetingAsset(rawData));
    }
    await this.disk.bulk(this.meetingAssets);
  }

  private async saveDeltaMeetingAssetsToDisk(data: MeetingAsset[]) {
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      this.removeUnderscorePrefixes(data[i], rawData);
      rawData._id = DB_KEY_PREFIXES.MEETING_ASSETS + rawData.indskr_activitycustomerassetid;
      await this.disk.updateOrInsert(rawData._id, (doc) => {
        return new MeetingAsset(rawData);
      });
      const index = this.meetingAssets.findIndex(ca => ca.indskr_activitycustomerassetid === rawData.indskr_activitycustomerassetid);
      if (index > -1) {
        this.meetingAssets[index] = new MeetingAsset(rawData);
      } else {
        this.meetingAssets.push(new MeetingAsset(rawData));
      }
    }
  }

  public async mapAssetsToMeeting(newAssets: CustomerAsset[], removedAssets: CustomerAsset[], appointmentid: string) {
    const existingMappings = this.meetingAssets.filter(a => a.appointmentid == appointmentid);
    let payload = [];
    if (!_.isEmpty(newAssets)) {
      newAssets.forEach(na => {
        const idx = existingMappings.findIndex(em => em.msdyn_customerassetid == na.msdyn_customerassetid && em.appointmentid == appointmentid);
        let mapping;
        if (idx > -1) {
          mapping = _.cloneDeep(existingMappings[idx]);
        } else {
          mapping = {
            appointmentid: appointmentid,
            msdyn_customerassetid: na.msdyn_customerassetid,
            msdyn_name: na.msdyn_name,
            indskr_serialnumber: na.indskr_serialnumber,
            ownerid: this.authService.user.systemUserID,
            indskr_category: na.categoryName,
            indskr_assetstatus: na.indskr_assetstatus,
            indskr_product: na.productName
          };
        }
        mapping.statecode = 0;
        payload.push(mapping);
      });
    }
    if (!_.isEmpty(removedAssets)) {
      removedAssets.forEach(ra => {
        let mapping = existingMappings.find(em => ra.msdyn_customerassetid == em.msdyn_customerassetid && em.appointmentid == appointmentid);
        if (mapping) {
          mapping = _.cloneDeep(mapping);
          mapping.statecode = 1;
          payload.push(mapping);
        }
      })
    }
    if (!_.isEmpty(payload)) {
      const meetingAssets = await this.fieldMaterialManagementDataService.mapMeetingAssets(payload, appointmentid);
      if (meetingAssets) {
        await this.saveDeltaMeetingAssetsToDisk(meetingAssets);
        return true;
      }
    }
  }

  sortListByFieldName(options, fieldName: string) {
    if (_.isEmpty(options)) return [];
    return options.sort((a, b) => {
      let nameA: string = a[fieldName], nameB: string = b[fieldName];
      if (!nameA || !nameB) return 1;
      nameA = nameA.toLowerCase();
      nameB = nameB.toLowerCase();
      if (nameA < nameB)
        return -1;
      if (nameA >= nameB)
        return 1;
    });
  }

  public getAssetById(id: string): CustomerAsset {
    return this.customerAssets.find(e => e.msdyn_customerassetid == id);
  }

  public async getAssetsForAssetBooking(fromDate, toDate) {
    let fetchXml = fetchQueries.fetchAssetsForAssetBooking;
    let buFilter = this.authService.user.buSettings['indskr_fetchassets'] == 548910001 ?
        `<condition attribute="owningbusinessunit" operator="eq" value="${this.authService.user.xBusinessUnitId}" />` : '';
    fetchXml = fetchXml.replace('{fromDate}', fromDate).replace('{toDate}', toDate)
                      .replace('{buFilter}', buFilter);
    
    try {
      return await this.fieldMaterialManagementDataService.getCustomerAssets(fetchXml);
    } catch(e) {
      console.log('error getAssetsForAssetBooking: ' + e);
      return null;
    }
  }

  public async checkAssetAvailabilityForAssetBooking(fromDate, toDate) {
    let fetchXml = fetchQueries.fetchUnavailableAssetsForAssetBooking;
    fetchXml = fetchXml.replace('{fromDate}', fromDate).replace('{toDate}', toDate);
    try {
      return await this.fieldMaterialManagementDataService.getCustomerAssets(fetchXml);
    } catch(e) {
      console.log('error checkAssetAvailabilityForAssetBooking: ' + e);
      return null;
    }
  }
}
