<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="onCloseClicked($event)"
      >
        <ion-icon slot="icon-only" ng-reflect-name="indegene-alert-close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
