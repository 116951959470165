<div class="mainWrapper" #scrollTop>
  <ng-container *ngIf="authenticationService.user.isProductConsent">
    <ind-section-header [viewData]="productsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ion-item-group *ngFor="let product of (products | orderBy:'indskr_productname':true) | slice:0:limitProds">
      <ion-item>
        <ion-label class="product-item"> {{ product.indskr_productname }}</ion-label>
        <ion-checkbox
          slot="start"
          [(ngModel)]="product.isChecked"
          [checked]="product.isChecked"
          (ionChange)="checkIfTermsAlreadyGenerated('products')"
        ></ion-checkbox>
      </ion-item>
      <div class="separationLine"></div>
    </ion-item-group>
    <ion-item-group>
      <ion-item *ngIf="products.length > 3">
        <div class="expProd" (click)="expandCollapse()">{{ !isExpanded ? this.translate.instant('AND')+' '+ (products.length - limitProds) +' '+this.translate.instant('MORE') : this.translate.instant('SHOW_LESS')}}</div>
      </ion-item>
      <div class="separationLine"></div>
    </ion-item-group>
  </ng-container>

  <ind-section-header class="section-channels" [viewData]="channelsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-item-group *ngFor="let channel of consentChannels | orderBy:'indskr_consentType':true;">
    <ion-item>
      <ion-label class="channel-item"> {{ displayChannelLabels(channel.indskr_consentType)}}</ion-label>
      <ion-checkbox
        slot="start"
        [(ngModel)]="channel.isChecked"
        [checked]="channel.isChecked"
        (ionChange)="checkIfTermsAlreadyGenerated('channels')"
      ></ion-checkbox>
    </ion-item>
    <div class="separationLine"></div>
  </ion-item-group>

  <ind-section-header class="section-consentTerms" [viewData]="contactConsentTermsModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-item>
    <div class="gen-terms">
      <ion-row *ngIf="generatedTerms" nowrap>
        <div class="content-list" *ngFor="let term of generatedTerms | orderBy:'indskr_consentTermName':true;" (click)="openConsentDetails(term)">
          <div style="width: 100px;height: 70px;">
            <img src="assets/imgs/consent-signature.svg" />
            <div class="text-bottom">{{ term.indskr_consentTermName }}</div>
          </div>
        </div>
      </ion-row>
    </div>
  </ion-item>
</div>
