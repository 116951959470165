/**
 * Class representation of brands
 *
 * @export
 * @class Brand
 */
import {TherapeuticArea} from "../case-intake/case-utility.class";

export class Brand {
  public ID: string;
  public name: string;
  public code: string;
  public description: string;

  public created: Date;
  public modified: Date;

  public createdBy: string;
  public modifiedBy: string;

  public priority: number;
  public status: string;
  public keyMessages: Array<KeyMessage>;

  public isChecked: boolean;
  public isSelected: boolean;
  public isAutoSelected: boolean;
  public isGenieSelected: boolean;
  public isCompetitorProduct: string;
  public defaultuomid: string;

  public value: string;
  public indskr_newproductintroduction: boolean;

  public therapeuticAreas: TherapeuticArea[] = [];
  public skus: Brand[] = [];
  public productApplicability: string[] = [];
  public productStructure: number;
  public productnumber : number;
  public producttype : number;
  public itemCount : number;
  public indskr_account : string;
  public indskr_limitedrelease : boolean;
  public indskr_recordtype: number;

  constructor(raw: object, index?: number, isSelected?:boolean) {
    this.ID = raw["productid"] || raw["indskr_productid"];
    this.indskr_newproductintroduction = raw["indskr_newproductintroduction"];
    this.priority = index;
    if (!this.ID) this.ID = raw["id"];
    this.code = undefined; //raw['brandCode'];
    this.description = raw["description"];

    this.value = raw["name"] || raw["productName"];
    this.isCompetitorProduct = raw["isCompetitorProduct"] ? raw["isCompetitorProduct"] : undefined;

    this.name = raw['name'] || raw['productName'] || raw['indskr_name'];
    this.status = raw['statuscode'];
    this.created = raw['createdon'] ? new Date(parseInt(raw['createdon'])) : undefined;
    this.createdBy = raw['_createdby_value'];
    this.modifiedBy = raw['_modifiedby_value'];
    this.modified = raw['modifiedon'] ? new Date(parseInt(raw['modifiedon'])) : undefined;
    this.keyMessages = new Array<KeyMessage>();
    this.isSelected = isSelected;
    this.isAutoSelected  = raw['indskr_isautomaticallyselected'];
    this.defaultuomid = raw['defaultuomid'];
    let keyMessages = raw["keyMessages"] || raw["activityProductKeyMessages"];
    this.therapeuticAreas = raw['therapeuticAreas'] ? raw['therapeuticAreas'] : [];
    this.skus = raw['skus'] ? this.getSKU(raw['skus']) : [];
    this.productApplicability = raw['indskr_productapplicability']? raw['indskr_productapplicability'].split(',') : [];
    this.productStructure = raw['productStructure'];
    this.productnumber = raw['productnumber'];
    this.itemCount = raw['indskr_itemcount'];
    this.producttype = raw['producttype'];
    this.indskr_account = raw['indskr_account'];
    this.indskr_limitedrelease = raw['indskr_limitedrelease'];
    this.indskr_recordtype = raw['indskr_recordtype'];
    this.indskr_account = raw['indskr_account'] || raw['_indskr_account_value'];

    if (!keyMessages) return;
    for (
      let keyMessageIndex = 0;
      keyMessageIndex < keyMessages.length;
      keyMessageIndex++
    ) {
      const keyMessage = keyMessages[keyMessageIndex];
      this.keyMessages.push(
        new KeyMessage(
          keyMessage.keymessageid || keyMessage.indskr_keymessageid,
          keyMessage.keymessagename || keyMessage.indskr_name,
          keyMessage.keymessagedescription
        )
      ); // they changed the field names AGAIN!!!! THIS IS GETTING ANNOYING!!!!!!
    }
  }

  getSKU(raw: any[]): Brand[] {
    let temp = raw.map(r => new Brand(r));

    temp.map(t => {
      t.therapeuticAreas = this.therapeuticAreas;
    });

    return temp;
  }

}

export class KeyMessage {
  ID: String;
  name: String;
  description: String;
  // tagged: boolean;
  public isSelected: boolean;
  public isAutoSelected: boolean;
  public isGenieSelected: boolean;

  constructor(id: string, name: string, description: string) {
    this.ID = id;
    this.name = name;
    this.description = description;
  }
}


