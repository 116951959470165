import { AuthenticationResult, BrowserCacheLocation, Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { TokenCredential, TokenCredentialOptions } from "./token.credential";

export class BrowserCredential implements TokenCredential {
  private app: PublicClientApplication;
  private readonly _ready: Promise<any>;
  readonly ready = () => this._ready;
  constructor(private options: TokenCredentialOptions) {
    const config: Configuration = {
      auth: {
        clientId: options.clientId,
        redirectUri: window.location.origin + '/.auth/login/aad/callback',
        authority: options.authority
      },
      cache: {
        cacheLocation: options.persist ? BrowserCacheLocation.LocalStorage : BrowserCacheLocation.SessionStorage
      }
    };
    this.app = new PublicClientApplication(config);
    this._ready = this.init();
  }

  private async init() {
    await this.app.handleRedirectPromise();
  }

  async getToken(scopes: string | string[]) {
    const account = this.options.username && this.app.getAccountByUsername(this.options.username)
    if (!Array.isArray(scopes)) {
      scopes = [scopes];
    }
    const accessTokenRequest = {
      scopes,
      ...account ? { account } : {
        loginHint: this.options.username,
        domainHint: this.options.domain,
      }
    };
    let interactionRequired = false;
    let authResult: AuthenticationResult;
    try {
      if (!account) {
        interactionRequired = true;
        return;
      }
      authResult = await this.app.acquireTokenSilent(accessTokenRequest);
    }
    catch (error) {
      if (error instanceof InteractionRequiredAuthError || error.errorCode === 'no_account_error') {
        interactionRequired = true;
      } else {
        throw error;
      }
    } finally {
      if (interactionRequired) {
        authResult = await this.app.acquireTokenPopup(accessTokenRequest);
        if (authResult.account?.username?.toLowerCase() !== this.options.username?.toLowerCase()) {
          throw new Error("LOGIN_WE_SIGN_YOU_IN");
        }
      }
      if (authResult) {
        return {
          token: authResult.accessToken,
          expiresOnTimestamp: authResult.expiresOn.getTime()
        };
      }
    }
  }
  async logout() {}

}
