<ng-container *ngIf="!isSkeleton; else skeleton">
  <ion-item-divider *ngIf="viewData" [ngClass]="{'highlighted-divider':viewData.isHighlighted,'normal-divider':!viewData.isHighlighted, 'popover-divider':viewData.searchPopoverHeader}">
      <ion-label (click)="filterClicked($event)" class="title-text" [ngClass]="{ 'filterStyleHeading': viewData.isFilterStyleHeading, 'has-right-label': viewData.rightLabelTxt, 'call-paln-filter': viewData.isCallPlanFilter }">
        <div class="text-wrapper">
          <ng-content *ngIf="viewData.isMultiFilter; else regFilter" select="[header]"></ng-content>
          <ng-template #regFilter>
            {{ viewData.title | capitalizeFirstLetter: '': !!viewData.doNotModifyTitleCase }}
          </ng-template>
          <span *ngIf="viewData.subtitle">{{ viewData.subtitle }}</span>
           <span class="active-filter" *ngIf="viewData.filterText">{{viewData.filterText}}</span>
          <ion-icon *ngIf="viewData.showArrow" [name]="viewData.arrowType" class="title-icon {{viewData.arrowType}}"></ion-icon>
          <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
        </div>
      </ion-label>
      <div class="right-param" *ngIf="viewData.customRightParams">
        <div class="param-title">{{viewData.customRightParams.title}}</div>
        <div class="param-value">
          <div class="completed"> {{viewData.customRightParams.completed}}</div><div class="defined">/{{viewData.customRightParams.defined}}</div>
        </div>
      </div>
      <ion-label class="right-label" *ngIf="(!loader && viewData.rightLabelTxt) || (loader && (loader | async) === 0 && viewData.rightLabelTxt)" [ngClass]="viewData.customRightLabelClass ? viewData.customRightLabelClass : ''">
        <div class="text-wrapper">
          {{ viewData.rightLabelTxt }}
        </div>
      </ion-label>
      <ion-buttons slot="end" *ngIf="viewData.controls?.length" [ngClass]="{'optimizeWidth':viewData?.isOptimizeButtonWidth, 'adjust-width' : viewData?.adjustWidth}">
          <ng-container *ngFor="let button of viewData.controls let i = index">
              <span *ngIf="i==1 && viewData.isShowSperationVerticalLine" class="separationVerticalLine">&#124;</span>
              <ion-button #rightSectionButton
                *ngIf="!(button.hasOwnProperty('isVisible') && button.isVisible == false)"
                [disabled]="button.isDisabled"
                (click)="onButtonClick(button, $event, rightSectionButton)"
                id="{{button.id}}"
                class="{{ 'num-btns-' + viewData?.controls?.length }}">
                <div class="button-content-wrapper" [tooltip]="button.tooltip"[display]="button.tooltip ? true : false">
                    <span class="section-header-button-text" *ngIf="button?.text || button?.textObservable">
                      <ng-container *ngIf="button.textObservable else pureText;">
                        {{button.textObservable | async}}
                      </ng-container>
                      <ng-template #pureText>
                        {{button.text}}
                      </ng-template>
                    </span>
                    <ion-icon *ngIf="button.icon" [ngClass]="{'disabled':button.isDisabled}" [src]="button.icon" class="control-icon-sort sortIcon"></ion-icon>
                    <ion-icon *ngIf="button.iconName" [ngClass]="{'disabled':button.isDisabled}" [name]="button.iconName" class="control-icon-sort sortIcon"></ion-icon>
                    <ion-icon *ngIf="button.iconClass" [ngClass]="{'disabled':button.isDisabled}" class="control-icon {{button.iconClass}}"></ion-icon>
                    <img *ngIf="button.img" [ngClass]="{'disabled':button.isDisabled}" [src]="button.img" class="control-img">
                  </div>
              </ion-button>
          </ng-container>
      </ion-buttons>
      <div *ngIf="loader && (loader | async) > 0" class="data-load-indicator">
        <ion-spinner name="dots"></ion-spinner>
      </div>
  </ion-item-divider>
</ng-container>

<ng-template #skeleton>
  <ion-item-divider class="normal-divider">
    <ion-label class="title-text" style="width: 100%;">
      <ion-skeleton-text class="skeleton-text-animated"
                          [ngStyle]="{'width': skeletonConfig?.headerSkeletonTextWidth ? skeletonConfig.headerSkeletonTextWidth : defaultWidth,
                                      'line-height': skeletonConfig?.headerSkeletonTextLineHeight ? skeletonConfig.headerSkeletonTextLineHeight : defaultLineHeight}"></ion-skeleton-text>
    </ion-label>
  </ion-item-divider>
</ng-template>