import { PageName } from './../navigation/navigation.service';
import { IShipmentReason, ISkuFilter } from './../../interfaces/allocation/allocation.shared.interface';
import { IAllocationShipmentAndTransferBase } from './../../interfaces/allocation/allocation-shipment.interface';
import { Injectable } from '@angular/core';
import { DiskService } from '../disk/disk.service';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { BehaviorSubject, Subject } from 'rxjs';
import { LogService } from '../logging/log-service';
import { AdjustmentStatus, ShipmentStatus, TransferType } from '../../enums/allocation/allocation.enum';
// import { ShipmentStatus } from '@omni/enums/allocation/allocation.enum';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';
import { MyAssistantService, NOTIFICATION } from '../my-assistant/my-assistant.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AllocationShipmentService {
    public showAllShipmentsFilter$ = new BehaviorSubject<boolean>(null);
    public isProductFilterExpanded: boolean;
    public isStatusFilterExpanded: boolean;
    public shipmentsSKUFilter: ISkuFilter;
    public shipmentsStatusFilter: ShipmentStatus | AdjustmentStatus;
    public transferTypeFilter: TransferType;
    public allLossReasons: IShipmentReason[] = [];
    public newShipmentSelectedFromList$: Subject<PageName> = new Subject<PageName>();
    public newAdjustmentSelectedFromList$: Subject<boolean> = new Subject<boolean>();
    public adjustmentDeleted$: Subject<String> = new Subject<String>();
    public isNewAdjustment = false;
    public fromAgendaPlanTab = false;
    private allocationNotificationModel: IndNotificationDataModel;

    constructor(
        private readonly disk: DiskService,
        private readonly logService: LogService,
        private myAssistantService: MyAssistantService,
        private translate: TranslateService,
        public authService : AuthenticationService  
    ){}

  /** ----------------------------------------------------------------------------------------
   *  Sync
   */
  async saveInitialSyncedShipmentsTransfersData(raw: any[]): Promise<boolean> {
    if (Array.isArray(raw)) {
      try {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.ALLOC_SHIPMENT_AND_TRANSFER, () => ({ raw }));

        return true;
      } catch (error) {
        console.error('saveInitialSyncedShipmentsTransfersData: ', error);
      }
    }
    return false;
  }
  async processDeltaSyncedShipmentsTransfersData(addedOrUpdatedRawData: IAllocationShipmentAndTransferBase[], deletedRawData: any[]): Promise<{ success: boolean, shipmentsData: IAllocationShipmentAndTransferBase[] | null }> {
    if (
      (Array.isArray(addedOrUpdatedRawData) && addedOrUpdatedRawData.length > 0)
      || (Array.isArray(deletedRawData && deletedRawData.length > 0))
    ) {
      try {
        let shouldUpsert: boolean = false;
        let localDbData: { raw: IAllocationShipmentAndTransferBase[] } = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_AND_TRANSFER, true);

        // Sanity checks
        if (!localDbData) {
          // No record
          localDbData = {
            raw: []
          };
          shouldUpsert = true;
        } else if (localDbData && !Array.isArray(localDbData.raw)) {
          localDbData.raw = [];
        }

        const localDataMap: Map<string, number> = new Map(localDbData.raw.map((i, idx) => [i.indskr_usershipmentallocation_v2id, idx]));
        const itemsToBeAppended: IAllocationShipmentAndTransferBase[] = [];
        const deletedIdxes: number[] = [];

        // First replace
        for (let i = 0; i < addedOrUpdatedRawData.length; i++) {
          const deltaData = addedOrUpdatedRawData[i];

          if (localDataMap.has(deltaData.indskr_usershipmentallocation_v2id)) {
            // Replace
            const idx = localDataMap.get(deltaData.indskr_usershipmentallocation_v2id)
            localDbData.raw[idx] = deltaData;
          } else {
            // New data
            // Push to array to append later
            //For_Review records should be hidden to Shipped user
            if (deltaData.indskr_shipmentstatus != ShipmentStatus.ForReview) {
              itemsToBeAppended.push(deltaData);
            }
          }
          if (deltaData.indskr_shipmentstatus === ShipmentStatus.Shipped && deltaData.ownerId != this.authService.user.systemUserID) {
            // Create New Notification
            this.allocationNotificationModel = {
              type: NOTIFICATION.CUSTOMER_ALLOCATION_TRANSFER,
              name: this.translate.instant("CUSTOMER_ALLOCATION_TRANSFER", { userName: deltaData.ownerFullName, productName: deltaData.at_indskr_skuname }),
              DateTime: Date.now(),
              id: NOTIFICATION.CUSTOMER_ALLOCATION_TRANSFER + deltaData.indskr_usershipmentallocation_v2id,
              data: deltaData,
              icon: 'indegene-assets/imgs/transfer_From.svg',
              isRed: false,
              params: { userName: deltaData.ownerFullName, productName: deltaData.at_indskr_skuname }
            };
            this.myAssistantService.saveNotificationToDisk(this.allocationNotificationModel);
          }
        }
        await this.myAssistantService.loadAndMapNotificationsFromDB();

        // Remove deleted
        for (let i = 0; i < deletedRawData.length; i++) {
          const deletedData = deletedRawData[i];

          if (localDataMap.has(deletedData.indskr_usershipmentallocation_v2id)) {
            deletedIdxes.push(localDataMap.get(deletedData.indskr_usershipmentallocation_v2id));
          }
        }
        // Sort indexes in descending order
        deletedIdxes.sort((a, b) => b - a);
        for (let i = 0; i < deletedIdxes.length; i++) {
          const idxToBeDeleted = deletedIdxes[i];
          localDbData.raw.splice(idxToBeDeleted, 1);
        }

        // Append newly added
        if (itemsToBeAppended.length > 0) {
          localDbData.raw.unshift(...itemsToBeAppended);
        }

        if (!shouldUpsert) {
          await this.disk.updateDocWithIdAndRev(localDbData, false, true);
        } else {
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.ALLOC_SHIPMENT_AND_TRANSFER, doc => localDbData);
        }

        return { success: true, shipmentsData: Array.isArray(localDbData.raw) ? [...localDbData.raw] : [] };
      } catch (error) {
        console.error('saveDeltaSyncedShipmentsTransfersData: ', error);
      }
    } else {
      // No delta changes
      return { success: true, shipmentsData: null };
    }

    return { success: false, shipmentsData: null };
  }

  mapShipmentLossReasons(rawReasons: IShipmentReason[], doNotSave?: boolean) {
    this.allLossReasons.length = 0;
    if (Array.isArray(rawReasons)) {
      this.allLossReasons = rawReasons;
    }
    if (!doNotSave) {
      //Save to DB or update
      this.disk.updateOrInsert(DB_KEY_PREFIXES.SHIPMENT_LOSS_REASONS, doc => ({ rawReasons }))
        .catch(error => this.logService.logError(`Failed saving shipment loss reasons. Error: ${error}`));
    }
  }

    clearFilters(fromNgDestroy = false) {
        this.isProductFilterExpanded = false;
        this.isStatusFilterExpanded = false;
        this.shipmentsStatusFilter = undefined;
        this.shipmentsSKUFilter = undefined;
        this.transferTypeFilter = undefined;
        if (!fromNgDestroy) {
          this.showAllShipmentsFilter$.next(true);
        }
    }

    getSelectedReasonText(value: number) {
      const foundReason = !isNaN(value) ? this.allLossReasons.find(r => r.value === value) : null;
      return foundReason && foundReason.reason ? foundReason.reason : '';
    }
}
