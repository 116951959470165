<ion-header>
  <ind-page-title [viewData]="EventAttendiesPageTitle"
    (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <div class="search-bar-container">
    <ion-searchbar   placeholder="{{ 'Search Participants'+ '-'+currentEvent.name}} " [(ngModel)] ="searchText"  (ionInput) = "filterParticipants($event.target.value)">
      <ion-buttons slot="end">
        <ion-button tooltip="{{'FILTERS' | translate}}" (click) = "openFilter($event)">
          <ion-icon src="assets/imgs/search_filter.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-searchbar>
  </div>


  <ind-section-header [viewData]="participantsHeader" (onControlClick) = "syncEventDetails($event)" ></ind-section-header>
  <ion-item-group *ngFor="let item of viewModel ">
    <ion-item>
      <p [attr.data-letters]="item.initials"></p>
      <ion-label class="customer-info">
        <ion-text class="primary-text">{{item.primaryTextLeft}}</ion-text>
        <p class="secondary-text"><span *ngIf="item.primarySpecialty && item.primarySpecialty.trim().length>0">{{item.primarySpecialty}},</span> {{item.secondaryTextLeft}} </p>
      </ion-label>
      <ion-icon class="checkboxStyleIcons" name="checkmark-circle" *ngIf="item.checkboxChecked" slot="end"
      (click)="item.clickHandler(item.id,'accordionIcon',item)"></ion-icon>
      <ion-icon class="checkboxStyleIcons" name="ellipse-outline" *ngIf="!item.checkboxChecked" slot="end"
        (click)="item.clickHandler(item.id,'accordionIcon',item)"></ion-icon>
    </ion-item>
    <ion-item class="accordian-expanded" *ngIf="item.isExpanded">
      <div class="signatureImageContainer" *ngIf="!item.expandableData">
        <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
        <div class="signatureWrapper ion-text-center">
          <p [ngClass]="{'accordion-icon-disabled':item.accordionIconCssClass=='accordion-icon-disabled'}" text-center  (click)="launchSignaturePad(item.id)">{{('CONSENT_TAP_TO_SIGN') | translate}}</p>
        </div>
      </div>
      <div class="signatureImageContainer" *ngIf="item.expandableData">
        <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
        <div class="capturedImage">
          <img [src]="item.expandableData" alt = "signature">
        </div>
      </div>
    </ion-item>
    <div class="separationLine"></div>
  </ion-item-group>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'captureEvents'"
  (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>