<ion-header>
  <ind-page-title [viewData]="procedureTrackerPageTitle" (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ng-container>
    <ion-list class='details-list'>
      <ind-section-header [viewData]='detailsHeader'  (onControlClick)='handleButtonClick($event)'></ind-section-header>
      <ind-form-field [viewData]="getProductFormField()"></ind-form-field>
      <ind-form-field [viewData]="getQtyFormField()"></ind-form-field>
    </ion-list>
  </ng-container>
</ion-content>
