import { Injectable } from "@angular/core";
import { ContactDataService } from "@omni/data-services/contact/contact.data.service";
import { CustomerEventsDataService } from "@omni/data-services/customer-event/customer-events.data.service";
import { AuthenticationService } from "../authentication.service";
import { CustomerAssessService } from "../customer-assess/customer-assess.service";
import { DeviceService } from "../device/device.service";
import { Contact } from "@omni/classes/contact/contact.class";
import { ContactOfflineService } from "./contact.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { SurveyCategory, SurveyStatus } from "@omni/classes/customer-assessment/assessment-template.class";
import { groupBy, isEmpty, values } from "lodash";
import { ConsentService } from "../consent/consent.service";
import { TranslateService } from "@ngx-translate/core";
import { SampleActivity } from "@omni/classes/activity/sample.activity.class";

@Injectable({
  providedIn: 'root',
})
export class ContactTimelineAggregatorService {
  constructor(
    private authenticationService: AuthenticationService,
    private device: DeviceService,
    private contactService: ContactOfflineService,
    private contactDataService: ContactDataService,
    private customerEventDataService: CustomerEventsDataService,
    private customerAssessService: CustomerAssessService,
    private consentService: ConsentService,
    private translate: TranslateService,
  ) {}

  async fetchContactTimeline(contact: Contact){
    const isCustomerJourneyTimelineEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_JOURNEY_TIMELINE);
    const isCustomerEventsEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION) || this.authenticationService.user.eventTimeLine;
    const isEventToolfeatureAction = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL) || this.authenticationService.user.eventTimeLine;
    const hasCustomerSurveyEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);

    await this.contactDataService.getContactActivitiesForTimeline(contact).then(async ()=>{
      //fetching event registrations before combining with activities
      if (!this.device.isOffline) {
        /**************************Customer Journey**************************/
        if (isCustomerJourneyTimelineEnabled) {
          await this.contactDataService.getCustomerJourneysForTimeline(contact)
        }
        /**************************Customer Journey**************************/

        await this.contactDataService.getContactWebsiteAccessLogsTimeline(contact);
      }
      if (!this.device.isOffline) {
        if(isCustomerEventsEnabled && !isEmpty(contact)) {
          await this.customerEventDataService.getContactEventTimeline(contact).then(()=>{
            if(contact.eventsAndRegistrationsByContact){
              this.contactService.isContactDetailsLoaded = true;
            }
          }).catch((err) => {
            console.log('error while fetching timeline events', err);
          });
        } else {
          this.contactService.isContactDetailsLoaded = true;
        }
        if (isEventToolfeatureAction && !isEmpty(contact)) {

          try {
            await this.customerEventDataService.getContactCheckedInEventTimeline(contact);

          if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) { // ? call only if FA is enabled
            await this.customerEventDataService.getContactCompletedEventTimeline(contact)
          }
          } catch (err) {
            console.log('error while fetching timeline events', err);
          }

        }
        /**************************Customer Survey**************************/
        if (hasCustomerSurveyEnabled && !isEmpty(contact)) {
          await this.customerAssessService.getSurveysForTimeline(contact.ID, SurveyCategory.CONTACT);
        }
        return;
      }
      // Offline only
      // else {
      //   if(isCustomerEventsEnabled && !isEmpty(contact)) {
      //     contact.eventsAndRegistrationsByContact = [];
      //     await this.customerEventDataService.getOfflineContactEventTimeline(contact).then(()=>{
      //       if(contact.eventsAndRegistrationsByContact){
      //         this.contactService.isContactDetailsLoaded = true;
      //       }
      //     }).catch((err) => {
      //       console.log('error while fetching timeline events', err);
      //     });
      //   } else {
      //     this.contactService.isContactDetailsLoaded = true;
      //   }
      //   if (isEventToolfeatureAction && !isEmpty(contact)) {
      //     contact.eventsCheckInByContact = [];
      //     contact.eventsCompletedByContact = [];
      //     try {
      //       await this.customerEventDataService.getOfflineContactCheckedInEventTimeline(contact);

      //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) { // ? call only if FA is enabled
      //       await this.customerEventDataService.getOfflineContactCompletedEventTimeline(contact)
      //     }
      //     } catch (err) {
      //       console.log('error while fetching timeline events', err);
      //     }
      //   }
      //   /**************************Customer Survey**************************/
      //   if (hasCustomerSurveyEnabled && !isEmpty(contact)) {
      //     this.customerAssessService.loadSurveysByContactIdForTimeline(contact.ID);
      //   }
      //   return;
      // }
    }).catch((err) => {
      console.error("contact-timeline-aggregator.service: error: ", err);
    });
  }

  private getPhoneCallSubject(subject) {
    return  (subject == 'Phone Call' || subject.includes(' - Phone Call')) ? subject.replace('Phone Call', this.translate.instant('NEW_ACTIVITY_PHONECALL')): subject;
  }

  getMostRecentActivitySubject(contact: Contact): string {
    let mostRecentActivitySubject: string;
    let mostRecentActivityStartTimestamp: number = 0;

    if (Array.isArray(contact.activitesTimeline)) {
      const prefixAllocationOrder = this.translate.instant('ALLOWCATION_ORDER');
      const prefixEmail = this.translate.instant('MESSAGE');
      const prefixInquiry = this.translate.instant('INQUIRY');
      const prefixPhoneCall = this.translate.instant('NEW_ACTIVITY_PHONECALL');
      for (let i = 0; i < contact.activitesTimeline.length; i++) {
        const activity = contact.activitesTimeline[i];
        const startTimestamp = activity.scheduledStart?.getTime() ?? undefined;
        if (!isNaN(startTimestamp) && startTimestamp >= mostRecentActivityStartTimestamp) {
          let subject = activity.subject;
          switch (activity.type) {
            case 'Sample':
              subject = `${prefixAllocationOrder} - ${(activity as SampleActivity).orderId}`;
              break;
            case 'Email':
              subject = `${prefixEmail} - ${activity.subject}`;
              break;
            case 'CaseIntake':
              subject = `${prefixInquiry} - ${activity.subject}`;
              break;
            case 'PhoneCall':
              subject = `${prefixPhoneCall} - ${this.getPhoneCallSubject(activity.subject)}`;
              break;
            default:
              break;
          }
          mostRecentActivitySubject = subject;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    if (Array.isArray(contact.websiteAccessLogs)) {
      for (let i = 0; i < contact.websiteAccessLogs.length; i++) {
        const log = contact.websiteAccessLogs[i];
        const startTimestamp = log.accessDateTime?.getTime() ?? undefined;
        if (
          !isNaN(startTimestamp)
          && startTimestamp >= mostRecentActivityStartTimestamp
          && this.authenticationService.user.customNotifications
        ) {
          mostRecentActivitySubject = `${log.channel} - ${log.department} - ${log.contactName}`;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    const eventPrefix = this.translate.instant('EVENT');
    if (
      (
        this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION)
        || this.authenticationService.user.eventTimeLine
      ) && Array.isArray(contact.eventsAndRegistrationsByContact)
    ) {
      for (let i = 0; i < contact.eventsAndRegistrationsByContact.length; i++) {
        const eventOrRegistration = contact.eventsAndRegistrationsByContact[i];
        const startTimestamp = eventOrRegistration.scheduledStart?.getTime() ?? undefined;
        if (!isNaN(startTimestamp) && startTimestamp >= mostRecentActivityStartTimestamp) {
          mostRecentActivitySubject = `${eventPrefix} - ${eventOrRegistration.eventName}`;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    if (
      (
        this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)
        || this.authenticationService.user.eventTimeLine
      ) && Array.isArray(contact.eventsCheckInByContact)
    ) {
      for (let i = 0; i < contact.eventsCheckInByContact.length; i++) {
        const eventCheckIn = contact.eventsCheckInByContact[i];
        const startTimestamp = eventCheckIn.checkinTime?.getTime() ?? eventCheckIn.createdOn?.getTime() ?? undefined;
        if (!isNaN(startTimestamp) && startTimestamp >= mostRecentActivityStartTimestamp) {
          mostRecentActivitySubject = `${eventPrefix} - ${eventCheckIn.name}`;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    if (Array.isArray(contact.eventsCompletedByContact)) {
      for (let i = 0; i < contact.eventsCompletedByContact.length; i++) {
        const eventCompleted = contact.eventsCompletedByContact[i];
        const startTimestamp = eventCompleted.startDate?.getTime() ?? undefined;
        if (!isNaN(startTimestamp) && startTimestamp >= mostRecentActivityStartTimestamp) {
          mostRecentActivitySubject = `${eventPrefix} = ${eventCompleted.name}`;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    let consenctActivities = this.consentService.allConsentActivitiesWithProductsAndEmails;
    if(Array.isArray(consenctActivities)) {
      consenctActivities = consenctActivities.filter((activity) => activity.contactId == contact.ID);

      for (let i = 0; i < consenctActivities.length; i++) {
        const consentActivity = consenctActivities[i];
        const startTimestamp = consentActivity.createdon ? new Date(consentActivity.createdon).getTime() : undefined;
        if (!isNaN(startTimestamp) && startTimestamp >= mostRecentActivityStartTimestamp) {
          mostRecentActivitySubject = consentActivity.indskr_name;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    if (
      this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY)
      && Array.isArray(this.customerAssessService.contactSurveysForTimeline)
    ) {
      const surveysByContactId: any = this.customerAssessService.contactSurveysForTimeline.filter(survey => survey.surveyStatus != SurveyStatus.SAVED) || [];
      if(!isEmpty(surveysByContactId)) {
        let groupedResponsesByDate: any = [];
        groupedResponsesByDate = groupBy(surveysByContactId, 'indskr_assessmentdate');
        groupedResponsesByDate = values(groupedResponsesByDate);

        const prefix = this.translate.instant('SURVEY');
        for (let i = 0; i < groupedResponsesByDate.length; i++) {
          const response = groupedResponsesByDate[i];
          const startTimestamp = response && response[0] && response[0]['indskr_assessmentdate'] ? new Date(response[0]['indskr_assessmentdate'])?.getTime() : undefined;
          if (!isNaN(startTimestamp) && startTimestamp >= mostRecentActivityStartTimestamp) {
            mostRecentActivitySubject = `${prefix} - ${response[0]?.indskr_name}`;
            mostRecentActivityStartTimestamp = startTimestamp;
          }
        }
      }
    }

    if (
      this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_JOURNEY_TIMELINE)
      && Array.isArray(contact.customerJourneysTimeline)
    ) {
      const prefix = this.translate.instant('CUSTOMER_JOURNEY');
      const meetingPrefix = this.translate.instant('MEETING');
      const phoneCallPrefix = this.translate.instant('NEW_ACTIVITY_PHONECALL');
      for (let i = 0; i < contact.customerJourneysTimeline.length; i++) {
        const customerJourney = contact.customerJourneysTimeline[i];
        const startTimestamp = customerJourney.journeyStart?.getTime() ?? undefined;
        if (
          !isNaN(startTimestamp)
          && startTimestamp >= mostRecentActivityStartTimestamp
          && (
            customerJourney.type === 'Appointment'
            || customerJourney.type === 'PhoneCall'
          )
        ) {
          mostRecentActivitySubject = `${prefix} - ${customerJourney.type === 'Appointment' ? meetingPrefix : phoneCallPrefix} - ${customerJourney.journeyName}`;
          mostRecentActivityStartTimestamp = startTimestamp;
        }
      }
    }

    return mostRecentActivitySubject;
  }
}
