<ion-content
  [class.current-view]="currentViewType"
  [class.month-view]="currentViewType === 'Month'"
  [class.week-view]="currentViewType === 'Week'"
  [class.three-days-view]="currentViewType === '3 Days'"
  [class.day-view]="currentViewType === 'Day'"
>
  <mbsc-eventcalendar
    #calendar
    [data]="activities"
    [options]="options"
    [view]="view"
    [headerTemplate]="customHeaderTemplate"
    [scheduleEventTemplate]="customActivityTemplate"
    [locale]="myLocale"
    [marked]="activities"
    [allDayText]="allDayTextValue"
    [timeFormat]="myTimeFormat"
    [(selectedDate)]="selectedDate"
    (onPageLoading)="onPageLoading($event)"
    (onPageChange)="onPageChange($event)"
    (onPageLoaded)="onPageLoaded($event)"
    (onSelectedDateChange)="onSelectedDateChange($event)"
    (onSelectedEventsChange)="onSelectedEventChange($event)"
    (onCellClick)="onCellClick($event)"
    (onEventClick)="onEventClick($event)"
    [showEventTooltip]="true"
    class="ind-calendar"
  >
    <ng-template #customHeaderTemplate>
      <div class="ind-calendar-header">
        <mbsc-button (click)="navigatePage(true)" icon="arrow-left5" variant="flat" class="md-custom-header-button" themeVariant="light"></mbsc-button>
        <div class="ind-calendar-custom-header-nav-wrapper">
          <mbsc-calendar-nav class="ind-calendar-custom-header-nav"></mbsc-calendar-nav>
        </div>
        <div class="ind-calendar-custom-header-view-controller">
          <ion-button fill="clear" size="small" (click)="onCalendarViewOptionBtnClick($event)">
            <span class="mbsc-calendar-month mbsc-calendar-title">
              {{currentViewLabel}}
            </span>
            <ion-icon src="assets/imgs/sort_with_double_arrows.svg" class="control-icon-sort sortIcon"></ion-icon>
          </ion-button>
        </div>
        <mbsc-button (click)="navigatePage(false)" icon="arrow-right5" variant="flat" class="md-custom-header-button" themeVariant="light"></mbsc-button>
      </div>
    </ng-template>

    <ng-template #customActivityTemplate let-data>
      <div class='mbsc-ios mbsc-schedule-event-background' [style]="{'background': data.original.color}"></div>
      <div aria-hidden="true" class='mbsc-ios mbsc-schedule-event-inner mbsc-schedule-event-small-height' style="color: rgb(0, 0, 0);">
        <span class='activity-text' [ngClass]="{'activity-completed-text': data.original.priorityId == 1, 'activity-open-text': data.original.priorityId == 2, 'time-off-text': data.original.priorityId == 3, 'follow-up-text': data.original.priorityId == 4, 'procedure-log-text': data.original.priorityId == 6}">
          <div *ngIf="data.original.proximityWarn || data.original.recommended" class="activity-header">
            <span class="mbsc-ios mbsc-schedule-event-title">{{data.original.text}}</span>
              <ion-icon *ngIf="data.original.proximityWarn" slot="end" class="end-icon proximity-warn" [src]="'proximity-warn'"
                [name]="'ProximityWarning'"></ion-icon>
              <ion-icon *ngIf="data.original.recommended" [ngClass]="data.original.proximityWarn ? 'extraMargin': ''" slot="end" class="end-icon recommended-star" [src]="'recommended-star'"
                [name]="'recommended-star'"></ion-icon>
          </div>
          <div class="mbsc-ios mbsc-schedule-event-title ind-activity-title" *ngIf="!data.original.proximityWarn && !data.original.recommended">{{data.original.text}}</div>
          <span *ngIf="data.original.type == 'Appointment' || data.original.type == 'Joint Meeting' || data.original.type == 'LiveMeet'" class="secondary-info-text">{{(data.original.location || 'NO_LOCATION') | translate}}</span>
          <span *ngIf="data.original.type == 'PhoneCall' || data.original.type == 'JointPhoneCall'" class="secondary-info-text">{{(data.original.selectedMobileNumber || 'NO_CONTACT_NUMBER') | translate}}</span>
          <span *ngIf="data.original.type == 'Sample'" class="secondary-info-text">{{data.original.contactName || 'No '+ utilityService.globalCustomerText}}</span>
          <span *ngIf="data.original.type == 'indskr_order'" class="secondary-info-text">{{(data.original.location || 'NO_ACCOUNT') | translate}}</span>
          <span *ngIf="data.original.type == 'Email'" class="secondary-info-text">{{data.original.info}}</span>
          <span *ngIf="data.original.type == 'TimeOffRequest' || data.original.type == 'TimeOff'" class="secondary-info-text">{{data.original.timeOffReason}}</span>
          <span *ngIf="data.original.type == 'CaseIntake'" class="secondary-info-text">{{data.original.info}}</span>
          <span *ngIf="data.original.type == 'SurgeryOrder'" class="secondary-info-text">{{(data.original.account || 'NO_ACCOUNT') | translate}}</span>
          <span *ngIf="data.original.type == 'ProcedureTracker'" class="secondary-info-text">{{(data.original.account || 'NO_ACCOUNT') | translate}}</span>
          <span *ngIf="data.original.type == 'StoreCheck'" class="secondary-info-text">{{data.original.location}}</span>
          <span *ngIf="data.original.type == 'SetBooking'" class="secondary-info-text">{{data.original.location}}</span>
        </span>
      </div>
    </ng-template>

  </mbsc-eventcalendar>
</ion-content>
