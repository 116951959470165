<ion-header mode="md">
  <ion-toolbar class="header">
    <ion-title>{{ header }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="icon-only" (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<div *ngIf="alertType === 0">
  <omni-list-item cssClass="omni-border-bottom ion-padding" label="{{ topLabel }}"
    value="{{ topValue }}"></omni-list-item>
  <omni-list-item cssClass="ion-padding" label="{{ bottomLabel }}" value="{{ bottomValue }}"></omni-list-item>
</div>

<div class="wrapper">
  <div *ngIf="alertType === 1" class="notification-div">
    <ion-label>{{ notificationMessage }}</ion-label>
  </div>
  
  <div *ngIf="buttons.length > 0">
    <ion-button *ngFor="let customButton of buttons" fill="clear"  class="alert-btn ion-float-right"
      (click)="onButtonClick(customButton)">{{ customButton.label }}</ion-button>
  </div>

</div>
