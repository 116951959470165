<ind-section-header [viewData]='procedureStatsSectionTitle'></ind-section-header>
<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="bwRepArrivalsField" (timeSelected)="setProcedureTime('indskr_bwreparrives', $event)"
      class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_bwreparrives')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_bwreparrives ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="ptInRoomField" (timeSelected)="setProcedureTime('indskr_ptinroom', $event)"
      class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_ptinroom')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_ptinroom ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="ptReadyField" (timeSelected)="setProcedureTime('indskr_ptready', $event)"
      class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_ptready')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_ptready ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="procStartField" (timeSelected)="setProcedureTime('indskr_procstartneedle', $event)"
      class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_procstartneedle')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_procstartneedle ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="transseptalAccessField"
      (timeSelected)="setProcedureTime('indskr_transseptalaccess', $event)" class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_transseptalaccess')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_transseptalaccess ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>

  <ion-col size="12" size-md="6" class="time-col">
    <ion-row>
      <ion-col size="12" size-md="6" class="time-col">
        <ind-form-field [viewData]="transseptalAcessMintutesFluorotimeField" class="time-field"></ind-form-field>
      </ion-col>
      <ion-col size="12" size-md="6" class="time-col">
        <ind-form-field [viewData]="transseptalAcessSecondsFluorotimeField" class="time-field"></ind-form-field>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="mappingCompleteField"
      (timeSelected)="setProcedureTime('indskr_mappingcomplete', $event)" class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_mappingcomplete')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_mappingcomplete ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>

  <ion-col size="12" size-md="6" class="time-col">
    <ion-row>
      <ion-col size="12" size-md="6" class="time-col">
        <ind-form-field [viewData]="mappingCompleteFluorotimeMintutesField" class="time-field"></ind-form-field>
      </ion-col>
      <ion-col size="12" size-md="6" class="time-col">
        <ind-form-field [viewData]="mappingCompleteFluorotimeSecondsField" class="time-field"></ind-form-field>
      </ion-col>
    </ion-row>
  </ion-col>
  <!-- <ion-col size="12" size-md="6" class="time-col">
    <ind-form-field [viewData]="mappingCompleteFluorotimeField" class="time-field"></ind-form-field>
  </ion-col> -->
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="procedureCompleteField"
      (timeSelected)="setProcedureTime('indskr_procedurecomplete', $event)" class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_procedurecomplete')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_procedurecomplete ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>

  <ion-col size="12" size-md="6" class="time-col">
    <ion-row>
      <ion-col size="12" size-md="6" class="time-col">
        <ind-form-field [viewData]="procedureCompleteMintutesFluorotimeField" class="time-field"></ind-form-field>
      </ion-col>
      <ion-col size="12" size-md="6" class="time-col">
        <ind-form-field [viewData]="procedureCompleteSecondsFluorotimeField" class="time-field"></ind-form-field>
      </ion-col>
    </ion-row>

    <!-- <ind-form-field [viewData]="procedureCompleteFluorotimeField" class="time-field"></ind-form-field> -->
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="ptOutOfRoomField" (timeSelected)="setProcedureTime('indskr_ptoutofroom', $event)"
      class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_ptoutofroom')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_ptoutofroom ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" size-md="6" class="time-col">
    <ind-datetime-form [viewData]="bwRepLeavesField" (timeSelected)="setProcedureTime('indskr_bwrepleaves', $event)"
      class="time-field"></ind-datetime-form>
    <ion-button [disabled]="currentSurgeryOrder.status !== 1" fill="clear" (click)="setProcedureTime('indskr_bwrepleaves')"> <ion-icon slot="icon-only"
        src="{{currentSurgeryOrder.indskr_bwrepleaves ? 'assets/imgs/timer_blue.svg' : 'assets/imgs/tools_timeoff.svg' }}"></ion-icon></ion-button>
  </ion-col>
</ion-row>

<ind-section-header [viewData]='cartoStatsSectionTitle' (onControlClick)='onProcedureCartoSectionClick($event)'></ind-section-header>

<ion-row class="ion-margin-bottom"  *ngIf="isCartoStatsEnabled && activeConsents.length > 0">
  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="totalmappingPointsField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="averageRfPowerWattsField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="posteriorLeftField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="ablPointsField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="posteriorRightField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="interiorLeftFields"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="interiorRightField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="anteriorLeftField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="anteriorRightField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="roofLeftField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="roofRightField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="superiorLeftField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="superiorRightField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="ridgeField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="fluidDeliveredField"></ind-form-field>
  </ion-col>

  <ion-col size="12" size-md="6">
    <ind-form-field [viewData]="cTMergedField"></ind-form-field>
  </ion-col>
</ion-row>