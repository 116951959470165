import { CRMInstance } from "./instance.class";
import { Domain } from "../domain/domain.class";
import { TeamsConfig } from './teams.config.class';

export class UserConfig {
    public familyName: string;
    public givenName: string;
    public fullName: string;
    public CRMInstances: Array<CRMInstance>;
    public refreshToken: string;
    public defaultInstance: CRMInstance;
    public activeInstance: CRMInstance;
    public teamsConfig: TeamsConfig;

    constructor(raw: object) {
        this.familyName = raw['familyName'];
        this.givenName = raw['givenName'];
        this.fullName = raw['fullName'];
        this.refreshToken = raw['refreshToken'];

        let instances = raw['crmInstances'];
        if (instances) {
            this.CRMInstances = [];
            instances.map(instance => {
                let newInstance: CRMInstance = {
                    id: instance.Id,
                    appCenterSecret: instance.AppCenterSecret,
                    appInsightsInstrumentationKey: instance.AppInsightsInstrumentationKey,
                    dnaDashboardUrl: instance.DnaDashboardUrl,
                    entryPointUrl: instance.EntryPointUrl,
                    urlName: instance.UrlName,
                    friendlyName: instance.FriendlyName,
                    OmnipresenceWfeUrl: instance.OmnipresenceWfeUrl,
                    url: instance.Url,
                    geneeSecret: instance.GeneeSecret,
                    geneeVersion: instance.GeneeVersion,
                    veevaConfig: instance.veevaConfiguration,
                    EdgeAnalyticsUrl:instance.EdgeAnalyticsUrl || ''
                }

                this.CRMInstances.push(newInstance);

                if (!this.defaultInstance) this.defaultInstance = newInstance;
            });
        }
    }
}
