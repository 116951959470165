import { ActionReducerMap } from '@ngrx/store';
import { appState, fileState, ResourceState } from './application.state';
import { fileManagerState } from './io-file-service/reducers/file.reducer';
import { resourceManagerState } from './io-file-service/reducers/resource.reducer';

export const appReducers: ActionReducerMap<appState> = {
    fileManager: fileManagerState,
    resourceManager: resourceManagerState
}

export const fileReducers: ActionReducerMap<fileState> = {
    fileManager: fileManagerState,
}
export const resourceReducers: ActionReducerMap<ResourceState> = {
    resourceManager: resourceManagerState,
}