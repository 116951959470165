export const ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY = 'indskr_accountaccountaffiliation';
export const ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID = 'account-account-affiliation';
export const ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME = 'indskr_affiliatedtoaccountid';
export const ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME = 'indskr_affiliatedfromaccountid';
export const ACCOUNT_ACCOUNT_SOURCE_TYPE_ATTRIBUTE_NAME = 'indskr_affiliationsourcetype';

export const ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY = 'indskr_accountaccountaffiliationcr';
export const ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME = 'indskr_affiliatedtoaccountid';
export const ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME = 'indskr_affiliatedfromaccountid';

export const CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY = 'indskr_contactrelationship';
export const CONTACT_CONTACT_AFFILIATIONS_VIEW_ID = 'contact-contact-affiliation';
export const CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME = 'indskr_relatedcontactid';
export const CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME = 'indskr_contactid';
export const CONTACT_CONTACT_SOURCE_TYPE_ATTRIBUTE_NAME = 'indskr_relationshipsourcetype';

export const CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY = 'indskr_contactrelationshipcr';
export const CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME = 'indskr_relatedcontactid';
export const CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME = 'indskr_contactcr';

export const ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY = 'indskr_accountcontactaffiliation';
export const ACCOUNT_CONTACT_SOURCE_TYPE_ATTRIBUTE_NAME = 'indskr_affiliationsourcetype';
export const ACCOUNT_CONTACT_AFFILIATIONS_VIEW_ID = 'account-contact-affiliation';
export const ACCOUNT_CONTACT_AFFILIATIONS_LABEL_ATTRIBUTE = 'indskr_contactid';
export const CONTACT_ACCOUNT_AFFILIATIONS_VIEW_ID = 'contact-account-affiliation';
export const CONTACT_ACCOUNT_AFFILIATIONS_LABEL_ATTRIBUTE = 'indskr_accountid';
