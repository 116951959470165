export const ASSESS_TEMPLATE_FETCHXML = `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
<entity name="indskr_assessmenttemplate">
  <attribute name="indskr_assessmenttemplateid" />
  <attribute name="indskr_entityoptions" />
  <attribute name="indskr_type"/>
  <attribute name="indskr_surveytype"/>
  <attribute name="indskr_surveyfrequency"/>
  <attribute name="indskr_metadata" />
  <attribute name="indskr_name" />
  <attribute name="indskr_validate" />
  <attribute name="indskr_validfrom" />
  <attribute name="indskr_validto" />
  <attribute name="statuscode" />
  <attribute name="statecode" />
  <attribute name="createdon" />
  <filter type="and">
    <condition attribute="indskr_type" operator="eq" value="600000000" />
  </filter>
  <link-entity name="goalrollupquery" from="goalrollupqueryid" to="indskr_queryid" link-type="outer" alias="ab" >
<attribute name="fetchxml" />
</link-entity>
  <filter type="and">
    {statusCondition}
    <condition attribute="indskr_validfrom" operator="le" value="{0}"/>
    <condition attribute="indskr_validto" operator="ge" value="{0}"/>
    {DeltaCondition}
  </filter>
  <link-entity name="indskr_mobileapprole" alias="as" to="indskr_assessmenttemplateid" from="indskr_assessmenttemplate">
    {statecodeAttribute}
    <filter type="and">
      {RoleStatusCondition}
      <condition attribute="indskr_businessunit" operator="eq" value="{BUID}" />
    </filter>
    <link-entity name="role" alias="at" to="indskr_securityrole" from="roleid">
      <link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">
        <filter type="and">
          <condition attribute="systemuserid" operator="eq-userid" />
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
</entity>
</fetch>`


export const CUSTOMER_SURVEY_TEMPLATE_FETCHXML_ROLE =`<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
<entity name="indskr_assessmenttemplate">
  <attribute name="indskr_assessmenttemplateid" />
  <attribute name="indskr_entityoptions" />
  <attribute name="indskr_type"/>
  <attribute name="indskr_surveytype"/>
  <attribute name="indskr_surveyfrequency"/>
  <attribute name="indskr_metadata" />
  <attribute name="indskr_name" />
  <attribute name="indskr_validate" />
  <attribute name="indskr_validfrom" />
  <attribute name="indskr_validto" />
  <attribute name="statuscode" />
  <attribute name="statecode" />
  <attribute name="createdon" />
  <filter type="and">
    <condition attribute="indskr_type" operator="eq" value="600000001" />
  </filter>
  <link-entity name="goalrollupquery" from="goalrollupqueryid" to="indskr_queryid" link-type="outer" alias="ab" >
<attribute name="fetchxml" />
</link-entity>
  <filter type="and">
    {statusCondition}
    {DeltaCondition}
  </filter>
  <link-entity name="indskr_mobileapprole" alias="as" to="indskr_assessmenttemplateid" from="indskr_assessmenttemplate">
    {statecodeAttribute}
    <filter type="and">
      {RoleStatusCondition}
      <condition attribute="indskr_businessunit" operator="eq" value="{BUID}" />
    </filter>
    <link-entity name="role" alias="at" to="indskr_securityrole" from="roleid">
      <link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">
        <filter type="and">
          <condition attribute="systemuserid" operator="eq-userid" />
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`


export const CUSTOMER_SURVEY_TEMPLATE_FETCHXML_POSITION =`<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
<entity name="indskr_assessmenttemplate">
  <attribute name="indskr_assessmenttemplateid" />
  <attribute name="indskr_entityoptions" />
  <attribute name="indskr_type"/>
  <attribute name="indskr_surveytype"/>
  <attribute name="indskr_surveyfrequency"/>
  <attribute name="indskr_customerssurveyed"/>
  <attribute name="indskr_metadata" />
  <attribute name="indskr_name" />
  <attribute name="indskr_validate" />
  <attribute name="indskr_validfrom" />
  <attribute name="indskr_validto" />
  <attribute name="statuscode" />
  <attribute name="statecode" />
  <attribute name="createdon" />
  <filter type="and">
    <condition attribute="indskr_type" operator="eq" value="600000001" />
  </filter>
  <link-entity name="goalrollupquery" from="goalrollupqueryid" to="indskr_queryid" link-type="outer" alias="ab" >
<attribute name="fetchxml" />
</link-entity>
  <filter type="and">
    {statusCondition}
    {DeltaCondition}
  </filter>
  <link-entity name="indskr_indskr_assessmenttemplate_indskr_positi" from="indskr_assessmenttemplateid" to="indskr_assessmenttemplateid" visible="false" intersect="true">
      <link-entity name="indskr_positiongroups" from="indskr_positiongroupsid" to="indskr_positiongroupsid" alias="bq">
        <link-entity name="indskr_indskr_positiongroups_position_n2n" from="indskr_positiongroupsid" to="indskr_positiongroupsid" visible="false" intersect="true">
          <link-entity name="position" from="positionid" to="positionid" alias="br">
            <link-entity name="indskr_position_systemuser" from="positionid" to="positionid" visible="false" intersect="true">
              <link-entity name="systemuser" from="systemuserid" to="systemuserid" alias="bs">
                <filter type="and">
                  <condition attribute="systemuserid" operator="eq-userid" />
                </filter>
              </link-entity>
            </link-entity>
          </link-entity>
        </link-entity>
      </link-entity>
    </link-entity>
</entity>
</fetch>
`

export const ASSESS_TEAMVIEW_TEMPLATES_FETCHXML = `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
<entity name="indskr_assessmenttemplate">
  <attribute name="indskr_assessmenttemplateid" />
  <attribute name="indskr_entityoptions" />
  <attribute name="indskr_metadata" />
  <attribute name="indskr_name" />
  <attribute name="indskr_validate" />
  <attribute name="indskr_validfrom" />
  <attribute name="indskr_validto" />
  <attribute name="statuscode" />
  <attribute name="statecode" />
  <attribute name="indskr_type" />
  <filter type="and">
    {statusCondition}
    <condition attribute="indskr_validfrom" operator="le" value="{0}"/>
    <condition attribute="indskr_validto" operator="ge" value="{0}"/>
    {DeltaCondition}
  </filter>
  <link-entity name='indskr_mobileapprole' from='indskr_assessmenttemplate' to='indskr_assessmenttemplateid' link-type='inner' alias='aq'>
    {statecodeAttribute}
    <filter type="and">
      {RoleStatusCondition}
      <condition attribute='indskr_securityrole' operator='in'>
        {rolesCondition}
      </condition>
    </filter>    
  </link-entity>
</entity>
</fetch>`

export const ASSESS_TEAMVIEW_ROLES = `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
<entity name="role">
  <attribute name='name'/>
  <attribute name='businessunitid'/>
  <attribute name='roleid'/>
  <link-entity name='systemuserroles' from='roleid' to='roleid' visible='false' intersect='true'>
    <link-entity name='systemuser' from='systemuserid' to='systemuserid' alias='ae'>
      <link-entity name='indskr_position_systemuser' from='systemuserid' to='systemuserid' visible='false' intersect='true'>
        <link-entity name='position' from='positionid' to='positionid' alias='af'>
          <filter type='and'>
            <condition attribute='indskr_status' operator='eq' value='100000000'/>
            <condition attribute='positionid' operator='in'>
              {positionsCondition}
            </condition>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
  </link-entity>
</entity>
</fetch>`

export const CUSTOMER_ASSESS_TEAMVIEW_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_customerassessmentresponse">
  <all-attributes/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    <filter type="or">
      <condition attribute="indskr_businessunit" operator="not-null"/>
      <condition attribute="indskr_procedures" operator="not-null"/>
    </filter>
  </filter>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca" >
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_name" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_assessmentdate" />
    <filter type="and">
      <condition attribute="indskr_entityid" operator="eq" value="{indskr_entityid}" />
      <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
      <condition attribute="indskr_template" operator="eq" value="{indskr_template}" />
      {DeltaCondition}
    </filter>
  </link-entity>
</entity>
</fetch>
`

export const ASSESSMENT_TEMPLATE_STATUS_CONDITION = `<condition attribute="statecode" value="1" operator="eq" />
<condition attribute="statuscode" value="2" operator="eq" />`

export const CUSTOMER_ASSESS_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_customerassessmentresponse">
  <all-attributes/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    <filter type="or">
      <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
      <condition attribute="indskr_procedures" operator="not-null"/>
    </filter>
  </filter>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca" >
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_name" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_assessmentdate" />
    <filter type="and">
      <condition attribute="indskr_entityid" operator="eq" value="{indskr_entityid}" />
      <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
      <condition attribute="indskr_template" operator="eq" value="{indskr_template}" />
      {DeltaCondition}
    </filter>
  </link-entity>
</entity>
</fetch>
`

export const ALL_CUSTOMER_ASSESS_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" count="5000">
<entity name="indskr_customerassessmentresponse">
  <all-attributes/>
  <order attribute="indskr_customerassessmentresponseid" descending="false"/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    {DeltaCondition} 
  </filter>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca" >
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_name" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_assessmentdate" />
    <link-entity name='contact' from='contactid' to='indskr_customer' link-type='inner' alias='dj'>
        <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='dk'>
          <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='dl'>
            <filter type='or'>
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
    </link-entity>
    {templateCondition}
  </link-entity>
</entity>
</fetch>
`

export const CUSTOMER_SURVEY_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_customerassessment">
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_customer" />
    <attribute name="indskr_assessmentdate" />
    <attribute name="indskr_appointment" />
    <attribute name="statuscode" alias="surveyStatus"/>
    <attribute name="modifiedby"/>
    <order attribute="indskr_assessmentdate" descending="false" />
    <filter type="and">
      <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
      <condition attribute="indskr_entityid" operator="eq" value="{indskr_entityid}" />
      {OfflineDurationCondition}
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="aa">
      <link-entity name="indskr_userposition" from="indskr_userid" to="systemuserid" link-type="inner" alias="ab">
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ac">
          <filter type="or">
            <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
            <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="ap">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="1"/>
        <condition attribute="indskr_assessmenttemplateid" operator="eq" value="{indskr_template}" />
      </filter>
    </link-entity>
    <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ae">
      <filter type="and">
        <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
      </filter>
    </link-entity> 
  </entity>
</fetch>
`

export const ALL_CUSTOMER_SURVEY_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" count="5000" >
<entity name="indskr_customerassessment">
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_name" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_customer" />
    <attribute name="indskr_assessmentdate" />
    <attribute name="indskr_appointment" />
    <attribute name="statuscode" alias="surveyStatus"/>
    <attribute name="modifiedby"/>
    <attribute name="indskr_affiliatedcontact" />
    <order attribute="indskr_assessmentdate" descending="false" />
    <filter type="and">
      <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
      <condition attribute="statecode" operator="eq" value="0" />
      {DeltaCondition}
      {OfflineDurationCondition}
    </filter>
    <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="aa">
      <link-entity name="indskr_userposition" from="indskr_userid" to="systemuserid" link-type="inner" alias="ab">
        <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ac">
          <filter type="or">
            <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
            <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
          </filter>
        </link-entity>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="ap">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="1"/>
        <condition attribute="indskr_assessmenttemplateid" operator="in">
          {templatesCondition}
        </condition>
      </filter>
    </link-entity>
    <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ae">
      <filter type="and">
        <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
      </filter>
    </link-entity>    
  </entity>
</fetch>
`

export const CUSTOMER_SURVEY_DETAILS_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_customerassessmentresponse">
  <all-attributes/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    <filter type="or">
      <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
    </filter>
  </filter>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca" >
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_name" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_assessmentdate" />
    <attribute name="indskr_customer" />
    <attribute name="indskr_appointment" />
    <attribute name="indskr_affiliatedcontact" />
    <filter type="and">
      <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
      <condition attribute="indskr_customerassessmentid" operator="eq" value="{customerAssessmentId}" />
    </filter>
    <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="te">
      <attribute name="indskr_type" />
      <attribute name="indskr_surveytype" />
      <attribute name="indskr_surveyfrequency" />
    </link-entity>
  </link-entity>
</entity>
</fetch>
`

export const ALL_CUSTOMER_SURVEY_TEMPLATE_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_customerassessment">
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="modifiedon" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_customer" />
    <attribute name="indskr_assessmentdate" />
    <attribute name="indskr_appointment" />
    <attribute name="indskr_affiliatedcontact" />
    <filter type="and">
      <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
      <condition attribute="statecode" operator="eq" value="0" />
      <condition attribute="indskr_template" operator="in">
        {templatesCondition}
      </condition>
      {DeltaCondition}
    </filter>
    <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ae">
      <all-attributes/>
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
        <filter type="or">
          <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
          <condition attribute="indskr_procedures" operator="not-null"/>
        </filter>
      </filter>
    </link-entity>
    <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="aa">
      <attribute name="indskr_type" />
      <attribute name="indskr_surveytype" />
      <attribute name="indskr_surveyfrequency" />
    </link-entity>
  </entity>
</fetch>
`

export const CUSTOMER_ASSESS_FOR_SEARCH_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" count="5000" >
<entity name="indskr_customerassessmentresponse">
  <attribute name="indskr_customerassessmentresponseid" />
  <attribute name="indskr_customerassessment" />
  <attribute name="indskr_name" />
  <attribute name="indskr_attribute" />
  <attribute name="indskr_type" />
  <attribute name="indskr_therapeuticarea" />
  <attribute name="indskr_product" />
  <attribute name="indskr_position" />
  <attribute name="indskr_flag" />
  <attribute name="indskr_text" />
  <attribute name="indskr_choicevalues" />
  <attribute name="indskr_choicelabel" />
  <attribute name="indskr_choice" />
  <attribute name="modifiedon" /> 
  <attribute name="owninguser" />
  <order attribute="indskr_customerassessmentresponseid" descending="false"/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    {DeltaCondition} 
  </filter>
  <link-entity name="indskr_assessmentattribute" from="indskr_assessmentattributeid" to="indskr_attribute" link-type="inner" alias="ba">
      <filter type="and">
        <condition attribute="indskr_type" operator="in">
          {TypeCondition}
        </condition>
      </filter>
      <link-entity name="indskr_assessmentsectionattribute" from="indskr_attribute" to="indskr_assessmentattributeid" link-type="inner" alias="ao">
      <filter type="and">
        <condition attribute="indskr_searchable" operator="eq" value="1" />
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca">
    <attribute name="indskr_customer" />
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_name" />
    <attribute name="indskr_entityid" />
    <link-entity name='contact' from='contactid' to='indskr_customer' link-type='inner' alias='dj'>
        <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='dk'>
          <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='dl'>
            <filter type='or'>
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
    </link-entity>
    {templateCondition}
  </link-entity>
</entity>
</fetch>`

export const ACCOUNT_ASSESS_FOR_SEARCH_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true" count="5000" >
<entity name="indskr_customerassessmentresponse">
  <attribute name="indskr_customerassessmentresponseid" />
  <attribute name="indskr_customerassessment" />
  <attribute name="indskr_name" />
  <attribute name="indskr_attribute" />
  <attribute name="indskr_type" />
  <attribute name="indskr_therapeuticarea" />
  <attribute name="indskr_product" />
  <attribute name="indskr_position" />
  <attribute name="indskr_flag" />
  <attribute name="indskr_text" />
  <attribute name="indskr_choicevalues" />
  <attribute name="indskr_choicelabel" />
  <attribute name="indskr_choice" />
  <attribute name="modifiedon" /> 
  <attribute name="owninguser" />
  <order attribute="indskr_customerassessmentresponseid" descending="false"/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
    {DeltaCondition} 
  </filter>
  <link-entity name="indskr_assessmentattribute" from="indskr_assessmentattributeid" to="indskr_attribute" link-type="inner" alias="ba">
      <filter type="and">
        <condition attribute="indskr_type" operator="in">
          {TypeCondition}
        </condition>
      </filter>
      <link-entity name="indskr_assessmentsectionattribute" from="indskr_attribute" to="indskr_assessmentattributeid" link-type="inner" alias="ao">
      <filter type="and">
        <condition attribute="indskr_searchable" operator="eq" value="1" />
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca">
    <attribute name="indskr_customer" />
    <attribute name="indskr_customerassessmentid" />
    <attribute name="indskr_template" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_name" />
    <attribute name="indskr_entityid" />
    <link-entity name='account' from='accountid' to='indskr_customer' link-type='inner' alias='dj'>
        <link-entity name='indskr_customerposition' from='indskr_customerid' to='accountid' link-type='inner' alias='dk'>
          <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='dl'>
            <filter type='or'>
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
    </link-entity>
    {templateCondition}
  </link-entity>
</entity>
</fetch>`

export const POSITION_PRODUCT_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="product">
  <attribute name="productid" alias="productId"/>
  <filter type="and">
    <condition attribute="indskr_producttype" operator="eq" value="100000002"/>
    <condition attribute="indskr_competitorproduct" operator="ne" value="1" />
    <condition attribute="statuscode" operator="eq" value="1"/>
  </filter>
  <link-entity name="indskr_productassignment" from="indskr_productid" to="productid" alias="aa">
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0"/>
    </filter>
    <link-entity name="position" from="positionid" to="indskr_positionid" alias="af" distinct="true">
      <attribute name="positionid" alias="positionId"/>
      <filter type="or">
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000"/>
          <condition attribute="positionid" operator="in">{0} </condition>
        </filter>
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000"/>
          <condition attribute="parentpositionid" operator="in">{0}</condition>
        </filter>
      </filter>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`

export const POSITIONGROUP_PRODUCT_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="systemuser">
  <filter type="and">
    <condition attribute="systemuserid" operator="eq-userid" />
  </filter>
  <link-entity name="indskr_position_systemuser" from="systemuserid" to="systemuserid" visible="false">
    <link-entity name="position" from="positionid" to="positionid" alias="ak">
  <attribute name="positionid" alias="positionId"/>
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
  </filter>
      <link-entity name="indskr_indskr_positiongroups_position_n2n" from="positionid" to="positionid" visible="false">
        <link-entity name="indskr_positiongroups" from="indskr_positiongroupsid" to="indskr_positiongroupsid" alias="al">
    <attribute name="indskr_positiongroupsid" alias="positionGroupId"/>
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    </link-entity>
      </link-entity>
      <link-entity name="indskr_position_product" from="positionid" to="positionid" visible="false">
        <link-entity name="product" from="productid" to="productid" alias="am">
    <attribute name="productid" alias="productId"/>
    <filter type="and">
      <condition attribute="indskr_producttype" operator="eq" value="100000002"/>
      <condition attribute="indskr_competitorproduct" operator="ne" value="1" />
      <condition attribute="statuscode" operator="eq" value="1"/>
    </filter>
    <link-entity name="indskr_productassignment" from="indskr_productid" to="productid" alias="aa">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="0"/>
      </filter>
    </link-entity>
    </link-entity>
      </link-entity>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`

export const ASSESSED_CONTACTS_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="contact">
  <attribute name="contactid" />
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
  </filter>
  <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner" alias="indskr_customerpositionfilter">
    <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
      <filter type="or">
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
          <condition attribute="positionid" operator="in">                    {positionIds}                </condition>
        </filter>
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
          <condition attribute="parentpositionid" operator="in">                    {positionIds}                </condition>
        </filter>
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_customerassessment" from="indskr_customer" to="contactid" link-type="inner" alias="ad">
    <filter type="and">
      <condition attribute="indskr_template" operator="eq" value="{templateId}"/>
    </filter>
    <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ae" >
      <filter type="and">
        <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
      </filter>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`

export const ASSESSED_ACCOUNTS_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="account">
  <attribute name="accountid" />
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
  </filter>
  <link-entity name="indskr_customerposition" from="indskr_customerid" to="accountid" link-type="inner" alias="aa">
    <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ab" >
      <filter type="or">
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
          <condition attribute="positionid" operator="in">                    {positionIds}                </condition>
        </filter>
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
          <condition attribute="parentpositionid" operator="in">                    {positionIds}                </condition>
        </filter>
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_customerassessment" from="indskr_customer" to="accountid" link-type="inner" alias="ac">
    <filter type="and">
      <condition attribute="indskr_template" operator="eq" value="{templateId}"/>
    </filter>
    <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ad" >
      <filter type="and">
        <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
      </filter>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`

export const PROCEDURES_FOR_SPECIALTY = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="contact">
  <filter type="and">
    <condition attribute="statecode" operator="eq" value="0" />
  </filter>
  <link-entity name="indskr_customerposition" from="indskr_customerid" to="contactid" link-type="inner">
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner">
      <filter type="or">
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
          <condition attribute="positionid" operator="in">
            {positionIds}
          </condition>
        </filter>
        <filter type="and">
          <condition attribute="indskr_status" operator="eq" value="100000000" />
          <condition attribute="parentpositionid" operator="in">
            {positionIds}
          </condition>
        </filter>
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_lu_specialty" from="indskr_lu_specialtyid" to="indskr_lu_specialtyid" alias="ad">
    <attribute name="indskr_lu_specialtyid" alias="specialtyId"/>
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
    <link-entity name="indskr_indskr_lu_specialty_product" from="indskr_lu_specialtyid" to="indskr_lu_specialtyid" visible="false" intersect="true">
      <link-entity name="product" from="productid" to="productid" alias="ab" >
        <attribute name="name" alias="productName"/>
        <attribute name="productid" alias="indskr_productid"/>
        <filter type="and">
          <condition attribute="indskr_bundletype" operator="eq" value="548910000" />
          <condition attribute="indskr_forassessment" operator="eq" value="1" />
          <condition attribute="productstructure" operator="eq" value="3" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      </link-entity>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`

export const USER_SURVEY_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
<entity name="indskr_usersurveyresponse">
  <attribute name="indskr_usersurveyresponseid" />
  <attribute name="indskr_name" />
  <attribute name="createdon" />
  <attribute name="indskr_usersurvey" />
  <attribute name="indskr_userprimaryposition" />
  <attribute name="indskr_type" />
  <attribute name="indskr_timestamp" />
  <attribute name="indskr_therapeuticarea" />
  <attribute name="indskr_text" />
  <attribute name="indskr_specialty" />
  <attribute name="indskr_product" />
  <attribute name="indskr_positiongroup" />
  <attribute name="indskr_position" />
  <attribute name="indskr_numeric" />
  <attribute name="modifiedon" />
  <attribute name="indskr_memo" />
  <attribute name="indskr_flag" />
  <attribute name="createdonbehalfby" />
  <attribute name="indskr_choicevalues" />
  <attribute name="indskr_choicelabel" />
  <attribute name="indskr_choice" />
  <attribute name="indskr_attribute" />
  <order attribute="indskr_name" descending="false" />
  <filter type="and">
    {DeltaCondition} 
  </filter>
  <link-entity name="indskr_usersurvey" from="indskr_usersurveyid" to="indskr_usersurvey" link-type="inner" alias="ca">
    <attribute name="indskr_user" />
    <attribute name="indskr_template" />
    <attribute name="indskr_surveydate" />
    <attribute name="indskr_name" />
    <attribute name="modifiedon" />
    <attribute name="modifiedonbehalfby" />
    <attribute name="indskr_appointment" />
    <attribute name="statuscode" alias="surveyStatus"/>
    <filter type="and">
      <condition attribute="indskr_user" operator="eq"  value="{userID}" />
      <condition attribute="statecode" operator="eq" value="0" />
    </filter>
  </link-entity>
</entity>
</fetch>`

  export const SURVEY_FOR_TIMELINE_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_customerassessment">
      <attribute name="indskr_customerassessmentid" />
      <attribute name="indskr_name" />
      <attribute name="indskr_template" />
      <attribute name="indskr_entityid" />
      <attribute name="indskr_customer" />
      <attribute name="indskr_assessmentdate" />
      <attribute name="statuscode" alias="surveyStatus"/>
      <attribute name="modifiedby"/>
      <order attribute="indskr_assessmentdate" descending="false" />
      <filter type="and">
        <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
        <condition attribute="indskr_entityid" operator="eq" value="{indskr_entityid}" />
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="aa">
        <link-entity name="indskr_userposition" from="indskr_userid" to="systemuserid" link-type="inner" alias="ab">
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ac">
            <filter type="or">
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="ap">
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="1"/>
          <condition attribute="indskr_assessmenttemplateid" operator="in">
            {templatesCondition}
          </condition>
        </filter>
      </link-entity>
      <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ae">
        <filter type="and">
          <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
        </filter>
      </link-entity>    
    </entity>
  </fetch>
  `

  export const SURVEYED_ENTITIES_FOR_ONETIME_SURVEY_FETCHXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
    <entity name="indskr_customerassessment">
      <attribute name="indskr_entityid" />
      <filter type="and">
        <condition attribute="indskr_entity" operator="eq" value="{indskr_entity}" />
        <condition attribute="statuscode" operator="in">
          <value>1</value>
          <value>548910001</value>
        </condition>
        <condition attribute="statecode" operator="eq" value="0" />
      </filter>
      <link-entity name="systemuser" from="systemuserid" to="owninguser" link-type="inner" alias="aa">
        <link-entity name="indskr_userposition" from="indskr_userid" to="systemuserid" link-type="inner" alias="ab">
          <link-entity name="position" from="positionid" to="indskr_positionid" link-type="inner" alias="ac">
            <filter type="or">
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="ap">
        <attribute name="indskr_assessmenttemplateid" alias="templateid" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="1"/>
          <condition attribute="indskr_surveyfrequency" operator="eq" value="600000000"/>
          <condition attribute="indskr_assessmenttemplateid" operator="in">
            {templatesCondition}
          </condition>
        </filter>
      </link-entity>
      <link-entity name="indskr_customerassessmentresponse" from="indskr_customerassessment" to="indskr_customerassessmentid" link-type="inner" alias="ae">
        <filter type="and">
          <condition attribute="indskr_businessunit" operator="eq" value="{BUId}" />
        </filter>
      </link-entity>    
    </entity>
  </fetch>
  `

  export const NUMBER_OF_SURVEYED_FETCHXML =`<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">
    <entity name="indskr_assessmenttemplate">
      <attribute name="indskr_assessmenttemplateid" />
      <attribute name="indskr_customerssurveyed" />
      <filter type="and">
        <condition attribute="indskr_assessmenttemplateid" operator="in">
          {templatesCondition}
        </condition>
      </filter>
    </entity>
  </fetch>
  `

  export const CUSTOMER_ASSESS_ONLINE_SEARCH_FETCHXML = `
  <fetch version="1.0" output-format="xml-platform" latematerialize="true" mapping="logical" distinct="true">
  <entity name="indskr_customerassessmentresponse">
    <attribute name="indskr_customerassessmentresponseid" />
    <attribute name="indskr_name" />
    <attribute name="createdon" />
    <attribute name="indskr_therapeuticarea" />
    <attribute name="indskr_product" />
    <attribute name="indskr_position" />
    <attribute name="indskr_flag" />
    <attribute name="indskr_choicevalues" />
    <attribute name="indskr_choicelabel" />
    <attribute name="indskr_choice" />
    <order attribute="indskr_name" descending="false" />
    <filter type="and">
      {searchCondition}
      <condition attribute="indskr_name" operator="eq" value="{category}" />
    </filter>
    <link-entity name="indskr_assessmentattribute" from="indskr_assessmentattributeid" to="indskr_attribute" link-type="inner" alias="bs">
      <filter type="and">
        {typeCondition}
      </filter>
      <link-entity name="indskr_assessmentsectionattribute" from="indskr_attribute"
        to="indskr_assessmentattributeid" link-type="inner" alias="bt">
        <filter type="and">
          <condition attribute="indskr_searchable" operator="eq" value="1" />
        </filter>
      </link-entity>
    </link-entity>
    <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca">
      <attribute name="indskr_template" />
      <attribute name="indskr_entityid" />
      <attribute name="indskr_entity" />
      <attribute name="indskr_customer" />
      <link-entity name='contact' from='contactid' to='indskr_customer' link-type='inner' alias='dj'>
        <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='dk'>       
          <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='dl'>
            <filter type='or'>
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
      </link-entity>
      <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="bx">
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="1" />
          <condition attribute="indskr_assessmenttemplateid" operator="eq" value="{templateId}" />
        </filter>
      </link-entity>
    </link-entity>
  </entity>
</fetch>
`

export const ACCOUNT_ASSESS_ONLINE_SEARCH_FETCHXML = `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
<entity name="indskr_customerassessmentresponse">
  <attribute name="indskr_customerassessmentresponseid" />
  <attribute name="indskr_name" />
  <attribute name="createdon" />
  <attribute name="indskr_therapeuticarea" />
  <attribute name="indskr_product" />
  <attribute name="indskr_position" />
  <attribute name="indskr_flag" />
  <attribute name="indskr_choicevalues" />
  <attribute name="indskr_choicelabel" />
  <attribute name="indskr_choice" />
  <order attribute="indskr_name" descending="false" />
  <filter type="and">
    {searchCondition}
    <condition attribute="indskr_name" operator="eq" value="{category}" />
  </filter>
  <link-entity name="indskr_assessmentattribute" from="indskr_assessmentattributeid" to="indskr_attribute" link-type="inner" alias="bs">
    <filter type="and">
      {typeCondition}
    </filter>
    <link-entity name="indskr_assessmentsectionattribute" from="indskr_attribute"
      to="indskr_assessmentattributeid" link-type="inner" alias="bt">
      <filter type="and">
        <condition attribute="indskr_searchable" operator="eq" value="1" />
      </filter>
    </link-entity>
  </link-entity>
  <link-entity name="indskr_customerassessment" from="indskr_customerassessmentid" to="indskr_customerassessment" link-type="inner" alias="ca">
    <attribute name="indskr_template" />
    <attribute name="indskr_entityid" />
    <attribute name="indskr_entity" />
    <attribute name="indskr_customer" />
    <link-entity name='account' from='accountid' to='indskr_customer' link-type='inner' alias='dj'>
        <link-entity name='indskr_customerposition' from='indskr_customerid' to='accountid' link-type='inner' alias='dk'>
          <link-entity name='position' from='positionid' to='indskr_positionid' link-type='inner' alias='dl'>
            <filter type='or'>
              <condition attribute="positionid" operator="eq"  value="{userPosition}"/>
              <condition attribute="parentpositionid" operator="eq" value="{parentUserPosition}"/>
            </filter>
          </link-entity>
        </link-entity>
    </link-entity>
    <link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="bx">
      <filter type="and">
        <condition attribute="statecode" operator="eq" value="1" />
        <condition attribute="indskr_assessmenttemplateid" operator="eq" value="{templateId}" />
      </filter>
    </link-entity>
  </link-entity>
</entity>
</fetch>
`