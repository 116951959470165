<!-- Generated template for the InstanceSelectionComponent component -->
<ion-content class="instanceContent ion-padding">
  <!-- <img src="assets/imgs/login_logo.png" width="70px"> -->


  <div class="instanceCard">
    <div class="welcome ion-text-center">
      <h1>{{'WELLCOME' | translate}} {{authenticationOfflineService.userConfig.givenName}}</h1>
      <p>{{'SELECT_INSTANCE_BELOW' | translate}}</p>
    </div>

    <div class="header">
      <p style="padding-left:5px">{{'SELECT_AN_INSTANCE' | translate}}

      </p>
    </div>
    <div class="instance-list ion-margin-bottom">
      <ion-list >
        <ion-radio-group [(ngModel)]="selectedInstance">
          <ion-item class="item" *ngFor="let instance of authenticationOfflineService.userConfig.CRMInstances">
            <ion-label>{{instance.friendlyName}}</ion-label>
            <ion-radio slot="end" [value]="instance"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
    <ion-button class="continueButton" [disabled]="!selectedInstance" (click)="continueButtonClick()">{{'CONTINUE' | translate}}</ion-button>
  </div>


  <div class="footer ion-text-center">

    <a style="border-right:1px solid white" (click)="openPrivacyPopover()">{{'PRIVACY_POLICY' | translate}}</a>

    <a style="border-right:1px solid white" (click)="openTerms()">{{'TERMS_AND_CONDITIONS' | translate}}</a>
    <a (click)="openCopyright()">{{'COPYRIGHT_INFO' | translate}}</a>

  </div>
</ion-content>
