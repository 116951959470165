import { Component, Input } from '@angular/core';
import { IndDisplayFormAffiliationViewDataModel } from '../../../interfaces/dynamic-form/ind-display-form-affiliation-view-data.interface';

@Component({
  selector: 'ind-affiliations-card',
  templateUrl: 'ind-affiliations-card.html',
  styleUrls: ['ind-affiliations-card.scss']
})
export class IndAffiliationsCardComponent {
  @Input() viewData: IndDisplayFormAffiliationViewDataModel;

  constructor() {}

  ngOnInit() {
  }
  onContentClick(event) {
    if (this.viewData?.clickHandler) {
      this.viewData.clickHandler(this.viewData.id, event);
    }
  }
}
