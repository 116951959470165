import { Injectable } from "@angular/core";
import { Endpoints } from "../../../config/endpoints.config";
import { HttpClient } from "@angular/common/http";
import * as _ from 'lodash';
import { DynamicForm, ControlDataType, LookupQuery, LookupSearchResult, Option, Control, FormType, DisplayText, BusinessProcessType, SubgridLayout } from "../../classes/dynamic-form/dynamic-form.class";
import { FormFieldType } from "../../models/indFormFieldDataModel";
import { DynamicsClientService } from "../../data-services/dynamics-client/dynamics-client.service";
import { IndDropdownListDetailModel } from "../../models/indDropdownListModel";
import { SelectListData } from "../../components/popover/popover";
import { Observable, Subject } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { DiskService } from "../disk/disk.service";
import { AuthenticationService } from "../authentication.service";
import { MOBILE_FORMS_FETCHXML, MOBILE_FORMS_DELTA, MOBILE_FORMS_ID_FILTER, MOBILE_FORMS_BUSINESS_RULES } from "../../config/dynamic-forms/dynamic-forms-fetchxml";
import { differenceInHours, format, isBefore, isValid } from "date-fns";
import { fetchQueries } from "../../config/dynamics-fetchQueries";
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../localization/localization.service';
import { ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID,
         ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME, ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME, ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME,
         ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME, ACCOUNT_CONTACT_AFFILIATIONS_VIEW_ID, CONTACT_ACCOUNT_AFFILIATIONS_VIEW_ID,
         CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_VIEW_ID,
         CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME, CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME, CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME,
         CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME } from "../../config/dynamic-forms/affiliations-contants";
import { AffiliationsFilters } from "../../interfaces/dynamic-form/display-form.interface";
import { DEFAULT_CONTACT_CREATE_FORM, multilingualLanguageToAttributeMapping } from '@omni/config/dynamic-forms/default-contact/default-contact-create';
import { OmniAccordionViewDataModel } from "@omni/models/omniAccordionViewDataModel";
import { IndDisplayFormAffiliationViewDataModel } from "@omni/interfaces/dynamic-form/ind-display-form-affiliation-view-data.interface";
import { NotificationService, ToastStyle } from "../notification/notification.service";
import { LoadingController } from "@ionic/angular";
import { DeltaService } from '@omni/data-services/delta/delta.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DEFAULT_FORM_LANGUAGE_CODE } from "@omni/components/shared/ind-display-form/ind-display-form";
import { DynamicsConstant } from "@omni/utility/util";
// import { ContactOfflineService } from "../contact/contact.service";


@Injectable({
  providedIn: 'root'
})
export class DynamicFormsService {

  // public tabs: any[] = [];
  // public sections: Section[] = [];
  // public formFieldAttributes: any[] = [];

  private dynamicForms = [];
  public contactCreateForm: DynamicForm;
  public metadata: any;

  public searchResultsObs$: Observable<any>;
  public searchParamsSub = new Subject();

  public lookupSearchData:Array<LookupSearchResult>= [];
  public lookupSearchInProgress:boolean = false;
  private islookupSearchMultilingualData: boolean =false;

  public linkedEntityFormIsDirtyTracker:boolean = false;

  public isSelectedDataFromSearch: boolean = false;
  public isSelectedDataFromAddressOneKey: boolean = false;
  public isSearchedAddress: boolean = true;
  public storedLinkedEntityValues = [];
  public numOfLinkedEntity: {
    [x: string]: number
  };

  public mdmId: string;

  //Default Link Entity
  public contactDefaultLinkEntity = [
    "indskr_indskr_customeraddress_v2",
    "indskr_email_address",
    "indskr_accountcontactaffiliation",
  ]

  // Address auto-populated
  public selectedCountry: any;
  public selectedCity: any;
  public lookupSearchDataForCityBasedOnCountry: Array<LookupSearchResult>= [];
  public hasCityDataBasedOnCountry: boolean = false;
  public isSelectedCountry: boolean = false; // for checking mandatory field in Address CR
  public addressAttributeName: string;
  public accountAffiliationAttributeName: string;
  // Track affiliated Contact from Account
  public isNavAffiliatedContactFromAccount: boolean = false;
  public isOpenedAffiliatedContactOnAccount: boolean = false;
  public isOpenedAffiliatedContactOnContact: boolean = false;
  public isOpenedAffiliatedAccountOnContact: boolean = false;
  public isOpenedAffiliatedAccountOnAccount: boolean = false;
  public okAddressMapper: any = {};
  public okCRMapper: any = {};
  public okLookUpMapper: any = [];
  //Custom label for A-A/C-C affiliation
  public accountAffiliatedFromLabelText: string;
  public accountAffiliatedToLabelText: string;
  public contactAffiliatedFromLabelText: string;
  public contactAffiliatedToLabelText: string;
  public accountCrAffiliatedFromLabelText: string;
  public accountCrAffiliatedToLabelText: string;
  public contactCrAffiliatedFromLabelText: string;
  public contactCrAffiliatedToLabelText: string;

  /*************Edit business information in offline - Non-OneKey contacts*************/
  public allLookUpFieldsForOfflineEdit: any = {};
  /*************Edit business information in offline - Non-OneKey contacts*************/

  public affiliatedContacts: any[] = [];
  public targetContactIds: any[] = [];

  //Specific For Linked Entity Business Rules Implementation
  private currentLinkedEntityName:string;
  private currentLinkedEntityForm:DynamicForm;
  private currentLinkedEntityRawData:any;
  private currentLinkedEntitySubgridLayout:Array<SubgridLayout>;
  private subgridLayoutChangeAfterBusinessRules:boolean = false;
  
  constructor(
      private http: HttpClient,
      private dynamics: DynamicsClientService,
      private disk: DiskService,
      private authenticationService: AuthenticationService,
      public translate : TranslateService,
      public localizationService: LocalizationService,
      private notificationService: NotificationService,
      private loadingController: LoadingController,
      private deltaService: DeltaService,
      // private contactService: ContactOfflineService,
  ) {}


  public async getDynamicForms(isFullSync, loadFromDbOnly = false) {

    let offlineDocs;
    let lastModifiedForDeltaSync, hourDifference;
    let now = new Date();

    await this.disk.retrieve(DB_KEY_PREFIXES.DYNAMIC_FORM, true).then((doc)=>{
      offlineDocs = doc
        if(doc && doc.raw){
          this.dynamicForms = doc.raw
        }
        else {
          this.dynamicForms = [];
          isFullSync = true;
        }
      })

    if(!loadFromDbOnly) {
      let fetchXML = MOBILE_FORMS_FETCHXML;
      if(isFullSync) {
        fetchXML = fetchXML.replace('{0}', this.authenticationService.user.systemUserID);
        fetchXML = fetchXML.replace('{BUID}', this.authenticationService.user.xBusinessUnitId);
        fetchXML = fetchXML.replace('{IdCondition}', '');
      }
      else {
        fetchXML = MOBILE_FORMS_DELTA;
        let deltaSyncFilter;
        lastModifiedForDeltaSync = offlineDocs ? offlineDocs.lastDeltaSync : null;
        if(lastModifiedForDeltaSync){
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
          const entityname = 'indskr_mobileappform';
          deltaSyncFilter = fetchQueries.deltaSyncFilter.replace('{entityName}', entityname);
          deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference);
          deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id');
        }
        else deltaSyncFilter = ''
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', deltaSyncFilter);
      }

      try {
        let response = await this.dynamics.executeFetchQuery('indskr_mobileappforms', fetchXML);
        if(response){
          if(Array.isArray(response) && response.length != 0){
            if(isFullSync){
              let data = this.agreegateDynamicFormsResponse(response);
              //Fetch Business Rules
              await this.getBusineesRulesForMobileAppForms(data);
              if(data){
                await this.saveFormsDefinitionToOfflineDB(data, offlineDocs, isFullSync, now);
              }
            }else{
              await this.saveFormsDefinitionToOfflineDB(response, offlineDocs, isFullSync, now);
            }
          }
        }
      } catch(error) {
        console.log("Error fetching form metadata: ", error)
      }
    }
    else {
      // await this.contactService.fetchContactsForConfiguredDisplay(isFullSync, false);
    }
  }

  private agreegateDynamicFormsResponse(rawData:Array<any>):Array<any>{
    let result:Array<any> = [];
    if(!rawData || (rawData && rawData.length <= 0)) return result;
    for(var i=0;i<rawData.length;i++){
      const item = rawData[i];
      if(!item) continue;
      const idx  = result.findIndex(a=> a.hasOwnProperty('indskr_mobileappformid') && item.hasOwnProperty('indskr_mobileappformid') && a.indskr_mobileappformid == item.indskr_mobileappformid);
      if(idx >= 0){
        if(item['indskr_searchentity.indskr_searchentityid']){
          let searchEntity = {
            indskr_searchentityid: item['indskr_searchentity.indskr_searchentityid'],
            indskr_attribute: item['indskr_searchentity.indskr_attribute'],
            indskr_relatedentity: item['indskr_searchentity.indskr_relatedentity']
          };
          result[idx].search_entities.push(searchEntity);
        }
        if(item['indskr_linkedentityformconfigurations.indskr_linkedentityformconfigurationsid'] &&
          result[idx].search_entities.indskr_linkedentityformconfigurationsid !== item['indskr_linkedentityformconfigurations.indskr_linkedentityformconfigurationsid']){
          let linkedEntities = {
            indskr_entityname: item['indskr_linkedentityformconfigurations.indskr_entityname'],
            indskr_linkedentityformconfigurationsid: item['indskr_linkedentityformconfigurations.indskr_linkedentityformconfigurationsid'],
            indskr_maximumnumberofrecordsallowed: item['indskr_linkedentityformconfigurations.indskr_maximumnumberofrecordsallowed'],
            indskr_minimumnumberofrecordsallowed: item['indskr_linkedentityformconfigurations.indskr_minimumnumberofrecordsallowed'],
            indskr_requirementtype: item['indskr_linkedentityformconfigurations.indskr_requirementtype'],
            linkedEntitiesFieldConfig: []
          };
          result[idx].linked_entities_config.push(linkedEntities);
        }
        if(item['indskr_mobileappformfield.indskr_mobileappformfieldid']){
          let mandatoryFieldConfig = {
            indskr_mobileappformid: item['indskr_mobileappformfield.indskr_mobileappformid'],
            indskr_mobileappformfieldid: item['indskr_mobileappformfield.indskr_mobileappformfieldid'],
            indskr_mobileappformfieldname: item['indskr_mobileappformfield.indskr_mobileappformfieldname'],
            indskr_isrequired: item['indskr_mobileappformfield.indskr_isrequired'],
          };
          result[idx].mandatory_field_config.push(mandatoryFieldConfig);
        }
        if(item['indskr_editconfig']){
          result[idx].indskr_editconfig = item['indskr_editconfig'];
        }
      }else if(item.hasOwnProperty('indskr_mobileappformid') && item.indskr_mobileappformid){
        let formObj = {
          indskr_businessprocess: item['indskr_businessprocess'],
          indskr_formid: item['indskr_formid'],
          indskr_name: item['indskr_name'],
          indskr_entityname: item['indskr_entityname'],
          indskr_mdmusertype: item['indskr_mdmusertype'],
          statecode: item['statecode'],
          statuscode: item['statuscode'],
          versionnumber: item['versionnumber'],
          indskr_mobileappformid: item['indskr_mobileappformid'],
          modifiedon: item['modifiedon'],
          _owningbusinessunit_value: item['_owningbusinessunit_value'],
          _ownerid_value: item['_ownerid_value'],
          indskr_metadata: item['indskr_metadata'],
          indskr_formtype: item['indskr_formtype'],
          indskr_editconfig: item['indskr_editconfig'],
          search_entities: [],
          linked_entities_config: [],
          mandatory_field_config: []
        };
        if(item['indskr_searchentity.indskr_searchentityid']){
          let searchEntity = {
            indskr_searchentityid: item['indskr_searchentity.indskr_searchentityid'],
            indskr_attribute: item['indskr_searchentity.indskr_attribute'],
            indskr_relatedentity: item['indskr_searchentity.indskr_relatedentity']
          };
          formObj.search_entities.push(searchEntity);
        }
        if(item['indskr_linkedentityformconfigurations.indskr_linkedentityformconfigurationsid']){
          let linkedEntities = {
            indskr_entityname: item['indskr_linkedentityformconfigurations.indskr_entityname'],
            indskr_linkedentityformconfigurationsid: item['indskr_linkedentityformconfigurations.indskr_linkedentityformconfigurationsid'],
            indskr_maximumnumberofrecordsallowed: item['indskr_linkedentityformconfigurations.indskr_maximumnumberofrecordsallowed'],
            indskr_minimumnumberofrecordsallowed: item['indskr_linkedentityformconfigurations.indskr_minimumnumberofrecordsallowed'],
            indskr_requirementtype: item['indskr_linkedentityformconfigurations.indskr_requirementtype'],
            linkedEntitiesFieldConfig: []
          };
          formObj.linked_entities_config.push(linkedEntities);
        }
        if(item['indskr_mobileappformfield.indskr_mobileappformfieldid']){
          let mandatoryFieldConfig = {
            indskr_mobileappformid: item['indskr_mobileappformfield.indskr_mobileappformid'],
            indskr_mobileappformfieldid: item['indskr_mobileappformfield.indskr_mobileappformfieldid'],
            indskr_mobileappformfieldname: item['indskr_mobileappformfield.indskr_mobileappformfieldname'],
            indskr_isrequired: item['indskr_mobileappformfield.indskr_isrequired'],
          };
          formObj.mandatory_field_config.push(mandatoryFieldConfig);
        }
        if(item['indskr_editconfig']){
          formObj.indskr_editconfig = item['indskr_editconfig'];
        }
        result.push(formObj);
      }
    }
    return result;
  }

  private async saveFormsDefinitionToOfflineDB(rawForms, offlineDocs, isFullSync, timeDeltaSync) {
    let offlineForms;
    if(offlineDocs && offlineDocs["raw"]){
      offlineForms = offlineDocs["raw"];
    } else {
      offlineForms = [];
    }
    if(rawForms && Array.isArray(rawForms) && rawForms.length != 0) {

      if(!offlineForms || isFullSync) {
        offlineForms = rawForms;
        // await this.contactService.fetchContactsForConfiguredDisplay(isFullSync, false);
      } else {

        let formToAdd = [];
        //Delta Sync
        for (let i = 0; i < rawForms.length; i++) {

          let form = rawForms[i];

          if(form.indskr_formtype == FormType.DISPLAYFORM) {
            if(form.indskr_entityname == "contact"){
              //Force FullSync
              // await this.contactService.fetchContactsForConfiguredDisplay(true, false);
            }
          } else {
            // Do Delta Sync
            // await this.contactService.fetchContactsForConfiguredDisplay(false, false);
          }

          if(Array.isArray(offlineForms)){
            //Remove recently modiefied forms from offlineForms and keep track of forms that has been published
            let idx = formToAdd.findIndex(a=> a.indskr_mobileappformid == form.indskr_mobileappformid);
            if(idx >= 0){
              if(form['statecode'] == 1 && form['statuscode'] == 2) {
                formToAdd[idx] = form;
              } else {
                formToAdd.splice(idx,1);
              }
            } else {
              if((form['statecode'] == 1 && form['statuscode'] == 2)) {
                formToAdd.push(form);
              }
            }
            let idy = offlineForms.findIndex(a=> a.indskr_mobileappformid == form.indskr_mobileappformid);
            if(idy >= 0)  {
              offlineForms.splice(idy,1);
            }
          }
        }

        // Get data of forms to add. This step is added to check wether the forms are assigned to users security role
        // forms that are not in users security role won't be returned from this fetchXML. :).
        if(formToAdd.length > 0) {
          let formIds = "";
          formToAdd.forEach(p => {
            formIds += '<value>' + p['indskr_mobileappformid'] + '</value>'
          })
          let idFilter = MOBILE_FORMS_ID_FILTER;
          idFilter = idFilter.replace('{FormIds}', formIds);

          let fetchXML = MOBILE_FORMS_FETCHXML;
          fetchXML = fetchXML.replace('{0}', this.authenticationService.user.systemUserID);
          fetchXML = fetchXML.replace('{BUID}', this.authenticationService.user.xBusinessUnitId);
          fetchXML = fetchXML.replace('{IdCondition}', idFilter);
          let response = await this.dynamics.executeFetchQuery('indskr_mobileappforms', fetchXML);
          if(response){
            if(response && Array.isArray(response) && response.length != 0) {
              let data = this.agreegateDynamicFormsResponse(response);
              //Fetch Business Rules
              await this.getBusineesRulesForMobileAppForms(data);
              if(data){
                offlineForms = [...offlineForms, ...data]
              }
            }
          }
        }
      }
      this.dynamicForms = offlineForms;
      this.disk.updateOrInsert(DB_KEY_PREFIXES.DYNAMIC_FORM, doc => {
        doc = {
          lastDeltaSync: timeDeltaSync.getTime(),
          raw: offlineForms,
        };
        return doc;
      })
      .catch(error => console.error('Save Forms to DB error: ', error));
    }
  }

  public getFormDefinitionForEntity(entity, formType:FormType, businessProcess?: BusinessProcessType):DynamicForm {
    try {
        let formDef;
        let foundForms = this.dynamicForms.filter(a=> a.indskr_entityname == entity && a.indskr_formtype == formType);
        if (businessProcess) foundForms = foundForms.filter(a => a.indskr_businessprocess == businessProcess);
        else if (entity == 'contact' || entity == 'account') foundForms = foundForms.filter(a => a.indskr_businessprocess !== BusinessProcessType.OneKey);
        if (foundForms && Array.isArray(foundForms) && foundForms.length != 0){
          if(foundForms.length > 1){
            if (entity == 'indskr_contactcr' || entity == 'indskr_accountcr') {
              // formDef = null;
              if(businessProcess && businessProcess == BusinessProcessType.Omnipresence){
                this.notificationService.notify(this.translate.instant('MORE_THAN_ONE_FORM_CONFIGURED_ERR'), 'DynamicFormsService', "top", ToastStyle.DANGER, 3000);
              } else if (businessProcess == BusinessProcessType.SanofiChina) {
                const businessLine = this.authenticationService.user?.buConfigs?.indskr_businessline;
                if (businessLine == 1) {
                  foundForms = foundForms.filter(f => f.indskr_mdmusertype == 808210000);
                } else if (businessLine == 2) {
                  foundForms = foundForms.filter(f => f.indskr_mdmusertype == 808210001);
                } else if (businessLine == 3) {
                  foundForms = foundForms.filter(f => f.indskr_mdmusertype == 548910000);
                }
              }
              if (foundForms.length > 1) return;
            } else {
              foundForms = foundForms.sort((a, b) => {
                return (isBefore(new Date(a['modifiedon']), new Date(b['modifiedon'])) ? 1 : -1);
              });
            }
          }
          formDef = foundForms[0];
        }

        if(!formDef){
          if(businessProcess && businessProcess == BusinessProcessType.Omnipresence){
            this.notificationService.notify(this.translate.instant('DONT_HAVE_FORM_CONFIGURED_ERR'), 'DynamicFormsService', "top", ToastStyle.DANGER, 3000);
          }
          return null;
        }
        let form = new DynamicForm(formDef);
        if(formDef.indskr_formtype == FormType.DISPLAYFORM) return form;

        for(let i = 0; i < form.metadata.length; i++) {
          const tab = form.metadata[i];
          if(tab && tab.controls && tab.controls.length > 0) {
            for(let j = 0; j < tab.controls.length; j++) {
              let control = tab.controls[j];
              if(!control.dataType) {
                if(control.subgrid) {
                  if(control.subgrid.targetEntityType) {
                    // if(control.subgrid.targetEntityType == "customeraddress" || control.subgrid.targetEntityType == "indskr_indskr_customeraddress_v2") {
                    //   control.isVisible = true;
                    // } else {
                      let foundForm = this.dynamicForms.find(a=> a.indskr_entityname == control.subgrid.targetEntityType && a.indskr_formtype == FormType.CREATEFORM);
                      if(!foundForm ) {
                        control.forceHide = true;
                      }
                    // }
                  }
                }
              }
            }
          }
        }
        return form;
    } catch (error) {
        console.error('Form Defination: ', error);
        return null;
    }
  }

  getFormFieldType(control:Control) {
    switch(control.dataType) {
        case ControlDataType.StringType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.IntegerType:
        case ControlDataType.MoneyType:
        case ControlDataType.MemoType:
          return FormFieldType.INLINE_INPUT;
        case ControlDataType.LookupType:
          if((control.lookupEntityPrimaryId == 'accountid' && control.attributeName != 'indskr_surgeonaccountzsur') || control.lookupEntityPrimaryId == 'contactid' || control.lookupEntityPrimaryId == 'productid'){
            return FormFieldType.NEW_PAGE_SELECT;
          }
        case ControlDataType.MultiSelectPicklistType:
        case ControlDataType.PicklistType:
        case ControlDataType.BooleanType:
        case ControlDataType.DateTimeType:
          return FormFieldType.POPOVER_SELECT;
        default:
          return FormFieldType.INLINE_INPUT;
    }
  }

  public checkIfControlShouldNotBeVisible(control: Control): Boolean {
    let flag: boolean = false;
    if (control.dataType) {
      if (control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType || control.dataType == ControlDataType.OwnerType || control.dataType == ControlDataType.CustomerType || control.dataType == ControlDataType.WebResource) {
        flag = true;
      }
      if (control.attributeName == 'indskr_submittedon' || control.attributeName == 'createdby' || control.attributeName == 'createdon' || control.attributeName == 'modifiedby' || control.attributeName == 'modifiedon') {
        flag = true;
      }
    }
    // if(control.isVisible){
    //   flag = true;
    // }
    return flag;
  }

  getControl(form: DynamicForm, id) {
    let control;
    for(let md of form.metadata) {
      control = md.controls.find(x => x.attributeName == id);
      if(control) return control;
    }
    return control;
  }

  getDescription() {

  }

  async getLookupResults(searchParams, control: Control, filter, isOneKey?: boolean): Promise<any> {

    let lookupQueries = control.lookupQueries;
    let maximumCount = "1000";
    if(searchParams && searchParams.length > 3 && control && control.attributeName == "indskr_city_lu") {
      maximumCount = "5000";
    }
    const isOneKeyCodeLabels: boolean = control.lookupEntitySetName == "omnione_onekeycodeslabelses" && this.authenticationService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');

    let fetchXML = lookupQueries.fetchXml
    fetchXML = fetchXML.replace(`{addressfieldsfilter}`, filter);
    fetchXML = fetchXML.replace(`count="{0}"`, `count="`+maximumCount+`"`);
    fetchXML = fetchXML.replace("{PositionFilter}", this._getPositionFilter());

    /***Jumbled search - set fetchXML, searchText***/
    let formattedSearchText = searchParams ? this.convertFormattedString(searchParams) : '';
    const cntWordsInSearchText = formattedSearchText.split(" ").filter(sw => {return sw !=''}).length;
    let lookupEntityPrimaryName = control.lookupEntityPrimaryName;
    const targetFilterFetchXML = `<condition attribute="${lookupEntityPrimaryName}" operator="like" value="{0}" />`
    let searchTextSplitWords: string[] = [];
    if(formattedSearchText && formattedSearchText.trim() !='') {
      // if(isOneKeyCodeLabels && this.islookupSearchMultilingualData) lookupEntityPrimaryName = multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authenticationService.user.localeId];
      if(isOneKeyCodeLabels && this.islookupSearchMultilingualData) lookupEntityPrimaryName = this.localizationService.getOneKeyTableAttributes();
      const jumbledSearchFetchXMLandWords = this._getJumbledSearchFetchXMLandWords(formattedSearchText, lookupEntityPrimaryName);
      const jumbledSearchFilterFetchXML = jumbledSearchFetchXMLandWords.searchFilterFetchXML;
      searchTextSplitWords = jumbledSearchFetchXMLandWords.searchWords;

      // Scenario where fetchXML has no filter/search conditions from the configured form
      if (!fetchXML.includes(targetFilterFetchXML)) {
        // Find the index of the filter tag to insert search condition inside the filter
        const filterOpenTagIndex = fetchXML.indexOf('<filter type="or"');
        if (filterOpenTagIndex > -1) {
          const filterCloseTagIndex = fetchXML.indexOf('>', filterOpenTagIndex);
          if (filterCloseTagIndex > -1) {
            fetchXML = fetchXML.slice(0, filterCloseTagIndex + 1) + targetFilterFetchXML + fetchXML.slice(filterCloseTagIndex + 1);
          }
        } else {
          // Find the index of the entity tag to add filter and search condition
          const entityOpenTagIndex = fetchXML.indexOf('<entity');
          if (entityOpenTagIndex > -1) {
            const entityCloseTagIndex = fetchXML.indexOf('>', entityOpenTagIndex);
            if (entityCloseTagIndex > -1) {
              const filterCondition: string = `<filter type="or">${targetFilterFetchXML}</filter>`;
              fetchXML = fetchXML.slice(0, entityCloseTagIndex + 1) + filterCondition + fetchXML.slice(entityCloseTagIndex + 1);
            }
          }
        }
      }

      fetchXML = fetchXML.replace(targetFilterFetchXML, jumbledSearchFilterFetchXML);
    } else{
      const re = new RegExp(`\\{0\\}`, 'g');
      fetchXML = fetchXML.replace(re, `%`);
    }
    //onekeycodelabes - set fetchXML
    if(isOneKeyCodeLabels) {
      let key = this.localizationService.getOneKeyTableAttributes();
      const multilingualFetchXML = `
        <attribute name="omnione_onekeycodeslabelsid" />
        <attribute name="${key}" alias="multilingual_value"/>
        <attribute name="omnione_cod_id_onekey" />
        <attribute name="statecode" />
        <filter type="and">
          <condition attribute="omnione_schemafieldname" operator="eq" value="${control.attributeName}" />
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
      `;
      // <condition attribute="omnione_bu" operator="eq" value="${this.authenticationService.user.businessUnitName}" />
      fetchXML = fetchXML.replace(`<attribute name="omnione_onekeycodeslabelsid" />`,multilingualFetchXML);
    }
    let sr: LookupSearchResult[] = [];
    this.lookupSearchData = [];
    //related to address - postal code, district, city search
    if(control.lookupEntityPrimaryId == "indskr_lu_postalcodeid") {
      //DSE-UAT, the generated fetch xml from Dynamics has following filters and causes the error.
      //Temporary solution is implemented to remove these filters
      fetchXML = fetchXML.replace(`<condition attribute="indskr_city" operator="like" value="%" />`, '');
      fetchXML = fetchXML.replace(`<condition attribute="omnione_cityname" operator="like" value="%" />`, '');
      const postalCodeBasedOnCityFetchXML = `
        <attribute name="indskr_lu_postalcodeid" />
        <filter type="and">
          <condition attribute='statecode' operator='eq' value='0'/>
          <condition attribute="indskr_city" operator="eq" value="{filteredByCityId}" />
        </filter>
        `;
      if (!_.isEmpty(filter)) {
        fetchXML = fetchXML.replace(`<attribute name="indskr_lu_postalcodeid" />`, postalCodeBasedOnCityFetchXML);
        fetchXML = fetchXML.replace(`{filteredByCityId}`, filter);
      }
    } else if(control.lookupEntityPrimaryId == "indskr_lu_districtid") {
      const countyDistrictBasedOnStateIdFetchXML = `
        <attribute name="indskr_lu_districtid" />
        <attribute name="indskr_countycode" />
        <filter type="and">
          <condition attribute='statecode' operator='eq' value='0'/>
          <condition attribute="indskr_stateprovince" operator="eq" value="{filteredByStateId}" />
        </filter>
       `;
       if (!_.isEmpty(filter)) {
        fetchXML = fetchXML.replace(`<attribute name="indskr_lu_districtid" />`, countyDistrictBasedOnStateIdFetchXML);
        fetchXML = fetchXML.replace(`{filteredByStateId}`, filter);
       }
    } else if(control.lookupEntityPrimaryId == 'indskr_lu_cityid' && this.hasCityDataBasedOnCountry) {
      if(formattedSearchText && formattedSearchText.trim() !='') {
        this.lookupSearchData = this.lookupSearchDataForCityBasedOnCountry.filter(x=> {
          formattedSearchText = formattedSearchText.toLowerCase();
          const formattedNameForSearch = this.convertFormattedString(x.nameForSearch).toLowerCase();
          const formattedName = this.convertFormattedString(x.name).toLowerCase();
          return formattedNameForSearch.includes(formattedSearchText) || formattedName.includes(formattedSearchText);
        });
      } else this.lookupSearchData = this.lookupSearchDataForCityBasedOnCountry;
      return;
    }
    if(control.attributeName == 'indskr_shipto' && filter) {
      const entityOpenTagIndex = fetchXML.indexOf('<entity');
          if (entityOpenTagIndex > -1) {
            const entityCloseTagIndex = fetchXML.indexOf('>', entityOpenTagIndex);
            if (entityCloseTagIndex > -1) {
              fetchXML = fetchXML.slice(0, entityCloseTagIndex + 1) + filter + fetchXML.slice(entityCloseTagIndex + 1);
            }
          }
    }
    //execute fetch query
    this.lookupSearchInProgress = true;
    let pageCount = 0;
    await this.dynamics.executeFetchQueryWithPageNumber(control.lookupEntitySetName, fetchXML,pageCount)
          .then((res) => {
            this.islookupSearchMultilingualData = false;
            this.islookupSearchMultilingualData = res.some(r=>r['multilingual_value']);
            for(let r of res) {
              let data;
              if(isOneKeyCodeLabels && r["multilingual_value"]) data = new LookupSearchResult(r, control.lookupEntityPrimaryId, "multilingual_value");
              else data = new LookupSearchResult(r, control.lookupEntityPrimaryId, control.lookupEntityPrimaryName);
              if(r['multilingual_value']){
                data.name = data.nameForSearch = r['multilingual_value'];
              }
              //sorting results with weighted values
              if(formattedSearchText && formattedSearchText.trim() !='') {
                let weightedMatchChar: number = 0;
                searchTextSplitWords.forEach((sw, idx) => {
                  const searchWordLength = sw.length;
                  sw = sw.toLowerCase();
                  let nameForSearch = this.convertFormattedString(data.nameForSearch).toLowerCase();
                /** Weighted values(wv) for the matched letters
                 * 1. Input text by a user (determined by cntWordsInSearchText)
                 *  a. checking exact matched words or included search parameters: wv is searchWordLength + 20 or 10,
                 *  b. matched split word is more than 3 letters: wv is searchWordLength + 3,
                 *  c. matched split word is less than 3 letters: wv is searchWordLength
                 * 2. Jumbled text
                 *  a. matched split word is 3 letters : wv is 1
                 * 3. No matched split word: wv is 0
                */
                  if(idx == 0 && searchWordLength > 3 && nameForSearch && nameForSearch.includes(sw)) {
                    weightedMatchChar += nameForSearch.length == searchWordLength ? searchWordLength + 20 : searchWordLength + 10;
                  }else {
                    weightedMatchChar += nameForSearch.split(sw).length  > 1 ? idx < cntWordsInSearchText ? searchWordLength > 3 ? searchWordLength + 3 : searchWordLength : 1 : 0;
                  }
                });
                data.totalWeightedMatch = weightedMatchChar;
              }
              sr.push(data);
            }
            sr.sort((a, b) => (b.totalWeightedMatch - a.totalWeightedMatch || a.nameForSearch.localeCompare(b.nameForSearch)));

            if(formattedSearchText && formattedSearchText.trim() !='') {
              sr = _.filter(sr, function (result:LookupSearchResult) {
                return result.totalWeightedMatch > 0;
              });
            }

            console.warn('searchTextSplitWords');
            console.log(searchTextSplitWords);
            // console.warn('filteredSearchResults');
            // console.log(sr);

            this.lookupSearchData = sr;
            this.lookupSearchInProgress = false;
            return sr;
          },
          (err) => {
            console.log('Lookup search dynamics error', err);
            this.lookupSearchInProgress = false;
          })
    return sr;
  }

  async getLookupResultsForCityBasedOnCountry(searchByCountryId, control: Control): Promise<any> {
    let fetchXML;
    fetchXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
      <entity name="indskr_lu_country">
        <attribute name="indskr_countryname" />
        <attribute name="createdon" />
        <attribute name="statecode" />
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="0" />
        </filter>
        <filter type="or">
          <condition attribute="indskr_lu_countryid" operator="eq" value="{0}" />
        </filter>
        <attribute name="indskr_lu_countryid" />
        <link-entity name="indskr_lu_state" from="indskr_lu_countryid" to="indskr_lu_countryid" link-type="outer" alias="bt">
          <link-entity name="indskr_lu_city" from="indskr_state" to="indskr_lu_stateid" link-type="outer" alias="bu">
            <attribute name="indskr_lu_cityid" />
            <attribute name="indskr_name" />
            <attribute name="indskr_state" />
            <attribute name="indskr_statename" />
            <attribute name="statecode" />
            <order attribute="indskr_name" descending="false" />
          </link-entity>
        </link-entity>
      </entity>
    </fetch>`;
    let re = new RegExp(`\\{0\\}`, 'g');
    fetchXML = fetchXML.replace(re, searchByCountryId);

    let sr: LookupSearchResult[] = [];
    this.lookupSearchDataForCityBasedOnCountry = [];
    this.hasCityDataBasedOnCountry = false;
    const loader = await this.loadingController.create({});
    loader.present();

    let index = 1;
    let hasMoreRecords = false;
    let values = []
    do {  
      try {
        let response = await this.dynamics.executeFetchXml(control.lookupEntitySetName, fetchXML,'*',index)

        hasMoreRecords = false;
  
        if ( response.value && _.isArray(response.value) && !_.isEmpty(response.value)) {
          values = values.concat(response.value)
        }
  
        if (response.hasOwnProperty(DynamicsConstant.hasMoreRecords)) {
          hasMoreRecords = response[DynamicsConstant.hasMoreRecords]
        }
        index = index + 1;
      } catch(error) {
        console.log("Error retreiving getLookupResultsForCityBasedOnCountry: ", error);
        hasMoreRecords = false;
      }
    } while (hasMoreRecords)
    //Do not add any filters in the above linked entity indskr_lu_city. This is the outer join type. Adding fitler causes performance issues.
    values = values.filter(v=>v.statecode == 0);
    values.forEach(r => {
      let hasCityDataToStore: boolean = this.checkCityData(r, 'indskr_lu_cityid');
      if(hasCityDataToStore) {
        sr.push(new LookupSearchResult(r, control.lookupEntityPrimaryId, control.lookupEntityPrimaryName));
      }

      if (sr) {
        sr.forEach(x=> {
          x.id = x.cityId;
          x.name = x.cityName;
          x.nameForSearch = x.cityName.toLowerCase();
        })
        /**city entity does not have a relationship with the country entity. So, the city cannot be filtered by country in fetchXML.
         * When a country is selected, city data is stored through the state entity in fetchXML
         */
        this.lookupSearchDataForCityBasedOnCountry = sr;
        if (this.lookupSearchDataForCityBasedOnCountry) {
          this.hasCityDataBasedOnCountry = true;
        }
      }
    });
    loader.dismiss();

    if (this.lookupSearchDataForCityBasedOnCountry) {
      this.hasCityDataBasedOnCountry = true;
    }
  }

  async getLookupCodIdOnekey(schemaFieldName: string, onekeyCodesLabelsId: string): Promise<string> {
    let codIdOnekey: string = '';
    if(!schemaFieldName || !onekeyCodesLabelsId) return codIdOnekey;
    let fetchXML;
    fetchXML = `<fetch returntotalrecordcount='true' version="1.0" mapping="logical">
    <entity name='omnione_onekeycodeslabels'>
      <attribute name='omnione_onekeycodeslabelsid'/>
      <attribute name='omnione_name'/>
      <attribute name='omnione_cod_id_onekey'/>
      <order attribute='omnione_name' descending='false'/>
      <filter type='and'>
        <condition attribute='omnione_schemafieldname' operator='eq' value='{0}' />
        <condition attribute='omnione_onekeycodeslabelsid' operator='eq' value='{0}' />
      </filter>
    </entity>
    </fetch>`;

    const targetFilterSchemaField = `<condition attribute='omnione_schemafieldname' operator='eq' value='{0}' />`;
    const targetFilterOnekeyCodesLabelsId = `<condition attribute='omnione_onekeycodeslabelsid' operator='eq' value='{0}' />`;
    const re = new RegExp(`\\{0\\}`, 'g');
    const setFilterSchemaField = targetFilterSchemaField.replace(re, schemaFieldName);
    fetchXML = fetchXML.replace(targetFilterSchemaField, setFilterSchemaField);
    const SetFilterOnekeyCodesLabelsId = targetFilterOnekeyCodesLabelsId.replace(re, onekeyCodesLabelsId);
    fetchXML = fetchXML.replace(targetFilterOnekeyCodesLabelsId, SetFilterOnekeyCodesLabelsId);

    await this.dynamics.executeFetchQueryWithPageNumber('omnione_onekeycodeslabelses', fetchXML, 0)
          .then((res) => {
            if(res[0] && res[0].omnione_cod_id_onekey) codIdOnekey = res[0].omnione_cod_id_onekey;
          },
          (err) => {console.log('Lookup search dynamics error', err);});
    return codIdOnekey;
  }

  async getLookupCountyCodeOneKey(lookupEntitySetName: string, lookupId: string): Promise<string> {
    let countyCodeOneKey: string = '';
    let fetchXML;
    fetchXML = `<fetch returntotalrecordcount='true' version="1.0" mapping="logical">
    <entity name='indskr_lu_district'>
      <attribute name='indskr_name'/>
      <attribute name='indskr_lu_districtid'/>
      <attribute name='indskr_countycode'/>
      <order attribute='indskr_name' descending='false'/>
      <filter type='and'>
        <condition attribute='statecode' operator='eq' value='0'/>
        <condition attribute='indskr_lu_districtid' operator='eq' value='{0}' />
      </filter>
    </entity>
    </fetch>`;

    const re = new RegExp(`\\{0\\}`, 'g');
    fetchXML = fetchXML.replace(re, lookupId);

    await this.dynamics.executeFetchQueryWithPageNumber(lookupEntitySetName, fetchXML, 0)
          .then((res) => {
            if(res[0] && res[0].indskr_countycode) countyCodeOneKey = res[0].indskr_countycode;
          },
          (err) => {console.log('Lookup search dynamics error', err);});
    return countyCodeOneKey;
  }

  async getLookupStateIdBasedOnCity(lookupEntitySetName: string, lookupId: string): Promise<string> {
    let stateId: string = '';
    let fetchXML;
    fetchXML = `<fetch returntotalrecordcount='true' version="1.0" mapping="logical">
    <entity name='indskr_lu_city'>
      <attribute name='indskr_name'/>
      <attribute name='indskr_state'/>
      <attribute name='indskr_lu_cityid'/>
      <order attribute='indskr_name' descending='false'/>
      <filter type='and'>
        <condition attribute='statecode' operator='eq' value='0'/>
        <condition attribute='indskr_lu_cityid' operator='eq' value='{0}' />
      </filter>
    </entity>
    </fetch>`;

    const re = new RegExp(`\\{0\\}`, 'g');
    fetchXML = fetchXML.replace(re, lookupId);

    await this.dynamics.executeFetchQueryWithPageNumber(lookupEntitySetName, fetchXML, 0)
          .then((res) => {
            if(res[0] && res[0]._indskr_state_value) stateId = res[0]._indskr_state_value;
          },
          (err) => {console.log('Lookup search dynamics error', err);});
    return stateId;
  }

  private _getPositionFilter() {
    let positionIds = this.authenticationService.user.positions.map(o => {
      return o.ID
    });

    if(!positionIds) return "";
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>'
    })

    return positionString;
  }

  public getDropdownListViewFromOptionSet(options:Array<Option>,selectedValue,langCode:string):IndDropdownListDetailModel{
    let dropdownListDetail:IndDropdownListDetailModel = {
      id: 'dynamicform-picklist-select',
      data: options.map((option,optionIndex) => {
        let data:SelectListData;
        try {
          if(option && option.value && option.displayNames && option.displayNames.length != 0){
            let foundDisplayName = option.displayNames.find(a=> a.languagecode == langCode) || option.displayNames.find(a=> a.languagecode == '1033');
            if(foundDisplayName){
              if(selectedValue && Array.isArray(selectedValue) && selectedValue.length>1) {
                data = {
                  title: foundDisplayName.description,
                  id: option.value.toString(),
                  isSelected: false,
                }
                selectedValue.forEach(val=> {if(option.value == val) data.isSelected= true;});
              } else {
                data = {
                  title: foundDisplayName.description,
                  id: option.value.toString(),
                  isSelected: (selectedValue && option.value == selectedValue) ?true:false,
                }
              }
            }
          }
        } catch (error) {
          console.log('Error occured while parsing option set data'+error);
        }
        return data;
      }).filter(option => option),
    };
    return dropdownListDetail;
  }
  /**
   * With debounce
   */
  async getLookupSearchResults(searchParams, lookupQueries: LookupQuery) {
    this.searchResultsObs$ = this.searchParamsSub.pipe(
      debounceTime(1000),
      map(searchParams => {

        let fetchXML = lookupQueries.fetchXml.replace(`{0}`, searchParams + `%`);
        let sr: LookupSearchResult[] = [];
        this.dynamics.executeFetchQuery(lookupQueries.entityName + "s", fetchXML)
          .then((res) => {
            for(let r of res) {
              //sr.push(new LookupSearchResult(r));
            }
            return sr;
          },
          (err) => {
            console.log('Lookup search dynamics error', err);
          })
      })
    )

  }

  public async getOptionSet(attrName) {

    let options: Option[] = [];

    let result = await this.dynamics.retrieveGlobalOptionSet("Name='" + attrName + "'");
    if(result && result['Options'] && Array.isArray(result['Options']) ) {
      let rawOptions = result['Options'];
      rawOptions.forEach(op => {
        options.push(new Option(op));
      });
    }
    return options;

  }

  public async createLinkedEntity(payload, entityName) {

    let headers = Endpoints.headers.content_type.json;
      headers.headers = headers.headers.set(
          'No-Retry', 'true'
      );
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.dynamic_forms.CREATE_LINKED_ENTITY.replace('{entityName}', entityName);
    try {
      const response = await this.http.post(url, payload, headers).toPromise();
      if (response) {
        return response;
      }
    } catch (httpError) {
        console.log(httpError);
        if (httpError.error.errorCode == 'ERR_IO_500' && httpError.error.errorMessage.includes("duplicate")) {
          this.notificationService.notify(this.translate.instant('DUPLICATE_DETECTED'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
        } else {
          this.notificationService.notify( this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
        }
    }

  }

  public getSearchConfigDisplayText(dtArray: DisplayText[],formType:DynamicFormType, isCustomControl: boolean = false, attributeName?: string, entityName?: string) {
    if(!dtArray) return "";

    let displayText: string;
    if (entityName && (entityName == 'contact' || entityName == 'account') && attributeName && attributeName == 'statuscode') {
      return displayText = this.translate.instant('VERIFICATION');
    }
    let langCode = (formType == DynamicFormType.DEFAULTFORM || isCustomControl) ? "0000" : this.localizationService.selectedLanguage.localeID;
    let dt = dtArray.find(value => value.languagecode == langCode);

    if(dt) {
        displayText = dt.languagecode == "0000" ?  this.translate.instant(dt.description) : dt.description;
    } else {
        let en = dtArray.find(value => value.languagecode == "1033"); // default to english if no translation for the specific language;
        if(en) {
            //check multilingualLabels
            displayText = this.localizationService.getTranslatedString(en.description);
        } else {
            displayText = "";
        }
    }
    return displayText;
  }

  public mapAddressOneKey(searchedData) {
    let searchedDataMap = []
    searchedData.forEach(sData => {
        let acaObj = {
          createdon: sData['createdon_Formatted'] ? sData['createdon_Formatted'] : '',
          indskr_addressid: sData['indskr_addressid'] ? sData['indskr_addressid'] : '',
          indskr_accountid: sData['ap.accountid'] ? sData['ap.accountid'] : '',
          indskr_composite: sData['indskr_composite'] ? sData['indskr_composite'] : '',
          indskr_compositecitystate: sData['indskr_compositecitystate'] ? sData['indskr_compositecitystate'] : '',
          indskr_name: sData.indskr_name ? sData.indskr_name: '',
          indskr_shipto: sData['ao.indskr_shipto_Formatted'] ? sData['ao.indskr_shipto_Formatted'] : '',
          indskr_country_lu_value: sData['_indskr_country_lu_value'] ? sData['_indskr_country_lu_value'] : '',
          indskr_country_lu_value_Formatted: sData['_indskr_country_lu_value_Formatted'] ? sData['_indskr_country_lu_value_Formatted'] : '',
          indskr_state_lu_value: sData['_indskr_state_lu_value'] ? sData['_indskr_state_lu_value'] : '',
          indskr_state_lu_value_Formatted: sData['_indskr_state_lu_value_Formatted'] ? sData['_indskr_state_lu_value_Formatted'] : '',
          indskr_city_lu_value: sData['_indskr_city_lu_value'] ? sData['_indskr_city_lu_value'] : '',
          indskr_city_lu_value_Formatted: sData['_indskr_city_lu_value_Formatted'] ? sData['_indskr_city_lu_value_Formatted'] : '',
          indskr_postalcode_lu_value: sData['_indskr_postalcode_lu_value'] ? sData['_indskr_postalcode_lu_value'] : '',
          indskr_postalcode_lu_value_Formatted: sData['_indskr_postalcode_lu_value_Formatted'] ? sData['_indskr_postalcode_lu_value_Formatted'] : '',
          indskr_line3: sData['indskr_line3'] ? sData['indskr_line3'] : '',
          indskr_line2: sData['indskr_line2'] ? sData['indskr_line2'] : '',
          indskr_line1: sData['indskr_line1'] ? sData['indskr_line1'] : '',
          indskr_postofficebox: sData['indskr_postofficebox'] ? sData['indskr_postofficebox'] : '',
          indskr_district_value: sData['_indskr_district_value'] ? sData['_indskr_district_value'] : '',
          indskr_district_value_Formatted: sData['_indskr_district_value_Formatted'] ? sData['_indskr_district_value_Formatted'] : '',
        }
        if(acaObj.indskr_addressid) {
          searchedDataMap.push(acaObj);
        }
    });
    return searchedDataMap;
  }

  /*customizeLabelRequestResponseComments(control:Control) {
    if (control.attributeName && control.attributeName === 'indskr_requestcomments')
      control.displayNames.forEach(dn => dn.description = this.translate.instant('REQUEST_DETAILS'));
    if (control.attributeName && control.attributeName === 'indskr_responsecomments')
      control.displayNames.forEach(dn => dn.description = this.translate.instant('RESPONSE_DETAILS'));
  }*/

   appendSortCriteria(JSONQuery: any, linkEntityAttributesStr: string) {
    if (JSONQuery.fetch.entity[0]['order'] && JSONQuery.fetch.entity[0]['order'].length) {
      JSONQuery.fetch.entity[0]['order'].forEach(sort => {
        linkEntityAttributesStr += `<order attribute="${sort.$.attribute}" descending="${sort.$.descending}"/>`;
      });
    }
    return linkEntityAttributesStr;
  }

  appendFilterCriteria(JSONQuery: any, linkEntityAttributesStr: string, forLinkedEntity = false) {

    if (!forLinkedEntity) {
      if (JSONQuery.fetch.entity[0]['filter'] && JSONQuery.fetch.entity[0]['filter'].length) {
        JSONQuery.fetch.entity[0]['filter'].forEach(filter => {

          let filterString = ''

          filterString = this.getFilterToken(filterString, filter);

          if (filter.condition && filter.condition.length) {
            filter.condition.forEach(con => {
              const attributeName = con.$.attribute;
              const operatorName = con.$.operator;
              // Ignoring the only condition accepting either contact ID or account ID or any value whihc has to be put instead of {0} As this is taken care above for in the entity level filter criteria.
              if (con.$.value) {
              filterString += con.$.value === '{0}' ? '' : `<condition attribute="${attributeName}" operator="${operatorName}" value="${con.$.value}"/>`;
              } else if (con.value && con.value.length > 0) {
                // filterString += `<condition attribute="${attributeName}" operator="${operatorName}"><value>${con.value[0]}</value></condition>`
                filterString += `<condition attribute="${attributeName}" operator="${operatorName}">`;
                let valueString = '';
                con.value.forEach(val => {
                  let formattedValue: string = '';
                  if(val && val._ && typeof val._ === 'string') {
                    formattedValue = val._;
                  }else if(val && typeof val === 'string') {
                    formattedValue = val;
                  }
                  if(!_.isEmpty(formattedValue)) {
                    valueString += `<value>${formattedValue}</value>`;
                  }
                });
                if(valueString) filterString += valueString;
                filterString += '</condition>';
              } else {
                filterString += `<condition attribute="${attributeName}" operator="${operatorName}" />`
              }
            });
            filterString += '</filter>';
          } else {
            filterString = '<filter></filter>';
          }
          //remove empty filter condition
          filterString = filterString.replace('<filter></filter>', '');

          linkEntityAttributesStr += filterString;
        });
      }
    } else {

      if (JSONQuery.filter && !_.isEmpty(JSONQuery.filter)) {

      JSONQuery.filter.forEach(filter => {

        let filterString = '';

        filterString = this.getFilterToken(filterString, filter);

          if (filter.condition && filter.condition.length) {
            filter.condition.forEach(con => {
              const attributeName = con.$.attribute;
              const operatorName = con.$.operator;
              // Ignoring the only condition accepting either contact ID or account ID or any value whihc has to be put instead of {0} As this is taken care above for in the entity level filter criteria.
              if (con.$.value) {
                filterString += con.$.value === '{0}' ? '' : `<condition attribute="${attributeName}" operator="${operatorName}" value="${con.$.value}"/>`;
              } else if (con.value && con.value.length > 0) {
                // filterString += `<condition attribute="${con.$.attribute}" operator="${con.$.operator}"><value>${con.value[0]}</value></condition>`
                filterString += `<condition attribute="${attributeName}" operator="${operatorName}">`;
                let valueString = '';
                con.value.forEach(val => {
                  let formattedValue: string = '';
                  if(val && val._ && typeof val._ === 'string') {
                    formattedValue = val._;
                  }else if(val && typeof val === 'string') {
                    formattedValue = val;
                  }
                  if(!_.isEmpty(formattedValue)) {
                    valueString += `<value>${formattedValue}</value>`;
                  }
                });
                if(valueString) filterString += valueString;
                filterString += '</condition>';
              } else {
                filterString += `<condition attribute="${attributeName}" operator="${operatorName}" />`
              }
            });
            filterString += '</filter>';
          } else {
            filterString = '<filter></filter>';
          }
          //remove empty filter condition
          filterString = filterString.replace('<filter></filter>', '');

          linkEntityAttributesStr += filterString;
        });

      }
    }

    return linkEntityAttributesStr;

  }

  private getFilterToken(filterString: string, filter: any) {
    filterString += '<filter';

    // If type is for filter is not available close with cosing tag ">".
    if (filter.$ && filter.$.type) {
      filterString += ` type="${filter.$.type}">`;
    } else {
      filterString += '>';
    }
    return filterString;
  }

  private checkCityData(raw: any, target: string) {
    let rawData = [];
    let foundValue;
    for (let r in raw) {
      rawData.push({
        key: r,
        value: raw[r]
      });
    }
    rawData.forEach(x=> {
        let mKey = x.key.replace(/\w+.\./g,'');
        if (mKey == target) {
          foundValue = x.value;
        }
      });
    return foundValue ? true : false;
  }


  /**
   * Display form
   ******************************************************************************************************************
   */

  getDefaultAffiliationsFilters(): AffiliationsFilters {
    let affiliationsFilters = {};
    const text = this.translate.instant('ALL');
    affiliationsFilters[ACCOUNT_ACCOUNT_AFFILIATIONS_VIEW_ID] = {
      filteredRecordCount: -1,
      filters: [
        {
          filterBy: 'fromToRelation',
          value: 'all',
          text,
        },
        {
          filterBy: 'sourceType',
          value: 'all',
          text,
        }
      ]
    };
    affiliationsFilters[CONTACT_CONTACT_AFFILIATIONS_VIEW_ID] = {
      filteredRecordCount: -1,
      filters: [
        {
          filterBy: 'fromToRelation',
          value: 'all',
          text,
        },
        {
          filterBy: 'sourceType',
          value: 'all',
          text,
        }
      ]
    };
    affiliationsFilters[ACCOUNT_CONTACT_AFFILIATIONS_VIEW_ID] = {
      filteredRecordCount: -1,
      filters: [
        {
          filterBy: 'sourceType',
          value: 'all',
          text,
        }
      ]
    };
    affiliationsFilters[CONTACT_ACCOUNT_AFFILIATIONS_VIEW_ID] = {
      filteredRecordCount: -1,
      filters: [
        {
          filterBy: 'sourceType',
          value: 'all',
          text,
        }
      ]
    };

    return affiliationsFilters;
  }
  updateAffiliationsFilter(affiliationsFilters: AffiliationsFilters, viewId: string, filterBy: string, value: string, text: string) {
    if (affiliationsFilters && affiliationsFilters[viewId] && Array.isArray(affiliationsFilters[viewId].filters)) {
      let filters = affiliationsFilters[viewId].filters;
      let filter = filters.find(f => f.filterBy === filterBy);
      if (filter) {
        filter.value = value;
        filter.text = text;
      } else {
        filter = {
          filterBy,
          value,
          text,
        };
        filters.push(filter);
      }
    }
  }
filterAffiliations(linkedEntityValues: any, affiliationsFilters: AffiliationsFilters, viewId: string) {
    let filteredRecordCount = 0;
    if (linkedEntityValues
        && Array.isArray(linkedEntityValues[viewId])
        && affiliationsFilters
        && affiliationsFilters[viewId]
        && Array.isArray(affiliationsFilters[viewId].filters)) {

      const filters = affiliationsFilters[viewId].filters;

      for (let i = 0; i < linkedEntityValues[viewId].length; i++) {
        const cardData = linkedEntityValues[viewId][i];

        let isFiltered = false;
        for (let j = 0; j < filters.length; j++) {
          const filter = filters[j];
          if (filter.value !== 'all' && filter.value !== 'target') {
            isFiltered = ((cardData.filter
              && (!cardData.filter[filter.filterBy]
                || (cardData.filter[filter.filterBy]
                  && cardData.filter[filter.filterBy] !== filter.value)
                )
              )
            || !cardData.filter);
          } else if (filter.value === 'target') {
            let contactsMatchedAlready = _.intersectionBy(this.affiliatedContacts, this.targetContactIds, 'ID');

            let selIndex = contactsMatchedAlready.findIndex((actId) => actId.fullname === cardData.primaryText); //Check for duplicates
            if (selIndex === -1) {
              isFiltered = true;
            }
          }

          if (isFiltered) {
            break;
          }
        }
        cardData.isFiltered = isFiltered;
        if (!isFiltered) {
          filteredRecordCount++;
        }
      }

      affiliationsFilters[viewId].filteredRecordCount = filteredRecordCount;
    }
  }
  //Multilingual support - translate option set value
  public translatedValue(value): string {
    let _value: string;
    const formattedValue: string = value.replace(/ |\//g,"_").toUpperCase(); //set key value for translation
    // Option set value for Status and Status Reason
    // Status: Active, Inactive
    // Status Reason: Provisional, Valid, Invalid, Closed, Deceased, Maternity/Paternity leave, Open, Is Opening, Retired, Temporarily Closed, Temporarily Inactive.
    _value = this.translate.instant(formattedValue);
    if (_value != formattedValue) {
      return _value;
    } else return value; //not found translation key
  }
  //Extract the display name of the field for account-account/contact-contact affiliation
  public getCustomDisplayNameFromField(control, formType: any) {
    const refEntityName = control?.subgrid?.referencingEntity ?? control?.subgrid?.targetEntityType;
    const affiliationsRefNames = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
      ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY,
    ];
    const crAffiliationsRefNames = [ ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY ];
    const isAffiliationsData = affiliationsRefNames.includes(refEntityName);
    if (!isAffiliationsData) return;

    const isCrAffiliationsData = crAffiliationsRefNames.includes(refEntityName);
    if (!isCrAffiliationsData) {
      //A-A-Affiliation in account form
      if (refEntityName == ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
        if (control.subgrid.subgridLayout) {
          control.subgrid.subgridLayout.forEach(attrib => {
            if (attrib.attribute == ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME) {
              this.accountAffiliatedFromLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.accountAffiliatedFromLabelText) || formType == DynamicFormType.DEFAULTFORM || this.accountAffiliatedFromLabelText == 'Affiliated From') this.accountAffiliatedFromLabelText = this.translate.instant('AFFILIATED_FROM');
            } else if (attrib.attribute == ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME) {
              this.accountAffiliatedToLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.accountAffiliatedToLabelText) || formType == DynamicFormType.DEFAULTFORM || this.accountAffiliatedToLabelText == 'Affiliated To') this.accountAffiliatedToLabelText = this.translate.instant('AFFILIATED_TO');
            }
          });
        }
      }
      //C-C-Affiliation in contact form
      else if (refEntityName == CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY) {
        if (control.subgrid.subgridLayout) {
          control.subgrid.subgridLayout.forEach(attrib => {
            if (attrib.attribute == CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME) {
              this.contactAffiliatedFromLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.contactAffiliatedFromLabelText) || formType == DynamicFormType.DEFAULTFORM) this.contactAffiliatedFromLabelText = this.translate.instant('RELATED_FROM');
            } else if (attrib.attribute == CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME) {
              this.contactAffiliatedToLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.contactAffiliatedToLabelText) || formType == DynamicFormType.DEFAULTFORM) this.contactAffiliatedToLabelText = this.translate.instant('RELATED_TO');
            }
          });
        }
      }
    } else {
      //A-A-Affiliation in account CR form
      if (refEntityName == ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY) {
        if (control.subgrid.subgridLayout) {
          control.subgrid.subgridLayout.forEach(attrib => {
            if (attrib.attribute == ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME) {
              this.accountCrAffiliatedFromLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.accountCrAffiliatedFromLabelText) || formType == DynamicFormType.DEFAULTFORM || this.accountCrAffiliatedFromLabelText == 'Affiliated From') this.accountCrAffiliatedFromLabelText = this.translate.instant('AFFILIATED_FROM');
            } else if (attrib.attribute == ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME) {
              this.accountCrAffiliatedToLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.accountCrAffiliatedToLabelText) || formType == DynamicFormType.DEFAULTFORM || this.accountCrAffiliatedToLabelText == 'Affiliated To') this.accountCrAffiliatedToLabelText = this.translate.instant('AFFILIATED_TO');
            }
          });
        }
      }
      //C-C-Affiliation in contact CR form
      else if (refEntityName == CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY) {
        if (control.subgrid.subgridLayout) {
          control.subgrid.subgridLayout.forEach(attrib => {
            if (attrib.attribute == CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME) {
              this.contactCrAffiliatedFromLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.contactCrAffiliatedFromLabelText) || formType == DynamicFormType.DEFAULTFORM) this.contactCrAffiliatedFromLabelText = this.translate.instant('RELATED_FROM');
            } else if (attrib.attribute == CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME) {
              this.contactCrAffiliatedToLabelText = this.getDisplayText(attrib.displayNames);
              if (_.isEmpty(this.contactCrAffiliatedToLabelText) || formType == DynamicFormType.DEFAULTFORM) this.contactCrAffiliatedToLabelText = this.translate.instant('RELATED_TO');
            }
          });
        }
      }
    }
  }
  public getDisplayText(dtArray: DisplayText[],langCode:string = '1033'): string {
    //default to English as custom label, translation is not supported due to custom input value
    if (!dtArray) return "";
    let displayText: string;
    let en = dtArray.find(value => value.languagecode == langCode);
    if (en) {
      displayText = langCode == '0000' ? this.translate.instant(en.description) : en.description
    } else {
      displayText = "";
    }
    return displayText;
  }
  /**
   * Convert formatted text - Characters with strokes, and diacritics matching
   * lodash _deburr string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks.
   */
   public convertFormattedString(targetText: string): string {
    let formattedText: string = targetText;
    formattedText = _.deburr(targetText);
    // ToDo: If necessary, add unsupported parts for characters with strokes and diacritics
    // you can add characters that are not supported here
    const charactersList = [{raw:'–',converted:'-'}];
    if(formattedText.includes("–")) {
      charactersList.forEach(c => {
        const index = charactersList.findIndex(item => formattedText.includes(item.raw));
        if(index > -1) {
          formattedText = formattedText.replace(new RegExp(_.escapeRegExp(charactersList[index].raw), 'g'), charactersList[index].converted);
        }
      });
    }
    return formattedText;
  }
  /** Jumbled search - extract 3 letters from words */
  private _getJumbledSearchFetchXMLandWords(formattedSearchText: string, lookupEntityPrimaryName: string): any {
    let searchTextSplitWords = formattedSearchText.split(" ").filter(sw => {return sw !=''});
    searchTextSplitWords.forEach(sw => {
      if(sw.length > 3) {
        let jumbledText = '';
        const searchTextSplit = sw.split("");
        searchTextSplit.forEach((c, idx)=> {
          if(c && idx < sw.length - 2) {
            jumbledText = sw.substring(idx, idx + 3);
            if(jumbledText) searchTextSplitWords.push(jumbledText);
          }
        });
      }
    });
    let jumbledSearchFetchXML = ''
    searchTextSplitWords.forEach(sw => {
      sw = _.escape(sw);
      jumbledSearchFetchXML += `<condition attribute="${lookupEntityPrimaryName}" operator="like" value="%${sw}%" />`
    });
    return {searchFilterFetchXML: jumbledSearchFetchXML, searchWords: searchTextSplitWords};
  }
  /** Clear onekeycode from lookup data */
  public clearMappedLookupOnekeyWithId(targetId: string) {
    const okIdx = this.okLookUpMapper.findIndex(ld => {return ld.guid && ld.guid == targetId;});
    if(okIdx >= 0) {
      this.okLookUpMapper.splice(okIdx,1);
    }
  }
  public clearMappedLookupOnekeyWithName(schemaName: string) {
    const okIdx = this.okLookUpMapper.findIndex(ld => {return ld.entity && ld.entity == schemaName;});
    if(okIdx >= 0) {
      this.okLookUpMapper.splice(okIdx,1);
    }
  }
  /** Add onekeycode to lookup data */
  public addLookupOnekeyData(targetId: string, crLookUp: any) {
    const okIdx = this.okLookUpMapper.findIndex(ld => {return ld.guid && ld.guid == targetId;});
    if(okIdx >= 0) {
      this.okLookUpMapper[okIdx] = crLookUp;
    } else {
      this.okLookUpMapper.push(crLookUp);
    }
  }

  /*************Edit business information in offline - Non-OneKey contacts*************/
  public async populateLookupFieldsDataForDynamicForms(loadFromDbOnly = false){
    //*Pending to add check for fields configured from Dynamic Forms *//

    /**
     * To check for Feature Action related to Contact Edit and then fetch All lookup fields for
      1.Edit a contact in Offline mode
      2.Edit Business CR in Offline mode
      3.Create OneKey CR in Offline mode
    */
    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACT_EDITOR)
    || (this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST)
    && this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_ONEKEY_SELECTION))) {
      await this.getAllLookupFieldsDataForOfflineContactEdit(loadFromDbOnly);
    }
  }

  private async getAllLookupFieldsDataForOfflineContactEdit(loadFromDbOnly = false) {
    if(loadFromDbOnly){
      await this.disk.retrieve(DB_KEY_PREFIXES.DYNAMIC_FORMS_ALL_LOOKUP_FIELDS, true).then((doc)=>{
        if(doc && doc.raw){
          this.allLookUpFieldsForOfflineEdit = doc.raw
        }
        else {
          this.allLookUpFieldsForOfflineEdit = {};
        }
      })
      return;
    }
    try {
      const lookupFields = await this.getAllLookupFieldsForOfflineContactEdit();
      let allLookUpFieldsForOfflineContactEdit = {};

      const query = '';
      const filter = '';
      const changeRequest = false;

      for (let i = 0; i < lookupFields.length; i++) {
        const lookupField = lookupFields[i];
        if(lookupField.lookupQueries && lookupField.attributeName) {
          const results = await this.getLookupResults(query, lookupField, filter, changeRequest);

          //Storing the results in the form of Key-Value pair
          allLookUpFieldsForOfflineContactEdit[lookupField.attributeName] = results;
        }
      }

      this.allLookUpFieldsForOfflineEdit = allLookUpFieldsForOfflineContactEdit;

      console.warn(`getAllLookupFieldsDataForOfflineContactEdit`);
      for (const key in this.allLookUpFieldsForOfflineEdit) {
        if (this.allLookUpFieldsForOfflineEdit.hasOwnProperty(key)) {
            console.log(`${key} : ${this.allLookUpFieldsForOfflineEdit[key].length}`);
            // console.warn(this.allLookUpFieldsForOfflineEdit[key]);
        }
      }

      this.disk.updateOrInsert(DB_KEY_PREFIXES.DYNAMIC_FORMS_ALL_LOOKUP_FIELDS, (doc)=>{
        doc = {
            raw: []
        };
        doc.raw = this.allLookUpFieldsForOfflineEdit;
        return doc;
      })
    } catch (error) {
      console.log('lookup fields data for offline contact edit - service failed', error)
    }
  }


  /*************Edit business information in offline - Non-OneKey contacts*************/
  public async getAllLookupFieldsForOfflineContactEdit():Promise<any> {
    let lookupFields = [];
    let entities = ['contact','contactcr','indskr_contactcr'];

    return await Promise.all ([
        entities.forEach(async (entityName) => {
          let contactForm: DynamicForm = await this.getFormDefinitionForEntity(entityName, FormType.EDITFORM);
          if(!contactForm){
            contactForm = new DynamicForm(DEFAULT_CONTACT_CREATE_FORM['value'][0]);
          }

          if (contactForm && contactForm.metadata && contactForm.metadata.length > 0) {
              contactForm.metadata.forEach((tab) => {
              if (tab && tab.controls && tab.controls.length > 0) {
                tab.controls.forEach(async control => {
                  if(control.dataType == ControlDataType.LookupType) {
                    lookupFields.push(control);
                  } else if (control.dataType == null && control['linkedEntityConfig']) {
                    const foundForm = await this.getFormDefinitionForEntity(control['linkedEntityConfig']['entityName'], FormType.CREATEFORM);

                    if (foundForm && foundForm.metadata && foundForm.metadata.length > 0) {
                      foundForm.metadata.forEach((tab) => {
                        if (tab && tab.controls && tab.controls.length > 0) {
                          tab.controls.forEach(async control => {
                            if(control.dataType = ControlDataType.LookupType) {
                              lookupFields.push(control);
                            }
                          });
                        }
                      });
                    }
                  }
                });
              }
            })
          }

        })//end of entities.forEach
    ]).then(()=>{
      let lookupFieldsToRemove = ['indskr_accountid','indskr_contactid'];
      //To remove Unwanted lookups fields from Offline storage
      return lookupFields = lookupFields.filter(item => !lookupFieldsToRemove.includes(item.attributeName));
    });
  }

  public getInputTextForFormField(control: Control,raw:any): string {
    try {
      if (control && control.dataType) {
        switch (control.dataType) {
          case ControlDataType.StringType:
          case ControlDataType.MoneyType:
          case ControlDataType.IntegerType:
          case ControlDataType.DecimalType:
          case ControlDataType.DoubleType:
          case ControlDataType.MemoType:
            return raw[control.attributeName]?raw[control.attributeName]:'';
          case ControlDataType.LookupType:
            if(control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
              if(raw['_' + control.attributeName  + '_value'] && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == raw['_' + control.attributeName  + '_value']);
                let translatedLang: string = '';
                let key = this.localizationService.getOneKeyTableAttributes();
                if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                if(foundRecord && foundRecord['omnione_onekeycodeslabelsid'] && translatedLang){
                  return translatedLang?translatedLang:'';
                }
              }
            }else if (raw['_' + control.attributeName + '_value']) {
              return raw['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue'] ? raw['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue'] : '';
            }
            else {
              return '';
            }
          case ControlDataType.BooleanType:
            return (raw && raw.hasOwnProperty(control.attributeName)) ? (raw[control.attributeName] ? this.translate.instant('YES') : this.translate.instant('NO')) : '';
          case ControlDataType.StateType:
          case ControlDataType.StatusType:
            if (raw[control.attributeName] || raw[control.attributeName] == 0) {
              let str = raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue'];
              return str ? str : '';
            }
            else {
              return '';
            }
          case ControlDataType.MultiSelectPicklistType:
            if (raw[control.attributeName]) {
              let str = raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',');
              return str ? str : '';
            }
            else {
              return '';
            }
          case ControlDataType.PicklistType:
            if (raw[control.attributeName]) {
              return raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue'] ? raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue'] : '';
            }
            else {
              return '';
            }
          case ControlDataType.DateTimeType:
            if (raw[control.attributeName] || raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
              let date: Date;
              if (raw[control.attributeName]) {
                date = new Date(raw[control.attributeName]);
                if (!isValid(date)) {
                  date = new Date(parseInt(raw[control.attributeName]));
                  if (!isValid(date) && raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                    date = new Date(raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                  }
                }
              } else if (raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                  date = new Date(raw[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
              }
              if (isValid(date)) {
                if (control.attributeName =='omnione_yearofbirth') {
                  return format(date, 'YYYY');
                } else if(control.attributeName =='omnione_dayandmonthofbirth'){
                  return format(date);
                }
              } else {
                return '';
              }
            } else {
              return '';
            }
          default:
            return '';
        }
      } else {
        return '';
      }
    } catch (error) {
      console.log("Error occured while parsing quick glance option values"+ error);
    }
  }

  //Fetch Business Rules
   private async getBusineesRulesForMobileAppForms(data){
    let formIds:Array<string> = []; 
    let formIdsString:string = '';
    try {
      if(data && data.length > 0){
        data.forEach(form => {
          if(form && form['indskr_formid']){
            let formId = form['indskr_formid'].substring(form['indskr_formid'].indexOf('(')+1,form['indskr_formid'].length-1);
            if(formId && !formIds.includes(formId)){
              formIds.push(formId);
              formIdsString+= `<value>${formId}</value>\n`
            }
          }
        });
        formIdsString = formIdsString.substring(0, formIdsString.length - 1);
        let fetchXML = MOBILE_FORMS_BUSINESS_RULES;
        fetchXML = fetchXML.replace('{FormIDS}', formIdsString);
        const businessRulesData = await this.dynamics.executeFetchQuery('workflows', fetchXML);
        if(businessRulesData && businessRulesData.length > 0){
          businessRulesData.forEach(rule=> {
            if(rule && rule['processtriggerformid']){
              let forms = data.filter(a=> a['indskr_formid'].includes(rule['processtriggerformid']));
                if(forms && forms.length > 0){{
                  forms.forEach(form=>{
                    if(form['businessRules']){
                      form['businessRules'].push(rule);
                    }else{
                      form['businessRules'] = [rule];
                    }
                  })
                }
              }
            }
          })
        }
      }
    } catch (error) {
      console.log(error);
    }
   }

   public async runBussinessRulesForLinkedEntity(subgridLayout,entityName,rawData){
    let foundForm:DynamicForm = this.getFormDefinitionForEntity(entityName,FormType.DISPLAYFORM);
    if(foundForm && foundForm.businessRules){
      this.currentLinkedEntityName = entityName+'.';
      this.currentLinkedEntityForm = foundForm;
      this.currentLinkedEntityRawData = rawData;
      this.currentLinkedEntitySubgridLayout = subgridLayout;
      this.currentLinkedEntitySubgridLayout.forEach(a=> {a['isVisible'] = true} );
      this.subgridLayoutChangeAfterBusinessRules = false;
      const businessRules = foundForm && foundForm.businessRules ? foundForm.businessRules : [];
      const functionMap: Array<Function> = [];

      // Create a map of function names to actual functions
      businessRules.forEach((funcString) => {
        try {
          if(funcString && funcString.logicFunction){
            funcString.logicFunction = funcString.logicFunction.replace('(eventContext)','(eventContext,Mscrm,Xrm)')
            const func = new Function('return ' + funcString.logicFunction)();
            if (func) {
              functionMap.push(func);
            }
          }
          
        } catch (error) {
          console.error(`Error parsing function: ${error}`);
        }
      });

      for (const func of functionMap) {
        try {
          const Mscrm = {
            BusinessRules: {
              Utility: {
                isNull: (o) => o == null || o === undefined,
                sanitizeGuid: (guid) => true,
              },
              ErrorHandlerFactory: {
                getHandler: function (e, f) {
                  return {
                    handleError: () => {
                      throw e;
                    },
                  };
                },
              },
            },
          };
          
          const Xrm = {
            Page: {
              data: {
                entity: undefined,
              },
            },
          };
          const eventContext = {
            getFormContext: () => this._getFormContext()
          }
          func(eventContext,Mscrm,Xrm);
        } catch (error) {
          console.error('Error executing function:', error);
        }
      }
      if(this.subgridLayoutChangeAfterBusinessRules){
        subgridLayout = this.currentLinkedEntitySubgridLayout.filter(a=> a['isVisible'] != false );
        this.subgridLayoutChangeAfterBusinessRules = false;
      }
    }
  }

  private _getFormContext(){
    return {
      data: {
        entity: {
          attributes: {
            get: (fieldName) => this._getAttribute(fieldName),
          }
        }
      }
    }
  }

  private _getAttribute(fieldName){
    return {
      getValue: () => this._getAttributeValue(fieldName),
      //setValue: (value) => this._setBusinessRuleAttributeValue(value,fieldName),
      controls: [
        {
          setVisible: (visible) => this._setVisible(visible,fieldName),
          setDisabled: (disabled) => true,//this._setDisabled(disabled,fieldName),
        },
      ],
      setRequiredLevel: (required) => true,//this._setIsRequired(required,fieldName),
    }
  }

  private _getAttributeValue(fieldName){
    let targetControl: Control = this._getTargetControl(fieldName);
    if(targetControl.dataType == ControlDataType.PicklistType){
      if (this.currentLinkedEntityRawData && this.currentLinkedEntityRawData.hasOwnProperty(this.currentLinkedEntityName+targetControl.attributeName)) {
        return parseInt(this.currentLinkedEntityRawData[this.currentLinkedEntityName+targetControl.attributeName]);
      }else{
        return null;
      }
    }
    else {
      return this._getInputTextForFormField(targetControl);
    }
  }

  private _getInputTextForFormField(control: Control): string {
    if (control && control.dataType) {
      switch (control.dataType) {
        case ControlDataType.StringType:
        case ControlDataType.MoneyType:
        case ControlDataType.IntegerType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.MemoType:
          return (this.currentLinkedEntityRawData && this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName]) ? this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName] : '';
        case ControlDataType.LookupType:
          return (this.currentLinkedEntityRawData && this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName]) ? this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName] : '';
        case ControlDataType.BooleanType:
          return (this.currentLinkedEntityRawData && this.currentLinkedEntityRawData.hasOwnProperty(this.currentLinkedEntityName+control.attributeName)) ? (this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName] ? this.translate.instant('YES') : this.translate.instant('NO')) : '';
        case ControlDataType.StateType:
          return (this.currentLinkedEntityRawData && (this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName] || this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName]==0)) ? this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName] : '';
        case ControlDataType.StatusType:
        case ControlDataType.MultiSelectPicklistType:
        //case ControlDataType.PicklistType:
          //return (this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        case ControlDataType.DateTimeType:
          return (this.currentLinkedEntityRawData && this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName]) ? this.currentLinkedEntityRawData[this.currentLinkedEntityName+control.attributeName] : '';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  private _getTargetControl(attrName: string): Control {
    let targetControl: Control
    for (let tabIdx = 0; tabIdx < this.currentLinkedEntityForm.metadata.length; tabIdx++) {
      const tab = this.currentLinkedEntityForm.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == attrName);
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    };
    return targetControl;
  }

  private _setVisible(visible, fieldName) {
    if(this.currentLinkedEntitySubgridLayout && this.currentLinkedEntitySubgridLayout.length > 0){
      let idx = this.currentLinkedEntitySubgridLayout.findIndex(a=> a.attribute == fieldName);
      if(idx>= 0){
        this.currentLinkedEntitySubgridLayout[idx]['isVisible'] = visible;
        this.subgridLayoutChangeAfterBusinessRules = true;
      }
    }
    // for (let tabIdx = 0; tabIdx < this.formMetadata.metadata.length; tabIdx++) {
    //   const tab = this.formMetadata.metadata[tabIdx];
    //   if (tab && tab.controls && tab.controls.length > 0) {
    //     let foundControl = tab.controls.find(c => c.attributeName == fieldName);
    //     if (foundControl) {
    //       foundControl.forceHide = !visible;
    //       break;
    //     }
    //   }
    // };
  }

}
