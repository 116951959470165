<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content>
  <ind-section-header [viewData]="detailHeaderModel"></ind-section-header>

  <ind-form-field [viewData]="getQuoteIdFormField()"></ind-form-field>

  <ind-form-field [viewData]="getNameFormField()"></ind-form-field>

  <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>

  <ind-datetime-form [viewData]="getEffectiveFromFormField()"></ind-datetime-form>

  <ind-datetime-form [viewData]="getEffectiveToFormField()"></ind-datetime-form>

  <ind-form-field [viewData]="getCountryFormField()"></ind-form-field>

  <ind-form-field [viewData]="getCurrencyFormField()"></ind-form-field>

  <ind-form-field [viewData]="getAccountsFormField()"></ind-form-field>

  <ind-form-field [viewData]="getPriceListFormField()"></ind-form-field>

  <ind-form-field [viewData]="getDiscountFormField()"></ind-form-field>

  <ind-form-field [viewData]="getDetailAmountFormField()"></ind-form-field>

  <ind-form-field [viewData]="getSuggestedAmountFormField()"></ind-form-field>

  <ind-form-field [viewData]="getOpportunityFormField()"></ind-form-field>

  <ind-form-field [viewData]="getSalesOrderFormField()"></ind-form-field>

  <!-- Product section -->
  <ion-item-group>
    <ind-section-header [viewData]='productsHeader' (onControlClick)='openProduct()'></ind-section-header>
    <div class="table-container">
      <table *ngIf="currentQuote?.products.length">
        <thead>
          <tr>
            <th>{{ 'PRODUCT' | translate }}</th>
            <th>{{ 'QUANTITY' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of currentQuote.products" (click)="openProduct(product)">
            <td>{{ product.name }}</td>
            <td>{{ product.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </ion-item-group>

  <!-- note section -->
  <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-section-header>
  <ind-text-area [placeHolder]="'ENTER_NOTES' | translate" [maxLength]="1000" [disabled]="isNotesDisabled"
    [showAttachment]="isAttachmentAdded" [hasDownload]="isAttachmentAdded" [attachmentTitle]="attachmentTitle"
    (removeAttachment)="removeAttachment($event)" [value]="notes" (indChange)="notesChanged($event)" [rows]="4"
    [cols]="10">
  </ind-text-area>
  <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
  <io-note *ngFor="let note of quotenotes" [isNoteAttachmentEnabled]="true" [inputNote]="note"
    [editable]="!isReadOnlyMode" [readOnlyNote]="isReadOnlyMode" [readOnlyAttachment]="false "
    [deletable]="!isReadOnlyMode" (saveNoteOut)="updateNote($event)" [disableDownloadInOffline]="true"
    [from]="uiService.page.MarketingPlanDetails"></io-note>

</ion-content>