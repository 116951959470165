import { IAllocationAdjustment } from './../../interfaces/allocation/allocation-adjustment.interface';
import { SelectListData } from "@omni/components/popover/popover";
import { Lot } from "./lot.class";
import { format } from "date-fns";
import { AuthenticationService } from '../../services/authentication.service';
import { AllocationAdjustService } from '../../services/sample/allocation-adjust.service';
import { SampleService } from '../../services/sample/sample.service';
import { EventsService } from '../../services/events/events.service';
import { AdjustmentStatus } from '../../enums/allocation/allocation.enum';
import { IShipmentReason } from '../../interfaces/allocation/allocation.shared.interface';

export class AllocationAdjustForm {
  formattedAdjustmentDate: string;
  user: SelectListData;
  sku: SelectListData;
  lot: Lot;
  adjustStatusTxt: string = '';
  isValid: boolean;
  isQtyValid: boolean;
  availableLots: Lot[];
  ownerID: string;
  ownerFullName: string;
  adjustmentNumber: string;
  private _adjustmentStatus: AdjustmentStatus;
  private _adjustmentDate: Date;
  private _quantityadjustment: number;
  private _reason: IShipmentReason;
  private _externalID: string;
  private _adjustmentID: string;
  comments: string;

//  public quantityInputDone$: Subject<boolean> = new Subject<boolean>();


  constructor(adjustment?: IAllocationAdjustment,
              private readonly authService?: AuthenticationService,
              private readonly reasons?: IShipmentReason[],
              private readonly sampleService?: SampleService,
              private readonly events?: EventsService,
              private readonly adjustService?: AllocationAdjustService) {

  this.adjustStatus = AdjustmentStatus.Open;

    // Placeholder for initialisation of adjustment.
    if (!adjustment) {
      this.adjustmentDate = new Date();
      this.availableLots = [];
      this.quantityAdjusted = null;
      this.ownerID = this.authService.user.systemUserID;
      this.ownerFullName = this.authService.user.displayName;
      this._externalID = `offline_adjustment_${new Date().getTime()}`

    } else {
    this.isValid = true;
    this.isQtyValid = true;
    this.availableLots = [];
    this.ownerID = adjustment.ownerId
    this._externalID = adjustment.indskr_externalid;
    this._adjustmentStatus = AdjustmentStatus.Open;
    const dateTimestamp: number = Number(adjustment.indskr_adjustmentdate);
    const isDateNumber = !isNaN(dateTimestamp) && dateTimestamp > 0;
    this.adjustmentDate = new Date(isDateNumber ? dateTimestamp : adjustment.indskr_adjustmentdate) ?? null;
    this.adjustmentID = adjustment.indskr_usershipmentallocation_v2id;
    this.adjustmentNumber = adjustment.indskr_shipmentnumber

      if (adjustment.indskr_reasonforadjustment) {
        if (reasons.length > 0) {
          const foundReason = reasons.find((reason) => reason.value === adjustment.indskr_reasonforadjustment);
          this.reason = foundReason;
        }
      }

      this.sku = {
        id: adjustment.at_indskr_skuid,
        title: adjustment.at_indskr_skuname,
        isSelected: true
      }

      adjustService.lots.forEach(o => {
        if (o.sampleSKUId === adjustment.at_indskr_skuid && o.status === 0 && o.totalQuantityRemaining >= 0) {
          if (o.id === adjustment.at_indskr_lotid) {
            o.isSelected = true;
            this.lot = o;
          }
          this.availableLots.push(o);
        }
      });
      this.quantityAdjusted = adjustment.indskr_quantityadjusted;
    }
  }

  set adjustStatus(status: AdjustmentStatus) {
    this._adjustmentStatus = status;
    this._setShipmentStatusTxt(status);
  }
  get adjustStatus(): AdjustmentStatus {
    return this._adjustmentStatus;
  }

  get adjustmentID() {
    return this._adjustmentID;
  }

  set adjustmentID(id: string) {
    this._adjustmentID = id;
  }

  set reason(reason: IShipmentReason) {
    this._reason = reason;
    this.validate();
  }
  get reason() {
    return this._reason;
  }
  get external_id() {
    return this._externalID;
  }

  set adjustmentDate(date: Date) {
    this._adjustmentDate = date;
    this.formattedAdjustmentDate = format(date, 'MMM D, YYYY');
  }
  get adjustmentDate(): Date {
    return this._adjustmentDate;
  }

  set quantityAdjusted(qty: any) {
    const qtyInt = parseInt(qty);
    this._quantityadjustment = !isNaN(qtyInt) ? qtyInt : null;
    this._validateQty();
    this.validate();
    this.events.publish('quantityInputDone$');
  }
  get quantityAdjusted(): any {
    return this._quantityadjustment;
  }

  private _setShipmentStatusTxt(status: AdjustmentStatus) {
    switch (status) {
      case AdjustmentStatus.Open:
        this.adjustStatusTxt = 'Open';
        break;
      case AdjustmentStatus.InReview:
        this.adjustStatusTxt = 'InReview';
        break;
      default:
        this.adjustStatusTxt = '';
        break;
    }
  }

  private _validateQty() {
    if (this._quantityadjustment > 0) {
      this.isQtyValid = this.lot && this._quantityadjustment > 0;
    } else {
      this.isQtyValid = this.lot && ((this.lot.totalQuantityRemaining + this._quantityadjustment) >= 0) && this._quantityadjustment != 0 && this._quantityadjustment != null;
    }
  }

  validate() {
    this.isValid = this.formattedAdjustmentDate && this.sku && this.reason && this.lot && this.isQtyValid;

    if(this.isValid && this.reason.value==548910004 && !this.comments) {
      this.isValid = false;
    }
  }
}
