<ion-header>
    <ion-toolbar class="left-header-toolbar" [ngClass]="{ 'offline-indicator': repService.isOfflineState, 'toolbar-android': isAndroid, 'impersontated-view': authenticationOfflineService.impersonatedUser }">
        <ion-buttons class="start-slot" slot="start">
            <ion-menu-toggle *ngIf="!authenticationOfflineService.impersonatedUser; else impersonatedUserLeftButton">
                <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" [ngClass]="{'whiteFont':activityService.teamViewActive}" src="assets/imgs/menu-toggle.svg"></ion-icon>
                <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
                      deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
                </span>
            </ion-menu-toggle>
            <ng-template #impersonatedUserLeftButton>
                <ion-button (click)="onStopImpersonation()">
                    <ion-icon slot="icon-only" src="assets/imgs/header_cancel.svg" class="seventyPercentWidth"></ion-icon>
                </ion-button>
            </ng-template>
        </ion-buttons>

        <div class="searchbar-wrapper" *ngIf="hasBaselineEnabled">
            <ion-searchbar [disabled]="agendaFooterService.actSegment == 'approvals'" (ionChange)="onInput($event)" (ionFocus)="onSearchFocus()" (ionBlur)="onSearchBlur()" [debounce]="300" placeholder="{{authenticationOfflineService.impersonatedUser?authenticationOfflineService.impersonatedUser.fullName+': ':''}}{{activityService.activityFilterValue | translate}}">
            </ion-searchbar>
            <div class="searchbar-overlay" *ngIf="shouldShowSearchBarFilterButton">
                <ion-button tooltip="Filter" class="searchbar-overlay-button" fill="clear" (click)="openActivitiesFilter($event)">
                    <ion-icon class="searchbar-overlay-button-filter-icon" slot="icon-only" src="assets/imgs/header_filter.svg"></ion-icon>
                </ion-button>
            </div>
        </div>

        <ion-buttons class="end-slot" slot="end" *ngIf="hasBaselineEnabled && !authenticationOfflineService.impersonatedUser">
            <ion-button tooltip="{{'NEW_ACTIVITY' | translate}}" [disabled]="activityService.teamViewActive" (click)="openNewActivity()">
                <ion-icon slot="icon-only" src="assets/imgs/ios_add_3x.svg"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ng-container *ngIf="backgroundUploadInProgress || syncInProgress">
      <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
      <div class="in-progress">
        <ion-spinner></ion-spinner>
        <span class="secondary-text">{{'UPLOAD_IN_PROGRESS' | translate}}</span>
      </div>
    </ng-container>
    <ion-toolbar *ngIf="hasBaselineEnabled && agendaFooterService.actSegment != 'scheduler' && (!faService.isShortCallLauncherEnabled || (faService.isShortCallLauncherEnabled && agendaFooterService.actSegment != 'week'))">
        <ion-button *ngIf="!faService.isShortCallLauncherEnabled" fill="clear" size="small" (click)="segmentChanged('notifications')" class="notification-btn">
          <ion-icon slot="icon-only" src="assets/imgs/notification_bluedot.svg" class="tab-notification-dot" *ngIf="assistantService.hasAnyUnRedNotifications"></ion-icon>
          <ion-icon slot="icon-only" src="assets/imgs/notifications-inactive.svg"></ion-icon>
        </ion-button>
        <ind-tabs [data]="tabsData" [(selectedTab)]="uiService.activitiesPageTab" (selectedTabChange)="segmentChanged($event)" (click)="segmentClick()"></ind-tabs>
    </ion-toolbar>
</ion-header>

<ion-content #content [ngClass]="{'vectorimageleft' : (!(groupedActivities$.value && groupedActivities$.value.length) && activityService.noActivityConfirmation && !activityService.activitiesSearchText)}">
    <div [ngSwitch]="agendaFooterService.actSegment" *ngIf="activityService.displayActivities && hasBaselineEnabled" class="activityMode">
        <div *ngSwitchCase="'agenda'" class="activityMode-agenda agenda-view">
            <!-- Display skeleton only during the app launch -->
            <ng-container *ngIf="deltaService.isInitialSyncFinished; else snapshot">
                <div *ngIf="!(groupedActivities$.value && groupedActivities$.value.length) && activityService.noActivityConfirmation && !activityService.activitiesSearchText" class="no-data-message">
                    {{'NO_ACTIVITIES' | translate}}
                </div>

                <ng-container *ngIf="groupedActivities$.value && groupedActivities$.value.length>0">
                    <virtual-list #activitiesvirtuallist [source$]="groupedActivities$" (update)="viewPortItems = $event" [height]="'off'" [visibleChildren]="12" [bufferAmount]="3" [childHeight]="96">

                        <div #item *ngFor="let activity of viewPortItems">
                            <ind-section-header *ngIf="activity.isDivider" [viewData]="activity.view"></ind-section-header>
                            <ion-item-sliding *ngIf="!activity.isDivider" #slidingItem>
                                <main-card [viewData]="getMainCardDataModel(activity)"></main-card>
                                <ion-item-options *ngIf="!activityService.teamViewActive && (device.isAndroid() || device.isIOS())">
                                  <div class="sliding-button" *ngIf="!activity.isCompleted && checkCustomScrapCondition(activity)" (click)="scrapActivityFromSlideButton(activity); slidingItem.close();">
                                    <img class="sliding-icon" src="assets/imgs/activity_pane_scrap.svg" width="40" />
                                    <p class="sliding-btn-text">{{'SCRAP'|translate}}</p>
                                  </div>
                                  <div class="sliding-button" *ngIf="checkCustomCloneCondition(activity)"(click)="cloneActivity(activity); slidingItem.close();">
                                    <img class="sliding-icon" src="assets/imgs/copyicon.svg" width="40" />
                                    <p class="sliding-btn-text">{{'CLONE'|translate}}</p>
                                  </div>
                                </ion-item-options>
                            </ion-item-sliding>
                          </div>

                    </virtual-list>
                </ng-container>
                <ng-container *ngIf="activityService.activitiesSearchText && (!groupedActivities$.value || groupedActivities$.value.length==0)">
                    <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                </ng-container>
            </ng-container>

            <ng-template #snapshot>
                <ng-container *ngIf="!deltaService.isInitialSyncFinished && activityService.snapshot; else initializing;">
                    <div *ngFor="let viewPortItem of activityService.snapshot">
                        <ind-section-header *ngIf="viewPortItem.isDivider" [viewData]="viewPortItem.view"></ind-section-header>
                        <main-card *ngIf="!viewPortItem.isDivider" [viewData]="viewPortItem"></main-card>
                    </div>
                </ng-container>
            </ng-template>

            <ng-template #initializing>
                <ion-list>
                    <ind-section-header [isSkeleton]="true"></ind-section-header>
                    <ng-container *ngFor="let i of [].constructor(numberOfSkeletonCardsToDisplay)">
                        <main-card [isSkeleton]="true"></main-card>
                    </ng-container>
                </ion-list>
            </ng-template>
        </div>

        <div *ngSwitchCase="'week'" class="week-view">
            <activity-calendar [calendarSearchText]="activityService.activitiesSearchText"></activity-calendar>
        </div>

        <div *ngSwitchCase="'scheduler'" class="scheduler-view">
            <scheduler-list [schedulerSearchText]="activityService.activitiesSearchText"></scheduler-list>
        </div>
        <div [ngClass]="{'disabled' : device.isOffline}" *ngSwitchCase="'approvals'" class="approvals-view">
          <app-approval-list></app-approval-list>
        </div>
    </div>
    <div class="no-data-message" *ngIf="!hasBaselineEnabled">
        {{'NO_ACCESS_TO_OMNIPRESENCE' | translate}}
    </div>
</ion-content>

<!-- <footer-toolbar *ngIf="device.isMobileDevice && device.deviceOrientation === 'portrait'" [footerView]="'masterView'" [selectedView]="'activities'"></footer-toolbar> -->
<!-- <ion-footer [hidden]="!hasBaselineEnabled">
  <footer-toolbar *ngIf="hasBaselineEnabled" [footerView]="'masterView'" [selectedView]="'activities'"></footer-toolbar>
</ion-footer> -->
