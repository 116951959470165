import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'omni-list-item',
  templateUrl: './omni-list-item.component.html',
  styleUrls: ['./omni-list-item.component.scss'],
})
export class OmniListItemComponent implements OnInit {
  @Input() value: string;
  @Input() label: string;
  @Input() cssClass : string;
  constructor() { }

  ngOnInit() { }

}
