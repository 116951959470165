<ion-header>
  <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
    <!-- <ion-buttons slot="start">
      <ion-button large fill="clear" color="primary" (click)="onCloseModal()"
        *ngIf="contactService.contactPageMode === compViewMode.READONLY">
        <div>{{ 'CANCEL' | translate}}</div>
      </ion-button>
      <ion-button  *ngIf="contactService.contactPageMode !== compViewMode.READONLY" icon-only  class='back-btn back-to-home-btn' color="primary" (click)="onCloseModal()">
          <ion-icon  class="back-btn back-btn-align" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons> -->
    <!-- <ion-title text-center>{{utilityService.globalCustomerText | translate}}</ion-title> -->
      <ind-header-left  [viewData]="indHeaderLeftModel"
        [searchDisabled]="true"
        [hideFilterButton]="true"
        [(searchText)]="contactSearchText"
        (onControlClick)="onPageTitleControlClick()"
      ></ind-header-left>
  </ion-toolbar>

<!--  <ion-toolbar >-->
    <!-- <ion-searchbar placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}" id = "contact-searchbar">
    </ion-searchbar> -->
<!--  </ion-toolbar>-->



  <!-- <ion-item-divider sticky='true' class="keyControlsArea" color="light">
</ion-item-divider> -->


</ion-header>

<ion-content>


  <ion-item-divider  sticky='true' *ngIf="$any(activityService.selectedActivity)?.contacts.length > 0">
    <div > {{ 'SELECTED_CAP' | translate}} ({{$any(activityService.selectedActivity)?.contacts.length}})</div>
  </ion-item-divider>

  <ion-list>
    <ion-item *ngFor="let selContact of $any(activityService.selectedActivity)?.contacts" [ngClass]="{'itemSelected':(selContact?.ID===contactService.contactInformation?.ID)}" (click)="openDetails(selContact)">
      {{selContact.firstName}} {{selContact.lastName}}
      <span *ngIf="selContact.isguest" class="contact-state-guest">{{ 'CONTACT_GUEST' | translate}}</span>
    </ion-item>
  </ion-list>
</ion-content>
