import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';



@Component({
  selector: 'glance-card',
  templateUrl: 'glance-card.html',
  styleUrls:['glance-card.scss']
})
export class GlanceCardComponent {

  @Input() viewData: GlanceCardViewDataModel;
  @Output() onControlClick = new EventEmitter<any>();

  public userInitials:string;

  constructor() {
  }

  public ngOnInit() {
    let name = this.viewData.name.split(' ')
    this.userInitials = (name.length > 1 ? name[0].charAt(0) + name[name.length - 1].charAt(0) : '').toUpperCase();
  }

  public onButtonClick(button,event){
    if(button && !button.isDisabled) {
      let obj = {
        id: button.id,
        item: button,
        event: event,
      };
      this.onControlClick.emit(obj);
    }
  }

  onExpandClick (){
    this.viewData.isExpanded = !this.viewData.isExpanded;
  }
}
