import { Injectable } from '@angular/core';
import { State } from '@omni/classes/shared/state.class';
import { INFO_BUTTON_IO_CONFIG_PREFIXES_AND_DATA_TYPE } from '@omni/config/pharmacovigilance-report/pharmacovigilance-report.config';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { Pharmacovigilance, PharmacovigilanceBtnConfigNameAndDataTypeMapping, PharmacovigilanceInfoBtnData, PharmacovigilanceInfoBtnDataValue, PharmacovigilanceInfoModalDataType } from '@omni/interfaces/pharmacovigilance-reporting/pharmacovigilance.interface';
import { IoConfiguration } from '@omni/interfaces/shared/shared.interface';
import { DataReadState, DataSyncState } from '@omni/types/state/state.type';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiskService } from '../disk/disk.service';

export type PageViewMode = 'Create' | 'Update' | 'ReadOnly';

@Injectable({
  providedIn: 'root',
})
export class PharmacovigilanceService {
  private _records$: BehaviorSubject<Pharmacovigilance[]> = new BehaviorSubject([]);
  private _selectedRecord$: BehaviorSubject<Pharmacovigilance> = new BehaviorSubject(null);
  private _detailPageViewMode$: BehaviorSubject<PageViewMode> = new BehaviorSubject('ReadOnly');
  private _infoButtonConfigNameAndDataTypeMap: PharmacovigilanceBtnConfigNameAndDataTypeMapping;
  private _infoButtonsData: Map<string, PharmacovigilanceInfoBtnData> = new Map();

  readonly records$: Observable<Pharmacovigilance[]> = this._records$.asObservable();
  readonly selectedRecord$: Observable<Pharmacovigilance> = this._selectedRecord$.asObservable();
  readonly detailPageViewMode$: Observable<PageViewMode> = this._detailPageViewMode$.asObservable();

  readonly recordDataSyncState: State<string, DataSyncState> = new State<string, DataSyncState>('pharmacovigilanceSync', 'Null');
  readonly recordDataReadState: State<string, DataReadState> = new State<string, DataReadState>('pharmacovigilanceRead', 'Null');

  constructor(
    private disk: DiskService,
  ) {}

  setSelectedRecord(record: Pharmacovigilance) {
    this._selectedRecord$.next(record);
  }
  setDetailPageViewMode(viewMode: PageViewMode) {
    this._detailPageViewMode$.next(viewMode);
  }

  hasSelectedRecord(): boolean {
    return !!(this._selectedRecord$.getValue());
  }

  async saveFullSyncedRecords(response: Pharmacovigilance[], syncInfo: EntitySyncInfo) {
    if (Array.isArray(response)) {
      try {
        await this.disk.updateOrInsert(
          DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS,
          doc => ({
            raw: response,
          })
        );
        syncInfo.totalSynced = response.length;
      } catch (error) {
        console.error('saveFullSyncedRecords: ', error);
        throw error;
      }
    }
  }

  async loadRecordsFromDB() {
    let records: Pharmacovigilance[] = [];
    try {
      this.updateRecordDataReadState('Read');
      const doc: { raw: Pharmacovigilance[] } = await this.disk.retrieve(
        DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS,
        true,
      );
      if (Array.isArray(doc?.raw)) {
        records = doc.raw;
      }
      this._records$.next(records);
      this.updateRecordDataReadState('Success');
    } catch (error) {
      console.error('loadRecordsFromDB: ', error);
      this._records$.next(records);
      this.updateRecordDataReadState('Fail');
    }
  }
  unloadRecords() {
    this._records$.next([]);
    this.updateRecordDataReadState('Null');
  }

  async saveCreatedRecord(record: Pharmacovigilance) {
    const records = this._records$.getValue();
    records.unshift(record);
    this._records$.next(records);
    await this.disk.updateOrInsert(
      DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS,
      doc => ({
        raw: records,
      }),
    );
  }

  async saveInfoButtonsData(configDataArray: IoConfiguration[]) {
    try {
      if (Array.isArray(configDataArray)) {
        await this.disk.updateOrInsert(
          DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS_INFO_BTNS_DATA,
          doc => ({
            raw: configDataArray,
          }),
        );
      }
    } catch (error) {
      console.error('saveInfoButtonsData: ', error);
    }
  }
  async loadInfoButtonsDataFromDB(buName: string) {
    try {
      let configData: IoConfiguration[];
      const doc: { raw: IoConfiguration[] } = await this.disk.retrieve(
        DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS_INFO_BTNS_DATA,
        true,
      );
      if (Array.isArray(doc?.raw)) {
        configData = doc.raw;
        this.generateInfoButtonConfigMapping(buName);
        this.setInfoButtonsData(configData, buName);
      }
    } catch (error) {
      console.error('loadInfoButtonsData: ', error);
    }
  }
  unloadInfoButtonsData() {
    if (this._infoButtonConfigNameAndDataTypeMap || this._infoButtonsData.size > 0) {
      this._infoButtonsData.clear();
      this._infoButtonConfigNameAndDataTypeMap = undefined;
    }
  }

  setInfoButtonsData(configDataArray: IoConfiguration[], buName: string) {
    if (this._infoButtonsData.size > 0) {
      this._infoButtonsData.clear();
    }
    for (const configData of configDataArray) {
      const configNamePrefix = configData.indskr_configname.replace(buName, '');
      const dataType: PharmacovigilanceInfoModalDataType = this._infoButtonConfigNameAndDataTypeMap
                                          .hasOwnProperty(configNamePrefix)
                                          ? this._infoButtonConfigNameAndDataTypeMap[configNamePrefix].dataType
                                          : null;
      if (dataType !== null) {
        try {
          const value: PharmacovigilanceInfoBtnDataValue = dataType === 'table'
            ? JSON.parse(configData.indskr_configvalue)
            : { text: configData.indskr_configvalue };

          this._infoButtonsData.set(
            configNamePrefix,
            {
              configName: configData.indskr_configname,
              value,
            },
          );
        } catch (error) {
          console.error('setInfoButtonsData: ', error);
        }
      }
    }
  }
  getInfoButtonData(configNamePrefix: string): PharmacovigilanceInfoBtnData | null {
    return this._infoButtonsData.get(configNamePrefix) || null;
  }
  generateInfoButtonConfigMapping(buName: string): PharmacovigilanceBtnConfigNameAndDataTypeMapping {
    let mapping: PharmacovigilanceBtnConfigNameAndDataTypeMapping = {};
    for (const key in INFO_BUTTON_IO_CONFIG_PREFIXES_AND_DATA_TYPE) {
      if (Object.prototype.hasOwnProperty.call(INFO_BUTTON_IO_CONFIG_PREFIXES_AND_DATA_TYPE, key)) {
        const dataType: PharmacovigilanceInfoModalDataType = INFO_BUTTON_IO_CONFIG_PREFIXES_AND_DATA_TYPE[key];
        const configName = key + buName;
        mapping[key] = {
          configName,
          dataType
        };
      }
    }
    this.setInfoButtonConfigNameAndDataTypeMap(mapping);
    return mapping;
  }

  updateRecordDataSyncState(state: DataSyncState) {
    this.recordDataSyncState.setState(state);
  }
  private updateRecordDataReadState(state: DataReadState) {
    this.recordDataReadState.setState(state);
  }

  private setInfoButtonConfigNameAndDataTypeMap(mapping: PharmacovigilanceBtnConfigNameAndDataTypeMapping) {
    this._infoButtonConfigNameAndDataTypeMap = mapping;
  }
}
