import { Component, Input, OnInit } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';
import { EventsService } from '../../../services/events/events.service';
import { TranslateService } from '@ngx-translate/core';

import { UIService } from '../../../services/ui/ui.service';

@Component({
    selector: 'nothing-selected-view',
    templateUrl: 'nothing-selected-view.html',
  styleUrls:['nothing-selected-view.scss']
  })
  export class NothingSelectedView implements OnInit {

    @Input() textToDisplay: string;
    @Input() notShowText = false;
    @Input() deviceString: string;
    platform: string;
    constructor(
                private readonly translate: TranslateService, 
                private readonly uiService: UIService,
                private readonly events: EventsService,
                public readonly deviceService: DeviceService) {
      this.textToDisplay = this.uiService.nothingSelectedScreenMessage;
    }
    ngOnInit(){
      this.textToDisplay = this.translate.instant('NOTHING_SELECTED');
      this.events.subscribe('nothingSelectedMessageUpdate', ()=>{
        this.textToDisplay = this.uiService.nothingSelectedScreenMessage;
      });
      this.events.subscribe('other', () => {
        this.platform = 'other';
      });
    }
  }

