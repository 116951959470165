<div #swipeLayer (swipeleft)="swipe($event.type)" (swiperight)="swipe($event.type)" class="swipe-layer"></div>
<div [ngSwitch]="assetType" class="iframe-wrapper" #iframeWrapper [ngClass]="{'imageWrapper': assetType === 'image'}"
  [ngStyle]="wrapperStyle">
  <iframe *ngSwitchCase="'presentation'" id="content-iframe" frameBorder="0" [ngStyle]="frameStyle" #iframeContainer
    [hidden]="!link" allow="autoplay" [src]="link | safe:'resourceUrl'"
    (load)="injectSlidePageConfig(iframeContainer)"></iframe>
  <img #imageContainer class="imageContent" *ngSwitchCase="'image'" [src]="link | safe:'resourceUrl'"
    [ngStyle]="frameStyle" />
  <img #imageTempContainer *ngSwitchCase="'image'" [src]="link | safe:'resourceUrl'" hidden />
  <ng2-pdfjs-viewer #pdfViewer *ngSwitchCase="'pdf'" viewerId="pdfResource" [(page)]="deafultPdfPage"
    (onPageChange)="onPageChange($event)" [pdfSrc]="link" openFile="false" viewBookmark="false" download="false"
    (onDocumentLoad)="initLoadCompleted($event)"></ng2-pdfjs-viewer>

  <pdf-viewer class="allow-scroll-bar" #pdfPresentationViewer *ngSwitchCase="'pdfPresentation'" [src]="link" [(page)]="pdfPresentationPageNum"
    [show-all]="false" [render-text]="true" [zoom-scale]="'page-width'"
    [original-size]="false" (page-rendered)="afterPDFLoaded()" (pagechanging)="pageRendered($event)"
    [ngStyle]="frameStyle">
  </pdf-viewer>

  <video #videoContainer *ngSwitchCase="'video'" controls disablePictureInPicture [src]="link | safe:'resourceUrl'" width="100%"
    oncontextmenu="return false;" controlsList="nodownload noremoteplayback">
    <source [src]="link | safe:'resourceUrl'">
  </video>
  <p class="no-data-message" *ngSwitchCase="'unsupported'">
    {{'CONTACT_NO_PREVIEW_AVAILABLE_FOR' | translate}} <i>{{$any(_activeContent).title}} - {{'PARTICI_NOT_SUPPORTED' |
      translate}}</i>
  </p>
</div>
<div appDebounceClick *ngIf="assetType !== 'unsupported' && !hideNavigation" (debounceClick)="changePage(-1)"
  class="arrow-float-left"><img src="assets/imgs/arrow-left.svg"> </div>
<div appDebounceClick *ngIf="assetType !== 'unsupported' && !hideNavigation" (debounceClick)="changePage(1)"
  class="arrow-float-right"><img src="assets/imgs/arrow-right.svg"> </div>
