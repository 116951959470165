import { Pipe, PipeTransform } from '@angular/core';
import { format, isValid } from 'date-fns';
import _ from 'lodash';
@Pipe({
    name: "capitalizeFirstLetter"
})
export class CapitalizeFirstLetter implements PipeTransform{
    transform(value: string, formatting?: string, skipPipe = false): string {
      let formattedStr = value;
      if(!formatting && !skipPipe && !_.isEmpty(formattedStr)){
        let words = value.toLowerCase().trim().split(' ').filter(word=> !_.isEmpty(word));
        words = words.map(a=> a =a.replace(a[0],a[0].toUpperCase()));
        formattedStr = words.join(' ');
      }
      return formattedStr;
    }

}
