import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { SelectListDetail } from '@omni/models/select-list-detail-model';
import { ActivityService } from '@omni/services/activity/activity.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-quote-products',
  templateUrl: './quote-products.component.html',
  styleUrls: ['./quote-products.component.scss'],
})
export class QuoteProductsComponent implements OnInit {
  @Input() inputProducts = [];
  @Input() isEditMode = false;
  @Input() selectedProducts = [];
  @Input() callbackEvent: Function;
  @Input() isDuplicateAllowed = false;
  @Input() selectedProduct: any = {};

  procedureTrackerPageTitle: IndPageTitleViewDataModel;
  detailsHeader: IndSectionHeaderViewDataModel;
  filteredProduct = [];
  selectedProductsIds = [];
  _isClicked = [];

  constructor(
    public translate: TranslateService,
    private modalCtrl: ModalController,
    public activityService: ActivityService,
    public navService: NavigationService
  ) { }

  ngOnInit() {
    this.getDetailsHeader();
    this.getPageTitle();

  }

  get isFormValid() {
    return (!_.isEmpty(this.selectedProduct?.name) && !_.isEmpty(this.selectedProduct?.quantity))
  }

  getDetailsHeader() {
    let buttons = [];
    // // if (this.isInMeetingProcedureTrackerFlow) {
    //   buttons.push(
    //     {
    //       id:'cancel',
    //       text: this.isEditMode ? this.translate.instant('SCRAP'):this.translate.instant('CANCEL'),
    //       // isDisabled: this.backgroundUploadInProgress ? true : !this.isCancelEnabled,
    //     },
    //     {
    //       id:'confirm',
    //       text: this.isEditMode ? this.translate.instant('UPDATE'): this.translate.instant('SAVE'),
    //       // isDisabled: this.backgroundUploadInProgress ? true : this.isEditMode ? !this.isFormDirty: !this.isConfirmEnabled,
    //     });
    // // }
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: buttons,
    };
  }

  getPageTitle() {
    const controls: any = [
      {
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.isEditMode ? this.translate.instant('SCRAP') : this.translate.instant('CANCEL'),
        isDisabled: false,
        // isDisabled: this.backgroundUploadInProgress || this.device.isOffline ? true : !this.isCancelEnabled,
        align: "right"
      },
      {
        id: 'confirm',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.isEditMode ? this.translate.instant('UPDATE') : this.translate.instant('SAVE'),
        isDisabled: !this.isFormValid,
        align: "right"
      },

    ]

    if (this.isEditMode) {
      controls.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      })
    }

    this.procedureTrackerPageTitle = {
      id: 'product',
      title: this.translate.instant('PRODUCT'),
      controls: controls
    };
  }

  public getQtyFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUANTITY'),
      inputText: this.selectedProduct?.quantity ?? '',
      inputValue: this.selectedProduct?.quantity ?? '',
      customPlaceholderLabel: this.translate.instant('ENTER_QUANTITY'),
      id: 'qty-field',
      inputType: 'number',
      showArrow: true,
      isRequired: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClicked && this._isClicked['qty-field'] && _.isEmpty(this.selectedProduct?.quantity),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }


  getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.selectedProduct?.name ?? '',
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
      id: 'product-field',
      isReadOnly: true,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['price-list-field'] && _.isEmpty(this.selectedProduct?.name),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  handleButtonClick(id) {
    let isDeleted = false;
    switch (id) {
      case 'confirm':
        // if (this.isConfirmEnabled) {
        this.callbackEvent(this.selectedProduct, isDeleted);
        // }
        break;
      case 'cancel':
        if (this.isEditMode) {
          isDeleted = true;
          this.callbackEvent(this.selectedProduct, isDeleted);
        }
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
    // if (this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow) {
    // this.navCtrl.pop();
    // } else {
    this.navService.popChildNavPageWithPageTracking();
    // }
  }

  handleFormFieldEvent(id, event, eventName) {
    switch (id) {
      case "product-field":
        this.openProductSelect();
        break;
      case 'qty-field':
        if (eventName && eventName == 'input_value_confirm') {
          let val = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : '';
          this.handleQunatity(val);
        }
        break;
      default:
        break;
    }
    this._isClicked[id] = true;

  }

  async handleQunatity(quantity) {
    if (quantity && _.toNumber(quantity) <= 0) quantity = '';
    this.selectedProduct.quantity = quantity;
    this.getPageTitle();
  }

  async openProductSelect() {
    if (!this.inputProducts.length) return;
    this.selectedProductsIds = this.selectedProducts.map(({ id }) => id);
    const products = _.cloneDeep(this.inputProducts.map(({ id, name }) => ({ id, title: name, isSelected: this.selectedProduct?.id === id })));

    const selectListDetail: SelectListDetail = {
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('ACCOUNT_CALL_PLAN_ALL_PRODUCTS'),
      data: products
    };
    let selectProdcutModal = await this.modalCtrl.create({
      component: SelectListComponent, componentProps: {
        viewData: selectListDetail
      }, cssClass: 'select_country', backdropDismiss: false
    });
    selectProdcutModal.present();

    selectProdcutModal.onDidDismiss().then(async ({ data }: any) => {
      if (!data || !data.isDone) return;
      if (data.selectedItems.length) {
        const selectedProduct = this.inputProducts.find(({ id }) => id == data.selectedItems[0].id);
        if (!this.isDuplicateAllowed && this.selectedProductsIds.includes(selectedProduct.id)) {
          const ExistingProduct = this.selectedProducts.find(({ id }) => selectedProduct.id === id)
          this.selectedProduct = ExistingProduct;
          this.isEditMode = true;
        } else {
          this.selectedProduct = { ...selectedProduct, quantity: this.selectedProduct?.quantity ?? '' };
          this.selectedProducts.push(this.selectedProduct);
        }
        this.getPageTitle();
      }
    });
  }

}
