import { ChartFilterOption } from './../../../../interfaces/edge-analytics/report.interface';
import { IndReportCardCompBaseData, IndReportCardSegmentData } from './../../../../interfaces/edge-analytics/ind-report-card-base.interface';
import { ProcedureCardKpiId, ProcedureKPI } from './../../../enums/edge-analytics/surgery-order/surgery-order-report.enum';
import { IndReportTileDataModel } from './../../../models/IndReportTileDataModel';
export const SurgeryOrderReportTilePerRow = 3;
export const SurgeryOrderReportTiles: IndReportTileDataModel[] = [
  {
    id: ProcedureKPI.customerReach,
    firstHeading: "CUSTOMER_REACH_YTD",
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
  {
    id: ProcedureKPI.ytd,
    firstHeading: 'YTD',
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
  {
    id: ProcedureKPI.mtd,
    firstHeading: 'MTD',
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
  {
    id: ProcedureKPI.wtd,
    firstHeading: 'WTD',
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
  {
    id: ProcedureKPI.yesterday,
    firstHeading: "YESTERDAY",
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
  {
    id: ProcedureKPI.contacts,
    firstHeading: "CUSTOMERS",
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
  {
    id: ProcedureKPI.accounts,
    firstHeading: "ACCOUNTS",
    dataValue: '',
    dataFieldName: '',
    order: 0,
  },
];

export const SurgeryOrderReportCards: IndReportCardCompBaseData[] = [
  {
    id: ProcedureCardKpiId.byContact,
    title: "PROCEDURES_BY_CUSTOMER",
    showSegment: false,
    // segmentData: null,
    cardBodyCompData: null,
    cardBodyCompType: 'card-table',
    mobileCardSize: 12,
    cardSize: 6,
  },
  {
    id: ProcedureCardKpiId.byAccount,
    title: "PROCEDURES_BY_ACCOUNT",
    showSegment: false,
    // segmentData: null,
    cardBodyCompData: null,
    cardBodyCompType: 'card-table',
    mobileCardSize: 12,
    cardSize: 6,
  },
];

export const SurgeryOrderCardSegments: { [key: string]: IndReportCardSegmentData } = {
  byContact: {
    initialValue: 'ytd',
    buttons: [
      {
        label: 'YTD',
        value: 'ytd',
      },
      {
        label: 'MTD',
        value: 'mtd',
      },
    ]
  },
  byAccount: {
    initialValue: 'ytd',
    buttons: [
      {
        label: 'YTD',
        value: 'ytd',
      },
      {
        label: 'MTD',
        value: 'mtd',
      },
    ]
  },
}

export const ProcedureTrendChartFilterOptions: ChartFilterOption[] = [
  {
    value: ProcedureKPI.completedTrend,
    isSelected: true,
    displayText: '#Procedures',
    displayTextKey: "HASH_PROCEDURES",
    data: undefined
  },
]

export const SurgeryOrderChartTitles = {
  bySurgery: 'BY_SURGERY',
  trend: 'TREND',
}
