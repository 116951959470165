<ion-grid no-padding class="users-page">
    <ion-row no-padding>
        <!--  -->
        <ion-col class="users-left-pane" no-padding>
            <ion-header>
                <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="searchInput" [searchDisabled]="mode == 'read_only'" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="searchText($event.detail)"></ind-header-left>
            </ion-header>

            <ion-content no-padding [ngClass]="{'vectorimageleft': (!searchInput && getUsersList.length == 0  && selectedUsers.length == 0)}">
                <ion-item-group>
                    <p *ngIf="(device.isOffline && !isSPTask) && selectedUsers.length == 0 && (mode == 'multi_selection')" justify-content-center class="middle-center">
                        {{'CHILD_USER_ASSIGNMENT_IS_ONLY_AVAILABLE_IN_ONLINE_MODE' | translate}}
                    </p>
                    <!-- Selected users multi selection view -->
                    <!-- <ion-item-divider *ngIf=" (mode == 'multi_selection' || mode == 'read_only') && selectedUsers.length > 0" sticky="true">
                        {{'SELECTED' | translate}} ({{selectedUsers.length}})
                    </ion-item-divider> -->
                    <ind-section-header *ngIf=" (mode == 'multi_selection' || mode == 'read_only') && selectedUsers.length > 0" [viewData]='{id:"selectedUsersHeader",title: (translate.instant("SELECTED"))+" ("+selectedUsers.length+")"}'></ind-section-header>
                    <ion-list scrollY="true" *ngIf="selectedUsers.length>0">
                        <ion-item-group class='selector-item' *ngFor="let user of selectedUsers; let i = index;">
                            <ion-item>
                              <ion-label>
                                <ion-text class="primary-text">{{user.displayName}}</ion-text>
                                <p *ngIf="user.emailaddress" class="secondary-text">
                                  <ion-text>{{user.emailaddress}}, {{user.businessunitname}}</ion-text>
                                </p>
                              </ion-label>
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" *ngIf="mode == 'multi_selection' && (!device.isOffline || isSPTask)" slot="end" [ngClass]="{
                                  'disabled': !user.isRemovable
                                }"
                                (click)="addRemoveToSelected(user,false)"> </ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <!-- All users single selection -->
                    <!-- <ion-item-divider *ngIf="mode =='single_selection'  && !searchActive; else searchNotActive" sticky="true">
                        {{'ALL_TEAM_MEMBERS_CAP' | translate}} ({{(getUsersList).length}})
                    </ion-item-divider> -->
                    <ind-section-header *ngIf="mode =='single_selection'  && !searchActive; else searchNotActive" [viewData]='{id:"allUsersHeader",title:this.from == "ActivtiesPageFilter" ? this.translate.instant("COACHING_TEAM_MEMBERS"):this.translate.instant("ALL_USERS")+" ("+getUsersList.length+")"}'></ind-section-header>
                    <ng-template #searchNotActive>
                        <!-- <ion-item-divider *ngIf="mode =='single_selection'" sticky="true">
                            <ion-label class="textColor">
                                {{'RESULTS_CAP' | translate}} ({{(getUsersList).length}})
                            </ion-label>
                        </ion-item-divider> -->
                        <ind-section-header *ngIf="mode =='single_selection'" [viewData]='{id:"resultsUsersHeader",title: (translate.instant("RESULTS_CAP"))+" ("+getUsersList.length+")"}'></ind-section-header>
                    </ng-template>


                    <ion-list scrollY="true" *ngIf="getUsersList.length>0 && mode =='single_selection'" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                        <ion-item-group class='selector-item' [ngClass]="{'itemSelected':(user?.userId===selectedUser?.userId)}" *ngFor="let user of usersToDisplay; let i = index;" (click)="handleUserSelection(user)">
                            <ion-item>
                                <ion-label>{{user.fullName}}</ion-label>
                                <ion-icon class="checkmark-icon" name="indegene-selectors-checkmark-icon" *ngIf="user.userId == selectedUser?.userId" slot="end"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                    

                    <!-- All users multi selection view -->
                    <!-- <ion-item-divider *ngIf="(!device.isOffline || isSPTask) && mode == 'multi_selection' && !searchInput; else noSearchInput" sticky="true">
                        {{'ALL_TEAM_MEMBERS_CAP' | translate}} ({{(getUsersList).length}})
                    </ion-item-divider> -->
                    <ind-section-header *ngIf="(!device.isOffline || isSPTask) && mode == 'multi_selection' && !searchInput; else noSearchInput" [viewData]='{id:"allUsersHeader",title:this.from == "ActivtiesPageFilter" ? this.translate.instant("COACHING_TEAM_MEMBERS"):this.translate.instant("ALL_USERS")+" ("+getUsersList.length+")"}'></ind-section-header>
                    <ng-template #noSearchInput>
                        <!-- <ion-item-divider *ngIf="(!device.isOffline || isSPTask) && mode == 'multi_selection'" sticky="true">
                            <ion-label class="textColor">
                                {{'RESULTS_CAP' | translate}} ({{(getUsersList).length}})
                            </ion-label>
                        </ion-item-divider> -->
                        <ind-section-header *ngIf="(!device.isOffline || isSPTask) && mode == 'multi_selection'" [viewData]='{id:"resultsUsersHeader",title: (translate.instant("RESULTS_CAP"))+" ("+getUsersList.length+")"}'></ind-section-header>
                    </ng-template>

                    <ion-list scrollY="true" *ngIf="(!device.isOffline || isSPTask) && getUsersList.length>0 && mode == 'multi_selection'" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                        <ion-item-group class='selector-item' *ngFor="let user of usersToDisplay">
                            <ion-item>
                                <!-- <ion-label>{{user.displayName}}</ion-label>
                                <p *ngIf="user.emailaddress" class="secondary-text">
                                  <ion-text>{{user.emailaddress}}</ion-text>
                                </p> -->
                                <ion-label>
                                  <ion-text class="primary-text">{{user.displayName}}</ion-text>
                                  <p *ngIf="user.emailaddress" class="secondary-text">
                                    <ion-text>{{user.emailaddress}}, {{user.businessunitname}}</ion-text>
                                  </p>
                                </ion-label>
                                <ion-icon class="add-icon" name="indegene-selectors-add-icon" slot="end" *ngIf="!user.isSelected" (click)="addRemoveToSelected(user,true)"> </ion-icon>
                                <ion-icon class="checkmark-icon" name="indegene-selectors-checkmark-icon" slot="end" *ngIf="user.isSelected"> </ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                    <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
                      <ion-infinite-scroll-content></ion-infinite-scroll-content>
                    </ion-infinite-scroll>
                    <p class="no-data-message" *ngIf="!searchInput && getUsersList.length == 0  && selectedUsers.length == 0" justify-content-center>
                        {{'NO_USERS' | translate}}
                    </p>
                    <ind-no-search-result *ngIf="searchInput && getUsersList.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                </ion-item-group>
            </ion-content>
            <!-- <ion-footer> -->
                <footer-toolbar [footerView]="'masterView'" [selectedView]="'childUsers'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>
        <!--  -->
        <ion-col class="users-right-pane" no-padding>
            <nothing-selected-view [textToDisplay]="''" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
        </ion-col>
    </ion-row>
</ion-grid>
