<ion-header>
  <ion-toolbar class="left-header-toolbar" [ngClass]="{'toolbar-android': isAndroid}">
    <ion-buttons class="start-slot" slot="start">
      <ion-menu-toggle>
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" src="assets/imgs/menu-toggle.svg"></ion-icon>
        <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
              deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
        </span>
      </ion-menu-toggle>
    </ion-buttons>

    <div class="searchbar-wrapper">
        <ion-searchbar [(ngModel)]="searchText" (ionChange)="onSearchInput($event)" [debounce]="300" placeholder="{{'HOME' | translate}}"></ion-searchbar>
    </div>
  </ion-toolbar>

  <ng-container *ngIf="backgroundUploadInProgress || syncInProgress">
    <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
    <div class="in-progress">
      <ion-spinner></ion-spinner>
      <span class="secondary-text">{{'UPLOAD_IN_PROGRESS' | translate}}</span>
    </div>
  </ng-container>
</ion-header>

<ion-content>
  <ng-container *ngIf="selectedAccount; else accountWidgetskeleton">
    <div id="account-container">
      <ion-item class="no-border">
        <ion-icon slot="start" name="accountwidgeticon" class="accountwidgeticon"></ion-icon>
        <ion-label >
          <ion-text class="primary-text account" (click)="openAccountSelection()">{{selectedAccount.accountName}}</ion-text>
          <ion-text class="secondary-text targetCount">{{targetContactsCount}} {{'TARGET_CONTACTS' | translate}}</ion-text>
        </ion-label>
        <ion-icon name="chevron-down-outline" class="arrow-icon" (click)="openAccountSelection()"></ion-icon>
        <ion-button slot="end" fill="clear" *ngFor="let option of shortCallActivityOptions" (click)="selectOption(option)" [ngClass]="{selected: selectedActivityOption == option}"><span>{{option | translate}}</span></ion-button>
      </ion-item>
    </div>
  </ng-container>

  <div *ngIf="selectedActivityOption === 'MEETING' && selectedAccount" id="contact-container">
    <div class="contact-chips">
      <div [id]="contact.ID"
        *ngFor="let contact of contactListToBeDisplayedObservable | async"
        class="contact-chip"
        [ngClass]="{'chip-selected': contact.ID === selectedContact?.ID}"
        (click)="contactChipClick(contact)"
      >
        <ion-icon *ngIf="contact.isTargetContact"
          [name]="contact.ID === selectedContact?.ID ? 'target-contact-icon-selected' : 'target-contact-icon'"
          [class]="contact.ID === selectedContact?.ID ? 'target-contact-icon-selected' : 'target-contact-icon'"
        ></ion-icon>
        <span>{{ contact.fullname | uppercase }}</span>
      </div>
    </div>
    <div *ngIf="selectedContact" class="contact-detail-wrapper">
      <div class="contact-detail-card">
        <div class="contact-detail-card-row">
          <div class="contact-detail-card-upper-line-text contact-attriute-row">
            <div class="contact-attribute contact-name">
              {{selectedContact.fullname}}
            </div>
            <div *ngIf="selectedContact.indskr_externalid" class="contact-attribute contact-etms">
              {{selectedContact.indskr_externalid}}
            </div>
            <div *ngIf="selectedContact.raw.department" class="contact-attribute contact-department">
              {{selectedContact.raw.department}}
            </div>
          </div>
          <div class="contact-detail-card-upper-line-btn">
            <ion-button
              fill="outline"
              class="contact-detail-btn"
              (click)="openContactDetail(selectedContact)"
            >{{'DETAILS' | translate}}</ion-button>
          </div>
        </div>
        <div class="contact-detail-card-row">
          <div class="contact-attriute-row">
            <div *ngIf="selectedContact.productSegmentations && selectedContact.productSegmentations[0] && selectedContact.productSegmentations[0].segmentation" class="contact-attribute contact-segment">
              {{selectedContact.productSegmentations[0].segmentation}}
            </div>

            <div *ngIf="selectedContact.raw.indskr_speaker" class="contact-attribute contact-speaker">
              <div>{{'SPEAKER' | translate}}</div>
            </div>

            <div *ngIf="(mostRecentActivityLoadingCounter.counterObservable | async) <= 0 && mostRecentActivityTitle" class="contact-attribute contact-activity">
              <div class="text">
                {{mostRecentActivityTitle}}
              </div>
            </div>
            <div *ngIf="(mostRecentActivityLoadingCounter.counterObservable | async) > 0" class="contact-attribute contact-activity">
              <div class="text">
                <ion-skeleton-text class="skeleton-text-animated" style="width: 200px;"></ion-skeleton-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

  <div id="content-container">
    <div class="card-filter-section">
      <div class="section-title">{{'CONTENT' | translate}}</div>
      <div class="card-filter">
        <ind-tabs [data]="productTabData" [type]="'md'" [(selectedTab)]="selectedProductTab"
          (selectedTabChange)="selectedTabChange($event)"></ind-tabs>
        <div class="searchbar-overlay" *ngIf="!syncInProgress">
          <ion-button tooltip="Filter" class="searchbar-overlay-button" fill="clear" (click)="openContentFilter()">
            <ion-icon class="searchbar-overlay-button-filter-icon" slot="icon-only"
              src="assets/imgs/filter-blue-horz.svg"></ion-icon>
            <span class="active-filter" *ngIf="selectedTags.length > 0"></span>
          </ion-button>
        </div>
      </div>
    </div>
    <div class="card-section">
      <io-content-cards [contentData]="eCardData" (selectedContent)="onContentSelect($event, true)"></io-content-cards>
      <io-content-cards [contentData]="edaData" (selectedContent)="onContentSelect($event, false)"></io-content-cards>
    </div>
  </div>

</ion-content>

<ng-template #accountWidgetskeleton>
  <ion-item class="wrapper-item no-border">
    <ion-thumbnail slot="start" class="main-icon">
      <ion-skeleton-text class="skeleton-text-animated"></ion-skeleton-text>
    </ion-thumbnail>

    <ion-label class="text-wrapper">
      <ion-text class="primary-text">
        <ion-skeleton-text class="skeleton-text-animated">
        </ion-skeleton-text>
      </ion-text>
      <ion-text class="secondary-text">
        <ion-skeleton-text class="skeleton-text-animated target-contact-skl" >
        </ion-skeleton-text>
      </ion-text>
    </ion-label>
  </ion-item>

  <div class="separationLine"></div>
</ng-template>

  <div class="searchSuggestionWrapper" *ngIf="suggestionsData && suggestionsData.length">
    <div class="filteredSearchSuggestion">
      <ion-item *ngFor="let value of suggestionsData" (click)="handleFacetSelection(value)">
        <ion-label class="contactName" [innerHTML]="value.text"></ion-label>
      </ion-item>
    </div>
  </div>