<ion-list>
  <ion-grid>
    <ion-row>
      <ng-container *ngFor="let pres of data">
        <div class="list-item-wrapper" *ngIf="listType == 'list'" (click)="handleMainItemClick($event, pres)">
          <ng-container [ngTemplateOutlet]="listRef" [ngTemplateOutletContext]="{data: pres}"></ng-container>
        </div>
        <div class="grid-item-wrapper" *ngIf="listType == 'grid'" (click)="handleMainItemClick($event, pres)">
          <ng-container [ngTemplateOutlet]="gridRef" [ngTemplateOutletContext]="{data: pres}"></ng-container>
        </div>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-list>