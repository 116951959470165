import { ApprovalReasonConfig } from './../../classes/approvals/approval-reasons-config.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { Injectable } from "@angular/core";
import { GPSActivityApprovalStatus, GPSActivityPhoto, Photo, PhotoResponse } from '@omni/classes/store-check/photo';
import { AppealDataService } from '@omni/data-services/appeal/appeal.data.service';
import _ from 'lodash';
import { ActivityService } from '../activity/activity.service';
import { StoreCheckDataService } from '@omni/data-services/store-check/store-check.data.service';
import { UploadedPhoto } from '@omni/classes/store-check/uploaded-photo';
import { AuthenticationService } from '../authentication.service';
import { NotificationService, ToastStyle } from '../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ApprovalStatus } from '@omni/classes/quotes/quotes.class';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppealService {
  
  constructor(private appealDataService: AppealDataService,
    private storeCheckDataService: StoreCheckDataService,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private activityService: ActivityService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService) {
  }

  public async uploadPhotosToBlobStorgae(payload: Photo[], gpsActivitiyPayload) {
    const activity = (this.activityService.selectedActivity) as AppointmentActivity;
    const createdAt = new Date().getTime();
    payload = payload.map((image) => {
      const base64String = image.base64String.replace(/^data:image\/\w+;base64,/, '');
      return { ...image, base64String, createdAt: createdAt };
    });
    try {
      const photosUploaded: UploadedPhoto[] = await this.storeCheckDataService.uploadPhotosToBlobStorage(payload);
      if (!_.isEmpty(photosUploaded) && !_.isEmpty(gpsActivitiyPayload) ) {
          photosUploaded.forEach((photoUploaded)=>{
            gpsActivitiyPayload[0]['photoAttachmentIds'].push(photoUploaded.photoId);
          })
          await this.appealDataService.updateGPSActivityPhotos(activity.ID, gpsActivitiyPayload);
          const photos: PhotoResponse[] = photosUploaded.map(p => {
            return { indskr_photoattachmentid: p.photoId, indskr_photoorigin: p.photoOrigin, indskr_photourl: p.photoUrl, name: p.name }
          });
          const gpsActivityPhoto: GPSActivityPhoto = {
            indskr_gpscheckindetailsid: gpsActivitiyPayload[0]['indskr_gpscheckindetailsid'],
            indskr_type: gpsActivitiyPayload[0]['indskr_type'],
            photoAttachments:photos
          }
          activity.gpsActivityPhotos.push(gpsActivityPhoto);
      }
    } catch (error) {
      console.error("Appeal - Failed to upload photos: ", error);
    }
  }

  public getDateFormatted(date) {
    return this.datePipe.transform(new Date(date), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  public getStatusFormattedValue(statuscode) {
    switch (statuscode) {
      case ApprovalStatus.PENDING: return this.translate.instant('PENDING');
      case ApprovalStatus.APPROVED: return this.translate.instant('APPROVED');
      case ApprovalStatus.NOT_APPROVED: return this.translate.instant('NOT_APPROVED');
      case ApprovalStatus.CANCELED: return this.translate.instant('CANCELLED');
    }
  }

  public getAppealStatusFormattedValue(statuscode) {
    switch (statuscode) {
      case GPSActivityApprovalStatus.DRAFT: return this.translate.instant('OM_DRAFT');
      case GPSActivityApprovalStatus.PENDING: return this.translate.instant('PENDING');
      case GPSActivityApprovalStatus.APPROVED: return this.translate.instant('APPROVED');
      case GPSActivityApprovalStatus.NOT_APPROVED: return this.translate.instant('NOT_APPROVED');
      case GPSActivityApprovalStatus.OUT_OF_SUBMISSION_WINDOW_PERIOD: return this.translate.instant('OUT_OF_SUBMISSION_WINDOW_PERIOD');
      case GPSActivityApprovalStatus.WHITE_LIST_AUTO_APPROVED: return this.translate.instant('WHITE_LIST_AUTO_APPROVED');
    }
  }

  public async updateAppealStatus(meetingId, payload) {
    return await this.appealDataService.updateAppealStatus(meetingId, payload);
  }
}
