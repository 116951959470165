import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { LoadingController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { InquirySignatureModalComponent } from '../inquiry-signature-modal/inquiry-signature-modal';
import { FooterViews } from '../../../services/footer/footer.service';
import { FooterService } from '../../../services/footer/footer.service';
import { EventsService } from '../../../services/events/events.service';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { AlertService } from '../../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { DeviceService } from '../../../services/device/device.service';
import { CaseManagementService, AccesingMode } from '../../../services/case-management/case-management.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { CaseManagementDataService } from '../../../data-services/case-management/case-management.data.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';

/**
 * Generated class for the CaptureCustomerInquireComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'capture-customer-inquire',
  templateUrl: 'capture-customer-inquire.html',
  styleUrls:['capture-customer-inquire.scss']
})
export class CaptureCustomerInquireComponent implements OnInit, OnDestroy {

  @Input() selectedCase: CaseActivity;
  offlineFAEnabled = false;
  globalCustomerText: any;
  capturedSignature: any;
  isAccessedFromMeetings = false;
  public pageTitle: string = "";
  public backButton = [{
    id: "close",
    icon: "chevron-back-outline",
    isDisabled: false,
    align: "left"
  }];
  public signatureHeaderModel: IndSectionHeaderViewDataModel;
  constructor(
    public popoverCtrl: PopoverController,
    public readonly footerService: FooterService,
    private readonly events: EventsService,
    public params: NavParams,
    private readonly alertService: AlertService,
    public translate: TranslateService,
    private readonly authenticationService: AuthenticationService,
    public device: DeviceService,
    public caseManagementService: CaseManagementService,
    public notificationService: NotificationService,
    public utilityService: GlobalUtilityService,
    private readonly loadingController: LoadingController,
    public caseManagementDataService: CaseManagementDataService,
    public modalCtrl: ModalController,
    private navService: NavigationService
    )
    {
    this.events.subscribe('Inquiryconsent:footerEvents', (data) => {
      this.modalCtrl.dismiss(null, null);
    });
    if(this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION){
      this.isAccessedFromMeetings = true;
    } else{
      this.footerService.initButtons(FooterViews.INQUIRY_CONSENT);
    }

    if (!this.selectedCase) {
       this.selectedCase = params.data.inquiryRef as CaseActivity;
       if (!this.selectedCase) {
        this.selectedCase = params.data as CaseActivity;
       }
    }
  }

  ngOnInit() {
    if (this.selectedCase && this.selectedCase.raw) {
      this.capturedSignature = this.selectedCase.raw.indskr_signature ? this.selectedCase.raw.indskr_signature : this.selectedCase.signature;
    } else {
      this.capturedSignature = this.selectedCase.signature;
    }
    this.pageTitle = this.translate.instant("INQUIRY_ACKNOWLEDGEMENT");
    this.signatureHeaderModel = {
      id:'details-header',
      title: this.translate.instant('SIGNATURE_CAP'),
      controls: []
    }
  }

  async goBack(event){
    if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
      await this.navService.popChildNavPageWithPageTracking();
    } else {
      this.modalCtrl.dismiss(null, null);
    }
    if(this.selectedCase._case_type.surveyTemplate) this.events.publish('reloadSurveyTemplate');
    this.isAccessedFromMeetings = false;
  }

submitInquiry(){
    this.alertService.showAlert({
      title: this.translate.instant('SUBMIT_INQUIRY'),
      message: this.translate.instant('SUBMIT_INQUIRY_CONFIRMATION')}, this.translate.instant('SUBMIT')
    ).then (res => {
      if(res.role ==='ok') {
        this.submit();
      }
    });
  }

 async submit(){
    if (
      this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_INQUIRY_OFFLINE)
    ) {
      this.offlineFAEnabled = true;
    }
    if (this.device.isOffline) {
      if (this.offlineFAEnabled) {
        //Generate case in offline mode

        const res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
        this.caseManagementService.assignSelectedCase(res);
        this.selectedCase = res;
        this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
        if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
          this.events.publish('updateEmbeddedActivity', this.selectedCase);
      } else {
        this.notificationService.notify(
          this.translate.instant('CASE_CU_INQ_SUB_ALLOW_ONLY_ONLINE_WITH_TEXT', { text: this.globalCustomerText }),
         'Customer Inquiry',
         'top',
          ToastStyle.DANGER
        );
      }
    }
    else {
      const loader = await this.loadingController.create();
      loader.present();
      await this.caseManagementDataService.createCaseOnline(this.selectedCase).then(
        async value => {
          if (!value.hasOwnProperty('error')) {
            await this.caseManagementService.addNewCase(value, this.selectedCase).then(
              async res => {
                this.caseManagementService.assignSelectedCase(res);
                this.selectedCase = res;
                this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
                if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
                  this.events.publish('updateEmbeddedActivity', this.selectedCase);
                if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
                  await this.navService.popChildNavPageWithPageTracking();
                } else {
                  this.modalCtrl.dismiss(null, null);
                }
                loader.dismiss();
              },
              async err => {
                if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
                  await this.navService.popChildNavPageWithPageTracking();
                } else {
                  this.modalCtrl.dismiss(null, null);
                }
                loader.dismiss();
              }
            );
          }
          else {
            if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
              await this.navService.popChildNavPageWithPageTracking();
            } else {
              this.modalCtrl.dismiss(null, null);
            }
            loader.dismiss();
            const res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
            this.caseManagementService.assignSelectedCase(res);
            this.selectedCase = res;
            this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
            if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
              this.events.publish('updateEmbeddedActivity', this.selectedCase);
          }
        }
      ).catch(
        async err => {
          if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
            await this.navService.popChildNavPageWithPageTracking();
          } else {
            this.modalCtrl.dismiss(null, null);
          }
          loader.dismiss();
          const res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
          this.caseManagementService.assignSelectedCase(res);
          this.selectedCase = res;
          this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
          if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
            this.events.publish('updateEmbeddedActivity', this.selectedCase);
        });
    }
  }


  async openSignaturePad(){
    const popover = await this.popoverCtrl.create({
                      component: InquirySignatureModalComponent,
                      componentProps: {selectedCase : this.selectedCase},
                      cssClass: 'signature-pad',
                      backdropDismiss:false});
    popover.present();
    popover.onDidDismiss().then(async (data:any)=>{
      if (data.data && data.data.submit){
        if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
          await this.navService.popChildNavPageWithPageTracking();
        } else {
          this.modalCtrl.dismiss(null, null);
        }
      }
      else if(data && !data.submit){
        // this.showTapToSubmit = true;
      }
    })
  }

  ngOnDestroy(){
    this.events.unsubscribe('Inquiryconsent:footerEvents');
  }

}
