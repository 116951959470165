<ion-header>
  <ind-page-title [viewData]="surveyDetailsPageTitle" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-page-title>
  <ion-toolbar no-padding *ngIf="!isHideTabs">
    <ind-tabs [data]="tabsData" [(selectedTab)]="selectedTab" (selectedTabChange)="segmentChanged($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content no-padding>
  <div [ngSwitch]="selectedTab" class="switchConditionContainer" [ngClass]="{'margin-bottom-above-fab': !isHideTabs && footerService.shouldApplyBottomMargin()}">
    <survey-preview #surveyForm *ngSwitchCase="'preview'" (isFormDirty)="isSurveyFormDirty($event)" (isValidToSubmit)="isValidToSubmit($event)"
      (savedData)="savedSurveyForm($event)" (submittedData)="submittedSurveyForm($event)">
    </survey-preview>
    <survey-history *ngSwitchCase="'activities'"></survey-history>
  </div>
</ion-content>

<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('CustomerSurvey')" [footerView]="'detailView'" [selectedView]="'surveyDetails'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>