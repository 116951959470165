import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController,  NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { CustomerInterest } from '../../../../classes/xperiences/trending.customer.class';
import { PopoverComponent } from '../../../../components/popover/popover';
import { RepServices } from '../../../../data-services/rep/rep.services';
import { IndPageTitleViewDataModel } from '../../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../../models/indSectionHeaderDataModel';
import { SelectListData } from '../../../../models/select-list-data-model';
import { AlertService } from '../../../../services/alert/alert.service';
import { DeviceService } from '../../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../../services/navigation/navigation.service';
import { UIService } from '../../../../services/ui/ui.service';
import { XperiencesService } from '../../../../services/xperiences/xperiences.service';
import { OptionSelectionMode, PopoverMode } from '../../../../utility/util';
import { SelectListComponent } from '../../../shared/select-list/select-list';
import { ActivityService } from '@omni/services/activity/activity.service';


/**
 * Generated class for the CustomerInterestComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'customer-interest',
  templateUrl: 'customer-interest.html',
  styleUrls:['customer-interest.scss']
})
export class CustomerInterestComponent implements OnInit, OnDestroy {

  public interest: CustomerInterest;
  public tempInterest: CustomerInterest;
  private createMode = false;
  private readonly popoverMode = PopoverMode;
  readOnly = false;
  public insightsDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public insightsDetailPageHeaderModel: IndPageTitleViewDataModel;
  private isClickedInterestDesc: boolean = false;

  constructor(
    public repService: RepServices,
    private readonly trackingService: TrackService,
    private readonly xpService: XperiencesService,
    private readonly navService: NavigationService,
    public readonly footerService: FooterService,
    private readonly uiService: UIService,
    private readonly navParams: NavParams,
    private readonly events: Events,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly modalCtrl: ModalController,
    private readonly popoverCtrl: PopoverController,
    private readonly deviceService: DeviceService,
    private readonly activityService: ActivityService,
    ) {
  }

  ngOnInit() {
    this.interest = (this.navParams.data && this.navParams.data.interest);
    this.readOnly = (this.navParams.data && this.navParams.data.readOnly);
    if (!this.interest) {
      this.interest = new CustomerInterest();
      if (!this.interest.score) this.interest.score = undefined;
      this.createMode = true;
    }
    this.footerService.initButtons(FooterViews.Contacts);
    this.events.subscribe(OptionSelectionMode.SELECT_INTEREST_CATEGORY.toString(), (res) => {
      this.interest.selectedCategory = res.selected;
    });
    this.tempInterest = cloneDeep(this.interest);
    if (!this.tempInterest.score) this.tempInterest.score = undefined;

    this.initInterestDetailsPageTitle();

    this.intializeInterestsHeder();
  }

  private initInterestDetailsPageTitle(): void {
    let buttons = [];
    if (this.readOnly) {
      buttons.push({
        id: 'cancel',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    } else {
      buttons.push({
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: false,
        align: 'right'
      }, {
        id: 'done',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: !this.interest.description,
        align: 'right'
      })
    }
    this.insightsDetailPageHeaderModel = {
      id: 'customer-insight',
      title: `${this.translate.instant('CONTACT_INTEREST_INSIGHTS')} +`,
      controls: buttons
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.closepage(false);
        break;
      case 'done':
        this.closepage(true);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private intializeInterestsHeder() {
    this.insightsDetailsHeaderModel = {
      id: 'insights-interests-header',
      title: this.translate.instant('XPERIENCES_INTEREST_DETAILS'),
      controls: [],
    };
  }

public get interestDescriptionFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.interest.description,
      inputValue: this.interest.description,
      customPlaceholderLabel:this.translate.instant('ENTER_DESCRIPTION'),
      id: 'interests-description-field',
      isReadOnly: this.readOnly,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !this.readOnly,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedInterestDesc && _.isEmpty(this.interest.description),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public get interestCategoryFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CATEGORY'),
      inputText: !this.interest.selectedCategory ? '' : this.interest.selectedCategory.title ,
      customPlaceholderLabel: this.readOnly && (!this.interest.selectedCategory || _.isEmpty(this.interest.selectedCategory.title)) ? this.translate.instant('XPERIENCES_RELATIONSHIP_NO_CATEGORY') : this.translate.instant('SELECT_CATEGORY'),
      id: 'interests-category-field',
      isReadOnly: true,
      isDisabled: this.readOnly,
      showArrow: !this.readOnly,
      formFieldType: this.readOnly ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'interests-description-field':
          if (eventName && eventName == 'input_value_change') {
            this.openDescriptionPopover(event);
          } else if(eventName && eventName == 'input_value_confirm') {
            this.isClickedInterestDesc = true;
          }
          break;
        case 'interests-category-field':
          this.selectInterestCategory();
          break;
        case 'other-sources-field':
          this.openSourcesPopover(event);
          break;
        default: break;
      }
    }
  }

  async openDescriptionPopover(myEvent) {
      if (this.readOnly) return;
      this.isClickedInterestDesc = true;
      this.interest.description =  (myEvent && myEvent.target.value && myEvent.target.value.length !== 0) ? myEvent.target.value : '';
      this.initInterestDetailsPageTitle();
  }

  public async selectInterestCategory() {
    const categories: SelectListData[] = cloneDeep(this.xpService.interestCategories);
    const selected = this.interest.selectedCategory;
    const options: SelectListData[] = categories.map(category => {
      return { ...category, isSelected: selected && selected.id === category.id };
    });
    this.footerService.forceHideMasterFooter = true;
    const categoryModal =await this.modalCtrl.create({component: SelectListComponent,
      componentProps:{
        viewData: {
          data: options,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('XPERINECES_ALL_CATEGORY'),
          dividerTitle: this.translate.instant('XPERINECES_ALL_CATEGORY'),
          isSearchEnabled: true
        }
      },
      backdropDismiss: false
    });
    categoryModal.present();
    categoryModal.onDidDismiss().then(async (data: any) => {
      this.footerService.forceHideMasterFooter = false;
      data = data.data;
      if (data && data.isDone) {
        if (data.selectedItems && data.selectedItems.length) {
          this.interest.selectedCategory = data.selectedItems[0];
        } else {
          this.interest.selectedCategory = null;
        }
      }
    });
  }

    updateInterestsConfidence(event) {
    this.interest.score = event;
  }

  public closepage(add: boolean) {
    if (add) {
      if (this.interest.description) {
        if (this.createMode) {
          this.xpService.newInsight.interests.push(this.interest);
          this.events.publish('insights-interests-created');
          this.trackingService.tracking('XperiencesInsgihts+InterestDone', TrackingEventNames.INSIGHTSPLUS);
        }
      } else {
        return;
      }
      this.navService.popChildNavPageWithPageTracking();
    } else {
      if (!_.isEqual(this.interest, this.tempInterest) && !this.readOnly) {
          this.discardChangesPopup();
      }
      else {
        if (this.tempInterest) this.interest = this.tempInterest;
        this.navService.popChildNavPageWithPageTracking();
      }
    }
  }

  discardChangesPopup() {

    this.alertService.showAlert({
      title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
      message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')}, this.translate.instant('DISCARD')
    ).then (res => {
      if(res.role === 'ok') {
        if (this.tempInterest) this.interest = this.tempInterest;
        this.navService.popChildNavPageWithPageTracking();
      }
    });
  }

  async openSourcesPopover(event) {
    if(this.interest.sources.length > 1) {
      const popover = await this.popoverCtrl.create({component:PopoverComponent,componentProps: { field: 'XperienceInsightsPlusSources', insightsPlusSources: this.interest.sources },cssClass: 'insights-plus-sources-popover', event});
      popover.present();
    }
  }

  public get otherSourcesFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('XPERIENCES_OTHER_SOURCES'),
      inputText: this.interest.sourcesText,
      id: 'other-sources-field',
      isReadOnly: false,
      isDisabled: false,
      showArrow: !this.readOnly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isDateTimeField: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  ngOnDestroy() {
    this.events.unsubscribe(OptionSelectionMode.SELECT_INTEREST_CATEGORY.toString());
  }
}
