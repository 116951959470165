<ion-header>
    <ind-page-title [viewData]="title" (onControlClick)='onSectionHeaderControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ind-section-header [viewData]='headerData'></ind-section-header>
    <ion-list>
        <ion-item (click)="selectInsightsType(insightType.InisghtsPlus)">
            {{'XPERIENCES_CUSTOMER_INSIGHTS_PLUS' | translate: { globalCustomerText: translatedCutomerText } }}
            <ion-icon name="checkmark" color="primary" *ngIf="selectedInsightType === insightType.InisghtsPlus" slot="end"></ion-icon>
        </ion-item>
        <ion-item (click)="selectInsightsType(insightType.GeneralInsightsPlus)">
            {{ 'XPERIENCES_GENERAL_INSIGHTS_PLUS' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="selectedInsightType === insightType.GeneralInsightsPlus" slot="end"></ion-icon>
        </ion-item>
    </ion-list>
</ion-content>

<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'" [fromWhichView]="'Xperience'"></footer-toolbar>
<!-- </ion-footer> -->