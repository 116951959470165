import { PageName } from './../../../services/navigation/navigation.service';
import { NotificationService } from './../../../services/notification/notification.service';
import { DeviceService } from './../../../services/device/device.service';
import { UIService } from './../../../services/ui/ui.service';
import { DynamicsClientService } from './../../../data-services/dynamics-client/dynamics-client.service';
import { AlertService } from './../../../services/alert/alert.service';
import { PopoverController } from '@ionic/angular';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { format, getDate } from "date-fns";
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { AddCustomerAvailabilityComponent } from '../add-customer-availability/add-customer-availability.component';
import _ from 'lodash';

@Component({
  selector: 'app-customer-availability',
  templateUrl: './customer-availability.component.html',
  styleUrls: ['./customer-availability.component.scss'],
})
export class CustomerAvailabilityComponent implements OnInit {
  @Input() data: {dayValue:any, day:string,data:any[],modifiedBy:string,modifiedOn:any}[];
  @Input() address: string;
  @Input() contactName: string;
  @Input() customerAddressId: string;
  public titleBar: IndPageTitleViewDataModel;
  public contactAddressHeaderModel: IndSectionHeaderViewDataModel;
  public timeSlots = [
    {Value: "0", Label: "12:00 AM"},
    {Value: "30", Label: "12:30 AM"},
    {Value: "100", Label: "1:00 AM"},
    {Value: "130", Label: "1:30 AM"},
    {Value: "200", Label: "2:00 AM"},
    {Value: "230", Label: "2:30 AM"},
    {Value: "300", Label: "3:00 AM"},
    {Value: "330", Label: "3:30 AM"},
    {Value: "400", Label: "4:00 AM"},
    {Value: "430", Label: "4:30 AM"},
    {Value: "500", Label: "5:00 AM"},
    {Value: "530", Label: "5:30 AM"},
    {Value: "600", Label: "6:00 AM"},
    {Value: "630", Label: "6:30 AM"},
    {Value: "700", Label: "7:00 AM"},
    {Value: "730", Label: "7:30 AM"},
    {Value: "800", Label: "8:00 AM"},
    {Value: "830", Label: "8:30 AM"},
    {Value: "900", Label: "9:00 AM"},
    {Value: "930", Label: "9:30 AM"},
    {Value: "1000", Label: "10:00 AM"},
    {Value: "1030", Label: "10:30 AM"},
    {Value: "1100", Label: "11:00 AM"},
    {Value: "1130", Label: "11:30 AM"},
    {Value: "1200", Label: "12:00 PM"},
    {Value: "1230", Label: "12:30 PM"},
    {Value: "1300", Label: "1:00 PM"},
    {Value: "1330", Label: "1:30 PM"},
    {Value: "1400", Label: "2:00 PM"},
    {Value: "1430", Label: "2:30 PM"},
    {Value: "1500", Label: "3:00 PM"},
    {Value: "1530", Label: "3:30 PM"},
    {Value: "1600", Label: "4:00 PM"},
    {Value: "1630", Label: "4:30 PM"},
    {Value: "1700", Label: "5:00 PM"},
    {Value: "1730", Label: "5:30 PM"},
    {Value: "1800", Label: "6:00 PM"},
    {Value: "1830", Label: "6:30 PM"},
    {Value: "1900", Label: "7:00 PM"},
    {Value: "1930", Label: "7:30 PM"},
    {Value: "2000", Label: "8:00 PM"},
    {Value: "2030", Label: "8:30 PM"},
    {Value: "2100", Label: "9:00 PM"},
    {Value: "2130", Label: "9:30 PM"},
    {Value: "2200", Label: "10:00 PM"},
    {Value: "2230", Label: "10:30 PM"},
    {Value: "2300", Label: "11:00 PM"},
    {Value: "2330", Label: "11:30 PM"}
  ]
  public daysOfWeek = [
    {Value: 100000000, Label: this.translate.instant("SUNDAY"), Order: 1},
    {Value: 100000001, Label: this.translate.instant("MONDAY"), Order: 2},
    {Value: 100000002, Label: this.translate.instant("TUESDAY"), Order: 3},
    {Value: 100000003, Label: this.translate.instant("WEDNESDAY"), Order: 4},
    {Value: 100000004, Label: this.translate.instant("THURSDAY"), Order: 5},
    {Value: 100000005, Label: this.translate.instant("FRIDAY"), Order: 6},
    {Value: 100000006, Label: this.translate.instant("SATURDAY"), Order: 7}
  ]
  constructor(
    public navService: NavigationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    public alertService: AlertService,
    public dynamics: DynamicsClientService,
    public uiService: UIService,
    private readonly device: DeviceService,
    private readonly notification: NotificationService
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.daysOfWeek.forEach((day)=>{
      if(!this.data.some(o=>o['dayValue']==day.Value)){
        this.data.splice(day.Order-1, 0, {
          dayValue:day.Value,
          day:day.Label,
          data:[],
          modifiedBy:'',
          modifiedOn:''
        })
      }
    })
    this.getPageTitle();
    this.initContactAddressHeaderModel();
  }
  public getPageTitle() {
    let buttons = [];
      buttons = [{
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      }];
    this.titleBar = {
      id: "id",
      //title: this.translate.instant('PRESENTATION') + ' ' + this.translate.instant('DETAILS'),
      title: this.contactName + '-' + this.translate.instant('CUSTOMER_AVAILABILITY'),
      controls: buttons
    }
  }

  public initContactAddressHeaderModel(){
    this.contactAddressHeaderModel = {
      id: 'selectededitems',
      title: this.address,
      controls: []
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      default:
        break;
    }
  }
  closePage(){
    this.navService.popChildNavPageWithPageTracking();
  }
  formattedDate(val){
    return format(val, this.dateTimeFormatsService.dateToUpper)
  }
  async addNewData(dayRecord){
    if(this.device.isOffline){
      this.notification.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'),'CustomerAvailabilityComponent')
      return;
    }
    const modal = await this.modalCtrl.create({
      component: AddCustomerAvailabilityComponent,
      componentProps: {slotsData: this.timeSlots, dayRecord},
      cssClass: 'customer-availability-modal'
    });
    await modal.present().then(() => {});
    modal.onDidDismiss().then((res)=>{
      if(res && res.data && res.data.newDataAdded && res.data.changes && res.data.changes.length){
        this.uiService.displayLoader();
        let changes = res.data.changes;
        let tasks = [];
        let dayOfWeek = res.data.dayOfWeek
        changes.forEach(newRecord => {
          let payload = {
            indskr_dayofweek: dayOfWeek.dayValue,
            indskr_from:newRecord['startTimeValue'],
            indskr_to: newRecord['endTimeValue'],
            'indskr_CustomerAddressId@odata.bind': `/indskr_indskr_customeraddress_v2s(${this.customerAddressId})`
          }
          console.log(payload);
          tasks.push(this.dynamics.create(payload,'indskr_customeravailability_v2s').then((response)=>{
            let day = this.data.find(d=>d.day == dayRecord.day)
            day.data.push({
              indskr_from:response['indskr_from'],
              indskr_from_Formatted: response['indskr_from@OData.Community.Display.V1.FormattedValue'],
              indskr_to:response['indskr_to'],
              indskr_to_Formatted:  response['indskr_to@OData.Community.Display.V1.FormattedValue'],
              indskr_customeravailability_v2id: response['indskr_customeravailability_v2id'],
              _indskr_customeraddressid_value: response['_indskr_customeraddressid_value'],
              indskr_dayofweek:response['indskr_dayofweek@OData.Community.Display.V1.FormattedValue'],
              _modifiedby_value:response['_modifiedby_value@OData.Community.Display.V1.FormattedValue'],
              modifiedon: response['modifiedon'],
              indskr_dayorder:response['indskr_dayorder']
            })
            day.modifiedOn = response['modifiedon'];
            day.modifiedBy = response['_modifiedby_value@OData.Community.Display.V1.FormattedValue']
            day.data = _.sortBy(day.data,['indskr_from']);
          }));
        });
        Promise.all(tasks).then(()=>{
          this.uiService.dismissLoader();
        }).catch(()=>{
          this.uiService.dismissLoader();
        })
      }
    })
  }

  deleteRecord(timeRecord, dayRecord){
    if(this.device.isOffline){
      this.notification.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'),'CustomerAvailabilityComponent')
      return;
    }
    this.alertService.showAlert({
      title: this.translate.instant('REMOVE_TIMESLOT'),
      message: this.translate.instant("MEETING_STRUCTURE_REMOVE_CONTENT_MESSAGE").replace("{{0}}", timeRecord.indskr_from_Formatted +" - "+ timeRecord.indskr_to_Formatted)
    },
      this.translate.instant('REMOVE')
    ).then(res => {
      if (res.role == "ok") {
        this.dynamics.delete(timeRecord['indskr_customeravailability_v2id'],'indskr_customeravailability_v2s');
        _.remove(dayRecord.data, (o)=> o == timeRecord)
      }
    });
  }

}
