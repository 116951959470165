export const defaultAccountCreateForm = {
    value: [
        {
            createdon: "2020-04-09T13:53:41Z",
            importsequencenumber: null,
            indskr_entityid: null,
            indskr_entityname: "account",
            indskr_formid: "iO - App Form (e819476b-54bf-412a-8b77-d0b6179cf4eb)",
            indskr_formtype: 548910000,
            indskr_metadata: {
                EntityLogicalName: "account",
                EntitySetName: "accounts",
                SchemaName: "Account",
                Tabs: [
                    {
                        Controls: [
                            {
                                AttributeName: "name",
                                DataType: "StringType",
                                DateFormat: null,
                                Descriptions: [
                                    {
                                        Description: "ENTER_ACCOUNT_NAME",
                                        LanguageCode: "0000"
                                    }
                                ],
                                DisplayNames: [
                                    {
                                        Description: "ACCOUNT_NAME",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: false,
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: true,
                                SchemaName: "name",
                                Subgrid: null,
                                Visible: true,
                            },
                            {
                                AttributeName: "indskr_accounttype",
                                DataType: "PicklistType",
                                DateFormat: null,
                                Descriptions: [
                                    {
                                        Description: "SELECT_TYPE",
                                        LanguageCode: "0000"
                                    }
                                ],
                                DisplayNames: [
                                    {
                                        Description: "TYPE",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: false,
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: "indskr_accounttype",
                                Subgrid: null,
                                Visible: true,
                            },
                            {
                                AttributeName: "parentaccountid",
                                DataType: "LookupType",
                                DateFormat: null,
                                Descriptions: [
                                    {
                                        Description: "SELECT_PARENT_ACCOUNT",
                                        LanguageCode: "0000"
                                    },
                                ],
                                DisplayNames: [
                                    {
                                        Description: "ACCOUNT_PARENT_ACCOUNT",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: false,
                                LookupEntityPrimaryIdAttribute: "accountid",
                                LookupEntityPrimaryNameAttribute: "name",
                                LookupEntitySetName: "accounts",
                                LookupQueries: [
                                    {
                                        EntityName: "account",
                                        FetchXml: `<fetch returntotalrecordcount='true' count="{0}" version="1.0" output-format="xml-platform" mapping="logical"><entity name="account"><attribute name="name" /><attribute name="address1_city" /><order attribute="name" descending="false" /><attribute name="telephone1" /><filter type="or"><condition attribute="name" operator="like" value="{0}" /></filter><attribute name="accountid" /></entity></fetch>`,
                                        ObjectTypeCode: 1
                                    }
                                ],
                                LookupTargets: [
                                    "account"
                                ],
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: "ParentAccountId",
                                Subgrid: null,
                                Visible: true,
                            },
                            {
                              AttributeName: "indskr_accountsourcetype",
                              DataType: "PicklistType",
                              DateBehavior: null,
                              DateFormat: null,
                              Descriptions: [
                                {
                                  Description: "ACCOUNT_SOURCE_TYPE",
                                  LanguageCode: "0000"
                                }
                              ],
                              DisplayNames: [
                                {
                                  Description: "ACCOUNT_SOURCE_TYPE",
                                  LanguageCode: "0000"
                                }
                              ],
                              Format: null,
                              IsCustomAttribute: true,
                              IsList: false,
                              LookupEntityPrimaryIdAttribute: null,
                              LookupEntityPrimaryNameAttribute: null,
                              LookupEntitySetName: null,
                              LookupQueries: [],
                              LookupTargets: null,
                              MaxLength: 0,
                              MaxValue: null,
                              MinValue: null,
                              OptionSet: {
                                Options: []
                              },
                              Precision: 0,
                              ReadOnly: true,
                              Required: false,
                              SchemaName: "indskr_AccountSourceType",
                              Subgrid: null,
                              Visible: true
                            },
                            {
                                AttributeName: "Addresses",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "ADDRESSES",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_customer",
                                    ReferencingEntity: "indskr_indskr_customeraddress_v2",
                                    ReferencingEntityNavigationPropertyName: "indskr_Customer_account",
                                    RelationshipName: "indskr_account_indskr_indskr_customeraddress_v2_Customer",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                            {
                                                Attribute: "indskr_line1",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "Street 1",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                            {
                                                Attribute: "indskr_country_lu",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "COUNTRY",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                            {
                                                Attribute: "indskr_state_lu",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "STATE",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                            {
                                                Attribute: "indskr_city_lu",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "CITY",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                            {
                                                Attribute: "indskr_postalcode_lu",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "ZIP_POSTAL_CODES",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    SubgridQuery: `<fetch mapping="logical" output-format="xml-platform" version="1.0">
                                                                <entity name="indskr_indskr_customeraddress_v2">
                                                                    <order descending="false" attribute="indskr_address" />
                                                                    <attribute name="indskr_address" />
                                                                    <link-entity name="indskr_address" visible="false" link-type="outer" to="indskr_address" from="indskr_addressid" alias="a_814e681c5c3de811813d480fcfeae051">
                                                                    <attribute name="indskr_line3" />
                                                                    <attribute name="indskr_line2" />
                                                                    <attribute name="indskr_line1" />
                                                                    <attribute name="indskr_state_lu" />
                                                                    <attribute name="indskr_postalcode_lu" />
                                                                    <attribute name="indskr_postofficebox" />
                                                                    <attribute name="indskr_telephone2" />
                                                                    <attribute name="indskr_telephone1" />
                                                                    <attribute name="indskr_longitude" />
                                                                    <attribute name="indskr_latitude" />
                                                                    <attribute name="indskr_isverified" />
                                                                    <attribute name="indskr_secondaryfax" />
                                                                    <attribute name="indskr_fax" />
                                                                    <attribute name="indskr_country_lu" />
                                                                    <attribute name="indskr_city_lu" />
                                                                    <attribute name="indskr_subbrick" />
                                                                    <attribute name="indskr_district" />
                                                                    <attribute name="indskr_timezone" />
                                                                    </link-entity>
                                                                    <attribute name="indskr_samplingeligible" />
                                                                    <attribute name="indskr_isprimary" />
                                                                    <attribute name="indskr_indskr_customeraddress_v2id" />
                                                                    <filter>
                                                                    <condition attribute="indskr_customer" operator="eq" value="{0}" />
                                                                    </filter>
                                                                </entity>
                                                                </fetch>`,
                                    TargetEntityType: "indskr_indskr_customeraddress_v2",
                                    ViewId: "{8AE5AF13-6F8E-E811-816B-480FCFF40721}"
                                },
                                Visible: true,
                            },
                            {
                                AttributeName: "ioAffiliatedContact",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "CONTACT_AFFILIATION",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_accountid",
                                    ReferencingEntity: "indskr_accountcontactaffiliation",
                                    ReferencingEntityNavigationPropertyName: "indskr_accountid",
                                    RelationshipName: "indskr_account_accountcontactaffiliation_account",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                            {
                                                Attribute: "indskr_contactid",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "CONTACT",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                            {
                                                Attribute: "omnione_role",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "AFFILIATION_ROLE",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    SubgridQuery: `<fetch version="1.0" mapping="logical">
                                            <entity name="indskr_accountcontactaffiliation">
                                                <attribute name="createdon" />
                                                <order attribute="indskr_contactid" descending="false" />
                                                <filter type="and">
                                                <condition attribute="statecode" operator="eq" value="0" />
                                                </filter>
                                                <attribute name="statecode" />
                                                <attribute name="indskr_contactid" />
                                                <attribute name="omnione_role" />
                                                <attribute name="createdby" />
                                                <attribute name="indskr_accountcontactaffiliationid" />
                                                <filter>
                                                <condition attribute="indskr_accountid" operator="eq" value="{0}" />
                                                </filter>
                                            </entity>
                                            </fetch>`,
                                    TargetEntityType: "indskr_accountcontactaffiliation",
                                    ViewId: "{4CF09727-6219-E711-8112-480FCFEBC391}"
                                },
                                Visible: true,
                            },
                            {
                                AttributeName: "ioAccountAccount",
                                DataType: null,
                                DateFormat: null,
                                Descriptions: [],
                                DisplayNames: [
                                    {
                                        Description: "ACCOUNT_RELATED_ACCOUNTS",
                                        LanguageCode: "0000"
                                    }
                                ],
                                IsList: true,
                                LookupEntityPrimaryIdAttribute: null,
                                LookupEntityPrimaryNameAttribute: null,
                                LookupEntitySetName: null,
                                LookupQueries: [],
                                LookupTargets: null,
                                MaxLength: 0,
                                MaxValue: null,
                                MinValue: null,
                                OptionSet: {
                                    Options: []
                                },
                                Precision: 0,
                                ReadOnly: false,
                                Required: false,
                                SchemaName: null,
                                Subgrid: {
                                    ParentAttribute: "accountid",
                                    ParentEntity: "account",
                                    ReferencingAttribute: "indskr_affiliatedfromaccountid",
                                    ReferencingEntity: "indskr_accountaccountaffiliation",
                                    ReferencingEntityNavigationPropertyName: "indskr_affiliatedfromaccountid",
                                    RelationshipName: "indskr_account_accountaccountaffiliation_affiliatedfromaccountid",
                                    RelationshipType: 0,
                                    SubgridLayout: {
                                        Attributes: [
                                            {
                                                Attribute: "indskr_affiliatedtoaccountid",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "ACCOUNT",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                            {
                                                Attribute: "omnione_relationship",
                                                Descriptions: [],
                                                DisplayNames: [
                                                    {
                                                        Description: "SELECT_AFFILIATION_TYPE",
                                                        LanguageCode: "0000"
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    SubgridQuery: `<fetch version="1.0" output-format="xml-platform" mapping="logical">
                                            <entity name="indskr_accountaccountaffiliation">
                                                <order attribute="indskr_affiliatedtoaccountid" descending="false" />
                                                <attribute name="omnione_relationship" />
                                                <attribute name="statecode" />
                                                <attribute name="indskr_startdate" />
                                                <attribute name="indskr_enddate" />
                                                <attribute name="indskr_description" />
                                                <attribute name="createdon" />
                                                <attribute name="createdby" />
                                                <attribute name="indskr_affiliatedtoaccountid" />
                                                <attribute name="modifiedon" />
                                                <attribute name="modifiedby" />
                                                <attribute name="indskr_accountaccountaffiliationid" />
                                                <filter>
                                                <condition attribute="indskr_affiliatedfromaccountid" operator="eq" value="{0}" />
                                                </filter>
                                            </entity>
                                            </fetch>`,
                                    TargetEntityType: "indskr_accountaccountaffiliation",
                                    ViewId: "{71184A8B-409B-E811-8155-480FCFF4F6A1}"
                                },
                                Visible: true,
                            },
                        ],
                        DisplayNames: [
                            {
                                Description: "OVERVIEW_CAP",
                                LanguageCode: "0000"
                            }
                        ],
                    }
                ]
            },
            indskr_mobileappformid: "206cbc84-697a-ea11-a812-000d3a1ab78a",
            indskr_name: "Account - Create",
            indskr_validate: false,
            modifiedon: "2020-05-01T14:39:14Z",
            overriddencreatedon: null,
            statecode: 0,
            statuscode: 1,
        }
    ]
}