import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { IONote } from '@omni/classes/io/io-note.class';
import { Presentation } from '@omni/classes/presentation/presentation.class';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { differenceInHours, isBefore, isEqual, isValid } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { Guid } from 'typescript-guid';
import { Endpoints } from '../../../config/endpoints.config';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '../../classes/activity/follow-up-action.activity.class';
import { Resource } from '../../classes/resource/resource.class';
import { fetchQueries } from '../../config/dynamics-fetchQueries';
import { DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from '../../config/pouch-db.config';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { ActivityService } from '../../services/activity/activity.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../../services/disk/disk.service';
import { NotificationService } from '../../services/notification/notification.service';
import { Utility } from '../../utility/util';
import { DeltaService } from '../delta/delta.service';
import { FollowUpActivityDataService } from '../follow-up-activity/follow-up-activity.data.service';
import { AccountPlan, AccountPlanObjective, AccountPlanObjectiveReport, AccountPlanReportState, CreateProgressReportRequestBody, Expense } from './../../classes/account-management/account-plan.class';
import { FeatureActionsMap } from './../../classes/authentication/user.class';
import { AccountManagementOfflineService } from './../../services/account-management/account-management.service';
import { AuthenticationService } from './../../services/authentication.service';
import { DynamicsClientService } from './../dynamics-client/dynamics-client.service';

@Injectable({
  providedIn: 'root'
})
export class AccountManagementDataService {
  constructor(
    public dynamics: DynamicsClientService,
    public authService: AuthenticationService,
    public accountmanagementOfflineService: AccountManagementOfflineService,
    public opportunityService: OpportunityManagementService,
    private activityService: ActivityService,
    private followupDataService: FollowUpActivityDataService,
    public disk: DiskService,
    public http: HttpClient,
    public notifyService: NotificationService,
    public translate: TranslateService,
    private deltaService: DeltaService,
    private readonly eventsToolService: EventsToolService,
  ) {

  }
  async loadOfflineDataForAccountPlans() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) {
      try {
        await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLANS, true).then((doc) => {
          if (doc && doc.raw) {
            this.accountmanagementOfflineService.accountPlans = doc.raw;
            this.accountmanagementOfflineService.currencySymbol = doc.currencySymbol;
          }
          else {
            this.accountmanagementOfflineService.accountPlans = [];
          }
        })
        await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT).then(doc => {
          if (doc) {
            this.accountmanagementOfflineService.allProgressReports = doc.raw;
          }
          else this.accountmanagementOfflineService.allProgressReports = [];
        }).catch(err => {
          this.accountmanagementOfflineService.allProgressReports = [];
        });
      }
      catch (error) {
        this.accountmanagementOfflineService.accountPlans = [];
        this.accountmanagementOfflineService.allProgressReports = [];
      }
    }
  }

  async getAccountPlans(fullSync?: boolean, accountPlanID?: string, loadFromDbOnly = false) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) {
      this.deltaService.pushSyncEntityName(SyncFeatureCategory.plans);
      if (loadFromDbOnly) {
        await this.loadOfflineDataForAccountPlans();
      } else {
        try {
          let offlineDataStored;
          let lastModifiedForDeltaSync, hourDifference;
          await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLANS, true).then((doc) => {
            offlineDataStored = doc
            if (doc && doc.raw) {
              this.accountmanagementOfflineService.accountPlans = doc.raw;
              this.accountmanagementOfflineService.currencySymbol = doc.currencySymbol;
            }
            else {
              this.accountmanagementOfflineService.accountPlans = [];
              fullSync = true;
            }
          })
          let positionIds = this.authService.user.positions.map(o => {
            return o.ID
          });
          let positionString = '';
          positionIds.forEach(p => {
            positionString += '<value>' + p + '</value>'
          })
          let fetchXML = fetchQueries.accountManagement.fetchAccountPlans.split('{positionIDs}').join(positionString);
          let now = new Date();
          if (fullSync) {
            fetchXML = fetchXML.replace('{deltaSyncFilter}', '').replace('{statuscondition}', '<condition attribute="statuscode" value="548910002" operator="ne"/>').replace('{planID}', '');
          } else if (accountPlanID) {
            let planIDFilter = `<filter><condition attribute="indskr_accountplanid" operator="eq" value="` + accountPlanID + `"/></filter>`
            fetchXML = fetchXML.replace('{planID}', planIDFilter).replace('{deltaSyncFilter}', '').replace('{statuscondition}', '');
          } else {
            offlineDataStored ? lastModifiedForDeltaSync = offlineDataStored.lastModified : '';
            let deltaSyncFilter
            if (lastModifiedForDeltaSync) {
              hourDifference = differenceInHours(now, new Date(lastModifiedForDeltaSync))
              //add one to make sure we take care of fractional difference in hours
              hourDifference += 1
              const entityname = 'indskr_accountplan'
              deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
              deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference)
              deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id')
              fetchXML = fetchXML.replace('{statuscondition}', '');
            }
            else {
              deltaSyncFilter = '';
              fetchXML = fetchXML.replace('{statuscondition}', '<condition attribute="statuscode" value="548910002" operator="ne"/>');
            }
            fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter).replace('{planID}', '');
          }
          // //fill up offline duration filter
          fetchXML = fetchXML.replace('{compareToDate}', this.getDateWithDuration());
          await this.dynamics.executeFetchQuery('indskr_accountplans', fetchXML)
            .then(async (res) => {
              if (res) {
                const responses = await Promise.all([
                  this.getDocuments(fullSync, accountPlanID, hourDifference),
                  this.getObjectives(fullSync, accountPlanID, hourDifference),
                  this.getContacts(fullSync, accountPlanID, hourDifference),
                  this.getExpenses(fullSync, accountPlanID, hourDifference),
                  this.getAccountPlansEvents(fullSync, accountPlanID, hourDifference)
                ])
                if (responses) {
                  responses.forEach(resp => res.push(...resp));
                }
              }
              if (!fullSync && !accountPlanID) {
                let deletedPlans = await this.fetchHardDeletedAccountPlans(hourDifference);
                if (deletedPlans) res.push(...deletedPlans);
              }
              await this.aggregateAccountPlans(res, fullSync, accountPlanID);
              await this.getAccountPlansReports(fullSync, accountPlanID, hourDifference);
              if (accountPlanID) {
                let planIndex = this.accountmanagementOfflineService.accountPlans.findIndex(o => o.ID == accountPlanID);
                if (planIndex > -1) {
                  this.accountmanagementOfflineService.accountPlans[planIndex].lastUpdated = now.getTime()
                }
                this.saveAccountPlanInDB();
              } else {
                this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLANS, (doc) => {
                  doc = {
                    raw: []
                  };
                  doc.raw = this.accountmanagementOfflineService.accountPlans;
                  doc.raw.map(r => {
                    r.lastUpdated = now.getTime();
                  })
                  doc.lastModified = now.getTime();
                  doc.currencySymbol = this.accountmanagementOfflineService.currencySymbol;
                  return doc;
                })
              }
            },
              (err) => {

              })
          this.accountmanagementOfflineService.accountPlans$.next(this.accountmanagementOfflineService.accountPlans);
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
  private getDateWithDuration() {
    const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
    let today = new Date();
    const maxEndDateUnixTimestamp = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - duration
    );
    let dateWithDuration = moment(maxEndDateUnixTimestamp).format('YYYY-MM-DD');
    return dateWithDuration;
  }

  private async getCurrencySymbol(): Promise<string> {
    const defaultCurrencyUrl = this.authService.userConfig.activeInstance.url + Endpoints.accountManagement.DEFAULT_CURRENCY_SYMBOL.replace("{userId}", this.authService.user.systemUserID);
    let response = await this.http.get<any[]>(defaultCurrencyUrl).toPromise();
    if (response['_transactioncurrencyid_value']) {
      //If user has personalized currecy set
      const personalCurrecyUrl = this.authService.userConfig.activeInstance.url + Endpoints.accountManagement.PERSONAL_SETTING_CURRENCY_SYMBOL.replace("{transactioncurrencyid}", response['_transactioncurrencyid_value']);
      response = await this.http.get<any[]>(personalCurrecyUrl).toPromise();
      return response['currencysymbol'];
    }
    return response['currencysymbol'];
  }

  public saveAccountPlanInLocal(accountPlan: AccountPlan) {
    const index = this.accountmanagementOfflineService.accountPlans.findIndex(plan => plan.ID == accountPlan.ID);
    if (index > -1) {
      this.accountmanagementOfflineService.accountPlans[index] = accountPlan;
      this.saveAccountPlanInDB();
    }
  }

  public async saveAccountPlanInDB() {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLANS, (doc) => {
      doc = {
        raw: [],
        lastModified: doc.lastModified
      };
      doc.raw = this.accountmanagementOfflineService.accountPlans;
      doc.currencySymbol = this.accountmanagementOfflineService.currencySymbol;
      return doc;
    });
  }

  public async scrapAccountPlanInLocal(planId: string) {
    try {
      const index = this.accountmanagementOfflineService.accountPlans.findIndex(plan => plan.ID == planId);
      if (index === -1) return false;
      this.accountmanagementOfflineService.accountPlans.splice(index, 1);
      await this.saveAccountPlanInDB();
      this.accountmanagementOfflineService.accountPlans$.next(this.accountmanagementOfflineService.accountPlans);
      console.log(this.accountmanagementOfflineService.accountPlans);
    } catch (error) {
      console.log(error);
    }
  }

  async getAccountPlansEvents(fullSync?: boolean, accountPlanID?: string, hourDifference?: any): Promise<any> {
    if (this.authService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_ACCOUNT_PLAN)) {
      try {
        return await this.dynamics.executeFetchQuery('indskr_accountplans', this.getLinkEntityFetchXml(fetchQueries.accountManagement.fetchEvents, fullSync, accountPlanID, hourDifference));
      } catch (error) {
        console.log(error)
      }
    }
    return [];
  }

  async getAccountPlansReports(fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    try {
      let positionIds = this.authService.user.positions.map(o => {
        return o.ID
      });
      let positionString = '';
      positionIds.forEach(p => {
        positionString += '<value>' + p + '</value>'
      })
      let fetchXML = fetchQueries.accountManagement.fetchObjectiveReports.split('{positionIDs}').join(positionString);
      if (fullSync) {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      else {
        let deltaSyncFilter
        if (hourDifference) {
          const entityname = 'indskr_accountplanprogressreport'
          deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
          deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference)
          deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id')
        }
        else deltaSyncFilter = ''
        fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter)
      }
      if (accountPlanID) {
        let planIDFilter = `<filter><condition attribute="indskr_accountplanid" operator="eq" value="` + accountPlanID + `"/></filter>`
        fetchXML = fetchXML.replace('{planID}', planIDFilter)
      }
      else {
        fetchXML = fetchXML.replace('{planID}', '')
      }
      //fill up offline duration filter
      const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
      let today = new Date();
      const maxEndDateUnixTimestamp = Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - duration
      );
      let dateWithDuration = moment(maxEndDateUnixTimestamp).format('YYYY-MM-DD');
      fetchXML = fetchXML.replace('{compareToDate}', dateWithDuration);
      await this.dynamics.executeFetchQuery('indskr_accountplans', fetchXML)
        .then(async (res) => {
          if (!fullSync) {
            let deletedReports = await this.fetchDeletedReports(fullSync, accountPlanID, hourDifference)
            res.push(...deletedReports)
          }
          console.log('account plan reports', res);
          await this.aggregateAccountPlanReports(res, fullSync, accountPlanID);
        },
          (err) => {

          })

    } catch (error) {
      console.log(error)
    }
  }

  private async getObjectives(fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    try {
      let objectives = await this.dynamics.executeFetchQuery('indskr_accountplans', this.getLinkEntityFetchXml(fetchQueries.accountManagement.fetchObjectives, fullSync, accountPlanID, hourDifference));
      return objectives;
    } catch (error) {
      console.error(error)
    }
    return [];
  }

  private async getDocuments(fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    try {
      let documents = await this.dynamics.executeFetchQuery('indskr_accountplans', this.getLinkEntityFetchXml(fetchQueries.accountManagement.fetchDocuments, fullSync, accountPlanID, hourDifference));
      return documents.filter(doc => doc.hasOwnProperty('ap_document.indskr_iodocumentid') || doc.hasOwnProperty('ap_resource.indskr_ioresourceid') || doc.hasOwnProperty('ap_ppt.indskr_iopresentationid'));
    } catch (error) {
      console.error(error)
    }
    return [];
  }

  private getLinkEntityFetchXml(fetchXML, fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    const positionIds = this.authService.user.positions.map(o => {
      return o.ID
    });
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>';
    })
    fetchXML = fetchXML.split('{positionIDs}').join(positionString);
    if (fullSync) {
      fetchXML = fetchXML.replace('{deltaSyncFilter}', '').replace('{planID}', '');
    } else if (accountPlanID) {
      let planIDFilter = `<filter><condition attribute="indskr_accountplanid" operator="eq" value="` + accountPlanID + `"/></filter>`;
      fetchXML = fetchXML.replace('{planID}', planIDFilter).replace('{deltaSyncFilter}', '');
    } else {
      let deltaSyncFilter;
      if (hourDifference) {
        const entityname = 'indskr_accountplan';
        deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname);
        deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference);
        deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id');
      }
      else deltaSyncFilter = ''
      fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter).replace('{planID}', '');
    }
    fetchXML = fetchXML.replace('{compareToDate}', this.getDateWithDuration());
    return fetchXML;
  }

  private async getExpenses(fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    // if (this.authService.hasFeatureAction(FeatureActionsMap.EXPENSES_IN_ACCOUNT_PLAN)) {
    if (!accountPlanID)
      this.accountmanagementOfflineService.currencySymbol = await this.getCurrencySymbol();
    try {
      if (this.authService.hasFeatureAction(FeatureActionsMap.EXPENSES_IN_ACCOUNT_PLAN)) {
        const expenses = await this.dynamics.executeFetchQuery('indskr_accountplans', this.getLinkEntityFetchXml(fetchQueries.accountManagement.fetchExpenses, fullSync, accountPlanID, hourDifference));
        return expenses;
      }
    } catch (error) {
      console.error(error)
    }
    // }
    return [];
  }

  private async getContacts(fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    try {
      return await this.dynamics.executeFetchQuery('indskr_accountplans', this.getLinkEntityFetchXml(fetchQueries.accountManagement.fetchContacts, fullSync, accountPlanID, hourDifference));
    } catch (error) {
      console.error(error)
    }
    return [];
  }

  async fetchHardDeletedAccountPlans(hourDifference?: any) {
    try {
      let responseForDeletedPlans = [];
      let fetchXML;
      if (hourDifference) {
        fetchXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="indskr_trackchange">
              <order attribute='createdon' descending='false'/>
              <attribute name="createdon" alias="track_action_CreatedOn"/>
              <attribute name="indskr_action" alias="track_action"/>
              <attribute name="indskr_entityid" alias="planID"/>
              <filter type="and">
                <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference + `" />
                <condition value="indskr_accountplan" attribute="indskr_entityname" operator="eq" />
                <condition value="548910001" attribute="indskr_action" operator="eq" />
              </filter>
              </entity>
              </fetch>`
        responseForDeletedPlans = await this.dynamics.executeFetchQuery('indskr_trackchanges', fetchXML)
      }
      return responseForDeletedPlans
    } catch (error) {
      console.log(error)
    }
  }

  async fetchDeletedReports(fullSync?: boolean, accountPlanID?: string, hourDifference?: any) {
    try {
      let responseForDeletedReports = [];
      let fetchXML;
      if (hourDifference) {
        fetchXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
          <entity name="indskr_trackchange">
            <order attribute='createdon' descending='false'/>
            <attribute name="createdon" alias="track_action_CreatedOn"/>
            <attribute name="indskr_action" alias="track_action"/>
            <attribute name="indskr_entityid" alias="reportId"/>
            <filter type="and">
              <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference + `" />
              <condition value="indskr_accountplanprogressreport" attribute="indskr_entityname" operator="eq" />
              <condition value="548910001" attribute="indskr_action" operator="eq" />
            </filter>
            </entity>
            </fetch>`;
        responseForDeletedReports = await this.dynamics.executeFetchQuery('indskr_trackchanges', fetchXML)
      }
      return responseForDeletedReports
    } catch (error) {
      console.log(error)
    }
  }

  async aggregateAccountPlans(accountPlansData, fullSync?: boolean, accountPlanID?: string) {
    let accountPlans: AccountPlan[] = [];
    accountPlansData.map(a => {
      if (a.indskr_accountplanid) {
        let accPlan: AccountPlan;
        accPlan = accountPlans.find(o => o.ID == a.indskr_accountplanid)
        if (!accPlan) {
          accPlan = new AccountPlan(a);
          accPlan.accountPlanObjectives = []
          accPlan.contactAccountAffs = []
          accPlan.products = []
          accPlan.events = []
          accountPlans.push(accPlan)
        }
        if (a.hasOwnProperty('PO.indskr_accountplanobjectiveid')) {
          let objective = accPlan.accountPlanObjectives.find(o => o.objectiveID == a['PO.indskr_accountplanobjectiveid'])
          if (!objective) {
            objective = new AccountPlanObjective((a));
            accPlan.accountPlanObjectives.push(objective);
          }
          if (a['PO.CSF.indskr_criticalsuccessfactorsid']) {
            let CSF = objective.CSFs.find(c => c.CSFId == a['PO.CSF.indskr_criticalsuccessfactorsid']);
            if (!CSF) {
              CSF = {
                CSFId: a['PO.CSF.indskr_criticalsuccessfactorsid'],
                CSFName: a['PO.CSF.indskr_name']
              }
              objective.CSFs.push(CSF)
            }
          }
        }
        if (a.hasOwnProperty('product.productid')) {
          let product = accPlan.products.find(p => p.productID == a['product.productid'])
          if (!product) {
            product = { productID: a['product.productid'], productName: a['product.name'] }
            accPlan.products.push(product)
          }
        }

        if (a.hasOwnProperty('eventid')) {
          let events = accPlan.events.find(event => event.ID === a.eventid);
          if (!events) {
            let eventExists = true;
            if (accountPlanID) {
              eventExists = this.eventsToolService.eventsToolData.some(event => event.ID === a.eventid);
            }
            if (eventExists) {
              events = { ID: a.eventid, name: a.event_name, statuscode: a.statuscode }
              accPlan.events.push(events);
              let sortedEvents = _.orderBy(accPlan.events, [event => event.name.toLowerCase()]);
              accPlan.events = sortedEvents;
            }

          }
        }

        if (a.hasOwnProperty('af.contactid')) {
          let contact = accPlan.contactAccountAffs.find(c => c.contactId == a['af.contactid'])
          if (!contact) {
            contact = { contactId: a['af.contactid'], contactFullName: a['af.firstname'] + " " + a['af.lastname'], indskr_accountcontactaffiliationid : a['ae.indskr_accountcontactaffiliationid'] }
            accPlan.contactAccountAffs.push(contact);
          }
        }

        if (a.hasOwnProperty('track_action')) {
          accPlan.trackAction = a['track_action_Formatted'];
        }
        if (a.hasOwnProperty('ap_document.indskr_iodocumentid') && !accPlan.documents.some(doc => (<Resource>doc).ioDocumentId === a['ap_document.indskr_iodocumentid'])) {
          const document: Resource = this.mapDocument(a);
          if (document) accPlan.documents.push(document);
        }
        if (a.hasOwnProperty('ap_resource.indskr_ioresourceid') && !accPlan.documents.some(doc => (<Resource>doc).ioResourceId === a['ap_resource.indskr_ioresourceid'])) {
          const resource: Resource = this.mapResource(a);
          if (resource) accPlan.documents.push(resource);
        }
        if (a.hasOwnProperty('ap_ppt.indskr_iopresentationid') && !accPlan.documents.some(doc => (<Presentation>doc).ioPresentationId === a['ap_ppt.indskr_iopresentationid'])) {
          const presentation: Presentation = this.mapPresentation(a);
          if (presentation) accPlan.documents.push(presentation);
        }
        if (a.hasOwnProperty('expenseId')) {
          const expense: Expense = this.formatExpense(a);
          if (expense) {
            const index = accPlan.expenses.findIndex(exp => exp.expensesId == expense.expensesId);
            if (index >= 0) {
              accPlan.expenses[index].notes.push(...expense.notes);
            } else {
              accPlan.expenses.push(expense);
            }
            _.orderBy(accPlan.expenses, 'description');
          }
        }
      }
      else if (a.hasOwnProperty('planID')) {
        a['indskr_accountplanid'] = a['planID'];
        let aAccountPlan = new AccountPlan(a);
        //if we have an entry in the accountPlans array already that means its not hard deleted, we can skip this entry
        if (accountPlans.some(ap => ap.ID == aAccountPlan.ID)) {

        }
        else accountPlans.push(aAccountPlan);
      }
    })

    if (fullSync) {
      this.accountmanagementOfflineService.accountPlans = accountPlans
    } else {
      accountPlans.map(acpl => {
        if (!acpl.trackAction || (acpl.trackAction && acpl.trackAction == 'Download')) {
          let index = this.accountmanagementOfflineService.accountPlans.findIndex(o => o.ID == acpl.ID)
          if (index > -1) {
            this.accountmanagementOfflineService.accountPlans[index] = acpl
          }
          else {
            this.accountmanagementOfflineService.accountPlans.push(acpl);
          }
        }
        if ((acpl.trackAction && acpl.trackAction == 'Remove') || acpl.status === "Cancelled") {
          let index = this.accountmanagementOfflineService.accountPlans.findIndex(o => o.ID == acpl.ID)
          if (index > -1) {
            this.accountmanagementOfflineService.accountPlans[index].state = 1
            this.accountmanagementOfflineService.accountPlans[index].statusCode = 2
          }
          else {

          }
        }
      })
      this.accountmanagementOfflineService.accountPlans = this.accountmanagementOfflineService.accountPlans.filter(p => {
        return (p.state == 0 || !(p.state == 1 && p.statusCode == 2)) && !_.isEmpty(p.accountId)
      })
    }
  }

  private formatExpense(data): Expense {
    let expense: Expense = {
      expensesId: data.expenseId,
      amount: data.amount,
      description: data.description,
      amountFormatted: data['amount@OData.Community.Display.V1.FormattedValue'],
      currencySymbol: data.transactioncurrencysymbol,
      notes: []
    }
    if (data['annotationId']) {
      const note = new IONote({
        annotationid: data['annotationId'],
        createdon: data['createdOn'],
        notetext: data['noteText'],
        filename: data['fileName'],
        isdocument: data['fileSize'] > 0 ? true : false,
        filesize: data['fileSize'],
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
        hasDocument: true
      });
      note.createdTime = data['createdOn'];
      expense.notes = [note];
    }
    return expense;
  }

  async aggregateAccountPlanReports(reportsData, fullSync?: boolean, accountPlanID?: string) {
    try {
      let reports: AccountPlanObjectiveReport[] = [];
      let accountPlanReportsMap = new Map();
      reportsData.map(a => {
        ``
        if (a.indskr_accountplanid) {
          if (a.hasOwnProperty('PO.indskr_accountplanobjectiveid')) {
            if (a.hasOwnProperty('report.indskr_accountplanprogressreportid')) {
              let report = reports.find(r => r.reportId == a['report.indskr_accountplanprogressreportid'])
              if (!report) {
                report = new AccountPlanObjectiveReport(a);
                reports.push(report);
              }
              if (a.hasOwnProperty('report.CSF.indskr_criticalsuccessfactorsid')) {
                let CSF = report.CSFs.find(o => o.CSFId == a['report.CSF.indskr_criticalsuccessfactorsid'])
                if (!CSF) {
                  CSF = {
                    CSFId: a['report.CSF.indskr_criticalsuccessfactorsid'],
                    CSFName: a['report.CSF.indskr_name']
                  }
                  report.CSFs.push(CSF);
                }
              }
              if (a.hasOwnProperty('progressReportNotes.annotationid')) {
                let note = report.notes.find(o => o.noteId == a['progressReportNotes.annotationid'])
                if (!note) {
                  note = new IONote({
                    annotationid: a['progressReportNotes.annotationid'],
                    activityid: a['report.indskr_accountplanprogressreportid'],
                    contactid: null,
                    accountid: null,
                    createdon: new Date(a['progressReportNotes.createdon']).getTime().toString(),
                    notetext: a['progressReportNotes.notetext'],
                    ownerName: a['progressReportNotes.ownerid_Formatted'],
                    ownerid: a['progressReportNotes.ownerid'],
                    isdocument: a['progressReportNotes.isdocument'] && a['progressReportNotes.filesize'] > 0,
                    documentbody: '',
                    filename: a['progressReportNotes.filename'],
                    filesize: a['progressReportNotes.filesize'],
                    mimetype: a['progressReportNotes.mimetype'],
                    pendingPushForDynamics: false,
                    isDeleted: false,
                    updated: false,
                  })
                  report.notes.push(note);
                }
              }
            }
          }
        }
        else if (a.hasOwnProperty('reportId')) {
          a['report.indskr_accountplanprogressreportid'] = a['reportId'];
          //if we have an entry in the reports array already that means its not hard deleted, we can skip this entry
          if (reports.some(rp => rp.reportId == a.reportId)) {

          }
          else reports.push(new AccountPlanObjectiveReport(a))
        }
      })
      if (fullSync) {
        //initial sync of reports data
        await this.createProgressReport({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true }, reports, true, true)
      }
      else {
        //delta sync of reports data
        await this.updateProgressReport({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true }, reports, true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async createProgressReportOnline(data): Promise<any> {
    // Create new progress report on dynamics and return promise with success or failure

    return new Promise(async (resolve, reject) => {
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.CREATEORUPDATE_PROGRESS_REPORT;
      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        'X-BusinessUnitId',
        this.authService.user.xBusinessUnitId
      ).set(
        "X-PositionId",
        this.authService.user.xPositionID
      );
      await this.http
        .post(url, data, headers)
        .toPromise()
        .then(async (response) => {
          resolve(response);
        }, async (error) => {
          reject(error)
        })
    })
  }

  async updateProgressReportOnline(data): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.CREATEORUPDATE_PROGRESS_REPORT;
      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        'X-BusinessUnitId',
        this.authService.user.xBusinessUnitId
      ).set(
        "X-PositionId",
        this.authService.user.xPositionID
      );
      await this.http
        .patch(url, data, headers)
        .toPromise()
        .then(async (response) => {
          resolve(response);
        }, async (error) => {
          reject(error)
        })
    })
  }

  public deleteProgressReportOnline(data): Promise<any> {
    // delete new progress report on dynamics and return promise with success or failure

    return new Promise(async (resolve, reject) => {
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.DELETE_PROGRESS_REPORT;
      url = url.replace('{REPORTID}', data.reportId)
      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        'X-BusinessUnitId',
        this.authService.user.xBusinessUnitId
      ).set(
        "X-PositionId",
        this.authService.user.xPositionID
      );
      await this.http
        .delete(url, headers)
        .toPromise()
        .then(async (response) => {
          resolve(response);
        }, async (error) => {
          reject(error)
        })
    })
  }

  public async createProgressReport(action: OperationDetail, data, isBulkInsert: boolean = false, fromInitialSync: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (action.onDynamics) {
        // Call online service to create on dynamics
        if (!isBulkInsert) {
          let serviceDTO = new CreateProgressReportRequestBody(data);
          await this.createProgressReportOnline(serviceDTO).then(successResponse => {
            // Succesfully created on dynamics
            data.reportId = successResponse['indskr_accountplanprogressreportid'];
            data.syncPending = false;
            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
            }
          }, error => {
            // Handle any error scenario
            data.syncPending = true;
            if (error && error.errorCode && error.errorCode == "ERR_IO_DELETE_APPR") {
              if (error.entityName && error.entityName == 'indskr_criticalsuccessfactorses') {
                let index = data.CSFs.findIndex((o) => {
                  o.CSFId == error.entityId
                })
                data.CSFs.splice(index, 1);
              }
              if (error.entityName && (error.entityName == 'indskr_accountplans' || error.entitiName == 'indskr_accountplanobjectives')) {
                reject(error)
              }
            }
          });
        } else {
          // Don't think it will be used for now
        }
      }
      if (action.onLocalDatabase) {
        let offlineDTO = data; //To Do: Transform data into offline data format
        if (!isBulkInsert) {
          offlineDTO = [data];
        } else if (Array.isArray(data)) {
          offlineDTO = data.map(a => {
            return a; //To Do: Transform data into offline data format
          })
        }

        try {
          let offlineProgressReportDoc;
          await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT).then(doc => {
            if (doc && doc.raw) {
              offlineProgressReportDoc = doc;
              this.accountmanagementOfflineService.allProgressReports = doc.raw;
            }
          }).catch(err => {
            // Take it as Progress Report Doc is empty or not saved yet
          });
          if (offlineProgressReportDoc && !fromInitialSync) {
            offlineProgressReportDoc.raw.push(...offlineDTO);
            await this.disk.updateDocWithIdAndRev(offlineProgressReportDoc);

            // Update offline data count
            if (offlineDTO.syncPending) {
              this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROGRESS_REPORT, 1);
            }
          } else {
            offlineProgressReportDoc = offlineDTO;
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT, (doc) => {
              if (!doc || !doc.raw) {
                doc = {
                  raw: []
                };
              }
              doc.raw = offlineProgressReportDoc;
              return doc;
            });
          }
          if (!action.onLocalCopy) {
            resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
          }
        } catch (error) {
          reject('Error occured saving data to offline db');
        }
      }
      if (action.onLocalCopy) {
        // Data for UI
        try {
          if (!isBulkInsert) {
            data = [data];
          }
          if (fromInitialSync) {
            this.accountmanagementOfflineService.allProgressReports = data
          }
          else {
            data.forEach(rep => {
              this.accountmanagementOfflineService.upsertLocalObjectProgressReport(rep);
            });
          }

          resolve('Successfully created new progress report');
        } catch (err) {
          reject('Error occured while creating new progress report');
        }
      }
    });
  }

  public async updateProgressReport(action: OperationDetail, data, isBulkUpdate: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (action.onDynamics) {
        if (!isBulkUpdate) {
          let serviceDTO:any = new CreateProgressReportRequestBody(data);
          serviceDTO['indskr_accountplanprogressreportid'] = data.reportId
          if(action.message == 'UPDATE_NOTES' && data.status != 'Open'){
            serviceDTO = {
              indskr_accountplanprogressreportid: data.reportId,
              notes: serviceDTO.notes,
            }
          }
          await this.updateProgressReportOnline(serviceDTO).then(successResponse => {
            // Succesfully updated on dynamics
            if (successResponse.indskr_completedpercent) {
              data.completedPercent = successResponse.indskr_completedpercent
            }
            if (successResponse.indskr_completedval) {
              data.completedValue = successResponse.indskr_completedval
            }
            data.syncPending = false;
            if (successResponse && successResponse['indskr_accountplanprogressreportid']) {
              // Update offline notes id
              let notesArrayFromResponse = successResponse['notes'];
              let notes = [];
              if ( notesArrayFromResponse && _.isArray(notesArrayFromResponse)) {

                notesArrayFromResponse.map(noteFromResponse => {
                  if (noteFromResponse.hasOwnProperty('deleted') && !noteFromResponse['deleted']) {
                    let foundOwnerName = '';
                    let foundNote = data.notes.find(a=> a.ownerId == noteFromResponse['ownerid']);
                    if(foundNote && foundNote.ownerName){
                      foundOwnerName = foundNote.ownerName
                    }
                    notes.push(new IONote({
                      annotationid: noteFromResponse['noteid'],
                      isdocument: this._notehasDocument(noteFromResponse),
                      notetext: noteFromResponse['notetext'],
                      createdon: noteFromResponse['createdon'],
                      ownerid: noteFromResponse['ownerid'],
                      ownerName: foundOwnerName,
                      filename: noteFromResponse['filename'],
                      filesize: noteFromResponse['filesize'],
                      mimetype: noteFromResponse['mimetype'],
                      documentbody: noteFromResponse['documentbody'],
                      activityid: noteFromResponse['progressReportId'],
                      accountid: '',
                      contactid: '',
                      pendingPushForDynamics: false,
                      deleted: false,
                    }));
                  }
                });
              }
              data.notes = notes;
            }
            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
            }
          }, err => {
            // Handle any error scenario
            let error = err.error
            data.syncPending = true;
            if (error && error.errorCode && error.errorCode == 'ERR_IO_PR_APPROVE_TO_OPEN') {
              data.state = 1;
              data.statusCode = AccountPlanReportState.Approved;
              data.status = 'Approved';
              data.syncPending = false;
              this.notifyService.notify(this.translate.instant('AC_MANGE_SOME_DATA_SEEMS_TO_OUT_OF_SYNC'), '', 'top', '')
            }
            if (error && error.errorCode && error.errorCode == 'ERR_IO_PR_OPEN_TO_APPROVE') {
              data.state = 0;
              data.statusCode = AccountPlanReportState.Open;
              data.status = 'Open';
              data.syncPending = false;
              this.notifyService.notify(this.translate.instant('AC_MANGE_SOME_DATA_SEEMS_TO_OUT_OF_SYNC'), '', 'top', '')
            }
          });
        } else {// Can include the upload progress report part here and utilize the same methode for all operations

        }
      }
      if (action.onLocalDatabase) {
        let offlineDTO; //To Do: Transform data into offline data format
        if (!isBulkUpdate) {
          offlineDTO = [data];
        } else if (Array.isArray(data)) {
          offlineDTO = data.map(a => {
            return a;// Formatted Offline DTO
          })
        }
        try {
          let offlineProgressReportDoc;
          await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT).then(doc => {
            if (doc) {
              offlineProgressReportDoc = doc;
              this.accountmanagementOfflineService.allProgressReports = doc.raw;
            }
          }).catch(err => {
            // Take it as Progress Report Doc is empty or not saved yet
          });
          if (offlineProgressReportDoc && offlineProgressReportDoc.raw) {
            offlineDTO.forEach(dto => {
              if (!dto.trackAction || (dto.trackAction && dto.trackAction == 'Download')) {
                let idx = -1;
                //search by dynamics GUID first
                if (dto.reportId) {
                  idx = offlineProgressReportDoc.raw.findIndex(a => {
                    return a.reportId == dto.reportId;
                  });
                }
                if (idx >= 0) {
                  //assuming the dto object to be the most updated one.
                  //if(!offlineProgressReportDoc.raw[idx].syncPending){
                  offlineProgressReportDoc.raw[idx] = dto;
                  //}
                } else {
                  //search by external ID- slight chance of having same record in local with missing dynamics GUID
                  let possibleLocalIDIndex = offlineProgressReportDoc.raw.findIndex(a => {
                    return a.offlineId == dto.offlineId
                  })
                  if (possibleLocalIDIndex >= 0) {
                    offlineProgressReportDoc.raw[possibleLocalIDIndex] = dto;
                  }
                  else offlineProgressReportDoc.raw.push(dto);
                }
              }
              else if (dto.trackAction && dto.trackAction == 'Remove') {
                let idx = offlineProgressReportDoc.raw.findIndex(a => {
                  return a.reportId == dto.reportId;
                });
                //search by dynamics GUID first
                if (idx >= 0) {
                  offlineProgressReportDoc.raw[idx].state = 1;
                  offlineProgressReportDoc.raw[idx].statusCode = 2;
                }
                else {
                  //search by external ID- slight chance of having same record in local with missing dynamics GUID
                  let possibleLocalIDIndex = offlineProgressReportDoc.raw.findIndex(a => {
                    return a.offlineId = dto.offlineId
                  })
                  if (possibleLocalIDIndex >= 0) {
                    offlineProgressReportDoc.raw[possibleLocalIDIndex].state = 1;
                    offlineProgressReportDoc.raw[possibleLocalIDIndex].statusCode = 2;
                  }
                }
              }
            })
            // For tracking offline data count
            let offlineDataCount = 0;
            offlineProgressReportDoc.raw = offlineProgressReportDoc.raw.filter(o => {
              if (o.syncPending) {
                offlineDataCount++;
              }
              return (o.syncPending == true || (o.state == 0 || !(o.state == 1 && o.statusCode == 2)))
            })
            await this.disk.updateDocWithIdAndRev(offlineProgressReportDoc);

            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROGRESS_REPORT, offlineDataCount);
          } else {
            offlineProgressReportDoc = offlineDTO
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT, (doc) => {
              if (!doc || !doc.raw) {
                doc = {
                  raw: []
                };
              }
              doc.raw = offlineProgressReportDoc;
              return doc;
            });

            // Tracking offline data count
            if (Array.isArray(offlineDTO) && offlineDTO.length > 0) {
              const offlineCount = offlineDTO.filter(o => o.syncPending).length;
              this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROGRESS_REPORT, offlineCount);
            }
          }
          if (!action.onLocalCopy) {
            resolve('Successfully updated in offline DB');
          }
        } catch (error) {
          reject('Error occured updating data to offline db');
        }
      }
      if (action.onLocalCopy) {
        try {
          // Update local copy of data for UI
          if (!isBulkUpdate) {
            data = [data];
          }
          data.forEach(report => {
            this.accountmanagementOfflineService.upsertLocalObjectProgressReport(report);
            this.accountmanagementOfflineService.allProgressReports = this.accountmanagementOfflineService.allProgressReports.filter(o => {
              return o.state == 0 || !(o.state == 1 && o.statusCode == 2)
            })
          });

          resolve('Successfully created new progress report');
        } catch (err) {
          reject('Error occured while creating new progress report');
        }
      }
    });
  }

  public async deleteProgressReport(action: OperationDetail, data, isBulkUpdate: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (action.onDynamics) {
        if (!isBulkUpdate) {
          //let serviceDTO = new CreateProgressReportRequestBody(data);
          //serviceDTO['indskr_accountplanprogressreportid'] = data.reportId
          if (data.reportId) {
            await this.deleteProgressReportOnline(data).then(successResponse => {
              // Succesfully updated on dynamics
              data.syncPending = false;
              if (!action.onLocalDatabase && !action.onLocalCopy) {
                resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
              }
            }, error => {
              // Handle any error scenario
              data.syncPending = true;
            });
          }
          else {
            data.syncPending = false;
          }

        } else {// Can include the upload progress report part here and utilize the same methode for all operations

        }
      }
      else {
        data.deleted = true;
        data.syncPending = true;
      }
      await this.updateProgressReport({
        onDynamics: false,
        onLocalDatabase: action.onLocalDatabase,
        onLocalCopy: action.onLocalCopy
      }, data).then((data) => {
        resolve(data)
      }, (data) => {
        reject(data)
      })
    });
  }

  public async purgeData(maxEndDateUnixTimestamp: number) {
    let document;
    let offlineSavedAccountPlans;
    let toBePurgedAccountPlans = [];
    let afterPurgedAccountPlans = [];
    let deletedFollowups = [];
    let compareToDate = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp, true);
    await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLANS, true)
      .then((doc) => {
        if (doc && doc.raw) {
          document = doc;
          offlineSavedAccountPlans = doc.raw
        }
      }).catch(error => {
        console.log('Error occured while fetching account plans' + error);
      });
    if (offlineSavedAccountPlans) {
      offlineSavedAccountPlans.forEach(raw => {
        if (raw['endDate'] && raw['endDate'] < compareToDate.getTime().toString()) {
          // remove from existing arrays
          toBePurgedAccountPlans.push(raw);
          if (this.accountmanagementOfflineService.accountPlans) {
            const idx = this.accountmanagementOfflineService.accountPlans.findIndex(c => c.ID === raw['ID']);
            if (idx >= 0) {
              this.accountmanagementOfflineService.accountPlans.splice(idx, 1);
            }
          }
        } else {
          afterPurgedAccountPlans.push(raw);
        }
      });
    }
    // Only write to local db when something got purged

    if (offlineSavedAccountPlans && afterPurgedAccountPlans.length < offlineSavedAccountPlans.length) {
      try {
        document.raw = afterPurgedAccountPlans;
        await this.disk.updateDocWithIdAndRev(document);
      } catch (error) {
        console.error('purgeAccountPlans: ', error);
      }
      // Purge Associated followup tasks
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY,
            $lte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
          }
        }
      };
      try {
        // Fetch from DB
        const offlineFollowups: any[] = await this.disk.find(option);
        if (offlineFollowups && Array.isArray(offlineFollowups) && offlineFollowups.length > 0) {
          offlineFollowups.forEach(async rawfollowup => {
            let followup = new FollowUpActivity(rawfollowup);
            if (toBePurgedAccountPlans.some(plan => plan['ID'] == followup.accountPlanId)) {
              deletedFollowups.push({ _id: rawfollowup._id, _rev: rawfollowup._rev, _deleted: true });
              this.activityService.removeActivity(followup, false, null);
            }
          });
        }
      } catch (error) {
        console.log('Error occured while fetching follow up activities data from offline db');
      }
      try {
        // Bulk save/update docs to DB
        if (deletedFollowups.length > 0) {
          await this.disk.bulk(deletedFollowups);
        }
      } catch (error) {
        // TODO: handle error..
        console.error('purgeFollowups: ', error);
      }
    }
  }

  public mapDocument(row: any) {
    return new Resource({
      indskr_iodocumentid: row['ap_document.indskr_iodocumentid'],
      indskr_ioresourceid: "",
      indskr_title: row['ap_document.indskr_title'],
      createdon: isValid(new Date(row['ap_document.createdon'])) ? new Date(row['ap_document.createdon']).getTime().toString() : null,
      modifiedon: isValid(new Date(row['ap_document.modifiedon'])) ? new Date(row['ap_document.modifiedon']).getTime().toString() : null,
      indskr_ckmassettype: row['ap_document.indskr_ckmdocumenttype'],
      indskr_ckmthumbnailurl: row['ap_document.indskr_ckmthumbnailurl'],
      indskr_ckmasseturl: row['ap_document.indskr_ckmdocumenturl'],
      indskr_ckmassetid: row['ap_document.indskr_ckmdocumentid'],
      indskr_description: row['ap_document.indskr_description'],
      statecode: row['ap_document.statecode'],
      statuscode: row['ap_document.statuscode'],
      indskr_availablefrom: isValid(new Date(row['ap_document.indskr_availablefrom'])) ? new Date(row['ap_document.indskr_availablefrom']).getTime().toString() : null,
      indskr_availableuntil: isValid(new Date(row['ap_document.indskr_availableuntil'])) ? new Date(row['ap_document.indskr_availableuntil']).getTime().toString() : null,

      brands: null,
      indskr_ckmcreateddate: null,
      indskr_ckmtitle: null,
      _indskr_ckmcreatedbyid_value: null,
      indskr_ckmdescription: null,
      _indskr_ckmpublishedbyid_value: null,
      indskr_ckmpublisheddate: null,
      _indskr_ckmversionbyid_value: null,
      indskr_ckmversiondate: null,
      indskr_ckmversionid: null,
      _indskr_languageid_value: null,
      isNew: row['isNew']
    });
  }

  public mapResource(row: any) {
    return new Resource({
      indskr_iodocumentid: "",
      indskr_ioresourceid: row['ap_resource.indskr_ioresourceid'],
      indskr_title: row['ap_resource.indskr_title'],
      createdon: isValid(new Date(row['ap_resource.createdon'])) ? new Date(row['ap_resource.createdon']).getTime().toString() : null,
      modifiedon: isValid(new Date(row['ap_resource.modifiedon'])) ? new Date(row['ap_resource.modifiedon']).getTime().toString() : null,
      indskr_ckmassettype: row['ap_resource.indskr_ckmassettype'],
      indskr_ckmthumbnailurl: row['ap_resource.indskr_ckmthumbnailurl'],
      indskr_ckmasseturl: row['ap_resource.indskr_ckmasseturl'],
      indskr_ckmassetid: row['ap_resource.indskr_ckmassetid'],
      indskr_description: row['ap_resource.indskr_description'],
      statecode: row['ap_resource.statecode'],
      statuscode: row['ap_resource.statuscode'],
      indskr_availablefrom: isValid(new Date(row['ap_resource.indskr_availablefrom'])) ? new Date(row['ap_resource.indskr_availablefrom']).getTime().toString() : null,
      indskr_availableuntil: isValid(new Date(row['ap_resource.indskr_availableuntil'])) ? new Date(row['ap_resource.indskr_availableuntil']).getTime().toString() : null,

      brands: null,
      indskr_ckmcreateddate: null,
      indskr_ckmtitle: null,
      _indskr_ckmcreatedbyid_value: null,
      indskr_ckmdescription: null,
      _indskr_ckmpublishedbyid_value: null,
      indskr_ckmpublisheddate: null,
      _indskr_ckmversionbyid_value: null,
      indskr_ckmversiondate: null,
      indskr_ckmversionid: null,
      _indskr_languageid_value: null,
      isNew: row['isNew']
    });
  }

  public mapPresentation(row: any) {
    const presentation = new Presentation({
      indskr_iopresentationid: row['ap_ppt.indskr_iopresentationid'],
      indskr_title: row['ap_ppt.indskr_title'],
      createdon: row['ap_ppt.createdon'],
      modifiedon: row['ap_ppt.modifiedon'],
      indskr_ckmthumbnailurl: row['ap_ppt.indskr_ckmthumbnailurl'],
      indskr_ckmthumbnailszipurl: row['ap_ppt.indskr_ckmthumbnailszipurl'],
      indskr_ckmzipurl: row['ap_ppt.indskr_ckmzipurl'],
      indskr_description: row['ap_ppt.indskr_description'],
      statecode: row['ap_ppt.statecode'],
      statuscode: row['ap_ppt.statuscode'],
      indskr_availablefrom: row['ap_ppt.indskr_availablefrom'],
      indskr_availableuntil: row['ap_ppt.indskr_availableuntil'],
    });
    if (!isValid(presentation.availableUntil)) {
      presentation.availableUntil = null;
    }
    if (!isValid(presentation.availableFrom)) {
      presentation.availableFrom = null
    }
    return presentation;
  }

  public async saveAccountPlan(accountPlan, accountPlanId?: string): Promise<AccountPlan> {
    if (!accountPlanId) {
      accountPlanId = Guid.create().toString();
    }
    if (accountPlan.indskr_startdate) {
      accountPlan.indskr_startdate = moment(accountPlan.indskr_startdate).format("YYYY-MM-DD").toString();
    }
    if (accountPlan.indskr_enddate) {
      accountPlan.indskr_enddate = moment(accountPlan.indskr_enddate).format("YYYY-MM-DD").toString();
    }
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.SAVE_ACCOUNT_PLAN.replace('{accountPlanId}', accountPlanId);
    const response = await this.http.put<any>(url, accountPlan, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    accountPlan.ID = response.indskr_accountplanid;
    return accountPlan;
  }

  public async addOpportunitiesToAccountPlan(accountPlanId: string, payload: any): Promise<any> {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.SAVE_ACCOUNT_PLAN.replace('{accountPlanId}', accountPlanId);
    await this.http.put<any>(url, payload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async fetchAppointments(accountPlan: AccountPlan): Promise<AppointmentActivity[]> {
    const accountIds = '<value>' + accountPlan.accountId + '</value>';
    const fetchXml = fetchQueries.accountManagement.fetchAppointmentsForAccounts.replace('{accountIds}', accountIds);
    const response: AppointmentActivity[] = await this.dynamics.executeFetchQuery('appointments', fetchXml).then((resp: []) => {
      return resp.map(data => {
        const appointment = new AppointmentActivity(data);
        appointment.ownerId = this.authService.user.xSystemUserID;
        return appointment;
      });
    })
    return response;
  }

  public async fetchMeetingsTimeline(accountPlanId: string): Promise<any> {
    const fetchXML = fetchQueries.accountManagement.fetchMeetingForAccountPlan.replace('{planID}', accountPlanId);
    const response = await this.dynamics.executeFetchQuery('indskr_accountplans', fetchXML).then((resp: []) => {
      return resp.map(data => {
        let meeting = {
          location: data["meeting.location"],
          subject: data["meeting.subject"],
          scheduledstart: data["meeting.scheduledstart"],
          scheduledend: data["meeting.scheduledend"],
          indskr_ownerfullname: data["meeting.ownerid_Formatted"],
          indskr_ownerid: data["meeting.ownerid"],
          activityid: data["meeting.activityid"],
          indskr_jointmeeting: data["meeting.indskr_jointmeeting"],
          isRemoteDetailing: data["isRemoteDetailing"],
          statecode: data["meeting.statecode"],
        }
        const appointment = new AppointmentActivity(meeting);
        appointment['activityMeetingId'] = data['activityMeetingId'];
        return appointment;
      });
    });
    return response;
  }

  getFollowupActivity(accountPlan: AccountPlan) {
    const followUp = this.activityService.activities.filter((a) => {
      return (a.type === ActivityType.FollowUp && (a as FollowUpActivity).accountPlanId === accountPlan.ID
        && (a.ownerId === this.authService.user.systemUserID
          || (a as FollowUpActivity).assignedTo.some(o => o.userId === this.authService.user.systemUserID)
          || accountPlan.ownerID === this.authService.user.systemUserID)
        && ((a as FollowUpActivity).planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN)
        && (a.state === 0));
    }).sort((a, b) => {
      return (a.subject > b.subject) ? 1 : -1;
    }).sort((a, b) => {
      if (isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        if (isEqual(a.scheduledEnd, b.scheduledEnd)) return 1;
        else return (isBefore(a.scheduledEnd, b.scheduledEnd) ? 1 : -1);
      } else if (isValid(a.scheduledEnd) && !isValid(b.scheduledEnd)) {
        return 1;
      } else if (!isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        return -1;
      } else {
        return 1;
      }
    });
    return followUp;
  }

  public async fetchPhoneCallsTimeline(accountPlanId: string): Promise<any> {
    let response = [];
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      const fetchXML = fetchQueries.accountManagement.fetchPhoneCallsForAccountPlan.replace('{planID}', accountPlanId);
      response = await this.dynamics.executeFetchQuery('indskr_accountplans', fetchXML).then((resp: []) => {
        return resp.map(data => {
          let phoneCall = {
            subject: data["phonecall.subject"],
            scheduledstart: data["phonecall.scheduledstart"],
            scheduledend: data["phonecall.scheduledend"],
            indskr_ownerfullname: data["phonecall.ownerid_Formatted"],
            indskr_ownerid: data["phonecall.ownerid"],
            phonenumber: data["phonecall.phonenumber"],
            activityid: data["phonecall.activityid"],
            statecode: data["phonecall.statecode"],
            activitytypecode: ActivityType.PhoneCall,
          }
          const phonecallActivity = new PhoneActivity(phoneCall);
          phonecallActivity['activityPhoneCallId'] = data['activityPhoneCallId'];
          return phonecallActivity;
        });
      })
    }
    return response;
  }

  // fetchPhoneCallsForAccountPlan
  public async fetchPhoneCalls(accountPlan: AccountPlan): Promise<PhoneActivity[]> {
    const accountIds = '<value>' + accountPlan.accountId + '</value>';
    const fetchXml = fetchQueries.accountManagement.fetchPhoneCallsForAccounts.replace('{accountIds}', accountIds);
    const response: PhoneActivity[] = await this.dynamics.executeFetchQuery('phonecalls', fetchXml).then((resp: []) => {
      return resp.map(data => {
        const phoneCallActivity = new PhoneActivity(data);
        phoneCallActivity.ownerId = this.authService.user.systemUserID;
        return phoneCallActivity;
      });
    })
    return response;
  }

  public async saveMeetingsForAccountPlan(accountPlanId: string, requestPayload) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.SAVE_ACTIVITY_MEETING.replace('{accountPlanId}', accountPlanId);
    return await this.http.put<any>(url, requestPayload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async savePhoneCallsForAccountPlan(accountPlanId: string, requestPayload) {
    const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.SAVE_ACTIVITY_PHONECALL.replace('{accountPlanId}', accountPlanId);
    return await this.http.put<any>(url, requestPayload, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async saveFollowupForAccountPlan(requestPayload: FollowUpActivity[]) {
    await this.followupDataService.updateFollowUpActivities(requestPayload);
  }

  private async _updateObjectiveOnline(payload,objectiveId:string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accountManagement.UPDATE_OBJECTIVE;
      url = url.replace('{{AccountPlanObjectiveId}}',objectiveId);
      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        'X-BusinessUnitId',
        this.authService.user.xBusinessUnitId
      ).set(
        "X-PositionId",
        this.authService.user.xPositionID
      );
      await this.http
        .patch(url, payload, headers)
        .toPromise()
        .then(async (response) => {
          resolve(response);
        }, async (error) => {
          reject(error)
        })
    })
  }

  public async updateObjectiveTarget(objective: AccountPlanObjective){
    const serviceDTO = {
      "indskr_targetamount":objective.target,
      "indskr_achievement1yrprojected": objective.achievementProjectedOne,
      "indskr_achievement1yryotal": objective.achievementTotalOne
    }
    await this._updateObjectiveOnline(serviceDTO,objective.objectiveID);
    //Success update in db
    if(this.accountmanagementOfflineService.selectedAccountPlan$.value){
      let idx = this.accountmanagementOfflineService.accountPlans.findIndex(ap => ap.ID == this.accountmanagementOfflineService.selectedAccountPlan$.value.ID);
      if(idx >= 0){
        let objIdx = this.accountmanagementOfflineService.accountPlans[idx].accountPlanObjectives.findIndex(o => o.objectiveID == objective.objectiveID);
        if(objIdx >= 0){
          this.accountmanagementOfflineService.accountPlans[idx].accountPlanObjectives[objIdx].target = objective.target;
          this.accountmanagementOfflineService.accountPlans[idx].accountPlanObjectives[objIdx].formattedTarget = objective.formattedTarget;

          await this.saveAccountPlanInDB();
        }
      }
    }
  }

  private _notehasDocument(note) {
    return (note.hasOwnProperty('filesize') && note['filesize'] > 0)
  }


  // Calculation not required on app side
  // public async updateEstimatedAndActualRevenueOfAccountPlans (){
  //   try {

  //     this.accountmanagementOfflineService.accountPlans.forEach(accountPlan=> {
  //       const associatedOpportunities = this.opportunityService.opportunities.filter(opp => opp.accountPlanID == accountPlan.ID);
  //       accountPlan.actualRevenue = 0.00;
  //       accountPlan.estimatedRevenue = 0.00;
  //       if(associatedOpportunities && associatedOpportunities.length){
  //         associatedOpportunities.forEach(opportunity => {
  //           accountPlan.estimatedRevenue = accountPlan.estimatedRevenue + opportunity.estimatedValue;
  //           if(opportunity.state == 'Won'){
  //             accountPlan.actualRevenue = accountPlan.actualRevenue + opportunity.closedValue;
  //           }
  //         });
  //       }
  //     });

  //     await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_PLANS, (doc)=>{
  //       doc = {
  //           raw: [],
  //           lastModified: doc.lastModified
  //       };
  //       doc.raw = this.accountmanagementOfflineService.accountPlans;
  //       return doc;
  //     });

  //   } catch (error) {
  //     console.log("Error occured while updating Estimated/Actual Revenue of Account Plan"+error);
  //   }
  // }

}

export interface OperationDetail {
  onDynamics: boolean;
  onLocalDatabase: boolean;
  onLocalCopy: boolean;
  message?:string;
}
