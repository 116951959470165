import { Component } from '@angular/core';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';

/**
 * Generated class for the LanguageSettingsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-language-settings[base-page]',
  templateUrl: 'app-language-settings.html',
  styleUrls:['app-language-settings.scss']
})
export class LanguageSettingsComponent {

  text: string;

  constructor (
     public navService: NavigationService,
     private uiService: UIService,
     public device: DeviceService
    ) {
  }

}
