<ion-header>

    <ind-page-title [viewData]="allocationTransferPanePageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ion-list class='details-list'>
        <ind-section-header [viewData]='detailsHeader'></ind-section-header>
        <ind-form-field [viewData]="statusFormField"></ind-form-field>
        <ind-form-field [viewData]="transferNumberField"></ind-form-field>
        <ind-form-field [viewData]="shipmentDateField"></ind-form-field>
        <ind-form-field [viewData]="selectedUserField"></ind-form-field>
        <ind-form-field [viewData]="selectedProductField"></ind-form-field>

        <ind-section-header *ngIf="allocationTransferForm" [viewData]='availableLotsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
        <ion-item class="pill-scroll-x-item" margin-top *ngIf="allocationTransferForm?.sku && allocationTransferForm?.availableLots">
            <!-- <ion-row> -->
            <ion-col [ngClass]="allocationTransferForm?.availableLots.length > 1?'activeArrow':''" class="arrow">
                <ion-icon name="chevron-back-outline" style="color:#c8c7cc;" (click)="arrowClicked('left')"></ion-icon>
            </ion-col>
            <ion-col class="scroll-container" id="lotsContainer">
                <div *ngIf="allocationTransferForm?.availableLots.length > 0">
                    <span *ngFor="let Lot of allocationTransferForm?.availableLots" [ngClass]="{'selected': isLotSelected(Lot)}" class="lotNameBubble" (click)="selectLot(Lot)">{{Lot.name}} {{'(' + Lot.totalQuantityRemaining + ')'}}
                            </span>
                </div>
            </ion-col>
            <ion-col [ngClass]="allocationTransferForm?.availableLots.length > 1?'activeArrow':''" class="arrow arrow-right">
                <ion-icon name="chevron-forward-outline" style="color:#c8c7cc;" (click)="arrowClicked('right')"></ion-icon>
            </ion-col>
            <!-- </ion-row> -->
        </ion-item>
        <ind-section-header *ngIf="allocationTransferForm?.sku && allocationTransferForm?.lot" [viewData]='selectedLotsHeader'></ind-section-header>
        <ion-item *ngIf="allocationTransferForm?.sku && allocationTransferForm?.lot" class="qtyLot">

            <ion-label>
                <p class="primary-text">
                    <ion-text>{{allocationTransferForm?.lot?.name}}</ion-text>
                </p>
                <p class="child-label-text">
                  <ion-text>{{formattedDateInfoLabelForSampleLot}}</ion-text>
                </p>
                <p class="form-field-label-text">
                  <ion-text>{{'QUANTITY' | translate}}</ion-text>
                </p>
                <p class="qtyLotInput">
                    <ion-input [ngClass]="allocationTransferForm.quantityTransferred?{'exceededlimits': !allocationTransferForm.isQtyValid}:''" [(ngModel)]='allocationTransferForm.quantityTransferred' type="number" pattern="[0-9]*" step="1" placeholder="0" value="{{allocationTransferForm.quantityTransferred?allocationTransferForm.quantityTransferred:null}}"
                        min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="qtyLot-labelText" #focusInput (focus)="onInputFocus(focusInput)" [disabled]="allocationTransferForm.lot.totalQuantityRemaining > 0 ? false : true"
                        (ionChange)="onInput($event)">
                    </ion-input>
                    <ion-icon slot="end" name="chevron-back-outline" class="ion-float-right arrow-icon"></ion-icon>
                </p>
            </ion-label>
        </ion-item>
        <ind-form-field *ngIf="allocationTransferForm?.sku && allocationTransferForm?.lot" [viewData]="reasonField"></ind-form-field>
        <ind-form-field *ngIf="allocationTransferForm.reason && allocationTransferForm.reason.value==548910000" [viewData]="getCommentFormField()"></ind-form-field>
    </ion-list>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'ShipmentDetails'"></footer-toolbar>
<!-- </ion-footer> -->
