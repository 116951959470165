export enum CoachingDimensions {
  sourceType = 'sourceType',
  statusCode = 'statusCode',
  activityId = 'activityId',
  covisitor = 'covisitor',
}

export enum CoachingKPI {
  coVisitorMeetings = 'Covisitor Meetings',
  coachingsAcknowledged = 'Coachings Acknowledged',
  avgCoachingScore = 'Average Coaching Score',
  uniqCustMetWithCovisitor = 'Unique Customers Met with Covisitor',
  attendedAsCovisitor = 'Attended as Covisitor',
}

export enum CoachingChartId {
  trend = 'trend',
}
