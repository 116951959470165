<ion-header>
  <ind-page-title [viewData]="pageTitle" (onControlClick)='headerClickHandler($event)'></ind-page-title>
  <ion-toolbar>
    <ind-tabs [data]="tabsData" [selectedTab]="territorySegment" (selectedTabChange)="segmentChanged($event)">
    </ind-tabs>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div [ngSwitch]="territorySegment">
    <territory-manangement-list-details [selectedTab]="selectedTab" *ngSwitchCase="'manage-list'"></territory-manangement-list-details>
    <territory-manangement-info [selectedTab]="selectedTab" *ngSwitchCase="'info'"></territory-manangement-info>
  </div>

</ion-content>

<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'territoryDetails'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>