import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuotePriceList, QuoteStatus, Quotes } from '@omni/classes/quotes/quotes.class';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  selectedQuotes$: BehaviorSubject<Quotes> = new BehaviorSubject(null);
  quotesList$: BehaviorSubject<Quotes[]> = new BehaviorSubject([]);
  priceLists: QuotePriceList[] = [];
  constructor(
    public translate : TranslateService
  ) { }

  async updateQuoteLocal(updatedProperties, quoteId) {
    const quotes = this.quotesList$.getValue();
    const index = quotes.findIndex((quote) => quote.ID === quoteId);
    if (index >= 0) {
      quotes[index] = { ...quotes[index], ...updatedProperties };
      this.quotesList$.next(quotes);
      this.selectedQuotes$.next(quotes[index]);
      return
    }
    return;
  }

  updateProduct(updatedProduct, quoteId) {
    let products = this.selectedQuotes$.getValue().products;
    let product: any;
    if (products.length === 0 || !products.some(({ quoteDetailId }) => quoteDetailId === updatedProduct.product.quoteDetailId)) {
      product = {
        id: updatedProduct.product.productId,
        quantity: updatedProduct.product.quantity,
        quoteDetailId: updatedProduct.product.quoteDetailId,
        name: updatedProduct.product.name
      }
      products.push(product);
    } else {
      const index = products.findIndex(({ quoteDetailId }) => quoteDetailId === updatedProduct.product.quoteDetailId);
      if (index >= 0) {
        if (updatedProduct.product.deleted) {
          products.splice(index, 1);
        } else {
          products[index] = { ...products[index], ...updatedProduct.product }
        }
      }
    }
    this.updateQuoteLocal({ products, totalAmount: updatedProduct.totalAmount }, quoteId);
  }

  async clearProductsAndUpdateLocal(updatedProperties, quoteId) {
    try {
      updatedProperties = { ...updatedProperties, products: [] };
      await this.updateQuoteLocal(updatedProperties, quoteId);
      return;
    } catch (error) {
      return
    }
  }

   getFormattedStatus(status: any) {
    switch (status) {
      case QuoteStatus.APPROVED:
        return this.translate.instant("APPROVED");
      case QuoteStatus.DRAFT:
        return this.translate.instant("OM_DRAFT");
      case QuoteStatus.REJECTED:
        return this.translate.instant("REJECTED");
      case QuoteStatus.DYNAMICS_CANCELED:
      case QuoteStatus.CANCELED:
        return this.translate.instant("CANCELLED");
      case QuoteStatus.PENDING_APPROVAL:
        return this.translate.instant("POP_IN_REVIEV");
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.DYNAMICS_IN_PROGRESS:
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.WON:
        return this.translate.instant("WON");
      case QuoteStatus.OPEN:
        return this.translate.instant("AL_SHIPMENT_FILTERLIST_OPEN");
      case QuoteStatus.LOST:
        return this.translate.instant("OPPORTUNITY_STATE_LOST");
    }
  }
}
