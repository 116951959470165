<ion-header>
  <ind-page-title [viewData]='titleBar' (onControlClick)='handleButtonClick($event)'></ind-page-title>
</ion-header>

<ion-content [ngClass]="{'mobileContent': device.isMobileDevice}">
  <ion-list>
    <ind-form-field [viewData]="categoryViewData"></ind-form-field>
    <ind-form-field [viewData]="statusFieldViewData"></ind-form-field>
    <ind-form-field [viewData]="productViewData"></ind-form-field>
    <ind-form-field [viewData]="productCodeViewData"></ind-form-field>
    <ind-form-field [viewData]="serialNoViewData"></ind-form-field>
    <ind-form-field [viewData]="parentAssetViewData"></ind-form-field>
    <ind-form-field [viewData]="accountViewData"></ind-form-field>
    <ind-form-field [viewData]="contactViewData"></ind-form-field>
    <ng-container *ngIf="notesSectionHeaderViewData">
      <ind-section-header [viewData]="notesSectionHeaderViewData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <ind-text-area id="notesInput" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000" (indChange)="notesChanged($event)" [value]="tempNoteText"
                     (removeAttachment)="removeAttachment($event)" [rows]="4" [cols]="10"> </ind-text-area>
      <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
      <io-note *ngFor="let note of assetNotes" [inputNote]="note" [editable]="isNoteEditable(note)" [readOnlyNote]="false" [readOnlyAttachment]="false"
               [deletable]="isNoteEditable(note)" [disableDownloadInOffline]="true" [isNoteAttachmentEnabled]="true" (saveNoteOut)="updateNote($event)"></io-note>
    </ng-container>
    <ng-container *ngIf="selectedAsset.transferEnabled">
      <ind-section-header [viewData]="transferDetailsHeaderModel"></ind-section-header>
      <ind-form-field [viewData]="newAssetOwnerFieldViewData"></ind-form-field>
      <ind-form-field [viewData]="newAssetLocationFieldViewData"></ind-form-field>
      <ind-datetime-form [viewData]="newAssetLocationStartDateFieldViewData"></ind-datetime-form>
      <ind-datetime-form [viewData]="newAssetLocationEndDateFieldViewData"></ind-datetime-form>
      <ind-form-field *ngIf="selectedAsset.indskr_assetscategory === 548910002"
                      [viewData]="newAssetLocationContractFieldViewData"></ind-form-field>
    </ng-container>
  </ion-list>
</ion-content>

<footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="handleButtonClick($event)" [footerView]="'detailView'" [selectedView]="'AssetDetails'"></footer-toolbar>
