<!-- Generated template for the GeneeNotificationPopoverComponent component -->
<div *ngIf="geneeData.closeBtn" class="closeBtnContainer">
  <img tappable (click)="closePopover()" class="closeButton" src="assets/imgs/closeGeneeScreen.png" width="24"  height="24"/>
</div>
<div [ngClass]="{'scrollContent': geneeData.closeBtn}">
  <div class="geneeCommentWrapper">
    <img tappable src="assets/imgs/ogeeneeChat.svg" width="35"  height="35"/>
    <!-- <img src="assets/imgs/chat_edge_left.svg" height="25" class="chatEdgeLeft"/> -->
    <p class="geneeCommentContainer">{{geneeData.notificationText}}</p>
  </div>
  <div class="actionButtonsContainer">
    <ion-button *ngIf="secondaryButtontext" icon-only padding-left class="geneeActionButtons secondaryButton" (click)="closePopover()">
      {{secondaryButtontext}}
    </ion-button>
    <ion-button *ngIf="geneeData.mainButtontext" icon-only padding-left class="geneeActionButtons mainButton" (click)="handleMainButtonClick()">
      {{geneeData.mainButtontext}}
    </ion-button>
    <ind-arrow-scroller *ngIf="geneeData.actions && geneeData.actions.length">
      <div #scrollableContent>
        <ion-button *ngFor ="let action of geneeData.actions" icon-only padding-left
        [ngClass] ="{'mainButton' : action.activitytypecode === geneeData.defaultAction, 'secondaryButton': action.activitytypecode !== geneeData.defaultAction}"
        class="geneeActionButtons actionButtonContainer" (click)="handleActionClick(action)">
          <ion-label style="margin-bottom: -3px !important;">{{action.name}}</ion-label>
          <img style="margin-bottom: -6px !important;" src="assets/imgs/xp_{{action.strength}}_strength_bar.svg" />
        </ion-button>
      </div>
    </ind-arrow-scroller>
    <ion-button *ngIf="geneeData.showLoader" icon-only padding-left class="geneeActionButtons mainButton">
      <ion-spinner></ion-spinner>
    </ion-button>
  </div>
</div>
  <!-- <div *ngIf="isGeneeButtonEnabled" class="ion-text-center geneeFooterContainer">
    <button ion-button fill="clear" class="microphone-btn">
      <img src="assets/imgs/ogeenee.svg" width="35"/>
    </button>
  </div> -->

