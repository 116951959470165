<ion-grid no-padding class="content-page" [ngClass]="(uiService.activeView == 'Sample' || !!allocFeatureService.selectedShipment || uiService.activeView == 'AllocationTransfer' || uiService.activeView == 'AllocationAdjust' || uiService.activeView == 'SimpleEntitySelect' || uiService.activeView == 'UserInventory')?'preview-active':'preview-inactive'">
  <ion-row no-padding>
      <ion-col no-padding class="sample-left-pane">
          <customer-allocation-list></customer-allocation-list>
      </ion-col>
      <ion-col no-padding class="sample-right-pane">
          <ion-nav #customerallocationtoolrightpane [ngClass]="uiService.activeView !=='customerallocationtoolrightpane'?'hide-nav':'' "></ion-nav>
          <nothing-selected-view [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'" *ngIf="!sampleService.selectedSampleSKU && uiService.activeView != 'Sample' && uiService.activeView != 'AllocationShipment' && !allocFeatureService.selectedShipment && uiService.activeView != 'AllocationOrdersList' && uiService.activeView != 'AllocationTransfer' && uiService.activeView != 'AllocationAdjust' && uiService.activeView !='UserInventory'"></nothing-selected-view>

          <new-sample-activity *ngIf=" activityService?.selectedActivity && uiService.activeView==='Sample' "></new-sample-activity>
          <allocation-shipment-details *ngIf="allocFeatureService?.selectedShipment && uiService.activeView==='AllocationShipment'" [masterPage]="pageName.AllocationComponent"></allocation-shipment-details>
          <allocation-transfer *ngIf="uiService.activeView=='AllocationTransfer' "></allocation-transfer>
          <allocation-adjust *ngIf="uiService.activeView=='AllocationAdjust' "></allocation-adjust>
          <user-inventory-detail *ngIf="uiService.activeView=='UserInventory'"></user-inventory-detail>
      </ion-col>
  </ion-row>
</ion-grid>