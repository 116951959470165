
import { ConfirmationAlertData } from "../classes/shared/confirmation-alert-data.class";
import { MainCardViewDataModel } from "./MainCardViewDataModel";

export enum MainToolTemplateListSelectionType {
    SINGLESELECTION = 'single-selection',
    MULTIPLESELECTION = 'multiple-selection',
}

export interface MainToolTemplateDetail {
    data?:Array<MainCardViewDataModel>;
    // selectedData?:Array<MainCardViewDataModel>;
    title:string;
    dividerTitle:string;
    showLeftHeaderButton?:boolean;
    orderByPropertyName?:string;
    leftHeaderBtnText?:string;
    leftHeaderBtnImgSrc?: string;
    showRightHeaderButton?:boolean;
    rightHeaderBtnText?:string;
    rightHeaderBtnImgSrc?: string;
    isSearchEnabled?:boolean;
    searchTitle?:string;
    minSearchLength?:number;
    isListSelectionEnabled?:boolean;
    hideAllItemsList?:boolean;
    listSelectionType?:MainToolTemplateListSelectionType;
    confirmationCheckRequired?:boolean;
    confirmationAlertData?:ConfirmationAlertData;
    navOptions?: any;
    updateOptionForTeamMember?: boolean;
    preventDeSelect?: boolean;
    eventsHandler?:(data:any, eventTarget:string, refData?:MainToolTemplateDetail)=>void;
    searchHandler?:(searchText:string)=>string[];
}
