
import {takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnChanges, OnInit, ChangeDetectorRef, Input, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { EventsService } from '../../../services/events/events.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { SchedulerService, SchedulingPop } from '../../../services/scheduler/scheduler.service';
import { Scheduler } from "../../../classes/scheduler/scheduler.class";
import { SchedulerDataService } from '../../../data-services/scheduler/scheduler.data.service';
import { LoadingController } from "@ionic/angular";
import { schedulePayload } from '../../../classes/scheduler/scheduler.class';
import * as moment from "moment";
import { DeviceService } from '../../../services/device/device.service';
import { Subscription, Subject } from 'rxjs';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { UIService } from '../../../services/ui/ui.service';
import { REP_STATUS } from '../../../models/rep-status-model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { AlertService } from '../../../services/alert/alert.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { OrderByPipe } from '@omni/pipes/orderby.pipe';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { SchedulerDetailComponent } from '../scheduler-detail/scheduler-detail';

@Component({
  selector: 'scheduler-list',
  templateUrl: 'scheduler-list.html',
  styleUrls:['scheduler-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerListComponent implements OnDestroy, OnInit, OnChanges {

  text: string;
  public isDeviceOnline: boolean = true;
  @Input() schedulerSearchText:string = '';
  private refreshList: boolean = false;
  @Input() confirmNewPattern:boolean = false;

  public schedulerList: Scheduler[] = [];
  private newRequestedRaised: boolean = false;
  public selectedSchedule: Scheduler;
  private selectedScheduleObs: Subscription;
  private selectedScheduleSubscription: Subscription;
  private doListRefreshSubscription: Subscription;
  private newActivitySubscription: Subscription;
  ngdestroy$: any = new Subject<boolean>();
  private ItabSubscription: Subscription;
  slicedSchedulerList: Scheduler[];
  sliceCount = 20;
  private orderByPipe: OrderByPipe;
  routesHeaderModel: IndSectionHeaderViewDataModel;

  constructor(
    public events: EventsService,
    private _cd: ChangeDetectorRef,
    public repService: RepServices,
    private schedulerService: SchedulerService,
    public deviceService: DeviceService,
    private alertService: AlertService,
    private loadingCtrl: LoadingController,
    private schedulerDataService: SchedulerDataService,
    private loadCtrl: LoadingController,
    private trackingService: TrackService,
    private activityService: ActivityService,
    private uiService: UIService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private navService: NavigationService,
  ) {
    this.orderByPipe = new OrderByPipe();
  }

  ngOnInit() {
    this.isDeviceOnline = !this.deviceService.isOffline;
    this.selectedScheduleObs = this.schedulerService.schedulerObs.subscribe(value => {
      this.selectedSchedule = value;
      this._cd.detectChanges();
    });

    this.selectedScheduleSubscription = this.schedulerService.schedulerListObservable.subscribe(res => {
      if (res) {
        this._cd.detectChanges();
        this.schedulerList = [];
         this.mapSchedulerPatterns();
        Object.assign(this.schedulerList, this.schedulerService.getAllSchedules);
        this.schedulerList = this.orderByPipe.transform(this.schedulerList, "startdate", false);
        this.slicedSchedulerList = this.schedulerList.slice(0, this.sliceCount);
        this.refreshList = !this.refreshList;
        this._cd.detectChanges();
        this._cd.markForCheck();
        this.updateEmptyMessage()
      }
    });

    if (this.uiService.activityRequestObserver) {
      this.newActivitySubscription = this.uiService.activityRequestObserver.subscribe(status => {
        this.newRequestedRaised = status;
      });
    }

    this.events.observe('device:deviceIsOffline').pipe(
    takeUntil(this.ngdestroy$))
    .subscribe((status) => {
      // the network service indicate that the device is offline so no arguments.
      this.cd.detectChanges();
      this.isDeviceOnline = false;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });

    this.translate.onLangChange.subscribe(data => {
      this.getRoutesHeader();
      this.cd.detectChanges();
      this.cd.markForCheck();
    })

    this.events.observe('device:deviceIsOnline').pipe(
    takeUntil(this.ngdestroy$))
    .subscribe((status) => {
      if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
        this.cd.detectChanges();
        this.isDeviceOnline = false;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
      else {
        this.cd.detectChanges();
        this.isDeviceOnline = true;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
      this.getRoutesHeader();
    });

    this.ItabSubscription = this.uiService.tabObserver.subscribe(view => {
      if(this.deviceService.isOffline || this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState){
        this.cd.detectChanges();
        this.isDeviceOnline = false;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
      this.getRoutesHeader();
    });

    this.deviceService.isOfflineObservable.pipe(takeUntil(this.ngdestroy$)).subscribe((offline) => {
      this.isDeviceOnline = !offline;
      this.getRoutesHeader();
      this.cd.detectChanges();
    });
    this.getRoutesHeader();
  }

  ngOnDestroy() {
    this.schedulerService.setSchedule(undefined);
    this.activityService.selectedActivity = undefined;
    if (this.newRequestedRaised) {
      this.uiService.showNewActivity = true;
    }
    else {
      this.uiService.showNewActivity = false;
    }
    this.selectedScheduleObs.unsubscribe();
    this.selectedScheduleSubscription.unsubscribe();
    //please do not unsubscribe to these events. These are crucial events that need to be detected by app.
    //unsubsribing it like this will unsubscribe it from all the places.
    // this.events.unsubscribe('device:deviceIsOffline');
    // this.events.unsubscribe('device:deviceIsOnline');
    if (this.newActivitySubscription) {
      this.newActivitySubscription.unsubscribe();
    }
    this.ItabSubscription.unsubscribe();
    this.ngdestroy$.next(true);
    this.ngdestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    //If Change is detected on the refresh key then force UI to update
    try {
      if (changes) {
        if (changes.refreshList && changes.refreshList.currentValue != changes.refreshList.previousValue) {
          this._cd.detectChanges();
          this._cd.markForCheck();
        }
        if(changes.schedulerSearchText && changes.schedulerSearchText.currentValue.length >= 1 && changes.schedulerSearchText.currentValue != changes.schedulerSearchText.previousValue){
          this.schedulerList = [];
          Object.assign(this.schedulerList, this.schedulerService.getFilteredSchedulesBySearchText(changes.schedulerSearchText.currentValue));
          this.schedulerList = this.orderByPipe.transform(this.schedulerList, "startdate", false);
          this.slicedSchedulerList = this.schedulerList.slice(0, this.sliceCount);
          this.refreshList = !this.refreshList;
          this._cd.detectChanges();
          this._cd.markForCheck();
        }else if(changes.schedulerSearchText && changes.schedulerSearchText.currentValue.length < 1 && (changes.schedulerSearchText.currentValue != changes.schedulerSearchText.previousValue || changes.schedulerSearchText.firstChange)){
          this.schedulerList = [];
          Object.assign(this.schedulerList, this.schedulerService.getAllSchedules);
          this.schedulerList = this.orderByPipe.transform(this.schedulerList, "startdate", false);
           this.slicedSchedulerList = this.schedulerList.slice(0, this.sliceCount);
          this.refreshList = !this.refreshList;
         this._cd.detectChanges();
         this._cd.markForCheck();
        }else if(changes.confirmNewPattern && (changes.confirmNewPattern.currentValue != changes.confirmNewPattern.previousValue || changes.confirmNewPattern.firstChange )){
          this.newScheduler();
        }
        this.updateEmptyMessage()
      }
    }
    catch (err) {
      console.error(err);
    }

  }

  public getRoutesHeader() {

    let detailsHeaderControls = [];

    detailsHeaderControls.push({
        id: 'saved-routes-btn',
        text: this.translate.instant('SCHEDULE_NEW_PLAN'),
        isDisabled: !this.isDeviceOnline || this.deviceService.isOffline,
    });

    this.routesHeaderModel = {
      id: 'saved-routes-header',
      title: this.translate.instant('SAVED_ROUTES_PLANS'),
      controls: detailsHeaderControls,
    };
  }

  onSectionHeaderControlClick(id) {
    if (id === 'saved-routes-btn') {
      this.newScheduler();
    }
    this.getRoutesHeader();
  }

  updateEmptyMessage(){
    this.events.publish('updateActivitiesRHSEmptyPage', this.schedulerList.length > 0 ? false: true)
  }

  mapSchedulerPatterns() {
    if (!this.schedulerService.hasMappedData) {
      let loader = this.loadCtrl.create()
      .then((loader)=>{
        loader.present();
        this.schedulerService.mapSchedulerPatterns();
        loader.dismiss();
      })
    }
  }

  onSelect(event: Scheduler) {
    if (this.schedulerService.areChangeSaved) {
      this.schedulerService.setSchedule(event);
      //for mobile device then update stack
      this.scheduleSelected(event)
    }
    else {
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('SCHEDULER_LIST_ALERT_MESSAGE')}, this.translate.instant('DISCARD')
      ).then (res => {
        if(res.role == "ok") {
          this.schedulerService.discardCurrentChanges()
          .then( e => {
            console.log(e);
            this.schedulerService.setSchedule(event);
            //for mobile device then update stack
            this.scheduleSelected(event)
          }).catch(e => {
            console.log(e);
          });

        }
      });
    }
  }

  newScheduler() {
    //Only trigger when the device is online
    this.trackingService.tracking('SchedulerNewPattern', TrackingEventNames.SCHEDULER)
    if (!this.deviceService.isOffline) {
      //TO Avoid User from clicking the new pattern button multiple times and adding junk data!!
      if (this.selectedSchedule == undefined) {
        let localSchedulePattern: Scheduler = this.schedulerService.newSchedule;
        localSchedulePattern.isNewScheduler = true;
        //this.schedulerService.addSchedule(localSchedulePattern);
        this.onSelect(localSchedulePattern);
      }
      else {
        if (!this.selectedSchedule.isNewScheduler) {
          let localSchedulePattern: Scheduler = this.schedulerService.newSchedule;
          localSchedulePattern.isNewScheduler = true;
          //Ñthis.schedulerService.addSchedule(localSchedulePattern);
          this.onSelect(localSchedulePattern);
        }
      }
    }
    else
      return;
  }

  ngAfterContentChecked() {
    // do this check only if user is on activities home page
    if (this.navService.getCurrentPageName() !== PageName.ActivitiesPageComponent) return;
    // set selected schedule if user has viwed any schedule but somewhere activityService.selectedActivity is changed/nullified
    if (this.selectedSchedule && ((this.activityService.selectedActivity && !(this.activityService.selectedActivity instanceof Scheduler)) ||
    !this.activityService.selectedActivity)) {
      this.scheduleSelected(this.selectedSchedule)
      this.schedulerService.setSchedule(this.selectedSchedule);
    }
    // retain activieView if it has been changed in any of the toolsDrawer items
    if (this.uiService.activeView !== 'Scheduler') {
      this.uiService.activeView = 'Scheduler';
    }
  }

  doInfinite(eventDetail, event) {
    let count = this.slicedSchedulerList.length;
    this.schedulerList = this.orderByPipe.transform(this.schedulerList, "startdate", false);
    this.slicedSchedulerList.push(...this.schedulerList.slice(count,  count + 20));
    event.target.complete();
  }

  scheduleSelected(routePlan) {
    this.activityService.selectedActivity = routePlan;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'Scheduler';
    if (this.deviceService.isMobileDevice) {
      this.uiService.showRightPane = true;
    }
   if(this.navService.getActiveChildNavViewPageName() !== PageName.SchedulerDetailComponent){
    this.navService.setChildNavRoot(SchedulerDetailComponent, PageName.SchedulerDetailComponent, PageName.ActivitiesPageComponent);
   } 
  }

}
