import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { TimeOffActivity } from '../../../classes/activity/timeoff.class';
import { TimeOffService } from '../../../services/time-off/time-off.service';
import { TImeOffReason } from '../../../classes/timeoff/timeoffReasons.class';
import { LocalizationService } from '@omni/services/localization/localization.service';

@Component({
  selector: 'time-off-item',
  templateUrl: 'time-off-item.html',
  styleUrls:['time-off-item.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TimeOffItemComponent implements OnChanges {

  /* Event emitter to inform the selected time-off request */
  @Output() onSelect = new EventEmitter<TimeOffActivity>();

  /* stores the current time-off request data on tile */
  @Input() timeOffData: TimeOffActivity;

  //private duration: string = "";

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    public timeOffService: TimeOffService,
    private localisationService: LocalizationService
  ) { }

  /*
    Whenever a timeoff data is detected then updated the tile
  */
  ngOnChanges() {
    /* error handling for the start and end time */
    if (isNaN(new Date(this.timeOffData.totEndTime).getDate())) {
      this.timeOffData.totEndTime = new Date();
    }
    if (isNaN(new Date(this.timeOffData.totStartTime).getDate())) {
      this.timeOffData.totStartTime = new Date();
    }
  }

  /* depending on the tab selected return the custom value */
  getSubjectOrUser(): string {
    return this.timeOffService.totMode === "teamRequests" ? this.timeOffData.totOwnerValue : this.timeOffData.name;
  }

  /* Reason for team view format */
  get getReasonTeam(): string {
    let reason: string = "";
    this.timeOffService.timeOffReasonList.map((item: TImeOffReason) => {
      if (this.timeOffData.timeOffReason === item.reasonCode) {
        reason = item.reason;
      }
    });
    return this.localisationService.getTranslatedString(reason) + ": " + this.timeOffData.name;
  }

  /* Reason for my-request format */
  get getReason(): string {
    let reason: string = "";
    this.timeOffService.timeOffReasonList.map((item: TImeOffReason) => {
      if (this.timeOffData.timeOffReason === item.reasonCode) {
        reason = item.reason;
      }
    });
    return this.localisationService.getTranslatedString(reason);
  }

  /* custom icon depending on the time off status */
  getTOTIcon(tot: TimeOffActivity): string {
    return tot.statuscode != 100000004
      ? "assets/imgs/tot_unapproved_status.svg"
      : "assets/imgs/tot_approved_status.svg";
  }

  /* Custom duration for tile view */
  get calculateDuration(): string {
    return this.timeOffService.getFormattedTimeInterval(this.timeOffData);
  }

}
