import { Injectable } from "@angular/core";
import { EmbeddedInteraction, Activity, EmbeddedInteractionType, ActivityType } from "./../../classes/activity/activity.class";
import { BehaviorSubject } from "rxjs";
import { ActivityService, ActivitySource } from "../activity/activity.service";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "./../../classes/authentication/user.class";
import { EmailActivity } from "./../../classes/activity/email.activity.class";
import { CaseActivity } from "./../../classes/case-intake/case-activity.class";
import { FooterService, FooterViews } from "../footer/footer.service";
import { ModalController, LoadingController } from "@ionic/angular";
import _ from "lodash";
import { UIService } from "../ui/ui.service";
import { PageName, NavigationService } from "../navigation/navigation.service";
import { InMeetingAllocationComponent } from "../../components/in-meeting-allocation/in-meeting-allocation";
import { AccesingMode, CaseManagementService } from "../case-management/case-management.service";
import { InMeetingCasesComponent } from "../../components/case-management/in-meeting-cases/in-meeting-cases";
import { TranslateService } from "@ngx-translate/core";
import { DiskService } from "../disk/disk.service";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { ActivityDataService } from "../../data-services/activity/activity.service";
import { ChannelType } from "../../classes/consent/channel.class";
import { EmailTemplateType } from "../../classes/email-templates/email-template.class";
import {DeviceService} from "../device/device.service";
import { PhoneActivity } from "../../classes/activity/phone.activity.class";

@Injectable({
  providedIn: 'root'
})

export class PhoneCallMeetingStructureService{
    private currentPhoneCallMeetingStructureSource = new BehaviorSubject<EmbeddedInteraction[]>(undefined);
    public currentPhoneCallMeetingStructureObserver = this.currentPhoneCallMeetingStructureSource.asObservable();
    private selectedPhoneCallMeetingActivitySource = new BehaviorSubject<EmbeddedInteraction>(null);
    public selectedPhoneCallMeetingActivityObserver = this.selectedPhoneCallMeetingActivitySource.asObservable();

    constructor(private activityService: ActivityService,
        private authenticationService: AuthenticationService,
        private modalCtrl: ModalController,
        private footerService: FooterService,
        private uiService: UIService,
        private navService: NavigationService,
        private translate: TranslateService,
        private caseManagementService: CaseManagementService,
        private disk: DiskService,
        private loadingController: LoadingController,
        private activityDataService: ActivityDataService,
        private device: DeviceService) {
    }

    private reInitializeSequence(embeddedInteractions: EmbeddedInteraction[]) {
        if (embeddedInteractions && embeddedInteractions.length > 0) {
            let sequence = embeddedInteractions.length - 1;
            embeddedInteractions = _.orderBy(embeddedInteractions, "sequence", "desc");
            embeddedInteractions.forEach(eI => {
                eI.sequence = sequence;
                sequence--;
            });
        }
        return embeddedInteractions;
    }

    private updateEmbeddedInteraction(embeddedInteractions: EmbeddedInteraction[]) {
        this.setCurrentPhoneCallMeetingStructure(embeddedInteractions);
        this.saveToDisk(embeddedInteractions);
    }

    private async saveToDisk(embeddedInteractions: EmbeddedInteraction[]) {
        const activity: PhoneActivity = <PhoneActivity>this.activityService.selectedActivity;
        const activityId = activity.offlineId && activity.offlineId.length > 0 ? activity.offlineId : activity.ID;
        let key = DB_KEY_PREFIXES.EMBEDDED_INTERACTIONS + activityId;
        await this.disk.updateOrInsert(key, doc => ({ embeddedInteractions }));
    }

    public setCurrentPhoneCallMeetingStructure(input) {
        this.currentPhoneCallMeetingStructureSource.next(input);
    }

    public async createEmbeddedInteractions(activity: PhoneActivity) {
        if (!activity) {
            this.setCurrentPhoneCallMeetingStructure([]);
            return;
        }
        let inPhoneCallMeetingActivities = this.getInPhoneCallMeetingActivities(activity);
        let currentEmbeddedInteractions: EmbeddedInteraction[] = [];
        let sequence = 0;
        if (inPhoneCallMeetingActivities.length === 0) {
            this.updateEmbeddedInteraction(currentEmbeddedInteractions)
        } else {
            if (inPhoneCallMeetingActivities && inPhoneCallMeetingActivities.length > 0) {
                inPhoneCallMeetingActivities.forEach(inMeetingActivity => {
                    const embeddedInteraction: EmbeddedInteraction =
                    new EmbeddedInteraction(this.getActivityId(inMeetingActivity),
                    this.activityTitle(inMeetingActivity), this.activityService.getActivityIcon(inMeetingActivity),
                    EmbeddedInteractionType.Activity, sequence++, inMeetingActivity,
                    this.getActivityIconName(inMeetingActivity));
                    currentEmbeddedInteractions.push(embeddedInteraction);
                });
            }

            const activityId = activity.offlineId && activity.offlineId.length > 0 ? activity.offlineId : activity.ID;
            await this.savePhoneCallMeetingActivitiesInDB(activityId, currentEmbeddedInteractions);
        }
    }
    private getInPhoneCallMeetingActivities(activity: PhoneActivity): Activity[] {
        let activities: Activity[] = [];
        const allActivities = this.authenticationService.impersonatedUser ? this.activityService.agendaTeamViewActivities : this.activityService.activities;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE))
            activities = activities.concat(allActivities.filter((o) => {
                return (o instanceof CaseActivity && ((o._online_meetingID === activity.ID) || (o.indskr_phonecallid === activity.ID) ||
                    (o.indskr_phonecallid && o.indskr_phonecallid.length > 0 && o.indskr_phonecallid === activity.offlineId) ||
                    (o.indskr_phonecallid && o.indskr_phonecallid.length > 0 && o.indskr_phonecallid === activity.offlineId) ||
                     (o.phoneCallOfflineId && o.phoneCallOfflineId.length > 0 && o.phoneCallOfflineId === activity.offlineId)))
            }));
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY))
            activities = activities.concat(allActivities.filter((o) => {
                return o instanceof EmailActivity && o.emailType != EmailTemplateType.RemoteURL
                && ((o.phoneCallActivityId && o.phoneCallActivityId.length > 0
                && o.phoneCallActivityId === activity.ID )|| (o.phoneCallOfflineId && o.phoneCallOfflineId.length > 0 && o.phoneCallOfflineId === activity.offlineId))
            }));
        return activities;
    }

    private getActivityId(activity: Activity) {
        let id = '';
        switch (activity.type) {
            case ActivityType.CaseIntake:
                id = (<CaseActivity>activity).offline_ID;
                break;
            case ActivityType.Email:
                id = (<EmailActivity>activity).offlineActivityId;
                break;
            default:
                console.log("Unhandled swithc case");
        }
        return id;
    }

    private activityTitle(activity: Activity) {
        let title: string = "";
        switch (activity.type) {
            case ActivityType.Email:
                let emailActivity: EmailActivity = new EmailActivity(activity);
                title = this.translate.instant('MESSAGE') + (emailActivity.subject == 'Message' ? '' : ' - ' +
                emailActivity.subject)
                break;
            case ActivityType.CaseIntake:
                let caseActivity: CaseActivity = <CaseActivity>activity;
                title = this.translate.instant('INQUIRY');
                if (caseActivity._case_type) {
                    title = caseActivity._case_type.name + ((caseActivity && caseActivity._case_contact) ?
                    (' - ' + caseActivity._case_contact.fullName) : '');
                }
                break;
            default:
                console.log("Unhandled activity case");
        }
        return title;
    }
    private getActivityIconName(activity: Activity) {
        let iconName: string = '';
        switch (activity.type) {
            case ActivityType.Email:
                iconName = "email";
                if (activity.status === 1) {
                    iconName = "email";
                }
                else if (activity.status === 3 || activity.status === 548910000) {
                    iconName = "email_blue";
                }
                else if (activity.status === 6 || activity.status === 9) {
                    iconName = "email_orange"
                }
                else if (activity.status === 8) {
                    iconName = "email_red"
                    if (activity instanceof EmailActivity && activity.channelType !== ChannelType.EMAIL && activity.status == 8 && activity.emailActivityParties.length > 1) {
                        iconName = "email";
                    }
                }
                else if (activity.status === 0) {
                    iconName = "email_green"
                }
                break;
            case ActivityType.CaseIntake:
                iconName = (<CaseActivity>activity)._case_status_value === 'Open' ? "case-intake" : "case-intake-completed";
                break;
            default:
                console.log("Unhandled activity icon case");
        }
        return "indegene-" + iconName;
    }

    private async savePhoneCallMeetingActivitiesInDB(activityId: string, embeddedActivities: EmbeddedInteraction[]) {
        await this.existingEmbeddedInteractions(activityId, embeddedActivities).then((data: EmbeddedInteraction[]) => {
            if (data && data.length > 0) {
                data = _.orderBy(data, "sequence", "desc");
            }
            this.setCurrentPhoneCallMeetingStructure(data);
        }).catch((er) => {
            console.error("Error occurred in existingEmbeddedInteractions() ", er);
            if (embeddedActivities && embeddedActivities.length > 0) {
                embeddedActivities = _.orderBy(embeddedActivities, "sequence", "desc");
            }
            embeddedActivities = this.reInitializeSequence(embeddedActivities);
            this.setCurrentPhoneCallMeetingStructure(embeddedActivities);
        });
    }

    private async existingEmbeddedInteractions(activityId: string, embeddedInteractions: EmbeddedInteraction[]) {
        let key = DB_KEY_PREFIXES.EMBEDDED_INTERACTIONS + activityId;
        let embeddedInteractionsRaw = await this.disk.retrieve(key);
        if (embeddedInteractionsRaw && embeddedInteractionsRaw.embeddedInteractions) {
            let embeddedInteractionsInDB: EmbeddedInteraction[] = embeddedInteractionsRaw.embeddedInteractions;
            //Add Embedded Interaction if not present in DB
            embeddedInteractionsInDB = this.addEmbeddedInteractionsIfNotPresentInDB(embeddedInteractions, embeddedInteractionsInDB);
            //Remove if not present in latest embedded interaction
            embeddedInteractionsInDB = this.removeEmbeddedInteractionsIfNotPresentInLatest(embeddedInteractionsInDB, embeddedInteractions);
            embeddedInteractions = embeddedInteractionsInDB
        }
        embeddedInteractions = this.reInitializeSequence(embeddedInteractions);
        await this.disk.updateOrInsert(key, doc => ({ embeddedInteractions }));
        return embeddedInteractions;
    }

    private addEmbeddedInteractionsIfNotPresentInDB(embeddedInteractions: EmbeddedInteraction[],
        embeddedInteractionsInDB: EmbeddedInteraction[]) {
        let embeddedInteractionsToUpdate: EmbeddedInteraction[] = [];
        embeddedInteractions.forEach(embeddedInteraction => {
            let sequence = -1;
            const index = embeddedInteractionsInDB.findIndex(eI => eI.id === embeddedInteraction.id);
            if (index === -1) {
                //Add at the end if not present
                embeddedInteraction.sequence = sequence;
                sequence--;
                embeddedInteractionsToUpdate.push(embeddedInteraction);
            }
            else {
                //Update name, thumbnail and sequence
                const eI = embeddedInteractionsInDB[index];
                if (eI.type === EmbeddedInteractionType.Activity) {
                    eI.name = this.activityTitle(<Activity>embeddedInteraction.interaction);
                    eI.thumbnailUrl = this.activityService.getActivityIcon(<Activity>embeddedInteraction.interaction);
                    eI.iconName = this.getActivityIconName(<Activity>eI.interaction);
                }
                else {
                    eI.name = embeddedInteraction.interaction['name'] ? embeddedInteraction.interaction['name'] : embeddedInteraction.interaction['title'];
                    eI.thumbnailUrl = embeddedInteraction.interaction['thumbnailUrl'] ? embeddedInteraction.interaction['thumbnailUrl'] : embeddedInteraction.interaction['thumbnailURL'];
                }
                eI.interaction = embeddedInteraction.interaction;
                embeddedInteractionsInDB[index] = eI;
            }
        });
        if (embeddedInteractionsToUpdate.length > 0) {
            embeddedInteractionsInDB = embeddedInteractionsInDB.concat(embeddedInteractionsToUpdate);
            embeddedInteractionsToUpdate = [];
        }
        return embeddedInteractionsInDB;
    }

    private removeEmbeddedInteractionsIfNotPresentInLatest(embeddedInteractionsInDB: EmbeddedInteraction[],
        embeddedInteractions: EmbeddedInteraction[]) {
        let embeddedInteractionsToUpdate: EmbeddedInteraction[] = [];
        embeddedInteractionsInDB.forEach(embeddedInteraction => {
            let index = embeddedInteractions.findIndex(eI => eI.id === embeddedInteraction.id);
            if (index === -1) {
                embeddedInteractionsToUpdate.push(embeddedInteraction);
            }
        });
        if (embeddedInteractionsToUpdate.length > 0) {
            embeddedInteractionsInDB = embeddedInteractionsInDB.filter(embeddedInteraction => embeddedInteractionsToUpdate.indexOf(embeddedInteraction) === -1);
            embeddedInteractionsInDB = _.orderBy(embeddedInteractionsInDB, "sequence", "desc");
        }
        return embeddedInteractionsInDB;
    }

    public setCurrentPhoneCallMeetingActivity(activity: EmbeddedInteraction) {
        this.selectedPhoneCallMeetingActivitySource.next(activity);
    }

    public updateEmbeddedActivity(activity: Activity) {
        const currentEmbeddedInteractions = this.currentPhoneCallMeetingStructureSource.getValue();
        if (currentEmbeddedInteractions) {
            currentEmbeddedInteractions.filter(em => this.getActivityId(activity) === em.id)
                .forEach(eI => {
                    eI.interaction = activity;
                    eI.name = this.activityTitle(activity);
                    eI.thumbnailUrl = this.activityService.getActivityIcon(activity);
                    eI.iconName = this.getActivityIconName(activity);
                });
        }
        this.updateEmbeddedInteraction(currentEmbeddedInteractions);
    }

    //For message activity
    public async openInPhoneCallMeetingActivitiesModal(activity: Activity, isNewlyCreated: boolean = false) {
        this.uiService.showNewActivity = false;

            if (!isNewlyCreated) {
                this.activityDataService.updateActivityDetails(activity, true, false);
            }
            if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
                this.uiService.activeView = 'PhoneCall'
                this.footerService.initButtons(FooterViews.PHONE_CALL);
            }
            let modal = await this.modalCtrl.create({component: InMeetingAllocationComponent,componentProps: { type: activity.type }, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
            modal.present();
            modal.onDidDismiss().then((data) => {
                this.footerService.initButtons(FooterViews.PHONE_CALL);
            });
    }

    public async openInPhoneCallMeetingCaseModal(activity: Activity) {
        this.caseManagementService.assignSelectedCase(<CaseActivity>activity);
               let source = AccesingMode.IN_PHONE_CALL

            this.caseManagementService.accessedFrom = source;
            let loader = await this.loadingController.create();
            if(!this.authenticationService.impersonatedUser){
              this.activityDataService.fetchCaseDetails(activity);
            }
            let modal =await this.modalCtrl.create({
              component: InMeetingCasesComponent,
              componentProps: {
                type: activity.type,
                view: 'case-details'
            },
            backdropDismiss: false, cssClass: this.device.isOffline ? "inMeetingCases offline-padding" : "inMeetingCases" })
            modal.present();
            modal.onDidDismiss().then((data) => {
                if (source == AccesingMode.IN_PHONE_CALL) {
                    this.footerService.initButtons(FooterViews.PHONE_CALL);
                }
            });

    }

    public createInPhoneCallMeetingEmbededActivity(embeddedActivity: Activity) {
        let currentEmbeddedInteraction = this.currentPhoneCallMeetingStructureSource.getValue() ?
        this.currentPhoneCallMeetingStructureSource.getValue() : [];
        let seq = this.getPhoneCallMeetingActivitySequence(currentEmbeddedInteraction);
        let embeddedInteraction: EmbeddedInteraction = this.getPhoneCallMeetingActivity(embeddedActivity, seq);
        currentEmbeddedInteraction.push(embeddedInteraction);
        this.setCurrentPhoneCallMeetingActivity(embeddedInteraction);
        this.updateEmbeddedInteraction(this.reInitializeSequence(currentEmbeddedInteraction))
    }

    private getPhoneCallMeetingActivitySequence(embeddedActivities: EmbeddedInteraction[]) {
        let seq = 0;
        const selectedMeetingActivity: EmbeddedInteraction = this.selectedPhoneCallMeetingActivitySource.getValue();
        if (!selectedMeetingActivity) {
            // get the highest sequence
            if (embeddedActivities && embeddedActivities.length > 0) {
                seq = embeddedActivities
                    .filter(embed => embed.sequence != null)
                    .sort((a, b) => (b.sequence) - (a.sequence))[0].sequence;
                seq++;
            }
        }
        else {
            seq = selectedMeetingActivity.sequence;
            if (embeddedActivities && embeddedActivities.length > 0) {
                embeddedActivities.filter(embed => embed.sequence < seq).forEach(embed => embed.sequence = --embed.sequence);
                this.setCurrentPhoneCallMeetingStructure(embeddedActivities);
            }
            seq = seq - 1;
        }
        return seq;
    }

    private getPhoneCallMeetingActivity(embeddedActivity: Activity, seq: number) {
        let embeddedInteraction: EmbeddedInteraction;
        if (embeddedActivity instanceof Activity) {
            embeddedInteraction = new EmbeddedInteraction(this.getActivityId(embeddedActivity),
            this.activityTitle(embeddedActivity), this.activityService.getActivityIcon(embeddedActivity),
            EmbeddedInteractionType.Activity, seq, embeddedActivity, this.getActivityIconName(embeddedActivity));
        }
        return embeddedInteraction;
    }


    public removeActivity(activity: Activity) {
        if (this.activityService.selectedActivity instanceof PhoneActivity) {
            const currentEmbeddedInteractions = this.currentPhoneCallMeetingStructureSource.getValue();
            if (currentEmbeddedInteractions && Array.isArray(currentEmbeddedInteractions)) {
                let filteredEmbeddedInteractions = currentEmbeddedInteractions.filter(embeddedInteraction => embeddedInteraction.id != this.getActivityId(activity))
                filteredEmbeddedInteractions = _.orderBy(filteredEmbeddedInteractions, "sequence", "desc");
                if (currentEmbeddedInteractions.length == 1) {
                    this.setCurrentPhoneCallMeetingActivity(null);
                } else {
                    const deletedActivityIndex = currentEmbeddedInteractions.findIndex(ei => ei.id === this.getActivityId(activity) && ei.type === EmbeddedInteractionType.Activity);
                    //check deleted activity is at last
                    if (deletedActivityIndex >= 0 && currentEmbeddedInteractions.length == deletedActivityIndex + 1) {
                        this.setCurrentPhoneCallMeetingActivity(currentEmbeddedInteractions[deletedActivityIndex - 1]);
                    } else {
                        this.setCurrentPhoneCallMeetingActivity(filteredEmbeddedInteractions[deletedActivityIndex]);
                    }
                }
                this.updateEmbeddedInteraction(this.reInitializeSequence(filteredEmbeddedInteractions));
            }
        }
    }

    public updateSequenceOnEmbeddedInteractionDrag(embeddedInteractions: EmbeddedInteraction[]) {
        if (embeddedInteractions && embeddedInteractions.length > 0) {
            let sequence = embeddedInteractions.length - 1;
            embeddedInteractions.forEach(eI => {
                eI.sequence = sequence;
                sequence--;
            });
        }
        this.updateEmbeddedInteraction(embeddedInteractions);
    }

}
