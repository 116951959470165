import { ChildNavNames } from '@omni/services/navigation/navigation.service';
import { EventName, EventsService } from './../../../services/events/events.service';
import { Component, OnInit, ViewChild, Input, ElementRef, Injector } from '@angular/core';
import { Presentation, Page, ContentMode, SwipeStatus } from '../../../classes/presentation/presentation.class';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { NavController } from '@ionic/angular';
import { PresentationView, UIService } from '../../../services/ui/ui.service'
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { OrderByPipe } from "./../../../pipes/orderby.pipe";
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { Subject, Subscription } from 'rxjs';
import { Resource } from '../../../classes/resource/resource.class';
import { IoEventListener } from '../../../services/io-event-listener.decorator';
import { PresentationDetailComponent } from '../presentation-detail/presentation-detail';
import { takeUntil } from 'rxjs/operators';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { WebsocketDataService } from '@omni/data-services/websocket/websocket.data.service';

@Component({
    selector: 'presentation-preview',
    templateUrl: 'presentation-preview.html',
  styleUrls:['presentation-preview.scss']
})
export class PresentationPreviewComponent implements OnInit {

    private _carousel: any;

    @ViewChild('carousel', {static: true}) set carousel(c: any) {
        if (this._carousel !== c) {
            this._carousel = c;
            this._carousel && this._carousel.updateCarousel();
        }
    }
    get carousel(): any {
        return this._carousel;
    }
    @ViewChild('carouselContainer', {static: true}) carouselContainer: ElementRef;

    @Input() viewMode: string;

    public presList: (Presentation | Resource)[];
    private _presData: Presentation | Resource;
    public set presData(val: Presentation | Resource) {
        if (val !== this._presData) {
            this._presData = val;
            // if (this.content) {
            //     this.content.resize();
            // }
        }
    }
    public get presData(): Presentation | Resource {
        return this._presData;
    }
    public selectedPageUrl: string;
    public selectedPage: Page;

    public PresentationView = PresentationView;

    public isLayoverPushView: boolean = false;
    public layoverViewPushedFrom: string;
    public layoverViewPassedData: any;

    public presMode: string;
    //@ViewChild(Content, {static: true}) content: Content;
    private currentCarouselBriefCaseSubscription: Subscription;
    private currentSelectedPreSubscription: Subscription;
    private currentSelectedPresPageSubscription: Subscription;
    private tempSwipeStatus: SwipeStatus;
    @Input() from: any;
    public pageName = PageName;

    public presentaionPreviewTitle: IndPageTitleViewDataModel;

    constructor(
        public injector: Injector,
        public presentationService: PresentationService,
        public navCtrl: NavController,
        public deviceService: DeviceService,
        public activityService: ActivityService,
        public footerService: FooterService,
        public events: EventsService,
        private navService: NavigationService,
        public uiService: UIService,
        public websocket: WebsocketDataService,
    ) {
        this.tempSwipeStatus = this.presentationService.swipeStatus;
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
        this.footerService.initButtons(FooterViews.Presentations);
        this.events.unsubscribe(EventName.PRESENTATION_DETAILS);
        this.events.subscribe(EventName.PRESENTATION_DETAILS, () => {
          this.openPresentationDetail();
        });
    }

  ngOnInit() {

    if (this.viewMode) {
      this.presMode = this.viewMode;
    } else if (this.from == 'PresentationToolsPage') {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = 'PresentationToolsPage';
      this.layoverViewPassedData = this.viewMode;
      this.presMode = this.layoverViewPassedData;
    } else if (this.from == 'DisplayValueComponent'){
      this.presMode = PresentationView.MENU;
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = 'DisplayValueComponent';
      this.layoverViewPassedData = this.viewMode;
    }

    let orderBy = new OrderByPipe();
    this.footerService.disableButton(['fullscreen']);

    this.currentCarouselBriefCaseSubscription = this.presentationService.carouselBriefcase.subscribe(pres => {
      this.presList = pres;
    });
    this.currentSelectedPreSubscription = this.presentationService.currentSelectedPres.subscribe(pres => {
      this.presData = pres;
      this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;

      if (this.presData && this.presData['presentationPages']) {
        this.presData['presentationPages'] = orderBy.transform(this.presData['presentationPages'], 'pageSequence', true)
      }
      if (this.presData) {
        if (this.presMode == PresentationView.MENU) {
          this.footerService.updateButtons(['presentaion_detail', 'fullscreen', 'play'], true);
          this.footerService.enableButtons(['presentaion_detail', 'fullscreen', 'play']);
        } else {
          if (this.presentationService.contentMode === ContentMode.PRESENTATION) {
            this.footerService.updateButtons(['presentaion_detail', 'fullscreen'], true);
            this.footerService.enableButtons(['presentaion_detail', 'fullscreen']);
          } else {
            this.footerService.updateButtons(['fullscreen'], true);
            this.footerService.enableButtons(['fullscreen']);
          }
          if (this.presentationService.contentMode === ContentMode.RESOURCE && this.presentationService.presPages[0].fileType === "unsupported") {
            this.footerService.initButtons(FooterViews.Resources);
          } else {
            this.footerService.initButtons(FooterViews.Presentations);
          }
        }
      } else {
        if (this.presMode == PresentationView.MENU) {
          this.footerService.updateButtons(['presentaion_detail', 'fullscreen', 'play'], true);
          this.footerService.disableButton(['presentaion_detail', 'fullscreen', 'play']);
        } else {
          this.footerService.disableButton(['fullscreen']);
          this.footerService.hideButton('play');
        }
      }
      this.events.publish('footerButtonsUpdated', this.pageName.PresentationPreviewComponent);
    });

    this.currentSelectedPresPageSubscription = this.presentationService.currentSelectedPresPage.subscribe(page => {
      this.selectedPage = page;
      this.selectedPageUrl = page ? page.pageUrl : "";
      this.initTitle();
    });
    // if (this.content) {
    //     this.content.resize();
    // }
    if (this.from == 'DisplayValueComponent'){
      this.footerService.hideButton('play');
    }
  }

  initTitle(){
  this.presentaionPreviewTitle = {
      id: 'presentation-preview',
      title: this.selectedPage?.name,
      controls: [{
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left',
      }],
    };

 
  }

    ngOnDestroy() {
        this.presentationService.swipeStatus = this.tempSwipeStatus;
        this.presentationService.presShowRightPane = false;
        this.currentCarouselBriefCaseSubscription.unsubscribe();
        this.currentSelectedPreSubscription.unsubscribe();
        this.currentSelectedPresPageSubscription.unsubscribe();
    }

    public goBack() {
        if(this.from == 'DisplayValueComponent'){
          this.presentationService.setCurrentSelectedPres(null);
          this.navService.popChildNavPageWithPageTracking();
        }else{
          this.presentationService.setCurrentSelectedPres(null);
          this.navService.popChildNavPageWithPageTracking();
          this.presentationService.presShowRightPane = false;
        }
    }

    @IoEventListener("window:message", message => message.type === 'io-spa' && message.action === 'tracking-event', message => [message.data])
    public onTrackingEvent(eventData) {
        let { indeTrack, indeGoal, elementId, eventType } = eventData;
        let _class = eventData['class'];
        let events: { type: string, value: string }[] = [
            ...(elementId ? [{ type: 'ID', value: elementId }] : []),
            ...(_class ? [{ type: 'CLASS', value: _class }] : []),
            ...(indeTrack ? [{ type: 'TRACK', value: indeTrack }] : []),
            ...(indeGoal ? [{ type: 'GOAL', value: indeGoal }] : []),
        ];
        let caseInsensitiveEquals = (a: string, b: string) => (a || '').localeCompare(b || '', undefined, { sensitivity: 'accent' }) === 0;
        if (this.presentationService.activePresentation instanceof Presentation) {
            let page = this.presentationService.activePresentation.virtualPages.find(page =>
                events.findIndex(ev =>
                    caseInsensitiveEquals(ev.type, page.indskr_virtualpagetype) && caseInsensitiveEquals(ev.value, page.indskr_virtualpagevalue)) >= 0);
        }

        // Virtual page navigation is not allowed within the iframe
        // App needs to handle the page navigation triggered by virtual event
        switch (eventType) {
            case 'Redirect':
              this.presentationService.handleVirtualPageRedirect(this.presentationService.activePresentation as Presentation, eventData);
              break;

            default:
                break;
        }
    }

    @IoEventListener("window:message", message => message.type === 'io-spa' && message.action === 'page-init', message => [message.data])
    onSlidePageInit(initData) {
        if (this.presMode === PresentationView.ADDTOMEETING) {
            this.footerService.updateSwipeButtonState(this.pageName.PresentationPreviewComponent);
            this.events.publish('footerButtonsUpdated', this.pageName.PresentationPreviewComponent);
        }
    }

  private async openPresentationDetail() {
    this.uiService.showNewActivity = false;
    let pres = this.presData ? this.presData : this.presentationService.activePresentation;
    this.navService.pushChildNavPageWithPageTracking(PresentationDetailComponent, PageName.PresentationDetailComponent, PageName.PresentationPreviewComponent, { from: 'PresentationPreview', selectedPresentation: pres });
  }
}
