import { Research } from './../../../classes/contact/scientific-info.class';
import { MainCardViewDataModel } from './../../../models/MainCardViewDataModel';
import { EventsService } from './../../../services/events/events.service';
import { Component, ViewChild } from '@angular/core';
import {NavigationService} from "../../../services/navigation/navigation.service";
import {RepServices} from "../../../data-services/rep/rep.services";
import {UIService} from "../../../services/ui/ui.service";
import {ContactOfflineService} from "../../../services/contact/contact.service";
import {
  AttachmentDetail,
  Publication,
  PublicationPreview,
  ScientificInfo
} from "../../../classes/contact/scientific-info.class";
import {ContactDataService} from "../../../data-services/contact/contact.data.service";
import {DeviceService} from "../../../services/device/device.service";
import {NotificationService, ToastStyle} from "../../../services/notification/notification.service";
import {LoadingController, ModalController} from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { FooterService } from '@omni/services/footer/footer.service';

/**
 * Generated class for the ContactHistoryComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'scientific-info-details',
  templateUrl: 'scientific-info-details.html',
  styleUrls:['scientific-info-details.scss']
})
export class ScientificInfoDetailsComponent {

  public scientificInfo: ScientificInfo;
  public isExpanded: boolean = false;
  public scientificInfoDetailsPanePageTitle: IndPageTitleViewDataModel;

  //@ViewChild(Content, {static:true}) content: Content

  constructor(
    private navService: NavigationService,
    public repService: RepServices,
    public uiService: UIService,
    private device: DeviceService,
    public contactService: ContactOfflineService,
    public contactDataService: ContactDataService,
    public notificationService: NotificationService,
    private loadingCtrl: LoadingController,
    public translate:TranslateService,
    public modalCtrl: ModalController,
    public events: EventsService,
    public footerService: FooterService
  ) {
    this.events.subscribe('closeScientInfoModal', ()=>this.closePage());
  }

  ngOnInit() {
    this.scientificInfo = this.contactService.contactInformation.scientificInfo;
    //this.content.resize();
    this.initPageTitle();
  }

  closePage() {
    this.contactService.scientInfoModalOpen = false;
    this.modalCtrl.dismiss()
  }

  expandOrCollapse(obj: Object) {
    if (obj['isExpanded']) {
      obj['isExpanded'] = false;
    } else {
      this.checkIfItemExpandedOrCollapsed(obj);
    }
  }

  checkIfItemExpandedOrCollapsed(obj: any) {
    if (this.uiService.scientificInfoView == 'Publications') {
      this.mapExpandOrCollapse(obj, this.scientificInfo.publications);
    } else if (this.uiService.scientificInfoView == 'Research') {
      this.mapExpandOrCollapse(obj, this.scientificInfo.researches);
    } else if (this.uiService.scientificInfoView == 'Speaker Engagements') {
      this.mapExpandOrCollapse(obj, this.scientificInfo.speakerEngagements);
    } else if (this.uiService.scientificInfoView == 'Event Attendance') {
      this.mapExpandOrCollapse(obj, this.scientificInfo.eventHistory);
    } /*else if (this.uiService.scientificInfoView == 'Session History') {
      this.mapExpandOrCollapse(obj, this.scientificInfo.sessionHistory);
    }*/
  }

  mapExpandOrCollapse(obj: Object, objs: Object[]) {
    objs.map(listItem => {
      if (obj == listItem) {
        listItem['isExpanded'] = !listItem['isExpanded'];
      } else {
        listItem['isExpanded'] = false;
      }
      return listItem;
    });
  }

  async previewPublication(publicationId, atc: AttachmentDetail) {
    if (this.device.isOffline) return;
    const loader =await this.loadingCtrl.create();
    loader.present();
    this.contactDataService.getPublicationPreviewById(publicationId, atc.indskr_attachmentid)
      .then((res: PublicationPreview) => {
        if (res) {
          this.uiService.showNewActivity = false;
          this.uiService.prevView = 'scientificInfoDetails';
          this.uiService.activeView = 'publicationPreview';
          this.uiService['preview'] = res;
          this.uiService['preview'].indskr_attachmentname = atc.indskr_attachmentname;
        } else {
          this.notificationService.notify(
            this.translate.instant('SCIENTIFIC_NO_PRE_AVAIL'),
            "Publication",
            "top",
            ToastStyle.DANGER,
            3000,
            true
          );
        }
        loader.dismiss();
    }).catch(err => {
      console.error("error occurred while previewing publication", err);
    });
  }

  public getPublicationsMainCardDataModel(pub: Publication): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    if(pub){
      viewData= {
        id: pub.indskr_publicationsid,
        fixedHeight: true,
        primaryTextRight:pub.indskr_name,
        // isExpandable: true,
        // isExpanded: pub['isExpanded'],
        // expandableViewType: 'activitiesList',
        // showArrow: true,
        // arrowType: 'chevron-forward-outline',
        noSeparationLine: true,
        isAccordionForm: true,
        showAccordionIcon: true,
        accordionIconType:  pub['isExpanded'] ?'accordion-minus' : 'accordion-add',
        isAccordionText: pub['isExpanded'],
        clickHandler:(id: string, event) => {
          this.expandOrCollapse(pub);
        }
      };
    }
    return viewData;
  }

  public getResearchMainCardDataModel(res: Research): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    if(res){
      viewData= {
        id:res.indskr_name,
        fixedHeight: true,
        primaryTextRight:res.indskr_name,
        // isExpandable: true,
        // isExpanded: res['isExpanded'],
        // expandableViewType: 'activitiesList',
        // showArrow: true,
        // arrowType: res['isExpanded'] ? 'chevron-up-outline': 'chevron-down-outline',
        noSeparationLine: true,
        isAccordionForm: true,
        showAccordionIcon: true,
        accordionIconType:  res['isExpanded'] ?'accordion-minus' : 'accordion-add',
        isAccordionText: res['isExpanded'],
        clickHandler:(id: string, event) => {
          this.expandOrCollapse(res);
        }
      };
    }
    return viewData;
  }

  private initPageTitle(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    });
    this.scientificInfoDetailsPanePageTitle = {
      id: 'scientific-info-details',
      title: this.uiService.scientificInfoView,
      controls: buttons,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

}
