import { PipeTransform, Pipe } from '@angular/core';
import { from } from 'rxjs';
import { OrderManagementService } from '../services/order-management/order-management.service';
import { OrderActivity } from '../classes/activity/order.activity.class';
import { AuthenticationService } from '../services/authentication.service';

@Pipe({
    name: 'orderListFilter',
    pure: true
})
export class OrderListFilter implements PipeTransform {
    constructor(
        private orderManagermentService: OrderManagementService,
        private authenticationService: AuthenticationService
    ) {

    }

    transform(items: Array<OrderActivity>, filterBy:{statusString: string, products: string, searchText: string}, team?: boolean, searchQuery? : string) {
        //Only show team call plans
        let returnItems
        if (team) {
          returnItems = items.filter(order => order.isTeamOrder && order.orderStatusString !="Draft" && !order.isHardDeleted
                        && this.authenticationService.user.childUsers.some(user => user.userId == order.ownerId));
        } else {
          returnItems = items.filter(order => !order.isTeamOrder && !order.isHardDeleted);
        }
        // single select cproduct filter logic getting obsolete, replacign with multi-select logic
        //if (this.callPlanService.callPlanFilter === 'All Products') return items;
        //if (this.callPlanService.multiProductSelectionFilter && !this.callPlanService.multiProductSelectionFilter.length) return items;

        if(filterBy.statusString){
          returnItems = returnItems.filter(order=> order.orderStatusString == filterBy.statusString)
        }
        if(filterBy.products) returnItems = returnItems.filter(order => order.products.some(p=>p.productId == filterBy.products))//multiProductSelectionFilter.indexOf(callPlan.productID)>-1);

        if(filterBy.searchText){
          returnItems = returnItems.filter((o)=>{
            return o.orderNumber.toLowerCase().indexOf(filterBy.searchText.trim().toLowerCase())>-1
                  || (team?o.ownerNameString.toLowerCase().indexOf(filterBy.searchText.trim().toLowerCase())>-1
                      : o.accountNameString.toLowerCase().indexOf(filterBy.searchText.trim().toLowerCase())>-1)
          })
        }

        return returnItems;
      }
}
