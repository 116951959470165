import { AuthenticationService } from './../../../services/authentication.service';
import { AccountPlansSelectionPageModel } from "@omni/models/account-plans-selection-page-model";
import { ActivityService } from '@omni/services/activity/activity.service';

import { Component, OnDestroy, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoadingController, NavParams } from '@ionic/angular';
import { isFuture, isPast } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from '../../../classes/account/account.class';
import { RepServices } from '../../../data-services/rep/rep.services';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { DeviceService } from '../../../services/device/device.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { Utility } from '../../../utility/util';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { AccountPlanDetails } from '../account-plan-details/account-plan-details';
import { AccountPlan, AccountPlanMode } from './../../../classes/account-management/account-plan.class';
import { AccountManagementDataService } from './../../../data-services/account-management/account-management.data.service';
import { EventsService } from './../../../services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { IndTabsDataModel } from '../../../models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { Subscription } from 'rxjs';
import { MultiSelectPopover } from "../../multi-select-popover/multi-select-popover";
import { PopoverController } from "@ionic/angular";
import { OrderByPipe } from "../../../pipes/orderby.pipe";
import * as _ from 'lodash';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { NewAccountPlanComponent } from '../new-account-plan/new-account-plan';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
@Component({
  selector: 'account-plan-list',
  templateUrl: 'account-plan-list.html',
  styleUrls: ['account-plan-list.scss']
})
export class AccountPlanList implements OnInit, OnDestroy {
  @Input() viewData: AccountPlansSelectionPageModel;

  @Output() closeModal = new EventEmitter();
  @Output() selectAccount = new EventEmitter<any>();
  @Input() accountListAccessedFrom: string;

  public selectedAccount: Account;
  plansData: any;
  selectedAccountID: string;
  ngUnSubscribe$ = new Subject<boolean>();
  public pastHeaderModel: IndSectionHeaderViewDataModel;
  public currentHeaderModel: IndSectionHeaderViewDataModel;
  public futureHeaderModel: IndSectionHeaderViewDataModel;

  tabs: IndTabsDataModel[] = [];
  private subs: Subscription[] = [];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  private allAccountsForAccountPlans: Account[];
  public selectedPlanId: string;
  private rawPlanData: any;
  private searchedData: any;
  private filteredAccountPlansData: any;
  private searchedDataAfterFilter: any;
  public isSearched: boolean = false;
  private isFiltered: boolean = false;
  private _accountPlansFilterPopover;
  filterPopoverData: any[];
  public accountPlansFilter = { status: "", account: [] };
  public accountFilterList: any[] = []
  private preUpdatedSubAccountNumber: number = 0;
  filterBadgeCount: number = 0;
  public fromPlanTab: boolean = false;
  public accountPlanKeyControlAreaModel: IndKeyControlsAreaModel;
  public searchInput = '';

  public selectedItems:Array<MainCardViewDataModel> = [];
  public selectableItems:Array<MainCardViewDataModel> = [];
  public isReadOnlyMode:boolean = false;
  public selectedAccPlansHeader:IndSectionHeaderViewDataModel;
  public selectableAccPlansHeader:IndSectionHeaderViewDataModel;
  private _isDirty:boolean = false;
  public hasAccountPlanCreation: boolean = false;
  public compViewMode = ComponentViewMode;

  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public uiService: UIService,
    public accountManagementOfflineService: AccountManagementOfflineService,
    public navParams: NavParams,
    private readonly accountService: AccountOfflineService,
    public accountManagementDataService: AccountManagementDataService,
    private readonly navService: NavigationService,
    public laodingCtrl: LoadingController,
    public trackingService: TrackService,
    public events: EventsService,
    public readonly translate: TranslateService,
    private popover: PopoverController,
    public activityService: ActivityService,
    public footerService: FooterService,
    private readonly authService: AuthenticationService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
    this.hasAccountPlanCreation = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_PLAN_CREATION);
  }

  ngOnInit() {
    console.log(this.viewData);
    if (this.accountManagementOfflineService.accountPlans) {
      this.accountService.createAccountplanAccountsTree(this.accountManagementOfflineService.accountPlans);
      this.allAccountsForAccountPlans = this.accountService.accountsTreeForOtherTool;
      this.recursiveAccountList();
      let accountPlansData = [];
      this.allAccountsForAccountPlans.forEach(ac => {
        accountPlansData.push(...this.accountManagementOfflineService.accountPlans.filter(ap => ap.accountId === ac.id));
      })
      this.rawPlanData = accountPlansData;
      this.updateAccountPlansList(accountPlansData);
    }

    this.accountManagementOfflineService.accountPlans$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.length) {
        this.allAccountsForAccountPlans = [];
        this.accountService.createAccountplanAccountsTree(this.accountManagementOfflineService.accountPlans);
        this.allAccountsForAccountPlans = this.accountService.accountsTreeForOtherTool;
        this.recursiveAccountList();
        let accountPlansData = [];
        this.allAccountsForAccountPlans.forEach(ac => {
          accountPlansData.push(...data.filter(ap => ap.accountId === ac.id));
        })
        this.rawPlanData = accountPlansData;
        if (this.isSearched) {
          accountPlansData = this.isFiltered ? this.searchedDataAfterFilter : this.searchedData;
        } else if (this.isFiltered) {
          accountPlansData = this.filteredAccountPlansData;
        }
        this.updateAccountPlansList(accountPlansData);
        // account plan cancel specific calls
        this.onSearch();
        this.getFilterCount();
        if ((this.isFiltered)) this.filterAccountPlans();
        // account plan cancel specific calls

        this.intializeheaders();
      }
    });

    this.accountManagementOfflineService.selectedAccountPlan$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data) {
        this.openAccountPlan(data, this.accountManagementOfflineService.selectedMode === AccountPlanMode.NEW);
      }
    })

    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan == 'accountPlansTab') this.fromPlanTab = true;
    this.tabs = this.uiService.getAgendaTabs();
    this.subs.push(this.device.isOfflineObservable.subscribe(isOffline => {
      this.tabs = this.uiService.getAgendaTabs();
      this._initKeyControlsAreaModel();
    }))

    this.events.observe("sync:completed")
      .subscribe(() => {
        this.tabs = this.uiService.getAgendaTabs();
      });
    if (this.viewData.viewMode != this.compViewMode.LISTVIEW) {
      this.isReadOnlyMode = this.viewData.isReadOnlyMode;
      this._initSelectedViewItems();
      this._initSelectableViewItems();
    }
    this.initFilter();
    this.initAccountPlansHeaderLeft();
    this.intializeheaders();
    this._initKeyControlsAreaModel();
  }

  private _initSelectedViewItems() {
    if(this.viewData.selectedAccPlans){
      this.selectedItems = this.viewData.selectedAccPlans.map(event => {
        return {
          id: event.ID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: true,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          primaryTextRight: event.accountPlanName,
          showArrow: false,
          arrowType: '',
          clickHandler: (id: string, event,eventName,refData) => this._handlePlanItemClick(id,event,eventName,refData),
        };
      });
      this._initSelectedHeader();
    }
  }

  private _initSelectableViewItems() {
    if(this.viewData.selectedAccPlans){
      this.selectableItems = this.viewData.selectableAccPlans.map(event => {
        const isSelected = this.viewData.selectedAccPlans.some(a=> a.ID == event.ID);
        return {
          id: event.ID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: true,
          endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
          endIconCssClass: isSelected?'checkmark-icon':'add-icon',
          isSelected: isSelected,
          primaryTextRight: event.accountPlanName,
          showArrow: false,
          arrowType: '',
          clickHandler: (id: string, event,eventName,refData) => this._handlePlanItemClick(id,event,eventName,refData),
        };
      });
      this._initSelectedHeader();
    }
  }

  _handlePlanItemClick(id, event, eventName, refData) {
    if (id && eventName) {
      if (eventName == 'mainItem') {
        const foundItem = this.accountManagementOfflineService.accountPlans.find(a => {
            if (a.ID) {
              return a.ID === id;
            } else {
              return false;
            }
        });
        this.accountManagementOfflineService.selectedMode = AccountPlanMode.VIEW;
        this.accountManagementOfflineService.selectedAccountPlan$.next(foundItem);
      }else if(eventName == 'endIcon'){
        if(refData && refData.showEndIcon){
          switch (refData.endIconType){
            case 'indegene-selectors-add-icon':
              this._handleAddAccPlans(id);
              break;
            case 'indegene-selectors-remove-icon':
              this._handleRemoveAccPlans(id);
              break;
          }
        }
      }
    }
  }

  private _handleAddAccPlans(id){
    let foundItem;
    foundItem = this.selectableItems.find(a=> a.id == id);
    if(foundItem){
      let isAlreadyAddedIndex = this.selectedItems.findIndex(a=> a.id == id);
      if(isAlreadyAddedIndex < 0){
        foundItem.endIconType = 'indegene-selectors-checkmark-icon';
        foundItem.endIconCssClass = 'checkmark-icon';

        this.selectedItems.push({
          id: foundItem.id,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: true,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          primaryTextRight: foundItem.primaryTextRight,
          showArrow: false,
          arrowType: '',
          clickHandler: (id: string, event, target, data) => this._handlePlanItemClick(id, event, target, data),
        });
      }
      this._initSelectedHeader();
      this.initAccountPlansHeaderLeft();
    }
  }

  private _handleRemoveAccPlans(id){
    let foundItemIndex;
    foundItemIndex = this.selectedItems.findIndex(a=> a.id == id);
    if(foundItemIndex >= 0){
      this.selectedItems.splice(foundItemIndex,1);
      let listItem = this.selectableItems.find(a=> a.id == id);
      if(listItem){
        listItem.endIconType = 'indegene-selectors-add-icon';
        listItem.endIconCssClass = 'add-icon';
      }
      this._initSelectedHeader();
      this.initAccountPlansHeaderLeft();
    }
  }

  private _initSelectedHeader() {
    this.selectedAccPlansHeader = {
      id: 'selected-events-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: [],
    };
    this.selectableAccPlansHeader = {
      id: 'selected-events-header',
      title: this.translate.instant('ACCOUNT_PLANS') + ' (' + this.selectableItems.length + ')',
      controls: [],
    };
  }

  initFilter() {
    let orderBy = new OrderByPipe();
    this.filterPopoverData = [
      {
        text: '',
        selectedValues: ['0'],
        value: '0',
        items: [
          { text: this.translate.instant('ALL_ACCOUNT_PLANS'), value: '0' }
        ],
        handler: (selectedItem, item, itemRef) => {
          if (itemRef && itemRef.parent && itemRef.parent.items[1]) {
            itemRef.parent.items[1].deSelectAll();
          }
          this.filterBadgeCount = 0;
          item.value = selectedItem.value;
          this.accountPlansFilter = { ...this.accountPlansFilter, account: [] };
          this.accountFilterList = [];
          this.filteredAccountPlansData = [];
          this.updateAccountPlansList(this.rawPlanData);
          this.isFiltered = false;
          this.initAccountPlansHeaderLeft();
          this.intializeheaders();
        }
      },
      {
        text: this.translate.instant('ACCOUNT'),
        value: '',
        selectedValues: [],
        multiselect: true,
        items: orderBy.transform(this.allAccountsForAccountPlans.map(ac => ({ text: ac.accountName, value: ac.id })), "text", true),
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          if (itemRef && itemRef.parent && itemRef.parent.items && itemRef.parent.items[0] && itemRef.parent.items[0].items && itemRef.parent.items[0].items[0]) {
            itemRef.parent.items[0].value = undefined;

            itemRef.parent.items[0].items[0].checked =
              itemRef.parent.items[0].items[0].item.checked = false;

            itemRef.parent.items[0].items[0].selectedValues =
              itemRef.parent.items[0].items[0].item.selectedValues = [];
          }
          this.onAccountFilterChange(selectedItem, item, itemRef, selected);
          this.initAccountPlansHeaderLeft();
          this.intializeheaders();
        }
      },
    ];
  }

  ngOnDestroy() {
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
    this.fromPlanTab = false;
    this.events.unsubscribe('cancelledAccountplan')
    if (this.uiService.activeViewOnPlanTab == 'planProgressReports') this.uiService.activeViewOnPlanTab = undefined;
  }

  intializeheaders() {
    this.initializePastHeader();
    this.initializeCurrentHeader();
    this.initializeFutureHeader();
  }

  public getAccountPlanmainCardModel(accountPlan: AccountPlan): MainCardViewDataModel {
    let totalCompletedPercent = 0;
    let numberOfaccountPlanObj = 0;
    let avgTotalCompletedPercent = 0;
    if (accountPlan.accountPlanObjectives.length != 0) {
      for (let i = 0; i < accountPlan.accountPlanObjectives.length; i++) {
        if (accountPlan.accountPlanObjectives[i].completedPercent > 0) {
          totalCompletedPercent = accountPlan.accountPlanObjectives[i].completedPercent + totalCompletedPercent;
          numberOfaccountPlanObj++;
        }
      }
      avgTotalCompletedPercent = (totalCompletedPercent / numberOfaccountPlanObj);
    }
    return {
      id: accountPlan.ID,
      fixedHeight: true,
      primaryTextLeft: '',
      primaryTextRight: accountPlan.accountPlanName,
      isPrimaryTextRightPillText: (avgTotalCompletedPercent ? avgTotalCompletedPercent.toFixed(0) : 0) + '%',
      isPrimaryTextRightPillType: true,
      secondaryTextRight: accountPlan.accountName,
      isSecondaryTextRightPillType: false,
      isSelected: this.selectedPlanId == accountPlan.ID,
      showArrow: false,
      showEndIcon: accountPlan.status == 'Completed',
      endIconCssClass: 'completed-icon',
      isSecondSrcAttribute:true,
      showSecondEndIcon : !this.device.isOffline,
      secondEndIconType: 'assets/imgs/horizontal-menu-filled.svg',
      secondEndIconCssClass : 'showOnHover',
      // arrowType: 'chevron-forward',
      clickHandler: async (id: string, event,target) => {
        const clonePopover = await this.accountManagementOfflineService.showCloneDropDown(id, event);

        if(this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT && this.accountManagementOfflineService.tempReqPayload !== undefined){
          let response = await this.accountManagementOfflineService.showConfirmAlert();
          if(!response) return;
        }

        if(target === 'mainItem'){
          this.accountManagementOfflineService.selectedMode = AccountPlanMode.VIEW;
          this.accountManagementOfflineService.selectedAccountPlan$.next(accountPlan);
        } else if(target === 'secondEndIcon'){
            this.openCloneDropDown(id,clonePopover)
        }
      },
    };
  }

  async openCloneDropDown(id, clonePopover) {
    // const clonePopover = await this.accountManagementOfflineService.showCloneDropDown(id, event);

    clonePopover.onDidDismiss().then(async ({ data }: any) => {
      if (data && data.isDone) this.cloneAccountPlan(id);
    });

    clonePopover.present();
  }

  async cloneAccountPlan(id){
    let reqPayload = await this.accountManagementOfflineService.cloneAccountPlan(id);
    this.uiService.displayLoader();
    this.accountManagementDataService.saveAccountPlan(reqPayload).then(async (accountPlan) => {
      await this.accountManagementDataService.getAccountPlans(false, accountPlan.ID);
      accountPlan = _.cloneDeep(this.accountManagementOfflineService.accountPlans.find(o => o.ID === accountPlan.ID));
      this.accountManagementOfflineService.selectedMode = AccountPlanMode.EDIT;
      this.accountManagementOfflineService.selectedAccountPlan$.next(accountPlan);
      this.uiService.dismissLoader();
    });
  }

  private _initKeyControlsAreaModel() {
    if (this.hasAccountPlanCreation) {
      let btns = [];
      if(this.viewData.viewMode == this.compViewMode.LISTVIEW) {
        btns.push({
          id: 'create-btn',
          text: this.translate.instant('CREATE'),
          isDisabled: this.device.isOffline,
          leftAligned: false
        });
      }
      this.accountPlanKeyControlAreaModel = {
        id: 'accounts-list-key-controls-area',
        eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
        controls: btns
      };
    }
  }

  private async handleControlsAreaEvent(id, event, eventName) {
    if(this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT && this.accountManagementOfflineService.tempReqPayload !== undefined){
      let response = await this.accountManagementOfflineService.showConfirmAlert();
      if(!response) return;
    }

    if (id == 'create-btn') {
      this.selectedPlanId = null;
      this.accountManagementOfflineService.isRightPaneNavActive = true;
      this.navService.setChildNavRoot(NewAccountPlanComponent, PageName.NewAccountPlanComponent, PageName.AccountManagementPageComponent);
    }
  }

  private initializePastHeader() {
    this.pastHeaderModel = {
      id: 'account-plan-past-header',
      title: `${this.translate.instant('PAST')} (${this.plansData.past.length})`,
      controls: [],
    };
  }

  private initializeCurrentHeader() {
    this.currentHeaderModel = {
      id: 'account-plan-current-header',
      isHighlighted: true,
      title: `${this.translate.instant('CURRENT')} (${this.plansData.present.length})`,
      controls: [],
    };
  }

  private initializeFutureHeader() {
    this.futureHeaderModel = {
      id: 'account-plan-future-header',
      title: `${this.translate.instant('FUTURE')} (${this.plansData.future.length})`,
      controls: [],
    };
  }

  getGroupedData(list) {
    const formattedAccountPlanObj = {
      past: [],
      present: [],
      future: []
    };
    if (list && list.length > 0) {
      list.map(o => {
        o.accountPlanTimeFrame = isPast(parseInt(o.endDate)) ? 'Past'
          : isFuture(parseInt(o.startDate)) ? 'Future' : 'Present';
        if (o.accountPlanTimeFrame === 'Past') {
          formattedAccountPlanObj.past.push(o);
          return;
        }
        if (o.accountPlanTimeFrame === 'Present') {
          formattedAccountPlanObj.present.push(o);
          return;
        }
        formattedAccountPlanObj.future.push(o);
      });
    }

    return formattedAccountPlanObj;
  }

  public async openAccountPlan(plan: AccountPlan, isNew: boolean = false): Promise<void> {
    this.selectedPlanId = plan.ID;
    this.trackingService.tracking('AccountPlanClicked', TrackingEventNames.ACCOUNTPLANNING)
    if (!this.device.isOffline) {
      if (isNew) {
        this.uiService.displayLoader();
        // await this.accountManagementDataService.getAccountPlans(false, plan.ID);
      } else {
        this.accountManagementDataService.getAccountPlans(false, plan.ID);
      }
      await this.uiService.dismissLoader();
    }
    if (this.uiService.activitiesPagePlanTabSelectedPlan == 'accountPlansTab') {
      if (this.uiService.activitiesPageTab == 'plans') this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      if (this.uiService.activeViewOnPlanTab == 'planProgressReports') this.uiService.activeViewOnPlanTab = undefined;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent, {
        deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
      });
      this.navService.pushChildNavPageWithPageTracking(AccountPlanDetails, PageName.AccountPlanDetailsComponent, PageName.AccountManagementPageComponent,
        { data: plan });
      this.uiService.showRightPane = true;
    } else {
      if (isNew) {
        const popoverData = this.filterPopoverData.find(popoverData => popoverData.text == this.translate.instant('ACCOUNT'));
        if (popoverData)
          popoverData.items = new OrderByPipe().transform(this.allAccountsForAccountPlans.map(ac => ({ text: ac.accountName, value: ac.id })), "text", true);
      }
      // this.accountManagementOfflineService.selectedMode = isNew ? AccountPlanMode.NEW : AccountPlanMode.VIEW;
      this.selectAccount.emit({ plan: plan, selectedMode: this.accountManagementOfflineService.selectedMode });
      // this.navService.pushChildNavPageWithPageTracking(AccountPlanDetails,PageName.AccountPlanDetailsComponent,PageName.AccountManagementPageComponent,{data:plan});
    }
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  // public backToAccountManagement(): void{
  //     this.uiService.showRightPane = false;
  //     this.accountManagementOfflineService.isRightPaneNavActive = false;
  //     this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.AccountManagementPageComponent,{
  //       deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
  //     });
  //     setTimeout(()=>{
  //       this.events.publish('accountListContentResize');
  //     },0);
  // }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
  }

  private initAccountPlansHeaderLeft(): void {
    let buttons = [];
    if(this.viewData.viewMode == this.compViewMode.LISTVIEW) {
      buttons.push({
        id: "close",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      }); 
    } else {
      if(this.viewData.viewMode ==this.compViewMode.SELECTION || this.viewData.viewMode == this.compViewMode.READONLY){
        buttons.push({
          id: "close",
          imgSrc: 'assets/imgs/header_cancel.svg',
          cssClass: (this.viewData.viewMode===this.compViewMode.SELECTION || this.viewData.viewMode === this.compViewMode.READONLY) ?'seventyPercentWidth' : '',
          isDisabled: false,
          align: "left",
        });
      }
    }
    if (this.viewData.viewMode == this.compViewMode.SELECTION) {
      buttons.push({
        id: "done",
        imgSrc: 'assets/imgs/header_complete.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: _.xor(this.viewData.selectedAccPlans.map(a=> a.ID),this.selectedItems.map(b=> b.id)).length == 0,
        align: "right",
      });
    }
    this.indHeaderLeftModel = {
      id: 'account-plan-list-header-left',
      cssClass: 'main-tool-header-title',
      // title: this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab === 'plans' ? this.translate.instant('Home') : this.translate.instant('ACCOUNT_PLANS'),
      title: this.getPlaceHolder(),
      mode: true,
      isOnActPage: this.fromPlanTab ? true : false,
      controls: buttons,
    };
  }

  getPlaceHolder(): string {
    let str = '';
    if (!this.isFiltered) {
      str = this.fromPlanTab ? this.translate.instant('HOME') : str = this.translate.instant('ACCOUNT_PLANS');
    } else if (this.isFiltered && this.accountFilterList.length > 0) {
      let filteredAccountName = [];
      this.accountFilterList.forEach(filteredId => {
        filteredAccountName.push(...this.allAccountsForAccountPlans.filter(ac => ac.id === filteredId));
      })
      if (filteredAccountName.length == 1) {
        str = filteredAccountName[0].accountName;
      } else {
        filteredAccountName.forEach(ac => {
          str = ac.accountName + ', ' + str;
        });
      }
    } else {
      str = this.translate.instant('ACCOUNT_PLANS');
    }
    return str;
  }

  async goBack() {
    this.isFiltered = false;
    this.isSearched = false;
    this.filteredAccountPlansData = [];
    this.searchedData = [];
    this.uiService.toolsActivityActive = false;
    this.accountManagementOfflineService.isRightPaneNavActive = false;
    this.trackingService.tracking('AccountManagementBack', TrackingEventNames.ACCOUNTPLANNING)
    this.uiService.activeView = '';
    if (this.uiService.activitiesPageTab === 'plans') {
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      // this.uiService.activitiesPagePlanTabSelectedPlan = 'accountPlansTab';
    }

    if(this.accountManagementOfflineService.selectedForGoalTab){
      this.accountManagementOfflineService.selectedMode = AccountPlanMode.VIEW;
      this.events.publish('account-plan-mode-changed');
      this.accountManagementOfflineService.selectedAccountPlan$.next(this.accountManagementOfflineService.selectedForGoalTab);
    }

    if (this.navService.getCurrentPageName() === PageName.AccountManagementPageComponent) {
      this.accountService.accessedAccountListFrom = null;
      this.accountService.selected = undefined;
      if(this.navService.getPreviousPageName() !== PageName.ActivitiesPageComponent){
        await this.navService.popWithPageTracking().then(() => {
          this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
          if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
        }); 
      }else{
        this.uiService.showRightPane = false;
        this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
        await this.navService.popToRootWithPageTracking().then(() => {
          if (this.activityService.selectedActivityAtHome) {
            this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
            if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
            // if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) this.footerService.initButtons(FooterViews.EmailDetails);
            if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) this.events.publish('update-email-footer');
            if (this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) this.footerService.initButtons(FooterViews.PHONE_CALL);
            if (this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
            if (this.navService.getActiveChildNavViewPageName() === PageName.NewSampleActivityComponent) this.footerService.initButtons(FooterViews.SampleDropDetails);
          }
          else if (this.uiService.activitiesPageTab === 'plans') {
            if (this.navService.getActiveChildNavViewPageName() === PageName.PlanProgressReportComponent) this.events.publish('resetPlanProgressReportFooter');
            else this.footerService.initButtons('');
          }
        });
      }
      
      
    }
  }

  private logicToRunAfterPopToRootWhenAccessedFromToolsDrawer() {
    // Short call feature requires to bring the tab back to short call home
    if (this.faService.isShortCallLauncherEnabled) {
      this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
      this.uiService.setAgendaTab('shortCallHome');
    }
  }

  public async  onPageTitleControlClick(id: string) {
    if(this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT && this.accountManagementOfflineService.tempReqPayload !== undefined){
      let response = await this.accountManagementOfflineService.showConfirmAlert();
      if(!response) return;
    }

    switch (id) {
      case 'close':
        this.goBack();
        break;
      case 'done':
        let newSelectedPlans = this.selectedItems.map(a=> {
          return this.accountManagementOfflineService.getAccountPlanByPlanId(a.id);
        });
        this.viewData.callbackFromSelectionComponent('DONEBUTTONCLICK', newSelectedPlans, null)
        this.goBack();
        break;
      default:
        break;
    }
  }

  updateAccountPlansList(data: any) {
    this.plansData = this.getGroupedData(data.sort((a, b) => {
      a.accountPlanName > b.accountPlanName ? 1 : -1;
    }));
    this.intializeheaders();
  }

  onSearch() {
    if (this.searchInput && this.searchInput.trim().length > 0) {
      this.isSearched = true;
      if (this.isFiltered) {
        if (this.viewData.viewMode == this.compViewMode.LISTVIEW) {
          this.searchedDataAfterFilter = this.filteredAccountPlansData.filter((item) => {
            return (item.accountPlanName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1) || (item.accountName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
          });
          this.updateAccountPlansList(this.searchedDataAfterFilter);
        } else {
          this.searchedDataAfterFilter = this.selectableItems.filter((item) => {
            return (item.accountPlanName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1) || (item.accountName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
          });
          this._initSelectedHeader();
        }
      } else {
        if (this.viewData.viewMode == this.compViewMode.LISTVIEW) {
          this.searchedData = this.rawPlanData.filter((item) => {
            return (item.accountPlanName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1) || (item.accountName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
          });
          this.updateAccountPlansList(this.searchedData);
        } else {
          this.searchedData = this.selectableItems.filter((item) => {
            return (item.accountPlanName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1) || (item.accountName.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
          });
        }
      }
    } else {
      this.isSearched = false;
      if (this.viewData.viewMode == this.compViewMode.LISTVIEW) {
        if (this.isFiltered) {
          this.updateAccountPlansList(this.filteredAccountPlansData);
        } else {
          this.updateAccountPlansList(this.rawPlanData);
        }
      } else {
        this._initSelectableViewItems();
      }
    }
  }

  onCancel(event) {
    this.updateAccountPlansList(this.rawPlanData);
    this.intializeheaders();
  }

  public async openAccountPlansFilter(event) {
    if(this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT && this.accountManagementOfflineService.tempReqPayload !== undefined){
      let response = await this.accountManagementOfflineService.showConfirmAlert();
      if(!response) return;
    }

    this.trackingService.tracking('AccountPlansFilter', TrackingEventNames.ACCOUNT_PLANS);
    this._accountPlansFilterPopover = await this.popover.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    this._accountPlansFilterPopover.present();
    this._accountPlansFilterPopover.onDidDismiss().then(() => {
      this._accountPlansFilterPopover = null;
    });
  }

  public accountFilter(id) {
    let index = this.accountFilterList.findIndex(x => x == id);
    if (index > -1) {
      this.accountFilterList.splice(index, 1);
    } else {
      this.accountFilterList.push(id);
    }
    this.filterAccountPlans();
  }

  private filterAccountPlans() {
    if (this.accountFilterList.length > 0) {
      let accountPlansData = [];
      this.accountFilterList.forEach(ac => {
        accountPlansData.push(...this.accountManagementOfflineService.accountPlans.filter(ap => ap.accountId === ac));
      })
      if (accountPlansData.length === 0) { // if there is no more accountplans which satisfies the filter reset
        this.resetFilter();
        return;
      }
      this.filteredAccountPlansData = accountPlansData;
      this.isFiltered = true;
      this.updateAccountPlansList(this.filteredAccountPlansData);
    } else {
      this.isFiltered = false;
      this.updateAccountPlansList(this.rawPlanData);
    }
  }

  resetFilter() {
    this.initFilter();
    this.filterBadgeCount = 0;
    this.accountFilterList = [];
    this.isFiltered = false;
    this.accountPlansFilter.account = [];
    this.initAccountPlansHeaderLeft();
    this.updateAccountPlansList(this.rawPlanData);
  }

  getFilterButtonBadgeCount() {
    return this.filterBadgeCount;
  }

  private getFilterCount() {
    this.filterBadgeCount = 0;
    this.filterBadgeCount = (this.accountFilterList.length > 0) ? this.accountFilterList.length : 0;
  }

  private onAccountFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if (selectedItem && selectedItem.value) {
      if (selected) {
        this.accountPlansFilter.account.push(selectedItem.value);
        this.accountPlansFilter.account.push(selectedItem);
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          itemRef.item.selectedValues.push(selectedItem.value);
        }
      } else {
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
          if (idx >= 0) {
            itemRef.item.selectedValues.splice(idx, 1);
          }
        }
      }
    } else {
      this.accountPlansFilter.account = [];
      this.accountFilterList = [];

    }
    this.accountFilter(selectedItem.value);
    this.getFilterCount();
    this.isFiltered = this.accountFilterList.length > 0;
    if (!this.isFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]) {
      itemRef.parent._value = itemRef.parent.items[0]._value = '0';
      this.filterPopoverData[0].value = '0';
    }
  }

  private recursiveAccountList() {
    if (this.allAccountsForAccountPlans) {
      let foundSubAccounts = [];
      let subAccountsNumber: number = 0;
      this.allAccountsForAccountPlans.forEach(ac => {
        if (ac.subAccounts && ac.subAccounts.length != 0) {
          ac.subAccounts.map(subAc => {
            foundSubAccounts.push(subAc);
          });
        }
        if (foundSubAccounts.length != 0) {
          foundSubAccounts = _.uniqBy(foundSubAccounts, 'id');
          subAccountsNumber = foundSubAccounts.length;
        }
      });
      if (this.preUpdatedSubAccountNumber != subAccountsNumber) {
        this.preUpdatedSubAccountNumber = subAccountsNumber;
        this.allAccountsForAccountPlans.push(...foundSubAccounts);
        this.allAccountsForAccountPlans = _.uniqBy(this.allAccountsForAccountPlans, 'id');
        this.recursiveAccountList();
      }
    }
    this.allAccountsForAccountPlans = _.uniqBy(this.allAccountsForAccountPlans, 'id');
    this.preUpdatedSubAccountNumber = 0;
  }



}
