import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { PopoverController } from '@ionic/angular';

@Component({
  selector: "mdm-material-modal",
  templateUrl: "mdm-material-modal.html",
  styleUrls: ['mdm-material-modal.scss']
})
export class MdmMaterialModal {
  @Input() materialData: string;

  material: SafeResourceUrl;
    
  constructor(
    public popoverCtrl: PopoverController,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    console.log("Material Data: ", this.materialData);
    this.material = this.sanitizer.bypassSecurityTrustResourceUrl(this.materialData);
  }

  closePopover() {
    this.popoverCtrl.dismiss({ completedFlow: false });
  }

}
