<div class="left-float">
    <h3 class="dark-color startTime">{{getFormattedAndLocalisedDate(activity.indskr_scheduledstart)}}</h3>
    <p class="light-color activityLength">{{getActivityLength(activity.indskr_scheduledstart, activity.indskr_scheduledend)}}
    </p>
</div>
<ion-thumbnail class="left-float">
    <ng-container *ngIf="activity.activityType == 'Appointment'">
        <img *ngIf="!activity.jointmeeting" [src]="activity.statecode != 1 ? 'assets/imgs/appointment.svg' : 'assets/imgs/appointment_complete.svg'" width="20">
        <img *ngIf="activity.jointmeeting" [src]="activity.statecode != 1 ? 'assets/imgs/open_joint_recommended_meeting.svg' : 'assets/imgs/Joint_appointment_complete.svg'" width="20">
    </ng-container>
    <ng-container *ngIf="activity.activityType == 'PhoneCall'">
        <img *ngIf="!activity.jointphonecall" [src]="activity.statecode != 1 ? 'assets/imgs/phone-call-activity.svg' : 'assets/imgs/phone-call-completed.svg'" width="20">
        <img *ngIf="activity.jointphonecall" [src]="activity.statecode != 1 ? 'assets/imgs/phone-call-activity.svg' : 'assets/imgs/phone-call-completed.svg'" width="20">
    </ng-container>
    <ng-container *ngIf="activity.activityType == 'SurgeryOrder'">
      <img [src]="activity.statecode != 1 ? 'assets/imgs/order_open.svg' : 'assets/imgs/orders_complete.svg'" width="20">
  </ng-container>
</ion-thumbnail>
<div text-left>
    <h2 class="dark-color ellipsis activitySubject">{{activity.indskr_subject}}</h2>
    <h4 class="light-color ellipsis activityLocation" text-nowrap>
        <ng-container *ngIf="activity.activityType == 'Appointment'">
            <ion-icon name="location-outline"></ion-icon>
            {{(activity.indskr_location ? activity.indskr_location : 'NO_LOCATION') | translate}}
        </ng-container>
        <ng-container *ngIf="activity.activityType == 'PhoneCall'">
            {{activity.phonenumber}}
        </ng-container>
        <ng-container *ngIf="activity.activityType == 'SurgeryOrder'">
          {{activity.orderNumber}}
      </ng-container>
    </h4>
</div>
