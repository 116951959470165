import { FeatureActionsMap } from './../../classes/authentication/user.class';
import { ReportDataManagementService } from './report-data-management.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { Injectable } from '@angular/core';
import * as Cube from 'olap-cube-js';
import { DeviceService } from '../device/device.service';
import { MeasureType } from '../../enums/edge-analytics/edge-analytics.enum';
import { MeetingDimensions } from '../../enums/edge-analytics/meeting/meeting-report.enum';
import { BaseMeasureData, CallPlanDimensionDTO, ChartFilterOption, EmailTemplateDimensionDTO, MeetingFactsDTO, MessageChannelDimensionDTO, MessageFactsDTO, PositionContactCountDimensionDTO, ProductDimensionDTO, SystemUserDimensionDTO, YearMonthListItem } from '../../../interfaces/edge-analytics/report.interface';
import { MeetingByProductChartFilterOptions, MeetingDataCubeDimensionHierarchies, MeetingReportYearMonthKeyProp, MeetingTrendChartExtraFilterOptions } from '../../config/edge-analytics/meeting-report/meeting-report.config';
import { DefaultChartOptions } from '../../config/edge-analytics/report.config';
import {  meetingGenerateCharts,
          meetingGenerateMeasureData,
          meetingSubscribeToMeasureData,
          meetingResetFilters,
          meetingUpdateMeasureDataAfterSync, meetingFillInMissingDateFacts
        } from './functions/meeting/meeting-report.functions';
import { calcChartHeight, calcChartWidth, updateHighChartOption, getTotalYearMonthList, getMissingYearMonths, getUserIdFromUserDimensions } from './functions/report.functions';
import { MeetingMeasureData } from '../../../interfaces/edge-analytics/meeting-report.interface';
import { TranslateService } from '@ngx-translate/core';
import { MessageByChannelChartFilterOptions, MessageDataCubeDimensionHierarchies, MessageReportYearMonthKeyProp, MessageTrendChartFilterOptions } from '../../config/edge-analytics/message-report/message-report.config';
import { MessageMeasureData } from '../../../interfaces/edge-analytics/message-report.interface';
import { messageFillInMissingDateFacts, messageGenerateCharts, messageGenerateMeasureData, messageResetFilters, messageSubscribeToMeasureData, messageUpdateMeasureDataAfterSync } from './functions/message/message-report.functions';
import { MessageDimensions } from '../../enums/edge-analytics/message/message-report.enum';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';
import { format, isValid, parse } from 'date-fns';
import { CoachingFactsDTO, CoachingMeasureData } from '../../../interfaces/edge-analytics/coaching-report.interface';
import { CoachingDataCubeDimensionHierarchies, CoachingReportYearMonthKeyProp, CoachingTrendChartFilterOptions } from '../../config/edge-analytics/coaching-report/coaching-report.config';
import { coachingFillInMissingDateFacts, coachingGenerateCharts, coachingGenerateMeasureData, coachingResetFilters, coachingSubscribeToMeasureData, coachingUpdateMeasureDataAfterSync } from './functions/coaching/coaching-report.functions';
import { procedureGenerateCharts, procedureResetFilters, procedureSubscribeToMeasureData } from './functions/surgery-order/surgery-order-report.functions';
import { SurgeryOrderMeasureData } from '../../../interfaces/edge-analytics/surgery-order-report.interface';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import { ProcedureTrendChartFilterOptions } from '@omni/config/edge-analytics/surgery-order-report/surgery-order-report.config';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _totalYearMonthList: YearMonthListItem[] = [];
  private _lastRefreshDateTimeRawValue: Date;
  private _realTimeDataDateTimeRawValue: Date;

  constructor(
    private readonly deviceService: DeviceService,
    private readonly authService: AuthenticationService,
    private readonly translate: TranslateService,
    private readonly dateTimeFormatService: DateTimeFormatsService,
    private readonly reportDataMgmService: ReportDataManagementService,
    private readonly datePipe: DatePipe,
  ) {}


  /** ----------------------------------------------------------------------------------------
   *  Getters & Setters
   */
  getLastRefreshDateFormattedText(measureType: MeasureType): string {
    const rawDateTimeValue = measureType !== MeasureType.procedure && measureType !== MeasureType.salesOrder  ? this._lastRefreshDateTimeRawValue : this._realTimeDataDateTimeRawValue;
    let formattedText = '';
    if (rawDateTimeValue) {
      const dateString = this.datePipe.transform(rawDateTimeValue, this.dateTimeFormatService.date, undefined,this.translate.currentLang) || '';
      const timeString = rawDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatService.is12HourFormat, hour: '2-digit', minute: '2-digit' }) || '';
      formattedText = _.capitalize(dateString) + ' ' + timeString;
    }
    if (formattedText === '') {
        if (measureType !== MeasureType.procedure) {
          console.warn('setLastRefreshDateFormattedText: Invalid input..', this._lastRefreshDateTimeRawValue);
        } else {
          console.warn('setRealTimeDataDateFormattedText: Invalid input..', this._realTimeDataDateTimeRawValue);
        }
      }
    return formattedText;
  }
  setLastRefreshDateFormattedText(rawRefreshDateTimeString: string) {
    // Expected raw string format
    // "2020-11-14 00:03:52  (GMT +05:30)"
    let formattedText = '';
    if (rawRefreshDateTimeString) {
      const array = rawRefreshDateTimeString.split(/\s+/);
      if (Array.isArray(array) && array.length === 4) {
        const ymd = array[0];
        const time = array[1];
        const tz = array[3].replace(')', '');

        if (ymd && time && tz) {
          const isoFormat = ymd + 'T' + time + tz;
          const parsedDate: Date = parse(isoFormat);
          if (isValid(parsedDate)) {
            this._lastRefreshDateTimeRawValue = parsedDate;
          }
        }
      }
    }
  }
  setTotalYearMonthList(dataStartDate: string) {
    if (dataStartDate) {
      this._totalYearMonthList = getTotalYearMonthList(dataStartDate);
    } else {
      console.error('setTotalYearMonthList: invalid input: ', dataStartDate);
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Measure data setup helper functions
   */
  async setupMeetingMeasureDataCube(  meetingFacts: MeetingFactsDTO[],
                                      workingDaysCalculationData: MeetingFactsDTO[],
                                      productDimensions: ProductDimensionDTO[],
                                      meetingCallPlansDimensions: CallPlanDimensionDTO[],
                                      systemUserDimensions: SystemUserDimensionDTO[],
                                      positionContactCountDimensions: PositionContactCountDimensionDTO[],
                                      newLastUpdatedDate: Date,
                                      dataStartDate: string) {

    if (Array.isArray(meetingFacts) && Array.isArray(workingDaysCalculationData)
                                    && Array.isArray(productDimensions) && Array.isArray(meetingCallPlansDimensions)
                                    && Array.isArray(systemUserDimensions) && newLastUpdatedDate) {

      if (!this.authService.hasFeatureAction(FeatureActionsMap.BASELINE)) {
        if (this.reportDataMgmService.hasMeasure(MeasureType.meeting)) {
          this.reportDataMgmService.removeMeasure(MeasureType.meeting);
        }
        return;
      }

      let meetingMeasureData: MeetingMeasureData;
      const meetingCube = new Cube(MeetingDataCubeDimensionHierarchies);

      // Only interested in user working days data for now.
      // This section might need to be changed later if team view comes in...
      const userId: number = getUserIdFromUserDimensions(this.authService.user.userPrincipalName, systemUserDimensions);
      if (userId !== null) {
        workingDaysCalculationData = workingDaysCalculationData.filter(w => w.sk_systemuserid === userId);
      } else {
        meetingFacts = [];
        console.warn('setupMeetingMeasureDataCube: There is no facts data for current user..', );
      }

      if (Array.isArray(this._totalYearMonthList) && userId) {
        const missingYearMonthList = getMissingYearMonths(this._totalYearMonthList, meetingFacts, MeetingReportYearMonthKeyProp);
        if (Array.isArray(missingYearMonthList) && missingYearMonthList.length > 0) {
          // We already have system user id filtered facts here.
          // Will require to update this logic later when we decide to display other users' data..
          meetingFillInMissingDateFacts(missingYearMonthList, meetingFacts, userId);
        }
      } else {
        console.warn('setupMeetingMeasureDataCube: Total year month list not avail');
      }

      meetingCube.addFacts(meetingFacts);

      // Assign product names
      meetingCube.getDimensionMembers(MeetingDimensions.products).forEach(o => {
        const prod = productDimensions.find(p => p.sk_productid === o.sk_productid);
        o.product = prod ? prod.productname : this.translate.instant('DATA_NOT_AVAILABLE');
      });

      // Check for statecode = 1 dimension member and manually add if not
      if (
        !meetingCube.getDimensionMembers(MeetingDimensions.statecode)
        .find(d => d.statecode === 1)
      ) {
        meetingCube.addDimensionMember(MeetingDimensions.statecode, { statecode: 1 });
      }

      // Get primary position's contact count
      const primaryPositionId: string = (this.authService.user.positions.find(p => p.primary === true))?.ID ?? undefined;
      let contactCount: number = 0;
      if (Array.isArray(positionContactCountDimensions) && primaryPositionId !== undefined) {
        const positionContactCount = positionContactCountDimensions.find(p => p.positionid.toLowerCase() === primaryPositionId.toLowerCase());
        if (positionContactCount) {
          contactCount = positionContactCount.customers;
        }
      }

      if (!this.reportDataMgmService.hasMeasure(MeasureType.meeting)) {
        // Initial data setup
        meetingMeasureData = meetingGenerateMeasureData(meetingCube,
                                                        workingDaysCalculationData,
                                                        meetingCallPlansDimensions,
                                                        systemUserDimensions,
                                                        contactCount,
                                                        this.authService.user.userPrincipalName,
                                                        newLastUpdatedDate,
                                                        dataStartDate,
                                                        this.reportDataMgmService.getConfiguration('Meetings & Phone Calls'),
                                                        this.translate.currentLang);
      } else {
        // Update
        const curMeetingMeasureData: MeetingMeasureData = this.reportDataMgmService.getMeasure(MeasureType.meeting);
        const chartFilterOptionData: ChartFilterOption[] = this.getChartFilterOptionData(curMeetingMeasureData);
        meetingMeasureData = meetingUpdateMeasureDataAfterSync( curMeetingMeasureData,
                                                                meetingCube,
                                                                workingDaysCalculationData,
                                                                meetingCallPlansDimensions,
                                                                systemUserDimensions,
                                                                contactCount,
                                                                this.authService.user.userPrincipalName,
                                                                newLastUpdatedDate,
                                                                dataStartDate,
                                                                chartFilterOptionData,
                                                                this.reportDataMgmService.getConfiguration('Meetings & Phone Calls'));
      }

      if (meetingMeasureData) {
        this.translateMeasureData(meetingMeasureData);
        this.reportDataMgmService.addMeasure(MeasureType.meeting, meetingMeasureData);
      }
    } else {
      console.error('setupMeetingMeasureDataCube: Invalid input: ', meetingFacts,
                                                                    workingDaysCalculationData,
                                                                    productDimensions,
                                                                    meetingCallPlansDimensions,
                                                                    systemUserDimensions,
                                                                    newLastUpdatedDate,
                                                                    dataStartDate);
    }
  }
  public translateMeasureData(measureData: BaseMeasureData) {
    // Do all of the necessary translation here..
    // Chart filters
    if (Array.isArray(measureData.charts)) {
      for (let i = 0; i < measureData.charts.length; i++) {
        const chartData = measureData.charts[i];
        if (chartData.title) {
          chartData.title = this.translate.instant(chartData.titleKey);
        }

        if (chartData.chartFilterTemplate) {
          chartData.chartFilterTemplate.title = chartData.chartFilterTemplate.titleKey
                                                ? this.translate.instant(chartData.chartFilterTemplate.titleKey) : '';
          chartData.chartFilterTemplate.dividerTitle = chartData.chartFilterTemplate.dividerTitleKey
                                                        ? this.translate.instant(chartData.chartFilterTemplate.dividerTitleKey) : '';
        }
      }
    }
  }

  async setupMessageMeasureDataCube(  messageFacts: MessageFactsDTO[],
                                      emailTemplateDimensions: EmailTemplateDimensionDTO[],
                                      messageChannelDimensions: MessageChannelDimensionDTO[],
                                      systemUserDimensions: SystemUserDimensionDTO[],
                                      newLastUpdatedDate: Date,
                                      dataStartDate: string) {

    if (Array.isArray(messageFacts) && Array.isArray(emailTemplateDimensions)
                                    && Array.isArray(systemUserDimensions)
                                    && newLastUpdatedDate) {

      if (!this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
        if (this.reportDataMgmService.hasMeasure(MeasureType.message)) {
          this.reportDataMgmService.removeMeasure(MeasureType.message);
        }
        return;
      }
      let messageMeasureData: MessageMeasureData;
      const messageCube = new Cube(MessageDataCubeDimensionHierarchies);

      let userId: number = getUserIdFromUserDimensions(this.authService.user.userPrincipalName, systemUserDimensions);
      if (userId !== null) {
        emailTemplateDimensions = emailTemplateDimensions.filter(e => e.sk_systemuserid === userId)
      } else {
        messageFacts = [];
        console.warn('setupMessageMeasureDataCube: There is no facts data for current user..');
      }

      if (Array.isArray(this._totalYearMonthList) && userId) {
        const missingYearMonthList = getMissingYearMonths(this._totalYearMonthList, messageFacts, MessageReportYearMonthKeyProp);
        if (Array.isArray(missingYearMonthList) && missingYearMonthList.length > 0) {
          // We already have system user id filtered facts here.
          // Will require to update this logic later when we decide to display other users' data..
          messageFillInMissingDateFacts(missingYearMonthList, messageFacts, userId);
        }
      } else {
        console.warn('setupMessageMeasureDataCube: Total year month list not avail');
      }

      messageCube.addFacts(messageFacts);

      // Assign channel names
      messageCube.getDimensionMembers(MessageDimensions.channel).forEach(o => {
        const channel = messageChannelDimensions.find(c => c.sk_channelid === o.sk_channelid);
        o.channel = channel ? channel.channelname : '';
      });

      if (!this.reportDataMgmService.hasMeasure(MeasureType.message)) {
        // Initial data setup
        messageMeasureData = messageGenerateMeasureData(messageCube,
                                                        emailTemplateDimensions,
                                                        messageChannelDimensions,
                                                        systemUserDimensions,
                                                        this.authService.user.userPrincipalName,
                                                        newLastUpdatedDate,
                                                        dataStartDate,
                                                        this.reportDataMgmService.getConfiguration('Messages'),
                                                        this.translate.currentLang);
      } else {
        // Update
        const curMessageMeasureData: MessageMeasureData = this.reportDataMgmService.getMeasure(MeasureType.message);
        const chartFilterOptionData: ChartFilterOption[] = this.getChartFilterOptionData(curMessageMeasureData);
        messageMeasureData = messageUpdateMeasureDataAfterSync( curMessageMeasureData,
                                                                messageCube,
                                                                emailTemplateDimensions,
                                                                messageChannelDimensions,
                                                                systemUserDimensions,
                                                                this.authService.user.userPrincipalName,
                                                                newLastUpdatedDate,
                                                                dataStartDate,
                                                                chartFilterOptionData,
                                                                this.reportDataMgmService.getConfiguration('Messages'));
      }

      if (messageMeasureData) {
        this.translateMeasureData(messageMeasureData)
        this.reportDataMgmService.addMeasure(MeasureType.message, messageMeasureData);
      }
    } else {
      console.error('setupMessageMeasureDataCube: Invalid input: ', messageFacts,
                                                                    emailTemplateDimensions,
                                                                    messageChannelDimensions,
                                                                    systemUserDimensions,
                                                                    newLastUpdatedDate,
                                                                    dataStartDate);
    }
  }

  async setupCoachingMeasureDataCube(coachingFacts: CoachingFactsDTO[], systemUserDimensions: SystemUserDimensionDTO[], newLastUpdatedDate: Date, dataStartDate: string) {
    if (Array.isArray(coachingFacts) && Array.isArray(systemUserDimensions) && newLastUpdatedDate) {
      if (!this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL)) {
        if (this.reportDataMgmService.hasMeasure(MeasureType.coaching)) {
          this.reportDataMgmService.removeMeasure(MeasureType.coaching);
        }
        return;
      }
      let coachingMeasureData: CoachingMeasureData;
      const coachingCube = new Cube(CoachingDataCubeDimensionHierarchies);

      if (Array.isArray(this._totalYearMonthList)) {
        const userId: number = getUserIdFromUserDimensions(this.authService.user.userPrincipalName, systemUserDimensions);
        const missingYearMonthList = getMissingYearMonths(this._totalYearMonthList, coachingFacts, CoachingReportYearMonthKeyProp);
        if (Array.isArray(missingYearMonthList) && missingYearMonthList.length > 0) {
          // We already have system user id filtered facts here.
          // Will require to update this logic later when we decide to display other users' data..
          coachingFillInMissingDateFacts(missingYearMonthList, coachingFacts, userId);
        }
      } else {
        console.warn('setupCoachingMeasureDataCube: Total year month list not avail');
      }
      coachingCube.addFacts(coachingFacts);

      if (!this.reportDataMgmService.hasMeasure(MeasureType.coaching)) {
        // Initial data setup
        coachingMeasureData = coachingGenerateMeasureData(coachingCube,
                                                          systemUserDimensions,
                                                          this.authService.user.userPrincipalName,
                                                          newLastUpdatedDate,
                                                          dataStartDate,
                                                          this.reportDataMgmService.getConfiguration('Coachings & Covisitor Meetings'),
                                                          this.translate.currentLang);
      } else {
        // Update
        const curCoachingMeasureData: CoachingMeasureData = this.reportDataMgmService.getMeasure(MeasureType.coaching);
        const chartFilterOptionData: ChartFilterOption[] = this.getChartFilterOptionData(curCoachingMeasureData);
        coachingMeasureData = coachingUpdateMeasureDataAfterSync( curCoachingMeasureData,
                                                                  coachingCube,
                                                                  systemUserDimensions,
                                                                  this.authService.user.userPrincipalName,
                                                                  newLastUpdatedDate,
                                                                  dataStartDate,
                                                                  chartFilterOptionData,
                                                                  this.reportDataMgmService.getConfiguration('Coachings & Covisitor Meetings'));
      }

      if (coachingMeasureData) {
        this.translateMeasureData(coachingMeasureData);
        this.reportDataMgmService.addMeasure(MeasureType.coaching, coachingMeasureData);
      }
    } else {
      console.error('setupCoachingMeasureDataCube: Invalid input: ', coachingFacts, systemUserDimensions, newLastUpdatedDate, dataStartDate);
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Subscribe to measure data
   */
  subscribeToMeasureData(measure: BaseMeasureData) {
    if (measure && measure.measureType) {
      const chartFilterOptionData: ChartFilterOption[] = this.getChartFilterOptionData(measure);
      measure.isFilterDirty = true;
      switch (measure.measureType) {
        case MeasureType.meeting:
          meetingSubscribeToMeasureData(measure as MeetingMeasureData, this.authService.user.userPrincipalName, chartFilterOptionData, this.reportDataMgmService.getConfiguration('Meetings & Phone Calls'));
          break;
        case MeasureType.message:
          messageSubscribeToMeasureData(measure as MessageMeasureData, this.authService.user.userPrincipalName, chartFilterOptionData, this.reportDataMgmService.getConfiguration('Messages'));
          break;
        case MeasureType.coaching:
          coachingSubscribeToMeasureData(measure as CoachingMeasureData, this.authService.user.userPrincipalName, chartFilterOptionData, this.reportDataMgmService.getConfiguration('Coachings & Covisitor Meetings'));
          break;
        case MeasureType.procedure:
          procedureSubscribeToMeasureData(measure as SurgeryOrderMeasureData, this.authService.user.systemUserID, chartFilterOptionData);
          break;

        default:
          console.warn('subscribeToMeasureData: Unhandled case: ', measure.measureType);
          break;
      }
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Data filter helper functions
   */
  updateSelectedDateFilter(measure: BaseMeasureData, selectedDateFilters: any[]) {
    if (measure && Array.isArray(selectedDateFilters)) {
      measure._selectedDateFilters$.next(selectedDateFilters);
      measure.isFilterDirty = true;
    }
  }
  updateSelectedChartFilter(measure: BaseMeasureData, newOptions: ChartFilterOption[], chartId: string) {
    if (measure && Array.isArray(newOptions) && chartId) {
      const chart = measure.charts.find(c => c.id === chartId);
      if (chart) {
        chart._chartFilterOptions$.next(newOptions);
        measure.isFilterDirty = true;
      } else {
        console.warn('updateSelectedChartFilter: chart not found:', measure, newOptions, chartId);
      }
    } else {
      console.warn('updateSelectedChartFilter: Invalid / missing input: ', measure, newOptions, chartId);
    }
  }
  resetFilters(measure: any) {
    if (measure && measure.measureType) {
      const chartFilterOptionData: ChartFilterOption[] = this.getChartFilterOptionData(measure);
      switch (measure.measureType) {
        case MeasureType.meeting:
          meetingResetFilters(measure, this.authService.user.userPrincipalName, chartFilterOptionData, this.reportDataMgmService.getConfiguration('Meetings & Phone Calls'));
          break;

        case MeasureType.message:
          messageResetFilters(measure, this.authService.user.userPrincipalName, chartFilterOptionData, this.reportDataMgmService.getConfiguration('Messages'));
          break;

        case MeasureType.coaching:
          coachingResetFilters(measure, this.authService.user.userPrincipalName, chartFilterOptionData, this.reportDataMgmService.getConfiguration('Coachings & Covisitor Meetings'));
          break;

        case MeasureType.procedure:
          procedureResetFilters(measure, this.authService.user.systemUserID);
          break;

        default:
          break;
      }
      measure.isFilterDirty = false;
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Chart helper functions
   */
  generateCharts(measureData: BaseMeasureData) {
    if (measureData) {
      switch (measureData.measureType) {
        case MeasureType.meeting:
          meetingGenerateCharts(measureData as MeetingMeasureData,
                                this.deviceService.isMobileDevicePortrait,
                                window.innerWidth,
                                window.innerHeight);
          break;

        case MeasureType.message:
          messageGenerateCharts(measureData as MessageMeasureData,
                                this.deviceService.isMobileDevicePortrait,
                                window.innerWidth,
                                window.innerHeight);
          break;

        case MeasureType.coaching:
          coachingGenerateCharts( measureData as CoachingMeasureData,
                                  this.deviceService.isMobileDevicePortrait,
                                  window.innerWidth,
                                  window.innerHeight);
          break;

        case MeasureType.procedure:
          procedureGenerateCharts(measureData as SurgeryOrderMeasureData,
                                  this.deviceService.isMobileDevicePortrait,
                                  window.innerWidth,
                                  window.innerHeight);
          break;

        default:
          break;
      }
    }
  }
  redrawHighChart(highChartRef: Highcharts.Chart) {
    if (highChartRef) {
      highChartRef.redraw();
    }
  }
  resizeCharts(measureData: BaseMeasureData) {
    if (measureData && Array.isArray(measureData.charts)) {
      for (let i = 0; i < measureData.charts.length; i++) {
        const chartData = measureData.charts[i];
        const chart: any = {};
        chart.width = calcChartWidth(this.deviceService.isMobileDevicePortrait, window.innerWidth);
        chart.height = calcChartHeight(this.deviceService.isMobileDevicePortrait, window.innerHeight);

        const plotOptions: any = {};
        if (chartData?.customChartOption?.chart?.type === 'pie') {
          plotOptions['pie'] = {
            size: this.deviceService.isMobileDevicePortrait ? null : '60%'
          };
        }

        updateHighChartOption(chartData.highChartRef, { chart, plotOptions }, false, true);
        chartData._chartUpdated$.next(chartData.id);
      }
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Temporary helper functions to be re-written
   */
  overwriteMeasureUpdateTime(date?: Date) {
    if (!date) {
      date = new Date();
    }
    this._realTimeDataDateTimeRawValue = date;
  }

  public getChartFilterOptionData(measure: BaseMeasureData): ChartFilterOption[] {
    let chartFilterOptionData: ChartFilterOption[] = [];
    switch (measure.measureType) {
      case MeasureType.meeting:
        const meetingByProductFilterOptions = JSON.parse(JSON.stringify(MeetingByProductChartFilterOptions));
        const meetingTrendFilterOptions = JSON.parse(JSON.stringify(MeetingTrendChartExtraFilterOptions));
        chartFilterOptionData = [...meetingByProductFilterOptions, ...meetingTrendFilterOptions];
        break;
      case MeasureType.message:
        const messageByChannelFilterOptions = JSON.parse(JSON.stringify(MessageByChannelChartFilterOptions));
        const messageTrendFilterOptions = JSON.parse(JSON.stringify(MessageTrendChartFilterOptions));
        chartFilterOptionData = [...messageByChannelFilterOptions, ...messageTrendFilterOptions];
        break;
      case MeasureType.coaching:
        chartFilterOptionData = JSON.parse(JSON.stringify(CoachingTrendChartFilterOptions));
        break;
      case MeasureType.procedure:
        chartFilterOptionData = JSON.parse(JSON.stringify(ProcedureTrendChartFilterOptions))
        break;
      default:
        break;
    }
    // Translate display text
    chartFilterOptionData.map(fd => {
      fd.displayText = this.translate.instant(fd.displayTextKey);
    });
    return chartFilterOptionData;
  }
}
