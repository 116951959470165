<!-- Generated template for the MoreOptionsPopoverComponent component -->
<ng-template #action_button_more let-button>
  <ion-button fill="clear" [disabled]="(!button.isactive ||
    (device.isOffline && button.disableWhenOffline))" class="actionBtn" [ngClass]="button.id" (click)="buttonEventHandler(button)">
    <div class="actionItem">
      <ion-badge color="primary" class="actionBadge" *ngIf="shouldShowBadge(button)">
        {{ getBadgeData(button) }}
      </ion-badge>
      <img class="actionIcon ion-text-center" [ngClass]="footerIconStyle(button)" [src]="button.iconurl" width="25px"
        (click)="buttonEventHandler(button)" />
      <span class="ion-text-center actionTitle" (click)="buttonEventHandler(button)"
        [ngClass]="button.id.indexOf('active') > 0 ? 'footer-button-text-active' : 'footer-button-text-inactive'">
        {{ getButtonValue(button) }}</span>
    </div>
  </ion-button>
</ng-template>
<ion-row *ngFor="let buttonGroup of buttonGroups">
  <ion-col *ngFor="let button of buttonGroup" class="ion-no-padding">
    <ng-container [ngTemplateOutlet]="action_button_more" [ngTemplateOutletContext]="{$implicit:button}">
    </ng-container>
  </ion-col>
</ion-row>
