import { DeviceService } from './../device/device.service';
import { PresentationService } from './../presentation/presentation.service';
import { DeltaService } from './../../data-services/delta/delta.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './../authentication.service';
import { ActivityService } from "./../activity/activity.service";
import { Injectable } from "@angular/core";
import { EventsService } from "../events/events.service";
import { AppointmentActivity } from "../../classes/activity/appointment.activity.class";
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import {
  EntitySyncInfo,
  EntityNames
} from "../../data-services/delta/delta.service";
import { Endpoints } from "../../../config/endpoints.config";
import { TrackAction } from "../../utility/common-enums";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../disk/disk.service";
import uuid from 'uuid';
import { PresentationView } from '../ui/ui.service';
import { timeout } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

interface IContactPoll {
  indskr_pollingid?: string;
  indskr_activitypresentationslideid: string;
  indskr_iopresentationid: string;
  indskr_datetimecreatedonapp: number;
  activityid: string;
  contactid: string;
  indskr_name: string;
  indskr_pollingtemplateid: string;
  indskr_ratingssummary: string;
  indskr_ckmpageid: string;
  indskr_page: string;
  indskr_offlinemeetingid: string;
  pollingMatrix: {
          indskr_pollingmatrixid?: string;
          indskr_assessmentratingid: string;
          indskr_assessmentmeasureid: string;
          indskr_assessmentcategoryid: string;
          indskr_comments: string;
          indskr_name: string;
          indskr_assessmentratingscaleid: string;
      }[]
}

interface ContactPoll {
  _id?: string,
  activityPresentationSlideId?: string;
  pageId?: string;
  presentationId?: string;
  createdDate?: number;
  activityId?: string;
  offlineMeetingId?: string;
  contactId?: string;
  name?: string;
  pollTemplateId?: string;
  ratingSummary?: string;
  pollResults?: ContactPollResult[];
  pollId?: string;
  offlinePollId?: string;
  statecode?: string;
  submitted?: boolean;
  error?: string;
}

interface ContactPollResult {
  customRating?: any;
  categoryId?: string;
  ratingId?: string;
  measureId?: string;
  comments?: string;
  name?: string;
  ratingScaleId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PollService {
  private activePoll: any;
  private subscriptions: { unsubscribe: Function; }[] = [];
  private unSavedPoll: string[] = [];
  constructor(
    private activityService: ActivityService,
    private eventService: EventsService,
    private disk: DiskService,
    private authService: AuthenticationService,
    private http: HttpClient,
    private deltaService: DeltaService,
    private presService: PresentationService,
    private device: DeviceService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    this.subscriptions.push(...eventService.subscribe(
      "window:message",
      async message => {
        if (this.presService.viewMode !== PresentationView.MEETING) return;
        if (message.type !== "io-spa") return;
        if (
          message.action == "loaded" &&
          message.data &&
          message.data.activePage &&
          message.data.activePage.poll
        ) {
          this.activePoll = message.data.activePage.poll;
          this.unSavedPoll = [];
          eventService.publish("iframe:message", {
            type: "io-frame",
            action: "init-params",
            data: { contacts: await this.getContactsForPollSlide() }
          });
        }
        if (message.action == "poll-confirm") {
          const confirmDialog = await this.alertService.showAlert({
            header: translate.instant('POP_CONFIRM_SUBMIT_POLL'),
            message: translate.instant('POP_R_U_CONFIRM_SUBMIT_POLL'), backdropDismiss: false
          }, "Yes", "No");
          if(confirmDialog.role == "ok"){
            eventService.publish("iframe:message", {
              type: "io-frame",
              action: "submit-confirmed",
              data: { response: true }
            });
          }
        }
        if (message.action == "poll-submitted") {

            let {
              data: { contact }
            } = message;
            this.unSavedPoll = this.unSavedPoll.filter(item => item !== contact.id);
            this.submitPoll(contact);
        }
        if (message.action == "poll-changed") {
          let {
            data: { contact }
          } = message;
          const idx = this.unSavedPoll.findIndex(item => item === contact.id);
          if(idx === -1) this.unSavedPoll.push(contact.id);
        }
        if (message.action == "poll-saved") {
          let {
            data: { contact, poll }
          } = message;
          this.unSavedPoll = this.unSavedPoll.filter(item => item !== contact.id);
          this.savePoll(contact.id, poll);
        }
      }
    ));
    // let contactsRefreshed = async (data) => {
    //   if(data) {
    //     eventService.publish("iframe:message", {
    //       type: "io-frame",
    //       action: "refresh-contacts",
    //       data: { contacts: await this.getContactsForPollSlide() }
    //     });
    //   }
    // }
    // this.subscriptions.push(...
    //   eventService.subscribe('ContactsSelected', data => contactsRefreshed(data)));
    // this.subscriptions.push(...
    //   eventService.subscribe('refreshSeletecContacts', () => contactsRefreshed(true)));
  }

  public contactsRefreshed = async (data) => {
    if(data) {
      this.eventService.publish("iframe:message", {
        type: "io-frame",
        action: "refresh-contacts",
        data: { contacts: await this.getContactsForPollSlide() }
      });
    }
  }
  public get isUnsavedPoll(){
    return this.unSavedPoll.length > 0;
  }
  public clearSavedPolls(){
    this.unSavedPoll = [];
  }
  public isPollsToSubmit(){
    let activity = this.activityService.selectedActivity as AppointmentActivity;
    if (activity && this.activePoll) {
      let polls = this.getStoredPolls();
      if(polls != null){
        polls = polls.filter(poll => poll.activityId === activity.ID || poll.activityId === activity.offlineMeetingId);
        return polls.length > 0;
      }
    }else{
      return false;
    }
  }
  private async getContactsForPollSlide() {
    let activity = this.activityService
      .selectedActivity as AppointmentActivity;
    if (activity && this.activePoll) {
      let polls = this.getStoredPolls();
      if(polls != null){
        polls = polls.filter(poll => (poll.activityId === activity.ID || poll.activityId === activity.offlineMeetingId)  && poll.pageId === this.presService.activePresPage.id);
      }
      return await Promise.all(activity.contacts.map(async cust => ({
        id: cust.ID,
        name: cust.fullName,
        poll: (polls.length > 0) ? polls.find(poll => poll.contactId === cust.ID)?.poll : undefined,
        pollSubmitted: !!(await this.findPoll({contactId: cust.ID, pollTemplateId: this.activePoll.id}))[0],
        pollSaved: (polls.length > 0)
      })));
    }
  }

  private getStoredPolls(){
    const ls = window.localStorage.getItem('polls');
    return (ls !== null) ? JSON.parse(ls) : [];
  }

  private savePoll(contactId: string, poll: any){
    const polls = this.getStoredPolls();
    let activity = this.activityService.selectedActivity as AppointmentActivity;
    let selectedPres = this.presService.activePresentation;
    let selectedSlide = this.presService.activePresPage;
    const prevPoll = polls.find(poll => poll.pageId === selectedSlide.id && poll.activityId === activity.ID && poll.contactId === contactId);
    if(prevPoll){
      prevPoll.poll = poll;
    }else{
      polls.push({
        pageId: selectedSlide.id,
        activityId: activity.ID,
        contactId: contactId,
        poll
      });
    }
    window.localStorage.setItem('polls', JSON.stringify(polls));
  }

  private async findPoll(filter: ContactPoll, limit?: number) {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.POLL_RESULTS + "_",
          $lte: DB_KEY_PREFIXES.POLL_RESULTS + "_" + PREFIX_SEARCH_ENDKEY_UNICODE
        },
        ...filter
      }
    };
    if (!isNaN(limit) && limit > 0) {
      option['limit'] = limit;
    }
    let results = await this.disk.find(option) as ContactPoll[];
    return results || [];
  }

  private async submitPoll(contactPollData) {
    let activity = this.activityService.selectedActivity as AppointmentActivity;
    let selectedPres = this.presService.activePresentation;
    let selectedSlide = this.presService.activePresPage;

    let pollCategories = contactPollData.poll.categories as {
        id: any;
        questions: { id: any, scaleId: string, options: any[], answer: any }[];
      }[];

    let pollObj: ContactPoll = {
      pageId: selectedSlide.id as any as string,
      presentationId: selectedPres['ioPresentationId'], //TODO: resource poll?
      createdDate: (new Date()).getTime(),
      name: contactPollData.poll.name,
      ratingSummary: '',
      submitted: false,
      activityId: activity.ID,
      offlineMeetingId: activity.offlineMeetingId,
      contactId: contactPollData.id,
      pollTemplateId: contactPollData.poll.id,
      pollResults: pollCategories.reduce(
        (arr, category) => {
          category.questions.forEach(question => {
            let selectedOption = question.options && question.options.find(opt => opt.selected);
            arr.push({
              measureId: question.id,
              ratingId: selectedOption && selectedOption.id,
              name: ' ',
              customRating: selectedOption ? undefined : question.answer,
              categoryId: category.id,
              ratingScaleId: question.scaleId,
            });
          });
          return arr;
        },
        [] as ContactPollResult[]
      )
    }
    await this.writePollToDisk(pollObj);
    let polls = this.getStoredPolls();
    const idx = polls.findIndex(poll => poll.activityId === activity.ID && poll.pageId === selectedSlide.id && poll.contactId === contactPollData.id);
    this.unSavedPoll = this.unSavedPoll.filter(item => item !== contactPollData.id);
    if(idx > -1){
      polls.splice(idx,1);
      window.localStorage.setItem('polls', JSON.stringify(polls));
    }

    setTimeout(async ()=>{
      if(!this.device.isOffline) await this.submitOfflineData();
    },1000);

  }

  private async writePollToDisk(poll: ContactPoll) {
    if (!poll._id) {
      poll._id = `${DB_KEY_PREFIXES.POLL_RESULTS}_${poll.contactId}_${poll.pollTemplateId}`;
    }
    let existingPollObject = await this.disk.retrieve(poll._id, true);
    if (existingPollObject) {
      poll['_rev'] = existingPollObject['_rev']
    }
    await this.disk.updateOrInsert(poll._id, doc => poll);
  }

  public async sync(forceFullSync: boolean = false, loadFromDbOnly = false): Promise<void> {
    if (loadFromDbOnly) {
      return;
    }

    let syncState = await this.disk.getSyncState(
      DB_SYNC_STATE_KEYS.SYNC_POLL_RESULTS
    );
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    const doFullSync = forceFullSync || isInitialSync;
    const newLastUpdatedTime = new Date().getTime();
    const pollSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.poll_results,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    //If isInitialSync, we clearly got nothing, load it up sonny, standard old school method of replace everything
    let url: string = (doFullSync ? Endpoints.poll.GET : Endpoints.poll.GET_DELTA.replace("{lastUpdatedTime}", syncState.lastUpdatedTime.toString()));
    const positions = this.authService.user.positions.map((o)=> o.ID);
    url = url.replace('{{positionIDs}}',positions.join(','));
    url = this.authService.userConfig.activeInstance.entryPointUrl + url;

    let response: any;

    try {
      response = await this.http
        .get(url, Endpoints.GLOBAL_SYNC_HEADER)
        .toPromise();
    } catch (error) {
      console.error("syncPoll: _doInitialSync: ", error);
      //accountSyncInfo.errors = '[account][error]' + error ? error.errorMessage : '';
      this.deltaService.addSyncErrorToEntitySyncInfo(pollSyncInfo, url, error);
      return;
    }

    if (response && Array.isArray(response)) {
      let docs = await Promise.all(response.filter(item => item.contactid && item.indskr_pollingtemplateid).map(async item => {
        let poll: ContactPoll = {
          activityPresentationSlideId: item.indskr_activitypresentationslideid || '',
          presentationId: item.indskr_iopresentationid || '',
          createdDate: item.indskr_datetimecreatedonapp,
          activityId: item.activityid || '',
          contactId: item.contactid || '',
          name: item.indskr_name || '',
          pollTemplateId: item.indskr_pollingtemplateid || '',
          ratingSummary: item.indskr_ratingssummary || '',
          pollId: item.indskr_pollingid || '',
          submitted: true,
        };
        poll._id = `${DB_KEY_PREFIXES.POLL_RESULTS}_${poll.contactId}_${poll.pollTemplateId}`;
        const doc = await this.disk.retrieve(poll._id, true);
        if (doc) {
          poll['_rev'] = doc['_rev'];
        }
        return poll;
      }
      ));
      await this.disk.bulk(docs);
      pollSyncInfo.totalSynced = response.length;
    }

    // Add sync info to the service for tracking
    this.deltaService.addEntitySyncInfo(pollSyncInfo);

    // // Done sync. Update sync state.
    if (pollSyncInfo.syncStatus) {
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
    }

    console.log(
      `Sync status variables: ${syncState} & ${isInitialSync} & ${doFullSync} & ${newLastUpdatedTime}`
    );

    return;
  }


  public async submitOfflineData(isPartialUpload = false, maxRecordCountToPartialUpload = 10) {
    // For partial upload, allow up to 10 records per request.
    let uploadedDataCount = 0;
    let pending = await this.findPoll({ submitted: false });
    let pendingOfflineData = isPartialUpload && Array.isArray(pending) && pending.length > maxRecordCountToPartialUpload ? pending.splice(0, maxRecordCountToPartialUpload) : pending;
    if(!pendingOfflineData || pendingOfflineData.length === 0) {
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.POLL, 0);
      return;
    }
    this.disk.wasThereOfflineDataUpload = true;
    let postData: IContactPoll[] = await this.preparePollingRequestData(pendingOfflineData);
    const pollSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.poll_results,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.poll.CREATE;
    try {
      let response;
      if (postData && postData.length > 0) {
        if (!isPartialUpload) {
          response = await this.http.post(url, postData, Endpoints.headers.content_type.json).toPromise();
        } else {
          response = await this.http.post(url, postData, Endpoints.headers.content_type.json).pipe(timeout(150000)).toPromise();
        }
      }
      if (response) {
        for (let i = 0; i < pendingOfflineData.length; i++) {
          let obj = response[i];
          let poll = pendingOfflineData[i];
          if(obj && obj.errorMessage) {
            poll.error = obj.errorMessage;
            pollSyncInfo.totalFailed++;
          }
          if(!poll.activityId.includes('offline')){
            poll.submitted = true;
            uploadedDataCount++;
          }
        }
      }
    } catch (err) {
      console.error('uploadOfflinePolls:', err);
      pollSyncInfo.totalFailed += postData.length;
      this.deltaService.addSyncErrorToEntitySyncInfo(pollSyncInfo, url, err);
    }
    this.deltaService.addEntitySyncInfo(pollSyncInfo, true);
    await this.disk.bulk(pendingOfflineData);
    // Track offline data count
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.POLL, pending.length - uploadedDataCount);
  }

  private async preparePollingRequestData(pendingOfflineData: ContactPoll[]): Promise<IContactPoll[]> {
    let postData: IContactPoll[] = [];
    if (pendingOfflineData) {
      for (let pollData of pendingOfflineData) {
        let activityId = pollData.activityId && !pollData.activityId.includes('offline') ? pollData.activityId : undefined;
        let offlineActivityId = pollData.offlineMeetingId && pollData.offlineMeetingId.includes('offline') ? pollData.offlineMeetingId : undefined;
        let activity = activityId ? this.activityService.getActivityByID(activityId) : this.activityService.getActivityByOfflineID(offlineActivityId);
        if (activity) {
          activityId = activity.ID
          pollData.activityId = activityId;
        }
        let activityPresentationSlideid;
        if (activity instanceof AppointmentActivity) {
          let presentation = activity.activityPresentations.filter(pres => (pres.DTO as any).indskr_iopresentationid == pollData.presentationId)[0];
          if (presentation) {
            let page = presentation.activityPresentationSlides.filter(slide => slide.ckmpageid === pollData.pageId)[0];
            if (page) {
              activityPresentationSlideid = (page.DTO as any).activitypresentationslideid;
            }
          }
        }
        if (!activityId.includes('offline')) {
          let contactPollObj : IContactPoll = {
            indskr_activitypresentationslideid: activityPresentationSlideid == pollData.pageId ? undefined : activityPresentationSlideid,
            indskr_iopresentationid: pollData.presentationId,
            indskr_datetimecreatedonapp: pollData.createdDate,
            activityid: activityId,
            contactid: pollData.contactId,
            indskr_name: pollData.name,
            indskr_pollingtemplateid: pollData.pollTemplateId,
            indskr_ratingssummary: pollData.ratingSummary,
            indskr_ckmpageid: pollData.pageId,
            indskr_page: pollData.pageId,
            indskr_offlinemeetingid: offlineActivityId,
            pollingMatrix: pollData.pollResults.map(pollResult => ({
              indskr_assessmentcategoryid: pollResult.categoryId,
              indskr_assessmentratingid: pollResult.ratingId,
              indskr_assessmentmeasureid: pollResult.measureId,
              indskr_customrating: pollResult.customRating,
              indskr_comments: ' ',
              indskr_name: ' ',
              indskr_assessmentratingscaleid: pollResult.ratingScaleId,
            })),
          }
          postData.push(contactPollObj);
        }
      }
    }
    return postData;
  }

  init() {}

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
