import { Component, Input } from '@angular/core';
import { UIService } from "../../services/ui/ui.service";

/**
 * Generated class for the SpinnerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'io-spinner',
  templateUrl: 'spinner.html',
  styleUrls:['spinner.scss']
})
export class SpinnerComponent {

  @Input() displayText:string;


  constructor(
    private uiService: UIService,
  ) {

  }

  ngOnInit(){
    this.uiService.displayLoader();
  }

  ngOnDestroy(){
    this.uiService.dismissLoader();
  }

}
