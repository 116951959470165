import {Pipe, PipeTransform} from "@angular/core";
import {ConsentService} from "../services/consent/consent.service";
import {ConsentTerm} from "../classes/consent/consent-term.class";


@Pipe({
  name: 'activeConsentsFilter',
  pure: true
})
export class ActiveConsentsFilter implements PipeTransform {

  constructor(
    private consentService: ConsentService,
  ) {}

  /**
   *  This is the pipe for filtering Active Consents
   *
   * @returns
   * @memberof ActiveConsentsFilter
   */
  transform(items: ConsentTerm[]) {
    if(this.consentService.showAllConsentsFilter$.value){
      return items;
    }
    if(this.consentService.consentProductFilter$) {
      items = items.filter((term) => term.products
        .some((p) => p.indskr_productid == this.consentService.consentProductFilter$.ID));
    }
    if(this.consentService.consentChannelFilter$) {
      items = items.filter((term) => term.channels
        .some((c) => c.indskr_consenttypeid == this.consentService.consentChannelFilter$.indskr_consenttypeid));
    }
    items = items.sort((a,b)=> (a.createdon < b.createdon)?1:-1)
    return items;
  }

}
