import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import fr from '@angular/common/locales/fr';
import ja from '@angular/common/locales/ja';
import tr from '@angular/common/locales/tr';
import pt from '@angular/common/locales/pt';
import it from '@angular/common/locales/it';
import { Component, Input, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Activity, ActivityType } from '@omni/classes/activity/activity.class';
import { FollowUpActivity } from '@omni/classes/activity/follow-up-action.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { FollowUpActionDetailComponent } from '@omni/components/activity/follow-up-action-detail/follow-up-action-detail';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { FollowUpActivityDataService } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsService } from '@omni/services/events/events.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { UIService } from '@omni/services/ui/ui.service';
import { format } from 'date-fns';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
const MIN_SEARCH_LENGTH = 1;


@Component({
  selector: 'account-plan-activities',
  templateUrl: 'account-plan-activities.html',
  styleUrls: ['account-plan-activities.scss']
})
export class AccountPlanActivities {
  @ViewChild('accountplanactivitiesrightpane', { static: true }) public navCtrl: IonNav;
  public searchInput: string;
  public indHeaderLeftModel: IndHeaderLeftDataModel = {
    id: 'accountplan-activities-header-left',
    title: this.translate.instant('MEETINGS'),
    mode: true,
    controls: [{
      id: 'cancel',
      imgSrc: 'assets/imgs/header_cancel.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: false,
      align: 'left'
    }, {
      id: 'done',
      imgSrc: 'assets/imgs/header_complete.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: !this.device.isOffline,
      align: 'right'
    }]
  };
  public searchActive = false;
  @Input() activities: Activity[];
  @Input() activityType: ActivityType;
  @Input() callbackEvent: any;
  @Input() existingActivities: Activity[];
  @Input() from : any;
  public selectedActivities: Activity[] = [];
  private selectedActivity: Activity;
  private date_fns_locale: any;
  public activitiesMainCardModel: MainCardViewDataModel[] = [];
  public selectedActivitiesMainCardModel: MainCardViewDataModel[] = [];
  private filteredActivities: Activity[] = [];
  public deviceIsOffline: boolean = false;
  public noActivitiesText: string;
  private ngUnSubscibe$ = new Subject<boolean>();

  constructor(public navService: NavigationService,
    public uiService: UIService,
    public translate: TranslateService,
    public footerService: FooterService,
    private dateTimeFormatService: DateTimeFormatsService,
    private activityService: ActivityService,
    private followupDataService: FollowUpActivityDataService,
    private device: DeviceService,
    private activityDataService: ActivityDataService,
    private authenticationOfflineService: AuthenticationService,
    private events: EventsService) {

  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.AccountPlanActivitiesNavigation, PageName.AccountPlanActivities, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' })
    switch (this.translate.currentLang) {
      case 'es':
        this.date_fns_locale = { locale: es }
        break;
      case 'fr':
        this.date_fns_locale = { locale: fr }
        break;
      case 'ja':
        this.date_fns_locale = { locale: ja }
        break;
      case 'tr':
        this.date_fns_locale = { locale: tr }
        break;
      case 'pt':
        this.date_fns_locale = { locale: pt }
        break;
      case 'it':
        this.date_fns_locale = { locale: it }
        break;
      case 'en':
        this.date_fns_locale = { locale: en }
        break;
    }
    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      this.deviceIsOffline = isOffline;
    })
    this.filteredActivities = this.activities;
    if (!_.isEmpty(this.existingActivities)) {
      this.selectedActivities = _.cloneDeep(this.existingActivities);
      this.getSelectedActivitiesMainCard();
    }
    this.getActivitiesMainCard();
    this.updateTitleAsPerActivityType();

  }

  private updateTitleAsPerActivityType() {
    switch (this.activityType) {
      case ActivityType.Appointment:
        this.indHeaderLeftModel.title = this.translate.instant('MEETINGS');
        this.noActivitiesText = this.translate.instant('NO_MEETINGS');
        break;
      case ActivityType.PhoneCall:
        this.indHeaderLeftModel.title = this.translate.instant('PHONE_CALLS');
        this.noActivitiesText = this.translate.instant('NO_PHONE_CALLS');
        break;
      case ActivityType.FollowUp:
        this.indHeaderLeftModel.title = this.translate.instant('FOLLOW_UP_ACTIONS');
        this.noActivitiesText = this.translate.instant('NO_FOLLOWUPS');
        break;
      case ActivityType.SurgeryOrder:
        this.indHeaderLeftModel.title = this.translate.instant('PROCEDURE_LOGS');
        this.noActivitiesText = this.translate.instant('NO_PROCEDURE');
        break;
      default:
        console.error("Unhandled case");
    }
  }

  public searchText(ev) {
    if (this.deviceIsOffline || this.activities.length == 0) return;
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= MIN_SEARCH_LENGTH) {
      this.searchActive = true;
      this.filteredActivities = this.activities.filter(activity => {
        return activity.subject.trim().toLowerCase().includes(val.trim().toLowerCase());
      });
    } else {
      this.filteredActivities = this.activities;
      this.searchActive = false;
    }
    this.getActivitiesMainCard();
  }

  private getActivitiesMainCard() {
    this.activitiesMainCardModel = _.orderBy(this.filteredActivities, [obj => obj.scheduledStart, obj => obj.subject], 'desc').map(activity => this.getMainCardDataModel(activity));
  }

  private getSelectedActivitiesMainCard() {
    this.selectedActivitiesMainCardModel = _.orderBy(this.selectedActivities, [obj => obj.scheduledStart, obj => obj.subject], ['desc', 'asc']).map(activity => this.getMainCardDataModel(activity, true));
  }

  private getMainCardDataModel(activity: Activity, forSelectedSection: boolean = false) {
    // Followup time - set default 12Am
    let setFollowUpTime = new Date();
    setFollowUpTime.setHours(24);
    setFollowUpTime.setMinutes(0);
    let viewData: MainCardViewDataModel;
    switch (activity.type) {
      case ActivityType.Appointment:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isSelected: this.selectedActivity?.ID == activity.ID,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && activity.subject == 'Visit') ? this.translate.instant('VISIT') : activity.subject,
          secondaryTextRight: (!activity.location || activity.location == 'nan') ? this.translate.instant('NO_LOCATION') : (activity.location == 'No Location' ? this.translate.instant('NO_LOCATION') : activity.location),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          endIconType: this.getEndIconType(forSelectedSection, activity),
          mainItemCssClass: 'selector-item',
          endIconCssClass: this.getEndIconCssClass(forSelectedSection, activity),
          showEndIcon: activity.ownerId === this.authenticationOfflineService.user.systemUserID && (this.from !== PageName.MarketingPlanDetails || activity.state === 0) ? true : false,
          clickHandler: (id: string, event, eventName, refData) => this.handleMainCardEvent(id, event, eventName, refData, ActivityType.Appointment)
        }
        break;
      case ActivityType.PhoneCall:
        const phoneactivity: PhoneActivity = activity as PhoneActivity;
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          isSelected: (this.selectedActivity && this.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: this.activityService.getActivitysubject(activity),
          secondaryTextRight: (phoneactivity.selectedMobileNumber == "" || phoneactivity.selectedMobileNumber == null || phoneactivity.selectedMobileNumber == undefined) ? this.translate.instant('NO_CONTACT_NUMBER') : phoneactivity.selectedMobileNumber,
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          endIconType: this.getEndIconType(forSelectedSection, activity),
          mainItemCssClass: 'selector-item',
          endIconCssClass: this.getEndIconCssClass(forSelectedSection, activity),
          showEndIcon: true,
          clickHandler: (id: string, event, eventName, refData) => this.handleMainCardEvent(id, event, eventName, refData, ActivityType.PhoneCall)
        }
        break;
      case ActivityType.FollowUp:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          isSelected: (this.selectedActivity && this.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(setFollowUpTime, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject == 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : activity.subject,
          secondaryTextRight: this.followupDataService.getActivitySecondaryTextStringForFollowup(activity as FollowUpActivity),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          endIconType: this.getEndIconType(forSelectedSection, activity),
          mainItemCssClass: 'selector-item',
          endIconCssClass: this.getEndIconCssClass(forSelectedSection, activity),
          showEndIcon: true,
          clickHandler: (id: string, event, eventName, refData) => this.handleMainCardEvent(id, event, eventName, refData, ActivityType.FollowUp)
        }
        break;
      case ActivityType.SurgeryOrder:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isSelected: this.selectedActivity?.ID == activity.ID,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject == 'Procedure Log' ? this.translate.instant('NEW_ACTIVITY_PROCEDURE_LOG') : activity.subject,
          // secondaryTextRight: (!activity.location || activity.location == 'nan') ? this.translate.instant('NO_LOCATION') : (activity.location == 'No Location' ? this.translate.instant('NO_LOCATION') : activity.location),
          showArrow: false,
          showIcon:  true ,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          endIconType: this.getEndIconType(forSelectedSection, activity),
          mainItemCssClass: 'selector-item',
          endIconCssClass: this.getEndIconCssClass(forSelectedSection, activity),
          showEndIcon: activity.ownerId === this.authenticationOfflineService.user.systemUserID && (this.from !== PageName.MarketingPlanDetails || activity.state === 0) ? true : false,
          clickHandler: (id: string, event, eventName, refData) => this.handleMainCardEvent(id, event, eventName, refData, ActivityType.SurgeryOrder)
        }
        break;
      default:
        console.error("Unhandled case");
    }
    return viewData;
  }

  private getEndIconType(forSelectedSection: boolean, activity: Activity) {
    return forSelectedSection ? 'indegene-selectors-remove-icon' : this.selectedActivities.some(sActivity => sActivity.ID == activity.ID) ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon';
  }

  private getEndIconCssClass(forSelectedSection: boolean, activity: Activity) {
    return forSelectedSection ? 'remove-icon' : this.selectedActivities.some(sActivity => sActivity.ID == activity.ID) ? 'checkmark-icon' : 'add-icon'
  }

  private handleMainCardEvent(id: string, event, eventName, refData, activitytype: ActivityType) {
    if (eventName == 'mainItem') {
      this.openActivityDetails(id, activitytype);
    } else if (eventName == 'endIcon') {
      if (refData && refData.showEndIcon) {
        if (refData.endIconType == 'indegene-selectors-add-icon') {
          this.addActivity(id);
        } else if (refData.endIconType == 'indegene-selectors-remove-icon') {
          this.removeActivity(id);
        }
        this.getSelectedActivitiesMainCard();
        this.indHeaderLeftModel.controls.find(control => control.id == 'done').isDisabled = !this.isDirty;
        this.getActivitiesMainCard();
      }
    }
  }

  get isDirty() {
    if (this.existingActivities.length != this.selectedActivities.length) return true;
    return this.selectedActivities.some(activity => !this.existingActivities.some(eActivity => eActivity.ID == activity.ID));
    // return this.existingActivities.length != this.selectedActivities.length ? true : !_.isEqualWith(this.existingActivities, this.selectedActivities, obj => obj.ID);
  }

  private addActivity(id: string) {
    this.selectedActivities.push(this.activities.find(activity => activity.ID == id));
  }

  private removeActivity(id: string) {
    this.selectedActivities = this.selectedActivities.filter(activity => activity.ID != id);
  }

  private async openActivityDetails(id: string, activityType: ActivityType) {
    this.activityService.selectedActivity = this.selectedActivity = this.activities.find(activity => activity.ID == id);
    this.activitiesMainCardModel.forEach(card => card.isSelected = this.selectedActivity.ID == card.id);
    this.selectedActivitiesMainCardModel.forEach(card => card.isSelected = this.selectedActivity.ID == card.id);
    await this.activityDataService.updateActivityDetails(this.selectedActivity);
    switch (activityType) {
      case ActivityType.Appointment:
        this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPlanActivities, { from: PageName.AccountPlanActivities });
        this.events.publish('activities:appConfigFields');
        //timeout to fire the event once after the component is loaded
        setTimeout(() => { this.events.publish('detectChangesOnActivityDetails'); }, 0);
        this.footerService.initButtons(FooterViews.Activities);
        break;
      case ActivityType.PhoneCall:
        this.navService.setChildNavRoot(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.AccountPlanActivities, { from: PageName.AccountPlanActivities });
        this.events.publish('phoneactivity:appConfigFields');
        this.footerService.initButtons(FooterViews.PHONE_CALL);
        break;
      case ActivityType.FollowUp:
        this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.AccountManagementPageComponent,
          { from: PageName.AccountPlanActivities, activity: this.selectedActivity });
        break;
      case ActivityType.SurgeryOrder:
        this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.AccountManagementPageComponent,
          { from: PageName.AccountPlanActivities, activity: this.selectedActivity });
        break;
      default:
        console.error("Unhandled case");
    }
    this.navService.setChildNavRightPaneView(true);
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack(false);
        break;
      case 'done':
        this.goBack(true);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public goBack(isDone: boolean) {
    if (isDone) {
      const data = {
        selectedActivities: this.selectedActivities,
        activityType: this.activityType
      }
      this.callbackEvent(data);
    }
    this.navService.popChildNavCtrlFromStack(ChildNavNames.AccountPlanActivitiesNavigation).then(() => {
      this.footerService.initButtons(FooterViews.None);
      this.navService.popWithPageTracking();
    })
  }
}
