import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { Presentation } from "../classes/presentation/presentation.class";
import { Resource } from "../classes/resource/resource.class";

/**
 * Generated class for the OrderBy pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
    name: "contentCustomSort"
})
export class ContentCustomSortPipe<T> implements PipeTransform {
    transform<T>(items: Array<T>, sortOption: { favourite?: boolean, downloaded: boolean }) {
        if (!Array.isArray(items)) return [];
        if (!sortOption || !(sortOption.favourite || sortOption.downloaded)) return items;

        let filteredItems: Array<T>;
        let propName: string;

        if (sortOption.favourite) {
            propName = 'favourite';
        } else if (sortOption.downloaded) {
            propName = 'downloaded';
        }
        if (propName) {
            let sortedPropValueTruePresentation: T[] = (items as T[]).filter(e => {
                return e[propName];
            }).sort(this.sortAlphaNumerical);
    
            //Non-downloaded presentation sorted here
            let sortedPropValueFalsePresentation: T[] = (items as T[]).filter(e => {
                return !e[propName];
            }).sort(this.sortAlphaNumerical);
    
            //combine presentation list with downloaded data on the top of the stack
            filteredItems = sortedPropValueTruePresentation.concat(sortedPropValueFalsePresentation);
        }

        return Array.isArray(filteredItems) ? filteredItems : items;
    }

    private sortAlphaNumerical(a, b) {
        //order for sorting as requested by QA team
        //priority order ==>> special character >> numerals >> alphabets
        let sortOrder: string = '*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz';

        // first character of the name of x
        let index_x = sortOrder.indexOf(a.name.toLowerCase()[0]);

        // first character of the name of y
        let index_y = sortOrder.indexOf(b.name.toLowerCase()[0]);

        if (index_x === index_y) {
            // same first character, sort regular
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            } 
            else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        } 
        else {
            //return in the order as set on the sortOrder list
            return index_x - index_y;
        }
    }

}
