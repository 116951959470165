import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { Presentation } from "../classes/presentation/presentation.class";

/**
 * Generated class for the OrderBy pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
    name: "presentationFilterBy"
})
export class PresentationFilterByPipe implements PipeTransform {

    // Filter format: { name: '', specialtyId: '', brandId: '' }
    transform(items: Presentation[], filterOptions: { name: string, specialtyId: string, brandId: string }) {
        if (!Array.isArray(items)) return [];
        if (!filterOptions) return items;

        let filteredItems;

        if (filterOptions.hasOwnProperty('name') && filterOptions.name) {
            filteredItems = _.filter(items, (pres) => {
                const re = new RegExp('(' + filterOptions.name + ')+', 'gi');
                return re.test(pres.name) || 
                        re.test(pres.description) ||
                        re.test(pres.languageName) ||
                        pres.keyMessages.some(x => re.test(x.keyMessageName)) ||
                        pres.presentationPages.some(x => re.test(x.name)) ||
                        pres.brands.some(x => re.test(x.name));
            });
        }

        if (filterOptions.hasOwnProperty('specialtyId') && filterOptions.specialtyId) {
            filteredItems = _.filter(items, (pres) => _.some(pres.specialties, ['id', filterOptions.specialtyId]));
        }

        if (filterOptions.hasOwnProperty('brandId') && filterOptions.brandId) {
            filteredItems = _.filter(Array.isArray(filteredItems) ? filteredItems : items, (pres) => _.some(pres.brands, ['ID', filterOptions.brandId]));
        }

        return Array.isArray(filteredItems) ? filteredItems : items;
    }
}
