import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format, isAfter, isBefore, isEqual, isSameDay, isValid } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { Resource } from '../../../classes/resource/resource.class';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ResourceService, ResourceView } from '../../../services/resource/resource.service';
import { ComponentViewMode, PresentationView, UIService } from '../../../services/ui/ui.service';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
import { ResourcePageComponent } from '../../resource/resource-page/resource-page';
import { AccountObjectiveDetails } from '../account-objective-details/account-objective-details';
import { AccountPlan, AccountPlanMode, Expense } from '../../../classes/account-management/account-plan.class';
import { ActivityType } from '../../../classes/activity/activity.class';
import { FOLLOW_UP_TYPE, FollowUpActivity } from '../../../classes/activity/follow-up-action.activity.class';
import { ActivityService } from '../../../services/activity/activity.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { FollowUpActionDetailComponent } from '../../activity/follow-up-action-detail/follow-up-action-detail';
import { AccountPlanOpportunityListComponent } from '../account-plan-opportunity-list/account-plan-opportunity-list';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { SelectListData } from '@omni/models/select-list-data-model';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { ModalController, PopoverController } from '@ionic/angular';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { PresentationPageComponent } from '@omni/pages/presentation/presentation';
import { EventsSelectionViewDataModel } from '@omni/models/events-selection-page-model';
import { EventsToolPageComponent } from '@omni/components/events-tool/event-tool-page/events-tools-page';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { ExpensesDetails } from '../account-plan-expenses/expenses-details';
import { Guid } from 'typescript-guid';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import moment from 'moment';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';

@Component({
  selector: 'account-plan-info',
  templateUrl: 'account-plan-info.html',
  styleUrls: ['account-plan-info.scss']
})
export class AccountPlanInfo implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() selectedMode: AccountPlanMode;
  public isOpportunitiesEnabled = false;
  public isOpportunitiesVisible = false;
  //calling it a reference as its a copy by reference and not by value
  public planDataSetReference: AccountPlan;
  public allModelObjectives: Array<MainCardViewDataModel> = [];
  public expensesMainCardModel: Array<MainCardViewDataModel> = [];
  public checkIfInvalidDocuments = false;
  // formattedPeriod: string;
  productsString: string;
  followUpTasksForPlan: FollowUpActivity[];
  ngUnSubscibe$ = new Subject<boolean>();
  showMore = false;
  strategy_Text_default_length = 150;
  private strategy: string;
  private contactsString: string;
  public pageTitle: IndPageTitleViewDataModel;
  public detailHeaderModel: IndSectionHeaderViewDataModel;
  public strategyHeaderModel: IndSectionHeaderViewDataModel;
  public objectivesHeaderModel: IndSectionHeaderViewDataModel;
  public expensesHeaderModel: IndSectionHeaderViewDataModel;
  public isExpensesEnabled: boolean = false;

  public eventsSectionHeaderModel: IndSectionHeaderViewDataModel;
  public associatedEvents: EventActivity[] = [];
  public deviceOffline: boolean = false;
  isEventsEnabled = false;
  public accountsString: string;
  private _isClicked: { [x: string]: boolean } = {};

  constructor(
    public device: DeviceService,
    public repService: RepServices,
    private readonly accountManagementService: AccountManagementOfflineService,
    public activityService: ActivityService,
    public authService: AuthenticationService,
    private readonly navService: NavigationService,
    public changeDetectionRef: ChangeDetectorRef,
    public trackingService: TrackService,
    public resourceService: ResourceService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public opportunityService: OpportunityManagementService,
    public readonly translate: TranslateService,
    public footerService: FooterService,
    public uiService: UIService,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    private modalCtrl: ModalController,
    private readonly brandService: BrandOfflineService,
    private contactService: ContactOfflineService,
    private accountManagementDataService: AccountManagementDataService,
    private eventsToolService: EventsToolService,
    private alertService: AlertService,
    private accountService: AccountOfflineService,
    private events: EventsService,
    private _cd: ChangeDetectorRef,
    private readonly popoverCtrl: PopoverController,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.trackingService.tracking('AccountPlanInfo', TrackingEventNames.ACCOUNTPLANNING);

    this.accountManagementService.selectedAccountPlan$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((data) => {
      if (data) {
        this.planDataSetReference = _.cloneDeep(this.accountManagementService.accountPlans.find(o => o.ID === data.ID));
        if(this.planDataSetReference) {
          this.setViewdata();
          this.initializeHeaders();
        }
      }
    });

    this.events.observe('account-plan-mode-changed').pipe(takeUntil(this.ngUnSubscibe$)).subscribe(() => {
      this.selectedMode = this.accountManagementService.selectedMode;
      this.setViewdata();
      this.initializeHeaders();
    })

    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      // if the device offlice diisable events add button and account plan cancel button
      this.deviceOffline = isOffline;
      this.initializeEventsHeader();
      this.initializeExpensesHeader();
      // this.initPageTitle();
    });

    //disabled opportunities, to be enabled later
    const accountPlan = this.data;
    this.associatedEvents = this.data.events ? this.data.events.filter(event => event.statuscode !== 548910006) : [];
    // this.footerService.initButtons(FooterViews.None);
    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
    }
    // this.planDataSetReference = this.accountManagementService.accountPlans.find(o => o.ID === accountPlan.ID);
    // this.setViewdata();

    this.accountManagementService.accountPlans$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((data) => {
      if (data && data.length) {
        this.planDataSetReference = _.cloneDeep(data.find(o => o.ID === accountPlan.ID));
        if (this.planDataSetReference) {
          this.setViewdata();
        }
      }
    });

    this.isOpportunitiesVisible = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    this.isExpensesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EXPENSES_IN_ACCOUNT_PLAN);
    this.isEventsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_ACCOUNT_PLAN);
    // this.initPageTitle();
    this.initializeHeaders();
  }

  ionViewDidEnter() {
    if (this.planDataSetReference) {
      const applicableOpportunities = this.opportunityService.opportunities.filter((oppo) => {
        return oppo.accountPlanID && oppo.accountPlanID === this.planDataSetReference.ID;
      });
      this.isOpportunitiesEnabled = applicableOpportunities.length > 0 ? true : false;
      this.setViewdata();
      // Refresh followup tasks list if user came back after creating new follow up task
    }
  }

  private initializeHeaders() {
    this.initializeAPDetailHeader();
    this.initializeAPStrategyHeader();
    this.intializeAPObjectives();
    this.initializeEventsHeader();
    this.initializeExpensesHeader();
  }

  private initializeAPDetailHeader() {
    this.detailHeaderModel = {
      id: 'account-plan-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
  }

  private initializeAPStrategyHeader() {
    this.strategyHeaderModel = {
      id: 'account-plan-strategy-header',
      title: this.translate.instant('STRATEGY'),
      controls: [
        {
          id: 'ap-detail-strategy-documents',
          text: this.translate.instant('DOCUMENTS'),
          isDisabled: this.checkIfInvalidDocuments,
        }
      ]
    };
  }

  private initializeEventsHeader() {
    const planExpiredOrCompleted = this.data.status === 'Completed';

    this.eventsSectionHeaderModel = {
      id: 'account-plan-events-header',
      title: this.translate.instant('EVENTS'),
      controls: [{
        id: 'add-account-plan-events',
        text: this.translate.instant('ADD'),
        isDisabled: this.deviceOffline || planExpiredOrCompleted || (this.data.ownerID && this.data.ownerID !== this.authService.user.systemUserID)
      }]
    };
  }

  private initializeExpensesHeader() {
    this.expensesHeaderModel = {
      id: 'expenses',
      title: this.translate.instant('EXPENSES'),
      controls: [{
        id: 'add-expenses',
        text: this.translate.instant('ADD'),
        isDisabled: this.deviceOffline,
      }, {
        id: 'edit-expenses',
        text: this.translate.instant('EDIT'),
        isDisabled: true,
      }, {
        id: 'delete-expenses',
        text: this.translate.instant('DELETE'),
        isDisabled: true,
      }],
    };
  }

  private intializeAPObjectives() {
    this.objectivesHeaderModel = {
      id: 'account-plan-objectives-header',
      title: this.translate.instant('OBJECTIVES_CAP'),
      controls: []
    };
  }

  private get startPeriod() {
    return this._getFormattedDate(this.planDataSetReference.startDate);
  }

  private get endPeriod() {
    return this._getFormattedDate(this.planDataSetReference.endDate);
  }

  private _getFormattedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  // private _getLocalisedStatus(status: string) {
  //   switch (status) {
  //     case 'Open':
  //       return this.translate.instant('OPEN');
  //     case 'Completed':
  //       return this.translate.instant('COMPLETED');
  //     case 'Canceled':
  //       return this.translate.instant('CANCELLED');
  //   }
  // }

  setViewdata(data?: AccountPlan) {
    this.getStartPeriodFormField();
    this.getEndPeriodFormField();

    this.productsString = this.getProductsString;
    this.contactsString = this.getContactString;
    this.accountsString = this.getAccountsString;
    const followUpTasksForPlan = this.activityService.activities.filter((a) => {
      return (a.type === ActivityType.FollowUp && (a as FollowUpActivity).accountPlanId === this.planDataSetReference.ID
        && (a.ownerId === this.authService.user.systemUserID
          || (a as FollowUpActivity).assignedTo.some(o => o.userId === this.authService.user.systemUserID)
          || this.planDataSetReference.ownerID === this.authService.user.systemUserID)
        && ((a as FollowUpActivity).planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN)
        && (a.state === 0 || a.state === 1));
    }).sort((a, b) => {
      return (a.subject > b.subject) ? 1 : -1;
    }).sort((a, b) => {
      if (isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        if (isEqual(a.scheduledEnd, b.scheduledEnd)) return 1;
        else return (isBefore(a.scheduledEnd, b.scheduledEnd) ? 1 : -1);
      } else if (isValid(a.scheduledEnd) && !isValid(b.scheduledEnd)) {
        return 1;
      } else if (!isValid(a.scheduledEnd) && isValid(b.scheduledEnd)) {
        return -1;
      } else {
        return 1;
      }
    });

    this.followUpTasksForPlan = followUpTasksForPlan.map((f) => {
      return f as FollowUpActivity;
    });

    if (this.planDataSetReference.accountPlanObjectives && this.planDataSetReference.accountPlanObjectives.length !== 0) {
      let allObjectives = this.planDataSetReference.accountPlanObjectives;
      if(this.authService.user.objectivesBasedOnOwnership){
        allObjectives = allObjectives.filter(a=> a.ownerId == this.authService.user.systemUserID || a.ownerManagerId == this.authService.user.systemUserID);
      }
      this.allModelObjectives = allObjectives.map((f) => {
        const viewModel: MainCardViewDataModel = {
          id: f.objectiveID,
          fixedHeight: true,
          primaryTextLeft: '',
          primaryTextRight: f.name + (this.authService.user.objectivesBasedOnOwnership? ' - '+f.ownerName:'') ,
          secondaryTextRight: ((f.completedPercent) ? f.completedPercent : 0) + '%',
          isSecondaryTextRightPillType: true,
          showArrow: true,
          arrowType: 'chevron-forward',
          clickHandler: (id: string, event) => {
            this.openPlanObjective(this.planDataSetReference.accountPlanObjectives.find(f => f.objectiveID == id));
          },
        };
        if (f.type === 'QUANTITATIVE') {
          viewModel.secondaryTextRightTwo = `${this.translate.instant('ACCOUNTS_OBJECTIVE_TARGET')}  ${f.formattedTarget ? this.dateTimeFormatsService.formattedCurrency(f.formattedTarget) : '0'}`;
        }
        return viewModel;
      });
    }

    if (this.accountManagementService.selectedMode == AccountPlanMode.NEW || this.accountManagementService.selectedMode == AccountPlanMode.EDIT) {
      this.checkIfInvalidDocuments = false;
    } else if (this.planDataSetReference.documents.length === 0) {
      this.checkIfInvalidDocuments = true;
    } else {
      this.checkIfInvalidDocuments = true;
      for (let i = 0; i < this.planDataSetReference.documents.length; i++) {
        const document = this.planDataSetReference.documents[i];
        let compareToDate = null;
        if (!_.isEmpty(document['ioDocumentId']) || !_.isEmpty(document['ioResourceId'])) {
          const res = <Resource>document;
          compareToDate = (res.raw && res.raw.indskr_availableuntil) ? new Date(parseInt(res.raw.indskr_availableuntil)) : null;
        } else {
          compareToDate = (document && document.availableUntil) ? document.availableUntil : null;
        }
        if (document && (!compareToDate || !isValid(compareToDate) || isSameDay(compareToDate, new Date()) || isAfter(compareToDate, new Date()))) {
          this.checkIfInvalidDocuments = false;
          break;
        }
      }
    }
  }

  private get getContactString() {
    return !_.isEmpty(this.planDataSetReference.contactAccountAffs) ?
      (this.planDataSetReference.contactAccountAffs.length > 1 ?
        this.planDataSetReference.contactAccountAffs[0].contactFullName + ' +' + (this.planDataSetReference.contactAccountAffs.length - 1) :
        this.planDataSetReference.contactAccountAffs[0].contactFullName) : '';
  }

  private get getProductsString() {
    return this.planDataSetReference.products.map(o => {
      return o.productName;
    }).toString().split(',').join(', ');
  }

  private get getAccountsString() {
    return _.isEmpty(this.planDataSetReference.accountName) ? '' : this.planDataSetReference.accountName;
  }

  ngOnDestroy() {
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
  }

  public getFormattedDueDate(value): string {
    if (isValid(value)) {
      return `${this.translate.instant('DUE')} ${this._getFormattedDate(value)}`;
    } else {
      return '';
    }
  }

  public handleOpportunities(): void {
    this.navService.pushChildNavPageWithPageTracking(AccountPlanOpportunityListComponent, PageName.AccountPlanOpportunityListComponent,
      PageName.AccountManagementPageComponent, { selectedAccountPlan: this.planDataSetReference });
  }

  public handlePlusButton(): void {
    this.trackingService.tracking('AccountPlanNewActivity', TrackingEventNames.ACCOUNTPLANNING);
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool', plan: this.planDataSetReference });
  }

  public openPlanObjective(objective): void {
    this.trackingService.tracking('AccountPlanObjectives', TrackingEventNames.ACCOUNTPLANNING);
    this.navService.pushChildNavPageWithPageTracking(AccountObjectiveDetails, PageName.AccountObjectiveDetailsComponent,
      PageName.AccountManagementPageComponent, { data: objective, accountPlanRef: this.planDataSetReference });
  }

  public openFollowUpDetails(task: FollowUpActivity) {
    this.activityService.selectedActivity = this.activityService.activities.find(o => o.ID === task.ID);
    this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool', activity: task });
  }

  public backToAccountPlanList(): void {
    if (this.uiService.activitiesPagePlanTabSelectedPlan == 'accountPlansTab') {
      this.navService.popToRootWithPageTracking();
    }
    this.navService.popChildNavPageWithPageTracking();
    this.uiService.showRightPane = false;
    this.accountManagementService.isRightPaneNavActive = false;
  }

  openResources() {
    if (this.checkIfInvalidDocuments) return;
    const contentIds = [];
    this.planDataSetReference.documents.forEach(doc => {
      if (!_.isEmpty(doc['ioPresentationId']))
        contentIds.push({ ioPresentationId: doc['ioPresentationId'] });
      else if (!_.isEmpty(doc['ioDocumentId'])) {
        contentIds.push({ ioDocumentId: doc['ioDocumentId'] })
      } else
        contentIds.push({ ioResourceId: doc['ioResourceId'] })
    })
    this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent,
      {
        viewMode: this.accountManagementService.selectedMode == AccountPlanMode.NEW || this.accountManagementService.selectedMode == AccountPlanMode.EDIT ? PresentationView.ADDTOACCOUNTPLAN : PresentationView.VIEWFROMACCOUNTPLAN,
        selectedContentIds: contentIds,
        callbackEvent: (data: any) => this._handlePresentationComponentCallback(data)
      }, PageName.PresentationPageComponent);
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCTS'),
      inputText: this.productsString,
      customPlaceholderLabel: (this.selectedMode != AccountPlanMode.VIEW  ? this.translate.instant('SELECT_PRODUCTS') : `${this.translate.instant('NO')} ${this.translate.instant('PRODUCTS')}`),
      id: 'products-field',
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isReadOnly: true,
      isDisabled: this.selectedMode === AccountPlanMode.VIEW,
      showArrow: this.selectedMode !== AccountPlanMode.VIEW,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getNameFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NAME'),
      inputText: this.planDataSetReference?.accountPlanName,
      inputValue: this.planDataSetReference?.accountPlanName,
      id: 'name-field',
      isDisabled: this.device.isOffline,
      placeholderLabel: this.translate.instant('NAME'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['name-field'] && _.isEmpty(this.planDataSetReference.accountPlanName),
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
        this._isClicked['name-field'] = true;
      }
    };
    return viewData;
  }

  public getAccountsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.accountsString,
      inputValue: this.accountsString,
      customPlaceholderLabel: this.translate.instant('NO_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: this.selectedMode !== AccountPlanMode.EDIT,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: this.accountsString && this.accountManagementService.selectedMode === AccountPlanMode.EDIT ? true : false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

 /* public plannedBudgetFormField() {
    const isDisabled = this.planDataSetReference?.ownerID != this.authService.user.systemUserID || this.planDataSetReference?.status === 'Completed' || this.device.isOffline || this.selectedMode === AccountPlanMode.VIEW;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PLANNED_BUDGET'),
      inputText: this.planDataSetReference?.indskr_plannedbudget ? this.dateTimeFormatsService.formattedCurrency(this.planDataSetReference.plannedBudgetFormatted) : '',
      inputValue: this.planDataSetReference?.indskr_plannedbudget,
      id: 'planned-budget-field',
      isDisabled: isDisabled,
      customPlaceholderLabel: isDisabled ?  this.translate.instant('NO_PLANNED_BUDGET'):  this.translate.instant('ENTER_PLANNED_BUDGET'),
      showArrow: !isDisabled,
      formFieldType: FormFieldType.INLINE_INPUT,
      inputType: 'number',
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  } */

  public getContactFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomersText),
      inputText: this.contactsString,
      inputValue: this.contactsString,
      customPlaceholderLabel: this.selectedMode != AccountPlanMode.VIEW ? this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.utilityService.globalCustomersText }) : this.translate.instant('NO') +' ' + this.utilityService.globalCustomersText ,
      id: 'contact-field',
      isReadOnly: true,
      isDisabled: this.selectedMode == AccountPlanMode.NEW || this.selectedMode === AccountPlanMode.EDIT ? false : this.contactsString ? false : true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: this.selectedMode !== AccountPlanMode.VIEW ? true : this.contactsString ? true : false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getEstimatedRevenueFormField(): IndFormFieldViewDataModel {
    if (this.planDataSetReference) { // when account plan is cancelled planDataSetReference will be null
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        isHidden: !(this.planDataSetReference.estimatedRevenue && this.planDataSetReference.estimatedRevenue > 0),
        label: this.translate.instant('OPPORTUNITIES_ESTIMATED_REVENUE'),
        inputText: this.dateTimeFormatsService.formattedCurrency((this.planDataSetReference.estimatedRevenureFormatted ? this.planDataSetReference.estimatedRevenureFormatted : '0.00')),
        placeholderLabel: '',
        id: 'estimated-revenue-field',
        isReadOnly: true,
        isDisabled: true,
      };
      return viewData;
    }
  }

  public getActualRevenueFormField(): IndFormFieldViewDataModel {
    if (this.planDataSetReference) { // when account plan is cancelled planDataSetReference will be null
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        isHidden: !(this.planDataSetReference.actualRevenue && this.planDataSetReference.actualRevenue > 0),
        label: this.translate.instant('OPPORTUNITIES_ACTUAL_REVENUE'),
        inputText: this.dateTimeFormatsService.formattedCurrency((this.planDataSetReference.actualRevenueFormatted ? this.planDataSetReference.actualRevenueFormatted : '0.00')),
        placeholderLabel: '',
        id: 'actual-revenue-field',
        isReadOnly: true,
        isDisabled: true,
      };
      return viewData;
    }
  }

  public getStartPeriodFormField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('START_PERIOD'),
      inputText: this.planDataSetReference && this.planDataSetReference.startDate ? this.startPeriod : '',
      customPlaceholderLabel: this.selectedMode === AccountPlanMode.VIEW ? '-' : this.translate.instant('SELECT_START_PERIOD'),
      startDateTimeValue: this.planDataSetReference?.startDate,
      endDateTimeValue: this.planDataSetReference?.endDate,
      fromViewPage: CurViewPageType.AccountPlan,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.selectedMode !== AccountPlanMode.EDIT,
      showArrow: true,
      isRequired: this.selectedMode === AccountPlanMode.EDIT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getEndPeriodFormField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('END_PERIOD'),
      inputText: this.planDataSetReference && this.planDataSetReference.endDate ? this.endPeriod : '',
      customPlaceholderLabel: this.selectedMode === AccountPlanMode.VIEW ? '-' : this.translate.instant('SELECT_END_PERIOD'),
      startDateTimeValue: this.planDataSetReference?.startDate,
      endDateTimeValue: this.planDataSetReference?.endDate,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.selectedMode !== AccountPlanMode.EDIT,
      showArrow: true,
      isRequired: this.selectedMode === AccountPlanMode.EDIT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  setShowMore(event) {
    this.showMore = !this.showMore;
  }

  private handleFormFieldEvent(id, event, eventName) {
    // if (this.device.isOffline) return;
    switch (id) {
      case "products-field":
        this.gotoProductSelection();
        break;
      case "contact-field":
        this.gotoContactSelection();
        break;
      case "account-field":
        this.openAccountsSection();
        break;
      case DateTimeFieldType.StartDateField:
        this.openStartDatePicker();
        break;
      case DateTimeFieldType.EndDateField:
        this.openEndDatePicker();
        break;
      case 'name-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleAccountPlanNameUpdate(event);
        }
        break;
      case "planned-budget-field":
        if (eventName && eventName == 'input_value_confirm') {
          this.updatePlannedBudgetField(event);
        }
        break;
      default:
        console.log("unhandled case");
    }
  }

  private handleAccountPlanNameUpdate(event) {
    if (event && event.target.value !== undefined) {
      this.planDataSetReference.accountPlanName = event.target.value;
      this.accountManagementService.tempReqPayload  = this.planDataSetReference;
      this.events.publish('account-field-change');
      // this.initPageTitle();
    }
  }

  private async updatePlannedBudgetField(event) {
    if (event && event.target.value !== undefined && this.planDataSetReference.indskr_plannedbudget != event.target.value) {
      this.planDataSetReference.indskr_plannedbudget = isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value);
      if (this.planDataSetReference.indskr_plannedbudget != null) {
        this.planDataSetReference.plannedBudgetFormatted = this.getFormattedValue(this.planDataSetReference.indskr_plannedbudget);
      } else {
        this.planDataSetReference.plannedBudgetFormatted = null;
      }

      if (this.selectedMode !== AccountPlanMode.EDIT) {
        await this.uiService.displayLoader();
        await this.accountManagementDataService.saveAccountPlan({ indskr_plannedbudget: this.planDataSetReference.indskr_plannedbudget }, this.planDataSetReference.ID);
        await this.uiService.dismissLoader();
      }
      this.accountManagementService.tempReqPayload = this.planDataSetReference;
      this.events.publish('account-field-change');
    }
  }

  private getFormattedValue(value: number): string {
    return this.dateTimeFormatsService.selectedSymbolPos.value == 'left'
      ? this.accountManagementService.currencySymbol + value.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : value.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.accountManagementService.currencySymbol;
  }

  private openAccountsSection() {
    this.accountService.accessedAccountListFrom = PageName.AccountPlanDetailsComponent;
    this.accountManagementService.currentAccountPlan = this.planDataSetReference;
    const selectedAccount = { id: this.planDataSetReference.accountId, name: this.planDataSetReference.accountName, statecode: this.planDataSetReference.accountState };
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      'listMode': this.selectedMode === AccountPlanMode.EDIT ? ComponentViewMode.ADDNEW : ComponentViewMode.READONLY,
      selectedAccountIds: [selectedAccount],
      callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
    }, PageName.AccountPageComponent).then(() => {
      if (!this.device.isMobileDevice) {
        this.events.publish(EventName.OPEN_SELECTED_ACCOUNT_DETAILS, selectedAccount);
      }
    });
  }

  private async _handleAccountComponentCallback(selectedAccounts) {
    const accountId = selectedAccounts[0].id;
    await this.removeAccountAffliated(accountId);
    this.planDataSetReference.accountId = selectedAccounts[0].id;
    this.planDataSetReference.accountName = selectedAccounts[0].name;
    this.accountManagementService.tempReqPayload  = this.planDataSetReference;
    this.events.publish('account-field-change');
    this.accountsString = selectedAccounts[0].name;
  }

  async removeAccountAffliated(newAccountId){
    if (!this.planDataSetReference.contactAccountAffs || this.planDataSetReference.contactAccountAffs.length === 0) return;
    const removeContactIds = [];
    // const removeAccIds = removedAccounts.map(({ id }) => id);
    const contactAccAffs = await this.contactService.getAccountContactAffiliationsFromDB();
    // const accountsExceptRemove = this.planDataSetReference.accounts.filter(({ id }) => !removeAccIds.includes(id)).map(({ id }) => id);

    this.planDataSetReference.contactAccountAffs.forEach(contactAccountAff => {
      const affliation = contactAccAffs.find(item => item['indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid'] === contactAccountAff['indskr_accountcontactaffiliationid']);
      if (affliation && this.planDataSetReference.accountId == affliation.accountid) {
        const otheraffliation = contactAccAffs.find(item => item['indskr_accountcontactaffiliation.indskr_contactid'] === contactAccountAff.contactId && newAccountId == item.accountid);
        if (otheraffliation) {
          contactAccountAff['indskr_accountcontactaffiliationid'] = otheraffliation['indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid']
        } else {
          removeContactIds.push(contactAccountAff.contactId);
        }
      }
    });

    this.planDataSetReference.contactAccountAffs = this.planDataSetReference.contactAccountAffs.filter((item) => !removeContactIds.includes(item.contactId));
    this.contactsString = this.getContactString;
  }

  private gotoContactSelection() {
    if (this.selectedMode == AccountPlanMode.NEW || this.selectedMode === AccountPlanMode.EDIT) {
      if (this.deviceOffline) return;
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
    } else {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
    }
    this.uiService.showNewActivity = false;
    this.contactService.accessedContactListFrom = PageName.AccountPlanDetailsComponent;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
      {
        selectedContactIds: this.planDataSetReference.contactAccountAffs ? this.planDataSetReference.contactAccountAffs.map(con => con.contactId) : [],
        selectedAccountIds: [this.planDataSetReference.accountId],
        callbackEvent: (data: any) => this._handleContactComponentCallback(data)
      }, PageName.AccountPlanDetailsComponent, { animate: false }, null);
  }

  private async _handleContactComponentCallback(selectedData: any[]) {
    const reqPayload = [];
    this.uiService.displayLoader();
    selectedData.forEach(data => reqPayload.push({ indskr_accountcontactaffiliationid: data['indskr_accountcontactaffiliationid'] }));
    if (this.selectedMode === AccountPlanMode.NEW) {

      if (!_.isEmpty(this.planDataSetReference.contactAccountAffs)) {
        this.planDataSetReference.contactAccountAffs.filter(contAccAff => !selectedData.map(data => data['indskr_accountcontactaffiliationid']).includes(contAccAff.indskr_accountcontactaffiliationid)).map(contAccAff => {
          reqPayload.push({ indskr_accountcontactaffiliationid: contAccAff.indskr_accountcontactaffiliationid, action: "DELETE" });
        })
      }

      this.accountManagementDataService.saveAccountPlan({ contactAccountAffs: reqPayload }, this.planDataSetReference.ID).then(() => {
        selectedData = _.orderBy(selectedData, 'contactFullName');
        this.planDataSetReference.contactAccountAffs = selectedData;
        this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
        this.contactsString = this.getContactString;
        this.uiService.dismissLoader();
      })
    } else {
      selectedData = _.orderBy(selectedData, 'contactFullName');
      this.planDataSetReference.contactAccountAffs = selectedData;
      this.accountManagementService.tempReqPayload = this.planDataSetReference;
      this.events.publish('account-field-change');
      this.contactsString = this.getContactString;
      this.uiService.dismissLoader();
    }

  }

  private _handlePresentationComponentCallback(selectedItems: []) {
    const selectedContentIds = [];
    selectedItems.forEach(con => {
      if (con['ioResourceId']) {
        selectedContentIds.push({ resourceId: con["ioResourceId"] });
      }
      if (con['ioDocumentId']) {
        selectedContentIds.push({ documentId: con["ioDocumentId"] });
      }
      if (con['ioPresentationId']) {
        selectedContentIds.push({ presentationId: con["ioPresentationId"] });
      }
    });
    if (!_.isEmpty(this.planDataSetReference.documents)) {
      this.planDataSetReference.documents.forEach(doc => {
        if (doc['ioResourceId'] && !selectedContentIds.some(con => (con['resourceId'] == doc['ioResourceId']))) {
          selectedContentIds.push({ resourceId: doc["ioResourceId"], action: "DELETE" });
        }
        if (doc['ioDocumentId'] && !selectedContentIds.some(con => (con['documentId'] == doc['ioDocumentId']))) {
          selectedContentIds.push({ documentId: doc["ioDocumentId"], action: "DELETE" });
        }
        if (doc['ioPresentationId'] && !selectedContentIds.some(con => (con['presentationId'] == doc['ioPresentationId']))) {
          selectedContentIds.push({ presentationId: doc["ioPresentationId"], action: "DELETE" });
        }
      })
    }
    this.uiService.displayLoader();
    this.accountManagementDataService.saveAccountPlan({ documents: selectedContentIds }, this.planDataSetReference.ID).then(() => {
      this.planDataSetReference.documents = selectedItems;
      this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
      this.uiService.dismissLoader();
    })
  }

  private async gotoProductSelection() {
    const selectedProductIds = [];
    if (!_.isEmpty(this.planDataSetReference.products)) {
      this.planDataSetReference.products.map(prod => selectedProductIds.push(prod.productID));
    }
    const options: SelectListData[] = _.orderBy(this.brandService.brands.map(prod => { return { id: prod.ID, title: prod.name, isSelected: selectedProductIds.includes(prod.ID) } }), 'title');
    const productsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('PRODUCTS'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: true,
        }
      },
      backdropDismiss: false
    });
    productsModal.present();
    productsModal.onDidDismiss().then(async (obj: any) => {
      const data = obj.data;
      if (data && data.isDone) {
        const selectedProducts = [];
        const productsRef = []
        data.selectedItems.forEach(selectedItem => {
          selectedProducts.push({ productId: selectedItem.id });
          productsRef.push({ productID: selectedItem.id, productName: selectedItem.title });
        });

        if (!_.isEmpty(this.planDataSetReference.products)) {
          this.planDataSetReference.products.filter(prod => !data.selectedItems.map(selectedItem => selectedItem.id).includes(prod.productID)).forEach(prod => selectedProducts.push({ productId: prod.productID, action: "DELETE" }));
        }

        if (this.selectedMode === AccountPlanMode.NEW) {
          await this.uiService.displayLoader();
          await this.accountManagementDataService.saveAccountPlan({ products: selectedProducts }, this.planDataSetReference.ID).then((accounPlan) => {
            this.planDataSetReference.products = productsRef;
            this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
          });
          await this.uiService.dismissLoader();
          this.productsString = this.planDataSetReference.products.map(o => {
            return o.productName;
          }).toString().split(',').join(', ');
        } else {
          this.planDataSetReference.products = productsRef;
          this.accountManagementService.tempReqPayload = this.planDataSetReference;
          this.events.publish('account-field-change');
          this.productsString = this.planDataSetReference.products.map(o => {
            return o.productName;
          }).toString().split(',').join(', ');
        }
      }
    })
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'ap-detail-strategy-documents':
        this.openResources();
        break;
      case 'add-expenses':
        this.addExpense();
        break;
      case 'edit-expenses':
        this.editExpense();
        break;
      case 'delete-expenses':
        this.deleteExpense();
        break;
      case 'add-account-plan-events':
        this.openEvents();
        break;
      default:
        console.error("unhandled error");
    }
  }

  // scrap accoun plan //

  showConfirmAlert() {
    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_ACCOUNT_PLAN'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_ACCOUNT_PLAN')
    }, this.translate.instant('YES'), this.translate.instant('NO')
    ).then(async res => {
      if (res.role == "ok") this.scrapAccountPlan();
    });
  }

  scrapAccountPlan() {
    let payload = { statecode: 1, statuscode: 548910002 };
    this.uiService.displayLoader();
    this.accountManagementDataService.saveAccountPlan(payload, this.data.ID).then(async () => {
      this.uiService.dismissLoader();
      await this.accountManagementDataService.scrapAccountPlanInLocal(this.data.ID);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.AccountManagementPageComponent);
      this.accountManagementService.isRightPaneNavActive = false;
    }).catch(err => console.log('error from scrap', err));
  }

  // associating events to account plan //

  private openEvents() {
    let startDate, endDate;
    const eventsSelectionViewData: EventsSelectionViewDataModel = {
      isReadOnlyMode: false,
      eventsDataSet: [],
      selectedEvents: this.associatedEvents,
      disableDoneButtonInOffline: true,
      viewMode: ComponentViewMode.ADDNEW,
      callbackFromSelectionComponent: (event: string, newSelectedEvents: Array<EventActivity>, data: any) => this._handledEventsSelection(event, newSelectedEvents),
    };

    if (this.selectedMode == AccountPlanMode.NEW) {   // when user open event just after creating account plan
      startDate = new Date(this.data.indskr_startdate).getTime();
      endDate = new Date(this.data.indskr_enddate).getTime();
    } else {
      startDate = this.data.startDate;
      endDate = this.data.endDate;
    }

    this.navService.pushWithPageTracking(EventsToolPageComponent, PageName.EventsToolPageComponent, { viewData: eventsSelectionViewData, startDate, endDate, from: 'account-plan' }, PageName.EventsToolPageComponent);
  }

  private async _handledEventsSelection(event, newSelectedEvents: Array<EventActivity>) {
    if (event && event == 'DONEBUTTONCLICK') {
      let payload: any = { events: [] };
      this.associatedEvents = [];

      if (this.data.events && this.data.events.length) { // if events already associated with accounts plan
        let events = this.setEventsPayload(this.data.events, newSelectedEvents, "remove"); // making payload to remove
        payload.events.push(...events);
        events = this.setEventsPayload(newSelectedEvents, this.data.events, "add"); // making payload to add
        payload.events.push(...events);
      } else {
        payload.events = newSelectedEvents.map(selectedEvent => {
          return { indskr_eventid: selectedEvent.ID }
        });
      }

      this.uiService.displayLoader();
      await this.accountManagementDataService.saveAccountPlan(payload, this.data.ID).then(() => {
        this.associatedEvents = _.orderBy(newSelectedEvents, [event => event.name.toLowerCase()]);
        let events = this.associatedEvents.map((event) => {
          return { ID: event.ID, name: event.name, statuscode: event.statuscode };
        })
        this.planDataSetReference.events = events;
        this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
        this.uiService.dismissLoader();
      }).catch((error) => {
        this.uiService.dismissLoader();
      });
    }
  }

  setEventsPayload(firstArray, secondArray, flag): any[] {
    const payload: any[] = [];
    firstArray.forEach((event: EventActivity) => {
      const index = secondArray.findIndex((eve: EventActivity) => eve.ID === event.ID);
      if (index < 0) {
        if (flag === "remove") payload.push({ action: "DELETE", indskr_eventid: event.ID });
        if (flag === "add") payload.push({ indskr_eventid: event.ID });
      }
    });
    return payload;
  }


  private addExpense() {
    this.navService.pushChildNavPageWithPageTracking(ExpensesDetails, PageName.ExpensesDetails, PageName.AccountPlanDetailsComponent, {
      isEditMode: false,
      selectedExpense: new Expense(Guid.create().toString(), null, null, null, [], this.accountManagementService.currencySymbol),
      accountPlanId: this.planDataSetReference.ID,
      callbackEvent: (data: any) => this._handleExpenseComponentCallback(data)
    });
  }

  private editExpense() {
    const selectedExpense = this.planDataSetReference.expenses.find(exp => exp['isChecked']);
    this.navService.pushChildNavPageWithPageTracking(ExpensesDetails, PageName.ExpensesDetails, PageName.AccountPlanDetailsComponent, {
      isEditMode: true,
      selectedExpense: _.cloneDeep(selectedExpense),
      accountPlanId: this.planDataSetReference.ID,
      callbackEvent: (data: any) => this._handleExpenseComponentCallback(data)
    });
  }

  private async _handleExpenseComponentCallback(data) {
    if (data?.isDone && data.selectedExpense) {
      if (this.deviceOffline) return;
      const index = this.planDataSetReference.expenses.findIndex(expense => expense.expensesId == data.selectedExpense.expensesId);
      if (data.deleted) {
        const payload = {
          indskr_expenseid: data.selectedExpense.expensesId,
          action: "DELETE"
        }
        if (index >= 0) {
          await this.uiService.displayLoader();
          await this.accountManagementDataService.saveAccountPlan({ indskr_name: this.planDataSetReference.accountPlanName, expenses: [payload] }, this.planDataSetReference.ID).then(() => {
            this.planDataSetReference.expenses.splice(index, 1);
            this.planDataSetReference.expenses.forEach(ex => ex['isChecked'] = false);
            this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
          }).finally(() => {
            this.uiService.dismissLoader();
          })
        }
      } else {
        const payload = {
          indskr_expenseid: data.selectedExpense.expensesId,
          indskr_amount: data.selectedExpense.amount,
          indskr_name: data.selectedExpense.description,
          indskr_expensecategory: 548910001 //expense for account plan
        }
        if (data.selectedExpense.notes) {
          const notes = [];
          data.selectedExpense.notes.forEach(note => {
            let notePayload;
            if (notes['deleted']) {
              if (index >= 0) {
                notePayload = {
                  annotationid: note.noteId,
                  action: "DELETED"
                }
              }
            } else {
              notePayload = {
                notetext: note.noteText,
                ownerid: note.ownerId,
                ownerName: note.ownerName,
                filename: note.documentName,
                filesize: note.documentSize,
                mimetype: note.documentMimeType,
                annotationid: note.noteId
              }
              if (!_.isEmpty(note.documentDataURL)) {
                notePayload['documentbody'] = note.documentDataURL
              }
            }
            notes.push(notePayload);
          })
          payload['notes'] = notes;
        }
        await this.uiService.displayLoader();
        await this.accountManagementDataService.saveAccountPlan({ indskr_name: this.planDataSetReference.accountPlanName, expenses: [payload] }, this.planDataSetReference.ID).then(() => {
          if (data.selectedExpense.notes) {
            data.selectedExpense.notes = data.selectedExpense.notes.filter(note => !note.isDeleted);
          }
          if (index >= 0) {
            this.planDataSetReference.expenses[index] = data.selectedExpense;
          } else {
            this.planDataSetReference.expenses.push(data.selectedExpense);
          }
          this.planDataSetReference.expenses.forEach(ex => ex['isChecked'] = false);
          this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
        }).finally(() => {
          this.uiService.dismissLoader();
        })
      }
      this.updateExpensesHeader();
      this._cd.detectChanges();
    }
  }

  private async deleteExpense() {
    if (this.deviceOffline) return;
    const payload = this.planDataSetReference.expenses.filter(exp => exp['isChecked']).map(exp => { return { indskr_expenseid: exp.expensesId, action: "DELETE" } });
    if (!_.isEmpty(payload)) {
      await this.uiService.displayLoader();
      await this.accountManagementDataService.saveAccountPlan({ indskr_name: this.planDataSetReference.accountPlanName, expenses: payload }, this.planDataSetReference.ID).then(() => {
        this.planDataSetReference.expenses = this.planDataSetReference.expenses.filter(exp => !exp['isChecked']);
        this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
        this.updateExpensesHeader();
        this._cd.detectChanges();
      }).finally(() => {
        this.uiService.dismissLoader();
      })
    }
  }

  onTextAreaFocus(value) {
    if (this.deviceOffline) return;
    this.strategy = value;
  }

  async onStrategyUpdate(value) {
    if (this.deviceOffline) return;
    if (value != this.strategy) {
      this.planDataSetReference.strategy = value;
      if(this.selectedMode === 1){
        this.accountManagementService.tempReqPayload  = this.planDataSetReference;
        this.events.publish('account-field-change');
        return;
      }
      await this.uiService.displayLoader();
      await this.accountManagementDataService.saveAccountPlan({ indskr_strategy: value }, this.planDataSetReference.ID).then((accounPlan) => {
        // this.planDataSetReference.strategy = value;
        this.accountManagementDataService.saveAccountPlanInLocal(this.planDataSetReference);
      });
      await this.uiService.dismissLoader();
    }
  }

  public expensesIsSelected(event) {
    event.stopPropagation();
    this.updateExpensesHeader();
  }

  private updateExpensesHeader() {
    const selectedExpenses = this.planDataSetReference.expenses.filter(exp => exp['isChecked']);
    this.expensesHeaderModel.controls.forEach(control => {
      if (control.id == 'delete-expenses') {
        control.isDisabled = !this.deviceOffline && !(selectedExpenses.length > 0);
      } else if (control.id == 'edit-expenses') {
        control.isDisabled = !this.deviceOffline && !(selectedExpenses.length == 1);
      }
    });
  }

  private async openStartDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime).getTime();
        if (this.planDataSetReference.endDate && valueDate > parseInt(this.planDataSetReference.endDate)) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'Account Plan', 'top', ToastStyle.DANGER);
        } else {
          this.uiService.displayLoader();
          if(await this.isActivityInPast(obj.data.startTime)){
            this.uiService.dismissLoader();
            this.notificationService.notify(this.translate.instant('ACCOUNT_PLAN_ACTIVITY_VALIDATION'), 'Account Plan', 'top', ToastStyle.DANGER);
            return;
          }
          this.uiService.dismissLoader();
          this.planDataSetReference.startDate = valueDate.toString();
          this.accountManagementService.tempReqPayload = this.planDataSetReference;
          this.events.publish('account-field-change');
        }
      }
    })
  }

  private async openEndDatePicker() {
    if (!this.planDataSetReference.startDate) return;
    const today = new Date().getTime();
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.endTime)) {
        const valueDate = new Date(obj.data.endTime).getTime();
        if (parseInt(this.planDataSetReference.startDate) > valueDate) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'Account Plan', 'top', ToastStyle.DANGER);
        }else if(valueDate < today){
          this.notificationService.notify(this.translate.instant('ACCOUNT_PLAN_CAN_NOT_BE_PAST'), 'Account Plan', 'top', ToastStyle.DANGER);
        } else {
          this.planDataSetReference.endDate = valueDate.toString();
          this.accountManagementService.tempReqPayload = this.planDataSetReference;
          this.events.publish('account-field-change');
        }
      }
    })
  }

  private async getDateTimePopover() {
    return await this.popoverCtrl.create({
      component: IndDatetimeFormComponent,
      componentProps: {
        currentViewPage: CurViewPageType.AccountPlan,
        startDateTimeValue: this.planDataSetReference?.startDate ? moment(new Date(parseInt(this.planDataSetReference.startDate))).format() : null,
        endDateTimeValue: this.planDataSetReference?.endDate ? moment(new Date(parseInt(this.planDataSetReference.endDate))).format() : null,
      },
      cssClass: "datetime-popover"
    });
  }

  private async isActivityInPast(newStartDate) {
    const allActivities = await this.getActivitiesByAccountPlan();
    const activityFollowup = this.accountManagementDataService.getFollowupActivity(this.planDataSetReference);
    const linkedMeeting = await this.accountManagementDataService.fetchMeetingsTimeline(this.planDataSetReference.ID);
    const linkedTimeline = await this.accountManagementDataService.fetchPhoneCallsTimeline(this.planDataSetReference.ID);

    allActivities.push(...activityFollowup)
    allActivities.push(...linkedMeeting)
    allActivities.push(...linkedTimeline)

    const isConflict = allActivities.some(actvity => {
      actvity.scheduledStart.setHours(0, 0, 0, 0);
      return actvity.scheduledStart < new Date(newStartDate).setHours(0,0,0,0);
    });

    return isConflict;
  }

  private async getActivitiesByAccountPlan(){
    let allActivities = [];
    allActivities = this.activityService.activities.filter((item: any) => {
      if (item.type === ActivityType.Appointment) {
        return item.associatedAccountPlans.some((it) => it.accountPlanId === this.planDataSetReference.ID);
      } else if (item.type === ActivityType.PhoneCall) {
        return item.accountPlanId === this.planDataSetReference.ID;
      } else if (item.type === ActivityType.FollowUp) {
        return item.accountPlanId === this.planDataSetReference.ID;
      }
    });
    return allActivities;
  }
}
