export const DefaultChartColors = ['#000000'];
export const DefaultChartColorOptions = ['#2E5BFF', '#01C5D7', '#F8C546', '#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'];

export const DefaultChartHeightForMobileView = (4 / 3 * 100) + '%';
export const DefaultChartHeightOffset = 190;
export const DefaultChartHeightForSplitView = (3 / 4 * 100) + '%';

export const DefaultChartOptions = {
  colors: DefaultChartColorOptions,
  chart: {
    type: 'column',
    backgroundColor: '#ffffff'
  },
  legend: {
    itemDistance: 10,
    layout : 'horizontal',
    verticalAlign: 'top',
    itemStyle: {
      fontSize: '11px',
      fontWeight: 'normal',
      color: DefaultChartColors[0]
    }
  },
  title: {
    text: '',
    align:'left',
    style: {
      color: DefaultChartColors[0]
    }
  },
  xAxis: {
    lineWidth: 1,
    lineColor: '#E0E7FF',
    tickLength: 0,
    labels: {
      style: {
        color: DefaultChartColors[0]
      }
    },
    categories: undefined,
  },
  yAxis: {
    title: {
      text: ''
    },
    labels:{
      style:{
        color: DefaultChartColors[0]
      }
    },
    gridLineColor: '#E0E7FF',
    gridLineDashStyle: 'ShortDash',
    lineWidth: 1,
    lineColor: '#E0E7FF',
  },
  credits:{
    enabled: false
  },
  series: [],
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      maxPointWidth: 100,
    },
    series: {
      borderRadius: 5,
    }
  }
}

export const EDGE_ANALYTICS_MEETING_REPORT_REV_KEY = 'edge_meeting_rev';
export const EDGE_ANALYTICS_MEETING_REPORT_REV = '1.0';
