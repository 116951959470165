<ion-header>
    <ind-page-title [viewData]="newActivityPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
    <ion-list>
        <ind-section-header *ngIf="isMeetingPresentation" [viewData]='newActivityHeader' (onControlClick)='onPageTitleControlClick($event)'>
        </ind-section-header>
        <ion-item-group class="toolActivtiesList">
          <ion-item *ngIf="NewActivitySource === 'MeetingPresentation'" (click)="setActiveView('MeetingPresentation')">
            {{'CONTENT' | translate}}
            <ion-icon name="checkmark" color="primary"
              *ngIf="uiService.activeViewNewMeeting == 'MeetingPresentation' && !uiService.activeViewNewMeetingType" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasProcedureLogEnabled" (click)="setActiveView('surgeryorder')">
            {{'NEW_ACTIVITY_PROCEDURE_LOG' | translate }}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'surgeryorder'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="isMeetingEnabled" (click)="setActiveView('meeting')">
            {{'MEETING' | translate}}
            <ion-icon name="checkmark" color="primary"
              *ngIf="uiService.activeViewNewMeeting == 'meeting' && !uiService.activeViewNewMeetingType" slot="end"></ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasTimeoffEnabled" (click)="setActiveView('timeoff')">
            {{'TIME_OFF' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'timeoff'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="isFollowUpActionEnabled" (click)="setActiveView('follow-up-action')">
            {{'FOLLOW_UP_ACTION' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'follow-up-action'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasPhonecallEnabled" (click)="setActiveView('phonecall')" [ngClass]="{'greyedOut':noPhoneNumber}">
            {{'NEW_ACTIVITY_PHONECALL' | translate }}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'phonecall'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasDirectMessagingEnabled" (click)="setActiveView('email')">
            {{'MESSAGE' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'email'" slot="end"></ion-icon>
          </ion-item>
        
          <ion-item *ngIf="isAccountVisitEnabled" (click)="setActiveView('accountVisit')">
            {{'ACCOUNT_VISIT' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'accountVisit'"
              slot="end"></ion-icon>
          </ion-item>
        
          <ion-item (click)="setActiveView('order')" *ngIf="isOrderCreationEnabled">
            {{'NEW_ACTIVITY_SALES_ORDER' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'order'" slot="end"></ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasSamplingEnabled" (click)="setActiveView('allocation')"
            [ngClass]="{'greyedOut':noEligibleAllocation}">
            {{'ALLOWCATION_ORDER' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'allocation'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasCustomerInquiryEnabled" (click)="setActiveView('customerinquiry')">
            {{translatedCustomerInquiryText}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'customerinquiry'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item (click)="setActiveView('meeting', 'live')" *ngIf="hasLiveMeetEnabled">
            {{'LIVE_MEETING' | translate}}
            <ion-icon name="checkmark" color="primary"
              *ngIf="uiService.activeViewNewMeeting == 'meeting' && uiService.activeViewNewMeetingType === 'live'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasStoreCheckEnabled" (click)="setActiveView('storecheck')"
            [ngClass]="{'greyedOut':this.device.isOffline}">
            {{'STORE_CHECK' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'storecheck'"
              slot="end"></ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasKitBookingEnabled" [ngClass]="{'greyedOut': device.isOffline}"
            (click)="setActiveView('kit_booking')">
            {{'KIT_BOOKING' | translate }}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'kit_booking'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasXperincesEnabled" (click)="setActiveView('new-insight')"
            [ngClass]="{'greyedOut': device.isOffline}">
            {{NewActivitySource === 'Agenda' ? ('XPERIENCES_INSIGHTS_PLUS' | translate) : translatedCustomerInsightPlsText }}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'new-insight'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="hasOpportunityEnabled" (click)="setActiveView('opportunity')">
            {{'OPPORTUNITY' | translate }}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'opportunity'" slot="end">
            </ion-icon>
          </ion-item>
        
          <ion-item *ngIf="isSurveyEnabled" (click)="setActiveView('survey')">
            {{'SURVEY' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'survey'" slot="end"></ion-icon>
          </ion-item>

          <ion-item (click)="setActiveView('quote')" *ngIf="isQuoteCreationEnabled">
            Quote
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'quote'" slot="end"></ion-icon>
          </ion-item>
          
          <!-- [ngClass]="{'greyedOut':noEligibleAllocation}" need to add disabling if no eligible customers are there-->
          <ion-item (click)="setActiveView('consent')" *ngIf="isConsentCreationEnabled">
            {{'CONSENT' | translate}}
            <ion-icon name="checkmark" color="primary" *ngIf="uiService.activeViewNewMeeting == 'consent'"
              slot="end"></ion-icon>
          </ion-item>
      
        </ion-item-group>


        <div *ngIf="uiService.activeViewNewMeeting == 'meeting' || uiService.activeViewNewMeeting == 'accountVisit' || uiService.activeViewNewMeeting == 'phone' || uiService.activeViewNewMeeting == 'phonecall'" class='sectionsList meeting-time section-time'>
            <!-- <ion-item-divider class="ion-item-text" color="light">
                {{'TIME_CAP' | translate }}
            </ion-item-divider> -->

            <ind-section-header [viewData]='{id:"new-category",title: translate.instant("TIME_CAP")}'></ind-section-header>
            <ion-item *ngIf="uiService.activeViewNewMeeting == 'meeting' && uiService.activeViewNewMeetingType === 'live'" class="selectEvent">
                <ion-label>{{'EVENT' | translate }}</ion-label>
                <ion-select class="eventSelection" *ngIf="(msEventService.events | async) as events" interface="popover" placeholder="Select Event" [(ngModel)]="selectedEvent" [interfaceOptions]="{cssClass: 'events-popover'}">
                    <ion-select-option disabled *ngIf="events.length === 0" [value]='false'>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-select-option>
                    <ion-select-option *ngFor="let event of events" [value]='event'>{{event.name}}</ion-select-option>
                </ion-select>
            </ion-item>

            <ng-container *ngIf="!uiService.activeViewNewMeetingType || (uiService.activeViewNewMeeting == 'meeting' && !!selectedEvent)">
              <ion-row>
                <ion-col size="6"><ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form></ion-col>
                <ion-col size="6"><ind-datetime-form *ngIf="startTimeField" [viewData]="startTimeField"></ind-datetime-form></ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="6"><ind-datetime-form *ngIf="endDateField && !endDateField.isHidden" [viewData]="endDateField"></ind-datetime-form></ion-col>
                <ion-col size="6"><ind-datetime-form *ngIf="endTimeField && !endTimeField.isHidden" [viewData]="endTimeField"></ind-datetime-form></ion-col>
              </ion-row>
              <activity-conflict-alert-msg *ngIf="displayConflictAlert" [msg]="conflictMsg"></activity-conflict-alert-msg>
              <ind-datetime-form *ngIf="durationField" [viewData]="durationField"></ind-datetime-form>
            </ng-container>
        </div>

        <div *ngIf="uiService.activeViewNewMeeting == 'storecheck'" class='sectionsList meeting-time section-time'>
          <ind-section-header [viewData]='{id:"new-category",title: translate.instant("TIME_CAP")}'></ind-section-header>
          <ion-row>
            <ion-col size="6">
              <ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form>
            </ion-col>
            <ion-col size="6">
              <ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form>
            </ion-col>
          </ion-row>
        </div>

        <div *ngIf="uiService.activeViewNewMeeting == 'follow-up-action'" class="sectionsList section-followUp-category">
            <ind-section-header [viewData]='{id:"new-category",title: translate.instant("CATEGORY")}'></ind-section-header>
            <!--
            <ion-item-divider class="ion-item-text" color="light">
                {{'CATEGORY' | translate}}
            </ion-item-divider> -->
            <ng-container *ngIf="isNoneTaskEnabled">
              <ion-item (click)="selectFollowupActionCategory('none')">
                <ion-checkbox [checked]="followUpActionCategory == 'none'"> </ion-checkbox>
                <ion-label>{{'NONE' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
            <ng-container *ngIf="isAccountPlanTaskEnabled">
              <ion-item (click)="selectFollowupActionCategory('accountplantask')">
                <ion-checkbox [checked]="followUpActionCategory == 'accountplantask'"> </ion-checkbox>
                <ion-label>{{'NEW_ACTIVITY_ACCOUNT_PLAN_ACTION' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
            <ng-container *ngIf="isScientificPlanTaskEnabled">
              <ion-item (click)="selectFollowupActionCategory('scientificplantask')">
                <ion-checkbox [checked]="followUpActionCategory == 'scientificplantask'"> </ion-checkbox>
                <ion-label>{{'NEW_ACTIVITY_SCIENTIFIC_PALN_ACTION' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
            <ng-container *ngIf="isOpportunityTaskEnabled">
              <ion-item (click)="selectFollowupActionCategory('opportunitytask')">
                <ion-checkbox [checked]="followUpActionCategory == 'opportunitytask'"> </ion-checkbox>
                <ion-label>{{'NEW_ACTIVITY_OPPORTUNITY_ACTION' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
            <ng-container *ngIf="isMeetingTaskEnabled">
              <ion-item (click)="selectFollowupActionCategory('meetingtask')">
                <ion-checkbox [checked]="followUpActionCategory == 'meetingtask'"> </ion-checkbox>
                <ion-label>{{'NEW_ACTIVITY_MEETING_ACTION' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
            <ng-container *ngIf="isMarketingPlanTaskEnabled">
              <ion-item (click)="selectFollowupActionCategory('marketingplantask')">
                <ion-checkbox [checked]="followUpActionCategory == 'marketingplantask'"> </ion-checkbox>
                <ion-label>{{'MARKETING_BUSINESS_PLAN_ACTION' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
            <ng-container *ngIf="isEventFollowUpActionEnabled">
              <ion-item (click)="selectFollowupActionCategory('eventtask')">
                <ion-checkbox [checked]="followUpActionCategory == 'eventtask'"> </ion-checkbox>
                <ion-label>{{'EVENT_ACTION' | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ng-container>
        </div>

        <div *ngIf="uiService.activeViewNewMeeting == 'timeoff'" class="sectionsList section-newTimeOff">
            <new-time-off #timeOffCmp [displayConflictAlert]="displayConflictAlert" [uuid]="uuid"></new-time-off>
        </div>

        <div *ngIf="uiService.activeViewNewMeeting == 'customerinquiry'" class="sectionsList section-customerinquiry-category">
            <!-- <ion-item-divider class="ion-item-text" color="light">
                {{'CATEGORY' | translate | uppercase}}
            </ion-item-divider> -->
            <ind-section-header [viewData]='{id:"new-category",title: translate.instant("CATEGORY")}'></ind-section-header>

            <!--<ion-item class="type-selection" *ngFor="let item of caseManagementService.caseTypeList;">
        <ion-label>{{item.name}}</ion-label>
        <ion-checkbox [checked]="item.id == selectedType" (ionChange)="toggle(item, $event)">
        </ion-checkbox>
      </ion-item>-->
            <!--Due to Bug: TC-1118 > REVERTING TO PREVIOUS VERSION-->
            <ion-item-group *ngFor="let item of caseManagementService.caseTypeList" (click)="selectCaseManagementType(item)">
              <ion-item>
                <!-- <ion-icon color="primary" class="radio-button" [name]="type == item ? 'checkmark-circle' : 'radio-button-off'" slot="start"></ion-icon> -->
                  <ion-checkbox [checked]="type == item"> </ion-checkbox>
                  <ion-label>{{item.name}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>
        </div>
        <div *ngIf="uiService.activeViewNewMeeting == 'email'" class="sectionsList section-email-channel">
            <!-- <ion-item-divider class="ion-item-text" color="light">
                {{'NEW_ACTIVITY_CHANNEL' | translate }}
            </ion-item-divider> -->
            <ind-section-header [viewData]='{id:"new-channel",title: translate.instant("NEW_ACTIVITY_CHANNEL")}'></ind-section-header>

            <!--<ion-item class="type-selection" *ngFor="let item of caseManagementService.caseTypeList;">
        <ion-label>{{item.name}}</ion-label>
        <ion-checkbox [checked]="item.id == selectedType" (ionChange)="toggle(item, $event)">
        </ion-checkbox>
      </ion-item>-->
            <!--Due to Bug: TC-1118 > REVERTING TO PREVIOUS VERSION-->
            <ion-item-group *ngFor="let channel of (directMessagingService.channelList | async | orderBy)" (click)="selectMessagingType(channel)">
              <ion-item>
                <!-- <ion-icon color="primary" class="radio-button" [name]="selectedMessagingChannelType === channel ? 'checkmark-circle' : 'radio-button-off'" slot="start"></ion-icon> -->
                  <ion-checkbox [checked]="selectedMessagingChannelType?.id == channel?.id">
                  </ion-checkbox>
                  <ion-label>{{channelNameTranslation(channel.title) | translate}}</ion-label>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>
        </div>
        <div *ngIf="uiService.activeViewNewMeeting == 'new-insight' && NewActivitySource === 'Agenda'" class="sectionsList section-email-channel">
          <ind-section-header [viewData]='{id:"new-insights-header",title: translate.instant("XPERIENCES_NEW_INSIGHTS")}'></ind-section-header>
            <ion-item (click)="selectInsightType(insightType.CUSTOMER_INSIGHT)">
              <ion-checkbox [checked]="selectedInsightType == insightType.CUSTOMER_INSIGHT"></ion-checkbox>
              <ion-label>{{translatedCustomerInsightPlsText}}</ion-label>
            </ion-item>
            <div class="separationLine"></div>
            <ion-item (click)="selectInsightType(insightType.GENERAL_INSIGHT)">
              <ion-checkbox [checked]="selectedInsightType == insightType.GENERAL_INSIGHT"></ion-checkbox>
              <ion-label>{{'XPERIENCES_GENERAL_INSIGHTS_PLUS' | translate}}</ion-label>
            </ion-item>
            <div class="separationLine"></div>
      </div>
      <div *ngIf="uiService.activeViewNewMeeting == 'surgeryorder' && hasProcedureLogEnabled " class="sectionsList section-procedure-log">
        <ind-section-header [viewData]='{id:"new-category",title: translate.instant("CATEGORY")}'></ind-section-header>
        <ng-container *ngIf="hasProcedureLogConfigEnabled && hasBulkProcedureLogConfigEnabled">
          <ion-item (click)="procedureLogSelectionType('procedureLog')">
            <ion-checkbox [checked]="procedureLogSelection == 'procedureLog'"></ion-checkbox>
            <ion-label>{{'PROCEDURE_LOG' | translate}}</ion-label>
          </ion-item>
          <div class="separationLine"></div>
          <div class="margin-25left" *ngIf="procedureLogSelection == 'procedureLog'">
            <ion-item (click)="selectProcedureCategory('procedureDay')">
              <ion-checkbox [checked]="procedureCategory == 'procedureDay'"></ion-checkbox>
              <ion-label>{{'PROCEDURE_DAY' | translate}}</ion-label>
            </ion-item>
            <div class="separationLine" *ngIf="!authService.user.buSettings || !authService.user.buSettings['indskr_noprocedureday']"></div>
            <ion-item (click)="selectProcedureCategory('noProcedureDay')" *ngIf="!authService.user.buSettings || !authService.user.buSettings['indskr_noprocedureday']">
              <ion-checkbox [checked]="procedureCategory == 'noProcedureDay'"></ion-checkbox>
              <ion-label>{{'NO_PROCEDURE_DAY' | translate}}</ion-label>
            </ion-item>
          </div>
          <div class="separationLine"></div>
        </ng-container>
        <ng-container *ngIf="hasProcedureLogConfigEnabled && !hasBulkProcedureLogConfigEnabled">
          <ion-item (click)="selectProcedureCategory('procedureDay')">
            <ion-checkbox [checked]="procedureCategory == 'procedureDay'"></ion-checkbox>
            <ion-label>{{'PROCEDURE_DAY' | translate}}</ion-label>
          </ion-item>
          <div class="separationLine" *ngIf="!authService.user.buSettings || !authService.user.buSettings['indskr_noprocedureday']"></div>
          <ion-item (click)="selectProcedureCategory('noProcedureDay')" *ngIf="!authService.user.buSettings || !authService.user.buSettings['indskr_noprocedureday']">
            <ion-checkbox [checked]="procedureCategory == 'noProcedureDay'"></ion-checkbox>
            <ion-label>{{'NO_PROCEDURE_DAY' | translate}}</ion-label>
          </ion-item>
          <div class="separationLine"></div>
        </ng-container>
        <ng-container *ngIf="hasBulkProcedureLogConfigEnabled">
          <ion-item (click)="selectProcedureCategory('procedure_tracker')">
            <ion-checkbox [checked]="procedureCategory == 'procedure_tracker'"></ion-checkbox>
            <ion-label>{{'BULK_PROCEDURE_LOG' | translate}}</ion-label>
          </ion-item>
          <div class="separationLine"></div>
        </ng-container>
      </div>
      <div *ngIf="uiService.activeViewNewMeeting == 'consent'" class="sectionsList section-consent-customers">
        <ind-section-header [viewData]='{id:"all-customers",title: translate.instant("ALL_CUSTOMERS")}'></ind-section-header>
        <ion-item-group *ngFor="let customer of (activityOfflineService.selectedActivity['contacts'])" (click)="selectConsentCustomer(customer)">
          <ion-item *ngIf="!customer.isguest">
            <!-- <ion-icon color="primary" class="radio-button" [name]="selectedMessagingChannelType === channel ? 'checkmark-circle' : 'radio-button-off'" slot="start"></ion-icon> -->
              <ion-checkbox [checked]="selectedConsentCustomer?.ID == customer?.ID">
              </ion-checkbox>
              <ion-label>{{customer.fullname}}</ion-label>
              <img slot="end" [src]="ContactStatusDisplay[customer.connectionState]" width="14" class="contact-state-icon" />
          </ion-item>
          <div *ngIf="!customer.isguest" class="separationLine"></div>
        </ion-item-group>
        <ion-item class="emptyListText" *ngIf="(activityOfflineService.selectedActivity['contacts']).length==0">
          <ion-label>{{'NO_CUSTOMER' | translate}}</ion-label>
        </ion-item>
    </div>
    </ion-list>
</ion-content>
<io-spinner [displayText]="'NEW_ACTIVITY_SPINNER' | translate" *ngIf="isLoaderOn"></io-spinner>
