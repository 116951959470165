import { FormFieldType } from './../models/indNewActivityDataModel';
import { ConfigFieldOptionValue } from './../classes/activity/activity.class';
import { Endpoints } from './../../config/endpoints.config';
import { addDays, differenceInDays, getDaysInMonth, isSaturday, isSunday, isThisMonth, startOfMonth } from 'date-fns';
import { Subscription } from 'rxjs';

export function parseTextAfter(text: string, delimiter: string): string {
  let parsed = text;
  if (parsed.indexOf(delimiter) !== -1) {
    parsed = parsed.split(delimiter).pop();
  }
  return parsed;
}
export function parseTextBefore(text: string, delimiter: string): string {
  let parsed = text;
  if (parsed.indexOf(delimiter) !== -1) {
    parsed = parsed.split(delimiter)[0];
  }
  return parsed;
}

export function unsubscribeSubscriptionArray(subArray: Subscription[]) {
  if (Array.isArray(subArray)) {
    for (let i = 0; i < subArray.length; i++) {
      const sub: Subscription = subArray[i];
      sub.unsubscribe();
    }

    subArray = [];
  }
}

export function getWorkingDaysOfMonth(yearMonth: number, includeSaturday = true) {
  let workingDays = 0;
  if (!isNaN(yearMonth) && yearMonth > 200000 && yearMonth < 1000000) {
    try {
      const year = parseInt(yearMonth.toString().slice(0,4));
      const month = parseInt(yearMonth.toString().slice(4,6));
      const date = new Date(year, month - 1, 1);
      const startDate = startOfMonth(date);
      let daysToCount = 0;
      let daysToSubtract = 0;

      if (!isThisMonth(date)) {
        // Previous month
        daysToCount = getDaysInMonth(date);
      } else {
        // This month
        daysToCount = differenceInDays(new Date(), startDate);
      }

      for (let i = 0; i < daysToCount; i++) {
        const curDate = addDays(startDate, i);
        if (isSunday(curDate) || (!includeSaturday && isSaturday(curDate))) {
          daysToSubtract++;
        }
      }

      workingDays = daysToCount - daysToSubtract;
    } catch (error) {
      console.error('getWorkingDaysOfMonth: ', error);
    }
  }
  return workingDays;
}

export function convertTimestampStringToISODateFormat(numberString: string, format: 'date' | 'dateTime'): string | undefined {
  let formattedString: string;
  try {
    const timestamp: number = numberString !== null ? Number(numberString) : undefined;
    formattedString = convertTimestampNumberToISODateFormat(timestamp, format);
  } catch (error) {
    console.error('convertTimestampStringToISODateFormat: ', error);
  }
  return formattedString;
}
export function convertTimestampNumberToISODateFormat(timestamp: number, format: 'date' | 'dateTime'): string | undefined {
  let formattedString: string;
  try {
    if (!isNaN(timestamp)) {
      const date = new Date(timestamp);
      formattedString = getISOFormattedDate(date, format);
    } else {
      console.error('convertTimestampNumberToISODateFormat: not a number!: ', timestamp);
    }
  } catch (error) {
    console.error('convertTimestampNumberToISODateFormat: ', error);
  }

  return formattedString;
}
export function getISOFormattedDate(date: Date, format: 'date' | 'dateTime'): string | undefined {
  let formattedString: string;
  try {
    formattedString = date.toJSON();
    formattedString = format === 'date' ? formattedString.split('T')[0] : formattedString;
  } catch (error) {
    console.error('getISOFormattedDate: ', error);
  }
  return formattedString;
}

// Returns index of the element in the array or
// (-m - 1) where m is the insertion point for the new element
export function binarySearchInsertionIdx(
  array: any[],
  el: number,
  compareFn: (a: number, b: number) => number,
  attributeGetterFn?: (o: any) => number
): number | null {
  try {
    let m = 0;
    let n = array.length - 1;
    while (m <= n) {
      const k = (n + m) >> 1;
      const arrayValue = attributeGetterFn ? attributeGetterFn(array[k]) : array[k];
      const cmp = compareFn(el, arrayValue);
      if (cmp > 0) {
        m = k + 1;
      } else if (cmp < 0) {
        n = k - 1;
      } else {
        return k;
      }
    }
    return -m - 1;
  } catch (error) {
    console.error('binarySearch: ', array, el, compareFn, attributeGetterFn);
    return null;
  }
}

export function getTodayTimestamp(h?: number, m?: number, s?: number, ms?: number): number | null {
  let timestamp: number = null;

  try {
    const today: Date = new Date();
    today.setHours(
      isNaN(h) ? 0 : h,
      isNaN(m) ? 0 : m,
      isNaN(s) ? 0 : s,
      isNaN(ms) ? 0 : ms,
    );
    timestamp = today.getTime();
  } catch (error) {
    console.error('getTodayTimestamp: ', error);
  }

  return timestamp;
}

export enum PendingActionType {
  FOLLOWUP = 'followupTask',
  TIMEOFF = 'timeoff',
  ADJUSTMENT = 'adjustment',
  CALL_PLAN = 'callplan',
  SHIPMENT = "shipments",
  TRANSFER = "transfers",
  ORDER = "indskr_order",
  COACHING="coaching"
}

export function getConfigFieldPickListRequestURL(configType: string, entityName: string, fieldName: string): string {
  let requestURL: string = '';
  switch (configType) {
    case 'Picklist':
      requestURL = Endpoints.activites.GET_CONFIG_TYPE_OPTIONSET_VALUES;
      requestURL = requestURL.replace('{{logicalEntityName}}', "'" + entityName + "'");
      requestURL = requestURL.replace('{{attributeLogicalName}}', "'" + fieldName + "'");
      break;
    case 'Virtual':
      requestURL = Endpoints.activites.GET_CONFIG_TYPE_MULTISELECT_OPTIONSET_VALUES;
      requestURL = requestURL.replace('{{logicalEntityName}}', "'" + entityName + "'");
      requestURL = requestURL.replace('{{attributeLogicalName}}', "'" + fieldName + "'");
      break;
    default:
      console.warn("getConfigFieldPickListRequestURL: Unhandled config field type when fetching option values", configType, entityName, fieldName);
      break;
  }
  return requestURL;
}

export function parseConfigFieldPicklistResponse(
  response: any,
  activityName: string,
  fieldName: string,
  configType: string,
  fieldLabel: string
): ConfigFieldOptionValue[] {
  let result: ConfigFieldOptionValue[] = [];
  let index = 1;
  let optionSetName = response.OptionSet.Name;
  if (optionSetName) {
    response.OptionSet.Options.forEach(option => {
      let keyLabel = option.Label.UserLocalizedLabel? option.Label.UserLocalizedLabel.Label : option.Label.LocalizedLabels[0].Label;
      //let keyLabel = (option.Label.LocalizedLabels && option.Label.LocalizedLabels[0].Label);
      let keyValue = option.Value;
      if (keyLabel !== undefined && keyValue !== undefined) {
        let optionValue: ConfigFieldOptionValue = {
          activity: activityName,
          fieldName: fieldName,
          fieldLabel: fieldLabel,
          label: keyLabel,
          value: keyValue,
          valueType: optionSetName,
          optionSetType: configType,
          order: index++
        };
        result.push(optionValue);
      }
    });
  }
  return result;
}

export function getConfigFormFieldViewDataModelId(fieldType: string, fieldName?: string) {
  let id: string;
  switch (fieldType) {
    case 'String':
      id = 'appConfig-text-field';
      break;
    case 'Uniqueidentifier':
      id = 'appConfig-Uniqueidentifier-field';
      break;
    case 'Boolean':
      id = 'appConfig-Boolean-field';
      break;
    case 'DateTime':
      id = 'appConfig-DateTime-field';
      break;
    case 'Lookup':
      id = 'appConfig-Lookup-field';
      break;
    case 'Picklist':
      id = 'appConfig-Picklist-field';
      break;
    case 'Virtual':
      id = 'appConfig-MultiSelectPicklist-field';
      break;
    case 'Owner':
      id = 'appConfig-Owner-field';
      break;
    case 'Currency':
      id = 'appConfig-Currency-field';
      break;
    case 'Money':
      id = 'appConfig-Money-field';
      break;
    case 'Integer':
      id = 'appConfig-Integer-field';
      break;
    case 'Memo':
      id = 'appConfig-Memo-field';
      break;
    case 'EntityName':
      id = 'appConfig-EntityName-field';
      break;
    case 'Decimal':
      id = 'appConfig-Decimal-field';
      break;
    case 'BigInt':
      id = 'appConfig-BigInt-field';
      break;
    default:
      console.error('getConfigFormFieldViewDataModelId: Unhandled switch case statement: ', fieldType);
      break;
  }
  if (id && fieldName) {
    id = id + '-' + fieldName;
  }
  return id;
}

function convertReadOnlyValue(readOnly: string | boolean): boolean {
  let returnValue = false;
  returnValue = (readOnly === "false" || readOnly === false || readOnly === undefined || readOnly === null) ? returnValue : true;
  return returnValue;
}

export function getConfigFormFieldIsReadOnly(fieldType: string, readOnly: boolean): boolean {
  let isReadOrDisabled: boolean = false;
  switch (fieldType) {
    case 'String':
    case 'Uniqueidentifier':
    case 'DateTime':
    case 'Integer':
    case 'BigInt':
    case 'Decimal':
    case 'Memo':
    case 'Owner':
    case 'Currency':
    case 'Money':
    case 'EntityName':
      isReadOrDisabled = convertReadOnlyValue(readOnly);
      break;

    case 'Boolean':
    case 'Lookup':
    case 'Picklist':
    case 'Virtual':
      isReadOrDisabled = true;
      break;

    default:
      break;
  }
  return isReadOrDisabled;
}

export function getConfigFormFieldType(fieldType: string): any {
  switch (fieldType) {
    case 'String':
    case 'Uniqueidentifier':
    case 'Integer':
    case 'BigInt':
    case 'Decimal':
    case 'Currency':
    case 'Money':
    case 'EntityName':
    case 'Memo':
    case 'Owner':
      return FormFieldType.INLINE_INPUT;
    case 'Lookup':
      return FormFieldType.POPOVER_SELECT;
    case 'Boolean':
    case 'DateTime':
    case 'Picklist':
    case 'Virtual':
      return FormFieldType.POPOVER_SELECT;
    default:
      break;
  }
}

export function getConfigFormInputType(fieldType: string): any {
  let inputType: 'number' | 'text' = 'text';
  switch (fieldType) {
    case 'Integer':
    case 'BigInt':
    case 'Decimal':
    case 'Currency':
    case 'Money':
      inputType = 'number';
      break;
    default:
      break;
  }
  return inputType;
}

export function configFieldSortFn(a, b) {
  if (a.order && b.order) {
    if (a.order > b.order) return 1;
    else if (a.order < b.order) return -1;
    else {
      return a.fieldLabel.toLowerCase() < b.fieldLabel.toLowerCase() ? -1 : 1;
    }
  } else if (a.order || b.order) {
    if (a.order === null) {
      return 1;
    } else if (b.order === null) {
      return -1;
    }
    if (a.order > b.order) return 1;
    else if (a.order < b.order) return -1;
    else {
      return a.fieldLabel.toLowerCase() < b.fieldLabel.toLowerCase() ? -1 : 1;
    }
  }
}

export function sortConfigFieldsByOrder(a, b) {
  const orderA = a.order === undefined ? Infinity : a.order;
  const orderB = b.order === undefined ? Infinity : b.order;
  return orderA - orderB;
}

export function emailValidation(email: string): boolean {
  let isValid = false;

  if (email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  )) {
    isValid = true;
  }

  return isValid;
}

export function sortObjArrayByStringField(sortByKey: string, descending = false) {
  const key = function(x) {
    return x[sortByKey].toLowerCase();
  }
  const reverse = !descending ? 1 : -1;

  return (a: any, b: any) => {
    const fieldA = key(a);
    const fieldB = key(b);
    if (fieldA < fieldB) {
      return reverse * -1;
    }
    if (fieldA > fieldB) {
      return reverse * 1;
    }
    return 0;
  }
}

export function sortObjArrayByChineseStringField(array: any[], sortByKey: string, descending = false) {
  return !descending
    ? array?.sort((a, b) => a[sortByKey].localeCompare(b[sortByKey], ["zh-CN-u-co-pinyin"]))
    : array?.sort((a, b) => b[sortByKey].localeCompare(a[sortByKey], ["zh-CN-u-co-pinyin"]))
}