import { EventHandler } from '@omni/events';
import { AppointmentActivity } from "./../../classes/activity/appointment.activity.class";
import { ActivityService } from "./../activity/activity.service";
import {
  Resource,
  IMeetingResourceStatus,
  IResource,
  ResourceParticipantStatus,
  OfflineResource
} from "./../../classes/resource/resource.class";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Contact } from "../../classes/contact/contact.class";

import { Events } from '@omni/events';
import { SelectedSuggestionPillDataModel } from './../../models/search-config-data-model';
import { SearchConfigService } from './../search/search-config.service';
import { DiskService } from './../disk/disk.service';
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";

export enum ResourceView {
  MENU = "menu",
  PUSH = "push",
  ADDTOEMAIL = "addtoemail",
  DOCUMENTS = "documents",
  MEETING = "meeting"
}

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  public allResources: Array<Resource>;
  public selectedResourceList: Resource[] = [];
  private highlightedResource$ = new BehaviorSubject<Resource>(null);
  public viewMode: ResourceView = ResourceView.MENU;
  highlightedResource = this.highlightedResource$.asObservable();
  public activeResource: Resource;

  private resourceSelectionSource = new BehaviorSubject<Resource[]>(undefined);
  resourceSelectionObserver = this.resourceSelectionSource.asObservable();

  public isSelectMode: boolean = false;
  public offlineResources: OfflineResource[] = [];

  /**
   *  To keep track current Resource view
   */
  // public viewMode: ResourceView;

  public getResourceById(resourceid: string, documentid: string): Resource {
    const idx: string = resourceid ? resourceid : documentid;
    return this.allResources.find(
      pres =>
        pres.ioResourceId === idx || pres.ioDocumentId === idx
    );
  }

  get participantsByResource() {
    return this.activityService.selectedActivity instanceof AppointmentActivity
      ? this.activityService.selectedActivity.sharedResources
      : [];
  }

  recentSearches: SelectedSuggestionPillDataModel[] = [];

  constructor(
    private activityService: ActivityService,
    private events: Events,
    private searchConfigService: SearchConfigService, // private presDataService: ResourceDataService
    private disk: DiskService
     // private presDataService: ResourceDataService
  ) {
    this.subscribeEvent((status: IMeetingResourceStatus) => {
      let found = this.participantsByResource.find(
        ({ contact, resource }) =>
          contact.ID === status.indskr_contactid &&
          ((resource.ioResourceId &&
            resource.ioResourceId === status.indskr_ioresourceid) ||
            (resource.ioDocumentId &&
              resource.ioDocumentId === status.indskr_iodocumentid))
      );
      if (found) {
        found.statuses = found.statuses || [];
        found.statuses.push({
          status: 1 + +status.indskr_resultstatus,
          date: new Date(+status.indskr_resulttime)
        });
      }
      this.events.publish("refresh_resource_status");
    });
    //this.selectedPresView.next({name:"list", code:"ios-list"});
  }

  subscribeEvent(handler: EventHandler) {
    this.events.unsubscribe("meeting:resourcestatus", handler);
    this.events.subscribe("meeting:resourcestatus", handler);
  }

  clearAllResources() {
    this.allResources = [];
  }

  /**
   * Update list of all Resource
   * Tag Resources that are already downloaded
   *
   * @param raw
   */
  public refreshResourceList(raw: IResource[]) {
    console.log("online Resource");
    if (Array.isArray(raw)) {
      raw = raw.filter((item) => (item.statecode === "0" && item.statuscode=== "1"))
      if(!this.offlineResources || this.offlineResources.length < 1) {
        this.allResources = raw.map(rawJSON => new Resource(rawJSON));
      } else {
        this.allResources = [];
        raw.map(rawJSON => {
          const resource = new Resource(rawJSON);
          if(this.offlineResources.some(x => x.ioResourceId === resource.ioResourceId || x.ioResourceId === resource.ioDocumentId)) {
            resource.downloaded = true;
          }
          this.allResources.push(resource);
        });
      }
    } else {
      console.error('Unsupported data format for resources!');
    }
    this.allResources.forEach(resource => this.mapResourceFieldsToSearchIndex(resource));
  }

  public reset() {
    this.highlightedResource$.next(null);
  }

  public highlightResource(resource: Resource) {
    this.highlightedResource$.next(resource);
  }

  addParticipantsForResource(resource: Resource, contacts: Contact[]) {
    contacts.forEach(c => {
      let existing = this.participantsByResource.find(
        obj => obj.resource === resource && obj.contact === c
      );
      if (!existing) {
        existing = { resource: resource, contact: c, statuses: [] };
        this.participantsByResource.push(existing);
      }
      existing.statuses.push({
        status: <number>ResourceParticipantStatus.SENT,
        date: new Date()
      });
    });
  }

  public updateSelResourceList(resource: Resource) {
    let element = this.selectedResourceList.find(r => r.assetID === resource.assetID);
    if (!element) {
        // pres.isSelected = true;
        this.selectedResourceList.push(resource);
    } else {
      // pres.isSelected = false;
      let index = this.selectedResourceList.indexOf(resource);
      this.selectedResourceList.splice(index, 1);
    }
  }

  public setEmailResource(input) {
    this.resourceSelectionSource.next(input);
  }

  public mapResourceFieldsToSearchIndex(newResource:Resource){
    if(!this.searchConfigService.isConfigInitiated){
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    newResource.brands.forEach(brand=>{
      if(brand && !this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==brand))
        this.searchConfigService.resourcesSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(brand);
    })
    if(newResource.assetType && !this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.some(o=> o == newResource.assetType)){
      this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.push(newResource.assetType);
    }
    // if(newResource.raw.brands) newResource.raw.brands.forEach(brand => {
    //   if(brand.therapeuticAreas && brand.therapeuticAreas.length > 0)
    //     brand.therapeuticAreas.forEach(ta=>{
    //       if(ta.therapeuticAreaName && !this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Therapeutic Areas').values.some(o=> o==ta.therapeuticAreaName))
    //       this.searchConfigService.resourcesSearchIndexesConfig.find(config=>config.categoryName=='Therapeutic Areas').values.push(ta.therapeuticAreaName);
    //   })
    // })
    newResource.therapeuticAreas.forEach(ta=>{
      if(ta.therapeuticAreaName && !this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Therapeutic Areas').values.some(o=> o==ta.therapeuticAreaName))
        this.searchConfigService.resourcesSearchIndexesConfig.find(config=>config.categoryName=='Therapeutic Areas').values.push(ta.therapeuticAreaName);
    })
    newResource.specialties.forEach(specialty=>{
      if(specialty.name && !this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Specialties').values.some(o=> o==specialty.name))
        this.searchConfigService.resourcesSearchIndexesConfig.find(config=>config.categoryName=='Specialties').values.push(specialty.name);
    })
    newResource.diseaseStates.forEach(diseaseState=>{
      if(diseaseState && !this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Disease State').values.some(o=> o == diseaseState.diseaseStateName))
        this.searchConfigService.resourcesSearchIndexesConfig.find(config=> config.categoryName == 'Disease State').values.push(diseaseState.diseaseStateName);
    })
  }

  async updateNewResourceStatus(newResources: Resource[]) {
     if(newResources && newResources.length > 0) {
        let offlineSaved = await this.getResourcesFromDB();
        if(offlineSaved && offlineSaved && Array.isArray(offlineSaved)){
            newResources.forEach(res =>{
                let foundSavedIndex = offlineSaved.findIndex(savedRes =>
                  (savedRes.indskr_ioresourceid === res.ioResourceId || savedRes.indskr_iodocumentid === res.ioDocumentId)
                );
                if (foundSavedIndex >= 0) {
                  offlineSaved[foundSavedIndex]['isNew'] = false;
                }
            });
            await this.disk.saveOrUpdateResourcesToDB(offlineSaved);
        }
    }
  }

  public async getResourcesFromDB() {
    let dbResources = [];
    try {
      let rawResourcesDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.RESOURCE);
      if (rawResourcesDoc && rawResourcesDoc.raw && Array.isArray(rawResourcesDoc.raw)) {
        dbResources = rawResourcesDoc.raw;
      }
    }
    catch (error) {
      console.warn('mapDeltaSyncedResources: No data to load');
    }
    return dbResources;
  }
  public getOfflineResourceById(resourceid: string): OfflineResource {
    return this.offlineResources.find(r => r.ioResourceId === resourceid);
  }

  public refreshOfflineResourceList(raw: any) {
    console.log("offline resources");
    if (Array.isArray(raw)) {
      this.offlineResources = [];
      raw.map(rawJSON => {
          this.offlineResources.push(rawJSON);
      });
    } else {
      console.error('OFFLINE Unsupported data format for presentations!');
    }
  }

}
