import { Injectable } from "@angular/core";
import { DeviceService } from "../device/device.service";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { FeatureAction } from "@omni/classes/feature-action/feature-action.class";
import { EventsService } from "../events/events.service";

@Injectable({
  providedIn: 'root',
})
export class FeatureActionsService {
  private featureActions: Map<FeatureActionsMap, FeatureAction> = new Map();

  // other feature flags that requires extra logic
  private _isShortCallEnabled = false;
  get isShortCallLauncherEnabled() {
    return this._isShortCallEnabled;
  }

  constructor(
    private device: DeviceService,
    private authService: AuthenticationService,
    private events: EventsService,
  ) {
    this.events.observe('FA Updated')
      .subscribe(() => this.updateFaValues);
  }

  getFeatureAction(faName: FeatureActionsMap): FeatureAction {
    return this.featureActions.get(faName) ?? null;
  }

  updateFaValues() {
    const faNames = Object.values(FeatureActionsMap);

    for (let i = 0; i < faNames.length; i++) {
      const faName = faNames[i];
      const isOn = this.authService.hasFeatureAction(faName);
      let fa = this.featureActions.get(faName);
      if (!fa) {
        fa = new FeatureAction(faName, isOn);
        this.featureActions.set(faName, fa);
      } else {
        fa.setIsOn(isOn);
      }

      this.setFeatureFlagsWithExtraLogic(faName);
    }
  }

  // Set Featue flags with extra logic
  private setFeatureFlagsWithExtraLogic(faName: FeatureActionsMap) {
    switch (faName) {
      case FeatureActionsMap.SHORT_CALL_LAUNCHER:
        this._isShortCallEnabled = this.checkShortCallEnabled();
        break;

      default:
        break;
    }
  }


  // Extra logic for feature flags
  private checkShortCallEnabled() {
    let enabled = false;
    let fa = this.featureActions.get(FeatureActionsMap.SHORT_CALL_LAUNCHER);
    if (fa) {
      enabled = this.device.isNativeCordova && this.device.isMobileDevicePortrait && fa.getIsOn()
    }
    return enabled;
  }
}
