<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="quoteSearchText"
    (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput()"></ind-header-left>

  <ind-key-controls-area id="allQuotes" *ngIf="quotesListKeyControlAreaModel"
    [viewData]="quotesListKeyControlAreaModel"> </ind-key-controls-area>

  <ion-toolbar no-padding>
    <ind-tabs [data]="tabsData" [(selectedTab)]="selectedTab" (selectedTabChange)="checkQuoteModel($event)">
    </ind-tabs>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft': showVectorImage }">
  <div [ngSwitch]="selectedTab">
    <div *ngSwitchCase="'Quotes'">
      <ind-section-header [viewData]="allQuotesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>

      <div class="no-data-message" *ngIf="filteredQuotes.length == 0 && !isSearching">
        {{ 'NO_QUOTES_AVAILABLE' | translate}}
      </div>

      <div *ngIf="filteredQuotes.length == 0 && isSearching">
        <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
      </div>

      <ion-list class="plans-list">
        <main-card [viewData]="getQuotemainCardModel(plan)" *ngFor="let plan of filteredQuotes"></main-card>
      </ion-list>
    </div>
    <div *ngSwitchCase="'Approvals'">
      <ind-section-header [viewData]="pendingSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ng-container *ngIf="isPendingSectionExpanded">
        <div class="no-data-message" *ngIf="pendingApprovalQuotes.length == 0">
          {{ 'NO_PENDING_APPROVALS' | translate}}
        </div>
        <ion-list class="plans-list">
          <main-card [viewData]="getQuotemainCardModel(quote)" *ngFor="let quote of pendingApprovalQuotes">
          </main-card>
        </ion-list>
      </ng-container>
      <ind-section-header [viewData]="completedSectionHeader" (onControlClick)='onSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ng-container *ngIf="isCompletedSectionExpanded">
        <div class="no-data-message" *ngIf="completedApprovalQuotes.length == 0">
          {{ 'NO_COMPLETED_APPROVALS' | translate}}
        </div>
        <ion-list class="plans-list">
          <main-card [viewData]="getQuotemainCardModel(quote)" *ngFor="let quote of completedApprovalQuotes">
          </main-card>
        </ion-list>
      </ng-container>
    </div>

  </div>
</ion-content>
<footer-toolbar [footerView]="'masterView'" [selectedView]="'Timeoff'"></footer-toolbar>