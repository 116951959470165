
import { Component, OnInit, AfterViewInit, EventEmitter, NgZone } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { UIService } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { VeevaConfiguration } from '@omni/classes/authentication/veeva.configuration';
import { AuthenticationService } from '@omni/services/authentication.service';
import { SafeHtml } from '@angular/platform-browser';
import { VeevaWidgetConfig } from '@omni/models/veeva.widget.config';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-veeva-profile-widget',
  templateUrl: './veeva-profile-widget.component.html',
  styleUrls: ['./veeva-profile-widget.component.scss'],
})
export class VeevaProfileWidgetComponent implements AfterViewInit {
  public title: string;
  public preset: string;
  // private identifier: string;
  private appID: string;
  private profileId: string;
  manifestURL: string;
  widgetURL: string;
  widgetName: string;
  widgetID: string;
  authDomain: string;


  constructor(private route: ActivatedRoute, private ngZone: NgZone) {
    this.route.queryParams.subscribe((params: Params) => {
      this.preset = params['preset'];
      this.title = params['title'];
      this.appID = params['id'];
      this.profileId = params['id'];

      this.authDomain = params['authDomain'];
      this.widgetName = params['widgetName'];

      this.manifestURL = params['manifestURL'];
      this.widgetURL = params['widgetURL'];
      this.widgetID = params['widgetID'];
    });
  }

   ngOnInit() {
    this.embedWidget();

this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // update component data
        // but don't trigger change detection.
        this.startWidget();
      }, 3000);
    });
    }

  ngAfterViewInit() {
    // setTimeout(() =>{
    //   this.startWidget();
    // }, 3000);

    let el: HTMLElement = document.getElementById('startbutton') as HTMLElement;
    setTimeout(() => el.click(), 3000);

        // this.eventEmitter.emit();

  }

  ionViewDidEnter(){
  //  this.startWidget();
  }

  startWidget() {
    const widgetIFrameEl = document.getElementById('widgetIFrame') as HTMLIFrameElement;
    try {
      // widgetIFrameEl.hidden = false;
      widgetIFrameEl.contentWindow.postMessage({ method: 'start', data: this.profileId });
    } catch (error) {
    }
  }
  handleSearchWidgetSelectEvent(ev) {
    // perform handling as you normally would
    console.log('Received', ev.type, 'with data:', ev.detail, 'outside iframe');
  }
  closeWidget() {
    const widgetIFrameEl = document.getElementById('widgetIFrame') as HTMLIFrameElement;
    try {
      // widgetIFrameEl.hidden = true;
      widgetIFrameEl.contentWindow.postMessage({ method: 'close' });
    } catch (error) {
    }
  }
  async embedWidget() {
    const widgetContainerEl = document.getElementById('widgetContainer');
    // const authDomain = 'fergene.veevanetwork.com';
    // const widgetId = 'NDE1OTE7OztvbW5pcHJlc2VuY2VzZWFyY2h3aWRnZXRfX2M=';
    // defining iframe content as string for convenience. move to separate file
    // if desired for cleanliness
    const iframeSrc = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8" />
          <!-- this iframe needs the widget assets here, this means we can remove
          the imports in app.component.ts -->
          <script type="text/javascript" src="${this.manifestURL}"></script>
          <script type="text/javascript" src="${this.widgetURL}"></script>
          <style>
            html, body, #widgetEl {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
            }
          </style>
        </head>
        <body>
          <!-- just need the search widget element since the sole purpose
          of this iframe is to host the widget -->
          <veeva-network-profile-widget
            id="widgetEl"
            widget-name="${this.widgetName}"
            auth-domain="${this.authDomain}"
            widget-id="${this.widgetID}" // Sandbox
            hold-initialization="true">
          </veeva-network-profile-widget>
          <script type="text/javascript">
            var widgetEl = document.getElementById("widgetEl");
            // this method handles the generic behaviour of an emitted Network widget
            function handleWidgetEvent(ev) {
              // only pass this subset of the event details
              var eventData = {
                isFromVeevaNetworkWidget: true,
                type: ev.type,
                detail: ev.detail
              };
              // the event data must be represented as a string
              eventData = JSON.stringify(eventData);
              // post the message to the parent that hosts this iframe
              //
              // NOTE: Instead of '*', please make sure that you are supplying
              // your trusted domain here so that your host application is only
              // accepting trusted messages from this iframe. This will appear in
              // th 'ev.origin' property on the host.
              //
              window.parent.postMessage(eventData, "*");
            }
            // add usual event listeners for the embedded widget
            widgetEl.addEventListener("veeva-network:${this.widgetName}:select", handleWidgetEvent);
            widgetEl.addEventListener("veeva-network:${this.widgetName}:close-widget", handleWidgetEvent);
            // listen to any events emitted by the host
            window.addEventListener("message", function(ev) {
              // NOTE: only accept a trusted ev.origin. e.g.
              // if (ev.origin !== 'https://my.trusted.domain.com/') return;
              console.log("Received event" + ev.data.method);
              // this assumes that the data always corresponds to a method of the widget API
              switch (ev.data.method) {
                case "start":
                  widgetEl.start("${this.profileId}");
                  break;
                case "close":
                  widgetEl.close();
                  break;
                default:
                  break;
              }
            });
          </script>
        </body>
      </html>
    `;

    const _widgetName = this.widgetName;

    const iframeEl = document.createElement('iframe');
    iframeEl.setAttribute('id', 'widgetIFrame');
    iframeEl.setAttribute('srcdoc', iframeSrc);
    // this property will be controlled by the start/close methods on this
    // page component
    // iframeEl.hidden = true;

    // other miscellaneous styles to make this experience more immersive and appear
    // to naturally belong on the page
    iframeEl.style.width = '100%';
    iframeEl.style.height = '100%';
    iframeEl.style.minHeight = '800px';
    iframeEl.style.border = 'none';
    // cleanly insert into the widget container element
    widgetContainerEl.innerHTML = '';
    widgetContainerEl.appendChild(iframeEl);
    // listen to any events emitted by the iframe (add any security details here so that
    // you're only accepting messages from the trusted source, current domain)
    const self = this; // or just proxy the context, ie. `.bind(this)` either works
    window.addEventListener('message', function (ev) {
      // NOTE: only accept a trusted ev.origin. e.g.
      // if (ev.origin !== 'https://my.trusted.domain.com/') return;
      try {
        // we need to parse the event data that was stringified in the iframe
        var eventData = JSON.parse(ev.data);
        // notice that this listener only handles events emitted from iframe with this property on the event data
        if (!eventData.isFromVeevaNetworkWidget) return;
        // handle embedded widget events here
        switch (eventData.type) {
          case `veeva-network:${_widgetName}:select`:
            self.handleSearchWidgetSelectEvent(eventData);
            break;
          default:
            break;
        }
      } catch (e) {
        // most likely, if the above code block received an error, it means
        // that the JSON.parse operation likely failed which means it probably
        // wasn't a valid iframe-emitted widget event so doing nothing here
        // is the right thing to do
      }
    });
    iframeEl.contentWindow.postMessage({ method: 'start' });
  }
}
