import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerPositionList, CustomerPositionListType, StatusCode } from '@omni/classes/territory-management-list/customer-position-list.class';
import { TerritoryManagementDataService } from '@omni/data-services/territory-management/territory-management.data.service';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { UIService } from '@omni/services/ui/ui.service';
import { TerritoryManagementDetailsComponent } from '../territory-management-details/territory-management-details';
import { TerritoryManagementTabs } from '../territory-management-list/territory-management-list';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';

export enum ListPeriod {
  CURRENT_LIST_PERIOD = 'currentListPeriod',
  NEXT_LIST_PERIOD = 'nextListPeriod'
}

@Component({
  selector: 'app-new-manage-list',
  templateUrl: './new-manage-list.component.html',
  styleUrls: ['./new-manage-list.component.scss'],
})

export class NewManageListComponent implements OnInit {

  newManageListTitle: IndPageTitleViewDataModel;
  selectedListPeriod = ListPeriod.CURRENT_LIST_PERIOD;
  selectedCustomerPositionList: CustomerPositionList;
  private ngDestroy$: any = new Subject<boolean>();
  private currentTab: TerritoryManagementTabs = TerritoryManagementTabs.MANAGE_LIST;

  constructor(
    public uiService: UIService,
    public translate: TranslateService,
    public navService: NavigationService,
    private authService: AuthenticationService,
    public territoryService: TerritoryManagementService,
    public territoryDataService: TerritoryManagementDataService
  ) { }

  ngOnInit() {
    this.initPageTitle();

    this.territoryService.selectedCustomerPostionListSubject.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.selectedCustomerPositionList = data;
    });

    this.selectedListPeriod = this.territoryService.isCurrentCustomerListPeriodExists() ? ListPeriod.NEXT_LIST_PERIOD : ListPeriod.CURRENT_LIST_PERIOD;
  }

  initPageTitle() {
    this.newManageListTitle = {
      id: "new-manage-list-title",
      title: this.translate.instant("NEW_MANAGE_LIST"),

      controls: [
        {
          id: 'new_manage_list_cancel_button',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: false,
          align: "right"
        },
        {
          id: 'new_manage_list_confirm_button',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('CONFIRM'),
          isDisabled: false,
          // isDisabled: this.backgroundUploadInProgress ? true : !this.isConfirmEnabled,
          align: "right"
        }
      ]
    }
  }

  onPageTitleControlClick(id: string) {
    if (id === 'new_manage_list_cancel_button' || id === 'close') {
      this.goBack();
    } else {
      this.territoryService.currentListPeriodCustomerList = this.selectedListPeriod === ListPeriod.CURRENT_LIST_PERIOD;
      this.createCustomerListPeriod();
    }
  }

  goBack() {
    if (this.navService.getActiveChildNavName() === ChildNavNames.TerritoryNavigation) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.TerritoryManagementPageComponent);
      this.navService.setChildNavRightPaneView(false);
    } else {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  async createCustomerListPeriod() {
    await this.uiService.displayLoader({}, true);
    let currentListPeriod = this.territoryService.currentListPeriod;
    let listperiodId = this.territoryService.currentListPeriodCustomerList ? currentListPeriod.indskr_customerlistperiodid : currentListPeriod.indskr_nextlistperiod.indskr_customerlistperiodid;
    this.selectedCustomerPositionList = null;
    const customerPositionList = this.territoryService.createCustomerPositionList();
    // this.territoryService.selectedCustomerPostionListSubject.next(customerPositionList);
    const configuredAccountIds = await this.territoryDataService.getAvailableContacts(listperiodId);
    await this.territoryDataService.fetchCurrentquarterContacts(currentListPeriod.indskr_customerlistperiodid, listperiodId,false, configuredAccountIds);
    await this.territoryDataService.fetchConfiguredAccounts(currentListPeriod.indskr_customerlistperiodid).then((resp) => {
      this.territoryService.currentQAccountIds = resp.map(res => res['account.accountid'])
    });
    this.territoryService.basePositionListTargets = _.cloneDeep(this.selectedCustomerPositionList.positionListTargets);
    //Keep only selected as BASE
    this.territoryService.basePositionListTargets.forEach(plt => {
      const selectedAcc = plt.contact.accounts.filter(acc => acc['isSelected']);
      plt.contact.accounts = selectedAcc;
    });
    await this.uiService.dismissLoader();
    this.navService.setChildNavRightPaneView(true);
    this.navService.setChildNavRoot(TerritoryManagementDetailsComponent, PageName.TerritoryManagementDetailsComponent, PageName.TerritoryManagementPageComponent, {
      selectedTab: this.currentTab
    });
  }

  selectPeriod(listPeriod) {
    this.selectedListPeriod = listPeriod;
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

}
