import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import _, { cloneDeep } from 'lodash';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { IndDropdownListComponent } from '../../shared/ind-dropdown-list/ind-dropdown-list';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { IndSectionSpecificAssessDataModel } from '@omni/models/indSectionSpecificAssessDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { AssessmentAttributeType, AttributeSection, ChoiceOption, Control, RatingScaleType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { format } from 'date-fns';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { Guid } from 'typescript-guid';
import { UserPosition } from '@omni/classes/authentication/user.class';
import { Brand } from '@omni/classes/brand/brand.class';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import moment from 'moment';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { DatePipe } from '@angular/common';
import { TherapeuticArea } from '@omni/classes/therapeutic-area/therapeutic-area.class';
import { Action, CaptureDataFor, FilterType, MarketScanProduct } from '@omni/classes/market-scan/market-scan.class';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { FooterViews } from '@omni/services/footer/footer.service';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { Contact, Specialty } from '@omni/classes/contact/contact.class';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { Product } from '@omni/classes/consent/product.class';
import { Account } from '@omni/classes/account/account.class';

@Component({
  selector: 'section-specific',
  templateUrl: 'section-specific.html',
  styleUrls: ['section-specific.scss']
})
export class SectionSpecificComponent {

  @Input() template: IndSectionSpecificAssessDataModel;
  @Input() rawData: CustomerAssessment;
  @Input() masterData: { positions: UserPosition[], ta: TherapeuticArea[], products: Brand[], procedures: Brand[], positionGroups: any[] }
  @Input() criteriaData: { entity: string, data: Contact | Account }
  @Input() currentFormValueForSection: { [x: string]: any };
  @Input() specialty: Specialty;
  @Input() fromMultiUpdate: boolean;
  @Input() multipleFormView: Array<{
    contactId: string,
    rawData: CustomerAssessment,
    currentFormView: Array<{
      id: string,
      type: string,
      isSharedSection: boolean,
      view: any,
      data?: string,
      control?: Control,
    }>
  }>;
  @Input() currentMultipleFormValue: Array<{
    contactId: string,
    sectionId: string,
    id: string,
    currentValue: { [x: string]: any }
  }>;


  public titleBar: IndPageTitleViewDataModel;
  private _currentFormValue: { [x: string]: any } = {};
  private _isClicked: { [x: string]: boolean } = {};
  public generalAttributes: IndFormFieldViewDataModel[] = [];
  public sectionHeaderData: IndSectionHeaderViewDataModel;
  public currentFormView: Array<{
    id: string,
    name: string,
    controls: any[],
  }> = [];
  private selectedSections: Array<{
    taId: string,
    positionId: string,
    productId: string,
    procedureId?: string,
    positionGroupId: string
  }> = [];
  private isFormDirty: boolean = false;
  private readonly $ngDestroy: any = new Subject<boolean>();
  public startTime: { [x: string]: any } = {};
  public prevStartTime: { [x: string]: any } = {};
  private _deletedCurrentFormValue: { [x: string]: any } = {};
  //selected sections
  private selectedPositions: string[] = [];
  private selectedPositionGroups: string[] = [];
  private selectedProducts: string[] = [];
  private selectedTAs: string[] = [];
  private selectedProcedures: string[] = [];

  //Filtered data as per selection
  private filteredPositions: UserPosition[] = [];
  private filteredPositionGroups: any[] = [];
  private filteredProducts: Brand[] = [];

  //For MultiUpdate
  indHeaderLeftModel: IndHeaderLeftDataModel;
  _multipleFormValue: Array<{
    contactId: string,
    sectionId: string,
    id: string,
    currentValue: { [x: string]: any }
  }> = [];


  constructor(
    public readonly device: DeviceService,
    public translate: TranslateService,
    private readonly popoverCtrl: PopoverController,
    public navService: NavigationService,
    private customerAssessService: CustomerAssessService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private modalCtrl: ModalController,
    private localizationService: LocalizationService
  ) {
  }

  ngOnInit() {
    this.initPageTitle();
    this.initSectionHeaderData();
    if (!this.template.isEdit) {
      if (this.template.section.Position) {
        this.filteredPositions = this.masterData.positions;
      }
      if (this.template.section.PositionGroup) {
        this.filteredPositionGroups = this.masterData.positionGroups;
      }
      this._filterProducts();
      this.initGeneralAttributes();
    } else {

      if (this.template.section.Position) {
        this.selectedPositions = this.masterData.positions?.map(posi => posi.ID);
      }
      if (this.template.section.PositionGroup) {
        this.selectedPositionGroups = this.masterData.positionGroups?.map(pg => pg.positionGroupId);
      }
      this._filterProducts();
      this.selectedProducts = this.masterData.products?.map(prod => prod.ID);
      this.selectedTAs = this.masterData.ta?.map(ta => ta.therapeuticareaId);
      this.selectedProcedures = this.masterData.procedures?.map(procedure => procedure.ID);
      this._initSelectedSections();
      if (this.currentFormValueForSection && !_.isEmpty(Object.keys(this.currentFormValueForSection)))
        this._currentFormValue = this.currentFormValueForSection;
      this._initViewData();
    }
    this.dateTimeFormatsService.updateSelectedTimeConfiguredSectionForm$.pipe(takeUntil(this.$ngDestroy)).subscribe((timeData) => {
      if (timeData) {
        this._handleTimeFormField(timeData);
      }
    })
    if(this.fromMultiUpdate) {
      this.initHeaderLeft();
      if(this.currentMultipleFormValue && !_.isEmpty(Object.keys(this.currentMultipleFormValue)))
        this._multipleFormValue = this.currentMultipleFormValue;
    }
  }

  private _filterProducts() {
    if (!this.template.section.Position && !this.template.section.PositionGroup && !this.template.section.TherapetuicArea && this.template.section.Product) {
      this.filteredProducts = this.masterData.products;
    }
  }

  private async _handleTimeFormField(timeData) {
    let targetControl: Control;
    if (timeData.controlId) {
      targetControl = this.getTargetControl(timeData.controlId);
      if (timeData.id == DateTimeFieldType.StartTimeField) {
        if (this.startTime && this.startTime.hasOwnProperty(targetControl.Attribute.GUID)) {
          this.startTime[targetControl.Attribute.GUID] = timeData.time;
        } else {
          this.startTime = {
            [targetControl.Attribute.GUID]: timeData.time
          }
        }
        setTimeout(() => {
          this.handleStartDateTimeValue(targetControl, timeData.dataRef.section);
        }, 0);
      }
    }
  }

  private handleStartDateTimeValue(targetControl: Control, section) {
    if (!_.isEmpty(targetControl)) {
      const sectionId: string = this.getSectionId(section);
      let m = moment(this.startTime[targetControl.Attribute.GUID]);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Configured Form', 'top', ToastStyle.DANGER);
        this.startTime[targetControl.Attribute.GUID] = this.prevStartTime[targetControl.Attribute.GUID];
      } else {
        this.prevStartTime[targetControl.Attribute.GUID] = this.startTime[targetControl.Attribute.GUID];
        let selectedDate = this.startTime[targetControl.Attribute.GUID];
        let selectedDateUTC = moment(selectedDate).utc().format();
        let formattedDate = (new Date(selectedDateUTC).toISOString());
        this._setAttributeValue(targetControl, formattedDate, section, sectionId);
        this.validateForm();
      }
    }
  }

  private initSectionHeaderData() {
    if (this.template) {
      this.sectionHeaderData = {
        id: this.template.section.SectionProperties.GUID,
        title: this.template.isEdit ? this.template.title : this.customerAssessService.getSectionHeader(this.template.section.SectionProperties),
        controls: [],
        doNotModifyTitleCase: true
      }
    }
  }

  private initHeaderLeft() {
    this.indHeaderLeftModel = {
      id: 'section-specific-header-left',
      title: this.translate.instant('ASSESSMENT'),
      mode: true,
      controls: [{       
        id: 'close',
        icon: "chevron-back-outline",
        isDisabled: false,
        align: 'left'
      }]
    }
  }

  private initGeneralAttributes() {
    this.generalAttributes = [];
    if (this.template?.section.Procedure) {
      const formField: IndFormFieldViewDataModel = {
        label: this.translate.instant('PROCEDURES'),
        placeholderLabel: this.translate.instant('PROCEDURES'),
        inputText: this.getDisplayTextForProcedure(),
        id: 'procedure',
        isReadOnly: true,
        isDisabled: this.masterData.procedures.length == 1,
        showArrow: this.masterData.procedures.length > 1,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this._handleGeneralFormFieldEvent(id, event, eventName)
      }
      this.generalAttributes.push(formField);
    }
    if (this.template?.section.TherapetuicArea) {
      const formField: IndFormFieldViewDataModel = {
        label: this.translate.instant('THERAPEUTIC_AREAS'),
        placeholderLabel: this.translate.instant('THERAPEUTIC_AREAS'),
        inputText: this.getDisplayTextForTA(),
        id: 'ta',
        isReadOnly: true,
        isDisabled: this.masterData.ta.length == 1,
        showArrow: this.masterData.ta.length > 1,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this._handleGeneralFormFieldEvent(id, event, eventName)
      }
      this.generalAttributes.push(formField);
    }

    //|| this.template?.section.Product
    if (this.template?.section.Position ) {
      //Product is always mapped with position
      const formField: IndFormFieldViewDataModel = {
        label: this.translate.instant('POSITIONS'),
        customPlaceholderLabel: this.translate.instant('SELECT') + " " + this.translate.instant('POSITIONS'),
        inputText: this.getDisplayTextForPosition(),
        id: 'position',
        isReadOnly: true,
        isHidden: !this.template.section.Position,
        isDisabled: this.filteredPositions.length == 1 || this.template.section.TherapetuicArea ? _.isEmpty(this.selectedTAs) : false,
        showArrow: this.filteredPositions.length > 1,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this._handleGeneralFormFieldEvent(id, event, eventName)
      }
      this.generalAttributes.push(formField);
    }

    if (this.template?.section.PositionGroup ) {
      //Product is always mapped with positiongroup
      const formField: IndFormFieldViewDataModel = {
        label: this.translate.instant('POSITION_GROUPS'),
        customPlaceholderLabel: this.translate.instant('SELECT') + " " + this.translate.instant('POSITION_GROUPS'),
        inputText: this.getDisplayTextForPositionGroup(),
        id: 'positionGroup',
        isReadOnly: true,
        isHidden: !this.template.section.PositionGroup,
        isDisabled: this.filteredPositionGroups.length == 1,
        showArrow: this.filteredPositionGroups.length > 1,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this._handleGeneralFormFieldEvent(id, event, eventName)
      }
      this.generalAttributes.push(formField);
    }

    if (this.template?.section.Product) {
      const formField: IndFormFieldViewDataModel = {
        label: this.translate.instant('PRODUCTS'),
        customPlaceholderLabel: this.translate.instant('SELECT_PRODUCTS'),
        inputText: this.getDisplayTextForProduct(),
        id: 'product',
        isReadOnly: true,
        isDisabled: this.filteredProducts.length == 1 || this.template.section.TherapetuicArea ? _.isEmpty(this.selectedTAs) : this.template.section.Position ? _.isEmpty(this.selectedPositions) : this.template.section.Product ? _.isEmpty(this.filteredProducts) : false,
        showArrow: this.filteredProducts.length > 1,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this._handleGeneralFormFieldEvent(id, event, eventName)
      }
      this.generalAttributes.push(formField);
    }
    this._initSelectedSections();
    this._initViewData();
  }

  private _initSelectedSections() {
    this.selectedSections = [];
    if (this.template.section.Position && !this.template.section.Product && !this.template.section.PositionGroup) {
      // For Position, For TA and Position section
      this.selectedPositions.forEach(id => {
        this.selectedSections.push({ taId: this.selectedTAs ? this.selectedTAs[0] : null, positionId: id, productId: null, positionGroupId: null });
      })
    } 
    else if (!this.template.section.Position && !this.template.section.Product && this.template.section.PositionGroup) {
      // For PositionGroup, For TA and PositionGroup section
      this.selectedPositionGroups.forEach(id => {
        this.selectedSections.push({ taId: this.selectedTAs ? this.selectedTAs[0] : null, positionId: null, productId: null, positionGroupId: id });
      })
    } 
    else if (!this.template.section.Position && this.template.section.Product && !this.template.section.PositionGroup) {
      //For Product only section , For Product and TA section
      this.selectedProducts.forEach(id => {
        this.selectedSections.push({ taId: this.selectedTAs ? this.selectedTAs[0] : null, positionId: null, productId: id, positionGroupId: null  });
      })
    } 
    else if (!this.template.section.Position && !this.template.section.Product && this.template.section.TherapetuicArea && !this.template.section.PositionGroup) {
      //Only TA section
      this.selectedTAs.forEach(id => {
        this.selectedSections.push({ taId: id, positionId: null, productId: null, positionGroupId: null  });
      })
    } 
    else if (this.template.section.PositionGroup && !this.template.section.Position && !this.template.section.Product && !this.template.section.TherapetuicArea) {
      //Only PositionGroup section
      this.selectedPositionGroups.forEach(id => {
        this.selectedSections.push({ taId: null, positionId: null, productId: null, positionGroupId: this.selectedPositionGroups.length == 1 ? this.selectedPositionGroups[0] : id  });
      })
    } 
    else if (this.template.section.PositionGroup && !this.template.section.Position && !this.template.section.Product) {
      //PositionGroup section, For TA and PositionGroup section
      this.selectedPositionGroups.forEach(id => {
        this.selectedSections.push({ taId: this.selectedTAs ? this.selectedTAs[0] : null, positionId: null, productId: null, positionGroupId: this.selectedPositionGroups.length == 1 ? this.selectedPositionGroups[0] : id  });
      })
    }
    else if (this.template.section.PositionGroup && this.selectedPositionGroups.length == 1 && !this.template.section.TherapetuicArea && this.template.section.Product) {
      //PositionGroup section, For Product and PositionGroup section
      this.selectedProducts.forEach(id => {
        this.selectedSections.push({ taId: null, positionId: null, productId: id, positionGroupId: this.selectedPositionGroups[0]  });
      })
    }
    else if (this.template.section.Position && this.template.section.Product && this.selectedPositions.length == 1 && !this.template.section.PositionGroup) {
      //For Position and Product , For TA and Product and Position section
      this.selectedProducts.forEach(id => {
        this.selectedSections.push({ taId: this.selectedTAs ? this.selectedTAs[0] : null, positionId: this.selectedPositions[0], productId: id, positionGroupId: null  });
      })
    } else if (!this.template.section.Position && this.template.section.Product && this.selectedPositionGroups.length == 1 && this.template.section.PositionGroup) {
      //For PositionGroup and Product , For TA and Product and PositionGroup section
      this.selectedProducts.forEach(id => {
        this.selectedSections.push({ taId: this.selectedTAs ? this.selectedTAs[0] : null, positionId: null, productId: id, positionGroupId: this.selectedPositionGroups[0]  });
      })
    } else if (this.template.section.Procedure) {
      this.selectedProcedures.forEach(id => {
        this.selectedSections.push({ taId: null, positionId: null, productId: null, procedureId: id, positionGroupId: null  });
      })
    }
  }

  private _initViewData() {
    this.currentFormView = [];

    // /*************Product Specific Assessments section*************/
    //To check for Product Assessments section and render according to Product grid
    if (!this.template.section.Position && this.template.section.Product && !this.template.section.PositionGroup && !_.isEmpty(this.selectedProducts)) {
      console.warn("Product Assessments section");

      this.selectedSections.forEach(selectedSection => {
        const controls = [];
        this.template.section.Controls.forEach(control => {

          const productsSpecifiedAttributes = control.hasOwnProperty('Attribute') && control.Attribute.hasOwnProperty('SectionAttributeProducts') ? control.Attribute['SectionAttributeProducts'] : [];

          if(!_.isEmpty(productsSpecifiedAttributes)) {
            // To check for attribute that has more than one product selected in the grid, it will for selected product only
            const objSelectedProducts = this.selectedProducts.map((str) => ({ product: str }));
            
            const intersection = _.intersectionBy(objSelectedProducts,productsSpecifiedAttributes,'product');
            // To check for Selected section ProductId to display that product specific attribute
            const isSelectedSection = intersection.find((obj) => {
              return obj.product === selectedSection.productId;
            })

            const selectedSectionProductName = this.filteredProducts.find((obj) => {
              return obj.ID === selectedSection.productId;
            }).name

            //If any of the selected product matches with productsSpecifiedAttributes then show else skip
            if(!_.isEmpty(intersection) && isSelectedSection) {
              // To check for attribute that has no value selected in the grid, it will for all products
              const view = this._getViewForControl(control, selectedSection);
              if (view) {
                console.warn(`Added ${control.DisplayName} for product: ${selectedSectionProductName}`);

                controls.push(view);
              }
            }
          }
          else {
            // To check for attribute that has no value selected in the grid, it will for all products
            const view = this._getViewForControl(control, selectedSection);
            if (view) {
              controls.push(view);
            }
          }
        });
        const sectionTemplate = this.customerAssessService.getSelectionTemplate(selectedSection, this.masterData, controls);
        if (sectionTemplate)
          this.currentFormView.push(sectionTemplate);
      })
    }
    // /*************Product Specific Assessments section*************/
    else {
      this.selectedSections.forEach(selectedSection => {
        const controls = [];
        this.template.section.Controls.forEach(control => {
          const view = this._getViewForControl(control, selectedSection);
          if (view) {
            controls.push(view);
          }
        });
        const sectionTemplate = this.customerAssessService.getSelectionTemplate(selectedSection, this.masterData, controls);
        if (sectionTemplate)
          this.currentFormView.push(sectionTemplate);
      })
    }
  }

  private _getPlaceholdertext(control: Control) {
    let placeholderText = control.Description;

    if (control.hasOwnProperty('Descriptions')) {

      const descriptions = control['Descriptions'];

      if (!_.isEmpty(descriptions)) {
        const placeholder = descriptions.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        if (placeholder) {
          placeholderText = placeholder.Description;
        }
      }
    }
    return placeholderText;
  }

  private _getLabeltext(control: Control, isSetToEnglish: boolean = false) {
    let labelText = control.DisplayName;

    if (control.Attribute.hasOwnProperty('DisplayNames')) {

      const displayNames = control.Attribute['DisplayNames'];

      if (!_.isEmpty(displayNames)) {
        let displayName;
        if(!isSetToEnglish) {
          displayName = displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        }else {
          displayName = displayNames.find(value => value.LanguageCode == '1033');
        }
        if (displayName) {
          labelText = displayName.Description;
        } else {
          let foundTranslatedStr = this.localizationService.getTranslatedString(labelText, isSetToEnglish);
          if(!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
        }
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(labelText, isSetToEnglish);
        if(!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
      }
    }
    return labelText;
  }

  private _getViewForControl(control: Control, selectedSection) {
    if (control.Type) {
      try {
        //Account Contact Criteria match
        if (this.criteriaData.entity == 'contact') {
          const contact = this.criteriaData.data as Contact;
          if (control.Attribute.ContactType || control.Attribute.Designation || control.Attribute.Specialty) {
            if (control.Attribute.ContactType && !control.Attribute.Designation && !control.Attribute.Specialty) {
              if (!contact?.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != contact?.indskr_contacttype) {
                return;
              }
            } else if (control.Attribute.ContactType && control.Attribute.Designation && !control.Attribute.Specialty) {
              if ((!contact?.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != contact?.indskr_contacttype) || (!contact.professionalDesignation || control.Attribute.Designation['Id'] != contact.professionalDesignation.id)) {
                return;
              }
            } else if (control.Attribute.ContactType && control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!contact?.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != contact?.indskr_contacttype) || (!contact.professionalDesignation || control.Attribute.Designation['Id'] != contact.professionalDesignation.id) || !contact.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (!control.Attribute.ContactType && control.Attribute.Designation && !control.Attribute.Specialty) {
              if (!contact.professionalDesignation || control.Attribute.Designation['Id'] != contact.professionalDesignation.id) {
                return;
              }
            } else if (!control.Attribute.ContactType && control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!contact.professionalDesignation || control.Attribute.Designation['Id'] != contact.professionalDesignation.id) || !contact.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (!control.Attribute.ContactType && !control.Attribute.Designation && control.Attribute.Specialty) {
              if (!contact.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (control.Attribute.ContactType && !control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!contact?.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != contact?.indskr_contacttype) || !contact.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            }
          }
        } else {
          const account = this.criteriaData.data as Account;
          if (control.Attribute.AccountType && account) {
            if (!account.extendedDetails.accountTypeOption || control.Attribute.AccountType['SelectedValue'] != account.extendedDetails.accountTypeOption) {
              return;
            }
          }
        }
        let labelText: string = this._getLabeltext(control);
        let placeholderText: string = this._getPlaceholdertext(control);
        const fieldType = this.customerAssessService.getFormFieldType(control, true);
        const inputText = this._getInputTextForFormField(control, selectedSection);
        const controlId: string = [selectedSection.positionId, selectedSection.taId, selectedSection.productId, selectedSection.procedureId, selectedSection.positionGroupId, control.Attribute.GUID].join("_");
        if (control.Type != AssessmentAttributeType.TIMESTAMP) {
          let fieldView: IndFormFieldViewDataModel = {
            label: labelText,
            inputText: inputText,
            inputValue: inputText,
            id: control.Attribute.GUID,
            isReadOnly: fieldType != FormFieldType.INLINE_INPUT,
            isDisabled: this._isControlDisabled(),
            showArrow: true,
            inputType: (control.Type == AssessmentAttributeType.NUMERIC || control.Type == AssessmentAttributeType.RANGE) ? 'number' : 'text',
            formFieldType: fieldType,
            isRequired: control.Mandatory,
            isInvalid: false,
            isEmptyRequiredField: control.Mandatory ? this._isClicked && this._isClicked[controlId] && _.isEmpty(inputText) : false,
            errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
            inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(id, value, event, this.currentFormView) : true,
            eventHandler: (id: string, event, eventName, section) => this._handleFormFieldEvent(id, event, eventName, section),
            section: selectedSection
          };
          if (placeholderText && placeholderText.trim() != '') {
            fieldView.customPlaceholderLabel = placeholderText;
          } else {
            fieldView.placeholderLabel = labelText;
          }
          if (control.Type == AssessmentAttributeType.RANGE) {
            fieldView.inputMin = control.RangeMin;
            fieldView.inputMax = control.RangeMax;
            fieldView.customPlaceholderLabel = fieldView.placeholderLabel = this.translate.instant('ENTER_RANGE_BETWEEN', {min: control.RangeMin, max: control.RangeMax} );
          }
          return {
            id: fieldView.id,
            type: 'form-field',
            view: fieldView,
            control: control
          };
        } else if (control.Type == AssessmentAttributeType.TIMESTAMP) {
          return this._getViewForTimeStampFormField(control, inputText, labelText, selectedSection);
        }
      } catch (error) {
        console.log('Error occured while parsing controls view' + error);
      }
    }
  }

  private _getViewForTimeStampFormField(control: Control, inputText: string, labelText: string, section) {
    let dateFieldView: IndDateTimeFormViewDataModel;
    let timeFieldView: IndDateTimeFormViewDataModel;
    let dateFieldValue: string = '';
    let timeFieldValue: string = '';
    const controlId: string = [this.getSectionId(section), control.Attribute.GUID].join("_");
    if (!_.isEmpty(inputText)) {
      const formattedDateTimeValue = moment(inputText).format();
      if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid Date') {
        let currentDateTimeValue = new Date(formattedDateTimeValue);
        dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        timeFieldValue = currentDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        if (!this.dateTimeFormatsService.is12HourFormat && timeFieldValue.includes("24:")) {
          timeFieldValue = timeFieldValue.replace("24:", "00:");
        }
      }
    }
    const dateTextTrans = this.translate.instant('DATE');
    let labelDateText: string = labelText;
    if (labelText.includes('Date') || labelText.includes('date') || labelText.includes(dateTextTrans) || labelText.includes(dateTextTrans.toLowerCase())) {
      labelDateText = labelText;
    } else {
      labelDateText = labelDateText + ' ' + dateTextTrans;
    }
    dateFieldView = {
      isDateField: true,
      label: labelDateText,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      inputText: dateFieldValue,
      inputValue: dateFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartDateField,
      controlId: control.Attribute.GUID,
      isReadOnly: true,
      isDisabled: this._isControlDisabled(), // _.isEmpty(inputText) ? this._isControlDisabled(control) : this._isControlDisabled(control) || isNotEditable,
      showArrow: true,
      isRequired: control.Mandatory,
      isInvalid: false,
      isEmptyRequiredField: control.Mandatory ? this._isClicked && this._isClicked[controlId] && _.isEmpty(inputText) : false,
      errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false) : true,
      eventHandler: (id: string, event, eventName, section) => this._handleFormFieldEvent(control.Attribute.GUID, event, eventName, section),
      section: section
    };
    const timeTextTrans = this.translate.instant('TIME');
    let labelTimeText: string = labelText;
    if (!labelTimeText.includes("Time") && !labelTimeText.includes("time")) {
      if (labelText.includes('Date')) {
        labelTimeText = labelText.replace('Date', 'Time');
      } else if (labelText.includes('date')) {
        labelTimeText = labelText.replace('date', 'time');
      } else if (labelText.includes(dateTextTrans)) {
        labelTimeText = labelText.replace(dateTextTrans, timeTextTrans);
      } else if (labelText.includes(dateTextTrans.toLowerCase())) {
        labelTimeText = labelText.replace(dateTextTrans.toLowerCase(), timeTextTrans.toLowerCase());
      } else {
        labelTimeText = labelTimeText + ' ' + timeTextTrans;
      }
    }
    timeFieldView = {
      isTimeField: true,
      label: labelTimeText,
      customPlaceholderLabel: this.translate.instant('SELECT_TIME'),
      inputText: timeFieldValue,
      inputValue: timeFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartTimeField,
      controlId: control.Attribute.GUID,
      isReadOnly: true,
      isDisabled: _.isEmpty(dateFieldValue) ? true : this._isControlDisabled(),
      showArrow: dateFieldView.inputText != '',
      isRequired: control.Mandatory,
      isInvalid: false,
      isEmptyRequiredField: control.Mandatory ? this._isClicked && this._isClicked[controlId] && _.isEmpty(inputText) : false,
      errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false) : true,
      section: section
    };
    return {
      id: dateFieldView.id,
      type: 'form-field',
      view: dateFieldView,
      timeView: timeFieldView,
      control: control,
    };
  }

  private _isControlDisabled() {
    return false;
  }

  private _getChoiceLabel(choiceOption: ChoiceOption, isSetToEnglish: boolean = false) {
    let label = choiceOption.Label;
    if (choiceOption.displayNames && !_.isEmpty(choiceOption.displayNames)) {
      let multilingualLabel; 
      if(!isSetToEnglish) {
        multilingualLabel = choiceOption.displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
      }else {
        multilingualLabel = choiceOption.displayNames.find(value => value.LanguageCode == '1033');
      }
      if (multilingualLabel) {
        label = multilingualLabel.Description;
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(label, isSetToEnglish);
        if(!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
      }
    } else {
      let foundTranslatedStr = this.localizationService.getTranslatedString(label, isSetToEnglish);
      if(!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
    }
    return label
  }

  private _getInputTextForFormField(control: Control, section): string {
    let value = '';
    if (control && control.Type) {
      let formValue;
      const sectionId: string = this.getSectionId(section);
      if (this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][control.Attribute.GUID]) {
        formValue = this._currentFormValue[sectionId][control.Attribute.GUID];
      } else if (this.rawData) {
        formValue = this.rawData.responses.find(resp => resp.indskr_attribute === control.Attribute.GUID && resp.indskr_position == section.positionId && resp.indskr_positiongroup == section.positionGroupId && resp.indskr_therapeuticarea == section.taId && resp.indskr_product == section.productId && resp.indskr_procedures == section.procedureId);
      }
      if (formValue || control.Type == AssessmentAttributeType.FLAG) {
        switch (control.Type) {
          case AssessmentAttributeType.TEXT:
            value = formValue['indskr_text'];
            break;
          case AssessmentAttributeType.FLAG:
            if (formValue && formValue.hasOwnProperty('indskr_flag')) {
              value = formValue['indskr_flag'];
            } else {
              value = control.FlagDeafultvalue.toString();
              this._setAttributeValue(control, control.FlagDeafultvalue, section, sectionId, false, true);
            }
            break;
          case AssessmentAttributeType.MEMO:
            value = formValue['indskr_memo'];
            break;
          case AssessmentAttributeType.NUMERIC:
          case AssessmentAttributeType.RANGE:
            value = '' + formValue['indskr_numeric'];
            break;
          case AssessmentAttributeType.TIMESTAMP:
            value = formValue['indskr_timestamp'] ? format(formValue['indskr_timestamp'], this.dateTimeFormatsService.dateTimeToUpper) : '';
            break;
          case AssessmentAttributeType.CHOICE:
            const choiceValues: string[] = formValue['indskr_choicevalues'] ? formValue['indskr_choicevalues'].split(",") : [];
            const selectedOptions: ChoiceOption[] = control.Choice.Options.filter(option => choiceValues.includes(option.RatingProperties[0].GUID));
            value = selectedOptions.length == 1 ? this._getChoiceLabel(selectedOptions[0]) : this._getChoiceLabel(selectedOptions[0]) + ' +' + (selectedOptions.length - 1);
            break;
        }
      }
      return value;
    }
  }

  private getDisplayTextForPosition(): string {
    //In case of TA, Position and TA and position, only Position selection after TA selected
    if (this.template.section.TherapetuicArea && this.selectedTAs.length == 0) return null;
    if (this.filteredPositions.length == 1) {
      this.selectedPositions = this.filteredPositions.map(posi => posi.ID);
      if (this.template.section.Product && !this.template.section.TherapetuicArea) {
        if (_.isEmpty(this.selectedProducts)) {
          const filteredProductIds = this.filteredPositions.find(posi => this.selectedPositions.includes(posi.ID))['unSavedProductIds'];
          this.filteredProducts = _.orderBy(this.masterData.products.filter(prod => filteredProductIds.includes(prod.ID)), 'name');
          this.selectedProducts = [];
        }
      }
      return this.filteredPositions[0].name;
    }
    if (!_.isEmpty(this.selectedPositions)) {
      const selectedPos = this.filteredPositions.filter(pos => this.selectedPositions.includes(pos.ID));
      return selectedPos.length > 1 ? selectedPos[0].name + ' +' + (selectedPos.length - 1) : selectedPos[0].name;
    }
    return null;
  }

  private getDisplayTextForPositionGroup(): string {
    //In case of TA, Position and TA and position, only Position selection after TA selected
    if (this.template.section.TherapetuicArea && this.selectedTAs.length == 0) return null;
    if (this.filteredPositionGroups.length == 1) {
      this.selectedPositionGroups = this.filteredPositionGroups.map(posi => posi.positionGroupId);
      if (this.template.section.Product && !this.template.section.TherapetuicArea) {
        if (_.isEmpty(this.selectedProducts)) {
          const filteredProductIds = this.filteredPositionGroups.find(posi => this.selectedPositionGroups.includes(posi.positionGroupId))['unSavedProductIds'];
          this.filteredProducts = _.orderBy(this.masterData.products.filter(prod => filteredProductIds.includes(prod.ID)), 'name');
          this.selectedProducts = [];
        }
      }
      return this.filteredPositionGroups[0].positionGroupName;
    }
    if (!_.isEmpty(this.selectedPositionGroups)) {
      const selectedPos = this.filteredPositionGroups.filter(pos => this.selectedPositionGroups.includes(pos.positionGroupId));
      return selectedPos.length > 1 ? selectedPos[0].positionGroupName + ' +' + (selectedPos.length - 1) : selectedPos[0].positionGroupName;
    }
    return null;
  }

  private getDisplayTextForProduct(): string {
    //Incase of Product and TA and Position section, if more than one position is filtered for selected TA: no need to auto select Product
    // if (this.template.section.Position && this.template.section.Product && this.template.section.TherapetuicArea && this.filteredPositions.length > 1 && this.filteredProducts.length == 1 && _.isEmpty(this.selectedProducts)) return null;
    if (this.template.section.Position && _.isEmpty(this.selectedPositions)) return null;

    if (this.filteredProducts.length == 1) {
      //Auto select Product if only one is available
      this.selectedProducts = this.filteredProducts.map(posi => posi.ID);
      return this.filteredProducts[0].name;
    }
    if (!_.isEmpty(this.selectedProducts)) {
      const selectedProd = this.filteredProducts.filter(prod => this.selectedProducts.includes(prod.ID));
      return selectedProd.length > 1 ? selectedProd[0].name + ' +' + (selectedProd.length - 1) : selectedProd[0].name;
    }
    if (this.selectedTAs.length == 1 && !this.template.section.Position && !this.template.section.PositionGroup) {
      const ta = this.masterData.ta.find(ta => ta.therapeuticareaId == this.selectedTAs[0]);
      if (ta['unSavedProductIds'] && ta['brands'].filter(prod => ta['unSavedProductIds'].includes(prod.ID)).length == 1) {
        this.filteredProducts = ta['brands'].filter(prod => ta['unSavedProductIds'].includes(prod.ID));
        this.selectedProducts.push(this.filteredProducts[0].ID);
        return this.filteredProducts[0].name;
      }
    }
    return null;
  }

  private getDisplayTextForTA(): string {
    if (this.masterData.ta.length == 1) {
      this.selectedTAs = [this.masterData.ta[0].therapeuticareaId];
      if (this.template.section.Position && this.template.section.Product) {
        const unSavedPositionProd = this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedPositionIds'];
        this.filteredPositions = _.orderBy(this.masterData.positions.filter(posi => unSavedPositionProd.map(posi => posi['positionId']).includes(posi.ID)), 'name');
        if (this.filteredPositions.length == 1) {
          this.selectedPositions = [this.filteredPositions[0].ID];
          if (_.isEmpty(this.selectedProducts)) {
            const filteredProductIds = unSavedPositionProd.find(posi => posi.positionId == this.selectedPositions[0])['unSavedProductIds']
            this.filteredProducts = _.orderBy(this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId))['brands'].filter(prod => filteredProductIds.includes(prod.ID)), 'name');
            this.selectedProducts = [];
          }
        }
      } else if (this.template.section.PositionGroup && this.template.section.Product) {
        const unSavedPositionGrpProd = this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedPositionGroupIds'];
        this.filteredPositionGroups = _.orderBy(this.masterData.positionGroups.filter(posi => unSavedPositionGrpProd.map(posi => posi['positionGroupId']).includes(posi.positionGroupId)), 'positionGroupName');
        if (this.filteredPositionGroups.length == 1) {
          this.selectedPositionGroups = [this.filteredPositionGroups[0].positionGroupId];
          if (_.isEmpty(this.selectedProducts)) {
            const filteredProductIds = unSavedPositionGrpProd.find(posi => posi.positionGroupId == this.selectedPositionGroups[0])['unSavedProductIds']
            this.filteredProducts = _.orderBy(this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId))['brands'].filter(prod => filteredProductIds.includes(prod.ID)), 'name');
            this.selectedProducts = [];
          }
        }
      }
      return this.masterData.ta[0].name;
    }
    if (!_.isEmpty(this.selectedTAs)) {
      const selectedTAs = this.masterData.ta.filter(ta => this.selectedTAs.includes(ta.therapeuticareaId));
      return selectedTAs.length > 1 ? selectedTAs[0].name + ' +' + (selectedTAs.length - 1) : selectedTAs[0].name;
    }
    return null;
  }

  private getDisplayTextForProcedure(): string {
    if (this.masterData.procedures.length == 1) {
      this.selectedProcedures = [this.masterData.procedures[0].ID];
      return this.masterData.procedures[0].name;
    }
    if (!_.isEmpty(this.selectedProcedures)) {
      const selectedProcedues = this.masterData.procedures.filter(procedure => this.selectedProcedures.includes(procedure.ID));
      return selectedProcedues.length > 1 ? selectedProcedues[0].name + ' +' + (selectedProcedues.length - 1) : selectedProcedues[0].name;
    }
    return null;
  }


  private _handleGeneralFormFieldEvent(id, event, eventName) {
    switch (id) {
      case 'position':
        this._handlePositionSelection(id, event, eventName);
        break;
      case 'positionGroup':
        this._handlePositionGroupSelection(id, event, eventName);
        break;
      case 'ta':
        this._handleTherapeuticAreaSelection(id, event, eventName);
        break;
      case 'product':
        this._handleProductSelection(id, event, eventName);
        break;
      case 'procedure':
        this._handleProcedureSelection(id, event, eventName);
        break;
    }
  }

  private _handleFormFieldEvent(id, event, eventName, section) {
    const targetControl: Control = this.getTargetControl(id);
    if (targetControl) {
      const controlId: string = [this.getSectionId(section), id].join("_");
      switch (targetControl.Type) {
        case AssessmentAttributeType.TEXT:
        case AssessmentAttributeType.MEMO:
        case AssessmentAttributeType.NUMERIC:
        case AssessmentAttributeType.RANGE:
          this._handleInlineInputFormField(id, event, eventName, targetControl, section.section);
          this._isClicked[controlId] = true;
          break;
        case AssessmentAttributeType.TIMESTAMP:
          this._handleDateTimeFormField(id, event, eventName, targetControl, section.section);
          this._isClicked[controlId] = true;
          break;
        case AssessmentAttributeType.CHOICE:
          this._handleChoiceFormField(id, event, eventName, targetControl, section.section);
          this._isClicked[controlId] = true;
          break;
        case AssessmentAttributeType.FLAG:
          this._handleFlagToggleFormField(id, event, eventName, targetControl, section.section);
          this._isClicked[controlId] = true;
          break;
        default:
          console.error("unhanled type");
      }
    }
  }

  private async _handleDateTimeFormField(id, event, eventName, targetControl: Control, section: any) {
    let currentStartDateTime;
    let currentEndDateTime;
    const sectionId: string = this.getSectionId(section);
    if (this._currentFormValue && this._currentFormValue.hasOwnProperty(sectionId) && this._currentFormValue[sectionId].hasOwnProperty(targetControl.Attribute.GUID)) {
      currentStartDateTime = this._currentFormValue[sectionId][targetControl.Attribute.GUID].indskr_timestamp
    } else if (this.rawData && this.rawData.responses.findIndex(resp => resp.indskr_attribute === targetControl.Attribute.GUID && resp.indskr_position == section.positionId && resp.indskr_positiongroup == section.positionGroupId && resp.indskr_therapeuticarea == section.taId && resp.indskr_product == section.productId && resp.indskr_procedures === section.procedureId) > -1) {
      currentStartDateTime = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID && resp.indskr_position == section.positionId && resp.indskr_positiongroup == section.positionGroupId && resp.indskr_therapeuticarea == section.taId && resp.indskr_product == section.productId && resp.indskr_procedures === section.procedureId).indskr_timestamp;
    }
    if (currentStartDateTime && !currentEndDateTime) {
      currentEndDateTime = currentStartDateTime;
    }
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ConfiguredForm,
          startDateTimeValue: currentStartDateTime ? moment(currentStartDateTime).format() : '',
          endDateTimeValue: currentEndDateTime ? moment(currentEndDateTime).format() : '',
          controlId: targetControl.Attribute.GUID,
        },
        cssClass: "datetime-popover"
      }
    );
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime);
        this._setAttributeValue(targetControl, valueDate.toISOString(), section, sectionId);
        this.customerAssessService._validateMandatoryField(targetControl.Attribute.GUID, null, { value: this._currentFormValue[sectionId][targetControl.Attribute.GUID] }, this.currentFormView);
        this.validateForm();
      }
    })
  }

  private async _handleFlagToggleFormField(id, event, eventName, targetControl: Control, section) {
    if (event?.detail) {
      const value = event.detail.checked;
      const sectionId = this.getSectionId(section);
      // isUpdated = this._currentFormValue && this._currentFormValue[sectionId] ? value != this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_flag'] : true;
      let isUpdated: boolean = false;
      if(this._currentFormValue && this._currentFormValue[sectionId]) {
        if(this._currentFormValue[sectionId][targetControl.Attribute.GUID] && this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_flag']) {
          isUpdated =  value != this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_flag'];
        }else {
          isUpdated = true;
        }
      }else {
        isUpdated = true;
      }
      if (isUpdated) {
        this._setAttributeValue(targetControl, value, section, sectionId, false);
        this.validateForm();
      }
    }
  }

  private async _handleChoiceFormField(id, event, eventName, targetControl: Control, section) {
    let selectedValue: string[] = [];
    const sectionId: string = this.getSectionId(section);
    if (this._currentFormValue && this._currentFormValue.hasOwnProperty(sectionId) && this._currentFormValue[sectionId].hasOwnProperty(targetControl.Attribute.GUID)) {
      selectedValue = this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_choicevalues'] ? this._currentFormValue[sectionId][targetControl.Attribute.GUID]['indskr_choicevalues'].split(",") : [];
    } else if (this.rawData) {
      const resp = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID && resp.indskr_position == section.positionId && resp.indskr_positiongroup == section.positionGroupId && resp.indskr_therapeuticarea == section.taId && resp.indskr_product == section.productId && resp.indskr_procedures == section.procedureId);
      selectedValue = resp ? resp['indskr_choicevalues'].split(",") : []
    }
    //sort choice options by order value
    const targetChoiceOptions: ChoiceOption[] = targetControl.Choice.Options || [];
    const sortedOptionsWithOrders = targetChoiceOptions.filter(op => op.Order !='').sort((a,b) => {
      const rawOrderA: string = a.Order;
      const rawOrderB: string = b.Order;
      const numOrderA: number = parseFloat(rawOrderA);
      const numOrderB: number = parseFloat(rawOrderB);

      if(!isNaN(numOrderA) && !isNaN(numOrderB)) {
        if(numOrderA !== numOrderB) {
          return numOrderA - numOrderB;
        }
        // If 'Order' values are the same, compare 'Value'
        const rawValueA: string = a.Value;
        const rawValueB: string = b.Value;
        const numValueA: number = parseFloat(rawValueA);
        const numValueB: number = parseFloat(rawValueB);
  
        if(!isNaN(numValueA) && !isNaN(numValueB)) {
          if(numValueA !== numValueB) {
            return numValueA - numValueB;
          }
        }
      }
      // If 'Order' and 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });

    const sortedOptionsWithoutOrders = targetChoiceOptions.filter(op => op.Order =='').sort((a,b) => {
      const rawValueA: string = a.Value;
      const rawValueB: string = b.Value;
      const numValueA: number = parseFloat(rawValueA);
      const numValueB: number = parseFloat(rawValueB);

      if(!isNaN(numValueA) && !isNaN(numValueB)) {
        if(numValueA !== numValueB) {
          return numValueA - numValueB;
        }
      }
      // If 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });
    
    let sortedOptions = sortedOptionsWithOrders.concat(sortedOptionsWithoutOrders);
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-choice-field-select',
      data: sortedOptions.map(op => {
        return {
          id: op.RatingProperties[0].GUID,
          title: this._getChoiceLabel(op),
          titleEnglish: this._getChoiceLabel(op, true),
          isSelected: selectedValue.includes(op.RatingProperties[0].GUID)
        }
      }),
      isMultipleSelectionEnabled: targetControl.Choice.Type == RatingScaleType.MULTI_SELECT,
      showCancelandDoneBtn: targetControl.Choice.Type == RatingScaleType.MULTI_SELECT
    };
    const _dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await _dropdownPopover.present();
    await _dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        let newSelectedValue = {};
        if (!_.isEmpty(data.selectedItems)) {
          const choiceValues: string[] = [];
          const choiceLabels: string[] = [];
          const choiceLabelsEnglish: string[] = [];
          data.selectedItems.forEach(item => {
            choiceValues.push(item.id);
            choiceLabels.push(item.title);
            choiceLabelsEnglish.push(item.titleEnglish);
          })
          newSelectedValue = {
            indskr_choice: targetControl.Choice.RatingScale.GUID,
            indskr_choicevalues: choiceValues.join(","),
            indskr_choicelabel: choiceLabels.join(","),
            lang_en_choiceLabel: choiceLabelsEnglish.join(","),
          }
          this._setAttributeValue(targetControl, newSelectedValue, section, sectionId);
        } else if (data.selectedItems && data.selectedItems.length == 0) {
          this._clearAttributeValue(targetControl, section, sectionId);
          newSelectedValue = null;
        }
        if (targetControl.Mandatory && this.customerAssessService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT) {
          this.customerAssessService._validateMandatoryField(id, null, { value: newSelectedValue }, this.currentFormView);
        }
        this.validateForm();
      }
    });
  }

  private _clearAttributeValue(control: Control, section, sectionId) {
    if (this.rawData) {
      const respSaved = this.rawData.responses.find(resp => resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID && resp.indskr_position == section.positionId && resp.indskr_positiongroup == section.positionGroupId && resp.indskr_therapeuticarea == section.taId && resp.indskr_product == section.productId && resp.indskr_procedures == section.procedureId);
      if (respSaved) {
        //Deactivate response if value is cleared out
        const responseCaptured = {
          statecode: 1,
          statuscode: 2
        };
        if (!this._deletedCurrentFormValue) this._deletedCurrentFormValue = {};
        if (!this._deletedCurrentFormValue[sectionId]) this._deletedCurrentFormValue[sectionId] = {};
        responseCaptured['indskr_customerassessmentresponseid'] = respSaved.indskr_customerassessmentresponseid;
        this._deletedCurrentFormValue[sectionId][control.Attribute.GUID] = responseCaptured;
      }
    }
    if (this._currentFormValue && this._currentFormValue[sectionId] && this._currentFormValue[sectionId][control.Attribute.GUID] && this._currentFormValue[sectionId][control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
      //Remove from current request payload to save
      delete this._currentFormValue[sectionId][control.Attribute.GUID];
    }
    this._initViewData();
  }

  private _handleInlineInputFormField(id, event, eventName, control: Control, section) {
    if (eventName == 'input_value_confirm' && control) {
      let value;
      const sectionId: string = this.getSectionId(section);
      if (event.value) {
        if (control.Type == AssessmentAttributeType.RANGE && (Number(event.value) < control.RangeMin || Number(event.value) > control.RangeMax)) {
          value = '';
        } else {
          value = event.value;
        }
      } else if (event.target) {
        if (control.Type == AssessmentAttributeType.RANGE && (Number(event.target.value) < control.RangeMin || Number(event.target.value) > control.RangeMax)) {
          value = '';
        } else {
          value = event.target.value;
        }
      }
      if (value) {
        this._setAttributeValue(control, value, section, sectionId, false);
      } else {
        this._clearAttributeValue(control, section, sectionId);
      }
      this._initViewData();
      this.validateForm();
    }
  }

  private _setAttributeValue(control: Control, value: any, section, sectionId: string, initViewData: boolean = true, defaultValues: boolean = false) {
    let responseCaptured = {
      indskr_attribute: control.Attribute.GUID,
      indskr_type: control.Type,
      indskr_name: control.Attribute.DisplayName,
      indskr_position: section.positionId,
      indskr_positiongroup: section.positionGroupId,
      indskr_product: section.productId,
      indskr_therapeuticarea: section.taId,
      indskr_procedures: section.procedureId,
      indskr_specialty: this.specialty ? this.specialty.id : null
    };
    responseCaptured['indskr_name'] = this._getLabeltext(control, true);
    if (this.device._isOffline) {
      responseCaptured['modifiedon'] = new Date().toISOString().toString(),
      responseCaptured['modifiedby'] = this.authService.user.xSystemUserID
    }

    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
        responseCaptured['indskr_text'] = value;
        break;
      case AssessmentAttributeType.FLAG:
        responseCaptured['indskr_flag'] = value;
        break;
      case AssessmentAttributeType.MEMO:
        responseCaptured['indskr_memo'] = value;
        break;
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        responseCaptured['indskr_numeric'] = value;
        break;
      case AssessmentAttributeType.TIMESTAMP:
        responseCaptured['indskr_timestamp'] = value;
        break;
      case AssessmentAttributeType.CHOICE:
        responseCaptured['indskr_choice'] = value['indskr_choice'];
        responseCaptured['indskr_choicevalues'] = value['indskr_choicevalues'];
        responseCaptured['indskr_choicelabel'] = value['lang_en_choiceLabel'] ? value['lang_en_choiceLabel'] : value['indskr_choicelabel'];
        break;
    }
    if(this.fromMultiUpdate) {
      let targetSectionId = this.template.section.SectionProperties.GUID;
      this.multipleFormView?.forEach((view) => {
        // To prevent the responseid from being copied
        if(!_.isEmpty(responseCaptured['indskr_customerassessmentresponseid'])) delete responseCaptured['indskr_customerassessmentresponseid'];
        let capturedResponses = _.cloneDeep(responseCaptured);
        let idx = this._multipleFormValue?.findIndex((form) => form.contactId == view.contactId && form.sectionId == targetSectionId && form.id == sectionId)
        if(idx<0) {
          this._multipleFormValue.push({
            contactId: view.contactId,
            sectionId: targetSectionId,
            id: sectionId,
            currentValue: {}
          })
        }
        let foundForm = this._multipleFormValue.find((formVal) => formVal.contactId == view.contactId && formVal.sectionId == targetSectionId && formVal.id == sectionId)
        let _currentValues = foundForm.currentValue;
        let _currentValue;
        if(!_.isEmpty(_currentValues)) _currentValue = _currentValues[control.Attribute.GUID];
        if(!_.isEmpty(_currentValue) && _currentValue['indskr_customerassessmentresponseid']) {
          capturedResponses['indskr_customerassessmentresponseid'] = _currentValues[control.Attribute.GUID]['indskr_customerassessmentresponseid'];
        } else {
          let index = -1;
          if(view.rawData) {
            index = view.rawData.responses.findIndex(resp => resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID);
          }
          capturedResponses['indskr_customerassessmentresponseid'] = index >= 0 ? view.rawData.responses[index]['indskr_customerassessmentresponseid'] : Guid.create().toString();
        }
        foundForm.currentValue[control.Attribute.GUID] = capturedResponses;

        // this._multipleFormValue.filter((formVal) => formVal.contactId == view.contactId && formVal.sectionId == targetSectionId)?.forEach((value) => {
        //   let isSectionValueSame = false;
        //   let _currentValues = value.currentValue;
        //   let _currentValue;
        //   if(!_.isEmpty(_currentValues)) {
        //     _currentValue = _currentValues[control.Attribute.GUID];
        //     if(!_.isEmpty(_currentValue)) {
        //       isSectionValueSame = _currentValue['indskr_procedures'] == capturedResponses['indskr_procedures'] &&
        //       _currentValue['indskr_product'] == capturedResponses['indskr_product'] &&
        //       _currentValue['indskr_position'] == capturedResponses['indskr_position'] &&
        //       _currentValue['indskr_therapeuticarea'] == capturedResponses['indskr_therapeuticarea'];
        //     }
        //   }
        //   if(_currentValues[control.Attribute.GUID] && _currentValues[control.Attribute.GUID]['indskr_customerassessmentresponseid'] && isSectionValueSame)
        //     capturedResponses['indskr_customerassessmentresponseid'] = _currentValues[control.Attribute.GUID]['indskr_customerassessmentresponseid'];
        //   else {
        //     let index = -1;
        //     if(view.rawData) {
        //       index = view.rawData.responses.findIndex(resp => resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID);
        //     }
        //     capturedResponses['indskr_customerassessmentresponseid'] = index >= 0 ? view.rawData.responses[index]['indskr_customerassessmentresponseid'] : Guid.create().toString();
        //   }
        //   value.currentValue[control.Attribute.GUID] = capturedResponses;
        // });
      })
    }
    let assessResponseId;
    if (!this._currentFormValue) this._currentFormValue = {};
    if (!this._currentFormValue[sectionId]) this._currentFormValue[sectionId] = {};
    if (this._currentFormValue[sectionId][control.Attribute.GUID] && this._currentFormValue[sectionId][control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
      // responseCaptured['indskr_customerassessmentresponseid'] = this._currentFormValue[sectionId][control.Attribute.GUID]['indskr_customerassessmentresponseid'];
      assessResponseId = this._currentFormValue[sectionId][control.Attribute.GUID]['indskr_customerassessmentresponseid'];
    } else {
      let index = -1;
      if (this.rawData) {
        index = this.rawData.responses.findIndex(resp =>
          resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID
          && resp.indskr_position == section.positionId && resp.indskr_positiongroup == section.positionGroupId && resp.indskr_therapeuticarea == section.taId
          && resp.indskr_product == section.productId && resp.indskr_procedures === section.procedureId);
      }
      assessResponseId = index >= 0 ? this.rawData.responses[index]['indskr_customerassessmentresponseid'] : Guid.create().toString();
    }
    this._currentFormValue[sectionId][control.Attribute.GUID] = responseCaptured;
    this._currentFormValue[sectionId][control.Attribute.GUID]['indskr_customerassessmentresponseid'] = assessResponseId;
    if (!defaultValues)
      this.isFormDirty = this.customerAssessService.isAssessmentDirty = true;
    if (initViewData && !defaultValues) {
      this._initViewData();
    }
  }

  private validateForm() {
    let isValid: boolean = true;
    if (this.currentFormView) {
      if (!this._currentFormValue || (Object.entries(this._currentFormValue).length != this.currentFormView.length) || this.currentFormView?.length == 0) {
        //If not all sub-sections are filled
        isValid = false;
        this.isFormDirty = !this._currentFormValue || this.currentFormView?.length == 0 ? false : this.isFormDirty;
      } else {
        //Check if all mandatory fields are filled
        this.currentFormView.forEach(formView => {
          if (isValid) {
            const sectionId: string = this.getSectionId(formView.controls[0].view.section);
            formView.controls.filter(formView => formView.type === 'form-field' && formView.control?.Mandatory).forEach(formView => {
              if (isValid) {
                const id = formView.id == DateTimeFieldType.StartDateField || formView.id == DateTimeFieldType.StartTimeField ? formView.view.controlId : formView.id;
                if (this._currentFormValue && this._currentFormValue.hasOwnProperty(sectionId) && this._currentFormValue[sectionId].hasOwnProperty(id)) {
                  isValid = true;
                } else if (this.rawData && formView.view.section) {
                  isValid = this.rawData.responses.some(resp => resp.indskr_attribute === id && resp.indskr_position == formView.view.section.positionId && resp.indskr_positiongroup == formView.view.section.positionGroupId 
                    && resp.indskr_therapeuticarea == formView.view.section.taId && resp.indskr_product == formView.view.section.productId
                    && resp.indskr_procedures == formView.view.section.procedureId);
                } else {
                  isValid = false;
                }
              }
            })
          }
        })
      }
    }
    if (this.template.isEdit) {
      this.titleBar.controls.find(control => control.id == 'save').isDisabled = !isValid;
    } else {
      this.titleBar.controls.find(control => control.id == 'confirm').isDisabled = !isValid;
    }
  }

  private getSectionId(section) {
    return [section.positionId, section.taId, section.productId, section.procedureId, section.positionGroupId].join("_");
  }

  private getTargetControl(id: string): Control {
    return this.template.section.Controls.find(control => control.Attribute.GUID === id);
  }

  private async _handlePositionSelection(id, event, eventName) {
    if (this.filteredPositions.length == 1 || this.filteredPositions.length == 0) return;
    let options = this.filteredPositions.map(posi => {
      return {
        id: posi.ID,
        title: posi.name,
        isSelected: this.selectedPositions.includes(posi.ID)
      }
    });

    const positionsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: !this.template.section.Product,
          title: this.translate.instant('POSITIONS'),
          dividerTitle: this.translate.instant('ALL_POSITIONS_CAP'),
          isSearchEnabled: true,
          // isReadOnly: this.isReadOnlyView
        }
      },
      backdropDismiss: false
    });
    positionsModal.present();
    positionsModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this._currentFormValue = {}; //clear selected values once TA is reset
          this.selectedPositions = [];
        } else {
          Object.keys(this._currentFormValue).forEach(id => {
            if (!data.selectedItems.some(item => id.includes(item.id))) {
              //Remove from current request payload to save
              delete this._currentFormValue[id];
            }
          })
          this.selectedPositions = data.selectedItems.map(item => item.id);
          if (this.template.section.TherapetuicArea || this.template.section.Product) {
            this.selectedProducts = [];
            this._currentFormValue = {} //Reset current form value
            if (this.template.section.Product && this.template.section.TherapetuicArea && !this.template.section.PositionGroup) {
              //Product, TA, Position section
              const selectedTA = this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId));
              const unSavedProductIds = selectedTA['unSavedPositionIds'].find(posiProdId => this.selectedPositions.includes(posiProdId['positionId']))['unSavedProductIds'];
              this.filteredProducts = _.orderBy(selectedTA['brands'].filter(prod => unSavedProductIds.includes(prod.ID)), 'name');
            } 
            else if (this.template.section.Product && this.template.section.Position && !this.template.section.TherapetuicArea && !this.template.section.PositionGroup) {
              //Product , Position section
              const filteredProductIds = [];
              this.filteredPositions.filter(posi => this.selectedPositions.includes(posi.ID)).forEach(posi => filteredProductIds.push(...posi['unSavedProductIds']))
              this.filteredProducts = _.orderBy(this.masterData.products.filter(prod => filteredProductIds.includes(prod.ID)), 'name');
            }
          }
        }
        this.initGeneralAttributes();
        this.validateForm();
      }
    });
  }

  private async _handlePositionGroupSelection(id, event, eventName) {
    if (this.filteredPositionGroups.length == 1 || this.filteredPositionGroups.length == 0) return;
    let options = this.filteredPositionGroups.map(posi => {
      return {
        id: posi.positionGroupId,
        title: posi.positionGroupName,
        isSelected: this.selectedPositionGroups.includes(posi.positionGroupId)
      }
    });

    const positionGroupsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: !this.template.section.Product,
          title: this.translate.instant('POSITION_GROUPS'),
          dividerTitle: this.translate.instant('ALL_POSITION_GROUPS'),
          isSearchEnabled: true,
          // isReadOnly: this.isReadOnlyView
        }
      },
      backdropDismiss: false
    });
    positionGroupsModal.present();
    positionGroupsModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this._currentFormValue = {};
          this.selectedPositionGroups = [];
        } else {
          Object.keys(this._currentFormValue).forEach(id => {
            if (!data.selectedItems.some(item => id.includes(item.id))) {
              //Remove from current request payload to save
              delete this._currentFormValue[id];
            }
          })
          this.selectedPositionGroups = data.selectedItems.map(item => item.id);
          if (this.template.section.TherapetuicArea || this.template.section.Product) {
            this.selectedProducts = [];
            this._currentFormValue = {} //Reset current form value
            if (this.template.section.Product && this.template.section.TherapetuicArea) {
              //Product, TA, PositionGroup section
              const selectedTA = this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId));
              const unSavedProductIds = selectedTA['unSavedPositionGroupIds'].find(posiProdId => this.selectedPositionGroups.includes(posiProdId['positionGroupId']))['unSavedProductIds'];
              this.filteredProducts = _.orderBy(selectedTA['brands'].filter(prod => unSavedProductIds.includes(prod.ID)), 'name');
            } 
            else if (this.template.section.Product && !this.template.section.Position && !this.template.section.TherapetuicArea && this.template.section.PositionGroup) {
              //Product , PositionGroup section
              const filteredProductIds = [];
              this.filteredPositionGroups.filter(posi => this.selectedPositionGroups.includes(posi.positionGroupId)).forEach(posi => filteredProductIds.push(...posi['unSavedProductIds']))
              this.filteredProducts = _.orderBy(this.masterData.products.filter(prod => filteredProductIds.includes(prod.ID)), 'name');
            }
            else if (this.template.section.Product && !this.template.section.Position && this.template.section.TherapetuicArea && this.template.section.PositionGroup) {
              //Product , PositionGroup and TA section
              const filteredProductIds = [];
              this.filteredPositionGroups.filter(posi => this.selectedPositionGroups.includes(posi.positionGroupId)).forEach(posi => filteredProductIds.push(...posi['unSavedProductIds']))
              this.filteredProducts = _.orderBy(this.masterData.products.filter(prod => filteredProductIds.includes(prod.ID)), 'name');
            }
          }
        }
        this.initGeneralAttributes();
        this.validateForm();
      }
    });
  }

  private async _handleProductSelection(id, event, eventName) {
    if (this.filteredProducts.length == 1 || this.filteredProducts.length == 0) return;
    if (this.template.section.Position && _.isEmpty(this.selectedPositions)) return;
    if (this.template.section.PositionGroup && _.isEmpty(this.selectedPositionGroups)) return;
    let data = [];
    if (this.template.section.Product && !this.template.section.Position && !this.template.section.PositionGroup && !this.template.section.TherapetuicArea) {
      //Product only
      data = this.filteredProducts.map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProducts.includes(prod.ID)
        }
      })
    } else if (this.template.section.Product && this.template.section.Position && !this.template.section.TherapetuicArea && !this.template.section.PositionGroup) {
      //Product and Position
      const unSavedProductIdsForPosition = this.filteredPositions.find(posi => this.selectedPositions[0] == posi.ID)['unSavedProductIds'];
      data = this.filteredProducts.filter(prod => unSavedProductIdsForPosition.includes(prod.ID)).map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProducts.includes(prod.ID)
        }
      })
    } else if (this.template.section.Product && !this.template.section.Position && !this.template.section.TherapetuicArea && this.template.section.PositionGroup) {
      //Product and PositionGroup
      const unSavedProductIdsForPositionGroup = this.filteredPositionGroups.find(posi => this.selectedPositionGroups[0] == posi.positionGroupId)['unSavedProductIds'];
      data = this.filteredProducts.filter(prod => unSavedProductIdsForPositionGroup.includes(prod.ID)).map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProducts.includes(prod.ID)
        }
      })
    } else if (this.template.section.Product && !this.template.section.Position && !this.template.section.PositionGroup && this.template.section.TherapetuicArea) {
      //Product and TA
      const unSavedProductIdsForPosition = this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedProductIds'];
      data = this.filteredProducts.filter(prod => unSavedProductIdsForPosition.includes(prod.ID)).map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProducts.includes(prod.ID)
        }
      })
    } else if (this.template.section.Product && this.template.section.Position && !this.template.section.PositionGroup && this.template.section.TherapetuicArea) {
      //Product and TA and Position
      data = this.filteredProducts.map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProducts.includes(prod.ID)
        }
      })
    } else if (this.template.section.Product && !this.template.section.Position && this.template.section.PositionGroup && this.template.section.TherapetuicArea) {
      //Product and TA and PositionGroup
      data = this.filteredProducts.map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProducts.includes(prod.ID)
        }
      })
    }

    const productsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('PRODUCTS'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: true,
          // isReadOnly: this.isReadOnlyView
        }
      },
      backdropDismiss: false
    });
    productsModal.present();
    productsModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this._currentFormValue = {}; //clear selected values once Product is reset
          this.selectedProducts = [];
        } else {
          Object.keys(this._currentFormValue).forEach(id => {
            if (!data.selectedItems.some(item => id.includes(item.id))) {
              //Remove from current request payload to save
              delete this._currentFormValue[id];
            }
          })
          this.selectedProducts = data.selectedItems.map(item => item.id);
        }
        this.initGeneralAttributes();
        this.validateForm();
      }
    });
  }

  private async _handleProcedureSelection(id, event, eventName) {
    if (this.template.section.Procedure && _.isEmpty(this.masterData.procedures)) return;
    let data = [];
    if (this.template.section.Procedure) {
      //Product only
      data = this.masterData.procedures.map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: this.selectedProcedures.includes(prod.ID)
        }
      })
    }
    const productsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('PROCEDURES'),
          dividerTitle: this.translate.instant('ALL_PROCEDURES'),
          isSearchEnabled: true,
        }
      },
      backdropDismiss: false
    });
    productsModal.present();
    productsModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this._currentFormValue = {}; //clear selected values once Procedure is reset
          this.selectedProcedures = [];
        } else {
          Object.keys(this._currentFormValue).forEach(id => {
            if (!data.selectedItems.some(item => id.includes(item.id))) {
              //Remove from current request payload to save
              delete this._currentFormValue[id];
            }
          })
          this.selectedProcedures = data.selectedItems.map(item => item.id);
        }
        this.initGeneralAttributes();
        this.validateForm();
      }
    });
  }

  private async _handleTherapeuticAreaSelection(id, event, eventName) {
    if (this.masterData.ta.length == 1 || this.masterData.ta.length == 0) return;
    let options = this.masterData.ta.map(ta => {
      return {
        id: ta.therapeuticareaId,
        title: ta.name,
        isSelected: this.selectedTAs.includes(ta.therapeuticareaId)
      }
    });

    const taModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: !this.template.section.Position && !this.template.section.PositionGroup && !this.template.section.Product,
          title: this.translate.instant('THERAPEUTIC_AREAS'),
          dividerTitle: this.translate.instant('ALL_CAP') + ' ' + this.translate.instant('THERAPEUTIC_AREAS_CAPS'),
          isSearchEnabled: true,
          // isReadOnly: this.isReadOnlyView
        }
      },
      backdropDismiss: false
    });
    taModal.present();
    taModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this._currentFormValue = {}; //clear selected values once TA is reset
          this.selectedTAs = [];
          this.selectedPositions = [];
          this.selectedPositionGroups = [];
          this.selectedProducts = [];
        } else if (this.selectedTAs.includes(data.selectedItems.map(item => item.id))) {
          return;
        } else {
          Object.keys(this._currentFormValue).forEach(id => {
            if (!data.selectedItems.some(item => item.id == id)) {
              //Remove from current request payload to save
              delete this._currentFormValue[id];
            }
          })
          this.selectedTAs = data.selectedItems.map(item => item.id);
          if (this.template.section.Product) {
            // TA and product and position, TA and Product
            this.filteredPositions = this.masterData.positions.filter(posi => this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedPositionIds'].find(posiProd => posiProd['positionId'] == posi.ID));
            this.filteredPositionGroups = this.masterData.positionGroups.filter(posi => this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedPositionGroupIds'].find(posiProd => posiProd['positionGroupId'] == posi.positionGroupId));
            if (this.filteredPositions.length == 1) {
              this.selectedPositions = [this.filteredPositions[0].ID];
              const filteredProductIds = this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedPositionIds'].find(posiProd => this.selectedPositions.includes(posiProd['positionId']))['unSavedProductIds'];
              this.filteredProducts = _.orderBy(this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId))['brands'].filter(prod => filteredProductIds.includes(prod.ID)), 'name');
              this.selectedProducts = [];
            } else {
              this.selectedPositions = [];
              this.selectedPositionGroups = [];
              this.selectedProducts = [];
            }
            if (this.filteredPositionGroups.length == 1) {
              this.selectedPositionGroups = [this.filteredPositionGroups[0].positionGroupId];
              const filteredProductIds = this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedPositionGroupIds'].find(posiProd => this.selectedPositionGroups.includes(posiProd['positionGroupId']))['unSavedProductIds'];
              this.filteredProducts = _.orderBy(this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId))['brands'].filter(prod => filteredProductIds.includes(prod.ID)), 'name');
              this.selectedProducts = [];
            } else {
              this.selectedPositions = [];
              this.selectedPositionGroups = [];
              this.selectedProducts = [];
            }
            if (this.template.section.Product && this.template.section.TherapetuicArea && !this.template.section.Position && !this.template.section.PositionGroup) {
              const filteredProductIds = this.masterData.ta.find(ta => this.selectedTAs[0] == ta.therapeuticareaId)['unSavedProductIds'];
              this.filteredProducts = _.orderBy(this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId))['brands'].filter(prod => filteredProductIds.includes(prod.ID)), 'name');
            }
          }
          /*else if (this.template.section.Product) {
            //TA and Product
            const ta = this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId));
            this.filteredProducts = _.orderBy(ta['brands'].filter(prod => ta['unSavedProductIds'].includes(prod.ID)), 'name');
            this.selectedProducts = [];
          }*/ else {
            //TA and Position
            const ta = this.masterData.ta.find(ta => this.selectedTAs.includes(ta.therapeuticareaId));
            this.filteredPositions = _.orderBy(this.masterData.positions.filter(prod => ta['unSavedPositionIds'].includes(prod.ID)), 'name');
            this.selectedPositions = [];
            this.filteredPositionGroups = _.orderBy(this.masterData.positionGroups.filter(prod => ta['unSavedPositionGroupIds'].includes(prod.positionGroupId)), 'positionGroupName');
            this.selectedPositionGroups = [];
          }
        }
        this.initGeneralAttributes();
        this.validateForm();
      }
    });
  }

  private initPageTitle() {
    const controls = [];
    let title;
    if (this.template.isEdit) {
      controls.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left',
      }, {
        id: 'save',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled: true,
        align: 'right'
      });
      title = this.translate.instant("EDIT") + " " + this.template.title;
    } else {
      controls.push(
        {
          id: 'cancel',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: false,
          align: 'right'
        },
        {
          id: 'confirm',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('CONFIRM'),
          isDisabled: true,
          align: 'right'
        });
      title = this.translate.instant("NEW") + " " + this.template.section.SectionProperties.DisplayName
    }

    this.titleBar = {
      id: 'section-specific',
      title: title,
      controls: controls
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
      case 'cancel':
        this.closePage(false);
        break;
      case 'save':
      case 'confirm':
        this.closePage(true);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private async closePage(isDone: boolean) {
    if(this.fromMultiUpdate) {
      if (isDone) {
        this.navService.popWithPageTracking().then(() => this.template.eventHandler(this.template.id, this._multipleFormValue));
      } else {
        if (!this.isFormDirty || (this.isFormDirty && await this.customerAssessService.discardChagesForAssess()))
          this.navService.popWithPageTracking();
      }
    } else {
      if (isDone) {
        this.navService.popChildNavPageWithPageTracking().then(() => this.template.eventHandler(this.template.id, this._currentFormValue));
      } else {
        if (!this.isFormDirty || (this.isFormDirty && await this.customerAssessService.discardChagesForAssess()))
          this.navService.popChildNavPageWithPageTracking();
      }
    }
    
  }
}
