<!-- <div class='template-personalize-split-pane' [ngClass]="{'list-active':contentToken}" >
  <ion-nav class="navList" #replacementList></ion-nav>
  <template-preview></template-preview>
</div> -->
<ion-grid class="content-page"
  [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed) ? 'preview-active' : 'preview-inactive'">
  <ion-row>
    <ion-col class="left-pane">
      <replacement-token-list [contentToken]="contentToken"></replacement-token-list>
    </ion-col>
    <ion-col class="right-pane">
      <ion-nav #templatepersonalizepagerightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
