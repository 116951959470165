import { Activity } from './../../classes/activity/activity.class';
import { TimeOffStatus } from './../../classes/activity/timeoff.class';
import { Injectable, NgZone } from "@angular/core";
import { TimeOffActivity } from "../../classes/activity/timeoff.class";
import { TImeOffReason } from "../../classes/timeoff/timeoffReasons.class";
import { TimeOffResponse } from "../../classes/activity/timeoff.response.class";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../disk/disk.service";
import { BehaviorSubject } from "rxjs";
import { User } from "../../classes/account/child.user.class";
import { Position } from "../../classes/account/child.position.class";
import * as moment from "moment";
import { differenceInDays } from "date-fns";
import { ActivityType } from "../../classes/activity/activity.class";
import { ActivityService } from "../activity/activity.service";

import { Events } from '@omni/events';
import { DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { DeltaService, EntitySyncInfo } from "../../data-services/delta/delta.service";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DateTimeFormatsService } from "../date-time-formats/date-time-formats.service";
import { TranslateService } from "@ngx-translate/core";
import { SelectedSuggestionPillDataModel } from "../../models/search-config-data-model";
import { SearchConfigService } from "../search/search-config.service";
import { UIService } from '../ui/ui.service';
import { DatePipe } from '@angular/common';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TimeOffService {

    public tot: TimeOffActivity[] = [];
    public teamTot: TimeOffActivity[] = [];

    public timeOffReasonList: TImeOffReason[] = [];

    public displayNoTimeOffActivity: boolean = false;
    public displayNoTeamTimeOffActivity: boolean = false;

    public displayNoUsers: boolean = false;
    public displayNoPositions: boolean = false;

    public totMode: string = "myRequests";
    public timeOffResponse: TimeOffResponse;

    public display = false;
    public isTotModified: boolean = false;

    private toFilterOptions: any = { all: true, status: false, position: false, user: false };

    public users: User[] = [];
    public positions: Position[] = [];

    private totObs = new BehaviorSubject<TimeOffActivity[]>(null);
    totObserver = this.totObs.asObservable();

    private teamTotObs = new BehaviorSubject<TimeOffActivity[]>(null);
    teamTotObserver = this.teamTotObs.asObservable();

    private selTot = new BehaviorSubject<TimeOffActivity>(null);
    selectedTot = this.selTot.asObservable();

    private userObs = new BehaviorSubject<User[]>(null);
    userObserver = this.userObs.asObservable();

    private positionObs = new BehaviorSubject<Position[]>(null);
    positionObserver = this.positionObs.asObservable();

    public popupSelectedEvent: string = this.translate.instant('ALL_DAY');
    public reasonSelectedEvent: string = "Sick";
    public popupSelectedTimePeriodEvent: string = this.translate.instant('AM');

    public defaultTimeOffReason: TImeOffReason;

    public timeOffFilterObject: filterModel = { all: "all", status: "", position: "", user: "" };

    public repTimeOffStatusFilterValue: string = "";
    public repTimeOffUserFilterValue: string = "";
    public repTimeOffPositionFilterValue: string = "";

    private filterSource = new BehaviorSubject<filterModel>(this.timeOffFilterObject);
    filterObserver = this.filterSource.asObservable();

    private mobRequestInitiatedFrom: string = '';

    //handler for cases where data request is taking place.
    public isProcessing: boolean = false;

    // Show details pane when on mobile
    public totShowRightPane = false;

    recentSearches: SelectedSuggestionPillDataModel[] = [];
    teamRecentSearches: SelectedSuggestionPillDataModel[] = [];

    constructor(
        public disk: DiskService,
        public _ngZone: NgZone,
        private activityService: ActivityService,
        private events: Events,
        private authenticationService: AuthenticationService,
        private deltaService: DeltaService,
        public dateTimeFormatsService: DateTimeFormatsService,
        public translate: TranslateService,
        private searchConfigService: SearchConfigService,
        private uiService: UIService,
        private datePipe: DatePipe
    ) { }

    setTOTMode(mode: string) {
        this.totMode = mode;
        this.resetAllRepTimeOffFilters();
    }

    public setIsTotModified(flag: boolean) {
        this.isTotModified = flag;
    }

    private upsertMyTimeOff(data: TimeOffActivity) {
        //checking for GUID
        let idx: number = this.tot.findIndex((e: TimeOffActivity) => data.ID === e.ID);
        //valid index detected then update the value
        if (idx > -1) {
            this.tot[idx] = data;
        }
        else {
            //GUID not found in record so we can create new or update the on basis of offline ID
            let offIdx: number = this.tot.findIndex((e: TimeOffActivity) => data.offlineTimeOffRequestId === e.offlineTimeOffRequestId);
            //valid offline ID detected so update the existing record
            if (offIdx > -1) {
                this.tot[offIdx] = data;
            }
            //Data is not registered on device so add record
            else {
                this.tot.push(data);
            }
        }
        this.mapMyTimeOffFieldsToSearchIndex(data);
    }

    private upsertTeamTimeOff(data: TimeOffActivity) {
        //checking for GUID
        let idx: number = this.teamTot.findIndex((e: TimeOffActivity) => data.ID === e.ID);
        //valid index detected then update the value
        if (idx > -1) {
            this.teamTot[idx] = data;
        }
        else {
            //GUID not found in record so we can create new or update the on basis of offline ID
            let offIdx: number = this.teamTot.findIndex((e: TimeOffActivity) => data.offlineTimeOffRequestId === e.offlineTimeOffRequestId);
            //valid offline ID detected so update the existing record
            if (offIdx > -1) {
                this.teamTot[offIdx] = data;
            }
            //Data is not registered on device so add record
            else {
                this.teamTot.push(data);
            }
        }
        this.mapTeamTimeOffFieldsToSearchIndex(data);
    }

    public async mapFullSyncedTots(rawTots, newLastUpdatedTime: number) {
        //Full sync requested so clean up and set the data obtained by service.
        //As we are performing the upload as well now

        return await this._ngZone.runOutsideAngular(async () => {
            this.tot = [];
            this.displayNoTimeOffActivity = false;
            if (rawTots && Array.isArray(rawTots)) {
                for (let i = 0; i < rawTots.length; i++) {
                    const rawTot = rawTots[i];
                    rawTot._id = DB_KEY_PREFIXES.MY_TIMEOFF + rawTot.indskr_timeoffrequestid;
                    rawTot.lastUpdatedTime = newLastUpdatedTime;

                    // Delete unnecessary data that starts with '_'
                    // Pouch db doesn't allow field name to start with '_'
                    rawTot['indskr_positionid_value'] = rawTot['_indskr_positionid_value'];
                    delete rawTot['_indskr_positionid_value'];
                    rawTot['indskr_positionid_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
                    delete rawTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                    rawTot['ownerid_value'] = rawTot['_ownerid_value'];
                    delete rawTot['_ownerid_value'];
                    rawTot['ownerid_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
                    delete rawTot['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                    rawTot['indskr_approver_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_indskr_approver_value'];
                    delete rawTot['_indskr_approver_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                    delete rawTot['_indskr_appointmentid_value'];
                    delete rawTot['_indskr_appointmentid_value@OData.Community.Display.V1.FormattedValue'];
                    delete rawTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
                    delete rawTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                    const timeOffType = rawTot.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
                    const timeOff = new TimeOffActivity(rawTot, timeOffType);
                    this.upsertMyTimeOff(timeOff);
                    this.activityService.addActivity(timeOff,false,false,null,false);
                }

                try {
                    // Bulk save docs to DB
                    await this.disk.bulk(rawTots);
                    this.activityService.displayActivities = this.activityService.activities;
                    this.totObs.next(this.tot);
                } catch (error) {
                    console.error('mapFullSyncedTots: ', error);
                    // TODO: handle error..
                }
            } else {
                console.error('mapFullSyncedTots: Invalid raw data provided: ', rawTots);
            }

            if (this.tot.length === 0) {
                this.displayNoTimeOffActivity = true;
            }
            return this.tot;
        });
    }

    // async mapFullSyncedTeamTots(rawTeamTots, newLastUpdatedTime: number) {
    //     return await this._ngZone.runOutsideAngular(async () => {
    //         this.teamTot = [];
    //         this.displayNoTeamTimeOffActivity = false;
    //         if (rawTeamTots && Array.isArray(rawTeamTots)) {
    //             for (let i = 0; i < rawTeamTots.length; i++) {
    //                 const rawTeamTot = rawTeamTots[i];
    //                 rawTeamTot._id = DB_KEY_PREFIXES.TEAM_TIMEOFF + rawTeamTot.indskr_timeoffrequestid;
    //                 rawTeamTot.lastUpdatedTime = newLastUpdatedTime;

    //                 rawTeamTot['indskr_positionid_value'] = rawTeamTot['_indskr_positionid_value'];
    //                 delete rawTeamTot['_indskr_positionid_value'];
    //                 rawTeamTot['indskr_positionid_value@OData.Community.Display.V1.FormattedValue'] = rawTeamTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    //                 delete rawTeamTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    //                 rawTeamTot['ownerid_value'] = rawTeamTot['_ownerid_value'];
    //                 delete rawTeamTot['_ownerid_value'];
    //                 rawTeamTot['ownerid_value@OData.Community.Display.V1.FormattedValue'] = rawTeamTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    //                 delete rawTeamTot['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    //                 rawTeamTot['indskr_approver_value@OData.Community.Display.V1.FormattedValue'] = rawTeamTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_indskr_approver_value'];
    //                 delete rawTeamTot['_indskr_approver_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    //                 delete rawTeamTot['_indskr_appointmentid_value'];
    //                 delete rawTeamTot['_indskr_appointmentid_value@OData.Community.Display.V1.FormattedValue'];
    //                 delete rawTeamTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    //                 delete rawTeamTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];
    //                 const timeOffType = rawTeamTot.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
    //                 const teamTimeOff = new TimeOffActivity(rawTeamTot, timeOffType);
    //                 this.upsertTeamTimeOff(teamTimeOff);
    //                 this.activityService.addActivity(teamTimeOff, true);
    //                 //this.teamTot.push(teamTimeOff);
    //             }

    //             try {
    //                 // Bulk save docs to DB
    //                 await this.disk.bulk(rawTeamTots);
    //                 this.teamTotObs.next(this.teamTot);

    //             } catch (error) {
    //                 console.error('mapFullSyncedTeamTots: ', error);
    //                 // TODO: handle error..
    //             }
    //         } else {
    //             console.error('mapFullSyncedTeamTots: Invalid raw data provided');
    //         }

    //         if (this.teamTot.length === 0) {
    //             this.displayNoTeamTimeOffActivity = true;
    //         }
    //         return this.teamTot;
    //     });
    // }

    async loadMyTotsFromDBAndMap(dataRange: { from: string, to: string }) {
        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.MY_TIMEOFF,
                    $lte: DB_KEY_PREFIXES.MY_TIMEOFF + PREFIX_SEARCH_ENDKEY_UNICODE
                },
            }
        };
        if (dataRange && dataRange.from) {
            option.selector['indskr_starttime'] = {
                $gte: dataRange.from
            };
        }
        if (dataRange && dataRange.to) {
            option.selector['indskr_starttime'] = Object.assign({},
                option.selector['indskr_starttime'],
                { $lte: dataRange.to }
            );
        }

        try {
            // Fetch from DB and do mapping
            const rawTots: any[] = await this.disk.find(option);

            this.tot = [];
            this.displayNoTimeOffActivity = false;
            if (rawTots && Array.isArray(rawTots)) {
                for (let i = 0; i < rawTots.length; i++) {
                    const rawTot = rawTots[i];
                    const timeOffType = rawTot.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
                    const timeOff = new TimeOffActivity(rawTot, timeOffType);
                    this.upsertMyTimeOff(timeOff);
                    this.activityService.addActivity(timeOff,false,false,null,false);
                }

                //this.activityService.displayActivities = this.activityService.activities;
                this.totObs.next(this.tot);
            }
        } catch (error) {
            console.error('loadMyTotsFromDBAndMap: ', error);
        }

        // Load offline created time offs that are not uploaded yet
        const offlineTimeOffsDoc = await this.disk.loadOfflineTimeOffs();
        if (offlineTimeOffsDoc && offlineTimeOffsDoc.hasOwnProperty('myTos') && Array.isArray(offlineTimeOffsDoc['myTos'])) {
            const rawOfflineCreatedTimeOffs = offlineTimeOffsDoc['myTos'].filter(a => (a.indskr_timeoffrequestid && a.indskr_timeoffrequestid.includes('offline')) || (a.indskr_local_timeoffrequestid && a.indskr_local_timeoffrequestid.includes('offline')));
            for (let i = 0; i < rawOfflineCreatedTimeOffs.length; i++) {
                let rawOfflineCreatedTimeOff = rawOfflineCreatedTimeOffs[i];
                const timeOffType = rawOfflineCreatedTimeOff.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
                const timeOff = new TimeOffActivity(rawOfflineCreatedTimeOff, timeOffType);
                timeOff.pendingPushToDynamics = true;
                // Set ownerId
                timeOff.totOwnerId = this.authenticationService.user.systemUserID;
                this.upsertMyTimeOff(timeOff);
                this.activityService.addActivity(timeOff,false,false,null,false);
            }

            this.activityService.displayActivities = this.activityService.activities;
            this.totObs.next(this.tot);
        }

        if (this.tot.length === 0) {
            this.displayNoTimeOffActivity = true;
        }

        this.disk.retrieve(DB_KEY_PREFIXES.TIME_OFF_RECENT_SEARCHES, true).then((doc)=>{
            if(doc && doc.raw){
              this.recentSearches = doc.raw
            }
            else {
              this.recentSearches = [];
            }
        })

    }

    // async loadTeamTotsFromDBAndMap(dataRange: { from: string, to: string }) {
    //     let option = {
    //         selector: {
    //             '_id': {
    //                 $gte: DB_KEY_PREFIXES.TEAM_TIMEOFF,
    //                 $lte: DB_KEY_PREFIXES.TEAM_TIMEOFF + PREFIX_SEARCH_ENDKEY_UNICODE
    //             },
    //         }
    //     };
    //     if (dataRange && dataRange.from) {
    //         option.selector['indskr_starttime'] = {
    //             $gte: dataRange.from
    //         };
    //     }
    //     if (dataRange && dataRange.to) {
    //         option.selector['indskr_starttime'] = Object.assign({},
    //             option.selector['indskr_starttime'],
    //             { $lte: dataRange.to }
    //         );
    //     }

    //     try {
    //         // Fetch from DB and do mapping
    //         const rawTeamTots: any[] = await this.disk.find(option);

    //         this.teamTot = [];
    //         this.displayNoTeamTimeOffActivity = false;

    //         if (rawTeamTots && Array.isArray(rawTeamTots)) {
    //             for (let i = 0; i < rawTeamTots.length; i++) {
    //                 const rawTeamTot = rawTeamTots[i];
    //                 const timeOff = new TimeOffActivity(rawTeamTot, ActivityType.TimeOffRequest);
    //                 this.upsertTeamTimeOff(timeOff);
    //                 //this.teamTot.push(timeOff);
    //             }

    //             this.teamTotObs.next(this.teamTot);
    //         }
    //     } catch (error) {
    //         console.error('loadTeamTotsFromDBAndMap: ', error);
    //     }

    //     if (this.teamTot.length === 0) {
    //         this.displayNoTeamTimeOffActivity = true;
    //     }

    //     this.disk.retrieve(DB_KEY_PREFIXES.TIME_OFF_TEAM_RECENT_SEARCHES, true).then((doc)=>{
    //         if(doc && doc.raw){
    //           this.teamRecentSearches = doc.raw
    //         }
    //         else {
    //           this.teamRecentSearches = [];
    //         }
    //     })

    // }

    async mapDeltaSyncedTots(rawTots, rawDeletedTots = [], newLastUpdatedTime: number) {
        let isUpdated = false;

        if (Array.isArray(rawTots) && rawTots.length > 0) {
            for (let i = 0; i < rawTots.length; i++) {
                const rawTot = rawTots[i];
                rawTot._id = DB_KEY_PREFIXES.MY_TIMEOFF + rawTot.indskr_timeoffrequestid;
                rawTot.lastUpdatedTime = newLastUpdatedTime;
                const doc = await this.disk.retrieve(rawTot._id, true);
                if (doc) {
                    rawTot['_rev'] = doc['_rev'];
                }

                rawTot['indskr_positionid_value'] = rawTot['_indskr_positionid_value'];
                delete rawTot['_indskr_positionid_value'];
                rawTot['indskr_positionid_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
                delete rawTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                rawTot['ownerid_value'] = rawTot['_ownerid_value'];
                delete rawTot['_ownerid_value'];
                rawTot['ownerid_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
                delete rawTot['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                rawTot['indskr_approver_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_indskr_approver_value'];
                delete rawTot['_indskr_approver_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                delete rawTot['_indskr_appointmentid_value'];
                delete rawTot['_indskr_appointmentid_value@OData.Community.Display.V1.FormattedValue'];
                delete rawTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
                delete rawTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

                const timeOffType = rawTot.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
                const timeOff = new TimeOffActivity(rawTot, timeOffType);
                this.upsertMyTimeOff(timeOff);
                // Replace if exists
                this.activityService.addActivity(timeOff, true,false,null,false);
            }

            try {
                // Bulk save docs to DB
                await this.disk.bulk(rawTots);
                isUpdated = true;
            } catch (error) {
                console.error('mapDeltaSyncedTots: ', error);
                // TODO: handle error..
            }
        }

        if (Array.isArray(rawDeletedTots) && rawDeletedTots.length > 0) {

            /* Handling my timeoffs removal */

            const delIds = rawDeletedTots.map(raw => DB_KEY_PREFIXES.MY_TIMEOFF + raw.indskr_entityid);
            const option = {
                selector: {
                    '_id': {
                        $in: delIds
                    }
                }
            };

            const rawTimeOffDocs = await this.disk.find(option);

            /* Handling team timeoff removal */

            const delTeamIds = rawDeletedTots.map(raw => DB_KEY_PREFIXES.TEAM_TIMEOFF + raw.indskr_entityid);
            const optionTeam = {
                selector: {
                    '_id': {
                        $in: delTeamIds
                    }
                }
            };
            const rawTeamTimeOffDocs = await this.disk.find(optionTeam);


            /* Updating pouch for my timeoff */
            if (Array.isArray(rawTimeOffDocs) && rawTimeOffDocs.length > 0) {
                isUpdated = true;
                for (let i = 0; i < rawTimeOffDocs.length; i++) {
                    const timeOffIdx: number = this.tot.findIndex(t => t.timeOffRequestId === rawTimeOffDocs[i]['indskr_timeoffrequestid']);
                    if (timeOffIdx >= 0) {
                        this.activityService.removeActivity(this.tot[timeOffIdx],false,null,false);
                        this.tot.splice(timeOffIdx, 1);
                    }
                    rawTimeOffDocs[i] = { _id: rawTimeOffDocs[i]._id, _rev: rawTimeOffDocs[i]._rev, _deleted: true };
                }

                try {
                    // Bulk save docs to DB
                    await this.disk.bulk(rawTimeOffDocs);
                } catch (error) {
                    console.error('mapDeltaSyncedTots: ', error);
                    // TODO: handle error..
                }
            }

            /* Updating pouch for team timeoff */
            if (Array.isArray(rawTeamTimeOffDocs) && rawTeamTimeOffDocs.length > 0) {
                isUpdated = true;
                for (let i = 0; i < rawTeamTimeOffDocs.length; i++) {
                    const teamTimeOffIdx: number = this.teamTot.findIndex(t => t.timeOffRequestId === rawTeamTimeOffDocs[i]['indskr_timeoffrequestid']);
                    if (teamTimeOffIdx >= 0) {
                        this.activityService.removeActivity(this.teamTot[teamTimeOffIdx],false,null,false);
                        this.teamTot.splice(teamTimeOffIdx, 1);
                    }
                    rawTeamTimeOffDocs[i] = { _id: rawTeamTimeOffDocs[i]._id, _rev: rawTeamTimeOffDocs[i]._rev, _deleted: true };
                }

                try {
                    // Bulk save docs to DB
                    await this.disk.bulk(rawTeamTimeOffDocs);
                } catch (error) {
                    console.error('mapDeltaSyncedTeamTots: ', error);
                    // TODO: handle error..
                }
            }

        }

        if (isUpdated) {
            this.activityService.displayActivities = this.activityService.activities;
            this.totObs.next(this.tot);
        }
    }

    // async mapDeltaSyncedTeamTots(rawTeamTots, newLastUpdatedTime: number) {
    //     if (rawTeamTots && Array.isArray(rawTeamTots)) {
    //         for (let i = 0; i < rawTeamTots.length; i++) {
    //             const rawTeamTot = rawTeamTots[i];
    //             rawTeamTot['_id'] = DB_KEY_PREFIXES.TEAM_TIMEOFF + rawTeamTot.indskr_timeoffrequestid;
    //             rawTeamTot['lastUpdatedTime'] = newLastUpdatedTime;
    //             const doc = await this.disk.retrieve(rawTeamTot._id, true);
    //             if (doc) {
    //                 rawTeamTot['_rev'] = doc['_rev'];
    //             }

    //             rawTeamTot['indskr_positionid_value'] = rawTeamTot['_indskr_positionid_value'];
    //             delete rawTeamTot['_indskr_positionid_value'];
    //             rawTeamTot['indskr_positionid_value@OData.Community.Display.V1.FormattedValue'] = rawTeamTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    //             delete rawTeamTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    //             rawTeamTot['ownerid_value'] = rawTeamTot['_ownerid_value'];
    //             delete rawTeamTot['_ownerid_value'];
    //             rawTeamTot['ownerid_value@OData.Community.Display.V1.FormattedValue'] = rawTeamTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    //             delete rawTeamTot['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    //             rawTeamTot['indskr_approver_value@OData.Community.Display.V1.FormattedValue'] = rawTeamTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_indskr_approver_value'];
    //             delete rawTeamTot['_indskr_approver_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    //             delete rawTeamTot['_indskr_appointmentid_value'];
    //             delete rawTeamTot['_indskr_appointmentid_value@OData.Community.Display.V1.FormattedValue'];
    //             delete rawTeamTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    //             delete rawTeamTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];
    //             const timeOffType = rawTeamTot.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
    //             const timeOff = new TimeOffActivity(rawTeamTot, timeOffType);
    //             const totIdx = this.teamTot.findIndex(tot => tot.timeOffRequestId === timeOff.timeOffRequestId);
    //             if (totIdx >= 0) {
    //                 this.teamTot[totIdx] = timeOff;
    //                 this.upsertTeamTimeOff(timeOff);
    //                 this.activityService.addActivity(timeOff, true);
    //             } else {
    //                 this.upsertTeamTimeOff(timeOff);
    //                 this.activityService.addActivity(timeOff, true);
    //                 //this.teamTot.push(timeOff);
    //             }
    //         }

    //         try {
    //             // Bulk save docs to DB
    //             await this.disk.bulk(rawTeamTots);
    //             this.teamTotObs.next(this.teamTot);
    //         } catch (error) {
    //             console.error('mapDeltaSyncedTeamTots: ', error);
    //             // TODO: handle error..
    //         }
    //     }
    // }

    async mapUploadedOfflineTots(rawUploadedTotResponse, offlineTimeOffDBDoc, timeOffSyncInfo?: EntitySyncInfo, totalUploadRecordCount?: number, url?: string, isUpload?: boolean) {
        if (rawUploadedTotResponse && Array.isArray(rawUploadedTotResponse['offlineTimeOffs'])) {
            const newLastUpdatedTime = new Date().getTime();
            const rawTotsWithProperFormat = [];

            for (let i = 0; i < rawUploadedTotResponse['offlineTimeOffs'].length; i++) {
                const offlineTimeOffResponse = rawUploadedTotResponse['offlineTimeOffs'][i];

                if (!offlineTimeOffResponse.errorMessage) {
                    const isOfflineCreatedTot = offlineTimeOffResponse.hasOwnProperty('indskr_local_timeoffrequestid');
                    let totIdx = isOfflineCreatedTot ?
                        this.tot.findIndex(tot => tot.ID === offlineTimeOffResponse['indskr_local_timeoffrequestid']) :
                        this.tot.findIndex(tot => tot.ID === offlineTimeOffResponse['indskr_timeoffrequestid']);
                    const offlineDocIdx = isOfflineCreatedTot ?
                        offlineTimeOffDBDoc['myTos'].findIndex(t => t.indskr_local_timeoffrequestid === offlineTimeOffResponse['indskr_local_timeoffrequestid'])
                        : offlineTimeOffDBDoc['myTos'].findIndex(t => t.indskr_timeoffrequestid === offlineTimeOffResponse['indskr_timeoffrequestid']);

                    if (totIdx >= 0) {
                        const newRawTotDoc = this.tot[totIdx].DTO;
                        this.tot[totIdx]._id = newRawTotDoc['_id'] = DB_KEY_PREFIXES.MY_TIMEOFF + offlineTimeOffResponse['indskr_timeoffrequestid'];
                        this.tot[totIdx].ID = this.tot[totIdx].timeOffRequestId = newRawTotDoc['indskr_timeoffrequestid'] = offlineTimeOffResponse['indskr_timeoffrequestid'];
                        this.tot[totIdx].lastUpdatedTime = newRawTotDoc['lastUpdatedTime'] = newLastUpdatedTime;

                        if (this.tot[totIdx]._rev) {
                            newRawTotDoc['_rev'] = this.tot[totIdx]._rev;
                        } else {
                            const doc = await this.disk.retrieve(this.tot[totIdx]._id, true);
                            if (doc) {
                                this.tot[totIdx]._rev = newRawTotDoc['_rev'] = doc._rev;
                            }
                        }

                        rawTotsWithProperFormat.push(newRawTotDoc);

                        if (offlineDocIdx >= 0) {
                            // Remove from offline doc
                            offlineTimeOffDBDoc['myTos'].splice(offlineDocIdx, 1);
                        } else {
                            console.error('mapUploadedOfflineTots: ' + isOfflineCreatedTot ? offlineTimeOffResponse['indskr_local_timeoffrequestid'] : offlineTimeOffResponse['indskr_timeoffrequestid'] + ' not found from offline doc');
                        }
                    } else {
                        if (offlineTimeOffResponse['statuscode'] && offlineTimeOffResponse['statuscode'] === 100000001) {
                            // Deleted ones fall here since we remove from tot array at the time of deletion.
                            if (offlineDocIdx >= 0) {
                                // Remove from offline doc
                                offlineTimeOffDBDoc['myTos'].splice(offlineDocIdx, 1);
                            } else {
                                console.error('mapUploadedOfflineTots: ' + isOfflineCreatedTot ? offlineTimeOffResponse['indskr_local_timeoffrequestid'] : offlineTimeOffResponse['indskr_timeoffrequestid'] + ' not found from offline doc');
                            }
                        } else {
                            console.error('mapUploadedOfflineTots: ' + isOfflineCreatedTot ? offlineTimeOffResponse['indskr_local_timeoffrequestid'] : offlineTimeOffResponse['indskr_timeoffrequestid'] + ' not found from tot array');
                            //CWD-3717
                            if (isUpload) {
                                console.log("offline TOT trying to get uploaded but no data in the service level storing TO");

                                let tempTimeOff = new TimeOffActivity({
                                    indskr_timeoffrequestid: offlineTimeOffResponse.indskr_timeoffrequestid,
                                    statecode: 0,
                                    statuscode: 100000000,
                                    "statuscode@OData.Community.Display.V1.FormattedValue": "Open",
                                    "indskr_starttime@OData.Community.Display.V1.FormattedValue": offlineTimeOffResponse.indskr_starttime,
                                    indskr_starttime: offlineTimeOffResponse.indskr_starttime,
                                    _indskr_positionid_value: this.authenticationService.user.xPositionID,
                                    // "_indskr_positionid_value@OData.Community.Display.V1.FormattedValue": this.authenticationService.user.positionName,
                                    indskr_isalldayevent: true,
                                    "indskr_endtime@OData.Community.Display.V1.FormattedValue": offlineTimeOffResponse.indskr_endtime,
                                    indskr_endtime: offlineTimeOffResponse.indskr_endtime,
                                    indskr_reason: offlineTimeOffResponse.indskr_reason,
                                    "indskr_reason@OData.Community.Display.V1.FormattedValue": "",
                                    indskr_name: offlineTimeOffResponse.indskr_name,
                                    _ownerid_value: this.authenticationService.user.systemUserID,
                                    "_ownerid_value@OData.Community.Display.V1.FormattedValue": "",
                                    indskr_local_timeoffrequestid: offlineTimeOffResponse.indskr_local_timeoffrequestid
                                }, ActivityType.TimeOffRequest);

                                const newRawTotDoc = tempTimeOff.DTO;
                                tempTimeOff._id = newRawTotDoc['_id'] = DB_KEY_PREFIXES.MY_TIMEOFF + offlineTimeOffResponse['indskr_timeoffrequestid'];
                                tempTimeOff.ID = tempTimeOff.timeOffRequestId = newRawTotDoc['indskr_timeoffrequestid'] = offlineTimeOffResponse['indskr_timeoffrequestid'];
                                tempTimeOff.lastUpdatedTime = newRawTotDoc['lastUpdatedTime'] = newLastUpdatedTime;

                                const doc = await this.disk.retrieve(offlineTimeOffResponse.indskr_local_timeoffrequestid, true);
                                if (doc) {
                                    tempTimeOff._rev = newRawTotDoc['_rev'] = doc._rev;
                                }

                                this.tot.push(tempTimeOff);
                                rawTotsWithProperFormat.push(newRawTotDoc);

                                if (offlineDocIdx >= 0) {
                                    // Remove from offline doc
                                    offlineTimeOffDBDoc['myTos'].splice(offlineDocIdx, 1);
                                } else {
                                    console.error('mapUploadedOfflineTots: ' + isOfflineCreatedTot ? offlineTimeOffResponse['indskr_local_timeoffrequestid'] : offlineTimeOffResponse['indskr_timeoffrequestid'] + ' not found from offline doc');
                                }
                            }
                        }
                    }

                    if (timeOffSyncInfo) {
                        timeOffSyncInfo.totalSynced++;
                    }
                } else {
                    if (timeOffSyncInfo) {
                        // timeOffSyncInfo.errorMessage = '[timeOff][error]' + offlineTimeOffResponse && offlineTimeOffResponse.errorMessage ? offlineTimeOffResponse.errorMessage : `Upload failed for ${offlineTimeOffResponse.indskr_timeoffrequestid}`;
                        this.deltaService.addSyncErrorToEntitySyncInfo(timeOffSyncInfo, url, offlineTimeOffResponse);
                        timeOffSyncInfo.totalFailed++;
                    }
                }
            };

            try {
                await this.disk.bulk(rawTotsWithProperFormat);
                this.totObs.next(this.tot);
            } catch (error) {
                console.error('mapUploadedOfflineTots: ', error);
            }

            await this.disk.updateDocWithIdAndRev(offlineTimeOffDBDoc);
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.TIME_OFF, offlineTimeOffDBDoc.myTos.length);
        } else {
            console.error('mapUploadedOfflineTots: Invalid raw data provided');
            if (timeOffSyncInfo) {
                //timeOffSyncInfo.errorMessage = '[timeOff][error] Invalid response';
                this.deltaService.addSyncErrorToEntitySyncInfo(timeOffSyncInfo, url, rawUploadedTotResponse);
                timeOffSyncInfo.totalFailed = totalUploadRecordCount;
            }
        }
    }

    public async purgeData(maxEndDateUnixTimestamp: number) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
            return;
        }

        // Find option
        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.TIMEOFF,
                    $lte: DB_KEY_PREFIXES.TIMEOFF + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                'indskr_endtime': {
                    $lt: maxEndDateUnixTimestamp.toString()
                }
            }
        };

        let deletedTimeOffs;
        try {
            // Fetch from DB and delete
            const rawTimeOffs: any[] = await this.disk.find(option);

            if (rawTimeOffs && Array.isArray(rawTimeOffs)) {
                let wasThereTotChange = false;
                let wasThereTeamTotChange = false;
                deletedTimeOffs = rawTimeOffs.map(raw => {
                    // Remove from activity array if exists.
                    // Not using constructor to avoid unnecessary overhead
                    const isTeam = this.authenticationService.user.systemUserID !== raw['ownerid_value'];
                    const totHandle = isTeam ? this.teamTot : this.tot;
                    const idx = totHandle.findIndex(tot => tot.ID === raw['indskr_timeoffrequestid']);
                    if (idx >= 0) {
                        totHandle.splice(idx, 1);
                        const timeOffType = raw.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
                        const tempTimeOff = { ID: raw['indskr_timeoffrequestid'], type: timeOffType };
                        this.activityService.removeActivity(tempTimeOff as TimeOffActivity);

                        if (!isTeam) {
                            wasThereTotChange = true;
                        } else {
                            wasThereTeamTotChange = true;
                        }
                    }
                    return { _id: raw._id, _rev: raw._rev, _deleted: true };
                });

                // In case sync happenes in time off tool page..
                if (wasThereTotChange) {
                    this.totObs.next(this.tot);
                }
                if (wasThereTeamTotChange) {
                    this.teamTotObs.next(this.teamTot);
                }
            }
        } catch (error) {
            console.error('purgeData [timeOff]: ', error);
        }

        try {
            // Bulk save/update docs to DB
            await this.disk.bulk(deletedTimeOffs);
        } catch (error) {
            // TODO: handle error..
            console.error('purgeData [timeOff]: ', error);
        }
    }

    public orderByDate(tot: TimeOffActivity[]): TimeOffActivity[] {
        return tot.slice().sort((a, b) => {
            return a.totStartTime < b.totStartTime ? -1 : 1;
        });
    }

    //Mapping child users via data service
    public mapUsers(raw: any, doNotSave?: boolean): User[] {
        this.users = [];
        this.displayNoUsers = false;
        if (Array.isArray(raw)) {
            raw.map(rawJSON => {
                this.users.push(new User(rawJSON));
            });
            this.userObs.next(this.users);
            if (this.users.length === 0) { this.displayNoUsers = true; }
        }
        else {
            console.error('Unsupported data format for TOT!');
        }

        if (!doNotSave) {
            //Save to our DB or update
            this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_TIMEOFF_USERS, doc => ({ raw }))
                .catch(error => console.error('TimeOffService: mapUsers: ', error));
        }
        return this.users;
    }

    //Mapping child positions via data service
    public mapPositions(raw: any, doNotSave?: boolean): Position[] {
        this.positions = [];
        this.displayNoPositions = false;
        if (Array.isArray(raw)) {
            raw.map(rawJSON => {
                this.positions.push(new Position(rawJSON));
            });
            this.positionObs.next(this.positions);
            if (this.positions.length === 0) { this.displayNoPositions = true; }
        }
        else {
            console.error('Unsupported data format for TOT!');
        }

        if (!doNotSave) {
            //Save to our DB or update
            this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_TIMEOFF_POSITIONS, doc => ({ raw }))
                .catch(error => console.error('TimeOffService: mapUsers: ', error));
        }
        return this.positions;
    }

    //selecting rep selected TOT
    public setSelectedTot(tot: TimeOffActivity) {
        this.selTot.next(tot);
    }

    public updateSelectedTot(timeOff: TimeOffActivity) {
        const index: number = this.tot.findIndex(t => t.ID === timeOff.ID);
        if (index >= 0) {
            this.tot[index] = timeOff;
            this.totObs.next(this.tot);
        }
    }

    public async mapTimeOffReasons(raw: any) {
      this.timeOffReasonList = [];
      if (Array.isArray(raw)) {
      const reasonsToHide = this.getReasonsToHideAsPerConfig();
        raw.map(rawData => {
          if (!reasonsToHide.includes(rawData['reason']))
            this.timeOffReasonList.push(new TImeOffReason(rawData));
        });
      } else {
        console.error('Unsupported data format for Team TOT!');
      }
      // Initializing default timeoff reason value to be used is hard coded for now until service include any default tag
      let foundReason = this.timeOffReasonList.find(t => t.reasonCode == 100000001);
      if (foundReason) {
        this.defaultTimeOffReason = foundReason;
      }
    }

  private getReasonsToHideAsPerConfig() {
    const reasonsConfig = this.authenticationService.user.ioConfigurations?.find(o => o.configName == "Timeoff.Reasons.Configuration");
    const reasonsToHide = [];
    if (reasonsConfig) {
      const config = JSON.parse(reasonsConfig.configValue);
      if (!_.isEmpty(config)) {
        config.forEach(config => {
          if (config.hasOwnProperty('Reason') && config.hasOwnProperty('Display') && config['Display'] == 'No') {
            reasonsToHide.push(config['Reason']);
          }
        });
      }
    }
    return reasonsToHide;
  }

    public async mapTOTResponse(raw: any) {
        this.timeOffResponse = new TimeOffResponse(raw);
        console.log(this.timeOffResponse);
    }


    /* Time off ;list filter logic */
    //reset
    public resetAllRepTimeOffFilters() {
        this.toFilterOptions.all = true;
        this.toFilterOptions.status = false;
        this.toFilterOptions.user = false;
        this.toFilterOptions.position = false;
        this.repTimeOffStatusFilterValue = "";
        this.repTimeOffUserFilterValue = "";
        this.repTimeOffPositionFilterValue = "";
        this.timeOffFilterObject = { all: "all", status: "", position: "", user: "" };
        this.updateFilterValue();
    }

    //Whenever user selects any status then update the filterObject for pipe to subscribe
    public updateStatusOption(): void {
        //if (this.repTimeOffStatusFilterValue != "") {
        this.toFilterOptions.all = false;
        this.toFilterOptions.status = this.repTimeOffStatusFilterValue ? true : false;
        this.timeOffFilterObject.all = "";
        this.timeOffFilterObject.status = this.repTimeOffStatusFilterValue;
        this.updateFilterValue();
        // }
        console.log("status filter has been selected");
    }

    //Whenever user selects any user then update the filterObject for pipe to subscribe
    public updateUserOption(): void {
        // if (this.repTimeOffUserFilterValue != "") {
        this.toFilterOptions.all = false;
        this.toFilterOptions.user = this.repTimeOffUserFilterValue ? true : false;
        this.timeOffFilterObject.all = "";
        this.timeOffFilterObject.user = this.repTimeOffUserFilterValue;
        this.updateFilterValue();
        // }
        console.log("user filter has been selected");
    }

    //Whenever user selects any position then update the filterObject for pipe to subscribe
    public updatePositionOption(): void {
        // if (this.repTimeOffPositionFilterValue != "") {
        this.toFilterOptions.all = false;
        this.toFilterOptions.position = this.repTimeOffPositionFilterValue ? true : false;
        this.timeOffFilterObject.all = "";
        this.timeOffFilterObject.position = this.repTimeOffPositionFilterValue;
        this.updateFilterValue();
        // }
        console.log("position filter has been selected");
    }

    private updateFilterValue() {
        console.log(this.timeOffFilterObject);
        this.filterSource.next(this.timeOffFilterObject);
    }

    get getStringForFilterValue(): string {
        //If we have to specify all the current filters set
        let str: string = "";
        if (this.totMode == "teamRequests") {
            if (this.toFilterOptions.status) {
                str = `${this.repTimeOffStatusFilterValue}`;
                if (this.toFilterOptions.user) {
                    str += ', ' + `${this.repTimeOffUserFilterValue}`;
                    if (this.toFilterOptions.position) {
                        str += ', ' + `${this.repTimeOffPositionFilterValue}`;
                    }
                }
                else if (this.toFilterOptions.position) {
                    str += ', ' + `${this.repTimeOffPositionFilterValue}`;
                }
            }
            else if (this.toFilterOptions.user) {
                str = `${this.repTimeOffUserFilterValue}`;
                if (this.toFilterOptions.position) {
                    str += ', ' + `${this.repTimeOffPositionFilterValue}`;
                }
            }
            else if (this.toFilterOptions.position) {
                str = `${this.repTimeOffPositionFilterValue}`;
            }
            else {
                str = this.translate.instant('TIMEOFF_ALL_TIME_OFF')
            }
            return str
        } else {
            let TimeOffStatusFilterValue: string;
            switch (this.repTimeOffStatusFilterValue) {
                case 'Open':
                    TimeOffStatusFilterValue = this.translate.instant('OPEN');
                    break;
                case 'Approved':
                    TimeOffStatusFilterValue = this.translate.instant('APPROVED');
                    break;
                case 'For Review':
                    TimeOffStatusFilterValue = this.translate.instant('FOR_REVIEW');
                    break;
                default:
                    TimeOffStatusFilterValue = this.repTimeOffStatusFilterValue;
                    break;
                }
            return `${TimeOffStatusFilterValue}` || this.translate.instant('TIMEOFF_ALL_TIME_OFF');
        }
    }

    public async saveMyTimeOff(data: TimeOffActivity, hasNetwork: boolean, raw?: any) {
        //online
        if (hasNetwork) {
            //logic to update the tot as per the server response
            data.timeOffRequestId = raw["indskr_timeoffrequestid"];
            data.totStartTime = new Date(raw["indskr_starttime"]);
            data.totEndTime = new Date(raw["indskr_endtime"]);
            data.positionId = raw["indskr_positionid"];
            data.ID = data.timeOffRequestId;
        }
        //offline
        else {
            data.timeOffRequestId = data.offlineTimeOffRequestId;
            data.ID = data.timeOffRequestId;
            data.totOwnerId = this.authenticationService.user.systemUserID;
            data.pendingPushToDynamics = true;
            await this.disk.insertOfflineTimeOffToDatabase(data);
        }
        data.scheduledStart = data.totStartTime;
        data.scheduledEnd = data.totEndTime;
        data.scheduledStartLocale = data.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        this.upsertMyTimeOff(data);
        //this.tot.push(data);
        this.totObs.next(this.tot);
        this.activityService.updateTimeOffRecords(data);
        //CWD-3247 fix
        if (this.tot.length != 0) { this.displayNoTimeOffActivity = false }
        try {
            await this.disk.updateOrInsertActivityToActivityDetailRawDocument(data, false, 'My');
        } catch (error) {
            console.error('saveMyTimeOff: ', error);
        }
        return;
    }

    public async delete(hasNetwork: boolean, tot: TimeOffActivity) {

        let index = this.tot.findIndex(a => a.timeOffRequestId == tot.timeOffRequestId);
        this.activityService.removeActivity(tot);

        if (!this.uiService.toolsActivityActive)
        {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        this.tot.splice(index, 1);
        console.log(index);
        this.totObs.next(this.tot);
        //offline deletion of time-off
        if (!hasNetwork) {
            await this.disk.removeTimeOffFromDatabase(tot);
        }

        await this.disk.deleteOfflineTimeOff(tot, false);
    }

    public async deleteTimeOffFromOfflineDoc(tot: TimeOffActivity) {

        await this.disk.removeOfflineTimeOffFromOfflineTable(tot);
    }

    public async onTimeOffUpdated(data: TimeOffActivity, hasNetwork: boolean, raw?: any) {
        let index = this.tot.findIndex((t: TimeOffActivity) => {
            return t.timeOffRequestId === data.timeOffRequestId;
        });
        data.scheduledStart = data.totStartTime;
        data.scheduledEnd = data.totEndTime;
        data.scheduledStartLocale = data.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        Object.assign(this.tot[index], data);
        this.activityService.updateTimeOffRecords(data);
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(data, false, 'My');
        this.totObs.next(this.tot);
        if (!hasNetwork) {
            await this.disk.updateOfflineTimeOffToPayload(data);
        }
        else {
            if (data.timeOffRequestId.includes("offline_")) {
                await this.disk.updateOfflineTimeOffToPayload(data);
            }
        }
    }

    public async onTimeOffReopened(data: TimeOffActivity, hasNetwork: boolean, teamViewActive: boolean, raw?: any) {
        let index = -1;
        if (this.totMode == "myRequests" && !teamViewActive) {
            index = this.tot.findIndex((t: TimeOffActivity) => {
                return t.timeOffRequestId === data.timeOffRequestId;
            });
            data.type = ActivityType.TimeOffRequest;
            Object.assign(this.tot[index], data);
            this.totObs.next(this.tot);
            await this.disk.updateOrInsertActivityToActivityDetailRawDocument(data, false, 'My');
        } else if (this.totMode == "teamRequests" || teamViewActive) {
            data.type = ActivityType.TimeOffRequest;
            //Remove Open TimeOff from Team TimeOff
            index = this.teamTot.findIndex((t: TimeOffActivity) => {
                return t.timeOffRequestId === data.timeOffRequestId;
            });
            await this.disk.remove(DB_KEY_PREFIXES.TEAM_TIMEOFF + this.teamTot[index].timeOffRequestId);
            this.teamTot.splice(index, 1);
            this.teamTotObs.next(this.teamTot);
        }
        data.scheduledStart = data.totStartTime;
        data.scheduledEnd = data.totEndTime;
        data.scheduledStartLocale = data.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        this.activityService.updateTimeOffRecords(data);
        //Update Details
        this.activityService.selected = data;
        this.setSelectedTot(<TimeOffActivity>this.activityService.selectedActivity);
        if (!hasNetwork) {
            await this.disk.updateOfflineTimeOffToPayload(data);
        }
        else {
            if (data.timeOffRequestId.includes("offline_")) {
                await this.disk.updateOfflineTimeOffToPayload(data);
            }
        }
    }

    public async onTimeOffApproval(data: TimeOffActivity, hasNetwork: boolean, approve: boolean, raw?: any) {
        if (hasNetwork) {
            let index = this.teamTot.findIndex((t: TimeOffActivity) => {
                return t.timeOffRequestId === data.timeOffRequestId;
            });
            console.log(index);
            if (approve) {
                data.type = ActivityType.TimeOff;
                //replace object with updated data
                Object.assign(this.teamTot[index], data);
                // await this.disk.updateOrInsertActivityToActivityDetailRawDocument(data, false, 'Team');
            } else {
                //delete the tot object from list and disk
                this.teamTot.splice(index, 1);
                // await this.disk.deleteOfflineTimeOff(data, true);
            }
            this.teamTotObs.next(this.teamTot);
        }
    }

    public generateTimeDuration(selectedTot: TimeOffActivity): string {
        if (selectedTot != null) {
            //All Day Event
            if (selectedTot.totIsAlldayEvent === true) {
                let localeStartDate = moment(selectedTot.totStartTime)
                let startTime = localeStartDate.locale(this.translate.currentLang).format(this.dateTimeFormatsService.dateToUpper);
                let localeEndDate = moment(selectedTot.totEndTime)
                let endTime = localeEndDate.locale(this.translate.currentLang).format(this.dateTimeFormatsService.dateToUpper);
                return startTime + " - " + endTime;
            }
            //either half or other days selected
            else { 
                //Halfday
                let duration: number = this.durationCalculator(selectedTot);
                if (duration === 719 || duration === 720) {
                    let startTime = moment(selectedTot.totStartTime).locale(this.translate.currentLang).format(this.dateTimeFormatsService.dateToUpper + " A");
                    return startTime;
                }
                else {
                    let startTime = moment(selectedTot.totStartTime).locale(this.translate.currentLang).format(this.dateTimeFormatsService.dateToUpper);
                    let endTime = moment(selectedTot.totEndTime).locale(this.translate.currentLang).format(this.dateTimeFormatsService.dateToUpper);
                    return startTime + " - " + endTime;
                }
            }
        }
        return "Select Dates";
    }

    public generateTimeDurationText(selectedTot: TimeOffActivity): any {
      let dateTimeValue: any = {
        startDateTime: '',
        startDate: '',
        startTime: '',
        endDateTime: '',
        endDate: '',
        endTime: '',
        duration: '',
      }; 
      if (selectedTot != null) {
        let m1 = moment(selectedTot.totStartTime);
        let m2 = moment(selectedTot.totEndTime);
        if (m1.isValid() && m2.isValid()) {
          let startDateTimeValue: Date = new Date(selectedTot.totStartTime);
          let endDateTimeValue: Date = new Date(selectedTot.totEndTime);
          let startDayValue = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
          let endDayValue = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
          let startTimeValue = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
          let endTimeValue = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
          let formattedDuration: string = this.getFormattedTimeInterval(selectedTot);;
  
          dateTimeValue = {
            startDateTime: startDateTimeValue,
            startDate: startDayValue,
            startTime: startTimeValue,
            endDateTime: endDateTimeValue,
            endDate: endDayValue,
            endTime: endTimeValue,
            duration: formattedDuration,
          };
          return dateTimeValue;
        }
      }
      return "Select Dates";
    }

    public durationCalculator(timeoff: TimeOffActivity): number {
        let start = moment(new Date(timeoff.totStartTime));
        let end = moment(new Date(timeoff.totEndTime));
        let difference: number = end.diff(start, 'minutes')
        return difference;
    }

    public unsetMobileTracker() {
        this.mobRequestInitiatedFrom = '';
    }

    public setMobileTracker(input: string) {
        this.mobRequestInitiatedFrom = input;
    }

    public getMobileTracker(): string {
        return this.mobRequestInitiatedFrom;
    }

    public getFormattedTimeInterval(timeOff: Activity): string {
      let timeOffData = (timeOff as TimeOffActivity)
        let duration: string = "";
        let totEndTime: Date;
        let totStartTime: Date;
        let event: boolean = null;
        //Timeoff details on agenda view which has been approved
        if (isNaN(new Date(timeOffData.totEndTime).valueOf()) || isNaN(new Date(timeOffData.totStartTime).valueOf())) {
            totEndTime = new Date(timeOffData.scheduledEnd);
            totStartTime = new Date(timeOffData.scheduledStart);
            event = timeOffData.allDayEvent;
        }
        //regular timeoff data unapproved on the list
        else {
            totEndTime = new Date(timeOffData.totEndTime);
            totStartTime = new Date(timeOffData.totStartTime);
            event = timeOffData.totIsAlldayEvent;
        }
        if (event === true) {
            let days: any = moment(totEndTime).diff(moment(totStartTime), 'days') + 1;
            if (days == 1) {
              duration = days + " " + this.translate.instant('DAY');
            } else {
              duration = days + " " + this.translate.instant('DAYS');
            }
            return duration;
        }
        else {
            let diff: number = this.durationCalculator(timeOffData);
            if (diff === 719 || diff === 720) {
                duration = "0.5" + " " + this.translate.instant('DAY');;
                return duration;
            }
            else {
                let days: any = differenceInDays(totEndTime, totStartTime);
                let timeDifference: Date = new Date((new Date(totEndTime).setSeconds(0).valueOf() - new Date(totStartTime).setSeconds(0).valueOf()));
                //Epoch Unix timestamp correction: added 1 second to calcuated time difference in minutes
                let correctedTimeDiff: Date = new Date(timeDifference.setSeconds(timeDifference.getSeconds()+1));
                let minutes: any = correctedTimeDiff.getUTCMinutes();
                let hours: any = correctedTimeDiff.getUTCHours();
                if (days === 0) {
                  days = "";
                } else if (days <= 1) {
                  if (hours !=0 || minutes !=0) {
                    days = days + " " + this.translate.instant('DAY') + ", ";
                  } else {
                    days = days + " " + this.translate.instant('DAY');
                  }
                } else {
                  if (hours !=0 || minutes !=0) {
                    days = days + " " + this.translate.instant('DAYS') + ", ";
                  } else {
                    days = days + " " + this.translate.instant('DAYS');
                  }
                }
                if (hours === 0) {
                  hours = "";
                } else if (hours <= 1) {      
                  if (minutes === 0) {
                    hours = hours + " " + this.translate.instant('HOUR');
                  } else {
                    hours = hours + " " + this.translate.instant('HOUR') + ", ";
                  }
                }
                else {
                  if (minutes === 0) {
                    hours = hours + " " + this.translate.instant('HOURS');
                  } else {
                    hours = hours + " " + this.translate.instant('HOURS') + ", ";
                  }
                }
                if (minutes === 0) {
                  minutes = "";
                } else {
                  minutes = minutes + " " + this.translate.instant('MINUTES');
                }
                duration = days + "" + hours + "" + minutes;
                return duration; 
            }
        }
    }

    public mapMyTimeOffFieldsToSearchIndex(newTot:TimeOffActivity){
        if(!this.searchConfigService.isConfigInitiated){
            this.searchConfigService.initSearchConfigs();
            this.searchConfigService.isConfigInitiated = true;
        }

        if(newTot.statusValue && !this.searchConfigService.myTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Status').values.some(o=> o == newTot.statusValue)){
            this.searchConfigService.myTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Status').values.push(newTot.statusValue);
        }
        if(newTot.reason && !this.searchConfigService.myTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Reasons').values.some(o=> o == newTot.reason)){
            this.searchConfigService.myTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Reasons').values.push(newTot.reason);
        }
        if(newTot.type && !this.searchConfigService.myTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.some(o=> o == newTot.durationType)){
            this.searchConfigService.myTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.push(newTot.durationType);
        }
    }

        public mapTeamTimeOffFieldsToSearchIndex(newTot:TimeOffActivity){
            if(!this.searchConfigService.isConfigInitiated){
                this.searchConfigService.initSearchConfigs();
                this.searchConfigService.isConfigInitiated = true;
            }
            if(newTot.statuscode != TimeOffStatus.Open){
                if(newTot.statusValue && !this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Status').values.some(o=> o == newTot.statusValue)){
                    this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Status').values.push(newTot.statusValue);
                }
                if(newTot.reason && !this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Reasons').values.some(o=> o == newTot.reason)){
                    this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Reasons').values.push(newTot.reason);
                }
                if(newTot.type && !this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.some(o=> o == newTot.durationType)){
                    this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.push(newTot.durationType);
                }
                if(newTot.totOwnerValue && !this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.some(o=> o == newTot.totOwnerValue)){
                    this.searchConfigService.teamTimeOffSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.push(newTot.totOwnerValue);
                }
            }
     }

    public refreshMyTimeOffList(){
        this.totObs.next(this.tot);
    }

    public refreshTeamTimeOffList(){
        this.teamTotObs.next(this.teamTot);
    }

    public getTimePeriodText(selectedTimePeriod: string) {
      let popupSelectedTimePeriod: string;
      switch (selectedTimePeriod) {
        case 'AM':
          popupSelectedTimePeriod = this.translate.instant('AM');
          break;
        case 'PM':
          popupSelectedTimePeriod = this.translate.instant('PM');
          break;
        default:
          popupSelectedTimePeriod = this.popupSelectedTimePeriodEvent;
          break;
      }
      return popupSelectedTimePeriod;
    }

}


export interface filterModel {
    all: string,
    status: string,
    position: string,
    user: string
}
