import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { InventoryLot } from "@omni/classes/sample/allocation-inventory.class";
import { IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { NavigationService } from "@omni/services/navigation/navigation.service";
import { AllocationInventoryService } from "@omni/services/sample/allocation-inventory.service";
import { UIService } from "@omni/services/ui/ui.service";
import _ from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: 'user-inventory-detail',
  templateUrl: 'user-inventory-detail.html',
  styleUrls:['user-inventory-detail.scss']
})

export class UserInventoryDetailComponent {

  public userInventoryDetailPanePageTitle: IndPageTitleViewDataModel;
  public generaSectionTitle: IndSectionHeaderViewDataModel;
  public qtyReceivedField: IndFormFieldViewDataModel;
  public qtyDroppedField: IndFormFieldViewDataModel;
  public qtyAdjustedField: IndFormFieldViewDataModel;
  public qtyTransferredField: IndFormFieldViewDataModel;
  public qtyRemainingField: IndFormFieldViewDataModel;
  public selectedLot: InventoryLot;
  private selectedLotSubscription: Subscription;


  constructor(
    private navService: NavigationService,
    public translate: TranslateService,
    private allocationInventoryService: AllocationInventoryService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.selectedLotSubscription = this.allocationInventoryService.selectedLotObservable$.subscribe((lot) => {
      this.selectedLot = lot;
      if(!_.isEmpty(this.selectedLot)) {
        this._initPageTitle();
        this._initSectionTitle();
        this._initAllDataModels();
      }
    })
    this._initPageTitle();
    this._initSectionTitle();
    this._initAllDataModels();
  }

  private _initAllDataModels() {
    this.getQtyReceivedField();
    this.getQtyDroppedField();
    this.getQtyAdjustedField();
    this.getQtyRemainingField();
    this.getQtyTransferredField();
  }

  ngOnDestroy() {
    if(this.selectedLotSubscription) this.selectedLotSubscription.unsubscribe();
  }

  private _initPageTitle() {
    let buttons = [];
    if (!this.isSplitView) {
        buttons.push({
            id: 'close',
            icon: 'chevron-back-outline',
            isDisabled: false,
            align: 'left'
        });
    }
    this.userInventoryDetailPanePageTitle = {
      id: 'user-Inventory-details-page-title',
      title: this.selectedLot.lotName,
      controls: buttons
    };
  }

  private _initSectionTitle() {
    let titleText = this.translate.instant('GENERAL');
    if(this.translate.currentLang == 'es') {
      titleText = this.translate.instant('SUMMARY');
    }
    this.generaSectionTitle = {
      id: 'user-Inventory-general-header',
      title: titleText,
      controls: [],
    };
  }

  private getQtyReceivedField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUANTITY_RECEIVED'),
      inputText: this.selectedLot.totalQtyRecieved.toString(),
      id: 'qty-received-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.qtyReceivedField = viewData;
  }

  private getQtyDroppedField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUANTITY_DROPPED'),
      inputText: this.selectedLot.totalQtyDropped.toString(),
      id: 'qty-dropped-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.qtyDroppedField = viewData;
  }

  private getQtyAdjustedField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUANTITY_ADJUSTED'),
      inputText: this.selectedLot.qtyAdjusted.toString(),
      id: 'qty-adjusted-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.qtyAdjustedField = viewData;
  }

  private getQtyTransferredField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('AL_QUANTITY_TRANS'),
      inputText: this.selectedLot.totalQtyTransferred.toString(),
      id: 'qty-transferred-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.qtyTransferredField = viewData;
  }

  private getQtyRemainingField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUANTITY_REMAINING'),
      inputText: this.selectedLot.totalQtyRemaining.toString(),
      id: 'qty-remaining-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.qtyRemainingField = viewData;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
        case 'close':
            this.onClosePage();
            break;
        default:
            console.log('Unhandled switch case statement');
            break;
    }
  }

  public onClosePage() {
    this.allocationInventoryService.selectedLot$.next(undefined);
    const activeChildNavViewPageName = this.navService.getPreviousActiveChildNavViewPageName();
    if(activeChildNavViewPageName) {
      this.navService.popChildNavPageWithPageTracking();
    }else {
      this.uiService.activeView = '';
    }
  }

  isSplitView() {
    return (window.innerWidth > 500);
  }

}