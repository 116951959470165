import { Component, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SettingsComponent } from '../settings/settings';
import { UIService } from '../../../services/ui/ui.service';

/**
 * Generated class for the SettingsHomeComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'settings-home',
  templateUrl: 'settings-home.html',
  styleUrls:['settings-home.scss']
})
export class SettingsHomeComponent {

  public showVideoDetails:boolean=false;

  constructor(public uiService:UIService) {

  }

/**
 * Toggle video settings details view
 *
 * @memberof SettingsHomeComponent
 */
showHideSettings(value){
    this.uiService.showNewActivity = false;
    this.uiService.activeView = value;
  }


}
