import { BehaviorSubject, Observable } from "rxjs";

export class FeatureAction {
  private name: string;
  private _isOn: boolean = false;
  private isOn$: BehaviorSubject<boolean>;
  public isOnObservable: Observable<boolean>;

  constructor(name: string, isOn: boolean = false) {
    this.name = name;
    this._isOn = isOn;
    this.isOn$ = new BehaviorSubject(this._isOn);
    this.isOnObservable = this.isOn$.asObservable();
  }

  getName() {
    return this.name;
  }
  getIsOn() {
    return this._isOn;
  }
  setIsOn(isOn: boolean) {
    if (isOn !== this._isOn) {
      this._isOn = isOn;
      this.isOn$.next(this._isOn);
    }
  }
}
